import { isEmptyString } from "@util";

export interface FederatedLoginState {
  username: string;
  accountId: string;
  redirectUri?: string;
  rememberMe?: boolean;
  autoLoginEnabled?: boolean;
}

export const DEFAULT_FEDERATED_LOGIN_STATE = Object.freeze<FederatedLoginState>({
  username: "",
  accountId: "",
  rememberMe: false,
  autoLoginEnabled: false,
});

export const createEncodedFederatedLoginState = (request: FederatedLoginState): string => {
  try {
    return btoa(JSON.stringify(request));
  } catch (e) {
    console.error("Failed to create encoded federated login state!", e);
    return "";
  }
};

export const decodeFederatedLoginState = (state: string): FederatedLoginState => {

  if (isEmptyString(state)) {
    return DEFAULT_FEDERATED_LOGIN_STATE;
  }

  try {
    return {
      ...DEFAULT_FEDERATED_LOGIN_STATE,
      ...JSON.parse(atob(state)),
    };
  } catch (e) {
    console.error("Failed to decode federated login state!", e);
    return DEFAULT_FEDERATED_LOGIN_STATE;
  }
};
