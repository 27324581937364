import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import TextField from "@components/text-field";
import { AutocompleteRenderOptionState } from "@material-ui/lab/Autocomplete/Autocomplete";
import AutocompleteDropdown, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface FilterOptionsConfig<T = string> {
  ignoreAccents?: boolean;
  ignoreCase?: boolean;
  limit?: number;
  matchFrom?: "any" | "start";
  stringify?: (option: T) => string;
  trim?: boolean;
}

export interface AutocompleteModel<T = string> {
  className?: string;
  filterOptionsConfig?: FilterOptionsConfig<T>;
  tagClassName?: string;
  optionClassName?: string;
  options?: string[];
  value?: string[];
  loading?: boolean;
  loadingText?: string;
  disabled?: boolean;
  openOnFocus?: boolean;
  blurOnSelect?: boolean;
  selectOnFocus?: boolean;
  multiple?: boolean;
  disableClearable?: boolean;
  filterSelectedOptions?: boolean;
  freeSolo?: boolean;
  popupIcon?: React.ReactNode;
  label?: string;
  inputType?: React.InputHTMLAttributes<unknown>["type"];
  noOptionsLabel?: string;
  maxLimitLabel?: string;
  pleaseRemoveSelectedValueLabel?: string;
  placeholder?: string;
  maxNumSelectedValues?: number;
  helperText?: string;
  helperTextClassName?: string;
  customValuesDisabled?: boolean;
}

export interface AutocompleteActions<T = string> {
  setValue?: (value: string[]) => void;
  getOptionDisabled?: (option: string) => boolean;
  renderOption?: (option: string, state: AutocompleteRenderOptionState) => React.ReactNode;
  onInputValueChange?: (inputValue: string) => void;
  onOpen?: () => void;
}

type Model<T> = AutocompleteModel<T>;
type Actions<T> = AutocompleteActions<T>;
type Props<T> = WithStyles<typeof styles> & Model<T> & Actions<T>;

export const Autocomplete = withStyles(styles)(<T, >(props: Props<T>) => {

  const {
    classes,
    className,
    filterOptionsConfig,
    tagClassName,
    optionClassName,
    options = [],
    value = [],
    disabled,
    openOnFocus = true,
    multiple = true,
    blurOnSelect = true,
    selectOnFocus = true,
    disableClearable,
    filterSelectedOptions,
    freeSolo,
    popupIcon,
    label,
    inputType,
    loading,
    loadingText = "Loading...",
    noOptionsLabel = "No Options Found",
    maxLimitLabel = "Max Limit Reached",
    pleaseRemoveSelectedValueLabel = "Please remove selected value to continue",
    placeholder,
    helperText,
    helperTextClassName,
    maxNumSelectedValues,
    customValuesDisabled,
    setValue = noop,
    getOptionDisabled,
    renderOption,
    onOpen = noop,
    onInputValueChange = noop,
  } = props;

  const formHelperTextProps = {
    className: classnames("autocompleteFormHelperText", helperTextClassName),
    classes: {
      root: classes.autocompleteFormHelperText,
    },
  };

  const inputLabelProps = {
    className: "autocompleteInputLabel",
    shrink: true,
    classes: {
      root: classes.autocompleteInputLabel,
      shrink: classes.autocompleteInputLabelShrink,
      error: classes.error,
      focused: classes.focused,
    },
  };

  const maxLimitReached = React.useMemo(() =>
    maxNumSelectedValues && value.length >= maxNumSelectedValues, [maxNumSelectedValues, value]);

  const autocompleteOptions = React.useMemo(() =>
    maxLimitReached ? [] : options, [maxLimitReached, options]);

  const noOptionsText = React.useMemo(() => {
      if (maxLimitReached) {
        if (maxNumSelectedValues && maxNumSelectedValues === 1) {
          return pleaseRemoveSelectedValueLabel;
        } else {
          return maxLimitLabel;
        }
      }

      return noOptionsLabel;
    },
    [maxLimitReached, maxNumSelectedValues, maxLimitLabel, noOptionsLabel, pleaseRemoveSelectedValueLabel]);

  const filter = createFilterOptions<T>(filterOptionsConfig);

  const filterOptions = React.useCallback((visibleOptions, autocompleteState) => {

    const filteredResults = filter(visibleOptions, autocompleteState);

    const { inputValue = "" } = autocompleteState;

    if (!customValuesDisabled && !maxLimitReached &&
      filteredResults.length === 0 && !isEmptyString(inputValue)) {

      filteredResults.push(inputValue);
    }

    return filteredResults;

  }, [filter, customValuesDisabled, maxLimitReached]);

  const onChange = React.useCallback((event, updatedValue) => setValue(updatedValue), [setValue]);

  return (
    <AutocompleteDropdown
      className={classnames("autocomplete", className, classes.container)}
      classes={{
        tag: classnames(classes.autocompleteTag, tagClassName),
        option: classnames(classes.autocompleteOption, optionClassName),
      }}
      options={autocompleteOptions}
      noOptionsText={noOptionsText}
      value={value}
      loading={loading}
      loadingText={loadingText}
      disabled={disabled}
      openOnFocus={openOnFocus}
      multiple={multiple}
      blurOnSelect={blurOnSelect}
      selectOnFocus={selectOnFocus}
      disableClearable={disableClearable}
      filterSelectedOptions={filterSelectedOptions}
      freeSolo={freeSolo}
      popupIcon={popupIcon}
      onChange={onChange}
      renderOption={renderOption}
      getOptionDisabled={getOptionDisabled}
      onOpen={(event) => onOpen()}
      onInputChange={(event, updatedValue) => onInputValueChange(updatedValue)}
      renderInput={({ InputProps, ...params }) => (
        <TextField
          {...params}
          className={classnames("autocompleteTextField", classes.autocompleteTextField)}
          label={label}
          type={inputType}
          placeholder={placeholder}
          color="secondary"
          variant="outlined"
          InputLabelProps={inputLabelProps}
          helperText={helperText}
          FormHelperTextProps={formHelperTextProps}
          fullWidth={true}
          disabled={disabled || loading}
          InputProps={{
            ...InputProps,
            className: classnames(
              "autocompleteTextFieldInput",
              InputProps.className,
              classes.autocompleteTextFieldInput,
            ),
            classes: {
              input: classnames("autocompleteTextFieldInputPropsInput",
                classes.autocompleteTextFieldInputPropsInput),
            },
          }}
        />
      )}
      filterOptions={filterOptions}
    />
  );
});

export default Autocomplete;
