import { AppSchema } from "@schemas";
import { hasSpecialChars } from "@util";
import { createSelector } from "reselect";

export const getNamespace = (state: AppSchema): string => {
  return state.createNamespace.namespace;
};

export const getErrorMessage = (state: AppSchema): string => {
  return state.createNamespace.errorMessage;
};

export const getSuccessMessage = (state: AppSchema): string => {
  return state.createNamespace.successMessage;
};

export const isAccessDeniedVisible = (state: AppSchema): boolean => {
  return state.createNamespace.showAccessDenied;
};

export const isProgressIndicatorVisible = (state: AppSchema): boolean => {
  return state.createNamespace.showProgressIndicator;
};

export const isNamespaceValid: (state: AppSchema) => boolean = createSelector(
  getNamespace, (namespace: string) => namespace.trim().length > 0 && !hasSpecialChars(namespace));
