import React from "react";
import classnames from "classnames";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@components/text-field";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { formControlLabel, styles, textField } from "./styles";
import { JsonSchemaPropertyType } from "../../../../data";
import { enterKeyHandler, formEventHandler, noop } from "../../../../util";
import PropertyEditorType from "./PropertyEditorType";

const FormControlLabel = withStyles(formControlLabel)(MuiFormControlLabel);
const PropertyTextField = withStyles(textField)(TextField);

export interface Model {
  className?: string;
  name?: string;
  nameError?: string;
  type?: JsonSchemaPropertyType;
  types?: string[];
  description?: string;
  required?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  setName?: (name: string) => void;
  clearNameError?: () => void;
  setType?: (type: JsonSchemaPropertyType) => void;
  setDescription?: (description: string) => void;
  setRequired?: (required: boolean) => void;
  save?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const PropertyEditor = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    name = "",
    nameError = "",
    type = JsonSchemaPropertyType.STRING,
    types = [],
    description = "",
    required = false,
    loading = false,
    setName = noop,
    clearNameError = noop,
    setType,
    setDescription,
    setRequired = noop,
    save = noop,
    children,
  } = props;

  const formHelperTextProps = {
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const updateName = (updatedName: string = "") => {
    setName(updatedName);
    clearNameError();
  };

  return (
    <div className={classnames("propertyEditor", className, classes.container)}>
      <div className={classnames("controls", classes.controls)}>
        <PropertyTextField
          className={classnames("name", classes.inputField, classes.name)}
          autoComplete="off"
          label="Name"
          placeholder="Set property name"
          name="name"
          value={name}
          fullWidth={false}
          autoFocus={true}
          disabled={loading}
          helperText={nameError}
          variant="outlined"
          margin="none"
          error={nameError.length > 0}
          onChange={formEventHandler(updateName)}
          onKeyDown={enterKeyHandler(save)}
          FormHelperTextProps={formHelperTextProps}
          InputLabelProps={inputLabelProps}
        />
        <PropertyEditorType
          type={type}
          types={types}
          loading={loading}
          setType={setType}
        />
        <FormControlLabel
          className={classnames("requiredContainer", classes.inputField, classes.requiredContainer)}
          label="Required?"
          control={(
            <Checkbox
              className={classnames("required", classes.required)}
              color="primary"
              checked={required}
              disabled={loading}
              onChange={formEventHandler(() => setRequired(!required))}
            />
          )}
        />
      </div>
      <PropertyTextField
        className={classnames("description", classes.inputField, classes.description)}
        autoComplete="off"
        name="description"
        label="Description (optional)"
        placeholder="Add a description for this property (optional)"
        value={description}
        variant="outlined"
        margin="none"
        fullWidth={true}
        disabled={loading}
        onChange={formEventHandler(setDescription)}
        onKeyDown={enterKeyHandler(save)}
        FormHelperTextProps={formHelperTextProps}
        InputLabelProps={inputLabelProps}
      />
      {children}
    </div>
  );
});

export default PropertyEditor;
