import React from "react";
import {
  ActionMenuItem,
  DELETE_DS_LAYOUT_ACTION,
  EDIT_DS_LAYOUT_ACTION,
  DeleteDSLayoutDialog,
  DSLayoutAction,
  DSLayoutOperation,
  SummaryJsonDetailsView,
  SummaryJsonDetailsViewActions,
  SummaryJsonDetailsViewModel,
} from "@components";
import { useGetDSLayout } from "@hooks";
import {
  DSLayoutInfo,
  DSTypeInfo,
  KeyValuePair,
  SummaryViewData
} from "@data";
import { PrimaryIcon } from "../components/styles";
import LayoutSummaryView from "../components/LayoutSummaryView";
import { noop } from "@util";
import ChildrenView from "../components/ChildrenView";

const DEFAULT_ACTIONS: ActionMenuItem[] = Object.freeze([
  EDIT_DS_LAYOUT_ACTION,
  DELETE_DS_LAYOUT_ACTION,
]) as ActionMenuItem[];

interface ContainerModel extends SummaryJsonDetailsViewModel {
  name?: string;
}

interface ContainerActions extends SummaryJsonDetailsViewActions {
  onClickAction?: (action: ActionMenuItem) => void;
  showListView?: () => void;
  editLayout?: () => void;
}

type Props = ContainerModel & ContainerActions;

const DigitalShadowLayoutDetails = (props: Props) => {

  const {
    name = "",
    onClickAction = noop,
    showListView = noop,
    editLayout = noop,
    ...otherProps
  } = props;

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [operation, setOperation] = React.useState(DSLayoutOperation.NONE);

  const [ model, actions ] = useGetDSLayout({
    name
  });

  const { dsLayout = DSLayoutInfo.EMPTY, showErrorView, ...otherModel } = model;

  const { refresh, ...otherActions } = actions;

  const summaryViewItems = React.useMemo(() => [
    new SummaryViewData({
      className: "name",
      name: "Name",
      value: dsLayout.getName()
    }),
    new SummaryViewData({
      className: "description",
      name: "Description",
      value: dsLayout.getDescription()
    }),
  ], [dsLayout]);

  const json = React.useMemo(() =>
    JSON.stringify(dsLayout.toJS(), null, "  "), [dsLayout]);

  const metadata = React.useMemo(() =>
    KeyValuePair.fromObjectToArray(dsLayout.getMetadata()),
    [dsLayout]);

  const deleteDSLayout = React.useCallback(() => {
    setOpenDeleteDialog(true);
    setOperation(DSLayoutOperation.DELETE);
  }, [setOpenDeleteDialog, setOperation]);

  const closeDeleteDialog = React.useCallback(() => {
    setOpenDeleteDialog(false);
    setOperation(DSLayoutOperation.NONE);
  }, [setOpenDeleteDialog, setOperation]);

  const onDeleteSuccess = React.useCallback(() => {
    closeDeleteDialog();
    showListView();
  }, [showListView, closeDeleteDialog]);

  const actionClicked = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case DSLayoutAction.EDIT_DS_LAYOUT_ACTION:
        return editLayout();
      case DSLayoutAction.DELETE_DS_LAYOUT_ACTION:
        return deleteDSLayout();
      default:
        return onClickAction(action);
    }
  }, [deleteDSLayout, editLayout, onClickAction]);

  const children = React.useMemo(() =>
    dsLayout.getChildren().length > 0 ?
      dsLayout.getChildren().map((attrs) => new DSTypeInfo(attrs))
    : [],
    [dsLayout]);

  const additionalViews = React.useMemo(()  => (
    <ChildrenView
      types={children}
      showErrorView={showErrorView}
    />
  ), [children, showErrorView]);

  const layoutSummaryView = React.useMemo(() => (
   <LayoutSummaryView
     summaryViewItems={summaryViewItems}
     metadataItems={metadata}
   />
  ), [summaryViewItems, metadata]);

  return (
    <React.Fragment>
      <SummaryJsonDetailsView
        {...otherProps}
        {...otherModel}
        {...otherActions}
        className={"digitalShadowLayoutDetails"}
        summaryViewClassName={"digitalShadowLayoutDetailsSummaryView"}
        jsonViewClassName={"digitalShadowLayoutDetailsJsonView"}
        showDefaultSummaryView={false}
        customSummaryView={layoutSummaryView}
        additionalViews={additionalViews}
        actions={DEFAULT_ACTIONS}
        onClickAction={actionClicked}
        json={json}
        icon={PrimaryIcon}
        title={name}
        refresh={refresh}
      />
      <DeleteDSLayoutDialog
        open={openDeleteDialog}
        operation={operation}
        cancel={closeDeleteDialog}
        layoutName={dsLayout.getName()}
        onSuccess={onDeleteSuccess}
      />
    </React.Fragment>
  );
};

export default DigitalShadowLayoutDetails;
