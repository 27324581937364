import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const listItem = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "center",
    },
    label: {
      color: Color.TEXT,
      cursor: "default",
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
      textTransform: "none",
      wordBreak: "break-word",
      marginRight: 8,
    },
    tags: {
      cursor: "pointer",
      listStyleType: "none",
      padding: 0,
      margin: "0 8px 0 0",
    },
    tag: {
      overflow: "hidden",
      display: "inline-block",
      padding: 2,
    },
    chip: {
      backgroundColor: Color.SIGNIFY_GREEN + " !important",
      height: "auto !important",
      padding: "6px 10px !important",
      "& span": {
        color: Color.TEXT,
        fontSize: 11,
        fontWeight: 400,
        lineHeight: "11px",
        margin: 0,
        padding: 0,
      },
    },
  });

export const childrenView = () =>
  createStyles({
    container: {
      marginTop: 0,
      "& th.expandColumn, & td.expandColumn": {
        width: "10%",
      },
    },
  });

export const expandedChildView = () =>
  createStyles({
    container: {
      marginLeft: 15,
      marginTop: 0,
      "& th.expandColumn, & td.expandColumn": {
        width: "10%",
      },
    },
    lastChild: {
      "& td.name": {
        paddingLeft: "calc(10px + 10%)"
      },
    }
  });
