import React from "react";
import classnames from "classnames";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { wizardStepper as styles } from "./styles";
import { ThemeProvider } from "@components/styles";
import { clickHandler, getStringValue, noop } from "@util";
import theme from "./theme";

export interface WizardStepperModel<WizardStep> {
  className?: string;
  steps?: WizardStep[];
  currentStep?: WizardStep;
  disabledSteps?: WizardStep[];
  completedSteps?: WizardStep[];
}

export interface WizardStepperActions<WizardStep> {
  setCurrentStep?: (step: WizardStep) => void;
  mapStepToLabel?: (step: WizardStep) => React.ReactNode | string | null;
}

type Props<WizardStep> = WithStyles<typeof styles> &
  WizardStepperModel<WizardStep> & WizardStepperActions<WizardStep> & {

  children?: React.ReactNode;
};

export const WizardStepper = withStyles(styles)(<WizardStep, >(props: Props<WizardStep>) => {

  const {
    classes,
    className,
    steps = [],
    disabledSteps = [],
    completedSteps = [],
    currentStep,
    setCurrentStep = noop,
    mapStepToLabel = () => null,
  } = props;

  const currentStepIndex = React.useMemo(() => {
    if (!currentStep) {
      return 0;
    }
    const index = steps.indexOf(currentStep);
    return index >= 0 ? index : 0;
  }, [steps, currentStep]);

  return (
    <ThemeProvider theme={theme}>
      <Stepper
        className={classnames("wizardStepper", className, classes.container)}
        activeStep={currentStepIndex}
        nonLinear={true}
      >
        {steps.map((step: WizardStep) => {

          const label = mapStepToLabel(step);

          if (!label) {
            return null;
          }

          return (
            <Step
              key={`wizard-step-${step}`}
              className={classnames("step", getStringValue(step), classes.step)}
              disabled={disabledSteps.indexOf(step) >= 0}
            >
              <StepButton
                className={classnames("stepButton", classes.stepButton)}
                completed={completedSteps.indexOf(step) >= 0}
                onClick={clickHandler(() => {
                  if (step !== currentStep) {
                    setCurrentStep(step);
                  }
                })}
              >
                <StepLabel className={classnames("stepLabel", getStringValue(step), classes.stepLabel)}>
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </ThemeProvider>
  );
});

export default WizardStepper;
