import React from "react";
import { isEmptyString, memoize } from "@util";
import { useLocation } from "react-router-dom";
import Module, { MapActionToRedirectUri } from "@data/Module";

interface PortalRedirectMappers {
  [key: string]: MapActionToRedirectUri;
}

export const getPortalRedirectMappers = memoize((modules: Module[]): PortalRedirectMappers => {
  return modules
    .reduce((data: PortalRedirectMappers, module: Module) => {

      const { redirects = {} } = module;

      const duplicates = Object.keys(redirects)
        .filter(action => data.hasOwnProperty(action));

      if (duplicates.length > 0) {
        throw new Error("Multiple redirect handlers found for the following action(s): " + duplicates.join(", "));
      }

      return {
        ...data,
        ...redirects,
      };
    }, {});
});

export const usePortalRedirect = (modules: Module[]): string | null => {

  const location = useLocation();
  const mappers = getPortalRedirectMappers(modules);
  const { pathname, search } = location;
  const queryParams = new URLSearchParams(search);
  const isBaseUrl = React.useMemo(() => pathname === "/", [location]);
  const action = React.useMemo(() => queryParams.get("action") || "", [queryParams]);
  const resourceId = React.useMemo(() => queryParams.get("resourceId") || "", [queryParams]);
  const mapper: MapActionToRedirectUri | undefined = mappers[action];

  if (!isBaseUrl || isEmptyString(action) || !mapper) {
    return null;
  }

  // Remove query params that we are providing to the mappers
  queryParams.delete("action");
  queryParams.delete("resourceId");

  return mapper(action, resourceId, queryParams);
};

export default usePortalRedirect;
