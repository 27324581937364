import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes
} from "@base";

export enum PoliciesViewMode {
  CUSTOM = "custom",
  MANAGED = "managed"
}

export interface SecurityServiceRegionalDetailsSchema extends BasePortalModuleSchema {
  serviceId: string;
  policiesViewMode: PoliciesViewMode;
  serviceEnabled: boolean;
  removeServiceButtonEnabled: boolean;
  detachPolicyButtonEnabled: boolean;
}

export const DEFAULT_STATE: SecurityServiceRegionalDetailsSchema = createPortalModuleDefaultState({
  serviceId: "",
  policiesViewMode: PoliciesViewMode.CUSTOM,
  serviceEnabled: false,
  removeServiceButtonEnabled: false,
  detachPolicyButtonEnabled: true,
});

type SecurityServiceRegionalDetailsActionTypes = PortalModuleActionTypes<SecurityServiceRegionalDetailsSchema>;

export const ACTION_TYPES: SecurityServiceRegionalDetailsActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<SecurityServiceRegionalDetailsSchema>(MODULE_ID, DEFAULT_STATE),
});

export const SecurityServiceRegionalDetailsReducer = combineReducers<SecurityServiceRegionalDetailsSchema>(
  createPortalModuleReducers<SecurityServiceRegionalDetailsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default SecurityServiceRegionalDetailsReducer;
