import React from "react";
import { useHistory } from "react-router-dom";
import { DeviceEnrollmentStatus } from "@data";
import { getPathToDeviceEnrollment } from "@modules/deviceEnrollmentDetails/helpers";
import { getEnrollDevicePath, getValidateDevicePath } from "@modules/deviceEnrollmentWizard/helpers";
import DeviceEnrollment from "./containers/DeviceEnrollment";

export function DeviceEnrollmentView() {

  const history = useHistory();

  const enrolDevice = React.useCallback(() =>
  history.push(getEnrollDevicePath()), [history]);

  const validateDevice = React.useCallback(() =>
    history.push(getValidateDevicePath()), [history]);

  const showDeviceEnrollmentDetails = React.useCallback((deviceEnrollment: DeviceEnrollmentStatus) =>
    history.push(getPathToDeviceEnrollment(deviceEnrollment.getBatchId())), [history]);

  return (
    <DeviceEnrollment
      showDeviceEnrollmentDetails={showDeviceEnrollmentDetails}
      enrollDevice={enrolDevice}
      validateDevice={validateDevice}
    />
  );
}

export default DeviceEnrollmentView;
