import React from "react";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  usePortalUserFeedbackClient
} from "@hooks";
import { isEmptyString } from "@util";

export interface UseSubmitUserFeedbackProps extends Partial<UseApiRequestProps<void>> {
  message: string;
  imageData?: string;
}

export interface UseSubmitUserFeedbackModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UseSubmitUserFeedbackActions {
  submitFeedback: () => void;
  reset: () => void;
}

type Props = UseSubmitUserFeedbackProps;
type Model = UseSubmitUserFeedbackModel;
type Actions = UseSubmitUserFeedbackActions;
type Result = [Model, Actions];

export const useSubmitUserFeedback = (props: Props): Result => {

  const {
    message = "",
    imageData = "",
    defaultErrorMessage = "Failed to submit feedback",
    ...otherProps
  } = props;

  const json = React.useMemo(() =>
      JSON.stringify(isEmptyString(imageData) ? {message} : {message, imageData}),
    [imageData, message]);

  const PortalUserFeedbackClient = usePortalUserFeedbackClient();

  const makeApiRequest = React.useCallback(() =>
      PortalUserFeedbackClient.submitUserFeedback(json),
    [PortalUserFeedbackClient, json]);

  const [{ showSuccessView, ...baseModel }, { refresh: submitFeedback, reset }] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Feedback Submitted Successfully" : "" ,
    [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    showSuccessView,
    successMessage,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    submitFeedback,
    reset
  }), [submitFeedback, reset]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useSubmitUserFeedback;
