import React from "react";
import { AuditEvent, AuditEventAttributes } from "@data";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useAuditEventClient,
} from "@hooks";

type SuccessResponse = AuditEventAttributes;

export interface UseAuditEventProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  auditEventId?: string;
}

export interface UseAuditEventModel extends UseApiRequestModel<SuccessResponse> {
  event?: AuditEvent;
}

export interface UseAuditEventActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseAuditEventProps;
type Model = UseAuditEventModel;
type Actions = UseAuditEventActions;
type Result = [Model, Actions];

export const useAuditEvent = (props: Props = {}): Result => {

  const {
    auditEventId = "",
    defaultErrorMessage = "Failed to get audit event details",
    ...otherProps
  } = props;

  const AuditEventClient = useAuditEventClient();

  const makeApiRequest = React.useCallback(() =>
    AuditEventClient.getAuditEvent(auditEventId),
    [AuditEventClient, auditEventId]);

  const [{ successResponse, ...baseModel }, { ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const event = React.useMemo(() => {
    const attrs = successResponse || {};
    return new AuditEvent(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    event,
  }), [
    baseModel,
    event,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
  }), [
    baseActions,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useAuditEvent;
