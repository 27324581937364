import set from "lodash/set";
import { AppSchema } from "../../../main/schemas";
import { identityAction } from "../../../../util/ReducerUtil";
import {
  JsonSchemaDefinition, JsonSchemaMetadata,
  JsonSchemaPropertiesDefinition,
  JsonSchemaProperty,
} from "../../../../data";
import { SchemaWizardActions } from "../schemaWizard";
import { SchemaWizardSelectors } from "../../selectors/schemaWizard";
import { PropertiesTableSelectors } from "../../selectors/propertiesTable";
import { DEFAULT_STATE, PropertiesTableActionType } from "../../reducers/propertiesTable";

export const setBreadcrumbs = identityAction<PropertiesTableActionType, string[]>(
  PropertiesTableActionType.SET_BREADCRUMBS, DEFAULT_STATE.breadcrumbs);

export const clearBreadcrumbs = () => setBreadcrumbs();

export const showChildProperty = (propertyName: string) =>
  (dispatch: any, getState: () => AppSchema) => {

    const breadcrumbs = PropertiesTableSelectors.getBreadcrumbs(getState());

    const updatedBreadcrumbs = breadcrumbs.concat([propertyName]);

    return dispatch(setBreadcrumbs(updatedBreadcrumbs));
  };

export const goBack = () => (dispatch: any, getState: () => AppSchema) => {

  const breadcrumbs = PropertiesTableSelectors.getBreadcrumbs(getState());

  const updatedBreadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1);

  return dispatch(setBreadcrumbs(updatedBreadcrumbs));
};

export const reset = () => (dispatch: any) => {
  return dispatch(setBreadcrumbs());
};

export const updateSchema = (schema: JsonSchemaDefinition) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    if (!PropertiesTableSelectors.isViewingChildProperty(state)) {
      return dispatch(SchemaWizardActions.updateSchema(schema));
    }

    const rootSchema = { ...SchemaWizardSelectors.getSchema(state) };

    const path = PropertiesTableSelectors.getPathToProperties(state);

    const updatedSchema = set(rootSchema, path, schema);

    return dispatch(SchemaWizardActions.updateSchema(updatedSchema));
  };

export const setRequiredProperties = (requiredProperties: string[] = []) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const schema = PropertiesTableSelectors.getSchema(state);

    return dispatch(updateSchema({
      ...schema,
      required: (Array.isArray(requiredProperties) ? requiredProperties : [])
        .map((propertyName: string) => propertyName.trim())
        .filter((propertyName: string) => propertyName.length > 0),
    }));
  };

export const addRequiredProperty = (value: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const propertyName = (value || "").trim();

    const requiredProperties = PropertiesTableSelectors.getRequiredProperties(state);

    const updatedRequiredProperties = requiredProperties.indexOf(propertyName) === -1
      ? requiredProperties.concat(propertyName) : requiredProperties;

    return dispatch(setRequiredProperties(updatedRequiredProperties));
  };

export const removeRequiredProperty = (value: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const requiredPropertyName = (value || "").trim();

    const requiredProperties = PropertiesTableSelectors.getRequiredProperties(state);

    const updatedRequiredProperties = requiredProperties
      .filter((propertyName: string) => propertyName !== requiredPropertyName);

    return dispatch(setRequiredProperties(updatedRequiredProperties));
  };

export const setProperties = (properties: JsonSchemaPropertiesDefinition = {}) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const schema = PropertiesTableSelectors.getSchema(state);

    return dispatch(updateSchema({
      ...schema,
      properties,
    }));
  };

export const updateProperty = (property: JsonSchemaProperty = JsonSchemaProperty.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const properties = PropertiesTableSelectors.getProperties(state);

    const propertyName = property.name.trim();

    const description = property.description.trim();

    if (property.required) {
      dispatch(addRequiredProperty(propertyName));
    } else {
      dispatch(removeRequiredProperty(propertyName));
    }

    const propertyDefaultValue = property.defaultValue;

    const enumValues = property.enumValues;

    const propertyAttributes = {
      ...(properties.hasOwnProperty(propertyName) ? { ...properties[propertyName] } : {}),
      type: property.type,
      $ref: property.reference,
      description: property.description,
      enum: enumValues,
      default: propertyDefaultValue,
      ...(property.hasConstraints() ? property.getValidationData() : {}),
    };

    if (property.isReference()) {
      delete propertyAttributes.type;
    } else {
      delete propertyAttributes.$ref;
    }

    if (description.length === 0) {
      delete propertyAttributes.description;
    }

    if (!property.hasEnumValues()) {
      delete propertyAttributes.enum;
    }

    if (!property.hasDefaultValue()) {
      delete propertyAttributes.default;
    }

    return dispatch(setProperties({
      ...properties,
      [propertyName]: propertyAttributes,
    }));
  };

// Alias to prevent confusion since the setProperty method supports both new and existing properties
export const addProperty = (property?: JsonSchemaProperty) => updateProperty(property);

export const removeProperty = (property: JsonSchemaProperty = JsonSchemaProperty.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const properties = PropertiesTableSelectors.getProperties(state);

    const propertyName = property.getName();

    delete properties[propertyName];

    if (property.required) {
      dispatch(removeRequiredProperty(propertyName));
    }

    return dispatch(setProperties({
      ...properties,
    }));
  };

export const setSelectedSchema = (schemaMetadata: JsonSchemaMetadata = JsonSchemaMetadata.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const schema = PropertiesTableSelectors.getSchema(state);

    return dispatch(updateSchema({
      ...schema,
      $ref: schemaMetadata.getSchemaRef() || "#",
    }));
  };
