import React from "react";
import classnames from "classnames";
import TextField from "@components/text-field";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { enterKeyHandler, formEventHandler, noop } from "@util";
import styles from "./styles";

export interface Model {
  name?: string;
  nameErrorMessage?: string;
  path?: string;
  pathErrorMessage?: string;
  showLoadingIndicator?: boolean;
  saveOnEnter?: boolean;
}

export interface Actions {
  setName?: (name: string) => void;
  setPath?: (path: string) => void;
  save?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SecurityGroupInfo = withStyles(styles)((props: Props) => {

  const {
    classes,
    name = "",
    nameErrorMessage = "",
    path = "",
    pathErrorMessage = "",
    showLoadingIndicator = false,
    saveOnEnter,
    setName = noop,
    setPath = noop,
    save = noop,
  } = props;

  const formHelperTextProps = {
    className: classnames("formHelperText", classes.formHelperText),
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const inputProps = {
    className: classnames("textField", classes.textField),
    spellCheck: false,
  };

  const onEnterPressed = React.useCallback(() => {
    if (saveOnEnter) {
      save();
    }
  }, [saveOnEnter, save]);

  return (
    <div className={classnames("securityGroupInfo", classes.container)}>
      <TextField
        className={classnames("name", classes.textField, classes.name)}
        type="text"
        variant="outlined"
        autoComplete="off"
        margin="none"
        fullWidth={true}
        autoFocus={true}
        label="Security Group Name"
        name="name"
        value={name}
        inputProps={inputProps}
        helperText={nameErrorMessage || "Security group name cannot include spaces or uppercase letters"}
        disabled={showLoadingIndicator}
        placeholder="Name of the security group"
        error={nameErrorMessage.length > 0}
        onChange={formEventHandler(setName)}
        onKeyDown={enterKeyHandler(onEnterPressed)}
        FormHelperTextProps={formHelperTextProps}
        InputLabelProps={inputLabelProps}
      />
      <TextField
        className={classnames("path", classes.textField, classes.path)}
        type="text"
        variant="outlined"
        autoComplete="off"
        margin="none"
        fullWidth={true}
        label="Security Group Path"
        name="path"
        value={path}
        inputProps={inputProps}
        helperText={pathErrorMessage}
        disabled={showLoadingIndicator}
        placeholder="Path of the security group"
        error={pathErrorMessage.length > 0}
        onChange={formEventHandler(setPath)}
        onKeyDown={enterKeyHandler(onEnterPressed)}
        FormHelperTextProps={formHelperTextProps}
        InputLabelProps={inputLabelProps}
      />
    </div>
  );
});

export default SecurityGroupInfo;
