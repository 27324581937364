import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";

export interface Model {
  softwareVersion?: string;
}

export const RemoveSoftwareVersion = withStyles(styles)((props: WithStyles<typeof styles> & Model) => {

  const {
    classes,
    softwareVersion = "",
  } = props;

  const label = !softwareVersion
    ? "Are you sure you want to remove this software version?"
    : `Are you sure you want to remove software version ${softwareVersion}?`;

  return (
    <div className={classnames("removeSoftwareVersion", "content", classes.content)}>
      <label className={classnames("title", classes.title)}>
        {label}
      </label>
    </div>
  );
});

export default RemoveSoftwareVersion;
