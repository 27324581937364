import React from "react";
import { noop } from "@util";
import QueryView from "../components/QueryView";
import { CreateWorkloadRequest } from "@data/CreateWorkloadRequest";
import { useGetQueryDatabases, useGetQueryDatabasesTables } from "@hooks";

interface Model {
  data?: CreateWorkloadRequest;
  databaseName?: string;
}

interface Actions {
  setWorkloadData?: (data: CreateWorkloadRequest) => void;
}

type Props = Model & Actions;

const QueryViewContainer = (props: Props) => {

  const {
    data = CreateWorkloadRequest.EMPTY,
    databaseName = data.getDatabaseName(),
    setWorkloadData = noop,
  } = props;

  const [{ databases = [], showLoadingIndicator: loadingDatabases },
    { refresh: loadDatabases }] = useGetQueryDatabases({});

  const [{ tables = [], showLoadingIndicator: loadingTables },
    { refresh: loadTables }] = useGetQueryDatabasesTables({ databaseName });

  const tableName = React.useMemo(() => data.getTableName(), [data]);

  const dataSources = React.useMemo(() =>
    data.getDataInputs().map(({ dataSetAlias }) => dataSetAlias), [data]);

  return (
    <QueryView
      tables={tables}
      tableName={tableName}
      databases={databases}
      databaseName={databaseName}
      dataSources={dataSources}
      loadingTables={loadingTables}
      loadingDatabases={loadingDatabases}
      loadDatabases={loadDatabases}
      loadTables={loadTables}
      upload={data.isUpload()}
      overwrite={data.isOverwrite()}
      queryType={data.getQueryType()}
      sql={data.getQuery()}
      setQueryData={(attrs) => {
        setWorkloadData({
          query: {
            ...data.toJS().query,
            ...attrs,
          },
        });
      }}
    />
  );
};

export default QueryViewContainer;
