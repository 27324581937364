import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";

export interface ProfileAttributeDefinitionSchema extends BasePortalModuleSchema {
  nameFilter: string;
}

export const DEFAULT_STATE: ProfileAttributeDefinitionSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  nameFilter: "",
});

export type ProfileAttributeDefinitionActionTypes = PortalModuleActionTypes<ProfileAttributeDefinitionSchema>;

export const ACTION_TYPES: ProfileAttributeDefinitionActionTypes =
  createPortalModuleActionTypes<ProfileAttributeDefinitionSchema>(MODULE_ID, DEFAULT_STATE);

export const ProfileAttributeDefinitionReducer = combineReducers<ProfileAttributeDefinitionSchema>(
  createPortalModuleReducers<ProfileAttributeDefinitionSchema>(ACTION_TYPES, DEFAULT_STATE));

export default ProfileAttributeDefinitionReducer;
