import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks";
import { EmailTemplate, EmailTemplateAttributes } from "@data";
import useEmailTemplateClient from "@hooks/emailTemplates/use-email-template-client";

type SuccessResponse = EmailTemplateAttributes[];

export interface UseEmailTemplatesProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  accountId?: string;
}

export interface UseEmailTemplatesModel extends UseApiRequestModel<SuccessResponse> {
  emailTemplates?: EmailTemplate[];
}

export interface UseEmailTemplatesActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseEmailTemplatesProps;
type Model = UseEmailTemplatesModel;
type Actions = UseEmailTemplatesActions;
type Result = [Model, Actions];

export const useEmailTemplates = (props: Props = {}): Result => {

  const {
    accountId = "",
    defaultErrorMessage = "Failed to get email templates",
    ...otherProps
  } = props;

  const EmailTemplateClient = useEmailTemplateClient();

  const makeApiRequest = React.useCallback(
    () => EmailTemplateClient.getEmailTemplates(accountId),
    [EmailTemplateClient, accountId]);

  const [{successResponse, ...baseModel}, { ...baseActions} ] =
    useApiRequest<SuccessResponse>({
    ...otherProps,
    defaultErrorMessage,
    makeApiRequest,
  });

  const emailTemplates = React.useMemo(() => {
    const items = successResponse || [];
    return items.map(attrs => new EmailTemplate(attrs));
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    emailTemplates,
  }), [
    baseModel,
    emailTemplates,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
  }), [
    baseActions,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEmailTemplates;
