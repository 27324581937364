import React from "react";
import classnames from "classnames";
import debounce from "lodash/debounce";
import { KEY_LAST_MOUSE_MOVE } from "@store/constants";
import { isValidNumber, noop, onLocalStorageUpdated, onMouseMove } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

// Users will be logged out if they have not moved the mouse in the last 10 minutes
export const DEFAULT_MAX_ALLOWED_IDLE_TIME_MS = (1000 * 60) * 10;

export interface IdleSessionListenerModel {
  loggedIn?: boolean;
  maxAllowedIdleTimeMs?: number;
  lastMouseMove?: number;
}

export interface IdleSessionListenerActions {
  getLastPersistedMouseMove?: () => number;
  updateLastMouseMove?: (lastMouseMove: number) => void;
  logoutDueToInactivity?: () => void;
}

type Model = IdleSessionListenerModel;
type Actions = IdleSessionListenerActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const IdleSessionListener = withStyles(styles)((props: Props) => {

  const {
    classes,
    loggedIn,
    lastMouseMove = 0,
    maxAllowedIdleTimeMs = DEFAULT_MAX_ALLOWED_IDLE_TIME_MS,
    getLastPersistedMouseMove = React.useCallback(() => lastMouseMove, [lastMouseMove]),
    updateLastMouseMove = noop,
    logoutDueToInactivity = noop,
    children,
  } = props;

  const [logoutAsInactive, setLogoutAsInactive] = React.useState(false);

  const [lastPersistedMouseMove, setLastPersistedMouseMove] = React.useState(getLastPersistedMouseMove());

  const mostRecentMouseMove = React.useMemo(() =>
      Math.max(lastMouseMove, lastPersistedMouseMove),
    [lastMouseMove, lastPersistedMouseMove]);

  const timeElapsedSinceActive = React.useMemo(() =>
    Date.now() - mostRecentMouseMove, [mostRecentMouseMove]);

  const inactivityCountdown = React.useMemo(() =>
    maxAllowedIdleTimeMs - timeElapsedSinceActive, [maxAllowedIdleTimeMs, timeElapsedSinceActive]);

  React.useEffect(() => {
    if (!logoutAsInactive && loggedIn && (lastMouseMove !== mostRecentMouseMove)) {
      updateLastMouseMove(mostRecentMouseMove);
    }
  }, [logoutAsInactive, loggedIn, lastMouseMove, mostRecentMouseMove, updateLastMouseMove]);

  React.useEffect(() => {
    if (!logoutAsInactive && loggedIn && (timeElapsedSinceActive >= maxAllowedIdleTimeMs)) {
      setLogoutAsInactive(true);
    }
  }, [logoutAsInactive, loggedIn, timeElapsedSinceActive, maxAllowedIdleTimeMs, setLogoutAsInactive]);

  React.useEffect(() => {

    if (logoutAsInactive || !loggedIn) {
      return noop;
    }

    let ignore = false;

    const listener = debounce(() => {
      if (!ignore) {
        const currentTime = Date.now();
        setLastPersistedMouseMove(currentTime);
        updateLastMouseMove(currentTime);
      }
    }, 500);

    const unsubscribe = onMouseMove(listener);

    return () => {
      ignore = true;
      unsubscribe();
    };

  }, [
    logoutAsInactive,
    loggedIn,
    setLastPersistedMouseMove,
    updateLastMouseMove,
  ]);

  React.useEffect(() => {

    if (logoutAsInactive || !loggedIn) {
      return noop;
    }

    const timer = setTimeout(() => {
      setLogoutAsInactive(true);
    }, inactivityCountdown);

    return () => {
      clearTimeout(timer);
    };

  }, [
    logoutAsInactive,
    loggedIn,
    inactivityCountdown,
    setLogoutAsInactive,
  ]);

  React.useEffect(() => {

    if (logoutAsInactive || !loggedIn) {
      return noop;
    }

    const listener = (event: StorageEvent) => {

      const { key = "", newValue = "" } = event;

      if (key === KEY_LAST_MOUSE_MOVE && isValidNumber(newValue)) {
        setLastPersistedMouseMove(Number(newValue));
      }
    };

    const unsubscribe = onLocalStorageUpdated(listener);

    return () => {
      unsubscribe();
    };

  }, [
    logoutAsInactive,
    loggedIn,
    setLastPersistedMouseMove,
  ]);

  React.useEffect(() => {
    if (logoutAsInactive && loggedIn) {
      logoutDueToInactivity();
    }
  }, [logoutAsInactive, loggedIn, logoutDueToInactivity]);

  return (
    <div className={classnames("idleSessionListener", classes.container)}>
      {children}
    </div>
  );
});

export default IdleSessionListener;
