import { Record } from "immutable";
import { equalsIgnoreCase, getStringValue } from "@util";
import { AccessRequestPrincipalType, AccessRequestType } from "@data/DataAccessRequest";

export interface DataSetAccessRequestAttributes {
  accountId: string;
  dataSetAlias: string;
  accessRequestType: AccessRequestType;
  accessRequestPrincipalType: AccessRequestPrincipalType;
  principalIdentifier: string;
  requestedBy: string;
  requestedAtTime: string;
}

export class DataSetAccessRequest extends Record ({
  accountId: "",
  dataSetAlias: "",
  accessRequestType: AccessRequestType.NONE,
  accessRequestPrincipalType: AccessRequestPrincipalType.IOT_USER_PRINCIPAL,
  principalIdentifier: "",
  requestedBy: "",
  requestedAtTime: "",
}) implements DataSetAccessRequestAttributes {

  public static EMPTY: DataSetAccessRequest = new DataSetAccessRequest();

  public readonly accessRequestPrincipalType: AccessRequestPrincipalType;
  public readonly accessRequestType: AccessRequestType;
  public readonly accountId: string;
  public readonly dataSetAlias: string;
  public readonly principalIdentifier: string;
  public readonly requestedAtTime: string;
  public readonly requestedBy: string;

  public equals(other: DataSetAccessRequest): boolean {
    return equalsIgnoreCase(this.getAccountId(), other.getAccountId()) &&
      equalsIgnoreCase(this.getDataSetAlias(), other.getDataSetAlias()) &&
      equalsIgnoreCase(this.getPrincipalIdentifier(), other.getPrincipalIdentifier());
  }

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getDataSetAlias(): string {
    return getStringValue(this.dataSetAlias);
  }

  public getPrincipalIdentifier(): string {
    return getStringValue(this.principalIdentifier);
  }

  public getRequestType(): AccessRequestType {
    return this.accessRequestType;
  }

  public getPrincipalType(): AccessRequestPrincipalType {
    return this.accessRequestPrincipalType;
  }

  public revokeAccessDisabled(): boolean {
    return this.accessRequestPrincipalType === AccessRequestPrincipalType.IOT_DEVICE_TYPE ||
      this.accessRequestPrincipalType === AccessRequestPrincipalType.IOT_CLOUD_STORAGE;
  }

}

export default DataSetAccessRequest;