import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { batches as styles } from "./styles";
import { Search } from "@components";

export interface Model {
  title?: string;
  searchQuery?: string;
  loading?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  updateSearchQuery?: (searchQuery: string) => void;
  refresh?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const EnrollmentHistoryListView = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Enrollment History",
    searchQuery = "",
    updateSearchQuery,
    children,
  } = props;

  return (
    <div className={classnames("enrollmentHistory", classes.container)}>
      <div className={classnames("header", classes.header)}>
        <h1 className={classnames("historyTitle", classes.title)}>
          {title}
        </h1>
      </div>
      <Search
        className={classes.search}
        hint="Filter results"
        value={searchQuery}
        updateSearchQuery={updateSearchQuery}
      />
      {children}
    </div>
  );
});

export default EnrollmentHistoryListView;
