import { AppSchema } from "@schemas";
import { createSelector } from "reselect";
import { Dashboard, DashboardAttributes } from "@data";
import { DashboardColumn, DashboardsSchema } from "./reducers";
import { createComparator, getSearchResults } from "./helpers";
import { Comparator, isEmptyString, withModuleSelector } from "@util";
import { SCHEMA_KEY as DASHBOARDS_SCHEMA_KEY } from "../dashboards/constants";

const createModuleSelector = <T>(key: keyof DashboardsSchema) => withModuleSelector(DASHBOARDS_SCHEMA_KEY, key);
export const getSelectedTab = createModuleSelector<string>("selectedTab");
export const getItemAttributes = createModuleSelector<DashboardAttributes[]>("items");
export const getSearchQuery = createModuleSelector<string>("searchQuery");
export const getSortedColumn = createModuleSelector<DashboardColumn>("sortedColumn");
export const isSortOrderAscending = createModuleSelector<boolean>("sortOrderAscending");
export const getErrorMessage = createModuleSelector<string>("errorMessage");
export const isEmptyViewVisible = createModuleSelector<boolean>("showEmptyView");
export const isAccessDeniedVisible = createModuleSelector<boolean>("showAccessDenied");
export const isLoadingIndicatorVisible = createModuleSelector<boolean>("showLoadingIndicator");

const getComparator: (state: AppSchema) => Comparator<Dashboard> = createSelector(
  [getSortedColumn, isSortOrderAscending], createComparator);

export const getItems: (state: AppSchema) => Dashboard[] = createSelector(
  [getItemAttributes, getComparator],
  (items: DashboardAttributes[], comparator: Comparator<Dashboard>) => {

    const dataSets = items.map((attrs: DashboardAttributes) => new Dashboard(attrs));
    dataSets.sort(comparator);
    return dataSets;
  });

export const getDashboards: (state: AppSchema) => Dashboard[] = createSelector(
  [getItems, getSearchQuery], getSearchResults);

export const getPublishedDashboards: (state: AppSchema) => Dashboard[] = createSelector(
  [getDashboards, getSearchQuery], (items: Dashboard[], nameFilter: string) => getSearchResults(
    items.filter(item => item.isPublished()),
    nameFilter,
  ));

export const getUnpublishedDashboards: (state: AppSchema) => Dashboard[] = createSelector(
  [getDashboards, getSearchQuery], (items: Dashboard[], nameFilter: string) => getSearchResults(
    items.filter(item => !item.isPublished()),
    nameFilter,
  ));

export const isErrorViewVisible: (state: AppSchema) => boolean = createSelector(
  getErrorMessage, (errorMessage: string) => !isEmptyString(errorMessage));

export const isLoadingViewVisible: (state: AppSchema) => boolean = createSelector(
  [isLoadingIndicatorVisible, isErrorViewVisible],
  (isLoading: boolean, showErrorView: boolean) =>
    isLoading && !showErrorView);
