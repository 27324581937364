import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { SummaryViewLabel } from "@components/summary-view-label";
import { summaryView as styles } from "./styles";
import { UserStatus } from "@data";

export interface Model {
  userId?: string;
  status?: UserStatus;
  anonymousId?: string;
  createdAt?: string;
  children?: React.ReactNode;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SummaryView = withStyles(styles)((props: Props) => {

  const {
    classes,
    userId,
    status,
    anonymousId,
    createdAt,
    children,
  } = props;

  return (
    <div className={classnames("summaryView", classes.container)}>
      <SummaryViewLabel
        className={classnames("userId", classes.summaryViewLabel)}
        name="User ID"
        value={userId}
      />
      <SummaryViewLabel
        className={classnames("status", classes.summaryViewLabel)}
        name="Status"
        value={status}
      />
      <SummaryViewLabel
        className={classnames("anonymousId", classes.summaryViewLabel)}
        name="Anonymous ID"
        value={anonymousId}
      />
      <SummaryViewLabel
        className={classnames("createdAt", classes.summaryViewLabel)}
        name="Created At"
        value={createdAt}
      />
      <div className={classnames("content", classes.content)}>
        {children}
      </div>
    </div>
  );
});

export default SummaryView;
