import React from "react";
import { Module } from "@data";
import { MODULE_ID } from "./constants";
import { isOpen as isDialogOpen } from "./selectors";
import { initialize, open } from "./actions";
import { PrimaryIcon } from "../users/components/styles";
import ShareDashboardDialog from "./containers/ShareDashboardDialog";

export const shareDashboardModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "Share Dashboard",
  title: "Share Dashboard",
  icon: PrimaryIcon,
  view: <ShareDashboardDialog />,
  onClick: open,
  isDialogOpen,
  initialize,
};

export default shareDashboardModule;
