import React from "react";
import classnames from "classnames";
import CreateAdminNote from "@components/create-admin-note";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, { PortalModuleDialogModel, PortalModuleDialogActions } from "@components/portal-module-dialog";
import styles from "./styles";
import { isEmptyString, noop } from "@util";

export interface CreateAdminNoteDialogModel<Item> extends PortalModuleDialogModel<Item> {
  userId?: string;
  note?: string;
  placeholder?: string;
}

export interface CreateAdminNoteDialogActions<Item> extends PortalModuleDialogActions<Item> {
  trackCreateAdminNoteRequest?: () => void;
  trackCreateAdminNoteSuccess?: () => void;
  trackCreateAdminNoteFailed?: (analytic: string) => void;
}

type Model<Item> = CreateAdminNoteDialogModel<Item>;
type Actions<Item> = CreateAdminNoteDialogActions<Item>;
type Props<Item> = WithStyles<typeof styles> & Model<Item> & Actions<Item> & {
  children?: React.ReactNode;
};

export const CreateAdminNoteDialog = withStyles(styles)(<Item, >(props: Props<Item>) => {

  const {
    classes,
    className,
    userId,
    note,
    placeholder,
    accessDenied,
    errorMessage,
    hideCancelButton: defaultHideCancelButton,
    continueButtonLabel: defaultContinueButtonLabel,
    successMessage: parentSuccessMessage,
    loading: showParentLoadingIndicator,
    trackCreateAdminNoteRequest,
    trackCreateAdminNoteSuccess,
    trackCreateAdminNoteFailed,
    confirm: parentConfirm = noop,
    children,
    ...otherProps
  } = props;

  const [showAdminNoteLoadingIndicator, setShowAdminNoteLoadingIndicator] = React.useState(false);
  const [adminNoteSuccessMessage, setAdminNoteSuccessMessage] = React.useState("");
  const [omitAdminNote, setOmitAdminNote] = React.useState(false);

  const loading = React.useMemo(() =>
    showParentLoadingIndicator || showAdminNoteLoadingIndicator,
    [showParentLoadingIndicator, showAdminNoteLoadingIndicator]);

  const successMessage = React.useMemo(() =>
    !isEmptyString(parentSuccessMessage) ? adminNoteSuccessMessage : "",
    [parentSuccessMessage, adminNoteSuccessMessage]);

  const confirm = React.useCallback((item: string) => {
    if (isEmptyString(parentSuccessMessage)) {
      parentConfirm(item);
    } else {
      setAdminNoteSuccessMessage(parentSuccessMessage as string);
    }
  }, [
    parentSuccessMessage,
    parentConfirm,
    setAdminNoteSuccessMessage,
  ]);

  const cancelButtonHidden = React.useMemo(() =>
    isEmptyString(parentSuccessMessage) ? defaultHideCancelButton : true,
    [parentSuccessMessage, defaultHideCancelButton]);

  const continueButtonLabel = React.useMemo(() =>
    isEmptyString(parentSuccessMessage) ? defaultContinueButtonLabel : "DONE",
    [parentSuccessMessage, defaultContinueButtonLabel]);

  React.useEffect(() => {
    // The create note request should only be made if the note is not empty
    if (omitAdminNote) {
      setAdminNoteSuccessMessage(parentSuccessMessage as string);
    }
  }, [omitAdminNote, parentSuccessMessage, setAdminNoteSuccessMessage]);

  React.useEffect(() => {
    if (!isEmptyString(parentSuccessMessage) && isEmptyString(adminNoteSuccessMessage)) {
      setShowAdminNoteLoadingIndicator(true);
    }
  }, [
    parentSuccessMessage,
    adminNoteSuccessMessage,
    setShowAdminNoteLoadingIndicator,
  ]);

  return (
    <PortalModuleDialog
      {...otherProps}
      className={classnames("createAdminNoteDialog", className, classes.container)}
      loading={loading}
      accessDenied={accessDenied}
      errorMessage={errorMessage}
      successMessage={successMessage}
      hideCancelButton={cancelButtonHidden}
      continueButtonLabel={continueButtonLabel}
      confirm={confirm}
    >
      <CreateAdminNote
        className={classnames("createAdminNote", classes.createAdminNote)}
        userId={userId}
        note={note}
        placeholder={placeholder}
        disabled={loading}
        setOmitAdminNote={setOmitAdminNote}
        showLoadingIndicator={showAdminNoteLoadingIndicator}
        setSuccessMessage={setAdminNoteSuccessMessage}
        setShowLoadingIndicator={setShowAdminNoteLoadingIndicator}
        trackRequestEvent={trackCreateAdminNoteRequest}
        trackSuccessEvent={trackCreateAdminNoteSuccess}
        trackErrorEvent={trackCreateAdminNoteFailed}
      >
        {children}
      </CreateAdminNote>
    </PortalModuleDialog>
  );
});

export default CreateAdminNoteDialog;
