import React from "react";
import classnames from "classnames";
import { clickHandler, noop } from "@util";
import Button, { ButtonProps } from "@components/button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface CancelButtonProps extends ButtonProps {
  label?: string;
  uppercase?: boolean;
  onClick?: () => void;
}

type Props = WithStyles<typeof styles> & CancelButtonProps;

export const CancelButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    label = "Cancel",
    uppercase,
    onClick = noop,
    fullWidth = false,
    ...otherProps
  } = props;

  const { children = label } = otherProps;

  return (
    <Button
      {...otherProps}
      className={classnames("cancelButton", className, classes.button)}
      classes={{
        label: classnames("label", classes.label, {
          [classes.uppercase]: uppercase,
        }),
      }}
      fullWidth={fullWidth}
      onClick={clickHandler(onClick)}
    >
      {children}
    </Button>
  );
});

export default CancelButton;
