import { Module } from "@data";
import { equalsIgnoreCase } from "@util";
import { initialize, onMount } from "./actions";
import { createListViewModule } from "@base/createModule";
import { PrimaryIcon as icon } from "./components/styles";
import { LEGACY_MODULE_PATH, MODULE_ID, MODULE_PATH } from "./constants";
import view from "./DataAccessRequestsView";

export const dataAccessRequestsModule: Module = createListViewModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  legacyPath: LEGACY_MODULE_PATH,
  name: "Data Access Requests",
  title: "Data Access Requests",
  className: "dataAccessRequestsModule",
  icon,
  view,
  initialize,
  onMount,
  disabled: !equalsIgnoreCase("true", process.env.REACT_APP_DATA_GOVERNANCE_MODULES_ENABLED),
});

export default dataAccessRequestsModule;
