import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import FiltersMenu from "@components/filters-menu";
import { PopoverOrigin } from "@material-ui/core/Popover";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DataLakeFileBrowserFilters, {
  DataLakeFileBrowserFiltersActions,
  DataLakeFileBrowserFiltersModel,
} from "../DataLakeFileBrowserFilters";
import styles from "./styles";

const DEFAULT_ANCHOR_ORIGIN: PopoverOrigin = {
  horizontal: "right",
  vertical: "top",
};

const DEFAULT_TRANSFORM_ORIGIN: PopoverOrigin = {
  horizontal: "left",
  vertical: "top",
};

export interface DataLakeFileBrowserFiltersMenuModel extends DataLakeFileBrowserFiltersModel {
  className?: string;
  disabled?: boolean;
  open?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export interface DataLakeFileBrowserFiltersMenuActions extends DataLakeFileBrowserFiltersActions {
  openMenu?: () => void;
  closeMenu?: () => void;
}

type Model = DataLakeFileBrowserFiltersMenuModel;
type Actions = DataLakeFileBrowserFiltersMenuActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataLakeFileBrowserFiltersMenu = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    disabled,
    open = false,
    anchorOrigin = DEFAULT_ANCHOR_ORIGIN,
    transformOrigin = DEFAULT_TRANSFORM_ORIGIN,
    openMenu = noop,
    closeMenu = noop,
    children,
    ...otherProps
  } = props;

  return (
    <FiltersMenu
      className={classnames("dataLakeFileBrowserFiltersMenu", className, classes.container)}
      buttonClassName={classnames("filtersMenuIconButton", classes.filtersMenuIconButton)}
      iconClassName={classnames("filtersMenuIcon", classes.filtersMenuIcon)}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      open={open}
      disabled={disabled}
      openMenu={openMenu}
      closeMenu={closeMenu}
    >
      <DataLakeFileBrowserFilters
        {...otherProps}
        className={classnames("filters", classes.filters)}
        disabled={disabled}
      />
      {children}
    </FiltersMenu>
  );
});

export default DataLakeFileBrowserFiltersMenu;
