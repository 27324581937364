import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getPathToShadowLayout } from "@modules/digitalShadowLayoutDetails/helpers";
import DigitalShadowLayoutWizard from "./containers/DigitalShadowLayoutWizard";
import { Location } from "history";

type LocationProps = {
  from?: Location;
  goBackOnSuccess?: boolean;
};

export function EditDigitalShadowLayoutView() {

  const history = useHistory();

  const { id: layoutName } = useParams<{ id: string}>();

  const { state: { from = undefined, goBackOnSuccess = false} = {} } = useLocation<LocationProps>();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onSuccess = React.useCallback(() => {
    if (goBackOnSuccess) {
      history.goBack();
    } else if (from) {
      history.replace(from);
    } else {
      history.replace(getPathToShadowLayout(layoutName));
    }
  }, [history, from, goBackOnSuccess, layoutName]);

  return (
    <DigitalShadowLayoutWizard
      key={layoutName}
      cancel={cancel}
      onEditSuccess={onSuccess}
    />
  );
}

export default EditDigitalShadowLayoutView;
