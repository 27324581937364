import { isEmptyString } from "@util";
import { createSelector } from "reselect";
import { DEFAULT_STATE, DeleteDataSetRequestSchema } from "./reducers";
import { createSelectors, Selector } from "@base/createSelectors";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";

export const {
  accountId: getAccountId,
  dataSetAlias: getDataSetAlias,
  open: isDialogOpen,
  getErrorMessage,
  getSuccessMessage,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  getAccessToken,
} = createSelectors<DeleteDataSetRequestSchema>(moduleId, schemaKey, DEFAULT_STATE);

export const isDataSetValid: Selector<boolean> = createSelector(
  [getAccountId, getDataSetAlias],
  (accountId, dataSetAlias) => !isEmptyString(accountId) && !isEmptyString(dataSetAlias));
