import { Module } from "@data";
import view from "./containers/NamespaceDetails";
import { createModule } from "@base/createModule";
import { initialize } from "@modules/namespaceDetails/actions";
import { isCommonServicesModulesDisabled, isEmptyString } from "@util";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { MODULE_ID, MODULE_PATH, VIEW_NAMESPACE_JSON_REDIRECT } from "./constants";

type RouteParams = { id?: string; };

export const namespaceDetailsModule: Module = createModule({
    id: MODULE_ID,
    path: MODULE_PATH,
    exactPath: false,
    strictPath: false,
    name: "Namespace Details",
    title: "Namespace Details",
    className: "namespaceDetailsModule",
    primaryColor,
    icon,
    view,
    onMount: ({ id = "" }: RouteParams = {}) => initialize(id),
    redirects: {
        [VIEW_NAMESPACE_JSON_REDIRECT]: (action, namespace, extraQueryParams) =>
            isEmptyString(namespace) ? null : `${namespace}/json?${extraQueryParams}`,
    },
    disabled: isCommonServicesModulesDisabled(),
});

export default namespaceDetailsModule;
