import React from "react";
import classnames from "classnames";
import camelCase from "lodash/camelCase";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { summaryView as styles } from "./styles";
import { SummaryViewData } from "@data";
import SummaryViewLabel , { SummaryViewDateLabel } from "@components/summary-view-label";
import { isEmptyString } from "@util";

export interface SummaryViewModel {
  items?: SummaryViewData[];
  className?: string;
  contentClassName?: string;
  children?: React.ReactNode;
  title?: string;
}

type Props = WithStyles<typeof styles> & SummaryViewModel;

export const SummaryView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className = "",
    contentClassName,
    items = [],
    children,
    title,
  } = props;

  return (
    <div className={classnames("summaryView", className, classes.container)}>
      {title && <label className={classnames("title", classes.title)}>{title}</label>}
      {items.map((item: SummaryViewData) => isEmptyString(item.getValue()) ? null : !item.isDate() ? (
        <SummaryViewLabel
          key={`summary-view-label-${camelCase(item.getName())}`}
          className={item.getClassName()}
          name={item.getName()}
          value={item.getValue()}
        />
      ) : (
        <SummaryViewDateLabel
          key={`summary-view-date-label-${camelCase(item.getName())}`}
          className={item.getClassName()}
          name={item.getName()}
          value={item.getValue()}
          formatDatePattern={item.getFormatDatePattern()}
        />
      ))}
      <div className={classnames("content", contentClassName, classes.content)}>
        {children}
      </div>
    </div>
  );
});

export default SummaryView;
