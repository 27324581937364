import React from "react";
import { Record } from "immutable";
import { ComparableItem } from "@components";

export interface ColumnAttributes<Item extends ComparableItem<Item>> {
  className: string;
  value: string;
  column: string;
  chipTags: string[];
  firstColumn: boolean;
  checkmark: boolean;
  isDate: boolean;
  customEl: null | React.ReactNode;
  formatDatePattern: string;
}

export const DEFAULT_COLUMN_ATTRIBUTES: ColumnAttributes<any> = {
  className: "",
  value: "",
  column: "",
  chipTags: [],
  firstColumn: false,
  checkmark: false,
  isDate: false,
  customEl: null,
  formatDatePattern: "LLL",
};

export class ModuleListColumn extends Record(DEFAULT_COLUMN_ATTRIBUTES) implements ColumnAttributes<any> {

  public static EMPTY: ModuleListColumn = new ModuleListColumn();

  public readonly className: string;
  public readonly value: string;
  public readonly column: string;
  public readonly chipTags: string[];
  public readonly firstColumn: boolean;
  public readonly checkmark: boolean;
  public readonly isDate: boolean;
  public readonly customEl: null | React.ReactNode;
  public readonly formatDatePattern: string;
}

export default ColumnAttributes;
