import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () => createStyles({
  container: {
    marginBottom: "1em",
    fontSize: 14,
    "& svg": {
      color: Color.BLACK3,
    },
    "& ul": {
      margin: 0,
    },
    "& p": {
      fontSize: 14,
      fontWeight: 400,
    },
    "& div.MuiAlert-icon": {
      flexDirection: "column",
      justifyContent: "space-around",
    }
  },
});

export default styles;
