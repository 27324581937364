import React from "react";
import { noop } from "@util";
import { EmailTemplate, EmailTemplateMessageType } from "@data";
import { ActionMenuItem } from "@components/actions-menu";
import { EmailTemplateAction } from "@components/email-template-actions-menu";
import {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import EmailTemplateListColumn from "./EmailTemplateListColumn";
import { sortAndFilterEmailTemplateResults } from "./helpers";
import { EmailTemplateIcon } from "./styles";
import ModuleListView from "@components/module-list-view";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import { getPathToEmailTemplate } from "@modules/emailTemplateDetails/helpers";
import DeleteEmailTemplateDialog from "@components/delete-email-template-dialog";
import { mapMessageTypeToOptionName } from "@modules/emailTemplateWizard/components/TemplateView";

export const DEFAULT_EMAIL_TEMPLATE_LIST_COLUMNS: EmailTemplateListColumn[] = [
  EmailTemplateListColumn.ID,
  EmailTemplateListColumn.APPLICATION_ID,
  EmailTemplateListColumn.LANGUAGE,
  EmailTemplateListColumn.MESSAGE_TYPE,
  EmailTemplateListColumn.SUBJECT
];

export interface EmailTemplatesListModel extends SortedSearchResultsListModel<EmailTemplate, EmailTemplateListColumn> {
  accountId?: string;
}

export interface EmailTemplatesListActions
  extends SortedSearchResultsListActions<EmailTemplate, EmailTemplateListColumn> {

  editEmailTemplate?: (emailTemplate: EmailTemplate) => void;
  onDeleteSuccess?: () => void;
}

type Props = EmailTemplatesListModel & EmailTemplatesListActions;

export const EmailTemplatesList = (props: Props) => {

  const {
    className = "emailTemplates",
    listClassName = "emailTemplatesList",
    columns = DEFAULT_EMAIL_TEMPLATE_LIST_COLUMNS,
    sortByColumn = EmailTemplateListColumn.ID,
    sortOrderAscending = true,
    tableLayoutFixed = false,
    nameFilterDelay = 25,
    noResultsLabel = "No Email Templates found",
    summaryViewQuantities = {
      other: "email templates",
      one: "email template",
    },
    items = [],
    nameFilter = "",
    accountId = "",
    editEmailTemplate = noop,
    onClickAction = noop,
    onDeleteSuccess,
    ...otherProps
  } = props;

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);
  const [templateId, setTemplateId] = React.useState<string>("");

  const deleteEmailTemplate = React.useCallback((emailTemplate: EmailTemplate) => {
    setTemplateId(emailTemplate.getId());
    setOpenDeleteDialog(true);
  }, [setTemplateId, setOpenDeleteDialog]);

  const closeDeleteDialog = React.useCallback(() => {
    setOpenDeleteDialog(false);
  }, [setOpenDeleteDialog]);

  const onDeleteEmailSuccess = React.useCallback(() => {
    if (onDeleteSuccess) {
      onDeleteSuccess();
    }
    closeDeleteDialog();
  }, [onDeleteSuccess, closeDeleteDialog]);

  const onActionClicked = React.useCallback((emailTemplate: EmailTemplate, action: ActionMenuItem) => {
    switch (action.id) {
      case EmailTemplateAction.EDIT_EMAIL_TEMPLATE:
        return editEmailTemplate(emailTemplate);
      case EmailTemplateAction.DELETE_EMAIL_TEMPLATE:
        return deleteEmailTemplate(emailTemplate);
      default:
        return onClickAction(emailTemplate, action);

    }
  }, [editEmailTemplate, deleteEmailTemplate, onClickAction]);

  const emailTemplates = sortAndFilterEmailTemplateResults({ items, nameFilter, sortOrderAscending, sortByColumn });

  const moduleListItems: ListViewItem<EmailTemplate>[] = React.useMemo(() =>
    emailTemplates.map(email => {
      return {
        item: email,
        pathToDetailsView: getPathToEmailTemplate(email.getId()),
        icon: EmailTemplateIcon,
        columnAttributes: createColumns([
          {
            className: "emailTemplateId",
            value: email.getId(),
            column: EmailTemplateListColumn.ID,
            firstColumn: true,
          },
          {
            className: "emailAppId",
            value: email.getApplicationId(),
            column: EmailTemplateListColumn.APPLICATION_ID,
          },
          {
            className: "emailLanguage",
            value: email.getLanguage(),
            column: EmailTemplateListColumn.LANGUAGE,
          },
          {
            className: "emailType",
            value: mapMessageTypeToOptionName(email.getMessageType() as EmailTemplateMessageType),
            column: EmailTemplateListColumn.MESSAGE_TYPE,
          },
          {
            className: "emailSubject",
            value: email.getEmailSubject(),
            column: EmailTemplateListColumn.SUBJECT,
          },
        ])
      };
    }), [emailTemplates]);

  return (
    <React.Fragment>
      <ModuleListView
        {...otherProps}
        className={className}
        listViewItems={moduleListItems}
        columns={columns}
        nameFilter={nameFilter}
        nameFilterDelay={nameFilterDelay}
        tableLayoutFixed={tableLayoutFixed}
        sortByColumn={sortByColumn}
        sortOrderAscending={sortOrderAscending}
        noResultsLabel={noResultsLabel}
        summaryViewIcon={EmailTemplateIcon}
        summaryViewQuantities={summaryViewQuantities}
        onClickAction={onActionClicked}
      />
      <DeleteEmailTemplateDialog
        open={openDeleteDialog}
        cancel={closeDeleteDialog}
        accountId={accountId}
        templateId={templateId}
        onSuccessMessageShown={onDeleteEmailSuccess}
      />
    </React.Fragment>
  );
};

export default EmailTemplatesList;
