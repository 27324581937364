import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { FederationProviderWizardSelectors } from "../selectors";
import ReviewView, { Actions, Model } from "../components/ReviewView";

type Props = Model & Actions;

const mapStateToProps = (state: AppSchema, ownProps: Props): Model => ({
  domain: FederationProviderWizardSelectors.getDomain(state),
  clientId: FederationProviderWizardSelectors.getClientId(state),
  clientSecret: FederationProviderWizardSelectors.getClientSecret(state),
  scope: FederationProviderWizardSelectors.getScopeAsJson(state),
  authorizationEndpoint: FederationProviderWizardSelectors.getAuthorizationEndpoint(state),
  discoveryDocument: FederationProviderWizardSelectors.getDiscoveryDocument(state),
  tokenEndpoint: FederationProviderWizardSelectors.getTokenEndpoint(state),
  userEndpoint: FederationProviderWizardSelectors.getUserEndpoint(state),
  jwksUri: FederationProviderWizardSelectors.getJwksUri(state),
  issuer: FederationProviderWizardSelectors.getIssuer(state),
  federationClosed: FederationProviderWizardSelectors.isFederationClosed(state) + "",
  attributes: FederationProviderWizardSelectors.getAttributes(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(ReviewView);
