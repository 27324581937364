import React from "react";
import classnames from "classnames";
import DefaultIcon from "@material-ui/icons/Info";
import { getPluralString, Quantities } from "@util";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface SummaryProps {
  className?: string;
  color?: string;
  label?: string;
  showIcon?: boolean;
  icon?: React.ComponentType<SvgIconProps>;
  iconStyle?: React.CSSProperties;
  applyMinWidth?: boolean;
  children?: React.ReactNode;
}

type Props = WithStyles<typeof styles> & SummaryProps;

export const Summary = React.memo(withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    color,
    label,
    showIcon = true,
    applyMinWidth,
    children,
    icon: Icon = DefaultIcon,
  } = props;

  const ref = React.useRef<HTMLDivElement>(null);

  const [minWidth, setMinWidth] = React.useState(0);

  React.useLayoutEffect(() => {

    if (applyMinWidth && ref && ref.current) {

      const { width } = ref.current.getBoundingClientRect();

      if (width > minWidth) {
        setMinWidth(width);
      }
    }
  }, [applyMinWidth, ref, minWidth, setMinWidth, label]);

  const style = React.useMemo(() =>
    applyMinWidth && minWidth > 0 ? ({ minWidth }) : undefined,
    [applyMinWidth, minWidth]);

  return (
    <div
      ref={ref}
      className={classnames("summary", className, classes.container)}
      style={style}
    >
      {showIcon && <Icon className={classes.icon} htmlColor={color} />}
      <label className={classes.label} style={{ color }}>{label}</label>
      {children}
    </div>
  );
}));

export const withQuantity =
  (quantities: Quantities): React.ComponentType<SummaryProps & { quantity?: number }> =>
    (props: SummaryProps & { quantity?: number }) => {
      const { quantity = 0, label = getPluralString(quantity, quantities), ...otherProps } = props;
      return (
        <Summary {...otherProps} label={label} />
      );
    };

export default Summary;
