import { isEmptyString } from "@util";
import { DeviceConfigurationAttributes } from "@data";
import {
  createQueryParams,
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments
} from "@network/helpers";

const REGIONAL_API = process.env.REACT_APP_REGIONAL_API || "";

if (isEmptyString(REGIONAL_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_REGIONAL_API");
}

export interface GetDeviceDataResponse {
  data: any;
}

export const getDeviceConfiguration = (authToken: string,
                                       deviceId: string,
                                       includeData: string): Promise<DeviceConfigurationAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Device ID", deviceId]
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({includeData});

    const url = `${REGIONAL_API}/device-management/data/v1/devices/${deviceId}${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch device info failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getActualDeviceConfigurationBySchema = (
  authToken: string,
  deviceId: string,
  schemaIdentity: string): Promise<GetDeviceDataResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Device ID", deviceId],
      ["Schema Identity", schemaIdentity]
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/data/v1/devices/${deviceId}/configuration/actual/schemas/identity/${schemaIdentity}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch actual device configuration failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getDesiredDeviceConfigurationBySchema = (
  authToken: string,
  deviceId: string,
  schemaIdentity: string): Promise<GetDeviceDataResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Device ID", deviceId],
      ["Schema Identity", schemaIdentity]
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/data/v1/devices/${deviceId}/configuration/desired/schemas/identity/${schemaIdentity}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch desired device configuration failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteActualDeviceDataBySchema = (
  authToken: string,
  deviceId: string,
  schemaIdentity: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Device ID", deviceId],
      ["Schema Identity", schemaIdentity]
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/data/v1/devices/${deviceId}/configuration/actual/schemas/identity/${schemaIdentity}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to delete actual device data";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteDesiredDeviceDataBySchema = (
  authToken: string,
  deviceId: string,
  schemaIdentity: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Device ID", deviceId],
      ["Schema Identity", schemaIdentity]
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/data/v1/devices/${deviceId}/configuration/desired/schemas/identity/${schemaIdentity}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to delete desired device data";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const editActualDeviceDataBySchema = (
  authToken: string,
  deviceId: string,
  schemaIdentity: string,
  json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Device ID", deviceId],
      ["Schema Identity", schemaIdentity]
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/data/v1/devices/${deviceId}/configuration/actual/schemas/identity/${schemaIdentity}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to edit actual device data";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const editDesiredDeviceDataBySchema = (
  authToken: string,
  deviceId: string,
  schemaIdentity: string,
  json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Device ID", deviceId],
      ["Schema Identity", schemaIdentity]
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/data/v1/devices/${deviceId}/configuration/desired/schemas/identity/${schemaIdentity}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to edit desired device data";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getDeviceMetadataBySchema = (
  authToken: string,
  deviceId: string,
  schemaIdentity: string): Promise<GetDeviceDataResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Device ID", deviceId],
      ["Schema Identity", schemaIdentity]
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/data/v1/devices/${deviceId}/metadata/schemas/identity/${schemaIdentity}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch metadata of device failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteDeviceMetadataBySchema = (
  authToken: string,
  deviceId: string,
  schemaIdentity: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Device ID", deviceId],
      ["Schema Identity", schemaIdentity]
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/data/v1/devices/${deviceId}/metadata/schemas/identity/${schemaIdentity}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to delete metadata of device";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const editDeviceMetadataBySchema = (
  authToken: string,
  deviceId: string,
  schemaIdentity: string,
  json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Device ID", deviceId],
      ["Schema Identity", schemaIdentity]
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/data/v1/devices/${deviceId}/metadata/schemas/identity/${schemaIdentity}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to edit device metadata";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getRegionConfigurationBySchema = (
  authToken: string,
  schemaIdentity: string): Promise<GetDeviceDataResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Schema Identity", schemaIdentity]
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/data/v1/devices/region/current/configuration/desired/schemas/identity/${schemaIdentity}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch desired region device configuration failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteRegionConfigurationBySchema = (
  authToken: string,
  schemaIdentity: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Schema Identity", schemaIdentity]
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/data/v1/devices/region/current/configuration/desired/schemas/identity/${schemaIdentity}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to delete desired region device configuration";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const editRegionConfigurationBySchema = (
  authToken: string,
  schemaIdentity: string,
  json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Schema Identity", schemaIdentity]
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/data/v1/devices/region/current/configuration/desired/schemas/identity/${schemaIdentity}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to edit desired region device configuration";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getTypeConfigurationBySchema = (
  authToken: string,
  typeIdentity: string,
  schemaIdentity: string): Promise<GetDeviceDataResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Schema Identity", schemaIdentity],
      ["Type Identity", typeIdentity],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/data/v1/devices/type/${typeIdentity}/configuration/desired/schemas/identity/${schemaIdentity}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch desired type device configuration failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteTypeConfigurationBySchema = (
  authToken: string,
  typeIdentity: string,
  schemaIdentity: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Schema Identity", schemaIdentity],
      ["Type Identity", typeIdentity],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/data/v1/devices/type/${typeIdentity}/configuration/desired/schemas/identity/${schemaIdentity}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to delete desired type device configuration";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const editTypeConfigurationBySchema = (
  authToken: string,
  typeIdentity: string,
  schemaIdentity: string,
  json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Schema Identity", schemaIdentity],
      ["Type Identity", typeIdentity],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/data/v1/devices/type/${typeIdentity}/configuration/desired/schemas/identity/${schemaIdentity}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to edit desired type device configuration";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
