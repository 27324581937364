import React from "react";
import classnames from "classnames";
import { ActionMenuItem, ActionsMenu, ActionsMenuProps } from "@components";
import { noop } from "@util";

export enum ServiceAction {
  ADD_SERVICE_TO_GROUP = "add_service_to_group",
  REMOVE_SERVICE_FROM_GROUP = "remove_service_from_group",
  ATTACH_POLICY_TO_SERVICE = "attach_policy_to_service",
  DETACH_POLICY_FROM_SERVICE = "detach_policy_from_service",
  DELETE_SERVICE_REGIONAL = "delete_service_regional",
  GENERATE_SERVICE_SECRET = "generate_service_secret",
  SET_SERVICE_SECRET = "set_service_secret",
  DELETE_SERVICE_SECRET = "delete_service_secret",
  ENABLE_SERVICE = "enable_service",
  DISABLE_SERVICE = "disable_service"
}

export const ADD_SERVICE_TO_GROUP_ACTION: ActionMenuItem = Object.freeze({
  id: ServiceAction.ADD_SERVICE_TO_GROUP,
  name: "Add Service to Group",
  disabled: false,
});

export const REMOVE_SERVICE_FROM_GROUP_ACTION: ActionMenuItem = Object.freeze({
  id: ServiceAction.REMOVE_SERVICE_FROM_GROUP,
  name: "Remove Service from Group",
  disabled: false,
});

export const ATTACH_POLICY_TO_SERVICE_ACTION: ActionMenuItem = Object.freeze({
  id: ServiceAction.ATTACH_POLICY_TO_SERVICE,
  name: "Attach Policy",
  disabled: false,
});

export const DETACH_POLICY_FROM_SERVICE_ACTION: ActionMenuItem = Object.freeze({
  id: ServiceAction.DETACH_POLICY_FROM_SERVICE,
  name: "Detach Policy",
  disabled: false,
});

export const DELETE_SERVICE_REGIONAL: ActionMenuItem = Object.freeze({
  id: ServiceAction.DELETE_SERVICE_REGIONAL,
  name: "Delete Service",
  disabled: false,
});

export const GENERATE_SERVICE_SECRET: ActionMenuItem = Object.freeze({
  id: ServiceAction.GENERATE_SERVICE_SECRET,
  name: "Generate New Secret",
  disabled: false,
});

export const SET_SERVICE_SECRET: ActionMenuItem = Object.freeze({
  id: ServiceAction.SET_SERVICE_SECRET,
  name: "Set New Secret",
  tooltip: "Set secret explicitly",
  disabled: false,
});

export const DELETE_SERVICE_SECRET: ActionMenuItem = Object.freeze({
  id: ServiceAction.DELETE_SERVICE_SECRET,
  name: "Delete Secondary Secret",
  disabled: false,
});

export const ENABLE_SERVICE: ActionMenuItem = Object.freeze({
  id: ServiceAction.ENABLE_SERVICE,
  name: "Enable Service",
  disabled: false,
});

export const DISABLE_SERVICE: ActionMenuItem = Object.freeze({
  id: ServiceAction.DISABLE_SERVICE,
  name: "Disable Service",
  disabled: false,
});

export const DEFAULT_SERVICE_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  ADD_SERVICE_TO_GROUP_ACTION,
  REMOVE_SERVICE_FROM_GROUP_ACTION,
  ATTACH_POLICY_TO_SERVICE_ACTION,
  DETACH_POLICY_FROM_SERVICE_ACTION,
  DELETE_SERVICE_REGIONAL,
  GENERATE_SERVICE_SECRET,
  SET_SERVICE_SECRET,
  DELETE_SERVICE_SECRET,
  ENABLE_SERVICE,
  DISABLE_SERVICE,
]) as ActionMenuItem[];

export interface ServiceActionsMenuProps extends ActionsMenuProps {
  addServiceToGroup?: () => void;
  removeServiceFromGroup?: () => void;
  attachPolicy?: () => void;
  detachPolicy?: () => void;
  deleteServiceRegional?: () => void;
  generateServiceSecret?: () => void;
  setServiceSecret?: () => void;
  deleteServiceSecret?: () => void;
  enableService?: () => void;
  disableService?: () => void;
}

export const withServiceActions = (Component: React.ComponentType<ServiceActionsMenuProps>) =>
  (props: ServiceActionsMenuProps) => {

    const {
      actions = DEFAULT_SERVICE_ACTION_MENU_ITEMS,
      addServiceToGroup = noop,
      removeServiceFromGroup = noop,
      attachPolicy = noop,
      detachPolicy = noop,
      deleteServiceRegional = noop,
      generateServiceSecret = noop,
      setServiceSecret = noop,
      deleteServiceSecret = noop,
      enableService = noop,
      disableService = noop,
      onClickAction = noop,
    } = props;

    return (
      <Component
        {...props}
        actions={actions}
        onClickAction={(action: ActionMenuItem) => {
          switch (action.id) {
            case ServiceAction.ADD_SERVICE_TO_GROUP:
              return addServiceToGroup();
            case ServiceAction.REMOVE_SERVICE_FROM_GROUP:
              return removeServiceFromGroup();
            case ServiceAction.ATTACH_POLICY_TO_SERVICE:
              return attachPolicy();
            case ServiceAction.DETACH_POLICY_FROM_SERVICE:
              return detachPolicy();
            case ServiceAction.DELETE_SERVICE_REGIONAL:
              return deleteServiceRegional();
            case ServiceAction.GENERATE_SERVICE_SECRET:
              return generateServiceSecret();
            case ServiceAction.SET_SERVICE_SECRET:
              return setServiceSecret();
            case ServiceAction.DELETE_SERVICE_SECRET:
              return deleteServiceSecret();
            case ServiceAction.ENABLE_SERVICE:
              return enableService();
            case ServiceAction.DISABLE_SERVICE:
              return disableService();
            default:
              return onClickAction(action);
          }
        }}
      />
    );
  };

export const ServiceActionsMenu = withServiceActions((props: ServiceActionsMenuProps) => {

  const { className, buttonLabel = "Service Actions" } = props;

  return (
    <ActionsMenu
      {...props}
      className={classnames("serviceActionsMenu", className)}
      buttonLabel={buttonLabel}
    />
  );
});

export default ServiceActionsMenu;
