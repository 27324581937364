import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";

export enum ShareDashboardSteps {
  GET_USER_ID = "get_user_id_to_whom_we_share",
  CONFIRM = "confirm",
}

export interface ShareDashboardSchema extends BasePortalModuleSchema {
  dashboardId: number;
  userId: string;
  step: ShareDashboardSteps;
  open: boolean;
}

export const DEFAULT_STATE: ShareDashboardSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  dashboardId: -1,
  userId: "",
  step: ShareDashboardSteps.GET_USER_ID,
  open: false,
});

export type ShareDashboardActionTypes = PortalModuleActionTypes<ShareDashboardSchema> & {
  SHARE_DASHBOARD_REQUEST: string;
  SHARE_DASHBOARD_SUCCESS: string;
  SHARE_DASHBOARD_FAILED: string;
};

export type shareDashboardActionType = ShareDashboardActionTypes[keyof ShareDashboardActionTypes];

export const ACTION_TYPES: ShareDashboardActionTypes = {
  ...createPortalModuleActionTypes<ShareDashboardSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    SHARE_DASHBOARD_REQUEST: `${MODULE_ID}/SHARE_DASHBOARD_request`,
    SHARE_DASHBOARD_SUCCESS: `${MODULE_ID}/SHARE_DASHBOARD_success`,
    SHARE_DASHBOARD_FAILED: `${MODULE_ID}/SHARE_DASHBOARD_failed`,
  },
};

export const ShareDashboardReducer = combineReducers<ShareDashboardSchema>(
  createPortalModuleReducers<ShareDashboardSchema>(ACTION_TYPES, DEFAULT_STATE));

export default ShareDashboardReducer;
