import { Record } from "immutable";
import { WorkloadInstanceState } from "@data/WorkloadInstance";

export interface WorkloadLogInfoAttributes {
  name: string;
  version: number;
  executionId: string;
  messages: string[];
  executionState: WorkloadInstanceState;
}

export class WorkloadLogInfo extends Record({
  name: "",
  version: 0,
  executionId: "",
  messages: [],
  executionState: WorkloadInstanceState.NONE,
}) implements WorkloadLogInfoAttributes {

  public static EMPTY: WorkloadLogInfo = new WorkloadLogInfo();

  public readonly name: string;
  public readonly version: number;
  public readonly executionId: string;
  public readonly messages: string[];
  public readonly executionState: WorkloadInstanceState;
}

export default WorkloadLogInfo;
