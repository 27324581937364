import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";
import { ReportFormat, ReportType, UserReport, UserReportAttributes } from "@data";

export enum ReportSteps {
  GENERATE = "generate",
  LOADING = "loading",
  COMPLETE = "complete"
}

export interface UserReportSchema extends BasePortalModuleSchema {
  userReport: UserReportAttributes;
  reportId: string;
  step: ReportSteps;
  generateDialog: boolean;
  type: ReportType;
  format: ReportFormat;
}

export const DEFAULT_STATE: UserReportSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  userReport: Object.freeze({...UserReport.EMPTY.toJS() }),
  reportId: "",
  step: ReportSteps.GENERATE,
  generateDialog: false,
  type: ReportType.USERS,
  format: ReportFormat.CSV,
});

type UserReportActionType = PortalModuleActionTypes<UserReportSchema> & {
  GENERATE_USER_REPORT_REQUEST: string;
  GENERATE_USER_REPORT_SUCCESS: string;
  GENERATE_USER_REPORT_FAILED: string;
  FETCH_USER_REPORT_REQUEST: string;
  FETCH_USER_REPORT_SUCCESS: string;
  FETCH_USER_REPORT_FAILED: string;
};

export const ACTION_TYPES: UserReportActionType = Object.freeze({
  ...createPortalModuleActionTypes<UserReportSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    GENERATE_USER_REPORT_REQUEST: `${MODULE_ID}/generate_user_report_request`,
    GENERATE_USER_REPORT_SUCCESS: `${MODULE_ID}/generate_user_report_success`,
    GENERATE_USER_REPORT_FAILED: `${MODULE_ID}/generate_user_report_failed`,
    FETCH_USER_REPORT_REQUEST: `${MODULE_ID}/fetch_user_report_request`,
    FETCH_USER_REPORT_SUCCESS: `${MODULE_ID}/fetch_user_report_success`,
    FETCH_USER_REPORT_FAILED: `${MODULE_ID}/fetch_user_report_failed`,
  },
});

export const UserReportReducer = combineReducers<UserReportSchema>(
  createPortalModuleReducers<UserReportSchema>(ACTION_TYPES, DEFAULT_STATE));

export default UserReportReducer;
