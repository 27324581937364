import React from "react";
import classnames from "classnames";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { removeDialog as styles } from "../styles";

export const RemoveDevice = withStyles(styles)((props: WithStyles<typeof styles>) => {

  const {
    classes,
  } = props;

  return (
    <div className={classnames("removeDevice", "content", classes.content)}>
      <label className={classnames("title", classes.title)}>
        Are you sure you want to delete this Device?
      </label>
      <label className={classnames("subtitle", classes.subtitle)}>
        <span className={classes.warning}>WARNING:</span>This action cannot be undone!
      </label>
    </div>
  );
});

export default RemoveDevice;
