import { isEmptyString } from "@util";
import { ApplicationAttributes, ApplicationUserManagementAttributes } from "@data";
import {
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments
} from "@network/helpers";
import RestClientError from "@network/RestClientError";

const REGIONAL_API = process.env.REACT_APP_REGIONAL_API || "";

if (isEmptyString(REGIONAL_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_REGIONAL_API");
}

export const getApplicationsRegionalApi = (authToken: string): Promise<ApplicationAttributes[]> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/applications`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch applications failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage)
      .catch((errorResponse: RestClientError) => {

        const { status = 500 } = errorResponse || {};

        // AppIdM treats no applications like an error when it should be an empty list
        if (status === 404) {
          return Promise.resolve([]);
        }

        return Promise.reject(errorResponse);
      });
  };

  return validate().then(makeRequest);
};

export const getApplicationRegionalApi = (authToken: string,
                                          applicationId: string): Promise<ApplicationAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments( [
      ["Application Id", applicationId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/applications/${applicationId}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to get Application ${applicationId}`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteApplicationRegionalApi = (authToken: string,
                                             applicationId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Application ID", applicationId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/applications/${applicationId}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to delete application ${applicationId}`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const enableApplicationRegionalApi = (authToken: string,
                                             applicationId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Application ID", applicationId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/applications/${applicationId}/state/enabled`;

    const settings = {
      method: "PATCH",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to enable application ${applicationId}`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const disableApplicationRegionalApi = (authToken: string,
                                              applicationId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Application ID", applicationId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/applications/${applicationId}/state/disabled`;

    const settings = {
      method: "PATCH",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to disable application ${applicationId}`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const generateApplicationSecret = (authToken: string,
                                          applicationId: string): Promise<ApplicationAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Application ID", applicationId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/applications/${applicationId}/authentication/secrets`;

    const settings = {
      method: "PATCH",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to generate application secret for ${applicationId}`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteApplicationSecret = (authToken: string,
                                        applicationId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Application ID", applicationId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/applications/${applicationId}/authentication/secrets`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to delete old application secret for ${applicationId}`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const setApplicationSecret = (authToken: string,
                                     applicationId: string,
                                     json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Application ID", applicationId],
      ["Secret", json],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/applications/${applicationId}/authentication/secrets`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type":  "application/json"
      },
      body: json,
    };

    const defaultErrorMessage = `Failed to update application secret for ${applicationId}`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const authenticateApplication = (authToken: string,
                                        applicationId: string,
                                        json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Application ID", applicationId],
      ["Secret", json],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/applications/${applicationId}/authentication`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type":  "application/json"
      },
      body: json,
    };

    const defaultErrorMessage = `Failed to authenticate application for ${applicationId}`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const createApplicationRegionalApi = (authToken: string,
                                             json: string): Promise<ApplicationAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Application", json],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/applications`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = `Failed to create application`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const editApplicationRegionalApi = (authToken: string,
                                           applicationId: string,
                                           json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Application ID", applicationId],
      ["Application", json],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/applications/${applicationId}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = `Failed to edit application`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getApplicationUserManagement = (authToken: string,
                                             applicationId: string): Promise<ApplicationUserManagementAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments( [
      ["Application Id", applicationId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/applications/${applicationId}/configuration/user-management`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to get Application User Management ${applicationId}`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const updateApplicationUserManagement = (authToken: string,
                                                applicationId: string,
                                                json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Application ID", applicationId],
      ["Application User Management", json],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/applications/${applicationId}/configuration/user-management`;

    const settings = {
      method: "PATCH",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = `Failed to update application user management`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
