import Color from "@Color";
import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
      marginBottom: 30,
    },
    browserContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    header: {
      borderRadius: 0,
      borderLeft: "none",
      borderTop: "none",
      borderRight: "none",
      borderBottom: `1px solid ${Color.GREY1}`,
    },
    content: {
      padding: 10,
    },
    list: {
    },
  });

export default styles;
