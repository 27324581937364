// Stub implementation that will be used when LocalStorage is not supported
class LocalStorageStub implements Storage {

    [index: number]: string;

    public readonly length: number = 0;

    public getItem(key: string): string | null {
        return null;
    }

    public setItem(key: string, data: string): void {
        // no-op
    }

    public removeItem(key: string): void {
        // no-op
    }

    public clear(): void {
        // no-op
    }

    public key(index: number): string | null {
        return null;
    }
}

export default (typeof localStorage !== "undefined"  ? localStorage : new LocalStorageStub());
