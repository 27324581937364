import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useApplicationManagerRegionalClient
} from "@hooks";
import { Application, ApplicationAttributes } from "@data/Application";

type SuccessResponse = ApplicationAttributes[];

export interface UseGetApplicationsRegionalProps extends Partial<UseApiRequestProps<SuccessResponse>> {
}

export interface UseGetApplicationsRegionalModel extends UseApiRequestModel<SuccessResponse> {
  applications: Application[];
}

export interface UseGetApplicationsRegionalActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetApplicationsRegionalProps;
type Model = UseGetApplicationsRegionalModel;
type Actions = UseGetApplicationsRegionalActions;
type Result = [Model, Actions];

export const useGetApplicationsRegional = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to get applications",
    ...otherProps
  } = props;

  const ApplicationManagerClient = useApplicationManagerRegionalClient();

  const makeApiRequest = React.useCallback(() =>
      ApplicationManagerClient.getApplicationsRegionalApi(),
    [ApplicationManagerClient]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const applications = React.useMemo<Application[]>(() => {
    const items = Array.isArray(successResponse) ? successResponse : [];
    return items.map(attrs => new Application(attrs));
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    applications,
  }), [
    baseModel,
    applications,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetApplicationsRegional;
