import React from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { noop } from "@util";
import { AppSchema } from "@main/schemas";
import { JsonSchemaMetadata } from "@data";
import { Color, SchemaProperties, SchemasList } from "@components";
import { useSchemaRef, useSchemas } from "@hooks";
import { PropertiesTableActions } from "../actions/propertiesTable";
import { getSelectedSchemaRef } from "../selectors/propertiesTable";

const styles = () =>
  createStyles({
    schemasList: {
      flex: "0 0 auto",
      "& .addFilterButton": {
        color: Color.TEXT,
      },
    },
  });

interface Model {
  selectedSchemaRef?: string;
}

interface Actions {
  setSelectedSchemaRef?: (schemas?: JsonSchemaMetadata) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

const SelectSchemaView = withStyles(styles)((props: Props) => {

  const {
    classes,
    selectedSchemaRef = "",
    setSelectedSchemaRef = noop,
  } = props;

  const setSelectedItems = React.useCallback((selectedSchemas: JsonSchemaMetadata[]) =>
    setSelectedSchemaRef(selectedSchemas.pop()), [setSelectedSchemaRef]);

  const [{
    metadata,
    errorMessage,
    showErrorView,
    showProgressIndicator,
    isValidSchemaRef,
  }] = useSchemaRef(selectedSchemaRef);

  const schemas = React.useMemo(() =>
    showProgressIndicator ? [] : [metadata],
    [showProgressIndicator, metadata]);

  const selectedItems = React.useMemo(() => schemas, [schemas]);

  const [ model, actions ] = useSchemas();

  if (isValidSchemaRef) {
    return (
      <React.Fragment>
        <SchemasList
          className={classes.schemasList}
          hideSummary={true}
          hideSearch={true}
          showSearchFilters={false}
          selectable={true}
          selectAllDisabled={true}
          tableLayoutFixed={false}
          schemas={schemas}
          selectedItems={selectedItems}
          errorMessage={errorMessage}
          showErrorView={showErrorView}
          showProgressIndicator={showProgressIndicator}
          setSelectedItems={setSelectedItems}
        />
        <SchemaProperties
          className="schemaReferenceProperties"
          title="Schema Reference Properties"
          location={selectedSchemaRef}
          refreshEnabled={true}
          actions={[]}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Typography variant="subtitle1" style={{ margin: "16px 0 8px" }}>
        Select the schema reference to apply to this property
      </Typography>
      <SchemasList
        className={classes.schemasList}
        {...model}
        {...actions}
        hideSummary={true}
        hideSearch={false}
        showSearchFilters={true}
        selectable={true}
        selectAllDisabled={true}
        tableLayoutFixed={false}
        setSelectedItems={setSelectedItems}
      />
    </React.Fragment>
  );
});

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  selectedSchemaRef: getSelectedSchemaRef(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setSelectedSchemaRef: (schema?: JsonSchemaMetadata) =>
    dispatch(PropertiesTableActions.setSelectedSchema(schema)),
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps
)(SelectSchemaView);
