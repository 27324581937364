import { Record } from "immutable";
import { getStringValue } from "@util";
import QualityReportDefects, { QualityReportDefectsAttributes } from "./QualityReportDefects";
import QualityReportVulnerabilities, {
  QualityReportVulnerabilitiesAttributes,
} from "./QualityReportVulnerabilities";
import QualityReportCVEVulnerabilities, {
  QualityReportCVEVulnerabilitiesAttributes,
} from "./QualityReportCVEVulnerabilities";

export enum QualityReportTrend {
  NONE = "",
  TRENDING_UP = "TRENDING_UP",
  FLAT = "FLAT",
  TRENDING_DOWN = "TRENDING_DOWN",
}

export enum QualityReportPlatformDomainId {
  NONE = "",
  SECURITY_SERVICES = "SECURITY_SERVICES",
  DEVICE_MANAGEMENT = "DEVICE_MANAGEMENT",
  DATA_AND_ANALYTICS = "DATA_AND_ANALYTICS",
  COMMON_SERVICES = "COMMON_SERVICES",
  SELF_SERVICE_TOOLS = "SELF_SERVICE_TOOLS",
}

export enum QualityReportState {
  NONE = "",
  DRAFT = "DRAFT",
  REVIEW = "REVIEW",
  PUBLISHED = "PUBLISHED",
}

export interface QualityReportPlatformDomain {
  id: QualityReportPlatformDomainId.NONE;
  name: string;
}

export interface QualityReportGroup {
  id: string;
  name: string;
}

export interface QualityReportCodeCoverage {
  lineCoverage: number;
  methodCoverage: number;
  classCoverage: number;
  branchCoverage: number;
  instructionCoverage: number;
  complexityCoverage: number;
}

export interface QualityReportTests {
  total: number;
  security: number;
  functional: number;
}

export interface QualityReportSecurityDetails {
  openSourceVulnerabilities: QualityReportCVEVulnerabilitiesAttributes;
  staticCodeAnalysisVulnerabilities: QualityReportVulnerabilitiesAttributes;
}

export interface QualityReportQualityDetails {
  staticCodeAnalysisDefects: QualityReportDefectsAttributes;
  functionalDefects: QualityReportDefectsAttributes;
  codeCoverage: QualityReportCodeCoverage;
}

export interface QualityReportAttributes {
  id: string;
  createdAt: string;
  version: string;
  description: string;
  state: QualityReportState;
  platformDomain: QualityReportPlatformDomain;
  group: QualityReportGroup;
  licenses: string[];
  security: QualityReportSecurityDetails;
  quality: QualityReportQualityDetails;
  tests: QualityReportTests;
}

export class QualityReport extends Record({
  id: "",
  createdAt: "",
  version: "",
  description: "",
  state: QualityReportState.NONE,
  platformDomain: {
    id: QualityReportPlatformDomainId.NONE,
    name: "",
  },
  group: {
    id: "",
    name: "",
  },
  licenses: [],
  security: {
    openSourceVulnerabilities: QualityReportCVEVulnerabilities.EMPTY.toJS(),
    staticCodeAnalysisVulnerabilities: QualityReportVulnerabilities.EMPTY.toJS(),
  },
  quality: {
    staticCodeAnalysisDefects: QualityReportDefects.EMPTY.toJS(),
    functionalDefects: QualityReportDefects.EMPTY.toJS(),
    codeCoverage: {
      lineCoverage: 0.0,
      methodCoverage: 0.0,
      classCoverage: 0.0,
      branchCoverage: 0.0,
      instructionCoverage: 0.0,
      complexityCoverage: 0.0,
    },
  },
  tests: {
    total: 0,
    security: 0,
    functional: 0,
  },
}) implements QualityReportAttributes {

  public static EMPTY: QualityReport = new QualityReport();

  public readonly id: string;
  public readonly createdAt: string;
  public readonly version: string;
  public readonly description: string;
  public readonly state: QualityReportState;
  public readonly platformDomain: QualityReportPlatformDomain;
  public readonly group: QualityReportGroup;
  public readonly licenses: string[];
  public readonly security: QualityReportSecurityDetails;
  public readonly quality: QualityReportQualityDetails;
  public readonly tests: QualityReportTests;

  public getId(): string {
    return getStringValue(this.id);
  }

  public getCreatedAt(): string {
    return getStringValue(this.createdAt);
  }

  public getCreatedAtDate(): Date {
    return new Date(getStringValue(this.createdAt));
  }

  public getVersion(): string {
    return getStringValue(this.version);
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public getState(): QualityReportState {
    return this.state || QualityReportState.NONE;
  }

  public isDraftState(): boolean {
    return this.state === QualityReportState.DRAFT;
  }

  public isReviewState(): boolean {
    return this.state === QualityReportState.REVIEW;
  }

  public isPublishedState(): boolean {
    return this.state === QualityReportState.PUBLISHED;
  }

  public getPlatformDomain(): QualityReportPlatformDomain {
    const {
      id = QualityReportPlatformDomainId.NONE,
      name = "",
    } = this.platformDomain || {};
    return { id, name };
  }

  public getPlatformDomainId(): QualityReportPlatformDomainId {
    const { id } = this.getPlatformDomain();
    return id;
  }

  public getPlatformDomainName(): string {
    const { name } = this.getPlatformDomain();
    return name;
  }

  public getGroup(): QualityReportGroup {
    const {
      id = "",
      name = "",
    } = this.group || {};
    return { id, name };
  }

  public getGroupId(): string {
    const { id } = this.getGroup();
    return id;
  }

  public getGroupName(): string {
    const { name } = this.getGroup();
    return name;
  }

  public getLicenses(): string[] {
    return Array.isArray(this.licenses) ? this.licenses : [];
  }

  public hasLicenses(): boolean {
    return this.getLicenses().length > 0;
  }

  public getQualityReportSecurityDetails(): QualityReportSecurityDetails {
    return this.security || {
      openSourceVulnerabilities: QualityReportCVEVulnerabilities.EMPTY.toJS(),
      staticCodeAnalysisVulnerabilities: QualityReportVulnerabilities.EMPTY.toJS(),
    };
  }

  public getOpenSourceVulnerabilities(): QualityReportCVEVulnerabilities {
    const { openSourceVulnerabilities } = this.getQualityReportSecurityDetails();
    const {
      critical = 0,
      high = 0,
      medium = 0,
      low = 0,
      none = 0,
    } = openSourceVulnerabilities;
    return new QualityReportCVEVulnerabilities({
      critical,
      high,
      medium,
      low,
      none,
    });
  }

  public getNumCriticalOpenSourceVulnerabilities(): number {
    return this.getOpenSourceVulnerabilities().getNumCriticalVulnerabilities();
  }

  public getNumHighOpenSourceVulnerabilities(): number {
    return this.getOpenSourceVulnerabilities().getNumHighVulnerabilities();
  }

  public getNumMediumOpenSourceVulnerabilities(): number {
    return this.getOpenSourceVulnerabilities().getNumMediumVulnerabilities();
  }

  public getNumLowOpenSourceVulnerabilities(): number {
    return this.getOpenSourceVulnerabilities().getNumLowVulnerabilities();
  }

  public getNumNoneOpenSourceVulnerabilities(): number {
    return this.getOpenSourceVulnerabilities().getNumNoneVulnerabilities();
  }

  public getNumOpenSourceVulnerabilities(): number {
    return this.getNumCriticalOpenSourceVulnerabilities() +
      this.getNumHighOpenSourceVulnerabilities() +
      this.getNumMediumOpenSourceVulnerabilities() +
      this.getNumLowOpenSourceVulnerabilities() +
      this.getNumNoneOpenSourceVulnerabilities();
  }

  public getStaticCodeAnalysisVulnerabilities(): QualityReportVulnerabilities {
    const { staticCodeAnalysisVulnerabilities } = this.getQualityReportSecurityDetails();
    const {
      blocker = 0,
      critical = 0,
      major = 0,
      minor = 0,
      info = 0,
    } = staticCodeAnalysisVulnerabilities;
    return new QualityReportVulnerabilities({
      blocker,
      critical,
      major,
      minor,
      info,
    });
  }

  public getNumBlockerStaticCodeAnalysisVulnerabilities(): number {
    return this.getStaticCodeAnalysisVulnerabilities().getNumBlockerVulnerabilities();
  }

  public getNumCriticalStaticCodeAnalysisVulnerabilities(): number {
    return this.getStaticCodeAnalysisVulnerabilities().getNumCriticalVulnerabilities();
  }

  public getNumMajorStaticCodeAnalysisVulnerabilities(): number {
    return this.getStaticCodeAnalysisVulnerabilities().getNumMajorVulnerabilities();
  }

  public getNumMinorStaticCodeAnalysisVulnerabilities(): number {
    return this.getStaticCodeAnalysisVulnerabilities().getNumMinorVulnerabilities();
  }

  public getNumInfoStaticCodeAnalysisVulnerabilities(): number {
    return this.getStaticCodeAnalysisVulnerabilities().getNumInfoVulnerabilities();
  }

  public getNumStaticCodeAnalysisVulnerabilities(): number {
    return this.getNumBlockerStaticCodeAnalysisVulnerabilities() +
      this.getNumCriticalStaticCodeAnalysisVulnerabilities() +
      this.getNumMajorStaticCodeAnalysisVulnerabilities() +
      this.getNumMinorStaticCodeAnalysisVulnerabilities() +
      this.getNumInfoStaticCodeAnalysisVulnerabilities();
  }

  public getQualityReportQualityDetails(): QualityReportQualityDetails {
    return this.quality || {
      staticCodeAnalysisDefects: QualityReportDefects.EMPTY.toJS(),
      functionalDefects: QualityReportDefects.EMPTY.toJS(),
      codeCoverage: {
        lineCoverage: 0.0,
        methodCoverage: 0.0,
        classCoverage: 0.0,
        branchCoverage: 0.0,
        instructionCoverage: 0.0,
        complexityCoverage: 0.0,
      },
    };
  }

  public getStaticCodeAnalysisDefects(): QualityReportDefects {
    const { staticCodeAnalysisDefects } = this.getQualityReportQualityDetails();
    const {
      blocker = 0,
      critical = 0,
      major = 0,
      minor = 0,
      info = 0,
    } = staticCodeAnalysisDefects;
    return new QualityReportDefects({
      blocker,
      critical,
      major,
      minor,
      info,
    });
  }

  public getNumBlockerStaticCodeAnalysisDefects(): number {
    return this.getStaticCodeAnalysisDefects().getNumBlockerDefects();
  }

  public getNumCriticalStaticCodeAnalysisDefects(): number {
    return this.getStaticCodeAnalysisDefects().getNumCriticalDefects();
  }

  public getNumMajorStaticCodeAnalysisDefects(): number {
    return this.getStaticCodeAnalysisDefects().getNumMajorDefects();
  }

  public getNumMinorStaticCodeAnalysisDefects(): number {
    return this.getStaticCodeAnalysisDefects().getNumMinorDefects();
  }

  public getNumInfoStaticCodeAnalysisDefects(): number {
    return this.getStaticCodeAnalysisDefects().getNumInfoDefects();
  }

  public getNumStaticCodeAnalysisDefects(): number {
    return this.getNumBlockerStaticCodeAnalysisDefects() +
      this.getNumCriticalStaticCodeAnalysisDefects() +
      this.getNumMajorStaticCodeAnalysisDefects() +
      this.getNumMinorStaticCodeAnalysisDefects() +
      this.getNumInfoStaticCodeAnalysisDefects();
  }

  public getFunctionalDefects(): QualityReportDefects {
    const { functionalDefects } = this.getQualityReportQualityDetails();
    const {
      blocker = 0,
      critical = 0,
      major = 0,
      minor = 0,
      info = 0,
    } = functionalDefects;
    return new QualityReportDefects({
      blocker,
      critical,
      major,
      minor,
      info,
    });
  }

  public getNumBlockerFunctionalDefects(): number {
    return this.getFunctionalDefects().getNumBlockerDefects();
  }

  public getNumCriticalFunctionalDefects(): number {
    return this.getFunctionalDefects().getNumCriticalDefects();
  }

  public getNumMajorFunctionalDefects(): number {
    return this.getFunctionalDefects().getNumMajorDefects();
  }

  public getNumMinorFunctionalDefects(): number {
    return this.getFunctionalDefects().getNumMinorDefects();
  }

  public getNumInfoFunctionalDefects(): number {
    return this.getFunctionalDefects().getNumInfoDefects();
  }

  public getNumFunctionalDefects(): number {
    return this.getNumBlockerFunctionalDefects() +
      this.getNumCriticalFunctionalDefects() +
      this.getNumMajorFunctionalDefects() +
      this.getNumMinorFunctionalDefects() +
      this.getNumInfoFunctionalDefects();
  }

  public getCodeCoverage(): QualityReportCodeCoverage {
    const { codeCoverage } = this.getQualityReportQualityDetails();
    const {
      lineCoverage = 0.0,
      methodCoverage = 0.0,
      classCoverage = 0.0,
      branchCoverage = 0.0,
      instructionCoverage = 0.0,
      complexityCoverage = 0.0,
    } = codeCoverage;
    return {
      lineCoverage,
      methodCoverage,
      classCoverage,
      branchCoverage,
      instructionCoverage,
      complexityCoverage,
    };
  }

  public getLineCoverage(): number {
    const { lineCoverage } = this.getCodeCoverage();
    return lineCoverage;
  }

  public getMethodCoverage(): number {
    const { methodCoverage } = this.getCodeCoverage();
    return methodCoverage;
  }

  public getClassCoverage(): number {
    const { classCoverage } = this.getCodeCoverage();
    return classCoverage;
  }

  public getBranchCoverage(): number {
    const { branchCoverage } = this.getCodeCoverage();
    return branchCoverage;
  }

  public getInstructionCoverage(): number {
    const { instructionCoverage } = this.getCodeCoverage();
    return instructionCoverage;
  }

  public getComplexityCoverage(): number {
    const { complexityCoverage } = this.getCodeCoverage();
    return complexityCoverage;
  }

  public getTests(): QualityReportTests {
    const {
      total = 0,
      security = 0,
      functional = 0,
    } = this.tests || {};
    return {
      total,
      security,
      functional,
    };
  }

  public getNumTotalTests(): number {
    const { total } = this.getTests();
    return total;
  }

  public getNumSecurityTests(): number {
    const { security } = this.getTests();
    return security;
  }

  public getNumFunctionalTests(): number {
    const { functional } = this.getTests();
    return functional;
  }
}

export default QualityReport;
