import { combineReducers } from "redux";
import { Actions, filterAction, Identity } from "../../util";

export interface DeletePolicySchema {
  open: boolean;
  policyName: string;
  errorMessage: string;
  successMessage: string;
  showProgressIndicator: boolean;
  showAccessDenied: boolean;
}

export const DEFAULT_STATE: DeletePolicySchema = Object.freeze({
  open: false,
  policyName: "",
  errorMessage: "",
  successMessage: "",
  showProgressIndicator: false,
  showAccessDenied: false,
});

export enum DeletePolicyActionType {
  SET_OPEN = "delete_policy/set_open",
  SET_POLICY_NAME = "delete_policy/set_policy_name",
  SET_ERROR_MESSAGE = "delete_policy/set_error_message",
  SET_SUCCESS_MESSAGE = "delete_policy/set_success_message",
  SET_SHOW_PROGRESS_INDICATOR = "delete_policy/set_show_progress_indicator",
  SET_SHOW_ACCESS_DENIED = "delete_policy/set_show_access_denied",
  DELETE_POLICY_REQUEST = "delete_policy/delete_policy_request",
  DELETE_POLICY_SUCCESS = "delete_policy/delete_policy_success",
  DELETE_POLICY_FAILED = "delete_policy/delete_policy_failed",
}

const open = filterAction<boolean>(Actions(
  DeletePolicyActionType.SET_OPEN))(Identity,
  DEFAULT_STATE.open);

const policyName = filterAction<string>(Actions(
  DeletePolicyActionType.SET_POLICY_NAME))(Identity,
  DEFAULT_STATE.policyName);

const errorMessage = filterAction<string>(Actions(
  DeletePolicyActionType.SET_ERROR_MESSAGE))(Identity,
  DEFAULT_STATE.errorMessage);

const successMessage = filterAction<string>(Actions(
  DeletePolicyActionType.SET_SUCCESS_MESSAGE))(Identity,
  DEFAULT_STATE.successMessage);

const showProgressIndicator = filterAction<boolean>(Actions(
  DeletePolicyActionType.SET_SHOW_PROGRESS_INDICATOR))(Identity,
  DEFAULT_STATE.showProgressIndicator);

const showAccessDenied = filterAction<boolean>(Actions(
  DeletePolicyActionType.SET_SHOW_ACCESS_DENIED))(Identity,
  DEFAULT_STATE.showAccessDenied);

export const DeletePolicyReducer = combineReducers<DeletePolicySchema>({
  open,
  policyName,
  errorMessage,
  successMessage,
  showProgressIndicator,
  showAccessDenied,
});

export default DeletePolicyReducer;
