import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const createIcon = (icon: any) => {

  const { ReactComponent: Icon } = icon;

  const { props: defaultProps = {} } = Icon();

  const {
    width: defaultWidth = 16,
    height: defaultHeight = 16,
    viewBox: defaultViewBox,
  } = defaultProps;

  return (props: SvgIconProps) => {

    const {
      width = defaultWidth,
      height = defaultHeight,
      viewBox = defaultViewBox || `0 0 ${width} ${height}`,
      ...otherProps
    } = props;

    return (
      <SvgIcon
        width={width}
        height={height}
        viewBox={viewBox}
        {...otherProps}
      >
        <Icon/>
      </SvgIcon>
    );
  };
};

export default createIcon;
