import React from "react";
import useApiRequest, {
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import { DSTypeInfo } from "@data/DSTypeInfo";
import useDigitalShadowClient from "../use-digital-shadow-client";
import { ETagHeaders, GetDSTypeResponse } from "@network";

type SuccessResponse = GetDSTypeResponse;

export interface UseGetDSTypeProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
}

export interface UseGetDSTypeModel extends UseApiRequestModel<SuccessResponse> {
  dsType: DSTypeInfo;
  etag: ETagHeaders;
}

export interface UseGetDSTypeActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetDSTypeProps;
type Model = UseGetDSTypeModel;
type Actions = UseGetDSTypeActions;
type Result = [Model, Actions];

export const useGetDSType = (props: Props): Result => {

  const {
    name,
    defaultErrorMessage = "Failed to get shadow type",
    ...otherProps
  } = props;

  const DigitalShadowClient = useDigitalShadowClient();

  const makeApiRequest = React.useCallback(() =>
    DigitalShadowClient.getTypeApi(name), [DigitalShadowClient, name]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const etag = React.useMemo(() => {
    return successResponse?.etag || {};
  }, [successResponse]);

  const dsType = React.useMemo<DSTypeInfo>(() => {
    const attrs = successResponse?.typeAttrs || {};
    return new DSTypeInfo(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    etag,
    dsType,
  }), [
    baseModel,
    etag,
    dsType,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetDSType;
