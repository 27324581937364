import { Module } from "@data";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./PoliciesView";

export const policiesModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Policies",
  title: "Policy Management",
  className: "policiesModule",
  primaryColor,
  icon,
  view,
  backButton: null,
});

export default policiesModule;
