// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister()
        .then(() => {
          console.log("Service worker removed.");
          window.postMessage("serviceWorkerRemoved", window.location.origin);
        })
        .catch(error => {
          console.error("Error during service worker un-registration:", error);
        });
    });
  }
}

export default unregister;
