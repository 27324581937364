import React from "react";
import classnames from "classnames";
import {
  DetailsViewActions,
  DetailsViewModel,
  DetailsViewRoute
} from "@components";
import SummaryView from "@components/summary-view";
import DetailsView from "@components/details-view";
import { JsonView } from "@components/json-view";
import { SummaryViewData } from "@data/SummaryViewData";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";

export interface SummaryJsonDetailsViewModel extends DetailsViewModel {
  summaryViewClassName?: string;
  jsonViewClassName?: string;
  className?: string;
  summaryViewItems?: SummaryViewData[];
  json?: string;
  fileName?: string;
  showJsonHeader?: boolean;
  showDefaultSummaryView?: boolean;
  additionalViews?: React.ReactNode;
  customSummaryView?: React.ReactNode;
  additionalRoutes?: DetailsViewRoute[];
}

export type SummaryJsonDetailsViewActions = DetailsViewActions;

type Props = WithStyles<typeof styles> & SummaryJsonDetailsViewModel & SummaryJsonDetailsViewActions;

export const SummaryJsonDetailsView = withStyles(styles)((props: Props) => {

  const {
    classes,
    summaryViewClassName,
    jsonViewClassName,
    className = "",
    summaryViewItems = [],
    additionalRoutes = [],
    json = "",
    fileName,
    showJsonHeader,
    showDefaultSummaryView = true,
    additionalViews,
    customSummaryView,
    ...otherProps
  } = props;

  const DefaultSummaryView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <React.Fragment>
        <SummaryView
          className={classnames("summaryView", summaryViewClassName, classes.summaryView)}
          items={summaryViewItems}
        />
        {additionalViews}
      </React.Fragment>
    );
  }, [summaryViewClassName, summaryViewItems, additionalViews]);

  const DetailsSummaryView = React.useMemo<() => React.ReactElement>(() => () => {
    if (showDefaultSummaryView) {
      return <DefaultSummaryView/>;
    } else {
      return (
        <React.Fragment>
          {customSummaryView}
          {additionalViews}
        </React.Fragment>
      );
    }
  }, [showDefaultSummaryView, DefaultSummaryView, customSummaryView, additionalViews]);

  const DetailsJsonView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <JsonView
        className={classnames("jsonView", jsonViewClassName, classes.jsonView)}
        json={json}
        fileName={fileName}
        showHeader={showJsonHeader}
      />
    );
  }, [jsonViewClassName, json, fileName, showJsonHeader]);

  const ROUTES: DetailsViewRoute[] = React.useMemo( () => [
    {
      id: "summary",
      name: "Summary",
      view: DetailsSummaryView
    },
    {
      id: "json",
      name: "{} JSON",
      path: "/json",
      view: DetailsJsonView
    }
  ] , [DetailsSummaryView, DetailsJsonView]);

  const allRoutes = React.useMemo(() =>
  ROUTES.concat(additionalRoutes.filter(route => !(route.id === "summary" || route.id === "json"))),
    [ROUTES, additionalRoutes]);

  return (
    <DetailsView
      {...otherProps}
      className={classnames("summaryJsonDetailsView", className, classes.container)}
      routes={allRoutes}
    />
  );
});

export default SummaryJsonDetailsView;
