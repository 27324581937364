import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { deleteDialog as styles } from "../styles";
import { AuthenticatorRequirement } from "@data";

export interface Model {
  userId?: string;
  mfaStatus?: AuthenticatorRequirement;
}

type Props = WithStyles<typeof styles> & Model;

export const UpdateMfaRequirement = withStyles(styles)((props: Props) => {

  const { classes, userId = "", mfaStatus } = props;

  const mfaRequired = React.useMemo(() => {

    return (
      <React.Fragment>
        <label className={classnames("title", classes.title)}>
          Are you sure you want {userId} to require multi-factor authentication?
        </label>
        <label className={classnames("subtitle", classes.subtitle)}>
          The next time the user logs in, they will be required to enter a one time token from their
          enrolled mfa device.
        </label>
        <label className={classnames("subtitle", classes.subtitle)}>
          If the user has not yet enrolled an MFA device, they will be required to do so before logging in
        </label>
      </React.Fragment>
    );
  }, [userId]);

  const mfaNotRequired = React.useMemo(() => {

    return (
      <React.Fragment>
        <label className={classnames("title", classes.title)}>
          Are you sure you want {userId} to log in without multi-factor authentication?
        </label>
        <label className={classnames("subtitle", classes.subtitle)}>
          With this setting, user can login without multi-factor authentication.
        </label>
      </React.Fragment>
    );
  }, [userId]);

  return (
    <div className={classnames("updateMfa", classes.container)}>
      {mfaStatus === AuthenticatorRequirement.NONE ? mfaRequired : mfaNotRequired}
    </div>
  );
});

export default UpdateMfaRequirement;
