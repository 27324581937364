import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { DataApprovalRequest } from "@data";
import {
  setFilterByCurrentAccount,
  setFilterByPendingApproval,
  setNameFilter,
  setReloadItemsRequested,
} from "../actions";
import {
  getCurrentAccountId,
  getNameFilter,
  isFilterByCurrentAccountEnabled,
  isFilterByPendingApprovalEnabled,
  isReloadItemsRequested,
} from "../selectors";
import DataApprovalRequests, {
  DataApprovalRequestsActions as Actions,
  DataApprovalRequestsModel as Model,
} from "@components/data-approval-requests";
import { approveRequest, rejectRequest } from "@modules/approveRequest/actions";
import useGetDataApprovalRequests from "@hooks/dataApprovalRequest/use-get-data-approval-requests";

export interface ContainerModel extends Model {
  reloadItemsRequested?: boolean;
}

export interface ContainerActions extends Actions {
  requestReload?: () => void;
  onItemsReloaded?: () => void;
}

type Props = ContainerModel & ContainerActions;

const DataApprovalRequestsContainer = (props: Props) => {

  const {
    filterByPendingApproval: pendingFilterEnabled,
    reloadItemsRequested,
    requestReload = noop,
    onItemsReloaded = noop,
    setFilterByPendingApproval: setPendingFilterEnabled = noop,
    ...otherProps
  } = props;

  const [{approvalRequests: items, ...model}, {refresh, ...actions}] =
    useGetDataApprovalRequests();

  React.useEffect(() => {
    if (reloadItemsRequested) {
      refresh();
      onItemsReloaded();
    }
  }, [reloadItemsRequested, refresh, onItemsReloaded]);

  if (reloadItemsRequested) {
    return null;
  }

  return (
    <DataApprovalRequests
      {...model}
      {...actions}
      {...otherProps}
      filterByPendingApproval={pendingFilterEnabled}
      items={items}
      refresh={refresh}
      setFilterByPendingApproval={setPendingFilterEnabled}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  nameFilter: getNameFilter(state),
  filterByCurrentAccount: isFilterByCurrentAccountEnabled(state),
  currentAccountId: getCurrentAccountId(state),
  filterByPendingApproval: isFilterByPendingApprovalEnabled(state),
  reloadItemsRequested: isReloadItemsRequested(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setNameFilter: value => dispatch(setNameFilter(value)),
  setFilterByCurrentAccount: value => dispatch(setFilterByCurrentAccount(value)),
  setFilterByPendingApproval: value => dispatch(setFilterByPendingApproval(value)),
  requestReload: () => dispatch(setReloadItemsRequested(true)),
  onItemsReloaded: () => dispatch(setReloadItemsRequested(false)),
  approveDataApprovalRequest: (dataApprovalRequest: DataApprovalRequest) =>
    dispatch(approveRequest(dataApprovalRequest.approvalRequestId, dataApprovalRequest.dataSetAlias)),
  rejectDataApprovalRequest: (dataApprovalRequest: DataApprovalRequest) =>
    dispatch(rejectRequest(dataApprovalRequest.approvalRequestId, dataApprovalRequest.dataSetAlias)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DataApprovalRequestsContainer);
