import { Record } from "immutable";
import { equalsIgnoreCase, getStringValue, isString } from "../util";

interface PolicyInfoPath {
  value: string;
}

// TODO: Remove once list group policies response uses the correct type for policy info path
//       https://www.tracker.dtf.signify.com/browse/IOTSEC-2092
type Path = PolicyInfoPath | string;

export interface PolicyInfoAttributes {
  name: string;
  description: string;
  version: string;
  path: Path;
}

export class PolicyInfo extends Record({
  name: "",
  description: "",
  version: "",
  path: "",
}) implements PolicyInfoAttributes {

  public static EMPTY: PolicyInfo = new PolicyInfo();

  public readonly name: string;
  public readonly description: string;
  public readonly version: string;
  public readonly path: Path;

  // We need to override equals because this Record contains a nested Object property
  //
  // Reference: https://immutable-js.github.io/immutable-js/docs/#/Record/equals
  // Value equality: Records use value equality when compared with is() or record.equals().
  // That is, two Records with the same keys and values are equal. Plain objects use reference
  // equality. Two objects with the same keys and values are not equal since they are different
  // objects. This is important to consider when using objects as keys in a Map or values in a
  // Set, which use equality when retrieving values.
  public equals(other: PolicyInfo): boolean {
    return equalsIgnoreCase(this.getName(), other.getName()) &&
      equalsIgnoreCase(this.getDescription(), other.getDescription()) &&
      equalsIgnoreCase(this.getVersion(), other.getVersion()) &&
      equalsIgnoreCase(this.getPath(), other.getPath());
  }

  public getName(): string {
    return getStringValue(this.name);
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public getVersion(): string {
    return getStringValue(this.version);
  }

  public getPath(): string {

    if (isString(this.path)) {
      return getStringValue(this.path);
    }

    const { value = "" } = this.path as PolicyInfoPath || {};

    return getStringValue(value);
  }
}
