import { Record } from "immutable";
import { getStringValue } from "@util";

export interface TrainingCertificateInfoAttributes {
  trainingCertificateId: string;
  fileName: string;
  uploadedDate: string;
  certifiedDate: string;
  expiryDate: string;
  expired: boolean;
}

export class TrainingCertificateInfo extends  Record({
  trainingCertificateId: "",
  fileName: "",
  uploadedDate: "",
  certifiedDate: "",
  expiryDate: "",
  expired: false,
}) implements TrainingCertificateInfoAttributes {

  public static EMPTY: TrainingCertificateInfo = new TrainingCertificateInfo();

  public readonly trainingCertificateId: string;
  public readonly fileName: string;
  public readonly uploadedDate: string;
  public readonly certifiedDate: string;
  public readonly expiryDate: string;
  public readonly expired: boolean;

  public getTrainingCertificateId(): string {
    return getStringValue(this.trainingCertificateId);
  }

  public getFileName(): string {
    return getStringValue(this.fileName);
  }

  public getUploadedDate(): string {
    return getStringValue(this.uploadedDate);
  }

  public getCertifiedDate(): string {
    return getStringValue(this.certifiedDate);
  }

  public getExpiryDate(): string {
    return getStringValue(this.expiryDate);
  }

  public isExpired(): boolean {
    return this.expired;
  }
}
