import React from "react";
import { Redirect, useHistory, useParams, useRouteMatch, useLocation } from "react-router-dom";
import DeviceDetails from "./containers/DeviceDetails";
import { getSelectedTab } from "./selectors";
import {
  Device,
  DeviceDataModel,
  DeviceDataModelAttributes,
  DeviceTypeListItem,
  JsonSchemaMetadata
} from "@data";
import { getPathToDeviceEnrollment } from "@modules/deviceEnrollmentDetails/helpers";
import { useDispatch, useStore } from "react-redux";
import { useDeviceDetailsQueryParams } from "@hooks";
import { DeviceDetailsTabsType } from "@modules/deviceDetails/components/DeviceDetailsTabs";
import {
  getPathToDeviceEnrollmentView,
  getPathToDeviceBootstrapView,
  getPathToDeviceConfigurationView, getPathToDeviceValidationDetailsView
} from "@modules/deviceDetails/helpers";
import { getPathToSchema } from "@modules/schemaDetails/helpers";
import { getPathToDeviceType } from "@modules/deviceTypeDetails/helpers";
import { getEnrollDevicePath } from "@modules/deviceEnrollmentWizard/helpers";
import { LEGACY_MODULE_PATH } from "@modules/deviceValidationDetails/constants";
import { getPathToDeviceValidationDetails } from "@modules/deviceValidationDetails/helpers";
import { setDeviceRequests } from "./actions";

export function DeviceDetailsView() {

  const matchesLegacyValidationDetailsPage = useRouteMatch(LEGACY_MODULE_PATH);

  const location = useLocation<any>();

  const history = useHistory();

  const store = useStore();

  const dispatch = useDispatch();

  const { id: deviceId } = useParams<{ id: string }>();

  const deviceRequests = location.state ? location.state.deviceRequests : [];

  const lastSelectedTab = React.useMemo(() => getSelectedTab(store.getState()), [store]);

  const { selectedTab = DeviceDetailsTabsType.ENROLLMENT } = useDeviceDetailsQueryParams();

  const showEnrollmentDetails = React.useCallback((device: Device) =>
    history.push(getPathToDeviceEnrollment(device.getBatchId())), [history]);

  const enrollDevice = React.useCallback(() =>
    history.push(getEnrollDevicePath()), [history]);

  const showSchemaDetailsInNewTab = React.useCallback((schema: JsonSchemaMetadata) =>
    window.open(getPathToSchema(schema.getId()), "_blank", "noopener,noreferrer"), []);

  const showDeviceTypeDetailsInNewTab = React.useCallback((deviceType: DeviceTypeListItem) =>
    window.open(getPathToDeviceType(deviceType.getTypeIdentity()), "_blank", "noopener,noreferrer"), []);

  if (matchesLegacyValidationDetailsPage?.isExact) {
    return <Redirect to={getPathToDeviceValidationDetails(deviceId)} />;
  }

  if (selectedTab === null) {
    switch (lastSelectedTab) {
    case DeviceDetailsTabsType.BOOTSTRAP:
      return <Redirect to={getPathToDeviceBootstrapView(deviceId)} />;
    case DeviceDetailsTabsType.CONFIGURATION:
      return <Redirect to={getPathToDeviceConfigurationView(deviceId)} />;
    case DeviceDetailsTabsType.ENROLLMENT:
      return <Redirect to={getPathToDeviceEnrollmentView(deviceId)} />;
    case DeviceDetailsTabsType.VALIDATION:
      return <Redirect to={getPathToDeviceValidationDetailsView(deviceId)} />;
    default:
      return <Redirect to={getPathToDeviceEnrollmentView(deviceId)} />;
    }
  }

  React.useEffect(() => {
    dispatch(setDeviceRequests(deviceRequests.map(
      (requestAttrs: DeviceDataModelAttributes) => new DeviceDataModel(requestAttrs))));
  }, []);

  return (
    <DeviceDetails
      selectedTab={selectedTab}
      key={deviceId}
      location={location}
      showEnrollmentDetails={showEnrollmentDetails}
      openDeviceEnrollmentWizard={enrollDevice}
      showSchemaDetailsInNewTab={showSchemaDetailsInNewTab}
      showDeviceTypeDetailsInNewTab={showDeviceTypeDetailsInNewTab}
    />
  );
}

export default DeviceDetailsView;
