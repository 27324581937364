import React from "react";
import { CreateSecurityGroupResponse } from "@network";
import {
  UseApiRequestModel,
  UseApiRequestProps,
  useApiRequest,
  useSecurityGroupsClient,
} from "@hooks";

type SuccessResponse = CreateSecurityGroupResponse;

export interface UseCreateSecurityGroupProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  json?: string;
}

export interface UseCreateSecurityGroupModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseCreateSecurityGroupActions {
  createSecurityGroup: () => void;
  reset: () => void;
}

type Props = UseCreateSecurityGroupProps;
type Model = UseCreateSecurityGroupModel;
type Actions = UseCreateSecurityGroupActions;
type Result = [Model, Actions];

export const useCreateSecurityGroup = (props: Props = {}): Result => {

  const {
    json = "",
    defaultErrorMessage = "Failed to create security group",
    ...otherProps
  } = props;

  const SecurityGroupsClient = useSecurityGroupsClient();

  const makeApiRequest = React.useCallback(() =>
      SecurityGroupsClient.createSecurityGroup(json),
    [SecurityGroupsClient, json]);

  const [{showSuccessView, ...baseModel}, {reset, refresh: createSecurityGroup}] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
      deferRequest: true,
    });

  const successMessage = React.useMemo(() => showSuccessView ?
    "Security Group Created" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    reset,
    createSecurityGroup,
  }), [
    reset,
    createSecurityGroup,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useCreateSecurityGroup;
