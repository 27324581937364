import React from "react";
import { getStringValue, isEmptyString } from "@util";
import { createComparator, getSearchResults } from "@components/policies-list/helpers";
import { AuthTokenContext } from "@components/auth-token-provider";
import { PolicyOperationPrincipal } from "@hooks";
import PoliciesListColumn from "@components/policies-list/PoliciesListColumn";
import {
  EffectivePolicy,
  EffectivePolicyAttributes,
  ManagedPolicyAttributes,
  Policy,
  PolicyAttributes,
  PolicyKind,
} from "@data";
import {
  AuthorizationServiceClient,
  GetEffectivePolicyResponse,
  GetManagedPoliciesResponse,
  GetPoliciesResponse,
  PolicyClient,
  RestClientError,
} from "@network";

export const sortAndFilterPolicyResult = (props: {
  items: Policy[],
  nameFilter: string,
  sortOrderAscending: boolean,
  sortByColumn: PoliciesListColumn
}) => {

  const {items, nameFilter, sortOrderAscending, sortByColumn } = props;

  const comparator = React.useMemo(() =>
    createComparator(sortByColumn, sortOrderAscending), [sortByColumn, sortOrderAscending]);

  const sortedPolicies = React.useMemo(() => items.sort(comparator).slice(), [items, comparator]);

  return React.useMemo(() =>
    getSearchResults(sortedPolicies, nameFilter), [sortedPolicies, nameFilter]);
};

export interface UsePoliciesOptions {
  searchDisabled?: boolean;
  excludedPolicyNames?: string[];
}

export interface UserPoliciesModel {
  policies: Policy[];
  nameFilter: string;
  errorMessage: string;
  errorStatus: number | string;
  showLoadMoreButton: boolean;
  sortOrderAscending: boolean;
  showSearch: boolean;
  showNotFound: boolean;
  showLoadingIndicator: boolean;
  totalNumPolicies: number;
  totalNumVisiblePolicies: number;
  sortByColumn: PoliciesListColumn;
  excludedPolicyNames: string[];
}

export interface UsePoliciesActions {
  refresh: () => void;
  loadMore: () => void;
  setNameFilter: (nameFilter: string) => void;
  setSortByColumn: (column: PoliciesListColumn) => void;
  setSortOrderAscending: (ascending: boolean) => void;
}

type Model = UserPoliciesModel;
type Actions = UsePoliciesActions;
type GetPoliciesApi = (authToken: string,
                       nextPage?: string,
                       nameFilter?: string) => Promise<GetPoliciesResponse>;

export const usePolicies = (getPolicies?: GetPoliciesApi,
                            options: UsePoliciesOptions = {}): [Model, Actions] => {

  const { searchDisabled = false, excludedPolicyNames = [] } = options;

  const authToken = React.useContext(AuthTokenContext);
  const [items, setItems] = React.useState<Policy[]>([]);
  const [nameFilter, setNameFilter] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorStatus, setErrorStatus] = React.useState<string | number>("");
  const [nextPageToken, setNextPageToken] = React.useState("");
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);
  const [sortOrderAscending, setSortOrderAscending] = React.useState(true);
  const [sortByColumn, setSortByColumn] = React.useState(PoliciesListColumn.NONE);
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);
  const [showNotFound, setShowNotFound] = React.useState(false);
  const [showAccessDenied, setShowAccessDenied] = React.useState(false);

  const fetchPolicies = React.useCallback(() =>
      getPolicies === undefined ?
        PolicyClient.getPolicies(authToken, nextPageToken, nameFilter)
         : getPolicies(authToken, nextPageToken, nameFilter),
    [authToken, nextPageToken, nameFilter]);

  React.useEffect(() => {

    let ignore = false;

    setShowLoadingIndicator(true);
    setErrorMessage("");

    fetchPolicies()
      .then((response: GetPoliciesResponse) => {
        if (!ignore) {
          const { policies: results = [] , paging: { next = ""} = {} } = response;
          setItems(s => s.concat(results.map(attrs => new Policy(attrs))));
          setNextPageToken(next || "");
          setShowLoadingIndicator(false);
        }
      }, (response: RestClientError) => {
        if (!ignore) {
          const { status, error = "Fetch policies failed" } = response;
          if (status === 404) {
            setShowNotFound(true);
          }
          if (status === 403) {
            setShowAccessDenied(true);
          }
          setErrorMessage(error);
          setErrorStatus(status);
          setShowLoadingIndicator(false);
        }
      });
    return () => { ignore = true; };

  }, [
    lastRefresh,
    setItems,
    setNextPageToken,
    setErrorMessage,
    setErrorStatus,
    setShowLoadingIndicator,
    setShowAccessDenied,
    setShowNotFound,
  ]);

  const showLoadMoreButton = React.useMemo(() =>
    !isEmptyString(nextPageToken) && isEmptyString(errorMessage) && !showLoadingIndicator,
    [nextPageToken, errorMessage, showLoadingIndicator]);

  const loadMore = React.useCallback(() => {
    setLastRefresh(+new Date);
  }, [setLastRefresh]);

  const refresh = React.useCallback(() => {
    setNextPageToken("");
    setItems([]);
    setLastRefresh(+new Date);
    setSortByColumn(PoliciesListColumn.NONE);
    setSortOrderAscending(true);
    setShowNotFound(false);
    setShowAccessDenied(false);
  }, [setNextPageToken, setItems, setLastRefresh, setSortByColumn,
    setSortOrderAscending, setShowNotFound, setShowAccessDenied]);

  const updateNameFilter = React.useCallback((updatedNameFilter: string) => {
    setNameFilter(updatedNameFilter);
    if (!searchDisabled) {
      refresh();
    }
  }, [setNameFilter, searchDisabled, refresh]);

  const policies = sortAndFilterPolicyResult({items, nameFilter, sortOrderAscending, sortByColumn});

  const totalNumPolicies = React.useMemo(() => items.length, [items]);

  const totalNumVisiblePolicy = React.useMemo( () => policies.length, [policies]);

  const showSearch = React.useMemo(() =>
    !isEmptyString(nameFilter) || totalNumPolicies > 1, [totalNumPolicies, nameFilter]);

  const showErrorView = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]);

  const showNoResultsView = React.useMemo(() =>
    totalNumVisiblePolicy === 0 && !showErrorView && !showLoadingIndicator,
    [totalNumVisiblePolicy, showErrorView, showLoadingIndicator]);

  const model = React.useMemo<Model>(() => ({
    policies,
    nameFilter,
    errorMessage,
    errorStatus,
    showLoadMoreButton,
    sortOrderAscending,
    sortByColumn,
    showSearch,
    showNotFound,
    showAccessDenied,
    showLoadingIndicator,
    totalNumPolicies,
    totalNumVisiblePolicies: totalNumVisiblePolicy,
    excludedPolicyNames,
  }), [
    policies,
    nameFilter,
    errorMessage,
    showLoadMoreButton,
    sortOrderAscending,
    sortByColumn,
    showSearch,
    showLoadingIndicator,
    totalNumPolicies,
    showNoResultsView,
    showNotFound,
    showAccessDenied,
    totalNumVisiblePolicy,
    excludedPolicyNames,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    refresh,
    loadMore,
    setNameFilter: updateNameFilter,
    setSortByColumn,
    setSortOrderAscending,
  }) , [
    refresh,
    loadMore,
    updateNameFilter,
    setSortByColumn,
    setSortOrderAscending,
  ]);

  return [model, actions];
};

export const useAccountPolicies = (principalType: PolicyOperationPrincipal,
                                   principalId: string = ""): [Model, Actions] => {

  const getAllPolicies: GetPoliciesApi = (authToken: string, nextPage: string = "", nameFilter: string = "") =>
    PolicyClient.getPolicies(authToken, nextPage, nameFilter);

  const getAccountPolicies: GetPoliciesApi = (authToken: string, nextPage: string = "", nameFilter: string = "") =>
    AuthorizationServiceClient.getEffectivePolicies(authToken, principalType, principalId, nextPage, nameFilter)
      .then(({policies = [], paging: { next = ""} = {} }) => ({
        policies: (policies.map(attrs => new EffectivePolicy(attrs))).filter((policy: EffectivePolicy) =>
          policy.getKind() === PolicyKind.CUSTOMER)
          .filter((policy: EffectivePolicy) =>
            policy.getPolicy().getName().indexOf("implicit-permissions") === -1)
          .map(effectivePolicy => effectivePolicy.getPolicy()),
        next: next || "",
      }));

  const getGroupAccountPolicies: GetPoliciesApi = (authToken: string, nameFilter: string = "") =>
    AuthorizationServiceClient.getEffectiveGroupPolicies(authToken, principalId, nameFilter)
      .then((attrs: EffectivePolicyAttributes[]) => ({
    policies: attrs.map(attr => new EffectivePolicy(attr)).filter((policy: EffectivePolicy) =>
      policy.getKind() === PolicyKind.CUSTOMER)
      .map(effectivePolicy => effectivePolicy.getPolicy())
  }));

  const getPolicies: GetPoliciesApi = (authToken: string, nextPage: "", nameFilter: string = "") => {
    if (isEmptyString(principalId)) {
      return getAllPolicies(authToken, nextPage, nameFilter);
    } else if (principalType !== PolicyOperationPrincipal.GROUP) {
      return getAccountPolicies(authToken, nextPage, nameFilter);
    } else {
      return getGroupAccountPolicies(authToken, nameFilter);
    }
  };

  return usePolicies(getPolicies);
};

export const useManagedEffectivePolicies = (principalType: PolicyOperationPrincipal,
                                            principalId: string = ""): [Model, Actions] => {

  const getAllPolicies: GetPoliciesApi = (authToken: string, nextPage: string = "", nameFilter: string = "") =>
    PolicyClient.getPolicies(authToken, nextPage, nameFilter, );

  const getManagedPolicies: GetPoliciesApi = (authToken: string, nextPage: string = "", nameFilter: string = "") =>
    AuthorizationServiceClient.getEffectivePolicies(authToken, principalType, principalId, nextPage, nameFilter )
      .then(({policies = [], paging: { next = ""} = {} }) => ({
        policies: (policies.map(attrs => new EffectivePolicy(attrs))).filter((policy: EffectivePolicy) =>
          policy.getKind() === PolicyKind.MANAGED).map(effectivePolicy => effectivePolicy.getPolicy()),
        next: next || "",
      }));

  const getGroupManagedPolicies: GetPoliciesApi = (authToken: string) =>
    AuthorizationServiceClient.getEffectiveGroupPolicies(authToken, principalId)
      .then((attrs: EffectivePolicyAttributes[]) => ({
        policies: attrs.map(attr => new EffectivePolicy(attr)).filter((policy: EffectivePolicy) =>
          policy.getKind() === PolicyKind.MANAGED)
          .map(effectivePolicy => effectivePolicy.getPolicy())
      }));

  const getPolicies: GetPoliciesApi = (authToken: string, nextPage: "", nameFilter: string = "") => {
    if (isEmptyString(principalId)) {
      return getAllPolicies(authToken, nextPage, nameFilter);
    } else if (principalType !== PolicyOperationPrincipal.GROUP) {
      return getManagedPolicies(authToken, nextPage, nameFilter);
    } else {
      return getGroupManagedPolicies(authToken);
    }
  };

  return usePolicies(getPolicies);
};

const getAllEffectivePolicies = (props: {
  authToken: string,
  principalType: PolicyOperationPrincipal,
  principalId: string,
}): Promise<EffectivePolicy[]> => {

  const { principalType, principalId, authToken } = props;

  const getNextPage = (nextPage: string = ""): Promise<EffectivePolicy[]> =>
    AuthorizationServiceClient.getEffectivePolicies(authToken, principalType, principalId , nextPage)
      .then((response: GetEffectivePolicyResponse) => {

        const { policies = [], paging: { next = "" } = { next: "" } } = response;

        const nextPageToken = getStringValue(next || "");

        const allEffectivePolicies = policies.map(attrs => new EffectivePolicy(attrs));

        if (isEmptyString(nextPageToken)) {
          return Promise.resolve(allEffectivePolicies);
        }

        return getNextPage(nextPageToken)
          .then((results: EffectivePolicy[]) => allEffectivePolicies.concat(results));
      });

  return getNextPage();

};

const getGroupEffectivePolicies = (props: {
  authToken: string,
  groupName: string,
}): Promise<EffectivePolicy[]> => {

  const { groupName, authToken } = props;

  return AuthorizationServiceClient.getEffectiveGroupPolicies(authToken, groupName)
    .then((attrs: EffectivePolicyAttributes[]) =>
    attrs.map(attr => new EffectivePolicy(attr)));
};

export const useAccountPolicyThatIsNotAttachedToPrincipal = (principalType: PolicyOperationPrincipal,
                                                             principalId: string = "",
                                                             policyNames: string[] = []): [Model, Actions] => {

  const attachedPolicyNames = React.useRef<string[]>([]);
  const attachedPolicyNamesFetched = React.useRef(false);

  const getAllPolicies: GetPoliciesApi = (authToken: string, nextPage: string = "") =>
    PolicyClient.getPolicies(authToken, nextPage);

  const getAccountPolicyThatIsNotAttachedToPrincipal: GetPoliciesApi =
    async (authToken: string, nextPage: string = "", nameFilter = "") => {

      attachedPolicyNames.current = attachedPolicyNamesFetched.current ? policyNames :
        principalType !== PolicyOperationPrincipal.GROUP ?
        await getAllEffectivePolicies({authToken, principalType, principalId })
        .then(effectivePolicies => {
          attachedPolicyNamesFetched.current = true;
          return effectivePolicies.filter(effectivePolicy => effectivePolicy.getKind() === PolicyKind.CUSTOMER)
            .map(policy => policy.getPolicy().getName());
        })
      :  await getGroupEffectivePolicies({authToken, groupName: principalId })
          .then(effectivePolicies => {
            attachedPolicyNamesFetched.current = true;
            return effectivePolicies.filter(effectivePolicy => effectivePolicy.getKind() === PolicyKind.CUSTOMER)
              .map(policy => policy.getPolicy().getName());
          });

      return PolicyClient.getPolicies(authToken, nextPage, nameFilter)
        .then((response: GetPoliciesResponse) => {

          const { policies: results = [], paging: { next = "" } = { next: "" } } = response;

          const nextPageToken = getStringValue(next || "");

          return {
            policies: results.filter((attrs: PolicyAttributes) =>
              attachedPolicyNames.current.indexOf(new Policy(attrs).getName()) === -1),
            paging: {
              next: nextPageToken,
            },
          };
        });
    };

  const getPolicies: GetPoliciesApi = (authToken: string, nextPage: string = "", nameFilter: string = "") => {
    if (isEmptyString(principalId)) {
      return getAllPolicies(authToken, nextPage, nameFilter);
    } else {
      return getAccountPolicyThatIsNotAttachedToPrincipal(authToken, nextPage, nameFilter);
    }
  };

  return usePolicies(getPolicies, { excludedPolicyNames: attachedPolicyNames.current });
};

export const useManagedPolicyThatIsNotAttachedToPrincipal = (principalType: PolicyOperationPrincipal,
                                                             principalId: string = ""): [Model, Actions] => {

  const getAllPolicies: GetPoliciesApi = (authToken: string, nextPage: string = "") =>
    PolicyClient.getPolicies(authToken, nextPage);

  const getAccountPolicyThatIsNotAttachedToPrincipal: GetPoliciesApi =
    async (authToken: string, nextPage: string = "") => {

      const excludedManagedPolicyName = principalType !== PolicyOperationPrincipal.GROUP ?
        await getAllEffectivePolicies({authToken, principalType, principalId })
          .then(effectivePolicies =>
            effectivePolicies.filter(effectivePolicy => effectivePolicy.getKind() === PolicyKind.MANAGED)
              .map(policy => policy.getPolicy().getName()))
        :  await getGroupEffectivePolicies({authToken, groupName: principalId })
          .then(effectivePolicies =>
            effectivePolicies.filter(effectivePolicy => effectivePolicy.getKind() === PolicyKind.MANAGED)
              .map(policy => policy.getPolicy().getName()));

      return AuthorizationServiceClient.getManagedPolicies(authToken, nextPage)
        .then((response: GetManagedPoliciesResponse) => {

          const { policies: results = [], paging: { next = "" } = { next: "" } } = response;

          const nextPageToken = getStringValue(next || "");

          const items = results.map((attrs: ManagedPolicyAttributes) =>
          new Policy(attrs.policy));

          return {
            policies: items.filter((attrs: PolicyAttributes) =>
              excludedManagedPolicyName.indexOf(new Policy(attrs).getName()) === -1),
            paging: {
              next: nextPageToken,
            },
          };
        });
    };

  const getPolicies: GetPoliciesApi = (authToken: string, nextPage: string = "") => {
    if (isEmptyString(principalId)) {
      return getAllPolicies(authToken, nextPage);
    } else {
      return getAccountPolicyThatIsNotAttachedToPrincipal(authToken, nextPage);
    }
  };

  return usePolicies(getPolicies);
};
