import { AppSchema } from "@schemas";
import { createSelectors } from "@base";
import { createSelector } from "reselect";
import { MODULE_ID, SCHEMA_KEY } from "../constants";
import { ActionMenuItem } from "@components/actions-menu";
import { DeviceTypeAction } from "@modules/deviceTypeManager/reducers";
import {
  DeviceTypeModelV3,
  DeviceTypeModelV3Attributes,
  DeviceTypeModelV2,
  DeviceTypeModelV2Attributes,
  DeviceTypeState, DeviceTypeModelVersion,
} from "@data";
import {
  DEFAULT_STATE,
  DeviceTypeDetailsSchema,
  DeviceTypeSchemaViewMode
} from "../reducers";
import isDeviceTypeV2APIEnabled from "@util/isDeviceTypeV2APIEnabled";

// Each item starts off disabled and will switch depending on state of active device type
export const DEFAULT_ACTION_MENU_ITEMS: ActionMenuItem[] = [
  {
    id: DeviceTypeAction.CLONE,
    name: "Clone Device Type",
    disabled: false,
  },
  {
    id: DeviceTypeAction.EDIT,
    name: "Edit Device Type",
    disabled: true,
  },
  {
    id: DeviceTypeAction.DRAFT,
    name: "Draft New Device Type Version",
    disabled: true,
  },
  {
    id: DeviceTypeAction.PROMOTE,
    name: "Promote Device Type",
    disabled: true,
  },
  {
    id: DeviceTypeAction.DEPRECATE,
    name: "Deprecate Device Type",
    disabled: true,
  },
  {
    id: DeviceTypeAction.DECOMMISSION,
    name: "Decommission Device Type",
    disabled: true,
  },
  {
    id: DeviceTypeAction.DELETE,
    name: "Delete Device Type",
    disabled: true,
  },
  {
    id: DeviceTypeAction.ENROLL_DEVICE,
    name: "Enroll New Device",
    disabled: true,
  },
];

export const {
  deviceTypeV3: getDeviceTypeV3Attributes,
  deviceTypeV2: getDeviceTypeV2Attributes,
  softwareVersions: getSoftwareVersions,
  selectedSoftwareVersion: getSelectedSoftwareVersion,
  showHistoricalData: isHistoricalDataViewSelected,
  jsonV3: getJsonV3,
  jsonV2: getJsonV2,
  etag: getEtag,
  modelVersion: getModelVersion,
  schemaViewMode: getSchemaViewMode,
  isLatest,
  getErrorMessage,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isNotFoundVisible,
  getCurrentAccountId,
} = createSelectors<DeviceTypeDetailsSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

const isV2ApiEnabled = () => isDeviceTypeV2APIEnabled();

export const isHistoricalApiModel: (state: AppSchema) => boolean = createSelector(
  getModelVersion, (modelVersion: DeviceTypeModelVersion) =>
    DeviceTypeModelVersion.HISTORICAL === modelVersion);

export const isRegionalApiModel: (state: AppSchema) => boolean = createSelector(
  [getModelVersion, isHistoricalApiModel],
  (modelVersion: DeviceTypeModelVersion, historical: boolean) => !historical);

export const isSoftwareVersionsTabVisible: (state: AppSchema) => boolean = createSelector(
  isHistoricalApiModel, (historical: boolean) => historical);

export const isModelVersionNotSupportedErrorVisible: (state: AppSchema) => boolean = createSelector(
  [isHistoricalApiModel, isV2ApiEnabled], (historical: boolean, v2ApiEnabled: boolean) =>
    historical && !v2ApiEnabled);

export const isConfigurationViewSelected: (state: AppSchema) => boolean = createSelector(
  getSchemaViewMode, (viewMode: DeviceTypeSchemaViewMode) =>
    DeviceTypeSchemaViewMode.CONFIGURATION === viewMode);

export const isMetadataViewSelected: (state: AppSchema) => boolean = createSelector(
  getSchemaViewMode, (viewMode: DeviceTypeSchemaViewMode) =>
    DeviceTypeSchemaViewMode.METADATA === viewMode);

export const isSoftwareVersionSelected: (state: AppSchema) => boolean = createSelector(
  [getSelectedSoftwareVersion, getSoftwareVersions],
  (selectedSoftwareVersion: string, softwareVersions: string[]) =>
    selectedSoftwareVersion.length > 0 && softwareVersions.indexOf(selectedSoftwareVersion) >= 0);

export const isSoftwareVersionDetailsVisible: (state: AppSchema) => boolean = createSelector(
  [
    isLoadingIndicatorVisible,
    isSoftwareVersionSelected,
  ],
  (isLoadingDeviceTypeDetails: boolean,
   softwareVersionSelected: boolean) => {

    return !isLoadingDeviceTypeDetails
      && softwareVersionSelected;
  });

export const getDeviceTypeV3: (state: AppSchema) => DeviceTypeModelV3 = createSelector(
  getDeviceTypeV3Attributes, (attrs: DeviceTypeModelV3Attributes) =>
    new DeviceTypeModelV3(attrs));

export const getDeviceTypeV2: (state: AppSchema) => DeviceTypeModelV2 = createSelector(
  getDeviceTypeV2Attributes, (attrs: DeviceTypeModelV2Attributes) =>
    new DeviceTypeModelV2(attrs));

export const didDeviceTypeFailToLoad: (state: AppSchema) => boolean = createSelector(
  [isErrorMessageVisible, getJsonV3], (showErrorMessage: boolean, jsonV3: string) =>
    showErrorMessage && jsonV3 === DEFAULT_STATE.jsonV3);

export const isActionsButtonDisabled: (state: AppSchema) => boolean = createSelector(
  [isLoadingIndicatorVisible, didDeviceTypeFailToLoad],
  (loading: boolean, deviceTypeFailedToLoad: boolean) =>
    loading || deviceTypeFailedToLoad);

export const isToggleJsonApiSwitchVisible: (state: AppSchema) => boolean = createSelector(
  [isRegionalApiModel, isLoadingIndicatorVisible, didDeviceTypeFailToLoad, isV2ApiEnabled],
  (regional: boolean, loading: boolean, deviceTypeFailedToLoad: boolean, v2ApiEnabled: boolean) =>
    regional && v2ApiEnabled && !loading && !deviceTypeFailedToLoad);

export const getJson: (state: AppSchema) => string = createSelector(
  [
    isLoadingIndicatorVisible,
    didDeviceTypeFailToLoad,
    isRegionalApiModel,
    isHistoricalDataViewSelected,
    getJsonV3,
    getJsonV2,
  ],
  (loading: boolean,
   deviceTypeFailedToLoad: boolean,
   regional: boolean,
   historicalViewSelected: boolean,
   jsonV3: string,
   jsonV2: string) => {

    // Do not show device type while loading - or if the device type failed to load - to avoid
    // potentially showing stale - or incorrect - data or the wrong model version.
    if (loading || deviceTypeFailedToLoad) {
      return JSON.stringify({}, null, "  ");
    }

    return !regional || historicalViewSelected ? jsonV2 : jsonV3;
  });

export const getDeviceTypeTags: (state: AppSchema) => string[] = createSelector(
  getDeviceTypeV3, (deviceType: DeviceTypeModelV3) => {

    const { tags = [] } = deviceType.toJS();

    return tags.map((tag: string) => tag.trim()).filter((tag: string) => tag.length > 0);
  });

export const getDeviceTypeId: (state: AppSchema) => string = createSelector(
  getDeviceTypeV3, (deviceType: DeviceTypeModelV3) =>
    deviceType.getTypeIdentity());

export const getDeviceTypeNamespace: (state: AppSchema) => string = createSelector(
  getDeviceTypeV3, (deviceType: DeviceTypeModelV3) =>
    deviceType.getNamespace());

export const getDeviceTypeName: (state: AppSchema) => string = createSelector(
  getDeviceTypeV3, (deviceType: DeviceTypeModelV3) =>
    deviceType.getName());

export const getDeviceTypeVersion: (state: AppSchema) => string = createSelector(
  getDeviceTypeV3, (deviceType: DeviceTypeModelV3) =>
    deviceType.getVersion());

export const getDeviceTypeState: (state: AppSchema) => DeviceTypeState = createSelector(
  getDeviceTypeV3, (deviceType: DeviceTypeModelV3) =>
    deviceType.state);

export const isDraftDeviceType: (state: AppSchema) => boolean = createSelector(
  getDeviceTypeState, (state: DeviceTypeState) =>
    DeviceTypeState.DRAFT === state);

export const isLegacyDraftDeviceType: (state: AppSchema) => boolean = createSelector(
  [isDraftDeviceType, getDeviceTypeVersion],
  (isDraft: boolean, version: string) =>
    isDraft && version === "0");

export const isReleasedDeviceType: (state: AppSchema) => boolean = createSelector(
  getDeviceTypeState, (state: DeviceTypeState) =>
    DeviceTypeState.RELEASED === state);

export const isDeprecatedDeviceType: (state: AppSchema) => boolean = createSelector(
  getDeviceTypeState, (state: DeviceTypeState) =>
    DeviceTypeState.DEPRECATED === state);

export const isDecommissionedDeviceType: (state: AppSchema) => boolean = createSelector(
  getDeviceTypeState, (state: DeviceTypeState) =>
    DeviceTypeState.DECOMMISSIONED === state);

export const isDeletedDeviceType: (state: AppSchema) => boolean = createSelector(
  getDeviceTypeState, (state: DeviceTypeState) =>
    DeviceTypeState.DELETED === state);

export const isEditButtonEnabled: (state: AppSchema) => boolean = createSelector(
  isDraftDeviceType, (isDraft: boolean) => isDraft);

export const isDraftButtonEnabled: (state: AppSchema) => boolean = createSelector(
  isReleasedDeviceType, (isReleased: boolean) => isReleased);

export const isPromoteButtonEnabled: (state: AppSchema) => boolean = createSelector(
  isDraftDeviceType, (isDraft: boolean) => isDraft);

// Unlike SRS v2, DTS v2 does not allow state transitions from DECOMMISSIONED => DEPRECATED
export const isDeprecateButtonEnabled: (state: AppSchema) => boolean = createSelector(
  [isReleasedDeviceType], (isReleased: boolean) => isReleased);

export const isDecommissionButtonEnabled: (state: AppSchema) => boolean = createSelector(
  [isDeprecatedDeviceType], (isDeprecated: boolean) => isDeprecated);

// DTS v2 does not allow deleting device types created w/ DTS v1 in state DRAFT aka DEVELOPMENT
export const isDeleteButtonEnabled: (state: AppSchema) => boolean = createSelector(
  [isDraftDeviceType, isLegacyDraftDeviceType, isDecommissionedDeviceType],
  (isDraft: boolean, isLegacyDraft: boolean, isDecommissioned: boolean) =>
    (isDraft && !isLegacyDraft) || isDecommissioned);

export const getActionMenuItems: (state: AppSchema) => ActionMenuItem[] = createSelector(
  [
    isEditButtonEnabled,
    isDraftButtonEnabled,
    isPromoteButtonEnabled,
    isDeprecateButtonEnabled,
    isDecommissionButtonEnabled,
    isDeleteButtonEnabled,
    isRegionalApiModel,
  ],
  (editEnabled: boolean,
   draftEnabled: boolean,
   promoteEnabled: boolean,
   deprecateEnabled: boolean,
   decommissionEnabled: boolean,
   deleteEnabled: boolean,
   regional: boolean) => {

    return DEFAULT_ACTION_MENU_ITEMS.map((action: ActionMenuItem) => {

      switch (action.id) {
        case DeviceTypeAction.EDIT:
          return {
            ...action,
            disabled: !editEnabled,
          };
        case DeviceTypeAction.DRAFT:
          return {
            ...action,
            disabled: !draftEnabled,
          };
        case DeviceTypeAction.PROMOTE:
          return {
            ...action,
            disabled: !promoteEnabled,
          };
        case DeviceTypeAction.DEPRECATE:
          return {
            ...action,
            disabled: !deprecateEnabled,
          };
        case DeviceTypeAction.DECOMMISSION:
          return {
            ...action,
            disabled: !decommissionEnabled,
          };
        case DeviceTypeAction.DELETE:
          return {
            ...action,
            disabled: !deleteEnabled,
          };
        case DeviceTypeAction.ENROLL_DEVICE:
          return {
            ...action,
            disabled: !regional,
          };
        default:
          return action;
      }
    });
  });

export const getDeviceTypeConfigurationSchemas: (state: AppSchema) => string[] = createSelector(
  [isRegionalApiModel, getDeviceTypeV3, getDeviceTypeV2],
  (regional: boolean, deviceTypeV3: DeviceTypeModelV3, deviceTypeV2: DeviceTypeModelV2) =>
    regional ? deviceTypeV3.getSchemas() : deviceTypeV2.getSchemas() );

export const getDeviceTypeMetadataSchemas: (state: AppSchema) => string[] = createSelector(
  [isRegionalApiModel, getDeviceTypeV3],
  (regional: boolean, deviceTypeV3: DeviceTypeModelV3) =>
    regional ? deviceTypeV3.getMetadataSchemas() : [] );

export const getDeviceTypeSchemas: (state: AppSchema) => string[] = createSelector(
  [isMetadataViewSelected, getDeviceTypeMetadataSchemas, getDeviceTypeConfigurationSchemas],
  (metadata: boolean, metadataSchemas: string[], configSchemas: string[]) =>
    metadata ? metadataSchemas : configSchemas );

export const isSchemaTableVisible: (state: AppSchema) => boolean = createSelector(
  [isErrorMessageVisible, isLoadingIndicatorVisible, getDeviceTypeSchemas],
  (error: boolean, loading: boolean) =>
    !error || !loading);
