import React from "react";
import { AppSchema } from "@schemas";
import { IdentityType } from "@data";
import { connect } from "react-redux";
import { isEmptyString, noop } from "@util";
import { DEFAULT_REDIRECT_URI } from "../constants";
import { isInformationBannerVisible } from "@main/selectors";
import { useLoginPageRedirectState } from "../LoginPageRedirect";
import {
  getErrorMessage,
  getIdentityType,
  isErrorMessageVisible,
  isLoadingIndicatorVisible,
  isServiceLoginFormVisible,
  isStartSessionVisible,
  isTermsDialogVisible,
} from "../selectors";
import {
  hideErrorMessage,
  hideTermsDialog,
  initialize,
  setIdentityType,
  showTermsDialog,
} from "../actions";
import LoginPage, { Actions, Model } from "../components/LoginPage";
import StartSessionContainer from "./StartSessionContainer";
import ServiceLoginFormContainer from "./ServiceLoginForm";
import UserLoginFormContainer from "./LoginFormContainer";

interface ContainerModel extends Model {
  showStartSession?: boolean;
  showServiceLoginForm?: boolean;
}

interface ContainerActions extends Actions {
  initialize?: (redirectUri?: string) => void;
  openUserLoginForm?: () => void;
}

export type LoginPageContainerProps = ContainerModel & ContainerActions & {
  children?: React.ReactNode;
};

const LoginPageContainer = (props: LoginPageContainerProps) => {

  const {
    showStartSession,
    showServiceLoginForm,
    initialize: initializeModule = noop,
    openUserLoginForm = noop,
    children,
    ...otherProps
  } = props;

  const { redirectUri = DEFAULT_REDIRECT_URI, userId = "", from: { search = "" } = { search: "" } } =
    useLoginPageRedirectState();

  const loginPageRedirectUri = React.useMemo(() => redirectUri || DEFAULT_REDIRECT_URI, [redirectUri]);

  const [initialized, setInitialized] = React.useState(false);

  const initializeLoginPage = React.useCallback(() => {

    // If the redirectUri contained a valid userId, make sure the user login form is selected
    if (showServiceLoginForm && !isEmptyString(userId)) {
      openUserLoginForm();
    }

    initializeModule(loginPageRedirectUri + search);

  }, [loginPageRedirectUri, search, showServiceLoginForm, userId, openUserLoginForm, initializeModule]);

  React.useEffect(() => {

    if (!initialized) {
      initializeLoginPage();
      setInitialized(true);
    }

  }, [initialized, initializeLoginPage, setInitialized]);

  if (!initialized) {
    return null;
  }

  if (showStartSession) {
    return <StartSessionContainer />;
  }

  return (
    <LoginPage {...otherProps}>
      {showServiceLoginForm && <ServiceLoginFormContainer />}
      {!showServiceLoginForm && <UserLoginFormContainer />}
      {children}
    </LoginPage>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  showStartSession: isStartSessionVisible(state),
  showServiceLoginForm: isServiceLoginFormVisible(state),
  errorMessage: getErrorMessage(state),
  showErrorMessage: isErrorMessageVisible(state),
  showTermsDialog: isTermsDialogVisible(state),
  showProgressIndicator: isLoadingIndicatorVisible(state),
  identityType: getIdentityType(state),
  informationBannerVisible: isInformationBannerVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  initialize: redirectUri => dispatch(initialize(redirectUri)),
  openTermsDialog: () => dispatch(showTermsDialog()),
  closeTermsDialog: () => dispatch(hideTermsDialog()),
  hideErrorMessage: () => dispatch(hideErrorMessage()),
  setIdentityType: (identityType: IdentityType) => dispatch(setIdentityType(identityType)),
  openUserLoginForm: () => dispatch(setIdentityType(IdentityType.USER)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, LoginPageContainerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(LoginPageContainer);
