import React from "react";
import { connect } from "react-redux";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { Actions, Model, SoftwareVersionEditor } from "../components/SoftwareVersionEditor";
import { DeviceTypeDetailsSelectors, ManageSoftwareVersionsSelectors } from "../selectors";
import { DeviceTypeDetailsActions, ManageSoftwareVersionsActions } from "../actions";
import { ManageSoftwareVersionsAction } from "../reducers/manageSoftwareVersions";

interface OwnProps {
  title?: string;
}

interface ContainerModel extends Model {
  title?: string;
  softwareVersion?: string;
  errorMessage?: string;
  successMessage?: string;
  action?: ManageSoftwareVersionsAction;
}

interface ContainerActions extends Actions {
  clearSuccessMessage?: () => void;
}

type Props = WithSnackbarProps & ContainerModel & ContainerActions;

const SoftwareVersionEditorContainer = withSnackbar((props: Props) => {

  const {
    enqueueSnackbar,
    title,
    errorMessage = "",
    successMessage = "",
    action = "",
    softwareVersion = "",
    softwareVersions,
    selectedSoftwareVersion,
    showAccessDenied,
    showLoadingIndicator,
    disabled,
    clearErrorMessage,
    addSoftwareVersion,
    removeSoftwareVersion,
    setSelectedSoftwareVersion,
    clearSuccessMessage = noop,
  } = props;

  const showSnackbarMessage = (message: string,
                               variant: "success" | "error",
                               autoHideDuration = 2000) => {

    if (action.length > 0 && softwareVersion.length > 0 && message.length > 0) {
      enqueueSnackbar(message, {
        variant,
        autoHideDuration,
        key: encodeURIComponent(`${action}-${softwareVersion}-${variant}`),
        preventDuplicate: true,
      });
    }
  };

  const showSuccessMessage = () =>
    showSnackbarMessage(successMessage || title || "Success", "success");

  React.useEffect(() => {

    if (successMessage.length > 0) {
      showSuccessMessage();

      const timer = setTimeout(clearSuccessMessage, 1000);

      return () => clearTimeout(timer);
    }

    return () => noop;
  });

  return (
    <SoftwareVersionEditor
      title={title}
      disabled={disabled}
      errorMessage={errorMessage}
      softwareVersions={softwareVersions}
      selectedSoftwareVersion={selectedSoftwareVersion}
      showAccessDenied={showAccessDenied}
      showLoadingIndicator={showLoadingIndicator}
      clearErrorMessage={clearErrorMessage}
      addSoftwareVersion={addSoftwareVersion}
      removeSoftwareVersion={removeSoftwareVersion}
      setSelectedSoftwareVersion={setSelectedSoftwareVersion}
    />
  );
});

const mapStateToProps = (state: AppSchema, ownProps: OwnProps): ContainerModel => {

  const { title } = ownProps;

  const showLoadingIndicator = DeviceTypeDetailsSelectors.isLoadingIndicatorVisible(state) ||
    ManageSoftwareVersionsSelectors.isLoadingIndicatorVisible(state);

  return {
    title,
    showLoadingIndicator,
    disabled: showLoadingIndicator,
    softwareVersion: ManageSoftwareVersionsSelectors.getSoftwareVersion(state),
    errorMessage: ManageSoftwareVersionsSelectors.getErrorMessage(state),
    successMessage: ManageSoftwareVersionsSelectors.getSuccessMessage(state),
    action: ManageSoftwareVersionsSelectors.getAction(state),
    softwareVersions: DeviceTypeDetailsSelectors.getSoftwareVersions(state),
    selectedSoftwareVersion: DeviceTypeDetailsSelectors.getSelectedSoftwareVersion(state),
    showAccessDenied: ManageSoftwareVersionsSelectors.isAccessDeniedVisible(state),
  };
};

const mapDispatchToProps = (dispatch: any): ContainerActions => ({
  clearErrorMessage: () => dispatch(ManageSoftwareVersionsActions.clearErrorMessage()),
  clearSuccessMessage: () => dispatch(ManageSoftwareVersionsActions.clearSuccessMessage()),
  addSoftwareVersion: (softwareVersion: string) => {
    dispatch(ManageSoftwareVersionsActions.setSoftwareVersion(softwareVersion));
    return dispatch(ManageSoftwareVersionsActions.addSoftwareVersion(softwareVersion));
  },
  removeSoftwareVersion: (softwareVersion: string) => {
    dispatch(ManageSoftwareVersionsActions.setSoftwareVersion(softwareVersion));
    return dispatch(ManageSoftwareVersionsActions.showRemoveDialog());
  },
  setSelectedSoftwareVersion: (softwareVersion: string) =>
    dispatch(DeviceTypeDetailsActions.setSelectedSoftwareVersion(softwareVersion)),
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(SoftwareVersionEditorContainer);
