import React from "react";
import styles from "./styles";
import { Breadcrumb } from "@data";
import classnames from "classnames";
import { isEmptyString } from "@util";
import { Typography } from "@material-ui/core";
import { Link, UnderlineOptions } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import MuiBreadcrumbs, { BreadcrumbsProps as MuiBreadcrumbsProps } from "@material-ui/core/Breadcrumbs";

export interface CustomMuiBreadcrumbsProps extends MuiBreadcrumbsProps {
  items?: Breadcrumb[];
}

type Props = WithStyles< typeof styles> & CustomMuiBreadcrumbsProps;

export const Breadcrumbs = withStyles(styles)(((props: Props) => {

  const {
    classes,
    className,
    items = [],
    ...otherProps
  } = props;

  const breadcrumbs = React.useMemo(() => items.map((crumb, index) =>
    (isEmptyString(crumb.getURL()) || index === items.length - 1) ? (
      <Typography
        key={`crumb-${index}`}
        className={classnames("bold", classes.root, {[classes.bold]: index === items.length - 1})}
      >
        {crumb.getTitle()}
      </Typography>
      ) : (
      <Link
        underline={UnderlineOptions.HOVER}
        key={`crumb-${index}`}
        href={crumb.getURL()}
        className={classnames("breadcrumbLink", classes.root, className)}
      >
        {crumb.getTitle()}
      </Link>
    )), [items]);

  return (
    <MuiBreadcrumbs
      {...otherProps}
      separator={"›"}
      aria-label="breadcrumb"
      className={classnames("breadcrumbs", classes.root, className)}
    >
      {breadcrumbs}
    </MuiBreadcrumbs>
  );
}));

export default Breadcrumbs;
