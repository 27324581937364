import createStyles from "@material-ui/core/styles/createStyles";
import { primaryColor } from "@modules/userDetails/components/styles";
import { StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const noteTextField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& textarea": {
      fontSize: 14,
      fontWeight: 400,
      padding: 0,
      margin: 0,
      color: Color.BLACK4,
    },
  },
};

export const createNotesView = () =>
  createStyles({
    container: {
      marginTop: 16,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    createNoteContainer: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    actionsContainer: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      marginTop: 15,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    addNoteButton: {
      alignSelf: "flex-start",
    },
    note: {
      marginTop: 25,
    },
    cancelButton: {
    },
    saveButton: {
      marginLeft: 16,
    },
  });

export const notesListView = () =>
  createStyles({
    label: {
      color: Color.GREY7,
      fontFamily: "Roboto, sans-serif",
      fontSize: 20,
      fontWeight: 300,
      lineHeight: "16px",
      fontStyle: "normal",
    },
    container: {
      marginTop: 12,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    commentContainer: {
      marginTop: 12,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      borderBottom: "1px solid #e8e8e8 !important",
    },
    noResultText: {
      alignItems: "center",
      fontFamily: "Roboto, sans-serif",
      fontSize: 20,
      fontWeight: 400,
      lineHeight: "16px",
      padding: 5,
      margin: 0,
      textTransform: "none",
    },
    header: {
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 16,
    },
    heading: {
      color: Color.GREY9,
      fontSize: 14,
      fontWeight: 200,
      lineHeight: "21px",
    },
    userId: {
      fontSize: 18,
      fontWeight: 500,
      marginRight: 4,
    },
    timeStamp: {
      fontSize: 16,
      fontWeight: 400,
    },
    note: {
      color: Color.BLACK4,
      fontSize: 14,
      fontWeight: 300,
      padding: "4px 0",
      lineHeight: 2,
      margin: "8px 4px",
      whiteSpace: "pre-line",
    },
  });

export const styles = () =>
  createStyles({
    container: {
      color: primaryColor,
      marginTop: 16,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: 0,
    },
    errorMessageContainer: {
      marginBottom: 35,
    },
  });
