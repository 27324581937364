import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { setNameFilter, } from "../actions";
import { getNameFilter } from "../selectors";
import { useGetFederationProviders } from "@hooks";
import FederationProviders, { Actions, Model } from "../components/FederationProviders";
import { open as deleteFederationProvider } from "@modules/deleteFederationProvider/actions";
import { isSuccessMessageVisible as isDeleteSuccess } from "@modules/deleteFederationProvider/selectors";

export interface ContainerModel extends Model {
  deleteSuccess?: boolean;
}

export interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const FederationProvidersContainer = (props: Props) => {

  const { deleteSuccess } = props;

  const [model, actions] = useGetFederationProviders();

  const { refresh } = actions;

  React.useEffect(() => {
    if (deleteSuccess) {
      refresh();
    }
  }, [deleteSuccess, refresh]);

  return (
    <FederationProviders
      {...props}
      {...model}
      {...actions}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  nameFilter: getNameFilter(state),
  deleteSuccess: isDeleteSuccess(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setNameFilter: (nameFilter: string) => dispatch(setNameFilter(nameFilter)),
  deleteFederationProvider: federationProvider =>
    dispatch(deleteFederationProvider(federationProvider.getProviderId())),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(FederationProvidersContainer);
