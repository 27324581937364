import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ErrorView, SummaryViewDateLabel, SummaryViewLabel } from "@components";
import { authenticationSummaryView as styles } from "../styles";
import capitalize from "lodash/capitalize";

export interface Model {
  userId?: string;
  errorMessage?: string;
  anonymousId?: string;
  lastSuccessfulLogin?: string;
  lastFailedLogin?: string;
  lastPasswordChange?: string;
  userType?: string;
  createdAt?: string;
  updatedAt?: string;
  failedLogins?: number;
  loading?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const UserAuthenticationSummaryView = withStyles(styles)((props: Props) => {

  const {
    classes,
    errorMessage,
    userId,
    anonymousId,
    lastSuccessfulLogin,
    lastFailedLogin,
    lastPasswordChange,
    userType,
    createdAt,
    updatedAt,
    failedLogins = 0,
    loading = false,
    children,
  } = props;

  return (
    <div className={classnames("userAuthenticationSummaryView", classes.container)}>
      <ErrorView className={classes.errorMessageContainer} message={errorMessage} />
      <SummaryViewLabel
        className={classnames("userId", classes.summaryViewLabel)}
        name="User ID"
        value={userId}
      />
      <SummaryViewLabel
        className={classnames("anonymousId", classes.summaryViewLabel)}
        name="Anonymous ID"
        value={anonymousId}
      />
      <SummaryViewLabel
        className={classnames("userType", classes.summaryViewLabel)}
        name="User Type"
        value={capitalize(userType)}
      />
      <SummaryViewDateLabel
        className={classnames("lastSuccessfulLogin", classes.summaryViewLabel)}
        name="Last Successful Login"
        value={lastSuccessfulLogin}
      />
      <SummaryViewDateLabel
        className={classnames("lastFailedLogin", classes.summaryViewLabel)}
        name="Last Failed Login"
        value={lastFailedLogin}
      />
      <SummaryViewDateLabel
        className={classnames("lastPasswordChange", classes.summaryViewLabel)}
        name="Last Password Change"
        value={lastPasswordChange}
      />
      <SummaryViewDateLabel
        className={classnames("createdAt", classes.summaryViewLabel)}
        name="Created At"
        value={createdAt}
      />
      <SummaryViewDateLabel
        className={classnames("updatedAt", classes.summaryViewLabel)}
        name="Updated At"
        value={updatedAt}
      />
      <div className={classnames( classes.summaryViewContainer)}>
        <div className={classnames( classes.labelContainer)}>
          <label className={classnames("failedLoginLabel", classes.failedLoginLabel)}>
            Recent Failed Logins:
          </label>
          <label className={classnames("failedLoginInfo", classes.failedLoginInfo)}>
            The number of failed logins that have occurred since the last successful login
          </label>
        </div>
        <label className={classnames("failedLoginValue", classes.failedLoginValue)}>{failedLogins}</label>
      </div>
      <div className={classnames("content", classes.content)}>
        {loading ? (
          <label className={classnames(classes.title)}>
            Loading authentication attributes...
          </label>
        ) : children}
      </div>
    </div>
  );
});

export default UserAuthenticationSummaryView;
