import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import {
  refresh,
  expireUserPassword,
  changeUserPassword,
  closeUser as closeAccount,
  deleteUser as deleteAccount,
  lockUser as lockAccount,
  logoutUser as logoutAccount,
  unlockUser as unlockAccount,
  clearErrorsAndLoading as clearErrors,
} from "../actions";
import {
  getActionMenuItems,
  getErrorMessage,
  getUserId,
  isLoadingIndicatorVisible,
  isActionsMenuButtonDisabled,
  isNotFoundVisible,
  isAccessDeniedVisible,
  getMyProfileViewActionMenuItems,
} from "../selectors";
import Workloads from "./Workloads";
import EventsView from "./EventsView";
import ProfileView from "./ProfileView";
import PoliciesView from "./PoliciesView";
import AdminNotesView from "./AdminNotesView";
import SecurityGroupsView from "./SecurityGroupsView";
import SummaryView from "./SummaryView";
import { PrimaryIcon } from "../components/styles";
import UserAuthenticationSummaryView from "./UserAuthenticationSummaryView";
import { ActionMenuItem, DetailsView, DetailsViewRoute, UserActionsMenu } from "@components";
import { ManagedPolicy, Policy, SecurityGroup } from "@data";
import { equalsIgnoreCase, noop } from "@util";
import { isDataWorkloadsModuleEnabled } from "@main/selectors";
import { useRouteMatch } from "react-router-dom";
import { MY_PROFILE_PATH } from "../constants";

const ADMIN_NOTES_BUTTON_ENABLED =
  !equalsIgnoreCase("true", process.env.REACT_APP_ADMIN_NOTE_DISABLED);

export interface ContainerModel {
  userId?: string;
  showNotFound?: boolean;
  showAccessDenied?: boolean;
  loading?: boolean;
  errorMessage?: string;
  title?: string;
  actions?: ActionMenuItem[];
  myProfileActions?: ActionMenuItem[];
  actionsMenuDisabled?: boolean;
  showNotesButton?: boolean;
  showWorkloadsButton?: boolean;
  children?: React.ReactNode;
}

export interface ContainerActions {
  showSecurityGroupDetails?: (group: SecurityGroup) => void;
  showPolicyDetails?: (policy: Policy) => void;
  showManagedPolicyDetails?: (policy: ManagedPolicy) => void;
  showWorkloadDetails?: (name: string) => void;
  attachPolicyToUser?: (userId: string) => void;
  detachPolicyFromUser?: (userId: string) => void;
  addGroup?: (userId: string) => void;
  removeGroup?: (userId: string) => void;
  refresh?: () => void;
  addUserToGroup?: () => void;
  removeUserFromGroup?: () => void;
  attachPolicy?: () => void;
  detachPolicy?: () => void;
  logoutUser?: () => void;
  lockUser?: () => void;
  unlockUser?: () => void;
  deleteUser?: () => void;
  closeUser?: () => void;
  expirePassword?: () => void;
  changePassword?: () => void;
  recoverPassword?: () => void;
  clearErrorsAndLoading?: () => void;
}

const withSummary = (children: React.ReactElement) =>
  <SummaryView>{children}</SummaryView>;

type Props = ContainerModel & ContainerActions;

const UserDetailsContainer = (props: Props) => {

  const {
    userId,
    loading,
    actions,
    myProfileActions,
    showNotFound,
    showAccessDenied,
    actionsMenuDisabled,
    showWorkloadsButton,
    showSecurityGroupDetails,
    showPolicyDetails,
    showManagedPolicyDetails,
    showWorkloadDetails,
    attachPolicyToUser = noop,
    detachPolicyFromUser = noop,
    addGroup = noop,
    lockUser = noop,
    closeUser = noop,
    unlockUser = noop,
    logoutUser = noop,
    deleteUser = noop,
    removeGroup = noop,
    expirePassword = noop,
    changePassword = noop,
    recoverPassword = noop,
    clearErrorsAndLoading = noop,
    ...otherProps
  } = props;

  const isMyProfileView = useRouteMatch(MY_PROFILE_PATH);

  const userActions = React.useMemo(() => isMyProfileView ? myProfileActions : actions,
    [isMyProfileView, myProfileActions, actions]);

  const attachPolicy = React.useCallback(() => {
    attachPolicyToUser(userId);
  }, [attachPolicyToUser, userId]);

  const detachPolicy = React.useCallback(() => {
    detachPolicyFromUser(userId);
  }, [detachPolicyFromUser, userId]);

  const addUserToGroup = React.useCallback(() => {
    addGroup(userId);
  }, [addGroup, userId]);

  const removeUserFromGroup = React.useCallback(() => {
    removeGroup(userId);
  }, [removeGroup, userId]);

  const authenticationView = React.useMemo<() => React.ReactElement>(() =>
    () => <UserAuthenticationSummaryView />, []);

  const profileView = React.useMemo<() => React.ReactElement>(() => () =>
    withSummary(<ProfileView />), [withSummary]);

  const adminNotesView = React.useMemo<() => React.ReactElement>(() => () =>
    withSummary(<AdminNotesView />), [withSummary]);

  const eventsView = React.useMemo<() => React.ReactElement>(() => () =>
    withSummary(<EventsView />), [withSummary]);

  const workloadsView = React.useMemo<() => React.ReactElement>(() => () =>
    withSummary(<Workloads showWorkloadDetails={showWorkloadDetails} />),
    [withSummary, showWorkloadDetails]);

  const securityGroupsView = React.useMemo<() => React.ReactElement>(() => () =>
    withSummary(<SecurityGroupsView showSecurityGroupDetails={showSecurityGroupDetails} />),
    [withSummary, showSecurityGroupDetails]);

  const policiesView = React.useMemo<() => React.ReactElement>(() => () =>
    withSummary(
      <PoliciesView
        showPolicyDetails={showPolicyDetails}
        showManagedPolicyDetails={showManagedPolicyDetails}
      />
    ), [withSummary, showPolicyDetails, showManagedPolicyDetails]);

  const ROUTES: DetailsViewRoute[] = React.useMemo(() => [
    {
      id: "profileView",
      name: "Profile",
      view: profileView,
    },
    {
      id: "authenticationView",
      name: "Authentication",
      view: authenticationView,
      path: "/authentication"
    },
    {
      id: "securityGroupsView",
      name: "Security Groups",
      view: securityGroupsView,
      path: "/security-groups"
    },
    {
      id: "policiesView",
      name: "Policies",
      view: policiesView,
      path: "/policies"
    },
    {
      id: "adminNotesView",
      name: "Notes",
      view: adminNotesView,
      path: "/notes"
    },
    {
      id: "eventsView",
      name: "Events",
      view: eventsView,
      path: "/events"
    },
    {
      id: "workloadsView",
      name: "Workloads",
      view: workloadsView,
      path: "/workloads",
      hidden: !showWorkloadsButton,
    },
  ], [
    profileView,
    securityGroupsView,
    policiesView,
    adminNotesView,
    eventsView,
    workloadsView,
    authenticationView,
    showWorkloadsButton,
  ]);

  const onTabChange = React.useCallback(() => {
    clearErrorsAndLoading();
  }, [clearErrorsAndLoading]);

  const buttonLabel = React.useMemo(() => {
    return isMyProfileView ? "Manage My Account" : "Actions";
  }, [isMyProfileView]);

  return (
    <React.Fragment>
      <DetailsView
        className={"userDetails"}
        {...otherProps}
        routes={ROUTES}
        icon={PrimaryIcon}
        tabsMarginTop={false}
        showLoadingIndicator={loading}
        showAccessDenied={showAccessDenied}
        showNotFound={showNotFound}
        onTabChange={onTabChange}
        headerButtons={(
          <UserActionsMenu
            actions={userActions}
            buttonLabel={buttonLabel}
            disabled={actionsMenuDisabled}
            addUserToGroup={addUserToGroup}
            removeUserFromGroup={removeUserFromGroup}
            attachPolicy={attachPolicy}
            detachPolicy={detachPolicy}
            logoutUser={logoutUser}
            deleteUser={deleteUser}
            lockUser={lockUser}
            unlockUser={unlockUser}
            closeUser={closeUser}
            expirePassword={expirePassword}
            changePassword={changePassword}
            recoverPassword={recoverPassword}
          />
        )}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  loading: isLoadingIndicatorVisible(state),
  errorMessage: getErrorMessage(state),
  title: getUserId(state),
  actions: getActionMenuItems(state),
  myProfileActions: getMyProfileViewActionMenuItems(state),
  actionsMenuDisabled: isActionsMenuButtonDisabled(state),
  showNotesButton: ADMIN_NOTES_BUTTON_ENABLED,
  showWorkloadsButton: isDataWorkloadsModuleEnabled(state),
  userId: getUserId(state),
  showNotFound: isNotFoundVisible(state),
  showAccessDenied: isAccessDeniedVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  refresh: () => dispatch(refresh()),
  logoutUser: () => dispatch(logoutAccount()),
  deleteUser: () => dispatch(deleteAccount()),
  lockUser: () => dispatch(lockAccount()),
  unlockUser: () => dispatch(unlockAccount()),
  closeUser: () => dispatch(closeAccount()),
  expirePassword: () => dispatch(expireUserPassword()),
  changePassword: () => dispatch(changeUserPassword()),
  clearErrorsAndLoading: () => dispatch(clearErrors()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(UserDetailsContainer);
