import React from "react";
import { noop } from "@util";
import Chip from "@components/chip";
import classnames from "classnames";
import { SearchFilter } from "@data";
import RemoveIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface SearchFiltersModel {
  className?: string;
  disabled?: boolean;
  color?: "primary" | "secondary" | "default";
  searchFilters?: SearchFilter[];
  lockedFilters?: string[];
  children?: React.ReactNode;
}

export interface SearchFiltersActions {
  setSearchFilters?: (searchFilters: SearchFilter[]) => void;
  setSelectedSearchFilter?: (searchFilter: SearchFilter) => void;
  mapSearchFilterToLabel?: (searchFilter: SearchFilter) => React.ReactNode;
}

type Model = SearchFiltersModel;
type Actions = SearchFiltersActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const SearchFilters = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    disabled,
    color = "secondary",
    searchFilters = [],
    lockedFilters = [],
    setSearchFilters = noop,
    setSelectedSearchFilter = noop,
    mapSearchFilterToLabel = (searchFilter: SearchFilter) =>
      `${searchFilter.getKey()} ${searchFilter.condition} ${searchFilter.getValue()}`,
    children,
  } = props;

  const removeFilter = React.useCallback((searchFilter: SearchFilter) =>
    setSearchFilters(searchFilters.filter(
      (filter: SearchFilter) => !filter.equals(searchFilter))),
    [setSearchFilters, searchFilters]);

  return (
    <React.Fragment>
      {searchFilters.length > 0 && (
        <div className={classnames("searchFilters", className, classes.container)}>
          {searchFilters.map((filter: SearchFilter) => {

            const filterDisabled = disabled || lockedFilters.indexOf(filter.getKey()) >= 0;

            return (
              <Chip
                key={filter.getSearchQuery() || filter.getKey()}
                className={classnames("searchFilter", classes.searchFilter, {
                  [classes.searchFilterKeyVerbatim]: filter.isKeyVerbatim,
                  [classes.searchFilterValueVerbatim]: filter.isValueVerbatim,
                  "disabled": filterDisabled,
                })}
                color={!filterDisabled ? color : "default"}
                clickable={!filterDisabled}
                disabled={filterDisabled}
                label={mapSearchFilterToLabel(filter)}
                icon={<SearchIcon className={classnames("icon", classes.icon)}/>}
                deleteIcon={(
                  <RemoveIcon
                    className={classnames("deleteIcon", classes.deleteIcon)}
                    aria-label="delete-icon"
                  />
                )}
                onDelete={() => removeFilter(filter)}
                onClick={() => setSelectedSearchFilter(filter)}
              />
            );
          })}
        </div>
      )}
      {children}
    </React.Fragment>
  );
});

export default SearchFilters;
