import React from "react";
import classnames from "classnames";
import HelpIcon from "@material-ui/icons/Help";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from "@components";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { AccountIdTextField, LoginTextField, PasswordTextField } from "../LoginTextField";
import { clickHandler, isEmptyString, noop } from "../../../../util";
import { loginButton, styles, tooltip } from "./styles";
import { RememberMe } from "../RememberMe";

const LoginButton = withStyles(loginButton)(Button);
const LoginTooltip = withStyles(tooltip)(Tooltip);

export const DEFAULT_ACCOUNT_ID_TOOLTIP =
  "Specify the IoT Account for the business/product you are accessing.";

export const DEFAULT_TOKEN_TOOLTIP =
  "Use the app on your mobile\n" +
  "phone to generate a token. The\n" +
  "app was configured for this\n" +
  "account on your first login.";

export const DEFAULT_SHOW_PASSWORD_TOOLTIP = "Show Password";
export const DEFAULT_HIDE_PASSWORD_TOOLTIP = "Hide Password";

export interface Model {
  username?: string;
  usernameError?: string;
  account?: string;
  accountError?: string;
  accountTooltip?: string;
  rememberMe?: boolean;
  password?: string;
  passwordError?: string;
  token?: string;
  tokenError?: string;
  tokenTooltip?: string;
  showPassword?: boolean;
  showPasswordTooltip?: string;
  hidePasswordTooltip?: string;
  showCredentialsView?: boolean;
  loginButtonLabel?: string;
  children?: React.ReactNode;
}

export interface Actions {
  setUsername?: (username: string) => void;
  setAccount?: (account: string) => void;
  setPassword?: (password: string) => void;
  setShowPassword?: (showPassword: boolean) => void;
  setToken?: (token: string) => void;
  setRememberMe?: (rememberMe: boolean) => void;
  login?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const LoginForm = withStyles(styles)((props: Props) => {

  const {
    classes,
    username = "",
    usernameError = "",
    account = "",
    accountError = "",
    rememberMe,
    password = "",
    passwordError = "",
    token = "",
    tokenError = "",
    tokenTooltip = DEFAULT_TOKEN_TOOLTIP,
    showPassword = false,
    showCredentialsView = false,
    loginButtonLabel = "Login",
    setUsername = noop,
    setAccount = noop,
    setPassword = noop,
    setShowPassword = noop,
    setToken = noop,
    setRememberMe = noop,
    login = noop,
    children,
  } = props;

  return (
    <form className={classnames("loginForm", classes.container)}>
      <LoginTextField
        className={classnames("username", classes.username)}
        name="login_hint"
        label="Username"
        value={username}
        helperText={usernameError}
        error={!isEmptyString(usernameError)}
        setValue={setUsername}
        login={login}
      />
      <AccountIdTextField
        className={classnames("account", classes.account)}
        name="iot_account_id"
        label="Account ID"
        accountId={account}
        accountIdErrorMessage={accountError}
        setAccountId={setAccount}
        login={login}
      />
      {showCredentialsView && (
        <React.Fragment>
          <PasswordTextField
            className={classnames("password", classes.password)}
            autoFocus={true}
            name="password"
            label="Password"
            showPasswordTooltip="Show Password"
            hidePasswordTooltip="Hide Password"
            toggleShowPasswordLabel="Toggle Password Visibility"
            password={password}
            passwordErrorMessage={passwordError}
            showPassword={showPassword}
            setPassword={setPassword}
            setShowPassword={setShowPassword}
            login={login}
          />
          <LoginTextField
            className={classnames("token", classes.token)}
            name="token"
            label="Multi-Factor Authentication Token"
            value={token}
            helperText={tokenError}
            error={!isEmptyString(tokenError)}
            setValue={setToken}
            login={login}
            endAdornment={(
              <InputAdornment position="end">
                <LoginTooltip className={classnames("tooltip", classes.tooltip)} title={tokenTooltip}>
                  <IconButton className={classnames("iconButton", classes.iconButton)} tabIndex={-1}>
                    <HelpIcon className={classnames("textFieldIcon", classes.textFieldIcon)} />
                  </IconButton>
                </LoginTooltip>
              </InputAdornment>
            )}
          />
        </React.Fragment>
      )}
      <RememberMe
        className={classnames("rememberMe", classes.rememberMe)}
        label="Remember me"
        rememberMe={rememberMe}
        setRememberMe={setRememberMe}
      />
      <LoginButton
        className={classnames("loginButton", classes.loginButton)}
        variant="contained"
        fullWidth={true}
        onClick={clickHandler(login)}
      >
        {loginButtonLabel}
      </LoginButton>
      {children}
    </form>
  );
});

export default LoginForm;
