import { isEmptyString } from "@util";
import { createActions } from "@modules/base";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { User } from "@data";

export const {
  user: setUser,
  expirePasswordDialog: setExpirePasswordDialog,
  EXPIRE_PASSWORD_REQUEST: expirePasswordRequest,
  EXPIRE_PASSWORD_SUCCESS: expirePasswordSuccess,
  EXPIRE_PASSWORD_FAILED: expirePasswordFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setUser());
  dispatch(setExpirePasswordDialog());
  return dispatch(baseReset());
};

export const open = (user: User) => (dispatch: any) => {

  if (isEmptyString(user.getUserId())) {
    return Promise.resolve();
  }

  dispatch(reset());
  dispatch(setUser(user));
  return dispatch(setExpirePasswordDialog(true));
};

export const close = reset;

export const initialize = reset;
