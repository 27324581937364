import React from "react";
import {
  ModuleListViewActions,
  ModuleListViewModel,
  DELETE_DS_LAYOUT_ACTION,
  ActionMenuItem,
  DSLayoutAction,
  DSLayoutOperation,
  DeleteDSLayoutDialog,
  EDIT_DS_LAYOUT_ACTION,
} from "@components";
import ModuleListView from "@components/module-list-view";
import DSTypesLayoutsListColumn from "@components/ds-types-layouts-list-columns";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import { PrimaryIcon } from "../styles";
import { noop } from "@util";
import { DSLayoutInfo, DSTypeLayout } from "@data";
import { useListDSLayouts } from "@hooks";
import { getPathToShadowLayout } from "@modules/digitalShadowLayoutDetails/helpers";

const DEFAULT_LAYOUTS_LIST_COLUMNS: DSTypesLayoutsListColumn[] = [
  DSTypesLayoutsListColumn.NAME,
];

const ACTION_ITEMS: ActionMenuItem[] = [
  EDIT_DS_LAYOUT_ACTION,
  DELETE_DS_LAYOUT_ACTION
];

type ContainerModel = ModuleListViewModel<DSTypeLayout, DSTypesLayoutsListColumn>;

interface ContainerActions extends ModuleListViewActions<DSTypeLayout, DSTypesLayoutsListColumn> {
  showLayoutDetails?: (dsTypeName: DSTypeLayout) => void;
  editLayout?: (dsTypeName: DSTypeLayout) => void;
  createLayout?: () => void;
}

type Props = ContainerModel & ContainerActions;

const DigitalShadowLayouts = (props: Props) => {

  const {
    showLayoutDetails,
    createLayout,
    actions: layoutActions = ACTION_ITEMS,
    onClickAction = noop,
    editLayout = noop,
    ...otherProps
  } = props;

  const [openOperationDialog, setOpenOperationDialog] = React.useState(false);
  const [name, setName] = React.useState("");
  const [operation, setOperation] = React.useState(DSLayoutOperation.NONE);

  const [nameFilter, setNameFilter] = React.useState("");

  const [ model, actions ] = useListDSLayouts();

  const { layouts } = model;

  const { refresh } = actions;

  const moduleListItems: ListViewItem<DSTypeLayout>[] = React.useMemo(() =>
    layouts.map(layout => {
      return {
        item: layout,
        pathToDetailsView: getPathToShadowLayout(layout.getName()),
        icon: PrimaryIcon,
        columnAttributes: createColumns([
          {
            className: "typeName",
            value: layout.getName(),
            column: DSTypesLayoutsListColumn.NAME,
            firstColumn: true,
          },
        ])
      };
    }), [layouts]);

  const deleteDSLayout = React.useCallback((layoutName: string) => {
    setOperation(DSLayoutOperation.DELETE);
    setName(layoutName);
    setOpenOperationDialog(true);
  }, [setOpenOperationDialog, setOperation, setName]);

  const onClickTypeAction = React.useCallback((layout: DSLayoutInfo, action: ActionMenuItem) => {
    switch (action.id) {
      case DSLayoutAction.DELETE_DS_LAYOUT_ACTION:
        return deleteDSLayout(layout.getName());
      case DSLayoutAction.EDIT_DS_LAYOUT_ACTION:
        return editLayout(layout);
      default:
        return onClickAction(layout, action);
    }
  }, [deleteDSLayout, editLayout, onClickAction]);

  const onSuccess = React.useCallback(() => {
    setOpenOperationDialog(false);
    setOperation(DSLayoutOperation.NONE);
    setName("");
    refresh();
  }, [setOpenOperationDialog, setOperation,  refresh, setName]);

  const cancelOperation = React.useCallback(() => {
    setOpenOperationDialog(false);
    setOperation(DSLayoutOperation.NONE);
    setName("");
  }, [setOpenOperationDialog, setOperation, setName]);

  return (
    <React.Fragment>
      <ModuleListView
        {...model}
        {...actions}
        {...otherProps}
        className={"digitalShadowLayouts"}
        summaryViewIcon={PrimaryIcon}
        nameFilter={nameFilter}
        listClassName={"digitalShadowLayoutsList"}
        columns={DEFAULT_LAYOUTS_LIST_COLUMNS}
        listViewItems={moduleListItems}
        showSummary={true}
        showSearch={true}
        showCreateButton={true}
        createButtonClassName="createLayoutButton"
        createButtonLabel="Create Shadow Layout"
        summaryViewLabel="Shadow Layouts"
        onClickCreateButton={createLayout}
        actions={layoutActions}
        onClickAction={onClickTypeAction}
        setNameFilter={setNameFilter}
        onClickItem={showLayoutDetails}
      />
      <DeleteDSLayoutDialog
        open={openOperationDialog}
        layoutName={name}
        operation={operation}
        onSuccess={onSuccess}
        cancel={cancelOperation}
      />
    </React.Fragment>
  );
};

export default DigitalShadowLayouts;
