import React from "react";
import { ManagedPolicy } from "@data";
import { isEmptyString, noop } from "@util";
import { ManagedPoliciesList } from "@components";
import {
  useManagedPolicies as useManagedPoliciesList,
  PolicyOperationPrincipal,
  useManagedEffectivePolicies
} from "@hooks";

export interface Model {
  groupName?: string;
  hidden?: boolean;
}

export interface Actions {
  showManagedPolicyDetails?: (policy: ManagedPolicy) => void;
  setDetachPolicyButtonEnabled?: (enabled: boolean) => void;
}

type Props = Model & Actions;

const ManagedPoliciesView = (props: Props) => {

  const {
    hidden,
    groupName,
    showManagedPolicyDetails,
    setDetachPolicyButtonEnabled: enableDetachPolicyButton = noop,
    ...otherProps
  } = props;

  const [ policyModel ] = useManagedEffectivePolicies(PolicyOperationPrincipal.GROUP, groupName);

  const {
    policies = [],
    showLoadingIndicator: policyLoading,
    errorMessage: policyErrorMessage,
    ...remainingPolicyModel } = policyModel;

  const [ model, actions ] = useManagedPoliciesList();

  const {
    managedPolicies = [],
    showLoadingIndicator: managedPolicyLoading,
    errorMessage: managedPolicyErrorMessage,
    nameFilter,
    ...remainingManagedPolicyModel
  } = model;

  const items: ManagedPolicy[] = React.useMemo(() => {
    return managedPolicies.filter(managedPolicy =>
      policies.some(policy => policy.getName() === managedPolicy.getPolicy().getName()));
  }, [managedPolicies, policies]);

  const showSearch = React.useMemo(() => !isEmptyString(nameFilter) || items.length > 1, [items, nameFilter]);

  const { totalNumPolicies } = model;

  const loadingIndicator = React.useMemo(() => policyLoading || managedPolicyLoading,
    [policyLoading, managedPolicyLoading]);

  const errorMessage = React.useMemo(() => {
    if (!isEmptyString(managedPolicyErrorMessage)) {
      return managedPolicyErrorMessage;
    } else if (!isEmptyString(policyErrorMessage)) {
      return policyErrorMessage;
    } else {
      return "";
    }
  }, [managedPolicyErrorMessage, policyErrorMessage]);

  React.useEffect(() => {
    enableDetachPolicyButton(totalNumPolicies > 0);
  }, [enableDetachPolicyButton, totalNumPolicies]);

  if (hidden) {
    return null;
  }

  return (
    <ManagedPoliciesList
      {...otherProps}
      {...remainingPolicyModel}
      {...remainingManagedPolicyModel}
      {...actions}
      className={"groupManagedPolicies"}
      items={items}
      nameFilter={nameFilter}
      showSearch={showSearch}
      showLoadingIndicator={loadingIndicator}
      errorMessage={errorMessage}
      onClickItem={showManagedPolicyDetails}
      loadingLabel="Loading managed policies attached to this group..."
      noResultsLabel="Group does not have any managed policies attached"
    />
  );
};

export default ManagedPoliciesView;
