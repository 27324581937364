import React from "react";
import classnames from "classnames";
import { clickHandler, noop } from "@util";
import { JsonSchemaMetadata } from "@data";
import {
  NotFoundView,
  AccessDeniedView,
  ActionMenuItem,
  DECOMMISSION_SCHEMA_ACTION,
  DELETE_SCHEMA_ACTION,
  DEPRECATE_SCHEMA_ACTION,
  PROMOTE_SCHEMA_ACTION,
  SchemaAction,
  SchemasList,
  SchemasListActions,
  SchemasListModel,
} from "@components";
import ContinueButton from "@components/continue-button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export const DEFAULT_ACTIONS: ActionMenuItem[] = Object.freeze([
  PROMOTE_SCHEMA_ACTION,
  DEPRECATE_SCHEMA_ACTION,
  DECOMMISSION_SCHEMA_ACTION,
  DELETE_SCHEMA_ACTION,
]) as ActionMenuItem[];

const DEFAULT_MAP_ITEM_TO_ACTION = (schema: JsonSchemaMetadata, action: ActionMenuItem) => {
  switch (action.id) {
    case SchemaAction.PROMOTE:
      return {
        ...action,
        disabled: !(schema.isDraftSchema() || schema.isDeprecatedSchema()),
      };
    case SchemaAction.DEPRECATE:
      return {
        ...action,
        disabled: !(schema.isReleasedSchema() || schema.isDecommissionedSchema()),
      };
    case SchemaAction.DECOMMISSION:
      return {
        ...action,
        disabled: !(schema.isDeprecatedSchema()),
      };
    case SchemaAction.DELETE:
      return {
        ...action,
        disabled: !(schema.isDraftSchema() || schema.isDecommissionedSchema()),
      };
    default:
      return {
        ...action,
        disabled: true,
        hidden: true,
      };
  }
};

export interface Model extends SchemasListModel {
  children?: React.ReactNode;
  showNotFound?: boolean;
  showAccessDenied?: boolean;
}

export interface Actions extends SchemasListActions {
  createSchema?: () => void;
  showSchemaDetails?: (schema: JsonSchemaMetadata) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const Schemas = withStyles(styles)((props: Props) => {

  const {
    classes,
    createSchema,
    showNotFound,
    showAccessDenied,
    showSchemaDetails = noop,
    actions = DEFAULT_ACTIONS,
    mapItemToAction = DEFAULT_MAP_ITEM_TO_ACTION,
    children,
    ...otherProps
  } = props;

  if (showNotFound) { return <NotFoundView/>; }

  if (showAccessDenied) { return <AccessDeniedView/>; }

  return (
    <SchemasList
      {...otherProps}
      actions={actions}
      hideSummary={false}
      hideSearch={false}
      showSearchFilters={true}
      mapItemToAction={mapItemToAction}
      onClickItem={showSchemaDetails}
    >
      <ContinueButton
        className={classnames("createSchemaButton", classes.createButton)}
        onClick={clickHandler(createSchema)}
      >
        Create Schema
      </ContinueButton>
      {children}
    </SchemasList>
  );
});

export default Schemas;
