import { createStyles, StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const addButton: StyleRules<string> = {
  root: {
    height: 50,
    padding: "0 8px",
  },
  label: {
    color: Color.GREY7,
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    padding: 0,
    margin: 0,
    textTransform: "none",
  },
};

export const tableBodyRow: StyleRules<string> = {
  root: {
    height: "auto",
  },
};

export const tableBodyCell: StyleRules<string> = {
  root: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto, sans-serif",
    fontSize: 13,
    lineHeight: "16px",
    fontWeight: 400,
    fontStyle: "normal",
    width: "auto",
    height: "auto",
    minHeight: 50,
    padding: 8,
  },
};

export const styles = () =>
  createStyles({
    table: {
      borderCollapse: "collapse",
      overflow: "hidden",
      tableLayout: "fixed",
      marginTop: 8,
    },
    nameColumn: {
    },
    textField: {
      fontSize: 20,
    },
    valueColumn: {
    },
    actionsColumn: {
      width: 100,
    },
    addButtonRow: {
      padding: 6,
    },
    addButtonColumn: {
      textAlign: "center",
    },
    addButton: {
      height: 50,
      padding: "0 8px",
    },
    addButtonLabel: {
      color: Color.GREY7,
    },
    clickable: {
      cursor: "pointer",
    },
  });

export default styles;
