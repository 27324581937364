import React from "react";
import classnames from "classnames";
import { deviceTypeEditorView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface Model {
  children?: React.ReactNode;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DeviceTypeEditorView = withStyles(styles)((props: Props) => {

  const {
    classes,
    children,
  } = props;

  return (
    <div className={classnames("editorView", classes.container)}>
      <label className={classnames("title", classes.title)}>Device Type Editor</label>
      {children}
    </div>
  );
});

export default DeviceTypeEditorView;
