import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { UserProfileAttributesMap } from "@data";
import { isEmptyString, noop } from "@util";
import {
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel,
  usePortalSnackbar,
} from "@components";
import { styles } from "./styles";

export interface Model extends ConfirmationDialogModel<UserProfileAttributesMap> {
  loadingMessage?: string;
  confirmationMessage?: string;
  closeOnSuccessDelay?: number;
}

export interface Actions extends ConfirmationDialogActions<UserProfileAttributesMap>  {
  onSuccessMessageShown?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SaveProfileAttributesDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    loading,
    successMessage,
    errorMessage,
    continueButtonLabel = "Save",
    title = "Save Profile Attributes",
    loadingMessage = "Saving profile attributes...",
    confirmationMessage = "Are you sure you want to save these changes?",
    closeOnSuccessDelay = 500,
    onSuccessMessageShown,
    ...otherProps
  } = props;

  const {
    open,
    cancel: closeDialog = noop,
    continueButtonDisabled = loading,
  } = otherProps;

  const message = React.useMemo(() => {
    if (loading) {
      return loadingMessage;
    } else if (!isEmptyString(successMessage)) {
      return successMessage;
    } else {
      return confirmationMessage;
    }
  }, [
    loading,
    loadingMessage,
    successMessage,
    confirmationMessage,
  ]);

  usePortalSnackbar("save-profile-attributes", {
    errorMessage,
    successMessage,
    onSuccessMessageShown: onSuccessMessageShown || closeDialog,
    successCallbackDelay: closeOnSuccessDelay,
  });

  if (!open) {
    return null;
  }

  return (
    <ConfirmationDialog
      {...otherProps}
      className={classnames("saveProfileAttributesDialog", className)}
      title={title}
      loading={loading}
      errorMessage={errorMessage}
      successMessage={successMessage}
      continueButtonLabel={continueButtonLabel}
      continueButtonDisabled={continueButtonDisabled}
    >
      {!errorMessage && (
        <div className={classes.content}>
          <label className={classnames("label", classes.label)}>
            {message}
          </label>
        </div>
      )}
    </ConfirmationDialog>
  );
});

export default SaveProfileAttributesDialog;
