import { Module } from "@data";
import { initialize } from "./actions";
import { createModule } from "@base/createModule";
import { MODULE_ID } from "./constants";
import { getPathToDataSet } from "./helpers";
import { ModuleIcon as icon } from "./components/styles";
import { DATA_SETS_MODULE_DISABLED } from "@modules/dataSets/module";
import view from "./DataSetDetailsView";

type RouteParams = { id?: string };

export const dataSetDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: getPathToDataSet(":id"),
  exactPath: false,
  strictPath: false,
  name: "Data Set Details",
  title: "Data Set Details",
  className: "dataSetDetailsModule",
  icon,
  view,
  onMount: ({ id = "" }: RouteParams = {}) => initialize(id),
  disabled: DATA_SETS_MODULE_DISABLED,
});

export default dataSetDetailsModule;
