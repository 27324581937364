import React from "react";
import { isValidInteger } from "@util";
import { RouteParams } from "./constants";
import { useHistory, useParams } from "react-router-dom";
import { getPathToWorkloadTriggers } from "@modules/dataWorkloadDetails/helpers";
import TriggerWizard from "./containers/TriggerWizard";
import useTriggerType from "./useTriggerType";

export function AddTriggerView() {

  const history = useHistory();

  const type = useTriggerType();

  const { name, version: versionParam } = useParams<RouteParams>();

  const version = React.useMemo(() => {
    return isValidInteger(versionParam) ? Number(versionParam) : -1;
  }, [versionParam]);

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const showWorkloadDetails = React.useCallback(() => {
    history.replace(getPathToWorkloadTriggers(name, version));
  }, [name, version, history]);

  return (
    <TriggerWizard
      name={name}
      version={version}
      type={type}
      cancel={cancel}
      showWorkloadDetails={showWorkloadDetails}
    />
  );
}

export default AddTriggerView;
