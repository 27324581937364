import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { schemaPropertiesBrowserLabel as styles } from "./styles";

export const SchemaPropertiesBrowserLabel = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  name: string,
  value?: string,
}) => {

  const { classes, className = "", name, value } = props;

  if (!value) {
    return null;
  }

  return (
    <div className={classnames(className, classes[className], classes.container)}>
      <label className={classnames("name", classes.name)}>{name}:</label>
      <label className={classnames("value", classes.value)}>{value}</label>
    </div>
  );
});

export default SchemaPropertiesBrowserLabel;
