import { v4 as uuidV4 } from "uuid";
import { AppSchema } from "@schemas";
import { equalsIgnoreCase, isEmptyString } from "@util";
import { DeviceDataModel, DeviceTypeListItem, DeviceTypeModelV3, JsonSchemaMetadata } from "@data";
import { createActions } from "@base/createActions";
import { AddDeviceWizardStep, DeviceEnrollmentWizardStep } from "../steps";
import { DeviceEnrollmentWizardState } from "../models";
import { ACTION_TYPES, DEFAULT_STATE, EnrollmentType } from "../reducers";
import { DeviceEnrollmentWizardSelectors } from "../selectors";
import { DeviceEnrollmentClient, GetPreSignedUrlResponse, RestClientError } from "@network";
import {
  enrollDevice,
  updateDeviceId,
  updateDeviceType,
  validateDeviceEnrollment,
  addAvailableSchemas,
  addCredential,
} from "./addDeviceWizard";
import { setDeviceRequest as setValidationViewDeviceRequest } from "@modules/deviceValidationDetails/actions";

export const {
  batchId: setBatchId,
  deviceRef: setDeviceRef,
  device: setDevice,
  deviceEnrollmentWizardStep: setDeviceEnrollmentWizardStep,
  errorTitle: setErrorTitle,
  showValidateMode: setShowValidateMode,
  availableSchemas: setAvailableSchemas,
  deviceType: setDeviceTypeAttributes,
  enrollmentType: setEnrollmentType,
  preSignedUrl: setPreSignedUrl,
  devices: setDevices,
  base64Payload: setBase64Payload,
  deviceDataMode: setDeviceDataMode,
  file: setFile,
  addDeviceDialog: setAddDeviceDialog,
  removeDeviceDialog: setRemoveDeviceDialog,
  addDeviceWizardStep: setAddDeviceWizardStep,
  deviceDataDialog: setDeviceDataDialog,
  schema: setSchema,
  schemaIdentity: setSchemaIdentity,
  deviceData: setDeviceData,
  validatedPayload: setValidatedPayload,
  reEnrollmentPayload: setReEnrollmentPayload,
  reEnrollmentBatchId: setReEnrollmentBatchId,
  deviceDataValidated: setDeviceDataValidated,
  deviceTypeSelected: setDeviceTypeSelected,
  deviceEditMode: setDeviceEditMode,
  defaultState: setDefaultStateAttributes,
  setErrorMessage,
  setSuccessMessage,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  DEVICE_ENROLLMENT_REQUEST: deviceEnrollmentRequest,
  DEVICE_ENROLLMENT_SUCCESS: deviceEnrollmentSuccess,
  DEVICE_ENROLLMENT_FAILED: deviceEnrollmentFailed,
  VALIDATE_DEVICE_ENROLLMENT_REQUEST: validateDeviceEnrollmentRequest,
  VALIDATE_DEVICE_ENROLLMENT_SUCCESS: validateDeviceEnrollmentSuccess,
  VALIDATE_DEVICE_ENROLLMENT_FAILED: validateDeviceEnrollmentFailed,
  PRE_SIGNED_URL_REQUEST: preSignedUrlRequest,
  PRE_SIGNED_URL_SUCCESS: preSignedUrlSuccess,
  PRE_SIGNED_URL_FAILED: preSignedUrlFailed,
  BATCH_DEVICE_ENROLLMENT_REQUEST: batchDeviceEnrollmentRequest,
  BATCH_DEVICE_ENROLLMENT_SUCCESS: batchDeviceEnrollmentSuccess,
  BATCH_DEVICE_ENROLLMENT_FAILED: batchDeviceEnrollmentFailed,
  VALIDATE_SCHEMA_REQUEST: validateSchemaRequest,
  VALIDATE_SCHEMA_SUCCESS: validateSchemaSuccess,
  VALIDATE_SCHEMA_FAILED: validateSchemaFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  const batchId = uuidV4();
  dispatch(setBatchId(batchId));
  dispatch(setDeviceRef());
  dispatch(setDevice());
  dispatch(setDeviceEnrollmentWizardStep());
  dispatch(setErrorTitle());
  dispatch(setShowValidateMode());
  dispatch(setAvailableSchemas());
  dispatch(setDeviceTypeAttributes());
  dispatch(setEnrollmentType());
  dispatch(setPreSignedUrl());
  dispatch(setDevices());
  dispatch(setBase64Payload());
  dispatch(setDeviceDataMode());
  dispatch(setFile());
  dispatch(setAddDeviceDialog());
  dispatch(setRemoveDeviceDialog());
  dispatch(setAddDeviceWizardStep());
  dispatch(setDeviceDataDialog());
  dispatch(setSchema());
  dispatch(setSchemaIdentity());
  dispatch(setDeviceData());
  dispatch(setValidatedPayload());
  dispatch(setReEnrollmentPayload());
  dispatch(setReEnrollmentBatchId());
  dispatch(setDeviceDataValidated());
  dispatch(setDeviceTypeSelected());
  dispatch(setDeviceEditMode());
  dispatch(setDefaultStateAttributes(new DeviceEnrollmentWizardState({ batchId }).toJS()));
  return dispatch(baseReset());
};

export const openWizard = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const devices = DeviceEnrollmentWizardSelectors.getDevices(state);
  const isValidatedPayload = DeviceEnrollmentWizardSelectors.isValidatedPayload(state);
  const isReEnrollmentPayload = DeviceEnrollmentWizardSelectors.isReEnrollmentPayload(state);
  const reEnrollmentBatchId = DeviceEnrollmentWizardSelectors.getReEnrollmentBatchId(state);
  const deviceType = DeviceEnrollmentWizardSelectors.getDeviceType(state);
  const isDeviceTypeSelected = DeviceEnrollmentWizardSelectors.isDeviceTypeSelected(state);
  dispatch(reset());

  if (isValidatedPayload) {
    dispatch(setDevices(devices));
    // Batch id is generated as part of reset and does not need to be re-generated here
    dispatch(setEnrollmentType(EnrollmentType.VISUAL_EDITOR));
    dispatch(setDeviceEnrollmentWizardStep(DeviceEnrollmentWizardStep.EDITOR));
  }

  if (isReEnrollmentPayload) {
    dispatch(setDevices(devices));
    dispatch(setBatchId(reEnrollmentBatchId));
    dispatch(setEnrollmentType(EnrollmentType.VISUAL_EDITOR));
    dispatch(setDeviceEnrollmentWizardStep(DeviceEnrollmentWizardStep.EDITOR));
  }

  if (isDeviceTypeSelected) {
    dispatch(openAddDeviceDialog());
    dispatch(setDeviceTypeAttributes(deviceType.toJS()));
    dispatch(updateDeviceType(DeviceTypeListItem.from(deviceType)));
    const schemaIdentities = deviceType.getSchemas();
    const schemas = schemaIdentities.map(schemaIdentity =>
      JsonSchemaMetadata.fromNameAndVersion(schemaIdentity).toJS());
    const credentialNames = deviceType.getCredentialNames();
    credentialNames.map(credentialName => dispatch(addCredential(credentialName, "")));
    dispatch(addAvailableSchemas(schemas));
    dispatch(setAddDeviceWizardStep(AddDeviceWizardStep.DATA));
    dispatch(setDeviceEnrollmentWizardStep(DeviceEnrollmentWizardStep.DEVICES));
  }

  return Promise.resolve();
};

export const clearDevices = ()  => setDevices();

export const initialize = () => (dispatch: any) => {
  dispatch(reset());
  dispatch(setEnrollmentType(EnrollmentType.VISUAL_EDITOR));
  dispatch(setDeviceEnrollmentWizardStep(DeviceEnrollmentWizardStep.BATCH_ID));
  return dispatch(setShowValidateMode(true));
};

// Enrollment Type
export const setVisualEditorEnrollmentType = () => (dispatch: any) => {
  dispatch(reset());
  return dispatch(setEnrollmentType(EnrollmentType.VISUAL_EDITOR));
};
export const setFileUploadEnrollmentType = () => (dispatch: any) => {
  dispatch(reset());
  return dispatch(setEnrollmentType(EnrollmentType.FILE));
};

export const updateDevicesJson = (json: string) =>
  (dispatch: any) => {

    try {

      dispatch(setBase64Payload(""));
      dispatch(setDevices(JSON.parse(json)));

      return dispatch(setErrorMessage());
    } catch (e) {

      dispatch(setErrorTitle("JSON Error"));
      return dispatch(setErrorMessage("Failed to parse json body. " +
        "Please verify that the JSON is valid before continuing."));
    }
  };

export const addDevice = () => (dispatch: any, getState: () => AppSchema) => {

  const newDevice = DeviceEnrollmentWizardSelectors.getDevice(getState());

  const devices = DeviceEnrollmentWizardSelectors.getDevices(getState());

  const updatedDevices = devices.filter(entry => !equalsIgnoreCase(newDevice.getDeviceId(), entry.getDeviceId()))
    .concat(newDevice);

  dispatch(clearDevices());
  dispatch(closeAddDeviceDialog());

  return dispatch(setDevices(updatedDevices));
};

export const updateDevice = () => (dispatch: any, getState: () => AppSchema) => {

  const newDevice = DeviceEnrollmentWizardSelectors.getDevice(getState());

  const devices = DeviceEnrollmentWizardSelectors.getDevices(getState());

  const updatedDevices = devices.findIndex(device => device.getDeviceId() === newDevice.getDeviceId()) === -1 ?
    devices.concat(newDevice) : devices;

  dispatch(clearDevices());
  dispatch(closeAddDeviceDialog());

  return dispatch(setDevices(updatedDevices));
};

export const removeDevice = (deviceId: string) => (dispatch: any, getState: () => AppSchema) => {

  const devices = DeviceEnrollmentWizardSelectors.getDevices(getState());

  const updatedDevices = devices.filter(device => device.getDeviceId() !== deviceId);

  dispatch(clearDevices());
  dispatch(closeRemoveDeviceDialog());

  return dispatch(setDevices(updatedDevices));
};

export const updateBase64Payload = (encodedPayload: string = "") => (dispatch: any, getState: () => AppSchema) => {

  const existingPayload = DeviceEnrollmentWizardSelectors.getBase64payload(getState());

  if (isEmptyString(existingPayload)) {
    dispatch(setBase64Payload(encodedPayload));
  } else {
    dispatch(setBase64Payload(existingPayload + "\n" + encodedPayload));
  }
};

export const convertJsonToBase64 = () => (dispatch: any, getState: () => AppSchema) => {

  const devices = DeviceEnrollmentWizardSelectors.getDevices(getState());

  return devices.map(device => dispatch(updateBase64Payload(btoa(JSON.stringify(device.toJS())))));
};

export const setDeviceEnrollmentCurrentStep = (step: DeviceEnrollmentWizardStep) =>
  (dispatch: any, getState: () => AppSchema) => {

  const disabledStep = DeviceEnrollmentWizardSelectors.getDeviceEnrollmentDisabledSteps(getState());

  if (DeviceEnrollmentWizardStep.NONE === step || disabledStep.indexOf(step) >= 0) {
    return Promise.resolve();
  }

  dispatch(setErrorMessage());
  return dispatch(setDeviceEnrollmentWizardStep(step));
};

export const showDeviceEnrollmentNextStep = () => (dispatch: any, getState: () => AppSchema) => {

  return dispatch(setDeviceEnrollmentCurrentStep(
    DeviceEnrollmentWizardSelectors.getDeviceEnrollmentNextStep(getState())));
};

export const showDeviceEnrollmentPreviousStep = () => (dispatch: any, getState: () => AppSchema) => {

  return dispatch(setDeviceEnrollmentCurrentStep(
    DeviceEnrollmentWizardSelectors.getDeviceEnrollmentPreviousStep(getState())));
};

export const  generatePreSignedUrl = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const accessToken = DeviceEnrollmentWizardSelectors.getAccessToken(state);
  const batchId = DeviceEnrollmentWizardSelectors.getBatchId(state);
  const json = JSON.stringify({
    batchId,
  });

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setErrorMessage());
  dispatch(preSignedUrlRequest());

  return DeviceEnrollmentClient.getPreSignedUrl(accessToken, json)
    .then((response: GetPreSignedUrlResponse) => {

      const { url: url = "" } = response;

      dispatch(setPreSignedUrl(url));
      dispatch(preSignedUrlSuccess());
      return dispatch(batchEnrollDevice());
    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to get pre-signed URL" } = response;

      dispatch(preSignedUrlFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(setErrorTitle("Failed to get Pre-Signed URL"));

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideLoadingIndicator());
    });
};

export const batchEnrollDevice = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const preSignedUrl = DeviceEnrollmentWizardSelectors.getPreSignedUrl(state);
  const encodedPayload = DeviceEnrollmentWizardSelectors.getBase64payload(state);
  const file = DeviceEnrollmentWizardSelectors.getFile(state);

  const payload = DeviceEnrollmentWizardSelectors.isFileUploadSelected(state) ? file : encodedPayload;

  if (payload === file && !DeviceEnrollmentWizardSelectors.isFileUploaded(state)) {
    return Promise.resolve();
  } else if (payload === encodedPayload && isEmptyString(encodedPayload)) {
    return Promise.resolve();
  }

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setErrorMessage());
  dispatch(batchDeviceEnrollmentRequest());

  return DeviceEnrollmentClient.batchEnrollDevices(preSignedUrl, payload)
    .then(() => {

      dispatch(batchDeviceEnrollmentSuccess());
      dispatch(setSuccessMessage("Batch Enrollment request submitted"));
      return dispatch(hideLoadingIndicator());
    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to submit batch enrollment request" } = response;

      dispatch(batchDeviceEnrollmentFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(setErrorTitle("Batch Enrollment Failed"));

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideLoadingIndicator());
    });
};

export const batchEnrollment = () => (dispatch: any, getState: () => AppSchema) => {

  if (!DeviceEnrollmentWizardSelectors.isFileUploadSelected(getState())) {
     return Promise.resolve(dispatch(convertJsonToBase64()))
       .then(dispatch(generatePreSignedUrl()));
  }
  return dispatch(generatePreSignedUrl());
};

export const save = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  if (DeviceEnrollmentWizardSelectors.isValidateModeActive(state)) {
    return dispatch(validateDeviceEnrollment());
  }

  if (DeviceEnrollmentWizardSelectors.isBatchEnrollment(state)
    || DeviceEnrollmentWizardSelectors.isFileUploadSelected(state)) {
    return dispatch(batchEnrollment());
  } else {
    return dispatch(enrollDevice());
  }
};

export const openAddDeviceDialog = () => (dispatch: any) => {
  dispatch(updateDeviceId(uuidV4()));
  return dispatch(setAddDeviceDialog(true));
};

export const openEditDeviceDialog = (device: DeviceDataModel) => (dispatch: any) => {
  dispatch(setDevice(device));
  dispatch(setDeviceEditMode(true));
  dispatch(setAddDeviceWizardStep(AddDeviceWizardStep.EDITOR));
  return dispatch(setAddDeviceDialog(true));
};

export const openCloneDeviceDialog = (device: DeviceDataModel) => (dispatch: any) => {
  dispatch(setDevice(device));
  dispatch(updateDeviceId(uuidV4()));
  return dispatch(setAddDeviceDialog(true));
};

export const closeAddDeviceDialog = () => (dispatch: any) => {
  dispatch(setDevice());
  dispatch(setAddDeviceWizardStep());
  dispatch(setDeviceDataMode());
  dispatch(setDeviceEditMode());
  return dispatch(setAddDeviceDialog(false));
};

export const openRemoveDeviceDialog = (device: DeviceDataModel) => (dispatch: any) => {
  dispatch(setDevice(device));
  return dispatch(setRemoveDeviceDialog(true));
};

export const closeRemoveDeviceDialog = () => (dispatch: any) => {
  dispatch(setDevice());
  dispatch(setAddDeviceWizardStep());
  return dispatch(setRemoveDeviceDialog(false));
};

export const openDeviceEnrollmentFromValidateResult = (device: DeviceDataModel = DeviceDataModel.EMPTY) =>
  (dispatch: any) => {
  dispatch(setDevices([device]));
  return dispatch(setValidatedPayload(true));
  };

export const openDeviceEnrollmentForReEnrollment = (device: DeviceDataModel = DeviceDataModel.EMPTY) =>
  (dispatch: any) => {
    dispatch(setDevices([device]));
    return dispatch(setReEnrollmentPayload(true));
  };

export const openDeviceEnrollmentFromDeviceType =
  (deviceType: DeviceTypeModelV3 = DeviceTypeModelV3.EMPTY) =>
  (dispatch: any) => {
    dispatch(setDeviceTypeAttributes(deviceType.toJS()));
    return dispatch(setDeviceTypeSelected(true));
  };

export const setDeviceRequest = (device: DeviceDataModel) =>
  (dispatch: any) => {
    return dispatch(setValidationViewDeviceRequest(device));
  };
