import { isEmptyString } from "@util";
import { AppSchema } from "@main/schemas";
import { createActions } from "@modules/base";
import { refresh as refreshDataCatalogModule } from "@modules/dataCatalog/actions";
import {
  getAccessToken,
  getApprovalRequestId,
  getComments,
  isApproveRequestAction,
} from "./selectors";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { RestClientError, DataApprovalRequestClient } from "@network";
import { ApproveRequest, ApproveRequestAction } from "@data";

export const {
  approvalRequestId: setApprovalRequestId,
  dataSetAlias: setDataSetAlias,
  comments: setComments,
  open: setOpen,
  action: setAction,
  setErrorMessage,
  setSuccessMessage,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  APPROVE_REQUEST_REQUEST: approveRequestRequest,
  APPROVE_REQUEST_SUCCESS: approveRequestSuccess,
  APPROVE_REQUEST_FAILED: approveRequestFailed,
  REJECT_REQUEST_REQUEST: rejectRequestRequest,
  REJECT_REQUEST_SUCCESS: rejectRequestSuccess,
  REJECT_REQUEST_FAILED: rejectRequestFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setApprovalRequestId());
  dispatch(setDataSetAlias());
  dispatch(setComments());
  dispatch(setOpen());
  dispatch(setAction());
  return dispatch(baseReset());
};

export const approveRequest = (approvalRequestId: string = "", dataSetAlias: string = "") => (dispatch: any) => {
  return dispatch(open(approvalRequestId, dataSetAlias, ApproveRequestAction.APPROVE_REQUEST));
};

export const rejectRequest = (approvalRequestId: string = "", dataSetAlias: string = "") => (dispatch: any) => {
  return dispatch(open(approvalRequestId, dataSetAlias, ApproveRequestAction.REJECT_REQUEST));
};

export const save = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const approvalRequestId = getApprovalRequestId(state);
  const comments = getComments(state).trim();
  const accessToken = getAccessToken(state);
  const isApprove = isApproveRequestAction(state);

  const json = JSON.stringify(new ApproveRequest({comments}).toJS());

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());

  if (isApprove) {
    dispatch(approveRequest());
  } else {
    dispatch(rejectRequest());
  }

  return DataApprovalRequestClient.approveApprovalRequest(accessToken, approvalRequestId, isApprove, json)
    .then(() => {

      if (isApprove) {
        dispatch(approveRequestSuccess());
      } else {
        dispatch(rejectRequestSuccess());
      }
      dispatch(refreshDataCatalogModule());
      dispatch(hideLoadingIndicator());

      if (isApprove) {
        return dispatch(setSuccessMessage("Request Approved"));
      } else {
        return dispatch(setSuccessMessage("Request Rejected"));
      }
    }, (response: RestClientError) => {

      const { analytic, status, error } = response;

      if (isApprove) {
        dispatch(approveRequestFailed(analytic));
      } else {
        dispatch(rejectRequestFailed(analytic));
      }

      dispatch(hideLoadingIndicator());

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(setErrorMessage(error));
    });
};

export const open =
  (approvalRequestId: string, dataSetAlias: string, action: ApproveRequestAction) => (dispatch: any) => {

  if (isEmptyString(approvalRequestId) || isEmptyString(dataSetAlias)) {
    return Promise.resolve();
  }

  dispatch(reset());
  dispatch(setApprovalRequestId(approvalRequestId));
  dispatch(setDataSetAlias(dataSetAlias));
  dispatch(setAction(action));

  return dispatch(setOpen(true));
};

export const close = reset;

export const initialize = reset;
