import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    textField: {
      fontSize: 12,
      height: "auto",
      padding: 8,
    },
    formHelperText: {
      fontSize: 12,
    },
    inputLabel: {
      transform: "translate(12px, 25px) scale(1)",
      "&$error:not($focused)": {
        transform: "translate(12px, 5px) scale(1)",
      },
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    focused: {
    },
    error: {
    },
  });

export default styles;
