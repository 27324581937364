import React from "react";
import { noop } from "@util";
import Policy from "@data/Policy";
import classnames from "classnames";
import { SummaryViewData } from "@data";
import SummaryView from "@components/summary-view";
import ContinueButton from "@components/continue-button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PolicyStatementsList, {
  PolicyStatementsListColumn,
} from "@components/policy-statement-list";
import styles from "./styles";

export interface PolicyViewModel {
  className?: string;
  policy?: Policy;
  editButtonDisabled?: boolean;
}

export interface PolicyViewActions {
  onClickEditPolicy?: () => void;
}

type Model = PolicyViewModel;
type Actions = PolicyViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const PolicyView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    policy = Policy.EMPTY,
    editButtonDisabled,
    onClickEditPolicy = noop,
    children,
  } = props;

  const items = React.useMemo(() => [
    new SummaryViewData({
      className: "name",
      name: "Name",
      value: policy.getName(),
    }),
    new SummaryViewData({
      className: "version",
      name: "Version",
      value: policy.getVersion(),
    }),
    new SummaryViewData({
      className: "path",
      name: "Path",
      value: policy.getPath(),
    }),
    new SummaryViewData({
      className: "description",
      name: "Description",
      value: policy.getDescription(),
    }),
  ], [
    policy,
  ]);

  const [nameFilter, setNameFilter] = React.useState("");

  const [sortOrderAscending, setSortOrderAscending] = React.useState(true);

  const [sortByColumn, setSortByColumn] = React.useState(PolicyStatementsListColumn.ACTION);

  const statements = React.useMemo(() => policy.getStatements(), [policy]);

  const controls = React.useMemo(() => (
    <div className={classnames("controls", classes.controls)}>
      <ContinueButton
        className={classnames("editButton", classes.editButton)}
        color="primary"
        label="Edit Policy"
        disabled={editButtonDisabled}
        onClick={onClickEditPolicy}
      />
    </div>
  ), [classes, editButtonDisabled, onClickEditPolicy]);

  if (!policy.isValid()) {
    return (
      <div className={classnames("policyView", className, classes.container)}>
        {controls}
        <div className={classnames("emptyView", classes.emptyView)}>
          <p className={classnames("emptyViewLabel", classes.emptyViewLabel)}>
            No policy attached to this workload
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={classnames("policyView", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        <SummaryView
          className={classnames("summaryView", classes.summaryView)}
          items={items}
        />
        {controls}
      </div>
      <PolicyStatementsList
        className={classnames("statementsList", classes.statementsList)}
        items={statements}
        showSearch={statements.length > 1}
        showSummary={true}
        showIcon={false}
        showRefreshButton={false}
        nameFilter={nameFilter}
        sortOrderAscending={sortOrderAscending}
        sortByColumn={sortByColumn}
        setNameFilter={setNameFilter}
        setSortOrderAscending={setSortOrderAscending}
        setSortByColumn={setSortByColumn}
      />
      {children}
    </div>
  );
});

export default PolicyView;
