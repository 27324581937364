import React from "react";
import classnames from "classnames";
import { DEFAULT_NAMESPACE_ACTION_MENU_ITEMS } from "../namespace-actions-menu";
import NamespacesList, { NamespacesListActions, NamespacesListModel } from "../namespaces-list";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface NamespacesModel extends NamespacesListModel {
  className?: string;
  listClassName?: string;
}

export interface NamespacesActions extends NamespacesListActions {
  createNamespace?: () => void;
}

type Props = WithStyles<typeof styles> & NamespacesModel & NamespacesActions & {
  children?: React.ReactNode;
};

export const Namespaces = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    listClassName,
    showSummary = true,
    createButtonLabel = "Create Namespace",
    createButtonClassName = "createNameSpaceButton",
    actions = DEFAULT_NAMESPACE_ACTION_MENU_ITEMS,
    createNamespace,
    onClickCreateButton = createNamespace,
    children,
    ...otherProps
  } = props;

  return (
    <NamespacesList
      {...otherProps}
      className={classnames("namespaces", className, classes.container)}
      listClassName={classnames("list", listClassName, classes.list)}
      actions={actions}
      showSummary={showSummary}
      createButtonLabel={createButtonLabel}
      createButtonClassName={createButtonClassName}
      onClickCreateButton={onClickCreateButton}
    >
      {children}
    </NamespacesList>
  );
});

export default Namespaces;
