import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { isEmptyString, noop, scrollToTop } from "@util";
import { DataSetRequestWizardSelectors } from "../selectors";
import DataSetRequestWizard, { Actions, Model } from "../components/DataSetRequestWizard";
import DataSetAliasView from "./DataSetAliasView";
import DataOwnersView from "./DataOwnersView";
import ArchitectsView from "./ArchitectsView";
import EngineeringContactsView from "./EngineeringContactsView";
import BusinessOwnersView from "./BusinessOwnersView";
import DataDescriptionView from "./DataDescriptionView";
import ReviewView from "./ReviewView";
import { DataSetRequestWizardActions } from "../actions";
import EditIoTApprovedRequest from "../components/EditIoTApprovedRequest";
import EditDescriptionView from "./EditDescriptionView";
import TermsConditions from "./TermsConditions";
import MakeRequestDialog from "./MakeRequestDialog";
import EditModeInitializeErrorView from "./EditModeInitializeErrorView";

interface ContainerModel extends Model {
  requestIoTApproved?: boolean;
  dataSetRequestId?: string;
  showEditModeInitializeErrorView?: boolean;
  editMode?: boolean;
}

interface ContainerActions extends Actions {
  setTermsConditionsFileProvided?: (termsConditionsFileProvided: boolean) => void;
  showDataSetDetails?: (dataSetRequestId: string) => void;
  onEditSuccess?: (dataSetRequestId: string) => void;
}

type Props = ContainerModel & ContainerActions;

const DataSetRequestWizardContainer = (props: Props) => {

  const {
    requestIoTApproved = false,
    editMode = false,
    dataSetRequestId = "",
    errorMessage,
    successMessage,
    showLoadingIndicator,
    showSuccessIndicator,
    showEditModeInitializeErrorView,
    cancel,
    save = noop,
    setTermsConditionsFileProvided = noop,
    showDataSetDetails = noop,
    onEditSuccess = noop,
    ...otherProps
  } = props;

  const [showMakeRequestDialog, setShowMakeRequestDialog] = React.useState(false);

  const [showCreateSuccessIndicator, setShowCreateSuccessIndicator] = React.useState(false);

  const [termsConditionsFile, setTermsConditionsFile] = React.useState<File | null>(null);

  const createSuccessMessage = React.useMemo(() =>
      !showCreateSuccessIndicator ? "" : successMessage,
    [showCreateSuccessIndicator, successMessage]);

  const onClickSave = React.useCallback(() => {
    setShowMakeRequestDialog(true);
    save();
  }, [setShowMakeRequestDialog]);

  const onCreateSuccess = React.useCallback(() => {
    showDataSetDetails(dataSetRequestId);
  }, [showDataSetDetails, dataSetRequestId]);

  const onSuccess = React.useCallback(() =>
      editMode ? onEditSuccess() : onCreateSuccess(),
    [editMode, onEditSuccess, onCreateSuccess]);

  const closeMakeRequestDialog = React.useCallback(() => {
    setShowCreateSuccessIndicator(true);
  }, [setShowCreateSuccessIndicator]);

  // When the user hits the save button, make sure we scroll back to the top
  React.useLayoutEffect(() => scrollToTop(), [showLoadingIndicator]);

  // Update redux store whe our locally stored file state changes
  React.useEffect(() => {
    setTermsConditionsFileProvided(termsConditionsFile !== null);
  }, [termsConditionsFile, setTermsConditionsFileProvided]);

  // Close the make request dialog if a data set request error occurs
  React.useEffect(() => {
    if (!isEmptyString(errorMessage)) {
      setShowMakeRequestDialog(false);
    }
  }, [errorMessage, setShowMakeRequestDialog]);

  if (showEditModeInitializeErrorView) {
    return <EditModeInitializeErrorView cancel={cancel} />;
  }

  if (requestIoTApproved) {
    return (
      <EditIoTApprovedRequest
        {...otherProps}
        errorMessage={errorMessage}
        successMessage={successMessage}
        showLoadingIndicator={showLoadingIndicator}
        showSuccessIndicator={showSuccessIndicator}
        cancel={cancel}
        save={save}
        onSuccess={onEditSuccess}
      >
        <EditDescriptionView />
      </EditIoTApprovedRequest>
    );
  }

  return (
    <DataSetRequestWizard
      {...otherProps}
      errorMessage={errorMessage}
      successMessage={createSuccessMessage}
      showSuccessIndicator={showCreateSuccessIndicator}
      cancel={cancel}
      save={onClickSave}
      onSuccess={onSuccess}
    >
      <DataSetAliasView />
      <DataOwnersView />
      <ArchitectsView />
      <EngineeringContactsView />
      <BusinessOwnersView />
      <DataDescriptionView />
      <TermsConditions
        termsConditionsFile={termsConditionsFile}
        onChangeTermsConditionsFile={setTermsConditionsFile}
      />
      <ReviewView />
      {showMakeRequestDialog && (
        <MakeRequestDialog
          termsConditionsFile={termsConditionsFile}
          close={closeMakeRequestDialog}
        />
      )}
    </DataSetRequestWizard>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  className: "createDataSetRequestWizard",
  currentState: DataSetRequestWizardSelectors.getDataSetRequestState(state),
  steps: DataSetRequestWizardSelectors.getSteps(state),
  dataSetRequestId: DataSetRequestWizardSelectors.getDataSetRequestRef(state),
  successMessage: DataSetRequestWizardSelectors.getSuccessMessage(state),
  errorTitle: DataSetRequestWizardSelectors.getErrorTitle(state),
  errorMessage: DataSetRequestWizardSelectors.getErrorMessage(state),
  errors: DataSetRequestWizardSelectors.getErrors(state),
  showLoadingIndicator: DataSetRequestWizardSelectors.isLoadingIndicatorVisible(state),
  showSuccessIndicator: DataSetRequestWizardSelectors.isSuccessMessageVisible(state),
  currentStep: DataSetRequestWizardSelectors.getDataSetRequestWizardStep(state),
  completedSteps: DataSetRequestWizardSelectors.getCompletedSteps(state),
  disabledSteps: DataSetRequestWizardSelectors.getDisabledSteps(state),
  previousStepButtonDisabled: DataSetRequestWizardSelectors.isPreviousStepButtonDisabled(state),
  nextStepButtonDisabled: DataSetRequestWizardSelectors.isNextStepButtonDisabled(state),
  showSaveButton: DataSetRequestWizardSelectors.isLastStepActive(state),
  showEditModeInitializeErrorView: DataSetRequestWizardSelectors.isEditModeInitializeErrorVisible(state),
  requestIoTApproved: DataSetRequestWizardSelectors.isRequestIoTApproved(state),
  editMode: DataSetRequestWizardSelectors.isEditModeActive(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setTermsConditionsFileProvided: termsConditionsFileProvided => dispatch(
    DataSetRequestWizardActions.setTermsConditionsFileProvided(termsConditionsFileProvided)),
  setCurrentStep: step => dispatch(DataSetRequestWizardActions.setCurrentStep(step)),
  previousStep: () => dispatch(DataSetRequestWizardActions.showPreviousStep()),
  nextStep: () => dispatch(DataSetRequestWizardActions.showNextStep()),
  save: () => dispatch(DataSetRequestWizardActions.save()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DataSetRequestWizardContainer);
