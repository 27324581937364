import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    header: {
      borderRadius: 0,
      borderLeft: "none",
      borderTop: "none",
      borderRight: "none",
      borderBottom: `1px solid ${Color.GREY1}`,
    },
    content: {
      padding: 10,
    },
    list: {
    },
    emptyView: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    emptyViewHeader: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      padding: "24px 12px",
      backgroundColor: Color.LIGHT_GREY4,
      borderBottom: `1px solid ${Color.GREY1}`,
    },
    emptyViewContent: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      maxHeight: 200,
    },
    emptyViewTitle: {
      color: Color.TEXT,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "22px",
    },
    emptyViewSubtitle: {
      color: Color.GREY3,
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 16,
    },
    emptyViewLink: {
      color: Color.BLUE,
      cursor: "pointer",
      textDecoration: "underline",
    },
  });

export default styles;
