import { createStyles, StyleRules, Theme } from "@material-ui/core/styles";
import { Color } from "../../../../components/styles";

export const dialog: StyleRules<string> = {
  paper: {
    backgroundColor: Color.WHITE,
    maxWidth: 500,
    minWidth: 380,
    padding: 23,
    width: "calc(100vw - 80px)",
  },
};

export const dialogTitle: StyleRules<string> = {
  root: {
    color: Color.TEXT,
    fontFamily: "Roboto, sans-serif",
    fontSize: 20,
    fontWeight: 300,
    lineHeight: "24px",
    margin: 0,
    padding: 0,
  },
};

export const dialogContent: StyleRules<string> = {
  root: {
    margin: 0,
    padding: 0,
  },
};

export const dialogActions: StyleRules<string> = {
  root: {
    margin: 0,
  },
};

export const styles = (theme: Theme) =>
  createStyles({
    dialogBody: {
      padding: 23,
    },
    label: {
      color: Color.GREY6,
      fontFamily: "Roboto, sans-serif",
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "21px",
      marginTop: 20,
    },
    link: {
      color: Color.LIGHT_BLUE,
      padding: 0,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    closeButton: {
      fontSize: 14,
      fontWeight: 500,
      height: 36,
      lineHeight: "16px",
    },
  });

export default styles;
