import React from "react";
import classnames from "classnames";
import { formEventHandler, noop } from "@util";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import { searchInput } from "./styles";

const SearchInput = withStyles(searchInput)(TextField);

export const Search = (props: {
  className?: string;
  ariaLabel?: string;
  hint?: string;
  value?: string;
  autoFocus?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  variant?: "standard" | "outlined" | "filled";
  style?: React.CSSProperties;
  updateSearchQuery?: (newValue: string, previousValue?: string) => void;
}) => {

  const {
    className,
    ariaLabel = "search",
    style,
    hint = "Search",
    value = "",
    autoFocus = false,
    fullWidth = false,
    disabled,
    error,
    helperText,
    variant = "standard",
    updateSearchQuery = noop,
    ...otherProps
  } = props;

  const onChange = formEventHandler((newValue: string) => updateSearchQuery(newValue, value));

  return (
    <SearchInput
      {...otherProps}
      className={classnames("searchInput", className)}
      type="search"
      style={style}
      value={value}
      placeholder={hint}
      fullWidth={fullWidth}
      onChange={onChange}
      autoFocus={autoFocus}
      disabled={disabled}
      helperText={helperText}
      error={error}
      variant={variant}
      inputProps={{
        "aria-label": ariaLabel,
      }}
    />
  );
};

export default Search;
