import copyToClipboard from "clipboard-copy";
import { useState, useCallback, useEffect } from "react";
import { noop } from "@util";

export const useClipboard = (value: string, leaveDelay: number) => {

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const onSuccess = useCallback(() => {
    setShowSuccess(true);
    setShowError(false);
  }, [setShowSuccess, setShowError]);

  const onError = useCallback(() => {
    setShowSuccess(false);
    setShowError(true);
  }, [setShowSuccess, setShowError]);

  const copy = useCallback(() => {

    copyToClipboard(value).then(onSuccess, onError);

  }, [value, onSuccess, onError]);

  useEffect(() => {

    if (showTooltip || (!showSuccess && !showError)) {
      return () => noop;
    }

    const timer = setTimeout(() => {
      setShowSuccess(false);
      setShowError(false);
    }, leaveDelay + 1);

    return () => clearTimeout(timer);

  }, [showSuccess, showError, showTooltip]);

  return {
    copy,
    showSuccess, setShowSuccess,
    showError, setShowError,
    showTooltip, setShowTooltip,
  };
};

export default useClipboard;
