import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import {
  getSuccessMessage,
  isCommentLoadingIndicatorVisible,
  isAddCommentButtonActive,
  getCommentErrorMessage,
  isCreateCommentBox,
  getComment,
} from "../selectors";
import { createDataAccessRequestComment, showCreateCommentBox, hideCreateCommentBox, setComment } from "../actions";
import CreateCommentView, { Model, Actions } from "@components/request-comments/CreateCommentView";

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => {

  const successMessage = getSuccessMessage(state);
  const finished = !!(successMessage && successMessage.length > 0);
  const showProgressIndicator = isCommentLoadingIndicatorVisible(state);
  const buttonsDisabled = showProgressIndicator || finished;

  const showCancelButton = !finished && !showProgressIndicator;
  const showSaveButton = !finished && isAddCommentButtonActive(state);

  return {
    showProgressIndicator,
    showCancelButton,
    showSaveButton,
    finished,
    buttonsDisabled,
    errorMessage: getCommentErrorMessage(state),
    createCommentBoxVisible: isCreateCommentBox(state),
    comment: getComment(state),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  save: () => dispatch(createDataAccessRequestComment()),
  cancel: () => dispatch(hideCreateCommentBox()),
  setComment: (comment: string) => dispatch(setComment(comment)),
  addComment: () => dispatch(showCreateCommentBox()),
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(CreateCommentView);
