import React from "react";
import { Location } from "history";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import GroupManagementWizard from "./containers/GroupManagementWizard";
import SelectSecurityGroupsView from "./containers/SelectSecurityGroupsView";
import SelectServiceView from "./containers/SelectServiceView";
import SelectUsersView from "./containers/SelectUsersView";

function GroupManagementWizardView(props: {
  className?: string,
  children?: React.ReactNode,
}) {

  const history = useHistory();

  const { children } = props;

  const { url } = useRouteMatch();

  const { state: { from } = { from: undefined } } = useLocation<{ from?: Location }>();

  const cancel = React.useCallback(() => history.goBack(), [history]);

  // Replace this history entry with the entry point - if provided
  // otherwise, just go back to the previous page - default behavior
  const onSuccess = React.useCallback(() => {
    if (from) {
      history.replace(from);
    } else {
      history.goBack();
    }
  }, [history, from]);

  return (
    <GroupManagementWizard
      key={url}
      cancel={cancel}
      onSuccess={onSuccess}
    >
      {children}
    </GroupManagementWizard>
  );
}

export function ManageUserGroupsView() {

  return (
    <GroupManagementWizardView>
      <SelectSecurityGroupsView />
    </GroupManagementWizardView>
  );
}

export function ManageGroupUsersView() {

  return (
    <GroupManagementWizardView>
      <SelectUsersView />
    </GroupManagementWizardView>
  );
}

export function ManageGroupServicesView() {

  return (
    <GroupManagementWizardView>
      <SelectServiceView />
    </GroupManagementWizardView>
  );
}

export function ManageServiceGroupsView() {

  return (
    <GroupManagementWizardView>
      <SelectSecurityGroupsView />
    </GroupManagementWizardView>
  );
}
