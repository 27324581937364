import React from "react";
import { noop } from "@util";
import { AuthTokenContext } from "@components";
import { RestClientError, UiManagerLegacyClient } from "@network";
import {
  DeleteUiPackageDialog,
  DeleteUiPackageDialogActions as Actions,
  DeleteUiPackageDialogModel as Model,
} from "./DeleteUiPackageDialog";

export interface DeleteUiPackageModel extends Model {
}

export interface DeleteUiPackageActions extends Actions {
  showLoadingIndicator?: () => void;
  deleteUiPackageRequest?: () => void;
  deleteUiPackageSuccess?: () => void;
  deleteUiPackageFailed?: (error: string) => void;
}

type Props = DeleteUiPackageModel & DeleteUiPackageActions;

export const DeleteUiPackage = (props: Props) => {

  const {
    cancel = noop,
    onSuccessMessageShown = noop,
    showLoadingIndicator: showDeletingUiPackage = noop,
    deleteUiPackageRequest = noop,
    deleteUiPackageSuccess = noop,
    deleteUiPackageFailed = noop,
    ...otherProps
  } = props;

  const authToken = React.useContext(AuthTokenContext);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);

  const deleteUiPackage = React.useCallback(() =>
      UiManagerLegacyClient.deleteUiPackage(authToken),
[authToken]);

  React.useEffect(() => {

    let ignore = false;

    if (showLoadingIndicator) {

      setErrorMessage("");
      deleteUiPackageRequest();

      deleteUiPackage()
        .then(() => {
          if (!ignore) {
            setShowLoadingIndicator(false);
            deleteUiPackageSuccess();
            setSuccessMessage("Ui Package Deleted");
          }
        }, (response: RestClientError) => {
          if (!ignore) {
            const { error = "Delete Ui Package failed" } = response;
            setErrorMessage(error);
            deleteUiPackageFailed(error);
            setShowLoadingIndicator(false);
          }
        });
    }
  }, [
    showLoadingIndicator,
    setErrorMessage,
    deleteUiPackage,
    setShowLoadingIndicator,
    setSuccessMessage,
    deleteUiPackageRequest,
    deleteUiPackageSuccess,
    deleteUiPackageFailed,
  ]);

  const confirm = React.useCallback(() => {
    showDeletingUiPackage();
    setShowLoadingIndicator(true);
  }, [showDeletingUiPackage, setShowLoadingIndicator]);

  return (
    <DeleteUiPackageDialog
      {...otherProps}
      open={true}
      errorMessage={errorMessage}
      successMessage={successMessage}
      loading={showLoadingIndicator}
      cancel={cancel}
      confirm={confirm}
      onSuccessMessageShown={onSuccessMessageShown}
    />
  );
};

export default DeleteUiPackage;
