import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { SummaryViewData } from "@data";
import { isEmptyString, noop } from "@util";
import {
  getErrorMessage,
  getJson,
  getPolicyDescription,
  getPolicyName,
  getPolicyPath,
  getPolicyVersion,
  isLoadingIndicatorVisible,
  isAccessDeniedVisible,
  isNotFoundVisible,
} from "../selectors";
import ModuleIcon from "@material-ui/icons/Assignment";
import { refresh, showDeletePolicyDialog } from "../actions";
import SummaryJsonDetailsView from "@components/summary-json-details-view";
import { ActionMenuItem, POLICY_ACTION_MENU_ITEMS, PolicyAction } from "@components";
import Statement from "./StatementsView";

export interface ContainerModel {
  name?: string;
  version?: string;
  path?: string;
  description?: string;
  json?: string;
  errorMessage?: string;
  showNotFound?: boolean;
  showAccessDenied?: boolean;
  title?: string;
  loading?: boolean;
}

export interface ContainerActions {
  refresh?: () => void;
  deletePolicy?: () => void;
  editPolicy?: () => void;
  onClickAction?: (action: ActionMenuItem) => void;
}

type Props = ContainerModel & ContainerActions;

const PolicyDetailsContainer = (props: Props) => {

  const {
    showNotFound,
    showAccessDenied,
    title,
    refresh: refreshPage,
    loading,
    editPolicy = noop,
    deletePolicy = noop,
    onClickAction = noop,
    name,
    version,
    path,
    description,
    json,
    errorMessage,
  } = props;

  const summaryViewItems = React.useMemo(() => [
    new SummaryViewData({
      className: "name",
      name: "Name",
      value: name,
    }),
    new SummaryViewData({
      className: "version",
      name: "Version",
      value: version,
    }),
    new SummaryViewData({
      className: "path",
      name: "Path",
      value: path,
    }),
    new SummaryViewData({
      className: "description",
      name: "Description",
      value: description,
    }),
  ], [
    name,
    path,
    version,
    description,
  ]);

  const actionClicked = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case PolicyAction.DELETE:
        return deletePolicy();
      case PolicyAction.EDIT:
        return editPolicy();
      default:
        return onClickAction(action);
    }
  }, [deletePolicy, editPolicy, onClickAction]);

  const StatementView = React.useMemo(() => (
    <Statement/>
  ), []);

  return (
      <SummaryJsonDetailsView
        title={title}
        icon={ModuleIcon}
        refresh={refreshPage}
        showLoadingIndicator={loading}
        actions={POLICY_ACTION_MENU_ITEMS}
        onClickAction={actionClicked}
        summaryViewItems={summaryViewItems}
        additionalViews={StatementView}
        json={json}
        fileName={`${name}.json`}
        showJsonHeader={!isEmptyString(name)}
        jsonViewClassName={"policyJsonView"}
        errorMessage={errorMessage}
        showNotFound={showNotFound}
        showAccessDenied={showAccessDenied}
      />
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
  loading: isLoadingIndicatorVisible(state),
  errorMessage: getErrorMessage(state),
  title: getPolicyName(state),
  showNotFound: isNotFoundVisible(state),
  showAccessDenied: isAccessDeniedVisible(state),
  name: getPolicyName(state),
  version: getPolicyVersion(state),
  path: getPolicyPath(state),
  description: getPolicyDescription(state),
  json: getJson(state),
});

const mapDispatchToProps = (dispatch: any): ContainerActions => ({
  refresh: () => dispatch(refresh()),
  deletePolicy: () => dispatch(showDeletePolicyDialog()),
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(PolicyDetailsContainer);
