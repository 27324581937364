import createStyles from "@material-ui/core/styles/createStyles";
import { Color } from "@components";

export const saveProfileAttributesDialog = () =>
  createStyles({
    label: {
      margin: 8,
      padding: 0,
      lineHeight: "18px",
      fontSize: 16,
      color: Color.TEXT,
      textAlign: "center",
    },
  });

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      position: "relative",
    },
    editMode: {
      zIndex: 1201,
      backgroundColor: Color.WHITE,
      padding: "20px 10px",
      margin: "-10px",
    },
    editModeModal: {
      background: "rgba(105, 105, 105, 0.25)",
      position: "fixed",
      zIndex: 1200,
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    editModeTitle: {
      color: Color.GREY6,
      fontSize: 28,
      fontWeight: 400,
      margin: "8px 0 15px",
    },
    editModeControls: {
      margin: "15px 10px 0 0",
    },
    controls: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      position: "absolute",
      right: 0,
      top: 0,
    },
    userProfileAttributes: {
    },
    editUserProfileAttributes: {
    },
    button: {
      textTransform: "none",
    },
    editButton: {

    },
    cancelButton: {

    },
    saveButton: {
      marginLeft: 15,
    },
  });

export default styles;
