import { isEmptyString } from "@util";
import {
  makeApiRequestAndComplete,
  withAuthToken,
  withRequiredArguments
} from "@network/helpers";

// #TODO: Change to regional endpoint once regional endpoint for this API is enabled
const PORTAL_USER_FEEDBACK_URL = process.env.REACT_APP_PORTAL_USER_FEEDBACK_API || "";

if (isEmptyString(PORTAL_USER_FEEDBACK_URL)) {
  throw new Error("Missing Environment Variable: REACT_APP_PORTAL_USER_FEEDBACK_API");
}

export const submitUserFeedback = (authToken: string,
                                   json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments( [
      ["Feedback Message", json],
    ]));

  const makeRequest = () => {

    const url = `${PORTAL_USER_FEEDBACK_URL}/portal/user-interactions/v1/feedback-messages`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to submit user feedback";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
