import React from "react";
import theme from "./theme";
import { noop } from "@util";
import styles from "./styles";
import classnames from "classnames";
import { ThemeProvider } from "@components/styles";
import DateIcon from "@material-ui/icons/DateRange";
import { IconButton } from "@components/icon-button";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  DatePicker as DatePickerComponent,
  DateTimePicker as DateTimePickerComponent,
} from "@material-ui/pickers";

export interface DatePickerModel {
  className?: string;
  datePickerInputClassName?: string;
  icon?: React.ReactNode | null;
  selectedDate?: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  label?: string;
  emptyLabel?: string;
  disabled?: boolean;
  autoOk?: boolean;
  clearable?: boolean;
  disableFuture?: boolean;
  format?: string;
  inputVariant?: "filled" | "outlined" | "standard";
  variant?: "date" | "date-time";
}

export interface DatePickerActions {
  setSelectedDate?: (date?: Date | null) => void;
}

type Model = DatePickerModel;
type Actions = DatePickerActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const DatePicker = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    datePickerInputClassName,
    icon = <DateIcon />,
    selectedDate = null,
    minDate,
    maxDate,
    label = "Select date",
    emptyLabel = "-",
    disabled,
    autoOk = true,
    clearable = true,
    disableFuture = true,
    format = "yyyy-MM-dd HH:mm",
    inputVariant = "outlined",
    variant = "date-time",
    setSelectedDate = noop,
    ...otherProps
  } = props;

  const DatePickerDialog = React.useMemo(() =>
    variant === "date-time" ? DateTimePickerComponent : DatePickerComponent, [variant]);

  return (
    <ThemeProvider theme={theme}>
      <DatePickerDialog
        className={classnames("datePicker", className, classes.container)}
        value={selectedDate}
        onChange={setSelectedDate}
        {...(!minDate ? ({}) : ({ minDate }))}
        {...(!maxDate ? ({}) : ({ maxDate }))}
        label={label}
        emptyLabel={emptyLabel}
        autoOk={autoOk}
        clearable={clearable}
        disabled={disabled}
        disableFuture={disableFuture}
        format={format}
        inputVariant={inputVariant}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                {icon}
              </IconButton>
            </InputAdornment>
          ),
          classes: {
            input: classnames(classes.datePickerInput, datePickerInputClassName),
          },
        }}
        {...otherProps}
      />
    </ThemeProvider>
  );
});

export default DatePicker;
