import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";
import {
  CreateWorkloadRequestV3,
  Policy,
  PolicyAttributes,
  WorkloadVariableSchema,
} from "@data";
import {
  QueryWorkloadAttributes,
  WorkloadCompletionMetadata,
  WorkloadData,
  WorkloadDataSetsAttributes,
  WorkloadQueryType,
  WorkloadType,
} from "./Workload";

export enum WorkloadYesNoOption {
  YES = "YES",
  NO = "NO",
  NO_ANSWER = "NO_ANSWER",
}

export enum WorkloadVolumeOption {
  GB = "GB",
  GB_TB = "GB_TB",
  TB = "TB",
  NO_ANSWER = "NO_ANSWER",
}

export enum WorkloadRuntimeOption {
  JAVA8 = "JAVA8",
  PYTHON27 = "PYTHON27",
  PYTHON37 = "PYTHON37",
  NONE = "",
}

export type WorkloadRuntimeOptionLabels<K extends keyof any = WorkloadRuntimeOption> = {
  [P in K]: string;
};

export const DEFAULT_WORKLOAD_RUNTIME_OPTION_LABELS: WorkloadRuntimeOptionLabels = {
  [WorkloadRuntimeOption.JAVA8]: "JAVA 8",
  [WorkloadRuntimeOption.PYTHON27]: "PYTHON 2.7",
  [WorkloadRuntimeOption.PYTHON37]: "PYTHON 3.7",
  [WorkloadRuntimeOption.NONE]: "",
};

export type WorkloadVolumeOptionLabels<K extends keyof any = WorkloadVolumeOption> = {
  [P in K]: string;
};

export const DEFAULT_WORKLOAD_VOLUME_OPTION_LABELS: WorkloadVolumeOptionLabels = {
  [WorkloadVolumeOption.GB]: "< 1 GB",
  [WorkloadVolumeOption.GB_TB]: "1 GB - 1 TB",
  [WorkloadVolumeOption.TB]: "> 1 TB",
  [WorkloadVolumeOption.NO_ANSWER]: "N/A",
};

export type WorkloadYesNoOptionLabels<K extends keyof any = WorkloadYesNoOption> = {
  [P in K]: string;
};

export const DEFAULT_WORKLOAD_YES_NO_OPTION_LABELS: WorkloadYesNoOptionLabels = {
  [WorkloadYesNoOption.YES]: "YES",
  [WorkloadYesNoOption.NO]: "NO",
  [WorkloadYesNoOption.NO_ANSWER]: "N/A",
};

export interface CreateWorkloadRequestAttributes {
  "@type": WorkloadType;
  type: WorkloadType;
  accountId: string;
  name: string;
  description: string;
  tags: string[];
  data: WorkloadData;
  entryPoint: string;
  timeout?: number;
  memory?: number;
  runtime: WorkloadRuntimeOption;
  policy: PolicyAttributes;
  query?: QueryWorkloadAttributes;
  completionMetadata?: WorkloadCompletionMetadata;
}

export class CreateWorkloadRequest extends Record({
  "@type": WorkloadType.FUNCTION,
  type: WorkloadType.FUNCTION,
  accountId: "",
  name: "",
  description: "",
  tags: [],
  data: {
    inputs: [],
    outputs: [],
  },
  entryPoint: "",
  timeout: 60,
  memory: 756,
  runtime: WorkloadRuntimeOption.NONE,
  policy: Policy.EMPTY.toJS(),
  query: {
    type: WorkloadQueryType.SELECT,
    query: "",
    databaseName: "",
    tableName: "",
    upload: false,
    overwrite: false,
  },
  completionMetadata: {
    actions: [],
    variableSchemas: [],
  },
}) implements CreateWorkloadRequestAttributes {

  public static EMPTY: CreateWorkloadRequest = new CreateWorkloadRequest();

  public readonly "@type": WorkloadType;
  public readonly type: WorkloadType;
  public readonly accountId: string;
  public readonly name: string;
  public readonly description: string;
  public readonly tags: string[];
  public readonly data: WorkloadData;
  public readonly entryPoint: string;
  public readonly timeout: number;
  public readonly memory: number;
  public readonly runtime: WorkloadRuntimeOption;
  public readonly policy: PolicyAttributes;
  public readonly query: QueryWorkloadAttributes;
  public readonly completionMetadata: WorkloadCompletionMetadata;

  public static from(workload: CreateWorkloadRequestV3): CreateWorkloadRequest {
    return new CreateWorkloadRequest({...workload.toJS()});
  }

  public getType(): WorkloadType {
    return this.type;
  }

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getName(): string {
    return getStringValue(this.name);
  }

  public getDescription(): string {
    return this.description;
  }

  public getTags(): string[] {
    return Array.isArray(this.tags) ? this.tags : [];
  }

  public getData(): WorkloadData {
    const { inputs = [], outputs = [] } = this.data || {};
    return {
      inputs,
      outputs,
    };
  }

  public getDataInputs(): WorkloadDataSetsAttributes[] {
    const { inputs = [] } = this.getData();
    return inputs;
  }

  public getDataOutputs(): WorkloadDataSetsAttributes[] {
    const { outputs = [] } = this.getData();
    return outputs;
  }

  public getEntryPoint(): string {
    return this.entryPoint;
  }

  public getRuntime(): WorkloadRuntimeOption {
    return this.runtime;
  }

  public getPolicy(): Policy {
    return new Policy(this.policy);
  }

  public hasPolicy(): boolean {
    return this.getPolicy().isValid();
  }

  public getDatabaseName(): string {
    const { databaseName = "" } = this.query;
    return databaseName;
  }

  public getTableName(): string {
    const { tableName = "" } = this.query;
    return tableName;
  }

  public getQueryType(): WorkloadQueryType {
    const { type = WorkloadQueryType.SELECT } = this.query;
    return type;
  }

  public getQuery(): string {
    const { query = "" } = this.query;
    return query;
  }

  public isOverwrite(): boolean {
    const { overwrite = false } = this.query;
    return overwrite;
  }

  public isUpload(): boolean {
    const { upload = false } = this.query;
    return upload;
  }

  public isSelectQuery(): boolean {
    const { type = "" } = this.query;
    return this.type === WorkloadType.QUERY && type === WorkloadQueryType.SELECT;
  }

  public isInsertQuery(): boolean {
    const { type = "" } = this.query;
    return this.type === WorkloadType.QUERY && type === WorkloadQueryType.INSERT;
  }

  public isFunctionType(): boolean {
    return this.type === WorkloadType.FUNCTION;
  }

  public isQueryType(): boolean {
    return this.type === WorkloadType.QUERY;
  }

  public getTimeout(): number {
    return this.timeout;
  }

  public getTimeoutAsString(): string {
    return this.timeout + "s";
  }

  public getMemory(): number {
    return this.memory;
  }

  public getMemoryAsString(): string {
    return this.memory + "MB";
  }

  public getWorkloadCompletionMetadata(): WorkloadCompletionMetadata {
    const {
      actions = [],
      variableSchemas = [],
    } = this.completionMetadata || {};
    return {
      actions: Array.isArray(actions) ? actions : [],
      variableSchemas: Array.isArray(variableSchemas) ? variableSchemas : [],
    };
  }

  public getWorkloadCompletionMetadataActions(): string[] {
    const { actions = [] } = this.getWorkloadCompletionMetadata();
    return actions.map(getStringValue).filter(it => !isEmptyString(it));
  }

  public getWorkloadCompletionMetadataVariableSchemas(): WorkloadVariableSchema[] {
    const { variableSchemas = [] } = this.getWorkloadCompletionMetadata();
    return variableSchemas
      .map(attrs => new WorkloadVariableSchema(attrs))
      .filter(variableSchema => variableSchema.hasName());
  }
}

export default CreateWorkloadRequest;
