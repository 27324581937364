import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "../../main/schemas";
import { RemovePropertyActions } from "../actions/removeProperty";
import { JsonSchemaProperty } from "../../../data";
import { SchemaWizardSelectors } from "../selectors/schemaWizard";
import { RemovePropertySelectors } from "../selectors/removeProperty";
import {
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel,
} from "../../../components/confirmation-dialog";
import RemoveProperty from "./RemoveProperty";

type Model = ConfirmationDialogModel<JsonSchemaProperty>;
type Actions = ConfirmationDialogActions<JsonSchemaProperty>;

const RemovePropertyDialog = (props: Model & Actions) => {

  const { open = false } = props;

  if (!open) {
    return null;
  }

  return (
    <ConfirmationDialog {...props}>
      <RemoveProperty />
    </ConfirmationDialog>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  className: "removePropertyDialog",
  title: "Remove Property",
  continueButtonLabel: "Remove Property",
  open: SchemaWizardSelectors.isRemovePropertyVisible(state),
  item: RemovePropertySelectors.getProperty(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  cancel: () => dispatch(RemovePropertyActions.close()),
  confirm: () => dispatch(RemovePropertyActions.removeProperty()),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(RemovePropertyDialog);
