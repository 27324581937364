import createStyles from "@material-ui/core/styles/createStyles";
import { StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const commentTextField: StyleRules = {
  root: {
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& textarea": {
      fontSize: 14,
      fontWeight: 400,
      padding: 0,
      margin: 0,
      color: Color.BLACK4,
    },
  },
};

export const createCommentsView = () =>
  createStyles({
    container: {
      marginTop: 12,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    createCommentContainer: {
      flex: "0 0 auto",
      position: "relative",
      right: 0,
      top: 0,
    },
    actionsContainer: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      marginTop: 25,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    addCommentButton: {
      alignSelf: "flex-start",
    },
    addCommentButtonLabel: {
      fontWeight: 500,
    },
    comment: {
      marginTop: 25,
    },
    cancelButton: {
    },
    saveButton: {
      marginLeft: 16,
    },
  });

export const commentsListView = createStyles({
  label: {
    color: Color.GREY7,
    fontSize: 20,
    fontWeight: 300,
    lineHeight: "16px",
    fontStyle: "normal",
  },
  container: {
    marginTop: 12,
    flex: "1 0 auto",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    justifyContent: "flex-start",
  },
  commentContainer: {
    marginTop: 12,
    flex: "1 0 auto",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    justifyContent: "flex-start",
    borderBottom: "1px solid #e8e8e8 !important",
  },
  noResultText: {
    alignItems: "center",
    fontSize: 20,
    fontWeight: 400,
    lineHeight: "16px",
    padding: 5,
    margin: 0,
    textTransform: "none",
  },
  header: {
    fontWeight: 300,
    lineHeight: "18px",
    marginTop: 16,
  },
  heading: {
    color: Color.GREY9,
    fontSize: 14,
    fontWeight: 200,
    lineHeight: "21px",
  },
  userId: {
    fontSize: 18,
    fontWeight: 500,
    marginRight: 4,
  },
  timeStamp: {
    fontSize: 16,
    fontWeight: 400,
  },
  message: {
    color: Color.BLACK4,
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 2,
    margin: "8px 4px",
    padding: "4px 0",
    whiteSpace: "pre-line",
  },
});

export const styles = () =>
  createStyles({
    container: {
      marginTop: 32,
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: 0,
    },
    errorMessageContainer: {
      marginBottom: 35,
    },
  });

export default styles;
