import React from "react";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useWorkloadManagerClient } from "@hooks";
import { WorkloadInternetAccessConfig } from "@data";

type SuccessResponse = void;

export interface UseUpdateInternetAccessConfigurationProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  etag: string;
  configuration: WorkloadInternetAccessConfig;
}

export interface UseUpdateInternetAccessConfigurationModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseUpdateInternetAccessConfigurationActions {
  updateInternetAccessConfiguration: () => void;
  reset: () => void;
}

type Props = UseUpdateInternetAccessConfigurationProps;
type Model = UseUpdateInternetAccessConfigurationModel;
type Actions = UseUpdateInternetAccessConfigurationActions;
type Result = [Model, Actions];

export const useUpdateInternetAccessConfiguration = (props: Props): Result => {

  const {
    name = "",
    version = 1,
    etag,
    configuration,
    defaultErrorMessage = "Failed to update the internet access configuration",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.updateInternetAccessConfiguration(name, version, etag, configuration),
    [WorkloadManagerClient, name, version, etag, configuration]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: updateInternetAccessConfiguration, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Delete Successful" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    updateInternetAccessConfiguration,
    reset
  }), [
    updateInternetAccessConfiguration,
    reset
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useUpdateInternetAccessConfiguration;
