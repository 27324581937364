import { createStyles, StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const breadcrumbs = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    actionsMenu: {
      padding: 0,
      marginLeft: 4,
    },
    actionsMenuIconButton: {
      padding: 6,
    },
    breadcrumbsContainer: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "center",
    },
    breadcrumbDivider: {
      marginLeft: 8,
      marginRight: 8,
    },
    breadcrumb: {
      height: "auto",
      minWidth: 0,
      margin: 0,
      padding: 0,
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:active": {
        backgroundColor: "transparent",
      },
      "&$active .label": {
        fontWeight: 500,
      },
    },
    breadcrumbLabel: {
      color: Color.TEXT,
      fontSize: 21,
      fontWeight: 300,
      lineHeight: "36px",
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline",
      },
      "&:active": {
        backgroundColor: "transparent",
        textDecoration: "underline",
      },
    },
    active: {
    },
    disabled: {
    },
  });

export const requiredIcon: StyleRules = {
  root: {
    color: Color.SIGNIFY_GREEN,
    width: 16,
    height: 16,
    marginLeft: 8,
  },
};

export const tableHeaderRow: StyleRules = {
  root: {
    height: 50,
  },
};

export const tableHeaderCell: StyleRules = {
  root: {
    color: Color.GREY3,
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: 400,
    fontStyle: "normal",
    textAlign: "left",
    width: "auto",
    height: "auto",
    minHeight: 50,
    padding: "0 0 0 8px",
  },
};

export const tableBodyRow: StyleRules = {
  root: {
    height: 50,
  },
};

export const tableBodyCell: StyleRules = {
  root: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto, sans-serif",
    fontSize: 13,
    lineHeight: "16px",
    fontWeight: 400,
    fontStyle: "normal",
    width: "auto",
    height: "auto",
    minHeight: 50,
    padding: 8,
  },
};

export const schemaPropertiesBrowserLabel = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      position: "relative",
      marginTop: 10,
      "&:first-child": {
        marginTop: 0,
      },
    },
    name: {
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "monospace",
      fontWeight: 500,
      fontSize: 14,
      alignSelf: "flex-start",
    },
    value: {
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "monospace",
      fontSize: 12,
      marginLeft: 16,
      fontWeight: 300,
    },
  });

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 25,
    },
    table: {
      borderCollapse: "collapse",
      overflow: "hidden",
      tableLayout: "fixed",
      marginTop: 8,
    },
    nameColumn: {

    },
    typeColumn: {

    },
    descriptionColumn: {

    },
    defaultValueColumn: {

    },
    requiredColumn: {

    },
    actionsColumn: {
      width: 100,
    },
    clickable: {
      cursor: "pointer",
    },
    addButtonRow: {
      padding: 6,
    },
    addButtonColumn: {
      textAlign: "center",
    },
    addButton: {
      color: Color.GREY7,
      height: "auto",
      minWidth: "auto",
      padding: "12px 8px",
    },
    footerRow: {
    },
    footerColumn: {
    },
    refreshButton: {
      color: Color.SIGNIFY_GREEN,
      width: 28,
      height: 28,
      minWidth: 28,
      minHeight: 28,
    },
    refreshButtonIcon: {
      width: 16,
      height: 16,
    },
    center: {
      textAlign: "center",
    },
  });

export default styles;
