import { combineReducers } from "redux";
import { JsonSchemaMetadataAttributes, SearchFilterAttributes } from "../../data";
import { Actions, filterAction, Identity } from "../../util";

export type SchemaItems = { [key: string]: JsonSchemaMetadataAttributes };

export interface SchemasModuleSchema {
  nameFilter: string;
  searchFilters: SearchFilterAttributes[];
  items: SchemaItems;
  nextPage: string;
  errorMessage: string;
  showEmptyView: boolean;
  showNotFound: boolean;
  showAccessDenied: boolean;
  showLoadingIndicator: boolean;
}

export const DEFAULT_STATE: SchemasModuleSchema = Object.freeze({
  nameFilter: "",
  searchFilters: [],
  items: {},
  nextPage: "",
  errorMessage: "",
  showNotFound: false,
  showEmptyView: true,
  showAccessDenied: false,
  showLoadingIndicator: false,
});

export enum SchemasActionType {
  SET_NAME_FILTER = "schemas/set_name_filter",
  SET_SEARCH_FILTERS = "schemas/set_search_filters",
  SET_ITEMS = "schemas/set_items",
  SET_NEXT_PAGE = "schemas/set_next_page",
  SET_ERROR_MESSAGE = "schemas/set_error_message",
  TOGGLE_SHOW_LOADING_INDICATOR = "schemas/toggle_show_loading_indicator",
  TOGGLE_SHOW_NOT_FOUND = "schemas/toggle_show_not_found",
  TOGGLE_SHOW_ACCESS_DENIED = "schemas/toggle_show_access_denied",
  TOGGLE_SHOW_EMPTY_VIEW = "schemas/toggle_show_empty_view",
  LOAD_ITEMS = "schemas/load_items",
  LOAD_ITEMS_SUCCESS = "schemas/load_items_success",
  LOAD_ITEMS_FAILED = "schemas/load_items_failed",
}

const nameFilter = filterAction<string>(Actions(
  SchemasActionType.SET_NAME_FILTER))(Identity,
  DEFAULT_STATE.nameFilter);

const searchFilters = filterAction<SearchFilterAttributes[]>(Actions(
  SchemasActionType.SET_SEARCH_FILTERS))(Identity,
  DEFAULT_STATE.searchFilters);

const items = filterAction<SchemaItems>(Actions(
  SchemasActionType.SET_ITEMS))(Identity,
  DEFAULT_STATE.items);

const nextPage = filterAction<string>(Actions(
  SchemasActionType.SET_NEXT_PAGE))(Identity,
  DEFAULT_STATE.nextPage);

const errorMessage = filterAction<string>(Actions(
  SchemasActionType.SET_ERROR_MESSAGE))(Identity,
  DEFAULT_STATE.errorMessage);

const showEmptyView = filterAction<boolean>(Actions(
  SchemasActionType.TOGGLE_SHOW_EMPTY_VIEW))(Identity,
  DEFAULT_STATE.showEmptyView);

const showNotFound = filterAction<boolean>(Actions(
  SchemasActionType.TOGGLE_SHOW_NOT_FOUND))(Identity,
  DEFAULT_STATE.showAccessDenied);

const showAccessDenied = filterAction<boolean>(Actions(
  SchemasActionType.TOGGLE_SHOW_ACCESS_DENIED))(Identity,
  DEFAULT_STATE.showAccessDenied);

const showLoadingIndicator = filterAction<boolean>(Actions(
  SchemasActionType.TOGGLE_SHOW_LOADING_INDICATOR))(Identity,
  DEFAULT_STATE.showLoadingIndicator);

export const SchemasReducer = combineReducers<SchemasModuleSchema>({
  nameFilter,
  searchFilters,
  items,
  nextPage,
  errorMessage,
  showEmptyView,
  showNotFound,
  showAccessDenied,
  showLoadingIndicator,
});
