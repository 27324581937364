import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { DataSetRequestWizardSelectors } from "../selectors";
import ReviewView, { Actions, Model } from "../components/ReviewView";

interface ContainerModel extends Model {
  hidden?: boolean;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const ReviewViewContainer = (props: Props) => {

  const { hidden, ...otherProps } = props;

  if (hidden) {
    return null;
  }

  return <ReviewView {...otherProps} />;
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  hidden: !DataSetRequestWizardSelectors.isReviewViewSelected(state),
  dataSetAlias: DataSetRequestWizardSelectors.getDataSetAlias(state),
  dataSetType: DataSetRequestWizardSelectors.getDataSetType(state),
  description: DataSetRequestWizardSelectors.getDescription(state),
  dataSetGroup: DataSetRequestWizardSelectors.getDataSetGroup(state),
  tags: DataSetRequestWizardSelectors.getTags(state).join(", "),
  dataOwners: DataSetRequestWizardSelectors.getDataOwners(state).join(", "),
  architects: DataSetRequestWizardSelectors.getArchitects(state).join(", "),
  engineeringContacts: DataSetRequestWizardSelectors.getEngineeringContacts(state).join(", "),
  businessOwners: DataSetRequestWizardSelectors.getBusinessOwners(state).join(", "),
  ingestionMechanism: DataSetRequestWizardSelectors.getIngestionMechanism(state),
  schema: DataSetRequestWizardSelectors.getSchema(state),
  sampleAnonymizedData: DataSetRequestWizardSelectors.getSampleAnonymizedData(state),
  dataClassification: DataSetRequestWizardSelectors.getDataClassification(state),
  dataVolume: DataSetRequestWizardSelectors.getDataRate(state).dataVolume,
  dataVelocity: DataSetRequestWizardSelectors.getDataRate(state).dataVelocity,
  archivingInDays: DataSetRequestWizardSelectors.getArchivingInDaysLifecyclePolicy(state),
  expirationInDays: DataSetRequestWizardSelectors.getExpirationInDaysLifecyclePolicy(state),
  confirmNonProductionData: DataSetRequestWizardSelectors.getConfirmNonProductionData(state) + "",
  showIngestionMechanism: DataSetRequestWizardSelectors.showIngestionMechanism(state),
  isProductionAccount: DataSetRequestWizardSelectors.isProductionAccount(state),
  containsPiiData: DataSetRequestWizardSelectors.getContainsPiiData(state) + "",
  piiComment: DataSetRequestWizardSelectors.getPiiComment(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps
)(ReviewViewContainer);
