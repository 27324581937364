import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { isEmptyString, noop } from "@util";
import { Dashboard } from "@data";
import { refresh, setSearchQuery, setSelectedTab } from "../actions";
import {
  getPublishedDashboards,
  getSearchQuery,
  getSelectedTab,
  getUnpublishedDashboards,
  isLoadingIndicatorVisible,
} from "../selectors";
import { DashboardsTabType } from "../components/DashboardsTabs";
import Dashboards, { Actions, Model } from "../components/Dashboards";
import DashboardsList from "./DashboardsList";
import { open as openPublishDashboardDialog } from "@modules/publishDashboard/actions";
import { open as openShareDashboardDialog } from "@modules/shareDashboard/actions";

export interface ContainerModel extends Model {
  selectedTab?: DashboardsTabType;
  currentAccountId?: string;
  publishedDashboards?: Dashboard[];
  unpublishedDashboards?: Dashboard[];
}

export interface ContainerActions extends Actions {
  updateSelectedTab?: (selectedTab: DashboardsTabType) => void;
  publishDashboard?: (dashboard: Dashboard) => void;
  shareDashboard?: (dashboard: Dashboard) => void;
  showDashboardDetails?: (dashboard: Dashboard) => void;
}

type Props = ContainerModel & ContainerActions;

const DashboardsContainer = (props: Props) => {

  const {
    selectedTab = DashboardsTabType.PUBLISHED,
    publishedDashboards = [],
    unpublishedDashboards = [],
    updateSelectedTab = noop,
    showDashboardDetails = noop,
    publishDashboard = noop,
    shareDashboard = noop,
    ...otherProps
  } = props;

  const { searchQuery } = otherProps;

  const dashboards = React.useMemo(() => {
    switch (selectedTab) {
      case DashboardsTabType.UNPUBLISHED:
        return unpublishedDashboards;
      case DashboardsTabType.PUBLISHED:
      default:
        return publishedDashboards;
    }
  }, [selectedTab, publishedDashboards, unpublishedDashboards]);

  const numItems = dashboards.length;

  const noResultsLabel = React.useMemo(() => {

    if (!isEmptyString(searchQuery)) {
      return "No results found";
    }

    switch (selectedTab) {
      case DashboardsTabType.PUBLISHED:
        return "You don't have any published dashboards";
      case DashboardsTabType.UNPUBLISHED:
        return "You don't have any unpublished dashboards";
      default:
        return "You don't have any dashboards";
    }

  }, [searchQuery, selectedTab]);

  // Save any changes to the selected tab so that we can restore the last selected tab in
  // the event that the module is mounted without a query param specifying which tab to use
  React.useEffect(() => {
    updateSelectedTab(selectedTab);
  }, [updateSelectedTab, selectedTab]);

  return (
    <Dashboards
      {...otherProps}
      numItems={numItems}
    >
      <DashboardsList
        dashboards={dashboards}
        noResultsLabel={noResultsLabel}
        publishDashboard={publishDashboard}
        shareDashboard={shareDashboard}
        onClickItemActions={showDashboardDetails}
      />
    </Dashboards>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  selectedTab: getSelectedTab(state),
  publishedDashboards: getPublishedDashboards(state),
  unpublishedDashboards: getUnpublishedDashboards(state),
  searchQuery: getSearchQuery(state),
  loading: isLoadingIndicatorVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  updateSelectedTab: selectedTab => dispatch(setSelectedTab(selectedTab)),
  refresh: () => dispatch(refresh()),
  updateSearchQuery: (searchQuery: string) => dispatch(setSearchQuery(searchQuery)),
  publishDashboard: (dashboard: Dashboard) => dispatch(
    openPublishDashboardDialog(dashboard.getDashboardId(), dashboard.publishable)),
  shareDashboard: (dashboard: Dashboard) => dispatch(openShareDashboardDialog(dashboard.getDashboardId())),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardsContainer);
