import { createStyles } from "@material-ui/core/styles";
import ModuleIcon from "@material-ui/icons/VerifiedUser";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const serviceInfo = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    serviceId: {
      marginTop: 0,
    },
    protocolContainer: {
      marginTop: 40,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      minWidth: 250,
      width: "fit-content",
    },
    textField: {
      color: Color.TEXT,
      fontSize: 16,
      height: "auto",
    },
    formHelperText: {
      color: Color.TEXT,
      fontSize: 13,
    },
    formHelperTextError: {
      marginBottom: 15,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
  });

export default serviceInfo;
