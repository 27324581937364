import { Record } from "immutable";
import { getStringValue } from "@util";

export interface SummaryViewDataAttributes {
  className: string;
  name: string;
  value: string;
  date: boolean;
  hidden: boolean;
  formatDatePattern: string;
  tooltipIconText: string;
}

export class SummaryViewData extends Record({
  className: "",
  name: "",
  value: "",
  date: false,
  hidden: false,
  formatDatePattern: "LLL",
  tooltipIconText: "",
}) implements SummaryViewDataAttributes {

  public static EMPTY: SummaryViewData = new SummaryViewData();

  public readonly name: string;
  public readonly className: string;
  public readonly value: string;
  public readonly date: boolean;
  public readonly hidden: boolean;
  public readonly formatDatePattern: string;
  public readonly tooltipIconText: string;

  public getName(): string {
    return getStringValue(this.name);
  }

  public getClassName(): string {
    return getStringValue(this.className);
  }

  public getValue(): string {
    return !this.hidden ? getStringValue(this.value) : "";
  }

  public isDate(): boolean {
    return this.date;
  }

  public getFormatDatePattern(): string {
    return getStringValue(this.formatDatePattern);
  }

  public getTooltipIconText(): string {
    return getStringValue(this.tooltipIconText);
  }
}

export default SummaryViewData;
