import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import {
  isDataWorkloadsModuleEnabled,
  isQualityReportsEnabled,
  isCostReportsEnabled,
  isReleaseNotesEnabled,
} from "@main/selectors";
import WhatsNewWelcomePage, {
  WhatsNewWelcomePageActions as Actions,
  WhatsNewWelcomePageModel as Model,
} from "./WhatsNewWelcomePage";
import WelcomePage from "../components/WelcomePage";

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const WelcomePageContainer = (props: Props) => {

  const { showWhatsNew } = props;

  if (showWhatsNew) {
    return <WhatsNewWelcomePage {...props} />;
  } else {
    return <WelcomePage {...props} />;
  }
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  showDataWorkloads: isDataWorkloadsModuleEnabled(state),
  showWhatsNew: isReleaseNotesEnabled(state),
  showCostReports: isCostReportsEnabled(state),
  showSecurityServicesQualityReportsButton: isQualityReportsEnabled(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(WelcomePageContainer);
