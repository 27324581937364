import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const primaryColor = Color.MODULES;

export const styles = () =>
    createStyles({
        container: {
            flex: "1 0 auto",
            display: "flex",
            flexFlow: "column nowrap",
            "& label.name": {
                minWidth: 120,
            },
        },
        content: {
            marginTop: 20,
            color: primaryColor,
            flex: "1 0 auto",
            display: "flex",
            flexFlow: "column nowrap",
        },
        header: {
            minHeight: 50,
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            alignContent: "center",
            position: "relative",
            color: primaryColor,
        },
        value: {
            backgroundColor: "#eaeaea",
            color: Color.TEXT,
            display: "inline-block",
            fontFamily: "monospace",
            fontSize: 12,
            fontWeight: 300,
            padding: 10,
        },
        title: {
            color: Color.TEXT,
            fontWeight: 200,
            fontSize: 22,
            margin: "0 0 0 8px",
        },
        viewModeButton: {
            marginLeft: 15,
            "&:first-child": {
                marginLeft: 0,
            },
        },
        controls: {
            flex: "0 0 auto",
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            justifyContent: "flex-start",
            marginTop: 25,
        },
        htmlPreview: {
            flex: "1 0 auto",
            marginTop: 10,
            minHeight: "50vh",
        },
        htmlEditor: {
            flex: "1 0 auto",
            marginTop: 20,
            minHeight: "50vh",
        },
  });

export default styles;
