import { getPathToService } from "@modules/securityServiceRegionalDetails/helpers";
import { getPathToGroup } from "@modules/securityGroupDetails/helpers";
import { getPathToUser } from "@modules/userDetails/helpers";

export const getManageGroupUsersBaseUrl = (groupName: string) =>
  `${getPathToGroup(groupName)}/users`;

export const getAddGroupUserPath = (groupName: string) =>
  `${getManageGroupUsersBaseUrl(groupName)}/add`;

export const getRemoveGroupUserPath = (groupName: string) =>
  `${getManageGroupUsersBaseUrl(groupName)}/remove`;

export const getManageUserGroupsBaseUrl = (userId: string) =>
  `${getPathToUser(userId)}/groups`;

export const getAddUserToGroupPath = (userId: string) =>
  `${getManageUserGroupsBaseUrl(userId)}/add`;

export const getRemoveUserFromGroupPath = (userId: string) =>
  `${getManageUserGroupsBaseUrl(userId)}/remove`;

export const getManageGroupServicesBaseUrl = (groupName: string) =>
  `${getPathToGroup(groupName)}/services`;

export const getAddGroupServicePath = (groupName: string) =>
  `${getManageGroupServicesBaseUrl(groupName)}/add`;

export const getRemoveGroupServicePath = (groupName: string) =>
  `${getManageGroupServicesBaseUrl(groupName)}/remove`;

export const getManageServiceGroupsBaseUrl = (serviceId: string) =>
  `${getPathToService(serviceId)}/groups`;

export const getAddServiceToGroupPath = (serviceId: string) =>
  `${getManageServiceGroupsBaseUrl(serviceId)}/add`;

export const getRemoveServiceFromGroupPath = (serviceId: string) =>
  `${getManageServiceGroupsBaseUrl(serviceId)}/remove`;
