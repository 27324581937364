import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import {
  ActionMenuItem,
  DataAccessRequestAction,
  DEFAULT_DATA_ACCESS_REQUEST_ACTION_MENU_ITEMS,
} from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DetailsView, { DetailsViewActions, DetailsViewModel } from "@components/details-view";
import styles, { PrimaryIcon } from "./styles";

export interface Model extends DetailsViewModel {
}

export interface Actions extends DetailsViewActions {
  deleteDataAccessRequest?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataAccessRequestDetails = withStyles(styles)((props: Props) => {

  const {
    classes,
    icon = PrimaryIcon,
    title = "Data Access Request Details",
    actions = DEFAULT_DATA_ACCESS_REQUEST_ACTION_MENU_ITEMS,
    actionsLabel = "Actions",
    deleteDataAccessRequest = noop,
    onClickAction = noop,
    children,
    ...otherProps
  } = props;

  const actionClicked = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case DataAccessRequestAction.DELETE:
        return deleteDataAccessRequest();
      default:
        return onClickAction(action);
    }
  }, [deleteDataAccessRequest, onClickAction]);

  return (
    <DetailsView
      {...otherProps}
      className={classnames("dataAccessRequestDetails", classes.container)}
      icon={icon}
      title={title}
      actions={actions}
      actionsLabel={actionsLabel}
      onClickAction={actionClicked}
    >
      {children}
    </DetailsView>
  );
});

export default DataAccessRequestDetails;
