import React from "react";
import classnames from "classnames";
import ActionsMenu, { ActionMenuItem, ActionsMenuProps } from "../actions-menu";
import { equalsIgnoreCase, noop } from "@util";

export enum UserAction {
  ADD_USER_TO_GROUP = "add_user_to_group",
  REMOVE_USER_FROM_GROUP = "remove_user_from_group",
  POLICY_ACTIONS = "policy_actions",
  GROUP_ACTIONS = "group_actions",
  PASSWORD_ACTIONS = "password_actions",
  ACCOUNT_ACTIONS = "account_actions",
  ATTACH_POLICY = "attach_policy",
  DETACH_POLICY = "detach_policy",
  LOGOUT_USER = "logout_user",
  EXPIRE_PASSWORD = "expire_password",
  CHANGE_PASSWORD = "change_password",
  RECOVER_PASSWORD = "recover_password",
  LOCK_USER = "lock_user",
  UNLOCK_USER = "unlock_user",
  CLOSE_USER = "close_user",
  DELETE_USER = "delete_user",
}

export const ADD_USER_TO_GROUP_ACTION: ActionMenuItem = Object.freeze({
  id: UserAction.ADD_USER_TO_GROUP,
  name: "Add",
  disabled: false,
});

export const REMOVE_USER_FROM_GROUP_ACTION: ActionMenuItem = Object.freeze({
  id: UserAction.REMOVE_USER_FROM_GROUP,
  name: "Remove",
  disabled: false,
});

export const ATTACH_POLICY_TO_USER_ACTION: ActionMenuItem = Object.freeze({
  id: UserAction.ATTACH_POLICY,
  name: "Attach Policy",
  disabled: false,
});

export const DETACH_POLICY_FROM_USER_ACTION: ActionMenuItem = Object.freeze({
  id: UserAction.DETACH_POLICY,
  name: "Detach Policy",
  disabled: false,
});

export const GROUP_ACTIONS: ActionMenuItem = Object.freeze({
  id: UserAction.GROUP_ACTIONS,
  name: "Groups",
  disabled: false,
  actions: [ADD_USER_TO_GROUP_ACTION, REMOVE_USER_FROM_GROUP_ACTION],
});

export const POLICY_ACTIONS: ActionMenuItem = Object.freeze({
  id: UserAction.POLICY_ACTIONS,
  name: "Policies",
  disabled: false,
  actions: [ATTACH_POLICY_TO_USER_ACTION, DETACH_POLICY_FROM_USER_ACTION],
});

export const LOGOUT_USER: ActionMenuItem = Object.freeze({
  id: UserAction.LOGOUT_USER,
  name: "Expire Session",
  disabled: false,
});

export const EXPIRE_PASSWORD: ActionMenuItem = Object.freeze({
  id: UserAction.EXPIRE_PASSWORD,
  name: "Expire",
  disabled: false,
});

export const CHANGE_PASSWORD: ActionMenuItem = Object.freeze({
  id: UserAction.CHANGE_PASSWORD,
  name: "Change",
  disabled: false,
});

export const RECOVER_PASSWORD: ActionMenuItem = Object.freeze({
  id: UserAction.RECOVER_PASSWORD,
  name: "Recover",
  disabled: false,
});

export const PASSWORD_ACTIONS: ActionMenuItem = Object.freeze({
  id: UserAction.PASSWORD_ACTIONS,
  name: "User Password",
  disabled: false,
  actions: [EXPIRE_PASSWORD, CHANGE_PASSWORD, RECOVER_PASSWORD],
});

export const LOCK_USER: ActionMenuItem = Object.freeze({
  id: UserAction.LOCK_USER,
  name: "Lock",
  disabled: false,
});

export const UNLOCK_USER: ActionMenuItem = Object.freeze({
  id: UserAction.UNLOCK_USER,
  name: "Unlock",
  disabled: false,
});

export const CLOSE_USER: ActionMenuItem = Object.freeze({
  id: UserAction.CLOSE_USER,
  name: "Close",
  disabled: false,
});

export const DELETE_USER: ActionMenuItem = Object.freeze({
  id: UserAction.DELETE_USER,
  name: "Delete",
  disabled: false,
  hidden: equalsIgnoreCase("true", process.env.REACT_APP_DELETE_USER_ACTION_DISABLED),
});

export const ACCOUNT_ACTIONS: ActionMenuItem = Object.freeze({
  id: UserAction.ACCOUNT_ACTIONS,
  name: "Account",
  disabled: false,
  actions: [
    LOGOUT_USER,
    LOCK_USER,
    UNLOCK_USER,
    CLOSE_USER,
    DELETE_USER,
  ],
});

export const DEFAULT_USER_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  GROUP_ACTIONS,
  POLICY_ACTIONS,
  PASSWORD_ACTIONS,
  ACCOUNT_ACTIONS,
]) as ActionMenuItem[];

export interface UserActionsMenuProps extends ActionsMenuProps {
  addUserToGroup?: () => void;
  removeUserFromGroup?: () => void;
  attachPolicy?: () => void;
  detachPolicy?: () => void;
  logoutUser?: () => void;
  expirePassword?: () => void;
  changePassword?: () => void;
  recoverPassword?: () => void;
  lockUser?: () => void;
  unlockUser?: () => void;
  closeUser?: () => void;
  deleteUser?: () => void;
}

export const withUserActions = (Component: React.ComponentType<UserActionsMenuProps>) =>
  (props: UserActionsMenuProps) => {

    const {
      actions = DEFAULT_USER_ACTION_MENU_ITEMS,
      addUserToGroup = noop,
      removeUserFromGroup = noop,
      attachPolicy = noop,
      detachPolicy = noop,
      logoutUser = noop,
      expirePassword = noop,
      changePassword = noop,
      recoverPassword = noop,
      lockUser = noop,
      unlockUser = noop,
      closeUser = noop,
      deleteUser = noop,
      onClickAction = noop,
    } = props;

    return (
      <Component
        {...props}
        actions={actions}
        onClickAction={(action: ActionMenuItem) => {
          if (action.id === UserAction.ADD_USER_TO_GROUP) {
            addUserToGroup();
          } else if (action.id === UserAction.REMOVE_USER_FROM_GROUP) {
            removeUserFromGroup();
          } else if (action.id === UserAction.ATTACH_POLICY) {
            attachPolicy();
          } else if (action.id === UserAction.DETACH_POLICY) {
            detachPolicy();
          } else if (action.id === UserAction.LOGOUT_USER) {
            logoutUser();
          } else if (action.id === UserAction.EXPIRE_PASSWORD) {
            expirePassword();
          } else if (action.id === UserAction.CHANGE_PASSWORD) {
            changePassword();
          } else if (action.id === UserAction.RECOVER_PASSWORD) {
            recoverPassword();
          } else if (action.id === UserAction.LOCK_USER) {
            lockUser();
          } else if (action.id === UserAction.UNLOCK_USER) {
            unlockUser();
          }  else if (action.id === UserAction.CLOSE_USER) {
            closeUser();
          } else if (action.id === UserAction.DELETE_USER) {
            deleteUser();
          } else {
            onClickAction(action);
          }
        }}
      />
    );
  };

export const UserActionsMenu = withUserActions((props: UserActionsMenuProps) => {

  const { className, buttonLabel = "User Actions" } = props;

  return (
    <ActionsMenu
      {...props}
      className={classnames("userActionsMenu", className)}
      buttonLabel={buttonLabel}
    />
  );
});

export default UserActionsMenu;
