import React from "react";
import { User } from "@data";
import classnames from "classnames";
import {
  ACCOUNT_ACTIONS,
  GROUP_ACTIONS,
  PASSWORD_ACTIONS,
  POLICY_ACTIONS,
  UserAction,
} from "@components/user-actions-menu";
import { ActionMenuItem } from "@components/actions-menu";
import { ContinueButton } from "@components/continue-button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import UsersList, { UsersListActions, UsersListModel } from "@components/users-list";
import styles from "./styles";

export const DEFAULT_ACTIONS: ActionMenuItem[] = Object.freeze([
  GROUP_ACTIONS,
  POLICY_ACTIONS,
  ACCOUNT_ACTIONS,
  PASSWORD_ACTIONS,
]) as ActionMenuItem[];

const DEFAULT_MAP_ITEM_TO_ACTION = (user: User, action: ActionMenuItem) => {
  switch (action.id) {
    case "policy_actions":
      if (action.actions) {
        const filteredPolicyActions = action.actions.map((nestedAction) => {
            switch (nestedAction.id) {
              case UserAction.ATTACH_POLICY:
                return {
                  ...nestedAction,
                  disabled: user.isUserUnconfirmed(),
                };
              case UserAction.DETACH_POLICY:
                return {
                  ...nestedAction,
                  disabled: user.isUserUnconfirmed(),
                };
              default:
                return nestedAction;
            }
          });
        return {
          ...action,
          actions: filteredPolicyActions,
        };
      } else {
        return action;
      }
    case "account_actions":
      if (action.actions) {
        const filteredGroupActions = action.actions.map((nestedAction) => {
            switch (nestedAction.id) {
              case UserAction.LOCK_USER:
                return {
                  ...nestedAction,
                  disabled: user.isUserLocked(),
                };
              case UserAction.UNLOCK_USER:
                return {
                  ...nestedAction,
                  disabled: !user.isUserLocked(),
                };
              default:
                return nestedAction;
            }
          });
        return {
          ...action,
          actions: filteredGroupActions,
        };
      } else {
        return action;
      }
    default:
      return action;
  }
};

export interface Model extends UsersListModel {
  showGenerateReportButton?: boolean;
  reportButtonLabel?: string;
  reportButtonActive?: boolean;
  children?: React.ReactNode;
}

export interface Actions extends UsersListActions {
  createUser?: () => void;
  inviteUser?: () => void;
  generateReport?: () => void;
  showUserDetails?: (user: User) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const Users = withStyles(styles)((props: Props) => {

  const {
    classes,
    createUser,
    inviteUser,
    showUserDetails,
    reportButtonLabel,
    reportButtonActive,
    showGenerateReportButton,
    actions = DEFAULT_ACTIONS,
    mapItemToAction = DEFAULT_MAP_ITEM_TO_ACTION,
    children,
    generateReport,
    ...otherProps
  } = props;

  return (
    <UsersList
      {...otherProps}
      actions={actions}
      showSummary={true}
      showSearch={true}
      onClickItem={showUserDetails}
      mapItemToAction={mapItemToAction}
    >
      {showGenerateReportButton && (
        <ContinueButton
          className={classnames("reportButton", classes.button, classes.reportButton)}
          primary={false}
          disabled={!reportButtonActive}
          onClick={generateReport}
        >
          {reportButtonLabel}
        </ContinueButton>
      )}
      <ContinueButton
        className={classnames("inviteUserButton", classes.button, classes.inviteUserButton)}
        primary={false}
        onClick={inviteUser}
      >
        Invite User
      </ContinueButton>
      <ContinueButton
        className={classnames("createUserButton", classes.button, classes.createUserButton)}
        primary={true}
        onClick={createUser}
      >
        Create User
      </ContinueButton>
      {children}
    </UsersList>
  );
});

export default Users;
