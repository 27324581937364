import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
    },
    datePickerInput: {
      padding: "12px 14px",
    },
  });

export default styles;
