import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    icon: {
      width: 16,
      height: 16,
      marginRight: 8,
    },
    label: {
      color: Color.TEXT,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
      wordBreak: "break-word",
      marginRight: 8,
      "&.name": {
        color: Color.LIGHT_BLUE,
      },
    },
    placeholder: {
      fontStyle: "italic",
      color: Color.GREY3,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "14px",
    },
    approvalRequestType: {
    },
    requestState: {
    },
    approvers: {
    },
    approversList: {
      listStyleType: "none",
      padding: 0,
      margin: "0 8px 0 0",
    },
    approver: {
      marginTop: 8,
      overflow: "hidden",
      "&:first-child": {
        marginTop: 0,
      },
    },
    approverLabel: {
      color: Color.TEXT,
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "16px",
      wordBreak: "break-word",
      textDecoration: "none",
      "&:hover, &:active": {
        textDecoration: "underline",
      },
    },
    approvalComments: {
    },
    approvedBy: {
    },
    approvedAt: {
    },
  });

export default styles;
