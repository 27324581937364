import React from "react";
import classnames from "classnames";
import { clickHandler, noop } from "@util";
import Button, { ButtonProps } from "@components/button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface ContinueButtonProps extends ButtonProps {
  label?: string;
  uppercase?: boolean;
  primary?: boolean;
  onClick?: () => void;
}

type Props = WithStyles<typeof styles> & ContinueButtonProps;

export const ContinueButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className = "continueButton",
    label = "Continue",
    uppercase,
    primary = true,
    color = primary ? "primary" : "secondary",
    onClick = noop,
    fullWidth = false,
    ...otherProps
  } = props;

  const { children = label, disabled } = otherProps;

  return (
    <Button
      {...otherProps}
      className={classnames("continueButton", className, classes.button, {
        "disabled": disabled,
      })}
      classes={{
        label: classnames("label", classes.label, {
          [classes.uppercase]: uppercase,
        }),
      }}
      color={color}
      variant="contained"
      fullWidth={fullWidth}
      onClick={clickHandler(onClick)}
    >
      {children}
    </Button>
  );
});

export default ContinueButton;
