import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { MailToLinkLabel } from "../../../../components";
import { styles } from "./styles";

export interface Model {
  title?: string[];
  contactInfo?: string[];
  children?: React.ReactNode;
}

export const Footer = withStyles(styles)((props: WithStyles<typeof styles> & Model) => {

  const { classes, contactInfo = [], title = "Contact Us" } = props;

  if (contactInfo.length === 0) {
    return null;
  }

  return (
    <div className={classnames("footer", classes.container)}>
      <label className={classnames("title", classes.title)}>{title}</label>
      <div className={classnames("contactInfo", classes.contactInfo)}>
        {contactInfo.map((emailAddress: string) => (
          <MailToLinkLabel
            className={classnames("emailAddress", classes.emailAddress)}
            key={emailAddress}
            email={emailAddress}
            label={emailAddress}
          />
        ))}
      </div>
    </div>
  );
});

export default Footer;
