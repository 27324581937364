import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    header: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      backgroundColor: Color.LIGHT_GREY,
      border: `1px solid ${Color.GREY1}`,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderBottom: "none",
      minHeight: 50,
    },
    title: {
      fontSize: 18,
      lineHeight: "21px",
      fontWeight: 400,
      margin: "10px 20px",
    },
    list: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      borderTop: `1px solid ${Color.GREY1}`,
    },
    listItem: {
    },
    emptyView: {
      color: Color.GREY9,
      fontSize: 14,
      fontWeight: 400,
      fontStyle: "italic",
      textAlign: "center",
    },
    errorCodeView: {
      margin: 0,
      padding: 25,
      textAlign: "center",
      // For this particular component, we don't want the buttons typically shown with the
      // not found and access denied errors...we just want the styling
      "& .buttonContainer": {
        display: "none",
      },
    },
    accessDeniedError: {
    },
    notFoundError: {
    },
    errorView: {
      margin: "15px 10px 10px",
      "& .title": {
        fontSize: 16,
      },
      "& .message": {
        fontSize: 13,
      },
    },
    loadingLabel: {
      display: "block",
      textAlign: "center",
      fontStyle: "italic",
      color: Color.TEXT,
      fontSize: 12,
      fontWeight: 300,
      paddingTop: 15,
      paddingBottom: 15,
    },
    loadMoreButton: {
      height: 50,
      borderBottom: `1px solid ${Color.GREY1}`,
    },
    loadMoreButtonLabel: {
      fontSize: 16,
      fontWeight: 300,
    },
  });

export default styles;
