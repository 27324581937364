import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { isEmptyString } from "@util";
import { styles } from "./styles";

export interface Model {
  userId?: string;
}

type Props = WithStyles<typeof styles> & Model;

export const LogoutUser = withStyles(styles)((props: Props) => {

  const { classes, userId = "" } = props;

  const content = React.useMemo(() => {

    if (isEmptyString(userId)) {
      return (
        <label className={classnames("emptyViewLabel", classes.emptyViewLabel)}>
          No User Selected
        </label>
      );
    }

    return (
      <React.Fragment>
        <label className={classnames("title", classes.title)}>
          Are you sure you want to logout this user?
        </label>
        <label className={classnames("subtitle", classes.subtitle)}>
          Once invoked, this user will need to re-authenticate and login again.
        </label>
        <label className={classnames("userId", classes.userId)}>
          {userId}
        </label>
      </React.Fragment>
    );
  }, [userId]);

  return (
    <div className={classnames("logoutUser", classes.container)}>
      {content}
    </div>
  );
});

export default LogoutUser;
