import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { AuthenticatorIcon, listItem as styles } from "./styles";
import { AuthenticatorListColumn } from "./AuthenticatorListColumn";
import { Authenticator } from "@data";
import { isEmptyString } from "@util";

type StyleProps = WithStyles<typeof styles>;

export const AuthenticatorIdColumn = withStyles(styles)((props: StyleProps & {
  authenticatorId?: string,
}) => {

  const { classes, authenticatorId = ""} = props;

  if (isEmptyString(authenticatorId)) {
    return null;
  }

  return (
    <div className={classnames("authenticatorIdColumn", classes.container)}>
      <AuthenticatorIcon className={classnames("moduleIcon", classes.icon)} />
      <label className={classnames("authenticatorId", classes.label)}>{authenticatorId}</label>
    </div>
  );
});

export const AuthenticatorListItem = withStyles(styles)((props: WithStyles<typeof styles> & {
  authenticator?: Authenticator,
  column?: AuthenticatorListColumn,
}) => {

  const { column, authenticator = Authenticator.EMPTY, classes } = props;

  if ( !column || Authenticator.EMPTY.equals(authenticator)) {
    return null;
  }

  switch (column) {
  case AuthenticatorListColumn.AUTHENTICATOR_ID:
    return (
      <AuthenticatorIdColumn authenticatorId={authenticator.getId()}/>
    );
  case AuthenticatorListColumn.STATUS:
    return (
      <label className={classnames("status", classes.label)}>{authenticator.getStatus()}</label>
    );
  case AuthenticatorListColumn.TYPE:
    return (
      <label className={classnames("type", classes.label)}>{authenticator.getType()}</label>
    );
  case AuthenticatorListColumn.LABEL:
    return (
      <label className={classnames("label", classes.label)}>{authenticator.getLabel()}</label>
    );
  default:
    return null;
  }

});

export default AuthenticatorListItem;
