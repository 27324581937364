import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import TextField from "@components/text-field";
import { useEditEntryPoint } from "@hooks";
import { formEventHandler, hasSpecialChars, isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";

export interface WorkloadEntryPointDialogModel extends PortalModuleDialogModel<string> {
  name?: string;
  version?: number;
  etag?: string;
  entryPoint?: string;
  dialogClassName?: string;
}

export interface WorkloadEntryPointDialogActions extends PortalModuleDialogActions<string> {
  setEntryPoint?: (entryPoint: string) => void;
}

type Props = WithStyles<typeof styles> & WorkloadEntryPointDialogModel & WorkloadEntryPointDialogActions;

export const WorkloadEntryPointDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    dialogClassName,
    name = "",
    version = 1,
    etag = "",
    entryPoint = "",
    setEntryPoint = noop,
    cancel: cancelDialog = noop,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const [{ showLoadingIndicator, ...otherModel }, { editEntryPoint, reset }] =
    useEditEntryPoint({ name, version, etag, entryPoint: entryPoint });

  const confirm = React.useCallback(() => {
    editEntryPoint();
  }, [editEntryPoint]);

  const cancel = React.useCallback(() => {
    reset();
    cancelDialog();
  }, [reset, cancelDialog]);

  const onSuccessMessageShown = React.useCallback(() => {
    reset();
    onSuccess();
  }, [reset, onSuccess]);

  const disabled = React.useMemo(() =>
    isEmptyString(entryPoint) || hasSpecialChars(entryPoint), [entryPoint]);

  if (isEmptyString(name)) {
    return null;
  }

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      className={classnames("workloadEntryPointDialog", dialogClassName)}
      title="Edit Workload Entry Point"
      loading={showLoadingIndicator}
      continueButtonLabel="Save"
      continueButtonDisabled={disabled}
      onSuccessMessageShown={onSuccessMessageShown}
      confirm={confirm}
      cancel={cancel}
    >
      <div className={classnames("workloadEntryPointContainer", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Enter the new entry point for this workload:
          </label>
          <div className={classnames("workloadEntryPoint", classes.descriptionInput)}>
            <TextField
              className={classnames("entryPoint", classes.textField)}
              variant="outlined"
              autoComplete="off"
              margin="none"
              fullWidth={true}
              autoFocus={true}
              required={true}
              multiline={true}
              label="Entry Point"
              name="entryPoint"
              value={entryPoint}
              placeholder="Entry Point"
              onChange={formEventHandler((updatedEntryPoint: string) => setEntryPoint(updatedEntryPoint))}
              InputLabelProps={inputLabelProps}
              disableSpecialChars={true}
            />
          </div>
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadEntryPointDialog;
