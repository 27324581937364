import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { useAddActionLabels } from "@hooks";
import { ActionsListItemData, WorkloadCompletionMetadata } from "@components/workload-completion-metadata";

export interface WorkloadAddActionLabelDialogModel extends PortalModuleDialogModel<string> {
  name?: string;
  version?: number;
  etag?: string;
}

export interface WorkloadAddActionLabelDialogActions extends PortalModuleDialogActions<string> {
  setDescription?: (description: string) => void;
}

type Props = WithStyles<typeof styles> & WorkloadAddActionLabelDialogModel & WorkloadAddActionLabelDialogActions;

export const WorkloadAddActionLabelDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    name = "",
    version = 1,
    etag = "",
    setDescription = noop,
    cancel: cancelDialog = noop,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  if (isEmptyString(name)) {
    return null;
  }

  const [actionLabels, setActionLabels] = React.useState<ActionsListItemData[]>([]);

  const addActions = React.useCallback((updatedItem: ActionsListItemData) => {
    setActionLabels(actionLabels.concat(updatedItem));
  }, [setActionLabels, actionLabels]);

  const editActions = React.useCallback((updatedItems: ActionsListItemData[]) => {
    setActionLabels(updatedItems);
  }, [setActionLabels]);

  const actionNames = React.useMemo(() => actionLabels.map((label) => label.getName()), [actionLabels]);

  const [{ showLoadingIndicator, ...otherModel }, { updateActionLabels, reset }] =
    useAddActionLabels({ name, version, etag, actionLabels: actionNames });

  const confirm = React.useCallback(() => {
    updateActionLabels();
  }, [updateActionLabels]);

  const cancel = React.useCallback(() => {
    reset();
    cancelDialog();
    setActionLabels([]);
  }, [reset, cancelDialog, setActionLabels]);

  const onSuccessMessageShown = React.useCallback(() => {
    reset();
    setActionLabels([]);
    onSuccess();
  }, [reset, onSuccess, setActionLabels]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      className="workloadAddActionLabelDialog"
      title="Add Workload Completion Actions"
      loading={showLoadingIndicator}
      continueButtonLabel="Save"
      continueButtonDisabled={!actionLabels.length}
      onSuccessMessageShown={onSuccessMessageShown}
      confirm={confirm}
      cancel={cancel}
    >
      <div className={classnames("workloadAddActionLabelContainer", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Enter all the new actions in the table below.
          </label>
          <label className={classnames("title", classes.title)}>
            These new actions will be added to the existing list of actions.
          </label>
          <WorkloadCompletionMetadata
            title=""
            hideVariables={true}
            actions={actionNames}
            showAddActions={true}
            showEditActions={true}
            showDeleteActions={true}
            customAddAction={addActions}
            customEditActions={editActions}
          />
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadAddActionLabelDialog;
