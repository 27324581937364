import {
  BASE_URL,
  MODULE_PATH as DETAILS_PAGE_BASE_URL,
  RouteParams as CloneWorkloadRouteParams,
} from "@modules/dataWorkloadDetails/constants";

export const CREATE_WORKLOAD_WIZARD_MODULE_ID = "create_workload";
export const CREATE_WORKLOAD_WIZARD_MODULE_PATH = `${BASE_URL}/create`;

export const CLONE_WORKLOAD_WIZARD_MODULE_ID = "clone_workload_request";
export const CLONE_WORKLOAD_WIZARD_MODULE_PATH = `${DETAILS_PAGE_BASE_URL}/clone`;

export { CloneWorkloadRouteParams };
