import { isEmptyString } from "@util";
import { createSelector } from "reselect";
import { DEFAULT_STATE, LockUserSchema, LockUserAction } from "./reducers";
import { createSelectors, Selector } from "@base/createSelectors";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";
import { LockUserRequest, LockUserRequestAttributes } from "@data";

export const {
  userId: getUserId,
  open: isDialogOpen,
  action: getUserLockAction,
  lockUser: getLockUserAttributes,
  json: getJson,
  getErrorMessage,
  getSuccessMessage,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  getAccessToken,
} = createSelectors<LockUserSchema>(moduleId, schemaKey, DEFAULT_STATE);

export const getLockUser: Selector<LockUserRequest> = createSelector(
  getLockUserAttributes, (attrs: LockUserRequestAttributes) => {

    return new LockUserRequest(attrs);
  });

export const isUserLocked: Selector<boolean> = createSelector(
  getLockUser, (lockUser: LockUserRequest) => {
    return lockUser.userLocked;
  });

export const isUserIdValid: Selector<boolean> = createSelector(
  getUserId, userId => !isEmptyString(userId));

export const isLockUserAction: Selector<boolean> = createSelector(
  getUserLockAction, (action: LockUserAction) => {
    return action === LockUserAction.LOCK_USER;
  });

export const getDialogTitle: Selector<string> = createSelector(
  isLockUserAction, (lockUserAction: boolean) => {
    return lockUserAction ? "Lock User" : "Unlock User";
  });

export const getContinueButtonLabel: Selector<string> = createSelector(
  isLockUserAction, (lockUserAction: boolean) => {
    return lockUserAction ? "Lock User" : "Unlock User";
  });

export const getClassName: Selector<string> = createSelector(
  isLockUserAction, (lockUserAction: boolean) => {
    return lockUserAction ? "lockUserDialog" : "unlockUserDialog";
  });
