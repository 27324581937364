import React from "react";
import { createComparator, getSearchResults } from "@components/managed-policies-list/helpers";
import { AuthTokenContext } from "@components";
import { isEmptyString } from "@util";
import { ManagedPoliciesListColumn } from "@components";
import { GetManagedPoliciesResponse, AuthorizationServiceClient, RestClientError } from "@network";
import { ManagedPolicy } from "@data";

export interface UseManagedPoliciesOptions {
  searchDisabled?: boolean;
}

export interface UseManagedPoliciesModel {
  managedPolicies: ManagedPolicy[];
  nameFilter: string;
  errorMessage: string;
  showLoadMoreButton: boolean;
  sortOrderAscending: boolean;
  showSearch: boolean;
  showLoadingIndicator: boolean;
  totalNumPolicies: number;
  showNoResultsView: boolean;
  showNotFound: boolean;
  showAccessDenied: boolean;
  totalNumVisiblePolicies: number;
  sortByColumn: ManagedPoliciesListColumn;
}

export interface UseManagedPoliciesActions {
  refresh: () => void;
  loadMore: () => void;
  setNameFilter: (nameFilter: string) => void;
  setSortByColumn: (column: ManagedPoliciesListColumn) => void;
  setSortOrderAscending: (ascending: boolean) => void;
}

type Model = UseManagedPoliciesModel;
type Actions = UseManagedPoliciesActions;

export const sortAndFilterManagedPolicyResult = (props: {
  items: ManagedPolicy[],
  nameFilter: string,
  sortOrderAscending: boolean,
  sortByColumn: ManagedPoliciesListColumn
}) => {

  const {items, nameFilter, sortOrderAscending, sortByColumn } = props;

  const comparator = React.useMemo(() =>
    createComparator(sortByColumn, sortOrderAscending), [sortByColumn, sortOrderAscending]);

  const sortedServices = React.useMemo(() => items.sort(comparator).slice(), [items, comparator]);

  return React.useMemo(() =>
    getSearchResults(sortedServices, nameFilter), [sortedServices, nameFilter]);
};

export const useManagedPolicies = (options: UseManagedPoliciesOptions = {}): [Model, Actions] => {

  const { searchDisabled = false } = options;

  const authToken = React.useContext(AuthTokenContext);
  const [items, setItems] = React.useState<ManagedPolicy[]>([]);
  const [nameFilter, setNameFilter] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [nextPageToken, setNextPageToken] = React.useState("");
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);
  const [sortOrderAscending, setSortOrderAscending] = React.useState(true);
  const [sortByColumn, setSortByColumn] = React.useState(ManagedPoliciesListColumn.NONE);
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);
  const [showNotFound, setShowNotFound] = React.useState(false);
  const [showAccessDenied, setShowAccessDenied] = React.useState(false);

  const fetchManagedPolicies = React.useCallback(() =>
      AuthorizationServiceClient.getManagedPolicies(authToken, nextPageToken, nameFilter),
    [authToken, nextPageToken, nameFilter]);

  React.useEffect(() => {

    let ignore = false;

    setShowLoadingIndicator(true);
    setErrorMessage("");

    fetchManagedPolicies()
      .then((response: GetManagedPoliciesResponse) => {
        if (!ignore) {
          const { policies: results = [] , paging: { next = ""} = {} } = response;
          setItems(s => s.concat(results.map(attrs => new ManagedPolicy(attrs))));
          // Put an extra if condition to prevent memory leak error while running Managed Policy Details IT test
          if (!ignore) {
            setShowLoadingIndicator(false);
            setNextPageToken(next || "");
          }
        }
      }, (response: RestClientError) => {
        if (!ignore) {
          const { status, error = "Fetch managed policies failed" } = response;
          if (status === 404) {
            setShowNotFound(true);
          }
          if (status === 403) {
            setShowAccessDenied(true);
          }
          setErrorMessage(error);
          setShowLoadingIndicator(false);
        }
      });
    return () => { ignore = true; };

  },
    [
      lastRefresh,
      setItems,
      setNextPageToken,
      setErrorMessage,
      setShowLoadingIndicator,
      setShowNotFound,
      setShowAccessDenied,
    ]);

  const showLoadMoreButton = React.useMemo(() =>
    !isEmptyString(nextPageToken) && isEmptyString(errorMessage) && !showLoadingIndicator,
    [nextPageToken, errorMessage, showLoadingIndicator]);

  const loadMore = React.useCallback(() => {
    setLastRefresh(+new Date);
  }, [setLastRefresh]);

  const refresh = React.useCallback(() => {
    setNextPageToken("");
    setItems([]);
    setLastRefresh(+new Date);
    setSortByColumn(ManagedPoliciesListColumn.NONE);
    setSortOrderAscending(true);
    setShowNotFound(false);
    setShowAccessDenied(false);
  }, [setNextPageToken, setItems, setLastRefresh, setSortByColumn,
    setSortOrderAscending, setShowAccessDenied, setShowNotFound]);

  const updateNameFilter = React.useCallback((updatedNameFilter: string) => {
    setNameFilter(updatedNameFilter);
    if (!searchDisabled) {
      refresh();
    }
  }, [setNameFilter, searchDisabled, refresh]);

  const managedPolicies = sortAndFilterManagedPolicyResult({items, nameFilter, sortOrderAscending, sortByColumn});

  const totalNumPolicies = React.useMemo(() => items.length, [items]);

  const totalNumVisiblePolicy = React.useMemo( () => managedPolicies.length, [managedPolicies]);

  const showSearch = React.useMemo(() =>
    !isEmptyString(nameFilter) || totalNumPolicies > 1, [nameFilter, totalNumPolicies]);

  const showErrorView = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]);

  const showNoResultsView = React.useMemo(() =>
    totalNumVisiblePolicy === 0 && !showErrorView && !showLoadingIndicator,
    [totalNumVisiblePolicy, showErrorView, showLoadingIndicator]);

  const model = React.useMemo<Model>(() => ({
    managedPolicies,
    nameFilter,
    errorMessage,
    showLoadMoreButton,
    sortOrderAscending,
    sortByColumn,
    showSearch,
    showLoadingIndicator,
    showNoResultsView,
    showNotFound,
    showAccessDenied,
    totalNumPolicies,
    totalNumVisiblePolicies: totalNumVisiblePolicy,
  }), [
    managedPolicies,
    nameFilter,
    errorMessage,
    showLoadMoreButton,
    sortOrderAscending,
    sortByColumn,
    showSearch,
    showLoadingIndicator,
    totalNumPolicies,
    showNoResultsView,
    showNotFound,
    showAccessDenied,
    totalNumVisiblePolicy,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    refresh,
    loadMore,
    setNameFilter: updateNameFilter,
    setSortByColumn,
    setSortOrderAscending,
  }) , [
    refresh,
    loadMore,
    updateNameFilter,
    setSortByColumn,
    setSortOrderAscending,
  ]);

  return [model , actions];
};

export default useManagedPolicies;
