import React from "react";
import classnames from "classnames";
import { AccessRequestApprovalDetail } from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import AccessRequestApprovalDetailsListColumn from "./AccessRequestApprovalDetailsListColumn";
import SortedSearchResultsList, {
  SortedSearchResultsListActions as ListActions,
  SortedSearchResultsListModel as ListModel,
} from "@components/sorted-search-results-list";
import styles from "./styles";
import AccessRequestApprovalDetailsListItem
  from "@components/data-access-request-summary-view/AccessRequestApprovalDetailsList/AccessRequestApprovalDetailsListItem";

type Item = AccessRequestApprovalDetail;
type Column = AccessRequestApprovalDetailsListColumn;

const DEFAULT_COLUMNS: Column[] = [
  AccessRequestApprovalDetailsListColumn.APPROVAL_REQUEST_TYPE,
  AccessRequestApprovalDetailsListColumn.REQUEST_STATE,
  AccessRequestApprovalDetailsListColumn.APPROVERS,
  AccessRequestApprovalDetailsListColumn.APPROVAL_COMMENTS,
  AccessRequestApprovalDetailsListColumn.APPROVED_BY,
  AccessRequestApprovalDetailsListColumn.APPROVED_AT,
];

const DEFAULT_RENDER_ITEM = (item: Item, column: Column) => (
  <AccessRequestApprovalDetailsListItem
    column={column}
    item={item}
  />
);

export interface AccessRequestApprovalDetailsListModel extends ListModel<Item, Column> {
  className?: string;
  title?: string;
}

export interface AccessRequestApprovalDetailsListActions extends ListActions<Item, Column> {
}

type Model = AccessRequestApprovalDetailsListModel;
type Actions = AccessRequestApprovalDetailsListActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const AccessRequestApprovalDetailsList = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Access Request Approval Details",
    tableLayoutFixed = false,
    columns = DEFAULT_COLUMNS,
    items = [],
    renderItem = DEFAULT_RENDER_ITEM,
    children,
    ...otherProps
  } = props;

  return (
    <div className={classnames("accessRequestApprovalDetailsList", className, classes.container)}>
      <h1 className={classnames("title", classes.title)}>
        {title}
      </h1>
      <SortedSearchResultsList
        {...otherProps}
        className={classnames("list", classes.list)}
        tableLayoutFixed={tableLayoutFixed}
        columns={columns}
        items={items}
        renderItem={renderItem}
      />
      {children}
    </div>
  );
});

export default AccessRequestApprovalDetailsList;
