import React from "react";
import classnames from "classnames";
import MuiAccordionSummary, { AccordionSummaryProps } from "@material-ui/core/AccordionSummary";

export interface CustomAccordionSummaryProps extends AccordionSummaryProps {}

export const AccordionSummary = ((props: CustomAccordionSummaryProps) => {

    const {
      className,
      ...otherProps
    } = props;

    return (
      <MuiAccordionSummary
        {...otherProps}
        className={classnames("accordionSummary", className)}
      />
    );
});

export default AccordionSummary;
