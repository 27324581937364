import createStyles from "@material-ui/core/styles/createStyles";
import { AuditEventIcon, primaryColor } from "@modules/auditEvents/components/styles";
import Color from "@components/styles/Color";

export { AuditEventIcon };

export const listItem = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "center",
    },
    label: {
      color: Color.TEXT,
      cursor: "inherit",
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
      textTransform: "none",
      wordBreak: "break-word",
      marginRight: 8,
    },
    icon: {
      color: primaryColor,
      alignSelf: "center",
      width: 16,
      height: 16,
      marginTop: 0,
      marginRight: 10,
    },
  });

export const styles = () =>
  createStyles({
    container: {
    },
    table: {
      "& th.timestamp, & td.timestamp": {
        width: "20%",
      },
      "& th.severity, & td.severity": {
        width: "10%",
      },
      "& th.type, & td.type": {
        width: "30%",
      },
      "& th.principal, & td.principal": {
        width: "40%",
      },
    },
  });

export default styles;
