import React from "react";
import classnames from "classnames";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
} from "@components/submit-api-request-view";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import { DSLayoutInfo } from "@data";

export enum DigitalShadowLayoutWizardSteps {
  CREATE_TYPE = "create_type",
  LAYOUT_INFO = "layout_info",
  CHILDREN = "children",
  JSON_REVIEW = "json_review",
}

export type DigitalShadowLayoutWizardStepLabels<K extends keyof any = DigitalShadowLayoutWizardSteps> = {
  [P in K]: string;
};

export const DEFAULT_STEP_LABELS: DigitalShadowLayoutWizardStepLabels = {
  [DigitalShadowLayoutWizardSteps.CREATE_TYPE]: "Wizard Type",
  [DigitalShadowLayoutWizardSteps.LAYOUT_INFO]: "Layout Information",
  [DigitalShadowLayoutWizardSteps.CHILDREN]: "Children",
  [DigitalShadowLayoutWizardSteps.JSON_REVIEW]: "JSON Review",
};

export interface Model extends SubmitApiRequestViewModel<DSLayoutInfo, DigitalShadowLayoutWizardSteps> {
  stepLabels?: DigitalShadowLayoutWizardStepLabels;
  isEdit?: boolean;
}

export interface Actions extends SubmitApiRequestViewActions<DSLayoutInfo, DigitalShadowLayoutWizardSteps> {
  stepLabels?: DigitalShadowLayoutWizardStepLabels;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DigitalShadowLayoutWizard = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    snackbarId = "digital-shadow-layout-wizard",
    errorTitle = "Create Shadow Layout Failed",
    saveButtonLabel = "Create Layout",
    isEdit = false,
    defaultState = DSLayoutInfo.EMPTY,
    currentState = DSLayoutInfo.EMPTY,
    stepLabels = DEFAULT_STEP_LABELS,
    mapStepToLabel = React.useCallback(step => stepLabels[step] || null, [stepLabels]),
    children,
    ...otherProps
  } = props;

  return (
    <SubmitApiRequestView
      {...otherProps}
      className={classnames("digitalShadowLayoutWizard", className, classes.container)}
      snackbarId={snackbarId}
      errorTitle={errorTitle}
      defaultState={defaultState}
      currentState={currentState}
      saveButtonLabel={saveButtonLabel}
      mapStepToLabel={mapStepToLabel}
    >
      {children}
    </SubmitApiRequestView>
  );
});

export default DigitalShadowLayoutWizard;
