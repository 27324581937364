import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: 0,
    },
    list: {
    },
  });

export default styles;
