import React from "react";
import classnames from "classnames";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

export interface CustomAlertProps extends AlertProps {}

export const Alert = ((props: CustomAlertProps) => {

    const {
      className,
      ...otherProps
    } = props;

    return (
      <MuiAlert
        {...otherProps}
        className={classnames("alert", className)}
      />
    );
});

export default Alert;
