import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { getPrivacyTermsConditionsId } from "../selectors";
import TermsConditionsView, {
  TermsConditionsViewActions as Actions,
  TermsConditionsViewModel as Model,
} from "../components/TermsConditionsView";

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const TermsConditionsViewContainer = (props: Props) => {

  const {
    ...otherProps
  } = props;

  return (
    <TermsConditionsView
      {...otherProps}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  termsConditionsId: getPrivacyTermsConditionsId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(TermsConditionsViewContainer);
