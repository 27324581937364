import React from "react";
import classnames from "classnames";
import { RadioGroup as MuiRadioGroup, RadioGroupProps }  from "@material-ui/core";

export interface CustomRadioGroupProps extends RadioGroupProps {}

export const RadioGroup = ((props: CustomRadioGroupProps) => {

    const {
      className,
      ...otherProps
    } = props;

    return (
      <MuiRadioGroup
        {...otherProps}
        className={classnames("radioGroup", className)}
      />
    );
});

export default RadioGroup;
