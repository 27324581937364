import createStyles from "@material-ui/core/styles/createStyles";
import { PrimaryIcon } from "@modules/dashboards/components/styles";
import { Color } from "@Color";

export const ModuleIcon = PrimaryIcon;

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    container: {
      marginTop: 0,
    },
  });
