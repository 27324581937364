import React from "react";
import { Module } from "@data";
import { MODULE_ID } from "./constants";
import { isDialogOpen } from "./selectors";
import { initialize, open } from "./actions";
import { PrimaryIcon } from "../users/components/styles";
import CloseUserDialog from "./containers/CloseUserDialog";

export const closeUserModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "Close User Account",
  title: "Close User Account",
  icon: PrimaryIcon,
  view: <CloseUserDialog />,
  onClick: open,
  isDialogOpen,
  initialize,
};

export default closeUserModule;
