import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { DeviceTypeModelV3, DeviceTypeListItem } from "@data";
import { DeviceTypeAction } from "../reducers";
import { usePortalSnackbar } from "@components";
import { refresh } from "@modules/deviceTypeDetails/actions";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MODULE_PATH as DETAILS_PAGE_PATH } from "@modules/deviceTypeDetails/constants";
import {
  getDeviceType,
  getDeviceTypeAction,
  getDialogClassName,
  getDialogContinueButtonLabel,
  getDialogTitle,
  getErrorMessage,
  getSuccessMessage,
  isAccessDeniedVisible,
  isDialogVisible,
  isProgressIndicatorVisible,
} from "../selectors";
import { close, executeDeviceTypeAction } from "../actions";
import DeviceTypeManager, { Actions, Model } from "../components/DeviceTypeManager";
import { getPathToDeviceType } from "@modules/deviceTypeDetails/helpers";

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
  refreshDetailsPage?: () => void;
}

type Props = ContainerModel & ContainerActions;

const DeviceTypeManagerContainer = (props: Props) => {

  const history = useHistory();

  const match = useRouteMatch(DETAILS_PAGE_PATH);

  const isDetailsPageLoaded = React.useMemo(() => match && match.isExact, [match]);

  const { refreshDetailsPage = noop, ...otherProps } = props;

  const {
    open,
    errorMessage,
    successMessage,
    item: deviceType = DeviceTypeModelV3.EMPTY,
    deviceTypeAction = DeviceTypeAction.NONE,
    cancel: closeDialog = noop,
  } = otherProps;

  const wasNewVersionDrafted = React.useMemo(() =>
    deviceTypeAction === DeviceTypeAction.DRAFT, [deviceTypeAction]);

  const wasDeleted = React.useMemo(() =>
    deviceTypeAction === DeviceTypeAction.DELETE, [deviceTypeAction]);

  const wasUpdated = React.useMemo(() =>
    (deviceTypeAction === DeviceTypeAction.PROMOTE) ||
    (deviceTypeAction === DeviceTypeAction.DEPRECATE) ||
    (deviceTypeAction === DeviceTypeAction.DECOMMISSION),
    [deviceTypeAction]);

  const showDeviceTypeDetails = React.useCallback(() => {
    history.push(getPathToDeviceType(deviceType.typeIdentity));
  }, [history, deviceType]);

  const goBack = React.useCallback(() => history.goBack(), [history]);

  const onSuccessMessageShown = React.useCallback(() => {

    if (wasNewVersionDrafted) {
      showDeviceTypeDetails();
    } else if (isDetailsPageLoaded) {
      if (wasDeleted) {
        goBack();
      } else if (wasUpdated) {
        refreshDetailsPage();
      }
    }

    closeDialog();

  }, [
    isDetailsPageLoaded,
    wasNewVersionDrafted,
    wasDeleted,
    wasUpdated,
    showDeviceTypeDetails,
    goBack,
    refreshDetailsPage,
    closeDialog,
  ]);

  usePortalSnackbar(deviceTypeAction || "device-type-manager", {
    errorMessage,
    successMessage,
    onSuccessMessageShown,
    successCallbackDelay: 250,
  });

  if (!open) {
    return null;
  }

  return <DeviceTypeManager {...otherProps} />;
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  deviceTypeAction: getDeviceTypeAction(state),
  className: getDialogClassName(state),
  title: getDialogTitle(state),
  continueButtonLabel: getDialogContinueButtonLabel(state),
  open: isDialogVisible(state),
  item: getDeviceType(state),
  loading: isProgressIndicatorVisible(state),
  accessDenied: isAccessDeniedVisible(state),
  errorMessage: getErrorMessage(state),
  successMessage: getSuccessMessage(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  refreshDetailsPage: () => dispatch(refresh()),
  cancel: () => dispatch(close()),
  confirm: (deviceType?: DeviceTypeListItem) => dispatch(executeDeviceTypeAction(deviceType)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceTypeManagerContainer);
