import { Record } from "immutable";
import { equalsIgnoreCase, getStringValue, isEmptyString } from "@util";

type PolicyCondition = {
  [operator in string]?: {
    [key in string]: number | string | string[];
  };
};

export interface PolicyStatementAttributes {
  id: string;
  action: string;
  resource: string;
  effect: string;
  condition?: PolicyCondition;
}

export class PolicyStatement extends  Record({
  id: "",
  action: "",
  resource: "",
  effect: "",
  condition: {},
}) implements PolicyStatementAttributes {

  public static EMPTY: PolicyStatement = new PolicyStatement();

  public readonly id: string;
  public readonly action: string;
  public readonly resource: string;
  public readonly effect: string;
  public readonly condition: PolicyCondition;

  public getId(): string {
    return getStringValue(this.id);
  }

  public getAction(): string {
    return getStringValue(this.action);
  }

  public getResource(): string {
    return getStringValue(this.resource);
  }

  public getEffect(): string {
    return getStringValue(this.effect);
  }

  public getCondition(): PolicyCondition {
    return this.condition;
  }

  public isValid(): boolean {
    const effect = this.getEffect();
    return !isEmptyString(this.getAction()) &&
      !isEmptyString(this.getResource()) &&
      !isEmptyString(effect) &&
      (equalsIgnoreCase("allow", effect) || equalsIgnoreCase("deny", effect));
  }
}
