import { Module } from "@data";
import { equalsIgnoreCase } from "@util";
import { initialize, onMount } from "./actions";
import { createModule } from "@base/createModule";
import { DATA_CATALOG_PATH, LEGACY_MODULE_PATH, MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./DataSetsView";

// Module can be disabled on its own but also falls under the umbrella of D&A
export const DATA_SETS_MODULE_DISABLED = equalsIgnoreCase("false", process.env.REACT_APP_DATA_SETS_MODULE_ENABLED) ||
  equalsIgnoreCase("false", process.env.REACT_APP_DATA_ANALYTICS_MODULES_ENABLED);

export const dataSetsModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  legacyPath: [LEGACY_MODULE_PATH, DATA_CATALOG_PATH],
  exactPath: true,
  strictPath: true,
  name: "Data Sets",
  title: "Data Sets",
  className: "dataSetsModule",
  primaryColor,
  icon,
  view,
  backButton: null,
  initialize,
  onMount,
  disabled: DATA_SETS_MODULE_DISABLED,
});

export default dataSetsModule;
