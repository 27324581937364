import AppSchema from "@schemas";
import { MODULE_ID } from "./constants";
import { disableModuleId } from "@main/actions";
import { isUserPrincipal } from "@main/selectors";
import isCostReportsEnabled from "@util/isCostReportsEnabled";
import getCostReportsWhitelistedAccounts from "@util/getCostReportsWhitelistedAccounts";
import isCostReportsAccountWhitelistingEnabled from "@util/isCostReportsAccountWhitelistingEnabled";

export const COST_REPORTS_REQUIREMENTS = {
  disabled: !isCostReportsEnabled(),
  ...(!isCostReportsAccountWhitelistingEnabled() ? ({}) : ({
    whitelistedAccounts: getCostReportsWhitelistedAccounts(),
  })),
  // In addition to checking environment variable to determine if module should
  // be enabled, we must also only enable this module if a user principal is active.
  //
  // We do not need to make the additional environment check because this will only run if
  // the module's disabled flag is not false
  initialize: () => (dispatch: any, getState: () => AppSchema) => {
    const state = getState();
    if (!isUserPrincipal(state)) {
      return dispatch(disableModuleId(MODULE_ID));
    }
    return Promise.resolve();
  }
};

export default COST_REPORTS_REQUIREMENTS;
