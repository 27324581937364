import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { WaitForApiRequestView } from "@components";
import { useTrainingStatus } from "@hooks";
import { getPathToDataAccessRequest } from "@modules/dataAccessRequestDetails/helpers";
import { reloadItems as refreshDataAccessRequests } from "@modules/dataAccessRequests/actions";
import { reloadItems as refreshDataApprovalRequests } from "@modules/dataApprovalRequests/actions";
import DataAccessRequestWizard from "./containers/DataAccessRequestWizard";

export function CreateDataAccessRequestView() {

  const history = useHistory();

  const dispatch = useDispatch();

  const { id: dataSetId } = useParams<{ id: string }>();

  const refreshDataCatalogModule = React.useCallback(() => {
    dispatch(refreshDataAccessRequests());
    dispatch(refreshDataApprovalRequests());
  }, [dispatch]);

  const showDataAccessRequestDetails = React.useCallback(accessRequestId => {
    history.replace(getPathToDataAccessRequest(accessRequestId));
  }, [history]);

  const [{ trainingCompleted, showSuccessView, ...model }, { refresh }] = useTrainingStatus();

  if (!showSuccessView) {
    return (
      <WaitForApiRequestView
        className="createDataAccessRequestView"
        loadingMessage="Loading Data Access Request Wizard..."
        errorTitle="Unable to verify your training certificate status"
        retry={refresh}
        {...model}
      />
    );
  }

  return (
    <DataAccessRequestWizard
      dataSetId={dataSetId}
      trainingCompleted={trainingCompleted}
      refreshDataCatalogModule={refreshDataCatalogModule}
      showDataAccessRequestDetails={showDataAccessRequestDetails}
    />
  );
}

export default CreateDataAccessRequestView;
