import React from "react";
import classnames from "classnames";
import { withQuantity } from "@components/summary";
import { PrimaryIcon, dashboards as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { RefreshButton, Search } from "@components";

export const Summary = withQuantity({
  other: "dashboards",
  one: "dashboard",
});

export interface Model {
  numItems?: number;
  searchQuery?: string;
  loading?: boolean;
}

export interface Actions {
  refresh?: () => void;
  updateSearchQuery?: (searchQuery: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const Dashboards = withStyles(styles)((props: Props) => {

  const {
    classes,
    numItems = 0,
    searchQuery = "",
    loading,
    refresh,
    updateSearchQuery,
    children,
  } = props;

  return (
    <div className={classnames("dashboards", classes.container)}>
      <Summary quantity={numItems} icon={PrimaryIcon}>
        <RefreshButton loading={loading} refresh={refresh} />
      </Summary>
      <Search
        className={classes.search}
        hint="Filter results by name"
        value={searchQuery}
        updateSearchQuery={updateSearchQuery}
      />
      {children}
    </div>
  );
});

export default Dashboards;
