import React from "react";

export const AccountIdContext = React.createContext("");

export const AccountIdProvider = (props: {
  accountId?: string,
  children?: React.ReactNode,
}) => {

  const { accountId = "", children } = props;

  return (
    <AccountIdContext.Provider value={accountId}>
      {children}
    </AccountIdContext.Provider>
  );
};

export default AccountIdProvider;
