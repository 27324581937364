import snakeCase from "lodash/snakeCase";
import { ReducersMapObject } from "redux";
import { Actions, filterAction, Identity } from "@util";

export interface BasePortalModuleSchema {
  errors: string[];
  errorMessage: string;
  successMessage: string;
  showNotFound: boolean;
  showEmptyView: boolean;
  showAccessDenied: boolean;
  showLoadingIndicator: boolean;
}

export const DEFAULT_STATE: BasePortalModuleSchema = Object.freeze({
  errors: [],
  errorMessage: "",
  successMessage: "",
  showNotFound: false,
  showEmptyView: true,
  showAccessDenied: false,
  showLoadingIndicator: false,
});

type BaseSchema = BasePortalModuleSchema;

export type PortalModuleActionTypes<S extends BaseSchema = BaseSchema> = {
  [P in keyof S]: string;
};

export type PortalModuleActionType<S extends BaseSchema = BaseSchema> =
  PortalModuleActionTypes<S>[keyof PortalModuleActionTypes<S>];

export type PortalModuleReducers<S extends BaseSchema = BaseSchema> = ReducersMapObject<S>;

export const createPortalModuleDefaultState = <S extends BaseSchema = BaseSchema>(moduleState: any) =>
  Object.freeze({
    ...DEFAULT_STATE,
    ...moduleState,
  }) as S;

export const createPortalModuleActionTypes =
  <S extends BaseSchema>(moduleId: string, defaultState: S): PortalModuleActionTypes<S> =>
    Object.keys(defaultState)
      .reduce((actionTypes: PortalModuleActionTypes<S>, key: keyof PortalModuleActionTypes) => {
        actionTypes[key] = `${moduleId}/set_${snakeCase(key)}`;
        return actionTypes;
      }, {} as PortalModuleActionTypes<S>);

export const createPortalModuleReducers =
  <S extends BaseSchema>(actionTypes: PortalModuleActionTypes<S>, defaultState: S): PortalModuleReducers<S> =>
    Object.keys(defaultState)
      .reduce((reducers: PortalModuleReducers<S>, key: keyof PortalModuleReducers) => {
        reducers[key] = filterAction(Actions(actionTypes[key]))(Identity, defaultState[key]);
        return reducers;
      }, {} as PortalModuleReducers<S>);
