import { Record } from "immutable";
import { getStringValue, isEmptyString, isValidInteger } from "@util";

export const SCHEMA_REF_PREFIX = "https://signify.com/v2/cmnsvs/schemareg/schemas";
const SCHEMA_REF_REGEX = /^.*\/ns\/(.+)\/n\/(.+)\/v\/(.+)$/;

export enum JsonSchemaState {
  DRAFT = "DRAFT",
  RELEASED = "RELEASED",
  DEPRECATED = "DEPRECATED",
  DECOMMISSIONED = "DECOMMISSIONED",
  DELETED = "DELETED",
}

export interface JsonSchemaMetadataAttributes {
  namespace: string;
  name: string;
  version: number;
  state: JsonSchemaState;
  description: string;
  mimeType: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  etag: string;
  location: string;
}

export class JsonSchemaMetadata extends Record({
  namespace: "",
  name: "",
  version: 1,
  state: JsonSchemaState.DRAFT,
  description: "",
  mimeType: "application/schema+json",
  createdAt: "",
  createdBy: "",
  updatedAt: "",
  updatedBy: "",
  etag: "",
  location: "",
}) implements JsonSchemaMetadataAttributes {

  public static EMPTY: JsonSchemaMetadata = new JsonSchemaMetadata();

  public readonly namespace: string;
  public readonly name: string;
  public readonly version: number;
  public readonly state: JsonSchemaState;
  public readonly description: string;
  public readonly mimeType: string;
  public readonly createdAt: string;
  public readonly createdBy: string;
  public readonly updatedAt: string;
  public readonly updatedBy: string;
  public readonly etag: string;
  public readonly location: string;

  public static fromNameAndVersion(nameAndVersion: string): JsonSchemaMetadata {

    const id = getStringValue(nameAndVersion);
    const namespace = getStringValue(id.split(":")[0]) || "";
    const name = getStringValue(id.split(":")[1]) || "";
    const version = getStringValue(id.split(":")[2]) || "";
    const isValidVersion = isValidInteger(version);

    return new JsonSchemaMetadata({
      namespace,
      name,
      version: isValidVersion ? Number(version) : undefined,
      location: isValidVersion
        ? `/data/modeling/v1/schemas/identity/${namespace}:${name}:${version}`
        : `/data/modeling/v1/schemas/name/${namespace}:${name}/latest`,
    });
  }

  public getNamespace(): string {
    return getStringValue(this.namespace);
  }

  public getName(): string {
    return getStringValue(this.name);
  }

  public getState(): string {
    return getStringValue(this.state);
  }

  public getLastUpdated(): string {
    return getStringValue(this.updatedAt);
  }

  public getVersionAsString(): string {
    return isValidInteger(this.version) ? `${this.version}` : "";
  }

  public getId(): string {

    const namespace = this.getNamespace();

    if (isEmptyString(namespace)) {
      return "";
    }

    const name = this.getName();

    if (isEmptyString(name)) {
      return "";
    }

    // Fallback to latest version if version is missing
    const version = this.getVersionAsString() || "latest";

    return `${namespace}:${name}:${version}`;
  }

  public hasId(): boolean {
    return !isEmptyString(this.getId());
  }

  public getSchemaRef(): string {

    const schemaRef = SCHEMA_REF_PREFIX +
      `/ns/${this.getNamespace()}/n/${this.getName()}/v/${this.getVersionAsString()}`;

    return SCHEMA_REF_REGEX.test(schemaRef) ? schemaRef : "";
  }

  public isDraftSchema(): boolean {
    return this.state === JsonSchemaState.DRAFT;
  }

  public isReleasedSchema(): boolean {
    return this.state === JsonSchemaState.RELEASED;
  }

  public isDeprecatedSchema(): boolean {
    return this.state === JsonSchemaState.DEPRECATED;
  }

  public isDecommissionedSchema(): boolean {
    return this.state === JsonSchemaState.DECOMMISSIONED;
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public getCreatedAt(): string {
    return getStringValue(this.createdAt);
  }

  public getCreatedBy(): string {
    return getStringValue(this.createdBy);
  }

  public getUpdatedAt(): string {
    return getStringValue(this.updatedAt);
  }

  public getUpdatedBy(): string {
    return getStringValue(this.updatedBy);
  }

  public getEtag(): string {
    return getStringValue(this.etag);
  }

  public getLocation(): string {
    return getStringValue(this.location);
  }
}

export default JsonSchemaMetadata;
