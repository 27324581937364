import React from "react";
import useApiRequest, {
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import { DSLayoutInfo } from "@data/DSLayoutInfo";
import useDigitalShadowClient from "../use-digital-shadow-client";
import { ETagHeaders, GetDSLayoutResponse } from "@network";

type SuccessResponse = GetDSLayoutResponse;

export interface UseGetDSLayoutProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
}

export interface UseGetDSLayoutModel extends UseApiRequestModel<SuccessResponse> {
  dsLayout: DSLayoutInfo;
  etag: ETagHeaders;
}

export interface UseGetDSLayoutActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetDSLayoutProps;
type Model = UseGetDSLayoutModel;
type Actions = UseGetDSLayoutActions;
type Result = [Model, Actions];

export const useGetDSLayout = (props: Props): Result => {

  const {
    name,
    defaultErrorMessage = "Failed to get layout",
    ...otherProps
  } = props;

  const DigitalShadowClient = useDigitalShadowClient();

  const makeApiRequest = React.useCallback(() =>
    DigitalShadowClient.getLayoutApi(name), [DigitalShadowClient, name]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const etag = React.useMemo(() => {
    return successResponse?.etag || {};
  }, [successResponse]);

  const dsLayout = React.useMemo<DSLayoutInfo>(() => {
    const attrs = successResponse?.layoutAttrs || {};
    return new DSLayoutInfo(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    etag,
    dsLayout,
  }), [
    baseModel,
    etag,
    dsLayout,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetDSLayout;
