import { isEmptyString } from "@util";
import { createSelector } from "reselect";
import { ShareDashboardSchema, ShareDashboardSteps, DEFAULT_STATE } from "./reducers";
import { createSelectors, Selector } from "@base/createSelectors";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";

export const {
  userId: getUserId,
  dashboardId: getDashboardId,
  step: getShareDashboardStep,
  open: isOpen,
  getErrorMessage,
  getSuccessMessage,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  getAccessToken,
} = createSelectors<ShareDashboardSchema>(moduleId, schemaKey, DEFAULT_STATE);

export const isUserIdValid: Selector<boolean> = createSelector(
  getUserId, userId => !isEmptyString(userId));

export const isSaveButtonEnabled: Selector<boolean> = createSelector(
  [isUserIdValid], (userId: boolean) => {
    return userId;
  });

export const isGetUserIdStep: Selector<boolean> = createSelector(
  getShareDashboardStep, step => step === ShareDashboardSteps.GET_USER_ID
);

export const isConfirmStep: Selector<boolean> = createSelector(
  getShareDashboardStep, step => step === ShareDashboardSteps.CONFIRM
);
