import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { withQuantity, RefreshButton, UiPackageManagementActionsMenu, ActionMenuItem } from "@components";
import { PrimaryIcon, files as styles } from "./styles";

export const Summary = withQuantity({
  other: "files",
  one: "file"
});

export interface Model {
  numItems?: number;
  loading?: boolean;
  actions?: ActionMenuItem[];
  actionsLabel?: string;
  children?: React.ReactNode;
}

export interface Actions {
  refresh?: () => void;
  uploadUiPackage?: () => void;
  deleteUiPackage?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const Files = withStyles(styles)((props: Props) => {

  const {
    classes,
    numItems = 0,
    loading,
    actions = [],
    actionsLabel = "Actions",
    children,
    refresh,
    uploadUiPackage,
    deleteUiPackage,
  } = props;

  return (
    <div className={classnames("files", classes.container)}>
      <Summary quantity={numItems} icon={PrimaryIcon}>
        <RefreshButton loading={loading} refresh={refresh}/>
        <UiPackageManagementActionsMenu
          className={classes.profileAttributeDefinitionActions}
          buttonLabel={actionsLabel}
          actions={actions}
          uploadUiPackage={uploadUiPackage}
          deleteUiPackage={deleteUiPackage}
        />
      </Summary>
      {children}
    </div>
  );
});

export default Files;
