import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      marginTop: 8,
    },
    errorView: {
    },
    accessDenied: {
    },
    loadingIndicator: {
      alignSelf: "center",
    },
    table: {
    },
    label: {
    },
    key: {
    },
    value: {
    },
  });

export default styles;
