import React from "react";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { jsonView as styles } from "./styles";
import { ErrorView, JsonEditor } from "@components";

export interface Model {
  title?: string;
  json?: string;
  errorMessage?: string;
}

export interface Actions {
  setJson?: (value: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const JsonReviewView = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Enter JSON Body",
    errorMessage = "",
    json,
    setJson,
    children,
  } = props;

  return (
    <div className={classnames("jsonReviewView", classes.container)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      <ErrorView
        title="JSON Error"
        className="shadowJsonErrorMessage"
        message={errorMessage}
      />
      <JsonEditor
        className={classnames("shadowJsonEditor", classes.jsonEditor)}
        name="shadowJsonEditor"
        json={json}
        setJson={setJson}
        height={"400px"}
      />
      {children}
    </div>
  );

});

export default JsonReviewView;
