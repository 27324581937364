import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { listItem as styles } from "./styles";
import { KeyValuePair } from "@data";
import { MetadataListColumn } from "./MetadataListColumns";
import classnames from "classnames";

export const MetadataListItems = withStyles(styles)((props: WithStyles<typeof styles> & {
  keyValue: KeyValuePair,
  column: string,
}) => {

  const { classes, keyValue = KeyValuePair.EMPTY, column } = props;

  if ( !column || KeyValuePair.EMPTY.equals(keyValue) ) {
    return null;
  }

  switch (column) {
    case MetadataListColumn.KEY:
      return (
        <div className={classnames("key", classes.container)}>
          <label className={classnames("key", classes.label)}>{keyValue.getKey()}</label>
        </div>
      );
    case MetadataListColumn.VALUE:
      return (
        <label className={classnames("value", classes.label)}>{keyValue.getValue()}</label>
      );
    default:
      return null;
  }
});

export default MetadataListItems;
