import { MODULE_PATH as BASE_URL } from "@modules/releaseNotes/constants";
import { MODULE_PATHS as BASE_MODULE_PATHS } from "@modules/releaseNotes/constants";
import { LEGACY_MODULE_PATHS as BASE_LEGACY_MODULE_PATHS } from "@modules/releaseNotes/constants";

export const MODULE_ID = "release_note_details";

export const MODULE_TITLE = "Release Note Details";

export const MODULE_PATHS = BASE_MODULE_PATHS
  .filter(path => path !== BASE_URL)
  .map(baseUrl => `${baseUrl}/:version`);

export const LEGACY_MODULE_PATHS = BASE_LEGACY_MODULE_PATHS
  .map(baseUrl => `${baseUrl}/:version`);

export interface RouteParams {
  version: string;
}

export { BASE_URL };
