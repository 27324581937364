import React from "react";
import { getPluralString, isEmptyString, noop } from "@util";
import { Device, DeviceAttributes, EnrollmentStatus } from "@data";
import { AuthTokenContext } from "@components/auth-token-provider";
import { DeviceEnrollmentClient, RestClientError } from "@network";

export interface UseDeviceEnrollmentStatusProps {
  deviceId?: string;
  autoRefreshEnabled?: boolean;
  autoRefreshDelaySecs?: number;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
}

export interface UseDeviceEnrollmentStatusModel {
  deviceId: string;
  device: Device;
  errorMessage: string;
  showErrorView: boolean;
  showAccessDenied: boolean;
  showNotFound: boolean;
  showLoadingIndicator: boolean;
  autoRefreshActive: boolean;
  autoRefreshMessage?: string;
  autoRefreshCountdown?: number;
}

export interface UseDeviceEnrollmentStatusActions {
  refresh: () => void;
}

type Props = UseDeviceEnrollmentStatusProps;
type Model = UseDeviceEnrollmentStatusModel;
type Actions = UseDeviceEnrollmentStatusActions;

export const useDeviceEnrollmentStatus = (props: Props): [Model, Actions] => {

  const {
    deviceId = "",
    autoRefreshEnabled = true,
    autoRefreshDelaySecs = 5,
    trackRequestEvent = noop,
    trackSuccessEvent = noop,
    trackErrorEvent = noop,
  } = props;

  const authToken = React.useContext(AuthTokenContext);
  const [device, setDevice] = React.useState(Device.EMPTY);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showAccessDenied, setShowAccessDenied] = React.useState(false);
  const [showNotFound, setShowNotFound] = React.useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);
  const [autoRefreshCountdown, setAutoRefreshCountdown] = React.useState(autoRefreshDelaySecs);

  const showErrorView = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]);

  const enrollmentStatus = React.useMemo(() => device.status, [device]);

  const enrollmentStatusPending = React.useMemo(() =>
    enrollmentStatus === EnrollmentStatus.QUEUED ||
    enrollmentStatus === EnrollmentStatus.ENROLLMENT_PENDING ||
    enrollmentStatus === EnrollmentStatus.DISENROLLMENT_PENDING ||
    enrollmentStatus === EnrollmentStatus.VALIDATION_PENDING,
    [enrollmentStatus]);

  const autoRefreshActive = React.useMemo<boolean>(() =>
    autoRefreshEnabled && enrollmentStatusPending,
    [autoRefreshEnabled, enrollmentStatusPending]);

  const autoRefreshMessage = React.useMemo(() => {
    if (!autoRefreshActive) {
      return "";
    }
    return "Refreshing in " + getPluralString(autoRefreshCountdown, {
      one: "second",
      other: "seconds",
    });
  }, [autoRefreshActive, autoRefreshCountdown]);

  const getDeviceEnrollmentStatus = React.useCallback(() =>
      DeviceEnrollmentClient.getDeviceStatus(authToken, deviceId),
    [authToken, deviceId]);

  const reset = React.useCallback(() => {
    setShowLoadingIndicator(false);
    setShowAccessDenied(false);
    setShowNotFound(false);
    setErrorMessage("");
    setDevice(Device.EMPTY);
  }, [setShowLoadingIndicator, setShowAccessDenied, setShowNotFound, setErrorMessage, setDevice]);

  const refresh = React.useCallback(() => {
    reset();
    setShowLoadingIndicator(true);
  }, [reset, setShowLoadingIndicator]);

  React.useEffect(() => {

    let ignore = false;

    if (showLoadingIndicator) {

      trackRequestEvent();

      getDeviceEnrollmentStatus()
        .then((attrs: DeviceAttributes) => {
          if (!ignore) {
            trackSuccessEvent();
            setDevice(new Device({ deviceId, ...attrs }));
            setShowLoadingIndicator(false);
          }
        }, (response: RestClientError) => {
          if (!ignore) {
            const { analytic, status, error = "Fetch device enrollment status failed" } = response;
            trackErrorEvent(analytic);
            setErrorMessage(error);
            if (status === 403) {
              setShowAccessDenied(true);
            } else if (status === 404) {
              setShowNotFound(true);
            }
            setShowLoadingIndicator(false);
          }
        });
    }

    return () => { ignore = true; };

  }, [
    deviceId,
    showLoadingIndicator,
    getDeviceEnrollmentStatus,
    setErrorMessage,
    setShowAccessDenied,
    setShowNotFound,
    setShowLoadingIndicator,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
  ]);

  React.useEffect(() => {

    if (!autoRefreshActive) {
      return noop;
    }

    if (showLoadingIndicator) {
      setAutoRefreshCountdown(autoRefreshDelaySecs);
      return noop;
    }

    if (autoRefreshCountdown > 0) {
      const timer = setTimeout(() => setAutoRefreshCountdown(autoRefreshCountdown - 1), 1000);
      return () => clearTimeout(timer);
    }

    refresh();

    return noop;

  }, [
    autoRefreshActive,
    showLoadingIndicator,
    autoRefreshCountdown,
    setAutoRefreshCountdown,
    autoRefreshDelaySecs,
    refresh,
  ]);

  const model = React.useMemo<Model>(() => ({
    deviceId,
    device,
    errorMessage,
    showErrorView,
    showAccessDenied,
    showNotFound,
    showLoadingIndicator,
    autoRefreshActive,
    ...(!autoRefreshActive ? ({}) : ({
      autoRefreshMessage,
      autoRefreshCountdown,
    })),
  }), [
    deviceId,
    device,
    errorMessage,
    showErrorView,
    showAccessDenied,
    showNotFound,
    showLoadingIndicator,
    autoRefreshActive,
    autoRefreshMessage,
    autoRefreshCountdown,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    refresh,
  }), [
    refresh,
  ]);

  return React.useMemo(() => [model, actions], [model, actions]);
};

export default useDeviceEnrollmentStatus;
