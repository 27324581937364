import React from "react";
import AceEditor, { AceOptions, EditorProps } from "react-ace";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import "brace/theme/tomorrow";
import "brace/ext/searchbox";
import "brace/mode/json";
import "brace/mode/mysql";

export const DEFAULT_EDITOR_PROPS = {
  $blockScrolling: true,
};

export interface JsonEditorModel {
  className?: string;
  name?: string;
  mode?: string;
  json?: string;
  width?: string;
  height?: string;
  readOnly?: boolean;
  tabSize?: number;
  fontSize?: number;
  showGutter?: boolean;
  wrapEnabled?: boolean;
  showPrintMargin?: boolean;
  highlightActiveLine?: boolean;
  debounceChangePeriod?: number;
  editorProps?: EditorProps;
  aceOptions?: AceOptions;
  marginTop?: boolean;
}

export interface JsonEditorActions {
  setJson?: (json: string) => void;
}

type Props = WithStyles<typeof styles> & JsonEditorModel & JsonEditorActions;

export const JsonEditor = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    name = "jsonEditor",
    mode = "json",
    json = "{}",
    width = "auto",
    height: initialHeight = "auto",
    readOnly,
    tabSize = 2,
    fontSize = 12,
    showGutter,
    wrapEnabled,
    showPrintMargin,
    highlightActiveLine = true,
    debounceChangePeriod = 500,
    editorProps = DEFAULT_EDITOR_PROPS,
    aceOptions = {},
    marginTop = true,
    setJson,
  } = props;

  const [height, setHeight] = React.useState(initialHeight);

  const updateHeight = React.useCallback(node => {
    if (initialHeight === "auto" && node !== null) {
      setHeight(`${node.getBoundingClientRect().height}px`);
    }
  }, [initialHeight, setHeight]);

  return (
    <div
      ref={updateHeight}
      className={classnames(className, classes.container, {
        [classes.marginTop]: marginTop,
      })}
    >
      <AceEditor
        className={classnames("jsonEditor", classes.jsonEditor)}
        name={name}
        mode={mode}
        theme="tomorrow"
        value={json}
        width={width}
        height={height}
        tabSize={tabSize}
        readOnly={readOnly}
        fontSize={fontSize}
        showGutter={showGutter}
        wrapEnabled={wrapEnabled}
        showPrintMargin={showPrintMargin}
        highlightActiveLine={!readOnly && highlightActiveLine}
        debounceChangePeriod={debounceChangePeriod}
        editorProps={editorProps}
        setOptions={aceOptions}
        onChange={setJson}
      />
    </div>
  );
});

export default JsonEditor;
