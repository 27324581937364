import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    header: {
      flex: "0 0 50px",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      backgroundColor: Color.LIGHT_GREY4,
      border: `1px solid ${Color.GREY1}`,
    },
    fileName: {
      color: Color.TEXT,
      fontSize: 15,
      fontWeight: 400,
      lineHeight: "1",
      marginLeft: 15,
    },
    downloadIconButton: {
      backgroundColor: Color.WHITE,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      marginLeft: 8,
      width: "auto",
      height: "auto",
      padding: 6,
    },
    downloadIcon: {
      color: Color.BLACK,
      width: 14,
      height: 14,
    },
    jsonEditor: {
      marginTop: 0,
      flex: "1 0 auto",
    },
    showHeader: {
    },
  });

export default styles;
