import React from "react";
import { noop } from "@util";
import JsonView from "./JsonView";
import { Namespace, NamespaceLifecycle } from "@data";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import SummaryView from "./SummaryView";
import { setNamespace } from "../actions";
import { useNamespace } from "@hooks";
import { getErrorMessage, getJson, getNamespaceName } from "../selectors";
import DetailsViewRoute from "@components/details-view/DetailsViewRoute";
import NamespaceDetails, { Actions, Model } from "../components/NamespaceDetails";
import {
  NamespaceAction,
  ActionMenuItem
} from "@components";
import NamespaceLifecycleManagementDialog from "@components/namespace-lifecycle-managament-dialog";
import { DEPRECATE_NAMESPACE, PUBLISH_NAMESPACE } from "@components/namespace-actions-menu";

const ROUTES: DetailsViewRoute[] = [
  {
    id: "summary",
    name: "Summary",
    view: SummaryView,
  },
  {
    id: "json",
    name: "{} JSON",
    path: "/json",
    view: JsonView,
  },
];

interface ContainerModel extends Model {
  json?: string;
  namespaceName?: string;
}

interface ContainerActions extends Actions {
  setNamespaceContent?: (namespace: Namespace) => void;
}

type Props = ContainerModel & ContainerActions;

const NamespacesDetailsContainer = (props: Props) => {

  const {
    routes = ROUTES,
    namespaceName,
    setNamespaceContent = noop,
    onClickAction = noop,
  } = props;

  const [openLifecycleDialog, setOpenLifecycleDialog] = React.useState(false);
  const [lifecycleState, setLifecycleState] = React.useState(NamespaceLifecycle.NONE);

  const [model, actions] = useNamespace({
    namespaceName,
  });

  const { namespace, showNotFound } = model;
  const { refresh } = actions;

  const namespaceActions = React.useMemo(() => ([
    {
      ...PUBLISH_NAMESPACE,
      disabled: namespace.isProductionLifecycle(),
    },
    {
      ...DEPRECATE_NAMESPACE,
      disabled: !namespace.isProductionLifecycle(),
    }
  ]), [namespace]);

  const publishNamespace = React.useCallback(() => {
    setOpenLifecycleDialog(true);
    setLifecycleState(NamespaceLifecycle.PRODUCTION);
  }, [setOpenLifecycleDialog, setLifecycleState]);

  const deprecateNamespace = React.useCallback(() => {
    setOpenLifecycleDialog(true);
    setLifecycleState(NamespaceLifecycle.DEPRECATED);
  }, [setOpenLifecycleDialog, setLifecycleState]);

  const onClickNamespaceAction = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
    case NamespaceAction.PUBLISH_NAMESPACE:
      return publishNamespace();
    case NamespaceAction.DEPRECATE_NAMESPACE:
      return deprecateNamespace();
    default:
      return onClickAction(action);
    }
  }, [publishNamespace, deprecateNamespace]);

  const closeDialog = React.useCallback(() => {
    setLifecycleState(NamespaceLifecycle.NONE);
    return setOpenLifecycleDialog(false);
  }, [setLifecycleState, setOpenLifecycleDialog]);

  const onUpdateSuccess = React.useCallback(() => {

    closeDialog();
    refresh();
  }, [closeDialog, refresh]);

  React.useEffect(() => {
    setNamespaceContent(namespace);
  }, [namespace, setNamespaceContent]);

  return (
    <React.Fragment>
      <NamespaceDetails
        {...model}
        {...actions}
        {...props}
        routes={routes}
        actions={namespaceActions}
        onClickAction={onClickNamespaceAction}
        showNotFound={showNotFound}
        refresh={refresh}
      />
      <NamespaceLifecycleManagementDialog
        open={openLifecycleDialog}
        namespace={namespace}
        state={lifecycleState}
        cancel={closeDialog}
        onSuccessMessageShown={onUpdateSuccess}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  json: getJson(state),
  namespaceName: getNamespaceName(state),
  errorMessage: getErrorMessage(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setNamespaceContent: (namespace: Namespace) => dispatch(setNamespace(namespace)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(NamespacesDetailsContainer);
