import React from "react";
import { DataLakeDataSet, ExecuteWorkloadRequest, Trigger } from "@data";
import { useCurrentAccountId, useExecuteWorkload, useGetWorkloads } from "@hooks";
import TestViewDrawer, { TestViewDrawerActions, TestViewDrawerModel } from "../components/TestViewDrawer";

export interface TestViewDrawerContainerModel extends Partial<TestViewDrawerModel> {
  name: string;
  version: number;
  accountId?: string;
  dataSets: DataLakeDataSet[];
  testTrigger: Trigger;
  isFunctionWorkload: boolean;
}

export interface TestViewDrawerContainerActions extends Partial<TestViewDrawerActions> {
  showExecutionHistoryView: () => void;
  showExecutionInstanceDetails: (name: string, version: number, executionId: string) => void;
}

type Props = TestViewDrawerContainerModel & TestViewDrawerContainerActions;

export const TestViewDrawerContainer = (props: Props) => {

  const {
    name,
    version,
    accountId = useCurrentAccountId(),
    testTrigger,
    isFunctionWorkload,
    showExecutionHistoryView,
    showExecutionInstanceDetails,
    ...otherProps
  } = props;

  const [executionData, setExecutionData] = React.useState(new ExecuteWorkloadRequest({
    ...ExecuteWorkloadRequest.EMPTY,
    accountId,
    type: testTrigger.getType(),
    scheduleId: testTrigger.getScheduleId(),
  }));

  const [error, setError] = React.useState("");

  const [{executionId = "", errorMessage, ...executeModel}, {executeWorkload: testWorkload}] =
    useExecuteWorkload({name, version, data: executionData});

  const showExecutionInstance = React.useCallback(() => showExecutionInstanceDetails(name, version, executionId),
    [name, version, executionId, showExecutionInstanceDetails]);

  const updateExecuteWorkloadData = React.useCallback((obj: ExecuteWorkloadRequest) => {
    setExecutionData(
      new ExecuteWorkloadRequest({
        ...executionData.toJS(),
        ...obj,
      }));
    setError("");
  }, [executionData, setExecutionData, setError]);

  React.useEffect(() => {
    setExecutionData(new ExecuteWorkloadRequest({
      accountId,
      type: testTrigger.getType(),
      scheduleId: testTrigger.getScheduleId(),
      precedingWorkloadId: testTrigger.getPrecedingWorkloadName(),
      dataSetAlias: testTrigger.getDataSetAlias(),
    }));
  }, [testTrigger]);

  React.useEffect(() => {
    if (errorMessage) {
      setError(errorMessage);
    }
  }, [errorMessage]);

  const [{ workloads, ...workloadsModel}, actions] = useGetWorkloads();
  const filteredWorkloads = React.useMemo(() => workloads.filter(workload => workload.getName() !== name)
    , [workloads, name]);

  return (
    <TestViewDrawer
      {...otherProps}
      {...workloadsModel}
      {...actions}
      {...executeModel}
      executionData={executionData}
      isFunctionWorkload={isFunctionWorkload}
      setExecutionData={updateExecuteWorkloadData}
      filteredWorkloads={filteredWorkloads}
      errorMessage={error}
      testWorkload={testWorkload}
      showExecutionInstance={showExecutionInstance}
      showExecutionHistoryView={showExecutionHistoryView}
    />
  );
};

export default TestViewDrawerContainer;
