import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "../../main/schemas";
import { PropertyEditorActions } from "../actions/propertyEditor";
import { SchemaWizardSelectors } from "../selectors/schemaWizard";
import { PropertyEditorSelectors } from "../selectors/propertyEditor";
import { Actions, Model, PropertyEditorDialog } from "../components/PropertyEditorDialog";
import PropertyEditor from "./PropertyEditor";

const PropertyEditorDialogContainer = (props: Model & Actions) => {

  const { open = false } = props;

  if (!open) {
    return null;
  }

  return (
    <PropertyEditorDialog {...props}>
      <PropertyEditor />
    </PropertyEditorDialog>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  className: PropertyEditorSelectors.getDialogClassName(state),
  maxWidth: PropertyEditorSelectors.getDialogMaxWidth(state),
  title: PropertyEditorSelectors.getDialogTitle(state),
  loading: PropertyEditorSelectors.isProgressIndicatorVisible(state),
  continueButtonLabel: PropertyEditorSelectors.getSaveButtonLabel(state),
  continueButtonDisabled: !PropertyEditorSelectors.isSaveButtonEnabled(state),
  item: PropertyEditorSelectors.getProperty(state),
  open: SchemaWizardSelectors.isPropertyEditorVisible(state) &&
    !SchemaWizardSelectors.isRemovePropertyVisible(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  cancel: () => dispatch(PropertyEditorActions.close()),
  confirm: () => dispatch(PropertyEditorActions.save()),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyEditorDialogContainer);
