import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { equalsIgnoreCase, isEmptyString } from "@util";
import { DeviceTypeWizardActions } from "../actions";
import { getCurrentAccountId } from "@main/selectors";
import { SchemasList, JsonDialog } from "@components";
import { useSchemas, useSchemaDefinition } from "@hooks";
import { DeviceTypeWizardSelectors } from "../selectors";
import { JsonSchemaDefinition, JsonSchemaMetadata, JsonSchemaState } from "@data";
import SchemasView, { Actions, Model } from "../components/SchemasView";

interface ContainerModel extends Model {
  hidden?: boolean;
  accountId?: string;
  selectedSchemaIds?: string[];
}

interface ContainerActions extends Actions {
  setSelectedSchema?: (schemas: JsonSchemaMetadata[]) => void;
}

const SchemasViewContainer = (props: ContainerModel & ContainerActions) => {

  const {
    hidden,
    accountId = "",
    selectedSchemaIds = [],
    setSelectedSchema,
    ...otherProps
  } = props;

  const initialSchemas = React.useMemo(() => Array.from(new Set(selectedSchemaIds))
    .map(id => JsonSchemaMetadata.fromNameAndVersion(id)), [selectedSchemaIds]);

  const [ model, api ] = useSchemas({ initialSchemas });

  const {
    nameFilter,
    errorMessage,
    searchFilters,
    schemas: items,
    showProgressIndicator,
  } = model;

  const schemas = React.useMemo(() => {
    const missingItems = initialSchemas
      .filter(initialSchema => {
        const schemaId = initialSchema.getId();
        return !items.some(schema => equalsIgnoreCase(schemaId, schema.getId()));
      });

    return missingItems
      .concat(items.slice())
      .filter(schema => schema.state !== JsonSchemaState.DECOMMISSIONED);

    }, [selectedSchemaIds, items]);

  const selectedItems = React.useMemo(() =>
    schemas.filter(schema => selectedSchemaIds.indexOf(schema.getId()) >= 0),
    [schemas, selectedSchemaIds]);

  const showNoResultsView = React.useMemo(() =>
    schemas.length === 0 && isEmptyString(errorMessage) && !showProgressIndicator,
    [schemas, errorMessage, showProgressIndicator]);

  const noResultsLabel = React.useMemo(() => {
      if (isEmptyString(nameFilter) && searchFilters.length === 0) {
        return `No schemas found in account ${accountId}`;
      } else {
        return "No schemas found";
      }
    }, [nameFilter, searchFilters, accountId]);

  const [schemaDetailWindow, setSchemaDetailWindow ] = React.useState(false);
  const [schemaIdentity, setSchemaIdentity] = React.useState("");
  const [schemaJson, setSchemaJson] = React.useState("");
  const [schemaDetailError, setSchemaDetailError] = React.useState("");

  const namespace = schemaIdentity.split(":")[0] || "";
  const name = schemaIdentity.split(":")[1] || "";
  const version = schemaIdentity.split(":")[2] || "";

  const onSuccess = React.useCallback((schemaDefinition: JsonSchemaDefinition) => {
    setSchemaJson(JSON.stringify(schemaDefinition, null, "  "));
    setSchemaDetailWindow(true);
  }, [setSchemaJson, setSchemaDetailWindow]);

  const onFailure = React.useCallback((error: string) => {
    setSchemaDetailError(error);
    setSchemaDetailWindow(true);
  }, [setSchemaDetailError]);

  const [ schemaDefModel ] = useSchemaDefinition({
    namespace,
    name,
    version,
    onSuccess,
    onFailure,
  });

  const {
    showProgressIndicator: loading,
  } = schemaDefModel;

  const onClickShowMoreInfo = React.useCallback((schema: JsonSchemaMetadata) => {
      setSchemaIdentity(schema.getId());
  }, [setSchemaIdentity]);

  const closeSchemaDetailDialog = React.useCallback(() => {
    setSchemaIdentity("");
    setSchemaDetailWindow(false);
  }, [setSchemaDetailWindow, setSchemaIdentity]);

  if (hidden) {
    return null;
  }

  return (
    <SchemasView
      {...otherProps}
      showBackDrop={loading}
    >
      <SchemasList
        {...model}
        {...api}
        schemas={schemas}
        hideSummary={true}
        hideSearch={false}
        showSearchFilters={true}
        selectable={true}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedSchema}
        showNoResultsView={showNoResultsView}
        noResultsLabel={noResultsLabel}
        showMoreInfoLabel="Schema Details"
        onClickShowMoreInfo={onClickShowMoreInfo}
      />
      <JsonDialog
        className="deviceTypeWizardSchemaDetailsDialog"
        open={schemaDetailWindow}
        cancel={closeSchemaDetailDialog}
        errorMessage={schemaDetailError}
        schema={schemaJson}
        jsonTitle={schemaIdentity}
      />
    </SchemasView>
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
  hidden: !DeviceTypeWizardSelectors.isSchemasViewSelected(state),
  accountId: getCurrentAccountId(state),
  selectedSchemaIds: DeviceTypeWizardSelectors.getSchemas(state),
});

const mapDispatchToProps = (dispatch: any): ContainerActions => ({
  setSelectedSchema: (schemas: JsonSchemaMetadata[]) => dispatch(
    DeviceTypeWizardActions.updateSchemas((schemas.map((schema: JsonSchemaMetadata) =>
      `${schema.namespace}:${schema.name}:${schema.version}`)))),
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps
)(SchemasViewContainer);
