import { isEmptyString } from "@util";
import { AdminNoteAttributes } from "@data";
import {
  createQueryParams,
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments
} from "@network/helpers";

const USER_IDM_ADMINISTRATION_URL = process.env.REACT_APP_USER_IDM_ADMINISTRATION || "";

if (isEmptyString(USER_IDM_ADMINISTRATION_URL)) {
  throw new Error("Missing Environment Variable: REACT_APP_USER_IDM_ADMINISTRATION");
}

export interface GetAdminNotesResponse {
  notes: AdminNoteAttributes[];
  paging?: {
    next?: string
  };
}

export const createAdminNote = (authToken?: string,
                                userId?: string,
                                json?: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["Note", json],
    ]));

  const makeRequest = () => {

    const url = `${USER_IDM_ADMINISTRATION_URL}/security/identity/v1/users/${userId}/administration/notes`;

    // TODO: We need the fallback url currently for non-ali-cloud envs, but eventually only above url will work
    //       Reference Material
    //        - https://www.tracker.dtf.signify.com/browse/IOTSEC-3738
    //        - https://www.collaboration.dtf.signify.com/display/IOTPLAT/
    //            IoT+Security+APIs+-+Regional+-+v1+-+User+Identities+-+User+Administration
    const fallbackUrl = `${USER_IDM_ADMINISTRATION_URL}/security/identity/v1/administration/users/${userId}/notes`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to add admin note";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage)
      .catch(error => {

        const { status = 500 } = error || {};

        // If a valid error was encountered, return the error
        if (status !== 500 && status !== 404) {
          return Promise.reject(error);
        }

        // Otherwise, try using the fallback url
        return makeApiRequestAndComplete(fallbackUrl, settings, defaultErrorMessage);
      });
  };

  return validate().then(makeRequest);
};

export const getAdminNotes = (authToken?: string,
                              userId?: string,
                              next: string = ""): Promise<GetAdminNotesResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({ next });

    const url = USER_IDM_ADMINISTRATION_URL +
      `/security/identity/v1/users/${userId}/administration/notes${queryParams}`;

    // TODO: We need the fallback url currently for non-ali-cloud envs, but eventually only above url will work
    //       Reference Material
    //        - https://www.tracker.dtf.signify.com/browse/IOTSEC-3738
    //        - https://www.collaboration.dtf.signify.com/display/IOTPLAT/
    //            IoT+Security+APIs+-+Regional+-+v1+-+User+Identities+-+User+Administration
    const fallbackUrl = USER_IDM_ADMINISTRATION_URL +
      `/security/identity/v1/administration/users/${userId}/notes${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to fetch admin notes";

    return makeJsonApiRequest(url, settings, defaultErrorMessage)
      .catch(error => {

        const { status = 500 } = error || {};

        // If a valid error was encountered, return the error
        if (status !== 500 && status !== 404) {
          return Promise.reject(error);
        }

        // Otherwise, try using the fallback url
        return makeJsonApiRequest(fallbackUrl, settings, defaultErrorMessage);
      });
  };

  return validate().then(makeRequest);
};
