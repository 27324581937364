import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";

export interface Model {
  className?: string;
  visible?: boolean;
  children?: React.ReactNode;
}

export interface Actions {

}

type Props = WithStyles<typeof styles> & Model & Actions;

export const ConstraintsEditor = withStyles(styles)((props: Props) => {

  const { classes, className, visible, children } = props;

  if (!visible) {
    return null;
  }

  return (
    <div className={classnames("constraintsEditor", className, classes.container)}>
      {children}
    </div>
  );
});

export default ConstraintsEditor;
