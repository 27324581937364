import { AppSchema } from "@main/schemas";
import { isEmptyString } from "@util";
import { getAuthToken } from "@main/selectors";
import { createActions } from "@modules/base/createActions";
import {
  Device,
  DeviceAttributes,
  DeviceConfiguration,
  DeviceConfigurationAttributes,
  DeviceDataModel,
  DeviceDataScope
} from "@data";
import { DeviceEnrollmentClient, DeviceRegistryClient, GetDeviceHistoryResponse, RestClientError } from "@network";
import { open as openDisenrollDeviceDialog } from "@modules/disenrollDevice/actions";
import {
  ACTION_TYPES,
  DEFAULT_STATE,
  DeviceConfigurationDataMode,
} from "./reducers";
import {
  getDeviceId,
  getDevice,
  getItems,
  getNextPage,
  isSortOrderAscending,
  getDataMode,
  getDeviceRequests,
} from "./selectors";
import { DeviceDataType } from "@components";
import {
  openDeviceEnrollmentForReEnrollment as openDeviceEnrollmentWizard,
  setReEnrollmentBatchId,
} from "@modules/deviceEnrollmentWizard/actions/deviceEnrollmentWizard";

export const {
  device: setDevice,
  deviceId: setDeviceId,
  deviceDataViewMode: setDeviceDataViewMode,
  deviceScopeMode: setDeviceScopeMode,
  items: setItemAttributes,
  nextPage: setNextPage,
  searchQuery: setSearchQuery,
  sortedColumn: setSortedColumn,
  sortOrderAscending: setSortOrderAscending,
  deviceConfiguration: setDeviceConfiguration,
  dataMode: setDataMode,
  configurationErrorMessage: setConfigurationErrorMessage,
  deviceJson: setDeviceJson,
  selectedTab: setSelectedTab,
  deleteDataDialog: setDeleteDataDialog,
  deviceDataEditMode: setDeviceDataEditMode,
  enrollmentLoadingIndicator: setEnrollmentLoadingIndicator,
  historyLoadingIndicator: setHistoryLoadingIndicator,
  configurationLoadingIndicator: setConfigurationLoadingIndicator,
  configRefresh: setConfigRefresh,
  deviceTypeIdentity: setDeviceTypeIdentity,
  deviceRequests: setDeviceRequests,
  setErrorMessage,
  setSuccessMessage,
  setShowLoadingIndicator,
  showNotFound,
  hideNotFound,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  FETCH_DEVICE_DETAILS_REQUEST: fetchDeviceDetailsRequest,
  FETCH_DEVICE_DETAILS_SUCCESS: fetchDeviceDetailsSuccess,
  FETCH_DEVICE_DETAILS_FAILURE: fetchDeviceDetailsFailure,
  FETCH_DEVICE_ENROLLMENT_HISTORY_REQUEST: fetchDeviceEnrollmentHistoryRequest,
  FETCH_DEVICE_ENROLLMENT_HISTORY_SUCCESS: fetchDeviceEnrollmentHistorySuccess,
  FETCH_DEVICE_ENROLLMENT_HISTORY_FAILURE: fetchDeviceEnrollmentHistoryFailed,
  FETCH_DEVICE_CONFIGURATION_REQUEST: fetchDeviceConfigurationRequest,
  FETCH_DEVICE_CONFIGURATION_SUCCESS: fetchDeviceConfigurationSuccess,
  FETCH_DEVICE_CONFIGURATION_FAILURE: fetchDeviceConfigurationFailure,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setDevice());
  dispatch(setDeviceDataViewMode());
  dispatch(setDeviceScopeMode());
  dispatch(setItemAttributes());
  dispatch(setNextPage());
  dispatch(setSearchQuery());
  dispatch(setSortedColumn());
  dispatch(setSortOrderAscending());
  dispatch(setDeviceConfiguration());
  dispatch(setDataMode());
  dispatch(setConfigurationErrorMessage());
  dispatch(setDeviceJson());
  dispatch(setSelectedTab());
  dispatch(setDeleteDataDialog());
  dispatch(setDeviceDataEditMode());
  dispatch(setEnrollmentLoadingIndicator());
  dispatch(setHistoryLoadingIndicator());
  dispatch(setConfigurationLoadingIndicator());
  dispatch(setConfigRefresh());
  dispatch(setDeviceTypeIdentity());
  return dispatch(baseReset());
};

export const showActualView = () => setDeviceDataViewMode(DeviceDataType.ACTUAL);
export const showDesiredView = () => setDeviceDataViewMode(DeviceDataType.DESIRED);
export const showMetadataView = () => setDeviceDataViewMode(DeviceDataType.METADATA);

export const showDeviceScopeView = () => setDeviceScopeMode(DeviceDataScope.DEVICE);
export const showTypeScopeView = () => setDeviceScopeMode(DeviceDataScope.TYPE);
export const showRegionScopeView = () => setDeviceScopeMode(DeviceDataScope.REGION);

export const disenrollDevice = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(openDisenrollDeviceDialog(getDeviceId(getState())));

const fetchDeviceDetails = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const deviceId = getDeviceId(state);
  const authToken = getAuthToken(state);

  dispatch(setEnrollmentLoadingIndicator(true));
  dispatch(showEmptyView());
  dispatch(setErrorMessage());
  dispatch(hideAccessDenied());
  dispatch(hideNotFound());
  dispatch(fetchDeviceDetailsRequest());

  return DeviceEnrollmentClient.getDeviceStatus(authToken, deviceId)
    .then((attrs: DeviceAttributes) => {

      dispatch(fetchDeviceDetailsSuccess());
      dispatch(setDeviceJson(JSON.stringify(attrs, null, "  ")));
      if (isEmptyString(attrs.deviceReference)) {
        attrs.deviceId = deviceId;
      }
      dispatch(setDevice(new Device(attrs).toJS()));
      dispatch(getDeviceEnrollmentHistory());
      dispatch(setEnrollmentLoadingIndicator(false));
      return dispatch(hideEmptyView());

    }, (response: RestClientError) => {

      const { analytic, status, error = "Fetch device details failed" } = response;

      dispatch(fetchDeviceDetailsFailure(analytic));
      dispatch(setErrorMessage(error));
      dispatch(setEnrollmentLoadingIndicator(false));

      if (status === 404) {
        dispatch(showNotFound());
      }

      return dispatch(hideEmptyView());
    });
};

export const fetchDeviceConfiguration = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const deviceId = getDeviceId(state);
  const includeData = getDataMode(state);
  const authToken = getAuthToken(state);

  dispatch(setConfigurationLoadingIndicator(true));
  dispatch(showEmptyView());
  dispatch(setConfigurationErrorMessage());
  dispatch(hideAccessDenied());
  dispatch(fetchDeviceConfigurationRequest());

  return DeviceRegistryClient.getDeviceConfiguration(authToken, deviceId, includeData)
    .then((attrs: DeviceConfigurationAttributes) => {

      dispatch(fetchDeviceConfigurationSuccess());
      dispatch(setDeviceConfiguration( new DeviceConfiguration(attrs)));
      dispatch(setDeviceTypeIdentity(attrs.typeIdentity));
      dispatch(setConfigurationLoadingIndicator(false));
      return dispatch(hideEmptyView());
    }, (response: RestClientError) => {

      const { analytic, status, error = "Fetch device configuration failed" } = response;

      dispatch(fetchDeviceConfigurationFailure(analytic));
      dispatch(setConfigurationErrorMessage(error));
      dispatch(setConfigurationLoadingIndicator(false));

      if (status === 404) {
        dispatch(setConfigurationErrorMessage("Device should be enrolled to see data"));
      }

      return dispatch(hideEmptyView());
    });

};

export const refresh = () => (dispatch: any) => {
  dispatch(clearItems());
  dispatch(fetchDeviceConfiguration());
  dispatch(setConfigRefresh(true));
  return dispatch(fetchDeviceDetails());
};

export const toggleSortOrder = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(setSortOrderAscending(!isSortOrderAscending(getState())));

export const setItems = (deviceEnrollments: Device[]) => (dispatch: any) =>
  dispatch(setItemAttributes(deviceEnrollments.map(deviceEnrollment => deviceEnrollment.toJS())));

export const addItems = (deviceEnrollments: Device[]) =>
  (dispatch: any, getState: () => AppSchema) =>
    dispatch(setItems(getItems(getState()).concat(deviceEnrollments)));

export const clearItems = () => setItemAttributes();

export const getDeviceEnrollmentHistory = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const authToken = getAuthToken(state);
  const deviceId = getDeviceId(state);
  const nextPageToken = getNextPage(state);
  const isFirstPage = isEmptyString(nextPageToken);

  dispatch(setHistoryLoadingIndicator(true));
  dispatch(showEmptyView());
  dispatch(setErrorMessage());
  dispatch(hideAccessDenied());
  dispatch(fetchDeviceEnrollmentHistoryRequest());

  if (isFirstPage) {
    dispatch(clearItems());
  }

  return DeviceEnrollmentClient.getDeviceHistoryDetails(authToken, deviceId, nextPageToken)
    .then((response: GetDeviceHistoryResponse) => {

      const { history = [] , paging: { next: nextPage = "" } = {} } = response;

      const items = history.map((attrs: DeviceAttributes) =>
        new Device(attrs));

      dispatch(fetchDeviceEnrollmentHistorySuccess());
      dispatch(addItems(items));
      dispatch(setNextPage(nextPage || ""));
      dispatch(setHistoryLoadingIndicator(false));
      return dispatch(hideEmptyView());

    }, (response: RestClientError) => {

      const { analytic, error = "Fetch device enrollment history failed" } = response;

      dispatch(fetchDeviceEnrollmentHistoryFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(setHistoryLoadingIndicator(false));

      return dispatch(hideEmptyView());
    });
};

export const reloadHistory = () => (dispatch: any) => {
  dispatch(clearItems());
  dispatch(setSortOrderAscending());
  dispatch(setSortedColumn());
  return dispatch(getDeviceEnrollmentHistory());
};

export const updateDataMode = (mode: DeviceConfigurationDataMode) => (dispatch: any) => {
  dispatch(setDataMode(mode));
  return dispatch(fetchDeviceConfiguration());
};

export const openDeleteDataDialog = () => (dispatch: any) => {
  return dispatch(setDeleteDataDialog(true));
};

export const closeDeleteDataDialog = () => (dispatch: any) => {
  return dispatch(setDeleteDataDialog(false));
};

export const initialize = (deviceId: string) => (dispatch: any) => {
  dispatch(reset());
  dispatch(setDeviceId(deviceId));
  return dispatch(refresh());
};

export const openDeviceEnrollment = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const deviceRequestList = getDeviceRequests(getState());
  const deviceId = getDeviceId(state);
  const deviceRequestPayload = deviceRequestList.filter(devices => devices.getDeviceId() === deviceId);
  const device = deviceRequestPayload.length === 1 ? deviceRequestPayload[0] : DeviceDataModel.EMPTY;
  const batchId = getDevice(state).getBatchId();
  if (isEmptyString(device.getDeviceId())) {
    dispatch(setReEnrollmentBatchId(batchId));
    return dispatch(openDeviceEnrollmentWizard(new DeviceDataModel({
      deviceId
    })));
  }
  dispatch(setReEnrollmentBatchId(batchId));
  return dispatch(openDeviceEnrollmentWizard(device));
};
