import { isEmptyString, isValidInteger } from "@util";
import {
  createQueryParams,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments,
} from "@network/helpers";
import { QualityReportAttributes, QualityReportPlatformDomainId } from "@data/QualityReport";

const QUALITY_REPORTS_API = process.env.REACT_APP_PORTAL_QUALITY_REPORTS_API || "";

if (isEmptyString(QUALITY_REPORTS_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_PORTAL_QUALITY_REPORTS_API");
}

export interface ListQualityReportsResponse {
  reports: QualityReportAttributes[];
  paging?: {
    next?: string;
    totalNumItems?: number;
  };
}

export const listQualityReports = (authToken: string,
                                   createdBefore: string = "",
                                   createdAfter: string = "",
                                   next: string = "",
                                   limit: number = 0): Promise<ListQualityReportsResponse> => {

  const validate = () => withAuthToken(authToken);

  const queryParams = createQueryParams({
    createdBefore,
    createdAfter,
    next,
    ...(!isValidInteger(limit) || limit <= 0 ? {} : { limit }),
  });

  const makeRequest = () => {

    const url = `${QUALITY_REPORTS_API}/reports/${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch quality reports failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const listPlatformDomainQualityReports = (authToken: string,
                                                 platformDomainId: QualityReportPlatformDomainId,
                                                 createdBefore: string = "",
                                                 createdAfter: string = "",
                                                 next: string = "",
                                                 limit: number = 0): Promise<ListQualityReportsResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Platform Domain ID", platformDomainId],
    ]));

  const queryParams = createQueryParams({
    createdBefore,
    createdAfter,
    next,
    ...(!isValidInteger(limit) || limit <= 0 ? {} : { limit }),
  });

  const makeRequest = () => {

    const url = `${QUALITY_REPORTS_API}/platform-domain/${platformDomainId}/reports/${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch quality reports failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const listPlatformDomainGroupQualityReports = (authToken: string,
                                                      platformDomainId: QualityReportPlatformDomainId,
                                                      groupId: string,
                                                      createdBefore: string = "",
                                                      createdAfter: string = "",
                                                      next: string = "",
                                                      limit: number = 0): Promise<ListQualityReportsResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Platform Domain ID", platformDomainId],
      ["Group ID", groupId],
    ]));

  const queryParams = createQueryParams({
    createdBefore,
    createdAfter,
    next,
    ...(!isValidInteger(limit) || limit <= 0 ? {} : { limit }),
  });

  const makeRequest = () => {

    const url = `${QUALITY_REPORTS_API}/platform-domain/${platformDomainId}/group/${groupId}/reports/${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch quality reports failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getQualityReport = (authToken: string,
                                 platformDomainId: QualityReportPlatformDomainId,
                                 groupId: string,
                                 version: string = "latest"): Promise<QualityReportAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Platform Domain ID", platformDomainId],
      ["Group ID", groupId],
      ["Version", version],
    ]));

  const makeRequest = () => {

    const url = `${QUALITY_REPORTS_API}/platform-domain/${platformDomainId}/group/${groupId}/v/${version}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch quality report failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
