import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { isEmptyString } from "../../util";
import { styles } from "./styles";
import {
  SecurityGroupsList,
  SecurityGroupsListActions,
  SecurityGroupsListModel,
} from "../security-groups-list";

export interface SecurityGroupsModel extends SecurityGroupsListModel {
  className?: string;
  title?: string;
  errorTitle?: string;
  loadingTitle?: string;
}

export interface SecurityGroupsActions extends SecurityGroupsListActions {
}

type Props = WithStyles<typeof styles> & SecurityGroupsModel & SecurityGroupsActions;

export const SecurityGroups = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title: defaultTitle = "Security groups",
    errorTitle = "Failed to load security groups",
    loadingTitle = "Loading security groups...",
    loadingLabel= "Fetching security groups...",
    noResultsLabel: defaultNoResultsLabel = "No security groups found",
    nameFilter,
    items = [],
    showLoadingIndicator,
    errorMessage,
    ...otherProps
  } = props;

  const showErrorView = React.useMemo(() =>
    !isEmptyString(errorMessage), [errorMessage]);

  const title = React.useMemo(() => {
    if (showErrorView) {
      return errorTitle;
    } else if (showLoadingIndicator) {
      return loadingTitle;
    } else {
      return defaultTitle;
    }
  }, [showErrorView, showLoadingIndicator, errorTitle, loadingTitle, defaultTitle]);

  const noResultsLabel = React.useMemo(() => {
    if (isEmptyString(nameFilter)) {
      return defaultNoResultsLabel;
    } else {
      return "No Results";
    }
  }, [nameFilter, defaultNoResultsLabel]);

  return (
    <div className={classnames("securityGroups", className, classes.container)}>
      <h1 className={classnames("title", classes.title, {[classes.error]: showErrorView})}>
        {title}
      </h1>
      <SecurityGroupsList
        {...otherProps}
        items={items}
        nameFilter={nameFilter}
        loadingLabel={loadingLabel}
        errorMessage={errorMessage}
        noResultsLabel={noResultsLabel}
        showLoadingIndicator={showLoadingIndicator}
      />
    </div>
  );
});

export default SecurityGroups;
