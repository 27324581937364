import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { SecurityServiceRegional } from "@data";
import { isEmptyString, noop } from "@util";
import classnames from "classnames";
import { useServiceSecretManagement, ServiceSecretOperation } from "@hooks";
import { TextFieldStyled } from "@components/text-field-styled";
import { SecretDialog } from "@components";

export interface ServiceSecretOperationDialogModel extends PortalModuleDialogModel<SecurityServiceRegional> {
  dialogClassName?: string;
  serviceId?: string;
  operation: ServiceSecretOperation;
}

export interface ServiceSecretOperationDialogActions extends PortalModuleDialogActions<SecurityServiceRegional> {
}

type Props = WithStyles<typeof styles> &
  ServiceSecretOperationDialogModel & ServiceSecretOperationDialogActions;

const BODY_TEXT = "This is the ONLY time the service secret can be viewed. " +
  "You cannot retrieve this secret later; however, you can generate a new secret at any time.";

export const ServiceSecretOperationDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    dialogClassName,
    serviceId = "",
    operation = ServiceSecretOperation.NONE,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  if (isEmptyString(serviceId) || operation === ServiceSecretOperation.NONE) {
    return null;
  }

  const [secret, setSecret] = React.useState("");
  const [openSecretsDialog, setOpenSecretsDialog] = React.useState(false);

  const isDelete = React.useMemo(() => operation === ServiceSecretOperation.DELETE,
    [operation]);

  const isGenerate = React.useMemo(() => operation === ServiceSecretOperation.GENERATE,
    [operation]);

  const isSet = React.useMemo(() => operation === ServiceSecretOperation.SET,
    [operation]);

  const title = React.useMemo(() =>
      `${operation} Service Secret`,
    [operation]);

  const continueButtonDisabled = React.useMemo(() => isSet ? secret.length < 8 : false, [secret, isSet]);

  const [model, actions] = useServiceSecretManagement({
    serviceId,
    secret,
    operation
  });

  const {
    showLoadingIndicator,
    service,
    successMessage,
    errorMessage,
    ...otherModel
  } = model;
  const {
    performOperation,
    ...otherActions
  } = actions;

  const confirm = React.useCallback(() => {
    performOperation();
  }, [performOperation]);

  const generatedSecret = React.useMemo(() => service.getPrimarySecret(), [service]);

  const onSuccessOperation = React.useCallback(() => {
    if (isGenerate) {
      setOpenSecretsDialog(true);
    } else {
      onSuccess();
    }
  }, [setOpenSecretsDialog, onSuccess]);

  const closeSecretDialog = React.useCallback(() => {
    setOpenSecretsDialog(false);
    onSuccess();
  }, [setOpenSecretsDialog, onSuccess]);

  return (
    <React.Fragment>
      <PortalModuleDialog
        {...otherProps}
        {...otherModel}
        {...otherActions}
        className={classnames("serviceSecretOperationDialog", dialogClassName)}
        title={title}
        loading={showLoadingIndicator}
        continueButtonLabel={operation}
        confirm={confirm}
        continueButtonDisabled={continueButtonDisabled}
        onSuccessMessageShown={onSuccessOperation}
        successMessage={successMessage}
        errorMessage={errorMessage}
      >
        <div className={classnames("serviceSecretOperation", classes.container)}>
          <React.Fragment>
            {isGenerate && (
              <div className={classnames("generateSecret", classes.informationContainer)}>
                <label className={classnames("title", classes.title)}>
                  Are you sure you want to generate new service secret?
                </label>
                <label className={classnames("subtitle", classes.subtitle)}>
                  <span className={classes.warning}>WARNING:
                  </span>This will expire the old secret and generate a new one!
                </label>
                <label className={classnames("serviceId", classes.serviceId)}>
                  {serviceId}
                </label>
              </div>
            )}
            {isDelete && (
              <div className={classnames("deleteSecret", classes.informationContainer)}>
                <label className={classnames("title", classes.title)}>
                  Are you sure you want to delete old service secret?
                </label>
                <label className={classnames("subtitle", classes.subtitle)}>
                  This will delete the old (secondary) secret!
                </label>
                <label className={classnames("serviceId", classes.serviceId)}>
                  {serviceId}
                </label>
              </div>
            )}
            {isSet && (
              <div className={classnames("setSecret", classes.setSecretContainer)}>
                <label className={classnames("title", classes.title, classes.setTitle)}>
                  Set new service secret
                </label>
                <TextFieldStyled
                  className={classnames("serviceSecretTextField", classes.serviceSecretTextField)}
                  name={"secret"}
                  label="Secret"
                  value={secret}
                  fullWidth={true}
                  autoFocus={true}
                  placeholder={"Set Service Secret"}
                  helperText={"Min length 8 characters. Must contain small letter, capital letter, number and special character"}
                  setValue={setSecret}
                />
              </div>
            )}
          </React.Fragment>
        </div>
      </PortalModuleDialog>
      <SecretDialog
        className="serviceSecretDialog"
        open={openSecretsDialog}
        title="Service Secret"
        bodyText={BODY_TEXT}
        secretFieldLabel="Service Secret"
        secret={generatedSecret}
        fileName={serviceId}
        closeDialog={closeSecretDialog}
      />
    </React.Fragment>
  );
});

export default ServiceSecretOperationDialog;
