import { Record } from "immutable";
import { getStringValue } from "@util";
import { TriggerAttributes, TriggerType } from "@data";

export type TriggerStorage = {
  bucketName: string;
  key: string;
  size?: number;
};

export enum ExecutionScope {
  WORKLOAD = "WORKLOAD",
  WORKFLOW = "WORKFLOW",
}

export class ExecuteWorkloadRequest extends Record({
  eventType: TriggerType.NONE,
  "@type": TriggerType.NONE,
  type: TriggerType.NONE,
  eventDatetime: "",
  scheduleId: "",
  dataSetAlias: "",
  triggerStorage: {},
  precedingWorkloadName: "",
  accountId: "",
  listFile: [""],
  executionScope: ExecutionScope.WORKLOAD,
}) implements TriggerAttributes {

  public static EMPTY: ExecuteWorkloadRequest = new ExecuteWorkloadRequest();

  public readonly eventType: TriggerType;
  public readonly "@type": TriggerType;
  public readonly type: TriggerType;
  public readonly eventDatetime: string;
  public readonly scheduleId: string;
  public readonly triggerStorage: TriggerStorage;
  public readonly precedingWorkloadName: string;
  public readonly accountId: string;
  public readonly dataSetAlias: string;
  public readonly listFile: string[];
  public readonly executionScope: ExecutionScope;

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getBucketName(): string {
    const storage = this.triggerStorage;
    return !this.triggerStorage ? "" : getStringValue(storage.bucketName);
  }

  public getKey(): string {
    const storage = this.triggerStorage;
    return !this.triggerStorage ? "" : getStringValue(storage.key);
  }

  public getSize(): number {
    const storage = this.triggerStorage;
    return !this.triggerStorage || !storage.size ? 0 : storage.size;
  }

  public getType(): TriggerType {
    return this.type;
  }

  public getPrecedingWorkloadName(): string {
    return getStringValue(this.precedingWorkloadName);
  }

  public getDataSetAlias(): string {
    return getStringValue(this.dataSetAlias);
  }

  public getExecutionScope(): ExecutionScope {
    return this.executionScope;
  }
}

export default ExecuteWorkloadRequest;
