import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { DeviceTypesList } from "@components";
import { useDeviceTypes, useDeviceType } from "@hooks";
import {
  DeviceEnrollmentWizardSelectors
} from "../../selectors";
import {
  DeviceTypeListItem,
  DeviceTypeModelV3,
  DeviceTypeModelV3Attributes,
  DeviceTypeModelVersion,
  JsonSchemaMetadata,
  JsonSchemaMetadataAttributes
} from "@data";
import { equalsIgnoreCase, isEmptyString, noop } from "@util";
import DeviceTypesView , { Actions, Model } from "../../components/addDeviceWizard/DeviceTypeView";
import DeviceTypeInfoView from "./DeviceTypeInfoView";
import { AddDeviceWizardActions, DeviceEnrollmentWizardActions } from "../../actions";

interface ContainerModel extends Model {
  hidden?: boolean;
  accountId?: string;
  selectedDeviceTypeIdentity?: string;
  isLoadingIndicator?: boolean;
}

interface ContainerActions extends Actions {
  updateDeviceType?: (deviceType?: DeviceTypeListItem) => void;
  addAvailableSchemas?: (items: JsonSchemaMetadataAttributes[]) => void;
  clearCredentials?: () => void;
  setAvailableSchemas?: () => void;
  addCredential?: (name: string, value: string) => void;
  setDeviceTypeAttributes?: (attrs: DeviceTypeModelV3Attributes) => void;
  setErrorMessage?: (message: string) => void;
}

const DeviceTypesViewContainer = (props: ContainerModel & ContainerActions) => {

  const {
    hidden,
    accountId = "",
    selectedDeviceTypeIdentity = "",
    isLoadingIndicator = false,
    updateDeviceType = noop,
    addAvailableSchemas = noop,
    clearCredentials = noop,
    setAvailableSchemas = noop,
    addCredential = noop,
    setDeviceTypeAttributes = noop,
    setErrorMessage = noop,
    ...otherProps
  } = props;

  const [identity, setIdentity] = React.useState("");

  const namespace = identity.split(":")[0] || "";
  const name = identity.split(":")[1] || "";
  const version = identity.split(":")[2] || "";

  const [ model, api ] = useDeviceTypes();
  const [ deviceTypeModel, deviceTypeAction ] = useDeviceType(namespace, name, version);

  const {
    deviceType,
    errorMessage,
    showProgressIndicator: loading,
  } = deviceTypeModel;

  const {
    refresh,
  } = deviceTypeAction;

  const {
    searchQuery,
    deviceTypes: items,
    showProgressIndicator: isLoading,
    showLoadMoreButton: isLoadMoreButtonVisible,
    showErrorView: isErrorVisible,
  } = model;

  const setSelectedItems = React.useCallback((selectedDeviceTypes: DeviceTypeListItem[]) => {
    const deviceTypeListItem: DeviceTypeListItem | undefined = selectedDeviceTypes.pop();
    updateDeviceType(deviceTypeListItem);
    if ( deviceTypeListItem !== undefined) {
      setIdentity(deviceTypeListItem.getTypeIdentity());
      setAvailableSchemas([]);
      clearCredentials();
      setDeviceTypeAttributes();
      refresh();
    }
  },
    [
      updateDeviceType,
      setIdentity,
      setAvailableSchemas,
      clearCredentials,
      setDeviceTypeAttributes,
      refresh,
    ]);

  const selectedDeviceTypeIdentities = React.useMemo(() => {
    const typeIdentity = selectedDeviceTypeIdentity;
    const selectedDeviceType = new DeviceTypeListItem({ typeIdentity });
    return selectedDeviceType.hasTypeIdentity() ? [selectedDeviceType.getTypeIdentity()] : [];
  }, [selectedDeviceTypeIdentity]);

  const searchResults = React.useMemo(() => {

    const validDeviceTypes =
      items.filter(validDeviceType => validDeviceType.getModelVersion() === DeviceTypeModelVersion.REGIONAL);

    const missingItems = selectedDeviceTypeIdentities
      .filter(deviceTypeIdentity =>
        !validDeviceTypes.some(dt => equalsIgnoreCase(deviceTypeIdentity, dt.getTypeIdentity())))
      .map(deviceTypeIdentity => {
        const typeIdentity = deviceTypeIdentity;
        return  new DeviceTypeListItem({ typeIdentity });
      });

    return missingItems.concat(validDeviceTypes);

  }, [selectedDeviceTypeIdentities, items]);

  const selectedItems = React.useMemo(() =>
      searchResults.filter(dt => selectedDeviceTypeIdentities.indexOf(dt.getTypeIdentity()) >= 0),
    [searchResults, selectedDeviceTypeIdentities]);

  const isItemSelected = React.useMemo(() =>
    selectedItems.length > 0, [selectedItems]);

  const deviceTypes = React.useMemo(() =>
      isItemSelected ? selectedItems : searchResults,
    [isItemSelected, selectedItems, searchResults]);

  const showErrorView = React.useMemo(() =>
    isErrorVisible && !isItemSelected,
    [isErrorVisible, isItemSelected]);

  const showProgressIndicator = React.useMemo(() =>
    isLoading && !isItemSelected,
    [isLoading, isItemSelected]);

  const showNoResultsView = React.useMemo(() =>
    deviceTypes.length === 0 && !isItemSelected && !showErrorView && !showProgressIndicator,
    [deviceTypes, isItemSelected, showErrorView, showProgressIndicator]);

  const noResultsLabel = React.useMemo(() => {
    if (isEmptyString(searchQuery)) {
      return `No device types found in account ${accountId}`;
    } else {
      return "No device types found";
    }
  }, [searchQuery, accountId]);

  const showLoadMoreButton = React.useMemo(() =>
    isLoadMoreButtonVisible && !isItemSelected,
    [isLoadMoreButtonVisible, isItemSelected]);

  const isDeviceTypeInfoViewVisible = React.useMemo(() =>
    selectedDeviceTypeIdentity.length > 0 , [selectedDeviceTypeIdentity]);

  const showBackDrop = React.useMemo(() =>
    isLoadingIndicator || loading, [isLoadingIndicator, loading]);

  React.useEffect(() => {
    if (!showBackDrop) {
      if (isEmptyString(errorMessage)) {
        if ( deviceType !== DeviceTypeModelV3.EMPTY) {
          setDeviceTypeAttributes(deviceType.toJS());
        }
        const schemaIdentities = deviceType.getSchemas();
        const schemas = schemaIdentities.map(schemaIdentity =>
          JsonSchemaMetadata.fromNameAndVersion(schemaIdentity).toJS());
        addAvailableSchemas(schemas);
        const credentials = deviceType.getCredentialNames();
        credentials.map(credentialName => addCredential(credentialName, ""));
      } else {
        setErrorMessage(errorMessage);
      }
    }
  }, [
    showBackDrop,
    errorMessage,
    deviceType,
    setDeviceTypeAttributes,
    addAvailableSchemas,
    clearCredentials,
    addCredential,
    setErrorMessage]);

  if (hidden) {
    return null;
  }

  return (
    <DeviceTypesView
      showBackDrop={showBackDrop}
      {...otherProps}
    >
      <DeviceTypesList
        {...model}
        {...api}
        hideSummary={true}
        selectable={true}
        selectAllDisabled={true}
        deviceTypes={deviceTypes}
        hideSearch={isItemSelected}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        noResultsLabel={noResultsLabel}
        showNoResultsView={showNoResultsView}
        showLoadMoreButton={showLoadMoreButton}
        showProgressIndicator={showProgressIndicator}
        showErrorView={showErrorView}
      />
      {isDeviceTypeInfoViewVisible && <DeviceTypeInfoView />}
    </DeviceTypesView>
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
  hidden: !DeviceEnrollmentWizardSelectors.isDeviceTypeViewSelected(state),
  accountId: DeviceEnrollmentWizardSelectors.getCurrentAccountId(state),
  selectedDeviceTypeIdentity: DeviceEnrollmentWizardSelectors.getDevice(state).getDeviceType(),
  isLoadingIndicator: DeviceEnrollmentWizardSelectors.isLoadingIndicatorVisible(state),
});

const mapDispatchToProps = (dispatch: any): ContainerActions => ({
  updateDeviceType: deviceType => dispatch(AddDeviceWizardActions.updateDeviceType(deviceType)),
  addAvailableSchemas: (items: JsonSchemaMetadataAttributes[]) =>
    dispatch(AddDeviceWizardActions.addAvailableSchemas(items)),
  setAvailableSchemas: () => dispatch(DeviceEnrollmentWizardActions.setAvailableSchemas()),
  clearCredentials: () => dispatch(AddDeviceWizardActions.clearCredentials()),
  addCredential: (name: string, value: string) => dispatch(AddDeviceWizardActions.addCredential(name, value)),
  setErrorMessage: (message: string) => dispatch(DeviceEnrollmentWizardActions.setErrorMessage(message)),
  setDeviceTypeAttributes: (attrs: DeviceTypeModelV3Attributes) =>
    dispatch(DeviceEnrollmentWizardActions.setDeviceTypeAttributes(attrs)),
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps
)(DeviceTypesViewContainer);
