import ModuleIcon from "@material-ui/icons/Create";
import { Theme } from "@material-ui/core/styles/createTheme";
import { createStyles, StyleRules } from "@material-ui/core/styles";
import {
  primaryColor as federationProviderModulePrimaryColor,
} from "@modules/federationProviders/components/styles";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = federationProviderModulePrimaryColor;

export const textField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "14px",
      fontWeight: 300,
      padding: "2px 0",
      margin: 0,
    },
    "& input": {
      fontSize: 14,
      fontWeight: 400,
      padding: "18.5px 14px",
      margin: 0,
    },
  },
};

export const clientView = (theme: Theme) =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    errorViewContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 18,
      lineHeight: "21px",
      marginTop: 8,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    textBox: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    scopeEditor: {
      marginTop: 25,
    },
  });

export const endPointsView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    errorViewContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 18,
      lineHeight: "21px",
      marginTop: 8,
    },
    inputLabel: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 18,
      lineHeight: "40px",
      marginTop: 15,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    textBox: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    radioGroup: {
    },
    radio: {
    },
  });

export const reviewView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    propertyContainer: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      justifyContent: "stretch",
      marginTop: 25,
      textAlign: "left",
    },
    propertyName: {
      flex: "0 0 100px",
      alignSelf: "flex-start",
      justifySelf: "flex-start",
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      fontSize: 16,
    },
    propertyValue: {
      flex: "1 1 auto",
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "monospace",
      fontSize: 14,
      marginLeft: 15,
      fontWeight: 500,
    },
    federationProviderContainer: {
      flex: "0 0 auto",
    },
    json: {
      backgroundColor: "#eaeaea",
      display: "block",
      whiteSpace: "pre-wrap",
      padding: 10,
      fontSize: 12,
      fontWeight: 300,
    },
  });

export const jsonEditorView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    header: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      position: "relative",
      color: primaryColor,
    },
    icon: {
      width: 40,
      height: 40,
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    controls: {
      marginTop: 16,
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    jsonEditor: {
      flex: "1 0 auto",
      marginTop: 25,
    },
    error: {
      marginTop: 25,
    },
  });

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    steps: {
      padding: "0 0 24px",
      margin: 0,
    },
    step: {
    },
    stepButton: {
    },
    stepLabel: {
    },
    title: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: 0,
      padding: 0,
    },
    error: {
      flex: "0 0 auto",
      margin: "0 0 8px 0",
    },
    actionsContainer: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: 15,
    },
    cancelButton: {
      fontWeight: 500,
      marginLeft: 0,
      color: Color.DARK_BLUE,
      fontSize: 14,
    },
    previousStepButton: {
      color: Color.BLACK,
      marginLeft: 8,
    },
    nextStepButton: {
      color: Color.WHITE,
      marginLeft: 16,
    },
    saveButton: {
      marginLeft: 16,
    },
  });

export default styles;
