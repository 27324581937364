export enum DataApprovalRequestsListColumn {
  NONE = "",
  NAME = "Data Set",
  ACCOUNT = "Account",
  STATUS = "Status",
  TYPE = "Type",
  REQUESTED_BY = "Requested By",
}

export default DataApprovalRequestsListColumn;
