import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    emptyViewLabel: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      fontStyle: "italic",
    },
  });

export default styles;
