import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { getEditSchemaPath } from "@modules/schemaWizard/helpers";
import SchemaDetails from "./containers/SchemaDetails";

export function SchemaDetailsView() {

  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const editSchema = React.useCallback(() =>
      history.push(getEditSchemaPath(id), { goBackOnSuccess: true}),
    [history, id]);

  return (
    <SchemaDetails
      key={id}
      editSchema={editSchema}
    />
  );
}

export default SchemaDetailsView;
