import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import useDigitalShadowClient from "../use-digital-shadow-client";

export interface UseDeleteDSTypeProps extends Partial<UseApiRequestProps<void>> {
  name: string;
  etag: string;
}

export interface UseDeleteDSTypeModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UseDeleteDSTypeActions extends UseApiRequestActions<void> {
  deleteType: () => void;
}

type Props = UseDeleteDSTypeProps;
type Model = UseDeleteDSTypeModel;
type Actions = UseDeleteDSTypeActions;
type Result = [ Model, Actions ];

export const useDeleteDSType = (props: Props): Result => {

  const {
    name = "",
    etag = "",
    defaultErrorMessage = "Failed to delete shadow type",
    ...otherProps
  } = props;

  const DigitalShadowClient = useDigitalShadowClient();

  const makeApiRequest = React.useCallback(() =>
      DigitalShadowClient.deleteTypeApi(name, etag),
    [DigitalShadowClient, name, etag]);

  const [{ showSuccessView, ...baseModel },
    { refresh: deleteType, ...baseActions }
  ] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Shadow Type Deleted" : "",
    [ showSuccessView ]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    etag,
    location,
    successMessage,
    showSuccessView
  }), [
    baseModel,
    etag,
    location,
    successMessage,
    showSuccessView
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    deleteType,
    refresh: deleteType,
  }), [
    baseActions,
    deleteType,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDeleteDSType;
