import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createTheme";
import Color from "../styles/Color";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    button: {
      margin: 0,
      padding: 0,
      minWidth: 0,
      textAlign: "left",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    label: {
      color: Color.GREY3,
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      textTransform: "none",
      lineHeight: "14px",
      margin: 0,
      padding: 0,
    },
    tooltipIcon: {
      marginLeft: 5,
    },
    iconButton: {
      width: 42,
      height: 42,
      padding: 0,
      margin: 4,
    },
    icon: {
      width: 32,
      height: 32,
    },
  });

export default styles;
