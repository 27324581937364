import isDataWorkloadsModuleEnabled from "@util/isDataWorkloadsModuleEnabled";
import getDataWorkloadsModuleWhitelistedAccounts from "@util/getDataWorkloadsModuleWhitelistedAccounts";
import isDataWorkloadsModuleAccountWhitelistingEnabled from "@util/isDataWorkloadsModuleAccountWhitelistingEnabled";

export const DATA_WORKLOADS_REQUIREMENTS = {
  disabled: !isDataWorkloadsModuleEnabled(),
  ...(!isDataWorkloadsModuleAccountWhitelistingEnabled() ? ({}) : ({
    whitelistedAccounts: getDataWorkloadsModuleWhitelistedAccounts(),
  })),
};

export default DATA_WORKLOADS_REQUIREMENTS;
