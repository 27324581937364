import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      fontFamily: "Roboto, sans-serif",
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "flex-start",
      minHeight: "100vh",
    },
    loadingContainer: {
      backgroundColor: Color.SIGNIFY_GREEN,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      alignContent: "center",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    loadingLabel: {
      color: Color.WHITE,
      fontFamily: "Roboto, sans-serif",
      fontSize: 48,
      fontWeight: 400,
      lineHeight: "48px",
    },
  });

export default styles;
