import React from "react";
import { DataSetRequest } from "@data";
import SummaryView from "@components/data-set-request-summary-view/DataSetRequestSummaryView";

interface SummaryViewModel {
  dataSetRequest?: DataSetRequest;
}

interface SummaryViewActions {
}

type Props = SummaryViewModel & SummaryViewActions;

const Summary = (props: Props) => {

  const { dataSetRequest = DataSetRequest.EMPTY } = props;

  return (
    <SummaryView
      dataSetAlias={dataSetRequest.getDataSetAlias()}
      description={dataSetRequest.getDescription()}
      dataSetGroup={dataSetRequest.getDataSetGroup()}
      dataSetType={dataSetRequest.getDataSetType()}
      status={dataSetRequest.getStatus()}
      dataOwners={dataSetRequest.getDataOwnersToString()}
      architects={dataSetRequest.getArchitectsToString()}
      engineeringContacts={dataSetRequest.getEngineeringContactsToString()}
      businessOwners={dataSetRequest.getBusinessOwnersToString()}
      tags={dataSetRequest.getTagsToString()}
      ingestionMechanism={dataSetRequest.getIngestionMechanism()}
      schema={dataSetRequest.getSchema()}
      sampleAnonymizedData={dataSetRequest.getSampleAnonymizedData()}
      dataClassification={dataSetRequest.getDataClassification()}
      dataOwnerApprovalStatus={dataSetRequest.getDataOwnerApprovalStatus()}
      businessOwnerApprovalStatus={dataSetRequest.getBusinessOwnerApprovalStatus()}
      dataVolume={dataSetRequest.getDataVolume()}
      dataVelocity={dataSetRequest.getDataVelocity()}
      archivingInDays={dataSetRequest.getArchivingInDays()}
      expirationInDays={dataSetRequest.getExpirationInDays()}
      confirmNonProductionData={dataSetRequest.isConfirmedNonProductionData()}
      termsConditionsId={dataSetRequest.getTermsConditionsId()}
      createdAt={dataSetRequest.getDataSetRequestedAtTime()}
      createdBy={dataSetRequest.getDataSetRequestedBy()}
      piiComment={dataSetRequest.getPiiComment()}
      containsPiiData={dataSetRequest.getContainsPII()}
    />
  );
};

export default Summary;
