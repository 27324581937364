import React from "react";
import classnames from "classnames";
import { formEventHandler } from "@util";
import { styles, textField } from "./styles";
import TextField from "@components/text-field";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

const NoteTextBox = withStyles(textField)(TextField);

export const AdminNoteTextField = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string;
  disabled?: boolean;
  note?: string;
  placeholder?: string;
  setNote?: (note: string) => void;
  children?: React.ReactNode;
}) => {

  const {
    classes,
    className = "adminNoteTextField",
    disabled,
    note,
    placeholder = "Add Note (Optional)",
    setNote,
    children,
  } = props;

  const formHelperTextProps = {
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  return (
    <div className={classnames("adminNoteTextField", className, classes.container)}>
      <NoteTextBox
        className={classnames("note", classes.inputField, classes.note)}
        disabled={disabled}
        type="text"
        autoComplete="off"
        label="Note"
        placeholder={placeholder}
        name="note"
        value={note}
        minRows={3}
        maxRows={3}
        fullWidth={true}
        multiline={true}
        autoFocus={false}
        variant="outlined"
        margin="none"
        onChange={formEventHandler(setNote)}
        FormHelperTextProps={formHelperTextProps}
        InputLabelProps={inputLabelProps}
      />
      {children}
    </div>
  );
});

export default AdminNoteTextField;
