import React from "react";
import classnames from "classnames";
import { clickHandler } from "@util";
import { CancelButton } from "@components/cancel-button";
import { SaveButton } from "@components/save-button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface Model {
  showProgressIndicator?: boolean;
  showCancelButton?: boolean;
  buttonsDisabled?: boolean;
  finished?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  cancel?: () => void;
  save?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const CreateNamespace = withStyles(styles)((props: Props) => {

  const {
    classes,
    showCancelButton,
    showProgressIndicator,
    buttonsDisabled,
    finished,
    cancel,
    save,
    children,
  } = props;

  return (
    <div className={classnames("createNamespace", classes.container)}>
      {children}
      <div className={classnames("actionsContainer", classes.actionsContainer)}>
        {showCancelButton && (
          <CancelButton
            className={classnames("cancelButton", classes.button, classes.cancelButton)}
            disabled={buttonsDisabled}
            variant="text"
            color="inherit"
            onClick={clickHandler(cancel)}
          />
        )}
        <SaveButton
          className={classnames("saveButton", classes.button, classes.saveButton)}
          disabled={buttonsDisabled}
          loading={showProgressIndicator}
          success={finished}
          save={save}
        />
      </div>
    </div>
  );
});

export default CreateNamespace;
