import React from "react";
import { WorkloadQueryType } from "@data";
import { useWorkloadManagerClient } from "@hooks";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps } from "@hooks";

type SuccessResponse = void;

export interface UseEditQueryProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  query?: string;
  tableName?: string;
  overwrite?: boolean;
  type?: WorkloadQueryType;
}

export interface UseEditQueryModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseEditQueryActions {
  editQuery: () => void;
  reset: () => void;
}

type Props = UseEditQueryProps;
type Model = UseEditQueryModel;
type Actions = UseEditQueryActions;
type Result = [Model, Actions];

export const useEditQuery = (props: Props): Result => {

  const {
    name,
    query,
    type,
    version,
    tableName,
    overwrite,
    defaultErrorMessage = "Failed to edit workload query",
    ...otherProps
  } = props;

  const {
    editWorkloadQueryType,
    editWorkloadQuery,
    editWorkloadQueryOverwrite,
    editWorkloadQueryTableName,
  } = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    new Promise<void>(async (resolve, reject) => {

      await (type ? editWorkloadQueryType(name, version, type) :
        Promise.resolve()).catch((error) => reject(error));

      await (query ? editWorkloadQuery(name, version, query) :
        Promise.resolve()).catch((error) => reject(error));

      await (tableName ? editWorkloadQueryTableName(name, version, tableName) :
        Promise.resolve()).catch((error) => reject(error));

      await (overwrite !== undefined ? editWorkloadQueryOverwrite(name, version, overwrite) :
        Promise.resolve()).catch((error) => reject(error));

      resolve();
    }), [name, version, query, type, tableName, overwrite]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: editQuery, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Edit Successful" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    editQuery,
    reset
  }), [
    editQuery,
    reset
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEditQuery;
