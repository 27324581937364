import React from "react";
import classnames from "classnames";
import { metadataView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { MetadataTableActions, MetadataTableModel, MetadataTable } from "@components";
import Typography from "@material-ui/core/Typography";

export interface Model extends MetadataTableModel {
  children?: React.ReactNode;
}

export interface Actions extends MetadataTableActions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const MetadataView = withStyles(styles)((props: Props) => {

  const {
    classes,
    items = [],
    children,
  } = props;

  if (items.length < 1) {
    return null;
  }

  return (
    <div className={classnames("metadataView", classes.container)}>
      <Typography className={classnames("title", classes.title)} variant="h4">
        Layout Metadata
      </Typography>
      <MetadataTable
        items={items}
      />
      {children}
    </div>
  );
});

export default MetadataView;
