import Ajv from "ajv";
import { AppSchema } from "../../../main/schemas";
import {
  JsonSchemaProperty,
  JsonSchemaPropertyAttributes,
  JsonSchemaPropertyDefaultValueType,
  JsonSchemaPropertyType,
} from "../../../../data";
import {
  DEFAULT_STATE,
  PropertyEditorAction,
  PropertyEditorActionType,
} from "../../reducers/propertyEditor";
import { SchemaWizardActions } from "../../actions/schemaWizard";
import { StringConstraintsActions } from "../../actions/stringConstraints";
import { NumberConstraintsActions } from "../../actions/numberConstraints";
import { IntegerConstraintsActions } from "../../actions/integerConstraints";
import { PropertyEditorSelectors } from "../../selectors/propertyEditor";
import { PropertiesTableSelectors } from "../../selectors/propertiesTable";
import { PropertiesTableActions } from "../propertiesTable";
import { getCloneName } from "@util";

const MIN_VALIDATION_TIME = 50;

export const setErrorMessage = (value: string = DEFAULT_STATE.errorMessage): PropertyEditorAction => ({
  type: PropertyEditorActionType.SET_ERROR_MESSAGE,
  value: value || "",
});

export const clearErrorMessage = (): PropertyEditorAction => setErrorMessage();

export const setOriginal =
  (value: JsonSchemaPropertyAttributes = DEFAULT_STATE.original): PropertyEditorAction => ({
    type: PropertyEditorActionType.SET_ORIGINAL,
    value,
  });

export const setName = (value: string = DEFAULT_STATE.name): PropertyEditorAction => ({
  type: PropertyEditorActionType.SET_NAME,
  value,
});

export const setNameError = (value: string = DEFAULT_STATE.nameError): PropertyEditorAction => ({
  type: PropertyEditorActionType.SET_NAME_ERROR,
  value,
});

export const clearNameError = () => (dispatch: any) => {
  dispatch(setNameError());
  return dispatch(clearErrorMessage());
};

export const setType = (value: JsonSchemaPropertyType = DEFAULT_STATE.type): PropertyEditorAction => ({
  type: PropertyEditorActionType.SET_TYPE,
  value,
});

export const setDescription = (value: string = DEFAULT_STATE.description): PropertyEditorAction => ({
  type: PropertyEditorActionType.SET_DESCRIPTION,
  value,
});

export const setRequired = (value: boolean = DEFAULT_STATE.required): PropertyEditorAction => ({
  type: PropertyEditorActionType.SET_REQUIRED,
  value,
});

export const setClonedProperty = (value: boolean = DEFAULT_STATE.clonedProperty): PropertyEditorAction => ({
  type: PropertyEditorActionType.SET_CLONED_PROPERTY,
  value,
});

export const setEnumValues =
  (value: JsonSchemaPropertyDefaultValueType[] = DEFAULT_STATE.enumValues.slice(0)): PropertyEditorAction => ({
    type: PropertyEditorActionType.SET_ENUM_VALUES,
    value: !Array.isArray(value)
      ? DEFAULT_STATE.enumValues.slice(0)
      : value.filter((enumValue: string | number) => (enumValue + "").trim().length > 0),
  });

export const setEnumValueError =
  (value: string = DEFAULT_STATE.enumValueError): PropertyEditorAction => ({
    type: PropertyEditorActionType.SET_ENUM_VALUE_ERROR,
    value,
  });

export const clearEnumValueError = () => (dispatch: any) => {
  dispatch(setEnumValueError());
  return dispatch(clearErrorMessage());
};

export const setDefaultValue =
  (value: JsonSchemaPropertyDefaultValueType = DEFAULT_STATE.defaultValue): PropertyEditorAction => ({
    type: PropertyEditorActionType.SET_DEFAULT_VALUE,
    value,
  });

export const setDefaultValueError =
  (value: string = DEFAULT_STATE.defaultValueError): PropertyEditorAction => ({
    type: PropertyEditorActionType.SET_DEFAULT_VALUE_ERROR,
    value,
  });

export const clearDefaultValueError = () => (dispatch: any) => {
  dispatch(setDefaultValueError());
  return dispatch(clearErrorMessage());
};

const toggleShowProgressIndicator =
  (value: boolean = DEFAULT_STATE.showProgressIndicator): PropertyEditorAction => ({
    type: PropertyEditorActionType.TOGGLE_SHOW_PROGRESS_INDICATOR,
    value,
  });

export const showProgressIndicator = (): PropertyEditorAction => toggleShowProgressIndicator(true);

export const hideProgressIndicator = (): PropertyEditorAction => toggleShowProgressIndicator(false);

export const validateProperty = (): PropertyEditorAction => ({
  type: PropertyEditorActionType.VALIDATE_PROPERTY,
});

export const validatePropertySuccess = (): PropertyEditorAction => ({
  type: PropertyEditorActionType.VALIDATE_PROPERTY_SUCCESS,
});

export const validatePropertyFailed = (error: string): PropertyEditorAction => ({
  type: PropertyEditorActionType.VALIDATE_PROPERTY_FAILED,
  value: error,
});

export const clearErrors = () => (dispatch: any) => {
  dispatch(setEnumValueError());
  dispatch(setDefaultValueError());
  return dispatch(setErrorMessage());
};

export const updateEnumValues = (values: JsonSchemaPropertyDefaultValueType[] = []) =>
  (dispatch: any) => {

    dispatch(clearEnumValueError());

    dispatch(setEnumValues(values));

    dispatch(StringConstraintsActions.reset());

    dispatch(NumberConstraintsActions.reset());

    return dispatch(IntegerConstraintsActions.reset());
  };

export const addEnumValue = (value: JsonSchemaPropertyDefaultValueType) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    if (typeof value === "string" && value.trim().length === 0) {
      return dispatch(setEnumValueError("Empty values not allowed"));
    }

    if (typeof value === "number" && isNaN(value)) {
      return dispatch(setEnumValueError("Illegal number value"));
    }

    const enumValues = PropertyEditorSelectors.getEnumValues(state);

    if (enumValues.indexOf(value) >= 0) {
      return dispatch(setEnumValueError("This value has already been added"));
    }

    const ajv = new Ajv;

    const type = PropertyEditorSelectors.getType(state);

    const validateEnumValue = ajv.compile({
      type,
    });

    const isEnumValueValid = validateEnumValue(value);

    if (!isEnumValueValid) {
      return dispatch(setEnumValueError(ajv.errorsText(validateEnumValue.errors)));
    }

    // Remove current default value if this is the first enum value added
    if (enumValues.length === 0) {
      dispatch(setDefaultValue());
      dispatch(clearDefaultValueError());
    }

    return dispatch(updateEnumValues(enumValues.concat(value)));
  };

export const removeEnumValue = (value: JsonSchemaPropertyDefaultValueType) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const enumValues = PropertyEditorSelectors.getEnumValues(state);

    const updatedEnumValues = enumValues
      .filter((enumValue: JsonSchemaPropertyDefaultValueType) => value !== enumValue);

    const currentDefaultValue = PropertyEditorSelectors.getDefaultValue(state);

    // Remove current default value if the enum being removed is set as the default
    if (value === currentDefaultValue) {
      dispatch(setDefaultValue());
      dispatch(clearDefaultValueError());
    }

    return dispatch(updateEnumValues(updatedEnumValues));
  };

export const updateType = (type: JsonSchemaPropertyType) =>
  (dispatch: any) => {

    dispatch(setType(type));
    dispatch(setDefaultValue());
    dispatch(setEnumValues());
    dispatch(clearErrors());
    dispatch(StringConstraintsActions.reset());
    dispatch(NumberConstraintsActions.reset());
    return dispatch(IntegerConstraintsActions.reset());
  };

export const constraintsUpdated = () => clearErrors();

export const reset = () => (dispatch: any) => {
  dispatch(setOriginal());
  dispatch(setName());
  dispatch(setNameError());
  dispatch(setType());
  dispatch(setDescription());
  dispatch(setRequired());
  dispatch(setClonedProperty());
  dispatch(setEnumValues());
  dispatch(setEnumValueError());
  dispatch(setDefaultValue());
  dispatch(setDefaultValueError());
  dispatch(setErrorMessage());
  dispatch(toggleShowProgressIndicator());
  dispatch(StringConstraintsActions.reset());
  dispatch(NumberConstraintsActions.reset());
  return dispatch(IntegerConstraintsActions.reset());
};

export const open = (property: JsonSchemaProperty = JsonSchemaProperty.EMPTY) => (dispatch: any) => {
  dispatch(reset());
  dispatch(setOriginal({ ...property.toJS() }));
  dispatch(setName(property.name));
  dispatch(setType(property.type));
  dispatch(setDescription(property.description));
  dispatch(setRequired(property.required));
  dispatch(setEnumValues(property.enumValues));
  dispatch(setDefaultValue(property.defaultValue));
  dispatch(StringConstraintsActions.setConstraints(property.getStringConstraints()));
  dispatch(NumberConstraintsActions.setConstraints(property.getNumberConstraints()));
  dispatch(IntegerConstraintsActions.setConstraints(property.getIntegerConstraints()));
  return dispatch(SchemaWizardActions.showPropertyEditor());
};

export const openCloneProperty = (property: JsonSchemaProperty = JsonSchemaProperty.EMPTY) => (dispatch: any) => {
  dispatch(reset());
  dispatch(setClonedProperty(true));
  dispatch(setOriginal({ ...property.toJS() }));
  dispatch(setName(getCloneName(property.name)));
  dispatch(setType(property.type));
  dispatch(setDescription(property.description));
  dispatch(setRequired(property.required));
  dispatch(setEnumValues(property.enumValues));
  dispatch(setDefaultValue(property.defaultValue));
  dispatch(StringConstraintsActions.setConstraints(property.getStringConstraints()));
  dispatch(NumberConstraintsActions.setConstraints(property.getNumberConstraints()));
  dispatch(IntegerConstraintsActions.setConstraints(property.getIntegerConstraints()));
  return dispatch(SchemaWizardActions.showPropertyEditor());
};

export const close = () => (dispatch: any) => {

  dispatch(SchemaWizardActions.hidePropertyEditor());

  return dispatch(reset());
};

export const add = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  if (!PropertyEditorSelectors.hasName(state)) {
    return dispatch(setNameError("Name is required"));
  }

  if (!PropertyEditorSelectors.hasUniqueName(state)) {
    return dispatch(setNameError("Name must be unique"));
  }

  const defaultValueValidation = PropertyEditorSelectors.validateDefaultValue(state);

  if (!defaultValueValidation.isValid) {
    return dispatch(setDefaultValueError(defaultValueValidation.error));
  }

  const property = PropertyEditorSelectors.getProperty(state);

  dispatch(PropertiesTableActions.addProperty(property));

  return dispatch(close());
};

export const edit = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  if (!PropertyEditorSelectors.hasName(state)) {
    return dispatch(setNameError("Name is required"));
  }

  const defaultValueValidation = PropertyEditorSelectors.validateDefaultValue(state);

  if (!defaultValueValidation.isValid) {
    return dispatch(setDefaultValueError(defaultValueValidation.error));
  }

  const property = PropertyEditorSelectors.getProperty(state);
  const propertyName = property.getName();

  const originalProperty = PropertyEditorSelectors.getOriginalProperty(state);
  const originalPropertyName = originalProperty.getName();

  // As long as the user did not change the property name, perform update
  if (propertyName === originalPropertyName) {
    dispatch(PropertiesTableActions.updateProperty(property));
    return dispatch(close());
  }

  // Otherwise, we must verify the new property name does not already exist and then
  // migrate the old property attributes to the new property - this is necessary in case the
  // property contains attributes that were not added using the visual editor.
  if (!PropertyEditorSelectors.hasUniqueName(state)) {
    return dispatch(setNameError("Name must be unique"));
  }

  const schemaProperties = PropertiesTableSelectors.getProperties(state);

  // We must now ensure that all of the old property attributes are moved to the new property
  // name to ensure even attributes added using raw json will be preserved.
  if (schemaProperties.hasOwnProperty(originalPropertyName)) {

    const updatedSchemaProperties = {
      ...schemaProperties,
      [propertyName]: {
        ...schemaProperties[originalPropertyName],
      },
    };

    dispatch(PropertiesTableActions.setProperties(updatedSchemaProperties));

    dispatch(PropertiesTableActions.removeProperty(originalProperty));
  }

  dispatch(PropertiesTableActions.updateProperty(property));

  return dispatch(close());
};

export const validate = () => (dispatch: any, getState: () => AppSchema): Promise<boolean> => {

  const validateConstraints = () => {

    if (PropertyEditorSelectors.isStringConstraintsVisible(getState())) {
      return dispatch(StringConstraintsActions.validate());
    }

    if (PropertyEditorSelectors.isNumberConstraintsVisible(getState())) {
      return dispatch(NumberConstraintsActions.validate());
    }

    if (PropertyEditorSelectors.isIntegerConstraintsVisible(getState())) {
      return dispatch(IntegerConstraintsActions.validate());
    }

    return Promise.resolve();
  };

  const waitForAnimation = () => new Promise((resolve) =>
    setTimeout(resolve, MIN_VALIDATION_TIME));

  return Promise.all([waitForAnimation(), validateConstraints()])
    .then(() => !PropertyEditorSelectors.hasValidationErrors(getState()));
};

export const save = () => (dispatch: any, getState: () => AppSchema) => {

  const property = PropertyEditorSelectors.getProperty(getState());

  const propertyName = property.getName();

  if (propertyName.length === 0) {
    return dispatch(setNameError("Name is required"));
  }

  dispatch(showProgressIndicator());
  dispatch(clearErrorMessage());
  dispatch(validateProperty());

  return dispatch(validate())
    .then((success: boolean) => {

      dispatch(validatePropertySuccess());
      dispatch(hideProgressIndicator());

      if (!success) {
        return dispatch(setErrorMessage("Please fix all visible errors before proceeding."));
      }

      if (PropertyEditorSelectors.isAddingProperty(getState())) {
        return dispatch(add());
      } else {
        return dispatch(edit());
      }

    }, (error: Error) => {

      const { message = "Failed to validate schema property" } = error;

      console.error("ERROR! Unexpected error thrown during constraints validation!", error);

      dispatch(validatePropertyFailed(message));
      dispatch(hideProgressIndicator());
      return dispatch(setErrorMessage(message));
    });
};
