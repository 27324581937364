import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      marginTop: 8,
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
    },
    label: {
      color: Color.TEXT,
      cursor: "pointer",
      fontSize: 12,
      lineHeight: "14px",
      fontWeight: 400,
    },
    alert: {
      marginTop: 25,
    },
    alertTitle: {
      fontSize: 16,
      marginTop: 2,
    },
    alertDescription: {
      fontSize: 14,
      lineHeight: "21px",
      marginTop: 10,
      marginBottom: 0,
    },
    dropdownMenu: {
      minWidth: 250,
      maxWidth: 350,
      display: "inline-flex",
      alignItems: "stretch",
      marginTop: 25,
      "&:first-child": {
        marginTop: 0,
      },
    },
    dropdownMenuLabel: {
      marginTop: 8,
      fontSize: 18,
      fontWeight: 500,
    },
    switch: {
      marginLeft: 8,
      marginRight: 8,
    },
    connectionMode: {
    },
    filterSwitch: {
      "& label": {
        fontWeight: 400,
      },
      "& .switch": {
        marginLeft: 0,
      },
    },
    mqttFilterSwitch: {
      marginTop: 15,
    },
  });

export default styles;
