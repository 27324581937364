import { isEmptyString } from "@util";
import { DataApprovalRequestAttributes } from "@data/DataApprovalRequest";
import {
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments,
} from "./helpers";

const DATA_APPROVAL_REQUEST_API = process.env.REACT_APP_DATA_APPROVAL_REQUEST_API || "";

if (isEmptyString(DATA_APPROVAL_REQUEST_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_DATA_APPROVAL_REQUEST_API");
}

export interface GetDataApprovalRequestsResponse {
  approvalRequests: DataApprovalRequestAttributes[];
  paging?: {
    next?: string;
  };
}

export const getDataApprovalRequests = (authToken: string): Promise<GetDataApprovalRequestsResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${DATA_APPROVAL_REQUEST_API}/data/management/v1/approval-requests/approval-by/me`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Data Approval Requests failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const approveApprovalRequest = (authToken: string,
                               approvalRequestId: string,
                               approve: boolean,
                               json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Approval Request Id", approvalRequestId],
      ["Approve Request", json],
    ]));

  const makeRequest = () => {

    const url = `${DATA_APPROVAL_REQUEST_API}/data/management/v1/approval-requests/${approvalRequestId}/states/` +
      (approve ? "APPROVED" : "REJECTED") ;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to " + approve ? "approve" : "reject" + " request";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getDataApprovalRequest = (authToken: string,
                                     approvalRequestId: string): Promise<DataApprovalRequestAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Approval Request Id", approvalRequestId],
    ]));

  const makeRequest = () => {

    const url = `${DATA_APPROVAL_REQUEST_API}/data/management/v1/approval-requests/${approvalRequestId}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Data Approval Request failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
