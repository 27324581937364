import createStyles from "@material-ui/core/styles/createStyles";
import Color from "../../../../components/styles/Color";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      fontFamily: "Roboto, sans-serif",
      width: "100%",
    },
    header: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    title: {
      color: Color.GREY8,
      fontSize: 28,
      fontWeight: 300,
      lineHeight: "32px",
      margin: "0px 0px 10px 0px",
      padding: 0,
    },
    subtitle: {
      color: Color.GREY9,
      fontSize: 12,
      fontWeight: 300,
      lineHeight: "14px",
      margin: "10px 0 0 0",
      padding: 0,
    },
    accessTokenOut: {
      color: Color.GREY6,
      fontSize: 12,
      fontWeight: 300,
      lineHeight: "14px",
      height: "14px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      wordBreak: "break-word",
      width: "100%",
      resize: "none",
    },
    accessTokenIn: {
      color: Color.GREY6,
      fontSize: 12,
      fontWeight: 300,
      lineHeight: "14px",
      height: "100%",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "normal",
      wordBreak: "break-word",
      width: "100%",
      resize: "none",
    },
    accessTokenContainerOut: {
      backgroundColor: Color.LIGHT_GREY2,
      borderRadius: 3,
      border: "1px solid " + Color.GREY1,
      padding: 12,
      width: "50%",
      minWidth: "100px",
      maxWidth: "500px",
      maxHeight: "40px",
    },
    accessTokenContainerIn: {
      backgroundColor: Color.LIGHT_GREY2,
      borderRadius: 3,
      border: "1px solid " + Color.GREY1,
      padding: 12,
      width: "50%",
      minWidth: "100px",
      maxWidth: "500px",
      maxHeight: "100%",
      transition: "max-height 0.5s ease-in",
    },
    tokenAndButtonContainer: {
      display: "flex",
      flexDirection: "row",
      margin: "10px 0 0 0"
    },
    copyButton: {
      width: 28,
      height: 28,
      margin: "5px 0px 0px 12px",
    },
    copyIcon: {
      width: 12,
      height: 12,
    },
  });

export default styles;
