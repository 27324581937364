import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { isEmptyString } from "@util";
import { styles } from "./styles";

export interface Model {
  deviceId?: string;
}

type Props = WithStyles<typeof styles> & Model;

export const DisenrollDevice = withStyles(styles)((props: Props) => {

  const { classes, deviceId = "" } = props;

  const content = React.useMemo(() => {

    if (isEmptyString(deviceId)) {
      return (
        <label className={classnames("emptyViewLabel", classes.emptyViewLabel)}>
          No Device Selected
        </label>
      );
    }

    return (
      <React.Fragment>
        <label className={classnames("title", classes.title)}>
          Are you sure you want to disenroll this device?
        </label>
        <label className={classnames("subtitle", classes.subtitle)}>
          <span className={classes.warning}>WARNING:</span>This action cannot be undone!
        </label>
        <label className={classnames("deviceId", classes.deviceId)}>
          {deviceId}
        </label>
      </React.Fragment>
    );
  }, [deviceId]);

  return (
    <div className={classnames("disenrollDevice", classes.container)}>
      {content}
    </div>
  );
});

export default DisenrollDevice;
