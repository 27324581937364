import React from "react";
import moment from "moment";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { isEmptyString, noop } from "@util";
import { withQuantity } from "../summary";
import { SchemaIcon, styles } from "./styles";
import { ActionMenuItem } from "../actions-menu";
import { createComparator } from "./createComparator";
import { SchemaAction } from "../schema-actions-menu";
import { JsonSchemaMetadata, SearchFilter } from "../../data";
import ManageSearchFilters from "./ManageSearchFilters";
import SchemasListColumn from "./SchemasListColumn";
import RefreshButton from "@components/refresh-button";
import NameFilter from "@components/name-filter";
import { ModuleListView } from "@components/module-list-view";
import { getPathToSchema } from "@modules/schemaDetails/helpers";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";

export const DEFAULT_SCHEMAS_LIST_COLUMNS: SchemasListColumn[] = [
  SchemasListColumn.NAMESPACE,
  SchemasListColumn.NAME,
  SchemasListColumn.VERSION,
  SchemasListColumn.STATE,
  SchemasListColumn.LAST_UPDATED,
];

export const SchemasSummary = withQuantity({
  other: "schemas",
  one: "schema",
});

export interface SchemasListModel {
  className?: string;
  schemas?: JsonSchemaMetadata[];
  nameFilter?: string;
  searchFilters?: SearchFilter[];
  numResults?: number;
  errorMessage?: string;
  showErrorView?: boolean;
  showProgressIndicator?: boolean;
  showLoadMoreButton?: boolean;
  showNoResultsView?: boolean;
  actions?: ActionMenuItem[];
  columns?: SchemasListColumn[];
  selectable?: boolean;
  selectAllDisabled?: boolean;
  selectedItems?: JsonSchemaMetadata[];
  noResultsLabel?: string;
  loadMoreLabel?: string;
  tableLayoutFixed?: boolean;
  hideSummary?: boolean;
  hideSearch?: boolean;
  showSearchFilters?: boolean;
  showMoreInfoLabel?: string;
  children?: React.ReactNode;
}

export interface SchemasListActions {
  setNameFilter?: (nameFilter: string) => void;
  setSearchFilters?: (searchFilters: SearchFilter[]) => void;
  loadMore?: () => void;
  refresh?: () => void;
  clearList?: () => void;
  onClickItem?: (item: JsonSchemaMetadata) => void;
  onClickShowMoreInfo?: (item: JsonSchemaMetadata) => void;
  setSelectedItems?: (items: JsonSchemaMetadata[]) => void;
  mapItemToAction?: (item: JsonSchemaMetadata, action: ActionMenuItem) => ActionMenuItem;
  onClickAction?: (item: JsonSchemaMetadata, action: ActionMenuItem) => void;
  editSchema?: (schema: JsonSchemaMetadata) => void;
  draftNewSchemaVersion?: (schema: JsonSchemaMetadata) => void;
  promoteSchema?: (schema: JsonSchemaMetadata) => void;
  deprecateSchema?: (schema: JsonSchemaMetadata) => void;
  decommissionSchema?: (schema: JsonSchemaMetadata) => void;
  deleteSchema?: (schema: JsonSchemaMetadata) => void;
}

type Props = WithStyles<typeof styles> & SchemasListModel & SchemasListActions;

export const SchemasList = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    schemas = [],
    nameFilter = "",
    searchFilters = [],
    errorMessage,
    showErrorView,
    showProgressIndicator,
    showLoadMoreButton,
    showNoResultsView,
    actions = [],
    columns = DEFAULT_SCHEMAS_LIST_COLUMNS,
    selectable,
    selectAllDisabled,
    selectedItems = [],
    noResultsLabel = "No schemas found",
    loadMoreLabel,
    children,
    hideSummary,
    hideSearch,
    showSearchFilters,
    showMoreInfoLabel,
    tableLayoutFixed = true,
    setNameFilter = noop,
    setSearchFilters = noop,
    loadMore = noop,
    refresh = noop,
    onClickItem = noop,
    onClickShowMoreInfo = noop,
    onClickAction = noop,
    editSchema = noop,
    draftNewSchemaVersion = noop,
    promoteSchema = noop,
    deprecateSchema = noop,
    decommissionSchema = noop,
    deleteSchema = noop,
    setSelectedItems = noop,
    mapItemToAction,
  } = props;

  const onActionClicked = React.useCallback((schema: JsonSchemaMetadata, action: ActionMenuItem) => {
    switch (action.id) {
      case SchemaAction.EDIT:
        return editSchema(schema);
      case SchemaAction.DRAFT:
        return draftNewSchemaVersion(schema);
      case SchemaAction.PROMOTE:
        return promoteSchema(schema);
      case SchemaAction.DEPRECATE:
        return deprecateSchema(schema);
      case SchemaAction.DECOMMISSION:
        return decommissionSchema(schema);
      case SchemaAction.DELETE:
        return deleteSchema(schema);
      default:
        return onClickAction(schema, action);
    }
  }, [
    editSchema,
    draftNewSchemaVersion,
    promoteSchema,
    deprecateSchema,
    decommissionSchema,
    deleteSchema,
    onClickAction,
  ]);

  const onItemClicked = React.useCallback((schema: JsonSchemaMetadata) => {

    if (!selectable) {
      return onClickItem(schema);
    }

    const checked = selectedItems.indexOf(schema) >= 0;

    if (checked) {
      return setSelectedItems(selectedItems.filter(item => item !== schema));
    } else {
      return setSelectedItems(selectedItems.concat(schema));
    }

  }, [selectable, selectedItems, setSelectedItems, onClickItem]);

  const [sortByColumn, setSortByColumn] = React.useState(SchemasListColumn.NONE);

  const [ascending, setAscending] = React.useState(true);

  const onClickToggleSortOrder = React.useCallback(() => setAscending(!ascending), [ascending]);

  const comparator = React.useMemo(() =>
    createComparator(sortByColumn, ascending),
    [sortByColumn, ascending]);

  const sortedSchemas = React.useMemo(() =>
    schemas.slice(0).sort(comparator),
    [schemas, comparator]);

  const numResults = React.useMemo(() => schemas.length, [schemas]);

  const sortButtonDisabled = React.useMemo(() => numResults < 1, [numResults]);

  const onClickRefresh = React.useCallback(() => {
    setAscending(true);
    setSortByColumn(SchemasListColumn.NONE);
    refresh();
  }, [setSortByColumn, setAscending, refresh]);

  const moduleListItems: ListViewItem<JsonSchemaMetadata>[] = React.useMemo(() =>
    sortedSchemas.map(schema => {
      return {
        item: schema,
        pathToDetailsView: getPathToSchema(schema.getId()),
        icon: SchemaIcon,
        columnAttributes: createColumns([
          {
            className: "schemaNamespace",
            value: schema.getNamespace(),
            column: SchemasListColumn.NAMESPACE,
            firstColumn: true,
          },
          {
            className: "schemaName",
            value: schema.getName(),
            column: SchemasListColumn.NAME,
          },
          {
            className: "schemaVersion",
            value: schema.getVersionAsString(),
            column: SchemasListColumn.VERSION,
          },
          {
            className: "schemaState",
            value: schema.getState(),
            column: SchemasListColumn.STATE,
          },
          {
            className: "schemaUpdated",
            value: isEmptyString(schema.getLastUpdated()) ? "" :
              moment(+new Date(schema.getLastUpdated()), "x").fromNow(),
            column: SchemasListColumn.LAST_UPDATED,
          },
        ])
      };
    }), [sortedSchemas]);

  return (
    <div className={classnames("schemas", className, classes.container)}>
      {!hideSummary && (
        <SchemasSummary label="Schemas" quantity={numResults} icon={SchemaIcon}>
          <RefreshButton
            className={classnames("refresh", classes.refreshButton)}
            loading={showProgressIndicator}
            refresh={onClickRefresh}
          />
          <div className={classnames("controls", classes.controls)}>
            {children}
          </div>
        </SchemasSummary>
      )}
      {!hideSearch && (
        <NameFilter
          className={classnames("search", classes.search)}
          hint="Filter schemas by name"
          nameFilter={nameFilter}
          setNameFilter={(updatedNameFilter: string) => {
            setNameFilter(updatedNameFilter);
            onClickRefresh();
          }}
        />
      )}
      {showSearchFilters && (
        <ManageSearchFilters
          className={classes.searchFilters}
          disabled={showProgressIndicator}
          searchFilters={searchFilters}
          setSearchFilters={(updatedSearchFilters: SearchFilter[]) => {
            setSearchFilters(updatedSearchFilters);
            onClickRefresh();
          }}
        />
      )}
      <ModuleListView
        className={classnames("schemasList", classes.schemasList)}
        columns={columns}
        sortedColumn={sortByColumn}
        actions={actions}
        listViewItems={moduleListItems}
        error={errorMessage}
        sortButtonDisabled={sortButtonDisabled}
        sortOrderAscending={ascending}
        selectable={selectable}
        selectAllDisabled={selectAllDisabled}
        selectedItems={selectedItems}
        noResultsLabel={noResultsLabel}
        loadMoreLabel={loadMoreLabel}
        tableLayoutFixed={tableLayoutFixed}
        showErrorView={showErrorView}
        showLoadingIndicator={showProgressIndicator}
        showNoResultsView={showNoResultsView}
        showLoadMoreButton={showLoadMoreButton}
        showMoreInfoLabel={showMoreInfoLabel}
        onClickLoadMore={loadMore}
        onClickItem={onItemClicked}
        onClickShowMoreInfo={onClickShowMoreInfo}
        onClickAction={onActionClicked}
        onClickColumn={(column: SchemasListColumn) => setSortByColumn(column)}
        onClickToggleSortOrder={onClickToggleSortOrder}
        setSelectedItems={setSelectedItems}
        mapItemToAction={mapItemToAction}
      />
    </div>
  );
});

export default SchemasList;
