import { Module } from "@data";
import { createModule } from "@base";
import { getEditPolicyPath } from "./helpers";
import { primaryColor, PrimaryIcon as icon } from "@modules/createPolicy/styles";
import EditPolicyView from "@modules/editPolicy/EditPolicyView";
import { isAccessDeniedVisible, isEmptyViewVisible } from "@modules/createPolicy/selectors";
import { initialize } from "./actions";

type RouteParams = { id?: string; };

export const editPolicyModule: Module = createModule<RouteParams>({
  id: "edit_policy",
  path: getEditPolicyPath(":id"),
  exactPath: true,
  strictPath: true,
  name: " Edit Policy",
  title: "Edit Policy",
  className: "editPolicyModule",
  primaryColor,
  icon,
  view: EditPolicyView,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: ({ id: policyName }: RouteParams = {}) => initialize(policyName),
});

export default editPolicyModule;
