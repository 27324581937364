import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { getDataAccessRequest } from "../selectors";
import DataAccessRequestView, { Actions, Model } from "../components/DataAccessRequestView";

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  showDataSetAlias: false,
  dataAccessRequest: getDataAccessRequest(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(DataAccessRequestView);
