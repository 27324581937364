enum Quantity {
  OTHER = "other",
  ONE = "one",
  ZERO = "zero",
}

namespace Quantity {

  export function of(quantity: number): Quantity {
    if (quantity === 0) {
      return Quantity.ZERO;
    } else if (quantity === 1) {
      return Quantity.ONE;
    } else {
      return Quantity.OTHER;
    }
  }
}

export interface Quantities {
  [Quantity.OTHER]: string;
  [Quantity.ONE]?: string;
  [Quantity.ZERO]?: string;
}

export const getPluralString = (quantity: number, quantities: Quantities): string => {
  return quantity + " " + (quantities[Quantity.of(quantity)] || quantities[Quantity.OTHER]);
};
