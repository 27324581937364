import React from "react";
import classnames from "classnames";
import { tag as styles } from "./styles";
import TagIcon from "@material-ui/icons/Label";
import RemoveIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/AddOutlined";
import { Chip, TextField, IconButton } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { clickHandler, enterKeyHandler, formEventHandler, hasSpecialChars, noop } from "@util";

export interface TagProps {
  className?: string;
  tags: string[];
  label?: string;
  helperText?: string;
  icon?: React.ReactElement;
  fullWidth?: boolean;
  emptyValueLabel?: string;
  setTags: (tags: string[]) => void;
}

export const TagEditor = withStyles(styles)(((props: WithStyles<typeof styles> & TagProps) => {

  const {
    classes,
    className,
    tags = [],
    fullWidth = false,
    label = "Add Tags (optional)",
    emptyValueLabel = "No Tags Defined",
    helperText = "Tags to associate with this item",
    icon = <TagIcon className={classnames("tagIcon", classes.tagIcon)} />,
    setTags = noop,
  } = props;

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const [tag, setTag] = React.useState("");

  const addTag = React.useCallback(() => {
    const tagged = (tag || "").toLowerCase();
    setTags(tags.indexOf(tagged) === -1 ? tags.concat(tagged) : tags);
    setTag("");
  }, [tag, tags, setTag, setTags]);

  const removeTag = React.useCallback((input: string) => () => {
    const tagged = (input || "").toLowerCase();
    setTags(tags.filter((value: string) => value !== tagged));
  }, [tags, setTags]);

  return (
    <React.Fragment>
      <div className={classnames(className, classes.tagEditor)}>
        <TextField
          className={classnames("tagInput", classes.tagInput, { [classes.fullWidth]: fullWidth })}
          autoComplete="off"
          name="tag"
          label={label}
          value={tag}
          helperText={helperText}
          variant="outlined"
          disableSpecialChars={true}
          InputLabelProps={inputLabelProps}
          onChange={formEventHandler(setTag)}
          onKeyDown={hasSpecialChars(tag) ? noop : enterKeyHandler(addTag)}
        />
        {tag.trim().length > 0 && (
          <IconButton
            className={classnames("addTagButton", classes.addTagIconButton)}
            color="primary"
            onClick={clickHandler(addTag)}
            disabled={hasSpecialChars(tag)}
          >
            <AddIcon />
          </IconButton>
        )}
      </div>
      <div className={classnames("tags", classes.tags)}>
        {tags.length === 0 && (
          <label className={classes.tagsEmptyView}>{emptyValueLabel}</label>
        )}
        {tags.map((key: string) => (
          <Chip
            key={key}
            label={key}
            color="primary"
            className={classnames(key, classes.tag)}
            icon={icon}
            deleteIcon={<RemoveIcon className={classnames("removeIcon", classes.tagIcon)} />}
            onDelete={removeTag(key)}
          />
        ))}
      </div>
    </React.Fragment>
  );
}));

export default TagEditor;
