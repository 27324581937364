import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { SummaryView, SummaryViewModel } from "@components";
import { getSummaryViewItems } from "@modules/namespaceDetails/selectors";

interface ContainerModel extends SummaryViewModel {
    isLoading?: boolean;
}

const SummaryViewContainer = (props: ContainerModel) => {

    const { isLoading, items, ...otherProps } = props;

    const summaryViewItems = React.useMemo(() => isLoading ? [] : items, [isLoading, items]);

    return (
        <SummaryView
            {...otherProps}
            items={summaryViewItems}
        />
    );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
    items: getSummaryViewItems(state),
});

export default connect<ContainerModel, {}, ContainerModel>(
    mapStateToProps,
)(SummaryViewContainer);
