import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import {
  getErrorMessage,
  getSortByColumn,
  getSortedItems,
  isLoadingIndicatorVisible,
  isSortOrderAscending,
} from "../selectors";
import { setSortByColumn, toggleSortOrder } from "../actions";
import FilesList , { Actions, Model } from "../components/FilesList";

type Props = Model & Actions;

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => {

  const items = getSortedItems(state);
  const isEmpty = items.length === 0;
  const error = getErrorMessage(state);
  const showErrorView = error.length > 0;
  const isLoading = isLoadingIndicatorVisible(state);
  const showNoResultsView = isEmpty && !showErrorView && !isLoading;
  const showLoadingIndicator = isLoading && !showErrorView && isEmpty;

  return {
    items,
    error,
    showErrorView,
    showLoadingIndicator,
    showNoResultsView,
    sortedColumn: getSortByColumn(state),
    sortOrderAscending: isSortOrderAscending(state),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  toggleSortOrder: () => dispatch(toggleSortOrder()),
  setSortedColumn: (column: string) => dispatch(setSortByColumn(column)),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(FilesList);
