import { isEmptyString } from "@util";
import { DataSetDetailAttributes, DataSetInfoAttributes, DataSetType } from "@data";
import {
  createQueryParams,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments,
} from "./helpers";

const DATA_CATALOG_API = process.env.REACT_APP_DATA_CATALOG_API || "";

if (isEmptyString(DATA_CATALOG_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_DATA_CATALOG_API");
}

export interface GetDataSetsResponse {
  dataSets: DataSetInfoAttributes[];
  paging?: {
    next?: string;
  };
}

export interface GetDataSetsRequestOptions {
  next?: string;
  accountId?: string;
  dataSetGroup?: string;
  dataSetAliasContains?: string;
  dataSetType?: DataSetType;
}

export const getDataSets = (authToken: string,
                            options: GetDataSetsRequestOptions = {}): Promise<GetDataSetsResponse> => {

  const { next, accountId, dataSetGroup, dataSetAliasContains, dataSetType } = options || {};

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams({
      next,
      dataSetGroup,
      dataSetType,
      dataSetAliasContains,
    });

    const url = `${DATA_CATALOG_API}/datacatalog/v1/datasets${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        ...(isEmptyString(accountId) ? ({}) : ({
          "X-IoT-Platform-AccountId": accountId,
        })),
      }
    };

    const defaultErrorMessage = "Fetch data sets failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getDataSet = (authToken: string,
                           dataSetAlias: string,
                           accountId: string): Promise<DataSetDetailAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Data Set Name", dataSetAlias],
      ["Account ID", accountId],
    ]));

  const makeRequest = () => {

    const url = `${DATA_CATALOG_API}/datacatalog/v1/datasets/${dataSetAlias}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "X-IoT-Platform-AccountId": `${accountId}`,
      },
    };

    const defaultErrorMessage = "Fetch data set failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
