import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ThemeProvider, Wizard, WizardActions, WizardModel } from "@components";
import {
  AddDeviceWizardStep,
  ADD_DEVICE_DIALOG_STEPS as DEFAULT_STEPS,
  ADD_DEVICE_DIALOG_STEPS_LABELS as DEFAULT_STEP_LABELS
} from "../../steps";
import styles from "../styles";
import theme from "../theme";
import CircularProgress from "@components/circular-progress";

export interface Model extends WizardModel<AddDeviceWizardStep> {
  stepLabels?: { [key: string]: string };
  showProgressIndicator?: boolean;
}

export interface Actions extends WizardActions<AddDeviceWizardStep> {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const AddDeviceWizard = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    steps = DEFAULT_STEPS,
    stepLabels = DEFAULT_STEP_LABELS,
    showProgressIndicator,
    mapStepToLabel = React.useCallback(step => stepLabels[step] || null, [stepLabels]),
    children,
    ...otherProps
  } = props;

  return (
    <ThemeProvider theme={theme}>
      <Wizard
        {...otherProps}
        steps={steps}
        className={classnames("addDeviceWizard", className, classes.container)}
        mapStepToLabel={mapStepToLabel}
      >
        {showProgressIndicator && (
          <CircularProgress size={68} className={classes.progressIndicator}/>
        )}
        {children}
      </Wizard>
    </ThemeProvider>
  );
});

export default AddDeviceWizard;
