import React from "react";
import classnames from "classnames";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { summaryEditorView as styles } from "./styles";
import { ErrorView } from "@components/error-view";

export interface Model {
  showInvalidJsonError?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SummaryEditorView = withStyles(styles)((props: Props) => {

  const { classes, showInvalidJsonError, children } = props;

  if (showInvalidJsonError) {
    return (
      <div className={classnames("summaryEditorView", "error", classes.container)}>
        <ErrorView
          title="JSON Syntax Error"
          message="The visual editor cannot be used until you have fixed the JSON syntax error."
        />
      </div>
    );
  }

  return (
    <div className={classnames("summaryEditorView", classes.container)}>
      {children}
    </div>
  );
});

export default SummaryEditorView;
