import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      marginTop: 10,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: 0,
    },
    controls: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      marginTop: 16,
    },
    viewModeButton: {
      marginLeft: 15,
      "&:first-child": {
        marginLeft: 0,
      },
    }, 
    tabs: {
      color: Color.TEXT,
      minHeight: 0,
      width: "100%",
      borderBottom: `1px solid ${Color.GREY1}`,
      "& .MuiTabs-flexContainer": {
        gap: 10,
      },
      "& .MuiTab-textColorInherit.Mui-selected": {
        fontWeight: 400,
      }
    },
    tab: {
      textTransform: "none",
      opacity: "1",
      minWidth: 0,
      minHeight: 0,
      fontWeight: 300,
      padding: "10px 5px",
      "&:hover": {
        background: Color.LIGHT_GREY4,
      },
      "& span.MuiTab-wrapper::after": {
        display: "block",
        content: "var(--tabPolicyValue)",
        color: Color.TRANSPARENT,
        overflow: "hidden",
        visibility: "hidden",
        fontWeight: 500,
        height: 0,
      }
    }
  });

export default styles;
