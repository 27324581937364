import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { createActions } from "@base";

export const {
  policyName: setPolicyName,
  accountId: setAccountId,
  managedPolicy: setManagedPolicy,
  nameFilter: setNameFilter,
  sortByColumn: setSortByColumn,
  sortOrderAscending: setSortOrderAscending,
  FETCH_MANAGED_POLICY_DETAILS_REQUEST: fetchManagedPolicyDetailsRequest,
  FETCH_MANAGED_POLICY_DETAILS_SUCCESS: fetchManagedPolicyDetailsSuccess,
  FETCH_MANAGED_POLICY_DETAILS_FAILURE: fetchManagedPolicyDetailsFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setPolicyName());
  dispatch(setAccountId());
  dispatch(setManagedPolicy());
  dispatch(setNameFilter());
  dispatch(setSortByColumn());
  dispatch(setSortOrderAscending());
  return dispatch(baseReset());
};

export const initialize = (policyName: string, accountId: string) => (dispatch: any) => {

  dispatch(reset());
  dispatch(setPolicyName(policyName));
  dispatch(setAccountId(accountId));

  return Promise.resolve();
};
