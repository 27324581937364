import React from "react";
import { clickHandler, noop } from "@util";
import classnames from "classnames";
import { enrollmentTypeView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { EnrollmentType } from "../reducers";
import Switch from "@material-ui/core/Switch";
import { ErrorView, Alert, AlertTitle } from "@components";

export interface Model {
  title?: string;
  enrollmentType?: EnrollmentType;
  showInvalidJsonError?: boolean;
}

export interface Actions {
  setVisualEditorType?: () => void;
  setFileUploadType?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const EnrollmentTypeView = withStyles(styles)((props: Props) => {

  const {
    classes,
    showInvalidJsonError,
    title = "Select Enrollment Type",
    enrollmentType = EnrollmentType.VISUAL_EDITOR,
    setVisualEditorType = noop,
    setFileUploadType = noop,
    children,
  } = props;

  if (showInvalidJsonError) {
    return (
      <div className={classnames("enrollmentTypeView", classes.errorViewContainer)}>
        <ErrorView
          title="JSON Syntax Error"
          message="Visual editors cannot be used until you have fixed the JSON syntax error."
        />
      </div>
    );
  }

  const visualEditorSelected = React.useMemo(() =>
    EnrollmentType.VISUAL_EDITOR === enrollmentType,
    [enrollmentType]);

  const onChange = React.useCallback(() => {
    if (visualEditorSelected) {
      setFileUploadType();
    } else {
      setVisualEditorType();
    }
  }, [visualEditorSelected, setFileUploadType, setVisualEditorType]);

  return (
    <div className={classnames("enrollmentTypeView", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>{title}</label>
        </React.Fragment>
      <div className={classnames("enrollmentTypeSwitch", classes.switchContainer)}>
        <label
          className={classnames("fileUploadLabel", classes.label)}
          onClick={clickHandler(() => setFileUploadType())}
        >
          File Upload
        </label>
        <Switch
          className={classnames("switch", classes.switch)}
          color="primary"
          checked={visualEditorSelected}
          onChange={onChange}
        />
        <label
          className={classnames("visualEditorLabel", classes.label)}
          onClick={clickHandler(() => setVisualEditorType())}
        >
          Visual Editor
        </label>
        {children}
      </div>
      {visualEditorSelected && (
        <React.Fragment>
        <Alert severity="info" className={classnames("visualEditorInfo", classes.alert)}>
        <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
        <strong>
        Note
        </strong>
        </AlertTitle>
        <p className={classnames("alertDescription", classes.alertDescription)}>
        Selecting Visual Editor will let you enroll a single or multiple devices using a wizard.
        Click Next Step to proceed.
        </p>
        </Alert>
        </React.Fragment>
      )}
      {!visualEditorSelected && (
        <React.Fragment>
          <Alert severity="info" className={classnames("fileUploadInfo", classes.alert)}>
            <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
              <strong>
                Note
              </strong>
            </AlertTitle>
            <p className={classnames("alertDescription", classes.alertDescription)}>
              Selecting File Upload will let you upload a Base 64 encoded file to enroll multiple devices.
              Click Next step to proceed if you have a file ready to be uploaded.
            </p>
          </Alert>
        </React.Fragment>
      )}
      <React.Fragment>
        <Alert severity="info" className={classnames("fileCreationInfo", classes.alert)}>
          <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
            <strong>
              Create File
            </strong>
          </AlertTitle>
          <p className={classnames("alertDescription", classes.alertDescription)}>
            To create a file for batch enrollment of devices,select Visual Editor as Enrollment Type and
            click on step 4.JSON in the wizard stepper on top. <br/>
            In the editor, enter the devices you want to enroll as JSON and click
            "Download Batch Enrollment File" button.
          </p>
        </Alert>
      </React.Fragment>
      {children}
    </div>
  );
});

export default EnrollmentTypeView;
