import React from "react";
import classnames from "classnames";
import { isEmptyString } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  DeviceTypeModelV3CredentialEncoding,
  DeviceTypeModelV3CredentialPersistence,
  DeviceTypeModelV3CredentialType,
  DeviceTypeModelV3DeviceAuthenticationType,
} from "@data";
import {
  Autocomplete,
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel,
  DropdownMenu,
  TextFieldStyled,
} from "@components";
import CredentialsViewItem, { CredentialsViewItemAttributes } from "../CredentialsViewItem";
import styles from "./styles";

const DEFAULT_CREDENTIAL_TYPES: DeviceTypeModelV3CredentialType[] = [
  DeviceTypeModelV3CredentialType.CERTIFICATE,
  DeviceTypeModelV3CredentialType.HMAC_SECRET,
];

const mapTypeToOptionName = (value: DeviceTypeModelV3CredentialType) => {
  switch (value) {
    case DeviceTypeModelV3CredentialType.HMAC_SECRET:
      return "HMAC Secret";
    case DeviceTypeModelV3CredentialType.CERTIFICATE:
      return "Certificate";
    case DeviceTypeModelV3CredentialType.USER_DEFINED:
      return "User Defined";
    default:
      return null;
  }
};

export interface Model extends ConfirmationDialogModel<CredentialsViewItem> {
  credentialTypes?: DeviceTypeModelV3CredentialType[];
  cmsAuthorityIds?: string[];
  cmsAuthorityNames?: { [key: string]: string };
  loadingAuthorityIds?: boolean;
  deviceAuthenticationAlreadyAdded?: boolean;
}

export interface Actions extends ConfirmationDialogActions<CredentialsViewItem> {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const CredentialsEditorDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    item: initialItem = CredentialsViewItem.EMPTY,
    credentialTypes = DEFAULT_CREDENTIAL_TYPES,
    cmsAuthorityIds = [],
    cmsAuthorityNames = {},
    loadingAuthorityIds,
    deviceAuthenticationAlreadyAdded,
    continueButtonLabel = "Save",
    children,
    ...otherProps
  } = props;

  const [item, setItem] = React.useState(initialItem);

  const editMode = React.useMemo(() => initialItem.hasName(), [initialItem]);

  const title = React.useMemo(() =>
    `${editMode ? "Edit" : "Add"} Credential Definition`, [editMode]);

  const continueButtonDisabled = React.useMemo(() => !item.hasName(), [item]);

  const selectedCmsAuthorityIds = React.useMemo(() => item.getTrustedCmsAuthorityIds(), [item]);

  const showTrustedAuthorityIds = React.useMemo(() => item.isCertificateCredentialType(), [item]);

  const deviceAuthenticationTypes = React.useMemo(() => {
    switch (item.getType()) {
      case DeviceTypeModelV3CredentialType.CERTIFICATE:
        return [
          DeviceTypeModelV3DeviceAuthenticationType.X509,
          DeviceTypeModelV3DeviceAuthenticationType.ASYM,
        ];
      case DeviceTypeModelV3CredentialType.HMAC_SECRET:
        return [
          DeviceTypeModelV3DeviceAuthenticationType.SYM,
        ];
      default:
        return [];
    }
  }, [item]);

  const updateItem = React.useCallback((value: Partial<CredentialsViewItemAttributes>) =>
    setItem(new CredentialsViewItem({
      ...item.toJS(),
      ...value,
    })), [item, setItem]);

  const setCredentialName = React.useCallback(
    name => updateItem({ name }), [updateItem]);

  const setTrustedCmsAuthorityIds = React.useCallback(
    trustedCmsAuthorityIds => updateItem({ trustedCmsAuthorityIds }), [updateItem]);

  const setDeviceAuthentication = React.useCallback(
    deviceAuthentication => updateItem({ deviceAuthentication }), [updateItem]);

  const updateCredentialType = React.useCallback((type: DeviceTypeModelV3CredentialType) =>
    setItem(new CredentialsViewItem({
      ...item.toJS(),
      type,
      // Reset any previously selected authority ids and device authentication types
      trustedCmsAuthorityIds: [],
      deviceAuthentication: [],
      // Figure out the encoding and persistence based on the new type
      ...(type === DeviceTypeModelV3CredentialType.CERTIFICATE ? ({
        encoding: DeviceTypeModelV3CredentialEncoding.PEM,
        persistence: DeviceTypeModelV3CredentialPersistence.MUTABLE,
      }) : ({
        encoding: DeviceTypeModelV3CredentialEncoding.BASE64,
        persistence: DeviceTypeModelV3CredentialPersistence.IMMUTABLE,
      })),
    })), [item, setItem]);

  const stringifyAuthorityOption = React.useCallback((option: string): string => {
    const optionName = cmsAuthorityNames[option];
    return isEmptyString(optionName) ? option : `${option} (${optionName})`;
  }, [cmsAuthorityNames]);

  const filterAuthorityOptionsConfig = React.useMemo(() => ({
    stringify: stringifyAuthorityOption,
  }), [stringifyAuthorityOption]);

  const renderAuthorityOption = React.useCallback((option: string): string | React.ReactNode => {
    const optionName = cmsAuthorityNames[option];
    return (
      <div className={classnames("autocompleteOptionAndName", classes.autocompleteOptionAndName)}>
        <label className={classnames("autocompleteOptionValue", classes.autocompleteOptionValue)}>
          {option}
        </label>
        {!isEmptyString(optionName) && (
          <label className={classnames("autocompleteOptionName", classes.autocompleteOptionName)}>
            ({optionName})
          </label>
        )}
      </div>
    );
  }, [cmsAuthorityNames, classes]);

  return (
    <ConfirmationDialog
      {...otherProps}
      className={classnames("credentialsEditorDialog", className, classes.dialog)}
      title={title}
      continueButtonLabel={continueButtonLabel}
      continueButtonDisabled={continueButtonDisabled}
      item={item}
    >
      <div className={classnames("credentialsEditor", classes.content)}>
        <TextFieldStyled
          className={classnames("credentialName", classes.textField, classes.credentialName)}
          inputLabelClassName={classnames("credentialNameInputLabel", classes.inputLabel)}
          name="credentialName"
          label="Credential Name"
          placeholder="Set Credential Name"
          disabled={editMode}
          shrinkInputLabel={true}
          autoFocus={!editMode}
          value={item.getName()}
          setValue={setCredentialName}
        />
        <DropdownMenu
          className={classnames("credentialType", classes.dropdownMenu, classes.credentialType)}
          dropdownMenuLabel="Credential Type"
          dropdownMenuLabelClassName={classes.dropdownMenuLabel}
          hideEmptyValue={true}
          values={credentialTypes}
          selectedValue={item.getType()}
          setSelectedValue={updateCredentialType}
          mapValueToLabel={mapTypeToOptionName}
        />
        {showTrustedAuthorityIds && (
          <Autocomplete
            className={classnames("trustedAuthorityIds", classes.autocomplete, classes.trustedAuthorityIds)}
            helperTextClassName={classnames("autocompleteHelperText", classes.autocompleteHelperText)}
            optionClassName={classnames("autocompleteOption", classes.autocompleteOption)}
            label="Trusted Certificate Authority ID(s)"
            placeholder="Add Trusted Certificate Authority ID(s)"
            helperText="Authority ID(s) used to configure this device type for certificate generation"
            noOptionsLabel=""
            freeSolo={true}
            options={cmsAuthorityIds}
            renderOption={renderAuthorityOption}
            filterOptionsConfig={filterAuthorityOptionsConfig}
            value={selectedCmsAuthorityIds}
            setValue={setTrustedCmsAuthorityIds}
            loading={loadingAuthorityIds}
          />
        )}
        <Autocomplete
          className={classnames("deviceAuthenticationTypes", classes.autocomplete, classes.deviceAuthenticationTypes)}
          helperTextClassName={classnames("autocompleteHelperText", classes.autocompleteHelperText)}
          optionClassName={classnames("autocompleteOption", classes.autocompleteOption)}
          label="Device Authentication Type"
          disabled={deviceAuthenticationAlreadyAdded}
          maxNumSelectedValues={1}
          maxLimitLabel="A device type can only have one device authentication type"
          pleaseRemoveSelectedValueLabel="Please remove selected value to change device authentication type"
          placeholder={item.hasDeviceAuthentication() ? "" :
            (deviceAuthenticationAlreadyAdded
              ? "Device Authentication Type Already Added to Another Credential"
              : "Add Device Authentication Type(s)")
          }
          helperText={item.hasDeviceAuthentication() ? "" :
            (deviceAuthenticationAlreadyAdded
              ? "A device type can only have a single device authentication type"
              : "Select the device authentication type supported by this credential definition")
          }
          customValuesDisabled={true}
          noOptionsLabel="Device Authentication Type Not Found"
          options={deviceAuthenticationTypes}
          value={item.getDeviceAuthentication()}
          setValue={setDeviceAuthentication}
        />
        {children}
      </div>
    </ConfirmationDialog>
  );
});

export default CredentialsEditorDialog;
