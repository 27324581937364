import createStyles from "@material-ui/core/styles/createStyles";
import { PrimaryIcon } from "@modules/dataWorkloads/styles";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Color from "@Color";

export const ModuleIcon = PrimaryIcon;
export const WorkloadInstanceIcon = PlayCircleOutlineIcon;

export const primaryColor = Color.MODULES;

export const summaryView = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      "& .missingAlertView": {
        marginBottom: 0,
      },
    },
    summaryContainer: {
      marginTop: 25,
      "& > .content": {
        marginTop: 0,
        "& > .chipView": {
          marginTop: "25px !important",
        },
      },
    },
    completionMetadata: {
      marginTop: 25,
      "& .box": {
        marginTop: 0,
      }
    },
    dataSetContainer: {
      marginTop: 25,
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      "& .dataSetsList table.list": {
        tableLayout: "auto",
      },
    },
    dataSetInputContainer: {
      display: "flex",
      flex: "0 0 50%",
      flexFlow: "column nowrap",
      paddingRight: 20,
    },
    dataSetOutputContainer: {
      display: "flex",
      flex: "0 0 50%",
      flexFlow: "column nowrap",
      paddingLeft: 20,
    },
    dataSetControls: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
    },
    dataListView: {
      "& td.name": {
        width: "100%"
      }
    },
    dataSetTitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      minWidth: 100,
      alignSelf: "flex-start",
      fontSize: 24,
      lineHeight: "28px",
    },
    parameters: {
      marginTop: 30,
    },
  });

export const configurationView = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      flexFlow: "column nowrap",
      display: "flex",
    },
    dnsNames: {
      "& label.title": {
        fontSize: 16,
        fontWeight: 500,
        minWidth: 200,
      }
    },
    title: {
      fontSize: 24,
      fontWeight: 300,
      marginTop: 20,
      marginBottom: 20,
    },
    switchContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
    },
    label: {
      fontSize: 16,
      fontWeight: 500,
      minWidth: 200,
    },
    leftLabel: {
      fontSize: 14,
      fontWeight: 300,
      marginRight: 10,
    },
    row: {
      display: "flex",
      flexFlow: "row",
      alignItems: "center",
    }
  });

export const queryView = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      "& .missingAlertView": {
        marginBottom: 0,
      },
    },
    summaryContainer: {
      marginTop: 25,
      "& > .content": {
        marginTop: 0,
        "& > .chipView": {
          marginTop: "25px !important",
        },
      },
    },
    sqlEditor: {
      marginTop: 25,
    },
    queryTitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      minWidth: 100,
      alignSelf: "flex-start",
      fontSize: 24,
      lineHeight: "28px",
    },
    editButton: {
      marginLeft: "auto",
      padding: 15,
    },
    titleContainer: {
      display: "flex",
      marginTop: 25,
    },
  });

export const triggersView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 25,
      "& table": {
        marginTop: 0,
      }
    },
    header: {
      display: "flex",
      alignItems: "center",
    },
    headerContent: {
      alignItems: "center",
      display: "flex"
    },
    triggerTitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 24,
    },
    addTrigger: {
      justifySelf: "flex-end",
      marginLeft: "auto",
      marginRight: "15px",
    },
    removeAll: {
    },
    controls: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      marginLeft: "auto",
    },
  });

export const instancesListView = () =>
  createStyles({
    container: {
      "& .expandColumn": {
        width: "50px",
      },
    },
    filters: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      paddingTop: 25,
    },
    datePicker: {
      marginRight: 8,
      flex: "1 0 0",
    },
    state: {
      minWidth: 200,
      marginRight: 8,
      "& .dropdownMenu": {
        minWidth: 200,
      }
    },
    limit: {
      "& label + .dropdownMenu": {
        marginTop: 8,
        minWidth: 75,
      },
      minWidth: 75,
    },
    refreshButton: {
    },
  });

export const validationView = () =>
  createStyles({
    container: {
      marginTop: 12,
    },
  });

export const codeInfoView = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      "& label.name": {
        minWidth: 170,
      },
    },
    missingAlert: {
      marginTop: 20,
      "& + .summaryView": {
        marginTop: 5,
      },
    },
    missingAlertBody: {
      marginTop: 10,
    },
    controls: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      marginLeft: "auto",
      position: "relative",
    },
    downloadCodeButton: {
    },
    uploadCodeButton: {
      justifySelf: "flex-end",
      marginLeft: "auto",
    },
    buttonIcon: {
      width: "auto",
      height: 16,
      marginRight: 6,
    },
    uploadButton: {
      cursor: "pointer",
    },
    uploadContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "flex-start",
    },
    content: {
      flex: "1 0 auto",
      color: Color.TEXT,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      padding: 150,
      borderRadius: 6,
      textAlign: "center",
      marginTop: 16,
      border: `1px dashed ${Color.TEXT}`,
      cursor: "pointer",
    },
    contentDragging: {
      backgroundColor: Color.LIGHT_GREY2,
    },
    contentSelected: {
      backgroundColor: Color.LIGHT_GREY,
    },
    contentDisabled: {
      cursor: "no-drop",
      backgroundColor: Color.LIGHT_GREY,
    },
    label: {
      flex: "0 0 auto",
      margin: 8,
      fontSize: 18,
      lineHeight: "21px",
      fontWeight: 400,
    },
    disabledLabel: {
      flex: "0 0 auto",
      fontSize: 18,
      lineHeight: "21px",
      fontWeight: 400,
      cursor: "no-drop"
    },
    subLabel: {
      marginTop: 20,
    },
    dialogLabel: {
      flex: "0 0 auto",
      margin: 8,
      fontSize: 18,
      lineHeight: "21px",
      fontWeight: 400,
    },
    compactView: {
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      marginTop: 0,
      paddingTop: 25,
      "& .summaryView": {
        flex: "1 0 auto",
        marginTop: 0,
      },
      "& .downloadButton": {
        margin: 0,
      },
    },
  });

export const testView = () =>
  createStyles({
    container: {
      marginTop: 0,
      "& .content": {
        marginTop: 0,
      },
      margin: "0px 10px",
    },
    testView: {
      marginTop: 25,
      padding: "0 20px",
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 23,
      lineHeight: "32px",
      marginBottom: 25,
    },
    saveButton: {
      justifyContent: "right"
    },
    label: {
      marginTop: 20,
      fontSize: 16,
      fontWeight: 300,
      display: "block",
    },
    triggerStorage: {
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      display: "block",
      margin: "15px 0px",
    },
    dataSetAlias: {
      width: "75%",
      "& div": {
        width: "100%"
      }
    },
    fileName: {
      width: "100%",
      "& button": {
        padding: 0,
        color: Color.SIGNIFY_GREEN,
      },
      "& > div": {
        width: "100%",
      }
    },
    dataLake: {
      marginTop: 10,
      flexFlow: "column nowrap",
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    precedingWorkload: {
      "& table": {
        width: "inherit"
      },
    },
    triggerType: {
      marginBottom: 10,
    }
  });

export const testViewDrawer = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: "-50px",
    },
    drawer: {
      "& div.MuiDrawer-paper": {
        width: "45%",
      }
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      minWidth: 100,
      alignSelf: "flex-start",
      fontSize: 24,
      lineHeight: "28px",
    },
    cancel: {
      textAlign: "right",
    },
    toolbar: {
      backgroundColor: primaryColor,
      justifyContent: "space-between",
    },
    testView: {
      margin: "25px",
      marginTop: "10px",
    }
  });

export const dataWorkloadDetailsLayout = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    header: {
      minHeight: 50,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      paddingBottom: 25,
      marginBottom: 25,
      borderBottom: `1px solid ${Color.GREY1}`,
    },
    icon: {
      color: Color.SIGNIFY_GREEN,
      width: 40,
      height: 40,
    },
    title: {
      color: Color.BLACK,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: "0 0 0 8px",
    },
    refreshButton: {
      color: Color.SIGNIFY_GREEN,
    },
    deleteButton: {
      marginLeft: "auto",
      backgroundColor: Color.RED3,
      color: Color.WHITE,
      "&:hover": {
        backgroundColor: Color.DARK_RED,
      },
      "&:active": {
        backgroundColor: Color.RED2,
      },
    },
    content: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      "& .versionHistory": {
        flex: "1 0 250px",
        minWidth: 250,
        maxWidth: 350,
        "& + .notFoundView": {
          flex: "1 0 auto",
          alignSelf: "flex-start",
        },
      },
      "& .detailsView": {
        flex: "1 0 75%",
        paddingLeft: 20,
        "&.loading": {
          "& .controls": {
            marginTop: 0,
            "& .tabs": {
              flex: "0 0 auto",
            },
          },
        },
        "& .header + .workloadStateHeader": {
          marginTop: 15,
          "& + .detailsViewTabs": {
            marginTop: 15,
          },
        },
      },
    },
  });
