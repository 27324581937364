import React from "react";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  ServiceActionOperation,
  useSecurityServicesRegionalClient
} from "@hooks";

export interface UseServiceActionManagementProps extends Partial<UseApiRequestProps<void>> {
  serviceId: string;
  operation: ServiceActionOperation;
}

export interface UseServiceActionManagementModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UseServiceActionManagementActions {
  performOperation: () => void;
}

type Props = UseServiceActionManagementProps;
type Model = UseServiceActionManagementModel;
type Actions = UseServiceActionManagementActions;
type Result = [Model, Actions];

export const useServiceActionManagement = (props: Props): Result => {

  const {
    serviceId = "",
    operation = ServiceActionOperation.NONE,
    ...otherProps
  } = props;

  const SecurityServicesRegionalClient = useSecurityServicesRegionalClient();

  const defaultErrorMessage = React.useMemo(() =>
    `Failed to ${operation} service`, [operation]);

  const mapApiToOperation = React.useCallback(() => {
    switch (operation) {
    case ServiceActionOperation.DELETE:
      return SecurityServicesRegionalClient.deleteServiceRegionalApi(serviceId);
    case ServiceActionOperation.ENABLE:
      return SecurityServicesRegionalClient.enableServiceRegionalApi(serviceId);
    case ServiceActionOperation.DISABLE:
      return SecurityServicesRegionalClient.disableServiceRegionalApi(serviceId);
    default:
      return Promise.resolve();
    }
  }, [operation, serviceId]);

  const [{ showSuccessView, ...baseModel }, { refresh: performOperation }] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => mapApiToOperation(),
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? `${operation} Successful` : "" ,
    [showSuccessView, operation]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    performOperation
  }), [performOperation]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useServiceActionManagement;
