import { useApiRequestAnalytics } from "@hooks";
import {
  BlobFilesBeginUploadResponse,
  CompleteBlobFilesUploadResponse,
  DataLakeFileTransferClient,
  GetBlobUploadUrlResponse,
  GetDataLakeFileMetadataResponse,
  GetDownloadCredentialResponse,
  GetDownloadUrlResponse,
} from "@network";
import { DataLakeMultiPartUploadUrlMapAttributes } from "@data";

export type GetAcquiredFilesDownloadUrlApi =
  (dataSet: string, files: string[], accountId: string) => Promise<GetDownloadUrlResponse>;

export type GetAcquiredStreamDownloadUrlApi =
  (dataSetAlias: string, json: string, accountId: string) => Promise<GetDownloadUrlResponse>;

export type GetBlobFilesDownloadUrlApi =
  (dataSetAlias: string, json: string, accountId: string) => Promise<GetDownloadUrlResponse>;

export type GetAcquiredFilesCredentialsApi =
  (dataSetAlias: string, json: string, accountId: string) => Promise<GetDownloadCredentialResponse>;

export type GetAcquiredStreamCredentialsApi =
  (dataSetAlias: string, json: string, accountId: string) => Promise<GetDownloadCredentialResponse>;

export type GetBlobFilesCredentialsApi =
  (dataSetAlias: string, json: string, accountId: string) => Promise<GetDownloadCredentialResponse>;

export type GetBlobFilesSingleFileUploadUrlApi =
  (dataSetAlias: string, fileName: string) => Promise<GetBlobUploadUrlResponse>;

export type StartMultipartBlobFileUploadApi =
  (dataSetAlias: string, fileName: string) => Promise<BlobFilesBeginUploadResponse>;

export type CancelMultipartBlobFileUploadApi =
  (dataSetAlias: string, uploadId: string) => Promise<void>;

export type CompleteMultipartBlobFileUploadApi =
  (dataSetAlias: string, uploadId: string, json: string) => Promise<CompleteBlobFilesUploadResponse>;

export type GetMultiPartBlobUploadUrlApi =
  (dataSetAlias: string, uploadId: string, startingPartNumber: number, partCount: number)
    => Promise<DataLakeMultiPartUploadUrlMapAttributes>;

export type GetDataLakeFileMetadataApi = (dataSet: string,
                                          file: string,
                                          accountId: string) => Promise<GetDataLakeFileMetadataResponse>;

export interface DataLakeFileTransferClientApi {
  getAcquiredFilesDownloadUrl: GetAcquiredFilesDownloadUrlApi;
  getAcquiredStreamDownloadUrl: GetAcquiredStreamDownloadUrlApi;
  getBlobFilesDownloadUrl: GetBlobFilesDownloadUrlApi;
  getAcquiredFilesCredentials: GetAcquiredFilesCredentialsApi;
  getAcquiredStreamCredentials: GetAcquiredStreamCredentialsApi;
  getBlobFilesCredentials: GetBlobFilesCredentialsApi;
  getBlobFilesSingleFileUploadUrl: GetBlobFilesSingleFileUploadUrlApi;
  startMultipartBlobFileUpload: StartMultipartBlobFileUploadApi;
  cancelMultipartBlobFileUpload: CancelMultipartBlobFileUploadApi;
  completeMultipartBlobFileUpload: CompleteMultipartBlobFileUploadApi;
  getMultiPartBlobUploadUrl: GetMultiPartBlobUploadUrlApi;
  getDataLakeFileMetadata: GetDataLakeFileMetadataApi;
}

enum ActionType {
  GET_ACQUIRED_FILES_DOWNLOAD_URL_REQUEST = "data_lake_file_transfer_client/get_acquired_files_download_url_request",
  GET_ACQUIRED_FILES_DOWNLOAD_URL_SUCCESS = "data_lake_file_transfer_client/get_acquired_files_download_url_success",
  GET_ACQUIRED_FILES_DOWNLOAD_URL_FAILED = "data_lake_file_transfer_client/get_acquired_files_download_url_failed",

  GET_ACQUIRED_STREAM_DOWNLOAD_URL_REQUEST = "data_lake_file_transfer_client/get_acquired_stream_download_url_request",
  GET_ACQUIRED_STREAM_DOWNLOAD_URL_SUCCESS = "data_lake_file_transfer_client/get_acquired_stream_download_url_success",
  GET_ACQUIRED_STREAM_DOWNLOAD_URL_FAILED = "data_lake_file_transfer_client/get_acquired_stream_download_url_failed",

  GET_BLOB_FILES_DOWNLOAD_URL_REQUEST = "data_lake_file_transfer_client/get_blob_files_download_url_request",
  GET_BLOB_FILES_DOWNLOAD_URL_SUCCESS = "data_lake_file_transfer_client/get_blob_files_download_url_success",
  GET_BLOB_FILES_DOWNLOAD_URL_FAILED = "data_lake_file_transfer_client/get_blob_files_download_url_failed",

  GET_ACQUIRED_FILES_CREDENTIALS_REQUEST = "data_lake_file_transfer_client/get_acquired_files_credentials_request",
  GET_ACQUIRED_FILES_CREDENTIALS_SUCCESS = "data_lake_file_transfer_client/get_acquired_files_credentials_success",
  GET_ACQUIRED_FILES_CREDENTIALS_FAILED = "data_lake_file_transfer_client/get_acquired_files_credentials_failed",

  GET_ACQUIRED_STREAM_CREDENTIALS_REQUEST = "data_lake_file_transfer_client/get_acquired_stream_credentials_request",
  GET_ACQUIRED_STREAM_CREDENTIALS_SUCCESS = "data_lake_file_transfer_client/get_acquired_stream_credentials_success",
  GET_ACQUIRED_STREAM_CREDENTIALS_FAILED = "data_lake_file_transfer_client/get_acquired_stream_credentials_failed",

  GET_BLOB_FILES_CREDENTIALS_REQUEST = "data_lake_file_transfer_client/get_blob_files_credentials_request",
  GET_BLOB_FILES_CREDENTIALS_SUCCESS = "data_lake_file_transfer_client/get_blob_files_credentials_success",
  GET_BLOB_FILES_CREDENTIALS_FAILED = "data_lake_file_transfer_client/get_blob_files_credentials_failed",

  GET_BLOB_FILES_SINGLE_FILE_UPLOAD_URL_REQUEST = "data_lake_file_transfer_client/get_blob_files_single_file_upload_url_request",
  GET_BLOB_FILES_SINGLE_FILE_UPLOAD_URL_SUCCESS = "data_lake_file_transfer_client/get_blob_files_single_file_upload_url_success",
  GET_BLOB_FILES_SINGLE_FILE_UPLOAD_URL_FAILED = "data_lake_file_transfer_client/get_blob_files_single_file_upload_url_failed",

  START_MULTIPART_BLOB_FILE_UPLOAD_REQUEST = "data_lake_file_transfer_client/start_multipart_blob_file_upload_request",
  START_MULTIPART_BLOB_FILE_UPLOAD_SUCCESS = "data_lake_file_transfer_client/start_multipart_blob_file_upload_success",
  START_MULTIPART_BLOB_FILE_UPLOAD_FAILED = "data_lake_file_transfer_client/start_multipart_blob_file_upload_failed",

  CANCEL_MULTIPART_BLOB_FILE_UPLOAD_REQUEST =
    "data_lake_file_transfer_client/cancel_multipart_blob_file_upload_request",
  CANCEL_MULTIPART_BLOB_FILE_UPLOAD_SUCCESS =
    "data_lake_file_transfer_client/cancel_multipart_blob_file_upload_success",
  CANCEL_MULTIPART_BLOB_FILE_UPLOAD_FAILED =
    "data_lake_file_transfer_client/cancel_multipart_blob_file_upload_failed",

  COMPLETE_MULTIPART_BLOB_FILE_UPLOAD_REQUEST = "data_lake_file_transfer_client/complete_multipart_blob_file_upload_request",
  COMPLETE_MULTIPART_BLOB_FILE_UPLOAD_SUCCESS = "data_lake_file_transfer_client/complete_multipart_blob_file_upload_success",
  COMPLETE_MULTIPART_BLOB_FILE_UPLOAD_FAILED = "data_lake_file_transfer_client/complete_multipart_blob_file_upload_failed",

  GET_MULTIPART_BLOB_UPLOAD_URL_REQUEST = "data_lake_file_transfer_client/get_multipart_blob_upload_url_request",
  GET_MULTIPART_BLOB_UPLOAD_URL_SUCCESS = "data_lake_file_transfer_client/get_multipart_blob_upload_url_success",
  GET_MULTIPART_BLOB_UPLOAD_URL_FAILED = "data_lake_file_transfer_client/get_multipart_blob_upload_url_failed",

  GET_DATA_LAKE_FILE_METADATA_REQUEST = "data_lake_file_transfer_client/get_data_lake_file_metadata_request",
  GET_DATA_LAKE_FILE_METADATA_SUCCESS = "data_lake_file_transfer_client/get_data_lake_file_metadata_success",
  GET_DATA_LAKE_FILE_METADATA_FAILED = "data_lake_file_transfer_client/get_data_lake_file_metadata_failed",
}

export const useGetAcquiredFilesDownloadUrlApi = (): GetAcquiredFilesDownloadUrlApi =>
  useApiRequestAnalytics(DataLakeFileTransferClient.getAcquiredFilesDownloadUrl, {
    REQUEST_EVENT: ActionType.GET_ACQUIRED_FILES_DOWNLOAD_URL_REQUEST,
    SUCCESS_EVENT: ActionType.GET_ACQUIRED_FILES_DOWNLOAD_URL_SUCCESS,
    ERROR_EVENT: ActionType.GET_ACQUIRED_FILES_DOWNLOAD_URL_FAILED,
  });

export const useGetAcquiredStreamDownloadUrlApi = (): GetAcquiredStreamDownloadUrlApi =>
  useApiRequestAnalytics(DataLakeFileTransferClient.getAcquiredStreamDownloadUrl, {
    REQUEST_EVENT: ActionType.GET_ACQUIRED_STREAM_DOWNLOAD_URL_REQUEST,
    SUCCESS_EVENT: ActionType.GET_ACQUIRED_STREAM_DOWNLOAD_URL_SUCCESS,
    ERROR_EVENT: ActionType.GET_ACQUIRED_STREAM_DOWNLOAD_URL_FAILED,
  });

export const useGetBlobFilesDownloadUrlApi = (): GetBlobFilesDownloadUrlApi =>
  useApiRequestAnalytics(DataLakeFileTransferClient.getBlobFilesDownloadUrl, {
    REQUEST_EVENT: ActionType.GET_BLOB_FILES_DOWNLOAD_URL_REQUEST,
    SUCCESS_EVENT: ActionType.GET_BLOB_FILES_DOWNLOAD_URL_SUCCESS,
    ERROR_EVENT: ActionType.GET_BLOB_FILES_DOWNLOAD_URL_FAILED,
  });

export const useGetAcquiredFilesCredentialsApi = (): GetAcquiredFilesCredentialsApi =>
  useApiRequestAnalytics(DataLakeFileTransferClient.getAcquiredFilesCredentials, {
    REQUEST_EVENT: ActionType.GET_ACQUIRED_FILES_CREDENTIALS_REQUEST,
    SUCCESS_EVENT: ActionType.GET_ACQUIRED_FILES_CREDENTIALS_SUCCESS,
    ERROR_EVENT: ActionType.GET_ACQUIRED_FILES_CREDENTIALS_FAILED,
  });

export const useGetAcquiredStreamCredentialsApi = (): GetAcquiredStreamCredentialsApi =>
  useApiRequestAnalytics(DataLakeFileTransferClient.getAcquiredStreamCredentials, {
    REQUEST_EVENT: ActionType.GET_ACQUIRED_STREAM_CREDENTIALS_REQUEST,
    SUCCESS_EVENT: ActionType.GET_ACQUIRED_STREAM_CREDENTIALS_SUCCESS,
    ERROR_EVENT: ActionType.GET_ACQUIRED_STREAM_CREDENTIALS_FAILED,
  });

export const useGetBlobFilesCredentialsApi = (): GetBlobFilesCredentialsApi =>
  useApiRequestAnalytics(DataLakeFileTransferClient.getBlobFilesCredentials, {
    REQUEST_EVENT: ActionType.GET_BLOB_FILES_CREDENTIALS_REQUEST,
    SUCCESS_EVENT: ActionType.GET_BLOB_FILES_CREDENTIALS_SUCCESS,
    ERROR_EVENT: ActionType.GET_BLOB_FILES_CREDENTIALS_FAILED,
  });

export const useGetBlobFilesSingleFileUploadUrlApi = (): GetBlobFilesSingleFileUploadUrlApi =>
  useApiRequestAnalytics(DataLakeFileTransferClient.getBlobFilesSingleFileUploadUrl, {
    REQUEST_EVENT: ActionType.GET_BLOB_FILES_SINGLE_FILE_UPLOAD_URL_REQUEST,
    SUCCESS_EVENT: ActionType.GET_BLOB_FILES_SINGLE_FILE_UPLOAD_URL_SUCCESS,
    ERROR_EVENT: ActionType.GET_BLOB_FILES_SINGLE_FILE_UPLOAD_URL_FAILED,
  });

export const useStartMultipartBlobFileUploadApi = (): StartMultipartBlobFileUploadApi =>
  useApiRequestAnalytics(DataLakeFileTransferClient.startMultipartBlobFileUpload, {
    REQUEST_EVENT: ActionType.START_MULTIPART_BLOB_FILE_UPLOAD_REQUEST,
    SUCCESS_EVENT: ActionType.START_MULTIPART_BLOB_FILE_UPLOAD_SUCCESS,
    ERROR_EVENT: ActionType.START_MULTIPART_BLOB_FILE_UPLOAD_FAILED,
  });

export const useCancelMultipartBlobFileUploadApi = (): CancelMultipartBlobFileUploadApi =>
  useApiRequestAnalytics(DataLakeFileTransferClient.cancelMultipartBlobFileUpload, {
    REQUEST_EVENT: ActionType.CANCEL_MULTIPART_BLOB_FILE_UPLOAD_REQUEST,
    SUCCESS_EVENT: ActionType.CANCEL_MULTIPART_BLOB_FILE_UPLOAD_SUCCESS,
    ERROR_EVENT: ActionType.CANCEL_MULTIPART_BLOB_FILE_UPLOAD_FAILED,
  });

export const useCompleteMultipartBlobFileUploadApi = (): CompleteMultipartBlobFileUploadApi =>
  useApiRequestAnalytics(DataLakeFileTransferClient.completeMultipartBlobFileUpload, {
    REQUEST_EVENT: ActionType.COMPLETE_MULTIPART_BLOB_FILE_UPLOAD_REQUEST,
    SUCCESS_EVENT: ActionType.COMPLETE_MULTIPART_BLOB_FILE_UPLOAD_SUCCESS,
    ERROR_EVENT: ActionType.COMPLETE_MULTIPART_BLOB_FILE_UPLOAD_FAILED,
  });

export const useGetMultiPartBlobUploadUrlApi = (): GetMultiPartBlobUploadUrlApi =>
  useApiRequestAnalytics(DataLakeFileTransferClient.getMultiPartBlobUploadUrl, {
    REQUEST_EVENT: ActionType.GET_MULTIPART_BLOB_UPLOAD_URL_REQUEST,
    SUCCESS_EVENT: ActionType.GET_MULTIPART_BLOB_UPLOAD_URL_SUCCESS,
    ERROR_EVENT: ActionType.GET_MULTIPART_BLOB_UPLOAD_URL_FAILED,
  });

export const useGetDataLakeFileMetadata = (): GetDataLakeFileMetadataApi =>
  useApiRequestAnalytics(DataLakeFileTransferClient.getDataLakeFileMetadata, {
    REQUEST_EVENT: ActionType.GET_DATA_LAKE_FILE_METADATA_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DATA_LAKE_FILE_METADATA_SUCCESS,
    ERROR_EVENT: ActionType.GET_DATA_LAKE_FILE_METADATA_FAILED,
  });

export const useDataLakeFileTransferClient = (): DataLakeFileTransferClientApi => ({
  getAcquiredFilesDownloadUrl: useGetAcquiredFilesDownloadUrlApi(),
  getAcquiredStreamDownloadUrl: useGetAcquiredStreamDownloadUrlApi(),
  getBlobFilesDownloadUrl: useGetBlobFilesDownloadUrlApi(),
  getAcquiredFilesCredentials: useGetAcquiredFilesCredentialsApi(),
  getAcquiredStreamCredentials: useGetAcquiredStreamCredentialsApi(),
  getBlobFilesCredentials: useGetBlobFilesCredentialsApi(),
  getBlobFilesSingleFileUploadUrl: useGetBlobFilesSingleFileUploadUrlApi(),
  startMultipartBlobFileUpload: useStartMultipartBlobFileUploadApi(),
  cancelMultipartBlobFileUpload: useCancelMultipartBlobFileUploadApi(),
  completeMultipartBlobFileUpload: useCompleteMultipartBlobFileUploadApi(),
  getMultiPartBlobUploadUrl: useGetMultiPartBlobUploadUrlApi(),
  getDataLakeFileMetadata: useGetDataLakeFileMetadata(),
});

export { ActionType as DataLakeFileTransferClientActionType };

export default useDataLakeFileTransferClient;
