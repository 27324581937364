import React from "react";
import { SecurityGroup, SecurityGroupAttributes } from "@data";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useSecurityGroupsClient,
} from "@hooks";

type SuccessResponse = SecurityGroupAttributes;

export interface UseSecurityGroupProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  groupName?: string;
  securityGroup?: SecurityGroup;
}

export interface UseSecurityGroupModel extends UseApiRequestModel<SuccessResponse> {
  securityGroup: SecurityGroup;
}

export interface UseSecurityGroupActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseSecurityGroupProps;
type Model = UseSecurityGroupModel;
type Actions = UseSecurityGroupActions;
type Result = [Model, Actions];

export const useSecurityGroup = (props: Props = {}): Result => {

  const {
    groupName = "",
    securityGroup: initialSecurityGroup = new SecurityGroup(),
    defaultErrorMessage = "Failed to get security group",
    ...otherProps
  } = props;

  const SecurityGroupsClient = useSecurityGroupsClient();

  const makeApiRequest = React.useCallback(() =>
      SecurityGroupsClient.getSecurityGroup(groupName),
    [SecurityGroupsClient, groupName]);

  const [{successResponse, ...baseModel}, {...baseActions}] = useApiRequest<SuccessResponse>({
    ...otherProps,
    defaultErrorMessage,
    makeApiRequest,
  });

  const securityGroup = React.useMemo(() => {
    const attrs = successResponse || {};
    return new SecurityGroup(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    securityGroup,
  }), [
    baseModel,
    securityGroup,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
  }), [
    baseActions,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useSecurityGroup;
