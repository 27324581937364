import { logout } from "@main/actions";
import { isEmptyString } from "@util";
import { AppSchema } from "@main/schemas";
import { createActions } from "@modules/base";
import { reloadUsers } from "@modules/users/actions";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { OrchestrationServiceClient, RestClientError } from "@network";
import { getAccessToken, getUserId, isSelfAuthorized } from "./selectors";

export const {
  userId: setUserId,
  open: setOpen,
  selfAuthorized: setSelfAuthorized,
  setErrorMessage,
  setSuccessMessage,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  CLOSE_USER_REQUEST: closeUserRequest,
  CLOSE_USER_SUCCESS: closeUserSuccess,
  CLOSE_USER_FAILED: closeUserFailed,
  CLOSE_USER_CREATE_ADMIN_NOTE_REQUEST: closeUserCreateAdminNoteRequest,
  CLOSE_USER_CREATE_ADMIN_NOTE_SUCCESS: closeUserCreateAdminNoteSuccess,
  CLOSE_USER_CREATE_ADMIN_NOTE_FAILED: closeUserCreateAdminNoteFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setUserId());
  dispatch(setOpen());
  dispatch(setSelfAuthorized());
  return dispatch(baseReset());
};

export const closeUser = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const userId = getUserId(state);
  const accessToken = getAccessToken(state);
  const selfAuthorized = isSelfAuthorized(state);

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(closeUserRequest());

  return OrchestrationServiceClient.closeUserAccount(accessToken, userId, selfAuthorized)
    .then(() => {

      dispatch(closeUserSuccess());
      dispatch(hideLoadingIndicator());
      dispatch(setSuccessMessage("User Account Closed"));

      if (selfAuthorized) {
        return dispatch(logout());
      }

      return dispatch(reloadUsers());

    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to close user account" } = response;

      dispatch(closeUserFailed(analytic));
      dispatch(hideLoadingIndicator());

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(setErrorMessage(error));
    });
};

export const open = (userId: string, selfAuthorized?: boolean) => (dispatch: any) => {

  if (isEmptyString(userId)) {
    return Promise.resolve();
  }

  dispatch(reset());
  dispatch(setUserId(userId));
  dispatch(setSelfAuthorized(selfAuthorized));
  return dispatch(setOpen(true));
};

export const close = reset;

export const initialize = reset;
