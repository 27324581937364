import React from "react";
import classnames from "classnames";
import { ActionMenuItem, ActionsMenu, ActionsMenuProps } from "@components";
import { noop } from "@util";

export enum ApplicationAction {
  EDIT_APPLICATION = "edit_application",
  ROTATE_APPLICATION_SECRET = "rotate_application_secret",
  DELETE_APPLICATION_REGIONAL = "delete_application_regional",
  GENERATE_APPLICATION_SECRET = "generate_application_secret",
  SET_APPLICATION_SECRET = "set_application_secret",
  DELETE_APPLICATION_SECRET = "delete_application_secret",
  AUTHENTICATE_APPLICATION = "authenticate_application",
}

export const DELETE_APPLICATION_REGIONAL: ActionMenuItem = Object.freeze({
  id: ApplicationAction.DELETE_APPLICATION_REGIONAL,
  name: "Delete Application",
  disabled: false,
});

export const EDIT_APPLICATION: ActionMenuItem = Object.freeze({
  id: ApplicationAction.EDIT_APPLICATION,
  name: "Edit Application",
  disabled: false,
});

export const ROTATE_APPLICATION_SECRET: ActionMenuItem = Object.freeze({
  id: ApplicationAction.ROTATE_APPLICATION_SECRET,
  name: "Rotate Application Secret",
  disabled: false,
});

export const GENERATE_APPLICATION_SECRET: ActionMenuItem = Object.freeze({
  id: ApplicationAction.GENERATE_APPLICATION_SECRET,
  name: "Generate New Secret",
  disabled: false,
});

export const SET_APPLICATION_SECRET: ActionMenuItem = Object.freeze({
  id: ApplicationAction.SET_APPLICATION_SECRET,
  name: "Set New Secret",
  tooltip: "Set Secret explicitly",
  disabled: false,
});

export const DELETE_APPLICATION_SECRET: ActionMenuItem = Object.freeze({
  id: ApplicationAction.DELETE_APPLICATION_SECRET,
  name: "Delete Secondary Secret",
  disabled: false,
});

export const AUTHENTICATE_APPLICATION: ActionMenuItem = Object.freeze({
  id: ApplicationAction.AUTHENTICATE_APPLICATION,
  name: "Authenticate Application",
  disabled: false,
  tooltip: "Verify your application secret",
});

export const DEFAULT_APPLICATION_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  EDIT_APPLICATION,
  ROTATE_APPLICATION_SECRET,
  DELETE_APPLICATION_REGIONAL,
  GENERATE_APPLICATION_SECRET,
  SET_APPLICATION_SECRET,
  DELETE_APPLICATION_SECRET,
  AUTHENTICATE_APPLICATION,
]) as ActionMenuItem[];

export interface ApplicationActionsMenuProps extends ActionsMenuProps {
  deleteApplicationRegional?: () => void;
  editApplication?: () => void;
  rotateApplicationSecret?: () => void;
  generateSecret?: () => void;
  setSecret?: () => void;
  deleteSecret?: () => void;
  authenticateApplication?: () => void;
}

export const withApplicationActions = (Component: React.ComponentType<ApplicationActionsMenuProps>) =>
  (props: ApplicationActionsMenuProps) => {

    const {
      actions = DEFAULT_APPLICATION_ACTION_MENU_ITEMS,
      deleteApplicationRegional = noop,
      editApplication = noop,
      rotateApplicationSecret = noop,
      generateSecret = noop,
      setSecret = noop,
      deleteSecret = noop,
      authenticateApplication = noop,
      onClickAction = noop,
    } = props;

    return (
      <Component
        {...props}
        actions={actions}
        onClickAction={(action: ActionMenuItem) => {
          switch (action.id) {
          case ApplicationAction.EDIT_APPLICATION:
            return editApplication();
          case ApplicationAction.ROTATE_APPLICATION_SECRET:
            return rotateApplicationSecret();
          case ApplicationAction.DELETE_APPLICATION_REGIONAL:
            return deleteApplicationRegional();
          case ApplicationAction.GENERATE_APPLICATION_SECRET:
            return generateSecret();
          case ApplicationAction.SET_APPLICATION_SECRET:
            return setSecret();
          case ApplicationAction.DELETE_APPLICATION_SECRET:
            return deleteSecret();
          case ApplicationAction.AUTHENTICATE_APPLICATION:
            return authenticateApplication();
          default:
            return onClickAction(action);
          }
        }}
      />
    );
  };

export const ApplicationActionsMenu = withApplicationActions((props: ApplicationActionsMenuProps) => {

  const { className, buttonLabel = "Application Actions" } = props;

  return (
    <ActionsMenu
      {...props}
      className={classnames("applicationActionsMenu", className)}
      buttonLabel={buttonLabel}
    />
  );
});

export default ApplicationActionsMenu;
