import { createSelector } from "reselect";
import { AppSchema } from "@main/schemas";
import { isEmptyString, withModuleSelector } from "@util";
import { IdentityType, User, UserAttributes } from "@data";
import { BasePortalModuleSchema as BaseSchema } from "./createReducers";

export type Selector<T = any> = (state: AppSchema) => T;

export type ModuleSelectors<S extends BaseSchema = BaseSchema> = {
  [K in keyof S]: Selector<S[K]>;
};

export type ModuleSelector<S extends BaseSchema = BaseSchema> =
  ModuleSelectors<S>[keyof ModuleSelectors<S>];

export type CreateModuleSelector<S extends BaseSchema> =
  <T>(key: keyof S) => ModuleSelector<S>;

export type PortalModuleSelectors<S extends BaseSchema> = ModuleSelectors<S> & {
  getAccessToken: Selector<string>;
  getCurrentUser: Selector<User>;
  getCurrentUserId: Selector<string>;
  getCurrentAccountId: Selector<string>;
  getPrincipalId: Selector<string>;
  getIdentityType: Selector<IdentityType>;
  isUserPrincipal: Selector<boolean>;
  isServicePrincipal: Selector<boolean>;
  getCurrentServiceId: Selector<string>;
  getErrors: Selector<string[]>,
  getErrorMessage: Selector<string>;
  getSuccessMessage: Selector<string>;
  isErrorMessageVisible: Selector<boolean>;
  isSuccessMessageVisible: Selector<boolean>;
  isEmptyViewVisible: Selector<boolean>;
  isAccessDeniedVisible: Selector<boolean>;
  isLoadingIndicatorVisible: Selector<boolean>;
  isNotFoundVisible: Selector<boolean>;
  createModuleSelector: CreateModuleSelector<S>;
};

export const createSelectors =
  <S extends BaseSchema>(moduleId: string,
                         schemaKey: keyof AppSchema,
                         defaultState: S): PortalModuleSelectors<S> => {

    if (isEmptyString(moduleId)) {
      throw new Error("Missing Argument: [moduleId]");
    }

    if (isEmptyString(schemaKey)) {
      throw new Error("Missing Argument: [schemaKey]");
    }

    const createModuleSelector = (key: keyof S) => withModuleSelector(schemaKey, key);

    const selectors = Object.keys(defaultState)
      .reduce((moduleSelectors: ModuleSelectors<S>, key: keyof ModuleSelectors) => {
        moduleSelectors[key] = createModuleSelector(key);
        return moduleSelectors;
      }, {} as ModuleSelectors<S>);

    const isErrorMessageVisible: Selector<boolean> = createSelector(
      selectors.errorMessage, (errorMessage: string) => !isEmptyString(errorMessage));

    const isSuccessMessageVisible: Selector<boolean> = createSelector(
      selectors.successMessage, (successMessage: string) => !isEmptyString(successMessage));

    const getCurrentUserAttributes: Selector<UserAttributes> = state => state.currentUser;

    const getCurrentUser: Selector<User> = createSelector(
      getCurrentUserAttributes, (attrs: UserAttributes) => new User(attrs));

    const getCurrentUserId: Selector<string> = createSelector(
      getCurrentUser, (user: User) => user.getUserId());

    const getCurrentAccountId: Selector<string> = state => state.accountId;

    const getPrincipalId: Selector<string> = state => state.principalId;

    const getIdentityType: Selector<IdentityType> = state => state.identityType;

    const isUserPrincipal: Selector<boolean> = createSelector(
      [getPrincipalId, getIdentityType], (principalId: string, identityType: IdentityType) =>
        !isEmptyString(principalId) && (identityType === IdentityType.USER));

    const isServicePrincipal: Selector<boolean> = createSelector(
      [getPrincipalId, getIdentityType], (principalId: string, identityType: IdentityType) =>
        !isEmptyString(principalId) && (identityType === IdentityType.SERVICE));

    const getCurrentServiceId: Selector<string> = createSelector(
      [isServicePrincipal, getPrincipalId], (serviceIdentity: boolean, principalId: string) =>
        !serviceIdentity ? "" : principalId);

    const getAccessToken: Selector<string> = state => state.authToken;

    const {
      errors: getErrors,
      errorMessage: getErrorMessage,
      successMessage: getSuccessMessage,
      showEmptyView: isEmptyViewVisible,
      showAccessDenied: isAccessDeniedVisible,
      showLoadingIndicator: isLoadingIndicatorVisible,
      showNotFound: isNotFoundVisible,
    } = selectors;

    return {
      ...selectors,
      getAccessToken,
      getCurrentUser,
      getCurrentUserId,
      getCurrentAccountId,
      getPrincipalId,
      getIdentityType,
      isUserPrincipal,
      isServicePrincipal,
      getCurrentServiceId,
      getErrors,
      getErrorMessage,
      getSuccessMessage,
      isErrorMessageVisible,
      isSuccessMessageVisible,
      isEmptyViewVisible,
      isAccessDeniedVisible,
      isLoadingIndicatorVisible,
      isNotFoundVisible,
      createModuleSelector,
    };
  };
