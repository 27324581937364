import { Module } from "@data";
import { initialize, onMount } from "./actions";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./SecurityGroupsView";

export const securityGroupsModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Security Groups",
  title: "Security Group Management",
  className: "securityGroupsModule",
  primaryColor,
  icon,
  view,
  backButton: null,
  initialize,
  onMount,
});

export default securityGroupsModule;
