import { Theme } from "@material-ui/core/styles/createTheme";
import { createStyles } from "@material-ui/core/styles";
import { Color } from "../../../../components/styles";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      flex: "0 0 auto",
      backgroundColor: theme.palette.grey[50],
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      padding: "20px 40px 30px",
    },
    title: {
      flex: "0 0 auto",
      color: theme.palette.grey[200],
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "24px",
    },
    contactInfo: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
    },
    emailAddress: {
      color: Color.LIGHT_BLUE,
      fontFamily: "Roboto, sans-serif",
      fontSize: 16,
      fontWeight: "normal",
      lineHeight: "24px",
      textDecoration: "none",
      marginLeft: 150,
      "&:first-child": {
        marginLeft: 0,
      },
    },
  });
