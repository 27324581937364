import { Record } from "immutable";

export interface MultipartUploadAttributes {
  etag: string;
  partNumber: number;
}

export interface DataLakeUploadCompleteRequestAttributes {
  completeMultipartUpload: MultipartUploadAttributes[];
}

export class DataLakeUploadCompleteRequest extends Record({
  completeMultipartUpload: []
}) implements DataLakeUploadCompleteRequestAttributes {

  public static EMPTY: DataLakeUploadCompleteRequest = new DataLakeUploadCompleteRequest();

  public readonly completeMultipartUpload: MultipartUploadAttributes[];

  public getCompleteMultipartUpload(): MultipartUploadAttributes[] {
    return this.completeMultipartUpload;
  }
}

export default DataLakeUploadCompleteRequest;
