import { AppSchema } from "@schemas";
import { IdentityType } from "@data";
import { connect } from "react-redux";
import { isLoggedIn } from "@main/selectors";
import { setAuthToken, setIdentityType, showPortal } from "@main/actions";
import useRestoreServiceSession, {
  UseRestoreServiceSessionActions,
  UseRestoreServiceSessionModel,
} from "@hooks/session/use-restore-service-session";

export interface RestoreServiceSessionModel extends UseRestoreServiceSessionModel {
}

export interface RestoreServiceSessionActions extends UseRestoreServiceSessionActions {
}

type Model = RestoreServiceSessionModel;
type Actions = RestoreServiceSessionActions;
type Props = Model & Actions;

const RestoreServiceSessionContainer = (props: Props) => {

  const {
    loggedIn,
    setAccessToken,
    showRestoreSessionLoadingIndicator,
    hideRestoreSessionLoadingIndicator,
    restoreSessionFailed,
  } = props;

  useRestoreServiceSession({
    loggedIn,
    setAccessToken,
    showRestoreSessionLoadingIndicator,
    hideRestoreSessionLoadingIndicator,
    restoreSessionFailed,
  });

  return null;
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  loggedIn: isLoggedIn(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setAccessToken: (accessToken: string) => {
    dispatch(setAuthToken(accessToken));
    dispatch(setIdentityType(IdentityType.SERVICE));
    dispatch(showPortal());
  },
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(RestoreServiceSessionContainer);
