import { isEmptyString } from "@util";
import { CertificateAuthorityAttributes } from "@data";
import { createQueryParams, makeJsonApiRequest, withAuthToken } from "@network/helpers";

const REGIONAL_API = process.env.REACT_APP_REGIONAL_API || "";

if (isEmptyString(REGIONAL_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_REGIONAL_API");
}

export interface GetTrustedCertificateAuthoritiesResponse {
  items: CertificateAuthorityAttributes[];
  paging: {
    next?: string;
  };
}

export const getTrustedCertificateAuthorities = (authToken: string,
                                                 next: string = ""):
  Promise<GetTrustedCertificateAuthoritiesResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams({ next });

    const url = `${REGIONAL_API}/security/management/v1/certificates/trust/authorities${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch trusted certificate authorities failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
