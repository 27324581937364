import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DownloadFile from "@components/download-file";
import SaveButton from "@components/save-button";
import styles from "./styles";

export interface DownloadButtonProps {
  className?: string;
  downloadFileClassName?: string;
  buttonClassName?: string;
  color?: "inherit" | "primary" | "secondary" | "default";
  label?: React.ReactNode | string;
  downloadIndicatorSize?: number;
  href?: string;
  fileName?: string;
  loading?: boolean;
  disabled?: boolean;
  downloadFinishedDelay?: number;
  download?: () => void;
}

type Props = WithStyles<typeof styles> & DownloadButtonProps & {
  children?: React.ReactNode;
};

export const DownloadButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    downloadFileClassName,
    color,
    buttonClassName,
    label = "Download",
    downloadIndicatorSize,
    href,
    fileName,
    loading,
    disabled,
    downloadFinishedDelay,
    download = noop,
    children,
  } = props;

  const [downloading, setDownloading] = React.useState(false);

  const downloadStarted = React.useCallback(() => setDownloading(true), [setDownloading]);

  const downloadFinished = React.useCallback(() => setDownloading(false), [setDownloading]);

  return (
    <React.Fragment>
      <SaveButton
        className={classnames("downloadButton", className, classes.downloadButton)}
        buttonClassName={buttonClassName}
        loading={loading || downloading}
        disabled={disabled}
        color={color}
        label={label}
        downloadIndicatorSize={downloadIndicatorSize}
        save={download}
      />
      <DownloadFile
        className={classnames("downloadFile", downloadFileClassName)}
        href={href}
        fileName={fileName}
        downloadFinishedDelay={downloadFinishedDelay}
        downloadStarted={downloadStarted}
        downloadFinished={downloadFinished}
      />
      {children}
    </React.Fragment>
  );
});

export default DownloadButton;
