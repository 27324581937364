import React from "react";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import classnames from "classnames";

interface ReleaseNoteIssueStatusKeyModel {
}

interface ReleaseNoteIssueStatusKeyActions {
}

type Model = ReleaseNoteIssueStatusKeyModel;
type Actions = ReleaseNoteIssueStatusKeyActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const ReleaseNoteIssueStatusKey = withStyles(styles)((props: Props) => {

  const {
    classes,
  } = props;

  return (
    <Table className={classes.container}>
      <TableHead>
        <TableRow>
          <TableCell className={classnames(classes.planned, classes.cell)}>
            <Typography className={classes.label}>
              Planned
            </Typography>
          </TableCell>
          <TableCell className={classnames(classes.inProgress, classes.cell)}>
            <Typography className={classes.label}>
              In Progress
            </Typography>
          </TableCell>
          <TableCell className={classnames(classes.postponed, classes.cell)}>
            <Typography className={classes.label}>
              Postponed
            </Typography>
          </TableCell>
          <TableCell className={classnames(classes.completed, classes.cell)}>
            <Typography className={classes.label}>
              Completed
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
});

export default ReleaseNoteIssueStatusKey;
