import { AppSchema } from "../../../main/schemas";
import { JsonSchemaProperty, JsonSchemaPropertyAttributes } from "../../../../data";
import {
  DEFAULT_STATE,
  RemovePropertyAction,
  RemovePropertyActionType,
} from "../../reducers/removeProperty";
import { RemovePropertySelectors } from "../../selectors/removeProperty";
import { SchemaWizardActions } from "../../actions/schemaWizard";
import { PropertiesTableActions } from "../propertiesTable";

export const setProperty =
  (value: JsonSchemaPropertyAttributes = DEFAULT_STATE.property): RemovePropertyAction => ({
    type: RemovePropertyActionType.SET_PROPERTY,
    value,
  });

export const reset = () => (dispatch: any) => {
  return dispatch(setProperty());
};

export const open = (property: JsonSchemaProperty = JsonSchemaProperty.EMPTY) =>
  (dispatch: any) => {

    dispatch(reset());
    dispatch(setProperty({ ...property.toJS() }));
    return dispatch(SchemaWizardActions.showRemoveProperty());
  };

export const close = () => (dispatch: any) => {

  dispatch(SchemaWizardActions.hideRemoveProperty());

  return dispatch(reset());
};

export const removeProperty = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const property = RemovePropertySelectors.getProperty(state);

  dispatch(PropertiesTableActions.removeProperty(property));

  return dispatch(close());
};
