import { AppSchema } from "@main/schemas";
import { createActions } from "@modules/base";
import { isEmptyString, ReduxAction } from "@util";
import { RestClientError, UserIdmLegacyClient } from "@network";
import { randomStringGenerator } from "@util/RandomStringGenerator";
import { UpdateUserProfileRequest, User, UserProfileAttributesMap } from "@data";
import { ACTION_TYPES, CreateUserActionType as ActionType, DEFAULT_STATE } from "./reducers";
import {
  getAccessToken,
  getFederationProviders,
  getMfaSecret,
  getPassword,
  getSelectedFederationProvider,
  getUserId,
  getUserProfileAttributesMap,
  isFederatedUser,
  isMfaEnabled,
  isPortalUser,
} from "./selectors";

export const {
  userId: setUserId,
  userIdErrorMessage: setUserIdErrorMessage,
  password: setPassword,
  passwordErrorMessage: setPasswordErrorMessage,
  showPassword: setShowPassword,
  mfaEnabled: setMfaEnabled,
  mfaSecret: setMfaSecret,
  mfaSecretErrorMessage: setMfaSecretErrorMessage,
  user: setUser,
  profile: setProfile,
  isPortalUser: setIsPortalUser,
  isFederatedUser: setIsFederatedUser,
  federationProviders: setFederationProviders,
  selectedFederationProvider: setSelectedFederationProvider,
  setErrorMessage,
  setSuccessMessage,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  CREATE_USER_REQUEST: createUserRequest,
  CREATE_USER_SUCCESS: createUserSuccess,
  CREATE_USER_FAILED: createUserFailed,
  CREATE_PORTAL_USER_REQUEST: createPortalUserRequest,
  CREATE_PORTAL_USER_SUCCESS: createPortalUserSuccess,
  CREATE_PORTAL_USER_FAILED: createPortalUserFailed,
  CREATE_FEDERATED_USER_REQUEST: createFederatedUserRequest,
  CREATE_FEDERATED_USER_SUCCESS: createFederatedUserSuccess,
  CREATE_FEDERATED_USER_FAILED: createFederatedUserFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setUserId());
  dispatch(setUserIdErrorMessage());
  dispatch(setPassword());
  dispatch(setPasswordErrorMessage());
  dispatch(setShowPassword());
  dispatch(setMfaEnabled());
  dispatch(setMfaSecret());
  dispatch(setMfaSecretErrorMessage());
  dispatch(setUser());
  dispatch(setProfile());
  dispatch(setIsPortalUser());
  dispatch(setIsFederatedUser());
  dispatch(setFederationProviders());
  dispatch(setSelectedFederationProvider());
  return dispatch(baseReset());
};

export const showPassword = (): ReduxAction<ActionType> => setShowPassword(true);
export const hidePassword = (): ReduxAction<ActionType> => setShowPassword(false);

export const enableMfa = (): ReduxAction<ActionType> => setMfaEnabled(true);
export const disableMfa = (): ReduxAction<ActionType> => setMfaEnabled(false);

export const updateUserId = (userId?: string) => (dispatch: any) => {
  dispatch(setErrorMessage());
  dispatch(setUserIdErrorMessage());
  return dispatch(setUserId(userId));
};

export const updatePassword = (password?: string) => (dispatch: any) => {
  dispatch(setErrorMessage());
  dispatch(setPasswordErrorMessage());
  return dispatch(setPassword(password));
};

export const updateMfaSecret = (mfaSecret?: string) => (dispatch: any) => {
  dispatch(setErrorMessage());
  dispatch(setMfaSecretErrorMessage());
  return dispatch(setMfaSecret(mfaSecret));
};

export const updateProfile = (profile: UserProfileAttributesMap) => (dispatch: any) => {

  const profileAttributes = Object.keys(profile)
    .reduce((attributes: UserProfileAttributesMap, key: string) => {
      const attribute = profile[key];
      attributes[attribute.getName()] = attribute.toJS();
      return attributes;
    }, {});

  dispatch(setErrorMessage());
  return dispatch(setProfile(profileAttributes));
};

export const updateFederatedUserType = (federatedUser?: boolean) => (dispatch: any) => {
  dispatch(setErrorMessage());
  dispatch(setUserIdErrorMessage());
  dispatch(updatePassword());
  dispatch(updateMfaSecret());
  dispatch(setSelectedFederationProvider());
  return dispatch(setIsFederatedUser(federatedUser));
};

export const updateUserType = (portalUser: boolean) => (dispatch: any) => {
  dispatch(setErrorMessage());
  dispatch(setUserIdErrorMessage());
  dispatch(updatePassword());
  dispatch(updateMfaSecret());
  dispatch(setSelectedFederationProvider());
  dispatch(setIsFederatedUser());
  return dispatch(setIsPortalUser(portalUser));
};

export const createNormalUser = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const userId = getUserId(state);
  const password = getPassword(state);
  const mfaEnabled = isMfaEnabled(state);
  const mfaSecret = getMfaSecret(state);
  const accessToken = getAccessToken(state);
  const federationProviders = getFederationProviders(state);
  const profileAttributes = getUserProfileAttributesMap(state);

  const isUserIdValid = !isEmptyString(userId);
  const isPasswordValid = !isEmptyString(password);
  const isMfaSecretValid = !mfaEnabled || !isEmptyString(mfaSecret);
  const { profile = {} } = UpdateUserProfileRequest.from(profileAttributes).toJS();

  const isFederatedUserId = isUserIdValid && federationProviders
    .some(federationProvider => new RegExp(`^.*@${federationProvider}$`).test(userId));

  if (!isUserIdValid) {
    dispatch(setUserIdErrorMessage("Invalid User ID"));
  } else if (isFederatedUserId) {
    dispatch(setUserIdErrorMessage(
      "This email address cannot be used because there is a federation provider registered for this domain"));
  } else {
    dispatch(setUserIdErrorMessage());
  }

  if (!isPasswordValid) {
    dispatch(setPasswordErrorMessage("Invalid Password"));
  } else {
    dispatch(setPasswordErrorMessage());
  }

  if (!isMfaSecretValid) {
    dispatch(setMfaSecretErrorMessage("Invalid Secret"));
  } else {
    dispatch(setMfaSecretErrorMessage());
  }

  if (!isUserIdValid || !isPasswordValid || !isMfaSecretValid || isFederatedUserId) {
    return Promise.resolve();
  }

  dispatch(showLoadingIndicator());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(hideAccessDenied());
  dispatch(createUserRequest());

  const json = JSON.stringify({
    userId,
    password,
    profile,
  });

  return UserIdmLegacyClient.createUser(accessToken, json)
    .then(() => {

      dispatch(createUserSuccess());
      dispatch(setUser(new User({ id: userId, userId }).toJS()));
      dispatch(setSuccessMessage("User Created"));
      return dispatch(hideLoadingIndicator());

    }, (response: RestClientError) => {

      const { analytic, error } = response;

      dispatch(createUserFailed(analytic));
      dispatch(setErrorMessage(error));

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideLoadingIndicator());
    });
};

export const createPortalUser = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const userId = getUserId(state);
  const password = randomStringGenerator();
  const accessToken = getAccessToken(state);
  const { profile = {} } = UpdateUserProfileRequest.from(getUserProfileAttributesMap(state)).toJS();

  if (isEmptyString(userId)) {
    return dispatch(setUserIdErrorMessage("Invalid User ID"));
  }

  if (userId.indexOf("@") === -1) {
    dispatch(setUserId(`${userId}@signify.com`));
    return dispatch(createPortalUser());
  }

  if (userId.indexOf("@") >= 0 && !/^.*@(signify|cooperlighting).com$/.test(userId)) {
    return dispatch(setUserIdErrorMessage("Only @signify.com email addresses are allowed for IoT Portal Users"));
  }

  dispatch(showLoadingIndicator());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(setUserIdErrorMessage());
  dispatch(setPasswordErrorMessage());
  dispatch(setMfaSecretErrorMessage());
  dispatch(hideAccessDenied());
  dispatch(createPortalUserRequest());

  const json = JSON.stringify({
    userId,
    password,
    profile,
  });

  return UserIdmLegacyClient.createUser(accessToken, json)
    .then(() => {

      dispatch(createPortalUserSuccess());
      dispatch(setUser(new User({ id: userId, userId }).toJS()));
      dispatch(setSuccessMessage("User Created"));
      return dispatch(hideLoadingIndicator());

    }, (response: RestClientError) => {

      const { analytic, error } = response;

      dispatch(createPortalUserFailed(analytic));
      dispatch(setErrorMessage(error));

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideLoadingIndicator());
    });
};

export const createFederatedUser = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const userId = getUserId(state);
  const password = randomStringGenerator();
  const accessToken = getAccessToken(state);
  const selectedFederationProvider = getSelectedFederationProvider(state);
  const { profile = {} } = UpdateUserProfileRequest.from(getUserProfileAttributesMap(state)).toJS();

  if (isEmptyString(userId)) {
    return dispatch(setUserIdErrorMessage("Invalid User ID"));
  }

  // Auto append selected federation provider
  if (userId.indexOf("@") === -1 && !isEmptyString(selectedFederationProvider)) {
    dispatch(setUserId(`${userId}@${selectedFederationProvider}`));
    return dispatch(createFederatedUser());
  }

  // Validate email address matches selected federation provider domain
  if (userId.indexOf("@") >= 0 && !(new RegExp(`^.*@${selectedFederationProvider}$`)).test(userId)) {
    return dispatch(setUserIdErrorMessage(`Email address must end with @${selectedFederationProvider}`));
  }

  dispatch(showLoadingIndicator());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(setUserIdErrorMessage());
  dispatch(setPasswordErrorMessage());
  dispatch(setMfaSecretErrorMessage());
  dispatch(hideAccessDenied());
  dispatch(createFederatedUserRequest());

  const json = JSON.stringify({
    userId,
    password,
    profile,
  });

  return UserIdmLegacyClient.createUser(accessToken, json)
    .then(() => {

      dispatch(createFederatedUserSuccess());
      dispatch(setUser(new User({ id: userId, userId }).toJS()));
      dispatch(setSuccessMessage("User Created"));
      return dispatch(hideLoadingIndicator());

    }, (response: RestClientError) => {

      const { analytic, error } = response;

      dispatch(createFederatedUserFailed(analytic));
      dispatch(setErrorMessage(error));

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideLoadingIndicator());
    });
};

export const createUser = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  if (isPortalUser(state)) {
    return dispatch(createPortalUser());
  } else if (isFederatedUser(state)) {
    return dispatch(createFederatedUser());
  } else {
    return dispatch(createNormalUser());
  }
};
