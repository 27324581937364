import { Record } from "immutable";
import { getStringValue } from "@util";

export interface DataLakeFileMetadataItemAttributes {
  key: string;
  value: string;
}

export class DataLakeFileMetadataItem extends Record({
  key: "",
  value: "",
}) implements DataLakeFileMetadataItemAttributes {

  public static EMPTY: DataLakeFileMetadataItem = new DataLakeFileMetadataItem();

  public readonly key: string;
  public readonly value: string;

  public getKey(): string {
    return getStringValue(this.key);
  }

  public getValue(): string {
    return getStringValue(this.value);
  }
}

export default DataLakeFileMetadataItem;
