import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  PortalModuleActionTypes,
  createPortalModuleDefaultState,
} from "@base/createReducers";

export interface ApplicationWizardSchema extends BasePortalModuleSchema {
  applicationId: string;
  editMode: boolean;
}

export const DEFAULT_STATE: ApplicationWizardSchema = createPortalModuleDefaultState({
  applicationId: "",
  editMode: false,
});

type ApplicationWizardActionTypes = PortalModuleActionTypes<ApplicationWizardSchema>;

export const ACTION_TYPES: ApplicationWizardActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<ApplicationWizardSchema>(MODULE_ID, DEFAULT_STATE),
});

export const ApplicationWizardReducer = combineReducers<ApplicationWizardSchema>(
  createPortalModuleReducers<ApplicationWizardSchema>(ACTION_TYPES, DEFAULT_STATE));

export default ApplicationWizardReducer;
