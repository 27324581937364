import React from "react";
import classnames from "classnames";
import { HtmlEditor } from "@components";
import { EmailTemplateMessageType } from "@data";
import TextField from "@components/text-field";
import { formEventHandler, isEmptyString, noop } from "@util";
import EmailTemplateReqs from "@data/EmailTemplateRequirements";
import VerifyFieldsView, { VerifyAndCheck } from "./VerifyFieldsView";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { useEmailTemplateValidate } from "@hooks";
import { bodyView as styles, textField } from "./styles";

const EmailSubjectTextField = withStyles(textField)(TextField);
const DescriptionTextField = withStyles(textField)(TextField);
const TextBodyTextField = withStyles(textField)(TextField);

export interface Model {
  emailSubject?: string;
  emailSubjectError?: string;
  description?: string;
  descriptionError?: string;
  title?: string;
  textBody?: string;
  htmlBody?: string;
  children?: string;
  messageType?: EmailTemplateMessageType;
  emailRequirements?: EmailTemplateReqs;
  defaultValidateSuccessMessage?: string;
}

export interface Actions {
  setEmailSubject?: (emailSubject: string) => void;
  setDescription?: (description: string) => void;
  setTextBody?: (textBody: string) => void;
  setHtmlBody?: (htmlBody: string) => void;
  setValidateStatus?: (status: boolean) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

function emailVerify(rank: number, name: string, value: string, condition: VerifyAndCheck,
                     check: any, emailRequirements: EmailTemplateReqs) {
  return (
      <VerifyFieldsView
          key={name + rank}
          name={name}
          value={value}
          check={check}
          condition={condition}
          emailRequirements={emailRequirements}
      />
  );
}

export const BodyView = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Set Email Body",
    emailSubject = "",
    emailSubjectError = "",
    description = "",
    descriptionError = "",
    textBody = "",
    htmlBody = "",
    messageType = EmailTemplateMessageType.MESSAGE_SIGNUP,
    emailRequirements = EmailTemplateReqs.EMPTY.toJS(),
    defaultValidateSuccessMessage = "Email Template is valid",
    children,
    setDescription = noop,
    setEmailSubject = noop,
    setTextBody = noop,
    setHtmlBody = noop,
    setValidateStatus = noop,
  } = props;

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const [validateMessage, setValidateMessage] = React.useState("");

  const [{ successMessage: validateSuccessMessage, errorMessage: validateErrorMessage}] =
    useEmailTemplateValidate({
      messageType,
      emailSubject,
      description,
      textBody,
      htmlBody,
      defaultSuccessMessage: defaultValidateSuccessMessage,
    });

  React.useEffect(() => {
    setValidateStatus(!isEmptyString(validateSuccessMessage));
    if (!isEmptyString(validateSuccessMessage)) {
      setValidateMessage(validateSuccessMessage);
    }
    if (!isEmptyString(validateErrorMessage)) {
      setValidateMessage(validateErrorMessage);
    }
  }, [validateSuccessMessage, validateErrorMessage, setValidateMessage]);

  const checkSubject = React.useMemo(() =>
          emailVerify(1, "Email Subject", emailSubject, VerifyAndCheck.LENGTH, [10, 256], emailRequirements),
      [emailSubject, emailRequirements]);
  const checkDesc = React.useMemo(() =>
          emailVerify(2, "Description", description, VerifyAndCheck.LENGTH, [1, 256], emailRequirements),
      [description, emailRequirements]);
  const checkEmailTemplate = React.useMemo(() =>
          emailVerify(3, "Template", validateMessage, VerifyAndCheck.TRUTH,
              validateMessage === defaultValidateSuccessMessage, emailRequirements),
      [validateMessage, defaultValidateSuccessMessage, emailRequirements]);

  const emailReqType = React.useMemo(() => {
    const reqType = { [messageType]: [checkSubject, checkDesc]};

    if (emailRequirements.messageTypes[messageType] != null) {
      const required = emailRequirements.messageTypes[messageType].requiredParameters;

      for (let i = 0; i < required.length; i++) {
        const checklist = { [required[i]]: [required[i]] };

        for (const [params] of Object.entries(emailRequirements.parameters)) {
          if (required[i] !== params) {
            if (emailRequirements.parameters[params].replacement != null) {
              if (required[i] === emailRequirements.parameters[params].replacement) {
                checklist[required[i]].push(params);
              }
            }
          }
        }

        reqType[messageType].push(
            emailVerify(reqType[messageType].length, "Text Body",
                  textBody, VerifyAndCheck.EXISTS, checklist, emailRequirements));
      }
    }
    reqType[messageType].push(checkEmailTemplate);

    return reqType;
  }, [checkSubject, checkDesc, checkEmailTemplate, textBody, messageType, emailRequirements]);

  return (
      <div className={classes.head}>
        {emailReqType[messageType] != null && emailReqType[messageType].length > 0 && (
          <div className={classnames("verifyFieldsView", classes.verifyContainer)}>
            <label className={classes.title}>
              Email Requirements
            </label>
            <div className={classnames("verifyMain", classes.verifyMain)}>
              { emailReqType[messageType] }
            </div>
          </div>
        )}
        <div
            className={
                classnames("bodyView",
                    emailReqType[messageType] != null && emailReqType[messageType].length === 0
                    ? classes.container : classes.containerWithReqs)
            }
        >
          <label className={classes.title}>
            {title}
          </label>
          <EmailSubjectTextField
            className={classnames("emailSubject", classes.textBox)}
            autoComplete="off"
            label="Email Subject"
            name="emailSubject"
            value={emailSubject}
            fullWidth={true}
            autoFocus={true}
            required={true}
            helperText={emailSubjectError}
            error={emailSubjectError.length > 0}
            variant="outlined"
            margin="none"
            InputLabelProps={inputLabelProps}
            onChange={formEventHandler(setEmailSubject)}
          />
          <DescriptionTextField
            className={classnames("description", classes.textBox)}
            autoComplete="off"
            label="Description"
            name="description"
            value={description}
            fullWidth={true}
            autoFocus={false}
            required={true}
            helperText={descriptionError}
            error={descriptionError.length > 0}
            variant="outlined"
            margin="none"
            InputLabelProps={inputLabelProps}
            onChange={formEventHandler(setDescription)}
          />
          <TextBodyTextField
            className={classnames("textBody", classes.textBox)}
            autoComplete="off"
            label="Text Body"
            name="textBody"
            value={textBody}
            fullWidth={true}
            autoFocus={false}
            required={!(emailRequirements.messageTypes[messageType] != null
                && emailRequirements.messageTypes[messageType].requiredParameters.length === 0)}
            multiline={true}
            minRows={3}
            maxRows={3}
            variant="outlined"
            margin="none"
            InputLabelProps={inputLabelProps}
            onChange={formEventHandler(setTextBody)}
          />
          <div className={classnames("htmlEditor", classes.htmlContainer)}>
            <div className={classnames("htmlBody", classes.subtitle)}>
              HTML Body
            </div>
            <HtmlEditor
              className={classnames("htmlEditor", classes.htmlEditor)}
              html={htmlBody}
              setHtml={setHtmlBody}
              debounceChangePeriod={50}
            />
          </div>
          {children}
        </div>
      </div>
  );
});

export default BodyView;
