import React from "react";
import { useHistory } from "react-router-dom";
import EditProfileAttributeDefinition from "./containers/EditProfileAttributeDefinition";

export function EditProfileAttributeDefinitionView() {

  const history = useHistory();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onSuccess = React.useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <EditProfileAttributeDefinition
      cancel={cancel}
      onSuccess={onSuccess}
    />
  );
}

export default EditProfileAttributeDefinitionView;
