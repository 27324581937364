import React from "react";
import { useWorkloadManagerClient } from "@hooks";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps } from "@hooks";

type SuccessResponse = void;

export interface UseEditMemoryProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  memory: number;
  etag: string;
}

export interface UseEditMemoryModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseEditMemoryActions {
  editMemory: () => void;
  reset: () => void;
}

type Props = UseEditMemoryProps;
type Model = UseEditMemoryModel;
type Actions = UseEditMemoryActions;
type Result = [Model, Actions];

export const useEditMemory = (props: Props): Result => {

  const {
    name,
    version,
    memory,
    etag,
    defaultErrorMessage = "Failed to edit memory",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.editWorkloadMemoryApi(name, version, etag, memory.toString(10)),
    [WorkloadManagerClient, name, version, etag, memory]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: editTimeout, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Edit Successful" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    editMemory: editTimeout,
    reset
  }), [
    editTimeout,
    reset
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEditMemory;
