import equals from "lodash/isEqual";
import { createSelector } from "reselect";
import { isEmptyString, isValidJson } from "@util";
import { MODULE_ID, SCHEMA_KEY } from "../constants";
import { createSelectors, Selector } from "@modules/base/createSelectors";
import {
  DeviceTypeModelV3,
  DeviceTypeModelV3ConstraintsAttributes,
  DeviceTypeModelV3DefinitionAttributes,
  DeviceTypeModelV3DeviceAuthentication,
  DeviceTypeModelVersion,
} from "@data";
import {
  DeviceTypeRequest,
  DeviceTypeRequestV2,
  DeviceTypeRequestV2Attributes,
  DeviceTypeRequestV3,
  DeviceTypeRequestV3Attributes,
  DeviceTypeWizardState,
  DeviceTypeWizardStateAttributes,
} from "../models";
import {
  DEFAULT_STATE,
  DeviceTypeWizardSchema,
  DeviceTypeWizardStep,
} from "../reducers/deviceTypeWizard";
import isDeviceTypeV2APIEnabled from "@util/isDeviceTypeV2APIEnabled";

export const CREATE_DEVICE_TYPE_V3_WIZARD_STEPS = [
  DeviceTypeWizardStep.API_VERSION,
  DeviceTypeWizardStep.NAMESPACE,
  DeviceTypeWizardStep.NAME,
  DeviceTypeWizardStep.CONNECTIVITY,
  DeviceTypeWizardStep.GROUPS,
  DeviceTypeWizardStep.SCHEMAS,
  DeviceTypeWizardStep.CREDENTIALS,
  DeviceTypeWizardStep.EDITOR,
  DeviceTypeWizardStep.REVIEW,
];

export const CREATE_DEVICE_TYPE_V2_WIZARD_STEPS = [
  DeviceTypeWizardStep.API_VERSION,
  DeviceTypeWizardStep.NAMESPACE,
  DeviceTypeWizardStep.NAME,
  DeviceTypeWizardStep.GROUPS,
  DeviceTypeWizardStep.SCHEMAS,
  DeviceTypeWizardStep.SECRET,
  DeviceTypeWizardStep.EDITOR,
  DeviceTypeWizardStep.REVIEW,
];

export const CREATE_DEVICE_TYPE_WIZARD_STEP_LABELS = {
  [DeviceTypeWizardStep.API_VERSION]: "API Version",
  [DeviceTypeWizardStep.NAMESPACE]: "Namespace",
  [DeviceTypeWizardStep.NAME]: "Name",
  [DeviceTypeWizardStep.CONNECTIVITY]: "Connectivity",
  [DeviceTypeWizardStep.GROUPS]: "Group(s)",
  [DeviceTypeWizardStep.SCHEMAS]: "Schema(s)",
  [DeviceTypeWizardStep.SECRET]: "Secrets",
  [DeviceTypeWizardStep.CREDENTIALS]: "Credentials",
  [DeviceTypeWizardStep.EDITOR]: "JSON",
  [DeviceTypeWizardStep.REVIEW]: "Finish",
};

export const EDIT_DEVICE_TYPE_V3_WIZARD_STEPS = [
  DeviceTypeWizardStep.API_VERSION,
  DeviceTypeWizardStep.NAMESPACE,
  DeviceTypeWizardStep.NAME,
  DeviceTypeWizardStep.CONNECTIVITY,
  DeviceTypeWizardStep.GROUPS,
  DeviceTypeWizardStep.SCHEMAS,
  DeviceTypeWizardStep.CREDENTIALS,
  DeviceTypeWizardStep.EDITOR,
  DeviceTypeWizardStep.REVIEW,
];

export const EDIT_DEVICE_TYPE_V2_WIZARD_STEPS = [
  DeviceTypeWizardStep.API_VERSION,
  DeviceTypeWizardStep.NAMESPACE,
  DeviceTypeWizardStep.NAME,
  DeviceTypeWizardStep.GROUPS,
  DeviceTypeWizardStep.SCHEMAS,
  DeviceTypeWizardStep.SECRET,
  DeviceTypeWizardStep.EDITOR,
  DeviceTypeWizardStep.REVIEW,
];

export const EDIT_DEVICE_TYPE_WIZARD_STEP_LABELS = {
  ...CREATE_DEVICE_TYPE_WIZARD_STEP_LABELS,
};

export const {
  modelVersion: getModelVersion,
  namespace: getNamespace,
  name: getName,
  version: getVersion,
  json: getJson,
  deviceType: getDeviceTypeRequestAttributes,
  deviceTypeV2: getDeviceTypeRequestV2Attributes,
  deviceTypeWizardStep: getDeviceTypeWizardStep,
  showEditMode: isEditModeActive,
  showEditModeInitializeError: isEditModeInitializeErrorVisible,
  showCloneMode: isCloneModeActive,
  deviceTypeRef: getDeviceTypeRef,
  etag: getEtag,
  defaultState: getDeviceTypeWizardDefaultStateAttributes,
  getErrorMessage,
  getSuccessMessage,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  getCurrentAccountId,
  getAccessToken,
} = createSelectors<DeviceTypeWizardSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

const isV2ApiEnabled = () => isDeviceTypeV2APIEnabled();

export const isValidModelVersion: Selector<boolean>  = createSelector(
  getModelVersion, (modelVersion: DeviceTypeModelVersion) => {

    switch (modelVersion) {
      case DeviceTypeModelVersion.HISTORICAL:
      case DeviceTypeModelVersion.REGIONAL:
        return true;
      default:
        return false;
    }
  });

export const isHistoricalApiSelected: Selector<boolean>  = createSelector(
  getModelVersion, (modelVersion: DeviceTypeModelVersion) =>
    DeviceTypeModelVersion.HISTORICAL === modelVersion);

export const isModelVersionNotSupportedErrorVisible: Selector<boolean>  = createSelector(
  [isEditModeActive, isHistoricalApiSelected, isV2ApiEnabled],
  (editMode: boolean, historical: boolean, v2ApiEnabled: boolean) =>
    editMode && historical && !v2ApiEnabled);

export const isRegionalApiSelected: Selector<boolean>  = createSelector(
  isHistoricalApiSelected, (historical: boolean) => !historical);

export const getDeviceTypeRequestV3: Selector<DeviceTypeRequestV3> = createSelector(
  getDeviceTypeRequestAttributes, (attrs: DeviceTypeRequestV3Attributes) => {

    return new DeviceTypeRequestV3(attrs);
  });

export const getDeviceTypeRequestV2: Selector<DeviceTypeRequestV2> = createSelector(
  getDeviceTypeRequestV2Attributes, (attrs: DeviceTypeRequestV2Attributes) => {

    return new DeviceTypeRequestV2(attrs);
  });

export const getDeviceTypeRequest: Selector<DeviceTypeRequest> = createSelector(
  [isRegionalApiSelected, getDeviceTypeRequestV3, getDeviceTypeRequestV2],
  (regional: boolean,
   deviceTypeRequestV3: DeviceTypeRequestV3,
   deviceTypeRequestV2: DeviceTypeRequestV2) => {

    return regional ? deviceTypeRequestV3 : deviceTypeRequestV2;
  });

export const getDescription: Selector<string>  = createSelector(
  getDeviceTypeRequest, (deviceTypeRequest: DeviceTypeRequest) =>
    deviceTypeRequest.getDescription());

export const getTags: Selector<string[]> = createSelector(
  getDeviceTypeRequest, (deviceTypeRequest: DeviceTypeRequest) =>
    deviceTypeRequest.getTags());

export const getGroupNames: Selector<string[]> = createSelector(
  getDeviceTypeRequest, (deviceTypeRequest: DeviceTypeRequest) => {

    return deviceTypeRequest.getGroups();
  });

export const hasSelectedGroups: Selector<boolean>  = createSelector(
  getGroupNames, groups => groups.length > 0);

export const getCredentialDefinitionNames: Selector<string[]> = createSelector(
  getDeviceTypeRequestV3, (deviceTypeV3: DeviceTypeRequestV3) => deviceTypeV3.getDefinitionNames());

export const getCredentialDefinitions: Selector<DeviceTypeModelV3DefinitionAttributes[]> = createSelector(
  getDeviceTypeRequestV3, (deviceTypeV3: DeviceTypeRequestV3) => {

    return deviceTypeV3.getDefinitions();
  });

export const hadCredentialDefinitions: Selector<boolean> = createSelector(
  getCredentialDefinitions, (credentialDefinitions: DeviceTypeModelV3DefinitionAttributes[]) =>
    credentialDefinitions.length > 0);

export const getConstraints: Selector<DeviceTypeModelV3ConstraintsAttributes> =
  createSelector(getDeviceTypeRequestV3, deviceTypeV3 => deviceTypeV3.getConstraints());

export const hasAddedConstraints: Selector<boolean> = createSelector(
  getConstraints, (constraints: DeviceTypeModelV3ConstraintsAttributes) =>
    Object.keys(constraints).length > 0);

export const getDeviceAuthentication: Selector<DeviceTypeModelV3DeviceAuthentication[]> =
  createSelector(getDeviceTypeRequestV3, deviceTypeV3 => deviceTypeV3.getDeviceAuthentication());

export const getSchemas: Selector<string[]> = createSelector(
  getDeviceTypeRequest, (deviceTypeRequest: DeviceTypeRequest) =>
    deviceTypeRequest.getSchemas());

export const hasSelectedSchemas: Selector<boolean>  = createSelector(
  getSchemas, schemas => schemas.length > 0);

export const getSecretsSchema: Selector<string>  = createSelector(
  getDeviceTypeRequestV2, (deviceTypeRequest: DeviceTypeRequestV2) =>
    deviceTypeRequest.getSecretsSchema());

export const hasSelectedSecretsSchema: Selector<boolean>  = createSelector(
  getSecretsSchema, secretsSchema => !isEmptyString(secretsSchema));

export const getNamespaceViewTitle: Selector<string>  = createSelector(
  isEditModeActive, (editMode: boolean) =>
    !editMode ? "Select Device Type Namespace" : "Device Type Namespace");

export const getNamespaceViewSubtitle: Selector<string>  = createSelector(
  isEditModeActive, (editMode: boolean) =>
    !editMode ? "" : "NOTE: Device type namespace cannot be changed");

export const getErrorTitle: Selector<string>  = createSelector(
  [getErrorMessage, isEditModeActive],
  (errorMessage: string = "", editMode: boolean = false) => {

    if (isEmptyString(errorMessage)) {
      return "";
    }

    return editMode ? "Update Device Type Failed" : "Create Device Type Failed";
  });

export const isApiVersionViewSelected: Selector<boolean>  = createSelector(
  getDeviceTypeWizardStep, (currentStep: DeviceTypeWizardStep) =>
    DeviceTypeWizardStep.API_VERSION === currentStep);

export const isNamespaceViewSelected: Selector<boolean>  = createSelector(
  getDeviceTypeWizardStep, (currentStep: DeviceTypeWizardStep) =>
    DeviceTypeWizardStep.NAMESPACE === currentStep);

export const isNameViewSelected: Selector<boolean>  = createSelector(
  getDeviceTypeWizardStep, (currentStep: DeviceTypeWizardStep) =>
    DeviceTypeWizardStep.NAME === currentStep);

export const isConnectivityViewSelected: Selector<boolean>  = createSelector(
  getDeviceTypeWizardStep, (currentStep: DeviceTypeWizardStep) =>
    DeviceTypeWizardStep.CONNECTIVITY === currentStep);

export const isGroupViewSelected: Selector<boolean>  = createSelector(
  getDeviceTypeWizardStep, (currentStep: DeviceTypeWizardStep) =>
    DeviceTypeWizardStep.GROUPS === currentStep);

export const isSchemasViewSelected: Selector<boolean>  = createSelector(
  getDeviceTypeWizardStep, (currentStep: DeviceTypeWizardStep) =>
    DeviceTypeWizardStep.SCHEMAS === currentStep);

export const isSecretSchemaViewSelected: Selector<boolean>  = createSelector(
  getDeviceTypeWizardStep, (currentStep: DeviceTypeWizardStep) =>
    DeviceTypeWizardStep.SECRET === currentStep);

export const isCredentialsViewSelected: Selector<boolean>  = createSelector(
  getDeviceTypeWizardStep, (currentStep: DeviceTypeWizardStep) =>
    DeviceTypeWizardStep.CREDENTIALS === currentStep);

export const isDeviceTypeEditorViewSelected: Selector<boolean>  = createSelector(
  getDeviceTypeWizardStep, (currentStep: DeviceTypeWizardStep) =>
    DeviceTypeWizardStep.EDITOR === currentStep);

export const isReviewViewSelected: Selector<boolean>  = createSelector(
  getDeviceTypeWizardStep, (currentStep: DeviceTypeWizardStep) =>
    DeviceTypeWizardStep.REVIEW === currentStep);

export const isNamespaceValid: Selector<boolean>  = createSelector(
  getNamespace, (namespace: string) => !isEmptyString(namespace));

export const isNameValid: Selector<boolean>  = createSelector(
  getName, (name: string) => !isEmptyString(name));

export const isJsonValid: Selector<boolean>  = createSelector(
  getJson, (json: string) => isValidJson(json));

export const isDeviceTypeCreated: Selector<boolean>  = createSelector(
  getDeviceTypeRef, (deviceTypeRef: string) => !isEmptyString(deviceTypeRef));

export const getDeviceTypeRefVersion: Selector<string>  = createSelector(
  getDeviceTypeRef, (typeIdentity: string) => new DeviceTypeModelV3({ typeIdentity }).getVersion());

export const isSaveButtonEnabled: Selector<boolean>  = createSelector(
  [isValidModelVersion, isNamespaceValid, isNameValid, isJsonValid],
  (validModelVersion: boolean,
   validNamespace: boolean,
   validName: boolean,
   validJson: boolean) =>
    validModelVersion && validNamespace && validName && validJson);

const getDeviceTypeWizardModeSteps: Selector<DeviceTypeWizardStep[]> = createSelector(
  [isEditModeActive, isHistoricalApiSelected], (editMode: boolean, historical: boolean) => {

    if (editMode) {
      return historical ? EDIT_DEVICE_TYPE_V2_WIZARD_STEPS : EDIT_DEVICE_TYPE_V3_WIZARD_STEPS;
    }

    return historical ? CREATE_DEVICE_TYPE_V2_WIZARD_STEPS : CREATE_DEVICE_TYPE_V3_WIZARD_STEPS;
  });

export const getSteps: Selector<DeviceTypeWizardStep[]> = createSelector(
  [getDeviceTypeWizardModeSteps, isV2ApiEnabled],
  (steps: DeviceTypeWizardStep[], v2ApiEnabled: boolean) =>
    v2ApiEnabled ? steps : steps.filter(step => step !== DeviceTypeWizardStep.API_VERSION));

export const getStepLabels = createSelector(
  isEditModeActive, (editMode: boolean) =>
    editMode ? EDIT_DEVICE_TYPE_WIZARD_STEP_LABELS : CREATE_DEVICE_TYPE_WIZARD_STEP_LABELS);

export const isFirstStepActive: Selector<boolean>  = createSelector(
  [getSteps, getDeviceTypeWizardStep],
  (steps: DeviceTypeWizardStep[], currentStep: DeviceTypeWizardStep) =>
    currentStep === steps[0]);

export const hasValidDeviceAuthenticationCredentialNames: Selector<boolean>  = createSelector(
  [getDeviceAuthentication, getCredentialDefinitionNames],
  (deviceAuthentication: DeviceTypeModelV3DeviceAuthentication[], credentialNames: string[]) =>
    deviceAuthentication.every(entry => credentialNames.indexOf(entry.getCredentialName()) >= 0));

export const isDeviceAuthenticationJsonEditorErrorRequired: Selector<boolean>  = createSelector(
  [isDeviceTypeEditorViewSelected, hasValidDeviceAuthenticationCredentialNames],
  (jsonStepSelected: boolean, validCredentialNames: boolean) =>
    jsonStepSelected && !validCredentialNames);

export const isLastStepActive: Selector<boolean>  = createSelector(
  [getSteps, getDeviceTypeWizardStep],
  (steps: DeviceTypeWizardStep[], currentStep: DeviceTypeWizardStep) =>
    currentStep === steps[steps.length - 1]);

export const getDisabledSteps: Selector<DeviceTypeWizardStep[]> = createSelector(
  [
    getSteps,
    isHistoricalApiSelected,
    isValidModelVersion,
    isNamespaceValid,
    isNameValid,
    isJsonValid,
  ],
  (steps: DeviceTypeWizardStep[],
   historical: boolean,
   validModelVersion: boolean,
   validNamespace: boolean,
   validName: boolean,
   validJson: boolean) => {

    if (!validModelVersion) {
      return steps.filter(step => DeviceTypeWizardStep.API_VERSION !== step);
    }

    if (!validNamespace) {
      return steps.filter(step => DeviceTypeWizardStep.API_VERSION !== step &&
        DeviceTypeWizardStep.NAMESPACE !== step);
    }

    if (!validName) {
      return steps.filter(step => DeviceTypeWizardStep.API_VERSION !== step &&
        DeviceTypeWizardStep.NAMESPACE !== step &&
        DeviceTypeWizardStep.NAME !== step);
    }

    if (!validJson) {
      return steps.filter(step => DeviceTypeWizardStep.EDITOR !== step);
    }

    return [];
  });

export const getCompletedSteps: Selector<DeviceTypeWizardStep[]> = createSelector(
  [
    getSteps,
    getDeviceTypeWizardStep,
    isValidModelVersion,
    isNamespaceValid,
    isNameValid,
    hasAddedConstraints,
    hasSelectedGroups,
    hasSelectedSchemas,
    hasSelectedSecretsSchema,
    hadCredentialDefinitions,
    isJsonValid,
    isLastStepActive,
    isLoadingIndicatorVisible,
    isDeviceTypeCreated,
  ],
  (steps: DeviceTypeWizardStep[],
   currentStep: DeviceTypeWizardStep,
   validModelVersion: boolean,
   validNamespace: boolean,
   validName: boolean,
   addedConstraints: boolean,
   selectedGroups: boolean,
   selectedSchemas: boolean,
   selectedSecretsSchema: boolean,
   addedCredentials: boolean,
   validJson: boolean,
   lastStepActive: boolean,
   loading: boolean,
   success: boolean) => {

    const currentStepIndex = steps.indexOf(currentStep);

    return steps.filter((step: DeviceTypeWizardStep, stepIndex: number) => {

      const isCurrentStep = (step === currentStep);

      switch (step) {
        case DeviceTypeWizardStep.API_VERSION:
          return !isCurrentStep && validModelVersion;
        case DeviceTypeWizardStep.NAMESPACE:
          return !isCurrentStep && validNamespace;
        case DeviceTypeWizardStep.NAME:
          return !isCurrentStep && validNamespace && validName;
        case DeviceTypeWizardStep.CONNECTIVITY:
          return !isCurrentStep && validNamespace && validName &&
            (addedConstraints || currentStepIndex > stepIndex);
        case DeviceTypeWizardStep.GROUPS:
          return !isCurrentStep && validNamespace && validName &&
            (selectedGroups || currentStepIndex > stepIndex);
        case DeviceTypeWizardStep.SCHEMAS:
          return !isCurrentStep && validNamespace && validName &&
            (selectedSchemas || currentStepIndex > stepIndex);
        case DeviceTypeWizardStep.SECRET:
          return !isCurrentStep && validNamespace && validName &&
            (selectedSecretsSchema || currentStepIndex > stepIndex);
        case DeviceTypeWizardStep.CREDENTIALS:
          return !isCurrentStep && validNamespace && validName &&
            (addedCredentials || currentStepIndex > stepIndex);
        case DeviceTypeWizardStep.EDITOR:
          return lastStepActive && validNamespace && validName && validJson;
        case DeviceTypeWizardStep.REVIEW:
          return lastStepActive && (loading || success);
        default:
          return false;
      }
    });
  });

export const getPreviousStep: Selector<DeviceTypeWizardStep> = createSelector(
  [isFirstStepActive, getSteps, getDeviceTypeWizardStep],
  (firstStep: boolean, steps: DeviceTypeWizardStep[], currentStep: DeviceTypeWizardStep) => {

    if (firstStep) {
      return DeviceTypeWizardStep.NONE;
    }

    const currentStepIndex = steps.indexOf(currentStep);

    return steps[currentStepIndex - 1] || DeviceTypeWizardStep.NONE;
  });

export const getNextStep: Selector<DeviceTypeWizardStep> = createSelector(
  [isLastStepActive, getSteps, getDeviceTypeWizardStep],
  (lastStep: boolean, steps: DeviceTypeWizardStep[], currentStep: DeviceTypeWizardStep) => {

    if (lastStep) {
      return DeviceTypeWizardStep.NONE;
    }

    const currentStepIndex = steps.indexOf(currentStep);

    return steps[currentStepIndex + 1] || DeviceTypeWizardStep.NONE;
  });

export const isPreviousStepButtonDisabled: Selector<boolean>  = createSelector(
  [getDisabledSteps, getPreviousStep],
  (disabledSteps: DeviceTypeWizardStep[], previousStep: DeviceTypeWizardStep) =>
    DeviceTypeWizardStep.NONE === previousStep || disabledSteps.indexOf(previousStep) >= 0);

export const isNextStepButtonDisabled: Selector<boolean>  = createSelector(
  [getDisabledSteps, getNextStep],
  (disabledSteps: DeviceTypeWizardStep[], nextStep: DeviceTypeWizardStep) => {

    return DeviceTypeWizardStep.NONE === nextStep || disabledSteps.indexOf(nextStep) >= 0;
  });

export const isApiVersionSwitchLocked: Selector<boolean>  = createSelector(
  [
    isEditModeActive,
    isCloneModeActive,
    isRegionalApiSelected,
    getDeviceTypeRequestV3,
    getDeviceTypeRequestV2,
  ],
  (editMode: boolean,
   cloneMode: boolean,
   regional: boolean,
   deviceTypeRequestV3: DeviceTypeRequestV3,
   deviceTypeRequestV2: DeviceTypeRequestV2) => {

    if (editMode || cloneMode) {
      return true;
    }

    if (!regional) {

      return !equals(
        DeviceTypeRequestV2.fromDeviceTypeRequestV3(deviceTypeRequestV3).toJS(),
        deviceTypeRequestV2.toJS());
    }

    return !equals(
      DeviceTypeRequestV3.fromDeviceTypeRequestV2(deviceTypeRequestV2).toJS(),
      deviceTypeRequestV3.toJS());
  });

export const getHistoricalJson: Selector<string> = createSelector(
  getDeviceTypeRequestV2, (deviceTypeRequestV2: DeviceTypeRequestV2) =>
    JSON.stringify(deviceTypeRequestV2.toJS(), null, "  "));

export const getDeviceTypeWizardDefaultState: Selector<DeviceTypeWizardState> = createSelector(
  getDeviceTypeWizardDefaultStateAttributes, (attrs: DeviceTypeWizardStateAttributes) =>
    new DeviceTypeWizardState(attrs));

export const getDeviceTypeWizardState: Selector<DeviceTypeWizardState> = createSelector(
  [getNamespace, getName, getJson],
  (namespace: string, name: string, json: string) => new DeviceTypeWizardState({
    namespace,
    name,
    json,
  }));
