import React from "react";
import moment from "moment/moment";
import classnames from "classnames";
import { IdentityType } from "@data";
import { Alert } from "@components/alert";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import ContinueButton from "@components/continue-button";
import DialogContent from "@material-ui/core/DialogContent";
import BackdropLoadingView from "@components/backdrop-loading-view";
import { getStringValue, isEmptyString, noop, onLocalStorageUpdated } from "@util";
import {
  KEY_ACCESS_TOKEN_EXPIRY_TIME,
  KEY_ACCOUNT_ID,
  KEY_IDENTITY_TYPE,
  KEY_PRINCIPAL_ID,
} from "@store/constants";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export const LOCAL_STORAGE_KEYS_TO_WATCH = [
  KEY_ACCESS_TOKEN_EXPIRY_TIME,
  KEY_ACCOUNT_ID,
  KEY_PRINCIPAL_ID,
  KEY_IDENTITY_TYPE,
];

export interface StartSessionListenerModel {
  accessTokenExpiryTime?: string;
  accountId?: string;
  principalId?: string;
  identityType?: IdentityType;
}

export interface StartSessionListenerActions {
  reload?: () => void;
}

type Model = StartSessionListenerModel;
type Actions = StartSessionListenerActions;
type Props = WithStyles<typeof styles> & Model & Actions;

const StartSessionListener = withStyles(styles)((props: Props) => {

  const {
    classes,
    accessTokenExpiryTime: initialAccessTokenExpiryTime = "",
    accountId: initialAccountId = "",
    principalId: initialPrincipalId = "",
    identityType: initialIdentityType = IdentityType.NONE,
    reload = noop,
  } = props;

  const [reloading, setReloading] = React.useState(false);
  const [accessTokenExpiryTime, setAccessTokenExpiryTime] = React.useState(initialAccessTokenExpiryTime);
  const [accountId, setAccountId] = React.useState(initialAccountId);
  const [principalId, setPrincipalId] = React.useState(initialPrincipalId);
  const [identityType, setIdentityType] = React.useState(initialIdentityType);

  const isAuthTokenExpired = React.useMemo(() => {
    if (isEmptyString(accessTokenExpiryTime)) {
      return true;
    } else {
      return moment(new Date(accessTokenExpiryTime)).isBefore(moment());
    }
  }, [accessTokenExpiryTime]);

  const showLoggedInDialog = React.useMemo(() =>
      !isAuthTokenExpired &&
      !isEmptyString(accountId) &&
      !isEmptyString(principalId) &&
      identityType !== IdentityType.NONE,
    [
      isAuthTokenExpired,
      accountId,
      principalId,
      identityType,
    ]);

  const onClickReload = React.useCallback(() => {
    setReloading(true);
  }, [setReloading]);

  React.useEffect(() => {
    if (!reloading) {
      return noop;
    }
    const timer = setTimeout(() => {
      reload();
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [reloading, reload]);

  React.useEffect(() => {

    if (showLoggedInDialog) {
      return noop;
    }

    const listener = (event: StorageEvent) => {

      const { key = "", newValue = "" } = event;

      if (!key || LOCAL_STORAGE_KEYS_TO_WATCH.indexOf(key) === -1) {
        return;
      }

      if (key === KEY_ACCESS_TOKEN_EXPIRY_TIME) {
        if (accessTokenExpiryTime !== newValue) {
          setAccessTokenExpiryTime(getStringValue(newValue));
        }
      } else if (key === KEY_ACCOUNT_ID) {
        if (accountId !== newValue) {
          setAccountId(getStringValue(newValue));
        }
      } else if (key === KEY_PRINCIPAL_ID) {
        if (principalId !== newValue) {
          setPrincipalId(getStringValue(newValue));
        }
      } else if (key === KEY_IDENTITY_TYPE) {
        const newIdentityType = (() => {
          if (newValue === IdentityType.USER) {
            return IdentityType.USER;
          } else if (newValue === IdentityType.SERVICE) {
            return IdentityType.SERVICE;
          } else {
            return IdentityType.NONE;
          }
        })();
        if (identityType !== newIdentityType) {
          setIdentityType(newIdentityType);
        }
      }
    };

    const unsubscribe = onLocalStorageUpdated(listener);

    return () => {
      unsubscribe();
    };

  }, [
    showLoggedInDialog,
    accessTokenExpiryTime,
    accountId,
    principalId,
    identityType,
    setAccessTokenExpiryTime,
    setAccountId,
    setPrincipalId,
    setIdentityType,
  ]);

  if (!showLoggedInDialog) {
    return null;
  }

  return (
    <React.Fragment>
      <Dialog
        className={classnames("startSessionListener", classes.container)}
        maxWidth="sm"
        open={true}
      >
        <DialogContent
          className={classnames("dialogContent", classes.dialogContent)}
        >
          <Typography className={classnames("title", classes.title )} variant="h4">
            New sign-in
          </Typography>
          <Alert className={classnames("infoView", classes.infoView )} severity="info">
            You were signed into your account from another tab.<br/>
            Please press <b>Reload</b> to sign into the IoT Portal.
          </Alert>
          <div className={classnames("controls", classes.controls)}>
            <ContinueButton
              className={classnames("continueButton", classes.continueButton)}
              disabled={reloading}
              label="Reload"
              onClick={onClickReload}
            />
          </div>
        </DialogContent>
      </Dialog>
      <BackdropLoadingView
        className={classnames("backdrop", classes.backdrop)}
        showProgressIndicator={reloading}
        open={true}
      />
    </React.Fragment>
  );
});

export default StartSessionListener;
