import React from "react";
import classnames from "classnames";
import camelCase from "lodash/camelCase";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import { JsonSchemaPropertyType } from "../../../../data";
import { clickHandler, formEventHandler, noop } from "../../../../util";

export interface Model {
  type?: JsonSchemaPropertyType;
  types?: string[];
  loading?: boolean;
}

export interface Actions {
  setType?: (type: JsonSchemaPropertyType) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const PropertyEditorType = withStyles(styles)((props: Props) => {

  const {
    classes,
    type = JsonSchemaPropertyType.NONE,
    types = [],
    loading = false,
    setType = noop,
  } = props;

  const [open, setOpen] = React.useState(false);

  const updateType = (updatedType: JsonSchemaPropertyType) => {
    setType(updatedType);
    setOpen(false);
  };

  return (
    <FormControl
      className={classnames("typeContainer", classes.inputField, classes.typeContainer)}
      onClick={clickHandler(() => setOpen(!open))}
    >
      <InputLabel>Type</InputLabel>
      <Select
        className={classnames("type", classes.type)}
        name="type"
        value={type}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disableUnderline={true}
        disabled={loading}
        onChange={formEventHandler(updateType)}
      >
        {types.map((propertyType: JsonSchemaPropertyType, index: number) => (
          <MenuItem
            className={classnames("menuItem", camelCase(propertyType))}
            button={true}
            value={propertyType}
            key={`type-${index}`}
            onClick={clickHandler(() => updateType(propertyType))}
          >
            {propertyType}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export default PropertyEditorType;
