import SessionStorageClient from "@util/SessionStorageClient";

export const cleanSessionStorage = (loggedIn: boolean = false) => {
  if (loggedIn) {
    return false;
  }
  SessionStorageClient.clear();
  return true;
};

export default cleanSessionStorage;
