import React from "react";
import { ManagedPolicy, Policy, SecurityGroup } from "@data";
import { useHistory, useParams } from "react-router-dom";
import { getAddServiceToGroupPath, getRemoveServiceFromGroupPath } from "@modules/groupManagementWizard/helpers";
import SecurityServiceDetails from "./containers/SecurityServiceDetails";
import { getPathToPolicy } from "@modules/policyDetails/helpers";
import { getPathToManagedPolicy } from "@modules/managedPolicyDetails/helpers";
import { getAttachPoliciesToServicePath, getDetachPoliciesFromServicePath } from "@modules/policyManagement/helpers";
import { getPathToGroup } from "@modules/securityGroupDetails/helpers";

export function SecurityServiceDetailsView() {

  const history = useHistory();

  const { id: serviceId } = useParams<{ id: string, action: string }>();

  const showPolicyDetails = React.useCallback((policy: Policy) =>
      history.push(getPathToPolicy(policy.getName())), [history]);

  const showManagedPolicyDetails = React.useCallback((policy: ManagedPolicy) =>
      history.push(getPathToManagedPolicy(policy.getPolicy().getName(), policy.getAccountId())), [history]);

  const showSecurityGroupDetails = React.useCallback((group: SecurityGroup) =>
    history.push(getPathToGroup(group.getName())), [history]);

  const addServiceToGroup = React.useCallback(() =>
      history.push(getAddServiceToGroupPath(serviceId)), [history, serviceId]);

  const attachPolicy = React.useCallback(() =>
      history.push(getAttachPoliciesToServicePath(serviceId)), [history, serviceId]);

  const detachPolicy = React.useCallback(() =>
      history.push(getDetachPoliciesFromServicePath(serviceId)), [history, serviceId]);

  const removeServiceFromGroup = React.useCallback(() =>
      history.push(getRemoveServiceFromGroupPath(serviceId)), [history, serviceId]);

  return (
      <SecurityServiceDetails
          key={serviceId}
          showPolicyDetails={showPolicyDetails}
          showManagedPolicyDetails={showManagedPolicyDetails}
          showSecurityGroupDetails={showSecurityGroupDetails}
          addServiceToGroup={addServiceToGroup}
          removeServiceFromGroup={removeServiceFromGroup}
          attachPolicy={attachPolicy}
          detachPolicy={detachPolicy}
      />
  );
}

export default SecurityServiceDetailsView;
