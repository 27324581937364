import React from "react";
import { ManagedPolicyInfo, ManagedPolicyInfoAttributes } from "@data";
import { AuthTokenContext } from "@components";
import { isEmptyString, noop } from "@util";
import { AuthorizationServiceClient, RestClientError } from "@network";

export interface UseManagedPolicyProps {
  policyName?: string;
  accountId?: string;
  onSuccess?: (policy: ManagedPolicyInfo) => void;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
}

export interface UseManagedPolicyModel {
  managedPolicy: ManagedPolicyInfo;
  errorMessage: string;
  showNotFound: boolean;
  showErrorView: boolean;
  showAccessDenied: boolean;
  showProgressIndicator: boolean;
  isValidPolicyName: boolean;
}

export interface UseManagedPolicyActions {
  refresh: () => void;
}

type Props = UseManagedPolicyProps;

export const useManagedPolicy = (props: Props): [UseManagedPolicyModel, UseManagedPolicyActions] => {

  const {
    policyName = "",
    accountId = "",
    onSuccess = noop,
    trackRequestEvent = noop,
    trackSuccessEvent = noop,
    trackErrorEvent = noop,
  } = props;

  const authToken = React.useContext(AuthTokenContext);
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);
  const [managedPolicy, setManagedPolicy] = React.useState(ManagedPolicyInfo.EMPTY);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showNotFound, setShowNotFound] = React.useState(false);
  const [showAccessDenied, setShowAccessDenied] = React.useState(false);
  const [showProgressIndicator, setShowProgressIndicator] = React.useState(false);

  const isValidPolicyName = React.useMemo(() => !isEmptyString(policyName), [policyName]);
  const isValidAccountId = React.useMemo(() => !isEmptyString(accountId), [accountId]);

  React.useEffect(() => {
    if (!isValidPolicyName || !isValidAccountId) {
      return () => noop;
    }

    let ignore = false;

    setShowNotFound(false);
    setShowAccessDenied(false);
    setShowProgressIndicator(true);
    trackRequestEvent();
    setErrorMessage("");

    AuthorizationServiceClient.getManagedPolicy(authToken, policyName, accountId)
      .then((attrs: ManagedPolicyInfoAttributes) => {

        if (!ignore) {
          setManagedPolicy(new ManagedPolicyInfo(attrs));
          onSuccess(new ManagedPolicyInfo(attrs));
          trackSuccessEvent();
          setShowProgressIndicator(false);
        }
      }, (response: RestClientError) => {

        if (!ignore) {
          const { error = "Fetch managed Policy failed", analytic, status } = response;
          setErrorMessage(error);
          trackErrorEvent(analytic);
          setShowProgressIndicator(false);
          if (status === 404) {
            setShowNotFound(true);
          }
          if (status === 403) {
            setShowAccessDenied(true);
          }
        }
      });

    return () => { ignore = true; };
  } , [
    isValidPolicyName,
    setShowNotFound,
    setShowAccessDenied,
    setShowProgressIndicator,
    setErrorMessage,
    lastRefresh,
    authToken,
    policyName,
    setManagedPolicy,
    onSuccess,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
  ]);

  const showErrorView = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]);

  const refresh = React.useCallback(() =>
    setLastRefresh(+new Date) , [setLastRefresh]);

  const model = React.useMemo<UseManagedPolicyModel>( () => ({
    managedPolicy,
    errorMessage,
    showNotFound,
    showErrorView,
    showAccessDenied,
    showProgressIndicator,
    isValidPolicyName
  }), [
    managedPolicy,
    errorMessage,
    showNotFound,
    showErrorView,
    showAccessDenied,
    showProgressIndicator,
    isValidPolicyName,
  ]);

  const actions = React.useMemo<UseManagedPolicyActions>(() => ({
    refresh,
  }), [refresh]);

  return [ model, actions];

};

export default useManagedPolicy;
