import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Device } from "@data";
import { DeviceHistoryColumns } from "../reducers";
import { listItem as styles, PrimaryIcon } from "./styles";
import PaginatedList, {
  PaginatedListActions,
  PaginatedListModel,
} from "@components/paginated-list";
import { Link } from "@components";
import { getPathToDeviceEnrollment } from "@modules/deviceEnrollmentDetails/helpers";

export const columns = [
  DeviceHistoryColumns.BATCH_ID,
  DeviceHistoryColumns.STATUS,
  DeviceHistoryColumns.STATUS_MESSAGE,
  DeviceHistoryColumns.UPDATED,
  DeviceHistoryColumns.UPDATED_AT,
];

const EnrollmentHistoryItem = withStyles(styles)((props: WithStyles<typeof styles> & {
  enrollmentHistory: Device,
  column: string,
}) => {

  const { classes, enrollmentHistory = Device.EMPTY, column } = props;

  if (!column || Device.EMPTY.equals(enrollmentHistory)) {
    return null;
  }

  switch (column) {
    case DeviceHistoryColumns.BATCH_ID:
      return (
        <div className={classnames("batchId", classes.container)}>
          <Link href={getPathToDeviceEnrollment(enrollmentHistory.getBatchId())}>
            <PrimaryIcon className={classes.icon} />
            <label className={classes.label}>{enrollmentHistory.getBatchId()}</label>
          </Link>
        </div>
      );
    case DeviceHistoryColumns.STATUS:
      return (
        <label className={classnames("status", classes.label)}>{enrollmentHistory.status}</label>
      );
    case DeviceHistoryColumns.STATUS_MESSAGE:
      return (
        <label className={classnames("statusMessage", classes.label)}>{enrollmentHistory.statusMessage}</label>
      );
    case DeviceHistoryColumns.UPDATED:
      return (
        <label className={classnames("updated", classes.label)}>{enrollmentHistory.getUpdatedPerson()}</label>
      );
    case DeviceHistoryColumns.UPDATED_AT:
      return (
        <label className={classnames("updatedAt", classes.label)}>{enrollmentHistory.updatedAt}</label>
      );
    default:
      return null;
  }
});

export interface Model extends PaginatedListModel<Device> {
}

export interface Actions extends PaginatedListActions<Device> {
  loadMore?: () => void;
  toggleSortOrder?: () => void;
  setSortedColumn?: (column: string) => void;
  showEnrollmentDetails?: (device: Device) => void;
}

export const EnrollmentHistoryList = (props: Model & Actions) => {

  const {
    loadMore,
    toggleSortOrder,
    setSortedColumn,
    showEnrollmentDetails,
    ...remainingProps
  } = props;

  return (
    <PaginatedList
      { ...remainingProps }
      columns={columns}
      onClickLoadMore={loadMore}
      onClickColumn={setSortedColumn}
      onClickToggleSortOrder={toggleSortOrder}
      onClickItem={showEnrollmentDetails}
      renderItem={(enrollmentHistory: Device, column: string) => (
        <EnrollmentHistoryItem enrollmentHistory={enrollmentHistory} column={column} />
      )}
    />
  );
};

export default EnrollmentHistoryList;
