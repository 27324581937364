import { OrderedMap } from "immutable";
import { identityAction, isEmptyString, ReduxAction } from "@util";
import { AppSchema } from "@schemas";
import { getAuthToken } from "@main/selectors";
import { DeviceTypeListItem, DeviceTypeListItemAttributes } from "@data";
import { DeviceTypeClient, GetDeviceTypesResponse, RestClientError } from "@network";
import {
  DEFAULT_STATE,
  DeviceTypeColumn,
  DeviceTypeItems,
  DeviceTypesActionType,
} from "./reducers";
import {
  getDeviceTypeById,
  getDeviceTypesMap,
  getNextPage,
  getSearchQuery,
  isSortOrderAscending,
} from "./selectors";

export type Action = ReduxAction<DeviceTypesActionType>;

export const setSearchQuery = identityAction<DeviceTypesActionType, string>(
  DeviceTypesActionType.SET_SEARCH_QUERY, DEFAULT_STATE.searchQuery);

export const setSortedColumn = identityAction<DeviceTypesActionType, DeviceTypeColumn>(
  DeviceTypesActionType.SET_SORTED_COLUMN, DEFAULT_STATE.sortedColumn);

export const setSortOrder = identityAction<DeviceTypesActionType, boolean>(
  DeviceTypesActionType.SET_SORT_ORDER, DEFAULT_STATE.sortOrderAscending);

export const setDeviceTypes = identityAction<DeviceTypesActionType, DeviceTypeItems>(
  DeviceTypesActionType.SET_DEVICE_TYPES, DEFAULT_STATE.items);

export const setNextPage = identityAction<DeviceTypesActionType, string>(
  DeviceTypesActionType.SET_NEXT_PAGE, DEFAULT_STATE.nextPage);

export const setErrorMessage = identityAction<DeviceTypesActionType, string>(
  DeviceTypesActionType.SET_ERROR_MESSAGE, DEFAULT_STATE.errorMessage);

export const setShowEmptyView = identityAction<DeviceTypesActionType, boolean>(
  DeviceTypesActionType.TOGGLE_SHOW_EMPTY_VIEW, DEFAULT_STATE.showEmptyView);

export const setShowAccessDenied = identityAction<DeviceTypesActionType, boolean>(
  DeviceTypesActionType.TOGGLE_SHOW_ACCESS_DENIED, DEFAULT_STATE.showAccessDenied);

export const setShowLoadingIndicator = identityAction<DeviceTypesActionType, boolean>(
  DeviceTypesActionType.TOGGLE_SHOW_LOADING_INDICATOR, DEFAULT_STATE.showLoadingIndicator);

export const reset = () => (dispatch: any) => {
  dispatch(setSearchQuery());
  dispatch(setSortedColumn());
  dispatch(setSortOrder());
  dispatch(setDeviceTypes());
  dispatch(setNextPage());
  dispatch(setErrorMessage());
  dispatch(setShowEmptyView());
  dispatch(setShowAccessDenied());
  return dispatch(setShowLoadingIndicator());
};

export const toggleSortOrder = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(setSortOrder(!isSortOrderAscending(getState())));

export const updateDeviceTypes = (attrs: DeviceTypeItems = {}) =>
  (dispatch: any, getState: () => AppSchema) => {

    return dispatch(setDeviceTypes(
      getDeviceTypesMap(getState()).merge(
        OrderedMap<string, DeviceTypeListItemAttributes>(attrs)
          .map((deviceTypeAttrs: DeviceTypeListItemAttributes) => new DeviceTypeListItem(deviceTypeAttrs))
          .toOrderedMap())
        .map((deviceType: DeviceTypeListItem) => deviceType.toJS())
        .toOrderedMap()
        .toJS()));
  };

export const addDeviceTypes = (deviceTypes: DeviceTypeListItemAttributes[] = []) =>
  updateDeviceTypes(deviceTypes.reduce((data, deviceTypeAttrs) => {
    const deviceType = new DeviceTypeListItem(deviceTypeAttrs);

    if (!DeviceTypeListItem.EMPTY.equals(deviceType)) {
      data[deviceType.getTypeIdentity()] = deviceType.toJS();
    }

    return data;
  }, {}));

export const removeDeviceType = (id: string = "") => (dispatch: any, getState: () => AppSchema) =>
  dispatch(setDeviceTypes(
    getDeviceTypesMap(getState())
      .delete(id)
      .map((item: DeviceTypeListItem) => item.toJS())
      .toOrderedMap()
      .toJS()));

export const updateDeviceType = (attrs: DeviceTypeListItemAttributes = DeviceTypeListItem.EMPTY.toJS()) =>
  (dispatch: any) => {

    const deviceType = new DeviceTypeListItem(attrs);

    if (DeviceTypeListItem.EMPTY.equals(deviceType)) {
      return Promise.resolve();
    }

    return dispatch(updateDeviceTypes({
      [deviceType.getTypeIdentity()]: deviceType.toJS(),
    }));
  };

export const deviceTypeUpdated = (updatedDeviceType: DeviceTypeListItem = DeviceTypeListItem.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const deviceTypeId = updatedDeviceType.getTypeIdentity();

    const deviceType = getDeviceTypeById(deviceTypeId, state);

    // Do not update the device type modules store w/ the provided device type if it does not exist
    if (DeviceTypeListItem.EMPTY.equals(deviceType)) {
      return Promise.resolve();
    }

    return dispatch(updateDeviceTypes({
      [deviceTypeId]: new DeviceTypeListItem({
        ...deviceType.toJS(),
        ...updatedDeviceType.toJS(),
      }).toJS(),
    }));
  };

export const clearDeviceTypes = () => setDeviceTypes();

export const showEmptyView = (): Action => setShowEmptyView(true);
export const hideEmptyView = (): Action => setShowEmptyView(false);

export const showAccessDenied = (): Action => setShowAccessDenied(true);
export const hideAccessDenied = (): Action => setShowAccessDenied(false);

export const showLoadingIndicator = (): Action => setShowLoadingIndicator(true);
export const hideLoadingIndicator = (): Action => setShowLoadingIndicator(false);

export const loadDeviceTypesRequest = (): Action => ({
  type: DeviceTypesActionType.LOAD_DEVICE_TYPES,
});

export const loadDeviceTypesSuccess = (): Action => ({
  type: DeviceTypesActionType.LOAD_DEVICE_TYPES_SUCCESS,
});

export const loadDeviceTypesFailed = (error: string): Action => ({
  type: DeviceTypesActionType.LOAD_DEVICE_TYPES_FAILED,
  value: error,
});

export const fetchDeviceTypes = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const authToken = getAuthToken(state);
  const nextPageToken = getNextPage(state);
  const searchQuery = getSearchQuery(state);
  const isFirstPage = isEmptyString(nextPageToken);

  dispatch(showLoadingIndicator());
  dispatch(setErrorMessage());
  dispatch(hideAccessDenied());
  dispatch(loadDeviceTypesRequest());

  // Clear device types if loading the first page of results
  if (isFirstPage) {
    dispatch(clearDeviceTypes());
  }

  return DeviceTypeClient.getDeviceTypes(authToken, nextPageToken, searchQuery)
    .then((response: GetDeviceTypesResponse) => {

      // Ignore results if search query changed while request was in progress
      if (searchQuery !== getSearchQuery(getState())) {
        return Promise.resolve();
      }

      const { items = [], paging: { next: nextPage = "" } = {} } = response;

      const deviceTypes = items.map((attrs: DeviceTypeListItemAttributes) =>
        new DeviceTypeListItem(attrs).toJS());

      dispatch(loadDeviceTypesSuccess());
      dispatch(addDeviceTypes(deviceTypes));
      dispatch(setNextPage(nextPage || ""));
      dispatch(hideLoadingIndicator());
      return dispatch(hideEmptyView());

    }, (response: RestClientError) => {

      // Ignore results if search query changed while request was in progress
      if (searchQuery !== getSearchQuery(getState())) {
        return Promise.resolve();
      }

      const { analytic, error = "Fetch device types failed" } = response;

      dispatch(loadDeviceTypesFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(hideLoadingIndicator());

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideEmptyView());
    });
};

export const reloadDeviceTypes = () => (dispatch: any) => {

  dispatch(setNextPage());
  dispatch(clearDeviceTypes());
  dispatch(setSortedColumn());
  return dispatch(fetchDeviceTypes());
};

export const onMount = () => (dispatch: any) => {

  return dispatch(reloadDeviceTypes());
};

export const initialize = () => (dispatch: any) => {

  return dispatch(reset());
};
