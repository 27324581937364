import React from "react";
import { User } from "@data/User";
import { getPluralString, isEmptyString, noop } from "@util";
import { UsersList } from "@components/users-list";
import { UsersListColumn } from "@components/users-list/UsersListColumn";
import { useSecurityGroupUsers } from "@hooks";
import Users from "../components/Users";

export const DEFAULT_COLUMNS: UsersListColumn[] = [UsersListColumn.NAME];

export interface Model {
  groupName?: string;
}

export interface Actions {
  showUserDetails?: (user: User) => void;
  setRemoveUserButtonEnabled?: (enabled: boolean) => void;
}

type Props = Model & Actions;

const UsersContainer = (props: Props) => {

  const {
    groupName,
    showUserDetails,
    setRemoveUserButtonEnabled: enableRemoveUserButton = noop,
  } = props;

  const [{ users, ...model }, api] = useSecurityGroupUsers(groupName);

  const { showLoadingIndicator, nameFilter, totalNumUsers, totalNumVisibleUsers } = model;

  React.useEffect(() => {
    enableRemoveUserButton(totalNumUsers > 0);
    }, [enableRemoveUserButton, totalNumUsers]);

  const title = React.useMemo(() => {

    if (showLoadingIndicator) {
      return "Loading users that belong to this group...";
    } else if (!isEmptyString(nameFilter)) {
      return getPluralString(totalNumVisibleUsers, {
        other: "users found",
        one: "user found",
      });
    } else {
      return getPluralString(totalNumUsers, {
        other: "users belong to this group",
        one: "user belongs to this group",
      });
    }

  }, [showLoadingIndicator, nameFilter, totalNumUsers, totalNumVisibleUsers]);

  return (
    <Users title={title}>
      <UsersList
        {...model}
        {...api}
        items={users}
        columns={DEFAULT_COLUMNS}
        showSearchFilters={false}
        onClickItem={showUserDetails}
      />
    </Users>
  );
};

export default UsersContainer;
