import React from "react";
import { useHistory } from "react-router-dom";
import { FederationProvider } from "@data";
import { getPathToFederationProvider } from "@modules/federationProviderDetails/helpers";
import FederationProviders from "./containers/FederationProviders";
import {
  getEditFederationProviderPath,
  getCreateFederationProviderPath,
} from "@modules/federationProviderWizard/helpers";

export function FederationProvidersView() {

  const history = useHistory();

  const createFederationProvider = React.useCallback(() =>
  history.push(getCreateFederationProviderPath()), [history]);

  const editFederationProvider = React.useCallback((federationProvider: FederationProvider) =>
    history.push(getEditFederationProviderPath(federationProvider.getProviderId())), [history]);

  const showFederationProviderDetails = React.useCallback((federationProvider: FederationProvider) =>
    history.push(getPathToFederationProvider(federationProvider.getProviderId())), [history]);

  return (
    <FederationProviders
      createFederationProvider={createFederationProvider}
      editFederationProvider={editFederationProvider}
      showFederationProviderDetails={showFederationProviderDetails}
    />
  );
}

export default FederationProvidersView;
