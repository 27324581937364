import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    label: {
      color: Color.TEXT,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
      wordBreak: "break-word",
    },
    iconButton: {
      width: 36,
      height: 36,
      minWidth: "auto",
    },
    icon: {
      color: Color.GREY3,
      width: 18,
      height: 18,
    },
    popover: {
    },
    editIconButton: {
      marginLeft: "auto",
    },
    editIcon: {
    },
  });

export default styles;
