import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";

export interface AuditEventDescriptorMetadata {
  [key: string]: string;
}

export interface AuditEventDescriptorAttributes {
  type: string;
  severity: string;
  accountId: string;
  principal: string;
  metadata: AuditEventDescriptorMetadata;
  requestId: string;
  ip: string;
  userAgent: string;
  clientId: string;
  location: string;
  httpStatus: number;
}

export class AuditEventDescriptor extends Record({
  type: "",
  severity: "",
  accountId: "",
  principal: "",
  metadata: {},
  requestId: "",
  ip: "",
  userAgent: "",
  clientId: "",
  location: "",
  httpStatus: 0,
}) implements AuditEventDescriptorAttributes {

  public static EMPTY: AuditEventDescriptor = new AuditEventDescriptor();

  public readonly type: string;
  public readonly severity: string;
  public readonly accountId: string;
  public readonly principal: string;
  public readonly metadata: AuditEventDescriptorMetadata;
  public readonly requestId: string;
  public readonly ip: string;
  public readonly userAgent: string;
  public readonly clientId: string;
  public readonly location: string;
  public readonly httpStatus: number;

  public getType(): string {
    return getStringValue(this.type);
  }

  public hasType(): boolean {
    return !isEmptyString(this.getType());
  }

  public getSeverity(): string {
    return getStringValue(this.severity);
  }

  public hasSeverity(): boolean {
    return !isEmptyString(this.getSeverity());
  }

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public hasAccountId(): boolean {
    return !isEmptyString(this.getAccountId());
  }

  public getPrincipal(): string {
    return getStringValue(this.principal);
  }

  public hasPrincipal(): boolean {
    return !isEmptyString(this.getPrincipal());
  }

  public getMetadata(): AuditEventDescriptorMetadata {
    const metadata = this.metadata || {};

    return { ...metadata };
  }

  public hasMetadata(): boolean {
    return Object.keys(this.getMetadata()).length === 0;
  }

  public getRequestId(): string {
    return getStringValue(this.requestId);
  }

  public hasRequestId(): boolean {
    return !isEmptyString(this.getRequestId());
  }

  public getIP(): string {
    return getStringValue(this.ip);
  }

  public hasIP(): boolean {
    return !isEmptyString(this.getIP());
  }

  public getUserAgent(): string {
    return getStringValue(this.userAgent);
  }

  public hasUserAgent(): boolean {
    return !isEmptyString(this.getUserAgent());
  }

  public getClientId(): string {
    return getStringValue(this.clientId);
  }

  public hasClientId(): boolean {
    return !isEmptyString(this.getClientId());
  }

  public getLocation(): string {
    return getStringValue(this.location);
  }

  public hasLocation(): boolean {
    return !isEmptyString(this.getLocation());
  }

  public getHttpStatus(): number {
    return this.httpStatus;
  }

  public hasHttpStatus(): boolean {
    return this.getHttpStatus() > 0;
  }
}

export default AuditEventDescriptor;
