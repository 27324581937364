import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useSecurityServicesRegionalClient,
} from "@hooks";
import { GetServiceDataSetsResponse, ServiceDataSetAccess } from "@network";

type SuccessResponse = GetServiceDataSetsResponse;

export interface UseGetServiceDataSetsProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  accountId?: string;
  serviceId?: string;
  accessRequestType?: string;
}

export interface UseGetServiceDataSetsModel extends UseApiRequestModel<SuccessResponse> {
  dataSets: ServiceDataSetAccess[];
}

export interface UseGetServiceDataSetsActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetServiceDataSetsProps;
type Model = UseGetServiceDataSetsModel;
type Actions = UseGetServiceDataSetsActions;
type Result = [Model, Actions];

export const useGetServiceDataSets = (props: Props = {}): Result => {

  const {
    accountId = "",
    serviceId = "",
    accessRequestType = "",
    defaultErrorMessage = "Failed to get accessible data sets for the service",
    ...otherProps
  } = props;

  const SecurityServiceRegionalClient = useSecurityServicesRegionalClient();

  const makeApiRequest = React.useCallback(() =>
      SecurityServiceRegionalClient.getServiceDataSetsApi(accountId, serviceId, accessRequestType),
    [SecurityServiceRegionalClient, accountId, serviceId, accessRequestType]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const dataSets = React.useMemo(() => {
    return successResponse ? successResponse.accessibleDataSets : [];
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    dataSets,
  }), [
    baseModel,
    dataSets,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetServiceDataSets;
