import React from "react";
import classnames from "classnames";
import { isEmptyString } from "@util";
import { Chip, Link, TooltipIcon } from "@components";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import DefaultIcon from "@material-ui/icons/Info";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import ActionsMenu, { ActionMenuItem } from "@components/actions-menu";
import { Vector } from "@data/Vector";
import EditIcon from "@material-ui/icons/Edit";

export interface ChipViewModel {
  className?: string;
  editIconClassName?: string;
  title?: string;
  path?: string;
  items?: string[];
  icon?: React.ComponentType<SvgIconProps>;
  marginTop?: boolean;
  marginBottom?: boolean;
  showActionsButton?: boolean;
  showEditIcon?: boolean;
  showEmptyView?: boolean;
  emptyViewLabel?: string;
  editTooltipText?: string;
  actions?: ActionMenuItem[];
}

export interface ChipViewActions {
  onClickAction?: (action: ActionMenuItem) => void;
  onClickEditIcon?: () => void;
}

type Props = WithStyles<typeof styles> & ChipViewModel & ChipViewActions;

export const ChipView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    editIconClassName = "edit",
    title = "",
    path = "",
    items = [],
    icon: PrimaryIcon = DefaultIcon,
    marginTop = false,
    marginBottom = false,
    showEmptyView = false,
    showActionsButton = false,
    showEditIcon = false,
    editTooltipText = "Update",
    emptyViewLabel = "No tags are defined",
    actions = [],
    onClickAction,
    onClickEditIcon,
  } = props;

  if (items.length === 0 && !showEmptyView) {
    return null;
  }

  return (
    <div
      style={{ marginTop: marginTop ? 15 : 0, marginBottom: marginBottom ? 15 : 0 }}
      className={classnames("chipView", className, classes.container)}
    >
      <div className={classnames("titleContainer", classes.titleContainer)}>
        <label
          className={
            classnames("title", classes.title,
              { [classes.titleAndAction]: showActionsButton })
          }
        >
          {title}
        </label>
        {showActionsButton && (
          <ActionsMenu
            className={classnames(`${className}ActionsButton`, classes.actions)}
            buttonVariant="icon"
            actions={actions}
            onClickAction={onClickAction}
          />
        )}
        {showEditIcon && (
          <TooltipIcon
            className={classnames("editIcon", editIconClassName, classes.editIcon)}
            tooltipText={editTooltipText}
            size={Vector.square(20)}
            icon={EditIcon}
            onClick={onClickEditIcon}
          />
        )}
      </div>
      <div className={classnames(className, classes.items)}>
        {items.map((item: string) => {
          const chip = (
            <Chip
              key={`${className}-${item}`}
              label={item}
              color="primary"
              icon={<PrimaryIcon className={classnames("icon", classes.icon)} />}
              className={classnames("tag", item, classes.item)}
              style={{cursor: isEmptyString(path) ? "auto" : "pointer"}}
            />
          );
          return isEmptyString(path) ? chip :
            <Link href={path + item} key={`${className}-${item}`}>{chip}</Link>;
        })}
      </div>
      {!items.length && showEmptyView && (
        <div className={classnames(className, classes.emptyView)}>
          {emptyViewLabel}
        </div>
      )}
    </div>
  );
});

export default ChipView;
