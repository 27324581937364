import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import { Application, EmailTemplate, EmailTemplateMessageType } from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ApplicationsList, ApplicationsListActions, ApplicationsListModel } from "@components";
import { applicationView as styles } from "./styles";

export interface Model extends ApplicationsListModel {
  className?: string;
  title?: string;
  applicationId?: string;
  messageType?: EmailTemplateMessageType;
  currentEmails?: EmailTemplate[];
  hasDuplicateAppId: boolean;
  hasDuplicateNoAppId: boolean;
}

export interface Actions extends ApplicationsListActions {
  setSelectedApplication?: (application?: Application) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

enum Subtitles {
  DEFAULT = "NOTE: This step allows the email template to be used for a specific application.",
  OPTIONAL = "NOTE: This optional step allows the email template to be used for a specific application.",
  USED = "NOTE: An existing email template is already using this message type. In order to use this message type you must select a specific application.",
  USED_BY_APP = "NOTE: The application selected already has an existing email template with this message type. In order to use this message type you must select a different application.",
}

export const ApplicationView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Select Application",
    items = [],
    selectedItems = [],
    currentEmails = [],
    showLoadMoreButton,
    showSearch: showSearchView,
    showLoadingIndicator,
    applicationId,
    messageType,
    errorMessage,
    hasDuplicateAppId,
    hasDuplicateNoAppId,
    setSelectedApplication = noop,
    children,
    ...otherProps
  } = props;

  const subtitle = React.useMemo(() => {
    const isCustomTemplate = messageType === EmailTemplateMessageType.MESSAGE_CUSTOM_TEMPLATE;
    const sub = hasDuplicateAppId ? Subtitles.USED_BY_APP : (hasDuplicateNoAppId ? Subtitles.USED : Subtitles.DEFAULT);
    return isCustomTemplate ? Subtitles.OPTIONAL : sub;
  }, [messageType, hasDuplicateAppId, hasDuplicateNoAppId]);

  const applicationSelected = React.useMemo(() =>
    selectedItems.length > 0, [selectedItems]);

  // We only override the show search view property if a user is selected
  const showSearch = React.useMemo(() =>
      showSearchView && !applicationSelected,
    [showSearchView, applicationSelected]);

  const showLoadMore = React.useMemo(() =>
      applicationSelected ? false : showLoadMoreButton,
    [applicationSelected, showLoadMoreButton]);

  const showLoading = React.useMemo(() =>
      applicationSelected ? false : showLoadingIndicator,
    [applicationSelected, showLoadingIndicator]);

  const error = React.useMemo(() =>
      applicationSelected ? "" : errorMessage,
    [applicationSelected, errorMessage]);

  const visibleItems = React.useMemo(() => {
    if (applicationSelected) {
      return selectedItems.slice();
    } else {
      return items.slice();
    }
  }, [applicationSelected, items, selectedItems]);

  const setSelectedItems = React.useCallback((selectedApplications: Application[]) =>
    setSelectedApplication(selectedApplications.pop()), [setSelectedApplication]);

  return (
    <div className={classnames("applicationView", className, classes.container)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      {subtitle && (
        <Typography className={classnames("subtitle", classes.subtitle)} variant="h5">
          {subtitle}
        </Typography>
      )}
      <ApplicationsList
        {...otherProps}
        className={classnames("applications", classes.applications)}
        selectable={true}
        selectAllDisabled={true}
        items={visibleItems}
        showSearch={showSearch}
        showLoadMoreButton={showLoadMore}
        showLoadingIndicator={showLoading}
        errorMessage={error}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
      {children}
    </div>
  );
});

export default ApplicationView;
