import React from "react";
import { useHistory } from "react-router-dom";
import { DataAccessRequest } from "@data/DataAccessRequest";
import { getPathToDataAccessRequest } from "@modules/dataAccessRequestDetails/helpers";
import DataAccessRequests from "./containers/DataAccessRequests";

export function DataAccessRequestsView() {

  const history = useHistory();

  const showDataAccessRequestDetails = React.useCallback((dataAccessRequest: DataAccessRequest) =>
    history.push(getPathToDataAccessRequest(dataAccessRequest.getAccessRequestId())), [history]);

  return (
    <DataAccessRequests
      showDataAccessRequestDetails={showDataAccessRequestDetails}
    />
  );
}

export default DataAccessRequestsView;
