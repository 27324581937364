import React from "react";
import Stepper, { CustomStepperProps } from "./Stepper";
import Step from "./Step";
import StepLabel from "./StepLabel";
import StepContent from "./StepContent";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import classnames from "classnames";

export interface CustomVerticalStepperProps extends Partial<CustomStepperProps> {
  className?: string;
  activeStep: number;
  steps: string[];
}

interface CustomVerticalStepperActions {
  getStepContent: (step: number) => React.ReactNode;
}

type Props = WithStyles<typeof styles> & CustomVerticalStepperProps & CustomVerticalStepperActions;

export const VerticalStepper = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    activeStep,
    steps,
    getStepContent,
    ...otherProps
  } = props;

  return (
    <div className={classnames(className, classes.root)}>
      <Stepper {...otherProps} activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label} expanded={activeStep > 0}>
            <StepLabel className={classnames("stepLabel", classes.stepLabel)}>{label}</StepLabel>
            <StepContent>
              {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
});

export default VerticalStepper;
