import createStyles from "@material-ui/core/styles/createStyles";
import { Color } from "@components";

export const summaryViewLabel = () =>
  createStyles({
    container: {
      backgroundColor: Color.LIGHT_GREY,
      border: `1px solid ${Color.GREY3}`,
      borderRadius: 4,
      fontSize: 10,
      lineHeight: "16px",
      whiteSpace: "nowrap",
      margin: "10px 0 0 10px",
      padding: 2,
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    name: {
      color: Color.TEXT,
      fontWeight: 500,
      padding: "0 8px",
      alignSelf: "center !important",
    },
    value: {
      backgroundColor: Color.WHITE,
      color: Color.TEXT,
      fontWeight: 300,
      marginLeft: 0,
      padding: 8,
      flex: "3 0 auto",
    },
  });

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      marginBottom: 15,
    },
    summaryView: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "stretch",
    },
    emptyView: {},
    jsonView: {
    },
    jsonLabel: {},
    jsonValue: {
      display: "block",
      padding: 10,
      fontFamily: "monospace",
      fontWeight: 300,
      textAlign: "left",
      whiteSpace: "pre-wrap",
    },
    metadata: {
      alignSelf: "flex-start",
      flex: "1 0 auto",
    },
    link: {
      fontSize: 12,
      lineHeight: "14px",
      fontWeight: 400,
      margin: "16px 0 8px",
      textDecoration: "underline",
    },
    detailsLink: {
      marginTop: 25,
      alignSelf: "flex-end",
      flex: "1 0 auto",
    },
  });

export default styles;
