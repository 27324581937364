import React from "react";
import { Module } from "@data";
import { MODULE_ID } from "./constants";
import { isOpen as isDialogOpen } from "./selectors";
import { initialize, open } from "./actions";
import { PrimaryIcon } from "../users/components/styles";
import ChangePasswordDialog from "./containers/ChangePasswordDialog";

export const changePasswordModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "Change User Password",
  title: "Change User Password",
  icon: PrimaryIcon,
  view: <ChangePasswordDialog />,
  onClick: open,
  isDialogOpen,
  initialize,
};

export default changePasswordModule;
