import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { isEmptyString, noop } from "@util";
import { getDeviceRequest } from "../selectors";
import { openDeviceEnrollment } from "../actions";
import { SummaryView, SummaryViewModel } from "@components";
import DeviceRequestPayloadView from "../components/DeviceRequestPayloadView";
import { Device, DeviceDataModel, EnrollmentStatus, SummaryViewData } from "@data";

interface ContainerModel extends SummaryViewModel {
  showLoadingIndicator?: boolean;
  deviceRequest?: DeviceDataModel;
  device?: Device;
}

interface ContainerAction {
  refresh?: () => void;
  enrollDevice?: () => void;
  openDeviceEnrollmentWizard?: (device: DeviceDataModel) => void;
}

type Props = ContainerModel & ContainerAction;

const SummaryViewContainer = (props: Props) => {

  const {
    showLoadingIndicator,
    deviceRequest = DeviceDataModel.EMPTY,
    device = Device.EMPTY,
    refresh = noop,
    enrollDevice = noop,
    openDeviceEnrollmentWizard = noop,
    ...otherProps
  } = props;

  const items = React.useMemo(() => showLoadingIndicator ? [] : ([
      new SummaryViewData({
        className: "batchId",
        name: "Batch ID",
        value: device.getBatchId(),
      }),
      new SummaryViewData({
        className: "status",
        name: "Status",
        value: device.status,
      }),
      new SummaryViewData({
        className: "statusMessage",
        name: "Status Message",
        value: device.getStatusMessage(),
      }),
      new SummaryViewData({
        className: "updatedBy",
        name: "Updated By",
        value: device.getUpdatedBy(),
      })
    ]),
    [showLoadingIndicator, device]);

  const showDeviceRequest = React.useMemo(() =>
    !showLoadingIndicator && !isEmptyString(deviceRequest.getDeviceId()),
    [showLoadingIndicator, deviceRequest]);

  const isEnrollDeviceButtonDisabled = React.useMemo(() =>
    device.status !== EnrollmentStatus.VALIDATED, [device]);

  const deviceRequestJson = React.useMemo(() =>
    JSON.stringify(deviceRequest.toJS(), null, "  "), [deviceRequest]);

  const onClickEnrollDevice = React.useCallback(() => {
    enrollDevice();
    openDeviceEnrollmentWizard(deviceRequest);
  }, [openDeviceEnrollmentWizard, deviceRequest, enrollDevice]);

  return (
    <SummaryView {...otherProps} items={items}>
      {showDeviceRequest && (
        <DeviceRequestPayloadView
          json={deviceRequestJson}
          isEnrollDeviceButtonDisabled={isEnrollDeviceButtonDisabled}
          onClickEnrollDevice={onClickEnrollDevice}
        />
      )}
    </SummaryView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  deviceRequest: getDeviceRequest(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerAction): ContainerAction => ({
  openDeviceEnrollmentWizard: (device: DeviceDataModel) => dispatch(openDeviceEnrollment(device)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerAction, Props>(
  mapStateToProps,
  mapDispatchToProps
)(SummaryViewContainer);
