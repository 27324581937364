import { createSelector } from "reselect";
import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { createSelectors, Selector } from "@modules/base/createSelectors";
import {
  DEFAULT_STATE,
  DataSetDetailsSchema,
} from "./reducers";
import { getStringValue } from "@util";

const ACCOUNT_ID_REGEX = new RegExp(/lrn.*[:]([0-9]+)/);

export const {
  dataSetId: getDataSetId,
  getErrorMessage,
  isErrorMessageVisible,
  getCurrentAccountId,
  getCurrentUserId,
} = createSelectors<DataSetDetailsSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getDataSetAlias: Selector<string> = createSelector(
  getDataSetId, (dataSetId: string) =>
    getStringValue(dataSetId.split(":").pop()));

export const getDataSetAccountId: Selector<string> = createSelector(
  getDataSetId, (dataSetId: string) => {

    const match = dataSetId.match(ACCOUNT_ID_REGEX);

    if (!Array.isArray(match) || match.length === 0) {
      return "";
    } else {
      return getStringValue(match.pop());
    }
  });
