import { ActionMenuItem } from "@components";

export enum PropertiesTableAction {
  EDIT_PROPERTY = "edit_property",
  REMOVE_PROPERTY = "remove_property",
  CLONE_PROPERTY = "clone_property",
}

export const PROPERTIES_TABLE_ACTIONS: ActionMenuItem[] = [
  {
    id: PropertiesTableAction.EDIT_PROPERTY,
    name: "Edit Property",
  },
  {
    id: PropertiesTableAction.REMOVE_PROPERTY,
    name: "Remove Property",
  },
  {
    id: PropertiesTableAction.CLONE_PROPERTY,
    name: "Clone Property",
  },
];
