import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { canRestoreUserSession } from "@main/selectors";
import { restoreUserSession } from "@main/actions";
import useRestoreUserSession, {
  UseRestoreUserSessionActions,
  UseRestoreUserSessionModel,
} from "@hooks/session/use-restore-user-session";

export interface RestoreUserSessionModel extends UseRestoreUserSessionModel {
}

export interface RestoreUserSessionActions extends UseRestoreUserSessionActions {
}

type Model = RestoreUserSessionModel;
type Actions = RestoreUserSessionActions;
type Props = Model & Actions;

const RestoreUserSessionContainer = (props: Props) => {

  const {
    userSessionRestorable,
    restoreUserSession: restoreUserPrincipalSession,
    showRestoreSessionLoadingIndicator,
    hideRestoreSessionLoadingIndicator,
    restoreSessionFailed,
  } = props;

  useRestoreUserSession({
    userSessionRestorable,
    restoreUserSession: restoreUserPrincipalSession,
    showRestoreSessionLoadingIndicator,
    hideRestoreSessionLoadingIndicator,
    restoreSessionFailed,
  });

  return null;
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  userSessionRestorable: canRestoreUserSession(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  restoreUserSession: (callback?: () => void) => dispatch(restoreUserSession(callback)),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(RestoreUserSessionContainer);
