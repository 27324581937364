import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";
import { DataSetState, DataSetType } from "@data/DataSetRequest";

const ACCOUNT_ID_REGEX = new RegExp(/lrn.*[:]([0-9]+)/);

export interface DataSetInfoAttributes {
  dataSetId: string;
  dataSetGroup?: string;
  dataSetState: DataSetState;
  dataSetType?: DataSetType;
  description: string;
  dataOwners: string[];
  tags: string[];
  containsPII: boolean;
}

export class DataSetInfo extends Record({
  dataSetId: "",
  dataSetGroup: "",
  dataSetState: DataSetState.READY_FOR_INGESTION,
  dataSetType: DataSetType.NONE,
  description: "",
  dataOwners: [],
  tags: [],
  containsPII: false,
}) implements DataSetInfoAttributes {

  public static EMPTY: DataSetInfo = new DataSetInfo();

  public readonly dataSetId: string;
  public readonly dataSetGroup: string;
  public readonly dataSetState: DataSetState;
  public readonly dataSetType: DataSetType;
  public readonly description: string;
  public readonly dataOwners: string[];
  public readonly tags: string[];
  public readonly containsPII: boolean;

  public getDataSetId(): string {
    return getStringValue(this.dataSetId);
  }

  public getDataSetGroup(): string {
    return getStringValue(this.dataSetGroup);
  }

  public getState(): DataSetState {
    return this.dataSetState;
  }

  public getDataSetType(): DataSetType {
    return this.dataSetType || DataSetType.NONE;
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public getAlias(): string {
    return getStringValue(this.getDataSetId().split(":").pop());
  }

  public getAccountId(): string {

    const dataSetId = this.getDataSetId();

    const match = dataSetId.match(ACCOUNT_ID_REGEX);

    if (!Array.isArray(match) || match.length === 0) {
      return "";
    } else {
      return getStringValue(match.pop()); // we want the last match
    }
  }

  public getDataOwners(): string[] {
    const dataOwners = Array.isArray(this.dataOwners) ? this.dataOwners : [];
    return dataOwners
      .map(getStringValue)
      .filter(owner => !isEmptyString(owner))
      .sort();
  }

  public getDataOwnerEmails(): string[] {
    return this.getDataOwners()
      .map((owner: string) => `${getStringValue(owner).split(":").pop()}`);
  }

  public getTags(): string[] {
    const tags = Array.isArray(this.tags) ? this.tags : [];
    return tags
      .map(getStringValue)
      .filter(owner => !isEmptyString(owner))
      .sort();
  }

  public containsPii(): boolean {
    return this.containsPII;
  }
}

export default DataSetInfo;
