import { createStyles, StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const dialog: StyleRules = {
  paper: {
    padding: 0,
    overflow: "hidden",
  },
};

export const dialogTitle: StyleRules = {
  root: {
    background: Color.SIGNIFY_GREEN,
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    color: Color.BLACK,
    fontSize: 24,
    fontWeight: 400,
    lineHeight: "28px",
    margin: 0,
    padding: 16,
  },
};

export const dialogContent: StyleRules = {
  root: {
    padding: "32px 16px 16px",
    margin: 0,
  },
};

export const dialogActions: StyleRules = {
  root: {
    minHeight: 72,
    padding: "0 16px",
    margin: "16px 0 0",
    backgroundColor: Color.LIGHT_GREY5,
  },
};

export const styles = () =>
  createStyles({
    cancelButton: {
      minWidth: "auto",
      padding: "6px 8px",
    },
    continueButton: {
      marginLeft: 10,
    },
  });

export default styles;
