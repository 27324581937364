import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { createSelectors } from "@base/createSelectors";
import {
  DEFAULT_STATE,
  DeviceEnrollmentDetailsSchema,
} from "./reducers";

export const {
  batchId: getBatchId,
  autoRefresh: isAutoRefreshEnabled,
} = createSelectors<DeviceEnrollmentDetailsSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);
