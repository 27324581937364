import { createSelector } from "reselect";
import { AppSchema } from "@schemas";
import { DeviceTypeAction } from "./reducers";
import { DeviceTypeListItem, DeviceTypeListItemAttributes } from "@data";

const getDeviceTypeAttributes = (state: AppSchema): DeviceTypeListItemAttributes => {
  return state.deviceTypeManager.deviceType;
};

export const getDeviceTypeAction = (state: AppSchema): DeviceTypeAction => {
  return state.deviceTypeManager.deviceTypeAction;
};

export const getErrorMessage = (state: AppSchema): string => {
  return state.deviceTypeManager.errorMessage;
};

export const getSuccessMessage = (state: AppSchema): string => {
  return state.deviceTypeManager.successMessage;
};

export const isDialogVisible = (state: AppSchema): boolean => {
  return state.deviceTypeManager.showDialog;
};

export const isAccessDeniedVisible = (state: AppSchema): boolean => {
  return state.deviceTypeManager.showAccessDenied;
};

export const isProgressIndicatorVisible = (state: AppSchema): boolean => {
  return state.deviceTypeManager.showProgressIndicator;
};

export const getDeviceType: (state: AppSchema) => DeviceTypeListItem = createSelector(
  getDeviceTypeAttributes, (attrs: DeviceTypeListItemAttributes) => new DeviceTypeListItem(attrs));

export const getDialogClassName: (state: AppSchema) => string = createSelector(
  getDeviceTypeAction, (deviceTypeAction: DeviceTypeAction) => {

    switch (deviceTypeAction) {
      case DeviceTypeAction.PROMOTE:
        return "promoteDeviceTypeDialog";
      case DeviceTypeAction.DRAFT:
        return "draftDeviceTypeDialog";
      case DeviceTypeAction.DEPRECATE:
        return "deprecateDeviceTypeDialog";
      case DeviceTypeAction.DECOMMISSION:
        return "decommissionDeviceTypeDialog";
      case DeviceTypeAction.DELETE:
        return "deleteDeviceTypeDialog";
      default:
        return "invalidDeviceTypeAction";
    }
  });

export const getDialogTitle: (state: AppSchema) => string = createSelector(
  [getDeviceTypeAction, getSuccessMessage],
  (deviceTypeAction: DeviceTypeAction, successMessage: string) => {

    switch (deviceTypeAction) {
      case DeviceTypeAction.PROMOTE:
        return successMessage.length > 0 ? "Device Type Promoted" : "Promote Device Type";
      case DeviceTypeAction.DRAFT:
        return successMessage.length > 0 ? "New Device Type Version Drafted" : "Draft New Device Type Version";
      case DeviceTypeAction.DEPRECATE:
        return successMessage.length > 0 ? "Device Type Deprecated" : "Deprecate Device Type";
      case DeviceTypeAction.DECOMMISSION:
        return successMessage.length > 0 ? "Device Type Decommissioned" : "Decommission Device Type";
      case DeviceTypeAction.DELETE:
        return successMessage.length > 0 ? "Device Type Deleted" : "Delete Device Type";
      default:
        return "Invalid Schema Action";
    }
  });

export const getDialogContinueButtonLabel: (state: AppSchema) => string = createSelector(
  getDeviceTypeAction, (deviceTypeAction: DeviceTypeAction) => {

    switch (deviceTypeAction) {
      case DeviceTypeAction.PROMOTE:
        return "Promote Device Type";
      case DeviceTypeAction.DRAFT:
        return "Draft New Device Type Version";
      case DeviceTypeAction.DEPRECATE:
        return "Deprecate Device Type";
      case DeviceTypeAction.DECOMMISSION:
        return "Decommission Device Type";
      case DeviceTypeAction.DELETE:
        return "Delete Device Type";
      default:
        return "Go Back";
    }
  });
