import { AppSchema } from "@main/schemas";
import { createActions } from "@base/createActions";
import { ACTION_TYPES, ShareDashboardSteps, DEFAULT_STATE } from "./reducers";
import { RestClientError, DashboardSecurityClient } from "@network";
import { getAccessToken, getShareDashboardStep, getUserId, getDashboardId } from "./selectors";
import { fetchDashboards } from "@modules/dashboards/actions";

export const {
  dashboardId: setDashboardId,
  userId: setUserId,
  open: setOpen,
  step: setShareDashboardStep,
  setErrorMessage,
  setSuccessMessage,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  SHARE_DASHBOARD_REQUEST: shareDashboardRequest,
  SHARE_DASHBOARD_SUCCESS: shareDashboardSuccess,
  SHARE_DASHBOARD_FAILED: shareDashboardFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setDashboardId());
  dispatch(setUserId());
  dispatch(setShareDashboardStep());
  dispatch(setOpen());
  return dispatch(baseReset());
};

export const showConfirmationDialog = () => (dispatch: any) =>
  dispatch(setShareDashboardStep(ShareDashboardSteps.CONFIRM));

export const shareDashboard = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const userId = getUserId(state);
  const dashboardId = getDashboardId(state);
  const accessToken = getAccessToken(state);

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(shareDashboardRequest());

  return DashboardSecurityClient.shareDashboard(accessToken, dashboardId, userId)
    .then(() => {

      dispatch(shareDashboardSuccess());
      dispatch(hideLoadingIndicator());
      dispatch(setSuccessMessage("Dashboard has been shared"));
      return dispatch(fetchDashboards());
    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to share the dashboard" } = response;

      dispatch(shareDashboardFailed(analytic));
      dispatch(hideLoadingIndicator());

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(setErrorMessage(error));
    });
};

export const confirm = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const step = getShareDashboardStep(state);

  if ( step === ShareDashboardSteps.GET_USER_ID) {
    return dispatch(showConfirmationDialog());
  }

  return dispatch(shareDashboard());
};

export const open = (dashboardId: number) => (dispatch: any) => {

  dispatch(reset());
  dispatch(setDashboardId(dashboardId));
  return dispatch(setOpen(true));
};

export const close = reset;

export const initialize = reset;
