import React from "react";
import classnames from "classnames";
import { testViewDrawer as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Box, Drawer } from "@material-ui/core";
import TestView, { TestViewActions, TestViewModel } from "../components/TestView";
import Toolbar from "@material-ui/core/Toolbar";
import { IconButton } from "@components";
import CancelIcon from "@material-ui/icons/Clear";
import { noop } from "@util";

export interface TestViewDrawerModel extends TestViewModel {
  showTestView?: boolean;
}

export interface TestViewDrawerActions extends TestViewActions {
  setShowTestView?: (show: boolean) => void;
}

type Props = WithStyles<typeof styles> & TestViewDrawerModel & TestViewDrawerActions;

export const TestViewDrawer = withStyles(styles)((props: Props) => {

  const {
    classes,
    executionData,
    showTestView,
    setShowTestView = noop,
    ...otherProps
  } = props;

  const close = React.useCallback(() => setShowTestView(false), [setShowTestView]);

  return (
    <div className={classnames("testViewDrawer", classes.container)}>
      <Drawer
        className={classnames("drawer", classes.drawer)}
        open={showTestView}
        anchor="right"
      >
        <Box boxShadow={1}>
          <Toolbar className={classnames("toolbar", classes.toolbar)}>
            <p className={classnames("title", classes.title)}>Test Workload</p>
            <IconButton onClick={close}>
              <CancelIcon/>
            </IconButton>
          </Toolbar>
        </Box>
        <TestView
          {...otherProps}
          className={classnames("testView", classes.testView)}
          showCancelButton={true}
          cancel={close}
          executionData={executionData}
        />
      </Drawer>
    </div>
  );
});

export default TestViewDrawer;
