import { createStyles, StyleRules, Theme } from "@material-ui/core/styles";
import Color from "@Color";
import ModuleIcon from "@material-ui/icons/Apartment";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const textField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "14px",
      fontWeight: 300,
      padding: "2px 0",
      margin: 0,
    },
    "& input": {
      fontSize: 14,
      fontWeight: 400,
      padding: "18.5px 14px",
      margin: 0,
    },
  },
};

export const textAreaField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& textarea": {
      fontSize: 14,
      fontWeight: 400,
      padding: 0,
      margin: 0,
    },
  },
};

export const jsonView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      marginTop: 8,
    },
    jsonEditor: {
      flex: "1 0 auto",
      marginTop: 25,
    },
  });

export const optionsView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    switchContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      marginTop: 15,
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    label: {
      fontWeight: 300,
      fontSize: 14,
    },
    switch: {
      marginLeft: 8,
      marginRight: 8,
    },
    alert: {
      marginTop: 16,
    },
    alertTitle: {
      fontSize: 14,
      marginTop: 2,
    },
    alertDescription: {
      fontSize: 14,
      lineHeight: "21px",
      marginTop: 10,
      marginBottom: 0,
    },
    errorViewContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
  });

export const layoutInfoView = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      marginTop: 8,
    },
    metadata: {
      color: Color.TEXT,
      fontWeight: 200,
      marginTop: 20,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    nameTextField: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    description: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    metadataContainer: {
      marginTop: 20,
      display: "flex",
      flexFlow: "row wrap",
    },
    metadataKey: {
      fontFamily: "Roboto, sans-serif",
      minWidth: 250,
    },
    metadataValue: {
      fontFamily: "Roboto, sans-serif",
      minWidth: 250,
      marginLeft: 25,
    },
    addMetadataButton: {
      margin: "16px 0 0 16px",
      width: 32,
      height: 32,
      borderRadius: 32,
      padding: 0,
      backgroundColor: Color.WHITE,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.54)",
      },
    },
    metadataTagContainer: {
      display: "flex",
      flexFlow: "row wrap",
    },
    metadataTag: {
      height: 28,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
      margin: theme.spacing(),
      padding: theme.spacing(),
      color: Color.WHITE,
    },
    metadataIcon: {
      color: Color.WHITE,
      width: 14,
      height: 14,
      marginLeft: 2,
      marginRight: 2,
      "&:first-child": {
        marginRight: 0,
      },
    },
  });

export const childrenView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      marginTop: 8,
    },
  });

export const typeDialog = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      marginTop: 8,
    },
    descriptionTitle: {
      color: Color.TEXT,
      fontWeight: 200,
      marginTop: 15,
    },
    metadata: {
      color: Color.TEXT,
      fontWeight: 200,
      marginTop: 15,
    },
    typeList: {
      flex: "1 0 auto",
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    description: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
      width: "50%"
    },
    metadataContainer: {
      marginTop: 20,
      display: "flex",
      flexFlow: "row wrap",
    },
    metadataKey: {
      fontFamily: "Roboto, sans-serif",
      minWidth: 250,
    },
    metadataValue: {
      fontFamily: "Roboto, sans-serif",
      minWidth: 250,
      marginLeft: 25,
    },
    addMetadataButton: {
      margin: "16px 0 0 16px",
      width: 32,
      height: 32,
      borderRadius: 32,
      padding: 0,
      backgroundColor: Color.WHITE,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.54)",
      },
    },
    metadataTagContainer: {
      display: "flex",
      flexFlow: "row wrap",
    },
    metadataTag: {
      height: 28,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
      margin: theme.spacing(),
      padding: theme.spacing(),
      color: Color.WHITE,
    },
    metadataIcon: {
      color: Color.WHITE,
      width: 14,
      height: 14,
      marginLeft: 2,
      marginRight: 2,
      "&:first-child": {
        marginRight: 0,
      },
    },
  });

export const removeChildDialog = () =>
  createStyles({
    content: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    subtitle: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 18,
    },
    label: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "18px",
      textAlign: "center",
      marginTop: 16,
      marginBottom: 16,
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
  });

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
  });
