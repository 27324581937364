import React from "react";
import { noop } from "@util";
import { Trigger, TriggerType } from "@data";
import classnames from "classnames";
import { useCurrentAccountId } from "@hooks";
import { triggersView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  ActionMenuItem,
  ADD_DATA_LAKE_TRIGGER,
  ADD_SCHEDULE_TRIGGER,
  ADD_WORKLOAD_COMPLETE_TRIGGER,
  AddTriggerActionsMenu,
  ContinueButton,
  RefreshButton,
  TriggerAction,
  TriggersList,
  TriggersListActions,
  TriggersListModel,
  WorkloadCompletionDialog,
} from "@components";

export interface Model extends TriggersListModel {
  triggers: Trigger[];
  workloadName: string;
  version: number;
  accountId?: string;
  scheduleTriggerButtonDisabled?: boolean;
  dataLakeTriggerButtonDisabled?: boolean;
  workloadCompleteTriggerButtonDisabled?: boolean;
  testTriggerButtonDisabled?: boolean;
  disableEdit?: boolean;
}

export interface Actions extends TriggersListActions {
  addTrigger?: () => void;
  editTrigger?: (trigger: Trigger) => void;
  removeAllTriggers?: () => void;
  testTrigger?: (trigger: Trigger) => void;
  removeTrigger?: (trigger: Trigger) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const TriggersView = withStyles(styles)((props: Props) => {

  const {
    classes,
    triggers,
    workloadName,
    version,
    showLoadingIndicator,
    accountId = useCurrentAccountId(),
    scheduleTriggerButtonDisabled,
    dataLakeTriggerButtonDisabled,
    workloadCompleteTriggerButtonDisabled,
    addTrigger = noop,
    editTrigger = noop,
    removeAllTriggers,
    testTriggerButtonDisabled,
    testTrigger = noop,
    disableEdit,
    removeTrigger = noop,
    refresh = noop,
    actions,
    ...otherProps
  } = props;

  const [showWorkloadCompletionDialog, setShowWorkloadCompletionDialog] = React.useState(false);
  const [currentTrigger, setCurrentTrigger] = React.useState(Trigger.EMPTY);

  const onEditSuccess = React.useCallback(() => {
    setShowWorkloadCompletionDialog(false);
    refresh();
  }, [setShowWorkloadCompletionDialog, refresh]);

  const disableRemoveAllButton = React.useMemo(() =>
      disableEdit || triggers.length <= 1,
    [ disableEdit, triggers ]);

  const onClickTriggerAction = React.useCallback((trigger: Trigger, action: ActionMenuItem) => {
    switch (action.id) {
      case TriggerAction.TEST_TRIGGER:
        return testTrigger(trigger);
      case TriggerAction.EDIT_TRIGGER:
        if (trigger.getType() === TriggerType.WORKLOAD_COMPLETE) {
          setCurrentTrigger(trigger);
          return setShowWorkloadCompletionDialog(true);
        }
        return editTrigger(trigger);
      case TriggerAction.REMOVE_TRIGGER:
        return removeTrigger(trigger);
      default:
        return noop();
    }
  }, [testTrigger, editTrigger, removeTrigger, setCurrentTrigger, setShowWorkloadCompletionDialog]);

  const mapTriggerToAction = React.useCallback((item: Trigger, action: ActionMenuItem) => {
    switch (action.id) {
      case TriggerAction.TEST_TRIGGER:
        return {
          ...action,
          disabled: testTriggerButtonDisabled ||
            item.getType() === TriggerType.WORKLOAD_COMPLETE, // testing workload complete is currently unsupported
        };
      case TriggerAction.EDIT_TRIGGER:
        return {
          ...action,
          disabled: disableEdit // when workload is not in DRAFT state
          || item.getType() === TriggerType.DATA_LAKE && dataLakeTriggerButtonDisabled // no more available data inputs
        };
      case TriggerAction.REMOVE_TRIGGER:
        return {
          ...action,
          disabled: disableEdit,
        };
      default:
        return action;
    }
  }, [disableEdit, testTriggerButtonDisabled, dataLakeTriggerButtonDisabled]);

  const addTriggerActions = React.useMemo(() => {
    return [
      {
        ...ADD_DATA_LAKE_TRIGGER,
        disabled: dataLakeTriggerButtonDisabled,
      },
      {
        ...ADD_SCHEDULE_TRIGGER,
        disabled: scheduleTriggerButtonDisabled,
      },
      {
        ...ADD_WORKLOAD_COMPLETE_TRIGGER,
        disabled: workloadCompleteTriggerButtonDisabled,
      }
    ];
  }, [scheduleTriggerButtonDisabled, dataLakeTriggerButtonDisabled, workloadCompleteTriggerButtonDisabled]);

  const addTriggerButtonDisabled = React.useMemo(() =>
    dataLakeTriggerButtonDisabled && scheduleTriggerButtonDisabled && workloadCompleteTriggerButtonDisabled,
    [dataLakeTriggerButtonDisabled, scheduleTriggerButtonDisabled, workloadCompleteTriggerButtonDisabled]);

  return (
    <div className={classnames("triggersView", classes.container)}>
      <div className={classnames("header", classes.header)}>
        <div className={classnames("headerContent", classes.headerContent)}>
          <label className={classnames("triggerTitle", classes.triggerTitle)}>
            Triggers
          </label>
          <RefreshButton
            className={classnames("refreshTriggerButton")}
            loading={showLoadingIndicator}
            refresh={refresh}
          />
        </div>
        <div className={classnames("controls", classes.controls)}>
          <AddTriggerActionsMenu
            className={classnames("addTriggerButton", classes.addTrigger)}
            addTrigger={addTrigger}
            actions={addTriggerActions}
            disabled={addTriggerButtonDisabled}
          />
          <ContinueButton
            className={classnames("removeAllButton", classes.removeAll)}
            color={"secondary"}
            label={"Remove All"}
            disabled={disableRemoveAllButton}
            onClick={removeAllTriggers}
          />
        </div>
      </div>
      <TriggersList
        {...otherProps}
        triggers={triggers}
        actions={actions}
        showLoadingIndicator={showLoadingIndicator}
        onClickAction={onClickTriggerAction}
        mapItemToAction={mapTriggerToAction}
      />
      <WorkloadCompletionDialog
        open={showWorkloadCompletionDialog}
        cancel={() => setShowWorkloadCompletionDialog(false)}
        onClose={() => setShowWorkloadCompletionDialog(false)}
        onSuccessMessageShown={onEditSuccess}
        name={workloadName}
        version={version}
        trigger={currentTrigger}
      />
    </div>
  );
});

export default TriggersView;
