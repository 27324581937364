import { createSelector } from "reselect";
import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { createSelectors, Selector } from "@modules/base/createSelectors";
import {
  DEFAULT_STATE,
  DeviceDetailsSchema,
  DeviceHistoryColumns,
} from "./reducers";
import {
  Device,
  DeviceAttributes,
  DeviceConfiguration,
  DeviceConfigurationAttributes, DeviceDataModel, DeviceDataModelAttributes,
  EnrollmentStatus,
  SummaryViewData,
  DeviceDataScope
} from "@data";
import { Comparator, compare, getPluralString, isEmptyString } from "@util";
import AppSchema from "@schemas";
import { DeviceDataType } from "@components/device-data-type";

const compareByBatchId = (item: Device, otherItem: Device, ascending: boolean) =>
  compare(item.getBatchId(), otherItem.getBatchId(), ascending);

const compareByStatus = (item: Device, otherItem: Device, ascending: boolean) =>
  compare(item.status, otherItem.status, ascending);

const compareByStatusMessage = (item: Device, otherItem: Device, ascending: boolean) =>
  compare(item.statusMessage, otherItem.statusMessage, ascending);

const compareByUpdatedBy = (item: Device, otherItem: Device, ascending: boolean) =>
  compare(item.updatedBy, otherItem.updatedBy, ascending);

const compareByUpdatedAt = (item: Device, otherItem: Device, ascending: boolean) =>
  compare(item.updatedAt, otherItem.updatedAt, ascending);

const createComparator = (sortedColumn: DeviceHistoryColumns, ascending: boolean) =>
  (item: Device, otherItem: Device) => {
    switch (sortedColumn) {
      case DeviceHistoryColumns.BATCH_ID:
        return compareByBatchId(item, otherItem, ascending);
      case DeviceHistoryColumns.STATUS:
        return compareByStatus(item, otherItem, ascending);
      case DeviceHistoryColumns.STATUS_MESSAGE:
        return compareByStatusMessage(item, otherItem, ascending);
      case DeviceHistoryColumns.UPDATED:
        return compareByUpdatedBy(item, otherItem, ascending);
      case DeviceHistoryColumns.UPDATED_AT:
        return compareByUpdatedAt(item, otherItem, ascending);
      default:
        return 0;
    }
  };

export const {
  device: getDeviceAttributes,
  deviceId: getDeviceId,
  deviceDataViewMode: getDeviceDataViewMode,
  deviceScopeMode: getDeviceScopeMode,
  items: getItemAttributes,
  searchQuery: getSearchQuery,
  nextPage: getNextPage,
  sortedColumn: getSortedColumn,
  sortOrderAscending: isSortOrderAscending,
  deviceConfiguration: getDeviceConfigurationAttributes,
  dataMode: getDataMode,
  configurationErrorMessage: getConfigurationErrorMessage,
  deviceJson: getDeviceJson,
  selectedTab: getSelectedTab,
  deleteDataDialog: isDeleteDataDialogOpen,
  deviceDataEditMode: isDeviceDataEditMode,
  enrollmentLoadingIndicator: isEnrollmentLoadingIndicator,
  historyLoadingIndicator: isHistoryLoadingIndicator,
  configurationLoadingIndicator: isConfigurationLoadingIndicator,
  configRefresh: getConfigRefresh,
  deviceTypeIdentity: getDeviceTypeIdentity,
  deviceRequests: getDeviceRequestsAttributes,
  getErrorMessage,
  getSuccessMessage,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  isNotFoundVisible,
} = createSelectors<DeviceDetailsSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getDevice: Selector<Device> = createSelector(
  getDeviceAttributes, (attrs: DeviceAttributes) =>
    new Device(attrs));

export const getDeviceConfiguration: Selector<DeviceConfiguration> = createSelector(
  getDeviceConfigurationAttributes, (attrs: DeviceConfigurationAttributes) =>
    new DeviceConfiguration(attrs));

export const getBatchId: Selector<string> = createSelector(
  getDevice, (device: Device) => device.getBatchId());

export const getDeviceIdFromDeviceReference: Selector<string> = createSelector(
  getDevice, (device: Device) => device.getDeviceId());

export const getStatus: Selector<EnrollmentStatus> = createSelector(
  getDevice, (device: Device) => device.status);

export const getStatusMessage: Selector<string> = createSelector(
  getDevice, (device: Device) => device.statusMessage);

export const getUpdatedBy: Selector<string> = createSelector(
  getDevice, (device: Device) => device.updatedBy);

export const getDeviceAsJson: Selector<string> = createSelector(
  [isLoadingIndicatorVisible, getDeviceJson],
  (isLoading: boolean, deviceJson: string) => {

    return isLoading ? JSON.stringify(DEFAULT_STATE.device, null, "  ") : deviceJson;
  });

export const getDeviceConfigurationAsJson: Selector<string> = createSelector(
  [getDeviceConfiguration, isLoadingIndicatorVisible],
  (device: DeviceConfiguration, loading: boolean) => {

    const data = loading ? DEFAULT_STATE.deviceConfiguration : device.toJS();

    return JSON.stringify(data, null, "  ");
  }
);

export const isActualViewActive: Selector<boolean> = createSelector(
  getDeviceDataViewMode, (viewMode: DeviceDataType) =>
    DeviceDataType.ACTUAL === viewMode
);

export const isDesiredViewActive: Selector<boolean> = createSelector(
  getDeviceDataViewMode, (viewMode: DeviceDataType) =>
    DeviceDataType.DESIRED === viewMode
);

export const isMetadataViewActive: Selector<boolean> = createSelector(
  getDeviceDataViewMode, (viewMode: DeviceDataType) =>
    DeviceDataType.METADATA === viewMode
);

export const isDeviceScopeViewActive: Selector<boolean> = createSelector(
  getDeviceScopeMode, (viewMode: DeviceDataScope) =>
    DeviceDataScope.DEVICE === viewMode
);

export const isTypeScopeViewActive: Selector<boolean> = createSelector(
  getDeviceScopeMode, (viewMode: DeviceDataScope) =>
    DeviceDataScope.TYPE === viewMode
);

export const isRegionScopeViewActive: Selector<boolean> = createSelector(
  getDeviceScopeMode, (viewMode: DeviceDataScope) =>
    DeviceDataScope.REGION === viewMode
);

export const getItems: (state: AppSchema) => Device[] = createSelector(
  getItemAttributes, (items: DeviceAttributes[]) =>
    items.map((attrs: DeviceAttributes) => new Device(attrs)));

export const getNumItems: (state: AppSchema) => number = createSelector(
  getItems, (items: Device[]) => items.length);

const getComparator: (state: AppSchema) => Comparator<Device> = createSelector(
  [getSortedColumn, isSortOrderAscending], (sortedColumn: DeviceHistoryColumns, ascending: boolean) =>
    createComparator(sortedColumn, ascending));

export const getSortedItems: (state: AppSchema) => Device[] = createSelector(
  [getItems, getComparator], (items: Device[], comparator: Comparator<Device>) => {
    items.sort(comparator);
    return items.slice();
  });

export const getSearchResults: (state: AppSchema) =>  Device[] = createSelector(
  [getSortedItems, getSearchQuery, getComparator],
  (items: Device[], searchQuery: string, comparator: Comparator<Device>) => {
    if (searchQuery.length === 0 || items.length === 0) {
      return items;
    }

    const devices = items.slice();
    const nameFilter = searchQuery.toLowerCase();

    return devices.filter((device: Device) =>
      device.getDeviceId().toLowerCase().indexOf(nameFilter) >= 0 ||
      device.getBatchId().toLowerCase().indexOf(nameFilter) >= 0 ||
      device.getStatus().toLowerCase().indexOf(nameFilter) >= 0 ||
      device.getUpdatedBy().toLowerCase().indexOf(nameFilter) >= 0 ||
      device.getUpdatedAt().toLowerCase().indexOf(nameFilter) >= 0
    ).sort(comparator);
  });

export const getNumSearchResults: (state: AppSchema) => number = createSelector(
  getSearchResults, (items: Device[]) => items.length);

export const getDevicesTitle: (state: AppSchema) => string = createSelector(
  getNumSearchResults , (numItems: number) => getPluralString(numItems, {
    other: "Enrollment Batches",
    one: "Enrollment Batch",
  }));

export const isDeviceEnrolled: Selector<boolean> = createSelector(
 [ isLoadingIndicatorVisible, getDevice], (loading: boolean, device: Device) =>
    !loading && device.status === EnrollmentStatus.ENROLLED
);

export const getDeviceDataInfo: Selector<string> = createSelector(
  getDeviceDataViewMode, (viewMode: DeviceDataType) => {
    if (viewMode === DeviceDataType.ACTUAL) {
      return "Actual Configuration represents the last available configuration for a given device (and schema) " +
        "that has been provided by the device itself to the cloud. " +
        "This configuration is read-only";
    } else if (viewMode === DeviceDataType.DESIRED) {
      return "Desired Configuration represents a requested, but not yet applied change" +
        " to configuration for a given device (and schema). Only device scope configuration is shown here.";
    } else {
      return "Metadata represents cloud-only administrative data about the device";
    }
  });

export const isLoadingIndicator: Selector<boolean> = createSelector(
  [
    isEnrollmentLoadingIndicator,
    isHistoryLoadingIndicator,
    isConfigurationLoadingIndicator,
    isLoadingIndicatorVisible,
    getConfigurationErrorMessage,
  ],
  (enrollment: boolean, history: boolean, config: boolean, loading: boolean, configError: string) => {
    if (isEmptyString(configError)) {
      return enrollment || history || config || loading;
    } else {
      return enrollment || history || config;
    }
  }
);

export const getSummaryViewItems: Selector<SummaryViewData[]> = createSelector(
  [isLoadingIndicator, getBatchId, getStatus, getStatusMessage, getUpdatedBy],
  (loading, batchId, status, statusMessage, updatedBy) => loading ? [] : ([
    new SummaryViewData({
      className: "batchId",
      name: "Batch ID",
      value: batchId,
    }),
    new SummaryViewData({
      className: "status",
      name: "Status",
      value: status,
    }),
    new SummaryViewData({
      className: "statusMessage",
      name: "Status Message",
      value: statusMessage,
    }),
    new SummaryViewData({
      className: "updatedBy",
      name: "Updated By",
      value: updatedBy,
    }),
  ]));

export const getDeviceRequests: Selector<DeviceDataModel[]> = createSelector(
  getDeviceRequestsAttributes, (devices: DeviceDataModelAttributes[]) =>
    devices.map(attrs => new DeviceDataModel(attrs)));

export const isEnrollmentFailure: Selector<boolean> = createSelector(
  [ isLoadingIndicatorVisible, getDevice], (loading: boolean, device: Device) =>
    !loading && device.status === EnrollmentStatus.ENROLLMENT_FAILURE
);
