import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MODULE_PATH as DETAILS_PAGE_PATH } from "@modules/userDetails/constants";
import { close, deleteUser } from "../actions";
import {
  getErrorMessage,
  getSuccessMessage,
  getUserId,
  isAccessDeniedVisible,
  isDialogOpen,
  isLoadingIndicatorVisible,
  isUserIdValid,
} from "../selectors";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import DeleteUser from "../components/DeleteUser";

interface Model extends PortalModuleDialogModel<string> {
  userId?: string;
}

interface Actions extends PortalModuleDialogActions<string> {
}

type Props = Model & Actions;

const DeleteUserDialog = (props: Props) => {

  const { item, cancel: closeDialog = noop } = props;

  const match = useRouteMatch(DETAILS_PAGE_PATH);

  const history = useHistory();

  const goBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onSuccessMessageShown = React.useCallback(() => {

    if (match && match.isExact) {
      goBack();
    }

    closeDialog();

  }, [match, closeDialog, goBack]);

  return (
    <PortalModuleDialog {...props} onSuccessMessageShown={onSuccessMessageShown}>
      <DeleteUser userId={item} />
    </PortalModuleDialog>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  className: "deleteUserDialog",
  title: "Delete User",
  open: isDialogOpen(state),
  item: getUserId(state),
  loading: isLoadingIndicatorVisible(state),
  accessDenied: isAccessDeniedVisible(state),
  errorMessage: getErrorMessage(state),
  successMessage: getSuccessMessage(state),
  continueButtonLabel: "Delete User",
  continueButtonDisabled: !isUserIdValid(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  cancel: () => dispatch(close()),
  confirm: () => dispatch(deleteUser()),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteUserDialog);
