import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { getEditFederationProviderPath } from "@modules/federationProviderWizard/helpers";
import FederationProviderDetails from "./containers/FederationProviderDetails";

export function FederationProviderDetailsView() {

  const history = useHistory();

  const { id: providerId } = useParams<{ id: string }>();

  const editFederationProvider = React.useCallback(() =>
      history.push(getEditFederationProviderPath(providerId), { goBackOnSuccess: true }),
    [history, providerId]);

  return (
    <FederationProviderDetails
      key={providerId}
      editFederationProvider={editFederationProvider}
    />
  );
}

export default FederationProviderDetailsView;
