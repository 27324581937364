import { Record } from "immutable";
import { Policy, PolicyAttributes } from "@data/Policy";
import { equalsIgnoreCase, getStringValue } from "@util";

const ACCOUNT_ID_REGEX = new RegExp(/lrn.*[:]([0-9]+)/);

export interface ReferenceAttributes {
  policyRef: string;
}

export interface ManagedPolicyAttributes {
  reference: ReferenceAttributes;
  policy: PolicyAttributes;
  description?: string;
}

export class ManagedPolicy extends Record({
  reference: {
    policyRef: "",
  },
  policy: Policy.EMPTY.toJS(),
  description: "",
}) implements ManagedPolicyAttributes {

  public static EMPTY: ManagedPolicy = new ManagedPolicy();

  public readonly policy: PolicyAttributes;
  public readonly reference: ReferenceAttributes;
  public readonly description: string;

  public equals(other: ManagedPolicy): boolean {
    return equalsIgnoreCase(JSON.stringify(this.toJS()), JSON.stringify(other.toJS()));
  }

  public getReference(): ReferenceAttributes {
    return this.reference;
  }

  public getPolicyRef(): string {
    return getStringValue(this.getReference().policyRef);
  }

  public getAccountId(): string {
    const policyRef = this.getPolicyRef();

    const match = policyRef.match(ACCOUNT_ID_REGEX);

    if (!Array.isArray(match) || match.length === 0) {
      return "";
    } else {
      return getStringValue(match.pop()); // we want the last match
    }
  }

  public getPolicy(): Policy {
    return new Policy(this.policy);
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }
}

export default ManagedPolicy;
