import { Theme } from "@material-ui/core/styles/createTheme";
import { createStyles, StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const nameTextField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "14px",
      fontWeight: 300,
      padding: "2px 0",
      margin: 0,
    },
    "& input": {
      fontSize: 14,
      fontWeight: 400,
      padding: "18.5px 14px",
      margin: 0,
    },
  },
};

export const descriptionTextField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& textarea": {
      fontSize: 14,
      fontWeight: 400,
      padding: 0,
      margin: 0,
    },
  },
};

export const namespaceView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    contentWrapper: {
      flex: "1 0 auto",
    },
  });

export const nameView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    errorViewContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "21px",
      marginTop: 16,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    schemaName: {
      marginTop: 25,
    },
    schemaDescription: {
      marginTop: 25,
    },
    tagEditor: {
      marginTop: 25,
    },
  });

export const schemaEditorView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    header: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      position: "relative",
    },
    icon: {
      width: 40,
      height: 40,
    },
    error: {
      marginTop: 25,
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    controls: {
      marginTop: 16,
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    summaryEditorButton: {
      marginLeft: 0,
    },
    jsonEditorButton: {
      marginLeft: 15,
    },
  });

export const reviewView = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    propertyContainer: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      justifyContent: "stretch",
      marginTop: 25,
      textAlign: "left",
    },
    propertyName: {
      flex: "0 0 100px",
      alignSelf: "flex-start",
      justifySelf: "flex-start",
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      fontSize: 16,
    },
    propertyValue: {
      flex: "1 1 auto",
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "monospace",
      fontSize: 14,
      marginLeft: 15,
      fontWeight: 500,
    },
    schemaContainer: {
      flex: "1 0 350px",
      display: "flex",
      alignItems: "stretch",
    },
    jsonEditor: {
      marginTop: 15,
      flex: "1 0 300px",
    },
    backdrop: {
      background: "rgba(105, 105, 105, 0.25)",
      zIndex: theme.zIndex.modal,
    },
  });

export const summaryEditorView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 25,
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontSize: 20,
      fontWeight: 400,
      lineHeight: "24px",
    },
  });

export const jsonEditorView = () =>
  createStyles({
    jsonEditor: {
      flex: "1 0 350px",
    },
  });
