import { createStyles } from "@material-ui/core/styles";
import { UsersIcon } from "@components/icons";
import Color from "@components/styles/Color";

export const PrimaryIcon = UsersIcon;

export const primaryColor = Color.MODULES;

export const selectApplication = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      margin: "25px 0 0",
      fontWeight: 300,
    },
    list: {
      marginTop: 25,
    },
  });

export const selectEmailTemplate = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
    },
    title: {
      margin: "25px 0 0",
      fontWeight: 300,
    },
    htmlPreview: {
      flex: "1 0 500px",
      marginTop: 20,
    },
  });

export const reviewView = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      "& label.title": {
        marginBottom: 15,
      },
    },
  });

export const userInfo = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    userId: {
      marginTop: 15,
    },
    passwordContainer: {
      marginTop: 15,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    password: {
    },
    passwordControls: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    showHidePasswordButton: {
      width: "auto !important",
      height: "auto !important",
      padding: 12,
    },
    randomPasswordButton: {
      width: "auto !important",
      height: "auto !important",
      padding: 12,
    },
    mfaEnabled: {
      marginTop: 15,
    },
    mfaSecret: {
      marginTop: 15,
    },
    checkboxContainer: {
      margin: "15px 0 0",
    },
    checkbox: {
      color: primaryColor,
      padding: 0,
    },
    iconButton: {
      position: "absolute",
      right: 8,
      bottom: 18,
      width: 20,
      height: 20,
      padding: 0,
      margin: 0,
    },
    tooltipIconPopper: {
      marginTop: 12,
      justifyContent: "flex-end",
    },
    textField: {
      color: Color.TEXT,
      fontSize: 16,
      height: "auto",
    },
    formHelperText: {
      color: Color.TEXT,
      fontSize: 13,
    },
    formHelperTextError: {
      marginBottom: 15,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    modal: {
      background: "rgba(105, 105, 105, 0.25)",
      position: "fixed",
      zIndex: 9999,
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    loadingMessage: {
      padding: 25,
      marginTop: 25,
      marginBottom: 25,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      "& h1": {
        fontWeight: 500,
      },
      "& h4": {
        marginTop: 15,
        fontWeight: 500,
        color: Color.SIGNIFY_GREEN,
      },
      "& h6": {
      },
    },
    loadingMessageLabel: {
      margin: 15,
    },
  });
