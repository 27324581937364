import React from "react";
import styles from "./styles";
import Module from "@data/Module";
import classnames from "classnames";
import List from "@material-ui/core/List";
import ModuleListItem from "./ModuleListItem";
import DividerListItem from "./DividerListItem";
import Collapse from "@material-ui/core/Collapse";
import { clickHandler, isEmptyString } from "@util";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import NavigationListItem, { ListItemType } from "./NavigationListItem";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import ReleaseNotesIconButton from "@components/release-notes-icon-button";
import CostReportsIconButton from "@components/cost-reports-icon-button";
import QualityReportsIconButton from "@components/quality-reports-icon-button";
import { QualityReportPlatformDomainId, ReleaseNotePlatformDomain } from "@data";

export interface HeaderListItemProps {
  name?: string;
  modules?: (Module | { [key: string]: Module[] })[];
  collapsible?: boolean;
  nested?: boolean;
  isFavorites?: boolean;
  releaseNotesPlatformDomain?: ReleaseNotePlatformDomain;
  releaseNotesClassName?: string;
  releaseNotesTooltip?: string;
  costReportsTooltip?: string;
  costReportsClassName?: string;
  showCostReportsIconButton?: boolean;
  showQualityReportsIconButton?: boolean;
  qualityReportsPlatformDomain?: QualityReportPlatformDomainId;
  qualityReportsClassName?: string;
  qualityReportsTooltip?: string;
}

type Props = WithStyles<typeof styles> & HeaderListItemProps;

export const HeaderListItem = withStyles(styles)((props: Props) => {

  const {
    classes,
    name = "",
    modules = [],
    collapsible = true,
    nested,
    isFavorites = false,
    releaseNotesPlatformDomain,
    releaseNotesClassName,
    releaseNotesTooltip,
    costReportsTooltip,
    costReportsClassName,
    showCostReportsIconButton,
    showQualityReportsIconButton,
    qualityReportsPlatformDomain,
    qualityReportsClassName,
    qualityReportsTooltip,
  } = props;

  const [collapsed, setCollapsed] = React.useState(isFavorites && nested);

  const hasName = React.useMemo(() => !isEmptyString(name), [name]);

  const isEmpty = React.useMemo(() =>
    !Array.isArray(modules) || modules.filter(({ disabled = false }) => !disabled).length === 0,
    [modules]);

  const isValid = React.useMemo(() => hasName &&  !isEmpty, [hasName, isEmpty]);

  const showHeader = React.useMemo(() => !(isFavorites && nested && !collapsed)
    , [isFavorites, nested, collapsed]);

  const header = React.useMemo(() => !isValid ? null : (
    <NavigationListItem
      name={(
        <React.Fragment>
          {name}
          <ReleaseNotesIconButton
            className={classnames(releaseNotesClassName, classes.releaseNotesIconButton)}
            platformDomain={releaseNotesPlatformDomain}
            tooltip={releaseNotesTooltip}
          />
          {showQualityReportsIconButton && (
            <QualityReportsIconButton
              className={classnames(qualityReportsClassName, classes.qualityReportsIconButton)}
              platformDomain={qualityReportsPlatformDomain}
              tooltip={qualityReportsTooltip}
            />
          )}
          {showCostReportsIconButton && (
            <CostReportsIconButton
              tooltip={costReportsTooltip}
              className={classnames(costReportsClassName, classes.costReportsIconButton)}
            />
          )}
        </React.Fragment>
      )}
      type={nested ? ListItemType.SUB_HEADER : ListItemType.HEADER}
      onClick={clickHandler(() => setCollapsed(!collapsed))}
      clickable={collapsible}
    >
      {collapsible && collapsed && (
        <ExpandMore
          className={classnames("expandMoreIcon", classes.expandMoreIcon, classes.headerIcon, {
              [classes.subHeaderIcon]: nested,
            },
          )}
        />
      )}
      {collapsible && !collapsed && (
        <ExpandLess
          className={classnames("expandLessIcon", classes.expandLessIcon, classes.headerIcon, {
              [classes.subHeaderIcon]: nested,
            },
          )}
        />
      )}
    </NavigationListItem>
  ), [
    classes,
    isValid,
    name,
    nested,
    collapsible,
    setCollapsed,
    collapsed,
    releaseNotesTooltip,
    releaseNotesClassName,
    releaseNotesPlatformDomain,
    qualityReportsTooltip,
    qualityReportsClassName,
    qualityReportsPlatformDomain,
    showQualityReportsIconButton,
    costReportsTooltip,
    costReportsClassName,
    showCostReportsIconButton,
  ]);

  const listItems = React.useMemo(() => {
    if (!isValid) {
      return null;
    } else {
      return (
        <React.Fragment>
          {modules.map(module => {

            const nestedListItems = Object.keys(module).every(key => Array.isArray(module[key]));

            if (nestedListItems) {
              return Object.keys(module).map(key => (
                <div
                  key={`nested-header-list-item-${key}`}
                  className={classnames("nestedHeaderListItem", classes.nestedHeaderListItem)}
                >
                  <HeaderListItem
                    nested={true}
                    name={key}
                    modules={module[key]}
                    isFavorites={isFavorites}
                  />
                </div>
              ));
            }

            return (
              <List key={(module as Module).id} component="div" disablePadding={true}>
                <ModuleListItem
                  {...(module as Module)}
                  className={classnames(classes.moduleListItem, {
                    [classes.nestedModuleListItem]: nested,
                  })}
                />
              </List>
            );
          })}
        </React.Fragment>
      );
    }
  }, [classes, isValid, modules, nested]);

  const list = React.useMemo(() => (
    <Collapse in={!collapsed} timeout={"auto"} unmountOnExit={true}>
      {listItems}
    </Collapse>
  ), [collapsed, listItems]);

  return React.useMemo(() => (
    <React.Fragment>
      {showHeader && header}
      {list}
      {!nested && <DividerListItem />}
    </React.Fragment>
  ), [header, list, nested]);
});

export default HeaderListItem;
