import {
  MODULE_PATH as BASE_URL,
  LEGACY_MODULE_PATH as LEGACY_BASE_URL,
} from "@modules/dataSetRequests/constants";
import { DataClassification, IngestionMechanism } from "@data/DataSetRequest";
import { getPathToDataSetRequest, getLegacyPathToDataSetRequest } from "@modules/dataSetRequestDetails/helpers";

export const getCreateDataSetRequestPath = () => `${BASE_URL}/create`;

export const getLegacyCreateDataSetRequestPath = () => `${LEGACY_BASE_URL}/create`;

export const getCloneDataSetRequestPath = (dataSetRequestId: string) =>
  `${getPathToDataSetRequest(dataSetRequestId)}/clone`;

export const getEditDataSetRequestPath = (dataSetRequestId: string) =>
  `${getPathToDataSetRequest(dataSetRequestId)}/edit`;

export const getLegacyEditDataSetRequestPath = (dataSetRequestId: string) =>
  `${getLegacyPathToDataSetRequest(dataSetRequestId)}/edit`;

export const toLRNs = (userIds: string[] = [], accountId: string) => {
  return userIds.map(userId => "lrn:iot:aam::" + accountId + ":principal:user:" + userId);
};

export const toUserIds = (lrns: string[] = []) => {
  return lrns.map(lrn => lrn.split(":").pop());
};

export const getDataClassificationDisplayName = (dataClassification: DataClassification | undefined): string => {
  switch (dataClassification) {
    case DataClassification.INTERNAL:
      return "Company Internal";
    case DataClassification.CONFIDENTIAL:
      return "Company Confidential";
    case DataClassification.SECRET:
      return "Company Secret";
    default:
      return "";
  }
};

export const getIngestionMechanismDisplayName = (value: IngestionMechanism): string => {
  switch (value) {
    case IngestionMechanism.MICRO_BATCH:
      return "Micro Batch";
    case IngestionMechanism.MACRO_BATCH:
      return "Macro Batch";
    case IngestionMechanism.MQTT:
      return "MQTT";
    default:
      return "";
  }
};
