import React from "react";
import { AccessRequestType, DataLakeDataSet } from "@data";
import { useListAccessibleDataSets } from "@hooks";
import DataLakeBrowser, { DataLakeBrowserActions, DataLakeBrowserModel } from "@components/data-lake-browser";

interface ContainerModel extends DataLakeBrowserModel {
}

interface ContainerActions extends DataLakeBrowserActions {
}

type Props = ContainerModel & ContainerActions;

const DataLakeBrowserContainer = (props: Props) => {

  const [
    { accessibleDataSets, ...model },
    { listAccessibleDataSets, ...actions }
  ] = useListAccessibleDataSets({
    accessRequestType: AccessRequestType.READ // We only care about data sets with read access
  });

  const dataSets: DataLakeDataSet[] =
    accessibleDataSets.filter((d) => d.isDataLakeAcquiredDataSetType());

  const [selectedDataSet, setSelectedDataSet] = React.useState(DataLakeDataSet.EMPTY);

  const accountId = React.useMemo(() => selectedDataSet.getAccountId(), [selectedDataSet]);

  const refresh = React.useCallback(() => {
    listAccessibleDataSets();
    setSelectedDataSet(DataLakeDataSet.EMPTY);
  }, [listAccessibleDataSets, setSelectedDataSet]);

  return (
    <DataLakeBrowser
      {...props}
      {...model}
      {...actions}
      refresh={refresh}
      dataSets={dataSets}
      accountId={accountId}
      selectedDataSet={selectedDataSet}
      setSelectedDataSet={setSelectedDataSet}
    />
  );
};

export default DataLakeBrowserContainer;
