import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { summaryViewLabel as styles } from "./styles";

export const SummaryViewLabel = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string;
  nameClassName?: string;
  valueClassName?: string;
  name?: string;
  value?: string;
}) => {

  const {
    classes,
    className,
    nameClassName,
    valueClassName,
    name,
    value,
  } = props;

  if (!name || !value) {
    return null;
  }

  return (
    <div className={classnames("summaryViewLabel", className, classes.container)}>
      <label className={classnames("name", nameClassName, classes.name)}>
        {`${name}:`}
      </label>
      <label className={classnames("value", valueClassName, classes.value)}>
        {value}
      </label>
    </div>
  );
});

export default SummaryViewLabel;
