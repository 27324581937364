import { Dashboard } from "@data";
import { Comparator, compare, isEmptyString } from "@util";
import { DashboardColumn } from "./reducers";

export const getSearchResults = (items: Dashboard[], searchQuery: string) => {

  const dataSets = items.slice();

  if (dataSets.length === 0 || isEmptyString(searchQuery)) {
    return dataSets;
  }

  const searchFilter = searchQuery.toLowerCase();

  return dataSets.filter((dashboard: Dashboard) =>
    dashboard.name.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0);
};

const compareByName = (left: Dashboard, right: Dashboard, ascending: boolean) =>
  compare(left.getDashboardName(), right.getDashboardName(), ascending);

export const createComparator =
  (sortedColumn: DashboardColumn, ascending: boolean): Comparator<Dashboard> =>
    (left: Dashboard, right: Dashboard) => {
      switch (sortedColumn) {
        case DashboardColumn.NAME:
          return compareByName(left, right, ascending);
        default:
          return 0;
      }
    };
