import React from "react";
import classnames from "classnames";
import MuiAlertTitle, { AlertTitleProps } from "@material-ui/lab/AlertTitle";

export interface CustomAlertTitleProps extends AlertTitleProps {}

export const AlertTitle = ((props: CustomAlertTitleProps) => {

    const {
      className,
      ...otherProps
    } = props;

    return (
      <MuiAlertTitle
        {...otherProps}
        className={classnames("alertTitle", className)}
      />
    );
});

export default AlertTitle;
