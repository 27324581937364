import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base";

export interface EmailTemplatesSchema extends BasePortalModuleSchema {
  nameFilter: string;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<EmailTemplatesSchema>({
  nameFilter: "",
});

export type EmailTemplatesActionTypes = PortalModuleActionTypes<EmailTemplatesSchema>;

export const ACTION_TYPES: EmailTemplatesActionTypes =
    createPortalModuleActionTypes<EmailTemplatesSchema>(MODULE_ID, DEFAULT_STATE);

export const EmailTemplatesReducer = combineReducers<EmailTemplatesSchema>(
  createPortalModuleReducers<EmailTemplatesSchema>(ACTION_TYPES, DEFAULT_STATE));

export default EmailTemplatesReducer;
