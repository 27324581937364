import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Button, CancelButton, ErrorView, SaveButton } from "@components";
import { isEmptyString } from "@util";
import styles from "./styles";

export interface Model {
  className?: string;
  title?: string;
  errorTitle?: string;
  errorMessage?: string;
  showSelectGroupsView?: boolean;
  showReviewView?: boolean;
  showLoadingIndicator?: boolean;
  showSuccessIndicator?: boolean;
  cancelButtonLabel?: string;
  saveButtonLabel?: string;
  saveButtonDisabled?: boolean;
  previousStepButtonDisabled?: boolean;
}

export interface Actions {
  cancel?: () => void;
  nextStep?: () => void;
  previousStep?: () => void;
  save?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const GroupManagementWizard = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Group Identity Management",
    errorTitle,
    errorMessage,
    showLoadingIndicator,
    showSuccessIndicator,
    showSelectGroupsView,
    showReviewView,
    cancelButtonLabel = "Cancel",
    saveButtonLabel = "Save",
    saveButtonDisabled,
    previousStepButtonDisabled,
    cancel,
    save,
    nextStep,
    previousStep,
    children,
  } = props;

  const isLoadingOrFinished = React.useMemo(() =>
    showLoadingIndicator || showSuccessIndicator,
    [showLoadingIndicator, showSuccessIndicator]);

  return (
    <div className={classnames("groupManagementWizard", className, classes.container)}>
      <label className={classnames("title", classes.title)}>
        {title}
      </label>
      <ErrorView
        className={classnames("error", classes.error)}
        title={errorTitle || `${saveButtonLabel} Failed`}
        message={errorMessage}
      />
      {showReviewView && (
        <div className={classnames("controls", classes.controls)}>
          {!isLoadingOrFinished && (
            <CancelButton
              className={classnames("cancelButton", classes.button, classes.cancelButton)}
              label={cancelButtonLabel}
              onClick={cancel}
            />
          )}
          {showSelectGroupsView && (
            <Button
              className={classnames("nextStepButton", classes.button, classes.nextStepButton)}
              disabled={saveButtonDisabled}
              variant="contained"
              color="primary"
              onClick={nextStep}
            >
              Next Step
            </Button>
          )}
          {previousStepButtonDisabled && (
            <Button
              className={classnames("previousStepButton", classes.button, classes.previousStepButton)}
              disabled={false}
              variant="contained"
              color="secondary"
              onClick={previousStep}
            >
              Previous Step
            </Button>
          )}
          {!showSelectGroupsView && (
            <SaveButton
              className={classnames("saveButton", classes.saveButton)}
              label={isEmptyString(errorMessage) ? saveButtonLabel : "Retry"}
              disabled={saveButtonDisabled}
              loading={showLoadingIndicator}
              success={showSuccessIndicator}
              save={save}
            />
          )}
        </div>
      )}
      {children}
      {!showReviewView && (
        <div className={classnames("controls", classes.controls)}>
          {!isLoadingOrFinished && (
            <CancelButton
              className={classnames("cancelButton", classes.button, classes.cancelButton)}
              label={cancelButtonLabel}
              onClick={cancel}
            />
          )}
          {showSelectGroupsView && (
            <Button
              className={classnames("nextStepButton", classes.button, classes.nextStepButton)}
              disabled={saveButtonDisabled}
              variant="contained"
              color="primary"
              onClick={nextStep}
            >
              Next Step
            </Button>
          )}
          {!showSelectGroupsView && (
            <SaveButton
              className={classnames("saveButton", classes.saveButton)}
              label={saveButtonLabel}
              disabled={saveButtonDisabled}
              loading={showLoadingIndicator}
              success={showSuccessIndicator}
              save={save}
            />
          )}
        </div>
      )}
    </div>
  );
});

export default GroupManagementWizard;
