import React from "react";
import DataLakeDataSet from "@data/DataLakeDataSet";
import { ListAccessibleDataSetsResponse } from "@network";
import {
  useDataAccessRequestClient,
  usePaginatedApiRequest,
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
} from "@hooks";
import { AccessRequestType } from "@data";

type SuccessResponse = ListAccessibleDataSetsResponse;

export interface UseListAccessibleDataSetsProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
  accessRequestType?: AccessRequestType;
}

export interface UseListAccessibleDataSetsModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  accessibleDataSets: DataLakeDataSet[];
}

export interface UseListAccessibleDataSetsActions extends UsePaginatedApiRequestActions<SuccessResponse> {
  listAccessibleDataSets: () => void;
}

type Props = UseListAccessibleDataSetsProps;
type Model = UseListAccessibleDataSetsModel;
type Actions = UseListAccessibleDataSetsActions;
type Result = [Model, Actions];

export const useListAccessibleDataSets = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to list data sets that you have access to",
    accessRequestType,
    ...otherProps
  } = props;

  const DataAccessRequestClient = useDataAccessRequestClient();

  const [accessibleDataSets, setAccessibleDataSets] = React.useState<DataLakeDataSet[]>([]);

  const makeApiRequest = React.useCallback((accessToken, next = "") =>
      DataAccessRequestClient.listAccessibleDataSets(accessRequestType, next),
    [DataAccessRequestClient, accessRequestType]);

  const [{ successResponse, ...baseModel }, {
    reset: baseReset,
    refresh: baseRefresh,
    ...baseActions
  }] =
    usePaginatedApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const updatedDataSets = React.useMemo(() => {
    const { accessibleDataSets: dataSets = [] } = successResponse || {};
    return dataSets.map(attrs => new DataLakeDataSet(attrs));
  }, [successResponse]);

  const reset = React.useCallback(() => {
    setAccessibleDataSets([]);
    baseReset();
  }, [setAccessibleDataSets, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    accessibleDataSets,
  }), [
    baseModel,
    accessibleDataSets,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    refresh,
    reset,
    listAccessibleDataSets: refresh,
  }), [
    baseActions,
    refresh,
    reset,
  ]);

  React.useEffect(() => {
    setAccessibleDataSets(curr => curr.concat(updatedDataSets));
  }, [updatedDataSets, setAccessibleDataSets]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useListAccessibleDataSets;
