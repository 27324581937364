import React from "react";
import { useAuditEvent } from "@hooks";
import { PrimaryIcon } from "../styles";
import { SummaryViewData, AuditEvent } from "@data";
import {
  SummaryJsonDetailsView,
  SummaryJsonDetailsViewActions,
  SummaryJsonDetailsViewModel
} from "@components";

interface DetailsModel extends SummaryJsonDetailsViewModel {
  auditEventId?: string;
  children?: React.ReactNode;
}

interface DetailsActions extends SummaryJsonDetailsViewActions {
}

type Props = DetailsModel & DetailsActions;

const AuditEventDetailsContainer = (props: Props) => {

  const { auditEventId = "" } = props;

  const [{ event = AuditEvent.EMPTY, ...otherModel }, { ...otherActions }]
    = useAuditEvent({ auditEventId });

  const summaryViewItems = React.useMemo(() => [
    new SummaryViewData({
      className: "id",
      name: "Event ID",
      value: event.getId(),
    }),
    new SummaryViewData({
      className: "timestamp",
      name: "Timestamp",
      value: event.getTimestamp(),
    }),
    new SummaryViewData({
      className: "type",
      name: "Type",
      value: event.getType(),
    }),
    new SummaryViewData({
      className: "severity",
      name: "Severity",
      value: event.getSeverity(),
    }),
    new SummaryViewData({
      className: "accountId",
      name: "Account ID",
      value: event.getAccountId(),
    }),
    new SummaryViewData({
      className: "principal",
      name: "Principal",
      value: event.getPrincipal(),
    }),
    new SummaryViewData({
      className: "requestId",
      name: "Request ID",
      value: event.getRequestId(),
    }),
    new SummaryViewData({
      className: "ip",
      name: "IP Address",
      value: event.getIP(),
    }),
    new SummaryViewData({
      className: "userAgent",
      name: "User Agent",
      value: event.getUserAgent(),
    }),
    new SummaryViewData({
      className: "clientId",
      name: "Client ID",
      value: event.getClientId(),
    }),
    new SummaryViewData({
      className: "location",
      name: "Location",
      value: event.getLocation(),
    }),
    new SummaryViewData({
      className: "metadata",
      name: "Metadata",
      value: JSON.stringify(event.getMetadata(), null, "  "),
    }),
  ], [event]);

  const json = React.useMemo(() => JSON.stringify(event.toJS(), null, "  "), [event]);

  return (
    <SummaryJsonDetailsView
      {...props}
      {...otherModel}
      {...otherActions}
      title={`Audit Event: ${auditEventId}`}
      fileName={`${auditEventId}.json`}
      className={"auditEventDetails"}
      icon={PrimaryIcon}
      json={json}
      summaryViewItems={summaryViewItems}
      summaryViewClassName={"auditEventDetailsSummary"}
      jsonViewClassName={"auditEventDetailsJson"}
    />
  );
};

export default AuditEventDetailsContainer;
