import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { configSummary as styles } from  "./styles";
import { ErrorView } from "@components";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@components/circular-progress";

export interface Model {
  errorMessage?: string;
  loadingIndicator?: boolean;
  backdrop?: boolean;
  children?: React.ReactNode;
}

export interface Actions {

}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DeviceConfigurationSummaryView = withStyles(styles)((props: Props) => {

  const {
    classes,
    errorMessage,
    loadingIndicator = false,
    backdrop= false,
    children,
  } = props;

  return (
    <div className={classnames("deviceConfigSummaryView", classes.container)}>
      <ErrorView className={classes.errorMessageContainer} message={errorMessage} />
      <Backdrop className={classnames("backdrop", classes.backdrop)} open={backdrop} />
      {backdrop && (
        <CircularProgress size={68} className={classes.progressIndicator}/>
      )}
      {!loadingIndicator && children}
    </div>
  );
});
