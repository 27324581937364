import React from "react";
import { oneMonthAgo } from "@util";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { getUserId } from "../selectors";
import { useUserAuditEvents } from "@hooks";
import { AuditEventFilter } from "@components/audit-events/AuditEventFilter";
import {
  fetchUserAuditEventsFailed,
  fetchUserAuditEventsRequest,
  fetchUserAuditEventsSuccess,
} from "../actions";
import AuditEvents from "@components/audit-events";

interface Model {
  hidden?: boolean;
  userId?: string;
}

interface Actions {
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
}

type Props = Model & Actions;

const EventsView = (props: Props) => {

  const { hidden, userId, ...otherProps } = props;

  const [model, actions] = useUserAuditEvents(userId, {
    ...otherProps,
    startDate: oneMonthAgo(),
  });

  if (hidden) {
    return null;
  }

  return (
    <AuditEvents
      {...model}
      {...actions}
      className={"userAuditEvents"}
      lockedFilters={[AuditEventFilter.PRINCIPAL]}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  userId: getUserId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  trackRequestEvent: () => dispatch(fetchUserAuditEventsRequest()),
  trackSuccessEvent: () => dispatch(fetchUserAuditEventsSuccess()),
  trackErrorEvent: analytic => dispatch(fetchUserAuditEventsFailed(analytic)),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(EventsView);
