import React from "react";
import { useHistory } from "react-router-dom";
import { getPathToShadowLayout } from "@modules/digitalShadowLayoutDetails/helpers";
import DigitalShadowLayoutWizard from "./containers/DigitalShadowLayoutWizard";

export function CreateDigitalShadowLayoutView() {

  const history = useHistory();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const showLayoutDetails = React.useCallback((name: string) => {
    history.replace(getPathToShadowLayout(name));
  }, [history]);

  return (
    <DigitalShadowLayoutWizard
      cancel={cancel}
      showLayoutDetails={showLayoutDetails}
    />
  );
}

export default CreateDigitalShadowLayoutView;
