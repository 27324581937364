import { OrderedMap } from "immutable";
import { isEmptyString, isValidJson } from "@util";
import { createSelector } from "reselect";
import { MODULE_ID, SCHEMA_KEY } from "../constants";
import { createSelectors, Selector } from "@base/createSelectors";
import { DeviceEnrollmentWizardState, DeviceEnrollmentWizardStateAttributes } from "../models";
import {
  ADD_DEVICE_DIALOG_STEPS,
  AddDeviceWizardStep,
  DEVICE_ENROLLMENT_WIZARD_FILE_UPLOAD_STEPS,
  DEVICE_ENROLLMENT_WIZARD_VISUAL_EDITOR_STEPS,
  DeviceEnrollmentWizardStep,
  VALIDATE_DEVICE_WIZARD_STEPS,
} from "../steps";
import {
  DEFAULT_STATE,
  DeviceDataMode,
  DeviceEnrollmentWizardSchema,
  EnrollmentType,
  SchemaItems,
} from "../reducers";
import {
  DataAttributes,
  DeviceDataModel,
  DeviceDataModelAttributes,
  DeviceTypeModelV3,
  DeviceTypeModelV3Attributes,
  JsonSchemaDefinition,
  JsonSchemaMetadata,
  JsonSchemaMetadataAttributes,
  TableHeaderRowData,
} from "@data";

export const {
  batchId: getBatchId,
  deviceRef: getDeviceRef,
  device: getDeviceAttributes,
  devices: getDevicesAttributes,
  deviceEnrollmentWizardStep: getDeviceEnrollmentWizardStep,
  errorTitle: getErrorTitle,
  showValidateMode: isValidateModeActive,
  availableSchemas: getAvailableSchemaAttributes,
  deviceType: getDeviceTypeAttributes,
  enrollmentType: getEnrollmentType,
  preSignedUrl: getPreSignedUrl,
  base64Payload: getBase64payload,
  deviceDataMode: getDeviceDataMode,
  file: getFile,
  addDeviceDialog: isAddDeviceDialogVisible,
  removeDeviceDialog: isRemoveDeviceDialogVisible,
  addDeviceWizardStep: getAddDeviceWizardStep,
  deviceDataDialog: isDeviceDataDialogVisible,
  schema: getSchema,
  schemaIdentity: getSchemaIdentity,
  validatedPayload: isValidatedPayload,
  reEnrollmentPayload: isReEnrollmentPayload,
  reEnrollmentBatchId: getReEnrollmentBatchId,
  deviceData: getDeviceData,
  deviceDataValidated: isDeviceDataValidated,
  deviceTypeSelected: isDeviceTypeSelected,
  deviceEditMode: isDeviceEditMode,
  defaultState: getDefaultStateAttributes,
  getErrorMessage,
  getSuccessMessage,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  getCurrentAccountId,
  getAccessToken,
} = createSelectors<DeviceEnrollmentWizardSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getDevices: Selector<DeviceDataModel[]> = createSelector(
  getDevicesAttributes, (deviceAttributes: DeviceDataModelAttributes[] = []) => {
    if (Array.isArray(deviceAttributes)) {
      return deviceAttributes.map((attrs: DeviceDataModelAttributes) => new DeviceDataModel(attrs));
    } else {
      return [];
    }
  });

export const getDevice: Selector<DeviceDataModel> = createSelector(
  getDeviceAttributes, (attrs: DeviceDataModelAttributes) => {
    return new DeviceDataModel(attrs);
  });

export const getDevicesAsJson: Selector<string> = createSelector(
  getDevices, (devices: DeviceDataModel[] = []) => {
    return (JSON.stringify(devices, null, "  "));
  });

export const isVisualEditorSelected: Selector<boolean> = createSelector(
  getEnrollmentType, (enrollmentType: EnrollmentType) =>
    EnrollmentType.VISUAL_EDITOR === enrollmentType
);

export const isFileUploadSelected: Selector<boolean> = createSelector(
  getEnrollmentType, (enrollmentType: EnrollmentType) =>
    EnrollmentType.FILE === enrollmentType
);

// Device Enrollment Wizard Step
export const isEnrollmentTypeViewSelected: Selector<boolean> = createSelector(
  getDeviceEnrollmentWizardStep, (step: DeviceEnrollmentWizardStep) => {
    return DeviceEnrollmentWizardStep.ENROLLMENT_TYPE === step;
  });

export const isBatchIdViewSelected: Selector<boolean> = createSelector(
  getDeviceEnrollmentWizardStep, (step: DeviceEnrollmentWizardStep) => {
    return DeviceEnrollmentWizardStep.BATCH_ID === step;
  });

export const isFileUploadViewSelected: Selector<boolean> = createSelector(
  getDeviceEnrollmentWizardStep, (step: DeviceEnrollmentWizardStep) => {
    return DeviceEnrollmentWizardStep.FILE_UPLOAD === step;
  });

export const isDevicesViewSelected: Selector<boolean> = createSelector(
  getDeviceEnrollmentWizardStep, (step: DeviceEnrollmentWizardStep) => {
    return DeviceEnrollmentWizardStep.DEVICES === step;
  });

export const isDeviceEnrollmentEditorViewSelected: Selector<boolean> = createSelector(
  getDeviceEnrollmentWizardStep, (step: DeviceEnrollmentWizardStep) => {
    return DeviceEnrollmentWizardStep.EDITOR === step;
  });

export const isReviewViewSelected: Selector<boolean> = createSelector(
  getDeviceEnrollmentWizardStep, (step: DeviceEnrollmentWizardStep) => {
    return DeviceEnrollmentWizardStep.REVIEW === step;
  });

export const isBatchIdValid: Selector<boolean> = createSelector(
  getBatchId, (batchId: string) =>
    !isEmptyString(batchId));

export const isDevicesJsonValid: Selector<boolean> = createSelector(
  getDevicesAsJson, (json: string) => isValidJson(json));

export const isDeviceValid: Selector<boolean> = createSelector(
  getDevices, (devices: DeviceDataModel[] = []) => {
    return devices.length > 0;
  });

export const isFileUploaded: Selector<boolean> = createSelector(
  getFile, (file: File) => file !== null && file.size > 0 );

export const hasDevices: Selector<boolean> = createSelector(
  getDevices, (devices: DeviceDataModel[]) => devices.length > 0);

export const getNumberOfDevices: Selector<number> = createSelector(
  getDevices, (devices: DeviceDataModel[]) => devices.length);

export const isBatchEnrollment: Selector<boolean> = createSelector(
  getDevices, (devices: DeviceDataModel[] = []) => {
    return devices.length > 1 ;
  });

export const getDeviceEnrollmentAsJson: Selector<string> = createSelector(
  getDevice, (deviceEnrollment: DeviceDataModel = DeviceDataModel.EMPTY) => {
    return (JSON.stringify(deviceEnrollment.toJS(), null, "  "));
  });

export const getDeviceType: Selector<DeviceTypeModelV3> = createSelector(
  getDeviceTypeAttributes, (attrs: DeviceTypeModelV3Attributes) => new DeviceTypeModelV3(attrs));

export const getDeviceTypeAsJson: Selector<string> = createSelector(
  getDeviceType, (deviceType: DeviceTypeModelV3 = DeviceTypeModelV3.EMPTY) => {
    return (JSON.stringify(deviceType.toJS(), null, "  "));
  });

export const getSchemasMap: Selector<OrderedMap<string, JsonSchemaMetadata>> = createSelector(
  getAvailableSchemaAttributes, (schemas: SchemaItems) => {

    return OrderedMap<string, JsonSchemaMetadataAttributes>(schemas)
      .map((attrs: JsonSchemaMetadataAttributes) => new JsonSchemaMetadata(attrs))
      .toOrderedMap();
  });

export const getAvailableSchema: Selector<JsonSchemaMetadata[]> = createSelector(
  getSchemasMap, (schemas: OrderedMap<string, JsonSchemaMetadata>) => schemas.toArray());

export const isActualDeviceDataModeActive: Selector<boolean> = createSelector(
  getDeviceDataMode, (dataMode: DeviceDataMode) =>
    DeviceDataMode.ACTUAL === dataMode
);

export const isDesiredDeviceDataModeActive: Selector<boolean> = createSelector(
  getDeviceDataMode, (dataMode: DeviceDataMode) =>
    DeviceDataMode.DESIRED === dataMode
);

export const getDeviceDataDialogTitle: Selector<string> = createSelector(
  getDeviceDataMode, (dataMode: DeviceDataMode) =>
    dataMode === DeviceDataMode.ACTUAL ? "Actual Device Data" : "Desired Device Data");

export const getDeviceDataDialogContinueButtonLabel: Selector<string> = createSelector(
  getDeviceDataMode, (dataMode: DeviceDataMode) =>
    dataMode === DeviceDataMode.ACTUAL ? "Add Actual Data" : "Add Desired Data");

export const getDataViewTitle: Selector<string> = createSelector(
  getDeviceDataMode, (dataMode: DeviceDataMode) =>
    dataMode === DeviceDataMode.ACTUAL ? "Actual Data" : "Desired Data");

export const getSchemaJson: Selector<string> = createSelector(
  getSchema, (schema: JsonSchemaDefinition) =>
    JSON.stringify(schema, null, "  "));

export const getSelectedSchemaIds: Selector<string[]> = createSelector(
  [getDeviceDataMode, getDevice], (dataMode: DeviceDataMode, device: DeviceDataModel) =>
    dataMode === DeviceDataMode.ACTUAL ? device.getActualSchemas() : device.getDesiredSchemas()
);

export const getPopulateAllSchemaValue: Selector<boolean> = createSelector(
  [getDeviceDataMode, getDevice], (dataMode: DeviceDataMode, device: DeviceDataModel) =>
    dataMode === DeviceDataMode.ACTUAL ? device.getActualPopulateAllSchema() : device.getDesiredPopulateAllSchema()
);

export const getDeviceDataInfo: Selector<string> = createSelector(
  getDeviceDataMode, (dataMode: DeviceDataMode) =>
    dataMode === DeviceDataMode.ACTUAL ?
      "Actual Data represents the last available data for a given device (and schema) " +
      "that has been provided by the device itself to the cloud." :
      "Desired Data represents a requested, but not yet applied change" +
      " to data for a given device (and schema)."
);

export const isDeviceEnrollmentComplete: Selector<boolean>  = createSelector(
  getDeviceRef, (deviceId: string) => !isEmptyString(deviceId));

export const canAddMoreDevices: Selector<boolean> = createSelector(
  [isValidateModeActive, getDevices],
  (validateMode: boolean, devices: DeviceDataModel[]) => {
    if (validateMode) {
      return devices.length < 1;
    }
    return true;
  });

export const isSaveButtonEnabled: Selector<boolean> = createSelector(
  [isFileUploaded, hasDevices, isBatchIdValid],
  (validFile: boolean, validDevices: boolean, validBatchId: boolean) =>
    (validFile || validDevices) && validBatchId);

export const getSaveButtonLabel: Selector<string> = createSelector(
  isValidateModeActive, (validateMode: boolean) => validateMode ? "Validate Device" : "Enroll Device"
);

export const getDeviceEnrollmentSteps: Selector<DeviceEnrollmentWizardStep[]> = createSelector(
  [isFileUploadSelected, isValidateModeActive],
  (fileUpload: boolean, validateMode: boolean) => {

    if (validateMode) {
      return VALIDATE_DEVICE_WIZARD_STEPS;
    }

    return fileUpload ? DEVICE_ENROLLMENT_WIZARD_FILE_UPLOAD_STEPS
      : DEVICE_ENROLLMENT_WIZARD_VISUAL_EDITOR_STEPS;
  });

export const isDeviceEnrollmentFirstStepActive: Selector<boolean> = createSelector(
  [getDeviceEnrollmentWizardStep, getDeviceEnrollmentSteps],
  (currentStep: DeviceEnrollmentWizardStep, steps: DeviceEnrollmentWizardStep[]) =>
    currentStep === steps[0]);

export const isDeviceEnrollmentLastStepActive: Selector<boolean> = createSelector(
  [getDeviceEnrollmentWizardStep, getDeviceEnrollmentSteps],
  (currentStep: DeviceEnrollmentWizardStep, steps: DeviceEnrollmentWizardStep[]) =>
    currentStep === steps[steps.length - 1]);

export const getDeviceEnrollmentDisabledSteps: Selector<DeviceEnrollmentWizardStep[]> = createSelector(
  [
    getDeviceEnrollmentSteps,
    isBatchIdValid,
    isFileUploaded,
    isFileUploadSelected,
  ],
  (
    steps: DeviceEnrollmentWizardStep[],
    validBatchId: boolean,
    validFile: boolean,
    fileUploadSelected: boolean,
  ) => {

    if (!validBatchId) {
      return steps.filter(step => DeviceEnrollmentWizardStep.ENROLLMENT_TYPE !== step &&
        DeviceEnrollmentWizardStep.BATCH_ID !== step);
    }

    if (fileUploadSelected && !validFile) {
      return steps.filter(step => DeviceEnrollmentWizardStep.ENROLLMENT_TYPE !== step &&
        DeviceEnrollmentWizardStep.BATCH_ID !== step && DeviceEnrollmentWizardStep.FILE_UPLOAD !== step);
    }

    return [];
  }
);

export const getDeviceEnrollmentCompletedSteps: Selector<DeviceEnrollmentWizardStep[]> = createSelector(
  [
    getDeviceEnrollmentSteps,
    getDeviceEnrollmentWizardStep,
    isBatchIdValid,
    isFileUploaded,
    hasDevices,
    isDeviceEnrollmentLastStepActive,
    isLoadingIndicatorVisible,
  ], (
    steps: DeviceEnrollmentWizardStep[],
    currentStep: DeviceEnrollmentWizardStep,
    validBatchId: boolean,
    validFile: boolean,
    validDevices: boolean,
    lastStepActive: boolean,
    loading: boolean,
  ) => {

    const currentStepIndex = steps.indexOf(currentStep);

    return steps.filter((step: DeviceEnrollmentWizardStep, index: number) => {

      if (index > currentStepIndex) {
        return false;
      }

      const isCurrentStep = (step === currentStep);

      switch (step) {
        case DeviceEnrollmentWizardStep.ENROLLMENT_TYPE:
          return !isCurrentStep;
        case DeviceEnrollmentWizardStep.BATCH_ID:
          return !isCurrentStep;
        case DeviceEnrollmentWizardStep.FILE_UPLOAD:
          return !isCurrentStep &&  validBatchId && validFile;
        case DeviceEnrollmentWizardStep.DEVICES:
          return !isCurrentStep &&  validBatchId && validDevices;
        case DeviceEnrollmentWizardStep.EDITOR:
          return lastStepActive && validBatchId && validDevices;
        case DeviceEnrollmentWizardStep.REVIEW:
          return lastStepActive && loading;
        default:
          return false;
      }
    });
  });

export const getDeviceEnrollmentPreviousStep: Selector<DeviceEnrollmentWizardStep> = createSelector(
  [isDeviceEnrollmentFirstStepActive, getDeviceEnrollmentSteps, getDeviceEnrollmentWizardStep] ,
  (firstStep: boolean, steps: DeviceEnrollmentWizardStep[], currentStep: DeviceEnrollmentWizardStep) => {

    if (firstStep) {
      return DeviceEnrollmentWizardStep.NONE;
    }

    const currentStepIndex = steps.indexOf(currentStep);

    return steps[currentStepIndex - 1] || DeviceEnrollmentWizardStep.NONE;
  });

export const getDeviceEnrollmentNextStep: Selector<DeviceEnrollmentWizardStep> = createSelector(
  [isDeviceEnrollmentLastStepActive, getDeviceEnrollmentSteps, getDeviceEnrollmentWizardStep] ,
  (lastStep: boolean, steps: DeviceEnrollmentWizardStep[], currentStep: DeviceEnrollmentWizardStep) => {

    if (lastStep) {
      return DeviceEnrollmentWizardStep.NONE;
    }

    const currentStepIndex = steps.indexOf(currentStep);

    return steps[currentStepIndex + 1] || DeviceEnrollmentWizardStep.NONE;
  });

export const isDeviceEnrollmentPreviousStepButtonDisabled: Selector<boolean>  = createSelector(
  [getDeviceEnrollmentDisabledSteps, getDeviceEnrollmentPreviousStep],
  (disabledSteps: DeviceEnrollmentWizardStep[], previousStep: DeviceEnrollmentWizardStep) =>
    DeviceEnrollmentWizardStep.NONE === previousStep || disabledSteps.indexOf(previousStep) >= 0
);

export const isDeviceEnrollmentNextStepButtonDisabled: Selector<boolean>  = createSelector(
  [getDeviceEnrollmentDisabledSteps, getDeviceEnrollmentNextStep],
  (disabledSteps: DeviceEnrollmentWizardStep[], nextStep: DeviceEnrollmentWizardStep) =>
    DeviceEnrollmentWizardStep.NONE === nextStep || disabledSteps.indexOf(nextStep) >= 0
);

export const getDeviceTableHeaderRowItems: Selector<TableHeaderRowData[]> = createSelector(
  isLoadingIndicatorVisible, (loading: boolean) =>  loading ? [] : [
    new TableHeaderRowData({
      className: "deviceId",
      label: "Device ID"
    }),
  ]);

// Add Device Wizard Step
export const isDeviceIdViewSelected: Selector<boolean> = createSelector(
  getAddDeviceWizardStep, (step: AddDeviceWizardStep) => {
    return AddDeviceWizardStep.DEVICE_ID === step;
  });

export const isDeviceTypeViewSelected: Selector<boolean> = createSelector(
  getAddDeviceWizardStep, (step: AddDeviceWizardStep) => {
    return AddDeviceWizardStep.DEVICE_TYPE === step;
  });

export const isDataViewSelected: Selector<boolean> = createSelector(
  getAddDeviceWizardStep, (step: AddDeviceWizardStep) => {
    return AddDeviceWizardStep.DATA === step;
  });

export const isCredentialViewSelected: Selector<boolean> = createSelector(
  getAddDeviceWizardStep, (step: AddDeviceWizardStep) => {
    return AddDeviceWizardStep.CREDENTIALS === step;
  });

export const isAddDeviceEditorViewSelected: Selector<boolean> = createSelector(
  getAddDeviceWizardStep, (step: AddDeviceWizardStep) => {
    return AddDeviceWizardStep.EDITOR === step;
  });

export const hasDeviceTypeSelected: Selector<boolean> = createSelector(
  getDevice, (deviceEnrollment: DeviceDataModel) =>
    !isEmptyString(deviceEnrollment.getDeviceType()));

export const hasSelectedActualSchema: Selector<boolean> = createSelector(
  getDevice, (deviceEnrollment: DeviceDataModel) =>
    deviceEnrollment.getActualSchemas().length > 0);

export const hasSelectedDesiredSchema: Selector<boolean> = createSelector(
  getDevice, (deviceEnrollment: DeviceDataModel) =>
    deviceEnrollment.getDesiredSchemas().length > 0);

export const hasCredentials: Selector<boolean> = createSelector(
  getDevice, (deviceEnrollment: DeviceDataModel) =>
    deviceEnrollment.getCredentials().length > 0);

export const areCredentialsValid: Selector<boolean> = createSelector(
  getDevice, (deviceEnrollment: DeviceDataModel) =>
    deviceEnrollment.getCredentials().some(credential =>
      !isEmptyString(credential.name) && !isEmptyString(credential.value)
    ));

export const isDeviceIdValid: Selector<boolean> = createSelector(
  getDevice, (deviceEnrollment: DeviceDataModel) =>
    !isEmptyString(deviceEnrollment.getDeviceId()));

export const isDeviceEnrollmentJsonValid: Selector<boolean> = createSelector(
  getDeviceEnrollmentAsJson, (json: string) => isValidJson(json));

export const isAddDeviceFirstStepActive: Selector<boolean> = createSelector(
  getAddDeviceWizardStep, (step: AddDeviceWizardStep) => ADD_DEVICE_DIALOG_STEPS[0] === step);

export const isAddDeviceLastStepActive: Selector<boolean> = createSelector(
  getAddDeviceWizardStep, (step: AddDeviceWizardStep) =>
    step === ADD_DEVICE_DIALOG_STEPS[ADD_DEVICE_DIALOG_STEPS.length - 1]);

export const getAddDeviceDisabledSteps: Selector<AddDeviceWizardStep[]> = createSelector(
  [
    isDeviceIdValid,
    hasDeviceTypeSelected,
    isDeviceEnrollmentJsonValid
  ],
  (
    validDeviceId: boolean,
    validDeviceType: boolean,
    validJson: boolean,
  ) => {

    const steps = ADD_DEVICE_DIALOG_STEPS;

    if (!validDeviceId) {
      return steps.filter(step => AddDeviceWizardStep.DEVICE_ID !== step);
    }

    if (!validDeviceType) {
      return steps.filter(step => AddDeviceWizardStep.DEVICE_ID !== step &&
        AddDeviceWizardStep.DEVICE_TYPE !== step);
    }

    if (!validJson) {
      return steps.filter(step => AddDeviceWizardStep.EDITOR !== step);
    }

    return [];
  });

export const getAddDeviceCompletedSteps: Selector<AddDeviceWizardStep[]> = createSelector(
  [
    getAddDeviceWizardStep,
    isDeviceIdValid,
    hasDeviceTypeSelected,
    hasSelectedDesiredSchema,
    hasSelectedActualSchema,
    hasCredentials,
    areCredentialsValid,
    isDeviceEnrollmentJsonValid,
    isAddDeviceLastStepActive,
  ],
  (
    currentStep: AddDeviceWizardStep,
    validDeviceId: boolean,
    validDeviceType: boolean,
    selectedDesiredSchema: boolean,
    selectedActualSchema: boolean,
    selectedCredentials: boolean,
    validCredentials: boolean,
    validJson: boolean,
    lastStepActive: boolean
  ) => {

    const steps = ADD_DEVICE_DIALOG_STEPS;

    const currentStepIndex = steps.indexOf(currentStep);

    return steps.filter((step: AddDeviceWizardStep, stepIndex: number) => {

      const isCurrentStep = (step === currentStep);

      switch (step) {
        case AddDeviceWizardStep.DEVICE_ID:
          return !isCurrentStep && validDeviceId;
        case AddDeviceWizardStep.DEVICE_TYPE:
          return !isCurrentStep && validDeviceId && validDeviceType;
        case AddDeviceWizardStep.DATA:
          return !isCurrentStep && validDeviceId && validDeviceType &&
            (selectedDesiredSchema || selectedActualSchema || currentStepIndex > stepIndex);
        case AddDeviceWizardStep.CREDENTIALS:
          return !isCurrentStep && validDeviceId && validDeviceType &&
            ((selectedCredentials && validCredentials) || currentStepIndex > stepIndex);
        case AddDeviceWizardStep.EDITOR:
          return lastStepActive && validJson;
        default:
          return false;
      }
    });

  });

export const getAddDevicePreviousStep: Selector<AddDeviceWizardStep> = createSelector(
  [isAddDeviceFirstStepActive, getAddDeviceWizardStep],
  (firstStep: boolean, currentStep: AddDeviceWizardStep) => {

    if (firstStep) {
      return AddDeviceWizardStep.NONE;
    }

    const currentStepIndex = ADD_DEVICE_DIALOG_STEPS.indexOf(currentStep);

    return ADD_DEVICE_DIALOG_STEPS[currentStepIndex - 1] || AddDeviceWizardStep.NONE;
  });

export const getAddDeviceNextStep: Selector<AddDeviceWizardStep> = createSelector(
  [isAddDeviceLastStepActive, getAddDeviceWizardStep],
  (lastStep: boolean, currentStep: AddDeviceWizardStep) => {

    if (lastStep) {
      return AddDeviceWizardStep.NONE;
    }

    const currentStepIndex = ADD_DEVICE_DIALOG_STEPS.indexOf(currentStep);

    return ADD_DEVICE_DIALOG_STEPS[currentStepIndex + 1] || AddDeviceWizardStep.NONE;
  });

export const isAddDevicePreviousStepButtonDisabled: Selector<boolean> = createSelector(
  [getAddDeviceDisabledSteps, getAddDevicePreviousStep],
  (disabledSteps: AddDeviceWizardStep[], previousStep: AddDeviceWizardStep) =>
    AddDeviceWizardStep.NONE === previousStep || disabledSteps.indexOf(previousStep) >= 0);

export const isAddDeviceNextStepButtonDisabled: Selector<boolean> = createSelector(
  [getAddDeviceDisabledSteps, getAddDeviceNextStep],
  (disabledSteps: AddDeviceWizardStep[], nextStep: AddDeviceWizardStep) => {

    return AddDeviceWizardStep.NONE === nextStep || disabledSteps.indexOf(nextStep) >= 0;
  });

export const getCredentialsTableHeaderRowItems: Selector<TableHeaderRowData[]> = createSelector(
  isLoadingIndicatorVisible, (loading: boolean) => loading ? [] : [
    new TableHeaderRowData({
      className: "name",
      label: "Name"
    }),
    new TableHeaderRowData({
      className: "value",
      label: "Value"
    }),
  ]);

export const isAddDeviceButtonEnabled: Selector<boolean> = createSelector(
  [isDeviceEnrollmentJsonValid, hasDeviceTypeSelected, isBatchIdValid, isDeviceIdValid],
  (validJson: boolean, validDeviceType: boolean, validDeviceId: boolean) =>
    validJson && validDeviceId && validDeviceType );

export const getSelectedSchemaNamespace: Selector<string> = createSelector(
  getSchemaIdentity, (schemaIdentity: string) =>
    schemaIdentity.split(":")[0] || "" );

export const getSelectedSchemaName: Selector<string> = createSelector(
  getSchemaIdentity, (schemaIdentity: string) =>
    schemaIdentity.split(":")[1] || "" );

export const getSelectedSchemaVersion: Selector<string> = createSelector(
  getSchemaIdentity, (schemaIdentity: string) =>
    schemaIdentity.split(":")[2] || "" );

export const getDataValidationItem: Selector<DataAttributes> = createSelector(
  [getSchemaIdentity, getDeviceData],
  (schemaIdentity: string, schemaData: string) => {
    return({
      schemaIdentity,
      schemaData
    });
  });

export const isDownloadFileButtonEnabled: Selector<boolean> = createSelector(
  [hasDevices, isErrorMessageVisible],
  (validDevices: boolean,  errorMessage: boolean) =>
     validDevices && !errorMessage);

export const getDeviceEnrollmentWizardDefaultState: Selector<DeviceEnrollmentWizardState> = createSelector(
  getDefaultStateAttributes, (attrs: DeviceEnrollmentWizardStateAttributes) =>
    new DeviceEnrollmentWizardState(attrs));

export const getDeviceEnrollmentWizardState: Selector<DeviceEnrollmentWizardState> = createSelector(
  [getBatchId, isFileUploaded, getNumberOfDevices,  getDeviceEnrollmentAsJson],
  (batchId: string, fileUploaded: boolean, numDevices: number, json: string) =>
     new DeviceEnrollmentWizardState({ batchId, fileUploaded, numDevices, json }));

export const getDeviceWizardTitle: Selector<string> = createSelector(
  isDeviceEditMode, (editMode: boolean) => !editMode ? "Add Device" : "Edit Device"
);

export const getDeviceWizardSaveButtonLabel: Selector<string> = createSelector(
  isDeviceEditMode, (editMode: boolean) => !editMode ? "Add Device" : "Update Device"
);

export const getDeviceWizardClassName: Selector<string> = createSelector(
  isDeviceEditMode, (editMode: boolean) => !editMode ? "addDeviceDialog" : "editDeviceDialog"
);
