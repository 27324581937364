import get from "lodash/get";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import { AppSchema } from "@schemas";
import { createSelector } from "reselect";
import { SchemaWizardSelectors } from "../schemaWizard";
import { PropertiesTableSchema } from "../../reducers/propertiesTable";
import {
  JsonSchemaDefinition,
  JsonSchemaPropertiesDefinition,
  JsonSchemaProperty,
} from "@data";

export const getBreadcrumbs: (state: AppSchema) => string[] = createSelector(
  SchemaWizardSelectors.getPropertiesTableAttributes,
  (propertiesTable: PropertiesTableSchema) => propertiesTable.breadcrumbs);

export const isViewingChildProperty: (state: AppSchema) => boolean = createSelector(
  getBreadcrumbs, (breadcrumbs: string[]) => breadcrumbs.length > 0);

export const getPathToProperties: (state: AppSchema) => string[] = createSelector(
  [getBreadcrumbs], (breadcrumbs: string[]) => {

    if (breadcrumbs.length === 0) {
      return [];
    }

    return flatten(breadcrumbs.map((key: string) => ["properties"].concat(key)));
  });

export const getSchema: (state: AppSchema) => JsonSchemaDefinition = createSelector(
  [getPathToProperties, SchemaWizardSelectors.getSchema],
  (path: string[], schema: JsonSchemaDefinition) => {

    if (path.length === 0) {
      return schema;
    }

    return get(schema, path, {});
  });

export const isNotFoundErrorVisible: (state: AppSchema) => boolean = createSelector(
  getSchema, (schema: JsonSchemaDefinition) => isEmpty(schema));

export const getProperties: (state: AppSchema) => JsonSchemaPropertiesDefinition = createSelector(
  getSchema, (schema: JsonSchemaDefinition) => {
    const properties = schema.properties || {};
    return Object.keys(properties).reduce((data, propertyName) => {
      data[propertyName.trim()] = properties[propertyName];
      return data;
    }, {});
  });

export const getRequiredProperties: (state: AppSchema) => string[] = createSelector(
  getSchema, (schema: JsonSchemaDefinition) => {
    const requiredProperties = Array.isArray(schema.required) ? schema.required : [];
    return requiredProperties
      .map((propertyName: string) => propertyName.trim())
      .filter((propertyName: string) => propertyName.length > 0);
  });

export const getJsonSchemaProperties: (state: AppSchema) => JsonSchemaProperty[] = createSelector(
  [getRequiredProperties, getProperties],
  (requiredProperties: string[], properties: JsonSchemaPropertiesDefinition) => {
    return Object.keys(properties).map((name: string) =>
      JsonSchemaProperty.from(name, properties[name], requiredProperties));
  });

export const getSchemaRef: (state: AppSchema) => string = createSelector(
  [isViewingChildProperty, getSchema],
  (showChildProperty: boolean, schema: JsonSchemaDefinition) => {

    if (!showChildProperty) {
      return "";
    }

    const { $ref } = schema;

    if (typeof $ref !== "string" || $ref.trim().length === 0) {
      return "";
    }

    return $ref;
  });

export const isSchemasTableVisible: (state: AppSchema) => boolean = createSelector(
  getSchemaRef, (schemaRef: string) =>
    schemaRef.length > 0 && schemaRef.indexOf("#/definitions") !== 0);

export const getSelectedSchemaRef: (state: AppSchema) => string = createSelector(
  [isSchemasTableVisible, getSchemaRef],
  (showSchemasTable: boolean,
   schemaRef: string) => {

    return !showSchemasTable ? "" : schemaRef;
  });
