import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";

export interface DeleteFederationProviderSchema extends BasePortalModuleSchema {
  open: boolean;
  providerId: string;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<DeleteFederationProviderSchema>({
  ...BASE_DEFAULT_STATE,
  showEmptyView: false,
  open: false,
  providerId: "",
});

export type DeleteFederationProviderActionTypes = PortalModuleActionTypes<DeleteFederationProviderSchema> & {
  DELETE_FEDERATION_PROVIDER_REQUEST: string;
  DELETE_FEDERATION_PROVIDER_SUCCESS: string;
  DELETE_FEDERATION_PROVIDER_FAILED: string;
};

export const ACTION_TYPES: DeleteFederationProviderActionTypes = {
  ...createPortalModuleActionTypes<DeleteFederationProviderSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    DELETE_FEDERATION_PROVIDER_REQUEST: `${MODULE_ID}/delete_federation_provider_request`,
    DELETE_FEDERATION_PROVIDER_SUCCESS: `${MODULE_ID}/delete_federation_provider_success`,
    DELETE_FEDERATION_PROVIDER_FAILED: `${MODULE_ID}/delete_federation_provider_failed`,
  },
};

export const DeleteFederationProviderReducer = combineReducers<DeleteFederationProviderSchema>(
  createPortalModuleReducers<DeleteFederationProviderSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DeleteFederationProviderReducer;
