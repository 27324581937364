import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { isEmptyString, noop } from "@util";
import {
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel,
  usePortalSnackbar,
} from "@components";
import { styles } from "./styles";

export interface DeleteUiPackageDialogModel extends ConfirmationDialogModel<string> {
  loadingMessage?: string;
  confirmationMessage?: string;
  closeOnSuccessDelay?: number;
}

export interface DeleteUiPackageDialogActions extends ConfirmationDialogActions<string> {
  onSuccessMessageShown?: () => void;
}

type Props = WithStyles< typeof styles> &
  DeleteUiPackageDialogModel & DeleteUiPackageDialogActions;

export const DeleteUiPackageDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    loading,
    successMessage,
    errorMessage,
    continueButtonLabel = "Delete",
    title = "Delete UI Package",
    loadingMessage = "Deleting UI Package...",
    confirmationMessage = "Are you sure you want to delete this UI Package?",
    closeOnSuccessDelay = 500,
    onSuccessMessageShown,
    ...otherProps
  } = props;

  const {
    open,
    cancel: closeDialog = noop,
    continueButtonDisabled = loading,
  } = otherProps;

  const message = React.useMemo(() => {
    if (loading) {
      return loadingMessage;
    } else if (!isEmptyString(successMessage)) {
      return successMessage;
    } else {
      return confirmationMessage;
    }
  }, [
    loading,
    loadingMessage,
    successMessage,
    confirmationMessage,
  ]);

  usePortalSnackbar("delete-binary-data-attributes", {
    errorMessage,
    successMessage,
    onSuccessMessageShown: onSuccessMessageShown || closeDialog,
    successCallbackDelay: closeOnSuccessDelay,
  });

  if (!open) {
    return null;
  }
  return (
    <ConfirmationDialog
      {...otherProps}
      className={classnames("deleteUiPackageDialog", className)}
      title={title}
      loading={loading}
      errorMessage={errorMessage}
      successMessage={successMessage}
      continueButtonLabel={continueButtonLabel}
      continueButtonDisabled={continueButtonDisabled}
    >
      {!errorMessage && (
        <div className={classes.content}>
          <label className={classnames("label", classes.label)}>
            {message}
          </label>
        </div>
      )}
    </ConfirmationDialog>
  );
});

export default DeleteUiPackageDialog;
