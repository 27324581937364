import { Record } from "immutable";
import { getStringValue } from "@util";

export enum DeviceEnrollmentState {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  COMPLETED_WITH_ERROR = "COMPLETED_WITH_ERROR"
}

export interface DeviceEnrollmentStatusAttributes {
  batchId: string;
  status: DeviceEnrollmentState;
  updatedBy?: string;
}

export class DeviceEnrollmentStatus extends Record( {
  batchId: "",
  status: DeviceEnrollmentState.IN_PROGRESS,
  updatedBy: "",
}) implements  DeviceEnrollmentStatusAttributes {

  public static EMPTY: DeviceEnrollmentStatus = new DeviceEnrollmentStatus();

  public readonly batchId: string;
  public readonly status: DeviceEnrollmentState;
  public readonly updatedBy: string;

  public getBatchId(): string {
    return getStringValue(this.batchId);
  }

  public getStatus(): string {
    return getStringValue(this.status);
  }

  public getUpdatedBy(): string {
    return getStringValue(this.updatedBy);
  }
}

export default DeviceEnrollmentStatus;
