import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "center",
    },
    label: {
      color: Color.TEXT,
      cursor: "default",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
      textTransform: "none",
      wordBreak: "break-word",
      marginRight: 8,
    },
    fab: {
      width: 28,
      height: 28,
    },
    progressIndicator: {
      color: Color.SIGNIFY_GREEN,
      position: "absolute",
    },
    icons: {
      fontSize: 13,
    },
    failedLabel: {
      color: Color.RED,
    },
    success: {
      backgroundColor: Color.SIGNIFY_GREEN,
      color: Color.BLACK,
    },
    failed: {
      backgroundColor: Color.RED,
      color: Color.BLACK,
    },
    errorIcon: {
      color: Color.BLACK,
    },
    successIcon: {
      color: Color.BLACK,
    },
  });

export default styles;
