import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import { jsonView as styles } from "./styles";
import { JsonView as JsonViewer, ToggleDtsModelVersionSwitch } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface Model {
  json?: string;
  fileName?: string;
  toggleApiSwitchTitle?: string;
  toggleApiSwitchHelperText?: string;
  isToggleApiSwitchVisible?: boolean;
  isToggleApiSwitchDisabled?: boolean;
  isHistoricalDataViewSelected?: boolean;
  historicalButtonLabel?: string;
  regionalButtonLabel?: string;
}

export interface Actions {
  setShowHistoricalDataView?: (value: boolean) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const JsonView = withStyles(styles)((props: Props) => {

  const {
    classes,
    json = "{}",
    fileName,
    toggleApiSwitchTitle = "Device Type API Version",
    toggleApiSwitchHelperText =
      `This device type uses the latest API version. Select the historical view mode to view this 
      data using the legacy API model.`,
    isToggleApiSwitchVisible,
    isToggleApiSwitchDisabled,
    isHistoricalDataViewSelected,
    setShowHistoricalDataView = noop,
  } = props;

  return (
    <div className={classnames("jsonView", classes.container)}>
      {isToggleApiSwitchVisible && (
        <React.Fragment>
          <label className={classnames("toggleApiSwitchTitle", classes.title)}>
            {toggleApiSwitchTitle}
          </label>
          {!isEmptyString(toggleApiSwitchHelperText) && (
            <label className={classnames("toggleApiSwitchHelperText", classes.helperText)}>
              {toggleApiSwitchHelperText}
            </label>
          )}
          <ToggleDtsModelVersionSwitch
            className={classnames("toggleDtsApiVersion", classes.toggleApiSwitch)}
            disabled={isToggleApiSwitchDisabled}
            regionalApiSelected={!isHistoricalDataViewSelected}
            showHistoricalApi={() => setShowHistoricalDataView(true)}
            showRegionalApi={() => setShowHistoricalDataView(false)}
          />
        </React.Fragment>
      )}
      <JsonViewer
        className={classnames("jsonViewer", classes.jsonViewer, {
          [classes.extraTopMargin]: !isToggleApiSwitchVisible,
        })}
        json={json}
        fileName={fileName}
      />
    </div>
  );
});

export default JsonView;
