import { AppSchema } from "@schemas";
import { createActions } from "@base/createActions";
import { CreateDataSetRequestResponse, DataSetRequestClient, RestClientError } from "@network";
import { refresh as refreshDataCatalogModule } from "@modules/dataCatalog/actions";
import {
  ACTION_TYPES,
  DataSetRequestWizardStep,
  DEFAULT_STATE,
} from "../reducers/dataSetRequestWizard";
import { DataSetRequestWizardSelectors } from "../selectors";
import { CreateDataSetRequest } from "@data/CreateDataSetRequest";
import { DataClassification, DataSetRequest, DataSetType, IngestionMechanism } from "@data";
import { getCurrentAccountId } from "@main/selectors";
import { toLRNs } from "../helpers";
import { EditModeActions } from "../actions";
import { isEmptyString } from "@util";

export const {
  createDataSetRequest: setCreateDataSetRequestAttributes,
  dataSetRequestWizardStep: setDataSetRequestWizardStep,
  showEditMode: setShowEditMode,
  dataSetRequestRef: setDataSetRequestRef,
  requestIoTApproved: setRequestIoTApproved,
  dataRangeErrorMessage: setDataRangeErrorMessage,
  lifecycleErrorMessage: setLifecycleErrorMessage,
  showEditModeInitializeError: setShowEditModeInitializeError,
  termsConditionsFileProvided: setTermsConditionsFileProvided,
  setErrors,
  setErrorMessage,
  setSuccessMessage,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  CREATE_DATA_SET_REQUEST_REQUEST: createDataSetRequestRequest,
  CREATE_DATA_SET_REQUEST_SUCCESS: createDataSetRequestSuccess,
  CREATE_DATA_SET_REQUEST_FAILED: createDataSetRequestFailed,
  FETCH_DATA_SET_REQUEST_REQUEST: fetchDataSetRequestRequest,
  FETCH_DATA_SET_REQUEST_SUCCESS: fetchDataSetRequestSuccess,
  FETCH_DATA_SET_REQUEST_FAILED: fetchDataSetRequestFailed,
  EDIT_DATA_SET_REQUEST_REQUEST: editDataSetRequestRequest,
  EDIT_DATA_SET_REQUEST_SUCCESS: editDataSetRequestSuccess,
  EDIT_DATA_SET_REQUEST_FAILED: editDataSetRequestFailed,
  ADD_TERMS_CONDITIONS_FILE: trackAddTermsConditionsFile,
  REMOVE_TERMS_CONDITIONS_FILE: trackRemoveTermsConditionsFile,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setCreateDataSetRequestAttributes());
  dispatch(setDataSetRequestWizardStep());
  dispatch(setDataSetRequestRef());
  dispatch(setShowEditMode(false));
  dispatch(setRequestIoTApproved(false));
  dispatch(setDataRangeErrorMessage());
  dispatch(setLifecycleErrorMessage());
  dispatch(setShowEditModeInitializeError());
  dispatch(setTermsConditionsFileProvided());
  return dispatch(baseReset());
};

export const updateDataSetRequest =
  (request: CreateDataSetRequest = CreateDataSetRequest.EMPTY) => (dispatch: any) => {

    const dataSetRequest = request.toJS();

    dispatch(setCreateDataSetRequestAttributes(dataSetRequest));

    return dispatch(setErrorMessage());
  };

export const updateDataSetAlias = (dataSetAlias: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      dataSetAlias,
    })));
  };

export const updateDescription = (description: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      description,
    })));
  };

export const updateDataSetGroup = (dataSetGroup: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      dataSetGroup,
    })));
  };

export const updateDataSetType = (dataSetType: DataSetType = DataSetType.DATA_LAKE_ACQUIRED) =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      dataSetType,
    })));
  };

export const updateTags = (tags: string[] = []) =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      tags,
    })));
  };

export const updateDataOwners = (dataOwners: string[] = []) =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      dataOwners
    })));
  };

export const updateArchitects = (architects: string[] = []) =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      architects
    })));
  };

export const updateEngineeringContacts = (engineeringContacts: string[] = []) =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      engineeringContacts
    })));
  };

export const updateBusinessOwners = (businessOwners: string[] = []) =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      businessOwners
    })));
  };

export const updateSchema = (schema: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      schema,
    })));
  };

export const updatePiiComment = (comment: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      piiComment: comment,
    })));
  };

export const updateSampleAnonymizedData = (sampleAnonymizedData: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      sampleAnonymizedData,
    })));
  };

export const updateDataClassification = (dataClassification: DataClassification) =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      dataClassification,
    })));
  };

export const updateIngestionMechanism = (ingestionMechanism: IngestionMechanism) =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      ingestionMechanism,
    })));
  };

export const updateConfirmNonProductionData = (confirmNonProductionData: boolean) =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      confirmNonProductionData,
    })));
  };

export const updateContainsPII = (containsPII: boolean) =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      containsPII: containsPII,
    })));
  };

export const updateDataVolume = (dataVolume: number) =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    dispatch(setDataRangeErrorMessage());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      dataRate: {
        ...dataSetRequest.dataRate,
        dataVolume,
      }
    })));
  };

export const updateDataVelocity = (dataVelocity: number) =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    dispatch(setDataRangeErrorMessage());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      dataRate: {
        ...dataSetRequest.dataRate,
        dataVelocity,
      }
    })));
  };

export const updateArchivingInDays = (archivingInDays: number) =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    dispatch(setLifecycleErrorMessage());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      lifecyclePolicy: {
        ...dataSetRequest.lifecyclePolicy,
        archivingInDays,
      }
    })));
  };

export const updateExpirationInDays = (expirationInDays: number) =>
  (dispatch: any, getState: () => AppSchema) => {

    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequest(getState());

    dispatch(setLifecycleErrorMessage());

    return dispatch(updateDataSetRequest(new CreateDataSetRequest({
      ...dataSetRequest.toJS(),
      lifecyclePolicy: {
        ...dataSetRequest.lifecyclePolicy,
        expirationInDays,
      }
    })));
  };

export const setCurrentStep = (step: DataSetRequestWizardStep) => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const disabledSteps = DataSetRequestWizardSelectors.getDisabledSteps(state);

  if ( DataSetRequestWizardStep.NONE === step || disabledSteps.indexOf(step) >= 0 ) {
    return Promise.resolve();
  }

  dispatch(setErrorMessage());
  return dispatch(setDataSetRequestWizardStep(step));
};

export const showNextStep = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  return dispatch(setCurrentStep(DataSetRequestWizardSelectors.getNextStep(state)));
};

export const showPreviousStep = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();

  return dispatch(setCurrentStep(DataSetRequestWizardSelectors.getPreviousStep(state)));
};

export const save = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  if (DataSetRequestWizardSelectors.isEditModeActive(state)) {
    return dispatch(EditModeActions.save());
  }

  const accountId = getCurrentAccountId(state);
  const accessToken = DataSetRequestWizardSelectors.getAccessToken(state);
  const excludeLifecyclePolicy = DataSetRequestWizardSelectors.isLifecyclePolicyEmpty(state);
  const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequestAttributes(state);
  const containsPii = DataSetRequestWizardSelectors.getContainsPiiData(state);
  const dataSetGroup = DataSetRequestWizardSelectors.getDataSetGroup(state);
  const dataSetRequestCopy = JSON.parse(JSON.stringify(dataSetRequest));
  dataSetRequestCopy.dataOwners = toLRNs(dataSetRequest.dataOwners, accountId);
  dataSetRequestCopy.architects = toLRNs(dataSetRequest.architects, accountId);
  dataSetRequestCopy.engineeringContacts = toLRNs(dataSetRequest.engineeringContacts, accountId);
  dataSetRequestCopy.businessOwners = toLRNs(dataSetRequest.businessOwners, accountId);

  if (dataSetRequest.dataSetType !== DataSetType.DATA_LAKE_ACQUIRED) {
    delete dataSetRequestCopy.ingestionMechanism;
  }

  if (excludeLifecyclePolicy) {
    delete dataSetRequestCopy.lifecyclePolicy;
  }

  if (!containsPii) {
    delete dataSetRequestCopy.piiComment;
  }

  if (isEmptyString(dataSetGroup)) {
    delete dataSetRequestCopy.dataSetGroup;
  }

  const json = JSON.stringify(dataSetRequestCopy, null, "  ");

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setErrors());
  dispatch(setErrorMessage());
  dispatch(createDataSetRequestRequest());

  return DataSetRequestClient.createDataSetRequest(accessToken, json)
    .then((response: CreateDataSetRequestResponse) => {

      dispatch(createDataSetRequestSuccess());
      dispatch(refreshDataCatalogModule());
      dispatch(setDataSetRequestRef(response.dataSetRequestId));
      dispatch(hideLoadingIndicator());
      return dispatch(setSuccessMessage("Data Set Request Created"));

    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to create data set request", errors } = response;

      dispatch(createDataSetRequestFailed(analytic));
      dispatch(setErrors(errors));
      dispatch(setErrorMessage(error));

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideLoadingIndicator());
    });
};

export const cloneDataSetRequest = (dataSetRequest: DataSetRequest = DataSetRequest.EMPTY) =>
  (dispatch: any) => {

    dispatch(reset());

    return dispatch(setCreateDataSetRequestAttributes(new CreateDataSetRequest({
      dataSetAlias: `${dataSetRequest.getDataSetAlias()}-clone`,
      description: dataSetRequest.getDescription(),
      dataSetGroup: dataSetRequest.getDataSetGroup(),
      dataSetType: dataSetRequest.getDataSetType(),
      tags: dataSetRequest.getTags(),
      dataOwners: dataSetRequest.getDataOwners().map(lrn => lrn.split(":").pop()),
      architects: dataSetRequest.getArchitects().map(lrn => lrn.split(":").pop()),
      engineeringContacts: dataSetRequest.getEngineeringContacts().map(lrn => lrn.split(":").pop()),
      businessOwners: dataSetRequest.getBusinessOwners().map(lrn => lrn.split(":").pop()),
      schema: dataSetRequest.getSchema(),
      sampleAnonymizedData: dataSetRequest.getSampleAnonymizedData(),
      ingestionMechanism: dataSetRequest.getIngestionMechanism(),
      dataClassification: dataSetRequest.getDataClassification(),
      provenance: dataSetRequest.getProvenance(),
      sourceGeographies: dataSetRequest.getSourceGeographies(),
      sharedGeographies: dataSetRequest.getSharedGeographies(),
      dataRate: dataSetRequest.getDataRate(),
      lifecyclePolicy: dataSetRequest.getLifecyclePolicy(),
      confirmNonProductionData: dataSetRequest.isConfirmedNonProductionData(),
      termsConditionsId: dataSetRequest.getTermsConditionsId(),
    }).toJS()));
  };
