import React from "react";
import classnames from "classnames";
import camelCase from "lodash/camelCase";
import AddIcon from "@material-ui/icons/AddOutlined";
import TagIcon from "@material-ui/icons/Save";
import RemoveIcon from "@material-ui/icons/Clear";
import IconButton from "@components/icon-button";
import TextField from "@components/text-field";
import CircularProgress from "@components/circular-progress";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { clickHandler, enterKeyHandler, formEventHandler, noop } from "@util";
import { ErrorView } from "@components/error-view";
import { Chip } from "@components/chip";
import { chip, loadingIndicator, styles, textField } from "./styles";

const SoftwareVersionChip = withStyles(chip)(Chip);
const SoftwareVersionTextField = withStyles(textField)(TextField);
const LoadingIndicator = withStyles(loadingIndicator)(CircularProgress);

export interface Model {
  title?: string;
  errorMessage?: string;
  softwareVersions?: string[];
  selectedSoftwareVersion?: string;
  showAccessDenied?: boolean;
  showLoadingIndicator?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  clearErrorMessage?: () => void;
  addSoftwareVersion?: (softwareVersion: string) => void;
  removeSoftwareVersion?: (softwareVersion: string) => void;
  setSelectedSoftwareVersion?: (softwareVersion: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SoftwareVersionEditor = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "",
    disabled = false,
    errorMessage = "",
    showAccessDenied,
    showLoadingIndicator,
    softwareVersions = [],
    selectedSoftwareVersion = "",
    children,
    clearErrorMessage = noop,
    addSoftwareVersion = noop,
    removeSoftwareVersion = noop,
    setSelectedSoftwareVersion = noop,
  } = props;

  const [softwareVersion, setSoftwareVersion] = React.useState("");

  const updateSoftwareVersion = () => {
    if (!disabled) {
      addSoftwareVersion(softwareVersion);
      setSoftwareVersion("");
    }
  };

  const formHelperTextProps = {
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const showErrorMessage = errorMessage.length > 0;

  return (
    <div className={classnames("softwareVersionEditor", classes.container)}>
      {showAccessDenied && (
        <ErrorView
          className={classnames("accessDenied", classes.errorMessage)}
          title="Access Denied"
          message="You do not have permission to perform this action"
        />
      )}
      {showErrorMessage && (
        <ErrorView
          className={classnames("errorMessage", classes.errorMessage)}
          message={errorMessage}
        />
      )}
      {title.length > 0 && (<label className={classnames("title", classes.title)}>{title}</label>)}
      <div className={classes.editor}>
        <SoftwareVersionTextField
          className={classnames("textField", classes.textField)}
          label="Add Software Version (optional)"
          name="softwareVersion"
          value={softwareVersion}
          fullWidth={false}
          disabled={disabled}
          autoComplete="off"
          variant="outlined"
          margin="none"
          error={softwareVersion.length === 0 && (showAccessDenied || showErrorMessage)}
          onChange={formEventHandler((newValue: string) => {
            setSoftwareVersion(newValue);
            clearErrorMessage();
          })}
          onKeyDown={enterKeyHandler(updateSoftwareVersion)}
          FormHelperTextProps={formHelperTextProps}
          InputLabelProps={inputLabelProps}
        />
        {!showLoadingIndicator && softwareVersion.trim().length > 0 && (
          <IconButton
            className={classnames("addIconButton", classes.addIconButton, {
              [classes.disabled]: disabled,
            })}
            color={disabled ? "default" : "primary"}
            disabled={disabled}
            onClick={clickHandler(updateSoftwareVersion)}
          >
            <AddIcon className={classnames("addIcon", classes.addIcon)} />
          </IconButton>
        )}
        {showLoadingIndicator && (
          <LoadingIndicator
            className={classnames("loadingIndicator", classes.loadingIndicator)}
            size={16}
            thickness={2}
            color="primary"
          />
        )}
      </div>
      <div className={classnames("softwareVersions", classes.softwareVersions)}>
        {showLoadingIndicator && softwareVersions.length === 0 && (
          <label className={classnames("loading", classes.emptyViewLabel)}>
            Loading...
          </label>
        )}
        {!showLoadingIndicator && softwareVersions.length === 0 && (
          <label className={classnames("emptyViewLabel", classes.emptyViewLabel)}>
            No Software Versions Defined
          </label>
        )}
        {softwareVersions.map((version: string) => {

          const selected = !disabled && version === selectedSoftwareVersion;

          const extraProps = disabled ? {} : {
            deleteIcon: <RemoveIcon className="removeIcon" />,
            onDelete: clickHandler(() => removeSoftwareVersion(version)),
            onClick: clickHandler(() => setSelectedSoftwareVersion(version)),
          };

          const color = selected ? "primary" : "secondary";

          return (
            <SoftwareVersionChip
              { ...extraProps }
              label={version}
              clickable={!disabled}
              color={disabled ? "default" : color}
              key={`software-version-${camelCase(version)}`}
              icon={<TagIcon className="softwareVersionIcon" />}
              className={classnames("softwareVersion", camelCase(version), classes.softwareVersion, {
                [classes.disabled]: disabled,
              })}
            />
          );
        })}
      </div>
      {children}
    </div>
  );
});

export default SoftwareVersionEditor;
