import { createStyles, StyleRules } from "@material-ui/core/styles";
import { Color } from "@components/styles";

export const button: StyleRules<string> = {
  root: {
    height: 18,
    padding: "0 4px",
    margin: 0,
  },
  label: {
    color: Color.TEXT,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "14px",
    textTransform: "none",
  },
};

export const styles = () =>
  createStyles({
    button: {
    },
  });

export default styles;
