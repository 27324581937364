import { AppSchema } from "@schemas";
import { createActions } from "@base/createActions";
import { DeviceTypeClient, ETagLocationHeaders, RestClientError } from "@network";
import {
  DeviceTypeModelV2,
  DeviceTypeModelV3,
  DeviceTypeModelV3ConfigurationAttributes,
  DeviceTypeModelVersion,
  JsonSchemaMetadata,
} from "@data";
import { DeviceTypeRequestV2, DeviceTypeRequestV3 } from "../models";
import { ACTION_TYPES, DEFAULT_STATE, DeviceTypeWizardStep } from "../reducers/deviceTypeWizard";
import { DeviceTypeWizardSelectors } from "../selectors";
import { EditModeActions } from "../actions";
import isDeviceTypeV2APIEnabled from "@util/isDeviceTypeV2APIEnabled";

export const {
  modelVersion: setModelVersion,
  namespace: setNamespace,
  name: setName,
  version: setVersion,
  json: setJson,
  deviceType: setDeviceTypeRequestAttributes,
  deviceTypeV2: setDeviceTypeRequestV2Attributes,
  deviceTypeWizardStep: setDeviceTypeWizardStep,
  showEditMode: setShowEditMode,
  showEditModeInitializeError: setShowEditModeInitializeError,
  showCloneMode: setShowCloneMode,
  deviceTypeRef: setDeviceTypeRef,
  etag: setEtag,
  defaultState: setDeviceTypeWizardDefaultState,
  setErrorMessage,
  setSuccessMessage,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  CREATE_DEVICE_TYPE_REQUEST: createDeviceTypeRequest,
  CREATE_DEVICE_TYPE_SUCCESS: createDeviceTypeSuccess,
  CREATE_DEVICE_TYPE_FAILED: createDeviceTypeFailed,
  FETCH_DEVICE_TYPE_REQUEST: fetchDeviceTypeRequest,
  FETCH_DEVICE_TYPE_SUCCESS: fetchDeviceTypeSuccess,
  FETCH_DEVICE_TYPE_FAILED: fetchDeviceTypeFailed,
  EDIT_DEVICE_TYPE_REQUEST: editDeviceTypeRequest,
  EDIT_DEVICE_TYPE_SUCCESS: editDeviceTypeSuccess,
  EDIT_DEVICE_TYPE_FAILED: editDeviceTypeFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setModelVersion());
  dispatch(setNamespace());
  dispatch(setName());
  dispatch(setVersion());
  dispatch(setJson());
  dispatch(setDeviceTypeRequestAttributes());
  dispatch(setDeviceTypeRequestV2Attributes());
  // The API_VERSION step should only be used as the default when v2 API support is enabled
  dispatch(setDeviceTypeWizardStep(isDeviceTypeV2APIEnabled()
    ? DeviceTypeWizardStep.API_VERSION
    : DeviceTypeWizardStep.NAMESPACE));
  dispatch(setShowEditMode());
  dispatch(setShowEditModeInitializeError());
  dispatch(setShowCloneMode());
  dispatch(setDeviceTypeRef());
  dispatch(setEtag());
  dispatch(setDeviceTypeWizardDefaultState());
  return dispatch(baseReset());
};

export const showEditMode = () => setShowEditMode(true);
export const hideEditMode = () => setShowEditMode(false);

export const showApiVersionView = () => setDeviceTypeWizardStep(DeviceTypeWizardStep.API_VERSION);
export const showNamespaceView = () => setDeviceTypeWizardStep(DeviceTypeWizardStep.NAMESPACE);
export const showNameView = () => setDeviceTypeWizardStep(DeviceTypeWizardStep.NAME);
export const showConnectivityView = () => setDeviceTypeWizardStep(DeviceTypeWizardStep.CONNECTIVITY);
export const showGroupsView = () => setDeviceTypeWizardStep(DeviceTypeWizardStep.GROUPS);
export const showSchemasView = () => setDeviceTypeWizardStep(DeviceTypeWizardStep.SCHEMAS);
export const showSecretsSchemaView = () => setDeviceTypeWizardStep(DeviceTypeWizardStep.SECRET);
export const showCredentialsView = () => setDeviceTypeWizardStep(DeviceTypeWizardStep.CREDENTIALS);
export const showDeviceTypeEditorView = () => setDeviceTypeWizardStep(DeviceTypeWizardStep.EDITOR);
export const showReviewView = () => setDeviceTypeWizardStep(DeviceTypeWizardStep.REVIEW);

export const updateJson = (json: string = DEFAULT_STATE.json) =>
  (dispatch: any, getState: () => AppSchema) => {

    const isRegionalApiModel = DeviceTypeWizardSelectors.isRegionalApiSelected(getState());

    dispatch(setJson(json));

    try {

      if (isRegionalApiModel) {
        const deviceTypeRequestV3 = new DeviceTypeRequestV3(JSON.parse(json));
        dispatch(setDeviceTypeRequestAttributes(deviceTypeRequestV3.toJS()));
        dispatch(setDeviceTypeRequestV2Attributes(
          DeviceTypeRequestV2.fromDeviceTypeRequestV3(deviceTypeRequestV3).toJS()));
        return dispatch(setErrorMessage());
      }

      const deviceTypeRequestV2 = new DeviceTypeRequestV2(JSON.parse(json));
      dispatch(setDeviceTypeRequestV2Attributes(deviceTypeRequestV2.toJS()));
      dispatch(setDeviceTypeRequestAttributes(
        DeviceTypeRequestV3.fromDeviceTypeRequestV2(deviceTypeRequestV2).toJS()));
      return dispatch(setErrorMessage());

    } catch (e) {
      return dispatch(setErrorMessage("Failed to parse deviceType. " +
        "Please verify that the JSON is valid before continuing."));
    }
  };

export const updateDeviceType =
  (deviceTypeRequest: DeviceTypeRequestV3 | DeviceTypeRequestV2 = DeviceTypeRequestV3.EMPTY) =>
    (dispatch: any, getState: () => AppSchema) => {

      const state = getState();

      // Do not overwrite device type from visual editor if edits are being made in json editor
      if (!DeviceTypeWizardSelectors.isJsonValid(state)) {
        return dispatch(setErrorMessage("Failed to parse device type. " +
          "Please verify that the JSON is valid before continuing."));
      }

      const isRegionalApiModel = DeviceTypeWizardSelectors.isRegionalApiSelected(state);

      if (isRegionalApiModel) {
        // In case the user was previously editing a v2 device type and had already added a
        // secrets schema, preserve the value so that it is not lost when converting the v3
        // model, which has no concept of secrets schema, into the legacy v2 model.
        const secretsSchema = DeviceTypeWizardSelectors.getSecretsSchema(state);

        const deviceTypeRequestV3 = deviceTypeRequest as DeviceTypeRequestV3;
        const deviceTypeRequestV3Attrs = deviceTypeRequestV3.toJS();
        dispatch(setJson(JSON.stringify(deviceTypeRequestV3Attrs, null, "  ")));
        dispatch(setDeviceTypeRequestAttributes(deviceTypeRequestV3Attrs));

        // Update the legacy v2 attributes using the v3 attributes - where possible
        const legacyAttrs = DeviceTypeRequestV2.fromDeviceTypeRequestV3(deviceTypeRequestV3).toJS();

        // We must manually add the secrets schema back to the v2 model since this property is
        // not supported by the v3 model
        dispatch(setDeviceTypeRequestV2Attributes(new DeviceTypeRequestV2({
          ...legacyAttrs,
          metadata: {
            secretsSchema,
            ...legacyAttrs.metadata,
          },
        })));

        return dispatch(setErrorMessage());
      }

      const deviceTypeRequestV2 = deviceTypeRequest as DeviceTypeRequestV2;
      const deviceTypeRequestV2Attrs = deviceTypeRequestV2.toJS();
      dispatch(setJson(JSON.stringify(deviceTypeRequestV2Attrs, null, "  ")));
      dispatch(setDeviceTypeRequestV2Attributes(deviceTypeRequestV2Attrs));
      dispatch(setDeviceTypeRequestAttributes(
        DeviceTypeRequestV3.fromDeviceTypeRequestV2(deviceTypeRequestV2).toJS()));
      return dispatch(setErrorMessage());
    };

export const updateModelVersion = (modelVersion: DeviceTypeModelVersion) =>
  (dispatch: any, getState: () => AppSchema) => {

    dispatch(setModelVersion(modelVersion));

    const state = getState();

    // Whenever the model version is changed, we need to make sure that the JSON editor
    // reflects the active device type model version's attributes.
    const isRegionalApiModel = DeviceTypeWizardSelectors.isRegionalApiSelected(state);

    if (isRegionalApiModel) {
      return dispatch(updateDeviceType(DeviceTypeWizardSelectors.getDeviceTypeRequestV3(state)));
    }

    return dispatch(updateDeviceType(DeviceTypeWizardSelectors.getDeviceTypeRequestV2(state)));
  };

export const updateNamespace = (namespace: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    // The device type namespace cannot be modified in edit mode
    if (DeviceTypeWizardSelectors.isEditModeActive(state)) {
      return Promise.resolve();
    }

    dispatch(setErrorMessage());

    return dispatch(setNamespace(namespace));
  };

export const updateName = (name: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    // The device type name cannot be modified in edit mode
    if (DeviceTypeWizardSelectors.isEditModeActive(state)) {
      return Promise.resolve();
    }

    dispatch(setErrorMessage());

    return dispatch(setName(name));
  };

export const updateDescription = (description: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();
    const deviceTypeRequest = DeviceTypeWizardSelectors.getDeviceTypeRequest(state);
    const isRegionalApiModel = DeviceTypeWizardSelectors.isRegionalApiSelected(state);

    if (isRegionalApiModel) {
      const deviceTypeRequestV3 = deviceTypeRequest as DeviceTypeRequestV3;
      return dispatch(updateDeviceType(new DeviceTypeRequestV3({
        ...deviceTypeRequestV3.toJS(),
        description,
      })));
    }

    const deviceTypeRequestV2 = deviceTypeRequest as DeviceTypeRequestV2;
    return dispatch(updateDeviceType(new DeviceTypeRequestV2({
      ...deviceTypeRequestV2.toJS(),
      description,
    })));
  };

export const updateTags = (tags: string[] = []) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();
    const deviceTypeRequest = DeviceTypeWizardSelectors.getDeviceTypeRequest(state);
    const isRegionalApiModel = DeviceTypeWizardSelectors.isRegionalApiSelected(state);

    if (isRegionalApiModel) {
      const deviceTypeRequestV3 = deviceTypeRequest as DeviceTypeRequestV3;
      return dispatch(updateDeviceType(new DeviceTypeRequestV3({
        ...deviceTypeRequestV3.toJS(),
        tags,
      })));
    }

    const deviceTypeRequestV2 = deviceTypeRequest as DeviceTypeRequestV2;
    const deviceTypeRequestV2Attrs = deviceTypeRequestV2.toJS();
    const { metadata = {} } = deviceTypeRequestV2Attrs;
    return dispatch(updateDeviceType(new DeviceTypeRequestV2({
      ...deviceTypeRequestV2Attrs,
      metadata: {
        ...metadata,
        tags,
      },
    })));
  };

export const updateGroups = (groups: string[] = []) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();
    const deviceTypeRequest = DeviceTypeWizardSelectors.getDeviceTypeRequest(state);
    const isRegionalApiModel = DeviceTypeWizardSelectors.isRegionalApiSelected(state);

    if (isRegionalApiModel) {
      const deviceTypeRequestV3 = deviceTypeRequest as DeviceTypeRequestV3;
      const deviceTypeRequestV3Attrs = deviceTypeRequestV3.toJS();
      const { security = {} } = deviceTypeRequestV3Attrs;
      const { authorization = {} } = security;
      return dispatch(updateDeviceType(new DeviceTypeRequestV3({
        ...deviceTypeRequestV3Attrs,
        security: {
          ...security,
          authorization: {
            ...authorization,
            groups: groups.map(name => ({ name })),
          },
        },
      })));
    }

    const deviceTypeRequestV2 = deviceTypeRequest as DeviceTypeRequestV2;
    const deviceTypeRequestV2Attrs = deviceTypeRequestV2.toJS();
    const { metadata = {} } = deviceTypeRequestV2Attrs;
    return dispatch(updateDeviceType(new DeviceTypeRequestV2({
      ...deviceTypeRequestV2Attrs,
      metadata: {
        ...metadata,
        groups,
      },
    })));
  };

export const updateSchemas = (schemas: string[] = []) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();
    const deviceTypeRequest = DeviceTypeWizardSelectors.getDeviceTypeRequest(state);
    const isRegionalApiModel = DeviceTypeWizardSelectors.isRegionalApiSelected(state);

    if (isRegionalApiModel) {
      const deviceTypeRequestV3 = deviceTypeRequest as DeviceTypeRequestV3;
      const deviceTypeRequestV3Attrs = deviceTypeRequestV3.toJS();
      const { data = {} } = deviceTypeRequestV3Attrs;
      const { configuration = [] } = data;

      // Do not overwrite previously entered schema restrictions - possibly added from json editor
      const restrictions = configuration
        .reduce((combined: { [key: string]: string }, config: DeviceTypeModelV3ConfigurationAttributes) => {
          const { schema, restriction } = config;
          combined[schema] = restriction;
          return combined;
        }, {});

      return dispatch(updateDeviceType(new DeviceTypeRequestV3({
        ...deviceTypeRequestV3Attrs,
        data: {
          ...data,
          configuration: schemas.map(schema => ({
            schema,
            ...(!restrictions.hasOwnProperty(schema) ? ({}) : ({
              restriction: restrictions[schema],
            })),
          })),
        },
      })));
    }

    const deviceTypeRequestV2 = deviceTypeRequest as DeviceTypeRequestV2;
    const deviceTypeRequestV2Attrs = deviceTypeRequestV2.toJS();
    const { metadata = {} } = deviceTypeRequestV2Attrs;
    return dispatch(updateDeviceType(new DeviceTypeRequestV2({
      ...deviceTypeRequestV2Attrs,
      metadata: {
        ...metadata,
        schemas,
      },
    })));
  };

export const updateSecretSchema = (schemaMetadata: JsonSchemaMetadata = JsonSchemaMetadata.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const deviceType = DeviceTypeWizardSelectors.getDeviceTypeRequestV2(getState());

    const secretsSchema = schemaMetadata.getId();

    return dispatch(updateDeviceType(new DeviceTypeRequestV2({
      ...deviceType.toJS(),
      metadata: {
        ...deviceType.metadata,
        secretsSchema,
      },
    })));
  };

export const setCurrentStep = (step: DeviceTypeWizardStep) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const disabledSteps = DeviceTypeWizardSelectors.getDisabledSteps(state);

    if (DeviceTypeWizardStep.NONE === step || disabledSteps.indexOf(step) >= 0) {
      return Promise.resolve();
    }

    // Make sure that the user did not delete security credential definitions that had corresponding
    // device authentication entries; if they did, block them from leaving the json editor until
    // all of the device authentication entries have corresponding security credential definitions.
    if (DeviceTypeWizardSelectors.isDeviceAuthenticationJsonEditorErrorRequired(state)) {
      return dispatch(setErrorMessage(
        "Device authentication entries must have a corresponding security credential definition " +
        "with the same name as the device authentication credential name"));
    }

    dispatch(setErrorMessage());
    return dispatch(setDeviceTypeWizardStep(step));
  };

export const showNextStep = () => (dispatch: any, getState: () => AppSchema) => {

  return dispatch(setCurrentStep(DeviceTypeWizardSelectors.getNextStep(getState())));
};

export const showPreviousStep = () => (dispatch: any, getState: () => AppSchema) => {

  return dispatch(setCurrentStep(DeviceTypeWizardSelectors.getPreviousStep(getState())));
};

export const save = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  if (DeviceTypeWizardSelectors.isEditModeActive(state)) {
    return dispatch(EditModeActions.save());
  }

  const isRegionalApiModel = DeviceTypeWizardSelectors.isRegionalApiSelected(state);
  const modelVersion = DeviceTypeWizardSelectors.getModelVersion(state);
  const accessToken = DeviceTypeWizardSelectors.getAccessToken(state);
  const namespace = DeviceTypeWizardSelectors.getNamespace(state);
  const name = DeviceTypeWizardSelectors.getName(state);
  const json = DeviceTypeWizardSelectors.getJson(state);

  const createDeviceType = () => {
    if (isRegionalApiModel) {
      return DeviceTypeClient.createDeviceTypeV3(accessToken, namespace, name, json);
    } else {
      return DeviceTypeClient.createDeviceTypeV2(accessToken, namespace, name, json);
    }
  };

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setErrorMessage());
  dispatch(createDeviceTypeRequest(modelVersion));

  return createDeviceType()
    .then((response: ETagLocationHeaders) => {

      const { etag, nameAndVersion = `${namespace}:${name}:1` } = response;

      dispatch(createDeviceTypeSuccess(modelVersion));
      dispatch(setEtag(etag));
      dispatch(setDeviceTypeRef(nameAndVersion));
      dispatch(setSuccessMessage("Device Type Created"));
      return dispatch(hideLoadingIndicator());

    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to create device type" } = response;

      dispatch(createDeviceTypeFailed(`${modelVersion}:${analytic}`));
      dispatch(setErrorMessage(error));

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideLoadingIndicator());
    });
};

export const cloneDeviceType = (deviceTypeV3: DeviceTypeModelV3,
                                deviceTypeV2: DeviceTypeModelV2 = DeviceTypeModelV2.EMPTY) =>
  (dispatch: any) => {

    const modelVersion = deviceTypeV3.getModelVersion();

    const deviceTypeRequestV3 = DeviceTypeRequestV3.from(deviceTypeV3);

    const deviceTypeRequestV2 = deviceTypeV2.hasNameAndVersion()
        ? DeviceTypeRequestV2.from(deviceTypeV2)
        : DeviceTypeRequestV2.fromDeviceTypeRequestV3(deviceTypeRequestV3);

    const deviceTypeRequestV3Attrs = deviceTypeRequestV3.toJS();

    const deviceTypeRequestV2Attrs = deviceTypeRequestV2.toJS();

    const deviceTypeRequestAttrs = DeviceTypeModelVersion.HISTORICAL === modelVersion
      ? deviceTypeRequestV2Attrs : deviceTypeRequestV3Attrs;

    const namespace = deviceTypeV3.getNamespace();

    const name = `${deviceTypeV3.getName()}-clone`;

    const json = JSON.stringify(deviceTypeRequestAttrs, null, "  ");

    dispatch(reset());
    dispatch(showNameView());
    dispatch(setModelVersion(modelVersion));
    dispatch(setNamespace(namespace));
    dispatch(setName(name));
    dispatch(setDeviceTypeRequestAttributes(deviceTypeRequestV3Attrs));
    dispatch(setDeviceTypeRequestV2Attributes(deviceTypeRequestV2Attrs));
    dispatch(setJson(json));
    return dispatch(setShowCloneMode(true));
  };
