import React from "react";
import classnames from "classnames";
import { noop } from "@util";
import { SortedSearchResultsListActions, SortedSearchResultsListModel } from "@components/sorted-search-results-list";
import ModuleListView from "@components/module-list-view";
import { ActionMenuItem } from "@components/actions-menu";
import DataSetsListColumn from "./models/DataSetsListColumn";
import DataSetsListItemData from "./models/DataSetsListItemData";
import { DataSetAction } from "@components/data-set-actions-menu";
import useSortedSearchResults from "./hooks/useSortedSearchResults";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import mapDataSetTypeToOptionName from "./helpers/mapDataSetTypeToOptionName";
import styles, { PrimaryIcon } from "./styles";
import Account from "./components/Account";
import NameAndDescription from "./components/NameAndDescription";

type Item = DataSetsListItemData;
type Column = DataSetsListColumn;

export const DEFAULT_DATA_SETS_LIST_COLUMNS: Column[] = [
  DataSetsListColumn.NAME,
  DataSetsListColumn.ACCOUNT,
  DataSetsListColumn.GROUP,
  DataSetsListColumn.TYPE,
  DataSetsListColumn.TAGS,
  DataSetsListColumn.PII
];

export const DEFAULT_COLUMN_TOOLTIPS = {
  [DataSetsListColumn.PII]: "Personal Identifiable Information",
};

export interface DataSetsListModel extends SortedSearchResultsListModel<Item, Column> {
  className?: string;
  target?: string;
  rel?: string;
}

export interface DataSetsListActions extends SortedSearchResultsListActions<Item, Column> {
  showDataSetDetails?: (dataSetId: string) => void;
  requestDataSetAccess?: (dataSetId: string) => void;
}

type Model = DataSetsListModel;
type Actions = DataSetsListActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataSetsList = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    listClassName,
    loadMoreButtonClassName,
    rel,
    target,
    items = [],
    selectedItems = [],
    tableLayoutFixed = true,
    columns = DEFAULT_DATA_SETS_LIST_COLUMNS,
    columnTooltips = DEFAULT_COLUMN_TOOLTIPS,
    nameFilter: initialNameFilter = "",
    nameFilterDelay = 250,
    sortByColumn: initialSortByColumn = DataSetsListColumn.NONE,
    sortOrderAscending: initialSortOrderAscending = true,
    noResultsLabel = "No data sets found",
    noSearchResultsLabel = "No data sets found",
    showDataSetDetails = noop,
    onClickItem = React.useCallback((item: DataSetsListItemData) =>
      showDataSetDetails(item.getId()), [showDataSetDetails]),
    requestDataSetAccess = noop,
    onClickAction = noop,
    setNameFilter: updateNameFilter = noop,
    setSortByColumn: updateSortByColumn = noop,
    setSortOrderAscending: updateSortOrderAscending = noop,
    children,
    ...otherProps
  } = props;

  const [nameFilter, setNameFilter] = React.useState(initialNameFilter);
  const [sortByColumn, setSortByColumn] = React.useState(initialSortByColumn);
  const [sortOrderAscending, setSortOrderAscending] = React.useState(initialSortOrderAscending);

  const dataSets = useSortedSearchResults({ items, sortByColumn, sortOrderAscending, nameFilter });

  const requestAccess = React.useCallback((id: string)  => {
    requestDataSetAccess(id);
  }, [requestDataSetAccess]);

  const onActionClicked = React.useCallback((item: Item, action: ActionMenuItem) => {
    switch (action.id) {
      case DataSetAction.REQUEST_ACCESS:
        return requestAccess(item.getId());
      default:
        return onClickAction(item, action);
    }
  }, [requestAccess, onClickAction]);

  // Typescript complains without declaring this function instead of just providing setSortByColumn
  const setSortByColumnHandler = React.useCallback((column: Column) => setSortByColumn(column), [setSortByColumn]);

  // In case parent is maintaining state, make sure local state changes are propagated up
  React.useEffect(() => {
    updateNameFilter(nameFilter);
  }, [nameFilter, updateNameFilter]);

  // In case parent is maintaining state, make sure local state changes are propagated up
  React.useEffect(() => {
    updateSortByColumn(sortByColumn);
  }, [sortByColumn, updateSortByColumn]);

  // In case parent is maintaining state, make sure local state changes are propagated up
  React.useEffect(() => {
    updateSortOrderAscending(sortOrderAscending);
  }, [sortOrderAscending, updateSortOrderAscending]);

  const moduleListItems: ListViewItem<DataSetsListItemData>[] = React.useMemo(() =>
    dataSets.map(item => {
      return {
        item: item,
        rel: rel,
        target: target,
        pathToDetailsView: item.getHref(),
        icon: PrimaryIcon,
        columnAttributes: createColumns([
          {
            className: "dataSetName",
            value: item.getName(),
            column: DataSetsListColumn.NAME,
            firstColumn: true,
            customEl: (
              <NameAndDescription
                className="dataSetName"
                name={item.getName()}
                description={item.getDescription()}
                href={item.getHref()}
                rel={rel}
                target={target}
              />
            ),
          },
          {
            className: "dataSetGroup",
            value: item.getDataSetGroup(),
            column: DataSetsListColumn.GROUP,
          },
          {
            className: "dataSetAccount",
            value: item.getAccount(),
            column: DataSetsListColumn.ACCOUNT,
            customEl: (
              <Account
                className="dataSetAccount"
                account={item.getAccountId()}
                alias={item.getAccountAlias()}
              />
            ),
          },
          {
            className: classnames("dataSetType", classes.dataSetType),
            value: mapDataSetTypeToOptionName(item.getDataSetType()),
            column: DataSetsListColumn.TYPE,
          },
          {
            className: "dataSetContainsPII",
            checkmark: item.getContainsPII(),
            value: item.getContainsPII(),
            column: DataSetsListColumn.PII,
          },
          {
            className: "dataSetTags",
            chipTags: item.getTags(),
            column: DataSetsListColumn.TAGS,
          },
        ])
      };
    }), [dataSets]);

  return (
    <React.Fragment>
      <ModuleListView
        {...otherProps}
        className={classnames("dataSetsList", className, classes.container)}
        listClassName={classnames("list", listClassName, classes.list, {
          [classes.staticLayout]: tableLayoutFixed,
        })}
        loadMoreButtonClassName={classnames("loadMoreButton", loadMoreButtonClassName, classes.loadMoreButton)}
        summaryViewIcon={PrimaryIcon}
        columns={columns}
        columnTooltips={columnTooltips}
        listViewItems={moduleListItems}
        selectedItems={selectedItems}
        tableLayoutFixed={tableLayoutFixed}
        sortByColumn={sortByColumn}
        sortOrderAscending={sortOrderAscending}
        noResultsLabel={noResultsLabel}
        noSearchResultsLabel={noSearchResultsLabel}
        nameFilter={nameFilter}
        nameFilterDelay={nameFilterDelay}
        onClickItem={onClickItem}
        onClickAction={onActionClicked}
        setNameFilter={setNameFilter}
        setSortByColumn={setSortByColumnHandler}
        setSortOrderAscending={setSortOrderAscending}
      />
      {children}
    </React.Fragment>
  );
});

export default DataSetsList;
