import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base";

export interface DataSetRequestsSchema extends BasePortalModuleSchema {
  nameFilter: string;
  filterByCurrentUser: boolean;
  filterByPendingApproval: boolean;
  reloadItemsRequested: boolean;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<DataSetRequestsSchema>({
  showEmptyView: false,
  nameFilter: "",
  filterByCurrentUser: true,
  filterByPendingApproval: false,
  reloadItemsRequested: false,
});

type DataSetRequestsActionTypes = PortalModuleActionTypes<DataSetRequestsSchema>;

export const ACTION_TYPES: DataSetRequestsActionTypes =
  createPortalModuleActionTypes<DataSetRequestsSchema>(MODULE_ID, DEFAULT_STATE);

export const DataSetRequestsReducer = combineReducers<DataSetRequestsSchema>(
  createPortalModuleReducers<DataSetRequestsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DataSetRequestsReducer;
