import { OrderedMap } from "immutable";
import { createSelector } from "reselect";
import { SCHEMA_KEY } from "./constants";
import { AppSchema } from "@schemas";
import { SchemaItems, SchemasModuleSchema } from "./reducers";
import { isEmptyString, withModuleSelector } from "../../util";
import {
  JsonSchemaMetadata,
  JsonSchemaMetadataAttributes,
  SearchFilter,
  SearchFilterAttributes,
} from "../../data";

type Schema = SchemasModuleSchema;
type Selector<T> = (state: AppSchema) => T;

const createModuleSelector = <T>(key: keyof Schema): Selector<T> =>
  withModuleSelector(SCHEMA_KEY, key);

export const getNameFilter = createModuleSelector<string>("nameFilter");

export const getSearchFilters: Selector<SearchFilter[]> = createSelector(
  createModuleSelector<SearchFilterAttributes[]>("searchFilters"),
  (searchFilters: SearchFilterAttributes[]) =>
    searchFilters.map(attrs => new SearchFilter(attrs)));

export const getErrorMessage = createModuleSelector<string>("errorMessage");
export const isNotFoundVisible = createModuleSelector<boolean>("showNotFound");
export const isAccessDeniedVisible = createModuleSelector<boolean>("showAccessDenied");
export const isEmptyViewVisible = createModuleSelector<boolean>("showEmptyView");
export const isLoadingIndicatorVisible = createModuleSelector<boolean>("showLoadingIndicator");
export const getSchemaAttributes = createModuleSelector<SchemaItems>("items");
export const getNextPage = createModuleSelector<string>("nextPage");

export const getSchemasMap: (state: AppSchema) => OrderedMap<string, JsonSchemaMetadata> =
  createSelector(getSchemaAttributes, (schemaAttributes: SchemaItems) =>
    OrderedMap<string, JsonSchemaMetadataAttributes>(schemaAttributes)
      .map((attrs: JsonSchemaMetadataAttributes) => new JsonSchemaMetadata(attrs))
      .toOrderedMap());

export const getSchemas: (state: AppSchema) => JsonSchemaMetadata[] = createSelector(
  getSchemasMap, (schemas: OrderedMap<string, JsonSchemaMetadata>) => schemas.toArray());

export const getSchemaById = (id: string, state: AppSchema): JsonSchemaMetadata => {
  return getSchemasMap(state).get(id, JsonSchemaMetadata.EMPTY);
};

export const getNumSchemas: (state: AppSchema) => number = createSelector(
  getSchemas, (schemas: JsonSchemaMetadata[]) => schemas.length);

export const hasNextPage: (state: AppSchema) => boolean = createSelector(
  getNextPage, (nextPageToken: string) => !isEmptyString(nextPageToken));

export const isSchemasListEmpty: (state: AppSchema) => boolean = createSelector(
  getNumSchemas, (numSchemas: number) => numSchemas === 0);

export const isErrorViewVisible: (state: AppSchema) => boolean = createSelector(
  getErrorMessage, (errorMessage: string) => !isEmptyString(errorMessage));

export const isLoadingViewVisible: (state: AppSchema) => boolean = createSelector(
  [isLoadingIndicatorVisible, isErrorViewVisible],
  (isLoading: boolean, showErrorView: boolean) =>
    isLoading && !showErrorView);

export const isNoResultsViewVisible: (state: AppSchema) => boolean = createSelector(
  [isSchemasListEmpty, isErrorViewVisible, isLoadingIndicatorVisible],
  (isEmpty: boolean, showErrorView: boolean, isLoading: boolean) =>
    isEmpty && !showErrorView && !isLoading);

export const isLoadMoreButtonVisible: (state: AppSchema) => boolean = createSelector(
  [hasNextPage, isErrorViewVisible, isLoadingIndicatorVisible],
  (canLoadMore: boolean, showErrorView: boolean, isLoading: boolean) =>
    canLoadMore && !showErrorView && !isLoading);
