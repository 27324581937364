import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";

export enum DataSetType {
  NONE = "",
  DATA_LAKE_ACQUIRED = "DATA_LAKE_ACQUIRED",
  DATA_LAKE_BLOBS = "DATA_LAKE_BLOBS",
  DATA_WAREHOUSE_SYSTEM = "DATA_WAREHOUSE_SYSTEM",
  DATA_WAREHOUSE_SHARED = "DATA_WAREHOUSE_SHARED",
}

export enum DataSetState {
  NONE = "",
  PENDING_IOT_APPROVAL = "PENDING_IOT_APPROVAL",
  PENDING_PRIVACY_TNC_UPLOAD = "PENDING_PRIVACY_TNC_UPLOAD",
  ADDITIONAL_INFORMATION_REQUESTED = "ADDITIONAL_INFORMATION_REQUESTED",
  PENDING_BUSINESS_CUSTOMER_APPROVALS = "PENDING_BUSINESS_CUSTOMER_APPROVALS",
  BO_PENDING = "BO_PENDING",
  BO_APPROVED = "BO_APPROVED",
  DO_PENDING = "DO_PENDING",
  DO_APPROVED = "DO_APPROVED",
  READY_FOR_PROVISIONING = "READY_FOR_PROVISIONING",
  PROVISIONING_IN_PROGRESS = "PROVISIONING_IN_PROGRESS",
  PENDING_INGESTION_MECHANISM_SELECTION = "PENDING_INGESTION_MECHANISM_SELECTION",
  READY_FOR_INGESTION = "READY_FOR_INGESTION",
  REJECTED = "REJECTED",
  SUSPENDED = "SUSPENDED",
  DEPRECATED = "DEPRECATED",
  DELETED = "DELETED",
  CANCELLED = "CANCELLED",
}

export enum IngestionMechanism {
  NONE = "",
  MICRO_BATCH = "microbatch",
  MACRO_BATCH = "macrobatch",
  MQTT = "mqtt",
}

export enum DataClassification {
  SECRET= "SECRET",
  CONFIDENTIAL = "CONFIDENTIAL",
  INTERNAL = "INTERNAL",
}

export interface DataRate {
  dataVolume: number;
  dataVelocity: number;
}

export interface LifecyclePolicy {
  archivingInDays: number;
  expirationInDays: number;
}

export interface DataSetRequestAttributes {
  dataSetRequestId: string;
  dataSetAlias: string;
  accountId: string;
  description: string;
  dataSetGroup: string;
  dataSetType: DataSetType;
  status: DataSetState;
  dataOwners?: string[];
  architects?: string[];
  engineeringContacts?: string[];
  businessOwners?: string[];
  tags?: string[];
  ingestionMechanism?: IngestionMechanism;
  schema?: string;
  sampleAnonymizedData?: string;
  provenance?: string;
  dataClassification?: DataClassification;
  sourceGeographies?: string;
  sharedGeographies?: string;
  dataOwnerApprovalStatus?: DataSetState;
  businessOwnerApprovalStatus?: DataSetState;
  dataSetRequestedBy?: string;
  dataSetRequestedAtTime?: string;
  dataRate?: DataRate;
  lifecyclePolicy?: LifecyclePolicy;
  confirmNonProductionData?: boolean;
  termsConditionsId?: string;
  containsPII: boolean;
  piiComment?: string;
}

export class DataSetRequest extends Record({
  dataSetRequestId: "",
  dataSetAlias: "",
  accountId: "",
  description: "",
  dataSetGroup: "",
  dataSetType: DataSetType.DATA_LAKE_ACQUIRED,
  status: DataSetState.NONE,
  dataOwners: [],
  architects: [],
  engineeringContacts: [],
  businessOwners: [],
  tags: [],
  ingestionMechanism: IngestionMechanism.NONE,
  schema: "",
  sampleAnonymizedData: "",
  provenance: "N/A",
  dataClassification: DataClassification.INTERNAL,
  sourceGeographies: "N/A",
  sharedGeographies: "N/A",
  dataOwnerApprovalStatus: DataSetState.READY_FOR_INGESTION,
  businessOwnerApprovalStatus: DataSetState.READY_FOR_INGESTION,
  dataSetRequestedBy: "",
  dataSetRequestedAtTime: "",
  dataRate: {
    dataVolume: 0,
    dataVelocity: 0,
  },
  lifecyclePolicy: {
    archivingInDays: 0,
    expirationInDays: 0,
  },
  confirmNonProductionData: true,
  termsConditionsId: "",
  containsPII: false,
  piiComment: "",
}) implements DataSetRequestAttributes {

  public static EMPTY: DataSetRequest = new DataSetRequest();

  public readonly dataSetRequestId: string;
  public readonly accountId: string;
  public readonly dataSetAlias: string;
  public readonly dataSetType: DataSetType;
  public readonly status: DataSetState;
  public readonly description: string;
  public readonly dataSetGroup: string;
  public readonly dataOwners: string[];
  public readonly architects: string[];
  public readonly engineeringContacts: string[];
  public readonly businessOwners: string[];
  public readonly tags: string[];
  public readonly ingestionMechanism: IngestionMechanism;
  public readonly schema: string;
  public readonly sampleAnonymizedData: string;
  public readonly provenance: string;
  public readonly dataClassification: DataClassification;
  public readonly sourceGeographies: string;
  public readonly sharedGeographies: string;
  public readonly dataOwnerApprovalStatus: DataSetState.READY_FOR_INGESTION;
  public readonly businessOwnerApprovalStatus: DataSetState.READY_FOR_INGESTION;
  public readonly dataSetRequestedBy: string;
  public readonly dataSetRequestedAtTime: string;
  public readonly dataRate: DataRate;
  public readonly lifecyclePolicy: LifecyclePolicy;
  public readonly confirmNonProductionData: boolean;
  public readonly termsConditionsId: string;
  public readonly containsPII: boolean;
  public readonly piiComment?: string;

  public getDataSetRequestId(): string {
    return getStringValue(this.dataSetRequestId);
  }

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getDataSetAlias(): string {
    return getStringValue(this.dataSetAlias);
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public getDataSetGroup(): string {
    return getStringValue(this.dataSetGroup);
  }

  public getDataSetType(): DataSetType {
    return this.dataSetType;
  }

  public getStatus(): DataSetState {
    return this.status;
  }

  public usersToString(users: string[]): string {
    return users.map(user => user.split(":").pop()).join(", ");
  }

  public getDataOwners(): string[] {
    const dataOwners = Array.isArray(this.dataOwners) ? this.dataOwners : [];
    return dataOwners.map(getStringValue);
  }

  public getDataOwnersToString(): string {
    return this.usersToString(this.getDataOwners());
  }

  public getArchitects(): string[] {
    const architects = Array.isArray(this.architects) ? this.architects : [];
    return architects.map(getStringValue);
  }

  public getArchitectsToString(): string {
    return this.usersToString(this.getArchitects());
  }

  public getEngineeringContacts(): string[] {
    const engineeringContacts = Array.isArray(this.engineeringContacts) ? this.engineeringContacts : [];
    return engineeringContacts.map(getStringValue);
  }

  public getEngineeringContactsToString(): string {
    return this.usersToString(this.getEngineeringContacts());
  }

  public getBusinessOwners(): string[] {
    const businessOwners = Array.isArray(this.businessOwners) ? this.businessOwners : [];
    return businessOwners.map(getStringValue);
  }

  public getBusinessOwnersToString(): string {
    return this.usersToString(this.getBusinessOwners());
  }

  public getTags(): string[] {
    const tags = Array.isArray(this.tags) ? this.tags : [];
    return tags.map(getStringValue);
  }

  public getTagsToString(): string {
    return this.getTags().join(", ");
  }

  public getIngestionMechanism(): IngestionMechanism {
    return this.ingestionMechanism;
  }

  public getSchema(): string {
    return getStringValue(this.schema);
  }

  public getSampleAnonymizedData(): string {
    return getStringValue(this.sampleAnonymizedData);
  }

  public getProvenance(): string {
    return getStringValue(this.provenance);
  }

  public getDataClassification(): DataClassification {
    return this.dataClassification;
  }

  public getSourceGeographies(): string {
    return getStringValue(this.sourceGeographies);
  }

  public getSharedGeographies(): string {
    return getStringValue(this.sharedGeographies);
  }

  public getDataOwnerApprovalStatus(): DataSetState {
    return this.dataOwnerApprovalStatus;
  }

  public getBusinessOwnerApprovalStatus(): DataSetState {
    return this.businessOwnerApprovalStatus;
  }

  public getDataSetRequestedBy(): string {
    return getStringValue(this.dataSetRequestedBy);
  }

  public getDataSetRequestedAtTime(): string {
    return getStringValue(this.dataSetRequestedAtTime);
  }

  public getDataRate(): DataRate {

    const { dataVolume = 0, dataVelocity = 0 } = this.dataRate || {};

    return {
      dataVolume,
      dataVelocity,
    };
  }

  public getDataVolume(): number {
    const { dataVolume = 0 } = this.getDataRate();
    return dataVolume;
  }

  public getDataVelocity(): number {
    const { dataVelocity = 0 } = this.getDataRate();
    return dataVelocity;
  }

  public getLifecyclePolicy(): LifecyclePolicy {

    const { archivingInDays = 0, expirationInDays = 0 } = this.lifecyclePolicy || {};

    return {
      archivingInDays,
      expirationInDays,
    };
  }

  public getArchivingInDays(): number {
    const { archivingInDays = 0 } = this.getLifecyclePolicy();
    return archivingInDays;
  }

  public getExpirationInDays(): number {
    const { expirationInDays = 0 } = this.getLifecyclePolicy();
    return expirationInDays;
  }

  public isConfirmedNonProductionData(): boolean {
    return this.confirmNonProductionData;
  }

  public getTermsConditionsId(): string {
    return getStringValue(this.termsConditionsId);
  }

  public hasTermsConditionsId(): boolean {
    return !isEmptyString(this.getTermsConditionsId());
  }

  public isPendingPrivacyTncUpload(): boolean {
    return DataSetState.PENDING_PRIVACY_TNC_UPLOAD === this.getStatus();
  }

  public isRequestIotApproved(): boolean {
    return DataSetState.PENDING_IOT_APPROVAL !== this.getStatus()
      && DataSetState.PENDING_PRIVACY_TNC_UPLOAD !== this.getStatus();
  }

  public getContainsPII(): boolean {
    return this.containsPII;
  }

  public getPiiComment(): string {
    return this.piiComment ? this.piiComment : "";
  }
}

export default DataSetRequest;
