import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import {
  refresh,
  setBatchId,
  setErrorMessage,
  clearDeviceEnrollments,
  fetchDeviceEnrollmentStatus,
} from "../actions";
import {
  getBatchId,
  isLoadingIndicatorVisible,
} from "../selectors";
import { DeviceEnrollmentStatus } from "@data";
import DeviceEnrollment , { Actions, Model } from "../components/DeviceEnrollment";
import DeviceEnrollmentList from "./DeviceEnrollmentList";
import debounce from "lodash/debounce";
import { equalsIgnoreCase, getStringValue } from "@util";

interface ContainerActions extends Actions {
  showDeviceEnrollmentDetails?: (deviceEnrollment: DeviceEnrollmentStatus) => void;
}

type Props = Model & ContainerActions;

const DeviceEnrollmentContainer = (props: Props) => {

  const {
    showDeviceEnrollmentDetails,
    ...otherProps
  } = props;

  return (
    <DeviceEnrollment {...otherProps}>
      <DeviceEnrollmentList
        showDeviceEnrollmentDetails={showDeviceEnrollmentDetails}
      />
    </DeviceEnrollment>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  searchQuery: getBatchId(state),
  loading: isLoadingIndicatorVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => {
  // Debounce the network request to fetch device types that match the search query as user types
  const search = debounce(() => dispatch(fetchDeviceEnrollmentStatus()), 500);

  return {
    updateSearchQuery: (searchQuery: string = "", previousSearchQuery: string = "") => {
      dispatch(clearDeviceEnrollments());
      dispatch(setErrorMessage());
      dispatch(setBatchId(getStringValue(searchQuery)));

      if (!equalsIgnoreCase(searchQuery, previousSearchQuery)) {
        search();
      }
    },
    refresh: () => dispatch(refresh()),
    ...ownProps,
  };
};

export default connect<Model, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceEnrollmentContainer);
