import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { Model, SummaryView } from "../components/SummaryView";
import {
  getApprovers,
  getRequestedBy,
  getDataApprovalRequest,
  getDeviceTypeIdentifier,
} from "../selectors";
import { isSourceDataSetRequest } from "../helpers";
import DataSetRequestView from "./DataSetRequestView";
import DataAccessRequestView from "./DataAccessRequestView";
import { getCurrentAccountId } from "@main/selectors";

interface ContainerModel extends Model {
  showDataSetRequest?: boolean;
  showDataAccessRequest?: boolean;
}
const SummaryViewContainer = (props: ContainerModel) => {

  const {
    showDataSetRequest,
    showDataAccessRequest,
  } = props;

  return (
    <SummaryView {...props}>
      {showDataSetRequest && <DataSetRequestView/>}
      {showDataAccessRequest && <DataAccessRequestView/>}
    </SummaryView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => {

  const request = getDataApprovalRequest(state);
  const currentAccountId = getCurrentAccountId(state);
  const accountId = request.accountId;

  return {
    accountId,
    showAccountId: accountId !== currentAccountId,
    dataSetAlias: request.dataSetAlias,
    requestType: request.requestType,
    requestState: request.requestState,
    approvers: getApprovers(state),
    deviceTypeIdentifier: getDeviceTypeIdentifier(state),
    requestedAt: request.requestedAt,
    requestedBy: getRequestedBy(state),
    showDataSetRequest: isSourceDataSetRequest(request.requestType),
    showDataAccessRequest: !isSourceDataSetRequest(request.requestType),
    ...ownProps,
    };
};

export default connect<Model, {}, Model>(
  mapStateToProps,
)(SummaryViewContainer);
