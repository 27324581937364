import AppSchema from "@AppSchema";
import { createActions } from "@base/createActions";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { getAccessToken, getDashboardId } from "@modules/publishDashboard/selectors";
import { DashboardSecurityClient, RestClientError } from "@network";
import { fetchAllDashboardsRequest, fetchDashboards } from "@modules/dashboards/actions";

export const {
  dashboardId: setDashboardId,
  publishable: setPublishable,
  publishedDashboardId: setPublishedDashboardId,
  publishDashboard: setPublishDashboard,
  setErrorMessage,
  setSuccessMessage,
  showLoadingIndicator,
  hideLoadingIndicator,
  showAccessDenied,
  hideAccessDenied,
  PUBLISH_DASHBOARD_REQUEST: publishDashboardRequest,
  PUBLISH_DASHBOARD_SUCCESS: publishDashboardSuccess,
  PUBLISH_DASHBOARD_FAILED: publishDashboardFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setPublishDashboard());
  return dispatch(baseReset());
};

export const close = reset;

export const publishDashboard = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const dashboardId = getDashboardId(state);
  const accessToken = getAccessToken(state);

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(publishDashboardRequest());

  return DashboardSecurityClient.publishDashboard(accessToken, dashboardId)
      .then(() => {
        dispatch(setPublishedDashboardId(dashboardId));
        dispatch(publishDashboardSuccess());
        dispatch(hideLoadingIndicator());
        dispatch(fetchAllDashboardsRequest());
        dispatch(setSuccessMessage("Dashboard has been published"));
        return dispatch(fetchDashboards());

      }, (response: RestClientError) => {

        const { analytic, status, error = "Failed to publish the dashboard" } = response;

        dispatch(publishDashboardFailed(analytic));
        dispatch(hideLoadingIndicator());

        if (status === 403) {
          dispatch(showAccessDenied());
        }

        return dispatch(setErrorMessage(error));
      });
};

export const open = (dashboardId: number, publishable: boolean) => (dispatch: any, getState: () => AppSchema) => {
  dispatch(reset());
  dispatch(setDashboardId(dashboardId));
  dispatch(setPublishable(publishable));
  dispatch(setPublishedDashboardId());
  return dispatch(setPublishDashboard(true));
};

export const initialize = () => (dispatch: any) => {
  return dispatch(reset());
};
