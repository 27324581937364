import { createSelector } from "reselect";
import { AppSchema } from "../../../main/schemas";
import { JsonSchemaProperty, JsonSchemaPropertyAttributes } from "../../../../data";

const getPropertyAttributes = (state: AppSchema): JsonSchemaPropertyAttributes => {
  return state.schemaWizard.removeProperty.property;
};

export const getProperty: (state: AppSchema) => JsonSchemaProperty = createSelector(
  getPropertyAttributes, (propertyAttributes: JsonSchemaPropertyAttributes) =>
    new JsonSchemaProperty(propertyAttributes));
