import { Record } from "immutable";
import {
    EmailParametersResponse,
    EmailRequirementsResponse,
    GetEmailRequirementsResponse
} from "@network";

export class EmailTemplateReqs extends Record({
    messageTypes: {},
    parameters: {},
}) implements GetEmailRequirementsResponse {

    public static EMPTY: EmailTemplateReqs = new EmailTemplateReqs();

    public readonly messageTypes: EmailRequirementsResponse;
    public readonly parameters: EmailParametersResponse;

}

export default EmailTemplateReqs;