import React from "react";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useWorkloadManagerClient } from "@hooks";
import { UploadWorkloadCodeUrl } from "@network";

type SuccessResponse = UploadWorkloadCodeUrl;

export interface UseGetUploadWorkloadCodeUrlProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  workloadName: string;
  version: number;
  fileName: string;
}

export interface UseGetUploadWorkloadCodeUrlModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
  url: string;
}

export interface UseGetUploadWorkloadCodeUrlActions {
  getUploadUrl: () => void;
  reset: () => void;
}

type Props = UseGetUploadWorkloadCodeUrlProps;
type Model = UseGetUploadWorkloadCodeUrlModel;
type Actions = UseGetUploadWorkloadCodeUrlActions;
type Result = [Model, Actions];

export const useGetUploadWorkloadCodeUrl = (props: Props): Result => {

  const {
    workloadName = "",
    version,
    fileName,
    defaultErrorMessage = "Failed to get URL to upload workload code",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.getUploadWorkloadCodeUrlApi(
      workloadName,
      version,
      fileName
    ), [WorkloadManagerClient, workloadName, version, fileName]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: getUploadUrl, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Get Upload URL Successful" : "" ,
    [showSuccessView]);

  const url = React.useMemo(() =>
      successResponse ? successResponse.uploadUrl : "" ,
    [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    url,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    url,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    getUploadUrl,
    reset
  }), [getUploadUrl, reset]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetUploadWorkloadCodeUrl;
