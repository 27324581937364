import React from "react";
import { useParams, useHistory } from "react-router-dom";
import DataSetDetails from "./containers/DataSetDetails";
import { getDataAccessRequestPath } from "@modules/dataAccessRequestWizard/helpers";

export function DataSetDetailsView() {

  const history = useHistory();

  const { id: dataSetRequestId } = useParams<{ id: string }>();

  const requestDataSetAccess = React.useCallback(() =>
    history.push(getDataAccessRequestPath(dataSetRequestId)), [history, dataSetRequestId]);

  return (
    <DataSetDetails
      key={dataSetRequestId}
      requestAccess={requestDataSetAccess}
    />
  );
}

export default DataSetDetailsView;
