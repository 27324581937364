import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginTop: "25px",
      marginRight: "16px",
    },
    stepLabel: {
      "& .MuiStepLabel-label": {
        fontSize: 16,
        fontWeight: 300,
      }
    },
    actionsContainer: {
    },
    resetContainer: {
    },
  });

export default styles;
