import createStyles from "@material-ui/core/styles/createStyles";
import { StyleRules, Theme } from "@material-ui/core/styles";

export const textField: StyleRules<string> = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& input": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      padding: "8px 10px",
      margin: 0,
    },
  },
};

export const passwordInfo = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    inputField: {
      flex: "1 0 auto",
    },
    formHelperTextError: {
      fontSize: 12,
    },
    controls: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 8,
    },
    password: {
      flex: "1 0 auto",
    },
  });

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 16,
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
    emptyViewLabel: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      fontStyle: "italic",
    },
    userId: {
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "16px",
      marginTop: 16,
    },
  });

export default styles;
