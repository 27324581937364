import React from "react";

export type DomEvent = React.MouseEvent<{}> | React.TouchEvent<{}> | React.ChangeEvent<{}> | Event;
export type FormChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;

export function swallow(event?: DomEvent): void {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }
}

export function stopPropagation(event?: DomEvent): void {
  if (event) {
    event.stopPropagation();
  }
}

export function clickHandler(callback?: () => void): (event?: DomEvent) => void {
  return (event?: DomEvent) => {
    swallow(event);
    if (callback) {
      callback();
    }
  };
}

export function clickMultiHandler(callbacks?: (() => void)[]): (event?: DomEvent) => void {
  return (event?: DomEvent) => {
    swallow(event);
    callbacks?.forEach((callback) => {
      if (callback) {
        callback();
      }
    });
  };
}

export function enterKeyHandler(callback?: () => void): (event?: React.KeyboardEvent<{}>) => void {
  return (event?: React.KeyboardEvent<{}>) => {
    if (event && event.keyCode === 13 && callback) {
      swallow(event);
      callback();
    }
  };
}

export function formEventHandler(callback?: (newValue: string) => void) {
  return (event: FormChangeEvent) => {
    if (callback) {
      const newValue = event.target.value;
      callback(newValue);
    }
  };
}

export function formNumberEventHandler(callback?: (newValue: number) => void) {
  return (event: FormChangeEvent) => {
    if (callback) {
      const newValue = Number(event.target.value);
      callback(newValue);
    }
  };
}

export function checkboxEventHandler(callback?: (checked: boolean) => void) {
  return (event: FormChangeEvent, checked: boolean) => {
    if (callback) {
      callback(checked);
    }
  };
}

export function tabEventHandler(callback?: (newValue: string) => void) {
  return (event: React.ChangeEvent<{}>, value: string) => {
    if (callback) {
      callback(value);
    }
  };
}

export const noopClickHandler = clickHandler();

export default {
  swallow,
  clickHandler,
};
