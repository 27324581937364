import React from "react";
import classnames from "classnames";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { schemaData as styles } from "./styles";
import { DeviceDataType, SchemasList, SchemasListColumn } from "@components";
import {
  DeviceTypeModelV3MetadataAttributes,
  DeviceTypeModelV3MetadataMode,
  JsonSchemaMetadata
} from "@data";
import DataEditor from "@modules/deviceDetails/components/DataEditor";
import { noop } from "@util";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Typography from "@material-ui/core/Typography";

export const DEFAULT_COLUMNS: SchemasListColumn[] = [
  SchemasListColumn.NAMESPACE,
  SchemasListColumn.NAME,
  SchemasListColumn.VERSION
];

export interface Model {
  className?: string;
  title?: string;
  selectedSchema?: string;
  schemaIdentities?: string[];
  metadata?: DeviceTypeModelV3MetadataAttributes[];
  json?: string;
  readOnly?: boolean;
  showLoadingIndicator?: boolean;
  invalidJsonError?: boolean;
  isReadWriteSelected?: boolean;
  isDeviceScopeViewActive?: boolean;
  isTypeScopeViewActive?: boolean;
  isRegionScopeViewActive?: boolean;
  dataMode?: DeviceDataType;
  children?: React.ReactNode;
}

export interface Actions {
  showSchemaDetailsInNewTab?: (schema: JsonSchemaMetadata) => void;
  onClickSchema?: (schema: JsonSchemaMetadata) => void;
  setJson?: (json: string) => void;
  editData?: () => void;
  deleteData?: () => void;
  cancelEdit?: () => void;
  confirmEdit?: () => void;
  onChangeIsReadWriteSelected?: (event: any) => void;
  onChangeScope?: (event: any) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SchemaDataView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    schemaIdentities = [],
    metadata = [],
    selectedSchema = "",
    json = "",
    showLoadingIndicator,
    invalidJsonError,
    readOnly =  true,
    isReadWriteSelected,
    isDeviceScopeViewActive,
    isTypeScopeViewActive,
    isRegionScopeViewActive,
    title = "Schemas",
    dataMode,
    children,
    showSchemaDetailsInNewTab,
    onClickSchema,
    setJson = noop,
    editData = noop,
    deleteData = noop,
    cancelEdit = noop,
    confirmEdit = noop,
    onChangeIsReadWriteSelected = noop,
    onChangeScope = noop,
  } = props;

  const actualDesiredSchemas = React.useMemo(() => schemaIdentities.map(identity =>
      JsonSchemaMetadata.fromNameAndVersion(identity))
  , [schemaIdentities]);

  const readOnlyMetadataSchemas = React.useMemo(() => {
    const metadataSchemas = metadata.filter(attrs => attrs.mode === DeviceTypeModelV3MetadataMode.READ_ONLY)
      .map(attrs => JsonSchemaMetadata.fromNameAndVersion(attrs.schema));
    return Array.isArray(metadataSchemas) ? metadataSchemas : [];
  }, [metadata]);

  const readWriteMetadataSchemas = React.useMemo(() => {
    const metadataSchemas = metadata.filter(attrs => attrs.mode !== DeviceTypeModelV3MetadataMode.READ_ONLY)
      .map(attrs => JsonSchemaMetadata.fromNameAndVersion(attrs.schema));
    return Array.isArray(metadataSchemas) ? metadataSchemas : [];
  }, [metadata]);

  const schemas = React.useMemo(() => {
    if (dataMode === DeviceDataType.METADATA) {
      if (isReadWriteSelected) {
        return readWriteMetadataSchemas;
      } else {
        return readOnlyMetadataSchemas;
      }
    } else {
      return actualDesiredSchemas;
    }
  }, [actualDesiredSchemas, dataMode, readWriteMetadataSchemas, readOnlyMetadataSchemas, isReadWriteSelected]);

  const selectedSchemas = React.useMemo(() => JsonSchemaMetadata.fromNameAndVersion(selectedSchema), [selectedSchema]);

  const showMetadataRadioButtons = React.useMemo(() => dataMode === DeviceDataType.METADATA, [dataMode]);
  const showDesiredRadioButtons = React.useMemo(() => dataMode === DeviceDataType.DESIRED, [dataMode]);

  const mode = React.useMemo(() => metadata
      .filter(attrs => attrs.schema === selectedSchema)
      .map(attrs => attrs.mode)
  , [metadata, selectedSchema]);

  const noResultLabel = React.useMemo(() =>
    `There are no ${dataMode} schemas`, [dataMode]);

  const showNoResultView = React.useMemo(() => schemas.length === 0, [schemas]);

  return (
    <div className={classnames("schemaDataView", className, classes.container)}>
      <div className={classnames("schemas", className, classes.schemas)}>
        <label className={classnames("title", classes.title)}>{title}</label>
        {showMetadataRadioButtons && (
          <RadioGroup
            className={classnames("radioGroup", "metadataRadioGroup", classes.radioGroup)}
            row={true}
          >
            <FormControlLabel
              className={classnames("radio", "read-write", classes.radio)}
              label="READ_WRITE"
              control={(
                <Radio
                  value="yes"
                  checked={isReadWriteSelected}
                  disabled={showLoadingIndicator}
                  onChange={onChangeIsReadWriteSelected}
                />
              )}
            />
            <FormControlLabel
              className={classnames("radio", "read-only", classes.radio)}
              label="READ_ONLY"
              control={(
                <Radio
                  value="no"
                  checked={!isReadWriteSelected}
                  disabled={showLoadingIndicator}
                  onChange={onChangeIsReadWriteSelected}
                />
              )}
            />
          </RadioGroup>
        )}
        {showDesiredRadioButtons && (
          <React.Fragment>
            <Typography variant="subtitle1" style={{ margin: "16px 0 8px" }}>
              Select Desired Configuration Scope
            </Typography>
            <RadioGroup
              className={classnames("radioGroup", "desiredRadioGroup", classes.radioGroup)}
              row={true}
            >
              <FormControlLabel
                className={classnames("radio", "device", classes.radio)}
                label="Device"
                control={(
                  <Radio
                    value="device"
                    checked={isDeviceScopeViewActive}
                    disabled={showLoadingIndicator}
                    onChange={onChangeScope}
                  />
                )}
              />
              <FormControlLabel
                className={classnames("radio", "type", classes.radio)}
                label="Type"
                control={(
                  <Radio
                    value="type"
                    checked={isTypeScopeViewActive}
                    disabled={showLoadingIndicator}
                    onChange={onChangeScope}
                  />
                )}
              />
              <FormControlLabel
                className={classnames("radio", "region", classes.radio)}
                label="Region"
                control={(
                  <Radio
                    value="region"
                    checked={isRegionScopeViewActive}
                    disabled={showLoadingIndicator}
                    onChange={onChangeScope}
                  />
                )}
              />
            </RadioGroup>
          </React.Fragment>
        )}
        <SchemasList
          schemas={schemas}
          hideSearch={true}
          hideSummary={true}
          showNoResultsView={showNoResultView}
          noResultsLabel={noResultLabel}
          columns={DEFAULT_COLUMNS}
          onClickItem={onClickSchema}
          onClickShowMoreInfo={showSchemaDetailsInNewTab}
          showMoreInfoLabel="Schema Details"
          selectedItems={[selectedSchemas]}
        />
      </div>
      <div className={classnames("data", className, classes.data)}>
        <DataEditor
          schemaIdentity={selectedSchema}
          mode={mode[0]}
          json={json}
          readOnly={readOnly}
          showLoadingIndicator={showLoadingIndicator}
          invalidJsonError={invalidJsonError}
          dataMode={dataMode}
          setJson={setJson}
          editData={editData}
          deleteData={deleteData}
          cancelEdit={cancelEdit}
          confirmEdit={confirmEdit}
        />
      </div>
      {children}
    </div>
  );
});

export default SchemaDataView;
