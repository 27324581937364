import React from "react";
import { isEmptyString } from "@util";
import { SecurityServiceRegional } from "@data";
import ServicePrincipalView, { Actions, Model } from "../components/ServicePrincipalView";
import useGetServicesRegional from "@hooks/serviceManagerRegional/use-get-services-regional";

interface ContainerModel extends Model {
  serviceId?: string;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const ServicePrincipalContainerView = (props: Props) => {

  const {
    serviceId = "",
    ...otherProps
  } = props;

  const [{ services: items, ...model }, actions] = useGetServicesRegional();

  const selectedItems: SecurityServiceRegional[] = React.useMemo(() =>
      isEmptyString(serviceId) ? [] : [new SecurityServiceRegional({ id: serviceId })],
    [serviceId]);

  return (
    <ServicePrincipalView
      {...otherProps}
      {...model}
      {...actions}
      items={items}
      selectedItems={selectedItems}
    />
  );
};

export default ServicePrincipalContainerView;
