import React from "react";
import classnames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import ShowIcon from "@material-ui/icons/Visibility";
import { IconButton } from "@components";
import HideIcon from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { password as styles, textFieldTooltip } from "./styles";
import { clickHandler, isEmptyString, noop } from "../../../../util";
import LoginTextField from "./LoginTextField";

const TextFieldTooltip = withStyles(textFieldTooltip)(Tooltip);

export const DEFAULT_SHOW_PASSWORD_TOOLTIP = "Show Password";
export const DEFAULT_HIDE_PASSWORD_TOOLTIP = "Hide Password";

export const PasswordTextField = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  name?: string,
  label?: string,
  password?: string;
  passwordErrorMessage?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  showPassword?: boolean;
  showPasswordTooltip?: string;
  hidePasswordTooltip?: string;
  toggleShowPasswordLabel?: string,
  setPassword?: (password: string) => void;
  setShowPassword?: (showPassword: boolean) => void;
  login?: () => void;
}) => {

  const {
    classes,
    className,
    name = "password",
    label = "Password",
    password = "",
    passwordErrorMessage = "",
    disabled,
    autoFocus,
    showPassword = false,
    showPasswordTooltip = DEFAULT_SHOW_PASSWORD_TOOLTIP,
    hidePasswordTooltip = DEFAULT_HIDE_PASSWORD_TOOLTIP,
    toggleShowPasswordLabel = "Toggle Password Visibility",
    setPassword = noop,
    setShowPassword = noop,
    login = noop,
  } = props;

  const tooltip = showPassword ? hidePasswordTooltip : showPasswordTooltip;

  return (
    <LoginTextField
      className={className}
      name={name}
      label={label}
      value={password}
      helperText={passwordErrorMessage}
      type={showPassword ? "text" : "password"}
      error={!isEmptyString(passwordErrorMessage)}
      disabled={disabled}
      autoFocus={autoFocus}
      setValue={setPassword}
      login={login}
      endAdornment={(
        <InputAdornment position="end">
          <TextFieldTooltip
            className={classnames("tooltip", classes.tooltip)}
            title={tooltip}
            placement="right"
          >
            <IconButton
              className={classnames("iconButton", classes.iconButton, {
                [classes.hidden]: password.length === 0,
              })}
              tabIndex={-1}
              aria-label={toggleShowPasswordLabel}
              onClick={clickHandler(() => setShowPassword(!showPassword))}
            >
              {showPassword && (
                <HideIcon className={classnames("textFieldIcon", "hide", classes.textFieldIcon)} />
              )}
              {!showPassword && (
                <ShowIcon className={classnames("textFieldIcon", "show", classes.textFieldIcon)} />
              )}
            </IconButton>
          </TextFieldTooltip>
        </InputAdornment>
      )}
    />
  );
});

export default PasswordTextField;
