import React from "react";
import { PaginatedList, PaginatedListActions, PaginatedListModel } from "@components";
import { DSTypeInfo } from "@data";
import DSLayoutChildrenListItem from "./DSLayoutChildrenListItem";
import DSLayoutChildrenListColumn from "./DSLayoutChildrenListColumn";
import { DSLayoutExpandedChildView } from "./DSLayoutExpandedChildView";
import { childrenView as styles } from "./styles";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { noop } from "@util";

const DEFAULT_TYPES_LIST_COLUMNS: DSLayoutChildrenListColumn[] = [
  DSLayoutChildrenListColumn.NAME,
  DSLayoutChildrenListColumn.DESCRIPTION,
  DSLayoutChildrenListColumn.METADATA
];

export interface DSLayoutChildrenListViewModel extends PaginatedListModel<DSTypeInfo> {
  editMode?: boolean;
  addItemLabel?: string;
  resetExpanded?: boolean;
}

export interface DSLayoutChildrenListViewActions extends PaginatedListActions<DSTypeInfo> {
  onClickAddItem?: () => void;
  setParentNodeName?: (name: string) => void;
  onClickDeleteIcon?: (type: DSTypeInfo) => void;
}

type Props = WithStyles<typeof styles> & DSLayoutChildrenListViewModel & DSLayoutChildrenListViewActions;

export const DSLayoutChildrenListView = withStyles(styles)((props: Props) => {

  const {
    classes,
    editMode = false,
    resetExpanded = false,
    addItemLabel = "Add Children",
    items = [],
    columns = DEFAULT_TYPES_LIST_COLUMNS,
    onClickAddItem = noop,
    onClickDeleteIcon = noop,
    setParentNodeName = noop,
    ...otherProps
  } = props;

  const expandable = React.useMemo(() =>
    !editMode ? items.some((type: DSTypeInfo) => type.getChildren().length > 0) : true
    , [items, editMode]);

  const showAddItemButton = React.useMemo(() =>
    items.length < 1 && editMode, [items, editMode]);

  const [expandedTypes, setExpandedTypes] = React.useState<string[]>([]);

  const setExpandedItems = React.useCallback((expandedShadowTypes: DSTypeInfo[]) => {
    setExpandedTypes(expandedShadowTypes.map(shadowType => shadowType.getName()));
    expandedShadowTypes.length > 0 ?
      setParentNodeName(expandedShadowTypes[0].getName()) :
      setParentNodeName("");
  }, [setExpandedTypes, setParentNodeName]);

  const isItemExpanded = React.useCallback((item: DSTypeInfo) =>
    expandedTypes.indexOf(item.getName()) >= 0, [expandedTypes]);

  const expandedItems = React.useMemo(() =>
      !expandable ? [] : items.filter(isItemExpanded),
    [expandable, items, isItemExpanded]);

  const renderExpandedItem = (item: DSTypeInfo) => {
    return (
      <DSLayoutExpandedChildView
        editMode={editMode}
        addItemLabel={`Add Child to ${item.getName()}`}
        onClickAddItem={onClickAddItem}
        setParentNodeName={setParentNodeName}
        onClickDeleteIcon={onClickDeleteIcon}
        items={
          item.getChildren().length > 0 ?
            item.getChildren().map(attrs => new DSTypeInfo(attrs)) : []
        }
      />
    );
  };

  React.useEffect(() => {
    if (resetExpanded) {
      setExpandedTypes([]);
    }
  }, [resetExpanded]);

  return (
    <PaginatedList
      {...otherProps}
      className={classnames("dsLayoutChildrenListView", classes.container)}
      items={items}
      columns={columns}
      noResultsLabel="No Children defined for this Layout"
      expandable={expandable}
      showAddItemButton={showAddItemButton}
      addItemLabel={addItemLabel}
      expandedItems={expandedItems}
      setExpandedItems={setExpandedItems}
      renderExpandedItem={renderExpandedItem}
      onClickAddItem={onClickAddItem}
      onClickDeleteIcon={onClickDeleteIcon}
      renderItem={(item: DSTypeInfo, column: DSLayoutChildrenListColumn) => (
        <DSLayoutChildrenListItem
          type={item}
          column={column}
        />
      )}
    />
  );
});

export default DSLayoutChildrenListView;
