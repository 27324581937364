import { createActions } from "@base";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { AppSchema } from "@schemas";
import { getDeviceRequest } from "./selectors";
import { DeviceDataModel } from "@data";
import { openDeviceEnrollmentFromValidateResult as openDeviceEnrollmentWizard } from "@modules/deviceEnrollmentWizard/actions/deviceEnrollmentWizard";

export const {
  deviceId: setDeviceId,
  deviceRequest: setDeviceRequestAttrs,
  FETCH_DEVICE_VALIDATION_DETAILS_REQUEST: fetchDeviceValidationDetailsRequest,
  FETCH_DEVICE_VALIDATION_DETAILS_SUCCESS: fetchDeviceValidationDetailsSuccess,
  FETCH_DEVICE_VALIDATION_DETAILS_FAILURE: fetchDeviceValidationDetailsFailure,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setDeviceId());
  dispatch(setDeviceRequestAttrs());
  return dispatch(baseReset());
};

export const setDeviceRequest = (deviceRequest: DeviceDataModel = DeviceDataModel.EMPTY) =>
  (dispatch: any) => dispatch(setDeviceRequestAttrs(deviceRequest.toJS()));

export const initialize = (deviceId: string) => (dispatch: any, getState: () => AppSchema) => {
  const deviceRequest = getDeviceRequest(getState());

  dispatch(reset());
  dispatch(setDeviceId(deviceId));

  if (deviceId === deviceRequest.getDeviceId()) {
    dispatch(setDeviceRequest(deviceRequest));
  }

  return Promise.resolve();
};

export const openDeviceEnrollment = (deviceRequest: DeviceDataModel) => (dispatch: any) => {
  return dispatch(openDeviceEnrollmentWizard(deviceRequest));
};
