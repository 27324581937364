import { combineReducers } from "redux";
import { Namespace, NamespaceAttributes } from "@data";
import {
    BasePortalModuleSchema,
    createPortalModuleActionTypes,
    createPortalModuleDefaultState,
    createPortalModuleReducers,
} from "@base/createReducers";
import { MODULE_ID } from "@modules/namespaceDetails/constants";

export enum NamespaceDetailsViewMode {
    SUMMARY = "summary",
    JSON = "json"
}

export interface NamespaceDetailsModuleSchema extends BasePortalModuleSchema {
    json: string;
    namespaceName: string;
    errorMessage: string;
    showEmptyView: boolean;
    showLoadingIndicator: boolean;
    showJsonView: boolean;
    showSummaryView: boolean;
    namespace: NamespaceAttributes;
    viewMode: NamespaceDetailsViewMode;
}

export const DEFAULT_STATE: NamespaceDetailsModuleSchema = createPortalModuleDefaultState({
    json: JSON.stringify(Namespace.EMPTY.toJS(), null, "  "),
    errorMessage: "",
    namespaceName: "",
    showEmptyView: false,
    showAccessDenied: false,
    showLoadingIndicator: false,
    showJsonView: false,
    showSummaryView: true,
    isLatest: false,
    viewMode: NamespaceDetailsViewMode.SUMMARY,
    namespace: Object.freeze({ ...Namespace.EMPTY.toJS() }),
});

export const ACTION_TYPES = Object.freeze({
    ...createPortalModuleActionTypes<NamespaceDetailsModuleSchema>(MODULE_ID, DEFAULT_STATE),
});

export const NamespaceDetailsReducer = combineReducers<NamespaceDetailsModuleSchema>(
    createPortalModuleReducers<NamespaceDetailsModuleSchema>(ACTION_TYPES, DEFAULT_STATE));

export default NamespaceDetailsReducer;
