import { Record } from "immutable";

export interface InviteUserStateAttributes {
  userId: string;
  applicationId: string;
  emailTemplateId: string;
  profileValueUpdated: boolean;
}

export class InviteUserState extends Record({
  userId: "",
  applicationId: "",
  emailTemplateId: "",
  profileValueUpdated: false,
}) implements InviteUserStateAttributes {

  public static EMPTY = new InviteUserState();

  public readonly userId: string;
  public readonly applicationId: string;
  public readonly emailTemplateId: string;
  public readonly profileValueUpdated: boolean;
}

export default InviteUserState;
