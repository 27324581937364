import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { noop } from "@util";
import { ManagedPolicy } from "@data";
import { selectView as styles } from "./styles";
import { ManagedPolicyBulkRequest } from "@data/ManagedPolicyBulkRequest";
import {
  ManagedPoliciesList,
  ManagedPoliciesListActions,
  ManagedPoliciesListModel,
} from "@components";
import { ManagedPoliciesListColumn } from "@components/managed-policies-list";

export const DEFAULT_COLUMNS: ManagedPoliciesListColumn[] = [
  ManagedPoliciesListColumn.NAME,
  ManagedPoliciesListColumn.DESCRIPTION,
];

export interface Model extends ManagedPoliciesListModel {
  className?: string;
  selectedManagedPolicies?: ManagedPolicy[];
}

export interface Actions extends ManagedPoliciesListActions {
  setSelectedManagedPolicy?: (policy?: ManagedPolicy) => void;
  setPolicyBulkRequest?: (req: ManagedPolicyBulkRequest[]) => void;
  setSelectedManagedPolicies?: (items: ManagedPolicy[]) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SelectManagedPolicy = withStyles(styles)((props: Props) => {

  const {
    items = [],
    selectedManagedPolicies = [],
    showLoadMoreButton,
    showSearch: showSearchView,
    showLoadingIndicator,
    errorMessage,
    setSelectedManagedPolicies = noop,
    setSelectedManagedPolicy = noop,
    setPolicyBulkRequest = noop,
    ...otherProps
  } = props;

  const policySelected = React.useMemo(() =>
      selectedManagedPolicies.length > 0,
    [selectedManagedPolicies]);

  const showLoadMore = React.useMemo(() =>
      policySelected ? false : showLoadMoreButton,
    [policySelected, showLoadMoreButton]);

  const error = React.useMemo(() =>
      policySelected ? "" : errorMessage,
    [policySelected, errorMessage]);

  const policies = React.useMemo(() => items.slice(), [items]);

  const setSelectedItems = (item: ManagedPolicy[]) => {
    setSelectedManagedPolicies(item);
    setSelectedManagedPolicy(item.length > 0 ? item[0] : ManagedPolicy.EMPTY);
    setPolicyBulkRequest(item.map((policy: ManagedPolicy) =>
      new ManagedPolicyBulkRequest({
        policy: policy.toJS(),
        status: ManagedPolicyBulkRequest.EMPTY.status,
    })));
  };

  return (
    <ManagedPoliciesList
      {...otherProps}
      items={policies}
      columns={DEFAULT_COLUMNS}
      showSearch={true}
      showLoadMoreButton={showLoadMore}
      showLoadingIndicator={showLoadingIndicator}
      errorMessage={error}
      selectable={true}
      selectAllDisabled={false}
      selectedItems={selectedManagedPolicies}
      setSelectedItems={setSelectedItems}
    />
  );
});

export default SelectManagedPolicy;
