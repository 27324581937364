import { v4 as uuidV4 } from "uuid";
import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base/createReducers";
import {
  DeviceDataModel,
  DeviceDataModelAttributes,
  DeviceTypeModelV3,
  DeviceTypeModelV3Attributes,
  JsonSchemaDefinition,
  JsonSchemaMetadataAttributes,
} from "@data";
import {
  DeviceEnrollmentWizardState,
  DeviceEnrollmentWizardStateAttributes,
} from "./models/DeviceEnrollmentWizardState";
import { AddDeviceWizardStep, DeviceEnrollmentWizardStep } from "./steps";
import { DEFAULT_SCHEMA } from "@modules/schemaDetails/reducers";

export enum EnrollmentType {
  VISUAL_EDITOR = "visual_editor",
  FILE = "file"
}

export enum DeviceDataMode {
  ACTUAL = "actual",
  DESIRED = "desired",
}

export type SchemaItems = { [key: string]: JsonSchemaMetadataAttributes };

export interface DeviceEnrollmentWizardSchema extends BasePortalModuleSchema {
  batchId: string;
  deviceRef: string;
  device: DeviceDataModelAttributes;
  devices: DeviceDataModelAttributes[];
  deviceEnrollmentWizardStep: DeviceEnrollmentWizardStep;
  errorTitle: string;
  showValidateMode: boolean;
  availableSchemas: SchemaItems;
  deviceType: DeviceTypeModelV3Attributes;
  enrollmentType: EnrollmentType;
  preSignedUrl: string;
  base64Payload: string;
  deviceDataMode: DeviceDataMode;
  file: File;
  addDeviceDialog: boolean;
  removeDeviceDialog: boolean;
  addDeviceWizardStep: AddDeviceWizardStep;
  deviceDataDialog: boolean;
  schema: JsonSchemaDefinition;
  schemaIdentity: string;
  validatedPayload: boolean;
  reEnrollmentPayload: boolean;
  reEnrollmentBatchId: string;
  deviceData: string;
  deviceDataValidated: boolean;
  deviceTypeSelected: boolean;
  defaultState: DeviceEnrollmentWizardStateAttributes;
  deviceEditMode: boolean;
}

export const DEFAULT_STATE: DeviceEnrollmentWizardSchema = createPortalModuleDefaultState({
  batchId: uuidV4(),
  deviceRef: "",
  device: { ...DeviceDataModel.EMPTY.toJS() },
  deviceEnrollmentWizardStep: DeviceEnrollmentWizardStep.ENROLLMENT_TYPE,
  showEmptyView: false,
  errorTitle: "",
  showValidateMode: false,
  availableSchemas: {},
  deviceType: Object.freeze({ ...DeviceTypeModelV3.EMPTY.toJS() }),
  enrollmentType: EnrollmentType.VISUAL_EDITOR,
  preSignedUrl: "",
  devices: [],
  base64Payload: "",
  deviceDataMode: DeviceDataMode.ACTUAL,
  file: new File([], ""),
  addDeviceDialog: false,
  removeDeviceDialog: false,
  addDeviceWizardStep: AddDeviceWizardStep.DEVICE_ID,
  deviceDataDialog: false,
  schema: Object.freeze({ ...DEFAULT_SCHEMA }),
  schemaIdentity: "",
  validatedPayload: false,
  reEnrollmentPayload: false,
  reEnrollmentBatchId: "",
  deviceData: "",
  deviceDataValidated: false,
  deviceTypeSelected: false,
  deviceEditMode: false,
  defaultState: { ...DeviceEnrollmentWizardState.EMPTY.toJS() },
});

type DeviceEnrollmentWizardActionTypes = PortalModuleActionTypes<DeviceEnrollmentWizardSchema> & {
  DEVICE_ENROLLMENT_REQUEST: string;
  DEVICE_ENROLLMENT_SUCCESS: string;
  DEVICE_ENROLLMENT_FAILED: string;
  VALIDATE_DEVICE_ENROLLMENT_REQUEST: string;
  VALIDATE_DEVICE_ENROLLMENT_SUCCESS: string;
  VALIDATE_DEVICE_ENROLLMENT_FAILED: string;
  PRE_SIGNED_URL_REQUEST: string;
  PRE_SIGNED_URL_SUCCESS: string;
  PRE_SIGNED_URL_FAILED: string;
  BATCH_DEVICE_ENROLLMENT_REQUEST: string;
  BATCH_DEVICE_ENROLLMENT_SUCCESS: string;
  BATCH_DEVICE_ENROLLMENT_FAILED: string;
  VALIDATE_SCHEMA_REQUEST: string;
  VALIDATE_SCHEMA_SUCCESS: string;
  VALIDATE_SCHEMA_FAILED: string;
};

export const ACTION_TYPES: DeviceEnrollmentWizardActionTypes = {
  ...createPortalModuleActionTypes<DeviceEnrollmentWizardSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    DEVICE_ENROLLMENT_REQUEST: `${MODULE_ID}/device_enrollment_request`,
    DEVICE_ENROLLMENT_SUCCESS: `${MODULE_ID}/device_enrollment_success`,
    DEVICE_ENROLLMENT_FAILED: `${MODULE_ID}/device_enrollment_failed`,
    VALIDATE_DEVICE_ENROLLMENT_REQUEST: `${MODULE_ID}/validate_device_enrollment_request`,
    VALIDATE_DEVICE_ENROLLMENT_SUCCESS: `${MODULE_ID}/validate_device_enrollment_success`,
    VALIDATE_DEVICE_ENROLLMENT_FAILED: `${MODULE_ID}/validate_device_enrollment_failed`,
    PRE_SIGNED_URL_REQUEST: `${MODULE_ID}/pre_signed_url_request`,
    PRE_SIGNED_URL_SUCCESS: `${MODULE_ID}/pre_signed_url_success`,
    PRE_SIGNED_URL_FAILED: `${MODULE_ID}/pre_signed_url_failed`,
    BATCH_DEVICE_ENROLLMENT_REQUEST: `${MODULE_ID}/batch_device_enrollment_request`,
    BATCH_DEVICE_ENROLLMENT_SUCCESS: `${MODULE_ID}/batch_device_enrollment_success`,
    BATCH_DEVICE_ENROLLMENT_FAILED: `${MODULE_ID}/batch_device_enrollment_failed`,
    VALIDATE_SCHEMA_REQUEST: `${MODULE_ID}/validate_schema_request`,
    VALIDATE_SCHEMA_SUCCESS: `${MODULE_ID}/validate_schema_success`,
    VALIDATE_SCHEMA_FAILED: `${MODULE_ID}/validate_schema_failed`,
  },
};

export const DeviceEnrollmentWizardReducer = combineReducers<DeviceEnrollmentWizardSchema>(
  createPortalModuleReducers<DeviceEnrollmentWizardSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DeviceEnrollmentWizardReducer;
