import Color from "@Color";
import ModuleIcon from "@material-ui/icons/BarChart";
import createStyles from "@material-ui/core/styles/createStyles";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    list: {
    },
  });

export default styles;
