import { Record } from "immutable";
import { SecurityGroup, SecurityGroupAttributes } from "@data";
import { equalsIgnoreCase } from "@util";

export enum GroupStatus {
  QUEUED = "Queued",
  PROCESSING = "Processing",
  SUCCESS = "Success",
  FAILED = "Failed",
}

export interface SecurityGroupBulkRequestAttributes {
  securityGroup: SecurityGroupAttributes;
  status: GroupStatus;
  errorMessage: string;
}

export class SecurityGroupBulkRequest extends Record({
  securityGroup: SecurityGroup.EMPTY.toJS(),
  status: GroupStatus.QUEUED,
  errorMessage: "",
}) implements SecurityGroupBulkRequestAttributes {

  public static EMPTY: SecurityGroupBulkRequest = new SecurityGroupBulkRequest();

  public readonly securityGroup: SecurityGroupAttributes;
  public readonly status: GroupStatus;
  public readonly errorMessage: string;

  // We need to override equals because this Record contains a nested Object property
  //
  // Reference: https://immutable-js.github.io/immutable-js/docs/#/Record/equals
  // Value equality: Records use value equality when compared with is() or record.equals().
  // That is, two Records with the same keys and values are equal. Plain objects use reference
  // equality. Two objects with the same keys and values are not equal since they are different
  // objects. This is important to consider when using objects as keys in a Map or values in a
  // Set, which use equality when retrieving values.
  public equals(other: SecurityGroupBulkRequest): boolean {
    return this.getSecurityGroup().equals(other.getSecurityGroup()) &&
      equalsIgnoreCase(this.status, other.status);
  }

  public getSecurityGroup(): SecurityGroup {
    return new SecurityGroup(this.securityGroup);
  }

  public getSecurityGroupName(): string {
    return this.getSecurityGroup().getName();
  }

  public getGroupStatus(): GroupStatus {
    return this.status;
  }

  public getErrorMessage(): string {
    return this.errorMessage;
  }
}
