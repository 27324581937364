import { Module } from "@data";
import { onMount } from "./actions";
import { isInitialized } from "./selectors";
import { createModule } from "@base/createModule";
import { LEGACY_MODULE_PATH, MODULE_ID, MODULE_PATH } from "./constants";
import { ModuleIcon as icon } from "./components/styles";
import view from "./DashboardDetailsView";

type RouteParams = { id?: string };

export const dashboardDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATH,
  legacyPath: LEGACY_MODULE_PATH,
  exactPath: false,
  strictPath: false,
  name: "Dashboard Details",
  title: "Dashboard Details",
  className: "dataSetDetailsModule",
  icon,
  view,
  isEmptyViewVisible: state => !isInitialized(state),
  onMount: ({ id = "" }: RouteParams = {}) => onMount(+id),
});

export default dashboardDetailsModule;
