import React from "react";
import { Module } from "@data";
import { MODULE_ID } from "./constants";
import { isDialogOpen } from "./selectors";
import { initialize, open } from "./actions";
import { PrimaryIcon } from "../users/components/styles";
import LockUserDialog from "./containers/LockUserDialog";

export const lockUserModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "Lock User",
  title: "Lock User",
  icon: PrimaryIcon,
  view: <LockUserDialog />,
  onClick: open,
  isDialogOpen,
  initialize,
};

export default lockUserModule;
