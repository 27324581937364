import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { Model, Actions, DevicesTable } from "../../components/devices/devicesTable";
import {
  DEVICES_TABLE_ACTIONS as actions,
  DevicesTableAction,
} from "../../components/devices/devicesTable/DevicesTableAction";
import { noop } from "@util";
import { ActionMenuItem } from "@components";
import { DeviceDataModel } from "@data";
import { DeviceEnrollmentWizardSelectors } from "../../selectors";

export interface DevicesTableProps {
  devices?: DeviceDataModel[];
  addButtonDisabled?: boolean;
  addDevice?: () => void;
  removeDevice?: (device: DeviceDataModel) => void;
  editDevice?: (device: DeviceDataModel) => void;
  cloneDevice?: (device: DeviceDataModel) => void;
}

const mapStateToProps = (state: AppSchema, ownProps: DevicesTableProps): Model => {

  const { devices, addButtonDisabled  } = ownProps;

  return {
    actions,
    devices,
    addButtonDisabled,
    headerColumns: DeviceEnrollmentWizardSelectors.getDeviceTableHeaderRowItems(state),
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: DevicesTableProps): Actions => {

  const {
    removeDevice = noop,
    editDevice = noop,
    cloneDevice = noop,
    addDevice,
  } = ownProps;

  const onClickDeviceAction = (device: DeviceDataModel, action: ActionMenuItem) => {
    if (DevicesTableAction.REMOVE_DEVICE === action.id) {
      removeDevice(device);
    } else if (DevicesTableAction.EDIT_DEVICE === action.id) {
      editDevice(device);
    } else if (DevicesTableAction.CLONE_DEVICE === action.id) {
      cloneDevice(device);
    }
  };

  const onClickItem = (device: DeviceDataModel) => {
    editDevice(device);
  };

  return {
    addDevice: addDevice,
    onClickDeviceAction,
    onClickItem,
  };
};

export default connect<Model, Actions, DevicesTableProps>(
  mapStateToProps,
  mapDispatchToProps
)(DevicesTable);
