import createStyles from "@material-ui/core/styles/createStyles";
import { Color } from "@Color";
import TrainingCertificateIcon from "@material-ui/icons/Book";

export const PrimaryIcon = TrainingCertificateIcon;

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles( {
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    uploadButton: {
      marginLeft: "auto",
      padding: "6px 16px",
    },
    downloadCertificateButton: {
      margin: "0 0 0 20px",
      padding: 0,
    },
    downloadButton: {
      padding: "6px 16px",
    },
    alert: {
      margin: "0 0 10px 0",
    },
    alertTitle: {
      fontSize: 14,
      marginTop: 2,
    },
    alertDescription: {
      fontSize: 14,
      lineHeight: "21px",
      marginTop: 10,
      marginBottom: 0,
      paddingLeft: 20,
    },
    underlined: {
      marginLeft: 4,
      marginRight: 4,
      textDecoration: "underline",
    },
    pdfViewer: {
    },
  });
