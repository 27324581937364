import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import EditorView , { Actions, Model } from "../components/EditorView";
import DevicesJsonEditor from "./DevicesJsonEditor";
import { DeviceEnrollmentWizardSelectors } from "../selectors";
import { DeviceEnrollmentWizardActions } from "../actions";

interface ContainerModel extends Model {
  hidden?: boolean;
}

const DeviceEnrollmentEditorViewContainer = (props: ContainerModel & Actions) => {

  const { hidden } = props;

  if (hidden) {
    return null;
  }

  return (
    <EditorView {...props}>
      < DevicesJsonEditor />
    </EditorView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  hidden: !DeviceEnrollmentWizardSelectors.isDeviceEnrollmentEditorViewSelected(state),
  isAddDeviceWizard: false,
  isDownloadFileButtonVisible: !DeviceEnrollmentWizardSelectors.isValidateModeActive(state),
  downloadButtonDisabled: !DeviceEnrollmentWizardSelectors.isDownloadFileButtonEnabled(state),
  devices: DeviceEnrollmentWizardSelectors.getDevices(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  covertToBase64: () => dispatch(DeviceEnrollmentWizardActions.convertJsonToBase64()),
  ...ownProps
});

export default connect<ContainerModel, Actions, ContainerModel & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceEnrollmentEditorViewContainer);
