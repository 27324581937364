import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      color: Color.TEXT,
    },
    icon: {
      color: "inherit",
    },
    label: {
      color: "inherit",
      fontSize: 14,
      fontWeight: 400,
      marginLeft: 8,
    },
    loadingIndicator: {
      color: Color.SIGNIFY_GREEN,
    },
    queueIcon: {
    },
    errorIcon: {
    },
    successIcon: {
    },
    loading: {
      color: Color.GREY3,
      "& .label": {
        fontStyle: "italic",
      },
    },
    error: {
      color: Color.DARK_RED,
    },
    warning: {
      color: Color.MUSTARD,
    },
    success: {
      color: Color.SIGNIFY_GREEN,
    },
  });

export default styles;
