import { createSelector } from "reselect";
import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { createSelectors, Selector } from "@base";
import { getPluralString } from "@util";
import { AccessRequestType, DataAccessRequest, DataAccessRequestAttributes } from "@data";
import { DataAccessRequestDetailsSchema, DEFAULT_STATE } from "./reducers";
import RequestComment, { RequestCommentAttributes } from "@data/RequestComment";

export const {
  dataAccessRequest: getDataAccessRequestAttributes,
  comments: getCommentAttributesArray,
  comment: getComment,
  createCommentBox: isCreateCommentBox,
  errorMessage: getErrorMessage,
  successMessage: getSuccessMessage,
  showEmptyView: isEmptyViewVisible,
  showAccessDenied: isAccessDeniedVisible,
  showLoadingIndicator: isLoadingIndicatorVisible,
  isNotFoundVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  commentLoadingIndicator : isCommentLoadingIndicatorVisible,
  commentErrorMessage: getCommentErrorMessage,
} = createSelectors<DataAccessRequestDetailsSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getDataAccessRequest: Selector<DataAccessRequest> = createSelector(
  getDataAccessRequestAttributes, (attrs: DataAccessRequestAttributes) =>
    new DataAccessRequest(attrs));

export const getAccessRequestId: Selector<string> = createSelector(
  [getDataAccessRequest], (dataAccessRequest: DataAccessRequest) =>
    dataAccessRequest.getAccessRequestId());

export const getDataAccessRequestAccountId: Selector<string> = createSelector(
  [getDataAccessRequest], (dataAccessRequest: DataAccessRequest) =>
    dataAccessRequest.getAccountId());

export const getJson: Selector<string> = createSelector(
  [isLoadingIndicatorVisible, getDataAccessRequest],
  (isLoading: boolean, dataAccessRequest: DataAccessRequest) => {

    // If the page is loading, only show the bare minimum to avoid showing potentially stale data
    const data = isLoading ? DEFAULT_STATE.dataAccessRequest : dataAccessRequest.toJS();

    return JSON.stringify(data, null, "  ");
  });

export const getComments: Selector<RequestComment[]> = createSelector(
  getCommentAttributesArray, (attrsArray: RequestCommentAttributes[]) =>
    attrsArray.map((attrs: RequestCommentAttributes) => new RequestComment({
      ...attrs,
      author: attrs.author.split(":").pop(),
    })));

export const getNumOfComments: Selector<number> = createSelector(
  getComments, (items: RequestComment[]) => items.length);

export const getCommentsViewTitle: Selector<string> = createSelector(
  getNumOfComments, (numItems: number) => getPluralString(numItems, {
    other: "Comments",
    one: "Comment",
  }));

export const isAddCommentButtonActive: Selector<boolean> = createSelector(
  getComment, (comment: string) => {
    return comment.trim().length > 0;
  });

export const getAccessRequestType: Selector<AccessRequestType> = createSelector(
  getDataAccessRequest, (request: DataAccessRequest) =>
    request.getAccessRequestType());

export const getDataSetAlias: Selector<string> = createSelector(
  getDataAccessRequest, (request: DataAccessRequest) =>
    request.getDataSetAlias());

export const getActualTitle: Selector<string> = createSelector(
  [getAccessRequestType, getDataAccessRequestAccountId, getDataSetAlias],
  (action: AccessRequestType, accountId: string, alias: string) =>
    `${action}:${accountId}:${alias}`);

export const getTitle: Selector<string> = createSelector(
  [isLoadingIndicatorVisible, getAccessRequestId, getActualTitle],
  (loading: boolean, accessRequestId: string, actualTitle: string) =>
    loading ? `Loading: ${accessRequestId}` : actualTitle);
