import { createStyles, Theme } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
      position: "relative",
      margin: theme.spacing(),
    },
    button: {
    },
    fab: {
      backgroundColor: Color.WHITE,
      color: Color.SIGNIFY_GREEN,
      width: 40,
      height: 40,
    },
    progressIndicator: {
      color: Color.SIGNIFY_GREEN,
      position: "absolute",
      zIndex: 1,
    },
    success: {
      backgroundColor: Color.SIGNIFY_GREEN,
    },
    closeIcon: {
      color: Color.BLACK,
    },
    checkmarkIcon: {
      color: Color.BLACK,
    },
  });

export default styles;
