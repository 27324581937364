import React from "react";
import { EmailTemplate } from "@data";
import { Comparator, compare, isEmptyString } from "@util";
import { EmailTemplateListColumn } from "./EmailTemplateListColumn";
import { BASE_URL } from "@modules/emailTemplates/constants";

export const getPathToEmailTemplate = (id: string) =>
  `${BASE_URL}/${id}`;

export const getSearchResults = (items: EmailTemplate[], searchQuery: string) => {

  const templates = items.slice();

  const searchFilter = searchQuery.toLowerCase();

  if (templates.length === 0 || isEmptyString(searchQuery)) {
    return templates;
  }

  return templates.filter((template: EmailTemplate) =>
    template.getId().toLowerCase().indexOf(searchFilter) >= 0 ||
    template.getApplicationId().toLowerCase().indexOf(searchFilter) >= 0 ||
    template.getLanguage().toLowerCase().indexOf(searchFilter) >= 0 ||
    template.getMessageType().toLowerCase().indexOf(searchFilter) >= 0 ||
    template.getEmailSubject().toLowerCase().indexOf(searchFilter) >= 0);
};

const compareById = (left: EmailTemplate, right: EmailTemplate, ascending: boolean) =>
  compare(left.getId(), right.getId(), ascending);

const compareByApplicationId = (left: EmailTemplate, right: EmailTemplate, ascending: boolean) =>
  compare(left.getApplicationId(), right.getApplicationId(), ascending);

const compareByLanguage = (left: EmailTemplate, right: EmailTemplate, ascending: boolean) =>
  compare(left.getLanguage(), right.getLanguage(), ascending);

const compareByMessageType = (left: EmailTemplate, right: EmailTemplate, ascending: boolean) =>
  compare(left.getMessageType(), right.getMessageType(), ascending);

const compareByEmailSubject = (left: EmailTemplate, right: EmailTemplate, ascending: boolean) =>
  compare(left.getEmailSubject(), right.getEmailSubject(), ascending);

export const createComparator =
  (sortedColumn: EmailTemplateListColumn, ascending: boolean): Comparator<EmailTemplate> =>
    (left: EmailTemplate, right: EmailTemplate) => {
      switch (sortedColumn) {
        case EmailTemplateListColumn.ID:
          return compareById(left, right, ascending);
        case EmailTemplateListColumn.APPLICATION_ID:
          return compareByApplicationId(left, right, ascending);
        case EmailTemplateListColumn.MESSAGE_TYPE:
          return compareByMessageType(left, right, ascending);
        case EmailTemplateListColumn.LANGUAGE:
          return compareByLanguage(left, right, ascending);
        case EmailTemplateListColumn.SUBJECT:
          return compareByEmailSubject(left, right, ascending);
        default:
          return 0;
      }
    };

export const sortAndFilterEmailTemplateResults = (props: {
  items: EmailTemplate[],
  nameFilter: string,
  sortOrderAscending: boolean,
  sortByColumn: EmailTemplateListColumn,
}) => {

  const { items, nameFilter, sortOrderAscending, sortByColumn } = props;

  const comparator = React.useMemo(() =>
    createComparator(sortByColumn, sortOrderAscending), [sortByColumn, sortOrderAscending]);

  const sortedEmailTemplates = React.useMemo(() => items.sort(comparator).slice(), [items, comparator]);

  return React.useMemo(() =>
    getSearchResults(sortedEmailTemplates, nameFilter), [sortedEmailTemplates, nameFilter]);
};
