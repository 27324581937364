import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  UserProfileAttributesList,
  UserProfileAttributesListActions as ListActions,
  UserProfileAttributesListModel as ListModel,
} from "../user-profile-attributes-list";
import {
  UserProfileAttributesTitle,
  UserProfileAttributesTitleModel as TitleModel,
} from "./UserProfileAttributesTitle";
import styles from "./styles";

export interface UserProfileAttributesModel extends ListModel, TitleModel {
  className?: string;
}

export interface UserProfileAttributesActions extends ListActions {
}

export type UserProfileAttributesProps = UserProfileAttributesModel & UserProfileAttributesActions;

type Props = WithStyles<typeof styles> & UserProfileAttributesProps;

export const UserProfileAttributes = withStyles(styles)((props: Props) => {

  const { classes, className, ...otherProps } = props;

  return (
    <div className={classnames("userProfileAttributes", className, classes.container)}>
      <UserProfileAttributesTitle {...otherProps} />
      <UserProfileAttributesList {...otherProps} />
    </div>
  );
});

export default UserProfileAttributes;
