import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";

export interface LogoutUserSchema extends BasePortalModuleSchema {
  open: boolean;
  userId: string;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<LogoutUserSchema>({
  ...BASE_DEFAULT_STATE,
  showEmptyView: false,
  open: false,
  userId: "",
});

export type LogoutUserActionTypes = PortalModuleActionTypes<LogoutUserSchema> & {
  LOGOUT_USER_REQUEST: string;
  LOGOUT_USER_SUCCESS: string;
  LOGOUT_USER_FAILED: string;
};

export const ACTION_TYPES: LogoutUserActionTypes = {
  ...createPortalModuleActionTypes<LogoutUserSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    LOGOUT_USER_REQUEST: `${MODULE_ID}/logout_user_request`,
    LOGOUT_USER_SUCCESS: `${MODULE_ID}/logout_user_success`,
    LOGOUT_USER_FAILED: `${MODULE_ID}/logout_user_failed`,
  },
};

export const LogoutUserReducer = combineReducers<LogoutUserSchema>(
  createPortalModuleReducers<LogoutUserSchema>(ACTION_TYPES, DEFAULT_STATE));

export default LogoutUserReducer;
