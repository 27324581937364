import { isEmptyString } from "@util";
import { NamespaceAttributes, NamespaceLifecycle } from "@data";
import {
  createQueryParams,
  makeApiRequest,
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments,
} from "./helpers";

const NAMESPACE_API = process.env.REACT_APP_NAMESPACE_API || "";

if (isEmptyString(NAMESPACE_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_NAMESPACE_API");
}

export interface GetNamespacesResponse {
  entries: NamespaceAttributes[];
  paging?: {
    next?: string;
  };
}

export const getNamespaces = (authToken: string,
                              next: string = ""): Promise<GetNamespacesResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams({ next });

    const url = `${NAMESPACE_API}/data/modeling/v1/namespaces${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch namespaces failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getNamespace = (authToken: string,
                                namespace: string): Promise<NamespaceAttributes> => {

  const validate = () => withAuthToken(authToken)
      .then(() => withRequiredArguments([
        ["Namespace", namespace],
      ]));

  const makeRequest = () => {

    const url = `${NAMESPACE_API}/data/modeling/v1/namespaces/${namespace}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to get namespace [${namespace}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const createNamespace = (authToken: string,
                                namespace: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
    ]));

  const makeRequest = () => {

    const url = `${NAMESPACE_API}/data/modeling/v1/namespaces/${namespace}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to create namespace [${namespace}]`;

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(() => Promise.resolve());
  };

  return validate().then(makeRequest);
};

export const updateNamespaceLifecycleState = (authToken: string,
                                              namespace: string,
                                              state: NamespaceLifecycle): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["LifecycleState", state],
    ]));

  const makeRequest = () => {

    const url = `${NAMESPACE_API}/data/modeling/v1/namespaces/${namespace}/state/${state}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to update namespace [${namespace}] state to ${state}`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
