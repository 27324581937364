import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { DeviceDataModel } from "@data";
import { DeviceEnrollmentWizardActions } from "../actions";
import { DeviceEnrollmentWizardSelectors } from "../selectors";
import DeviceEnrollmentWizard, { Actions, Model } from "../components/DeviceEnrollmentWizard";
import EnrollmentTypeView from "./EnrollmentTypeView";
import BatchIdView from "./BatchIdView";
import FileUploadView from "./FileUploadView";
import DevicesEditor from "./devices/DevicesEditor";
import DeviceEnrollmentEditorView from "./DeviceEnrollmentEditorView";
import ReviewView from "./ReviewView";

interface ContainerModel extends Model {
  snackbarId?: string;
  successMessage?: string;
  batchId?: string;
  deviceId?: string;
  validateMode?: boolean;
  deviceRequest?: DeviceDataModel;
  deviceRequests?: DeviceDataModel[];
}

interface ContainerActions extends Actions {
  showDeviceEnrollmentDetails?: (batchId: string, deviceRequests?: DeviceDataModel[]) => void;
  showDeviceValidationDetails?: (deviceId: string, deviceRequest?: DeviceDataModel) => void;
  setDeviceRequest?: (deviceRequest: DeviceDataModel) => void;
}

type Props = ContainerModel & ContainerActions;

const DeviceEnrollmentWizardContainer = (props: Props) => {

  const {
    batchId = "",
    deviceId = "",
    deviceRequest,
    deviceRequests = [],
    validateMode,
    showDeviceEnrollmentDetails = noop,
    showDeviceValidationDetails = noop,
    setDeviceRequest = noop,
    ...otherProps
  } = props;

  const onSuccess = React.useCallback(() => {
    if (!validateMode) {
      showDeviceEnrollmentDetails(batchId, deviceRequests);
    } else {
      showDeviceValidationDetails(deviceId, deviceRequest);
      setDeviceRequest(deviceRequest);
    }
  }, [
    validateMode,
    showDeviceEnrollmentDetails,
    batchId,
    showDeviceValidationDetails,
    deviceId,
    setDeviceRequest,
    deviceRequest,
    deviceRequests
  ]);

  return (
    <DeviceEnrollmentWizard
      {...otherProps}
      onSuccess={onSuccess}
    >
      <EnrollmentTypeView />
      <BatchIdView />
      <FileUploadView />
      <DevicesEditor />
      <DeviceEnrollmentEditorView />
      <ReviewView />
    </DeviceEnrollmentWizard>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  currentState: DeviceEnrollmentWizardSelectors.getDeviceEnrollmentWizardState(state),
  defaultState: DeviceEnrollmentWizardSelectors.getDeviceEnrollmentWizardDefaultState(state),
  steps: DeviceEnrollmentWizardSelectors.getDeviceEnrollmentSteps(state),
  batchId: DeviceEnrollmentWizardSelectors.getBatchId(state),
  deviceId: DeviceEnrollmentWizardSelectors.getDeviceRef(state),
  validateMode: DeviceEnrollmentWizardSelectors.isValidateModeActive(state),
  successMessage: DeviceEnrollmentWizardSelectors.getSuccessMessage(state),
  errorMessage: DeviceEnrollmentWizardSelectors.getErrorMessage(state),
  errorTitle: DeviceEnrollmentWizardSelectors.getErrorTitle(state),
  showLoadingIndicator: DeviceEnrollmentWizardSelectors.isLoadingIndicatorVisible(state),
  showSuccessIndicator: DeviceEnrollmentWizardSelectors.isSuccessMessageVisible(state),
  saveButtonLabel: DeviceEnrollmentWizardSelectors.getSaveButtonLabel(state),
  saveButtonDisabled: !DeviceEnrollmentWizardSelectors.isSaveButtonEnabled(state),
  currentStep: DeviceEnrollmentWizardSelectors.getDeviceEnrollmentWizardStep(state),
  completedSteps: DeviceEnrollmentWizardSelectors.getDeviceEnrollmentCompletedSteps(state),
  disabledSteps: DeviceEnrollmentWizardSelectors.getDeviceEnrollmentDisabledSteps(state),
  previousStepButtonDisabled: DeviceEnrollmentWizardSelectors.isDeviceEnrollmentPreviousStepButtonDisabled(state),
  nextStepButtonDisabled: DeviceEnrollmentWizardSelectors.isDeviceEnrollmentNextStepButtonDisabled(state),
  showSaveButton: DeviceEnrollmentWizardSelectors.isDeviceEnrollmentLastStepActive(state),
  deviceRequest: DeviceEnrollmentWizardSelectors.getDevices(state)[0],
  deviceRequests: DeviceEnrollmentWizardSelectors.getDevices(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setCurrentStep: step => dispatch(DeviceEnrollmentWizardActions.setDeviceEnrollmentCurrentStep(step)),
  previousStep: () => dispatch(DeviceEnrollmentWizardActions.showDeviceEnrollmentPreviousStep()),
  nextStep: () => dispatch(DeviceEnrollmentWizardActions.showDeviceEnrollmentNextStep()),
  save: () => dispatch(DeviceEnrollmentWizardActions.save()),
  setDeviceRequest: (device: DeviceDataModel) => dispatch(DeviceEnrollmentWizardActions.setDeviceRequest(device)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceEnrollmentWizardContainer);
