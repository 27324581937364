import { isEmptyString } from "./Functions";
import SessionStorage from "./SessionStorage";

export interface SessionStorageApi<V> {
  hasItem(key: string): boolean;
  getItem(key: string, defaultValue: V): V;
  setItem(key: string, data: V): SessionStorageApi<V>;
  removeItem(key: string): SessionStorageApi<V>;
  clear(): SessionStorageApi<V>;
}

class SessionStorageClient implements SessionStorageApi<string> {

  public hasItem(key: string): boolean {
    const val = SessionStorage.getItem(key);
    return val !== null && !isEmptyString(val);
  }

  public getItem(key: string, defaultValue: string = ""): string {
    const val = SessionStorage.getItem(key);
    return val || defaultValue;
  }

  public setItem(key: string, data: string): SessionStorageApi<string> {
    if (isEmptyString(data)) {
      return this.removeItem(key);
    }
    SessionStorage.setItem(key, data);
    return this;
  }

  public removeItem(key: string): SessionStorageApi<string> {
    SessionStorage.removeItem(key);
    return this;
  }

  public clear(): SessionStorageApi<string> {
    SessionStorage.clear();
    return this;
  }
}

export default new SessionStorageClient();
