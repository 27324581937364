import React from "react";
import { Module } from "@data";
import { MODULE_ID } from "./constants";
import { isDialogOpen } from "./selectors";
import { initialize, open } from "./actions";
import { PrimaryIcon } from "./components/styles";
import PrimaryView from "./containers/SchemaStateManagerModule";

export const schemaStateManagerModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "Schema State Manager",
  title: "Manage Schema State",
  icon: PrimaryIcon,
  view: <PrimaryView />,
  isDialogOpen,
  initialize,
  onClick: open,
};

export default schemaStateManagerModule;
