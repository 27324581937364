import { isEmptyString } from "@util";
import { createSelector } from "reselect";
import { createSelectors, Selector } from "@base/createSelectors";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";
import {
  DEFAULT_STATE,
  GroupManagementWizardAction,
  GroupManagementWizardSchema,
  SelectSecurityGroupStep,
  SelectUserStep
} from "./reducers";
import {
  SecurityGroup,
  SecurityGroupBulkRequest,
  SecurityGroupBulkRequestAttributes,
  UsersBulkRequest,
  UsersBulkRequestAttributes,
  User
} from "@data";

export const {
  getAccessToken,
  userId: getUserId,
  serviceId: getServiceId,
  groupName: getGroupName,
  securityGroupBulkRequest: getSecurityGroupBulkRequestAttributes,
  userBulkRequest: getUserBulkRequestAttributes,
  action: getAction,
  groupsStep: getGroupsStep,
  usersStep: getUsersStep,
  getErrorMessage,
  getSuccessMessage,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
} = createSelectors<GroupManagementWizardSchema>(moduleId, schemaKey, DEFAULT_STATE);

export const getSecurityGroupsBulkRequest: Selector<SecurityGroupBulkRequest[]> = createSelector(
  getSecurityGroupBulkRequestAttributes, (requests: SecurityGroupBulkRequestAttributes[]) =>
    requests.map((attrs: SecurityGroupBulkRequestAttributes) =>
      new SecurityGroupBulkRequest(attrs)));

export const getSecurityGroups: Selector<SecurityGroup[]> = createSelector(
  getSecurityGroupsBulkRequest, (requests: SecurityGroupBulkRequest[]) =>
    requests.map(request => request.getSecurityGroup()));

export const getUsersBulkRequest: Selector<UsersBulkRequest[]> = createSelector(
  getUserBulkRequestAttributes, (requests: UsersBulkRequestAttributes[]) =>
    requests.map((attrs: UsersBulkRequestAttributes) =>
      new UsersBulkRequest(attrs)));

export const getUsers: Selector<User[]> = createSelector(
  getUsersBulkRequest, (requests: UsersBulkRequest[]) =>
    requests.map(request => request.getUser()));

export const isGroupsView: Selector<boolean> = createSelector(
  getGroupsStep, (step: SelectSecurityGroupStep) =>
    SelectSecurityGroupStep.GROUPS === step);

export const isReviewView: Selector<boolean> = createSelector(
  getGroupsStep, (step: SelectSecurityGroupStep) =>
    SelectSecurityGroupStep.REVIEW === step);

export const isUsersView: Selector<boolean> = createSelector(
  getUsersStep, (step: SelectUserStep) =>
    SelectUserStep.USERS === step);

export const isUsersReviewView: Selector<boolean> = createSelector(
  getUsersStep, (step: SelectUserStep) =>
    SelectUserStep.REVIEW === step);

export const showFirstStep: Selector<boolean> = createSelector(
  [isGroupsView, isUsersView], (groupsView: boolean, usersView: boolean) =>
    groupsView || usersView);

export const showLastStep: Selector<boolean> = createSelector(
  [isReviewView, isUsersReviewView], (groupsReviewView: boolean, usersReviewView: boolean) =>
    groupsReviewView || usersReviewView);

export const isPreviousButtonEnabled: Selector<boolean> = createSelector(
  [isLoadingIndicatorVisible, isErrorMessageVisible, isSuccessMessageVisible],
  (loadingIndicator: boolean, errorMessage: boolean, successMessage: boolean ) =>
    (loadingIndicator || errorMessage || successMessage));

export const isAddingUserToGroup: Selector<boolean> = createSelector(
  getAction, (action: GroupManagementWizardAction) =>
    GroupManagementWizardAction.ADD_USER === action);

export const isRemovingUserFromGroup: Selector<boolean> = createSelector(
  getAction, (action: GroupManagementWizardAction) =>
    GroupManagementWizardAction.REMOVE_USER === action);

export const isAddingServiceToGroup: Selector<boolean> = createSelector(
  getAction, (action: GroupManagementWizardAction) =>
    GroupManagementWizardAction.ADD_SERVICE === action);

export const isRemovingServiceFromGroup: Selector<boolean> = createSelector(
  getAction, (action: GroupManagementWizardAction) =>
    GroupManagementWizardAction.REMOVE_SERVICE === action);

export const isGroupSelected: Selector<boolean> = createSelector(
  getGroupName, (groupName: string) => !isEmptyString(groupName));

export const areGroupSelected: Selector<boolean> = createSelector(
  getSecurityGroups, (groups: SecurityGroup[]) => groups.length > 0);

export const isBulkRequest: Selector<boolean> = createSelector(
  getSecurityGroups, (groups: SecurityGroup[]) => groups.length > 1);

export const isUserSelected: Selector<boolean> = createSelector(
  getUserId, (userId: string) => !isEmptyString(userId));

export const areUsersSelected: Selector<boolean> = createSelector(
  getUsers, (users: User[]) => users.length > 0);

export const isUserBulkRequest: Selector<boolean> = createSelector(
  getUsers, (users: User[]) => users.length > 1);

export const isServiceSelected: Selector<boolean> = createSelector(
  getServiceId, (serviceId: string) => !isEmptyString(serviceId));

export const isSaveButtonEnabled: Selector<boolean> = createSelector(
  [
    isGroupSelected,
    areGroupSelected,
    isUserSelected,
    areUsersSelected,
    isServiceSelected
  ], (
    groupSelected: boolean,
    groupsSelected: boolean,
    userSelected: boolean,
    usersSelected: boolean,
    serviceSelected: boolean
  ) =>
    (groupSelected || groupsSelected) && (userSelected || usersSelected || serviceSelected));

export const getErrorTitle: Selector<string> = createSelector(
  [getAction, isBulkRequest], (action: GroupManagementWizardAction, plural: boolean) => {
    switch (action) {
      case GroupManagementWizardAction.ADD_USER:
        return plural ? "Add Users to Group Failed" : "Add User to Groups Failed";
      case GroupManagementWizardAction.REMOVE_USER:
        return plural ? "Remove Users from Group Failed" : "Remove User from Groups Failed";
      case GroupManagementWizardAction.ADD_SERVICE:
        return plural ? "Add Service to Group Failed" : "Add Service to Groups Failed";
      case GroupManagementWizardAction.REMOVE_SERVICE:
        return plural ? "Remove Service from Group Failed" : "Remove Service from Groups Failed";
      default:
        return "Error";
    }
  });

export const getModuleTitle: Selector<string> = createSelector(
  [isAddingUserToGroup, isRemovingUserFromGroup, isAddingServiceToGroup, isRemovingServiceFromGroup],
  (showAddUserToGroup: boolean,
   showRemoveUserFromGroup: boolean,
   showAddServiceToGroup: boolean,
   showRemoveServiceFromGroup: boolean) => {

    if (showAddUserToGroup) {
      return "Add User(s) to Group(s)";
    } else if (showRemoveUserFromGroup) {
      return "Remove User(s) from Group(s)";
    } else if (showAddServiceToGroup) {
      return "Add Service to Group(s)";
    } else if (showRemoveServiceFromGroup) {
      return "Remove Service from Group(s)";
    } else {
      return "Manage Security Group Membership";
    }
  });

const getAddUserToGroupTitle: Selector<string> = createSelector(
  [
    isUserSelected,
    isGroupSelected,
    areGroupSelected,
    areUsersSelected,
    getUserId,
    getGroupName,
    isBulkRequest,
    isUserBulkRequest
  ],
  (userSelected: boolean,
   groupSelected: boolean,
   groupsSelected: boolean,
   usersSelected: boolean,
   userId: string,
   groupName: string,
   groupBulk: boolean,
   userBulk: boolean) => {

    if (usersSelected && groupSelected) {
      return userBulk ? `Add selected users to group ${groupName}` : `Add selected user to group ${groupName}`;
    } else if (userSelected && groupsSelected) {
      return groupBulk ? `Add user ${userId} to selected groups` : `Add user ${userId} to selected group`;
    } else if (userSelected && !groupSelected) {
      return `Select group(s) to add user ${userId} to`;
    } else if (!usersSelected && groupSelected) {
      return `Select user(s) to add to group ${groupName}`;
    } else {
      return "Add user to group(s)";
    }
  });

const getRemoveUserFromGroupTitle: Selector<string> = createSelector(
  [
    isUserSelected,
    isGroupSelected,
    areGroupSelected,
    areUsersSelected,
    getUserId,
    getGroupName,
    isBulkRequest,
    isUserBulkRequest
  ],
  (userSelected: boolean,
   groupSelected: boolean,
   groupsSelected: boolean,
   usersSelected: boolean,
   userId: string,
   groupName: string,
   groupBulk: boolean,
   userBulk: boolean) => {

    if (usersSelected && groupSelected) {
      return userBulk ? `Remove selected users from group ${groupName}` : 
        `Remove selected user from group ${groupName}`;
    } else if (userSelected && groupsSelected) {
      return groupBulk ? `Remove user ${userId} from selected groups` : 
        `Remove user ${userId} from selected group`;
    } else if (userSelected && !groupSelected) {
      return `Select group(s) to remove user ${userId} from`;
    } else if (!usersSelected && groupSelected) {
      return `Select user(s) to remove from group ${groupName}`;
    } else {
      return "Remove user from group(s)";
    }
  });

const getAddServiceToGroupTitle: Selector<string> = createSelector(
  [isServiceSelected, isGroupSelected, areGroupSelected, getServiceId, getGroupName, isBulkRequest],
  (serviceSelected: boolean,
   groupSelected: boolean,
   groupsSelected: boolean,
   serviceId: string,
   groupName: string,
   plural: boolean) => {

    if (serviceSelected && groupSelected) {
      return `Add service ${serviceId} to group ${groupName}`;
    } else if (serviceSelected && groupsSelected) {
      return plural ? `Add service ${serviceId} to selected groups` : `Add service ${serviceId} to selected group`;
    } else if (serviceSelected && !groupSelected) {
      return `Select a group to add service ${serviceId} to`;
    } else if (!serviceSelected && groupSelected) {
      return `Select a service to add to group ${groupName}`;
    } else {
      return "Add service to group(s)";
    }
  });

const getRemoveServiceFromGroupTitle: Selector<string> = createSelector(
  [isServiceSelected, isGroupSelected, areGroupSelected, getServiceId, getGroupName, isBulkRequest],
  (serviceSelected: boolean,
   groupSelected: boolean,
   groupsSelected: boolean,
   serviceId: string,
   groupName: string,
   plural: boolean) => {

    if (serviceSelected && groupSelected) {
      return `Remove service ${serviceId} from group ${groupName}`;
    } else if (serviceSelected && groupsSelected) {
      return plural ? `Remove service ${serviceId} from selected groups` : `Remove service ${serviceId} from selected group`;
    } else if (serviceSelected && !groupSelected) {
      return `Select a group to remove service ${serviceId} from`;
    } else if (!serviceSelected && groupSelected) {
      return `Select a service to remove from group ${groupName}`;
    } else {
      return "Remove service from group(s)";
    }
  });

export const getTitle: Selector<string> = createSelector(
  [
    isAddingUserToGroup,
    isRemovingUserFromGroup,
    isAddingServiceToGroup,
    isRemovingServiceFromGroup,
    getAddUserToGroupTitle,
    getRemoveUserFromGroupTitle,
    getAddServiceToGroupTitle,
    getRemoveServiceFromGroupTitle,

  ],
  (
    showAddUserToGroupTitle: boolean,
    showRemoveUsersFromGroupTitle: boolean,
    showAddServiceToGroupTitle: boolean,
    showRemoveServiceFromGroupTitle: boolean,
    addUserToGroupTitle: string,
    removeUserFromGroupTitle: string,
    addServiceToGroupTitle: string,
    removeServiceFromGroupTitle: string,
  ) => {

    if (showAddUserToGroupTitle) {
      return addUserToGroupTitle;
    } else if (showRemoveUsersFromGroupTitle) {
      return removeUserFromGroupTitle;
    } else if (showAddServiceToGroupTitle) {
      return addServiceToGroupTitle;
    } else if (showRemoveServiceFromGroupTitle) {
      return removeServiceFromGroupTitle;
    } else {
      return "";
    }
  });

export const getClassName: Selector<string> = createSelector(
  [isAddingUserToGroup, isRemovingUserFromGroup, isAddingServiceToGroup, isRemovingServiceFromGroup],
  (
    showAddUserToGroup: boolean,
    showRemoveUserFromGroup: boolean,
    showAddServiceToGroup: boolean,
    showRemoveServiceFromGroup: boolean
  ) => {

    if (showAddUserToGroup) {
      return "addUserToGroup";
    } else if (showRemoveUserFromGroup) {
      return "removeUserFromGroup";
    } else if (showAddServiceToGroup) {
      return "addServiceToGroup";
    } else if (showRemoveServiceFromGroup) {
      return "removeServiceFromGroup";
    } else {
      return "";
    }
  });

export const getSnackbarId: Selector<string> = createSelector(
  [isAddingUserToGroup, isRemovingUserFromGroup, isAddingServiceToGroup, isRemovingServiceFromGroup],
  (
    showAddUserToGroup: boolean,
    showRemoveUserFromGroup: boolean,
    showAddServiceToGroup: boolean,
    showRemoveServiceFromGroup: boolean
  ) => {

    if (showAddUserToGroup) {
      return "add-user-to-groups";
    } else if (showRemoveUserFromGroup) {
      return "remove-user-from-groups";
    } else if (showAddServiceToGroup) {
      return "add-service-to-groups";
    } else if (showRemoveServiceFromGroup) {
      return "remove-service-from-groups";
    } else {
      return "group-management-wizard";
    }
  });

export const getSaveButtonLabel: Selector<string> = createSelector(
  [
    isAddingUserToGroup,
    isRemovingUserFromGroup,
    isAddingServiceToGroup,
    isRemovingServiceFromGroup,
    isErrorMessageVisible,
    isBulkRequest,
  ],
  (
    showAddUserToGroup: boolean,
    showRemoveUserFromGroup: boolean,
    showAddServiceToGroup: boolean,
    showRemoveServiceFromGroup: boolean,
    showErrorMessage: boolean,
    plural: boolean,
  ) => {

    if (showAddUserToGroup && !showErrorMessage) {
      return plural ? "Add User to Groups" : "Add Users to Group";
    } else if (showRemoveUserFromGroup && !showErrorMessage) {
      return plural ? "Remove User from Groups" : "Remove Users from Group";
    } else if (showAddServiceToGroup && !showErrorMessage) {
      return plural ? "Add Service To Groups" : "Add Service To Group";
    } else if (showRemoveServiceFromGroup && !showErrorMessage) {
      return plural ? "Remove Service from Groups" : "Remove Service from Group";
    } else if (showErrorMessage) {
      return "Retry Failed Requests";
    } else {
      return "Save";
    }
  });
