export enum DeviceEnrollmentWizardStep {
  NONE = "",
  ENROLLMENT_TYPE = "enrollment_type",
  BATCH_ID = "batchId",
  FILE_UPLOAD = "file_upload",
  DEVICES = "devices",
  EDITOR = "editor",
  REVIEW = "review",
}

export enum AddDeviceWizardStep {
  NONE = "",
  DEVICE_ID = "deviceId",
  DEVICE_TYPE = "device_type",
  DATA = "data",
  CREDENTIALS = "credentials",
  EDITOR = "editor",
}

export const DEVICE_ENROLLMENT_WIZARD_FILE_UPLOAD_STEPS = [
  DeviceEnrollmentWizardStep.ENROLLMENT_TYPE,
  DeviceEnrollmentWizardStep.BATCH_ID,
  DeviceEnrollmentWizardStep.FILE_UPLOAD,
  DeviceEnrollmentWizardStep.REVIEW,
];

export const DEVICE_ENROLLMENT_WIZARD_VISUAL_EDITOR_STEPS = [
  DeviceEnrollmentWizardStep.ENROLLMENT_TYPE,
  DeviceEnrollmentWizardStep.BATCH_ID,
  DeviceEnrollmentWizardStep.DEVICES,
  DeviceEnrollmentWizardStep.EDITOR,
  DeviceEnrollmentWizardStep.REVIEW,
];

export const VALIDATE_DEVICE_WIZARD_STEPS = [
  DeviceEnrollmentWizardStep.BATCH_ID,
  DeviceEnrollmentWizardStep.DEVICES,
  DeviceEnrollmentWizardStep.EDITOR,
  DeviceEnrollmentWizardStep.REVIEW,
];

export const ADD_DEVICE_DIALOG_STEPS = [
  AddDeviceWizardStep.DEVICE_ID,
  AddDeviceWizardStep.DEVICE_TYPE,
  AddDeviceWizardStep.DATA,
  AddDeviceWizardStep.CREDENTIALS,
  AddDeviceWizardStep.EDITOR,
];

export const DEVICE_ENROLLMENT_WIZARD_STEPS_LABELS = {
  [DeviceEnrollmentWizardStep.ENROLLMENT_TYPE]: "Enrollment Type",
  [DeviceEnrollmentWizardStep.BATCH_ID]: "Batch ID",
  [DeviceEnrollmentWizardStep.FILE_UPLOAD]: "File Upload",
  [DeviceEnrollmentWizardStep.DEVICES]: "Devices",
  [DeviceEnrollmentWizardStep.EDITOR]: "JSON",
  [DeviceEnrollmentWizardStep.REVIEW]: "Finish",
};

export const ADD_DEVICE_DIALOG_STEPS_LABELS = {
  [AddDeviceWizardStep.DEVICE_ID]: "Device ID",
  [AddDeviceWizardStep.DEVICE_TYPE]: "Device Type",
  [AddDeviceWizardStep.DATA]: "Device Data",
  [AddDeviceWizardStep.CREDENTIALS]: "Credentials",
  [AddDeviceWizardStep.EDITOR]: "JSON",
};
