import { createStyles } from "@material-ui/core/styles";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
    },
    errorView: {
      "& .message": {
        marginTop: 15,
        fontSize: 16,
      },
    },
    actions: {
      marginTop: 25,
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    cancelButton: {
    },
    retryButton: {
      marginLeft: 25,
    },
  });
