import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "20px",
      textAlign: "center",
    },
    label: {
      color: "rgba(255, 255, 255, 0.5)",
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
    },
    button: {
      height: 20,
      lineHeight: "20px",
      marginLeft: 5,
      minWidth: "auto",
      textAlign: "left",
      color: "#23A7DE",
      padding: 0,
      textDecoration: "none",
      textTransform: "none",
    },
  });

export default styles;
