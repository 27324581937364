import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import classnames from "classnames";
import { useRemoveWorkloadTrigger } from "@hooks";
import { noop } from "@util";

export interface WorkloadTriggerDeleteDialogModel extends PortalModuleDialogModel<string> {
  name: string;
  version: number;
  eventSources?: string[];
  eventSourceNames?: string[];
  dialogClassName?: string;
  removeAll?: boolean;
}

export interface WorkloadTriggerDeleteDialogActions extends PortalModuleDialogActions<string> {
}

type Props = WithStyles< typeof styles> & WorkloadTriggerDeleteDialogModel & WorkloadTriggerDeleteDialogActions;

export const WorkloadTriggerRemoveDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    name,
    version,
    eventSources = [],
    eventSourceNames = [],
    removeAll = false,
    cancel = noop,
    onSuccessMessageShown = noop,
    ...otherProps
  } = props;

  const [{showLoadingIndicator, ...otherModel}, {removeWorkloadTrigger, reset, ...otherActions}] =
    useRemoveWorkloadTrigger({ name, version, eventSources });

  const confirm = React.useCallback(() => {
    removeWorkloadTrigger();
  }, [removeWorkloadTrigger]);

  const closeDialog = React.useCallback(() => {
    reset();
    cancel();
  }, [reset, cancel]);

  const onSuccess = React.useCallback(() => {
    reset();
    onSuccessMessageShown();
  }, [reset, onSuccessMessageShown]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      {...otherActions}
      className={classnames("workloadTriggerRemoveDialog")}
      title={removeAll ? "Remove All Workload Triggers" : "Remove Workload Trigger"}
      loading={showLoadingIndicator}
      continueButtonLabel={removeAll ? "Remove All" : "Remove"}
      confirm={confirm}
      cancel={closeDialog}
      onSuccessMessageShown={onSuccess}
    >
      <div className={classnames("workloadTriggerRemove", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            {removeAll ? "Are you sure you want to remove all workload triggers?" :
              "Are you sure you want to remove this workload trigger?"}
          </label>
          <label className={classnames("subtitle", classes.subtitle)}>
            <span className={classes.warning}>WARNING:</span>This action cannot be undone!
          </label>
          <label className={classnames("trigger", classes.trigger)}>
            {eventSourceNames.toString()}
          </label>
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadTriggerRemoveDialog;
