import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  PortalModuleActionTypes,
  createPortalModuleDefaultState,
} from "@base/createReducers";

export interface DataSetDetailsSchema extends BasePortalModuleSchema {
  dataSetId: string;
}

export const DEFAULT_STATE: DataSetDetailsSchema = createPortalModuleDefaultState({
  dataSetId: "",
});

type DataSetDetailsActionTypes = PortalModuleActionTypes<DataSetDetailsSchema> & {
  FETCH_DATA_SET_REQUEST: string;
  FETCH_DATA_SET_SUCCESS: string;
  FETCH_DATA_SET_FAILED: string;
};

export const ACTION_TYPES: DataSetDetailsActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<DataSetDetailsSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    FETCH_DATA_SET_REQUEST: `${MODULE_ID}/fetch_data_set_request`,
    FETCH_DATA_SET_SUCCESS: `${MODULE_ID}/fetch_data_set_success`,
    FETCH_DATA_SET_FAILED: `${MODULE_ID}/fetch_data_set_failed`,
  }
});

export const DataSetDetailsReducer = combineReducers<DataSetDetailsSchema>(
  createPortalModuleReducers<DataSetDetailsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DataSetDetailsReducer;
