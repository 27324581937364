import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import Tab from "@components/tab";
import { Tabs as TabsComponent } from "@components/tabs";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles, { tabs } from "./styles";

const Tabs = withStyles(tabs)(TabsComponent);

export enum DeviceDetailsTabsType {
  ENROLLMENT = "enrollment",
  CONFIGURATION = "configuration",
  BOOTSTRAP = "bootstrap",
  VALIDATION = "validation",
}

export interface DeviceDetailsTabsModel {
  className?: string;
  selectedTab?: DeviceDetailsTabsType;
  enrollmentLabel?: string;
  configurationLabel?: string;
  bootstrapLabel?: string;
}

export interface DeviceDetailsTabsActions {
  showEnrollmentView?: () => void;
  showConfigurationView?: () => void;
  showBootstrapView?: () => void;
}

type Props = WithStyles<typeof styles> & DeviceDetailsTabsModel & DeviceDetailsTabsActions;

export const DeviceDetailsTabs = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    selectedTab = DeviceDetailsTabsType.ENROLLMENT,
    enrollmentLabel = "Device Enrollment",
    configurationLabel = "Device Configuration",
    bootstrapLabel = "Device Bootstrap",
    showEnrollmentView = noop,
    showConfigurationView = noop,
    showBootstrapView = noop,
  } = props;

  const setSelectedTab = React.useCallback((tab: DeviceDetailsTabsType) => {
    if (selectedTab !== tab) {
      switch (tab) {
      case DeviceDetailsTabsType.CONFIGURATION:
        return showConfigurationView();
      case DeviceDetailsTabsType.BOOTSTRAP:
        return showBootstrapView();
      case DeviceDetailsTabsType.ENROLLMENT:
      default:
        return showEnrollmentView();
      }
    }
  }, [
    selectedTab,
    showConfigurationView,
    showBootstrapView,
    showEnrollmentView,
  ]);

  return (
    <Tabs
      className={classnames("deviceDetailsTabs", className, classes.container)}
      value={selectedTab}
      onChange={(event, value) => {
        if (value) {
          setSelectedTab(value);
        }
      }}
    >
      <Tab
        className={classnames("enrollmentTab", classes.tab)}
        label={enrollmentLabel}
        value={DeviceDetailsTabsType.ENROLLMENT}
      />
      <Tab
        className={classnames("configurationTab", classes.tab)}
        label={configurationLabel}
        value={DeviceDetailsTabsType.CONFIGURATION}
      />
      <Tab
        className={classnames("bootstrapTab", classes.tab)}
        label={bootstrapLabel}
        value={DeviceDetailsTabsType.BOOTSTRAP}
      />
    </Tabs>
  );
});

export default DeviceDetailsTabs;
