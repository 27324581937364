import { useApiRequestAnalytics } from "@hooks";
import { GetProfileAttributeDefinitionResponse, ProfileAttributeClient } from "@network";

export type EditProfileAttributeDefinitionsApi = (accountId: string, json: string) => Promise<void>;
export type GetProfileAttributeDefinitionsApi = (accountId: string) => Promise<GetProfileAttributeDefinitionResponse>;

export interface ProfileAttributeClientApi {
  editProfileAttributeDefinitions: EditProfileAttributeDefinitionsApi;
  getProfileAttributeDefinitions: GetProfileAttributeDefinitionsApi;
}

enum ActionTypes {
  EDIT_PROFILE_ATTRIBUTE_DEFINITIONS_REQUEST = "profile_attribute_client/edit_profile_attribute_definitions_request",
  EDIT_PROFILE_ATTRIBUTE_DEFINITIONS_SUCCESS = "profile_attribute_client/edit_profile_attribute_definitions_success",
  EDIT_PROFILE_ATTRIBUTE_DEFINITIONS_FAILED = "profile_attribute_client/edit_profile_attribute_definitions_failed",

  GET_PROFILE_ATTRIBUTE_DEFINITIONS_REQUEST = "profile_attribute_client/get_profile_attribute_definitions_request",
  GET_PROFILE_ATTRIBUTE_DEFINITIONS_SUCCESS = "profile_attribute_client/get_profile_attribute_definitions_success",
  GET_PROFILE_ATTRIBUTE_DEFINITIONS_FAILED = "profile_attribute_client/get_profile_attribute_definitions_failed",
}

export const useEditProfileAttributeDefinitionsApi = () =>
  useApiRequestAnalytics(ProfileAttributeClient.editProfileAttributeDefinition, {
    REQUEST_EVENT: ActionTypes.EDIT_PROFILE_ATTRIBUTE_DEFINITIONS_REQUEST,
    SUCCESS_EVENT: ActionTypes.EDIT_PROFILE_ATTRIBUTE_DEFINITIONS_SUCCESS,
    ERROR_EVENT: ActionTypes.EDIT_PROFILE_ATTRIBUTE_DEFINITIONS_FAILED,
  });

export const useGetProfileAttributeDefinitionsApi = () =>
  useApiRequestAnalytics(ProfileAttributeClient.getProfileAttributeDefinition, {
    REQUEST_EVENT: ActionTypes.GET_PROFILE_ATTRIBUTE_DEFINITIONS_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_PROFILE_ATTRIBUTE_DEFINITIONS_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_PROFILE_ATTRIBUTE_DEFINITIONS_FAILED,
  });

export const useProfileAttributeClient = (): ProfileAttributeClientApi => ({
  editProfileAttributeDefinitions: useEditProfileAttributeDefinitionsApi(),
  getProfileAttributeDefinitions: useGetProfileAttributeDefinitionsApi(),
});

export { ActionTypes as ProfileAttributeClientActionType };

export default useProfileAttributeClient;
