import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { Namespace, NamespaceLifecycle } from "@data";
import classnames from "classnames";
import { useNamespaceLifecycleManagement } from "@hooks";

export interface NamespaceLifecycleManagementDialogModel extends PortalModuleDialogModel<Namespace> {
  dialogClassName?: string;
  namespace?: Namespace;
  state?: NamespaceLifecycle;
}

export interface NamespaceLifecycleManagementDialogActions extends PortalModuleDialogActions<Namespace> {
}

type Props = WithStyles< typeof styles> &
  NamespaceLifecycleManagementDialogModel &NamespaceLifecycleManagementDialogActions;

export const NamespaceLifecycleManagementDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    dialogClassName,
    namespace = Namespace.EMPTY,
    state = NamespaceLifecycle.NONE,
    ...otherProps
  } = props;

  if (namespace === Namespace.EMPTY || state === NamespaceLifecycle.NONE) {
    return null;
  }

  const text = React.useMemo(() => {
    if (state === NamespaceLifecycle.PRODUCTION) {
      return "Are you sure you want to publish this namespace?";
    } else if (state === NamespaceLifecycle.DEPRECATED) {
      return "Are you sure you want to deprecate this namespace?";
    } else {
      return "Are you sure you want to perform this update?";
    }
  }, [state]);

  const continueButtonLabel = React.useMemo(() => {
    if (state === NamespaceLifecycle.PRODUCTION) {
      return "Publish";
    } else if (state === NamespaceLifecycle.DEPRECATED) {
      return "Deprecate";
    } else {
      return "Update";
    }
  }, [state]);

  const title = React.useMemo(() => {
    if (state === NamespaceLifecycle.PRODUCTION) {
      return "Publish Namespace";
    } else if (state === NamespaceLifecycle.DEPRECATED) {
      return "Deprecate Namespace";
    } else {
      return "Update Namespace";
    }
  }, [state]);

  const namespaceName = React.useMemo(() => namespace.getName(), [namespace]);

  const [ model, actions] = useNamespaceLifecycleManagement({
    namespace: namespaceName,
    state
  });

  const { showLoadingIndicator, ...otherModel } = model;
  const { updateState, ...otherActions } = actions;

  const confirm = React.useCallback(() => {
    updateState();
  }, [updateState]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      {...otherActions}
      className={classnames("namespaceLifecycleDialog", dialogClassName)}
      title={title}
      loading={showLoadingIndicator}
      continueButtonLabel={continueButtonLabel}
      confirm={confirm}
    >
      <div className={classnames("namespaceDialog", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            {text}
          </label>
          <label className={classnames("namespace", classes.namespace)}>
            {namespaceName}
          </label>
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default NamespaceLifecycleManagementDialog;
