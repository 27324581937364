import React from "react";
import { DeviceDataType } from "@components";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  useDeviceRegistryClient,
} from "@hooks";
import { isEmptyString, noop } from "@util";
import { DeviceDataScope } from "@data";

export interface UseDeleteDeviceDataBySchemaProps extends Partial<UseApiRequestProps<void>> {
  deviceId: string;
  schemaIdentity: string;
  deviceDataType: DeviceDataType;
  typeIdentity?: string;
  deviceDataScope?: DeviceDataScope;
  refreshPage?: () => void;
}

export interface UseDeleteDeviceDataBySchemaModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UseDeleteDeviceDataBySchemaActions {
  deleteDeviceData: () => void;
}

type Props = UseDeleteDeviceDataBySchemaProps;
type Model = UseDeleteDeviceDataBySchemaModel;
type Actions = UseDeleteDeviceDataBySchemaActions;
type Result = [Model, Actions];

export const useDeleteDeviceDataBySchema = (props: Props): Result => {

  const {
    deviceDataType,
    deviceDataScope = DeviceDataScope.DEVICE,
    deviceId,
    schemaIdentity,
    typeIdentity = "",
    refreshPage = noop,
    ...otherProps
  } = props;

  const DeviceRegistryClient = useDeviceRegistryClient();

  const defaultErrorMessage = React.useMemo(() =>
    `Failed to delete ${deviceDataType} data for device` , [deviceDataType]);

  const mapApiCallToOperation = React.useCallback(() => {
    if (deviceDataType === DeviceDataType.DESIRED) {
      if (deviceDataScope === DeviceDataScope.DEVICE) {
        return DeviceRegistryClient.deleteDesiredDeviceDataBySchema(deviceId, schemaIdentity);
      } else if (deviceDataScope === DeviceDataScope.TYPE) {
        return DeviceRegistryClient.deleteTypeConfigurationBySchema(typeIdentity, schemaIdentity);
      } else {
        return DeviceRegistryClient.deleteRegionConfigurationBySchema(schemaIdentity);
      }
    } else if (deviceDataType === DeviceDataType.METADATA) {
      return DeviceRegistryClient.deleteDeviceMetadataBySchema(deviceId, schemaIdentity);
    } else {
      return DeviceRegistryClient.deleteActualDeviceDataBySchema(deviceId, schemaIdentity);
    }
  }, [
    deviceDataType,
    deviceDataScope,
    typeIdentity,
    deviceId,
    schemaIdentity,
  ]);

  const [{ showSuccessView, ...baseModel }, { refresh: deleteDeviceData }] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => mapApiCallToOperation(),
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Delete Successful" : "" ,
    [showSuccessView]);

  React.useEffect(() => {
    if (!isEmptyString(successMessage)) {
      refreshPage();
    }
  }, [successMessage, refreshPage]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    deleteDeviceData,
  }), [
    deleteDeviceData,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDeleteDeviceDataBySchema;
