import React from "react";
import classnames from "classnames";
import { workloadInfo as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { WorkloadQueryEditor, WorkloadQueryEditorActions, WorkloadQueryEditorModel } from "@components";

interface Model extends WorkloadQueryEditorModel {
  title?: string;
}

interface Actions extends WorkloadQueryEditorActions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

const QueryViewContainer = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Configure SQL Query Editor",
    ...otherProps
  } = props;

  return (
    <div className={classnames("queryView", classes.container)}>
      <label className={classnames("title", classes.title)}>{title}</label>
      <WorkloadQueryEditor {...otherProps} />
    </div>
  );
});

export default QueryViewContainer;
