import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import Checkbox from "@material-ui/core/Checkbox";
import FilterSwitch from "@components/filter-switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles, { formControlLabel } from "./styles";

const DefaultValueFormControlLabel = withStyles(formControlLabel)(FormControlLabel);

export interface Model {
  className?: string;
  defaultValue?: boolean;
  disabled?: boolean;
}

export interface Actions {
  setDefaultValue?: (defaultValue?: boolean) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const BooleanDefaultValueEditor = withStyles(styles)((props: Props) => {

  const {
    classes,
    className = "booleanDefaultValueEditor",
    defaultValue,
    disabled = false,
    setDefaultValue = noop,
  } = props;

  const defaultValueEnabled = React.useMemo<boolean>(() =>
    typeof defaultValue === "boolean", [defaultValue]);

  const defaultValueTrue = React.useMemo<boolean>(() =>
    defaultValue === true, [defaultValue]);

  const toggleDefaultValueEnabled = React.useCallback(() =>
    setDefaultValue(defaultValueEnabled ? undefined : false),
    [setDefaultValue, defaultValueEnabled]);

  return (
    <div className={classnames(className, classes.container, classes.booleanDefaultValueEditor)}>
      <DefaultValueFormControlLabel
        className={classnames("defaultValue", classes.inputField, classes.checkboxContainer)}
        label="Add Default Value (optional)?"
        control={(
          <Checkbox
            className={classnames("defaultValueEnabled", classes.checkbox)}
            color="primary"
            disabled={disabled}
            checked={defaultValueEnabled}
            onChange={toggleDefaultValueEnabled}
          />
        )}
      />
      {defaultValueEnabled && (
        <FilterSwitch
          className={classnames("defaultValue", classes.filterSwitch)}
          leftLabelClassName={classnames("left", classes.filterSwitchLabel)}
          rightLabelClassName={classnames("left", classes.filterSwitchLabel)}
          leftLabel="false"
          rightLabel="true"
          disabled={disabled}
          checked={defaultValueTrue}
          onChange={setDefaultValue}
        />
      )}
    </div>
  );
});

export default BooleanDefaultValueEditor;
