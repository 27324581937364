import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  DeviceTypesListActions,
  DeviceTypesListModel,
  DeviceTypesList
} from "@components";
import styles from "./styles";
import { DeviceTypeListItem } from "@data";
import DeviceTypeListColumn from "@components/device-types-list/DeviceTypeListColumn";

export const DEVICE_TYPES_COLUMNS: DeviceTypeListColumn[] = [
  DeviceTypeListColumn.NAMESPACE,
  DeviceTypeListColumn.NAME,
];

export interface Model extends DeviceTypesListActions {
}

export interface Actions extends DeviceTypesListModel {
  setSelectedType: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DeviceTypeView = withStyles(styles)((props: Props) => {

  const {
    classes,
    setSelectedType = noop,
    children,
    ...otherProps
  } = props;

  const setSelectedDeviceType = React.useCallback((deviceTypes: DeviceTypeListItem[]) => {
    setSelectedType(deviceTypes.pop());
  }, [setSelectedType]);

  return(
    <div className={classnames("deviceTypeView", classes.deviceTypeView)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        Select Device Type
      </Typography>
      <DeviceTypesList
        {...otherProps}
        className={classnames("deviceTypeList", classes.deviceTypeList)}
        selectable={true}
        hideSummary={true}
        columns={DEVICE_TYPES_COLUMNS}
        selectAllDisabled={true}
        setSelectedItems={setSelectedDeviceType}
      />
      {children}
    </div>
  );
});

export default DeviceTypeView;
