import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useSecurityServicesRegionalClient
} from "@hooks";
import { SecurityServiceRegional, SecurityServiceRegionalAttributes } from "@data";

type SuccessResponse = SecurityServiceRegionalAttributes;

export interface UseGetServiceProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  serviceId: string;
}

export interface UseGetServiceModel extends UseApiRequestModel<SuccessResponse> {
  service: SecurityServiceRegional;
}

export interface UseGetServiceActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetServiceProps;
type Model = UseGetServiceModel;
type Actions = UseGetServiceActions;
type Result = [Model, Actions];

export const useGetServiceRegional = (props: Props): Result => {

  const {
    serviceId = "",
    defaultErrorMessage = "Failed to get service",
    ...otherProps
  } = props;

  const SecurityServiceRegionalClient = useSecurityServicesRegionalClient();

  const makeApiRequest = React.useCallback(() =>
      SecurityServiceRegionalClient.getSecurityServiceRegionalApi(serviceId),
    [SecurityServiceRegionalClient]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const service = React.useMemo<SecurityServiceRegional>(() => {
    const attrs = successResponse || {};
    return new SecurityServiceRegional(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    service,
  }), [
    baseModel,
    service,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetServiceRegional;
