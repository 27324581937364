import { isEmptyString } from "@util";
import { ReportType, ReportFormat, UserReportAttributes } from "@data";
import { makeJsonApiRequest, withAuthToken, withRequiredArguments } from "@network/helpers";

const ADMIN_REPORTING_URL = process.env.REACT_APP_USER_IDM_ADMIN_REPORTING_API || "";

if (isEmptyString(ADMIN_REPORTING_URL)) {
  throw new Error("Missing Environment Variable: REACT_APP_USER_IDM_ADMIN_REPORTING_API");
}

export interface CreateUserReportResponse {
  id: string;
  type: ReportType;
  format: ReportFormat;
}

export const createUserReport = (authToken: string,
                                 json: string): Promise<CreateUserReportResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments( [
      ["Json Body", json],
    ]));

  const makeRequest = () => {

    const url = `${ADMIN_REPORTING_URL}/security/identity/v1/user-reports`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to create user report";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getUserReport = (authToken: string,
                              reportId: string): Promise<UserReportAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments( [
      ["Report Id", reportId],
    ]));

  const makeRequest = () => {

    const url = `${ADMIN_REPORTING_URL}/security/identity/v1/user-reports/${reportId}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to get user report";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
