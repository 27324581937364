import React from "react";
import { useHistory } from "react-router-dom";
import { ManagedPolicy } from "@data";
import { getPathToManagedPolicy } from "@modules/managedPolicyDetails/helpers";
import ManagedPolicies from "./containers/ManagedPolicies";

export function ManagedPolicyView() {

  const history = useHistory();

  const showManagedPolicyDetails = React.useCallback((managedPolicy: ManagedPolicy) =>
    history.push(getPathToManagedPolicy(
      managedPolicy.getPolicy().getName(),
      managedPolicy.getAccountId())),
    [history]);

  return (
    <ManagedPolicies
      showManagedPolicyDetails={showManagedPolicyDetails}
    />
  );
}

export default ManagedPolicyView;
