import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import { DSTypeInfo } from "@data/DSTypeInfo";
import useDigitalShadowClient from "../use-digital-shadow-client";
import { ETagHeaders } from "@network";

type SuccessResponse = ETagHeaders;

export interface UseUpdateDSTypeProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  requestBody?: DSTypeInfo;
  requestEtag?: string;
}

export interface UseUpdateDSTypeModel extends UseApiRequestModel<SuccessResponse> {
  etag: string;
  successMessage: string;
}

export interface UseUpdateDSTypeActions extends UseApiRequestActions<SuccessResponse> {
  updateType: () => void;
}

type Props = UseUpdateDSTypeProps;
type Model = UseUpdateDSTypeModel;
type Actions = UseUpdateDSTypeActions;
type Result = [ Model, Actions ];

export const useUpdateDSType = (props: Props): Result => {

  const {
    requestBody = DSTypeInfo.EMPTY,
    requestEtag = "",
    defaultErrorMessage = "Failed to update shadow type",
    ...otherProps
  } = props;

  const DigitalShadowClient = useDigitalShadowClient();

  const typeName = React.useMemo(() => requestBody.getName(), [requestBody]);

  const editRequestBody = React.useMemo(() => {
    return ({
      description: requestBody.getDescription(),
      metadata: requestBody.getMetadata()
    });
  }, [requestBody]);

  const makeApiRequest = React.useCallback(() =>
      DigitalShadowClient.updateTypeApi(typeName, JSON.stringify(editRequestBody), requestEtag),
    [DigitalShadowClient, typeName, editRequestBody, requestEtag]);

  const [{ successResponse, showSuccessView, ...baseModel },
    { refresh: updateType, ...baseActions }
  ] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Shadow Type updated" : "",
    [ showSuccessView ]);

  const etag = React.useMemo(() => {
    return successResponse?.etag || "";
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    etag,
    location,
    successMessage,
    showSuccessView
  }), [
    baseModel,
    etag,
    location,
    successMessage,
    showSuccessView
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    updateType,
    refresh: updateType,
  }), [
    baseActions,
    updateType,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useUpdateDSType;
