import React from "react";
import { Module } from "@data";
import { MODULE_ID } from "./constants";
import { isDialogOpen } from "./selectors";
import { initialize, open } from "./actions";
import { isDeviceManagementEnabled } from "@util";
import { PrimaryIcon } from "@modules/devices/components/styles";
import DisenrollDeviceDialog from "./containers/DisenrollDeviceDialog";

export const disenrollDeviceModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "Disenroll Device",
  title: "Disenroll Device",
  icon: PrimaryIcon,
  view: <DisenrollDeviceDialog />,
  onClick: open,
  isDialogOpen,
  initialize,
  disabled: !isDeviceManagementEnabled(),
};

export default disenrollDeviceModule;
