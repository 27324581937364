import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import ErrorView from "@components/error-view";
import RefreshButton from "@components/refresh-button";
import ActionMenuItem from "@components/actions-menu/ActionMenuItem";
import { JsonSchemaDefinition, JsonSchemaMetadataAttributes, JsonSchemaProperty } from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import SchemaPropertiesBrowserLabel from "./SchemaPropertiesBrowserLabel";
import SchemaPropertiesBreadcrumbs from "./SchemaPropertiesBreadcrumbs";
import SchemaPropertiesTable from "./SchemaPropertiesTable";
import SchemaProperties from "./SchemaProperties";
import { AccessDeniedView } from "@components";
import styles from "./styles";

export interface Model {
  className?: string;
  title?: string;
  addButtonLabel?: string;
  noResultsLabel?: string;
  loadingLabel?: string;
  refreshEnabled?: boolean;
  actions?: ActionMenuItem[];
  breadcrumbs?: string[];
  schema?: JsonSchemaDefinition;
  metadata?: JsonSchemaMetadataAttributes;
  properties?: JsonSchemaProperty[];
  nestedSchemaRef?: string;
  errorMessage?: string;
  showAccessDenied?: boolean;
  showNotFoundError?: boolean;
  showLoadingIndicator?: boolean;
}

export interface Actions {
  refresh?: () => void;
  addProperty?: () => void;
  clearBreadcrumbs?: () => void;
  setBreadcrumbs?: (breadcrumbs: string[]) => void;
  showParentProperty?: () => void;
  showObjectProperty?: (property: JsonSchemaProperty) => void;
  showSchemaRefProperty?: (property: JsonSchemaProperty) => void;
  onClickBreadcrumbsAction?: (action: ActionMenuItem) => void;
  onClickPropertyAction?: (property: JsonSchemaProperty, action: ActionMenuItem) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SchemaPropertiesBrowser = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title,
    addButtonLabel,
    noResultsLabel,
    loadingLabel,
    refreshEnabled,
    breadcrumbs = [],
    properties,
    errorMessage = "",
    nestedSchemaRef = "",
    showAccessDenied,
    showNotFoundError,
    showLoadingIndicator,
    actions,
    refresh,
    addProperty,
    clearBreadcrumbs,
    setBreadcrumbs,
    showParentProperty,
    showObjectProperty,
    showSchemaRefProperty,
    onClickBreadcrumbsAction,
    onClickPropertyAction,
  } = props;

  if (showAccessDenied) { return <AccessDeniedView/>; }

  if (showNotFoundError
    && breadcrumbs.length > 0
    && typeof showParentProperty === "function"
    && showParentProperty !== noop) {

    showParentProperty();

    return null;
  }

  const showNestedSchemaProperties = nestedSchemaRef.length > 0;

  const propertyName = breadcrumbs.slice().pop();

  const showRefreshButton = showLoadingIndicator ||
    (refreshEnabled && typeof refresh === "function" && refresh !== noop);

  return (
    <div className={classnames("schemaPropertiesBrowser", className, classes.container)}>
      <SchemaPropertiesBreadcrumbs
        title={title}
        breadcrumbs={breadcrumbs}
        actions={actions}
        clearBreadcrumbs={clearBreadcrumbs}
        setBreadcrumbs={setBreadcrumbs}
        onClickAction={onClickBreadcrumbsAction}
      >
        {showRefreshButton && (
          <RefreshButton
            className={classnames("refreshButton", classes.refreshButton)}
            iconClassName={classnames("refreshButtonIcon", classes.refreshButtonIcon)}
            loadingIndicatorSize={28}
            loading={showLoadingIndicator}
            refresh={refresh}
          />
        )}
      </SchemaPropertiesBreadcrumbs>
      {showNestedSchemaProperties && (
        <React.Fragment>
          <SchemaPropertiesBrowserLabel
            className="nestedSchemaRefLabel"
            name="$ref"
            value={nestedSchemaRef}
          />
          <SchemaProperties
            className="nestedSchemaProperties"
            title="Schema Reference Properties"
            actions={[]}
            refreshEnabled={true}
            location={nestedSchemaRef}
          />
        </React.Fragment>
      )}
      {!showNestedSchemaProperties && (
        <React.Fragment>
          {showNotFoundError && (
            <ErrorView
              className="notFoundError"
              title="Not Found"
              message={`Unable to find '${propertyName || "schema properties"}'`}
            />
          )}
          {!showNotFoundError && (
            <React.Fragment>
              {errorMessage.length > 0 && (<ErrorView message={errorMessage} />)}
              <SchemaPropertiesTable
                addButtonLabel={addButtonLabel}
                noResultsLabel={noResultsLabel}
                loadingLabel={loadingLabel}
                properties={properties}
                actions={actions}
                showLoadingIndicator={showLoadingIndicator}
                addProperty={addProperty}
                showObjectProperty={showObjectProperty}
                showSchemaRefProperty={showSchemaRefProperty}
                onClickPropertyAction={onClickPropertyAction}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
});

export default SchemaPropertiesBrowser;
