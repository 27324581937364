import { createStyles } from "@material-ui/core/styles";

export const styles = () =>
  createStyles({
    button: {
    },
    label: {
    },
    uppercase: {
      textTransform: "uppercase",
    },
  });

export default styles;
