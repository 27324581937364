import { Record } from "immutable";
import { getStringValue } from "@util";

export enum SecurityProtocol {
  SHARED_SECRET = "shared-secret:1",
  NONE = "",
}

export interface CreateServiceSecurityAttributes {
  protocol: SecurityProtocol;
}

export interface CreateSecurityServiceRegionalRequestAttributes {
  id: string;
  security: CreateServiceSecurityAttributes;
}

export class CreateSecurityServiceRegionalRequest extends Record({
  id: "",
  security: {
    protocol: SecurityProtocol.SHARED_SECRET,
  }
}) implements CreateSecurityServiceRegionalRequestAttributes {

  public static EMPTY: CreateSecurityServiceRegionalRequest = new CreateSecurityServiceRegionalRequest();

  public readonly id: string;
  public readonly security: CreateServiceSecurityAttributes;

  public getId(): string {
    return getStringValue(this.id);
  }

  public getProtocol(): string {
    const { protocol: protocol = "" }  = this.security || {};

    return getStringValue(protocol);
  }
}
