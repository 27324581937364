import React from "react";
import classnames from "classnames";
import MuiRadio, { RadioProps } from "@material-ui/core/Radio";

export interface CustomRadioProps extends RadioProps {}

export const Radio = ((props: CustomRadioProps) => {

    const {
      className,
      ...otherProps
    } = props;

    return (
      <MuiRadio
        {...otherProps}
        className={classnames("radio", className)}
      />
    );
});

export default Radio;
