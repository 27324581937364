import { combineReducers } from "redux";
import { Actions, filterAction, Identity, ReduxAction } from "@util";
import {
  JsonSchemaDefinition,
  JsonSchemaMetadata,
  JsonSchemaMetadataAttributes,
} from "@data";

export interface SchemaDetailsModuleSchema {
  json: string;
  schema: JsonSchemaDefinition;
  metaData: JsonSchemaMetadataAttributes;
  errorMessage: string;
  showNotFound: boolean;
  showEmptyView: boolean;
  showAccessDenied: boolean;
  showLoadingIndicator: boolean;
  isLatest: boolean;
}

export enum SchemaDetailsActionType {
  SET_JSON = "schema_details/set_json",
  SET_SCHEMA = "schema_details/set_schema",
  SET_META_DATA = "schema_details/set_meta_data",
  SET_ERROR_MESSAGE = "schema_details/set_error_message",
  SET_SHOW_NOT_FOUND = "schema_details/set_show_not_found",
  SET_SHOW_EMPTY_VIEW = "schema_details/set_show_empty_view",
  SET_SHOW_ACCESS_DENIED = "schema_details/set_show_access_denied",
  SET_SHOW_LOADING_INDICATOR = "schema_details/set_show_loading_indicator",
  SET_IS_LATEST = "schema_details/set_is_latest",
  LOAD_SCHEMA_REQUEST = "schema_details/load_schema_request",
  LOAD_SCHEMA_REQUEST_SUCCESS = "schema_details/load_schema_request_success",
  LOAD_SCHEMA_REQUEST_FAILED = "schema_details/load_schema_request_failed",
  VALIDATE_SCHEMA_REQUEST = "schema_details/validation_view/validate_schema_request",
  VALIDATE_SCHEMA_SUCCESS = "schema_details/validation_view/validate_schema_success",
  VALIDATE_SCHEMA_FAILED = "schema_details/validation_view/validate_schema_failed",
}

export type SchemaDetailsAction = ReduxAction<SchemaDetailsActionType>;

export const DEFAULT_SCHEMA: JsonSchemaDefinition = Object.freeze({
  $schema: "",
  title: "",
  description: "",
  type: "",
  properties: {},
  required: [],
});

export const DEFAULT_STATE: SchemaDetailsModuleSchema = Object.freeze({
  json: JSON.stringify(DEFAULT_SCHEMA, null, "  "),
  schema: Object.freeze({ ...DEFAULT_SCHEMA }),
  metaData: Object.freeze({ ...JsonSchemaMetadata.EMPTY.toJS() }),
  errorMessage: "",
  showNotFound: false,
  showEmptyView: false, // Prefer to only show empty view once if details view is already active
  showAccessDenied: false,
  showLoadingIndicator: false,
  isLatest: false,
});

const json = filterAction<string>(Actions(
  SchemaDetailsActionType.SET_JSON))(Identity,
  DEFAULT_STATE.json);

const schema = filterAction<JsonSchemaDefinition>(Actions(
  SchemaDetailsActionType.SET_SCHEMA))(Identity,
  DEFAULT_STATE.schema);

const metaData = filterAction<JsonSchemaMetadataAttributes>(Actions(
  SchemaDetailsActionType.SET_META_DATA))(Identity,
  DEFAULT_STATE.metaData);

const errorMessage = filterAction<string>(Actions(
  SchemaDetailsActionType.SET_ERROR_MESSAGE))(Identity,
  DEFAULT_STATE.errorMessage);

const showNotFound = filterAction<boolean>(Actions(
  SchemaDetailsActionType.SET_SHOW_NOT_FOUND))(Identity,
  DEFAULT_STATE.showNotFound);

const showEmptyView = filterAction<boolean>(Actions(
  SchemaDetailsActionType.SET_SHOW_EMPTY_VIEW))(Identity,
  DEFAULT_STATE.showEmptyView);

const showAccessDenied = filterAction<boolean>(Actions(
  SchemaDetailsActionType.SET_SHOW_ACCESS_DENIED))(Identity,
  DEFAULT_STATE.showAccessDenied);

const showLoadingIndicator = filterAction<boolean>(Actions(
  SchemaDetailsActionType.SET_SHOW_LOADING_INDICATOR))(Identity,
  DEFAULT_STATE.showLoadingIndicator);

const isLatest = filterAction<boolean>(Actions(
  SchemaDetailsActionType.SET_IS_LATEST))(Identity,
  DEFAULT_STATE.isLatest);

export const SchemaDetailsReducer = combineReducers<SchemaDetailsModuleSchema>({
  json,
  schema,
  metaData,
  errorMessage,
  showNotFound,
  showEmptyView,
  showAccessDenied,
  showLoadingIndicator,
  isLatest,
});

export default SchemaDetailsReducer;
