import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";
import { AccessRequestType, AccessRequestPrincipalType } from "@data/DataAccessRequest";

const ACCOUNT_ID_REGEX = new RegExp(/lrn.*[:]([0-9]+)/);

const getAccountIdFromDataSetId = (dataSetId: string) => {
  const match = dataSetId.match(ACCOUNT_ID_REGEX);

  if (!Array.isArray(match) || match.length === 0) {
    return "";
  } else {
    return getStringValue(match.pop()); // we want the last match
  }
};

export interface CreateDataAccessRequestAttributes {
  accessRequestPrincipalType: AccessRequestPrincipalType;
  accessRequestType: AccessRequestType;
  accountId: string;
  dataSetAlias: string;
  dataSetGroup: string | undefined;
  reason: string;
  servicePrincipalIdentifier: string | undefined;
  deviceType: string | undefined;
  deviceNamespace: string | undefined;
  isAccessForExistingCloudResource: boolean | undefined;
  trustedAccountsForCloudRole: string[] | undefined;
  cloudRoleIdentifier: string | undefined;
  principalAccountForCloudStorage: string | undefined;
}

export class CreateDataAccessRequest extends Record({
  accessRequestPrincipalType: AccessRequestPrincipalType.IOT_USER_PRINCIPAL,
  accessRequestType: AccessRequestType.READ,
  accountId: "",
  dataSetAlias: "",
  dataSetGroup: undefined,
  reason: "",
  servicePrincipalIdentifier: undefined,
  deviceType: undefined,
  deviceNamespace: undefined,
  isAccessForExistingCloudResource: undefined,
  trustedAccountsForCloudRole: undefined,
  cloudRoleIdentifier: undefined,
  principalAccountForCloudStorage: undefined,
}) implements CreateDataAccessRequestAttributes {

  public static EMPTY: CreateDataAccessRequest = new CreateDataAccessRequest();

  public readonly accessRequestPrincipalType: AccessRequestPrincipalType;
  public readonly accessRequestType: AccessRequestType;
  public readonly accountId: string;
  public readonly dataSetAlias: string;
  public readonly dataSetGroup: string | undefined;
  public readonly reason: string;
  public readonly servicePrincipalIdentifier: string | undefined;
  public readonly deviceType: string | undefined;
  public readonly deviceNamespace: string | undefined;
  public readonly isAccessForExistingCloudResource: boolean | undefined;
  public readonly trustedAccountsForCloudRole: string[] | undefined;
  public readonly cloudRoleIdentifier: string | undefined;
  public readonly principalAccountForCloudStorage: string | undefined;

  public static fromDataSetId(dataSetId: string): CreateDataAccessRequest {
    return new CreateDataAccessRequest({
      dataSetAlias: getStringValue(dataSetId.split(":").pop()),
      accountId: getAccountIdFromDataSetId(dataSetId),
    });
  }

  public getAccessRequestPrincipalType(): AccessRequestPrincipalType {
    return this.accessRequestPrincipalType;
  }

  public getAccessRequestType(): AccessRequestType {
    return this.accessRequestType;
  }

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getDataSetGroup(): string {
    return getStringValue(this.dataSetGroup);
  }

  public getDataSetAlias(): string {
    return getStringValue(this.dataSetAlias);
  }

  public getReason(): string {
    return getStringValue(this.reason);
  }

  public hasReason(): boolean {
    return !isEmptyString(this.getReason());
  }

  public isServicePrincipalRequest(): boolean {
    return this.getAccessRequestPrincipalType() === AccessRequestPrincipalType.IOT_SERVICE_PRINCIPAL;
  }

  public isDeviceTypeRequest(): boolean {
    return this.getAccessRequestPrincipalType() === AccessRequestPrincipalType.IOT_DEVICE_TYPE;
  }

  public getServicePrincipalIdentifier(): string {
    return getStringValue(this.servicePrincipalIdentifier);
  }

  public getDeviceType(): string {
    return getStringValue(this.deviceType);
  }

  public getDeviceNamespace(): string {
    return getStringValue(this.deviceNamespace);
  }

  public hasServicePrincipalIdentifier(): boolean {
    return !isEmptyString(this.getServicePrincipalIdentifier());
  }

  public isDataAccessRequestForExistingCloudResource(): boolean {
    return this.isAccessForExistingCloudResource === true;
  }

  public isIotCloudRole(): boolean {
    return this.getAccessRequestPrincipalType() === AccessRequestPrincipalType.IOT_CLOUD_ROLE;
  }

  public isIotCloudStorageRequest(): boolean {
    return this.getAccessRequestPrincipalType() === AccessRequestPrincipalType.IOT_CLOUD_STORAGE;
  }

  public getTrustedAccountsForCloudRole(): string[] {
    const trustedAccountsForCloudRole = this.trustedAccountsForCloudRole;

    if (!Array.isArray(trustedAccountsForCloudRole)) {
      return [];
    }

    return trustedAccountsForCloudRole.map(getStringValue).filter(id => !isEmptyString(id));
  }

  public hasTrustedAccountsForCloudRole(): boolean {
    return this.getTrustedAccountsForCloudRole().length > 0;
  }

  public getCloudRoleIdentifier(): string {
    return getStringValue(this.cloudRoleIdentifier);
  }

  public hasCloudRoleIdentifier(): boolean {
    return !isEmptyString(this.getCloudRoleIdentifier());
  }

  public getPrincipalAccountForCloudStorage(): string {
    return getStringValue(this.principalAccountForCloudStorage);
  }

  public hasPrincipalAccountForCloudStorage(): boolean {
    return !isEmptyString(this.getPrincipalAccountForCloudStorage());
  }
}

export default CreateDataAccessRequest;
