import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import ActionsMenu, { ActionMenuItem, ActionsMenuProps } from "@components/actions-menu";

export enum DSLayoutAction {
  EDIT_DS_LAYOUT_ACTION = "edit_ds_layout_action",
  DELETE_DS_LAYOUT_ACTION = "delete_ds_layout_action",
}

export const EDIT_DS_LAYOUT_ACTION: ActionMenuItem = Object.freeze({
  id: DSLayoutAction.EDIT_DS_LAYOUT_ACTION,
  name: "Edit Shadow Layout",
  disabled: false,
});

export const DELETE_DS_LAYOUT_ACTION: ActionMenuItem = Object.freeze({
  id: DSLayoutAction.DELETE_DS_LAYOUT_ACTION,
  name: "Delete Shadow Layout",
  disabled: false,
});

export const DEFAULT_DS_LAYOUT_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  EDIT_DS_LAYOUT_ACTION,
  DELETE_DS_LAYOUT_ACTION,
]) as ActionMenuItem[];

export interface DSLayoutActionsMenuProps extends ActionsMenuProps {
  editDSLayout?: () => void;
  deleteDSLayout?: () => void;
}

export const withDSLayoutActions = (Component: React.ComponentType<DSLayoutActionsMenuProps>) =>
  (props: DSLayoutActionsMenuProps) => {

    const {
      actions = DEFAULT_DS_LAYOUT_ACTION_MENU_ITEMS,
      editDSLayout = noop,
      deleteDSLayout = noop,
      onClickAction = noop,
    } = props;

    return (
      <Component
        {...props}
        actions={actions}
        onClickAction={(action: ActionMenuItem) => {
          if (action.id === DSLayoutAction.EDIT_DS_LAYOUT_ACTION) {
            editDSLayout();
          } else if (action.id === DSLayoutAction.DELETE_DS_LAYOUT_ACTION) {
            deleteDSLayout();
          } else {
            onClickAction(action);
          }
        }}
      />
    );
  };

export const DSLayoutActionsMenu = withDSLayoutActions((props: DSLayoutActionsMenuProps) => {

  const { className, buttonLabel = "Shadow Layout Actions" } = props;

  return (
    <ActionsMenu
      {...props}
      className={classnames("dsLayoutActionsMenu", className)}
      buttonLabel={buttonLabel}
    />
  );
});

export default DSLayoutActionsMenu;
