import { Record } from "immutable";
import { getStringValue } from "@util";
import {
  DataClassification,
  DataRate,
  DataSetType,
  IngestionMechanism,
  LifecyclePolicy
} from "@data/DataSetRequest";

export interface CreateDataSetRequestAttributes {
  dataSetAlias: string;
  description: string;
  dataSetGroup: string;
  dataSetType: DataSetType;
  tags?: string[];
  dataOwners: string[];
  architects: string[];
  engineeringContacts: string[];
  businessOwners: string[];
  schema: string;
  sampleAnonymizedData: string;
  ingestionMechanism?: IngestionMechanism;
  provenance: string;
  dataClassification: DataClassification;
  sourceGeographies: string;
  sharedGeographies: string;
  dataRate?: DataRate;
  lifecyclePolicy?: LifecyclePolicy;
  confirmNonProductionData?: boolean;
  termsConditionsId?: string;
  containsPII: boolean;
  piiComment?: string;
}

export class CreateDataSetRequest extends Record({
  dataSetAlias: "",
  description: "",
  dataSetGroup: "",
  dataSetType: DataSetType.DATA_LAKE_ACQUIRED,
  tags: [],
  dataOwners: [],
  architects: [],
  engineeringContacts: [],
  businessOwners: [],
  schema: "",
  sampleAnonymizedData: "",
  ingestionMechanism: IngestionMechanism.NONE,
  dataClassification: DataClassification.INTERNAL,
  provenance: "N/A",
  sourceGeographies: "N/A",
  sharedGeographies: "N/A",
  dataRate: {
    dataVolume: 0,
    dataVelocity: 0
  },
  lifecyclePolicy: {
    archivingInDays: 0,
    expirationInDays: 0
  },
  confirmNonProductionData: false,
  termsConditionsId: null,
  containsPII: false,
  piiComment: "",
}) implements CreateDataSetRequestAttributes {

  public static EMPTY: CreateDataSetRequest = new CreateDataSetRequest();

  public readonly dataSetAlias: string;
  public readonly description: string;
  public readonly dataSetGroup: string;
  public readonly dataSetType: DataSetType;
  public readonly tags: string[];
  public readonly dataOwners: string[];
  public readonly architects: string[];
  public readonly engineeringContacts: string[];
  public readonly businessOwners: string[];
  public readonly schema: string;
  public readonly sampleAnonymizedData: string;
  public readonly ingestionMechanism: IngestionMechanism;
  public readonly provenance: string;
  public readonly dataClassification: DataClassification;
  public readonly sourceGeographies: string;
  public readonly sharedGeographies: string;
  public readonly dataRate: DataRate;
  public readonly lifecyclePolicy: LifecyclePolicy;
  public readonly confirmNonProductionData: boolean;
  public readonly termsConditionsId?: string;
  public readonly containsPII: boolean;
  public readonly piiComment?: string;

  public getDataSetAlias(): string {
    return getStringValue(this.dataSetAlias);
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public getDataSetGroup(): string {
    return getStringValue(this.dataSetGroup);
  }

  public getDataSetType(): DataSetType {
    return this.dataSetType;
  }

  public getTags(): string[] {
    return this.tags;
  }

  public getDataOwners(): string[] {
    return this.dataOwners;
  }

  public getArchitects(): string[] {
    return this.architects;
  }

  public getEngineeringContacts(): string[] {
    return this.engineeringContacts;
  }

  public getBusinessOwners(): string[] {
    return this.businessOwners;
  }

  public getSchema(): string {
    return getStringValue(this.schema);
  }

  public getSampleAnonymizedData(): string {
    return getStringValue(this.sampleAnonymizedData);
  }

  public getIngestionMechanism(): IngestionMechanism {
    return this.ingestionMechanism;
  }

  public getProvenance(): string {
    return getStringValue(this.provenance);
  }

  public getDataClassification(): DataClassification {
    return this.dataClassification;
  }

  public getSourceGeographies(): string {
    return getStringValue(this.sourceGeographies);
  }

  public getSharedGeographies(): string {
    return getStringValue(this.sharedGeographies);
  }

  public getDataRate(): DataRate {
    return this.dataRate;
  }

  public getLifecyclePolicy(): LifecyclePolicy {

    const { archivingInDays = 0, expirationInDays = 0 } = this.lifecyclePolicy || {};

    return {
      archivingInDays,
      expirationInDays,
    };
  }

  public getArchivingInDaysLifecyclePolicy(): number {
    const { archivingInDays = 0 } = this.getLifecyclePolicy();
    return archivingInDays;
  }

  public getExpirationInDaysLifecyclePolicy(): number {
    const { expirationInDays = 0 } = this.getLifecyclePolicy();
    return expirationInDays;
  }

  public getConfirmNonProductionData(): boolean {
    return this.confirmNonProductionData;
  }

  public getTermsConditionsId(): string {
    return getStringValue(this.termsConditionsId);
  }

  public getContainsPII(): boolean {
    return this.containsPII;
  }
}
