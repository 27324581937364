import React from "react";
import { DSLayoutInfoMetadata } from "@data";
import { layoutInfoView as styles, textAreaField, textField } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { clickHandler, formEventHandler, hasSpecialChars, noop } from "@util";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import TextField from "@components/text-field";
import { Chip, IconButton } from "@components";
import AddIcon from "@material-ui/icons/AddOutlined";
import RemoveIcon from "@material-ui/icons/Clear";
import MetadataIcon from "@material-ui/icons/Label";

const NameTextField = withStyles(textField)(TextField);
const MetadataTextField = withStyles(textField)(TextField);
const DescriptionTextArea = withStyles(textAreaField)(TextField);

export interface Model {
  title?: string;
  name?: string;
  description?: string;
  editMode?: boolean;
  metadata?: DSLayoutInfoMetadata;
}

export interface Actions {
  setName?: (name: string) => void;
  setDescription?: (description: string) => void;
  addMetadataEntry?: (key: string, value: string) => void;
  removeMetadataEntry?: (key: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const LayoutInfoView = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Set Basic Layout Information",
    description = "",
    name = "",
    editMode = false,
    metadata = {},
    children,
    setName = noop,
    setDescription = noop,
    addMetadataEntry = noop,
    removeMetadataEntry = noop,
  } = props;

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const [key, setKey] = React.useState("");
  const [value, setValue] = React.useState("");

  const addMetadata = React.useCallback(() => {
    addMetadataEntry(key, value);
    setKey("");
    setValue("");
  }, [key, value, addMetadataEntry, setValue, setKey]);

  const removeMetadata = (k: string) => () => {
    if (removeMetadataEntry) {
      return removeMetadataEntry(k);
    }
  };

  const validMetadata = React.useMemo(() => 
    key.trim().length > 0 && value.trim().length > 0 && !hasSpecialChars(key), [key, value]);

  return (
    <div className={classnames("layoutInfoView", classes.container)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      <NameTextField
        className={classnames("nameTextField", classes.nameTextField)}
        autoComplete="off"
        required={true}
        label="Layout Name"
        name="name"
        value={name}
        fullWidth={true}
        autoFocus={!editMode}
        disabled={editMode}
        variant="outlined"
        margin="none"
        disableSpecialChars={true}
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setName)}
      />
      <DescriptionTextArea
        className={classnames("description", classes.description)}
        autoComplete="off"
        name="description"
        label="Description"
        value={description}
        fullWidth={true}
        multiline={true}
        minRows={3}
        maxRows={3}
        autoFocus={editMode}
        variant="outlined"
        margin="none"
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setDescription)}
      />
      <Typography className={classnames("metadata", classes.metadata)} variant="h5">
        Layout Metadata
      </Typography>
      <div className={classnames("metadataContainer", classes.metadataContainer)}>
        <MetadataTextField
          className={classnames("metadataKey", classes.metadataKey)}
          autoComplete="off"
          name="metadataKey"
          label="Key"
          value={key}
          variant="outlined"
          disableSpecialChars={true}
          InputLabelProps={inputLabelProps}
          onChange={formEventHandler(setKey)}
        />
        <MetadataTextField
          className={classnames("metadataValue", classes.metadataValue)}
          autoComplete="off"
          name="metadataValue"
          label="Value"
          value={value}
          variant="outlined"
          InputLabelProps={inputLabelProps}
          onChange={formEventHandler(setValue)}
        />
        {validMetadata && (
          <IconButton
            className={classnames("addMetadataButton", classes.addMetadataButton)}
            color="primary"
            onClick={clickHandler(addMetadata)}
          >
            <AddIcon />
          </IconButton>
        )}
      </div>
      <div className={classnames("metadataContainer", classes.metadataContainer)}>
        {Object.entries(metadata).map((data: string[] = []) => (
          <Chip
            key={data[0]}
            label={`${data[0]} : ${data[1]}`}
            color="primary"
            className={classnames("metadataTag", classes.metadataTag)}
            icon={<MetadataIcon className={classnames("scopeIcon", classes.metadataIcon)} />}
            deleteIcon={<RemoveIcon className={classnames("removeIcon", classes.metadataIcon)} />}
            onDelete={removeMetadata(data[0])}
          />
        ))}
      </div>
      {children}
    </div>
  );
});

export default LayoutInfoView;
