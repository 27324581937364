import { isEmptyString } from "@util";
import {
  AccessRequestPrincipalType as PrincipalType,
  AccessRequestType,
  DataAccessRequestAttributes,
  DataLakeDataSetAttributes,
  DataSetAccessRequestAttributes,
  GetRequestCommentsResponse,
  TrainingCertificateInfoAttributes,
} from "@data";
import {
  createQueryParams,
  makeApiRequest,
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments,
} from "@network/helpers";

const DATA_ACCESS_REQUEST_API = process.env.REACT_APP_DATA_ACCESS_REQUEST_API || "";

if (isEmptyString(DATA_ACCESS_REQUEST_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_DATA_ACCESS_REQUEST_API");
}

export interface CreateDataAccessRequestResponse {
  accessRequestId: string;
}

export interface GetDataAccessRequestsResponse {
  accessRequests: DataAccessRequestAttributes[];
  paging?: {
    next?: string;
  };
}

export interface ListAccessibleDataSetsResponse {
  accessibleDataSets: DataLakeDataSetAttributes[];
  paging?: {
    next?: string;
  };
}

export interface TrainingStatusResponse {
  trainingCompleted: boolean;
}

export interface TrainingCertUrlResponse {
  trainingCertificateUrl: string;
}

export interface ListPrincipalIdentifiersResponse {
  accountId: string;
  principalType: PrincipalType;
  principalIdentifiers: string[];
}

export interface GetDataSetAccessRequestsResponse {
  provisionedAccesses: DataSetAccessRequestAttributes[];
}

export interface RevokeAccessResponse {
  revokeAccessRequestId: string;
}

export const getDataAccessRequests = (authToken: string): Promise<GetDataAccessRequestsResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${DATA_ACCESS_REQUEST_API}/data/management/v1/access-requests/requested-by/me`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Data Access Requests failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getAccountDataAccessRequests = (authToken: string): Promise<GetDataAccessRequestsResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${DATA_ACCESS_REQUEST_API}/data/management/v1/access-requests/requested-by/principal-account-id`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Data Access Requests failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const createDataAccessRequest = (authToken: string,
                                        json: string): Promise<CreateDataAccessRequestResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Data Access Request", json],
    ]));

  const makeRequest = () => {

    const url = `${DATA_ACCESS_REQUEST_API}/data/management/v1/access-requests`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to create Data Access Request";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getDataAccessRequest = (authToken: string,
                                     accessRequestId: string): Promise<DataAccessRequestAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Access Request Id", accessRequestId],
    ]));

  const makeRequest = () => {

    const url = `${DATA_ACCESS_REQUEST_API}/data/management/v1/access-requests/${accessRequestId}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Data Access Request failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getDataAccessRequestComments = (authToken: string,
                                             accessRequestId: string)
  : Promise<GetRequestCommentsResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Access Request Id", accessRequestId]
    ]));

  const makeRequest = () => {

    const url = `${DATA_ACCESS_REQUEST_API}/data/management/v1/access-requests/${accessRequestId}/comments`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Data Set Request Comments failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const createDataAccessRequestComment = (authToken?: string,
                                               accessRequestId?: string,
                                               json?: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Access Request Id", accessRequestId],
      ["Comment", json],
    ]));

  const makeRequest = () => {

    const url = `${DATA_ACCESS_REQUEST_API}/data/management/v1/access-requests/${accessRequestId}/comments`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to add comment";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };
  return validate().then(makeRequest);
};

export const deleteDataAccessRequest = (authToken: string,
                                        accessRequestId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Access Request Id", accessRequestId],
    ]));

  const makeRequest = () => {

    const url = `${DATA_ACCESS_REQUEST_API}/data/management/v1/access-requests/${accessRequestId}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to cancel data access request [${accessRequestId}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const uploadTrainingCert = (authToken: string,
                                   file: File): Promise<any> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url =
      `${DATA_ACCESS_REQUEST_API}/data/management/v1/access-requests/training-certificates?fileName=${file.name}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/octet-stream",
      },
      body: file,
    };

    const defaultErrorMessage = "Failed to upload Training Certificate";

    return makeApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getTrainingStatus = (authToken: string): Promise<TrainingStatusResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url =
      `${DATA_ACCESS_REQUEST_API}/data/management/v1/access-requests/training-certificates/training-status`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to fetch Training Status";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getTrainingCertificateDetails = (authToken: string): Promise<TrainingCertificateInfoAttributes> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url =
      `${DATA_ACCESS_REQUEST_API}/data/management/v1/access-requests/training-certificates/details`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to fetch Training certificate details";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getTrainingCertUrl = (authToken: string,
                                   trainingCertificateId?: string): Promise<TrainingCertUrlResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = !isEmptyString(trainingCertificateId) ?
      `${DATA_ACCESS_REQUEST_API}/data/management/v1/access-requests/training-certificates/${trainingCertificateId}` :
      `${DATA_ACCESS_REQUEST_API}/data/management/v1/access-requests/training-certificates/training-certificate-url`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to get the training completion certificate URL";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

const listPrincipalIdentifiers = (authToken: string,
                                  principalType: PrincipalType): Promise<ListPrincipalIdentifiersResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Principal Type", principalType],
    ]));

  const makeRequest = () => {

    const url = `${DATA_ACCESS_REQUEST_API}/data/management/v1/access-requests` +
      `/cloud-principal-identifiers/principal-types/${principalType}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to fetch list of cloud principal identifiers";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const listCloudRolePrincipals = (authToken: string) =>
  listPrincipalIdentifiers(authToken, PrincipalType.IOT_CLOUD_ROLE);

export const listAccessibleDataSets = (authToken: string,
                                       accessRequestType?: AccessRequestType,
                                       next: string = ""): Promise<ListAccessibleDataSetsResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams({ accessRequestType, next });

    const url = `${DATA_ACCESS_REQUEST_API}/data/management/v1/access-requests/accessible-by/me${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to list data sets that you have access to";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getDataSetAccessRequests = (authToken: string, accountId: string, dataSetAlias: string)
  : Promise<GetDataSetAccessRequestsResponse> => {

  const validate = () => withAuthToken(authToken).then(() => withRequiredArguments([
    ["Account ID", accountId],
    ["Data Set", dataSetAlias],
  ]));

  const makeRequest = () => {

    const url =
      `${DATA_ACCESS_REQUEST_API}/data/management/v1/access-requests/accounts/${accountId}/datasets/${dataSetAlias}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Fetch Data Access Requests for Data Set ${dataSetAlias} failed`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const revokeDataAccessByOwner = (authToken: string, accountId: string, dataSetAlias: string, json: string)
  : Promise<RevokeAccessResponse> => {

  const validate = () => withAuthToken(authToken).then(() => withRequiredArguments([
    ["Account ID", accountId],
    ["Data Set", dataSetAlias],
  ]));

  const makeRequest = () => {

    const url =
      `${DATA_ACCESS_REQUEST_API}/data/management/v1/access-requests/accounts/${accountId}/datasets/${dataSetAlias}/revoke`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = `Revoke Data Access for ${accountId} for Data Set ${dataSetAlias} failed`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
