import React from "react";
import classnames from "classnames";
import Button from "@components/button";
import { clickHandler, noop } from "@util";
import RefreshButton from "@components/refresh-button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { dataWorkloadDetailsLayout as styles, ModuleIcon } from "./styles";

export interface DataWorkloadDetailsLayoutModel {
  className?: string;
  title?: string;
  showLoadingIndicator?: boolean;
}

export interface DataWorkloadDetailsLayoutActions {
  refresh?: () => void;
  deleteWorkload?: () => void;
}

type Model = DataWorkloadDetailsLayoutModel;
type Actions = DataWorkloadDetailsLayoutActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataWorkloadDetailsLayout = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Workload Details",
    showLoadingIndicator,
    refresh = noop,
    deleteWorkload,
    children,
  } = props;

  return (
    <div className={classnames("dataWorkloadDetailsLayout", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        <ModuleIcon className={classnames("icon", classes.icon)}/>
        <h1 className={classnames("title", classes.title)}>{title}</h1>
        <RefreshButton
          className={classnames("refreshWorkloadDetailsButton", classes.refreshButton)}
          loading={showLoadingIndicator}
          refresh={refresh}
        />
        <Button
          className={classnames("deleteButton", classes.deleteButton)}
          color="inherit"
          variant="contained"
          onClick={clickHandler(deleteWorkload)}
        >
          Delete Workload
        </Button>
      </div>
      <div className={classnames("content", classes.content)}>
        {children}
      </div>
    </div>
  );
});

export default DataWorkloadDetailsLayout;
