import { Record } from "immutable";
import { DEFAULT_SCHEMA } from "@data";

export interface SchemaWizardStateAttributes {
  namespace: string;
  name: string;
  json: string;
}

export class SchemaWizardState extends Record({
  namespace: "",
  name: "",
  json: JSON.stringify(DEFAULT_SCHEMA, null, "  "),
}) implements SchemaWizardStateAttributes {

  public static EMPTY = new SchemaWizardState();

  public readonly namespace: string;
  public readonly name: string;
  public readonly json: string;
}

export default SchemaWizardState;
