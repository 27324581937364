import React from "react";
import { SearchFilter, User, UserAttributes } from "@data";
import { UsersListColumn, AuthTokenContext } from "@components";
import { equalsIgnoreCase, getStringValue, isEmptyString, noop } from "@util";
import { createComparator, getSearchResults } from "@components/users-list/helpers";
import {
  GetSecurityGroupIdentitiesResponse,
  GetUsersResponse,
  RestClientError, SearchUsersRequestBody,
  UserIdmLegacyClient,
  SecurityGroupClient,
  UserIdmClient,
} from "@network";

export interface UseUsersModel {
  users: User[];
  nameFilter: string;
  errorMessage: string;
  showLoadMoreButton: boolean;
  sortOrderAscending: boolean;
  sortByColumn: UsersListColumn;
  showSearch: boolean;
  showLoadingIndicator: boolean;
  totalNumUsers: number;
  totalNumVisibleUsers: number;
  orJoinOperator?: boolean;
  showOrJoinOperator?: boolean;
  searchFilters?: SearchFilter[];
}

export interface UseUsersActions {
  refresh: () => void;
  loadMore: () => void;
  setNameFilter: (nameFilter: string) => void;
  setSortByColumn: (column: UsersListColumn) => void;
  setSortOrderAscending: (ascending: boolean) => void;
  setOrJoinOperator?: (orJoinOperator: boolean) => void;
  setSearchFilters?: (searchFilters: SearchFilter[]) => void;
}

type Model = UseUsersModel;
type Actions = UseUsersActions;
type GetUsersApi = (authToken: string,
                    nextPage?: string,
                    limit?: number) => Promise<GetUsersResponse>;
type SearchUsersApi = (authToken: string,
                       body: SearchUsersRequestBody,
                       nextPage?: string,
                       limit?: number) => Promise<GetUsersResponse>;

export interface UseUsersProps {
  users?: User[];
  nameFilter?: string;
  nextPageToken?: string;
  getUsers?: GetUsersApi;
  searchUsers?: SearchUsersApi;
  searchEnabled?: boolean;
  sortByColumn?: UsersListColumn;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
}

export const sortAndFilterUserResults = (props: {
  items: User[],
  nameFilter: string,
  sortOrderAscending: boolean,
  sortByColumn: UsersListColumn,
}) => {

  const { items, nameFilter, sortOrderAscending, sortByColumn } = props;

  // Workaround for IOTSEC-3112 which includes closed (ie soft deleted) users in get users response
  const users = items.filter(user => !user.isUserClosed());

  const comparator = React.useMemo(() =>
    createComparator(sortByColumn, sortOrderAscending), [sortByColumn, sortOrderAscending]);

  const sortedUsers = React.useMemo(() => users.sort(comparator).slice(), [users, comparator]);

  return React.useMemo(() =>
    getSearchResults(sortedUsers, nameFilter), [sortedUsers, nameFilter]);
};

const DEFAULT_API = () => Promise.resolve({ users: [] } as GetUsersResponse);

const useUsers = (props: UseUsersProps): [Model, Actions] => {

  const {
    users: initialItems = [],
    nameFilter: initialNameFilter = "",
    nextPageToken: initialNextPageToken = "",
    sortByColumn: initialSortByColumn = UsersListColumn.NONE,
    getUsers = DEFAULT_API,
    searchUsers = DEFAULT_API,
    searchEnabled = false,
    trackRequestEvent = noop,
    trackSuccessEvent = noop,
    trackErrorEvent = noop,
  } = props;

  const authToken = React.useContext(AuthTokenContext);
  const [items, setItems] = React.useState<User[]>(initialItems);
  const [nameFilter, setNameFilter] = React.useState(initialNameFilter);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [nextPageToken, setNextPageToken] = React.useState(initialNextPageToken);
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);
  const [sortOrderAscending, setSortOrderAscending] = React.useState(true);
  const [sortByColumn, setSortByColumn] = React.useState(initialSortByColumn);
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);
  const [orJoinOperator, setOrJoinOperator] = React.useState(false);
  const [searchFilters, setSearchFilters] = React.useState<SearchFilter[]>([]);
  const [showNotFound, setShowNotFound] = React.useState(false);
  const [showAccessDenied, setShowAccessDenied] = React.useState(false);

  const updateItems = React.useCallback((updatedItems: User[] = []) => {

    // Make sure there are no duplicates and prefer the updated value to the older value
    setItems(currentItems => currentItems
      .filter(item => {
        const userId = item.getUserId();
        return !updatedItems.some(otherUser => equalsIgnoreCase(userId, otherUser.getUserId()));
      })
      .concat(updatedItems));

  }, [setItems]);

  const internalSearchFilters = React.useMemo(() => ([] as SearchFilter[])
    .concat(new SearchFilter({
      key: "email",
      value: nameFilter,
    }))
    .concat(searchFilters)
    .filter(searchFilter => searchFilter.hasKey() && searchFilter.hasValue()),
    [searchFilters, nameFilter]);

  const showOrJoinOperator = React.useMemo(() =>
    internalSearchFilters.length > 1, [internalSearchFilters]);

  const searchRequestBody = React.useMemo(() => ({
    ...(showOrJoinOperator ? { orJoinOperator } : {}),
    queryCollection: internalSearchFilters.map(filter => ({
      attributeName: filter.getKey(),
      attributeCriteria: filter.getValue(),
    })),
  }), [showOrJoinOperator, orJoinOperator, internalSearchFilters]);

  const searchSupported = React.useMemo(() =>
    searchEnabled && internalSearchFilters.length > 0,
    [searchEnabled, internalSearchFilters]);

  const fetchUsers = React.useCallback(() => {
    if (!searchSupported) {
      return getUsers(authToken, nextPageToken);
    } else {
      return searchUsers(authToken, searchRequestBody, nextPageToken);
    }
  }, [getUsers, searchUsers, searchSupported, authToken, nextPageToken, searchRequestBody]);

  React.useEffect(() => {

    let ignore = false;

    trackRequestEvent();
    setShowLoadingIndicator(true);
    setErrorMessage("");

    fetchUsers()
      .then((response: GetUsersResponse) => {
        if (!ignore) {
          const { users: results = [], next = "" } = response;
          trackSuccessEvent();
          setNextPageToken(next || "");
          setShowLoadingIndicator(false);
          updateItems(results.map(attrs => new User(attrs)));
        }
      }, (response: RestClientError) => {
        if (!ignore) {
          const { analytic, error = "Fetch users failed" } = response;
          if (response.status === 404) {
            setShowNotFound(true);
          }
          if (response.status === 403) {
            setShowAccessDenied(true);
          } else {
            trackErrorEvent(analytic);
          }
          setErrorMessage(error);
          setShowLoadingIndicator(false);
        }
      });

    return () => { ignore = true; };

  }, [
    lastRefresh,
    setShowAccessDenied,
    setShowNotFound,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
    updateItems,
  ]);

  const showLoadMoreButton = React.useMemo(() =>
    !isEmptyString(nextPageToken) && isEmptyString(errorMessage) && !showLoadingIndicator,
    [nextPageToken, errorMessage, showLoadingIndicator]);

  // This is also used as the retry callback in the event of an error
  const loadMore = React.useCallback(() => {
    setLastRefresh(+new Date);
  }, [setLastRefresh]);

  const refresh = React.useCallback(() => {
    setItems([]);
    setNextPageToken("");
    setSortOrderAscending(true);
    setSortByColumn(UsersListColumn.NONE);
    setLastRefresh(+new Date);
    setShowNotFound(false);
    setShowAccessDenied(false);
  }, [setNextPageToken, setItems, setLastRefresh, setSortOrderAscending,
    setSortByColumn, setShowNotFound, setShowAccessDenied]);

  const updateNameFilter = React.useCallback((updatedNameFilter: string) => {
    setNameFilter(updatedNameFilter);
    if (searchEnabled) {
      refresh();
    }
  }, [setNameFilter, searchEnabled, refresh]);

  const updateSearchFilters = React.useCallback((updatedSearchFilters: SearchFilter[]) => {
    setSearchFilters(updatedSearchFilters);
    if (searchEnabled) {
      refresh();
    }
  }, [setSearchFilters, searchEnabled, refresh]);

  const updateOrJoinOperator = React.useCallback((updatedOrJoinOperator: boolean) => {
    setOrJoinOperator(updatedOrJoinOperator);
    if (searchEnabled) {
      refresh();
    }
  }, [setOrJoinOperator, searchEnabled, refresh]);

  const users = sortAndFilterUserResults({ items, nameFilter, sortOrderAscending, sortByColumn });

  const totalNumUsers = React.useMemo(() => items.length, [items]);

  const totalNumVisibleUsers = React.useMemo(() => users.length, [users]);

  const showSearch = React.useMemo(() => totalNumUsers > 1, [totalNumUsers]);

  const model: Model = React.useMemo(() => ({
    users,
    nameFilter,
    errorMessage,
    showLoadMoreButton,
    sortOrderAscending,
    sortByColumn,
    showSearch,
    showNotFound,
    showAccessDenied,
    showLoadingIndicator,
    totalNumUsers,
    totalNumVisibleUsers,
    orJoinOperator,
    showOrJoinOperator,
    searchFilters,
  }), [
    users,
    nameFilter,
    errorMessage,
    showLoadMoreButton,
    sortOrderAscending,
    sortByColumn,
    showSearch,
    showNotFound,
    showAccessDenied,
    showLoadingIndicator,
    totalNumUsers,
    totalNumVisibleUsers,
    orJoinOperator,
    showOrJoinOperator,
    searchFilters,
  ]);

  const actions: Actions = React.useMemo(() => ({
    refresh,
    loadMore,
    setNameFilter: updateNameFilter,
    setSortByColumn,
    setSortOrderAscending,
    setOrJoinOperator: updateOrJoinOperator,
    setSearchFilters: updateSearchFilters,
  }), [
    refresh,
    loadMore,
    updateNameFilter,
    setSortByColumn,
    setSortOrderAscending,
    updateOrJoinOperator,
    updateSearchFilters,
  ]);

  return [model, actions];
};

export const useAccountUsers = (props: UseUsersProps = {}): [Model, Actions] => {

  const { sortByColumn = UsersListColumn.NAME, searchEnabled = true, ...otherProps } = props;

  return useUsers({
    sortByColumn,
    searchEnabled,
    getUsers: UserIdmLegacyClient.getUsers,
    searchUsers: UserIdmClient.getUsersWithFilter,
    ...otherProps,
  });
};

export const useSecurityGroupUsers = (groupName: string = "", props: UseUsersProps = {}): [Model, Actions] => {

  const getAllUsers: GetUsersApi = (authToken: string, nextPage: string = "") =>
    UserIdmLegacyClient.getUsers(authToken, nextPage);

  const getSecurityGroupUsers: GetUsersApi = (authToken: string, nextPage: string = "") =>
    SecurityGroupClient.getSecurityGroupUsers(authToken, groupName, nextPage)
      .then(({ identityReferences = [], paging: { next = "" } = {} }) => ({
        users: identityReferences.map(({ id }) => new User({ id, userId: id })),
        next: next || "",
      }));

  const getUsers: GetUsersApi = (authToken: string, nextPage: string = "") => {
    if (isEmptyString(groupName)) {
      return getAllUsers(authToken, nextPage);
    } else {
      return getSecurityGroupUsers(authToken, nextPage);
    }
  };

  return useUsers({
    getUsers,
    ...props,
  });
};

const getAllSecurityGroupUsers = (props: {
  authToken: string,
  groupName: string,
}): Promise<User[]> => {

  const { groupName, authToken } = props;

  const getNextPage = (nextPage: string = ""): Promise<User[]> =>
    SecurityGroupClient.getSecurityGroupUsers(authToken, groupName, nextPage)
      .then((response: GetSecurityGroupIdentitiesResponse) => {

        const { identityReferences = [], paging: { next = "" } = { next: "" }} = response;

        const nextPageToken = getStringValue(next || "");

        const users = identityReferences.map(({ id }) => new User({ id, userId: id }));

        if (isEmptyString(nextPageToken)) {
          return Promise.resolve(users);
        }

        return getNextPage(nextPageToken)
          .then((results: User[]) => users.concat(results));
      });

  return getNextPage();
};

export const useUsersWhoDoNotBelongToSecurityGroup = (groupName: string = "",
                                                      props: UseUsersProps = {}): [Model, Actions] => {

  const getAllUsers: GetUsersApi = (authToken: string, nextPage: string = "") =>
    UserIdmLegacyClient.getUsers(authToken, nextPage);

  const getUsersWhoDoNotBelongToSecurityGroup: GetUsersApi =
    async (authToken: string, nextPage: string = "") => {

      const excludedUserIds = await getAllSecurityGroupUsers({ authToken, groupName })
        .then(users => users.map(user => user.getUserId()));

      return UserIdmLegacyClient.getUsers(authToken, nextPage)
        .then((response: GetUsersResponse) => {

          const { users = [], next = "" } = response;

          const nextPageToken = getStringValue(next || "");

          return {
            next: nextPageToken,
            users: users.filter((attrs: UserAttributes) =>
              excludedUserIds.indexOf(new User(attrs).getUserId()) === -1),
          };
        });
    };

  const getUsers: GetUsersApi = (authToken: string, nextPage: string = "") => {
    if (isEmptyString(groupName)) {
      return getAllUsers(authToken, nextPage);
    } else {
      return getUsersWhoDoNotBelongToSecurityGroup(authToken, nextPage);
    }
  };

  const getAllUsersWithFilters:
    SearchUsersApi = (authToken: string,
                      requestBody: SearchUsersRequestBody,
                      next: string = "") =>
    UserIdmClient.getUsersWithFilter(authToken, requestBody, next);

  const getUsersWhoDoNotBelongToSecurityGroupFilter: SearchUsersApi =
    async (authToken: string, requestBody: SearchUsersRequestBody, nextPage: string = "") => {

      const excludedUserIds = await getAllSecurityGroupUsers({ authToken, groupName })
        .then(users => users.map(user => user.getUserId()));

      return UserIdmClient.getUsersWithFilter(authToken, requestBody, nextPage)
        .then((response: GetUsersResponse) => {

          const { users = [], next = "" } = response;

          const nextPageToken = getStringValue(next || "");

          return {
            next: nextPageToken,
            users: users.filter((attrs: UserAttributes) =>
              excludedUserIds.indexOf(new User(attrs).getUserId()) === -1),
          };
        });
    };

  const searchUsers: SearchUsersApi = (authToken: string,
                                       requestBody: SearchUsersRequestBody,
                                       next: string = "") => {
    if (isEmptyString(groupName)) {
      return getAllUsersWithFilters(authToken, requestBody, next);
    } else {
      return getUsersWhoDoNotBelongToSecurityGroupFilter(authToken, requestBody, next);
    }
  };

  return useUsers({
    getUsers,
    searchUsers,
    searchEnabled: true,
    ...props,
  });
};
