import { RefreshTokenResponse, UserIdmLegacyClient } from "@network";

export type TokenRefreshCallback = (error: any, token?: string, expiryTime?: string) => void;

export const tokenRefresh = (accountId: string,
                             userId: string,
                             authToken: string,
                             refreshToken: string,
                             tokenRefreshCallback: TokenRefreshCallback,
                             currentTokenExpiry: number = 0) => {

  let tickTimer: any;
  let stopped = false;
  let retryCounter = 0;

  const ONE_MINUTE = 1000 * 60;
  const THREE_SECONDS = 3000;

  const next = (currentToken: string) => {

    if (!stopped) {

      UserIdmLegacyClient.refresh(currentToken, userId, refreshToken)
        .then((response: RefreshTokenResponse) => {
          if (!stopped) {
            retryCounter = 0;
            const {tokens: {accessToken, expiryTime}} = response;
            tokenRefreshCallback(null, accessToken, new Date(expiryTime).toISOString());
            tick();
          }
        })
        .catch((error) => {
          if (!stopped) {
            const status = error.status;
            if (status !== 401 && status !== 403) {
              tokenRefreshCallback(error);
              return;
            } else if (retryCounter++ < 3) {
              tick();
            } else {
              tokenRefreshCallback(error);
              return;
            }
          }
        });
    }
  };

  tick();

  function tick() {
    clearTimeout(tickTimer);
    tickTimer = setTimeout(() => {
      if (Date.now() + ONE_MINUTE >= currentTokenExpiry) {
        next(authToken);
      } else {
        tick();
      }
    }, THREE_SECONDS);
  }

  return () => {
    clearTimeout(tickTimer);
    stopped = true;
  };
};

export default tokenRefresh;
