import { AppSchema } from "@main/schemas";
import { createActions } from "@base/createActions";
import { getAuthToken } from "@main/selectors";
import {
  DataAccessRequestClient,
  DataApprovalRequestClient,
  DataSetRequestClient,
  RestClientError,
} from "@network";
import {
  ApprovalRequestType,
  DataAccessRequest, DataAccessRequestAttributes,
  DataApprovalRequest,
  DataApprovalRequestAttributes,
  DataSetRequest, DataSetRequestAttributes,
  GetRequestCommentsResponse,
  RequestComment,
  RequestCommentAttributes,
} from "@data";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { getComment, getCommentAttributesArray, getDataApprovalRequest } from "./selectors";
import { approveRequest, rejectRequest } from "@modules/approveRequest/actions";
import { isSourceDataSetRequest } from "@modules/dataApprovalRequestDetails/helpers";

export const {
  dataApprovalRequest: setDataApprovalRequest,
  dataSetRequest: setDataSetRequest,
  dataAccessRequest: setDataAccessRequest,
  comments: setComments,
  comment: setComment,
  createCommentBox: setCreateCommentBox,
  commentLoadingIndicator: setCommentLoadingIndicator,
  commentErrorMessage: setCommentErrorMessage,
  setErrorMessage,
  setSuccessMessage,
  showLoadingIndicator,
  hideLoadingIndicator,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  showNotFound,
  hideNotFound,
  FETCH_DATA_APPROVAL_REQUEST_DETAILS_REQUEST: fetchDataApprovalRequestDetailsRequest,
  FETCH_DATA_APPROVAL_REQUEST_DETAILS_SUCCESS: fetchDataApprovalRequestDetailsSuccess,
  FETCH_DATA_APPROVAL_REQUEST_DETAILS_FAILURE: fetchDataApprovalRequestDetailsFailed,
  FETCH_DATA_APPROVAL_REQUEST_COMMENTS_REQUEST: fetchDataApprovalRequestCommentsRequest,
  FETCH_DATA_APPROVAL_REQUEST_COMMENTS_SUCCESS: fetchDataApprovalRequestCommentsSuccess,
  FETCH_DATA_APPROVAL_REQUEST_COMMENTS_FAILURE: fetchDataApprovalRequestCommentsFailed,
  CREATE_DATA_APPROVAL_REQUEST_COMMENT_REQUEST: createDataApprovalRequestCommentRequest,
  CREATE_DATA_APPROVAL_REQUEST_COMMENT_SUCCESS: createDataApprovalRequestCommentSuccess,
  CREATE_DATA_APPROVAL_REQUEST_COMMENT_FAILED: createDataApprovalRequestCommentFailed,
  FETCH_TERMS_AND_CONDITIONS_REQUEST: fetchTermsAndConditionsRequest,
  FETCH_TERMS_AND_CONDITIONS_SUCCESS: fetchTermsAndConditionsSuccess,
  FETCH_TERMS_AND_CONDITIONS_FAILURE: fetchTermsAndConditionsFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setDataApprovalRequest());
  dispatch(setDataSetRequest());
  dispatch(setDataAccessRequest());
  dispatch(setComments());
  dispatch(setComment());
  dispatch(setCreateCommentBox());
  dispatch(setCommentLoadingIndicator());
  dispatch(setCommentErrorMessage());
  return dispatch(baseReset());
};

export const showCommentLoadingIndicator = () => setCommentLoadingIndicator(true);
export const hideCommentLoadingIndicator = () => setCommentLoadingIndicator(false);

export const resetComments = () => (dispatch: any) => {
  dispatch(setComments());
  dispatch(setCreateCommentBox());
  return dispatch(setCommentErrorMessage());
};

export const addComment = (comments: RequestCommentAttributes[]) => (dispatch: any, getState: () => AppSchema) =>
  dispatch(setComments(getCommentAttributesArray(getState()).concat(comments)));

export const showCreateCommentBox = () => (dispatch: any) => {
  dispatch(setCommentErrorMessage());
  dispatch(setSuccessMessage());
  return dispatch(setCreateCommentBox(true));
};

export const hideCreateCommentBox = () => (dispatch: any) => {
  dispatch(setCommentErrorMessage());
  dispatch(setComment());
  return dispatch(setCreateCommentBox(false));
};

type GetDataApprovalRequestDetailsResponse = {
  dataApprovalRequest: DataApprovalRequest;
  dataSetRequest?: DataSetRequest;
  dataAccessRequest?: DataAccessRequest;
};

const getDataApprovalRequestDetails =
  (authToken: string, approvalRequestId: string): Promise<GetDataApprovalRequestDetailsResponse> =>
    DataApprovalRequestClient.getDataApprovalRequest(authToken, approvalRequestId)
      .then((attrs: DataApprovalRequestAttributes) => {
        const dataApprovalRequest = new DataApprovalRequest(attrs);
        const sourceSystemRequestId = dataApprovalRequest.sourceSystemRequestId;
        if (isSourceDataSetRequest(dataApprovalRequest.requestType)) {
          return DataSetRequestClient.getDataSetRequest(authToken, sourceSystemRequestId)
            .then((dataSetRequestAttributes: DataSetRequestAttributes) => {
              return new DataSetRequest(dataSetRequestAttributes);
            })
            .then(dataSetRequest => ({
              dataApprovalRequest,
              dataSetRequest,
              dataAccessRequest: DataAccessRequest.EMPTY,
            }));
        } else {
          return DataAccessRequestClient.getDataAccessRequest(authToken, sourceSystemRequestId)
            .then((dataAccessRequestAttributes: DataAccessRequestAttributes) => {
              return new DataAccessRequest(dataAccessRequestAttributes);
            })
            .then(dataAccessRequest => ({
              dataApprovalRequest,
              dataAccessRequest,
              dataSetRequest: DataSetRequest.EMPTY,
            }));
        }
      });

export const fetchDataApprovalRequestDetails = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const approvalRequestId = getDataApprovalRequest(state).approvalRequestId;
  const authToken = getAuthToken(state);

  dispatch(showLoadingIndicator());
  dispatch(setErrorMessage());
  dispatch(hideNotFound());
  dispatch(hideAccessDenied());
  dispatch(fetchDataApprovalRequestDetailsRequest());

  return getDataApprovalRequestDetails(authToken, approvalRequestId)
    .then(response => {

      const {
        dataApprovalRequest,
        dataSetRequest = DataSetRequest.EMPTY,
        dataAccessRequest = DataAccessRequest.EMPTY,
      } = response;

      dispatch(fetchDataApprovalRequestDetailsSuccess());
      dispatch(setDataApprovalRequest(dataApprovalRequest.toJS()));
      dispatch(setDataSetRequest(dataSetRequest.toJS()));
      dispatch(setDataAccessRequest(dataAccessRequest.toJS()));
      dispatch(fetchDataApprovalRequestComments());
      dispatch(hideLoadingIndicator());
      return dispatch(hideEmptyView());

    }, (response: RestClientError) => {

      const { analytic, status, error = "Fetch data approval request details failed" } = response;

      dispatch(fetchDataApprovalRequestDetailsFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(hideLoadingIndicator());

      if (response.status === 404) {
        dispatch(showNotFound());
      }

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideEmptyView());
    });
};

export const fetchDataApprovalRequestComments = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const authToken = getAuthToken(state);
  const dataApprovalRequest = getDataApprovalRequest(state);

  dispatch(setErrorMessage());
  dispatch(hideAccessDenied());
  dispatch(fetchDataApprovalRequestCommentsRequest());
  dispatch(showCommentLoadingIndicator());
  dispatch(resetComments());

  fetchComments(authToken, dataApprovalRequest.requestType, dataApprovalRequest.sourceSystemRequestId)
    .then((response: GetRequestCommentsResponse) => {
      const comments = response.comments.map(
        (attrs: RequestCommentAttributes) => new RequestComment(attrs).toJS());
      dispatch(setComments(comments));
      dispatch(hideCommentLoadingIndicator());
      return dispatch(fetchDataApprovalRequestCommentsSuccess());

    }, (response: RestClientError) => {

      const {analytic, error = "Fetch data approval request comments failed"} = response;

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      dispatch(fetchDataApprovalRequestCommentsFailed(analytic));
      dispatch(hideCommentLoadingIndicator());
      return dispatch(setCommentErrorMessage(error));
    });
};

export const createDataApprovalRequestComment = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const authToken = getAuthToken(state);
  const message = getComment(state);
  const dataApprovalRequest = getDataApprovalRequest(state);

  const json = JSON.stringify({
    message,
  });

  dispatch(createDataApprovalRequestCommentRequest());
  dispatch(setSuccessMessage());
  dispatch(showCommentLoadingIndicator());
  dispatch(setCommentErrorMessage());

  return createComment(authToken, dataApprovalRequest.requestType, dataApprovalRequest.sourceSystemRequestId, json)
    .then(() => {

      dispatch(resetComments());
      dispatch(createDataApprovalRequestCommentSuccess());
      dispatch(hideCommentLoadingIndicator());
      dispatch(hideCreateCommentBox());
      dispatch(setSuccessMessage("Comment created"));
      return dispatch(fetchDataApprovalRequestComments());
    }, (response: RestClientError) => {

      const { analytic, error = "Failed to add Comment" } = response;

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      dispatch(hideCommentLoadingIndicator());
      dispatch(createDataApprovalRequestCommentFailed(analytic));
      return dispatch(setCommentErrorMessage(error));
    });
};

const fetchComments = (authToken: string,
                       requestType: ApprovalRequestType,
                       requestId: string): Promise<GetRequestCommentsResponse> => {
  if (isSourceDataSetRequest(requestType)) {
    return DataSetRequestClient.getDataSetRequestComments(authToken, requestId);
  } else {
    return DataAccessRequestClient.getDataAccessRequestComments(authToken, requestId);
  }
};

const createComment = (authToken: string,
                       requestType: ApprovalRequestType,
                       requestId: string,
                       json?: string): Promise<void> => {
  if (isSourceDataSetRequest(requestType)) {
    return DataSetRequestClient.createDataSetRequestComment(authToken, requestId, json);
  } else {
    return DataAccessRequestClient.createDataAccessRequestComment(authToken, requestId, json);
  }
};

export const refresh = () => (dispatch: any) => {

  return dispatch(fetchDataApprovalRequestDetails());
};

export const initialize = (id: string) => (dispatch: any) => {
  dispatch(reset());
  dispatch(setDataApprovalRequest(new DataApprovalRequest({approvalRequestId: id}).toJS()));
  return dispatch(refresh());
};

export const approveDataApprovalRequest = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const dataApprovalRequest = getDataApprovalRequest(state);
  return dispatch(approveRequest(dataApprovalRequest.approvalRequestId, dataApprovalRequest.dataSetAlias));
};

export const rejectDataApprovalRequest = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const dataApprovalRequest = getDataApprovalRequest(state);
  return dispatch(rejectRequest(dataApprovalRequest.approvalRequestId, dataApprovalRequest.dataSetAlias));
};
