import { Record } from "immutable";
import { DataSetState } from "@data/DataSetRequest";

export interface DataSetRequestEventAttributes {
  state: DataSetState;
  comments: string;
  eventTime: string;
  actionPerformedBy: string;
}

export class DataSetRequestEvent extends Record({
  state: DataSetState.PENDING_IOT_APPROVAL,
  comments: "",
  eventTime: "",
  actionPerformedBy: "",
}) implements DataSetRequestEventAttributes {

  public static EMPTY: DataSetRequestEvent = new DataSetRequestEvent();

  public readonly state: DataSetState;
  public readonly comments: string;
  public readonly eventTime: string;
  public readonly actionPerformedBy: string;
}

export default DataSetRequestEvent;
