import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import {
  getDeviceDataInfo,
  isActualViewActive,
  isDesiredViewActive,
  isMetadataViewActive,
} from "../selectors";
import SchemaDataView from "./SchemaDataView";
import { DeviceTypeModelV3MetadataAttributes, JsonSchemaMetadata } from "@data";
import DeviceDataView, { Actions, Model } from "../components/DeviceDataView";

interface ContainerModel extends Model {
  selectedSchema?: string;
  schemaIdentities?: string[];
  json?: string;
  showLoadingIndicator?: boolean;
  invalidJsonError?: boolean;
  metadata?: DeviceTypeModelV3MetadataAttributes[];
  isReadWriteSelected?: boolean;
}

interface ContainerActions extends Actions {
  showSchemaDetailsInNewTab?: (schema: JsonSchemaMetadata) => void;
  onClickSchema?: (schema: JsonSchemaMetadata) => void;
  setJson?: (json: string) => void;
  cancelEdit?: () => void;
  confirmEdit?: () => void;
  onChangeIsReadWriteSelected?: (event: any) => void;
  onChangeScope?: (event: any) => void;
}

type Props = ContainerModel & ContainerActions;

const DeviceDataViewContainer = (props: Props) => {

  const {
    selectedSchema,
    schemaIdentities,
    json,
    showLoadingIndicator,
    invalidJsonError,
    showSchemaDetailsInNewTab,
    metadata = [],
    isReadWriteSelected,
    onClickSchema = noop,
    setJson,
    cancelEdit = noop,
    confirmEdit = noop,
    onChangeIsReadWriteSelected = noop,
    onChangeScope = noop,
    ...otherProps
  } = props;

  return (
    <DeviceDataView {...otherProps} >
      <SchemaDataView
        selectedSchema={selectedSchema}
        schemaIdentities={schemaIdentities}
        metadata={metadata}
        isReadWriteSelected={isReadWriteSelected}
        json={json}
        showLoadingIndicator={showLoadingIndicator}
        invalidJsonError={invalidJsonError}
        showSchemaDetailsInNewTab={showSchemaDetailsInNewTab}
        onClickSchema={onClickSchema}
        setJson={setJson}
        cancelEdit={cancelEdit}
        confirmEdit={confirmEdit}
        onChangeIsReadWriteSelected={onChangeIsReadWriteSelected}
        onChangeScope={onChangeScope}
      />
    </DeviceDataView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  information: getDeviceDataInfo(state),
  actualViewSelected: isActualViewActive(state),
  desiredViewSelected: isDesiredViewActive(state),
  metadataViewSelected: isMetadataViewActive(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceDataViewContainer);
