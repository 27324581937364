import { Module } from "@data";
import { createModule } from "@base/createModule";
import { isEmptyString, isValidNumber } from "@util";
import { ModuleIcon as icon } from "./components/styles";
import { getPathToWorkloadExecutionHistory } from "./helpers";
import DATA_WORKLOADS_REQUIREMENTS from "@modules/dataWorkloads/requirements";
import { DATA_PROCESSING_DEVELOPER_GUIDE } from "@modules/dataWorkloads/constants";
import {
  LEGACY_MODULE_PATH,
  MODULE_ID,
  MODULE_PATH,
  RouteParams,
  VIEW_WORKLOAD_EXECUTION_HISTORY_REDIRECT,
} from "./constants";
import view from "./DataWorkloadDetailsView";

export const dataWorkloadDetailsModule: Module = createModule<RouteParams>({
  ...DATA_WORKLOADS_REQUIREMENTS,
  id: MODULE_ID,
  path: [
    MODULE_PATH,
    // Legacy module path included here instead of the module's 'legacyPath' attribute because
    // the legacy path in this case should be considered both exact and strict; whereas, we do
    // not want exact and strict on the new module path. We therefore use a switch in the
    // module view to redirect the legacy url to the new url.
    LEGACY_MODULE_PATH,
  ],
  exactPath: false,
  strictPath: false,
  showBreadcrumbs: true,
  name: "Workload Details",
  title: "Workload Details",
  className: "dataWorkloadDetailsModule",
  documentationLink: DATA_PROCESSING_DEVELOPER_GUIDE,
  icon,
  view,
  redirects: {
    [VIEW_WORKLOAD_EXECUTION_HISTORY_REDIRECT]: (action, resourceId, extraQueryParams) => {
      const workloadName = extraQueryParams.get("workloadName") || "";
      const workloadVersion = extraQueryParams.get("workloadVersion") || "";

      if (isEmptyString(workloadName) || !isValidNumber(workloadVersion)) {
        return null;
      } else {
        extraQueryParams.delete("workloadName");
        extraQueryParams.delete("workloadVersion");
        return `${getPathToWorkloadExecutionHistory(workloadName, Number(workloadVersion))}?${extraQueryParams}`;
      }
    }
  }
});

export default dataWorkloadDetailsModule;
