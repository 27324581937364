import createStyles from "@material-ui/core/styles/createStyles";
import { Color } from "@Color";

const primaryColor = Color.MODULES;

export const summaryView = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 25,
      "& label.name": {
        minWidth: 200,
      },
      whiteSpace: "pre-wrap",
    },
    content: {
      marginTop: 32,
      color: primaryColor,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      minWidth: 100,
      alignSelf: "flex-start",
      fontSize: 24,
      lineHeight: "28px",
    }
  });
