import React from "react";
import { TrainingCertificateInfoAttributes, TrainingCertificateInfo } from "@data";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useDataAccessRequestClient } from "@hooks";

export interface UseTrainingCertificateDetailsModel extends UseApiRequestModel<TrainingCertificateInfoAttributes> {
  certificateDetails?: TrainingCertificateInfo;
}

export interface UseTrainingCertificateDetailsActions {
  refresh: () => void;
}

type Props = Partial<UseApiRequestProps<TrainingCertificateInfoAttributes>>;
type Model = UseTrainingCertificateDetailsModel;
type Actions = UseTrainingCertificateDetailsActions;
type Result = [Model, Actions];

export const useTrainingCertificateDetails = (props: Props): Result => {

  const {
    defaultErrorMessage = "Failed to get training certificate details",
    ...otherProps
  } = props;

  const DataAccessRequestClient = useDataAccessRequestClient();

  const [{ successResponse, ...baseModel }, { refresh }] =
    useApiRequest<TrainingCertificateInfoAttributes>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => DataAccessRequestClient.getTrainingCertificateDetails()
    });

  const certificateDetails = React.useMemo(() => {
    const attrs = successResponse || {};
    return new TrainingCertificateInfo(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    certificateDetails
  }), [
    baseModel,
    certificateDetails,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    refresh,
  }), [
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useTrainingCertificateDetails;
