import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import {
  open as openDeleteDataAccessRequestDialog,
} from "@modules/deleteDataAccessRequest/actions";
import { DataAccessRequest, RequestState } from "@data";
import useDataAccessRequests from "@hooks/dataAccessRequest/use-data-access-requests";
import {
  getCurrentAccountId,
  getNameFilter,
  isFilterByCurrentAccountEnabled,
  isFilterByCurrentUserEnabled,
  isFilterByPendingApprovalEnabled,
  isReloadItemsRequested,
} from "../selectors";
import {
  setFilterByCurrentAccount,
  setFilterByCurrentUser,
  setFilterByPendingApproval,
  setNameFilter,
  setReloadItemsRequested,
} from "../actions";
import DataAccessRequests, {
  DataAccessRequestsActions as Actions,
  DataAccessRequestsModel as Model,
} from "@components/data-access-requests";

interface ContainerModel extends Model {
  currentAccountId?: string;
  reloadItemsRequested?: boolean;
}

interface ContainerActions extends Actions {
  requestReload?: () => void;
  onItemsReloaded?: () => void;
}

type Props = ContainerModel & ContainerActions;

const DataAccessRequestsContainer = (props: Props) => {

  const {
    currentAccountId,
    filterByCurrentUser,
    filterByCurrentAccount,
    filterByPendingApproval,
    reloadItemsRequested,
    requestReload = noop,
    onItemsReloaded = noop,
    ...otherProps
  } = props;

  const [{dataAccessRequests, ...model}, {refresh, ...actions}] =
    useDataAccessRequests({filterByCurrentUser});

  const items = dataAccessRequests
    .filter(item => {
      const accountId = item.getAccountId();

      if (filterByCurrentAccount) {
        return accountId === currentAccountId;
      }

      return !accountId.startsWith("0000001");
    })
    .filter(item => !filterByPendingApproval ||
      (item.requestState === RequestState.PENDING ||
        item.requestState === RequestState.PENDING_DO_APPROVAL));

  React.useEffect(() => {
    if (reloadItemsRequested) {
      refresh();
      onItemsReloaded();
    }
  }, [reloadItemsRequested, refresh, onItemsReloaded]);

  React.useEffect(() => {
    requestReload();
  }, [filterByCurrentUser, requestReload]);

  if (reloadItemsRequested) {
    return null;
  }

  return (
    <DataAccessRequests
      {...model}
      {...actions}
      {...otherProps}
      items={items}
      filterByCurrentUser={filterByCurrentUser}
      filterByCurrentAccount={filterByCurrentAccount}
      filterByPendingApproval={filterByPendingApproval}
      refresh={refresh}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  nameFilter: getNameFilter(state),
  filterByCurrentUser: isFilterByCurrentUserEnabled(state),
  filterByCurrentAccount: isFilterByCurrentAccountEnabled(state),
  filterByPendingApproval: isFilterByPendingApprovalEnabled(state),
  currentAccountId: getCurrentAccountId(state),
  reloadItemsRequested: isReloadItemsRequested(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setNameFilter: value => dispatch(setNameFilter(value)),
  setFilterByCurrentUser: value => dispatch(setFilterByCurrentUser(value)),
  setFilterByCurrentAccount: value => dispatch(setFilterByCurrentAccount(value)),
  setFilterByPendingApproval: value => dispatch(setFilterByPendingApproval(value)),
  deleteDataAccessRequest: (dataAccessRequest: DataAccessRequest) =>
    dispatch(openDeleteDataAccessRequestDialog(
      dataAccessRequest.getAccessRequestId(),
      dataAccessRequest.getDataSetAlias(),
    )),
  requestReload: () => dispatch(setReloadItemsRequested(true)),
  onItemsReloaded: () => dispatch(setReloadItemsRequested(false)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DataAccessRequestsContainer);
