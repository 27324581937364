import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    emptyView: {
      marginTop: 25,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      padding: 8,
      border: `1px dashed ${Color.GREY11}`,
      borderRadius: 8,
      minHeight: 200,
    },
    emptyViewLabel: {
      color: Color.GREY7,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
    },
    header: {
      marginTop: 25,
      display: "flex",
      flexFlow: "row nowrap",
    },
    summaryView: {
      marginTop: 0,
      marginRight: 10,
      "& > .content": {
        marginTop: 0,
      },
    },
    controls: {
      marginLeft: "auto",
      display: "flex",
      flexFlow: "row nowrap",
    },
    editButton: {
      marginTop: 25,
    },
    statementsList: {
      marginTop: 25,
      "& > .summaryViewContainer": {
        "& > .summary": {
          minHeight: "auto",
          "& > label": {
            margin: 0,
          },
        },
      },
      "& > .searchInput": {
        marginLeft: 0,
      },
      "& > table": {
        marginTop: 10,
      },
    },
  });

export default styles;
