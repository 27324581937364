import moment from "moment";
import setDate from "date-fns/set";
import addDate from "date-fns/add";
import subtractDate from "date-fns/sub";
import { isEmptyString } from "@util/Functions";

const FORMAT_DATE_PATTERN = "YYYY-MM-DDTHH:mm:ss[Z]";

const ISO_8601_DATE_TIME_PATTERN = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

export const formatAsISO8601DateTimeString = (value: string = "", defaultValue: string = value): string =>
  isNaN(Date.parse(value)) ? defaultValue : moment(new Date(value)).format(ISO_8601_DATE_TIME_PATTERN);

export const formatLocalDateToUtc = (date: Date = new Date()): string =>
  moment(date, FORMAT_DATE_PATTERN).utc().format(FORMAT_DATE_PATTERN);

export const formatUtcDateToLocal = (date: Date = new Date()): string =>
  moment(date).format(FORMAT_DATE_PATTERN);

export const formatUTCDate = (dateString: string = "",
                              pattern: string = "LLL"): string =>
  !isEmptyString(dateString) ? moment(dateString).format(pattern || "LLL") : "";

export const formatDateToUTC = (dateString: string = "") => {
  return new Date(dateString).toLocaleDateString("en-US",
    { day: "numeric", month: "long", year: "numeric", timeZone: "UTC"} );
};

export const beginningOfDayDate = (date = new Date()): Date =>
  setDate(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

export const beginningOfDay = (date = new Date()): string =>
  formatLocalDateToUtc(beginningOfDayDate(date));

export const endOfDayDate = (date = new Date()): Date =>
  setDate(date, { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 });

export const endOfDay = (date = new Date()): string =>
  formatLocalDateToUtc(endOfDayDate(date));

export const daysAgo = (days: number): Date =>
  beginningOfDayDate(subtractDate(new Date, { days }));

export const daysAway = (days: number, date: Date): Date =>
  endOfDayDate(addDate(date, { days }));

export const daysFromNow = (days: number): Date =>
  daysAway(days, endOfDayDate(new Date()));

export const monthsAgo = (months: number): Date =>
  beginningOfDayDate(subtractDate(new Date, { months }));

export const oneWeekAgo = (): Date => daysAgo(7);

export const oneMonthAgo = (): Date => monthsAgo(1);
