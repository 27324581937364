import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { RedirectUriColumn } from "./RedirectUriColumn";
import { styles } from "./styles";
import { Quantities } from "@util";
import SortedSearchResultsList, {
  SortedSearchResultsListModel,
  SortedSearchResultsListActions
} from "@components/sorted-search-results-list";
import { RedirectUriListItemData } from "@data";

const REDIRECT_URI_LIST_DEFAULT_COLUMNS = [
  RedirectUriColumn.URI
];

const RedirectURIListItems = withStyles(styles)((props: WithStyles<typeof styles> & {
  redirectUri: RedirectUriListItemData,
  column: RedirectUriColumn,
}) => {

  const { classes, redirectUri = RedirectUriListItemData.EMPTY, column } = props;

  if (column === RedirectUriColumn.URI) {
    return (
      <div className={classnames("uri", classes.container)}>
        <label className={classnames("uri", classes.label)}>{redirectUri.getUri()}</label>
      </div>
    );
  } else {
    return null;
  }
});

export interface RedirectUriListModel extends
  SortedSearchResultsListModel<RedirectUriListItemData, RedirectUriColumn> {
  redirectUris?: RedirectUriListItemData[];
}

export interface RedirectUriListActions extends
  SortedSearchResultsListActions<RedirectUriListItemData, RedirectUriColumn> {
}

export const RedirectUriList = withStyles(styles)((props: WithStyles<typeof styles> &
  RedirectUriListModel & RedirectUriListActions) => {

  const {
    classes,
    redirectUris = [],
    ...otherProps
  } = props;

  const summaryViewQuantities: Quantities = {
    other: "redirectUris",
    one: "redirectUri",
  };

  return(
    <SortedSearchResultsList
      { ...otherProps }
      className={classnames("redirectUriList", classes.listContainer)}
      columns={REDIRECT_URI_LIST_DEFAULT_COLUMNS}
      summaryViewQuantities={summaryViewQuantities}
      items={redirectUris}
      showSummary={true}
      showIcon={false}
      showSearch={false}
      sortingDisabled={true}
      showRefreshButton={false}
      noResultsLabel={"No Redirect URIs for this application"}
      tableLayoutFixed={false}
      renderItem={(redirectUri: RedirectUriListItemData, column: RedirectUriColumn) => (
        <RedirectURIListItems redirectUri={redirectUri} column={column} />
      )}
    />
  );
});

export default RedirectUriList;
