import { Primitive } from "@util";
import ApplicationsListColumn from "./ApplicationsListColumn";
import { Application } from "@data";

export const filterResultsByNameFilter = (item: Application, nameFilter: string) => {

  const searchQuery = nameFilter.toLowerCase();

  return item.getId().toLowerCase().indexOf(searchQuery) >= 0 ||
    item.getName().toLowerCase().indexOf(searchQuery) >= 0 ||
    item.getDescription().indexOf(searchQuery) >= 0;
};

export const mapItemToColumnValue = (item: Application,
  column: ApplicationsListColumn): Primitive => {

  switch (column) {
  case ApplicationsListColumn.ID:
    return item.getId().toLowerCase();
  case ApplicationsListColumn.NAME:
    return item.getName().toLowerCase();
  case ApplicationsListColumn.DESCRIPTION:
    return item.getDescription().toLowerCase();
  default:
    return "";
  }
};
