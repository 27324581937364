import React from "react";
import * as ReactDOM from "react-dom";
import unregisterServiceWorker from "./unregisterServiceWorker";
import registerServiceWorker from "./registerServiceWorker";
import "./setupReactPdfWorker";
import store from "./store";
import Main from "./Main";
import "./index.css";

const CACHE_ENABLED = process.env.REACT_APP_CACHE_ENABLED === "true" ||
  (process.env.NODE_ENV === "production" && process.env.REACT_APP_CACHE_ENABLED !== "false");

ReactDOM.render(
  <Main store={store} />,
  document.getElementById("root") as HTMLElement
);

if (CACHE_ENABLED) {
  registerServiceWorker();
} else {
  unregisterServiceWorker();
}
