import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import { styles } from "./styles";
import { CancelButton, ContinueButton, ErrorView } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface EditModeInitializeErrorViewModel {
  className?: string;
  errorTitle?: string;
  errorMessage?: string;
  hideRetryButton?: boolean;
}

export interface EditModeInitializeErrorViewActions {
  cancel?: () => void;
  retry?: () => void;
}

type Props = WithStyles<typeof styles> & EditModeInitializeErrorViewModel & EditModeInitializeErrorViewActions & {
  children?: React.ReactNode;
};

export const EditModeInitializeErrorView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    errorTitle,
    errorMessage,
    hideRetryButton,
    cancel = noop,
    retry = noop,
    children,
  } = props;

  return (
    <div className={classnames("editModeInitializeErrorView", className, classes.container)}>
      <ErrorView
        className={classnames("errorView", classes.errorView)}
        title={errorTitle}
        message={errorMessage}
        size="large"
      />
      <div className={classnames("actions", classes.actions)}>
        <CancelButton
          className={classnames("cancelButton", classes.cancelButton)}
          label="Cancel"
          onClick={cancel}
        />
        {!hideRetryButton && (
          <ContinueButton
            className={classnames("retryButton", classes.retryButton)}
            label="Retry"
            onClick={retry}
          />
        )}
      </div>
      {children}
    </div>
  );
});

export default EditModeInitializeErrorView;
