import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      marginTop: 25,
      width: "fit-content",
    },
    label: {
      fontWeight: 400,
      fontSize: 12,
    },
    cell: {
      border: "lightgray 1px solid",
      textAlign: "center",
      padding: 10,
    },
    planned: {
      backgroundColor: Color.LIGHT_GREEN,
    },
    inProgress: {
      backgroundColor: Color.LIGHT_YELLOW,
    },
    postponed: {
      backgroundColor: Color.LIGHT_GREY3,
    },
    completed: {
      backgroundColor: Color.LIGHT_BLUE2,
    },
  });

export default styles;
