import { Module } from "@data";
import { isReleaseNotesEnabled } from "@util";
import { createModule } from "@base/createModule";
import { ModuleIcon as icon } from "./components/styles";
import {
  LEGACY_MODULE_PATHS,
  MODULE_ID,
  MODULE_PATHS,
  MODULE_TITLE,
  RouteParams,
} from "./constants";
import customBreadcrumbsFactory from "./customBreadcrumbsFactory";
import view from "./ReleaseNoteDetailsView";

export const releaseNoteDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATHS,
  legacyPath: LEGACY_MODULE_PATHS,
  exactPath: false,
  strictPath: false,
  showBreadcrumbs: true,
  name: MODULE_TITLE,
  title: MODULE_TITLE,
  className: "releaseNoteDetailsModule",
  icon,
  view,
  customBreadcrumbsFactory,
  disabled: !isReleaseNotesEnabled(),
});

export default releaseNoteDetailsModule;
