import React from "react";
import { SecurityGroup } from "@data";
import { GetSecurityGroupsResponse } from "@network";
import {
  usePaginatedApiRequest,
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
  useSecurityGroupsClient,
} from "@hooks";

type SuccessResponse = GetSecurityGroupsResponse;

export interface UseSecurityGroupsProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
  securityGroups?: SecurityGroup[];
  groupName?: string;
  limit?: number;
}

export interface UseSecurityGroupsModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  securityGroups: SecurityGroup[];
}

export interface UseSecurityGroupsActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseSecurityGroupsProps;
type Model = UseSecurityGroupsModel;
type Actions = UseSecurityGroupsActions;
type Result = [Model, Actions];

export const useSecurityGroups = (props: Props = {}): Result => {

  const {
    limit = 50,
    groupName = "",
    securityGroups: initialSecurityGroups = [],
    defaultErrorMessage = "Failed to get security groups",
    ...otherProps
  } = props;

  const SecurityGroupsClient = useSecurityGroupsClient();

  const [securityGroups, setSecurityGroups] = React.useState<SecurityGroup[]>(initialSecurityGroups);

  const makeApiRequest = React.useCallback((accessToken, next) =>
      SecurityGroupsClient.getSecurityGroups(next, groupName, limit),
    [SecurityGroupsClient, groupName, limit]);

  const [
    {successResponse, ...baseModel},
    {reset: baseReset, refresh: baseRefresh, ...baseActions},
  ] = usePaginatedApiRequest<SuccessResponse>({
    ...otherProps,
    defaultErrorMessage,
    makeApiRequest,
  });

  const updatedSecurityGroups = React.useMemo(() => {
    const { groups: items = [] } = successResponse || {};
    return items.map(attrs => new SecurityGroup(attrs));
  }, [successResponse]);

  const reset = React.useCallback(() => {
    setSecurityGroups([]);
    baseReset();
  }, [setSecurityGroups, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successResponse,
    securityGroups,
  }), [
    baseModel,
    successResponse,
    securityGroups,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    setSecurityGroups(currentSecurityGroups => currentSecurityGroups.concat(updatedSecurityGroups));
  }, [updatedSecurityGroups, setSecurityGroups]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useSecurityGroups;
