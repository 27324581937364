import React from "react";
import { NotFoundView, AccessDeniedView, } from "@components/error-view";
import {
  ComparableItem,
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components";
import SortedSearchResultsList from "@components/sorted-search-results-list";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { sortAndFilterListViewResult } from "@components/module-list-view/helpers";
import ModuleListItem from "./ModuleListItem";
import { RestClientError } from "@network";
import { noop } from "@util";

export interface ModuleListViewModel<Item extends ComparableItem<Item>, Column extends string>
  extends SortedSearchResultsListModel<Item, Column> {

  listViewItems?: ListViewItem<Item>[];
  showNotFound?: boolean;
  showAccessDenied?: boolean;
  errorResponse?: RestClientError;
}

export interface ModuleListViewActions<Item extends ComparableItem<Item>, Column extends string>
  extends SortedSearchResultsListActions<Item, Column> {

  customNameSearch?: (name: string) => void;
}

type Props<Item extends ComparableItem<Item>, Column extends string> =
  ModuleListViewModel<Item, Column> & ModuleListViewActions<Item, Column>;

export const ModuleListView = (
  <Item extends ComparableItem<Item>, Column extends string>
  (props: Props<Item, Column>) => {

    const {
      listViewItems = [],
      columns = [],
      sortByColumn: initialSortByColumn = "",
      sortOrderAscending: initialSortOrderAscending = true,
      showNotFound = false,
      showAccessDenied = false,
      nameFilterDelay = 250,
      refresh: baseRefresh = noop,
      errorMessage,
      errorResponse,
      nameFilter = "",
      setNameFilter = noop,
      customNameSearch,
      ...otherProps
    } = props;

    const [sortByColumn, setSortByColumn] = React.useState<string>(initialSortByColumn);
    const [sortOrderAscending, setSortOrderAscending] = React.useState(initialSortOrderAscending);

    const sortedItems = sortAndFilterListViewResult({
      items: listViewItems,
      nameFilter,
      sortOrderAscending,
      sortByColumn,
    });

    const items = React.useMemo(() =>
      sortedItems.map(sortedItem => sortedItem.item), [sortedItems]);

    const refresh = React.useCallback(() => {
      setSortByColumn(initialSortByColumn);
      setSortOrderAscending(initialSortOrderAscending);
      baseRefresh();
    }, [
      initialSortByColumn,
      initialSortOrderAscending,
      setSortByColumn,
      setSortOrderAscending,
      baseRefresh,
    ]);

    const setNameSearch = React.useCallback((name: string) => {
      customNameSearch ? customNameSearch(name) : setNameFilter(name);
    }, [setNameFilter, customNameSearch]);

    if (showNotFound) {
      return <NotFoundView errorMessage={errorResponse?.description || errorMessage}/>;
    }

    if (showAccessDenied) {
      return <AccessDeniedView errorMessage={errorResponse?.description || errorMessage}/>;
    }

    return (
      <SortedSearchResultsList
        {...otherProps}
        items={items}
        refresh={refresh}
        nameFilter={nameFilter}
        setNameFilter={setNameSearch}
        nameFilterDelay={nameFilterDelay}
        sortByColumn={sortByColumn}
        columns={columns}
        errorMessage={errorMessage}
        sortOrderAscending={sortOrderAscending}
        setSortByColumn={setSortByColumn}
        setSortOrderAscending={setSortOrderAscending}
        renderItem={(item: Item, column: string) => (
          <ModuleListItem
            column={column}
            item={item}
            listItems={listViewItems}
          />
        )}
      />
    );
  });

export default ModuleListView;
