import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { layout as styles } from "./styles";

export interface Model {
  informationBannerVisible?: boolean;
  children?: React.ReactNode;
}

export interface Actions {

}

type Props = WithStyles<typeof styles> & Model & Actions;

export const Layout = withStyles(styles)((props: Props) => {

  const { classes, informationBannerVisible, children } = props;

  return (
    <div
      className={classnames("layout", classes.container, {
        [classes.informationBannerVisible]: informationBannerVisible,
      })}
    >
      {children}
    </div>
  );
});

export default Layout;