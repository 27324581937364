import React from "react";
import { isEmptyString } from "@util";

export interface UseDocumentTitleProps {
  baseName?: string;
  pageName?: string;
}

export const useDocumentTitle = (props: UseDocumentTitleProps = {}) => {

  const { baseName = "IoT Portal", pageName = "" } = props;

  const documentTitle = React.useMemo(() =>
      [baseName, pageName].filter(it => !isEmptyString(it)).join(" - "),
    [baseName, pageName]);

  React.useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);
};

export default useDocumentTitle;
