import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import { logsView as styles } from "./styles";
import { MissingAlertView } from "@components";
import EndDateIcon from "@material-ui/icons/Today";
import RestClientError from "@network/RestClientError";
import StartDateIcon from "@material-ui/icons/DateRange";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { DatePicker } from "@components/date-picker";
import { DropdownMenu } from "@components/dropdown-menu";
import { ModuleListView } from "@components/module-list-view";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import { LogLevelType, WorkloadLogItem } from "@data";
import { SortedSearchResultsListActions, SortedSearchResultsListModel } from "@components/sorted-search-results-list";

export type LogLevelLabels<K extends keyof any = LogLevelType> = {
  [P in K]: string;
};

export const DEFAULT_LOG_LEVEL_LABELS: LogLevelLabels = {
  [LogLevelType.ALL]: "ALL",
  [LogLevelType.TRACE]: "TRACE",
  [LogLevelType.DEBUG]: "DEBUG",
  [LogLevelType.INFO]: "INFO",
  [LogLevelType.WARN]: "WARN",
  [LogLevelType.ERROR]: "ERROR",
  [LogLevelType.OFF]: "OFF",
  [LogLevelType.NOT_SPECIFIED]: "NOT SPECIFIED",
};

export enum LogsColumn {
  NONE = "",
  NUMBER = "#",
  TIMESTAMP = "Timestamp",
  LEVEL = "Level",
  MESSAGE = "Message",
}

export const DEFAULT_COLUMNS = [
  LogsColumn.NUMBER,
  LogsColumn.TIMESTAMP,
  LogsColumn.LEVEL,
  LogsColumn.MESSAGE,
];

const LOG_LEVEL_TYPES: LogLevelType[] = [
  LogLevelType.NOT_SPECIFIED,
  LogLevelType.ALL,
  LogLevelType.INFO,
  LogLevelType.DEBUG,
  LogLevelType.ERROR,
  LogLevelType.TRACE,
  LogLevelType.WARN,
  LogLevelType.OFF,
];
const DEFAULT_LIMITS: number[] = [10, 25, 50, 75, 100, 200, 500];

export interface Model extends SortedSearchResultsListModel<WorkloadLogItem, LogsColumn> {
  limit?: number;
  startDate?: Date | null;
  endDate?: Date | null;
  messageContains?: string;
  logLevel?: LogLevelType;
  logLevelLabels?: LogLevelLabels;
  errorResponse?: RestClientError;
}

export interface Actions extends SortedSearchResultsListActions<WorkloadLogItem, LogsColumn> {
  setStartDate?: (date?: Date) => void;
  setEndDate?: (date?: Date) => void;
  setLimit?: (limit: number) => void;
  setLogLevel?: (level: LogLevelType) => void;
  setMessageContains?: (message: string) => void;
  mapLogLevelToLabel?: (type: LogLevelType) => React.ReactNode | string | null;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const LogsView = withStyles(styles)((props: Props) => {

  const {
    classes,
    items = [],
    columns = DEFAULT_COLUMNS,
    showLoadingIndicator,
    limit,
    startDate,
    endDate,
    logLevel,
    messageContains,
    errorResponse,
    setLimit = noop,
    setStartDate = noop,
    setEndDate = noop,
    setLogLevel = noop,
    setMessageContains = noop,
    logLevelLabels = DEFAULT_LOG_LEVEL_LABELS,
    mapLogLevelToLabel = React.useCallback((type: LogLevelType) =>
      logLevelLabels[type] || null, [logLevelLabels]),
    ...otherProps
  } = props;

  const moduleListItems: ListViewItem<WorkloadLogItem>[] = React.useMemo(() =>
    items.map((log, index: number) => {
      return {
        item: log,
        pathToDetailsView: "",
        columnAttributes: createColumns([
          {
            className: "number",
            value: index + 1,
            column: LogsColumn.NUMBER,
          },
          {
            className: "timestamp",
            value: log.getTimestamp(),
            column: LogsColumn.TIMESTAMP,
          },
          {
            className: "level",
            value: log.getLogLevel(),
            column: LogsColumn.LEVEL,
          },
          {
            className: "message",
            value: log.getMessage(),
            column: LogsColumn.MESSAGE,
          },
        ])
      };
    }), [items]);

  const filters = React.useMemo(() => {
    return (
      <div className={classnames("filters", classes.filters)}>
        <DatePicker
          className={classnames("filterByStartDate", classes.datePicker)}
          label={"Start Date"}
          icon={<StartDateIcon/>}
          disabled={showLoadingIndicator}
          selectedDate={startDate}
          setSelectedDate={setStartDate}
          maxDate={endDate}
        />
        <DatePicker
          className={classnames("filterByEndDate", classes.datePicker)}
          label={"End Date"}
          icon={<EndDateIcon/>}
          disabled={showLoadingIndicator}
          selectedDate={endDate}
          setSelectedDate={setEndDate}
          minDate={startDate}
        />
        <DropdownMenu
          className={classnames("level", classes.level)}
          values={LOG_LEVEL_TYPES}
          disabled={showLoadingIndicator}
          selectedValue={logLevel}
          setSelectedValue={(state: LogLevelType) => setLogLevel(state)}
          mapValueToLabel={mapLogLevelToLabel}
          dropdownMenuLabel={"Level"}
          hideEmptyValue={true}
        />
        <DropdownMenu
          className={classnames("limit", classes.limit)}
          values={DEFAULT_LIMITS.map(value => value.toString())}
          disabled={showLoadingIndicator}
          selectedValue={`${limit}`}
          setSelectedValue={(selectedValue: string) => setLimit(Number(selectedValue))}
          dropdownMenuLabel={"Limit"}
          hideEmptyValue={true}
        />
      </div>
    );
  }, [
    limit,
    startDate,
    endDate,
    logLevel,
    messageContains,
    setLimit,
    setStartDate,
    setEndDate,
    setLogLevel,
    setMessageContains,
    mapLogLevelToLabel,
    showLoadingIndicator,
  ]);

  if (errorResponse?.message === "NotSupported") {
    return (
      <div className={classnames("logsNotSupported", classes.notSupported)}>
        <MissingAlertView
          message={"This workload does not support the ability to show the execution instance logs."}
          showAction={false}
        />
      </div>
    );
  }

  return (
    <ModuleListView
      { ...otherProps }
      className={classnames("logsView", classes.container)}
      listViewItems={moduleListItems}
      columns={columns}
      showSearch={true}
      fixedRowHeight={false}
      tableLayoutFixed={false}
      sortingDisabled={true}
      alternateRowColor={true}
      whiteSpaceNoWrapCols={[0, 1, 2]}
      showLoadingIndicator={showLoadingIndicator}
      nameFilter={messageContains}
      setNameFilter={setMessageContains}
      header={filters}
    />
  );
});

export default LogsView;
