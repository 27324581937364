import React from "react";
import classnames from "classnames";
import { deviceEnrollmentEditorView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { noop } from "@util";
import { SaveButton } from "@components";
import { DeviceDataModel } from "@data";

export interface Model {
  isAddDeviceWizard?: boolean;
  isDownloadFileButtonVisible?: boolean;
  downloadButtonDisabled?: boolean;
  devices?: DeviceDataModel[];
  children?: React.ReactNode;
}

export interface Actions {
  covertToBase64?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const EditorView = withStyles(styles)((props: Props) => {

  const {
    classes,
    isAddDeviceWizard = false,
    isDownloadFileButtonVisible = false,
    devices = [],
    downloadButtonDisabled = true,
    children,
    covertToBase64 = noop,
  } = props;

  const download = React.useCallback(() => {
    const base64String = devices.map(device => btoa(JSON.stringify(device.toJS()))).join("\n");

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(base64String));
    element.setAttribute("download",   devices[0].getDeviceId() + "_devices_encoded");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();
  }, [covertToBase64, document, devices]);

  return (
    <div className={classnames("editorView", classes.container)}>
      <div className={classnames("headerContainer", classes.headerContainer)}>
        <label className={classnames("title", classes.title)}>
          {isAddDeviceWizard ? "Device Editor" : "Devices Editor"}
        </label>
        {isDownloadFileButtonVisible && (
          <SaveButton
            className={classnames("downloadEncodedFile", classes.downloadEncodedFile)}
            label="Download Batch Enrollment File"
            disabled={downloadButtonDisabled}
            save={download}
          />
        )}
      </div>
      {children}
    </div>
  );
});

export default EditorView;
