import { createStyles, StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const addButton: StyleRules = {
  root: {
    height: 50,
    padding: "0 8px",
  },
  label: {
    color: Color.GREY7,
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    padding: 0,
    margin: 0,
    textTransform: "none",
  },
};

export const requiredIcon: StyleRules = {
  root: {
    color: Color.SIGNIFY_GREEN,
    width: 16,
    height: 16,
    marginLeft: 8,
  },
};

export const tableHeaderRow: StyleRules = {
  root: {
    height: 50,
  },
};

export const tableHeaderCell: StyleRules = {
  root: {
    color: Color.GREY3,
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: 400,
    fontStyle: "normal",
    textAlign: "left",
    width: "auto",
    height: "auto",
    minHeight: 50,
    padding: "0 0 0 8px",
  },
};

export const tableBodyRow: StyleRules = {
  root: {
    height: "auto",
  },
};

export const tableBodyCell: StyleRules = {
  root: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto, sans-serif",
    fontSize: 13,
    lineHeight: "16px",
    fontWeight: 400,
    fontStyle: "normal",
    width: "auto",
    height: "auto",
    minHeight: 50,
    padding: 8,
  },
};

export const styles = () =>
  createStyles({
    table: {
      borderCollapse: "collapse",
      overflow: "hidden",
      tableLayout: "fixed",
      marginTop: 8,
    },
    nameColumn: {
    },
    typeColumn: {
    },
    descriptionColumn: {
    },
    defaultValueColumn: {
    },
    requiredColumn: {
    },
    actionsColumn: {
      width: 100,
    },
    clickable: {
      cursor: "pointer",
    },
    addButtonRow: {
      padding: 6,
    },
    addButtonColumn: {
      textAlign: "center",
    },
    addButton: {
    },
    footerRow: {
    },
    footerColumn: {
    },
  });

export default styles;
