import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { useDeleteActionLabel } from "@hooks";

export interface WorkloadDeleteActionLabelDialogModel extends PortalModuleDialogModel<string> {
  name?: string;
  version?: number;
  etag?: string;
  actionLabel?: string;
}

export interface WorkloadDeleteActionLabelDialogActions extends PortalModuleDialogActions<string> {
}

type Props = WithStyles<typeof styles> & WorkloadDeleteActionLabelDialogModel & WorkloadDeleteActionLabelDialogActions;

export const WorkloadDeleteActionLabelDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    name = "",
    version = 1,
    etag = "",
    actionLabel = "",
    cancel: cancelDialog = noop,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  const [{ showLoadingIndicator, ...otherModel }, { deleteActionLabel, reset }] =
    useDeleteActionLabel({ name, version, etag, actionLabel });

  const cancel = React.useCallback(() => {
    reset();
    cancelDialog();
  }, [reset, cancelDialog]);

  const onSuccessMessageShown = React.useCallback(() => {
    reset();
    onSuccess();
  }, [reset, onSuccess]);

  if (isEmptyString(name)) {
    return null;
  }

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      className="workloadDeleteActionLabelDialog"
      title="Remove Workload Completion Action"
      loading={showLoadingIndicator}
      continueButtonLabel="Remove"
      onSuccessMessageShown={onSuccessMessageShown}
      confirm={deleteActionLabel}
      cancel={cancel}
    >
      <div className={classnames("workloadDeleteActionLabelContainer", classes.container)}>
        <label className={classnames("title", classes.title)}>
          Are you sure you want to remove the action label {actionLabel} from the workload {name}?
        </label>
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadDeleteActionLabelDialog;
