import React from "react";
import classnames from "classnames";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { propertyInfoLabel as styles } from "./styles";

export const PropertyInfoLabel = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  name: string,
  value?: string,
}) => {

  const { classes, className = "", name = "", value = "" } = props;

  return (
    <div className={classnames("schemaInfoLabel", className, classes.container)}>
      <label className={classnames("name", classes.label, classes.emphasis)}>
        {name}:
      </label>
      <label className={classnames("value", classes.label)}>
        {value || "N/A"}
      </label>
    </div>
  );
});

export default PropertyInfoLabel;
