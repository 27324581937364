import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { DataSetRequestWizardActions } from "../actions";
import { DataSetRequestWizardSelectors } from "../selectors";
import { DataClassification, IngestionMechanism } from "@data";
import DataDescriptionView, { Model, Actions } from "../components/DataDescriptionView";

interface ContainerModel extends Model {
  hidden?: boolean;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const DataDescriptionViewContainer = (props: Props) => {

  const { hidden, ...otherProps } = props;

  if (hidden) {
    return null;
  }

  return <DataDescriptionView {...otherProps} />;
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  hidden: !DataSetRequestWizardSelectors.isDataDescriptionViewSelected(state),
  schema: DataSetRequestWizardSelectors.getSchema(state),
  schemaError: DataSetRequestWizardSelectors.getSchemaErrorMessage(state),
  sampleAnonymizedData: DataSetRequestWizardSelectors.getSampleAnonymizedData(state),
  sampleAnonymizedDataError: DataSetRequestWizardSelectors.getSampleAnonymizedDataErrorMessage(state),
  dataClassification: DataSetRequestWizardSelectors.getDataClassification(state),
  ingestionMechanism: DataSetRequestWizardSelectors.getIngestionMechanism(state),
  dataRangeErrorMessage: DataSetRequestWizardSelectors.getDataRangeErrorMessage(state),
  dataVolume: DataSetRequestWizardSelectors.getDataVolume(state),
  dataVolumeError: DataSetRequestWizardSelectors.getDataVolumeErrorMessage(state),
  dataVelocity: DataSetRequestWizardSelectors.getDataVelocity(state),
  dataVelocityError: DataSetRequestWizardSelectors.getDataVelocityErrorMessage(state),
  lifecycleErrorMessage: DataSetRequestWizardSelectors.getLifecycleErrorMessage(state),
  archivingInDays: DataSetRequestWizardSelectors.getArchivingInDaysLifecyclePolicy(state),
  archivingInDaysError: DataSetRequestWizardSelectors.getArchivingInDaysValidationErrorMessage(state),
  expirationInDays: DataSetRequestWizardSelectors.getExpirationInDaysLifecyclePolicy(state),
  expirationInDaysError: DataSetRequestWizardSelectors.getExpirationInDaysValidationErrorMessage(state),
  confirmNonProductionData: DataSetRequestWizardSelectors.getConfirmNonProductionData(state),
  showIngestionMechanism: DataSetRequestWizardSelectors.showIngestionMechanism(state),
  isProductionAccount: DataSetRequestWizardSelectors.isProductionAccount(state),
  containsPII: DataSetRequestWizardSelectors.getContainsPiiData(state),
  piiComment: DataSetRequestWizardSelectors.getPiiComment(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setSchema: (schema: string) =>
    dispatch(DataSetRequestWizardActions.updateSchema(schema)),
  setSampleAnonymizedData: (sampleAnonymizedData: string) =>
    dispatch(DataSetRequestWizardActions.updateSampleAnonymizedData(sampleAnonymizedData)),
  setDataClassification: (dataClassification: DataClassification) =>
    dispatch(DataSetRequestWizardActions.updateDataClassification(dataClassification)),
  setIngestionMechanism: (ingestionMechanism: IngestionMechanism) =>
    dispatch(DataSetRequestWizardActions.updateIngestionMechanism(ingestionMechanism)),
  setDataVolume: (dataVolume:  number) =>
    dispatch(DataSetRequestWizardActions.updateDataVolume(dataVolume)),
  setDataVelocity: (dataVelocity:  number) =>
    dispatch(DataSetRequestWizardActions.updateDataVelocity(dataVelocity)),
  setArchivingInDays: (archivingInDays: number) =>
    dispatch(DataSetRequestWizardActions.updateArchivingInDays(archivingInDays)),
  setExpirationInDays: (expirationInDays: number) =>
    dispatch(DataSetRequestWizardActions.updateExpirationInDays(expirationInDays)),
  setConfirmNonProductionData: (confirmNonProductionData: boolean) =>
    dispatch(DataSetRequestWizardActions.updateConfirmNonProductionData(confirmNonProductionData)),
  setContainsPIIData: (containsPII: boolean) =>
    dispatch(DataSetRequestWizardActions.updateContainsPII(containsPII)),
  setPiiComment: (comment: string) =>
    dispatch(DataSetRequestWizardActions.updatePiiComment(comment)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps
)(DataDescriptionViewContainer);
