import { isEmptyString, isValidNumber } from "@util";
import { DEFAULT_ERROR_MESSAGE, withRestClientErrorHandling } from "./RestClientError";
import isDeviceTypeV2APIEnabled from "@util/isDeviceTypeV2APIEnabled";

const APPLICATION_ID = process.env.REACT_APP_FEDERATION_APP_ID || "";

if (isEmptyString(APPLICATION_ID)) {
  throw new Error("Missing Environment Variable: REACT_APP_FEDERATION_APP_ID");
}

const DEVICE_TYPE_V2_API_ENABLED = isDeviceTypeV2APIEnabled();

const ETAG_HEADER = "ETag";
const LOCATION_HEADER_REGEX = /^.*\/ns\/(.+)\/n\/(.+)\/v\/(.+)$/;
const LOCATION_HEADER_REGEX_UPDATED = /^.*\/data\/modeling\/v1\/schemas\/identity\/(.+):(.+):(.+)$/;
const DEVICE_ENROLL_LOCATION_HEADER_REGEX = /^.*\/device-management\/enrollment\/v1\/devices\/(.+)\/status$/;
const DEVICE_MANAGEMENT_LOCATION_HEADER_REGEX =
  /^.*\/device-management\/modeling\/v1\/types\/identity\/(.+):(.+):(.+)$/;

const getLocationMatch = (location: string, index: number, defaultValue: string = ""): string => {

  if (DEVICE_MANAGEMENT_LOCATION_HEADER_REGEX.test(location)) {
    const match = location.match(DEVICE_MANAGEMENT_LOCATION_HEADER_REGEX);
    if (Array.isArray(match) && match.length > index) {
      return `${match[index]}`.trim();
    }
  }

  if (!LOCATION_HEADER_REGEX.test(location)) {
    if (!LOCATION_HEADER_REGEX_UPDATED.test(location)) {
      return defaultValue;
    } else {
      const matchUpdated = location.match(LOCATION_HEADER_REGEX_UPDATED);

      if (Array.isArray(matchUpdated) && matchUpdated.length > index) {
        return `${matchUpdated[index]}`.trim();
      }
    }
  } else {
    const match = location.match(LOCATION_HEADER_REGEX);

    if (Array.isArray(match) && match.length > index) {
      return match[index].trim();
    }
  }

  return defaultValue;
};

export const getNamespaceFromLocation = (location: string = "") => getLocationMatch(location, 1);
export const getNameFromLocation = (location: string = "") => getLocationMatch(location, 2);
export const getVersionFromLocation = (location: string = "") => getLocationMatch(location, 3);

export const getDeviceIdFromLocation = (location: string = ""): string => {

  if (!DEVICE_ENROLL_LOCATION_HEADER_REGEX.test(location)) {
    return "";
  } else {
    const matchUpdated = location.match(DEVICE_ENROLL_LOCATION_HEADER_REGEX);

    if (Array.isArray(matchUpdated) && matchUpdated.length > 1) {
      return `${matchUpdated[1]}`.trim();
    }
  }

  return "";
};

export interface ETagHeaders {
  etag?: string;
}

export interface DeviceEnrollmentLocationHeaders {
  deviceId?: string;
}

export interface ETagLocationHeaders extends ETagHeaders {
  location?: string;
  namespace?: string;
  name?: string;
  version?: number;
  nameAndVersion?: string;
}

// NOTE: Usage requires additional CORS response header for client to grab the specified header
// from the response: Access-Control-Expose-Headers: ${headerName}
export const getResponseHeaderValue = (response: Response,
                                       name: string,
                                       defaultValue: string = ""): string => {

  if (!response.headers || typeof response.headers.get !== "function") {
    return defaultValue;
  }

  return response.headers.get(name) || defaultValue;
};

export const getEtagResponseHeader = (response: Response, defaultValue: string = ""): string => {
  return getResponseHeaderValue(response, ETAG_HEADER, defaultValue);
};

export const getETagHeaders = (response: Response): ETagHeaders => {

  const etag = getEtagResponseHeader(response);

  return {
    ...(isEmptyString(etag) ? {} : { etag }),
  };
};

export const getETagLocationHeaders = (response: Response): ETagLocationHeaders => {

  const etag = getEtagResponseHeader(response);
  const location = getResponseHeaderValue(response, "location");

  const headers = {
    ...(isEmptyString(etag) ? {} : { etag }),
    ...(isEmptyString(location) ? {} : { location }),
  };

  if (!LOCATION_HEADER_REGEX.test(location)) {
    if (!LOCATION_HEADER_REGEX_UPDATED.test(location)) {
      if (!DEVICE_MANAGEMENT_LOCATION_HEADER_REGEX.test(location)) {
        return headers;
      }
    }
  }

  const namespace = getNamespaceFromLocation(location);
  const name = getNameFromLocation(location);
  const version = getVersionFromLocation(location);

  const isNamespaceValid = !isEmptyString(namespace);
  const isNameValid = !isEmptyString(name);
  const isVersionValid = isValidNumber(version);

  return {
    ...headers,
    ...(!isNamespaceValid ? {} : { namespace }),
    ...(!isNameValid ? {} : { name }),
    ...(!isVersionValid ? {} : { version: Number(version) }),
    ...(!isNamespaceValid || !isNameValid || !isVersionValid ? {} : {
      nameAndVersion: `${namespace}:${name}:${version}`,
    }),
  };
};

export const getDeviceIdFromHeader = (response: Response): DeviceEnrollmentLocationHeaders => {

  const location = getResponseHeaderValue(response, "Location");

  const deviceId = getDeviceIdFromLocation(location);

  const isDeviceIdValid = !isEmptyString(deviceId);

  return {
    ...(!isDeviceIdValid ? {} : { deviceId })
  };
};

export const catchNetworkError = (defaultErrorMessage: string = DEFAULT_ERROR_MESSAGE) =>
  (error: Error) => {

    const message = error && error.message ? error.message : defaultErrorMessage;

    return Promise.reject({
      message,
      status: 500,
      description: defaultErrorMessage,
      error: message,
      analytic: `500:${message}`,
    });
  };

export const makeApiRequest =
  (url: string, settings: RequestInit, defaultErrorMessage?: string): Promise<Response> => {

    return fetch(url, settings)
      .catch(catchNetworkError(defaultErrorMessage))
      .then(withRestClientErrorHandling(defaultErrorMessage));
  };

export const makeJsonApiRequest =
  <T = any>(url: string, settings: RequestInit, defaultErrorMessage?: string): Promise<T> =>
    makeApiRequest(url, settings, defaultErrorMessage)
      .then((response: Response) => response.json());

export const makeApiRequestAndComplete =
  (url: string, settings: RequestInit, defaultErrorMessage?: string): Promise<void> =>
    makeApiRequest(url, settings, defaultErrorMessage)
      .then(() => Promise.resolve());

export const withAuthToken = (authToken?: string) => {
  if (isEmptyString(authToken)) {
    return Promise.reject({
      status: 401,
      message: "Unauthorized",
      description: "Not Authorized",
      error: "Not Authorized",
      analytic: "401:Unauthorized",
    });
  } else {
    return Promise.resolve(authToken);
  }
};

export const withRequiredArguments = (requiredArgs: (string | undefined)[][]) =>
  Promise.all(requiredArgs.map(([key, value]) => {
    if (isEmptyString(value)) {
      const error = `Invalid ${key}`;
      return Promise.reject({
        error,
        status: 400,
        message: "Bad Request",
        description: error,
        analytic: `400:Bad Request:${error}`,
      });
    } else {
      return Promise.resolve(value);
    }
  }));

// We allow the following query param values in our apis
//   - Any non-empty string
//   - Any number
//   - Any boolean
const isQueryParamValueAllowed = (value: any = "") => {
  return (!isEmptyString(value) || isValidNumber(value) || (typeof value === "boolean"));
};

// Helper that can be used to convert an object of key/value pairs into a query params string
export const createQueryParams = (params: any = {}): string => {

  const queryParams = Object.keys(params)
    .filter((key: string) => !isEmptyString(key) && isQueryParamValueAllowed(params[key]))
    .map((key: string) => `${key}=${encodeURIComponent(decodeURIComponent(params[key]))}`);

  return queryParams.length === 0 ? "" : `?${queryParams.join("&")}`;
};

export const withApplicationIdHeader = (headers: any = {}): any => ({
  ...headers,
  "X-IoT-Platform-ApplicationId": APPLICATION_ID,
});

export const withDeviceTypeV2ApiSupport = () => {
  if (!DEVICE_TYPE_V2_API_ENABLED) {
    return Promise.reject({
      error: "Device Type Model Version Not Supported",
      status: 415,
      message: "Oops, this device type model version is not supported by the IoT Portal",
      description: "Not Supported",
      analytic: "415:NotSupported:Historical Device Type API Not Supported",
    });
  } else {
    return Promise.resolve();
  }
};
