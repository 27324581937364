import { createSelectors, Selector } from "@base";
import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { DEFAULT_STATE, SecurityServiceRegionalDetailsSchema, PoliciesViewMode } from "./reducers";
import { createSelector } from "reselect";
import {
  ActionMenuItem,
  ADD_SERVICE_TO_GROUP_ACTION,
  ATTACH_POLICY_TO_SERVICE_ACTION,
  DELETE_SERVICE_REGIONAL,
  DETACH_POLICY_FROM_SERVICE_ACTION,
  REMOVE_SERVICE_FROM_GROUP_ACTION,
} from "@components";
import { AppSchema } from "@schemas";

export const {
  serviceId: getServiceId,
  policiesViewMode: getPoliciesViewMode,
  removeServiceButtonEnabled: isRemoveServiceButtonEnabled,
  detachPolicyButtonEnabled: isDetachPolicyButtonEnabled,
  getCurrentServiceId,
} = createSelectors<SecurityServiceRegionalDetailsSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const isCustomPoliciesViewActive: (state: AppSchema) => boolean = createSelector(
    getPoliciesViewMode, (viewMode: PoliciesViewMode) =>
        PoliciesViewMode.CUSTOM === viewMode);

export const isManagedPoliciesViewActive: (state: AppSchema) => boolean = createSelector(
    getPoliciesViewMode, (viewMode: PoliciesViewMode) =>
        PoliciesViewMode.MANAGED === viewMode);

export const getActionMenuItems: Selector<ActionMenuItem[]> = createSelector(
    [isRemoveServiceButtonEnabled, isDetachPolicyButtonEnabled],
    (removeServiceButtonEnabled: boolean, detachPolicyButtonEnabled: boolean) => ([
      ADD_SERVICE_TO_GROUP_ACTION,
      {
        ...REMOVE_SERVICE_FROM_GROUP_ACTION,
        disabled: !removeServiceButtonEnabled,
      },
      ATTACH_POLICY_TO_SERVICE_ACTION,
      {
        ...DETACH_POLICY_FROM_SERVICE_ACTION,
        disabled: !detachPolicyButtonEnabled,
      },
      DELETE_SERVICE_REGIONAL,
    ]));
