import React from "react";
import classnames from "classnames";
import QualityReport from "@data/QualityReport";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import CVEVulnerabilities from "./CVEVulnerabilities";
import Vulnerabilities from "./Vulnerabilities";
import styles from "./styles";

export interface SecuritySectionModel {
  className?: string;
  title?: string;
  openSourceVulnerabilitiesTitle?: string;
  staticCodeAnalysisVulnerabilitiesTitle?: string;
  currentReport?: QualityReport;
  previousReport?: QualityReport;
}

export interface SecuritySectionActions {
}

type Model = SecuritySectionModel;
type Actions = SecuritySectionActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const SecuritySection = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Security",
    openSourceVulnerabilitiesTitle = "Vulnerabilities (Open Source)",
    staticCodeAnalysisVulnerabilitiesTitle = "Vulnerabilities (Static Code Analysis)",
    currentReport = QualityReport.EMPTY,
    previousReport = QualityReport.EMPTY,
    children,
  } = props;

  const showHistoricalTrends = React.useMemo(() =>
      !QualityReport.EMPTY.equals(previousReport),
    [previousReport]);

  return (
    <div className={classnames("securitySection", className, classes.container)}>
      <Typography
        className={classnames("title", classes.title)}
        variant="h5"
      >
        {title}
      </Typography>
      <div className={classnames("content", classes.content)}>
        <CVEVulnerabilities
          className={classnames("openSourceVulnerabilities",
            classes.column,
            classes.openSourceVulnerabilities,
          )}
          title={openSourceVulnerabilitiesTitle}
          currentReport={currentReport.getOpenSourceVulnerabilities()}
          previousReport={previousReport.getOpenSourceVulnerabilities()}
          showHistoricalTrends={showHistoricalTrends}
        />
        <Vulnerabilities
          className={classnames("staticCodeAnalysisVulnerabilities",
            classes.column,
            classes.staticCodeAnalysisVulnerabilities,
          )}
          title={staticCodeAnalysisVulnerabilitiesTitle}
          currentReport={currentReport.getStaticCodeAnalysisVulnerabilities()}
          previousReport={previousReport.getStaticCodeAnalysisVulnerabilities()}
          showHistoricalTrends={showHistoricalTrends}
        />
      </div>
      {children}
    </div>
  );
});

export default SecuritySection;
