import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    link: {
      color: Color.BLACK,
      cursor: "pointer",
    },
  });

export default styles;
