import React from "react";
import classnames from "classnames";
import SecurityIcon from "@material-ui/icons/Lock";
import Typography from "@material-ui/core/Typography";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import FunctionalIcon from "@material-ui/icons/Accessibility";
import QualityReport, { QualityReportTrend } from "@data/QualityReport";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const getQualityReportTrend = (trendDelta: number) => {
  if (trendDelta === 0) {
    return QualityReportTrend.FLAT;
  } else {
    return trendDelta < 0
      ? QualityReportTrend.TRENDING_DOWN
      : QualityReportTrend.TRENDING_UP;
  }
};

export interface TestsModel {
  className?: string;
  title?: string;
  currentReport?: QualityReport;
  previousReport?: QualityReport;
}

export interface TestsActions {
}

type Model = TestsModel;
type Actions = TestsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const Tests = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Tests",
    currentReport = QualityReport.EMPTY,
    previousReport = QualityReport.EMPTY,
    children,
  } = props;

  const showHistoricalTrends = React.useMemo(() =>
      !QualityReport.EMPTY.equals(previousReport),
    [previousReport]);

  const numSecurityTests = React.useMemo(() =>
      currentReport.getNumSecurityTests(),
    [currentReport]);

  const securityTestsTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumSecurityTests() - previousReport.getNumSecurityTests()),
    [showHistoricalTrends, currentReport, previousReport]);

  const securityTestsTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(securityTestsTrendDelta),
    [showHistoricalTrends, securityTestsTrendDelta]);

  const numFunctionalTests = React.useMemo(() =>
      currentReport.getNumFunctionalTests(),
    [currentReport]);

  const functionalTestsTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumFunctionalTests() - previousReport.getNumFunctionalTests()),
    [showHistoricalTrends, currentReport, previousReport]);

  const functionalTestsTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(functionalTestsTrendDelta),
    [showHistoricalTrends, functionalTestsTrendDelta]);

  const numTests = React.useMemo(() =>
      currentReport.getNumTotalTests(),
    [currentReport]);

  const securityTestsBarClipPath = React.useMemo(() =>
      numTests <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numSecurityTests / numTests)) * 100}% 0% 0%)`,
    [numTests, numSecurityTests]);

  const functionalTestsBarClipPath = React.useMemo(() =>
      numTests <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numFunctionalTests / numTests)) * 100}% 0% 0%)`,
    [numTests, numFunctionalTests]);

  return (
    <div className={classnames("tests", className, classes.container)}>
      <Typography
        className={classnames("title", classes.title)}
        variant="h5"
      >
        {title}
      </Typography>
      <div className={classnames("content", classes.content)}>
        <div className={classnames("securityTests", classes.row)}>
          <SecurityIcon
            className={classnames("securityIcon", classes.typeOfTestIcon, classes.security)}
          />
          <Typography className={classnames("typeOfTestLabel", classes.typeOfTestLabel)}>
            Security
          </Typography>
          <div
            className={classnames("securityTestsBar", classes.typeOfTestBar, classes.security)}
            style={{ clipPath: securityTestsBarClipPath }}
          />
          <Typography className={classnames("typeOfTestCountLabel", classes.typeOfTestCountLabel)}>
            {numSecurityTests}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {securityTestsTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {securityTestsTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {securityTestsTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {securityTestsTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: securityTestsTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: securityTestsTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: securityTestsTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({securityTestsTrendDelta < 0 ? "" : "+"}{securityTestsTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("functionalTests", classes.row)}>
          <FunctionalIcon
            className={classnames("functionalIcon", classes.typeOfTestIcon, classes.functional)}
          />
          <Typography className={classnames("typeOfTestLabel", classes.typeOfTestLabel)}>
            Functional
          </Typography>
          <div
            className={classnames("functionalTestsBar", classes.typeOfTestBar, classes.functional)}
            style={{ clipPath: functionalTestsBarClipPath }}
          />
          <Typography className={classnames("typeOfTestCountLabel", classes.typeOfTestCountLabel)}>
            {numFunctionalTests}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {functionalTestsTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {functionalTestsTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {functionalTestsTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {functionalTestsTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: functionalTestsTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: functionalTestsTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: functionalTestsTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({functionalTestsTrendDelta < 0 ? "" : "+"}{functionalTestsTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("footer", classes.footer)}>
          <Typography className={classnames("totalCountPrefix", classes.totalCountPrefix)}>
            {numTests}
            <Typography
              className={classnames("totalCountSuffix", classes.totalCountSuffix)}
              component="span"
            >
              {numTests === 1 ? "test" : "tests"}
            </Typography>
          </Typography>
        </div>
      </div>
      {children}
    </div>
  );
});

export default Tests;
