import { DeviceProvisioningDataAttributes } from "@data";
import { DeviceProvisioningClient } from "@network";
import { useApiRequestAnalytics } from "@hooks";

export type GetDeviceBootstrapConfigurationApi =
  (authorization: string, deviceId: string) => Promise<DeviceProvisioningDataAttributes>;

export interface DeviceProvisioningClientApi {
  getDeviceBootstrapConfiguration: GetDeviceBootstrapConfigurationApi;
}

enum ActionType {
  GET_DEVICE_BOOTSTRAP_CONFIGURATION_REQUEST = "device_provisioning_client/get_device_bootstrap_configuration_request",
  GET_DEVICE_BOOTSTRAP_CONFIGURATION_SUCCESS = "device_provisioning_client/get_device_bootstrap_configuration_success",
  GET_DEVICE_BOOTSTRAP_CONFIGURATION_FAILED = "device_provisioning_client/get_device_bootstrap_configuration_failed",
}

export const useGetDeviceBootstrapConfigurationApi = (): GetDeviceBootstrapConfigurationApi =>
  useApiRequestAnalytics(DeviceProvisioningClient.getDeviceBootstrapConfiguration, {
    REQUEST_EVENT: ActionType.GET_DEVICE_BOOTSTRAP_CONFIGURATION_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DEVICE_BOOTSTRAP_CONFIGURATION_SUCCESS,
    ERROR_EVENT: ActionType.GET_DEVICE_BOOTSTRAP_CONFIGURATION_FAILED,
  });

export const useDeviceProvisioningClient = (): DeviceProvisioningClientApi => ({
  getDeviceBootstrapConfiguration: useGetDeviceBootstrapConfigurationApi(),
});

export { ActionType as DeviceProvisioningClientActionType };

export default useDeviceProvisioningClient;
