import Color from "@Color";
import ModuleIcon from "@material-ui/icons/CalendarToday";
import { createStyles } from "@material-ui/core/styles";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    triggers: {
      marginBottom: 50,
    },
  });

export default styles;
