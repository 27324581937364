import { Record } from "immutable";
import { getStringValue } from "@util";

export interface DataLakeCredentialAttributes {
  accessKeyId: string;
  secretKey: string;
  sessionToken: string;
  tokenExpiresAt: string;
}

export interface DataLakeScopeAttributes {
  serviceNames: string[];
}
export interface DataLakeDownloadCredentialsAttributes {
  vendor: string;
  credential: DataLakeCredentialAttributes;
  scope: DataLakeScopeAttributes;
}

export class DataLakeDownloadCredentials extends Record({
  vendor: "",
  credential: {
    accessKeyId: "",
    secretKey: "",
    sessionToken: "",
    tokenExpiresAt: "",
  },
  scope: {
    serviceNames: [],
  },
}) implements DataLakeDownloadCredentialsAttributes {

  public static EMPTY: DataLakeDownloadCredentials = new DataLakeDownloadCredentials();

  public readonly vendor: string;
  public readonly credential: DataLakeCredentialAttributes;
  public readonly scope: DataLakeScopeAttributes;

  public getVendor(): string {
    return getStringValue(this.vendor);
  }

  public getServiceNames(): string[] {
    const { serviceNames = [] } = {} = this.scope || {};

    if (!Array.isArray(serviceNames)) {
      return [];
    }

    return serviceNames;
  }

  public getAccessKeyId(): string {
    return getStringValue(this.credential.accessKeyId);
  }

  public getSecretKey(): string {
    return getStringValue(this.credential.secretKey);
  }

  public getSessionToken(): string {
    return getStringValue(this.credential.sessionToken);
  }

  public getTokenExpiresAt(): string {
    return getStringValue(this.credential.tokenExpiresAt);
  }

}
