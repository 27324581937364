import React from "react";
import {
  UseApiRequestModel,
  UseApiRequestProps,
  useApiRequest,
  useNamespaceClient
} from "@hooks";
import { NamespaceLifecycle } from "@data";

export interface UseNamespaceLifecycleManagementProps extends Partial<UseApiRequestProps<void>> {
  namespace: string;
  state: NamespaceLifecycle;
}

export interface  UseNamespaceLifecycleManagementModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface  UseNamespaceLifecycleManagementActions {
  updateState: () => void;
}

type Props =  UseNamespaceLifecycleManagementProps;
type Model =  UseNamespaceLifecycleManagementModel;
type Actions =  UseNamespaceLifecycleManagementActions;
type Result = [Model, Actions];

export const useNamespaceLifecycleManagement = (props: Props): Result => {

  const {
    namespace = "",
    state = NamespaceLifecycle.NONE,
    ...otherProps
  } = props;

  const NamespaceClient = useNamespaceClient();

  const defaultErrorMessage = React.useMemo(() =>
    `Failed to update namespace state to ${state}`, [state]);

  const makeApiRequest = React.useCallback(() =>
    NamespaceClient.updateNamespaceLifecycle(namespace, state),
    [NamespaceClient, namespace, state]);

  const [{ showSuccessView, ...baseModel }, { refresh: updateState }] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? `Namespace updated` : "" ,
    [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    updateState
  }), [updateState]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useNamespaceLifecycleManagement;
