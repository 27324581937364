import { AppSchema } from "@schemas";
import { identityAction, ReduxAction } from "@util";
import { DeviceTypeClient, RestClientError } from "../../../network";
import { getAuthToken } from "@main/selectors";
import { DeviceTypeDetailsActions } from "../actions";
import { DeviceTypeDetailsSelectors } from "../selectors";
import {
  DEFAULT_STATE,
  ManageSoftwareVersionsAction,
  ManageSoftwareVersionsActionType as ActionType,
} from "../reducers/manageSoftwareVersions";

type Action = ReduxAction<ActionType>;

export const setAction = identityAction<ActionType, ManageSoftwareVersionsAction>(
  ActionType.SET_ACTION, DEFAULT_STATE.action);

export const setSoftwareVersion = identityAction<ActionType, string>(
  ActionType.SET_SOFTWARE_VERSION, DEFAULT_STATE.softwareVersion);

export const setErrorMessage = identityAction<ActionType, string>(
  ActionType.SET_ERROR_MESSAGE, DEFAULT_STATE.errorMessage);

export const setSuccessMessage = identityAction<ActionType, string>(
  ActionType.SET_SUCCESS_MESSAGE, DEFAULT_STATE.successMessage);

export const setShowAccessDenied = identityAction<ActionType, boolean>(
  ActionType.SET_SHOW_ACCESS_DENIED, DEFAULT_STATE.showAccessDenied);

export const setShowLoadingIndicator = identityAction<ActionType, boolean>(
  ActionType.SET_SHOW_LOADING_INDICATOR, DEFAULT_STATE.showLoadingIndicator);

export const setShowRemoveDialog = identityAction<ActionType, boolean>(
  ActionType.SET_SHOW_REMOVE_DIALOG, DEFAULT_STATE.showRemoveDialog);

export const addSoftwareVersionRequest = (): Action => ({
  type: ActionType.ADD_SOFTWARE_VERSION_REQUEST,
});

export const addSoftwareVersionSuccess = (): Action => ({
  type: ActionType.ADD_SOFTWARE_VERSION_SUCCESS,
});

export const addSoftwareVersionFailed = (error: string): Action => ({
  type: ActionType.ADD_SOFTWARE_VERSION_FAILED,
  value: error,
});

export const removeSoftwareVersionRequest = (): Action => ({
  type: ActionType.REMOVE_SOFTWARE_VERSION_REQUEST,
});

export const removeSoftwareVersionSuccess = (): Action => ({
  type: ActionType.REMOVE_SOFTWARE_VERSION_SUCCESS,
});

export const removeSoftwareVersionFailed = (error: string): Action => ({
  type: ActionType.REMOVE_SOFTWARE_VERSION_FAILED,
  value: error,
});

export const clearErrorMessage = (): Action => setErrorMessage();

export const clearSuccessMessage = (): Action => setSuccessMessage();

export const showAccessDenied = (): Action => setShowAccessDenied(true);
export const hideAccessDenied = (): Action => setShowAccessDenied(false);

export const showLoadingIndicator = (): Action => setShowLoadingIndicator(true);
export const hideLoadingIndicator = (): Action => setShowLoadingIndicator(false);

export const showRemoveDialog = (): Action => setShowRemoveDialog(true);
export const hideRemoveDialog = (): Action => setShowRemoveDialog(false);

export const addSoftwareVersion = (softwareVersion: string) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();
    const selectedDeviceType = DeviceTypeDetailsSelectors.getDeviceTypeV3(state);
    const softwareVersions = DeviceTypeDetailsSelectors.getSoftwareVersions(state);
    const versionToAdd = softwareVersion.trim();

    if (versionToAdd.length === 0 || softwareVersions.indexOf(versionToAdd) >= 0) {
      dispatch(setErrorMessage("Software version already exists"));
      return Promise.resolve();
    }

    const authToken = getAuthToken(state);
    const namespace = selectedDeviceType.getNamespace();
    const name = selectedDeviceType.getName();
    const version = selectedDeviceType.getVersion();

    dispatch(DeviceTypeDetailsActions.setSoftwareVersions(
      softwareVersions.concat(versionToAdd)));

    dispatch(setAction(ManageSoftwareVersionsAction.ADD_SOFTWARE_VERSION));
    dispatch(showLoadingIndicator());
    dispatch(clearSuccessMessage());
    dispatch(clearErrorMessage());
    dispatch(hideAccessDenied());
    dispatch(addSoftwareVersionRequest());

    return DeviceTypeClient.addSoftwareVersion(authToken, namespace, name, version, versionToAdd)
      .then(() => {

        dispatch(addSoftwareVersionSuccess());
        dispatch(setSuccessMessage("Added software version: " + versionToAdd));

        if (!DeviceTypeDetailsSelectors.isSoftwareVersionSelected(getState())) {
          dispatch(DeviceTypeDetailsActions.setSelectedSoftwareVersion(versionToAdd));
        }

        return dispatch(hideLoadingIndicator());

      }, (response: RestClientError) => {

        const { analytic, error = "Add software version failed" } = response;

        dispatch(addSoftwareVersionFailed(analytic));
        dispatch(setErrorMessage(error));

        // Remove the software version that we added optimistically before the request failed
        dispatch(DeviceTypeDetailsActions.setSoftwareVersions(
          DeviceTypeDetailsSelectors.getSoftwareVersions(getState())
            .filter((deviceTypeSoftwareVersion: string) =>
              versionToAdd !== deviceTypeSoftwareVersion)));

        if (response.status === 403) {
          dispatch(showAccessDenied());
        }

        return dispatch(hideLoadingIndicator());
      });
  };

export const removeSoftwareVersion = (softwareVersion: string) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();
    const selectedDeviceType = DeviceTypeDetailsSelectors.getDeviceTypeV3(state);
    const softwareVersions = DeviceTypeDetailsSelectors.getSoftwareVersions(state);
    const versionToRemove = softwareVersion.trim();

    if (versionToRemove.length === 0 || softwareVersions.indexOf(versionToRemove) === -1) {
      dispatch(setErrorMessage("Software version does not exist"));
      return Promise.resolve();
    }

    const authToken = getAuthToken(state);
    const namespace = selectedDeviceType.getNamespace();
    const name = selectedDeviceType.getName();
    const version = selectedDeviceType.getVersion();

    dispatch(DeviceTypeDetailsActions.setSoftwareVersions(
      softwareVersions.filter((deviceTypeSoftwareVersion: string) =>
        versionToRemove !== deviceTypeSoftwareVersion)));

    dispatch(setAction(ManageSoftwareVersionsAction.REMOVE_SOFTWARE_VERSION));
    dispatch(showLoadingIndicator());
    dispatch(clearSuccessMessage());
    dispatch(clearErrorMessage());
    dispatch(hideAccessDenied());
    dispatch(removeSoftwareVersionRequest());

    return DeviceTypeClient.removeSoftwareVersion(authToken, namespace, name, version, versionToRemove)
      .then(() => {

        dispatch(removeSoftwareVersionSuccess());
        dispatch(setSuccessMessage("Removed software version: " + versionToRemove));

        if (!DeviceTypeDetailsSelectors.isSoftwareVersionSelected(getState())) {
          dispatch(DeviceTypeDetailsActions.setSelectedSoftwareVersion(
            DeviceTypeDetailsSelectors.getSoftwareVersions(getState())[0]));
        }

        return dispatch(hideLoadingIndicator());

      }, (response: RestClientError) => {

        const { analytic, error = "Remove software version failed" } = response;

        dispatch(removeSoftwareVersionFailed(analytic));
        dispatch(setErrorMessage(error));

        // Add the software version back that we removed optimistically before the request failed
        dispatch(DeviceTypeDetailsActions.setSoftwareVersions(
          DeviceTypeDetailsSelectors.getSoftwareVersions(getState())
            .concat(versionToRemove)));

        if (response.status === 403) {
          dispatch(showAccessDenied());
        }

        return dispatch(hideLoadingIndicator());
      });
  };

export const reset = () => (dispatch: any) => {
  dispatch(setAction());
  dispatch(setSoftwareVersion());
  dispatch(setErrorMessage());
  dispatch(setSuccessMessage());
  dispatch(setShowAccessDenied());
  dispatch(setShowLoadingIndicator());
  return dispatch(setShowRemoveDialog());
};
