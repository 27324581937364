import { StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const searchInput: StyleRules = {
  root: {
    color: Color.TEXT,
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "16px",
    width: 300,
    "& .MuiInput-underline": {
      "&:before": {
        borderBottom: "1px solid #e8e8e8 !important",
      },
      "&:hover:before": {
        borderBottom: "1px solid #bec8d2 !important",
      },
      "&:focused:before": {
        borderBottom: "2px solid #98a2ae !important",
      },
    },
  },
};
