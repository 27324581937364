import { combineReducers } from "redux";
import { MODULE_ID } from "../constants";
import {
  DEFAULT_SCHEMA,
  JsonSchemaDefinition,
  JsonSchemaMetadata,
  JsonSchemaMetadataAttributes,
} from "@data";
import { SchemaWizardState, SchemaWizardStateAttributes } from "../models";
import {
  DEFAULT_STATE as PROPERTIES_TABLE_DEFAULT_STATE,
  PropertiesTableReducer,
  PropertiesTableSchema,
} from "./propertiesTable";
import {
  DEFAULT_STATE as PROPERTY_EDITOR_DEFAULT_STATE,
  PropertyEditorReducer,
  PropertyEditorSchema,
} from "./propertyEditor";
import {
  DEFAULT_STATE as REMOVE_PROPERTY_DEFAULT_STATE,
  RemovePropertyReducer,
  RemovePropertySchema,
} from "./removeProperty";
import {
  DEFAULT_STATE as EDIT_MODE_DEFAULT_STATE,
  EditModeReducer,
  EditModeSchema,
} from "./editMode";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base/createReducers";

export enum SchemaWizardStep {
  NONE = "",
  NAMESPACE = "namespace",
  NAME  = "name",
  SCHEMA = "schema",
  REVIEW = "review",
}

export enum SchemaEditorViewMode {
  SUMMARY = "Visual Editor",
  JSON = "{} JSON Editor",
}

export interface SchemaWizardSchema extends BasePortalModuleSchema {
  createdSchema: JsonSchemaMetadataAttributes;
  namespace: string;
  json: string;
  schema: JsonSchemaDefinition;
  schemaWizardStep: SchemaWizardStep;
  schemaEditorViewMode: SchemaEditorViewMode;
  defaultState: SchemaWizardStateAttributes;
  propertiesTable: PropertiesTableSchema;
  showPropertyEditor: boolean;
  propertyEditor: PropertyEditorSchema;
  showRemoveProperty: boolean;
  removeProperty: RemovePropertySchema;
  showEditMode: boolean;
  editMode: EditModeSchema;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<SchemaWizardSchema>({
  showEmptyView: false,
  createdSchema: JsonSchemaMetadata.EMPTY.toJS(),
  namespace: "",
  json: JSON.stringify(DEFAULT_SCHEMA, null, "  "),
  schema: Object.freeze({ ...DEFAULT_SCHEMA }),
  schemaWizardStep: SchemaWizardStep.NAMESPACE,
  schemaEditorViewMode: SchemaEditorViewMode.SUMMARY,
  defaultState: { ...SchemaWizardState.EMPTY.toJS() },
  propertiesTable: Object.freeze({ ...PROPERTIES_TABLE_DEFAULT_STATE }),
  showPropertyEditor: false,
  propertyEditor: Object.freeze({ ...PROPERTY_EDITOR_DEFAULT_STATE }),
  showRemoveProperty: false,
  removeProperty: Object.freeze({ ...REMOVE_PROPERTY_DEFAULT_STATE }),
  showEditMode: false,
  editMode: Object.freeze({ ...EDIT_MODE_DEFAULT_STATE }),
});

type SchemaWizardActionTypes = PortalModuleActionTypes<SchemaWizardSchema> & {
  SAVE_SCHEMA: string;
  SAVE_SCHEMA_SUCCESS: string;
  SAVE_SCHEMA_FAILED: string;
};

export const ACTION_TYPES: SchemaWizardActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<SchemaWizardSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    SAVE_SCHEMA: `${MODULE_ID}/save_schema`,
    SAVE_SCHEMA_SUCCESS: `${MODULE_ID}/save_schema_success`,
    SAVE_SCHEMA_FAILED: `${MODULE_ID}/save_schema_failed`,
  },
});

export const SchemaWizardReducer = combineReducers<SchemaWizardSchema>({
  ...createPortalModuleReducers<SchemaWizardSchema>(ACTION_TYPES, DEFAULT_STATE),
  propertiesTable: PropertiesTableReducer,
  propertyEditor: PropertyEditorReducer,
  removeProperty: RemovePropertyReducer,
  editMode: EditModeReducer,
});

export default SchemaWizardReducer;
