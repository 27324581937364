import React from "react";
import ProfileAttributeDefinitionListColumn from "./ProfileAttributeDefinitionListColumn";
import {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import { ProfileAttributeDefinition } from "@data";
import { ProfileAttributeIcon } from "./styles";
import { noop } from "@util";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import { ModuleListView } from "@components/module-list-view";

export const DEFAULT_PROFILE_ATTRIBUTE_DEFINITION_LIST_COLUMNS: ProfileAttributeDefinitionListColumn[] = [
  ProfileAttributeDefinitionListColumn.NAME,
  ProfileAttributeDefinitionListColumn.DESCRIPTION,
  ProfileAttributeDefinitionListColumn.DATA_TYPE,
  ProfileAttributeDefinitionListColumn.MAX_LENGTH,
  ProfileAttributeDefinitionListColumn.OPTIONAL,
  ProfileAttributeDefinitionListColumn.HIDDEN,
  ProfileAttributeDefinitionListColumn.IMMUTABLE,
];

export type ProfileAttributeDefinitionListModel =
  SortedSearchResultsListModel<ProfileAttributeDefinition, ProfileAttributeDefinitionListColumn>;

export interface ProfileAttributeDefinitionListActions
  extends SortedSearchResultsListActions<ProfileAttributeDefinition, ProfileAttributeDefinitionListColumn> {
}

type Props = ProfileAttributeDefinitionListModel & ProfileAttributeDefinitionListActions;

export const ProfileAttributeDefinitionList = (props: Props) => {

  const {
    className = "profileAttributeDefinition",
    listClassName = "profileAttributeDefinitionList",
    columns = DEFAULT_PROFILE_ATTRIBUTE_DEFINITION_LIST_COLUMNS,
    sortByColumn = ProfileAttributeDefinitionListColumn.NAME,
    sortOrderAscending = true,
    noResultsLabel = "No Profile Attribute Definitions found",
    summaryViewQuantities = {
      other: "profile attribute definitions",
      one: "profile attribute definition",
    },
    items = [],
    nameFilter = "",
    onClickAction = noop,
    ...otherProps
  } = props;

  const moduleListItems: ListViewItem<ProfileAttributeDefinition>[] = React.useMemo(() =>
    items.map(attribute => {
      return {
        item: attribute,
        pathToDetailsView: "",
        icon: ProfileAttributeIcon,
        columnAttributes: createColumns([
          {
            className: "attributeName",
            value: attribute.getName(),
            column: ProfileAttributeDefinitionListColumn.NAME,
            firstColumn: true,
          },
          {
            className: "attributeDescription",
            value: attribute.getDescription(),
            column: ProfileAttributeDefinitionListColumn.DESCRIPTION,
          },
          {
            className: "attributeDataType",
            value: attribute.getDataType(),
            column: ProfileAttributeDefinitionListColumn.DATA_TYPE,
          },
          {
            className: "attributeMaxLength",
            value: attribute.getMaxLength(),
            column: ProfileAttributeDefinitionListColumn.MAX_LENGTH,
          },
          {
            className: "attributeOptional",
            checkmark: attribute.isOptional(),
            column: ProfileAttributeDefinitionListColumn.OPTIONAL,
          },
          {
            className: "attributeHidden",
            checkmark: attribute.isHidden(),
            column: ProfileAttributeDefinitionListColumn.HIDDEN,
          },
          {
            className: "attributeImmutable",
            checkmark: attribute.isImmutable(),
            column: ProfileAttributeDefinitionListColumn.IMMUTABLE,
          },
        ])
      };
    }), [items]);

  return (
    <ModuleListView
      {...otherProps}
      className={className}
      listClassName={listClassName}
      listViewItems={moduleListItems}
      columns={columns}
      nameFilter={nameFilter}
      nameFilterDelay={25}
      sortByColumn={sortByColumn}
      sortOrderAscending={sortOrderAscending}
      noResultsLabel={noResultsLabel}
      summaryViewIcon={ProfileAttributeIcon}
      summaryViewQuantities={summaryViewQuantities}
    />
  );
};

export default ProfileAttributeDefinitionList;
