import { createStyles } from "@material-ui/core/styles";

export const styles = () =>
  createStyles({
    container: {},
    adminNoteTextField: {},
    errorView: {
      marginTop: 0,
      marginBottom: 10,
      textAlign: "center",
    },
    accessDenied: {},
  });

export default styles;
