import { ActionType } from "@util";

export interface SupersetLoadedContent {
    time: number;
    url: string;
}

export const refreshSuperset = (url: string) =>
    (dispatch: any) => dispatch({
        type: ActionType.SUPERSET_APP_RELOAD,
        value: {
            time: Date.now(),
            url,
        }
    });
