import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { isEmptyString } from "@util/Functions";
import { TextButton } from "@components/text-button";
import { binaryDataListItem as styles } from "./styles";
import EditBinaryDataListItem from "./EditBinaryDataListItem";
import DeleteBinaryDataListItem from "./DeleteBinaryDataListItem";
import DownloadBinaryDataListItem from "./DownloadBinaryDataListItem";

export const BinaryDataListItem = withStyles(styles)((props: WithStyles<typeof styles> & {
  key?: string,
  userId?: string,
  name?: string,
  selfAuthorized?: boolean,
}) => {

  const {
    classes,
    key = "BinaryDataListItem",
    userId = "",
    name = "",
    selfAuthorized,
  } = props;

  const [showDownloadMode, setShowDownloadMode] = React.useState(false);
  const [showEditMode, setShowEditMode] = React.useState(false);
  const [showDeleteMode, setShowDeleteMode] = React.useState(false);

  const reset = React.useCallback(() => {
    setShowDownloadMode(false);
    setShowEditMode(false);
    setShowDeleteMode(false);
  }, [
    setShowDownloadMode,
    setShowEditMode,
    setShowDeleteMode,
  ]);

  const showControls = React.useMemo(() =>
    !showDownloadMode && !showEditMode && !showDeleteMode,
    [showDownloadMode, showEditMode, showDeleteMode]);

  const view = React.useMemo(() => {

    if (showDownloadMode) {
      return (
        <DownloadBinaryDataListItem
          userId={userId}
          name={name}
          selfAuthorized={selfAuthorized}
          onSuccess={reset}
          onError={reset}
        />
      );
    }

    if (showEditMode) {
      return (
        <EditBinaryDataListItem
          userId={userId}
          name={name}
          cancel={() => setShowEditMode(false)}
          onSuccess={reset}
        />
      );
    }

    if (showDeleteMode) {
      return (
        <DeleteBinaryDataListItem
          userId={userId}
          name={name}
          selfAuthorized={selfAuthorized}
          cancel={() => setShowDeleteMode(false)}
          onSuccess={reset}
        />
      );
    }

    return null;

  }, [
    showDownloadMode,
    showEditMode,
    showDeleteMode,
    userId,
    name,
    selfAuthorized,
    reset,
    setShowEditMode,
  ]);

  if (isEmptyString(name)) {
    return null;
  }

  return (
    <div key={key} className={classnames("binaryDataListItem", classes.container)}>
      {showControls && (
        <React.Fragment>
          <TextButton
            className={classnames("downloadButton", classes.button)}
            onClick={() => setShowDownloadMode(true)}
          >
            Download
          </TextButton>
          <TextButton
            className={classnames("editButton", classes.button)}
            onClick={() => setShowEditMode(true)}
          >
            Upload
          </TextButton>
          <TextButton
            className={classnames("deleteButton", classes.button)}
            onClick={() => setShowDeleteMode(true)}
          >
            Delete
          </TextButton>
        </React.Fragment>
      )}
      {view}
    </div>
  );
});

export default BinaryDataListItem;
