import React from "react";
import classnames from "classnames";
import TextField from "@components/text-field";
import { equalsIgnoreCase, formEventHandler, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { dataSetNameFilter as styles } from "./styles";

export interface DataSetNameFilterModel {
  className?: string;
  disabled?: boolean;
  nameFilter?: string;
  nameFilterDelay?: number;
}

export interface DataSetNameFilterActions {
  setNameFilter?: (nameFilter: string) => void;
}

type Model = DataSetNameFilterModel;
type Actions = DataSetNameFilterActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataSetNameFilter = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    disabled,
    nameFilter = "",
    nameFilterDelay =  500,
    setNameFilter = noop,
  } = props;

  const [pendingNameFilter, setPendingNameFilter] = React.useState(nameFilter);

  // Whenever the search query changes, clear the previous timer, and create a new timer
  // to wait 500ms (default value), check if the search query does not match the case
  // insensitive nameFilter that was previously used to trigger a search. If it is a new query,
  // update the name filter to trigger a new search.
  React.useEffect(() => {

    if (!equalsIgnoreCase(pendingNameFilter, nameFilter)) {
      const timer = setTimeout(() => {
        setNameFilter(pendingNameFilter);
      }, nameFilterDelay);

      return () => clearTimeout(timer);
    }

    return () => noop();

  }, [nameFilterDelay, nameFilter, pendingNameFilter, setNameFilter]);

  return (
    <TextField
      className={classnames("dataSetNameFilter", className, classes.container)}
      size="small"
      type="search"
      name="fileName"
      color="secondary"
      value={pendingNameFilter}
      disabled={disabled}
      placeholder="Filter data sets by name"
      autoComplete="off"
      InputLabelProps={{ shrink: true }}
      InputProps={{ disableUnderline: true }}
      onChange={formEventHandler(setPendingNameFilter)}
    />
  );
});

export default DataSetNameFilter;
