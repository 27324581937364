import { isEmptyString } from "@util";
import { createQueryParams, makeJsonApiRequest, withRequiredArguments } from "./helpers";

const PORTAL_API = process.env.REACT_APP_PORTAL_API || "";

if (isEmptyString(PORTAL_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_PORTAL_API");
}

export interface FederatedLoginSessionInfo {
  principalId: string;
  accountId: string;
  userId: string;
  accessToken: string;
  refreshToken: string;
  idToken: string;
  expiresIn: number;
}

export const getFederatedLoginSessionInfo = (code: string): Promise<FederatedLoginSessionInfo> => {

  const validate = () => withRequiredArguments([
    ["OAuth Code", code],
  ]);

  const makeRequest = () => {

    const queryParams = createQueryParams({ code });

    const url = `${PORTAL_API}/tokens/${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to exchange oauth code [${code}] for IoT tokens.`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
