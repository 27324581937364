import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { noop } from "@util/Functions";
import { UserProfileAttribute, UserProfileAttributesMap } from "@data/UserProfileAttribute";
import EditProfileView, { Model } from "../components/ProfileView/EditProfileView";
import SaveProfileAttributesDialog from "./SaveProfileAttributesDialog";

interface ContainerModel {
  items?: UserProfileAttribute[];
}

interface ContainerActions {
  cancel?: () => void;
  onSuccess?: () => void;
}

type Props = ContainerModel & ContainerActions;

const EditProfileViewContainer = (props: Props) => {

  const {
    items: originalItems = [],
    cancel: closeEditProfileView = noop,
    onSuccess = noop,
  } = props;

  const originalAttributes = React.useMemo(() =>
    originalItems
      .filter(profileAttribute => !profileAttribute.isBinaryDataType())
      .reduce((combined: UserProfileAttributesMap, attribute: UserProfileAttribute) => {
        combined[attribute.getName()] = attribute;
        return combined;
      }, {}), [originalItems]);

  const [ attributes, setAttributes ] = React.useState<UserProfileAttributesMap>(originalAttributes);

  const items = React.useMemo(() =>
    Object.keys(attributes).map(key => attributes[key])
    , [attributes]);

  const updateItem = React.useCallback((item: UserProfileAttribute) =>
    setAttributes({
      ...attributes,
      [item.getName()]: item,
    }), [setAttributes, attributes]);

  const [ showSaveDialog, setShowSaveDialog ] = React.useState(false);

  const saveButtonDisabled = React.useMemo(() =>
    // Do not enable save button if the user has not changed any attributes
    JSON.stringify(originalAttributes) === JSON.stringify(attributes),
    [originalAttributes, attributes]);

  return (
    <EditProfileView
      title={`Profile Attributes Editor`}
      items={items}
      showSearch={false}
      sortingDisabled={true}
      saveButtonDisabled={saveButtonDisabled}
      showModal={!showSaveDialog}
      updateItem={updateItem}
      save={() => setShowSaveDialog(true)}
      cancel={closeEditProfileView}
    >
      {showSaveDialog && (
        <SaveProfileAttributesDialog
          attributes={attributes}
          cancel={() => setShowSaveDialog(false)}
          onSuccessMessageShown={onSuccess}
        />
      )}
    </EditProfileView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps,
});

export default connect<Model, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(EditProfileViewContainer);
