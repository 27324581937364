import React from "react";
import { Color } from "../styles";

type StyleFactory = (args?: any) => React.CSSProperties;

interface Styles {
  link: StyleFactory;
}

export const styles: Styles = {
  link: () => ({
    color: Color.LIGHT_BLUE,
  }),
};
