import React from "react";
import classnames from "classnames";
import Button from "@components/button";
import { auditEventFilters as styles } from "./styles";
import { Autocomplete, DropdownMenu } from "@components";
import { clickHandler, isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { AuditEventFilter } from "@components/audit-events/AuditEventFilter";

export const DEFAULT_LIMITS: number[] = [10, 25, 50, 75, 100, 200, 500];

export const DEFAULT_LIMIT = 100;

export const DEFAULT_SEVERITIES: string[] = [
  "INFO",
  "ERROR",
];

export interface AuditEventAutocompleteFiltersModel {
  className?: string;
  showLoadingIndicator?: boolean;
  lockedFilters?: AuditEventFilter[];
  hideClearButton?: boolean;
  clearButtonLabel?: string;

  availableLimits?: number[];
  availableTypes?: string[];
  availableSeverities?: string[];
  availableAccountIds?: string[];
  availablePrincipals?: string[];
  availableOrigins?: string[];
  availableRequestIds?: string[];

  limit?: number;
  selectedTypes?: string[];
  selectedSeverities?: string[];
  selectedAccountIds?: string[];
  selectedPrincipals?: string[];
  selectedOrigins?: string[];
  selectedRequestIds?: string[];

  maxNumEventTypes?: number;
  maxNumEventSeverities?: number;
  maxNumEventAccountIds?: number;
  maxNumEventPrincipals?: number;
  maxNumEventOrigins?: number;
  maxNumEventRequestIds?: number;
}

export interface AuditEventAutocompleteFiltersActions {
  setLimit?: (limit: number) => void;
  setSelectedTypes?: (types: string[]) => void;
  setSelectedSeverities?: (severities: string[]) => void;
  setSelectedPrincipals?: (principals: string[]) => void;
  setSelectedAccountIds?: (accountIds: string[]) => void;
  setSelectedOrigins?: (origins: string[]) => void;
  setSelectedRequestIds?: (requestIds: string[]) => void;
  clearFilters?: () => void;
}

type Model = AuditEventAutocompleteFiltersModel;
type Actions = AuditEventAutocompleteFiltersActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const AuditEventFilters = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    showLoadingIndicator,
    lockedFilters = [],
    hideClearButton,
    clearButtonLabel = lockedFilters.length === 0 ? "Clear Filters" : "Reset Filters",

    availableLimits = DEFAULT_LIMITS,
    availableTypes = [],
    availableSeverities = [],
    availableAccountIds = [],
    availablePrincipals = [],
    availableOrigins = [],
    availableRequestIds = [],

    limit = DEFAULT_LIMIT,
    selectedTypes = [],
    selectedSeverities = [],
    selectedAccountIds = [],
    selectedPrincipals = [],
    selectedOrigins = [],
    selectedRequestIds = [],

    maxNumEventTypes = 1,
    maxNumEventSeverities = 1,
    maxNumEventAccountIds = 1,
    maxNumEventPrincipals = 1,
    maxNumEventOrigins = 1,
    maxNumEventRequestIds = 1,

    setLimit = noop,
    setSelectedTypes = noop,
    setSelectedSeverities = noop,
    setSelectedPrincipals = noop,
    setSelectedAccountIds = noop,
    setSelectedOrigins = noop,
    setSelectedRequestIds = noop,

    clearFilters = noop,

  } = props;

  const types = React.useMemo(() =>
      Array.from(new Set(([] as string[])
        .concat(Array.isArray(availableTypes) ? availableTypes.slice() : [])
        .concat(Array.isArray(selectedTypes) ? selectedTypes.slice() : [])))
        .filter(value => !isEmptyString(value)),
    [availableTypes, selectedTypes]);

  const severities = React.useMemo(() =>
      Array.from(new Set(DEFAULT_SEVERITIES.slice()
        .concat(Array.isArray(availableSeverities) ? availableSeverities.slice() : [])
        .concat(Array.isArray(selectedSeverities) ? selectedSeverities.slice() : [])))
        .filter(value => !isEmptyString(value)),
    [availableSeverities, selectedSeverities]);

  const accountIds: string[] = React.useMemo(() =>
      Array.from(new Set(([] as string[])
        .concat(Array.isArray(availableAccountIds) ? availableAccountIds.slice() : [])
        .concat(Array.isArray(selectedAccountIds) ? selectedAccountIds.slice() : [])))
        .filter(value => !isEmptyString(value)),
    [availableAccountIds, selectedAccountIds]);

  const principals: string[] = React.useMemo(() =>
      Array.from(new Set(([] as string[])
        .concat(Array.isArray(availablePrincipals) ? availablePrincipals.slice() : [])
        .concat(Array.isArray(selectedPrincipals) ? selectedPrincipals.slice() : [])))
        .filter(value => !isEmptyString(value)),
    [availablePrincipals, selectedPrincipals]);

  const origins: string[] = React.useMemo(() =>
      Array.from(new Set(([] as string[])
        .concat(Array.isArray(availableOrigins) ? availableOrigins.slice() : [])
        .concat(Array.isArray(selectedOrigins) ? selectedOrigins.slice() : [])))
        .filter(value => !isEmptyString(value)),
    [availableOrigins, selectedOrigins]);

  const requestIds: string[] = React.useMemo(() =>
      Array.from(new Set(([] as string[])
        .concat(Array.isArray(availableRequestIds) ? availableRequestIds.slice() : [])
        .concat(Array.isArray(selectedRequestIds) ? selectedRequestIds.slice() : [])))
        .filter(value => !isEmptyString(value)),
    [availableRequestIds, selectedRequestIds]);

  return (
    <div className={classnames("auditEventFilters", className, classes.container)}>
      <Autocomplete
        className={classnames("filterByAccountId", "autocomplete", classes.autocomplete)}
        label={"Event account id" + (maxNumEventSeverities <= 1 ? "" : `(max ${maxNumEventAccountIds})`)}
        placeholder="Filter by event account id"
        options={accountIds}
        value={selectedAccountIds}
        loading={showLoadingIndicator}
        disabled={lockedFilters.indexOf(AuditEventFilter.ACCOUNT_ID) >= 0}
        setValue={setSelectedAccountIds}
        maxNumSelectedValues={maxNumEventAccountIds}
      />
      <Autocomplete
        className={classnames("filterBySeverity", "autocomplete", classes.autocomplete)}
        label={"Event severity" + (maxNumEventSeverities <= 1 ? "" : `(max ${maxNumEventSeverities})`)}
        placeholder="Filter by event severity"
        options={severities}
        value={selectedSeverities}
        loading={showLoadingIndicator}
        disabled={lockedFilters.indexOf(AuditEventFilter.SEVERITY) >= 0}
        setValue={setSelectedSeverities}
        maxNumSelectedValues={maxNumEventSeverities}
      />
      <Autocomplete
        className={classnames("filterByType", "autocomplete", classes.autocomplete)}
        label={"Event type" + (maxNumEventTypes <= 1 ? "" : `(max ${maxNumEventTypes})`)}
        placeholder="Filter by event type"
        options={types}
        value={selectedTypes}
        loading={showLoadingIndicator}
        disabled={lockedFilters.indexOf(AuditEventFilter.TYPE) >= 0}
        setValue={setSelectedTypes}
        maxNumSelectedValues={maxNumEventTypes}
      />
      <Autocomplete
        className={classnames("filterByPrincipal", "autocomplete", classes.autocomplete)}
        label={"Event principal" + (maxNumEventPrincipals <= 1 ? "" : `(max ${maxNumEventPrincipals})`)}
        placeholder="Filter by event principal"
        options={principals}
        value={selectedPrincipals}
        loading={showLoadingIndicator}
        disabled={lockedFilters.indexOf(AuditEventFilter.PRINCIPAL) >= 0}
        setValue={setSelectedPrincipals}
        maxNumSelectedValues={maxNumEventPrincipals}
      />
      <Autocomplete
        className={classnames("filterByOrigin", "autocomplete", classes.autocomplete)}
        label={"Event origin" + (maxNumEventSeverities <= 1 ? "" : `(max ${maxNumEventAccountIds})`)}
        placeholder="Filter by event origin"
        options={origins}
        value={selectedOrigins}
        loading={showLoadingIndicator}
        disabled={lockedFilters.indexOf(AuditEventFilter.ORIGIN) >= 0}
        setValue={setSelectedOrigins}
        maxNumSelectedValues={maxNumEventOrigins}
      />
      <Autocomplete
        className={classnames("filterByRequestId", "autocomplete", classes.autocomplete)}
        label={"Event request id" + (maxNumEventSeverities <= 1 ? "" : `(max ${maxNumEventAccountIds})`)}
        placeholder="Filter by event request id"
        options={requestIds}
        value={selectedRequestIds}
        loading={showLoadingIndicator}
        disabled={lockedFilters.indexOf(AuditEventFilter.REQUEST_ID) >= 0}
        setValue={setSelectedRequestIds}
        maxNumSelectedValues={maxNumEventRequestIds}
      />
      <div className={classnames("footer", classes.footer)}>
        <DropdownMenu
          className={classnames("limit", classes.limit)}
          values={availableLimits.map(availableLimit => `${availableLimit}`)}
          disabled={showLoadingIndicator}
          selectedValue={`${limit}`}
          setSelectedValue={(selectedValue: string) => setLimit(Number(selectedValue))}
          dropdownMenuLabel={"Limit"}
        />
        {!hideClearButton && (
          <Button
            className={classnames("clearFiltersButton", classes.clearFiltersButton)}
            classes={{
              label: classnames("label", classes.clearFiltersButtonLabel),
            }}
            disabled={showLoadingIndicator}
            onClick={clickHandler(clearFilters)}
          >
            {clearButtonLabel}
          </Button>
        )}
      </div>
    </div>
  );
});

export default AuditEventFilters;
