import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { getTrainingCertificateId } from "../selectors";
import TrainingCertificateView, {
  TrainingCertificateViewActions as Actions,
  TrainingCertificateViewModel as Model,
} from "../components/TrainingCertificateView";

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const TrainingCertificateViewContainer = (props: Props) => {

  const {
    ...otherProps
  } = props;

  return (
    <TrainingCertificateView
      {...otherProps}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  trainingCertificateId: getTrainingCertificateId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(TrainingCertificateViewContainer);
