import { Module } from "@data";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./styles";
import view from "./containers/DigitalShadowTypes";
import { isDigitalShadowEnabled } from "@util";

export const digitalShadowTypesModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Shadow Types",
  title: "Shadow Type Management",
  className: "digitalShadowTypesModule",
  primaryColor,
  icon,
  view,
  backButton: null,
  disabled: !isDigitalShadowEnabled(),
});

export default digitalShadowTypesModule;
