import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { isEmptyString } from "@util";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { useDeleteSecurityGroup } from "@hooks";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface DeleteSecurityGroupDialogModel extends PortalModuleDialogModel<string> {
  securityGroupName?: string;
}

export interface DeleteSecurityGroupDialogActions extends PortalModuleDialogActions<string> {
}

type Props = WithStyles< typeof styles> & DeleteSecurityGroupDialogModel & DeleteSecurityGroupDialogActions;

export const DeleteSecurityGroupDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    securityGroupName = "",
    ...otherProps
  } = props;

  const [{showLoadingIndicator, ...otherModel}, {deleteSecurityGroup, ...otherActions}] =
    useDeleteSecurityGroup({securityGroupName});

  const confirm = React.useCallback(() => {
    deleteSecurityGroup();
  }, [deleteSecurityGroup]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      {...otherActions}
      className={classnames("deleteSecurityGroupDialog")}
      title={"Delete Security Group"}
      loading={showLoadingIndicator}
      continueButtonLabel={"Delete Security Group"}
      continueButtonDisabled={isEmptyString(securityGroupName)}
      confirm={confirm}
    >
      <div className={classnames("deleteSecurityGroup", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Are you sure you want to delete this security group?
          </label>
            <label className={classnames("subtitle", classes.subtitle)}>
            <span className={classes.warning}>WARNING:</span>This action cannot be undone!
          </label>
          <label className={classnames("securityGroupName", classes.securityGroupName)}>
            {securityGroupName}
          </label>
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default DeleteSecurityGroupDialog;
