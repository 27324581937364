import React from "react";
import { useStore } from "react-redux";
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { getSelectedTab } from "./selectors";
import { DashboardsTabType } from "./components/DashboardsTabs";
import { MODULE_PATH, PUBLISHED_DASHBOARDS_PATH, UNPUBLISHED_DASHBOARDS_PATH } from "./constants";
import { useDashboardsQueryParams } from "@hooks";
import Dashboards from "./containers/Dashboards";
import { path as pathToDashboard } from "@modules/dashboardDetails/constants";
import { Dashboard } from "@data";

export function DashboardsView() {

  const match = useRouteMatch({ path: MODULE_PATH });

  const store = useStore();
  const history = useHistory();

  const showDetails = React.useCallback((dashboard: Dashboard) =>
    history.push(pathToDashboard(dashboard.id)), [ history ]);

  const lastSelectedTab = React.useMemo(() => getSelectedTab(store.getState()), [ store ]);

  const { selectedTab = DashboardsTabType.PUBLISHED } = useDashboardsQueryParams();

  // If a dashboard status query param was not detected - or did not match a known value -
  // redirect to the last known selected tab (published tab is default state) - as long as
  // the module is actually active; this last check is necessary to prevent a redirect from
  // a details view route that would match this module's prefix
  if (selectedTab === null && match && match.isExact) {
    switch (lastSelectedTab) {
      case DashboardsTabType.UNPUBLISHED:
        return <Redirect to={UNPUBLISHED_DASHBOARDS_PATH}/>;
      case DashboardsTabType.PUBLISHED:
      default:
        return <Redirect to={PUBLISHED_DASHBOARDS_PATH}/>;
    }
  }

  // Do not render if a selected tab was not found (ie: the module path is not an exact match)
  if (selectedTab === null) {
    return null;
  }

  return (
    <Dashboards
      selectedTab={selectedTab}
      showDashboardDetails={showDetails}
    />
  );
}

export default DashboardsView;
