import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { Layout, Model, Actions } from "../components/Layout";
import Navigation from "./Navigation";
import LayoutContent from "./LayoutContent";
import { MaintenanceReminder } from "@data";
import { useLocation } from "react-router-dom";
import { MaintenanceBanner } from "@components";
import { getStringValue, equalsIgnoreCase } from "@util";
import { updateClosedMaintenanceReminders } from "../actions";
import {
  getOpenDialog,
  isMaintenanceReminderVisible,
  isInformationBannerVisible,
  getMaintenanceReminder,
  getMaintenanceReminderLabel,
  getClosedMaintenanceRemindersData
} from "../selectors";

interface ContainerModel extends Model {
  dialog?: React.ReactNode;
  maintenanceReminder?: MaintenanceReminder;
  showMaintenanceReminder?: boolean;
  maintenanceReminderLabel?: string;
  closedMaintenanceReminders?: string[];
}

interface ContainerActions extends Actions {
  updateClosedMaintenanceReminders?: (value: string[]) => void;
}

const LayoutContainer = (props: ContainerModel) => {

  const {
    dialog,
    showMaintenanceReminder = false,
    ...otherProps
  } = props;
  const { search } = useLocation();

  const queryParams = new URLSearchParams(getStringValue(search));
  const action = React.useMemo(() => queryParams.get("action") || "", [queryParams]);
  const showNavigation = !equalsIgnoreCase(action, "openDashboard");

  return (
    <Layout {...otherProps}>
      {showMaintenanceReminder && <MaintenanceBanner {...otherProps} />}
      {showNavigation && <Navigation />}
      <LayoutContent />
      {dialog}
    </Layout>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => {

  const { view: dialog = null } = getOpenDialog(state) || {};

  return {
    dialog,
    maintenanceReminder: getMaintenanceReminder(state),
    maintenanceReminderLabel: getMaintenanceReminderLabel(state),
    showMaintenanceReminder: isMaintenanceReminderVisible(state),
    closedMaintenanceReminders: getClosedMaintenanceRemindersData(state),
    informationBannerVisible: isInformationBannerVisible(state),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  updateClosedMaintenanceReminders: (value: string[]) => dispatch(updateClosedMaintenanceReminders(value)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps
)(LayoutContainer);
