import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { getPluralString, Quantities } from "@util/PluralString";
import { isEmptyString, isString } from "@util/Functions";
import {
  UserProfileAttributesListActions,
  UserProfileAttributesListModel,
} from "@components/user-profile-attributes-list";
import styles from "./styles";

export interface UserProfileAttributesTitleModel extends UserProfileAttributesListModel {
  className?: string;
  title?: Quantities | string;
  filteredViewTitle?: Quantities | string;
  errorTitle?: string;
  loadingTitle?: string;
  totalNumItems?: number;
  totalNumVisibleItems?: number;
}

export interface UserProfileAttributesTitleActions extends UserProfileAttributesListActions {
}

export type UserProfileAttributesTitleProps = UserProfileAttributesTitleModel & UserProfileAttributesTitleActions;

type Props = WithStyles<typeof styles> & UserProfileAttributesTitleProps;

export const UserProfileAttributesTitle = withStyles(styles)((props: Props) => {

  const {
    classes,
    title: defaultTitle = {
      other: "profile attributes",
      one: "profile attribute",
    },
    filteredViewTitle = {
      other: "profile attributes found",
      one: "profile attribute found",
    },
    errorTitle = "Failed to load profile attributes",
    loadingTitle = "Loading profile attributes...",
    totalNumItems = 0,
    totalNumVisibleItems = 0,
    nameFilter,
    errorMessage,
    showLoadingIndicator
  } = props;

  const showErrorView = React.useMemo(() =>
    !isEmptyString(errorMessage), [errorMessage]);

  const showFilteredView = React.useMemo(() =>
    !isEmptyString(nameFilter), [nameFilter]);

  const title = React.useMemo(() => {
    if (showErrorView) {
      return errorTitle;
    } else if (showLoadingIndicator) {
      return loadingTitle;
    } else if (showFilteredView) {
      if (isString(filteredViewTitle)) {
        return filteredViewTitle as string;
      } else {
        return getPluralString(totalNumVisibleItems, filteredViewTitle as Quantities);
      }
    } else {
      if (isString(defaultTitle)) {
        return defaultTitle as string;
      } else {
        return getPluralString(totalNumItems, defaultTitle as Quantities);
      }
    }
  }, [
    showErrorView,
    showLoadingIndicator,
    showFilteredView,
    errorTitle,
    loadingTitle,
    filteredViewTitle,
    defaultTitle,
    totalNumVisibleItems,
    totalNumItems,
  ]);

  return (
    <h1 className={classnames("title", classes.title, {[classes.error]: showErrorView})}>
      {title}
    </h1>
  );
});

export default UserProfileAttributesTitle;
