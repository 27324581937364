import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import {
  close,
  save,
  trackCreateAdminNoteFailed,
  trackCreateAdminNoteRequest,
  trackCreateAdminNoteSuccess,
} from "../actions";
import { useRouteMatch } from "react-router-dom";
import { noop } from "@util";
import {
  getClassName,
  getContinueButtonLabel,
  getDialogTitle,
  getErrorMessage,
  getSuccessMessage,
  getUserId,
  isAccessDeniedVisible,
  isDialogOpen,
  isLoadingIndicatorVisible,
  isLockUserAction,
  isUserIdValid,
} from "../selectors";
import { refresh as refreshUserDetailsPage } from "@modules/userDetails/actions";
import { MODULE_PATH as DETAILS_PAGE_PATH } from "@modules/userDetails/constants";
import CreateAdminNoteDialog, {
  CreateAdminNoteDialogActions,
  CreateAdminNoteDialogModel,
} from "@components/create-admin-note-dialog";
import LockUser from "../components/LockUser";

interface Model extends CreateAdminNoteDialogModel<string> {
  lockUserAction?: boolean;
}

interface Actions extends CreateAdminNoteDialogActions<string> {
  refreshUserDetailsPage?: () => void;
}

type Props = Model & Actions;

const LockUserDialog = (props: Props) => {

  const {
    lockUserAction,
    refreshUserDetailsPage: refreshDetailsPage = noop,
    ...otherProps
  } = props;

  const { userId, cancel: closeDialog = noop } = otherProps;

  const match = useRouteMatch(DETAILS_PAGE_PATH);

  const isDetailsPageLoaded = React.useMemo(() => match && match.isExact, [match]);

  const onSuccessMessageShown = React.useCallback(() => {
    if (isDetailsPageLoaded) {
      refreshDetailsPage();
    }
    closeDialog();
  }, [isDetailsPageLoaded, refreshDetailsPage, closeDialog]);

  return (
    <CreateAdminNoteDialog {...otherProps} onSuccessMessageShown={onSuccessMessageShown}>
      <LockUser userId={userId} lockUserAction={lockUserAction} />
    </CreateAdminNoteDialog>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  className: getClassName(state),
  title: getDialogTitle(state),
  open: isDialogOpen(state),
  item: getUserId(state),
  loading: isLoadingIndicatorVisible(state),
  accessDenied: isAccessDeniedVisible(state),
  errorMessage: getErrorMessage(state),
  successMessage: getSuccessMessage(state),
  continueButtonLabel: getContinueButtonLabel(state),
  continueButtonDisabled: !isUserIdValid(state),
  userId: getUserId(state),
  lockUserAction: isLockUserAction(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  cancel: () => dispatch(close()),
  confirm: () => dispatch(save()),
  trackCreateAdminNoteRequest: () => dispatch(trackCreateAdminNoteRequest()),
  trackCreateAdminNoteSuccess: () => dispatch(trackCreateAdminNoteSuccess()),
  trackCreateAdminNoteFailed: analytic => dispatch(trackCreateAdminNoteFailed(analytic)),
  refreshUserDetailsPage: () => dispatch(refreshUserDetailsPage()),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(LockUserDialog);
