import React from "react";
import { Module } from "@data";
import { MODULE_ID } from "./constants";
import { isGenerateDialog as isDialogOpen } from "./selectors";
import { initialize, open } from "./actions";
import { PrimaryIcon } from "../users/components/styles";
import UserReportDialog from "./containers/UserReportDialog";

export const userReportModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "User Report",
  title: "User Report",
  icon: PrimaryIcon,
  view: <UserReportDialog />,
  onClick: open,
  isDialogOpen,
  initialize,
};

export default userReportModule;
