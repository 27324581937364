import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import { ErrorView } from "@components";

export interface Model {
  errorMessage?: string;
  title?: string;
  children?: React.ReactNode;
}

type Props = WithStyles<typeof styles> & Model;

export const AdminNotesView = withStyles(styles)((props: Props) => {

  const {
    classes,
    errorMessage,
    title = "Admin Notes",
    children,
  } = props;

  return (
    <div className={classnames("adminNotes", classes.container)}>
      <h1 className={classnames("title", classes.title)}>
        {title}
      </h1>
      <ErrorView className={classes.errorMessageContainer} message={errorMessage} />
      {children}
    </div>
  );
});

export default AdminNotesView;
