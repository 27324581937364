import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import DeviceEnrollmentList , { Model } from "../components/DeviceEnrollmentList";
import {
  getErrorMessage,
  getBatchId,
  isLoadingIndicatorVisible,
  getResult,
} from "../selectors";

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => {

  const items = getResult(state);
  const isEmpty = items.length === 0;
  const error = getErrorMessage(state);
  const searchQuery = getBatchId(state);
  const showErrorView = error.length > 0;
  const isLoading = isLoadingIndicatorVisible(state);
  const showNoResultsView = isEmpty && !showErrorView && !isLoading;
  const showLoadingIndicator = isLoading && !showErrorView && (isEmpty || !searchQuery);

  return {
    items,
    error,
    showLoadingIndicator,
    showErrorView,
    showNoResultsView,
    searchQuery: getBatchId(state),
    ...ownProps,
  };
};

export default connect<Model, {}, Model>(
  mapStateToProps,
)(DeviceEnrollmentList);
