import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    header: {
      flex: "0 0 60px",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      backgroundColor: Color.LIGHT_BLUE3,
      border: `1px solid ${Color.GREY1}`,
    },
    headerIcon: {
      color: Color.GREY9,
      marginLeft: 8,
      width: "auto",
      height: 24,
    },
    title: {
      fontSize: 18,
      lineHeight: "21px",
      fontWeight: 400,
      margin: "10px 0 10px 8px",
    },
    toggleCollapsedButton: {
      marginLeft: "auto",
    },
    toggleCollapsedIcon: {
    },
    content: {
      display: "flex",
      flexFlow: "column nowrap",
      padding: "15px 10px 10px",
      backgroundColor: Color.LIGHT_BLUE2,
    },
    emptyView: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
    },
    emptyViewTitle: {
      fontSize: 22,
      fontWeight: 300,
      margin: "10px 0 5px",
    },
    legacyReleaseNotesLink: {
      alignSelf: "center",
      margin: "8px 0",
      textAlign: "center",
      textDecoration: "none",
      fontWeight: 300,
      fontSize: 14,
      lineHeight: "24px",
      color: Color.TEXT,
      "&:hover": {
        fontWeight: 400,
        textDecoration: "underline",
      },
    },
    loadingView: {
      color: Color.GREY3,
      margin: "10px 0",
      "& > label": {
        fontSize: 18,
        lineHeight: "28px",
      },
    },
    list: {
      listStyleType: "none",
      margin: 0,
      padding: 0,
    },
    listItem: {
      display: "flex",
      flexFlow: "row nowrap",
      marginTop: 10,
      "&:first-child": {
        marginTop: 0,
      },
    },
    listItemIcon: {
      marginTop: 2,
      marginLeft: 2,
      color: Color.GREY9,
      width: 18,
      height: 18,
    },
    listItemContent: {
      marginLeft: 10,
      display: "flex",
      flexFlow: "column nowrap",
    },
    listItemTitle: {
      color: Color.TEXT,
    },
    listItemBody: {
      marginTop: 4,
      lineHeight: "18px",
      marginLeft: 2,
    },
    listItemLink: {
    },
    viewMoreLink: {
      fontSize: 14,
      marginTop: 4,
      textAlign: "right",
    },
  });

export default styles;
