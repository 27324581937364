import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { noop } from "@util/Functions";
import { styles } from "./styles";
import {
  ContinueButton,
  UserProfileAttributes,
  UserProfileAttributesActions,
  UserProfileAttributesModel,
} from "@components";

export interface Model extends UserProfileAttributesModel {
  hideEditButton?: boolean;
  editButtonDisabled?: boolean;
}

export interface Actions extends UserProfileAttributesActions {
  edit?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ProfileView = withStyles(styles)((props: Props) => {

  const {
    classes,
    hideEditButton,
    editButtonDisabled,
    edit = noop,
    children,
    ...otherProps
  } = props;

  return (
    <div className={classnames("profileView", classes.container)}>
      <div className={classnames("controls", classes.controls)}>
        {!hideEditButton && (
          <ContinueButton
            className={classnames("editButton", classes.editButton, classes.button)}
            label="Edit Profile Attributes"
            disabled={editButtonDisabled}
            onClick={edit}
          />
        )}
      </div>
      <UserProfileAttributes
        {...otherProps}
        className={classnames("userProfileAttributes", classes.userProfileAttributes)}
      />
      {children}
    </div>
  );
});

export default ProfileView;
