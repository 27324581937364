import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { SecurityServiceRegional } from "@data";
import { isEmptyString } from "@util";
import classnames from "classnames";
import { useServiceActionManagement, ServiceActionOperation } from "@hooks";

export interface ServiceActionOperationDialogModel extends PortalModuleDialogModel<SecurityServiceRegional> {
  dialogClassName?: string;
  serviceId?: string;
  operation?: ServiceActionOperation;
}

export interface ServiceActionOperationDialogActions extends PortalModuleDialogActions<SecurityServiceRegional> {
}

type Props = WithStyles< typeof styles> &
  ServiceActionOperationDialogModel & ServiceActionOperationDialogActions;

export const ServiceActionOperationDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    dialogClassName,
    serviceId = "",
    operation = ServiceActionOperation.NONE,
    ...otherProps
  } = props;

  if (isEmptyString(serviceId) || operation === ServiceActionOperation.NONE) {
    return null;
  }

  const isDelete = React.useMemo(() => operation === ServiceActionOperation.DELETE ,
    [operation]);

  const title = React.useMemo(() =>
      `${operation} Service` ,
    [operation]);

  const [ model, actions] = useServiceActionManagement({
    serviceId,
    operation
  });

  const { showLoadingIndicator, ...otherModel } = model;
  const { performOperation, ...otherActions } = actions;

  const confirm = React.useCallback(() => {
    performOperation();
  }, [performOperation]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      {...otherActions}
      className={classnames("serviceActionOperationDialog", dialogClassName)}
      title={title}
      loading={showLoadingIndicator}
      continueButtonLabel={operation}
      confirm={confirm}
    >
      <div className={classnames("serviceActionOperation", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Are you sure you want to {operation} this service?
          </label>
          {isDelete && (
            <label className={classnames("subtitle", classes.subtitle)}>
              <span className={classes.warning}>WARNING:</span>This action cannot be undone!
            </label>
          )}
          <label className={classnames("serviceId", classes.serviceId)}>
            {serviceId}
          </label>
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default ServiceActionOperationDialog;
