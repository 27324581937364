import React from "react";
import { SecurityGroup } from "@data";
import { getStringValue, isEmptyString } from "@util";
import { AuthTokenContext } from "../auth-token-provider";
import { createComparator, getSearchResults } from "./helpers";
import { SecurityGroupsListColumn } from "./SecurityGroupsListColumn";
import {
  GetSecurityGroupsResponse, GetSecurityServiceGroupResponse,
  RestClientError,
  SecurityGroupClient,
  SecurityServiceRegionalClient,
} from "../../network";

export interface UseSecurityGroupsOptions {
  searchDisabled?: boolean;
  excludedGroupNames?: string[];
}

export interface UseSecurityGroupsModel {
  securityGroups: SecurityGroup[];
  nameFilter: string;
  errorMessage: string;
  errorStatus: number | string;
  showLoadMoreButton: boolean;
  sortOrderAscending: boolean;
  sortByColumn: SecurityGroupsListColumn;
  showSearch: boolean;
  showLoadingIndicator: boolean;
  totalNumSecurityGroups: number;
  totalNumVisibleSecurityGroups: number;
  excludedGroupNames: string[];
}

export interface UseSecurityGroupsActions {
  refresh: () => void;
  loadMore: () => void;
  setNameFilter: (nameFilter: string) => void;
  setSortByColumn: (column: SecurityGroupsListColumn) => void;
  setSortOrderAscending: (ascending: boolean) => void;
}

type Model = UseSecurityGroupsModel;
type Actions = UseSecurityGroupsActions;
type GetSecurityGroupsApi = (authToken: string,
                             nextPage?: string,
                             nameFilter?: string) => Promise<GetSecurityGroupsResponse>;

export const sortAndFilterSecurityGroupResults = (props: {
  items: SecurityGroup[],
  nameFilter: string,
  sortOrderAscending: boolean,
  sortByColumn: SecurityGroupsListColumn,
}) => {

  const { items, nameFilter, sortOrderAscending, sortByColumn } = props;

  const comparator = React.useMemo(() =>
    createComparator(sortByColumn, sortOrderAscending), [sortByColumn, sortOrderAscending]);

  const sortedSecurityGroups = React.useMemo(() => items.sort(comparator).slice(), [items, comparator]);

  return React.useMemo(() =>
    getSearchResults(sortedSecurityGroups, nameFilter), [sortedSecurityGroups, nameFilter]);
};

export const useSecurityGroups = (getSecurityGroups: GetSecurityGroupsApi,
                                  options: UseSecurityGroupsOptions = {}): [Model, Actions] => {

  const { searchDisabled = false, excludedGroupNames = [] } = options;

  const authToken = React.useContext(AuthTokenContext);
  const [items, setItems] = React.useState<SecurityGroup[]>([]);
  const [nameFilter, setNameFilter] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorStatus, setErrorStatus] = React.useState<string | number>("");
  const [nextPageToken, setNextPageToken] = React.useState("");
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);
  const [sortOrderAscending, setSortOrderAscending] = React.useState(true);
  const [sortByColumn, setSortByColumn] = React.useState(SecurityGroupsListColumn.NONE);
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);

  const fetchSecurityGroups = React.useCallback(() =>
      getSecurityGroups(authToken, nextPageToken, nameFilter),
    [authToken, nextPageToken, nameFilter]);

  React.useEffect(() => {

    let ignore = false;

    setShowLoadingIndicator(true);
    setErrorMessage("");

    fetchSecurityGroups()
      .then((response: GetSecurityGroupsResponse) => {
        if (!ignore) {
          const { groups: results = [], paging: { next = "" } = { next: "" } } = response;
          setItems(g => g.concat(results.map(attrs => new SecurityGroup(attrs))));
          setNextPageToken(getStringValue(next || ""));
          setShowLoadingIndicator(false);
        }
      }, (response: RestClientError) => {
        if (!ignore) {
          const { status, error = "Fetch security groups failed" } = response;
          setErrorMessage(error);
          setErrorStatus(status);
          setShowLoadingIndicator(false);
        }
      });

    return () => { ignore = true; };

  }, [lastRefresh, setItems, setNextPageToken, setErrorMessage, setErrorStatus, setShowLoadingIndicator]);

  const showLoadMoreButton = React.useMemo(() =>
    !isEmptyString(nextPageToken) && isEmptyString(errorMessage) && !showLoadingIndicator,
    [nextPageToken, errorMessage, showLoadingIndicator]);

  // This is also used as the retry callback in the event of an error
  const loadMore = React.useCallback(() => {
    setLastRefresh(+new Date);
  }, [setLastRefresh]);

  const refresh = React.useCallback(() => {
    setItems([]);
    setNextPageToken("");
    setLastRefresh(+new Date);
    setSortOrderAscending(true);
    setSortByColumn(SecurityGroupsListColumn.NONE);
  }, [setNextPageToken, setItems, setLastRefresh, setSortOrderAscending, setSortByColumn]);

  const updateNameFilter = React.useCallback((updatedNameFilter: string) => {
    setNameFilter(updatedNameFilter);
    if (!searchDisabled) {
      refresh();
    }
  }, [setNameFilter, searchDisabled, refresh]);

  const securityGroups =
    sortAndFilterSecurityGroupResults({ items, nameFilter, sortOrderAscending, sortByColumn });

  const totalNumSecurityGroups = React.useMemo(() => items.length, [items]);

  const totalNumVisibleSecurityGroups = React.useMemo(() => securityGroups.length, [securityGroups]);

  const showSearch = React.useMemo(() =>
    !isEmptyString(nameFilter) || totalNumSecurityGroups > 1,
    [nameFilter, totalNumSecurityGroups]);

  const model: Model = {
    securityGroups,
    nameFilter,
    errorMessage,
    errorStatus,
    showLoadMoreButton,
    sortOrderAscending,
    sortByColumn,
    showSearch,
    showLoadingIndicator,
    totalNumSecurityGroups,
    totalNumVisibleSecurityGroups,
    excludedGroupNames,
  };

  const actions: Actions = {
    refresh,
    loadMore,
    setNameFilter: updateNameFilter,
    setSortByColumn,
    setSortOrderAscending,
  };

  return [model, actions];
};

export const useAccountSecurityGroups = (): [Model, Actions] => {

  const getSecurityGroups: GetSecurityGroupsApi = SecurityGroupClient.getSecurityGroups;

  return useSecurityGroups(getSecurityGroups);
};

export const useUserSecurityGroups = (userId: string = ""): [Model, Actions] => {

  const getAllSecurityGroups: GetSecurityGroupsApi = SecurityGroupClient.getSecurityGroups;

  const getUserSecurityGroups: GetSecurityGroupsApi = (authToken: string,
                                                       nextPage: string = "",
                                                       nameFilter: string = "") =>
    SecurityGroupClient.getSecurityGroupsByUserId(authToken, userId, nextPage, nameFilter);

  const getSecurityGroups: GetSecurityGroupsApi = (authToken: string,
                                                   nextPage: string = "",
                                                   nameFilter: string = "") => {
    if (isEmptyString(userId)) {
      return getAllSecurityGroups(authToken, nextPage, nameFilter);
    } else {
      return getUserSecurityGroups(authToken, nextPage, nameFilter);
    }
  };

  return useSecurityGroups(getSecurityGroups);
};

const getAllUserSecurityGroups = (props: {
  authToken: string,
  userId: string,
}): Promise<SecurityGroup[]> => {

  const { userId, authToken } = props;

  const getNextPage = (nextPage: string = ""): Promise<SecurityGroup[]> =>
    SecurityGroupClient.getSecurityGroupsByUserId(authToken, userId, nextPage)
      .then((response: GetSecurityGroupsResponse) => {

        const { groups = [], paging: { next = "" } = { next: "" }} = response;

        const nextPageToken = getStringValue(next || "");

        const securityGroups = groups.map(attrs => new SecurityGroup(attrs));

        if (isEmptyString(nextPageToken)) {
          return Promise.resolve(securityGroups);
        }

        return getNextPage(nextPageToken)
          .then((results: SecurityGroup[]) => securityGroups.concat(results));
      });

  return getNextPage();
};

export const useSecurityGroupsUserDoesNotBelongTo = (userId: string = "", groupNames: string[] = []):
  [Model, Actions] => {

  const attachedGroupNames = React.useRef<string[]>([]);
  const attachedGroupNamesFetched = React.useRef(false);

  const getAllSecurityGroups: GetSecurityGroupsApi = SecurityGroupClient.getSecurityGroups;

  const getSecurityGroupsUserDoesNotBelongTo: GetSecurityGroupsApi =
    async (authToken: string, nextPage: string = "", nameFilter: string = "") => {

      attachedGroupNames.current = attachedGroupNamesFetched.current ? groupNames :
        await getAllUserSecurityGroups({ authToken, userId })
          .then(groups => {
            attachedGroupNamesFetched.current  = true;
            return groups.map(group => group.getName());
          });

      return SecurityGroupClient.getSecurityGroups(authToken, nextPage, nameFilter)
        .then((response: GetSecurityGroupsResponse) => {

          const { groups = [], paging: { next = "" } = { next: "" }} = response;

          const nextPageToken = getStringValue(next || "");

          return {
            groups: groups.filter(({ name }) => attachedGroupNames.current.indexOf(name) === -1),
            paging: {
              next: nextPageToken,
            },
          };
        });
    };

  const getSecurityGroups: GetSecurityGroupsApi = (authToken: string,
                                                   nextPage: string = "",
                                                   nameFilter: string = "") => {
    if (isEmptyString(userId)) {
      return getAllSecurityGroups(authToken, nextPage, nameFilter);
    } else {
      return getSecurityGroupsUserDoesNotBelongTo(authToken, nextPage, nameFilter);
    }
  };

  return useSecurityGroups(getSecurityGroups, { excludedGroupNames: attachedGroupNames.current });
};

export const useServiceSecurityGroups = (serviceId: string = ""): [Model, Actions] => {

  const getAllSecurityGroups: GetSecurityGroupsApi = (authToken: string, nextPage: string = "") =>
    SecurityGroupClient.getSecurityGroups(authToken, nextPage);

  const getServiceSecurityGroups: GetSecurityGroupsApi = (authToken: string, nextPage: string = "") =>
    SecurityServiceRegionalClient.getServiceGroups(authToken, serviceId, nextPage)
      .then(({ groups = [], paging: { next = ""} = {} }) => ({
        groups: groups.map(attrs => new SecurityGroup(attrs)),
        paging: {
          next: next || "",
        }
      }));

  const getSecurityGroups: GetSecurityGroupsApi = (authToken: string, nextPage: string = "") => {
    if (isEmptyString(serviceId)) {
      return getAllSecurityGroups(authToken, nextPage);
    } else {
      return getServiceSecurityGroups(authToken, nextPage);
    }
  };

  // Do not trigger a refresh when the name filter is updated if we will be fetching security
  // groups that a service belongs to because that particular API does not have name filter support.
  return useSecurityGroups(getSecurityGroups, { searchDisabled: !isEmptyString(serviceId) });
};

const getAllServicesSecurityGroups = (props: {
  authToken: string,
  serviceId: string,
}): Promise<SecurityGroup[]> => {

  const { serviceId, authToken } = props;

  const getNextPage = (nextPage: string = ""): Promise<SecurityGroup[]> =>
    SecurityServiceRegionalClient.getServiceGroups(authToken, serviceId, nextPage)
      .then((response: GetSecurityServiceGroupResponse) => {

        const { groups = [], paging: { next = "" } = { next: "" }} = response;

        const nextPageToken = getStringValue(next || "");

        const securityGroups = groups.map(attrs => new SecurityGroup(attrs));

        if (isEmptyString(nextPageToken)) {
          return Promise.resolve(securityGroups);
        }

        return getNextPage(nextPageToken)
          .then((results: SecurityGroup[]) => securityGroups.concat(results));
      });

  return getNextPage();
};

export const useSecurityGroupsServicesDoesNotBelongTo = (serviceId: string = ""): [Model, Actions] => {

  const getAllSecurityGroups: GetSecurityGroupsApi = SecurityGroupClient.getSecurityGroups;

  const getSecurityGroupsServicesDoesNotBelongTo: GetSecurityGroupsApi =
    async (authToken: string, nextPage: string = "", nameFilter: string = "") => {

      const excludedGroupNames = await getAllServicesSecurityGroups({ authToken, serviceId })
        .then(groups => groups.map(group => group.getName()));

      return SecurityGroupClient.getSecurityGroups(authToken, nextPage, nameFilter)
        .then((response: GetSecurityGroupsResponse) => {

          const { groups = [], paging: { next = "" } = { next: "" }} = response;

          const nextPageToken = getStringValue(next || "");

          return {
            groups: groups.filter(({ name }) => excludedGroupNames.indexOf(name) === -1),
            paging: {
              next: nextPageToken,
            },
          };
        });
    };

  const getSecurityGroups: GetSecurityGroupsApi = (authToken: string,
                                                   nextPage: string = "",
                                                   nameFilter: string = "") => {
    if (isEmptyString(serviceId)) {
      return getAllSecurityGroups(authToken, nextPage, nameFilter);
    } else {
      return getSecurityGroupsServicesDoesNotBelongTo(authToken, nextPage, nameFilter);
    }
  };

  return useSecurityGroups(getSecurityGroups);
};
