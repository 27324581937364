import { createStyles, StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      position: "relative",
      minHeight: 500,
    },
    marginTop: {
      marginTop: 25,
    },
    queryHeaderContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
      alignItems: "center",
      border: `1px solid ${Color.LIGHT_GREY}`,
      marginBottom: 10,
    },
    queryHeaderTitle: {
      lineHeight: "30px",
      width: "100%",
      padding: 5,
      textAlign: "center",
      backgroundColor: Color.LIGHT_GREY,
    },
    queryContent: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "center",
    },
    queryEditorContainer: {
      width: "100%",
    },
    queryEditorOptions: {
      display: "flex",
      flexFlow: "column nowrap",
      marginRight: 20,
    },
    queryAlert: {
      marginBottom: 10,
    },
    databaseItem: {
      marginRight: 10,
      fontSize: 11,
      overflow: "hidden",
      border: `1px solid ${Color.LIGHT_GREY}`,
      "& .MuiSvgIcon-fontSizeInherit": {
        fontSize: 16,
      },
    },
    colType: {
      color: Color.GREY2,
    },
    sqlEditor: {
      flex: "1 0 auto",
      border: `1px solid ${Color.LIGHT_GREY}`,
      minWidth: 100,
    },
    panelsRow: {
      display: "flex",
      flexFlow: "row nowrap",
      width: "100%",
    },
    panelsCol: {
      display: "flex",
      flexFlow: "column nowrap",
      width: "100%",
    },
    database: {
      "& .autocompleteTextFieldInput": {
        minWidth: 0,
      },
      "& .autocompleteFormHelperText": {
        marginLeft: 4,
        fontSize: 12,
      },
    },
    dropdown: {
      alignItems: "stretch",
      marginBottom: 25,
      width: 400,
      "& .MuiOutlinedInput-input": {
        paddingBottom: 12,
        paddingLeft: 10,
      },
      "& .dropdownMenuHelperText": {
        marginLeft: 4,
      },
    },
    dropdownMenuEmptyValueLabel: {
      color: Color.GREY3,
      fontStyle: "normal",
    },
    dropdownMenuSelect: {
      "& > fieldset": {
        zIndex: 0,
        top: 0,
      },
    },
    dropdownMenuLabel: {
      zIndex: 1,
      backgroundColor: Color.WHITE,
      padding: "0 4px",
      marginLeft: 6,
      lineHeight: "12px",
    },
    beautifyButton: {
      float: "right",
      padding: 15,
      height: 18,
      minWidth: 0,
      background: Color.TRANSPARENT,
      "& .label": {
        fontSize: 12,
      },
    }
  });

export const accordionStyle: StyleRules = {
  root: {
    minHeight: 40,
    maxWidth: 300,
    "& .MuiIconButton-root": {
      padding: 0,
      minWidth: 28,
    },
    "&:before": {
      display: "none",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 40,
    },
    "& .MuiAccordionSummary-root": {
      minHeight: 40,
      padding: "0px 25px 0px 10px",
    },
    "& .MuiTypography-body1": {
      fontSize: 11,
      wordBreak: "break-word",
      userSelect: "text",
      cursor: "default"
    }
  }
};

export const accordionSummaryStyle: StyleRules = {
  root: {
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0 0 0 16px",
    },
  }
};

export const accordionDetailsStyle: StyleRules = {
  root: {
    padding: "0 0 0 20px",
  }
};

export const accordionColumnDetailsStyle: StyleRules = {
  root: {
    padding: "0 0 0 50px",
  }
};

export default styles;
