import React from "react";
import { initialize } from "./actions";
import { getDeviceTypeV2 } from "./selectors";
import { getPathToDeviceType } from "./helpers";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { DeviceTypeListItem, DeviceTypeModelV3 as DeviceType, JsonSchemaMetadata } from "@data";
import { getCreateDeviceTypePath, getEditDeviceTypePath } from "@modules/deviceTypeWizard/helpers";
import DeviceTypeDetails from "./containers/DeviceTypeDetails";
import { getEnrollDevicePath } from "@modules/deviceEnrollmentWizard/helpers";
import { getPathToSchema } from "@modules/schemaDetails/helpers";

export function DeviceTypeDetailsView() {

  const history = useHistory();
  const dispatch = useDispatch();

  // Easiest way for us to support both the v3 device type model and the legacy v2 model
  // without having to update all of the child component interfaces, which should hopefully
  // make it a tiny bit easier to remove v2 support when we get the green light.
  const legacyDeviceType = useSelector(getDeviceTypeV2);

  const { id: nameAndVersion } = useParams<{ id: string }>();

  const editDeviceType = React.useCallback(() =>
      history.push(getEditDeviceTypePath(nameAndVersion), { goBackOnSuccess: true }),
    [history, nameAndVersion]);

  const cloneDeviceType = React.useCallback((deviceType: DeviceType) => {
    history.push(getCreateDeviceTypePath(), {
      deviceType: deviceType.toJS(),
      deviceTypeV2: legacyDeviceType.toJS(),
    });
  }, [history, legacyDeviceType]);

  const showDeviceTypeDetails = React.useCallback((deviceType: DeviceTypeListItem) =>
      history.push(getPathToDeviceType(deviceType.getTypeIdentity())),
    [history]);

  const showSchemaDetails = React.useCallback((schema: JsonSchemaMetadata) =>
    history.push(getPathToSchema(schema.getId())), [history]);

  const showSchemaDetailsInNewTab = React.useCallback((schema: JsonSchemaMetadata) =>
    window.open(getPathToSchema(schema.getId()), "_blank", "noopener,noreferrer"), []);

  const enrollDevice = React.useCallback(() =>
    history.push(getEnrollDevicePath()), [history]);

  React.useEffect(() => {
    dispatch(initialize(nameAndVersion));
  }, [dispatch, nameAndVersion]);

  return (
    <DeviceTypeDetails
      key={nameAndVersion}
      editDeviceType={editDeviceType}
      cloneDeviceType={cloneDeviceType}
      showDeviceTypeDetails={showDeviceTypeDetails}
      showSchemaDetails={showSchemaDetails}
      showSchemaDetailsInNewTab={showSchemaDetailsInNewTab}
      enrollDevice={enrollDevice}
    />
  );
}

export default DeviceTypeDetailsView;
