import createStyles from "@material-ui/core/styles/createStyles";
import { StyleRules } from "@material-ui/core/styles/withStyles";
import Color from "@Color";

export const rememberMeCheckbox: StyleRules = {
  root: {
    color: "rgba(237, 243, 245, 0.5)",
    "&$checked": {
      color: "rgba(237, 243, 245, 0.5)",
    },
  },
  checked: {
    color: "rgba(237, 243, 245, 0.5)",
  },
};

export const loginButton: StyleRules = {
  root: {
    backgroundColor: Color.SIGNIFY_GREEN,
    "&:hover": {
      backgroundColor: Color.SIGNIFY_GREEN2,
    },
    "&:active": {
      backgroundColor: Color.SIGNIFY_GREEN3,
    },
  },
  label: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "16px",
    padding: 0,
    margin: "0 30px",
    color: Color.BLACK,
  },
};

export const tooltip: StyleRules = {
  popper: {
    width: 231,
    height: "auto",
    alignItems: "center",
    display: "flex",
  },
  tooltip: {
    backgroundColor: Color.WHITE,
    color: Color.GREY8,
    padding: "10px 15px",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px",
    textAlign: "left",
    whiteSpace: "normal",
  },
};

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      marginTop: 25,
    },
    logo: {

    },
    username: {

    },
    account: {
      marginTop: 15,
    },
    password: {
      marginTop: 15,
    },
    token: {
      marginTop: 15,
    },
    rememberMe: {

    },
    rememberMeCheckbox: {

    },
    loginButton: {
      marginTop: 15,
      zIndex: 0,
    },
    textField: {
      fontSize: 13,
      height: "auto",
    },
    textFieldIcon: {
      width: 20,
      height: 20,
      fillOpacity: 0.4,
      color: Color.WHITE,
    },
    tooltip: {
      width: 231,
      height: "auto",
      alignItems: "center",
      display: "flex",
      borderRadius: 3,
      fontWeight: 400,
      padding: 0,
      marginTop: -15,
      transition: "top 50ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, " +
        "transform 0ms cubic-bezier(0.23, 1, 0.32, 1) 50ms, " +
        "opacity 0ms cubic-bezier(0.23, 1, 0.32, 1) 50ms",
    },
    iconButton: {
      position: "absolute",
      right: 8,
      bottom: 18,
      width: 20,
      height: 20,
      padding: 0,
      margin: 0,
    },
    tooltipText: {
      color: Color.GREY8,
      padding: 0,
      margin: "10px 15px",
      fontSize: 14,
      lineHeight: "16px",
      textAlign: "left",
      whiteSpace: "normal",
    },
    formHelperText: {
      fontSize: 13,
    },
    inputLabel: {
      transform: "translate(12px, 25px) scale(1)",
      "&$error:not($focused)": {
        transform: "translate(12px, 5px) scale(1)",
      },
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    focused: {},
    error: {},
    hidden: {
      visibility: "hidden",
    },
  });

export default styles;
