import React from "react";
import { noop } from "@util";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { setDeleteAuthenticationDialog } from "../actions";
import {
  isDeleteAuthenticatorDialogOpen,
} from "../selectors";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import DeleteAuthenticator from "../components/Authentication/DeleteAuthenticator";

interface Model extends PortalModuleDialogModel<string> {
  authenticatorId?: string;
}

interface Actions extends PortalModuleDialogActions<string> {
  deleteAuthenticator?: () => void;
  onDeleteSuccess?: () => void;
}

const DeleteAuthenticatorDialog = (props: Model & Actions) => {

  const {
    authenticatorId,
    deleteAuthenticator = noop,
    onDeleteSuccess = noop,
    ...otherProps
  } = props;

  return (
    <PortalModuleDialog
      {...otherProps}
      onSuccessMessageShown={onDeleteSuccess}
      confirm={deleteAuthenticator}
    >
      <DeleteAuthenticator authenticatorId={authenticatorId} />
    </PortalModuleDialog>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  className: "deleteAuthenticatorDialog",
  title: "Delete Authenticator",
  open: isDeleteAuthenticatorDialogOpen(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  cancel: () => dispatch(setDeleteAuthenticationDialog(false)),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteAuthenticatorDialog);
