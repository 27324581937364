import { DEFAULT_STATE, UiPackageColumn, UiPackageSchema } from "./reducers";
import { createSelectors, Selector } from "@modules/base/createSelectors";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";
import { Comparator, compare } from "@util";
import { AppSchema } from "@schemas";
import { createSelector } from "reselect";
import { ActionMenuItem } from "@components/actions-menu";
import { DELETE_UI_PACKAGE_ACTION, UPLOAD_UI_PACKAGE_ACTION } from "@components/ui-package-management-action-menu";

export const {
  files: getFiles,
  sortByColumn: getSortByColumn,
  sortOrderAscending: isSortOrderAscending,
  errorMessage: getErrorMessage,
  successMessage: getSuccessMessage,
  showEmptyView: isEmptyViewVisible,
  showNotFound: isNotFoundVisible,
  showAccessDenied: isAccessDeniedVisible,
  showLoadingIndicator: isLoadingIndicatorVisible,
  showDeleteUiPackageDialog: isShowDeleteUiPackageDialogVisible,
  showUploadUiPackageDialog: isShowUploadUiPackageDialogVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
} = createSelectors<UiPackageSchema>(moduleId, schemaKey, DEFAULT_STATE);

const compareByFiles = (item: string, otherItem: string, ascending: boolean) =>
  compare(item, otherItem, ascending);

const createComparator = (sortedColumn: UiPackageColumn,
                          ascending: boolean): Comparator<string> =>

  (item: string, otherItem: string) => {
    switch (sortedColumn) {
      case UiPackageColumn.FILES:
        return compareByFiles(item, otherItem, ascending);
      default:
        return 0;
    }
  };

const getComparator: (state: AppSchema) => Comparator<string> = createSelector(
  [getSortByColumn, isSortOrderAscending], createComparator);

export const getSortedItems: (state: AppSchema) => string[] = createSelector(
  [getSortByColumn, getFiles, getComparator],
  (sortedColumn: UiPackageColumn,
   items: string[],
   comparator: Comparator<string>) => {

    if (UiPackageColumn.NONE === sortedColumn) {
      return items;
    }

    items.sort(comparator);
    return items.slice();
  });

export const getNumSearchResults: (state: AppSchema) => number = createSelector(
  getSortedItems, (items: string[]) => items.length);

export const getActionMenuItems: Selector<ActionMenuItem[]> = () => ([
  UPLOAD_UI_PACKAGE_ACTION,
  DELETE_UI_PACKAGE_ACTION,
]);
