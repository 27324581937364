import React from "react";
import { ManagedPolicy, Policy, SecurityServiceRegional, SummaryViewData, User } from "@data";
import { PrimaryIcon } from "../components/styles";
import {
  ActionMenuItem,
  SecurityGroupAction,
  DetailsView,
  DetailsViewRoute,
  SummaryView,
  DeleteSecurityGroupDialog,
  ATTACH_POLICY_ACTION,
  DETACH_POLICY_ACTION,
  ADD_USER_ACTION,
  REMOVE_USER_ACTION,
  ADD_SERVICE_ACTION,
  REMOVE_SERVICE_ACTION,
  DELETE_SECURITY_GROUP_ACTION
} from "@components";
import { noop } from "@util";
import PoliciesView from "./PoliciesView";
import Users from "./Users";
import Services from "./ServicesView";
import { useSecurityGroup } from "@hooks";

enum PoliciesViewMode {
  CUSTOM = "custom",
  MANAGED = "managed"
}

export interface ContainerModel {
  groupName?: string;
}

export interface ContainerActions {
  showListView?: () => void;
  showUserDetails?: (user: User) => void;
  showServiceDetails?: (service: SecurityServiceRegional) => void;
  showPolicyDetails?: (policy: Policy) => void;
  showManagedPolicyDetails?: (policy: ManagedPolicy) => void;
  attachPolicy?: () => void;
  detachPolicy?: () => void;
  addUser?: () => void;
  removeUser?: () => void;
  addService?: () => void;
  removeService?: () => void;
  onClickAction?: (action: ActionMenuItem) => void;
}

type Props = ContainerModel & ContainerActions;

const SecurityGroupDetailsContainer = (props: Props) => {

  const {
    groupName = "",
    showUserDetails,
    showServiceDetails,
    showPolicyDetails,
    showManagedPolicyDetails,
    showListView = noop,
    onClickAction = noop,
    attachPolicy = noop,
    detachPolicy = noop,
    addUser = noop,
    removeUser = noop,
    addService = noop,
    removeService = noop,
  } = props;

  const [{ securityGroup, ...otherModel }, { refresh, ...otherActions }] = useSecurityGroup({groupName});

  const name = React.useMemo(() => securityGroup.getName(), [securityGroup]);
  const path = React.useMemo(() => securityGroup.getPath(), [securityGroup]);

  const [removePolicyButtonEnabled, setRemovePolicyButtonEnabled] = React.useState<boolean>(true);
  const [removeUserButtonEnabled, setRemoveUserButtonEnabled] = React.useState<boolean>(true);
  const [removeServiceButtonEnabled, setRemoveServiceButtonEnabled] = React.useState<boolean>(true);

  const [policyViewMode, setPolicyViewMode] = React.useState<PoliciesViewMode>(PoliciesViewMode.CUSTOM);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);

  const deleteSecurityGroup = React.useCallback(() => {
    setOpenDeleteDialog(true);
  }, [setOpenDeleteDialog]);

  const closeDeleteDialog = React.useCallback(() => {
    setOpenDeleteDialog(false);
  }, [setOpenDeleteDialog]);

  const onDeleteSuccess = React.useCallback(() => {
    closeDeleteDialog();
    showListView();
  }, [showListView, closeDeleteDialog]);

  const actionMenuItems = React.useMemo(() => {
    return [
      ATTACH_POLICY_ACTION,
      {
        ...DETACH_POLICY_ACTION,
        disabled: !removePolicyButtonEnabled,
      },
      ADD_USER_ACTION,
      {
        ...REMOVE_USER_ACTION,
        disabled: !removeUserButtonEnabled,
      },
      ADD_SERVICE_ACTION,
      {
        ...REMOVE_SERVICE_ACTION,
        disabled: !removeServiceButtonEnabled,
      },
      DELETE_SECURITY_GROUP_ACTION,
    ];
  }, [removePolicyButtonEnabled, removeUserButtonEnabled, removeServiceButtonEnabled]);

  const actionClicked = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case SecurityGroupAction.ATTACH_POLICY:
        return attachPolicy();
      case SecurityGroupAction.DETACH_POLICY:
        return detachPolicy();
      case SecurityGroupAction.ADD_USER:
        return addUser();
      case SecurityGroupAction.REMOVE_USER:
        return removeUser();
      case SecurityGroupAction.ADD_SERVICE:
        return addService();
      case SecurityGroupAction.REMOVE_SERVICE:
        return removeService();
      case SecurityGroupAction.DELETE_SECURITY_GROUP:
        return deleteSecurityGroup();
      default:
        return onClickAction(action);
    }
  }, [
    attachPolicy,
    detachPolicy,
    addUser,
    removeUser,
    addService,
    removeService,
    deleteSecurityGroup,
    onClickAction,
  ]);

  const summaryViewItems = React.useMemo(() => [
    new SummaryViewData({
      className: "name",
      name: "Name",
      value: name,
    }),
    new SummaryViewData({
      className: "path",
      name: "Path",
      value: path,
    }),
  ], [name, path]);

  const policiesView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <SummaryView
        items={summaryViewItems}
      >
        <PoliciesView
          groupName={name}
          showPolicyDetails={showPolicyDetails}
          showManagedPolicyDetails={showManagedPolicyDetails}
          isCustomPoliciesViewActive={PoliciesViewMode.CUSTOM === policyViewMode}
          isManagedPoliciesViewActive={PoliciesViewMode.MANAGED === policyViewMode}
          showCustomPoliciesView={() => setPolicyViewMode(PoliciesViewMode.CUSTOM)}
          showManagedPoliciesView={() => setPolicyViewMode(PoliciesViewMode.MANAGED)}
          setRemovePolicyButtonEnabled={(enabled: boolean) => setRemovePolicyButtonEnabled(enabled)}
        />
      </SummaryView>
    );
    }, [
      name,
      policyViewMode,
      summaryViewItems,
      setPolicyViewMode,
      showPolicyDetails,
      showManagedPolicyDetails,
      setRemovePolicyButtonEnabled
  ]);

  const usersView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <SummaryView
        items={summaryViewItems}
      >
        <Users
          groupName={name}
          showUserDetails={showUserDetails}
          setRemoveUserButtonEnabled={(enabled: boolean) => setRemoveUserButtonEnabled(enabled)}
        />
      </SummaryView>
    );
  }, [name, summaryViewItems, showUserDetails, setRemoveUserButtonEnabled]);

  const servicesView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <SummaryView
        items={summaryViewItems}
      >
        <Services
          groupName={name}
          showServiceDetails={showServiceDetails}
          setRemoveServiceButtonEnabled={(enabled: boolean) => setRemoveServiceButtonEnabled(enabled)}
        />
      </SummaryView>
    );
  }, [name, summaryViewItems, showServiceDetails, setRemoveServiceButtonEnabled]);

  const routes: DetailsViewRoute[] = React.useMemo(() => [
    {
      id: "policiesView",
      name: "Policies",
      view: policiesView,
    },
    {
      id: "usersView",
      name: "Users",
      path: "/users",
      view: usersView,
    },
    {
      id: "servicesView",
      name: "Services",
      path: "/services",
      view: servicesView,
    },
  ], [policiesView, usersView, servicesView]);

  return (
    <React.Fragment>
      <DetailsView
        {...otherModel}
        {...otherActions}
        title={name}
        icon={PrimaryIcon}
        refresh={refresh}
        actions={actionMenuItems}
        onClickAction={actionClicked}
        routes={routes}
        tabsMarginTop={false}
      />
      <DeleteSecurityGroupDialog
        open={openDeleteDialog}
        cancel={closeDeleteDialog}
        securityGroupName={name}
        onSuccessMessageShown={onDeleteSuccess}
      />
    </React.Fragment>
  );
};

export default SecurityGroupDetailsContainer;
