import React from "react";
import { Module } from "@data";
import { isReleaseNotesEnabled } from "@util";
import { createListViewModule } from "@base/createModule";
import { PrimaryIcon as icon } from "./components/styles";
import { LEGACY_MODULE_PATHS, MODULE_ID, MODULE_PATHS, MODULE_TITLE } from "./constants";
import ReleaseNotesTabsView from "./ReleaseNotesTabsView";
import view from "./ReleaseNotesView";

export const releaseNotesModule: Module = createListViewModule({
  id: MODULE_ID,
  path: MODULE_PATHS,
  legacyPath: LEGACY_MODULE_PATHS,
  name: MODULE_TITLE,
  title: MODULE_TITLE,
  className: "releaseNotesModule",
  icon,
  view,
  tabs: <ReleaseNotesTabsView/>,
  disabled: !isReleaseNotesEnabled(),
});

export default releaseNotesModule;
