import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { equalsIgnoreCase, isEmptyString, noop } from "@util";
import { getCurrentAccountId, getCurrentServiceId, getCurrentUserId } from "@main/selectors";
import SessionMismatchDialog, { Actions, Model } from "../components/SessionMismatchDialog";
import LogoutAndRedirectToLoginPage from "./LogoutAndRedirectToLoginPage";

const useSessionQueryParams = () => {

  const location = useLocation();

  const search = React.useMemo(() => location.search, [location]);

  const pathname = React.useMemo(() => location.pathname, [location]);

  const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);

  const accountId = React.useMemo(() => queryParams.get("accountId") || "", [queryParams]);

  const userId = React.useMemo(() => queryParams.get("userId") || "", [queryParams]);

  return React.useMemo(() => ({
    pathname,
    queryParams,
    accountId,
    userId,
  }), [
    pathname,
    queryParams,
    accountId,
    userId,
  ]);
};

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const SessionMismatchDialogContainer = (props: Props) => {

  const { currentAccountId, currentServiceId, currentUserId, ...otherProps } = props;

  const { pathname, queryParams, accountId, userId } = useSessionQueryParams();

  const [redirectRequired, setRedirectRequired] = React.useState(false);

  const [logout, setLogout] = React.useState(false);

  const showAccountIdMismatchDialog = React.useMemo(() =>
    !isEmptyString(accountId) && !equalsIgnoreCase(currentAccountId, accountId),
    [accountId, currentAccountId]);

  const showUserIdMismatchDialog = React.useMemo(() =>
    !isEmptyString(userId) && !equalsIgnoreCase(currentUserId, userId),
    [userId, currentUserId]);

  const open = React.useMemo(() =>
    showAccountIdMismatchDialog || showUserIdMismatchDialog,
    [showAccountIdMismatchDialog, showUserIdMismatchDialog]);

  const title = React.useMemo(() => {
    if (showAccountIdMismatchDialog && !showUserIdMismatchDialog) {
      return "You are logged into a different account";
    } else if (!showAccountIdMismatchDialog && showUserIdMismatchDialog) {
      return "You are logged in as different principal";
    } else {
      return "You are not logged in as that IoT account principal";
    }
  }, [showAccountIdMismatchDialog, showUserIdMismatchDialog]);

  const className = React.useMemo(() => {
    if (showAccountIdMismatchDialog && !showUserIdMismatchDialog) {
      return "accountIdMismatchDialog";
    } else if (!showAccountIdMismatchDialog && showUserIdMismatchDialog) {
      return "userIdMismatchDialog";
    } else {
      return "sessionMismatchDialog";
    }
  }, [showAccountIdMismatchDialog, showUserIdMismatchDialog]);

  const removeAccountIdQueryParam = React.useCallback(() => {
    queryParams.delete("autoLogin");
    queryParams.delete("accountId");
    setRedirectRequired(true);
  }, [queryParams, setRedirectRequired]);

  const removeUserIdQueryParam = React.useCallback(() => {
    queryParams.delete("autoLogin");
    queryParams.delete("userId");
    setRedirectRequired(true);
  }, [queryParams, setRedirectRequired]);

  React.useEffect(() => {

    if (redirectRequired) {
      setRedirectRequired(false);
      return noop;
    }

    if ((!isEmptyString(accountId) || !isEmptyString(userId)) &&
      (!showAccountIdMismatchDialog || !showUserIdMismatchDialog)) {

      if (!isEmptyString(accountId) && !showAccountIdMismatchDialog) {
        removeAccountIdQueryParam();
        setRedirectRequired(true);
      }

      if (!isEmptyString(userId) && !showUserIdMismatchDialog) {
        removeUserIdQueryParam();
        setRedirectRequired(true);
      }

      return noop;
    }

    return noop;

  }, [
    accountId,
    showAccountIdMismatchDialog,
    removeAccountIdQueryParam,
    userId,
    showUserIdMismatchDialog,
    removeUserIdQueryParam,
    setRedirectRequired,
  ]);

  if (logout) {
    return <LogoutAndRedirectToLoginPage />;
  }

  if (redirectRequired) {
    return <Redirect to={`${pathname}?${queryParams}`} />;
  }

  return (
    <SessionMismatchDialog
      {...otherProps}
      open={open}
      title={title}
      className={className}
      currentAccountId={currentAccountId}
      otherAccountId={accountId}
      currentServiceId={currentServiceId}
      currentUserId={currentUserId}
      otherUserId={userId}
      removeAccountIdQueryParam={removeAccountIdQueryParam}
      removeUserIdQueryParam={removeUserIdQueryParam}
      confirm={() => setLogout(true)}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  currentAccountId: getCurrentAccountId(state),
  currentUserId: getCurrentUserId(state),
  currentServiceId: getCurrentServiceId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(SessionMismatchDialogContainer);
