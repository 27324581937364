import { BASE_URL } from "./constants";
import { ApprovalRequestType } from "@data";

export const getPathToDataApprovalRequest = (id: string) =>
  `${BASE_URL}/${id}`;

export const isSourceDataSetRequest = (requestType: ApprovalRequestType) => {
  switch (requestType) {
    case ApprovalRequestType.IOT_APPROVAL:
    case ApprovalRequestType.DO_APPROVAL:
    case ApprovalRequestType.BO_APPROVAL:
      return true;

    default:
      return false;
  }
};
