import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import FiltersMenu from "@components/filters-menu";
import { PopoverOrigin } from "@material-ui/core/Popover";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DataSetsFilters, {
  DataSetsFiltersActions,
  DataSetsFiltersModel,
} from "../data-sets-filters";
import styles from "./styles";

const DEFAULT_ANCHOR_ORIGIN: PopoverOrigin = {
  horizontal: "right",
  vertical: "top",
};

const DEFAULT_TRANSFORM_ORIGIN: PopoverOrigin = {
  horizontal: "left",
  vertical: "top",
};

export interface DataSetsFiltersMenuModel extends DataSetsFiltersModel {
  className?: string;
  disabled?: boolean;
  open?: boolean;
  showBadge?: boolean;
  badgeCount?: number;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export interface DataSetsFiltersMenuActions extends DataSetsFiltersActions {
  openMenu?: () => void;
  closeMenu?: () => void;
}

type Model = DataSetsFiltersMenuModel;
type Actions = DataSetsFiltersMenuActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataSetsFiltersMenu = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    disabled,
    open = false,
    badgeCount = 0,
    showBadge = badgeCount > 0,
    anchorOrigin = DEFAULT_ANCHOR_ORIGIN,
    transformOrigin = DEFAULT_TRANSFORM_ORIGIN,
    openMenu = noop,
    closeMenu = noop,
    children,
    ...otherProps
  } = props;

  return (
    <FiltersMenu
      className={classnames("dataSetsFiltersMenu", className, classes.container)}
      showBadge={showBadge}
      badgeCount={badgeCount}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      open={open}
      disabled={disabled}
      openMenu={openMenu}
      closeMenu={closeMenu}
    >
      <DataSetsFilters
        {...otherProps}
        className={classnames("filters", classes.filters)}
      >
        {children}
      </DataSetsFilters>
    </FiltersMenu>
  );
});

export default DataSetsFiltersMenu;
