import { AppSchema } from "@schemas";
import { getAuthToken } from "@main/selectors";
import { getStringValue, identityAction, ReduxAction } from "@util";
import { SoftwareVersionDetailsSelectors } from "../selectors";
import {
  DeviceTypeClient,
  GetDeviceTypesBySoftwareVersionResponse,
  GetSoftwareVersionsResponse,
  RestClientError,
} from "@network";
import {
  DEFAULT_STATE,
  SoftwareVersionDetailsActionType as ActionType,
} from "../reducers/softwareVersionDetails";

type Action = ReduxAction<ActionType>;

export const setNamespace = identityAction<ActionType, string>(
  ActionType.SET_NAMESPACE, DEFAULT_STATE.namespace);

export const setName = identityAction<ActionType, string>(
  ActionType.SET_NAME, DEFAULT_STATE.name);

export const setSoftwareVersion = identityAction<ActionType, string>(
  ActionType.SET_SOFTWARE_VERSION, DEFAULT_STATE.softwareVersion);

export const setDeviceTypeVersions = identityAction<ActionType, string[]>(
  ActionType.SET_DEVICE_TYPE_VERSIONS, DEFAULT_STATE.deviceTypeVersions);

export const setErrorMessage = identityAction<ActionType, string>(
  ActionType.SET_ERROR_MESSAGE, DEFAULT_STATE.errorMessage);

export const setShowAccessDenied = identityAction<ActionType, boolean>(
  ActionType.SET_SHOW_ACCESS_DENIED, DEFAULT_STATE.showAccessDenied);

export const setShowLoadingIndicator = identityAction<ActionType, boolean>(
  ActionType.SET_SHOW_LOADING_INDICATOR, DEFAULT_STATE.showLoadingIndicator);

export const clearErrorMessage = (): Action => setErrorMessage();

export const showAccessDenied = (): Action => setShowAccessDenied(true);
export const hideAccessDenied = (): Action => setShowAccessDenied(false);

export const showLoadingIndicator = (): Action => setShowLoadingIndicator(true);
export const hideLoadingIndicator = (): Action => setShowLoadingIndicator(false);

export const fetchDeviceTypesRequest = (): Action => ({
  type: ActionType.FETCH_DEVICE_TYPES_REQUEST,
});

export const fetchDeviceTypesSuccess = (): Action => ({
  type: ActionType.FETCH_DEVICE_TYPES_SUCCESS,
});

export const fetchDeviceTypesFailed = (error: string): Action => ({
  type: ActionType.FETCH_DEVICE_TYPES_FAILED,
  value: error,
});

export const fetchDeviceTypes = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const authToken = getAuthToken(state);
  const namespace = SoftwareVersionDetailsSelectors.getNamespace(state);
  const name = SoftwareVersionDetailsSelectors.getName(state);
  const softwareVersion = SoftwareVersionDetailsSelectors.getSoftwareVersion(state);

  dispatch(showLoadingIndicator());
  dispatch(clearErrorMessage());
  dispatch(hideAccessDenied());
  dispatch(fetchDeviceTypesRequest());

  return DeviceTypeClient.getDeviceTypesBySoftwareVersion(authToken, namespace, name, softwareVersion)
    .then((response: GetDeviceTypesBySoftwareVersionResponse) => {

      if (softwareVersion !== SoftwareVersionDetailsSelectors.getSoftwareVersion(getState())) {
        return Promise.resolve();
      }

      const { deviceTypeVersions = [] } = response;

      const validDeviceTypeVersions = deviceTypeVersions
        .map((data: GetSoftwareVersionsResponse) => {

          const { deviceType: { nameAndVersion = "" } = { nameAndVersion: "" } } = data;

          return getStringValue(nameAndVersion);
        })
        .filter((deviceTypeId: string) => deviceTypeId.length > 0);

      dispatch(fetchDeviceTypesSuccess());
      dispatch(setDeviceTypeVersions(validDeviceTypeVersions));
      return dispatch(hideLoadingIndicator());

    }, (response: RestClientError) => {

      if (softwareVersion !== SoftwareVersionDetailsSelectors.getSoftwareVersion(getState())) {
        return Promise.resolve();
      }

      const { analytic, error = "Fetch device types by software version failed" } = response;

      dispatch(fetchDeviceTypesFailed(analytic));
      dispatch(setErrorMessage(error));

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideLoadingIndicator());
    });
};

export const reset = () => (dispatch: any) => {
  dispatch(setNamespace());
  dispatch(setName());
  dispatch(setSoftwareVersion());
  dispatch(setDeviceTypeVersions());
  dispatch(setErrorMessage());
  dispatch(setShowAccessDenied());
  return dispatch(setShowLoadingIndicator());
};

export const loadDeviceTypes = (namespace: string, name: string, softwareVersion: string) =>
  (dispatch: any) => {

    dispatch(reset());
    dispatch(setNamespace(namespace));
    dispatch(setName(name));
    dispatch(setSoftwareVersion(softwareVersion));
    return dispatch(fetchDeviceTypes());
  };
