import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base";

export interface NamespacesSchema extends BasePortalModuleSchema {
  nameFilter: string;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<NamespacesSchema>({
  showEmptyView: false,
  nameFilter: "",
});

type NamespacesActionTypes = PortalModuleActionTypes<NamespacesSchema>;

export const ACTION_TYPES: NamespacesActionTypes =
  createPortalModuleActionTypes<NamespacesSchema>(MODULE_ID, DEFAULT_STATE);

export const NamespacesReducer = combineReducers<NamespacesSchema>(
  createPortalModuleReducers<NamespacesSchema>(ACTION_TYPES, DEFAULT_STATE));

export default NamespacesReducer;
