import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ConfirmationDialog, JsonView } from "@components";
import { styles } from "./styles";
import Header from "@components/schema-validation-view/Header";
import { isEmptyString } from "@util";

export const JsonDialog = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string;
  open?: boolean;
  title?: string;
  jsonTitle?: string;
  errorMessage?: string;
  schema?: string;
  fileName?: string;
  cancel?: () => void;
}) => {

  const {
    classes,
    className,
    open = false,
    title = "Schema JSON {}",
    jsonTitle = "Schema",
    errorMessage,
    cancel,
    schema,
    fileName,
    ...remainingProps
  } = props;

  if (!open) {
    return null;
  }

  return (
    <ConfirmationDialog
      {...remainingProps}
      className={classnames("jsonDialog", className)}
      open={open}
      hideContinueButton={true}
      cancelButtonLabel="Close"
      title={title}
      errorMessage={errorMessage}
      cancel={cancel}
    >
      {isEmptyString(errorMessage) && (
        <div className={classnames("contents")} >
          <Header
            className={classes.header}
            title={jsonTitle}
          />
          <JsonView
            className={classnames("schemaJsonViewer", classes.jsonViewer)}
            json={schema}
            fileName={fileName}
            fontSize={12}
            readOnly={true}
            highlightActiveLine={false}
            aceOptions={{
              highlightGutterLine: false,
            }}
          />
        </div>
      )}
    </ConfirmationDialog>
  );
});

export default JsonDialog;
