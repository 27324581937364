import React from "react";
import { isEmptyString } from "@util";
import { Redirect, Route, Switch } from "react-router-dom";

interface WithLegacyPathOptions {
  legacyPath?: string | string[];
  path?: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
}

export const withLegacyPath = <T, >(options: WithLegacyPathOptions) =>
  (Component: React.ComponentType<T>) => (props: T) => {

    const {
      legacyPath = "",
      path = "",
      exact,
      sensitive,
      strict,
    } = options;

    const redirectTo = Array.isArray(path) ? path[0] : path;

    if (isEmptyString(redirectTo)) {
      return <Component {...props} />;
    }

    if (!Array.isArray(legacyPath) && isEmptyString(legacyPath)) {
      return <Component {...props} />;
    }

    if (Array.isArray(legacyPath) && legacyPath.filter(p => !isEmptyString(p)).length === 0) {
      return <Component {...props} />;
    }

    return (
      <Switch>
        <Route
          path={legacyPath}
          exact={exact}
          sensitive={sensitive}
          strict={strict}
          render={routeProps => {

            const { location, match: { path: matchedPath, params } } = routeProps;

            const keys = Object.keys(params);

            const allKeysMatch = keys.every(key => redirectTo.indexOf(`:${key}`) >= 0);

            if (keys.length === 0 || !allKeysMatch) {
              return (
                <Redirect
                  from={matchedPath}
                  to={{
                    ...location,
                    pathname: redirectTo,
                  }}
                />
              );
            }

            const redirectToWithParams = redirectTo
              .split("/")
              .map(uri => uri.indexOf(":") !== 0 ? uri : params[uri.substring(1)] || uri)
              .join("/");

            return (
              <Redirect
                from={matchedPath}
                to={{
                  ...location,
                  pathname: redirectToWithParams,
                }}
              />
            );
          }}
        />
        <Route>
          <Component {...props} />
        </Route>
      </Switch>
    );
  };

export default withLegacyPath;
