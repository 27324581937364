import React from "react";
import { useHistory } from "react-router-dom";
import { EmailTemplate } from "@data/EmailTemplate";
import { getPathToEmailTemplate } from "@modules/emailTemplateDetails/helpers";
import {
  getCreateEmailTemplatePath,
  getEditEmailTemplatePath,
} from "@modules/emailTemplateWizard/helpers";
import EmailTemplates from "./containers/EmailTemplates";

export function EmailTemplatesView() {

  const history = useHistory();

  const createEmailTemplate = React.useCallback(() =>
    history.push(getCreateEmailTemplatePath()), [history]);

  const editEmailTemplate = React.useCallback((emailTemplate: EmailTemplate) =>
    history.push(getEditEmailTemplatePath(emailTemplate.getId())), [history]);

  const showEmailTemplateDetails = React.useCallback((emailTemplate: EmailTemplate) =>
    history.push(getPathToEmailTemplate(emailTemplate.getId())), [history]);

  return (
    <EmailTemplates
      createEmailTemplate={createEmailTemplate}
      editEmailTemplate={editEmailTemplate}
      showEmailTemplateDetails={showEmailTemplateDetails}
    />
  );
}

export default EmailTemplatesView;
