import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { isGroupsView, isReviewView } from "../selectors";
import SelectSecurityGroup from "./SelectSecurityGroup";
import ReviewView from "./ReviewView";
import { Model, SelectSecurityGroupsView } from "../components/SelectSecurityGroupsView";

interface ContainerModel extends Model {
  showSecurityGroupsView?: boolean;
  showReviewView?: boolean;
}

const SelectSecurityGroupViewContainer = (props: ContainerModel) => {
  const {
    showSecurityGroupsView,
    showReviewView,
    ...otherProps
  } = props;

  return (
    <SelectSecurityGroupsView {...otherProps}>
      {showSecurityGroupsView && <SelectSecurityGroup/>}
      {showReviewView && <ReviewView/>}
    </SelectSecurityGroupsView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  showReviewView: isReviewView(state),
  showSecurityGroupsView: isGroupsView(state),
});

export default connect<ContainerModel, {}, ContainerModel>(
  mapStateToProps
)(SelectSecurityGroupViewContainer);
