import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base/createReducers";

export interface DataAccessRequestsSchema extends BasePortalModuleSchema {
  nameFilter: string;
  filterByCurrentUser: boolean;
  filterByCurrentAccount: boolean;
  filterByPendingApproval: boolean;
  reloadItemsRequested: boolean;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<DataAccessRequestsSchema>({
  showEmptyView: false,
  nameFilter: "",
  filterByCurrentUser: true,
  filterByCurrentAccount: false,
  filterByPendingApproval: false,
  reloadItemsRequested: false,
});

type DataAccessRequestsActionTypes = PortalModuleActionTypes<DataAccessRequestsSchema>;

export const ACTION_TYPES: DataAccessRequestsActionTypes =
  createPortalModuleActionTypes<DataAccessRequestsSchema>(MODULE_ID, DEFAULT_STATE);

export const DataAccessRequestsReducer = combineReducers<DataAccessRequestsSchema>(
  createPortalModuleReducers<DataAccessRequestsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DataAccessRequestsReducer;
