import React from "react";
import { clickHandler, noop } from "@util";
import classnames from "classnames";
import { optionsView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { WizardType } from "../reducers";
import Switch from "@material-ui/core/Switch";
import { ErrorView, Alert, AlertTitle } from "@components";

export interface Model {
  title?: string;
  wizardType?: WizardType;
  showInvalidJsonError?: boolean;
}

export interface Actions {
  setVisualEditorType?: () => void;
  setJsonType?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const WizardTypeOptionView = withStyles(styles)((props: Props) => {

  const {
    classes,
    showInvalidJsonError,
    title = "Select Wizard type",
    wizardType = WizardType.VISUAL_EDITOR,
    setVisualEditorType = noop,
    setJsonType = noop,
    children,
  } = props;

  if (showInvalidJsonError) {
    return (
      <div className={classnames("createTypeOptionsView", classes.errorViewContainer)}>
        <ErrorView
          title="JSON Syntax Error"
          message="Visual editors cannot be used until you have fixed the JSON syntax error."
        />
      </div>
    );
  }

  const visualEditorSelected = React.useMemo(() =>
    WizardType.VISUAL_EDITOR === wizardType,
    [wizardType]);

  const onChange = React.useCallback(() => {
    if (visualEditorSelected) {
      setJsonType();
    } else {
      setVisualEditorType();
    }
  }, [visualEditorSelected, setJsonType, setVisualEditorType]);

  return (
    <div className={classnames("wizardTypeOptionsView", classes.container)}>
      <React.Fragment>
        <label className={classnames("title", classes.title)}>{title}</label>
      </React.Fragment>
      <div className={classnames("typeSwitch", classes.switchContainer)}>
        <label
          className={classnames("jsonLabel", classes.label)}
          onClick={clickHandler(() => setJsonType())}
        >
          JSON
        </label>
        <Switch
          className={classnames("switch", classes.switch)}
          color="primary"
          checked={visualEditorSelected}
          onChange={onChange}
        />
        <label
          className={classnames("visualEditorLabel", classes.label)}
          onClick={clickHandler(() => setVisualEditorType())}
        >
          Visual Editor
        </label>
        {children}
      </div>
        <React.Fragment>
          <Alert severity="info" className={classnames("visualEditorInfo", classes.alert)}>
            <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
              <strong>
                Note
              </strong>
            </AlertTitle>
            <p className={classnames("alertDescription", classes.alertDescription)}>
              Select JSON if you have payload ready to create a shadow layout <br/>
              Select Visual Editor if you want to follow the wizard to create a shadow layout <br/>
              Click Next Step to proceed.
            </p>
          </Alert>
        </React.Fragment>
      {children}
    </div>
  );
});

export default WizardTypeOptionView;
