import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { resultsView as styles } from "./styles";
import { Results } from "./Results";
import { Header } from "./Header";

export interface Model {
  className?: string;
  message?: string;
  showErrorMessage?: boolean;
  showSuccessMessage?: boolean;
  showLoadingIndicator?: boolean;
}

type Props = WithStyles<typeof styles> & Model;

export const ResultsView = withStyles(styles)((props: Props) => {

  const { classes, className, message, showErrorMessage, showSuccessMessage, showLoadingIndicator } = props;

  return (
    <div className={classnames("resultsView", className, classes.container)}>
      <Header
        className={classes.header}
        title="Validation Results"
        showErrorMessage={showErrorMessage}
        showSuccessMessage={showSuccessMessage}
        showLoadingIndicator={showLoadingIndicator}
      />
      <Results
        className={classes.results}
        message={message}
        showErrorMessage={showErrorMessage}
        showSuccessMessage={showSuccessMessage}
      />
    </div>
  );
});

export default ResultsView;
