import { equalsIgnoreCase } from "@util";
import StorageClient from "@util/LocalStorageClient";

const WHITELISTED_ACCOUNTS = process.env.REACT_APP_WHITELISTED_ACCOUNTS || "[]";

const KEY_SAVED_USERNAME = "com.signify.sst.portal.store.SAVED_USERNAME";
const KEY_SAVED_ACCOUNT = "com.signify.sst.portal.store.SAVED_ACCOUNT";

const KEY_SAVED_SERVICE_ID = "com.signify.sst.portal.store.SAVED_SERVICE_ID";
const KEY_SAVED_SERVICE_ACCOUNT_ID = "com.signify.sst.portal.store.SAVED_SERVICE_ACCOUNT_ID";

export const getWhitelistedAccounts = (): string[] => {
  try {
    const whitelistedAccounts = JSON.parse(WHITELISTED_ACCOUNTS);
    return Array.isArray(whitelistedAccounts) ? whitelistedAccounts : [];
  } catch {
    return [];
  }
};

export const isFederatedLoginRequired = (): boolean =>
  equalsIgnoreCase("true", process.env.REACT_APP_FEDERATED_LOGIN_REQUIRED);

export const formatFederatedLoginErrorMessage = (error: string = "Login Failed") => {
  if (equalsIgnoreCase(error, "not_found")) {
    return "ERROR: We could not find a user in that IoT account with that email address.\n" +
      "Please contact your IoT account owner for more help.";
  } else {
    return error;
  }
};

export const saveUsername = (username: string) => {
  StorageClient.setItem(KEY_SAVED_USERNAME, username);
};

export const getSavedUsername = () => {
  return StorageClient.getItem(KEY_SAVED_USERNAME, "");
};

export const saveAccount = (account: string) => {
  StorageClient.setItem(KEY_SAVED_ACCOUNT, account);
};

export const getSavedAccount = () => {
  return StorageClient.getItem(KEY_SAVED_ACCOUNT, "");
};

export const saveServiceId = (serviceId: string) => {
  StorageClient.setItem(KEY_SAVED_SERVICE_ID, serviceId);
};

export const getSavedServiceId = () => {
  return StorageClient.getItem(KEY_SAVED_SERVICE_ID, "");
};

export const saveServiceAccountId = (serviceAccountId: string) => {
  StorageClient.setItem(KEY_SAVED_SERVICE_ACCOUNT_ID, serviceAccountId);
};

export const getSavedServiceAccountId = () => {
  return StorageClient.getItem(KEY_SAVED_SERVICE_ACCOUNT_ID, "");
};

export const redirectToUrl = (url: string) => {
  window.location.href = url;
};
