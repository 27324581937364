import React from "react";
import { TriggerType, UpdateTriggerRequest } from "@data";
import { UpdateWorkloadTriggerResponse } from "@network";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useWorkloadManagerClient
} from "@hooks";

type SuccessResponse = UpdateWorkloadTriggerResponse;

export interface UseUpdateWorkloadTriggerProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  trigger: UpdateTriggerRequest;
}

export interface UseUpdateWorkloadTriggerModel extends UseApiRequestModel<SuccessResponse> {
  eventBindingId: string;
  successMessage: string;
}

export interface UseUpdateWorkloadTriggerActions extends UseApiRequestActions<SuccessResponse> {
  updateTrigger: () => void;
}

type Props = UseUpdateWorkloadTriggerProps;
type Model = UseUpdateWorkloadTriggerModel;
type Actions = UseUpdateWorkloadTriggerActions;
type Result = [Model, Actions];

export const useUpdateWorkloadTrigger = (props: Props): Result => {

  const {
    name,
    version,
    trigger,
    defaultErrorMessage = "Failed to update workload trigger",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const triggerInfo = trigger.toJS();
  const eventType = trigger.getType();

  delete triggerInfo.rateValue;
  delete triggerInfo.rateUnit;
  delete triggerInfo.scheduleType;

  if (eventType !== TriggerType.SCHEDULE) {
    delete triggerInfo.scheduleExpression;
  }

  if (eventType !== TriggerType.DATA_LAKE) {
    delete triggerInfo.dataSetAlias;
  }

  if (eventType !== TriggerType.WORKLOAD_COMPLETE) {
    delete triggerInfo.precedingWorkloadName;
    delete triggerInfo.completionState;
  }

  if (eventType !== TriggerType.WORKLOAD_COMPLETE && eventType !== TriggerType.DATA_LAKE) {
    delete triggerInfo.accountId;
  }

  const makeApiRequest = React.useCallback(() =>
      WorkloadManagerClient.updateWorkloadTriggerApi(name, version, { ...triggerInfo}),
    [WorkloadManagerClient, name, version, triggerInfo]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: updateTrigger, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
    showSuccessView ? "Trigger Updated" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    showSuccessView,
    successMessage,
    eventBindingId: successResponse?.eventBindingId || "",
  }), [
    baseModel,
    showSuccessView,
    successMessage,
    successResponse,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    refresh: updateTrigger,
    updateTrigger,
    reset,
  }), [updateTrigger, reset]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useUpdateWorkloadTrigger;
