import { combineReducers } from "redux";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base/createReducers";
import { MODULE_ID } from "./constants";

export interface AuditEventsSchema extends BasePortalModuleSchema {
  availableAccountIds: string[];
  availableOrigins: string[];
  availableTypes: string[];
  availableSeverities: string[];
  availablePrincipals: string[];
  availableRequestIds: string[];
}

export const DEFAULT_STATE: AuditEventsSchema = createPortalModuleDefaultState<AuditEventsSchema>({
  showEmptyView: false,
  availableAccountIds: [],
  availableOrigins: [],
  availableTypes: [],
  availableSeverities: [],
  availablePrincipals: [],
  availableRequestIds: [],
});

type AuditActionTypes = PortalModuleActionTypes<AuditEventsSchema> & {
  FIND_EVENTS_REQUEST: string;
  FIND_EVENTS_SUCCESS: string;
  FIND_EVENTS_FAILURE: string;
};

export const ACTION_TYPES: AuditActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<AuditEventsSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    FIND_EVENTS_REQUEST: `${MODULE_ID}/find_events_request`,
    FIND_EVENTS_SUCCESS: `${MODULE_ID}/find_events_success`,
    FIND_EVENTS_FAILURE: `${MODULE_ID}/find_events_failure`,
  },
});

export const AuditReducer = combineReducers<AuditEventsSchema>(
  createPortalModuleReducers<AuditEventsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default AuditReducer;
