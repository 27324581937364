import React from "react";
import classnames from "classnames";
import { clickHandler } from "@util";
import { Button, ErrorView } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { schemaEditorView as styles } from "./styles";

export interface Model {
  summaryEditorSelected?: boolean;
  jsonEditorSelected?: boolean;
  summaryEditorLabel?: string;
  jsonEditorLabel?: string;
  errorMessage?: string;
  children?: React.ReactNode;
}

export interface Actions {
  showSummaryEditor?: () => void;
  showJsonEditor?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SchemaEditorView = withStyles(styles)((props: Props) => {

  const {
    classes,
    summaryEditorSelected,
    jsonEditorSelected,
    summaryEditorLabel = "Summary",
    jsonEditorLabel = "{} JSON",
    errorMessage,
    children,
    showSummaryEditor,
    showJsonEditor,
  } = props;

  return (
    <div className={classnames("editorView", classes.container)}>
      <label className={classnames("title", classes.title)}>Schema Editor</label>
      <ErrorView className={classes.error} title="Schema Validation Error" message={errorMessage} />
      <div className={classnames("controls", classes.controls)}>
        <Button
          className={classnames("summaryEditorButton", classes.summaryEditorButton, {
            selected: summaryEditorSelected,
          })}
          color={summaryEditorSelected ? "primary" : "inherit"}
          onClick={clickHandler(showSummaryEditor)}
          variant="contained"
        >
          {summaryEditorLabel}
        </Button>
        <Button
          className={classnames("jsonEditorButton", classes.jsonEditorButton, {
            selected: jsonEditorSelected,
          })}
          color={jsonEditorSelected ? "primary" : "inherit"}
          onClick={clickHandler(showJsonEditor)}
          variant="contained"
        >
          {jsonEditorLabel}
        </Button>
      </div>
      {children}
    </div>
  );
});

export default SchemaEditorView;
