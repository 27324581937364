import React from "react";
import { noop } from "@util";
import styles from "./styles";
import classnames from "classnames";
import WorkloadVariableSchema from "@data/WorkloadVariableSchema";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  ActionsListItemData,
  WorkloadCompletionMetadata,
  WorkloadAddActionLabelDialog,
  WorkloadDeleteActionLabelDialog,
  WorkloadAddVariablesDialog,
  WorkloadDeleteVariableDialog,
  WorkloadEditVariableDialog,
  VariablesListColumn,
  VariablesListItem,
} from "@components";

export interface CompletionMetadataViewModel {
  workloadName: string;
  version: number;
  etag: string;
  actions?: string[];
  isDraftWorkload?: boolean;
  showLoadingIndicator?: boolean;
  variables?: WorkloadVariableSchema[];
}

export interface CompletionMetadataViewActions {
  refreshDetails?: () => void;
}

type Props = WithStyles<typeof styles> & CompletionMetadataViewModel & CompletionMetadataViewActions & {
  children?: React.ReactNode;
};

export const CompletionMetadataView = withStyles(styles)((props: Props) => {

  const {
    classes,
    workloadName,
    version,
    etag,
    actions = [],
    variables = [],
    isDraftWorkload,
    showLoadingIndicator,
    refreshDetails = noop,
  } = props;

  const [showAddActionLabelDialog, setShowAddActionLabelDialog] = React.useState(false);
  const [showDeleteActionLabelDialog, setShowDeleteActionLabelDialog] = React.useState(false);
  const [showDeleteVariableDialog, setShowDeleteVariableDialog] = React.useState(false);
  const [openVariableDialog, setOpenVariableDialog] = React.useState(false);

  const onAddActionLabelSuccess = React.useCallback(() => {
    setShowAddActionLabelDialog(false);
    refreshDetails();
  }, [setShowAddActionLabelDialog, refreshDetails]);

  const onVariableSuccess = React.useCallback(() => {
    setOpenVariableDialog(false);
    refreshDetails();
  }, [setOpenVariableDialog, refreshDetails]);

  const [actionLabel, setActionLabel] = React.useState("");

  const deleteAction = React.useCallback((label: ActionsListItemData) => {
    setActionLabel(label.getName());
    setShowDeleteActionLabelDialog(true);
  }, [setActionLabel, setShowDeleteActionLabelDialog]);

  const onDeleteActionLabelSuccess = React.useCallback(() => {
    setShowDeleteActionLabelDialog(false);
    refreshDetails();
  }, [setShowDeleteActionLabelDialog, refreshDetails]);

  const [variable, setVariable] = React.useState<WorkloadVariableSchema>(WorkloadVariableSchema.EMPTY);
  const [showEditVariableDialog, setShowEditVariableDialog] = React.useState(false);

  const editVariable = React.useCallback((item: WorkloadVariableSchema) => {
    setVariable(item);
    setShowEditVariableDialog(true);
  }, [setVariable, setShowEditVariableDialog]);

  const renderVariable = React.useCallback((item: WorkloadVariableSchema, column: VariablesListColumn) => (
    <VariablesListItem
      item={item}
      column={column}
      customEdit={editVariable}
    />
  ), [editVariable]);

  const deleteVariable = React.useCallback((item: WorkloadVariableSchema) => {
    setVariable(item);
    setShowDeleteVariableDialog(true);
  }, [setVariable, setShowDeleteVariableDialog]);

  const onDeleteVariableSuccess = React.useCallback(() => {
    setShowDeleteVariableDialog(false);
    refreshDetails();
  }, [setShowDeleteVariableDialog, refreshDetails]);

  return (
    <React.Fragment>
      <WorkloadCompletionMetadata
        className={classnames("completionMetadata", classes.completionMetadata)}
        title={""}
        actions={actions}
        variables={variables}
        showEditActions={false}
        showAddActions={isDraftWorkload}
        showDeleteActions={isDraftWorkload}
        showDeleteVariables={isDraftWorkload}
        showAddVariables={isDraftWorkload}
        showEditVariables={isDraftWorkload}
        showLoadingIndicator={showLoadingIndicator}
        onClickAddAction={() => setShowAddActionLabelDialog(true)}
        customDeleteAction={deleteAction}
        customDeleteVariable={deleteVariable}
        customRenderVariable={renderVariable}
        onClickAddVariable={() => setOpenVariableDialog(true)}
      />
      <WorkloadAddActionLabelDialog
        name={workloadName}
        version={version}
        etag={etag}
        open={showAddActionLabelDialog}
        cancel={() => setShowAddActionLabelDialog(false)}
        onSuccessMessageShown={onAddActionLabelSuccess}
      />
      <WorkloadAddVariablesDialog
        name={workloadName}
        version={version}
        etag={etag}
        open={openVariableDialog}
        cancel={() => setOpenVariableDialog(false)}
        onSuccessMessageShown={onVariableSuccess}
      />
      <WorkloadEditVariableDialog
        name={workloadName}
        version={version}
        etag={etag}
        variable={variable}
        open={showEditVariableDialog}
        cancel={() => setShowEditVariableDialog(false)}
        onSuccessMessageShown={onVariableSuccess}
      />
      <WorkloadDeleteActionLabelDialog
        name={workloadName}
        version={version}
        etag={etag}
        actionLabel={actionLabel}
        open={showDeleteActionLabelDialog}
        cancel={() => setShowDeleteActionLabelDialog(false)}
        onSuccessMessageShown={onDeleteActionLabelSuccess}
      />
      <WorkloadDeleteVariableDialog
        name={workloadName}
        version={version}
        etag={etag}
        variable={variable.getName()}
        open={showDeleteVariableDialog}
        cancel={() => setShowDeleteVariableDialog(false)}
        onSuccessMessageShown={onDeleteVariableSuccess}
      />
    </React.Fragment>
  );
});

export default CompletionMetadataView;
