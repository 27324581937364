import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import DataSetsFilter from "./DataSetsFilter";
import AppliedFilters from "../applied-filters";
import { DataSetType, SearchFilter } from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DataSetsList, {
  DataSetsListActions,
  DataSetsListModel,
  mapDataSetTypeToOptionName,
} from "../data-sets-list";
import DataSetsFiltersMenu, {
  DataSetsFiltersMenuActions,
  DataSetsFiltersMenuModel,
} from "../data-sets-filters-menu";
import styles from "./styles";

export interface DataSetsModel extends DataSetsListModel, DataSetsFiltersMenuModel {
  className?: string;
  listClassName?: string;
  dataSetTypeFilter?: DataSetType;
  showDataSetTypeFilter?: boolean;
  currentAccountFilterEnabled?: boolean;
  showCurrentAccountFilterEnabled?: boolean;
  accountFilter?: string;
  showAccountFilter?: boolean;
  groupFilter?: string;
  showGroupFilter?: boolean;
}

export interface DataSetsActions extends DataSetsListActions, DataSetsFiltersMenuActions {
  createDataSetRequest?: () => void;
  setDataSetTypeFilter?: (type: DataSetType) => void;
  setCurrentAccountFilterEnabled?: (enabled: boolean) => void;
  setAccountFilter?: (value: string) => void;
  setGroupFilter?: (value: string) => void;
  mapSearchFilterToLabel?: (searchFilter: SearchFilter) => React.ReactNode;
}

type Props = WithStyles<typeof styles> & DataSetsModel & DataSetsActions & {
  children?: React.ReactNode;
};

export const DataSets = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    listClassName,
    showSummary = true,
    showSearch = true,
    searchViewHint = "Filter by data set alias",
    summaryViewLabel = "Data Sets",
    createButtonLabel = "Create Data Set",
    createButtonClassName = "createDataSetRequestButton",
    showLoadingIndicator,
    dataSetTypeFilter = DataSetType.NONE,
    showAccountFilterButton,
    currentAccountFilterEnabled = true,
    showAccountFilter,
    accountFilter = "",
    showGroupFilter,
    groupFilter = "",
    createDataSetRequest,
    onClickCreateButton = createDataSetRequest,
    setDataSetTypeFilter = noop,
    setCurrentAccountFilterEnabled = noop,
    setAccountFilter = noop,
    setGroupFilter = noop,
    mapSearchFilterToLabel,
    children,
    ...otherProps
  } = props;

  const [showFiltersMenu, setShowFiltersMenu] = React.useState(false);

  const searchFilters = React.useMemo<SearchFilter[]>(() => ([] as SearchFilter[])
    .concat(!currentAccountFilterEnabled && isEmptyString(accountFilter) ? [new SearchFilter({
      key: DataSetsFilter.ALL_ACCOUNTS,
    })] : [])
    .concat(!currentAccountFilterEnabled && !isEmptyString(accountFilter)
      ? ([new SearchFilter({
        key: DataSetsFilter.ACCOUNT,
        value: accountFilter,
      })]) : [])
    .concat(!isEmptyString(groupFilter)
      ? ([new SearchFilter({
        key: DataSetsFilter.GROUP,
        value: groupFilter,
      })]) : [])
    .concat(dataSetTypeFilter !== DataSetType.NONE ? [new SearchFilter({
      key: DataSetsFilter.DATA_SET_TYPE,
      value: mapDataSetTypeToOptionName(dataSetTypeFilter),
    })] : []), [
    dataSetTypeFilter,
    currentAccountFilterEnabled,
    accountFilter,
    groupFilter,
  ]);

  const badgeCount = React.useMemo(() => searchFilters.length, [searchFilters]);

  // Show a custom no results label if there are no active search filters, which implies that the
  // active account actually has no data sets; however if search filters are applied, we can defer
  // to using the default value provided by the data sets list component since we now render a list
  // of all applied search filters, so the user will understand that there are no results matching
  // the active filters
  const noResultsLabel = React.useMemo(() =>
      currentAccountFilterEnabled && searchFilters.length === 0
        ? "This IoT account contains no data sets"
        : undefined,
    [currentAccountFilterEnabled, searchFilters]);

  const openMenu = React.useCallback(() => setShowFiltersMenu(true), [setShowFiltersMenu]);

  const closeMenu = React.useCallback(() => setShowFiltersMenu(false), [setShowFiltersMenu]);

  const clearFilters = React.useCallback(() => {
    setDataSetTypeFilter(DataSetType.NONE);
    setCurrentAccountFilterEnabled(true);
    setAccountFilter("");
    setGroupFilter("");
  }, [
    setDataSetTypeFilter,
    setCurrentAccountFilterEnabled,
    setAccountFilter,
    setGroupFilter,
  ]);

  const setSearchFilters = React.useCallback((updatedSearchFilters: SearchFilter[]) =>
    searchFilters
      .filter(it => !updatedSearchFilters.some(it2 => it.equals(it2)))
      .map(it => it.getKey())
      .forEach(type => {
        switch (type) {
          case DataSetsFilter.DATA_SET_TYPE:
            setDataSetTypeFilter(DataSetType.NONE);
            break;
          case DataSetsFilter.ALL_ACCOUNTS:
            setCurrentAccountFilterEnabled(true);
            break;
          case DataSetsFilter.GROUP:
            setGroupFilter("");
            break;
          case DataSetsFilter.ACCOUNT:
            setAccountFilter("");
            break;
          default:
            break;
        }
      }), [
    searchFilters,
    setDataSetTypeFilter,
    setCurrentAccountFilterEnabled,
    setAccountFilter,
    setGroupFilter,
  ]);

  return (
    <React.Fragment>
      <DataSetsList
        {...otherProps}
        className={classnames("dataSets", className, classes.container)}
        listClassName={classnames("list", listClassName, classes.list)}
        showSummary={showSummary}
        showSearch={showSearch}
        searchViewHint={searchViewHint}
        noResultsLabel={noResultsLabel}
        summaryViewLabel={summaryViewLabel}
        createButtonLabel={createButtonLabel}
        createButtonClassName={createButtonClassName}
        showLoadingIndicator={showLoadingIndicator}
        summaryViewFiltersMenu={(
          <DataSetsFiltersMenu
            {...otherProps}
            className={classnames("filtersMenu", classes.filtersMenu)}
            dataSetTypeFilter={dataSetTypeFilter}
            showAccountFilterButton={showAccountFilterButton}
            currentAccountFilterEnabled={currentAccountFilterEnabled}
            showAccountFilter={showAccountFilter}
            accountFilter={accountFilter}
            showGroupFilter={showGroupFilter}
            groupFilter={groupFilter}
            badgeCount={badgeCount}
            disabled={showLoadingIndicator}
            clearButtonDisabled={badgeCount === 0}
            open={showFiltersMenu}
            openMenu={openMenu}
            closeMenu={closeMenu}
            setDataSetTypeFilter={setDataSetTypeFilter}
            setCurrentAccountFilterEnabled={setCurrentAccountFilterEnabled}
            setAccountFilter={setAccountFilter}
            setGroupFilter={setGroupFilter}
            clearFilters={clearFilters}
          />
        )}
        header={(
          <AppliedFilters
            className={classnames("appliedFilters", classes.appliedFilters)}
            disabled={showLoadingIndicator}
            searchFilters={searchFilters}
            mapSearchFilterToLabel={mapSearchFilterToLabel}
            setSearchFilters={setSearchFilters}
            onClickSearchFilter={openMenu}
            clearFilters={clearFilters}
          />
        )}
        onClickCreateButton={onClickCreateButton}
      />
      {children}
    </React.Fragment>
  );
});

export default DataSets;
