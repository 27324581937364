import { combineReducers } from "redux";
import { Actions, filterAction, Identity, ReduxAction } from "../../../util/ReducerUtil";
import {
  JsonSchemaPropertyAttributes,
  JsonSchemaProperty,
} from "../../../data";

export interface RemovePropertySchema {
  property: JsonSchemaPropertyAttributes;
}

export const DEFAULT_STATE: RemovePropertySchema = Object.assign({
  property: JsonSchemaProperty.EMPTY.toJS(),
});

export enum RemovePropertyActionType {
  SET_PROPERTY = "schema_wizard/remove_property/set_property",
}

export type RemovePropertyAction = ReduxAction<RemovePropertyActionType>;

const property = filterAction<JsonSchemaPropertyAttributes>(Actions(
  RemovePropertyActionType.SET_PROPERTY))(Identity, DEFAULT_STATE.property);

export const RemovePropertyReducer = combineReducers<RemovePropertySchema>({
  property,
});

export default RemovePropertyReducer;
