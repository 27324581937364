import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import LockIcon from "@material-ui/icons/Lock";
import { apiVersionView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Alert, AlertTitle, AlertDialog, ErrorView, ToggleDtsModelVersionSwitch } from "@components";
import DeviceTypeModelVersion from "@data/DeviceTypeModelVersion";

export interface Model {
  className?: string;
  locked?: boolean;
  title?: string;
  modelVersion?: DeviceTypeModelVersion;
}

export interface Actions {
  setModelVersion?: (modelVersion: DeviceTypeModelVersion) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ApiVersionView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    locked,
    title = "Select Device Type API Version",
    modelVersion = DeviceTypeModelVersion.REGIONAL,
    setModelVersion = noop,
    children,
  } = props;

  const [showApiVersionLockedError, setShowApiVersionLockedError] = React.useState(false);

  const regionalApiSelected = React.useMemo(() =>
    DeviceTypeModelVersion.REGIONAL === modelVersion,
    [modelVersion]);

  const updateModelVersion = React.useCallback(updatedModelVersion => {
    if (locked) {
      setShowApiVersionLockedError(true);
    } else {
      setModelVersion(updatedModelVersion);
    }
  }, [locked, setShowApiVersionLockedError, setModelVersion]);

  const showRegionalApi = React.useCallback(() =>
    updateModelVersion(DeviceTypeModelVersion.REGIONAL), [updateModelVersion]);

  const showHistoricalApi = React.useCallback(() =>
    updateModelVersion(DeviceTypeModelVersion.HISTORICAL), [updateModelVersion]);

  return (
    <div className={classnames("apiVersionView", className, classes.container)}>
      {locked && (
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            {`${regionalApiSelected ? "Regional API Selected" : "Historical API Selected"}`}
          </label>
          <label className={classnames("subtitle", classes.subtitle)}>
            NOTE: You can only change the version before making edits to a device type.
          </label>
        </React.Fragment>
      )}
      {!locked && (
        <React.Fragment>
          <label className={classnames("title", classes.title)}>{title}</label>
          <label className={classnames("subtitle", classes.subtitle)}>
            The IoT Portal now supports creating device types using either the historical legacy API
            or the latest platform regional API. Please consider your decision carefully and keep
            the following in mind.
          </label>
        </React.Fragment>
      )}
      <ToggleDtsModelVersionSwitch
        color={locked ? "secondary" : "primary"}
        className={classnames("switch", classes.switch)}
        regionalApiSelected={regionalApiSelected}
        showRegionalApi={showRegionalApi}
        showHistoricalApi={showHistoricalApi}
      >
        {locked && (
          <LockIcon className={classnames("lockIcon", classes.lockIcon)} color="inherit" />
        )}
      </ToggleDtsModelVersionSwitch>
      {regionalApiSelected && (
        <React.Fragment>
          <Alert severity="info" className={classnames("regionalApiInfo", classes.alert)}>
            <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
              <strong>
                Compatibility Notes
              </strong>
            </AlertTitle>
            <p className={classnames("alertDescription", classes.alertDescription)}>
              By selecting the latest platform regional API, you can use all of the latest platform features
              and will soon be able to use the IoT Portal's graphical interface for enrolling devices that
              use this device type API version.
            </p>
            <p className={classnames("alertDescription", classes.alertDescription)}>
              Note that devices defined using the regional paradigm cannot be enrolled in the
              historical enrollment APIs.
            </p>
          </Alert>
        </React.Fragment>
      )}
      {!regionalApiSelected && (
        <React.Fragment>
          <Alert severity="info" className={classnames("historicalApiInfo", classes.alert)}>
            <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
              <strong>
                Compatibility Notes
              </strong>
            </AlertTitle>
            <p className={classnames("alertDescription", classes.alertDescription)}>
              This is an older paradigm that is provided for compatibility with
              pre-existing customer use cases.
            </p>
          </Alert>
          <Alert severity="warning" className={classnames("historicalApiWarning", classes.alert)}>
            <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
              <strong>
                WARNING
              </strong>
            </AlertTitle>
            <p className={classnames("alertDescription", classes.alertDescription)}>
              Device types defined using the historical syntax are missing several features
              when compared to the latest regional platform APIs.
              Please consider using the new regional paradigm.
            </p>
          </Alert>
        </React.Fragment>
      )}
      <AlertDialog
        open={showApiVersionLockedError}
        title="API Version Locked"
        close={() => setShowApiVersionLockedError(false)}
      >
        <ErrorView
          className={classnames("apiVersionLockedErrorDialog", classes.errorView)}
          title="Device Type API Version Locked"
          message="You are not allowed to change the API version once you begin editing the device type."
        />
      </AlertDialog>
      {children}
    </div>
  );
});

export default ApiVersionView;
