import { Policy } from "@data";
import { AppSchema } from "@schemas";
import { getAuthToken } from "@main/selectors";
import { getDefaultJson, getJson } from "./selectors";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { createActions } from "@base/createActions";
import { CreatePolicyResponse, PolicyClient, RestClientError } from "@network";

export const {
  json: setJson,
  policyRef: setPolicyRef,
  policyName: setPolicyName,
  setErrorMessage,
  setSuccessMessage,
  showLoadingIndicator,
  hideLoadingIndicator,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  CREATE_POLICY_REQUEST: createPolicyRequest,
  CREATE_POLICY_SUCCESS: createPolicySuccess,
  CREATE_POLICY_FAILED: createPolicyFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any, getState: () => AppSchema) => {
  dispatch(setJson(getDefaultJson(getState())));
  dispatch(setPolicyRef());
  dispatch(setPolicyName());
  return dispatch(baseReset());
};

export const updateJson = (json: string) => (dispatch: any) => {
  dispatch(setErrorMessage());
  return dispatch(setJson(json));
};

export const updatePolicy = (policy: Policy) => (dispatch: any) =>
  dispatch(updateJson(JSON.stringify(policy.toJS(), null, "  ")));

export const createPolicy = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const authToken = getAuthToken(state);
  const policy = getJson(state);

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setErrorMessage());
  dispatch(setSuccessMessage());
  dispatch(createPolicyRequest());

  return PolicyClient.createPolicy(authToken, policy)
    .then((response: CreatePolicyResponse) => {

      const { policyRef } = response;

      dispatch(createPolicySuccess());
      dispatch(setPolicyRef(policyRef));
      dispatch(hideLoadingIndicator());
      dispatch(setSuccessMessage("Policy Created"));
      return dispatch(hideEmptyView());

    }, (response: RestClientError) => {

      const { analytic, error = "Failed to create policy" } = response;

      dispatch(createPolicyFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(hideLoadingIndicator());

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideEmptyView());
    });
};

export const initialize = () => (dispatch: any, getState: () => AppSchema) => {

  const json = getJson(getState());

  dispatch(reset());
  return dispatch(setJson(json));
};
