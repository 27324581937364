import { Record } from "immutable";
import { getStringValue, isEmptyString } from "../util";

const containsValue = (key: string,
                       isKeyVerbatim: boolean,
                       value: string,
                       isValueVerbatim: boolean,
                       inverse: boolean = false) => {

  const keySelector =
    isKeyVerbatim ? `contains("${key}")` : `ascii_downcase | contains("${key.toLowerCase()}")`;

  const valueSelector =
    isValueVerbatim ? `contains("${value}")` : `ascii_downcase | contains("${value.toLowerCase()}")`;

  const selectResults = `select(length ${inverse ? "== 0" : "> 0"})`;

  const searchQuery = (`with_entries(select(.key | ${keySelector}))`) +
    (` | to_entries | map(select(.value | tostring | ${valueSelector})) | ${selectResults}`);

  return `(${searchQuery})`;
};

const doesNotContainValue = (key: string,
                             isKeyVerbatim: boolean,
                             value: string,
                             isValueVerbatim: boolean) => {

  return containsValue(key, isKeyVerbatim, value, isValueVerbatim, true);
};

const equalToValue = (key: string,
                      isKeyVerbatim: boolean,
                      value: string,
                      isValueVerbatim: boolean,
                      inverse: boolean = false) => {

  const keySelector =
    isKeyVerbatim ? `contains("${key}")` : `ascii_downcase | contains("${key.toLowerCase()}")`;

  const valueSelector =
    isValueVerbatim ? `. == "${value}"` : `ascii_downcase | . == "${value.toLowerCase()}"`;

  const selectResults = `select(length ${inverse ? "== 0" : "> 0"})`;

  const searchQuery = (`with_entries(select(.key | ${keySelector}))`) +
    (` | to_entries | map(select(.value | tostring | ${valueSelector})) | ${selectResults}`);

  return `(${searchQuery})`;
};

const notEqualToValue = (key: string,
                         isKeyVerbatim: boolean,
                         value: string,
                         isValueVerbatim: boolean) => {

  return equalToValue(key, isKeyVerbatim, value, isValueVerbatim, true);
};

export enum SearchFilterCondition {
  CONTAINS = "CONTAINS",
  DOES_NOT_CONTAIN = "DOES_NOT_CONTAIN",
  EQUALS = "EQUALS",
  DOES_NOT_EQUAL = "DOES_NOT_EQUAL",
}

export interface SearchFilterAttributes {
  key: string;
  isKeyVerbatim: boolean;
  value: string;
  isValueVerbatim: boolean;
  condition: SearchFilterCondition;
}

export class SearchFilter extends Record({
  key: "",
  isKeyVerbatim: true,
  value: "",
  isValueVerbatim: false,
  condition: SearchFilterCondition.CONTAINS,
}) implements SearchFilterAttributes {

  public static EMPTY: SearchFilter = new SearchFilter();

  public readonly key: string;
  public readonly isKeyVerbatim: boolean;
  public readonly value: string;
  public readonly isValueVerbatim: boolean;
  public readonly condition: SearchFilterCondition;

  public static createSearchQuery(filters: SearchFilter[]): string {

    if (!Array.isArray(filters) || filters.length === 0) {
      return "";
    }

    const searchQueries = Array.from(new Set(filters
      .map(filter => filter.getSearchQuery())
      .filter(searchQuery => !isEmptyString(searchQuery))));

    const combined = searchQueries.join(" and ");

    return searchQueries.length > 1 ? `(${combined})` : combined;
  }

  public getKey(): string {
    return getStringValue(this.key);
  }

  public hasKey(): boolean {
    return !isEmptyString(this.getKey());
  }

  public getValue(): string {
    return getStringValue(this.value);
  }

  public hasValue(): boolean {
    return !isEmptyString(this.getValue());
  }

  public getSearchQuery(): string {

    if (!this.hasKey() || !this.hasValue()) {
      return "";
    }

    const key = this.getKey();
    const value = this.getValue();
    const isKeyVerbatim = this.isKeyVerbatim;
    const isValueVerbatim = this.isValueVerbatim;
    const condition = this.condition;

    switch (condition) {
      case SearchFilterCondition.CONTAINS:
        return containsValue(key, isKeyVerbatim, value, isValueVerbatim);
      case SearchFilterCondition.DOES_NOT_CONTAIN:
        return doesNotContainValue(key, isKeyVerbatim, value, isValueVerbatim);
      case SearchFilterCondition.EQUALS:
        return equalToValue(key, isKeyVerbatim, value, isValueVerbatim);
      case SearchFilterCondition.DOES_NOT_EQUAL:
        return notEqualToValue(key, isKeyVerbatim, value, isValueVerbatim);
      default:
        return "";
    }
  }
}
