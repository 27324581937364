import { createSelector } from "reselect";
import {
  Policy,
  PolicyAttributes,
  PolicyStatement,
} from "@data";
import { DEFAULT_STATE, PolicyDetailsSchema } from "../reducers";
import { createSelectors, Selector } from "@modules/base/createSelectors";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "../constants";

export const {
  policy: getPolicyAttributes,
  nameFilter: getNameFilter,
  sortByColumn: getSortByColumn,
  sortOrderAscending: isSortOrderAscending,
  getErrorMessage,
  getSuccessMessage,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  isNotFoundVisible,
} = createSelectors<PolicyDetailsSchema>(moduleId, schemaKey, DEFAULT_STATE);

export const getPolicy: Selector<Policy> = createSelector(
  getPolicyAttributes, (attrs: PolicyAttributes) =>
    new Policy(attrs));

export const getJson: Selector<string> = createSelector(
  [isLoadingIndicatorVisible, getPolicy],
  (isLoading: boolean, policy: Policy) => {

    // If the page is loading, only show the bare minimum to avoid showing potentially stale data
    const data = isLoading ? DEFAULT_STATE.policy : policy.toJS();

    return JSON.stringify(data, null, "  ");
  });

export const getPolicyName: Selector<string> = createSelector(
  getPolicy, (policy: Policy) => policy.getName());

export const getPolicyDescription: Selector<string> = createSelector(
  getPolicy, (policy: Policy) => policy.getDescription());

export const getPolicyVersion: Selector<string> = createSelector(
  getPolicy, (policy: Policy) => policy.getVersion());

export const getPolicyPath: Selector<string> = createSelector(
  getPolicy, (policy: Policy) => policy.getPath());

export const getStatements: Selector<PolicyStatement[]> = createSelector(
  getPolicy, (policy: Policy) => policy.getStatements());
