import { combineReducers } from "redux";
import { Actions, filterAction, Identity, ReduxAction } from "../../../util/ReducerUtil";

export interface StringConstraintsSchema {
  minLength: string;
  minLengthError: string;
  maxLength: string;
  maxLengthError: string;
  pattern: string;
  patternError: string;
}

export const DEFAULT_STATE: StringConstraintsSchema = {
  minLength: "",
  minLengthError: "",
  maxLength: "",
  maxLengthError: "",
  pattern: "",
  patternError: "",
};

export enum StringConstraintsActionType {
  SET_MIN_LENGTH = "schema_wizard/string_constraints/set_min_length",
  SET_MIN_LENGTH_ERROR = "schema_wizard/string_constraints/set_min_length_error",
  SET_MAX_LENGTH = "schema_wizard/string_constraints/set_max_length",
  SET_MAX_LENGTH_ERROR = "schema_wizard/string_constraints/set_max_length_error",
  SET_PATTERN = "schema_wizard/string_constraints/set_pattern",
  SET_PATTERN_ERROR = "schema_wizard/string_constraints/set_pattern_error",
}

export type StringConstraintsAction = ReduxAction<StringConstraintsActionType>;

const minLength = filterAction<string>(Actions(
  StringConstraintsActionType.SET_MIN_LENGTH))(Identity,
  DEFAULT_STATE.minLength);

const minLengthError = filterAction<string>(Actions(
  StringConstraintsActionType.SET_MIN_LENGTH_ERROR))(Identity,
  DEFAULT_STATE.minLengthError);

const maxLength = filterAction<string>(Actions(
  StringConstraintsActionType.SET_MAX_LENGTH))(Identity,
  DEFAULT_STATE.maxLength);

const maxLengthError = filterAction<string>(Actions(
  StringConstraintsActionType.SET_MAX_LENGTH_ERROR))(Identity,
  DEFAULT_STATE.maxLengthError);

const pattern = filterAction<string>(Actions(
  StringConstraintsActionType.SET_PATTERN))(Identity,
  DEFAULT_STATE.pattern);

const patternError = filterAction<string>(Actions(
  StringConstraintsActionType.SET_PATTERN_ERROR))(Identity,
  DEFAULT_STATE.patternError);

export const StringConstraintsReducer = combineReducers<StringConstraintsSchema>({
  minLength,
  minLengthError,
  maxLength,
  maxLengthError,
  pattern,
  patternError,
});

export default StringConstraintsReducer;
