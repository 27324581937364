import React from "react";
import classnames from "classnames";
import { clickHandler } from "@util";
import { FederationProvider } from "@data";
import {
  ActionMenuItem,
  ContinueButton,
  DELETE_FEDERATION_PROVIDER_ACTION,
  EDIT_FEDERATION_PROVIDER_ACTION,
  FederationProviderList,
  FederationProvidersActions,
  FederationProvidersModel,
} from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export const DEFAULT_ACTIONS: ActionMenuItem[] = Object.freeze([
  EDIT_FEDERATION_PROVIDER_ACTION,
  DELETE_FEDERATION_PROVIDER_ACTION,
]) as ActionMenuItem[];

export interface Model extends FederationProvidersModel {
  showNotFound?: boolean;
  showAccessDenied?: boolean;
  children?: React.ReactNode;
}

export interface Actions extends FederationProvidersActions {
  createFederationProvider?: () => void;
  showFederationProviderDetails?: (federationProvider: FederationProvider) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const FederationProviders = withStyles(styles)((props: Props) => {

  const {
    classes,
    createFederationProvider,
    showFederationProviderDetails,
    actions = DEFAULT_ACTIONS,
    children,
    ...otherProps
  } = props;

  return (
    <FederationProviderList
      {...otherProps}
      actions={actions}
      showSummary={true}
      showSearch={true}
      onClickItem={showFederationProviderDetails}
    >
      <ContinueButton
        className={classnames("createFederationProviderButton", classes.createButton)}
        onClick={clickHandler(createFederationProvider)}
      >
        Create Federation Provider
      </ContinueButton>
      {children}
    </FederationProviderList>
  );
});

export default FederationProviders;
