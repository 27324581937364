import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  Chip,
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel,
  createColumns,
  DSTypeListViewActions,
  DSTypeListViewModel,
  DSTypesLayoutsListColumn,
  IconButton, ListViewItem,
  TextField,
} from "@components";
import { textAreaField, textField, typeDialog as styles } from "./styles";
import { DSTypeInfo } from "@data";
import DSTypeListView from "@components/ds-type-list-view";
import { formEventHandler, hasSpecialChars, isEmptyString, noop } from "@util";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/AddOutlined";
import MetadataIcon from "@material-ui/icons/Label";
import RemoveIcon from "@material-ui/icons/Clear";
import { PrimaryIcon } from "@modules/digitalShadowTypes/styles";

const DescriptionTextArea = withStyles(textAreaField)(TextField);
const MetadataTextField = withStyles(textField)(TextField);

export interface Model extends ConfirmationDialogModel<DSTypeInfo>, DSTypeListViewModel {
  title?: string;
  types?: DSTypeInfo[];
  loading?: boolean;
  editMode?: boolean;
  existingTypes?: string[];
}

export interface Actions extends ConfirmationDialogActions<DSTypeInfo>, DSTypeListViewActions {
  save?: (item: DSTypeInfo) => void;
  cancel?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DSTypeListDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Select Child",
    noResultsLabel = "No Shadow types available",
    types = [],
    existingTypes = [],
    loading = false,
    open = false,
    save = noop,
    cancel = noop,
    ...otherProps
  } = props;

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const [key, setKey] = React.useState("");
  const [value, setValue] = React.useState("");

  const [ child, setChild ] = React.useState<DSTypeInfo>(DSTypeInfo.EMPTY);

  const childDescription = React.useMemo(() => child.getDescription(), [child]);
  const childMetadata = React.useMemo(() => child.getMetadata(), [child]);

  const updateChild = React.useCallback((type: DSTypeInfo = DSTypeInfo.EMPTY) => {
    setChild(new DSTypeInfo({
      ...type.toJS()
    }));
  }, [setChild]);

  const setName = React.useCallback((name: string) => {
    updateChild(new DSTypeInfo({
      ...child.toJS(),
      name
    }));
  }, [child, updateChild]);

  const setDescription = React.useCallback((description: string) => {
    updateChild(new DSTypeInfo({
      ...child.toJS(),
      description
    }));
  }, [child, updateChild]);

  const setMetadata = React.useCallback((metadata: any = {}) => {
    updateChild(new DSTypeInfo({
      ...child.toJS(),
      metadata
    }));
  }, [child, updateChild]);

  const addMetadata = React.useCallback(() => {
    const newValue = {};
    newValue[key] = value;
    const updatedMetadata = !Object.keys(childMetadata).some(k => k === key) ?
      Object.assign(childMetadata, newValue) : childMetadata;
    setMetadata(updatedMetadata);
    setKey("");
    setValue("");
  }, [childMetadata, setMetadata, key, value, setKey, setValue]);

  const removeMetadata = React.useCallback((k: string) => {
    let updatedMetadata = childMetadata;
    delete updatedMetadata[k];
    setMetadata(updatedMetadata);
  }, [childMetadata, setMetadata]);

  const [ childName, setChildName ] = React.useState("");

  const selectedItems = React.useMemo(() => {
    if (isEmptyString(childName)) {
      return [];
    }
    const selectedType = types.find(item => item.getName() === childName);
    return selectedType ? [selectedType] : [new DSTypeInfo({ name: childName })];
  }, [childName, types]);

  const setSelectedType = React.useCallback(
    (dsType: DSTypeInfo = DSTypeInfo.EMPTY) => {
      setChildName(dsType.getName());
      if (!DSTypeInfo.EMPTY.equals(dsType)) {
        setName(dsType.getName());
      } else {
        setChild(DSTypeInfo.EMPTY);
      }
    }, [setChildName, setName, setChild]);

  const setSelectedItems = React.useCallback((dsTypes: DSTypeInfo[]) =>
    setSelectedType(dsTypes.pop()), [setSelectedType]);

  const continueButtonEnabled = React.useMemo(() => selectedItems.length > 0 ,
    [selectedItems]);

  const moduleListItems: ListViewItem<DSTypeInfo>[] = React.useMemo(() =>
    selectedItems.length > 0 ?
      selectedItems.map(type => {
        return {
          item: type,
          icon: PrimaryIcon,
          columnAttributes: createColumns([
            {
              className: "typeName",
              value: type.getName(),
              column: DSTypesLayoutsListColumn.NAME,
              firstColumn: true,
            },
          ])
        };
      })
      : types.filter(type =>
        !existingTypes.includes(type.getName()))
        .map(type => {
          return {
            item: type,
            icon: PrimaryIcon,
            columnAttributes: createColumns([
              {
                className: "typeName",
                value: type.getName(),
                column: DSTypesLayoutsListColumn.NAME,
                firstColumn: true,
              },
            ])
          };
        }), [types, selectedItems]);

  const showSearch = React.useMemo(() => moduleListItems.length > 1 , [moduleListItems]);

  const validMetadata = React.useMemo(() => 
    key.trim().length > 0 && value.trim().length > 0 && !hasSpecialChars(key), [key, value]);

  return (
    <ConfirmationDialog
      className={classnames("dsTypeListDialog", classes.container)}
      title={title}
      open={open}
      item={child}
      loading={loading}
      continueButtonLabel="Add"
      continueButtonDisabled={!continueButtonEnabled}
      maxWidth="md"
      confirm={save}
      cancel={cancel}
    >
      <DSTypeListView
        {...otherProps}
        className={classnames("typesList", classes.typeList)}
        listClassName="digitalShadowTypesList"
        listViewItems={moduleListItems}
        selectedItems={selectedItems}
        showSearch={showSearch}
        setSelectedItems={setSelectedItems}
        showSummary={true}
        showRefreshButton={false}
        summaryViewLabel="Available Types"
        showLoadingIndicator={loading}
        selectable={true}
        selectAllDisabled={true}
        noResultsLabel={noResultsLabel}
      />
      {continueButtonEnabled && (
        <React.Fragment>
          <Typography className={classnames("descriptionTitle", classes.descriptionTitle)} variant="h4">
            Add Child Description and Metadata (OPTIONAL)
          </Typography>
          <DescriptionTextArea
            className={classnames("description", classes.description)}
            autoComplete="off"
            name="description"
            label="Description"
            value={childDescription}
            fullWidth={false}
            multiline={true}
            minRows={3}
            maxRows={3}
            variant="outlined"
            margin="none"
            InputLabelProps={inputLabelProps}
            onChange={formEventHandler(setDescription)}
          />
          <Typography className={classnames("metadata", classes.metadata)} variant="h5">
            Child Metadata
          </Typography>
          <div className={classnames("metadataContainer", classes.metadataContainer)}>
            <MetadataTextField
              className={classnames("metadataKey", classes.metadataKey)}
              autoComplete="off"
              name="metadataKey"
              label="Key"
              value={key}
              variant="outlined"
              disableSpecialChars={true}
              InputLabelProps={inputLabelProps}
              onChange={formEventHandler(setKey)}
            />
            <MetadataTextField
              className={classnames("metadataValue", classes.metadataValue)}
              autoComplete="off"
              name="metadataValue"
              label="Value"
              value={value}
              variant="outlined"
              InputLabelProps={inputLabelProps}
              onChange={formEventHandler(setValue)}
            />
            {validMetadata && (
              <IconButton
                className={classnames("addMetadataButton", classes.addMetadataButton)}
                color="primary"
                onClick={addMetadata}
              >
                <AddIcon />
              </IconButton>
            )}
          </div>
          <div className={classnames("metadataContainer", classes.metadataContainer)}>
            {Object.entries(childMetadata).map((data: string[] = []) => (
              <Chip
                key={data[0]}
                label={`${data[0]} : ${data[1]}`}
                color="primary"
                className={classnames("metadataTag", classes.metadataTag)}
                icon={<MetadataIcon className={classnames("scopeIcon", classes.metadataIcon)} />}
                deleteIcon={<RemoveIcon className={classnames("removeIcon", classes.metadataIcon)} />}
                onDelete={() => removeMetadata(data[0])}
              />
            ))}
          </div>
        </React.Fragment>
      )}
    </ConfirmationDialog>
  );
});
