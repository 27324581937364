import { Module } from "@data";
import { reset } from "./actions";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { isCommonServicesModulesDisabled } from "@util";
import { primaryColor, PrimaryIcon as icon } from "./styles";
import { isAccessDeniedVisible, isEmptyViewVisible } from "./selectors";
import view from "./SchemaDetailsView";

export const schemaDetailsModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: true,
  name: "Schema Details",
  title: "Schema Details",
  className: "schemaDetailsModule",
  primaryColor,
  icon,
  view,
  isEmptyViewVisible: isEmptyViewVisible,
  isAccessDeniedVisible: isAccessDeniedVisible,
  onMount: reset,
  disabled: isCommonServicesModulesDisabled(),
});

export default schemaDetailsModule;
