import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles";
import Color from "@Color";

export const openMenuButton = () =>
  createStyles({
    button: {
    },
    buttonIcon: {
      width: 10,
      height: 10,
      marginLeft: 4,
    },
    iconButton: {
    },
    icon: {
      width: 24,
      height: 24,
    },
  });

export const nestedActionsMenuItem = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "center",
      padding: "11px 16px",
    },
    label: {
      flex: "1 0 auto",
      cursor: "pointer",
      "&$disabled": {
        cursor: "default",
      },
    },
    icon: {
      flex: "0 0 24px",
      margin: "0 0 0 8px",
      padding: 0,
      cursor: "pointer",
      "&$disabled": {
        cursor: "default",
      },
    },
    paper: {
      zIndex: 1500,
      marginLeft: 16,
      marginRight: 16,
    },
    popover: {
      zIndex: 1500,
    },
    menuList: {
      width: "auto",
      height: "100%",
      padding: 0,
      zIndex: 1500,
    },
    menuItem: {
      flex: "1 0 auto",
      color: Color.TEXT,
      fontSize: 16,
      fontFamily: "Roboto, sans-serif",
      lineHeight: "24px",
      padding: "11px 16px",
      fontWeight: 400,
      "&$subMenuOpen": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    menuItemLabel: {
      cursor: "pointer",
      "&$disabled": {
        cursor: "default",
      },
    },
    overridePointerEvents: {
      "pointer-events": "auto !important",
    },
    nestedActionsMenuItem: {
      flex: "1 0 auto",
      padding: 0,
    },
    subMenuOpen: {
    },
    disabled: {
    },
  });

export const styles = (theme: Theme) =>
  createStyles({
    container: {
    },
    menu: {
      width: "auto",
      height: "100%",
      padding: 0,
    },
    menuItem: {
      flex: "1 0 auto",
      color: Color.TEXT,
      fontSize: 16,
      fontFamily: "Roboto, sans-serif",
      lineHeight: "24px",
      padding: "11px 16px",
      fontWeight: 400,
      "&$subMenuOpen": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    menuItemLabel: {
      cursor: "pointer",
      "&$disabled": {
        cursor: "default",
      },
    },
    overridePointerEvents: {
      "pointer-events": "auto !important",
    },
    root: {
      color: Color.TEXT,
      padding: "8px",
      overflowY: "auto",
      "&$selected > $content $label": {
        backgroundColor: Color.WHITE,
      }
    },
    selected: {
    },
    group: {
      margin: 0,
      padding: 0,
    },
    content: {
    },
    label: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 16,
      fontWeight: 400,
    },
    nestedMenuItem: {
      flex: "1 0 auto",
      padding: 0,
    },
    subMenuOpen: {
    },
    disabled: {
    },
  });

export default styles;
