import React from "react";
import { isValidJson, noop } from "@util";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { getAccountId } from "@main/selectors";
import { WaitForApiRequestView } from "@components";
import EditProfileAttributeDefinition, {
  Actions,
  Model,
} from "../components/EditProfileAttributeDefinition";
import { useEditProfileAttributeDefinitions, useProfileAttributeDefinitions } from "@hooks";

interface ContainerModel extends Model {
  accountId?: string;
}

interface ContainerActions extends Actions {
  onSuccess?: () => void;
}

type Props = ContainerModel & ContainerActions;

const EditProfileAttributeDefinitionContainer = (props: Props) => {

  const {
    accountId = "",
    onSuccess = noop,
    ...otherProps
  } = props;

  const [{ attributes = [], ...otherModel }, { refresh }]
    = useProfileAttributeDefinitions({ accountId });

  const originalJson = React.useMemo(() => JSON.stringify({ attributes }, null, "  "), [attributes]);

  const [json, setJson] = React.useState<string>(originalJson);

  const isJsonValid = React.useMemo(() => isValidJson(json), [json]);

  const [{ showSuccessView, errorMessage, showLoadingIndicator }, { save, reset }]
    = useEditProfileAttributeDefinitions({ accountId, json });

  React.useEffect(() => {
    setJson(originalJson);
  }, [originalJson]);

  React.useEffect(() => {
    if (showSuccessView) {
      onSuccess();
    }
  }, [showSuccessView, onSuccess]);

  return (
    <WaitForApiRequestView
      {...otherModel}
      loadingMessage="Loading Editor..."
      errorTitle="Failed to Edit Profile Attribute Definitions"
      retry={refresh}
    >
      <EditProfileAttributeDefinition
        {...otherProps}
        json={json}
        isJsonValid={isJsonValid}
        errorMessage={errorMessage}
        showSuccessIndicator={showSuccessView}
        showLoadingIndicator={showLoadingIndicator}
        clearErrorMessage={reset}
        setJson={setJson}
        save={save}
      />
    </WaitForApiRequestView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  accountId: getAccountId(state),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
)(EditProfileAttributeDefinitionContainer);
