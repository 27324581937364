import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import ActionsMenu, { ActionMenuItem, ActionsMenuProps } from "@components/actions-menu";

export enum DSTypeAction {
  EDIT_DS_TYPE_ACTION = "edit_ds_type_action",
  DELETE_DS_TYPE_ACTION = "delete_ds_type_action",
}

export const EDIT_DS_TYPE_ACTION: ActionMenuItem = Object.freeze({
  id: DSTypeAction.EDIT_DS_TYPE_ACTION,
  name: "Edit Shadow Type",
  disabled: false,
});

export const DELETE_DS_TYPE_ACTION: ActionMenuItem = Object.freeze({
  id: DSTypeAction.DELETE_DS_TYPE_ACTION,
  name: "Delete Shadow Type",
  disabled: false,
});

export const DEFAULT_DS_TYPE_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  EDIT_DS_TYPE_ACTION,
  DELETE_DS_TYPE_ACTION,
]) as ActionMenuItem[];

export interface DSTypeActionsMenuProps extends ActionsMenuProps {
  editDSType?: () => void;
  deleteDSType?: () => void;
}

export const withDSTypeActions = (Component: React.ComponentType<DSTypeActionsMenuProps>) =>
  (props: DSTypeActionsMenuProps) => {

    const {
      actions = DEFAULT_DS_TYPE_ACTION_MENU_ITEMS,
      editDSType = noop,
      deleteDSType = noop,
      onClickAction = noop,
    } = props;

    return (
      <Component
        {...props}
        actions={actions}
        onClickAction={(action: ActionMenuItem) => {
          if (action.id === DSTypeAction.EDIT_DS_TYPE_ACTION) {
            editDSType();
          } else if (action.id === DSTypeAction.DELETE_DS_TYPE_ACTION) {
            deleteDSType();
          } else {
            onClickAction(action);
          }
        }}
      />
    );
  };

export const DSTypeActionsMenu = withDSTypeActions((props: DSTypeActionsMenuProps) => {

  const { className, buttonLabel = "Shadow Type Actions" } = props;

  return (
    <ActionsMenu
      {...props}
      className={classnames("dsTypeActionsMenu", className)}
      buttonLabel={buttonLabel}
    />
  );
});

export default DSTypeActionsMenu;
