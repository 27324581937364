import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { canRestoreServiceSession, canRestoreUserSession } from "@main/selectors";
import { hideLoadingIndicator, showLoadingIndicator } from "@modules/login/actions";
import RestoreUserSession, {
  RestoreUserSessionActions,
  RestoreUserSessionModel,
} from "./RestoreUserSession";
import RestoreServiceSession, {
  RestoreServiceSessionActions,
  RestoreServiceSessionModel,
} from "./RestoreServiceSession";

export interface RestoreSessionModel extends RestoreUserSessionModel, RestoreServiceSessionModel {
  serviceSessionRestorable?: boolean;
}

export interface RestoreSessionActions extends RestoreUserSessionActions, RestoreServiceSessionActions {
}

type Model = RestoreSessionModel;
type Actions = RestoreSessionActions;
type Props = Model & Actions;

const RestoreSession = (props: Props) => {

  const {
    userSessionRestorable,
    serviceSessionRestorable,
    showRestoreSessionLoadingIndicator,
    hideRestoreSessionLoadingIndicator,
    restoreSessionFailed,
  } = props;

  if (userSessionRestorable) {
    return (
      <RestoreUserSession
        showRestoreSessionLoadingIndicator={showRestoreSessionLoadingIndicator}
        hideRestoreSessionLoadingIndicator={hideRestoreSessionLoadingIndicator}
        restoreSessionFailed={restoreSessionFailed}
      />
    );
  }

  if (serviceSessionRestorable) {
    return (
      <RestoreServiceSession
        showRestoreSessionLoadingIndicator={showRestoreSessionLoadingIndicator}
        hideRestoreSessionLoadingIndicator={hideRestoreSessionLoadingIndicator}
        restoreSessionFailed={restoreSessionFailed}
      />
    );
  }

  return null;
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  userSessionRestorable: canRestoreUserSession(state),
  serviceSessionRestorable: canRestoreServiceSession(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  showRestoreSessionLoadingIndicator: () => dispatch(showLoadingIndicator()),
  hideRestoreSessionLoadingIndicator: () => dispatch(hideLoadingIndicator()),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(RestoreSession);
