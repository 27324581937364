import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { AppSchema } from "@schemas";
import { noop } from "@util";
import {
  decommissionSchema,
  deleteSchema,
  deprecateSchema,
  draftNewSchemaVersion,
  initialize,
  promoteSchema,
  refresh,
} from "../actions";
import {
  getActionMenuItems,
  getErrorMessage,
  getJson,
  getSchemaMetaData,
  getSchemaName,
  isLoadingIndicatorVisible,
  isNotFoundVisible,
} from "../selectors";
import { ActionMenuItem, DetailsViewRoute } from "@components";
import SummaryJsonDetailsView from "@components/summary-json-details-view";
import { PrimaryIcon } from "../styles";
import { SchemaAction } from "@modules/schemaStateManager/reducers";
import { JsonSchemaMetadata, SummaryViewData } from "@data";
import SchemaValidationView from "./SchemaValidationView";
import SummaryViewTable from "./SummaryViewTable";

const ADDITIONAL_ROUTES: DetailsViewRoute[] = [
  {
    id: "validationView",
    name: "Validate",
    path: "/validate",
    view: SchemaValidationView,
  }
];

interface ContainerModel {
  title?: string;
  showNotFound?: boolean;
  json?: string;
  loading?: boolean;
  errorMessage?: string;
  actions?: ActionMenuItem[];
  schema?: JsonSchemaMetadata;
}

interface ContainerActions {
  refresh?: () => void;
  editSchema?: (schema: JsonSchemaMetadata) => void;
  draftNewSchemaVersion?: (schema: JsonSchemaMetadata) => void;
  promoteSchema?: (schema: JsonSchemaMetadata) => void;
  deprecateSchema?: (schema: JsonSchemaMetadata) => void;
  decommissionSchema?: (schema: JsonSchemaMetadata) => void;
  deleteSchema?: (schema: JsonSchemaMetadata) => void;
  initialize?: (id: string) => void;
  onClickAction?: (action: ActionMenuItem) => void;
}

type Props = ContainerModel & ContainerActions;

const SchemaDetailsContainer = (props: Props) => {

  const {
    title,
    initialize: initializeModule = noop,
    showNotFound = false,
    draftNewSchemaVersion: draft = noop,
    promoteSchema: promote = noop,
    deprecateSchema: deprecate = noop,
    decommissionSchema: decommission = noop,
    deleteSchema: deleteSchemaAction = noop,
    editSchema = noop,
    refresh: refreshPage,
    onClickAction = noop,
    loading,
    errorMessage,
    actions: actionsMenu,
    schema = JsonSchemaMetadata.EMPTY,
    json,
  } = props;

  const { id } = useParams<{ id: string }>();

  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      initializeModule(id);
    }
  }, [id, initialized, setInitialized]);

  const onClickSchemaAction = React.useCallback((schemaAction: ActionMenuItem) => {
    switch (schemaAction.id) {
      case SchemaAction.EDIT:
        return editSchema(schema);
      case SchemaAction.DRAFT:
        return draft(schema);
      case SchemaAction.PROMOTE:
        return promote(schema);
      case SchemaAction.DEPRECATE:
        return deprecate(schema);
      case SchemaAction.DECOMMISSION:
        return decommission(schema);
      case SchemaAction.DELETE:
        return deleteSchemaAction(schema);
      default:
        onClickAction(schemaAction);
    }
  }, [
    schema,
    editSchema,
    draft,
    promote,
    deprecate,
    decommission,
    deleteSchemaAction,
    onClickAction,
  ]);

  const summaryViewItems = React.useMemo(() => [
    new SummaryViewData({
      className: "namespace",
      name: "Namespace",
      value: schema.getNamespace(),
    }),
    new SummaryViewData({
      className: "name",
      name: "Name",
      value: schema.getName(),
    }),
    new SummaryViewData({
      className: "version",
      name: "Version",
      value: schema.getVersionAsString(),
    }),
    new SummaryViewData({
      className: "state",
      name: "State",
      value: schema.getState(),
    }),
    new SummaryViewData({
      className: "description",
      name: "Description",
      value: schema.getDescription(),
    }),
    new SummaryViewData({
      className: "createdAt",
      name: "Created At",
      value: schema.getCreatedAt(),
    }),
    new SummaryViewData({
      className: "createdBy",
      name: "Created By",
      value: schema.getCreatedBy(),
    }),
    new SummaryViewData({
      className: "updatedAt",
      name: "Updated At",
      value: schema.getUpdatedAt(),
    }),
    new SummaryViewData({
      className: "updatedBy",
      name: "Updated By",
      value: schema.getUpdatedBy(),
    }),
    new SummaryViewData({
      className: "location",
      name: "Location",
      value: schema.getLocation(),
    }),
    new SummaryViewData({
      className: "etag",
      name: "ETag",
      value: schema.getEtag(),
    }),
  ], [schema]);

  return (
    <SummaryJsonDetailsView
      title={title}
      icon={PrimaryIcon}
      refresh={refreshPage}
      showLoadingIndicator={loading}
      errorMessage={errorMessage}
      actions={actionsMenu}
      onClickAction={onClickSchemaAction}
      summaryViewItems={summaryViewItems}
      additionalViews={<SummaryViewTable />}
      json={json}
      fileName={`${title}.json`}
      additionalRoutes={ADDITIONAL_ROUTES}
      showNotFound={showNotFound}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  schema: getSchemaMetaData(state),
  loading: isLoadingIndicatorVisible(state),
  errorMessage: getErrorMessage(state),
  actions: getActionMenuItems(state),
  title: getSchemaName(state),
  showNotFound: isNotFoundVisible(state),
  json: getJson(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  initialize: (id: string) => dispatch(initialize(id)),
  refresh: () => dispatch(refresh()),
  draftNewSchemaVersion: () => dispatch(draftNewSchemaVersion()),
  promoteSchema: () => dispatch(promoteSchema()),
  deprecateSchema: () => dispatch(deprecateSchema()),
  decommissionSchema: () => dispatch(decommissionSchema()),
  deleteSchema: () => dispatch(deleteSchema()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(SchemaDetailsContainer);
