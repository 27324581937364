import React from "react";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  useDeviceProvisioningClient,
} from "@hooks";
import {
  DeviceProvisioningData,
  DeviceProvisioningDataAttributes
} from "@data";

type SuccessResponse = DeviceProvisioningDataAttributes;

export interface UseDeviceBootstrapConfigServiceProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  deviceId: string;
  authorization: string;
}

export interface UseDeviceBootstrapConfigServiceModel extends UseApiRequestModel<SuccessResponse> {
  bootstrapConfig: DeviceProvisioningData;
}

export interface UseDeviceBootstrapConfigServiceActions {
  getDeviceBootstrapConfig: () => void;
}

type Props = UseDeviceBootstrapConfigServiceProps;
type Model = UseDeviceBootstrapConfigServiceModel;
type Actions = UseDeviceBootstrapConfigServiceActions;
type Result = [Model, Actions];

export const useDeviceBootstrapConfigService = (props: Props): Result => {

  const {
    deviceId,
    authorization,
    defaultErrorMessage = "Failed to get device bootstrap configuration",
    ...otherProps
  } = props;

  const DeviceProvisioningClient = useDeviceProvisioningClient();

  const [{ successResponse, ...baseModel }, { refresh: getDeviceBootstrapConfig }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest: () => DeviceProvisioningClient.getDeviceBootstrapConfiguration(authorization, deviceId),
    });

  const bootstrapConfig = React.useMemo(() => {
    const attrs = successResponse || {};
    return new DeviceProvisioningData(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    bootstrapConfig,
  }), [
    baseModel,
    bootstrapConfig,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    getDeviceBootstrapConfig,
  }), [
    getDeviceBootstrapConfig,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDeviceBootstrapConfigService;
