import React from "react";
import classnames from "classnames";
import ErrorView from "@components/error-view";
import DownloadIcon from "@material-ui/icons/GetApp";
import FileIcon from "@material-ui/icons/Description";
import { IconButton } from "@components";
import Typography from "@material-ui/core/Typography";
import DownloadButton from "@components/download-button";
import { clickHandler, isEmptyString, noop } from "@util";
import LeftArrowIcon from "@material-ui/icons/KeyboardArrowLeft";
import { Document, Page } from "react-pdf";
import RightArrowIcon from "@material-ui/icons/KeyboardArrowRight";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface PdfViewerModel {
  className?: string;
  file?: any;
  fileName?: string;
  loadingMessage?: string;
  downloadLabel?: string;
  downloadButtonAnimationMs?: number;
  errorTitle?: string;
  errorRetryButtonLabel?: string;
  hideDownloadButton?: boolean;
  customControls?: React.ReactNode;
}

export interface PdfViewerActions {
}

type Model = PdfViewerModel;
type Actions = PdfViewerActions;

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const PdfViewer = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    file,
    fileName = "",
    loadingMessage = "Loading File...",
    downloadLabel = "Download File",
    downloadButtonAnimationMs = 500,
    errorTitle = "Failed to Load PDF",
    errorRetryButtonLabel = "Retry",
    hideDownloadButton,
    customControls,
    children,
  } = props;

  const [downloading, setDownloading] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState("");

  const [numPages, setNumPages] = React.useState<null | number>(null);

  const [pageNumber, setPageNumber] = React.useState(1);

  const showLoadingView = React.useMemo(() =>
    numPages === null, [numPages]);

  const showErrorView = React.useMemo(() =>
    !isEmptyString(errorMessage), [errorMessage]);

  const currentPageLabel = React.useMemo(() => {
    if (showLoadingView || typeof numPages !== "number") {
      return "";
    }
    const numPagesLength = `${numPages}`.length;
    return numPagesLength === 0
      ? ""
      : `Page ${String(pageNumber).padStart(numPagesLength, "0")} of ${numPages}`;
  }, [showLoadingView, numPages, pageNumber]);

  const showControls = React.useMemo(() => !isEmptyString(currentPageLabel), [currentPageLabel]);

  const downloadLabelEl = React.useMemo<React.ReactNode>(() => (
    <React.Fragment>
      <DownloadIcon className={classnames("downloadButtonIcon", classes.downloadButtonIcon)}/>
      {downloadLabel}
    </React.Fragment>
  ), [downloadLabel]);

  const onLoadSuccess = React.useCallback((pdf = {}) => {
    const { numPages: pdfNumPages = null } = pdf;
    setNumPages(pdfNumPages);
    setPageNumber(1);
  }, [setNumPages, setPageNumber]);

  const onLoadError = React.useCallback(error => {
    setErrorMessage(error && error.message ? error.message : "Failed to load PDF");
  }, [setErrorMessage]);

  React.useEffect(() => {

    if (!downloading) {
      return noop;
    }

    const timer = setTimeout(() => setDownloading(false), downloadButtonAnimationMs + 1);

    return () => clearTimeout(timer);

  }, [downloadButtonAnimationMs, downloading, setDownloading]);

  if (showErrorView) {
    return (
      <div className={classnames("pdfViewer", className, classes.errorContainer)}>
        <ErrorView
          className={classnames("errorView", classes.errorView)}
          title={errorTitle}
          message={errorMessage}
          retryButtonLabel={errorRetryButtonLabel}
          retry={() => setErrorMessage("")}
        />
      </div>
    );
  }

  if (!file) {
    return null;
  }

  return (
    <div className={classnames("pdfViewer", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        {showLoadingView && (
          <Typography
            variant="h3"
            className={classnames("loadingMessage", classes.loadingMessage)}
          >
            Loading Document...
          </Typography>
        )}
        {!showLoadingView && (
          <React.Fragment>
            <Typography
              variant="subtitle1"
              className={classnames("fileName", classes.fileName)}
            >
              <FileIcon className={classnames("fileNameIcon", classes.fileNameIcon)} />
              {fileName}
            </Typography>
            {showControls && (
              <div className={classnames("controls", classes.controls)}>
                <IconButton
                  className={classnames("previousPageButton", classes.previousPageButton)}
                  disabled={!numPages || (pageNumber <= 1 || numPages <= 1)}
                  onClick={clickHandler(() => setPageNumber(pageNumber - 1))}
                >
                  <LeftArrowIcon
                    className={classnames("previousPageIcon", classes.previousPageIcon)}
                  />
                </IconButton>
                <Typography
                  variant="subtitle1"
                  className={classnames("currentPage", classes.currentPage)}
                >
                  {currentPageLabel}
                </Typography>
                <IconButton
                  className={classnames("nextPageButton", classes.nextPageButton)}
                  disabled={!numPages || (pageNumber >= numPages)}
                  onClick={clickHandler(() => setPageNumber(pageNumber + 1))}
                >
                  <RightArrowIcon
                    className={classnames("nextPageIcon", classes.nextPageIcon)}
                  />
                </IconButton>
              </div>
            )}
            {customControls}
            {!hideDownloadButton && (
              <DownloadButton
                className={classnames("download", classes.download)}
                buttonClassName={classnames("downloadButton", classes.downloadButton)}
                downloadFileClassName="pdfFile"
                color="primary"
                label={downloadLabelEl}
                downloadIndicatorSize={50}
                href={downloading ? file : ""}
                downloadFinishedDelay={downloadButtonAnimationMs}
                download={() => setDownloading(true)}
              />
            )}
          </React.Fragment>
        )}
      </div>
      <Document
        className={classnames("pdf", classes.pdf)}
        file={file}
        loading={loadingMessage}
        onLoadSuccess={onLoadSuccess}
        onLoadError={onLoadError}
      >
        <Page
          renderAnnotationLayer={false}
          renderTextLayer={false}
          pageNumber={pageNumber}
        />
      </Document>
      {children}
    </div>
  );
});

export default PdfViewer;
