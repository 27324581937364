import React from "react";
import {
  BackButton,
  BackButtonActions,
  BackButtonModel,
  BrowserHistoryBackButton,
} from "@components/back-button";

export interface WizardBackButtonModel extends BackButtonModel {
  firstStepSelected?: boolean;
}

export interface WizardBackButtonActions extends BackButtonActions {
  showPreviousStep?: () => void;
}

type Props = WizardBackButtonModel & WizardBackButtonActions;

export const WizardBackButton = (props: Props) => {

  const {
    className = "wizardBackButton",
    firstStepSelected,
    showPreviousStep,
    ...otherProps
  } = props;

  if (firstStepSelected) {
    return (
      <BrowserHistoryBackButton
        {...otherProps}
        className={className}
      />
    );
  }

  return (
    <BackButton
      {...otherProps}
      className={className}
      goBack={showPreviousStep}
    />
  );
};

export default WizardBackButton;
