import { createSelector } from "reselect";
import { LoginSchema } from "./reducers";
import { AppSchema } from "@main/schemas";
import { IdentityType } from "@data";
import { getWhitelistedAccounts, isFederatedLoginRequired } from "./helpers";
import { Selector } from "@modules/base/createSelectors";
import {
  getStringValue,
  getUrlParameter,
  isEmptyString,
  isValidJson,
  withModuleSelector,
} from "@util";
import {
  createEncodedFederatedLoginState,
  decodeFederatedLoginState,
  DEFAULT_FEDERATED_LOGIN_STATE,
  FederatedLoginState,
} from "./FederatedLoginState";

export const getQueryParams = (state: AppSchema) => state.queryParams;

const FEDERATION_APP_ID = process.env.REACT_APP_FEDERATION_APP_ID || "";
const FEDERATED_LOGIN_URL = process.env.REACT_APP_FEDERATED_LOGIN_URL || "";

type Schema = LoginSchema;

const selector = <T>(key: keyof Schema) => withModuleSelector("login", key);

export const getNonce = selector<string>("nonce");
export const getUsername = selector<string>("username");
export const getUsernameError = selector<string>("usernameError");
export const getAccount = selector<string>("account");
export const getAccountError = selector<string>("accountError");
export const getPassword = selector<string>("password");
export const getPasswordError = selector<string>("passwordError");
export const getToken = selector<string>("token");
export const getTokenError = selector<string>("tokenError");
export const isShowPasswordChecked = selector<boolean>("showPassword");
export const isRememberMeChecked = selector<boolean>("rememberMe");
export const isCredentialsViewVisible = selector<boolean>("showCredentialsView");
export const isTermsDialogVisible = selector<boolean>("showTermsDialog");
export const isLoadingIndicatorVisible = selector<boolean>("showLoadingIndicator");
export const isErrorMessageVisible = selector<boolean>("showErrorMessage");
export const getErrorMessage = selector<string>("errorMessage");
export const getIdentityType = selector<IdentityType>("identityType");
export const getServiceId = selector<string>("serviceId");
export const getServiceIdError = selector<string>("serviceIdError");
export const getMfaCode = selector<string>("mfaCode");
export const getMfaCodeError = selector<string>("mfaCodeError");
export const getServiceAccountId = selector<string>("serviceAccountId");
export const getServiceAccountIdError = selector<string>("serviceAccountIdError");
export const getServiceSecret = selector<string>("serviceSecret");
export const getServiceSecretError = selector<string>("serviceSecretError");
export const isRememberServiceChecked = selector<boolean>("rememberService");
export const getRedirectUri = selector<string>("redirectUri");

export const hasWhitelistedAccounts = (): boolean =>
  isFederatedLoginRequired() || getWhitelistedAccounts().length > 0;

export const isValidUsername: (state: AppSchema) => boolean = createSelector(
  getUsername, (username: string) => !isEmptyString(username));

export const isValidAccount: (state: AppSchema) => boolean = createSelector(
  getAccount, (account: string) => !isEmptyString(account));

export const isAccountWhitelisted: (state: AppSchema) => boolean = createSelector(
  [getAccount, getWhitelistedAccounts],
  (account: string, whitelistedAccounts) =>
    whitelistedAccounts.indexOf(getStringValue(account)) >= 0);

export const isValidPassword: (state: AppSchema) => boolean = createSelector(
  getPassword, (password: string) => !isEmptyString(password));

export const isValidServiceId: (state: AppSchema) => boolean = createSelector(
  getServiceId, (serviceId: string) => !isEmptyString(serviceId));

export const isValidServiceAccountId: (state: AppSchema) => boolean = createSelector(
  getServiceAccountId, (accountId: string) => !isEmptyString(accountId));

export const isValidServiceSecret: (state: AppSchema) => boolean = createSelector(
  getServiceSecret, (secret: string) => !isEmptyString(secret));

export const isServiceLoginFormVisible: (state: AppSchema) => boolean = createSelector(
  getIdentityType, (identityType: IdentityType) =>
    IdentityType.SERVICE === identityType);

// Not all users require tokens
export const isValidToken: (state: AppSchema) => boolean = createSelector(
  getToken, (token: string) => isEmptyString(token) || getStringValue(token).length === 6);

export const getLoginButtonLabel: (state: AppSchema) => string = createSelector(
  isCredentialsViewVisible, (showCredentialsView: boolean) =>
    showCredentialsView ? "Login" : "Continue");

export const isFederatedLoginSupported: (state: AppSchema) => boolean = createSelector(
  [isValidUsername, isValidAccount, isAccountWhitelisted],
  (validUsername: boolean, validAccount: boolean, whitelistedAccount: boolean) =>
    validUsername && validAccount && (isFederatedLoginRequired() || whitelistedAccount));

export const getFederatedLoginState: (state: AppSchema) => FederatedLoginState = createSelector(
  [getUsername, getAccount, isRememberMeChecked, getRedirectUri],
  (username: string, accountId: string, rememberMe: boolean, redirectUri: string) => ({
    username: getStringValue(username),
    accountId: getStringValue(accountId),
    rememberMe,
    redirectUri,
    autoLoginEnabled: !isEmptyString(username) && !isEmptyString(accountId),
  }));

export const getEncodedFederatedLoginState: (state: AppSchema) => string = createSelector(
  getFederatedLoginState, (state: FederatedLoginState) =>
    createEncodedFederatedLoginState(state));

export const getFederatedLoginUrl: (state: AppSchema) => string = createSelector(
  [getNonce, getEncodedFederatedLoginState],
  (nonce: string, state: string) => FEDERATED_LOGIN_URL +
    `?client_id=${FEDERATION_APP_ID}` +
    `&redirect_uri=${encodeURIComponent(window.location.origin)}` +
    "&scope=email+openid+profile&response_type=code+id_token" +
    `&response_mode=query&nonce=${nonce}&state=${state}&redirect_errors=true`);

export const getFederatedLoginResponseCode: (state: AppSchema) => string = createSelector(
  getQueryParams, (queryParams: string) => getUrlParameter(queryParams, "code"));

export const getFederatedLoginResponseNonce: (state: AppSchema) => string = createSelector(
  getQueryParams, (queryParams: string) => getUrlParameter(queryParams, "nonce"));

export const isFederatedLoginResponseNonceValid: (state: AppSchema) => boolean = createSelector(
  [getFederatedLoginResponseNonce, getNonce], (responseNonce: string, requestNonce: string) =>
    !isEmptyString(responseNonce) && (responseNonce === requestNonce));

export const getFederatedLoginResponseState: (state: AppSchema) => FederatedLoginState = createSelector(
  [isFederatedLoginResponseNonceValid, getQueryParams],
  (validNonce: boolean, queryParams: string) => !validNonce ? DEFAULT_FEDERATED_LOGIN_STATE :
    decodeFederatedLoginState(getUrlParameter(queryParams, "state")));

export const getFederatedLoginResponseUsername: (state: AppSchema) => string = createSelector(
  getFederatedLoginResponseState, ({ username }) => getStringValue(username));

export const getFederatedLoginResponseAccountId: (state: AppSchema) => string = createSelector(
  getFederatedLoginResponseState, ({ accountId }) => getStringValue(accountId));

export const getFederatedLoginResponseRedirectUri: (state: AppSchema) => string = createSelector(
  getFederatedLoginResponseState, ({ redirectUri }) => getStringValue(redirectUri));

export const isFederatedLoginResponseAvailable: (state: AppSchema) => boolean = createSelector(
  [getFederatedLoginResponseUsername, getFederatedLoginResponseAccountId],
  (username: string, accountId: string) =>
    !isEmptyString(username) && !isEmptyString(accountId));

export const isStartSessionVisible: (state: AppSchema) => boolean = createSelector(
  [getFederatedLoginResponseCode, getFederatedLoginResponseNonce, getNonce],
  (code: string, responseNonce: string, requestNonce: string) =>
    !isEmptyString(code) && (responseNonce === requestNonce));

export const getErrorUrlParameter: Selector<string> = createSelector(
  getQueryParams, (queryParams: string) => {

  const error = getUrlParameter(queryParams, "error");

  if (isEmptyString(error)) {
    return "";
  }

  const errorDescription = getUrlParameter(queryParams, "error_description");

  if (isEmptyString(errorDescription)) {
    return error;
  }

  if (isValidJson(errorDescription)) {
    const response = JSON.parse(errorDescription);
    const { message: responseMessage, description: responseDescription } = response;
    return responseDescription || responseMessage || error;
  }

  return errorDescription;
});
