import { Record } from "immutable";
import { getStringValue } from "@util";

export interface DataAttributes {
  schemaIdentity: string;
  schemaData: any;
}

export interface DeviceSchemaDataAttributes {
  populateAllSchemas: boolean;
  data: DataAttributes[];
}

export interface DeviceDataAttributes {
  actual: DeviceSchemaDataAttributes;
  desired: DeviceSchemaDataAttributes;
}

export interface CredentialAttributes {
  name: string;
  value: string;
}

export interface SecurityAttributes {
  credentials: CredentialAttributes[];
}

export interface DeviceDataModelAttributes {
  deviceId: string;
  deviceData: DeviceDataAttributes;
  deviceType: string;
  security: SecurityAttributes;
}

export class DeviceDataModel extends Record({
  deviceId: "",
  deviceType: "",
  deviceData: {
    actual: {
      data: [],
      populateAllSchemas: false,
    },
    desired: {
      data: [],
      populateAllSchemas: false,
    }
  },
  security: {
    credentials: []
  }
}) implements DeviceDataModelAttributes {

  public static EMPTY: DeviceDataModel = new DeviceDataModel();

  public readonly deviceId: string;
  public readonly deviceData: DeviceDataAttributes;
  public readonly deviceType: string;
  public readonly security: SecurityAttributes;

  public getDeviceId(): string {
    return getStringValue(this.deviceId);
  }

  public getDeviceType(): string {
    return getStringValue(this.deviceType);
  }

  public getActualSchemas(): string[] {

    const { actual: { data: actualData = [] } = {} } = {}  = this.deviceData || {};

    if (!Array.isArray(actualData)) {
      return [];
    }

    return actualData.map(({ schemaIdentity }) => getStringValue(schemaIdentity));
  }

  public getActualData(): DataAttributes[] {

    const { actual: { data: actualData = [] } = {} } = {}  = this.deviceData || {};

    return Array.isArray(actualData) ? actualData : [];
  }

  public getDesiredSchemas(): string[] {

    const { desired: { data: desiredData = [] } = {} } = {}  = this.deviceData || {};

    if (!Array.isArray(desiredData)) {
      return [];
    }

    return desiredData.map(({ schemaIdentity }) => getStringValue(schemaIdentity));
  }

  public getDesiredData(): DataAttributes[] {

    const { desired: { data: desiredData = [] } = {} } = {}  = this.deviceData || {};

    return Array.isArray(desiredData) ? desiredData : [];
  }

  public getCredentials(): CredentialAttributes[] {

    const { credentials = [] } = {}  = this.security || {};

    return Array.isArray(credentials) ? credentials : [];
  }

  public getActualPopulateAllSchema(): boolean {

    return this.deviceData.actual.populateAllSchemas;
  }

  public getDesiredPopulateAllSchema(): boolean {

    return this.deviceData.desired.populateAllSchemas;
  }
}
