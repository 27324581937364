import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import ActionsMenu, { ActionMenuItem, ActionsMenuProps } from "@components/actions-menu";

export { ActionMenuItem };

export enum NamespaceAction {
  PUBLISH_NAMESPACE = "publish_namespace",
  DEPRECATE_NAMESPACE = "deprecate_namespace",
}

export const PUBLISH_NAMESPACE: ActionMenuItem = Object.freeze({
  id: NamespaceAction.PUBLISH_NAMESPACE,
  name: "Publish Namespace",
  disabled: false,
});

export const DEPRECATE_NAMESPACE: ActionMenuItem = Object.freeze({
  id: NamespaceAction.DEPRECATE_NAMESPACE,
  name: "Deprecate Namespace",
  disabled: false,
});

export const DEFAULT_NAMESPACE_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  PUBLISH_NAMESPACE,
  DEPRECATE_NAMESPACE,
]) as ActionMenuItem[];

export interface NamespaceActionsMenuProps extends ActionsMenuProps {
  publishNamespace?: () => void;
  deprecateNamespace?: () => void;
}

export const withNamespaceActions = (Component: React.ComponentType<NamespaceActionsMenuProps>) =>
  (props: NamespaceActionsMenuProps) => {

    const {
      actions = DEFAULT_NAMESPACE_ACTION_MENU_ITEMS,
      publishNamespace = noop,
      deprecateNamespace = noop,
      onClickAction = noop,
    } = props;

    return (
      <Component
        {...props}
        actions={actions}
        onClickAction={(action: ActionMenuItem) => {
          switch (action.id) {
          case NamespaceAction.PUBLISH_NAMESPACE:
            return publishNamespace();
          case NamespaceAction.DEPRECATE_NAMESPACE:
            return deprecateNamespace();
          default:
            return onClickAction(action);
          }
        }}
      />
    );
  };

export const NamespaceActionsMenu = withNamespaceActions((props: NamespaceActionsMenuProps) => {

  const { className, buttonLabel = "Namespace Actions" } = props;

  return (
    <ActionsMenu
      {...props}
      className={classnames("namespaceActionsMenu", className)}
      buttonLabel={buttonLabel}
    />
  );
});

export default NamespaceActionsMenu;
