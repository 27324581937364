import { DeviceConfigurationAttributes } from "@data";
import { DeviceRegistryClient, GetDeviceDataResponse } from "@network";
import { useApiRequestAnalytics } from "@hooks";

export type GetDeviceConfigurationApi =
  (deviceId: string, includeData: string) => Promise<DeviceConfigurationAttributes>;

export type GetActualDeviceConfigurationBySchemaApi =
  (deviceId: string, schemaIdentity: string) => Promise<GetDeviceDataResponse>;

export type GetDesiredDeviceConfigurationBySchemaApi =
  (deviceId: string, schemaIdentity: string) => Promise<GetDeviceDataResponse>;

export type DeleteActualDeviceConfigurationBySchemaApi =
  (deviceId: string, schemaIdentity: string) => Promise<void>;

export type DeleteDesiredDeviceConfigurationBySchemaApi =
  (deviceId: string, schemaIdentity: string) => Promise<void>;

export type EditActualDeviceDataBySchemaApi =
  (deviceId: string, schemaIdentity: string, json: string) => Promise<void>;

export type EditDesiredDeviceDataBySchemaApi =
  (deviceId: string, schemaIdentity: string, json: string) => Promise<void>;

export type GetDeviceMetadataBySchemaApi =
  (deviceId: string, schemaIdentity: string) => Promise<GetDeviceDataResponse>;

export type DeleteDeviceMetadataBySchemaApi =
  (deviceId: string, schemaIdentity: string) => Promise<void>;

export type EditDeviceMetadataDataBySchemaApi =
  (deviceId: string, schemaIdentity: string, json: string) => Promise<void>;

export type GetRegionConfigurationBySchemaApi =
  (schemaIdentity: string) => Promise<GetDeviceDataResponse>;

export type DeleteRegionConfigurationBySchemaApi =
  (schemaIdentity: string) => Promise<void>;

export type EditRegionConfigurationBySchemaApi =
  (schemaIdentity: string, json: string) => Promise<void>;

export type GetTypeConfigurationBySchemaApi =
  (typeIdentity: string, schemaIdentity: string) => Promise<GetDeviceDataResponse>;

export type DeleteTypeConfigurationBySchemaApi =
  (typeIdentity: string, schemaIdentity: string) => Promise<void>;

export type EditTypeConfigurationBySchemaApi =
  (typeIdentity: string, schemaIdentity: string, json: string) => Promise<void>;

export interface DeviceRegistryClientApi {
  getDeviceConfiguration: GetDeviceConfigurationApi;
  getActualDeviceConfigurationBySchema: GetActualDeviceConfigurationBySchemaApi;
  getDesiredDeviceConfigurationBySchema: GetDesiredDeviceConfigurationBySchemaApi;
  deleteActualDeviceDataBySchema: DeleteActualDeviceConfigurationBySchemaApi;
  deleteDesiredDeviceDataBySchema: DeleteDesiredDeviceConfigurationBySchemaApi;
  editActualDeviceDataBySchema: EditActualDeviceDataBySchemaApi;
  editDesiredDeviceDataBySchema: EditDesiredDeviceDataBySchemaApi;
  getDeviceMetadataBySchema: GetDeviceMetadataBySchemaApi;
  deleteDeviceMetadataBySchema: DeleteDeviceMetadataBySchemaApi;
  editDeviceMetadataDataBySchema: EditDeviceMetadataDataBySchemaApi;
  getRegionConfigurationBySchema: GetRegionConfigurationBySchemaApi;
  deleteRegionConfigurationBySchema: DeleteRegionConfigurationBySchemaApi;
  editRegionConfigurationBySchema: EditRegionConfigurationBySchemaApi;
  getTypeConfigurationBySchema: GetTypeConfigurationBySchemaApi;
  deleteTypeConfigurationBySchema: DeleteTypeConfigurationBySchemaApi;
  editTypeConfigurationBySchema: EditTypeConfigurationBySchemaApi;
}

enum ActionType {
  GET_DEVICE_CONFIGURATION_REQUEST = "device_registry_client/get_device_configuration_request",
  GET_DEVICE_CONFIGURATION_SUCCESS = "device_registry_client/get_device_configuration_success",
  GET_DEVICE_CONFIGURATION_FAILED = "device_registry_client/get_device_configuration_failed",

  GET_ACTUAL_DEVICE_CONFIGURATION_BY_SCHEMA_REQUEST = "device_registry_client/get_actual_device_configuration_by_schema_request",
  GET_ACTUAL_DEVICE_CONFIGURATION_BY_SCHEMA_SUCCESS = "device_registry_client/get_actual_device_configuration_by_schema_success",
  GET_ACTUAL_DEVICE_CONFIGURATION_BY_SCHEMA_FAILED = "device_registry_client/get_actual_device_configuration_by_schema_failed",

  GET_DESIRED_DEVICE_CONFIGURATION_BY_SCHEMA_REQUEST = "device_registry_client/get_desired_device_configuration_by_schema_request",
  GET_DESIRED_DEVICE_CONFIGURATION_BY_SCHEMA_SUCCESS = "device_registry_client/get_desired_device_configuration_by_schema_success",
  GET_DESIRED_DEVICE_CONFIGURATION_BY_SCHEMA_FAILED = "device_registry_client/get_desired_device_configuration_by_schema_failed",

  DELETE_ACTUAL_DEVICE_CONFIGURATION_BY_SCHEMA_REQUEST = "device_registry_client/delete_actual_device_configuration_by_schema_request",
  DELETE_ACTUAL_DEVICE_CONFIGURATION_BY_SCHEMA_SUCCESS = "device_registry_client/delete_actual_device_configuration_by_schema_success",
  DELETE_ACTUAL_DEVICE_CONFIGURATION_BY_SCHEMA_FAILED = "device_registry_client/delete_actual_device_configuration_by_schema_failed",

  DELETE_DESIRED_DEVICE_CONFIGURATION_BY_SCHEMA_REQUEST = "device_registry_client/delete_desired_device_configuration_by_schema_request",
  DELETE_DESIRED_DEVICE_CONFIGURATION_BY_SCHEMA_SUCCESS = "device_registry_client/delete_desired_device_configuration_by_schema_success",
  DELETE_DESIRED_DEVICE_CONFIGURATION_BY_SCHEMA_FAILED = "device_registry_client/delete_desired_device_configuration_by_schema_failed",

  EDIT_ACTUAL_DEVICE_DATA_BY_SCHEMA_REQUEST = "device_registry_client/edit_actual_device_data_by_schema_request",
  EDIT_ACTUAL_DEVICE_DATA_BY_SCHEMA_SUCCESS = "device_registry_client/edit_actual_device_data_by_schema_success",
  EDIT_ACTUAL_DEVICE_DATA_BY_SCHEMA_FAILED = "device_registry_client/edit_actual_device_data_by_schema_failed",

  EDIT_DESIRED_DEVICE_DATA_BY_SCHEMA_REQUEST = "device_registry_client/edit_desired_device_data_by_schema_request",
  EDIT_DESIRED_DEVICE_DATA_BY_SCHEMA_SUCCESS = "device_registry_client/edit_desired_device_data_by_schema_success",
  EDIT_DESIRED_DEVICE_DATA_BY_SCHEMA_FAILED = "device_registry_client/edit_desired_device_data_by_schema_failed",

  GET_DEVICE_METADATA_BY_SCHEMA_REQUEST = "device_registry_client/get_device_metadata_by_schema_request",
  GET_DEVICE_METADATA_BY_SCHEMA_SUCCESS = "device_registry_client/get_device_metadata_by_schema_success",
  GET_DEVICE_METADATA_BY_SCHEMA_FAILED = "device_registry_client/get_device_metadata_by_schema_failed",

  DELETE_DEVICE_METADATA_BY_SCHEMA_REQUEST = "device_registry_client/delete_device_metadata_by_schema_request",
  DELETE_DEVICE_METADATA_BY_SCHEMA_SUCCESS = "device_registry_client/delete_device_metadata_by_schema_success",
  DELETE_DEVICE_METADATA_BY_SCHEMA_FAILED = "device_registry_client/delete_device_metadata_by_schema_failed",

  EDIT_DEVICE_METADATA_BY_SCHEMA_REQUEST = "device_registry_client/edit_device_metadata_by_schema_request",
  EDIT_DEVICE_METADATA_BY_SCHEMA_SUCCESS = "device_registry_client/edit_device_metadata_by_schema_success",
  EDIT_DEVICE_METADATA_BY_SCHEMA_FAILED = "device_registry_client/edit_device_metadata_by_schema_failed",

  GET_REGION_CONFIGURATION_BY_SCHEMA_REQUEST = "device_registry_client/get_region_configuration_by_schema_request",
  GET_REGION_CONFIGURATION_BY_SCHEMA_SUCCESS = "device_registry_client/get_region_configuration_by_schema_success",
  GET_REGION_CONFIGURATION_BY_SCHEMA_FAILED = "device_registry_client/get_region_configuration_by_schema_failed",

  DELETE_REGION_CONFIGURATION_BY_SCHEMA_REQUEST =
    "device_registry_client/delete_region_configuration_by_schema_request",
  DELETE_REGION_CONFIGURATION_BY_SCHEMA_SUCCESS =
    "device_registry_client/delete_region_configuration_by_schema_success",
  DELETE_REGION_CONFIGURATION_BY_SCHEMA_FAILED = "device_registry_client/delete_region_configuration_by_schema_failed",

  EDIT_REGION_CONFIGURATION_BY_SCHEMA_REQUEST = "device_registry_client/edit_region_configuration_by_schema_request",
  EDIT_REGION_CONFIGURATION_BY_SCHEMA_SUCCESS = "device_registry_client/edit_region_configuration_by_schema_success",
  EDIT_REGION_CONFIGURATION_BY_SCHEMA_FAILED = "device_registry_client/edit_region_configuration_by_schema_failed",

  GET_TYPE_CONFIGURATION_BY_SCHEMA_REQUEST = "device_registry_client/get_type_configuration_by_schema_request",
  GET_TYPE_CONFIGURATION_BY_SCHEMA_SUCCESS = "device_registry_client/get_type_configuration_by_schema_success",
  GET_TYPE_CONFIGURATION_BY_SCHEMA_FAILED = "device_registry_client/get_type_configuration_by_schema_failed",

  DELETE_TYPE_CONFIGURATION_BY_SCHEMA_REQUEST =
    "device_registry_client/delete_type_configuration_by_schema_request",
  DELETE_TYPE_CONFIGURATION_BY_SCHEMA_SUCCESS =
    "device_registry_client/delete_type_configuration_by_schema_success",
  DELETE_TYPE_CONFIGURATION_BY_SCHEMA_FAILED = "device_registry_client/delete_type_configuration_by_schema_failed",

  EDIT_TYPE_CONFIGURATION_BY_SCHEMA_REQUEST = "device_registry_client/edit_type_configuration_by_schema_request",
  EDIT_TYPE_CONFIGURATION_BY_SCHEMA_SUCCESS = "device_registry_client/edit_type_configuration_by_schema_success",
  EDIT_TYPE_CONFIGURATION_BY_SCHEMA_FAILED = "device_registry_client/edit_type_configuration_by_schema_failed",
}

export const useGetDeviceConfigurationApi = (): GetDeviceConfigurationApi =>
  useApiRequestAnalytics(DeviceRegistryClient.getDeviceConfiguration, {
    REQUEST_EVENT: ActionType.GET_DEVICE_CONFIGURATION_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DEVICE_CONFIGURATION_SUCCESS,
    ERROR_EVENT: ActionType.GET_DEVICE_CONFIGURATION_FAILED,
  });

export const useGetActualDeviceConfigurationBySchemaApi = (): GetActualDeviceConfigurationBySchemaApi =>
  useApiRequestAnalytics(DeviceRegistryClient.getActualDeviceConfigurationBySchema, {
    REQUEST_EVENT: ActionType.GET_ACTUAL_DEVICE_CONFIGURATION_BY_SCHEMA_REQUEST,
    SUCCESS_EVENT: ActionType.GET_ACTUAL_DEVICE_CONFIGURATION_BY_SCHEMA_SUCCESS,
    ERROR_EVENT: ActionType.GET_ACTUAL_DEVICE_CONFIGURATION_BY_SCHEMA_FAILED,
  });

export const useGetDesiredDeviceConfigurationBySchemaApi = (): GetDesiredDeviceConfigurationBySchemaApi =>
  useApiRequestAnalytics(DeviceRegistryClient.getDesiredDeviceConfigurationBySchema, {
    REQUEST_EVENT: ActionType.GET_DESIRED_DEVICE_CONFIGURATION_BY_SCHEMA_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DESIRED_DEVICE_CONFIGURATION_BY_SCHEMA_SUCCESS,
    ERROR_EVENT: ActionType.GET_DESIRED_DEVICE_CONFIGURATION_BY_SCHEMA_FAILED,
  });

export const useDeleteActualDeviceConfigurationBySchemaApi = (): DeleteActualDeviceConfigurationBySchemaApi =>
  useApiRequestAnalytics(DeviceRegistryClient.deleteActualDeviceDataBySchema, {
    REQUEST_EVENT: ActionType.DELETE_ACTUAL_DEVICE_CONFIGURATION_BY_SCHEMA_REQUEST,
    SUCCESS_EVENT: ActionType.DELETE_ACTUAL_DEVICE_CONFIGURATION_BY_SCHEMA_SUCCESS,
    ERROR_EVENT: ActionType.DELETE_ACTUAL_DEVICE_CONFIGURATION_BY_SCHEMA_FAILED,
  });

export const useDeleteDesiredDeviceConfigurationBySchemaApi = (): DeleteDesiredDeviceConfigurationBySchemaApi =>
  useApiRequestAnalytics(DeviceRegistryClient.deleteDesiredDeviceDataBySchema, {
    REQUEST_EVENT: ActionType.DELETE_DESIRED_DEVICE_CONFIGURATION_BY_SCHEMA_REQUEST,
    SUCCESS_EVENT: ActionType.DELETE_DESIRED_DEVICE_CONFIGURATION_BY_SCHEMA_SUCCESS,
    ERROR_EVENT: ActionType.DELETE_DESIRED_DEVICE_CONFIGURATION_BY_SCHEMA_FAILED,
  });

export const useEditActualDeviceDataBySchemaApi = (): EditActualDeviceDataBySchemaApi =>
  useApiRequestAnalytics(DeviceRegistryClient.editActualDeviceDataBySchema, {
    REQUEST_EVENT: ActionType.EDIT_ACTUAL_DEVICE_DATA_BY_SCHEMA_REQUEST,
    SUCCESS_EVENT: ActionType.EDIT_ACTUAL_DEVICE_DATA_BY_SCHEMA_SUCCESS,
    ERROR_EVENT: ActionType.EDIT_ACTUAL_DEVICE_DATA_BY_SCHEMA_FAILED,
  });

export const useEditDesiredDeviceDataBySchemaApi = (): EditDesiredDeviceDataBySchemaApi =>
  useApiRequestAnalytics(DeviceRegistryClient.editDesiredDeviceDataBySchema, {
    REQUEST_EVENT: ActionType.EDIT_DESIRED_DEVICE_DATA_BY_SCHEMA_REQUEST,
    SUCCESS_EVENT: ActionType.EDIT_DESIRED_DEVICE_DATA_BY_SCHEMA_SUCCESS,
    ERROR_EVENT: ActionType.EDIT_DESIRED_DEVICE_DATA_BY_SCHEMA_FAILED,
  });

export const useGetDeviceMetadataBySchemaApi = (): GetDeviceMetadataBySchemaApi =>
  useApiRequestAnalytics(DeviceRegistryClient.getDeviceMetadataBySchema, {
    REQUEST_EVENT: ActionType.GET_DEVICE_METADATA_BY_SCHEMA_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DEVICE_METADATA_BY_SCHEMA_SUCCESS,
    ERROR_EVENT: ActionType.GET_DEVICE_METADATA_BY_SCHEMA_FAILED,
  });

export const useDeleteDeviceMetadataBySchemaApi = (): DeleteDeviceMetadataBySchemaApi =>
  useApiRequestAnalytics(DeviceRegistryClient.deleteDeviceMetadataBySchema, {
    REQUEST_EVENT: ActionType.DELETE_DEVICE_METADATA_BY_SCHEMA_REQUEST,
    SUCCESS_EVENT: ActionType.DELETE_DEVICE_METADATA_BY_SCHEMA_SUCCESS,
    ERROR_EVENT: ActionType.DELETE_DEVICE_METADATA_BY_SCHEMA_FAILED,
  });

export const useEditDeviceMetadataDataBySchemaApi = (): EditDeviceMetadataDataBySchemaApi =>
  useApiRequestAnalytics(DeviceRegistryClient.editDeviceMetadataBySchema, {
    REQUEST_EVENT: ActionType.EDIT_DEVICE_METADATA_BY_SCHEMA_REQUEST,
    SUCCESS_EVENT: ActionType.EDIT_DEVICE_METADATA_BY_SCHEMA_SUCCESS,
    ERROR_EVENT: ActionType.EDIT_DEVICE_METADATA_BY_SCHEMA_FAILED,
  });

export const useGetRegionConfigurationBySchemaApi = (): GetRegionConfigurationBySchemaApi =>
  useApiRequestAnalytics(DeviceRegistryClient.getRegionConfigurationBySchema, {
    REQUEST_EVENT: ActionType.GET_REGION_CONFIGURATION_BY_SCHEMA_REQUEST,
    SUCCESS_EVENT: ActionType.GET_REGION_CONFIGURATION_BY_SCHEMA_SUCCESS,
    ERROR_EVENT: ActionType.GET_REGION_CONFIGURATION_BY_SCHEMA_FAILED,
  });

export const useDeleteRegionConfigurationBySchemaApi = (): DeleteRegionConfigurationBySchemaApi =>
  useApiRequestAnalytics(DeviceRegistryClient.deleteRegionConfigurationBySchema, {
    REQUEST_EVENT: ActionType.DELETE_REGION_CONFIGURATION_BY_SCHEMA_REQUEST,
    SUCCESS_EVENT: ActionType.DELETE_REGION_CONFIGURATION_BY_SCHEMA_SUCCESS,
    ERROR_EVENT: ActionType.DELETE_REGION_CONFIGURATION_BY_SCHEMA_FAILED,
  });

export const useEditRegionConfigurationBySchemaApi = (): EditRegionConfigurationBySchemaApi =>
  useApiRequestAnalytics(DeviceRegistryClient.editRegionConfigurationBySchema, {
    REQUEST_EVENT: ActionType.EDIT_REGION_CONFIGURATION_BY_SCHEMA_REQUEST,
    SUCCESS_EVENT: ActionType.EDIT_REGION_CONFIGURATION_BY_SCHEMA_SUCCESS,
    ERROR_EVENT: ActionType.EDIT_REGION_CONFIGURATION_BY_SCHEMA_FAILED,
  });

export const useGetTypeConfigurationBySchemaApi = (): GetTypeConfigurationBySchemaApi =>
  useApiRequestAnalytics(DeviceRegistryClient.getTypeConfigurationBySchema, {
    REQUEST_EVENT: ActionType.GET_TYPE_CONFIGURATION_BY_SCHEMA_REQUEST,
    SUCCESS_EVENT: ActionType.GET_TYPE_CONFIGURATION_BY_SCHEMA_SUCCESS,
    ERROR_EVENT: ActionType.GET_TYPE_CONFIGURATION_BY_SCHEMA_FAILED,
  });

export const useDeleteTypeConfigurationBySchemaApi = (): DeleteTypeConfigurationBySchemaApi =>
  useApiRequestAnalytics(DeviceRegistryClient.deleteTypeConfigurationBySchema, {
    REQUEST_EVENT: ActionType.DELETE_TYPE_CONFIGURATION_BY_SCHEMA_REQUEST,
    SUCCESS_EVENT: ActionType.DELETE_TYPE_CONFIGURATION_BY_SCHEMA_SUCCESS,
    ERROR_EVENT: ActionType.DELETE_TYPE_CONFIGURATION_BY_SCHEMA_FAILED,
  });

export const useEditTypeConfigurationBySchemaApi = (): EditTypeConfigurationBySchemaApi =>
  useApiRequestAnalytics(DeviceRegistryClient.editTypeConfigurationBySchema, {
    REQUEST_EVENT: ActionType.EDIT_TYPE_CONFIGURATION_BY_SCHEMA_REQUEST,
    SUCCESS_EVENT: ActionType.EDIT_TYPE_CONFIGURATION_BY_SCHEMA_SUCCESS,
    ERROR_EVENT: ActionType.EDIT_TYPE_CONFIGURATION_BY_SCHEMA_FAILED,
  });

export const useDeviceRegistryClient = (): DeviceRegistryClientApi => ({
  getDeviceConfiguration: useGetDeviceConfigurationApi(),
  getActualDeviceConfigurationBySchema: useGetActualDeviceConfigurationBySchemaApi(),
  getDesiredDeviceConfigurationBySchema: useGetDesiredDeviceConfigurationBySchemaApi(),
  deleteActualDeviceDataBySchema: useDeleteActualDeviceConfigurationBySchemaApi(),
  deleteDesiredDeviceDataBySchema: useDeleteDesiredDeviceConfigurationBySchemaApi(),
  editActualDeviceDataBySchema: useEditActualDeviceDataBySchemaApi(),
  editDesiredDeviceDataBySchema: useEditDesiredDeviceDataBySchemaApi(),
  getDeviceMetadataBySchema: useGetDeviceMetadataBySchemaApi(),
  deleteDeviceMetadataBySchema: useDeleteDeviceMetadataBySchemaApi(),
  editDeviceMetadataDataBySchema: useEditDeviceMetadataDataBySchemaApi(),
  getRegionConfigurationBySchema: useGetRegionConfigurationBySchemaApi(),
  deleteRegionConfigurationBySchema: useDeleteRegionConfigurationBySchemaApi(),
  editRegionConfigurationBySchema: useEditRegionConfigurationBySchemaApi(),
  getTypeConfigurationBySchema: useGetTypeConfigurationBySchemaApi(),
  deleteTypeConfigurationBySchema: useDeleteTypeConfigurationBySchemaApi(),
  editTypeConfigurationBySchema: useEditTypeConfigurationBySchemaApi(),
});

export { ActionType as DeviceRegistryClientActionType };

export default useDeviceRegistryClient;
