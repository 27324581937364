import { AuditEventAttributes } from "@data";
import { AuditServiceClient } from "@network";
import { useApiRequestAnalytics } from "@hooks";

export type GetAuditEventApi = (auditEventId: string) => Promise<AuditEventAttributes>;

export interface AuditEventClientApi {
  getAuditEvent: GetAuditEventApi;
}

enum ActionTypes {
  GET_AUDIT_EVENT_REQUEST = "audit_event_client/get_audit_event_request",
  GET_AUDIT_EVENT_SUCCESS = "audit_event_client/get_audit_event_success",
  GET_AUDIT_EVENT_FAILED = "audit_event_client/get_audit_event_failed",
}

export const useGetAuditEventApi = () =>
  useApiRequestAnalytics(AuditServiceClient.getEvent, {
    REQUEST_EVENT: ActionTypes.GET_AUDIT_EVENT_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_AUDIT_EVENT_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_AUDIT_EVENT_FAILED,
  });

export const useAuditEventClient = (): AuditEventClientApi => ({
  getAuditEvent: useGetAuditEventApi(),
});

export { ActionTypes as AuditEventClientActionTypes };

export default useAuditEventClient;
