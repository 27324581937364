import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { Authenticator, AuthenticatorRequirement, UserAdministrationUserType } from "@data";
import {
  getUserId,
  isPageRefresh,
} from "../selectors";
import {
  setDeleteAuthenticationDialog,
  setPageRefresh,
  setShowLoadingIndicator,
  setUpdateMfaDialog as updateMfaDialog,
} from "../actions";
import UserAuthenticationSummaryView, {
  Actions,
  Model
} from "../components/Authentication/UserAuthenticationSummaryView";
import DeleteAuthenticatorDialog from "./DeleteAuthenticatorDialog";
import UpdateMfaDialog from "./UpdateMfaDialog";
import MultiFactorAuthentication from "../components/Authentication/MultiFactorAuthentication";
import { getStringValue, isEmptyString, noop } from "@util";
import {
  AUTHENTICATOR_ACTION_MENU_ITEMS,
  AuthenticatorList,
  usePortalSnackbar,
} from "@components";
import {
  useDeleteAuthenticatorService,
  useGetUserAdminInfoService,
  useUpdateUserMfaRequirement,
} from "@hooks";

interface ContainerModel extends Model {
  userId?: string;
  snackbarId?: string;
  hidden?: boolean;
  refresh?: boolean;
}

export interface ContainerActions extends Actions {
  setDeleteAuthenticatorDialog?: (value: boolean) => void;
  setUpdateMfaDialog?: (value: boolean) => void;
  setLoading?: (value: boolean) => void;
  setRefresh?: (value: boolean) => void;
}

type Props = ContainerModel & ContainerActions;

const UserAuthenticationSummaryViewContainer = (props: Props) => {

  const {
    userId = "",
    snackbarId = "authentication-view",
    hidden,
    refresh,
    setDeleteAuthenticatorDialog = noop,
    setUpdateMfaDialog = noop,
    setLoading = noop,
    setRefresh = noop,
    ...otherProps
  } = props;

  if (hidden) {
    return null;
  }

  const [adminInfoError, setAdminInfoError] = React.useState("");
  const [updateMfaError, setUpdateMfaError] = React.useState("");
  const [deleteMfaError, setDeleteMfaError] = React.useState("");
  const [updateSuccess, setUpdateSuccess] = React.useState("");
  const [deleteSuccess, setDeleteSuccess] = React.useState("");
  const [authenticatorId, setAuthenticatorId] = React.useState("");
  const [json, setJson] = React.useState("");

  const [adminInfoModel, adminInfoActions] = useGetUserAdminInfoService({userId});

  const {
    userAdministration,
    errorMessage: adminInfoErrorMessage = "",
    showLoadingIndicator: adminInfoLoadingIndicator
  } = adminInfoModel;

  const { refresh: refreshAdminInfo } = adminInfoActions;

  const clearErrors = React.useCallback(() => {
    setAdminInfoError("");
    setUpdateMfaError("");
    setDeleteMfaError("");
  }, [
    setAdminInfoError,
    setUpdateMfaError,
    setDeleteMfaError,
  ]);

  const clearSuccessMessages = React.useCallback(() => {
    setUpdateSuccess("");
    setDeleteSuccess("");
  }, [
    setUpdateSuccess,
    setDeleteSuccess,
  ]);

  const [updateMfaModel , updateMfaActions] = useUpdateUserMfaRequirement({
    userId,
    json,
  });

  const {
    successMessage: updateMfaSuccessMessage,
    errorMessage: updateMfaErrorMessage = "",
    showLoadingIndicator: updateMfaLoadingIndicator
  } = updateMfaModel;

  const {
    updateMfaRequirement
  } = updateMfaActions;

  const [ deleteAuthenticatorModel, deleteAuthenticatorActions] = useDeleteAuthenticatorService({
    userId,
    authenticatorId
  });

  const {
    successMessage: deleteAuthenticatorSuccessMessage,
    errorMessage: deleteAuthenticatorErrorMessage = "",
    showLoadingIndicator: deleteAuthenticatorLoadingIndicator
  } = deleteAuthenticatorModel;

  const {
    deleteAuthenticator
  } = deleteAuthenticatorActions;

  const authenticators = React.useMemo(() => {
      const authenticatorAttributes = userAdministration.getAuthentication().secondary.authenticators;
      if (authenticatorAttributes === undefined) {
        return [];
      } else {
        return authenticatorAttributes.map(attrs => new Authenticator(attrs));
      }
      } ,
    [userAdministration]);

  const mfaStatus = React.useMemo(() => {
    return userAdministration.getAuthentication().secondary.administrativeRequirement;
  }, [userAdministration]);

  const anonymousId = React.useMemo(() =>
      getStringValue(userAdministration.getIdentity().anonymousId),
    [userAdministration]);

  const lastSuccessfulLogin = React.useMemo(() =>
      getStringValue(userAdministration.getActivity().login.lastSuccessful),
    [userAdministration]);

  const lastFailedLogin = React.useMemo(() =>
      getStringValue(userAdministration.getActivity().login.lastFailed),
    [userAdministration]);

  const lastPasswordChange = React.useMemo(() =>
      getStringValue(userAdministration.getActivity().password.lastChanged),
    [userAdministration]);

  const createdAt = React.useMemo(() =>
      getStringValue(userAdministration.getActivity().events.created),
    [userAdministration]);

  const updatedAt = React.useMemo(() =>
      getStringValue(userAdministration.getActivity().events.updated),
    [userAdministration]);

  const userType = React.useMemo(() =>
    userAdministration.getIdentity().userType , [userAdministration]);

  const externalUser =  React.useMemo(() => userType === UserAdministrationUserType.EXTERNAL , [userType]);

  const failedLogins = React.useMemo(() => {
      const failuresSinceLastSuccessfulLogin = userAdministration.getMetrics().login.failuresSinceLastSuccessfulLogin;
      if (failuresSinceLastSuccessfulLogin === undefined) {
        return 0;
      } else {
        return failuresSinceLastSuccessfulLogin;
      }
    } ,
    [userAdministration]);

  const onSelectDeleteAction = React.useCallback((authenticator: Authenticator) => {
    setAuthenticatorId(authenticator.getId());
    setDeleteAuthenticatorDialog(true);
  }, [
    setAuthenticatorId,
    setDeleteAuthenticatorDialog,
  ]);

  const onDeleteConfirm = React.useCallback(() => {
    clearSuccessMessages();
    clearErrors();
    deleteAuthenticator();
    setDeleteAuthenticatorDialog(false);
  }, [
    deleteAuthenticator,
    setDeleteAuthenticatorDialog,
    clearSuccessMessages,
    clearErrors,
  ]);

  const onDeleteSuccess = React.useCallback(() => {
    refreshAdminInfo();
  }, [refreshAdminInfo]);

  const onUpdateMfaConfirm = React.useCallback(() => {
    clearSuccessMessages();
    clearErrors();
    updateMfaRequirement();
    setUpdateMfaDialog(false);
  }, [
    updateMfaRequirement,
    setUpdateMfaDialog,
    clearSuccessMessages,
    clearErrors,
  ]);

  const onUpdateSuccess = React.useCallback(() => {
    refreshAdminInfo();
  }, [refreshAdminInfo]);

  const onChangeMfaRequirement = React.useCallback(() => {
    const mfaRequirement = userAdministration.getAuthentication().secondary.administrativeRequirement;
    if (mfaRequirement === AuthenticatorRequirement.NONE) {
      setJson(JSON.stringify({administrativeRequirement: "REQUIRED"}, null, "  "));
    } else {
      setJson(JSON.stringify({administrativeRequirement: "NONE"}, null, "  "));
    }
    setUpdateMfaDialog(true);
  }, [
    userAdministration,
    setJson,
    setUpdateMfaDialog
  ]);

  const showMfaAndAuthenticator = React.useMemo(() => {
    return isEmptyString(adminInfoErrorMessage);
  }, [adminInfoErrorMessage]);

  const successMessage = React.useMemo(() => {
    if (!isEmptyString(updateSuccess)) {
      return updateSuccess;
    } else if (!isEmptyString(deleteSuccess)) {
      return deleteSuccess;
    } else {
      return "";
    }
  }, [updateSuccess, deleteSuccess]);

  const errorMessage = React.useMemo(() => {
    if (!isEmptyString(adminInfoError)) {
      return adminInfoError;
    } else if (!isEmptyString(deleteMfaError)) {
      return deleteMfaError;
    } else if (!isEmptyString(updateMfaError)) {
      return updateMfaError;
    } else {
      return "";
    }
  }, [adminInfoError, deleteMfaError, updateMfaError]);

  usePortalSnackbar(snackbarId, {
    errorMessage,
    successMessage,
    successCallbackDelay: 750,
  });

  const isLoading = React.useMemo(() => {
    return adminInfoLoadingIndicator || deleteAuthenticatorLoadingIndicator || updateMfaLoadingIndicator;
  }, [adminInfoLoadingIndicator, deleteAuthenticatorLoadingIndicator, updateMfaLoadingIndicator]);

  React.useEffect(() => {
    setAdminInfoError(adminInfoErrorMessage);
  }, [adminInfoErrorMessage]);

  React.useEffect(() => {
    setUpdateMfaError(updateMfaErrorMessage);
  }, [updateMfaErrorMessage]);

  React.useEffect(() => {
    setDeleteMfaError(deleteAuthenticatorErrorMessage);
  }, [deleteAuthenticatorErrorMessage]);

  React.useEffect(() => {
    setDeleteSuccess(deleteAuthenticatorSuccessMessage);
  }, [deleteAuthenticatorSuccessMessage]);

  React.useEffect(() => {
    setUpdateSuccess(updateMfaSuccessMessage);
  }, [updateMfaSuccessMessage]);

  React.useEffect(() => {
    refreshAdminInfo();
  }, [updateMfaSuccessMessage, deleteAuthenticatorSuccessMessage]);

  React.useEffect(() => {
    setRefresh(false);
    refreshAdminInfo();
  }, [refresh]);

  return (
    <UserAuthenticationSummaryView
      {...otherProps}
      userId={userId}
      errorMessage={errorMessage}
      anonymousId={anonymousId}
      lastSuccessfulLogin={lastSuccessfulLogin}
      lastFailedLogin={lastFailedLogin}
      lastPasswordChange={lastPasswordChange}
      userType={userType}
      createdAt={createdAt}
      updatedAt={updatedAt}
      failedLogins={failedLogins}
      loading={isLoading}
    >
      {showMfaAndAuthenticator && (
        <React.Fragment>
          <MultiFactorAuthentication
            onChange={onChangeMfaRequirement}
            mfaRequired={mfaStatus}
            externalUser={externalUser}
          />
          <AuthenticatorList
            items={authenticators}
            actions={AUTHENTICATOR_ACTION_MENU_ITEMS}
            deleteAuthenticator={onSelectDeleteAction}
            showSummary={true}
            showSearch={true}
            showRefreshButton={false}
            showSortButton={true}
          />
        </React.Fragment>
      )}
      <DeleteAuthenticatorDialog
        authenticatorId={authenticatorId}
        deleteAuthenticator={onDeleteConfirm}
        onDeleteSuccess={onDeleteSuccess}
      />
      <UpdateMfaDialog
        userId={userId}
        mfaStatus={mfaStatus}
        updateAuthenticationRequirement={onUpdateMfaConfirm}
        onUpdateSuccess={onUpdateSuccess}
      />
    </UserAuthenticationSummaryView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  userId: getUserId(state),
  refresh: isPageRefresh(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setUpdateMfaDialog: (value: boolean) => dispatch(updateMfaDialog(value)),
  setDeleteAuthenticatorDialog: (value: boolean) => dispatch(setDeleteAuthenticationDialog(value)),
  setLoading: (value: boolean) => dispatch(setShowLoadingIndicator(value)),
  setRefresh: (value: boolean) => dispatch(setPageRefresh(value)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(UserAuthenticationSummaryViewContainer);
