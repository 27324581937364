import { Module } from "@data";
import { createModule } from "@modules/base";
import view from "./CreateWorkloadWizardView";
import CloneWorkloadWizardView from "./CloneWorkloadWizardView";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import DATA_WORKLOADS_REQUIREMENTS from "@modules/dataWorkloads/requirements";
import {
  CLONE_WORKLOAD_WIZARD_MODULE_ID,
  CLONE_WORKLOAD_WIZARD_MODULE_PATH,
  CloneWorkloadRouteParams,
  CREATE_WORKLOAD_WIZARD_MODULE_ID,
  CREATE_WORKLOAD_WIZARD_MODULE_PATH,
} from "./constants";
import { DATA_PROCESSING_DEVELOPER_GUIDE } from "@modules/dataWorkloads/constants";

export const createWorkloadWizardModule: Module = createModule({
  ...DATA_WORKLOADS_REQUIREMENTS,
  id: CREATE_WORKLOAD_WIZARD_MODULE_ID,
  path: CREATE_WORKLOAD_WIZARD_MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Create Workload",
  title: "Create New Workload",
  className: "createWorkloadModule",
  documentationLink: DATA_PROCESSING_DEVELOPER_GUIDE,
  primaryColor,
  icon,
  view,
});

export const cloneWorkloadWizardModule: Module = createModule<CloneWorkloadRouteParams>({
  ...DATA_WORKLOADS_REQUIREMENTS,
  id: CLONE_WORKLOAD_WIZARD_MODULE_ID,
  path: CLONE_WORKLOAD_WIZARD_MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Clone Workload Wizard",
  title: "Clone Workload",
  className: "cloneWorkloadModule",
  documentationLink: DATA_PROCESSING_DEVELOPER_GUIDE,
  primaryColor,
  icon,
  view: CloneWorkloadWizardView,
});

export default createWorkloadWizardModule;
