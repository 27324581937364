import React from "react";
import { noop } from "@util";
import { DataAccessRequest } from "@data";
import { ActionMenuItem } from "../actions-menu";
import DataAccessRequestsListColumn from "./DataAccessRequestsListColumn";
import { PrimaryIcon } from "@modules/dataAccessRequests/components/styles";
import { DataAccessRequestAction } from "../data-access-request-actions-menu";
import { getPathToDataSetAccessRequest } from "@modules/dataAccessRequests/helpers";
import ModuleListView, {
  createColumns,
  ListViewItem,
  ModuleListViewActions,
  ModuleListViewModel,
} from "@components/module-list-view";

type Item = DataAccessRequest;
type Column = DataAccessRequestsListColumn;

export const DEFAULT_DATA_ACCESS_REQUESTS_LIST_COLUMNS = [
  DataAccessRequestsListColumn.NAME,
  DataAccessRequestsListColumn.ACCOUNT,
  DataAccessRequestsListColumn.STATE,
  DataAccessRequestsListColumn.TYPE,
  DataAccessRequestsListColumn.PRINCIPAL_TYPE,
];

export interface DataAccessRequestsListModel extends ModuleListViewModel<Item, Column> {
}

export interface DataAccessRequestsListActions extends ModuleListViewActions<Item, Column> {
  showDataAccessRequestDetails?: (dataAccessRequest: DataAccessRequest) => void;
  deleteDataAccessRequest?: (dataAccessRequest: DataAccessRequest) => void;
}

type Model = DataAccessRequestsListModel;
type Actions = DataAccessRequestsListActions;
type Props = Model & Actions;

export const DataAccessRequestsList = (props: Props) => {

  const {
    items = [],
    columns = DEFAULT_DATA_ACCESS_REQUESTS_LIST_COLUMNS,
    nameFilter: initialNameFilter = "",
    noResultsLabel = "No Access Requests Found",
    summaryViewQuantities = {
      other: "data access requests",
      one: "data access request",
    },
    showDataAccessRequestDetails,
    deleteDataAccessRequest = noop,
    onClickAction = noop,
    setNameFilter: updateNameFilter = noop,
    ...otherProps
  } = props;

  const [nameFilter, setNameFilter] = React.useState(initialNameFilter);

  const onActionClicked = React.useCallback((item: DataAccessRequest, action: ActionMenuItem) => {
    if (action.id === DataAccessRequestAction.DELETE) {
      deleteDataAccessRequest(item);
    } else {
      onActionClicked(item, action);
    }
  }, [deleteDataAccessRequest, onClickAction]);

  // In case parent is maintaining state, make sure local state changes are propagated up
  React.useEffect(() => {
    updateNameFilter(nameFilter);
  }, [nameFilter, updateNameFilter]);

  const moduleListItems: ListViewItem<DataAccessRequest>[] = React.useMemo(() =>
    items.map(request => {
      return {
        item: request,
        pathToDetailsView: getPathToDataSetAccessRequest(request.getAccessRequestId()),
        icon: PrimaryIcon,
        columnAttributes: createColumns([
          {
            className: "requestName",
            value: request.getDataSetAlias(),
            column: DataAccessRequestsListColumn.NAME,
            firstColumn: true,
          },
          {
            className: "requestId",
            value: request.getAccountId(),
            column: DataAccessRequestsListColumn.ACCOUNT,
          },
          {
            className: "requestState",
            value: request.getRequestState(),
            column: DataAccessRequestsListColumn.STATE,
          },
          {
            className: "requestType",
            value: request.getAccessRequestType(),
            column: DataAccessRequestsListColumn.TYPE,
          },
          {
            className: "requestPrincipal",
            value: request.getAccessRequestPrincipalType(),
            column: DataAccessRequestsListColumn.PRINCIPAL_TYPE,
          },
        ]),
      };
    }), [items]);

  return (
    <ModuleListView
      {...otherProps}
      columns={columns}
      summaryViewIcon={PrimaryIcon}
      listViewItems={moduleListItems}
      nameFilter={nameFilter}
      noResultsLabel={noResultsLabel}
      summaryViewQuantities={summaryViewQuantities}
      onClickItem={showDataAccessRequestDetails}
      onClickAction={onActionClicked}
      setNameFilter={setNameFilter}
    />
  );
};

export default DataAccessRequestsList;
