import { Module } from "@data";
import { createModule } from "@modules/base";
import {
  ADD_TRIGGER_MODULE_PATHS,
  EDIT_TRIGGER_MODULE_PATHS,
  MODULE_ID,
  RouteParams,
} from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import DATA_WORKLOADS_REQUIREMENTS from "@modules/dataWorkloads/requirements";
import view from "./AddTriggerView";
import EditTriggerView from "./EditTriggerView";
import { DATA_PROCESSING_DEVELOPER_GUIDE } from "@modules/dataWorkloads/constants";

export const addTriggerModule: Module = createModule<RouteParams>({
  ...DATA_WORKLOADS_REQUIREMENTS,
  id: MODULE_ID,
  path: ADD_TRIGGER_MODULE_PATHS,
  exactPath: true,
  strictPath: true,
  name: "Add Trigger",
  title: "Add Trigger",
  className: "addTriggerModule",
  documentationLink: DATA_PROCESSING_DEVELOPER_GUIDE,
  primaryColor,
  icon,
  view,
});

export const editTriggerModule: Module = createModule<RouteParams>({
  ...DATA_WORKLOADS_REQUIREMENTS,
  id: "edit_trigger",
  path: EDIT_TRIGGER_MODULE_PATHS,
  exactPath: true,
  strictPath: true,
  name: "Edit Trigger",
  title: "Edit Trigger",
  className: "editTriggerModule",
  documentationLink: DATA_PROCESSING_DEVELOPER_GUIDE,
  primaryColor,
  icon,
  view: EditTriggerView,
});

export default addTriggerModule;
