import { Module } from "@data";
import { reset } from "./actions";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./styles";
import { isAccessDeniedVisible, isEmptyViewVisible } from "./selectors";
import CreatePolicyView from "./CreatePolicyView";

export const createPolicyModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Create Policy",
  title: "Create New Policy",
  className: "createPolicyModule",
  primaryColor,
  icon,
  view: CreatePolicyView,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: reset,
});

export default createPolicyModule;
