import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { DeviceTypeWizardSelectors } from "../selectors";
import DeviceTypeEditorView, { Actions, Model } from "../components/DeviceTypeEditorView";
import JsonEditorView from "./JsonEditorView";

interface ContainerModel extends Model {
  hidden?: boolean;
}

const DeviceTypeEditorViewContainer = (props: ContainerModel & Actions) => {

  const { hidden, ...otherProps } = props;

  if (hidden) {
    return null;
  }

  return (
    <DeviceTypeEditorView {...otherProps}>
      <JsonEditorView/>
    </DeviceTypeEditorView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  hidden: !DeviceTypeWizardSelectors.isDeviceTypeEditorViewSelected(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any): Actions => ({
});

export default connect<ContainerModel, Actions, ContainerModel & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceTypeEditorViewContainer);
