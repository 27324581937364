import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import FileUploadView , { Actions, Model } from "../components/FileUploadView";
import { DeviceEnrollmentWizardSelectors } from "../selectors";
import { DeviceEnrollmentWizardActions } from "../actions";

interface ContainerModel extends Model {
  hidden?: boolean;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const FileUploadViewContainer = (props: Props) => {

  const { hidden, ...otherProps } = props;

  if (hidden) {
    return null;
  }

  return <FileUploadView {...otherProps} />;
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => {

  const hidden = !DeviceEnrollmentWizardSelectors.isFileUploadViewSelected(state);

  return {
    hidden,
    isFileUploaded: DeviceEnrollmentWizardSelectors.isFileUploaded(state),
    errorMessage: DeviceEnrollmentWizardSelectors.getErrorMessage(state),
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions) => ({
  setFile: (file: File) => dispatch(DeviceEnrollmentWizardActions.setFile(file)),
  setErrorMessage: (error: string) => dispatch(DeviceEnrollmentWizardActions.setErrorMessage(error)),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadViewContainer);
