import React from "react";
import Fab from "@components/fab";
import classnames from "classnames";
import SaveIcon from "@material-ui/icons/Save";
import ReviewViewColumns from "./ReviewViewColumns";
import CheckmarkIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/PriorityHigh";
import { GroupStatus, SecurityGroupBulkRequest } from "@data";
import CircularProgress from "@components/circular-progress";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export const ReviewViewListItem = withStyles(styles)((props: WithStyles<typeof styles> & {
  groupBulkRequest?: SecurityGroupBulkRequest;
  column?: ReviewViewColumns
}) => {

  const { column, groupBulkRequest = SecurityGroupBulkRequest.EMPTY, classes } = props;

  if ( !column || SecurityGroupBulkRequest.EMPTY.equals(groupBulkRequest)) {
    return null;
  }

  const status = groupBulkRequest.getGroupStatus();
  const success = status === GroupStatus.SUCCESS;
  const loading = status === GroupStatus.PROCESSING;
  const error = status === GroupStatus.FAILED;

  switch (column) {
    case ReviewViewColumns.NAME:
      return (
        <label
          className={classnames("group", classes.label, {
            [classes.failedLabel]: error,
          })}
        >
          {groupBulkRequest.getSecurityGroup().getName()}
        </label>
      );
    case ReviewViewColumns.STATUS:
      return (
        <Fab
          color="primary"
          className={classnames("fab", classes.fab, {
            [classes.success]: success,
            [classes.failed]: error,
          })}
        >
          {success && !error && <CheckmarkIcon className={classnames(classes.icons, classes.successIcon)} />}
          {!success && error && <ErrorIcon className={classnames(classes.icons, classes.errorIcon)} />}
          {!success && !error && <SaveIcon className={classes.icons} />}
          {loading && <CircularProgress size={32} className={classes.progressIndicator} />}
        </Fab>
      );
    case ReviewViewColumns.ERRORS:
      return (
        <label
          className={classnames("errors", classes.label, {
            [classes.failedLabel]: error,
          })}
        >
          {groupBulkRequest.getErrorMessage()}
        </label>
      );
    default:
      return null;
  }
});

export default ReviewViewListItem;
