import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import {
  getErrorMessage,
  getNamespace,
  isNamespaceValid,
  isProgressIndicatorVisible,
} from "../selectors";
import { clearErrorMessage, save, setNamespace } from "../actions";
import { NamespaceInfo, Actions, Model } from "../components/NamespaceInfo";

export interface ContainerModel extends Model {
}

export interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => {
  const errorMessageNamespace = getErrorMessage(state);
  const namespaceNameError =
    !errorMessageNamespace || isNamespaceValid(state) ? "" : errorMessageNamespace;

  return {
    namespaceName: getNamespace(state),
    namespaceNameError,
    readOnly: isProgressIndicatorVisible(state),
    errorMessage: getErrorMessage(state),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setNamespaceName: (name: string) => {
    dispatch(setNamespace(name));
    dispatch(clearErrorMessage());
  },
  retry: () => dispatch(save()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(NamespaceInfo);
