import AppSchema from "@schemas";
import { ActionType } from "@util";

const getPersistedModuleIds = (state: AppSchema): string[] => {
  return state.persistedModuleIds;
};

const setPersistedModuleIds = (ids: string[] = []) => ({
  type: ActionType.UPDATE_PERSISTED_MODULE_IDS,
  value: ids,
});

export const addPersistedModuleId = (id: string) => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const persistedModuleIds = getPersistedModuleIds(state);
  if (persistedModuleIds.indexOf(id) >= 0) {
    return Promise.resolve();
  } else {
    return dispatch(setPersistedModuleIds(persistedModuleIds.concat([id])));
  }
};

export default addPersistedModuleId;
