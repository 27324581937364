import { isEmptyString } from "@util";
import {
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withApplicationIdHeader,
  withAuthToken,
  withRequiredArguments,
} from "@network/helpers";
import { UserAdministrationAttributes } from "@data";

const REGIONAL_API = process.env.REACT_APP_REGIONAL_API || "";

if (isEmptyString(REGIONAL_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_REGIONAL_API");
}

export const closeUserAccount = (authToken?: string,
                                 userId?: string,
                                 selfAuthorized?: boolean): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
    ]));

  const makeRequest = () => {

    const url = selfAuthorized
      ? `${REGIONAL_API}/security/identity/v1/users/me`
      : `${REGIONAL_API}/security/identity/v1/users/${userId}/administration/close`;

    const settings = selfAuthorized ?
      {
        method: "DELETE",
        headers: withApplicationIdHeader({
          "Authorization": `Bearer ${authToken}`,
          "Accept": "application/json",
        }),
      }
      :
      {
      method: "POST",
      headers: withApplicationIdHeader({
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      }),
    };

    const defaultErrorMessage = "Failed to close user account";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getUserAdminInfo = (authToken: string,
                                 userId: string): Promise<UserAdministrationAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/users/${userId}/administration`;

    const settings = {
      method: "GET",
      headers: withApplicationIdHeader({
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      }),
    };

    const defaultErrorMessage = "Failed to get user administration info";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const updateUserMfaRequirement = (authToken: string,
                                         userId: string,
                                         json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/users/${userId}/administration/mfa/requirement`;

    const settings = {
      method: "PUT",
      headers: withApplicationIdHeader({
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
      }),
      body: json,
    };

    const defaultErrorMessage = "Failed to update user Mfa requirement";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteAuthenticator = (authToken: string,
                                    userId: string,
                                    authenticatorId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["Authenticator ID", authenticatorId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/users/${userId}/administration/mfa/authenticators/${authenticatorId}`;

    const settings = {
      method: "DELETE",
      headers: withApplicationIdHeader({
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      }),
    };

    const defaultErrorMessage = "Failed to delete authenticator";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
