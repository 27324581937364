import { AppSchema } from "@main/schemas";
import { getProviderId } from "./selectors";
import { createActions } from "@modules/base/createActions";
import { getAuthToken } from "@main/selectors";
import { FederationProviderClient, RestClientError } from "@network";
import { FederationProvider, FederationProviderAttributes } from "@data";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { open as openDeleteFederationProviderModule } from "@modules/deleteFederationProvider/actions";

export const {
  federationProvider: setFederationProvider,
  setErrorMessage,
  setSuccessMessage,
  showLoadingIndicator,
  hideLoadingIndicator,
  showNotFound,
  hideNotFound,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  FETCH_FEDERATION_PROVIDER_DETAILS_REQUEST: fetchFederationProviderDetailsRequest,
  FETCH_FEDERATION_PROVIDER_DETAILS_SUCCESS: fetchFederationProviderDetailsSuccess,
  FETCH_FEDERATION_PROVIDER_DETAILS_FAILURE: fetchFederationProviderDetailsFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setFederationProvider());
  return dispatch(baseReset());
};

export const deleteFederationProvider = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(openDeleteFederationProviderModule(getProviderId(getState())));

const fetchFederationProviderDetails = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const providerId = getProviderId(state);
  const authToken = getAuthToken(state);

  dispatch(showLoadingIndicator());
  dispatch(setErrorMessage());
  dispatch(hideNotFound());
  dispatch(hideAccessDenied());
  dispatch(fetchFederationProviderDetailsRequest());

  return FederationProviderClient.getFederationProvider(authToken, providerId)
    .then((attrs: FederationProviderAttributes) => {

      dispatch(fetchFederationProviderDetailsSuccess());
      dispatch(setFederationProvider(new FederationProvider(attrs).toJS()));
      dispatch(hideLoadingIndicator());
      return dispatch(hideEmptyView());

    }, (response: RestClientError) => {

      const { analytic, error = "Fetch federation provider details failed" } = response;

      dispatch(fetchFederationProviderDetailsFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(hideLoadingIndicator());

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      if (response.status === 404) {
        dispatch(showNotFound());
      }

      return dispatch(hideEmptyView());
    });
};

export const refresh = () => (dispatch: any) => {
  return dispatch(fetchFederationProviderDetails());
};

export const initialize = (providerId: string) => (dispatch: any) => {

  dispatch(reset());
  dispatch(setFederationProvider(new FederationProvider({ providerId: providerId }).toJS()));
  return dispatch(refresh());
};
