import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      width: 40,
      height: 40,
      marginLeft: 15,
    },
    icon: {
      color: Color.SIGNIFY_GREEN,
      width: 30,
      height: 30,
    },
  });

export default styles;
