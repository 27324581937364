import createStyles from "@material-ui/core/styles/createStyles";
import Color from "../styles/Color";

export const styles = () =>
  createStyles({
    maintenanceReminder: {
      zIndex: 999999,
      background: Color.YELLOW,
      position: "fixed",
      width: "100%",
      fontSize: "15px",
    },
    maintenanceLabel: {
      width: "auto",
      color: Color.BLACK,
      fontWeight: 500,
      marginLeft: 4.5,
      marginRight: 4.5,
      "& .MuiInput-input": {
        textAlign: "center",
      }
    },
    maintenanceIcon: {
      color: Color.BLACK,
    },
  });

export default styles;
