import React from "react";
import { GetDeviceDataResponse } from "@network";
import { DeviceDataType } from "@components";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  useDeviceRegistryClient,
} from "@hooks";
import { DeviceDataScope } from "@data";

type SuccessResponse = GetDeviceDataResponse;

export interface UseDeviceDataBySchemaServiceProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  deviceId: string;
  schemaIdentity: string;
  deviceDataType: DeviceDataType;
  typeIdentity?: string;
  deviceDataScope?: DeviceDataScope;
}

export interface UseDeviceDataBySchemaServiceModel extends UseApiRequestModel<SuccessResponse> {
  deviceData: string;
}

export interface UseDeviceDataBySchemaServiceActions {
  getDeviceData: () => void;
}

type Props = UseDeviceDataBySchemaServiceProps;
type Model = UseDeviceDataBySchemaServiceModel;
type Actions = UseDeviceDataBySchemaServiceActions;
type Result = [Model, Actions];

export const useDeviceDataBySchemaService = (props: Props): Result => {

  const {
    deviceDataType,
    deviceDataScope = DeviceDataScope.DEVICE,
    deviceId,
    schemaIdentity,
    typeIdentity = "",
    ...otherProps
  } = props;

  const DeviceRegistryClient = useDeviceRegistryClient();

  const defaultErrorMessage = React.useMemo(() =>
    `Failed to get ${deviceDataType} data for device` , [deviceDataType]);

  const mapApiCallToOperation = React.useCallback(() => {
    if (deviceDataType === DeviceDataType.ACTUAL) {
      return DeviceRegistryClient.getActualDeviceConfigurationBySchema(deviceId, schemaIdentity);
    } else if (deviceDataType === DeviceDataType.DESIRED) {
      if (deviceDataScope === DeviceDataScope.DEVICE) {
        return DeviceRegistryClient.getDesiredDeviceConfigurationBySchema(deviceId, schemaIdentity);
      } else if (deviceDataScope === DeviceDataScope.TYPE) {
        return DeviceRegistryClient.getTypeConfigurationBySchema(typeIdentity, schemaIdentity);
      } else {
        return DeviceRegistryClient.getRegionConfigurationBySchema(schemaIdentity);
      }
    } else {
      return DeviceRegistryClient.getDeviceMetadataBySchema(deviceId, schemaIdentity);
    }
  }, [
    deviceDataType,
    deviceDataScope,
    typeIdentity,
    deviceId,
    schemaIdentity,
  ]);

  const [{ successResponse, ...baseModel }, { refresh: getDeviceData }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => mapApiCallToOperation(),
    });

  const deviceData = React.useMemo(() => {
    const { data = {} } = successResponse || {};
    return data;
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    deviceData,
  }), [
    baseModel,
    deviceData,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    getDeviceData,
  }), [
    getDeviceData,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDeviceDataBySchemaService;
