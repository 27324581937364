import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { listItem as styles } from "./styles";
import { PrimaryIcon } from "@modules/dataApprovalRequests/components/styles";
import { DataSetAccessRequest } from "@data";

export enum DataSetAccessRequestColumn {
  NONE = "",
  NAME = "Principal Identifier",
  ACCOUNT = "Account",
  TYPE = "Type",
  PRINCIPAL_TYPE = "Principal Type",
}
export const DataSetAccessRequestListItem = withStyles(styles)((props: WithStyles<typeof styles> & {
  accessRequest: DataSetAccessRequest,
  column?: DataSetAccessRequestColumn,
}) => {

  const { classes, accessRequest = DataSetAccessRequest.EMPTY, column } = props;

  if (!column || DataSetAccessRequest.EMPTY.equals(accessRequest)) {
    return null;
  }

  switch (column) {
    case DataSetAccessRequestColumn.NAME:
      return (
        <div className={classnames("name", classes.container)}>
          <PrimaryIcon className={classes.icon} />
          <label className={classes.label}>{accessRequest.getPrincipalIdentifier()}</label>
        </div>
      );
    case DataSetAccessRequestColumn.PRINCIPAL_TYPE:
      return (
        <label className={classnames("state", classes.label)}>{accessRequest.getPrincipalType()}</label>
      );
    case DataSetAccessRequestColumn.TYPE:
      return (
        <label className={classnames("type", classes.label)}>{accessRequest.getRequestType()}</label>
      );
    case DataSetAccessRequestColumn.ACCOUNT:
      return (
        <label className={classnames("account", classes.label)}>{accessRequest.getAccountId()}</label>
      );
    default:
      return null;
  }
});

export default DataSetAccessRequestListItem;
