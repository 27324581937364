import React from "react";
import classnames from "classnames";
import ModuleListView, {
  createColumns,
  ModuleListViewActions,
  ModuleListViewModel,
} from "@components/module-list-view";
import QualityReport from "@data/QualityReport";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { getPathToQualityReport } from "@modules/qualityReportDetails/helpers";
import styles, { PrimaryIcon } from "./styles";
import { useHistory } from "react-router-dom";

export enum QualityReportsListColumn {
  PLATFORM_DOMAIN = "Platform Domain",
  GROUP = "Group",
  VERSION = "Version",
  CREATED_AT = "Created At",
}

export const DEFAULT_QUALITY_REPORTS_LIST_COLUMNS: QualityReportsListColumn[] = [
  QualityReportsListColumn.PLATFORM_DOMAIN,
  QualityReportsListColumn.GROUP,
  QualityReportsListColumn.VERSION,
  QualityReportsListColumn.CREATED_AT,
];

type Item = QualityReport;
type Column = QualityReportsListColumn;

export interface QualityReportsModel extends ModuleListViewModel<Item, Column> {
  className?: string;
  title?: string;
  reports?: QualityReport[];
}

export interface QualityReportsActions extends ModuleListViewActions<Item, Column> {
}

type Model = QualityReportsModel;
type Actions = QualityReportsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const QualityReports = withStyles(styles)((props: Props) => {

  const history = useHistory();

  const {
    classes,
    className,
    listClassName,
    showSummary = true,
    showSearch = false,
    tableLayoutFixed,
    summaryViewIcon = PrimaryIcon,
    title = "Quality Reports",
    summaryViewLabel = title,
    columns = DEFAULT_QUALITY_REPORTS_LIST_COLUMNS,
    reports = [],
    items = reports,
    listViewItems = React.useMemo(() =>
      items.map((report: QualityReport) => ({
        item: report,
        pathToDetailsView: getPathToQualityReport(report),
        icon: PrimaryIcon,
        columnAttributes: createColumns([
          {
            className: "platformDomain",
            value: report.getPlatformDomainName(),
            column: QualityReportsListColumn.PLATFORM_DOMAIN,
            firstColumn: true,
          },
          {
            className: "group",
            value: report.getGroupName(),
            column: QualityReportsListColumn.GROUP,
          },
          {
            className: "version",
            value: report.getVersion(),
            column: QualityReportsListColumn.VERSION,
          },
          {
            className: "createdAt",
            value: report.getCreatedAt(),
            column: QualityReportsListColumn.CREATED_AT,
            isDate: true,
            formatDatePattern: "DD MMM YYYY",
          },
        ]),
      })), [items]),
    onClickItem = React.useCallback((report: QualityReport) => {
      history.push(getPathToQualityReport(report));
    }, [history]),
    children,
    ...otherProps
  } = props;

  return (
    <ModuleListView
      {...otherProps}
      className={classnames("qualityReports", className, classes.container)}
      listClassName={classnames("qualityReportsList", listClassName, classes.list)}
      showSummary={showSummary}
      showSearch={showSearch}
      tableLayoutFixed={tableLayoutFixed}
      summaryViewIcon={summaryViewIcon}
      summaryViewLabel={summaryViewLabel}
      columns={columns}
      items={items}
      listViewItems={listViewItems}
      onClickItem={onClickItem}
    >
      {children}
    </ModuleListView>
  );
});

export default QualityReports;
