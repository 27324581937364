import React from "react";
import classnames from "classnames";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { formEventHandler, Identity, isEmptyString, noop } from "@util";
import styles from "./styles";

export interface DropdownMenuModel {
  className?: string;
  emptyValueLabelClassName?: string;
  selectedValue?: string;
  values?: string[];
  disabledValues?: string[];
  errorMessage?: string;
  dropdownMenuLabel?: string;
  dropdownMenuHint?: string;
  dropdownMenuLabelClassName?: string;
  selectClassName?: string;
  hideEmptyValue?: boolean;
  emptyValueLabel?: string;
  fullWidth?: boolean;
  autoWidth?: boolean;
  disabled?: boolean;
  variant?: "standard" | "outlined" | "filled";
  loading?: boolean;
  loadingMessage?: string;
  loadingDropdownMenuHint?: string;
}

export interface DropdownMenuActions {
  setSelectedValue?: (selectedValue: string) => void;
  mapValueToLabel?: (value: string) => React.ReactNode | string | null;
}

type Props = WithStyles<typeof styles> & DropdownMenuModel & DropdownMenuActions;

export const DropdownMenu = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    emptyValueLabelClassName,
    selectedValue = "",
    values = [],
    disabledValues = [],
    errorMessage = "",
    dropdownMenuLabel = "",
    dropdownMenuHint = "",
    dropdownMenuLabelClassName,
    selectClassName,
    hideEmptyValue,
    emptyValueLabel = "",
    fullWidth = false,
    autoWidth = true,
    disabled,
    loading,
    loadingMessage = "Loading...",
    loadingDropdownMenuHint = "",
    variant,
    setSelectedValue = noop,
    mapValueToLabel = Identity,
  } = props;

  // Do not render dropdown menu if there are no options to select; unless it is loading
  if (!Array.isArray(values) || (values.length === 0 && !loading && hideEmptyValue)) {
    return null;
  }

  const showErrorMessage = React.useMemo(() =>
    !loading && !isEmptyString(errorMessage), [loading, errorMessage]);

  const showDropdownMenuLabel = React.useMemo(() =>
    !isEmptyString(dropdownMenuLabel), [dropdownMenuLabel]);

  const showLoadingDropdownMenuHint = React.useMemo(() =>
    loading && !isEmptyString(loadingDropdownMenuHint), [loading, loadingDropdownMenuHint]);

  const showDropdownMenuHint = React.useMemo(() =>
    !showErrorMessage && !showLoadingDropdownMenuHint && !isEmptyString(dropdownMenuHint),
    [showErrorMessage, showLoadingDropdownMenuHint, dropdownMenuHint]);

  return (
    <FormControl
      className={classnames("dropdownMenuContainer", className, classes.container)}
      color="secondary"
      error={showErrorMessage}
      fullWidth={fullWidth}
    >
      {showDropdownMenuLabel && (
        <InputLabel
          className={classnames("dropdownMenuLabel", dropdownMenuLabelClassName, classes.dropdownMenuLabel)}
          error={showErrorMessage}
          shrink={true}
        >
          {dropdownMenuLabel}
        </InputLabel>
      )}
      <Select
        className={classnames("dropdownMenu", selectClassName, classes.dropdownMenu)}
        disabled={disabled || loading}
        variant={variant}
        value={selectedValue}
        error={showErrorMessage}
        autoWidth={autoWidth}
        displayEmpty={!hideEmptyValue}
        onChange={formEventHandler(setSelectedValue)}
      >
        {!hideEmptyValue && (
          <MenuItem
            value=""
            selected={selectedValue === ""}
          >
            <em className={classnames("emptyValueLabel", emptyValueLabelClassName, classes.emptyValueLabel)}>
              {loading ? loadingMessage : emptyValueLabel}
            </em>
          </MenuItem>
        )}
        {values.map((value: string, index: number) => isEmptyString(value) ? null : (
          <MenuItem
            key={`dropdown-menu-item-${index}`}
            selected={selectedValue === value}
            disabled={disabledValues.indexOf(value) >= 0}
            value={value}
          >
            {mapValueToLabel(value)}
          </MenuItem>
        ))}
      </Select>
      {showLoadingDropdownMenuHint && (
        <FormHelperText
          className={classnames("dropdownMenuLoadingMessage", classes.dropdownMenuLoadingMessage)}
        >
          {loadingDropdownMenuHint}
        </FormHelperText>
      )}
      {showErrorMessage && (
        <FormHelperText
          className={classnames("dropdownMenuErrorMessage", classes.dropdownMenuErrorMessage)}
        >
          {errorMessage}
        </FormHelperText>
      )}
      {showDropdownMenuHint && (
        <FormHelperText
          className={classnames("dropdownMenuHelperText", classes.dropdownMenuHelperText)}
        >
          {dropdownMenuHint}
        </FormHelperText>
      )}
    </FormControl>
  );
});

export default DropdownMenu;
