import { Module } from "@data";
import { equalsIgnoreCase } from "@util";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./EditProfileAttributeDefinitionView";

export const editProfileAttributeDefinitionModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Update Profile Attribute Definitions",
  title: "Update Profile Attribute Definitions",
  className: "editProfileAttributeDefinitionModule",
  primaryColor,
  icon,
  view,

  // TODO: Edit profile attribute definitions is not currently safe to expose in the UI
  disabled: equalsIgnoreCase("true", process.env.REACT_APP_EDIT_PROFILE_ATTRIBUTE_DEFINITIONS_DISABLED),
});

export default editProfileAttributeDefinitionModule;
