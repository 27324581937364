import { DataSetType } from "@data";

export const mapDataSetTypeToOptionName = (value: DataSetType = DataSetType.NONE) => {
  switch (value) {
    case DataSetType.DATA_LAKE_ACQUIRED:
      return "Data Lake Acquired";
    case DataSetType.DATA_LAKE_BLOBS:
      return "Data Lake Blobs";
    case DataSetType.DATA_WAREHOUSE_SYSTEM:
      return "Data Warehouse System";
    case DataSetType.DATA_WAREHOUSE_SHARED:
      return "Data Warehouse Shared";
    case DataSetType.NONE:
      return "";
    default:
      // If a new type was introduced, we do not want to block it from being used,
      // so just render the value as a string until a proper label is introduced.
      return value + "";
  }
};

export default mapDataSetTypeToOptionName;
