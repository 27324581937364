import { PolicyAttributes } from "@data";
import { isEmptyString, isValidInteger } from "@util";
import {
  createQueryParams,
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments,
} from "./helpers";
import { GetAttachedPoliciesResponse } from "@network";

const AUTHORIZATION_SERVICE_URL = process.env.REACT_APP_AUTHORIZATION_SERVICE_API_LEGACY || "";

if (isEmptyString(AUTHORIZATION_SERVICE_URL)) {
  throw new Error("Missing Environment Variable: REACT_APP_AUTHORIZATION_SERVICE_API_LEGACY");
}

const DEFAULT_LIMIT = 50;

export interface GetPoliciesResponse {
  policies: PolicyAttributes[];
  paging?: {
    next?: string;
  };
}

export interface CreatePolicyResponse {
  policyRef: string;
}

export const getPolicies = (authToken: string,
                            next: string = "",
                            policyName: string = "",
                            limit: number = DEFAULT_LIMIT): Promise<GetPoliciesResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams({
      ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, Math.min(DEFAULT_LIMIT, limit)) }),
      ...(!isEmptyString(policyName) && policyName.length >= 3 ? ({ policyName }) : ({})),
      next,
    });

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/policies${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch policies failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getPolicy = (authToken: string,
                          policyName: string): Promise<PolicyAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Policy Name", policyName],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/policies/${policyName}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to get policy [${policyName}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const attachPolicyToGroup = (authToken: string,
                                    groupName: string,
                                    policyName: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Policy Name", policyName],
      ["Group Name", groupName],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/policies/${policyName}/group/${groupName}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to attach policy [${policyName}] to group [${groupName}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const detachPolicyFromGroup = (authToken: string,
                                      groupName: string,
                                      policyName: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Policy Name", policyName],
      ["Group Name", groupName],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/policies/${policyName}/group/${groupName}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to detach policy [${policyName}] from group [${groupName}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const createPolicy = (authToken: string,
                             json: string): Promise<CreatePolicyResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Policy", json],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/policies`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to create policy";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deletePolicy = (authToken: string,
                             policyName: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Policy Name", policyName],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/policies/${policyName}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to delete policy [${policyName}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getUserPolicies = (authToken: string,
                                userId: string = "",
                                next: string = ""): Promise<GetAttachedPoliciesResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User Id", userId],
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({ next });

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/policies/user/${userId}${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to get policies attached to user [${userId}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage)
      .then((response: { policies: PolicyAttributes[] | null }) => {

        const { policies = [] } = response;

        return {
          policies: policies || [],
        };
      });
  };

  return validate().then(makeRequest);
};

export const attachPolicyToUser = (authToken: string,
                                   userId: string,
                                   policyName: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["Policy Name", policyName],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/policies/${policyName}/user/${userId}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to attach policy [${policyName}] to user [${userId}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const detachPolicyFromUser = (authToken: string,
                                     userId: string,
                                     policyName: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["Policy Name", policyName],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/policies/${policyName}/user/${userId}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to detach policy [${policyName}] from user [${userId}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const attachPolicyToService = (authToken: string,
                                      serviceId: string,
                                      policyName: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Service ID", serviceId],
      ["Policy Name", policyName],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/policies/${policyName}/service/${serviceId}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to attach policy [${policyName}] to service [${serviceId}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const detachPolicyFromService = (authToken: string,
                                        serviceId: string,
                                        policyName: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Service ID", serviceId],
      ["Policy Name", policyName],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/policies/${policyName}/service/${serviceId}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to detach policy [${policyName}] from service [${serviceId}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const editPolicy = (authToken: string,
                           policyName: string,
                           json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Policy Name", policyName],
      ["Policy", json],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/policies/${policyName}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to update policy";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
