import React from "react";
import { analyticAction, isEmptyString } from "@util";
import { useDispatch } from "react-redux";
import { AuthTokenContext } from "@components";
import { RestClientError } from "@network";

const withApiRequestAnalyticsSuccessHandler = (trackEvent: () => void) => (response: any) => {
  trackEvent();
  return Promise.resolve(response);
};

const withApiRequestAnalyticsErrorHandler = (trackEvent: (analytic: string) => void) => (response: RestClientError) => {
  const { analytic } = response;
  trackEvent(analytic);
  return Promise.reject(response);
};

export const useApiRequestAnalytics = (apiRequest: (...args: any[]) => Promise<any>, options: {
  REQUEST_EVENT?: string;
  SUCCESS_EVENT?: string;
  ERROR_EVENT?: string;
}) => {

  const {
    REQUEST_EVENT,
    SUCCESS_EVENT,
    ERROR_EVENT,
  } = options;

  const dispatch = useDispatch();

  const authToken = React.useContext(AuthTokenContext);

  const trackRequestEvent = React.useCallback(analyticAction(REQUEST_EVENT), [REQUEST_EVENT]);

  const trackSuccessEvent = React.useCallback(analyticAction(SUCCESS_EVENT), [SUCCESS_EVENT]);

  const trackErrorEvent = React.useCallback(analyticAction(ERROR_EVENT), [ERROR_EVENT]);

  return React.useCallback((...args: any[]) => {
    dispatch(trackRequestEvent());
    const allArgs = isEmptyString(authToken) ? args : [authToken].concat(args);
    return apiRequest.apply(null, allArgs)
      .then(
        withApiRequestAnalyticsSuccessHandler(() => dispatch(trackSuccessEvent())),
        withApiRequestAnalyticsErrorHandler(analytic => dispatch(trackErrorEvent(analytic))));
  }, [authToken, dispatch, apiRequest, trackRequestEvent, trackSuccessEvent, trackErrorEvent]);
};

export default useApiRequestAnalytics;
