import { Module } from "@data";
import { initialize, onMount } from "./actions";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { isCommonServicesModulesDisabled } from "@util";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { isAccessDeniedVisible, isEmptyViewVisible } from "./selectors";
import view from "./SchemasView";

export const schemasModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Schemas",
  title: "Schema Management",
  className: "schemasModule",
  primaryColor,
  icon,
  view,
  backButton: null,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  initialize,
  onMount,
  disabled: isCommonServicesModulesDisabled(),
});

export default schemasModule;
