import * as DeviceTypeDetailsActions from "./deviceTypeDetails";
import * as ManageSoftwareVersionsActions from "./manageSoftwareVersions";
import * as SoftwareVersionDetailsActions from "./softwareVersionDetails";

export {
  DeviceTypeDetailsActions,
  ManageSoftwareVersionsActions,
  SoftwareVersionDetailsActions,
};

export * from "./deviceTypeDetails";

export default DeviceTypeDetailsActions;
