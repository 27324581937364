import React from "react";
import classnames from "classnames";
import camelCase from "lodash/camelCase";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import { SummaryViewActionItems } from "./SummaryViewActionItems";
import SummaryViewLabel , { SummaryViewDateLabel } from "@components/summary-view-label";
import { isEmptyString } from "@util";

export interface SummaryViewWithActionsModel {
  items?: SummaryViewActionItems[];
  className?: string;
  contentClassName?: string;
  children?: React.ReactNode;
}

type Props = WithStyles<typeof styles> & SummaryViewWithActionsModel;

export const SummaryViewWithActions = withStyles(styles)((props: Props) => {

  const {
    classes,
    className = "",
    contentClassName,
    items = [],
    children,
  } = props;

  return (
    <div className={classnames("summaryViewWithActions", className, classes.container)}>
      {items.map((item: SummaryViewActionItems) => isEmptyString(item.summaryViewItem.getValue()) ? null
        : !item.summaryViewItem.isDate() ? (
        <SummaryViewLabel
          key={`summary-view-label--${camelCase(item.summaryViewItem.getName())}-${camelCase(item.summaryViewItem.getValue())}`}
          className={item.summaryViewItem.getClassName()}
          name={item.summaryViewItem.getName()}
          value={item.summaryViewItem.getValue()}
          showActionsButton={item.showActions}
          showEditIcon={item.showEditIcon}
          editIconClassName={`${camelCase(item.summaryViewItem.getName())}EditButton`}
          editTooltipText={item.editTooltipText}
          actionsClassName={`${camelCase(item.summaryViewItem.getName())}ActionsButton`}
          tooltipIconText={item.summaryViewItem.getTooltipIconText()}
          tooltipIconClassName={`${camelCase(item.summaryViewItem.getName())}TooltipIcon`}
          showTooltipIcon={item.showTooltipIcon}
          actions={item.actions}
          onClickAction={item.onClickAction}
          onClickEditIcon={item.onClickEditIcon}
        />
      ) : (
        <SummaryViewDateLabel
          key={`summary-view-date-label-${camelCase(item.summaryViewItem.getName())}`}
          className={item.summaryViewItem.getClassName()}
          name={item.summaryViewItem.getName()}
          value={item.summaryViewItem.getValue()}
          formatDatePattern={item.summaryViewItem.getFormatDatePattern()}
        />
      ))}
      <div className={classnames("content", contentClassName, classes.content)}>
        {children}
      </div>
    </div>
  );
});

export default SummaryViewWithActions;
