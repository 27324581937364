import { createStyles, StyleRules } from "@material-ui/core/styles";

export const textField: StyleRules<string> = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& input": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      padding: "8px 10px",
      margin: 0,
    },
  },
};

export const styles = () =>
  createStyles({
    inputField: {
      flex: "1 0 auto",
      width: 100,
    },
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 20,
    },
    note: {
      flex: "1 0 auto",
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    formHelperTextError: {
      fontSize: 12,
    },
  });

export default styles;
