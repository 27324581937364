import React from "react";
import SortedSearchResultsList, {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import { PolicyBulkRequest } from "@data/PolicyBulkRequest";
import { ManagedPolicyBulkRequest } from "@data/ManagedPolicyBulkRequest";
import ReviewViewListItem, { PolicyReviewViewColumns } from "@modules/policyManagement/components/ReviewViewListItem";

const DEFAULT_REVIEW_LIST_COLUMNS: PolicyReviewViewColumns[] = [
  PolicyReviewViewColumns.NAME,
  PolicyReviewViewColumns.STATUS,
  PolicyReviewViewColumns.ERRORS,
];

type requestType = PolicyBulkRequest | ManagedPolicyBulkRequest;
export type PolicyReviewActions = SortedSearchResultsListActions<requestType, PolicyReviewViewColumns>;

interface ContainerModel extends SortedSearchResultsListModel<requestType, PolicyReviewViewColumns> {
  isManagedPolicy?: boolean;
}

type Props = ContainerModel & PolicyReviewActions;

export const ReviewViewList = (props: Props) => {

  const {
    className = "reviewViewList",
    columns = DEFAULT_REVIEW_LIST_COLUMNS,
    noResultsLabel = "No policies selected",
    summaryViewQuantities = {
      other: "policies",
      one: "policy",
    },
    items = [],
    isManagedPolicy = false,
    ...otherProps
  } = props;

  return (
    <SortedSearchResultsList
      {...otherProps}
      className={className}
      items={items}
      columns={columns}
      sortingDisabled={true}
      noResultsLabel={noResultsLabel}
      summaryViewQuantities={summaryViewQuantities}
      renderItem={(policy: PolicyBulkRequest | ManagedPolicyBulkRequest, column: PolicyReviewViewColumns) => (
        <ReviewViewListItem
          isManagedPolicy={isManagedPolicy}
          policyBulkRequest={policy}
          column={column}
        />
      )}
    />
  );
};

export default ReviewViewList;
