import AppSchema from "@AppSchema";
import { createActions } from "@base/createActions";
import { ACTION_TYPES, DEFAULT_STATE, ReportSteps } from "./reducers";
import { getAccessToken, getFormat, getReportId, getType, getUserReport, getUserReportLink } from "./selectors";
import { CreateUserReportResponse, ReportingClient, RestClientError } from "@network";
import { ReportStatus, UserReportAttributes } from "@data";
import { isEmptyString } from "@util";
import { setUserReportMessage } from "@main/actions";

export const {
  userReport: setUserReport,
  reportId: setReportId,
  step: setStep,
  generateDialog: setGenerateDialog,
  type: setType,
  format: setFormat,
  setErrorMessage,
  setSuccessMessage,
  showLoadingIndicator,
  hideLoadingIndicator,
  showAccessDenied,
  hideAccessDenied,
  GENERATE_USER_REPORT_REQUEST: generateUserReportRequest,
  GENERATE_USER_REPORT_SUCCESS: generateUserReportSuccess,
  GENERATE_USER_REPORT_FAILED: generateUserReportFailed,
  FETCH_USER_REPORT_REQUEST: fetchUserReportRequest,
  FETCH_USER_REPORT_SUCCESS: fetchUserReportSuccess,
  FETCH_USER_REPORT_FAILED: fetchUserReportFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setUserReport());
  dispatch(setReportId());
  dispatch(setStep());
  dispatch(setGenerateDialog());
  dispatch(setType());
  dispatch(setFormat());
  dispatch(setUserReportMessage(""));
  return dispatch(baseReset());
};

export const setGenerateStep = () => setStep(ReportSteps.GENERATE);
export const setLoadingStep = () => setStep(ReportSteps.LOADING);
export const setCompleteStep = () => setStep(ReportSteps.COMPLETE);

export const close = reset;

export const generateUserReport = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accessToken = getAccessToken(state);
  const type = getType(state);
  const format = getFormat(state);

  const json = JSON.stringify({
    type,
    format
  });

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(generateUserReportRequest());

  return ReportingClient.createUserReport(accessToken, json)
    .then((response: CreateUserReportResponse) => {

    const { id } = response;

    dispatch(setReportId(id));
    dispatch(setGenerateDialog(false));
    dispatch(hideLoadingIndicator());
    dispatch(setLoadingStep());
    dispatch(getUserReportById());
    return dispatch(generateUserReportSuccess());

  }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to request user report" } = response;

      dispatch(hideLoadingIndicator());
      dispatch(generateUserReportFailed(analytic));

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(setErrorMessage(error));
    });
};

// TODO: This needs to be reworked because it never clears the timeout and could be run multiple times
export const getUserReportById = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accessToken = getAccessToken(state);
  const reportId = getReportId(state);

  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(fetchUserReportRequest());

  let stopped = false;

  const refresh = () => {
    if (!stopped) {
      ReportingClient.getUserReport(accessToken, reportId)
        .then((attrs: UserReportAttributes) => {

          dispatch(setUserReport(attrs));

          const userReport = getUserReport(getState());

          if (userReport.getStatus() === ReportStatus.FINISHED) {
            stopped = true;
            dispatch(setUserReportMessage("User Report is available for download"));
            dispatch(setCompleteStep());
            return dispatch(fetchUserReportSuccess());
          } else {
            setTimeout(() => refresh(), 10000);
          }

        }, (response: RestClientError) => {

          const { analytic, status, error = "Failed to fetch user report" } = response;

          dispatch(fetchUserReportFailed(analytic));

          if (status === 403) {
            dispatch(showAccessDenied());
          }
          stopped = true;
          return dispatch(setErrorMessage(error));
        });
    }
  };
  setTimeout(() => refresh(), 10000);

  return () => {
    stopped = true;
  };
};

export const open = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const reportId = getReportId(state);

  if ( isEmptyString(reportId)) {
    dispatch(reset());
    dispatch(setGenerateStep());
    return dispatch(setGenerateDialog(true));
  } else {
    // TODO: Redux actions should never interact with the dom like this
    window.open(getUserReportLink(state));
    return dispatch(reset());
  }
};

export const initialize = () => (dispatch: any) => {
  dispatch(setType());
  dispatch(setFormat());
  return dispatch(baseReset());
};
