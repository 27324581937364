import * as ManageSoftwareVersionsSelectors from "./manageSoftwareVersions";
import * as DeviceTypeDetailsSelectors from "./deviceTypeDetails";
import * as SoftwareVersionDetailsSelectors from "./softwareVersionDetails";

export {
  DeviceTypeDetailsSelectors,
  ManageSoftwareVersionsSelectors,
  SoftwareVersionDetailsSelectors,
};

export * from "./deviceTypeDetails";

export default DeviceTypeDetailsSelectors;
