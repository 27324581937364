import React from "react";
import { AuditEvent } from "@data";
import classnames from "classnames";
import Link from "@components/link";
import { ErrorView } from "@components";
import { getPathToAuditEvent } from "@modules/auditEventDetails/helpers";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import SummaryViewLabel from "./SummaryViewLabel";
import styles from "./styles";

export interface AuditEventListItemExpandedModel {
  className?: string;
  auditEvent?: AuditEvent;
}

export interface AuditEventListItemExpandedActions {
}

type Model = AuditEventListItemExpandedModel;
type Actions = AuditEventListItemExpandedActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const AuditEventListItemExpanded = React.memo(withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    auditEvent = AuditEvent.EMPTY,
  } = props;

  if (!auditEvent.hasId()) {
    return (
      <div className={classnames("auditEventListItemExpanded", className, classes.container, classes.emptyView)}>
        <ErrorView
          className="notFoundError"
          title="Not Found"
          message={"Audit Event Not Found"}
        />
      </div>
    );
  }

  return (
    <div className={classnames("auditEventListItemExpanded", className, classes.container)}>
      <div className={classnames(classes.summaryView)}>
        <SummaryViewLabel
          className={classnames("id")}
          value={auditEvent.getId()}
          name="ID"
        />
        <SummaryViewLabel
          className={classnames("timestamp")}
          value={auditEvent.getTimestamp()}
          name="Timestamp"
        />
        <SummaryViewLabel
          className={classnames("originator")}
          value={auditEvent.getOriginator()}
          name="Originator"
        />
        <SummaryViewLabel
          className={classnames("type")}
          value={auditEvent.getType()}
          name="Type"
        />
        <SummaryViewLabel
          className={classnames("severity")}
          value={auditEvent.getSeverity()}
          name="Severity"
        />
        <SummaryViewLabel
          className={classnames("accountId")}
          value={auditEvent.getAccountId()}
          name="Account ID"
        />
        <SummaryViewLabel
          className={classnames("principal")}
          value={auditEvent.getPrincipal()}
          name="Principal"
        />
        <SummaryViewLabel
          className={classnames("requestId")}
          value={auditEvent.getRequestId()}
          name="Request ID"
        />
        <SummaryViewLabel
          className={classnames("ip")}
          value={auditEvent.getIP()}
          name="IP Address"
        />
        <SummaryViewLabel
          className={classnames("userAgent")}
          value={auditEvent.getUserAgent()}
          name="User Agent"
        />
        <SummaryViewLabel
          className={classnames("clientId")}
          value={auditEvent.getClientId()}
          name="Client ID"
        />
        <SummaryViewLabel
          className={classnames("location")}
          value={auditEvent.getLocation()}
          name="Location"
        />
        {auditEvent.hasHttpStatus() && (
          <SummaryViewLabel
            className={classnames("httpStatus")}
            value={`${auditEvent.getHttpStatus()}`}
            name="HTTP Status"
          />
        )}
      </div>
      <div className={classnames(classes.summaryView, classes.jsonView)}>
        <SummaryViewLabel
          className={classnames("metadata", classes.metadata)}
          nameClassName={classnames("summaryViewLabelName", classes.jsonLabel)}
          valueClassName={classnames("summaryViewLabelValue", classes.jsonValue)}
          value={JSON.stringify(auditEvent.getMetadata(), null, "  ")}
          name="Metadata"
        />
      </div>
      <Link
        className={classnames("detailsLink", classes.link, classes.detailsLink)}
        href={getPathToAuditEvent(auditEvent.getId())}
      >
        View details page
      </Link>
    </div>
  );
}));

export default AuditEventListItemExpanded;
