import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import NameFilter from "@components/name-filter";
import { withQuantity } from "@components/summary";
import PaginatedList from "@components/paginated-list";
import RefreshButton from "@components/refresh-button";
import { DeviceTypeListItem as DeviceTypeData } from "@data";
import isDeviceTypeV2APIEnabled from "@util/isDeviceTypeV2APIEnabled";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DeviceTypeListColumn from "./DeviceTypeListColumn";
import DeviceTypeListItem from "./DeviceTypeListItem";
import createComparator from "./createComparator";
import styles, { DeviceTypeIcon } from "./styles";

export const DEVICE_TYPES_DEFAULT_COLUMNS: DeviceTypeListColumn[] = [
  DeviceTypeListColumn.NAMESPACE,
  DeviceTypeListColumn.NAME,
  DeviceTypeListColumn.VERSION,
  DeviceTypeListColumn.STATE,
];

export const DeviceTypesSummary = withQuantity({
  other: "device types",
  one: "device type",
});

export interface DeviceTypesListModel {
  className?: string;
  deviceTypes?: DeviceTypeData[];
  searchQuery?: string;
  numResults?: number;
  errorMessage?: string;
  showErrorView?: boolean;
  showProgressIndicator?: boolean;
  showLoadMoreButton?: boolean;
  showNoResultsView?: boolean;
  columns?: DeviceTypeListColumn[];
  selectable?: boolean;
  selectAllDisabled?: boolean;
  selectedItems?: DeviceTypeData[];
  noResultsLabel?: string;
  loadMoreLabel?: string;
  tableLayoutFixed?: boolean;
  hideSummary?: boolean;
  hideSearch?: boolean;
  showMoreInfoLabel?: string;
  clickable?: boolean;
  children?: React.ReactNode;
}

export interface DeviceTypesListActions {
  setSearchQuery?: (searchQuery: string) => void;
  loadMore?: () => void;
  refresh?: () => void;
  clearList?: () => void;
  onClickItem?: (item: DeviceTypeData) => void;
  onClickShowMoreInfo?: (item: DeviceTypeData) => void;
  setSelectedItems?: (items: DeviceTypeData[]) => void;
}

type Props = WithStyles<typeof styles> & DeviceTypesListModel & DeviceTypesListActions;

export const DeviceTypesList = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    deviceTypes = [],
    searchQuery = "",
    errorMessage,
    showErrorView,
    showProgressIndicator,
    showLoadMoreButton,
    showNoResultsView,
    columns = DEVICE_TYPES_DEFAULT_COLUMNS,
    selectable,
    selectAllDisabled,
    selectedItems = [],
    noResultsLabel = "No device types found",
    loadMoreLabel,
    showMoreInfoLabel,
    children,
    hideSummary,
    hideSearch,
    tableLayoutFixed = true,
    clickable = true,
    setSearchQuery = noop,
    loadMore = noop,
    refresh = noop,
    onClickItem = noop,
    onClickShowMoreInfo = noop,
    setSelectedItems = noop,
  } = props;

  const onItemClicked = React.useCallback((deviceType: DeviceTypeData) => {

    if (!selectable) {
      return onClickItem(deviceType);
    }

    const checked = selectedItems.indexOf(deviceType) >= 0;

    if (checked) {
      return setSelectedItems(selectedItems.filter(item => item !== deviceType));
    } else {
      return setSelectedItems(selectedItems.concat(deviceType));
    }

  }, [selectable, selectedItems, setSelectedItems, onClickItem]);

  const [sortByColumn, setSortByColumn] = React.useState(DeviceTypeListColumn.NONE);

  const [ascending, setAscending] = React.useState(true);

  const onClickToggleSortOrder = React.useCallback(() => setAscending(!ascending), [ascending]);

  const comparator = React.useMemo(() =>
      createComparator(sortByColumn, ascending),
    [sortByColumn, ascending]);

  const validatedDeviceTypes = React.useMemo(() => {
    const v2DeviceTypesSupported = isDeviceTypeV2APIEnabled();
    if (v2DeviceTypesSupported) {
      return deviceTypes;
    } else {
      return deviceTypes.filter(deviceType => deviceType.isRegionalModelVersion());
    }
  }, [deviceTypes]);

  const sortedDeviceTypes = React.useMemo(() =>
      validatedDeviceTypes.slice(0).sort(comparator),
    [validatedDeviceTypes, comparator]);

  const numResults = React.useMemo(() => sortedDeviceTypes.length, [sortedDeviceTypes]);

  const sortButtonDisabled = React.useMemo(() => numResults < 1, [numResults]);

  const onClickRefresh = React.useCallback(() => {
    setAscending(true);
    setSortByColumn(DeviceTypeListColumn.NONE);
    refresh();
  }, [setSortByColumn, setAscending, refresh]);

  return (
    <div className={classnames("deviceTypes", className, classes.container)}>
      {!hideSummary && (
        <DeviceTypesSummary quantity={numResults} icon={DeviceTypeIcon}>
          <RefreshButton
            className={classnames("refresh", classes.refreshButton)}
            loading={showProgressIndicator}
            refresh={onClickRefresh}
          />
          <div className={classnames("controls", classes.controls)}>
            {children}
          </div>
        </DeviceTypesSummary>
      )}
      {!hideSearch && (
        <NameFilter
          className={classnames("search", classes.search)}
          hint="Filter device types by name"
          nameFilter={searchQuery}
          setNameFilter={(updatedNameFilter: string) => {
            setSearchQuery(updatedNameFilter);
            onClickRefresh();
          }}
        />
      )}
      <PaginatedList
        className={classnames("deviceTypesList", classes.deviceTypesList)}
        columns={columns}
        sortedColumn={sortByColumn}
        items={sortedDeviceTypes}
        error={errorMessage}
        sortButtonDisabled={sortButtonDisabled}
        sortOrderAscending={ascending}
        selectable={selectable}
        selectAllDisabled={selectAllDisabled}
        selectedItems={selectedItems}
        noResultsLabel={noResultsLabel}
        loadMoreLabel={loadMoreLabel}
        showMoreInfoLabel={showMoreInfoLabel}
        tableLayoutFixed={tableLayoutFixed}
        showErrorView={showErrorView}
        showLoadingView={showProgressIndicator}
        showNoResultsView={showNoResultsView}
        showLoadMoreButton={showLoadMoreButton}
        onClickLoadMore={loadMore}
        onClickItem={!clickable ? noop : onItemClicked}
        onClickShowMoreInfo={onClickShowMoreInfo}
        onClickColumn={(column: DeviceTypeListColumn) => setSortByColumn(column)}
        onClickToggleSortOrder={onClickToggleSortOrder}
        setSelectedItems={setSelectedItems}
        renderItem={(deviceType: DeviceTypeData, column: string) => (
          <DeviceTypeListItem
            deviceType={deviceType}
            column={column}
          />
        )}
      />
    </div>
  );
});

export default DeviceTypesList;
