import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { User } from "@data";
import { DataSetRequestWizardActions } from "../actions";
import { DataSetRequestWizardSelectors } from "../selectors";
import { equalsIgnoreCase, noop } from "@util";
import { useAccountUsers } from "@hooks";
import UsersView, { Actions, Model } from "../components/UsersView";

interface ContainerModel extends Model {
  hidden?: boolean;
  selectedBusinessOwners?: string[];
}

interface ContainerActions extends Actions {
  setSelectedItems?: (users: User[]) => void;
}

type Props = ContainerModel & ContainerActions;

const BusinessOwnersViewContainer = (props: Props) => {

  const {
    hidden,
    selectedBusinessOwners = [],
    setSelectedItems = noop,
  } = props;

  const [ model, actions ] = useAccountUsers();

  const { users, ...remainingModel } = model;

  const missingItems = React.useMemo(() =>
      selectedBusinessOwners
        .filter(userId => !users.some(businessOwner => equalsIgnoreCase(userId, businessOwner.getUserId())))
        .map(userId => new User({ userId })),
    [selectedBusinessOwners, users]);

  const items = React.useMemo(() =>
      missingItems
        .concat(users.slice()),
    [missingItems, users]);

  const selectedItems = React.useMemo(() =>
      items.filter(user => selectedBusinessOwners.indexOf(user.getUserId()) >= 0),
    [items, selectedBusinessOwners]);

  if (hidden) {
    return null;
  }

  return (
    <UsersView
      {...remainingModel}
      {...actions}
      maxNumSelectedUsers={1}
      className="BusinessOwnersView"
      title="Select Business Owner (Min 1; Max 1)"
      items={items}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  hidden: !DataSetRequestWizardSelectors.isBusinessOwnersViewSelected(state),
  selectedBusinessOwners: DataSetRequestWizardSelectors.getBusinessOwners(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setSelectedItems: (users: User[]) => dispatch(DataSetRequestWizardActions.updateBusinessOwners(
    users.map((user) => user.getUserId()))),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(BusinessOwnersViewContainer);
