import createStyles from "@material-ui/core/styles/createStyles";
import { Color } from "@components";

export const styles = () =>
  createStyles({
    sessionMismatchDialog: {
      "&.md div[role='dialog']": {
        maxWidth: 768,
      },
    },
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      textAlign: "center",
    },
    title: {
      color: Color.TEXT,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    subtitle: {
      color: Color.GREY3,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "18px",
      marginTop: 15,
    },
    sessionMismatchInfo: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    sessionInfoContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      textAlign: "left",
      marginLeft: 25,
      "&:first-child": {
        marginLeft: 0,
      },
    },
    sessionInfo: {
      marginTop: 25,
      display: "flex",
      flexFlow: "row nowrap",
    },
    sessionInfoLabel: {
      color: Color.TEXT,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "18px",
    },
    sessionLabel: {
      marginLeft: 10,
      color: Color.TEXT,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "18px",
    },
  });

export default styles;
