import { createSelector } from "reselect";
import { createSelectors, Selector } from "@base";
import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { getPluralString, getStringValue } from "@util";
import {
  ApprovalProcessingDetail,
  DataAccessRequest,
  DataAccessRequestAttributes,
  DataApprovalRequest,
  DataApprovalRequestAttributes,
  DataSetRequest,
  DataSetRequestAttributes,
  RequestComment,
  RequestCommentAttributes,
  RequestState,
} from "@data";
import { ActionMenuItem } from "@components/actions-menu";
import {
  APPROVE_DATA_APPROVAL_REQUEST_ACTION,
  REJECT_DATA_APPROVAL_REQUEST_ACTION,
} from "@components/data-approval-request-actions-menu";
import { DataApprovalRequestDetailsSchema, DEFAULT_STATE } from "./reducers";

export const {
  dataApprovalRequest: getDataApprovalRequestAttributes,
  dataSetRequest: getDataSetRequestAttributes,
  dataAccessRequest: getDataAccessRequestAttributes,
  comments: getCommentAttributesArray,
  comment: getComment,
  createCommentBox: isCreateCommentBox,
  errorMessage: getErrorMessage,
  successMessage: getSuccessMessage,
  showEmptyView: isEmptyViewVisible,
  showAccessDenied: isAccessDeniedVisible,
  showLoadingIndicator: isLoadingIndicatorVisible,
  isNotFoundVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  commentLoadingIndicator: isCommentLoadingIndicatorVisible,
  commentErrorMessage: getCommentErrorMessage,
} = createSelectors<DataApprovalRequestDetailsSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getDataApprovalRequest: Selector<DataApprovalRequest> = createSelector(
  getDataApprovalRequestAttributes, (attrs: DataApprovalRequestAttributes) =>
    new DataApprovalRequest(attrs));

export const getDataSetRequest: Selector<DataSetRequest> = createSelector(
  getDataSetRequestAttributes, (attrs: DataSetRequestAttributes) =>
    new DataSetRequest(attrs));

export const getDataAccessRequest: Selector<DataAccessRequest> = createSelector(
  getDataAccessRequestAttributes, (attrs: DataAccessRequestAttributes) =>
    new DataAccessRequest(attrs));

export const getPrivacyTermsConditionsId: Selector<string> = createSelector(
  getDataSetRequest, (dataSetRequest: DataSetRequest) =>
    dataSetRequest.getTermsConditionsId());

export const getDataSetAlias: Selector<string> = createSelector(
  getDataApprovalRequest, (dataApprovalRequest: DataApprovalRequest) =>
    dataApprovalRequest.dataSetAlias);

export const getApprovers: Selector<string> = createSelector(
  getDataApprovalRequest, (dataApprovalRequest: DataApprovalRequest) => {
    return dataApprovalRequest.approvers.map(user => user.split(":").pop()).join(", ");
  });

export const getRequestedBy: Selector<string> = createSelector(
  getDataApprovalRequest, (dataApprovalRequest: DataApprovalRequest) => {
    return getStringValue(dataApprovalRequest.requestedBy.split(":").pop());
  });

export const getSourceSystemRequestId: Selector<string> = createSelector(
  getDataApprovalRequest, (dataApprovalRequest: DataApprovalRequest) =>
    dataApprovalRequest.sourceSystemRequestId);

export const getProcessingDetails: Selector<ApprovalProcessingDetail[]> = createSelector(
  getDataApprovalRequest, (dataApprovalRequest: DataApprovalRequest) =>
    dataApprovalRequest.processingDetails.map(detail => new ApprovalProcessingDetail(detail)));

export const getNumOfProcessingDetails: Selector<number> = createSelector(
  getProcessingDetails, (items: ApprovalProcessingDetail[]) => items.length);

export const getProcessingDetailsTitle: Selector<string> = createSelector(
  getNumOfProcessingDetails, (numItems: number) => getPluralString(numItems, {
    other: "Processing Details",
    one: "Processing Detail",
  }));

export const getDataApprovalRequestAsJson: Selector<string> = createSelector(
  [isLoadingIndicatorVisible, getDataApprovalRequest],
  (isLoading: boolean, dataApprovalRequest: DataApprovalRequest) => {

    // If the page is loading, only show the bare minimum to avoid showing potentially stale data
    const data = isLoading ? DEFAULT_STATE.dataApprovalRequest : dataApprovalRequest.toJS();

    return JSON.stringify(data, null, "  ");
  });

export const isRequestPending: Selector<boolean> = createSelector(
  getDataApprovalRequest, dataApprovalRequest =>
    dataApprovalRequest.requestState === RequestState.PENDING ||
    dataApprovalRequest.requestState === RequestState.PENDING_DO_APPROVAL);

export const getActionMenuItems: Selector<ActionMenuItem[]> = createSelector(
  isRequestPending, pending => ([
    {
      ...APPROVE_DATA_APPROVAL_REQUEST_ACTION,
      hidden: !pending,
    },
    {
      ...REJECT_DATA_APPROVAL_REQUEST_ACTION,
      hidden: !pending,
    },
  ]));

export const getComments: Selector<RequestComment[]> = createSelector(
  getCommentAttributesArray, (attrsArray: RequestCommentAttributes[]) =>
    attrsArray.map((attrs: RequestCommentAttributes) => new RequestComment({
      ...attrs,
      author: attrs.author.split(":").pop(),
    })));

export const getNumOfComments: Selector<number> = createSelector(
  getComments, (items: RequestComment[]) => items.length);

export const getCommentsViewTitle: Selector<string> = createSelector(
  getNumOfComments, (numItems: number) => getPluralString(numItems, {
    other: "Comments",
    one: "Comment",
  }));

export const isAddCommentButtonActive: Selector<boolean> = createSelector(
  getComment, (comment: string) => {
    return comment.trim().length > 0;
  });

export const getTrainingCertificateId: Selector<string> = createSelector(
  getDataApprovalRequest, (dataApprovalRequest: DataApprovalRequest) => {
    const id = dataApprovalRequest.additionalInformation.trainingCompletionCertificateId;
    return id === undefined ? "" : id;
});

export const getDeviceTypeIdentifier: Selector<string> = createSelector(
  getDataApprovalRequest, (dataApprovalRequest: DataApprovalRequest) => {
    const identifier = dataApprovalRequest.additionalInformation.deviceTypeIdentifier;
    return identifier === undefined ? "" : identifier;
  });
