import { Record } from "immutable";
import { getStringValue } from "@util";
import {
  CompletionState,
  PrecedingWorkloadCompletionStatus,
  TriggerRateUnit,
  TriggerScheduleType,
  TriggerType
} from "@data/Trigger";
import AddTriggerRequest from "@data/AddTriggerRequest";

export class UpdateTriggerRequest extends Record({
  eventType: TriggerType.SCHEDULE,
  type: TriggerType.SCHEDULE,
  eventBindingId: "",
  rateValue: 30,
  rateUnit: TriggerRateUnit.MINUTES,
  scheduleType: TriggerScheduleType.RATE,
  scheduleExpression: "rate(30 minutes)",
  accountId: "",
  dataSetAlias: "",
  precedingWorkloadName: "",
  completionState: {
    actions: [],
    completionStatus: PrecedingWorkloadCompletionStatus.ANY,
  },
}) implements AddTriggerRequest {

  public static EMPTY: UpdateTriggerRequest = new UpdateTriggerRequest();

  public readonly eventType: TriggerType;
  public readonly "@type": TriggerType;
  public readonly type: TriggerType;
  public readonly eventBindingId: string;
  public readonly rateValue: number;
  public readonly rateUnit: TriggerRateUnit;
  public readonly scheduleType: TriggerScheduleType;
  public readonly scheduleExpression: string;
  public readonly accountId: string;
  public readonly dataSetAlias: string;
  public readonly precedingWorkloadName: string;
  public readonly completionState: CompletionState;

  public getType(): string {
    return this.eventType;
  }

  public getScheduleType(): TriggerScheduleType {
    return this.scheduleType;
  }

  public getScheduleExpression(): string {
    return this.scheduleExpression;
  }

  public getRateValue(): number {
    return this.rateValue;
  }

  public getRateUnit(): TriggerRateUnit {
    return this.rateUnit;
  }

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getDataSetAlias(): string {
    return getStringValue(this.dataSetAlias);
  }

  public getConditions(): string[] {
    const { actions = [] } = this.completionState;
    return Array.isArray(actions) ? actions : [];
  }

  public getPrecedingWorkloadName(): string {
    return getStringValue(this.precedingWorkloadName);
  }

  public getPrecedingWorkloadCompletionStatus(): PrecedingWorkloadCompletionStatus {
    const { completionStatus = PrecedingWorkloadCompletionStatus.ANY } = this.completionState;
    return completionStatus;  
  }

}

export default UpdateTriggerRequest;
