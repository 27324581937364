import React from "react";
import Module from "@data/Module";
import classnames from "classnames";
import { isEmptyString } from "@util";
import LayoutHeader from "./LayoutHeader";
import { layoutContent as styles } from "./styles";
import NotFoundView from "@components/error-view/NotFoundView";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import useDocumentTitle, { UseDocumentTitleProps } from "@hooks/use-document-title";

export interface Model {
  principalId?: string;
  routes?: Module[];
  showMyProfileButton?: boolean;
  profilePath?: string;
  settingsPath?: string;
  informationBannerVisible?: boolean;
  children?: React.ReactNode;
  showMaintenanceReminder?: boolean;
}

export interface Actions {
  onClickMyProfile?: () => void;
  onClickSettings?: () => void;
  onClickLogout?: () => void;
}

const Title = (props: UseDocumentTitleProps) => {

  useDocumentTitle(props);

  return null;
};

const PageNotFoundTitle = (props: {
  title?: string,
}) => {

  const { title = "Page Not Found" } = props;

  // Hide the site name when showing a page not found error
  return (
    <Title
      baseName=""
      pageName={title}
    />
  );
};

type Props = WithStyles<typeof styles> & Model & Actions;

export const PersistentView = withStyles(styles)((props: WithStyles<typeof styles> & Module) => {

  const { classes, id, path = "", view = null, style = {} } = props;

  const match = useRouteMatch(path);

  return (
    <div className={classnames("view", id, classes.view, { [classes.hidden]: !match })} style={{ ...style}}>
      {view}
    </div>
  );
});

export const LayoutContent = withStyles(styles)((props: Props) => {

  const {
    classes,
    principalId = "",
    routes = [],
    showMyProfileButton,
    profilePath,
    settingsPath,
    informationBannerVisible,
    showMaintenanceReminder = false,
    onClickSettings,
    onClickMyProfile,
    onClickLogout,
    children,
  } = props;

  return (
    <div className={classnames("content", classes.container)}>
      <LayoutHeader
        className={classnames("header", classes.header, {
          [classes.informationBannerVisible]: informationBannerVisible,
        })}
        routes={routes}
        principalId={principalId}
        showMyProfileButton={showMyProfileButton}
        profilePath={profilePath}
        settingsPath={settingsPath}
        onClickSettings={onClickSettings}
        onClickMyProfile={onClickMyProfile}
        onClickLogout={onClickLogout}
        showMaintenanceReminder={showMaintenanceReminder}
      />
      {routes
        .filter(module => !isEmptyString(module.id) && module.persist === true)
        .map(module => (
          <PersistentView
            key={module.id}
            {...module}
          />
        ))}
      <Switch>
        {routes.map((module: Module) => !module.view ? null : (
          <Route
            key={module.id}
            path={module.path}
            exact={module.exactPath}
            sensitive={module.sensitivePath}
            strict={module.strictPath}
          >
            {!module.dialog && (
              <Title pageName={module.name} />
            )}
            {!module.persist && (
              <div className={classnames("view", "module", classes.view)} style={{ ...(module.style || {} )}}>
                {module.view}
              </div>
            )}
          </Route>
        ))}
        <Route>
          <React.Fragment>
            <PageNotFoundTitle />
            <NotFoundView className={"error"}/>
          </React.Fragment>
        </Route>
      </Switch>
      {children}
    </div>
  );
});

export default LayoutContent;
