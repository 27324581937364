import React from "react";
import classnames from "classnames";
import { FederationProviderWizardState } from "../models";
import { DEFAULT_STEP_LABELS, DEFAULT_STEPS } from "../selectors";
import { FederationProviderWizardStep } from "../reducers/federationProviderWizard";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
} from "@components/submit-api-request-view";
import styles from "./styles";

type State = FederationProviderWizardState;
type Step = FederationProviderWizardStep;

export interface Model extends SubmitApiRequestViewModel<State, Step> {
  stepLabels?: { [key: string]: string };
}

export interface Actions extends SubmitApiRequestViewActions<State, Step> {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const FederationProviderWizard = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    snackbarId = "federation-provider-wizard",
    errorTitle = "Save Federation Provider Failed",
    saveButtonLabel = "Submit",
    steps = DEFAULT_STEPS,
    stepLabels = DEFAULT_STEP_LABELS,
    mapStepToLabel = React.useCallback(step => stepLabels[step] || null, [stepLabels]),
    currentState = FederationProviderWizardState.EMPTY,
    defaultState = FederationProviderWizardState.EMPTY,
    children,
    ...otherProps
  } = props;

  return (
    <SubmitApiRequestView
      {...otherProps}
      className={classnames("federationProviderWizard", className, classes.container)}
      snackbarId={snackbarId}
      errorTitle={errorTitle}
      saveButtonLabel={saveButtonLabel}
      steps={steps}
      currentState={currentState}
      defaultState={defaultState}
      mapStepToLabel={mapStepToLabel}
    >
      {children}
    </SubmitApiRequestView>
  );
});

export default FederationProviderWizard;
