import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { SummaryView, SummaryViewModel as Model } from "@components";
import { styles } from "./styles";

type Props = WithStyles<typeof styles> & Model;

export const ServicesSummaryView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    contentClassName,
    items,
    children,
  } = props;

  return (
    <SummaryView
      className={classnames("servicesSummaryView", className, classes.summaryView)}
      contentClassName={classnames("servicesSummaryViewContent", contentClassName, classes.summaryViewContent)}
      items={items}
    >
      {children}
    </SummaryView>
  );
});

export default ServicesSummaryView;
