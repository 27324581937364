import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { Model, CredentialsEditor } from "../../components/credentials/CredentialsEditor";
import CredentialsTable from "./CredentialsTable";
import { CredentialAttributes } from "@data";
import { DeviceEnrollmentWizardSelectors } from "../../selectors";

interface ContainerModel extends Model {
  hidden?: boolean;
  credentials?: CredentialAttributes[];
}

const CredentialsEditorContainer = (props: ContainerModel) => {

  const {
    hidden,
    credentials,
    ...remainingProps
  } = props;

  if (hidden) {
    return null;
  }

  return (
    <CredentialsEditor {...remainingProps}>
      <CredentialsTable
        credentials={credentials}
      />
    </CredentialsEditor>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => {

  const hidden = !DeviceEnrollmentWizardSelectors.isCredentialViewSelected(state);

  if (!DeviceEnrollmentWizardSelectors.isDeviceEnrollmentJsonValid(state)) {
    return {
      hidden,
      showInvalidJsonError: true,
      ...ownProps
    };
  }

  return {
    hidden,
    credentials: DeviceEnrollmentWizardSelectors.getDevice(state).getCredentials(),
    ...ownProps
  };
};

export default connect<ContainerModel, {}, ContainerModel>(
  mapStateToProps,
)(CredentialsEditorContainer);
