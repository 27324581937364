export type JsonSchemaPropertiesDefinition = {
  [key: string]: any
};

export type JsonSchemaDefinition = {
  $schema: string,
  title: string,
  description: string,
  type: string,
  definitions?: JsonSchemaPropertiesDefinition,
  properties: JsonSchemaPropertiesDefinition,
  required: string[],
} & {
  [key: string]: any,
};

export const DEFAULT_SCHEMA: JsonSchemaDefinition = {
  $schema: "http://json-schema.org/draft-07/schema#",
  title: "",
  description: "",
  type: "object",
  properties: {},
  required: [],
};
