import React from "react";
import { GetWorkloadCodeHeadResponse } from "@network";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  UseApiRequestActions,
  useWorkloadManagerClient,
} from "@hooks";

type SuccessResponse = GetWorkloadCodeHeadResponse;

export interface UseGetWorkloadCodeHeadProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
}

export interface UseGetWorkloadCodeHeadModel extends UseApiRequestModel<SuccessResponse> {
  eTag?: string;
  lastModified?: string;
  contentLength?: string;
  originalFilename?: string;
}

export interface UseGetWorkloadCodeHeadActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetWorkloadCodeHeadProps;
type Model = UseGetWorkloadCodeHeadModel;
type Actions = UseGetWorkloadCodeHeadActions;
type Result = [Model, Actions];

export const useGetWorkloadCodeHead = (props: Props): Result => {

  const {
    name,
    version,
    defaultErrorMessage = "Failed to get workload code head",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() => WorkloadManagerClient.getWorkloadCodeHeadApi(name, version),
    [WorkloadManagerClient, name, version]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const contentLength = React.useMemo<string>(() => {
    return successResponse ? (successResponse.contentLength ? successResponse.contentLength : "") : "";
  }, [successResponse]);

  const lastModified = React.useMemo<string>(() => {
    return successResponse ? (successResponse.lastModified ? successResponse.lastModified : "") : "";
  }, [successResponse]);

  const eTag = React.useMemo<string>(() => {
    return successResponse ? (successResponse.etag ? successResponse.etag : "") : "";
  }, [successResponse]);

  const originalFilename = React.useMemo<string>(() => {
    return successResponse ? (successResponse.originalFilename ? successResponse.originalFilename : "") : "";
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    eTag,
    lastModified,
    contentLength,
    originalFilename,
    ...baseModel,
  }), [
    eTag,
    lastModified,
    contentLength,
    originalFilename,
    baseModel,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetWorkloadCodeHead;
