import React from "react";
import classnames from "classnames";
import { ActionMenuItem, ActionsMenu, ActionsMenuProps } from "@components";
import { noop } from "@util";

export enum TriggerAction {
  TEST_TRIGGER = "test_trigger",
  EDIT_TRIGGER = "edit_trigger",
  REMOVE_TRIGGER = "remove_trigger",
}

export const TEST_TRIGGER: ActionMenuItem = Object.freeze({
  id: TriggerAction.TEST_TRIGGER,
  name: "Test Trigger",
  disabled: false,
});

export const EDIT_TRIGGER: ActionMenuItem = Object.freeze({
  id: TriggerAction.EDIT_TRIGGER,
  name: "Edit Trigger",
  disabled: false,
});

export const REMOVE_TRIGGER: ActionMenuItem = Object.freeze({
  id: TriggerAction.REMOVE_TRIGGER,
  name: "Remove Trigger",
  disabled: false,
});

export const DEFAULT_TRIGGER_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  TEST_TRIGGER,
  EDIT_TRIGGER,
  REMOVE_TRIGGER,
]) as ActionMenuItem[];

export interface TriggerActionsMenuProps extends ActionsMenuProps {
  removeTrigger?: () => void;
  editTrigger?: () => void;
  testTrigger?: () => void;
}

export const withTriggerActions = (Component: React.ComponentType<TriggerActionsMenuProps>) =>
  (props: TriggerActionsMenuProps) => {

    const {
      actions = DEFAULT_TRIGGER_ACTION_MENU_ITEMS,
      removeTrigger = noop,
      editTrigger = noop,
      testTrigger = noop,
      onClickAction = noop,
    } = props;

    return (
      <Component
        {...props}
        actions={actions}
        onClickAction={(action: ActionMenuItem) => {
          switch (action.id) {
            case TriggerAction.EDIT_TRIGGER:
              return editTrigger();
            case TriggerAction.REMOVE_TRIGGER:
              return removeTrigger();
            case TriggerAction.TEST_TRIGGER:
              return testTrigger();
            default:
              return onClickAction(action);
          }
        }}
      />
    );
  };

export const TriggerActionsMenu = withTriggerActions((props: TriggerActionsMenuProps) => {

  const { className, buttonLabel = "Trigger Actions" } = props;

  return (
    <ActionsMenu
      {...props}
      className={classnames("triggerActionsMenu", className)}
      buttonLabel={buttonLabel}
    />
  );
});

export default TriggerActionsMenu;
