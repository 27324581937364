import Color from "@Color";
import ModuleIcon from "@material-ui/icons/MenuBook";
import createStyles from "@material-ui/core/styles/createStyles";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    list: {
      "& th.description, & td.description": {
        width: "50%",
      },
      "& td.tableBodyColumn .description.value": {
        display: "block",
        margin: "8px 8px 8px 0",
        whiteSpace: "pre-line",
      },
    },
    alert: {
      fontSize: "14px",
      alignItems: "center",
      marginTop: "20px",
    },
    link: {
      color: "inherit",
    },
    typeChip: {
      backgroundColor: Color.GREY1,
      height: "auto !important",
      padding: "6px 10px !important",
      "& span": {
        color: Color.TEXT,
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "11px",
        margin: 0,
        padding: 0,
      },
    },
    chip: {
      height: "auto !important",
      padding: "6px 10px !important",
      "& span": {
        color: Color.TEXT,
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "11px",
        margin: 0,
        padding: 0,
      },
    },
    planned: {
      backgroundColor: Color.LIGHT_GREEN,
    },
    in_progress: {
      backgroundColor: Color.LIGHT_YELLOW,
    },
    postponed: {
      backgroundColor: Color.LIGHT_GREY3,
    },
    completed: {
      backgroundColor: Color.LIGHT_BLUE2,
    },
    quickFilters: {
      marginTop: 15,
      marginLeft: 4,
      "& + table.list": {
        marginTop: 0,
      },
      "& .searchFilters": {
        marginTop: 13,
        flexFlow: "row nowrap",
        alignItems: "center",
        "& .searchFilter": {
          marginTop: 0,
          marginLeft: 8,
          "&:first-child": {
            marginLeft: 0,
          },
        },
      },
    },
  });

export default styles;
