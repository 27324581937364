import { createStyles } from "@material-ui/core/styles";

export const styles = () =>
  createStyles({
    container: {
      color: "inherit",
      "& table": {
        marginTop: 15,
      },
      "& td label": {
        cursor: "pointer",
      },
    },
  });

export default styles;
