import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { SchemaStateManagerActions } from "../../schemaStateManager/actions";
import { fetchItems, reloadItems, setNameFilter, setSearchFilters } from "../actions";
import {
  getErrorMessage,
  getNameFilter,
  getNumSchemas,
  getSchemas,
  getSearchFilters,
  isNotFoundVisible,
  isAccessDeniedVisible,
  isErrorViewVisible,
  isLoadingViewVisible,
  isLoadMoreButtonVisible,
  isNoResultsViewVisible,
} from "../selectors";
import Schemas, { Actions, Model } from "../components/Schemas";

type Props = Model & Actions;

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  schemas: getSchemas(state),
  nameFilter: getNameFilter(state),
  searchFilters: getSearchFilters(state),
  numResults: getNumSchemas(state),
  errorMessage: getErrorMessage(state),
  showErrorView: isErrorViewVisible(state),
  showProgressIndicator: isLoadingViewVisible(state),
  showLoadMoreButton: isLoadMoreButtonVisible(state),
  showNoResultsView: isNoResultsViewVisible(state),
  showAccessDenied: isAccessDeniedVisible(state),
  showNotFound: isNotFoundVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setNameFilter: nameFilter => dispatch(setNameFilter(nameFilter)),
  setSearchFilters: searchFilters => dispatch(setSearchFilters(searchFilters)),
  loadMore: () => dispatch(fetchItems()),
  refresh: () => dispatch(reloadItems()),
  promoteSchema: schema => dispatch(SchemaStateManagerActions.openPromoteSchemaDialog(schema)),
  deprecateSchema: schema => dispatch(SchemaStateManagerActions.openDeprecateSchemaDialog(schema)),
  decommissionSchema: schema => dispatch(SchemaStateManagerActions.openDecommissionSchemaDialog(schema)),
  deleteSchema: schema => dispatch(SchemaStateManagerActions.openDeleteSchemaDialog(schema)),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(Schemas);
