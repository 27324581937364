import React from "react";
import { useHistory } from "react-router-dom";
import { getCreateDataSetRequestPath } from "@modules/dataSetRequestWizard/helpers";
import { getDataAccessRequestPath } from "@modules/dataAccessRequestWizard/helpers";
import { getPathToDataSet } from "@modules/dataSetDetails/helpers";
import DataSets from "./containers/DataSets";

export function DataSetsView() {

  const history = useHistory();

  const createDataSetRequest = React.useCallback(() =>
    history.push(getCreateDataSetRequestPath()), [history]);

  const requestDataSetAccess = React.useCallback((dataSetId: string) =>
    history.push(getDataAccessRequestPath(dataSetId)), [history]);

  const showDataSetDetails = React.useCallback((dataSetId: string) =>
    history.push(getPathToDataSet(dataSetId)), [history]);

  return (
    <DataSets
      createDataSetRequest={createDataSetRequest}
      requestDataSetAccess={requestDataSetAccess}
      showDataSetDetails={showDataSetDetails}
    />
  );
}

export default DataSetsView;
