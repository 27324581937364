import createStyles from "@material-ui/core/styles/createStyles";
import { StyleRules } from "@material-ui/core/styles";

export const textField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "14px",
      fontWeight: 300,
      padding: "2px 0",
      margin: 0,
    },
    "& input": {
      fontSize: 14,
      fontWeight: 400,
      padding: "18.5px 14px",
      margin: 0,
    },
  },
};

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 16,
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
    keyValuePair: {
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "16px",
      marginTop: 16,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    configurationTextFields: {
      marginTop: 20,
      display: "flex",
      flexFlow: "row nowrap",
    },
    configurationKey: {
      fontFamily: "Roboto, sans-serif",
      minWidth: 250,
    },
    configurationValue: {
      fontFamily: "Roboto, sans-serif",
      minWidth: 250,
      marginLeft: 25,
    },
  });

export default styles;
