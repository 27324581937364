import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  RefreshButton,
  Search,
  withQuantity,
} from "@components";
import { PrimaryIcon, styles } from "./styles";
import { isEmptyString } from "@util";

export const Summary = withQuantity({
  other: "devices",
  one: "device"
});

export interface Model {
  searchQuery?: string;
  loading?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  updateSearchQuery?: (searchQuery: string, previousSearchQuery?: string) => void;
  refresh?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DeviceEnrollment = withStyles(styles)((props: Props) => {

  const {
    classes,
    searchQuery = "",
    loading,
    children,
    updateSearchQuery,
    refresh,
  } = props;

  return (
    <div className={classnames("devices", classes.container)}>
      <Summary label="Enter device id to view device status" icon={PrimaryIcon}>
        <RefreshButton loading={loading} refresh={refresh} disabled={isEmptyString(searchQuery)}/>
      </Summary>
      <Search
        className={classes.search}
        hint="Enter Device ID"
        value={searchQuery}
        updateSearchQuery={updateSearchQuery}
      />
      {children}
    </div>
  );
});

export default DeviceEnrollment;
