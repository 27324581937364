import React from "react";
import {
  UseApiRequestModel,
  UseApiRequestProps,
  useApiRequest,
  useApplicationManagerRegionalClient
} from "@hooks";
import { ApplicationSecretOperations } from "./ApplicationSecretOperation";
import { Application, ApplicationAttributes } from "@data";

type SuccessResponse = ApplicationAttributes | void;

export interface UseApplicationSecretManagementProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  applicationId: string;
  secret?: string;
  operation: ApplicationSecretOperations;
}

export interface UseApplicationSecretManagementModel extends UseApiRequestModel<SuccessResponse> {
  application: Application;
  successMessage: string;
}

export interface UseApplicationSecretManagementActions {
  performOperation: () => void;
}

type Props = UseApplicationSecretManagementProps;
type Model = UseApplicationSecretManagementModel;
type Actions = UseApplicationSecretManagementActions;
type Result = [Model, Actions];

export const useApplicationSecretManagement = (props: Props): Result => {

  const {
    applicationId = "",
    secret = "",
    operation = ApplicationSecretOperations.NONE,
    ...otherProps
  } = props;

  const ApplicationRegionalClient = useApplicationManagerRegionalClient();

  const defaultErrorMessage = React.useMemo(() =>
    `Failed to ${operation} application secret`, [operation]);

  const mapApiToOperation = React.useCallback(() => {
    switch (operation) {
    case ApplicationSecretOperations.DELETE:
      return ApplicationRegionalClient.deleteApplicationSecretApi(applicationId);
    case ApplicationSecretOperations.SET:
      return ApplicationRegionalClient.setApplicationSecretApi(applicationId, JSON.stringify({ secret }));
    case ApplicationSecretOperations.GENERATE:
      return ApplicationRegionalClient.generateApplicationSecretApi(applicationId);
    default:
      return Promise.resolve();
    }
  }, [operation, applicationId, secret]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: performOperation }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => mapApiToOperation(),
    });

  const application = React.useMemo<Application>(() => {
    const attrs = successResponse || {};
    return new Application(attrs);
  }, [successResponse]);

  const successMessage = React.useMemo(() =>
      showSuccessView ? `${operation} Successful` : "" ,
    [showSuccessView, operation]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    application,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    application,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    performOperation
  }), [performOperation]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useApplicationSecretManagement;
