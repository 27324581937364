import createStyles from "@material-ui/core/styles/createStyles";
import { PrimaryIcon } from "../../styles";
import Color from "@Color";

export { PrimaryIcon };

export const styles = () =>
  createStyles({
    container: {
      cursor: "pointer",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      textDecoration: "none",
    },
    icon: {
      marginRight: 8,
      color: Color.SIGNIFY_GREEN,
      width: 16,
      height: 16,
    },
    column: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    noLink : {
      cursor: "default",
    },
    name: {
      cursor: "inherit",
      color: Color.TEXT,
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "16px",
      textDecoration: "underline",
      wordBreak: "break-word",
    },
    description: {
      marginTop: 8,
      cursor: "inherit",
      color: Color.GREY3,
      fontSize: 11,
      fontWeight: 300,
      lineHeight: "13px",
      wordBreak: "break-word",
    },
  });

export default styles;
