import React from "react";
import { noop } from "@util";
import { styles } from "./styles";
import classnames from "classnames";
import DownloadFile from "@components/download-file";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { useGetWorkloadCode } from "@hooks/workloadsManager/use-get-workload-code";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";

export interface DownloadCodeDialogModel extends PortalModuleDialogModel<string> {
  dialogClassName?: string;
  workloadName?: string;
  version?: number;
  fileType?: string;
}

export interface DownloadCodeDialogActions extends PortalModuleDialogActions<string> {
}

type Props = WithStyles< typeof styles> & DownloadCodeDialogModel & DownloadCodeDialogActions;

export const DownloadWorkloadCode = withStyles(styles)((props: Props) => {

  const {
    classes,
    dialogClassName,
    workloadName = "",
    version = -1,
    fileType = "jar",
    cancel = noop,
    onSuccessMessageShown = noop,
    ...otherProps
  } = props;

  const [ model, actions ] = useGetWorkloadCode({ workloadName, version });

  const { showLoadingIndicator, successResponse, url, ...otherModel } = model;
  const { download, reset } = actions;

  const href = React.useMemo(() => url, [url]);

  const [downloading, setDownloading] = React.useState(false);
  const downloadStarted = React.useCallback(() => setDownloading(true), [setDownloading]);
  const downloadFinished = React.useCallback(() => setDownloading(false), [setDownloading]);

  const closeDialog = React.useCallback(() => {
    reset();
    cancel();
    setDownloading(false);
  }, [cancel, reset, setDownloading]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      className={classnames("downloadCodeDialog", dialogClassName)}
      title={"Download Workload Code"}
      loading={showLoadingIndicator}
      continueButtonLabel={"Download"}
      confirm={download}
      cancel={closeDialog}
      onSuccessMessageShown={closeDialog}
    >
      <div className={classnames("workloadOperation", classes.container)}>
        <React.Fragment>
          {!downloading && (
            <label className={classnames("title", classes.title)}>
              Are you sure you want to download the code for this workload?
            </label>
          )}
          {downloading && (
            <label className={classnames("title", classes.title)}>
              Downloading...
            </label>
          )}
          <label className={classnames("workloadName", classes.workloadName)}>
            {workloadName}
          </label>
          <DownloadFile
            href={href}
            fileName={`${workloadName}.${fileType}`}
            downloadStarted={downloadStarted}
            downloadFinished={downloadFinished}
          />
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default DownloadWorkloadCode;
