import { combineReducers } from "redux";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base/createReducers";
import { MODULE_ID } from "./constants";

export interface DataCatalogSchema extends BasePortalModuleSchema {
}

export const DEFAULT_STATE: DataCatalogSchema = createPortalModuleDefaultState<DataCatalogSchema>({
  showEmptyView: false,
});

type DataCatalogActionTypes = PortalModuleActionTypes<DataCatalogSchema> & {
  UPLOAD_TERMS_AND_CONDITIONS_REQUEST: string;
  UPLOAD_TERMS_AND_CONDITIONS_SUCCESS: string;
  UPLOAD_TERMS_AND_CONDITIONS_FAILED: string;
};

export const ACTION_TYPES: DataCatalogActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<DataCatalogSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    UPLOAD_TERMS_AND_CONDITIONS_REQUEST: `${MODULE_ID}/upload_terms_and_conditions_request`,
    UPLOAD_TERMS_AND_CONDITIONS_SUCCESS: `${MODULE_ID}/upload_terms_and_conditions_success`,
    UPLOAD_TERMS_AND_CONDITIONS_FAILED: `${MODULE_ID}/upload_terms_and_conditions_failed`,
  },
});

export const DataCatalogReducer = combineReducers<DataCatalogSchema>(
  createPortalModuleReducers<DataCatalogSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DataCatalogReducer;
