import { createStyles, Theme } from "@material-ui/core/styles";

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      "& > .MuiDialog-container > .MuiDialog-paper": {
        maxWidth: 1600,
      },
    },
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
      marginBottom: 30,
    },
  });

export default styles;
