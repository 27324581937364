import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { getCommentsViewTitle, getErrorMessage } from "../selectors";
import {
  CommentsView,
  CommentsViewModel as Model,
} from "@components/request-comments/CommentsView";
import CreateCommentView from "./CreateCommentView";
import CommentsListView from "./CommentsListView";

const CommentsViewContainer = (props: Model) => {

  return (
    <CommentsView {...props}>
      <CreateCommentView />
      <CommentsListView />
    </CommentsView>
  );
};

const mapStateToProps = (state: AppSchema,  ownProps: Model): Model => ({
  title: getCommentsViewTitle(state),
  errorMessage: getErrorMessage(state),
  ...ownProps
});

export default connect<Model, {}, Model>(
  mapStateToProps,
)(CommentsViewContainer);
