import React from "react";
import classnames from "classnames";
import {
  SubmitApiRequestView,
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
  ThemeProvider,
} from "@components";
import { SchemaWizardStep as Step } from "../reducers/schemaWizard";
import SchemaWizardState from "../models/SchemaWizardState";
import theme from "./theme";

export const WIZARD_STEP_LABELS = {
  [Step.NONE]: null,
  [Step.NAMESPACE]: "Schema Namespace",
  [Step.NAME]: "Schema Name & Description",
  [Step.SCHEMA]: "Schema Properties",
  [Step.REVIEW]: "Review",
};

export interface Model extends SubmitApiRequestViewModel<SchemaWizardState, Step> {
}

export interface Actions extends SubmitApiRequestViewActions<SchemaWizardState, Step> {
}

type Props = Model & Actions & {
  children?: React.ReactNode;
};

export function SchemaWizard(props: Props) {

  const {
    className,
    snackbarId = "schema-wizard",
    errorTitle = "Save Schema Failed",
    currentState = SchemaWizardState.EMPTY,
    defaultState = SchemaWizardState.EMPTY,
    mapStepToLabel = (step: Step) => WIZARD_STEP_LABELS[step] || null,
    children,
    ...otherProps
  } = props;

  return (
    <ThemeProvider theme={theme}>
      <SubmitApiRequestView
        {...otherProps}
        className={classnames("schemaWizard", className)}
        snackbarId={snackbarId}
        errorTitle={errorTitle}
        currentState={currentState}
        defaultState={defaultState}
        mapStepToLabel={mapStepToLabel}
      >
        {children}
      </SubmitApiRequestView>
    </ThemeProvider>
  );
}

export default SchemaWizard;
