import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      padding: 25,
      minWidth: 500,
    },
    filter: {
      marginTop: 25,
      "&:first-child": {
        marginTop: 0,
      },
    },
    datePicker: {
    },
    startDate: {
    },
    endDate: {
    },
    fileName: {
    },
  });

export default styles;
