import React from "react";
import { applyInProd, equalsIgnoreCase } from "@util/Functions";
import BuildInfo, { BuildInfoAnchor } from "@components/build-info/BuildInfo";

export const withBuildInfo = (Component: React.ComponentType<any>,
                              anchor: BuildInfoAnchor = BuildInfoAnchor.TOP_LEFT) => {

  const DEVELOPMENT_MODE = process.env.NODE_ENV === "development";

  const SHOW_BUILD_INFO = applyInProd(() =>
      equalsIgnoreCase("true", process.env.REACT_APP_SHOW_BUILD_INFO),
    () => DEVELOPMENT_MODE && // We do this check just in case tests are running
      !equalsIgnoreCase("false", process.env.REACT_APP_SHOW_BUILD_INFO));

  if (!SHOW_BUILD_INFO) {
    return Component;
  }

  const MemoizedBuildInfo = React.memo(() => <BuildInfo anchor={anchor} />);

  return (props: any) => (
    <React.Fragment>
      <Component {...props} />
      <MemoizedBuildInfo />
    </React.Fragment>
  );
};

export default withBuildInfo;
