import React from "react";
import { noop } from "@util";
import { AuditEvent } from "@data";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { useAuditEvents } from "@hooks";
import AuditEvents, {
  AuditEventsActions as Actions,
  AuditEventsModel as Model,
} from "@components/audit-events";
import {
  findEventsFailure,
  findEventsRequest,
  findEventsSuccess,
  updateAutocompleteOptions,
} from "@modules/auditEvents/actions";

export interface ContainerModel extends Model {
}

export interface ContainerActions extends Actions {
  setAutocompleteOptions?: (events: AuditEvent[]) => void;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
}

type Props = ContainerModel & ContainerActions;

const AuditEventsContainer = (props: Props) => {

  const { setAutocompleteOptions = noop, ...otherProps } = props;

  const [model, actions] = useAuditEvents(otherProps);

  const { items = [] } = model;

  React.useEffect(() => {
    setAutocompleteOptions(items);
  }, [items, setAutocompleteOptions]);

  return (
    <AuditEvents
      {...model}
      {...actions}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setAutocompleteOptions: events => dispatch(updateAutocompleteOptions(events)),
  trackRequestEvent: () => dispatch(findEventsRequest()),
  trackSuccessEvent: () => dispatch(findEventsSuccess()),
  trackErrorEvent: analytic => dispatch(findEventsFailure(analytic)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(AuditEventsContainer);
