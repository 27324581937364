import { Theme } from "@material-ui/core/styles/createTheme";
import { createStyles } from "@material-ui/core/styles";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      "& .content": {
        paddingTop: 16,

        "& .errorView": {
          padding: "4px 16px",
          flexFlow: "row wrap",
          marginBottom: 0,

          "& .icon": {
            width: 24,
            height: 24,
            fontSize: 12,
            marginRight: 8,
          },

          "& .title": {
            display: "none",
          },

          "& .message": {
            fontSize: 12,
            lineHeight: 0,
            margin: 0,
          },
        },
      },
    },
  });
