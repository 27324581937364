import React from "react";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useWorkloadManagerClient } from "@hooks";

export interface UseRemoveWorkloadTriggerProps extends Partial<UseApiRequestProps<void>> {
  name: string;
  version: number;
  eventSources?: string[];
}

export interface UseRemoveWorkloadTriggerModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UseRemoveWorkloadTriggerActions {
  removeWorkloadTrigger: () => void;
  reset: () => void;
}

type Props = UseRemoveWorkloadTriggerProps;
type Model = UseRemoveWorkloadTriggerModel;
type Actions = UseRemoveWorkloadTriggerActions;
type Result = [Model, Actions];

export const useRemoveWorkloadTrigger = (props: Props): Result => {

  const {
    name,
    version,
    eventSources = [],
    defaultErrorMessage = "Failed to remove workload trigger",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.removeWorkloadTriggerApi(name, version, eventSources),
    [WorkloadManagerClient, name, version, eventSources]);

  const [{ showSuccessView, ...baseModel }, { refresh: deleteWorkloadTrigger, reset }] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Delete Successful" : "" ,
    [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    removeWorkloadTrigger: deleteWorkloadTrigger,
    reset,
  }), [deleteWorkloadTrigger, reset]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useRemoveWorkloadTrigger;
