import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useDataSetRequestClient,
} from "@hooks";

type SuccessResponse = void;

export interface UseCreateDataSetRequestCommentProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  dataSetRequestId: string;
  json: string;
}

export interface UseCreateDataSetRequestCommentModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseCreateDataSetRequestCommentActions extends UseApiRequestActions<SuccessResponse> {
  createComment: () => void;
}

type Props = UseCreateDataSetRequestCommentProps;
type Model = UseCreateDataSetRequestCommentModel;
type Actions = UseCreateDataSetRequestCommentActions;
type Result = [Model, Actions];

export const useCreateDataSetRequestComment = (props: Props): Result => {

  const {
    json = "",
    dataSetRequestId = "",
    defaultErrorMessage = "Failed to create data set request comment",
    ...otherProps
  } = props;

  const DataSetRequestClient = useDataSetRequestClient();

  const makeApiRequest = React.useCallback(() =>
    DataSetRequestClient.createDataSetRequestComment(dataSetRequestId, json),
    [DataSetRequestClient, dataSetRequestId, json]);

  const [{ showSuccessView, successResponse, ...baseModel }, {
    refresh: createComment,
    ...baseActions
  }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
    showSuccessView ? "Comment Created" : "",
    [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    createComment,
    refresh: createComment,
  }), [
    baseActions,
    createComment,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useCreateDataSetRequestComment;
