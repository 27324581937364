import React from "react";
import classnames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import MuiBadge, { BadgeProps as MuiBadgeProps } from "@material-ui/core/Badge";
import styles from "./styles";

const CustomBadge = withStyles(styles)(MuiBadge);

export interface BadgeProps extends MuiBadgeProps {
}

export const Badge = React.forwardRef((props: BadgeProps, ref?: React.Ref<any>) => {

  const { className, color = "primary", ...otherProps } = props;

  return (
    <CustomBadge
      {...otherProps}
      ref={ref}
      className={classnames("badge", className)}
      overlap="rectangular"
      color={color}
    />
  );
});

export default Badge;
