import React from "react";
import CertificateAuthorityListColumn from "./CertificateAuthorityListColumn";
import {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import { CertificateAuthority } from "@data";
import ModuleListView from "@components/module-list-view";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import { sortAndFilterCertificateAuthorityResult } from "@hooks";
import { CertificateAuthorityIcon } from "./styles";

export const DEFAULT_CERTIFICATE_AUTHORITY_LIST_COLUMNS: CertificateAuthorityListColumn[] = [
  CertificateAuthorityListColumn.AUTHORITY_ID,
  CertificateAuthorityListColumn.COMMON_NAME,
];

export type CertificateAuthorityListModel =
  SortedSearchResultsListModel<CertificateAuthority, CertificateAuthorityListColumn>;

export type CertificateAuthorityListActions =
  SortedSearchResultsListActions<CertificateAuthority, CertificateAuthorityListColumn>;

type Props = CertificateAuthorityListModel & CertificateAuthorityListActions;

export const CertificateAuthorityList = (props: Props) => {

  const {
    className = "certificateAuthorities",
    listClassName = "certificateAuthoritiesList",
    columns = DEFAULT_CERTIFICATE_AUTHORITY_LIST_COLUMNS,
    sortByColumn = CertificateAuthorityListColumn.AUTHORITY_ID,
    sortOrderAscending = true,
    noResultsLabel = "No authorities found for this account",
    summaryViewQuantities = {
      other: "authorities",
      one: "authority",
    },
    items = [],
    nameFilter = "",
    ...otherProps
  } = props;

  const certificateAuthorities =
    sortAndFilterCertificateAuthorityResult({ items, nameFilter, sortOrderAscending, sortByColumn });

  const moduleListItems: ListViewItem<CertificateAuthority>[] = React.useMemo(() =>
    certificateAuthorities.map(certificate => {
      return {
        item: certificate,
        pathToDetailsView: "",
        icon: CertificateAuthorityIcon,
        columnAttributes: createColumns([
          {
            className: "certificateId",
            value: certificate.getId(),
            column: CertificateAuthorityListColumn.AUTHORITY_ID,
            firstColumn: true,
          },
          {
            className: "certificateName",
            value: certificate.getCommonName(),
            column: CertificateAuthorityListColumn.COMMON_NAME,
          },
        ])
      };
    }), [items]);

  return (
    <ModuleListView
      {...otherProps}
      className={className}
      listViewItems={moduleListItems}
      columns={columns}
      nameFilter={nameFilter}
      nameFilterDelay={25}
      sortByColumn={sortByColumn}
      sortOrderAscending={sortOrderAscending}
      noResultsLabel={noResultsLabel}
      summaryViewIcon={CertificateAuthorityIcon}
      summaryViewQuantities={summaryViewQuantities}
    />
  );
};

export default CertificateAuthorityList;
