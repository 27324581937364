import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  Alert,
  AlertTitle,
  ContinueButton,
  DetailsView,
  DetailsViewRoute,
  DownloadTrainingCertificateButton,
  PRIVACY_DATA_PROTECTION_LINK,
  SummaryView,
} from "@components";
import { PrimaryIcon, styles } from "./styles";
import { clickHandler, isEmptyString } from "@util";
import { SummaryViewData, TrainingCertificateInfo } from "@data";
import TrainingCertificatePdfViewer from "@components/training-certificate-pdf-viewer";

export interface Model {
  title?: string;
  loading?: boolean;
  trainingCompleted?: boolean;
  certificateDetails?: TrainingCertificateInfo;
  statusErrorMessage?: string;
  detailsErrorMessage?: string;
  trainingLink?: string;
  children?: React.ReactNode;
}

export interface Actions {
  refresh?: () => void;
  uploadCertificate?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const TrainingCertificate = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Training Certificate",
    statusErrorMessage,
    detailsErrorMessage,
    trainingCompleted,
    certificateDetails = TrainingCertificateInfo.EMPTY,
    loading,
    trainingLink = PRIVACY_DATA_PROTECTION_LINK,
    children,
    refresh,
    uploadCertificate,
  } = props;

  const trainingStatus = React.useMemo(() => {
    if (loading) {
      return "Loading";
    } else if (!isEmptyString(statusErrorMessage)) {
      return "Error while fetching training status";
    } else if (trainingCompleted) {
      return "Training Completed";
    } else {
      return "Training Not Completed";
    }
  }, [loading, statusErrorMessage, trainingCompleted]);

  const certificateStatus = React.useMemo(() => {
    if (!isEmptyString(detailsErrorMessage) || !trainingCompleted || loading) {
      return "";
    } else if (certificateDetails.isExpired()) {
      return "Certificate Expired";
    } else {
      return "Certificate Valid";
    }
  }, [detailsErrorMessage, certificateDetails, trainingCompleted, loading]);

  const errorMessage = React.useMemo(() => {
    if (!isEmptyString(statusErrorMessage)) {
      return statusErrorMessage;
    } else if (!isEmptyString((detailsErrorMessage))) {
      return detailsErrorMessage;
    } else {
      return "";
    }
  }, [statusErrorMessage, detailsErrorMessage]);

  const header = (
    <Alert severity="info" className={classnames("certificateInfo", classes.alert)}>
      <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
        <strong>
          Note
        </strong>
      </AlertTitle>
      <ul className={classnames("alertDescription", classes.alertDescription)}>
        <li>
          A Privacy & Data Protection learning transcript completion certificate is required to request data access
        </li>
        <li>
          The certificate is a PDF document issued upon completion of the training course
        </li>
        <li>
          The certificate can be obtained by completing
          <a
            className={classes.underlined}
            href={trainingLink}
            target="_blank"
          >
            Privacy & Data Protection
          </a>
          training
        </li>
        <li>
          Every year, the training must be re-taken and a valid certificate must be provided
          as evidence of completion
        </li>
      </ul>
    </Alert>
  );

  const headerButtons = React.useMemo(() =>
    (
      <React.Fragment>
        <ContinueButton
          className={classnames("uploadCertificateButton", classes.uploadButton)}
          label="Upload Training Certificate"
          onClick={clickHandler(uploadCertificate)}
          primary={false}
        />
        <DownloadTrainingCertificateButton
          className={classnames("downloadCertificateButton", classes.downloadCertificateButton)}
          buttonClassName={classnames("downloadButton", classes.downloadButton)}
          label="Download Training Certificate"
        />
      </React.Fragment>
    ), [uploadCertificate]);

  const summaryViewItems = React.useMemo(() => [
    new SummaryViewData({
      className: "trainingCompleted",
      name: "Training Status",
      value: trainingStatus,
    }),
    new SummaryViewData({
      className: "trainingCertificateId",
      name: "Certificate ID",
      value: certificateDetails.getTrainingCertificateId(),
    }),
    new SummaryViewData({
      className: "fileName",
      name: "File Name",
      value: certificateDetails.getFileName(),
    }),
    new SummaryViewData({
      className: "uploadedDate",
      name: "Uploaded Date",
      value: certificateDetails.getUploadedDate(),
    }),
    new SummaryViewData({
      className: "certifiedDate",
      name: "Certificate Date",
      value: certificateDetails.getCertifiedDate(),
    }),
    new SummaryViewData({
      className: "expiryDate",
      name: "Expiry Date",
      value: certificateDetails.getExpiryDate(),
    }),
    new SummaryViewData({
      className: "expired",
      name: "Certificate Status",
      value: certificateStatus,
    }),
  ], [trainingStatus, certificateDetails, certificateStatus]);

  const DetailsSummaryView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <SummaryView
        items={summaryViewItems}
      />
    );
  }, [summaryViewItems]);

  const route: DetailsViewRoute[] = React.useMemo(() => [
    {
      id: "summaryView",
      name: "Summary",
      view: DetailsSummaryView,
    }
  ], [DetailsSummaryView]);

  return (
    <div className={classnames("trainingCertificate", classes.container)}>
      <DetailsView
        title={title}
        icon={PrimaryIcon}
        refresh={refresh}
        showLoadingIndicator={loading}
        errorMessage={errorMessage}
        header={header}
        headerButtons={headerButtons}
        routes={route}
        tabsMarginTop={false}
      >
        <TrainingCertificatePdfViewer
          className={classnames("pdfViewer", classes.pdfViewer)}
          trainingCertificateId={certificateDetails.getTrainingCertificateId()}
        />
      </DetailsView>
      {children}
    </div>
  );
});

export default TrainingCertificate;
