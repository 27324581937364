import React from "react";
import classnames from "classnames";
import {
  ProfileAttributeDefinitionList,
  ProfileAttributeDefinitionListActions,
  ProfileAttributeDefinitionListModel
} from "@components/profile-attribute-definition-list";
import { ActionMenuItem, EDIT_PROFILE_ATTRIBUTE_DEFINITION_ACTION } from "@components";
import { noop } from "@util";
import { ProfileAttributeDefinitionActionsMenu } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface Model extends ProfileAttributeDefinitionListModel {
  actions?: ActionMenuItem[];
  actionsLabel?: string;
  showNotFound?: boolean;
  showAccessDenied?: boolean;
  children?: React.ReactNode;
}

export interface Actions extends ProfileAttributeDefinitionListActions {
  editProfileAttributeDefinitions?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const ProfileAttributeDefinitions = withStyles(styles)((props: Props) => {

  const {
    classes,
    actionsLabel = "Actions",
    actions = [EDIT_PROFILE_ATTRIBUTE_DEFINITION_ACTION],
    children,
    editProfileAttributeDefinitions = noop,
    ...otherProps
  } = props;

  const { showLoadingIndicator } = otherProps;

  return (
    <ProfileAttributeDefinitionList
      {...otherProps}
      className={classnames("profileAttributeDefinitions", classes.container)}
      listClassName={classnames("profileAttributeDefinitionsList", classes.list)}
      tableLayoutFixed={true}
      showSummary={true}
      showSearch={true}
    >
      <ProfileAttributeDefinitionActionsMenu
        className={classes.profileAttributeDefinitionActions}
        buttonLabel={actionsLabel}
        actions={actions}
        disabled={showLoadingIndicator}
        editProfileAttributeDefinition={editProfileAttributeDefinitions}
      />
      {children}
    </ProfileAttributeDefinitionList>
  );
});

export default ProfileAttributeDefinitions;
