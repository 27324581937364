import React from "react";
import { noop } from "@util";

export interface UseRestoreUserSessionModel {
  userSessionRestorable?: boolean;
}

export interface UseRestoreUserSessionActions {
  restoreUserSession?: (restoreSessionFailedCallback?: () => void) => void;
  showRestoreSessionLoadingIndicator?: () => void;
  hideRestoreSessionLoadingIndicator?: () => void;
  restoreSessionFailed?: () => void;
}

type Model = UseRestoreUserSessionModel;
type Actions = UseRestoreUserSessionActions;
type Props = Model & Actions;

const useRestoreUserSession = (props: Props) => {

  const {
    userSessionRestorable,
    restoreUserSession = noop,
    showRestoreSessionLoadingIndicator = noop,
    hideRestoreSessionLoadingIndicator = noop,
    restoreSessionFailed = noop,
  } = props;

  const [restoringUserSession, setRestoringUserSession] = React.useState(false);

  const onRestoreSessionFailed = React.useCallback(() => {
    setRestoringUserSession(false);
    restoreSessionFailed();
  }, [setRestoringUserSession, restoreSessionFailed]);

  React.useEffect(() => {
    if (userSessionRestorable && !restoringUserSession) {
      restoreUserSession(onRestoreSessionFailed);
      setRestoringUserSession(true);
    }
  }, [
    userSessionRestorable,
    restoringUserSession,
    setRestoringUserSession,
    restoreUserSession,
    onRestoreSessionFailed,
  ]);

  React.useEffect(() => {
    if (restoringUserSession) {
      showRestoreSessionLoadingIndicator();
    } else {
      hideRestoreSessionLoadingIndicator();
    }
  }, [restoringUserSession, showRestoreSessionLoadingIndicator, hideRestoreSessionLoadingIndicator]);

  return restoringUserSession;
};

export default useRestoreUserSession;
