import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { UserProfileAttribute } from "@data";
import { noop } from "@util/Functions";
import {
  CancelButton,
  ContinueButton,
  EditUserProfileAttributes,
  EditUserProfileAttributesActions,
  EditUserProfileAttributesModel,
} from "@components";
import { styles } from "./styles";

export interface Model extends EditUserProfileAttributesModel {
  cancelButtonDisabled?: boolean;
  cancelButtonLabel?: string;
  saveButtonDisabled?: boolean;
  saveButtonLabel?: string;
  showModal?: boolean;
}

export interface Actions extends EditUserProfileAttributesActions {
  cancel?: () => void;
  save?: (items: UserProfileAttribute[]) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const EditProfileView = withStyles(styles)((props: Props) => {

  const {
    classes,
    cancelButtonDisabled,
    cancelButtonLabel,
    saveButtonDisabled,
    saveButtonLabel = "Save Changes",
    showModal,
    items = [],
    cancel = noop,
    save = noop,
    children,
    ...otherProps
  } = props;

  return (
    <React.Fragment>
      <div className={classnames("editProfileView", classes.container, classes.editMode)}>
        <div className={classnames("controls", classes.controls, classes.editModeControls)}>
          <CancelButton
            className={classnames("cancelButton", classes.cancelButton, classes.button)}
            disabled={cancelButtonDisabled}
            label={cancelButtonLabel}
            onClick={cancel}
          />
          <ContinueButton
            className={classnames("saveButton", classes.saveButton, classes.button)}
            disabled={saveButtonDisabled}
            label={saveButtonLabel}
            onClick={() => save(items)}
          />
        </div>
        <EditUserProfileAttributes
          {...otherProps}
          className={classnames("editUserProfileAttributes", classes.editUserProfileAttributes)}
          items={items}
        />
        {children}
      </div>
      {showModal && <div className={classnames("editModeModal", classes.editModeModal)} />}
    </React.Fragment>
  );
});

export default EditProfileView;
