import React from "react";
import { connect } from "react-redux";
import { EmailTemplate } from "@data";
import { AppSchema } from "@main/schemas";
import { equalsIgnoreCase, noop } from "@util";
import { getAccountId } from "@main/selectors";
import { setEmailTemplateId } from "../actions";
import { getEmailTemplateId, getUserId } from "../selectors";
import { useEmailTemplates } from "@hooks";
import SelectEmailTemplate, { Actions, Model } from "../components/SelectEmailTemplate";

interface ContainerModel extends Model {
  accountId?: string;
  emailTemplateId?: string;
  userId?: string;
}

interface ContainerActions extends Actions {
  setSelectedEmailTemplate?: (id: string) => void;
}

type Props = ContainerModel & ContainerActions;

const SelectEmailTemplateContainer = (props: Props) => {

  const { accountId = "", emailTemplateId = "", userId = "", setSelectedEmailTemplate = noop } = props;

  const [ model, actions ] = useEmailTemplates({accountId});

  const { emailTemplates = [] } = model;

  const selectedItems = React.useMemo(() =>
      emailTemplates.filter(emailTemplate =>
        equalsIgnoreCase(emailTemplate.getId(), emailTemplateId)),
    [emailTemplates, emailTemplateId]);

  const setSelectedItems = React.useCallback((selectedEmailTemplates: EmailTemplate[]) => {
    const emailTemplate = selectedEmailTemplates.pop() || EmailTemplate.EMPTY;
    setSelectedEmailTemplate(emailTemplate.getId());
  }, [setSelectedEmailTemplate]);

  const emailTemplateSelected = React.useMemo(() =>
    selectedItems.length > 0, [selectedItems]);

  const items = React.useMemo(() =>
      emailTemplateSelected ? selectedItems : emailTemplates,
    [emailTemplateSelected, selectedItems, emailTemplates]);

  const selectedEmailTemplate = React.useMemo(() =>
    selectedItems[0] || EmailTemplate.EMPTY, [selectedItems]);

  const htmlBody = React.useMemo(() =>
    selectedEmailTemplate.getHtmlBody(), [selectedEmailTemplate]);

  return (
    <SelectEmailTemplate
      {...model}
      {...actions}
      items={items}
      htmlBody={htmlBody}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      emailTemplateSelected={emailTemplateSelected}
      userId={userId}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  accountId: getAccountId(state),
  emailTemplateId: getEmailTemplateId(state),
  userId: getUserId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setSelectedEmailTemplate: (id: string) => dispatch(setEmailTemplateId(id)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(SelectEmailTemplateContainer);
