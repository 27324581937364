import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles";

export const propertyInfoLabel = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: 8,
      textAlign: "left",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      "&:first-child": {
        marginTop: 0,
      },
    },
    label: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
      marginTop: 8,
    },
    emphasis: {
      fontWeight: 500,
      marginRight: 4,
    },
  });

export const propertyInfo = (theme: Theme) =>
  createStyles({
    container: {
      flex: "1 0 auto",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginTop: 14,
    },
    emptyViewLabel: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
      marginTop: 8,
      marginBottom: 8,
      textAlign: "center",
      fontStyle: "italic",
    },
  });

export const styles = (theme: Theme) =>
  createStyles({
    content: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    subtitle: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 18,
    },
    label: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "18px",
      textAlign: "center",
      marginTop: 16,
      marginBottom: 16,
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
    propertyInfo: {
      flex: "1 0 auto",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginTop: 14,
    },
  });

export default styles;
