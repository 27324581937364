import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles, textField } from "./styles";
import TextField from "@components/text-field";
import { formEventHandler } from "@util";

const CommentsTextBox = withStyles(textField)(TextField);

export interface Model {
  approvalRequestId?: string;
  dataSetAlias?: string;
  isApprove?: boolean;
  comments?: string;
  commentsDisabled?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  setComments?: (comments: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const ApproveRequestView = withStyles(styles)((props: Props) => {

  const {
    classes,
    dataSetAlias,
    isApprove = true,
    comments,
    commentsDisabled,
    setComments,
    children,
  } = props;

  const action = isApprove ? "approve" : "reject";

  const formHelperTextProps = {
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  return (
    <div className={classnames("approveRequest", classes.container)}>
      <div className={classnames("approveRequest", classes.container)}>
        <label className={classnames("title", classes.title)}>
          Are you sure you want to {action} this request?
        </label>
        <label className={classnames("dataSetAlias", classes.dataSetAlias)}>
          {dataSetAlias}
        </label>
      </div>

      <div className={classnames("commentsTextField", classes.container)}>
        <CommentsTextBox
          className={classnames("comments", classes.inputField, classes.comments)}
          type="text"
          disabled={commentsDisabled}
          autoComplete="off"
          label="Comments"
          placeholder="Add Comments"
          name="comments"
          value={comments}
          minRows={3}
          maxRows={3}
          fullWidth={true}
          multiline={true}
          autoFocus={false}
          variant="outlined"
          margin="none"
          onChange={formEventHandler(setComments)}
          FormHelperTextProps={formHelperTextProps}
          InputLabelProps={inputLabelProps}
        />
        {children}
      </div>
    </div>
  );
});

export default ApproveRequestView;
