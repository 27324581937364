import React from "react";
import styles from "./styles";
import classnames from "classnames";
import { isEmptyString } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface NameAndDescriptionModel {
  className?: string;
  name?: string;
  conditions?: string;
  description?: string;
}

export interface NameAndDescriptionActions {
}

type Model = NameAndDescriptionModel;
type Actions = NameAndDescriptionActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const NameAndDescription = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    name = "",
    conditions = "",
    description = "",
    children,
  } = props;

  return (
    <div className={classnames("nameAndDescription", className, classes.container)}>
      <div className={classes.column}>
        <label>{name}</label>
        {!isEmptyString(description) && (
          <label className={classnames("description", classes.description)}>{description}</label>
        )}
        {!isEmptyString(conditions) && (
          <label className={classnames("conditions", classes.description)}>{conditions}</label>
        )}
      </div>
      {children}
    </div>
  );
});

export default NameAndDescription;
