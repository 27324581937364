import { Record } from "immutable";

export interface RequestCommentAttributes {
  message: string;
  timestamp: string;
  author: string;
}

export class RequestComment extends Record({
  message: "",
  timestamp: "",
  author: "",
}) implements RequestCommentAttributes {

  public static EMPTY: RequestComment = new RequestComment();

  public readonly message: string;
  public readonly timestamp: string;
  public readonly author: string;
}

export interface GetRequestCommentsResponse {
  comments: RequestCommentAttributes[];
  paging?: {
    next?: string;
  };
}

export default RequestComment;
