import React from "react";
import Color from "@Color";
import classnames from "classnames";
import { AppSchema } from "@main/schemas";
import { IdentityType, Vector } from "@data";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, clickMultiHandler, isEmptyString } from "@util";
import Button from "@components/button";
import CloseIcon from "@material-ui/icons/Close";
import { TooltipIcon } from "@components/tooltip";
import { clickHandler } from "@util/ReactEventUtils";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@components/icon-button";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import isFeedbackDialogEnabled from "@util/isFeedbackDialogEnabled";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface ErrorViewProps extends WithStyles<typeof styles> {
  className?: string;
  title?: string;
  message?: string;
  size?: "normal" | "large";
  showCloseIcon?: boolean;
  errors?: string[];
  onClickCloseIcon?: () => void;
  retry?: () => void;
  retryList?: (() => void)[];
  retryButtonLabel?: string;
  retryButtonClassName?: string;
  containsNewLine?: boolean;
  statusCode?: number;
}

const CUSTOMER_SUPPORT_LINK = "https://www.tracker.dtf.signify.com/browse/IOTCS";

export const ErrorView = withStyles(styles)((props: ErrorViewProps) => {

  const {
    classes,
    className,
    title = "Error",
    size = "normal",
    message = "",
    showCloseIcon,
    errors = [],
    onClickCloseIcon,
    retry,
    retryList = [],
    retryButtonLabel = "Retry",
    retryButtonClassName,
    containsNewLine = false,
    statusCode,
  } = props;

  const dispatch = useDispatch();
  const identityType = useSelector((state: AppSchema) => state.identityType);
  const principalId = useSelector((state: AppSchema) => state.principalId);
  const isUserPrincipal = !isEmptyString(principalId) && (identityType === IdentityType.USER);

  const showFeedbackButton = isFeedbackDialogEnabled() && isUserPrincipal;

  if (isEmptyString(message) || isEmptyString(title)) {
    return null;
  }

  return (
    <div
      className={classnames("errorView", className, classes.container, {
        [classes.large]: size === "large",
      })}
    >
      {showCloseIcon && (
        <IconButton
          color="inherit"
          disableRipple={true}
          className={classnames("closeIconButton", classes.closeIconButton)}
          onClick={clickHandler(onClickCloseIcon)}
        >
          <CloseIcon className={classnames("closeIcon", classes.closeIcon)} />
        </IconButton>
      )}
      {showFeedbackButton && (
        <div
          className={classnames("feedbackContainer",
            { [classes.feedbackLeft]: showCloseIcon },
            { [classes.feedbackRight]: !showCloseIcon },
          )}
        >
          <TooltipIcon
            className={classnames("feedbackButton", classes.feedbackButton)}
            useFab={true}
            color={Color.TEXT}
            size={Vector.square(16)}
            icon={FeedbackOutlinedIcon}
            style={{ width: 32, height: 32 }}
            tooltipText={"Submit Feedback"}
            onClick={() =>
              dispatch({
                type: ActionType.TOGGLE_FEEDBACK_DIALOG,
                value: true
              })
            }
          />
        </div>
      )}
      <div className={classnames("errorHeader", classes.errorHeader)}>
        <ErrorOutline className={classnames("icon", classes.icon)} color="inherit" />
        <label className={classnames("title", classes.title)}>{title}</label>
      </div>
      <label className={classnames("message", classes.message, {[classes.newLine]: containsNewLine})}>{message}</label>
      {statusCode && statusCode === 500 && (
        <Typography className={classnames("customerSupport", classes.message)} variant="body1">
          For further support, please consider creating a customer support ticket using the following link: {" "}
          <a className={classes.link} href={CUSTOMER_SUPPORT_LINK} target="_blank" rel="noopener noreferrer">
            {CUSTOMER_SUPPORT_LINK}
          </a>
        </Typography>
      )}
      {retry && (
        <Button
          className={classnames("tryAgainButton", retryButtonClassName, classes.errorButton)}
          classes={{
            label: classnames("label", classes.errorButtonLabel),
          }}
          variant="text"
          onClick={retryList.length === 0 ? clickHandler(retry) : clickMultiHandler(retryList)}
        >
          {retryButtonLabel}
        </Button>
      )}
      {errors.length > 0 && (
        <React.Fragment>
          <div className={classnames("separator", classes.separator)} />
          <div className={classnames("errorsContainer", classes.errorsContainer)}>
            <label className={classnames("errorListTitle", classes.errorListTitle)}>
              Errors:
            </label>
            {errors.map((error: string, index: number) => (
              <Typography key={error} className={classnames("error", classes.error)} variant="body1">
                <b>{`${index + 1}: `}</b>{error}
              </Typography>
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
});

export default ErrorView;
