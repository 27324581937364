import React from "react";
import useSelectedTab from "./useSelectedTab";
import QualityReportsTabs from "./components/QualityReportsTabs";

export function QualityReportsTabsView() {

  const selectedTab = useSelectedTab();

  return (
    <QualityReportsTabs selectedTab={selectedTab} />
  );
}

export default QualityReportsTabsView;
