import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { setUserId } from "../actions";
import { getUserId } from "../selectors";
import { useAccountUsers } from "@hooks";
import React from "react";
import { UsersIcon } from "@icons";
import { User } from "@data";
import UsersListColumn from "@components/users-list/UsersListColumn";
import {
    SortedSearchResultsListActions,
    SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import { noop } from "@util";
import { getCurrentAccountId } from "@main/selectors";
import ModuleListView from "@components/module-list-view";
import { getPathToUser } from "@modules/userDetails/helpers";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";

export const DEFAULT_USERS_LIST_COLUMNS: UsersListColumn[] = [
    UsersListColumn.NAME,
    UsersListColumn.STATUS
];

interface Model {
    userId?: string;
    accountId?: string;
}

interface Actions {
    setUserId?: (userId: string) => void;
}

const UsersList = (props: Model & Actions &
                       SortedSearchResultsListModel<User, UsersListColumn> &
                       SortedSearchResultsListActions<User, UsersListColumn>) => {

    const {
        items = [],
        className = "users",
        listClassName = "usersList",
        searchViewHint = "Filter by email address",
        columns = DEFAULT_USERS_LIST_COLUMNS,
        sortByColumn = UsersListColumn.NAME,
        sortOrderAscending = true,
        noResultsLabel = "No users found",
        summaryViewQuantities = {
            other: "users",
            one: "user",
        },
        nameFilterDelay = 250,
        showSearch,
        setUserId: dispatchSetUserId = noop,
        ...otherProps
    } = props;

    const [selectedItems, setSelectedItems] = React.useState<User[]>([]);

    const moduleListItems: ListViewItem<User>[] = React.useMemo(() =>
      items.map(item => {
        return {
          item: item,
          pathToDetailsView: getPathToUser(item.getUserId()),
          icon: UsersIcon,
          columnAttributes: createColumns([
            {
              className: "userId",
              value: item.getUserId(),
              column: UsersListColumn.NAME,
              firstColumn: true,
            },
            {
              className: "userStatus",
              value: item.getStatus(),
              column: UsersListColumn.STATUS,
            },
          ])
        };
      }), [items]);

    return (
        <ModuleListView
            {...otherProps}
            className={className}
            listViewItems={moduleListItems}
            listClassName={listClassName}
            searchViewHint={searchViewHint}
            columns={columns}
            nameFilterDelay={nameFilterDelay}
            sortByColumn={sortByColumn}
            sortOrderAscending={sortOrderAscending}
            noResultsLabel={noResultsLabel}
            summaryViewIcon={UsersIcon}
            summaryViewQuantities={summaryViewQuantities}
            selectAllDisabled={true}
            selectable={true}
            showSearch={true}
            selectedItems={selectedItems}
            setSelectedItems={(selectedUsers: User[]) => {
                const userIds = selectedUsers.map((user) =>
                    "lrn:iot:aam::" + props.accountId + ":principal:user:" + user.userId).join(", ");
                dispatchSetUserId(userIds);
                setSelectedItems(selectedUsers);
            }}
        />
    );
};

const mapStateToProps = (state: AppSchema): Model => ({
    userId: getUserId(state),
    accountId: getCurrentAccountId(state)
});

const UsersContainer = (props: any) => {

  const [model, actions] = useAccountUsers();

  const { users } = model;

  return (
      <React.Fragment>
        <UsersList
            {...model}
            {...actions}
            {...props}
            items={users}
        />
      </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: any): Actions => ({
  setUserId: (userId: string) => dispatch(setUserId(userId)),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(UsersContainer);
