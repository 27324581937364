import React from "react";
import { isEmptyString } from "@util";
import { MODULE_PATH } from "../constants";
import { getPathToDataSetRequest } from "../helpers";
import { Redirect, useRouteMatch } from "react-router-dom";
import TermsConditionsPdfViewer, {
  TermsConditionsPdfViewerActions as Actions,
  TermsConditionsPdfViewerModel as Model,
} from "@components/terms-conditions-pdf-viewer";

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const TermsConditionsView = (props: Props) => {

  const {
    className = "termsConditionsView",
    termsConditionsId = "",
    ...otherProps
  } = props;

  const match = useRouteMatch<{ id: string }>(MODULE_PATH);

  if (isEmptyString(termsConditionsId)) {
    if (match && match.params && match.params.id) {
      return <Redirect to={getPathToDataSetRequest(match.params.id)} />;
    } else {
      return <Redirect to={MODULE_PATH} />;
    }
  }

  return (
    <TermsConditionsPdfViewer
      {...otherProps}
      className={className}
      termsConditionsId={termsConditionsId}
    />
  );
};

export default TermsConditionsView;
