import { Module } from "@data";
import { createModule } from "@modules/base";
import { initialize } from "./actions/editMode";
import { isDeviceManagementEnabled } from "@util";
import { reset } from "./actions/deviceTypeWizard";
import { isAccessDeniedVisible, isEmptyViewVisible } from "./selectors";
import { getCreateDeviceTypePath, getEditDeviceTypePath } from "./helpers";
import { primaryColor, PrimaryIcon as icon } from "../deviceTypes/components/styles";
import CreateDeviceTypeView from "./CreateDeviceTypeView";
import EditDeviceTypeView from "./EditDeviceTypeView";
import BackButton from "./containers/BackButton";

type RouteParams = { id?: string; };

export const createDeviceTypeWizardModule: Module = createModule({
  id: "create_device_type",
  path: getCreateDeviceTypePath(),
  exactPath: true,
  strictPath: true,
  name: "Create Device Type Wizard",
  title: "Create New Device Type Wizard",
  className: "createDeviceTypeWizardModule",
  primaryColor,
  icon,
  BackButton,
  view: CreateDeviceTypeView,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: reset,
  disabled: !isDeviceManagementEnabled(),
});

export const editDeviceTypeWizardModule: Module = createModule<RouteParams>({
  id: "edit_device_type",
  path: getEditDeviceTypePath(":id"),
  exactPath: true,
  strictPath: true,
  name: "Edit Device Type Wizard",
  title: "Edit Device Type Wizard",
  className: "editDeviceTypeWizardModule",
  primaryColor,
  icon,
  BackButton,
  view: EditDeviceTypeView,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  skipInitializeOnRouteParamsChange: true,
  onMount: ({ id: nameAndVersion }: RouteParams = {}) => initialize(nameAndVersion),
  disabled: !isDeviceManagementEnabled(),
});

export default createDeviceTypeWizardModule;
