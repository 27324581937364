import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { Model, EditorDialog } from "../../components/EditorDialog";
import DeviceDataValidationView from "./DeviceDataValidationView";
import { DataAttributes } from "@data";
import { ConfirmationDialogModel, ConfirmationDialogActions } from "@components";
import { DeviceEnrollmentWizardSelectors } from "../../selectors";
import { AddDeviceWizardActions } from "../../actions";

type ContainerModel = Model & ConfirmationDialogModel<DataAttributes>;
type ContainerActions = ConfirmationDialogActions<DataAttributes>;

const DataValidationDialogContainer = (props: ContainerModel & ContainerActions) => {

  const { open: open = false } = props;

  if (!open) {
    return null;
  }

  return (
    <EditorDialog{...props} >
      <DeviceDataValidationView/>
    </EditorDialog>
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
  title: DeviceEnrollmentWizardSelectors.getDeviceDataDialogTitle(state),
  className: "dataValidationDialog",
  continueButtonLabel: DeviceEnrollmentWizardSelectors.getDeviceDataDialogContinueButtonLabel(state),
  continueButtonDisabled: !DeviceEnrollmentWizardSelectors.isDeviceDataValidated(state),
  item: DeviceEnrollmentWizardSelectors.getDataValidationItem(state),
  open: DeviceEnrollmentWizardSelectors.isAddDeviceDialogVisible(state),
  maxWidth: "md",
});

const mapDispatchToProps = (dispatch: any): ContainerActions => ({
  cancel: () => dispatch(AddDeviceWizardActions.closeDeviceDataDialog()),
  confirm: (data: DataAttributes) => dispatch(AddDeviceWizardActions.updateSchemaData(data)),
});

export default connect<Model, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps
)(DataValidationDialogContainer);
