import { Record } from "immutable";
import { getStringValue } from "@util";
import { AccessRequestType, AccessRequestPrincipalType } from "@data/DataAccessRequest";

export interface RevokeDataAccessRequestAttributes {
  accessPrincipalType: AccessRequestPrincipalType;
  accessRequestType: AccessRequestType;
  principalIdentifier: string;
}

export class RevokeDataAccessRequest extends Record({
  accessPrincipalType: AccessRequestPrincipalType.IOT_USER_PRINCIPAL,
  accessRequestType: AccessRequestType.NONE,
  principalIdentifier: "",
}) implements RevokeDataAccessRequestAttributes {

  public static EMPTY: RevokeDataAccessRequest = new RevokeDataAccessRequest();

  public readonly accessPrincipalType: AccessRequestPrincipalType;
  public readonly accessRequestType: AccessRequestType;
  public readonly principalIdentifier: string;

  public getAccessRequestPrincipalType(): AccessRequestPrincipalType {
    return this.accessPrincipalType;
  }

  public getAccessRequestType(): AccessRequestType {
    return this.accessRequestType;
  }

  public getServicePrincipalIdentifier(): string {
    return getStringValue(this.principalIdentifier);
  }
}

export default RevokeDataAccessRequest;
