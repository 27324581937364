import React from "react";
import {
  usePaginatedApiRequest,
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
  useDataLakeIndexClient
} from "@hooks";
import { GetDataLakeIndexResponse } from "@network";
import { DataLakeIndex, DataLakeIndexQuery } from "@data";

type SuccessResponse = GetDataLakeIndexResponse;

export interface UseDataLakeQueryResultsProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
  dataSetAlias: string;
  startDate: string;
  endDate?: string;
  accountId?: string;
  fileNameContains?: string;
  nextPageToken?: string;
  queryResults?: DataLakeIndex[];
}

export interface UseDataLakeQueryResultsModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  queryParameters: DataLakeIndexQuery;
  queryResults: DataLakeIndex[];
}

export interface UseDataLakeQueryResultsActions extends UsePaginatedApiRequestActions<SuccessResponse> {
  loadQueryResults: () => void;
  loadMore: () => void;
}

type Props = UseDataLakeQueryResultsProps;
type Model = UseDataLakeQueryResultsModel;
type Actions = UseDataLakeQueryResultsActions;
type Result = [Model, Actions];

export const useDataLakeQueryResults = (props: Props): Result => {

  const {
    dataSetAlias,
    startDate,
    endDate = "",
    accountId = "",
    fileNameContains = "",
    defaultErrorMessage = "Failed to get Data lake query results",
    queryResults: initialQueryResults = [],
    ...otherProps
  } = props;

  const [queryResults, setQueryResults] = React.useState<DataLakeIndex[]>(initialQueryResults);

  const DataLakeIndexClient = useDataLakeIndexClient();

  const makeApiRequest = React.useCallback((accessToken: string, nextPage: string = "") =>
      DataLakeIndexClient.getDataLakeQueryResults(
        dataSetAlias, startDate, endDate, accountId, fileNameContains, nextPage),
    [DataLakeIndexClient, dataSetAlias, startDate, endDate, accountId, fileNameContains]);

  const [{ nextPageToken, successResponse, ...baseModel }, { reset: baseReset, refresh: baseRefresh, ...baseActions }] =
    usePaginatedApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const queryParameters = React.useMemo(() => {
    const { query = {} } = successResponse || {};
    return new DataLakeIndexQuery(query);
  }, [successResponse]);

  const updatedQueryResults = React.useMemo<DataLakeIndex[]>(() => {
    const { results = [] } = successResponse || {};
    return results.map(attrs => new DataLakeIndex(attrs));
  }, [successResponse]);

  const reset = React.useCallback(() => {
    setQueryResults([]);
    baseReset();
  }, [baseReset, setQueryResults]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [baseRefresh, reset]);

  React.useEffect(() => {
    setQueryResults(currentQueryResults => currentQueryResults.concat(updatedQueryResults));
  }, [updatedQueryResults]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    nextPageToken,
    successResponse,
    queryParameters,
    queryResults,
  }), [
    baseModel,
    nextPageToken,
    successResponse,
    queryParameters,
    queryResults,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
    loadQueryResults: refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDataLakeQueryResults;
