import React from "react";
import { BroadcastChannelCallback, noop, openBroadcastChannel } from "@util";
import {
  BROADCAST_ACTION_PING,
  BROADCAST_CHANNEL_SERVICE_RESTORE_SESSION,
} from "@store/constants";

export interface UseKeepServiceSessionAliveModel {
  loggedIn?: boolean;
  servicePrincipalActive?: boolean;
  accessToken?: string;
}

export interface UseKeepServiceSessionAliveActions {
}

type Model = UseKeepServiceSessionAliveModel;
type Actions = UseKeepServiceSessionAliveActions;
type Props = Model & Actions;

const useKeepServiceSessionAlive = (props: Props) => {

  const {
    loggedIn,
    servicePrincipalActive,
    accessToken = "",
  } = props;

  React.useEffect(() => {

    if (!loggedIn || !servicePrincipalActive) {
      return noop;
    }

    const listener: BroadcastChannelCallback = (broadcastEvent: MessageEvent, client) => {
      if (broadcastEvent.data === BROADCAST_ACTION_PING) {
        client.postMessage(accessToken);
      }
    };

    const {
      postMessage,
      unsubscribe,
    } = openBroadcastChannel(BROADCAST_CHANNEL_SERVICE_RESTORE_SESSION, listener);

    postMessage(accessToken);

    return () => {
      unsubscribe();
    };

  }, [loggedIn, servicePrincipalActive, accessToken]);

  return !loggedIn && !servicePrincipalActive;
};

export default useKeepServiceSessionAlive;
