import React from "react";
import { useParams, useHistory } from "react-router-dom";
import ApplicationDetails from "./containers/ApplicationDetails";
import { getEditApplicationPath } from "@modules/applicationWizardRegional/helpers";

export function ApplicationDetailsView() {

  const history = useHistory();

  const { id: applicationId } = useParams<{ id: string }>();

  const editApplication = React.useCallback(() =>
      history.push(getEditApplicationPath(applicationId), { goBackOnSuccess: true}),
    [history, applicationId]);

  return (
    <ApplicationDetails
      editApplication={editApplication}
    />
  );
}

export default ApplicationDetailsView;
