import React from "react";
import styles from "./styles";
import DefaultIcon from "@material-ui/icons/MenuBook";
import HotFixReleaseIcon from "@material-ui/icons/Build";
import PatchReleaseIcon from "@material-ui/icons/BugReport";
import MajorReleaseIcon from "@material-ui/icons/NewReleases";
import { ReleaseNoteReleaseType } from "@data/ReleaseNote";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface ReleaseNoteIconModel {
  className?: string;
  releaseType?: ReleaseNoteReleaseType;
}

export interface ReleaseNoteIconActions {
}

type Model = ReleaseNoteIconModel;
type Actions = ReleaseNoteIconActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const ReleaseNoteIcon = withStyles(styles)((props: Props) => {

  const { className, releaseType } = props;

  const icon = React.useMemo(() => {
    switch (releaseType) {
      case ReleaseNoteReleaseType.MAJOR:
        return <MajorReleaseIcon className={className} />;
      case ReleaseNoteReleaseType.PATCH:
        return <PatchReleaseIcon className={className} />;
      case ReleaseNoteReleaseType.HOTFIX:
        return <HotFixReleaseIcon className={className} />;
      case ReleaseNoteReleaseType.MINOR:
      default:
        return <DefaultIcon className={className} />;
    }
  }, [releaseType, className]);

  return icon;
});

export default ReleaseNoteIcon;
