import { Module } from "@data";
import { initialize } from "./actions";
import { isDeviceManagementEnabled } from "@util";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { isAccessDeniedVisible, isEmptyViewVisible } from "./selectors";
import view from "./DeviceEnrollmentView";

export const deviceEnrollmentModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Device Enrollment",
  title: "Device Enrollment",
  className: "deviceEnrollmentModule",
  primaryColor,
  icon,
  view,
  backButton: null,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  initialize,
  disabled: !isDeviceManagementEnabled(),
});

export default deviceEnrollmentModule;
