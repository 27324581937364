import { isEmptyString } from "@util";
import { AppSchema } from "@main/schemas";
import { createActions } from "@modules/base";
import { refresh as refreshDataCatalogModule } from "@modules/dataCatalog/actions";
import { getAccessToken, getAccountId, getDataSetAlias } from "./selectors";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { DataSetRequestClient, RestClientError } from "@network";

export const {
  accountId: setAccountId,
  dataSetAlias: setDataSetAlias,
  open: setOpen,
  setErrorMessage,
  setSuccessMessage,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  DELETE_DATA_SET_REQUEST_REQUEST: deleteDataSetRequestRequest,
  DELETE_DATA_SET_REQUEST_SUCCESS: deleteDataSetRequestSuccess,
  DELETE_DATA_SET_REQUEST_FAILED: deleteDataSetRequestFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setAccountId());
  dispatch(setDataSetAlias());
  dispatch(setOpen());
  return dispatch(baseReset());
};

export const deleteDataSetRequest = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accountId = getAccountId(state);
  const dataSetAlias = getDataSetAlias(state);
  const accessToken = getAccessToken(state);

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(deleteDataSetRequestRequest());

  return DataSetRequestClient.deleteDataSetRequest(accessToken, accountId, dataSetAlias)
    .then(() => {

      dispatch(deleteDataSetRequestSuccess());
      dispatch(refreshDataCatalogModule());
      dispatch(hideLoadingIndicator());
      return dispatch(setSuccessMessage("Data Set Request cancelled"));

    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to cancel data set request" } = response;

      dispatch(deleteDataSetRequestFailed(analytic));
      dispatch(hideLoadingIndicator());

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(setErrorMessage(error));
    });
};

export const open = (accountId: string, dataSetAlias: string) => (dispatch: any) => {

  if (isEmptyString(accountId) || isEmptyString(dataSetAlias)) {
    return Promise.resolve();
  }

  dispatch(reset());
  dispatch(setAccountId(accountId));
  dispatch(setDataSetAlias(dataSetAlias));
  return dispatch(setOpen(true));
};

export const close = reset;

export const initialize = reset;
