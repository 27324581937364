import { Policy } from "@data";
import { PoliciesListColumn } from "./PoliciesListColumn";
import { Comparator, compare, isEmptyString } from "@util";

export const getSearchResults = (items: Policy[], searchQuery: string) => {

  const policies = items.slice();

  if (policies.length === 0 || isEmptyString(searchQuery)) {
    return policies;
  }

  return policies.filter((policy: Policy) =>
    policy.getName().indexOf(searchQuery) >= 0 ||
    policy.getPath().indexOf(searchQuery) >= 0 ||
    policy.getVersion().indexOf(searchQuery) >= 0 ||
    policy.getDescription().indexOf(searchQuery) >= 0);
};

const compareByName = (left: Policy, right: Policy, ascending: boolean) =>
  compare(left.getName(), right.getName(), ascending);

const compareByPath = (left: Policy, right: Policy, ascending: boolean) =>
  compare(left.getPath(), right.getPath(), ascending);

const compareByVersion = (left: Policy, right: Policy, ascending: boolean) =>
  compare(left.getVersion(), right.getVersion(), ascending);

const compareByDescription = (left: Policy, right: Policy, ascending: boolean) =>
  compare(left.getDescription(), right.getDescription(), ascending);

export const createComparator =
  (sortedColumn: PoliciesListColumn, ascending: boolean): Comparator<Policy> =>
    (left: Policy, right: Policy) => {
      switch (sortedColumn) {
        case PoliciesListColumn.NAME:
          return compareByName(left, right, ascending);
        case PoliciesListColumn.PATH:
          return compareByPath(left, right, ascending);
        case PoliciesListColumn.VERSION:
          return compareByVersion(left, right, ascending);
        case PoliciesListColumn.DESCRIPTION:
          return compareByDescription(left, right, ascending);
        default:
          return 0;
      }
    };
