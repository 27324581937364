import { createSelector } from "reselect";
import { isEmptyString } from "@util";
import { createSelectors, Selector } from "../base/createSelectors";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";
import { LogoutUserSchema, DEFAULT_STATE } from "./reducers";

export const {
  open: isDialogOpen,
  userId: getUserId,
  errorMessage: getErrorMessage,
  successMessage: getSuccessMessage,
  showEmptyView: isEmptyViewVisible,
  showAccessDenied: isAccessDeniedVisible,
  showLoadingIndicator: isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
} = createSelectors<LogoutUserSchema>(moduleId, schemaKey, DEFAULT_STATE);

export const isUserIdValid: Selector<boolean> = createSelector(
  getUserId, userId => !isEmptyString(userId));
