import { createSelector } from "reselect";
import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { createSelectors, Selector } from "@base";
import {
  DEFAULT_STATE,
  DeviceEnrollmentSchema,
  DeviceEnrollmentItems,
} from "./reducers";
import { DeviceEnrollmentStatus, DeviceEnrollmentStatusAttributes } from "@data";
import { OrderedMap } from "immutable";

export const {
  deviceEnrollments: getDeviceEnrollmentsAttributes,
  batchId: getBatchId,
  errorMessage: getErrorMessage,
  successMessage: getSuccessMessage,
  showEmptyView: isEmptyViewVisible,
  showAccessDenied: isAccessDeniedVisible,
  showLoadingIndicator: isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
} = createSelectors<DeviceEnrollmentSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getDeviceEnrollmentsMap: Selector<OrderedMap<string, DeviceEnrollmentStatus>> = createSelector(
  getDeviceEnrollmentsAttributes, (deviceEnrollments: DeviceEnrollmentItems) =>
    OrderedMap<string, DeviceEnrollmentStatusAttributes>(deviceEnrollments)
    .map((attrs: DeviceEnrollmentStatusAttributes) => new DeviceEnrollmentStatus(attrs))
    .toOrderedMap()
);

export const getDeviceEnrollments: Selector<DeviceEnrollmentStatus[]> = createSelector(
  getDeviceEnrollmentsMap, (deviceEnrollments: OrderedMap<string, DeviceEnrollmentStatus>) =>
    deviceEnrollments.toArray());

export const getResult: Selector<DeviceEnrollmentStatus[]> = createSelector(
  [getDeviceEnrollments, getBatchId],
  (deviceEnrollments: DeviceEnrollmentStatus[], batchId: string = "") => {
    if (batchId.length === 0) {
      return [];
    } else {
      return deviceEnrollments;
    }
  }
);

export const getNumResult: Selector<number> = createSelector(
  getResult, (items: DeviceEnrollmentStatus[]) => items.length);
