import { User } from "@data";
import { Primitive } from "@util";
import { UserProfileAttribute } from "@data";
import { UserProfileAttributesListColumn } from "./UserProfileAttributesListColumn";

export const filterResultsByNameFilter = (item: UserProfileAttribute,
                                          nameFilter: string) =>
  item.isNameFilterMatch(nameFilter);

export const mapItemToColumnValue = (item: UserProfileAttribute,
                                     column: UserProfileAttributesListColumn): Primitive => {

  switch (column) {
    case UserProfileAttributesListColumn.NAME:
      return item.getName();
    case UserProfileAttributesListColumn.VALUE:
      return item.getValueAsString();
    case UserProfileAttributesListColumn.DATA_TYPE:
      return item.getDataType();
    default:
      return "";
  }
};

export const mapUserToUserProfileAttribute =
  (user: User): UserProfileAttribute[] => {

    const schema = user.getSchema();

    return Object.keys(schema).map((name: string) => new UserProfileAttribute({
      name,
      dataType: schema[name],
      value: user.getProfileAttribute(name),
    }));
  };
