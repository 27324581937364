import React from "react";
import { WorkloadCodePackagingStatus } from "@data";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  UseApiRequestActions,
  useWorkloadManagerClient,
} from "@hooks";
import { GetWorkloadCodePackagingStatusResponse } from "@network";

type SuccessResponse = GetWorkloadCodePackagingStatusResponse;

export interface UseGetWorkloadCodePackagingStatusProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
}

export interface UseGetWorkloadCodePackagingStatusModel extends UseApiRequestModel<SuccessResponse> {
  status?: WorkloadCodePackagingStatus;
}

export interface UseGetWorkloadCodePackagingStatusActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetWorkloadCodePackagingStatusProps;
type Model = UseGetWorkloadCodePackagingStatusModel;
type Actions = UseGetWorkloadCodePackagingStatusActions;
type Result = [Model, Actions];

export const useGetWorkloadCodePackagingStatus = (props: Props): Result => {

  const {
    name,
    version,
    defaultErrorMessage = "Failed to get workload code packaging status",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() => WorkloadManagerClient.getWorkloadCodePackagingStatusApi(name, version),
    [WorkloadManagerClient, name, version]);

  const [{ successResponse, errorMessage: baseErrorMessage, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const status = React.useMemo<WorkloadCodePackagingStatus>(
    () => successResponse ? successResponse.packagingStatus : WorkloadCodePackagingStatus.NOT_AVAILABLE
  , [successResponse]);

  const errorMessage = React.useMemo<string>(() => successResponse ? successResponse.errorMessage
    ? successResponse.errorMessage : baseErrorMessage : baseErrorMessage, [successResponse, baseErrorMessage]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    status,
    errorMessage,
  }), [
    baseModel,
    status,
    errorMessage,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetWorkloadCodePackagingStatus;
