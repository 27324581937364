import React from "react";
import { noop } from "@util";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { inviteUser, setCurrentStep, showNextStep, showPreviousStep } from "../actions";
import { NewUserWizardStep as Step, NewUserWizardStep } from "../reducers";
import {
  getDisabledSteps,
  getCompletedSteps,
  getCurrentStep,
  getErrorMessage,
  getInviteUserState,
  getSteps,
  getSuccessMessage,
  getUserId,
  isLoadingIndicatorVisible,
  isSaveButtonEnabled,
  isSuccessMessageVisible,
  isReviewViewActive,
} from "../selectors";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
} from "@components/submit-api-request-view";
import EditProfileAttributes from "./EditProfileAttributes";
import SelectApplication from "./SelectApplication";
import SelectEmailTemplate from "./SelectEmailTemplate";
import InviteUserState from "../models/InviteUserState";
import ReviewView from "./ReviewView";

export const WIZARD_STEP_LABELS = {
  [Step.NONE]: null,
  [Step.USER_INFO]: "Enter User Info",
  [Step.APPLICATION]: "Select Application",
  [Step.EMAIL_TEMPLATE]: "Select Email Template (Optional)",
  [Step.REVIEW]: "Review Invitation",
};

interface ContainerModel extends SubmitApiRequestViewModel<InviteUserState, NewUserWizardStep> {
  userId?: string;
}

interface ContainerActions extends SubmitApiRequestViewActions<InviteUserState, NewUserWizardStep> {
  showUserDetails?: (userId: string) => void;
}

type Props = ContainerModel & ContainerActions & {
  children?: React.ReactNode;
};

const InviteUserContainer = (props: Props) => {

  const {
    className = "inviteUser",
    snackbarId = "invite-user",
    errorTitle = "Invite User Failed",
    saveButtonLabel = "Invite User",
    currentState = InviteUserState.EMPTY,
    defaultState = InviteUserState.EMPTY,
    userId = "",
    mapStepToLabel = (step: Step) => WIZARD_STEP_LABELS[step] || null,
    showUserDetails = noop,
    children,
    ...otherProps
  } = props;

  const onSuccess = React.useCallback(() =>
    showUserDetails(userId), [showUserDetails, userId]);

  return (
    <SubmitApiRequestView
      {...otherProps}
      className={className}
      snackbarId={snackbarId}
      errorTitle={errorTitle}
      saveButtonLabel={saveButtonLabel}
      currentState={currentState}
      defaultState={defaultState}
      mapStepToLabel={mapStepToLabel}
      onSuccess={onSuccess}
    >
      <EditProfileAttributes />
      <SelectApplication />
      <SelectEmailTemplate />
      <ReviewView />
      {children}
    </SubmitApiRequestView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  userId: getUserId(state),
  currentState: getInviteUserState(state),
  successMessage: getSuccessMessage(state),
  errorMessage: getErrorMessage(state),
  showLoadingIndicator: isLoadingIndicatorVisible(state),
  showSuccessIndicator: isSuccessMessageVisible(state),
  saveButtonDisabled: !isSaveButtonEnabled(state),
  steps: getSteps(state),
  currentStep: getCurrentStep(state),
  completedSteps: getCompletedSteps(state),
  disabledSteps: getDisabledSteps(state),
  showSaveButton: isReviewViewActive(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  previousStep: () => dispatch(showPreviousStep()),
  nextStep: () => dispatch(showNextStep()),
  save: () => dispatch(inviteUser()),
  setCurrentStep: (step: Step) => dispatch(setCurrentStep(step)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(InviteUserContainer);
