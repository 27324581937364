import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    title: {
      fontSize: 18,
      textAlign: "center",
      fontWeight: 400,
      padding: "15px 10px",
      lineHeight: "1",
      color: Color.TEXT,
      backgroundColor: Color.LIGHT_GREY6,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      border: `1px solid ${Color.GREY1}`,
    },
    emptyView: {
      flex: "1 0 auto",
      marginTop: 6,
      color: Color.GREY7,
      fontSize: 14,
      fontWeight: 400,
      textAlign: "center",
    },
    content: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      padding: "15px 30px",
      borderLeft: `1px solid ${Color.GREY1}`,
      borderRight: `1px solid ${Color.GREY1}`,
      borderBottom: `1px solid ${Color.GREY1}`,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    },
    list: {
      display: "flex",
      flexFlow: "column nowrap",
      margin: 0,
      padding: 0,
      listStyleType: "disc",
      marginLeft: 8,
      "&:first-child": {
        marginLeft: 0,
      },
    },
    listItem: {
    },
    license: {
      fontSize: 12,
      lineHeight: "2",
      fontWeight: 300,
    },
  });

export default styles;
