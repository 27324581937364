import { combineReducers } from "redux";
import { Actions, filterAction, Identity } from "../../util";
import { JsonSchemaMetadata, JsonSchemaMetadataAttributes } from "../../data";

export enum SchemaAction {
  NONE = "",
  EDIT = "edit_schema",
  DRAFT = "draft_schema",
  PROMOTE = "promote_schema",
  DEPRECATE = "deprecate_schema",
  DECOMMISSION = "decommission_schema",
  DELETE = "delete_schema",
}

export interface SchemaStateManagerSchema {
  showDialog: boolean;
  schema: JsonSchemaMetadataAttributes;
  schemaAction: SchemaAction;
  errorMessage: string;
  successMessage: string;
  showAccessDenied: boolean;
  showProgressIndicator: boolean;
}

export const DEFAULT_STATE: SchemaStateManagerSchema = Object.freeze({
  showDialog: false,
  schema: Object.freeze(JsonSchemaMetadata.EMPTY.toJS()),
  schemaAction: SchemaAction.NONE,
  errorMessage: "",
  successMessage: "",
  showAccessDenied: false,
  showProgressIndicator: false,
});

export enum SchemaStateManagerActionType {
  SET_SHOW_DIALOG = "schema_state_manager/set_show_dialog",
  SET_SCHEMA = "schema_state_manager/set_schema",
  SET_SCHEMA_ACTION = "schema_state_manager/set_schema_action",
  SET_ERROR_MESSAGE = "schema_state_manager/set_error_message",
  SET_SUCCESS_MESSAGE = "schema_state_manager/set_success_message",
  SET_SHOW_ACCESS_DENIED = "schema_state_manager/set_show_access_denied",
  SET_SHOW_PROGRESS_INDICATOR = "schema_state_manager/set_show_progress_indicator",
  DELETE_SCHEMA_REQUEST = "schema_state_manager/delete_schema_request",
  DELETE_SCHEMA_REQUEST_SUCCESS = "schema_state_manager/delete_schema_request_success",
  DELETE_SCHEMA_REQUEST_FAILED = "schema_state_manager/delete_schema_request_failed",
  PROMOTE_SCHEMA_REQUEST = "schema_state_manager/promote_schema_request",
  PROMOTE_SCHEMA_REQUEST_SUCCESS = "schema_state_manager/promote_schema_request_success",
  PROMOTE_SCHEMA_REQUEST_FAILED = "schema_state_manager/promote_schema_request_failed",
  DRAFT_NEW_SCHEMA_VERSION_REQUEST = "schema_state_manager/draft_new_schema_version_request",
  DRAFT_NEW_SCHEMA_VERSION_SUCCESS = "schema_state_manager/draft_new_schema_version_success",
  DRAFT_NEW_SCHEMA_VERSION_FAILED = "schema_state_manager/draft_new_schema_version_failed",
  DEPRECATE_SCHEMA_REQUEST = "schema_state_manager/deprecate_schema_request",
  DEPRECATE_SCHEMA_REQUEST_SUCCESS = "schema_state_manager/deprecate_schema_request_success",
  DEPRECATE_SCHEMA_REQUEST_FAILED = "schema_state_manager/deprecate_schema_request_failed",
  DECOMMISSION_SCHEMA_REQUEST = "schema_state_manager/decommission_schema_request",
  DECOMMISSION_SCHEMA_REQUEST_SUCCESS = "schema_state_manager/decommission_schema_request_success",
  DECOMMISSION_SCHEMA_REQUEST_FAILED = "schema_state_manager/decommission_schema_request_failed",
}

const showDialog = filterAction<boolean>(Actions(
  SchemaStateManagerActionType.SET_SHOW_DIALOG))(Identity,
  DEFAULT_STATE.showDialog);

const schema = filterAction<JsonSchemaMetadataAttributes>(Actions(
  SchemaStateManagerActionType.SET_SCHEMA))(Identity,
  DEFAULT_STATE.schema);

const schemaAction = filterAction<SchemaAction>(Actions(
  SchemaStateManagerActionType.SET_SCHEMA_ACTION))(Identity,
  DEFAULT_STATE.schemaAction);

const errorMessage = filterAction<string>(Actions(
  SchemaStateManagerActionType.SET_ERROR_MESSAGE))(Identity,
  DEFAULT_STATE.errorMessage);

const successMessage = filterAction<string>(Actions(
  SchemaStateManagerActionType.SET_SUCCESS_MESSAGE))(Identity,
  DEFAULT_STATE.successMessage);

const showAccessDenied = filterAction<boolean>(Actions(
  SchemaStateManagerActionType.SET_SHOW_ACCESS_DENIED))(Identity,
  DEFAULT_STATE.showAccessDenied);

const showProgressIndicator = filterAction<boolean>(Actions(
  SchemaStateManagerActionType.SET_SHOW_PROGRESS_INDICATOR))(Identity,
  DEFAULT_STATE.showProgressIndicator);

export const SchemaStateManagerReducer = combineReducers<SchemaStateManagerSchema>({
  showDialog,
  schema,
  schemaAction,
  errorMessage,
  successMessage,
  showAccessDenied,
  showProgressIndicator,
});

export default SchemaStateManagerReducer;
