import React from "react";
import classnames from "classnames";
import { clickHandler, formatAsISO8601DateTimeString, noop } from "@util";
import Dialog from "@material-ui/core/Dialog";
import { IconButton, SaveButton, SummaryViewLabel } from "@components";
import CloseIcon from "@material-ui/icons/Close";
import DataLakeFile from "../models/DataLakeFile";
import DialogTitle from "@material-ui/core/DialogTitle";
import DataLakeFileMetadata from "../DataLakeFileMetadata";
import DownloadDataLakeFileButton from "../DownloadDataLakeFileButton";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface FileDetailsDialogModel {
  className?: string;
  dataSetAlias?: string;
  accountId?: string;
  dataLakeFile?: DataLakeFile;
  open?: boolean;
  fullHeight?: boolean;
  customActionLabel?: string;
}

export interface FileDetailsDialogActions {
  closeDialog?: () => void;
  customAction?: (file: string) => void;
}

type Model = FileDetailsDialogModel;
type Actions = FileDetailsDialogActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const FileDetailsDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    dataSetAlias = "",
    accountId = "",
    dataLakeFile = DataLakeFile.EMPTY,
    open = false,
    fullHeight = true,
    closeDialog = noop,
    customActionLabel,
    customAction,
    children,
  } = props;

  return (
    <Dialog
      className={classnames("fileDetailsDialog", className, classes.container)}
      classes={{
        container: classnames("fileDetailsDialogContainer", classes.fileDetailsDialogContainer),
        scrollPaper: classnames("fileDetailsDialogPresentation", classes.fileDetailsDialogPresentation, {
          [classes.fullHeight]: fullHeight,
        }),
        paper: classnames("fileDetailsDialogPaper", classes.fileDetailsDialogPaper),
      }}
      open={open}
      onClose={closeDialog}
    >
      <DialogTitle
        className={classnames("fileDetailsDialogTitle", classes.fileDetailsDialogTitle)}
        disableTypography={true}
      >
        <label className={classnames("fileDetailsDialogTitleLabel", classes.fileDetailsDialogTitleLabel)}>
          File Details
        </label>
        <IconButton
          color="inherit"
          disableRipple={true}
          className={classnames("fileDetailsDialogCloseIconButton", classes.fileDetailsDialogCloseIconButton)}
          onClick={clickHandler(closeDialog)}
        >
          <CloseIcon className={classes.fileDetailsDialogCloseIcon} />
        </IconButton>
      </DialogTitle>
      <div className={classnames("fileDetailsDialogContent", classes.fileDetailsDialogContent)}>
        <SummaryViewLabel
          className={classnames("fileName", classes.fileDetailsDialogContentLabel)}
          nameClassName={classes.fileDetailsDialogContentLabelName}
          valueClassName={classes.fileDetailsDialogContentLabelValue}
          name="File Name"
          value={dataLakeFile.getFileName()}
        />
        <SummaryViewLabel
          className={classnames("fileSize", classes.fileDetailsDialogContentLabel)}
          nameClassName={classes.fileDetailsDialogContentLabelName}
          valueClassName={classes.fileDetailsDialogContentLabelValue}
          name="File Size"
          value={dataLakeFile.getFileSize()}
        />
        <SummaryViewLabel
          className={classnames("dateIndexed", classes.fileDetailsDialogContentLabel)}
          nameClassName={classes.fileDetailsDialogContentLabelName}
          valueClassName={classes.fileDetailsDialogContentLabelValue}
          name="Date Indexed"
          value={formatAsISO8601DateTimeString(dataLakeFile.getUpdatedAt(), "-")}
        />
        <SummaryViewLabel
          className={classnames("etag", classes.fileDetailsDialogContentLabel)}
          nameClassName={classes.fileDetailsDialogContentLabelName}
          valueClassName={classes.fileDetailsDialogContentLabelValue}
          name="ETag"
          value={dataLakeFile.getETag()}
        />
        <DataLakeFileMetadata
          className={classnames("metadata", classes.metadata)}
          dataSetAlias={dataSetAlias}
          file={dataLakeFile.getFilePath()}
          accountId={accountId}
        />
        {customAction ? (
            <SaveButton
              className={classnames("actionButton", classes.actionButton)}
              label={customActionLabel}
              save={() => {customAction(dataLakeFile.getFileName()); closeDialog(); }}
            />
          ) : (
            <DownloadDataLakeFileButton
              className={classnames("downloadButton", classes.downloadButton)}
              dataSetAlias={dataSetAlias}
              accountId={accountId}
              file={dataLakeFile.getFilePath()}
            />
          )
        }
      </div>
      {children}
    </Dialog>
  );
});

export default FileDetailsDialog;
