import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { Model, Actions, CredentialsTable } from "../../components/credentials/credentialsTable";
import { CredentialAttributes } from "@data";
import { DeviceEnrollmentWizardSelectors } from "../../selectors";
import { AddDeviceWizardActions } from "../../actions";

export interface CredentialsTableProps {
  credentials?: CredentialAttributes[];
}

const mapStateToProps = (state: AppSchema, ownProps: CredentialsTableProps): Model => {

  const { credentials } = ownProps;

  return {
    credentials,
    headerColumns: DeviceEnrollmentWizardSelectors.getCredentialsTableHeaderRowItems(state),
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: CredentialsTableProps): Actions => ({
  updateCredentialValue: (name: string, value: string) =>
    dispatch(AddDeviceWizardActions.updateCredentialValue(name, value)),
});

export default connect<Model, Actions, CredentialsTableProps>(
  mapStateToProps,
  mapDispatchToProps
)(CredentialsTable);
