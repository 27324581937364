import AppSchema from "@AppSchema";
import { AdminNote, Policy, User, UserAttributes } from "@data";
import { getAuthToken, getCurrentUserId } from "@main/selectors";
import { createActions } from "@base/createActions";
import {
  GetAttachedPoliciesResponse,
  PolicyClient,
  RestClientError,
  UserIdmLegacyClient,
  AdministrationClient,
  GetAdminNotesResponse,
} from "@network";
import { open as openLogoutUserDialog } from "@modules/logoutUser/actions";
import { open as openDeleteUserDialog } from "@modules/deleteUser/actions";
import { open as openCloseUserDialog } from "@modules/closeUser/actions";
import { open as openLockUserDialog } from "@modules/lockUser/actions";
import { open as openExpireUserPasswordDialog } from "@modules/expireUserPassword/actions";
import { open as openChangeUserPasswordDialog } from "@modules/changePassword/actions";
import {
  getUser,
  getUserId,
  isCurrentUserViewActive,
  getNoteNextPage,
  getNotes,
  getNote,
  isLoadingIndicatorVisible,
  getErrorMessage
} from "./selectors";
import { MY_PROFILE_PATH_NAME } from "./constants";
import { equalsIgnoreCase, isEmptyString } from "@util";
import { LockUserAction } from "@modules/lockUser/reducers";
import { ACTION_TYPES, DEFAULT_STATE, PoliciesViewMode } from "./reducers";

export const {
  policiesViewMode: setPoliciesViewMode,
  user: setUser,
  removeUserButtonEnabled: setRemoveUserButtonEnabled,
  detachPolicyButtonEnabled: setDetachPolicyButtonEnabled,
  implicitPermissionPolicy: setImplicitPermissionPolicy,
  notes: setNoteAttributes,
  note: setNote,
  adminUser: setAdminUser,
  noteNextPage: setNoteNextPage,
  createNoteBox: setCreateNoteBox,
  noteLoadingIndicator: setNoteLoadingIndicator,
  noteErrorMessage: setNoteErrorMessage,
  policyLoadingIndicator: setPolicyLoadingIndicator,
  policyErrorMessage: setPolicyErrorMessage,
  deleteAuthenticatorDialog: setDeleteAuthenticationDialog,
  updateMfaDialog: setUpdateMfaDialog,
  pageRefresh: setPageRefresh,
  setErrorMessage,
  setSuccessMessage,
  setShowLoadingIndicator,
  showLoadingIndicator,
  hideLoadingIndicator,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  showNotFound,
  hideNotFound,
  FETCH_USER_PROFILE_REQUEST: fetchUserProfileRequest,
  FETCH_USER_PROFILE_SUCCESS: fetchUserProfileSuccess,
  FETCH_USER_PROFILE_FAILED: fetchUserProfileFailed,
  FETCH_ADMIN_NOTES_REQUEST: fetchAdminNotesRequest,
  FETCH_ADMIN_NOTES_SUCCESS: fetchAdminNotesSuccess,
  FETCH_ADMIN_NOTES_FAILED: fetchAdminNotesFailed,
  CREATE_ADMIN_NOTE_REQUEST: createAdminNoteRequest,
  CREATE_ADMIN_NOTE_SUCCESS: createAdminNoteSuccess,
  CREATE_ADMIN_NOTE_FAILED: createAdminNoteFailed,
  FETCH_USER_AUDIT_EVENTS_REQUEST: fetchUserAuditEventsRequest,
  FETCH_USER_AUDIT_EVENTS_SUCCESS: fetchUserAuditEventsSuccess,
  FETCH_USER_AUDIT_EVENTS_FAILED: fetchUserAuditEventsFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setPoliciesViewMode());
  dispatch(setUser());
  dispatch(setRemoveUserButtonEnabled());
  dispatch(setDetachPolicyButtonEnabled());
  dispatch(setImplicitPermissionPolicy());
  dispatch(setNoteAttributes());
  dispatch(setNote());
  dispatch(setAdminUser());
  dispatch(setNoteNextPage());
  dispatch(setCreateNoteBox());
  dispatch(setNoteLoadingIndicator());
  dispatch(setPolicyLoadingIndicator());
  dispatch(setNoteErrorMessage());
  dispatch(setPolicyErrorMessage());
  dispatch(setDeleteAuthenticationDialog());
  dispatch(setUpdateMfaDialog());
  dispatch(setPageRefresh());
  return dispatch(baseReset());
};

export const resetAdminNotes = () => (dispatch: any) => {
  dispatch(setNoteAttributes());
  dispatch(setAdminUser());
  dispatch(setNoteNextPage());
  dispatch(setCreateNoteBox());
  return dispatch(setNoteErrorMessage());
};

export const clearErrorsAndLoading = () => (dispatch: any, getState: () => AppSchema) => {
  if (isLoadingIndicatorVisible(getState())) {
    dispatch(hideLoadingIndicator());
  }
  if (!isEmptyString(getErrorMessage(getState()))) {
    dispatch(setErrorMessage());
  }
};

export const showCustomPoliciesView = () => setPoliciesViewMode(PoliciesViewMode.CUSTOM);
export const showManagedPoliciesView = () => setPoliciesViewMode(PoliciesViewMode.MANAGED);

export const showNoteLoadingIndicator = () => setNoteLoadingIndicator(true);
export const hideNoteLoadingIndicator = () => setNoteLoadingIndicator(false);

export const logoutUser = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(openLogoutUserDialog(getUserId(getState())));

export const deleteUser = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(openDeleteUserDialog(getUserId(getState())));

export const closeUser = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const userId = getUserId(state);
  const selfAuthorized = isCurrentUserViewActive(state);
  return dispatch(openCloseUserDialog(userId, selfAuthorized));
};

export const lockUser = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(openLockUserDialog(getUserId(getState()), LockUserAction.LOCK_USER));

export const unlockUser = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(openLockUserDialog(getUserId(getState()), LockUserAction.UNLOCK_USER));

export const expireUserPassword = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(openExpireUserPasswordDialog(getUser(getState())));

export const changeUserPassword = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(openChangeUserPasswordDialog(getUserId(getState())));

export const setNotes = (notes: AdminNote[]) => (dispatch: any) =>
  dispatch(setNoteAttributes(notes.map(note => note.toJS())));

export const addNote = (notes: AdminNote[]) => (dispatch: any, getState: () => AppSchema) =>
  dispatch(setNotes(getNotes(getState()).concat(notes)));

export const showCreateNoteBox = () => (dispatch: any) => {
  dispatch(setNoteErrorMessage());
  dispatch(setSuccessMessage());
  return dispatch(setCreateNoteBox(true));
};

export const hideCreateNoteBox = () => (dispatch: any) => {
  dispatch(setNoteErrorMessage());
  dispatch(setNote());
  return dispatch(setCreateNoteBox(false));
};

export const fetchUserDetails = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const userId = getUserId(state);
  const authToken = getAuthToken(state);
  const myProfileView = isCurrentUserViewActive(state);
  const getUserProfile = myProfileView
    ? () => UserIdmLegacyClient.getCurrentUser(authToken, userId)
    : () => UserIdmLegacyClient.getUserProfile(authToken, userId);

  dispatch(showLoadingIndicator());
  dispatch(setErrorMessage());
  dispatch(hideAccessDenied());
  dispatch(hideNotFound());
  dispatch(fetchUserProfileRequest());

  return getUserProfile()
    .then((attrs: UserAttributes) => {

      dispatch(fetchUserProfileSuccess());
      dispatch(setUser(new User(attrs).toJS()));
      dispatch(hideLoadingIndicator());
      return dispatch(hideEmptyView());

    }, (response: RestClientError) => {

      const { analytic, error = "Fetch user profile failed" } = response;

      dispatch(fetchUserProfileFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(hideLoadingIndicator());

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      if (response.status === 404) {
        dispatch(showNotFound());
      }

      return dispatch(hideEmptyView());
    });
};

export const fetchUserPolicies = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const userId = getUserId(state);
  const authToken = getAuthToken(state);

  dispatch(setPolicyLoadingIndicator(true));
  dispatch(setImplicitPermissionPolicy());
  dispatch(setPolicyErrorMessage());

  return PolicyClient.getUserPolicies(authToken, userId)
    .catch((response: RestClientError) => {

      const { status } = response;

      if (status === 404) {
        return Promise.resolve({
          policies: [],
        });
      }

      return Promise.reject(response);
    })
    .then((response: GetAttachedPoliciesResponse) => {

      const { policies = [] } = response;

      const allPolicies = policies.map(attrs => new Policy(attrs));
      const implicitPermissionPolicy =
        allPolicies.filter((policy: Policy) => policy.getName().indexOf("implicit-permissions") >= 0);
      if (implicitPermissionPolicy.length >= 1) {
        dispatch(setImplicitPermissionPolicy(implicitPermissionPolicy[0]));
      }
      dispatch(setPolicyLoadingIndicator(false));
      return dispatch(hideEmptyView());

    }, (response: RestClientError) => {

      const { error = "Failed to fetch User Policies" } = response;

      dispatch(setPolicyErrorMessage(error));
      dispatch(setPolicyLoadingIndicator(false));
      return dispatch(hideEmptyView());
    });
};

export const fetchAdminNotes = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const userId = getUserId(state);
  const authToken = getAuthToken(state);
  const nextPage = getNoteNextPage(state);

  dispatch(fetchAdminNotesRequest());
  dispatch(showNoteLoadingIndicator());
  dispatch(resetAdminNotes());

  return AdministrationClient.getAdminNotes(authToken, userId, nextPage)
    .then((response: GetAdminNotesResponse) => {

      const { notes: results = [], paging: { next = "" } = { next: "" } } = response;

      dispatch(addNote(results.map(attrs => new AdminNote(attrs))));
      dispatch(setNoteNextPage(next));
      dispatch(setAdminUser(true));
      dispatch(hideNoteLoadingIndicator());
      return dispatch(fetchAdminNotesSuccess());
    }, (response: RestClientError) => {

      const { analytic, error = "Fetch admin notes failed" } = response;

      if (response.status === 403) {
        dispatch(setAdminUser(false));
      }

      dispatch(fetchAdminNotesFailed(analytic));
      dispatch(hideNoteLoadingIndicator());
      return dispatch(setNoteErrorMessage(error));
    });
};

export const createAdminNotes = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const userId = getUserId(state);
  const authToken = getAuthToken(state);
  const note = getNote(state);

  const json = JSON.stringify({
    note,
  });

  dispatch(createAdminNoteRequest());
  dispatch(setSuccessMessage());
  dispatch(showNoteLoadingIndicator());
  dispatch(setNoteErrorMessage());

  return AdministrationClient.createAdminNote(authToken, userId, json)
    .then(() => {

      dispatch(resetAdminNotes());
      dispatch(createAdminNoteSuccess());
      dispatch(hideNoteLoadingIndicator());
      dispatch(hideCreateNoteBox());
      dispatch(setSuccessMessage("Note created"));
      return dispatch(fetchAdminNotes());
    }, (response: RestClientError) => {

      const { analytic, error = "Failed to add Note" } = response;

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      dispatch(hideNoteLoadingIndicator());
      dispatch(createAdminNoteFailed(analytic));
      return dispatch(setNoteErrorMessage(error));
    });
};

export const refresh = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const myProfileView = isCurrentUserViewActive(state);
  const userId = myProfileView ? getCurrentUserId(state) : getUserId(state);

  // Reset all user info - except their id -  in case any metadata has changed
  dispatch(setUser(new User({ userId, id: userId }).toJS()));

  dispatch(fetchUserPolicies());
  dispatch(fetchAdminNotes());
  dispatch(setPageRefresh(true));
  return dispatch(fetchUserDetails());
};

export const initialize = (userId: string) => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const id = equalsIgnoreCase(userId, MY_PROFILE_PATH_NAME) ||
    isEmptyString(userId) ? getCurrentUserId(state) : userId;

  dispatch(reset());
  dispatch(setUser(new User({ userId: id, id }).toJS()));

  return dispatch(refresh());
};
