import { Record } from "immutable";
import { User, UserAttributes } from "@data";

export enum UserRequestStatus {
  QUEUED = "Queued",
  PROCESSING = "Processing",
  SUCCESS = "Success",
  FAILED = "Failed",
}

export interface UsersBulkRequestAttributes {
  user: UserAttributes;
  status: UserRequestStatus;
  errorMessage: string;
}

export class UsersBulkRequest extends Record({
  user: User.EMPTY.toJS(),
  status: UserRequestStatus.QUEUED,
  errorMessage: "",
}) implements UsersBulkRequestAttributes {

  public static EMPTY: UsersBulkRequest = new UsersBulkRequest();

  public readonly user: UserAttributes;
  public readonly status: UserRequestStatus;
  public readonly errorMessage: string;

  public getUser(): User {
    return new User(this.user);
  }

  public getUserId(): string {
    return this.getUser().getUserId();
  }

  public getUserStatus(): UserRequestStatus {
    return this.status;
  }

  public getErrorMessage(): string {
    return this.errorMessage;
  }
}
