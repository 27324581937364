import { createStyles, StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const tabs: StyleRules = {
  root: {
    minHeight: 0,
  },
  indicator: {
    backgroundColor: Color.WHITE,
  },
};

export const styles = () =>
  createStyles({
    container: {
      marginLeft: 10,
      marginRight: 10,
    },
    tab: {
      color: Color.WHITE,
      textTransform: "none",
      opacity: "1",
      minWidth: 0,
      minHeight: 0,
      padding: 0,
      margin: 8,
      "& > span": {
        marginLeft: 3,
      },
    },
  });

export default styles;
