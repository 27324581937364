import { applyInProd, equalsIgnoreCase } from "../../util";

const URL_BANNER_ENABLED =
  equalsIgnoreCase("true", process.env.REACT_APP_URL_BANNER_ENABLED);

const PORTAL_CLIENT_URL = process.env.REACT_APP_PORTAL_CLIENT_URL || "";

const isBrowserUrlCorrect = (): boolean => {
  return PORTAL_CLIENT_URL.indexOf(window.location.origin) === 0;
};

export const isUrlBannerVisible: () => boolean = applyInProd(
  () => () => URL_BANNER_ENABLED && !isBrowserUrlCorrect(),
  () => () => false); // Do not show banner when running local dev server
