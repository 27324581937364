import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { EmailTemplateLanguage, EmailTemplateMessageType } from "@data";
import { reviewView as styles } from "./styles";
import { HtmlBodyView } from "@components";
import { mapMessageTypeToOptionName } from "@modules/emailTemplateWizard/components/TemplateView";

export interface Model {
  templateId?: string;
  applicationId?: string;
  language?: EmailTemplateLanguage;
  messageType?: EmailTemplateMessageType;
  emailSubject?: string;
  description?: string;
  textBody?: string;
  htmlBody?: string;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ReviewView = withStyles(styles)((props: Props) => {

  const {
    classes,
    templateId,
    applicationId,
    language,
    messageType = EmailTemplateMessageType.NONE,
    emailSubject,
    description,
    textBody,
    htmlBody,
    children,
  } = props;

  const [showPreviewView, setShowPreviewView] = React.useState(true);

  return (
    <div className={classnames("reviewView", classes.container)}>
      <label className={classnames("title", classes.title)}>Review Email Template</label>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Template Id: </label>
        <label className={classnames("templateId", classes.propertyValue)}>{templateId}</label>
      </div>
      {applicationId && (
        <div className={classnames(classes.propertyContainer)}>
          <label className={classnames(classes.propertyName)}>Application Id: </label>
          <label className={classnames("applicationId", classes.propertyValue)}>{applicationId}</label>
        </div>
      )}
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Language: </label>
        <label className={classnames("language", classes.propertyValue)}>{language}</label>
      </div>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Message Type: </label>
        <label className={classnames("messageType", classes.propertyValue)}>
          {mapMessageTypeToOptionName(messageType)}
        </label>
      </div>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Email Subject: </label>
        <label className={classnames("emailSubject", classes.propertyValue)}>{emailSubject}</label>
      </div>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Description: </label>
        <label className={classnames("description", classes.propertyValue)}>{description}</label>
      </div>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Text Body: </label>
        <label className={classnames("textBody", classes.propertyValue)}>{textBody}</label>
      </div>
      {htmlBody && (
        <div className={classnames(classes.propertyContainer, classes.emailTemplateContainer)}>
          <HtmlBodyView
            previewViewSelected={showPreviewView}
            rawViewSelected={!showPreviewView}
            showPreviewView={() => setShowPreviewView(true)}
            showRawView={() => setShowPreviewView(false)}
            htmlBody={htmlBody}
          />
        </div>
      )}
      {children}
    </div>
  );
});

export default ReviewView;
