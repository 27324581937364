import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DownloadIcon from "@material-ui/icons/GetApp";
import CopyAccessToken from "./CopyAccessToken";
import DownloadLink from "./DownloadLink";
import styles from "./styles";

export interface Model {
  accountId?: string;
  documentationLink?: string;
  documentationLabel?: string;
  accessToken?: string;
  accessTokenExpiration?: string;
  version?: string;
  children?: React.ReactNode;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const AccountSettings = withStyles(styles)((props: Props) => {

  const {
    classes,
    accountId,
    documentationLink,
    documentationLabel,
    version,
    accessToken,
    accessTokenExpiration,
    children,
  } = props;

  return (
    <div className={classnames("accountSettings", classes.container)}>
      {accountId && (
        <div className={classnames("header", classes.header)}>
          <label className={classes.title}>IoT Account ID:</label>
          <label className={classnames("accountId", classes.title)}>{accountId}</label>
        </div>
      )}
      {documentationLink && (
        <div className={classes.documentation}>
          <p className={classes.label}>Documentation</p>
          <DownloadLink
            className="documentationLink"
            href={documentationLink}
            label={documentationLabel}
            icon={DownloadIcon}
          />
        </div>
      )}
      <CopyAccessToken
        className="accessToken"
        title="IoT Access Token"
        subtitle={!accessTokenExpiration ? "" : `Expires At: ${accessTokenExpiration}`}
        accessToken={accessToken}
        hint="Copy Access Token"
        successLabel="Access Token Copied"
        errorLabel="Copy Access Token Failed"
      />
      {version && (
        <div className={classnames("version", classes.version)}>
          <label>{version}</label>
        </div>
      )}
      {children}
    </div>
  );
});

export default AccountSettings;
