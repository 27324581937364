import { isEmptyString, isValidInteger } from "@util";
import {
  AuthenticateServiceMfaRequest,
  SecurityGroupAttributes,
  SecurityProtocol,
  SecurityServiceRegionalAttributes,
} from "@data";
import {
  createQueryParams,
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments,
} from "./helpers";

const DEFAULT_LIMIT = 50;

const REGIONAL_API_URL = process.env.REACT_APP_REGIONAL_API || "";
const AUTHORIZATION_SERVICE_URL = process.env.REACT_APP_AUTHORIZATION_SERVICE_API_LEGACY || "";
const DATA_SET_ACCESS_URL = process.env.REACT_APP_DATA_ACCESS_REQUEST_API || "";
const FEDERATION_APP_ID = process.env.REACT_APP_FEDERATION_APP_ID || "";

if (isEmptyString(REGIONAL_API_URL)) {
  throw new Error("Missing Environment Variable: REACT_APP_SERVICE_IDM_MANAGER");
}

if (isEmptyString(AUTHORIZATION_SERVICE_URL)) {
  throw new Error("Missing Environment Variable: REACT_APP_AUTHORIZATION_SERVICE_API_LEGACY");
}

export interface AuthenticateServiceRegionalResponse {
  accessToken: string;
  expiration: string;
}

export interface AuthenticateServiceServiceData {
  token: {
    token: string;
    expiration: string;
  };
}

export interface GetSecurityServiceGroupResponse {
  id: string;
  groups?: SecurityGroupAttributes[];
  paging?: {
    next?: string;
  };
}

export interface ServiceAuthenticationRegionalResponse {
  protocol: SecurityProtocol;
  data: AuthenticateServiceServiceData;
}

export interface CreateMultifactorAuthenticatorResponse {
  id: string;
  secret: string;
}

export interface ServiceDataSetAccess {
  accessRequestType: string;
  accountId: string;
  dataSetAlias: string;
}

export interface GetServiceDataSetsResponse {
  accessibleDataSets: ServiceDataSetAccess[];
}

export interface GetSecurityServicesResponse {
  items: SecurityServiceRegionalAttributes[];
  paging?: {
    next?: string;
  };
}

export const authenticateServiceRegional = (serviceId: string,
                                            accountId: string,
                                            secret: string,
                                            mfaCode: string = ""): Promise<AuthenticateServiceRegionalResponse> => {

  const validate = () => withRequiredArguments([
    ["Account ID", accountId],
    ["Service ID", serviceId],
    ["Secret", secret],
  ]);

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/identity/v2/services/${serviceId}/authenticate`;

    const data = new AuthenticateServiceMfaRequest({
      account: accountId,
      security: {
        protocol: SecurityProtocol.SHARED_SECRET,
        data: {
          secret,
        },
      },
      mfa: {
        code: mfaCode,
      },
    });

    const settings = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-IoT-Platform-ApplicationId": FEDERATION_APP_ID,
      },
      body: JSON.stringify(data),
    };

    const defaultErrorMessage = "The credentials you entered are incorrect. Please try again.";

    return makeJsonApiRequest<ServiceAuthenticationRegionalResponse>(url, settings, defaultErrorMessage)
      .then(({data: {token: {token: accessToken, expiration: expiration}}}) => ({
        accessToken,
        expiration,
      }));

  };

  return validate().then(makeRequest);
};

export const getServicesRegional = (authToken: string,
                                    nameFilter: string = "",
                                    groupName: string = "",
                                    next: string = "",
                                    limit: number = DEFAULT_LIMIT): Promise<GetSecurityServicesResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams({
      ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, Math.min(DEFAULT_LIMIT, limit)) }),
      ...(!isEmptyString(groupName) && groupName.length >= 3 ? ({ groupName }) : ({})),
      prefix: nameFilter,
      offset: next,
    });

    const url = `${REGIONAL_API_URL}/security/identity/v2/services${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch services failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getServiceRegional = (authToken: string,
                                   serviceId: string = ""): Promise<SecurityServiceRegionalAttributes> => {

  const validate = () => withAuthToken(authToken).then(() => withRequiredArguments([
    ["Service ID", serviceId],
  ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/identity/v2/services/${serviceId}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to get Service [${serviceId}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getServiceDataSets = (authToken: string,
                                   accountId: string,
                                   serviceId: string = "",
                                   accessRequestType: string): Promise<GetServiceDataSetsResponse> => {

  const validate = () => withAuthToken(authToken).then(() => withRequiredArguments([
    ["Account ID", accountId],
    ["Service ID", serviceId],
  ]));

  const queryParams = createQueryParams({ accessRequestType });

  const makeRequest = () => {
    const serviceIdentifier = `lrn:iot:aam::${accountId}:principal:service:${serviceId}`;
    const url =
      `${DATA_SET_ACCESS_URL}/data/management/v1/access-requests/accessible-by-service-principal/${serviceIdentifier}${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to get accessible data sets for service [${serviceId}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const createServiceRegional = (authToken: string,
                                      json: string): Promise<SecurityServiceRegionalAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["id", json],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/identity/v2/services/`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to create service";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getServiceGroups = (authToken: string,
                                 serviceId: string = "",
                                 next: string = ""): Promise<GetSecurityServiceGroupResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Service ID", serviceId],
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({ next });

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/services/${serviceId}/groups${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to get security groups for service [${serviceId}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const generateServiceSecret = (authToken: string,
                                      serviceId: string): Promise<SecurityServiceRegionalAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Service ID", serviceId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/identity/v2/services/${serviceId}/authentication/secrets`;

    const settings = {
      method: "PATCH",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to generate service secret for ${serviceId}`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteServiceSecret = (authToken: string,
                                    serviceId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Service ID", serviceId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/identity/v2/services/${serviceId}/authentication/secrets`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to delete old service secret for ${serviceId}`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const setServiceSecret = (authToken: string,
                                 serviceId: string,
                                 json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Service ID", serviceId],
      ["Secret", json],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/identity/v2/services/${serviceId}/authentication/secrets`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type":  "application/json"
      },
      body: json,
    };

    const defaultErrorMessage = `Failed to update service secret for ${serviceId}`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteServiceRegional = (authToken: string,
                                      serviceId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Service ID", serviceId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/identity/v2/services/${serviceId}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to delete service ${serviceId}`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const enableServiceRegionalApi = (authToken: string,
                                         serviceId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Service ID", serviceId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/identity/v2/services/${serviceId}/state/enabled`;

    const settings = {
      method: "PATCH",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to enable service ${serviceId}`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const disableServiceRegionalApi = (authToken: string,
                                          serviceId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Service ID", serviceId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/identity/v2/services/${serviceId}/state/disabled`;

    const settings = {
      method: "PATCH",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to disable service ${serviceId}`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const createMultifactorAuthenticator = (authToken: string): Promise<CreateMultifactorAuthenticatorResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/identity/v2/services/mfa`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to create multifactor authenticator`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const activateMultifactorAuthenticator = (authToken: string,
                                                 code: string,
                                                 id: string = ""): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Authenticator Code", code],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/identity/v2/services/mfa/activate`;

    const data = {
      code,
      // The ID is an optional field that we may use down the road, but right now it doesn't matter
      // since there can only be one registered mfa authenticator per service
      ...(isEmptyString(id) ? ({}) : ({
        id,
      })),
    };

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const defaultErrorMessage = "Failed to activate multifactor authenticator" +
      isEmptyString(id) ? "" : ` [${id}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteMultifactorAuthenticator = (authToken: string,
                                               code: string,
                                               id: string = ""): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Authenticator Code", code],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/identity/v2/services/mfa`;

    const data = {
      code,
      // The ID is an optional field that we may use down the road, but right now it doesn't matter
      // since there can only be one registered mfa authenticator per service
      ...(isEmptyString(id) ? ({}) : ({
        id,
      })),
    };

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const defaultErrorMessage = "Failed to delete multifactor authenticator" +
      isEmptyString(id) ? "" : ` [${id}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
