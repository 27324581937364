import React from "react";
import classnames from "classnames";
import { clickHandler } from "@util";
import Button from "@components/button";
import { DropdownIcon, UserIcon } from "@icons";
import { toolbarUserMenuButton as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export const ToolbarUserMenuButton = withStyles(styles)((props: WithStyles<typeof styles> & {
  label: string,
  onClick?: () => void,
}) => {

  const { classes, label, onClick } = props;

  return (
    <Button
      className={classnames("toolbarUserMenuButton", classes.button)}
      classes={{
        label: classnames("label", classes.label),
      }}
      onClick={clickHandler(onClick)}
    >
      <UserIcon color="inherit" className={classes.userIcon} />
      {label}
      <DropdownIcon color="inherit" className={classes.dropdownIcon} />
    </Button>
  );
});

export default ToolbarUserMenuButton;
