import React from "react";
import { Module } from "@data";
import { initialize, onMount } from "./actions";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { DASHBOARDS_MODULE_ENABLED } from "@modules/portlet/module";
import DashboardsTabsView from "./DashboardsTabsView";
import view from "./DashboardsView";

export const DashboardAccessControlModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Dashboard Access",
  title: "Dashboard Access Control",
  className: "dashboardAccessControlModule",
  primaryColor,
  icon,
  view,
  tabs: <DashboardsTabsView />,
  disabled: !DASHBOARDS_MODULE_ENABLED,
  backButton: null,
  initialize,
  onMount,
});

export default DashboardAccessControlModule;
