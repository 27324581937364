import { createStyles } from "@material-ui/core/styles";
import ModuleIcon from "@material-ui/icons/DeveloperMode";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const deviceTypes = () =>
  createStyles({
    container: {
      color: primaryColor,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    search: {
      marginLeft: 6,
      marginTop: 15,
    },
    controls: {
      flex: "1 0 auto",
      display: "inline-flex",
      justifyContent: "flex-end",
    },
    createDeviceTypeButton: {
    },
  });

export const listItem = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "center",
    },
    icon: {
      color: primaryColor,
      alignSelf: "center",
      width: 16,
      height: 16,
      marginTop: 0,
      marginRight: 10,
    },
    label: {
      color: Color.TEXT,
    }
  });

export const errorView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      padding: 20,
      borderRadius: 3,
      border: `1px solid ${Color.RED}`,
      backgroundColor: Color.LIGHT_RED,
    },
    icon: {
      width: 48,
      height: 48,
      color: Color.RED,
    },
    title: {
      color: Color.DARK_RED,
      fontFamily: "Roboto, sans-serif",
      fontSize: 24,
      fontWeight: 400,
      lineHeight: "28px",
      marginTop: 8,
    },
    error: {
      color: Color.DARK_RED,
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "16px",
      marginTop: 4,
    },
  });

export const emptyView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      justifyContent: "center",
    },
    progress: {
      color: primaryColor,
    },
    label: {
      color: Color.GREY3,
      fontFamily: "Roboto, sans-serif",
      fontSize: 24,
      fontStyle: "italic",
      fontWeight: 300,
      lineHeight: "50px",
      margin: "0 0 0 25px",
      padding: 0,
    },
  });

export const module = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
  });
