import { createActions } from "@base";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";

export const {
  dataSetId: setDataSetId,
  FETCH_DATA_SET_REQUEST: fetchDataSetRequest,
  FETCH_DATA_SET_SUCCESS: fetchDataSetSuccess,
  FETCH_DATA_SET_FAILED: fetchDataSetFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setDataSetId());
  return dispatch(baseReset());
};

export const initialize = (id: string) => (dispatch: any) => {

  dispatch(reset());
  dispatch(setDataSetId(id));
  return Promise.resolve();
};
