import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "center",
    },
    listContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      marginTop: 40,
      "& table": {
        marginTop: 0,
      },
    },
    label: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "16px",
      wordBreak: "break-word",
      marginRight: 8,
    },
  });
