import React from "react";
import { ManagedPolicy } from "@data";
import { isEmptyString, noop } from "@util";
import { connect } from "react-redux";
import AppSchema from "@schemas";
import capitalize from "lodash/capitalize";
import { ManagedPolicyBulkRequest } from "@data/ManagedPolicyBulkRequest";
import SelectManagedPolicy , { Actions, Model } from "../components/SelectManagedPolicy";
import {
  PolicyOperation,
  PolicyOperationPrincipal,
  useManagedEffectivePolicies,
  useManagedPolicyThatIsNotAttachedToPrincipal,
  useManagedPolicies as useManagedPoliciesList
} from "@hooks";
import {
  getPrincipalId,
  getOperation,
  getPrincipalType,
  getPolicyName,
  getPolicyRef,
  isManagedPolicyViewSelected,
  getSelectedManagedPolicies,
} from "../selectors";
import {
  setPolicyBulkRequest,
  setPolicyName,
  setPolicyRef,
  setSelectedManagedPolicies,
} from "../actions";

interface ContainerModel extends Model {
  principalId?: string;
  policyName?: string;
  policyRef?: string;
  principalType?: PolicyOperationPrincipal;
  operation?: PolicyOperation;
  hidden?: boolean;
}

interface ContainerActions extends Actions {
  setManagedPolicyName?: (name: string) => void;
  setManagedPolicyRef?: (policyRef: string) => void;
}

type Props = ContainerModel & ContainerActions;

const SelectManagedPolicyToAttachToPrincipal = (props: Props) => {

  const {
    principalId = "",
    policyName = "",
    policyRef,
    principalType = PolicyOperationPrincipal.SERVICE,
    hidden,
    setManagedPolicyName = noop,
    setManagedPolicyRef = noop,
    ...otherProps } = props;

  const [ policyModel ] = useManagedPolicyThatIsNotAttachedToPrincipal(principalType, principalId);

  const {
    policies = [],
    showLoadingIndicator: policyLoading,
    errorMessage: policyErrorMessage,
    ...remainingPolicyModel } = policyModel;

  const [ model, actions ] = useManagedPoliciesList();

  const {
    managedPolicies = [],
    showLoadingIndicator: managedPolicyLoading,
    errorMessage: managedPolicyErrorMessage,
    ...remainingManagedPolicyModel
  } = model;

  const errorMessage = React.useMemo(() => {
    if (!isEmptyString(managedPolicyErrorMessage)) {
      return managedPolicyErrorMessage;
    } else if (!isEmptyString(policyErrorMessage)) {
      return policyErrorMessage;
    } else {
      return "";
    }
  }, [managedPolicyErrorMessage, policyErrorMessage]);

  const loadingIndicator = React.useMemo(() =>
    managedPolicyLoading || policyLoading
  , [managedPolicyLoading, policyLoading]);

  const items: ManagedPolicy[] = React.useMemo(() => {
      return managedPolicies.filter(managedPolicy =>
        policies.some(policy => policy.getName() === managedPolicy.getPolicy().getName()));
  }, [managedPolicies, policies]);

  const setSelectedManagedPolicy = React.useCallback((managedPolicy: ManagedPolicy = ManagedPolicy.EMPTY) => {
    setManagedPolicyName(managedPolicy.getPolicy().getName());
    setManagedPolicyRef(managedPolicy.getPolicyRef());
  }, [setManagedPolicyName, setManagedPolicyRef]);

  const selectedItems: ManagedPolicy[] = React.useMemo(() =>
      isEmptyString(policyName) || isEmptyString(policyRef) ? [] : [
        new ManagedPolicy({
          policy: {
            info: {
              name: policyName,
            }
          },
          reference: {
            policyRef
          }
        })],
    [policyName, policyRef]);

  const noResultsLabel = React.useMemo(() =>
    isEmptyString(principalId) ?
      undefined :
      `${capitalize(principalType)} is attached to all the available Managed Policies`, [principalType]);

  const loadingLabel = React.useMemo(() =>
    isEmptyString(principalId) ?
      undefined :
      `Loading managed policies that can be attached to the ${principalType}...`, [principalType]);

  if (hidden) {
    return null;
  }

  return (
    <SelectManagedPolicy
      {...otherProps}
      {...remainingPolicyModel}
      {...remainingManagedPolicyModel}
      {...actions}
      items={items}
      className={"selectManagedPolicies"}
      errorMessage={errorMessage}
      showLoadingIndicator={loadingIndicator}
      selectedItems={selectedItems}
      noResultsLabel={noResultsLabel}
      loadingLabel={loadingLabel}
      setSelectedManagedPolicy={setSelectedManagedPolicy}
    />
  );

};

const SelectManagedPolicyToDetachFromPrincipal = (props: Props) => {

  const {
    principalId = "",
    policyName = "",
    policyRef = "",
    principalType = PolicyOperationPrincipal.SERVICE,
    hidden,
    setManagedPolicyName = noop,
    setManagedPolicyRef = noop,
    ...otherProps } = props;

  const [ policyModel ] = useManagedEffectivePolicies(principalType, principalId);

  const {
    policies = [],
    showLoadingIndicator: policyLoading,
    errorMessage: policyErrorMessage,
    ...remainingPolicyModel } = policyModel;

  const [ model, actions ] = useManagedPoliciesList();

  const {
    managedPolicies = [],
    showLoadingIndicator: managedPolicyLoading,
    errorMessage: managedPolicyErrorMessage,
    ...remainingManagedPolicyModel
  } = model;

  const items: ManagedPolicy[] = React.useMemo(() => {
    return managedPolicies.filter(managedPolicy =>
      policies.some(policy => policy.getName() === managedPolicy.getPolicy().getName()));
  }, [managedPolicies, policies]);

  const errorMessage = React.useMemo(() => {
    if (!isEmptyString(managedPolicyErrorMessage)) {
      return managedPolicyErrorMessage;
    } else if (!isEmptyString(policyErrorMessage)) {
      return policyErrorMessage;
    } else {
      return "";
    }
  }, [managedPolicyErrorMessage, policyErrorMessage]);

  const loadingIndicator = React.useMemo(() =>
    managedPolicyLoading || policyLoading
    , [managedPolicyLoading, policyLoading]);

  const setSelectedManagedPolicy = React.useCallback((managedPolicy: ManagedPolicy = ManagedPolicy.EMPTY) => {
    setManagedPolicyName(managedPolicy.getPolicy().getName());
    setManagedPolicyRef(managedPolicy.getPolicyRef());
  }, [setManagedPolicyName, setManagedPolicyRef]);

  const selectedItems: ManagedPolicy[] = React.useMemo(() =>
      isEmptyString(policyName) || isEmptyString(policyRef) ? [] : [
        new ManagedPolicy({
          policy: {
            info: {
              name: policyName,
            }
          },
          reference: {
            policyRef
          }
        })],
    [policyName, policyRef]);

  const noResultsLabel = React.useMemo(() =>
    isEmptyString(principalId) ?
      undefined :
      `There are no managed policies attached to this ${principalType}`, [principalType]);

  const loadingLabel = React.useMemo(() =>
    isEmptyString(principalId) ?
      undefined :
      `Loading managed policies that can be detached from the ${principalType}...`, [principalType]);

  if (hidden) {
    return null;
  }

  return (
    <SelectManagedPolicy
      {...otherProps}
      {...remainingPolicyModel}
      {...remainingManagedPolicyModel}
      {...actions}
      items={items}
      errorMessage={errorMessage}
      className={"selectManagedPolicies"}
      showLoadingIndicator={loadingIndicator}
      selectedItems={selectedItems}
      noResultsLabel={noResultsLabel}
      loadingLabel={loadingLabel}
      setSelectedManagedPolicy={setSelectedManagedPolicy}
    />
  );

};

const SelectManagedPolicyContainer = (props: Props) => {

  const { operation, ...otherProps } = props;

  if (operation === PolicyOperation.ATTACH ) {
    return (<SelectManagedPolicyToAttachToPrincipal {...otherProps} />);
  } else {
    return (<SelectManagedPolicyToDetachFromPrincipal {...otherProps} />);
  }
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  principalId: getPrincipalId(state),
  policyName: getPolicyName(state),
  policyRef: getPolicyRef(state),
  principalType: getPrincipalType(state),
  operation: getOperation(state),
  hidden: !isManagedPolicyViewSelected(state),
  selectedManagedPolicies: getSelectedManagedPolicies(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setPolicyBulkRequest: (req: ManagedPolicyBulkRequest[]) => dispatch(setPolicyBulkRequest(req)),
  setSelectedManagedPolicies: (items: ManagedPolicy[]) => dispatch(setSelectedManagedPolicies(items)),
  setManagedPolicyName: (name: string) =>
    dispatch(setPolicyName(name)),
  setManagedPolicyRef: (policyRef: string) =>
    dispatch(setPolicyRef(policyRef)),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(SelectManagedPolicyContainer);
