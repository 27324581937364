import React from "react";
import classnames from "classnames";
import { QualityReportTrend } from "@data";
import Typography from "@material-ui/core/Typography";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import QualityReportCVEVulnerabilities from "@data/QualityReportCVEVulnerabilities";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const getQualityReportTrend = (trendDelta: number) => {
  if (trendDelta === 0) {
    return QualityReportTrend.FLAT;
  } else {
    return trendDelta < 0
      ? QualityReportTrend.TRENDING_DOWN
      : QualityReportTrend.TRENDING_UP;
  }
};

export interface CVEVulnerabilitiesModel {
  className?: string;
  title?: string;
  currentReport?: QualityReportCVEVulnerabilities;
  previousReport?: QualityReportCVEVulnerabilities;
  showHistoricalTrends?: boolean;
}

export interface CVEVulnerabilitiesActions {
}

type Model = CVEVulnerabilitiesModel;
type Actions = CVEVulnerabilitiesActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const CVEVulnerabilities = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Open Source Vulnerabilities",
    currentReport = QualityReportCVEVulnerabilities.EMPTY,
    previousReport = QualityReportCVEVulnerabilities.EMPTY,
    showHistoricalTrends,
    children,
  } = props;

  const numCriticalVulnerabilities = React.useMemo(() =>
      currentReport.getNumCriticalVulnerabilities(),
    [currentReport]);

  const criticalVulnerabilitiesTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumCriticalVulnerabilities() - previousReport.getNumCriticalVulnerabilities()),
    [showHistoricalTrends, currentReport, previousReport]);

  const criticalVulnerabilitiesTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(criticalVulnerabilitiesTrendDelta),
    [showHistoricalTrends, criticalVulnerabilitiesTrendDelta]);

  const numHighVulnerabilities = React.useMemo(() =>
      currentReport.getNumHighVulnerabilities(),
    [currentReport]);

  const highVulnerabilitiesTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumHighVulnerabilities() - previousReport.getNumHighVulnerabilities()),
    [showHistoricalTrends, currentReport, previousReport]);

  const highVulnerabilitiesTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(highVulnerabilitiesTrendDelta),
    [showHistoricalTrends, highVulnerabilitiesTrendDelta]);

  const numMediumVulnerabilities = React.useMemo(() =>
      currentReport.getNumMediumVulnerabilities(),
    [currentReport]);

  const mediumVulnerabilitiesTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumMediumVulnerabilities() - previousReport.getNumMediumVulnerabilities()),
    [showHistoricalTrends, currentReport, previousReport]);

  const mediumVulnerabilitiesTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(mediumVulnerabilitiesTrendDelta),
    [showHistoricalTrends, mediumVulnerabilitiesTrendDelta]);

  const numLowVulnerabilities = React.useMemo(() =>
      currentReport.getNumLowVulnerabilities(),
    [currentReport]);

  const lowVulnerabilitiesTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumLowVulnerabilities() - previousReport.getNumLowVulnerabilities()),
    [showHistoricalTrends, currentReport, previousReport]);

  const lowVulnerabilitiesTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(lowVulnerabilitiesTrendDelta),
    [showHistoricalTrends, lowVulnerabilitiesTrendDelta]);

  const numNoneVulnerabilities = React.useMemo(() =>
      currentReport.getNumNoneVulnerabilities(),
    [currentReport]);

  const noneVulnerabilitiesTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumNoneVulnerabilities() - previousReport.getNumNoneVulnerabilities()),
    [showHistoricalTrends, currentReport, previousReport]);

  const noneVulnerabilitiesTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(noneVulnerabilitiesTrendDelta),
    [showHistoricalTrends, noneVulnerabilitiesTrendDelta]);

  const numVulnerabilities = React.useMemo(() =>
      numHighVulnerabilities +
      numCriticalVulnerabilities +
      numMediumVulnerabilities +
      numLowVulnerabilities +
      numNoneVulnerabilities,
    [
      numHighVulnerabilities,
      numCriticalVulnerabilities,
      numMediumVulnerabilities,
      numLowVulnerabilities,
      numNoneVulnerabilities,
    ]);

  const highSeverityBarClipPath = React.useMemo(() =>
      numVulnerabilities <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numHighVulnerabilities / numVulnerabilities)) * 100}% 0% 0%)`,
    [numVulnerabilities, numHighVulnerabilities]);

  const criticalSeverityBarClipPath = React.useMemo(() =>
      numVulnerabilities <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numCriticalVulnerabilities / numVulnerabilities)) * 100}% 0% 0%)`,
    [numVulnerabilities, numCriticalVulnerabilities]);

  const mediumSeverityBarClipPath = React.useMemo(() =>
      numVulnerabilities <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numMediumVulnerabilities / numVulnerabilities)) * 100}% 0% 0%)`,
    [numVulnerabilities, numMediumVulnerabilities]);

  const lowSeverityBarClipPath = React.useMemo(() =>
      numVulnerabilities <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numLowVulnerabilities / numVulnerabilities)) * 100}% 0% 0%)`,
    [numVulnerabilities, numLowVulnerabilities]);

  const noneSeverityBarClipPath = React.useMemo(() =>
      numVulnerabilities <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numNoneVulnerabilities / numVulnerabilities)) * 100}% 0% 0%)`,
    [numVulnerabilities, numNoneVulnerabilities]);

  return (
    <div className={classnames("vulnerabilities", className, classes.container)}>
      <Typography
        className={classnames("title", classes.title)}
        variant="h5"
      >
        {title}
      </Typography>
      <div className={classnames("content", classes.content)}>
        <div className={classnames("criticalVulnerabilities", classes.row)}>
          <Typography className={classnames("severityLabel", classes.severityLabel)}>
            Critical
          </Typography>
          <div
            className={classnames("criticalSeverityBar", classes.severityBar, classes.critical)}
            style={{ clipPath: criticalSeverityBarClipPath }}
          />
          <Typography className={classnames("severityCountLabel", classes.severityCountLabel)}>
            {numCriticalVulnerabilities}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {criticalVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {criticalVulnerabilitiesTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {criticalVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {criticalVulnerabilitiesTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: criticalVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: criticalVulnerabilitiesTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: criticalVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({criticalVulnerabilitiesTrendDelta < 0 ? "" : "+"}{criticalVulnerabilitiesTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("highVulnerabilities", classes.row)}>
          <Typography className={classnames("severityLabel", classes.severityLabel)}>
            High
          </Typography>
          <div
            className={classnames("highSeverityBar", classes.severityBar, classes.high)}
            style={{ clipPath: highSeverityBarClipPath }}
          />
          <Typography className={classnames("severityCountLabel", classes.severityCountLabel)}>
            {numHighVulnerabilities}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {highVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {highVulnerabilitiesTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {highVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {highVulnerabilitiesTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: highVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: highVulnerabilitiesTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: highVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({highVulnerabilitiesTrendDelta < 0 ? "" : "+"}{highVulnerabilitiesTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("mediumVulnerabilities", classes.row)}>
          <Typography className={classnames("severityLabel", classes.severityLabel)}>
            Medium
          </Typography>
          <div
            className={classnames("mediumSeverityBar", classes.severityBar, classes.medium)}
            style={{ clipPath: mediumSeverityBarClipPath }}
          />
          <Typography className={classnames("severityCountLabel", classes.severityCountLabel)}>
            {numMediumVulnerabilities}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {mediumVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {mediumVulnerabilitiesTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {mediumVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {mediumVulnerabilitiesTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: mediumVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: mediumVulnerabilitiesTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: mediumVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({mediumVulnerabilitiesTrendDelta < 0 ? "" : "+"}{mediumVulnerabilitiesTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("lowVulnerabilities", classes.row)}>
          <Typography className={classnames("severityLabel", classes.severityLabel)}>
            Low
          </Typography>
          <div
            className={classnames("lowSeverityBar", classes.severityBar, classes.low)}
            style={{ clipPath: lowSeverityBarClipPath }}
          />
          <Typography className={classnames("severityCountLabel", classes.severityCountLabel)}>
            {numLowVulnerabilities}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {lowVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {lowVulnerabilitiesTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {lowVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {lowVulnerabilitiesTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: lowVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: lowVulnerabilitiesTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: lowVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({lowVulnerabilitiesTrendDelta < 0 ? "" : "+"}{lowVulnerabilitiesTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("noneVulnerabilities", classes.row)}>
          <Typography className={classnames("severityLabel", classes.severityLabel)}>
            None
          </Typography>
          <div
            className={classnames("noneSeverityBar", classes.severityBar, classes.none)}
            style={{ clipPath: noneSeverityBarClipPath }}
          />
          <Typography className={classnames("severityCountLabel", classes.severityCountLabel)}>
            {numNoneVulnerabilities}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {noneVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {noneVulnerabilitiesTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {noneVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {noneVulnerabilitiesTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: noneVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: noneVulnerabilitiesTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: noneVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({noneVulnerabilitiesTrendDelta < 0 ? "" : "+"}{noneVulnerabilitiesTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("footer", classes.footer)}>
          <Typography className={classnames("totalCountPrefix", classes.totalCountPrefix)}>
            {numVulnerabilities}
            <Typography
              className={classnames("totalCountSuffix", classes.totalCountSuffix)}
              component="span"
            >
              {numVulnerabilities === 1 ? "vulnerability" : "vulnerabilities"}
            </Typography>
          </Typography>
        </div>
      </div>
      {children}
    </div>
  );
});

export default CVEVulnerabilities;
