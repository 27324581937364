import AppSchema from "@AppSchema";
import { connect } from "react-redux";
import { showPreviousStep } from "../actions";
import { isFirstStepSelected } from "../selectors";
import WizardBackButton, {
  WizardBackButtonActions as Actions,
  WizardBackButtonModel as Model,
} from "@components/back-button/WizardBackButton";

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  className: "inviteUserBackButton",
  firstStepSelected: isFirstStepSelected(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  showPreviousStep: () => dispatch(showPreviousStep()),
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(WizardBackButton);
