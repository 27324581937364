import React from "react";
import { useDispatch } from "react-redux";
import { DeviceTypeWizardActions } from "./actions";
import { useHistory, useLocation } from "react-router-dom";
import {
  DeviceTypeModelV2,
  DeviceTypeModelV2Attributes,
  DeviceTypeModelV3,
  DeviceTypeModelV3Attributes,
  SecurityGroup
} from "@data";
import { getPathToDeviceType } from "@modules/deviceTypeDetails/helpers";
import { getPathToGroup } from "@modules/securityGroupDetails/helpers";
import DeviceTypeWizard from "./containers/DeviceTypeWizard";

type LocationProps = {
  deviceType?: DeviceTypeModelV3Attributes;
  deviceTypeV2?: DeviceTypeModelV2Attributes;
};

export function CreateDeviceTypeView() {

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    state: {
      deviceType,
      deviceTypeV2 = DeviceTypeModelV2.EMPTY.toJS(),
    } = {},
  } = useLocation<LocationProps>();

  const [initialized, setInitialized] = React.useState(false);

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const showDeviceTypeDetails = React.useCallback(nameAndVersion => {
    history.replace(getPathToDeviceType(nameAndVersion));
  }, [history]);

  const showSecurityGroupDetails = React.useCallback((securityGroup: SecurityGroup) => {
    window.open(getPathToGroup(securityGroup.getName()), "_blank", "noopener,noreferrer");
  }, []);

  React.useEffect(() => {

    if (!initialized) {

      if (deviceType) {
        dispatch(DeviceTypeWizardActions.cloneDeviceType(
          new DeviceTypeModelV3(deviceType), new DeviceTypeModelV2(deviceTypeV2)));
      }

      setInitialized(true);
    }

  }, [initialized, deviceType, deviceTypeV2, dispatch, setInitialized]);

  if (!initialized) {
    return null;
  }

  return (
    <DeviceTypeWizard
      className="createDeviceTypeWizard"
      snackbarId="create-device-type"
      cancel={cancel}
      showDeviceTypeDetails={showDeviceTypeDetails}
      showSecurityGroupDetails={showSecurityGroupDetails}
    />
  );
}

export default CreateDeviceTypeView;
