import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import useDigitalShadowClient from "../use-digital-shadow-client";
import { ETagHeaders } from "@network";

type SuccessResponse = ETagHeaders;

export interface UseUpdateDSLayoutProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  layoutName?: string;
  requestBodyJson?: string;
  requestEtag?: string;
}

export interface UseUpdateDSLayoutModel extends UseApiRequestModel<SuccessResponse> {
  etag: string;
  successMessage: string;
}

export interface UseUpdateDSLayoutActions extends UseApiRequestActions<SuccessResponse> {
  updateLayout: () => void;
}

type Props = UseUpdateDSLayoutProps;
type Model = UseUpdateDSLayoutModel;
type Actions = UseUpdateDSLayoutActions;
type Result = [ Model, Actions ];

export const useUpdateDSLayout = (props: Props): Result => {

  const {
    requestBodyJson = "",
    layoutName = "",
    requestEtag = "",
    defaultErrorMessage = "Failed to update shadow layout",
    ...otherProps
  } = props;

  const DigitalShadowClient = useDigitalShadowClient();

  const makeApiRequest = React.useCallback(() =>
      DigitalShadowClient.updateLayoutApi(layoutName, requestBodyJson, requestEtag),
    [DigitalShadowClient, layoutName, requestBodyJson, requestEtag]);

  const [{ successResponse, showSuccessView, ...baseModel },
    { refresh: updateLayout, ...baseActions }
  ] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Shadow Layout Updated" : "",
    [ showSuccessView ]);

  const etag = React.useMemo(() => {
    return successResponse?.etag || "";
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    etag,
    location,
    successMessage,
    showSuccessView
  }), [
    baseModel,
    etag,
    location,
    successMessage,
    showSuccessView
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    updateLayout,
    refresh: updateLayout,
  }), [
    baseActions,
    updateLayout,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useUpdateDSLayout;
