import React from "react";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useWorkloadManagerClient } from "@hooks";
import { SetWorkloadSecretRequest } from "@data";

type SuccessResponse = void;

export interface UseSetWorkloadServiceSecretProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  accountId: string;
  principal: string;
  secret: SetWorkloadSecretRequest;
}

export interface UseSetWorkloadServiceSecretModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseSetWorkloadServiceSecretActions {
  setWorkloadServiceSecret: () => void;
  reset: () => void;
}

type Props = UseSetWorkloadServiceSecretProps;
type Model = UseSetWorkloadServiceSecretModel;
type Actions = UseSetWorkloadServiceSecretActions;
type Result = [Model, Actions];

export const useSetWorkloadServiceSecret = (props: Props): Result => {

  const {
    name,
    version,
    accountId,
    principal,
    secret,
    defaultErrorMessage = "Failed to set workload service secret",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
      WorkloadManagerClient.setWorkloadServiceSecretApi(name, version, accountId, principal, JSON.stringify(secret)),
      [WorkloadManagerClient, name, version, accountId, principal, secret]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: setWorkloadServiceSecret, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Secret Set for Workload Service" : "",
    [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    setWorkloadServiceSecret,
    reset,
  }), [
    setWorkloadServiceSecret,
    reset,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useSetWorkloadServiceSecret;
