import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { getNameFilter } from "../selectors";
import { Namespace, NamespaceLifecycle } from "@data";
import { setNameFilter as updateNameFilter } from "../actions";
import Namespaces, {
  NamespacesActions as Actions,
  NamespacesModel as Model,
} from "@components/namespaces";
import NamespaceLifecycleManagementDialog from "@components/namespace-lifecycle-managament-dialog";
import useNamespaces from "@hooks/namespaces/use-namespaces";

export interface ContainerModel extends Model {
}

export interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const NamespacesContainer = (props: Props) => {

  const { showNamespaceDetails, ...otherProps } = props;

  const [openLifecycleDialog, setOpenLifecycleDialog] = React.useState(false);

  const [lifecycleState, setLifecycleState] = React.useState(NamespaceLifecycle.NONE);

  const [namespace, setNamespace] = React.useState<Namespace>(Namespace.EMPTY);

  const [{ namespaces: items, ...model }, { refresh, ...actions }] = useNamespaces();

  const publishNamespace = React.useCallback((ns: Namespace) => {
    setNamespace(ns);
    setOpenLifecycleDialog(true);
    setLifecycleState(NamespaceLifecycle.PRODUCTION);
  }, [setNamespace, setOpenLifecycleDialog, setLifecycleState]);

  const deprecateNamespace = React.useCallback((ns: Namespace) => {
    setNamespace(ns);
    setOpenLifecycleDialog(true);
    setLifecycleState(NamespaceLifecycle.DEPRECATED);
  }, [setNamespace, setOpenLifecycleDialog, setLifecycleState]);

  const closeDialog = React.useCallback(() => {
    setNamespace(Namespace.EMPTY);
    setOpenLifecycleDialog(false);
    setLifecycleState(NamespaceLifecycle.NONE);
  }, [setNamespace, setOpenLifecycleDialog, setLifecycleState]);

  const onUpdateSuccess = React.useCallback(() => {
    closeDialog();
    refresh();
  }, [closeDialog, refresh]);

  return (
    <Namespaces
      {...model}
      {...actions}
      {...otherProps}
      items={items}
      summaryViewLabel="Namespaces"
      showSummary={true}
      showCreateButton={true}
      refresh={refresh}
      showNamespaceDetails={showNamespaceDetails}
      publishNamespace={publishNamespace}
      deprecateNamespace={deprecateNamespace}
    >
      <NamespaceLifecycleManagementDialog
        open={openLifecycleDialog}
        namespace={namespace}
        state={lifecycleState}
        cancel={closeDialog}
        onSuccessMessageShown={onUpdateSuccess}
      />
    </Namespaces>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  nameFilter: getNameFilter(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setNameFilter: value => dispatch(updateNameFilter(value)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(NamespacesContainer);
