import React from "react";
import moment from "moment";
import classnames from "classnames";
import { formatLocalDateToUtc, isEmptyString, noop } from "@util";
import { useDataLakeQueryResults } from "@hooks";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import FileNameFilterAndRefreshButton from "../FileNameFilterAndRefreshButton";
import FileBrowser, { FileBrowserModel, FileBrowserActions } from "../FileBrowser";
import styles from "./styles";

export interface SingleDateFileBrowserModel extends FileBrowserModel {
}

export interface SingleDateFileBrowserActions extends FileBrowserActions {
}

type Model = SingleDateFileBrowserModel;
type Actions = SingleDateFileBrowserActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const SingleDateFileBrowser = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    dataSetAlias = "",
    accountId = "",
    breadcrumbs = [dataSetAlias],
    fileNameFilter = "",
    setBreadcrumbs = noop,
    setFileNameFilter = noop,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
    children,
    ...otherProps
  } = props;

  const selectedDate = React.useMemo<string>(() => {

    const currentBreadcrumbs = (Array.isArray(breadcrumbs) ? breadcrumbs.slice(1) : [])
      .filter(breadcrumb => !isEmptyString(breadcrumb));

    const pendingSelectedDate = currentBreadcrumbs.length < 3 ? "" : currentBreadcrumbs.join("-");

    return moment(pendingSelectedDate).isValid() ? pendingSelectedDate : "";

  }, [breadcrumbs]);

  const startDate = React.useMemo<Date | null>(() =>
      isEmptyString(selectedDate) ? null : moment(selectedDate).toDate(),
    [selectedDate]);

  const endDate = React.useMemo<Date | null>(() =>
      isEmptyString(selectedDate)
        ? null : moment(selectedDate).hour(23).minute(59).second(59).millisecond(999).toDate(),
    [selectedDate]);

  const startDateQueryParam = React.useMemo<string>(() =>
      startDate === null ? "" : formatLocalDateToUtc(startDate),
    [startDate]);

  const endDateQueryParam = React.useMemo<string>(() =>
      endDate === null ? "" : formatLocalDateToUtc(endDate),
    [endDate]);

  const showFileNameFilter = React.useMemo(() =>
    startDate !== null && endDate !== null, [startDate, endDate]);

  const [{ queryResults, showLoadingIndicator, ...model }, actions] = useDataLakeQueryResults({
    deferRequest: true,
    dataSetAlias,
    startDate: startDateQueryParam,
    endDate: endDateQueryParam,
    accountId,
    fileNameContains: fileNameFilter,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
  });

  const { refresh } = actions;

  const header = React.useMemo(() => !showFileNameFilter ? null : (
    <FileNameFilterAndRefreshButton
      className={classnames("fileNameFilterContainer", classes.fileNameFilterContainer)}
      fileNameFilterClassName={classnames("fileNameFilter", classes.fileNameFilter)}
      loading={showLoadingIndicator}
      disabled={showLoadingIndicator}
      fileNameFilter={fileNameFilter}
      setFileNameFilter={setFileNameFilter}
      refresh={refresh}
    />
  ), [classes, showFileNameFilter, fileNameFilter, showLoadingIndicator, setFileNameFilter, refresh]);

  // Trigger refresh whenever startDate and endDate have changed to a non-null value and/or
  // the file name filter is changed - as long as a valid date is selected
  React.useEffect(() => {
    if (!isEmptyString(startDateQueryParam) && !isEmptyString(endDateQueryParam)) {
      refresh();
    }
  }, [startDateQueryParam, endDateQueryParam, fileNameFilter, refresh]);

  return (
    <FileBrowser
      {...model}
      {...actions}
      {...otherProps}
      className={classnames("singleDateFileBrowser", className, classes.container)}
      dataLakeIndexItems={queryResults}
      header={header}
      showLoadingIndicator={showLoadingIndicator}
      dataSetAlias={dataSetAlias}
      accountId={accountId}
      breadcrumbs={breadcrumbs}
      startDate={startDate}
      endDate={endDate}
      fileNameFilter={fileNameFilter}
      setBreadcrumbs={setBreadcrumbs}
      setFileNameFilter={setFileNameFilter}
    >
      {children}
    </FileBrowser>
  );
});

export default SingleDateFileBrowser;
