import React from "react";
import classnames from "classnames";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ActionMenuItem, ActionsMenu } from "@components";
import { styles, tableBodyCell, tableBodyRow } from "../../credentials/credentialsTable/styles";
import { clickHandler, noop } from "@util";
import { DeviceDataModel } from "@data";

const TableBodyRow = withStyles(tableBodyRow)(TableRow);
const TableBodyCell = withStyles(tableBodyCell)(TableCell);

export interface Model {
  className?: string;
  device?: DeviceDataModel;
  clickable?: boolean;
  actions?: ActionMenuItem[];
}

export interface Actions {
  onClick?: () => void;
  onClickAction?: (action: ActionMenuItem) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DevicesTableBodyRow = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    device = DeviceDataModel.EMPTY,
    clickable = false,
    actions = [],
    onClick,
    onClickAction = noop,
  } = props;

  return (
    <TableBodyRow
      className={classnames("tableBodyRow", className, {
        [classes.clickable]: clickable,
      })}
      hover={clickable}
      onClick={clickHandler(onClick)}
    >
      <TableBodyCell className={classnames("name", classes.nameColumn)}>
        {device.getDeviceId()}
      </TableBodyCell>
      <TableBodyCell className={classnames("actions", classes.actionsColumn)}>
        <ActionsMenu
          buttonVariant="icon"
          actions={actions}
          onClickAction={onClickAction}
        />
      </TableBodyCell>
    </TableBodyRow>
  );
});

export default DevicesTableBodyRow;
