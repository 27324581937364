import { Record } from "immutable";
import { equalsIgnoreCase, getStringValue } from "@util";

export interface CertificateAuthoritySubjectAttributes {
  countryName: string;
  stateOrProvinceName: string;
  localityName: string;
  organizationName: string;
  organizationalUnitName: string;
  commonName: string;
}

export interface CertificateAuthorityAttributes {
  id: string;
  subject: CertificateAuthoritySubjectAttributes;
  certificate: string;
}

export class CertificateAuthority extends Record({
  id: "",
  subject: {
    countryName: "",
    stateOrProvinceName: "",
    localityName: "",
    organizationName: "",
    organizationalUnitName: "",
    commonName: "",
  },
  certificate: "",
}) implements CertificateAuthorityAttributes {

  public static EMPTY: CertificateAuthority = new CertificateAuthority();

  public readonly id: string;
  public readonly subject: CertificateAuthoritySubjectAttributes;
  public readonly certificate: string;

  public equals(other: CertificateAuthority): boolean {
    return equalsIgnoreCase(JSON.stringify(this.toJS()), JSON.stringify(other.toJS()));
  }

  public getId(): string {
    return getStringValue(this.id);
  }

  public getCommonName(): string {
    return getStringValue(this.subject.commonName);
  }
}

export default CertificateAuthority;
