import React from "react";
import classnames from "classnames";
import { noop, formEventHandler } from "@util";
import { clientView as styles, textField } from "./styles";
import { ErrorView, TagEditor, TextField } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

const DomainTextField = withStyles(textField)(TextField);
const ClientIdTextField = withStyles(textField)(TextField);
const ClientSecretTextField = withStyles(textField)(TextField);

export interface Model {
  showInvalidJsonError?: boolean;
  domain?: string;
  clientId?: string;
  clientSecret?: string;
  scope?: string[];
  children?: React.ReactNode;
}

export interface Actions {
  setDomain?: (domain: string) => void;
  setClientId?: (clientId: string) => void;
  setClientSecret?: (clientSecret: string) => void;
  updateScope?: (scope: string[]) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const ClientView = withStyles(styles)((props: Props) => {

  const {
    classes,
    showInvalidJsonError,
    domain = "",
    clientId = "",
    clientSecret = "",
    scope = [],
    children,
    setDomain,
    setClientId,
    setClientSecret,
    updateScope = noop,
  } = props;

  if (showInvalidJsonError) {
    return (
      <div className={classnames("nameView", classes.errorViewContainer)}>
        <ErrorView
          title="JSON Syntax Error"
          message="Visual editors cannot be used until you have fixed the JSON syntax error."
        />
      </div>
    );
  }

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  return (
    <div className={classnames("clientView", classes.container)}>
      <label className={classes.title}>
        Set Client Details
      </label>
      <DomainTextField
        className={classnames("domain", classes.textBox)}
        autoComplete="off"
        label="Domain"
        name="domain"
        value={domain}
        fullWidth={true}
        autoFocus={true}
        variant="outlined"
        margin="none"
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setDomain)}
      />
      <ClientIdTextField
        className={classnames("clientId", classes.textBox)}
        autoComplete="off"
        label="Client ID"
        name="clientId"
        value={clientId}
        fullWidth={true}
        autoFocus={false}
        variant="outlined"
        margin="none"
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setClientId)}
      />
      <ClientSecretTextField
        className={classnames("clientSecret", classes.textBox)}
        autoComplete="off"
        label="Client Secret"
        name="clientSecret"
        value={clientSecret}
        fullWidth={true}
        autoFocus={false}
        variant="outlined"
        margin="none"
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setClientSecret)}
      />
      <TagEditor
        tags={scope}
        setTags={updateScope}
        label="Add Scope Value (optional)"
        emptyValueLabel="No Scope Defined"
        helperText="Scopes to associate with this provider"
        className={classnames("scopeEditor", classes.scopeEditor)}
      />
      {children}
    </div>
  );
});

export default ClientView;
