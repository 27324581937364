import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "../../main/schemas";
import { PropertyEditorSelectors } from "../selectors/propertyEditor";
import { ConstraintsEditor, Model, Actions } from "../components/ConstraintsEditor";
import StringConstraintsEditor from "./StringConstraintsEditor";
import NumberConstraintsEditor from "./NumberConstraintsEditor";
import IntegerConstraintsEditor from "./IntegerConstraintsEditor";

interface ContainerModel extends Model {
  showStringConstraintsEditor?: boolean;
  showNumberConstraintsEditor?: boolean;
  showIntegerConstraintsEditor?: boolean;
}

const ConstraintsEditorContainer = (props: ContainerModel & Actions) => {

  const {
    showStringConstraintsEditor,
    showNumberConstraintsEditor,
    showIntegerConstraintsEditor,
    ...remainingProps
  } = props;

  return (
    <ConstraintsEditor {...remainingProps}>
      {showStringConstraintsEditor && <StringConstraintsEditor />}
      {showNumberConstraintsEditor && <NumberConstraintsEditor />}
      {showIntegerConstraintsEditor && <IntegerConstraintsEditor />}
    </ConstraintsEditor>
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
  visible: PropertyEditorSelectors.isConstraintsEditorVisible(state),
  showStringConstraintsEditor: PropertyEditorSelectors.isStringConstraintsVisible(state),
  showNumberConstraintsEditor: PropertyEditorSelectors.isNumberConstraintsVisible(state),
  showIntegerConstraintsEditor: PropertyEditorSelectors.isIntegerConstraintsVisible(state),
});

export default connect<ContainerModel, {}, ContainerModel>(
  mapStateToProps,
)(ConstraintsEditorContainer);
