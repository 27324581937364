import ModuleIcon from "@material-ui/icons/Create";
import { createStyles, StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const primaryColor = Color.MODULES;

export const PrimaryIcon = ModuleIcon;

export const templateIdTextField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "14px",
      fontWeight: 300,
      padding: "2px 0",
      margin: 0,
    },
    "& input": {
      fontSize: 14,
      fontWeight: 400,
      padding: "18.5px 14px",
      margin: 0,
    },
  },
};

export const textField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "10px",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& textarea": {
      fontSize: 14,
      fontWeight: 400,
      padding: 0,
      margin: 0,
    },
    "& input": {
      fontSize: 14,
      fontWeight: 400,
      padding: "18.5px 14px",
      margin: 0,
    },
  },
};

export const templateView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    errorViewContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 18,
      lineHeight: "21px",
      marginTop: 8,
    },
    formControl: {
      marginTop: 40,
      minWidth: 250,
    },
    messageTypeFormControl: {
      marginTop: 0,
      minWidth: 350,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    templateId: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    label: {
      marginTop: 25,
    },
    selectionBox: {
      marginTop: 25,
    },
    messageTypeContainer: {
      marginTop: 40,
      display: "flex",
      flexFlow: "row nowrap",
    },
    alert: {
      marginLeft: 30,
    },
    alertTitle: {
      fontSize: 14,
      marginTop: 2,
    },
    alertDescription: {
      fontSize: 14,
      lineHeight: "21px",
      marginTop: 10,
      marginBottom: 0,
      paddingLeft: 20,
    },
    showParamsButtonLabel: {
      color: "rgb(13, 60, 97)",
      fontWeight: "bold",
      fontSize: 14,
    },
    button: {
      float: "right",
      marginLeft: 15,
        "&:first-child": {
          marginLeft: 0,
        },
    }
  });

export const bodyView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    containerWithReqs: {
      width: "70%",
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    errorViewContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 18,
      lineHeight: "21px",
      marginTop: 8,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    label: {
      marginTop: 25,
    },
    textBox: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    htmlContainer: {
      flex: "1 0 350px",
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 25,
      width: "100%",
    },
    htmlEditor: {
      flex: "1 0 auto",
      marginTop: 20,
    },
    head: {
      display: "flex",
    },
    verifyMain: {
      marginTop: "25px",
      width: "100%"
    },
    verifyContainer: {
      width: "20%",
      flex: "1 0 auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      marginRight: "30px",
      flexDirection: "column",
    },
    verifySuccess: {
      color: "green",
      width: "14px",
      height: "14px",
      marginRight: "5px",
    },
    verifyError: {
      color: "red",
      width: "14px",
      height: "14px",
      marginRight: "5px",
    },
    verifyField: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: "4px",
      padding: "4px",
      marginBottom: "20px",
    },
    verifyBorderBottom: {
      borderBottom: "1.5px dotted rgba(0, 0, 0, 0.23)",
      padding: "4px",
    },
    verifySubject: {
      padding: "4px",
    },
    verifySubjectCenter: {
      padding: "4px",
      textAlign: "center",
    },
    verifyChecklist: {
      width: "100%",
    },
    verifyCode: {
      padding: "4px",
      margin: "10px 10px 5px 10px",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      background: "rgba(0, 0, 0, 0.015)",
      textAlign: "center",
      borderRadius: "5px",
      wordBreak: "break-all",
    }
  });

export const applicationView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      marginTop: 8,
    },
    subtitle: {
      color: Color.TEXT,
      fontWeight: 300,
      marginTop: 15,
    },
    applications: {
    },
  });

export const reviewView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    propertyContainer: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      justifyContent: "stretch",
      marginTop: 25,
      textAlign: "left",
    },
    propertyName: {
      flex: "0 0 125px",
      alignSelf: "flex-start",
      justifySelf: "flex-start",
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      fontSize: 16,
    },
    propertyValue: {
      flex: "1 1 auto",
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "monospace",
      fontSize: 14,
      marginLeft: 15,
      fontWeight: 500,
    },
    emailTemplateContainer: {
      flex: "0 0 auto",
    },
  });

export const styles = () =>
  createStyles({
    container: {
    },
  });

export default styles;
