import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  ContinueButton,
  RefreshButton,
  Search,
  withQuantity,
} from "@components";
import { PrimaryIcon, styles } from "./styles";
import { clickHandler, isEmptyString } from "@util";

export const Summary = withQuantity({
  other: "device enrollment requests",
  one: "device enrollment batch",
});

export interface Model {
  searchQuery?: string;
  loading?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  updateSearchQuery?: (searchQuery: string, previousSearchQuery?: string) => void;
  refresh?: () => void;
  enrollDevice?: () => void;
  validateDevice?: () => void;
  batchDeviceEnrollment?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DeviceEnrollment = withStyles(styles)((props: Props) => {

  const {
    classes,
    searchQuery = "",
    loading,
    children,
    updateSearchQuery,
    refresh,
    enrollDevice,
    validateDevice,
  } = props;

  return (
    <div className={classnames("deviceEnrollment", classes.container)}>
        <Summary label="Enter batch id to view device enrollment status" icon={PrimaryIcon}>
          <RefreshButton loading={loading} refresh={refresh} disabled={isEmptyString(searchQuery)}/>
          <ContinueButton
            className={classnames("validateDeviceButton", classes.validateDeviceButton)}
            label="Validate Device Enrollment"
            onClick={clickHandler(validateDevice)}
            primary={false}
          />
          <ContinueButton
            className={classnames("enrollDeviceButton", classes.enrollDeviceButton)}
            label="Enroll Device"
            onClick={clickHandler(enrollDevice)}
            primary={true}
          />
        </Summary>
      <Search
        className={classes.search}
        hint="Enter Batch ID"
        value={searchQuery}
        updateSearchQuery={updateSearchQuery}
      />
      {children}
    </div>
  );
});

export default DeviceEnrollment;
