import React from "react";
import classnames from "classnames";
import CircularProgress from "@components/circular-progress";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";

export const LoadingView = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  label?: string,
  color?: string,
  size?: number | string,
}) => {

  const {
    classes,
    className,
    label = "Loading...",
    color = "inherit",
    size = 50,
  } = props;

  return (
    <div className={classnames("loadingView", className, classes.container)}>
      <CircularProgress
        className={classnames("progress", classes.progress)}
        style={{ color }}
        size={size}
      />
      <label className={classnames("label", classes.label)}>{label}</label>
    </div>
  );
});

export default LoadingView;
