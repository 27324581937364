import { isEmptyString } from "@util";
import { EmailTemplateAttributes } from "@data/EmailTemplate";
import {
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments,
} from "./helpers";
import RestClientError from "./RestClientError";
import { RegionalEmailTemplateMessageTypeAttrs } from "@data/RegionalEmailTemplateMessageType";
import RegionalEmailTemplateParameterInfo from "@data/RegionalEmailTemplateParameterInfo";

const EMAIL_TEMPLATE_API = process.env.REACT_APP_EMAIL_TEMPLATE_API || "";
const REACT_APP_REGIONAL_API = process.env.REACT_APP_REGIONAL_API || "";

if (isEmptyString(EMAIL_TEMPLATE_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_USER_IDM_ADMIN_API");
}

export type EmailRequirementsResponse = {
  [key: string]: RegionalEmailTemplateMessageTypeAttrs;
};

export type EmailParametersResponse = {
  [key: string]: RegionalEmailTemplateParameterInfo;
};

export interface GetEmailRequirementsResponse {
  messageTypes: EmailRequirementsResponse;
  parameters: EmailParametersResponse;
}

export interface GetEmailLanguagesResponse {
  languages: string[];
}

export const getEmailLanguages = (authToken: string): Promise<GetEmailLanguagesResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${REACT_APP_REGIONAL_API}/security/management/v1/user-interactions/email-communication/templates/supported/languages`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Email Languages failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getEmailRequirements = (authToken: string): Promise<GetEmailRequirementsResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${REACT_APP_REGIONAL_API}/security/management/v1/user-interactions/email-communication/templates/supported/parameters`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Email Requirements failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getEmailTemplates = (authToken: string,
                                  accountId: string): Promise<EmailTemplateAttributes[]> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Account Id", accountId],
    ]));

  const makeRequest = () => {

    const url = `${EMAIL_TEMPLATE_API}/v1/idm/user-idm/emailtemplates/accounts/${accountId}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Email Templates failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage)
      .catch((errorResponse: RestClientError) => {

        const { status = 500 } = errorResponse || {};

        // Email Template Service treats no results like an error when it should be an empty list
        if (status === 404) {
          return Promise.resolve([]);
        }

        return Promise.reject(errorResponse);
      });
  };

  return validate().then(makeRequest);
};

export const validateEmailTemplate = (authToken: string, json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
      .then(() => withRequiredArguments([
        ["Email Template", json],
      ]));

  const makeRequest = () => {

    const url = REACT_APP_REGIONAL_API +
      "/security/management/v1/user-interactions/email-communication/templates/validate";

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to validate email Template";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const createEmailTemplate = (authToken: string,
                                    accountId: string,
                                    templateId: string,
                                    json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Account Id", accountId,
        "Template Id", templateId,
        "Email Template", json],
    ]));

  const makeRequest = () => {

    const url = `${EMAIL_TEMPLATE_API}/v1/idm/user-idm/emailtemplates/accounts/${accountId}/${templateId}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to create email Template";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getEmailTemplate = (authToken: string,
                                 accountId: string,
                                 templateId: string): Promise<EmailTemplateAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Account Id", accountId],
      ["Template Id", templateId],
    ]));

  const makeRequest = () => {

    const url = `${EMAIL_TEMPLATE_API}/v1/idm/user-idm/emailtemplates/accounts/${accountId}/${templateId}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Email Template failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteEmailTemplate = (authToken: string,
                                    accountId: string,
                                    templateId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Account Id", accountId],
      ["Template Id", templateId],
    ]));

  const makeRequest = () => {

    const url = `${EMAIL_TEMPLATE_API}/v1/idm/user-idm/emailtemplates/accounts/${accountId}/${templateId}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to delete email template [${templateId}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const editEmailTemplate = (authToken: string,
                                  accountId: string,
                                  templateId: string,
                                  json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Account Id", accountId,
      "Template Id", templateId,
      "Email Template", json],
    ]));

  const makeRequest = () => {

    const url = `${EMAIL_TEMPLATE_API}/v1/idm/user-idm/emailtemplates/accounts/${accountId}/${templateId}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to edit email Template";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
