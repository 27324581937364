import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getPathToDevice } from "@modules/deviceDetails/helpers";
import { reset } from "@modules/deviceEnrollmentDetails/actions";
import DeviceEnrollmentDetails from "./containers/DeviceEnrollmentDetails";
import { DeviceEnrollment } from "@data";
import { isEmptyString } from "@util";
import { getPathToDeviceValidationDetails } from "@modules/deviceValidationDetails/helpers";

export function DeviceEnrollmentDetailsView() {

  const history = useHistory();

  const location = useLocation<any>();

  const {
    state: {
      batchId = "",
      deviceRequests = [],
    } = {}
  } =  location;

  const showDeviceDetails = React.useCallback((device: DeviceEnrollment) => {
    if (!isEmptyString(device.getDeviceId())) {
      history.push(getPathToDevice(device.getDeviceId()), {
        deviceRequests,
      });
    } else {
      reset();
    }

  }, [history, reset]);

  const showDeviceValidationDetails = React.useCallback((device: DeviceEnrollment) => {
    if (!isEmptyString(device.getDeviceId())) {
      history.push(getPathToDeviceValidationDetails(device.getDeviceId()));
    } else {
      reset();
    }

  }, [history, reset]);

  return (
    <DeviceEnrollmentDetails
      batchIdFromLocationState={batchId}
      showDeviceDetails={showDeviceDetails}
      showDeviceValidationDetails={showDeviceValidationDetails}
    />
  );
}

export default DeviceEnrollmentDetailsView;
