import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";

export enum UiPackageColumn {
  NONE = "",
  FILES = "Files"
}

export interface UiPackageSchema extends BasePortalModuleSchema {
  files: string[];
  sortByColumn: UiPackageColumn;
  sortOrderAscending: boolean;
  showDeleteUiPackageDialog: boolean;
  showUploadUiPackageDialog: boolean;
}

export const DEFAULT_STATE: UiPackageSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  files: [],
  sortByColumn: UiPackageColumn.NONE,
  sortOrderAscending: true,
  showDeleteUiPackageDialog: false,
  showUploadUiPackageDialog: false,
});

export type UiPackageActionTypes = PortalModuleActionTypes<UiPackageSchema> & {
  FETCH_UI_PACKAGE_REQUEST: string;
  FETCH_UI_PACKAGE_SUCCESS: string;
  FETCH_UI_PACKAGE_FAILED: string;
  UPLOAD_UI_PACKAGE_REQUEST: string;
  UPLOAD_UI_PACKAGE_SUCCESS: string;
  UPLOAD_UI_PACKAGE_FAILED: string;
  DELETE_UI_PACKAGE_REQUEST: string;
  DELETE_UI_PACKAGE_SUCCESS: string;
  DELETE_UI_PACKAGE_FAILED: string;
};

export const ACTION_TYPES: UiPackageActionTypes = {
  ...createPortalModuleActionTypes<UiPackageSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    FETCH_UI_PACKAGE_REQUEST: `${MODULE_ID}/fetch_ui_package_request`,
    FETCH_UI_PACKAGE_SUCCESS: `${MODULE_ID}/fetch_ui_package_success`,
    FETCH_UI_PACKAGE_FAILED: `${MODULE_ID}/fetch_ui_package_failed`,
    UPLOAD_UI_PACKAGE_REQUEST: `${MODULE_ID}/upload_ui_package_request`,
    UPLOAD_UI_PACKAGE_SUCCESS: `${MODULE_ID}/upload_ui_package_success`,
    UPLOAD_UI_PACKAGE_FAILED: `${MODULE_ID}/upload_ui_package_failed`,
    DELETE_UI_PACKAGE_REQUEST: `${MODULE_ID}/delete_ui_package_request`,
    DELETE_UI_PACKAGE_SUCCESS: `${MODULE_ID}/delete_ui_package_success`,
    DELETE_UI_PACKAGE_FAILED: `${MODULE_ID}/delete_ui_package_failed`,
  },
};

export const UiPackagesReducer = combineReducers<UiPackageSchema>(
  createPortalModuleReducers<UiPackageSchema>(ACTION_TYPES, DEFAULT_STATE));

export default UiPackagesReducer;
