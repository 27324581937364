import { TriggerType } from "@data";
import { MODULE_PATH as BASE_URL, RouteParams } from "@modules/dataWorkloadDetails/constants";

export const MODULE_ID = "add_trigger";

const BASE_ADD_TRIGGER_MODULE_PATH = `${BASE_URL}/triggers/add`;
export const ADD_SCHEDULE_TRIGGER_MODULE_PATH =
  `${BASE_ADD_TRIGGER_MODULE_PATH}/${TriggerType.SCHEDULE}`;
export const ADD_DATA_LAKE_MODULE_PATH =
  `${BASE_ADD_TRIGGER_MODULE_PATH}/${TriggerType.DATA_LAKE}`;
export const ADD_WORKLOAD_COMPLETE_MODULE_PATH =
  `${BASE_ADD_TRIGGER_MODULE_PATH}/${TriggerType.WORKLOAD_COMPLETE}`;
export const ADD_TRIGGER_MODULE_PATHS = [
  ADD_SCHEDULE_TRIGGER_MODULE_PATH,
  ADD_DATA_LAKE_MODULE_PATH,
  ADD_WORKLOAD_COMPLETE_MODULE_PATH,
];

const BASE_EDIT_TRIGGER_MODULE_PATH = `${BASE_URL}/triggers/edit`;
export const EDIT_SCHEDULE_TRIGGER_MODULE_PATH =
  `${BASE_EDIT_TRIGGER_MODULE_PATH}/${TriggerType.SCHEDULE}`;
export const EDIT_DATA_LAKE_MODULE_PATH =
  `${BASE_EDIT_TRIGGER_MODULE_PATH}/${TriggerType.DATA_LAKE}`;
export const EDIT_WORKLOAD_COMPLETE_MODULE_PATH =
  `${BASE_EDIT_TRIGGER_MODULE_PATH}/${TriggerType.WORKLOAD_COMPLETE}`;
export const EDIT_TRIGGER_MODULE_PATHS = [
  EDIT_SCHEDULE_TRIGGER_MODULE_PATH,
  EDIT_DATA_LAKE_MODULE_PATH,
  EDIT_WORKLOAD_COMPLETE_MODULE_PATH,
];

export { BASE_URL, RouteParams };
