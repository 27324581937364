import React from "react";
import classnames from "classnames";
import { equalsIgnoreCase, isEmptyString, noop } from "@util";
import { JsonEditor } from "@components/json-editor";
import { ToggleDtsModelVersionSwitch } from "@components/toggle-dts-model-version-switch";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { jsonEditorView as styles } from "./styles";

const DEVICE_TYPE_WIZARD_JSON_API_VERSION_PREVIEW_TOGGLE_DISABLED =
  !equalsIgnoreCase("true", process.env.REACT_APP_DEVICE_TYPE_WIZARD_JSON_API_VERSION_PREVIEW_TOGGLE_ENABLED);

export interface Model {
  json?: string;
  readOnly?: boolean;
  toggleApiSwitchTitle?: string;
  toggleApiSwitchHelperText?: string;
  isToggleApiSwitchVisible?: boolean;
  isToggleApiSwitchDisabled?: boolean;
  isHistoricalDataViewSelected?: boolean;
  historicalButtonLabel?: string;
  regionalButtonLabel?: string;
}

export interface Actions {
  setJson?: (newValue: string) => void;
  setShowHistoricalDataView?: (value: boolean) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const JsonEditorView = withStyles(styles)((props: Props) => {

  const {
    classes,
    json = "{}",
    readOnly = false,
    toggleApiSwitchTitle = "",
    toggleApiSwitchHelperText =
      `This device type uses the latest API version. Select the historical view mode to preview this 
      data using the legacy API model.`,
    isToggleApiSwitchVisible,
    isToggleApiSwitchDisabled,
    isHistoricalDataViewSelected,
    setJson,
    setShowHistoricalDataView = noop,
    children
  } = props;

  const showToggleApiSwitch = React.useMemo(() =>
    !DEVICE_TYPE_WIZARD_JSON_API_VERSION_PREVIEW_TOGGLE_DISABLED && isToggleApiSwitchVisible,
    [isToggleApiSwitchVisible]);

  return (
    <div className={classnames("jsonEditorView", classes.container)}>
      {showToggleApiSwitch && (
        <React.Fragment>
          {!isEmptyString(toggleApiSwitchTitle) && (
            <label className={classnames("toggleApiSwitchTitle", classes.title)}>
              {toggleApiSwitchTitle}
            </label>
          )}
          {!isEmptyString(toggleApiSwitchHelperText) && (
            <label className={classnames("toggleApiSwitchHelperText", classes.helperText)}>
              {toggleApiSwitchHelperText}
            </label>
          )}
          <ToggleDtsModelVersionSwitch
            className={classnames("toggleDtsApiVersion", classes.toggleApiSwitch)}
            disabled={isToggleApiSwitchDisabled}
            regionalApiSelected={!isHistoricalDataViewSelected}
            showHistoricalApi={() => setShowHistoricalDataView(true)}
            showRegionalApi={() => setShowHistoricalDataView(false)}
          />
        </React.Fragment>
      )}
      <JsonEditor
        className={classnames("jsonEditor", classes.jsonEditor, {
          [classes.extraTopMargin]: !showToggleApiSwitch,
        })}
        name="deviceTypeWizardJsonViewEditor"
        json={json}
        readOnly={readOnly}
        setJson={setJson}
      />
      {children}
    </div>
  );
});

export default JsonEditorView;
