import React from "react";
import ReleaseNotesTabs from "./components/ReleaseNotesTabs";
import useSelectedTab from "@modules/releaseNotes/useSelectedTab";

export function ReleaseNotesTabsView() {

  const selectedTab = useSelectedTab();

  return (
    <ReleaseNotesTabs selectedTab={selectedTab} />
  );
}

export default ReleaseNotesTabsView;
