import { isEmptyString } from "@util";
import { createSelector } from "reselect";
import { ApproveRequestSchema, DEFAULT_STATE } from "./reducers";
import { createSelectors, Selector } from "@base/createSelectors";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";
import { ApproveRequest, ApproveRequestAction, ApproveRequestAttributes } from "@data";

export const {
  approvalRequestId: getApprovalRequestId,
  dataSetAlias: getDataSetAlias,
  comments: getComments,
  open: isDialogOpen,
  action: getApproveRequestAction,
  approveRequest: getApproveRequestAttributes,
  getErrorMessage,
  getSuccessMessage,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  getAccessToken,
} = createSelectors<ApproveRequestSchema>(moduleId, schemaKey, DEFAULT_STATE);

export const getApproveRequest: Selector<ApproveRequest> = createSelector(
  getApproveRequestAttributes, (attrs: ApproveRequestAttributes) => {

    return new ApproveRequest(attrs);
  });

export const isApprovalRequestIdValid: Selector<boolean> = createSelector(
  getApprovalRequestId, approvalRequestId => !isEmptyString(approvalRequestId));

export const isApproveRequestAction: Selector<boolean> = createSelector(
  getApproveRequestAction, (action: ApproveRequestAction) => {
    return action === ApproveRequestAction.APPROVE_REQUEST;
  });

export const getDialogTitle: Selector<string> = createSelector(
  isApproveRequestAction, (approveRequestAction: boolean) => {
    return approveRequestAction ? "Approve Request" : "Reject Request";
  });

export const getClassName: Selector<string> = createSelector(
  isApproveRequestAction, (approveRequestAction: boolean) => {
    return approveRequestAction ? "approveRequestDialog" : "rejectRequestDialog";
  });

export const getContinueButtonLabel: Selector<string> = createSelector(
  isApproveRequestAction, (approveRequestAction: boolean) => {
    return approveRequestAction ? "Approve" : "Reject";
  });
