import React from "react";
import classnames from "classnames";
import Paper from "@material-ui/core/Paper";
import Label from "@material-ui/core/Typography";
import TextField from "@components/text-field";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { formEventHandler, noop, scrollToTop } from "@util";
import { userInfo as styles } from "./styles";

// Only show verbose loading message if request has been in progress for this long
const DELAYED_LOADING_MESSAGE_DELAY_MS = 1000;

export interface Model {
  userId?: string;
  userIdErrorMessage?: string;
  showLoadingIndicator?: boolean;
}

export interface Actions {
  setUserId?: (userId: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const UserInfo = withStyles(styles)((props: Props) => {

  const {
    classes,
    userId = "",
    userIdErrorMessage = "",
    showLoadingIndicator = false,
    setUserId = noop,
    children,
  } = props;

  const [showDelayedLoadingMessage, setShowDelayedLoadingMessage] = React.useState(false);

  const formHelperTextProps = {
    className: classnames("formHelperText", classes.formHelperText),
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const inputProps = {
    className: classnames("textField", classes.textField),
    spellCheck: false,
  };

  // Make sure the user sees the delayed loading message when it appears
  React.useLayoutEffect(() => scrollToTop(), [showDelayedLoadingMessage]);

  React.useEffect(() => {

    if (!showLoadingIndicator) {
      setShowDelayedLoadingMessage(false);
      return () => noop;
    }

    // Wait 5 seconds before showing delayed loading message - in case of slow requests
    // to prevent user from accidentally thinking the request is hung, when it can sometimes
    // take up to 60 seconds for the user to be invited; othertimes it can take 2 seconds.
    const timer = setTimeout(() =>
      setShowDelayedLoadingMessage(true), DELAYED_LOADING_MESSAGE_DELAY_MS);

    return () => clearTimeout(timer);

  }, [showLoadingIndicator, setShowDelayedLoadingMessage]);

  return (
    <div className={classnames("userInfo", classes.container)}>
      {showLoadingIndicator && (
        <React.Fragment>
          {showDelayedLoadingMessage && (
            <Paper
              className={classnames("loadingMessage", classes.loadingMessage)}
              elevation={5}
            >
              <Label className={classes.loadingMessageLabel} variant="h1" color="inherit">
                Inviting User... Please wait...
              </Label>
              <Label className={classes.loadingMessageLabel} variant="h4" color="inherit">
                This process can sometimes take up to 60 seconds
              </Label>
              <Label className={classes.loadingMessageLabel} variant="subtitle1" color="inherit">
                Please avoid reloading the page while this request is in progress to ensure
                that you receive confirmation that the user was invited.
              </Label>
            </Paper>
          )}
          <div className={classnames("modal", classes.modal)} />
        </React.Fragment>
      )}
      <TextField
        className={classnames("userId", classes.textField, classes.userId)}
        type="text"
        variant="outlined"
        autoComplete="off"
        margin="none"
        fullWidth={true}
        autoFocus={true}
        label="User ID"
        name="userId"
        value={userId}
        inputProps={inputProps}
        helperText={userIdErrorMessage}
        disabled={showLoadingIndicator}
        placeholder="User email address"
        error={userIdErrorMessage.length > 0}
        onChange={formEventHandler(setUserId)}
        FormHelperTextProps={formHelperTextProps}
        InputLabelProps={inputLabelProps}
      />
      {children}
    </div>
  );
});

export default UserInfo;
