export enum ServicesListColumn {
  NONE = "",
  NAME = "Service",
  LAST_UPDATED = "Last Updated",
  ID = "ID",
  CREATED = "Created",
  STATE = "State",
  MFA = "MFA",
}

export default ServicesListColumn;
