import React from "react";
import { useHistory } from "react-router-dom";
import { getPathToFederationProvider } from "@modules/federationProviderDetails/helpers";
import FederationProviderWizard from "./containers/FederationProviderWizard";

export function CreateFederationProviderView() {

  const history = useHistory();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onSuccess = React.useCallback(state => {
    history.replace(getPathToFederationProvider(state.getProviderId()));
  }, [history]);

  return (
    <FederationProviderWizard
      snackbarId="create-federation-provider"
      className="createFederationProviderWizard"
      cancel={cancel}
      onSuccess={onSuccess}
    />
  );
}

export default CreateFederationProviderView;
