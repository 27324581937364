import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { accessRequestsView as styles } from "./styles";
import SortedSearchResultsList, {
  sortAndFilterResults,
  SortedSearchResultsListActions,
  SortedSearchResultsListModel
} from "@components/sorted-search-results-list";
import { DataSetAccessRequest } from "@data";
import DataSetAccessRequestListItem, {
  DataSetAccessRequestColumn
} from "@modules/dataSetDetails/components/DataSetAccessRequestListItem";
import { AccessDeniedView, ActionMenuItem } from "@components";
import { noop, Primitive } from "@util";
import RevokeAccessDialog from "@components/revoke-access-dialog";

export enum AccessRequestAction {
  REVOKE = "revoke_data_set_access",
}

export interface AccessRequestsViewModel
  extends SortedSearchResultsListModel<DataSetAccessRequest, DataSetAccessRequestColumn> {
  accessRequests: DataSetAccessRequest[];
  showAccessDenied: boolean;
}

export interface AccessRequestsViewActions
  extends SortedSearchResultsListActions<DataSetAccessRequest, DataSetAccessRequestColumn> {
}

type Props = WithStyles<typeof styles> & AccessRequestsViewModel & AccessRequestsViewActions;

export const AccessRequestsView = withStyles(styles)((props: Props) => {

  const {
    accessRequests,
    showAccessDenied,
    onClickAction = noop,
    refresh = noop,
  } = props;

  const [dataAccessRequest, setDataAccessRequest] = React.useState(DataSetAccessRequest.EMPTY);
  const [openRevokeDialog, setOpenRevokeDialog] = React.useState(false);
  const [sortByColumn, setSortByColumn] = React.useState(DataSetAccessRequestColumn.NONE);
  const [orderAscending, setOrderAscending] = React.useState(true);

  const closeRevokeDialog = React.useCallback(() => {
    return setOpenRevokeDialog(false);
  }, [setOpenRevokeDialog]);

  const revokeAccess = React.useCallback((accessRequest: DataSetAccessRequest) => {
    setDataAccessRequest(accessRequest);
    setOpenRevokeDialog(true);
  }, [setDataAccessRequest, setOpenRevokeDialog]);

  const onRevokeAccessSuccess = React.useCallback(() => {
    closeRevokeDialog();
    refresh();
  }, [closeRevokeDialog, refresh]);

  const columns: DataSetAccessRequestColumn[] = [
    DataSetAccessRequestColumn.NAME,
    DataSetAccessRequestColumn.PRINCIPAL_TYPE,
    DataSetAccessRequestColumn.ACCOUNT,
    DataSetAccessRequestColumn.TYPE,
  ];

  const REVOKE_ACCESS_ACTION: ActionMenuItem = Object.freeze({
    id: AccessRequestAction.REVOKE,
    name: "Revoke Access",
  });

  const DEFAULT_ACTIONS: ActionMenuItem[] = Object.freeze([
    REVOKE_ACCESS_ACTION,
  ]) as ActionMenuItem[];

  const onActionClicked = React.useCallback((accessRequest: DataSetAccessRequest, action: ActionMenuItem) => {
    switch (action.id) {
      case AccessRequestAction.REVOKE:
        return revokeAccess(accessRequest);
      default:
        return onClickAction(accessRequest, action);
    }
  }, [
    revokeAccess,
    onClickAction,
  ]);

  const DEFAULT_MAP_ITEM_TO_ACTION = (accessRequest: DataSetAccessRequest, action: ActionMenuItem) => {
    switch (action.id) {
      case AccessRequestAction.REVOKE:
        return {
          ...action,
          disabled: accessRequest.revokeAccessDisabled(),
        };
      default:
        return action;
    }
  };

  const mapRequestToColumnValue = (item: DataSetAccessRequest, column: DataSetAccessRequestColumn): Primitive => {
    switch (column) {
      case DataSetAccessRequestColumn.NAME:
        return item.getPrincipalIdentifier();
      case DataSetAccessRequestColumn.ACCOUNT:
        return item.getAccountId();
      case DataSetAccessRequestColumn.TYPE:
        return item.getRequestType();
      case DataSetAccessRequestColumn.PRINCIPAL_TYPE:
        return item.getPrincipalType();
      default:
        return "";
    }
  };

  const sortedItems = sortAndFilterResults({
    items: accessRequests,
    sortByColumn,
    sortOrderAscending: orderAscending,
    mapItemToColumnValue: mapRequestToColumnValue,
  });

  return (
    showAccessDenied ?
      <AccessDeniedView/> : (
        <React.Fragment>
          <SortedSearchResultsList
            className="accessRequestsView"
            columns={columns}
            sortByColumn={sortByColumn}
            setSortByColumn={(column: DataSetAccessRequestColumn) => setSortByColumn(column)}
            sortOrderAscending={orderAscending}
            setSortOrderAscending={setOrderAscending}
            items={sortedItems}
            renderItem={(accessRequest: DataSetAccessRequest, column: DataSetAccessRequestColumn) => (
              <DataSetAccessRequestListItem
                accessRequest={accessRequest}
                column={column}
              />
            )}
            actions={DEFAULT_ACTIONS}
            onClickAction={onActionClicked}
            mapItemToAction={DEFAULT_MAP_ITEM_TO_ACTION}
          />
          <RevokeAccessDialog
            open={openRevokeDialog}
            cancel={closeRevokeDialog}
            dataAccessRequest={dataAccessRequest}
            onSuccessMessageShown={onRevokeAccessSuccess}
          />
        </React.Fragment>
      )
  );
});

export default AccessRequestsView;
