import React from "react";
import { noop, scrollToTop } from "@util";

export interface UseWizardStepsProps<Step> {
  steps?: Step[];
  disabledSteps?: Step[];
  currentStep?: Step;
  showLoadingIndicator?: boolean;
  showSuccessIndicator?: boolean;
  saving?: boolean;
  onChangeCurrentStep?: (step: Step) => void;
}

export interface UseWizardStepsModel<Step> {
  steps: Step[];
  disabledSteps: Step[];
  completedSteps: Step[];
  currentStep: Step;
  firstStepSelected: boolean;
  lastStepSelected: boolean;
  previousStepButtonDisabled: boolean;
  nextStepButtonDisabled: boolean;
}

export interface UseWizardStepsActions<Step> {
  setCurrentStep: (step: Step) => void;
  previousStep: () => void;
  nextStep: () => void;
}

type Props<Step> = UseWizardStepsProps<Step>;
type Model<Step> = UseWizardStepsModel<Step>;
type Actions<Step> = UseWizardStepsActions<Step>;
type Result<Step> = [Model<Step>, Actions<Step>];

export const useWizardSteps = <Step, >(props: Props<Step>): Result<Step> => {

  const {
    steps = [],
    disabledSteps = [],
    currentStep: initialCurrentStep = steps[0],
    showLoadingIndicator,
    showSuccessIndicator,
    saving = showLoadingIndicator || showSuccessIndicator,
    onChangeCurrentStep = noop,
  } = props;

  const [currentStep, updateCurrentStep] = React.useState<Step>(initialCurrentStep);

  const currentStepIndex = React.useMemo(() => steps.indexOf(currentStep), [steps, currentStep]);

  const firstStep = React.useMemo(() => steps[0], [steps]);

  const firstStepSelected = React.useMemo(() => currentStep === firstStep, [currentStep, firstStep]);

  const lastStep = React.useMemo(() => steps.slice().pop(), [steps]);

  const lastStepSelected = React.useMemo(() => currentStep === lastStep, [currentStep, lastStep]);

  const previousWizardStep = React.useMemo(() => steps[currentStepIndex - 1], [steps, currentStepIndex]);

  const nextWizardStep = React.useMemo(() => steps[currentStepIndex + 1], [steps, currentStepIndex]);

  const completedSteps = React.useMemo(() =>
    firstStepSelected ? [] : (saving ? steps :
      (steps
        .slice(0, currentStepIndex)
        .filter(step => disabledSteps.indexOf(step) === -1))),
    [firstStepSelected, steps, currentStepIndex, disabledSteps, saving]);

  const previousStepButtonDisabled = React.useMemo(() =>
    firstStepSelected || !previousWizardStep || disabledSteps.indexOf(previousWizardStep) >= 0,
    [firstStepSelected, previousWizardStep, disabledSteps]);

  const nextStepButtonDisabled = React.useMemo(() =>
    lastStepSelected || !nextWizardStep || disabledSteps.indexOf(nextWizardStep) >= 0,
    [lastStepSelected, nextWizardStep, disabledSteps]);

  const setCurrentStep = React.useCallback((step: Step) => {
    if (step && disabledSteps.indexOf(step) === -1) {
      updateCurrentStep(step);
    }
  }, [disabledSteps, updateCurrentStep]);

  const previousStep = React.useCallback(() => {
    if (!previousStepButtonDisabled) {
      setCurrentStep(previousWizardStep);
    }
  }, [previousStepButtonDisabled, setCurrentStep, previousWizardStep]);

  const nextStep = React.useCallback(() => {
    if (!nextStepButtonDisabled) {
      setCurrentStep(nextWizardStep);
    }
  }, [nextStepButtonDisabled, setCurrentStep, nextWizardStep]);

  const model = React.useMemo<Model<Step>>(() => ({
    steps,
    disabledSteps,
    completedSteps,
    currentStep,
    firstStepSelected,
    lastStepSelected,
    previousStepButtonDisabled,
    nextStepButtonDisabled,
  }), [
    steps,
    disabledSteps,
    completedSteps,
    currentStep,
    firstStepSelected,
    lastStepSelected,
    previousStepButtonDisabled,
    nextStepButtonDisabled,
  ]);

  const actions = React.useMemo<Actions<Step>>(() => ({
    setCurrentStep,
    previousStep,
    nextStep,
  }), [
    setCurrentStep,
    previousStep,
    nextStep,
  ]);

  // As the user moves through the wizard, make sure step transitions bring them to the
  // top of the wizard in case there is a lot of content in each step.
  React.useLayoutEffect(() => scrollToTop(), [currentStep]);

  // If the parent is maintaining the current step state instead, make sure ours stays updated
  React.useEffect(() => {
    updateCurrentStep(initialCurrentStep);
  }, [initialCurrentStep, updateCurrentStep]);

  // Similarly, if the parent wants to know the current step without having to maintain all the
  // business logic surrounding the changing of the current step, call the onChange handler
  // whenever our local state holding the current step is updated
  React.useEffect(() => {
    onChangeCurrentStep(currentStep);
  }, [currentStep, onChangeCurrentStep]);

  return React.useMemo<Result<Step>>(() => [model, actions], [model, actions]);
};

export default useWizardSteps;
