import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { Application } from "@data";
import { ApplicationOperations } from "@components";
import { isEmptyString } from "@util";
import classnames from "classnames";
import { useApplicationActionManagement } from "@hooks";

export interface ApplicationOperationDialogModel extends PortalModuleDialogModel<Application> {
  dialogClassName?: string;
  applicationId?: string;
  operation?: ApplicationOperations;
}

export interface ApplicationOperationDialogActions extends PortalModuleDialogActions<Application> {
}

type Props = WithStyles< typeof styles> &
  ApplicationOperationDialogModel & ApplicationOperationDialogActions;

export const ApplicationOperationDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    dialogClassName,
    applicationId = "",
    operation = ApplicationOperations.NONE,
    ...otherProps
  } = props;

  if (isEmptyString(applicationId) || operation === ApplicationOperations.NONE) {
    return null;
  }

  const isDelete = React.useMemo(() => operation === ApplicationOperations.DELETE ,
    [operation]);

  const title = React.useMemo(() =>
      `${operation} Application` ,
    [operation]);

  const [ model, actions] = useApplicationActionManagement({
    applicationId,
    operation
  });

  const { showLoadingIndicator, ...otherModel } = model;
  const { performOperation, ...otherActions } = actions;

  const confirm = React.useCallback(() => {
    performOperation();
  }, [performOperation]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      {...otherActions}
      className={classnames("applicationOperationsDialog", dialogClassName)}
      title={title}
      loading={showLoadingIndicator}
      continueButtonLabel={operation}
      confirm={confirm}
    >
      <div className={classnames("applicationOperation", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Are you sure you want to {operation} this application?
          </label>
          {isDelete && (
            <label className={classnames("subtitle", classes.subtitle)}>
            <span className={classes.warning}>WARNING:</span>This action cannot be undone!
          </label>
          )}
          <label className={classnames("applicationId", classes.applicationId)}>
            {applicationId}
          </label>
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default ApplicationOperationDialog;
