import { Record } from "immutable";
import { getStringValue } from "@util";

export enum DataLakeFileType {
  FOLDER = "FOLDER",
  FILE = "FILE",
}

export interface DataLakeFileAttributes {
  fileType: DataLakeFileType;
  fileName: string;
  fileSize: string;
  updatedAt: string;
  eTag: string;
  filePath: string;
}

export class DataLakeFile extends Record({
  fileType: DataLakeFileType.FILE,
  fileName: "",
  fileSize: "",
  updatedAt: "",
  eTag: "",
  filePath: "",
}) implements DataLakeFileAttributes {

  public static EMPTY: DataLakeFile = new DataLakeFile();

  public readonly fileType: DataLakeFileType;
  public readonly fileName: string;
  public readonly fileSize: string;
  public readonly updatedAt: string;
  public readonly eTag: string;
  public readonly filePath: string;

  public getFileType(): DataLakeFileType {
    return this.fileType;
  }

  public getFileName(): string {
    return getStringValue(this.fileName);
  }

  public getFileSize(): string {
    return getStringValue(this.fileSize);
  }

  public getUpdatedAt(): string {
    return getStringValue(this.updatedAt);
  }

  public getETag(): string {
    return getStringValue(this.eTag);
  }

  public getFilePath(): string {
    return getStringValue(this.filePath);
  }

  public isFolder(): boolean {
    return DataLakeFileType.FOLDER === this.getFileType();
  }
}

export default DataLakeFile;
