import { createStyles } from "@material-ui/core/styles";
import EmailTemplateIcon from "@material-ui/icons/Email";
import Color from "@Color";

export const primaryColor = Color.MODULES;

export const PrimaryIcon = EmailTemplateIcon;

export const styles = () =>
  createStyles({
    container: {},
    createButton: {
    },
  });

export default styles;
