import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  ConfirmationDialog,
} from "@components";
import { editorDialog as styles } from "./styles";

export interface Model {
  className?: string;
  open?: boolean;
  children?: React.ReactNode;
}

type Props = WithStyles<typeof styles> & Model;

export const EditorDialog = withStyles(styles)((props: Props) => {

  const { classes, className,  children, open = false, ...remainingProps } = props;

  if (!open) {
    return null;
  }

  return (
    <ConfirmationDialog
      {...remainingProps}
      className={classnames("editorDialog", classes.container, className)}
      open={open}
    >
      {children}
    </ConfirmationDialog>
  );
});

export default EditorDialog;
