import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import { DataSetType } from "@data";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base/createReducers";

export interface DataSetsSchema extends BasePortalModuleSchema {
  accountFilter: string;
  groupFilter: string;
  aliasContainsFilter: string;
  currentAccountFilterEnabled: boolean;
  dataSetTypeFilter: DataSetType;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<DataSetsSchema>({
  accountFilter: "",
  groupFilter: "",
  aliasContainsFilter: "",
  dataSetTypeFilter: DataSetType.NONE,
  currentAccountFilterEnabled: true,
  showEmptyView: false,
});

type DataSetsActionTypes = PortalModuleActionTypes<DataSetsSchema>;

export const ACTION_TYPES: DataSetsActionTypes =
  createPortalModuleActionTypes<DataSetsSchema>(MODULE_ID, DEFAULT_STATE);

export const DataSetsReducer = combineReducers<DataSetsSchema>(
  createPortalModuleReducers<DataSetsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DataSetsReducer;
