const WHITELISTED_ACCOUNTS = process.env.REACT_APP_COST_REPORTS_WHITELISTED_ACCOUNTS || "[]";

export const getCostReportsWhitelistedAccounts = (): string[] => {
  try {
    const whitelistedAccounts = JSON.parse(WHITELISTED_ACCOUNTS);
    return Array.isArray(whitelistedAccounts) ? whitelistedAccounts : [];
  } catch {
    return [];
  }
};

export default getCostReportsWhitelistedAccounts;
