import { Record } from "immutable";
import { getStringValue } from "@util";
import {
  FederationProviderAttribute,
  FederationProviderRequest,
  FederationProviderRequestAttributes,
  FederationProviderScope,
} from "@data";

export interface FederationProviderWizardStateAttributes extends FederationProviderRequestAttributes {
  providerId: string;
}

export class FederationProviderWizardState extends Record({
  providerId: "",
  ...FederationProviderRequest.EMPTY.toJS(),
}) implements FederationProviderWizardStateAttributes {

  public static EMPTY = new FederationProviderWizardState();

  public readonly providerId: string;
  public readonly domain: string;
  public readonly clientId: string;
  public readonly clientSecret: string;
  public readonly discoveryDocument: string;
  public readonly authorizationEndpoint: string;
  public readonly tokenEndpoint: string;
  public readonly userEndpoint: string;
  public readonly jwksUri: string;
  public readonly issuer: string;
  public readonly federationClosed: boolean;
  public readonly scope: FederationProviderScope;
  public readonly attributes: FederationProviderAttribute;

  public getProviderId(): string {
    return getStringValue(this.providerId);
  }

  public getFederationProviderRequest(): FederationProviderRequest {
    const { providerId, ...attrs } = this.toJS();
    return new FederationProviderRequest(attrs);
  }
}

export default FederationProviderWizardState;
