import { createSelectors, } from "@base/createSelectors";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";
import { DEFAULT_STATE, PublishDashboardSchema } from "./reducers";

export const {
  dashboardId: getDashboardId,
  publishable: isPublishable,
  publishedDashboardId: getPublishedDashboardId,
  publishDashboard: isDialogOpen,
  getErrorMessage,
  getSuccessMessage,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  getAccessToken,
} = createSelectors<PublishDashboardSchema>(moduleId, schemaKey, DEFAULT_STATE);
