import React from "react";
import { EmailTemplate } from "@data";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { useEmailTemplates } from "@hooks";
import { getAccountId } from "@main/selectors";
import { setNameFilter } from "../actions";
import { getNameFilter } from "../selectors";
import EmailTemplates, { Actions, Model } from "../components/EmailTemplates";

export interface ContainerModel extends Model {
  accountId?: string;
  onDeleteSuccess?: boolean;
  emailTemplates?: EmailTemplate[];
}

export interface ContainerActions extends Actions {
  setOnDeleteSuccess?: (value: boolean) => void;
}

type Props = ContainerModel & ContainerActions;

const EmailTemplatesContainer = (props: Props) => {

  const {
    accountId = "",
    ...otherProps
  } = props;

  const [{ emailTemplates = [], ...otherModel }, { refresh, ...otherActions }] = useEmailTemplates({accountId});

  return (
    <EmailTemplates
      {...otherProps}
      {...otherModel}
      {...otherActions}
      refresh={refresh}
      items={emailTemplates}
      accountId={accountId}
      onDeleteSuccess={refresh}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): ContainerModel => ({
  accountId: getAccountId(state),
  nameFilter: getNameFilter(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): ContainerActions => ({
  setNameFilter: (nameFilter: string) => dispatch(setNameFilter(nameFilter)),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(EmailTemplatesContainer);
