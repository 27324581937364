import React from "react";
import classnames from "classnames";
import { ErrorView } from "@components/error-view";
import TextField from "@components/text-field";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { namespaceInfo as styles, namespaceTextField } from "./styles";
import { formEventHandler } from "@util";

const NamespaceTextField = withStyles(namespaceTextField)(TextField);

export interface Model {
  namespaceName?: string;
  namespaceNameError?: string;
  readOnly?: boolean;
  errorMessage?: string;
}

export interface Actions {
  setNamespaceName?: (name: string) => void;
  retry?: () => void;
}

type  Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const NamespaceInfo = withStyles(styles)((props: Props) => {

  const {
    classes,
    errorMessage = "",
    namespaceName = "",
    namespaceNameError = "",
    setNamespaceName,
    retry,
    readOnly = false,
    children,
  } = props;

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    }
  };

  return (
    <div className={classnames("namespaceInfo", classes.container)}>
      <label className={classnames("title", classes.title)}>Set Namespace</label>
      <ErrorView title="Create Namespace Failed" message={errorMessage} retry={retry} />
      <NamespaceTextField
        className={classnames("namespace", classes.namespace)}
        autoComplete="off"
        label="Name"
        name="namespace"
        value={namespaceName}
        fullWidth={true}
        autoFocus={true}
        variant="outlined"
        margin="none"
        required={true}
        inputProps={{ readOnly }}
        helperText={namespaceNameError}
        error={namespaceNameError.length > 0}
        InputLabelProps={inputLabelProps}
        disableSpecialChars={true}
        onChange={formEventHandler(setNamespaceName)}
      />
      {children}
    </div>
  );
});

export default NamespaceInfo;
