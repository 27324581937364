import React from "react";
import { ManagedPolicy, Policy } from "@data";
import EffectivePoliciesView from "./EffectivePoliciesView";

interface Model {
  groupName?: string;
  isCustomPoliciesViewActive?: boolean;
  isManagedPoliciesViewActive?: boolean;
}

interface Actions {
  showCustomPoliciesView?: () => void;
  showManagedPoliciesView?: () => void;
  showPolicyDetails?: (policy: Policy) => void;
  showManagedPolicyDetails?: (policy: ManagedPolicy) => void;
  setRemovePolicyButtonEnabled?: (enabled: boolean) => void;
}

type Props = Model & Actions;

const PoliciesView = (props: Props) => {

  const {
    groupName = "",
    showPolicyDetails,
    showManagedPolicyDetails,
    showCustomPoliciesView,
    showManagedPoliciesView,
    isCustomPoliciesViewActive,
    isManagedPoliciesViewActive,
    setRemovePolicyButtonEnabled,
  } = props;

  return (
    <EffectivePoliciesView
      groupName={groupName}
      showPolicyDetails={showPolicyDetails}
      showManagedPolicyDetails={showManagedPolicyDetails}
      showCustomPoliciesView={showCustomPoliciesView}
      showManagedPoliciesView={showManagedPoliciesView}
      isCustomPoliciesViewActive={isCustomPoliciesViewActive}
      isManagedPoliciesViewActive={isManagedPoliciesViewActive}
      setRemovePolicyButtonEnabled={setRemovePolicyButtonEnabled}
    />
  );
};

export default PoliciesView;
