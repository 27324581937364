import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      color: primaryColor,
      display: "flex",
      flexFlow: "row nowrap",
      border: `1px solid ${Color.GREY1}`,
      alignItems: "stretch",
    },
    dataSets: {
      flex: "0 0 250px",
    },
    fileBrowser: {
      flex: "1 1 auto",
      borderLeft: `1px solid ${Color.GREY1}`,
    },
  });

export default styles;
