import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { isEmptyString } from "@util";
import { styles } from "./styles";

export interface Model {
  accountId?: string;
  dataSetAlias?: string;
}

type Props = WithStyles<typeof styles> & Model;

export const DeleteDataSetRequest = withStyles(styles)((props: Props) => {

  const { classes, accountId = "", dataSetAlias = ""} = props;

  const content = React.useMemo(() => {

    if (isEmptyString(accountId) || isEmptyString(dataSetAlias)) {
      return (
        <label className={classnames("emptyViewLabel", classes.emptyViewLabel)}>
          No Data Set Request Selected
        </label>
      );
    }

    return (
      <React.Fragment>
        <label className={classnames("title", classes.title)}>
          Are you sure you want to cancel this data set request?
        </label>
        <label className={classnames("subtitle", classes.subtitle)}>
          <span className={classes.warning}>WARNING:</span>This action cannot be undone!
        </label>
        <label className={classnames("dataSetAlias", classes.dataSetAlias)}>
          {dataSetAlias}
        </label>
      </React.Fragment>
    );
  }, [accountId, dataSetAlias]);

  return (
    <div className={classnames("deleteDataSetRequest", classes.container)}>
      {content}
    </div>
  );
});

export default DeleteDataSetRequest;
