import React from "react";
import { AuthTokenContext } from "@components/auth-token-provider";
import { isValidJson, noop } from "@util";
import { RestClientError, SchemaRegistryClient } from "@network";

export interface UseSchemaValidationProps {
  data?: string;
  namespace?: string;
  name?: string;
  version?: string;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
}

export interface UseSchemaValidationModel {
  successMessage?: string;
  errorMessage?: string;
  showAccessDenied?: boolean;
  showLoadingIndicator?: boolean;
}

export interface UseSchemaValidationActions {
  setData: (data: string) => void;
}

type Props = UseSchemaValidationProps;
type Model = UseSchemaValidationModel;
type Actions = UseSchemaValidationActions;

export const useSchemaValidation = (props: Props = {}): [Model, Actions] => {

  const {
    data: initialData = "",
    namespace = "",
    name = "",
    version = "",
    trackRequestEvent = noop,
    trackSuccessEvent = noop,
    trackErrorEvent = noop,

  } = props;

  const authToken = React.useContext(AuthTokenContext);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [data, setData] = React.useState(initialData);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [showAccessDenied, setShowAccessDenied] = React.useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);

  const validate = React.useCallback(() => {
    return SchemaRegistryClient.validateSchemaData(authToken, namespace, name, version, data);
  }, [
    authToken,
    namespace,
    name,
    version,
    data,
  ]);

  const reset = React.useCallback(() => {
    setShowLoadingIndicator(false);
    setShowAccessDenied(false);
    setErrorMessage("");
    setSuccessMessage("");
  }, [
    setShowLoadingIndicator,
    setShowAccessDenied,
    setErrorMessage,
    setSuccessMessage,
  ]);

  const updateData = React.useCallback((updatedData: string) => {
    reset();
    setData(updatedData);
    setShowLoadingIndicator(true);
  }, [reset, setData, setShowLoadingIndicator]);

  React.useEffect(() => {

    let ignore = false;

    if (showLoadingIndicator) {

      if (!isValidJson(data)) {
        setErrorMessage("Data must be valid JSON");
        setShowLoadingIndicator(false);
        return () => { ignore = true; };
      }

      trackRequestEvent();

      validate()
        .then(() => {
          if (!ignore) {
            trackSuccessEvent();
            setSuccessMessage("No errors found. Data validates against this schema.");
            setShowLoadingIndicator(false);
          }
        }, (response: RestClientError) => {
          if (!ignore) {
            const { analytic, status, error = "Validation Failed" } = response;
            setErrorMessage(error);
            if (status === 403) {
              setShowAccessDenied(true);
            } else {
              trackErrorEvent(analytic);
            }
            setShowLoadingIndicator(false);
          }
        });
    }

    return () => { ignore = true; };
  }, [
    showLoadingIndicator,
    data,
    setErrorMessage,
    setSuccessMessage,
    setShowAccessDenied,
    validate,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
  ]);

  const model: Model = React.useMemo(() => ({
    data,
    successMessage,
    errorMessage,
    showAccessDenied,
    showLoadingIndicator,
  }), [
    data,
    successMessage,
    errorMessage,
    showAccessDenied,
    showLoadingIndicator,
  ]);

  const actions: Actions = React.useMemo(() => ({
    setData: updateData
  }), [
    updateData
  ]);

  return [model, actions];

};

export default useSchemaValidation;
