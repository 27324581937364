import { Module } from "@data";
import { equalsIgnoreCase } from "@util";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./styles";
import view from "./DataLakeBrowserView";

export const DataLakeBrowserModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Browser",
  title: "Data Lake Browser",
  className: "dataLakeBrowserModule",
  primaryColor,
  icon,
  view,
  backButton: null,
  disabled: !equalsIgnoreCase("true", process.env.REACT_APP_DATA_LAKE_BROWSER_MODULE_ENABLED),
});

export default DataLakeBrowserModule;
