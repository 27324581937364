import React from "react";
import classnames from "classnames";
import { equalsIgnoreCase, isEmptyString, noop } from "@util";
import Search from "../search";

export interface NameFilterModel {
  className?: string;
  ariaLabel?: string;
  hint?: string;
  nameFilter?: string;
  delay?: number;
  autoFocus?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  validationErrorMessage?: string;
  validationEnabled?: boolean;
}

export interface NameFilterActions {
  setNameFilter?: (nameFilter: string) => void;
  validateNameFilter?: (nameFilter: string) => boolean;
}

type Props = NameFilterModel & NameFilterActions;

export const NameFilter = (props: Props) => {

  const {
    className,
    ariaLabel = "name-filter",
    nameFilter = "",
    hint = "Filter results",
    setNameFilter = noop,
    autoFocus = false,
    fullWidth = false,
    disabled,
    validationEnabled,
    validationErrorMessage = "Invalid Input",
    validateNameFilter = () => true,
    delay = 500,
  } = props;

  const [pendingNameFilter, setPendingNameFilter] = React.useState(nameFilter);

  const validInput = React.useMemo(() => {
    if (!validationEnabled || isEmptyString(pendingNameFilter)) {
      return true;
    } else {
      return validateNameFilter(pendingNameFilter);
    }
  }, [validationEnabled, pendingNameFilter, validateNameFilter]);

  // If the parent prop value changed, make sure we don't override it with a pending name filter
  React.useEffect(() => {
    if (!equalsIgnoreCase(nameFilter, pendingNameFilter)) {
      setPendingNameFilter(nameFilter);
    }
  }, [nameFilter, setPendingNameFilter]);

  // Whenever the search query changes, clear the previous timer, and create a new timer
  // to wait 500ms (default value) before notifying parent that the name filter value changed
  React.useEffect(() => {

    const timer = setTimeout(() => {
      if (validInput && !equalsIgnoreCase(nameFilter, pendingNameFilter)) {
        setNameFilter(pendingNameFilter);
      }
    }, delay);

    return () => clearTimeout(timer);

  }, [validInput, pendingNameFilter, setNameFilter]);

  return (
    <Search
      className={classnames("nameFilter", className)}
      ariaLabel={ariaLabel}
      hint={hint}
      autoFocus={autoFocus}
      fullWidth={fullWidth}
      disabled={disabled}
      value={pendingNameFilter}
      helperText={!validInput ? validationErrorMessage : ""}
      error={!validInput}
      updateSearchQuery={setPendingNameFilter}
    />
  );
};

export default NameFilter;
