import { Record } from "immutable";
import { getStringValue } from "@util";
import { DeviceDataModel, DeviceDataModelAttributes, CredentialAttributes } from "./DeviceDataModel";

export interface DeviceEnrollmentRequestAttributes {
  batchId: string;
  device: DeviceDataModelAttributes;
}

export class DeviceEnrollmentRequest extends Record( {
  batchId: "",
  device: DeviceDataModel.EMPTY.toJS(),

}) implements DeviceEnrollmentRequestAttributes {

  public static EMPTY: DeviceEnrollmentRequest = new DeviceEnrollmentRequest();

  public readonly batchId: string;
  public readonly device: DeviceDataModelAttributes;

  public getBatchId(): string {
    return getStringValue(this.batchId);
  }

  public getDeviceId(): string {
    return getStringValue(this.device.deviceId);
  }

  public getDeviceType(): string {
    return getStringValue(this.device.deviceType);
  }

  public getActualSchemas(): string[] {

    const { deviceData: { actual: { data: actualData = [] } = {} } = {} } = this.device || {};

    if (!Array.isArray(actualData)) {
      return [];
    }

    return actualData.map(({ schemaIdentity }) => getStringValue(schemaIdentity));
  }

  public getDesiredSchemas(): string[] {

    const { deviceData: { desired: { data: desiredData = [] } = {} } = {} } = this.device || {};

    if (!Array.isArray(desiredData)) {
      return [];
    }

    return desiredData.map(({ schemaIdentity }) => getStringValue(schemaIdentity));
  }

  public getCredentials(): CredentialAttributes[] {

    const { security: { credentials = [] } = {} } = this.device || {};

    return Array.isArray(credentials) ? credentials : [];
  }
}

export default DeviceEnrollmentRequest;
