import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel
} from "@components";
import { removeRedirectUri as styles } from "./styles";
import { noop } from "@util";

type Model = ConfirmationDialogModel<string>;
type Actions = ConfirmationDialogActions<string>;

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const RemoveRedirectUriDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className = "removeRedirectUriDialog",
    item = "",
    title = "Remove Redirect URI",
    continueButtonLabel = "Delete",
    open = false,
    cancel = noop,
    confirm = noop,
  } = props;

  return (
    <ConfirmationDialog
      className={className}
      open={open}
      title={title}
      item={item}
      continueButtonLabel={continueButtonLabel}
      cancel={cancel}
      confirm={confirm}
    >
      <div className={classnames("removeRedirectUri", "content", classes.content)}>
        <label className={classnames("title", classes.title)}>
          Are you sure you want to remove this Redirect Uri?
        </label>
        <label className={classnames("subtitle", classes.subtitle)}>
          <span className={classes.warning}>WARNING:</span>This action cannot be undone!
        </label>
      </div>
    </ConfirmationDialog>
  );
});

export default RemoveRedirectUriDialog;
