import { ReleaseNotePlatformDomain } from "@data";

export const getArchiveNotesLink = (platformDomain: ReleaseNotePlatformDomain) => {
  switch (platformDomain) {
    case ReleaseNotePlatformDomain.SELF_SERVICE_TOOLS:
      return "https://www.collaboration.dtf.signify.com/display/IOTPLAT/Self-Service+Tools+Releases";
    case ReleaseNotePlatformDomain.COMMON_SERVICES:
      return "https://www.collaboration.dtf.signify.com/display/IOTPLAT/Common+Services+Releases";
    case ReleaseNotePlatformDomain.DATA_AND_ANALYTICS:
      return "https://www.collaboration.dtf.signify.com/pages/viewpage.action?pageId=53288625";
    case ReleaseNotePlatformDomain.DEVICE_MANAGEMENT:
      return "https://www.collaboration.dtf.signify.com/display/IOTPLAT/Device+Management+Releases";
    case ReleaseNotePlatformDomain.SECURITY_SERVICES:
      return "https://www.collaboration.dtf.signify.com/display/IOTPLAT/Security+Services+Releases";
    default:
      return "";
  }
};
