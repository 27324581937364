import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { MODULE_PATH } from "../constants";
import useDataApprovalRequestsQueryParams, {
  DataApprovalRequestFilter,
  DataApprovalRequestState,
} from "@hooks/dataApprovalRequest/use-data-approval-requests-query-params";
import { noop } from "@util";
import { setFilterByPendingApproval } from "../actions";
import { isFilterByPendingApprovalEnabled } from "../selectors";
import DataApprovalRequests, { ContainerActions, ContainerModel } from "./DataApprovalRequests";

type Props = ContainerModel & ContainerActions;

const DataApprovalRequestsModuleContainer = (props: Props) => {

  const {
    filterByPendingApproval: pendingFilterEnabled,
    setFilterByPendingApproval: setPendingFilterEnabled = noop,
    ...otherProps
  } = props;

  const [initialized, setInitialized] = React.useState(false);

  const {validState, filterByPendingApproval} = useDataApprovalRequestsQueryParams();

  const redirectRequired = React.useMemo(() =>
      !validState || filterByPendingApproval !== pendingFilterEnabled,
    [validState, filterByPendingApproval, pendingFilterEnabled]);

  React.useEffect(() => {
    if (!initialized) {
      setPendingFilterEnabled(filterByPendingApproval);
      setInitialized(true);
    }
  }, [initialized, filterByPendingApproval, setPendingFilterEnabled, setInitialized]);

  if (redirectRequired) {
    const redirectTo = `${MODULE_PATH}?${DataApprovalRequestFilter.STATE}=` +
      (pendingFilterEnabled ? DataApprovalRequestState.PENDING : DataApprovalRequestState.ALL);

    return <Redirect to={redirectTo}/>;
  }

  if (!initialized) {
    return null;
  }

  return (
    <DataApprovalRequests
      {...otherProps}
      filterByPendingApproval={pendingFilterEnabled}
      setFilterByPendingApproval={setPendingFilterEnabled}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  filterByPendingApproval: isFilterByPendingApprovalEnabled(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setFilterByPendingApproval: value => dispatch(setFilterByPendingApproval(value)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DataApprovalRequestsModuleContainer);
