import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4,
      padding: 4,
    },
    header: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    title: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "21px",
      marginLeft: 6,
    },
    icon: {
      color: Color.BLACK,
      width: 18,
      height: 18,
    },
    helperText: {
      borderTop: "1.5px dotted rgba(0, 0, 0, 0.23)",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "21px",
      margin: "4px 0 0 0",
      padding: "8px 4px",
    },
    successIcon: {
      color: Color.SIGNIFY_GREEN,
    },
    errorIcon: {
      color: Color.DARK_RED,
    },
  });

export default styles;
