import React from "react";
import classnames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import MuiButton, { ButtonProps as MuiButtonProps } from "@material-ui/core/Button";
import styles from "./styles";

const CustomMuiButton = withStyles(styles)(MuiButton);

export interface CustomMuiButtonProps extends MuiButtonProps {
}

export type ButtonProps<C extends React.ElementType = "button", P = {}> =
  MuiButtonProps<C, {component?: C}> & CustomMuiButtonProps;

export const Button = React.forwardRef(
  <C extends React.ElementType>(props: ButtonProps<C>, ref?: React.Ref<any>) => {

    const {
      className,
      children,
      ...otherProps
    } = props;

    return (
      <CustomMuiButton
        ref={ref}
        className={classnames("button", className)}
        {...otherProps}
      >
        {children}
      </CustomMuiButton>
    );
  });

export default Button;
