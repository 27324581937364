import React from "react";
import { RequestComment } from "@data";
import { getPluralString } from "@util";
import { comments as styles } from "./styles";
import { WithStyles, withStyles } from "@material-ui/core";
import CreateCommentView from "../containers/CreateCommentView";
import { CommentsView as Comments } from "@components/request-comments";
import CommentsListView from "@components/request-comments/CommentsListView";

interface Model {
  items: RequestComment[];
  errorMessage: string;
  dataSetRequestId: string;
  showLoadingIndicator: boolean;
}

interface Actions {
  onSuccess: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

const CommentsView = withStyles(styles)((props: Props) => {

  const {
    classes,
    items = [],
    errorMessage = "",
    dataSetRequestId = "",
    showLoadingIndicator = false,
    onSuccess,
  } = props;

  const title = React.useMemo(() => getPluralString(items.length, {
    other: "Comments",
    one: "Comment",
  }), [items]);

  return (
    <Comments
      title={title}
      errorMessage={errorMessage}
      className={classes.container}
    >
      <CreateCommentView
        onSuccess={onSuccess}
        dataSetRequestId={dataSetRequestId}
      />
      <CommentsListView
        items={items}
        errorMessage={errorMessage}
        loadingIndicator={showLoadingIndicator}
      />
    </Comments>
  );
});

export default CommentsView;
