import React from "react";
import usePaginatedApiRequest, {
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
} from "@hooks/use-paginated-api-request";
import { ListTypesResponse } from "@network/DigitalShadowClient";
import useDigitalShadowClient from "../use-digital-shadow-client";
import { DSTypeInfo } from "@data";

type SuccessResponse = ListTypesResponse;

export interface UseListDSTypeProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
  limit?: number;
}

export interface UseListDSTypeModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  types: DSTypeInfo[];
}

export interface UseListDSTypeActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseListDSTypeProps;
type Model = UseListDSTypeModel;
type Actions = UseListDSTypeActions;
type Result = [Model, Actions];

export const useListDSTypes = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to list digital shadow types",
    limit,
    ...otherProps
  } = props;

  const DigitalShadowClient = useDigitalShadowClient();

  const [types, setTypes] = React.useState<DSTypeInfo[]>([]);

  const makeApiRequest = React.useCallback((accessToken, next) =>
      DigitalShadowClient.listTypesApi(next, limit),
    [DigitalShadowClient, limit]);

  const [{ successResponse, ...baseModel }, {
    reset: baseReset,
    refresh: baseRefresh,
    ...baseActions
  }] =
    usePaginatedApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const updatedTypes = React.useMemo<DSTypeInfo[]>(() => {
    const { items = [] } = successResponse || {};
    return items.map(attrs => new DSTypeInfo(attrs));
  }, [successResponse]);

  const reset = React.useCallback(() => {
    setTypes([]);
    baseReset();
  }, [setTypes, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    types,
  }), [
    baseModel,
    types,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    refresh();
  }, [
    refresh,
    limit,
  ]);

  React.useEffect(() => {
    setTypes(currentTypes => currentTypes.concat(updatedTypes));
  }, [updatedTypes, setTypes]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useListDSTypes;
