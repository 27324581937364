import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useWorkloadManagerClient
} from "@hooks";

type SuccessResponse = void;

export interface UseCancelWorkloadExecutionProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  executionIds: string[];
}

export interface UseCancelWorkloadExecutionActions extends UseApiRequestActions {
  cancelWorkloadExecution: () => void;
}

type Props = UseCancelWorkloadExecutionProps;
type Model = UseApiRequestModel<SuccessResponse>;
type Actions = UseCancelWorkloadExecutionActions;
type Result = [Model, Actions];

export const useCancelWorkloadExecution = (props: Props): Result => {

  const {
    name,
    version,
    executionIds = [],
    defaultErrorMessage = "Failed to cancel workload execution(s)",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.cancelWorkloadExecutionApi(name, version, executionIds),
    [WorkloadManagerClient, name, version, executionIds]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: cancelWorkloadExecution, ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
    showSuccessView ? "Workload Execution Cancelled Successfully" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    refresh: cancelWorkloadExecution,
    cancelWorkloadExecution,
  }), [
    baseActions,
    cancelWorkloadExecution,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useCancelWorkloadExecution;
