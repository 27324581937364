import QualityReport, { QualityReportPlatformDomainId } from "@data/QualityReport";
import {
  BASE_URL,
  COMMON_SERVICES_QUALITY_REPORTS_PATH,
  DATA_AND_ANALYTICS_QUALITY_REPORTS_PATH,
  DEVICE_MANAGEMENT_QUALITY_REPORTS_PATH,
  SECURITY_SERVICES_QUALITY_REPORTS_PATH,
} from "@modules/qualityReports/constants";

const getBaseUri = (report: QualityReport) => {
  switch (report.getPlatformDomainId()) {
    case QualityReportPlatformDomainId.SECURITY_SERVICES:
      return SECURITY_SERVICES_QUALITY_REPORTS_PATH;
    case QualityReportPlatformDomainId.DEVICE_MANAGEMENT:
      return DEVICE_MANAGEMENT_QUALITY_REPORTS_PATH;
    case QualityReportPlatformDomainId.DATA_AND_ANALYTICS:
      return DATA_AND_ANALYTICS_QUALITY_REPORTS_PATH;
    case QualityReportPlatformDomainId.COMMON_SERVICES:
      return COMMON_SERVICES_QUALITY_REPORTS_PATH;
    default:
      return BASE_URL;
  }
};

export const getPathToQualityReport = (report: QualityReport) =>
  `${getBaseUri(report)}/${report.getGroupId()}/${report.getVersion()}`;
