import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { FederationProviderWizardActions } from "../actions";
import { FederationProviderWizardSelectors } from "../selectors";
import EndpointsView, { Actions, Model } from "../components/EndpointsView";

const mapStateToProps = (state: AppSchema): Model => {

  if (!FederationProviderWizardSelectors.isJsonValid(state)) {
    return {
      showInvalidJsonError: true,
    };
  }

  return {
    authorizationEndpoint: FederationProviderWizardSelectors.getAuthorizationEndpoint(state),
    tokenEndpoint: FederationProviderWizardSelectors.getTokenEndpoint(state),
    userEndpoint: FederationProviderWizardSelectors.getUserEndpoint(state),
    jwksUri: FederationProviderWizardSelectors.getJwksUri(state),
    discoveryDocument: FederationProviderWizardSelectors.getDiscoveryDocument(state),
    issuer: FederationProviderWizardSelectors.getIssuer(state),
    federationClosed: FederationProviderWizardSelectors.isFederationClosed(state),
  };
};

const mapDispatchToProps = (dispatch: any): Actions => ({
  setDiscoveryDocument: (discoveryDocument: string) =>
    dispatch(FederationProviderWizardActions.updateDiscoveryDocument(discoveryDocument)),
  setAuthEndpoint: (authEndpoint: string) =>
    dispatch(FederationProviderWizardActions.updateAuthorizationEndpoint(authEndpoint)),
  setTokenEndpoint: (tokenEndpoint: string) =>
    dispatch(FederationProviderWizardActions.updateTokenEndpoint(tokenEndpoint)),
  setUserEndpoint: (userEndpoint: string) =>
    dispatch(FederationProviderWizardActions.updateUserEndpoint(userEndpoint)),
  setJwksUri: (jwksUri: string) =>
    dispatch(FederationProviderWizardActions.updateJwksUri(jwksUri)),
  setIssuer: (issuer: string) =>
    dispatch(FederationProviderWizardActions.updateIssuer(issuer)),
  setIsFederationClosed: (value: boolean) =>
    dispatch(FederationProviderWizardActions.updateFederationClosed(value)),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(EndpointsView);
