import React from "react";
import classnames from "classnames";
import WarningIcon from "@material-ui/icons/Warning";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles, warningIcon } from "./styles";
import { isEmptyString } from "../../util";

const PORTAL_CLIENT_URL = process.env.REACT_APP_PORTAL_CLIENT_URL || "";

const DEFAULT_MESSAGE = "Our site has moved. Please update your bookmarks.";

const WarningBannerIcon = withStyles(warningIcon)(WarningIcon);

export const InformationBanner = withStyles(styles)((props: WithStyles<typeof styles> & {
  url?: string,
  message?: string,
}) => {

  const { classes, url = PORTAL_CLIENT_URL, message = DEFAULT_MESSAGE } = props;

  if (isEmptyString(url)) {
    return null;
  }

  return (
    <div className={classnames("informationBanner", classes.container)} >
      <WarningBannerIcon />
      <div className={classnames("informationBannerText", classes.informationBannerText)}>
        <p className={classes.label}>{message}</p>
        <p className={classes.label}><a className={classes.link} href={url}>{url}</a></p>
      </div>
    </div>
  );
});

export default InformationBanner;
