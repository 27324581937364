import React from "react";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useWorkloadManagerClient } from "@hooks";

type SuccessResponse = void;

export interface UseDeleteVariableProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  etag: string;
  variable: string;
}

export interface UseDeleteVariableModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseDeleteVariableActions {
  deleteVariable: () => void;
  reset: () => void;
}

type Props = UseDeleteVariableProps;
type Model = UseDeleteVariableModel;
type Actions = UseDeleteVariableActions;
type Result = [Model, Actions];

export const useDeleteVariable = (props: Props): Result => {

  const {
    name = "",
    version = 1,
    etag,
    variable = "",
    defaultErrorMessage = "Failed to delete the variable",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.deleteWorkloadVariableApi(name, version, etag, variable),
    [WorkloadManagerClient, name, version, etag, variable]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: deleteVariable, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Delete Successful" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    deleteVariable,
    reset
  }), [
    deleteVariable,
    reset
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDeleteVariable;
