import { connect } from "react-redux";
import { getUserId } from "../selectors";
import { AppSchema } from "@schemas";
import { Model, Confirmation, } from "../components/Confirmation";

const mapStateToProps = (state: AppSchema): Model => ({
  userId: getUserId(state),
});

export default connect<Model, {}, Model>(
  mapStateToProps,
)(Confirmation);
