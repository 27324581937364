import React from "react";
import FederationProviderListColumn from "./FederationProviderListColumn";
import { ActionMenuItem } from "@components/actions-menu/ActionMenuItem";
import { FederationProviderAction } from "@components/federation-provider-action-menu/FederationProviderActionsMenu";
import { ModuleListView } from "@components/module-list-view";
import {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import { FederationProvider } from "@data";
import { sortAndFilterFederationProviderResults } from "@hooks";
import { createColumns } from "@components/module-list-view/helpers";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { getPathToFederationProvider } from "@modules/federationProviderDetails/helpers";
import { FederationProviderIcon } from "./styles";
import { noop } from "@util";

export const DEFAULT_FEDERATION_PROVIDER_LIST_COLUMNS: FederationProviderListColumn[] = [
  FederationProviderListColumn.ID,
  FederationProviderListColumn.CLIENT_ID,
  FederationProviderListColumn.DOMAIN,
  FederationProviderListColumn.ACCOUNT_ID
];

export type FederationProvidersListModel =
  SortedSearchResultsListModel<FederationProvider, FederationProviderListColumn>;

export interface FederationProvidersListActions extends
  SortedSearchResultsListActions<FederationProvider, FederationProviderListColumn> {
  editFederationProvider?: (federationProvider: FederationProvider) => void;
  deleteFederationProvider?: (federationProvider: FederationProvider) => void;
}

type Props = FederationProvidersListModel & FederationProvidersListActions;

export const FederationProviderList = (props: Props) => {

  const {
    className = "federationProviders",
    listClassName = "federationProvidersList",
    columns = DEFAULT_FEDERATION_PROVIDER_LIST_COLUMNS,
    sortByColumn = FederationProviderListColumn.NONE,
    sortOrderAscending = true,
    noResultsLabel = "No Federation Providers found",
    summaryViewQuantities = {
      other: "federation providers",
      one: "federation provider",
    },
    items = [],
    nameFilter = "",
    deleteFederationProvider = noop,
    editFederationProvider = noop,
    onClickAction = noop,
    ...otherProps
  } = props;

  const onActionClicked = React.useCallback((federationProvider: FederationProvider, action: ActionMenuItem) => {
    switch (action.id) {
      case FederationProviderAction.EDIT_FEDERATION_PROVIDER:
        return editFederationProvider(federationProvider);
      case FederationProviderAction.DELETE_FEDERATION_PROVIDER:
        return deleteFederationProvider(federationProvider);
      default:
        return onClickAction(federationProvider, action);

    }
  }, [ editFederationProvider, deleteFederationProvider, onClickAction]);

  const federationProviders =
    sortAndFilterFederationProviderResults({ items, nameFilter, sortOrderAscending, sortByColumn });

  const moduleListItems: ListViewItem<FederationProvider>[] = React.useMemo(() =>
    federationProviders.map(item => {
      return {
        item: item,
        pathToDetailsView: getPathToFederationProvider(item.getProviderId()),
        icon: FederationProviderIcon,
        columnAttributes: createColumns([
          {
            className: "providerId",
            value: item.getProviderId(),
            column: FederationProviderListColumn.ID,
            firstColumn: true,
          },
          {
            className: "providerClientId",
            value: item.getClientId(),
            column: FederationProviderListColumn.CLIENT_ID,
          },
          {
            className: "providerDomain",
            value: item.getDomain(),
            column: FederationProviderListColumn.DOMAIN,
          },
          {
            className: "providerAccount",
            value: item.getAccountId(),
            column: FederationProviderListColumn.ACCOUNT_ID,
          },
        ])
      };
    }), [federationProviders]);

  return (
    <ModuleListView
      {...otherProps}
      className={className}
      listViewItems={moduleListItems}
      columns={columns}
      nameFilter={nameFilter}
      nameFilterDelay={25}
      sortByColumn={sortByColumn}
      sortOrderAscending={sortOrderAscending}
      noResultsLabel={noResultsLabel}
      summaryViewIcon={FederationProviderIcon}
      summaryViewQuantities={summaryViewQuantities}
      onClickAction={onActionClicked}
    />
  );
};

export default FederationProviderList;
