import { Module } from "@data";
import { initialize } from "./actions";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./ApplicationDetailsView";

type RouteParams = { id?: string };

export const applicationRegionalDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: false,
  name: "Application Details",
  title: "Application Details",
  className: "applicationRegionalDetailsModule",
  primaryColor,
  icon,
  view,
  onMount: ({ id = "" }: RouteParams = {}) => initialize(id),
});

export default applicationRegionalDetailsModule;
