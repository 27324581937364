import React from "react";
import Color from "../styles/Color";

const labelStyle: React.CSSProperties = {
  color: Color.TEXT,
  fontFamily: "Roboto, sans-serif",
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "16px",
};

const mailToLinkLabelStyle: React.CSSProperties = {
  ...labelStyle,
  color: Color.LIGHT_BLUE,
  textDecoration: "none",
};

export const Label = (props: {
  className?: string,
  style?: React.CSSProperties,
  value?: React.ReactNode | string,
}) => {

  const { className = "", style = labelStyle, value = "" } = props;

  return (
    <label className={className} style={style}>{value}</label>
  );
};

export const MailToLinkLabel = (props: {
  className?: string,
  email?: string,
  label?: string,
  style?: React.CSSProperties,
}) => {

  const { className, style = mailToLinkLabelStyle, email = "", label = "" } = props;

  if (email.length === 0) {
    return (
      <Label
        className={className}
        style={{
          ...style,
          color: Color.TEXT,
        }}
        value={label}
      />
    );
  }

  return (
    <a className={className} style={style} href={`mailto:${email}`}>{label}</a>
  );
};
