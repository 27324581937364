import React from "react";
import classnames from "classnames";
import { DeviceTypeListItem as DeviceTypeData } from "@data";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { DeviceTypeIcon, listItem as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DeviceTypeListColumn from "./DeviceTypeListColumn";

type StyleProps = WithStyles<typeof styles>;

export const DeviceTypeAttributeColumn = withStyles(styles)((props: StyleProps & {
  className?: string,
  label?: string,
  icon?: React.ComponentType<SvgIconProps>,
}) => {

  const { classes, className, label = "", icon: Icon } = props;

  if (!label) {
    return null;
  }

  if (!Icon) {
    return (
      <label className={classnames(className, classes.label)}>
        {label}
      </label>
    );
  }

  return (
    <div className={classnames(className, classes.container)}>
      <Icon className={classes.icon} />
      <label className={classes.label}>{label}</label>
    </div>
  );
});

export const DeviceTypeListItem = withStyles(styles)((props: StyleProps & {
  deviceType?: DeviceTypeData,
  column?: string,
}) => {

  const {
    classes,
    deviceType = DeviceTypeData.EMPTY,
    column,
  } = props;

  if (!column || DeviceTypeData.EMPTY.equals(deviceType)) {
    return null;
  }

  switch (column) {
    case DeviceTypeListColumn.NAMESPACE:
      return (
        <DeviceTypeAttributeColumn
          className="namespace"
          icon={DeviceTypeIcon}
          label={deviceType.getNameSpace()}
        />
      );
    case DeviceTypeListColumn.NAME:
      return (
        <DeviceTypeAttributeColumn
          className="name"
          label={deviceType.getName()}
        />
      );
    case DeviceTypeListColumn.VERSION:
      return (
        <DeviceTypeAttributeColumn
          className={classnames("version", classes.version)}
          label={`${deviceType.getVersion()}`}
        />
      );
    case DeviceTypeListColumn.STATE:
      return (
        <DeviceTypeAttributeColumn
          className="state"
          label={deviceType.getState()}
        />
      );
    default:
      return null;
  }
});

export default DeviceTypeListItem;
