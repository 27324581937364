import React from "react";
import { useHistory } from "react-router-dom";
import { getPathToEmailTemplate } from "@modules/emailTemplateDetails/helpers";
import EmailTemplateWizard from "./containers/EmailTemplateWizard";

export function CreateEmailTemplateView() {

  const history = useHistory();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onSuccess = React.useCallback(emailTemplate => {
    history.replace(getPathToEmailTemplate(emailTemplate.getId()));
  }, [history]);

  return (
    <EmailTemplateWizard
      className="createEmailTemplateWizard"
      snackbarId="create-email-template"
      cancel={cancel}
      onSuccess={onSuccess}
    />
  );
}

export default CreateEmailTemplateView;
