import React from "react";
import classnames from "classnames";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { formEventHandler, formNumberEventHandler, isEmptyString, noop } from "@util";
import { getDataClassificationDisplayName, getIngestionMechanismDisplayName } from "../helpers";
import { textField, formControlLabel, dataDescriptionView as styles, textAreaField } from "./styles";
import { DataClassification, IngestionMechanism } from "@data";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@components/text-field";

const SchemaTextField = withStyles(textAreaField)(TextField);
const SampleAnonymizedDataTextField = withStyles(textAreaField)(TextField);
const DataDescriptionFormControlLabel = withStyles(formControlLabel)(FormControlLabel);
const PiiCommentTextField = withStyles(textAreaField)(TextField);
const DataVolumeTextField = withStyles(textField)(TextField);
const DataVelocityTextField = withStyles(textField)(TextField);
const ArchivingInDaysTextField = withStyles(textField)(TextField);
const ExpirationInDaysTextField = withStyles(textField)(TextField);

export interface Model {
  schema?: string;
  schemaError?: string;
  sampleAnonymizedData?: string;
  sampleAnonymizedDataError?: string;
  dataClassification?: DataClassification;
  ingestionMechanism?: IngestionMechanism;
  dataRangeErrorMessage?: string;
  dataVolume?: number;
  dataVolumeError?: string;
  dataVelocity?: number;
  dataVelocityError?: string;
  lifecycleErrorMessage?: string;
  archivingInDays?: number;
  archivingInDaysError?: string;
  archivingInDaysHelperText?: string;
  expirationInDays?: number;
  expirationInDaysError?: string;
  expirationInDaysHelperText?: string;
  confirmNonProductionData?: boolean;
  showIngestionMechanism?: boolean;
  isProductionAccount?: boolean;
  mqttInfoLink?: string;
  containsPII?: boolean;
  piiComment?: string;
  children?: React.ReactNode;
}

export interface Actions {
  setSchema?: (schema: string) => void;
  setSampleAnonymizedData?: (sampleAnonymizedData: string) => void;
  setDataClassification?: (dataClassification: DataClassification) => void;
  setIngestionMechanism?: (ingestionMechanism: IngestionMechanism) => void;
  setDataVolume?: (dataVolume: number) => void;
  setDataVelocity?: (dataVelocity: number) => void;
  setArchivingInDays?: (archivingInDays: number) => void;
  setExpirationInDays?: (expirationInDays: number) => void;
  setConfirmNonProductionData?: (confirmNonProductionData: boolean) => void;
  setContainsPIIData?: (containsPII: boolean) => void;
  setPiiComment?: (comment: string) => void;
  mapDataClassificationToOptionName?: (dataClassification: DataClassification) => string;
  mapIngestionMechanismToOptionName?: (ingestionMechanism: IngestionMechanism) => string;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DataDescriptionView = withStyles(styles)((props: Props) => {

  const {
    classes,
    schema = "",
    schemaError = "",
    sampleAnonymizedData = "",
    sampleAnonymizedDataError = "",
    dataClassification = DataClassification.INTERNAL,
    ingestionMechanism = IngestionMechanism.NONE,
    dataRangeErrorMessage = "",
    dataVolume,
    dataVolumeError = "",
    dataVelocity,
    dataVelocityError = "",
    lifecycleErrorMessage = "",
    archivingInDays = 0,
    archivingInDaysError = "",
    archivingInDaysHelperText = "The number of days after which to archive the data.",
    expirationInDays = 0,
    expirationInDaysError = "",
    expirationInDaysHelperText = "The number of days after which to expire (delete) the data.",
    confirmNonProductionData = false,
    showIngestionMechanism = true,
    isProductionAccount = true,
    containsPII = false,
    piiComment = "",
    mqttInfoLink = "https://www.collaboration.dtf.signify.com/display/IOTPLAT/IoT+Device+Management+MQTT+Telemetry",
    setSchema,
    setSampleAnonymizedData,
    setDataClassification,
    setIngestionMechanism,
    setDataVolume = noop,
    setDataVelocity = noop,
    setArchivingInDays = noop,
    setExpirationInDays = noop,
    setConfirmNonProductionData = noop,
    mapDataClassificationToOptionName = getDataClassificationDisplayName,
    mapIngestionMechanismToOptionName = getIngestionMechanismDisplayName,
    setContainsPIIData = noop,
    setPiiComment = noop,
    children,
  } = props;

  const showDataRangeError = React.useMemo(() =>
    !isEmptyString(dataRangeErrorMessage), [dataRangeErrorMessage]);

  const showLifecycleError = React.useMemo(() =>
    !isEmptyString(lifecycleErrorMessage), [lifecycleErrorMessage]);

  const showArchivingInDaysError = React.useMemo(() =>
    showLifecycleError || !isEmptyString(archivingInDaysError),
    [showLifecycleError, archivingInDaysError]);

  const showExpirationInDaysError = React.useMemo(() =>
    showLifecycleError || !isEmptyString(expirationInDaysError),
    [showLifecycleError, expirationInDaysError]);

  const archivingInDaysLabel = React.useMemo(() =>
    "Archiving In Days" + (expirationInDays <= 0 ? "" : " (must be less than expiration policy)"),
    [expirationInDays]);

  const expirationInDaysLabel = React.useMemo(() =>
    "Expiration In Days" + (archivingInDays <= 0 ? "" : " (must be greater than archival policy)"),
    [archivingInDays]);

  const dataClassificationMenuItems = React.useMemo(() =>
      Object.values(DataClassification)
        .map(value => {

          const displayName = mapDataClassificationToOptionName(value);

          return isEmptyString(displayName) ? null : (
            <MenuItem key={value} value={value}>{displayName}</MenuItem>
          );
        })
        .filter(el => el !== null),
    [mapDataClassificationToOptionName]);

  const ingestionMechanismMenuItems = React.useMemo(() =>
      Object.values(IngestionMechanism)
        .map(value => {

          const displayName = mapIngestionMechanismToOptionName(value);

          return isEmptyString(displayName) ? null : (
            <MenuItem key={value} value={value}>{displayName}</MenuItem>
          );
        })
        .filter(el => el !== null),
    [mapIngestionMechanismToOptionName]);

  const inputLabelProps = React.useMemo(() => ({
    classes: {
      shrink: classes.inputLabelShrink,
    },
  }), [classes]);

  return (
    <div className={classnames("dataDescriptionView", classes.container)}>
      <label className={classes.title}>
        Set Data Description
      </label>
      <SchemaTextField
        className={classnames("schema", classes.schema)}
        autoComplete="off"
        label="Schema"
        name="schema"
        value={schema}
        fullWidth={true}
        multiline={true}
        minRows={3}
        autoFocus={false}
        required={true}
        helperText={schemaError || "The schema of the data set"}
        error={schemaError.length > 0}
        InputLabelProps={inputLabelProps}
        variant="outlined"
        margin="none"
        onChange={formEventHandler(setSchema)}
      />
      <SampleAnonymizedDataTextField
        className={classnames("sampleAnonymizedData", classes.sampleAnonymizedData)}
        autoComplete="off"
        name="sampleAnonymizedData"
        label="Sample Anonymized Data"
        value={sampleAnonymizedData}
        fullWidth={true}
        multiline={true}
        minRows={3}
        autoFocus={false}
        required={true}
        helperText={
          sampleAnonymizedDataError ||
          "A sample anonymized record of the data set that confirms to the schema specified"
        }
        error={sampleAnonymizedDataError.length > 0}
        variant="outlined"
        margin="none"
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setSampleAnonymizedData)}
      />
      <div className={classnames("dataClassification", classes.dataClassification)}>
        <FormControl
          className={classnames("dataClassificationFormControl",
            classes.dataClassificationFormControl,
          )}
        >
          <InputLabel
            className="dataClassification"
            variant="standard"
          >
            Data Classification
          </InputLabel>
          <Select
            value={dataClassification}
            onChange={formEventHandler(setDataClassification)}
          >
            {dataClassificationMenuItems}
          </Select>
          <FormHelperText>
            Classification of the data being captured in this data set
          </FormHelperText>
        </FormControl>
      </div>
      {showIngestionMechanism && (
        <div className={classnames("ingestionMechanism", classes.ingestionMechanism)}>
          <div
            className={classnames("ingestionMechanismFormControlContainer",
              classes.ingestionMechanismFormControlContainer)
            }
          >
            <FormControl
              className={classnames("formControl",
                classes.ingestionMechanismFormControl,
              )}
            >
              <InputLabel
                className="ingestionMechanism"
                variant="standard"
                required={true}
              >
                Ingestion Mechanism
              </InputLabel>
              <Select
                className="ingestionMechanismDropdown"
                value={ingestionMechanism}
                onChange={formEventHandler(setIngestionMechanism)}
              >
                {ingestionMechanismMenuItems}
              </Select>
              <FormHelperText>
                Iot Platform ingestion mechanism used for ingesting the data
              </FormHelperText>
            </FormControl>
          </div>
          <div
            className={classnames("ingestionMechanismDescription",
              classes.ingestionMechanismDescription)
            }
          >
            <ul>
              <li>
                <strong>Microbatch:</strong> Suitable for small payloads (upto 800 KB)
              </li>
              <li>
                <strong>Macrobatch:</strong> Suitable for large payloads
              </li>
              <li>
                <strong>IoT Device Management MQTT: </strong>
                  Data will be uploaded to an IoT Device Management telemetry topic.
                <a
                  className={classes.underlined}
                  href={mqttInfoLink}
                  target="_blank"
                >
                  Click Here
                </a>
                for more information
              </li>
            </ul>
          </div>
        </div>
      )}
      <div className={classnames("piiContainer", classes.piiContainer)}>
        <label className={classnames("piiTitle", classes.dataRangeTitle)}>
          Privacy
        </label>
          <DataDescriptionFormControlLabel
            className={classnames("hasPIIData", classes.piiFlag)}
            label="Data set contains PII data"
            control={(
              <Checkbox
                className={classnames("checkbox", classes.checkbox)}
                value={containsPII}
                color="primary"
                checked={containsPII}
                onChange={() => setContainsPIIData(!containsPII)}
              />
            )}
          />
        {containsPII && (
          <PiiCommentTextField
            className={classnames("piiComment", classes.piiComment)}
            label="PII Data Description"
            name="piiComment"
            value={piiComment}
            fullWidth={true}
            multiline={true}
            minRows={2}
            autoFocus={false}
            required={true}
            helperText="Description of the PII data in this data set (required if data set contains PII data)"
            InputLabelProps={inputLabelProps}
            variant="outlined"
            margin="none"
            onChange={formEventHandler(setPiiComment)}
          />
        )}
      </div>
      <div className={classnames("dataRangeContainer", classes.dataRangeContainer)}>
        <label className={classnames("dataRangeTitle", classes.dataRangeTitle)}>
          Data Range
        </label>
        <label className={classnames("dataRangeSubtitle", classes.dataRangeSubtitle)}>
          Please provide the estimated data ingestion volume and velocity (value must be greater than zero)
        </label>
        {showDataRangeError && (
          <label className={classnames("dataRangeErrorMessage", classes.dataRangeErrorMessage)}>
            {dataRangeErrorMessage}
          </label>
        )}
        <div className={classnames("dataRange", classes.dataRange)}>
          <DataVolumeTextField
            className={classnames("dataVolume", classes.dataVolume)}
            autoComplete="off"
            label="Data Volume (MB Per Month)"
            name="dataVolume"
            type="number"
            value={dataVolume}
            fullWidth={false}
            variant="outlined"
            margin="none"
            required={true}
            helperText={dataVolumeError || "Estimated volume of data that will be ingested for this data set"}
            error={dataVolumeError.length > 0}
            InputLabelProps={inputLabelProps}
            onChange={formNumberEventHandler(setDataVolume)}
          />
          <DataVelocityTextField
            className={classnames("dataVelocity", classes.dataVelocity)}
            autoComplete="off"
            label="Data Velocity (Bytes Per Second)"
            name="dataVelocity"
            type="number"
            value={dataVelocity}
            fullWidth={false}
            variant="outlined"
            margin="none"
            required={true}
            helperText={dataVelocityError || "Estimated velocity at which the data will be ingested for this data set"}
            error={dataVelocityError.length > 0}
            InputLabelProps={inputLabelProps}
            onChange={formNumberEventHandler(setDataVelocity)}
          />
        </div>
      </div>
      {isProductionAccount && (
        <React.Fragment>
          <div className={classnames("lifecycleContainer", classes.lifecycleContainer)}>
            <label className={classnames("lifecycleTitle", classes.lifecycleTitle)}>
              Data Lifecycle Policy (OPTIONAL)
            </label>
            <label className={classnames("lifecycleSubtitle", classes.lifecycleSubtitle)}>
              <b>Archiving in Days: </b>The number of days after which to archive the data.
              When this value is not set - or equal to zero - the data is not archived from the
              data lake.
            </label>
            <label className={classnames("lifecycleSubtitle", classes.lifecycleSubtitle)}>
              <b>Expiration in Days: </b>The number of days after which to expire (delete) the data.
              When this value is not set - or equal to zero - the data is not expired (deleted)
              from the data lake.
            </label>
            <label className={classnames("lifecycleSubtitle", classes.lifecycleSubtitle)}>
              If both values are provided, the number of days until the data is expired (deleted)
              must be greater than the number of days until the data is archived.
            </label>
            {showLifecycleError && (
              <label className={classnames("lifecycleErrorMessage", classes.lifecycleErrorMessage)}>
                {lifecycleErrorMessage}
              </label>
            )}
            <div className={classnames("lifecycle", classes.lifecycle)}>
              <ArchivingInDaysTextField
                className={classnames("archivingInDays", classes.archivingInDays)}
                autoComplete="off"
                label={archivingInDaysLabel}
                name="archivingInDays"
                type="number"
                value={archivingInDays === 0 ? "" : `${archivingInDays}`}
                fullWidth={false}
                variant="outlined"
                margin="none"
                helperText={archivingInDaysError || archivingInDaysHelperText }
                error={showArchivingInDaysError}
                InputLabelProps={inputLabelProps}
                onChange={formNumberEventHandler(setArchivingInDays)}
              />
              <ExpirationInDaysTextField
                className={classnames("expirationInDays", classes.expirationInDays)}
                autoComplete="off"
                label={expirationInDaysLabel}
                name="expirationInDays"
                type="number"
                value={expirationInDays === 0 ? "" : `${expirationInDays}`}
                fullWidth={false}
                variant="outlined"
                margin="none"
                helperText={expirationInDaysError || expirationInDaysHelperText}
                error={showExpirationInDaysError}
                InputLabelProps={inputLabelProps}
                onChange={formNumberEventHandler(setExpirationInDays)}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      {!isProductionAccount && (
        <DataDescriptionFormControlLabel
          className={classnames("confirmNotProductionFormControl", classes.confirmNotProductionFormControl)}
          label="Please confirm that this data set will not contain any production data"
          control={(
            <Checkbox
              className={classnames("checkbox", classes.checkbox)}
              value={confirmNonProductionData}
              color="primary"
              checked={confirmNonProductionData}
              onChange={formEventHandler(() => setConfirmNonProductionData(!confirmNonProductionData))}
            />
          )}
        />
      )}
      {children}
    </div>
  );
});

export default DataDescriptionView;
