import React from "react";
import classnames from "classnames";
import TextField from "@components/text-field";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { endPointsView as styles, textField } from "./styles";
import { ErrorView } from "@components";
import { formEventHandler, noop } from "@util";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";

const AuthEndpointTextField = withStyles(textField)(TextField);
const TokenEndpointTextField = withStyles(textField)(TextField);
const UserEndpointTextField = withStyles(textField)(TextField);
const DiscoveryDocTextField = withStyles(textField)(TextField);
const JwksUriTextField = withStyles(textField)(TextField);
const IssuerTextField = withStyles(textField)(TextField);

export interface Model {
  showInvalidJsonError?: boolean;
  authorizationEndpoint?: string;
  discoveryDocument?: string;
  tokenEndpoint?: string;
  userEndpoint?: string;
  jwksUri?: string;
  issuer?: string;
  federationClosed?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  setAuthEndpoint?: (authEndpoint: string) => void;
  setDiscoveryDocument?: (discoveryDocument: string) => void;
  setTokenEndpoint?: (tokenEndpoint: string) => void;
  setUserEndpoint?: (userEndpoint: string) => void;
  setJwksUri?: (jwksUri: string) => void;
  setIssuer?: (issuer: string) => void;
  setIsFederationClosed?: (value: boolean) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const EndpointsView = withStyles(styles)((props: Props) => {

  const {
    classes,
    showInvalidJsonError,
    authorizationEndpoint = "",
    discoveryDocument = "",
    tokenEndpoint = "",
    userEndpoint = "",
    jwksUri = "",
    issuer = "",
    federationClosed = true,
    children,
    setAuthEndpoint,
    setDiscoveryDocument,
    setTokenEndpoint,
    setUserEndpoint,
    setJwksUri,
    setIssuer,
    setIsFederationClosed = noop,
  } = props;

  if (showInvalidJsonError) {
    return (
      <div className={classnames("nameView", classes.errorViewContainer)}>
        <ErrorView
          title="JSON Syntax Error"
          message="Visual editors cannot be used until you have fixed the JSON syntax error."
        />
      </div>
    );
  }

  const onChangeIsFederationClosed = React.useCallback(event => {
    if (event && event.target && event.target.value) {
      setIsFederationClosed(event.target.value === "yes");
    }
  }, [setIsFederationClosed]);

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  return (
    <div className={classnames("endpointView", classes.container)}>
      <label className={classes.title}>
        Set Endpoints
      </label>
      <AuthEndpointTextField
        className={classnames("authorizationEndpoint", classes.textBox)}
        autoComplete="off"
        label="Authorization Endpoint"
        name="authorizationEndpoint"
        value={authorizationEndpoint}
        fullWidth={true}
        autoFocus={true}
        variant="outlined"
        margin="none"
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setAuthEndpoint)}
      />
      <TokenEndpointTextField
        className={classnames("tokenEndpoint", classes.textBox)}
        autoComplete="off"
        label="Token Endpoint"
        name="tokenEndpoint"
        value={tokenEndpoint}
        fullWidth={true}
        autoFocus={false}
        variant="outlined"
        margin="none"
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setTokenEndpoint)}
      />
      <UserEndpointTextField
        className={classnames("userEndpoint", classes.textBox)}
        autoComplete="off"
        label="User Endpoint"
        name="UserEndpoint"
        value={userEndpoint}
        fullWidth={true}
        autoFocus={false}
        variant="outlined"
        margin="none"
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setUserEndpoint)}
      />
      <JwksUriTextField
        className={classnames("jwksUri", classes.textBox)}
        autoComplete="off"
        label="JWKS Uri"
        name="jwksUri"
        value={jwksUri}
        fullWidth={true}
        autoFocus={false}
        variant="outlined"
        margin="none"
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setJwksUri)}
      />
      <IssuerTextField
        className={classnames("issuer", classes.textBox)}
        autoComplete="off"
        label="Issuer"
        name="issuer"
        value={issuer}
        fullWidth={true}
        autoFocus={false}
        variant="outlined"
        margin="none"
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setIssuer)}
      />
      <DiscoveryDocTextField
        className={classnames("discoveryDocument", classes.textBox)}
        autoComplete="off"
        label="Discovery Document"
        name="discoveryDocument"
        value={discoveryDocument}
        fullWidth={true}
        autoFocus={false}
        variant="outlined"
        margin="none"
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setDiscoveryDocument)}
      />
      <label className={classes.inputLabel}>
        Federation Closed
      </label>
      <RadioGroup
        className={classnames("radioGroup", "isFederationClosed", classes.radioGroup)}
        row={true}
      >
        <FormControlLabel
          className={classnames("radio", "yes", classes.radio)}
          label="Yes"
          control={(
            <Radio
              value="yes"
              checked={federationClosed}
              onChange={onChangeIsFederationClosed}
            />
          )}
        />
        <FormControlLabel
          className={classnames("radio", "no", classes.radio)}
          label="No"
          control={(
            <Radio
              value="no"
              checked={!federationClosed}
              onChange={onChangeIsFederationClosed}
            />
          )}
        />
      </RadioGroup>
      {children}
    </div>
  );
});

export default EndpointsView;
