import React from "react";
import { isQualityReportsListViewEnabled } from "@util";
import QualityReports from "./containers/QualityReports";
import { Redirect, Route, Switch } from "react-router-dom";
import { QualityReportsTab } from "./components/QualityReportsTabs";
import { getPathToQualityReport } from "@modules/qualityReportDetails/helpers";
import { BASE_URL, SECURITY_SERVICES_QUALITY_REPORTS_PATH } from "./constants";
import QualityReport, { QualityReportPlatformDomainId } from "@data/QualityReport";
import useSelectedTab from "./useSelectedTab";

const LIST_VIEW_ENABLED = isQualityReportsListViewEnabled();

const LATEST_SECURITY_SERVICES_USER_IDM_REPORT = new QualityReport({
  version: "latest",
  platformDomain: {
    id: "SECURITY_SERVICES",
  },
  group: {
    id: "user-idm",
  },
});

export function QualityReportsView() {

  const selectedTab = useSelectedTab();

  const platformDomainId = React.useMemo(() => {
    switch (selectedTab) {
      case QualityReportsTab.DATA_AND_ANALYTICS:
        return QualityReportPlatformDomainId.DATA_AND_ANALYTICS;
      case QualityReportsTab.COMMON_SERVICES:
        return QualityReportPlatformDomainId.COMMON_SERVICES;
      case QualityReportsTab.DEVICE_MANAGEMENT:
        return QualityReportPlatformDomainId.DEVICE_MANAGEMENT;
      case QualityReportsTab.SECURITY_SERVICES:
        return QualityReportPlatformDomainId.SECURITY_SERVICES;
      case QualityReportsTab.SELF_SERVICE_TOOLS:
        return QualityReportPlatformDomainId.SELF_SERVICE_TOOLS;
      default:
        return QualityReportPlatformDomainId.NONE;
    }
  }, [selectedTab]);

  return (
    <Switch>
      <Route path={BASE_URL} exact={true} strict={false}>
        <Redirect to={SECURITY_SERVICES_QUALITY_REPORTS_PATH}/>
      </Route>
      <Route>
        {LIST_VIEW_ENABLED && (
          <QualityReports
            selectedTab={selectedTab}
            platformDomainId={platformDomainId}
          />
        )}
        {!LIST_VIEW_ENABLED && (
          <Redirect to={getPathToQualityReport(LATEST_SECURITY_SERVICES_USER_IDM_REPORT)}/>
        )}
      </Route>
    </Switch>
  );
}

export default QualityReportsView;
