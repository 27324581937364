import React from "react";
import { Comparator, compare } from "@util";
import { ComparableItem } from "@components";
import { BASE_URL } from "@modules/dataWorkloadDetails/constants";
import { WorkloadConfiguration } from "@data/WorkloadConfiguration";
import { WorkloadKeyValueListColumn } from "./WorkloadKeyValueView";

export const getPathToWorkload = (id: string) =>
  `${BASE_URL}/${id}`;

export const createComparator = (sortedColumn: string, ascending: boolean):
  Comparator<WorkloadConfiguration> => (left: WorkloadConfiguration, right: WorkloadConfiguration) => {
  switch (sortedColumn) {
    case WorkloadKeyValueListColumn.VALUE:
      return compare(left.getValue(), right.getValue(), ascending);
    case WorkloadKeyValueListColumn.KEY:
    default:
      return compare(left.getKey(), right.getKey(), ascending);
  }
};

export const sortConfigListResult = (<Item extends ComparableItem<Item>>(props: {
  items: WorkloadConfiguration[],
  sortOrderAscending: boolean,
  sortByColumn: string
}) => {

  const { items, sortOrderAscending, sortByColumn } = props;
  const comparator = React.useMemo(() => createComparator(sortByColumn, sortOrderAscending),
    [sortByColumn, sortOrderAscending]);

  return React.useMemo(() => items.sort(comparator).slice(), [items, comparator]);
});
