import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  PortalModuleActionTypes,
  createPortalModuleDefaultState,
} from "@base";
import { ManagedPolicyInfoAttributes, ManagedPolicyInfo } from "@data";
import { PolicyStatementsListColumn } from "../../components/policy-statement-list/PolicyStatementsListColumn";

export interface ManagedPolicyDetailsSchema extends BasePortalModuleSchema {
  policyName: string;
  accountId: string;
  managedPolicy: ManagedPolicyInfoAttributes;
  nameFilter: string;
  sortByColumn: PolicyStatementsListColumn;
  sortOrderAscending: boolean;
}

export const DEFAULT_STATE: ManagedPolicyDetailsSchema = createPortalModuleDefaultState({
  policyName: "",
  accountId: "",
  managedPolicy: Object.freeze({ ...ManagedPolicyInfo.EMPTY.toJS() }),
  nameFilter: "",
  sortByColumn: PolicyStatementsListColumn.NONE,
  sortOrderAscending: true,
});

type ManagedPolicyDetailsActionTypes = PortalModuleActionTypes<ManagedPolicyDetailsSchema> & {
  FETCH_MANAGED_POLICY_DETAILS_REQUEST: string;
  FETCH_MANAGED_POLICY_DETAILS_SUCCESS: string;
  FETCH_MANAGED_POLICY_DETAILS_FAILURE: string;
};

export const ACTION_TYPES: ManagedPolicyDetailsActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<ManagedPolicyDetailsSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    FETCH_MANAGED_POLICY_DETAILS_REQUEST: `${MODULE_ID}/fetch_managed_policy_details_request`,
    FETCH_MANAGED_POLICY_DETAILS_SUCCESS: `${MODULE_ID}/fetch_managed_policy_details_success`,
    FETCH_MANAGED_POLICY_DETAILS_FAILURE: `${MODULE_ID}/fetch_managed_policy_details_failure`,
  },
});

export const ManagedPolicyDetailsReducer = combineReducers<ManagedPolicyDetailsSchema>(
  createPortalModuleReducers<ManagedPolicyDetailsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default ManagedPolicyDetailsReducer;
