import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      padding: "20px 20px 12px",
      minWidth: 500,
    },
    row: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "space-between",
    },
    requiredContainer: {
      marginLeft: 8,
    },
    textField: {
      fontSize: 12,
      padding: 0,
      height: 50,
    },
    inputLabel: {
      fontSize: 12,
    },
    nameTextField: {
    },
    descriptionTextField: {
      marginTop: 15,
    },
    requiredCheckbox: {
    },
    controls: {
      margin: "10px 2px 5px 0",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    cancelButton: {
    },
    saveButton: {
      marginLeft: 10,
    },
  });

export default styles;
