import React from "react";
import styles from "./styles";
import classnames from "classnames";
import { ErrorView } from "@components/error-view";
import useWizardSteps from "./useWizardSteps";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import WizardStepper, { WizardStepperActions, WizardStepperModel } from "./WizardStepper";
import WizardControls, { WizardControlsActions, WizardControlsModel } from "./WizardControls";
import { Fab } from "@components/fab";

export interface WizardModel<Step> extends WizardControlsModel, WizardStepperModel<Step> {
  className?: string;
  documentationLink?: string;
  errorTitle?: string;
  errorMessage?: string;
  errors?: string[];
  showTopControls?: boolean;
  statusCode?: number;
}

export interface WizardActions<Step> extends WizardControlsActions, WizardStepperActions<Step> {
  mapStepToView?: (step: Step) => React.ReactNode | string | null;
  onChangeCurrentStep?: (step: Step) => void;
}

type Props<Step = string> = WithStyles<typeof styles> & WizardModel<Step> & WizardActions<Step> & {
  children?: React.ReactNode;
};

const Wizard = withStyles(styles)(<Step, >(props: Props<Step>) => {

  const {
    classes,
    className,
    documentationLink,
    errorTitle,
    errorMessage,
    errors,
    showTopControls,
    statusCode,
    mapStepToView = () => null,
    children,
    ...otherProps
  } = props;

  const [wizardState, wizardActions] = useWizardSteps(otherProps);

  const wizardProps = React.useMemo(() => ({
    ...wizardState,
    ...wizardActions,
    ...otherProps,
  }), [wizardState, wizardActions, otherProps]);

  const { currentStep } = wizardProps;

  const controls = React.useMemo(() => (
    <WizardControls
      {...wizardProps}
      className={classnames("controls", classes.controls)}
    />
  ), [classes, wizardProps]);

  return (
    <div className={classnames("wizard", className, classes.container)}>
      <WizardStepper
        {...wizardProps}
        className={classnames("stepper", classes.stepper)}
      />
      <ErrorView
        className={classnames("error", classes.error)}
        title={errorTitle}
        message={errorMessage}
        errors={errors}
        statusCode={statusCode}
      />
      {showTopControls && controls}
      <div className={classnames("content", classes.content)}>
        {mapStepToView(currentStep)}
        {children}
      </div>
      {controls}
      {documentationLink && (
        <Fab className={classnames("documentation", classes.documentation)} variant="extended">
          <a href={documentationLink} target="_blank" rel="noopener noreferrer">
            DOCUMENTATION
          </a>
        </Fab>
      )}
    </div>
  );
});

export { Wizard };
export default Wizard;
