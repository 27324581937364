import { createStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createTheme";
import Color from "@Color";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "flex-start",
    },
    wizardPage: {
      alignItems: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    uploadButton: {
      cursor: "pointer",
    },
    content: {
      flex: "1 0 auto",
      color: Color.TEXT,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      padding: 16,
      borderRadius: 6,
      textAlign: "center",
      marginTop: 16,
      border: `1px dashed ${Color.TEXT}`,
    },
    contentDragging: {
      backgroundColor: Color.LIGHT_GREY2,
    },
    contentSelected: {
      backgroundColor: Color.LIGHT_GREY,
    },
    label: {
      flex: "0 0 auto",
      margin: 8,
      fontSize: 18,
      lineHeight: "21px",
      fontWeight: 400,
    },
    alert: {
      marginTop: 25,
    },
    alertTitle: {
      fontSize: 14,
      marginTop: 2,
    },
    alertDescription: {
      fontSize: 14,
      lineHeight: "21px",
      marginTop: 10,
      marginBottom: 0,
      paddingLeft: 20,
    },
    underlined: {
      marginLeft: 4,
      marginRight: 4,
      textDecoration: "underline",
    },
    loadingIndicator: {
    },
    dialog: {
    },
    dialogLabel: {
      flex: "0 0 auto",
      margin: 8,
      fontSize: 18,
      lineHeight: "21px",
      fontWeight: 400,
    },
    backdrop: {
      background: "rgba(105, 105, 105, 0.25)",
      zIndex: theme.zIndex.modal,
    },
  });

export default styles;
