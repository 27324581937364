import { SecurityGroup } from "@data";
import { SecurityGroupsListColumn } from "./SecurityGroupsListColumn";
import { Comparator, compare, isEmptyString } from "@util";
import { BASE_URL } from "@modules/securityGroups/constants";

export const getPathToSecurityGroup = (id: string) =>
  `${BASE_URL}/${id}`;

export const getSearchResults = (items: SecurityGroup[], searchQuery: string) => {

  const groups = items.slice();

  if (groups.length === 0 || isEmptyString(searchQuery)) {
    return groups;
  }

  const searchFilter = searchQuery.toLowerCase();

  return groups.filter((group: SecurityGroup) =>
    group.getName().toLowerCase().indexOf(searchFilter) >= 0 ||
    group.getPath().toLowerCase().indexOf(searchFilter) >= 0);
};

const compareByName = (left: SecurityGroup, right: SecurityGroup, ascending: boolean) =>
  compare(left.getName(), right.getName(), ascending);

const compareByPath = (left: SecurityGroup, right: SecurityGroup, ascending: boolean) => {
  const result = compare(left.getPath(), right.getPath(), ascending);
  return result !== 0 ? result : compareByName(left, right, ascending);
};

export const createComparator =
  (sortedColumn: SecurityGroupsListColumn, ascending: boolean): Comparator<SecurityGroup> =>
    (left: SecurityGroup, right: SecurityGroup) => {
      switch (sortedColumn) {
        case SecurityGroupsListColumn.NAME:
          return compareByName(left, right, ascending);
        case SecurityGroupsListColumn.PATH:
          return compareByPath(left, right, ascending);
        default:
          return 0;
      }
    };
