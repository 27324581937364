import { Comparator, compare, getStringValue, isEmptyString } from "@util";
import ListViewItem from "@components/module-list-view/ListViewItem";
import ColumnAttributes, {
  DEFAULT_COLUMN_ATTRIBUTES,
  ModuleListColumn,
} from "@components/module-list-view/ColumnAttributes";
import React from "react";
import { ComparableItem } from "@components";

const getSearchResults = (<Item extends ComparableItem<Item>>(moduleItems: ListViewItem<Item>[],
                                                              searchQuery: string) => {

  const items = moduleItems.slice();

  const searchFilter = searchQuery.toLowerCase();

  if (items.length === 0 || isEmptyString(searchQuery)) {
    return items;
  }

  return items.filter((item: ListViewItem<Item>) =>
    item.columnAttributes.some((colAttrs: ColumnAttributes<Item>) =>
      getStringValue(colAttrs.value).toLowerCase().indexOf(searchFilter) >= 0 ||
      getStringValue(colAttrs.chipTags.toString()).toLowerCase().indexOf(searchFilter) >= 0,
    ));

});

const createComparator =
  (<Item extends ComparableItem<Item>>(sortedColumn: string, ascending: boolean):
    Comparator<ListViewItem<Item>> =>
    (left: ListViewItem<Item>, right: ListViewItem<Item>) => {

      let rightItem = DEFAULT_COLUMN_ATTRIBUTES;
      let leftItem = DEFAULT_COLUMN_ATTRIBUTES;

      left.columnAttributes.map(leftColAttr => {
        if (leftColAttr.column === sortedColumn) {
          leftItem = leftColAttr;
          const rightAttr = right.columnAttributes.find(rightColAttr => rightColAttr.column === leftColAttr.column);
          rightItem = rightAttr !== undefined ? rightAttr : DEFAULT_COLUMN_ATTRIBUTES;
        }
      });

      if (leftItem.chipTags.length > 0 || rightItem.chipTags.length > 0) {
        return compare(leftItem.chipTags.sort().join(","), rightItem.chipTags.sort().join(","), ascending);
      } else {
        return compare(leftItem.value, rightItem.value, ascending);
      }
    });

export const sortAndFilterListViewResult = (<Item extends ComparableItem<Item>>(props: {
  items: ListViewItem<Item>[],
  nameFilter: string,
  sortOrderAscending: boolean,
  sortByColumn: string
}) => {

  const {items, nameFilter, sortOrderAscending, sortByColumn} = props;

  const comparator = React.useMemo(() =>
    createComparator(sortByColumn, sortOrderAscending), [sortByColumn, sortOrderAscending]);

  const sortedResults = React.useMemo(() => items.sort(comparator).slice(), [items, comparator]);

  return React.useMemo(() =>
    getSearchResults(sortedResults, nameFilter), [sortedResults, nameFilter]);
});

export function createColumns(arr: any): ColumnAttributes<any>[] {
  return arr.map((obj: ColumnAttributes<any>) => new ModuleListColumn(obj));
}
