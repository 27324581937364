import React from "react";
import classnames from "classnames";
import { IconButton } from "@components/icon-button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { HelpIcon } from "@icons";
import { clickHandler, noop } from "@util";
import { TooltipIcon } from "../tooltip";
import { Vector } from "@data";
import styles from "./styles";

const ExpandButton = (props: WithStyles<typeof styles> & {
  className?: string,
  buttonClassName?: string,
  tooltipClassName?: string,
  tooltip?: string,
  expanded?: boolean,
  setExpanded?: (expanded: boolean) => void,
}) => {

  const {
    classes,
    className,
    buttonClassName,
    tooltipClassName,
    tooltip,
    expanded = false,
    setExpanded = noop,
  } = props;

  const Icon = expanded ? ExpandLessIcon : ExpandMoreIcon;

  const onClick = clickHandler(React.useCallback(() =>
    setExpanded(!expanded), [setExpanded, expanded]));

  return (
    <div className={classnames("expandButton", className, classes.container)}>
      <IconButton
        className={classnames("iconButton", buttonClassName, classes.iconButton)}
        onClick={onClick}
      >
        <Icon className={classes.icon} />
      </IconButton>
      {tooltip && (
        <div className={classes.tooltipIcon}>
          <TooltipIcon
            className={classnames("tooltip", tooltipClassName, classes.iconButton)}
            icon={HelpIcon}
            size={Vector.square(15)}
            tooltipText={tooltip}
            onClick={onClick}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ExpandButton);
