import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import { SecurityServiceRegional } from "@data";
import { ServicesListColumn } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import RegionalServicesList, {
  RegionalServicesListActions,
  RegionalServicesListModel,
} from "@components/regional-services-list";
import styles from "./styles";

const COLUMNS: ServicesListColumn[] = [ServicesListColumn.ID];

export interface SelectServiceModel extends RegionalServicesListModel {
  className?: string;
}

export interface SelectServiceActions extends RegionalServicesListActions {
  setSelectedService?: (service: SecurityServiceRegional) => void;
}

type Model = SelectServiceModel;
type Actions = SelectServiceActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const SelectServiceRegional = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    columns = COLUMNS,
    items = [],
    selectedItems = [],
    showLoadMoreButton,
    showSearch: showSearchView,
    showLoadingIndicator,
    errorMessage,
    setSelectedService = noop,
    ...otherProps
  } = props;

  const serviceSelected = React.useMemo(() =>
    selectedItems.length > 0,
    [selectedItems]);

  // We only override the show search view property if a service is selected
  const showSearch = React.useMemo(() =>
    showSearchView && !serviceSelected,
    [showSearchView, serviceSelected]);

  const showLoadMore = React.useMemo(() =>
      serviceSelected ? false : showLoadMoreButton,
    [serviceSelected, showLoadMoreButton]);

  const showLoading = React.useMemo(() =>
      serviceSelected ? false : showLoadingIndicator,
    [serviceSelected, showLoadingIndicator]);

  const error = React.useMemo(() =>
      serviceSelected ? "" : errorMessage,
    [serviceSelected, errorMessage]);

  const services = React.useMemo(() => {
    if (serviceSelected) {
      return selectedItems.slice();
    } else {
      return items.slice();
    }
  }, [serviceSelected, items, selectedItems]);

  const setSelectedItems = React.useCallback((selectedServices: SecurityServiceRegional[]) =>
    setSelectedService(selectedServices.pop()), [setSelectedService]);

  return (
    <RegionalServicesList
      {...otherProps}
      className={classnames("selectServiceRegional", className, classes.container)}
      items={services}
      columns={columns}
      showSearch={showSearch}
      showLoadMoreButton={showLoadMore}
      showLoadingIndicator={showLoading}
      errorMessage={error}
      selectable={true}
      selectAllDisabled={true}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
    />
  );
});

export default SelectServiceRegional;
