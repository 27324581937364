import React from "react";
import { clickHandler, equalsIgnoreCase, isEmptyString, noop } from "@util";
import { connect } from "react-redux";
import startCase from "lodash/startCase";
import { AppSchema } from "@main/schemas";
import { isUserPrincipal } from "@main/selectors";
import { ContinueButton, DetailsView, DetailsViewRoute } from "@components";
import { DataSetDetailSubType, DataSetDetailType, SummaryViewData } from "@data";
import {
  getCurrentUserId,
  getDataSetAccountId,
  getDataSetAlias,
  getDataSetId,
} from "../selectors";
import SummaryView from "../components/SummaryView";
import DataLakeView from "../components/DataLakeView";
import AccessRequestsView from "../components/AccessRequestsView";
import { useGetDataSetAccessRequests, useDataSet } from "@hooks";
import { ModuleIcon } from "../components/styles";

const DATA_LAKE_BROWSER_MODULE_DISABLED =
  !equalsIgnoreCase("true", process.env.REACT_APP_DATA_LAKE_BROWSER_MODULE_ENABLED);

interface ContainerModel {
  dataSetAlias?: string;
  accountId?: string;
  currentUserId?: string;
  dataSetId?: string;
  requestAccessVisible?: boolean;
}

interface ContainerActions {
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
  requestAccess?: (dataSetId: string) => void;
}

type Props = ContainerModel & ContainerActions;

const DataSetDetailsContainer = (props: Props) => {

  const {
    dataSetAlias = "",
    accountId = "",
    currentUserId = "",
    trackRequestEvent = noop,
    trackSuccessEvent = noop,
    trackErrorEvent = noop,
    requestAccess = noop,
  } = props;

  const [ model, {refresh: refreshSummary}] = useDataSet({
    dataSetAlias,
    accountId,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
  });

  const [{ dataAccessRequests, showAccessDenied: showAccessRequestsDenied, ...accessRequestsModel },
    {refresh: refreshAccessRequests }] = useGetDataSetAccessRequests({ accountId, dataSetId: dataSetAlias });

  const {
    dataSet,
    showNotFound,
    showAccessDenied,
    showProgressIndicator
  } = model;

  const accessRequestsTabVisible = React.useMemo(() => !isEmptyString(currentUserId) &&
    dataSet.getDataOwners().some(dataOwner => dataOwner.toLowerCase().indexOf(currentUserId.toLowerCase()) >= 0),
    [currentUserId, dataSet]);

  const dataLakeButtonVisible = React.useMemo(() => !DATA_LAKE_BROWSER_MODULE_DISABLED &&
      DataSetDetailType.DATA_LAKE === dataSet.getDataSetType() &&
      DataSetDetailSubType.ACQUIRED === dataSet.getDataSetSubType(),
    [dataSet]);

  const summaryViewItems = React.useMemo(() =>
      showProgressIndicator ? [] : [
        new SummaryViewData({
          className: "dataSetName",
          name: "Data Set Name",
          value: dataSet.getDataSetAlias(),
        }),
        new SummaryViewData({
          className: "accountId",
          name: "Account ID",
          value: dataSet.getDataSetAccountId(),
        }),
        new SummaryViewData({
          className: "dataSetType",
          name: "Type",
          value: startCase(dataSet.getDataSetType().toLowerCase()),
        }),
        new SummaryViewData({
          className: "dataSetSubtype",
          name: "Subtype",
          value: startCase(dataSet.getDataSetSubType().toLowerCase()),
        }),
        new SummaryViewData({
          className: "description",
          name: "Description",
          value: dataSet.getDataSetDescription(),
        }),
        new SummaryViewData({
          className: "dataSetGroup",
          name: "Data Set Group",
          value: dataSet.getDataSetGroup(),
        }),
        new SummaryViewData({
          className: "dataSetState",
          name: "State",
          value: startCase(dataSet.getDataSetState().toLowerCase()),
        }),
        new SummaryViewData({
          className: "dataModelReference",
          name: "Reference",
          value: dataSet.getDataModelReference(),
        }),
        new SummaryViewData({
          className: "containsPii",
          name: "Contains PII?",
          value: dataSet.containsPii() ? "Yes" : "No",
        }),
        new SummaryViewData({
          className: "piiComment",
          name: "PII Comment",
          value: dataSet.getPiiComment(),
        }),
      ],
    [dataSet, showProgressIndicator]);

  const summaryView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
    <SummaryView
      summaryViewItems={summaryViewItems}
      dataSetOwners={dataSet.getDataOwnerEmails()}
      tags={dataSet.getTags()}
      loading={showProgressIndicator}
    />
    );
  }, [summaryViewItems, dataSet, showProgressIndicator]);

  const dataLakeView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <DataLakeView
        dataSetAlias={dataSetAlias}
        accountId={accountId}
      />
    );
  }, [dataSetAlias, accountId]);

  const accessRequestsView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <AccessRequestsView
        accessRequests={dataAccessRequests}
        showAccessDenied={showAccessRequestsDenied}
        refresh={refreshAccessRequests}
      />
    );
  }, [dataAccessRequests, showAccessRequestsDenied, refreshAccessRequests]);

  const ROUTES: DetailsViewRoute[] = React.useMemo(() => [
    {
      id: "summary",
      name: "Summary",
      view: summaryView,
    },
    {
      id: "dataLake",
      name: "Data Lake",
      view: dataLakeView,
      disabled: !dataLakeButtonVisible,
      path: "/data-lake"
    },
    {
      id: "accessRequests",
      name: "Access Requests",
      view: accessRequestsView,
      disabled: !accessRequestsTabVisible,
      path: "/access-requests"
    },
  ], [dataLakeButtonVisible, accessRequestsTabVisible, summaryView, dataLakeView, accessRequestsView]);

  const refreshDetails = () => {
    refreshSummary();
    refreshAccessRequests();
  };

  const requestDataSetAccess = React.useCallback(() => {
    requestAccess();
  }, [requestAccess]);

  const requestAccessButton = React.useMemo(() => (
    <ContinueButton
      label="Request Data Set Access"
      onClick={clickHandler(requestDataSetAccess)}
    />
  ), [requestDataSetAccess]);

  return (
      <DetailsView
        {...props}
        {...model}
        {...(accessRequestsTabVisible ? accessRequestsModel : {})}
        tabsMarginTop={false}
        title={dataSetAlias}
        routes={ROUTES}
        icon={ModuleIcon}
        refresh={refreshDetails}
        showLoadingIndicator={showProgressIndicator}
        headerButtons={requestAccessButton}
        showAccessDenied={showAccessDenied}
        showNotFound={showNotFound}
      />
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
  dataSetAlias: getDataSetAlias(state),
  accountId: getDataSetAccountId(state),
  currentUserId: getCurrentUserId(state),
  dataSetId: getDataSetId(state),
  requestAccessVisible: isUserPrincipal(state),
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DataSetDetailsContainer);
