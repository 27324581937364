import { AppSchema } from "@schemas";
import { getAuthToken } from "@main/selectors";
import { NamespaceClient, RestClientError } from "@network";
import { getNamespace, isNamespaceValid } from "./selectors";
import { CreateNamespaceAction, CreateNamespaceActionType } from "./reducers";

type Action = CreateNamespaceAction;

export const setNamespace = (value: string = ""): Action => ({
  type: CreateNamespaceActionType.SET_NAMESPACE,
  value: value || "",
});

export const setErrorMessage = (value: string = ""): Action => ({
  type: CreateNamespaceActionType.SET_ERROR_MESSAGE,
  value: value || "",
});

export const clearErrorMessage = (): Action => setErrorMessage("");

export const setSuccessMessage = (value: string = ""): Action => ({
  type: CreateNamespaceActionType.SET_SUCCESS_MESSAGE,
  value: value || "",
});

export const clearSuccessMessage = (): Action => setSuccessMessage("");

export const showAccessDenied = (): Action => ({
  type: CreateNamespaceActionType.TOGGLE_SHOW_ACCESS_DENIED,
  value: true,
});

export const hideAccessDenied = (): Action => ({
  type: CreateNamespaceActionType.TOGGLE_SHOW_ACCESS_DENIED,
  value: false,
});

export const showProgressIndicator = (): Action => ({
  type: CreateNamespaceActionType.TOGGLE_SHOW_PROGRESS_INDICATOR,
  value: true,
});

export const hideProgressIndicator = (): Action => ({
  type: CreateNamespaceActionType.TOGGLE_SHOW_PROGRESS_INDICATOR,
  value: false,
});

export const createNamespaceRequest = (): Action => ({
  type: CreateNamespaceActionType.CREATE_NAMESPACE_REQUEST,
});

export const createNamespaceRequestSuccess = (): Action => ({
  type: CreateNamespaceActionType.CREATE_NAMESPACE_REQUEST_SUCCESS,
});

export const createNamespaceRequestFailure = (error: string): Action => ({
  type: CreateNamespaceActionType.CREATE_NAMESPACE_REQUEST_FAILURE,
  value: error,
});

export const save = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  if (!isNamespaceValid(state)) {
    return dispatch(setErrorMessage("Namespace is required"));
  }

  const authToken = getAuthToken(state);
  const namespace = getNamespace(state);

  dispatch(showProgressIndicator());
  dispatch(clearErrorMessage());
  dispatch(clearSuccessMessage());
  dispatch(createNamespaceRequest());

  return NamespaceClient.createNamespace(authToken, namespace)
    .then(() => {

      dispatch(createNamespaceRequestSuccess());
      dispatch(hideProgressIndicator());
      return dispatch(setSuccessMessage("Namespace Created"));

    }, (response: RestClientError) => {

      const {analytic, error = "Failed to create Namespace"} = response;

      dispatch(createNamespaceRequestFailure(analytic));
      dispatch(hideProgressIndicator());

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(setErrorMessage(error));
    });
};

export const reset = () => (dispatch: any) => {
  dispatch(setNamespace());
  dispatch(clearErrorMessage());
  dispatch(clearSuccessMessage());
  dispatch(hideAccessDenied());
  return dispatch(hideProgressIndicator());
};
