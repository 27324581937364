import React from "react";
import useApiRequest, {
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import QualityReport, {
  QualityReportAttributes,
  QualityReportPlatformDomainId,
} from "@data/QualityReport";
import usePortalQualityReportsClient from "../use-portal-quality-reports-client";

type SuccessResponse = QualityReportAttributes;

export interface UseGetQualityReportProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  platformDomainId?: QualityReportPlatformDomainId;
  groupId?: string;
  version?: string;
}

export interface UseGetQualityReportModel extends UseApiRequestModel<SuccessResponse> {
  report: QualityReport;
}

export interface UseGetQualityReportActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetQualityReportProps;
type Model = UseGetQualityReportModel;
type Actions = UseGetQualityReportActions;
type Result = [Model, Actions];

export const useGetQualityReport = (props: Props): Result => {

  const {
    platformDomainId = QualityReportPlatformDomainId.NONE,
    groupId = "",
    version = "latest",
    defaultErrorMessage = "Failed to get quality report",
    ...otherProps
  } = props;

  const PortalQualityReportsClient = usePortalQualityReportsClient();

  const makeApiRequest = React.useCallback(() =>
      PortalQualityReportsClient.getQualityReport(platformDomainId, groupId, version),
    [PortalQualityReportsClient, platformDomainId, groupId, version]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const report = React.useMemo<QualityReport>(() => {
    return new QualityReport(successResponse || {});
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    report,
  }), [
    baseModel,
    report,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetQualityReport;
