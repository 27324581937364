import { Record } from "immutable";

export interface QualityReportDefectsAttributes {
  blocker: number;
  critical: number;
  major: number;
  minor: number;
  info: number;
}

export class QualityReportDefects extends Record({
  blocker: 0,
  critical: 0,
  major: 0,
  minor: 0,
  info: 0,
}) implements QualityReportDefectsAttributes {

  public static EMPTY: QualityReportDefects = new QualityReportDefects();

  public readonly blocker: number;
  public readonly critical: number;
  public readonly major: number;
  public readonly minor: number;
  public readonly info: number;

  public getNumBlockerDefects(): number {
    return Math.max(0, this.blocker);
  }

  public getNumCriticalDefects(): number {
    return Math.max(0, this.critical);
  }

  public getNumMajorDefects(): number {
    return Math.max(0, this.major);
  }

  public getNumMinorDefects(): number {
    return Math.max(0, this.minor);
  }

  public getNumInfoDefects(): number {
    return Math.max(0, this.info);
  }
}

export default QualityReportDefects;
