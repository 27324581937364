import React from "react";
import { hasSpecialChars, isEmptyString, noop } from "@util";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { CreateSecurityServiceRegionalRequest, SecurityServiceRegional } from "@data";
import ServiceInfo from "../components/ServiceInfo";
import { SecretDialog } from "@components";
import { useCreateServiceRegional } from "@hooks";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel
} from "@components/submit-api-request-view";

interface ContainerModel extends SubmitApiRequestViewModel<SecurityServiceRegional> {
}

interface ContainerActions extends SubmitApiRequestViewActions<SecurityServiceRegional> {
  showServiceDetails?: (service: SecurityServiceRegional) => void;
}

type Props = ContainerModel & ContainerActions;

const BODY_TEXT = "This is the ONLY time the service secret can be viewed. " +
  "You cannot retrieve this secret later; however, you can generate a new secret at any time.";

const CreateServiceContainer = (props: Props) => {

  const {
    showServiceDetails: showServiceDetailsPage = noop,
    ...otherProps
  } = props;

  const [data, setData] =
    React.useState<CreateSecurityServiceRegionalRequest>(CreateSecurityServiceRegionalRequest.EMPTY);

  const [{ service, secret, showSuccessView, errorMessage, successMessage, ...otherModel }, { createService }] =
    useCreateServiceRegional({ data });

  const setServiceId = React.useCallback((serviceId: string) =>
    setData(
      new CreateSecurityServiceRegionalRequest({
        ...data,
        id: serviceId
    })), [data, setData]);

  const setProtocol = React.useCallback((protocol: string) =>
    setData(new CreateSecurityServiceRegionalRequest({
      ...data,
      protocol: protocol,
    })), [data, setData]);

  const disabled = React.useMemo(() =>
    isEmptyString(data.getId()) || hasSpecialChars(data.getId()), [data]);

  return (
    <SubmitApiRequestView
      {...otherModel}
      {...otherProps}
      snackbarId="create-service-regional"
      errorTitle="Create Service Failed"
      saveButtonLabel="Create Service"
      currentState={service}
      defaultState={SecurityServiceRegional.EMPTY}
      onSuccess={() => showServiceDetailsPage(service)}
      saveButtonDisabled={disabled}
      unsavedChangesGuardEnabled={!isEmptyString(service.getId())}
      showSuccessIndicator={showSuccessView}
      errorMessage={errorMessage}
      save={createService}
    >
      <ServiceInfo
        setServiceId={setServiceId}
        setProtocol={setProtocol}
        serviceId={data.getId()}
        protocol={data.getProtocol()}
      />
      <SecretDialog
        className="serviceSecretDialog"
        title="Service Secret"
        fileName={service.getId()}
        open={!isEmptyString(secret)}
        secret={secret}
        secretFieldLabel="Service Secret"
        bodyText={BODY_TEXT}
        closeDialog={() => showServiceDetailsPage(service)}
      />
    </SubmitApiRequestView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(CreateServiceContainer);
