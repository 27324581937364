import {
  LEGACY_MODULE_PATH as LEGACY_BASE_URL,
  MODULE_PATH as BASE_URL,
} from "@modules/dataAccessRequests/constants";

export const getDataAccessRequestPath = (id: string) => `${BASE_URL}/${id}/create`;

export const getLegacyDataAccessRequestPath = (id: string) => `${LEGACY_BASE_URL}/${id}/create`;

export const isValidAwsAccountId = (accountId: string) => {
  return /^[0-9]{12}$/.test(accountId);
};
