import React from "react";
import * as echarts from "echarts";
import classnames from "classnames";
import QualityReport from "@data/QualityReport";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

type EChartsOption = echarts.EChartOption;

const createSeriesBarChartOption = (attrs: Partial<echarts.EChartOption.SeriesBar> = {}) => ({
  ...attrs,
  type: "bar",
  smooth: true,
  seriesLayoutBy: "row",
  emphasis: { focus: "series" },
});

const createChartOptions = (reports: QualityReport[],
                            showLineCoverageOnly: boolean = true) => ({

  tooltip: {},

  // Legend only makes sense if there is more than one data source
  ...(showLineCoverageOnly ? ({}) : ({ legend: {} })),

  xAxis: [
    {
      type: "category",
      data: reports.map(report => `${report.getVersion()}`).reverse(),
    },
  ],

  yAxis: [
    {
      type: "value",
      min: 0,
      max: 100,
      axisLabel: {
        formatter: "{value} %",
      },
    },
  ],

  dataset: {
    source: showLineCoverageOnly ? ([
      ["Code Coverage"].concat(
        reports.map(report => `${report.getVersion()}`)),
      (["Code Coverage"] as (string | number)[]).concat(
        reports.map(report => report.getLineCoverage())),
    ]) : ([
      ["Code Coverage"].concat(
        reports.map(report => `${report.getVersion()}`)),
      (["Method Coverage"] as (string | number)[]).concat(
        reports.map(report => report.getMethodCoverage())),
      (["Class Coverage"] as (string | number)[]).concat(
        reports.map(report => report.getClassCoverage())),
      (["Branch Coverage"] as (string | number)[]).concat(
        reports.map(report => report.getBranchCoverage())),
      (["Instruction Coverage"] as (string | number)[]).concat(
        reports.map(report => report.getInstructionCoverage())),
      (["Complexity Coverage"] as (string | number)[]).concat(
        reports.map(report => report.getComplexityCoverage())),
      (["Line Coverage"] as (string | number)[]).concat(
        reports.map(report => report.getLineCoverage())),
    ]),
  },

  series: (showLineCoverageOnly ? ([
    createSeriesBarChartOption(),
  ]) : ([
    createSeriesBarChartOption(), // Method Coverage
    createSeriesBarChartOption(), // Class Coverage
    createSeriesBarChartOption(), // Branch Coverage
    createSeriesBarChartOption(), // Instruction Coverage
    createSeriesBarChartOption(), // Complexity Coverage
    createSeriesBarChartOption(), // Line Coverage
  ])),
});

export interface CodeCoverageHistoryModel {
  className?: string;
  showLineCoverageOnly?: boolean;
  reports?: QualityReport[];
}

export interface CodeCoverageHistoryActions {
}

type Model = CodeCoverageHistoryModel;
type Actions = CodeCoverageHistoryActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const CodeCoverageHistory = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    showLineCoverageOnly,
    reports = [],
    children,
  } = props;

  const ref = React.useRef<HTMLDivElement>(null);

  const chartOptions = React.useMemo(() =>
      createChartOptions(reports, showLineCoverageOnly) as EChartsOption,
    [reports, showLineCoverageOnly]);

  React.useLayoutEffect(() => {
    if (ref && ref.current) {
      echarts.init(ref.current);
    }
  }, [ref]);

  React.useEffect(() => {
    if (ref && ref.current) {
      const chart = echarts.getInstanceByDom(ref.current);
      if (chart) {
        chart.setOption(chartOptions);
      }
    }
  }, [ref, chartOptions]);

  return (
    <div className={classnames("codeCoverageHistory", className, classes.container)}>
      <div
        ref={ref}
        className={classnames("chart", classes.chart)}
      />
      {children}
    </div>
  );
});

export default CodeCoverageHistory;
