import { Record } from "immutable";
import { getStringValue } from "@util";
import { DeviceSchemaAttributes } from "@data/DeviceSchema";

export interface DeviceConfigurationAttributes {
  typeIdentity: string;
  actualItems: DeviceSchemaAttributes[];
  desiredItems: DeviceSchemaAttributes[];
}

export class DeviceConfiguration extends Record({
  typeIdentity: "",
  actualItems: [],
  desiredItems: [],
}) implements DeviceConfigurationAttributes {

  public static EMPTY: DeviceConfiguration = new DeviceConfiguration();

  public readonly typeIdentity: string;
  public readonly actualItems: DeviceSchemaAttributes[];
  public readonly desiredItems: DeviceSchemaAttributes[];

  public getTypeIdentity(): string {
    return getStringValue(this.typeIdentity);
  }

  public getActualSchemaData(): DeviceSchemaAttributes[] {

    if (!Array.isArray(this.actualItems)) {
      return [];
    }

    return this.actualItems;
  }

  public getDesiredSchemaData(): DeviceSchemaAttributes[] {

    if (!Array.isArray(this.desiredItems)) {
      return [];
    }

    return this.desiredItems;
  }
}

export default DeviceConfiguration;
