import createStyles from "@material-ui/core/styles/createStyles";
import { StyleRules } from "@material-ui/core/styles";
import Color from "@components/styles/Color";

export const textField: StyleRules<string> = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& input": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      padding: "8px 10px",
      margin: 0,
    },
  },
};

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    inputField: {
      flex: "1 0 auto",
    },
    textField: {
      color: Color.TEXT,
      fontSize: 16,
      height: "auto",
      minWidth: "300px",
      maxWidth: "700px",
    },
    formHelperTextError: {
      fontSize: 12,
    },
    controls: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 16,
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 10,
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
    textRed: {
      color: Color.RED
    },
    additionalText: {
      marginLeft: 5,
    },
    secret: {
      flex: "1 0 auto",
    },
    copyControls: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    copyToClipboardButton: {
      width: "auto !important",
      height: "auto !important",
      padding: 12,
    },
    tooltipIconPopper: {
      marginTop: 12,
      justifyContent: "flex-end",
    },
  });

export default styles;
