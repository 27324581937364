import { useApiRequestAnalytics } from "@hooks";
import { SecurityGroupAttributes } from "@data";
import {
  CreateSecurityGroupResponse,
  GetSecurityGroupIdentitiesResponse,
  GetSecurityGroupsResponse,
  SecurityGroupClient,
} from "@network";

export type CreateSecurityGroupApi = (json: string) => Promise<CreateSecurityGroupResponse>;

export type DeleteSecurityGroupApi = (groupName: string) => Promise<void>;

export type GetSecurityGroupApi = (groupName: string) => Promise<SecurityGroupAttributes>;

export type GetSecurityGroupsApi = (next?: string,
                                    groupName?: string,
                                    limit?: number) => Promise<GetSecurityGroupsResponse>;

export type GetSecurityGroupServicesApi = (groupName?: string,
                                           next?: string,
                                           limit?: number) => Promise<GetSecurityGroupIdentitiesResponse>;

export interface SecurityGroupsClientApi {
  getSecurityGroup: GetSecurityGroupApi;
  getSecurityGroups: GetSecurityGroupsApi;
  createSecurityGroup: CreateSecurityGroupApi;
  deleteSecurityGroup: DeleteSecurityGroupApi;
  getSecurityGroupServices: GetSecurityGroupServicesApi;
}

enum ActionTypes {
  GET_SECURITY_GROUP_REQUEST = "security_groups_client/get_security_group_request",
  GET_SECURITY_GROUP_SUCCESS = "security_groups_client/get_security_group_success",
  GET_SECURITY_GROUP_FAILED = "security_groups_client/get_security_group_failed",

  GET_SECURITY_GROUPS_REQUEST = "security_groups_client/get_security_groups_request",
  GET_SECURITY_GROUPS_SUCCESS = "security_groups_client/get_security_groups_success",
  GET_SECURITY_GROUPS_FAILED = "security_groups_client/get_security_groups_failed",

  CREATE_SECURITY_GROUP_REQUEST = "security_groups_client/create_security_group_request",
  CREATE_SECURITY_GROUP_SUCCESS = "security_groups_client/create_security_group_success",
  CREATE_SECURITY_GROUP_FAILED = "security_groups_client/create_security_group_failed",

  DELETE_SECURITY_GROUP_REQUEST = "security_groups_client/delete_security_group_request",
  DELETE_SECURITY_GROUP_SUCCESS = "security_groups_client/delete_security_group_success",
  DELETE_SECURITY_GROUP_FAILED = "security_groups_client/delete_security_group_failed",

  GET_SECURITY_GROUP_SERVICES_REQUEST = "security_groups_client/get_security_group_services_request",
  GET_SECURITY_GROUP_SERVICES_SUCCESS = "security_groups_client/get_security_group_services_success",
  GET_SECURITY_GROUP_SERVICES_FAILED = "security_groups_client/get_security_group_services_failed",
}

export const useGetSecurityGroupApi = () =>
  useApiRequestAnalytics(SecurityGroupClient.getSecurityGroup, {
    REQUEST_EVENT: ActionTypes.GET_SECURITY_GROUP_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_SECURITY_GROUP_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_SECURITY_GROUP_FAILED,
  });

export const useGetSecurityGroupsApi = () =>
  useApiRequestAnalytics(SecurityGroupClient.getSecurityGroups, {
    REQUEST_EVENT: ActionTypes.GET_SECURITY_GROUPS_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_SECURITY_GROUPS_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_SECURITY_GROUPS_FAILED,
  });

export const useCreateSecurityGroupApi = () =>
  useApiRequestAnalytics(SecurityGroupClient.createSecurityGroup, {
    REQUEST_EVENT: ActionTypes.CREATE_SECURITY_GROUP_REQUEST,
    SUCCESS_EVENT: ActionTypes.CREATE_SECURITY_GROUP_SUCCESS,
    ERROR_EVENT: ActionTypes.CREATE_SECURITY_GROUP_FAILED,
  });

export const useDeleteSecurityGroupApi = () =>
  useApiRequestAnalytics(SecurityGroupClient.deleteSecurityGroup, {
    REQUEST_EVENT: ActionTypes.DELETE_SECURITY_GROUP_REQUEST,
    SUCCESS_EVENT: ActionTypes.DELETE_SECURITY_GROUP_SUCCESS,
    ERROR_EVENT: ActionTypes.DELETE_SECURITY_GROUP_FAILED,
  });

export const useGetSecurityGroupServicesApi = () =>
  useApiRequestAnalytics(SecurityGroupClient.getSecurityGroupServices, {
    REQUEST_EVENT: ActionTypes.GET_SECURITY_GROUP_SERVICES_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_SECURITY_GROUP_SERVICES_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_SECURITY_GROUP_SERVICES_FAILED,
  });

export const useSecurityGroupsClient = (): SecurityGroupsClientApi => ({
  getSecurityGroup: useGetSecurityGroupApi(),
  getSecurityGroups: useGetSecurityGroupsApi(),
  createSecurityGroup: useCreateSecurityGroupApi(),
  deleteSecurityGroup: useDeleteSecurityGroupApi(),
  getSecurityGroupServices: useGetSecurityGroupServicesApi(),
});

export { ActionTypes as SecurityGroupsClientActionType };

export default useSecurityGroupsClient;
