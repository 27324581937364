import React from "react";
import classnames from "classnames";
import { SearchFilter } from "@data";
import { Chip } from "@components/chip";
import { formEventHandler, noop } from "@util";
import RemoveIcon from "@material-ui/icons/Clear";
import Checkbox from "@material-ui/core/Checkbox";
import SearchIcon from "@material-ui/icons/Search";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { formControlLabel, searchFilters as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

const OrJoinOperatorLabel = withStyles(formControlLabel)(FormControlLabel);

export interface Model {
  disabled?: boolean;
  orJoinOperator?: boolean;
  showOrJoinOperator?: boolean;
  searchFilters?: SearchFilter[];
}

export interface Actions {
  setOrJoinOperator?: (orJoinOperator: boolean) => void;
  removeSearchFilter?: (searchFilter: SearchFilter) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SearchFilters = withStyles(styles)((props: Props) => {

  const {
    classes,
    disabled,
    orJoinOperator = false,
    searchFilters = [],
    setOrJoinOperator = noop,
    removeSearchFilter = noop,
    ...otherProps
  } = props;

  if (searchFilters.length === 0) {
    return null;
  }

  const { showOrJoinOperator = searchFilters.length > 1 } = otherProps;

  return (
    <div className={classnames("searchFilters", classes.container)}>
      {showOrJoinOperator && (
        <OrJoinOperatorLabel
          className={classnames("checkboxContainer", classes.checkboxContainer)}
          label="Or Join"
          control={(
            <Checkbox
              className={classnames("checkbox", classes.checkbox)}
              disabled={disabled}
              checked={orJoinOperator}
              onChange={formEventHandler(() => setOrJoinOperator(!orJoinOperator))}
            />
          )}
        />
      )}
      {searchFilters.map((searchFilter: SearchFilter) => (
        <div
          key={searchFilter.getSearchQuery()}
          className={classnames("filter", classes.filter)}
        >
          <Chip
            className={classnames("searchFilter", classes.searchFilter)}
            color={disabled ? "default" : "secondary"}
            disabled={disabled}
            clickable={false}
            label={`${searchFilter.getKey()} : ${searchFilter.getValue()}`}
            icon={<SearchIcon className={classnames("icon", classes.icon)}/>}
            deleteIcon={<RemoveIcon className={classnames("deleteIcon", classes.deleteIcon)} />}
            onDelete={() => removeSearchFilter(searchFilter)}
          />
        </div>
      ))}
    </div>
  );

});

export default SearchFilters;
