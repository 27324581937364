import React from "react";
import { ListPrincipalIdentifiersResponse } from "@network";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useDataAccessRequestClient } from "@hooks";

type SuccessResponse = ListPrincipalIdentifiersResponse;

export interface UseListCloudRolePrincipalsProps extends Partial<UseApiRequestProps<SuccessResponse>> {
}

export interface UseListCloudRolePrincipalsModel extends UseApiRequestModel<SuccessResponse> {
  principalIdentifiers: string[];
}

export interface UseListCloudRolePrincipalsActions {
  listCloudRolePrincipals: () => void;
}

type Props = UseListCloudRolePrincipalsProps;
type Model = UseListCloudRolePrincipalsModel;
type Actions = UseListCloudRolePrincipalsActions;
type Result = [Model, Actions];

export const useListCloudRolePrincipals = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to list cloud role principal identifiers",
    ...otherProps
  } = props;

  const DataAccessRequestClient = useDataAccessRequestClient();

  const [{ successResponse, ...baseModel }, { refresh: listCloudRolePrincipals }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest: () => DataAccessRequestClient.listCloudRolePrincipals(),
    });

  const principalIdentifiers = React.useMemo(() => {
    const { principalIdentifiers: ids = [] } = successResponse || {};
    return ids;
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    principalIdentifiers,
  }), [
    baseModel,
    principalIdentifiers,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    listCloudRolePrincipals,
  }), [
    listCloudRolePrincipals,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useListCloudRolePrincipals;
