import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {},
    errorDialog: {},
    errorView: {
      margin: 0,
      "& label.message": {
        margin: "12px 0 6px",
      },
    },
    downloadButton: {},
    retryButton: {
      color: Color.DARK_RED,
      height: "auto",
    },
    loading: {},
    error: {},
    success: {},
  });

export default styles;
