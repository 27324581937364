import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { getSearchQuery, isLoadingIndicator } from "../selectors";
import { setSearchQuery, reloadHistory } from "../actions";
import { Model, EnrollmentHistoryListView, Actions } from "../components/EnrollmentHistoryListView";
import EnrollmentHistoryList from "./EnrollmentHistoryList";
import { Device } from "@data";

interface ContainerActions extends Actions {
  showEnrollmentDetails?: (device: Device) => void;
}

type Props = Model & ContainerActions;

const DeviceContainer = (props: Props) => {

  const { showEnrollmentDetails, ...otherProps } = props;

  return (
    <EnrollmentHistoryListView {...otherProps}>
      <EnrollmentHistoryList showEnrollmentDetails={showEnrollmentDetails}/>
    </EnrollmentHistoryListView>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  searchQuery: getSearchQuery(state),
  loading: isLoadingIndicator(state),
});

const mapDispatchToProps = (dispatch: any): ContainerActions => ({
  updateSearchQuery: (searchQuery: string) => dispatch(setSearchQuery(searchQuery)),
  refresh: () => dispatch(reloadHistory()),
});

export default connect<Model, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceContainer);
