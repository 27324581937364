import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { AdminNote } from "@data";
import { notesListView as styles } from "./styles";

export interface Model {
  items?: AdminNote[];
  noResultLabel?: string;
  errorMessage?: string;
  loadingIndicator?: boolean;
  children?: React.ReactNode;
}

export interface Actions {

}

type Props = WithStyles<typeof styles> & Model & Actions;

export const NoteListView = withStyles(styles)((props: Props) => {

  const {
    classes,
    items = [],
    errorMessage = "",
    noResultLabel = "There are no notes yet for this user",
    loadingIndicator,
    children,
  } = props;

  if ( loadingIndicator ) {
    return (
      <div className={classnames("loadingView", classes.container)}>
        <p className={classnames("loadingLabel", classes.label)}>Loading...</p>
      </div>
    );
  }

  if ( items.length === 0 && !(errorMessage.trim().length > 0) && !loadingIndicator ) {
    return (
      <div className={classnames("noResultView", classes.container)}>
        <p className={classnames("noResultsLabel", classes.label)}>{noResultLabel}</p>
      </div>
    );
  }

  return (
    <div className={classnames("notesListView", classes.container)}>
      {items.map((item: AdminNote) => {
        return (
          <div
            key={item.getCreatedAt()}
            className={classnames("notesListView", classes.commentContainer)}
          >
            <label className={classnames("heading", classes.heading)}>
              <span className={classes.userId}>{item.getCreatedBy()}</span>
              added a note on <span className={classes.timeStamp}>{item.getCreatedAt()}</span>
            </label>
            <p className={classnames("note", classes.note)}>
              {item.getNote()}
            </p>
          </div>
        );
      })}
      {children}
    </div>
  );
});

export default NoteListView;
