import React from "react";
import classnames from "classnames";
import { TabLink as Tab } from "@components/tab";
import { Tabs as TabsComponent } from "@components/tabs";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  COMMON_SERVICES_QUALITY_REPORTS_PATH,
  DATA_AND_ANALYTICS_QUALITY_REPORTS_PATH,
  DEVICE_MANAGEMENT_QUALITY_REPORTS_PATH,
  SELF_SERVICE_TOOLS_QUALITY_REPORTS_PATH,
  SECURITY_SERVICES_QUALITY_REPORTS_PATH,
} from "@modules/qualityReports/constants";
import styles, { tabs } from "./styles";

const Tabs = withStyles(tabs)(TabsComponent);

export enum QualityReportsTab {
  SECURITY_SERVICES = "Security Services",
  DEVICE_MANAGEMENT = "Device Management",
  DATA_AND_ANALYTICS = "Data & Analytics",
  COMMON_SERVICES = "Common Services",
  SELF_SERVICE_TOOLS = "Self-Service Tools",
}

export interface QualityReportsTabsModel {
  className?: string;
  showSecurityServicesTab?: boolean;
  showDeviceManagementTab?: boolean;
  showDataAndAnalyticsTab?: boolean;
  showCommonServicesTab?: boolean;
  showSelfServiceToolsTab?: boolean;
  selectedTab?: QualityReportsTab;
}

export interface QualityReportsTabsActions {
}

type Model = QualityReportsTabsModel;
type Actions = QualityReportsTabsActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const QualityReportsTabs = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    showSecurityServicesTab = true,
    showDeviceManagementTab,
    showDataAndAnalyticsTab,
    showCommonServicesTab,
    showSelfServiceToolsTab,
    selectedTab = QualityReportsTab.SECURITY_SERVICES,
  } = props;

  return (
    <Tabs
      className={classnames("qualityReportsTabs", className, classes.container)}
      value={selectedTab}
    >
      {showSecurityServicesTab && (
        <Tab
          className={classnames("securityServicesTab", classes.tab)}
          to={SECURITY_SERVICES_QUALITY_REPORTS_PATH}
          label={QualityReportsTab.SECURITY_SERVICES}
          value={QualityReportsTab.SECURITY_SERVICES}
        />
      )}
      {showDeviceManagementTab && (
        <Tab
          className={classnames("deviceManagementTab", classes.tab)}
          to={DEVICE_MANAGEMENT_QUALITY_REPORTS_PATH}
          label={QualityReportsTab.DEVICE_MANAGEMENT}
          value={QualityReportsTab.DEVICE_MANAGEMENT}
        />
      )}
      {showDataAndAnalyticsTab && (
        <Tab
          className={classnames("dataAndAnalyticsTab", classes.tab)}
          to={DATA_AND_ANALYTICS_QUALITY_REPORTS_PATH}
          label={QualityReportsTab.DATA_AND_ANALYTICS}
          value={QualityReportsTab.DATA_AND_ANALYTICS}
        />
      )}
      {showCommonServicesTab && (
        <Tab
          className={classnames("commonServicesTab", classes.tab)}
          to={COMMON_SERVICES_QUALITY_REPORTS_PATH}
          label={QualityReportsTab.COMMON_SERVICES}
          value={QualityReportsTab.COMMON_SERVICES}
        />
      )}
      {showSelfServiceToolsTab && (
        <Tab
          className={classnames("selfServiceToolsTab", classes.tab)}
          to={SELF_SERVICE_TOOLS_QUALITY_REPORTS_PATH}
          label={QualityReportsTab.SELF_SERVICE_TOOLS}
          value={QualityReportsTab.SELF_SERVICE_TOOLS}
        />
      )}
    </Tabs>
  );
});

export default QualityReportsTabs;
