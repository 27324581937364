import React from "react";
import classnames from "classnames";
import styles, { PrimaryIcon } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DetailsView, { DetailsViewActions, DetailsViewModel } from "@components/details-view";

export interface Model extends DetailsViewModel {}
export interface Actions extends DetailsViewActions {}

type Props = WithStyles<typeof styles> & Model & Actions & {
  namespaceName?: string;
  children?: React.ReactNode;
};

export const NamespaceDetails = withStyles(styles)((props: Props) => {

  const {
    classes,
    icon = PrimaryIcon,
    namespaceName = "Namespace Details",
    actionsLabel = "Actions",
    showNotFound,
    showAccessDenied,
    children,
    ...otherProps
  } = props;

  return (
    <DetailsView
      {...otherProps}
      className={classnames("namespaceDetails", classes.container)}
      icon={icon}
      title={namespaceName}
      actionsLabel={actionsLabel}
      tabsMarginTop={false}
      showNotFound={showNotFound}
      showAccessDenied={showAccessDenied}
    >
      {children}
    </DetailsView>
  );
});

export default NamespaceDetails;
