import React from "react";
import classnames from "classnames";
import Button from "@components/button";
import camelCase from "lodash/camelCase";
import { clickHandler, noop } from "@util";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import EditActionsListItemPopover from "./EditActionsListItemPopover";
import ActionsListItemData from "../ActionsListItemData";
import ActionsListColumn from "../ActionsListColumn";
import styles from "./styles";

export interface ActionsListItemModel {
  className?: string;
  column?: ActionsListColumn;
  item?: ActionsListItemData;
}

export interface ActionsListItemActions {
  editItem?: (updatedItem: ActionsListItemData) => void;
  isDuplicate?: (name: string) => boolean;
}

type Model = ActionsListItemModel;
type Actions = ActionsListItemActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ActionsListItem = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    column = ActionsListColumn.NONE,
    item = ActionsListItemData.EMPTY,
    editItem = noop,
    isDuplicate,
    children,
  } = props;

  const editIconButtonRef = React.useRef(null);

  const [showEditPopover, setShowEditPopover] = React.useState(false);

  const content = React.useMemo(() => {
    if (column === ActionsListColumn.NAME) {
      return (
        <Typography className={classnames("name", "label", classes.label)}>
          {item.getName()}
        </Typography>
      );
    } else if (column === ActionsListColumn.ACTIONS) {
      return (
        <Button
          ref={editIconButtonRef}
          className={classnames(
            "editIconButton",
            classes.iconButton,
            classes.editIconButton,
          )}
          onClick={clickHandler(() => {
            setShowEditPopover(true);
          })}
          name="editIconButton"
        >
          <EditIcon className={classnames("editIcon", classes.icon, classes.editIcon)} />
        </Button>
      );
    }
    return null;
  }, [classes, column, item, editIconButtonRef, setShowEditPopover]);

  const closePopover = React.useCallback(() => {
    setShowEditPopover(false);
  }, [setShowEditPopover]);

  if (ActionsListItemData.EMPTY.equals(item) || column === ActionsListColumn.NONE) {
    return null;
  }

  return (
    <div className={classnames("actionsListItem", className, camelCase(`${column}Column`), classes.container)}>
      {content}
      <EditActionsListItemPopover
        className={classnames("popover", classes.popover)}
        anchorEl={editIconButtonRef.current}
        open={showEditPopover}
        item={item}
        editItem={editItem}
        closePopover={closePopover}
        isDuplicate={isDuplicate}
      />
      {children}
    </div>
  );
});

export default ActionsListItem;
