import React from "react";
import {
  ModuleListViewActions,
  ModuleListViewModel,
} from "@components";
import ModuleListView from "@components/module-list-view";
import DSTypesLayoutsListColumn from "@components/ds-types-layouts-list-columns";
import { PrimaryIcon, styles } from "./styles";
import { DSTypeInfo } from "@data";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface DSTypeListViewModel extends ModuleListViewModel<DSTypeInfo, DSTypesLayoutsListColumn> {
  expandable?: boolean;
}

export interface DSTypeListViewActions extends ModuleListViewActions<DSTypeInfo, DSTypesLayoutsListColumn> {
}

type Props = WithStyles<typeof styles> & DSTypeListViewModel & DSTypeListViewActions;

const DSTypeListView =  withStyles(styles)((props: Props) => {

  const {
    classes,
    ...otherProps
  } = props;

  const [nameFilter, setNameFilter] = React.useState("");

  return (
      <ModuleListView
        {...otherProps}
        className="digitalShadowTypes"
        summaryViewIcon={PrimaryIcon}
        nameFilter={nameFilter}
        listClassName={classnames("digitalShadowTypesList", classes.table)}
        setNameFilter={setNameFilter}
      />
  );
});

export default DSTypeListView;
