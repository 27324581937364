import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import { SearchFilter } from "@data";
import FiltersMenu from "../filters-menu";
import FilterSwitch from "../filter-switch";
import DataAccessRequestsList, {
  DataAccessRequestsListActions,
  DataAccessRequestsListModel,
} from "../data-access-requests-list";
import {
  DEFAULT_DATA_ACCESS_REQUEST_ACTION_MENU_ITEMS as DEFAULT_ACTIONS,
} from "../data-access-request-actions-menu";
import AppliedFilters from "../applied-filters";
import DataAccessRequestsFilter from "./DataAccessRequestsFilter";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface DataAccessRequestsModel extends DataAccessRequestsListModel {
  filterByCurrentUser?: boolean;
  filterByCurrentAccount?: boolean;
  filterByPendingApproval?: boolean;
}

export interface DataAccessRequestsActions extends DataAccessRequestsListActions {
  setFilterByCurrentUser?: (checked: boolean) => void;
  setFilterByCurrentAccount?: (checked: boolean) => void;
  setFilterByPendingApproval?: (checked: boolean) => void;
  mapSearchFilterToLabel?: (searchFilter: SearchFilter) => React.ReactNode;
}

type Model = DataAccessRequestsModel;
type Actions = DataAccessRequestsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataAccessRequests = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    listClassName,
    actions = DEFAULT_ACTIONS,
    showLoadingIndicator,
    showSummary = true,
    showSearch = true,
    filterByCurrentUser = false,
    filterByCurrentAccount = false,
    filterByPendingApproval = false,
    setFilterByCurrentUser = noop,
    setFilterByCurrentAccount = noop,
    setFilterByPendingApproval = noop,
    mapSearchFilterToLabel,
    ...otherProps
  } = props;

  const [showFiltersMenu, setShowFiltersMenu] = React.useState(false);

  const searchFilters = React.useMemo<SearchFilter[]>(() => ([] as SearchFilter[])
    .concat(filterByCurrentUser ? [new SearchFilter({
      key: DataAccessRequestsFilter.CURRENT_USER,
    })] : [])
    .concat(filterByCurrentAccount ? [new SearchFilter({
      key: DataAccessRequestsFilter.CURRENT_ACCOUNT,
    })] : [])
    .concat(filterByPendingApproval ? [new SearchFilter({
      key: DataAccessRequestsFilter.PENDING,
    })] : []), [
    filterByCurrentUser,
    filterByCurrentAccount,
    filterByPendingApproval,
  ]);

  const noResultsLabel = React.useMemo(() =>
      filterByPendingApproval && searchFilters.length === 1
        ? "No Pending Access Requests Found" : "No Access Requests Found",
    [filterByPendingApproval, searchFilters]);

  const badgeCount = React.useMemo(() => searchFilters.length, [searchFilters]);

  const openMenu = React.useCallback(() => setShowFiltersMenu(true), [setShowFiltersMenu]);

  const closeMenu = React.useCallback(() => setShowFiltersMenu(false), [setShowFiltersMenu]);

  const setSearchFilters = React.useCallback((updatedSearchFilters: SearchFilter[]) =>
    searchFilters
      .filter(it => !updatedSearchFilters.some(it2 => it.equals(it2)))
      .map(it => it.getKey())
      .forEach(type => {
        switch (type) {
          case DataAccessRequestsFilter.CURRENT_USER:
            setFilterByCurrentUser(false);
            break;
          case DataAccessRequestsFilter.CURRENT_ACCOUNT:
            setFilterByCurrentAccount(false);
            break;
          case DataAccessRequestsFilter.PENDING:
            setFilterByPendingApproval(false);
            break;
          default:
            break;
        }
      }), [
    searchFilters,
    setFilterByCurrentUser,
    setFilterByCurrentAccount,
    setFilterByPendingApproval,
  ]);

  const clearFilters = React.useCallback(() => {
    setFilterByCurrentUser(false);
    setFilterByCurrentAccount(false);
    setFilterByPendingApproval(false);
  }, [
    setFilterByCurrentUser,
    setFilterByCurrentAccount,
    setFilterByPendingApproval,
  ]);

  return (
    <DataAccessRequestsList
      {...otherProps}
      className={classnames("dataAccessRequests", className, classes.container)}
      listClassName={classnames("list", listClassName, classes.list)}
      actions={actions}
      showSummary={showSummary}
      showSearch={showSearch}
      showLoadingIndicator={showLoadingIndicator}
      noResultsLabel={noResultsLabel}
      summaryViewFiltersMenu={(
        <FiltersMenu
          className={classnames("filtersMenu", classes.filtersMenu)}
          open={showFiltersMenu}
          badgeCount={badgeCount}
          disabled={showLoadingIndicator}
          openMenu={openMenu}
          closeMenu={closeMenu}
        >
          <div className={classnames("filters", classes.filters)}>
            <FilterSwitch
              className={classnames("currentUserFilterSwitch", classes.filterSwitch, classes.currentUser)}
              rightLabel="Show my access requests only"
              checked={filterByCurrentUser}
              onChange={checked => setFilterByCurrentUser(checked)}
            />
            <FilterSwitch
              className={classnames("currentAccountFilterSwitch", classes.filterSwitch, classes.currentAccount)}
              rightLabel="Show access requests for current account only"
              checked={filterByCurrentAccount}
              onChange={checked => setFilterByCurrentAccount(checked)}
            />
            <FilterSwitch
              className={classnames("pendingFilterSwitch", classes.filterSwitch, classes.pending)}
              rightLabel="Show pending access requests only"
              checked={filterByPendingApproval}
              onChange={checked => setFilterByPendingApproval(checked)}
            />
          </div>
        </FiltersMenu>
      )}
      header={(
        <AppliedFilters
          className={classnames("appliedFilters", classes.appliedFilters)}
          disabled={showLoadingIndicator}
          searchFilters={searchFilters}
          mapSearchFilterToLabel={mapSearchFilterToLabel}
          setSearchFilters={setSearchFilters}
          onClickSearchFilter={openMenu}
          clearFilters={clearFilters}
        />
      )}
    />
  );
});

export default DataAccessRequests;
