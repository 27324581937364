import React from "react";
import {
  UseApiRequestModel,
  UseApiRequestProps,
  useApiRequest,
  useEmailTemplateClient,
} from "@hooks";

type SuccessResponse = void;

export interface UseDeleteEmailTemplateProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  accountId?: string;
  templateId?: string;
}

export interface UseDeleteEmailTemplateModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseDeleteEmailTemplateActions {
  deleteEmailTemplate: () => void;
}

type Props = UseDeleteEmailTemplateProps;
type Model = UseDeleteEmailTemplateModel;
type Actions = UseDeleteEmailTemplateActions;
type Result = [Model, Actions];

export const useDeleteEmailTemplate = (props: Props = {}): Result => {

  const {
    accountId = "",
    templateId = "",
    defaultErrorMessage = "Failed to delete email template",
    ...otherProps
  } = props;

  const EmailTemplateClient = useEmailTemplateClient();

  const makeApiRequest = React.useCallback(() =>
    EmailTemplateClient.deleteEmailTemplate(accountId, templateId),
    [EmailTemplateClient, accountId, templateId]);

  const [{ showSuccessView, ...baseModel }, { refresh: deleteEmailTemplate }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
      deferRequest: true,
    });

  const successMessage = React.useMemo(() => showSuccessView ?
    "Delete Successful" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    deleteEmailTemplate,
  }), [
    deleteEmailTemplate,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDeleteEmailTemplate;
