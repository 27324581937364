import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import DataLakePreSignedUrl from "@data/DataLakePresignedUrl";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { DownloadButton } from "@components";
import { DownloadType, useDataLakeDownloadService } from "@hooks";
import styles from "./styles";

export interface DownloadDataLakeFileButtonModel {
  className?: string;
  buttonClassName?: string;
  disabled?: boolean;
  label?: string;
  dataSetAlias?: string;
  file?: string;
  accountId?: string;
}

export interface DownloadDataLakeFileButtonActions {
  setFilePath?: (path: string) => void;
  setDownloadUrl?: (url: string) => void;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
}

type Model = DownloadDataLakeFileButtonModel;
type Actions = DownloadDataLakeFileButtonActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DownloadDataLakeFileButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    buttonClassName,
    disabled,
    label = "Download File",
    dataSetAlias = "",
    file = "",
    accountId = "",
    setFilePath = noop,
    setDownloadUrl = noop,
    trackRequestEvent = noop,
    trackSuccessEvent = noop,
    trackErrorEvent = noop,
    children,
  } = props;

  const [{ urls, showLoadingIndicator }, { download }] = useDataLakeDownloadService({
    dataSet: dataSetAlias,
    files: [file],
    accountId,
    type: DownloadType.ACQUIRED_FILES,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
  });

  const url = React.useMemo(() => urls.slice().pop() || DataLakePreSignedUrl.EMPTY, [urls]);

  const filePath = React.useMemo(() => url.getFilePath(), [url]);

  const downloadURL = React.useMemo(() => url.getDownloadUrl(), [url]);

  React.useEffect(() => {
    setFilePath(filePath);
  }, [setFilePath, filePath]);

  React.useEffect(() => {
    setDownloadUrl(downloadURL);
  }, [setDownloadUrl, downloadURL]);

  return (
    <div className={classnames("downloadDataLakeFileButton", className, classes.container)}>
      <DownloadButton
        className={classnames("downloadButton", classes.downloadButton)}
        buttonClassName={classnames("button", buttonClassName, classes.button)}
        downloadFileClassName="dataLakeFile"
        label={label}
        href={downloadURL}
        loading={showLoadingIndicator}
        disabled={disabled || showLoadingIndicator}
        download={download}
      />
      {children}
    </div>
  );
});

export default DownloadDataLakeFileButton;
