import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import { FederationProvider, FederationProviderAttributes } from "@data";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "@modules/base/createReducers";

export interface FederationProviderDetailsSchema extends BasePortalModuleSchema {
  federationProvider: FederationProviderAttributes;
}

export const DEFAULT_STATE: FederationProviderDetailsSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  federationProvider: Object.freeze({ ...FederationProvider.EMPTY.toJS() }),
});

type FederationProviderDetailsActionTypes = PortalModuleActionTypes<FederationProviderDetailsSchema> & {
  FETCH_FEDERATION_PROVIDER_DETAILS_REQUEST: string;
  FETCH_FEDERATION_PROVIDER_DETAILS_SUCCESS: string;
  FETCH_FEDERATION_PROVIDER_DETAILS_FAILURE: string;
};

export const ACTION_TYPES: FederationProviderDetailsActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<FederationProviderDetailsSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    FETCH_FEDERATION_PROVIDER_DETAILS_REQUEST: `${MODULE_ID}/fetch_federation_provider_details_request`,
    FETCH_FEDERATION_PROVIDER_DETAILS_SUCCESS: `${MODULE_ID}/fetch_federation_provider_details_success`,
    FETCH_FEDERATION_PROVIDER_DETAILS_FAILURE: `${MODULE_ID}/fetch_federation_provider_details_failure`,
  },
});

export const FederationProviderDetailsReducer = combineReducers<FederationProviderDetailsSchema>(
  createPortalModuleReducers<FederationProviderDetailsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default FederationProviderDetailsReducer;
