import React from "react";
import { noop } from "@util";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MODULE_PATH as DETAILS_PAGE_PATH } from "@modules/federationProviderDetails/constants";
import { MODULE_PATH as LIST_VIEW_PATH } from "@modules/federationProviders/constants";
import { close, deleteFederationProvider } from "../actions";
import {
  getErrorMessage,
  getSuccessMessage,
  getProviderId,
  isAccessDeniedVisible,
  isDialogOpen,
  isEmptyViewVisible,
  isLoadingIndicatorVisible,
  isProviderIdValid,
} from "../selectors";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import DeleteFederationProvider from "../components/DeleteFederationProvider";

interface Model extends PortalModuleDialogModel<string> {
  providerId?: string;
}

type Actions = PortalModuleDialogActions<string>;

const DeleteFederationProviderDialog = (props: Model & Actions) => {

  const { providerId, ...otherProps } = props;

  const { cancel: closeDialog = noop } = otherProps;

  const match = useRouteMatch(DETAILS_PAGE_PATH);

  const history = useHistory();

  const showListView = React.useCallback(() => {
    history.push(LIST_VIEW_PATH);
  }, [history]);

  const onSuccessMessageShown = React.useCallback(() => {
    closeDialog();
    if (match) {
      showListView();
    }
  }, [match, closeDialog, showListView]);

  return (
    <PortalModuleDialog {...otherProps} onSuccessMessageShown={onSuccessMessageShown}>
      <DeleteFederationProvider providerId={providerId} />
    </PortalModuleDialog>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  className: "deleteFederationProviderDialog",
  title: "Delete Federation Provider",
  open: isDialogOpen(state),
  item: getProviderId(state),
  loading: isLoadingIndicatorVisible(state),
  showEmptyView: isEmptyViewVisible(state),
  accessDenied: isAccessDeniedVisible(state),
  errorMessage: getErrorMessage(state),
  successMessage: getSuccessMessage(state),
  continueButtonLabel: "Delete Federation Provider",
  continueButtonDisabled: !isProviderIdValid(state),
  providerId: getProviderId(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  cancel: () => dispatch(close()),
  confirm: () => dispatch(deleteFederationProvider()),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteFederationProviderDialog);
