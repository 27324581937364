import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    button: {
    },
    label: {
      color: Color.BLACK,
    },
    uppercase: {
      textTransform: "uppercase",
    },
  });

export default styles;
