import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel
} from "@components";
import { removeChildDialog as styles } from "./styles";
import { noop } from "@util";
import { DSTypeInfo } from "@data";

type Model = ConfirmationDialogModel<DSTypeInfo>;
type Actions = ConfirmationDialogActions<DSTypeInfo>;

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const RemoveChildDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className = "removeChildDialog",
    item = DSTypeInfo.EMPTY,
    title = "Delete Child",
    continueButtonLabel = "Delete",
    open = false,
    cancel = noop,
    confirm = noop,
  } = props;

  return (
    <ConfirmationDialog
      className={className}
      open={open}
      title={title}
      item={item}
      continueButtonLabel={continueButtonLabel}
      cancel={cancel}
      confirm={confirm}
    >
      <div className={classnames("removeChild", classes.content)}>
        <label className={classnames("title", classes.title)}>
          Are you sure you want to remove this child?
        </label>
        <label className={classnames("subtitle", classes.subtitle)}>
          <span className={classes.warning}>WARNING:</span>
          This will remove all child items of <span className={classes.warning}>{item.getName()}!</span>
        </label>
      </div>
    </ConfirmationDialog>
  );
});

export default RemoveChildDialog;
