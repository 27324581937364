import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import classnames from "classnames";
import { styles } from "./styles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { DSTypeInfo, DSTypeInfoMetadata } from "@data";
import {
  Chip,
  DSTypeOperations,
  IconButton,
  TextField
} from "@components";
import { clickHandler, formEventHandler, hasSpecialChars, isEmptyString, noop } from "@util";
import { useCreateDSType, useDeleteDSType, useGetDSType, useUpdateDSType } from "@hooks";
import { TextFieldStyled } from "@components/text-field-styled";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/AddOutlined";
import MetadataIcon from "@material-ui/icons/Label";
import RemoveIcon from "@material-ui/icons/Clear";
import ListItem from "@material-ui/core/ListItem";

export interface DSTypeManagementDialogModel extends PortalModuleDialogModel<DSTypeInfo> {
  operation: DSTypeOperations;
  name?: string;
}

export interface DSTypeManagementDialogActions extends PortalModuleDialogActions<DSTypeInfo> {
  onSuccess?: () => void;
  cancel?: () => void;
}

type Props = WithStyles< typeof styles> &
  DSTypeManagementDialogModel & DSTypeManagementDialogActions;

export const DSTypeManagementDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    operation = DSTypeOperations.NONE,
    name = "",
    onSuccess = noop,
    cancel = noop,
    ...otherProps
  } = props;

  if (operation === DSTypeOperations.NONE) {
    return null;
  }

  const isCreate = React.useMemo(() => operation === DSTypeOperations.CREATE, [operation]);
  const isUpdate = React.useMemo(() => operation === DSTypeOperations.UPDATE, [operation]);
  const isDelete = React.useMemo(() => operation === DSTypeOperations.DELETE, [operation]);

  const title = React.useMemo(() =>
      `${operation} Shadow Type`,
    [operation]);

  const [ model, actions ] = useGetDSType({
    name,
    deferRequest: true
  });

  const {
    showLoadingIndicator: fetchLoadingIndicator,
    dsType,
    etag: typeEtag,
    errorMessage: fetchErrorMessage,
    showErrorView: fetchError,
    showSuccessView: fetchSuccess,
  }  = model;
  const { refresh: getType }  = actions;

  const etag = React.useMemo(() => typeEtag.etag || "" , [typeEtag]);

  const [ data, setData ] = React.useState<DSTypeInfo>(DSTypeInfo.EMPTY);
  const [ errorMessage, setErrorMessage ] = React.useState("");
  const [ successMessage, setSuccessMessage ] = React.useState("");

  const [ key, setKey ] = React.useState("");
  const [ value, setValue ] = React.useState("");

  const typeMetadata = React.useMemo(() => data.getMetadata(), [data]);

  const updateData = React.useCallback((updatedData: DSTypeInfo) => {
    setErrorMessage("");
    setSuccessMessage("");
    setData(updatedData);
  }, [setErrorMessage, setData, setSuccessMessage]);

  const setName = React.useCallback((updatedName: string) =>
    updateData(new DSTypeInfo({
      ...data.toJS(),
      name: updatedName
    })), [updateData, data]);

  const setDescription = React.useCallback((description: string) =>
    updateData(new DSTypeInfo({
      ...data.toJS(),
      description
    })), [updateData, data]);

  const setMetadata = React.useCallback((metadata: DSTypeInfoMetadata = {}) =>
    updateData(new DSTypeInfo({
      ...data.toJS(),
      metadata
    })), [updateData, data]);

  const addMetadata = React.useCallback(() => {
    const newValue = {};
    newValue[key] = value;
    const updatedMetadata = !Object.keys(typeMetadata).some(k => k === key) ?
      Object.assign(typeMetadata, newValue) : typeMetadata;
    setMetadata(updatedMetadata);
    setKey("");
    setValue("");
  }, [
    typeMetadata,
    setMetadata,
    key,
    value,
    setKey,
    setValue,
  ]);

  const removeMetadata = React.useCallback((deleteKey: string) => {
    let updatedMetadata = typeMetadata;
    delete updatedMetadata[deleteKey];
    return setMetadata(updatedMetadata);
  }, [typeMetadata, setMetadata]);

  const keyValueNotNull = React.useMemo(() =>
    key.trim().length > 0 && value.trim().length > 0 && !hasSpecialChars(key),
    [key, value]);

  const enterPressed = React.useCallback(() => {
    if (keyValueNotNull) {
      return addMetadata();
    } else {
      return noop;
    }
  }, [key, value, addMetadata]);

  const [deleteModel, deleteActions] = useDeleteDSType({
    name,
    etag
  });

  const [createModel, createActions] = useCreateDSType({
    requestBody: data,
  });

  const {
    showLoadingIndicator: createLoadingIndicator,
    errorMessage: createErrorMessage,
    successMessage: createSuccessMessage,
    showErrorView: createError,
    showSuccessView: createSuccess,
  } = createModel;
  const { createType } = createActions;

  const [updateModel, updateActions] = useUpdateDSType({
    requestBody: data,
    requestEtag: etag
  });

  const {
    showLoadingIndicator: updateLoadingIndicator,
    errorMessage: updateErrorMessage,
    successMessage: updateSuccessMessage,
    showErrorView: updateError,
    showSuccessView: updateSuccess,
  } = updateModel;
  const { updateType } = updateActions;

  const {
    showLoadingIndicator: deleteLoadingIndicator,
    errorMessage: deleteErrorMessage,
    successMessage: deleteSuccessMessage,
    showErrorView: deleteError,
    showSuccessView: deleteSuccess,
  } = deleteModel;
  const { deleteType } = deleteActions;

  const performOperation = React.useCallback(() => {
    if (isDelete) {
      return deleteType();
    } else if (isCreate) {
      return createType();
    } else if (isUpdate) {
      return updateType();
    } else {
      return noop;
    }
  }, [
    isCreate,
    isUpdate,
    isDelete,
    deleteType,
    createType,
    updateType
  ]);

  const showDeleteContent = React.useMemo(() => isDelete && !fetchLoadingIndicator,
    [isDelete, fetchLoadingIndicator]);

  const showCreateUpdateContent = React.useMemo(() => (isCreate || isUpdate) && !fetchLoadingIndicator,
      [isCreate, isUpdate, fetchLoadingIndicator]);

  const continueButtonDisabled = React.useMemo(() =>
    isEmptyString(data.getName()) || hasSpecialChars(data.getName()) || fetchLoadingIndicator,
    [data, fetchLoadingIndicator]);

  const loadingIndicator = React.useMemo(() =>
    createLoadingIndicator || updateLoadingIndicator || deleteLoadingIndicator,
    [createLoadingIndicator, updateLoadingIndicator, deleteLoadingIndicator]);

  React.useEffect(() => {
    if (fetchError) {
      setErrorMessage(fetchErrorMessage);
    } else if (createError) {
      setErrorMessage(createErrorMessage);
    } else if (updateError) {
      setErrorMessage(updateErrorMessage);
    } else if (deleteError) {
      setErrorMessage(deleteErrorMessage);
    } else {
      setErrorMessage("");
    }
  }, [
  fetchError,
  createError,
  updateError,
  deleteError
  ]);

  React.useEffect(() => {
    if (createSuccess) {
      setSuccessMessage(createSuccessMessage);
    } else if (updateSuccess) {
      setSuccessMessage(updateSuccessMessage);
    } else if (deleteSuccess) {
      setSuccessMessage(deleteSuccessMessage);
    } else {
      setSuccessMessage("");
    }
  }, [
    createSuccess,
    updateSuccess,
    deleteSuccess
  ]);

  React.useEffect(() => {
    if (!isCreate) {
      getType();
    }
    return noop;
  }, []);

  React.useEffect(() => {
    setData(dsType);
  }, [fetchSuccess]);

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  return (
    <PortalModuleDialog
      {...otherProps}
      title={title}
      className={className}
      loading={loadingIndicator}
      continueButtonDisabled={continueButtonDisabled}
      continueButtonLabel={operation}
      errorMessage={errorMessage}
      successMessage={successMessage}
      confirm={performOperation}
      onSuccessMessageShown={onSuccess}
      cancel={cancel}
    >
      <div className={classnames("typeOperation", classes.container)}>
        <React.Fragment>
          {fetchLoadingIndicator && (
            <ListItem className={classnames("loadingLabel", classes.loadingLabel)}>
              Loading...
            </ListItem>
          )}
          {showDeleteContent && (
            <div className={classnames("deleteTypeDialog", classes.informationContainer)}>
              <label className={classnames("title", classes.title)}>
                Are you sure you want to delete this shadow type?
              </label>
              <label className={classnames("subtitle", classes.subtitle)}>
                <span className={classes.warning}>WARNING:</span>This action cannot be undone!
              </label>
              <label className={classnames("typeName", classes.typeName)}>
                {data.getName()}
              </label>
            </div>
          )}
          {showCreateUpdateContent && (
            <div className={classnames("typeInfo", classes.typeInfoContainer)}>
              <Typography className={classnames("infoTitle", classes.infoTitle)} variant="h4">
                Type Information
              </Typography>
              <TextField
                className={classnames("typeName", classes.description)}
                name="name"
                label="Name"
                variant="outlined"
                value={data.getName()}
                fullWidth={true}
                required={true}
                autoFocus={isCreate}
                disabled={isUpdate}
                disableSpecialChars={true}
                placeholder="Set Shadow Type Name"
                onChange={formEventHandler((updatedName: string) => setName(updatedName))}
                InputLabelProps={inputLabelProps}
              />
              <TextField
                  className={classnames("description", classes.description)}
                  variant="outlined"
                  autoComplete="off"
                  margin="none"
                  fullWidth={true}
                  autoFocus={isUpdate}
                  multiline={true}
                  minRows={3}
                  label="Description"
                  name="description"
                  value={data.getDescription()}
                  placeholder="Enter Type Description (optional)"
                  onChange={formEventHandler((updatedDescription: string) => setDescription(updatedDescription))}
                  InputLabelProps={inputLabelProps}
              />
              <Typography className={classnames("metadata", classes.metadata)} variant="h5">
                Metadata
              </Typography>
              <div className={classnames(`metadataTextFields`, classes.metadataTextFields)}>
                <TextFieldStyled
                  className={classnames(`metadataKey`, classes.metadataKey)}
                  name="key"
                  label="Key"
                  value={key}
                  setValue={setKey}
                  onEnterPressed={enterPressed}
                  disableSpecialChars={true}
                  shrinkInputLabel={true}
                />
                <TextFieldStyled
                  className={classnames(`metadataValue`, classes.metadataValue)}
                  name="value"
                  label="Value"
                  value={value}
                  setValue={setValue}
                  onEnterPressed={enterPressed}
                  shrinkInputLabel={true}
                />
                {keyValueNotNull && (
                  <IconButton
                    className={classnames("addMetadataButton", classes.addMetadataButton)}
                    color="primary"
                    onClick={clickHandler(addMetadata)}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </div>
              <div className={classnames("typeMetadataContainer", classes.metadataTagContainer)}>
                {Object.entries(typeMetadata).map((entry: string[] = []) => (
                  <Chip
                    key={entry[0]}
                    label={`${entry[0]} : ${entry[1]}`}
                    color="primary"
                    className={classnames("metadataTag", classes.metadataTag)}
                    icon={<MetadataIcon className={classnames("scopeIcon", classes.metadataIcon)} />}
                    deleteIcon={<RemoveIcon className={classnames("removeIcon", classes.metadataIcon)} />}
                    onDelete={() => removeMetadata(entry[0])}
                  />
                ))}
              </div>
            </div>
          )}
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default DSTypeManagementDialog;
