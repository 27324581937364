import { Record } from "immutable";
import { getStringValue } from "@util";

export enum DeviceDataScope {
  NONE = "",
  DEVICE = "device",
  TYPE = "type",
  REGION = "region"
}

export interface DeviceSchemaAttributes {
  schemaIdentity: string;
  data: any;
  scope?: DeviceDataScope;
  scopeIdentity?: string;
}

export class DeviceSchema extends Record({
  schemaIdentity: "",
  data: {},
  scope: DeviceDataScope.NONE,
  scopeIdentity: "",
}) implements DeviceSchemaAttributes {

  public static EMPTY: DeviceSchema = new DeviceSchema();

  public readonly schemaIdentity: string;
  public readonly data: any;
  public readonly scope: DeviceDataScope;
  public readonly scopeIdentity: string;

  public getSchemaIdentity(): string {
    return getStringValue(this.schemaIdentity);
  }

  public getScope(): DeviceDataScope {
    return this.scope;
  }

  public getScopeIdentity(): string {
    return getStringValue(this.scopeIdentity);
  }
}

export default DeviceSchema;
