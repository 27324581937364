import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";
import { StyleRules } from "@material-ui/core/styles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "center",
    },
    link: {
      display: "flex",
    },
    disabledLink: {
      display: "flex",
      pointerEvents: "none",
    },
    tags: {
      cursor: "pointer",
      listStyleType: "none",
      padding: 0,
      margin: "0 8px 0 0",
    },
    tag: {
      overflow: "hidden",
      display: "inline-block",
      padding: 2,
    },
    chip: {
      backgroundColor: Color.SIGNIFY_GREEN + " !important",
      height: "auto !important",
      padding: "6px 10px !important",
      "& span": {
        color: Color.TEXT,
        fontSize: 11,
        fontWeight: 400,
        lineHeight: "11px",
        margin: 0,
        padding: 0,
      },
    },
    label: {
      cursor: "inherit",
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "16px",
      wordBreak: "break-word",
      marginRight: 8,
    },
    icon: {
      color: Color.SIGNIFY_GREEN,
      alignSelf: "center",
      width: 16,
      height: 16,
      marginTop: 0,
      marginRight: 10,
    },
  });

export const requiredIcon: StyleRules<string> = {
  root: {
    color: Color.SIGNIFY_GREEN,
    width: 16,
    height: 16,
    marginLeft: 8,
  },
};

export default styles;