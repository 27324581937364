import { isEmptyString } from "@util";
import { createSelector } from "reselect";
import { DEFAULT_STATE, CloseUserSchema } from "./reducers";
import { createSelectors, Selector } from "@base/createSelectors";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";

export const {
  userId: getUserId,
  open: isDialogOpen,
  selfAuthorized: isSelfAuthorized,
  getErrorMessage,
  getSuccessMessage,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  getAccessToken,
} = createSelectors<CloseUserSchema>(moduleId, schemaKey, DEFAULT_STATE);

export const isUserIdValid: Selector<boolean> = createSelector(
  getUserId, userId => !isEmptyString(userId));

export const getTitle: Selector<string> = createSelector(
  isSelfAuthorized, (selfAuthorized: boolean) =>
    selfAuthorized ? "Are you sure you want to close your account?" : "Close User Account");

export const getContinueButtonLabel: Selector<string> = createSelector(
  isSelfAuthorized, (selfAuthorized: boolean) =>
    selfAuthorized ? "Close My Account" : "Close User Account");

export const getAdminNotePlaceholder: Selector<string> = createSelector(
  isSelfAuthorized, (selfAuthorized: boolean) =>
    selfAuthorized
      ? "Please enter the reason for closing your account (Optional)"
      : "Please enter a reason for closing this user's account (Optional)");
