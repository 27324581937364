import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { useUpdateInternetAccessConfiguration } from "@hooks";
import { WorkloadInternetAccessConfig } from "@data";

export interface WorkloadInternetAccessDialogModel extends PortalModuleDialogModel<string> {
  name?: string;
  version?: number;
  etag: string;
  configuration: WorkloadInternetAccessConfig;
}

export interface WorkloadInternetAccessDialogActions extends PortalModuleDialogActions<string> {
}

type Props = WithStyles<typeof styles> & WorkloadInternetAccessDialogModel & WorkloadInternetAccessDialogActions;

export const WorkloadInternetAccessDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    name = "",
    version = 1,
    etag,
    configuration,
    cancel: cancelDialog = noop,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  const internetAccess = React.useMemo(() => configuration.internetAccess, [configuration]);

  const [{ showLoadingIndicator, ...otherModel }, { updateInternetAccessConfiguration, reset }] =
    useUpdateInternetAccessConfiguration({name, version, etag,
      configuration: {
        internetAccess: !internetAccess,
        whitelistedDnsNames: configuration.whitelistedDnsNames
    }});

  const confirm = React.useCallback(() => {
    updateInternetAccessConfiguration();
  }, [updateInternetAccessConfiguration]);

  const cancel = React.useCallback(() => {
    reset();
    cancelDialog();
  }, [reset, cancelDialog]);

  const onSuccessMessageShown = React.useCallback(() => {
    reset();
    onSuccess();
  }, [reset, onSuccess]);

  if (isEmptyString(name)) {
    return null;
  }

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      className="workloadInternetAccessDialog"
      title="Edit Workload Internet Access"
      loading={showLoadingIndicator}
      continueButtonLabel="Save"
      onSuccessMessageShown={onSuccessMessageShown}
      confirm={confirm}
      cancel={cancel}
    >
      <div className={classnames("workloadInternetAccessContainer", classes.container)}>
          <label className={classnames("title", classes.title)}>
            Are you sure you want to {!internetAccess ? "enable" : "disable"} internet access for this workload?
          </label>
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadInternetAccessDialog;
