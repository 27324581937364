import React from "react";
import { DeviceEnrollmentStatus } from "@data";
import { DeviceEnrollmentColumns } from "../reducers";
import { PrimaryIcon } from "./styles";
import {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import { isEmptyString } from "@util";
import ModuleListView from "@components/module-list-view";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import { getPathToDeviceEnrollment } from "@modules/deviceEnrollmentDetails/helpers";

export const columns = [
  DeviceEnrollmentColumns.BATCH_ID,
  DeviceEnrollmentColumns.STATUS,
  DeviceEnrollmentColumns.UPDATED,
];

export interface Model extends SortedSearchResultsListModel<DeviceEnrollmentStatus, DeviceEnrollmentColumns> {
  searchQuery?: string;
}

export interface Actions extends SortedSearchResultsListActions<DeviceEnrollmentStatus, DeviceEnrollmentColumns> {
  showDeviceEnrollmentDetails?: (deviceEnrollment: DeviceEnrollmentStatus) => void;
}

export const DeviceEnrollmentList = (props: Model & Actions) => {

  const {
    items = [],
    searchQuery,
    showDeviceEnrollmentDetails,
    ...remainingProps
  } = props;

  const moduleListItems: ListViewItem<DeviceEnrollmentStatus>[] = React.useMemo(() =>
    items.map(device => {
      return {
        item: device,
        pathToDetailsView: getPathToDeviceEnrollment(device.getBatchId()),
        icon: PrimaryIcon,
        columnAttributes: createColumns([
          {
            className: "deviceId",
            value: device.getBatchId(),
            column: DeviceEnrollmentColumns.BATCH_ID,
            firstColumn: true,
          },
          {
            className: "deviceStatus",
            value: device.getStatus(),
            column: DeviceEnrollmentColumns.STATUS,
          },
          {
            className: "deviceUpdated",
            value: device.getUpdatedBy(),
            column: DeviceEnrollmentColumns.UPDATED,
          },
        ])
      };
    }), [items]);

  return (
    <ModuleListView
      { ...remainingProps }
      columns={columns}
      listViewItems={moduleListItems}
      onClickItem={showDeviceEnrollmentDetails}
      noResultsLabel={isEmptyString(searchQuery) ? "Enter Batch ID to view status" : "Batch ID not found" }
    />
  );
};

export default DeviceEnrollmentList;
