import React from "react";
import { DataSetType } from "@data";
import { isEmptyString } from "@util";
import DataSetInfo from "@data/DataSetInfo";
import {
  useCurrentAccountId,
  useDataCatalogClient,
  usePaginatedApiRequest,
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
} from "@hooks";
import { isDeveloperAccount, isProductionAccount, isSystemAccount } from "./helpers";
import { GetDataSetsRequestOptions, GetDataSetsResponse } from "@network/DataCatalogClient";

type SuccessResponse = GetDataSetsResponse;

export interface UseDataSetsProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>>,
  GetDataSetsRequestOptions {

  dataSets?: DataSetInfo[];
  excludedDataSetTypes?: DataSetType[];
}

export interface UseDataSetsModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  dataSets: DataSetInfo[];
}

export interface UseDataSetsActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseDataSetsProps;
type Model = UseDataSetsModel;
type Actions = UseDataSetsActions;
type Result = [Model, Actions];

export const useDataSets = (props: Props): Result => {

  const {
    accountId,
    dataSetGroup,
    dataSetType,
    dataSetAliasContains,
    dataSets: initialDataSets = [],
    excludedDataSetTypes = [],
    defaultErrorMessage = "Failed to fetch data sets",
    ...otherProps
  } = props;

  const currentAccountId = useCurrentAccountId();

  const DataCatalogClient = useDataCatalogClient();

  const [dataSets, setDataSets] = React.useState<DataSetInfo[]>(initialDataSets);

  const makeApiRequest = React.useCallback((accessToken, next) =>
      DataCatalogClient.getDataSets({
        ...(isEmptyString(accountId) ? ({}) : ({ accountId })),
        ...(isEmptyString(dataSetGroup) ? ({}) : ({ dataSetGroup })),
        ...(isEmptyString(dataSetType) ? ({}) : ({ dataSetType })),
        ...(isEmptyString(dataSetAliasContains) ? ({}) : ({ dataSetAliasContains })),
        ...(isEmptyString(next) ? ({}) : ({ next })),
      }),
    [DataCatalogClient, accountId, dataSetGroup, dataSetType, dataSetAliasContains]);

  const [
    { successResponse, ...baseModel },
    { reset: baseReset, refresh: baseRefresh, ...baseActions },
  ] = usePaginatedApiRequest<SuccessResponse>({
    ...otherProps,
    defaultErrorMessage,
    makeApiRequest,
  });

  const accountFilterEnabled = React.useMemo(() =>
    !isEmptyString(accountId), [accountId]);

  const hideDeveloperAccounts = React.useMemo(() =>
    isProductionAccount(currentAccountId), [currentAccountId]);

  const updatedDataSets = React.useMemo(() => {
    const { dataSets: items = [] } = successResponse || {};
    return items.map(attrs => new DataSetInfo(attrs));
  }, [successResponse]);

  const includeAllDataSetTypes = React.useMemo(() =>
    excludedDataSetTypes.length === 0, [excludedDataSetTypes]);

  const visibleDataSets = React.useMemo(() => dataSets
      // Hide 1xxx data sets from all accounts
      .filter(dataSet => !isSystemAccount(dataSet.getAccountId()))
      // Hide 2xxx data sets from production accounts
      .filter(dataSet => !hideDeveloperAccounts || !isDeveloperAccount(dataSet.getAccountId()))
      // Hide data sets that don't match accountId filter - when enabled
      .filter(dataSet => !accountFilterEnabled || accountId === dataSet.getAccountId())
      // Filter out any excluded data set types
      .filter(dataSet => includeAllDataSetTypes ||
        !excludedDataSetTypes.includes(dataSet.getDataSetType())),
    [
      dataSets,
      hideDeveloperAccounts,
      accountFilterEnabled,
      accountId,
      includeAllDataSetTypes,
      excludedDataSetTypes,
    ]);

  const reset = React.useCallback(() => {
    setDataSets([]);
    baseReset();
  }, [setDataSets, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  // Refresh whenever a search filter is changed
  React.useEffect(() => {
    refresh();
  }, [
    refresh,
    accountId,
    dataSetGroup,
    dataSetType,
    dataSetAliasContains,
  ]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successResponse,
    dataSets: visibleDataSets,
  }), [
    baseModel,
    successResponse,
    visibleDataSets,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    setDataSets(currentDataSets => currentDataSets.concat(updatedDataSets));
  }, [updatedDataSets, setDataSets]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDataSets;
