import { createActions } from "@base";
import { ACTION_TYPES } from "@modules/namespaceDetails/reducers";
import { DEFAULT_STATE, NamespaceDetailsViewMode } from "@modules/namespaceDetails/reducers";

export const {
    viewMode: setViewMode,
    namespace: setNamespace,
    namespaceName: setNamespaceName,
    setErrorMessage,
    hideAccessDenied,
    hideEmptyView,
    showAccessDenied,
    showEmptyView,
    showLoadingIndicator,
    hideLoadingIndicator,
    ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
    dispatch(setViewMode());
    dispatch(setNamespace());
    dispatch(setNamespaceName());
    return dispatch(baseReset());
};

export const showSummaryView = () => setViewMode(NamespaceDetailsViewMode.SUMMARY);
export const showJsonView = () => setViewMode(NamespaceDetailsViewMode.JSON);

export const initialize = (id: string) => (dispatch: any) => {
    dispatch(reset());
    dispatch(setNamespaceName(id));
};
