import { Record } from "immutable";
import { getStringValue } from "@util";

export interface DSTypeLayoutAttributes {
  name: string;
}

export class DSTypeLayout extends Record({
  name: "",
}) implements DSTypeLayoutAttributes {

  public static EMPTY: DSTypeLayout = new DSTypeLayout();

  public readonly name: string;

  public getName(): string {
    return getStringValue(this.name);
  }
}
