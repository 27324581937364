import React from "react";
import classnames from "classnames";
import { AccessRequestType } from "@data";
import TypeView, { Actions as TypeViewActions, Model as TypeViewModel } from "./TypeView";

export interface Model extends TypeViewModel {
}

export interface Actions extends TypeViewActions {
}

type Props = Model & Actions;

export const IotCloudStorageAccessReasonView = (props: Props) => {

  const {
    className,
    title = "Set Access Request Reason",
    subtitle = "For IoT Cloud Storage, only READ access can be requested",
    reasonLabel = "Please enter the reason - or purpose - for requesting access to this data set",
    accessRequestType = AccessRequestType.READ,
    accessRequestTypes = [accessRequestType],
    ...otherProps
  } = props;

  return (
    <TypeView
      {...otherProps}
      className={classnames("iotCloudStorageAccessReasonView", className)}
      title={title}
      subtitle={subtitle}
      reasonLabel={reasonLabel}
      accessRequestType={accessRequestType}
      accessRequestTypes={accessRequestTypes}
      hideAccessRequestTypesDropdownMenu={true}
    />
  );
};

export default IotCloudStorageAccessReasonView;
