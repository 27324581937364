import { DeviceTypeListItem } from "@data";
import { OrderedMap } from "immutable";
import React from "react";
import { AuthTokenContext } from "@components";
import { isEmptyString } from "@util";
import {
  DeviceTypeClient,
  GetDeviceTypesResponse,
  RestClientError,
} from "@network";

export interface UseDeviceTypesProps {
  initialDeviceTypes?: DeviceTypeListItem[];
}

export interface UseDeviceTypesModel {
  deviceTypes: DeviceTypeListItem[];
  searchQuery: string;
  numResults: number;
  showLoadMoreButton: boolean;
  errorMessage: string;
  showErrorView: boolean;
  showProgressIndicator: boolean;
  showNoResultsView: boolean;
}

export interface UseDeviceTypesActions {
  setSearchQuery: (searchQuery: string) => void;
  clearList: () => void;
  loadMore: () => void;
  refresh: () => void;
}

export const useDeviceTypes = (props: UseDeviceTypesProps = {}): [UseDeviceTypesModel, UseDeviceTypesActions] => {

  const { initialDeviceTypes = [] } = props;

  const initialDeviceTypesMap = React.useMemo(() => initialDeviceTypes.reduce((data, item) => {
    return !item.hasTypeIdentity() ? data : data.set(item.getTypeIdentity(), item);
  }, OrderedMap<string, DeviceTypeListItem>( {})), [initialDeviceTypes]);

  const authToken = React.useContext(AuthTokenContext);
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [nextPageToken, setNextPageToken] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showProgressIndicator, setShowProgressIndicator] = React.useState(false);
  const [deviceTypeMap, setDeviceTypeMap] = React.useState<OrderedMap<string, DeviceTypeListItem>>(
    OrderedMap<string, DeviceTypeListItem>(initialDeviceTypesMap));

  const deviceTypes = React.useMemo(() => deviceTypeMap.toArray(), [deviceTypeMap]);

  const numResults = React.useMemo(() => deviceTypes.length, [deviceTypes]);

  const showErrorView = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]);

  const showLoadMoreButton = React.useMemo(() =>
    !isEmptyString(nextPageToken) && !showErrorView && !showProgressIndicator,
    [nextPageToken, showErrorView, showProgressIndicator]);

  const showNoResultsView = React.useMemo(() =>
    numResults === 0 && !showErrorView && !showProgressIndicator,
    [numResults, showErrorView, showProgressIndicator]);

  const updateDeviceTypes = React.useCallback((updated: OrderedMap<string, DeviceTypeListItem>) => {
    setDeviceTypeMap(current => current.merge(updated));
  }, [setDeviceTypeMap]);

  const addDeviceTypes = React.useCallback((updated: DeviceTypeListItem[]) => {
    updateDeviceTypes(updated.reduce((data, item) => {
      return !item.hasTypeIdentity() ? data : data.set(item.getTypeIdentity(), item);
    }, OrderedMap<string, DeviceTypeListItem>({})));
  }, [updateDeviceTypes]);

  const getDeviceTypes = React.useCallback(() =>
      DeviceTypeClient.getDeviceTypes(authToken, nextPageToken, searchQuery),
    [authToken, nextPageToken, searchQuery]);

  const clearList = React.useCallback(() => {
    setDeviceTypeMap(OrderedMap<string, DeviceTypeListItem>({}));
  }, [setDeviceTypeMap]);

  const loadMore = React.useCallback(() => {
    setLastRefresh(+new Date);
  }, [setLastRefresh]);

  const refresh = React.useCallback(() => {
    setNextPageToken("");
    clearList();
    loadMore();
  }, [setNextPageToken, clearList, loadMore]);

  const model = React.useMemo<UseDeviceTypesModel>(() => ({
    deviceTypes,
    searchQuery,
    numResults,
    showLoadMoreButton,
    errorMessage,
    showErrorView,
    showProgressIndicator,
    showNoResultsView,
  }), [
    deviceTypes,
    searchQuery,
    numResults,
    showLoadMoreButton,
    errorMessage,
    showErrorView,
    showProgressIndicator,
    showNoResultsView,
  ]);

  const actions = React.useMemo<UseDeviceTypesActions>(() => ({
    setSearchQuery,
    loadMore,
    refresh,
    clearList,
  }), [
    setSearchQuery,
    loadMore,
    refresh,
    clearList,
  ]);

  React.useEffect(() => {

    let ignore = false;

    setShowProgressIndicator(true);
    setErrorMessage("");

    getDeviceTypes()
      .then((response: GetDeviceTypesResponse) => {
        if (!ignore) {
          const { items = [], paging: { next: nextPage = "" } = {} } = response;
          addDeviceTypes(items.map(attrs => new DeviceTypeListItem(attrs)));
          setShowProgressIndicator(false);
          setNextPageToken(nextPage || "");
        }
      }, (response: RestClientError) => {
        if (!ignore) {
          const { error = "Fetch device types failed" } = response;
          setErrorMessage(error);
          setShowProgressIndicator(false);
        }
      });

    return () => { ignore = true; };

  }, [
    lastRefresh,
    addDeviceTypes,
    setNextPageToken,
    setErrorMessage,
    setShowProgressIndicator,
  ]);

  return React.useMemo(() => [model, actions], [model, actions]);
};

export default useDeviceTypes;
