import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";
import AuditEventDescriptor, {
  AuditEventDescriptorAttributes,
  AuditEventDescriptorMetadata,
} from "@data/AuditEventDescriptor";

export interface AuditEventAttributes {
  id: string;
  timestamp: string;
  originator: string;
  event: AuditEventDescriptorAttributes;
}

export class AuditEvent extends Record({
  id: "",
  timestamp: "",
  originator: "",
  event: AuditEventDescriptor.EMPTY.toJS(),
}) implements AuditEventAttributes {

  public static EMPTY: AuditEvent = new AuditEvent();

  public readonly id: string;
  public readonly timestamp: string;
  public readonly originator: string;
  public readonly event: AuditEventDescriptorAttributes;

  public getId(): string {
    return getStringValue(this.id);
  }

  public hasId(): boolean {
    return !isEmptyString(this.getId());
  }

  public getTimestamp(): string {
    return getStringValue(this.timestamp);
  }

  public hasTimestamp(): boolean {
    return !isEmptyString(this.getTimestamp());
  }

  public getOriginator(): string {
    return getStringValue(this.originator);
  }

  public hasOriginator(): boolean {
    return !isEmptyString(this.getOriginator());
  }

  public getEvent(): AuditEventDescriptor {
    return new AuditEventDescriptor(this.event);
  }

  public getType(): string {
    return this.getEvent().getType();
  }

  public hasType(): boolean {
    return this.getEvent().hasType();
  }

  public getSeverity(): string {
    return this.getEvent().getSeverity();
  }

  public hasSeverity(): boolean {
    return this.getEvent().hasSeverity();
  }

  public getAccountId(): string {
    return this.getEvent().getAccountId();
  }

  public hasAccountId(): boolean {
    return this.getEvent().hasAccountId();
  }

  public getPrincipal(): string {
    return this.getEvent().getPrincipal();
  }

  public hasPrincipal(): boolean {
    return this.getEvent().hasPrincipal();
  }

  public getMetadata(): AuditEventDescriptorMetadata {
    return this.getEvent().getMetadata();
  }

  public hasMetadata(): boolean {
    return this.getEvent().hasMetadata();
  }

  public getRequestId(): string {
    return this.getEvent().getRequestId();
  }

  public hasRequestId(): boolean {
    return this.getEvent().hasRequestId();
  }

  public getIP(): string {
    return this.getEvent().getIP();
  }

  public hasIP(): boolean {
    return this.getEvent().hasIP();
  }

  public getUserAgent(): string {
    return this.getEvent().getUserAgent();
  }

  public hasUserAgent(): boolean {
    return this.getEvent().hasUserAgent();
  }

  public getClientId(): string {
    return this.getEvent().getClientId();
  }

  public hasClientId(): boolean {
    return this.getEvent().hasClientId();
  }

  public getLocation(): string {
    return this.getEvent().getLocation();
  }

  public hasLocation(): boolean {
    return this.getEvent().hasLocation();
  }

  public getHttpStatus(): number {
    return this.getEvent().getHttpStatus();
  }

  public hasHttpStatus(): boolean {
    return this.getEvent().hasHttpStatus();
  }
}

export default AuditEvent;
