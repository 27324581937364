import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel,
} from "../../../../components/confirmation-dialog";
import { JsonSchemaProperty } from "../../../../data";
import { styles } from "./styles";

export interface Model extends ConfirmationDialogModel<JsonSchemaProperty> {
  children?: React.ReactNode;
}

export type Actions = ConfirmationDialogActions<JsonSchemaProperty>;

type Props = WithStyles<typeof styles> & Model & Actions;

export const PropertyEditorDialog = withStyles(styles)((props: Props) => {

  const { classes, className, children, open = false, ...remainingProps } = props;

  if (!open) {
    return null;
  }

  return (
    <ConfirmationDialog
      {...remainingProps}
      className={classnames("propertyEditorDialog", className, classes.container)}
      open={open}
    >
      {children}
    </ConfirmationDialog>
  );
});

export default PropertyEditorDialog;
