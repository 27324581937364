import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import SchemasList, { SchemasListColumn } from "../schemas-list";
import { ActionMenuItem } from "../actions-menu";
import { JsonSchemaMetadata } from "../../data";
import { useSchemas } from "@hooks";
import { styles } from "./styles";

export interface SchemasModel {
  className?: string;
  actions?: ActionMenuItem[];
  columns?: SchemasListColumn[];
  selectable?: boolean;
  selectAllDisabled?: boolean;
  selectedItems?: JsonSchemaMetadata[];
  noResultsLabel?: string;
  loadMoreLabel?: string;
  tableLayoutFixed?: boolean;
  children?: React.ReactNode;
}

export interface SchemasActions {
  onClickItem?: (item: JsonSchemaMetadata) => void;
  setSelectedItems?: (items: JsonSchemaMetadata[]) => void;
  mapItemToAction?: (item: JsonSchemaMetadata, action: ActionMenuItem) => ActionMenuItem;
  onClickAction?: (item: JsonSchemaMetadata, action: ActionMenuItem) => void;
  editSchema?: (schema: JsonSchemaMetadata) => void;
  draftNewSchemaVersion?: (schema: JsonSchemaMetadata) => void;
  promoteSchema?: (schema: JsonSchemaMetadata) => void;
  deprecateSchema?: (schema: JsonSchemaMetadata) => void;
  decommissionSchema?: (schema: JsonSchemaMetadata) => void;
  deleteSchema?: (schema: JsonSchemaMetadata) => void;
}

type Props = WithStyles<typeof styles> & SchemasModel & SchemasActions;

export const Schemas = withStyles(styles)((props: Props) => {

  const { classes, className, children, ...otherProps } = props;

  const [ model, api ] = useSchemas();

  return (
    <div className={classnames("schemas", classes.container)}>
      <SchemasList
        {...model}
        {...api}
        {...otherProps}
        hideSummary={false}
        hideSearch={false}
        showSearchFilters={true}
      >
        {children}
      </SchemasList>
    </div>
  );
});

export default Schemas;
