import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base";
import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";

export interface PasswordRecoverySchema extends BasePortalModuleSchema {
  userId: string;
  emailTemplateId: string;
}

export const DEFAULT_STATE: PasswordRecoverySchema = createPortalModuleDefaultState({
  userId: "",
  emailTemplateId: "",
});

type PasswordRecoveryActionTypes = PortalModuleActionTypes<PasswordRecoverySchema> & {
  PASSWORD_RECOVERY_REQUEST: string;
  PASSWORD_RECOVERY_SUCCESS: string;
  PASSWORD_RECOVERY_FAILED: string;
};

export const ACTION_TYPES: PasswordRecoveryActionTypes = {
  ...createPortalModuleActionTypes<PasswordRecoverySchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    PASSWORD_RECOVERY_REQUEST: `${MODULE_ID}/password_recovery_request`,
    PASSWORD_RECOVERY_SUCCESS: `${MODULE_ID}/password_recovery_success`,
    PASSWORD_RECOVERY_FAILED: `${MODULE_ID}/password_recovery_failed`,
  },
};

export const PasswordRecoveryReducer = combineReducers<PasswordRecoverySchema>(
  createPortalModuleReducers<PasswordRecoverySchema>(ACTION_TYPES, DEFAULT_STATE));

export default PasswordRecoveryReducer;
