import { AppSchema } from "@main/schemas";
import { getAuthToken, getCurrentAccountId } from "@main/selectors";
import { DataSetRequestClient, RestClientError } from "@network";
import { refresh as refreshDataCatalogModule } from "@modules/dataCatalog/actions";
import { DataSetRequestWizardActions } from "../actions";
import { DataSetRequestAttributes, CreateDataSetRequest, DataSetType, DataSetState } from "@data";
import { DataSetRequestWizardSelectors } from "../selectors";
import { DataSetRequestWizardStep } from "../reducers/dataSetRequestWizard";
import { toLRNs, toUserIds } from "../helpers";

const getJson = (state: AppSchema) => {

  if (DataSetRequestWizardSelectors.isRequestIoTApproved(state)) {
    // only allow to edit description for IoT approved request
    const description = DataSetRequestWizardSelectors.getDescription(state);
    const descObj = {description: description};
    return JSON.stringify(descObj, null, "  ");
  } else {
    const accountId = getCurrentAccountId(state);
    const dataSetRequest = DataSetRequestWizardSelectors.getCreateDataSetRequestAttributes(state);
    const excludeLifecyclePolicy = DataSetRequestWizardSelectors.isLifecyclePolicyEmpty(state);

    const dataSetRequestCopy = new CreateDataSetRequest({
      ...dataSetRequest,
      dataOwners: toLRNs(dataSetRequest.dataOwners, accountId),
      architects: toLRNs(dataSetRequest.architects, accountId),
      engineeringContacts: toLRNs(dataSetRequest.engineeringContacts, accountId),
      businessOwners: toLRNs(dataSetRequest.businessOwners, accountId),
      dataSetAlias: undefined,
      confirmNonProductionData: undefined,
      ...(dataSetRequest.dataSetType === DataSetType.DATA_LAKE_ACQUIRED ? ({}) : ({ ingestionMechanism: undefined })),
    }).toJS();

    if (excludeLifecyclePolicy) {
      delete dataSetRequestCopy.lifecyclePolicy;
    }

    return JSON.stringify(dataSetRequestCopy, null, "  ");
  }
};

export const save = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accessToken = DataSetRequestWizardSelectors.getAccessToken(state);
  const dataSetRequestId = DataSetRequestWizardSelectors.getDataSetRequestRef(state);
  const json = getJson(state);

  dispatch(DataSetRequestWizardActions.showLoadingIndicator());
  dispatch(DataSetRequestWizardActions.hideAccessDenied());
  dispatch(DataSetRequestWizardActions.setErrors());
  dispatch(DataSetRequestWizardActions.setErrorMessage());
  dispatch(DataSetRequestWizardActions.editDataSetRequestRequest());

  return DataSetRequestClient.editDataSetRequest(accessToken, dataSetRequestId, json)
    .then(() => {

      dispatch(DataSetRequestWizardActions.editDataSetRequestSuccess());
      dispatch(DataSetRequestWizardActions.hideLoadingIndicator());
      dispatch(DataSetRequestWizardActions.setSuccessMessage("Data Set Request Updated"));
      return dispatch(refreshDataCatalogModule());

    }, (response: RestClientError) => {

      const { analytic, error = "Failed to update Data Set Request", errors } = response;

      dispatch(DataSetRequestWizardActions.editDataSetRequestFailed(analytic));
      dispatch(DataSetRequestWizardActions.setErrors(errors));
      dispatch(DataSetRequestWizardActions.setErrorMessage(error));

      if (response.status === 403) {
        dispatch(DataSetRequestWizardActions.showAccessDenied());
      }

      return dispatch(DataSetRequestWizardActions.hideLoadingIndicator());
    });
};

export const fetchDataSetRequest = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const authToken = getAuthToken(state);
  const dataSetRequestId = DataSetRequestWizardSelectors.getDataSetRequestRef(state);

  dispatch(DataSetRequestWizardActions.showLoadingIndicator());
  dispatch(DataSetRequestWizardActions.hideAccessDenied());
  dispatch(DataSetRequestWizardActions.setErrors());
  dispatch(DataSetRequestWizardActions.setErrorMessage());
  dispatch(DataSetRequestWizardActions.setShowEditModeInitializeError(false));
  dispatch(DataSetRequestWizardActions.fetchDataSetRequestRequest());

  return DataSetRequestClient.getDataSetRequest(authToken, dataSetRequestId)
    .then((attrs: DataSetRequestAttributes) => {

      dispatch(DataSetRequestWizardActions.fetchDataSetRequestSuccess());
      const attrsCopy = JSON.parse(JSON.stringify(attrs));
      attrsCopy.dataOwners = toUserIds(attrsCopy.dataOwners);
      attrsCopy.architects = toUserIds(attrsCopy.architects);
      attrsCopy.engineeringContacts = toUserIds(attrsCopy.engineeringContacts);
      attrsCopy.businessOwners = toUserIds(attrsCopy.businessOwners);

      if (attrsCopy.lifecyclePolicy.archivingInDays === -1) {
        attrsCopy.lifecyclePolicy.archivingInDays = 0;
      }

      if (attrsCopy.lifecyclePolicy.expirationInDays === -1) {
        attrsCopy.lifecyclePolicy.expirationInDays = 0;
      }

      dispatch(DataSetRequestWizardActions.updateDataSetRequest(new CreateDataSetRequest(attrsCopy)));
      dispatch(DataSetRequestWizardActions.setRequestIoTApproved(
        attrs.status !== DataSetState.PENDING_IOT_APPROVAL &&
        attrs.status !== DataSetState.PENDING_PRIVACY_TNC_UPLOAD));
      dispatch(DataSetRequestWizardActions.hideLoadingIndicator());
      return dispatch(DataSetRequestWizardActions.hideEmptyView());

    }, (response: RestClientError) => {

      const { analytic, error = "Failed to fetch data set request", errors } = response;

      dispatch(DataSetRequestWizardActions.fetchDataSetRequestFailed(analytic));
      dispatch(DataSetRequestWizardActions.setErrors(errors));
      dispatch(DataSetRequestWizardActions.setErrorMessage(error));
      dispatch(DataSetRequestWizardActions.setShowEditModeInitializeError(true));
      dispatch(DataSetRequestWizardActions.hideLoadingIndicator());

      if (response.status === 403) {
        dispatch(DataSetRequestWizardActions.showAccessDenied());
      }

      return dispatch(DataSetRequestWizardActions.hideEmptyView());
    });
};

export const initialize = (dataSetRequestId: string = "") => (dispatch: any) => {

  dispatch(DataSetRequestWizardActions.reset());
  dispatch(DataSetRequestWizardActions.showEmptyView());
  dispatch(DataSetRequestWizardActions.setDataSetRequestRef(dataSetRequestId));
  dispatch(DataSetRequestWizardActions.setShowEditMode(true));
  dispatch(DataSetRequestWizardActions.setDataSetRequestWizardStep(DataSetRequestWizardStep.DATA_SET_ALIAS));
  return dispatch(fetchDataSetRequest());
};

export const retryInitialize = () => (dispatch: any, getState: () => AppSchema) => {
  return dispatch(initialize(DataSetRequestWizardSelectors.getDataSetRequestRef(getState())));
};
