import ModuleIcon from "@material-ui/icons/SettingsCell";
import createStyles from "@material-ui/core/styles/createStyles";
import { Color } from "@components/styles";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const listItem = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "center",
      "&:first-child": {
        marginLeft: 4,
      },
    },
    icon: {
      color: primaryColor,
      alignSelf: "center",
      width: 16,
      height: 16,
      marginTop: 0,
      marginRight: 10,
    },
    label: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "16px",
      wordBreak: "break-word",
      marginRight: 8,
    },
  });

export const styles = () =>
  createStyles({
    container: {
      color: primaryColor,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    search: {
      marginLeft: 6,
      marginTop: 15,
    },
    controls: {
      flex: "1 0 auto",
      display: "inline-flex",
      justifyContent: "flex-end",
    },
    validateDeviceButton: {
      marginLeft: "auto",
      padding: "6px 16px",
    },
    enrollDeviceButton: {
      marginLeft: 15,
      padding: "6px 16px",
    },
    batchEnrollDeviceButton: {
      marginLeft: 15,
      padding: "6px 16px",
    },
  });

export default styles;
