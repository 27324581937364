import React from "react";
import { SecurityServiceRegional } from "@data";
import { useHistory } from "react-router-dom";
import { getCreateServicePath } from "@modules/createServiceRegional/helpers";
import { getPathToService } from "@modules/securityServiceRegionalDetails/helpers";
import SecurityServices from "./containers/SecurityServices";
import {
  getAddServiceToGroupPath,
  getRemoveServiceFromGroupPath,
} from "@modules/groupManagementWizard/helpers";
import {
  getAttachPoliciesToServicePath,
  getDetachPoliciesFromServicePath,
} from "@modules/policyManagement/helpers";

export function SecurityServicesView() {

  const history = useHistory();

  const createService = React.useCallback(() =>
    history.push(getCreateServicePath()), [history]);

  const showServiceDetails = React.useCallback((service: SecurityServiceRegional) =>
    history.push(getPathToService(service.getId())), [history]);

  const addServiceToGroup = React.useCallback((service: SecurityServiceRegional) =>
    history.push(getAddServiceToGroupPath(service.getId())), [history]);

  const removeServiceFromGroup = React.useCallback((service: SecurityServiceRegional) =>
    history.push(getRemoveServiceFromGroupPath(service.getId())), [history]);

  const attachPolicy = React.useCallback((service: SecurityServiceRegional) =>
    history.push(getAttachPoliciesToServicePath(service.getId())), [history]);

  const detachPolicy = React.useCallback((service: SecurityServiceRegional) =>
    history.push(getDetachPoliciesFromServicePath(service.getId())), [history]);

  return (
    <SecurityServices
      createService={createService}
      showServiceDetails={showServiceDetails}
      addServiceToGroup={addServiceToGroup}
      removeServiceFromGroup={removeServiceFromGroup}
      attachPolicy={attachPolicy}
      detachPolicy={detachPolicy}
    />
  );
}

export default SecurityServicesView;
