import createStyles from "@material-ui/core/styles/createStyles";
import { InventoryIcon as ModuleIcon } from "@components/icons";

export const PrimaryIcon = ModuleIcon;

export const styles = () =>
  createStyles({
    container: {
    },
    list: {
    },
    staticLayout: {
      "& th.name, & td.name": {
        width: "27%",
      },
      "& th.account, & td.account": {
        width: "13%",
      },
      "& th.group, & td.group": {
        width: "15%",
      },
      "& th.type, & td.type": {
        width: "10%",
      },
      "& th.tags, & td.tags": {
        width: "22%",
        "& ul li .chip": {
          cursor: "inherit",
        },
      },
      "& th.pii, & td.pii": {
        width: "8%",
      },
      "& th.actions, & td.actions": {
        width: "5%",
      },
    },
    dataSetType: {
      fontSize: 11,
    },
    loadMoreButton: {
    },
  });

export default styles;
