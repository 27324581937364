import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      backgroundColor: Color.LIGHT_GREY4,
      padding: "24px 12px",
      border: `1px solid ${Color.GREY1}`,
      borderRadius: 6,
    },
    filtersMenu: {
    },
    breadcrumbs: {
      marginLeft: 12,
    },
    refreshButton: {
      color: Color.SIGNIFY_GREEN,
      marginLeft: 12,
      minWidth: 20,
      minHeight: 20,
      width: 20,
      height: 20,
    },
    refreshButtonIcon: {
      width: 14,
      height: 14,
    },
  });

export default styles;
