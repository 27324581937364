import React from "react";
import { isEmptyString, noop } from "@util";
import { CreateDataAccessRequest } from "@data";
import { CreateDataAccessRequestResponse } from "@network";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useDataAccessRequestClient } from "@hooks";

export interface UseCreateDataAccessRequestProps extends Partial<UseApiRequestProps<CreateDataAccessRequestResponse>> {
  data?: CreateDataAccessRequest;
  refreshDataCatalogModule?: () => void;
}

export interface UseCreateDataAccessRequestModel extends UseApiRequestModel<CreateDataAccessRequestResponse> {
  accessRequestId: string;
  successMessage: string;
}

export interface UseCreateDataAccessRequestActions {
  createDataAccessRequest: () => void;
}

type Props = UseCreateDataAccessRequestProps;
type Model = UseCreateDataAccessRequestModel;
type Actions = UseCreateDataAccessRequestActions;
type Result = [Model, Actions];

export const useCreateDataAccessRequest = (props: Props): Result => {

  const {
    defaultErrorMessage = "Failed to create data access request",
    data = CreateDataAccessRequest.EMPTY,
    refreshDataCatalogModule = noop,
    ...otherProps
  } = props;

  const DataAccessRequestClient = useDataAccessRequestClient();

  const json = data.toJS();

  if (isEmptyString(data.getDataSetGroup())) {
    delete json.dataSetGroup;
  }

  const [{ successResponse, ...baseModel }, { refresh: createDataAccessRequest }] =
    useApiRequest<CreateDataAccessRequestResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => DataAccessRequestClient.createDataAccessRequest(
        JSON.stringify(json)),
    });

  const accessRequestId = React.useMemo(() => {
    const { accessRequestId: id = "" } = successResponse || {};
    return id;
  }, [successResponse]);

  const successMessage = React.useMemo(() =>
    isEmptyString(accessRequestId) ? "" : "Data Access Request Created", [accessRequestId]);

  React.useEffect(() => {
    if (!isEmptyString(successMessage)) {
      refreshDataCatalogModule();
    }
  }, [successMessage, refreshDataCatalogModule]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    accessRequestId,
  }), [
    baseModel,
    successMessage,
    accessRequestId,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    createDataAccessRequest,
  }), [
    createDataAccessRequest,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useCreateDataAccessRequest;
