import React from "react";
import { Location } from "history";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getPathToEmailTemplate } from "@modules/emailTemplateDetails/helpers";
import EmailTemplateWizard from "./containers/EmailTemplateWizard";

type LocationProps = {
  from?: Location;
  goBackOnSuccess?: boolean;
};

export function EditEmailTemplateView() {

  const history = useHistory();

  const { id: emailTemplateId } = useParams<{ id: string }>();

  const { state: { from = undefined, goBackOnSuccess = false } = {} } = useLocation<LocationProps>();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  // If specified, go back, otherwise either replace this history entry
  // with the provided entry point or goto the details page
  const onSuccess = React.useCallback(() => {
    if (goBackOnSuccess) {
      history.goBack();
    } else if (from) {
      history.replace(from);
    } else {
      history.replace(getPathToEmailTemplate(emailTemplateId));
    }
  }, [history, from, goBackOnSuccess, emailTemplateId]);

  return (
    <EmailTemplateWizard
      key={emailTemplateId}
      editMode={true}
      emailTemplateId={emailTemplateId}
      className="editEmailTemplateWizard"
      snackbarId="edit-email-template"
      cancel={cancel}
      onSuccess={onSuccess}
    />
  );
}

export default EditEmailTemplateView;
