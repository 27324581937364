import { isEmptyString } from "@util";
import DataSetsListItemData from "../models/DataSetsListItemData";

export const getSearchResults = (items: DataSetsListItemData[], searchQuery: string) => {

  const dataSets = items.slice();

  if (dataSets.length === 0 || isEmptyString(searchQuery)) {
    return dataSets;
  }

  const searchFilter = searchQuery.toLowerCase();

  return dataSets.filter((dataSet: DataSetsListItemData) =>
    dataSet.getName().toLowerCase().indexOf(searchFilter) >= 0 ||
    dataSet.getAccount().toLowerCase().indexOf(searchFilter) >= 0 ||
    dataSet.getDescription().toLowerCase().indexOf(searchFilter) >= 0 ||
    dataSet.getDataSetGroup().toLowerCase().indexOf(searchFilter) >= 0 ||
    dataSet.getTags().join(",").toLowerCase().indexOf(searchFilter) >= 0);
};

export default getSearchResults;
