import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    icon: {
      color: Color.SIGNIFY_GREEN,
      width: 16,
      height: 16,
      marginRight: 8,
    },
    label: {
      color: Color.TEXT,
      cursor: "pointer",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
      wordBreak: "break-word",
    },
    applicationId: {
    },
    applicationName: {
    },
    applicationDescription: {
    },
  });

export default styles;
