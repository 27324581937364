import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import ErrorView from "../error-view";
import LoadingView from "../loading-view";
import { AccessDeniedView, NotFoundView } from "@components";
import { UseApiRequestActions, UseApiRequestModel } from "@hooks";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface WaitForApiRequestViewModel extends Partial<UseApiRequestModel> {
  className?: string;
  loadingMessage?: string;
  errorTitle?: string;
  errorRetryButtonLabel?: string;
  retryList?: (() => void)[];
}

export interface WaitForApiRequestViewActions extends Partial<UseApiRequestActions> {
  retry?: () => void;
}

type Model = WaitForApiRequestViewModel;
type Actions = WaitForApiRequestViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const WaitForApiRequestView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    loadingMessage,
    showLoadingIndicator,
    errorTitle,
    errorRetryButtonLabel,
    errorMessage,
    errors,
    showErrorView,
    showAccessDenied,
    showNotFound,
    retry = noop,
    retryList = [],
    children = null,
    statusCode,
  } = props;

  if (showLoadingIndicator) {
    return (
      <div className={classnames(className, classes.container)}>
        <LoadingView
          className={classnames("loadingView", classes.loadingView)}
          label={loadingMessage}
        />
      </div>
    );
  }

  if (showAccessDenied) { return <AccessDeniedView/>; }

  if (showNotFound) { return <NotFoundView/>; }

  if (showErrorView) {
    return (
      <div className={classnames(className, classes.container)}>
        <ErrorView
          className={classnames("error", classes.errorView)}
          title={errorTitle}
          message={errorMessage}
          errors={errors}
          retryButtonLabel={errorRetryButtonLabel}
          retryList={retryList}
          retry={retry}
          statusCode={statusCode}
        />
      </div>
    );
  }

  return (
    <div className={classnames(className, classes.container)}>
      {children}
    </div>
  );
});

export default WaitForApiRequestView;
