import React from "react";
import {
  Model,
  Actions,
  DSTypeListDialog
} from "../components/DSTypeListDialog";
import { useListDSTypes } from "../../../hooks/digitalShadow/use-list-ds-types/useListDSTypes";
import { DSTypesLayoutsListColumn } from "@components";
import { noop } from "@util";
import { DSTypeInfo } from "@data";

const DEFAULT_TYPES_LIST_COLUMNS: DSTypesLayoutsListColumn[] = [
  DSTypesLayoutsListColumn.NAME,
];

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
  cancel?: () => void;
  addChild?: (item: DSTypeInfo) => void;
}

const DSTypeListDialogContainer = (props: ContainerModel & ContainerActions) => {

  const {
    columns = DEFAULT_TYPES_LIST_COLUMNS,
    open,
    cancel = noop,
    addChild = noop,
    ...otherProps
  } = props;

  const [ model, actions ] = useListDSTypes();

  const { types = [], showLoadingIndicator, ...otherModel } = model;

  if (!open) {
    return null;
  }

  return (
    <DSTypeListDialog
      {...otherProps}
      {...otherModel}
      {...actions}
      open={open}
      types={types}
      loading={showLoadingIndicator}
      loadingLabel="Loading Types"
      columns={columns}
      cancel={cancel}
      save={addChild}
    />
  );

};

export default DSTypeListDialogContainer;
