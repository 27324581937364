import { Record } from "immutable";
import { getStringValue } from "@util";

export interface UserFeedbackAttributes {
  message?: string;
  imageData?: string;
}

export class UserFeedback extends Record({
  message: "",
  imageData: "",
}) implements UserFeedbackAttributes {

  public static EMPTY: UserFeedback = new UserFeedback();

  public readonly message: string;
  public readonly imageData: string;

  public getMessage(): string {
    return getStringValue(this.message);
  }

  public getImageData(): string {
    return getStringValue(this.imageData);
  }
}

export default UserFeedback;
