import { createStyles, StyleRules } from "@material-ui/core/styles";
import Color from "@components/styles/Color";

export const loginButton: StyleRules = {
  root: {
    backgroundColor: Color.SIGNIFY_GREEN,
    "&:hover": {
      backgroundColor: Color.SIGNIFY_GREEN2,
    },
    "&:active": {
      backgroundColor: Color.SIGNIFY_GREEN3,
    },
  },
  label: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "16px",
    padding: 0,
    margin: "0 30px",
    color: Color.BLACK,
  },
};

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      marginTop: 25,
    },
    serviceId: {
    },
    accountId: {
      marginTop: 15,
    },
    secret: {
      marginTop: 15,
    },
    mfaCode: {
      marginTop: 15,
    },
    rememberService: {
    },
    loginButton: {
      marginTop: 15,
      zIndex: 0,
    },
  });

export default styles;
