import React from "react";
import classnames from "classnames";
import { request as styles } from "./styles";
import { ContinueButton, JsonView } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface Model {
  json?: string;
  title?: string;
  isEnrollDeviceButtonDisabled?: boolean;
}

export interface Actions {
  onClickEnrollDevice?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DeviceRequestPayloadView = withStyles(styles)((props: Props) => {

  const {
    classes,
    json,
    title = "Device Payload",
    isEnrollDeviceButtonDisabled,
    onClickEnrollDevice,
  } = props;

  return (
    <div className={classnames("deviceRequestPayloadView", classes.container)}>
      <div className={classnames("header", classes.header)}>
        <h1 className={classnames("devicePayloadTitle", classes.title)}>
          {title}
        </h1>
        <ContinueButton
          className={classnames("enrollDeviceButton", classes.enrollDeviceButton)}
          onClick={onClickEnrollDevice}
          disabled={isEnrollDeviceButtonDisabled}
        >
          Enroll Device
        </ContinueButton>
      </div>
      <JsonView
        className={classnames("deviceRequestJsonView", classes.jsonView)}
        name="deviceRequestJsonView"
        json={json}
        fileName={`${title}.json`}
      />
    </div>
  );
});

export default DeviceRequestPayloadView;
