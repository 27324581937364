import React from "react";
import debounce from "lodash/debounce";
import { connect } from "react-redux";
import { DeviceTypeListItem } from "@data";
import { equalsIgnoreCase } from "@util";
import { AppSchema } from "@main/schemas";
import { getNumDeviceTypes, getSearchQuery, isLoadingIndicatorVisible } from "../selectors";
import {
  fetchDeviceTypes,
  reloadDeviceTypes,
  setNextPage,
  setSearchQuery,
  setSortedColumn,
  setSortOrder,
} from "../actions";
import DeviceTypesView, { Actions, Model } from "../components/DeviceTypes";
import DeviceTypesList from "./DeviceTypesList";

export interface ContainerModel extends Model {
}

export interface ContainerActions extends Actions {
  showDeviceTypeDetails?: (deviceType: DeviceTypeListItem) => void;
}

type Props = ContainerModel & ContainerActions;

const DeviceTypesContainer = (props: Props) => {

  const { showDeviceTypeDetails, ...otherProps } = props;

  return (
    <DeviceTypesView {...otherProps}>
      <DeviceTypesList showDeviceTypeDetails={showDeviceTypeDetails} />
    </DeviceTypesView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  numItems: getNumDeviceTypes(state),
  searchQuery: getSearchQuery(state),
  loading: isLoadingIndicatorVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => {

  // Debounce the network request to fetch device types that match the search query as user types
  const search = debounce(() => dispatch(fetchDeviceTypes()), 500);

  return {
    updateSearchQuery: (searchQuery: string = "", previousSearchQuery: string = "") => {
      dispatch(setSearchQuery(searchQuery));

      if (!equalsIgnoreCase(searchQuery, previousSearchQuery)) {
        dispatch(setSortOrder());
        dispatch(setSortedColumn());
        dispatch(setNextPage());
        search();
      }
    },
    refresh: () => dispatch(reloadDeviceTypes()),
    ...ownProps,
  };
};

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceTypesContainer);
