import React from "react";
import { useHistory } from "react-router-dom";
import { Application } from "@data";
import { getPathToApplication } from "@modules/applicationRegionalDetails/helpers";
import Applications from "./containers/Applications";
import {
  getCreateApplicationPath,
  getEditApplicationPath
} from "@modules/applicationWizardRegional/helpers";

export function ApplicationsView() {

  const history = useHistory();

  const showApplicationDetails = React.useCallback((application: Application) =>
    history.push(getPathToApplication(application.getId())), [history]);

  const createApplication = React.useCallback(() =>
    history.push(getCreateApplicationPath()), [history]);

  const editApplication = React.useCallback((application: Application) =>
    history.push(getEditApplicationPath(application.getId())), [history]);

  return (
    <Applications
      showApplicationDetails={showApplicationDetails}
      createApplication={createApplication}
      editApplication={editApplication}
    />
  );
}

export default ApplicationsView;
