import { isEmptyString, isValidInteger } from "@util";
import {
  DataLakePreSignedUrlAttributes,
  DataLakeDownloadCredentialsAttributes,
  DataLakeMultiPartUploadUrlMapAttributes } from "@data";
import {
  createQueryParams, makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments
} from "@network/helpers";

const DATA_LAKE_FILE_TRANSFER_API = process.env.REACT_APP_DATA_LAKE_FILE_TRANSFER_API;

if (isEmptyString(DATA_LAKE_FILE_TRANSFER_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_DATA_LAKE_FILE_TRANSFER_API");
}

export interface GetDownloadUrlResponse {
  urlResponse: DataLakePreSignedUrlAttributes[];
}

export interface GetDownloadCredentialResponse {
  cloud: DataLakeDownloadCredentialsAttributes;
}

export interface GetBlobUploadUrlResponse {
  presignedURL: string;
}

export interface BlobFilesBeginUploadResponse {
  uploadId: string;
}

export interface CompleteBlobFilesUploadResponse {
  etag: string;
  fileName: string;
  fileSize: string;
}

export type DataLakeFileMetadata = {
  [key: string]: string;
};

export interface GetDataLakeFileMetadataResponse {
  file: string;
  metadata: DataLakeFileMetadata;
}

export const getAcquiredFilesDownloadUrl = (authToken: string,
                                            dataSet: string,
                                            files: string[],
                                            accountId: string = ""): Promise<GetDownloadUrlResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Data Set", dataSet],
      ["Files", Array.isArray(files) ? files.join(",") : ""],
    ]));

  const makeRequest = () => {

    const url = DATA_LAKE_FILE_TRANSFER_API +
      `/data/persistence/v1/acquired-file-repositories/${dataSet}/files/transfers/downloads/urls`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-IoT-Platform-AccountId": `${accountId}`,
      },
      body: JSON.stringify({ files }),
    };

    const defaultErrorMessage = `Failed to get acquired files download Url`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);

};

export const getAcquiredStreamDownloadUrl = (authToken: string,
                                            streamName: string,
                                            json: string,
                                            accountId: string = ""): Promise<GetDownloadUrlResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Stream Name", streamName],
      ["Files", json],
    ]));

  const makeRequest = () => {

    const url = `${DATA_LAKE_FILE_TRANSFER_API}/data/persistence/v1/acquired-streams/${streamName}/files/transfers/downloads/urls`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-IoT-Platform-AccountId": `${accountId}`,
      },
      body: json,
    };

    const defaultErrorMessage = `Failed to get acquired streams download Url`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);

};

export const getBlobFilesDownloadUrl = (authToken: string,
                                        blobStoreName: string,
                                        json: string,
                                        accountId: string = ""): Promise<GetDownloadUrlResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Blob Store Name", blobStoreName],
      ["Files", json],
    ]));

  const makeRequest = () => {

    const url = `${DATA_LAKE_FILE_TRANSFER_API}/data/persistence/v1/blob-stores/${blobStoreName}/files/transfers/downloads/urls`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-IoT-Platform-AccountId": `${accountId}`,
      },
      body: json,
    };

    const defaultErrorMessage = `Failed to get blob stores download Url`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);

};

export const getAcquiredFilesCredentials = (authToken: string,
                                            fileRepoName: string,
                                            json: string,
                                            accountId: string = ""): Promise<GetDownloadCredentialResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["File Repo Name", fileRepoName],
      ["Files", json],
    ]));

  const makeRequest = () => {

    const url = `${DATA_LAKE_FILE_TRANSFER_API}/data/persistence/v1/acquired-file-repositories/${fileRepoName}/files/transfers/downloads/credentials`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-IoT-Platform-AccountId": `${accountId}`,
      },
      body: json,
    };

    const defaultErrorMessage = `Failed to get acquired files download credentials`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);

};

export const getAcquiredStreamCredentials = (authToken: string,
                                            streamName: string,
                                            json: string,
                                            accountId: string = ""): Promise<GetDownloadCredentialResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Stream Name", streamName],
      ["Files", json],
    ]));

  const makeRequest = () => {

    const url = `${DATA_LAKE_FILE_TRANSFER_API}/data/persistence/v1/acquired-streams/${streamName}/files/transfers/downloads/credentials`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-IoT-Platform-AccountId": `${accountId}`,
      },
      body: json,
    };

    const defaultErrorMessage = `Failed to get acquired streams download credentials`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);

};

export const getBlobFilesCredentials = (authToken: string,
                                        blobStoreName: string,
                                        json: string,
                                        accountId: string = ""): Promise<GetDownloadCredentialResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Blob Store Name", blobStoreName],
      ["Files", json],
    ]));

  const makeRequest = () => {

    const url = `${DATA_LAKE_FILE_TRANSFER_API}/data/persistence/v1/blob-stores/${blobStoreName}/files/transfers/downloads/credentials`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-IoT-Platform-AccountId": `${accountId}`,
      },
      body: json,
    };

    const defaultErrorMessage = `Failed to get blob stores download credentials`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);

};

export const getBlobFilesSingleFileUploadUrl = (authToken: string,
                                                blobStoreName: string,
                                                fileName: string): Promise<GetBlobUploadUrlResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Blob Store Name", blobStoreName],
      ["File Name", fileName],
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({
      fileName,
    });

    const url = `${DATA_LAKE_FILE_TRANSFER_API}/data/persistence/v1/blob-stores/${blobStoreName}/files/transfers/uploads${queryParams}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      }
    };

    const defaultErrorMessage = `Failed to get blob stores upload URL`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);

};

export const startMultipartBlobFileUpload = (authToken: string, blobStoreName: string, fileName: string):
  Promise<BlobFilesBeginUploadResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Blob Store Name", blobStoreName],
      ["File Name", fileName],
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({
      fileName,
    });

    const url = `${DATA_LAKE_FILE_TRANSFER_API}/data/persistence/v1/blob-stores/${blobStoreName}/files/transfers/uploads/begin${queryParams}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      }
    };

    const defaultErrorMessage = `Failed to start multi part upload`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);

};

export const cancelMultipartBlobFileUpload = (authToken: string, blobStoreName: string, uploadId: string):
  Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Blob Store Name", blobStoreName],
      ["Upload Id", uploadId],
    ]));

  const makeRequest = () => {

    const url = `${DATA_LAKE_FILE_TRANSFER_API}/data/persistence/v1/blob-stores/${blobStoreName}/files/transfers/uploads/cancel/${uploadId}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      }
    };

    const defaultErrorMessage = `Failed to cancel multi part upload`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);

};

export const completeMultipartBlobFileUpload = (authToken: string,
                                                blobStoreName: string,
                                                uploadId: string,
                                                json: string): Promise<CompleteBlobFilesUploadResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Blob Store Name", blobStoreName],
      ["Upload Id", uploadId],
      ["Etags", json],
    ]));

  const makeRequest = () => {

    const url = `${DATA_LAKE_FILE_TRANSFER_API}/data/persistence/v1/blob-stores/${blobStoreName}/files/transfers/uploads/complete/${uploadId}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json
    };

    const defaultErrorMessage = `Failed to complete multi part upload`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);

};

export const getMultiPartBlobUploadUrl = (authToken: string,
  blobStoreName: string,
  uploadId: string,
  startingPartNumber: number,
  partCount: number): Promise<DataLakeMultiPartUploadUrlMapAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Blob Store Name", blobStoreName],
      ["Upload Id", uploadId],
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({
      ...(!isValidInteger(startingPartNumber) ? ({}) : ({ startingPartNumber })),
      ...(!isValidInteger(partCount) ? ({}) : ({ partCount })),
    });

    const url = `${DATA_LAKE_FILE_TRANSFER_API}/data/persistence/v1/blob-stores/${blobStoreName}/files/transfers/uploads/get-part-upload-url/${uploadId}${queryParams}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      }
    };

    const defaultErrorMessage = `Failed to get multi part upload urls`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getDataLakeFileMetadata = (authToken: string,
                                        dataSet: string,
                                        file: string,
                                        accountId: string = ""): Promise<GetDataLakeFileMetadataResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Data Set", dataSet],
      ["File", file],
    ]));

  const makeRequest = () => {

    const url = DATA_LAKE_FILE_TRANSFER_API + "/data/persistence/v1/acquired-file-repositories/" +
      `${dataSet}/files/metadata`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-IoT-Platform-AccountId": `${accountId}`,
      },
      body: JSON.stringify({ file }),
    };

    const defaultErrorMessage = "Failed to get data lake file metadata";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
