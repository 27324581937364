import React from "react";
import classnames from "classnames";
import ActionsMenu, { ActionMenuItem, ActionsMenuProps } from "../actions-menu";
import { noop } from "@util";
import {
  ADD_DATA_LAKE_TRIGGER,
  ADD_SCHEDULE_TRIGGER,
  ADD_WORKLOAD_COMPLETE_TRIGGER,
  WorkloadAction
} from "@components";
import { TriggerType } from "@data";

export const DEFAULT_ADD_TRIGGER_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  ADD_DATA_LAKE_TRIGGER,
  ADD_SCHEDULE_TRIGGER,
  ADD_WORKLOAD_COMPLETE_TRIGGER,
]) as ActionMenuItem[];

export interface AddTriggerActionsMenuProps extends ActionsMenuProps {
  addTrigger?: () => void;
}

export const withAddTriggerActions = (Component: React.ComponentType<AddTriggerActionsMenuProps>) =>
  (props: AddTriggerActionsMenuProps) => {

    const {
      actions = DEFAULT_ADD_TRIGGER_ACTION_MENU_ITEMS,
      addTrigger = noop,
      onClickAction = noop,
    } = props;

    return (
      <Component
        {...props}
        actions={actions}
        onClickAction={(action: ActionMenuItem) => {
          if (action.id === WorkloadAction.ADD_DATA_LAKE_TRIGGER) {
            addTrigger(TriggerType.DATA_LAKE);
          } else if (action.id === WorkloadAction.ADD_SCHEDULE_TRIGGER) {
            addTrigger(TriggerType.SCHEDULE);
          } else if (action.id === WorkloadAction.ADD_WORKLOAD_COMPLETE_TRIGGER) {
            addTrigger(TriggerType.WORKLOAD_COMPLETE);
          } else {
            onClickAction(action);
          }
        }}
      />
    );
  };

export const AddTriggerActionsMenu = withAddTriggerActions((props: AddTriggerActionsMenuProps) => {

  const { className, buttonLabel = "Add Trigger" } = props;

  return (
    <ActionsMenu
      {...props}
      className={classnames("addTriggerActionsMenu", className)}
      buttonLabel={buttonLabel}
    />
  );
});

export default AddTriggerActionsMenu;
