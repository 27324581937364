import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import WelcomePageDomain, { Actions, Model } from "../components/WelcomePageDomain";
import {
  getUserFavorites,
  isUserPrincipal
} from "@main/selectors";
import { updateCurrentFavorites } from "@main/actions";

type Props = Model & Actions;

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  isUserPrincipal: isUserPrincipal(state),
  favorites: getUserFavorites(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setFavorites: (value: string[]) => dispatch(updateCurrentFavorites(value)),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(WelcomePageDomain);
