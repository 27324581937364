import React from "react";
import { User } from "@data";
import { useHistory } from "react-router-dom";
import { getPathToUser } from "@modules/userDetails/helpers";
import CreateUser from "./containers/CreateUser";

export function CreateUserView() {

  const history = useHistory();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const showUserDetails = React.useCallback((user: User) => {
    history.replace(getPathToUser(user.getUserId()));
  }, [history]);

  return (
    <CreateUser
      cancel={cancel}
      showUserDetails={showUserDetails}
    />
  );
}

export default CreateUserView;
