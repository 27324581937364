import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () => createStyles({
  label: {
    color: Color.GREY7,
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px",
    fontStyle: "normal",
  },
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
    padding: 8,
    margin: "16px 0px 8px",
    borderRadius: 3,
    border: `1px solid ${Color.RED}`,
    backgroundColor: Color.LIGHT_RED,
    position: "relative",
  },
  icon: {
    width: 28,
    height: 28,
    color: Color.RED,
  },
  closeIconButton: {
    position: "absolute",
    top: 4,
    right: 4,
    padding: 4,
  },
  closeIcon: {
    width: 14,
    height: 14,
    color: Color.RED,
  },
  feedbackLeft: {
    position: "absolute",
    left: 10,
    top: 10,
  },
  feedbackRight: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  feedbackButton: {
    "&:hover": {
      background: Color.LIGHT_GREY5,
    }
  },
  errorHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 5,
    textAlign: "center",
    maxWidth: "calc(100% - 42px)",
  },
  title: {
    color: Color.DARK_RED,
    fontFamily: "Roboto, sans-serif",
    fontSize: 18,
    fontWeight: 400,
    lineHeight: "21px",
  },
  spacer: {
    width: 42,
  },
  message: {
    color: Color.DARK_RED,
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "16px",
    marginTop: 6,
    textAlign: "center",
    maxWidth: "100%",
  },
  link: {
    wordWrap: "break-word",
  },
  newLine: {
    whiteSpace: "pre-line",
    textAlign: "center",
  },
  large: {
    margin: 0,
    padding: 20,
    "& .icon": {
      height: 48,
      width: 48,
    },
    "& .title": {
      fontSize: 24,
      lineHeight: "28px",
    },
  },
  separator: {
    alignSelf: "stretch",
    paddingTop: 8,
    paddingBottom: 8,
    borderBottom: `1px solid ${Color.DARK_RED}`,
  },
  errorsContainer: {
    marginTop: 15,
    display: "flex",
    flexFlow: "column nowrap",
  },
  errorListTitle: {
    color: Color.DARK_RED,
    fontFamily: "Roboto, sans-serif",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "21px",
    margin: 0,
  },
  error: {
    color: Color.DARK_RED,
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    lineHeight: "14px",
    margin: "8px 0",
    padding: 0,
  },
  errorButton: {
    height: "auto",
    width: "auto",
    minWidth: "auto",
    marginTop: 2,
  },
  errorButtonLabel: {
    color: Color.BLACK,
    fontSize: 13,
    fontWeight: 500,
  },
});

export const errorCodeViewStyles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 100,
      marginBottom: 100,
    },
    label: {
      color: Color.GREY5,
      fontFamily: "Roboto, sans-serif",
      fontSize: 36,
      fontWeight: "bold",
      lineHeight: "50px",
      margin: "0 0 20px 0",
      padding: 0,
      display: "flex",
      alignItems: "center",
    },
    subLabel: {
      color: Color.GREY4,
      fontFamily: "Roboto, sans-serif",
      fontSize: 18,
      fontWeight: "bold",
      margin: "0 0 10px 0",
      padding: 0,
      display: "flex",
      alignItems: "center",
    },
    description: {
      color: Color.GREY4,
      fontFamily: "Roboto, sans-serif",
      fontSize: 15,
      fontWeight: 300,
      margin: "0 0 40px 0",
      padding: 0,
    },
    feedbackIcon: {
      marginRight: 10,
    },
    buttonContainer: {
      display: "flex",
      gap: "16px",
      marginBottom: 20,
    },
    button: {
      height: 36,
      minWidth: 100,
      padding: "6px 16px",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "16px",
      textTransform: "none",
    },
    icon: {
      marginRight: "10px",
    }
  });

export default styles;
