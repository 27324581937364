import React from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import withStyles from "@material-ui/core/styles/withStyles";
import { isEmptyString, noop } from "@util/Functions";
import styles from "./styles";

export interface UsePortalSnackbarOptions {
  autoHideDuration?: number;
  preventDuplicate?: boolean;
  errorMessage?: string;
  successMessage?: string;
  onErrorMessageShown?: () => void;
  onSuccessMessageShown?: () => void;
  errorCallbackDelay?: number;
  successCallbackDelay?: number;
}

export const usePortalSnackbar = (snackbarId: string, options: UsePortalSnackbarOptions = {}) => {

  const {
    autoHideDuration = 2000,
    preventDuplicate = true,
    errorMessage = "",
    successMessage = "",
    onErrorMessageShown = noop,
    onSuccessMessageShown = noop,
    errorCallbackDelay = 25,
    successCallbackDelay = 25,
  } = options;

  const { enqueueSnackbar } = useSnackbar();

  const showSnackbar = React.useCallback((message: string, variant: "success" | "error") => {

    enqueueSnackbar(message, {
      variant,
      key: `${snackbarId}-${variant}`,
      autoHideDuration,
      preventDuplicate,
    });

  }, [enqueueSnackbar]);

  const showSuccessSnackbar = React.useCallback((message: string) =>
    showSnackbar(message, "success"),
    [showSnackbar]);

  const showErrorSnackbar = React.useCallback((message: string) =>
    showSnackbar(message, "error"),
    [showSnackbar]);

  React.useEffect(() => {

    if (!isEmptyString(errorMessage)) {
      // Ensure errors only appear while the module is mounted
      // by waiting for ticks to complete before showing the error
      const timer = setTimeout(() => showErrorSnackbar(errorMessage), 0);

      const callbackTimer = setTimeout(onErrorMessageShown, errorCallbackDelay);
      return () => {
        clearTimeout(timer);
        clearTimeout(callbackTimer);
      };
    }

    if (!isEmptyString(successMessage)) {
      // Success messages should appear regardless of whether the module is mounted
      showSuccessSnackbar(successMessage);

      const callbackTimer = setTimeout(onSuccessMessageShown, successCallbackDelay);
      return () => clearTimeout(callbackTimer);
    }

    return () => noop;

  }, [
    errorMessage,
    showErrorSnackbar,
    onErrorMessageShown,
    errorCallbackDelay,
    successMessage,
    showSuccessSnackbar,
    onSuccessMessageShown,
    successCallbackDelay,
  ]);

  return [showSuccessSnackbar, showErrorSnackbar];
};

export const PortalSnackbarProvider = withStyles(styles)(SnackbarProvider);

export default PortalSnackbarProvider;
