import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { logout as performLogout } from "@main/actions";
import { Redirect, useLocation } from "react-router-dom";
import { equalsIgnoreCase, isEmptyString, noop } from "@util";
import { getCurrentAccountId, getCurrentServiceId, getCurrentUserId } from "@main/selectors";

interface Model {
  currentAccountId?: string;
  currentUserId?: string;
  currentServiceId?: string;
}

interface Actions {
  logout?: () => void;
}

type Props = Model & Actions;

const LogoutAndRedirectToLoginPage = (props: Props) => {

  const {
    currentAccountId = "",
    currentUserId = "",
    currentServiceId = "",
    logout = noop,
  } = props;

  const location = useLocation();

  const search = React.useMemo(() => location.search, [location]);

  const pathname = React.useMemo(() => location.pathname, [location]);

  const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);

  const accountId = React.useMemo(() => queryParams.get("accountId") || "", [queryParams]);

  const accountIdMissing = React.useMemo(() => isEmptyString(accountId), [accountId]);

  const userId = React.useMemo(() => queryParams.get("userId") || "", [queryParams]);

  const userIdMissing = React.useMemo(() =>
    isEmptyString(userId) && isEmptyString(currentServiceId),
    [userId, currentServiceId]);

  const validatedQueryParams = React.useMemo(() => {
    const updatedQueryParams = new URLSearchParams(queryParams);
    if (userIdMissing) {
      updatedQueryParams.set("userId", currentUserId);
    }
    if (accountIdMissing) {
      updatedQueryParams.set("accountId", currentAccountId);
    }

    if (!isEmptyString(currentAccountId) && !isEmptyString(currentUserId)) {
      updatedQueryParams.set("autoLogin", "true");
    }

    return updatedQueryParams;
  }, [queryParams, userIdMissing, accountIdMissing, currentUserId, currentAccountId]);

  const redirectRequired = React.useMemo(() =>
    !equalsIgnoreCase(queryParams.toString(), validatedQueryParams.toString()),
    [queryParams, validatedQueryParams]);

  React.useEffect(() => {
    logout();
  }, [logout]);

  if (!redirectRequired) {
    return null;
  }

  return <Redirect to={`${pathname}?${validatedQueryParams}`} />;
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  currentAccountId: getCurrentAccountId(state),
  currentUserId: getCurrentUserId(state),
  currentServiceId: getCurrentServiceId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  logout: () => dispatch(performLogout()),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(LogoutAndRedirectToLoginPage);
