import { DataCatalogClient, GetDataSetsRequestOptions, GetDataSetsResponse } from "@network";
import { useApiRequestAnalytics } from "@hooks";

export type GetDataSetsApi = (options?: GetDataSetsRequestOptions) => Promise<GetDataSetsResponse>;

export interface DataCatalogClientApi {
  getDataSets: GetDataSetsApi;
}

enum ActionType {
  GET_DATA_SETS_REQUEST = "data_catalog_client/get_data_sets_request",
  GET_DATA_SETS_SUCCESS = "data_catalog_client/get_data_sets_success",
  GET_DATA_SETS_FAILED = "data_catalog_client/get_data_sets_failed",
}

export const useGetDataSetsApi = (): GetDataSetsApi =>
  useApiRequestAnalytics(DataCatalogClient.getDataSets, {
    REQUEST_EVENT: ActionType.GET_DATA_SETS_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DATA_SETS_SUCCESS,
    ERROR_EVENT: ActionType.GET_DATA_SETS_FAILED,
  });

export const useDataCatalogClient = (): DataCatalogClientApi => ({
  getDataSets: useGetDataSetsApi(),
});

export { ActionType as DataCatalogClientActionType };

export default useDataCatalogClient;
