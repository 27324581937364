import React from "react";
import classnames from "classnames";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ActionMenuItem, ActionsMenu } from "@components/actions-menu";
import { CheckmarkIcon } from "../icons";
import { requiredIcon, styles, tableBodyCell, tableBodyRow } from "./styles";
import { clickHandler, noop } from "../../util";

const RequiredIcon = withStyles(requiredIcon)(CheckmarkIcon);
const TableBodyRow = withStyles(tableBodyRow)(TableRow);
const TableBodyCell = withStyles(tableBodyCell)(TableCell);

export interface PropertyTableRowData {
  name?: string;
  type?: string;
  description?: string;
  defaultValue?: string;
  required?: boolean;
}

export interface Model extends PropertyTableRowData {
  className?: string;
  selected?: boolean;
  clickable?: boolean;
  actions?: ActionMenuItem[];
}

export interface Actions {
  onClick?: () => void;
  onClickAction?: (action: ActionMenuItem) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const PropertiesTableBodyRow = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    name = "",
    type = "",
    description = "",
    defaultValue = "",
    required = false,
    selected = false,
    clickable = false,
    actions = [],
    onClick,
    onClickAction = noop,
  } = props;

  const showActionsColumn = actions.length > 0;

  return (
    <TableBodyRow
      className={classnames("tableBodyRow", className, {
        [classes.clickable]: clickable,
      })}
      selected={selected}
      hover={clickable}
      onClick={clickHandler(onClick)}
    >
      <TableBodyCell className={classnames("name", classes.nameColumn)}>
        {name}
      </TableBodyCell>
      <TableBodyCell className={classnames("type", classes.typeColumn)}>
        {type}
      </TableBodyCell>
      <TableBodyCell className={classnames("description", classes.descriptionColumn)}>
        {description}
      </TableBodyCell>
      <TableBodyCell className={classnames("defaultValue", classes.defaultValueColumn)}>
        {defaultValue}
      </TableBodyCell>
      <TableBodyCell className={classnames("required", classes.requiredColumn)}>
        {required && (<RequiredIcon />)}
      </TableBodyCell>
      {showActionsColumn && (
        <TableBodyCell className={classnames("actions", classes.actionsColumn)}>
          <ActionsMenu
            buttonVariant="icon"
            actions={actions}
            onClickAction={onClickAction}
          />
        </TableBodyCell>
      )}
    </TableBodyRow>
  );
});

export default PropertiesTableBodyRow;
