import React from "react";
import Typography from "@material-ui/core/Typography";

export const BuildInfoLabel = (props: {
  className?: string,
  name?: string,
  value?: string,
}) => {

  const { className, name, value } = props;

  if (!name || !value) {
    return null;
  }

  return (
    <Typography className={className} variant="body1"><b>{name}:</b> {value}</Typography>
  );
};

export default BuildInfoLabel;
