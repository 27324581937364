import React from "react";
import classnames from "classnames";
import HtmlPreview from "@components/html-preview";
import Typography from "@material-ui/core/Typography";
import { selectEmailTemplate as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import EmailTemplatesList, {
  EmailTemplatesListActions,
  EmailTemplatesListModel,
} from "@components/email-templates-list";

export interface Model extends EmailTemplatesListModel {
  emailTemplateSelected?: boolean;
  htmlBody?: string;
}

export interface Actions extends EmailTemplatesListActions {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const SelectEmailTemplate = withStyles(styles)((props: Props) => {

  const {
    classes,
    noResultsLabel = "There are not any valid email templates in this IoT Account to invite a user with",
    emailTemplateSelected,
    htmlBody,
    children,
    ...otherProps
  } = props;

  const title = React.useMemo(() =>
    emailTemplateSelected
      ? "We will invite this user with the following email template"
      : "Would you like to select a specific e-mail template that should be used to invite this user",
    [emailTemplateSelected]);

  return (
    <React.Fragment>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      <EmailTemplatesList
        {...otherProps}
        className={classnames("emailTemplates", classes.container)}
        selectable={true}
        showSearch={false}
        selectAllDisabled={true}
        noResultsLabel={noResultsLabel}
      />
      {emailTemplateSelected && (
        <HtmlPreview
          className={classnames("htmlPreview", classes.htmlPreview)}
          srcDoc={htmlBody}
        />
      )}
      {children}
    </React.Fragment>
  );
});

export default SelectEmailTemplate;
