import React from "react";
import WelcomePage from "./containers/WelcomePage";

export function WelcomePageView() {

  return (
    <WelcomePage />
  );
}

export default WelcomePageView;
