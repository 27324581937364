import React from "react";
import { useLocation } from "react-router-dom";
import { equalsIgnoreCase, getStringValue } from "@util";
import { DashboardsTabType } from "@modules/dashboards/components/DashboardsTabs";

export interface DashboardsQueryParams {
  selectedTab?: DashboardsTabType | null;
}

export const useDashboardsQueryParams = (): DashboardsQueryParams => {

  const { search } = useLocation();

  const queryParams = new URLSearchParams(getStringValue(search));

  const status = React.useMemo(() => queryParams.get("status") || "", [queryParams]);

  const selectedTab = React.useMemo(() => {
    if (equalsIgnoreCase(status, DashboardsTabType.PUBLISHED)) {
      return DashboardsTabType.PUBLISHED;
    } else if (equalsIgnoreCase(status, DashboardsTabType.UNPUBLISHED)) {
      return DashboardsTabType.UNPUBLISHED;
    } else {
      return null;
    }
  }, [status]);

  return React.useMemo(() => ({
    selectedTab,
  }), [
    selectedTab,
  ]);
};

export default useDashboardsQueryParams;
