import { Module } from "@data";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./EmailTemplateDetailsView";

type RouteParams = { id?: string };

export const emailTemplateDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Email Template Details",
  title: "Email Template Details",
  className: "emailTemplateDetailsModule",
  primaryColor,
  icon,
  view,
});

export default emailTemplateDetailsModule;
