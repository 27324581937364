import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { isEmptyString } from "@util";
import { SecurityGroup } from "@data";
import {
  useSecurityGroupsServicesDoesNotBelongTo,
  useSecurityGroupsUserDoesNotBelongTo,
  useServiceSecurityGroups,
  useUserSecurityGroups,
} from "@components/security-groups-list/useSecurityGroups";
import {
  getGroupName,
  getServiceId,
  getUserId,
  isAddingServiceToGroup,
  isAddingUserToGroup,
  getSecurityGroups,
  isRemovingUserFromGroup,
} from "../selectors";
import { updateSelectedSecurityGroups } from "../actions";
import SelectSecurityGroup, { Actions, Model } from "../components/SelectSecurityGroup";

interface ContainerModel extends Model {
  userId?: string;
  groupName?: string;
  serviceId?: string;
  addUserToGroup?: boolean;
  addServiceToGroup?: boolean;
  removeUserFromGroup?: boolean;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const SelectGroupToAddUserTo = (props: Props) => {

  const { userId = "", ...otherProps } = props;

  const [attachedGroupNames, setAttachedGroupNames] = React.useState<string[]>([]);

  const [ model, actions ] = useSecurityGroupsUserDoesNotBelongTo(userId, attachedGroupNames);

  const { securityGroups: items, excludedGroupNames, ...remainingModel } = model;

  React.useEffect(() => setAttachedGroupNames(excludedGroupNames), [excludedGroupNames, setAttachedGroupNames]);

  // Let security groups list component handle default value if a user has not been selected yet
  const noResultsLabel = React.useMemo(() =>
    isEmptyString(userId) ? undefined : "User already belongs to all available groups", [userId]);

  // Let security groups list component handle default value if a user has not been selected yet
  const loadingLabel = React.useMemo(() =>
    isEmptyString(userId) ? undefined : "Loading groups user can be added to...", [userId]);

  return (
    <SelectSecurityGroup
      {...remainingModel}
      {...actions}
      {...otherProps}
      items={items}
      noResultsLabel={noResultsLabel}
      loadingLabel={loadingLabel}
    />
  );
};

const SelectGroupToAddServiceTo = (props: Props) => {

  const { serviceId = "", ...otherProps } = props;

  const [ model, actions ] = useSecurityGroupsServicesDoesNotBelongTo(serviceId);

  const { securityGroups: items, ...remainingModel } = model;

  // Let security groups list component handle default value if a service has not been selected yet
  const noResultsLabel = React.useMemo(() =>
    isEmptyString(serviceId) ? undefined : "Service already belongs to all available groups", [serviceId]);

  // Let security groups list component handle default value if a service has not been selected yet
  const loadingLabel = React.useMemo(() =>
    isEmptyString(serviceId) ? undefined : "Loading groups service can be added to...", [serviceId]);

  return (
    <SelectSecurityGroup
      {...remainingModel}
      {...actions}
      {...otherProps}
      items={items}
      noResultsLabel={noResultsLabel}
      loadingLabel={loadingLabel}
    />
  );
};

const SelectGroupToRemoveUserFrom = (props: Props) => {

  const { userId = "", ...otherProps } = props;

  const [ model, actions ] = useUserSecurityGroups(userId);

  const { securityGroups: items, ...remainingModel } = model;

  // Let security groups list component handle default value if a user has not been selected yet
  const noResultsLabel = React.useMemo(() =>
    isEmptyString(userId) ? undefined : "User does not belong to any groups", [userId]);

  // Let security groups list component handle default value if a user has not been selected yet
  const loadingLabel = React.useMemo(() =>
    isEmptyString(userId) ? undefined : "Loading groups user belongs to...", [userId]);

  return (
    <SelectSecurityGroup
      {...remainingModel}
      {...actions}
      {...otherProps}
      items={items}
      noResultsLabel={noResultsLabel}
      loadingLabel={loadingLabel}
    />
  );
};

const SelectGroupToRemoveServiceFrom = (props: Props) => {

  const { serviceId = "", ...otherProps } = props;

  const [ model, actions ] = useServiceSecurityGroups(serviceId);

  const { securityGroups: items, ...remainingModel } = model;

  // Let security groups list component handle default value if a service has not been selected yet
  const noResultsLabel = React.useMemo(() =>
    isEmptyString(serviceId) ? undefined : "Service does not belong to any groups", [serviceId]);

  // Let security groups list component handle default value if a service has not been selected yet
  const loadingLabel = React.useMemo(() =>
    isEmptyString(serviceId) ? undefined : "Loading groups Service belongs to...", [serviceId]);

  return (
    <SelectSecurityGroup
      {...remainingModel}
      {...actions}
      {...otherProps}
      items={items}
      noResultsLabel={noResultsLabel}
      loadingLabel={loadingLabel}
    />
  );
};

const SelectSecurityGroupContainer = (props: Props) => {

  const { addUserToGroup, removeUserFromGroup, addServiceToGroup, ...otherProps } = props;

  if (addUserToGroup) {
    return <SelectGroupToAddUserTo {...otherProps} />;
  } else if (removeUserFromGroup) {
    return <SelectGroupToRemoveUserFrom {...otherProps} />;
  } else if (addServiceToGroup) {
    return <SelectGroupToAddServiceTo {...otherProps} />;
  } else {
    return <SelectGroupToRemoveServiceFrom {...otherProps} />;
  }
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  userId: getUserId(state),
  groupName: getGroupName(state),
  serviceId: getServiceId(state),
  selectedItems: getSecurityGroups(state),
  addUserToGroup: isAddingUserToGroup(state),
  removeUserFromGroup: isRemovingUserFromGroup(state),
  addServiceToGroup: isAddingServiceToGroup(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setSelectedItems: (securityGroup: SecurityGroup[] = []) =>
    dispatch(updateSelectedSecurityGroups(securityGroup)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(SelectSecurityGroupContainer);
