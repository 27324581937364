import ModuleIcon from "@material-ui/icons/Create";
import { Theme } from "@material-ui/core/styles/createTheme";
import { createStyles, StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const textField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "14px",
      fontWeight: 300,
      padding: "2px 0",
      margin: 0,
    },
    "& input": {
      fontSize: 14,
      fontWeight: 400,
      padding: "18.5px 14px",
      margin: 0,
    },
  },
};

export const textAreaField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& textarea": {
      fontSize: 14,
      fontWeight: 400,
      padding: 0,
      margin: 0,
    },
  },
};

export const formControlLabel: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "16px",
  },
  label: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "16px",
    marginLeft: 8,
    color: Color.TEXT,
  },
};

export const actionButton: StyleRules = {
  root: {
    height: 36,
    minWidth: 100,
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
  },
  label: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px",
    padding: 0,
    margin: 0,
    textTransform: "none",
  },
  contained: {
    color: Color.TEXT,
    backgroundColor: Color.WHITE,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    "&:active": {
      backgroundColor: "rgba(0, 0, 0, 0.54)",
    },
  },
  containedPrimary: {
    color: Color.WHITE,
    backgroundColor: "#1b578e",
    "&:hover": {
      backgroundColor: "#2d6f9e",
    },
    "&:active": {
      backgroundColor: "#4c79a2",
    },
  },
};

export const saveButton: StyleRules = {
  root: {
    height: 36,
    backgroundColor: "#1b578e",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    "&:hover": {
      backgroundColor: "#2d6f9e",
    },
    "&:active": {
      backgroundColor: "#4c79a2",
    },
  },
  label: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px",
    padding: 0,
    margin: 0,
    color: Color.WHITE,
  },
};

export const dataSetAliasView = (theme: Theme) =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      "&.editDescriptionView .dataSetAlias": {
        marginTop: 0,
      },
    },
    errorViewContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 18,
      lineHeight: "21px",
      marginTop: 8,
    },
    dataSetTypeContainer: {
      marginTop: 40,
      display: "flex",
      flexFlow: "row nowrap",
    },
    formControl: {
      minWidth: 250,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    dataSetAlias: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    descriptionLabel: {
      marginTop: 25,
    },
    description: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    dataSetGroup: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    tagEditor: {
      marginTop: 25,
    },
    alert: {
      marginLeft: 30,
    },
    alertTitle: {
      fontSize: 14,
      marginTop: 2,
    },
    alertDescription: {
      fontSize: 14,
      lineHeight: "21px",
      marginTop: 10,
      marginBottom: 0,
      paddingLeft: 20,
    },
    underlined: {
      marginLeft: 4,
      marginRight: 4,
      textDecoration: "underline",
    },
  });

export const usersView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    usersList: {
      flex: "1 0 auto",
    },
  });

export const dataDescriptionView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    errorViewContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 18,
      lineHeight: "21px",
      marginTop: 8,
    },
    formControl: {
      marginTop: 40,
      minWidth: 250,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    checkboxContainer: {
      marginTop: 40,
      minWidth: 250,
    },
    checkbox: {
      color: primaryColor,
      padding: 0,
    },
    schema: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    sampleAnonymizedData: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    piiContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      width: "100%",
    },
    piiFlag: {
      marginLeft: 0,
      marginTop: 10,
    },
    piiComment: {
      marginTop: 20,
      fontFamily: "Roboto, sans-serif",
    },
    dataRangeContainer: {
      marginTop: 20,
      display: "flex",
      flexFlow: "column nowrap",
    },
    dataRangeTitle: {
      color: Color.TEXT,
      fontWeight: 400,
      fontSize: 21,
    },
    dataRangeSubtitle: {
      marginTop: 8,
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 13,
    },
    dataRangeErrorMessage: {
      marginTop: 15,
      color: Color.RED,
      fontWeight: 400,
      fontSize: 13,
    },
    dataRange: {
      marginTop: 25,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    dataVolume: {
      marginRight: 15,
      fontFamily: "Roboto, sans-serif",
      minWidth: 400,
    },
    dataVelocity: {
      marginLeft: 15,
      fontFamily: "Roboto, sans-serif",
      minWidth: 400,
    },
    lifecycleContainer: {
      marginTop: 25,
      marginBottom: 25,
      display: "flex",
      flexFlow: "column nowrap",
    },
    lifecycleTitle: {
      color: Color.TEXT,
      fontWeight: 400,
      fontSize: 21,
    },
    lifecycleSubtitle: {
      marginTop: 8,
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 13,
      lineHeight: "21px",
    },
    lifecycleErrorMessage: {
      marginTop: 15,
      color: Color.RED,
      fontWeight: 400,
      fontSize: 13,
    },
    lifecycle: {
      marginTop: 20,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    archivingInDays: {
      marginRight: 15,
      flex: "1 0 auto",
      fontFamily: "Roboto, sans-serif",
      minWidth: 400,
    },
    expirationInDays: {
      marginLeft: 15,
      flex: "1 0 auto",
      fontFamily: "Roboto, sans-serif",
      minWidth: 400,
    },
    dataClassification: {
      marginTop: 40,
      display: "flex",
      flexFlow: "column nowrap",
      "& + .piiContainer": {
        marginTop: 20,
      },
    },
    dataClassificationFormControl: {
      minWidth: 400,
    },
    dataClassificationHelperText: {
      fontSize: 12,
      fontWeight: 300,
      lineHeight: "14px",
      margin: "0 14px 0 14px",
    },
    ingestionMechanism: {
      marginTop: 40,
      display: "flex",
      flexFlow: "row nowrap",
    },
    ingestionMechanismFormControlContainer: {
    },
    ingestionMechanismFormControl: {
      minWidth: 400,
    },
    ingestionMechanismHelperText: {
      fontSize: 12,
      fontWeight: 300,
      lineHeight: "14px",
      margin: "0 14px 0 14px",
    },
    ingestionMechanismDescription: {
      marginLeft: 10,
      color: Color.TEXT,
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "25px",
      "& > ul > li": {
        marginTop: 8,
        "&:first-child": {
          marginTop: 0,
        }
      },
    },
    confirmNotProductionFormControl: {
      marginTop: 25,
      marginLeft: 0,
    },
    underlined: {
      marginLeft: 4,
      marginRight: 4,
      textDecoration: "underline",
    },
  });

export const reviewView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      "& label.title": {
        marginBottom: 15,
      },
      "& label.name" : {
        minWidth: 240,
      },
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    propertyContainer: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      justifyContent: "stretch",
      marginTop: 25,
      textAlign: "left",
    },
    propertyName: {
      flex: "0 0 100px",
      alignSelf: "flex-start",
      justifySelf: "flex-start",
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      fontSize: 16,
      minWidth: 240,
    },
    propertyValue: {
      flex: "1 1 auto",
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "monospace",
      fontSize: 14,
      marginLeft: 15,
      fontWeight: 500,
    },
    deviceTypeContainer: {
      flex: "0 0 auto",
    },
    json: {
      backgroundColor: "#eaeaea",
      display: "block",
      whiteSpace: "pre-wrap",
      padding: 10,
      fontSize: 12,
      fontWeight: 300,
    },
  });

export const editIoTApprovedRequest = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    error: {
      flex: "0 0 auto",
      margin: "0 0 8px 0",
    },
    controls: {
      marginTop: 25,
      marginLeft: "auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    cancelButton: {
      marginLeft: 0,
    },
    cancelButtonLabel: {
      color: Color.BLACK,
    },
    saveButton: {
      marginLeft: 16,
    },
  });

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
    },
  });

export default styles;
