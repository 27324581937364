import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import EnrollmentTypeView , { Actions, Model } from "../components/EnrollmentTypeView";
import { DeviceEnrollmentWizardSelectors } from "../selectors";
import { DeviceEnrollmentWizardActions } from "../actions";

interface ContainerModel extends Model {
  hidden?: boolean;
}

type Props = ContainerModel & Actions;

const EnrollmentTypeViewContainer = (props: Props) => {

  const { hidden, ...otherProps } = props;

  if (hidden) {
    return null;
  }

  return <EnrollmentTypeView {...otherProps} />;
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => {

  const hidden = !DeviceEnrollmentWizardSelectors.isEnrollmentTypeViewSelected(state);

  if (!DeviceEnrollmentWizardSelectors.isDevicesJsonValid(state)) {
    return {
      hidden,
      showInvalidJsonError: true,
      ...ownProps
    };
  }

  return {
    hidden,
    enrollmentType: DeviceEnrollmentWizardSelectors.getEnrollmentType(state),
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setVisualEditorType: () => dispatch(DeviceEnrollmentWizardActions.setVisualEditorEnrollmentType()),
  setFileUploadType: () => dispatch(DeviceEnrollmentWizardActions.setFileUploadEnrollmentType()),
});

export default connect<ContainerModel, Actions, ContainerModel & Actions>(
  mapStateToProps,
  mapDispatchToProps
)(EnrollmentTypeViewContainer);
