import React from "react";
import useApiRequest, {
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import { DataSetRequest } from "@data";
import { GetDataSetRequestsResponse } from "@network";
import useDataSetRequestClient from "../use-data-set-request-client";

type SuccessResponse = GetDataSetRequestsResponse;

export interface UseGetDataSetRequestsProps extends Partial<UseApiRequestProps<SuccessResponse>> {
}

export interface UseGetDataSetRequestsModel extends UseApiRequestModel<SuccessResponse> {
  dataSetRequests: DataSetRequest[];
}

export interface UseGetDataSetRequestsActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetDataSetRequestsProps;
type Model = UseGetDataSetRequestsModel;
type Actions = UseGetDataSetRequestsActions;
type Result = [Model, Actions];

export const useGetDataSetRequests = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to get data set requests",
    ...otherProps
  } = props;

  const DataSetRequestClient = useDataSetRequestClient();

  const makeApiRequest = React.useCallback(() =>
      DataSetRequestClient.getDataSetRequests(),
    [DataSetRequestClient]);

  const [{successResponse, ...baseModel}, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const dataSetRequests = React.useMemo(() => {
    const {dataSetRequests: items = []} = successResponse || {};
    return items.map(attrs => new DataSetRequest(attrs));
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successResponse,
    dataSetRequests,
  }), [
    baseModel,
    successResponse,
    dataSetRequests,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetDataSetRequests;
