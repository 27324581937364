import React from "react";
import classnames from "classnames";
import { JsonSchemaProperty } from "@data";
import { ActionMenuItem } from "@components";
import SchemaPropertiesBrowser from "./SchemaPropertiesBrowser";
import { useSchemaLocation } from "@hooks";

export interface Model {
  className?: string;
  title?: string;
  location?: string;
  addButtonLabel?: string;
  noResultsLabel?: string;
  loadingLabel?: string;
  refreshEnabled?: boolean;
  hideLoadingIndicator?: boolean;
  actions?: ActionMenuItem[];
}

export interface Actions {
  onClickBreadcrumbsAction?: (action: ActionMenuItem) => void;
  onClickPropertyAction?: (property: JsonSchemaProperty, action: ActionMenuItem) => void;
}

export const SchemaProperties = (props: Model & Actions) => {

  const {
    className,
    title = "Schema Properties",
    location = "",
    actions: actionMenuItems,
    addButtonLabel,
    noResultsLabel,
    loadingLabel,
    refreshEnabled,
    hideLoadingIndicator,
    onClickBreadcrumbsAction,
    onClickPropertyAction,
  } = props;

  if (location.length === 0) {
    return null;
  }

  const [model, actions] = useSchemaLocation(location);

  const {
    breadcrumbs,
    schema,
    metadata,
    properties,
    errorMessage,
    showAccessDenied,
    showNotFoundError,
    showLoadingIndicator,
    showChildProperties,
  } = model;

  const { refresh, setBreadcrumbs } = actions;

  const [nestedSchemaRef, setNestedSchemaRef] = React.useState("");

  const updateBreadcrumbs = React.useCallback((updatedBreadcrumbs: string[]) => {

    setBreadcrumbs(updatedBreadcrumbs);

    setNestedSchemaRef("");

  }, [setBreadcrumbs, setNestedSchemaRef]);

  const clearBreadcrumbs = () => updateBreadcrumbs([]);

  const showSchemaRefProperty = React.useCallback((property: JsonSchemaProperty) => {

    if (property.isReference() && property.hasReference()) {

      const reference = property.reference;

      const propertyName = property.getName();

      const updatedBreadcrumbs = breadcrumbs.concat([propertyName]);

      updateBreadcrumbs(updatedBreadcrumbs);

      setNestedSchemaRef(reference);
    }

  }, [breadcrumbs, updateBreadcrumbs, setNestedSchemaRef]);

  const showParentProperty = React.useCallback(() => {

    if (showChildProperties) {

      const updatedBreadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1);

      updateBreadcrumbs(updatedBreadcrumbs);
    }
  }, [showChildProperties, breadcrumbs, updateBreadcrumbs]);

  const showObjectProperty = React.useCallback((property: JsonSchemaProperty) => {

    const propertyName = property.getName();

    const updatedBreadcrumbs = breadcrumbs.concat([propertyName]);

    updateBreadcrumbs(updatedBreadcrumbs);

  }, [breadcrumbs, updateBreadcrumbs]);

  return (
    <SchemaPropertiesBrowser
      className={classnames("schemaProperties", className)}
      title={title}
      addButtonLabel={addButtonLabel}
      noResultsLabel={noResultsLabel}
      loadingLabel={loadingLabel}
      refreshEnabled={refreshEnabled}
      actions={actionMenuItems}
      breadcrumbs={breadcrumbs}
      schema={schema}
      metadata={metadata}
      properties={properties}
      nestedSchemaRef={nestedSchemaRef}
      errorMessage={errorMessage}
      showAccessDenied={showAccessDenied}
      showNotFoundError={showNotFoundError}
      showLoadingIndicator={showLoadingIndicator && !hideLoadingIndicator}
      clearBreadcrumbs={clearBreadcrumbs}
      setBreadcrumbs={updateBreadcrumbs}
      showParentProperty={showParentProperty}
      showObjectProperty={showObjectProperty}
      showSchemaRefProperty={showSchemaRefProperty}
      onClickBreadcrumbsAction={onClickBreadcrumbsAction}
      onClickPropertyAction={onClickPropertyAction}
      refresh={() => {
        clearBreadcrumbs();
        refresh();
      }}
    />
  );
};

export default SchemaProperties;
