import React from "react";
import classnames from "classnames";
import { isEmptyString } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import ActionsMenu, { ActionMenuItem } from "@components/actions-menu";
import { TooltipIcon } from "../tooltip/TooltipIcon";
import { Vector } from "@data/Vector";
import EditIcon from "@material-ui/icons/Edit";

export interface SummaryViewLabelProps {
  className?: string;
  name: string;
  value?: string;
  nameClassName?: string;
  valueClassName?: string;
  actionsClassName?: string;
  showActionsButton?: boolean;
  showEditIcon?: boolean;
  editIconClassName?: string;
  editTooltipText?: string;
  tooltipIconText?: string;
  tooltipIconClassName?: string;
  showTooltipIcon?: boolean;
  onClickEditIcon?: () => void;
  actions?: ActionMenuItem[];
  onClickAction?: (action: ActionMenuItem) => void;
}

type Props = WithStyles<typeof styles> & SummaryViewLabelProps;

export const SummaryViewLabel = withStyles(styles)((props: Props) => {

  const {
    classes,
    className = "",
    nameClassName,
    valueClassName,
    actionsClassName,
    editIconClassName,
    editTooltipText = "Update",
    name,
    value,
    showActionsButton = false,
    showEditIcon = false,
    tooltipIconText,
    tooltipIconClassName,
    showTooltipIcon = !isEmptyString(tooltipIconText),
    actions = [],
    onClickAction,
    onClickEditIcon,
  } = props;

  if (isEmptyString(value)) {
    return null;
  }

  return (
    <div className={classnames(className, classes[className], classes.container)}>
      <label className={classnames("name", nameClassName, classes.name)}>{name}:</label>
      <label className={classnames("value", valueClassName, classes.value)}>{value}</label>
      {showActionsButton && (
        <ActionsMenu
          className={classnames("actions", actionsClassName, classes.actions)}
          buttonVariant="icon"
          actions={actions}
          onClickAction={onClickAction}
        />
      )}
      {showEditIcon && (
        <TooltipIcon
          className={classnames("editIcon", editIconClassName, classes.editIcon)}
          tooltipText={editTooltipText}
          size={Vector.square(20)}
          icon={EditIcon}
          onClick={onClickEditIcon}
        />
      )}
      {showTooltipIcon && (
        <TooltipIcon
          className={classnames("tooltipIcon", tooltipIconClassName, classes.tooltipIcon)}
          size={Vector.square(40)}
          tooltipText={tooltipIconText}
        />
      )}
    </div>
  );
});

export default SummaryViewLabel;
