import { isEmptyString } from "@util";
import {
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments,
} from "./helpers";
import { ProfileAttributeDefinitionAttributes } from "@data";

const PROFILE_ATTRIBUTE_URL =  process.env.REACT_APP_PROFILE_ATTRIBUTE_API || "";

if (isEmptyString(PROFILE_ATTRIBUTE_URL)) {
  throw new Error("Missing Environment Variable: PROFILE_ATTRIBUTE_URL");
}

export interface GetProfileAttributeDefinitionResponse  {
  attributes: ProfileAttributeDefinitionAttributes[];
}

export const getProfileAttributeDefinition = (authToken: string, accountId: string= ""):
  Promise<GetProfileAttributeDefinitionResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["AccountId", accountId],
    ]));

  const makeRequest = () => {

    const url = `${PROFILE_ATTRIBUTE_URL}/v1/idm/user-idm/attributes/accounts/${accountId}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to get profile attribute definition`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage)
      .then((response: { attributes: ProfileAttributeDefinitionAttributes[] | null }) => {

        const {attributes = []} = response;

        return {
          attributes: attributes || [],
        };
      });
  };

  return validate().then(makeRequest);
};

export const editProfileAttributeDefinition = (authToken: string,
                                               accountId: string,
                                               json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Account Id", accountId,
        "Profile Attribute Definition", json ],
    ]));

  const makeRequest = () => {

    const url = `${PROFILE_ATTRIBUTE_URL}/v1/idm/user-idm/attributes/accounts/${accountId}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to edit profile attribute definition";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
