import React from "react";
import classnames from "classnames";
import { isEmptyString } from "@util";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DataLakeFileBrowser, { DataLakeFileBrowserModel, DataLakeFileBrowserActions } from "@components/data-lake-file-browser";
import styles from "./styles";

export interface FileBrowserViewModel extends DataLakeFileBrowserModel {
  className?: string;
  showLoadingIndicator?: boolean;
  showEmptyView?: boolean;
}

export interface FileBrowserViewActions extends DataLakeFileBrowserActions {
}

type Model = FileBrowserViewModel;
type Actions = FileBrowserViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const FileBrowserView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    dataSetAlias = "",
    showLoadingIndicator,
    showEmptyView,
    children,
    ...otherProps
  } = props;

  if (isEmptyString(dataSetAlias)) {
    return (
      <div className={classnames("fileBrowserView", "emptyView", className, classes.emptyView)}>
        <div className={classnames("emptyViewHeader", classes.emptyViewHeader)}>
          <Typography className={classnames("emptyViewTitle", classes.emptyViewTitle)}>
            File Browser
          </Typography>
        </div>
        <div className={classnames("emptyViewContent", classes.emptyViewContent)}>
          {showLoadingIndicator && (
            <Typography className={classnames("emptyViewTitle", classes.emptyViewTitle)}>
              Loading data sets that you have access to...
            </Typography>
          )}
          {!showLoadingIndicator && (
            <React.Fragment>
              {showEmptyView && (
                <React.Fragment>
                  <Typography className={classnames("emptyViewTitle", classes.emptyViewTitle)}>
                    No Data Sets Found
                  </Typography>
                  <Typography className={classnames("emptyViewSubtitle", classes.emptyViewSubtitle)}>
                    You do not have access to any data sets
                  </Typography>
                </React.Fragment>
              )}
              {!showEmptyView && (
                <React.Fragment>
                  <Typography className={classnames("emptyViewTitle", classes.emptyViewTitle)}>
                    Please Select a Data Set
                  </Typography>
                  <Typography className={classnames("emptyViewSubtitle", classes.emptyViewSubtitle)}>
                    Select a data set from the left-hand side to explore the data lake files for that data set
                  </Typography>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  return (
    <DataLakeFileBrowser
      {...otherProps}
      className={classnames("fileBrowserView", className, classes.container)}
      headerClassName={classnames("fileBrowserViewHeader", classes.header)}
      contentClassName={classnames("fileBrowserViewContent", classes.content)}
      listClassName={classnames("fileBrowserViewList", classes.list)}
      dataSetAlias={dataSetAlias}
    >
      {children}
    </DataLakeFileBrowser>
  );
});

export default FileBrowserView;
