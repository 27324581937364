import { createStyles } from "@material-ui/core/styles";

export const styles = () =>
  createStyles({
    container: {
    },
    pdfViewer: {
    },
  });

export default styles;
