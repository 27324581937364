import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import {
  Actions,
  DeviceConfigurationSummaryView,
  Model
} from "../components/DeviceConfigurationSummaryView";
import { DeviceTypeList } from "../components/DeviceTypeList";
import DeviceDataView from "./DeviceDataView";
import DeleteDeviceDataDialog from "./DeleteDeviceDataDialog";
import {
  getConfigRefresh,
  getDeviceDataViewMode,
  getDeviceId,
  getDeviceScopeMode,
  getDeviceTypeIdentity,
  isDeleteDataDialogOpen,
  isDeviceScopeViewActive,
  isLoadingIndicator,
  isRegionScopeViewActive,
  isTypeScopeViewActive,
} from "../selectors";
import {
  closeDeleteDataDialog,
  setConfigRefresh,
  setDeviceDataEditMode,
  showActualView,
  showDesiredView,
  showDeviceScopeView,
  showMetadataView,
  showRegionScopeView,
  showTypeScopeView
} from "../actions";
import {
  usePortalSnackbar,
  DeviceDataType
} from "@components";
import {
  useDeleteDeviceDataBySchema,
  useDeviceConfigDetails,
  useDeviceDataBySchemaService,
  useDeviceType,
  useEditDeviceDataBySchema,
} from "@hooks";
import { DeviceDataScope, DeviceTypeListItem, JsonSchemaMetadata } from "@data";
import { isEmptyString, noop } from "@util";

interface ContainerModel extends Model {
  deviceId?: string;
  deviceTypeIdentity?: string;
  deleteDataDialog?: boolean;
  deviceDataType?: DeviceDataType;
  loadingIndicator?: boolean;
  snackbarId?: string;
  refresh?: boolean;
  deviceScopeActive?: boolean;
  typeScopeActive?: boolean;
  regionScopeActive?: boolean;
  deviceDataScope?: DeviceDataScope;
}

interface ContainerActions extends Actions {
  showDeviceTypeDetailsInNewTab?: (deviceType: DeviceTypeListItem) => void;
  showSchemaDetailsInNewTab?: (schema: JsonSchemaMetadata) => void;
  setEditMode?: (value: boolean) => void;
  setRefresh?: (value: boolean) => void;
  setActualView?: () => void;
  setDesiredView?: () => void;
  setMetadataView?: () => void;
  closeDialog?: () => void;
  showDeviceView?: () => void;
  showTypeView?: () => void;
  showRegionView?: () => void;
}

type Props = ContainerModel & ContainerActions;

const DeviceConfigurationSummaryViewContainer = (props: Props) => {

  const {
    snackbarId = "device-configuration",
    deleteDataDialog,
    deviceId = "",
    deviceTypeIdentity = "",
    deviceDataType = DeviceDataType.ACTUAL,
    deviceDataScope = DeviceDataScope.DEVICE,
    loadingIndicator,
    refresh = false,
    deviceScopeActive = true,
    typeScopeActive,
    regionScopeActive,
    showDeviceTypeDetailsInNewTab = noop,
    showSchemaDetailsInNewTab = noop,
    setEditMode = noop,
    setRefresh = noop,
    setActualView = noop,
    setDesiredView = noop,
    setMetadataView = noop,
    closeDialog = noop,
    showDeviceView = noop,
    showTypeView = noop,
    showRegionView = noop,
    ...otherProps
  } = props;

  const namespace = deviceTypeIdentity.split(":")[0] || "";
  const name = deviceTypeIdentity.split(":")[1] || "";
  const version = deviceTypeIdentity.split(":")[2] || "";

  const [schemaIdentity, setSchemaIdentity] = React.useState("");
  const [json, setJson] = React.useState("");
  const [configError, setConfigError] = React.useState("");
  const [dataError, setDataError] = React.useState("");
  const [editError, setEditError] = React.useState("");
  const [deleteError, setDeleteError] = React.useState("");
  const [deviceTypeError, setDeviceTypeError] = React.useState("");
  const [editSuccess, setEditSuccess] = React.useState("");
  const [deleteSuccess, setDeleteSuccess] = React.useState("");
  const [jsonObject, setJsonObject] = React.useState<any>();
  const [inValidJsonError, setInValidJsonError] = React.useState(false);
  const [isReadWriteSelected, setIsReadWriteSelected] = React.useState(true);
  const [showMetadataInfoBanner, setShowMetadataInfoBanner] = React.useState(false);

  const [ deviceDataModel, deviceDataActions] = useDeviceDataBySchemaService({
    deviceId,
    schemaIdentity,
    deviceDataType,
    typeIdentity: deviceTypeIdentity,
    deviceDataScope,
  });

  const [deviceConfigModel, deviceConfigActions] = useDeviceConfigDetails({deviceId});

  const {
    deviceConfig,
    errorMessage: configurationErrorMessage = "",
  } = deviceConfigModel;

  const { refresh: refreshConfiguration } = deviceConfigActions;

  const clearErrors = React.useCallback(() => {
    setConfigError("");
    setDataError("");
    setEditError("");
    setDeleteError("");
    setShowMetadataInfoBanner(false);
  }, [
    setConfigError,
    setDataError,
    setEditError,
    setDeleteError,
    setShowMetadataInfoBanner
  ]);

  const clearSuccessMessages = React.useCallback(() => {
    setEditSuccess("");
    setDeleteSuccess("");
  }, [
    setEditSuccess,
    setDeleteSuccess,
  ]);

  const reset = React.useCallback(() => {
    setJson("{}");
    setEditMode(false);
    setSchemaIdentity("");
    setInValidJsonError(false);
    setRefresh(false);
    closeDialog();
    clearErrors();
    clearSuccessMessages();
  }, [
    setJson,
    setEditMode,
    setSchemaIdentity,
    setInValidJsonError,
    setRefresh,
    closeDialog,
    clearErrors,
    clearSuccessMessages,
  ]);

  const refreshPage = React.useCallback(() => {
    reset();
    refreshConfiguration();
  }, [reset, refreshConfiguration]);

  const [ editDataModel, editDataActions] = useEditDeviceDataBySchema({
    deviceId,
    schemaIdentity,
    jsonObject,
    deviceDataType,
    refreshPage,
    typeIdentity: deviceTypeIdentity,
    deviceDataScope,
  });

  const [ deleteDataModel, deleteDataActions] = useDeleteDeviceDataBySchema({
    deviceId,
    schemaIdentity,
    deviceDataType,
    refreshPage,
    typeIdentity: deviceTypeIdentity,
    deviceDataScope,
  });

  const [ deviceTypeModel, deviceTypeAction ] = useDeviceType(namespace, name, version);

  const {
    deviceData,
    errorMessage: deviceDataErrorMessage = "",
    showNotFound,
    showLoadingIndicator: deviceDataLoadingIndicator,
  } = deviceDataModel;

  const {
    successMessage: editDataSuccessMessage,
    errorMessage: editDataErrorMessage = "",
    showLoadingIndicator: editDataLoadingIndicator
  } = editDataModel;

  const {
    successMessage: deleteDataSuccessMessage,
    errorMessage: deleteDataErrorMessage = "",
    showLoadingIndicator: deleteDataLoadingIndicator
  } = deleteDataModel;

  const {
    deviceType,
    errorMessage: deviceTypeErrorMessage,
  } = deviceTypeModel;

  const { getDeviceData } = deviceDataActions;

  const { editDeviceData } = editDataActions;

  const { deleteDeviceData } = deleteDataActions;

  const {
    refresh: deviceTypeRefresh,
  } = deviceTypeAction;

  const typeIdentity = React.useMemo(() => deviceConfig.getTypeIdentity(), [deviceConfig]);

  const actualSchemas = React.useMemo(() =>
    deviceConfig.getActualSchemaData().map(schemaAttributes => schemaAttributes.schemaIdentity), [deviceConfig]);

  const desiredDeviceScopeData = React.useMemo(() =>
    deviceConfig.getDesiredSchemaData().filter(schemaAttributes => schemaAttributes.scope === "device"),
    [deviceConfig]);
  const desiredTypeScopeData = React.useMemo(() =>
      deviceConfig.getDesiredSchemaData().filter(schemaAttributes => schemaAttributes.scope === "type"),
    [deviceConfig]);
  const desiredRegionScopeData = React.useMemo(() =>
      deviceConfig.getDesiredSchemaData().filter(schemaAttributes => schemaAttributes.scope === "region"),
    [deviceConfig]);

  const desiredDeviceScopeSchemas = React.useMemo(() =>
    desiredDeviceScopeData.map(attrs => attrs.schemaIdentity), [desiredDeviceScopeData]);
  const desiredTypeScopeSchemas = React.useMemo(() =>
    desiredTypeScopeData.map(attrs => attrs.schemaIdentity), [desiredTypeScopeData]);
  const desiredRegionScopeSchemas = React.useMemo(() =>
    desiredRegionScopeData.map(attrs => attrs.schemaIdentity), [desiredRegionScopeData]);

  const desiredSchemas = React.useMemo(() => {
    if (deviceScopeActive) {
      return desiredDeviceScopeSchemas;
    } else if (typeScopeActive) {
      return desiredTypeScopeSchemas;
    } else {
      return desiredRegionScopeSchemas;
    }
  }, [
    deviceScopeActive,
    desiredDeviceScopeSchemas,
    typeScopeActive,
    desiredTypeScopeSchemas,
    desiredRegionScopeSchemas
  ]);

  const metadata = React.useMemo(() =>
    deviceType.getMetadata(), [deviceType]);
  const metadataSchemas = React.useMemo(() =>
    deviceType.getMetadataSchemas(), [deviceType]);

  const schemaIdentities = React.useMemo(() => {
    if (deviceDataType === DeviceDataType.ACTUAL) {
      return actualSchemas;
    } else if (deviceDataType === DeviceDataType.DESIRED) {
      return desiredSchemas;
    } else {
      return metadataSchemas;
    }
  }, [deviceDataType, desiredSchemas, actualSchemas, metadataSchemas]);

  const onChangeIsReadWriteSelected = React.useCallback(event => {
    if (event && event.target && event.target.value) {
      setJson("");
      clearErrors();
      clearSuccessMessages();
      setSchemaIdentity("");
      setEditMode(false);
      setIsReadWriteSelected(event.target.value === "yes");
    }
  }, [setIsReadWriteSelected, setJson, clearErrors, clearSuccessMessages, setEditMode]);

  const onChangeScope = React.useCallback(event => {
    if (event && event.target && event.target.value) {
      setJson("");
      clearErrors();
      clearSuccessMessages();
      setSchemaIdentity("");
      setEditMode(false);
      if (event.target.value === "device") {
        showDeviceView();
      } else if (event.target.value === "type") {
        showTypeView();
      } else {
        showRegionView();
      }
    }
  }, [
    setIsReadWriteSelected,
    setJson,
    clearErrors,
    clearSuccessMessages,
    setEditMode,
    showDeviceView,
    showTypeView,
    showRegionView
  ]);

  const onDeleteConfirm = React.useCallback(() => {
    clearErrors();
    clearSuccessMessages();
    deleteDeviceData();
    closeDialog();
  }, [deleteDeviceData, closeDialog, clearErrors, clearSuccessMessages]);

  const onClickSchema = React.useCallback((schema: JsonSchemaMetadata) => {
    setJson("");
    clearErrors();
    clearSuccessMessages();
    setEditMode(false);
    setSchemaIdentity(schema.getId());
    return getDeviceData();
  }, [setJson, setSchemaIdentity, getDeviceData, setEditMode, clearErrors, clearSuccessMessages]);

  const updateJson = React.useCallback((value: string) => {
    try {
      setJson(value);
      clearErrors();
      setInValidJsonError(false);
      return setJsonObject(JSON.parse(value));
    } catch (e) {
      return setInValidJsonError(true);
    }
  }, [setJson, setInValidJsonError, setJsonObject, clearErrors]);

  const cancelEdit = React.useCallback(() => {
    setEditMode(false);
    setInValidJsonError(false);
    setJson(JSON.stringify(deviceData, null, "  "));
    clearErrors();
    clearSuccessMessages();
  }, [setEditMode, setJson, deviceData, setInValidJsonError, clearErrors, clearSuccessMessages]);

  const confirmEdit = React.useCallback(() => {
    clearErrors();
    clearSuccessMessages();
    editDeviceData();
  }, [editDeviceData, clearErrors, clearSuccessMessages]);

  const setActualDataView = React.useCallback(() => {
    reset();
    setActualView();
  }, [reset, setActualView]);

  const setDesiredDataView = React.useCallback(() => {
    reset();
    setDesiredView();
  }, [reset, setDesiredView]);

  const setMetaDataView = React.useCallback(() => {
    reset();
    setMetadataView();
  }, [reset, setMetadataView]);

  const onDeleteSuccess = React.useCallback(() => {
    reset();
  }, [reset]);

  const backdrop = React.useMemo(() =>
      editDataLoadingIndicator || deleteDataLoadingIndicator
    , [
      editDataLoadingIndicator,
      deleteDataLoadingIndicator
    ]);

  const successMessage = React.useMemo(() => {
    if (!isEmptyString(editSuccess)) {
      return editSuccess;
    } else if (!isEmptyString(deleteSuccess)) {
      return deleteSuccess;
    } else {
      return "";
    }
  }, [editSuccess, deleteDataSuccessMessage]);

  const errorMessage = React.useMemo(() => {
    if (!isEmptyString(configError)) {
      return configError;
    } else if (!isEmptyString(dataError)) {
      return dataError;
    } else if (!isEmptyString(editError)) {
      return editError;
    } else if (!isEmptyString(deleteError)) {
      return deleteError;
    } else if (!isEmptyString(deviceTypeError)) {
      return deviceTypeError;
    } else {
      return "";
    }
  }, [configError, dataError, editError, deleteError, deviceTypeError]);

  usePortalSnackbar(snackbarId, {
    errorMessage,
    successMessage,
    successCallbackDelay: 750,
  });

  React.useEffect(() => {
    setJson(JSON.stringify(deviceData, null, "  "));
  }, [
    deviceData,
  ]);

  React.useEffect(() => {
    reset();
    refreshConfiguration();
    deviceTypeRefresh();
  }, [
    refresh,
  ]);

  React.useEffect(() => {
    setConfigError(configurationErrorMessage);
  }, [configurationErrorMessage]);

  React.useEffect(() => {
    setEditError(editDataErrorMessage);
  }, [editDataErrorMessage]);

  React.useEffect(() => {
    if (showNotFound && deviceDataType === DeviceDataType.METADATA) {
      setShowMetadataInfoBanner(true);
      setDataError("");
    } else {
      setDataError(deviceDataErrorMessage);
    }
  }, [deviceDataErrorMessage]);

  React.useEffect(() => {
    setDeleteError(deleteDataErrorMessage);
  }, [deleteDataErrorMessage]);

  React.useEffect(() => {
    setDeviceTypeError(deviceTypeErrorMessage);
  }, [deviceTypeErrorMessage]);

  React.useEffect(() => {
    setEditSuccess(editDataSuccessMessage);
  }, [editDataSuccessMessage]);

  React.useEffect(() => {
    setDeleteSuccess(deleteDataSuccessMessage);
  }, [deleteDataSuccessMessage]);

  return (
    <DeviceConfigurationSummaryView
      {...otherProps}
      loadingIndicator={loadingIndicator}
      backdrop={backdrop}
      errorMessage={errorMessage}
    >
      {deleteDataDialog && (
        <DeleteDeviceDataDialog
          schemaIdentity={schemaIdentity}
          deleteDeviceData={onDeleteConfirm}
          onDeleteSuccess={onDeleteSuccess}
        />
        )}
      <DeviceTypeList
        deviceTypeIdentities={[typeIdentity]}
        showDeviceTypeDetailsInNewTab={showDeviceTypeDetailsInNewTab}
      />
      <DeviceDataView
        selectedSchema={schemaIdentity}
        schemaIdentities={schemaIdentities}
        metadata={metadata}
        isReadWriteSelected={isReadWriteSelected}
        json={json}
        showLoadingIndicator={deviceDataLoadingIndicator}
        invalidJsonError={inValidJsonError}
        metadataInfoBanner={showMetadataInfoBanner}
        showSchemaDetailsInNewTab={showSchemaDetailsInNewTab}
        onClickSchema={onClickSchema}
        setJson={updateJson}
        cancelEdit={cancelEdit}
        confirmEdit={confirmEdit}
        setActualDataView={setActualDataView}
        setDesiredDataView={setDesiredDataView}
        setMetadataView={setMetaDataView}
        onChangeIsReadWriteSelected={onChangeIsReadWriteSelected}
        onChangeScope={onChangeScope}
      />
    </DeviceConfigurationSummaryView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  deleteDataDialog: isDeleteDataDialogOpen(state),
  deviceId: getDeviceId(state),
  deviceDataType: getDeviceDataViewMode(state),
  loadingIndicator: isLoadingIndicator(state),
  refresh: getConfigRefresh(state),
  deviceDataScope: getDeviceScopeMode(state),
  deviceScopeActive: isDeviceScopeViewActive(state),
  typeScopeActive: isTypeScopeViewActive(state),
  regionScopeActive: isRegionScopeViewActive(state),
  deviceTypeIdentity: getDeviceTypeIdentity(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setEditMode: (value: boolean) => dispatch(setDeviceDataEditMode(value)),
  setRefresh: (value: boolean) => dispatch(setConfigRefresh(value)),
  setActualView: () => dispatch(showActualView()),
  setDesiredView: () => dispatch(showDesiredView()),
  setMetadataView: () => dispatch(showMetadataView()),
  closeDialog: () => dispatch(closeDeleteDataDialog()),
  showDeviceView: () => dispatch(showDeviceScopeView()),
  showTypeView: () => dispatch(showTypeScopeView()),
  showRegionView: () => dispatch(showRegionScopeView()),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceConfigurationSummaryViewContainer);
