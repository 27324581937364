import { Record } from "immutable";

export interface LockUserRequestAttributes {
  userLocked: boolean;
}

export class LockUserRequest extends Record({
  userLocked: false,
}) implements LockUserRequestAttributes {

  public static EMPTY: LockUserRequest = new LockUserRequest();

  public readonly userLocked: boolean;
}
