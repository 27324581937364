import createTheme from "@material-ui/core/styles/createTheme";
import { themeOptions } from "@main/components/theme";
import { baseThemeOptions } from "@components";
import Color from "@Color";

const { palette: basePalette = {} } = themeOptions;

const {
  primary: basePrimaryColors = {},
  secondary: baseSecondaryColors = {},
  text: baseTextColors = {},
} = basePalette;

export const theme = createTheme({
  ...themeOptions,
  palette: {
    ...basePalette,
    type: "light",
    primary: {
      ...basePrimaryColors,
      main: Color.SIGNIFY_GREEN,
      light: Color.BLUE_GREY,
      dark: Color.TEAL,
      contrastText: Color.TEXT,
    },
    secondary: {
      ...baseSecondaryColors,
      main: Color.DARK_BLUE,
      light: Color.BLUE_GREY,
      dark: Color.DARK_BLUE_GREEN,
      contrastText: Color.WHITE,
    },
    text: {
      ...baseTextColors,
      primary: Color.TEXT,
      secondary: Color.TEXT,
      disabled: Color.GREY3,
      hint: Color.SIGNIFY_GREEN,
    },
  },
});

export const usersListTheme = createTheme({
  ...baseThemeOptions,
  palette: {
    ...basePalette,
    type: "light",
    primary: {
      ...basePrimaryColors,
      main: Color.MODULES,
      light: Color.BLUE_GREY,
      dark: Color.BLUE,
      contrastText: Color.TEXT,
    },
    text: {
      ...baseTextColors,
      primary: Color.MODULES,
      secondary: Color.LIST_ITEMS,
      disabled: Color.GREY3,
      hint: Color.GREY3
    },
  },
});

export default theme;
