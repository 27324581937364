import { createStyles } from "@material-ui/core/styles";

export const styles = () =>
  createStyles({
    container: {
    },
    summaryView: {
      marginTop: 0,
    },
    jsonView: {
      marginTop: 0,
      minHeight: "50vh"
    },
  });

export default styles;
