import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { useSecurityGroups } from "@hooks";
import { setSearchQuery } from "../actions";
import { getSearchQuery } from "../selectors";
import SecurityGroups, { Actions, Model } from "../components/SecurityGroups";

interface ContainerModel extends Model {
  isDeleteSuccess?: boolean;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const SecurityGroupsContainer = (props: Props) => {

  const { isDeleteSuccess, nameFilter, ...otherProps } = props;

  const [ model, actions ] = useSecurityGroups({groupName: nameFilter});

  const { securityGroups } = model;

  const { refresh } = actions;

  React.useEffect(() => {
    refresh();
  }, [nameFilter, refresh]);

  return (
    <SecurityGroups
      {...model}
      {...actions}
      {...otherProps}
      items={securityGroups}
      nameFilter={nameFilter}
      onDeleteSuccess={refresh}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  nameFilter: getSearchQuery(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setNameFilter: (searchQuery: string) => dispatch(setSearchQuery(searchQuery)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(SecurityGroupsContainer);
