import { StyleRules } from "@material-ui/core/styles";
import Color from "@components/styles/Color";

export const styles: StyleRules<string> = {
  variantSuccess: {
    color: Color.BLACK,
    backgroundColor: Color.SIGNIFY_GREEN,
  },
};

export default styles;
