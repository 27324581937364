import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import ActionsMenu, { ActionMenuItem, ActionsMenuProps } from "../actions-menu";

export enum DataSetRequestAction {
  NONE = "",
  CLONE = "clone_data_set_request",
  EDIT = "edit_data_set_request",
  UPLOAD_TERMS_AND_CONDITIONS = "upload_terms_and_conditions",
  DELETE = "delete_data_set_request",
}

export const CLONE_DATA_SET_REQUEST_ACTION: ActionMenuItem = Object.freeze({
  id: DataSetRequestAction.CLONE,
  name: "Clone Data Set Request",
  disabled: false,
  hidden: false,
});

export const EDIT_DATA_SET_REQUEST_ACTION: ActionMenuItem = Object.freeze({
  id: DataSetRequestAction.EDIT,
  name: "Edit Description",
  disabled: false,
  hidden: false,
});

export const UPLOAD_TERMS_AND_CONDITIONS_ACTION: ActionMenuItem = Object.freeze({
  id: DataSetRequestAction.UPLOAD_TERMS_AND_CONDITIONS,
  name: "Upload Privacy Terms and Conditions",
  disabled: false,
  hidden: true,
});

export const DELETE_DATA_SET_REQUEST_ACTION: ActionMenuItem = Object.freeze({
  id: DataSetRequestAction.DELETE,
  name: "Cancel Request",
  disabled: false,
  hidden: false,
});

export const DEFAULT_DATA_SET_REQUEST_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  CLONE_DATA_SET_REQUEST_ACTION,
  EDIT_DATA_SET_REQUEST_ACTION,
  UPLOAD_TERMS_AND_CONDITIONS_ACTION,
  DELETE_DATA_SET_REQUEST_ACTION,
]) as ActionMenuItem[];

export interface DataSetRequestActionHandlers {
  cloneDataSetRequest?: () => void;
  editDataSetRequest?: () => void;
  uploadTermsAndConditions?: () => void;
  deleteDataSetRequest?: () => void;
}

export const DataSetRequestActionsMenu = (props: DataSetRequestActionHandlers & ActionsMenuProps) => {

  const {
    className,
    buttonLabel = "Data Set Request Actions",
    actions = DEFAULT_DATA_SET_REQUEST_ACTION_MENU_ITEMS,
    onClickAction = noop,
    cloneDataSetRequest = noop,
    editDataSetRequest = noop,
    uploadTermsAndConditions = noop,
    deleteDataSetRequest = noop,
    ...otherProps
  } = props;

  const actionClicked = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case DataSetRequestAction.CLONE:
        return cloneDataSetRequest();
      case DataSetRequestAction.EDIT:
        return editDataSetRequest();
      case DataSetRequestAction.UPLOAD_TERMS_AND_CONDITIONS:
        return uploadTermsAndConditions();
      case DataSetRequestAction.DELETE:
        return deleteDataSetRequest();
      default:
        return onClickAction(action);
    }
  }, [
    cloneDataSetRequest,
    editDataSetRequest,
    uploadTermsAndConditions,
    deleteDataSetRequest,
    onClickAction,
  ]);

  return (
    <ActionsMenu
      {...otherProps}
      className={classnames("dataSetRequestActionsMenu", className)}
      actions={actions}
      buttonLabel={buttonLabel}
      onClickAction={actionClicked}
    />
  );
};

export default DataSetRequestActionsMenu;
