import { Record } from "immutable";
import { getStringValue } from "@util";

export interface PreSignedUrlMapAttributes {
  [key: string]: string;
}

export interface DataLakeMultiPartUploadUrlMapAttributes {
  presignedURLMap: PreSignedUrlMapAttributes;
  uploadId: string;
}

export class DataLakeMultiPartUploadUrlMap extends Record({
  presignedURLMap: {},
  uploadId: "",
}) implements DataLakeMultiPartUploadUrlMapAttributes {

  public static EMPTY: DataLakeMultiPartUploadUrlMap = new DataLakeMultiPartUploadUrlMap();

  public readonly presignedURLMap: PreSignedUrlMapAttributes;
  public readonly uploadId: string;

  public getPreSignedURLMap(): PreSignedUrlMapAttributes {
    const presignedURLMap = this.presignedURLMap || {};

    return { ...presignedURLMap };
  }

  public getUploadId(): string {
    return getStringValue(this.uploadId);
  }
}

export default DataLakeMultiPartUploadUrlMap;
