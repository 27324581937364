import React from "react";
import { noop } from "@util/Functions";
import { UserProfileAttribute } from "@data";
import {
  EditUserProfileAttributeListItem as EditModeListItem,
  UserProfileAttributesList,
  UserProfileAttributesListActions,
  UserProfileAttributesListColumn as Column,
  UserProfileAttributesListModel,
} from "../user-profile-attributes-list";

export interface EditUserProfileAttributesListModel extends UserProfileAttributesListModel {
}

export interface EditUserProfileAttributesListActions extends UserProfileAttributesListActions {
  updateItem?: (item: UserProfileAttribute) => void;
}

type Props = EditUserProfileAttributesListModel & EditUserProfileAttributesListActions;

export const EditUserProfileAttributesList = (props: Props) => {

  const { updateItem = noop, ...otherProps } = props;

  return (
    <UserProfileAttributesList
      {...otherProps}
      renderItem={(attribute: UserProfileAttribute, column: Column) => (
        <EditModeListItem
          column={column}
          attribute={attribute}
          setValue={(value: string) => updateItem(new UserProfileAttribute({
            ...attribute.toJS(),
            value,
          }))}
        />
      )}
    />
  );
};

export default EditUserProfileAttributesList;
