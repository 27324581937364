import { AppSchema } from "@main/schemas";
import { getAuthToken } from "@main/selectors";
import { createActions } from "@modules/base/createActions";
import { RestClientError, UserIdmLegacyClient } from "@network";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { getUserId } from "./selectors";
import { isEmptyString } from "@util/Functions";

export const {
  userId: setUserId,
  open: setOpen,
  setErrorMessage,
  setSuccessMessage,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  LOGOUT_USER_REQUEST: logoutUserRequest,
  LOGOUT_USER_SUCCESS: logoutUserSuccess,
  LOGOUT_USER_FAILED: logoutUserFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const logoutUser = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const userId = getUserId(state);
  const authToken = getAuthToken(state);

  dispatch(showLoadingIndicator());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(logoutUserRequest());

  return UserIdmLegacyClient.logoutUser(authToken, userId)
    .then(() => {

      dispatch(logoutUserSuccess());
      dispatch(hideLoadingIndicator());
      return dispatch(setSuccessMessage("User has been logged out"));

    }, (response: RestClientError) => {

      const { analytic, error } = response;

      dispatch(logoutUserFailed(analytic));
      dispatch(hideLoadingIndicator());
      return dispatch(setErrorMessage(`Logout User Failed w/ Error: ${error}`));
    });
};

export const reset = () => (dispatch: any) => {
  dispatch(setUserId());
  dispatch(setOpen());
  return dispatch(baseReset());
};

export const open = (userId: string) => (dispatch: any) => {

  if (isEmptyString(userId)) {
    return Promise.resolve();
  }

  dispatch(reset());
  dispatch(setUserId(userId));
  return dispatch(setOpen(true));
};

export const close = reset;

export const initialize = reset;
