import React from "react";
import { useLocation } from "react-router-dom";
import { equalsIgnoreCase, getStringValue, isEmptyString } from "@util";

export enum DataApprovalRequestFilter {
  NONE = "",
  STATE = "state",
}

export enum DataApprovalRequestState {
  NONE = "",
  PENDING = "PENDING",
  ALL = "ALL",
}

const NORMALIZED_VALID_STATE_QUERY_PARAM_VALUES: string[] =
  Object.values(DataApprovalRequestState)
    .filter(it => !isEmptyString(it))
    .map(it => getStringValue(it).toLowerCase());

const isValidState = (state: string): boolean => {
  return !isEmptyString(state) &&
    NORMALIZED_VALID_STATE_QUERY_PARAM_VALUES.indexOf(
      getStringValue(state).toLowerCase()) >= 0;
};

export const DEFAULT_DATA_APPROVAL_REQUEST_FILTER_STATE = DataApprovalRequestState.PENDING;

export interface DataApprovalRequestsQueryParams {
  state?: string;
  validState?: boolean;
  filterByPendingApproval?: boolean;
}

export const useDataApprovalRequestsQueryParams = (): DataApprovalRequestsQueryParams => {

  const {search} = useLocation();

  const queryParams = new URLSearchParams(getStringValue(search));

  const state = React.useMemo(() =>
    queryParams.get(DataApprovalRequestFilter.STATE) || "", [queryParams]);

  return React.useMemo(() => ({
    ...(isEmptyString(state) ? ({}) : ({
      state,
      validState: isValidState(state),
      filterByPendingApproval: equalsIgnoreCase(state, DEFAULT_DATA_APPROVAL_REQUEST_FILTER_STATE),
    })),
  }), [state]);
};

export default useDataApprovalRequestsQueryParams;
