import { createSelector } from "reselect";
import { AppSchema } from "@schemas";
import { DeviceTypeModelV3, DeviceTypeListItem } from "@data";
import { DeviceTypeDetailsSelectors } from "../selectors";
import { compare, isEmptyString } from "@util";

export const getNamespace = (state: AppSchema): string => {
  return state.deviceTypeDetails.softwareVersionDetails.namespace;
};

export const getName = (state: AppSchema): string => {
  return state.deviceTypeDetails.softwareVersionDetails.name;
};

export const getSoftwareVersion = (state: AppSchema): string => {
  return state.deviceTypeDetails.softwareVersionDetails.softwareVersion;
};

export const getDeviceTypeVersions = (state: AppSchema): string[] => {
  return state.deviceTypeDetails.softwareVersionDetails.deviceTypeVersions;
};

export const getErrorMessage = (state: AppSchema): string => {
  return state.deviceTypeDetails.softwareVersionDetails.errorMessage;
};

export const isAccessDeniedVisible = (state: AppSchema): boolean => {
  return state.deviceTypeDetails.softwareVersionDetails.showAccessDenied;
};

const isLoadingIndicatorVisible = (state: AppSchema): boolean => {
  return state.deviceTypeDetails.softwareVersionDetails.showLoadingIndicator;
};

export const getTitle: (state: AppSchema) => string = createSelector(
  [getSoftwareVersion], (softwareVersion: string) => {

    return `Software Version ${softwareVersion} Device Types`;
  });

export const getNoResultsLabel: (state: AppSchema) => string = createSelector(
  isLoadingIndicatorVisible, (loading: boolean) => {

    return loading ? "Loading..." : "This software version is not used by any other device types";
  });

const getDeviceTypes: (state: AppSchema) => DeviceTypeListItem[] = createSelector(
  [isLoadingIndicatorVisible, getDeviceTypeVersions],
  (loading: boolean, deviceTypeIds: string[]) =>
    loading ? [] : deviceTypeIds.map((typeIdentity: string) =>
      DeviceTypeListItem.from(new DeviceTypeModelV3({ typeIdentity }))));

export const getSortedDeviceTypes: (state: AppSchema) => DeviceTypeListItem[] = createSelector(
  getDeviceTypes, (deviceTypes: DeviceTypeListItem[]) => {

    deviceTypes.sort((left: DeviceTypeListItem, right: DeviceTypeListItem) =>
      compare(left.getTypeIdentity(), right.getTypeIdentity(), true));

    return deviceTypes.slice();
  });

export const getSelectedDeviceTypes: (state: AppSchema) => DeviceTypeListItem[] = createSelector(
  [getSortedDeviceTypes, DeviceTypeDetailsSelectors.getDeviceTypeId],
  (deviceTypes: DeviceTypeListItem[], currentDeviceTypeId: string) =>
    deviceTypes.filter((deviceType: DeviceTypeListItem) =>
      deviceType.getTypeIdentity() === currentDeviceTypeId));

export const isNoResultsViewVisible: (state: AppSchema) => boolean = createSelector(
  getSortedDeviceTypes, (deviceTypes: DeviceTypeListItem[]) =>
    deviceTypes.length === 0);

export const isErrorViewVisible: (state: AppSchema) => boolean = createSelector(
  getErrorMessage, (errorMessage: string) => !isEmptyString(errorMessage));
