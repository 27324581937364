import { isEmptyString, isValidInteger } from "@util";
import { MaintenanceReminderAttributes, MaintenanceReminderStatus } from "@data";
import { makeJsonApiRequest, withAuthToken, createQueryParams } from "@network/helpers";

const MAINTENANCE_REMINDER_API = process.env.REACT_APP_MAINTENANCE_REMINDER_API || "";
const DEFAULT_GET_REMINDERS_LIMIT = 50;

export interface GetMaintenanceRemindersResponse {
  reminders: MaintenanceReminderAttributes[];
  paging?: {
    next?: string;
  };
}

export const getMaintenanceReminder = (authToken: string,
  status: MaintenanceReminderStatus = MaintenanceReminderStatus.ACTIVE,
  next: string = "",
  limit: number = DEFAULT_GET_REMINDERS_LIMIT
): Promise<GetMaintenanceRemindersResponse> => {

  const validate = () => withAuthToken(authToken);

  const queryParams = createQueryParams({
    ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, Math.min(DEFAULT_GET_REMINDERS_LIMIT, limit)) }),
    ...(!isEmptyString(status) ? ({ status }) : ({})),
    next,
  });

  const makeRequest = () => {

    const url = MAINTENANCE_REMINDER_API + `/portal/platform-administration/v1/maintenance-reminders${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch maintenance reminder failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};