import { connect } from "react-redux";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { PolicyStatement } from "@data";
import {
  getPolicyErrorMessage,
  getImplicitPermissionStatements,
  isPolicyLoadingIndicatorVisible,
} from "../selectors";
import { Actions, Model, StatementsList } from "../components/ImplicitPermissionStatements";

export interface StatementsListContainerProps {
  selectable?: boolean;
  noResultsLabel?: string;
  statements?: PolicyStatement[];
}

const mapStateToProps = (state: AppSchema, ownProps: StatementsListContainerProps): Model => {

  const {
    selectable = false,
    noResultsLabel = "No Statements to Display",
    statements: items = getImplicitPermissionStatements(state),
  } = ownProps;

  const isEmpty = items.length === 0;
  const isLoading = isPolicyLoadingIndicatorVisible(state);
  const error = getPolicyErrorMessage(state);
  const showErrorView = error.length > 0;
  const showNoResultsView = isEmpty && !showErrorView && !isLoading;
  const showLoadingView = isLoading && !showErrorView && (isEmpty);

  return {
    items,
    error,
    selectable,
    noResultsLabel,
    showErrorView,
    showLoadingView,
    showNoResultsView,
  };
};

const mapDispatchToProps = (dispatch: any): Actions => {
  return {
    loadMore: () => noop,
  };
};

export default connect<Model, Actions, StatementsListContainerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(StatementsList);
