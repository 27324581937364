import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 20,
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      alignSelf: "flex-start",
      fontSize: 24,
      lineHeight: "28px",
    },
    titleAndAction: {
      lineHeight: "40px",
    },
    titleContainer: {
      display: "flex",
    },
    items: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginTop: 15,
    },
    item: {
      color: Color.TEXT,
      height: 28,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "12px",
      paddingLeft: 4,
      paddingRight: 4,
      marginLeft: 8,
      marginRight: 8,
      marginTop: 8,
      "& span": {
        marginLeft: 2,
        marginRight: 2,
        paddingRight: 8,
      },
    },
    icon: {
      color: Color.BLACK,
      width: 12,
      height: 12,
    },
    editIcon: {
      marginLeft: 15,
      padding: 15,
    },
    emptyView: {
      color: Color.GREY7,
      fontSize: 14,
      fontWeight: 400,
      marginTop: 10,
    },
    actions: {
      marginLeft: 5,
      "& button": {
        padding: 8,
      },
    }
  });

export default styles;
