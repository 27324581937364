import React from "react";
import { EmailTemplateMessageType } from "@data";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  UseApiRequestActions,
  useEmailTemplateClient
} from "@hooks";
import { equalsIgnoreCase, isEmptyString, noop } from "@util";

type SuccessResponse = void;

export interface UseEmailTemplateValidateProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  messageType?: EmailTemplateMessageType;
  emailSubject?: string;
  description?: string;
  textBody?: string;
  htmlBody?: string;
  defaultSuccessMessage?: string;
  defaultErrorMessage?: string;
  debounceDelay?: number;
}

export interface UseEmailTemplateValidateModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseEmailTemplateValidateActions extends UseApiRequestActions<SuccessResponse> {
  validateEmailTemplate: () => void;
}

type Props = UseEmailTemplateValidateProps;
type Model = UseEmailTemplateValidateModel;
type Actions = UseEmailTemplateValidateActions;
type Result = [Model, Actions];

export const useEmailTemplateValidate = (props: Props): Result => {

  const {
    messageType = EmailTemplateMessageType.NONE,
    emailSubject = "",
    description = "",
    textBody = "",
    htmlBody = "",
    defaultSuccessMessage = "Email Template is valid",
    defaultErrorMessage = "Failed to validate the email template",
    debounceDelay = 500,
    ...otherProps
  } = props;

  const [json, setJson] = React.useState("");

  const EmailTemplateClient = useEmailTemplateClient();

  const pendingJson = React.useMemo(() => JSON.stringify({
    messageType,
    body: {
      emailSubject,
      description,
      ...(!isEmptyString(textBody) ? ({ textBody }) : ({})),
      ...(!isEmptyString(htmlBody) ? ({ htmlBody }) : ({})),
    },
  }), [messageType, emailSubject, description, textBody, htmlBody]);

  const validRequestBody = React.useMemo(() =>
      EmailTemplateMessageType.NONE !== messageType &&
      !isEmptyString(emailSubject) &&
      !isEmptyString(description) &&
      (!isEmptyString(textBody) || !isEmptyString(htmlBody))
    , [messageType, emailSubject, description, textBody, htmlBody]);

  const makeApiRequest = React.useCallback(() => {
    if (!validRequestBody) {
      return Promise.reject({
        error: "Email template cannot be validated without a valid message type, subject, " +
          "description, and either a text body or html body",
        status: 400,
        message: "Bad Request",
        description: "You must provide a valid request body",
        analytic: `400:Bad Request:Invalid Request Body`,
      });
    }
    return EmailTemplateClient.validateEmailTemplate(json);
  }, [validRequestBody, EmailTemplateClient, json]);

  const [{ showSuccessView, ...baseModel }, baseActions] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const { refresh: validateEmailTemplate } = baseActions;

  const successMessage = React.useMemo(() =>
      showSuccessView ? defaultSuccessMessage : "",
    [showSuccessView, defaultSuccessMessage]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    showSuccessView,
    successMessage,
  }), [
    baseModel,
    showSuccessView,
    successMessage,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    validateEmailTemplate,
  }), [
    baseActions,
    validateEmailTemplate,
  ]);

  // Debounced validate email template request
  React.useEffect(() => {

    if (!equalsIgnoreCase(json, pendingJson)) {
      const timer = setTimeout(() => {
        setJson(pendingJson);
      }, debounceDelay);

      return () => clearTimeout(timer);
    }

    return noop;

  }, [json, pendingJson, setJson, debounceDelay]);

  // Re-validate whenever the email template request body changes
  React.useEffect(() => {
    validateEmailTemplate();
  }, [json, validateEmailTemplate]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEmailTemplateValidate;
