import { Record } from "immutable";
import { equalsIgnoreCase, getStringValue, isEmptyString, isString } from "@util";

export interface SecurityGroupPath {
  value: string;
}

// TODO: API for create group uses a string and API for get/list uses an object. WTF?
type Path = SecurityGroupPath | string;

export interface SecurityGroupAttributes {
  name: string;
  path: Path;
}

export class SecurityGroup extends Record({
  name: "",
  path: "/",
}) implements SecurityGroupAttributes {

  public static EMPTY: SecurityGroup = new SecurityGroup();

  public readonly name: string;
  public readonly path: Path;

  // We need to override equals because this Record contains a nested Object property
  //
  // Reference: https://immutable-js.github.io/immutable-js/docs/#/Record/equals
  // Value equality: Records use value equality when compared with is() or record.equals().
  // That is, two Records with the same keys and values are equal. Plain objects use reference
  // equality. Two objects with the same keys and values are not equal since they are different
  // objects. This is important to consider when using objects as keys in a Map or values in a
  // Set, which use equality when retrieving values.
  public equals(other: SecurityGroup): boolean {
    return equalsIgnoreCase(this.getName(), other.getName()) &&
      equalsIgnoreCase(this.getPath(), other.getPath());
  }

  public getName(): string {
    return getStringValue(this.name + "");
  }

  public hasName(): boolean {
    return !isEmptyString(this.getName());
  }

  public getPath(): string {

    if (isString(this.path)) {
      return getStringValue(this.path);
    }

    const { value = "" } = this.path as SecurityGroupPath || {};

    return getStringValue(value);
  }

  public hasPath(): boolean {
    return !isEmptyString(this.getPath());
  }

  public doesPathHaveTrailingSlash(): boolean {
    const path = this.getPath();
    return path.slice(path.length - 1) === "/";
  }

  public isValidPath(): boolean {
    return this.hasPath() && this.doesPathHaveTrailingSlash();
  }
}
