import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { DeviceTypeColumn } from "../reducers";
import {
  getErrorMessage,
  getSearchResults,
  getSortedColumn,
  isErrorViewVisible,
  isLoadingViewVisible,
  isLoadMoreButtonVisible,
  isNoResultsViewVisible,
  isSortButtonDisabled,
  isSortOrderAscending,
} from "../selectors";
import { fetchDeviceTypes, setSortedColumn, toggleSortOrder } from "../actions";
import DeviceTypesList, { Actions, Model } from "../components/DeviceTypesList";

type Props = Model & Actions;

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  items: getSearchResults(state),
  error: getErrorMessage(state),
  sortedColumn: getSortedColumn(state),
  showErrorView: isErrorViewVisible(state),
  showLoadingIndicator: isLoadingViewVisible(state),
  sortButtonDisabled: isSortButtonDisabled(state),
  sortOrderAscending: isSortOrderAscending(state),
  showNoResultsView: isNoResultsViewVisible(state),
  showLoadMoreButton: isLoadMoreButtonVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  loadMore: () => dispatch(fetchDeviceTypes()),
  toggleSortOrder: () => dispatch(toggleSortOrder()),
  setSortedColumn: (column: DeviceTypeColumn) => dispatch(setSortedColumn(column)),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceTypesList);
