import React from "react";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { workloads as styles } from "./styles";
import classnames from "classnames";
import { useGetWorkload } from "@hooks";
import { AddTriggerRequest, PrecedingWorkloadCompletionStatus, UpdateTriggerRequest } from "@data";
import {
  DropdownMenu,
  WorkloadsList,
  VerticalStepper,
  ActionsListItemData,
  WorkloadActionsList,
} from "@components";

export interface Model {
  data?: UpdateTriggerRequest;
  currentWorkload: string;
}

export interface Actions {
  showWorkloadDetails?: (name: string) => void;
  setTriggerData?: (trigger: AddTriggerRequest) => void;
}

enum WorkloadCompleteSteps {
  PRECEDING_WORKLOAD = "PRECEDING_WORKLOAD",
  COMPLETION_STATUS = "COMPLETION_STATUS",
  CONDITIONS = "CONDITIONS",
}

const DEFAULT_STEPS = [
  WorkloadCompleteSteps.PRECEDING_WORKLOAD,
  WorkloadCompleteSteps.COMPLETION_STATUS,
  WorkloadCompleteSteps.CONDITIONS,
];

const DEFAULT_STEP_LABELS = {
  [WorkloadCompleteSteps.PRECEDING_WORKLOAD]: "",
  [WorkloadCompleteSteps.COMPLETION_STATUS]: "Select the completion status for the preceding workload",
  [WorkloadCompleteSteps.CONDITIONS]: "Add actions that the preceding workload will be required to have",
};

type Props = WithStyles<typeof styles> & Model & Actions;

export const WorkloadCompleteView = withStyles(styles)((props: Props) => {

  const {
    classes,
    currentWorkload,
    data = UpdateTriggerRequest.EMPTY,
    setTriggerData = noop,
    ...otherProps
  } = props;

  const { precedingWorkloadName } = data;

  const [{ workload: precedingWorkload, ...otherModel }, { refresh, ...otherActions }] =
    useGetWorkload({ name: precedingWorkloadName, deferRequest: true });

  const [activeStep, setActiveStep] = React.useState(WorkloadCompleteSteps.PRECEDING_WORKLOAD);
  const [status, setStatus] = React.useState(PrecedingWorkloadCompletionStatus.ANY);

  const updateStatus = React.useCallback((newStatus: PrecedingWorkloadCompletionStatus) => {
    setStatus(newStatus);
    setTriggerData({
      completionState: {
        ...data.toJS().completionState,
        completionStatus: newStatus
      }
    });
  }, [setStatus, setTriggerData, data]);

  const items = React.useMemo(() =>
    precedingWorkload.getWorkloadCompletionMetadataActions().map(
      action => new ActionsListItemData({ name: action })
    ), [precedingWorkload]);

  const selectedItems = React.useMemo(() =>
    data.getConditions().map(
      action => new ActionsListItemData({ name: action })
    ), [data]);

  const setSelectedItems = React.useCallback((updatedActions: ActionsListItemData[]) => {
    setTriggerData({
      completionState: {
        ...data.toJS().completionState,
        actions: updatedActions.map(item => item.getName())
      }
    });
  }, [setTriggerData, data]);

  React.useEffect(() => {
    if (!isEmptyString(precedingWorkloadName)) {
      refresh();
      setSelectedItems([]);
    }
  }, [precedingWorkloadName, refresh]);

  React.useEffect(() => {
    switch (true) {
      case !isEmptyString(data.getPrecedingWorkloadName()) &&
        !isEmptyString(data.getPrecedingWorkloadCompletionStatus()):
        return setActiveStep(WorkloadCompleteSteps.CONDITIONS);
      case !isEmptyString(data.getPrecedingWorkloadName()):
        return setActiveStep(WorkloadCompleteSteps.COMPLETION_STATUS);
      default:
        return setActiveStep(WorkloadCompleteSteps.PRECEDING_WORKLOAD);
    }
  }, [data, setActiveStep]);

  const mapStepContent = (step: WorkloadCompleteSteps) => {
    switch (step) {
      case WorkloadCompleteSteps.PRECEDING_WORKLOAD:
        return (
          <WorkloadsList
            {...otherProps}
            className={classnames("workloads", classes.container)}
            currentWorkload={currentWorkload}
            setTriggerData={setTriggerData}
            selectable={true}
            selectAllDisabled={true}
            summaryViewLabel={"Select the workload that will trigger workload " + currentWorkload}
            showIcon={false}
          />
        );
      case WorkloadCompleteSteps.COMPLETION_STATUS:
        return (
          <DropdownMenu
            hideEmptyValue={true}
            values={Object.values(PrecedingWorkloadCompletionStatus)}
            selectedValue={status}
            setSelectedValue={(newStatus) => updateStatus(newStatus as PrecedingWorkloadCompletionStatus)}
          />
        );
      case WorkloadCompleteSteps.CONDITIONS:
        return (
          <WorkloadActionsList
            {...otherProps}
            {...otherModel}
            {...otherActions}
            items={items}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            noResultsLabel={`No actions found for ${precedingWorkload.getName()}`}
          />
        );
      default:
        return null;
    }
  };

  return (
    <VerticalStepper
      activeStep={DEFAULT_STEPS.indexOf(activeStep)}
      steps={DEFAULT_STEPS.map((step) => DEFAULT_STEP_LABELS[step])}
      getStepContent={(step) => mapStepContent(DEFAULT_STEPS[step])}
    />
  );
});

export default WorkloadCompleteView;
