import React from "react";
import { GetDownloadUrlResponse } from "@network";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useDataLakeFileTransferClient,
} from "@hooks";
import { DownloadType } from "./helpers";
import { DataLakePreSignedUrl } from "@data";

type SuccessResponse = GetDownloadUrlResponse;

export interface UseDataLakeDownloadServiceProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  dataSet?: string;
  files?: string[];
  accountId?: string;
  type?: DownloadType;
}

export interface UseDataLakeDownloadServiceModel extends UseApiRequestModel<SuccessResponse> {
  urls: DataLakePreSignedUrl[];
}

export interface UseDataLakeDownloadServiceActions extends UseApiRequestActions<SuccessResponse> {
  download: () => void;
  reset: () => void;
}

type Props = UseDataLakeDownloadServiceProps;
type Model = UseDataLakeDownloadServiceModel;
type Actions = UseDataLakeDownloadServiceActions;
type Result = [Model, Actions];

export const useDataLakeDownloadService = (props: Props): Result => {

  const {
    dataSet = "",
    files = [],
    accountId = "",
    type = DownloadType.ACQUIRED_FILES,
    defaultErrorMessage = "Failed to create pre-signed download url",
    ...otherProps
  } = props;

  const DataLakeFileTransferClient = useDataLakeFileTransferClient();

  const makeApiRequest = React.useCallback(() => {
    if (type === DownloadType.ACQUIRED_FILES) {
      return DataLakeFileTransferClient.getAcquiredFilesDownloadUrl(dataSet, files, accountId);
    } else if (type === DownloadType.ACQUIRED_STREAMS) {
      return DataLakeFileTransferClient.getAcquiredStreamDownloadUrl(dataSet, JSON.stringify({ files }), accountId);
    } else {
      return DataLakeFileTransferClient.getBlobFilesDownloadUrl(dataSet, JSON.stringify({ files }), accountId);
    }
  }, [
    type,
    DataLakeFileTransferClient,
    dataSet,
    files,
    accountId,
  ]);

  const [{ successResponse, ...baseModel }, { refresh: download, ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      makeApiRequest,
      defaultErrorMessage,
      deferRequest: true,
    });

  const urls = React.useMemo(() => {
    const { urlResponse: attrsMap = [] } = successResponse || {} ;
    return attrsMap.map(attrs => new DataLakePreSignedUrl(attrs));
  } , [successResponse ]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    urls,
  }), [
    baseModel,
    urls,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    download,
    refresh: download,
  }), [
    baseActions,
    download,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDataLakeDownloadService;
