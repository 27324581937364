import React from "react";
import classnames from "classnames";
import AddIcon from "@material-ui/icons/AddOutlined";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@components";
import { Autocomplete, Button, DropdownMenu } from "@components";
import { clickHandler, getStringValue, isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const isValidAwsAccountId = (accountId: string) => {
  return /^[0-9]{12}$/.test(accountId);
};

export interface Model {
  isAccessForExistingCloudResource?: boolean;
  trustedAccountsForCloudRole?: string[];
  cloudRoleIdentifier?: string;
  cloudRoleIdentifiers?: string[];
  loadingCloudRoleIdentifiers?: boolean;
  cloudRoleIdentifiersErrorMessage?: string;
}

export interface Actions {
  setTrustedAccountsForCloudRole?: (trustedAccountsForCloudRole: string[]) => void;
  setCloudRoleIdentifier?: (cloudRoleIdentifier: string) => void;
  refreshCloudRoleIdentifiers?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const IotCloudRoleView = withStyles(styles)((props: Props) => {

  const {
    classes,
    isAccessForExistingCloudResource,
    trustedAccountsForCloudRole = [],
    cloudRoleIdentifier,
    cloudRoleIdentifiers = [],
    loadingCloudRoleIdentifiers,
    cloudRoleIdentifiersErrorMessage,
    setTrustedAccountsForCloudRole = noop,
    setCloudRoleIdentifier = noop,
    refreshCloudRoleIdentifiers = noop,
    children,
  } = props;

  const [autocompleteInputValue, setAutocompleteInputValue] = React.useState("");

  const showAddButton = React.useMemo(() => {
    const inputValue = getStringValue(autocompleteInputValue);
    return isValidAwsAccountId(inputValue) && (trustedAccountsForCloudRole.indexOf(inputValue) === -1);
  }, [autocompleteInputValue]);

  const onClickAddButton = React.useCallback(() => {
    setTrustedAccountsForCloudRole(Array.from(new Set(trustedAccountsForCloudRole.concat(autocompleteInputValue))));
    setAutocompleteInputValue("");
  }, [setTrustedAccountsForCloudRole, trustedAccountsForCloudRole, autocompleteInputValue]);

  const showRefreshCloudRoleIdentifiersButton = React.useMemo(() =>
    !isEmptyString(cloudRoleIdentifiersErrorMessage),
    [cloudRoleIdentifiersErrorMessage]);

  const dropdownMenuHint = React.useMemo(() =>
    cloudRoleIdentifiers.length === 0
      ? "No Existing IoT Cloud Roles Found"
      : "Select the IoT Cloud Role that will need access to this data set",
    [cloudRoleIdentifiers]);

  const renderOption = (option: string): string | React.ReactNode => {
    const valid = isValidAwsAccountId(option);
    if (valid) {
      return option;
    } else {
      return (
        <div className={classnames("invalidAutocompleteOption", classes.invalidAutocompleteOption)}>
          <label className={classnames("invalidAutocompleteOptionValue", classes.invalidAutocompleteOptionValue)}>
            {option}
          </label>
          <label className={classnames("invalidAutocompleteOptionError", classes.invalidAutocompleteOptionError)}>
            AWS Account ID Must Be 12-Digit Number
          </label>
        </div>
      );
    }
  };

  return(
    <div className={classnames("iotCloudRoleView", classes.iotCloudRoleView)}>
      <div className={classnames("cloudRoleIdentifier", classes.section, classes.cloudRoleIdentifier)}>
        <Typography className={classnames("title", classes.title)} variant="h3">
          Do you want to create a new IoT Cloud Role to access this data set?
        </Typography>
        <Typography variant="subtitle1" className={classnames("subtitle", classes.subtitle)}>
          <b>NOTE: </b>
          You may also request access on behalf of any IoT Cloud Role(s) that have already been created for you.
        </Typography>
        <div className={classnames("cloudRoleIdentifiersContainer", classes.cloudRoleIdentifiersContainer)}>
          <DropdownMenu
            className={classnames("cloudRoleIdentifiers", classes.dropdownMenu, classes.cloudRoleIdentifiers)}
            dropdownMenuLabel="IoT Cloud Role Principal"
            dropdownMenuLabelClassName={classes.dropdownMenuLabel}
            emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
            dropdownMenuHint={dropdownMenuHint}
            emptyValueLabel="Create New IoT Cloud Role"
            values={cloudRoleIdentifiers}
            selectedValue={cloudRoleIdentifier}
            loading={loadingCloudRoleIdentifiers}
            loadingDropdownMenuHint="Fetching available IoT Cloud Roles..."
            errorMessage={cloudRoleIdentifiersErrorMessage}
            setSelectedValue={setCloudRoleIdentifier}
          />
          {showRefreshCloudRoleIdentifiersButton && (
            <Button
              className={classnames("retryButton", classes.retryButton)}
              onClick={clickHandler(refreshCloudRoleIdentifiers)}
            >
              Retry
            </Button>
          )}
        </div>
      </div>
      {!isAccessForExistingCloudResource && (
        <div className={classnames("trustedAccounts", classes.section, classes.trustedAccounts)}>
          <Typography className={classnames("title", classes.title)} variant="h3">
            Set Trusted Accounts For IoT Cloud Role
          </Typography>
          <div className={classnames("autocompleteContainer", classes.autocompleteContainer)}>
            <Autocomplete
              className={classnames("trustedAccountsForCloudRole", classes.trustedAccountsForCloudRole)}
              optionClassName={classnames("autocompleteOption", classes.autocompleteOption)}
              label="Trusted AWS Account ID(s)"
              placeholder="Add Trusted AWS Account ID(s)"
              noOptionsLabel=""
              openOnFocus={false}
              selectOnFocus={false}
              disableClearable={true}
              freeSolo={true}
              renderOption={renderOption}
              getOptionDisabled={option => !isValidAwsAccountId(option)}
              value={trustedAccountsForCloudRole}
              setValue={setTrustedAccountsForCloudRole}
              onInputValueChange={setAutocompleteInputValue}
            />
            {showAddButton && (
              <IconButton
                className={classnames("addIconButton", classes.addIconButton)}
                color="primary"
                onClick={onClickAddButton}
              >
                <AddIcon className={classnames("addIcon", classes.addIcon)} />
              </IconButton>
            )}
          </div>
        </div>
      )}
      {children}
    </div>
  );
});

export default IotCloudRoleView;
