import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { Model, DevicesEditor } from "../../components/devices/DevicesEditor";
import DevicesTable from "./DevicesTable";
import AddDeviceDialog from "./AddDeviceDialog";
import RemoveDevice from "./RemoveDevice";
import { DeviceDataModel } from "@data";
import { DeviceEnrollmentWizardSelectors } from "../../selectors";
import { DeviceEnrollmentWizardActions } from "../../actions";

interface ContainerModel extends Model {
  hidden?: boolean;
  devices?: DeviceDataModel[];
  addButtonDisabled?: boolean;
}

interface ContainerActions {
  addDevice?: () => void;
  removeDevice?: (device: DeviceDataModel) => void;
  editDevice?: (device: DeviceDataModel) => void;
  cloneDevice?: (device: DeviceDataModel) => void;
}

const DeviceEditorContainer = (props: ContainerModel & ContainerActions) => {

  const {
    hidden,
    devices,
    addDevice,
    removeDevice,
    editDevice,
    cloneDevice,
    addButtonDisabled,
    ...remainingProps
  } = props;

  if (hidden) {
    return null;
  }

  return (
    <DevicesEditor {...remainingProps}>
      <DevicesTable
        devices={devices}
        addDevice={addDevice}
        removeDevice={removeDevice}
        editDevice={editDevice}
        cloneDevice={cloneDevice}
        addButtonDisabled={addButtonDisabled}
      />
      <AddDeviceDialog />
      <RemoveDevice />
    </DevicesEditor>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => {

  const hidden = !DeviceEnrollmentWizardSelectors.isDevicesViewSelected(state);

  if (!DeviceEnrollmentWizardSelectors.isDevicesJsonValid(state)) {
    return {
      hidden,
      showInvalidJsonError: true,
      ...ownProps
    };
  }

  return {
    hidden,
    devices: DeviceEnrollmentWizardSelectors.getDevices(state),
    addButtonDisabled: !DeviceEnrollmentWizardSelectors.canAddMoreDevices(state),
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any): ContainerActions => ({
  addDevice: () => dispatch(DeviceEnrollmentWizardActions.openAddDeviceDialog()),
  removeDevice: (device: DeviceDataModel) => dispatch(DeviceEnrollmentWizardActions.openRemoveDeviceDialog(device)),
  editDevice: (device: DeviceDataModel) => dispatch(DeviceEnrollmentWizardActions.openEditDeviceDialog(device)),
  cloneDevice: (device: DeviceDataModel) => dispatch(DeviceEnrollmentWizardActions.openCloneDeviceDialog(device)),
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceEditorContainer);
