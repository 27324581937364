export enum DeviceTypeState {
  NONE = "",
  DRAFT = "DRAFT",
  RELEASED = "RELEASED",
  DEPRECATED = "DEPRECATED",
  DECOMMISSIONED = "DECOMMISSIONED",
  DELETED = "DELETED",
}

export default DeviceTypeState;
