import createStyles from "@material-ui/core/styles/createStyles";
import AuthenticatorIcon from "@material-ui/icons/VpnKey";
import Color from "@Color";

export { AuthenticatorIcon };

export const primaryColor = Color.MODULES;

export const listItem = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "center",
    },
    label: {
      color: Color.TEXT,
      cursor: "default",
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
      textTransform: "none",
      wordBreak: "break-word",
      marginRight: 8,
    },
    icon: {
      color: primaryColor,
      alignSelf: "center",
      width: 16,
      height: 16,
      marginTop: 0,
      marginRight: 10,
      "&.pending": {
        color: Color.MUSTARD,
      },
    },
  });
