import React from "react";
import ManagedPolicy from "@data/ManagedPolicy";
import { useManagedPolicies } from "@hooks";
import ManagedPoliciesList from "@components/managed-policies-list";

interface ContainerModel {
  showManagedPolicyDetails?: (managedPolicy: ManagedPolicy) => void;
}

const ManagedPoliciesContainer = (props: ContainerModel) => {

  const { showManagedPolicyDetails } = props;

  const [ model, actions] = useManagedPolicies();

  const { managedPolicies } = model;

  return (
    <ManagedPoliciesList
      {...model}
      {...actions}
      summaryViewLabel="Managed Policies"
      items={managedPolicies}
      showSummary={true}
      showSearch={true}
      onClickItem={showManagedPolicyDetails}
    />
  );
};

export default ManagedPoliciesContainer;
