import { createStyles, Theme } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    backdrop: {
      background: "rgba(105, 105, 105, 0.25)",
      zIndex: theme.zIndex.modal,
    },
    dialog: {
    },
    dialogContent: {
      minWidth: 500,
      padding: "24px 20px",
    },
    title: {
      color: Color.TEXT,
    },
    successView: {
      marginTop: 10,
    },
    infoView: {
      marginTop: 10,
    },
    list: {
      marginTop: 15,
    },
    listItem: {
      marginTop: 15,
      "&:first-child": {
        marginTop: 0,
      },
    },
    controls: {
      marginTop: 15,
      paddingTop: 15,
      borderTop: `1px solid ${Color.GREY1}`,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    continueButton: {
    },
  });

export default styles;
