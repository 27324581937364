import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { close, confirm } from "../actions";
import {
  getErrorMessage,
  getSuccessMessage,
  isAccessDeniedVisible,
  isConfirmStep,
  isLoadingIndicatorVisible,
  isOpen,
  isGetUserIdStep,
  isSaveButtonEnabled,
} from "../selectors";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import Confirmation from "./Confirmation";
import UserIdContainer from "./UserIdContainer";

interface Model extends PortalModuleDialogModel<string> {
  showTakeUserIdView?: boolean;
  showConfirmationView?: boolean;
}

interface Actions extends PortalModuleDialogActions<string> {
}

type Props = Model & Actions;

const ShareDashboardDialog = (props: Props) => {

  const { showTakeUserIdView, showConfirmationView, ...otherProps } = props;

  const continueButtonLabel = React.useMemo(() =>
    showTakeUserIdView ? "Share dashboard" : "Confirm", [showTakeUserIdView]);

  return (
    <PortalModuleDialog {...otherProps} continueButtonLabel={continueButtonLabel}>
      {showTakeUserIdView && <UserIdContainer />}
      {showConfirmationView && <Confirmation />}
    </PortalModuleDialog>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  className: "shareDashboardDialog",
  title: "Share Dashboard",
  open: isOpen(state),
  loading: isLoadingIndicatorVisible(state),
  accessDenied: isAccessDeniedVisible(state),
  errorMessage: getErrorMessage(state),
  successMessage: getSuccessMessage(state),
  continueButtonDisabled: !isSaveButtonEnabled(state),
  showTakeUserIdView: isGetUserIdStep(state),
  showConfirmationView: isConfirmStep(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  cancel: () => dispatch(close()),
  confirm: () => dispatch(confirm()),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(ShareDashboardDialog);
