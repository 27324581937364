import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
    },
    chart: {
      marginTop: 10,
      flex: "1 0 auto",
      minWidth: 768,
      minHeight: 432,
    },
  });

export default styles;
