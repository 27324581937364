import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "../../main/schemas";
import { SummaryEditorView, Model, Actions } from "../components/SummaryEditorView";
import { SchemaWizardSelectors } from "../selectors/schemaWizard";
import PropertiesEditor from "./PropertiesEditor";
import PropertyEditorDialog from "./PropertyEditorDialog";
import RemovePropertyDialog from "./RemovePropertyDialog";

const SummaryEditorViewContainer = (props: Model & Actions) => {

  const { showInvalidJsonError } = props;

  if (showInvalidJsonError) {
    return <SummaryEditorView {...props} />;
  }

  return (
    <SummaryEditorView {...props}>
      <PropertiesEditor />
      <PropertyEditorDialog />
      <RemovePropertyDialog />
    </SummaryEditorView>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  showInvalidJsonError: !SchemaWizardSelectors.isJsonValid(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(SummaryEditorViewContainer);
