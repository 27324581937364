import { Record } from "immutable";
import { getStringValue } from "@util";

export enum NamespaceLifecycle {
  NONE = "",
  DEVELOPMENT = "DEVELOPMENT",
  PRODUCTION = "PRODUCTION",
  DEPRECATED = "DEPRECATED",
}

export interface NamespaceAttributes {
  createdAt: string;
  createdBy: string;
  lifecycle: NamespaceLifecycle;
  name: string;
  promotedAt: string | null;
  promotedBy: string | null;
  type: string;
}

export class Namespace extends Record({
  createdAt: "",
  createdBy: "",
  lifecycle: NamespaceLifecycle.NONE,
  type: "",
  name: "",
  promotedAt: null,
  promotedBy: null,
}) implements  NamespaceAttributes {

  public static EMPTY: Namespace = new Namespace();

  public readonly createdAt: string;
  public readonly createdBy: string;
  public readonly lifecycle: NamespaceLifecycle;
  public readonly name: string;
  public readonly promotedAt: string | null;
  public readonly promotedBy: string | null;
  public readonly type: string;

  public getCreatedAt(): string {
    return getStringValue(this.createdAt);
  }

  public getCreatedBy(): string {
    return getStringValue(this.createdBy);
  }

  public getLifecycle(): NamespaceLifecycle {
    return this.lifecycle;
  }

  public isDevelopmentLifecycle(): boolean {
    return this.lifecycle === NamespaceLifecycle.DEVELOPMENT;
  }

  public isProductionLifecycle(): boolean {
    return this.lifecycle === NamespaceLifecycle.PRODUCTION;
  }

  public isDeprecatedLifecycle(): boolean {
    return this.lifecycle === NamespaceLifecycle.DEPRECATED;
  }

  public getName(): string {
    return getStringValue(this.name);
  }

  public getType(): string {
    return getStringValue(this.type);
  }
}

export default Namespace;
