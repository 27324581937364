import React from "react";
import classnames from "classnames";
import { noop } from "@util";
import { DeviceTypeModelV3ConnectionMode } from "@data";
import { DropdownMenu, FilterSwitch, Alert, AlertTitle } from "@components";
import { DeviceTypeRequestV3 } from "../../models/DeviceTypeRequestV3";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const DEFAULT_CONNECTION_MODES = [
  DeviceTypeModelV3ConnectionMode.CONNECTED,
  DeviceTypeModelV3ConnectionMode.PROXIED,
];

export interface Model {
  title?: string;
  deviceType?: DeviceTypeRequestV3;
  connectionModes?: DeviceTypeModelV3ConnectionMode[];
}

export interface Actions {
  updateDeviceType?: (updatedDeviceType: DeviceTypeRequestV3) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ConnectivityView = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Device Type Connectivity",
    deviceType = DeviceTypeRequestV3.EMPTY,
    connectionModes = DEFAULT_CONNECTION_MODES,
    updateDeviceType = noop,
    children,
  } = props;

  const selectedConnectionMode = React.useMemo(() =>
    deviceType.getConnectionMode(), [deviceType]);

  const showMqttAlert = React.useMemo(() =>
    selectedConnectionMode === DeviceTypeModelV3ConnectionMode.CONNECTED,
    [selectedConnectionMode]);

  const updateConnectionMode = React.useCallback(updatedConnectionMode => {
    const { connectionMode, mqtt, ...prevConstraints } = deviceType.getConstraints();
    updateDeviceType(
      new DeviceTypeRequestV3({
        ...deviceType.toJS(),
        security: {
          ...deviceType.getSecurity(),
          constraints: {
            ...prevConstraints,
            // Do not add empty connection mode value
            ...(updatedConnectionMode === DeviceTypeModelV3ConnectionMode.NONE ? ({}) : ({
              connectionMode: updatedConnectionMode,
            })),
            // Do not add the mqtt flag unless connection mode is connected
            ...(updatedConnectionMode !== DeviceTypeModelV3ConnectionMode.CONNECTED ? ({}) : ({
              mqtt,
            })),
          },
        },
      }));
  }, [deviceType, updateDeviceType]);

  const updateMqtt = React.useCallback(updated => updateDeviceType(
    new DeviceTypeRequestV3({
      ...deviceType.toJS(),
      security: {
        ...deviceType.getSecurity(),
        constraints: {
          ...deviceType.getConstraints(),
          mqtt: updated,
        },
      },
    })), [deviceType, updateDeviceType]);

  return (
    <div className={classnames("connectivityView", classes.container)}>
      <label className={classnames("title", classes.title)}>
        {title}
      </label>
      <DropdownMenu
        className={classnames("connectionMode", classes.dropdownMenu, classes.connectionMode)}
        dropdownMenuLabel="Connection Mode"
        dropdownMenuLabelClassName={classes.dropdownMenuLabel}
        dropdownMenuHint="Select the device type connection mode"
        emptyValueLabel="None"
        values={connectionModes}
        selectedValue={selectedConnectionMode}
        setSelectedValue={updateConnectionMode}
      />
      {showMqttAlert && (
        <React.Fragment>
          <Alert severity="info" className={classnames("emptyCredentialsView", classes.alert)}>
            <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
              <strong>
                MQTT Enabled
              </strong>
            </AlertTitle>
            <p className={classnames("alertDescription", classes.alertDescription)}>
              Device Types now support a security configuration that can be used used to toggle
              support for MQTT by device type, which will allow the platform MQTT broker to
              authorize device connections using this device type.
            </p>
            <p className={classnames("alertDescription", classes.alertDescription)}>
              Enabling this will allow the platform MQTT broker to authorize device connections for this device type.
            </p>
          </Alert>
          <FilterSwitch
            className={classnames("mqttFilterSwitch", classes.filterSwitch, classes.mqttFilterSwitch)}
            rightLabel={"MQTT Enabled"}
            checked={deviceType.isMqttEnabled()}
            onChange={checked => updateMqtt(checked)}
          />
        </React.Fragment>
      )}
      {children}
    </div>
  );
});

export default ConnectivityView;
