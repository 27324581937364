import { createActions } from "@base";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { onMount as fetchDashboards } from "@modules/dashboards/actions";
import addPersistedModuleId from "../main/actions/addPersistedModuleId";
import { DASHBOARDS_MODULE_ID } from "@modules/portlet/constants";

export const {
  dashboardId: setDashboardId,
  initialized: setInitialized,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

export const onMount = (id: number) => (dispatch: any) => {
  // We depend on the child dashboards portlet module to send a message to portal to
  // populate the redux store with the user's dashboards because there is no direct API
  // available for this action and the only way is to ensure that the iframe is mounted, which
  // would be a no-op here if the module has already been lazily loaded.
  dispatch(addPersistedModuleId(DASHBOARDS_MODULE_ID));
  dispatch(setDashboardId(id));
  return dispatch(fetchDashboards());
};
