import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const filterButton = () =>
  createStyles({
    container: {
      width: 40,
      height: 40,
    },
    icon: {
      color: Color.SIGNIFY_GREEN,
      width: 30,
      height: 30,
    },
    badge: {
    },
  });

export const styles = () =>
  createStyles({
    container: {
    },
    openButton: {
    },
    popover: {
    },
  });

export default styles;
