import React from "react";
import classnames from "classnames";
import { KeyValuePair } from "@data";
import MetadataListColumns from "./MetadataListColumns";
import { PaginatedList, PaginatedListActions, PaginatedListModel } from "@components";
import MetadataListItem from "./MetadataListItem";
import { styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export const DEFAULT_METADATA_LIST_COLUMNS: MetadataListColumns[] = [
  MetadataListColumns.KEY,
  MetadataListColumns.VALUE,
];

export type MetadataTableModel = PaginatedListModel<KeyValuePair>;

export type MetadataTableActions = PaginatedListActions<KeyValuePair>;

type Props = WithStyles<typeof styles> & MetadataTableModel & MetadataTableActions;

export const MetadataTable = withStyles(styles)((props: Props) => {

  const {
    classes,
    className = "metadataTable",
    columns = DEFAULT_METADATA_LIST_COLUMNS,
    noResultsLabel = "No metadata defined",
    ...otherProps
  } = props;

  return (
    <PaginatedList
      {...otherProps}
      className={classnames("metadataTable", className, classes.container)}
      columns={columns}
      noResultsLabel={noResultsLabel}
      renderItem={(item: KeyValuePair, column: MetadataListColumns) => (
        <MetadataListItem
          keyValue={item}
          column={column}
        />
      )}
    />
  );
  });

export default MetadataTable;
