import React from "react";
import AppSchema from "@schemas";
import { connect } from "react-redux";
import { ManagedPolicy, Policy } from "@data";
import EffectivePoliciesView from "./EffectivePoliciesView";

interface Model {
}

interface Actions {
  showPolicyDetails?: (policy: Policy) => void;
  showManagedPolicyDetails?: (policy: ManagedPolicy) => void;
}

type Props = Model & Actions;

const PoliciesView = (props: Props) => {

  const {
    showPolicyDetails,
    showManagedPolicyDetails,
  } = props;

  return (
    <EffectivePoliciesView
      className="policies"
      showPolicyDetails={showPolicyDetails}
      showManagedPolicyDetails={showManagedPolicyDetails}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(PoliciesView);
