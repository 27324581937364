import React from "react";
import classnames from "classnames";
import TextField from "@components/text-field";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { userInfo as styles } from "@modules/createUser/components/styles";
import { clickHandler, formEventHandler, noop } from "@util";
import HidePasswordIcon from "@material-ui/icons/VisibilityOff";
import ShowPasswordIcon from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TooltipIcon } from "@components";
import { Vector } from "@data";
import { textField } from "./styles";
import RandomPasswordIcon from "@material-ui/icons/Autorenew";

const PasswordField = withStyles(textField)(TextField);

export interface  Model {
  password?: string;
  showPassword?: boolean;
  showPasswordTooltip?: string;
  hidePasswordTooltip?: string;
  children?: React.ReactNode;
}

export interface Actions {
  setPassword?: (password: string) => void;
  setShowPassword?: (showPassword: boolean) => void;
  generateRandomPassword?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const PasswordInfo = withStyles(styles)((props: Props) => {

  const {
    classes,
    password = "",
    showPassword = false,
    children,
    setPassword = noop,
    setShowPassword = noop,
    generateRandomPassword = noop,
  } = props;

  const showHidePasswordTooltip = showPassword ? "Hide Password" : "Show Password";

  const ShowHidePasswordIcon = React.useMemo(() => {
    return showPassword ? HidePasswordIcon : ShowPasswordIcon;
  }, [showPassword]);

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const formHelperTextProps = {
    className: classnames("formHelperText", classes.formHelperText),
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputProps = {
    className: classnames("textField", classes.textField),
    spellCheck: false,
  };

  return (
    <div className={classnames("passwordInfo", classes.container)}>
      <PasswordField
        className={classnames("password", classes.textField, classes.password)}
        type={showPassword ? "text" : "password"}
        variant="outlined"
        autoComplete="off"
        margin="none"
        fullWidth={true}
        label="Password"
        name="password"
        value={password}
        placeholder="User Password"
        onChange={formEventHandler(setPassword)}
        FormHelperTextProps={formHelperTextProps}
        helperText="Password must be at least 32 characters"
        InputLabelProps={inputLabelProps}
        InputProps={{
          inputProps,
          endAdornment: (
            <InputAdornment position="end">
              <div className={classnames("passwordControls", classes.passwordControls)}>
                <TooltipIcon
                  className={classnames(classes.showHidePasswordButton, {
                    "hidePasswordButton": showPassword,
                    "showPasswordButton": !showPassword,
                  })}
                  PopperProps={{
                    className: classnames("tooltipIconPopper", classes.tooltipIconPopper),
                  }}
                  onClick={clickHandler(() => setShowPassword(!showPassword))}
                  tooltipText={showHidePasswordTooltip}
                  icon={ShowHidePasswordIcon}
                  size={Vector.square(24)}
                  disableTouchRipple={false}
                />
                <TooltipIcon
                  className={classnames("randomPasswordButton", classes.randomPasswordButton)}
                  PopperProps={{
                    className: classnames("tooltipIconPopper", classes.tooltipIconPopper),
                  }}
                  onClick={clickHandler(generateRandomPassword)}
                  tooltipText="Generate random password"
                  icon={RandomPasswordIcon}
                  size={Vector.square(24)}
                  disableTouchRipple={false}
                />
              </div>
            </InputAdornment>
          ),
        }}
      />
      {children}
    </div>
  );
});

export default PasswordInfo;
