import React from "react";
import {
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel,
} from "@components/confirmation-dialog";
import { PromoteView } from "./PromoteView";
import { DeprecateView } from "./DeprecateView";
import { DecommissionView } from "./DecommissionView";
import { DeleteView } from "./DeleteView";
import { DraftView } from "./DraftView";
import { DeviceTypeListItem } from "@data";
import { DeviceTypeAction } from "../reducers";
import { AlertDialog, ErrorView } from "@components";

export interface Model extends ConfirmationDialogModel<DeviceTypeListItem> {
  deviceTypeAction?: DeviceTypeAction;
}

export type Actions = ConfirmationDialogActions<DeviceTypeListItem>;

export const DeviceTypeManager = (props: Model & Actions) => {

  const { deviceTypeAction = DeviceTypeAction.NONE, ...remainingProps } = props;

  const {
    item: deviceType = DeviceTypeListItem.EMPTY,
    open = false,
    successMessage = "",
    cancel,
  } = remainingProps;

  if (!open) {
    return null;
  }

  const deviceTypeId = deviceType.getTypeIdentity();

  const etag = deviceType.etag || "";

  const isMissingEtag = etag.length === 0;

  if ((isMissingEtag || deviceTypeId.length === 0) && successMessage.length === 0) {
    return (
      <AlertDialog open={true} title="Error" close={cancel}>
        {!isMissingEtag && <ErrorView message="Invalid Device Type" />}
        {isMissingEtag && (
          <ErrorView
            title="Invalid Device Type"
            message={"Missing etag. Please try again from the Device Type details page."}
          />
        )}
      </AlertDialog>
    );
  }

  if (deviceTypeAction === DeviceTypeAction.DELETE) {
    return (
      <ConfirmationDialog { ...remainingProps }>
        <DeleteView { ...remainingProps } />
      </ConfirmationDialog>
    );
  }

  if (deviceTypeAction === DeviceTypeAction.PROMOTE) {
    return (
      <ConfirmationDialog { ...remainingProps }>
        <PromoteView { ...remainingProps } />
      </ConfirmationDialog>
    );
  }

  if (deviceTypeAction === DeviceTypeAction.DECOMMISSION) {
    return (
      <ConfirmationDialog { ...remainingProps }>
        <DecommissionView { ...remainingProps } />
      </ConfirmationDialog>
    );
  }

  if (deviceTypeAction === DeviceTypeAction.DEPRECATE) {
    return (
      <ConfirmationDialog { ...remainingProps }>
        <DeprecateView { ...remainingProps } />
      </ConfirmationDialog>
    );
  }

  if (deviceTypeAction === DeviceTypeAction.DRAFT) {
    return (
      <ConfirmationDialog { ...remainingProps }>
        <DraftView { ...remainingProps } />
      </ConfirmationDialog>
    );
  }

  return (
    <AlertDialog open={true} title="Invalid Device Type Action" close={cancel}>
      <ErrorView message={`Unrecognized device type action [${deviceTypeAction}] cannot be executed.`} />
    </AlertDialog>
  );
};

export default DeviceTypeManager;
