import { connect } from "react-redux";
import {
  DefaultValueEditor,
  Model,
  Actions,
  DefaultValueTypes,
} from "../components/DefaultValueEditor";
import { PropertyEditorSelectors } from "../selectors/propertyEditor";
import { PropertyEditorActions } from "../actions/propertyEditor";
import { AppSchema } from "../../main/schemas";

const mapStateToProps = (state: AppSchema): Model => ({
  className: PropertyEditorSelectors.getDefaultValueEditorClassName(state),
  propertyType: PropertyEditorSelectors.getType(state),
  defaultValue: PropertyEditorSelectors.getDefaultValue(state),
  defaultValueError: PropertyEditorSelectors.getDefaultValueError(state),
  enumValues: PropertyEditorSelectors.getEnumValues(state),
  disabled: PropertyEditorSelectors.isProgressIndicatorVisible(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  setDefaultValue: (defaultValue: DefaultValueTypes) =>
    dispatch(PropertyEditorActions.setDefaultValue(defaultValue)),
  clearDefaultValueError: () =>
    dispatch(PropertyEditorActions.clearDefaultValueError()),
  save: () => dispatch(PropertyEditorActions.save()),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(DefaultValueEditor);
