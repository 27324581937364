import React from "react";
import { Policy } from "@data";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { usePolicies } from "@hooks";
import { open as openDeletePolicyDialog } from "@modules/deletePolicy/actions";
import { getSuccessMessage as getDeleteSuccessMessage } from "@modules/deletePolicy/selectors";
import Policies, { Actions, Model } from "../components/Policies";

interface ContainerModel extends Model {
  isDeleteSuccess?: boolean;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const PoliciesContainer = (props: Props) => {

  const { isDeleteSuccess, ...otherProps } = props;

  const [ model, actions] = usePolicies();

  const { policies } = model;

  const { refresh } = actions;

  React.useEffect(() => {
    if (isDeleteSuccess) {
      refresh();
    }
  }, [isDeleteSuccess, refresh]);

  return (
    <Policies
      {...otherProps}
      {...model}
      {...actions}
      items={policies}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  isDeleteSuccess: getDeleteSuccessMessage(state).length > 0,
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  deletePolicy: (policy: Policy) => dispatch(openDeletePolicyDialog(policy.getName())),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(PoliciesContainer);
