import { JsonSchemaMetadata } from "../../data";
import { Comparator, compare } from "../../util";
import SchemasListColumn from "./SchemasListColumn";

const compareByUpdatedAt = (item: JsonSchemaMetadata, otherItem: JsonSchemaMetadata, ascending: boolean) =>
  compare(item.updatedAt, otherItem.updatedAt, ascending);

const compareByState = (item: JsonSchemaMetadata, otherItem: JsonSchemaMetadata, ascending: boolean) =>
  compare(item.state, otherItem.state, ascending);

const compareByVersion = (item: JsonSchemaMetadata, otherItem: JsonSchemaMetadata, ascending: boolean) =>
  compare(item.version, otherItem.version, ascending);

const compareByName = (item: JsonSchemaMetadata, otherItem: JsonSchemaMetadata, ascending: boolean) => {
  const result = compare(item.name, otherItem.name, ascending);
  return result !== 0 ? result : compareByVersion(item, otherItem, ascending);
};

const compareByNamespace = (item: JsonSchemaMetadata, otherItem: JsonSchemaMetadata, ascending: boolean) => {
  const result = compare(item.namespace, otherItem.namespace, ascending);
  return result !== 0 ? result : compareByName(item, otherItem, ascending);
};

export const createComparator = (sortedColumn: SchemasListColumn,
                                 ascending: boolean): Comparator<JsonSchemaMetadata> =>

  (item: JsonSchemaMetadata, otherItem: JsonSchemaMetadata) => {
    switch (sortedColumn) {
      case SchemasListColumn.NAMESPACE:
        return compareByNamespace(item, otherItem, ascending);
      case SchemasListColumn.NAME:
        return compareByName(item, otherItem, ascending);
      case SchemasListColumn.VERSION:
        return compareByVersion(item, otherItem, ascending);
      case SchemasListColumn.STATE:
        return compareByState(item, otherItem, ascending);
      case SchemasListColumn.LAST_UPDATED:
        return compareByUpdatedAt(item, otherItem, ascending);
      default:
        return 0;
    }
  };

export default createComparator;
