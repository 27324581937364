import { createSelector } from "reselect";
import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { createSelectors, Selector } from "@modules/base/createSelectors";
import { DEFAULT_STATE, DeviceValidationDetailsSchema } from "./reducers";
import { DeviceDataModel, DeviceDataModelAttributes } from "@data";

export const {
  deviceId: getDeviceId,
  deviceRequest: getDeviceRequestAttributes,
} = createSelectors<DeviceValidationDetailsSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getDeviceRequest: Selector<DeviceDataModel> = createSelector(
  getDeviceRequestAttributes, (attrs: DeviceDataModelAttributes) =>
    new DeviceDataModel(attrs));
