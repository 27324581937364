import React from "react";
import classnames from "classnames";
import SuccessIcon from "@material-ui/icons/InfoOutlined";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";

export const SuccessView = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  title?: string,
  message?: string,
}) => {

  const {
    classes,
    className,
    title = "Success",
    message = "",
  } = props;

  if (!message || message.trim().length === 0) {
    return null;
  }

  return (
    <div className={classnames("successView", className, classes.container)}>
      <SuccessIcon className={classnames("icon", classes.icon)} color="inherit" />
      <label className={classnames("title", classes.title)}>{title}</label>
      <label className={classnames("message", classes.message)}>{message}</label>
    </div>
  );
});

export default SuccessView;
