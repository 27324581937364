import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { DeviceTypeDetailsSelectors } from "../selectors";
import SchemasList from "./SchemasList";
import { JsonSchemaMetadata } from "@data/JsonSchemaMetadata";
import { noop } from "@util";
import { SummaryViewData } from "@data";
import { SummaryView, ChipView } from "@components";
import TagIcon from "@material-ui/icons/Label";

const mapAttributesToSummaryValue = (attrs: any): string => {

  // Only serialize the Objects if there are attributes to render
  if (typeof attrs !== "object" || Object.keys(attrs).length === 0) {
    return "";
  }

  return JSON.stringify(attrs, null, "  ");
};

interface ContainerModel {
  showTags?: boolean;
  tags?: string[];
  showSchemasTable?: boolean;
  accountId?: string;
  namespace?: string;
  name?: string;
  version?: string;
  description?: string;
  state?: string;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
  etag?: string;
  metadata?: string;
  certificates?: string;
  connectivity?: string;
  data?: string;
  properties?: string;
  software?: string;
  security?: string;
  children?: React.ReactNode;
}

interface ContainerActions {
  showSchemaDetails?: (schema: JsonSchemaMetadata) => void;
  showSchemaDetailsInNewTab?: (schema: JsonSchemaMetadata) => void;
}

const SummaryViewContainer = (props: ContainerModel & ContainerActions) => {

  const {
    showTags,
    showSchemasTable,
    showSchemaDetails = noop,
    showSchemaDetailsInNewTab = noop,
    namespace,
    name,
    version,
    description,
    state,
    accountId,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    etag,
    metadata,
    certificates,
    connectivity,
    data,
    properties,
    software,
    security,
    tags,
    ...otherProps
  } = props;

  const summaryViewItems = React.useMemo(() => [
    new SummaryViewData({
      className: "namespace",
      name: "Namespace",
      value: namespace,
    }),
    new SummaryViewData({
      className: "name",
      name: "Name",
      value: name,
    }),
    new SummaryViewData({
      className: "version",
      name: "Version",
      value: version,
    }),
    new SummaryViewData({
      className: "state",
      name: "State",
      value: state,
    }),
    new SummaryViewData({
      className: "accountId",
      name: "Account ID",
      value: accountId,
    }),
    new SummaryViewData({
      className: "createdBy",
      name: "Created By",
      value: createdBy,
    }),
    new SummaryViewData({
      className: "updatedBy",
      name: "Updated By",
      value: updatedBy,
    }),
    new SummaryViewData({
      className: "updatedAt",
      name: "Updated At",
      value: updatedAt,
    }),
    new SummaryViewData({
      className: "etag",
      name: "Etag",
      value: etag,
    }),
    new SummaryViewData({
      className: "metadata",
      name: "Metadata",
      value: metadata,
    }),
    new SummaryViewData({
      className: "certificates",
      name: "Certificates",
      value: certificates,
    }),
    new SummaryViewData({
      className: "connectivity",
      name: "Connectivity",
      value: connectivity,
    }),
    new SummaryViewData({
      className: "data",
      name: "Data",
      value: data,
    }),
    new SummaryViewData({
      className: "properties",
      name: "Properties",
      value: properties,
    }),
    new SummaryViewData({
      className: "software",
      name: "Software",
      value: software,
    }),
    new SummaryViewData({
      className: "security",
      name: "Security",
      value: security,
    }),
  ], [
    namespace,
    name,
    version,
    description,
    state,
    accountId,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    etag,
    metadata,
    certificates,
    connectivity,
    data,
    properties,
    software,
    security
  ]);

  return (
    <SummaryView
      {...otherProps}
      items={summaryViewItems}
    >
      <ChipView
        title={"Tags"}
        className={"tag"}
        icon={TagIcon}
        marginBottom={true}
        items={tags}
      />
      {showSchemasTable && (
        <SchemasList
          showSchemaDetails={showSchemaDetails}
          showSchemaDetailsInNewTab={showSchemaDetailsInNewTab}
        />
      )}
    </SummaryView>
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => {

  const isLoading = DeviceTypeDetailsSelectors.isLoadingIndicatorVisible(state);
  const deviceTypeFailedToLoad = DeviceTypeDetailsSelectors.didDeviceTypeFailToLoad(state);
  const namespace = DeviceTypeDetailsSelectors.getDeviceTypeNamespace(state);
  const name = DeviceTypeDetailsSelectors.getDeviceTypeName(state);
  const version = DeviceTypeDetailsSelectors.getDeviceTypeVersion(state);

  // If the page is loading - or the device type failed to load - only show
  // the bare minimum data instead of potentially stale - or incorrect - data
  if (isLoading || deviceTypeFailedToLoad) {
    return {
      namespace,
      name,
      version,
    };
  }

  const isHistoricalApiModel = DeviceTypeDetailsSelectors.isHistoricalApiModel(state);

  if (isHistoricalApiModel) {

    const legacyDeviceType = DeviceTypeDetailsSelectors.getDeviceTypeV2(state);

    // We render tags in another view, so don't duplicate the data
    const { metadata: { tags, ...metadata } = { tags: [] } } = legacyDeviceType.toJS();

    return {
      namespace,
      name,
      version,
      showTags: !isLoading,
      showSchemasTable: DeviceTypeDetailsSelectors.isSchemaTableVisible(state),
      description: legacyDeviceType.getDescription(),
      state: legacyDeviceType.getState(),
      etag: DeviceTypeDetailsSelectors.getEtag(state),
      createdAt: legacyDeviceType.getCreatedAt(),
      updatedAt: legacyDeviceType.getUpdatedAt(),
      createdBy: legacyDeviceType.getCreatedBy(),
      updatedBy: legacyDeviceType.getUpdatedBy(),
      metadata: mapAttributesToSummaryValue(metadata),
      tags: DeviceTypeDetailsSelectors.getDeviceTypeTags(state),
    };
  }

  const deviceType = DeviceTypeDetailsSelectors.getDeviceTypeV3(state);

  const {
    certificates = {},
    connectivity = {},
    data = {},
    properties = {},
    software = {},
    security = {},
  } = deviceType.toJS();

  return {
    namespace,
    name,
    version,
    showTags: !isLoading,
    showSchemasTable: DeviceTypeDetailsSelectors.isSchemaTableVisible(state),
    description: deviceType.getDescription(),
    state: deviceType.state,
    etag: DeviceTypeDetailsSelectors.getEtag(state),
    certificates: mapAttributesToSummaryValue(certificates),
    connectivity: mapAttributesToSummaryValue(connectivity),
    data: mapAttributesToSummaryValue(data),
    properties: mapAttributesToSummaryValue(properties),
    software: mapAttributesToSummaryValue(software),
    security: mapAttributesToSummaryValue(security),
    tags: DeviceTypeDetailsSelectors.getDeviceTypeTags(state),
  };
};

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
)(SummaryViewContainer);
