import React from "react";
import { Module } from "@data";
import { MODULE_ID } from "./constants";
import { equalsIgnoreCase } from "@util";
import { isDialogOpen } from "./selectors";
import { initialize, open } from "./actions";
import { PrimaryIcon } from "@modules/users/components/styles";
import DeleteDataSetRequestDialog from "./containers/DeleteDataSetRequestDialog";

export const deleteDataSetRequestModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "Cancel Data Set Request",
  title: "Cancel Data Set Request",
  icon: PrimaryIcon,
  view: <DeleteDataSetRequestDialog />,
  onClick: open,
  isDialogOpen,
  initialize,
  disabled: !equalsIgnoreCase("true", process.env.REACT_APP_DATA_GOVERNANCE_MODULES_ENABLED),
};

export default deleteDataSetRequestModule;
