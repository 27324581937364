import React from "react";
import classnames from "classnames";
import { noop } from "@util";
import { ActionMenuItem, ActionsMenu, ActionsMenuProps } from "../actions-menu";

export enum DataAccessRequestAction {
  NONE = "",
  DELETE = "delete_data_access_request",
}

export const DELETE_DATA_ACCESS_REQUEST_ACTION: ActionMenuItem = Object.freeze({
  id: DataAccessRequestAction.DELETE,
  name: "Cancel Request",
  disabled: false,
  hidden: false,
});

export const DEFAULT_DATA_ACCESS_REQUEST_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  DELETE_DATA_ACCESS_REQUEST_ACTION,
]) as ActionMenuItem[];

export interface DataAccessRequestActionHandlers {
  deleteDataAccessRequest?: () => void;
}

export const DataAccessRequestActionsMenu = (props: DataAccessRequestActionHandlers & ActionsMenuProps) => {

  const {
    className,
    buttonLabel = "Data Access Request Actions",
    actions = DEFAULT_DATA_ACCESS_REQUEST_ACTION_MENU_ITEMS,
    onClickAction = noop,
    deleteDataAccessRequest = noop,
    ...otherProps
  } = props;

  return (
    <ActionsMenu
      {...otherProps}
      className={classnames("dataAccessRequestActionsMenu", className)}
      actions={actions}
      buttonLabel={buttonLabel}
      onClickAction={(action: ActionMenuItem) => {
        switch (action.id) {
          case DataAccessRequestAction.DELETE:
            return deleteDataAccessRequest();
          default:
            return onClickAction(action);
        }
      }}
    />
  );
};

export default DataAccessRequestActionsMenu;
