// *************************************************************************************************
// LOCAL STORAGE KEYS
// *************************************************************************************************

// Legacy key used in v27 and below
export const KEY_APP_STATE = "com.signify.sst.portal.store.APP_STATE";

export const KEY_VERSION_CODE = "com.signify.sst.portal.store.VERSION_CODE";

export const KEY_SAVED_USERNAME = "com.signify.sst.portal.store.SAVED_USERNAME";
export const KEY_SAVED_ACCOUNT = "com.signify.sst.portal.store.SAVED_ACCOUNT";

export const KEY_SAVED_SERVICE_ID = "com.signify.sst.portal.store.SAVED_SERVICE_ID";
export const KEY_SAVED_SERVICE_ACCOUNT_ID = "com.signify.sst.portal.store.SAVED_SERVICE_ACCOUNT_ID";

export const KEY_LAST_MOUSE_MOVE = "com.signify.sst.portal.store.LAST_MOUSE_MOVE";
export const KEY_ACCOUNT_ID = "com.signify.sst.portal.store.ACCOUNT_ID";
export const KEY_PRINCIPAL_ID = "com.signify.sst.portal.store.PRINCIPAL_ID";
export const KEY_IDENTITY_TYPE = "com.signify.sst.portal.store.IDENTITY_TYPE";
export const KEY_ACCESS_TOKEN_EXPIRY_TIME = "com.signify.sst.portal.store.ACCESS_TOKEN_EXPIRY_TIME";

export const KEY_FAVORITES = "com.signify.sst.portal.store.FAVORITES";

export const KEY_CLOSED_MAINTENANCE_REMINDERS = "com.signify.sst.portal.store.CLOSED_MAINTENANCE_REMINDERS";

// *************************************************************************************************
// SESSION STORAGE KEYS
// *************************************************************************************************

export const KEY_ACCESS_TOKEN = "com.signify.sst.portal.store.ACCESS_TOKEN";
export const KEY_REFRESH_TOKEN = "com.signify.sst.portal.store.REFRESH_TOKEN";
export const KEY_NONCE = "com.signify.sst.portal.store.NONCE";
export const KEY_LAST_IDENTITY_TYPE = "com.signify.sst.portal.store.LAST_IDENTITY_TYPE";

// *************************************************************************************************
// BROADCAST CHANNELS
// *************************************************************************************************

export const BROADCAST_CHANNEL = "PORTAL";
export const BROADCAST_CHANNEL_SERVICE = `${BROADCAST_CHANNEL}:SERVICE`;
export const BROADCAST_CHANNEL_SERVICE_RESTORE_SESSION = `${BROADCAST_CHANNEL_SERVICE}:RESTORE_SESSION`;
export const BROADCAST_CHANNEL_END_SESSION = `${BROADCAST_CHANNEL}:END_SESSION`;

// *************************************************************************************************
// BROADCAST ACTIONS
// *************************************************************************************************

export const BROADCAST_ACTION_PING = "PING";
export const BROADCAST_ACTION_LOGOUT = "LOGOUT";
