import React from "react";
import { ProfileAttributeDefinition } from "@data";
import { GetProfileAttributeDefinitionResponse } from "@network";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  UseApiRequestActions,
  useProfileAttributeClient,
} from "@hooks";

type SuccessResponse = GetProfileAttributeDefinitionResponse;

export interface UseProfileAttributeDefinitionsProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  accountId: string;
}

export interface UseProfileAttributeDefinitionsModel extends UseApiRequestModel<SuccessResponse> {
  attributes: ProfileAttributeDefinition[];
}

export interface UseProfileAttributeDefinitionsActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseProfileAttributeDefinitionsProps;
type Model = UseProfileAttributeDefinitionsModel;
type Actions = UseProfileAttributeDefinitionsActions;
type Result = [Model, Actions];

export const useProfileAttributeDefinitions = (props: Props): Result => {

  const {
    accountId = "",
    defaultErrorMessage = "Failed to get profile attribute definitions",
    ...otherProps
  } = props;

  const ProfileAttributeClient = useProfileAttributeClient();

  const makeApiRequest = React.useCallback(() =>
    ProfileAttributeClient.getProfileAttributeDefinitions(accountId),
    [ProfileAttributeClient, accountId]);

  const [{ successResponse, ...baseModel }, { ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const attributes = React.useMemo(() => {
    const items = successResponse?.attributes || [];
    return items.map((attrs) => new ProfileAttributeDefinition(attrs));
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    attributes,
  }), [
    baseModel,
    attributes,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
  }), [
    baseActions
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useProfileAttributeDefinitions;
