import React from "react";
import { useLocation } from "react-router-dom";
import { equalsIgnoreCase } from "@util";
import { DeviceDetailsTabsType } from "@modules/deviceDetails/components/DeviceDetailsTabs";

export interface DeviceDetailsQueryParams {
  selectedTab?: DeviceDetailsTabsType | null;
}

export const useDeviceDetailsQueryParams = (): DeviceDetailsQueryParams => {

  const { pathname } = useLocation();

  const view = pathname.split("/")[3];

  const selectedTab = React.useMemo(() => {
    if (equalsIgnoreCase(view, DeviceDetailsTabsType.ENROLLMENT)) {
      return DeviceDetailsTabsType.ENROLLMENT;
    } else if (equalsIgnoreCase(view, DeviceDetailsTabsType.CONFIGURATION)) {
      return DeviceDetailsTabsType.CONFIGURATION;
    } else if (equalsIgnoreCase(view, DeviceDetailsTabsType.BOOTSTRAP)) {
      return DeviceDetailsTabsType.BOOTSTRAP;
    } else if (equalsIgnoreCase(view, DeviceDetailsTabsType.VALIDATION)) {
      return DeviceDetailsTabsType.VALIDATION;
    } else {
      return DeviceDetailsTabsType.ENROLLMENT;
    }
  }, [view]);

  return React.useMemo(() => ({
    selectedTab,
  }), [
    selectedTab,
  ]);
};

export default useDeviceDetailsQueryParams;
