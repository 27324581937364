import React from "react";
import { GetQueryDatabasesResponse } from "@network";
import { useQueryDatabasesManagerClient } from "@hooks";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, UseApiRequestActions } from "@hooks";

type SuccessResponse = GetQueryDatabasesResponse[];

export interface UseGetQueryDatabasesProps extends Partial<UseApiRequestProps<SuccessResponse>> {
}

export interface UseGetQueryDatabasesModel extends UseApiRequestModel<SuccessResponse> {
  databases?: string[];
}

export interface UseGetQueryDatabasesActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetQueryDatabasesProps;
type Model = UseGetQueryDatabasesModel;
type Actions = UseGetQueryDatabasesActions;
type Result = [Model, Actions];

export const useGetQueryDatabases = (props: Props): Result => {

  const {
    defaultErrorMessage = "Failed to get query databases",
    ...otherProps
  } = props;

  const QueryDatabasesClient = useQueryDatabasesManagerClient();

  const makeApiRequest = React.useCallback(() => QueryDatabasesClient.getQueryDatabases(),
    [QueryDatabasesClient]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      deferRequest: true,
      defaultErrorMessage,
      makeApiRequest,
    });

  const databases = React.useMemo<string[]>(() => {
    const attrs = successResponse ? successResponse : [];
    return attrs.map(({ databaseName }) => databaseName);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    databases,
  }), [
    baseModel,
    databases,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetQueryDatabases;
