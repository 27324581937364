import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";
import { User } from "@data";

export interface ExpireUserPasswordSchema extends BasePortalModuleSchema {
  user: User;
  expirePasswordDialog: boolean;
}

export const DEFAULT_STATE: ExpireUserPasswordSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  expirePasswordDialog: false,
  user: User.EMPTY,
});

export type ExpireUserPasswordActionTypes = PortalModuleActionTypes<ExpireUserPasswordSchema> & {
  EXPIRE_PASSWORD_REQUEST: string;
  EXPIRE_PASSWORD_SUCCESS: string;
  EXPIRE_PASSWORD_FAILED: string;
};

export type ExpireUserPasswordActionType = ExpireUserPasswordActionTypes[keyof ExpireUserPasswordActionTypes];

export const ACTION_TYPES: ExpireUserPasswordActionTypes = {
  ...createPortalModuleActionTypes<ExpireUserPasswordSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    EXPIRE_PASSWORD_REQUEST: `${MODULE_ID}/expire_password_request`,
    EXPIRE_PASSWORD_SUCCESS: `${MODULE_ID}/expire_password_success`,
    EXPIRE_PASSWORD_FAILED: `${MODULE_ID}/expire_password_failed`,
  },
};

export const ExpireUserPasswordReducer = combineReducers<ExpireUserPasswordSchema>(
  createPortalModuleReducers<ExpireUserPasswordSchema>(ACTION_TYPES, DEFAULT_STATE));

export default ExpireUserPasswordReducer;
