import { createSelector } from "reselect";
import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { createSelectors, Selector } from "@modules/base/createSelectors";
import {
  DEFAULT_STATE,
  FederationProviderDetailsSchema,
} from "./reducers";
import { FederationProvider, FederationProviderAttributes, SummaryViewData } from "@data";
import { ActionMenuItem } from "@components/actions-menu";
import {
  DELETE_FEDERATION_PROVIDER_ACTION,
  EDIT_FEDERATION_PROVIDER_ACTION,
} from "@components/federation-provider-action-menu";

export const {
  federationProvider: getFederationProviderAttributes,
  errorMessage: getErrorMessage,
  successMessage: getSuccessMessage,
  showEmptyView: isEmptyViewVisible,
  showAccessDenied: isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  isNotFoundVisible,
} = createSelectors<FederationProviderDetailsSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getFederationProvider: Selector<FederationProvider> = createSelector(
  getFederationProviderAttributes, (attrs: FederationProviderAttributes) =>
    new FederationProvider(attrs));

export const getProviderId: Selector<string> = createSelector(
  getFederationProvider, (federationProvider: FederationProvider) => federationProvider.getProviderId());

export const getAccountId: Selector<string> = createSelector(
  getFederationProvider, (federationProvider: FederationProvider) => federationProvider.getAccountId());

export const getDomain: Selector<string> = createSelector(
  getFederationProvider, (federationProvider: FederationProvider) => federationProvider.getDomain());

export const getClientId: Selector<string> = createSelector(
  getFederationProvider, (federationProvider: FederationProvider) => federationProvider.getClientId());

export const getClientSecret: Selector<string> = createSelector(
  getFederationProvider, (federationProvider: FederationProvider) => {
    return federationProvider.clientSecret;
  });

export const getDiscoveryDocument: Selector<string> = createSelector(
  getFederationProvider, (federationProvider: FederationProvider) => {
    return federationProvider.discoveryDocument;
  });

export const getAuthorizationEndpoint: Selector<string> = createSelector(
  getFederationProvider, (federationProvider: FederationProvider) => {
    return federationProvider.authorizationEndpoint;
  });

export const getTokenEndpoint: Selector<string> = createSelector(
  getFederationProvider, (federationProvider: FederationProvider) => {
    return federationProvider.tokenEndpoint;
  });

export const getUserEndpoint: Selector<string> = createSelector(
  getFederationProvider, (federationProvider: FederationProvider) => {
    return federationProvider.userEndpoint;
  });

export const getJwksUri: Selector<string> = createSelector(
  getFederationProvider, (federationProvider: FederationProvider) => {
    return federationProvider.jwksUri;
  });

export const getIssuer: Selector<string> = createSelector(
  getFederationProvider, (federationProvider: FederationProvider) => {
    return federationProvider.issuer;
  });

export const isFederationClosed: Selector<boolean> = createSelector(
  getFederationProvider, (federationProvider: FederationProvider) => {
    return federationProvider.federationClosed;
  });

export const getScope: Selector<string[]> = createSelector(
  getFederationProvider, (federationProvider: FederationProvider) => {
    return federationProvider.scope.values;
  });

export const getAttributes: Selector<string> = createSelector(
  getFederationProvider, (federationProvider: FederationProvider) => {
    return JSON.stringify(federationProvider.attributes, null, "  ");
  });

export const getSummaryViewItems: Selector<SummaryViewData[]> = createSelector(
  [
    isLoadingIndicatorVisible,
    getAccountId,
    getProviderId,
    getDomain,
    getClientId,
    getClientSecret,
    getDiscoveryDocument,
    getAuthorizationEndpoint,
    getTokenEndpoint,
    getUserEndpoint,
    getJwksUri,
    getIssuer,
    isFederationClosed,
    getAttributes,
  ],
  (
    loading: boolean,
    accountId: string,
    providerId: string,
    domain: string,
    clientId: string,
    clientSecret: string,
    discoveryDocument: string,
    authorizationEndpoint: string,
    tokenEndpoint: string,
    userEndpoint: string,
    jwksUri: string,
    issuer: string,
    federationClosed: boolean,
    attributes: string,
  ) => loading ? [] : ([
    new SummaryViewData({
      className: "accountId",
      name: "Account ID",
      value: accountId,
    }),
    new SummaryViewData({
      className: "providerId",
      name: "Provider ID",
      value: providerId,
    }),
    new SummaryViewData({
      className: "domain",
      name: "Domain",
      value: domain,
    }),
    new SummaryViewData({
      className: "clientId",
      name: "Client ID",
      value: clientId,
    }),
    new SummaryViewData({
      className: "clientSecret",
      name: "Client Secret",
      value: clientSecret,
    }),
    new SummaryViewData({
      className: "discoveryDocument",
      name: "Discovery Document",
      value: discoveryDocument,
    }),
    new SummaryViewData({
      className: "authorizationEndpoint",
      name: "Authorization Endpoint",
      value: authorizationEndpoint,
    }),
    new SummaryViewData({
      className: "tokenEndpoint",
      name: "Token Endpoint",
      value: tokenEndpoint,
    }),
    new SummaryViewData({
      className: "userEndpoint",
      name: "User Endpoint",
      value: userEndpoint,
    }),
    new SummaryViewData({
      className: "jwksUri",
      name: "Jwks Uri",
      value: jwksUri,
    }),
    new SummaryViewData({
      className: "issuer",
      name: "Issuer",
      value: issuer,
    }),
    new SummaryViewData({
      className: "federationClosed",
      name: "Federation Closed",
      value: federationClosed + "",
    }),
    new SummaryViewData({
      className: "attributes",
      name: "Attributes",
      value: attributes,
    }),
  ]));

export const getFederationProviderAsJson: Selector<string> = createSelector(
  [isLoadingIndicatorVisible, getFederationProvider],
  (isLoading: boolean, federationProvider: FederationProvider) => {

    // If the page is loading, only show the bare minimum to avoid showing potentially stale data
    const data = isLoading ? DEFAULT_STATE.federationProvider : federationProvider.toJS();

    return JSON.stringify(data, null, "  ");
  });

export const getActionMenuItems: Selector<ActionMenuItem[]> = () => ([
  EDIT_FEDERATION_PROVIDER_ACTION,
  DELETE_FEDERATION_PROVIDER_ACTION
]);
