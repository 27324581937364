import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { isEmptyString, noop } from "@util";
import {
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel,
  usePortalSnackbar,
} from "@components";
import { styles } from "./styles";
import { User } from "@data";

export interface ExpireUserPasswordDialogModel extends ConfirmationDialogModel<string> {
  user?: User;
  loadingMessage?: string;
  closeOnSuccessDelay?: number;
  proceedToLogout?: boolean;
}

export interface ExpireUserPasswordDialogActions extends ConfirmationDialogActions<string>  {
  onSuccessMessageShown?: () => void;
}

type Props = WithStyles<typeof styles> &
  ExpireUserPasswordDialogModel & ExpireUserPasswordDialogActions;

export const ExpireUserPasswordDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    user = User.EMPTY,
    loading,
    successMessage,
    errorMessage,
    loadingMessage = "Expiring User Password...",
    closeOnSuccessDelay = 500,
    onSuccessMessageShown,
    proceedToLogout = false,
    continueButtonLabel = !proceedToLogout ? "Expire User Password" : "Continue to Logout",
    title = !proceedToLogout ? "Expire User Password" : "Proceed to Logout",
    ...otherProps
  } = props;

  const {
    open,
    cancel: closeDialog = noop,
    continueButtonDisabled = loading,
  } = otherProps;

  const message = React.useMemo(() => {
    if (loading) {
      return (
        <label className={classnames("label", classes.subtitle)}>
          {loadingMessage}
        </label>
      );
    } else if (!isEmptyString(successMessage)) {
      return (
        <label className={classnames("label", classes.subtitle)}>
          {successMessage}
        </label>
      );
    } else {
      return (
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Are you sure you want to expire this user's password?
          </label>
          <label className={classnames("subtitle", classes.subtitle)}>
            <span className={classes.warning}>WARNING:</span>This action cannot be undone!
          </label>
          <label className={classnames("userId", classes.userId)}>
            {user.getUserId()}
          </label>
        </React.Fragment>
      );
    }
  }, [
    loading,
    loadingMessage,
    successMessage,
    user,
  ]);

  usePortalSnackbar("expire-user-password-attributes", {
    errorMessage,
    successMessage,
    onSuccessMessageShown: onSuccessMessageShown || closeDialog,
    successCallbackDelay: closeOnSuccessDelay,
  });

  if (!open) {
    return null;
  }

  return (
    <ConfirmationDialog
      {...otherProps}
      className={classnames("expireUserPasswordDialog", className)}
      title={title}
      loading={loading}
      errorMessage={errorMessage}
      successMessage={successMessage}
      continueButtonLabel={continueButtonLabel}
      continueButtonDisabled={continueButtonDisabled}
    >
      {!errorMessage && (
        <div className={classes.container}>
          {message}
        </div>
      )}
      {errorMessage && proceedToLogout && (
        <div className={classes.container}>
          <React.Fragment>
            <label className={classnames("title", classes.title)}>
              Federated users password cannot be expired!
            </label>
            <label className={classnames("subtitle", classes.subtitle)}>
              Would you like to continue to end this user session instead?
            </label>
            <label className={classnames("userId", classes.userId)}>
              {user.getUserId()}
            </label>
          </React.Fragment>
        </div>
      )}
    </ConfirmationDialog>
  );
});

export default ExpireUserPasswordDialog;
