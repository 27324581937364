import React from "react";
import { DataSetRequest } from "@data";
import { useHistory, useParams } from "react-router-dom";
import DataSetRequestDetails from "./containers/DataSetRequestDetails";
import {
  getCreateDataSetRequestPath,
  getEditDataSetRequestPath,
} from "@modules/dataSetRequestWizard/helpers";

export function DataSetRequestDetailsView() {

  const history = useHistory();

  const { id: dataSetRequestId } = useParams<{ id: string }>();

  const editDataSetRequest = React.useCallback(() =>
      history.push(getEditDataSetRequestPath(dataSetRequestId), { goBackOnSuccess: true}),
    [history, dataSetRequestId]);

  const openCloneDataSetRequestWizard = React.useCallback((dataSetRequest: DataSetRequest) => {
    history.push(getCreateDataSetRequestPath(), {
      dataSetRequest: dataSetRequest.toJS(),
    });
  }, [history, dataSetRequestId]);

  return (
    <DataSetRequestDetails
      key={dataSetRequestId}
      dataSetRequestId={dataSetRequestId}
      editDataSetRequest={editDataSetRequest}
      openCloneDataSetRequestWizard={openCloneDataSetRequestWizard}
    />
  );
}

export default DataSetRequestDetailsView;
