import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      minWidth: 100,
      alignSelf: "flex-start",
      fontSize: 24,
      lineHeight: "28px",
    },
    label: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 14,
    },
    switch: {
      marginLeft: 8,
      marginRight: 8,
    },
  });

export default styles;
