import { createSelector } from "reselect";
import { DataSetRequestState } from "../model";
import { MODULE_ID, SCHEMA_KEY } from "../constants";
import { createSelectors, Selector } from "@modules/base/createSelectors";
import {
  isDataCatalogProductionAccount,
  isEmptyString,
} from "@util";

import {
  CreateDataSetRequest,
  CreateDataSetRequestAttributes,
  DataClassification,
  DataRate,
  DataSetType,
  IngestionMechanism,
} from "@data";
import {
  DataSetRequestWizardSchema,
  DataSetRequestWizardStep,
  DEFAULT_STATE,
} from "../reducers/dataSetRequestWizard";

export const CREATE_DATA_SET_REQUEST_STEPS = [
  DataSetRequestWizardStep.DATA_SET_ALIAS,
  DataSetRequestWizardStep.DATA_OWNERS,
  DataSetRequestWizardStep.ARCHITECTS,
  DataSetRequestWizardStep.ENGINEERING_CONTACTS,
  DataSetRequestWizardStep.BUSINESS_OWNERS,
  DataSetRequestWizardStep.DATA_DESCRIPTION,
  DataSetRequestWizardStep.TERMS_CONDITIONS,
  DataSetRequestWizardStep.REVIEW,
];

export const CREATE_DATA_SET_REQUEST_STEPS_LABEL = {
  [DataSetRequestWizardStep.DATA_SET_ALIAS]: "Data Set Name",
  [DataSetRequestWizardStep.DATA_OWNERS]: "Data Owners",
  [DataSetRequestWizardStep.ARCHITECTS]: "Architects",
  [DataSetRequestWizardStep.ENGINEERING_CONTACTS]: "Engineering Contacts",
  [DataSetRequestWizardStep.BUSINESS_OWNERS]: "Business Owners",
  [DataSetRequestWizardStep.DATA_DESCRIPTION]: "Data Description",
  [DataSetRequestWizardStep.TERMS_CONDITIONS]: "Terms & Conditions",
  [DataSetRequestWizardStep.REVIEW]: "Finish",
};

export const {
  createDataSetRequest: getCreateDataSetRequestAttributes,
  dataSetRequestWizardStep: getDataSetRequestWizardStep,
  showEditMode: isEditModeActive,
  requestIoTApproved: isRequestIoTApproved,
  dataSetRequestRef: getDataSetRequestRef,
  dataRangeErrorMessage: getDataRangeErrorMessage,
  lifecycleErrorMessage: getLifecycleErrorMessage,
  showEditModeInitializeError: isEditModeInitializeErrorVisible,
  termsConditionsFileProvided: hasProvidedTermsConditionsFile,
  getErrors,
  getErrorMessage,
  getSuccessMessage,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  getCurrentAccountId,
  getAccessToken,
} = createSelectors<DataSetRequestWizardSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

const DATA_SET_ALIAS_REGEX = new RegExp("^[a-z][-a-z0-9]{2,126}[a-z0-9]$");

export const isProductionAccount: Selector<boolean> = createSelector(
  getCurrentAccountId, (accountId: string) => isDataCatalogProductionAccount(accountId));

export const getCreateDataSetRequest: Selector<CreateDataSetRequest> = createSelector(
  getCreateDataSetRequestAttributes, (attrs: CreateDataSetRequestAttributes) => {

    return new CreateDataSetRequest(attrs);
  });

export const getDataSetAlias: Selector<string> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.dataSetAlias;
  });

export const getTermsConditionsId: Selector<string> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.getTermsConditionsId();
  });

export const getDescription: Selector<string> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.description;
  });

export const getDataSetGroup: Selector<string> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.dataSetGroup;
  });

export const getDataSetType: Selector<DataSetType> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.dataSetType;
  });

export const getTags: Selector<string[]> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.tags;
  });

export const getDataOwners: Selector<string[]> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.dataOwners;
  });

export const getArchitects: Selector<string[]> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.architects;
  });

export const getEngineeringContacts: Selector<string[]> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.engineeringContacts;
  });

export const getBusinessOwners: Selector<string[]> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.businessOwners;
  });

export const getSchema: Selector<string> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.schema;
  });

export const getSampleAnonymizedData: Selector<string> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.sampleAnonymizedData;
  });

export const getDataClassification: Selector<DataClassification> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.dataClassification;
  });

export const getIngestionMechanism: Selector<IngestionMechanism> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.ingestionMechanism;
  });

export const getConfirmNonProductionData: Selector<boolean> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.confirmNonProductionData;
  });

export const isConfirmNonProductionDataValid: Selector<boolean> = createSelector(
  isProductionAccount, getConfirmNonProductionData, (isProdAccount: boolean, value: boolean) => {
    return !isProdAccount ? value : true;
  });

export const getContainsPiiData: Selector<boolean> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.containsPII;
  });

export const getPiiComment: Selector<string> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.piiComment ? createDataSetRequest.piiComment : "";
  });

export const getDataRate: Selector<DataRate> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) => {

    return createDataSetRequest.dataRate;
  });

export const getArchivingInDaysLifecyclePolicy: Selector<number> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) =>
    createDataSetRequest.getArchivingInDaysLifecyclePolicy());

export const getExpirationInDaysLifecyclePolicy: Selector<number> = createSelector(
  getCreateDataSetRequest, (createDataSetRequest: CreateDataSetRequest) =>
    createDataSetRequest.getExpirationInDaysLifecyclePolicy());

export const isDataSetAliasViewSelected: Selector<boolean> = createSelector(
  getDataSetRequestWizardStep, (currentStep: DataSetRequestWizardStep) => {

    return DataSetRequestWizardStep.DATA_SET_ALIAS === currentStep;
  });

export const isDataOwnerViewSelected: Selector<boolean> = createSelector(
  getDataSetRequestWizardStep, (currentStep: DataSetRequestWizardStep) => {

    return DataSetRequestWizardStep.DATA_OWNERS === currentStep;
  });

export const isArchitectsViewSelected: Selector<boolean> = createSelector(
  getDataSetRequestWizardStep, (currentStep: DataSetRequestWizardStep) => {

    return DataSetRequestWizardStep.ARCHITECTS === currentStep;
  });

export const isEngineeringContactsViewSelected: Selector<boolean> = createSelector(
  getDataSetRequestWizardStep, (currentStep: DataSetRequestWizardStep) => {

    return DataSetRequestWizardStep.ENGINEERING_CONTACTS === currentStep;
  });

export const isBusinessOwnersViewSelected: Selector<boolean> = createSelector(
  getDataSetRequestWizardStep, (currentStep: DataSetRequestWizardStep) => {

    return DataSetRequestWizardStep.BUSINESS_OWNERS === currentStep;
  });

export const isDataDescriptionViewSelected: Selector<boolean> = createSelector(
  getDataSetRequestWizardStep, (currentStep: DataSetRequestWizardStep) => {

    return DataSetRequestWizardStep.DATA_DESCRIPTION === currentStep;
  });

export const isTermsConditionsViewSelected: Selector<boolean> = createSelector(
  getDataSetRequestWizardStep, (currentStep: DataSetRequestWizardStep) => {

    return DataSetRequestWizardStep.TERMS_CONDITIONS === currentStep;
  });

export const isReviewViewSelected: Selector<boolean> = createSelector(
  getDataSetRequestWizardStep, (currentStep: DataSetRequestWizardStep) => {

    return DataSetRequestWizardStep.REVIEW === currentStep;
  });

export const isDataSetAliasValid: Selector<boolean> = createSelector(
  getDataSetAlias, (dataSetAlias: string) => {

    return DATA_SET_ALIAS_REGEX.test(dataSetAlias);
  });

export const isDescriptionValid: Selector<boolean> = createSelector(
  getDescription, (description: string) => {

    return description.trim().length > 0;
  });

export const isDataOwnersValid: Selector<boolean> = createSelector(
  getDataOwners, (dataOwners: string[]) => {

    return dataOwners.length === 2;
  });

export const isArchitectsValid: Selector<boolean> = createSelector(
  getArchitects, (architects: string[]) => {

    return architects.length === 1 || architects.length === 2;
  });

export const isEngineeringContactsValid: Selector<boolean> = createSelector(
  getEngineeringContacts, (engineeringContacts: string[]) => {

    return engineeringContacts.length === 1 || engineeringContacts.length === 2;
  });

export const isBusinessOwnersValid: Selector<boolean> = createSelector(
  getBusinessOwners, (businessOwners: string[]) => {

    return businessOwners.length === 1;
  });

export const isSchemaValid: Selector<boolean> = createSelector(
  getSchema, (schema: string) => {

    return schema.trim().length > 0;
  });

export const isSampleAnonymizedDataValid: Selector<boolean> = createSelector(
  getSampleAnonymizedData, (sampleAnonymizedData: string) => {

    return sampleAnonymizedData.trim().length > 0;
  });

export const isPiiCommentValid: Selector<boolean> = createSelector(
  getContainsPiiData, getPiiComment, (containsPiiData: boolean, piiComment: string) => {

    return containsPiiData && !isEmptyString(piiComment) || !containsPiiData;
  });

export const getDataVolume: Selector<number> = createSelector(
  getDataRate, (dataRate: DataRate) => dataRate.dataVolume);

export const isDataVolumeValid: Selector<boolean> = createSelector(
  getDataVolume, (dataVolume: number) => dataVolume > 0);

export const getDataVelocity: Selector<number> = createSelector(
  getDataRate, (dataRate: DataRate) => dataRate.dataVelocity);

export const isDataVelocityValid: Selector<boolean> = createSelector(
  getDataVelocity, (dataVelocity: number) => dataVelocity > 0);

export const isDataRangeValid: Selector<boolean> = createSelector(
  [isDataVolumeValid, isDataVelocityValid],
  (dataVolumeValid: boolean, dataVelocityValid: boolean) =>
    dataVolumeValid && dataVelocityValid);

export const isLifecyclePolicyEmpty: Selector<boolean> = createSelector(
  [getArchivingInDaysLifecyclePolicy, getExpirationInDaysLifecyclePolicy],
  (archivingInDays: number, expirationInDays: number) =>
    archivingInDays <= 0 && expirationInDays <= 0);

export const isArchivingInDaysLifecyclePolicyValid: Selector<boolean> = createSelector(
  [
    isProductionAccount,
    isLifecyclePolicyEmpty,
    getArchivingInDaysLifecyclePolicy,
    getExpirationInDaysLifecyclePolicy
  ],
  (productionAccount: boolean,
   lifecyclePolicyEmpty: boolean,
   archivingInDays: number,
   expirationInDays: number) =>
    (!productionAccount || lifecyclePolicyEmpty || (archivingInDays === 0) ||
      (archivingInDays > 0 && (expirationInDays === 0 || (expirationInDays > archivingInDays)))));

export const getArchivingInDaysValidationErrorMessage: Selector<string> = createSelector(
  [isArchivingInDaysLifecyclePolicyValid, getArchivingInDaysLifecyclePolicy],
  (valid: boolean, archivingInDays: number) => {
    if (valid) {
      return "";
    } else if (archivingInDays < 0) {
      return "Value cannot be negative";
    } else {
      return "Value must be either ZERO or less than the number of days until the data expires";
    }
  });

export const isExpirationInDaysLifecyclePolicyValid: Selector<boolean> = createSelector(
  [
    isProductionAccount,
    isLifecyclePolicyEmpty,
    getArchivingInDaysLifecyclePolicy,
    getExpirationInDaysLifecyclePolicy,
  ],
  (productionAccount: boolean,
   lifecyclePolicyEmpty: boolean,
   archivingInDays: number,
   expirationInDays: number) =>
    (!productionAccount || lifecyclePolicyEmpty || (expirationInDays === 0) ||
      (expirationInDays > 0 && (archivingInDays === 0 || (expirationInDays > archivingInDays)))));

export const getExpirationInDaysValidationErrorMessage: Selector<string> = createSelector(
  [isExpirationInDaysLifecyclePolicyValid, getExpirationInDaysLifecyclePolicy],
  (valid: boolean, expirationInDays: number) => {
    if (valid) {
      return "";
    } else if (expirationInDays < 0) {
      return "Value cannot be negative";
    } else {
      return "Value must be either ZERO or greater than the number of days until the data is archived";
    }
  });

export const isFirstStepActive: Selector<boolean> = createSelector(
  getDataSetRequestWizardStep, (currentStep: DataSetRequestWizardStep) => {

    return DataSetRequestWizardStep.DATA_SET_ALIAS === currentStep;
  });

export const isLastStepActive: Selector<boolean> = createSelector(
  getDataSetRequestWizardStep, (currentStep: DataSetRequestWizardStep) => {

    return DataSetRequestWizardStep.REVIEW === currentStep;
  });

export const getErrorTitle: Selector<string> = createSelector(
  getErrorMessage,
  (errorMessage: string = "") => {

    if (errorMessage.length === 0) {
      return "";
    }

    return "Create Data Set Request Failed";
  });

export const getDataSetAliasErrorMessage: Selector<string> = createSelector(
  [isDataSetAliasValid, getDataSetAlias], (isValid: boolean, dataSetAlias: string) => {

    return !isValid && dataSetAlias.length > 0 ? "Invalid Data Set Name" : "";
  });

export const getDescriptionErrorMessage: Selector<string> = createSelector(
  [isDescriptionValid, getErrorMessage], (isValid: boolean, errorMessage: string) => {

    return !isValid && errorMessage.length > 0 ? "Invalid Description" : "";
  });

export const getSchemaErrorMessage: Selector<string> = createSelector(
  [isSchemaValid, getErrorMessage], (isValid: boolean, errorMessage: string) => {

    return !isValid && errorMessage.length > 0 ? "Invalid Schema" : "";
  });

export const getSampleAnonymizedDataErrorMessage: Selector<string> = createSelector(
  [isSampleAnonymizedDataValid, getErrorMessage], (isValid: boolean, errorMessage: string) => {

    return !isValid && errorMessage.length > 0 ? "Invalid Sample Anonymized Data" : "";
  });

export const getDataVolumeErrorMessage: Selector<string> = createSelector(
  [isDataVolumeValid, getErrorMessage], (isValid: boolean, errorMessage: string) => {

    return !isValid && errorMessage.length > 0 ? "Data volume must be greater than zero" : "";
  });

export const getDataVelocityErrorMessage: Selector<string> = createSelector(
  [isDataVelocityValid, getErrorMessage], (isValid: boolean, errorMessage: string) => {

    return !isValid && errorMessage.length > 0 ? "Data velocity must be greater than zero " : "";
  });

export const showIngestionMechanism: Selector<boolean> = createSelector(
  getDataSetType, (dataSetType: DataSetType) => {

    return dataSetType === DataSetType.DATA_LAKE_ACQUIRED;
  });

export const isIngestionMechanismValid: Selector<boolean> = createSelector(
  [showIngestionMechanism, getIngestionMechanism],
  (ingestionMechanismVisible: boolean, ingestionMechanism: IngestionMechanism) => {

    return !ingestionMechanismVisible || ingestionMechanism !== IngestionMechanism.NONE;
  });

export const isDataSetCreated: Selector<boolean> = createSelector(
  getSuccessMessage, (message: string) => !isEmptyString(message));

export const getDataSetRequestJson: Selector<string> = createSelector(
  getCreateDataSetRequestAttributes, (attrs: CreateDataSetRequestAttributes) =>
    JSON.stringify(attrs, null, "  "));

export const getSteps: Selector<DataSetRequestWizardStep[]> = createSelector(
  [isProductionAccount], (productionAccount: boolean) =>
    CREATE_DATA_SET_REQUEST_STEPS.filter(step => {
      switch (step) {
        case DataSetRequestWizardStep.TERMS_CONDITIONS:
          return productionAccount;
        default:
          return true;
      }
    }));

export const getDisabledSteps: Selector<DataSetRequestWizardStep[]> = createSelector(
  [
    getSteps,
    isDataSetAliasValid,
    isDescriptionValid,
    isDataOwnersValid,
    isArchitectsValid,
    isEngineeringContactsValid,
    isBusinessOwnersValid,
    isSchemaValid,
    isSampleAnonymizedDataValid,
    isPiiCommentValid,
    isDataRangeValid,
    isDataVolumeValid,
    isDataVelocityValid,
    isConfirmNonProductionDataValid,
    isArchivingInDaysLifecyclePolicyValid,
    isExpirationInDaysLifecyclePolicyValid,
    isIngestionMechanismValid,
  ],
  (
    steps: DataSetRequestWizardStep[],
    validDataSetAlias: boolean,
    validDescription: boolean,
    validDataOwner: boolean,
    validArchitects: boolean,
    validEngineeringContacts: boolean,
    validBusinessOwners: boolean,
    validSchema: boolean,
    validSampleAnonymizedData: boolean,
    validPiiComment: boolean,
    validDataRange: boolean,
    validDataVolume: boolean,
    validDataVelocity: boolean,
    validConfirmNonProductionData: boolean,
    validArchivingInDaysLifecyclePolicy: boolean,
    validExpirationInDaysLifecyclePolicy: boolean,
    validIngestionMechanism: boolean
   ) => {

    if (!validDataSetAlias || !validDescription) {
      return steps.filter(step => DataSetRequestWizardStep.DATA_SET_ALIAS !== step);
    }

    if (!validDataOwner) {
      return steps.filter(step => DataSetRequestWizardStep.DATA_SET_ALIAS !== step &&
        DataSetRequestWizardStep.DATA_OWNERS !== step);
    }

    if (!validArchitects) {
      return steps.filter(step => DataSetRequestWizardStep.DATA_SET_ALIAS !== step &&
        DataSetRequestWizardStep.DATA_OWNERS !== step &&
        DataSetRequestWizardStep.ARCHITECTS !== step);
    }

    if (!validEngineeringContacts) {
      return steps.filter(step => DataSetRequestWizardStep.DATA_SET_ALIAS !== step &&
        DataSetRequestWizardStep.DATA_OWNERS !== step &&
        DataSetRequestWizardStep.ARCHITECTS !== step &&
        DataSetRequestWizardStep.ENGINEERING_CONTACTS !== step);
    }

    if (!validBusinessOwners) {
      return steps.filter(step => DataSetRequestWizardStep.DATA_SET_ALIAS !== step &&
        DataSetRequestWizardStep.DATA_OWNERS !== step &&
        DataSetRequestWizardStep.ARCHITECTS !== step &&
        DataSetRequestWizardStep.ENGINEERING_CONTACTS !== step &&
        DataSetRequestWizardStep.BUSINESS_OWNERS !== step);
    }

    if (!validSchema || !validSampleAnonymizedData ||
      !validPiiComment || !validDataRange || !validDataVolume ||
      !validDataVelocity || !validArchivingInDaysLifecyclePolicy ||
      !validExpirationInDaysLifecyclePolicy || !validConfirmNonProductionData ||
      !validIngestionMechanism) {
      return steps.filter(step => DataSetRequestWizardStep.DATA_SET_ALIAS !== step &&
        DataSetRequestWizardStep.DATA_OWNERS !== step &&
        DataSetRequestWizardStep.ARCHITECTS !== step &&
        DataSetRequestWizardStep.ENGINEERING_CONTACTS !== step &&
        DataSetRequestWizardStep.BUSINESS_OWNERS !== step &&
        DataSetRequestWizardStep.DATA_DESCRIPTION !== step);
    }

    return [];
  });

export const getCompletedSteps: Selector<DataSetRequestWizardStep[]> = createSelector(
  [
    getSteps,
    getDataSetRequestWizardStep,
    isDataSetAliasValid,
    isDescriptionValid,
    isDataOwnersValid,
    isArchitectsValid,
    isEngineeringContactsValid,
    isBusinessOwnersValid,
    isSchemaValid,
    isSampleAnonymizedDataValid,
    isDataRangeValid,
    isDataVolumeValid,
    isDataVelocityValid,
    isConfirmNonProductionDataValid,
    isArchivingInDaysLifecyclePolicyValid,
    isExpirationInDaysLifecyclePolicyValid,
    hasProvidedTermsConditionsFile,
    isLastStepActive,
    isLoadingIndicatorVisible,
    isDataSetCreated,
  ],
  (
    steps: DataSetRequestWizardStep[],
    currentStep: DataSetRequestWizardStep,
    validDataSetAlias: boolean,
    validDescription: boolean,
    validDataOwner: boolean,
    validArchitects: boolean,
    validEngineeringContacts: boolean,
    validBusinessOwners: boolean,
    validSchema: boolean,
    validSampleAnonymizedData: boolean,
    validDataRange: boolean,
    validDataVolume: boolean,
    validDataVelocity: boolean,
    validConfirmNonProductionData: boolean,
    validArchivingInDaysLifecyclePolicy: boolean,
    validExpirationInDaysLifecyclePolicy: boolean,
    termsConditionsFileProvided: boolean,
    lastStepActive: boolean,
    loading: boolean,
    success: boolean,
  ) => {

    const currentStepIndex = steps.indexOf(currentStep);

    return steps.filter((step: DataSetRequestWizardStep, index: number) => {

      const isCurrentStep = (step === currentStep);

      switch (step) {
        case DataSetRequestWizardStep.DATA_SET_ALIAS:
          return !isCurrentStep && validDataSetAlias && validDescription;
        case DataSetRequestWizardStep.DATA_OWNERS:
          return !isCurrentStep && validDataSetAlias && validDescription
            && validDataOwner;
        case DataSetRequestWizardStep.ARCHITECTS:
          return !isCurrentStep && validDataSetAlias && validDescription
            && validDataOwner && validArchitects;
        case DataSetRequestWizardStep.ENGINEERING_CONTACTS:
          return !isCurrentStep && validDataSetAlias && validDescription
            && validDataOwner && validArchitects && validEngineeringContacts;
        case DataSetRequestWizardStep.BUSINESS_OWNERS:
          return !isCurrentStep && validDataSetAlias && validDescription
            && validDataOwner && validArchitects && validBusinessOwners;
        case DataSetRequestWizardStep.DATA_DESCRIPTION:
          return !isCurrentStep && validDataSetAlias && validDescription
            && validDataOwner && validArchitects && validBusinessOwners
            && validSchema && validSampleAnonymizedData && validDataRange
            && validDataVolume && validDataVelocity && validArchivingInDaysLifecyclePolicy
            && validExpirationInDaysLifecyclePolicy && validConfirmNonProductionData;
        case DataSetRequestWizardStep.TERMS_CONDITIONS:
          return termsConditionsFileProvided || currentStepIndex > index;
        case DataSetRequestWizardStep.REVIEW:
          return lastStepActive && (loading || success);
        default:
          return false;
      }
    });
  });

export const getPreviousStep: Selector<DataSetRequestWizardStep> = createSelector(
  [getSteps, isFirstStepActive, getDataSetRequestWizardStep],
  (steps: DataSetRequestWizardStep[], firstStep: boolean, currentStep: DataSetRequestWizardStep) => {

    if (firstStep) {
      return DataSetRequestWizardStep.NONE;
    }

    const currentStepIndex = steps.indexOf(currentStep);

    return steps[currentStepIndex - 1] || DataSetRequestWizardStep.NONE;
  });

export const getNextStep: Selector<DataSetRequestWizardStep> = createSelector(
  [getSteps, isLastStepActive, getDataSetRequestWizardStep],
  (steps: DataSetRequestWizardStep[], lastStep: boolean, currentStep: DataSetRequestWizardStep) => {

    if (lastStep) {
      return DataSetRequestWizardStep.NONE;
    }

    const currentStepIndex = steps.indexOf(currentStep);

    return steps[currentStepIndex + 1] || DataSetRequestWizardStep.NONE;
  });

export const isPreviousStepButtonDisabled: Selector<boolean>  = createSelector(
  [getDisabledSteps, getPreviousStep],
  (disabledSteps: DataSetRequestWizardStep[], previousStep: DataSetRequestWizardStep) =>
    DataSetRequestWizardStep.NONE === previousStep || disabledSteps.indexOf(previousStep) >= 0);

export const isNextStepButtonDisabled: Selector<boolean>  = createSelector(
  [getDisabledSteps, getNextStep],
  (disabledSteps: DataSetRequestWizardStep[], nextStep: DataSetRequestWizardStep) => {

    return DataSetRequestWizardStep.NONE === nextStep || disabledSteps.indexOf(nextStep) >= 0;
  });

export const getDataSetRequestState: Selector<DataSetRequestState> = createSelector(
  getDataSetRequestJson, (json: string) => new DataSetRequestState({
    json
  }));
