import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    root: {
      backgroundColor: Color.WHITE,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      minWidth: "auto",
      minHeight: "auto",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.25)",
      },
    },
    label: {
      color: Color.SIGNIFY_GREEN,
    },
    primary: {
    },
    secondary: {
    },
    extended: {
    },
    focusVisible: {
    },
    disabled: {
    },
    colorInherit: {
    },
    sizeSmall: {
    },
    sizeMedium: {
    },
  });

export default styles;
