import { DeviceEnrollment } from "@data";
import { isEmptyString } from "@util";
import React from "react";
import { DevicesListColumn } from "@components";
import { createComparator } from "./createComparator";

export const getSearchResults = (items: DeviceEnrollment[], searchQuery: string) => {

  const devices = items.slice();

  if (devices.length === 0 || isEmptyString(searchQuery)) {
    return devices;
  }

  return devices.filter((device: DeviceEnrollment) =>
    device.getDeviceId().indexOf(searchQuery) >= 0 ||
    device.status.indexOf(searchQuery) >= 0 ||
    device.getStatusMessage().indexOf(searchQuery) >= 0);
};

export const sortAndFilterDeviceResults = (props: {
  devices: DeviceEnrollment[],
  nameFilter: string,
  sortOrderAscending: boolean,
  sortByColumn: DevicesListColumn,
}) => {

  const { devices, nameFilter, sortOrderAscending, sortByColumn } = props;

  const comparator = React.useMemo(() =>
    createComparator(sortByColumn, sortOrderAscending), [sortByColumn, sortOrderAscending]);

  const sortedDevices = React.useMemo(() => devices.sort(comparator).slice(), [devices, comparator]);

  return React.useMemo(() =>
    getSearchResults(sortedDevices, nameFilter), [sortedDevices, nameFilter]);
};
