import { Module } from "@data";
import { reset } from "./actions";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { isAccessDeniedVisible, isEmptyViewVisible } from "./selectors";
import BackButton from "./containers/WizardBackButton";
import view from "./InviteUserView";

export const inviteUserModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Invite User",
  title: "Invite User",
  className: "inviteUserModule",
  primaryColor,
  icon,
  view,
  BackButton,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: reset,
});

export default inviteUserModule;
