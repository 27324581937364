import React from "react";
import classnames from "classnames";
import styles, { PrimaryIcon } from "./styles";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface CostReportsModel {
  className?: string;
  title?: string;
}

export interface CostReportsActions {
}

type Model = CostReportsModel;
type Actions = CostReportsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const CostReports = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Cost Report Metrics",
    children,
  } = props;

  return (
    <div className={classnames("costReports", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        <PrimaryIcon className={classnames("icon", classes.icon)} />
        <Typography className={classnames("title", classes.title)} variant="h3">
          {title}
        </Typography>
      </div>
      <div className={classnames("body", classes.body)}>
        Welcome to the cost reporting module
      </div>
      {children}
    </div>
  );
});

export default CostReports;
