import { combineReducers } from "redux";
import { Actions, filterAction, Identity } from "../../../util/ReducerUtil";

export interface PropertiesTableSchema {
  breadcrumbs: string[];
}

export const DEFAULT_STATE: PropertiesTableSchema = Object.assign({
  breadcrumbs: [],
});

export enum PropertiesTableActionType {
  SET_BREADCRUMBS = "schema_wizard/properties_table/set_breadcrumbs",
}

const breadcrumbs = filterAction<string[]>(Actions(
  PropertiesTableActionType.SET_BREADCRUMBS))(Identity,
  DEFAULT_STATE.breadcrumbs);

export const PropertiesTableReducer = combineReducers<PropertiesTableSchema>({
  breadcrumbs,
});
