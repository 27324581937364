import { Module } from "@data";
import { createModule } from "@base/createModule";
import { DATA_PROCESSING_DEVELOPER_GUIDE, MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./styles";
import DATA_WORKLOADS_REQUIREMENTS from "@modules/dataWorkloads/requirements";
import view from "./DataWorkloadView";

export const dataWorkloadsModule: Module = createModule({
  ...DATA_WORKLOADS_REQUIREMENTS,
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Workloads",
  title: "Workloads",
  className: "dataWorkloadsModule",
  documentationLink: DATA_PROCESSING_DEVELOPER_GUIDE,
  primaryColor,
  icon,
  view,
  backButton: null,
});

export default dataWorkloadsModule;
