import React from "react";
import { useParams } from "react-router-dom";
import DataAccessRequestDetails from "./containers/DataAccessRequestDetails";

export function DataAccessRequestDetailsView() {

  const { id: accessRequestId } = useParams<{ id: string }>();

  return (
    <DataAccessRequestDetails
      key={accessRequestId}
    />
  );
}

export default DataAccessRequestDetailsView;