import { createStyles, StyleRules, Theme } from "@material-ui/core/styles";
import { Color } from "@Color";
import ModuleIcon from "@material-ui/icons/ViewCarousel";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const textField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "14px",
      fontWeight: 300,
      padding: "2px 0",
      margin: 0,
    },
    "& input": {
      fontSize: 14,
      fontWeight: 400,
      padding: "18.5px 14px",
      margin: 0,
    },
  },
};

export const textAreaField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& textarea": {
      fontSize: 14,
      fontWeight: 400,
      padding: 0,
      margin: 0,
    },
  },
};

export const applicationInfoView = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      marginTop: 8,
    },
    openIdConnect: {
      color: Color.TEXT,
      fontWeight: 300,
      marginTop: 15,
    },
    metadata: {
      color: Color.TEXT,
      fontWeight: 200,
      marginTop: 20,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    idTextField: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    nameTextField: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    description: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    dropdownMenu: {
      marginTop: 16,
      minWidth: 300,
      display: "inline-flex",
      alignItems: "stretch",
    },
    dropdownMenuLabel: {
      marginTop: 8,
    },
    dropdownMenuEmptyValueLabel: {
      fontStyle: "italic",
    },
    applicationTypeContainer: {
      marginTop: 40,
      display: "flex",
      flexFlow: "row nowrap",
    },
    metadataContainer: {
      marginTop: 20,
      display: "flex",
      flexFlow: "row nowrap",
    },
    alert: {
      marginLeft: 30,
    },
    alertTitle: {
      fontSize: 14,
      marginTop: 2,
    },
    alertDescription: {
      fontSize: 14,
      lineHeight: "21px",
      marginTop: 10,
      marginBottom: 0,
      paddingLeft: 20,
    },
    metadataKey: {
      fontFamily: "Roboto, sans-serif",
      minWidth: 250,
    },
    metadataValue: {
      fontFamily: "Roboto, sans-serif",
      minWidth: 250,
      marginLeft: 25,
    },
    addMetadataButton: {
      margin: "16px 0 0 16px",
      width: 32,
      height: 32,
      borderRadius: 32,
      padding: 0,
      backgroundColor: Color.WHITE,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.54)",
      },
    },
    metadataTagContainer: {
      display: "flex",
      flexFlow: "row nowrap",
    },
    metadataTag: {
      height: 28,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
      margin: theme.spacing(),
      padding: theme.spacing(),
      color: Color.WHITE,
    },
    metadataIcon: {
      color: Color.WHITE,
      width: 14,
      height: 14,
      marginLeft: 2,
      marginRight: 2,
      "&:first-child": {
        marginRight: 0,
      },
    },
  });

export const removeRedirectUri = () =>
  createStyles({
    content: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    subtitle: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 18,
    },
    label: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "18px",
      textAlign: "center",
      marginTop: 16,
      marginBottom: 16,
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
  });

export const editRedirectUri = () =>
  createStyles({
    container: {
      "& .content": {
        paddingTop: 16,

        "& .errorView": {
          padding: "4px 16px",
          flexFlow: "row wrap",
          marginBottom: 0,

          "& .icon": {
            width: 24,
            height: 24,
            fontSize: 12,
            marginRight: 8,
          },

          "& .title": {
            display: "none",
          },

          "& .message": {
            fontSize: 12,
            lineHeight: 0,
            margin: 0,
          },
        },
      },
    },
    uriContainer: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    inputField: {
      flex: "1 0 auto",
    },
    formHelperTextError: {
      fontSize: 12,
    },
    controls: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 8,
    },
    uri: {
      flex: "1 0 auto",
    },
  });

export const view = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      marginTop: 8,
    },
    jsonEditor: {
      flex: "1 0 auto",
      marginTop: 25,
    },
    autocomplete: {
      flex: "1 0 auto",
      marginTop: 15,
      marginBottom: 15,
      width: "100%",
    },
  });

export const applicationSecurityView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      marginTop: 8,
    },
    dropdownMenu: {
      marginTop: 16,
      minWidth: 250,
      maxWidth: 300,
      display: "inline-flex",
      alignItems: "stretch",
    },
    dropdownMenuLabel: {
      marginTop: 8,
    },
  });

export const reviewView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      marginTop: 8,
    },
    summaryViewLabel: {
      marginTop: 25,
    },
    summaryViewLabelName: {
      flex: "0 0 175px",
      alignSelf: "center",
    },
    summaryViewLabelValue: {
      lineHeight: 2,
      padding: "5px 10px",
      whiteSpace: "pre-line",
    },
  });

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
  });
