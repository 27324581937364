import React from "react";
import DataSetRequest, { DataSetRequestAttributes } from "@data/DataSetRequest";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useDataSetRequestClient,
} from "@hooks";

type SuccessResponse = DataSetRequestAttributes;

export interface UseGetDataSetRequestProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  dataSetRequestId?: string;
}

export interface UseGetDataSetRequestModel extends UseApiRequestModel<SuccessResponse> {
  dataSetRequest: DataSetRequest;
}

export interface UseGetDataSetRequestActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetDataSetRequestProps;
type Model = UseGetDataSetRequestModel;
type Actions = UseGetDataSetRequestActions;
type Result = [Model, Actions];

export const useGetDataSetRequest = (props: Props): Result => {

  const {
    dataSetRequestId = "",
    defaultErrorMessage = "Failed to get data set request details",
    ...otherProps
  } = props;

  const DataSetRequestClient = useDataSetRequestClient();

  const makeApiRequest = React.useCallback(() =>
      DataSetRequestClient.getDataSetRequest(dataSetRequestId),
    [DataSetRequestClient, dataSetRequestId]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const dataSetRequest = React.useMemo(() => {
    const attrs = successResponse || {};
    return new DataSetRequest(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    dataSetRequest,
  }), [
    baseModel,
    dataSetRequest,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetDataSetRequest;
