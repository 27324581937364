import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import EmailTemplateReqs from "@data/EmailTemplateRequirements";
import SuccessIcon from "@material-ui/icons/CheckCircleOutline";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { bodyView as styles } from "@modules/emailTemplateWizard/components/styles";

export enum VerifyAndCheck {
    EXISTS = "exists",
    LENGTH = "length",
    TRUTH = "truth",
}

export interface Model {
    check?: any;
    name?: string;
    value?: string;
    condition?: VerifyAndCheck;
    emailRequirements?: EmailTemplateReqs;
}

type Props = WithStyles<typeof styles> & Model;

function checkReqs(value: string, check: any, callback: any) {
    let numFound = 0;
    for (const [field] of Object.entries(check)) {
        const braced = check[field].map((e: string) => "{{" + e + "}}");

        let foundField = false;
        for (const req of braced) {
            if (value.toLowerCase().includes(req.toLowerCase())) { foundField = true; }
            if (foundField) { numFound++; break; }
        }

        callback(foundField, field, braced);
    }
    return numFound === Object.keys(check).length ? 1 : 0;
}

export function verifyField(props: Props): number {

    const {
        check,
        condition,
        value = "",
    } = props;

    if (condition === VerifyAndCheck.TRUTH) {
        return check ? 1 : 0;
    }

    if (condition === VerifyAndCheck.LENGTH) {
        return value.length >= check[0] && length <= check[1] ? 1 : 0;
    }

    if (condition === VerifyAndCheck.EXISTS) {
        return checkReqs(value, check, noop);
    }

    return 0;
}

export const VerifyFieldsView = withStyles(styles)((props: Props) => {

    const {
        classes,
        name = "",
        value = "",
        check,
        condition,
        emailRequirements = EmailTemplateReqs.EMPTY.toJS(),
    } = props;

    let fields = [];
    const isMissingFields = verifyField(props) === 0;

    if (condition === VerifyAndCheck.TRUTH) {
        fields.push(
            fieldWarning(
                !isMissingFields,
                !isMissingFields ?
                    name + " has all requirements" :
                    name + " is missing a requirement",
                value
            )
        );
    }

    if (condition === VerifyAndCheck.LENGTH) {
        fields.push(
            fieldWarning(
                !isMissingFields,
                !isMissingFields ?
                    name + " has valid length" :
                    name + " has invalid length",
                "Must be between " + check[0] + " and " +  check[1] + " characters"
            )
        );
    }

    if (condition === VerifyAndCheck.EXISTS) {
        checkReqs(value, check, (foundField: boolean, field: string, braced: any) =>
            fields.push(
                fieldWarning(
                    foundField,
                    foundField ?
                        name + " has a " + field.split(":").join(" ").toLowerCase() :
                        name + " requires the parameter",
                    emailRequirements.parameters[field] != null
                        ? emailRequirements.parameters[field].description : "",
                    braced[0].toString(),
                )
            )
        );
    }

    function fieldWarning(foundField: boolean, title: string, description: string = "", code: string = "") {
        return (
            <div
                key={name + "-" + fields.length}
                className={classnames("verifyField", classes.verifyField)}
            >
                <div
                    className={
                        classnames("verifySubject",
                        isEmptyString(description) || foundField ? classes.verifySubject : classes.verifyBorderBottom)
                    }
                >
                    {!foundField && (
                        <ErrorIcon className={classnames("verifyError", classes.verifyError)}/>
                    )}
                    {foundField && (
                        <SuccessIcon className={classnames("verifySuccess", classes.verifySuccess)}/>
                    )}
                    {title}
                </div>
                {!foundField && !isEmptyString(code) && (
                    <div className={classnames("verifyCode", classes.verifyCode)}>
                        {code}
                    </div>
                )}
                {!foundField && !isEmptyString(description) && (
                    <div className={classnames("verifySubject", classes.verifySubjectCenter)}>
                        {description}
                    </div>
                )}
            </div>
        );
    }

    const fieldId = () => {
        const nameId = name.split(" ").join("");
        if (condition === VerifyAndCheck.TRUTH) { return nameId + "-" + "truth"; }
        if (condition === VerifyAndCheck.LENGTH) { return nameId + "-" + "length"; }
        return nameId + "-" + Object.keys(check).toString();
    };

    return (
        <div className={classnames("verifyChecklist", classes.verifyChecklist)}>
            {!isMissingFields && (
                <div id={"success" + fieldId()}>
                    { fields }
                </div>
            )}
            {isMissingFields && (
                <div id={"error" + fieldId()}>
                    <div>{ fields }</div>
                </div>
            )}
        </div>
    );
});

export default VerifyFieldsView;
