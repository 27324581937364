import React from "react";
import { noop } from "@util";
import { AuthTokenContext } from "@components";
import { RestClientError, UserIdmLegacyClient } from "@network";
import {
  ExpireUserPasswordDialog,
  ExpireUserPasswordDialogActions as Actions,
  ExpireUserPasswordDialogModel as Model,
} from "./ExpireUserPasswordDialog";
import { User } from "@data";

export interface ExpireUserPasswordActions extends Actions {
  showLoadingIndicator?: () => void;
  openLogoutUserDialog?: (user: User) => void;
  expirePasswordRequest?: () => void;
  expirePasswordSuccess?: () => void;
  expirePasswordFailed?: (error: string) => void;
}

type Props = Model & ExpireUserPasswordActions;

export const ExpireUserPassword = (props: Props) => {

  const {
    user = User.EMPTY,
    cancel = noop,
    onSuccessMessageShown = noop,
    showLoadingIndicator: showExpiringMessage = noop,
    openLogoutUserDialog = noop,
    expirePasswordRequest = noop,
    expirePasswordSuccess = noop,
    expirePasswordFailed = noop,
    ...otherProps
  } = props;

  const authToken = React.useContext(AuthTokenContext);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);
  const [proceedToLogout, setProceedToLogout] = React.useState(false);

  const expireUserPassword = React.useCallback(() =>
      UserIdmLegacyClient.expirePassword(authToken, user.getUserId()),
    [authToken, user]);

  React.useEffect(() => {

    let ignore = false;

    if (showLoadingIndicator) {

      setErrorMessage("");

      expirePasswordRequest();
      expireUserPassword()
        .then(() => {
          if (!ignore) {
            setShowLoadingIndicator(false);
            setSuccessMessage("User Password Expired");
            expirePasswordSuccess();
          }
        }, (response: RestClientError) => {
          if (!ignore) {
            const { error = "User Password Expired failed" } = response;
            if (response.status === 405) {
              setProceedToLogout(true);
            }
            setErrorMessage(error);
            expirePasswordFailed(error);
            setShowLoadingIndicator(false);
          }
        });
    }

    return () => { ignore = true; };

  }, [
    showLoadingIndicator,
    setErrorMessage,
    expireUserPassword,
    setShowLoadingIndicator,
    setSuccessMessage,
    setProceedToLogout,
    expirePasswordRequest,
    expirePasswordSuccess,
    expirePasswordFailed,
  ]);

  const confirm = React.useCallback(() => {
    showExpiringMessage();
    setShowLoadingIndicator(true);
  }, [showExpiringMessage, setShowLoadingIndicator]);

  const openLogoutUser = React.useCallback(() => {
    openLogoutUserDialog(user);
    cancel();
  }, [openLogoutUserDialog, cancel]);

  return (
    <ExpireUserPasswordDialog
      {...otherProps}
      open={true}
      user={user}
      proceedToLogout={proceedToLogout}
      errorMessage={errorMessage}
      successMessage={successMessage}
      loading={showLoadingIndicator}
      cancel={cancel}
      confirm={!proceedToLogout ? confirm : openLogoutUser}
      onSuccessMessageShown={onSuccessMessageShown}
    />
  );
};

export default ExpireUserPassword;
