import { isEmptyString } from "@util";
import { AppSchema } from "@main/schemas";
import { createActions } from "@modules/base";
import { RestClientError, UserIdmClient } from "@network";
import { ACTION_TYPES, ChangePasswordSteps, DEFAULT_STATE } from "./reducers";
import { getAccessToken, getChangePasswordStep, getPassword, getUserId } from "./selectors";

export const {
  userId: setUserId,
  password: setPassword,
  open: setOpen,
  step: setChangePasswordStep,
  showPassword: setShowPassword,
  setErrorMessage,
  setSuccessMessage,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  CHANGE_PASSWORD_REQUEST: changePasswordRequest,
  CHANGE_PASSWORD_SUCCESS: changePasswordSuccess,
  CHANGE_PASSWORD_FAILED: changePasswordFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setUserId());
  dispatch(setPassword());
  dispatch(setShowPassword());
  dispatch(setChangePasswordStep());
  dispatch(setOpen());
  return dispatch(baseReset());
};

export const showConfirmationDialog = () => (dispatch: any) =>
  dispatch(setChangePasswordStep(ChangePasswordSteps.CONFIRM));

export const showPassword = () => (dispatch: any) => dispatch(setShowPassword(true));
export const hidePassword = () => (dispatch: any) => dispatch(setShowPassword(false));

export const changePassword = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const userId = getUserId(state);
  const password = getPassword(state);
  const accessToken = getAccessToken(state);

  const json = JSON.stringify({
    password,
  });

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(changePasswordRequest());

  return UserIdmClient.changeUserPassword(accessToken, userId, json)
    .then(() => {

      dispatch(changePasswordSuccess());
      dispatch(hideLoadingIndicator());
      return dispatch(setSuccessMessage("User Password Changed"));

    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to change user password" } = response;

      dispatch(changePasswordFailed(analytic));
      dispatch(hideLoadingIndicator());

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(setErrorMessage(error));
    });
};

export const confirm = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const step = getChangePasswordStep(state);

  if ( step === ChangePasswordSteps.PASSWORD) {
    return dispatch(showConfirmationDialog());
  }

  return dispatch(changePassword());
};

export const open = (userId: string) => (dispatch: any) => {

  if (isEmptyString(userId)) {
    return Promise.resolve();
  }

  dispatch(reset());
  dispatch(setUserId(userId));
  return dispatch(setOpen(true));
};

export const close = reset;

export const initialize = reset;
