import { isValidRegex } from "../../../../util";
import { AppSchema } from "../../../main/schemas";
import { JsonSchemaStringConstraints } from "../../../../data";
import { StringConstraintsSelectors } from "../../selectors/stringConstraints";
import { PropertyEditorActions } from "../../actions/propertyEditor";
import {
  DEFAULT_STATE,
  StringConstraintsAction,
  StringConstraintsActionType,
} from "../../reducers/stringConstraints";

type Action = StringConstraintsAction;

export const setMinLength =
  (value: string = DEFAULT_STATE.minLength): Action => ({
    type: StringConstraintsActionType.SET_MIN_LENGTH,
    value,
  });

export const setMinLengthError =
  (value: string = DEFAULT_STATE.minLengthError): Action => ({
    type: StringConstraintsActionType.SET_MIN_LENGTH_ERROR,
    value,
  });

export const clearMinLengthError = (): Action => setMinLengthError();

export const updateMinLength = (value?: string) => (dispatch: any) => {
  dispatch(setMinLength(value));
  dispatch(clearMinLengthError());
  return dispatch(PropertyEditorActions.constraintsUpdated());
};

export const setMaxLength =
  (value: string = DEFAULT_STATE.maxLength): Action => ({
    type: StringConstraintsActionType.SET_MAX_LENGTH,
    value,
  });

export const setMaxLengthError =
  (value: string = DEFAULT_STATE.maxLengthError): Action => ({
    type: StringConstraintsActionType.SET_MAX_LENGTH_ERROR,
    value,
  });

export const clearMaxLengthError = (): Action => setMaxLengthError();

export const updateMaxLength = (value?: string) => (dispatch: any) => {
  dispatch(setMaxLength(value));
  dispatch(clearMaxLengthError());
  return dispatch(PropertyEditorActions.constraintsUpdated());
};

export const setPattern =
  (value: string = DEFAULT_STATE.pattern): Action => ({
    type: StringConstraintsActionType.SET_PATTERN,
    value,
  });

export const setPatternError =
  (value: string = DEFAULT_STATE.patternError): Action => ({
    type: StringConstraintsActionType.SET_PATTERN_ERROR,
    value,
  });

export const clearPatternError = (): Action => setPatternError();

export const updatePattern = (value?: string) => (dispatch: any) => {
  dispatch(setPattern(value));
  dispatch(clearPatternError());
  return dispatch(PropertyEditorActions.constraintsUpdated());
};

export const validateMinLength = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const value = StringConstraintsSelectors.getMinLength(state);

  const minLength = Number(value + "");

  if (value.trim().length > 0) {

    if (isNaN(minLength) || minLength < 0) {
      return dispatch(setMinLengthError("Value must be a non-negative integer."));
    }

    const constraints = StringConstraintsSelectors.getConstraints(state);

    if (constraints.hasMaxLength() && minLength > constraints.maxLength) {
      return dispatch(setMinLengthError("Value must be less than or equal to maxLength"));
    }
  }

  return dispatch(clearMinLengthError());
};

export const validateMaxLength = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const value = StringConstraintsSelectors.getMaxLength(state);

  const maxLength = Number(value + "");

  if (value.trim().length > 0) {

    if (isNaN(maxLength) || maxLength < 0) {
      return dispatch(setMaxLengthError("Value of this keyword must be a non-negative integer."));
    }

    const constraints = StringConstraintsSelectors.getConstraints(state);

    if (constraints.hasMinLength() && maxLength < constraints.minLength) {
      return dispatch(setMaxLengthError("Value must be greater than or equal to minLength"));
    }
  }

  return dispatch(clearMaxLengthError());
};

export const validatePattern = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const pattern = StringConstraintsSelectors.getPattern(state);

  if (pattern.trim().length > 0) {

    if (!isValidRegex(pattern)) {
      return dispatch(setPatternError(
        "Value must be a valid regular expression."));
    }
  }

  return dispatch(clearPatternError());
};

export const validate = () => (dispatch: any) => {

  return Promise.all([
    dispatch(validateMinLength()),
    dispatch(validateMaxLength()),
    dispatch(validatePattern()),
  ]);
};

export const reset = () => (dispatch: any) => {
  dispatch(setMinLength());
  dispatch(setMinLengthError());
  dispatch(setMaxLength());
  dispatch(setMaxLengthError());
  dispatch(setPattern());
  return dispatch(setPatternError());
};

export const setConstraints =
  (constraints: JsonSchemaStringConstraints = JsonSchemaStringConstraints.EMPTY) =>
    (dispatch: any) => {

      dispatch(setMinLength(constraints.hasMinLength() ? constraints.minLength + "" : ""));

      dispatch(setMaxLength(constraints.hasMaxLength() ? constraints.maxLength + "" : ""));

      return dispatch(setPattern(constraints.pattern));
    };
