import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { deviceBootstrap as styles } from "./styles";
import { Alert, AlertTitle } from "@components";

export interface Model {
  bootstrapLink?: string;
  description?: string;
  children?: React.ReactNode;
}

export interface Actions {

}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DeviceBootstrapView = withStyles(styles)((props: Props) => {

  const {
    classes,
    bootstrapLink = "https://www.collaboration.dtf.signify.com/pages/viewpage.action?spaceKey=IOTPLAT&title=How+To+-+Device+Management+-+Getting+Started+Guide#HowToDeviceManagementGettingStartedGuide-Bootstrap",
    description = "How to - Device Bootstrap",
    children,
  } = props;

  return (
    <div className={classnames("deviceBootstrapView", classes.container)}>
      <label className={classes.subtitle}>
        {description}
      </label>
      <React.Fragment>
        <Alert severity="info" className={classnames("certificateInfo", classes.alert)}>
          <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
            <strong>
              Note
            </strong>
          </AlertTitle>
          <p className={classnames("alertDescription", classes.alertDescription)}>
            Once a device has been enrolled and connected to a public network,
            it then has the ability to connect to the platform and authenticate itself
            using the protocol specified in its device type.
          </p>
          <p className={classnames("alertDescription", classes.alertDescription)}>
            For More Information about Device Bootstrap, click
            <a
              className={classes.underlined}
              href={bootstrapLink}
              target="_blank"
            >
              here
            </a>
            .
          </p>
        </Alert>
      </React.Fragment>
      {children}
    </div>
  );
});

export default DeviceBootstrapView;
