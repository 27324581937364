import React from "react";
import { isEmptyString } from "@util/Functions";
import { MODULE_PATH } from "@modules/accountSettings/constants";
import { accountSettingsModule } from "@modules/accountSettings/module";
import NavigationListItem, { ListItemType } from "./NavigationListItem";

export const SettingsListItem = (props: {
  accountId?: String;
}) => {

  const { accountId = "" } = props;

  const name = React.useMemo(() => isEmptyString(accountId) ? "Account Settings" : (
    <span>Account ID: <b>{accountId}</b></span>
  ), [accountId]);

  return (
    <NavigationListItem
      name={name}
      outlined={true}
      clickable={true}
      path={MODULE_PATH}
      type={ListItemType.SETTINGS}
      id={accountSettingsModule.id}
      icon={accountSettingsModule.icon}
      exactPath={accountSettingsModule.exactPath}
      strictPath={accountSettingsModule.strictPath}
      isActive={accountSettingsModule.isNavLinkActive}
    />
  );
};

export default SettingsListItem;
