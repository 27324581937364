import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks";
import { EmailTemplate, EmailTemplateAttributes } from "@data";
import useEmailTemplateClient from "@hooks/emailTemplates/use-email-template-client";

type SuccessResponse = EmailTemplateAttributes;

export interface UseEmailTemplateProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  accountId?: string;
  templateId?: string;
}

export interface UseEmailTemplateModel extends UseApiRequestModel<SuccessResponse> {
  emailTemplate?: EmailTemplate;
}

export interface UseEmailTemplateActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseEmailTemplateProps;
type Model = UseEmailTemplateModel;
type Actions = UseEmailTemplateActions;
type Result = [Model, Actions];

export const useEmailTemplate = (props: Props = {}): Result => {

  const {
    accountId = "",
    templateId = "",
    defaultErrorMessage = "Failed to get email template",
    ...otherProps
  } = props;

  const EmailTemplateClient = useEmailTemplateClient();

  const makeApiRequest = React.useCallback(() =>
    EmailTemplateClient.getEmailTemplate(accountId, templateId),
    [EmailTemplateClient, accountId, templateId]);

  const [{successResponse, ...baseModel}, { ...baseActions} ] =
    useApiRequest<SuccessResponse>({
    ...otherProps,
    defaultErrorMessage,
    makeApiRequest,
  });

  const emailTemplate = React.useMemo(() => {
    const attrs = successResponse || {};
    return new EmailTemplate(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    emailTemplate,
  }), [
    baseModel,
    emailTemplate,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
  }), [
    baseActions,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEmailTemplate;
