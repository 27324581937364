import { AppSchema } from "@schemas";
import { createActions } from "@modules/base";
import { equalsIgnoreCase, isEmptyString } from "@util";
import { RestClientError, SecurityGroupClient } from "@network";
import {
  GroupStatus,
  SecurityGroup,
  SecurityGroupBulkRequest,
  User,
  UserRequestStatus,
  UsersBulkRequest,
} from "@data";
import {
  ACTION_TYPES,
  DEFAULT_STATE,
  GroupManagementWizardAction,
  SelectSecurityGroupStep,
  SelectUserStep,
} from "./reducers";
import {
  getAccessToken,
  getAction,
  getGroupName,
  getSecurityGroupsBulkRequest,
  getUsersBulkRequest,
  getServiceId,
  getUserId,
  areUsersSelected,
  areGroupSelected,
} from "./selectors";

export const {
  userId: setUserId,
  serviceId: setServiceId,
  groupName: setGroupName,
  securityGroupBulkRequest: setSecurityGroupBulkRequestAttributes,
  userBulkRequest: setUsersBulkRequestAttributes,
  action: setAction,
  groupsStep: setGroupsStep,
  usersStep: setUsersStep,
  setErrorMessage,
  setSuccessMessage,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  ADD_USER_TO_GROUPS_REQUEST: addUserToGroupsRequest,
  ADD_USER_TO_GROUPS_SUCCESS: addUserToGroupsSuccess,
  ADD_USER_TO_GROUPS_FAILED: addUserToGroupsFailed,
  REMOVE_USER_FROM_GROUPS_REQUEST: removeUserFromGroupsRequest,
  REMOVE_USER_FROM_GROUPS_SUCCESS: removeUserFromGroupsSuccess,
  REMOVE_USER_FROM_GROUPS_FAILED: removeUserFromGroupsFailed,
  ADD_SERVICE_TO_GROUPS_REQUEST: addServiceToGroupsRequest,
  ADD_SERVICE_TO_GROUPS_SUCCESS: addServiceToGroupsSuccess,
  ADD_SERVICE_TO_GROUPS_FAILED: addServiceToGroupsFailed,
  REMOVE_SERVICE_FROM_GROUPS_REQUEST: removeServiceFromGroupsRequest,
  REMOVE_SERVICE_FROM_GROUPS_SUCCESS: removeServiceFromGroupsSuccess,
  REMOVE_SERVICE_FROM_GROUPS_FAILED: removeServiceFromGroupsFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setUserId());
  dispatch(setServiceId());
  dispatch(setGroupName());
  dispatch(setAction());
  dispatch(setSecurityGroupBulkRequestAttributes());
  dispatch(setUsersBulkRequestAttributes());
  dispatch(setGroupsStep());
  dispatch(setUsersStep());
  return dispatch(baseReset());
};

const setSecurityGroupBulkRequest = (requests: SecurityGroupBulkRequest[]) =>
  (dispatch: any) => dispatch(setSecurityGroupBulkRequestAttributes(requests.map(
    (request: SecurityGroupBulkRequest) => request.toJS())));

const setUserBulkRequest = (requests: UsersBulkRequest[]) =>
  (dispatch: any) => dispatch(setUsersBulkRequestAttributes(requests.map(
    (request: UsersBulkRequest) => request.toJS())));

export const showReviewView = () => (dispatch: any) =>
  dispatch(setGroupsStep(SelectSecurityGroupStep.REVIEW));

export const showGroupsView = () => (dispatch: any) =>
  dispatch(setGroupsStep(SelectSecurityGroupStep.GROUPS));

export const showUsersReviewView = () => (dispatch: any) =>
  dispatch(setUsersStep(SelectUserStep.REVIEW));

export const showUsersView = () => (dispatch: any) =>
  dispatch(setUsersStep(SelectUserStep.USERS));

export const showNextStep = () => (dispatch: any, getState: () => AppSchema) => {
  if (areUsersSelected(getState())) {
    return dispatch(showUsersReviewView());
  } else if (areGroupSelected(getState())) {
    return dispatch(showReviewView());
  }
};

export const showPreviousStep = () => (dispatch: any, getState: () => AppSchema) => {
  if (areUsersSelected(getState())) {
    return dispatch(showUsersView());
  } else if (areGroupSelected(getState())) {
    return dispatch(showGroupsView());
  }
};

export const updateUserId = (userId: string) => (dispatch: any) => {
  dispatch(setErrorMessage());
  return dispatch(setUserId(userId));
};

export const updateServiceId = (serviceId: string) => (dispatch: any) => {
  dispatch(setErrorMessage());
  return dispatch(setServiceId(serviceId));
};

export const setGroupStatus = (groupRequest: SecurityGroupBulkRequest, status: GroupStatus,
                               errorMessage: string = "") => (dispatch: any, getState: () => AppSchema) => {
    const state = getState();
    const securityGroups = getSecurityGroupsBulkRequest(state);

    const updatedSecurityGroup = new SecurityGroupBulkRequest({
      securityGroup: groupRequest.getSecurityGroup().toJS(),
      status: status,
      errorMessage: errorMessage,
    });

    let remainingSecurityGroups = [];
    for (let i = 0; i < securityGroups.length; i++) {
      const updated = equalsIgnoreCase(securityGroups[i].getSecurityGroupName(), groupRequest.getSecurityGroupName());
      remainingSecurityGroups.push(updated ? updatedSecurityGroup : securityGroups[i]);
    }

    return dispatch(setSecurityGroupBulkRequest(remainingSecurityGroups));
  };

export const setUserStatus = (userRequest: UsersBulkRequest, status: UserRequestStatus,
                               errorMessage: string = "") => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const users = getUsersBulkRequest(state);

  const updatedUsers = new UsersBulkRequest({
    user: userRequest.getUser().toJS(),
    status: status,
    errorMessage: errorMessage,
  });

  let remainingUsers = [];
  for (let i = 0; i < users.length; i++) {
    const updated = equalsIgnoreCase(users[i].getUserId(), userRequest.getUserId());
    remainingUsers.push(updated ? updatedUsers : users[i]);
  }

  return dispatch(setUserBulkRequest(remainingUsers));
};

export const updateSelectedSecurityGroups = (groups: SecurityGroup[]) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const bulkRequest = getSecurityGroupsBulkRequest(state);

    // Create a map to easily lookup previous status based on group name
    const statusRegistry = bulkRequest.reduce((data, request) => {
      data[request.getSecurityGroupName()] = request.getGroupStatus();
      return data;
    }, {});

    // Map the newly selected groups to a request object and copy the old status - if it exists
    const updatedBulkRequest = groups.map((securityGroup: SecurityGroup) =>
      new SecurityGroupBulkRequest({
        securityGroup: securityGroup.toJS(),
        status: statusRegistry[securityGroup.getName()] || SecurityGroupBulkRequest.EMPTY.status,
      }));

    return dispatch(setSecurityGroupBulkRequest(updatedBulkRequest));
  };

export const updateSelectedUsers = (users: User[]) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const bulkRequest = getUsersBulkRequest(state);

    // Create a map to easily lookup previous status based on group name
    const statusRegistry = bulkRequest.reduce((data, request) => {
      data[request.getUserId()] = request.getUserStatus();
      return data;
    }, {});

    // Map the newly selected groups to a request object and copy the old status - if it exists
    const updatedBulkRequest = users.map((user: User) =>
      new UsersBulkRequest({
        user: user.toJS(),
        status: statusRegistry[user.getUserId()] || UsersBulkRequest.EMPTY.status,
      }));

    return dispatch(setUserBulkRequest(updatedBulkRequest));
  };

const setAddToGroupStatusMessage = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const action = getAction(state);
  const userId = getUserId(state);
  const serviceId = getServiceId(state);
  const securityGroups = getSecurityGroupsBulkRequest(state);

  const successRequests = securityGroups.filter(groupsBulkRequest =>
    groupsBulkRequest.getGroupStatus() === GroupStatus.SUCCESS);

  if (successRequests.length === securityGroups.length) {
    if (action === GroupManagementWizardAction.ADD_USER) {
      dispatch(addUserToGroupsSuccess());
      dispatch(setSuccessMessage(`Added user '${userId}' to selected groups`));
    } else if (action === GroupManagementWizardAction.ADD_SERVICE) {
      dispatch(addServiceToGroupsSuccess());
      dispatch(setSuccessMessage(`Added service '${serviceId}' to selected groups`));
    }
    return dispatch(hideLoadingIndicator());
  }

  if (action === GroupManagementWizardAction.ADD_USER) {
    dispatch(addUserToGroupsFailed());
    dispatch(setErrorMessage(`Failed to add user '${userId}' to selected groups`));
  } else if (action === GroupManagementWizardAction.ADD_SERVICE) {
    dispatch(addServiceToGroupsFailed());
    dispatch(setErrorMessage(`Failed to add service '${serviceId}' to selected groups`));
  }

  return dispatch(hideLoadingIndicator());
};

const setAddToUserStatusMessage = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const action = getAction(state);
  const groupName = getGroupName(state);
  const users = getUsersBulkRequest(state);

  const successRequests = users.filter(usersBulkRequest =>
    usersBulkRequest.getUserStatus() === UserRequestStatus.SUCCESS);

  if (successRequests.length === users.length) {
    if (action === GroupManagementWizardAction.ADD_USER) {
      dispatch(addUserToGroupsSuccess());
      dispatch(setSuccessMessage(`Added selected users to group '${groupName}' `));
    }
    return dispatch(hideLoadingIndicator());
  }

  if (action === GroupManagementWizardAction.ADD_USER) {
    dispatch(addUserToGroupsFailed());
    dispatch(setErrorMessage(`Failed to add selected users to group '${groupName}' `));
  }

  return dispatch(hideLoadingIndicator());
};

const setRemoveFromUserStatusMessage = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const action = getAction(state);
  const groupName = getGroupName(state);
  const users = getUsersBulkRequest(state);

  const successRequests = users.filter(usersBulkRequest =>
    usersBulkRequest.getUserStatus() === UserRequestStatus.SUCCESS);

  if (successRequests.length === users.length) {
    if (action === GroupManagementWizardAction.REMOVE_USER) {
      dispatch(removeUserFromGroupsSuccess());
      dispatch(setSuccessMessage(`Removed selected users to group '${groupName}' `));
    }
    return dispatch(hideLoadingIndicator());
  }

  if (action === GroupManagementWizardAction.REMOVE_USER) {
    dispatch(removeUserFromGroupsFailed());
    dispatch(setErrorMessage(`Failed to remove selected users to group '${groupName}' `));
  }

  return dispatch(hideLoadingIndicator());
};

const setRemoveFromGroupStatusMessage = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const action = getAction(state);
  const userId = getUserId(state);
  const serviceId = getServiceId(state);
  const securityGroups = getSecurityGroupsBulkRequest(state);

  const successRequests = securityGroups.filter(groupsBulkRequest =>
    groupsBulkRequest.getGroupStatus() === GroupStatus.SUCCESS);

  if (successRequests.length === securityGroups.length) {
    if (action === GroupManagementWizardAction.REMOVE_USER) {
      dispatch(removeUserFromGroupsSuccess());
      dispatch(setSuccessMessage(`Removed user '${userId}' from selected groups`));
    } else if (action === GroupManagementWizardAction.REMOVE_SERVICE) {
      dispatch(removeServiceFromGroupsSuccess());
      dispatch(setSuccessMessage(`Removed service '${serviceId}' from selected groups`));
    }
    return dispatch(hideLoadingIndicator());
  }

  if (action === GroupManagementWizardAction.REMOVE_USER) {
    dispatch(removeUserFromGroupsFailed());
    dispatch(setErrorMessage(`Failed to remove user '${userId}' from selected groups`));
  } else if (action === GroupManagementWizardAction.REMOVE_SERVICE) {
    dispatch(removeServiceFromGroupsFailed());
    dispatch(setSuccessMessage(`Failed to remove service '${serviceId}' from selected groups`));
  }

  return dispatch(hideLoadingIndicator());
};

const addUserToGroups = (securityGroup: SecurityGroupBulkRequest) =>
  (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accessToken = getAccessToken(state);
  const userId = getUserId(state);
  const groupName = securityGroup.getSecurityGroup().getName();

  dispatch(setGroupStatus(securityGroup, GroupStatus.PROCESSING));

  return SecurityGroupClient.addUserToSecurityGroup(accessToken, userId, groupName)
    .then(() => {
      return dispatch(setGroupStatus(securityGroup, GroupStatus.SUCCESS));
    }, (error) => {
      return dispatch(setGroupStatus(securityGroup, GroupStatus.FAILED, error.description));
    });
};

const addUsersToGroup = (user: UsersBulkRequest) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();
    const accessToken = getAccessToken(state);
    const groupName = getGroupName(state);
    const userId = user.getUser().getUserId();

    dispatch(setUserStatus(user, UserRequestStatus.PROCESSING));

    return SecurityGroupClient.addUserToSecurityGroup(accessToken, userId, groupName)
      .then(() => {
        return dispatch(setUserStatus(user, UserRequestStatus.SUCCESS));
      }, (error) => {
        return dispatch(setUserStatus(user, UserRequestStatus.FAILED, error.description));
      });
  };

export const addUserToSecurityGroups = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const securityGroups = getSecurityGroupsBulkRequest(state);

  dispatch(showLoadingIndicator());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(addUserToGroupsRequest());

  const attachGroups = securityGroups
    .filter((securityGroup: SecurityGroupBulkRequest) => securityGroup.getGroupStatus() !== GroupStatus.SUCCESS)
    .map((securityGroup: SecurityGroupBulkRequest) => dispatch(addUserToGroups(securityGroup)));

  return Promise.all(attachGroups).then(() => dispatch(setAddToGroupStatusMessage()));
};

export const addUsersToSecurityGroup = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const users = getUsersBulkRequest(state);

  dispatch(showLoadingIndicator());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(addUserToGroupsRequest());

  const attachUsers = users
    .filter((user: UsersBulkRequest) => user.getUserStatus() !== UserRequestStatus.SUCCESS)
    .map((user: UsersBulkRequest) => dispatch(addUsersToGroup(user)));

  return Promise.all(attachUsers).then(() => dispatch(setAddToUserStatusMessage()));
};

const removeUserFromGroups = (securityGroup: SecurityGroupBulkRequest) =>
  (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accessToken = getAccessToken(state);
  const userId = getUserId(state);
  const groupName = securityGroup.getSecurityGroup().getName();

  dispatch(setGroupStatus(securityGroup, GroupStatus.PROCESSING));

  return SecurityGroupClient.removeUserFromSecurityGroup(accessToken, userId, groupName)
    .then(() => {
      return dispatch(setGroupStatus(securityGroup, GroupStatus.SUCCESS));
    }, (error) => {
      return dispatch(setGroupStatus(securityGroup, GroupStatus.FAILED, error.description));
    });
};

const removeUsersFromGroup = (user: UsersBulkRequest) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();
    const accessToken = getAccessToken(state);
    const groupName = getGroupName(state);
    const userId = user.getUser().getUserId();

    dispatch(setUserStatus(user, UserRequestStatus.PROCESSING));

    return SecurityGroupClient.removeUserFromSecurityGroup(accessToken, userId, groupName)
      .then(() => {
        return dispatch(setUserStatus(user, UserRequestStatus.SUCCESS));
      }, (error) => {
        return dispatch(setUserStatus(user, UserRequestStatus.FAILED, error.description));
      });
  };

export const removeUserFromSecurityGroups = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const securityGroups = getSecurityGroupsBulkRequest(state);

  dispatch(showLoadingIndicator());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(removeUserFromGroupsRequest());

  const removeGroups = securityGroups
    .filter((securityGroup: SecurityGroupBulkRequest) => securityGroup.getGroupStatus() !== GroupStatus.SUCCESS)
    .map((securityGroup: SecurityGroupBulkRequest) => dispatch(removeUserFromGroups(securityGroup)));

  return Promise.all(removeGroups).then(() => dispatch(setRemoveFromGroupStatusMessage()));
};

export const removeUsersFromSecurityGroup = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const users = getUsersBulkRequest(state);

  dispatch(showLoadingIndicator());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(removeUserFromGroupsRequest());

  const removeUsers = users
    .filter((user: UsersBulkRequest) => user.getUserStatus() !== UserRequestStatus.SUCCESS)
    .map((user: UsersBulkRequest) => dispatch(removeUsersFromGroup(user)));

  return Promise.all(removeUsers).then(() => dispatch(setRemoveFromUserStatusMessage()));
};

const addServiceToGroups = (securityGroup: SecurityGroupBulkRequest) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();
    const accessToken = getAccessToken(state);
    const serviceId = getServiceId(state);
    const groupName = securityGroup.getSecurityGroup().getName();

    dispatch(setGroupStatus(securityGroup, GroupStatus.PROCESSING));

    return SecurityGroupClient.addServiceToSecurityGroup(accessToken, serviceId, groupName)
      .then(() => {
        return dispatch(setGroupStatus(securityGroup, GroupStatus.SUCCESS));
      }, (error) => {
        return dispatch(setGroupStatus(securityGroup, GroupStatus.FAILED, error.description));
      });
  };

export const addServiceToSecurityGroups = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const securityGroups = getSecurityGroupsBulkRequest(state);

  dispatch(showLoadingIndicator());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(addServiceToGroupsRequest());

  const attachGroups = securityGroups
    .filter((securityGroup: SecurityGroupBulkRequest) => securityGroup.getGroupStatus() !== GroupStatus.SUCCESS)
    .map((securityGroup: SecurityGroupBulkRequest) => dispatch(addServiceToGroups(securityGroup)));

  return Promise.all(attachGroups).then(() => dispatch(setAddToGroupStatusMessage()));
};

const removeServiceFromGroups = (securityGroup: SecurityGroupBulkRequest) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();
    const accessToken = getAccessToken(state);
    const serviceId = getServiceId(state);
    const groupName = securityGroup.getSecurityGroup().getName();

    dispatch(setGroupStatus(securityGroup, GroupStatus.PROCESSING));

    return SecurityGroupClient.removeServiceFromSecurityGroup(accessToken, serviceId, groupName)
      .then(() => {
        return dispatch(setGroupStatus(securityGroup, GroupStatus.SUCCESS));
      }, (error) => {
        return dispatch(setGroupStatus(securityGroup, GroupStatus.FAILED, error.description));
      });
  };

export const removeServiceFromSecurityGroups = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const securityGroups = getSecurityGroupsBulkRequest(state);

  dispatch(showLoadingIndicator());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(removeUserFromGroupsRequest());

  const removeGroups = securityGroups
    .filter((securityGroup: SecurityGroupBulkRequest) => securityGroup.getGroupStatus() !== GroupStatus.SUCCESS)
    .map((securityGroup: SecurityGroupBulkRequest) => dispatch(removeServiceFromGroups(securityGroup)));

  return Promise.all(removeGroups).then(() => dispatch(setRemoveFromGroupStatusMessage()));
};

export const addServiceToGroup = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accessToken = getAccessToken(state);
  const serviceId = getServiceId(state);
  const groupName = getGroupName(state);

  dispatch(showLoadingIndicator());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(addServiceToGroupsRequest());

  return SecurityGroupClient.addServiceToSecurityGroup(accessToken, serviceId, groupName)
    .then(() => {

      dispatch(addServiceToGroupsSuccess());
      dispatch(setSuccessMessage(`Added service '${serviceId}' to group '${groupName}'`));
      return dispatch(hideLoadingIndicator());

    }, (response: RestClientError) => {

      const { analytic, error } = response;

      dispatch(addServiceToGroupsFailed(analytic));
      dispatch(setErrorMessage(error));
      return dispatch(hideLoadingIndicator());
    });
};

export const removeServiceFromGroup = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accessToken = getAccessToken(state);
  const serviceId = getServiceId(state);
  const groupName = getGroupName(state);

  dispatch(showLoadingIndicator());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(removeServiceFromGroupsRequest());

  return SecurityGroupClient.removeServiceFromSecurityGroup(accessToken, serviceId, groupName)
    .then(() => {

      dispatch(removeServiceFromGroupsSuccess());
      dispatch(setSuccessMessage(`Removed service '${serviceId}' from group '${groupName}'`));
      return dispatch(hideLoadingIndicator());

    }, (response: RestClientError) => {

      const { analytic, error } = response;

      dispatch(removeServiceFromGroupsFailed(analytic));
      dispatch(setErrorMessage(error));
      return dispatch(hideLoadingIndicator());
    });
};

export const addUserToGroup = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accessToken = getAccessToken(state);
  const userId = getUserId(state);
  const groupName = getGroupName(state);

  dispatch(showLoadingIndicator());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(addUserToGroupsRequest());

  return SecurityGroupClient.addUserToSecurityGroup(accessToken, userId, groupName)
    .then(() => {

      dispatch(addUserToGroupsSuccess());
      dispatch(setSuccessMessage(`Added user '${userId}' to group '${groupName}'`));
      return dispatch(hideLoadingIndicator());

    }, (response: RestClientError) => {

      const { analytic, error } = response;

      dispatch(addUserToGroupsFailed(analytic));
      dispatch(setErrorMessage(error));
      return dispatch(hideLoadingIndicator());
    });
};

export const removeUserFromGroup = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accessToken = getAccessToken(state);
  const userId = getUserId(state);
  const groupName = getGroupName(state);

  dispatch(showLoadingIndicator());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(removeUserFromGroupsRequest());

  return SecurityGroupClient.removeUserFromSecurityGroup(accessToken, userId, groupName)
    .then(() => {

      dispatch(removeUserFromGroupsSuccess());
      dispatch(setSuccessMessage(`Removed user '${userId}' from group '${groupName}'`));
      return dispatch(hideLoadingIndicator());

    }, (response: RestClientError) => {

      const { analytic, error } = response;

      dispatch(removeUserFromGroupsFailed(analytic));
      dispatch(setErrorMessage(error));
      return dispatch(hideLoadingIndicator());
    });
};

export const save = () => (dispatch: any, getState: () => AppSchema) => {

  const action = getAction(getState());
  const selectedSecurityGroups = getSecurityGroupsBulkRequest(getState());
  const selectedUsers = getUsersBulkRequest(getState());

  switch (action) {
    case GroupManagementWizardAction.ADD_USER:
      return selectedSecurityGroups.length > 0 ?
        dispatch(addUserToSecurityGroups()) :
        selectedUsers.length > 0 ?
          dispatch(addUsersToSecurityGroup()) : dispatch(addUserToGroup());
    case GroupManagementWizardAction.REMOVE_USER:
      return selectedSecurityGroups.length > 0 ?
        dispatch(removeUserFromSecurityGroups()) :
        selectedUsers.length > 0 ?
          dispatch(removeUsersFromSecurityGroup()) : dispatch(removeUserFromGroup());
    case GroupManagementWizardAction.ADD_SERVICE:
      return selectedSecurityGroups.length > 0 ?
        dispatch(addServiceToSecurityGroups()) : dispatch(addServiceToGroup());
    case GroupManagementWizardAction.REMOVE_SERVICE:
      return selectedSecurityGroups.length > 0 ?
        dispatch(removeServiceFromSecurityGroups()) : dispatch(removeServiceFromGroup());
    default:
      return dispatch(setErrorMessage("Invalid Action"));
  }
};

export const initialize = (props: {
  userId?: string,
  serviceId?: string,
  groupName?: string,
  action?: "add" | "remove",
  identityType?: "user" | "service",
}) => (dispatch: any) => {

  const {
    userId = "",
    serviceId = "",
    groupName = "",
    identityType = "user",
    action: actionType = "add",
  } = props;

  dispatch(reset());
  dispatch(setGroupName(groupName));

  if (isEmptyString(groupName)) {
    dispatch(setGroupsStep(SelectSecurityGroupStep.GROUPS));
  }

  if (!isEmptyString(groupName) && equalsIgnoreCase("user", identityType)) {
    dispatch(setUsersStep(SelectUserStep.USERS));
  }

  if (equalsIgnoreCase("service", identityType)) {
    dispatch(setServiceId(serviceId));
    return dispatch(setAction(
      equalsIgnoreCase("add", actionType)
        ? GroupManagementWizardAction.ADD_SERVICE
        : GroupManagementWizardAction.REMOVE_SERVICE));
  }

  dispatch(setUserId(userId));
  return dispatch(setAction(
    equalsIgnoreCase("add", actionType)
      ? GroupManagementWizardAction.ADD_USER
      : GroupManagementWizardAction.REMOVE_USER));
};
