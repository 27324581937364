import React from "react";
import classnames from "classnames";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
} from "@components/submit-api-request-view";
import EmailTemplateWizardState from "../models/EmailTemplateWizardState";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { DEFAULT_STEPS, DEFAULT_STEP_LABELS, EmailTemplateWizardStep } from "../helpers";
import styles from "./styles";

export interface Model extends SubmitApiRequestViewModel<EmailTemplateWizardState, EmailTemplateWizardStep> {
  stepLabels?: { [key: string]: string };
}

export interface Actions extends SubmitApiRequestViewActions<EmailTemplateWizardState, EmailTemplateWizardStep> {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const EmailTemplateWizard = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    snackbarId = "email-template-wizard",
    errorTitle = "Save Email Template Failed",
    saveButtonLabel = "Submit",
    steps = DEFAULT_STEPS,
    stepLabels = DEFAULT_STEP_LABELS,
    currentState = EmailTemplateWizardState.EMPTY,
    defaultState = EmailTemplateWizardState.EMPTY,
    mapStepToLabel = React.useCallback(step => stepLabels[step] || null, [stepLabels]),
    children,
    ...otherProps
  } = props;

  return (
    <SubmitApiRequestView
      {...otherProps}
      className={classnames("emailTemplateWizard", className, classes.container)}
      snackbarId={snackbarId}
      errorTitle={errorTitle}
      saveButtonLabel={saveButtonLabel}
      steps={steps}
      currentState={currentState}
      defaultState={defaultState}
      mapStepToLabel={mapStepToLabel}
    >
      {children}
    </SubmitApiRequestView>
  );
});

export default EmailTemplateWizard;
