import React from "react";
import { isEmptyString } from "@util/Functions";
import { Primitive, createComparatorFactory } from "@util/Comparators";

interface Props<Item, Column> {
  items?: Item[];
  nameFilter?: string;
  sortByColumn?: Column;
  sortOrderAscending?: boolean;
  mapItemToColumnValue?: (item: Item, column: Column) => Primitive;
  filterResultsByNameFilter?: (item: Item, nameFilter: string) => boolean;
}

export { Props as SortAndFilterResultsProps };

export const sortAndFilterResults = <Item, Column>(props: Props<Item, Column>) => {

  const {
    items = [],
    nameFilter = "",
    sortByColumn,
    sortOrderAscending,
    mapItemToColumnValue = () => "",
    filterResultsByNameFilter = () => true,
  } = props;

  const createComparator = React.useMemo(() =>
      createComparatorFactory<Item, Column>(mapItemToColumnValue),
    [mapItemToColumnValue]);

  const filteredResults = React.useMemo(() => {

    if (items.length === 0 || isEmptyString(nameFilter)) {
      return items;
    }

    return items.filter((item: Item) => filterResultsByNameFilter(item, nameFilter));

  }, [items, nameFilter, filterResultsByNameFilter]);

  const comparator = React.useMemo(() => {
      if (!sortByColumn) {
        return () => 0;
      } else {
        return createComparator(sortByColumn, sortOrderAscending);
      }
    },
    [createComparator, sortByColumn, sortOrderAscending]);

  return React.useMemo(() =>
      filteredResults.sort(comparator).slice(),
    [filteredResults, comparator]);
};

export default sortAndFilterResults;
