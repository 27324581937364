import React from "react";
import ReleaseNotes from "./containers/ReleaseNotes";
import { Redirect, Route, Switch } from "react-router-dom";
import { ReleaseNotePlatformDomain } from "@data/ReleaseNote";
import { ReleaseNotesTab } from "./components/ReleaseNotesTabs";
import useSelectedTab from "@modules/releaseNotes/useSelectedTab";
import { BASE_URL, SECURITY_SERVICES_RELEASE_NOTES_PATH } from "@modules/releaseNotes/constants";

export function ReleaseNotesView() {

  const selectedTab = useSelectedTab();

  const platformDomain = React.useMemo(() => {
    switch (selectedTab) {
      case ReleaseNotesTab.DATA_AND_ANALYTICS:
        return ReleaseNotePlatformDomain.DATA_AND_ANALYTICS;
      case ReleaseNotesTab.COMMON_SERVICES:
        return ReleaseNotePlatformDomain.COMMON_SERVICES;
      case ReleaseNotesTab.DEVICE_MANAGEMENT:
        return ReleaseNotePlatformDomain.DEVICE_MANAGEMENT;
      case ReleaseNotesTab.SELF_SERVICE_TOOLS:
        return ReleaseNotePlatformDomain.SELF_SERVICE_TOOLS;
      case ReleaseNotesTab.SECURITY_SERVICES:
      default:
        return ReleaseNotePlatformDomain.SECURITY_SERVICES;
    }
  }, [selectedTab]);

  return (
    <Switch>
      <Route path={BASE_URL} exact={true} strict={true}>
        <Redirect to={SECURITY_SERVICES_RELEASE_NOTES_PATH}/>
      </Route>
      <Route>
        <ReleaseNotes
          key={platformDomain}
          platformDomain={platformDomain}
          selectedTab={selectedTab}
        />
      </Route>
    </Switch>
  );
}

export default ReleaseNotesView;
