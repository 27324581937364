import { combineReducers } from "redux";
import { Actions, filterAction, Identity } from "../../../util/ReducerUtil";

export enum ManageSoftwareVersionsAction {
  NONE = "",
  ADD_SOFTWARE_VERSION = "add_software_version",
  REMOVE_SOFTWARE_VERSION = "remove_software_version",
}

export interface ManageSoftwareVersionsSchema {
  action: ManageSoftwareVersionsAction;
  softwareVersion: string;
  errorMessage: string;
  successMessage: string;
  showAccessDenied: boolean;
  showLoadingIndicator: boolean;
  showRemoveDialog: boolean;
}

export const DEFAULT_STATE: ManageSoftwareVersionsSchema = Object.freeze({
  action: ManageSoftwareVersionsAction.NONE,
  softwareVersion: "",
  errorMessage: "",
  successMessage: "",
  showAccessDenied: false,
  showLoadingIndicator: false,
  showRemoveDialog: false,
});

export enum ManageSoftwareVersionsActionType {
  SET_ACTION = "manage_software_versions/set_action",
  SET_SOFTWARE_VERSION = "manage_software_versions/set_software_version",
  SET_ERROR_MESSAGE = "manage_software_versions/set_error_message",
  SET_SUCCESS_MESSAGE = "manage_software_versions/set_success_message",
  SET_SHOW_ACCESS_DENIED = "manage_software_versions/set_show_access_denied",
  SET_SHOW_LOADING_INDICATOR = "manage_software_versions/set_show_loading_indicator",
  SET_SHOW_REMOVE_DIALOG = "manage_software_versions/set_show_remove_dialog",
  ADD_SOFTWARE_VERSION_REQUEST = "manage_software_versions/add_software_version_request",
  ADD_SOFTWARE_VERSION_SUCCESS = "manage_software_versions/add_software_version_success",
  ADD_SOFTWARE_VERSION_FAILED = "manage_software_versions/add_software_version_failed",
  REMOVE_SOFTWARE_VERSION_REQUEST = "manage_software_versions/remove_software_version_request",
  REMOVE_SOFTWARE_VERSION_SUCCESS = "manage_software_versions/remove_software_version_success",
  REMOVE_SOFTWARE_VERSION_FAILED = "manage_software_versions/remove_software_version_failed",
}

const action = filterAction<ManageSoftwareVersionsAction>(Actions(
  ManageSoftwareVersionsActionType.SET_ACTION))(Identity,
  DEFAULT_STATE.action);

const softwareVersion = filterAction<string>(Actions(
  ManageSoftwareVersionsActionType.SET_SOFTWARE_VERSION))(Identity,
  DEFAULT_STATE.softwareVersion);

const errorMessage = filterAction<string>(Actions(
  ManageSoftwareVersionsActionType.SET_ERROR_MESSAGE))(Identity,
  DEFAULT_STATE.errorMessage);

const successMessage = filterAction<string>(Actions(
  ManageSoftwareVersionsActionType.SET_SUCCESS_MESSAGE))(Identity,
  DEFAULT_STATE.successMessage);

const showAccessDenied = filterAction<boolean>(Actions(
  ManageSoftwareVersionsActionType.SET_SHOW_ACCESS_DENIED))(Identity,
  DEFAULT_STATE.showAccessDenied);

const showLoadingIndicator = filterAction<boolean>(Actions(
  ManageSoftwareVersionsActionType.SET_SHOW_LOADING_INDICATOR))(Identity,
  DEFAULT_STATE.showLoadingIndicator);

const showRemoveDialog = filterAction<boolean>(Actions(
  ManageSoftwareVersionsActionType.SET_SHOW_REMOVE_DIALOG))(Identity,
  DEFAULT_STATE.showRemoveDialog);

export const ManageSoftwareVersionsReducer = combineReducers<ManageSoftwareVersionsSchema>({
  action,
  softwareVersion,
  errorMessage,
  successMessage,
  showAccessDenied,
  showLoadingIndicator,
  showRemoveDialog,
});

export default ManageSoftwareVersionsReducer;
