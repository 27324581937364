import { Breadcrumb } from "@data";
import { MODULE_TITLE } from "./constants";
import { match as Match } from "react-router-dom";
import welcomePageModule from "@modules/welcome/module";
import { ReleaseNotesTab } from "@modules/releaseNotes/components/ReleaseNotesTabs";
import {
  BASE_URL as RELEASE_NOTES_BASE_URL,
  COMMON_SERVICES_RELEASE_NOTES_PATH,
  DATA_AND_ANALYTICS_RELEASE_NOTES_PATH,
  DEVICE_MANAGEMENT_RELEASE_NOTES_PATH,
  MODULE_TITLE as RELEASE_NOTES_TITLE,
  SECURITY_SERVICES_RELEASE_NOTES_PATH,
  SELF_SERVICE_TOOLS_RELEASE_NOTES_PATH,
} from "@modules/releaseNotes/constants";

const BASE_BREADCRUMBS = Object.freeze<Breadcrumb[]>([
  new Breadcrumb({
    title: welcomePageModule.breadcrumbTitle,
    url: welcomePageModule.path,
  }),
  new Breadcrumb({
    title: RELEASE_NOTES_TITLE,
    url: RELEASE_NOTES_BASE_URL,
  }),
]);

const PLATFORM_DOMAIN_BREADCRUMBS = Object.freeze<Breadcrumb[]>([
  new Breadcrumb({
    title: ReleaseNotesTab.SELF_SERVICE_TOOLS,
    url: SELF_SERVICE_TOOLS_RELEASE_NOTES_PATH,
  }),
  new Breadcrumb({
    title: ReleaseNotesTab.DATA_AND_ANALYTICS,
    url: DATA_AND_ANALYTICS_RELEASE_NOTES_PATH,
  }),
  new Breadcrumb({
    title: ReleaseNotesTab.COMMON_SERVICES,
    url: COMMON_SERVICES_RELEASE_NOTES_PATH,
  }),
  new Breadcrumb({
    title: ReleaseNotesTab.DEVICE_MANAGEMENT,
    url: DEVICE_MANAGEMENT_RELEASE_NOTES_PATH,
  }),
  new Breadcrumb({
    title: ReleaseNotesTab.SECURITY_SERVICES,
    url: SECURITY_SERVICES_RELEASE_NOTES_PATH,
  }),
]);

function customBreadcrumbsFactory(match: Match) {

  const { path, url } = match;

  return BASE_BREADCRUMBS
    .concat(PLATFORM_DOMAIN_BREADCRUMBS
      .find(breadcrumb => path.indexOf(breadcrumb.getURL()) >= 0) || [])
    .concat([new Breadcrumb({
      title: MODULE_TITLE,
      url,
    })]);
}

export default customBreadcrumbsFactory;
