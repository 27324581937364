import { Module } from "@data";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./DigitalShadowLayoutDetailsView";
import { isDigitalShadowEnabled } from "@util";

export const digitalShadowLayoutDetailsModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: false,
  name: "Shadow Layout Details",
  title: "Shadow Layout Details",
  className: "digitalShadowLayoutDetailsModule",
  primaryColor,
  icon,
  view,
  disabled: !isDigitalShadowEnabled(),
});

export default digitalShadowLayoutDetailsModule;
