import { combineReducers } from "redux";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base/createReducers";
import { MODULE_ID } from "../constants";
import { CreateDataSetRequest, CreateDataSetRequestAttributes } from "@data/CreateDataSetRequest";

export enum DataSetRequestWizardStep {
  NONE = "",
  DATA_SET_ALIAS = "data_set_alias",
  DATA_OWNERS = "data_owners",
  ARCHITECTS = "architects",
  ENGINEERING_CONTACTS = "engineering_contacts",
  BUSINESS_OWNERS = "business_owners",
  DATA_DESCRIPTION = "data_description",
  TERMS_CONDITIONS = "terms_conditions",
  REVIEW = "review",
}

export interface DataSetRequestWizardSchema extends BasePortalModuleSchema {
  createDataSetRequest: CreateDataSetRequestAttributes;
  dataSetRequestWizardStep: DataSetRequestWizardStep;
  json: string;
  showEditMode: boolean;
  requestIoTApproved: boolean;
  dataSetRequestRef: string;
  dataRangeErrorMessage: string;
  lifecycleErrorMessage: string;
  showEditModeInitializeError: boolean;
  termsConditionsFileProvided: boolean;
  containsPII: boolean;
}

export const DEFAULT_STATE: DataSetRequestWizardSchema = createPortalModuleDefaultState({
  showEmptyView: false,
  createDataSetRequest: { ...CreateDataSetRequest.EMPTY.toJS() },
  dataSetRequestWizardStep: DataSetRequestWizardStep.DATA_SET_ALIAS,
  json: JSON.stringify(CreateDataSetRequest.EMPTY.toJS(), null, "  "),
  showEditMode: false,
  requestIoTApproved: false,
  dataSetRequestRef: "",
  dataRangeErrorMessage: "",
  lifecycleErrorMessage: "",
  showEditModeInitializeError: false,
  termsConditionsFileProvided: false,
  containsPII: false,
});

type DataSetRequestWizardActionTypes = PortalModuleActionTypes<DataSetRequestWizardSchema> & {
  CREATE_DATA_SET_REQUEST_REQUEST: string;
  CREATE_DATA_SET_REQUEST_SUCCESS: string;
  CREATE_DATA_SET_REQUEST_FAILED: string;
  FETCH_DATA_SET_REQUEST_REQUEST: string,
  FETCH_DATA_SET_REQUEST_SUCCESS: string,
  FETCH_DATA_SET_REQUEST_FAILED: string,
  EDIT_DATA_SET_REQUEST_REQUEST: string;
  EDIT_DATA_SET_REQUEST_SUCCESS: string;
  EDIT_DATA_SET_REQUEST_FAILED: string;
  ADD_TERMS_CONDITIONS_FILE: string;
  REMOVE_TERMS_CONDITIONS_FILE: string;
};

export const ACTION_TYPES: DataSetRequestWizardActionTypes = {
  ...createPortalModuleActionTypes<DataSetRequestWizardSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    CREATE_DATA_SET_REQUEST_REQUEST: `${MODULE_ID}/create_data_set_request_request`,
    CREATE_DATA_SET_REQUEST_SUCCESS: `${MODULE_ID}/create_data_set_request_success`,
    CREATE_DATA_SET_REQUEST_FAILED: `${MODULE_ID}/create_data_set_request_failed`,
    FETCH_DATA_SET_REQUEST_REQUEST: `${MODULE_ID}/fetch_data_set_request_request`,
    FETCH_DATA_SET_REQUEST_SUCCESS: `${MODULE_ID}/fetch_data_set_request_success`,
    FETCH_DATA_SET_REQUEST_FAILED: `${MODULE_ID}/fetch_data_set_request_failed`,
    EDIT_DATA_SET_REQUEST_REQUEST: `${MODULE_ID}/edit_data_set_request_request`,
    EDIT_DATA_SET_REQUEST_SUCCESS: `${MODULE_ID}/edit_data_set_request_success`,
    EDIT_DATA_SET_REQUEST_FAILED: `${MODULE_ID}/edit_data_set_request_failed`,
    ADD_TERMS_CONDITIONS_FILE: `${MODULE_ID}/add_terms_conditions_file`,
    REMOVE_TERMS_CONDITIONS_FILE: `${MODULE_ID}/remove_terms_conditions_file`,
  }
};

export const DataSetRequestWizardReducer = combineReducers<DataSetRequestWizardSchema>(
  createPortalModuleReducers<DataSetRequestWizardSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DataSetRequestWizardReducer;
