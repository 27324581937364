import React from "react";
import classnames from "classnames";
import Switch from "@material-ui/core/Switch";
import { ThemeProvider } from "@components/styles";
import { clickHandler, isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import theme from "./theme";

export interface FilterSwitchModel {
  className?: string;
  title?: string;
  titleClassName?: string;
  showTitle?: boolean;
  leftLabelClassName?: string;
  rightLabelClassName?: string;
  disabled?: boolean;
  checked?: boolean;
  color?: "primary" | "secondary" | "default";
  leftLabel?: string;
  rightLabel?: string;
}

export interface FilterSwitchActions {
  onChange?: (updatedValue: boolean) => void;
}

type Model = FilterSwitchModel;
type Actions = FilterSwitchActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const FilterSwitch = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Switch",
    titleClassName,
    showTitle = false,
    leftLabelClassName,
    rightLabelClassName,
    disabled,
    color = "primary",
    leftLabel = "",
    rightLabel = "",
    checked,
    onChange = noop,
    children,
  } = props;

  const showLeftLabel = React.useMemo(() => !isEmptyString(leftLabel), [leftLabel]);

  const showRightLabel = React.useMemo(() => !isEmptyString(rightLabel), [rightLabel]);

  const onChangeHandler = React.useCallback(() => {
    onChange(!checked);
  }, [checked, onChange]);

  const onClickLeftLabel = React.useCallback(clickHandler(() => {
    if (!disabled) {
      if (!showRightLabel) {
        onChangeHandler();
      } else {
        onChange(false);
      }
    }
  }), [disabled, showRightLabel, onChange, onChangeHandler]);

  const onClickRightLabel = React.useCallback(clickHandler(() => {
    if (!disabled) {
      if (!showLeftLabel) {
        onChangeHandler();
      } else {
        onChange(true);
      }
    }
  }), [disabled, showLeftLabel, onChange, onChangeHandler]);

  return (
    <ThemeProvider theme={theme}>
      {showTitle && (
        <label className={classnames("title", titleClassName, classes.title)}>{title}</label>
      )}
      <div className={classnames("filterSwitch", className, classes.container)}>
        {showLeftLabel && (
          <label
            className={classnames("leftLabel", leftLabelClassName, classes.label)}
            onClick={onClickLeftLabel}
          >
            {leftLabel}
          </label>
        )}
        <Switch
          className={classnames("switch", classes.switch)}
          color={color}
          disabled={disabled}
          checked={checked}
          onChange={onChangeHandler}
        />
        {showRightLabel && (
          <label
            className={classnames("rightLabel", rightLabelClassName, classes.label)}
            onClick={onClickRightLabel}
          >
            {rightLabel}
          </label>
        )}
        {children}
      </div>
    </ThemeProvider>
  );
});

export default FilterSwitch;
