import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";

export interface DeleteDataSetRequestSchema extends BasePortalModuleSchema {
  accountId: string;
  dataSetAlias: string;
  open: boolean;
}

export const DEFAULT_STATE: DeleteDataSetRequestSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  open: false,
  accountId: "",
  dataSetAlias: "",
});

export type DeleteDataSetRequestActionTypes = PortalModuleActionTypes<DeleteDataSetRequestSchema> & {
  DELETE_DATA_SET_REQUEST_REQUEST: string;
  DELETE_DATA_SET_REQUEST_SUCCESS: string;
  DELETE_DATA_SET_REQUEST_FAILED: string;
};

export type DeleteDataSetRequestActionType =
  DeleteDataSetRequestActionTypes[keyof DeleteDataSetRequestActionTypes];

export const ACTION_TYPES: DeleteDataSetRequestActionTypes = {
  ...createPortalModuleActionTypes<DeleteDataSetRequestSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    DELETE_DATA_SET_REQUEST_REQUEST: `${MODULE_ID}/delete_data_set_request_request`,
    DELETE_DATA_SET_REQUEST_SUCCESS: `${MODULE_ID}/delete_data_set_request_success`,
    DELETE_DATA_SET_REQUEST_FAILED: `${MODULE_ID}/delete_ddata_set_request_failed`,
  },
};

export const DeleteDataSetRequestReducer = combineReducers<DeleteDataSetRequestSchema>(
  createPortalModuleReducers<DeleteDataSetRequestSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DeleteDataSetRequestReducer;
