import { Module } from "@data";
import { initialize } from "./actions";
import { createModule } from "@modules/base";
import { primaryColor, PrimaryIcon } from "./components/styles";
import { MODULE_ID, MODULE_PATH } from "./constants";
import view from "./SecurityServiceDetailsView";

type RouteParams = { id?: string };

export const securityServiceRegionalDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: false,
  name: "Security Service Details",
  title: "Service Details",
  className: "securityServiceRegionalDetailsModule",
  primaryColor,
  icon: PrimaryIcon,
  view,
  onMount: ({ id = "" }: RouteParams = {}) => initialize(id),
});

export default securityServiceRegionalDetailsModule;
