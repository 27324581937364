import React from "react";
import classnames from "classnames";
import { noop } from "../../util";
import { ActionMenuItem, ActionsMenu, ActionsMenuProps } from "../actions-menu";

export enum SchemaAction {
  NONE = "",
  EDIT = "edit_schema",
  DRAFT = "draft_schema",
  PROMOTE = "promote_schema",
  DEPRECATE = "deprecate_schema",
  DECOMMISSION = "decommission_schema",
  DELETE = "delete_schema",
}

export const EDIT_SCHEMA_ACTION: ActionMenuItem = Object.freeze({
  id: SchemaAction.EDIT,
  name: "Edit Schema",
  disabled: false,
  hidden: false,
});

export const DRAFT_SCHEMA_ACTION: ActionMenuItem = Object.freeze({
  id: SchemaAction.DRAFT,
  name: "Draft New Schema Version",
  disabled: false,
  hidden: false,
});

export const PROMOTE_SCHEMA_ACTION: ActionMenuItem = Object.freeze({
  id: SchemaAction.PROMOTE,
  name: "Promote Schema",
  disabled: false,
  hidden: false,
});

export const DEPRECATE_SCHEMA_ACTION: ActionMenuItem = Object.freeze({
  id: SchemaAction.DEPRECATE,
  name: "Deprecate Schema",
  disabled: false,
  hidden: false,
});

export const DECOMMISSION_SCHEMA_ACTION: ActionMenuItem = Object.freeze({
  id: SchemaAction.DECOMMISSION,
  name: "Decommission Schema",
  disabled: false,
  hidden: false,
});

export const DELETE_SCHEMA_ACTION: ActionMenuItem = Object.freeze({
  id: SchemaAction.DELETE,
  name: "Delete Schema",
  disabled: false,
  hidden: false,
});

export const DEFAULT_SCHEMA_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  EDIT_SCHEMA_ACTION,
  DRAFT_SCHEMA_ACTION,
  PROMOTE_SCHEMA_ACTION,
  DEPRECATE_SCHEMA_ACTION,
  DECOMMISSION_SCHEMA_ACTION,
  DELETE_SCHEMA_ACTION,
]) as ActionMenuItem[];

export interface SchemaActionHandlers {
  editSchema?: () => void;
  draftNewSchemaVersion?: () => void;
  promoteSchema?: () => void;
  deprecateSchema?: () => void;
  decommissionSchema?: () => void;
  deleteSchema?: () => void;
}

export const SchemaActionsMenu = (props: SchemaActionHandlers & ActionsMenuProps) => {

  const {
    className,
    buttonLabel = "Schema Actions",
    actions = DEFAULT_SCHEMA_ACTION_MENU_ITEMS,
    onClickAction = noop,
    editSchema = noop,
    draftNewSchemaVersion = noop,
    promoteSchema = noop,
    deprecateSchema = noop,
    decommissionSchema = noop,
    deleteSchema = noop,
    ...otherProps
  } = props;

  return (
    <ActionsMenu
      {...otherProps}
      className={classnames("schemaActionsMenu", className)}
      actions={actions}
      buttonLabel={buttonLabel}
      onClickAction={(action: ActionMenuItem) => {
        switch (action.id) {
          case SchemaAction.EDIT:
            return editSchema();
          case SchemaAction.DRAFT:
            return draftNewSchemaVersion();
          case SchemaAction.PROMOTE:
            return promoteSchema();
          case SchemaAction.DEPRECATE:
            return deprecateSchema();
          case SchemaAction.DECOMMISSION:
            return decommissionSchema();
          case SchemaAction.DELETE:
            return deleteSchema();
          default:
            return onClickAction(action);
        }
      }}
    />
  );
};

export default SchemaActionsMenu;
