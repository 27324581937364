import React from "react";
import { styles } from "./styles";
import { TagEditor } from "@components";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { useUpdateInternetAccessConfiguration } from "@hooks";
import { WorkloadInternetAccessConfig } from "@data";
import DnsNameIcon from "@material-ui/icons/CheckCircle";

export interface WorkloadDnsNamesDialogModel extends PortalModuleDialogModel<string> {
  name?: string;
  version?: number;
  etag?: string;
  configuration: WorkloadInternetAccessConfig;
  dialogClassName?: string;
}

export interface WorkloadDnsNamesDialogActions extends PortalModuleDialogActions<string> {
  setDnsNames?: (names: string[]) => void;
}

type Props = WithStyles<typeof styles> & WorkloadDnsNamesDialogModel & WorkloadDnsNamesDialogActions;

export const WorkloadDnsNamesDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    dialogClassName,
    name = "",
    version = 1,
    etag = "",
    configuration,
    setDnsNames = noop,
    cancel: cancelDialog = noop,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  if (isEmptyString(name)) {
    return null;
  }

  const [updatedNames, setUpdatedNames] = React.useState<string[]>([]);

  const dnsNames = React.useMemo(() => configuration.whitelistedDnsNames, [configuration]);

  const [{ showLoadingIndicator, ...otherModel }, { updateInternetAccessConfiguration, reset }] =
    useUpdateInternetAccessConfiguration({ name, version, etag,
      configuration: { internetAccess: configuration.internetAccess, whitelistedDnsNames: updatedNames } });

  const confirm = React.useCallback(() => {
    updateInternetAccessConfiguration();
  }, [updateInternetAccessConfiguration]);

  const cancel = React.useCallback(() => {
    reset();
    setUpdatedNames(dnsNames);
    cancelDialog();
  }, [reset, setUpdatedNames, dnsNames, cancelDialog]);

  const onSuccessMessageShown = React.useCallback(() => {
    reset();
    onSuccess();
  }, [reset, onSuccess]);

  React.useEffect(() => {
    setUpdatedNames(dnsNames);
  }, []);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      className={classnames("workloadDnsNamesDialog", dialogClassName)}
      title="Edit DNS Names"
      loading={showLoadingIndicator}
      continueButtonLabel="Save"
      onSuccessMessageShown={onSuccessMessageShown}
      confirm={confirm}
      cancel={cancel}
    >
      <div className={classnames("workloadDnsNamesContainer", classes.container)}>
        <TagEditor
          tags={updatedNames}
          label="Add Whitelisted DNS Names"
          emptyValueLabel="No DNS Names Defined"
          icon={<DnsNameIcon className={classnames("nameIcon", classes.nameIcon)}/>}
          fullWidth={true}
          setTags={setUpdatedNames}
          helperText="DNS names to allow access to the internet"
        />
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadDnsNamesDialog;
