import { Record } from "immutable";
import { EmailBody, EmailBodyAttributes } from "./EmailBody";
import { equalsIgnoreCase, getStringValue, isEmptyString } from "@util";

export interface EmailTemplateAttributes {
  id: string;
  applicationId: string;
  messageType: string;
  language: string;
  body: EmailBodyAttributes;
  s3Bucket?: string;
  s3Key?: string;
}

export class EmailTemplate extends Record({
  id: "",
  applicationId: "",
  messageType: "",
  language: "",
  body: EmailBody.EMPTY.toJS(),
  s3Bucket: "",
  s3Key: "",
}) implements EmailTemplateAttributes {

  public static EMPTY: EmailTemplate = new EmailTemplate();

  public readonly id: string;
  public readonly applicationId: string;
  public readonly messageType: string;
  public readonly language: string;
  public readonly body: EmailBodyAttributes;
  public readonly s3Bucket: string;
  public readonly s3Key: string;

  // We need to override equals because this Record contains a nested Object property
  //
  // Reference: https://immutable-js.github.io/immutable-js/docs/#/Record/equals
  // Value equality: Records use value equality when compared with is() or record.equals().
  // That is, two Records with the same keys and values are equal. Plain objects use reference
  // equality. Two objects with the same keys and values are not equal since they are different
  // objects. This is important to consider when using objects as keys in a Map or values in a
  // Set, which use equality when retrieving values.
  public equals(other: EmailTemplate): boolean {
    return equalsIgnoreCase(this.getId(), other.getId()) &&
      equalsIgnoreCase(this.getMessageType(), other.getMessageType()) &&
      equalsIgnoreCase(this.getLanguage(), other.getLanguage()) &&
      this.getBody().equals(other.getBody()) &&
      equalsIgnoreCase(this.getS3Bucket(), other.getS3Bucket()) &&
      equalsIgnoreCase(this.getS3Key(), other.getS3Key());
  }

  public getId(): string {
    return getStringValue(this.id);
  }

  public getApplicationId(): string {
    return getStringValue(this.applicationId);
  }

  public hasApplicationId(): boolean {
    return !isEmptyString(this.getApplicationId());
  }

  public getMessageType(): string {
    return getStringValue(this.messageType);
  }

  public getLanguage(): string {
    return getStringValue(this.language);
  }

  public getS3Bucket(): string {
    return getStringValue(this.s3Bucket);
  }

  public getS3Key(): string {
    return getStringValue(this.s3Key);
  }

  public getBody(): EmailBody {
    return new EmailBody(this.body);
  }

  public getTextBody(): string {
    return this.getBody().getTextBody();
  }

  public getHtmlBody(): string {
    return this.getBody().getHtmlBody();
  }

  public getDescription(): string {
    return this.getBody().getDescription();
  }

  public getEmailSubject(): string {
    return this.getBody().getEmailSubject();
  }
}

export default EmailTemplate;
