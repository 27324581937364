import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import {
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel,
} from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface Model extends ConfirmationDialogModel<void> {
  currentAccountId?: string;
  otherAccountId?: string;
  currentServiceId?: string;
  currentUserId?: string;
  otherUserId?: string;
}

export interface Actions extends ConfirmationDialogActions<void> {
  removeAccountIdQueryParam?: () => void;
  removeUserIdQueryParam?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const SessionMismatchDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    currentAccountId,
    otherAccountId,
    currentServiceId,
    currentUserId,
    otherUserId,
    removeAccountIdQueryParam = noop,
    removeUserIdQueryParam = noop,
    children,
    ...otherProps
  } = props;

  const { open } = otherProps;

  const showAccountInfo = React.useMemo(() =>
    !isEmptyString(currentAccountId) && !isEmptyString(otherAccountId),
    [currentAccountId, otherAccountId]);

  const showUserInfo = React.useMemo(() =>
    (!isEmptyString(currentServiceId) || !isEmptyString(currentUserId)) && !isEmptyString(otherUserId),
    [currentServiceId, currentUserId, otherUserId]);

  const title = React.useMemo(() => {
    if (showAccountInfo && !showUserInfo) {
      return "Oops, you are not logged into that IoT account";
    } else if (!showAccountInfo && showUserInfo) {
      return "Oops, you are not logged in as that user";
    } else {
      return "Oops, you are not logged in as that IoT account user principal";
    }
  }, [showAccountInfo, showUserInfo]);

  const cancel = React.useCallback(() => {

    if (showAccountInfo) {
      removeAccountIdQueryParam();
    }

    if (showUserInfo) {
      removeUserIdQueryParam();
    }

  }, [showAccountInfo, showUserInfo, removeAccountIdQueryParam, removeUserIdQueryParam]);

  const maxWidth = React.useMemo(() =>
    showAccountInfo && showUserInfo ? "md" : "sm", [showAccountInfo, showUserInfo]);

  if (!open) {
    return null;
  }

  return (
    <ConfirmationDialog
      {...otherProps}
      className={classnames("sessionMismatchDialog", maxWidth, className, classes.sessionMismatchDialog)}
      maxWidth={maxWidth}
      cancel={cancel}
    >
      <div className={classnames("sessionMismatch", classes.container)}>
        <label className={classnames("title", classes.title)}>
          {title}
        </label>
        <label className={classnames("subtitle", classes.subtitle)}>
          Press continue to logout and return to the login screen.
        </label>
        <div className={classnames("sessionMismatchInfo", classes.sessionMismatchInfo)}>
          {showAccountInfo && (
            <div className={classnames("accountInfoContainer", classes.sessionInfoContainer)}>
              <div className={classnames("currentAccountInfo", classes.sessionInfo)}>
                <label className={classnames("currentAccountInfoLabel", classes.sessionInfoLabel)}>
                  Current Account ID:
                </label>
                <label className={classnames("currentAccountLabel", classes.sessionLabel)}>
                  {currentAccountId}
                </label>
              </div>
              <div className={classnames("otherAccountInfo", classes.sessionInfo)}>
                <label className={classnames("otherAccountInfoLabel", classes.sessionInfoLabel)}>
                  Requested Account ID:
                </label>
                <label className={classnames("otherAccountLabel", classes.sessionLabel)}>
                  {otherAccountId}
                </label>
              </div>
            </div>
          )}
          {showUserInfo && (
            <div className={classnames("userInfoContainer", classes.sessionInfoContainer)}>
              {!isEmptyString(currentServiceId) && (
                <div className={classnames("currentServiceInfo", classes.sessionInfo)}>
                  <label className={classnames("currentServiceInfo", classes.sessionInfoLabel)}>
                    Current Service ID:
                  </label>
                  <label className={classnames("currentServiceLabel", classes.sessionLabel)}>
                    {currentServiceId}
                  </label>
                </div>
              )}
              {isEmptyString(currentServiceId) && (
                <div className={classnames("currentUserInfo", classes.sessionInfo)}>
                  <label className={classnames("currentUserInfoLabel", classes.sessionInfoLabel)}>
                    Current User ID:
                  </label>
                  <label className={classnames("currentUserLabel", classes.sessionLabel)}>
                    {currentUserId}
                  </label>
                </div>
              )}
              <div className={classnames("otherUserInfo", classes.sessionInfo)}>
                <label className={classnames("otherUserInfoLabel", classes.sessionInfoLabel)}>
                  Requested User ID:
                </label>
                <label className={classnames("otherUserLabel", classes.sessionLabel)}>
                  {otherUserId}
                </label>
              </div>
            </div>
          )}
        </div>
        {children}
      </div>
    </ConfirmationDialog>
  );
});

export default SessionMismatchDialog;
