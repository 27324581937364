import React from "react";
import classnames from "classnames";
import { JsonSchemaProperty } from "@data";
import { clickHandler, noop } from "@util";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { ActionMenuItem } from "@components";
import { Button } from "@components/button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { PropertiesTableBodyRow, PropertyTableRowData } from "./PropertiesTableBodyRow";
import { PropertiesTableFooterRow } from "./PropertiesTableFooterRow";
import { PropertiesTableHeaderRow } from "./PropertiesTableHeaderRow";
import styles, { addButton } from "./styles";

export const NUM_COLUMNS = 6;

const AddButton = withStyles(addButton)(Button);

const mapPropertyToDefaultValueLabel = (property: JsonSchemaProperty) => {

  const { defaultValue } = property;

  if (!property.hasDefaultValue()) {
    return "";
  }

  if (property.isNull()) {
    return "null";
  }

  if (property.isObject()) {
    return "{ ... }";
  }

  if (property.isArray()) {
    return "[ ... ]";
  }

  return defaultValue + "";
};

const mapPropertyToTableRowData = (property: JsonSchemaProperty): PropertyTableRowData => ({
  name: property.name,
  type: property.type,
  description: property.description,
  defaultValue: mapPropertyToDefaultValueLabel(property),
  required: property.required,
});

export interface Model {
  className?: string;
  properties?: JsonSchemaProperty[];
  actions?: ActionMenuItem[];
  children?: React.ReactNode;
}

export interface Actions {
  addProperty?: () => void;
  onClickProperty?: (property: JsonSchemaProperty) => void;
  onClickPropertyAction?: (property: JsonSchemaProperty, action: ActionMenuItem) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const PropertiesTable = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    properties = [],
    actions = [],
    children,
    addProperty,
    onClickProperty = noop,
    onClickPropertyAction = noop,
  } = props;

  const clickable = typeof onClickProperty === "function" && onClickProperty !== noop;

  const showActionsColumn = actions.length > 0;

  const numColumns = showActionsColumn ? NUM_COLUMNS : (NUM_COLUMNS - 1);

  return (
    <Table className={classnames("propertiesTable", className, classes.table)}>
      <TableHead>
        <PropertiesTableHeaderRow showActionsColumn={showActionsColumn} />
      </TableHead>
      <TableBody>
        {properties.map((property: JsonSchemaProperty, rowIndex: number) => (
          <PropertiesTableBodyRow
            { ...mapPropertyToTableRowData(property) }
            key={`table-body-row-${rowIndex}`}
            clickable={clickable}
            actions={actions}
            onClick={() => onClickProperty(property)}
            onClickAction={(action: ActionMenuItem) => onClickPropertyAction(property, action)}
          />
        ))}
        <PropertiesTableFooterRow
          className={classnames("addButtonRow", classes.addButtonRow)}
          columnClassName={classnames("addButtonColumn", classes.addButtonColumn)}
          numColumns={numColumns}
        >
          <AddButton
            className={classnames("addButton", classes.addButton)}
            onClick={clickHandler(addProperty)}
            disableRipple={true}
          >
            Add Property
          </AddButton>
        </PropertiesTableFooterRow>
        <PropertiesTableFooterRow numColumns={numColumns}>
          {children}
        </PropertiesTableFooterRow>
      </TableBody>
    </Table>
  );
});

export default PropertiesTable;
