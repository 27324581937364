import {
  MODULE_PATH as BASE_URL,
  LEGACY_MODULE_PATH as LEGACY_BASE_URL,
} from "@modules/dataAccessRequests/constants";

export const MODULE_ID = "data_access_request_details";
export const MODULE_PATH = `${BASE_URL}/:id`;
export const LEGACY_MODULE_PATH = `${LEGACY_BASE_URL}/:id`;

export const VIEW_ACCESS_REQUEST_COMMENTS_REDIRECT = "viewAccessRequestComments";

export const SCHEMA_KEY = "dataAccessRequestDetails";

export { BASE_URL, LEGACY_BASE_URL };
