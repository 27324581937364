import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import useDraftNewWorkloadVersion from "@hooks/workloadsManager/use-draft-new-workload-version";
import { noop } from "@util";

export interface WorkloadDraftNewVersionDialogModel extends PortalModuleDialogModel<string> {
  name: string;
  version: number | "latest";
}

export interface WorkloadDraftNewVersionDialogActions extends PortalModuleDialogActions<string> {
  close?: () => void;
}

type Props = WithStyles<typeof styles> & WorkloadDraftNewVersionDialogModel & WorkloadDraftNewVersionDialogActions;

export const WorkloadDraftNewVersionDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    name,
    version,
    title = "Draft New Version",
    continueButtonLabel = "Create",
    close = noop,
    ...otherProps
  } = props;

  const [{ showLoadingIndicator, ...model }, { draftNewVersion, reset, ...actions }] =
    useDraftNewWorkloadVersion({ name, version });

  const closeDialog = React.useCallback(() => {
    reset();
    close();
  }, [reset, close]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...model}
      {...actions}
      className={classnames("workloadDraftNewVersionDialog", className)}
      title={title}
      loading={showLoadingIndicator}
      continueButtonLabel={continueButtonLabel}
      confirm={draftNewVersion}
      cancel={closeDialog}
    >
      <div className={classnames("draftNewVersion", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Are you sure you want to draft a new version of this workload?
          </label>
          <label className={classnames("subtitle", classes.subTitle)}>
            NOTE: New version will be drafted from {version === "latest" ? 
            "the latest version" : `version ${version}`} of this workload
          </label>
          <label className={classnames("name", classes.name)}>
            <b>Name:</b> {name}
          </label>
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadDraftNewVersionDialog;
