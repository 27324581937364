import React from "react";
import { SearchFilter } from "@data";
import classnames from "classnames";
import Button from "@components/button";
import { clickHandler, noop } from "@util";
import SearchFilters from "./SearchFilters";
import { addFilter as styles } from "./styles";
import UserFilterDialog from "./UserFilterDialog";
import AddFilterIcon from "@material-ui/icons/FilterList";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface Model {
  className?: string;
  loading?: boolean;
  orJoinOperator?: boolean;
  showOrJoinOperator?: boolean;
  searchFilters?: SearchFilter[];
  clearFiltersButtonLabel?: string;
}

export interface Actions {
  setOrJoinOperator?: (orJoinOperator: boolean) => void;
  setSearchFilters?: (searchFilters: SearchFilter[]) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const ManageSearchFilters = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    loading,
    orJoinOperator,
    showOrJoinOperator,
    searchFilters = [],
    clearFiltersButtonLabel = "Clear",
    setOrJoinOperator = noop,
    setSearchFilters = noop,
  } = props;

  const [showSearchFilterDialog, setShowSearchFilterDialog] = React.useState(false);

  const openSearchFilterDialog = React.useCallback(() =>
    setShowSearchFilterDialog(true),
    [setShowSearchFilterDialog]);

  const closeSearchFilterDialog = React.useCallback(() =>
    setShowSearchFilterDialog(false),
    [setShowSearchFilterDialog]);

  const addSearchFilter = React.useCallback((searchFilter: SearchFilter) => {
    if (searchFilter.hasKey() && searchFilter.hasValue()) {
      setSearchFilters(searchFilters
        .filter(filter => !searchFilter.equals(filter))
        .concat(searchFilter));
    }
    closeSearchFilterDialog();
  }, [setSearchFilters, searchFilters, closeSearchFilterDialog]);

  const removeSearchFilter = React.useCallback((searchFilter: SearchFilter) => {
    setSearchFilters(searchFilters.filter(filter => !searchFilter.equals(filter)));
  }, [setSearchFilters, searchFilters]);

  const clearSearchFilters = React.useCallback(() => setSearchFilters([]), [setSearchFilters]);

  const showClearFiltersButton = React.useMemo(() => searchFilters.length > 0, [searchFilters]);

  return (
    <div className={classnames("manageSearchFilters", className, classes.container)}>
      <div className={classnames("searchFilterControls", classes.controls)}>
        <Button
          className={classnames("addFilterButton", classes.addFilterButton)}
          classes={{
            label: classnames("label", classes.addFilterButtonLabel),
            disabled: classnames("disabled", classes.disabledButton),
          }}
          color="primary"
          size="small"
          disableFocusRipple={true}
          disableRipple={true}
          disabled={loading}
          onClick={openSearchFilterDialog}
        >
          <AddFilterIcon className={classnames("addFilterIcon", "icon", classes.addFilterIcon)} />
          Add Search Filter
        </Button>
        {showClearFiltersButton && (
          <Button
            className={classnames("clearFiltersButton", classes.clearFiltersButton)}
            classes={{
              label: classnames("label", classes.clearFiltersButtonLabel),
              disabled: classnames("disabled", classes.disabledButton),
            }}
            disabled={loading}
            onClick={clickHandler(clearSearchFilters)}
          >
            {clearFiltersButtonLabel}
          </Button>
        )}
      </div>
      <SearchFilters
        disabled={loading}
        orJoinOperator={orJoinOperator}
        showOrJoinOperator={showOrJoinOperator}
        searchFilters={searchFilters}
        setOrJoinOperator={setOrJoinOperator}
        removeSearchFilter={removeSearchFilter}
      />
      {showSearchFilterDialog && (
        <UserFilterDialog
          open={showSearchFilterDialog}
          cancel={closeSearchFilterDialog}
          addSearchFilter={addSearchFilter}
        />
      )}
    </div>
  );
});

export default ManageSearchFilters;
