import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";
import {
  DeviceTypeModelV3Credentials,
  DeviceTypeModelV3CredentialsAttributes, DeviceTypeModelV3CredentialType,
  DeviceTypeModelV3Definition,
  DeviceTypeModelV3DeviceAuthentication, DeviceTypeModelV3DeviceAuthenticationType,
  DeviceTypeModelV3UsageAttributes,
  DeviceTypeModelVersion,
  DeviceTypeState,
} from "@data";

export enum DeviceTypeModelV3MetadataMode {
  NONE = "",
  READ_WRITE = "READ_WRITE",
  READ_ONLY = "READ_ONLY",
}

export enum DeviceTypeModelV3ConnectionMode {
  NONE = "",
  CONNECTED = "CONNECTED",
  PROXIED = "PROXIED",
  CONNECTED_OR_PROXIED = "CONNECTED_OR_PROXIED",
}

export interface DeviceTypeModelV3CertificateConfigAttributes {
  authorityId: string;
  certificatePropertyName: string;
  privateKeyPropertyName: boolean;
}

export interface DeviceTypeModelV3MqttConfigAttributes {
  brokerUrls: any;
}

export interface DeviceTypeModelV3ConnectivityAttributes {
  mqtt: DeviceTypeModelV3MqttConfigAttributes;
}

export interface DeviceTypeModelV3PropertyModelAttributes {
  key: string;
  value: string;
  secured: boolean;
}

export interface DeviceTypeModelV3ConfigurationAttributes {
  schema: string;
  restriction: string;
}

export interface DeviceTypeModelV3MetadataAttributes {
  schema: string;
  mode: DeviceTypeModelV3MetadataMode;
}

export interface DeviceTypeModelV3DataAttr {
  configuration: DeviceTypeModelV3ConfigurationAttributes[];
  metadata: DeviceTypeModelV3MetadataAttributes[];
}

export interface DeviceTypeModelV3PackageAttributes {
  id: string;
}

export interface DeviceTypeModelV3SoftwareAttributes {
  packages: DeviceTypeModelV3PackageAttributes[];
}

export interface DeviceTypeModelV3GroupAttributes {
  name: string;
}

export interface DeviceTypeModelV3AuthorizationAttributes {
  groups: DeviceTypeModelV3GroupAttributes[];
}

export interface DeviceTypeModelV3ConstraintsAttributes {
  connectionMode?: DeviceTypeModelV3ConnectionMode;
  mqtt?: boolean;
}

export interface DeviceTypeModelV3SecurityAttributes {
  authorization: DeviceTypeModelV3AuthorizationAttributes;
  constraints: DeviceTypeModelV3ConstraintsAttributes;
  credentials: DeviceTypeModelV3CredentialsAttributes;
}

export interface DeviceTypeModelV3Attributes {
  certificates?: DeviceTypeModelV3CertificateConfigAttributes[];
  connectivity?: DeviceTypeModelV3ConnectivityAttributes;
  data?: DeviceTypeModelV3DataAttr;
  description?: string;
  properties?: DeviceTypeModelV3PropertyModelAttributes[];
  tags?: string[];
  software?: DeviceTypeModelV3SoftwareAttributes;
  security?: DeviceTypeModelV3SecurityAttributes;
  typeIdentity: string;
  state: DeviceTypeState;
  etag?: string;
  modelVersion?: DeviceTypeModelVersion;
}

export class DeviceTypeModelV3 extends Record({
  certificates: [],
  connectivity: {},
  data: {},
  description: "",
  properties: [],
  tags: [],
  software: {},
  security: {
    authorization: {
      groups: []
    },
    constraints: {},
    credentials: {
      definitions: [],
      usage: {
        deviceAuthentication: [],
      },
    },
  },
  typeIdentity: "",
  state: DeviceTypeState.DRAFT,
  etag: "",
  modelVersion: DeviceTypeModelVersion.REGIONAL,
}) implements DeviceTypeModelV3Attributes {

  public static EMPTY: DeviceTypeModelV3 = new DeviceTypeModelV3();

  public readonly certificates: DeviceTypeModelV3CertificateConfigAttributes[];
  public readonly connectivity: DeviceTypeModelV3ConnectivityAttributes;
  public readonly data: DeviceTypeModelV3DataAttr;
  public readonly description: string;
  public readonly properties: DeviceTypeModelV3PropertyModelAttributes[];
  public readonly tags: string[];
  public readonly software: DeviceTypeModelV3SoftwareAttributes;
  public readonly security: DeviceTypeModelV3SecurityAttributes;
  public readonly typeIdentity: string;
  public readonly state: DeviceTypeState;
  public readonly etag: string;
  public readonly modelVersion: DeviceTypeModelVersion;

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public getNamespace(): string {
    return this.getTypeIdentity().split(":")[0] || "";
  }

  public getName(): string {
    return this.getTypeIdentity().split(":")[1] || "";
  }

  public getVersion(): string {
    return this.getTypeIdentity().split(":")[2] || "";
  }

  public getTags(): string[] {
    return Array.isArray(this.tags) ? this.tags : [];
  }

  public getGroups(): string[] {
    const security = this.security || { authorization: { groups: [] } };
    const { authorization = { groups: [] } } = security;
    const { groups = [] } = authorization;
    const data = Array.isArray(groups) ? groups : [];
    return data.map(({ name = "" }) => getStringValue(name));
  }

  public getSchemas(): string[] {
    const { configuration = [] } = this.data || { configuration: [] };
    const data = Array.isArray(configuration) ? configuration : [];
    return data.map(({ schema = "" }) => getStringValue(schema));
  }

  public getMetadata(): DeviceTypeModelV3MetadataAttributes[] {
    const { metadata = [] } = this.data || { metadata: [] };
    return Array.isArray(metadata) ? metadata : [];
  }

  public getMetadataSchemas(): string[] {
    const { metadata = [] } = this.data || { metadata: [] };
    const data = Array.isArray(metadata) ? metadata : [];
    return data.map(({ schema = "" }) => getStringValue(schema));
  }

  public getTypeIdentity(): string {
    return getStringValue(this.typeIdentity);
  }

  public hasTypeIdentity(): boolean {
    return !isEmptyString(this.getTypeIdentity());
  }

  public getState(): DeviceTypeState {
    return this.state || DeviceTypeState.NONE;
  }

  public getEtag(): string {
    return getStringValue(this.etag);
  }

  public getModelVersion(): DeviceTypeModelVersion {
    return this.modelVersion;
  }

  public getSecurity(): DeviceTypeModelV3SecurityAttributes {
    return this.security || { credentials: DeviceTypeModelV3Credentials.EMPTY.toJS() };
  }

  public getCredentials(): DeviceTypeModelV3Credentials {
    const security = this.getSecurity();
    const { credentials = DeviceTypeModelV3Credentials.EMPTY.toJS() } = security;
    return new DeviceTypeModelV3Credentials(credentials);
  }

  public getDefinitions(): DeviceTypeModelV3Definition[] {
    return this.getCredentials().getDefinitions();
  }

  public getCredentialType(): DeviceTypeModelV3CredentialType[] {
    return this.getCredentials().getDefinitionTypes();
  }

  public getDefinitionNames(): string[] {
    return this.getCredentials().getDefinitionNames();
  }

  public getCredentialNames(): string[] {
    return this.getDefinitionNames();
  }

  public getUsage(): DeviceTypeModelV3UsageAttributes {
    return this.getCredentials().getUsage();
  }

  public getDeviceAuthentication(): DeviceTypeModelV3DeviceAuthentication[] {
    return this.getCredentials().getDeviceAuthentication();
  }

  public getDeviceAuthenticationType(): DeviceTypeModelV3DeviceAuthenticationType[] {
    const authentications = this.getDeviceAuthentication();
    return authentications.map(authentication => authentication.getType());
  }
}

export default DeviceTypeModelV3;
