import { AppSchema } from "../../main/schemas";
import { ManageSoftwareVersionsAction } from "../reducers/manageSoftwareVersions";

export const getAction = (state: AppSchema): ManageSoftwareVersionsAction => {
  return state.deviceTypeDetails.manageSoftwareVersions.action;
};

export const getSoftwareVersion = (state: AppSchema): string => {
  return state.deviceTypeDetails.manageSoftwareVersions.softwareVersion;
};

export const getErrorMessage = (state: AppSchema): string => {
  return state.deviceTypeDetails.manageSoftwareVersions.errorMessage;
};

export const getSuccessMessage = (state: AppSchema): string => {
  return state.deviceTypeDetails.manageSoftwareVersions.successMessage;
};

export const isAccessDeniedVisible = (state: AppSchema): boolean => {
  return state.deviceTypeDetails.manageSoftwareVersions.showAccessDenied;
};

export const isLoadingIndicatorVisible = (state: AppSchema): boolean => {
  return state.deviceTypeDetails.manageSoftwareVersions.showLoadingIndicator;
};

export const isRemoveDialogVisible = (state: AppSchema): boolean => {
  return state.deviceTypeDetails.manageSoftwareVersions.showRemoveDialog;
};
