import React from "react";
import styles from "./styles";
import classnames from "classnames";
import camelCase from "lodash/camelCase";
import DetailsViewTab from "./DetailsViewTab";
import { DetailsViewTabVariant } from "./DetailsViewTabs";
import DetailsViewRoute from "@components/details-view/DetailsViewRoute";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface TabsButtonViewModel {
  baseUrl?: string;
  disabled?: boolean;
  routes?: DetailsViewRoute[];
  tabVariant?: DetailsViewTabVariant;
}

export interface TabsButtonViewActions {
}

type Model = TabsButtonViewModel;
type Actions = TabsButtonViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const TabsButtonView = withStyles(styles)((props: Props) => {

  const {
    classes,
    disabled,
    routes = [],
    baseUrl = "",
    tabVariant,
  } = props;

  if (tabVariant !== DetailsViewTabVariant.BUTTONS) {
    return null;
  }

  return (
    <div className={classnames("tabs", classes.buttons)} >
      {routes.map(({ id, name: label, path = "", className: tabClassName, activeClassName, hidden }) => {
        if (hidden) {
          return null;
        } else {
          return (
            <DetailsViewTab
              key={id}
              className={tabClassName || `${camelCase(id)}Button`}
              activeClassName={activeClassName}
              disabled={disabled}
              label={label}
              path={baseUrl + path}
            />
          );
        }
      })}
    </div>
  );
});

export default TabsButtonView;
