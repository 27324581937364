import { MODULE_PATH as BASE_URL } from "@modules/deviceEnrollment/constants";
import ModuleIcon from "@material-ui/icons/SettingsCell";
import Color from "@Color";

export const MODULE_ID = "device_enrollment_details";
export const MODULE_PATH = `${BASE_URL}/:id`;
export const SCHEMA_KEY = "deviceEnrollmentDetails";

export { BASE_URL };

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;
