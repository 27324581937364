import isEmpty from "lodash/isEmpty";
import { Record } from "immutable";
import {
  JsonSchemaNumberConstraints,
  JsonSchemaNumberConstraintsAttributes,
  JsonSchemaPropertiesDefinition,
  JsonSchemaStringConstraints,
  JsonSchemaStringConstraintsAttributes,
  JsonSchemaIntegerConstraints,
  JsonSchemaIntegerConstraintsAttributes,
  NumberValidationData,
  StringValidationData,
} from "../data";

export type JsonSchemaPropertyDefaultValueType = string | number | null | boolean;

export type JsonSchemaPropertyConstraints = null |
  JsonSchemaStringConstraintsAttributes |
  JsonSchemaNumberConstraintsAttributes |
  JsonSchemaIntegerConstraintsAttributes;

export enum JsonSchemaPropertyType {
  NONE = "",
  STRING = "string",
  NUMBER = "number",
  INTEGER = "integer",
  BOOLEAN = "boolean",
  NULL = "null",
  OBJECT = "object",
  ARRAY = "array",
  REFERENCE = "$ref",
}

export interface JsonSchemaBasicPropertyAttributes {
  name: string;
  type: JsonSchemaPropertyType;
  description: string;
  required: boolean;
  reference: string;
  enumValues: JsonSchemaPropertyDefaultValueType[];
  constraints: JsonSchemaPropertyConstraints;
  defaultValue: JsonSchemaPropertyDefaultValueType;
}

export type JsonSchemaProperties = {
  [key: string]: JsonSchemaBasicPropertyAttributes;
} & {
  [key: string]: any,
};

export interface JsonSchemaPropertyAttributes extends JsonSchemaBasicPropertyAttributes {
  name: string;
  type: JsonSchemaPropertyType;
  description: string;
  required: boolean;
  reference: string;
  enumValues: JsonSchemaPropertyDefaultValueType[];
  constraints: JsonSchemaPropertyConstraints;
  defaultValue: JsonSchemaPropertyDefaultValueType;
  properties: JsonSchemaProperties;
}

export type ValidationData = ({ type: string; } | { $ref: string; })
  & StringValidationData
  & NumberValidationData;

export class JsonSchemaProperty extends Record({
  name: "",
  type: JsonSchemaPropertyType.STRING,
  description: "",
  required: false,
  reference: "#",
  enumValues: [],
  constraints: null,
  defaultValue: "",
  properties: {},
}) implements JsonSchemaPropertyAttributes {

  public static EMPTY: JsonSchemaProperty = new JsonSchemaProperty();

  public readonly name: string;
  public readonly type: JsonSchemaPropertyType;
  public readonly description: string;
  public readonly required: boolean;
  public readonly reference: string;
  public readonly enumValues: JsonSchemaPropertyDefaultValueType[];
  public readonly constraints: JsonSchemaPropertyConstraints;
  public readonly defaultValue: JsonSchemaPropertyDefaultValueType;
  public readonly properties: JsonSchemaProperties;

  public static from(name: string,
                     property: JsonSchemaPropertiesDefinition = {},
                     requiredProperties: string[] = []): JsonSchemaProperty {

    const {
      type = JsonSchemaPropertyType.NONE,
      description = "",
      properties = {},
      $ref: reference,
      enum: enumValues = [],
      default: defaultValue,
    } = property;

    const attrs = {
      name,
      type: typeof reference === "string" ? JsonSchemaPropertyType.REFERENCE : type,
      description,
      reference,
      enumValues,
      required: requiredProperties.indexOf(name) >= 0,
      constraints: JsonSchemaProperty.getPropertyConstraints(property),
      ...(typeof defaultValue === "undefined" ? {} : { defaultValue }),
      ...(typeof properties !== "object" ? {} : { properties }),
    };

    return new JsonSchemaProperty(attrs);
  }

  public static getPropertyConstraints(
    property: JsonSchemaPropertiesDefinition = {}): JsonSchemaPropertyConstraints {

    const { type = JsonSchemaPropertyType.NONE, ...remainingAttrs } = property;

    return new JsonSchemaProperty({ type, constraints: remainingAttrs }).getConstraints();
  }

  public getName(): string {
    return (this.name + "").trim();
  }

  public isString(): boolean {
    return JsonSchemaPropertyType.STRING === this.type;
  }

  public isNumber(): boolean {
    return JsonSchemaPropertyType.NUMBER === this.type;
  }

  public isInteger(): boolean {
    return JsonSchemaPropertyType.INTEGER === this.type;
  }

  public isBoolean(): boolean {
    return JsonSchemaPropertyType.BOOLEAN === this.type;
  }

  public isNull(): boolean {
    return JsonSchemaPropertyType.NULL === this.type;
  }

  public isObject(): boolean {
    return JsonSchemaPropertyType.OBJECT === this.type;
  }

  public isArray(): boolean {
    return JsonSchemaPropertyType.ARRAY === this.type;
  }

  public isReference(): boolean {
    return JsonSchemaPropertyType.REFERENCE === this.type;
  }

  public hasReference(): boolean {

    if (!this.isReference()) {
      return false;
    }

    const reference = `${this.reference}`.trim();

    return reference.length > 0 && reference !== "#";
  }

  public hasDefaultValue(): boolean {

    if (this.isReference()) {
      return false;
    }

    if (this.type === JsonSchemaPropertyType.NULL && this.defaultValue === null) {
      return true;
    }

    if (this.isObject()) {
      return typeof this.defaultValue === "object" && !isEmpty(this.defaultValue);
    }

    if (this.isArray()) {
      return Array.isArray(this.defaultValue) && this.defaultValue.length > 0;
    }

    return typeof this.defaultValue !== "undefined" && this.defaultValue !== "";
  }

  public hasEnumValues(): boolean {

    if (this.isReference()) {
      return false;
    }

    return this.enumValues.length > 0;
  }

  public hasConstraints(): boolean {

    if (this.isReference()) {
      return false;
    }

    return typeof this.constraints !== "undefined" && this.constraints !== null;
  }

  public hasProperties(): boolean {

    if (this.isReference()) {
      return false;
    }

    return typeof this.properties === "object" && !isEmpty(this.properties);
  }

  public isBasicPropertyType(): boolean {

    return this.isString()
      || this.isNumber()
      || this.isInteger()
      || this.isBoolean()
      || this.isNull();
  }

  public getStringConstraints(): JsonSchemaStringConstraints {

    if (!this.isString() || !this.hasConstraints()) {
      return JsonSchemaStringConstraints.EMPTY;
    }

    return new JsonSchemaStringConstraints({ ...this.constraints });
  }

  public getNumberConstraints(): JsonSchemaNumberConstraints {

    if (!this.isNumber() || !this.hasConstraints()) {
      return JsonSchemaNumberConstraints.EMPTY;
    }

    return new JsonSchemaNumberConstraints({ ...this.constraints });
  }

  public getIntegerConstraints(): JsonSchemaIntegerConstraints {

    if (!this.isInteger() || !this.hasConstraints()) {
      return JsonSchemaIntegerConstraints.EMPTY;
    }

    return new JsonSchemaIntegerConstraints({ ...this.constraints });
  }

  public getConstraints(): JsonSchemaPropertyConstraints {

    if (!this.hasConstraints()) {
      return null;
    }

    const constraints = {
      ...this.getStringConstraints().getValidationData(),
      ...this.getNumberConstraints().getValidationData(),
      ...this.getIntegerConstraints().getValidationData(),
    };

    return isEmpty(constraints) ? null : constraints;
  }

  public getValidationData(): ValidationData | {} {

    if (this.isReference()) {
      return {
        ...(!this.hasReference() ? {} : {
          $ref: this.reference,
        }),
      };
    }

    const constraints = this.getConstraints();

    return {
      type: this.type,
      ...(constraints === null ? {} : constraints),
    };
  }
}
