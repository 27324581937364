import React from "react";
import { noop } from "@util";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MODULE_PATH as DETAILS_PAGE_PATH } from "@modules/deviceDetails/constants";
import { refresh as refreshDetailsPage } from "@modules/deviceDetails/actions";
import { close, disenrollDevice } from "../actions";
import {
  getErrorMessage,
  getSuccessMessage,
  getDeviceId,
  isAccessDeniedVisible,
  isDialogOpen,
  isEmptyViewVisible,
  isLoadingIndicatorVisible,
  isDeviceIdValid,
} from "../selectors";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import DisenrollDevice from "../components/DisenrollDevice";

interface Model extends PortalModuleDialogModel<string> {
  deviceId?: string;
}

type Actions = PortalModuleDialogActions<string>;

const DisenrollDeviceDialog = (props: Model & Actions) => {

  const { deviceId = "", ...otherProps } = props;

  const { cancel: closeDialog = noop } = otherProps;

  const match = useRouteMatch(DETAILS_PAGE_PATH);

  const history = useHistory();

  const goBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onSuccessMessageShown = React.useCallback(() => {

    refreshDetailsPage();

    closeDialog();

  }, [match, closeDialog, goBack, deviceId]);

  return (
    <PortalModuleDialog {...otherProps} onSuccessMessageShown={onSuccessMessageShown}>
      <DisenrollDevice deviceId={deviceId} />
    </PortalModuleDialog>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  className: "disenrollDeviceDialog",
  title: "Disenroll Device",
  open: isDialogOpen(state),
  item: getDeviceId(state),
  loading: isLoadingIndicatorVisible(state),
  showEmptyView: isEmptyViewVisible(state),
  accessDenied: isAccessDeniedVisible(state),
  errorMessage: getErrorMessage(state),
  successMessage: getSuccessMessage(state),
  continueButtonLabel: "Disenroll Device",
  continueButtonDisabled: !isDeviceIdValid(state),
  deviceId: getDeviceId(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  cancel: () => dispatch(close()),
  confirm: () => dispatch(disenrollDevice()),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(DisenrollDeviceDialog);
