import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { AnalyticsEvent, AnalyticsReducer, AnalyticsReduxAction } from "./reducer";
import { isLoggedIn, getCurrentAccountId } from "@main/selectors";
import { loadGa } from "./loadGa";

export function AnalyticsMiddleware(
  trackerId: string = process.env.REACT_APP_GA_TRACKER_ID || "",
  moduleLoader: (trackerId: string) => (...args: any[]) => void = loadGa,
  reduce: (action: AnalyticsReduxAction<any>) => AnalyticsEvent | null = AnalyticsReducer):
  Middleware<any, any, any> {

  if (!trackerId.length) {
    throw ("failed to load analytics, missing trackerId");
  }
  const gtag = moduleLoader(trackerId);
  // Set event category to node environment for now so we can differentiate developer
  // events from real events.
  gtag("event", "main", {
    "event_label": "init",
    "event_category": "main",
  });
  const analytics = (event: AnalyticsEvent) => {
    gtag("event", event.action, {
      "event_label": event.label,
      "event_category": event.category,
    });
  };
  return (api: MiddlewareAPI<any, any>) =>
    (next: Dispatch<AnyAction>) =>
      (action: any) => {
        if (action.hasOwnProperty("type")) {
          const { type, value = "" } = <{type: string, value?: string}> <any> action;
          const state = api.getState();
          const loggedIn = isLoggedIn(state);
          const accountId = getCurrentAccountId(state);
          const loggableAction = reduce({
            type,
            value,
            ...(!loggedIn ? {} : {
              accountId,
            }),
          });
          if (loggableAction) {
            analytics(loggableAction);
          }
        }
        return next(action);
      };
}

export default AnalyticsMiddleware;
