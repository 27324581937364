import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";
import { ApproveRequest, ApproveRequestAction, ApproveRequestAttributes } from "@data";

export interface ApproveRequestSchema extends BasePortalModuleSchema {
  approvalRequestId: string;
  dataSetAlias: string;
  comments: string;
  open: boolean;
  action: ApproveRequestAction;
  approveRequest: ApproveRequestAttributes;
}

export const DEFAULT_STATE: ApproveRequestSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  open: false,
  approvalRequestId: "",
  dataSetAlias: "",
  comments: "",
  action: ApproveRequestAction.NONE,
  approveRequest: { ...ApproveRequest.EMPTY.toJS() },
});

export type ApproveRequestActionTypes = PortalModuleActionTypes<ApproveRequestSchema> & {
  APPROVE_REQUEST_REQUEST: string;
  APPROVE_REQUEST_SUCCESS: string;
  APPROVE_REQUEST_FAILED: string;
  REJECT_REQUEST_REQUEST: string;
  REJECT_REQUEST_SUCCESS: string;
  REJECT_REQUEST_FAILED: string;
};

export const ACTION_TYPES: ApproveRequestActionTypes = {
  ...createPortalModuleActionTypes<ApproveRequestSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    APPROVE_REQUEST_REQUEST: `${MODULE_ID}/approve_request_request`,
    APPROVE_REQUEST_SUCCESS: `${MODULE_ID}/approve_request_success`,
    APPROVE_REQUEST_FAILED: `${MODULE_ID}/approve_request_failed`,
    REJECT_REQUEST_REQUEST: `${MODULE_ID}/reject_request_request`,
    REJECT_REQUEST_SUCCESS: `${MODULE_ID}/reject_request_success`,
    REJECT_REQUEST_FAILED: `${MODULE_ID}/reject_request_failed`,
  },
};

export const ApproveRequestReducer = combineReducers<ApproveRequestSchema>(
  createPortalModuleReducers<ApproveRequestSchema>(ACTION_TYPES, DEFAULT_STATE));

export default ApproveRequestReducer;
