import React from "react";
import { WorkloadInstance, WorkloadInstanceAttributes } from "@data";
import {
  useWorkloadManagerClient,
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  UseApiRequestActions,
} from "@hooks";

type SuccessResponse = WorkloadInstanceAttributes;

export interface UseGetWorkloadInstanceProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  executionId: string;
}

export interface UseGetWorkloadInstanceModel extends UseApiRequestModel<SuccessResponse> {
  workloadInstance: WorkloadInstance;
}

export interface UseGetWorkloadInstanceActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetWorkloadInstanceProps;
type Model = UseGetWorkloadInstanceModel;
type Actions = UseGetWorkloadInstanceActions;
type Result = [Model, Actions];

export const useGetWorkloadInstance = (props: Props): Result => {

  const {
    name,
    version,
    executionId,
    defaultErrorMessage = "Failed to get workload instance",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
      WorkloadManagerClient.getWorkloadInstanceApi(name, version, executionId),
    [WorkloadManagerClient, name, version, executionId]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const workloadInstance = React.useMemo<WorkloadInstance>(() => {
    const attrs = successResponse ? successResponse : {};
    return new WorkloadInstance(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    workloadInstance,
  }), [
    baseModel,
    workloadInstance,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetWorkloadInstance;
