import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import { DataAccessRequest, DataAccessRequestAttributes } from "@data";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "@base/createReducers";
import { RequestCommentAttributes } from "@data/RequestComment";

export enum AccessRequestApprovalDetailsColumn {
  NONE = "",
  APPROVAL_REQUEST_TYPE = "Request Type",
  REQUEST_STATE = "State",
  APPROVERS = "Approvers",
  APPROVAL_COMMENTS = "Comments",
  APPROVED_BY = "Approved By",
  APPROVED_AT = "Approved At",
}

export interface DataAccessRequestDetailsSchema extends BasePortalModuleSchema {
  dataAccessRequest: DataAccessRequestAttributes;
  comments: RequestCommentAttributes[];
  comment: string;
  createCommentBox: boolean;
  commentErrorMessage: string;
  commentLoadingIndicator: boolean;
}

export const DEFAULT_STATE: DataAccessRequestDetailsSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  dataAccessRequest: Object.freeze({ ...DataAccessRequest.EMPTY.toJS() }),
  comments: [],
  comment: "",
  createCommentBox: false,
  commentErrorMessage: "",
  commentLoadingIndicator: false,
} as DataAccessRequestDetailsSchema);

type DataAccessRequestDetailsActionTypes = PortalModuleActionTypes<DataAccessRequestDetailsSchema> & {
  FETCH_DATA_ACCESS_REQUEST_DETAILS_REQUEST: string;
  FETCH_DATA_ACCESS_REQUEST_DETAILS_SUCCESS: string;
  FETCH_DATA_ACCESS_REQUEST_DETAILS_FAILURE: string;
  FETCH_DATA_ACCESS_REQUEST_COMMENTS_REQUEST: string;
  FETCH_DATA_ACCESS_REQUEST_COMMENTS_SUCCESS: string;
  FETCH_DATA_ACCESS_REQUEST_COMMENTS_FAILURE: string;
  CREATE_DATA_ACCESS_REQUEST_COMMENT_REQUEST: string,
  CREATE_DATA_ACCESS_REQUEST_COMMENT_SUCCESS: string,
  CREATE_DATA_ACCESS_REQUEST_COMMENT_FAILED: string,
};

export const ACTION_TYPES: DataAccessRequestDetailsActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<DataAccessRequestDetailsSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    FETCH_DATA_ACCESS_REQUEST_DETAILS_REQUEST: `${MODULE_ID}/fetch_data_access_request_details_request`,
    FETCH_DATA_ACCESS_REQUEST_DETAILS_SUCCESS: `${MODULE_ID}/fetch_data_access_request_details_success`,
    FETCH_DATA_ACCESS_REQUEST_DETAILS_FAILURE: `${MODULE_ID}/fetch_data_access_request_details_failure`,
    FETCH_DATA_ACCESS_REQUEST_COMMENTS_REQUEST: `${MODULE_ID}/fetch_data_access_request_comments_request`,
    FETCH_DATA_ACCESS_REQUEST_COMMENTS_SUCCESS: `${MODULE_ID}/fetch_data_access_request_comments_success`,
    FETCH_DATA_ACCESS_REQUEST_COMMENTS_FAILURE: `${MODULE_ID}/fetch_data_access_request_comments_failure`,
    CREATE_DATA_ACCESS_REQUEST_COMMENT_REQUEST: `${MODULE_ID}/create_data_access_request_comment_request`,
    CREATE_DATA_ACCESS_REQUEST_COMMENT_SUCCESS: `${MODULE_ID}/create_data_access_request_comment_success`,
    CREATE_DATA_ACCESS_REQUEST_COMMENT_FAILED: `${MODULE_ID}/create_data_access_request_comments_failure`,
  },
});

export const DataAccessRequestDetailsReducer = combineReducers<DataAccessRequestDetailsSchema>(
  createPortalModuleReducers<DataAccessRequestDetailsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DataAccessRequestDetailsReducer;
