import React from "react";
import { useHistory } from "react-router-dom";
import { getPathToSchema } from "@modules/schemaDetails/helpers";
import SchemaWizard from "./containers/SchemaWizard";

export function CreateSchemaView() {

  const history = useHistory();

  const onSaveSchemaSuccess = React.useCallback(schema => {
    history.replace(getPathToSchema(schema.getId()));
  }, [history]);

  return (
    <SchemaWizard
      className="createSchemaWizard"
      snackbarId="create-schema"
      saveButtonLabel="Create Schema"
      errorTitle="Create Schema Failed"
      onSaveSchemaSuccess={onSaveSchemaSuccess}
    />
  );
}

export default CreateSchemaView;
