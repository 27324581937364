import React from "react";
import { noop } from "@util";
import { AuditEvent } from "@data";
import classnames from "classnames";
import { AuditEventIcon, styles } from "./styles";
import {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import AuditEventsListColumn from "./AuditEventsListColumn";
import ModuleListView from "@components/module-list-view";
import { getPathToAuditEvent } from "@modules/auditEvents/helpers";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import AuditEventListItemExpanded from "./AuditEventListItemExpanded";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export const DEFAULT_AUDIT_EVENTS_LIST_COLUMNS: AuditEventsListColumn[] = [
  AuditEventsListColumn.TIMESTAMP,
  AuditEventsListColumn.SEVERITY,
  AuditEventsListColumn.TYPE,
  AuditEventsListColumn.PRINCIPAL,
];

const DEFAULT_RENDER_EXPANDED_ITEM = (item: AuditEvent) => (
  <AuditEventListItemExpanded
    auditEvent={item}
  />
);

const DEFAULT_IS_COLUMN_SORTABLE = (column: AuditEventsListColumn) =>
  AuditEventsListColumn.TIMESTAMP === column;

export interface AuditEventsListModel extends
  SortedSearchResultsListModel<AuditEvent, AuditEventsListColumn> {

  expandable?: boolean;
  expandedEvents?: string[];
}

export interface AuditEventsListActions extends
  SortedSearchResultsListActions<AuditEvent, AuditEventsListColumn> {

  setExpandedEvents?: (expandedEvents: string[]) => void;
}

type Props = WithStyles<typeof styles> & AuditEventsListModel & AuditEventsListActions;

export const AuditEventsList = withStyles(styles)((props: Props) => {

  const {
    classes,
    className = "auditEvents",
    listClassName = "auditEventsList",
    columns = DEFAULT_AUDIT_EVENTS_LIST_COLUMNS,
    sortByColumn = AuditEventsListColumn.TIMESTAMP,
    sortOrderAscending = false,
    expandable = true,
    noResultsLabel = "No Events Found",
    noSearchResultsLabel = "No Results",
    loadingLabel = "Fetching Events...",
    summaryViewQuantities = {
      other: "audit events",
      one: "audit event",
    },
    items = [],
    expandedEvents = [],
    nameFilter = "",
    setExpandedEvents = noop,
    renderExpandedItem = DEFAULT_RENDER_EXPANDED_ITEM,
    isColumnSortable = DEFAULT_IS_COLUMN_SORTABLE,
    ...otherProps
  } = props;

  const moduleListItems: ListViewItem<AuditEvent>[] = React.useMemo(() =>
    items.map(event => {
      return {
        item: event,
        pathToDetailsView: getPathToAuditEvent(event.getId()),
        icon: AuditEventIcon,
        columnAttributes: createColumns([
          {
            className: "eventTimestamp",
            value: event.getTimestamp(),
            column: AuditEventsListColumn.TIMESTAMP,
            firstColumn: true,
          },
          {
            className: "eventSeverity",
            value: event.getSeverity(),
            column: AuditEventsListColumn.SEVERITY,
          },
          {
            className: "eventType",
            value: event.getType(),
            column: AuditEventsListColumn.TYPE,
          },
          {
            className: "eventPrincipal",
            value: event.getPrincipal(),
            column: AuditEventsListColumn.PRINCIPAL,
          }
        ])
      };
    }), [items]);

  const setExpandedItems = React.useCallback((expandedAuditEvents: AuditEvent[]) =>
    setExpandedEvents(expandedAuditEvents.map(event => event.getId())), [setExpandedEvents]);

  const isItemExpanded = React.useCallback((item: AuditEvent) =>
    expandedEvents.indexOf(item.getId()) >= 0, [expandedEvents]);

  const onClickItem = React.useCallback((item: AuditEvent) => {
    if (isItemExpanded(item)) {
      setExpandedEvents(expandedEvents.filter(eventId => eventId !== item.getId()));
    } else {
      setExpandedEvents(expandedEvents.concat(item.getId()));
    }
  }, [setExpandedEvents, expandedEvents]);

  const expandedItems = React.useMemo(() =>
    !expandable ? [] : items.filter(isItemExpanded),
    [expandable, items, isItemExpanded]);

  return (
    <ModuleListView
      {...otherProps}
      expandable={expandable}
      expandedItems={expandedItems}
      setExpandedItems={setExpandedItems}
      listViewItems={moduleListItems}
      columns={columns}
      className={classnames(className, classes.container)}
      listClassName={classnames(listClassName, classes.table)}
      sortByColumn={sortByColumn}
      sortOrderAscending={sortOrderAscending}
      noResultsLabel={noResultsLabel}
      noSearchResultsLabel={noSearchResultsLabel}
      loadingLabel={loadingLabel}
      summaryViewIcon={AuditEventIcon}
      summaryViewQuantities={summaryViewQuantities}
      tableLayoutFixed={false}
      nameFilter={nameFilter}
      nameFilterDelay={25}
      renderExpandedItem={renderExpandedItem}
      onClickItem={onClickItem}
      isColumnSortable={DEFAULT_IS_COLUMN_SORTABLE}
      showSortButton={true}
    />
  );
});

export default AuditEventsList;
