import { PortalUserFeedbackClient } from "@network";
import { useApiRequestAnalytics } from "@hooks";

export type SubmitUserFeedbackApi = (json: string) => Promise<void>;

export interface PortalUserFeedbackClientApi {
  submitUserFeedback: SubmitUserFeedbackApi;
}

enum ActionTypes {
  USER_FEEDBACK_REQUEST = "portal_user_feedback_client/user_feedback_request",
  USER_FEEDBACK_SUCCESS = "portal_user_feedback_client/user_feedback_success",
  USER_FEEDBACK_FAILED = "portal_user_feedback_client/user_feedback_failed",
}

export const useSubmitUserFeedbackApi = () =>
  useApiRequestAnalytics(PortalUserFeedbackClient.submitUserFeedback, {
    REQUEST_EVENT: ActionTypes.USER_FEEDBACK_REQUEST,
    SUCCESS_EVENT: ActionTypes.USER_FEEDBACK_SUCCESS,
    ERROR_EVENT: ActionTypes.USER_FEEDBACK_FAILED,
  });

export const usePortalUserFeedbackClient = (): PortalUserFeedbackClientApi => ({
  submitUserFeedback: useSubmitUserFeedbackApi(),
});

export { ActionTypes as PortalUserFeedbackClientActionType };

export default usePortalUserFeedbackClient;
