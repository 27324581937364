import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import {
  isLoadingIndicatorVisible,
  isAccessDeniedVisible,
  getErrorMessage,
  isNotFoundVisible,
  getDomain,
  getActionMenuItems,
  getSummaryViewItems,
  getFederationProviderAsJson,
  getScope,
} from "../selectors";
import { refresh, deleteFederationProvider } from "../actions";
import {
  ActionMenuItem,
  DEFAULT_FEDERATION_PROVIDER_ACTION_MENU_ITEMS,
  FederationProviderAction,
  SummaryJsonDetailsView,
  ChipView,
} from "@components";
import FederationProviderIcon from "@material-ui/icons/Domain";
import { noop } from "@util";
import ScopeIcon from "@material-ui/icons/Label";
import { SummaryViewData } from "@data";

interface ContainerModel {
  loading?: boolean;
  errorMessage?: string;
  title?: string;
  actions?: ActionMenuItem[];
  showNotFound?: boolean;
  showAccessDenied?: boolean;
  items?: SummaryViewData[];
  scopes?: string[];
  json?: string;
}

interface ContainerActions {
  refresh?: () => void;
  editFederationProvider?: () => void;
  deleteFederationProvider?: () => void;
  onClickAction?: (action: ActionMenuItem) => void;
}

type Props = ContainerModel & ContainerActions;

const FederationProviderContainer = (props: Props) => {

  const {
    showNotFound,
    showAccessDenied,
    title,
    items,
    json,
    editFederationProvider = noop,
    deleteFederationProvider: deleteProvider = noop,
    onClickAction = noop,
    scopes,
    refresh: refreshPage,
    loading,
    errorMessage,
  } = props;

  const actionClicked = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case FederationProviderAction.DELETE_FEDERATION_PROVIDER:
       return deleteProvider();
      case FederationProviderAction.EDIT_FEDERATION_PROVIDER:
        return editFederationProvider();
      default:
        return onClickAction(action);
    }
  }, [deleteProvider, editFederationProvider, onClickAction]);

  const ScopeView = React.useMemo(() => (
    <ChipView
      title={"Scope"}
      items={scopes}
      className={"scope"}
      icon={ScopeIcon}
    />
  ), [scopes]);

  return (
    <SummaryJsonDetailsView
      title={title}
      icon={FederationProviderIcon}
      summaryViewItems={items}
      json={json}
      fileName={`${title}.json`}
      actions={DEFAULT_FEDERATION_PROVIDER_ACTION_MENU_ITEMS}
      onClickAction={actionClicked}
      additionalViews={ScopeView}
      refresh={refreshPage}
      showLoadingIndicator={loading}
      errorMessage={errorMessage}
      showNotFound={showNotFound}
      showAccessDenied={showAccessDenied}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  loading: isLoadingIndicatorVisible(state),
  errorMessage: getErrorMessage(state),
  actions: getActionMenuItems(state),
  title: getDomain(state),
  showNotFound: isNotFoundVisible(state),
  showAccessDenied: isAccessDeniedVisible(state),
  items: getSummaryViewItems(state),
  json: getFederationProviderAsJson(state),
  scopes: getScope(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  refresh: () => dispatch(refresh()),
  deleteFederationProvider: () => dispatch(deleteFederationProvider()),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(FederationProviderContainer);
