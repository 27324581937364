import React from "react";
import { useSelector } from "react-redux";
import { AuditEvent, SearchFilter } from "@data";
import { DEFAULT_LIMIT } from "@components/audit-events/AuditEventFilters";
import { AuditServiceClient, RestClientError } from "@network";
import { AccountIdContext, AuthTokenContext } from "@components";
import { useSearchFilters, useAvailableItems, AvailableValues, SelectedValues } from "@hooks";
import { getAvailableAutocompleteValues } from "@modules/auditEvents/selectors";
import { formatLocalDateToUtc, formatUtcDateToLocal, isEmptyString, noop, oneWeekAgo } from "@util";
import { AuditEventFilter } from "@components/audit-events/AuditEventFilter";

export interface UseAuditEventsModel {
  items?: AuditEvent[];
  expandedEvents?: string[];
  startDate?: Date | null;
  endDate?: Date | null;
  sortOrderAscending?: boolean;
  searchFilters?: SearchFilter[];
  selectedValues?: SelectedValues;
  availableValues?: AvailableValues;
  limit?: number;
  errorMessage?: string;
  showAccessDenied?: boolean;
  showLoadingIndicator?: boolean;
  showLoadMoreButton?: boolean;
}

export interface UseAuditEventsActions {
  refresh: () => void;
  loadMore: () => void;
  setStartDate?: (date?: Date | null) => void;
  setEndDate?: (date?: Date | null) => void;
  setSortOrderAscending?: (ascending: boolean) => void;
  setSearchFilters?: (searchFilters: SearchFilter[]) => void;
  setLimit?: (limit: number) => void;
  setExpandedEvents?: (expandedEvents: string[]) => void;
}

export interface UseAuditEventsProps {
  items?: AuditEvent[];
  expandedEvents?: string[];
  startDate?: Date | null;
  endDate?: Date | null;
  sortOrderAscending?: boolean;
  searchFilters?: SearchFilter[];
  limit?: number;
  lastTimestamp?: string;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
}

type Model = UseAuditEventsModel;
type Actions = UseAuditEventsActions;
type Result = [Model, Actions];

export const useAuditEvents = (props: UseAuditEventsProps): Result => {

  const {
    items: initialItems = [],
    expandedEvents: initialExpandedEvents = [],
    startDate: initialStartDate = oneWeekAgo(),
    endDate: initialEndDate = null,
    sortOrderAscending: initialSortOrderAscending = false,
    searchFilters: initialSearchFilters = [],
    limit: initialLimit = DEFAULT_LIMIT,
    lastTimestamp: initialLastTimestamp = "",
    trackRequestEvent = noop,
    trackSuccessEvent = noop,
    trackErrorEvent = noop,
  } = props;

  const accessToken = React.useContext(AuthTokenContext);
  const [items, setItems] = React.useState<AuditEvent[]>(initialItems);
  const [expandedEvents, setExpandedEvents] = React.useState<string[]>(initialExpandedEvents);
  const [startDate, setStartDate] = React.useState<string>(
    initialStartDate === null ? "" : formatLocalDateToUtc(initialStartDate));
  const [endDate, setEndDate] = React.useState<string>(
    initialEndDate === null ? "" : formatLocalDateToUtc(initialEndDate));
  const [reversedTimeline, setReversedTimeline] = React.useState<boolean>(!initialSortOrderAscending);
  const [searchFilters, setSearchFilters] = React.useState<SearchFilter[]>(initialSearchFilters);
  const [limit, setLimit] = React.useState<number>(initialLimit);
  const [lastTimestamp, setLastTimestamp] = React.useState<string>(initialLastTimestamp);
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);
  const [showNotFound, setShowNotFound] = React.useState<boolean>(false);
  const [showAccessDenied, setShowAccessDenied] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);

  const hasNextPage = React.useMemo(() =>
    !isEmptyString(lastTimestamp), [lastTimestamp]);

  const selectedValues = useSearchFilters(searchFilters);

  const {
    selectedTypes: [type] = [],
    selectedSeverities: [severity] = [],
    selectedAccountIds: [accountId] = [],
    selectedPrincipals: [principal] = [],
    selectedOrigins: [origin] = [],
    selectedRequestIds: [requestId] = [],
  } = selectedValues;

  const params = React.useMemo(() => ({
    startDate,
    endDate,
    limit,
    type,
    severity,
    accountId,
    principal,
    origin,
    requestId,
    reversedTimeline,
  }), [
    startDate,
    endDate,
    limit,
    type,
    severity,
    accountId,
    principal,
    origin,
    requestId,
    reversedTimeline,
  ]);

  const getEvents = React.useCallback(() => {

    const {
      startDate: from,
      endDate: to,
      limit: maxLimit,
      reversedTimeline: reversed,
      ...otherParams
    } = params;

    const requestParams = {
      ...otherParams,
      startDate: hasNextPage ? lastTimestamp : (reversed ? endDate : startDate),
      endDate: reversed ? startDate : endDate,
      reversedTimeline: reversed,
      ...(maxLimit > 0 ? { limit: maxLimit } : {}),
    };

    return AuditServiceClient.getEvents(accessToken, requestParams);

  }, [accessToken, params, hasNextPage, lastTimestamp]);

  React.useEffect(() => {

    let ignore = false;

    trackRequestEvent();
    setShowLoadingIndicator(true);
    setShowNotFound(false);
    setShowAccessDenied(false);
    setErrorMessage("");

    getEvents()
      .then(({ events = [], lastTimestamp: nextPage = ""}) => {
        if (!ignore) {
          trackSuccessEvent();
          setItems(currentItems => currentItems.concat(events.map(event => new AuditEvent(event))));
          setLastTimestamp(isEmptyString(nextPage) ? "" : formatUtcDateToLocal(new Date(nextPage)));
          setShowLoadingIndicator(false);
        }
      }, (response: RestClientError) => {
        if (!ignore) {
          const { analytic, error = "Get audit events failed" } = response;
          setErrorMessage(error);
          if (response.status === 404) {
            setShowNotFound(true);
          }
          if (response.status === 403) {
            setShowAccessDenied(true);
          } else {
            trackErrorEvent(analytic);
          }
          setShowLoadingIndicator(false);
        }
      });

    return () => {
      ignore = true;
    };

  }, [params, lastRefresh, trackRequestEvent, trackSuccessEvent, trackErrorEvent]);

  const showLoadMoreButton = React.useMemo(() =>
    hasNextPage && isEmptyString(errorMessage) && !showLoadingIndicator,
    [hasNextPage, errorMessage, showLoadingIndicator]);

  const selectedStartDate = React.useMemo(() =>
    isEmptyString(startDate) ? null : new Date(startDate), [startDate]);

  const selectedEndDate = React.useMemo(() =>
    isEmptyString(endDate) ? null : new Date(endDate), [endDate]);

  const allAvailableValues = useSelector(getAvailableAutocompleteValues);

  const visibleAvailableValues = useAvailableItems(items);

  const availableValues = React.useMemo(() =>
    searchFilters.length === 0 ? allAvailableValues : visibleAvailableValues,
    [searchFilters, allAvailableValues, visibleAvailableValues]);

  const model: Model = React.useMemo(() => ({
    items,
    expandedEvents,
    startDate: selectedStartDate,
    endDate: selectedEndDate,
    sortOrderAscending: !reversedTimeline,
    searchFilters,
    selectedValues,
    availableValues,
    limit,
    errorMessage,
    showNotFound,
    showAccessDenied,
    showLoadingIndicator,
    showLoadMoreButton,
  }), [
    items,
    expandedEvents,
    selectedStartDate,
    selectedEndDate,
    searchFilters,
    selectedValues,
    availableValues,
    limit,
    errorMessage,
    showNotFound,
    showAccessDenied,
    showLoadingIndicator,
  ]);

  // This is also used as the retry callback in the event of an error
  const loadMore = React.useCallback(() => {
    setLastRefresh(+new Date);
  }, [setLastRefresh]);

  const refresh = React.useCallback(() => {
    setItems([]);
    setExpandedEvents([]);
    setLastTimestamp("");
    setLastRefresh(+new Date);
  }, [setItems, setExpandedEvents, setLastTimestamp, setLastRefresh]);

  const updateStartDate = React.useCallback((date?: Date | null) => {
    if (date) {
      setStartDate(formatLocalDateToUtc(date));
    } else {
      setStartDate("");
    }
    refresh();
  }, [setStartDate, refresh]);

  const updateEndDate = React.useCallback((date?: Date | null) => {
    if (date) {
      setEndDate(formatLocalDateToUtc(date));
    } else {
      setEndDate("");
    }
    refresh();
  }, [setEndDate, refresh]);

  const updateReversedTimeline = React.useCallback((reversed: boolean) => {
    setReversedTimeline(reversed);
    refresh();
  }, [setReversedTimeline, refresh]);

  const setSortOrderAscending = React.useCallback((ascending: boolean) =>
    updateReversedTimeline(!ascending), [updateReversedTimeline]);

  const updateSearchFilters = React.useCallback((filters: SearchFilter[]) => {
    setSearchFilters(filters);
    refresh();
  }, [setSearchFilters, refresh]);

  const updateLimit = React.useCallback((updatedLimit: number) => {
    setLimit(updatedLimit);
    refresh();
  }, [setLimit, refresh]);

  const actions: Actions = React.useMemo(() => ({
    refresh,
    loadMore,
    setStartDate: updateStartDate,
    setEndDate: updateEndDate,
    setSortOrderAscending,
    setSearchFilters: updateSearchFilters,
    setLimit: updateLimit,
    setExpandedEvents,
  }), [
    refresh,
    loadMore,
    updateStartDate,
    updateEndDate,
    setSortOrderAscending,
    updateSearchFilters,
    setLimit,
    setExpandedEvents,
  ]);

  return [model, actions];
};

export const useUserAuditEvents = (userId: string = "", props: UseAuditEventsProps = {}) => {

  const { searchFilters: initialSearchFilters = [] } = props;

  const accountId = React.useContext(AccountIdContext);

  const principal = React.useMemo(() =>
    `lrn:iot:aam::${accountId}:principal:user:${userId}`,
    [userId, accountId]);

  const searchFilters = React.useMemo(() => {

    return ([] as SearchFilter[])
      .concat([new SearchFilter({
        key: AuditEventFilter.PRINCIPAL,
        value: principal,
      })])
      .concat(initialSearchFilters.filter(searchFilter =>
        searchFilter.getKey() !== AuditEventFilter.PRINCIPAL));

  }, [principal, initialSearchFilters]);

  return useAuditEvents({
    ...props,
    searchFilters,
  });
};

export default useAuditEvents;
