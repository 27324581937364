import { AppSchema } from "@schemas";
import { EditModeSelectors } from "../../selectors/editMode";
import { SchemaWizardActions } from "../../actions/schemaWizard";
import { SchemaWizardSelectors } from "../../selectors/schemaWizard";
import { ETagHeaders, RestClientError, SchemaRegistryClient } from "@network";
import { SchemaEditorViewMode, SchemaWizardStep } from "../../reducers/schemaWizard";
import { DEFAULT_STATE, EditModeAction, EditModeActionType } from "../../reducers/editMode";
import { JsonSchemaDefinition, JsonSchemaMetadata, JsonSchemaMetadataAttributes } from "@data";
import SchemaWizardState from "../../models/SchemaWizardState";

export const setOriginalMetaData =
  (value: JsonSchemaMetadataAttributes = DEFAULT_STATE.originalMetaData): EditModeAction => ({
    type: EditModeActionType.SET_ORIGINAL_META_DATA,
    value,
  });

export const setOriginalSchema =
  (value: JsonSchemaDefinition = DEFAULT_STATE.originalSchema): EditModeAction => ({
    type: EditModeActionType.SET_ORIGINAL_SCHEMA,
    value,
  });

export const editSchemaRequest = (): EditModeAction => ({
  type: EditModeActionType.EDIT_SCHEMA_REQUEST,
});

export const editSchemaSuccess = (): EditModeAction => ({
  type: EditModeActionType.EDIT_SCHEMA_SUCCESS,
});

export const editSchemaFailed = (error: string): EditModeAction => ({
  type: EditModeActionType.EDIT_SCHEMA_FAILED,
  value: error || "",
});

export const reset = () => (dispatch: any) => {
  dispatch(setOriginalMetaData());
  return dispatch(setOriginalSchema());
};

export const save = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const updatedSchema = SchemaWizardSelectors.getSchema(state);

  const originalSchema = EditModeSelectors.getOriginalSchema(state);
  const originalSchemaMetaData = EditModeSelectors.getOriginalMetaData(state);

  if (!SchemaWizardSelectors.isTitleValid(state)) {
    return dispatch(SchemaWizardActions.setErrorMessage("Schema title is required"));
  }

  if (!SchemaWizardSelectors.isDescriptionValid(state)) {
    return dispatch(SchemaWizardActions.setErrorMessage("Schema description is required"));
  }

  if (updatedSchema.$id !== originalSchema.$id) {
    return dispatch(SchemaWizardActions.setErrorMessage("Schema '$id' cannot be changed"));
  }

  if (updatedSchema.title !== originalSchema.title) {
    return dispatch(SchemaWizardActions.setErrorMessage("Schema 'title' cannot be changed"));
  }

  const accessToken = SchemaWizardSelectors.getAccessToken(state);
  const namespace = originalSchemaMetaData.namespace;
  const name = originalSchemaMetaData.name;
  const version = originalSchemaMetaData.getVersionAsString();
  const schema = SchemaWizardSelectors.getJson(state);
  const etag = originalSchemaMetaData.etag;

  dispatch(SchemaWizardActions.showLoadingIndicator());
  dispatch(SchemaWizardActions.setErrorMessage());
  dispatch(editSchemaRequest());

  return SchemaRegistryClient.editSchema(accessToken, namespace, name, version, schema, etag)
    .then((response: ETagHeaders) => {

      const { etag: updatedEtag = "" } = response;

      const editedSchema = new JsonSchemaMetadata({
        ...originalSchemaMetaData.toJS(),
        ...(updatedEtag.length === 0 ? {} : { etag: updatedEtag }),
      });

      dispatch(editSchemaSuccess());
      dispatch(SchemaWizardActions.setCreatedSchemaAttributes(editedSchema.toJS()));
      dispatch(SchemaWizardActions.hideLoadingIndicator());
      return dispatch(SchemaWizardActions.setSuccessMessage("Schema Updated"));

    }, (response: RestClientError) => {

      const { analytic, error = "Failed to update schema" } = response;
      dispatch(editSchemaFailed(analytic));
      dispatch(SchemaWizardActions.hideLoadingIndicator());
      return dispatch(SchemaWizardActions.setErrorMessage(error));
    });
};

export const initialize = (originalMetaData: JsonSchemaMetadata,
                           originalSchema: JsonSchemaDefinition) => (dispatch: any) => {

  dispatch(SchemaWizardActions.reset());
  dispatch(setOriginalMetaData({ ...originalMetaData.toJS() }));
  dispatch(setOriginalSchema({ ...originalSchema }));
  dispatch(SchemaWizardActions.setNamespace(originalMetaData.namespace));
  dispatch(SchemaWizardActions.updateSchema({ ...originalSchema }));
  dispatch(SchemaWizardActions.setSchemaWizardStep(SchemaWizardStep.SCHEMA));
  dispatch(SchemaWizardActions.setSchemaEditorViewMode(SchemaEditorViewMode.SUMMARY));
  dispatch(SchemaWizardActions.setDefaultStateAttributes(new SchemaWizardState({
    namespace: originalMetaData.getNamespace(),
    name: originalMetaData.getName(),
    json: JSON.stringify(originalSchema, null, "  "),
  }).toJS()));
  return dispatch(SchemaWizardActions.setShowEditMode(true));
};
