import React from "react";
import classnames from "classnames";
import { DetailsViewRoute } from "@components";
import { DeviceConfigurationDataMode } from "../reducers";
import { noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { deviceEnrollment as styles, PrimaryIcon } from "./styles";
import DetailsView from "@components/details-view";

export interface Model {
  dataMode?: DeviceConfigurationDataMode;
  configurationErrorMessage?: string;
  loading?: boolean;
  deviceEnrolled?: boolean;
  children?: React.ReactNode;
  deviceId?: string;
  routes?: DetailsViewRoute[];
  errorMessage?: string;
  showNotFound?: boolean;
}

export interface Actions {
  setDataMode?: (dataMode: DeviceConfigurationDataMode) => void;
  refresh?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DeviceConfigurationView = withStyles(styles)((props: Props) => {

  const {
    classes,
    loading,
    deviceId,
    refresh = noop,
    routes = [],
    errorMessage,
    showNotFound,
  } = props;

  return (
    <div className={classnames("deviceConfigurationView", classes.container)}>
      <DetailsView
        title={deviceId}
        icon={PrimaryIcon}
        refresh={refresh}
        showLoadingIndicator={loading}
        routes={routes}
        errorMessage={errorMessage}
        tabsMarginTop={false}
        showNotFound={showNotFound}
      />
    </div>
  );
});

export default DeviceConfigurationView;
