import { Record } from "immutable";
import { getStringValue } from "@util";
import { DSTypeInfoAttributes } from "@data/DSTypeInfo";

export interface DSLayoutInfoMetadata {
  [key: string]: string;
}

export interface DSLayoutInfoAttributes {
  name: string;
  description: string;
  children: DSTypeInfoAttributes[];
  metadata: DSLayoutInfoMetadata;
}

export class DSLayoutInfo extends Record({
  name: "",
  description: "",
  children: [],
  metadata: {},
}) implements DSLayoutInfoAttributes {

  public static EMPTY: DSLayoutInfo = new DSLayoutInfo();

  public readonly name: string;
  public readonly description: string;
  public readonly children: DSTypeInfoAttributes[];
  public readonly metadata: DSLayoutInfoMetadata;

  public getName(): string {
    return getStringValue(this.name);
  }

  public getDescription(): string {
    return this.description;
  }

  public getChildren(): DSTypeInfoAttributes[] {
    return Array.isArray(this.children) ? this.children : [];
  }

  public getMetadata(): DSLayoutInfoMetadata {
    const metadata = this.metadata || {};
    return { ...metadata };
  }
}
