import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { Actions, getPluralString, isEmptyString, noop } from "@util";
import { SecurityGroups, useServiceSecurityGroups } from "@components";
import { getServiceId } from "../selectors";
import { setRemoveServiceButtonEnabled } from "../actions";
import { SecurityGroup } from "@data";

export interface Model {
  className?: string;
  serviceId?: string;
}

export interface Actions {
  setRemoveServiceButtonEnabled?: (enabled: boolean) => void;
  showSecurityGroupDetails?: (securityGroup: SecurityGroup) => void;
}

type Props = Model & Actions;

const SecurityGroupsView = (props: Props) => {

  const {
    className,
    serviceId,
    showSecurityGroupDetails = noop,
    setRemoveServiceButtonEnabled: enableRemoveServiceButton = noop,
  } = props;

  const [{ securityGroups, ...model}, actions] = useServiceSecurityGroups(serviceId);
  // note: above hook is still using historical API for getServices

  const { nameFilter, totalNumSecurityGroups, totalNumVisibleSecurityGroups } = model;

  const title = React.useMemo(() => {

    if (!isEmptyString(nameFilter)) {
      return getPluralString(totalNumVisibleSecurityGroups, {
        other: "Groups found",
        one: "Group found",
      });
    } else {
      return "This service belongs to " + getPluralString(totalNumSecurityGroups, {
        other: " groups",
        one: "group",
      });
    }
  }, [nameFilter, totalNumSecurityGroups, totalNumVisibleSecurityGroups]);

  React.useEffect(() => {
    enableRemoveServiceButton(totalNumSecurityGroups > 0);
  }, [enableRemoveServiceButton, totalNumSecurityGroups]);

  // NOTE: Name filter delay reduced to 25 because this particular table only supports
  // client-side filtering; if backend support for including a name filter when fetching
  // groups a service belongs to is ever added, we should revert back to the default 250ms.
  return (
    <SecurityGroups
      {...model}
      {...actions}
      className={classnames("serviceSecurityGroups", className)}
      title={title}
      items={securityGroups}
      nameFilterDelay={25}
      loadingTitle="Loading groups this service belongs to..."
      noResultsLabel="This service does not belong to any groups"
      errorTitle="Failed to load groups this service belongs to"
      onClickItem={showSecurityGroupDetails}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  serviceId: getServiceId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setRemoveServiceButtonEnabled: (enabled: boolean) => dispatch(setRemoveServiceButtonEnabled(enabled)),
  ...ownProps,
});

export default connect <Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(SecurityGroupsView);
