import React from "react";
import { isEmptyString } from "@util";
import { RestClientError } from "@network";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks";

export interface PagingResponse {
  paging?: {
    next?: string;
  };
}

export interface UsePaginatedApiRequestProps<S extends PagingResponse, E extends RestClientError = RestClientError>
  extends UseApiRequestProps<S, E> {

  makeApiRequest: (accessToken: string, nextPageToken?: string) => Promise<S>;
  nextPageToken?: string;
}

export interface UsePaginatedApiRequestModel<S extends PagingResponse, E extends RestClientError = RestClientError>
  extends UseApiRequestModel<S, E> {

  nextPageToken?: string;
  showLoadMoreButton?: boolean;
}

export interface UsePaginatedApiRequestActions<S extends PagingResponse, E extends RestClientError = RestClientError>
  extends UseApiRequestActions<S, E> {

  loadMore: () => void;
}

type Props<S extends PagingResponse, E extends RestClientError> = UsePaginatedApiRequestProps<S, E>;
type Model<S extends PagingResponse, E extends RestClientError> = UsePaginatedApiRequestModel<S, E>;
type Actions<S extends PagingResponse, E extends RestClientError> = UsePaginatedApiRequestActions<S, E>;
type Result<S extends PagingResponse, E extends RestClientError> = [Model<S, E>, Actions<S, E>];

export const usePaginatedApiRequest = <S extends PagingResponse, E extends RestClientError = RestClientError>
(props: Props<S, E>): Result<S, E> => {

  const {
    makeApiRequest: makePaginatedApiRequest,
    nextPageToken: initialNextPageToken = "",
    ...otherProps
  } = props;

  const [nextPageToken, setNextPageToken] = React.useState(initialNextPageToken);

  const showLoadMoreButton = React.useMemo(() => !isEmptyString(nextPageToken), [nextPageToken]);

  const makeApiRequest = React.useCallback((authToken: string) =>
      makePaginatedApiRequest(authToken, nextPageToken),
    [nextPageToken, makePaginatedApiRequest]);

  const [baseModel, baseActions] = useApiRequest<S, E>({
    ...otherProps,
    makeApiRequest,
  });

  const { showErrorView, showLoadingIndicator, successResponse } = baseModel;

  const { reset: baseReset, refresh: baseRefresh } = baseActions;

  const reset = React.useCallback(() => {
    setNextPageToken("");
    baseReset();
  }, [setNextPageToken, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  // Load more is also used by the PaginatedList component for Retry logic when an error is visible
  const loadMore = React.useCallback(() => {
    if (!showLoadingIndicator && (showErrorView || nextPageToken)) {
      baseRefresh();
    }
  }, [showLoadingIndicator, showErrorView, nextPageToken, baseRefresh]);

  const model = React.useMemo<Model<S, E>>(() => ({
    ...baseModel,
    nextPageToken,
    showLoadMoreButton,
  }), [
    baseModel,
    nextPageToken,
    showLoadMoreButton,
  ]);

  const actions = React.useMemo<Actions<S, E>>(() => ({
    ...baseActions,
    reset,
    refresh,
    loadMore,
  }), [
    baseActions,
    reset,
    refresh,
    loadMore,
  ]);

  React.useEffect(() => {
    if (successResponse) {
      const { paging: { next = "" } = { next: "" } } = successResponse;
      setNextPageToken(next);
    }
  }, [successResponse, setNextPageToken]);

  return React.useMemo<Result<S, E>>(() => [model, actions], [model, actions]);
};

export default usePaginatedApiRequest;
