import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import { DataSetRequest, DataSetState } from "@data";
import { ActionMenuItem } from "@components/actions-menu";
import DataSetRequestsListColumn from "./DataSetRequestsListColumn";
import { createColumns } from "@components/module-list-view/helpers";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { mapDataSetTypeToOptionName } from "@components/data-sets-list";
import { PrimaryIcon } from "@modules/dataSetRequests/components/styles";
import { getPathToDataSetRequest } from "@modules/dataSetRequests/helpers";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { DataSetRequestAction } from "@components/data-set-request-actions-menu";
import ModuleListView, {
  ModuleListViewActions,
  ModuleListViewModel,
} from "@components/module-list-view";
import styles from "./styles";

type Item = DataSetRequest;
type Column = DataSetRequestsListColumn;

export const DEFAULT_DATA_SET_REQUESTS_LIST_COLUMNS: Column[] = [
  DataSetRequestsListColumn.NAME,
  DataSetRequestsListColumn.STATUS,
  DataSetRequestsListColumn.TYPE,
];

const DEFAULT_MAP_ITEM_TO_ACTION = (dataRequest: Item, action: ActionMenuItem) => {
  switch (action.id) {
    case DataSetRequestAction.EDIT:
      return {
        ...action,
        name: dataRequest.isRequestIotApproved() ? "Edit Description" : "Edit Request",
      };
    default:
      return {
        ...action,
      };

  }
};

export const mapStatusToStatusName = (value: DataSetState) => {
  switch (value) {
    case DataSetState.PENDING_IOT_APPROVAL:
      return "Pending IoT Approval";
    case DataSetState.PENDING_PRIVACY_TNC_UPLOAD:
      return "Pending Privacy Terms and Conditions Upload";
    case DataSetState.ADDITIONAL_INFORMATION_REQUESTED:
      return "Additional Information Requested";
    case DataSetState.PENDING_BUSINESS_CUSTOMER_APPROVALS:
      return "Pending Business Customer Approvals";
    case DataSetState.BO_PENDING:
      return "Pending Business Owner Approval";
    case DataSetState.BO_APPROVED:
      return "Business Owner Approved";
    case DataSetState.DO_PENDING:
      return "Pending Data Owner Approval";
    case DataSetState.DO_APPROVED:
      return "Data Owner Approved";
    case DataSetState.READY_FOR_PROVISIONING:
      return "Ready for Provisioning";
    case DataSetState.PROVISIONING_IN_PROGRESS:
      return "Provisioning in Progress";
    case DataSetState.PENDING_INGESTION_MECHANISM_SELECTION:
      return "Pending Ingestion Mechanism Selection";
    case DataSetState.READY_FOR_INGESTION:
      return "Ready for Ingestion";
    case DataSetState.REJECTED:
      return "Rejected";
    case DataSetState.SUSPENDED:
      return "Suspended";
    case DataSetState.DEPRECATED:
      return "Deprecated";
    case DataSetState.DELETED:
      return "Deleted";
    case DataSetState.CANCELLED:
      return "Cancelled";
    case DataSetState.NONE:
      return "";
    default:
      // If a new type was introduced, we do not want to block it from being used,
      // so just render the value as a string until a proper label is introduced.
      return value + "";
  }
};

export interface DataSetRequestsListModel extends ModuleListViewModel<Item, Column> {
}

export interface DataSetRequestsListActions extends ModuleListViewActions<Item, Column> {
  showDataSetRequestDetails?: (dataSetRequest: DataSetRequest) => void;
  cloneDataSetRequest?: (dataSetRequest: DataSetRequest) => void;
  editDataSetRequest?: (dataSetRequest: DataSetRequest) => void;
  deleteDataSetRequest?: (dataSetRequest: DataSetRequest) => void;
}

type Model = DataSetRequestsListModel;
type Actions = DataSetRequestsListActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const DataSetRequestsList = withStyles(styles)((props: Props) => {

  const {
    classes,
    items = [],
    columns = DEFAULT_DATA_SET_REQUESTS_LIST_COLUMNS,
    actions = [],
    tableLayoutFixed = false,
    summaryViewIcon = PrimaryIcon,
    nameFilter: initialNameFilter = "",
    noResultsLabel = "No Data Set Requests Found",
    summaryViewQuantities = {
      other: "data set requests",
      one: "data set request",
    },
    showDataSetRequestDetails,
    cloneDataSetRequest = noop,
    editDataSetRequest = noop,
    deleteDataSetRequest = noop,
    onClickAction = noop,
    setNameFilter: updateNameFilter = noop,
    mapItemToAction = DEFAULT_MAP_ITEM_TO_ACTION,
    ...otherProps
  } = props;

  const [nameFilter, setNameFilter] = React.useState(initialNameFilter);

  const onActionClicked = React.useCallback((dataSetRequest: DataSetRequest, action: ActionMenuItem) => {
    if (action.id === DataSetRequestAction.DELETE) {
      deleteDataSetRequest(dataSetRequest);
    } else if (action.id === DataSetRequestAction.EDIT) {
      editDataSetRequest(dataSetRequest);
    } else if (action.id === DataSetRequestAction.CLONE) {
      cloneDataSetRequest(dataSetRequest);
    } else {
      onClickAction(dataSetRequest, action);
    }
  }, [
    deleteDataSetRequest,
    editDataSetRequest,
    cloneDataSetRequest,
    onClickAction,
  ]);

  // In case parent is maintaining state, make sure local state changes are propagated up
  React.useEffect(() => {
    updateNameFilter(nameFilter);
  }, [nameFilter, updateNameFilter]);

  const moduleListItems: ListViewItem<DataSetRequest>[] = React.useMemo(() =>
    items.map(request => {
      return {
        item: request,
        pathToDetailsView: getPathToDataSetRequest(request.getDataSetRequestId()),
        icon: PrimaryIcon,
        columnAttributes: createColumns([
          {
            className: "requestName",
            value: request.getDataSetAlias(),
            column: DataSetRequestsListColumn.NAME,
            firstColumn: true,
          },
          {
            className: "requestId",
            chipTags: [mapStatusToStatusName(request.getStatus())],
            column: DataSetRequestsListColumn.STATUS,
          },
          {
            className: classnames("dataSetType", classes.dataSetType),
            value: mapDataSetTypeToOptionName(request.getDataSetType()),
            column: DataSetRequestsListColumn.TYPE,
          },
        ]),
      };
    }), [items]);

  return (
    <ModuleListView
      {...otherProps}
      columns={columns}
      actions={actions}
      tableLayoutFixed={tableLayoutFixed}
      summaryViewIcon={summaryViewIcon}
      listViewItems={moduleListItems}
      nameFilter={nameFilter}
      noResultsLabel={noResultsLabel}
      summaryViewQuantities={summaryViewQuantities}
      onClickItem={showDataSetRequestDetails}
      onClickAction={onActionClicked}
      setNameFilter={setNameFilter}
      mapItemToAction={mapItemToAction}
    />
  );
});

export default DataSetRequestsList;
