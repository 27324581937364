import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import DeviceTypeInfoView , { Actions, Model } from "../../components/addDeviceWizard/DeviceTypeInfoView";
import DeviceTypeJsonView from "./DeviceTypeJsonView";

const DeviceTypeInfoViewContainer = (props: Model & Actions) => {

  return (
    <DeviceTypeInfoView {...props}>
      <DeviceTypeJsonView/>
    </DeviceTypeInfoView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceTypeInfoViewContainer);
