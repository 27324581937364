import useGetDataAccessRequests, {
  UseGetDataAccessRequestsActions,
  UseGetDataAccessRequestsModel,
  UseGetDataAccessRequestsProps,
} from "../use-get-data-access-requests";
import useGetAccountDataAccessRequests, {
  UseGetAccountDataAccessRequestsActions,
  UseGetAccountDataAccessRequestsModel,
  UseGetAccountDataAccessRequestsProps,
} from "../use-get-account-data-access-requests";

export interface UseDataAccessRequestsProps extends
  UseGetDataAccessRequestsProps, UseGetAccountDataAccessRequestsProps {

  filterByCurrentUser?: boolean;
}

export interface UseDataAccessRequestsModel extends
  UseGetDataAccessRequestsModel, UseGetAccountDataAccessRequestsModel {

}

export interface UseDataAccessRequestsActions extends
  UseGetDataAccessRequestsActions, UseGetAccountDataAccessRequestsActions {

}

type Props = UseDataAccessRequestsProps;
type Model = UseDataAccessRequestsModel;
type Actions = UseDataAccessRequestsActions;
type Result = [Model, Actions];

export const useDataAccessRequests = (props: Props): Result => {

  const {
    filterByCurrentUser = true,
    ...otherProps
  } = props;

  if (filterByCurrentUser) {
    return useGetDataAccessRequests(otherProps);
  } else {
    return useGetAccountDataAccessRequests(otherProps);
  }
};

export default useDataAccessRequests;
