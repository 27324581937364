import React from "react";
import AppSchema from "@schemas";
import { connect } from "react-redux";
import lowerCase from "lodash/lowerCase";
import { useHistory } from "react-router-dom";
import { equalsIgnoreCase, isEmptyString, noop } from "@util";
import { ManagedPolicy, Policy, SecurityGroup, SummaryViewData } from "@data";
import { ServiceActionOperation, ServiceSecretOperation, useGetServiceRegional } from "@hooks";
import FilterSwitch from "@components/filter-switch";
import ActionMenuItem from "@components/actions-menu/ActionMenuItem";
import ServiceActionOperationDialog from "@components/service-action-operation-dialog";
import ServiceSecretOperationDialog from "@components/service-secret-operation-dialog";
import SummaryViewActionItems from "@components/summary-view-with-actions/SummaryViewActionItems";
import EnableServiceMfaDialog from "@components/enable-service-mfa-dialog";
import DisableServiceMfaDialog from "@components/disable-service-mfa-dialog";
import { MODULE_PATH as SERVICES_LIST_PATH } from "@modules/securityServicesRegional/constants";
import { setServiceEnabled } from "../actions";
import { getActionMenuItems, getCurrentServiceId, getServiceId } from "../selectors";
import SecurityView from "../components/SecurityView";
import ServicesSummaryView from "../components/SummaryView";
import SecurityGroupsView from "./SecurityGroupsView";
import EffectivePoliciesView from "./EffectivePoliciesView";
import SecurityServiceDetails, {
  SecurityServiceDetailsActions,
  SecurityServiceDetailsModel,
} from "../components/SecurityServiceDetails";
import { PrimaryIcon } from "../components/styles";
import {
  DEFAULT_SERVICE_ACTION_MENU_ITEMS,
  DELETE_SERVICE_SECRET,
  DetailsViewRoute,
  GENERATE_SERVICE_SECRET,
  ServiceAction,
  SET_SERVICE_SECRET,
} from "@components";

interface ContainerModel extends SecurityServiceDetailsModel {
  currentServiceId?: string;
  serviceId?: string;
}

interface ContainerActions extends SecurityServiceDetailsActions {
  showPolicyDetails?: (policy: Policy) => void;
  showManagedPolicyDetails?: (policy: ManagedPolicy) => void;
  showSecurityGroupDetails?: (securityGroup: SecurityGroup) => void;
  addServiceToGroup?: () => void;
  removeServiceFromGroup?: () => void;
  attachPolicy?: () => void;
  detachPolicy?: () => void;
  setServiceEnabled?: (enabled: boolean) => void;
}

type Props = ContainerModel & ContainerActions & {
  children?: React.ReactNode;
};

const ServiceDetailsContainer = (props: Props) => {

  const {
    currentServiceId = "",
    serviceId = "",
    icon = PrimaryIcon,
    title = "Service Details",
    actions: serviceActions = DEFAULT_SERVICE_ACTION_MENU_ITEMS,
    addServiceToGroup = noop,
    removeServiceFromGroup = noop,
    attachPolicy = noop,
    detachPolicy = noop,
    onClickAction = noop,
    showPolicyDetails = noop,
    showManagedPolicyDetails = noop,
    showSecurityGroupDetails = noop,
    setServiceEnabled: enableServiceButton = noop,
    ...otherProps
  } = props;

  const [openActionOperationDialog, setOpenActionOperationDialog] = React.useState(false);
  const [openSecretOperationDialog, setOpenSecretOperationDialog] = React.useState(false);
  const [actionOperation, setActionOperation] = React.useState(ServiceActionOperation.NONE);
  const [secretOperation, setSecretOperation] = React.useState(ServiceSecretOperation.NONE);

  const [showEnableMfaDialog, setShowEnableMfaDialog] = React.useState(false);
  const [showDisableMfaDialog, setShowDisableMfaDialog] = React.useState(false);

  const [model, actions] = useGetServiceRegional({serviceId});

  const {service, showNotFound, showAccessDenied, ...otherModel} = model;
  const { refresh, ...otherActions} = actions;

  const openEnableMfaDialog = React.useCallback(() =>
    setShowEnableMfaDialog(true), [setShowEnableMfaDialog]);

  const closeEnableMfaDialog = React.useCallback(() =>
    setShowEnableMfaDialog(false), [setShowEnableMfaDialog]);

  const onMfaEnabledSuccess = React.useCallback(() => {
    closeEnableMfaDialog();
    refresh();
  }, [closeEnableMfaDialog, refresh]);

  const openDisableMfaDialog = React.useCallback(() =>
    setShowDisableMfaDialog(true), [setShowDisableMfaDialog]);

  const closeDisableMfaDialog = React.useCallback(() =>
    setShowDisableMfaDialog(false), [setShowDisableMfaDialog]);

  const onMfaDisabledSuccess = React.useCallback(() => {
    closeDisableMfaDialog();
    refresh();
  }, [closeDisableMfaDialog, refresh]);

  React.useEffect(() => {
    enableServiceButton(service.isEnabled());
  }, [enableServiceButton, service]);

  const summaryViewItems = React.useMemo(() => [
    new SummaryViewData({
      className: "id",
      name: "Service ID",
      value: service.getId(),
    }),
    new SummaryViewData({
      className: "created",
      name: "Created",
      value: service.getCreated(),
      date: true,
    }),
  ], [service]);

  const deleteServiceRegional = React.useCallback(() => {
    setActionOperation(ServiceActionOperation.DELETE);
    setOpenActionOperationDialog(true);
  }, [setActionOperation, setOpenActionOperationDialog]);

  const generateServiceSecret = React.useCallback(() => {
    setSecretOperation(ServiceSecretOperation.GENERATE);
    return setOpenSecretOperationDialog(true);
  }, [setSecretOperation, setOpenSecretOperationDialog]);

  const setServiceSecret = React.useCallback(() => {
    setSecretOperation(ServiceSecretOperation.SET);
    return setOpenSecretOperationDialog(true);
  }, [setSecretOperation, setOpenSecretOperationDialog]);

  const deleteServiceSecret = React.useCallback(() => {
    setSecretOperation(ServiceSecretOperation.DELETE);
    return setOpenSecretOperationDialog(true);
  }, [setSecretOperation, setOpenSecretOperationDialog]);

  const enableService = React.useCallback(() => {
    setActionOperation(ServiceActionOperation.ENABLE);
    setOpenActionOperationDialog(true);
  }, [setActionOperation, setOpenActionOperationDialog]);

  const disableService = React.useCallback(() => {
    setActionOperation(ServiceActionOperation.DISABLE);
    setOpenActionOperationDialog(true);
  }, [setActionOperation, setOpenActionOperationDialog]);

  const primarySecretActions = React.useMemo(() => ([
    {
      ...GENERATE_SERVICE_SECRET,
      disabled: !service.isPrimarySecretAvailable(),
    },
    {
      ...SET_SERVICE_SECRET,
      disabled: !service.isPrimarySecretAvailable(),
    },
  ]), [service]);

  const secondarySecretActions = React.useMemo(() => ([
    {
      ...DELETE_SERVICE_SECRET,
      disabled: !service.isSecondarySecretAvailable(),
    }
  ]), [service]);

  const onClickServiceAction = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case ServiceAction.ADD_SERVICE_TO_GROUP:
        return addServiceToGroup();
      case ServiceAction.REMOVE_SERVICE_FROM_GROUP:
        return removeServiceFromGroup();
      case ServiceAction.ATTACH_POLICY_TO_SERVICE:
        return attachPolicy();
      case ServiceAction.DETACH_POLICY_FROM_SERVICE:
        return detachPolicy();
      case ServiceAction.DELETE_SERVICE_REGIONAL:
        return deleteServiceRegional();
      default:
        return onClickAction(action);
    }
  }, [
    addServiceToGroup,
    removeServiceFromGroup,
    attachPolicy,
    detachPolicy,
    deleteServiceRegional,
    onClickAction
  ]);

  const onClickPrimarySecretActions = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case ServiceAction.GENERATE_SERVICE_SECRET:
        return generateServiceSecret();
      case ServiceAction.SET_SERVICE_SECRET:
        return setServiceSecret();
      default:
        return onClickAction(action);
    }
  }, [
    generateServiceSecret,
    setServiceSecret,
    onClickAction
  ]);

  const onClickSecondarySecretActions = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case ServiceAction.DELETE_SERVICE_SECRET:
        return deleteServiceSecret();
      default:
        return onClickAction(action);
    }
  }, [
    deleteServiceSecret,
    onClickAction
  ]);

  const onClickSwitch = React.useCallback((value: boolean) => {
    return value ? enableService() : disableService();
  }, [enableService, disableService]);

  const additionalView = React.useMemo(() => (
      <FilterSwitch
        className="serviceStateFilterSwitch"
        title="Service State"
        titleClassName="filterSwitchTitle serviceStateFilterSwitchTitle"
        showTitle={true}
        checked={service.isEnabled()}
        leftLabel="Disabled"
        leftLabelClassName="disabled"
        rightLabel="Enabled"
        rightLabelClassName="enabled"
        onChange={onClickSwitch}
      />
  ), [service, onClickSwitch]);

  const onClickMfaSwitch = React.useCallback((value: boolean) => {
    if (value) {
      openEnableMfaDialog();
    } else {
      openDisableMfaDialog();
    }
  }, [openEnableMfaDialog, openDisableMfaDialog]);

  const showMfaFilterSwitch = React.useMemo(() => !isEmptyString(currentServiceId) &&
      equalsIgnoreCase(currentServiceId, serviceId),
    [currentServiceId, serviceId]);

  const mfaView = React.useMemo(() => !showMfaFilterSwitch ? null : (
      <FilterSwitch
        className="mfaFilterSwitch"
        title="Multi-factor Authentication"
        titleClassName="filterSwitchTitle mfaFilterSwitchTitle"
        showTitle={true}
        checked={service.isMfaEnabled()}
        leftLabel="Disabled"
        leftLabelClassName="disabled"
        rightLabel="Enabled"
        rightLabelClassName="enabled"
        onChange={onClickMfaSwitch}
      />
  ), [showMfaFilterSwitch, service, onClickMfaSwitch]);

  const securityGroupsView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <ServicesSummaryView
        className="securityGroupsTabSummaryView"
        items={summaryViewItems}
      >
        {additionalView}
        <SecurityGroupsView
          className="summaryViewChildContent serviceSecurityGroupsView"
          showSecurityGroupDetails={showSecurityGroupDetails}
        />
      </ServicesSummaryView>
    );
  }, [summaryViewItems, additionalView, showSecurityGroupDetails]);

  const effectivePoliciesView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <ServicesSummaryView
        className="policiesTabSummaryView"
        items={summaryViewItems}
      >
        {additionalView}
        <EffectivePoliciesView
          className="summaryViewChildContent servicePoliciesView"
          showPolicyDetails={showPolicyDetails}
          showManagedPolicyDetails={showManagedPolicyDetails}
        />
      </ServicesSummaryView>
    );
  }, [summaryViewItems, additionalView, showPolicyDetails, showManagedPolicyDetails]);

  const securityViewItems: SummaryViewActionItems[] = React.useMemo(() => ([
    {
      showActions: false,
      summaryViewItem:
        new SummaryViewData({
          className: "protocol",
          name: "Protocol",
          value: service.getServiceProtocol(),
        }),
    },
    {
      showActions: true,
      actions: primarySecretActions,
      onClickAction: onClickPrimarySecretActions,
      summaryViewItem:
        new SummaryViewData({
          className: "primarySecret",
          name: "Primary Secret",
          value: service.getPrimarySecretAvailability(),
        })
    },
    {
      showActions: true,
      actions: secondarySecretActions,
      onClickAction: onClickSecondarySecretActions,
      summaryViewItem:
        new SummaryViewData({
          className: "secondarySecret",
          name: "Secondary Secret",
          value: service.getSecondarySecretAvailability(),
        })
    },
  ] as SummaryViewActionItems[]).concat(showMfaFilterSwitch ? [] : ([
    {
      showActions: false,
      showTooltipIcon: true,
      summaryViewItem:
        new SummaryViewData({
          className: "mfaStatus",
          name: "Multi-factor Authentication",
          value: service.getMfaStatus(),
          tooltipIconText: "You must be signed in as this service principal in order to " +
            `${service.isMfaEnabled() ? "disable" : "enable"} MFA`,
        }),
    },
  ])), [
    service,
    primarySecretActions,
    onClickPrimarySecretActions,
    secondarySecretActions,
    onClickSecondarySecretActions,
    showMfaFilterSwitch,
  ]);

  const securityView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <ServicesSummaryView
        className="securityTabSummaryView"
        items={summaryViewItems}
      >
        {additionalView}
        <SecurityView
          className="summaryViewChildContent serviceSecurityView"
          items={securityViewItems}
        >
          {mfaView}
        </SecurityView>
      </ServicesSummaryView>
    );
  }, [summaryViewItems, additionalView, securityViewItems, mfaView]);

  const ROUTES: DetailsViewRoute[] = React.useMemo(() => [
    {
      id: "securityGroups",
      name: "Security Groups",
      view: securityGroupsView,
    },
    {
      id: "policies",
      name: "Policies",
      path: "/policies",
      view: effectivePoliciesView,
    },
    {
      id: "securityView",
      name: "Security",
      path: "/security",
      view: securityView,
    }
  ], [securityGroupsView, effectivePoliciesView, securityView]);

  const closeActionOperationDialog = React.useCallback(() => {
    setActionOperation(ServiceActionOperation.NONE);
    return setOpenActionOperationDialog(false);
  }, [setActionOperation, setOpenActionOperationDialog]);

  const closeSecretOperationDialog = React.useCallback(() => {
    setSecretOperation(ServiceSecretOperation.NONE);
    return setOpenSecretOperationDialog(false);
  }, [setSecretOperation, setOpenSecretOperationDialog]);

  const history = useHistory();

  const showListView = React.useCallback(() => {
    history.push(SERVICES_LIST_PATH);
  }, [history]);

  const onActionOperationSuccess = React.useCallback(() => {

    closeActionOperationDialog();

    if (actionOperation === ServiceActionOperation.DELETE) {
      showListView();
    } else {
      refresh();
    }
  }, [closeActionOperationDialog, showListView, refresh, actionOperation]);

  const onSecretOperationSuccess = React.useCallback(() => {

    closeSecretOperationDialog();
    refresh();
  }, [closeSecretOperationDialog, refresh]);

  const actionDialogClassName = React.useMemo(() => `${lowerCase(actionOperation)}ServiceDialog`, [actionOperation]);
  const secretDialogClassName = React.useMemo(() =>
    `${lowerCase(secretOperation)}ServiceSecretDialog`, [secretOperation]);

  return (
    <React.Fragment>
      <SecurityServiceDetails
        {...otherProps}
        {...otherModel}
        {...otherActions}
        icon={icon}
        refresh={refresh}
        title={serviceId}
        routes={ROUTES}
        actions={serviceActions}
        showMfaFilterSwitch={showMfaFilterSwitch}
        onClickAction={onClickServiceAction}
        showAccessDenied={showAccessDenied}
        showNotFound={showNotFound}
      />
      <ServiceActionOperationDialog
        dialogClassName={actionDialogClassName}
        open={openActionOperationDialog}
        serviceId={serviceId}
        operation={actionOperation}
        onSuccessMessageShown={onActionOperationSuccess}
        cancel={closeActionOperationDialog}
      />
      <ServiceSecretOperationDialog
        dialogClassName={secretDialogClassName}
        open={openSecretOperationDialog}
        serviceId={serviceId}
        operation={secretOperation}
        onSuccessMessageShown={onSecretOperationSuccess}
        cancel={closeSecretOperationDialog}
      />
      {showEnableMfaDialog && (
        <EnableServiceMfaDialog
          className="serviceDetailsEnableMfaDialog"
          open={showEnableMfaDialog}
          cancel={closeEnableMfaDialog}
          onSuccessMessageShown={onMfaEnabledSuccess}
        />
      )}
      {showDisableMfaDialog && (
        <DisableServiceMfaDialog
          className="serviceDetailsDisableMfaDialog"
          authenticatorId={service.getMfaAuthenticatorId()}
          open={showDisableMfaDialog}
          cancel={closeDisableMfaDialog}
          onSuccessMessageShown={onMfaDisabledSuccess}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  currentServiceId: getCurrentServiceId(state),
  serviceId: getServiceId(state),
  actions: getActionMenuItems(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setServiceEnabled: (enabled: boolean) => dispatch(setServiceEnabled(enabled)),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceDetailsContainer);
