import React from "react";
import classnames from "classnames";
import { getStringValue, hasSpecialChars } from "@util";
import MuiTextField, { TextFieldProps } from "@material-ui/core/TextField";

interface TextFieldModel {
  disableSpecialChars?: boolean;
}

type Props = TextFieldProps & TextFieldModel;

export const TextField = ((props: Props) => {

  const {
    className,
    value,
    error,
    helperText,
    disableSpecialChars = false,
    ...otherProps
  } = props;

  const specialCharError = React.useMemo(() => {
    return disableSpecialChars && hasSpecialChars(getStringValue(value));
  }, [disableSpecialChars, value]);

  const specialCharText = "Text may only include letters, numbers, hyphens, periods and underscores.";

  return (
    <MuiTextField
      {...otherProps}
      value={value}
      error={error || specialCharError}
      helperText={specialCharError ? specialCharText : helperText}
      className={classnames("textField", className)}
    />
  );
});

export default TextField;
