import React from "react";
import classnames from "classnames";
import { noop } from "@util";
import { ContinueButton, SummaryJsonDetailsView, SummaryJsonDetailsViewModel } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { deviceEnrollment as styles, PrimaryIcon } from "./styles";
import { SummaryViewData } from "@data";

export interface Model extends SummaryJsonDetailsViewModel {
  disenrollDeviceButtonDisabled?: boolean;
  showReEnrollDeviceButton?: boolean;
  children?: React.ReactNode;
  items?: SummaryViewData[];
  deviceId?: string;
}

export interface Actions {
  deviceReEnrollment?: () => void;
  openDeviceEnrollmentWizard?: () => void;
  disenrollDevice?: () => void;
  reEnrollDevice?: () => void;
  refresh?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DeviceEnrollmentView = withStyles(styles)((props: Props) => {

  const {
    classes,
    disenrollDeviceButtonDisabled = false,
    showReEnrollDeviceButton = false,
    disenrollDevice = noop,
    reEnrollDevice = noop,
    openDeviceEnrollmentWizard = noop,
    deviceReEnrollment = noop,
    additionalViews,
    deviceId,
    showLoadingIndicator,
    showNotFound,
    json,
    refresh: refreshPage = noop,
    items,
    ...otherProps
  } = props;

  const reEnrollFailedEnrollment = React.useCallback(() => {
    openDeviceEnrollmentWizard();
    deviceReEnrollment();
  }, [openDeviceEnrollmentWizard, deviceReEnrollment]);

  const enrollmentButtonTitle = React.useMemo(() =>
      disenrollDeviceButtonDisabled ? (showReEnrollDeviceButton ? "Re-enroll Device" : "") : "Dis-enroll Device"
    , [showReEnrollDeviceButton, disenrollDeviceButtonDisabled]);

  const buttonAction = React.useCallback(() => {
      return (disenrollDeviceButtonDisabled ?
        (showReEnrollDeviceButton ? reEnrollFailedEnrollment() : noop()) : disenrollDevice());
    }
    , [disenrollDeviceButtonDisabled, showReEnrollDeviceButton, reEnrollFailedEnrollment, disenrollDevice]);

  const enrollmentButton = React.useMemo(() =>
    (
      <ContinueButton
        label={enrollmentButtonTitle}
        onClick={buttonAction}
      />
  ), [enrollmentButtonTitle, buttonAction]);

  return (
    <div className={classnames("deviceEnrollmentView", classes.container)}>
      <SummaryJsonDetailsView
        {...otherProps}
        icon={PrimaryIcon}
        title={deviceId}
        refresh={refreshPage}
        showLoadingIndicator={showLoadingIndicator}
        summaryViewItems={items}
        additionalViews={additionalViews}
        json={json}
        fileName={`${deviceId}.json`}
        jsonViewClassName={"deviceDetailsJsonView"}
        showNotFound={showNotFound}
        headerButtons={enrollmentButton}
      />
    </div>
  );
});

export default DeviceEnrollmentView;
