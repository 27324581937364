import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { DataSetAccessRequest, DataApprovalRequest } from "@data";
import classnames from "classnames";
import { useRevokeDataAccessByOwner } from "@hooks";
import { noop } from "@util";

export interface RevokeAccessDialogModel extends PortalModuleDialogModel<DataApprovalRequest> {
  dataAccessRequest: DataSetAccessRequest;
}

export interface RevokeAccessDialogActions extends PortalModuleDialogActions<DataApprovalRequest> {
}

type Props = WithStyles< typeof styles> &
  RevokeAccessDialogModel & RevokeAccessDialogActions;

export const RevokeAccessDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    dataAccessRequest = DataSetAccessRequest.EMPTY,
    onSuccessMessageShown = noop,
    cancel: close = noop,
    ...otherProps
  } = props;

  const title = "Revoke Data Access Request";

  const [model, actions] = useRevokeDataAccessByOwner({
    accountId: dataAccessRequest.getAccountId(),
    dataSetAlias: dataAccessRequest.getDataSetAlias(),
    accessPrincipalType: dataAccessRequest.getPrincipalType(),
    accessRequestType: dataAccessRequest.getRequestType(),
    principalIdentifier: dataAccessRequest.getPrincipalIdentifier(),
  });

  const [messageOnSuccess, setMessageOnSuccess] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const { showLoadingIndicator, successMessage, errorMessage: error, ...otherModel } = model;
  const { refresh: revokeAccess, ...otherActions } = actions;

  const clearMessages = React.useCallback(() => {
    setMessageOnSuccess("");
    setErrorMessage("");
  }, [setMessageOnSuccess, setErrorMessage]);

  const confirm = React.useCallback(() => {
    clearMessages();
    revokeAccess();
  }, [revokeAccess, clearMessages]);

  const cancel = React.useCallback(() => {
    clearMessages();
    close();
  }, [clearMessages, close]);

  const onRevokeAccessSuccess = React.useCallback(() => {
    clearMessages();
    onSuccessMessageShown();
  }, [clearMessages, onSuccessMessageShown]);

  React.useEffect(() => {
    setMessageOnSuccess(successMessage);
  }, [successMessage]);

  React.useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      {...otherActions}
      className={classnames("revokeAccessDialog")}
      title={title}
      loading={showLoadingIndicator}
      successMessage={messageOnSuccess}
      errorMessage={errorMessage}
      continueButtonLabel={"Revoke Data Access"}
      confirm={confirm}
      cancel={cancel}
      onSuccessMessageShown={onRevokeAccessSuccess}
    >
      <div className={classnames("revokeAccessDialogContainer", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Are you sure you want to revoke access?
          </label>
          <label className={classnames("subtitle", classes.subtitle)}>
            <span className={classes.warning}>WARNING:</span>This action cannot be undone!
          </label>
          <label className={classnames("principalIdentifier", classes.principalIdentifier)}>
            {dataAccessRequest.getPrincipalIdentifier()}
          </label>
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default RevokeAccessDialog;
