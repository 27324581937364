import React from "react";
import { useWorkloadManagerClient } from "@hooks";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  UseApiRequestActions,
} from "@hooks";
import { CreateWorkloadResponse } from "@network";

type SuccessResponse = CreateWorkloadResponse;

export interface UseDraftNewWorkloadVersionProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number | "latest";
}

export interface UseDraftNewWorkloadVersionModel extends UseApiRequestModel<SuccessResponse> {
  name: string;
  version: number;
  successMessage: string;
}

export interface UseDraftNewWorkloadVersionActions extends UseApiRequestActions<SuccessResponse> {
  draftNewVersion: () => void;
}

type Props = UseDraftNewWorkloadVersionProps;
type Model = UseDraftNewWorkloadVersionModel;
type Actions = UseDraftNewWorkloadVersionActions;
type Result = [Model, Actions];

export const useDraftNewWorkloadVersion = (props: Props): Result => {

  const {
    name,
    version = 0,
    defaultErrorMessage = "Failed to draft new workload version",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.draftNewWorkloadVersion(name, version),
    [WorkloadManagerClient, name, version]);

  const [ {
    showSuccessView,
    successResponse,
    ...baseModel
    }, {
    refresh: draftNewVersion,
    ...baseActions
    } ] = useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "New Version Created" : "",
    [ showSuccessView ]);

  const workloadVersion = React.useMemo<number>(() => {
    const { version: newWorkloadVersion = 2 } = successResponse || {};
    return newWorkloadVersion;
  }, [ successResponse ]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
    name,
    version: workloadVersion,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
    name,
    workloadVersion,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    draftNewVersion,
    refresh: draftNewVersion,
  }), [
    baseActions,
    draftNewVersion,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDraftNewWorkloadVersion;
