import { connect } from "react-redux";
import { SchemaValidationView } from "@components";
import { useSchemaValidation } from "@hooks";
import { AppSchema } from "@schemas";
import { DeviceEnrollmentWizardSelectors } from "../../selectors";
import { DeviceEnrollmentWizardActions } from "../../actions";
import React from "react";
import { isEmptyString, noop } from "@util";

interface ContainerModel {
  data?: string;
  namespace?: string;
  name?: string;
  version?: string;
  schemaJson?: string;
  showEmptyView?: boolean;
}

interface ContainerAction {
  updateData?: (data: string) => void;
  setDeviceDataValidated?: (value: boolean) => void;
  validateSchemaRequest?: () => void;
  validateSchemaSuccess?: () => void;
  validateSchemaFailed?: (analytic: string) => void;
}

type Props = ContainerModel & ContainerAction;

const DeviceDataValidationViewContainer = (props: Props) => {

  const {
    data,
    namespace = "",
    name = "",
    version = "",
    schemaJson,
    showEmptyView = false,
    updateData =  noop,
    setDeviceDataValidated = noop,
    validateSchemaRequest: trackRequestEvent = noop,
    validateSchemaSuccess: trackSuccessEvent = noop,
    validateSchemaFailed: trackErrorEvent = noop,
  } = props;

  const [model, actions] = useSchemaValidation({
    data,
    namespace,
    name,
    version,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
  });

  const { errorMessage } = model;

  const { setData } =  actions;

  const updateDeviceData = React.useCallback((updatedData: string) => {
    setData(updatedData);
    updateData(updatedData);
  }, [setData, updateData]);

  React.useEffect(() => {
    setDeviceDataValidated(isEmptyString(errorMessage));
  }, [setDeviceDataValidated, errorMessage]);

  return (
    <SchemaValidationView
      {...model}
      {...actions}
      data={data}
      schema={schemaJson}
      showEmptyView={showEmptyView}
      setData={updateDeviceData}
    />
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
  data: DeviceEnrollmentWizardSelectors.getDeviceData(state),
  schemaJson: DeviceEnrollmentWizardSelectors.getSchemaJson(state),
  namespace: DeviceEnrollmentWizardSelectors.getSelectedSchemaNamespace(state),
  name: DeviceEnrollmentWizardSelectors.getSelectedSchemaName(state),
  version: DeviceEnrollmentWizardSelectors.getSelectedSchemaVersion(state),
  showEmptyView: DeviceEnrollmentWizardSelectors.isLoadingIndicatorVisible(state),
});

const mapDispatchToProps = (dispatch: any): ContainerAction => ({
  updateData: (data: string) =>
    dispatch(DeviceEnrollmentWizardActions.setDeviceData(data)),
  setDeviceDataValidated: (value: boolean) =>
    dispatch(DeviceEnrollmentWizardActions.setDeviceDataValidated(value)),
  validateSchemaRequest: () =>
    dispatch(DeviceEnrollmentWizardActions.validateSchemaRequest()),
  validateSchemaSuccess: () =>
    dispatch(DeviceEnrollmentWizardActions.validateSchemaSuccess()),
  validateSchemaFailed: (analytic: string) =>
    dispatch(DeviceEnrollmentWizardActions.validateSchemaFailed(analytic)),
});

export default connect<ContainerModel, ContainerAction, ContainerModel & ContainerAction>(
  mapStateToProps,
  mapDispatchToProps
)(DeviceDataValidationViewContainer);
