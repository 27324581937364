import { createStyles, StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const dialog: StyleRules = {
  paper: {
    padding: 0,
    overflow: "hidden",
    height: "100%",
  },
};

export const dialogTitle: StyleRules = {
  root: {
    background: Color.SIGNIFY_GREEN,
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    color: Color.BLACK,
    fontSize: 24,
    fontWeight: 400,
    lineHeight: "28px",
    margin: 0,
    padding: 16,
  },
};

export const dialogContent: StyleRules = {
  root: {
    padding: "32px 16px 16px",
    margin: 0,
  },
};

export const dialogActions: StyleRules = {
  root: {
    minHeight: 72,
    padding: "0 16px",
    margin: "16px 0 0",
    backgroundColor: Color.LIGHT_GREY5,
  },
};

export const errorView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      padding: 20,
      borderRadius: 3,
      border: `1px solid ${Color.RED}`,
      backgroundColor: Color.LIGHT_RED,
      marginBottom: 16,
    },
    icon: {
      width: 48,
      height: 48,
      color: Color.RED,
    },
    title: {
      color: Color.DARK_RED,
      fontSize: 24,
      fontWeight: 400,
      lineHeight: "28px",
      marginTop: 8,
    },
    message: {
      color: Color.DARK_RED,
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "16px",
      marginTop: 4,
    },
  });

export const styles = () =>
  createStyles({
    content: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      alignContent: "center",
      justifyContent: "center",
      backgroundColor: Color.WHITE,
      marginLeft: 20,
      marginRight: 20,
      marginTop: 25,
      marginBottom: 25,
    },
    contentLabel: {
      color: Color.GREY6,
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "21px",
      fontFamily: "Roboto, sans-serif",
    },
    button: {
      height: 36,
    },
    cancelButton: {
      minWidth: "auto",
      padding: "6px 8px",
    },
  });
