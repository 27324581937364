import React from "react";
import classnames from "classnames";
import Fab from "@components/fab";
import { clickHandler, noop } from "@util";
import Tooltip from "@material-ui/core/Tooltip";
import CopyIcon from "@material-ui/icons/FileCopy";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export const CopyButton = withStyles(styles)((props: WithStyles<typeof styles> & {
  showTooltip?: boolean,
  onShowTooltip?: () => void,
  onHideTooltip?: () => void,
  tooltip?: string,
  tooltipLeaveDelay?: number,
  copy?: () => void,
}) => {

  const {
    classes,
    showTooltip,
    onShowTooltip,
    onHideTooltip,
    tooltip = "",
    tooltipLeaveDelay,
    copy = noop,
  } = props;

  return (
    <Tooltip
      title={tooltip}
      leaveDelay={tooltipLeaveDelay}
      open={showTooltip}
      onOpen={onShowTooltip}
      onClose={onHideTooltip}
      disableFocusListener={true}
    >
      <Fab
        className={classnames("copyButton", classes.copyButton)}
        color="default"
        size="small"
        onClick={clickHandler(copy)}
      >
        <CopyIcon
          className={classnames("copyIcon", classes.copyIcon)}
          aria-label="copy-icon"
          color="inherit"
        />
      </Fab>
    </Tooltip>
  );
});

export default CopyButton;
