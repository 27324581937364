import { Record } from "immutable";
import { GetEmailLanguagesResponse } from "@network";

export class EmailTemplateLang extends Record({
    languages: ["en-US", "es-ES", "fr-FR", "de-DE", "zh-TW"],
}) implements GetEmailLanguagesResponse {

    public static EMPTY: EmailTemplateLang = new EmailTemplateLang();

    public readonly languages: string[];

}

export default EmailTemplateLang;