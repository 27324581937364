import React from "react";
import classnames from "classnames";
import { ReleaseNotePlatformDomain } from "@data";
import { welcomePageTitle as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import ReleaseNotesIconButton from "@components/release-notes-icon-button";

type Props = WithStyles<typeof styles>;

export const WelcomePageTitle = withStyles(styles)((props: Props) => {

  const { classes } = props;

  return (
    <React.Fragment>
      IoT Platform Management Console
      <ReleaseNotesIconButton
        className={classnames("portalReleaseNotesButton", classes.container)}
        platformDomain={ReleaseNotePlatformDomain.SELF_SERVICE_TOOLS}
        tooltip="Self-Service Tools Release Notes"
      />
    </React.Fragment>
  );
});

export default WelcomePageTitle;
