import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles";
import { Color } from "../styles";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      padding: 8,
      margin: "16px 8px 8px 8px",
      borderRadius: 3,
      border: `1px solid ${Color.BLACK}`,
      backgroundColor: Color.SIGNIFY_GREEN,
      textAlign: "center",
    },
    icon: {
      width: 28,
      height: 28,
      color: Color.BLACK,
    },
    title: {
      color: Color.BLACK,
      fontFamily: "Roboto, sans-serif",
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
      marginTop: 8,
    },
    message: {
      color: Color.BLACK,
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "16px",
      marginTop: 4,
    },
  });

export default styles;
