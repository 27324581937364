import React from "react";
import { AddTriggerResponse } from "@network";
import { AddTriggerRequest, Trigger, TriggerType } from "@data";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useWorkloadManagerClient } from "@hooks";

type SuccessResponse = AddTriggerResponse;

export interface UseAddTriggerProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  data?: AddTriggerRequest;
}

export interface UseAddTriggerModel extends UseApiRequestModel<SuccessResponse> {
  triggerId: string;
  successMessage: string;
}

export interface UseAddTriggerActions {
  addTrigger: () => void;
}

type Props = UseAddTriggerProps;
type Model = UseAddTriggerModel;
type Actions = UseAddTriggerActions;
type Result = [Model, Actions];

export const useAddTrigger = (props: Props): Result => {

  const {
    name,
    version,
    data = AddTriggerRequest.EMPTY,
    defaultErrorMessage = "Failed to add trigger",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const triggerInfo = data.toJS();
  const eventType = data.getType();

  delete triggerInfo.rateValue;
  delete triggerInfo.rateUnit;
  delete triggerInfo.scheduleType;
  delete triggerInfo.eventBindingId;

  if (eventType !== TriggerType.SCHEDULE) {
    delete triggerInfo.scheduleExpression;
  }

  if (eventType !== TriggerType.DATA_LAKE && eventType !== TriggerType.WORKLOAD_COMPLETE) {
    delete triggerInfo.accountId;
  }

  if (eventType !== TriggerType.DATA_LAKE) {
    delete triggerInfo.dataSetAlias;
  }

  if (eventType !== TriggerType.WORKLOAD_COMPLETE) {
    delete triggerInfo.precedingWorkloadName;
    delete triggerInfo.completionState;
  }

  const makeApiRequest = React.useCallback(() =>
      WorkloadManagerClient.addWorkloadTriggerApi(name, version, JSON.stringify({
        ...triggerInfo,
        "@type": eventType,
        eventType,
      })), [WorkloadManagerClient, name, version, triggerInfo]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: addTrigger }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
    showSuccessView ? "Trigger Added" : "", [showSuccessView]);

  const triggerId = React.useMemo<string>(() => {
    const attrs = successResponse ? successResponse : {};
    return new Trigger(attrs).getId();
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
    triggerId,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
    triggerId,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    addTrigger,
  }), [
    addTrigger,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useAddTrigger;
