import { createStyles, StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const breadcrumb: StyleRules = {
  root: {
    height: 36,
    margin: 0,
    padding: 0,
    minWidth: 0,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:active": {
      backgroundColor: "transparent",
    },
  },
  label: {
    backgroundColor: "transparent",
    color: Color.TEXT,
    fontFamily: "Roboto, sans-serif",
    fontWeight: 300,
    fontSize: 21,
    lineHeight: "36px",
    textDecoration: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
    "&:active": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
};

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    actionsMenu: {
      padding: 0,
      marginLeft: 4,
    },
    actionsMenuIconButton: {
      padding: 6,
    },
    breadcrumbsContainer: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "center",
    },
    breadcrumbDivider: {
      marginLeft: 8,
      marginRight: 8,
    },
    breadcrumb: {

    },
    active: {

    },
  });

export default styles;
