import { AppSchema } from "@main/schemas";
import { createActions } from "@base/createActions";
import { getAuthToken } from "@main/selectors";
import { DeviceEnrollmentClient, RestClientError } from "@network";
import {
  DeviceEnrollmentStatus,
  DeviceEnrollmentStatusAttributes
} from "@data";
import { getBatchId, getDeviceEnrollmentsMap } from "./selectors";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { isEmptyString } from "@util";
import { OrderedMap } from "immutable";

export const {
  deviceEnrollments: setDeviceEnrollmentsAttributes,
  batchId: setBatchId,
  setErrorMessage,
  setSuccessMessage,
  showLoadingIndicator,
  hideLoadingIndicator,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  FETCH_DEVICE_ENROLLMENTS_REQUEST: fetchDeviceEnrollmentsRequest,
  FETCH_DEVICE_ENROLLMENTS_SUCCESS: fetchDeviceEnrollmentsSuccess,
  FETCH_DEVICE_ENROLLMENTS_FAILED: fetchDeviceEnrollmentsFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setDeviceEnrollmentsAttributes());
  dispatch(setBatchId());
  return dispatch(baseReset());
};

export const clearDeviceEnrollments = () => setDeviceEnrollmentsAttributes();

const updateItems =
  (items: OrderedMap<string, DeviceEnrollmentStatusAttributes> =
     OrderedMap<string, DeviceEnrollmentStatusAttributes>(DEFAULT_STATE.deviceEnrollments)) =>
    (dispatch: any, getState: () => AppSchema) => dispatch(setDeviceEnrollmentsAttributes(
      getDeviceEnrollmentsMap(getState()).merge(
        OrderedMap<string, DeviceEnrollmentStatusAttributes>(items)
          .map((attrs: DeviceEnrollmentStatusAttributes) => new DeviceEnrollmentStatus(attrs))
          .toOrderedMap())
        .map((item: DeviceEnrollmentStatus) => item.toJS())
        .toOrderedMap()
        .toJS()));

export const addDeviceEnrollments = (items: DeviceEnrollmentStatusAttributes[] = []) => (dispatch: any) => {

  const deviceEnrollments = items.reduce((data, attrs) => {
    const item = new DeviceEnrollmentStatus(attrs);

    if (!DeviceEnrollmentStatus.EMPTY.equals(item)) {
      return data.set(item.getBatchId(), item.toJS());
    }

    return data;
  }, OrderedMap<string, DeviceEnrollmentStatusAttributes>({}));

  return dispatch(updateItems(deviceEnrollments));
};

export const updateDeviceEnrollments =
  (attrs: DeviceEnrollmentStatusAttributes = DeviceEnrollmentStatus.EMPTY.toJS()) =>
  (dispatch: any) => {

    const item = new DeviceEnrollmentStatus(attrs);

    if (DeviceEnrollmentStatus.EMPTY.equals(item)) {
      return Promise.resolve();
    }

    return dispatch(updateItems(OrderedMap<string, DeviceEnrollmentStatusAttributes>({
      [item.getBatchId()]: item.toJS(),
    })));
  };

export const fetchDeviceEnrollmentStatus = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const batchId = getBatchId(state);
  const authToken = getAuthToken(state);

  if (isEmptyString(batchId)) {
    return dispatch(hideEmptyView());
  }

  dispatch(showLoadingIndicator());
  dispatch(setErrorMessage());
  dispatch(hideAccessDenied());
  dispatch(fetchDeviceEnrollmentsRequest());

  return DeviceEnrollmentClient.getBatchEnrollmentStatus(authToken, batchId)
    .then((attrs: DeviceEnrollmentStatusAttributes) => {

      // Ignore results if batchId changed while request was in progress
      if (batchId !== getBatchId(getState())) {
        return Promise.resolve();
      }

      dispatch(fetchDeviceEnrollmentsSuccess());
      dispatch(addDeviceEnrollments([new DeviceEnrollmentStatus(attrs).toJS()]));
      dispatch(hideLoadingIndicator());
      return dispatch(hideEmptyView());

    }, (response: RestClientError) => {

      // Ignore results if batchId changed while request was in progress
      if (batchId !== getBatchId(getState())) {
        return Promise.resolve();
      }

      const {analytic, error = "Fetch Device Enrollments failed"} = response;

      dispatch(fetchDeviceEnrollmentsFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(hideLoadingIndicator());

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideEmptyView());
    });
};

export const refresh = () => (dispatch: any) => {

  dispatch(clearDeviceEnrollments());
  return dispatch(fetchDeviceEnrollmentStatus());
};

export const initialize = () => (dispatch: any) => {

  dispatch(reset());
  return dispatch(fetchDeviceEnrollmentStatus());
};
