import React from "react";
import { isDataWorkloadsOneDotThreeFeaturesEnabled, isEmptyString, noop } from "@util";
import {
  AccountIdContext,
  AppliedFilters,
  createColumns,
  DataWorkloadFilters,
  ListViewItem,
  ModuleListView,
  ModuleListViewModel,
  WorkloadsFiltersMenu,
} from "@components";
import { AddTriggerRequest, SearchFilter, Workload } from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import { useGetWorkloads } from "@hooks";
import { PrimaryIcon } from "@modules/dataWorkloads/styles";
import { getPathToWorkload } from "@modules/dataWorkloadDetails/helpers";
import classnames from "classnames";

export interface WorkloadsListModel extends ModuleListViewModel<Workload, WorkloadListColumns> {
  currentWorkload?: string;
  selectable?: boolean;
}

export interface WorkloadsListActions {
  createWorkload?: () => void;
  showWorkloadDetails?: (name: string) => void;
  setTriggerData?: (trigger: AddTriggerRequest) => void;
  setActiveStep?: (step: number) => void;
}

type Props = WithStyles<typeof styles> & WorkloadsListModel & WorkloadsListActions;

export enum WorkloadListColumns {
  NAME = "Name",
  VERSION = "Version",
  RELEASED_BY = "Released By",
  TYPE = "Type",
}

export const DEFAULT_WORKLOAD_LIST_COLUMNS: WorkloadListColumns[] = [
  WorkloadListColumns.NAME,
  WorkloadListColumns.TYPE,
];

export const DEFAULT_RELEASED_WORKLOAD_LIST_COLUMNS: WorkloadListColumns[] = [
  WorkloadListColumns.NAME,
  WorkloadListColumns.VERSION,
  WorkloadListColumns.RELEASED_BY,
];

const mapSearchFilterToLabel = (searchFilter: SearchFilter) => {
  switch (searchFilter.getKey()) {
    case DataWorkloadFilters.RELEASED_FILTER:
      return "State: Released";
    case DataWorkloadFilters.PRINCIPAL_FILTER:
      return `Principal: ${searchFilter.getValue()}`;
    default:
      return "No Filters";
  }
};

export const WorkloadsList = withStyles(styles)((props: Props) => {

  const {
    classes,
    summaryViewLabel = "Workloads",
    currentWorkload= "",
    selectable = false,
    showWorkloadDetails = noop,
    createWorkload,
    setTriggerData = noop,
    setActiveStep = noop,
    ...otherProps
  } = props;

  const [selectedWorkload, setSelectedWorkload] = React.useState<Workload[]>([]);

  const updateSelectedWorkload = React.useCallback((workload: Workload[]) => {
    setSelectedWorkload(workload);
    setTriggerData({ precedingWorkloadName: workload.length > 0 ? workload[0].getName() : "" });
    setActiveStep(workload.length);
  }, [setSelectedWorkload, setTriggerData, setActiveStep]);

  const accountId = React.useContext(AccountIdContext);

  React.useEffect(() => {
    setTriggerData({ accountId: accountId});
  }, [accountId]);

  const [nameFilter, setNameFilter] = React.useState("");
  const [showFiltersMenu, setShowFiltersMenu] = React.useState(false);
  const [filterByReleasedState, setFilterByReleasedState] = React.useState(false);
  const [releasedByNameFilter, setReleasedByNameFilter] = React.useState<string>("");

  const setFilterByReleased = React.useCallback((value: boolean) => {
    if (!value) {
      setReleasedByNameFilter("");
    }
    setFilterByReleasedState(value);
  }, [setReleasedByNameFilter, setFilterByReleasedState]);

  const setReleasedByName = React.useCallback((value: string) => {
    setReleasedByNameFilter(value);
    setFilterByReleasedState(true);
  }, [setReleasedByNameFilter, setFilterByReleasedState]);

  const showType = isDataWorkloadsOneDotThreeFeaturesEnabled();

  const columns = React.useMemo(() => {
    return filterByReleasedState || !isEmptyString(releasedByNameFilter) ?
      DEFAULT_RELEASED_WORKLOAD_LIST_COLUMNS : showType ? DEFAULT_WORKLOAD_LIST_COLUMNS : [WorkloadListColumns.NAME];
  }, [filterByReleasedState, releasedByNameFilter, showType]);

  const [{ workloads, showLoadingIndicator, ...otherModel }, { refresh: refreshList, ...otherActions}] =
    useGetWorkloads({ nameFilter, filterByReleasedState, releasedByNameFilter });

  const moduleListItems: ListViewItem<Workload>[] = React.useMemo(() => {
    const items = selectable ? selectedWorkload.length > 0 ? selectedWorkload : workloads : workloads;
    return items.reduce((list: ListViewItem<Workload>[], workload) => {
      if (!selectable || workload.getName() !== currentWorkload) {
        list.push({
          item: workload,
          icon: PrimaryIcon,
          pathToDetailsView: getPathToWorkload(workload.getName()),
          columnAttributes: createColumns([
            {
              className: "workloadName",
              value: workload.getName(),
              column: WorkloadListColumns.NAME,
              firstColumn: true,
            },
            {
              className: "workloadType",
              value: workload.getTypeLabel(),
              column: WorkloadListColumns.TYPE,
            },
            {
              className: "workloadVersion",
              value: workload.getVersion(),
              column: WorkloadListColumns.VERSION,
            },
            {
              className: "workloadReleasedBy",
              value: workload.getReleasedBy(),
              column: WorkloadListColumns.RELEASED_BY,
            },
          ])
        });
      }
      return list;
    }, []);
  }, [selectable, selectedWorkload, workloads, currentWorkload]);

  const searchFilters = React.useMemo<SearchFilter[]>(() => ([] as SearchFilter[])
    .concat(filterByReleasedState ? [new SearchFilter({
      key: DataWorkloadFilters.RELEASED_FILTER
    })] : [])
    .concat(!isEmptyString(releasedByNameFilter)
      ? ([new SearchFilter({
        key: DataWorkloadFilters.PRINCIPAL_FILTER,
        value: releasedByNameFilter,
      })]) : []), [
    filterByReleasedState,
    releasedByNameFilter
  ]);

  const badgeCount = React.useMemo(() => searchFilters.length, [searchFilters]);

  const openMenu = React.useCallback(() => setShowFiltersMenu(true), [setShowFiltersMenu]);

  const closeMenu = React.useCallback(() => setShowFiltersMenu(false), [setShowFiltersMenu]);

  const setSearchFilters = React.useCallback((updatedSearchFilters: SearchFilter[]) =>
    searchFilters
      .filter(it => !updatedSearchFilters.some(it2 => it.equals(it2)))
      .map(it => it.getKey())
      .forEach(type => {
        switch (type) {
          case DataWorkloadFilters.RELEASED_FILTER:
            setFilterByReleasedState(false);
            break;
          case DataWorkloadFilters.PRINCIPAL_FILTER:
            setReleasedByNameFilter("");
            break;
          default:
            break;
        }
      }), [
    searchFilters,
    setFilterByReleasedState,
    setReleasedByNameFilter,
  ]);

  const clearFilters = React.useCallback(() => {
    setFilterByReleasedState(false);
    setReleasedByNameFilter("");
  }, [
    setFilterByReleasedState,
    setReleasedByNameFilter,
  ]);

  return (
    <ModuleListView
      {...otherProps}
      {...otherModel}
      {...otherActions}
      summaryViewIcon={PrimaryIcon}
      noResultsLabel="No workloads found"
      nameFilter={nameFilter}
      listClassName="workloadsList"
      columns={columns}
      listViewItems={moduleListItems}
      summaryViewLabel={summaryViewLabel}
      showSummary={true}
      showSearch={true}
      createButtonClassName="createWorkloadButton"
      createButtonLabel="Create Workload"
      refresh={refreshList}
      onClickItem={React.useCallback((item: Workload) =>
        showWorkloadDetails(item.getName()), [showWorkloadDetails])}
      onClickCreateButton={createWorkload}
      setNameFilter={setNameFilter}
      showLoadingIndicator={showLoadingIndicator}
      summaryViewFiltersMenu={(
        <WorkloadsFiltersMenu
          {...otherProps}
          className={classnames("filtersMenu", classes.filtersMenu)}
          badgeCount={badgeCount}
          disabled={showLoadingIndicator}
          clearButtonDisabled={badgeCount === 0}
          filterByReleasedState={filterByReleasedState}
          releasedByNameFilter={releasedByNameFilter}
          open={showFiltersMenu}
          openMenu={openMenu}
          closeMenu={closeMenu}
          clearFilters={clearFilters}
          setFilterByReleasedState={setFilterByReleased}
          setReleasedByNameFilter={setReleasedByName}
        />
      )}
      header={(
        <AppliedFilters
          className={classnames("appliedFilters", classes.appliedFilters)}
          disabled={showLoadingIndicator}
          searchFilters={searchFilters}
          mapSearchFilterToLabel={mapSearchFilterToLabel}
          setSearchFilters={setSearchFilters}
          onClickSearchFilter={openMenu}
          clearFilters={clearFilters}
        />
      )}
      selectable={selectable}
      selectedItems={selectable ? selectedWorkload : []}
      setSelectedItems={selectable ? updateSelectedWorkload : noop}
    />
  );
});

export default WorkloadsList;
