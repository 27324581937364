import React from "react";
import classnames from "classnames";
import { SignifyIcon } from "@icons";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ListItemType, NavigationListItem } from "./NavigationListItem";
import styles from "./styles";
import { equalsIgnoreCase } from "@util";

export const TitleListItem = withStyles(styles)((props: WithStyles<typeof styles> & {
  name?: string,
  path?: string,
  exactPath?: boolean,
  strictPath?: boolean,
}) => {

  const {
    classes,
    name = "IoT Platform",
    path = "/",
    exactPath = true,
    strictPath = true,
  } = props;

  return (
    <NavigationListItem
      className={classnames("titleListItem", classes.titleListItem)}
      id="title"
      icon={SignifyIcon}
      name={name}
      outlined={true}
      clickable={true}
      type={ListItemType.TITLE}
      path={path}
      exactPath={exactPath}
      strictPath={strictPath}
      isActive={(match, { pathname = "" }) => equalsIgnoreCase(path, pathname)}
    />
  );
});

export default TitleListItem;
