import { isEmptyString } from "@util";
import { DeviceEnrollmentAttributes, DeviceEnrollmentStatusAttributes, DeviceAttributes } from "@data";
import {
  createQueryParams,
  makeApiRequest,
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments,
  DeviceEnrollmentLocationHeaders,
  getDeviceIdFromHeader,
} from "@network/helpers";

const REGIONAL_API = process.env.REACT_APP_REGIONAL_API || "";

if (isEmptyString(REGIONAL_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_REGIONAL_API");
}

export interface GetPreSignedUrlResponse {
  url: string;
}

export interface GetBatchEnrollmentDetailsResponse {
  devices: DeviceEnrollmentAttributes[];
  paging?: {
    next?: string | null;
  };
}

export interface GetDeviceHistoryResponse {
  history: DeviceAttributes[];
  paging?: {
    next?: string | null;
  };
}

export const getPreSignedUrl = (authToken: string,
                                json: string): Promise<GetPreSignedUrlResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Batch ID", json]
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/enrollment/v1/devices/batches`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to get pre signed URL";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const batchEnrollDevices = (url: string,
                                   encodedBody: string | File | null): Promise<void> => {

  const validate = () => withRequiredArguments([
    ["PreSigned URL", url],
  ]);

  const makeRequest = () => {

    const settings = {
      method: "PUT",
      headers: {
        "Content-Type": "application/octet-stream",
      },
      body: encodedBody,
    };

    const defaultErrorMessage = `Failed to enroll devices`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getBatchEnrollmentStatus = (authToken: string,
                                         batchId: string): Promise<DeviceEnrollmentStatusAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Batch ID", batchId]
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/enrollment/v1/devices/batches/${batchId}/status`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch device enrollment status failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getBatchEnrollmentDetails = (authToken: string,
                                          batchId: string,
                                          next?: string): Promise<GetBatchEnrollmentDetailsResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Batch ID", batchId]
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({
      next,
      verbose: true,
    });

    const url = `${REGIONAL_API}/device-management/enrollment/v1/devices/batches/${batchId}/status/details${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch device enrollment details failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const enrollDevice = (authToken: string,
                             json: string): Promise<DeviceEnrollmentLocationHeaders> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Device Data", json],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/enrollment/v1/devices`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to enroll device";

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getDeviceIdFromHeader);
  };

  return validate().then(makeRequest);
};

export const validateDeviceEnrollment = (authToken: string,
                                         json: string): Promise<DeviceEnrollmentLocationHeaders> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Device Data", json],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/enrollment/v1/devices/validate`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to validate device payload";

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getDeviceIdFromHeader);
  };

  return validate().then(makeRequest);
};

export const getDeviceStatus = (authToken: string,
                                deviceId: string): Promise<DeviceAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Device ID", deviceId]
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/enrollment/v1/devices/${deviceId}/status`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch device status failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getDeviceHistoryDetails = (authToken: string,
                                        deviceId: string,
                                        next?: string): Promise<GetDeviceHistoryResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Device ID", deviceId]
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({next});

    const url = `${REGIONAL_API}/device-management/enrollment/v1/devices/${deviceId}/status/history${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch device history details failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const disenrollDevice = (authToken: string,
                                    deviceId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Device Id", deviceId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/enrollment/v1/devices/${deviceId}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to disenroll device [${deviceId}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
