import React from "react";
import { noop } from "@util";
import Policy from "@data/Policy";
import { useCurrentAccountId } from "@hooks";
import WorkloadPolicyEditorDialog, {
  WorkloadPolicyEditorDialogActions,
  WorkloadPolicyEditorDialogModel,
} from "@components/workload-policy-editor-dialog";
import useEditWorkloadPolicy from "@hooks/workloadsManager/use-edit-workload-policy";

interface EditWorkloadPolicyDialogModel extends Omit<WorkloadPolicyEditorDialogModel, "accountId"> {
  workloadName: string;
  workloadVersion: number;
  workloadEtag: string;
  policy?: Policy;
}

interface EditWorkloadPolicyDialogActions extends WorkloadPolicyEditorDialogActions {
  onSuccess?: () => void;
}

type Model = EditWorkloadPolicyDialogModel;
type Actions = EditWorkloadPolicyDialogActions;
type Props = Model & Actions;

export const EditWorkloadPolicyDialog = (props: Props) => {

  const {
    open,
    workloadName: name,
    workloadVersion: version,
    workloadEtag: etag,
    policy: initialPolicy = Policy.EMPTY,
    onSuccess = noop,
    onSuccessMessageShown = onSuccess,
    ...otherProps
  } = props;

  const accountId = useCurrentAccountId();

  const [pendingPolicy, setPendingPolicy] = React.useState(new Policy({ ...initialPolicy.toJS() }));

  const [resetDialogState, setResetDialogState] = React.useState(false);

  const [model, actions] = useEditWorkloadPolicy({ name, version, etag, policy: pendingPolicy });

  const { showLoadingIndicator } = model;

  const { reset, editWorkloadPolicy } = actions;

  const continueButtonDisabled = React.useMemo(() =>
      !pendingPolicy.isValid() || initialPolicy.equals(pendingPolicy),
    [pendingPolicy, initialPolicy]);

  // Reset hook state whenever the pending policy changes, which will also clear any
  // error messages shown in the dialog once the user makes some updates to the policy
  React.useEffect(() => {
    reset();
  }, [pendingPolicy, reset]);

  // Reset this component's state and child state if the dialog is closed and re-opened,
  // or if the initial policy provided by the parent changes
  React.useEffect(() => {
    setPendingPolicy(new Policy({ ...initialPolicy.toJS() }));
    setResetDialogState(true);
  }, [open, initialPolicy, reset, setPendingPolicy, setResetDialogState]);

  // Causes a new instance of the dialog to be rendered, which resets dialog's internal state
  React.useEffect(() => {
    if (resetDialogState) {
      setResetDialogState(false);
    }
  }, [resetDialogState, setResetDialogState]);

  if (resetDialogState) {
    return null;
  }

  return (
    <WorkloadPolicyEditorDialog
      {...otherProps}
      {...model}
      {...actions}
      className="editWorkloadPolicyDialog"
      open={open}
      accountId={accountId}
      workloadName={name}
      policy={pendingPolicy}
      loading={showLoadingIndicator}
      continueButtonDisabled={continueButtonDisabled}
      setPolicy={setPendingPolicy}
      onSuccessMessageShown={onSuccessMessageShown}
      save={editWorkloadPolicy}
    />
  );
};

export default EditWorkloadPolicyDialog;
