import React from "react";
import { noop } from "@util";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { setUpdateMfaDialog } from "../actions";
import {
  isUpdateMfaDialogOpen,
} from "../selectors";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import UpdateMfaRequirement from "../components/Authentication/UpdateMfaRequirement";
import { AuthenticatorRequirement } from "@data";

interface Model extends PortalModuleDialogModel<string> {
  userId?: string;
  mfaStatus?: AuthenticatorRequirement;
}

interface Actions extends PortalModuleDialogActions<string> {
  updateAuthenticationRequirement?: () => void;
  onUpdateSuccess?: () => void;
}

const UpdateMfaDialog = (props: Model & Actions) => {

  const {
    userId,
    mfaStatus,
    updateAuthenticationRequirement = noop,
    onUpdateSuccess = noop,
    ...otherProps
  } = props;

  return (
    <PortalModuleDialog
      {...otherProps}
      onSuccessMessageShown={onUpdateSuccess}
      confirm={updateAuthenticationRequirement}
    >
      <UpdateMfaRequirement userId={userId} mfaStatus={mfaStatus}/>
    </PortalModuleDialog>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  className: "changeMfaRequirementDialog",
  title: "Update MFA requirement",
  open: isUpdateMfaDialogOpen(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  cancel: () => dispatch(setUpdateMfaDialog(false)),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateMfaDialog);
