import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { DeviceTypeWizardActions } from "../actions";
import { DeviceTypeWizardSelectors } from "../selectors";
import JsonEditorView, { Model, Actions } from "../components/JsonEditorView";
import { noop } from "@util";

interface ContainerModel extends Model {
  historicalJson?: string;
  isRegionalApiModel?: boolean;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const JsonEditorViewContainer = (props: Props) => {

  const {
    historicalJson,
    isRegionalApiModel,
    json,
    setJson = noop,
    ...otherProps
  } = props;

  const [showHistoricalDataView, setShowHistoricalDataView] = React.useState(!isRegionalApiModel);

  const visibleJson = React.useMemo(() =>
      isRegionalApiModel && showHistoricalDataView ? historicalJson : json,
    [isRegionalApiModel, showHistoricalDataView, historicalJson, json]);

  const updateJson = React.useCallback(updatedJson => {
    if (!isRegionalApiModel || !showHistoricalDataView) {
      setJson(updatedJson);
    }
  }, [isRegionalApiModel, showHistoricalDataView, setJson]);

  return (
    <JsonEditorView
      {...otherProps}
      json={visibleJson}
      readOnly={isRegionalApiModel && showHistoricalDataView}
      isToggleApiSwitchVisible={isRegionalApiModel}
      isHistoricalDataViewSelected={showHistoricalDataView}
      setShowHistoricalDataView={setShowHistoricalDataView}
      setJson={updateJson}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  json: DeviceTypeWizardSelectors.getJson(state),
  historicalJson: DeviceTypeWizardSelectors.getHistoricalJson(state),
  isRegionalApiModel: DeviceTypeWizardSelectors.isRegionalApiSelected(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setJson: (json: string) => dispatch(DeviceTypeWizardActions.updateJson(json)),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(JsonEditorViewContainer);
