import { connect } from "react-redux";
import { AppSchema } from "../../main/schemas";
import { PropertyEditorSelectors } from "../selectors/propertyEditor";
import { StringConstraintsActions } from "../actions/stringConstraints";
import { StringConstraintsSelectors } from "../selectors/stringConstraints";
import { StringConstraintsEditor, Model, Actions } from "../components/ConstraintsEditor/StringConstraintsEditor";
import { PropertyEditorActions } from "@modules/schemaWizard/actions/propertyEditor";

const mapStateToProps = (state: AppSchema): Model => ({
  minLength: StringConstraintsSelectors.getMinLength(state),
  minLengthError: StringConstraintsSelectors.getMinLengthError(state),
  maxLength: StringConstraintsSelectors.getMaxLength(state),
  maxLengthError: StringConstraintsSelectors.getMaxLengthError(state),
  pattern: StringConstraintsSelectors.getPattern(state),
  patternError: StringConstraintsSelectors.getPatternError(state),
  disabled: PropertyEditorSelectors.isProgressIndicatorVisible(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  setMinLength: (value: string) => dispatch(StringConstraintsActions.updateMinLength(value)),
  setMaxLength: (value: string) => dispatch(StringConstraintsActions.updateMaxLength(value)),
  setPattern: (value: string) => dispatch(StringConstraintsActions.updatePattern(value)),
  save: () => dispatch(PropertyEditorActions.save()),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(StringConstraintsEditor);
