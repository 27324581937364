import { createActions } from "@base/createActions";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import {
  reloadItems as refreshDataSetRequestsModule,
} from "@modules/dataSetRequests/actions";
import {
  reloadItems as refreshDataAccessRequestsModule,
} from "@modules/dataAccessRequests/actions";
import {
  reloadItems as refreshDataApprovalRequestsModule,
} from "@modules/dataApprovalRequests/actions";

export const {
  setErrorMessage,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  UPLOAD_TERMS_AND_CONDITIONS_REQUEST: trackUploadTermsAndConditionsRequest,
  UPLOAD_TERMS_AND_CONDITIONS_SUCCESS: trackUploadTermsAndConditionsSuccess,
  UPLOAD_TERMS_AND_CONDITIONS_FAILED: trackUploadTermsAndConditionsFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  return dispatch(baseReset());
};

export const refresh = () => (dispatch: any) => {
  dispatch(refreshDataApprovalRequestsModule());
  dispatch(refreshDataAccessRequestsModule());
  return dispatch(refreshDataSetRequestsModule());
};
