import React from "react";
import classnames from "classnames";
import { formatUTCDate, isEmptyString } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

// Example of this pattern: 2022-05-15T12:00:00.000Z => May 15, 2022 8:00 AM
const DEFAULT_FORMAT_DATE_PATTERN = "LLL";

export interface SummaryViewDateProps {
  className?: string;
  name: string;
  value?: string;
  nameClassName?: string;
  valueClassName?: string;
  formatDatePattern?: string;
}

type Props = WithStyles<typeof styles> & SummaryViewDateProps;

export const SummaryViewDateLabel = withStyles(styles)((props: Props) => {

  const {
    classes,
    className = "",
    nameClassName,
    valueClassName,
    name,
    value,
    formatDatePattern = DEFAULT_FORMAT_DATE_PATTERN,
  } = props;

  if (isEmptyString(value)) {
    return null;
  }

  return (
    <div className={classnames(className, classes[className], classes.container)}>
      <label className={classnames("name", nameClassName, classes.name)}>{name}:</label>
      <label className={classnames("value", valueClassName, classes.value)}>
        {formatUTCDate(value, formatDatePattern || DEFAULT_FORMAT_DATE_PATTERN)}
      </label>
    </div>
  );
});

export default SummaryViewDateLabel;
