import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { getDashboard } from "../selectors";
import { Dashboard, SummaryViewData } from "@data";
import DashboardDetails from "@modules/dashboardDetails/components/DashboardDetails";
import { NotFoundView } from "@components";

interface ContainerModel {
  dashboard?: Dashboard;
}

type Props = ContainerModel;

const DashboardDetailsContainer = (props: Props) => {

  const { dashboard = Dashboard.EMPTY } = props;

  const showNotFoundView = React.useMemo(() => Dashboard.EMPTY.equals(dashboard), [dashboard]);

  if (showNotFoundView) { return <NotFoundView/>; }

  const readers = React.useMemo(() => dashboard.getReaders(), [dashboard]);
  const datasets = React.useMemo(() => dashboard.getDataSources(), [dashboard]);

  const dashboardSummaryItems = React.useMemo(() => [
      new SummaryViewData({
        className: "dashboardName",
        name: "Dashboard Name",
        value: dashboard.getDashboardName(),
      }),
      new SummaryViewData({
        className: "published",
        name: "Published",
        value: dashboard.isPublished() ? "true" : "false",
      }),
  ], [dashboard]);

  return (
    <DashboardDetails
      title={dashboard.getDashboardName()}
      datasets={datasets}
      readers={readers}
      dashboardSummaryItems={dashboardSummaryItems}
    />
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
  dashboard: getDashboard(state),
});

export default connect<ContainerModel, {  }, Props>(
  mapStateToProps,
)(DashboardDetailsContainer);
