import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import {
  isGenerateDialog,
} from "../selectors";
import GenerateUserReportDialog from "./GenerateUserReportDialog";

interface Model {
  generateDialog?: boolean;
  detailsDialog?: boolean;
}

interface Actions {
}

type Props = Model & Actions;

const UserReportDialog = (props: Props) => {

  const {
    generateDialog,
  } = props;

  return (
    <React.Fragment>
      {generateDialog && <GenerateUserReportDialog />}
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  generateDialog: isGenerateDialog(state),
});

export default connect<Model, {}, Model>(
  mapStateToProps,
)(UserReportDialog);
