import React from "react";
import { connect } from "react-redux";
import {
  createColumns,
  ListViewItem,
  ModuleListView,
  ModuleListViewActions,
  ModuleListViewModel,
  WorkloadListColumns,
} from "@components";
import { Workload } from "@data/Workload";
import { useGetWorkloads } from "@hooks";
import { getPathToWorkload } from "@modules/dataWorkloadDetails/helpers";
import { PrimaryIcon as WorkloadIcon } from "@modules/dataWorkloads/styles";
import { AppSchema } from "@schemas";
import {
  getUserId,
} from "@modules/userDetails/selectors";
import { isEmptyString, noop } from "@util";

export const DEFAULT_RELEASED_WORKLOAD_LIST_COLUMNS: WorkloadListColumns[] = [
  WorkloadListColumns.NAME,
  WorkloadListColumns.VERSION,
];

interface WorkloadsModel extends ModuleListViewModel<Workload, WorkloadListColumns> {
  hidden?: boolean;
  userId?: string;
}

interface WorkloadActions extends ModuleListViewActions<Workload, WorkloadListColumns> {
  showWorkloadDetails?: (workloadName: string) => void;
}

type Props = WorkloadsModel & WorkloadActions;

const WorkloadsView = (props: Props) => {

  const {
    hidden,
    userId,
    showWorkloadDetails = noop,
    onClickItem = React.useCallback((item: Workload) =>
      showWorkloadDetails(item.getName()), [showWorkloadDetails]),
    ...otherProps
  } = props;

  const [nameFilter, setNameFilter] = React.useState("");

  const [{ workloads, showLoadingIndicator, ...otherModel }, { refresh: refreshList, ...otherActions }] =
    useGetWorkloads({ nameFilter, filterByReleasedState: false, releasedByNameFilter: userId });

  const moduleListItems: ListViewItem<Workload>[] = React.useMemo(() =>
    workloads.map(workload => {
      return {
        item: workload,
        icon: WorkloadIcon,
        pathToDetailsView: getPathToWorkload(workload.getName()),
        columnAttributes: createColumns([
          {
            className: "workloadName",
            value: workload.getName(),
            column: WorkloadListColumns.NAME,
            firstColumn: true,
          },
          {
            className: "workloadVersion",
            value: workload.getVersion(),
            column: WorkloadListColumns.VERSION,
          },
          {
            className: "workloadReleasedBy",
            value: workload.getReleasedBy(),
            column: WorkloadListColumns.RELEASED_BY,
          },
        ])
      };
    }), [workloads]);

  const showSearch = React.useMemo(() =>
    workloads.length > 1 || !isEmptyString(nameFilter),
    [workloads, nameFilter]);

  const summaryViewLabel = React.useMemo(() =>
    showLoadingIndicator ? "Loading released workloads... " :
      "Workloads released by this user", [showLoadingIndicator]);

  if (hidden) {
    return null;
  }

  return (
    <ModuleListView
      {...otherProps}
      {...otherModel}
      {...otherActions}
      className={"workloadsView"}
      noResultsLabel="No workloads released by this user"
      nameFilter={nameFilter}
      setNameFilter={setNameFilter}
      onClickItem={onClickItem}
      refresh={refreshList}
      showLoadingIndicator={showLoadingIndicator}
      columns={DEFAULT_RELEASED_WORKLOAD_LIST_COLUMNS}
      listViewItems={moduleListItems}
      showSummary={true}
      showSearch={showSearch}
      summaryViewLabel={summaryViewLabel}
      summaryViewIcon={WorkloadIcon}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: WorkloadsModel): WorkloadsModel => ({
  userId: getUserId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: WorkloadActions): WorkloadActions => ({
  ...ownProps,
});

export default connect<WorkloadsModel, WorkloadActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(WorkloadsView);
