import { Module } from "@data";
import { initialize } from "./actions";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { isAccessDeniedVisible } from "./selectors";
import view from "./PasswordRecoveryView";

type RouteParams = { id?: string };

export const passwordRecoveryModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Recover Password",
  title: "Recover Password",
  className: "passwordRecoveryModule",
  primaryColor,
  icon,
  view,
  isAccessDeniedVisible,
  onMount: ({ id = "" }: RouteParams = {}) => initialize(id),
});

export default passwordRecoveryModule;
