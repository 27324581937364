import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  EditUserProfileAttributesList,
  EditUserProfileAttributesListActions as EditListActions,
  EditUserProfileAttributesListModel as EditListModel,
} from "../user-profile-attributes-list";
import {
  UserProfileAttributesTitle,
  UserProfileAttributesTitleModel as TitleModel,
} from "./UserProfileAttributesTitle";
import styles from "./styles";

export interface EditUserProfileAttributesModel extends EditListModel, TitleModel {
  className?: string;
}

export interface EditUserProfileAttributesActions extends EditListActions {
}

export type EditUserProfileAttributesProps = EditUserProfileAttributesModel & EditUserProfileAttributesActions;

type Props = WithStyles<typeof styles> & EditUserProfileAttributesProps;

export const EditUserProfileAttributes = withStyles(styles)((props: Props) => {

  const { classes, className, ...otherProps } = props;

  return (
    <div className={classnames("editUserProfileAttributes", className, classes.container)}>
      <UserProfileAttributesTitle {...otherProps} />
      <EditUserProfileAttributesList {...otherProps} />
    </div>
  );
});

export default EditUserProfileAttributes;
