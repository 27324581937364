import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    dialog: {
    },
    content: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
    },
    textField: {
      fontSize: 12,
      padding: 0,
      height: 50,
    },
    inputLabel: {
      fontSize: 12,
    },
    dropdownMenu: {
      minWidth: 250,
      display: "inline-flex",
      alignItems: "stretch",
      marginTop: 8,
    },
    dropdownMenuLabel: {
      marginTop: 8,
    },
    autocomplete: {
      flex: "1 0 auto",
      marginTop: 15,
      width: "100%",
    },
    autocompleteOption: {
    },
    autocompleteOptionAndName: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "center",
    },
    autocompleteOptionValue: {
      fontWeight: 400,
      fontSize: 12,
      color: Color.TEXT,
    },
    autocompleteOptionName: {
      marginLeft: "auto",
      fontWeight: 300,
      fontSize: 11,
      color: Color.BLACK2,
    },
    autocompleteHelperText: {
      fontSize: 11,
      fontWeight: 300,
      paddingTop: 4,
    },
    credentialName: {
    },
    credentialType: {
      marginLeft: 4,
    },
    deviceAuthenticationTypes: {
    },
    trustedAuthorityIds: {
      marginBottom: 15,
    },
  });

export default styles;
