import { Record } from "immutable";
import isEqual from "lodash/isEqual";
import { AuthenticatorAttributes } from "@data";

export enum AuthenticatorRequirement {
  NONE = "NONE",
  REQUIRED = "REQUIRED",
}

export enum UserAdministrationUserType {
  NONE = "",
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

export interface UserActivityEventAttributes {
  created: string;
  updated: string;
}

export interface UserActivityLoginAttributes {
  lastFailed: string;
  lastSuccessful: string;
}

export interface UserActivityPasswordAttributes {
  lastChanged: string;
}

export interface UserAdministrationActivityAttributes {
  events: UserActivityEventAttributes;
  login: UserActivityLoginAttributes;
  password: UserActivityPasswordAttributes;
}

export interface SecondaryAuthenticationAttributes {
  administrativeRequirement: AuthenticatorRequirement;
  authenticators: AuthenticatorAttributes[];
}

export interface UserAdministrationAuthenticationAttributes {
  secondary: SecondaryAuthenticationAttributes;
}

export interface UserAdministrationIdentityAttributes {
  anonymousId: string;
  userId: string;
  userType: UserAdministrationUserType;
}

export interface UserAdministrationLoginAttributes {
  failuresSinceLastSuccessfulLogin: number;
}

export interface UserAdministrationMetricsAttributes {
  login: UserAdministrationLoginAttributes;
}

export interface UserAdministrationStateAttributes {
  confirmed: boolean;
  locked: boolean;
}

export interface UserAdministrationAttributes {
  activity?: UserAdministrationActivityAttributes;
  authentication?: UserAdministrationAuthenticationAttributes;
  identity: UserAdministrationIdentityAttributes;
  metrics?: UserAdministrationMetricsAttributes;
  state?: UserAdministrationStateAttributes;
}

export class UserAdministration extends Record({
  activity: {
    events: {
      created: "",
      updated: "",
    },
    login: {
      lastFailed: "",
      lastSuccessful: "",
    },
    password: {
      lastChanged: "",
    }
  },
  authentication: {
    secondary: {
      administrativeRequirement: AuthenticatorRequirement.NONE,
      authenticators: [],
    }
  },
  identity: {
    anonymousId: "",
    userId: "",
    userType: UserAdministrationUserType.NONE
  },
  metrics: {
    login: {
      failuresSinceLastSuccessfulLogin: 0
    }
  },
  state: {
    confirmed: false,
    locked: false
  }
}) implements UserAdministrationAttributes {

  public static EMPTY: UserAdministration =  new UserAdministration();

  public readonly activity: UserAdministrationActivityAttributes;
  public readonly authentication: UserAdministrationAuthenticationAttributes;
  public readonly identity: UserAdministrationIdentityAttributes;
  public readonly metrics: UserAdministrationMetricsAttributes;
  public readonly state: UserAdministrationStateAttributes;

  public equals(other: UserAdministration ): boolean {
    return isEqual(this.toJS(), other.toJS());
  }

  public getActivity(): UserAdministrationActivityAttributes {
    return this.activity || {};
  }

  public getAuthentication(): UserAdministrationAuthenticationAttributes {
    return this.authentication || {};
  }

  public getMetrics(): UserAdministrationMetricsAttributes {
    return this.metrics || {};
  }

  public getState(): UserAdministrationStateAttributes {
    return this.state || {};
  }

  public getIdentity(): UserAdministrationIdentityAttributes {
    return this.identity || {};
  }

  public getAuthenticators(): AuthenticatorAttributes[] {
    return this.getAuthentication().secondary.authenticators || [];
  }
}

export default UserAdministration;
