import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { DeviceTypeWizardActions } from "../actions";
import { JsonDialog, SchemasList } from "@components";
import { useSchemaDefinition, useSchemas } from "@hooks";
import { DeviceTypeWizardSelectors } from "../selectors";
import { JsonSchemaDefinition, JsonSchemaMetadata, JsonSchemaState } from "@data";
import { equalsIgnoreCase, isEmptyString, noop } from "@util";
import SchemasView, { Actions, Model } from "../components/SchemasView";

interface ContainerModel extends Model {
  hidden?: boolean;
  accountId?: string;
  selectedSchemaId?: string;
}

interface ContainerActions extends Actions {
  setSelectedSchema?: (schemas?: JsonSchemaMetadata) => void;
}

const SecretsSchemasViewContainer = (props: ContainerModel & ContainerActions) => {

  const {
    hidden,
    accountId = "",
    selectedSchemaId = "",
    setSelectedSchema = noop,
    ...otherProps
  } = props;

  const setSelectedItems = React.useCallback((selectedSchemas: JsonSchemaMetadata[]) =>
    setSelectedSchema(selectedSchemas.pop()), [setSelectedSchema]);

  const [ model, api ] = useSchemas();

  const {
    nameFilter,
    searchFilters,
    schemas: items,
    showProgressIndicator: isLoading,
    showLoadMoreButton: isLoadMoreButtonVisible,
    showErrorView: isErrorVisible,
  } = model;

  const selectedSchemaIds = React.useMemo(() => {
    const [namespace, name, version] = selectedSchemaId.split(":");
    const selectedSchema = new JsonSchemaMetadata({ namespace, name, version });
    return selectedSchema.hasId() ? [selectedSchema.getId()] : [];
  }, [selectedSchemaId]);

  const searchResults = React.useMemo(() => {

    const validSchemas = items.filter(schema => schema.state !== JsonSchemaState.DECOMMISSIONED);

    const missingItems = selectedSchemaIds
      .filter(schemaId => !validSchemas.some(schema => equalsIgnoreCase(schemaId, schema.getId())))
      .map(schemaId => {
        const [namespace, name, version] = schemaId.split(":");
        return new JsonSchemaMetadata({ namespace, name, version });
      });

    return missingItems.concat(validSchemas);

  }, [selectedSchemaIds, items]);

  const selectedItems = React.useMemo(() =>
    searchResults.filter(schema => selectedSchemaIds.indexOf(schema.getId()) >= 0),
    [searchResults, selectedSchemaIds]);

  const isItemSelected = React.useMemo(() =>
    selectedItems.length > 0, [selectedItems]);

  const schemas = React.useMemo(() =>
    isItemSelected ? selectedItems : searchResults,
    [isItemSelected, selectedItems, searchResults]);

  const showErrorView = React.useMemo(() =>
    isErrorVisible && !isItemSelected,
    [isErrorVisible, isItemSelected]);

  const showProgressIndicator = React.useMemo(() =>
    isLoading && !isItemSelected,
    [isLoading, isItemSelected]);

  const showNoResultsView = React.useMemo(() =>
    schemas.length === 0 && !isItemSelected && !showErrorView && !showProgressIndicator,
    [schemas, isItemSelected, showErrorView, showProgressIndicator]);

  const noResultsLabel = React.useMemo(() => {
    if (isEmptyString(nameFilter) && searchFilters.length === 0) {
      return `No schemas found in account ${accountId}`;
    } else {
      return "No schemas found";
    }
  }, [nameFilter, searchFilters, accountId]);

  const showLoadMoreButton = React.useMemo(() =>
    isLoadMoreButtonVisible && !isItemSelected,
    [isLoadMoreButtonVisible, isItemSelected]);

  const [schemaDetailWindow, setSchemaDetailWindow ] = React.useState(false);
  const [schemaIdentity, setSchemaIdentity] = React.useState("");
  const [schemaJson, setSchemaJson] = React.useState("");
  const [schemaDetailError, setSchemaDetailError] = React.useState("");

  const schemaNamespace = schemaIdentity.split(":")[0] || "";
  const schemaName = schemaIdentity.split(":")[1] || "";
  const schemaVersion = schemaIdentity.split(":")[2] || "";

  const onSuccess = React.useCallback((schemaDefinition: JsonSchemaDefinition) => {
    setSchemaJson(JSON.stringify(schemaDefinition, null, "  "));
    setSchemaDetailWindow(true);
  }, [setSchemaJson, setSchemaDetailWindow]);

  const onFailure = React.useCallback((error: string) => {
    setSchemaDetailError(error);
    setSchemaDetailWindow(true);
  }, [setSchemaDetailError]);

  const [ schemaDefModel ] = useSchemaDefinition({
    namespace: schemaNamespace,
    name: schemaName,
    version: schemaVersion,
    onSuccess,
    onFailure,
  });

  const {
    showProgressIndicator: loading,
  } = schemaDefModel;

  const onClickShowMoreInfo = React.useCallback((schema: JsonSchemaMetadata) => {
    setSchemaIdentity(schema.getId());
  }, [setSchemaIdentity]);

  const closeSchemaDetailDialog = React.useCallback(() => {
    setSchemaIdentity("");
    setSchemaDetailWindow(false);
  }, [setSchemaDetailWindow, setSchemaIdentity]);

  if (hidden) {
    return null;
  }

  return (
    <SchemasView
      {...otherProps}
      title={"Select Device Type Secrets Schema"}
      className={"secretsView"}
      showBackDrop={loading}
    >
      <SchemasList
        {...model}
        {...api}
        hideSummary={true}
        selectable={true}
        selectAllDisabled={true}
        schemas={schemas}
        hideSearch={isItemSelected}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        noResultsLabel={noResultsLabel}
        showNoResultsView={showNoResultsView}
        showSearchFilters={!isItemSelected}
        showLoadMoreButton={showLoadMoreButton}
        showProgressIndicator={showProgressIndicator}
        showErrorView={showErrorView}
        showMoreInfoLabel="Schema Details"
        onClickShowMoreInfo={onClickShowMoreInfo}
      />
      <JsonDialog
        className="deviceTypeWizardSecretSchemaDetailsDialog"
        open={schemaDetailWindow}
        cancel={closeSchemaDetailDialog}
        errorMessage={schemaDetailError}
        schema={schemaJson}
        jsonTitle={schemaIdentity}
      />
    </SchemasView>
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
  hidden: !DeviceTypeWizardSelectors.isSecretSchemaViewSelected(state),
  accountId: DeviceTypeWizardSelectors.getCurrentAccountId(state),
  selectedSchemaId: DeviceTypeWizardSelectors.getSecretsSchema(state),
});

const mapDispatchToProps = (dispatch: any): ContainerActions => ({
  setSelectedSchema: schema => dispatch(DeviceTypeWizardActions.updateSecretSchema(schema)),
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps
)(SecretsSchemasViewContainer);
