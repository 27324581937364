import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { noop } from "@util";
import { Policy } from "@data";
import { selectView as styles } from "./styles";
import { PolicyBulkRequest } from "@data/PolicyBulkRequest";
import {
  PoliciesList,
  PoliciesListActions,
  PoliciesListModel,
} from "@components";
import { PoliciesListColumn } from "@components/policies-list";

export const DEFAULT_COLUMNS: PoliciesListColumn[] = [PoliciesListColumn.NAME];

export interface Model extends PoliciesListModel {
  className?: string;
  selectedPolicies?: Policy[];
}

export interface Actions extends PoliciesListActions {
  setSelectedPolicy?: (policy?: Policy) => void;
  setPolicyBulkRequest?: (req: PolicyBulkRequest[]) => void;
  setSelectedPolicies?: (items: Policy[]) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SelectPolicy = withStyles(styles)((props: Props) => {

  const {
    items = [],
    selectedPolicies = [],
    showLoadMoreButton,
    showSearch: showSearchView,
    showLoadingIndicator,
    errorMessage,
    setSelectedPolicies = noop,
    setSelectedPolicy = noop,
    setPolicyBulkRequest = noop,
    ...otherProps
  } = props;

  const policySelected = React.useMemo(() =>
    selectedPolicies.length > 0,
    [selectedPolicies]);

  const showLoadMore = React.useMemo(() =>
      policySelected ? false : showLoadMoreButton,
    [policySelected, showLoadMoreButton]);

  const error = React.useMemo(() =>
      policySelected ? "" : errorMessage,
    [policySelected, errorMessage]);

  const policies = React.useMemo(() => items.slice(), [items]);

  const setSelectedItems = (item: Policy[]) => {
    setSelectedPolicies(item);
    setSelectedPolicy(item.length > 0 ? item[0] : Policy.EMPTY);
    setPolicyBulkRequest(item.map((policy: Policy) =>
      new PolicyBulkRequest({
      policy: policy.toJS(),
      status: PolicyBulkRequest.EMPTY.status,
    })));
  };

  return (
      <PoliciesList
        {...otherProps}
        items={policies}
        columns={DEFAULT_COLUMNS}
        showSearch={true}
        showLoadMoreButton={showLoadMore}
        showLoadingIndicator={showLoadingIndicator}
        errorMessage={error}
        selectable={true}
        selectAllDisabled={false}
        selectedItems={selectedPolicies}
        setSelectedItems={setSelectedItems}
      />
  );
});

export default SelectPolicy;
