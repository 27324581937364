import { Record } from "immutable";
import {
  DeviceTypeModelV3CredentialType,
  DeviceTypeModelV3Definition,
  DeviceTypeModelV3DefinitionAttributes,
  DeviceTypeModelV3DeviceAuthentication,
  DeviceTypeModelV3DeviceAuthenticationAttributes,
} from "@data";

export interface DeviceTypeModelV3UsageAttributes {
  deviceAuthentication: DeviceTypeModelV3DeviceAuthenticationAttributes[];
  digitalSignature: any;
}

export interface DeviceTypeModelV3CredentialsAttributes {
  definitions: DeviceTypeModelV3DefinitionAttributes[];
  usage: DeviceTypeModelV3UsageAttributes;
}

export class DeviceTypeModelV3Credentials extends Record({
  definitions: [],
  usage: {
    deviceAuthentication: [],
  },
}) implements DeviceTypeModelV3CredentialsAttributes {

  public static EMPTY: DeviceTypeModelV3Credentials = new DeviceTypeModelV3Credentials();

  public readonly definitions: DeviceTypeModelV3DefinitionAttributes[];
  public readonly usage: DeviceTypeModelV3UsageAttributes;

  public getDefinitions(): DeviceTypeModelV3Definition[] {
    const definitions = Array.isArray(this.definitions) ? this.definitions : [];
    return definitions.map(attrs => new DeviceTypeModelV3Definition(attrs));
  }

  public getDefinitionNames(): string[] {
    const definitions = this.getDefinitions();
    return definitions.map(definition => definition.getName());
  }

  public getDefinitionTypes(): DeviceTypeModelV3CredentialType[] {
    const definitions = this.getDefinitions();
    return definitions.map(definition => definition.getType());
  }

  public getUsage(): DeviceTypeModelV3UsageAttributes {
    return this.usage || { deviceAuthentication: [] };
  }

  public getDeviceAuthentication(): DeviceTypeModelV3DeviceAuthentication[] {
    const { deviceAuthentication = [] } = this.getUsage();
    const data = Array.isArray(deviceAuthentication) ? deviceAuthentication : [];
    return data.map(attrs => new DeviceTypeModelV3DeviceAuthentication(attrs));
  }
}

export default DeviceTypeModelV3Credentials;
