import React from "react";
import { Trigger } from "@data";
import { GetWorkloadTriggersResponse } from "@network";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  useWorkloadManagerClient
} from "@hooks";

type SuccessResponse = GetWorkloadTriggersResponse;

export interface UseGetWorkloadTriggersProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
}

export interface UseGetWorkloadTriggersModel extends UseApiRequestModel<SuccessResponse> {
  triggers: Trigger[];
}

export interface UseGetWorkloadTriggersActions {
  getTriggers: () => void;
}

type Props = UseGetWorkloadTriggersProps;
type Model = UseGetWorkloadTriggersModel;
type Actions = UseGetWorkloadTriggersActions;
type Result = [Model, Actions];

export const useGetWorkloadTriggers = (props: Props): Result => {

  const {
    name,
    version,
    defaultErrorMessage = "Failed to get workload triggers",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
      WorkloadManagerClient.getWorkloadTriggersApi(name, version),
    [WorkloadManagerClient, name, version]);

  const [{ successResponse, ...baseModel }, { refresh: getTriggers }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const triggers = React.useMemo<Trigger[]>(() => {
    const { eventBindings: sources = []} = successResponse || {};
    return sources.map(attrs => new Trigger(attrs));
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    triggers,
  }), [
    baseModel,
    triggers,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    getTriggers,
  }), [getTriggers]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetWorkloadTriggers;
