import { Breadcrumb, Module } from "@data";
import { MODULE_ID } from "./constants";
import { equalsIgnoreCase } from "@util";
import { createModule } from "@modules/base/createModule";
import { getDataAccessRequestPath, getLegacyDataAccessRequestPath } from "./helpers";
import { primaryColor, PrimaryIcon as icon } from "@modules/dataAccessRequests/components/styles";
import view from "./CreateDataAccessRequestView";
import dataSetsModule from "@modules/dataSets/module";
import welcomePageModule from "@modules/welcome/module";
import { MODULE_PATH as dataSetsModulePath } from "@modules/dataSets/constants";

export const dataAccessRequestWizardModule: Module = createModule({
  id: MODULE_ID,
  path: getDataAccessRequestPath(":id"),
  legacyPath: getLegacyDataAccessRequestPath(":id"),
  exactPath: true,
  strictPath: true,
  name: "Request Data Access",
  title: "Request Data Access",
  className: "dataAccessRequestWizardModule",
  primaryColor,
  icon,
  view,
  skipInitializeOnRouteParamsChange: true,
  disabled: !equalsIgnoreCase("true", process.env.REACT_APP_DATA_GOVERNANCE_MODULES_ENABLED),
  customBreadcrumbs: [
    new Breadcrumb({title: welcomePageModule.breadcrumbTitle, url: welcomePageModule.path}),
    new Breadcrumb({title: dataSetsModule.title, url: dataSetsModulePath}),
    new Breadcrumb({title: "Request Data Access", url: getDataAccessRequestPath(":id")})
  ],
});

export default dataAccessRequestWizardModule;
