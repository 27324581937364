import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { selectView as styles } from "./styles";
import {
  UsersList,
  UsersListActions,
  UsersListModel,
} from "@components";
import { UsersListColumn } from "@components/users-list/UsersListColumn";

export const DEFAULT_COLUMNS: UsersListColumn[] = [UsersListColumn.NAME];

export interface Model extends UsersListModel {
  className?: string;
}

export interface Actions extends UsersListActions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SelectUser = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    selectedItems = [],
    showLoadMoreButton,
    showLoadingIndicator,
    errorMessage,
    ...otherProps
  } = props;

  const userSelected = React.useMemo(() =>
    selectedItems.length > 0,
    [selectedItems]);

  const showLoadMore = React.useMemo(() =>
    userSelected ? false : showLoadMoreButton,
    [userSelected, showLoadMoreButton]);

  const error = React.useMemo(() =>
      userSelected ? "" : errorMessage,
    [userSelected, errorMessage]);

  return (
    <div className={classnames("selectUser", className, classes.container)}>
      <UsersList
        {...otherProps}
        columns={DEFAULT_COLUMNS}
        showSearch={true}
        showLoadMoreButton={showLoadMore}
        showLoadingIndicator={showLoadingIndicator}
        errorMessage={error}
        selectable={true}
        selectAllDisabled={false}
        selectedItems={selectedItems}
      />
    </div>
  );
});

export default SelectUser;
