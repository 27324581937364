import React from "react";
import useApiRequest, {
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import { DataApprovalRequest } from "@data";
import { GetDataApprovalRequestsResponse } from "@network";
import useDataApprovalRequestClient from "../use-data-approval-request-client";

type SuccessResponse = GetDataApprovalRequestsResponse;

export interface UseGetDataApprovalRequestsProps extends Partial<UseApiRequestProps<SuccessResponse>> {
}

export interface UseGetDataApprovalRequestsModel extends UseApiRequestModel<SuccessResponse> {
  approvalRequests: DataApprovalRequest[];
}

export interface UseGetDataApprovalRequestsActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetDataApprovalRequestsProps;
type Model = UseGetDataApprovalRequestsModel;
type Actions = UseGetDataApprovalRequestsActions;
type Result = [Model, Actions];

export const useGetDataApprovalRequests = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to get data approval requests",
    ...otherProps
  } = props;

  const DataApprovalRequestClient = useDataApprovalRequestClient();

  const makeApiRequest = React.useCallback(() =>
      DataApprovalRequestClient.getDataApprovalRequests(),
    [DataApprovalRequestClient]);

  const [{successResponse, ...baseModel}, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const approvalRequests = React.useMemo(() => {
    const {approvalRequests: items = []} = successResponse || {};
    return items.map(attrs => new DataApprovalRequest(attrs));
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successResponse,
    approvalRequests,
  }), [
    baseModel,
    successResponse,
    approvalRequests,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetDataApprovalRequests;
