import React from "react";
import { useWorkloadManagerClient } from "@hooks";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps } from "@hooks";

type SuccessResponse = void;

export interface UseEditDescriptionProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  description: string;
}

export interface UseEditDescriptionModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseEditDescriptionActions {
  editDescription: () => void;
  reset: () => void;
}

type Props = UseEditDescriptionProps;
type Model = UseEditDescriptionModel;
type Actions = UseEditDescriptionActions;
type Result = [Model, Actions];

export const useEditDescription = (props: Props): Result => {

  const {
    name,
    version,
    description,
    defaultErrorMessage = "Failed to edit workload description",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.editWorkloadDescriptionApi(name, version, description),
    [WorkloadManagerClient, name, version, description]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: editDescription, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Edit Successful" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    editDescription,
    reset
  }), [
    editDescription,
    reset
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEditDescription;
