export enum EmailTemplateListColumn {
  NONE = "",
  ID = "Template Id",
  APPLICATION_ID = "Application Id",
  LANGUAGE = "Language",
  MESSAGE_TYPE = "Type",
  SUBJECT = "Subject",
}

export default EmailTemplateListColumn;
