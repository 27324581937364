import { FederationProvider } from "@data";
import { FederationProviderListColumn } from "@components";
import { Comparator, compare, isEmptyString } from "@util";

export const getSearchResults = (items: FederationProvider[], searchQuery: string) => {

  const providers = items.slice();

  if (providers.length === 0 || isEmptyString(searchQuery)) {
    return providers;
  }

  const searchFilter = searchQuery.toLowerCase();

  return providers.filter((provider: FederationProvider) =>
  provider.getProviderId().toLowerCase().indexOf(searchFilter) >= 0 ||
  provider.getClientId().toLowerCase().indexOf(searchFilter) >= 0 ||
  provider.getAccountId().toLowerCase().indexOf(searchFilter) >= 0 ||
  provider.getDomain().toLowerCase().indexOf(searchFilter) >= 0);
};

const compareById = (left: FederationProvider, right: FederationProvider, ascending: boolean) =>
  compare(left.getProviderId(), right.getProviderId(), ascending);

const compareByClientId = (left: FederationProvider, right: FederationProvider, ascending: boolean) =>
  compare(left.getClientId(), right.getClientId(), ascending);

const compareByAccountId = (left: FederationProvider, right: FederationProvider, ascending: boolean) =>
  compare(left.getAccountId(), right.getAccountId(), ascending);

const compareByDomain = (left: FederationProvider, right: FederationProvider, ascending: boolean) =>
  compare(left.getDomain(), right.getDomain(), ascending);

export const createComparator =
  (sortedColumn: FederationProviderListColumn, ascending: boolean): Comparator<FederationProvider> =>
    (left: FederationProvider, right: FederationProvider) => {
      switch (sortedColumn) {
        case FederationProviderListColumn.ID:
          return compareById(left, right, ascending);
        case FederationProviderListColumn.CLIENT_ID:
          return compareByClientId(left, right, ascending);
        case FederationProviderListColumn.DOMAIN:
          return compareByDomain(left, right, ascending);
        case FederationProviderListColumn.ACCOUNT_ID:
          return compareByAccountId(left, right, ascending);
        default:
          return 0;
      }
    };
