import React from "react";
import { connect } from "react-redux";
import { PropertyEditor, Model, Actions } from "../components/PropertyEditor";
import { JsonSchemaPropertyType } from "../../../data";
import { PropertyEditorSelectors } from "../selectors/propertyEditor";
import { PropertyEditorActions } from "../actions/propertyEditor";
import { AppSchema } from "../../main/schemas";
import EnumValueEditor from "./EnumValueEditor";
import DefaultValueEditor from "./DefaultValueEditor";
import ConstraintsEditor from "./ConstraintsEditor";

const PropertyEditorContainer = (props: Model & Actions) => {

  return (
    <PropertyEditor {...props}>
      <EnumValueEditor />
      <ConstraintsEditor />
      <DefaultValueEditor />
    </PropertyEditor>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  className: PropertyEditorSelectors.getPropertyEditorClassName(state),
  name: PropertyEditorSelectors.getName(state),
  nameError: PropertyEditorSelectors.getNameError(state),
  type: PropertyEditorSelectors.getType(state),
  types: PropertyEditorSelectors.getPropertyEditorTypes(state),
  description: PropertyEditorSelectors.getDescription(state),
  required: PropertyEditorSelectors.isRequired(state),
  loading: PropertyEditorSelectors.isProgressIndicatorVisible(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  setName: (name: string) => dispatch(PropertyEditorActions.setName(name)),
  clearNameError: () => dispatch(PropertyEditorActions.clearNameError()),
  setType: (type: JsonSchemaPropertyType) => dispatch(PropertyEditorActions.updateType(type)),
  setDescription: (description: string) => dispatch(PropertyEditorActions.setDescription(description)),
  setRequired: (required: boolean) => dispatch(PropertyEditorActions.setRequired(required)),
  save: () => dispatch(PropertyEditorActions.save()),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyEditorContainer);
