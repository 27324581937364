import React from "react";
import classnames from "classnames";
import { isReleaseNotesEnabled } from "@util";
import { MODULE_ID, MODULE_PATH, MODULE_TITLE } from "@modules/releaseNotes/constants";
import { PrimaryIcon as ReleaseNotesIcon } from "@modules/releaseNotes/components/styles";
import NavigationListItem, { ListItemType } from "./NavigationListItem";

export const ReleaseNotesListItem = (props: {
  className?: string;
  releaseNotesEnabled?: boolean;
}) => {

  const {
    className,
    releaseNotesEnabled = isReleaseNotesEnabled(),
  } = props;

  if (!releaseNotesEnabled) {
    return null;
  }

  return (
    <NavigationListItem
      className={classnames("releaseNotesListItem", className)}
      name={MODULE_TITLE}
      outlined={true}
      clickable={true}
      path={MODULE_PATH}
      type={ListItemType.RELEASE_NOTES}
      id={MODULE_ID}
      icon={ReleaseNotesIcon}
      exactPath={true}
      strictPath={true}
      isActive={(match, { pathname }) => pathname.indexOf(MODULE_PATH) >= 0}
    />
  );
};

export default ReleaseNotesListItem;
