import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import TextField from "@components/text-field";
import { batchIdView as styles, idTextField } from "./styles";
import { ErrorView, TooltipIcon } from "@components";
import { clickHandler, formEventHandler, noop } from "@util";
import GenerateUUID from "@material-ui/icons/Autorenew";
import { Vector } from "@data";
import InputAdornment from "@material-ui/core/InputAdornment";

const IdTextField = withStyles(idTextField)(TextField);

export interface Model {
  showInvalidJsonError?: boolean;
  className?: string;
  title?: string;
  idFieldName?: string;
  disabled?: boolean;
  description?: string;
  label?: string;
  id?: string;
  idError?: string;
  children?: React.ReactNode;
}

export interface Actions {
  setId?: (id: string) => void;
  generateRandomId?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const IdView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className = "idField",
    title = "Set ID",
    description = "",
    label = "Set ID",
    showInvalidJsonError,
    id = "",
    disabled = false,
    idFieldName = "idField",
    idError = "",
    children,
    setId,
    generateRandomId = noop
  } = props;

  if (showInvalidJsonError) {
    return (
      <div className={classnames("idView", classes.errorViewContainer)}>
        <ErrorView
          title="JSON Syntax Error"
          message="Visual editors cannot be used until you have fixed the JSON syntax error."
        />
      </div>
    );
  }

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const inputProps = {
    className: classnames("textField", classes.textField),
    spellCheck: false,
  };

  const formHelperTextProps = {
    className: classnames("formHelperText", classes.formHelperText),
    classes: {
      error: classes.formHelperTextError,
    },
  };

  return (
    <div className={classnames("idView", classes.container, className)}>
      <label className={classes.title}>
        {title}
      </label>
      <label className={classes.subtitle}>
        {description}
      </label>
      <IdTextField
        className={classnames("idField", classes.idField, idFieldName)}
        autoComplete="off"
        disabled={disabled}
        label={label}
        name={idFieldName}
        value={id}
        fullWidth={false}
        variant="outlined"
        margin="none"
        required={true}
        helperText={idError}
        error={idError.length > 0}
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setId)}
        FormHelperTextProps={formHelperTextProps}
        InputProps={{
          inputProps,
          endAdornment: (
            <InputAdornment position="end">
              <div className={classnames("uuidControls", classes.uuidControls)}>
                <TooltipIcon
                  className={classnames("uuidButton", classes.uuidButton)}
                  PopperProps={{
                    className: classnames("tooltipIconPopper", classes.tooltipIconPopper),
                  }}
                  onClick={clickHandler(generateRandomId)}
                  disabled={disabled}
                  tooltipText="Generate UUID"
                  icon={GenerateUUID}
                  size={Vector.square(24)}
                  disableTouchRipple={false}
                />
              </div>
            </InputAdornment>
          )
        }}
      />
      {children}
    </div>
  );
});

export default IdView;
