import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";

export enum DataSetDetailType {
  NONE = "",
  DATA_LAKE = "DATA_LAKE",
  DATA_LAKE_ACQUIRED = "DATA_LAKE_ACQUIRED",
  DATA_LAKE_BLOBS = "DATA_LAKE_BLOBS",
  DATA_WAREHOUSE = "DATA_WAREHOUSE",
  DATA_WAREHOUSE_PRIVATE = "DATA_WAREHOUSE_PRIVATE",
  DATA_WAREHOUSE_SHARED = "DATA_WAREHOUSE_SHARED",
  DATA_WAREHOUSE_SYSTEM = "DATA_WAREHOUSE_SYSTEM",
  DATA_WAREHOUSE_TABLE = "DATA_WAREHOUSE_TABLE",
  DOCUMENT_STORE = "DOCUMENT_STORE",
  KV_STORE = "KV_STORE",
  MANAGED_QUEUE = "MANAGED_QUEUE",
}

export enum DataSetDetailSubType {
  NONE = "",
  ACQUIRED = "ACQUIRED",
  BLOBS = "BLOBS",
  PRIVATE = "PRIVATE",
  SYSTEM = "SYSTEM",
  SHARED = "SHARED",
  TABLE = "TABLE",
}

export interface DataSetDetailAttributes {
  accountId: string;
  dataSetAlias: string;
  dataSetGroup?: string;
  dataSetSubType: DataSetDetailSubType | string;
  description: string;
  dataSetType: DataSetDetailType | string;
  dataSetState: string;
  metadataLinks: string[];
  dataOwners: string[];
  tags: string[];
  dataModelReference: string;
  containsPII?: boolean;
  PIIComment?: string;
}

export class DataSetDetail extends Record({
  accountId: "",
  dataSetAlias: "",
  dataSetGroup: "",
  dataSetSubType: DataSetDetailSubType.NONE,
  description: "",
  dataSetType: DataSetDetailType.NONE,
  dataSetState: "",
  dataOwners: [],
  metadataLinks: [],
  tags: [],
  dataModelReference: "",
  containsPII: false,
  PIIComment: "",
}) implements DataSetDetailAttributes {

  public static EMPTY: DataSetDetail = new DataSetDetail();

  public readonly accountId: string;
  public readonly dataSetAlias: string;
  public readonly dataSetGroup: string;
  public readonly dataSetSubType: DataSetDetailSubType | string;
  public readonly description: string;
  public readonly dataSetType: DataSetDetailType | string;
  public readonly dataSetState: string;
  public readonly dataOwners: string[];
  public readonly tags: string[];
  public readonly metadataLinks: string[];
  public readonly dataModelReference: string;
  public readonly containsPII: boolean;
  public readonly PIIComment: string;

  public getDataSetAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getDataSetAlias(): string {
    return getStringValue(this.dataSetAlias);
  }

  public getDataSetGroup(): string {
    return getStringValue(this.dataSetGroup);
  }

  public getDataSetSubType(): DataSetDetailSubType | string {
    const dataSetSubType = getStringValue(this.dataSetSubType);
    if (dataSetSubType === DataSetDetailSubType.ACQUIRED) {
      return DataSetDetailSubType.ACQUIRED;
    } else if (dataSetSubType === DataSetDetailSubType.BLOBS) {
      return DataSetDetailSubType.BLOBS;
    } else if (dataSetSubType === DataSetDetailSubType.PRIVATE) {
      return DataSetDetailSubType.PRIVATE;
    } else if (dataSetSubType === DataSetDetailSubType.SYSTEM) {
      return DataSetDetailSubType.SYSTEM;
    } else if (dataSetSubType === DataSetDetailSubType.SHARED) {
      return DataSetDetailSubType.SHARED;
    } else if (dataSetSubType === DataSetDetailSubType.TABLE) {
      return DataSetDetailSubType.TABLE;
    } else {
      return dataSetSubType;
    }
  }

  public getDataSetDescription(): string {
    return getStringValue(this.description);
  }

  public getDataSetType(): DataSetDetailType | string {
    const dataSetType = getStringValue(this.dataSetType);
    if (dataSetType === DataSetDetailType.DATA_LAKE) {
      return DataSetDetailType.DATA_LAKE;
    } else if (dataSetType === DataSetDetailType.DATA_LAKE_ACQUIRED) {
      return DataSetDetailType.DATA_LAKE_ACQUIRED;
    } else if (dataSetType === DataSetDetailType.DATA_LAKE_BLOBS) {
      return DataSetDetailType.DATA_LAKE_BLOBS;
    } else if (dataSetType === DataSetDetailType.DATA_WAREHOUSE) {
      return DataSetDetailType.DATA_WAREHOUSE;
    } else if (dataSetType === DataSetDetailType.DATA_WAREHOUSE_PRIVATE) {
      return DataSetDetailType.DATA_WAREHOUSE_PRIVATE;
    } else if (dataSetType === DataSetDetailType.DATA_WAREHOUSE_SHARED) {
      return DataSetDetailType.DATA_WAREHOUSE_SHARED;
    } else if (dataSetType === DataSetDetailType.DATA_WAREHOUSE_SYSTEM) {
      return DataSetDetailType.DATA_WAREHOUSE_SYSTEM;
    } else if (dataSetType === DataSetDetailType.DATA_WAREHOUSE_TABLE) {
      return DataSetDetailType.DATA_WAREHOUSE_TABLE;
    } else if (dataSetType === DataSetDetailType.DOCUMENT_STORE) {
      return DataSetDetailType.DOCUMENT_STORE;
    } else if (dataSetType === DataSetDetailType.KV_STORE) {
      return DataSetDetailType.KV_STORE;
    } else if (dataSetType === DataSetDetailType.MANAGED_QUEUE) {
      return DataSetDetailType.MANAGED_QUEUE;
    } else {
      return dataSetType;
    }
  }

  public getDataModelReference(): string {
    return getStringValue(this.dataModelReference);
  }

  public getDataSetState(): string {
    return getStringValue(this.dataSetState);
  }

  public getDataOwners(): string[] {
    const dataOwners = Array.isArray(this.dataOwners) ? this.dataOwners : [];
    return dataOwners
      .map(getStringValue)
      .filter(owner => !isEmptyString(owner))
      .sort();
  }

  public getDataOwnerEmails(): string[] {
    return this.getDataOwners()
      .map((owner: string) => `${getStringValue(owner).split(":").pop()}`);
  }

  public getTags(): string[] {
    const tags = Array.isArray(this.tags) ? this.tags : [];
    return tags
      .map(getStringValue)
      .filter(owner => !isEmptyString(owner))
      .sort();
  }

  public getMetadataLinks(): string[] {
    const links = Array.isArray(this.metadataLinks) ? this.metadataLinks : [];
    return links
      .map(getStringValue)
      .filter(link => !isEmptyString(link))
      .sort();
  }

  public containsPii(): boolean {
    return this.containsPII;
  }

  public getPiiComment(): string {
    return getStringValue(this.PIIComment);
  }

  public isDataLakeAcquiredDataSetType(): boolean {
    const dataSetType = this.getDataSetType();
    const dataSetSubType = this.getDataSetSubType();
    return (dataSetType === DataSetDetailType.DATA_LAKE_ACQUIRED) ||
      (dataSetType === DataSetDetailType.DATA_LAKE &&
        dataSetSubType === DataSetDetailSubType.ACQUIRED);
  }
}

export default DataSetDetail;
