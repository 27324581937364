import { Record } from "immutable";
import { equalsIgnoreCase, getStringValue } from "@util";

export enum UserStatus {
  NONE = "",
  CONFIRMED = "CONFIRMED",
  UNCONFIRMED = "UNCONFIRMED",
  PENDING = "PENDING",
  LOCKED = "LOCKED",
  CLOSED = "CLOSED",
}

export enum MfaStatus {
  NONE = "NONE",
  REQUIRED = "REQUIRED",
}

export type UserProfileSchema = {
  [key: string]: string; // TODO: Figure out allowed values and convert to an enum
};

export type UserProfileAttributes = {
  [key: string]: any; // TODO: Figure out allowed values
};

export interface UserAttributes {
  // TODO: Remove id and keep only userId once the updated User model has been completely rolled out
  // https://www.collaboration.dtf.signify.com/display/IOTINT/IOTSEC-2231+API+Breaking+changes+v.2.4.X
  id: string;
  userId?: string;
  anonymousId?: string;
  status?: UserStatus;
  createdAt?: string;
  lastLogin?: string;
  lastPasswordChange?: string;
  numberFailedLoginsSinceSuccess?: number;
  lastFailedLogin?: string;
  mfaStatus?: MfaStatus;
  profile?: UserProfileAttributes;
  schema?: UserProfileSchema;
}

export class User extends Record({
  id: "",
  userId: "",
  anonymousId: "",
  status: UserStatus.NONE,
  createdAt: "",
  lastLogin: "",
  lastPasswordChange: "",
  numberFailedLoginsSinceSuccess: 0,
  lastFailedLogin: "",
  mfaStatus: MfaStatus.NONE,
  profile: {},
  schema: {},
}) implements UserAttributes {

  public static EMPTY = new User();

  public readonly id: string;
  public readonly userId: string;
  public readonly anonymousId: string;
  public readonly status: UserStatus;
  public readonly createdAt: string;
  public readonly lastLogin: string;
  public readonly lastPasswordChange: string;
  public readonly numberFailedLoginsSinceSuccess: number;
  public readonly lastFailedLogin: string;
  public readonly mfaStatus: MfaStatus;
  public readonly profile: UserProfileAttributes;
  public readonly schema: UserProfileSchema;

  // We need to override equals because this Record contains a nested Object property
  //
  // Reference: https://immutable-js.github.io/immutable-js/docs/#/Record/equals
  // Value equality: Records use value equality when compared with is() or record.equals().
  // That is, two Records with the same keys and values are equal. Plain objects use reference
  // equality. Two objects with the same keys and values are not equal since they are different
  // objects. This is important to consider when using objects as keys in a Map or values in a
  // Set, which use equality when retrieving values.
  public equals(other: User): boolean {
    // When comparing users, we only care about the userId
    return equalsIgnoreCase(this.getUserId(), other.getUserId());
  }

  public getUserId(): string {
    return getStringValue(this.userId) ||
      getStringValue(this.id); // Legacy userId was returned as id
  }

  public getAnonymousId(): string {
    return getStringValue(this.anonymousId);
  }

  public getStatus(): UserStatus {
    return this.status;
  }

  public getCreatedAt(): string {
    return getStringValue(this.createdAt);
  }

  public getLastLogin(): string {
    return getStringValue(this.lastLogin);
  }

  public getLastPasswordChange(): string {
    return getStringValue(this.lastPasswordChange);
  }

  public getNumberFailedLoginsSinceSuccess(): number {
    return this.numberFailedLoginsSinceSuccess;
  }

  public getLastFailedLogin(): string {
    return getStringValue(this.lastFailedLogin);
  }

  public getMfaStatus(): MfaStatus {
    return this.mfaStatus;
  }

  public isUserLocked(): boolean {
    return this.getStatus() === UserStatus.LOCKED;
  }

  public isUserClosed(): boolean {
    return this.getStatus() === UserStatus.CLOSED;
  }

  public isUserUnconfirmed(): boolean {
    return this.getStatus() === UserStatus.UNCONFIRMED;
  }

  public getProfile(): UserProfileAttributes {

    const profile = this.profile || {};

    return {
      ...profile,
    };
  }

  public getSchema(): UserProfileSchema {

    const schema = this.schema || {};

    return {
      ...schema,
    };
  }

  public getProfileAttribute<T = string>(name: string): T | undefined {

    const profile = this.getProfile();

    return profile[name] as T;
  }

  public getFirstName(): string {
    return this.getProfileAttribute<string>("firstName") || "";
  }

  public getLastName(): string {
    return this.getProfileAttribute<string>("lastName") || "";
  }

  public getLanguage(): string {
    return this.getProfileAttribute<string>("language") || "";
  }
}

export default User;
