import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    button: {
      margin: 0,
      padding: 0,
      minWidth: 0,
      textAlign: "left",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    label: {
      color: Color.GREY3,
      fontSize: 12,
      fontWeight: 400,
      textTransform: "none",
      lineHeight: "14px",
      margin: 0,
      padding: 0,
    },
    tooltipIcon: {
      marginLeft: 5,
    },
    iconButton: {
      width: "auto",
      height: "auto",
      margin: "0 0 0 4px",
      padding: 4,
    },
    icon: {
      width: 10,
      height: 11,
      margin: 4,
    },
  });

export default styles;
