import React from "react";
import { isEmptyString } from "@util";
import {
  CreateSecurityServiceRegionalRequest,
  SecurityServiceRegional,
  SecurityServiceRegionalAttributes,
} from "@data";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useSecurityServicesRegionalClient } from "@hooks";

type SuccessResponse = SecurityServiceRegionalAttributes;

export interface UseCreateServiceRegionalProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  data?: CreateSecurityServiceRegionalRequest;
}

export interface UseCreateServiceRegionalModel extends UseApiRequestModel<SuccessResponse> {
  service: SecurityServiceRegional;
  successMessage: string;
  secret: string;
}

export interface UseCreateServiceRegionalActions {
  createService: () => void;
}

type Props = UseCreateServiceRegionalProps;
type Model = UseCreateServiceRegionalModel;
type Actions = UseCreateServiceRegionalActions;
type Result = [Model, Actions];

export const useCreateServiceRegional = (props: Props): Result => {

  const {
    data = CreateSecurityServiceRegionalRequest.EMPTY,
    defaultErrorMessage = "Failed to create service",
    ...otherProps
  } = props;

  const SecurityServiceClient = useSecurityServicesRegionalClient();

  const makeApiRequest = React.useCallback(() =>
          SecurityServiceClient.createSecurityServiceRegionalApi(JSON.stringify(data)),
      [SecurityServiceClient, data]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: createService }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const service = React.useMemo<SecurityServiceRegional>(() => {
    const attrs = successResponse || {};
    return new SecurityServiceRegional(attrs);
  }, [successResponse]);

  const successMessage = React.useMemo(() => showSuccessView ? "Service Created" : "", [showSuccessView]);

  const secret = React.useMemo(() =>
    !isEmptyString(service.getId()) ? service.getPrimarySecret() : "", [service]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
    service,
    secret
  }), [
    baseModel,
    successMessage,
    showSuccessView,
    service,
    secret
  ]);

  const actions = React.useMemo<Actions>(() => ({
    createService,
  }), [
    createService,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useCreateServiceRegional;
