import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  Device,
  DeviceAttributes,
  DeviceConfiguration,
  DeviceConfigurationAttributes,
  DeviceDataModelAttributes,
  DeviceDataScope
} from "@data";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "@modules/base/createReducers";
import { DeviceDetailsTabsType } from "@modules/deviceDetails/components/DeviceDetailsTabs";
import { DeviceDataType } from "@components/device-data-type";

export enum DeviceConfigurationDataMode {
  ALL = "ALL",
  ACTUAL = "ACTUAL",
  DESIRED = "DESIRED"
}

export enum DeviceHistoryColumns {
  NONE = "",
  BATCH_ID = "Batch ID",
  STATUS = "Status",
  STATUS_MESSAGE = "Status Message",
  UPDATED = "Updated By",
  UPDATED_AT = "Updated At",
}

export interface DeviceDetailsSchema extends BasePortalModuleSchema {
  device: DeviceAttributes;
  deviceId: string;
  deviceDataViewMode: DeviceDataType;
  deviceScopeMode: DeviceDataScope;
  items: DeviceAttributes[];
  sortedColumn: DeviceHistoryColumns;
  sortOrderAscending: boolean;
  searchQuery: string;
  nextPage: string;
  deviceConfiguration: DeviceConfigurationAttributes;
  dataMode: DeviceConfigurationDataMode;
  configurationErrorMessage: string;
  deviceJson: string;
  selectedTab: DeviceDetailsTabsType;
  deleteDataDialog: boolean;
  deviceDataEditMode: boolean;
  enrollmentLoadingIndicator: boolean;
  historyLoadingIndicator: boolean;
  configurationLoadingIndicator: boolean;
  configRefresh: boolean;
  deviceTypeIdentity: string;
  deviceRequests: DeviceDataModelAttributes[];
}

export const DEFAULT_STATE: DeviceDetailsSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  device: Object.freeze({ ...Device.EMPTY.toJS() }),
  deviceId: "",
  deviceDataViewMode: DeviceDataType.ACTUAL,
  deviceScopeMode: DeviceDataScope.DEVICE,
  items: [],
  sortedColumn: DeviceHistoryColumns.NONE,
  sortOrderAscending: true,
  searchQuery: "",
  nextPage: "",
  deviceConfiguration: Object.freeze({ ...DeviceConfiguration.EMPTY.toJS() }),
  dataMode: DeviceConfigurationDataMode.ALL,
  configurationErrorMessage: "",
  deviceJson: JSON.stringify(Device.EMPTY.toJS(), null, "  "),
  selectedTab: DeviceDetailsTabsType.ENROLLMENT,
  deleteDataDialog: false,
  deviceDataEditMode: false,
  enrollmentLoadingIndicator: false,
  historyLoadingIndicator: false,
  configurationLoadingIndicator: false,
  configRefresh: false,
  deviceTypeIdentity: "",
  deviceRequests: [],
});

type DeviceDetailsActionTypes = PortalModuleActionTypes<DeviceDetailsSchema> & {
  FETCH_DEVICE_DETAILS_REQUEST: string;
  FETCH_DEVICE_DETAILS_SUCCESS: string;
  FETCH_DEVICE_DETAILS_FAILURE: string;
  FETCH_DEVICE_ENROLLMENT_HISTORY_REQUEST: string;
  FETCH_DEVICE_ENROLLMENT_HISTORY_SUCCESS: string;
  FETCH_DEVICE_ENROLLMENT_HISTORY_FAILURE: string;
  FETCH_DEVICE_CONFIGURATION_REQUEST: string;
  FETCH_DEVICE_CONFIGURATION_SUCCESS: string;
  FETCH_DEVICE_CONFIGURATION_FAILURE: string;
};

export const ACTION_TYPES: DeviceDetailsActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<DeviceDetailsSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    FETCH_DEVICE_DETAILS_REQUEST: `${MODULE_ID}/fetch_device_details_request`,
    FETCH_DEVICE_DETAILS_SUCCESS: `${MODULE_ID}/fetch_device_details_success`,
    FETCH_DEVICE_DETAILS_FAILURE: `${MODULE_ID}/fetch_device_details_failure`,
    FETCH_DEVICE_ENROLLMENT_HISTORY_REQUEST: `${MODULE_ID}/fetch_device_enrollment_history_request`,
    FETCH_DEVICE_ENROLLMENT_HISTORY_SUCCESS: `${MODULE_ID}/fetch_device_enrollment_history_success`,
    FETCH_DEVICE_ENROLLMENT_HISTORY_FAILURE: `${MODULE_ID}/fetch_device_enrollment_history_failure`,
    FETCH_DEVICE_CONFIGURATION_REQUEST: `${MODULE_ID}/fetch_device_configuration_request`,
    FETCH_DEVICE_CONFIGURATION_SUCCESS: `${MODULE_ID}/fetch_device_configuration_success`,
    FETCH_DEVICE_CONFIGURATION_FAILURE: `${MODULE_ID}/fetch_device_configuration_failure`,
  },
});

export const DeviceDetailsReducer = combineReducers<DeviceDetailsSchema>(
  createPortalModuleReducers<DeviceDetailsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DeviceDetailsReducer;
