import React from "react";
import { ManagedPolicy, Policy, SecurityGroup } from "@data";
import { useHistory, useParams } from "react-router-dom";
import { getPathToGroup } from "@modules/securityGroupDetails/helpers";
import {
  getAddUserToGroupPath,
  getRemoveUserFromGroupPath,
} from "@modules/groupManagementWizard/helpers";
import {
  getAttachPoliciesToUserPath,
  getDetachPoliciesFromUserPath,
} from "@modules/policyManagement/helpers";
import UserDetails from "./containers/UserDetails";
import { getPathToPolicy } from "@modules/policyDetails/helpers";
import { getPasswordRecoveryPath } from "@modules/passwordRecovery/helpers";
import { getPathToManagedPolicy } from "@modules/managedPolicyDetails/helpers";
import { getPathToWorkload } from "@modules/dataWorkloadDetails/helpers";

export function UserDetailsView() {

  const history = useHistory();

  const { id: userId } = useParams<{ id: string }>();

  const showSecurityGroupDetails = React.useCallback((group: SecurityGroup) =>
    history.push(getPathToGroup(group.getName())), [history]);

  const showPolicyDetails = React.useCallback((policy: Policy) =>
    history.push(getPathToPolicy(policy.getName())), [history]);

  const showWorkloadDetails = React.useCallback((workloadName: string) =>
    history.push(getPathToWorkload(workloadName)), [history]);

  const showManagedPolicyDetails = React.useCallback((policy: ManagedPolicy) =>
    history.push(getPathToManagedPolicy(policy.getPolicy().getName(), policy.getAccountId())), [history]);

  const addUserToGroup = React.useCallback((id: string) =>
    history.push(getAddUserToGroupPath(id)), [history]);

  const removeUserFromGroup = React.useCallback((id: string) =>
    history.push(getRemoveUserFromGroupPath(id)), [history]);

  const attachPolicy = React.useCallback((id: string) =>
    history.push(getAttachPoliciesToUserPath(id)), [history]);

  const detachPolicy = React.useCallback((id: string) =>
    history.push(getDetachPoliciesFromUserPath(id)), [history]);

  const recoverPassword = React.useCallback(() =>
    history.push(getPasswordRecoveryPath(userId), { goBackOnSuccess: true}),
    [history, userId]);

  return (
    <UserDetails
      key={userId}
      addGroup={addUserToGroup}
      removeGroup={removeUserFromGroup}
      showSecurityGroupDetails={showSecurityGroupDetails}
      showPolicyDetails={showPolicyDetails}
      showWorkloadDetails={showWorkloadDetails}
      attachPolicyToUser={attachPolicy}
      detachPolicyFromUser={detachPolicy}
      recoverPassword={recoverPassword}
      showManagedPolicyDetails={showManagedPolicyDetails}
    />
  );
}

export default UserDetailsView;
