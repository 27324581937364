import React from "react";
import { TrainingCertUrlResponse } from "@network";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useDataAccessRequestClient } from "@hooks";

export interface UseTrainingCertificateUrlProps extends Partial<UseApiRequestProps<TrainingCertUrlResponse>> {
  trainingCertificateId?: string;
}

export interface UseTrainingCertificateUrlModel extends UseApiRequestModel<TrainingCertUrlResponse> {
  trainingCertificateUrl: string;
}

export interface UseTrainingCertificateUrlActions {
  download: () => void;
  reset: () => void;
}

type Props = UseTrainingCertificateUrlProps;
type Model = UseTrainingCertificateUrlModel;
type Actions = UseTrainingCertificateUrlActions;
type Result = [Model, Actions];

export const useTrainingCertificateUrl = (props: Props): Result => {

  const {
    defaultErrorMessage = "Failed to download training certificate",
    trainingCertificateId = "",
    ...otherProps
  } = props;

  const DataAccessRequestClient = useDataAccessRequestClient();

  const [{ successResponse, ...baseModel }, { refresh: download, reset }] =
    useApiRequest<TrainingCertUrlResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => DataAccessRequestClient.getTrainingCertUrl(trainingCertificateId)});

  const trainingCertificateUrl = React.useMemo(() => {
    const { trainingCertificateUrl: url = "" } = successResponse || {};
    return url;
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    trainingCertificateUrl,
  }), [
    baseModel,
    trainingCertificateUrl,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    download,
    reset
  }), [
    download,
    reset
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useTrainingCertificateUrl;
