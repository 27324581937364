import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { SchemaWizardSelectors } from "../selectors/schemaWizard";
import ReviewView, { Model } from "../components/ReviewView";

interface ContainerModel extends Model {
  hidden?: boolean;
}

const ReviewViewContainer = (props: ContainerModel) => {

  const { hidden, ...otherProps } = props;

  if (hidden) {
    return null;
  }

  return <ReviewView {...otherProps} />;
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  hidden: !SchemaWizardSelectors.isReviewViewSelected(state),
  namespace: SchemaWizardSelectors.getNamespace(state),
  name: SchemaWizardSelectors.getTitle(state),
  version: SchemaWizardSelectors.getReviewSchemaVersionLabel(state),
  json: SchemaWizardSelectors.getJson(state),
  showBackdrop: SchemaWizardSelectors.isLoadingIndicatorVisible(state),
  ...ownProps,
});

export default connect<ContainerModel>(
  mapStateToProps,
)(ReviewViewContainer);
