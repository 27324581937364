import React from "react";
import { getStringValue, isEmptyString } from "@util";
import { useSecurityServicesRegionalClient } from "@hooks";
import { AuthenticateServiceRegionalResponse } from "@network";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps } from "@hooks";

// Default message sent back by API as of 11-21-2022 for 400 error indicating MFA not enabled
export const DEFAULT_MFA_ERROR_MESSAGE = "One-time Tokens is not required";

type SuccessResponse = AuthenticateServiceRegionalResponse;

export interface UseAuthenticateServiceRegionalProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  serviceId: string;
  accountId: string;
  secret: string;
  mfaCode?: string;
}

export interface UseAuthenticateServiceRegionalModel extends UseApiRequestModel<SuccessResponse> {
  accessToken: string;
  expiration: string;
  successMessage: string;
  mfaCodeErrorMessage: string;
}

export interface UseAuthenticateServiceRegionalActions {
  authenticateService: () => void;
}

type Props = UseAuthenticateServiceRegionalProps;
type Model = UseAuthenticateServiceRegionalModel;
type Actions = UseAuthenticateServiceRegionalActions;
type Result = [Model, Actions];

export const useAuthenticateServiceRegional = (props: Props): Result => {

  const {
    serviceId = "",
    accountId = "",
    secret = "",
    mfaCode = "",
    defaultErrorMessage = "Failed to authenticate service",
    ...otherProps
  } = props;

  const SecurityServiceClient = useSecurityServicesRegionalClient();

  const makeApiRequest = React.useCallback(() => {
    if (isEmptyString(mfaCode)) {
      return SecurityServiceClient.authenticateServiceRegionalApi(serviceId, accountId, secret);
    } else {
      return SecurityServiceClient.authenticateServiceRegionalApi(serviceId, accountId, secret, mfaCode);
    }
  }, [SecurityServiceClient, serviceId, accountId, secret, mfaCode]);

  const [{
    showErrorView,
    showSuccessView,
    successResponse,
    errorResponse,
    errorMessage,
    ...baseModel
  }, {
    refresh: authenticateService,
  }] = useApiRequest<SuccessResponse>({
    ...otherProps,
    defaultErrorMessage,
    deferRequest: true,
    makeApiRequest,
  });

  const accessToken = React.useMemo(() => {
    const {accessToken: token} = successResponse || {};
    return getStringValue(token);
  }, [successResponse]);

  const expiration = React.useMemo(() => {
    const {expiration: expiry} = successResponse || {};
    return getStringValue(expiry);
  }, [successResponse]);

  const successMessage = React.useMemo(() => showSuccessView ? "Service Authenticated" : "", [showSuccessView]);

  const mfaCodeErrorMessage = React.useMemo(() => {
    if (!showErrorView) {
      return "";
    }
    const { status, description } = errorResponse || {};
    return `${status}` !== "400" ? "" : (description || DEFAULT_MFA_ERROR_MESSAGE);
  }, [showErrorView, errorResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    accessToken,
    expiration,
    showErrorView,
    errorResponse,
    errorMessage,
    showSuccessView,
    successResponse,
    successMessage,
    mfaCodeErrorMessage,
  }), [
    baseModel,
    accessToken,
    expiration,
    showErrorView,
    errorResponse,
    errorMessage,
    showSuccessView,
    successResponse,
    successMessage,
    mfaCodeErrorMessage,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    authenticateService,
  }), [
    authenticateService,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useAuthenticateServiceRegional;
