import { createStyles, StyleRules } from "@material-ui/core/styles";

export const formControlLabel: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "16px",
  },
  label: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "16px",
    marginLeft: 8,
  },
};

export const textField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& input": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      padding: "8px 10px",
      margin: 0,
    },
  },
};

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      justifyContent: "center",
      marginTop: 16,
    },
    booleanDefaultValueEditor: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    inputField: {
      flex: "1 0 auto",
    },
    checkboxContainer: {
      margin: 0,
    },
    checkbox: {
      padding: 0,
    },
    formHelperTextError: {
      fontSize: 12,
    },
    defaultEnumValueContainer: {
      marginTop: 8,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
    },
    defaultEnumValueLabel: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    defaultEnumValueErrorLabel: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      lineHeight: "0",
      fontWeight: 400,
      padding: 0,
      margin: "8px 0",
    },
    defaultEnumValue: {
      marginTop: 0,
    },
    filterSwitch: {
      marginTop: 10,
    },
    filterSwitchLabel: {
      "&.left": {
      },
      "&.right": {
      },
    },
  });

export default styles;
