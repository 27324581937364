import { NamespaceAttributes, NamespaceLifecycle } from "@data";
import { GetNamespacesResponse, NamespaceClient } from "@network";
import { useApiRequestAnalytics } from "@hooks";

export type GetNamespacesApi = (next?: string) => Promise<GetNamespacesResponse>;

export type GetNamespaceApi = (namespace: string) => Promise<NamespaceAttributes>;

export type UpdateNamespaceLifecycleApi = (namespace: string,
                                           state: NamespaceLifecycle) => Promise<void>;

export interface NamespaceClientApi {
  getNamespaces: GetNamespacesApi;
  getNamespace: GetNamespaceApi;
  updateNamespaceLifecycle: UpdateNamespaceLifecycleApi;
}

enum ActionTypes {
  GET_NAMESPACES_REQUEST = "namespace_client/get_namespaces_request",
  GET_NAMESPACES_SUCCESS = "namespace_client/get_namespaces_success",
  GET_NAMESPACES_FAILED = "namespace_client/get_namespaces_failed",

  GET_NAMESPACE_REQUEST = "namespace_client/get_namespace_request",
  GET_NAMESPACE_SUCCESS = "namespace_client/get_namespace_success",
  GET_NAMESPACE_FAILED = "namespace_client/get_namespace_failed",

  UPDATE_NAMESPACE_LIFECYCLE_REQUEST = "namespace_client/update_namespace_lifecycle_request",
  UPDATE_NAMESPACE_LIFECYCLE_SUCCESS = "namespace_client/update_namespace_lifecycle_success",
  UPDATE_NAMESPACE_LIFECYCLE_FAILED = "namespace_client/update_namespace_lifecycle_failed",
}

export const useGetNamespacesApi = () =>
  useApiRequestAnalytics(NamespaceClient.getNamespaces, {
    REQUEST_EVENT: ActionTypes.GET_NAMESPACES_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_NAMESPACES_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_NAMESPACES_FAILED,
  });

export const useGetNamespaceApi = () =>
  useApiRequestAnalytics(NamespaceClient.getNamespace, {
    REQUEST_EVENT: ActionTypes.GET_NAMESPACE_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_NAMESPACE_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_NAMESPACE_FAILED,
  });

export const useUpdateNamespaceLifecycleApi = () =>
  useApiRequestAnalytics(NamespaceClient.updateNamespaceLifecycleState, {
    REQUEST_EVENT: ActionTypes.UPDATE_NAMESPACE_LIFECYCLE_REQUEST,
    SUCCESS_EVENT: ActionTypes.UPDATE_NAMESPACE_LIFECYCLE_SUCCESS,
    ERROR_EVENT: ActionTypes.UPDATE_NAMESPACE_LIFECYCLE_FAILED,
  });

export const useNamespaceClient = (): NamespaceClientApi => ({
  getNamespaces: useGetNamespacesApi(),
  getNamespace: useGetNamespaceApi(),
  updateNamespaceLifecycle: useUpdateNamespaceLifecycleApi(),
});

export { ActionTypes as NamespaceClientActionType };

export default useNamespaceClient;
