import createTheme from "@material-ui/core/styles/createTheme";
import { themeOptions } from "@main/components/theme";
import { Color } from "@components/styles";

const {
  overrides: baseOverrides = {},
  palette: basePalette = {},
} = themeOptions;

const {
  primary: basePrimaryColors = {},
  secondary: baseSecondaryColors = {},
  text: baseTextColors = {},
} = basePalette;

export const theme = createTheme({
  ...themeOptions,
  palette: {
    ...basePalette,
    primary: {
      ...basePrimaryColors,
      contrastText: Color.WHITE,
    },
    secondary: {
      ...baseSecondaryColors,
    },
    text: {
      ...baseTextColors,
    },
  },
  overrides: {
    ...baseOverrides,
  },
});

export default theme;
