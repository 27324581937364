import ModuleIcon from "@material-ui/icons/Create";
import { primaryColor as namespaceModulePrimaryColor } from "@modules/namespaces/components/styles";
import { createStyles, StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = namespaceModulePrimaryColor;

export const namespaceTextField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "14px",
      fontWeight: 300,
      padding: "2px 0",
      margin: 0,
    },
    "& input": {
      fontSize: 14,
      fontWeight: 400,
      padding: "18.5px 14px",
      margin: 0,
    },
  },
};

export const saveButton: StyleRules<string> = {
  root: {
    height: 36,
    backgroundColor: "#1b578e",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    "&:hover": {
      backgroundColor: "#2d6f9e",
    },
    "&:active": {
      backgroundColor: "#4c79a2",
    },
  },
  label: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px",
    padding: 0,
    margin: 0,
    color: Color.WHITE,
  },
};

export const errorView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      padding: 8,
      margin: "16px 8px 8px 8px",
      borderRadius: 3,
      border: `1px solid ${Color.RED}`,
      backgroundColor: Color.LIGHT_RED,
    },
    icon: {
      width: 28,
      height: 28,
      color: Color.RED,
    },
    title: {
      color: Color.DARK_RED,
      fontFamily: "Roboto, sans-serif",
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
      marginTop: 8,
    },
    message: {
      color: Color.DARK_RED,
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "16px",
      marginTop: 4,
    },
  });

export const namespaceInfo = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "21px",
      marginTop: 16,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    namespace: {
      marginTop: 25,
    },
  });

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    actionsContainer: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: 25,
    },
    button: {
    },
    cancelButton: {
    },
    saveButton: {
      marginLeft: 16,
    },
  });

export default styles;
