import { AppSchema } from "@main/schemas";
import { PolicyClient, RestClientError } from "@network";
import {
  getPolicyName,
  getJson,
  getDefaultJson
} from "./selectors";
import { getAuthToken } from "@main/selectors";
import { Policy, PolicyAttributes } from "@data";
import { createActions } from "@base";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";

export const {
  json: setJson,
  policyRef: setPolicyRef,
  policyName: setPolicyName,
  setErrorMessage,
  setSuccessMessage,
  showLoadingIndicator,
  hideLoadingIndicator,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  EDIT_POLICY_REQUEST: editPolicyRequest,
  EDIT_POLICY_SUCCESS: editPolicySuccess,
  EDIT_POLICY_FAILED: editPolicyFailed,
  FETCH_POLICY_REQUEST: fetchPolicyRequest,
  FETCH_POLICY_SUCCESS: fetchPolicySuccess,
  FETCH_POLICY_FAILED: fetchPolicyFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any, getState: () => AppSchema) => {
  dispatch(setJson(getDefaultJson(getState())));
  dispatch(setPolicyRef());
  dispatch(setPolicyName());
  return dispatch(baseReset());
};

export const updateJson = (json: string) => (dispatch: any) => {
  dispatch(setErrorMessage());
  return dispatch(setJson(json));
};

export const updatePolicy = (policy: Policy) => (dispatch: any) =>
  dispatch(updateJson(JSON.stringify(policy.toJS(), null, "  ")));

export const editPolicy = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accessToken  =  getAuthToken(state);
  const policyName = getPolicyName(state);
  const json = getJson(state);

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setErrorMessage());
  dispatch(editPolicyRequest());

  return PolicyClient.editPolicy(accessToken, policyName, json)
    .then(() => {

      dispatch(editPolicySuccess());
      dispatch(hideLoadingIndicator());
      return dispatch(setSuccessMessage("Policy Updated"));

    }, (response: RestClientError) => {

      const { analytic, error = "Failed to update policy" } = response;

      dispatch(editPolicyFailed(analytic));
      dispatch(hideLoadingIndicator());

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(setErrorMessage(error));
    });
};

export const fetchPolicy = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accessToken  =  getAuthToken(state);
  const policyName = getPolicyName(state);

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setErrorMessage());
  dispatch(editPolicyRequest());

  return PolicyClient.getPolicy(accessToken, policyName)
    .then((attrs: PolicyAttributes) => {

      const policy = new Policy(attrs);

      dispatch(updatePolicy(policy));
      dispatch(fetchPolicySuccess());
      dispatch(hideLoadingIndicator());
      dispatch(hideEmptyView());
    }, (response: RestClientError) => {

      const { analytic, error = "Failed to fetch policy" } = response;

      dispatch(fetchPolicyFailed(analytic));
      dispatch(hideLoadingIndicator());

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(setErrorMessage(error));
    });
};

export const initialize = (policyName: string = "") => (dispatch: any) => {

  dispatch(reset());
  dispatch(showEmptyView());
  dispatch(setPolicyName(policyName));
  return dispatch(fetchPolicy());
};
