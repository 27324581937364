import createStyles from "@material-ui/core/styles/createStyles";
import { PrimaryIcon } from "@modules/dataWorkloads/styles";
import Color from "@Color";

export const ModuleIcon = PrimaryIcon;

export const primaryColor = Color.MODULES;

export const configurationView = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      "& div.content": {
        height: 0,
        marginTop: 0,
      },
    },
    header: {
      display: "flex",
      alignItems: "center",
      marginTop: 25,
    },
    headerContent: {
      alignItems: "center",
      display: "flex"
    },
    controls: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      marginLeft: "auto",
    },
    deleteAllButton: {
      marginLeft: 15,
    },
    addConfiguration: {
      justifySelf: "flex-end",
      marginLeft: "auto",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 24,
    },
    configurationList: {
      "& .MuiTable-root": {
        margin: 0,
      },
    }
  });
