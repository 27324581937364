import { Breadcrumb, Module } from "@data";
import { equalsIgnoreCase } from "@util";
import { createModule } from "@modules/base/createModule";
import { initialize } from "./actions/editMode";
import {
  getCloneDataSetRequestPath,
  getCreateDataSetRequestPath,
  getEditDataSetRequestPath,
  getLegacyCreateDataSetRequestPath,
  getLegacyEditDataSetRequestPath,
} from "./helpers";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { isAccessDeniedVisible, isEmptyViewVisible } from "./selectors/dataSetRequestWizard";
import CreateDataSetRequestView from "./CreateDataSetRequestView";
import { reset } from "./actions/dataSetRequestWizard";
import CloneDataSetRequestView from "./CloneDataSetRequestView";
import EditDataSetRequestView from "./EditDataSetRequestView";
import BackButton from "./containers/BackButton";
import dataSetsModule from "@modules/dataSets/module";
import welcomePageModule from "@modules/welcome/module";
import { MODULE_PATH as dataSetsModulePath } from "@modules/dataSets/constants";

type RouteParams = { id?: string; };

export const createDataSetRequestWizardModule: Module = createModule ({
  id: "create_data_set_request",
  path: getCreateDataSetRequestPath(),
  legacyPath: getLegacyCreateDataSetRequestPath(),
  exactPath: true,
  strictPath: true,
  name: "Create Data Set Request Wizard",
  title: "Create New Data Set",
  className: "createDataSetRequestWizardModule",
  primaryColor,
  icon,
  BackButton,
  view: CreateDataSetRequestView,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: reset,
  disabled: !equalsIgnoreCase("true", process.env.REACT_APP_DATA_GOVERNANCE_MODULES_ENABLED),
  customBreadcrumbs: [
    new Breadcrumb({title: welcomePageModule.breadcrumbTitle, url: welcomePageModule.path}),
    new Breadcrumb({title: dataSetsModule.title, url: dataSetsModulePath}),
    new Breadcrumb({title: "Create New Data Set", url: getCreateDataSetRequestPath()})
  ],
});

export const cloneDataSetRequestWizardModule: Module = createModule<RouteParams> ({
  id: "clone_data_set_request",
  path: getCloneDataSetRequestPath(":id"),
  exactPath: true,
  strictPath: true,
  name: "Clone Data Set Request Wizard",
  title: "Clone Data Set Request",
  className: "cloneDataSetRequestWizardModule",
  primaryColor,
  icon,
  BackButton,
  view: CloneDataSetRequestView,
  disabled: !equalsIgnoreCase("true", process.env.REACT_APP_DATA_GOVERNANCE_MODULES_ENABLED),
});

export const editDataSetRequestWizardModule: Module = createModule<RouteParams> ({
  id: "edit_data_set_request",
  path: getEditDataSetRequestPath(":id"),
  legacyPath: getLegacyEditDataSetRequestPath(":id"),
  exactPath: true,
  strictPath: true,
  name: "Edit Data Set Request Wizard",
  title: "Edit Data Set Request",
  className: "editDataSetRequestWizardModule",
  primaryColor,
  icon,
  BackButton,
  view: EditDataSetRequestView,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: ({ id: dataSetRequestId }: RouteParams = {}) => initialize(dataSetRequestId),
  disabled: !equalsIgnoreCase("true", process.env.REACT_APP_DATA_GOVERNANCE_MODULES_ENABLED),
});
