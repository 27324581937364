import React from "react";
import {
  ActionMenuItem,
  ApplicationAction,
  ApplicationOperations,
  DetailsViewRoute,
  SummaryJsonDetailsViewActions,
  SummaryJsonDetailsViewModel,
  SummaryViewActionItems,
} from "@components";
import ApplicationOperationDialog from "@components/application-operation-dialog";
import ApplicationSecretManagementDialog from "@components/application-secret-management-dialog";
import ApplicationAuthenticationDialog from "@components/application-authenticate-dialog";
import SummaryJsonDetailsView from "@components/summary-json-details-view";
import ChipView from "@components/chip-view";
import ApplicationSecurityView from "../components/ApplicationSecurityView";
import FilterSwitch from "@components/filter-switch";
import RedirectUriList from "@components/redirect-uri-list";
import ApplicationUserManagementView from "./ApplicationUserManagementView";
import { noop } from "@util";
import { useGetApplicationRegional, AuthenticateApplicationOperation } from "@hooks";
import { PrimaryIcon } from "../components/styles";
import { getApplicationId } from "../selectors";
import { Application, KeyValuePair, SummaryViewData } from "@data";
import AppSchema from "@schemas";
import { connect } from "react-redux";
import {
  AUTHENTICATE_APPLICATION,
  DELETE_APPLICATION_REGIONAL,
  DELETE_APPLICATION_SECRET,
  EDIT_APPLICATION,
  GENERATE_APPLICATION_SECRET,
  SET_APPLICATION_SECRET,
} from "@components/application-actions-menu";
import { MODULE_PATH as APPLICATIONS_LIST_PATH } from "@modules/applicationsRegional/constants";
import { useHistory } from "react-router-dom";
import lowerCase from "lodash/lowerCase";
import ApplicationSecretOperation
  from "@hooks/applicationManagerRegional/use-application-secret-management/ApplicationSecretOperation";
import ScopeIcon from "@material-ui/icons/Label";
import MetadataIcon from "@material-ui/icons/Style";

interface ContainerModel extends SummaryJsonDetailsViewModel {
  applicationId?: string;
}

interface ContainerActions extends SummaryJsonDetailsViewActions {
  editApplication?: () => void;
}

type Props = ContainerModel & ContainerActions;

const ApplicationDetailsContainer = (props: Props) => {

  const {
    applicationId = "",
    editApplication = noop,
    onClickAction = noop,
    ...otherProps
  } = props;

  const [openOperationDialog, setOpenOperationDialog] = React.useState(false);
  const [openSecretManagementDialog, setOpenSecretManagementDialog] = React.useState(false);
  const [openAuthenticationDialog, setOpenAuthenticationDialog] = React.useState(false);
  const [operation, setOperation] = React.useState(ApplicationOperations.NONE);
  const [secretOperation, setSecretOperation] = React.useState(ApplicationSecretOperation.NONE);
  const [authenticationOperation, setAuthenticationOperation] = React.useState(AuthenticateApplicationOperation.NONE);

  const [ model, actions ] = useGetApplicationRegional( {applicationId});

  const { application = Application.EMPTY, showNotFound, showAccessDenied, ...otherModel } = model;
  const { getApplication, ...otherActions } = actions;

  const redirectUris = React.useMemo(() => application.getRedirectUris(), [application]);

  const scopes = React.useMemo(() => application.getScopes(), [application]);

  const json = React.useMemo(() =>
    JSON.stringify(application.toJS(), null, "  "),
    [application]);

  const summaryViewItems = React.useMemo(() => [
    new SummaryViewData({
      className: "name",
      name: "Name",
      value: application.getName(),
    }),
    new SummaryViewData({
      className: "description",
      name: "Description",
      value: application.getDescription(),
    }),
    new SummaryViewData({
      className: "type",
      name: "Type",
      value: application.getApplicationType(),
    }),
    new SummaryViewData({
      className: "created",
      name: "Created",
      value: application.getCreated(),
      date: true,
    }),
  ], [application]);

  const metadataAsString = React.useMemo(() =>
  KeyValuePair.fromObjectToArray(application.getApplicationMetadata())
    .map((kvp: KeyValuePair) =>
    `${kvp.getKey()} : ${kvp.getValue()}`), [application]);

  const applicationActions = React.useMemo(() => ([
    EDIT_APPLICATION,
    DELETE_APPLICATION_REGIONAL,
  ]), [application]);

  const primarySecretActions = React.useMemo(() => ([
    {
      ...GENERATE_APPLICATION_SECRET,
      disabled: !application.isApplicationAuthenticated(),
    },
    {
      ...SET_APPLICATION_SECRET,
      disabled: !application.isApplicationAuthenticated(),
    },
    {
      ...AUTHENTICATE_APPLICATION,
      disabled: !application.isApplicationAuthenticated(),
    },
  ]), [application]);

  const secondarySecretActions = React.useMemo(() => ([
    {
      ...DELETE_APPLICATION_SECRET,
      disabled: !application.isApplicationAuthenticated() || !application.isSecondarySecretAvailable(),
    },
    {
      ...AUTHENTICATE_APPLICATION,
      disabled: !application.isApplicationAuthenticated() || !application.isSecondarySecretAvailable(),
    },
  ]), [application]);

  const deleteApplicationRegional = React.useCallback(() => {
    setOperation(ApplicationOperations.DELETE);
    setOpenOperationDialog(true);
  }, [setOperation, setOpenOperationDialog]);

  const enableApplication = React.useCallback(() => {
    setOperation(ApplicationOperations.ENABLE);
    return setOpenOperationDialog(true);
  }, [setOperation, setOpenOperationDialog]);

  const disableApplication = React.useCallback(() => {
    setOperation(ApplicationOperations.DISABLE);
    return setOpenOperationDialog(true);
  }, [setOperation, setOpenOperationDialog]);

  const generateApplicationSecret = React.useCallback(() => {
    setSecretOperation(ApplicationSecretOperation.GENERATE);
    return setOpenSecretManagementDialog(true);
  }, [setSecretOperation, setOpenSecretManagementDialog]);

  const setApplicationSecret = React.useCallback(() => {
    setSecretOperation(ApplicationSecretOperation.SET);
    return setOpenSecretManagementDialog(true);
  }, [setSecretOperation, setOpenSecretManagementDialog]);

  const deleteApplicationSecret = React.useCallback(() => {
    setSecretOperation(ApplicationSecretOperation.DELETE);
    return setOpenSecretManagementDialog(true);
  }, [setSecretOperation, setOpenSecretManagementDialog]);

  const authenticateApplication = React.useCallback(() => {
    setAuthenticationOperation(AuthenticateApplicationOperation.AUTHENTICATE);
    return setOpenAuthenticationDialog(true);
  }, [setOpenAuthenticationDialog, setAuthenticationOperation]);

  const onClickApplicationAction = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case ApplicationAction.DELETE_APPLICATION_REGIONAL:
        return deleteApplicationRegional();
      case ApplicationAction.EDIT_APPLICATION:
          return editApplication();
      default:
        return onClickAction(action);
    }
  }, [
    deleteApplicationRegional,
    editApplication,
    onClickAction
  ]);

  const onClickPrimarySecretActions = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
    case ApplicationAction.GENERATE_APPLICATION_SECRET:
      return generateApplicationSecret();
    case ApplicationAction.SET_APPLICATION_SECRET:
      return setApplicationSecret();
    case ApplicationAction.AUTHENTICATE_APPLICATION:
      return authenticateApplication();
    default:
      return onClickAction(action);
    }
  }, [
    generateApplicationSecret,
    setApplicationSecret,
    authenticateApplication,
    onClickAction
  ]);

  const onClickSecondarySecretActions = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
    case ApplicationAction.DELETE_APPLICATION_SECRET:
      return deleteApplicationSecret();
    case ApplicationAction.AUTHENTICATE_APPLICATION:
      return authenticateApplication();
    default:
      return onClickAction(action);
    }
  }, [
    deleteApplicationSecret,
    authenticateApplication,
    onClickAction
  ]);

  const onClickSwitch = React.useCallback((value: boolean) => {
    if (value) {
      return enableApplication();
    } else {
      return disableApplication();
    }
  }, [enableApplication, disableApplication]);

  const closeDialog = React.useCallback(() => {
    setOperation(ApplicationOperations.NONE);
    return setOpenOperationDialog(false);
  }, [setOperation, setOpenOperationDialog]);

  const closeSecretsDialog = React.useCallback(() => {
    setSecretOperation(ApplicationSecretOperation.NONE);
    return setOpenSecretManagementDialog(false);
  }, [setSecretOperation, setOpenSecretManagementDialog]);

  const closeAuthenticationDialog = React.useCallback(() => {
    setAuthenticationOperation(AuthenticateApplicationOperation.NONE);
    return setOpenAuthenticationDialog(false);
  }, [setOpenAuthenticationDialog, setSecretOperation]);

  const history = useHistory();

  const showListView = React.useCallback(() => {
    history.push(APPLICATIONS_LIST_PATH);
  }, [history]);

  const onOperationSuccess = React.useCallback(() => {

    closeDialog();

    if (operation === ApplicationOperations.DELETE) {
      showListView();
    } else {
      getApplication();
    }
  }, [closeDialog, showListView, getApplication, operation]);

  const onSecretOperationSuccess = React.useCallback(() => {

    closeSecretsDialog();
    getApplication();
  }, [closeSecretsDialog, getApplication]);

  const dialogClassName = React.useMemo(() => `${lowerCase(operation)}ApplicationDialog`, [operation]);
  const secretsDialogClassName = React.useMemo(() =>
    `${lowerCase(secretOperation)}ApplicationSecretDialog`, [secretOperation]);

  const securityViewItems: SummaryViewActionItems[] = React.useMemo(() => [
    {
      showActions: false,
      summaryViewItem:
        new SummaryViewData({
          className: "authentication",
          name: "Authentication",
          value: application.getApplicationAuthentication(),
        })
    },
    {
      showActions: false,
      summaryViewItem:
        new SummaryViewData({
          className: "protocol",
          name: "Protocol",
          value: application.getApplicationProtocol(),
        })
    },
    {
      showActions: true,
      actions: primarySecretActions,
      onClickAction: onClickPrimarySecretActions,
      summaryViewItem:
          new SummaryViewData({
          className: "primarySecret",
          name: "Primary Secret",
          value: application.getPrimarySecretAvailability(),
        })
    },
    {
      showActions: true,
      actions: secondarySecretActions,
      onClickAction: onClickSecondarySecretActions,
      summaryViewItem:
        new SummaryViewData({
          className: "secondarySecret",
          name: "Secondary Secret",
          value: application.getSecondarySecretAvailability(),
        })
    }
  ], [
    application,
    primarySecretActions,
    secondarySecretActions,
    onClickPrimarySecretActions,
    onClickSecondarySecretActions
  ]);

  const additionalViews = React.useMemo(() => (
    <React.Fragment>
      <FilterSwitch
        className={"applicationState"}
        title={"Application State"}
        showTitle={true}
        checked={application.isEnabled()}
        leftLabel={"Disabled"}
        leftLabelClassName={"disabled"}
        rightLabel={"Enabled"}
        rightLabelClassName={"enabled"}
        onChange={onClickSwitch}
      />
      <ChipView
        className={"tagView"}
        title={"Scopes"}
        items={scopes}
        icon={ScopeIcon}
        marginTop={true}
      />
      <ChipView
        className={"metadataView"}
        title={"Metadata"}
        items={metadataAsString}
        icon={MetadataIcon}
        marginTop={true}
      />
      {redirectUris.length > 0 && (
        <RedirectUriList
          redirectUris={redirectUris}
        />
      )}
    </React.Fragment>
  ), [scopes, redirectUris, application, onClickSwitch, metadataAsString]);

  const SecurityView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <ApplicationSecurityView
         items={securityViewItems}
         className={"applicationSecurityView"}
      />
    );
  }, [securityViewItems]);

  const UserManagementView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <ApplicationUserManagementView
        applicationId={applicationId}
      />
    );
  }, [applicationId]);

  const otherRoutes: DetailsViewRoute[] = React.useMemo(() => [
    {
      id: "userManagementView",
      name: "User Management",
      path: "/user-management",
      view: UserManagementView
    },
    {
      id: "securityView",
      name: "Security",
      path: "/security",
      hidden: !application.isApplicationAuthenticated(),
      view: SecurityView
    },
  ], [SecurityView, application, UserManagementView]);

  return (
    <React.Fragment>
    <SummaryJsonDetailsView
      {...otherModel}
      {...otherActions}
      {...otherProps}
      className={"applicationRegionalDetails"}
      summaryViewClassName={"applicationRegionalDetailsSummaryView"}
      jsonViewClassName={"applicationRegionalDetailsJsonView"}
      summaryViewItems={summaryViewItems}
      json={json}
      fileName={`${applicationId}.json`}
      additionalViews={additionalViews}
      additionalRoutes={otherRoutes}
      icon={PrimaryIcon}
      title={applicationId}
      actions={applicationActions}
      refresh={getApplication}
      onClickAction={onClickApplicationAction}
      showAccessDenied={showAccessDenied}
      showNotFound={showNotFound}
    />
      <ApplicationOperationDialog
        dialogClassName={dialogClassName}
        open={openOperationDialog}
        applicationId={applicationId}
        operation={operation}
        onSuccessMessageShown={onOperationSuccess}
        cancel={closeDialog}
      />
      <ApplicationSecretManagementDialog
        dialogClassName={secretsDialogClassName}
        open={openSecretManagementDialog}
        applicationId={applicationId}
        operation={secretOperation}
        onSuccessMessageShown={onSecretOperationSuccess}
        cancel={closeSecretsDialog}
      />
      <ApplicationAuthenticationDialog
        dialogClassName={"authenticateApplicationDialog"}
        open={openAuthenticationDialog}
        applicationId={applicationId}
        operation={authenticationOperation}
        cancel={closeAuthenticationDialog}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  applicationId: getApplicationId(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationDetailsContainer);
