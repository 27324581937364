import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { DataSetRequest, DataSetState } from "@data";
import SummaryView from "../components/SummaryView";
import { EventsList } from "../components/EventsList";
import CommentsView from "../components/CommentsView";
import TermsConditionsView from "../components/TermsConditionsView";
import { isDataCatalogProductionAccount, isEmptyString, noop } from "@util";
import { refresh as refreshDataCatalogModule } from "@modules/dataCatalog/actions";
import { open as openDeleteDataSetRequestDialog } from "@modules/deleteDataSetRequest/actions";
import DetailsViewRoute from "@components/details-view/DetailsViewRoute";
import UploadTermsAndConditions from "@components/upload-terms-and-conditions";
import DataSetRequestDetails, { Actions, Model } from "../components/DataSetRequestDetails";
import { useGetDataSetRequest, useGetDataSetRequestComments, useGetDataSetRequestEvents } from "@hooks";
import {
  CLONE_DATA_SET_REQUEST_ACTION,
  DELETE_DATA_SET_REQUEST_ACTION,
  EDIT_DATA_SET_REQUEST_ACTION,
  UPLOAD_TERMS_AND_CONDITIONS_ACTION,
} from "@components/data-set-request-actions-menu";

enum DataSetRequestDetailsViewRoute {
  SUMMARY_VIEW = "summary",
  EVENTS_VIEW = "events",
  COMMENTS_VIEW = "comments",
  TERMS_CONDITIONS_VIEW = "terms-conditions",
}

interface ContainerModel extends Model {
  dataSetRequest?: DataSetRequest;
  dataSetRequestId?: string;
}

interface ContainerActions extends Actions {
  refreshDataCatalogPage?: () => void;
  hideTermsAndConditionsBanner?: () => void;
  openCloneDataSetRequestWizard?: (dataSetRequest: DataSetRequest) => void;
}

type Props = ContainerModel & ContainerActions;

const DataSetRequestDetailsContainer = (props: Props) => {

  const {
    dataSetRequestId = "",
    termsConditionsId,
    refreshDataCatalogPage = noop,
    hideTermsAndConditionsBanner = noop,
    openCloneDataSetRequestWizard = noop,
    ...otherProps
  } = props;

  const [dataModel, dataActions] = useGetDataSetRequest({ dataSetRequestId });

  const [eventModel, eventActions] = useGetDataSetRequestEvents({ dataSetRequestId });

  const [commentModel, commentAction] = useGetDataSetRequestComments({ dataSetRequestId });

  const { refresh: refreshDataSet } = dataActions;
  const { dataSetRequest = DataSetRequest.EMPTY, showLoadingIndicator: loadingRequest } = dataModel;

  const { refresh: refreshEvents } = eventActions;
  const { events, showLoadingIndicator: loadingEvents } = eventModel;

  const { refresh: refreshComments } = commentAction;
  const { comments, showLoadingIndicator: loadingComments } = commentModel;

  const isLoading = React.useMemo(() =>
    loadingRequest || loadingEvents || loadingComments,
    [loadingRequest, loadingEvents, loadingComments]);

  const refreshDetailsPage = React.useCallback(() => {
    refreshDataSet();
    refreshEvents();
    refreshComments();
  }, [refreshDataSet, refreshEvents, refreshComments]);

  const [showUploadDialog, setShowUploadDialog] = React.useState(false);

  const Summary = React.useMemo<() => React.ReactElement>(() => () =>
    <SummaryView dataSetRequest={dataSetRequest} />,
    [dataSetRequest]);

  const Events = React.useMemo<() => React.ReactElement>(() => () =>
    <EventsList items={events} {...eventModel} {...eventActions} />,
    [events, eventModel, eventActions]);

  const Comments = React.useMemo<() => React.ReactElement>(() => () => (
    <CommentsView
      {...commentModel}
      {...commentAction}
      items={comments}
      dataSetRequestId={dataSetRequestId}
      onSuccess={refreshDetailsPage}
    />
  ), [dataSetRequestId, comments, commentModel, commentAction, refreshDetailsPage]);

  const TermsConditions = React.useMemo<() => React.ReactElement>(() => () =>
    <TermsConditionsView termsConditionsId={dataSetRequest.getTermsConditionsId()} />,
    [dataSetRequest]);

  const routes: DetailsViewRoute[] = React.useMemo(() => [
    {
      id: DataSetRequestDetailsViewRoute.SUMMARY_VIEW,
      name: "Summary",
      view: Summary,
    },
    {
      id: DataSetRequestDetailsViewRoute.EVENTS_VIEW,
      name: "Events",
      path: "/events",
      view: Events,
    },
    {
      id: DataSetRequestDetailsViewRoute.COMMENTS_VIEW,
      name: "Comments",
      path: "/comments",
      view: Comments,
    },
    {
      id: DataSetRequestDetailsViewRoute.TERMS_CONDITIONS_VIEW,
      name: "Privacy Terms & Conditions",
      path: "/terms-conditions",
      view: TermsConditions,
      hidden: true,
    },
  ], [Summary, Events, Comments, TermsConditions]);

  const validRoutes = React.useMemo(() =>
    routes.map(route => {
      const { id: routeId } = route;
      if (routeId === DataSetRequestDetailsViewRoute.TERMS_CONDITIONS_VIEW) {
        return {
          ...route,
          hidden: isEmptyString(termsConditionsId),
        };
      } else {
        return route;
      }
    }), [routes, termsConditionsId]);

  const openUploadTermsAndConditionsDialog = React.useCallback(() =>
    setShowUploadDialog(true), [setShowUploadDialog]);

  const closeUploadTermsAndConditionsDialog = React.useCallback(() =>
    setShowUploadDialog(false), [setShowUploadDialog]);

  const onUploadTermsAndConditionsSuccess = React.useCallback(() => {
    hideTermsAndConditionsBanner();
    refreshDetailsPage();
    refreshDataCatalogPage();
  }, [hideTermsAndConditionsBanner, refreshDetailsPage, refreshDataCatalogPage]);

  const cloneDataSetRequest = React.useCallback(() => {
    openCloneDataSetRequestWizard(dataSetRequest);
  }, [openCloneDataSetRequestWizard, dataSetRequest]);

  const title = React.useMemo(() =>
    isLoading ? `Loading: ${dataSetRequestId}` : dataSetRequest.getDataSetAlias(),
    [isLoading, dataSetRequest, dataSetRequestId]);

  const termsAndConditionsRequired = React.useMemo(() =>
    !dataSetRequest.hasTermsConditionsId() && dataSetRequest.isPendingPrivacyTncUpload(),
    [dataSetRequest]);

  const showTermsAndConditionsBanner = React.useMemo(() =>
    !isLoading && isDataCatalogProductionAccount(dataSetRequest.getAccountId()) && termsAndConditionsRequired,
    [isLoading, dataSetRequest, termsAndConditionsRequired]);

  const actions = React.useMemo(() => {
    const deleted = dataSetRequest.getStatus() === DataSetState.DELETED;
    const cancelled = dataSetRequest.getStatus() === DataSetState.CANCELLED;
    return [
      {
        ...CLONE_DATA_SET_REQUEST_ACTION,
        disabled: isLoading,
      },
      {
        ...EDIT_DATA_SET_REQUEST_ACTION,
        name: dataSetRequest.isRequestIotApproved() ? "Edit Description" : "Edit Request",
        disabled: isLoading,
      },
      {
        ...UPLOAD_TERMS_AND_CONDITIONS_ACTION,
        hidden: !isDataCatalogProductionAccount(dataSetRequest.getAccountId()),
        disabled: isLoading || cancelled || deleted,
      },
      {
        ...DELETE_DATA_SET_REQUEST_ACTION,
        disabled: isLoading || cancelled || deleted,
      },
    ];
  }, [isLoading, dataSetRequest]);

  return (
    <DataSetRequestDetails
      {...otherProps}
      {...dataModel}
      routes={validRoutes}
      title={title}
      actions={actions}
      dataSetRequest={dataSetRequest}
      actionsMenuDisabled={isLoading}
      showLoadingIndicator={isLoading}
      showTermsAndConditionsBanner={showTermsAndConditionsBanner}
      termsConditionsId={dataSetRequest.getTermsConditionsId()}
      refresh={refreshDetailsPage}
      uploadTermsAndConditions={openUploadTermsAndConditionsDialog}
      cloneDataSetRequest={cloneDataSetRequest}
    >
      {showUploadDialog && (
        <UploadTermsAndConditions
          open={showUploadDialog}
          dataSetRequestId={dataSetRequestId}
          closeDialog={closeUploadTermsAndConditionsDialog}
          onSuccess={onUploadTermsAndConditionsSuccess}
        />
      )}
    </DataSetRequestDetails>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  deleteDataSetRequest: (data: DataSetRequest) => dispatch(
    openDeleteDataSetRequestDialog(data.getAccountId(), data.getDataSetAlias())),
  refreshDataCatalogPage: () => dispatch(refreshDataCatalogModule()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DataSetRequestDetailsContainer);
