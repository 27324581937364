import { Comparator, compare } from "@util";
import { SecurityServiceRegional } from "@data";
import { ServicesListColumn } from "@components";

const compareByRegionalId = (left: SecurityServiceRegional, right: SecurityServiceRegional, ascending: boolean) =>
  compare(left.getId(), right.getId(), ascending);

const compareByRegionalState = (left: SecurityServiceRegional, right: SecurityServiceRegional, ascending: boolean) =>
  compare(left.getServiceState(), right.getServiceState(), ascending);

const compareByRegionalMfa = (left: SecurityServiceRegional, right: SecurityServiceRegional, ascending: boolean) =>
  compare(left.getMfaStatus(), right.getMfaStatus(), ascending);

const compareByRegionalCreated = (left: SecurityServiceRegional, right: SecurityServiceRegional, ascending: boolean) =>
  compare(left.getCreated(), right.getCreated(), ascending);

export const createRegionalComparator =
  (sortedColumn: ServicesListColumn | string, ascending: boolean): Comparator<SecurityServiceRegional> =>
    (left: SecurityServiceRegional, right: SecurityServiceRegional) => {
      switch (sortedColumn) {
        case ServicesListColumn.ID:
        case ServicesListColumn.NAME:
          return compareByRegionalId(left, right, ascending);
        case ServicesListColumn.STATE:
          return compareByRegionalState(left, right, ascending);
        case ServicesListColumn.MFA:
          return compareByRegionalMfa(left, right, ascending);
        case ServicesListColumn.CREATED:
          return compareByRegionalCreated(left, right, ascending);
        default:
          return 0;
      }
    };

export const sortSecurityServiceListItems = (props: {
  items: SecurityServiceRegional[],
  sortOrderAscending: boolean,
  sortByColumn: ServicesListColumn | string,
}) => {

  const { items, sortOrderAscending, sortByColumn } = props;

  const comparator = createRegionalComparator(sortByColumn, sortOrderAscending);

  return items.sort(comparator).slice();
};
