import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import useUploadTrainingCertFile from "./useUploadTrainingCertFile";
import TrainingCertificateView from "./TrainingCertificateView";
import ErrorView from "../error-view/ErrorView";
import styles from "./styles";

export interface UploadTrainingCertProps {
  className?: string;
  successLabel?: string;
  certificateUploaded?: boolean;
  showAlert?: boolean;
  showTitle?: boolean;
  inDialog?: boolean;
  onSuccess?: () => void;
}

type Props = WithStyles<typeof styles> & UploadTrainingCertProps & {
  children?: React.ReactNode;
};

export const UploadTrainingCert = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    successLabel,
    certificateUploaded,
    showAlert,
    showTitle,
    inDialog,
    onSuccess = noop,
    children,
  } = props;

  const [validationErrorMessage, setValidationErrorMessage] = React.useState("");

  const [file, setFile] = React.useState(new File([], ""));

  const [model, { uploadTrainingCert }] = useUploadTrainingCertFile({ file });

  const { showLoadingIndicator, showErrorView, errorMessage, successMessage } = model;

  const uploadFile = React.useCallback(() => uploadTrainingCert(file), [uploadTrainingCert, file]);

  const onSelectFile = React.useCallback(event => {

    const selectedFile = event && event.target && event.target.files
      ? event.target.files[0] : undefined;

    if (!selectedFile) {
      return setValidationErrorMessage("Invalid File");
    }

    const isPdf = selectedFile.name.slice(selectedFile.name.lastIndexOf(".") + 1) === "pdf";

    if (!isPdf) {
      return setValidationErrorMessage("Invalid File! File format should be PDF");
    }

    setValidationErrorMessage("");
    setFile(selectedFile);
    uploadFile();

  }, [setFile, setValidationErrorMessage, uploadFile]);

  const onDrop = React.useCallback(([selectedFile]) => {

    if (!selectedFile) {
      return setValidationErrorMessage("Invalid File");
    }

    const isPdf = selectedFile.name.slice(selectedFile.name.lastIndexOf(".") + 1) === "pdf";

    if (!isPdf) {
      return setValidationErrorMessage("Invalid File! File format should be PDF");
    }

    setValidationErrorMessage("");
    setFile(selectedFile);
    uploadFile();

  }, [setFile, setValidationErrorMessage, uploadFile]);

  const success = React.useMemo(() =>
    certificateUploaded || !isEmptyString(successMessage),
    [certificateUploaded, successMessage]);

  const error = React.useMemo(() =>
    showErrorView || !isEmptyString(validationErrorMessage),
    [showErrorView, validationErrorMessage]);

  React.useEffect(() => {
    if (!isEmptyString(successMessage)) {
      onSuccess();
    }
  }, [successMessage, onSuccess]);

  return (
    <div className={classnames("uploadTrainingCert", className, classes.container)}>
      <ErrorView
        className={classnames("uploadTrainingCertError", classes.errorView)}
        message={validationErrorMessage || errorMessage}
      />
      <TrainingCertificateView
        file={file}
        success={success}
        successLabel={successLabel}
        loading={showLoadingIndicator}
        error={error}
        onSelectFile={onSelectFile}
        onDrop={onDrop}
        showAlert={showAlert}
        showTitle={showTitle}
        inDialog={inDialog}
      >
        {children}
      </TrainingCertificateView>
    </div>
  );
});

export default UploadTrainingCert;
