import { combineReducers } from "redux";
import { MODULE_ID } from "../constants";
import {
  DeviceTypeModelV3,
  DeviceTypeModelV3Attributes,
  DeviceTypeModelV2,
  DeviceTypeModelV2Attributes, DeviceTypeModelVersion,
} from "@data";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base/createReducers";
import {
  DEFAULT_STATE as MANAGE_SOFTWARE_VERSIONS_DEFAULT_STATE,
  ManageSoftwareVersionsReducer,
  ManageSoftwareVersionsSchema,
} from "./manageSoftwareVersions";
import {
  DEFAULT_STATE as SOFTWARE_VERSION_DETAILS_DEFAULT_STATE,
  SoftwareVersionDetailsReducer,
  SoftwareVersionDetailsSchema,
} from "./softwareVersionDetails";

export enum DeviceTypeSchemaViewMode {
  CONFIGURATION = "configuration",
  METADATA = "metadata",
}

export interface DeviceTypeDetailsSchema extends BasePortalModuleSchema {
  deviceTypeV3: DeviceTypeModelV3Attributes;
  deviceTypeV2: DeviceTypeModelV2Attributes;
  softwareVersions: string[];
  selectedSoftwareVersion: string;
  showHistoricalData: boolean;
  jsonV3: string;
  jsonV2: string;
  etag: string;
  modelVersion: DeviceTypeModelVersion;
  isLatest: boolean;
  manageSoftwareVersions: ManageSoftwareVersionsSchema;
  softwareVersionDetails: SoftwareVersionDetailsSchema;
  schemaViewMode: DeviceTypeSchemaViewMode;
}

export const DEFAULT_STATE: DeviceTypeDetailsSchema = createPortalModuleDefaultState({
  deviceTypeV3: Object.freeze({ ...DeviceTypeModelV3.EMPTY.toJS() }),
  deviceTypeV2: Object.freeze({ ...DeviceTypeModelV2.EMPTY.toJS() }),
  softwareVersions: [],
  selectedSoftwareVersion: "",
  jsonV3: "",
  jsonV2: "",
  showHistoricalData: false,
  etag: "",
  modelVersion: DeviceTypeModelVersion.REGIONAL,
  isLatest: false,
  manageSoftwareVersions: Object.freeze({ ...MANAGE_SOFTWARE_VERSIONS_DEFAULT_STATE }),
  softwareVersionDetails: Object.freeze({ ...SOFTWARE_VERSION_DETAILS_DEFAULT_STATE }),
  schemaViewMode: DeviceTypeSchemaViewMode.CONFIGURATION,
  showEmptyView: false, // Prefer to only show empty view once if details view is already active
});

type DeviceTypeDetailsActionTypes = PortalModuleActionTypes<DeviceTypeDetailsSchema> & {
  LOAD_DEVICE_TYPE_REQUEST: string;
  LOAD_DEVICE_TYPE_SUCCESS: string;
  LOAD_DEVICE_TYPE_FAILED: string;
};

export const ACTION_TYPES: DeviceTypeDetailsActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<DeviceTypeDetailsSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    LOAD_DEVICE_TYPE_REQUEST: `${MODULE_ID}/load_device_type_request`,
    LOAD_DEVICE_TYPE_SUCCESS: `${MODULE_ID}/load_device_type_success`,
    LOAD_DEVICE_TYPE_FAILED: `${MODULE_ID}/load_device_type_failed`,
  },
});

export const DeviceTypeDetailsReducer = combineReducers<DeviceTypeDetailsSchema>({
  ...createPortalModuleReducers<DeviceTypeDetailsSchema>(ACTION_TYPES, DEFAULT_STATE),
  manageSoftwareVersions: ManageSoftwareVersionsReducer,
  softwareVersionDetails: SoftwareVersionDetailsReducer,
});

export default DeviceTypeDetailsReducer;
