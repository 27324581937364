import React from "react";
import { DataSetsListItemData, WorkloadCompletionMetadata } from "@components";
import useCurrentAccountId from "@hooks/use-current-account-id";
import DataWorkloadWizard, { Actions, Model } from "../components/DataWorkloadWizard";
import QueryView from "./QueryView";
import DataSourcesView from "./DataSourcesView";
import DataOutputsView from "./DataOutputsView";
import InfoView from "../components/InfoView";
import ReviewView from "../components/ReviewView";
import TypeView from "../components/TypeView";
import InternetConfigView from "@modules/dataWorkloadWizard/components/InternetConfigView";
import { hasSpecialChars, isDataWorkloadsOneDotThreeFeaturesEnabled, isEmptyString } from "@util";
import {
  Policy,
  WorkloadRuntimeOption,
  CreateWorkloadRequestV3,
  CreateWorkloadRequestAttributesV3,
} from "@data";

export enum CreateWorkloadStep {
  NONE = "",
  INFO = "info",
  TYPE = "type",
  DATA_SOURCES = "data_sources",
  DATA_OUTPUTS = "data_outputs",
  QUERY_EDITOR = "query_editor",
  COMPLETION_METADATA = "completion_metadata",
  INTERNET_ACCESS = "internet_access",
  REVIEW = "review",
}

export const DEFAULT_STEPS = [
  CreateWorkloadStep.INFO,
  CreateWorkloadStep.TYPE,
  CreateWorkloadStep.DATA_SOURCES,
  CreateWorkloadStep.QUERY_EDITOR,
  CreateWorkloadStep.DATA_OUTPUTS,
  CreateWorkloadStep.COMPLETION_METADATA,
].concat(!isDataWorkloadsOneDotThreeFeaturesEnabled() ? ([]) : ([
  CreateWorkloadStep.INTERNET_ACCESS,
])).concat([
  CreateWorkloadStep.REVIEW,
]);

export const DEFAULT_STEP_LABELS = {
  [CreateWorkloadStep.INFO]: "Information",
  [CreateWorkloadStep.TYPE]: "Type",
  [CreateWorkloadStep.DATA_SOURCES]: "Data Set Inputs",
  [CreateWorkloadStep.QUERY_EDITOR]: "Query Editor",
  [CreateWorkloadStep.DATA_OUTPUTS]: "Data Set Ouputs",
  [CreateWorkloadStep.COMPLETION_METADATA]: "Completion Metadata",
  [CreateWorkloadStep.INTERNET_ACCESS]: "Internet Access",
  [CreateWorkloadStep.REVIEW]: "Finish",
};

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
  showDataSetDetails?: (dataSet: DataSetsListItemData) => void;
  showDataSetDetailsInNewTab: (dataSet: DataSetsListItemData) => string;
}

type Props = ContainerModel & ContainerActions;

const DataWorkloadWizardContainer = (props: Props) => {

  const {
    data: initialData = CreateWorkloadRequestV3.EMPTY,
    showDataSetDetailsInNewTab,
    showDataSetDetails,
    ...otherProps
  } = props;

  const accountId = useCurrentAccountId();

  const [ data, setData ] = React.useState<CreateWorkloadRequestV3>(initialData);

  const [ selectedSources, setSelectedSources ] = React.useState<DataSetsListItemData[]>(
    initialData.getDataInputs().map(({ dataSetAlias }) =>
      DataSetsListItemData.fromWorkloadDataSetAttributes(dataSetAlias, accountId)));

  const [ selectedOutputs, setSelectedOutputs ] = React.useState<DataSetsListItemData[]>(
    initialData.getDataOutputs().map(({ dataSetAlias }) =>
      DataSetsListItemData.fromWorkloadDataSetAttributes(dataSetAlias, accountId)));

  const setWorkloadData = React.useCallback((updatedData: Partial<CreateWorkloadRequestAttributesV3>) => {
    setData(new CreateWorkloadRequestV3({
      ...data.toJS(),
      ...updatedData,
      accountId,
    }));
  }, [data, accountId, setData]);

  const clearSelectedDataSets = React.useCallback(() => {
    setSelectedSources([]);
    setSelectedOutputs([]);
  }, [setSelectedSources, setSelectedOutputs]);

  const infoView = React.useMemo(() =>
      <InfoView data={data} setWorkloadData={setWorkloadData}/>,
    [ data, setWorkloadData ]);

  const typeView = React.useMemo(() => (
    <TypeView
      data={data}
      setWorkloadData={setWorkloadData}
      clearSelectedDataSets={clearSelectedDataSets}
    />
  ), [data, setWorkloadData, clearSelectedDataSets]);

  const completionMetadataView = React.useMemo(() => (
    <WorkloadCompletionMetadata
      title="Configure Workload Completion Metadata (Optional)"
      data={data}
      setWorkloadData={setWorkloadData}
      showEditActions={true}
      showAddActions={true}
      showDeleteActions={true}
      showDeleteVariables={true}
      showAddVariables={true}
      showEditVariables={true}
    />
  ), [ data, setWorkloadData ]);

  const setPolicy = React.useCallback((policy: Policy) => {
    setWorkloadData({ policy: policy.toJS() });
  }, [setWorkloadData]);

  const removePolicy = React.useCallback(() => {
    setWorkloadData({ policy: Policy.EMPTY.toJS() });
  }, [setWorkloadData]);

  const internetConfigView = React.useMemo(() =>
      <InternetConfigView data={data} setWorkloadData={setWorkloadData}/>,
    [data, setWorkloadData]);

  const queryView = React.useMemo(() =>
    <QueryView data={data} setWorkloadData={setWorkloadData} />,
    [data, setWorkloadData]);

  const reviewView = React.useMemo(() => (
    <ReviewView
      data={data}
      accountId={accountId}
      selectedSources={selectedSources}
      selectedOutputs={selectedOutputs}
      showDataSetDetails={showDataSetDetails}
      showDataSetDetailsInNewTab={showDataSetDetailsInNewTab}
      setPolicy={setPolicy}
      removePolicy={removePolicy}
    />
  ), [
    data,
    accountId,
    selectedSources,
    selectedOutputs,
    showDataSetDetails,
    showDataSetDetailsInNewTab,
    setPolicy,
    removePolicy,
  ]);

  const dataSourcesView = React.useMemo(() => (
    <DataSourcesView
      data={data}
      selectedItems={selectedSources}
      setSelectedItems={setSelectedSources}
      setWorkloadData={setWorkloadData}
      showDataSetDetailsInNewTab={showDataSetDetailsInNewTab}
    />
  ), [
    data,
    selectedSources,
    setSelectedSources,
    setWorkloadData,
    showDataSetDetailsInNewTab,
  ]);

  const dataOutputsView = React.useMemo(() => (
    <DataOutputsView
      data={data}
      selectedItems={selectedOutputs}
      setSelectedItems={setSelectedOutputs}
      setWorkloadData={setWorkloadData}
      showDataSetDetailsInNewTab={showDataSetDetailsInNewTab}
    />
  ), [
    data,
    selectedOutputs,
    setSelectedOutputs,
    setWorkloadData,
    showDataSetDetailsInNewTab,
  ]);

  const mapStepToView = React.useCallback((step: CreateWorkloadStep) => {
    switch (step) {
      case CreateWorkloadStep.INFO:
        return infoView;
      case CreateWorkloadStep.TYPE:
        return typeView;
      case CreateWorkloadStep.DATA_SOURCES:
        return dataSourcesView;
      case CreateWorkloadStep.QUERY_EDITOR:
        return queryView;
      case CreateWorkloadStep.DATA_OUTPUTS:
        return dataOutputsView;
      case CreateWorkloadStep.COMPLETION_METADATA:
        return completionMetadataView;
      case CreateWorkloadStep.INTERNET_ACCESS:
        return internetConfigView;
      case CreateWorkloadStep.REVIEW:
        return reviewView;
      default:
        return null;
    }
  }, [
    infoView,
    typeView,
    queryView,
    dataSourcesView,
    dataOutputsView,
    completionMetadataView,
    internetConfigView,
    reviewView,
  ]);

  const isValidInfo = React.useMemo(() =>
    !isEmptyString(data.getName()) && !hasSpecialChars(data.getName()), [data]);
  const isValidSources = React.useMemo(() => data.getDataInputs().length > 0, [ data ]);

  const isValidType = React.useMemo(() => {
    if (data.isFunctionType()) {
      return data.getRuntime() !== WorkloadRuntimeOption.NONE &&
        !isEmptyString(data.getEntryPoint()) && !hasSpecialChars(data.getEntryPoint());
    }
    return true;
  }, [data]);

  const isValidMemory = React.useMemo(() => {
    const memory = data.getMemory();
    return data.isQueryType() || memory >= 756 && memory <= 2000;
  }, [data]);

  const isValidQuery = React.useMemo(() => {
    if (data.isQueryType()) {
      return !isEmptyString(data.getDatabaseName())
        && !isEmptyString(data.getTableName())
        && !isEmptyString(data.getQuery());
    }
    return true;
  }, [data]);

  const disabledSteps = React.useMemo(() => {

    if (!isValidInfo) {
      return DEFAULT_STEPS.filter(step => {
        switch (step) {
          case CreateWorkloadStep.INFO:
            return false;
          default:
            return true;
        }
      });
    }

    if (!isValidType || !isValidMemory) {
      return DEFAULT_STEPS.filter(step => {
        switch (step) {
          case CreateWorkloadStep.INFO:
          case CreateWorkloadStep.TYPE:
            return false;
          default:
            return true;
        }
      });
    }

    if (!isValidSources) {
      return DEFAULT_STEPS.filter(step => {
        switch (step) {
          case CreateWorkloadStep.INFO:
          case CreateWorkloadStep.TYPE:
          case CreateWorkloadStep.DATA_SOURCES:
            return false;
          default:
            return true;
        }
      });
    }

    if (!isValidQuery) {
      return DEFAULT_STEPS.filter(step => {
        switch (step) {
          case CreateWorkloadStep.INFO:
          case CreateWorkloadStep.TYPE:
          case CreateWorkloadStep.DATA_SOURCES:
          case CreateWorkloadStep.QUERY_EDITOR:
            return false;
          default:
            return true;
        }
      });
    }

    return [];

  }, [isValidInfo, isValidType, isValidSources, isValidMemory, isValidQuery]);

  return (
    <DataWorkloadWizard
      data={data}
      disabledSteps={disabledSteps}
      mapStepToView={mapStepToView}
      {...otherProps}
    />
  );
};

export default DataWorkloadWizardContainer;
