import { createStyles, StyleRules } from "@material-ui/core/styles";
import DashboardsIcon from "@material-ui/icons/LibraryBooks";
import Color from "@Color";

export const PrimaryIcon = DashboardsIcon;

export const primaryColor = Color.MODULES;

export const actionButton: StyleRules<string> = {
  root: {
    height: 36,
    minWidth: 100,
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
  },
  label: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px",
    padding: 0,
    margin: 0,
    textTransform: "none",
  },
  contained: {
    color: Color.TEXT,
    backgroundColor: Color.WHITE,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    "&:active": {
      backgroundColor: "rgba(0, 0, 0, 0.54)",
    },
  },
  containedPrimary: {
    color: Color.WHITE,
    backgroundColor: "#1b578e",
    "&:hover": {
      backgroundColor: "#2d6f9e",
    },
    "&:active": {
      backgroundColor: "#4c79a2",
    },
  },
};

export const dashboards = () =>
  createStyles({
    container: {
      color: primaryColor,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    search: {
      marginTop: 15,
    },
    filtersMenuButton: {
      marginLeft: 15,
    },
    controls: {
    },
    filtersMenu: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      padding: "10px 25px 10px 0",
    },
    filterSwitch: {
      marginLeft: 15,
    },
    currentAccount: {
    },
    pending: {
    },
  });

export const list = () =>
  createStyles({
    container: {
      marginTop: 15,
      "& th.dataSet, & td.dataSet": {
        width: "25%",
      },
      "& th.description, & td.description": {
        width: "25%",
      },
      "& th.account, & td.account": {
        width: "25%",
      },
      "& th.tags, & td.tags": {
        width: "20%",
      },
      "& th.actions, & td.actions": {
        width: "5%",
      },
    },
  });

export const listItem = () =>
  createStyles({
    nameColumn: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    icon: {
      color: primaryColor,
      width: 16,
      height: 16,
    },
    name: {
      marginLeft: 10,
      color: Color.TEXT,
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "16px",
      wordBreak: "break-word",
    },
    description: {
      color: Color.GREY7,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
      marginTop: 4,
      wordBreak: "break-word",
    },
    italic: {
      fontStyle: "italic",
    },
    placeholder: {
      fontStyle: "italic",
      color: Color.GREY3,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
    },
    account: {
      color: Color.GREY7,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
      wordBreak: "break-word",
    },
    state: {
      color: Color.GREY7,
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "16px",
    },
    tags: {
      listStyleType: "none",
      padding: 0,
      margin: "0 8px 0 0",
    },
    tag: {
      marginTop: 8,
      overflow: "hidden",
      "&:first-child": {
        marginTop: 0,
      },
    },
    chip: {
      backgroundColor: primaryColor,
      height: "auto",
      padding: "6px 10px",
      "& span": {
        color: Color.TEXT,
        fontSize: 11,
        fontWeight: 400,
        lineHeight: "11px",
        margin: 0,
        padding: 0,
      },
    },
  });

export const module = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      fontFamily: "Roboto, sans-serif",
    },
    tabs: {
    },
  });
