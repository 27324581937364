import { Record } from "immutable";
import { Policy, PolicyAttributes } from "./Policy";

export enum PolicyKind {
  CUSTOMER = "CUSTOMER",
  MANAGED = "MANAGED",
}

export interface EffectivePolicyAttributes {
  kind: PolicyKind;
  policy: PolicyAttributes;
}

export class EffectivePolicy extends Record({
  kind: PolicyKind.CUSTOMER,
  policy: Policy.EMPTY.toJS(),
}) implements EffectivePolicyAttributes {

  public static EMPTY: EffectivePolicy = new EffectivePolicy();

  public readonly kind: PolicyKind;
  public readonly policy: PolicyAttributes;

  public getKind(): PolicyKind {
    return this.kind;
  }

  public getPolicy(): Policy {
    return new Policy(this.policy);
  }
}

export default EffectivePolicy;
