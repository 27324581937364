import { combineReducers } from "redux";
import { Actions, filterAction, Identity, ReduxAction } from "../../../util/ReducerUtil";
import {
  DEFAULT_SCHEMA,
  JsonSchemaDefinition,
  JsonSchemaMetadata,
  JsonSchemaMetadataAttributes,
} from "../../../data";

export interface EditModeSchema {
  originalMetaData: JsonSchemaMetadataAttributes;
  originalSchema: JsonSchemaDefinition;
}

export const DEFAULT_STATE: EditModeSchema = {
  originalMetaData: JsonSchemaMetadata.EMPTY.toJS(),
  originalSchema: { ...DEFAULT_SCHEMA },
};

export enum EditModeActionType {
  SET_ORIGINAL_META_DATA = "schema_wizard/edit_mode/set_original_meta_data",
  SET_ORIGINAL_SCHEMA = "schema_wizard/edit_mode/set_original_schema",
  EDIT_SCHEMA_REQUEST = "schema_wizard/edit_mode/edit_schema_request",
  EDIT_SCHEMA_SUCCESS = "schema_wizard/edit_mode/edit_schema_success",
  EDIT_SCHEMA_FAILED = "schema_wizard/edit_mode/edit_schema_failed",
}

export type EditModeAction = ReduxAction<EditModeActionType>;

const originalMetaData = filterAction<JsonSchemaMetadataAttributes>(Actions(
  EditModeActionType.SET_ORIGINAL_META_DATA))(Identity,
  DEFAULT_STATE.originalMetaData);

const originalSchema = filterAction<JsonSchemaDefinition>(Actions(
  EditModeActionType.SET_ORIGINAL_SCHEMA))(Identity,
  DEFAULT_STATE.originalSchema);

export const EditModeReducer = combineReducers<EditModeSchema>({
  originalMetaData,
  originalSchema,
});

export default EditModeReducer;
