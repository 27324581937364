import React from "react";
import classnames from "classnames";
import { isEmptyString } from "@util";
import IconButton from "@components/icon-button";
import DownloadIcon from "@material-ui/icons/GetApp";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import JsonEditor, { JsonEditorActions, JsonEditorModel } from "../json-editor";
import styles from "./styles";

export interface JsonViewModel extends JsonEditorModel {
  fileName?: string;
  showHeader?: boolean;
}

export interface JsonViewActions extends JsonEditorActions {
}

type Props = WithStyles<typeof styles> & JsonViewModel & JsonViewActions;

export const JsonView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    fileName = "",
    showHeader = true,
    json = "{}",
    ...otherProps
  } = props;

  const headerVisible = React.useMemo(() =>
      showHeader && !isEmptyString(fileName),
    [showHeader, fileName]);

  return (
    <div className={classnames("jsonView", className, classes.container)}>
      {headerVisible && (
        <div className={classnames("header", classes.header)}>
          <label className={classnames("fileName", classes.fileName)}>
            {fileName}
          </label>
          <a
            href={"data:text/plain;charset=utf-8," + encodeURIComponent(json)}
            download={fileName}
          >
            <IconButton
              className={classnames("downloadIconButton", classes.downloadIconButton)}
            >
              <DownloadIcon className={classnames("downloadIcon", classes.downloadIcon)}/>
            </IconButton>
          </a>
        </div>
      )}
      <JsonEditor
        {...otherProps}
        className={classnames("jsonEditor", classes.jsonEditor, {
          [classes.showHeader]: showHeader,
        })}
        readOnly={true}
        json={json}
      />
    </div>
  );
});

export default JsonView;
