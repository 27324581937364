import React from "react";
import classnames from "classnames";
import ErrorIcon from "@material-ui/icons/HighlightOff";
import QueueIcon from "@material-ui/icons/RadioButtonUnchecked";
import SuccessIcon from "@material-ui/icons/CheckCircleOutline";
import CircularProgress from "@components/circular-progress";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface MakeRequestListItemAttributes {
  id: string;
  label: string;
  loadingMessage?: string;
  showLoadingIndicator?: boolean;
  errorMessage?: string;
  showErrorView?: boolean;
  successMessage?: string;
  showSuccessView?: boolean;
}

export interface MakeRequestListItemProps extends MakeRequestListItemAttributes {
  className?: string;
}

type Props = WithStyles<typeof styles> & MakeRequestListItemProps;

export const MakeRequestListItem = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    label,
    loadingMessage = label,
    showLoadingIndicator,
    errorMessage = label,
    showErrorView,
    successMessage = label,
    showSuccessView,
  } = props;

  const Icon = React.useMemo(() => {
    if (showErrorView) {
      return ErrorIcon;
    } else if (showSuccessView) {
      return SuccessIcon;
    } else {
      return QueueIcon;
    }
  }, [showErrorView, showSuccessView]);

  const activeLabel = React.useMemo(() => {
    if (showLoadingIndicator) {
      return loadingMessage;
    } else if (showErrorView) {
      return errorMessage;
    } else if (showSuccessView) {
      return successMessage;
    } else {
      return label;
    }
  }, [
    showLoadingIndicator,
    loadingMessage,
    showErrorView,
    errorMessage,
    showSuccessView,
    successMessage,
    label,
  ]);

  return (
    <div
      className={classnames("makeRequestListItem", className, classes.container, {
        [classes.loading]: showLoadingIndicator,
        [classes.error]: showErrorView,
        [classes.success]: showSuccessView,
      })}
    >
      {showLoadingIndicator && (
        <CircularProgress
          className={classnames("loadingIndicator", classes.loadingIndicator)}
          size={24}
        />
      )}
      {!showLoadingIndicator && (
        <Icon
          className={classnames("icon", classes.icon, {
            [classes.errorIcon]: showErrorView,
            [classes.successIcon]: showSuccessView,
            [classes.queueIcon]: !showErrorView && !showSuccessView,
          })}
        />
      )}
      <label className={classnames("label", classes.label)}>
        {activeLabel}
      </label>
    </div>
  );
});

export default MakeRequestListItem;
