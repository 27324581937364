import { getPathToUser } from "@modules/userDetails/helpers";
import { getPathToService } from "@modules/securityServiceRegionalDetails/helpers";
import { getPathToGroup } from "@modules/securityGroupDetails/helpers";

export const getManageUserPolicyBaseUrl = (userId: string) =>
  `${getPathToUser(userId)}/policies`;

export const getAttachPoliciesToUserPath = (userId: string) =>
  `${getManageUserPolicyBaseUrl(userId)}/attach`;

export const getDetachPoliciesFromUserPath = (userId: string) =>
  `${getManageUserPolicyBaseUrl(userId)}/detach`;

export const getManageServicePolicyBaseUrl = (serviceId: string) =>
  `${getPathToService(serviceId)}/policies`;

export const getAttachPoliciesToServicePath = (serviceId: string) =>
  `${getManageServicePolicyBaseUrl(serviceId)}/attach`;

export const getDetachPoliciesFromServicePath = (serviceId: string) =>
  `${getManageServicePolicyBaseUrl(serviceId)}/detach`;

export const getManageGroupPolicyBaseUrl = (groupName: string) =>
  `${getPathToGroup(groupName)}/policies`;

export const getAttachPoliciesToGroupPath = (groupName: string) =>
  `${getManageGroupPolicyBaseUrl(groupName)}/attach`;

export const getDetachPoliciesFromGroupPath = (groupName: string) =>
  `${getManageGroupPolicyBaseUrl(groupName)}/detach`;
