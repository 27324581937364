import React from "react";
import { connect } from "react-redux";
import { noop } from "../../../util";
import { AppSchema } from "../../main/schemas";
import { ErrorView } from "../../../components";
import { getCurrentAccountId } from "../../main/selectors";
import { PropertyEditorActions } from "../actions/propertyEditor";
import { RemovePropertyActions } from "../actions/removeProperty";
import { PropertiesTableActions } from "../actions/propertiesTable";
import { JsonSchemaMetadata, JsonSchemaProperty } from "../../../data";
import { PropertiesTableSelectors } from "../selectors/propertiesTable";
import { Actions, Model, PropertiesEditor } from "../components/PropertiesEditor";
import SelectSchemaView from "./SelectSchemaView";
import PropertiesTable from "./PropertiesTable";

interface ContainerModel extends Model {
  showNotFoundError?: boolean;
  properties?: JsonSchemaProperty[];
  showSchemas?: boolean;
  accountId?: string;
  schemas?: JsonSchemaMetadata[];
  selectedSchemas?: JsonSchemaMetadata[];
}

interface ContainerActions extends Actions {
  showParent?: () => void;
  addProperty?: () => void;
  editProperty?: (property: JsonSchemaProperty) => void;
  removeProperty?: (property: JsonSchemaProperty) => void;
  cloneProperty?: (property: JsonSchemaProperty) => void;
  showChildProperty?: (property: JsonSchemaProperty) => void;
  setSelectedSchemas?: (schemas: JsonSchemaMetadata[]) => void;
}

const PropertiesEditorContainer = (props: ContainerModel & ContainerActions) => {

  const {
    showNotFoundError,
    properties,
    addProperty,
    removeProperty,
    showParent = noop,
    editProperty = noop,
    cloneProperty,
    showChildProperty = noop,
    showSchemas,
    accountId = "",
    schemas = [],
    selectedSchemas = [],
    setSelectedSchemas,
    ...remainingProps
  } = props;

  if (showNotFoundError) {

    if (typeof showParent === "function" && showParent !== noop) {
      showParent();
      return null;
    }

    const { breadcrumbs = [] } = props;

    const propertyName = breadcrumbs.slice().pop();

    return (
      <PropertiesEditor {...remainingProps} hideActions={true}>
        <ErrorView
          className="notFoundError"
          title="Not Found"
          message={`Unable to find '${propertyName}'`}
        />
      </PropertiesEditor>
    );
  }

  if (showSchemas) {
    return (
      <PropertiesEditor {...remainingProps} hideActions={true}>
        <SelectSchemaView />
      </PropertiesEditor>
    );
  }

  const onClickProperty = (property: JsonSchemaProperty) => {
    if (property.isBasicPropertyType()) {
      editProperty(property);
    } else {
      showChildProperty(property);
    }
  };

  return (
    <PropertiesEditor addProperty={addProperty} {...remainingProps}>
      <PropertiesTable
        properties={properties}
        addProperty={addProperty}
        editProperty={editProperty}
        removeProperty={removeProperty}
        cloneProperty={cloneProperty}
        onClickProperty={onClickProperty}
      />
    </PropertiesEditor>
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
  className: "schemaProperties",
  title: "Schema Properties",
  showNotFoundError: PropertiesTableSelectors.isNotFoundErrorVisible(state),
  breadcrumbs: PropertiesTableSelectors.getBreadcrumbs(state),
  properties: PropertiesTableSelectors.getJsonSchemaProperties(state),
  showSchemas: PropertiesTableSelectors.isSchemasTableVisible(state),
  accountId: getCurrentAccountId(state),
});

const mapDispatchToProps = (dispatch: any): ContainerActions => ({
  showParent: () => dispatch(PropertiesTableActions.goBack()),
  clearBreadcrumbs: () => dispatch(PropertiesTableActions.clearBreadcrumbs()),
  setBreadcrumbs: (breadcrumbs: string[]) =>
    dispatch(PropertiesTableActions.setBreadcrumbs(breadcrumbs)),
  addProperty: () => dispatch(PropertyEditorActions.open()),
  editProperty: (property: JsonSchemaProperty) => dispatch(PropertyEditorActions.open(property)),
  cloneProperty: (property: JsonSchemaProperty) => dispatch(PropertyEditorActions.openCloneProperty(property)),
  removeProperty: (property: JsonSchemaProperty) => dispatch(RemovePropertyActions.open(property)),
  showChildProperty: (property: JsonSchemaProperty) =>
    dispatch(PropertiesTableActions.showChildProperty(property.name)),
  setSelectedSchemas: (selectedSchemas: JsonSchemaMetadata[] = []) =>
    dispatch(PropertiesTableActions.setSelectedSchema(selectedSchemas.pop())),
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(PropertiesEditorContainer);
