import { connect } from "react-redux";
import { AppSchema } from "../../main/schemas";
import { Model, SchemaProperties } from "../../../components/schema-properties/SchemaProperties";
import { getSchemaMetaData, isLoadingIndicatorVisible } from "../selectors";

const mapStateToProps = (state: AppSchema): Model => {

  const metadata = getSchemaMetaData(state);

  const isLoading = isLoadingIndicatorVisible(state);

  return {
    className: "schemaProperties",
    title: "Properties",
    location: metadata.location,
    noResultsLabel: isLoading ? "Loading..." : "No Properties Defined",
    refreshEnabled: false,
  };
};

export default connect<Model, {}, Model>(
  mapStateToProps,
)(SchemaProperties);
