import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import DropdownMenu from "@components/dropdown-menu";
import Typography from "@material-ui/core/Typography";
import { AccessRequestPrincipalType, AccessRequestType } from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export type AccessRequestPrincipalTypeLabels<K extends keyof any = AccessRequestPrincipalType> = {
  [P in K]: string;
};

export const DEFAULT_PRINCIPAL_TYPES: AccessRequestPrincipalType[] = [
  AccessRequestPrincipalType.IOT_USER_PRINCIPAL,
  AccessRequestPrincipalType.IOT_SERVICE_PRINCIPAL,
  AccessRequestPrincipalType.IOT_DEVICE_TYPE,
  AccessRequestPrincipalType.IOT_CLOUD_ROLE,
  AccessRequestPrincipalType.IOT_CLOUD_STORAGE,
];

export const DEFAULT_ACCESS_REQUEST_TYPE_LABELS: AccessRequestPrincipalTypeLabels = {
  [AccessRequestPrincipalType.IOT_USER_PRINCIPAL]: "IoT User Principal (Current User)",
  [AccessRequestPrincipalType.IOT_SERVICE_PRINCIPAL]: "IoT Service Principal",
  [AccessRequestPrincipalType.IOT_DEVICE_TYPE]: "IoT Device Type",
  [AccessRequestPrincipalType.IOT_CLOUD_ROLE]: "IoT Cloud Role",
  [AccessRequestPrincipalType.IOT_CLOUD_USER]: "IoT Cloud User",
  [AccessRequestPrincipalType.IOT_CLOUD_STORAGE]: "IoT Cloud Storage",
};

export interface Model {
  principalType?: AccessRequestPrincipalType;
  principalTypes?: AccessRequestPrincipalType[];
  principalTypeLabels?: AccessRequestPrincipalTypeLabels;
}

export interface Actions {
  setPrincipalType?: (type: AccessRequestPrincipalType) => void;
  mapPrincipalTypeToLabel?: (type: AccessRequestPrincipalType) => React.ReactNode | string | null;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const PrincipalView = withStyles(styles)((props: Props) => {

  const {
    classes,
    principalType = AccessRequestPrincipalType.IOT_USER_PRINCIPAL,
    principalTypes = DEFAULT_PRINCIPAL_TYPES,
    principalTypeLabels = DEFAULT_ACCESS_REQUEST_TYPE_LABELS,
    setPrincipalType = noop,
    mapPrincipalTypeToLabel = React.useCallback((type: AccessRequestType) =>
      principalTypeLabels[type] || null, [principalTypeLabels]),
    children,
  } = props;

  return (
    <div className={classnames("principalView", classes.principalView)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        Set Request Type
      </Typography>
      <DropdownMenu
        className={classnames("principalType", classes.dropdownMenu)}
        hideEmptyValue={true}
        dropdownMenuLabel="Request Type"
        dropdownMenuLabelClassName={classes.dropdownMenuLabel}
        dropdownMenuHint="How will this data be accessed?"
        values={principalTypes}
        selectedValue={principalType}
        setSelectedValue={setPrincipalType}
        mapValueToLabel={mapPrincipalTypeToLabel}
      />
      {children}
    </div>
  );
});

export default PrincipalView;
