import React from "react";
import { noop } from "@util";
import { AuthTokenContext } from "@components";
import { AdministrationClient, RestClientError } from "@network";

export interface UseCreateAdminNoteProps {
  userId?: string;
  note?: string;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
}

export interface UseCreateAdminNoteModel {
  userId: string;
  note: string;
  errorMessage: string;
  successMessage: string;
  showAccessDenied: boolean;
  showLoadingIndicator: boolean;
}

export interface UseCreateAdminNoteActions {
  setNote: (value: string) => void;
  save: () => void;
}

type Props = UseCreateAdminNoteProps;
type Model = UseCreateAdminNoteModel;
type Actions = UseCreateAdminNoteActions;
type Result = [Model, Actions];

export const useCreateAdminNote = (props: Props): Result => {

  const {
    userId = "",
    note: initialValue = "",
    trackRequestEvent = noop,
    trackSuccessEvent = noop,
    trackErrorEvent = noop,
  } = props;

  const accessToken = React.useContext(AuthTokenContext);
  const [note, setNote] = React.useState(initialValue);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [showAccessDenied, setShowAccessDenied] = React.useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);

  const save = React.useCallback(() => {
    setErrorMessage("");
    setSuccessMessage("");
    setShowAccessDenied(false);
    setShowLoadingIndicator(true);
  }, [setErrorMessage, setSuccessMessage, setShowAccessDenied, setShowLoadingIndicator]);

  const createAdminNote = React.useCallback(() => {
    return AdministrationClient.createAdminNote(accessToken, userId, JSON.stringify({ note }));
  }, [accessToken, userId, note]);

  React.useEffect(() => {

    let ignore = false;

    if (showLoadingIndicator) {

      trackRequestEvent();

      createAdminNote()
        .then(() => {
          if (!ignore) {
            trackSuccessEvent();
            setSuccessMessage("Admin note created");
            setShowLoadingIndicator(false);
          }
        }, (response: RestClientError) => {
          if (!ignore) {
            const { analytic, status, error = "Create admin note failed" } = response;
            trackErrorEvent(analytic);
            setErrorMessage(error);
            if (status === 403) {
              setShowAccessDenied(true);
            }
            setShowLoadingIndicator(false);
          }
        });
    }

    return noop;

  }, [
    showLoadingIndicator,
    createAdminNote,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
  ]);

  const model: Model = React.useMemo(() => ({
    userId,
    note,
    errorMessage,
    successMessage,
    showAccessDenied,
    showLoadingIndicator,
  }), [
    userId,
    note,
    errorMessage,
    successMessage,
    showAccessDenied,
    showLoadingIndicator,
  ]);

  const actions: Actions = React.useMemo(() => ({
    setNote,
    save,
  }), [
    setNote,
    save,
  ]);

  return React.useMemo(() => [model, actions], [model, actions]);
};

export default useCreateAdminNote;
