import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      marginBottom: 15,
    },
    summaryView: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "stretch",
    },
    jsonView: {
    },
    jsonLabel: {},
    jsonValue: {
      display: "block",
      padding: 10,
      fontFamily: "monospace",
      fontWeight: 300,
      textAlign: "left",
      whiteSpace: "pre-wrap",
    },
    metadata: {
      alignSelf: "flex-start",
      flex: "1 0 auto",
    },
  });

export default styles;
