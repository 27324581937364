import { Module } from "@data";
import { initialize } from "./actions";
import { createModule } from "@modules/base";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { isAccessDeniedVisible, isEmptyViewVisible } from "./selectors";
import {
  getManageGroupServicesBaseUrl,
  getManageGroupUsersBaseUrl,
  getManageServiceGroupsBaseUrl,
  getManageUserGroupsBaseUrl,
} from "@modules/groupManagementWizard/helpers";
import {
  ManageGroupServicesView,
  ManageGroupUsersView,
  ManageServiceGroupsView,
  ManageUserGroupsView,
} from "./GroupManagementWizardView";
import Title from "./containers/Title";

type RouteParams = { id?: string; action?: "add" | "remove" };

export const manageGroupUsersModule: Module = createModule<RouteParams>({
  id: "manage_group_users",
  path: `${getManageGroupUsersBaseUrl(":id")}/:action`,
  exactPath: true,
  strictPath: true,
  name: "Manage Security Group Users",
  className: "manageGroupUsersModule",
  primaryColor,
  icon,
  view: ManageGroupUsersView,
  Title,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: ({ id: groupName, ...otherProps }: RouteParams = {}) =>
    initialize({ groupName, identityType: "user", ...otherProps }),
});

export const manageUserGroupsModule: Module = createModule<RouteParams>({
  id: "manage_user_groups",
  path: `${getManageUserGroupsBaseUrl(":id")}/:action`,
  exactPath: true,
  strictPath: true,
  name: "Manage User Security Groups",
  className: "manageUserGroupsModule",
  primaryColor,
  icon,
  view: ManageUserGroupsView,
  Title,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: ({ id: userId, ...otherProps }: RouteParams = {}) =>
    initialize({ userId, identityType: "user", ...otherProps }),
});

export const manageGroupServicesModule: Module = createModule<RouteParams>({
  id: "manage_group_services",
  path: `${getManageGroupServicesBaseUrl(":id")}/:action`,
  exactPath: true,
  strictPath: true,
  name: "Manage Security Group Services",
  className: "manageGroupServicesModule",
  primaryColor,
  icon,
  view: ManageGroupServicesView,
  Title,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: ({ id: groupName, ...otherProps }: RouteParams = {}) =>
    initialize({ groupName, identityType: "service", ...otherProps }),
});

export const manageServiceGroupsModule: Module = createModule<RouteParams>({
  id: "manage_service_groups",
  path: `${getManageServiceGroupsBaseUrl(":id")}/:action`,
  exactPath: true,
  strictPath: true,
  name: "Manage Service Security Groups",
  className: "manageServiceGroupsModule",
  primaryColor,
  icon,
  view: ManageServiceGroupsView,
  Title,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: ({ id: serviceId, ...otherProps }: RouteParams = {}) =>
    initialize({ serviceId, identityType: "service", ...otherProps }),
});
