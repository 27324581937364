import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import {
  refresh,
  setDeviceId,
  setErrorMessage,
  clearDevices,
  fetchDevice,
} from "../actions";
import {
  getDeviceId,
  isLoadingIndicatorVisible,
} from "../selectors";
import { Device, EnrollmentStatus } from "@data";
import Devices , { Actions, Model } from "../components/Devices";
import DevicesList from "./DevicesList";
import debounce from "lodash/debounce";
import { equalsIgnoreCase, getStringValue, noop } from "@util";

interface ContainerActions extends Actions {
  showDeviceDetails?: (device: Device) => void;
  showDeviceValidationDetails?: (device: Device) => void;
}

type Props = Model & ContainerActions;

const DevicesContainer = (props: Props) => {

  const {
    showDeviceDetails = noop,
    showDeviceValidationDetails = noop,
    ...otherProps
  } = props;

  const showMoreDetails = React.useCallback((device: Device) => {
    if (device.status !== EnrollmentStatus.VALIDATED) {
      showDeviceDetails(device);
    } else {
      showDeviceValidationDetails(device);
    }
  }, [showDeviceDetails, showDeviceValidationDetails]);

  return (
    <Devices {...otherProps}>
      <DevicesList
        showDeviceDetails={showMoreDetails}
      />
    </Devices>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  searchQuery: getDeviceId(state),
  loading: isLoadingIndicatorVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => {
  // Debounce the network request to fetch device types that match the search query as user types
  const search = debounce(() => dispatch(fetchDevice()), 500);

  return {
    updateSearchQuery: (searchQuery: string = "", previousSearchQuery: string = "") => {
      dispatch(clearDevices());
      dispatch(setErrorMessage());
      dispatch(setDeviceId(getStringValue(searchQuery)));

      if (!equalsIgnoreCase(searchQuery, previousSearchQuery)) {
        search();
      }
    },
    refresh: () => dispatch(refresh()),
    ...ownProps,
  };
};

export default connect<Model, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DevicesContainer);
