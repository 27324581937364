import React from "react";
import { noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import { Alert, AlertTitle, Button } from "@components";
import classnames from "classnames";

export enum AlertSeverity {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
  SUCCESS = "success",
}

export interface MissingAlertViewProps extends WithStyles<typeof styles> {
  className?: string;
  title?: string;
  message?: React.ReactNode;
  severity?: AlertSeverity;
  showAction?: boolean;
  actionLabel?: string;
  action?: () => void;
}

export const MissingAlertView = withStyles(styles)((props: MissingAlertViewProps) => {

  const {
    title = "",
    message = "",
    actionLabel = "",
    severity = AlertSeverity.INFO,
    showAction = true,
    action = noop,
    classes,
    className,
  } = props;

  return (
    <Alert
      className={classnames("missingAlertView", className, severity, classes.container)}
      severity={severity}
      action={showAction && (
        <Button color="inherit" size="small" onClick={action}>
          {actionLabel}
        </Button>
      )}
    >
      <AlertTitle> {title} </AlertTitle>
      {message}
    </Alert>
  );
});

export default MissingAlertView;
