import { combineReducers } from "redux";
import { MODULE_ID } from "../constants";
import {
  DeviceTypeRequestV2,
  DeviceTypeRequestV2Attributes,
  DeviceTypeRequestV3,
  DeviceTypeRequestV3Attributes,
  DeviceTypeWizardState,
  DeviceTypeWizardStateAttributes,
} from "../models";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@modules/base/createReducers";
import DeviceTypeModelVersion from "@data/DeviceTypeModelVersion";
import {
  DeviceTypeModelV3CredentialEncoding,
  DeviceTypeModelV3CredentialPersistence,
  DeviceTypeModelV3CredentialType,
} from "@data";
import isDeviceTypeV2APIEnabled from "@util/isDeviceTypeV2APIEnabled";

export enum DeviceTypeWizardStep {
  NONE = "",
  API_VERSION = "api_version",
  NAMESPACE = "namespace",
  NAME  = "name",
  CONNECTIVITY = "connectivity",
  GROUPS = "groups",
  SCHEMAS = "schemas",
  SECRET = "secret",
  CREDENTIALS = "credentials",
  EDITOR = "editor",
  REVIEW = "review",
}

export const DEFAULT_CREDENTIAL_DEFINITION = {
  name: "",
  encoding: DeviceTypeModelV3CredentialEncoding.PEM,
  persistence: DeviceTypeModelV3CredentialPersistence.MUTABLE,
  type: DeviceTypeModelV3CredentialType.CERTIFICATE,
  trustedCmsAuthorityIds: [],
};

export interface DeviceTypeWizardSchema extends BasePortalModuleSchema {
  modelVersion: DeviceTypeModelVersion;
  namespace: string;
  name: string;
  version: string;
  json: string;
  deviceType: DeviceTypeRequestV3Attributes;
  deviceTypeV2: DeviceTypeRequestV2Attributes;
  deviceTypeWizardStep: DeviceTypeWizardStep;
  showEditMode: boolean;
  showEditModeInitializeError: boolean;
  showCloneMode: boolean;
  deviceTypeRef: string;
  etag: string;
  defaultState: DeviceTypeWizardStateAttributes;
}

export const DEFAULT_STATE: DeviceTypeWizardSchema = createPortalModuleDefaultState({
  modelVersion: DeviceTypeModelVersion.REGIONAL,
  showEmptyView: false,
  namespace: "",
  name: "",
  version: "",
  json: JSON.stringify(DeviceTypeRequestV3.EMPTY.toJS(), null, "  "),
  deviceType: { ...DeviceTypeRequestV3.EMPTY.toJS() },
  deviceTypeV2: { ...DeviceTypeRequestV2.EMPTY.toJS() },
  deviceTypeWizardStep: isDeviceTypeV2APIEnabled()
    ? DeviceTypeWizardStep.API_VERSION
    : DeviceTypeWizardStep.NAMESPACE,
  showEditMode: false,
  showEditModeInitializeError: false,
  showCloneMode: false,
  deviceTypeRef: "",
  etag: "",
  defaultState: { ...DeviceTypeWizardState.EMPTY.toJS() },
});

type DeviceTypeWizardActionTypes = PortalModuleActionTypes<DeviceTypeWizardSchema> & {
  CREATE_DEVICE_TYPE_REQUEST: string;
  CREATE_DEVICE_TYPE_SUCCESS: string;
  CREATE_DEVICE_TYPE_FAILED: string;
  FETCH_DEVICE_TYPE_REQUEST: string;
  FETCH_DEVICE_TYPE_SUCCESS: string;
  FETCH_DEVICE_TYPE_FAILED: string;
  EDIT_DEVICE_TYPE_REQUEST: string;
  EDIT_DEVICE_TYPE_SUCCESS: string;
  EDIT_DEVICE_TYPE_FAILED: string;
};

export const ACTION_TYPES: DeviceTypeWizardActionTypes = {
  ...createPortalModuleActionTypes<DeviceTypeWizardSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    CREATE_DEVICE_TYPE_REQUEST: `${MODULE_ID}/create_device_type_request`,
    CREATE_DEVICE_TYPE_SUCCESS: `${MODULE_ID}/create_device_type_success`,
    CREATE_DEVICE_TYPE_FAILED: `${MODULE_ID}/create_device_type_failed`,
    FETCH_DEVICE_TYPE_REQUEST: `${MODULE_ID}/fetch_device_type_request`,
    FETCH_DEVICE_TYPE_SUCCESS: `${MODULE_ID}/fetch_device_type_success`,
    FETCH_DEVICE_TYPE_FAILED: `${MODULE_ID}/fetch_device_type_failed`,
    EDIT_DEVICE_TYPE_REQUEST: `${MODULE_ID}/edit_device_type_request`,
    EDIT_DEVICE_TYPE_SUCCESS: `${MODULE_ID}/edit_device_type_success`,
    EDIT_DEVICE_TYPE_FAILED: `${MODULE_ID}/edit_device_type_failed`,
  },
};

export const DeviceTypeWizardReducer = combineReducers<DeviceTypeWizardSchema>(
  createPortalModuleReducers<DeviceTypeWizardSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DeviceTypeWizardReducer;
