import { Record } from "immutable";

interface SetWorkloadSecretRequestAttributes {
  secret: string;
}

export class SetWorkloadSecretRequest extends Record({
  secret: "",
}) implements SetWorkloadSecretRequestAttributes {

  public static EMPTY: SetWorkloadSecretRequest = new SetWorkloadSecretRequest();

  public readonly secret: string;

  public getSecret(): string {
    return this.secret;
  }
}
