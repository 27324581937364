import React from "react";
import classnames from "classnames";
import camelCase from "lodash/camelCase";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles, tableHeaderCell, tableHeaderRow } from "./styles";
import { TableHeaderRowData } from "@data";
import { isEmptyString } from "@util";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const TableHeaderRow = withStyles(tableHeaderRow)(TableRow);
const TableHeaderCell = withStyles(tableHeaderCell)(TableCell);

export interface TableHeaderRowModel {
  className?: string;
  items?: TableHeaderRowData[];
  showActionsColumn?: boolean;
  children?: React.ReactNode;
}

type Props = WithStyles<typeof styles> & TableHeaderRowModel;

export const TableHeaderRowView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    items = [],
    showActionsColumn,
    children,
  } = props;

  return (
    <TableHeaderRow className={classnames("tableHeaderRow", className)}>
    {items.map((item: TableHeaderRowData) => isEmptyString(item.getLabel()) ? null : (
        <TableHeaderCell
          key={`table-header-label${camelCase(item.getLabel())}`}
          className={classnames(camelCase(item.getLabel()), classes.label)}
        >
          {item.getLabel()}
        </TableHeaderCell>
    ))}
    {showActionsColumn && (
      <TableHeaderCell className={classnames("actions", classes.actionsColumn)}>
        Actions
      </TableHeaderCell>
    )}
        {children}
      </TableHeaderRow>
  );
});

export default TableHeaderRowView;
