import React from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { useDeviceDetailsQueryParams } from "@hooks";
import DeviceDetailsTab, {
  DeviceDetailsTabsType
} from "./components/DeviceDetailsTabs";
import {
  getPathToDeviceBootstrapView,
  getPathToDeviceConfigurationView,
  getPathToDeviceEnrollmentView,
  getPathToDeviceValidationDetailsView
} from "@modules/deviceDetails/helpers";

export function DeviceDetailsTabsView() {

  const history = useHistory();

  const { selectedTab } = useDeviceDetailsQueryParams();

  const { id: deviceId } = useParams<{ id: string }>();

  const location = useLocation<any>();

  const deviceRequests = location.state ? location.state.deviceRequests : [];

  const showEnrollmentView = React.useCallback(() =>
    history.push(getPathToDeviceEnrollmentView(deviceId), {
      deviceRequests
    }), [history, deviceRequests]);

  const showConfigurationView = React.useCallback(() =>
    history.push(getPathToDeviceConfigurationView(deviceId), {
      deviceRequests
    }), [history, deviceRequests]);

  const showBootstrapView = React.useCallback(() =>
    history.push(getPathToDeviceBootstrapView(deviceId), {
      deviceRequests
    }), [history, deviceRequests]);

  if (selectedTab === DeviceDetailsTabsType.VALIDATION) {
    return <Redirect to={getPathToDeviceValidationDetailsView(deviceId)}/>;
  }

  // Do not render the tabs component if we do not recognize the selected tab value
  if (selectedTab === null) {
    return null;
  }

  return (
    <DeviceDetailsTab
      selectedTab={selectedTab}
      showEnrollmentView={showEnrollmentView}
      showConfigurationView={showConfigurationView}
      showBootstrapView={showBootstrapView}
    />
  );
}

export default DeviceDetailsTabsView;
