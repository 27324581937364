import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    title: {
      fontSize: 14,
      textAlign: "center",
      fontWeight: 500,
      padding: "15px 10px",
      lineHeight: "1",
      color: Color.TEXT,
      backgroundColor: Color.LIGHT_GREY6,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      border: `1px solid ${Color.GREY1}`,
    },
    content: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      padding: "4px 8px",
      margin: 0,
      borderLeft: `1px solid ${Color.GREY1}`,
      borderRight: `1px solid ${Color.GREY1}`,
      borderBottom: `1px solid ${Color.GREY1}`,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    },
    row: {
      flex: "1 0 auto",
      marginTop: 15,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      "&:first-child": {
        marginTop: 8,
      },
    },
    severityIcon: {
      flex: "0 0 20px",
      width: 20,
      height: 20,
    },
    blocker: {
      color: Color.RED,
    },
    critical: {
      color: Color.RED2,
    },
    major: {
      color: Color.RED3,
    },
    minor: {
      color: Color.YELLOW2,
    },
    info: {
      color: Color.GREY6,
    },
    severityLabel: {
      flex: "0 0 50px",
      color: Color.TEXT,
      marginLeft: 6,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "1",
    },
    severityBar: {
      flex: "1 0 auto",
      alignSelf: "stretch",
      marginLeft: 5,
      marginRight: 10,
      // TODO: Clip path transition would need to applied after first render in order to see animation
      //       with a useEffect or useLayoutEffect
      // clipPath: "inset(0% 0% 0% 0%)",
      // transition: "clip-path 1s",
      "&$blocker": {
        backgroundColor: Color.RED,
      },
      "&$critical": {
        backgroundColor: Color.RED2,
      },
      "&$major": {
        backgroundColor: Color.RED3,
      },
      "&$minor": {
        backgroundColor: Color.YELLOW2,
      },
      "&$info": {
        backgroundColor: Color.GREY6,
      },
    },
    severityCountLabel: {
      flex: "0 0 auto",
      marginLeft: "auto",
      color: Color.TEXT,
      fontSize: 12,
      fontWeight: 300,
      lineHeight: "1",
    },
    trendingIcon: {
      flex: "0 0 auto",
      marginLeft: 6,
      width: 24,
      height: 24,
    },
    trendingUp: {
      color: Color.RED,
    },
    trendingFlat: {
      color: Color.GREY4,
    },
    trendingDown: {
      color: Color.SIGNIFY_GREEN3,
    },
    trendingDeltaLabel: {
      // TODO: Looks bad when there are flat trends, so maybe consider showing delta as a tooltip
      display: "none",
      flex: "0 0 auto",
      marginLeft: 6,
      fontSize: 11,
      fontWeight: 300,
      lineHeight: "1",
    },
    footer: {
      marginTop: 15,
      marginBottom: 10,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    totalCountPrefix: {
      color: Color.BLACK,
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "1",
    },
    totalCountSuffix: {
      marginLeft: 4,
      color: Color.TEXT,
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "1",
    },
  });

export default styles;
