// function appendScript(trackerId) {
//   var s = document.createElement("script");
//   s.id = "gtagjs";
//   s.async = true;
//   s.src = "https://www.googletagmanager.com/gtag/js?id=" + trackerId;
//   var n = document.getElementsByTagName("head")[0];
//   if (!n) n = document.body;
//   n.appendChild(s);
// }
//
// export function loadGa(trackerId, opts = {}) {
//   var w = window;
//   if (w.gtag) return w.gtag;
//   appendScript(trackerId);
//   w.dataLayer = w.dataLayer || [];
//   var gtag = w.gtag = w.gtag || function () {
//     w.dataLayer.push(arguments)
//   };
//   gtag("js", new Date());
//   gtag("config", trackerId, opts);
//   return gtag;
// }
export function loadGa() {
  return () => {
    /* no-op */
  };
}
