import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";

export enum DeviceTypeModelV3CredentialEncoding {
  NONE = "",
  BASE64 = "BASE64",
  PEM = "PEM",
}

export enum DeviceTypeModelV3CredentialPersistence {
  NONE = "",
  IMMUTABLE = "IMMUTABLE",
  MUTABLE = "MUTABLE",
}

export enum DeviceTypeModelV3CredentialType {
  NONE = "",
  HMAC_SECRET = "HMAC_SECRET",
  CERTIFICATE = "CERTIFICATE",
  USER_DEFINED = "USER_DEFINED",
}

export interface DeviceTypeModelV3DefinitionAttributes {
  name: string;
  type: DeviceTypeModelV3CredentialType;
  encoding: DeviceTypeModelV3CredentialEncoding;
  persistence: DeviceTypeModelV3CredentialPersistence;
  trustedCmsAuthorityIds: string[];
}

export class DeviceTypeModelV3Definition extends Record({
  name: "",
  type: DeviceTypeModelV3CredentialType.CERTIFICATE,
  encoding: DeviceTypeModelV3CredentialEncoding.PEM,
  persistence: DeviceTypeModelV3CredentialPersistence.MUTABLE,
  trustedCmsAuthorityIds: [],
}) implements DeviceTypeModelV3DefinitionAttributes {

  public static EMPTY: DeviceTypeModelV3Definition = new DeviceTypeModelV3Definition();

  public readonly name: string;
  public readonly type: DeviceTypeModelV3CredentialType;
  public readonly encoding: DeviceTypeModelV3CredentialEncoding;
  public readonly persistence: DeviceTypeModelV3CredentialPersistence;
  public readonly trustedCmsAuthorityIds: string[];

  public getName(): string {
    return getStringValue(this.name);
  }

  public getType(): DeviceTypeModelV3CredentialType {
    return this.type || DeviceTypeModelV3CredentialType.NONE;
  }

  public getEncoding(): DeviceTypeModelV3CredentialEncoding {
    return this.encoding || DeviceTypeModelV3CredentialEncoding.NONE;
  }

  public getPersistence(): DeviceTypeModelV3CredentialPersistence {
    return this.persistence || DeviceTypeModelV3CredentialPersistence.NONE;
  }

  public getTrustedCmsAuthorityIds(): string[] {
    if (DeviceTypeModelV3CredentialType.CERTIFICATE !== this.getType()) {
      return [];
    }

    const trustedCmsAuthorityIds =
      Array.isArray(this.trustedCmsAuthorityIds) ? this.trustedCmsAuthorityIds : [];

    return trustedCmsAuthorityIds.map(getStringValue).filter(value => !isEmptyString(value));
  }

  public hasTrustedCmsAuthorityIds(): boolean {
    return this.getTrustedCmsAuthorityIds().length > 0;
  }
}

export default DeviceTypeModelV3Definition;
