import { StyleRules } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Color from "../styles/Color";

export const INFORMATION_BANNER_HEIGHT = 125;

export const warningIcon: StyleRules<string> = {
  root: {
    color: Color.MUSTARD,
    flex: "0 0 auto",
    width: 42,
    height: 42,
  },
};

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      padding: 10,
      backgroundColor: "#9E9E9E",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      height: INFORMATION_BANNER_HEIGHT,
      zIndex: 9999,
      borderBottom: "1px solid #fff",
    },
    informationBannerText: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 20,
      lineHeight: "22px",
      textAlign: "center",
    },
    link: {
      color: Color.WHITE,
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "20px",
      textDecoration: "underline",
    },
    label: {
      margin: "8px 0 0 0",
    },
  });

export default styles;
