import React from "react";
import { noop } from "@util";
import { AccessRequestType } from "@data";
import { useListAccessibleDataSets } from "@hooks";
import DataSetsView from "../components/DataSetsView";
import { getPathToDataSet } from "@modules/dataSetDetails/helpers";
import { CreateWorkloadRequest } from "@data/CreateWorkloadRequest";
import { getDataSetId } from "@modules/dataWorkloadWizard/helpers";
import { DataSetsListItemData, DataSetsListActions, DataSetsListModel } from "@components";

interface Model extends DataSetsListModel {
  title?: string;
  data?: CreateWorkloadRequest;
}

interface Actions extends DataSetsListActions {
  setWorkloadData?: (data: CreateWorkloadRequest) => void;
  showDataSetDetailsInNewTab: (dataSet: DataSetsListItemData) => string;
}

type Props = Model & Actions;

const DataSourcesView = (props: Props) => {

  const {
    className = "dataSourcesView",
    summaryViewLabel = "Data Set Inputs",
    title = "Select data set input(s) required by this workload",
    selectedItems = [],
    data = CreateWorkloadRequest.EMPTY,
    setWorkloadData = noop,
    showDataSetDetailsInNewTab,
    ...otherProps
  } = props;

  const [{ accessibleDataSets: dataSources, ...dataSourcesModel }, dataSourcesActions] =
    useListAccessibleDataSets({ accessRequestType: AccessRequestType.READ });

  React.useEffect(() => {
    setWorkloadData({
      data: {
        ...data.getData(),
        inputs: selectedItems.map(({name: dataSetAlias, account: accountId}) => ({ accountId, dataSetAlias })),
      },
    });
  }, [selectedItems]);

  const sources = React.useMemo(() => {
    return data.isQueryType() ? dataSources.filter(
      dataSet => dataSet.isDataWarehouseDataSetType()) : dataSources;
  }, [data, dataSources]);

  const items = React.useMemo(() =>
    sources.map(dataSet => new DataSetsListItemData({
      id: getDataSetId(dataSet.accountId, dataSet.dataSetAlias),
      name: dataSet.dataSetAlias,
      account: dataSet.accountId,
      dataSetType: dataSet.dataSetType,
      href: getPathToDataSet(getDataSetId(dataSet.accountId, dataSet.dataSetAlias)),
    })), [sources]);

  return (
    <DataSetsView
      {...otherProps}
      {...dataSourcesModel}
      {...dataSourcesActions}
      className={className}
      title={title}
      items={items}
      selectedItems={selectedItems}
      summaryViewLabel={summaryViewLabel}
      onClickShowMoreInfoInNewTab={showDataSetDetailsInNewTab}
    />
  );
};

export default DataSourcesView;
