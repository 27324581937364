import React from "react";
import classnames from "classnames";
import ConfirmationDialog, {
  ConfirmationDialogActions,
  ConfirmationDialogModel,
} from "@components/confirmation-dialog";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface DeleteItemDialogModel<Item> extends ConfirmationDialogModel<Item> {
  className?: string;
  dialogClassName?: string;
  dialogTitle?: string;
  title?: string;
  subtitle?: string;
  showWarning?: boolean;
}

export type DeleteItemDialogActions<Item> = ConfirmationDialogActions<Item>;

export type Props<Item> = WithStyles<typeof styles> & DeleteItemDialogModel<Item> & DeleteItemDialogActions<Item>;

export const DeleteItemDialog = withStyles(styles)(<Item, >(props: Props<Item>) => {

  const {
    classes,
    className,
    dialogClassName,
    dialogTitle = "Please confirm this decision",
    title = "Are you sure you want to delete this?",
    subtitle = "This action cannot be undone!",
    showWarning = true,
    ...otherProps
  } = props;

  return (
    <ConfirmationDialog
      {...otherProps}
      className={classnames("deleteItemDialog", dialogClassName)}
      title={dialogTitle}
    >
      <div className={classnames("deleteItem", className, classes.container)}>
        <label className={classnames("title", classes.title)}>
          {title}
        </label>
        <label className={classnames("subtitle", classes.subtitle)}>
          {showWarning && (<span className={classes.warning}>WARNING:</span>)}
          {subtitle}
        </label>
      </div>
    </ConfirmationDialog>
  );
});

export default DeleteItemDialog;
