import React from "react";
import classnames from "classnames";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { fileUploadView as styles } from "./styles";
import { noop } from "@util";
import { useDropzone } from "react-dropzone";

export interface Model {
  title?: string;
  isFileUploaded?: boolean;
  errorMessage?: string;
  children?: React.ReactNode;
}

export interface Actions {
  setFile?: (file: File) => void;
  setErrorMessage?: (error: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const FileUploadView = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Upload File",
    errorMessage,
    isFileUploaded = false,
    setFile = noop,
    setErrorMessage = noop,
    children
  } = props;

  const onSelectFile = React.useCallback(event => {

    const selectedFile = event && event.target && event.target.files
      ? event.target.files[0] : undefined;

    if (!selectedFile) {
      return setErrorMessage("Invalid File");
    }

    setFile(selectedFile);

  }, [setFile, setErrorMessage]);

  const onDrop = React.useCallback(([selectedFile]) => {

    if (!selectedFile) {
      return setErrorMessage("Invalid File");
    }

    setFile(selectedFile);

  }, [setFile, setErrorMessage]);

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    preventDropOnDocument: true,
  });

  return (
    <div className={classnames("fileUploadView", classes.container)}>
      <label className={classnames("title", classes.title)}>
        {title}
      </label>
        <form
          {...getRootProps()}
          className={classnames("uploadContent", classes.content, {
            [classes.contentDragging]: isDragActive,
            [classes.contentSelected]: isFileUploaded && !isDragActive,
          })}
        >
          {!isFileUploaded  && (
            <label className={classes.label}>Select a file to upload</label>
          )}
          {isFileUploaded && (
            <label className={classes.label}>Click next to proceed <br/>Click here to upload different file</label>
          )}
          {!errorMessage && (
            <input {...getInputProps()} type="file" onChange={onSelectFile} />
          )}
        </form>
        {children}
      </div>
  );
});

export default FileUploadView;
