import React from "react";
import classnames from "classnames";
import TextField from "@components/text-field";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { withTextFieldTooltip } from "@components/tooltip/TextFieldTooltip";
import { enterKeyHandler, formEventHandler, noop } from "../../../../util";
import { styles, textField } from "./styles";

export const MINIMUM_TOOLTIP = "A number is valid against this keyword if is greater than, " +
  "or equal to, the value of this keyword";

export const EXCLUSIVE_MINIMUM_TOOLTIP = "A number is valid against this keyword if is " +
  "strictly greater than the value of this keyword.";

export const MAXIMUM_TOOLTIP = "A number is valid against this keyword if is less than, " +
  "or equal to, the value of this keyword.";

export const EXCLUSIVE_MAXIMUM_TOOLTIP = "A number is valid against this keyword if is " +
  "strictly less than the value of this keyword.";

export const MULTIPLE_OF_TOOLTIP = "A number is valid against this keyword if the division " +
  "between the number and the the value of this keyword results in an integer. " +
  "Value of this keyword must be a strictly positive number (zero is not allowed).";

const NumberConstraintTextField = withStyles(textField)(TextField);
const MinimumTextField = withTextFieldTooltip(MINIMUM_TOOLTIP)(NumberConstraintTextField);
const ExclusiveMinimumTextField = withTextFieldTooltip(EXCLUSIVE_MINIMUM_TOOLTIP)(NumberConstraintTextField);
const MaximumTextField = withTextFieldTooltip(MAXIMUM_TOOLTIP)(NumberConstraintTextField);
const ExclusiveMaximumTextField = withTextFieldTooltip(EXCLUSIVE_MAXIMUM_TOOLTIP)(NumberConstraintTextField);
const MultipleOfTextField = withTextFieldTooltip(MULTIPLE_OF_TOOLTIP)(NumberConstraintTextField);

export interface Model {
  className?: string;
  minimum?: string;
  minimumError?: string;
  exclusiveMinimum?: string;
  exclusiveMinimumError?: string;
  maximum?: string;
  maximumError?: string;
  exclusiveMaximum?: string;
  exclusiveMaximumError?: string;
  multipleOf?: string;
  multipleOfError?: string;
  disabled?: boolean;
}

export interface Actions {
  setMinimum?: (value: string) => void;
  setExclusiveMinimum?: (value: string) => void;
  setMaximum?: (value: string) => void;
  setExclusiveMaximum?: (value: string) => void;
  setMultipleOf?: (value: string) => void;
  save?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const NumericConstraintsEditor = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    minimum = "",
    minimumError = "",
    exclusiveMinimum = "",
    exclusiveMinimumError = "",
    maximum = "",
    maximumError = "",
    exclusiveMaximum = "",
    exclusiveMaximumError = "",
    multipleOf = "",
    multipleOfError = "",
    disabled = false,
    setMinimum = noop,
    setExclusiveMinimum = noop,
    setMaximum = noop,
    setExclusiveMaximum = noop,
    setMultipleOf = noop,
    save = noop,
  } = props;

  const formHelperTextProps = {
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  return (
    <div className={classnames("numericConstraintsEditor", className, classes.container)}>
      <div className={classes.inlineContainer}>
        <MinimumTextField
          className={classnames("minimum", classes.inlineInputField)}
          label="Minimum (optional)"
          placeholder="Minimum value (optional)"
          name="minimum"
          value={minimum}
          helperText={minimumError}
          error={minimumError.length > 0}
          onChange={formEventHandler(setMinimum)}
          onKeyDown={enterKeyHandler(save)}
          autoComplete="off"
          fullWidth={false}
          disabled={disabled}
          variant="outlined"
          margin="none"
          FormHelperTextProps={formHelperTextProps}
          InputLabelProps={inputLabelProps}
        />
        <ExclusiveMinimumTextField
          className={classnames("exclusiveMinimum", classes.inlineInputField)}
          label="Exclusive Minimum (optional)"
          placeholder="Exclusive minimum value (optional)"
          name="exclusiveMinimum"
          value={exclusiveMinimum}
          helperText={exclusiveMinimumError}
          error={exclusiveMinimumError.length > 0}
          onChange={formEventHandler(setExclusiveMinimum)}
          onKeyDown={enterKeyHandler(save)}
          autoComplete="off"
          fullWidth={false}
          disabled={disabled}
          variant="outlined"
          margin="none"
          FormHelperTextProps={formHelperTextProps}
          InputLabelProps={inputLabelProps}
        />
      </div>
      <div className={classes.inlineContainer}>
        <MaximumTextField
          className={classnames("maximum", classes.inlineInputField)}
          label="Maximum (optional)"
          placeholder="Maximum value (optional)"
          name="maximum"
          value={maximum}
          helperText={maximumError}
          error={maximumError.length > 0}
          onChange={formEventHandler(setMaximum)}
          onKeyDown={enterKeyHandler(save)}
          autoComplete="off"
          fullWidth={false}
          disabled={disabled}
          variant="outlined"
          margin="none"
          FormHelperTextProps={formHelperTextProps}
          InputLabelProps={inputLabelProps}
        />
        <ExclusiveMaximumTextField
          className={classnames("exclusiveMaximum", classes.inlineInputField)}
          label="Exclusive Maximum (optional)"
          placeholder="Exclusive maximum value (optional)"
          name="exclusiveMaximum"
          value={exclusiveMaximum}
          helperText={exclusiveMaximumError}
          error={exclusiveMaximumError.length > 0}
          onChange={formEventHandler(setExclusiveMaximum)}
          onKeyDown={enterKeyHandler(save)}
          autoComplete="off"
          fullWidth={false}
          disabled={disabled}
          variant="outlined"
          margin="none"
          FormHelperTextProps={formHelperTextProps}
          InputLabelProps={inputLabelProps}
        />
      </div>
      <MultipleOfTextField
        className={classnames("multipleOf", classes.inputField)}
        label="Multiple Of (optional)"
        placeholder="Number this property must be divisible by (optional)"
        name="multipleOf"
        value={multipleOf}
        helperText={multipleOfError}
        error={multipleOfError.length > 0}
        onChange={formEventHandler(setMultipleOf)}
        onKeyDown={enterKeyHandler(save)}
        autoComplete="off"
        fullWidth={true}
        disabled={disabled}
        variant="outlined"
        margin="none"
        FormHelperTextProps={formHelperTextProps}
        InputLabelProps={inputLabelProps}
      />
    </div>
  );
});

export default NumericConstraintsEditor;
