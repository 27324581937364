import { combineReducers } from "redux";
import { DeviceTypeListItem, DeviceTypeListItemAttributes } from "@data";
import { Actions, filterAction, Identity } from "@util";

export enum DeviceTypeAction {
  NONE = "",
  CLONE = "clone_device_type",
  EDIT = "edit_device_type",
  DRAFT = "draft_device_type",
  PROMOTE = "promote_device_type",
  DEPRECATE = "deprecate_device_type",
  DECOMMISSION = "decommission_device_type",
  DELETE = "delete_device_type",
  ENROLL_DEVICE = "enroll_device",
}

export interface DeviceTypeManagerSchema {
  deviceType: DeviceTypeListItemAttributes;
  deviceTypeAction: DeviceTypeAction;
  errorMessage: string;
  successMessage: string;
  showDialog: boolean;
  showAccessDenied: boolean;
  showProgressIndicator: boolean;
}

export const DEFAULT_STATE: DeviceTypeManagerSchema = Object.freeze({
  deviceType: Object.freeze({ ...DeviceTypeListItem.EMPTY.toJS() }),
  deviceTypeAction: DeviceTypeAction.NONE,
  errorMessage: "",
  successMessage: "",
  showDialog: false,
  showAccessDenied: false,
  showProgressIndicator: false,
});

export enum DeviceTypeManagerActionType {
  SET_DEVICE_TYPE = "device_type_manager/set_device_type",
  SET_DEVICE_TYPE_ACTION = "device_type_manager/set_device_type_action",
  SET_ERROR_MESSAGE = "device_type_manager/set_error_message",
  SET_SUCCESS_MESSAGE = "device_type_manager/set_success_message",
  SET_SHOW_DIALOG = "device_type_manager/set_show_dialog",
  SET_SHOW_ACCESS_DENIED = "device_type_manager/set_show_access_denied",
  SET_SHOW_PROGRESS_INDICATOR = "device_type_manager/set_show_progress_indicator",
  PROMOTE_DEVICE_TYPE_REQUEST = "device_type_manager/promote_device_type_request",
  PROMOTE_DEVICE_TYPE_REQUEST_SUCCESS = "device_type_manager/promote_device_type_request_success",
  PROMOTE_DEVICE_TYPE_REQUEST_FAILED = "device_type_manager/promote_device_type_request_failed",
  DEPRECATE_DEVICE_TYPE_REQUEST = "device_type_manager/deprecate_device_type_request",
  DEPRECATE_DEVICE_TYPE_REQUEST_SUCCESS = "device_type_manager/deprecate_device_type_request_success",
  DEPRECATE_DEVICE_TYPE_REQUEST_FAILED = "device_type_manager/deprecate_device_type_request_failed",
  DECOMMISSION_DEVICE_TYPE_REQUEST = "device_type_manager/decommission_device_type_request",
  DECOMMISSION_DEVICE_TYPE_REQUEST_SUCCESS = "device_type_manager/decommission_device_type_request_success",
  DECOMMISSION_DEVICE_TYPE_REQUEST_FAILED = "device_type_manager/decommission_device_type_request_failed",
  DELETE_DEVICE_TYPE_REQUEST = "device_type_manager/delete_device_type_request",
  DELETE_DEVICE_TYPE_REQUEST_SUCCESS = "device_type_manager/delete_device_type_request_success",
  DELETE_DEVICE_TYPE_REQUEST_FAILED = "device_type_manager/delete_device_type_request_failed",
  DRAFT_NEW_DEVICE_TYPE_VERSION_REQUEST = "device_type_manager/draft_new_device_type_version_request",
  DRAFT_NEW_DEVICE_TYPE_VERSION_REQUEST_SUCCESS = "device_type_manager/draft_new_device_type_version_request_success",
  DRAFT_NEW_DEVICE_TYPE_VERSION_REQUEST_FAILED = "device_type_manager/draft_new_device_type_version_request_failed",
}

const deviceType = filterAction<DeviceTypeListItemAttributes>(Actions(
  DeviceTypeManagerActionType.SET_DEVICE_TYPE))(Identity,
  DEFAULT_STATE.deviceType);

const deviceTypeAction = filterAction<DeviceTypeAction>(Actions(
  DeviceTypeManagerActionType.SET_DEVICE_TYPE_ACTION))(Identity,
  DEFAULT_STATE.deviceTypeAction);

const errorMessage = filterAction<string>(Actions(
  DeviceTypeManagerActionType.SET_ERROR_MESSAGE))(Identity,
  DEFAULT_STATE.errorMessage);

const successMessage = filterAction<string>(Actions(
  DeviceTypeManagerActionType.SET_SUCCESS_MESSAGE))(Identity,
  DEFAULT_STATE.successMessage);

const showDialog = filterAction<boolean>(Actions(
  DeviceTypeManagerActionType.SET_SHOW_DIALOG))(Identity,
  DEFAULT_STATE.showDialog);

const showAccessDenied = filterAction<boolean>(Actions(
  DeviceTypeManagerActionType.SET_SHOW_ACCESS_DENIED))(Identity,
  DEFAULT_STATE.showAccessDenied);

const showProgressIndicator = filterAction<boolean>(Actions(
  DeviceTypeManagerActionType.SET_SHOW_PROGRESS_INDICATOR))(Identity,
  DEFAULT_STATE.showProgressIndicator);

export const DeviceTypeManagerReducer = combineReducers<DeviceTypeManagerSchema>({
  deviceType,
  deviceTypeAction,
  errorMessage,
  successMessage,
  showAccessDenied,
  showDialog,
  showProgressIndicator,
});

export default DeviceTypeManagerReducer;
