import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    summaryViewContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    refreshButton: {
      color: Color.SIGNIFY_GREEN,
    },
    controls: {
      flex: "1 0 auto",
      display: "inline-flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    createButton: {
      marginLeft: "auto",
      padding: "6px 16px",
    },
    search: {
      marginLeft: 6,
      marginTop: 15,
      "& input": {
        color: Color.TEXT
      },
    },
  });

export default styles;
