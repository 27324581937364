import AppSchema from "@schemas";
import IdentityType from "@data/IdentityType";
import LocalStorageData from "./LocalStorageData";
import SessionStorageData from "./SessionStorageData";
import LocalStorageClient from "@util/LocalStorageClient";
import SessionStorageClient from "@util/SessionStorageClient";
import { isEmptyString, isValidInteger, isValidJson } from "@util";
import {
  KEY_ACCESS_TOKEN,
  KEY_ACCESS_TOKEN_EXPIRY_TIME,
  KEY_ACCOUNT_ID,
  KEY_APP_STATE,
  KEY_IDENTITY_TYPE,
  KEY_LAST_MOUSE_MOVE,
  KEY_NONCE,
  KEY_PRINCIPAL_ID,
  KEY_REFRESH_TOKEN,
  KEY_SAVED_ACCOUNT,
  KEY_SAVED_SERVICE_ACCOUNT_ID,
  KEY_SAVED_SERVICE_ID,
  KEY_SAVED_USERNAME,
  KEY_VERSION_CODE,
} from "./constants";

const VERSION_CODE = process.env.REACT_APP_VERSION_CODE || "";

const getPreviousAppState = (): AppSchema => {
  const previousState = LocalStorageClient.getItem(KEY_APP_STATE, "{}");
  if (isValidJson(previousState)) {
    try {
      return JSON.parse(previousState);
    } catch (e) {
      console.error("Failed to load previous state used in v27 and earlier");
      return {} as AppSchema;
    }
  }
  console.error("Previously saved v27 application state is invalid");
  return {} as AppSchema;
};

const getLastMouseMove = (): string => {

  const localStorageVersion = Number(LocalStorageClient.getItem(KEY_LAST_MOUSE_MOVE));

  const {
    lastMouseMove: appStateVersion = 0,
  } = getPreviousAppState();

  if (isNaN(localStorageVersion)) {
    return appStateVersion + "";
  }

  return Math.max(localStorageVersion, appStateVersion) + "";
};

const writeLocalStorageData = (data: LocalStorageData) => {

  const {
    versionCode,
    savedUsername,
    savedUserAccountId,
    savedServiceId,
    savedServiceAccountId,
    lastMouseMove,
    accountId,
    principalId,
    identityType,
    authTokenExpiryTime,
  } = data;

  LocalStorageClient.clear();
  LocalStorageClient.setItem(KEY_VERSION_CODE, versionCode);
  LocalStorageClient.setItem(KEY_SAVED_USERNAME, savedUsername);
  LocalStorageClient.setItem(KEY_SAVED_ACCOUNT, savedUserAccountId);
  LocalStorageClient.setItem(KEY_SAVED_SERVICE_ID, savedServiceId);
  LocalStorageClient.setItem(KEY_SAVED_SERVICE_ACCOUNT_ID, savedServiceAccountId);
  LocalStorageClient.setItem(KEY_LAST_MOUSE_MOVE, lastMouseMove);
  LocalStorageClient.setItem(KEY_ACCOUNT_ID, accountId);
  LocalStorageClient.setItem(KEY_PRINCIPAL_ID, principalId);
  LocalStorageClient.setItem(KEY_IDENTITY_TYPE, identityType);
  LocalStorageClient.setItem(KEY_ACCESS_TOKEN_EXPIRY_TIME, authTokenExpiryTime);
};

const writeSessionStorageData = (data: SessionStorageData) => {

  const {
    authToken,
    refreshToken,
    nonce,
  } = data;

  SessionStorageClient.clear();
  SessionStorageClient.setItem(KEY_ACCESS_TOKEN, authToken);
  SessionStorageClient.setItem(KEY_REFRESH_TOKEN, refreshToken);
  SessionStorageClient.setItem(KEY_NONCE, nonce);
};

const migrateLegacyDataV27 = (): boolean => {

  const {
    authToken: accessToken = "",
    refreshToken = "",
    authTokenExpiryTime: accessTokenExpiryTime = "",
    accountId = "",
    principalId = "",
    identityType = IdentityType.NONE,
    login: {
      nonce = "",
    },
  } = getPreviousAppState();

  const localStorageData: LocalStorageData = {
    versionCode: VERSION_CODE,
    savedUsername: LocalStorageClient.getItem(KEY_SAVED_USERNAME),
    savedUserAccountId: LocalStorageClient.getItem(KEY_SAVED_ACCOUNT),
    savedServiceId: LocalStorageClient.getItem(KEY_SAVED_SERVICE_ID),
    savedServiceAccountId: LocalStorageClient.getItem(KEY_SAVED_SERVICE_ACCOUNT_ID),
    lastMouseMove: getLastMouseMove(),
    accountId,
    principalId,
    identityType,
    authTokenExpiryTime: accessTokenExpiryTime,
  };

  const sessionStorageData: SessionStorageData = {
    authToken: accessToken,
    refreshToken,
    nonce,
  };

  writeLocalStorageData(localStorageData);

  writeSessionStorageData(sessionStorageData);

  return true;
};

const migrateLegacyData = (legacyVersionCode: number): boolean => {
  if (legacyVersionCode <= 27) {
    return migrateLegacyDataV27();
  } else {
    return false;
  }
};

export const migrateLegacyDataIfNecessary = (): boolean => {

  const currentVersionCode = Number(VERSION_CODE);

  if (isNaN(currentVersionCode) || currentVersionCode <= 0) {
    // Migration not possible since we don't know the current version code
    console.error("VERSION_CODE NOT FOUND");
    return false;
  }

  const lastVersionCode = LocalStorageClient.getItem(KEY_VERSION_CODE);

  if (isEmptyString(lastVersionCode) || !isValidInteger(lastVersionCode) || isNaN(Number(lastVersionCode))) {
    // Up until v28, we did not store the version code in a separate key, so we can figure out if
    // this is a fresh install or a v27 - or below - by checking if the app state key is present
    if (LocalStorageClient.hasItem(KEY_APP_STATE)) {
      return migrateLegacyData(27);
    }

    // Migration not possible since we don't know the last version code
    // and this may be the first time using portal
    return false;
  }

  const lastVersionCodeNumber = Number(lastVersionCode);

  if (lastVersionCodeNumber < currentVersionCode) {
    return migrateLegacyData(lastVersionCodeNumber);
  }

  // No migration necessary
  return false;
};

export default migrateLegacyDataIfNecessary;
