import { createSelector } from "reselect";
import { AppSchema } from "@schemas";
import { DeletePolicySchema } from "./reducers";
import { SCHEMA_KEY } from "./constants";
import { isEmptyString, withModuleSelector } from "../../util";

type Schema = DeletePolicySchema;
type Selector<T> = (state: AppSchema) => T;

const createModuleSelector = <T>(key: keyof Schema): Selector<T> =>
  withModuleSelector(SCHEMA_KEY, key);

export const isDialogOpen = createModuleSelector<boolean>("open");
export const getPolicyName = createModuleSelector<string>("policyName");
export const getErrorMessage = createModuleSelector<string>("errorMessage");
export const getSuccessMessage = createModuleSelector<string>("successMessage");
export const isProgressIndicatorVisible = createModuleSelector<boolean>("showProgressIndicator");
export const isAccessDeniedVisible = createModuleSelector<boolean>("showAccessDenied");

export const isPolicyNameValid: Selector<boolean> = createSelector(
  getPolicyName, (policyName: string) => !isEmptyString(policyName));
