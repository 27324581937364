import React from "react";
import styles from "./styles";
import classnames from "classnames";
import Checkbox from "@material-ui/core/Checkbox";
import CancelButton from "@components/cancel-button";
import ContinueButton from "@components/continue-button";
import TextFieldStyled from "@components/text-field-styled";
import { formEventHandler, hasSpecialChars, isEmptyString, noop } from "@util";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import WorkloadVariableSchema from "@data/WorkloadVariableSchema";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface EditVariablesListItemModel {
  className?: string;
  item?: WorkloadVariableSchema;
}

export interface EditVariablesListItemActions {
  save?: (value: WorkloadVariableSchema) => void;
  cancel?: () => void;
}

type Model = EditVariablesListItemModel;
type Actions = EditVariablesListItemActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const EditVariablesListItem = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    item = WorkloadVariableSchema.EMPTY,
    save = noop,
    cancel = noop,
    children,
  } = props;

  const [updatedName, setUpdatedName] = React.useState(item.getName());

  const [updatedDescription, setUpdatedDescription] = React.useState(item.getDescription());

  const [updatedRequired, setUpdatedRequired] = React.useState(item.isRequired());

  const saveButtonDisabled = React.useMemo(() =>
    isEmptyString(updatedName) || hasSpecialChars(updatedName),
    [updatedName]);

  const saveItem = React.useCallback(() => {
    save(new WorkloadVariableSchema({
      ...item.toJS(),
      name: updatedName,
      description: updatedDescription,
      required: updatedRequired,
    }));
  }, [item, updatedName, updatedDescription, updatedRequired]);

  const onEnterPressed = React.useCallback(() => {
    if (!saveButtonDisabled) {
      saveItem();
    }
  }, [saveButtonDisabled, saveItem]);

  return (
    <div className={classnames("editVariablesListItem", className, classes.container)}>
      <div className={classnames("row", classes.row)}>
        <TextFieldStyled
          className={classnames("nameTextField", classes.textField, classes.nameTextField)}
          label="Variable Name"
          placeholder="Set Variable Name"
          autoFocus={true}
          shrinkInputLabel={true}
          value={updatedName}
          disableSpecialChars={true}
          setValue={setUpdatedName}
          onEnterPressed={onEnterPressed}
        />
        <FormControlLabel
          className={classnames("requiredContainer", classes.requiredContainer)}
          label="Required?"
          control={(
            <Checkbox
              className={classnames("requiredCheckbox", classes.requiredCheckbox)}
              checked={updatedRequired}
              onChange={formEventHandler(() => setUpdatedRequired(!updatedRequired))}
            />
          )}
        />
      </div>
      <TextFieldStyled
        className={classnames("descriptionTextField", classes.textField, classes.descriptionTextField)}
        label="Variable Description (Optional)"
        placeholder="Set Variable Description"
        shrinkInputLabel={true}
        value={updatedDescription}
        setValue={setUpdatedDescription}
        onEnterPressed={onEnterPressed}
      />
      <div className={classnames("controls", classes.controls)}>
        <CancelButton
          className={classnames("cancelButton", classes.cancelButton)}
          label="Cancel"
          onClick={cancel}
        />
        <ContinueButton
          className={classnames("saveButton", classes.saveButton)}
          disabled={saveButtonDisabled}
          label="Save"
          onClick={saveItem}
        />
      </div>
      {children}
    </div>
  );
});

export default EditVariablesListItem;
