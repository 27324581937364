import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import { SearchFilter } from "@data";
import FiltersMenu from "../filters-menu";
import FilterSwitch from "../filter-switch";
import AppliedFilters from "../applied-filters";
import { ActionMenuItem } from "../actions-menu";
import DataApprovalRequestsList, {
  DataApprovalRequestsListActions,
  DataApprovalRequestsListModel,
} from "../data-approval-requests-list";
import {
  APPROVE_DATA_APPROVAL_REQUEST_ACTION,
  REJECT_DATA_APPROVAL_REQUEST_ACTION,
} from "../data-approval-request-actions-menu";
import DataApprovalRequestsFilter from "./DataApprovalRequestsFilter";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const DEFAULT_ACTIONS: ActionMenuItem[] = Object.freeze([
  APPROVE_DATA_APPROVAL_REQUEST_ACTION,
  REJECT_DATA_APPROVAL_REQUEST_ACTION,
]) as ActionMenuItem[];

export interface DataApprovalRequestsModel extends DataApprovalRequestsListModel {
  filterByCurrentAccount?: boolean;
  currentAccountId?: string;
  filterByPendingApproval?: boolean;
}

export interface DataApprovalRequestsActions extends DataApprovalRequestsListActions {
  setFilterByCurrentAccount?: (checked: boolean) => void;
  setFilterByPendingApproval?: (checked: boolean) => void;
  mapSearchFilterToLabel?: (searchFilter: SearchFilter) => React.ReactNode;
}

type Model = DataApprovalRequestsModel;
type Actions = DataApprovalRequestsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataApprovalRequests = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    listClassName,
    items: unfilteredItems = [],
    actions = DEFAULT_ACTIONS,
    showLoadingIndicator,
    showSummary = true,
    showSearch = true,
    filterByCurrentAccount = false,
    currentAccountId = "",
    filterByPendingApproval = false,
    setFilterByCurrentAccount = noop,
    setFilterByPendingApproval = noop,
    mapSearchFilterToLabel,
    ...otherProps
  } = props;

  const [showFiltersMenu, setShowFiltersMenu] = React.useState(false);

  const searchFilters = React.useMemo<SearchFilter[]>(() => ([] as SearchFilter[])
    .concat(filterByCurrentAccount ? [new SearchFilter({
      key: DataApprovalRequestsFilter.CURRENT_ACCOUNT,
    })] : [])
    .concat(filterByPendingApproval ? [new SearchFilter({
      key: DataApprovalRequestsFilter.PENDING,
    })] : []), [
    filterByCurrentAccount,
    filterByPendingApproval,
  ]);

  const noResultsLabel = React.useMemo(() =>
      filterByPendingApproval && searchFilters.length === 1
        ? "No Pending Approval Requests Found" : "No Approval Requests Found",
    [filterByPendingApproval, searchFilters]);

  const badgeCount = React.useMemo(() => searchFilters.length, [searchFilters]);

  const items = React.useMemo(() => unfilteredItems
      .filter(item => filterByCurrentAccount
        ? currentAccountId === item.getAccountId()
        : !item.getAccountId().startsWith("0000001"))
      .filter(item => !filterByPendingApproval || item.isPending()),
    [unfilteredItems, filterByCurrentAccount, currentAccountId, filterByPendingApproval]);

  const openMenu = React.useCallback(() => setShowFiltersMenu(true), [setShowFiltersMenu]);

  const closeMenu = React.useCallback(() => setShowFiltersMenu(false), [setShowFiltersMenu]);

  const setSearchFilters = React.useCallback((updatedSearchFilters: SearchFilter[]) =>
    searchFilters
      .filter(it => !updatedSearchFilters.some(it2 => it.equals(it2)))
      .map(it => it.getKey())
      .forEach(type => {
        switch (type) {
          case DataApprovalRequestsFilter.CURRENT_ACCOUNT:
            setFilterByCurrentAccount(false);
            break;
          case DataApprovalRequestsFilter.PENDING:
            setFilterByPendingApproval(false);
            break;
          default:
            break;
        }
      }), [
    searchFilters,
    setFilterByCurrentAccount,
    setFilterByPendingApproval,
  ]);

  const clearFilters = React.useCallback(() => {
    setFilterByCurrentAccount(false);
    setFilterByPendingApproval(false);
  }, [
    setFilterByCurrentAccount,
    setFilterByPendingApproval,
  ]);

  return (
    <DataApprovalRequestsList
      {...otherProps}
      className={classnames("dataApprovalRequests", className, classes.container)}
      listClassName={classnames("list", listClassName, classes.list)}
      items={items}
      actions={actions}
      showSummary={showSummary}
      showSearch={showSearch}
      showLoadingIndicator={showLoadingIndicator}
      noResultsLabel={noResultsLabel}
      summaryViewFiltersMenu={(
        <FiltersMenu
          className={classnames("filtersMenu", classes.filtersMenu)}
          open={showFiltersMenu}
          badgeCount={badgeCount}
          disabled={showLoadingIndicator}
          openMenu={openMenu}
          closeMenu={closeMenu}
        >
          <div className={classnames("filters", classes.filters)}>
            <FilterSwitch
              className={classnames("currentAccountFilterSwitch", classes.filterSwitch, classes.currentAccount)}
              rightLabel={"Show data approval requests for current account only"}
              checked={filterByCurrentAccount}
              onChange={checked => setFilterByCurrentAccount(checked)}
            />
            <FilterSwitch
              className={classnames("pendingFilterSwitch", classes.filterSwitch, classes.pending)}
              rightLabel={"Show pending data approvals requests only"}
              checked={filterByPendingApproval}
              onChange={checked => setFilterByPendingApproval(checked)}
            />
          </div>
        </FiltersMenu>
      )}
      header={(
        <AppliedFilters
          className={classnames("appliedFilters", classes.appliedFilters)}
          disabled={showLoadingIndicator}
          searchFilters={searchFilters}
          mapSearchFilterToLabel={mapSearchFilterToLabel}
          setSearchFilters={setSearchFilters}
          onClickSearchFilter={openMenu}
          clearFilters={clearFilters}
        />
      )}
    />
  );
});

export default DataApprovalRequests;
