import React from "react";
import classnames from "classnames";
import { isEmptyString } from "@util";
import { MODULE_PATH } from "../../constants";
import { Redirect, useRouteMatch } from "react-router-dom";
import { getPathToDataApprovalRequest } from "../../helpers";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import TrainingCertificatePdfViewer from "@components/training-certificate-pdf-viewer";
import styles from "./styles";

export interface TrainingCertificateViewModel {
  trainingCertificateId?: string;
}

export interface TrainingCertificateViewActions {
}

type Model = TrainingCertificateViewModel;
type Actions = TrainingCertificateViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const TrainingCertificateView = withStyles(styles)((props: Props) => {

  const {
    classes,
    trainingCertificateId = "",
    children,
  } = props;

  const match = useRouteMatch<{ id: string }>(MODULE_PATH);

  if (isEmptyString(trainingCertificateId)) {
    if (match && match.params && match.params.id) {
      return <Redirect to={getPathToDataApprovalRequest(match.params.id)} />;
    } else {
      return <Redirect to={MODULE_PATH} />;
    }
  }

  return (
    <TrainingCertificatePdfViewer
      className={classnames("trainingCertificateView", classes.container)}
      trainingCertificateId={trainingCertificateId}
    >
      {children}
    </TrainingCertificatePdfViewer>
  );
});

export default TrainingCertificateView;
