import React from "react";
import classnames from "classnames";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { namespaceView as styles } from "./styles";

export interface Model {
  title?: string;
  children?: React.ReactNode;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const NamespaceView = withStyles(styles)((props: Props) => {

  const { classes, title = "Select Schema Namespace", children } = props;

  return (
    <div className={classnames("namespaceView", classes.container)}>
      <label className={classnames("title", classes.title)}>
        {title}
      </label>
      <div className={classnames(classes.contentWrapper)}>
        {children}
      </div>
    </div>
  );
});

export default NamespaceView;
