import { ActionMenuItem } from "@components";

export enum RedirectUrisTableAction {
  EDIT_URI = "edit_uri",
  REMOVE_URI = "remove_uri",
}

export const REDIRECT_URIS_TABLE_ACTIONS: ActionMenuItem[] = [
  {
    id: RedirectUrisTableAction.EDIT_URI,
    name: "Edit Redirect URI",
  },
  {
    id: RedirectUrisTableAction.REMOVE_URI,
    name: "Remove Redirect URI",
  },
];
