import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { isEmptyString } from "@util";
import { styles } from "./styles";

export interface Model {
  userId?: string;
  selfAuthorized?: boolean;
}

export interface Action {
}

type Props = WithStyles<typeof styles> & Model & Action;

export const CloseUser = withStyles(styles)((props: Props) => {

  const { classes, selfAuthorized, userId = "" } = props;

  const content = React.useMemo(() => {

    if (isEmptyString(userId)) {
      return (
        <label className={classnames("emptyViewLabel", classes.emptyViewLabel)}>
          No User Selected
        </label>
      );
    }

    if (selfAuthorized) {
      return (
        <label className={classnames("subtitle", classes.closeMyAccountSubtitle)}>
          <strong>WARNING: </strong>By proceeding, your user will be effectively removed from this
          IoT account and your current session will end. Please consider this decision carefully.
        </label>
      );
    }

    return (
      <React.Fragment>
        <label className={classnames("title", classes.title)}>
          Are you sure you want to close this user's account?
        </label>
        <label className={classnames("userId", classes.userId)}>
          {userId}
        </label>
      </React.Fragment>
    );
  }, [classes, userId, selfAuthorized]);

  return (
    <div className={classnames("closeUser", classes.container)}>
      {content}
    </div>
  );
});

export default CloseUser;
