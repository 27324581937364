import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { SecurityGroup } from "@data/SecurityGroup";
import {
  ContinueButton,
  DEFAULT_SECURITY_GROUP_ACTION_MENU_ITEMS,
  SecurityGroupsList,
  SecurityGroupsListActions,
  SecurityGroupsListModel,
} from "@components";
import styles from "./styles";

export interface Model extends SecurityGroupsListModel {
  showNotFound?: boolean;
  showAccessDenied?: boolean;
  createSecurityGroupButtonLabel?: string;
}

export interface Actions extends SecurityGroupsListActions {
  createSecurityGroup?: () => void;
  showSecurityGroupDetails?: (securityGroup: SecurityGroup) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const SecurityGroups = withStyles(styles)((props: Props) => {

  const {
    classes,
    actions = DEFAULT_SECURITY_GROUP_ACTION_MENU_ITEMS,
    createSecurityGroupButtonLabel = "Create Security Group",
    createSecurityGroup,
    showSecurityGroupDetails,
    children,
    ...otherProps
  } = props;

  return (
    <SecurityGroupsList
      {...otherProps}
      summaryViewLabel="Security Groups"
      className={classnames("securityGroups", classes.container)}
      listClassName={classnames("securityGroupsList", classes.list)}
      showSummary={true}
      showSearch={true}
      actions={actions}
      onClickItem={showSecurityGroupDetails}
    >
      <ContinueButton
        className={classnames("createSecurityGroupButton", "createButton", classes.createButton)}
        label={createSecurityGroupButtonLabel}
        onClick={createSecurityGroup}
      />
      {children}
    </SecurityGroupsList>
  );
});

export default SecurityGroups;
