import React from "react";
import classnames from "classnames";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { TextFieldStyled } from "@components";
import { styles, tableBodyCell, tableBodyRow } from "./styles";
import { noop } from "@util";
import { CredentialAttributes } from "@data";

const TableBodyRow = withStyles(tableBodyRow)(TableRow);
const TableBodyCell = withStyles(tableBodyCell)(TableCell);

export interface Model {
  className?: string;
  credential?: CredentialAttributes;
}

export interface Actions {
  updateCredentialValue?: (name: string, value: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const CredentialsTableBodyRow = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    credential = { name: "", value: ""},
    updateCredentialValue = noop,
  } = props;

  const { name = "" } = credential || { name: "" };

  return (
    <TableBodyRow
      className={classnames("tableBodyRow", className)}
    >
      <TableBodyCell className={classnames("name", classes.nameColumn)}>
        {name}
      </TableBodyCell>
      <TableBodyCell className={classnames("name", classes.valueColumn)}>
        <TextFieldStyled
          className={classnames("valueTextField", name, classes.textField)}
          name="value"
          value={credential.value}
          setValue={(value: string) => updateCredentialValue(name, value)}
        />
      </TableBodyCell>
    </TableBodyRow>
  );
});

export default CredentialsTableBodyRow;
