export enum WorkloadConfigurationOperations {
  NONE = "",
  ADD = "add",
  EDIT = "edit",
  DELETE_BY_KEY = "delete_by_key",
  DELETE_KEYS = "delete_keys",
}

export { WorkloadConfigurationOperations as WorkloadConfigurationOperation };

export default WorkloadConfigurationOperations;
