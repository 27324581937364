import { Record } from "immutable";
import { equalsIgnoreCase } from "@util";
import {
  FederationProvider,
  FederationProviderAttribute,
  FederationProviderScope
} from "@data/FederationProvider";

export interface FederationProviderRequestAttributes {
  domain: string;
  clientId: string;
  clientSecret: string;
  scope?: FederationProviderScope;
  discoveryDocument?: string;
  authorizationEndpoint?: string;
  tokenEndpoint?: string;
  userEndpoint?: string;
  jwksUri?: string;
  issuer?: string;
  federationClosed: boolean;
  attributes: FederationProviderAttribute;
}

export class FederationProviderRequest extends Record({
  domain: "",
  clientId: "",
  clientSecret: "",
  scope: {},
  discoveryDocument: "",
  authorizationEndpoint: "",
  tokenEndpoint: "",
  userEndpoint: "",
  jwksUri: "",
  issuer: "",
  federationClosed: false,
  attributes: {},
}) implements FederationProviderRequestAttributes {

  public static EMPTY: FederationProviderRequest = new FederationProviderRequest();

  public readonly domain: string;
  public readonly clientId: string;
  public readonly clientSecret: string;
  public readonly discoveryDocument: string;
  public readonly authorizationEndpoint: string;
  public readonly tokenEndpoint: string;
  public readonly userEndpoint: string;
  public readonly jwksUri: string;
  public readonly issuer: string;
  public readonly federationClosed: boolean;
  public readonly scope: FederationProviderScope;
  public readonly attributes: FederationProviderAttribute;

  public static from(federationProvider: FederationProvider): FederationProviderRequest {

    const {
      domain = "",
      clientId = "",
      clientSecret = "",
      scope = {},
      discoveryDocument = "",
      authorizationEndpoint = "",
      tokenEndpoint = "",
      userEndpoint = "",
      jwksUri = "",
      issuer = "",
      federationClosed = false,
      attributes = {}
    } = federationProvider.toJS();

    return new FederationProviderRequest({
      domain: domain || "",
      clientId: clientId || "",
      clientSecret: clientSecret || "",
      discoveryDocument: discoveryDocument || "",
      authorizationEndpoint: authorizationEndpoint || "",
      tokenEndpoint: tokenEndpoint || "",
      userEndpoint: userEndpoint || "",
      jwksUri: jwksUri || "",
      issuer: issuer || "",
      federationClosed: federationClosed || "",
      scope: {
        ...(typeof scope === "object" ? scope : {}),
      },
      attributes: {
        ...(typeof attributes === "object" ? attributes : {}),
      },
    });
  }

  // We need to override equals because this Record contains a nested Object property
  //
  // Reference: https://immutable-js.github.io/immutable-js/docs/#/Record/equals
  // Value equality: Records use value equality when compared with is() or record.equals().
  // That is, two Records with the same keys and values are equal. Plain objects use reference
  // equality. Two objects with the same keys and values are not equal since they are different
  // objects. This is important to consider when using objects as keys in a Map or values in a
  // Set, which use equality when retrieving values.
  public equals(other: FederationProviderRequest): boolean {
    return equalsIgnoreCase(JSON.stringify(this.toJS()), JSON.stringify(other.toJS()));
  }
}
