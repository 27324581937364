import React from "react";
import {
  ActionMenuItem,
  ApplicationAction,
  ApplicationOperations,
  ModuleListViewActions,
  ModuleListViewModel,
  EDIT_APPLICATION,
  DELETE_APPLICATION_REGIONAL,
} from "@components";
import ModuleListView from "@components/module-list-view";
import ApplicationOperationDialog from "@components/application-operation-dialog";
import { Application } from "@data/Application";
import ApplicationListColumns from "@components/application-list-columns";
import { useGetApplicationsRegional } from "@hooks/applicationManagerRegional/use-get-applications-regional";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import { PrimaryIcon } from "../styles";
import { getPathToApplication } from "@modules/applicationRegionalDetails/helpers";
import lowerCase from "lodash/lowerCase";
import { noop, Quantities } from "@util";

const DEFAULT_APPLICATION_LIST_COLUMNS: ApplicationListColumns[] = [
  ApplicationListColumns.ID,
  ApplicationListColumns.NAME,
  ApplicationListColumns.TYPE,
];

const ACTION_ITEMS: ActionMenuItem[] = [
  EDIT_APPLICATION,
  DELETE_APPLICATION_REGIONAL,
];
type ContainerModel = ModuleListViewModel<Application, ApplicationListColumns>;

interface ContainerActions extends ModuleListViewActions<Application, ApplicationListColumns> {
  showApplicationDetails?: (application: Application) => void;
  createApplication?: (application: Application) => void;
  editApplication?: (application: Application) => void;
}

type Props = ContainerModel & ContainerActions;

const Applications = (props: Props) => {

  const {
    showApplicationDetails,
    createApplication,
    actions: applicationActions = ACTION_ITEMS,
    editApplication = noop,
    onClickAction = noop,
    ...otherProps
  } = props;

  const [applicationId, setApplicationId] = React.useState("");
  const [nameFilter, setNameFilter] = React.useState("");
  const [openOperationDialog, setOpenOperationDialog] = React.useState(false);
  const [operation, setOperation] = React.useState(ApplicationOperations.NONE);

  const [ model, actions ] = useGetApplicationsRegional();

  const { applications } = model;

  const { refresh: refreshList } = actions;

  const moduleListItems: ListViewItem<Application>[] = React.useMemo(() =>
    applications.map(application => {
      return {
        item: application,
        pathToDetailsView: getPathToApplication(application.getId()),
        icon: PrimaryIcon,
        columnAttributes: createColumns([
          {
            className: "applicationId",
            value: application.getId(),
            column: ApplicationListColumns.ID,
            firstColumn: true,
          },
          {
            className: "applicationName",
            value: application.getName(),
            column: ApplicationListColumns.NAME,
          },
          {
            className: "applicationType",
            value: application.getApplicationType(),
            column: ApplicationListColumns.TYPE,
          }
        ])
      };
    }), [applications]);

  const summaryViewQuantities: Quantities = {
      other: "applications",
      one: "application",
    };

  const deleteApplicationRegional = React.useCallback((application: Application = Application.EMPTY) => {
    setApplicationId(application.getId());
    setOperation(ApplicationOperations.DELETE);
    setOpenOperationDialog(true);
  }, [setApplicationId, setOperation, setOpenOperationDialog]);

  const onClickApplicationAction = React.useCallback((application: Application, action: ActionMenuItem) => {
    switch (action.id) {
    case ApplicationAction.EDIT_APPLICATION:
        return editApplication(application);
    case ApplicationAction.DELETE_APPLICATION_REGIONAL:
      return deleteApplicationRegional(application);
    default:
      return onClickAction(application, action);
    }
  }, [
    editApplication,
    deleteApplicationRegional,
    onClickAction,
  ]);

  const closeDialog = React.useCallback(() => {
    setApplicationId("");
    setOperation(ApplicationOperations.NONE);
    return setOpenOperationDialog(false);
  }, [setApplicationId, setOperation, setOpenOperationDialog]);

  const onOperationSuccess = React.useCallback(() => {
    closeDialog();
    refreshList();
  }, [refreshList, closeDialog]);

  const dialogClassName = React.useMemo(() => `${lowerCase(operation)}ApplicationDialog`, [operation]);

  return (
    <React.Fragment>
    <ModuleListView
      {...model}
      {...actions}
      {...otherProps}
      className={"applications"}
      summaryViewIcon={PrimaryIcon}
      nameFilter={nameFilter}
      listClassName={"applicationsRegionalList"}
      columns={DEFAULT_APPLICATION_LIST_COLUMNS}
      listViewItems={moduleListItems}
      showSummary={true}
      showSearch={true}
      showCreateButton={true}
      createButtonClassName="createApplicationButton"
      actions={applicationActions}
      onClickAction={onClickApplicationAction}
      createButtonLabel={"Create Application"}
      onClickCreateButton={createApplication}
      summaryViewQuantities={summaryViewQuantities}
      setNameFilter={setNameFilter}
      onClickItem={showApplicationDetails}
    />
    <ApplicationOperationDialog
      dialogClassName={dialogClassName}
      open={openOperationDialog}
      applicationId={applicationId}
      operation={operation}
      onSuccessMessageShown={onOperationSuccess}
      cancel={closeDialog}
    />
    </React.Fragment>
  );
};

export default Applications;
