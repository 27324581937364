import React from "react";
import classnames from "classnames";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ActionMenuItem, ActionsMenu } from "@components";
import { styles, tableBodyCell } from "./styles";
import { noop } from "@util";

const TableBodyCell = withStyles(tableBodyCell)(TableCell);

export interface Model {
  className?: string;
  uri?: string;
  actions?: ActionMenuItem[];
}

export interface Actions {
  onClickAction?: (action: ActionMenuItem) => void;
  editUri?: (uri: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const RedirectUrisTableBodyRow = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    uri = "",
    actions = [],
    onClickAction = noop,
    editUri,
  } = props;

  const onItemClicked = React.useCallback(() => {
    if (editUri) {
      return editUri(uri);
    } else {
      return noop;
    }
  }, [editUri, uri]);

  return (
    <TableRow
      className={classnames("tableBodyRow", className)}
      onClick={onItemClicked}
    >
      <TableBodyCell
        className={classnames("redirectUri", classes.uriColumn)}
      >
        {uri}
      </TableBodyCell>
      <TableBodyCell className={classnames("actions", classes.actionsColumn)}>
        <ActionsMenu
          buttonVariant="icon"
          actions={actions}
          onClickAction={onClickAction}
        />
      </TableBodyCell>
    </TableRow>
  );
});

export default RedirectUrisTableBodyRow;
