import React from "react";
import classnames from "classnames";
import { JsonEditor } from "@components/json-editor";
import { jsonEditorView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface Model {
  json?: string;
  readOnly?: boolean;
}

export interface Actions {
  setJson?: (json: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const JsonEditorView = withStyles(styles)((props: Props) => {

  const {
    classes,
    json = "{}",
    readOnly = false,
    setJson,
    children,
  } = props;

  return (
    <React.Fragment>
      <JsonEditor
        className={classnames("jsonEditor", classes.jsonEditor)}
        name="schemaWizardJsonViewEditor"
        json={json}
        readOnly={readOnly}
        setJson={setJson}
      />
      {children}
    </React.Fragment>
  );
});

export default JsonEditorView;
