import ProfileAttributeIcon from "@material-ui/icons/PermContactCalendar";
import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const PrimaryIcon = ProfileAttributeIcon;

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    error: {
      flex: "1 1 auto",
      margin: "0 0 25px 0",
    },
    jsonEditor: {
      marginTop: 0,
    },
    controls: {
      flex: "0 1 auto",
      marginTop: 16,
      marginLeft: "auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    cancelButton: {
    },
    cancelButtonLabel: {
      color: Color.BLACK,
    },
    saveButton: {
      marginLeft: 25,
    },
    loadingMessage: {
      padding: 25,
      marginBottom: 25,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      "& h1": {
        fontWeight: 500,
      },
      "& h4": {
        marginTop: 15,
        fontWeight: 500,
        color: Color.SIGNIFY_GREEN,
      },
      "& h6": {
      },
    },
    loadingMessageLabel: {
      margin: 15,
    },
    modal: {
      background: "rgba(105, 105, 105, 0.25)",
      position: "fixed",
      zIndex: 9999,
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
  });

export default styles;
