import { createStyles } from "@material-ui/core/styles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 3,
      position: "relative",
    },
    refreshMessage: {
      fontSize: 14,
      margin: "0 0 0 15px",
      fontWeight: 300,
    },
    closeIcon: {
      width: 20,
      height: 20,
      margin: "0 0 0 5px",
      cursor: "pointer"
    },
  });

export default styles;
