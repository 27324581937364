import { QueryDatabasesManagerClient } from "@network";
import { useApiRequestAnalytics } from "@hooks";
import { GetQueryDatabasesResponse, GetQueryDatabasesTableResponse } from "@network/QueryDatabasesManagerClient";

export type GetQueryDatabasesApi = () => Promise<GetQueryDatabasesResponse[]>;
export type GetQueryDatabasesTablesApi = (databaseName: string) => Promise<GetQueryDatabasesTableResponse>;

export interface QueryDatabasesManagerClientApi {
  getQueryDatabases: GetQueryDatabasesApi;
  getQueryDatabasesTables: GetQueryDatabasesTablesApi;
}

enum ActionType {
  GET_QUERY_DATABASE_REQUEST = "query_database_manager_client/get_query_database_request",
  GET_QUERY_DATABASE_SUCCESS = "query_database_manager_client/get_query_database_success",
  GET_QUERY_DATABASE_FAILED = "query_database_manager_client/get_query_database_failed",

  GET_QUERY_DATABASE_TABLES_REQUEST = "query_database_manager_client/get_query_database_tables_request",
  GET_QUERY_DATABASE_TABLES_SUCCESS = "query_database_manager_client/get_query_database_tables_success",
  GET_QUERY_DATABASE_TABLES_FAILED = "query_database_manager_client/get_query_database_tables_failed",
}

export const useGetQueryDatabasesApi = (): GetQueryDatabasesApi =>
  useApiRequestAnalytics(QueryDatabasesManagerClient.getQueryDatabases, {
    REQUEST_EVENT: ActionType.GET_QUERY_DATABASE_REQUEST,
    SUCCESS_EVENT: ActionType.GET_QUERY_DATABASE_SUCCESS,
    ERROR_EVENT: ActionType.GET_QUERY_DATABASE_FAILED,
  });

export const useGetQueryDatabasesTablesApi = (): GetQueryDatabasesTablesApi =>
  useApiRequestAnalytics(QueryDatabasesManagerClient.getQueryDatabasesTables, {
    REQUEST_EVENT: ActionType.GET_QUERY_DATABASE_TABLES_REQUEST,
    SUCCESS_EVENT: ActionType.GET_QUERY_DATABASE_TABLES_SUCCESS,
    ERROR_EVENT: ActionType.GET_QUERY_DATABASE_TABLES_FAILED,
  });

export const useQueryDatabasesManagerClient = (): QueryDatabasesManagerClientApi => ({
  getQueryDatabases: useGetQueryDatabasesApi(),
  getQueryDatabasesTables: useGetQueryDatabasesTablesApi(),
});

export { ActionType as QueryDatabasesClientActionType };

export default useQueryDatabasesManagerClient;
