import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { DeviceEnrollmentWizardSelectors } from "../../selectors";
import EditorView , { Actions, Model } from "../../components/EditorView";
import DeviceEnrollmentJsonEditorView from "./DeviceEnrollmentJsonEditorView";

interface ContainerModel extends Model {
  hidden?: boolean;
}

const AddDeviceEditorViewContainer = (props: ContainerModel & Actions) => {

  const { hidden } = props;

  if (hidden) {
    return null;
  }

  return (
    <EditorView {...props}>
      <DeviceEnrollmentJsonEditorView/>
    </EditorView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  hidden: !DeviceEnrollmentWizardSelectors.isAddDeviceEditorViewSelected(state),
  isAddDeviceWizard: true,
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  ...ownProps
});

export default connect<ContainerModel, Actions, ContainerModel & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(AddDeviceEditorViewContainer);
