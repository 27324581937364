import React from "react";
import classnames from "classnames";
import {
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel,
} from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import CredentialsViewItem from "../CredentialsViewItem";
import styles from "./styles";

export interface Model extends ConfirmationDialogModel<CredentialsViewItem> {
}

export interface Actions extends ConfirmationDialogActions<CredentialsViewItem> {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const RemoveCredentialDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    item = CredentialsViewItem.EMPTY,
    children,
    ...otherProps
  } = props;

  return (
    <ConfirmationDialog
      className={classnames("removeCredentialDialog", classes.dialog)}
      title="Remove Credential Definition"
      continueButtonLabel="Remove"
      item={item}
      {...otherProps}
    >
      <div className={classnames("removeCredential", classes.content)}>
        <label className={classnames("title", classes.title)}>
          Are you sure you want to remove this credential definition?
        </label>
        <label className={classnames("subtitle", classes.subtitle)}>
          <span className={classes.emphasis}>WARNING:</span>This action cannot be undone!
        </label>
      </div>
      <div className={classnames("credentialInfo", classes.credentialInfo)}>
        <div className={classnames("credentialName", classes.summaryViewLabel)}>
          <label className={classnames("summaryViewLabelName", classes.summaryViewLabelName)}>
            Credential Name:
          </label>
          <label className={classnames("summaryViewLabelValue", classes.summaryViewLabelValue)}>
            {item.getName()}
          </label>
        </div>
        <div className={classnames("type", classes.summaryViewLabel)}>
          <label className={classnames("summaryViewLabelName", classes.summaryViewLabelName)}>
            Type:
          </label>
          <label className={classnames("summaryViewLabelValue", classes.summaryViewLabelValue)}>
            {item.getType()}
          </label>
        </div>
        <div className={classnames("encoding", classes.summaryViewLabel)}>
          <label className={classnames("summaryViewLabelName", classes.summaryViewLabelName)}>
            Encoding:
          </label>
          <label className={classnames("summaryViewLabelValue", classes.summaryViewLabelValue)}>
            {item.getEncoding()}
          </label>
        </div>
        <div className={classnames("persistence", classes.summaryViewLabel)}>
          <label className={classnames("summaryViewLabelName", classes.summaryViewLabelName)}>
            Persistence:
          </label>
          <label className={classnames("summaryViewLabelValue", classes.summaryViewLabelValue)}>
            {item.getPersistence()}
          </label>
        </div>
        {item.hasTrustedCmsAuthorityIds() && (
          <div className={classnames("trustedCmsAuthorityIds", classes.summaryViewLabel)}>
            <label className={classnames("summaryViewLabelName", classes.summaryViewLabelName)}>
              Trusted Certificate Authority ID(s):
            </label>
            <div className={classes.summaryViewLabelValues}>
              {item.getTrustedCmsAuthorityIds().map(authorityId => (
              <label
                key={authorityId}
                className={classnames("summaryViewLabelValue", classes.summaryViewLabelValue)}
              >
                {authorityId}
              </label>
            ))}
            </div>
          </div>
        )}
        {item.hasDeviceAuthentication() && (
          <div className={classnames("deviceAuthentication", classes.summaryViewLabel)}>
            <label className={classnames("summaryViewLabelName", classes.summaryViewLabelName)}>
              Device Authentication Type(s):
            </label>
            <div className={classes.summaryViewLabelValues}>
            {item.getDeviceAuthentication().map(deviceAuthentication => (
              <label
                key={deviceAuthentication}
                className={classnames("summaryViewLabelValue", classes.summaryViewLabelValue)}
              >
                {deviceAuthentication}
              </label>
            ))}
            </div>
          </div>
        )}
      </div>
      {children}
    </ConfirmationDialog>
  );
});

export default RemoveCredentialDialog;
