import React from "react";
import { ManagedPolicy } from "@data";
import { ManagedPolicyIcon } from "./styles";
import { sortAndFilterManagedPolicyResult } from "@hooks";
import ManagedPoliciesListColumn from "./ManagedPoliciesListColumn";
import {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import { ModuleListView } from "@components/module-list-view";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import { getPathToManagedPolicy } from "@modules/managedPolicyDetails/helpers";

export const DEFAULT_MANAGED_POLICIES_LIST_COLUMNS: ManagedPoliciesListColumn[] = [
  ManagedPoliciesListColumn.NAME,
  ManagedPoliciesListColumn.DESCRIPTION,
];

export type ManagedPoliciesListModel = SortedSearchResultsListModel<ManagedPolicy, ManagedPoliciesListColumn>;

export type ManagedPoliciesListActions = SortedSearchResultsListActions<ManagedPolicy, ManagedPoliciesListColumn>;

type Props = ManagedPoliciesListModel & ManagedPoliciesListActions;

export const ManagedPoliciesList = (props: Props) => {

  const {
    className = "managedPolicies",
    listClassName = "managedPoliciesList",
    columns = DEFAULT_MANAGED_POLICIES_LIST_COLUMNS,
    sortByColumn = ManagedPoliciesListColumn.NAME,
    sortOrderAscending = true,
    noResultsLabel = "No managed policies found",
    summaryViewQuantities = {
      other: "managed policies",
      one: "managed policy",
    },
    items = [],
    nameFilter = "",
    nameFilterDelay = 250,
    searchViewHint = "Filter results that start with this name",
    ...otherProps
  } = props;

  const managedPolicies = sortAndFilterManagedPolicyResult({ items, nameFilter, sortOrderAscending, sortByColumn });

  const moduleListItems: ListViewItem<ManagedPolicy>[] = React.useMemo(() =>
    managedPolicies.map(policy => {
      return {
        item: policy,
        pathToDetailsView: getPathToManagedPolicy(policy.getPolicy().getName(), policy.getAccountId()),
        icon: ManagedPolicyIcon,
        columnAttributes: createColumns([
          {
            className: "managedPolicyName",
            value: policy.getPolicy().getName(),
            column: ManagedPoliciesListColumn.NAME,
            firstColumn: true,
          },
          {
            className: "managedPolicyDescription",
            value: policy.getDescription(),
            column: ManagedPoliciesListColumn.DESCRIPTION,
          }
        ])
      };
    }), [managedPolicies]);

  return (
    <ModuleListView
      {...otherProps}
      className={className}
      listViewItems={moduleListItems}
      columns={columns}
      nameFilter={nameFilter}
      nameFilterDelay={nameFilterDelay}
      searchViewHint={searchViewHint}
      sortByColumn={sortByColumn}
      sortOrderAscending={sortOrderAscending}
      noResultsLabel={noResultsLabel}
      summaryViewIcon={ManagedPolicyIcon}
      summaryViewQuantities={summaryViewQuantities}
    />
  );
};

export default ManagedPoliciesList;
