import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { updateAuthTokenFactory } from "@main/containers/functions";
import { initialize, logout, updateAuthToken } from "../actions";
import {
  getAuthToken,
  getAuthTokenExpiryMoment,
  getCurrentAccountId,
  getCurrentUserId,
  getIdentityType,
  getRefreshToken,
  getUserReportMessage,
} from "../selectors";
import AppLayout from "./Layout";
import SendFeedbackDialog from "./SendFeedbackDialog";
import SessionMismatchDialog from "./SessionMismatchDialog";
import App, { Actions, Model } from "../components/App";

const AppContainer = (props: Model & Actions) => {

  return (
    <App {...props}>
      <AppLayout />
      <SessionMismatchDialog />
      <SendFeedbackDialog />
    </App>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  accountId: getCurrentAccountId(state),
  userId: getCurrentUserId(state),
  authToken: getAuthToken(state),
  refreshToken: getRefreshToken(state),
  nextRefresh: getAuthTokenExpiryMoment(state),
  identityType: getIdentityType(state),
  successMessage: getUserReportMessage(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  initialize: () => dispatch(initialize()),
  updateAuthToken: updateAuthTokenFactory(dispatch, logout, updateAuthToken),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(AppContainer);
