import { Theme } from "@material-ui/core/styles/createTheme";
import { createStyles, StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const tableHeaderRow: StyleRules<string> = {
  root: {
    height: 50,
  },
};

export const tableHeaderCell: StyleRules<string> = {
  root: {
    color: Color.GREY3,
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: 400,
    fontStyle: "normal",
    textAlign: "left",
    width: "auto",
    height: "auto",
    minHeight: 50,
    padding: "0 0 0 8px",
  },
};

export const tableBodyRow: StyleRules<string> = {
  root: {
    height: "auto",
  },
};

export const tableBodyCell: StyleRules<string> = {
  root: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto, sans-serif",
    fontSize: 13,
    lineHeight: "16px",
    fontWeight: 400,
    fontStyle: "normal",
    width: "auto",
    height: "auto",
    minHeight: 50,
    padding: 8,
  },
};

export const styles = (theme: Theme) =>
  createStyles({
    table: {
      borderCollapse: "collapse",
      overflow: "hidden",
      tableLayout: "fixed",
      marginTop: 8,
    },
    actionsColumn: {
      width: 100,
    },
    label: {

    }
  });
