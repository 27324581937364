import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import FiltersMenu from "../filters-menu";
import FilterSwitch from "../filter-switch";
import AppliedFilters from "../applied-filters";
import { ActionMenuItem } from "../actions-menu";
import { DataSetState, SearchFilter } from "@data";
import DataSetRequestsList, {
  DataSetRequestsListActions,
  DataSetRequestsListModel,
} from "../data-set-requests-list";
import {
  CLONE_DATA_SET_REQUEST_ACTION,
  DELETE_DATA_SET_REQUEST_ACTION,
  EDIT_DATA_SET_REQUEST_ACTION,
} from "../data-set-request-actions-menu";
import DataSetRequestsFilter from "./DataSetRequestsFilter";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const DEFAULT_ACTIONS: ActionMenuItem[] = Object.freeze([
  CLONE_DATA_SET_REQUEST_ACTION,
  EDIT_DATA_SET_REQUEST_ACTION,
  DELETE_DATA_SET_REQUEST_ACTION,
]) as ActionMenuItem[];

export interface DataSetRequestsModel extends DataSetRequestsListModel {
  filterByCurrentUser?: boolean;
  currentUserId?: string;
  filterByPendingApproval?: boolean;
}

export interface DataSetRequestsActions extends DataSetRequestsListActions {
  setFilterByCurrentUser?: (checked: boolean) => void;
  setFilterByPendingApproval?: (checked: boolean) => void;
  mapSearchFilterToLabel?: (searchFilter: SearchFilter) => React.ReactNode;
}

type Model = DataSetRequestsModel;
type Actions = DataSetRequestsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataSetRequests = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    listClassName,
    items: unfilteredItems = [],
    actions = DEFAULT_ACTIONS,
    showLoadingIndicator,
    showSummary = true,
    showSearch = true,
    filterByCurrentUser = false,
    currentUserId = "",
    filterByPendingApproval = false,
    setFilterByCurrentUser = noop,
    setFilterByPendingApproval = noop,
    mapSearchFilterToLabel,
    ...otherProps
  } = props;

  const [showFiltersMenu, setShowFiltersMenu] = React.useState(false);

  const searchFilters = React.useMemo<SearchFilter[]>(() => ([] as SearchFilter[])
    .concat(filterByCurrentUser ? [new SearchFilter({
      key: DataSetRequestsFilter.CURRENT_USER,
    })] : [])
    .concat(filterByPendingApproval ? [new SearchFilter({
      key: DataSetRequestsFilter.PENDING,
    })] : []), [
    filterByCurrentUser,
    filterByPendingApproval,
  ]);

  const noResultsLabel = React.useMemo(() =>
      filterByPendingApproval && searchFilters.length === 1
        ? "No Pending Data Set Requests Found" : "No Data Set Requests Found",
    [filterByPendingApproval, searchFilters]);

  const badgeCount = React.useMemo(() => searchFilters.length, [searchFilters]);

  const currentUser = React.useMemo(() => currentUserId.toLowerCase(), [currentUserId]);

  const items = React.useMemo(() => unfilteredItems
      .filter(item => !item.getAccountId().startsWith("0000001"))
      .filter(item => !filterByCurrentUser ||
        item.getDataSetRequestedBy().toLowerCase().indexOf(currentUser) >= 0)
      .filter(item => !filterByPendingApproval ||
        item.getStatus() === DataSetState.PENDING_IOT_APPROVAL ||
        item.getStatus() === DataSetState.PENDING_PRIVACY_TNC_UPLOAD ||
        item.getStatus() === DataSetState.PENDING_BUSINESS_CUSTOMER_APPROVALS ||
        item.getStatus() === DataSetState.BO_PENDING ||
        item.getStatus() === DataSetState.DO_PENDING),
    [unfilteredItems, filterByCurrentUser, filterByPendingApproval]);

  const openMenu = React.useCallback(() => setShowFiltersMenu(true), [setShowFiltersMenu]);

  const closeMenu = React.useCallback(() => setShowFiltersMenu(false), [setShowFiltersMenu]);

  const setSearchFilters = React.useCallback((updatedSearchFilters: SearchFilter[]) =>
    searchFilters
      .filter(it => !updatedSearchFilters.some(it2 => it.equals(it2)))
      .map(it => it.getKey())
      .forEach(type => {
        switch (type) {
          case DataSetRequestsFilter.CURRENT_USER:
            setFilterByCurrentUser(false);
            break;
          case DataSetRequestsFilter.PENDING:
            setFilterByPendingApproval(false);
            break;
          default:
            break;
        }
      }), [
    searchFilters,
    setFilterByCurrentUser,
    setFilterByPendingApproval,
  ]);

  const clearFilters = React.useCallback(() => {
    setFilterByCurrentUser(false);
    setFilterByPendingApproval(false);
  }, [
    setFilterByCurrentUser,
    setFilterByPendingApproval,
  ]);

  return (
    <DataSetRequestsList
      {...otherProps}
      className={classnames("dataSetRequests", className, classes.container)}
      listClassName={classnames("list", listClassName, classes.list)}
      items={items}
      actions={actions}
      showSummary={showSummary}
      showSearch={showSearch}
      showLoadingIndicator={showLoadingIndicator}
      noResultsLabel={noResultsLabel}
      summaryViewFiltersMenu={(
        <FiltersMenu
          className={classnames("filtersMenu", classes.filtersMenu)}
          open={showFiltersMenu}
          badgeCount={badgeCount}
          disabled={showLoadingIndicator}
          openMenu={openMenu}
          closeMenu={closeMenu}
        >
          <div className={classnames("filters", classes.filters)}>
            <FilterSwitch
              className={classnames("currentUserFilterSwitch", classes.filterSwitch, classes.currentUser)}
              rightLabel="Show my data set requests only"
              checked={filterByCurrentUser}
              onChange={checked => setFilterByCurrentUser(checked)}
            />
            <FilterSwitch
              className={classnames("pendingFilterSwitch", classes.filterSwitch, classes.pending)}
              rightLabel="Show pending data set requests only"
              checked={filterByPendingApproval}
              onChange={checked => setFilterByPendingApproval(checked)}
            />
          </div>
        </FiltersMenu>
      )}
      header={(
        <AppliedFilters
          className={classnames("appliedFilters", classes.appliedFilters)}
          disabled={showLoadingIndicator}
          searchFilters={searchFilters}
          mapSearchFilterToLabel={mapSearchFilterToLabel}
          setSearchFilters={setSearchFilters}
          onClickSearchFilter={openMenu}
          clearFilters={clearFilters}
        />
      )}
    />
  );
});

export default DataSetRequests;
