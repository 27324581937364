import { Record } from "immutable";
import { PolicyInfo, PolicyInfoAttributes } from "./PolicyInfo";
import { PolicyStatement, PolicyStatementAttributes } from "./PolicyStatement";
import { equalsIgnoreCase, isEmptyString } from "@util/Functions";

export interface PolicyAttributes {
  info: PolicyInfoAttributes;
  statements: PolicyStatementAttributes[];
}

export class Policy extends Record({
  info: PolicyInfo.EMPTY.toJS(),
  statements: [],
}) implements PolicyAttributes {

  public static EMPTY: Policy = new Policy();

  public readonly info: PolicyInfoAttributes;
  public readonly statements: PolicyStatementAttributes[];

  // We need to override equals because this Record contains a nested Object property
  //
  // Reference: https://immutable-js.github.io/immutable-js/docs/#/Record/equals
  // Value equality: Records use value equality when compared with is() or record.equals().
  // That is, two Records with the same keys and values are equal. Plain objects use reference
  // equality. Two objects with the same keys and values are not equal since they are different
  // objects. This is important to consider when using objects as keys in a Map or values in a
  // Set, which use equality when retrieving values.
  public equals(other: Policy): boolean {
    return this.getInfo().equals(other.getInfo()) &&
      equalsIgnoreCase(
        JSON.stringify(this.getStatements().map(statement => statement.toJS())),
        JSON.stringify(other.getStatements().map(statement => statement.toJS())));
  }

  public getInfo(): PolicyInfo {
    return new PolicyInfo(this.info);
  }

  public getName(): string {
    return this.getInfo().getName();
  }

  public getDescription(): string {
    return this.getInfo().getDescription();
  }

  public getVersion(): string {
    return this.getInfo().getVersion();
  }

  public getPath(): string {
    return this.getInfo().getPath();
  }

  public getStatements(): PolicyStatement[] {
    const statements = Array.isArray(this.statements) ? this.statements : [];
    return statements.map((attrs: PolicyStatementAttributes) => new PolicyStatement(attrs));
  }

  public hasName(): boolean {
    return !isEmptyString(this.getName());
  }

  public hasStatements(): boolean {
    return this.getStatements().every(statement => statement.isValid());
  }

  public isValid(): boolean {
    return this.hasName() && this.hasStatements();
  }
}

export default Policy;
