import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import { User, UserAttributes } from "@data/User";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base/createReducers";
import { AdminNoteAttributes, Policy } from "@data";

export enum UserDetailsViewMode {
  PROFILE = "profile",
  AUTHENTICATION = "authentication",
  GROUPS = "groups",
  POLICIES = "policies",
  NOTES = "notes",
  EVENTS = "events",
  WORKLOADS = "workloads"
}

export enum PoliciesViewMode {
  CUSTOM = "custom",
  MANAGED = "managed"
}

export interface UserDetailsSchema extends BasePortalModuleSchema {
  policiesViewMode: PoliciesViewMode;
  user: UserAttributes;
  removeUserButtonEnabled: boolean;
  detachPolicyButtonEnabled: boolean;
  implicitPermissionPolicy: Policy;
  notes: AdminNoteAttributes[];
  note: string;
  adminUser: boolean;
  noteNextPage: string;
  createNoteBox: boolean;
  noteLoadingIndicator: boolean;
  noteErrorMessage: string;
  policyLoadingIndicator: boolean;
  policyErrorMessage: string;
  deleteAuthenticatorDialog: boolean;
  updateMfaDialog: boolean;
  pageRefresh: boolean;
}

export const DEFAULT_STATE: UserDetailsSchema = createPortalModuleDefaultState({
  policiesViewMode: PoliciesViewMode.CUSTOM,
  user: Object.freeze({ ...User.EMPTY.toJS() }),
  removeUserButtonEnabled: true,
  detachPolicyButtonEnabled: true,
  implicitPermissionPolicy: Policy.EMPTY,
  notes: [],
  note: "",
  adminUser: false,
  noteNextPage: "",
  createNoteBox: false,
  noteLoadingIndicator: false,
  noteErrorMessage: "",
  policyLoadingIndicator: false,
  policyErrorMessage: "",
  deleteAuthenticatorDialog: false,
  updateMfaDialog: false,
  pageRefresh: false,
});

type UserDetailsActionType = PortalModuleActionTypes<UserDetailsSchema> & {
  FETCH_USER_PROFILE_REQUEST: string;
  FETCH_USER_PROFILE_SUCCESS: string;
  FETCH_USER_PROFILE_FAILED: string;
  FETCH_ADMIN_NOTES_REQUEST: string;
  FETCH_ADMIN_NOTES_SUCCESS: string;
  FETCH_ADMIN_NOTES_FAILED: string;
  CREATE_ADMIN_NOTE_REQUEST: string;
  CREATE_ADMIN_NOTE_SUCCESS: string;
  CREATE_ADMIN_NOTE_FAILED: string;
  FETCH_USER_AUDIT_EVENTS_REQUEST: string;
  FETCH_USER_AUDIT_EVENTS_SUCCESS: string;
  FETCH_USER_AUDIT_EVENTS_FAILED: string;
};

export const ACTION_TYPES: UserDetailsActionType = Object.freeze({
  ...createPortalModuleActionTypes<UserDetailsSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    FETCH_USER_PROFILE_REQUEST: `${MODULE_ID}/fetch_user_profile`,
    FETCH_USER_PROFILE_SUCCESS: `${MODULE_ID}/fetch_user_profile_success`,
    FETCH_USER_PROFILE_FAILED: `${MODULE_ID}/fetch_user_profile_failed`,
    FETCH_ADMIN_NOTES_REQUEST: `${MODULE_ID}/fetch_admin_notes_request`,
    FETCH_ADMIN_NOTES_SUCCESS: `${MODULE_ID}/fetch_admin_notes_success`,
    FETCH_ADMIN_NOTES_FAILED: `${MODULE_ID}/fetch_admin_notes_failed`,
    CREATE_ADMIN_NOTE_REQUEST: `${MODULE_ID}/create_admin_note_request`,
    CREATE_ADMIN_NOTE_SUCCESS: `${MODULE_ID}/create_admin_note_success`,
    CREATE_ADMIN_NOTE_FAILED: `${MODULE_ID}/create_admin_note_failed`,
    FETCH_USER_AUDIT_EVENTS_REQUEST: `${MODULE_ID}/fetch_user_audit_events_request`,
    FETCH_USER_AUDIT_EVENTS_SUCCESS: `${MODULE_ID}/fetch_user_audit_events_success`,
    FETCH_USER_AUDIT_EVENTS_FAILED: `${MODULE_ID}/fetch_user_audit_events_failed`,
  },
});

export const UserDetailsReducer = combineReducers<UserDetailsSchema>(
  createPortalModuleReducers<UserDetailsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default UserDetailsReducer;
