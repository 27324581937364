import React from "react";
import WorkloadConfiguration from "@data/WorkloadConfiguration";
import { WorkloadKeyValueListColumn } from "./WorkloadKeyValueView";

export interface WorkloadKeyValueListItemProps {
  attribute: WorkloadConfiguration;
  column: WorkloadKeyValueListColumn;
}

type Props = WorkloadKeyValueListItemProps;

export const WorkloadKeyValueListItem = ((props: Props) => {

  const {
    column,
    attribute = WorkloadConfiguration.EMPTY,
  } = props;

  switch (column) {
    case WorkloadKeyValueListColumn.KEY:
      return <label className="key">{attribute.getKey()}</label>;
    case WorkloadKeyValueListColumn.VALUE:
      return <label className="value">{attribute.getValue()}</label>;
    default:
      return null;
  }
});

export default WorkloadKeyValueListItem;
