import React from "react";
import classnames from "classnames";
import { childrenView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  DSLayoutChildrenListView,
  DSLayoutChildrenListViewModel,
  DSLayoutChildrenListViewActions
} from "@components";
import Typography from "@material-ui/core/Typography";
import { DSTypeInfo } from "@data";
import { noop } from "@util";

export interface Model extends DSLayoutChildrenListViewModel {
  title?: string;
  types?: DSTypeInfo[];
  resetExpanded?: boolean;
  children?: React.ReactNode;
}

export interface Actions extends DSLayoutChildrenListViewActions {
  onClickAddChildren?: () => void;
  setParentNodeName?: (name: string) => void;
  removeChild?: (item: DSTypeInfo) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const ChildrenView = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Layout Children",
    types = [],
    showErrorView = false,
    resetExpanded = false,
    children,
    onClickAddChildren,
    setParentNodeName = noop,
    removeChild = noop,
    ...otherProps
  } = props;

  return (
    <div className={classnames("layoutChildrenView", classes.container)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      <DSLayoutChildrenListView
        {...otherProps}
        items={types}
        editMode={true}
        showAddItemButton={true}
        resetExpanded={resetExpanded}
        addItemLabel="Add Children"
        onClickAddItem={onClickAddChildren}
        setParentNodeName={setParentNodeName}
        onClickDeleteIcon={removeChild}
      />
      {children}
    </div>
  );
});

export default ChildrenView;
