import { PolicyClient } from "@network";
import { useApiRequestAnalytics } from "@hooks";

export type AttachPolicyToServiceApi = (serviceId: string, policyName: string) => Promise<void>;

export type DetachPolicyFromServiceApi = (serviceId: string, policyName: string) => Promise<void>;

export type AttachPolicyToGroupApi = (groupName: string, policyName: string) => Promise<void>;

export type DetachPolicyFromGroupApi = (groupName: string, policyName: string) => Promise<void>;

export type AttachPolicyToUserApi = (userId: string, policyName: string) => Promise<void>;

export type DetachPolicyFromUserApi = (userId: string, policyName: string) => Promise<void>;

export interface PolicyManagementClientApi {
  attachPolicyToService: AttachPolicyToServiceApi;
  detachPolicyFromService: DetachPolicyFromServiceApi;
  attachPolicyToGroup: AttachPolicyToGroupApi;
  detachPolicyFromGroup: DetachPolicyFromGroupApi;
  attachPolicyToUser: AttachPolicyToUserApi;
  detachPolicyFromUser: DetachPolicyFromUserApi;
}

enum ActionType {

  ATTACH_POLICY_TO_SERVICE_REQUEST = "policy_management_client/attach_policy_to_service_request",
  ATTACH_POLICY_TO_SERVICE_SUCCESS = "policy_management_client/attach_policy_to_service_success",
  ATTACH_POLICY_TO_SERVICE_FAILED = "policy_management_client/attach_policy_to_service_failed",

  DETACH_POLICY_FROM_SERVICE_REQUEST = "policy_management_client/detach_policy_from_service_request",
  DETACH_POLICY_FROM_SERVICE_SUCCESS = "policy_management_client/detach_policy_from_service_success",
  DETACH_POLICY_FROM_SERVICE_FAILED = "policy_management_client/detach_policy_from_service_failed",

  ATTACH_POLICY_TO_GROUP_REQUEST = "policy_management_client/attach_policy_to_group_request",
  ATTACH_POLICY_TO_GROUP_SUCCESS = "policy_management_client/attach_policy_to_group_success",
  ATTACH_POLICY_TO_GROUP_FAILED = "policy_management_client/attach_policy_to_group_failed",

  DETACH_POLICY_FROM_GROUP_REQUEST = "policy_management_client/detach_policy_from_group_request",
  DETACH_POLICY_FROM_GROUP_SUCCESS = "policy_management_client/detach_policy_from_group_success",
  DETACH_POLICY_FROM_GROUP_FAILED = "policy_management_client/detach_policy_from_group_failed",

  ATTACH_POLICY_TO_USER_REQUEST = "policy_management_client/attach_policy_to_user_request",
  ATTACH_POLICY_TO_USER_SUCCESS = "policy_management_client/attach_policy_to_user_success",
  ATTACH_POLICY_TO_USER_FAILED = "policy_management_client/attach_policy_to_user_failed",

  DETACH_POLICY_FROM_USER_REQUEST = "policy_management_client/detach_policy_from_user_request",
  DETACH_POLICY_FROM_USER_SUCCESS = "policy_management_client/detach_policy_from_user_success",
  DETACH_POLICY_FROM_USER_FAILED = "policy_management_client/detach_policy_from_user_failed",
}

export const useAttachPolicyToServiceApi = (): AttachPolicyToServiceApi =>
  useApiRequestAnalytics(PolicyClient.attachPolicyToService, {
    REQUEST_EVENT: ActionType.ATTACH_POLICY_TO_SERVICE_REQUEST,
    SUCCESS_EVENT: ActionType.ATTACH_POLICY_TO_SERVICE_SUCCESS,
    ERROR_EVENT: ActionType.ATTACH_POLICY_TO_SERVICE_FAILED,
  });

export const useDetachPolicyFromServiceApi = (): DetachPolicyFromServiceApi =>
  useApiRequestAnalytics(PolicyClient.detachPolicyFromService, {
    REQUEST_EVENT: ActionType.DETACH_POLICY_FROM_SERVICE_REQUEST,
    SUCCESS_EVENT: ActionType.DETACH_POLICY_FROM_SERVICE_SUCCESS,
    ERROR_EVENT: ActionType.DETACH_POLICY_FROM_SERVICE_FAILED,
  });

export const useAttachPolicyToUserApi = (): AttachPolicyToUserApi =>
  useApiRequestAnalytics(PolicyClient.attachPolicyToUser, {
    REQUEST_EVENT: ActionType.ATTACH_POLICY_TO_USER_REQUEST,
    SUCCESS_EVENT: ActionType.ATTACH_POLICY_TO_USER_SUCCESS,
    ERROR_EVENT: ActionType.ATTACH_POLICY_TO_USER_FAILED,
  });

export const useDetachPolicyFromUserApi = (): DetachPolicyFromUserApi =>
  useApiRequestAnalytics(PolicyClient.detachPolicyFromUser, {
    REQUEST_EVENT: ActionType.DETACH_POLICY_FROM_USER_REQUEST,
    SUCCESS_EVENT: ActionType.DETACH_POLICY_FROM_USER_SUCCESS,
    ERROR_EVENT: ActionType.DETACH_POLICY_FROM_USER_FAILED,
  });

export const useAttachPolicyToGroupApi = (): AttachPolicyToGroupApi =>
  useApiRequestAnalytics(PolicyClient.attachPolicyToGroup, {
    REQUEST_EVENT: ActionType.ATTACH_POLICY_TO_GROUP_REQUEST,
    SUCCESS_EVENT: ActionType.ATTACH_POLICY_TO_GROUP_SUCCESS,
    ERROR_EVENT: ActionType.ATTACH_POLICY_TO_GROUP_FAILED,
  });

export const useDetachPolicyFromGroupApi = (): DetachPolicyFromGroupApi =>
  useApiRequestAnalytics(PolicyClient.detachPolicyFromGroup, {
    REQUEST_EVENT: ActionType.DETACH_POLICY_FROM_GROUP_REQUEST,
    SUCCESS_EVENT: ActionType.DETACH_POLICY_FROM_GROUP_SUCCESS,
    ERROR_EVENT: ActionType.DETACH_POLICY_FROM_GROUP_FAILED,
  });

export const usePolicyManagementClient = (): PolicyManagementClientApi => ({
  attachPolicyToService: useAttachPolicyToServiceApi(),
  detachPolicyFromService: useDetachPolicyFromServiceApi(),
  attachPolicyToGroup: useAttachPolicyToGroupApi(),
  detachPolicyFromGroup: useDetachPolicyFromGroupApi(),
  attachPolicyToUser: useAttachPolicyToUserApi(),
  detachPolicyFromUser: useDetachPolicyFromUserApi(),
});

export { ActionType as PolicyManagementClientActionType };

export default usePolicyManagementClient;
