import React from "react";
import { noop } from "@util";
import { AccessRequestType } from "@data";
import DataSetsView from "../components/DataSetsView";
import { getPathToDataSet } from "@modules/dataSetDetails/helpers";
import { getDataSetId } from "@modules/dataWorkloadWizard/helpers";
import { useCurrentAccountId, useListAccessibleDataSets } from "@hooks";
import { DataSetsActions, DataSetsModel, DataSetsListItemData } from "@components";
import { CreateWorkloadRequest, CreateWorkloadRequestAttributes } from "@data/CreateWorkloadRequest";

interface Model extends DataSetsModel {
  title?: string;
  data?: CreateWorkloadRequest;
}

interface Actions extends DataSetsActions {
  setWorkloadData?: (data: Partial<CreateWorkloadRequestAttributes>) => void;
  showDataSetDetailsInNewTab: (dataSet: DataSetsListItemData) => string;
}

type Props = Model & Actions;

const DataOutputsView = (props: Props) => {

  const {
    className = "dataOutputsView",
    summaryViewLabel = "Data Set Outputs (optional)",
    title = "Select data set output(s) required by this workload",
    selectedItems = [],
    data = CreateWorkloadRequest.EMPTY,
    setWorkloadData = noop,
    showDataSetDetailsInNewTab,
    ...otherProps
  } = props;

  const [{ accessibleDataSets: dataOutputs, ...dataOutputsModel }, dataOutputsActions] =
    useListAccessibleDataSets({ accessRequestType: AccessRequestType.WRITE });

  const accountId = useCurrentAccountId();

  React.useEffect(() => {
    setWorkloadData({
      data: {
        ...data.getData(),
        outputs: selectedItems.map(({name: dataSetAlias}) => ({ accountId, dataSetAlias })),
      },
    });
  }, [selectedItems]);

  const outputs = React.useMemo(() => {
    return data.isQueryType() ? dataOutputs.filter(
      dataSet => dataSet.isDataWarehouseDataSetType()) : dataOutputs;
  }, [data, dataOutputs]);

  // Data Lake Acquired is a type of data set for which the data can be written only using
  // IoT platform ingestion service APIs. Thus, it should not be possible for a workload to
  // select a data set that is of type Data Lake Acquired as an output of the workload.
  const filteredDataOutputs = React.useMemo(() => outputs
    .filter(dataSet => !dataSet.isDataLakeAcquiredDataSetType())
    .map(dataSet => dataSet.toJS()),
    [outputs]);

  const items = React.useMemo(() =>
    filteredDataOutputs.map(dataSet => new DataSetsListItemData({
      id: getDataSetId(dataSet.accountId, dataSet.dataSetAlias),
      name: dataSet.dataSetAlias,
      account: dataSet.accountId,
      dataSetType: dataSet.dataSetType,
      href: getPathToDataSet(getDataSetId(dataSet.accountId, dataSet.dataSetAlias)),
    })), [filteredDataOutputs]);

  return (
    <DataSetsView
      {...otherProps}
      {...dataOutputsModel}
      {...dataOutputsActions}
      className={className}
      title={title}
      items={items}
      selectedItems={selectedItems}
      summaryViewLabel={summaryViewLabel}
      onClickShowMoreInfoInNewTab={showDataSetDetailsInNewTab}
    />
  );
};

export default DataOutputsView;
