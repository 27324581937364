import React from "react";
import classnames from "classnames";
import RemoveIcon from "@material-ui/icons/Clear";
import TextField from "@components/text-field";
import AddIcon from "@material-ui/icons/AddOutlined";
import { IconButton } from "@components";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { JsonSchemaPropertyDefaultValueType, JsonSchemaPropertyType } from "../../../../data";
import { withTextFieldTooltip } from "@components/tooltip/TextFieldTooltip";
import { Chip } from "@components";
import { enumTextField, styles } from "./styles";
import {
  clickHandler,
  enterKeyHandler,
  formEventHandler,
  noop,
  parseBoolean,
} from "../../../../util";

export const ENUM_VALUE_TOOLTIP = "An instance validates against this keyword if its value " +
  "equals can be found in the items defined by the value of this keyword.";

const EnumTextField = withTextFieldTooltip(ENUM_VALUE_TOOLTIP)(withStyles(enumTextField)(TextField));

export interface Model {
  visible?: boolean;
  propertyType?: JsonSchemaPropertyType;
  enumValueError?: string;
  enumValues?: JsonSchemaPropertyDefaultValueType[];
  disabled?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  clearEnumValueError?: () => void;
  addEnumValue?: (value: JsonSchemaPropertyDefaultValueType) => void;
  removeEnumValue?: (value: JsonSchemaPropertyDefaultValueType) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const EnumValueEditor = withStyles(styles)((props: Props) => {

  const {
    classes,
    visible,
    propertyType = JsonSchemaPropertyType.STRING,
    enumValueError = "",
    enumValues = [],
    disabled = false,
    addEnumValue = noop,
    removeEnumValue = noop,
    clearEnumValueError = noop,
  } = props;

  if (!visible) {
    return null;
  }

  const formHelperTextProps = {
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const [value, setValue] = React.useState("");

  const updateValue = (updatedValue: string) => {
    clearEnumValueError();
    setValue(updatedValue);
  };

  const addValue = () => {
    clearEnumValueError();
    setValue("");

    switch (propertyType) {
      case JsonSchemaPropertyType.STRING:
        return addEnumValue(value);
      case JsonSchemaPropertyType.NUMBER:
      case JsonSchemaPropertyType.INTEGER:
        return addEnumValue(Number(value));
      case JsonSchemaPropertyType.BOOLEAN:
        return addEnumValue(parseBoolean(value));
      case JsonSchemaPropertyType.NULL:
        if (value === "null") {
          return addEnumValue(null);
        } else {
          return addEnumValue(value);
        }
      default:
        return addEnumValue(value);
    }
  };

  const removeValue = (enumValue: JsonSchemaPropertyDefaultValueType) => () => {
    clearEnumValueError();
    removeEnumValue(enumValue);
  };

  const onEnterPressed = () => {
    if (value.trim().length > 0) {
      return addValue();
    }
  };

  return (
    <div className={classnames("enumValueEditor", classes.container)}>
      <div className={classnames("enumValueInputEditor", classes.enumValueEditor)}>
        <EnumTextField
          className={classnames("enumValueInput", classes.enumValueInput)}
          label="Enum Values (optional)"
          placeholder="Add allowed value (optional)"
          name="enum"
          value={value}
          fullWidth={false}
          disabled={disabled}
          autoComplete="off"
          helperText={enumValueError}
          variant="outlined"
          margin="none"
          error={enumValueError.length > 0}
          onChange={formEventHandler(updateValue)}
          onKeyDown={enterKeyHandler(onEnterPressed)}
          FormHelperTextProps={formHelperTextProps}
          InputLabelProps={inputLabelProps}
        />
        {value.trim().length > 0 && (
          <IconButton
            className={classnames("addEnumValueButton", classes.addEnumValueButton)}
            color="primary"
            disabled={disabled}
            onClick={clickHandler(addValue)}
          >
            <AddIcon className={classnames("addEnumValueIcon", classes.addEnumValueIcon)} />
          </IconButton>
        )}
      </div>
      <div className={classnames("enumValues", classes.enumValues)}>
        {enumValues.map((enumValue: JsonSchemaPropertyDefaultValueType, index: number) => {

          const extraProps = disabled ? {} : {
            deleteIcon: <RemoveIcon className={classnames("removeIcon", classes.enumValueIcon)} />,
            onDelete: removeValue(enumValue),
          };

          return (
            <Chip
              { ...extraProps }
              key={`enum-value-${index}`}
              label={enumValue + ""}
              color={!disabled ? "primary" : "default"}
              className={classnames("enumValue", enumValue + "", classes.enumValue, {
                [classes.disabled]: disabled
              })}
            />
          );
        })}
      </div>
    </div>
  );
});

export default EnumValueEditor;
