import React from "react";
import { noop } from "@util";
import { ActionMenuItem } from "@components/actions-menu";
import { PrimaryIcon } from "@modules/dataApprovalRequests/components/styles";
import DataApprovalRequestsListColumn from "./DataApprovalRequestsListColumn";
import { ApprovalRequestType, DataApprovalRequest, RequestState } from "@data";
import { DataApprovalRequestAction } from "@components/data-approval-request-actions-menu";
import {
  getApprovalRequestTypeDisplayName,
  getPathToDataSetApprovalRequest,
  getRequestStateDisplayName,
} from "@modules/dataApprovalRequests/helpers";
import ModuleListView, {
  createColumns,
  ListViewItem,
  ModuleListViewActions,
  ModuleListViewModel,
} from "@components/module-list-view";

type Column = DataApprovalRequestsListColumn;
type Item = DataApprovalRequest;

const DEFAULT_DATA_APPROVAL_REQUESTS_LIST_COLUMNS: Column[] = [
  DataApprovalRequestsListColumn.NAME,
  DataApprovalRequestsListColumn.ACCOUNT,
  DataApprovalRequestsListColumn.STATUS,
  DataApprovalRequestsListColumn.TYPE,
  DataApprovalRequestsListColumn.REQUESTED_BY,
];

const DEFAULT_MAP_ITEM_TO_ACTION = (item: Item, action: ActionMenuItem) => {
  switch (action.id) {
    case DataApprovalRequestAction.APPROVE:
    case DataApprovalRequestAction.REJECT:
      return {
        ...action,
        disabled: !item.isPending(),
      };
    default:
      return action;
  }
};

export interface DataApprovalRequestsListModel extends ModuleListViewModel<Item, Column> {
}

export interface DataApprovalRequestsListActions extends ModuleListViewActions<Item, Column> {
  showDataApprovalRequestDetails?: (dataApprovalRequest: DataApprovalRequest) => void;
  approveDataApprovalRequest?: (dataApprovalRequest: DataApprovalRequest) => void;
  rejectDataApprovalRequest?: (dataApprovalRequest: DataApprovalRequest) => void;
}

type Model = DataApprovalRequestsListModel;
type Actions = DataApprovalRequestsListActions;
type Props = Model & Actions;

export const DataApprovalRequestsList = (props: Props) => {

  const {
    items = [],
    columns = DEFAULT_DATA_APPROVAL_REQUESTS_LIST_COLUMNS,
    actions = [],
    tableLayoutFixed = false,
    summaryViewIcon = PrimaryIcon,
    nameFilter: initialNameFilter = "",
    noResultsLabel = "No Data Approval Requests Found",
    summaryViewQuantities = {
      other: "data approval requests",
      one: "data approval request",
    },
    showDataApprovalRequestDetails,
    onClickItem = showDataApprovalRequestDetails,
    approveDataApprovalRequest: approveRequest = noop,
    rejectDataApprovalRequest: rejectRequest = noop,
    onClickAction = noop,
    setNameFilter: updateNameFilter = noop,
    mapItemToAction = DEFAULT_MAP_ITEM_TO_ACTION,
    ...otherProps
  } = props;

  const [nameFilter, setNameFilter] = React.useState(initialNameFilter);

  const onActionClicked = React.useCallback((item, action) => {
    switch (action.id) {
      case DataApprovalRequestAction.APPROVE:
        return approveRequest(item);
      case DataApprovalRequestAction.REJECT:
        return rejectRequest(item);
      default:
        return onClickAction(item, action);
    }
  }, [
    approveRequest,
    rejectRequest,
    onClickAction,
  ]);

  // In case parent is maintaining state, make sure local state changes are propagated up
  React.useEffect(() => {
    updateNameFilter(nameFilter);
  }, [nameFilter, updateNameFilter]);

  const moduleListItems: ListViewItem<DataApprovalRequest>[] = React.useMemo(() =>
    items.map(request => {
      return {
        item: request,
        pathToDetailsView: getPathToDataSetApprovalRequest(request.getApprovalRequestId()),
        icon: PrimaryIcon,
        columnAttributes: createColumns([
          {
            className: "requestName",
            value: request.getName(),
            column: DataApprovalRequestsListColumn.NAME,
            firstColumn: true,
          },
          {
            className: "requestAccount",
            value: request.getAccountId(),
            column: DataApprovalRequestsListColumn.ACCOUNT,
          },
          {
            className: "requestStatus",
            value: getRequestStateDisplayName(RequestState[request.getStatus()]),
            column: DataApprovalRequestsListColumn.STATUS,
          },
          {
            className: "requestType",
            value: getApprovalRequestTypeDisplayName(ApprovalRequestType[request.getType()]),
            column: DataApprovalRequestsListColumn.TYPE,
          },
          {
            className: "requestSender",
            value: request.getRequestedBy(),
            column: DataApprovalRequestsListColumn.REQUESTED_BY,
          },
        ]),
      };
    }), [items]);

  return (
    <ModuleListView
      {...otherProps}
      columns={columns}
      actions={actions}
      tableLayoutFixed={tableLayoutFixed}
      summaryViewIcon={summaryViewIcon}
      listViewItems={moduleListItems}
      nameFilter={nameFilter}
      noResultsLabel={noResultsLabel}
      summaryViewQuantities={summaryViewQuantities}
      onClickItem={onClickItem}
      onClickAction={onActionClicked}
      setNameFilter={setNameFilter}
      mapItemToAction={mapItemToAction}
    />
  );
};

export default DataApprovalRequestsList;
