import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import { Tab } from "@components/tab";
import { Tabs as TabsComponent } from "@components/tabs";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles, { tabs } from "./styles";

const Tabs = withStyles(tabs)(TabsComponent);

export enum DashboardsTabType {
  PUBLISHED = "published",
  UNPUBLISHED = "unpublished",
}

export interface DashboardsTabsModel {
  className?: string;
  selectedTab?: DashboardsTabType;
  publishedLabel?: string;
  unpublishedLabel?: string;
}

export interface DashboardsTabsActions {
  showPublishedView?: () => void;
  showUnpublishedView?: () => void;
}

type Props = WithStyles<typeof styles> & DashboardsTabsModel & DashboardsTabsActions;

export const DashboardsTabs = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    selectedTab = DashboardsTabType.PUBLISHED,
    publishedLabel = "Published Dashboards",
    unpublishedLabel = "Unpublished Dashboards",
    showPublishedView = noop,
    showUnpublishedView = noop,
  } = props;

  const setSelectedTab = React.useCallback((tab: DashboardsTabType) => {
    if (selectedTab !== tab) {
      switch (tab) {
        case DashboardsTabType.UNPUBLISHED:
          return showUnpublishedView();
        case DashboardsTabType.PUBLISHED:
        default:
          return showPublishedView();
      }
    }
  }, [
    selectedTab,
    showPublishedView,
    showUnpublishedView,
  ]);

  return (
    <Tabs
      className={classnames("dashboardsTabs", className, classes.container)}
      value={selectedTab}
      onChange={(event, value) => {
        if (value) {
          setSelectedTab(value);
        }
      }}
    >
      <Tab
        className={classnames("publishedTab", classes.tab)}
        label={publishedLabel}
        value={DashboardsTabType.PUBLISHED}
      />
      <Tab
        className={classnames("unpublishedTab", classes.tab)}
        label={unpublishedLabel}
        value={DashboardsTabType.UNPUBLISHED}
      />
    </Tabs>
  );
});

export default DashboardsTabs;
