import Color from "@Color";
import ModuleIcon from "@material-ui/icons/Work";
import createStyles from "@material-ui/core/styles/createStyles";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    filtersMenu: {
      marginLeft: 15,
    },
    appliedFilters: {
      marginTop: 15,
      marginLeft: 4,
      paddingBottom: 20,
      borderBottom: `1px solid ${Color.LIGHT_GREY}`,
      "& + table.list": {
        marginTop: 0,
      },
      "& .searchFilters": {
        marginTop: 13,
        flexFlow: "row nowrap",
        alignItems: "center",
        "& .searchFilter": {
          marginTop: 0,
          marginLeft: 8,
          "&:first-child": {
            marginLeft: 0,
          },
        },
      },
    },
    staticLayout: {
      "& th.name, & td.name": {
        width: "27%",
      },
      "& th.createdBy, & td.createdBy": {
        width: "20%",
      },
      "& th.createdAt, & td.createdAt": {
        width: "20%",
      },
      "& th.tags, & td.tags": {
        width: "17%",
        "& ul li .chip": {
          cursor: "inherit",
        },
      },
      "& th.deployed, & td.deployed": {
        width: "8%",
      },
      "& th.deleteIconButton, & td.deleteIconButton": {
        width: "8%",
      },
    },
  });

export default styles;
