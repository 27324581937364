import { connect } from "react-redux";
import { AppSchema } from "../../main/schemas";
import { PropertyEditorSelectors } from "../selectors/propertyEditor";
import { IntegerConstraintsActions } from "../actions/integerConstraints";
import { IntegerConstraintsSelectors } from "../selectors/integerConstraints";
import { NumericConstraintsEditor, Model, Actions } from "../components/ConstraintsEditor/NumericConstraintsEditor";
import { PropertyEditorActions } from "@modules/schemaWizard/actions/propertyEditor";

const mapStateToProps = (state: AppSchema): Model => ({
  minimum: IntegerConstraintsSelectors.getMinimum(state),
  minimumError: IntegerConstraintsSelectors.getMinimumError(state),
  exclusiveMinimum: IntegerConstraintsSelectors.getExclusiveMinimum(state),
  exclusiveMinimumError: IntegerConstraintsSelectors.getExclusiveMinimumError(state),
  maximum: IntegerConstraintsSelectors.getMaximum(state),
  maximumError: IntegerConstraintsSelectors.getMaximumError(state),
  exclusiveMaximum: IntegerConstraintsSelectors.getExclusiveMaximum(state),
  exclusiveMaximumError: IntegerConstraintsSelectors.getExclusiveMaximumError(state),
  multipleOf: IntegerConstraintsSelectors.getMultipleOf(state),
  multipleOfError: IntegerConstraintsSelectors.getMultipleOfError(state),
  disabled: PropertyEditorSelectors.isProgressIndicatorVisible(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  setMinimum: (value: string) => dispatch(IntegerConstraintsActions.updateMinimum(value)),
  setExclusiveMinimum: (value: string) => dispatch(IntegerConstraintsActions.updateExclusiveMinimum(value)),
  setMaximum: (value: string) => dispatch(IntegerConstraintsActions.updateMaximum(value)),
  setExclusiveMaximum: (value: string) => dispatch(IntegerConstraintsActions.updateExclusiveMaximum(value)),
  setMultipleOf: (value: string) => dispatch(IntegerConstraintsActions.updateMultipleOf(value)),
  save: () => dispatch(PropertyEditorActions.save()),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(NumericConstraintsEditor);
