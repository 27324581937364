import { createSelector } from "reselect";
import { createSelectors, Selector } from "@base/createSelectors";
import { CreatePolicySchema, DEFAULT_STATE } from "./reducers";
import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { Policy } from "@data";

export const {
  json: getJson,
  policyRef: getPolicyRef,
  policyName: getPolicyName,
  errorMessage: getErrorMessage,
  successMessage: getSuccessMessage,
  showEmptyView: isEmptyViewVisible,
  showAccessDenied: isAccessDeniedVisible,
  showLoadingIndicator: isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  getCurrentAccountId,
} = createSelectors<CreatePolicySchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getPolicyRefName: Selector<string> = createSelector(
  getPolicyRef, (policyRef: string) => policyRef.split(":").pop() || "");

export const getPolicyTemplate: Selector<Policy> = createSelector(
  getCurrentAccountId, (accountId: string) => new Policy({
    info: {
      name: "",
      description: "",
      path: "/",
      version: "2016-05-22",
    },
    statements: [
      {
        action: "",
        effect: "Allow",
        resource: `lrn:iot:aam::${accountId}:::`,
      },
    ],
  }));

export const getDefaultJson: Selector<string> = createSelector(
  getPolicyTemplate, (template: Policy) =>
    JSON.stringify(template.toJS(), null, "  "));

export const getPolicy: Selector<Policy> = createSelector(
  [getJson, getPolicyTemplate], (json: string, template: Policy) => {
    try {
      return new Policy(JSON.parse(json));
    } catch {
      return template;
    }
  });

export const isSaveButtonDisabled: Selector<boolean> = createSelector(
  getPolicy, (policy: Policy) => !policy.hasName());

export const getCreatedPolicy: Selector<Policy> = createSelector(
  [getPolicyRefName, getPolicy], (name: string, policy: Policy) => {
    return new Policy({
      ...policy.toJS(),
      info: {
        ...policy.getInfo().toJS(),
        name,
      },
    });
  });
