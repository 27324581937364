import React from "react";
import classnames from "classnames";
import TextField from "@components/text-field";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { enterKeyHandler, formEventHandler, noop } from "../../../../util";
import { styles, textField } from "./styles";

const DefaultValueTextField = withStyles(textField)(TextField);

export interface Model {
  className?: string;
  defaultValue?: number;
  defaultValueError?: string;
  disabled?: boolean;
}

export interface Actions {
  setDefaultValue?: (defaultValue: string) => void;
  clearDefaultValueError?: () => void;
  save?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const NumberDefaultValueEditor = withStyles(styles)((props: Props) => {

  const {
    classes,
    className = "numberDefaultValueEditor",
    defaultValue = "",
    defaultValueError = "",
    disabled = false,
    setDefaultValue = noop,
    clearDefaultValueError = noop,
    save = noop,
  } = props;

  const formHelperTextProps = {
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const updateDefaultValue = (updatedDefaultValue: string = "") => {
    clearDefaultValueError();
    setDefaultValue(updatedDefaultValue);
  };

  return (
    <div className={classnames(className, classes.container)}>
      <DefaultValueTextField
        className={classnames("defaultValue", classes.inputField)}
        autoComplete="off"
        label="Default Value (optional)"
        placeholder="Add a default value for this property (optional)"
        name="default"
        value={defaultValue}
        fullWidth={true}
        disabled={disabled}
        helperText={defaultValueError}
        variant="outlined"
        margin="none"
        error={defaultValueError.length > 0}
        onChange={formEventHandler(updateDefaultValue)}
        onKeyDown={enterKeyHandler(save)}
        FormHelperTextProps={formHelperTextProps}
        InputLabelProps={inputLabelProps}
      />
    </div>
  );
});

export default NumberDefaultValueEditor;
