import { createSelector } from "reselect";
import { AppSchema } from "@schemas";
import { SchemaAction } from "../../schemaStateManager/reducers";
import { ActionMenuItem } from "../../../components/actions-menu";
import {
  JsonSchemaDefinition,
  JsonSchemaMetadata,
  JsonSchemaMetadataAttributes,
  JsonSchemaPropertiesDefinition,
  JsonSchemaProperty,
  JsonSchemaState,
} from "@data";

// Each item starts off disabled and will switch depending on state of active schema
export const DEFAULT_ACTION_MENU_ITEMS: ActionMenuItem[] = [
  {
    id: SchemaAction.EDIT,
    name: "Edit Schema",
    disabled: true,
  },
  {
    id: SchemaAction.DRAFT,
    name: "Draft New Schema Version",
    disabled: true,
  },
  {
    id: SchemaAction.PROMOTE,
    name: "Promote Schema",
    disabled: true,
  },
  {
    id: SchemaAction.DEPRECATE,
    name: "Deprecate Schema",
    disabled: true,
  },
  {
    id: SchemaAction.DECOMMISSION,
    name: "Decommission Schema",
    disabled: true,
  },
  {
    id: SchemaAction.DELETE,
    name: "Delete Schema",
    disabled: true,
  },
];

export const getJson = (state: AppSchema): string => {
  return state.schemaDetails.json;
};

export const getSchema = (state: AppSchema): JsonSchemaDefinition => {
  return state.schemaDetails.schema;
};

export const getMetaData = (state: AppSchema): JsonSchemaMetadataAttributes => {
  return state.schemaDetails.metaData;
};

export const getErrorMessage = (state: AppSchema): string => {
  return state.schemaDetails.errorMessage;
};

export const isEmptyViewVisible = (state: AppSchema): boolean => {
  return state.schemaDetails.showEmptyView;
};

export const isAccessDeniedVisible = (state: AppSchema): boolean => {
  return state.schemaDetails.showAccessDenied;
};

export const isLoadingIndicatorVisible = (state: AppSchema): boolean => {
  return state.schemaDetails.showLoadingIndicator;
};

export const isNotFoundVisible = (state: AppSchema): boolean => {
  return state.schemaDetails.showNotFound;
};

export const isLatest = (state: AppSchema): boolean => {
  return state.schemaDetails.isLatest;
};

export const getSchemaProperties: (state: AppSchema) => JsonSchemaPropertiesDefinition = createSelector(
  getSchema, (schema: JsonSchemaDefinition) => {

    const { properties = {} } = schema;

    // Return a copy to prevent updates to the original
    return {
      ...(typeof properties === "object" ? properties : {}),
    };
  });

export const getRequiredSchemaProperties: (state: AppSchema) => string[] = createSelector(
  getSchema, (schema: JsonSchemaDefinition) => {

    const { required = [] } = schema;

    // Return a copy to prevent updates to the original
    return Array.isArray(required) ? required.slice() : [];
  });

export const getJsonSchemaProperties: (state: AppSchema) => JsonSchemaProperty[] = createSelector(
  [getSchemaProperties, getRequiredSchemaProperties],
  (properties: JsonSchemaPropertiesDefinition, requiredProperties: string[]) => {

    return Object.keys(properties).map((name: string) =>
      JsonSchemaProperty.from(name, properties[name], requiredProperties));
  });

export const getSchemaMetaData: (state: AppSchema) => JsonSchemaMetadata = createSelector(
  getMetaData, (metaData: JsonSchemaMetadataAttributes) =>
    new JsonSchemaMetadata(metaData));

export const getSchemaId: (state: AppSchema) => string = createSelector(
  getSchemaMetaData, (schemaMetaData: JsonSchemaMetadata) =>
    schemaMetaData.getId());

export const getSchemaNamespace: (state: AppSchema) => string = createSelector(
  getSchemaMetaData, (schemaMetaData: JsonSchemaMetadata) =>
    schemaMetaData.namespace);

export const getSchemaName: (state: AppSchema) => string = createSelector(
  getSchemaMetaData, (schemaMetaData: JsonSchemaMetadata) =>
    schemaMetaData.name);

export const getSchemaVersion: (state: AppSchema) => string = createSelector(
  getSchemaMetaData, (schemaMetaData: JsonSchemaMetadata) =>
    `${schemaMetaData.version}`);

export const getSchemaState: (state: AppSchema) => JsonSchemaState = createSelector(
  getSchemaMetaData, (schemaMetaData: JsonSchemaMetadata) =>
    schemaMetaData.state);

export const isDraftSchema: (state: AppSchema) => boolean = createSelector(
  getSchemaState, (state: JsonSchemaState) =>
    state === JsonSchemaState.DRAFT);

export const isReleasedSchema: (state: AppSchema) => boolean = createSelector(
  getSchemaState, (state: JsonSchemaState) =>
    state === JsonSchemaState.RELEASED);

export const isDeprecatedSchema: (state: AppSchema) => boolean = createSelector(
  getSchemaState, (state: JsonSchemaState) =>
    state === JsonSchemaState.DEPRECATED);

export const isDecommissionedSchema: (state: AppSchema) => boolean = createSelector(
  getSchemaState, (state: JsonSchemaState) =>
    state === JsonSchemaState.DECOMMISSIONED);

export const isDraftButtonEnabled: (state: AppSchema) => boolean = createSelector(
  [isLatest, isReleasedSchema],
  (latest: boolean, isReleased: boolean) =>
    latest && isReleased);

export const isPromoteButtonEnabled: (state: AppSchema) => boolean = createSelector(
  [isDraftSchema, isDeprecatedSchema],
  (isDraft: boolean, isDeprecated: boolean) =>
    isDraft || isDeprecated);

export const isDeprecateButtonEnabled: (state: AppSchema) => boolean = createSelector(
  [isReleasedSchema, isDecommissionedSchema],
  (isReleased: boolean, isDecommissioned: boolean) =>
    isReleased || isDecommissioned);

export const isDecommissionButtonEnabled: (state: AppSchema) => boolean = createSelector(
  isDeprecatedSchema, (isDeprecated: boolean) => isDeprecated);

export const isDeleteButtonEnabled: (state: AppSchema) => boolean = createSelector(
  [isDraftSchema, isDecommissionedSchema],
  (isDraft: boolean, isDecommissioned: boolean) =>
    isDraft || isDecommissioned);

export const getActionMenuItems: (state: AppSchema) => ActionMenuItem[] = createSelector(
  [
    isDraftSchema,
    isDraftButtonEnabled,
    isPromoteButtonEnabled,
    isDeprecateButtonEnabled,
    isDecommissionButtonEnabled,
    isDeleteButtonEnabled,
  ],
  (draftSchema: boolean,
   draftEnabled: boolean,
   promoteEnabled: boolean,
   deprecateEnabled: boolean,
   decommissionEnabled: boolean,
   deleteEnabled: boolean) => {

    return DEFAULT_ACTION_MENU_ITEMS.map((action: ActionMenuItem) => {

      switch (action.id) {
        case SchemaAction.EDIT:
          return {
            ...action,
            disabled: !draftSchema,
          };
        case SchemaAction.DRAFT:
          return {
            ...action,
            disabled: !draftEnabled,
          };
        case SchemaAction.PROMOTE:
          return {
            ...action,
            disabled: !promoteEnabled,
          };
        case SchemaAction.DEPRECATE:
          return {
            ...action,
            disabled: !deprecateEnabled,
          };
        case SchemaAction.DECOMMISSION:
          return {
            ...action,
            disabled: !decommissionEnabled,
          };
        case SchemaAction.DELETE:
          return {
            ...action,
            disabled: !deleteEnabled,
          };
        default:
          return action;
      }
    });
  });
