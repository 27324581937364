import React from "react";
import classnames from "classnames";
import { childrenView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  DSLayoutChildrenListView,
  DSLayoutChildrenListViewModel,
  DSLayoutChildrenListViewActions
} from "@components";
import Typography from "@material-ui/core/Typography";
import { DSTypeInfo } from "@data";

export interface Model extends DSLayoutChildrenListViewModel {
  types?: DSTypeInfo[];
  showErrorView?: boolean;
  children?: React.ReactNode;
}

export interface Actions extends DSLayoutChildrenListViewActions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const ChildrenView = withStyles(styles)((props: Props) => {

  const {
    classes,
    types = [],
    showErrorView = false,
    children,
    ...otherProps
  } = props;

  if (showErrorView || types.length < 1) {
    return null;
  }

  return (
    <div className={classnames("childrenView", classes.container)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        Children
      </Typography>
      <DSLayoutChildrenListView
        items={types}
        {...otherProps}
      />
      {children}
    </div>
  );
});

export default ChildrenView;
