import React from "react";
import { useApiRequest, UseApiRequestModel, useEmailTemplateClient } from "@hooks";
import { GetEmailLanguagesResponse } from "@network";
import EmailTemplateLang from "@data/EmailTemplateLanguages";

type SuccessResponse = GetEmailLanguagesResponse;

export interface UseEmailTemplateLangProps {}

export interface UseEmailTemplateLangModel extends UseApiRequestModel<SuccessResponse> {
  emailLanguages: EmailTemplateLang;
}

export interface UseEmailTemplateLangActions {
  refresh: () => void;
}

type Props = UseEmailTemplateLangProps;
type Model = UseEmailTemplateLangModel;
type Actions = UseEmailTemplateLangActions;
type Result = [Model, Actions];

export const useEmailTemplateLanguages = (props: Props = {}): Result => {

  const {
    ...otherProps
  } = props;

  const EmailTemplateClient = useEmailTemplateClient();

  const defaultErrorMessage = React.useMemo(() =>
      "Failed to get email languages" , []);

  const [{ successResponse, ...baseModel }, { refresh }] =
      useApiRequest<SuccessResponse>({
        ...otherProps,
        defaultErrorMessage,
        makeApiRequest: () => EmailTemplateClient.getEmailTemplateLang(),
      });

  const emailLanguages = React.useMemo(() => {
    const attrs = successResponse || {};
    return new EmailTemplateLang(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    emailLanguages,
  }), [
    baseModel,
    emailLanguages,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    refresh,
  }), [
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEmailTemplateLanguages;
