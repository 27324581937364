import React from "react";
import { Location } from "history";
import { JsonSchemaMetadata } from "@data";
import { getPathToSchema } from "@modules/schemaDetails/helpers";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Color, ErrorView, LoadingView } from "@components";
import { useSchemaRef } from "@hooks";
import SchemaWizard from "./containers/SchemaWizard";

type LocationProps = {
  from?: Location;
  goBackOnSuccess?: boolean;
};

export function EditSchemaView() {

  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const { state: { from = undefined, goBackOnSuccess = false } = {} } = useLocation<LocationProps>();

  // If specified, go back, otherwise either replace this history entry
  // with the provided entry point or goto the details page
  const onSaveSchemaSuccess = React.useCallback(updatedSchema => {
    if (goBackOnSuccess) {
      history.goBack();
    } else if (from) {
      history.replace(from);
    } else {
      history.replace(getPathToSchema(updatedSchema.getId()));
    }
  }, [history, from, goBackOnSuccess]);

  const [{
    schema,
    metadata,
    errorMessage,
    showErrorView,
    showProgressIndicator,
    isValidSchemaRef,
  }] = useSchemaRef(JsonSchemaMetadata.fromNameAndVersion(id).getSchemaRef());

  if (showErrorView || !isValidSchemaRef) {
    return (
      <ErrorView
        className="editSchemaWizardErrorView"
        title="Edit Schema Wizard Failed to Initialize"
        message={errorMessage || `Invalid Schema ID: ${id}`}
        size="large"
      />
    );
  }

  if (showProgressIndicator) {
    return (
      <LoadingView label="Loading Edit Schema Wizard..." color={Color.MODULES} />
    );
  }

  return (
    <SchemaWizard
      key={id}
      schema={schema}
      editMode={true}
      metadata={metadata}
      className="editSchemaWizard"
      snackbarId="edit-schema"
      saveButtonLabel="Save Changes"
      errorTitle="Edit Schema Failed"
      onSaveSchemaSuccess={onSaveSchemaSuccess}
    />
  );
}

export default EditSchemaView;
