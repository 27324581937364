import createStyles from "@material-ui/core/styles/createStyles";
import Color from "../styles/Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      position: "relative",
      marginTop: 10,
      "&:first-child": {
        marginTop: 0,
      },
    },
    name: {
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      fontSize: 16,
      minWidth: 100,
      alignSelf: "flex-start",
    },
    value: {
      backgroundColor: "#eaeaea",
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "monospace",
      fontSize: 12,
      marginLeft: 15,
      fontWeight: 300,
      padding: 10,
    },
    actions: {
      marginLeft: 20,
      "& button": {
        padding: 8,
      },
    },
    editIcon: {
      marginLeft: 20,
      padding: 15,
    },
    tooltipIcon: {
      marginLeft: 20,
      padding: 15,
      "& svg": {
        width: "24px !important",
        height: "24px !important",
      },
    },
  });

export default styles;
