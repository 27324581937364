import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { createActions } from "@base";

export const {
  batchId: setBatchId,
  autoRefresh: setAutoRefresh,
  FETCH_DEVICE_ENROLLMENT_DETAILS_REQUEST: fetchDeviceEnrollmentDetailsRequest,
  FETCH_DEVICE_ENROLLMENT_DETAILS_SUCCESS: fetchDeviceEnrollmentDetailsSuccess,
  FETCH_DEVICE_ENROLLMENT_DETAILS_FAILURE: fetchDeviceEnrollmentDetailsFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setBatchId());
  dispatch(setAutoRefresh());
  return dispatch(baseReset());
};

export const initialize = (batchId: string) => (dispatch: any) => {
  dispatch(reset());
  return dispatch(setBatchId(batchId));
};
