import React from "react";
import classnames from "classnames";
import MuiStepLabel, { StepLabelProps } from "@material-ui/core/StepLabel";

export interface CustomStepLabelProps extends StepLabelProps {}

export const StepLabel = ((props: CustomStepLabelProps) => {

    const {
      className,
      ...otherProps
    } = props;

    return (
      <MuiStepLabel
        {...otherProps}
        className={classnames("stepLabel", className)}
      />
    );
});

export default StepLabel;
