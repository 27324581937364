import createTheme, { ThemeOptions } from "@material-ui/core/styles/createTheme";
import { baseThemeOptions, Color } from "@components/styles";

const { palette: basePalette = {} } = baseThemeOptions;

const {
  primary: basePrimaryColors = {},
  secondary: baseSecondaryColors = {},
  text: baseTextColors = {},
} = basePalette;

export const themeOptions: ThemeOptions = {
  ...baseThemeOptions,
  palette: {
    ...basePalette,
    type: "light",
    primary: {
      ...basePrimaryColors,
      main: Color.MODULES,
      light: Color.BLUE_GREY,
      dark: Color.BLUE,
      contrastText: Color.TEXT,
    },
    secondary: {
      ...baseSecondaryColors,
      main: Color.SIGNIFY_GREEN,
      light: Color.BLUE_GREY,
      dark: Color.TEAL,
      contrastText: Color.TEXT,
    },
    text: {
      ...baseTextColors,
      primary: Color.TEXT,
      secondary: Color.TEXT,
      disabled: Color.GREY3,
      hint: Color.SIGNIFY_GREEN
    },
  },
};

export const theme = createTheme(themeOptions);

export default theme;
