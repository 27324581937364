import { Module } from "@data";
import { initialize } from "./actions";
import { createModule } from "@base/createModule";
import { equalsIgnoreCase, isEmptyString } from "@util";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { isAccessDeniedVisible, isEmptyViewVisible } from "./selectors";
import { getPathToDataApprovalRequest } from "@modules/dataApprovalRequestDetails/helpers";
import {
  LEGACY_MODULE_PATH,
  MODULE_ID,
  MODULE_PATH,
  VIEW_APPROVAL_REQUEST_COMMENTS_REDIRECT,
  VIEW_APPROVAL_REQUEST_REDIRECT,
} from "./constants";
import view from "./DataApprovalRequestDetailsView";

type RouteParams = { id?: string };

export const dataApprovalRequestDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATH,
  legacyPath: LEGACY_MODULE_PATH,
  exactPath: false,
  strictPath: false,
  name: "Data Approval Request Details",
  title: "Data Approval Request Details",
  className: "dataApprovalRequestDetailsModule",
  primaryColor,
  icon,
  view,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: ({ id = "" }: RouteParams = {}) => initialize(id),
  redirects: {
    [VIEW_APPROVAL_REQUEST_REDIRECT]: (action, resourceId, extraQueryParams) =>
      isEmptyString(resourceId) ? null : `${getPathToDataApprovalRequest(resourceId)}?${extraQueryParams}`,
    [VIEW_APPROVAL_REQUEST_COMMENTS_REDIRECT]: (action, resourceId, extraQueryParams) =>
      isEmptyString(resourceId) ? null : `${getPathToDataApprovalRequest(resourceId)}/comments?${extraQueryParams}`,
  },
  disabled: !equalsIgnoreCase("true", process.env.REACT_APP_DATA_GOVERNANCE_MODULES_ENABLED),
});

export default dataApprovalRequestDetailsModule;
