import React from "react";
import {
  useWorkloadManagerClient,
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks";
import { GetWorkloadCodeUrl } from "@network";

type SuccessResponse = GetWorkloadCodeUrl;

export interface UseGetWorkloadCodeProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  workloadName: string;
  version?: number;
}

export interface UseGetWorkloadCodeModel extends UseApiRequestModel<SuccessResponse> {
  successMessage?: string;
  url: string;
}

export interface UseGetWorkloadCodeActions {
  download: () => void;
  reset: () => void;
}

type Props = UseGetWorkloadCodeProps;
type Model = UseGetWorkloadCodeModel;
type Actions = UseGetWorkloadCodeActions;
type Result = [Model, Actions];

export const useGetWorkloadCode = (props: Props): Result => {

  const {
    workloadName = "",
    version = -1,
    defaultErrorMessage = "Failed to download workload code",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() => WorkloadManagerClient.getWorkloadCodeApi(workloadName, version),
    [WorkloadManagerClient, workloadName, version]);

  const [{ successResponse, showSuccessView, ...baseModel }, { refresh: download, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
      deferRequest: true,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? `Download Successful` : "" ,
    [showSuccessView]);

  const url = React.useMemo(() => {
    const { downloadURL = "" } = successResponse || {};
    return downloadURL;
  }, [showSuccessView, successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    url,
    successMessage,
    showSuccessView,
    successResponse,
  }), [
    baseModel,
    url,
    successMessage,
    showSuccessView,
    successResponse
  ]);

  const actions = React.useMemo<Actions>(() => ({
    download,
    reset
  }), [
    download,
    reset
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetWorkloadCode;
