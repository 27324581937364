import React from "react";
import {
  ActionMenuItem,
  DSTypeManagementDialog,
  DSTypeOperations,
  DSTypeAction,
  EDIT_DS_TYPE_ACTION,
  DELETE_DS_TYPE_ACTION,
  DSTypeListItemExpanded,
  DSTypeListViewModel,
  DSTypeListViewActions,
  createColumns,
  DSTypesLayoutsListColumn,
  ListViewItem,
} from "@components";
import DSTypeListView from "@components/ds-type-list-view";
import { PrimaryIcon } from "../styles";
import { noop } from "@util";
import { DSTypeInfo } from "@data";
import { useListDSTypes } from "@hooks";
import lowerCase from "lodash/lowerCase";

const DEFAULT_TYPES_LIST_COLUMNS: DSTypesLayoutsListColumn[] = [
  DSTypesLayoutsListColumn.NAME,
  DSTypesLayoutsListColumn.DESCRIPTION,
];

const ACTION_ITEMS: ActionMenuItem[] = [
  EDIT_DS_TYPE_ACTION,
  DELETE_DS_TYPE_ACTION,
];

const DEFAULT_RENDER_EXPANDED_ITEM = (item: DSTypeInfo) => (
  <DSTypeListItemExpanded
    type={item}
  />
);

interface ContainerModel extends DSTypeListViewModel {
  expandable?: boolean;
}

interface ContainerActions extends DSTypeListViewActions {
}

type Props = ContainerModel & ContainerActions;

const DigitalShadowTypes =  (props: Props) => {

  const {
    actions: typeActions = ACTION_ITEMS,
    expandable = true,
    columns = DEFAULT_TYPES_LIST_COLUMNS,
    onClickAction = noop,
    renderExpandedItem = DEFAULT_RENDER_EXPANDED_ITEM,
    ...otherProps
  } = props;

  const [operation, setOperation] = React.useState(DSTypeOperations.NONE);
  const [openOperationDialog, setOpenOperationDialog] = React.useState(false);
  const [name, setName] = React.useState("");
  const [expandedTypes, setExpandedTypes] = React.useState<string[]>([]);

  const [ model, actions ] = useListDSTypes();

  const { types } = model;

  const { refresh } = actions;

  const moduleListItems: ListViewItem<DSTypeInfo>[] = React.useMemo(() =>
    types.map(type => {
      return {
        item: type,
        icon: PrimaryIcon,
        columnAttributes: createColumns([
          {
            className: "typeName",
            value: type.getName(),
            column: DSTypesLayoutsListColumn.NAME,
            firstColumn: true,
          },
          {
            className: "typeDescription",
            value: type.getDescription(),
            column: DSTypesLayoutsListColumn.DESCRIPTION,
          },
        ])
      };
    }), [types]);

  const dialogClassName = React.useMemo(() => `${lowerCase(operation)}DSTypeDialog`, [operation]);

  const createDSType = React.useCallback(() => {
    setOpenOperationDialog(true);
    setOperation(DSTypeOperations.CREATE);
  }, [setOpenOperationDialog, setOperation]);

  const deleteDSType = React.useCallback((typeName: string) => {
    setOperation(DSTypeOperations.DELETE);
    setName(typeName);
    setOpenOperationDialog(true);
  }, [setOpenOperationDialog, setOperation, setName]);

  const updateDsType = React.useCallback((typeName: string) => {
    setOperation(DSTypeOperations.UPDATE);
    setName(typeName);
    setOpenOperationDialog(true);
  }, [setOpenOperationDialog, setOperation, setName]);

  const onClickTypeAction = React.useCallback((type: DSTypeInfo, action: ActionMenuItem) => {
    switch (action.id) {
      case DSTypeAction.DELETE_DS_TYPE_ACTION:
        return deleteDSType(type.getName());
      case DSTypeAction.EDIT_DS_TYPE_ACTION:
        return updateDsType(type.getName());
      default:
        return onClickAction(type, action);
    }
  }, [deleteDSType, updateDsType, onClickAction]);

  const onSuccess = React.useCallback(() => {
    setOpenOperationDialog(false);
    setOperation(DSTypeOperations.NONE);
    setName("");
    refresh();
  }, [setOpenOperationDialog, setOperation,  refresh, setName]);

  const cancelOperation = React.useCallback(() => {
    setOpenOperationDialog(false);
    setOperation(DSTypeOperations.NONE);
    setName("");
  }, [setOpenOperationDialog, setOperation, setName]);

  const setExpandedItems = React.useCallback((expandedShadowTypes: DSTypeInfo[]) =>
    setExpandedTypes(expandedShadowTypes.map(shadowType => shadowType.getName())), [setExpandedTypes]);

  const isItemExpanded = React.useCallback((item: DSTypeInfo) =>
    expandedTypes.indexOf(item.getName()) >= 0, [expandedTypes]);

  const expandedItems = React.useMemo(() =>
      !expandable ? [] : types.filter(isItemExpanded),
    [expandable, types, isItemExpanded]);

  const onClickItem = React.useCallback((item: DSTypeInfo) => {
    if (isItemExpanded(item)) {
      setExpandedTypes(expandedTypes.filter(typeName => typeName !== item.getName()));
    } else {
      setExpandedTypes(expandedTypes.concat(item.getName()));
    }
  }, [setExpandedTypes, expandedTypes, isItemExpanded]);

  return (
    <React.Fragment>
      <DSTypeListView
        {...model}
        {...actions}
        {...otherProps}
        className="digitalShadowTypes"
        listViewItems={moduleListItems}
        columns={columns}
        showSummary={true}
        showSearch={true}
        summaryViewLabel="Shadow Types"
        expandable={expandable}
        expandedItems={expandedItems}
        setExpandedItems={setExpandedItems}
        renderExpandedItem={renderExpandedItem}
        onClickItem={onClickItem}
        actions={typeActions}
        onClickAction={onClickTypeAction}
        showCreateButton={true}
        createButtonClassName="createDSTypeButton"
        createButtonLabel="Create Shadow Type"
        onClickCreateButton={createDSType}
      />
      <DSTypeManagementDialog
        className={dialogClassName}
        open={openOperationDialog}
        name={name}
        operation={operation}
        onSuccess={onSuccess}
        cancel={cancelOperation}
      />
    </React.Fragment>
  );
};

export default DigitalShadowTypes;
