import { createSelector } from "reselect";
import { AppSchema } from "../../../main/schemas";
import { JsonSchemaPropertyType, JsonSchemaStringConstraints } from "../../../../data";

export const getMinLength = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.stringConstraints.minLength;
};

export const getMinLengthError = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.stringConstraints.minLengthError;
};

export const getMaxLength = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.stringConstraints.maxLength;
};

export const getMaxLengthError = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.stringConstraints.maxLengthError;
};

export const getPattern = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.stringConstraints.pattern;
};

export const getPatternError = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.stringConstraints.patternError;
};

export const hasValidationErrors: (state: AppSchema) => boolean = createSelector(
  [getMinLengthError, getMaxLengthError, getPatternError],
  (minLengthError: string, maxLengthError: string, patternError: string) => {

    return minLengthError.trim().length > 0 ||
      maxLengthError.trim().length > 0 ||
      patternError.trim().length > 0;
  }
);

export const getConstraints: (state: AppSchema) => JsonSchemaStringConstraints = createSelector(
  [getMinLength, getMaxLength, getPattern],
  (minLength: string,
   maxLength: string,
   pattern: string) => {

    return JsonSchemaStringConstraints.from({
      type: JsonSchemaPropertyType.STRING,
      minLength,
      maxLength,
      pattern,
    });
  }
);
