import { AppSchema } from "@schemas";
import { EditModeActions } from "../actions";
import { createActions } from "@base/createActions";
import { FederationProviderWizardState } from "../models";
import { FederationProviderWizardSelectors } from "../selectors";
import { FederationProviderClient, RestClientError } from "@network";
import { ACTION_TYPES, DEFAULT_STATE, FederationProviderWizardStep } from "../reducers";
import { FederationProvider, FederationProviderAttributes, FederationProviderRequest } from "@data";

export const {
  json: setJson,
  federationProviderRequest: setFederationProviderRequest,
  currentStep: setCurrentStep,
  showEditMode: setShowEditMode,
  providerId: setProviderId,
  defaultState: setDefaultStateAttributes,
  setErrors,
  setErrorMessage,
  setSuccessMessage,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  CREATE_FEDERATION_PROVIDER_REQUEST: createFederationProviderRequest,
  CREATE_FEDERATION_PROVIDER_SUCCESS: createFederationProviderSuccess,
  CREATE_FEDERATION_PROVIDER_FAILED: createFederationProviderFailed,
  FETCH_FEDERATION_PROVIDER_REQUEST: fetchFederationProviderRequest,
  FETCH_FEDERATION_PROVIDER_SUCCESS: fetchFederationProviderSuccess,
  FETCH_FEDERATION_PROVIDER_FAILED: fetchFederationProviderFailed,
  EDIT_FEDERATION_PROVIDER_REQUEST: editFederationProviderRequest,
  EDIT_FEDERATION_PROVIDER_SUCCESS: editFederationProviderSuccess,
  EDIT_FEDERATION_PROVIDER_FAILED: editFederationProviderFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setJson());
  dispatch(setFederationProviderRequest());
  dispatch(setCurrentStep());
  dispatch(setShowEditMode());
  dispatch(setProviderId());
  dispatch(setDefaultStateAttributes());
  return dispatch(baseReset());
};

export const setDefaultState = (defaultState: FederationProviderWizardState) =>
  (dispatch: any) => dispatch(setDefaultStateAttributes(defaultState.toJS()));

export const showEditMode = () => setShowEditMode(true);
export const hideEditMode = () => setShowEditMode(false);

export const showClientView = () => setCurrentStep(FederationProviderWizardStep.CLIENT);
export const showEndpointsView = () => setCurrentStep(FederationProviderWizardStep.ENDPOINTS);
export const showAttributesView = () => setCurrentStep(FederationProviderWizardStep.ATTRIBUTES);
export const showEditorView = () => setCurrentStep(FederationProviderWizardStep.EDITOR);
export const showReviewView = () => setCurrentStep(FederationProviderWizardStep.REVIEW);

export const updateJson = (json: string = DEFAULT_STATE.json) => (dispatch: any) => {

  dispatch(setJson(json));

  try {

    const federationProviderRequest = new FederationProviderRequest(JSON.parse(json));

    const federationProvider = federationProviderRequest.toJS();

    dispatch(setFederationProviderRequest(federationProvider));

    return dispatch(setErrorMessage());
  } catch (e) {
    return dispatch(setErrorMessage("Failed to parse federation provider. " +
      "Please verify that the JSON is valid before continuing."));
  }
};

export const updateFederationProvider =
  (federationProviderRequest: FederationProviderRequest = FederationProviderRequest.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  if (!FederationProviderWizardSelectors.isJsonValid(state)) {
    return dispatch(setErrorMessage("Failed to parse federation provider. " +
      "Please verify that the JSON is valid before continuing."));
  }

  const federationProvider = federationProviderRequest.toJS();

  dispatch(setJson(JSON.stringify(federationProvider, null, "  ")));

  dispatch(setFederationProviderRequest(federationProvider));

  return dispatch(setErrorMessage());
  };

export const updateDomain = (domain: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

  const federationProvider = FederationProviderWizardSelectors.getFederationProviderRequest(getState());

  return dispatch(updateFederationProvider(new FederationProviderRequest({
    ...federationProvider.toJS(),
    domain,
  })));
};

export const updateClientId = (clientId: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const federationProvider = FederationProviderWizardSelectors.getFederationProviderRequest(getState());

    return dispatch(updateFederationProvider(new FederationProviderRequest({
      ...federationProvider.toJS(),
      clientId,
    })));
  };

export const updateClientSecret = (clientSecret: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const federationProvider = FederationProviderWizardSelectors.getFederationProviderRequest(getState());

    return dispatch(updateFederationProvider(new FederationProviderRequest({
      ...federationProvider.toJS(),
      clientSecret,
    })));
  };

export const updateDiscoveryDocument = (discoveryDocument: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const federationProvider = FederationProviderWizardSelectors.getFederationProviderRequest(getState());

    return dispatch(updateFederationProvider(new FederationProviderRequest({
      ...federationProvider.toJS(),
      discoveryDocument,
    })));
  };

export const updateAuthorizationEndpoint = (authorizationEndpoint: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const federationProvider = FederationProviderWizardSelectors.getFederationProviderRequest(getState());

    return dispatch(updateFederationProvider(new FederationProviderRequest({
      ...federationProvider.toJS(),
      authorizationEndpoint,
    })));
  };

export const updateTokenEndpoint = (tokenEndpoint: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const federationProvider = FederationProviderWizardSelectors.getFederationProviderRequest(getState());

    return dispatch(updateFederationProvider(new FederationProviderRequest({
      ...federationProvider.toJS(),
      tokenEndpoint,
    })));
  };

export const updateUserEndpoint = (userEndpoint: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const federationProvider = FederationProviderWizardSelectors.getFederationProviderRequest(getState());

    return dispatch(updateFederationProvider(new FederationProviderRequest({
      ...federationProvider.toJS(),
      userEndpoint,
    })));
  };

export const updateJwksUri = (jwksUri: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const federationProvider = FederationProviderWizardSelectors.getFederationProviderRequest(getState());

    return dispatch(updateFederationProvider(new FederationProviderRequest({
      ...federationProvider.toJS(),
      jwksUri,
    })));
  };

export const updateIssuer = (issuer: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const federationProvider = FederationProviderWizardSelectors.getFederationProviderRequest(getState());

    return dispatch(updateFederationProvider(new FederationProviderRequest({
      ...federationProvider.toJS(),
      issuer,
    })));
  };

export const updateFederationClosed = (federationClosed: boolean = true) =>
  (dispatch: any, getState: () => AppSchema) => {

    const federationProvider = FederationProviderWizardSelectors.getFederationProviderRequest(getState());

    return dispatch(updateFederationProvider(new FederationProviderRequest({
      ...federationProvider.toJS(),
      federationClosed,
    })));
  };

export const updateScope = (values: string[] = [], separator: string = " ") =>
  (dispatch: any, getState: () => AppSchema) => {

    const federationProvider = FederationProviderWizardSelectors.getFederationProviderRequest(getState());

    return dispatch(updateFederationProvider(new FederationProviderRequest({
      ...federationProvider.toJS(),
      scope: {
        ...federationProvider.scope,
        values,
        separator,
      },
    })));
  };

export const updateAttributes = (value: string) =>
  (dispatch: any, getState: () => AppSchema) => {

    const federationProvider = FederationProviderWizardSelectors.getFederationProviderRequest(getState());

    try {

      const attributes = JSON.parse(value);

      dispatch(updateFederationProvider(new FederationProviderRequest({
        ...federationProvider.toJS(),
        attributes,
      })));

      return dispatch(setErrorMessage());

    } catch (e) {
      return dispatch(setErrorMessage("Failed to parse attributes. " +
        "Please verify that the JSON is valid before continuing."));
    }
  };

export const save =  () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  if (FederationProviderWizardSelectors.isEditModeActive(state)) {
    return dispatch(EditModeActions.save());
  }

  const accessToken = FederationProviderWizardSelectors.getAccessToken(state);
  const json = FederationProviderWizardSelectors.getJson(state);

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setErrors());
  dispatch(setErrorMessage());
  dispatch(createFederationProviderRequest());

  return FederationProviderClient.createFederationProvider(accessToken, json)
    .then((attrs: FederationProviderAttributes) => {

      const federationProvider = new FederationProvider(attrs);
      dispatch(createFederationProviderSuccess());
      dispatch(setProviderId(federationProvider.getProviderId()));
      dispatch(setSuccessMessage("Federation Provider Created"));
      return dispatch(hideLoadingIndicator());

    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to create device type", errors } = response;

      dispatch(createFederationProviderFailed(analytic));
      dispatch(setErrors(errors));
      dispatch(setErrorMessage(error));

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideLoadingIndicator());
    });
};
