import { MODULE_PATH as BASE_URL, RouteParams as BaseRouteParams } from "@modules/dataWorkloadDetails/constants";

export const MODULE_ID = "workload_instance_details";
export const MODULE_PATH = `${BASE_URL}/executions/:executionId`;

export const VIEW_WORKLOAD_EXECUTION_INSTANCE_REDIRECT = "viewWorkloadExecutionInstance";

export interface RouteParams extends BaseRouteParams {
  executionId: string;
}

export { BASE_URL };
