import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      borderBottom: "1px solid #ebebeb",
    },
    addFilterButton: {
      minWidth: "auto",
      margin: "8px 0",
      padding: 0,
    },
    addFilterButtonLabel: {
      marginRight: 8,
    },
    addFilterIcon: {
      color: Color.SIGNIFY_GREEN,
      width: 24,
      height: 24,
      marginRight: 8,
      marginLeft: 4,
    },
    addFilter: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "center",
    },
    searchFilters: {
      paddingTop: 16,
      paddingBottom: 16,
      borderTop: "1px solid #ebebeb",
    },
    disabledButton: {
      "& .icon, & .label": {
        color: Color.GREY3,
      },
    },
  });

export default styles;
