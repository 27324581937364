import { AppSchema } from "../../../main/schemas";
import { JsonSchemaNumberConstraints } from "../../../../data";
import { NumberConstraintsSelectors } from "../../selectors/numberConstraints";
import { PropertyEditorActions } from "../../actions/propertyEditor";
import {
  DEFAULT_STATE,
  NumberConstraintsAction,
  NumberConstraintsActionType,
} from "../../reducers/numberConstraints";

type Action = NumberConstraintsAction;

export const setMinimum =
  (value: string = DEFAULT_STATE.minimum): Action => ({
    type: NumberConstraintsActionType.SET_MINIMUM,
    value,
  });

export const setMinimumError =
  (value: string = DEFAULT_STATE.minimumError): Action => ({
    type: NumberConstraintsActionType.SET_MINIMUM_ERROR,
    value,
  });

export const clearMinimumError = (): Action => setMinimumError();

export const updateMinimum = (value?: string) => (dispatch: any) => {
  dispatch(setMinimum(value));
  dispatch(clearMinimumError());
  return dispatch(PropertyEditorActions.constraintsUpdated());
};

export const validateMinimum = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const value = NumberConstraintsSelectors.getMinimum(state);

  const minimum = Number(value + "");

  if (value.trim().length > 0) {

    if (isNaN(minimum)) {
      return dispatch(setMinimumError("Value must be a number."));
    }

    const constraints = NumberConstraintsSelectors.getConstraints(state);

    if (constraints.hasExclusiveMinimum() && minimum <= constraints.exclusiveMinimum) {
      return dispatch(setMinimumError("Value must be greater than exclusiveMinimum"));
    }

    if (constraints.hasMaximum() && minimum > constraints.maximum) {
      return dispatch(setMinimumError("Value must be less than or equal to maximum"));
    }

    if (constraints.hasExclusiveMaximum() && minimum >= constraints.exclusiveMaximum) {
      return dispatch(setMinimumError("Value must be less than exclusiveMaximum"));
    }
  }

  return dispatch(clearMinimumError());
};

export const setExclusiveMinimum =
  (value: string = DEFAULT_STATE.exclusiveMinimum): Action => ({
    type: NumberConstraintsActionType.SET_EXCLUSIVE_MINIMUM,
    value,
  });

export const setExclusiveMinimumError =
  (value: string = DEFAULT_STATE.exclusiveMinimumError): Action => ({
    type: NumberConstraintsActionType.SET_EXCLUSIVE_MINIMUM_ERROR,
    value,
  });

export const clearExclusiveMinimumError = (): Action => setExclusiveMinimumError();

export const updateExclusiveMinimum = (value?: string) => (dispatch: any) => {
  dispatch(setExclusiveMinimum(value));
  dispatch(clearExclusiveMinimumError());
  return dispatch(PropertyEditorActions.constraintsUpdated());
};

export const validateExclusiveMinimum = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const value = NumberConstraintsSelectors.getExclusiveMinimum(state);

  const exclusiveMinimum = Number(value + "");

  if (value.trim().length > 0) {

    if (isNaN(exclusiveMinimum)) {
      return dispatch(setExclusiveMinimumError("Value must be a number."));
    }

    const constraints = NumberConstraintsSelectors.getConstraints(state);

    if (constraints.hasMinimum() && exclusiveMinimum >= constraints.minimum) {
      return dispatch(setExclusiveMinimumError("Value must be less than minimum"));
    }

    if (constraints.hasMaximum() && exclusiveMinimum >= constraints.maximum) {
      return dispatch(setExclusiveMinimumError("Value must be less than maximum"));
    }

    if (constraints.hasExclusiveMaximum() && exclusiveMinimum >= constraints.exclusiveMaximum) {
      return dispatch(setExclusiveMinimumError("Value must be less than exclusiveMaximum"));
    }
  }

  return dispatch(clearExclusiveMinimumError());
};

export const setMaximum =
  (value: string = DEFAULT_STATE.maximum): Action => ({
    type: NumberConstraintsActionType.SET_MAXIMUM,
    value,
  });

export const setMaximumError =
  (value: string = DEFAULT_STATE.minimumError): Action => ({
    type: NumberConstraintsActionType.SET_MAXIMUM_ERROR,
    value,
  });

export const clearMaximumError = (): Action => setMaximumError();

export const updateMaximum = (value?: string) => (dispatch: any) => {
  dispatch(setMaximum(value));
  dispatch(clearMaximumError());
  return dispatch(PropertyEditorActions.constraintsUpdated());
};

export const validateMaximum = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const value = NumberConstraintsSelectors.getMaximum(state);

  const maximum = Number(value + "");

  if (value.trim().length > 0) {

    if (isNaN(maximum)) {
      return dispatch(setMaximumError("Value must be a number."));
    }

    const constraints = NumberConstraintsSelectors.getConstraints(state);

    if (constraints.hasMinimum() && maximum < constraints.minimum) {
      return dispatch(setMaximumError("Value must be greater than or equal to minimum"));
    }

    if (constraints.hasExclusiveMinimum() && maximum <= constraints.exclusiveMinimum) {
      return dispatch(setMaximumError("Value must be greater than exclusiveMinimum"));
    }

    if (constraints.hasExclusiveMaximum() && maximum >= constraints.exclusiveMaximum) {
      return dispatch(setMaximumError("Value must be less than exclusiveMaximum"));
    }
  }

  return dispatch(clearMaximumError());
};

export const setExclusiveMaximum =
  (value: string = DEFAULT_STATE.exclusiveMaximum): Action => ({
    type: NumberConstraintsActionType.SET_EXCLUSIVE_MAXIMUM,
    value,
  });

export const setExclusiveMaximumError =
  (value: string = DEFAULT_STATE.exclusiveMaximumError): Action => ({
    type: NumberConstraintsActionType.SET_EXCLUSIVE_MAXIMUM_ERROR,
    value,
  });

export const clearExclusiveMaximumError = (): Action => setExclusiveMaximumError();

export const updateExclusiveMaximum = (value?: string) => (dispatch: any) => {
  dispatch(setExclusiveMaximum(value));
  dispatch(clearExclusiveMaximumError());
  return dispatch(PropertyEditorActions.constraintsUpdated());
};

export const validateExclusiveMaximum = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const value = NumberConstraintsSelectors.getExclusiveMaximum(state);

  const exclusiveMaximum = Number(value + "");

  if (value.trim().length > 0) {

    if (isNaN(exclusiveMaximum) || exclusiveMaximum < 0) {
      return dispatch(setExclusiveMaximumError("Value must be a number."));
    }

    const constraints = NumberConstraintsSelectors.getConstraints(state);

    if (constraints.hasMinimum() && exclusiveMaximum <= constraints.minimum) {
      return dispatch(setExclusiveMaximumError("Value must be greater than minimum"));
    }

    if (constraints.hasExclusiveMinimum() && exclusiveMaximum <= constraints.exclusiveMinimum) {
      return dispatch(setExclusiveMaximumError("Value must be greater than exclusiveMinimum"));
    }

    if (constraints.hasMaximum() && exclusiveMaximum <= constraints.maximum) {
      return dispatch(setExclusiveMaximumError("Value must be greater than maximum"));
    }
  }

  return dispatch(clearExclusiveMaximumError());
};

export const setMultipleOf =
  (value: string = DEFAULT_STATE.multipleOf): Action => ({
    type: NumberConstraintsActionType.SET_MULTIPLE_OF,
    value,
  });

export const setMultipleOfError =
  (value: string = DEFAULT_STATE.multipleOfError): Action => ({
    type: NumberConstraintsActionType.SET_MULTIPLE_OF_ERROR,
    value,
  });

export const clearMultipleOfError = (): Action => setMultipleOfError();

export const updateMultipleOf = (value?: string) => (dispatch: any) => {
  dispatch(setMultipleOf(value));
  dispatch(clearMultipleOfError());
  return dispatch(PropertyEditorActions.constraintsUpdated());
};

export const validateMultipleOf = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const value = NumberConstraintsSelectors.getMultipleOf(state);

  const multipleOf = Number(value + "");

  if (value.trim().length > 0) {

    if (isNaN(multipleOf) || multipleOf <= 0) {
      return dispatch(setMultipleOfError("Value must be a non-negative integer greater than zero."));
    }
  }

  return dispatch(clearMultipleOfError());
};

export const validate = () => (dispatch: any) => {

  return Promise.all([
    dispatch(validateMinimum()),
    dispatch(validateExclusiveMinimum()),
    dispatch(validateMaximum()),
    dispatch(validateExclusiveMaximum()),
    dispatch(validateMultipleOf()),
  ]);
};

export const reset = () => (dispatch: any) => {
  dispatch(setMinimum());
  dispatch(setMinimumError());
  dispatch(setExclusiveMinimum());
  dispatch(setExclusiveMinimumError());
  dispatch(setMaximum());
  dispatch(setMaximumError());
  dispatch(setExclusiveMaximum());
  dispatch(setExclusiveMaximumError());
  dispatch(setMultipleOf());
  return dispatch(setMultipleOfError());
};

export const setConstraints =
  (constraints: JsonSchemaNumberConstraints = JsonSchemaNumberConstraints.EMPTY) =>
    (dispatch: any) => {

      dispatch(setMinimum(
        constraints.hasMinimum() ? constraints.minimum + "" : ""));

      dispatch(setExclusiveMinimum(
        constraints.hasExclusiveMinimum() ? constraints.exclusiveMinimum + "" : ""));

      dispatch(setMaximum(
        constraints.hasMaximum() ? constraints.maximum + "" : ""));

      dispatch(setExclusiveMaximum(
        constraints.hasExclusiveMaximum() ? constraints.exclusiveMaximum + "" : ""));

      return dispatch(setMultipleOf(
        constraints.hasMultipleOf() ? constraints.multipleOf + "" : ""));
    };
