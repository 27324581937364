import React from "react";
import classNames from "classnames";
import Markdown from "react-markdown";
import { markdownStyle as styles } from "./styles";
import { WithStyles, withStyles } from "@material-ui/core";

export interface MarkdownProps {
  markdown: string;
  className?: string;
}

type Props = WithStyles<typeof styles> & MarkdownProps;

export const MarkdownView = withStyles(styles)((props: Props) => {

  const { classes, className, markdown = "" } = props;

  return <Markdown className={classNames(classes.markdownView, className)}>{markdown}</Markdown>;
});

export default MarkdownView;
