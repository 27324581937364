import { createActions } from "@base/createActions";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";

export const {
  accountFilter: setAccountFilter, // Should not be reset in between page changes
  groupFilter: setGroupFilter, // Should not be reset in between page changes
  dataSetTypeFilter: setDataSetTypeFilter, // Should not be reset in between page changes
  aliasContainsFilter: setAliasContainsFilter, // Should not be reset in between page changes
  currentAccountFilterEnabled: setCurrentAccountFilterEnabled, // Should not be reset in between page changes
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setAccountFilter());
  dispatch(setGroupFilter());
  dispatch(setDataSetTypeFilter());
  dispatch(setAliasContainsFilter());
  dispatch(setCurrentAccountFilterEnabled());
  return dispatch(baseReset());
};

export const onMount = () => () => {
  return Promise.resolve();
};

export const initialize = () => (dispatch: any) => {
  return dispatch(reset());
};
