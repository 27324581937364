import { createStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createTheme";

export const styles = (theme: Theme) =>
  createStyles({
    backdrop: {
      background: "rgba(105, 105, 105, 0.25)",
      zIndex: theme.zIndex.modal,
    },
    progressIndicator: {
    },
    showProgressIndicator: {
      // Not sure why using !important is complained on zIndex, but this is necessary because
      // otherwise the material-ui default styles will overrule this no matter what
      // @ts-ignore
      zIndex: `${theme.zIndex.modal + 1} !important`,
    },
  });

export default styles;
