import React from "react";
import classnames from "classnames";
import { getPluralString, noop } from "@util";
import { Alert, AlertTitle } from "@components";
import { waitForDeviceValidationDetails as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface Model {
  className?: string;
  showLoadingIndicator?: boolean;
  refreshDelaySecs?: number;
}

export interface Actions {
  refresh?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactChild;
};

export const WaitForDeviceValidationDetails = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    showLoadingIndicator,
    refreshDelaySecs = 5,
    refresh = noop,
    children,
  } = props;

  const [countdown, setCountdown] = React.useState(refreshDelaySecs);

  React.useEffect(() => {

    if (showLoadingIndicator) {
      setCountdown(refreshDelaySecs);
      return noop;
    }

    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }

    refresh();

    return noop;

  }, [showLoadingIndicator, countdown, setCountdown, refreshDelaySecs, refresh]);

  return (
    <div className={classnames("waitForDeviceValidationDetails", className, classes.container)}>
      <Alert
        className={classnames("alert", classes.alert)}
        severity="info"
      >
        <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
          We're still validating this device
        </AlertTitle>
        <p className={classnames("alertMessage", classes.alertMessage)}>
          Refreshing in {getPluralString(countdown, { one: "second", other: "seconds" })}
        </p>
      </Alert>
      {children}
    </div>
  );
});

export default WaitForDeviceValidationDetails;
