import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { isEmptyString } from "@util";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { useDeleteEmailTemplate } from "@hooks";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface DeleteEmailTemplateDialogModel extends PortalModuleDialogModel<string> {
  accountId?: string;
  templateId?: string;
}

export interface DeleteEmailTemplateDialogActions extends PortalModuleDialogActions<string> {
}

type Props = WithStyles<typeof styles> & DeleteEmailTemplateDialogModel & DeleteEmailTemplateDialogActions;

export const DeleteEmailTemplateDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    accountId = "",
    templateId = "",
    ...otherProps
  } = props;

  const [{ showLoadingIndicator, ...otherModel }, { deleteEmailTemplate, ...otherActions }] =
    useDeleteEmailTemplate({ accountId, templateId });

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      {...otherActions}
      className={classnames("deleteEmailTemplateDialog")}
      title={"Delete Email Template"}
      loading={showLoadingIndicator}
      continueButtonLabel={"Delete Email Template"}
      continueButtonDisabled={isEmptyString(templateId)}
      confirm={deleteEmailTemplate}
    >
      <div className={classnames("deleteEmailTemplate", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Are you sure you want to delete this email template?
          </label>
          <label className={classnames("subtitle", classes.subtitle)}>
            <span className={classes.warning}>WARNING:</span>This action cannot be undone!
          </label>
          <label className={classnames("templateId", classes.templateId)}>
            {templateId}
          </label>
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default DeleteEmailTemplateDialog;
