import React from "react";
import classnames from "classnames";
import { DataLakeIndex, ExecuteWorkloadRequest, ExecutionScope, TriggerType, Workload } from "@data";
import { testView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  createColumns,
  DataLakeBrowserModel,
  DEFAULT_WORKLOAD_LIST_COLUMNS,
  DropdownMenu,
  ListViewItem,
  ModuleListItem,
  SortedSearchResultsList,
  SubmitApiRequestView,
  WorkloadListColumns,
} from "@components";
import { isEmptyString, noop } from "@util";
import { PrimaryIcon } from "@modules/dataWorkloads/styles";
import { getPathToWorkload } from "@modules/dataWorkloadDetails/helpers";
import TextField from "@material-ui/core/TextField";
import { DataLakeFileBrowserDialog } from "@components/data-lake-file-browser-dialog";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";

export interface TestViewModel extends DataLakeBrowserModel {
  executionData: ExecuteWorkloadRequest;
  filteredWorkloads: Workload[];
  errorMessage: string;
  showSuccessView: boolean;
  showCancelButton?: boolean;
  isFunctionWorkload: boolean;
}

export interface TestViewActions {
  setExecutionData?: (data: ExecuteWorkloadRequest) => void;
  showExecutionInstance: () => void;
  showExecutionHistoryView: () => void;
  testWorkload: () => void;
  cancel?: () => void;
}

type Props = WithStyles<typeof styles> & TestViewModel & TestViewActions;

const TRIGGER_TYPES =
  [TriggerType.SCHEDULE, TriggerType.DATA_LAKE, TriggerType.MANUAL_EXECUTE, TriggerType.WORKLOAD_COMPLETE];

const mapTriggerTypeToOptionName = (value: TriggerType) => {
  switch (value) {
    case TriggerType.DATA_LAKE:
      return "Data Lake";
    case TriggerType.WORKLOAD_COMPLETE:
      return "Workload Complete";
    case TriggerType.SCHEDULE:
      return "Schedule";
    case TriggerType.MANUAL_EXECUTE:
      return "Manual Execute";
    default:
      return null;
  }
};

const mapScopeToOptionName = (value: ExecutionScope) => {
  switch (value) {
    case ExecutionScope.WORKLOAD:
      return "Workload";
    case ExecutionScope.WORKFLOW:
      return "Workflow";
    default:
      return null;
  }
};

export const TestView = withStyles(styles)((props: Props) => {

  const {
    classes,
    executionData,
    filteredWorkloads,
    errorMessage,
    showCancelButton = false,
    isFunctionWorkload = false,
    testWorkload,
    cancel = noop,
    setExecutionData = noop,
    showExecutionInstance = noop,
    showExecutionHistoryView = noop,
    ...otherProps
  } = props;

  const triggerType = React.useMemo(() => executionData.getType(), [executionData]);

  const [executionScope, setExecutionScope] = React.useState(ExecutionScope.WORKLOAD);

  // Data Lake Test
  const isDataLakeType = React.useMemo(() => triggerType === TriggerType.DATA_LAKE, [triggerType]);
  const isManualExecuteType = React.useMemo(() => triggerType === TriggerType.MANUAL_EXECUTE, [triggerType]);
  const dataSetAlias = React.useMemo(() => executionData.getDataSetAlias(), [executionData]);

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const [showDataLakeBrowser, setShowDataLakeBrowser] = React.useState(false);

  const setFile = React.useCallback((index: DataLakeIndex) => {
    setExecutionData({
      triggerStorage: {bucketName: index.getBucketName(), key: index.getObjectKey()}
    });
    setShowDataLakeBrowser(false);
  }, [setExecutionData, setShowDataLakeBrowser]);

  const fileName = React.useMemo(() => executionData.getBucketName() + "/" + executionData.getKey(), [executionData]);

  const isDataLakeValid = React.useMemo(() => !isEmptyString(executionData.getBucketName()) &&
      !isEmptyString(executionData.getKey()), [executionData]);

  // Workload Completed Test
  const isWorkloadCompletedType = React.useMemo(() => triggerType === TriggerType.WORKLOAD_COMPLETE, [triggerType]);
  const [precedingWorkload, setPrecedingWorkload] =
    React.useState<Workload[]>([new Workload({name: executionData.getPrecedingWorkloadName()})]);

  const workloads = React.useMemo(() => {
    return precedingWorkload.length > 0 ? precedingWorkload.slice() : filteredWorkloads;
  }, [precedingWorkload, filteredWorkloads]);

  const moduleListItems: ListViewItem<Workload>[] = React.useMemo(() =>
    workloads.map(workload => {
      return {
        item: workload,
        icon: PrimaryIcon,
        pathToDetailsView: getPathToWorkload(workload.getName(), workload.getVersion()),
        columnAttributes: createColumns([
          {
            className: "workloadName",
            value: workload.getName(),
            column: WorkloadListColumns.NAME,
            firstColumn: true,
          },
        ])
      };
    }), [workloads]);

  const updatePrecedingWorkload = React.useCallback((updatedWorkloads: Workload[]) => {
    setPrecedingWorkload(updatedWorkloads);
    setExecutionData({precedingWorkloadName: updatedWorkloads.slice().pop()?.getName()});
  }, [setPrecedingWorkload, setExecutionData]);

  const updateTriggerType = React.useCallback((type) => {
    setExecutionData({type});
    setPrecedingWorkload([]);
  }, [setExecutionData, setPrecedingWorkload]);

  const updateScope = React.useCallback((scope) => {
    setExecutionData({executionScope: scope});
    setExecutionScope(scope);
  }, [setExecutionData, setExecutionScope]);

  const onSuccess = React.useCallback(() => {
    cancel();
    return isFunctionWorkload ? showExecutionInstance() : showExecutionHistoryView();
  }, [isFunctionWorkload, cancel, showExecutionInstance, showExecutionHistoryView]);

  return (
    <SubmitApiRequestView
      {...otherProps}
      className={classnames("testViewContainer", classes.container)}
      snackbarId="test-workload"
      errorTitle="Test Workload Failed"
      saveButtonLabel="Test Workload"
      currentState={executionData}
      defaultState={executionData}
      onSuccess={onSuccess}
      errorMessage={errorMessage}
      showCancelButton={showCancelButton}
      save={testWorkload}
      saveButtonDisabled={isDataLakeType && !isDataLakeValid}
      cancel={showCancelButton ? cancel : noop}
    >
      <div className={classnames("testView", classes.testView)}>
        <label className={classnames("title", classes.title)}>
          Manually execute this workload to test for expected behavior
        </label>
        <DropdownMenu
          className={classnames("triggerType", classes.triggerType)}
          disabled={true}
          dropdownMenuLabel="Trigger Type"
          hideEmptyValue={true}
          values={TRIGGER_TYPES}
          mapValueToLabel={mapTriggerTypeToOptionName}
          selectedValue={triggerType}
          setSelectedValue={updateTriggerType}
        />
        {isManualExecuteType && (
          <DropdownMenu
            className={classnames("executionScope")}
            dropdownMenuLabel="Execution Scope"
            hideEmptyValue={true}
            values={[ExecutionScope.WORKLOAD, ExecutionScope.WORKFLOW]}
            mapValueToLabel={mapScopeToOptionName}
            selectedValue={executionScope}
            setSelectedValue={updateScope}
          />
        )}
        {isDataLakeType && (
          <div className={classnames("dataLake", classes.dataLake)}>
            <TextField
              className={classnames("dataSetAlias", classes.triggerStorage, classes.dataSetAlias)}
              margin="none"
              disabled={true}
              InputLabelProps={inputLabelProps}
              label="Data Set Alias"
              value={dataSetAlias}
            />
            <TextField
              className={classnames("file", classes.triggerStorage, classes.fileName)}
              margin="none"
              disabled={true}
              InputLabelProps={inputLabelProps}
              label="File"
              value={isDataLakeValid ? fileName : "No file selected"}
              multiline={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => setShowDataLakeBrowser(true)}>
                      <AddCircleIcon/>
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <DataLakeFileBrowserDialog
              open={showDataLakeBrowser}
              cancel={() => setShowDataLakeBrowser(false)}
              dataSetAlias={dataSetAlias}
              accountId={executionData.getAccountId()}
              title="Select Data Lake File"
              header="Select the data lake file to use to test this trigger"
              setDataLakeIndex={setFile}
              onClose={() => setShowDataLakeBrowser(false)}
            />
          </div>
        )}
        {isWorkloadCompletedType && (
          <div className={classnames("selectPrecedingWorkload", classes.precedingWorkload)}>
            <SortedSearchResultsList
              {...otherProps}
              items={workloads}
              columns={DEFAULT_WORKLOAD_LIST_COLUMNS}
              selectable={true}
              selectAllDisabled={true}
              selectedItems={precedingWorkload}
              setSelectedItems={(selectedWorkloads: Workload[]) => updatePrecedingWorkload(selectedWorkloads)}
              renderItem={(item: Workload, column: WorkloadListColumns) => (
                <ModuleListItem
                  column={column}
                  item={item}
                  listItems={moduleListItems}
                />
              )}
            />
          </div>
        )}
      </div>
    </SubmitApiRequestView>
  );
});

export default TestView;
