import { Module } from "@data";
import { initialize } from "./actions/editMode";
import { reset } from "./actions/federationProviderWizard";
import { createModule } from "@modules/base/createModule";
import { getCreateFederationProviderPath, getEditFederationProviderPath } from "./helpers";
import { primaryColor, PrimaryIcon as icon } from "@modules/federationProviders/components/styles";
import { isAccessDeniedVisible, isEmptyViewVisible } from "./selectors/federationProviderWizard";
import CreateFederationProviderView from "./CreateFederationProviderView";
import EditFederationProviderView from "./EditFederationProviderView";

type RouteParams = { id?: string; };

export const createFederationProviderWizardModule: Module = createModule ({
  id: "create_federation_provider",
  path: getCreateFederationProviderPath(),
  exactPath: true,
  strictPath: true,
  name: "Create Federation Provider Wizard",
  title: "Create New Federation Provider",
  className: "createFederationProviderWizardModule",
  primaryColor,
  icon,
  view: CreateFederationProviderView,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: reset,
});

export const editFederationProviderWizardModule: Module = createModule<RouteParams> ({
  id: "edit_federation_provider",
  path: getEditFederationProviderPath(":id"),
  exactPath: true,
  strictPath: true,
  name: "Edit Federation Provider Wizard",
  title: "Edit Federation Provider",
  className: "editFederationProviderWizardModule",
  primaryColor,
  icon,
  view: EditFederationProviderView,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: ({ id: providerId }: RouteParams = {}) => initialize(providerId),
});
