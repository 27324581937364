import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      padding: 25,
      minWidth: 400,
      "&$showFooter": {
        paddingBottom: 15,
      },
    },
    dropdownMenu: {
      display: "inline-flex",
      alignItems: "stretch",
      padding: "8px 0 0",
    },
    dropdownMenuLabel: {
      marginTop: 8,
    },
    dataSetTypeFilter: {
    },
    filterSwitch: {
    },
    currentAccountFilterSwitch: {
      "& > .switch": {
        marginLeft: -8,
      },
    },
    search: {
      marginTop: 15,
      width: "auto",
    },
    searchAccount: {
      marginTop: 8,
    },
    searchGroup: {
    },
    footer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: 15,
    },
    clearFiltersButton: {
      height: "auto",
      minWidth: "auto",
      padding: "6px 8px",
    },
    clearFiltersButtonLabel: {
      textTransform: "uppercase",
    },
    showFooter: {
    },
  });

export default styles;
