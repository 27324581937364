import { connect } from "react-redux";
import copyToClipboard from "clipboard-copy";
import { AppSchema } from "@schemas";
import randomStringGenerator from "@util/RandomStringGenerator";
import UserInfo, { Actions, Model } from "../components/UserInfo";
import {
  updateFederatedUserType,
  setMfaEnabled,
  setShowPassword,
  showPassword,
  updateMfaSecret,
  updatePassword,
  updateUserId,
  updateUserType,
  setSelectedFederationProvider,
  setFederationProviders,
} from "../actions";
import {
  getMfaSecret,
  getMfaSecretErrorMessage,
  getPassword,
  getPasswordErrorMessage,
  getUserId,
  getUserIdErrorMessage,
  isLoadingIndicatorVisible,
  isMfaEnabled,
  isPasswordVisible,
  isPortalUser,
  isFederatedUser,
  getSelectedFederationProvider,
} from "../selectors";

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  userId: getUserId(state),
  userIdErrorMessage: getUserIdErrorMessage(state),
  password: getPassword(state),
  passwordErrorMessage: getPasswordErrorMessage(state),
  showPassword: isPasswordVisible(state),
  mfaEnabled: isMfaEnabled(state),
  mfaSecret: getMfaSecret(state),
  mfaSecretErrorMessage: getMfaSecretErrorMessage(state),
  isPortalUser: isPortalUser(state),
  isFederatedUser: isFederatedUser(state),
  selectedFederationProvider: getSelectedFederationProvider(state),
  showLoadingIndicator: isLoadingIndicatorVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setUserId: (userId: string) => dispatch(updateUserId(userId)),
  setPassword: (password: string) => dispatch(updatePassword(password)),
  setShowPassword: (showPasswordEnabled: boolean) => dispatch(setShowPassword(showPasswordEnabled)),
  setMfaEnabled: (mfaEnabled: boolean) => dispatch(setMfaEnabled(mfaEnabled)),
  setMfaSecret: (mfaSecret: string) => dispatch(updateMfaSecret(mfaSecret)),
  setIsPortalUser: (portalUser: boolean) => dispatch(updateUserType(portalUser)),
  setIsFederatedUser: (federatedUser: boolean) => dispatch(updateFederatedUserType(federatedUser)),
  setFederationProviders: (federationProviders: string[]) =>
    dispatch(setFederationProviders(federationProviders)),
  setSelectedFederationProvider: (selectedFederationProvider: string) =>
    dispatch(setSelectedFederationProvider(selectedFederationProvider)),
  generateRandomPassword: () => {
    const password = randomStringGenerator();
    dispatch(updatePassword(password));
    dispatch(showPassword());
    return copyToClipboard(password);
  },
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(UserInfo);
