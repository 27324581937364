import React from "react";
import classnames from "classnames";
import { clickHandler } from "@util";
import ContinueButton from "@components/continue-button";
import { PrimaryIcon, deviceTypes as styles } from "./styles";
import { RefreshButton } from "@components/refresh-button";
import { Search } from "@components/search";
import { withQuantity } from "@components/summary";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export const Summary = withQuantity({
  other: "device types",
  one: "device type",
});

export interface Model {
  numItems?: number;
  searchQuery?: string;
  searchPlaceholder?: string;
  loading?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  updateSearchQuery?: (searchQuery: string, previousSearchQuery?: string) => void;
  createDeviceType?: () => void;
  refresh?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DeviceTypes = withStyles(styles)((props: Props) => {

  const {
    classes,
    numItems = 0,
    searchQuery = "",
    searchPlaceholder = "Filter device types by name",
    loading,
    children,
    updateSearchQuery,
    createDeviceType,
    refresh,
  } = props;

  return (
    <div className={classnames("deviceTypes", classes.container)}>
      <Summary label="Device Types" quantity={numItems} icon={PrimaryIcon}>
        <RefreshButton loading={loading} refresh={refresh} />
        <div className={classnames(classes.controls)}>
          <ContinueButton
            className={classnames("createDeviceTypeButton", classes.createDeviceTypeButton)}
            onClick={clickHandler(createDeviceType)}
          >
            Create Device Type
          </ContinueButton>
        </div>
      </Summary>
      <Search
        className={classnames("search", classes.search)}
        hint={searchPlaceholder}
        value={searchQuery}
        updateSearchQuery={updateSearchQuery}
      />
      {children}
    </div>
  );
});

export default DeviceTypes;
