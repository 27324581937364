export enum AuditEventFilter {
  NONE = "",
  TYPE = "type",
  SEVERITY = "severity",
  ACCOUNT_ID = "accountId",
  PRINCIPAL = "principal",
  ORIGIN = "origin",
  REQUEST_ID = "requestId",
  START_DATE = "startDate",
  END_DATE = "endDate",
}

export default AuditEventFilter;
