import React from "react";
import useApiRequest, {
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import { DataSetRequestEvent } from "@data";
import { GetDataSetRequestEventsResponse } from "@network";
import useDataSetRequestClient from "../use-data-set-request-client";

type SuccessResponse = GetDataSetRequestEventsResponse;

export interface UseGetDataSetRequestEventsProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  dataSetRequestId: string;
}

export interface UseGetDataSetRequestEventsModel extends UseApiRequestModel<SuccessResponse> {
  events: DataSetRequestEvent[];
}

export interface UseGetDataSetRequestEventsActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetDataSetRequestEventsProps;
type Model = UseGetDataSetRequestEventsModel;
type Actions = UseGetDataSetRequestEventsActions;
type Result = [Model, Actions];

export const useGetDataSetRequestEvents = (props: Props): Result => {

  const {
    dataSetRequestId = "",
    defaultErrorMessage = "Failed to get data set request events",
    ...otherProps
  } = props;

  const DataSetRequestClient = useDataSetRequestClient();

  const makeApiRequest = React.useCallback(() =>
    DataSetRequestClient.getDataSetRequestEvents(dataSetRequestId),
    [DataSetRequestClient, dataSetRequestId]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const events = React.useMemo(() => {
    const { events: items = [] } = successResponse || {};
    return items.map(attrs => new DataSetRequestEvent(attrs));
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successResponse,
    events,
  }), [
    baseModel,
    successResponse,
    events,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetDataSetRequestEvents;
