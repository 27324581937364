import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import SortedSearchResultsList, {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import Typography from "@material-ui/core/Typography";
import ActionsListItemData from "./ActionsListItemData";
import ActionsListColumn from "./ActionsListColumn";
import ActionsListItem from "./ActionsListItem";
import styles from "./styles";

type Item = ActionsListItemData;
type Column = ActionsListColumn;

const DEFAULT_COLUMNS: Column[] = [
  ActionsListColumn.NAME,
  ActionsListColumn.ACTIONS,
];

export interface ActionsListModel extends SortedSearchResultsListModel<Item, Column> {
  className?: string;
  title?: string;
  workloadCompletionMetadataActions?: string[];
  emptyViewEnabled?: boolean;
  editModeEnabled?: boolean;
  showDeleteButton?: boolean;
}

export interface ActionsListActions extends SortedSearchResultsListActions<Item, Column> {
  editActions?: (updatedActions: ActionsListItemData[]) => void;
  customDelete?: (action: ActionsListItemData) => void;
}

type Model = ActionsListModel;
type Actions = ActionsListActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ActionsList = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    listClassName,
    editModeEnabled,
    columns = DEFAULT_COLUMNS.filter(column =>
      column !== ActionsListColumn.ACTIONS || editModeEnabled),
    workloadCompletionMetadataActions = [],
    items = workloadCompletionMetadataActions.map(action => new ActionsListItemData({
      name: action,
    })),
    tableLayoutFixed = true,
    sortingDisabled = true,
    showSearch = false,
    showSummary = false,
    title = "Actions",
    noResultsLabel = "No custom actions defined",
    header = React.useMemo(() => isEmptyString(title) ? null : (
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
    ), [classes, title]),
    emptyViewEnabled,
    showDeleteButton = false,
    editActions = noop,
    customDelete = noop,
    renderItem = (item: Item, column: Column) => (
      <ActionsListItem
        item={item}
        column={column}
        editItem={(updatedItem: ActionsListItemData) => {
          const index = workloadCompletionMetadataActions.indexOf(item.getName());
          if (index >= 0) {
            // Create new copy of array
            const updatedActions = workloadCompletionMetadataActions.slice();
            // Mutate updated array (replace existing entry)
            updatedActions.splice(index, 1, updatedItem.getName());
            editActions(updatedActions.map(action => new ActionsListItemData({name: action})));
          }
        }}
        isDuplicate={(name: string) => workloadCompletionMetadataActions.includes(name)}
      />
    ),
    ...otherProps
  } = props;

  const deleteItem = React.useCallback((item: ActionsListItemData) => {
    const index = workloadCompletionMetadataActions.indexOf(item.getName());
    if (index >= 0) {
      // Create new copy of array
      const updatedActions = workloadCompletionMetadataActions.slice();
      // Mutate updated array (delete entry)
      updatedActions.splice(index, 1);
      editActions(updatedActions.map(action => new ActionsListItemData({ name: action })));
    }
  }, [workloadCompletionMetadataActions, editActions]);

  const onClickDeleteIcon = React.useMemo(() =>
      showDeleteButton ? customDelete === noop ? deleteItem : customDelete : undefined,
    [showDeleteButton, customDelete, deleteItem]);

  if (items.length === 0 && !emptyViewEnabled) {
    return null;
  }

  return (
    <SortedSearchResultsList
      {...otherProps}
      className={classnames("actionsList", className, classes.container)}
      listClassName={classnames("list", listClassName, classes.list)}
      columns={columns}
      items={items}
      tableLayoutFixed={tableLayoutFixed}
      sortingDisabled={sortingDisabled}
      showSearch={showSearch}
      showSummary={showSummary}
      noResultsLabel={noResultsLabel}
      header={header}
      renderItem={renderItem}
      onClickDeleteIcon={onClickDeleteIcon}
    />
  );
});

export default ActionsList;
