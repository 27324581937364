import React from "react";
import classnames from "classnames";
import DefaultIcon from "@material-ui/icons/Info";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles, requiredIcon } from "./styles";
import { formatUTCDate, isEmptyString } from "@util";
import Link, { UnderlineOptions } from "@components/link";
import { Chip } from "@components/chip";
import { ComparableItem } from "@components";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import ListViewItem, { DEFAULT_LIST_VIEW_ITEM } from "./ListViewItem";
import { CheckmarkIcon } from "@icons";
import isEqual from "lodash/isEqual";

type StyleProps = WithStyles<typeof styles>;
const RequiredIcon = withStyles(requiredIcon)(CheckmarkIcon);

export const ModuleListItemFirstColumn = withStyles(styles)((props: StyleProps & {
  icon?: React.ComponentType<SvgIconProps> | null;
  rel?: string;
  value?: string;
  target?: string;
  className?: string;
  pathToDetailsView?: string;
}) => {

  const {
    classes,
    icon: PrimaryIcon = DefaultIcon,
    rel,
    target,
    value = "",
    className = "",
    pathToDetailsView = ""
  } = props;

  if (isEmptyString(value)) {
    return null;
  }

  return (
    <div className={classnames("moduleListItemFirstColumn", classes.container)}>
      <Link
        className={classnames("nameColumnLink",
          isEmptyString(pathToDetailsView) ? classes.disabledLink : classes.link)}
        underline={isEmptyString(pathToDetailsView) ? UnderlineOptions.NONE : UnderlineOptions.ALWAYS}
        href={pathToDetailsView}
        target={target}
        rel={rel}
      >
        {PrimaryIcon !== null && (
          <PrimaryIcon className={classnames("moduleIcon", classes.icon)} />
        )}
        <label className={classnames("moduleName", className, classes.label)}>{value}</label>
      </Link>
    </div>
  );
});

export const ModuleListItem = withStyles(styles)(<Item extends ComparableItem<Item>>
(props: WithStyles<typeof styles> & {
  listItems?: ListViewItem<Item>[],
  item?: Item,
  column?: string;
}) => {

  const { classes, listItems = [ DEFAULT_LIST_VIEW_ITEM ], item = {}, column } = props;

  if (!column || Object.keys(item).length === 0) {
    return null;
  }

  const listItem = listItems.find(object => isEqual(object.item, item));

  if (!listItem) {
    return null;
  }

  const icon = React.useMemo(() => listItem.icon , [listItem]);

  const colAttrs = listItem.columnAttributes.find(colAttr => colAttr.column === column);

  if (!colAttrs || typeof colAttrs !== "object" || Object.keys(colAttrs).length === 0) {
    return null;
  }

  const {
    firstColumn,
    isDate,
    value,
    className,
    chipTags,
    checkmark,
    customEl = null,
    formatDatePattern = "LLL",
  } = colAttrs;

  if (customEl !== null) {
    return (
      <React.Fragment>
        {customEl}
      </React.Fragment>
    );
  }

  if (firstColumn) {
    return (
      <ModuleListItemFirstColumn
        value={isDate ? formatUTCDate(value, formatDatePattern) : value}
        icon={icon}
        rel={listItem.rel}
        target={listItem.target}
        pathToDetailsView={listItem.pathToDetailsView}
        className={className}
      />
    );
  }

  if (Array.isArray(chipTags) && chipTags.length > 0) {
    return (
      <ul className={classnames("tags", classes.tags)}>
        {chipTags.map((tag: string, index: number) => (
          <li key={`${tag}-${index}`} className={classnames("tag", classes.tag)}>
            <Chip
              className={classnames("chip", className, classes.chip)}
              label={tag}
            />
          </li>
        ))}
      </ul>
    );
  }

  if (checkmark) {
    return (
      <label className={classnames("checkmark", classes.label)}>
        <RequiredIcon/>
      </label>
    );
  }

  if (isDate) {
    return (
      <label className={classnames("value", className, classes.label)}>
        {formatUTCDate(value, formatDatePattern)}
      </label>
    );
  }

  return (
    <label className={classnames("value", className, classes.label)}>{value}</label>
  );
});

export default ModuleListItem;
