import Color from "@Color";
import ModuleIcon from "@material-ui/icons/VerifiedUser";
import createStyles from "@material-ui/core/styles/createStyles";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    container: {
      marginTop: 0,
    },
    content: {
      marginTop: 25,
      "&$loading > h3": {
        // Details view is using an inline style that our class style cannot override without !important
        marginTop: "0px !important",
      },
      "& .summaryView": {
        // Summary view label action icon button were not aligned with default min widths
        "& label.name + label.value": {
          minWidth: 200,
        },
        // Add some space in between the shared summary view content and embedded child view content
        "& .summaryViewChildContent": {
          marginTop: 25,
        },
      },
      "&$showMfaFilterSwitch .summaryView label.name": {
        // When the filter switch is used, the label names look like there is too much gap between
        // the name and the value, so the temporary fix is to adjust the minWidth
        // if the mfa switch is visible (ie: the mfa summary view label is not visible)
        minWidth: 150,
      },
    },
    summaryView: {
      marginTop: 0,
    },
    summaryViewContent: {
      marginTop: 25,
    },
    title: {
      margin: "25px 0 0 0",
      color: Color.TEXT,
      fontSize: 24,
      fontWeight: 300,
      lineHeight: "28px",
    },
    loading: {
    },
    showMfaFilterSwitch: {
    },
  });

export default styles;
