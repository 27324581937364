import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { DropdownMenu, TextField } from "@components";
import {
  mapMemoryToSize,
  mapSizeToMemory,
  MemorySize,
} from "@modules/dataWorkloadWizard/components/AdvancedSettings";
import { useEditMemory } from "@hooks";

export interface WorkloadMemoryDialogModel extends PortalModuleDialogModel<string> {
  name?: string;
  version?: number;
  memory: number;
  etag: string;
  dialogClassName?: string;
  memorySizes?: string[];
}

export interface WorkloadMemoryDialogActions extends PortalModuleDialogActions<string> {
  setMemory?: (memory: string) => void;
}

type Props = WithStyles<typeof styles> & WorkloadMemoryDialogModel & WorkloadMemoryDialogActions;

export const WorkloadMemoryDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    dialogClassName,
    name = "",
    version = 1,
    memory,
    etag,
    memorySizes = [MemorySize.SMALL, MemorySize.MEDIUM, MemorySize.LARGE, MemorySize.OTHER],
    setMemory = noop,
    cancel: cancelDialog = noop,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  const [newMemory, setNewMemory] = React.useState<number>(memory);
  const [size, setSize] = React.useState<MemorySize>(MemorySize.SMALL);
  const [customMemory, setCustomMemory] = React.useState<number>(0);

  const updateSize = React.useCallback((newSize: MemorySize) => {
    setSize(newSize);
    setNewMemory(mapSizeToMemory(newSize, customMemory));
  }, [setNewMemory, setSize, mapSizeToMemory, customMemory]);

  const updateCustomMemory = React.useCallback((newSize: number) => {
    setCustomMemory(newSize);
    setNewMemory(newSize);
  }, [setNewMemory, setCustomMemory]);

  const [{ showLoadingIndicator, ...otherModel }, { editMemory, reset }] =
    useEditMemory({ name, version, etag, memory: newMemory });

  const onClose = React.useCallback(() => {
    setSize(mapMemoryToSize(memory));
    setCustomMemory(memory);
  }, [setSize, setCustomMemory, memory]);

  const confirm = React.useCallback(() => {
    editMemory();
  }, [editMemory]);

  const cancel = React.useCallback(() => {
    reset();
    cancelDialog();
    onClose();
  }, [reset, cancelDialog, onClose]);

  const onSuccessMessageShown = React.useCallback(() => {
    reset();
    onSuccess();
    onClose();
  }, [reset, onSuccess, onClose]);

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    }
  };

  const memoryError = React.useMemo(() =>
      customMemory < 756 || customMemory > 2048,
    [customMemory]);

  React.useEffect(() => {
    setSize(mapMemoryToSize(memory));
    setCustomMemory(memory);
  }, [memory]);

  if (isEmptyString(name)) {
    return null;
  }

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      className={classnames("workloadMemoryDialog", dialogClassName)}
      title="Edit Workload Memory"
      loading={showLoadingIndicator}
      continueButtonLabel="Save"
      continueButtonDisabled={memoryError}
      onSuccessMessageShown={onSuccessMessageShown}
      confirm={confirm}
      cancel={cancel}
    >
      <div className={classnames("workloadMemoryContainer", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Select memory for this workload:
          </label>
          <div className={classnames("workloadMemory", classes.memorySelection)}>
            <DropdownMenu
              className={classnames("memory", classes.dropdown)}
              selectedValue={size}
              setSelectedValue={updateSize}
              values={memorySizes}
              hideEmptyValue={true}
              selectClassName={classes.dropdownMenuSelect}
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
            />
            {size === MemorySize.OTHER && (
              <TextField
                className={classnames("customMemory", classes.customMemory)}
                type="number"
                value={customMemory}
                onChange={event =>
                  updateCustomMemory(isEmptyString(event.target.value) ? 0 : parseInt(event.target.value, 10))}
                variant="outlined"
                inputProps={{ min: 756, max: 2048 }}
                InputLabelProps={inputLabelProps}
                error={memoryError}
                label="Custom Memory"
                helperText="Enter custom memory between 756MB and 2048MB."
              />
            )}
          </div>
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadMemoryDialog;
