import React from "react";
import classnames from "classnames";
import { clickHandler, isEmptyString, noop } from "@util";
import { Button, ErrorView, SaveButton } from "@components";
import { PolicyOperation, PolicyOperationPrincipal } from "@hooks";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface Model {
  className?: string;
  title?: string;
  errorTitle?: string;
  errorMessage?: string;
  showLoadingIndicator?: boolean;
  showSuccessIndicator?: boolean;
  cancelButtonLabel?: string;
  saveButtonLabel?: string;
  saveButtonDisabled?: boolean;
  nextButtonLabel?: string;
  nextButtonDisabled?: boolean;
  managedPoliciesViewSelected?: boolean;
  policiesViewSelected?: boolean;
  information?: string;
  viewButtonDisabled?: boolean;
  children?: React.ReactNode;
  showReviewView?: boolean;
  showPoliciesView?: boolean;
  id?: string;
  policyName?: string;
  accountId?: string;
  operation?: PolicyOperation;
  principal?: PolicyOperationPrincipal;
  isManagedPolicy?: boolean;
}

export interface Actions {
  setPolicyName?: (name: string) => void;
  setPolicyRef?: (ref: string) => void;
  setManagedPoliciesView?: () => void;
  setPoliciesView?: () => void;
  cancel?: () => void;
  save?: () => void;
  nextStep?: () => void;
  previousStep?: (error: boolean) => void;
  updatePolicies?: (operation: PolicyOperation, principal: PolicyOperationPrincipal,
                    isManagedPolicy: boolean, id: string, accountId: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const PolicyManagement = withStyles(styles)((props: Props) => {

  const {
    id,
    accountId,
    operation,
    principal,
    isManagedPolicy,
    classes,
    className,
    title = "Policy Identity Management",
    information = "",
    errorTitle,
    errorMessage,
    showLoadingIndicator,
    showSuccessIndicator,
    cancelButtonLabel = "Cancel",
    saveButtonLabel = "Save",
    saveButtonDisabled,
    nextButtonDisabled,
    managedPoliciesViewSelected,
    showReviewView = false,
    policiesViewSelected = true,
    viewButtonDisabled = false,
    cancel,
    updatePolicies = noop,
    nextStep = noop,
    previousStep = noop,
    setManagedPoliciesView,
    setPoliciesView,
    children,
  } = props;

  const isLoadingOrFinished = React.useMemo(() =>
    showLoadingIndicator || showSuccessIndicator,
    [showLoadingIndicator, showSuccessIndicator]);

  const controls = (
    <div className={classnames("controls", classes.footerButtonControls)}>
      {!isLoadingOrFinished && (
        <Button
          className={classnames("cancelButton", classes.cancelButton)}
          classes={{
            label: classnames("label", classes.cancelButtonLabel),
          }}
          variant="text"
          onClick={clickHandler(cancel)}
        >
          {cancelButtonLabel}
        </Button>
      )}
      {!showReviewView && (
        <Button
          className={classnames("nextStepButton", classes.button, classes.nextStepButton)}
          disabled={nextButtonDisabled}
          variant="contained"
          color="primary"
          onClick={nextStep}
        >
          Next Step
        </Button>
      )}
      {!isLoadingOrFinished && showReviewView && (
        <Button
          className={classnames("previousButton", classes.cancelButton)}
          classes={{
            label: classnames("label", classes.cancelButtonLabel),
          }}
          variant="text"
          onClick={() => previousStep(!isEmptyString(errorMessage))}
        >
          Previous Step
        </Button>
      )}
      {showReviewView && (
        <SaveButton
          className={classnames("saveButton", classes.saveButton)}
          label={isEmptyString(errorMessage) ? saveButtonLabel : "Retry"}
          disabled={saveButtonDisabled}
          loading={showLoadingIndicator}
          success={showSuccessIndicator}
          save={() => {
            updatePolicies(operation, principal, isManagedPolicy, id, accountId);
          }}
        />
      )}
    </div>
  );

  return (
    <div className={classnames("policyManagement", className, classes.container)}>
      <label className={classnames("title", classes.title)}>
        {title}
      </label>
      <ErrorView
        className={classnames("error", classes.error)}
        title={errorTitle || `${saveButtonLabel} Failed`}
        message={errorMessage}
      />
      {!showReviewView && (
        <div className={classnames("headerControls", classes.headerButtonControls)}>
          <Button
            className={classnames("policiesButton", classes.policiesButton)}
            color={policiesViewSelected ? "primary" : "inherit"}
            onClick={clickHandler(setPoliciesView)}
            variant="contained"
            disabled={viewButtonDisabled}
          >
            Custom Policies
          </Button>
          <Button
            className={classnames("managedPoliciesButton", classes.managedPoliciesButton)}
            color={managedPoliciesViewSelected ? "primary" : "inherit"}
            onClick={clickHandler(setManagedPoliciesView)}
            variant="contained"
            disabled={viewButtonDisabled}
          >
            Managed Policies
          </Button>
        </div>
      )}
      <label className={classes.information}>
        {information}
      </label>
      {showReviewView && controls}
      {children}
      {!showReviewView && controls}
    </div>
  );
});

export default PolicyManagement;
