import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import {
  getSuccessMessage,
  isNoteLoadingIndicatorVisible,
  isAddNoteButtonActive,
  getNoteErrorMessage,
  isCreateNoteBox,
  getNote,
} from "../selectors";
import { createAdminNotes, showCreateNoteBox, hideCreateNoteBox, setNote } from "../actions";
import CreateNoteView, { Model, Actions } from "../components/AdminNotes/CreateNoteView";

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => {

  const successMessage = getSuccessMessage(state);
  const finished = !!(successMessage && successMessage.length > 0);
  const showProgressIndicator = isNoteLoadingIndicatorVisible(state);
  const buttonsDisabled = showProgressIndicator || finished;

  const showCancelButton = !finished && !showProgressIndicator;
  const showSaveButton = !finished && isAddNoteButtonActive(state);

  return {
    showProgressIndicator,
    showCancelButton,
    showSaveButton,
    finished,
    buttonsDisabled,
    errorMessage: getNoteErrorMessage(state),
    createNoteBoxVisible: isCreateNoteBox(state),
    note: getNote(state),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  save: () => dispatch(createAdminNotes()),
  cancel: () => dispatch(hideCreateNoteBox()),
  setNote: (note: string) => dispatch(setNote(note)),
  addNote: () => dispatch(showCreateNoteBox()),
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(CreateNoteView);
