import React from "react";
import { useCertificateAuthorities } from "@hooks";
import { CertificateAuthorityList } from "@components";

const ManagedPoliciesContainer = () => {

  const [ model, actions] = useCertificateAuthorities();

  const { certificateAuthorities } = model;

  return (
    <CertificateAuthorityList
      {...model}
      {...actions}
      summaryViewLabel="Certificate Authorities"
      items={certificateAuthorities}
      showSummary={true}
      showSearch={true}
    />
  );
};

export default ManagedPoliciesContainer;
