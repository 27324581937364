import { batch } from "react-redux";
import { AppSchema } from "@schemas";
import {
  getAuthToken,
  getCurrentAccountId,
  getCurrentUserId,
  getEnabledModuleIds,
  getFavoritesData,
  getFavoritesKey,
  getModules,
  getPrincipalId,
  isLoggedIn,
  isServicePrincipal,
  isUserPrincipal,
} from "../selectors";
import {
  AuthenticateServiceRegionalResponse,
  GetMaintenanceRemindersResponse,
  LoginResponse,
  MaintenanceReminderClient,
  RestClientError,
  UserIdmLegacyClient,
} from "@network";
import {
  ActionType,
  hashString,
  identityAction,
  isEmptyString,
  isMaintenanceReminderEnabled,
  noop,
} from "@util";
import {
  IdentityType,
  MaintenanceReminderAttributes,
  MaintenanceReminderState,
  Module,
  User,
  UserAttributes,
} from "@data";
import {
  loginWithFederationProvider,
  setAccount as setLoginAccountId,
  setIdentityType as setLoginIdentityType,
  setUsername as setLoginUsername,
} from "@modules/login/actions";
import { isFederatedLoginResponseAvailable } from "@modules/login/selectors";

export const toggleShowPortal = (value: boolean = false) => ({
  type: ActionType.TOGGLE_SHOW_PORTAL,
  value,
});

export const toggleShowFeedbackDialog = (value: boolean = false) => ({
  type: ActionType.TOGGLE_FEEDBACK_DIALOG,
  value,
});

const hideFeedbackDialog = () => toggleShowFeedbackDialog(false);

export const showPortal = () => (dispatch: any) => {
  // When the session is first created, set the first mouse move time to the current date
  // because we log users out after 10 minutes of being idle unless they move their mouse
  // so the first mouse move should be when we show portal
  dispatch(setLastMouseMoved(Date.now()));
  return dispatch(toggleShowPortal(true));
};

export const setQueryParams = (queryParams: string = "") => ({
  type: ActionType.UPDATE_QUERY_PARAMS,
  value: queryParams,
});

export const setVersion = (version: string = "") => ({
  type: ActionType.SET_VERSION,
  value: version,
});

export const setVersionCode = (versionCode: string = "") => ({
  type: ActionType.SET_VERSION_CODE,
  value: versionCode,
});

export const setUserReportMessage = (message: string = "") => ({
  type: ActionType.SET_USER_REPORT_MESSAGE,
  value: message,
});

export const reloadApp = () => ({
  type: ActionType.APP_RELOAD,
});

export const resetAppState = () => ({
  type: ActionType.RESET,
});

export const updateCurrentUser = (user: UserAttributes) => ({
  type: ActionType.UPDATE_CURRENT_USER,
  value: user,
});

export const setLastMouseMoved = (when: number = 0) => ({
  type: ActionType.UPDATE_LAST_MOUSE_MOVED,
  value: when
});

export const setEnabledModuleIds = (value: string[]) => ({
  type: ActionType.UPDATE_ENABLED_MODULE_IDS,
  value,
});

export const disableModuleId = (id: string) => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const enabledModuleIds = getEnabledModuleIds(state);
  return dispatch(setEnabledModuleIds(
    enabledModuleIds.filter(enabledModuleId => enabledModuleId !== id)));
};

export const setAuthToken = (authToken: string = "") => ({
  type: ActionType.UPDATE_AUTH_TOKEN,
  value: authToken || "",
});

export const setAuthTokenExpiryTime = (expiryTime: string = "") => ({
  type: ActionType.UPDATE_AUTH_TOKEN_EXPIRY_TIME,
  value: expiryTime || "",
});

export const updateRefreshToken = (refreshToken: string) => ({
  type: ActionType.UPDATE_REFRESH_TOKEN,
  value: refreshToken,
});

export const setAccountId = (accountId: string = "") => ({
  type: ActionType.SET_ACCOUNT_ID,
  value: accountId || "",
});

export const setPrincipalId = identityAction<ActionType>(
  ActionType.SET_PRINCIPAL_ID, "");

export const setIdentityType = identityAction<ActionType, IdentityType>(
  ActionType.SET_IDENTITY_TYPE, IdentityType.USER);

export const updateFavorites = (favorites: string) => ({
  type: ActionType.SET_USER_FAVORITES,
  value: favorites,
});

export const updateFavoritesKey = (favoritesKey: string) => ({
  type: ActionType.SET_USER_FAVORITES_KEY,
  value: favoritesKey,
});

export const updateCurrentFavorites = (currFavorites: string[]) => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const favoritesKey = getFavoritesKey(state);
  const favorites = getFavoritesData(state);
  favorites[favoritesKey] = currFavorites.join();
  return dispatch(updateFavorites(JSON.stringify(favorites)));
};

export const setFavoritesKey = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const accountId = getCurrentAccountId(state);
  const user = getCurrentUserId(state);
  return hashString(accountId + user).then((digest) => {
    dispatch(updateFavoritesKey(digest));
  });
};

export const updateMaintenanceReminder = (maintenanceReminder: MaintenanceReminderAttributes) => ({
  type: ActionType.SET_MAINTENANCE_REMINDER,
  value: maintenanceReminder,
});

export const updateClosedMaintenanceReminders = (closedMaintenanceReminders: string[]) => ({
  type: ActionType.UPDATE_CLOSED_MAINTENANCE_REMINDERS,
  value: JSON.stringify(closedMaintenanceReminders),
});

export const logout = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const authToken = getAuthToken(state);

  if (authToken.length > 0) {

    const userPrincipal = isUserPrincipal(state);

    // We only need to call logout if a user principal is logging out
    if (userPrincipal) {

      const user = getCurrentUserId(state);

      // We don't care about the response from logout since we clear state locally
      UserIdmLegacyClient.logout(authToken, user).then(noop, noop);
    }

    // TODO: Refactor API architecture to ensure we do not have to worry about race conditions like this one
    // We only need to reset app state if the user is logged in because this could potentially
    // be called as a result of multiple API request failures, which could cause the login
    // initialize method to be overridden by the default login module state
    dispatch(resetAppState());

    // Make sure the login screen shows the most recent identity type when the browser reloads
    dispatch(setLoginIdentityType(userPrincipal ? IdentityType.USER : IdentityType.SERVICE));
  }

  return Promise.resolve();
};

// TODO: Do something with response profile attributes or maybe just ditch this this entirely
export const fetchCurrentUser = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const authToken = getAuthToken(state);
  const userId = getCurrentUserId(state);

  dispatch({ type: ActionType.FETCH_CURRENT_USER_REQUEST });

  return UserIdmLegacyClient.getCurrentUser(authToken, userId)
    .then(() => {

      return dispatch({ type: ActionType.FETCH_CURRENT_USER_SUCCESS });

    }, (response: RestClientError) => {

      const { analytic, status } = response;

      if (status === 401 || status === 403) {
        console.error("Logging out because token is no longer valid");
        dispatch(logout());
      }

      return dispatch({
        type: ActionType.FETCH_CURRENT_USER_FAILURE,
        value: analytic,
      });
    });
};

export const fetchMaintenanceReminder = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const authToken = getAuthToken(state);

  dispatch({ type: ActionType.FETCH_MAINTENANCE_REMINDER_REQUEST });

  return MaintenanceReminderClient.getMaintenanceReminder(authToken)
    .then((response: GetMaintenanceRemindersResponse) => {

      dispatch({ type: ActionType.FETCH_MAINTENANCE_REMINDER_SUCCESS });

      const validReminder = response.reminders.filter((reminder) =>
        reminder.reminderState === MaintenanceReminderState.PUBLISHED &&
        !isEmptyString(reminder.description));

      // Shows the active maintenance reminder with the earliest start date or no reminder at all
      return dispatch(updateMaintenanceReminder(validReminder.length ? validReminder[0] : {}));

    }, (response: RestClientError) => {

      const { analytic } = response;

      return dispatch({
        type: ActionType.FETCH_MAINTENANCE_REMINDER_FAILURE,
        value: analytic,
      });
    });
};

export const createUserSession = (response: LoginResponse) => (dispatch: any) => {

  const { accountId, userId, accessToken, refreshToken, accessTokenExpiry } = response;

  return batch(() => {
    dispatch(updateCurrentUser(new User({ id: userId, userId }).toJS()));
    dispatch(setAccountId(accountId));
    dispatch(setPrincipalId(userId));
    dispatch(setIdentityType(IdentityType.USER));
    dispatch(updateRefreshToken(refreshToken));
    dispatch(setAuthToken(accessToken));
    dispatch(setAuthTokenExpiryTime(accessTokenExpiry));
    dispatch(setQueryParams(""));
    dispatch(showPortal());
  });
};

export const createServiceSession = (response: AuthenticateServiceRegionalResponse,
                                     accountId: string,
                                     serviceId: string) => (dispatch: any) => {

  const { accessToken, expiration } = response;

  return batch(() => {
    dispatch(setAccountId(accountId));
    dispatch(setPrincipalId(serviceId));
    dispatch(setIdentityType(IdentityType.SERVICE));
    dispatch(setAuthToken(accessToken));
    dispatch(setAuthTokenExpiryTime(expiration));
    dispatch(setQueryParams(""));
    dispatch(showPortal());
  });
};

export const updateLastMouseMove = (when: number) => (dispatch: any) => dispatch(setLastMouseMoved(when));

export const updateAuthToken = (authToken: string, expiryTime: string) =>
  (dispatch: any, getState: () => AppSchema) => {

  dispatch(setAuthToken(authToken));

  dispatch(setAuthTokenExpiryTime(expiryTime));

  if (isUserPrincipal(getState())) {
    return dispatch(fetchCurrentUser());
  }
};

export const restoreUserSession = (restoreSessionFailedCallback: () => void = noop) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();
    const hasFederatedLoginResponse = isFederatedLoginResponseAvailable(state);
    const accountId = getCurrentAccountId(state);
    const username = isUserPrincipal(state) ? getPrincipalId(state) : "";

    // Make sure we have enough information to actually initiate federated login flow
    if (isEmptyString(accountId) || isEmptyString(username)) {
      console.error("User session cannot be restored because either the accountId or username is invalid");
      restoreSessionFailedCallback();
      return Promise.resolve();
    }

    // Make sure we are not trying to restore session when being redirected back from federated login
    if (hasFederatedLoginResponse) {
      console.error("Restore user session should not be called after redirect back from federated login");
      restoreSessionFailedCallback();
      return Promise.resolve();
    }

    dispatch(setLoginAccountId(accountId));
    dispatch(setLoginUsername(username));
    return dispatch(loginWithFederationProvider());
  };

export const initialize = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const loggedIn = isLoggedIn(state);
  const servicePrincipal = isServicePrincipal(state);

  getModules(state).forEach((module: Module) => {
    if (!module.disabled && module.initialize) {
      dispatch(module.initialize());
    }
  });

  // Make sure the "submit feedback" dialog is not still open if the browser reloads
  // because the feedback dialog content will not be persisted either.
  dispatch(hideFeedbackDialog());

  if (isMaintenanceReminderEnabled()) {
    dispatch(fetchMaintenanceReminder());
  }

  if (!loggedIn) {
    return Promise.resolve();
  }

  if (!servicePrincipal) {
    dispatch(setFavoritesKey());
    return dispatch(fetchCurrentUser());
  }
};
