import { AppSchema } from "@schemas";
import { identityAction, ReduxAction } from "@util";
import { Dashboard, DashboardAttributes } from "@data";
import { DASHBOARDS_MODULE_ID } from "@modules/portlet/constants";
import { getDashboards, isSortOrderAscending } from "./selectors";
import { setInitialized } from "@modules/dashboardDetails/actions";
import { DashboardColumn, DashboardsActionType, DEFAULT_STATE } from "./reducers";
import addPersistedModuleId from "@main/actions/addPersistedModuleId";

type Action = ReduxAction<DashboardsActionType>;

export const setSelectedTab = identityAction<DashboardsActionType, string>(
  DashboardsActionType.SET_SELECTED_TAB, DEFAULT_STATE.selectedTab);

export const setDashboards = identityAction<DashboardsActionType, DashboardAttributes[]>(
  DashboardsActionType.SET_ITEMS, DEFAULT_STATE.items);

export const setSearchQuery = identityAction<DashboardsActionType, string>(
  DashboardsActionType.SET_SEARCH_QUERY, DEFAULT_STATE.searchQuery);

export const setSortedColumn = identityAction<DashboardsActionType, DashboardColumn>(
  DashboardsActionType.SET_SORTED_COLUMN, DEFAULT_STATE.sortedColumn);

export const setSortOrderAscending = identityAction<DashboardsActionType, boolean>(
  DashboardsActionType.SET_SORT_ORDER_ASCENDING, DEFAULT_STATE.sortOrderAscending);

export const setErrorMessage = identityAction<DashboardsActionType, string>(
  DashboardsActionType.SET_ERROR_MESSAGE, DEFAULT_STATE.errorMessage);

export const setShowEmptyView = identityAction<DashboardsActionType, boolean>(
  DashboardsActionType.SET_SHOW_EMPTY_VIEW, DEFAULT_STATE.showEmptyView);

export const setShowAccessDenied = identityAction<DashboardsActionType, boolean>(
  DashboardsActionType.SET_SHOW_ACCESS_DENIED, DEFAULT_STATE.showAccessDenied);

export const setShowLoadingIndicator = identityAction<DashboardsActionType, boolean>(
  DashboardsActionType.SET_SHOW_LOADING_INDICATOR, DEFAULT_STATE.showLoadingIndicator);

export const toggleSortOrder = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(setSortOrderAscending(!isSortOrderAscending(getState())));

export const showEmptyView = (): Action => setShowEmptyView(true);
export const hideEmptyView = (): Action => setShowEmptyView(false);

export const showAccessDenied = (): Action => setShowAccessDenied(true);
export const hideAccessDenied = (): Action => setShowAccessDenied(false);

export const showLoadingIndicator = (): Action => setShowLoadingIndicator(true);
export const hideLoadingIndicator = (): Action => setShowLoadingIndicator(false);

export const fetchAllDashboardsRequest = (): Action => ({
  type: DashboardsActionType.FETCH_ALL_DASHBOARDS_REQUEST,
  value: Date.now()
});

const fetchAllDashboardsSuccess = (): Action => ({
  type: DashboardsActionType.FETCH_ALL_DASHBOARDS_SUCCESS,
});
export const updateReaders  = (readers: { [key: number]: [string] }) =>
    (dispatch: any, getState: () => AppSchema) => {
      const dashboards = getDashboards(getState()).map((dashboard) => {
        return new Dashboard({
          ...dashboard.toJS(),
          readers: readers[dashboard.id]
        }).toJS();
      });
      console.debug("updateReaders/dashboards", dashboards);
      return dispatch(setDashboards(dashboards));
    };

export const updateDataSources = (dataSources: { [key: number]: [string] }) =>
    (dispatch: any, getState: () => AppSchema) => {
      const dashboards = getDashboards(getState()).map((dashboard) => {
        return new Dashboard({
          ...dashboard.toJS(),
          publishable: !dataSources[dashboard.id].find((datasource) => datasource.startsWith("user_")),
          dataSources: dataSources[dashboard.id]
        }).toJS();
      });
      console.debug("updateDataSources/dataSources", dataSources);
      console.debug("updateDataSources/dashboards", dashboards);
      return dispatch(setDashboards(dashboards));
    };

export const fetchDashboardsSuccess = (dashboardsFromSuperset: any[]) => (dispatch: any) => {
  dispatch(fetchAllDashboardsSuccess());
  const dashboardAttributes: DashboardAttributes[] = [];
  for (let i = 0; i < dashboardsFromSuperset.length; i++) {
    let dashboard = dashboardsFromSuperset[i];
    dashboardAttributes.push({
      id: dashboard.id,
      name: dashboard.dashboard_title,
      publishable: false,
      published: dashboard.published
    });
  }
  dispatch(setDashboards(dashboardAttributes));
  dispatch(setInitialized(true));
  dispatch(hideLoadingIndicator());
  return dispatch(hideEmptyView());
};

export const fetchDashboards = () => (dispatch: any) => {
  dispatch(showLoadingIndicator());
  dispatch(setErrorMessage());
  dispatch(hideAccessDenied());
  return dispatch(fetchAllDashboardsRequest());
};

export const reset = () => (dispatch: any) => {
  dispatch(setDashboards());
  dispatch(setSearchQuery());
  dispatch(setSortedColumn());
  dispatch(setSortOrderAscending());
  dispatch(setErrorMessage());
  dispatch(setShowEmptyView());
  dispatch(setShowAccessDenied());
  return dispatch(setShowLoadingIndicator());
};

export const refresh = () => (dispatch: any) => {
  dispatch(setDashboards());
  dispatch(setSortedColumn());
  return dispatch(fetchDashboards());
};

export const onMount = () => (dispatch: any) => {
  // The dashboard access control module has a dependency on the dashboard module, which was
  // previously always persisted when the application was initialized; however, recent changes have
  // changed this to a lazy persisted module where it will only be initialized once its route is
  // active. Since this module depends on communication with the dashboard module, we must make
  // sure that the dashboards module is rendered into the DOM when the dashboard access control
  // module is rendered; otherwise, there is no way to communicate with dashboards via postMessage
  // to determine the published & unpublished dashboards for the active user.
  dispatch(addPersistedModuleId(DASHBOARDS_MODULE_ID));
  return dispatch(fetchDashboards());
};

export const initialize = () => (dispatch: any, getState: () => AppSchema) => {
  // This is necessary until we can guarantee initialize will never be called before onMount;
  // however, this can happen with our current architecture where we iterate through every
  // registered - and enabled - module and call each initialize method when the app is mounted,
  // but we also called the active module's onMount method, which could end up being called
  // before the active module's initialize method in that scenario.
  const dashboards = getDashboards(getState());
  dispatch(reset());
  return dispatch(setDashboards(dashboards));
};
