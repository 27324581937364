import React from "react";
import classnames from "classnames";
import { clickHandler } from "@util";
import { Button, HtmlEditor, HtmlPreview } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";

export interface HtmlBodyViewModel {
  title?: string;
  previewViewSelected?: boolean;
  previewViewLabel?: string;
  rawViewSelected?: boolean;
  rawViewLabel?: string;
  htmlBody?: string;
  children?: React.ReactNode;
}

export interface HtmlBodyViewActions {
  showPreviewView?: () => void;
  showRawView?: () => void;
}

type Props = WithStyles<typeof styles> & HtmlBodyViewModel & HtmlBodyViewActions;

export const HtmlBodyView = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "HTML Body",
    previewViewLabel = "Preview",
    previewViewSelected,
    rawViewSelected,
    rawViewLabel = "Raw",
    htmlBody = "",
    children,
    showPreviewView,
    showRawView
  } = props;

  return (
      <div className={classnames("htmlBodyView", classes.container)}>
        <h1 className={classnames("title", classes.title)}>{title}</h1>
        <div className={classnames("controls", classes.controls)}>
          <Button
              className={classnames("previewButton", classes.viewModeButton)}
              color={previewViewSelected ? "primary" : "inherit"}
              onClick={clickHandler(showPreviewView)}
              variant="contained"
          >
            {previewViewLabel}
          </Button>
          <Button
              className={classnames("rawButton", classes.viewModeButton)}
              color={rawViewSelected ? "primary" : "inherit"}
              onClick={clickHandler(showRawView)}
              variant="contained"
          >
            {rawViewLabel}
          </Button>
        </div>
        <div className={classnames("content", classes.content)}>
          {previewViewSelected && (
              <HtmlPreview
                  className={classnames("htmlPreview", classes.htmlPreview)}
                  srcDoc={htmlBody}
              />
          )}
          {rawViewSelected && (
              <HtmlEditor
                  className={classnames("htmlEditor", classes.htmlEditor)}
                  html={htmlBody}
                  readOnly={true}
              />
          )}
        </div>
        {children}
      </div>
  );
});

export default HtmlBodyView;
