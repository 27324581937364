import { Record } from "immutable";
import { getStringValue } from "@util";
import {
  EmailBodyAttributes,
  EmailTemplateLanguage,
  EmailTemplateMessageType,
  EmailTemplateRequest,
  EmailTemplateRequestAttributes,
} from "@data";

export interface EmailTemplateWizardStateAttributes extends EmailTemplateRequestAttributes {
  templateId: string;
}

export class EmailTemplateWizardState extends Record({
  templateId: "",
  ...EmailTemplateRequest.EMPTY.toJS(),
}) implements EmailTemplateWizardStateAttributes {

  public static EMPTY = new EmailTemplateWizardState();

  public readonly templateId: string;
  public readonly applicationId: string;
  public readonly language: EmailTemplateLanguage;
  public readonly messageType: EmailTemplateMessageType;
  public readonly body: EmailBodyAttributes;

  public getId(): string {
    return getStringValue(this.templateId);
  }
}

export default EmailTemplateWizardState;
