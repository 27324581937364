import React from "react";
import classnames from "classnames";
import { noop } from "@util";
import { ActionMenuItem, ActionsMenu, ActionsMenuProps } from "../actions-menu";

export enum DataApprovalRequestAction {
  NONE = "",
  APPROVE = "approve_data_approval_request",
  REJECT = "reject_data_approval_request",
}

export const APPROVE_DATA_APPROVAL_REQUEST_ACTION: ActionMenuItem = Object.freeze({
  id: DataApprovalRequestAction.APPROVE,
  name: "Approve Request",
  disabled: false,
  hidden: false,
});

export const REJECT_DATA_APPROVAL_REQUEST_ACTION: ActionMenuItem = Object.freeze({
  id: DataApprovalRequestAction.REJECT,
  name: "Reject Request",
  disabled: false,
  hidden: false,
});

export const DEFAULT_DATA_APPROVAL_REQUEST_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  APPROVE_DATA_APPROVAL_REQUEST_ACTION,
  REJECT_DATA_APPROVAL_REQUEST_ACTION,
]) as ActionMenuItem[];

export interface DataApprovalRequestActionHandlers {
  approveDataApprovalRequest?: () => void;
  rejectDataApprovalRequest?: () => void;
}

export const DataApprovalRequestActionsMenu = (props: DataApprovalRequestActionHandlers & ActionsMenuProps) => {

  const {
    className,
    buttonLabel = "Data Approval Request Actions",
    actions = DEFAULT_DATA_APPROVAL_REQUEST_ACTION_MENU_ITEMS,
    onClickAction = noop,
    approveDataApprovalRequest = noop,
    rejectDataApprovalRequest = noop,
    ...otherProps
  } = props;

  return (
    <ActionsMenu
      {...otherProps}
      className={classnames("dataApprovalRequestActionsMenu", className)}
      actions={actions}
      buttonLabel={buttonLabel}
      onClickAction={(action: ActionMenuItem) => {
        switch (action.id) {
          case DataApprovalRequestAction.APPROVE:
            return approveDataApprovalRequest();
          case DataApprovalRequestAction.REJECT:
            return rejectDataApprovalRequest();
          default:
            return onClickAction(action);
        }
      }}
    />
  );
};

export default DataApprovalRequestActionsMenu;
