import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { AlertDialog, DownloadButton, ErrorView } from "@components";
import { useTermsAndConditionsUrl } from "@hooks";
import styles from "./styles";

export interface DownloadTermsAndConditionsButtonModel {
  className?: string;
  buttonClassName?: string;
  disabled?: boolean;
  label?: string;
  termsConditionsId?: string;
}

export interface DownloadTermsAndConditionsButtonActions {
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
}

type Model = DownloadTermsAndConditionsButtonModel;
type Actions = DownloadTermsAndConditionsButtonActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DownloadTermsAndConditionsButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    buttonClassName,
    disabled,
    label = "Download Privacy Terms & Conditions",
    termsConditionsId = "",
    trackRequestEvent = noop,
    trackSuccessEvent = noop,
    trackErrorEvent = noop,
    children,
  } = props;

  const [showErrorDialog, setShowErrorDialog] = React.useState(false);

  const [model, api] = useTermsAndConditionsUrl({
    termsConditionsId,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
  });

  const { errorMessage, showErrorView, showLoadingIndicator, termsConditionsUrl } = model;

  const { reset, download } = api;

  React.useEffect(() => setShowErrorDialog(showErrorView), [setShowErrorDialog, showErrorView]);

  if (isEmptyString(termsConditionsId)) {
    return null;
  }

  return (
    <React.Fragment>
      <DownloadButton
        className={classnames("downloadTermsAndConditionsButton", className, classes.downloadButton)}
        downloadFileClassName="downloadTermsAndConditionsFile"
        buttonClassName={buttonClassName}
        label={label}
        href={termsConditionsUrl}
        loading={showLoadingIndicator}
        disabled={disabled}
        download={download}
      />
      <AlertDialog
        className={classnames("errorDialog", classes.errorDialog)}
        open={showErrorDialog}
        title="Download Failed"
        close={reset}
      >
        <ErrorView
          className={classnames("errorView", classes.errorView)}
          message={errorMessage}
          retry={download}
          retryButtonLabel="Retry"
          retryButtonClassName={classnames("retryButton", classes.retryButton)}
        />
      </AlertDialog>
      {children}
    </React.Fragment>
  );
});

export default DownloadTermsAndConditionsButton;
