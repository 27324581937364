import React from "react";
import { WorkloadVariableSchema } from "@data";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useWorkloadManagerClient } from "@hooks";

type SuccessResponse = void;

export interface UseAddVariablesProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  etag: string;
  variables: WorkloadVariableSchema[];
}

export interface UseAddVariablesModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseAddVariablesActions {
  save: () => void;
  reset: () => void;
}

type Props = UseAddVariablesProps;
type Model = UseAddVariablesModel;
type Actions = UseAddVariablesActions;
type Result = [Model, Actions];

export const useAddVariables = (props: Props): Result => {

  const {
    name,
    version,
    etag,
    variables = [],
    defaultErrorMessage = "Failed to add workload completion variables",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.addWorkloadVariablesApi(
      name,
      version,
      etag,
      JSON.stringify({ schemas: variables })),
    [WorkloadManagerClient, name, version, etag, variables]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: save, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Variables Added" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    save,
    reset
  }), [
    save,
    reset
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useAddVariables;
