import { Authenticator } from "@data";
import { AuthenticatorListColumn } from "./AuthenticatorListColumn";
import { Comparator, compare, isEmptyString } from "@util";
import React from "react";

export const getSearchResults = (items: Authenticator[], searchQuery: string) => {

  const authenticators = items.slice();

  if (authenticators.length === 0 || isEmptyString(searchQuery)) {
    return authenticators;
  }

  const searchFilter = searchQuery.toLowerCase();

  return authenticators.filter((authenticator: Authenticator) =>
    authenticator.getId().toLowerCase().indexOf(searchFilter) >= 0 ||
    authenticator.getStatus().toLowerCase().indexOf(searchFilter) >= 0 ||
    authenticator.getType().toLowerCase().indexOf(searchFilter) >= 0);
};

const compareByAuthenticatorId = (left: Authenticator, right: Authenticator, ascending: boolean) =>
  compare(left.getId(), right.getId(), ascending);

const compareByStatus = (left: Authenticator, right: Authenticator, ascending: boolean) =>
  compare(left.getStatus(), right.getStatus(), ascending);

const compareByType = (left: Authenticator, right: Authenticator, ascending: boolean) =>
  compare(left.getType(), right.getType(), ascending);

const compareByLabel = (left: Authenticator, right: Authenticator, ascending: boolean) =>
  compare(left.getLabel(), right.getLabel(), ascending);

export const createComparator =
  (sortedColumn: AuthenticatorListColumn, ascending: boolean): Comparator<Authenticator> =>
    (left: Authenticator, right: Authenticator) => {
      switch (sortedColumn) {
      case AuthenticatorListColumn.AUTHENTICATOR_ID:
        return compareByAuthenticatorId(left, right, ascending);
      case AuthenticatorListColumn.STATUS:
        return compareByStatus(left, right, ascending);
      case AuthenticatorListColumn.TYPE:
        return compareByType(left, right, ascending);
      case AuthenticatorListColumn.LABEL:
        return compareByLabel(left, right, ascending);
      default:
        return 0;
      }
    };

export const sortAndFilterAuthenticatorResult = (props: {
  items: Authenticator[],
  nameFilter: string,
  sortOrderAscending: boolean,
  sortByColumn: AuthenticatorListColumn
}) => {

  const {items, nameFilter, sortOrderAscending, sortByColumn } = props;

  const comparator = React.useMemo(() =>
    createComparator(sortByColumn, sortOrderAscending), [sortByColumn, sortOrderAscending]);

  const sortedStatements = React.useMemo(() => items.sort(comparator).slice(), [items, comparator]);

  return React.useMemo(() =>
    getSearchResults(sortedStatements, nameFilter), [sortedStatements, nameFilter]);
};
