import React from "react";
import { useWorkloadManagerClient } from "@hooks";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps } from "@hooks";

type SuccessResponse = void;

export interface UseEditTimeoutProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  timeout: number;
  etag: string;
}

export interface UseEditTimeoutModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseEditTimeoutActions {
  editTimeout: () => void;
  reset: () => void;
}

type Props = UseEditTimeoutProps;
type Model = UseEditTimeoutModel;
type Actions = UseEditTimeoutActions;
type Result = [Model, Actions];

export const useEditTimeout = (props: Props): Result => {

  const {
    name,
    version,
    timeout,
    etag,
    defaultErrorMessage = "Failed to edit workload timeout",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.editWorkloadTimeoutApi(name, version, etag, timeout.toString(10)),
    [WorkloadManagerClient, name, version, etag, timeout]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: editTimeout, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Edit Successful" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    editTimeout,
    reset
  }), [
    editTimeout,
    reset
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEditTimeout;
