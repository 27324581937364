import React from "react";
import { Policy } from "@data";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import JsonEditor from "@components/json-editor";
import { createPolicy, updateJson } from "../actions";
import {
  getCreatedPolicy,
  getErrorMessage,
  getJson,
  getSuccessMessage,
  isLoadingIndicatorVisible,
  isSaveButtonDisabled,
  isSuccessMessageVisible,
} from "../selectors";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
} from "@components/submit-api-request-view";

interface ContainerModel extends SubmitApiRequestViewModel<Policy> {
  json?: string;
}

interface ContainerActions extends SubmitApiRequestViewActions<Policy> {
  setJson?: (newValue: string) => void;
}

type Props = ContainerModel & ContainerActions;

const CreatePolicyContainer = (props: Props) => {

  const {
    json,
    setJson,
    showLoadingIndicator,
    showSuccessIndicator,
    currentState = Policy.EMPTY,
    defaultState = Policy.EMPTY,
    ...otherProps
  } = props;

  const readOnly = showLoadingIndicator || showSuccessIndicator;

  return (
    <SubmitApiRequestView
      {...otherProps}
      fullHeight={true}
      snackbarId="create-policy"
      errorTitle="Create Policy Failed"
      saveButtonLabel="Create Policy"
      currentState={currentState}
      defaultState={defaultState}
      showLoadingIndicator={showLoadingIndicator}
      showSuccessIndicator={showSuccessIndicator}
    >
      <JsonEditor
        className="jsonEditor"
        name="createPolicyJsonEditor"
        marginTop={false}
        readOnly={readOnly}
        setJson={setJson}
        json={json}
      />
    </SubmitApiRequestView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  currentState: getCreatedPolicy(state),
  saveButtonLabel: "Create Policy",
  successMessage: getSuccessMessage(state),
  errorMessage: getErrorMessage(state),
  showLoadingIndicator: isLoadingIndicatorVisible(state),
  showSuccessIndicator: isSuccessMessageVisible(state),
  saveButtonDisabled: isSaveButtonDisabled(state),
  json: getJson(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  save: () => dispatch(createPolicy()),
  setJson: (json: string) => dispatch(updateJson(json)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(CreatePolicyContainer);
