import React from "react";
import moment from "moment";
import classnames from "classnames";
import RemoveIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import DataLakeFile, { DataLakeFileType } from "../models/DataLakeFile";
import { compare, formatLocalDateToUtc, isEmptyString, noop } from "@util";
import { useDataLakeQueryResults } from "@hooks";
import { Chip } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import FileNameFilterAndRefreshButton from "../FileNameFilterAndRefreshButton";
import FileBrowser, { FileBrowserModel, FileBrowserActions } from "../FileBrowser";
import styles from "./styles";

export interface CustomSearchFileBrowserModel extends FileBrowserModel {
}

export interface CustomSearchFileBrowserActions extends FileBrowserActions {
}

type Model = CustomSearchFileBrowserModel;
type Actions = CustomSearchFileBrowserActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const CustomSearchFileBrowser = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    dataSetAlias = "",
    accountId = "",
    startDate = null,
    endDate = null,
    breadcrumbs = [dataSetAlias],
    fileNameFilter = "",
    setBreadcrumbs = noop,
    setStartDate = noop,
    setEndDate = noop,
    openFiltersMenu = noop,
    setFileNameFilter = noop,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
    children,
    ...otherProps
  } = props;

  const startDateQueryParam = React.useMemo<string>(() =>
      startDate === null ? "" : formatLocalDateToUtc(startDate),
    [startDate]);

  const endDateQueryParam = React.useMemo<string>(() =>
      endDate === null ? "" : formatLocalDateToUtc(endDate),
    [endDate]);

  const [{ queryResults, showLoadingIndicator, ...model }, actions] = useDataLakeQueryResults({
    deferRequest: true,
    dataSetAlias,
    startDate: startDateQueryParam,
    endDate: endDateQueryParam,
    accountId,
    fileNameContains: fileNameFilter,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
  });

  const { refresh } = actions;

  const header = React.useMemo(() => (
    <FileNameFilterAndRefreshButton
      className={classnames("fileNameFilterContainer", classes.fileNameFilterContainer)}
      fileNameFilterClassName={classnames("fileNameFilter", classes.fileNameFilter)}
      loading={showLoadingIndicator}
      disabled={showLoadingIndicator}
      fileNameFilter={fileNameFilter}
      setFileNameFilter={setFileNameFilter}
      refresh={refresh}
    />
  ), [classes, fileNameFilter, showLoadingIndicator, setFileNameFilter, refresh]);

  const mapBreadcrumbsToVisibleItems = React.useCallback((path: string[], files: DataLakeFile[]) => {

    const [year = "", month = "", day = ""] = path.slice(1);

    if (isEmptyString(year)) {
      return Array.from(new Set(files.map(file => moment(file.getUpdatedAt()).format("YYYY"))))
        .sort((left, right) => compare(left, right, true))
        .map(fileName => new DataLakeFile({
          fileName,
          fileSize: "-",
          updatedAt: "-",
          fileType: DataLakeFileType.FOLDER,
        }));
    }

    if (isEmptyString(month)) {
      return Array.from(new Set(files
        .filter(file => year === moment(file.getUpdatedAt()).format("YYYY"))
        .map(file => moment(file.getUpdatedAt()).format("MM"))))
        .sort((left, right) => compare(left, right, true))
        .map(fileName => new DataLakeFile({
          fileName,
          fileSize: "-",
          updatedAt: "-",
          fileType: DataLakeFileType.FOLDER,
        }));
    }

    if (isEmptyString(day)) {
      return Array.from(new Set(files
        .filter(file => `${year}-${month}` === moment(file.getUpdatedAt()).format("YYYY-MM"))
        .map(file => moment(file.getUpdatedAt()).format("DD"))))
        .sort((left, right) => compare(left, right, true))
        .map(fileName => new DataLakeFile({
          fileName,
          fileSize: "-",
          updatedAt: "-",
          fileType: DataLakeFileType.FOLDER,
        }));
    }

    const selectedDate = `${year}-${month}-${day}`;

    // Make sure that we do not include results from other dates when a specific
    // YYYY-MM-DD has been selected.
    return files
      .filter(file => selectedDate === moment(file.getUpdatedAt()).format("YYYY-MM-DD"));

  }, []);

  // Trigger refresh whenever startDate and endDate have changed to a non-null value and/or
  // the file name filter is changed
  React.useEffect(() => {
    refresh();
  }, [startDateQueryParam, endDateQueryParam, fileNameFilter, refresh]);

  return (
    <React.Fragment>
      <div className={classnames("searchFilters", classes.searchFilters)}>
        <Chip
          className={classnames("searchFilter", classes.searchFilter)}
          color={showLoadingIndicator ? "default" : "secondary"}
          disabled={showLoadingIndicator}
          clickable={true}
          label={(
            <React.Fragment>
              <label>Start Date: {moment(startDateQueryParam).format("YYYY-MM-DD")}</label>
              {endDateQueryParam && (
                <React.Fragment>
                  <br/>
                  <label>End Date: {moment(endDateQueryParam).format("YYYY-MM-DD")}</label>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          icon={<SearchIcon className={classnames("icon", classes.icon)}/>}
          deleteIcon={<RemoveIcon className={classnames("deleteIcon", classes.deleteIcon)} />}
          onClick={openFiltersMenu}
          onDelete={() => {
            setStartDate(null);
            setEndDate(null);
          }}
        />
      </div>
      <FileBrowser
        {...model}
        {...actions}
        {...otherProps}
        className={classnames("customSearchFileBrowser", className, classes.container)}
        dataLakeIndexItems={queryResults}
        header={header}
        showLoadingIndicator={showLoadingIndicator}
        dataSetAlias={dataSetAlias}
        accountId={accountId}
        breadcrumbs={breadcrumbs}
        startDate={startDate}
        endDate={endDate}
        fileNameFilter={fileNameFilter}
        setBreadcrumbs={setBreadcrumbs}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setFileNameFilter={setFileNameFilter}
        mapBreadcrumbsToVisibleItems={mapBreadcrumbsToVisibleItems}
      >
        {children}
      </FileBrowser>
    </React.Fragment>
  );
});

export default CustomSearchFileBrowser;
