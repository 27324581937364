import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      position: "relative",
      backgroundColor: Color.LIGHT_GREY,
    },
    errorContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      position: "relative",
      backgroundColor: Color.TRANSPARENT,
    },
    errorView: {
      marginTop: 0,
    },
    header: {
      alignSelf: "stretch",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 75,
      margin: "0 0 10px 0",
      backgroundColor: Color.WHITE,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    },
    loadingMessage: {
      color: Color.TEXT,
      margin: 0,
      fontSize: 18,
      fontWeight: 300,
      flex: "1 0 auto",
      textAlign: "center",
    },
    fileName: {
      color: Color.TEXT,
      marginLeft: 15,
      fontWeight: 300,
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      maxWidth: 125,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    fileNameIcon: {
      width: "auto",
      height: 16,
      marginRight: 6,
    },
    controls: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    previousPageButton: {
    },
    previousPageIcon: {
    },
    currentPage: {
      color: Color.TEXT,
      fontWeight: 300,
    },
    nextPageButton: {
    },
    nextPageIcon: {
    },
    download: {
      marginLeft: "auto",
      marginRight: 12,
      minWidth: 150,
      "& .fab": {
        marginLeft: "auto",
        marginRight: 12,
      },
      "& .progressIndicator": {
        top: -2,
        left: -2,
      },
    },
    downloadButton: {
    },
    downloadButtonIcon: {
      width: "auto",
      height: 16,
      marginRight: 6,
    },
    pdf: {
      flex: "1 0 auto",
      padding: 10,
      minHeight: 500,
    },
  });

export default styles;
