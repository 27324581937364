import React from "react";
import usePaginatedApiRequest, {
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
} from "@hooks/use-paginated-api-request";
import { ListLayoutsTypesResponse } from "@network/DigitalShadowClient";
import useDigitalShadowClient from "../use-digital-shadow-client";
import { DSTypeLayout } from "@data";

type SuccessResponse = ListLayoutsTypesResponse;

export interface UseListDSLayoutProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
  limit?: number;
}

export interface UseListDSLayoutModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  layouts: DSTypeLayout[];
}

export interface UseListDSLayoutActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseListDSLayoutProps;
type Model = UseListDSLayoutModel;
type Actions = UseListDSLayoutActions;
type Result = [Model, Actions];

export const useListDSLayouts = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to list digital shadow layouts",
    limit,
    ...otherProps
  } = props;

  const DigitalShadowClient = useDigitalShadowClient();

  const [layouts, setLayouts] = React.useState<DSTypeLayout[]>([]);

  const makeApiRequest = React.useCallback((accessToken, next) =>
      DigitalShadowClient.listLayoutsApi(next, limit),
    [DigitalShadowClient, limit]);

  const [{ successResponse, ...baseModel }, {
    reset: baseReset,
    refresh: baseRefresh,
    ...baseActions
  }] =
    usePaginatedApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const updatedLayouts = React.useMemo<DSTypeLayout[]>(() => {
    const { items = [] } = successResponse || {};
    return items.map(attrs => new DSTypeLayout(attrs));
  }, [successResponse]);

  const reset = React.useCallback(() => {
    setLayouts([]);
    baseReset();
  }, [setLayouts, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    layouts,
  }), [
    baseModel,
    layouts,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    refresh();
  }, [
    refresh,
    limit,
  ]);

  React.useEffect(() => {
    setLayouts(currentTypes => currentTypes.concat(updatedLayouts));
  }, [updatedLayouts, setLayouts]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useListDSLayouts;
