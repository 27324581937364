import React from "react";
import { useLocation } from "react-router-dom";
import { equalsIgnoreCase, getStringValue, isEmptyString } from "@util";

export interface LoginPageQueryParams {
  accountId?: string;
  userId?: string;
  autoLogin?: boolean;
}

export const mapSearchToLoginPageQueryParams = (search: string = ""): LoginPageQueryParams => {

  const queryParams = new URLSearchParams(getStringValue(search));
  const accountId = queryParams.get("accountId") || "";
  const userId = queryParams.get("userId") || "";
  const autoLogin = queryParams.get("autoLogin") || "";

  return {
    ...(isEmptyString(accountId) ? ({}) : ({ accountId })),
    ...(isEmptyString(userId) ? ({}) : ({ userId })),
    ...(isEmptyString(autoLogin) ? ({}) : ({ autoLogin: equalsIgnoreCase("true", autoLogin) })),
  };
};

export const useLoginPageQueryParams = (): LoginPageQueryParams => {

  const { search } = useLocation();

  return React.useMemo(() => mapSearchToLoginPageQueryParams(search), [search]);
};

export default useLoginPageQueryParams;
