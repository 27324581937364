export enum DataSetsListColumn {
  NONE = "",
  NAME = "Name",
  GROUP = "Group",
  ACCOUNT = "Account",
  TYPE = "Type",
  TAGS = "Tags",
  PII = "PII?",
}

export default DataSetsListColumn;
