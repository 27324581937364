import React from "react";
import classnames from "classnames";
import Button from "@components/button";
import { JsonSchemaProperty } from "@data";
import { clickHandler, noop } from "@util";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { ActionMenuItem } from "@components/actions-menu";
import { PropertiesTableFooterRow } from "./PropertiesTableFooterRow";
import { PropertiesTableHeaderRow } from "./PropertiesTableHeaderRow";
import { PropertiesTableBodyRow, PropertyTableRowData } from "./PropertiesTableBodyRow";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export const NUM_COLUMNS = 6;

const mapPropertyToDefaultValueLabel = (property: JsonSchemaProperty) => {

  const { defaultValue } = property;

  if (!property.hasDefaultValue()) {
    return "";
  }

  if (property.isNull()) {
    return "null";
  }

  if (property.isObject()) {
    return "{ ... }";
  }

  if (property.isArray()) {
    return "[ ... ]";
  }

  return defaultValue + "";
};

const mapPropertyToTableRowData = (property: JsonSchemaProperty): PropertyTableRowData => ({
  name: property.name,
  type: property.type,
  description: property.description,
  defaultValue: mapPropertyToDefaultValueLabel(property),
  required: property.required,
});

export interface Model {
  className?: string;
  properties?: JsonSchemaProperty[];
  actions?: ActionMenuItem[];
  showLoadingIndicator?: boolean;
  addButtonLabel?: string;
  noResultsLabel?: string;
  loadingLabel?: string;
  children?: React.ReactNode;
}

export interface Actions {
  addProperty?: () => void;
  onClickProperty?: (property: JsonSchemaProperty) => void;
  onClickPropertyAction?: (property: JsonSchemaProperty, action: ActionMenuItem) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const PropertiesTable = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    properties = [],
    actions = [],
    showLoadingIndicator,
    addButtonLabel = "Add Property",
    noResultsLabel = "No Results",
    loadingLabel = "Loading...",
    children,
    addProperty,
    onClickProperty = noop,
    onClickPropertyAction = noop,
  } = props;

  const clickable = typeof onClickProperty === "function" && onClickProperty !== noop;

  const showAddPropertyButton = typeof addProperty === "function" && addProperty !== noop;

  const showActionsColumn = actions.length > 0;

  const numColumns = showActionsColumn ? NUM_COLUMNS : (NUM_COLUMNS - 1);

  const showNoResults = !showLoadingIndicator && properties.length === 0;

  const isClickable = (property: JsonSchemaProperty) => {

    if (!clickable) {
      return false;
    }

    if (showActionsColumn) {
      return true;
    }

    return property.isObject() || (property.isReference() && property.hasReference());
  };

  return (
    <Table className={classnames("propertiesTable", className, classes.table)}>
      <TableHead>
        <PropertiesTableHeaderRow showActionsColumn={showActionsColumn} />
      </TableHead>
      <TableBody>
        {properties.map((property: JsonSchemaProperty, rowIndex: number) => (
          <PropertiesTableBodyRow
            { ...mapPropertyToTableRowData(property) }
            key={`table-body-row-${rowIndex}`}
            clickable={isClickable(property)}
            actions={actions}
            onClick={() => onClickProperty(property)}
            onClickAction={(action: ActionMenuItem) => onClickPropertyAction(property, action)}
          />
        ))}
        {showAddPropertyButton && (
          <PropertiesTableFooterRow
            className={classnames("addButtonRow", classes.addButtonRow)}
            columnClassName={classnames("addButtonColumn", classes.addButtonColumn)}
            numColumns={numColumns}
          >
            <Button
              className={classnames("addButton", classes.addButton)}
              onClick={clickHandler(addProperty)}
            >
              {addButtonLabel}
            </Button>
          </PropertiesTableFooterRow>
        )}
        {showNoResults && (
          <PropertiesTableFooterRow
            className="empty"
            columnClassName={classes.center}
            numColumns={numColumns}
          >
            {noResultsLabel}
          </PropertiesTableFooterRow>
        )}
        {showLoadingIndicator && (
          <PropertiesTableFooterRow
            className="loading"
            columnClassName={classes.center}
            numColumns={numColumns}
          >
            {loadingLabel}
          </PropertiesTableFooterRow>
        )}
        {children && (
          <PropertiesTableFooterRow numColumns={numColumns}>
            {children}
          </PropertiesTableFooterRow>
        )}
      </TableBody>
    </Table>
  );
});

export default PropertiesTable;
