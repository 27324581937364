import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      "& label.title": {
        marginBottom: 15,
      },
      "& label.name" : {
        minWidth: 240,
      },
    },
    chipView: {
      marginBottom: 30,
      "& label.title": {
        marginBottom: 0,
      },
    },
    summaryViewContainer: {
      marginTop: 25,
      display: "flex",
      flexFlow: "row nowrap",
    },
    summaryView: {
      flex: "0 1 50%",
      paddingRight: 20,
      marginTop: 0,
      "& label.name": {
        minWidth: 160,
      },
      "& label.value": {
        fontSize: 11,
        maxWidth: 350,
        wordWrap: "break-word",
      },
    },
    policyView: {
      flex: "1 0 auto",
      marginTop: 0,
    },
    sqlEditor: {
      marginTop: 25,
      display: "contents"
    },
    dataSets: {
      marginTop: 25,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    dataListView: {
      marginBottom: 40,
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      minWidth: 100,
      alignSelf: "flex-start",
      fontSize: 24,
      lineHeight: "28px",
    },
    dataSetContainer: {
      marginTop: 25,
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
    },
    dataSetInputContainer: {
      display: "flex",
      flex: "0 0 50%",
      flexFlow: "column nowrap",
      paddingRight: 20,
    },
    dataSetOutputContainer: {
      display: "flex",
      flex: "0 0 50%",
      flexFlow: "column nowrap",
    },
    dataSetControls: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
    },
    completionMetadata: {
      marginBottom: 30,
      "& .variablesList": {
        paddingLeft: 0,
      },
    },
  });

export default styles;
