import { Record } from "immutable";
import { QueryTableColumns } from "@network";

export interface QueryDatabaseTableAttributes {
  name: string;
  columns: QueryTableColumns[];
}

export class QueryDatabaseTable extends Record({
  name: "",
  columns: [],
}) implements QueryDatabaseTableAttributes {

  public static EMPTY: QueryDatabaseTable = new QueryDatabaseTable();

  public readonly name: string;
  public readonly columns: QueryTableColumns[];

  public getName(): string {
    return this.name;
  }

  public getColumns(): QueryTableColumns[] {
    return Array.isArray(this.columns) ? this.columns : [];
  }

}

export default QueryDatabaseTable;
