import React from "react";
import { applyInProd } from "@util";
import { withBuildInfo } from "@components/build-info";
import LoginPageContainer, { LoginPageContainerProps } from "./containers/LoginPageContainer";
import LoginPageRedirect from "@modules/login/LoginPageRedirect";
import { useLoginPageQueryParams } from "@hooks";

const LoginPage = (props: LoginPageContainerProps) => {

  const { accountId, userId, autoLogin } = useLoginPageQueryParams();

  // Obfuscate login query params w/ redirect that removes params and puts them into location state
  if (accountId !== undefined || userId !== undefined || autoLogin !== undefined) {
    return <LoginPageRedirect />;
  }

  return <LoginPageContainer {...props} />;
};

export default applyInProd(
  () => LoginPage,
  () => withBuildInfo(LoginPage));
