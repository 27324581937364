import React from "react";
import classnames from "classnames";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
} from "@components/submit-api-request-view";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import { Application } from "@data";

export enum ApplicationWizardSteps {
  APPLICATION_INFO = "application_info",
  OPEN_ID_CONNECT = "open_id_connect",
  SECURITY = "security",
  JSON = "json",
  REVIEW = "review",
}

export type ApplicationWizardStepLabels<K extends keyof any = ApplicationWizardSteps> = {
  [P in K]: string;
};

export const DEFAULT_STEP_LABELS: ApplicationWizardStepLabels = {
  [ApplicationWizardSteps.APPLICATION_INFO]: "Application Info",
  [ApplicationWizardSteps.OPEN_ID_CONNECT]: "OpenId Connect",
  [ApplicationWizardSteps.SECURITY]: "Security",
  [ApplicationWizardSteps.JSON]: "JSON",
  [ApplicationWizardSteps.REVIEW]: "Review",
};

export interface Model extends SubmitApiRequestViewModel<Application, ApplicationWizardSteps> {
  stepLabels?: ApplicationWizardStepLabels;
  isEdit?: boolean;
}

export interface Actions extends SubmitApiRequestViewActions<Application, ApplicationWizardSteps> {
  stepLabels?: ApplicationWizardStepLabels;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ApplicationWizard = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    snackbarId = "application-wizard",
    errorTitle = "Create Application Failed",
    saveButtonLabel = "Create Application",
    isEdit = false,
    defaultState = Application.EMPTY,
    currentState = Application.EMPTY,
    stepLabels = DEFAULT_STEP_LABELS,
    mapStepToLabel = React.useCallback(step => stepLabels[step] || null, [stepLabels]),
    children,
    ...otherProps
  } = props;

  return (
    <SubmitApiRequestView
      {...otherProps}
      className={classnames("applicationWizard", className, classes.container)}
      snackbarId={snackbarId}
      errorTitle={errorTitle}
      defaultState={defaultState}
      currentState={currentState}
      saveButtonLabel={saveButtonLabel}
      mapStepToLabel={mapStepToLabel}
    >
      {children}
    </SubmitApiRequestView>
  );
});

export default ApplicationWizard;
