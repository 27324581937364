import React from "react";
import { Module } from "@data";
import { initialize } from "./actions";
import { createModule } from "@modules/base";
import { isDeviceManagementEnabled } from "@util";
import { isAccessDeniedVisible } from "./selectors";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import DeviceDetailsTabsView from "./DeviceDetailsTabsView";
import view from "./DeviceDetailsView";

type RouteParams = { id?: string };

export const deviceDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: false,
  name: "Device Details",
  title: "Device Details",
  className: "deviceDetailsModule",
  primaryColor,
  icon,
  view,
  tabs: <DeviceDetailsTabsView />,
  isAccessDeniedVisible,
  onMount: ({ id = "" }: RouteParams = {}) => initialize(id),
  disabled: !isDeviceManagementEnabled(),
});

export default deviceDetailsModule;
