import { connect } from "react-redux";
import copyToClipboard from "clipboard-copy";
import { AppSchema } from "@schemas";
import { randomStringGenerator } from "@util/RandomStringGenerator";
import { Actions, Model, PasswordInfo } from "../components/PasswordInfo";
import { setPassword, setShowPassword, showPassword } from "../actions";
import { getPassword, isShowPassword } from "../selectors";

const mapStateToProps = (state: AppSchema): Model => ({
  password: getPassword(state),
  showPassword: isShowPassword(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  setPassword: (password: string) => dispatch(setPassword(password)),
  setShowPassword: (showPasswordEnabled: boolean) => dispatch(setShowPassword(showPasswordEnabled)),
  generateRandomPassword: () => {
    const password = randomStringGenerator();
    dispatch(setPassword(password));
    dispatch(showPassword());
    return copyToClipboard(password);
  },
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordInfo);
