import React from "react";
import classnames from "classnames";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { clickHandler, isEmptyString, noop } from "../../../../util";
import { splashScreenIcon, styles } from "./styles";
import { IdentityType } from "../../../../data";
import {
  RefreshTokenResponse,
  UserIdmLegacyClient,
  RestClientError,
} from "../../../../network";

const SplashScreenIcon = withStyles(splashScreenIcon)(ErrorIcon);

const parseRefreshTokenResponse = (response: RefreshTokenResponse) => {

  const {
    tokens: {
      accessToken = "",
      expiryTime = "",
    } = {},
  } = response;

  return [accessToken, expiryTime];
};

export interface Model {
  userId?: string;
  accessToken?: string;
  refreshToken?: string;
  identityType?: IdentityType;
}

export interface Actions {
  logout?: () => void;
  updateAuthToken?: (authToken: string, expiryTime: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SplashScreen = withStyles(styles)((props: Props) => {

  const {
    classes,
    userId = "",
    accessToken = "",
    refreshToken = "",
    identityType = IdentityType.USER,
    updateAuthToken = noop,
    logout,
  } = props;

  const [initialized, setInitialized] = React.useState(false);

  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState("");

  const isServicePrincipal = IdentityType.SERVICE === identityType;

  const showSessionExpiredError = isServicePrincipal || !isEmptyString(errorMessage);

  React.useEffect(() => {

    let ignore = false;

    if (!initialized && !showLoadingIndicator && !showSessionExpiredError) {

      setInitialized(true);
      setShowLoadingIndicator(true);

      UserIdmLegacyClient.refresh(accessToken, userId, refreshToken)
        .then((response: RefreshTokenResponse) => {
          if (!ignore) {
            const [updatedAccessToken, updatedExpiryTime] = parseRefreshTokenResponse(response);
            setShowLoadingIndicator(false);
            updateAuthToken(updatedAccessToken, updatedExpiryTime);
          }
        }, (response: RestClientError) => {
          if (!ignore) {
            const { error = "Refresh token failed" } = response;
            setShowLoadingIndicator(false);
            setErrorMessage(error);
          }
        });
    }

    return () => { ignore = true; };

  }, []);

  return (
    <Paper className={classnames("splashScreen", classes.container)} elevation={2}>
      {showLoadingIndicator && (
        <div className={classnames("loading", classes.loading)}>
          <label className={classes.label}>Restoring Session...</label>
          <Button
            className={classnames("button", classes.button)}
            variant="text"
            color="inherit"
            onClick={clickHandler(logout)}
          >
            Cancel
          </Button>
        </div>
      )}
      {!showLoadingIndicator && showSessionExpiredError && <SplashScreenIcon />}
      {!showLoadingIndicator && showSessionExpiredError && (
        <div className={classnames("expired", classes.expired)}>
          <label className={classes.label}>Your session has expired</label>
          <Button
            className={classnames("button", classes.button)}
            variant="text"
            color="inherit"
            onClick={clickHandler(logout)}
          >
            Click <span className={classes.underlined}>here</span> to return to the IoT Portal login page
          </Button>
        </div>
      )}
    </Paper>
  );
});

export default SplashScreen;
