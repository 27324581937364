import React from "react";
import { Module } from "../../data";
import { MODULE_ID } from "./constants";
import { isDialogOpen } from "./selectors";
import { initialize, open } from "./actions";
import { PrimaryIcon } from "../users/components/styles";
import DeleteUserDialog from "./containers/DeleteUserDialog";

export const deleteUserModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "Delete User",
  title: "Delete User",
  icon: PrimaryIcon,
  view: <DeleteUserDialog />,
  onClick: open,
  isDialogOpen,
  initialize,
};

export default deleteUserModule;
