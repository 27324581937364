import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { Model, AddDeviceDialog } from "../../components/addDeviceWizard/AddDeviceDialog";
import { WizardDialogModel } from "@components";
import AddDeviceWizard from "../addDeviceWizard/AddDeviceWizard";
import { DeviceEnrollmentWizardSelectors } from "../../selectors";

type ContainerModel = Model & WizardDialogModel;

const AddDeviceDialogContainer = (props: ContainerModel) => {

  const { open: open = false } = props;

  if (!open) {
    return null;
  }

  return (
    <AddDeviceDialog {...props}>
      <AddDeviceWizard/>
    </AddDeviceDialog>
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
  title: DeviceEnrollmentWizardSelectors.getDeviceWizardTitle(state),
  className: DeviceEnrollmentWizardSelectors.getDeviceWizardClassName(state),
  open: DeviceEnrollmentWizardSelectors.isAddDeviceDialogVisible(state),
  maxWidth: "lg",
});

export default connect<ContainerModel, {}, ContainerModel>(
  mapStateToProps
)(AddDeviceDialogContainer);
