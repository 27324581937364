import React from "react";
import { noop } from "@util";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { closeDeleteDataDialog } from "../actions";
import {
 isDeleteDataDialogOpen,
} from "../selectors";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import DeleteDeviceData from "../components/DeleteDeviceData";

interface Model extends PortalModuleDialogModel<string> {
  schemaIdentity?: string;
}

interface Actions extends PortalModuleDialogActions<string> {
  deleteDeviceData?: () => void;
  onDeleteSuccess?: () => void;
}

const DeleteEmailTemplateDialog = (props: Model & Actions) => {

  const {
    schemaIdentity,
    deleteDeviceData = noop,
    onDeleteSuccess = noop,
    ...otherProps
  } = props;

  return (
    <PortalModuleDialog
      {...otherProps}
      onSuccessMessageShown={onDeleteSuccess}
      confirm={deleteDeviceData}
    >
      <DeleteDeviceData schemaIdentity={schemaIdentity} />
    </PortalModuleDialog>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  className: "deleteDeviceDataDialog",
  title: "Delete Device Data",
  open: isDeleteDataDialogOpen(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  cancel: () => dispatch(closeDeleteDataDialog()),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteEmailTemplateDialog);
