import React from "react";
import SortedSearchResultsList, {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import { UsersBulkRequest } from "@data";
import { UserReviewViewColumns, UserReviewViewListItem } from "./UserReviewViewListItem";

const DEFAULT_REVIEW_LIST_COLUMNS: UserReviewViewColumns[] = [
  UserReviewViewColumns.NAME,
  UserReviewViewColumns.STATUS,
  UserReviewViewColumns.ERRORS,
];

export type UserReviewViewListModel = SortedSearchResultsListModel<UsersBulkRequest, UserReviewViewColumns>;

export type UserReviewViewListActions = SortedSearchResultsListActions<UsersBulkRequest, UserReviewViewColumns>;

type Props = UserReviewViewListModel & UserReviewViewListActions;

export const UserReviewViewList = (props: Props) => {

  const {
    className = "userReviewViewList",
    columns = DEFAULT_REVIEW_LIST_COLUMNS,
    noResultsLabel = "No users selected",
    summaryViewQuantities = {
      other: "users",
      one: "user",
    },
    items = [],
    ...otherProps
  } = props;

  return (
    <SortedSearchResultsList
      {...otherProps}
      className={className}
      items={items}
      columns={columns}
      sortingDisabled={true}
      noResultsLabel={noResultsLabel}
      summaryViewQuantities={summaryViewQuantities}
      renderItem={(user: UsersBulkRequest, column: UserReviewViewColumns) => (
        <UserReviewViewListItem
          userBulkRequest={user}
          column={column}
        />
      )}
    />
  );
};

export default UserReviewViewList;
