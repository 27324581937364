import React from "react";
import { isEmptyString, noop } from "@util";
import { JsonSchemaDefinition } from "@data";
import { AuthTokenContext } from "@components";
import { RestClientError, SchemaRegistryClient } from "@network";

export const DEFAULT_SCHEMA: JsonSchemaDefinition = Object.freeze({
  $schema: "",
  title: "",
  description: "",
  type: "",
  properties: {},
  required: [],
});

export interface UseSchemaDefinitionProps {
  namespace?: string;
  name?: string;
  version?: string;
  onSuccess?: (schemaDefinition: JsonSchemaDefinition) => void;
  onFailure?: (error: string) => void;
}

export interface UseSchemaDefinitionModel {
  showProgressIndicator: boolean;
  isValidSchemaId: boolean;
}

export interface UseSchemaDefinitionActions {
  refresh: () => void;
}

export const useSchemaDefinition = (props: UseSchemaDefinitionProps = {}):
  [UseSchemaDefinitionModel, UseSchemaDefinitionActions] => {

  const {
    namespace,
    name,
    version,
    onSuccess = noop,
    onFailure = noop,
  } = props;

  const authToken = React.useContext(AuthTokenContext);
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);
  const [showProgressIndicator, setShowProgressIndicator] = React.useState(false);

  const isValidSchemaId = React.useMemo(() =>
    !isEmptyString(namespace) &&
    !isEmptyString(name) &&
    !isEmptyString(version),
    [namespace, name, version]);

  React.useEffect(() => {

    if (!isValidSchemaId) {
      return () => noop;
    }

    let ignore = false;

    setShowProgressIndicator(true);

    SchemaRegistryClient.getSchema(authToken, namespace, name, version)
      .then((schemaAttrs: JsonSchemaDefinition) => {

        if (!ignore) {
          onSuccess(schemaAttrs);
          setShowProgressIndicator(false);
        }

      }, (response: RestClientError) => {

        if (!ignore) {
          const { error = "Fetch schema definition failed" } = response;
          onFailure(error);
          setShowProgressIndicator(false);
        }
      });

    return () => { ignore = true; };

  }, [
    isValidSchemaId,
    setShowProgressIndicator,
    authToken,
    lastRefresh,
    namespace,
    name,
    version,
    onSuccess,
    onFailure,
  ]);

  const refresh = React.useCallback(() =>
    setLastRefresh(+new Date) , [setLastRefresh]);

  const model = React.useMemo<UseSchemaDefinitionModel>(() => ({
    showProgressIndicator,
    isValidSchemaId,
  }), [
    showProgressIndicator,
    isValidSchemaId,
  ]);

  const actions = React.useMemo<UseSchemaDefinitionActions>(() => ({
    refresh,
  }), [refresh]);

  return [model, actions];
};

export default useSchemaDefinition;
