import React from "react";
import classnames from "classnames";
import CheckmarkIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import CircularProgress from "@components/circular-progress";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { headerIcon as styles } from "./styles";

export const HeaderIcon = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  showErrorIcon?: boolean,
  showSuccessIcon?: boolean,
  showLoadingIndicator?: boolean,
}) => {

  const { classes, className, showErrorIcon, showSuccessIcon, showLoadingIndicator } = props;

  return (
    <div className={classnames("headerIcon", className, classes.container)}>
      {showLoadingIndicator && (
        <CircularProgress
          size={16}
          color="inherit"
          className="loading"
          classes={{
            root: classes.loadingIndicator,
            svg: classes.loadingIndicatorIcon,
          }}
        />
      )}
      {!showLoadingIndicator && showErrorIcon && (
        <ErrorIcon className={classnames("icon", "error", classes.icon)} />
      )}
      {!showLoadingIndicator && !showErrorIcon && showSuccessIcon && (
        <CheckmarkIcon className={classnames("icon", "success", classes.icon)} />
      )}
    </div>
  );
});

export default HeaderIcon;
