import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ModuleIcon, styles } from "./styles";
import { ChipView, DetailsView, SummaryView } from "@components";
import { SummaryViewData } from "@data";
import OwnerIcon from "@material-ui/icons/AccountBox";
import TagsIcon from "@material-ui/icons/Label";

export interface Model {
  title?: string;
  datasets: string[];
  readers: string[];
  dashboardSummaryItems?: SummaryViewData[];
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DashboardDetails = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Dashboard",
    readers,
    datasets,
    dashboardSummaryItems,
  } = props;

  const readerView = React.useMemo(() => {
    return readers.length === 0 ?
      (
        <SummaryView
          title={"Readers"}
          className={classnames("owners", classes.container)}
          items={[
            new SummaryViewData({
              className: "no-readers",
              name: "Readers",
              value: "None",
            })]}
        />
      ) : (
        <ChipView
          title={"Readers"}
          items={readers}
          icon={OwnerIcon}
          className={"owner"}
        />
      );
  }, [readers]);

  const datasetsView = React.useMemo(() => {
    return datasets.length === 0 ?
      (
        <SummaryView
          title={"Data Sets"}
          className={classnames("datasets", classes.container)}
          items={[
            new SummaryViewData({
              className: "no-datasets",
              name: "Data Sets",
              value: "None",
            })]}
        />
      ) : (
        <ChipView
          title={"Data Sets"}
          items={datasets}
          icon={TagsIcon}
          className={"owner"}
        />
      );
  }, [datasets]);

  const additionalViews = React.useMemo(() => (
    <React.Fragment>
      {readerView}
      {datasetsView}
    </React.Fragment>
  ), []);

  return (
    <div className={classnames("dataSetDetails", classes.container)}>
      <DetailsView
        title={title}
        icon={ModuleIcon}
        hideRefreshButton={true}
      >
        <SummaryView
          className={"dashboardSummaryView"}
          items={dashboardSummaryItems}
        />
        {additionalViews}
      </DetailsView>
    </div>
  );
});

export default DashboardDetails;
