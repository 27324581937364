import React from "react";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";
import { ThemeOptions } from "@material-ui/core/styles/createTheme";
import { createTheme, MuiThemeProvider, Theme, } from "@material-ui/core/styles";
import Color from "./Color";

const h1 = {
  fontFamily: "Roboto, sans-serif",
  fontSize: 48,
  fontWeight: 400,
  lineHeight: "52px",
};

const h2 = {
  fontFamily: "Roboto, sans-serif",
  fontSize: 38,
  fontWeight: 300,
  lineHeight: "42px",
};

const h3 = {
  fontFamily: "Roboto, sans-serif",
  fontSize: 28,
  fontWeight: 500,
  lineHeight: "32px",
};

const h4 = {
  fontFamily: "Roboto, sans-serif",
  fontSize: 24,
  fontWeight: 400,
  lineHeight: "28px",
};

const h5 = {
  fontFamily: "Roboto, sans-serif",
  fontSize: 20,
  fontWeight: 300,
  lineHeight: "24px",
};

const h6 = {
  fontFamily: "Roboto, sans-serif",
  fontSize: 18,
  fontWeight: 500,
  lineHeight: "21px",
};

const subtitle1 = {
  fontFamily: "Roboto, sans-serif",
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "16px",
};

const subtitle2 = {
  fontFamily: "Roboto, sans-serif",
  fontSize: 12,
  fontWeight: 500,
  lineHeight: "14px",
};

const body1 = {
  fontFamily: "Roboto, sans-serif",
  fontSize: 14,
  fontWeight: 300,
  lineHeight: "16px",
};

const body2 = {
  fontFamily: "Roboto, sans-serif",
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "14px",
};

const button = {
  fontFamily: "Roboto, sans-serif",
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "16px",
};

const caption = {
  fontFamily: "Roboto, sans-serif",
  fontSize: 12,
  fontWeight: 300,
  lineHeight: "14px",
};

const overline = {
  fontFamily: "Roboto, sans-serif",
  fontSize: 12,
  fontWeight: 300,
  lineHeight: "14px",
};

const typography: TypographyOptions = {
  htmlFontSize: 10,
  fontFamily: "Roboto, sans-serif",
  fontSize: 12,
  fontWeightLight: 200,
  fontWeightRegular: 300,
  fontWeightMedium: 400,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  subtitle1,
  subtitle2,
  body1,
  body2,
  button,
  caption,
  overline,
};

export const baseThemeOptions: ThemeOptions = {
  palette: {
    common: {
      black: Color.BLACK,
      white: Color.WHITE,
    },
    primary: {
      50: "#e8f5e9",
      100: "#c8e6c9",
      200: "#a5d6a7",
      300: "#81c784",
      400: "#66bb6a",
      500: "#4caf50",
      600: "#43a047",
      700: "#388e3c",
      800: "#2e7d32",
      900: "#1b5e20",
      A100: "#b9f6ca",
      A200: "#69f0ae",
      A400: "#00e676",
      A700: "#00c853",
      main: "#00e676",
      light: "#33eb91",
      dark: "#00a152",
      contrastText: "#3c3c41",
    },
    secondary: {
      50: "#e3e6ff",
      100: "#b9c2ff",
      200: "#8a99ff",
      300: "#5b70ff",
      400: "#3751ff",
      500: "#1432ff",
      600: "#122dff",
      700: "#0e26ff",
      800: "#0b1fff",
      900: "#0613ff",
      A100: "#ffffff",
      A200: "#f3f4ff",
      A400: "#c0c2ff",
      A700: "#a7a9ff",
      main: "#435bff",
      light: "#a0acff",
      dark: "#1432ff",
      contrastText: "#ffffff",
    },
    error: {
      light: Color.LIGHT_RED,
      main: Color.RED,
      dark: "#d03133",
      contrastText: Color.WHITE,
    },
    grey: {
      50: "#e8e8e8",
      100: "#c5c5c6",
      200: "#9e9ea0",
      300: "#77777a",
      400: "#59595e",
      500: "#3c3c41",
      600: "#36363b",
      700: "#2e2e32",
      800: "#27272a",
      900: "#1a1a1c",
      A100: "#636367",
      A200: "#89898c",
      A400: "#b0b0b2",
      A700: "#d7d7d8",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    divider: "#404854",
    background: {
      paper: "#ffffff",
      default: "#ffffff",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(0, 0, 0, 0.14)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)"
    },
  },
  typography,
  overrides: {
    MuiButton: {
      root: {
        fontSize: 14,
        height: 50,
      },
    },
    MuiInputBase: {
      root: {
        "& input": {
          height: "auto",
          lineHeight: "26px",
        },
      },
      input: {
        height: "auto",
        lineHeight: "26px",
      },
    },
    MuiInput: {
      root: {
        fontSize: 14,
        fontWeight: 300,
        "& input": {
          height: "auto",
          lineHeight: "26px",
        },
      },
      input: {
        height: "auto",
        lineHeight: "26px",
      },
      underline: {
        "&:before": {
          borderBottom: "1px solid rgba(255, 255, 255, 0.16)",
        },
        "&:focused:before": {
          borderBottom: "2px solid rgba(237, 243, 245, 0.5)",
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: "1px solid rgba(237, 243, 245, 0.5)",
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 14,
        fontWeight: 300,
      }
    },
    MuiMenuItem: {
      root: {
        fontWeight: 400,
        lineHeight: "26px",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(255, 255, 255, 0.54)",
      }
    },
    MuiStep: {
      horizontal: {
        "&:first-child": {
          paddingLeft: 0,
        },
        "&:last-child": {
          paddingRight: 0,
        },
      },
    },
    MuiStepLabel: {
      label: {
        color: Color.TEXT,
        "&$active, &$completed": {
          color: Color.TEXT,
        },
        "&$error": {
          color: Color.RED,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: Color.TEXT,
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: 24,
      },
    },
    MuiStepIcon: {
      root: {
        color: Color.GREY3,
        fontSize: 24,
        "&$active, &$completed": {
          color: Color.SIGNIFY_GREEN,
        },
        "&$error": {
          color: Color.RED,
        },
      },
      text: {
        fill: Color.TEXT,
        "&$active, &$completed": {
          fill: Color.SIGNIFY_GREEN,
        },
        "&$error": {
          fill: Color.RED,
        },
      },
    },
  },
};

export const baseTheme = createTheme(baseThemeOptions);

export const ThemeProvider = (props: {
  theme?: Theme,
  children?: React.ReactNode,
}) => {

  const { theme = baseTheme, children } = props;

  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  );
};
