import { createSelector } from "reselect";
import { createSelectors, Selector } from "@base";
import { Namespace, NamespaceAttributes, SummaryViewData } from "@data";
import { MODULE_ID, SCHEMA_KEY } from "@modules/namespaceDetails/constants";
import { NamespaceDetailsModuleSchema } from "@modules/namespaceDetails/reducers";
import { DEFAULT_STATE, NamespaceDetailsViewMode } from "@modules/namespaceDetails/reducers";

export const {
    viewMode: getViewMode,
    errorMessage: getErrorMessage,
    namespaceName: getNamespaceName,
    namespace: getNamespaceAttributes,
    showLoadingIndicator: isLoadingIndicatorVisible,
} = createSelectors<NamespaceDetailsModuleSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getNamespace: Selector<Namespace> = createSelector(
    getNamespaceAttributes, (attrs: NamespaceAttributes) =>
        new Namespace(attrs));

export const getJson: Selector<string> = createSelector(
    getNamespace, (namespace: Namespace) =>
        JSON.stringify(namespace.toJS(), null, "  "));

export const isSummaryViewSelected: Selector<boolean> = createSelector(
    getViewMode, (viewMode: NamespaceDetailsViewMode) =>
        NamespaceDetailsViewMode.SUMMARY === viewMode);

export const isJsonViewSelected: Selector<boolean> = createSelector(
    getViewMode, (viewMode: NamespaceDetailsViewMode) =>
        NamespaceDetailsViewMode.JSON === viewMode);

export const getCreatedAt: Selector<string> = createSelector(
    getNamespace, (namespace: Namespace) => namespace.getCreatedAt());

export const getCreatedBy: Selector<string> = createSelector(
    getNamespace, (namespace: Namespace) => namespace.getCreatedBy());

export const getLifecycle: Selector<string> = createSelector(
    getNamespace, (namespace: Namespace) => namespace.getLifecycle());

export const getType: Selector<string> = createSelector(
    getNamespace, (namespace: Namespace) => namespace.getType());

export const getSummaryViewItems: Selector<SummaryViewData[]> = createSelector(
    [
        getNamespaceName,
        getType,
        getLifecycle,
        getCreatedAt,
        getCreatedBy,
    ],
    (
        namespaceName: string,
        type: string,
        lifecycle: string,
        createdAt: string,
        createdBy: string,
    ) => ([
        new SummaryViewData({
            className: "name",
            name: "Name",
            value: namespaceName,
        }),
        new SummaryViewData({
            className: "type",
            name: "Type",
            value: type,
        }),
        new SummaryViewData({
            className: "lifecycle",
            name: "Lifecycle",
            value: lifecycle,
        }),
        new SummaryViewData({
            className: "createdAt",
            name: "Created At",
            value: createdAt,
        }),
        new SummaryViewData({
            className: "createdBy",
            name: "Created By",
            value: createdBy,
        }),
    ]));
