import { combineReducers } from "redux";
import { MODULE_ID } from "../constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base/createReducers";
import { FederationProviderRequest, FederationProviderRequestAttributes } from "@data";
import { FederationProviderWizardState, FederationProviderWizardStateAttributes } from "../models";

export enum FederationProviderWizardStep {
  CLIENT = "client",
  ENDPOINTS = "endpoints",
  ATTRIBUTES = "attributes",
  EDITOR = "editor",
  REVIEW = "review",
}

export interface FederationProviderWizardSchema extends BasePortalModuleSchema {
  json: string;
  federationProviderRequest: FederationProviderRequestAttributes;
  currentStep: FederationProviderWizardStep;
  showEditMode: boolean;
  providerId: string;
  defaultState: FederationProviderWizardStateAttributes;
}

export const DEFAULT_STATE: FederationProviderWizardSchema = createPortalModuleDefaultState({
  showEmptyView: false,
  json: JSON.stringify(FederationProviderRequest.EMPTY.toJS(), null, "  "),
  federationProviderRequest: { ...FederationProviderRequest.EMPTY.toJS() },
  currentStep: FederationProviderWizardStep.CLIENT,
  showEditMode: false,
  providerId: "",
  defaultState: { ...FederationProviderWizardState.EMPTY.toJS() },
});

type FederationProviderWizardActionTypes = PortalModuleActionTypes<FederationProviderWizardSchema> & {
  CREATE_FEDERATION_PROVIDER_REQUEST: string;
  CREATE_FEDERATION_PROVIDER_SUCCESS: string;
  CREATE_FEDERATION_PROVIDER_FAILED: string;
  FETCH_FEDERATION_PROVIDER_REQUEST: string;
  FETCH_FEDERATION_PROVIDER_SUCCESS: string;
  FETCH_FEDERATION_PROVIDER_FAILED: string;
  EDIT_FEDERATION_PROVIDER_REQUEST: string;
  EDIT_FEDERATION_PROVIDER_SUCCESS: string;
  EDIT_FEDERATION_PROVIDER_FAILED: string;
};

export const ACTION_TYPES: FederationProviderWizardActionTypes = {
  ...createPortalModuleActionTypes<FederationProviderWizardSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    CREATE_FEDERATION_PROVIDER_REQUEST: `${MODULE_ID}/create_federation_provider_request`,
    CREATE_FEDERATION_PROVIDER_SUCCESS: `${MODULE_ID}/create_federation_provider_success`,
    CREATE_FEDERATION_PROVIDER_FAILED: `${MODULE_ID}/create_federation_provider_failed`,
    FETCH_FEDERATION_PROVIDER_REQUEST: `${MODULE_ID}/fetch_federation_provider_request`,
    FETCH_FEDERATION_PROVIDER_SUCCESS: `${MODULE_ID}/fetch_federation_provider_success`,
    FETCH_FEDERATION_PROVIDER_FAILED: `${MODULE_ID}/fetch_federation_provider_failed`,
    EDIT_FEDERATION_PROVIDER_REQUEST: `${MODULE_ID}/edit_federation_provider_request`,
    EDIT_FEDERATION_PROVIDER_SUCCESS: `${MODULE_ID}/edit_federation_provider_success`,
    EDIT_FEDERATION_PROVIDER_FAILED: `${MODULE_ID}/edit_federation_provider_failed`,
  },
};

export const FederationProviderWizardReducer = combineReducers<FederationProviderWizardSchema>(
  createPortalModuleReducers<FederationProviderWizardSchema>(ACTION_TYPES, DEFAULT_STATE));

export default FederationProviderWizardReducer;
