import {
  makeApiRequest,
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
} from "@network/helpers";
import { isEmptyString } from "@util";

const USERIDM_UI_MANAGER_URL = process.env.REACT_APP_USERIDM_UI_MANAGER_LEGACY || "";

if (isEmptyString(USERIDM_UI_MANAGER_URL)) {
  throw new Error("Missing Environment Variable: REACT_APP_USERIDM_UI_MANAGER_LEGACY");
}

export interface GetUiPackageFilesLegacyResponse {
  package: {
    files: string[]
  };
}

export const uploadUIPackage = (authToken: string,
  file: File): Promise<any> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${USERIDM_UI_MANAGER_URL}/v1/aam/admin/users/ui-packages`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/octet-stream",
      },
      body: file,
    };

    const defaultErrorMessage = "Failed to upload UI Package";

    return makeApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteUiPackage = (authToken: string): Promise<void> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${USERIDM_UI_MANAGER_URL}/v1/aam/admin/users/ui-packages`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
      },
    };

    const defaultErrorMessage = "Failed to delete UI Package";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

// Added as a workaround for IOTSEC-4253; ali-cloud only has legacy support for listing files
export const listUiPackageFiles = (authToken: string): Promise<GetUiPackageFilesLegacyResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${USERIDM_UI_MANAGER_URL}/v1/aam/admin/users/ui-packages/files`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
      },
    };

    const defaultErrorMessage = "Failed to get UI Package files";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
