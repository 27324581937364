import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
      textAlign: "center",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    name: {
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "16px",
      marginTop: 16,
      marginBottom: 16,
    },
    dropdown: {
      "& div.dropdownMenu": {
        margin: "0px 0px 0px 20px",
      }
    },
    statusContainer: {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "center",
    },
  });

export default styles;
