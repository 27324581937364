import createStyles from "@material-ui/core/styles/createStyles";
import ModuleIcon from "@material-ui/icons/Assignment";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const summaryView = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      "& label.name": {
        minWidth: 240,
      },
    },
    content: {
      marginTop: 32,
      color: primaryColor,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
  });

export const dataAccessRequestView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    subtitle: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: "0 0 25px 0",
    },
    summaryView: {
    },
  });

export const dataSetRequestView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    subtitle: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: "0 0 25px 0",
    },
  });

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      "& .content": {
        "& > div": {
          // The details view component provides us with consistent margin-top for each content view
          marginTop: 0,
        },
      },
    },
    viewModeButton: {
      margin: "0 0 0 15px",
      "&:first-child": {
        marginLeft: 0,
      },
    },
    downloadButton: {
      color: Color.TEXT,
      backgroundColor: Color.WHITE,
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.54)",
      },
    },
  });

export default styles;
