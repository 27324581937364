import React from "react";
import classnames from "classnames";
import { clickHandler, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Button, ErrorView, SaveButton, usePortalSnackbar } from "@components";
import { editIoTApprovedRequest as styles } from "./styles";

export interface Model {
  errorMessage?: string;
  successMessage?: string;
  snackbarId?: string;
  showLoadingIndicator?: boolean;
  showSuccessIndicator?: boolean;
  saveButtonLabel?: string;
  saveButtonDisabled?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  cancel?: () => void;
  save?: () => void;
  onSuccess?: (dataSetRequestId: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const EditIoTApprovedRequest = withStyles(styles)((props: Props) => {

  const {
    classes,
    errorMessage,
    successMessage,
    snackbarId = "edit-data-set-request",
    showLoadingIndicator,
    showSuccessIndicator,
    saveButtonLabel = "Save",
    saveButtonDisabled,
    cancel,
    save,
    children,
    onSuccess = noop,
  } = props;

  const showCancelButton = !showLoadingIndicator && !showSuccessIndicator;

  usePortalSnackbar(snackbarId, {
    errorMessage,
    successMessage,
    onSuccessMessageShown: onSuccess,
    successCallbackDelay: 250
  });

  return (
    <div className={classnames("editPendingRequest", classes.container)}>
      <ErrorView className={classes.error} title="Edit Data Set Request Failed" message={errorMessage} />
      {children}
      <div className={classnames("controls", classes.controls)}>
        {showCancelButton && (
          <Button
            className={classnames("cancelButton", classes.cancelButton)}
            classes={{
              label: classnames("label", classes.cancelButtonLabel),
            }}
            variant="text"
            onClick={clickHandler(cancel)}
          >
            Cancel
          </Button>
        )}
        <SaveButton
          className={classnames("saveButton", classes.saveButton)}
          disabled={saveButtonDisabled}
          label={saveButtonLabel}
          loading={showLoadingIndicator}
          success={showSuccessIndicator}
          save={save}
        />
      </div>
    </div>
  );
});

export default EditIoTApprovedRequest;
