import React from "react";
import { Module } from "@data";
import { MODULE_ID } from "./constants";
import { isDialogOpen } from "./selectors";
import { initialize, open } from "./actions";
import { PrimaryIcon } from "../users/components/styles";
import PublishDashboardDialog from "./containers/PublishDashboardDialog";

export const publishDashboardModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "Publish Dashboard",
  title: "Publish Dashboard",
  icon: PrimaryIcon,
  view: <PublishDashboardDialog />,
  onClick: open,
  isDialogOpen,
  initialize,
};

export default publishDashboardModule;
