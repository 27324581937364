import React from "react";
import { useParams } from "react-router-dom";
import DataApprovalRequestDetails from "./containers/DataApprovalRequestDetails";

export function DataApprovalRequestDetailsView() {

  const { id: id } = useParams<{ id: string }>();

  return (
    <DataApprovalRequestDetails
      key={id}
    />
  );
}

export default DataApprovalRequestDetailsView;
