import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./styles";
import view from "./SecurityServicesView";
import Module from "@data/Module";

export const securityServicesRegionalModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Services",
  title: "Service Management",
  className: "securityServicesRegionalModule",
  primaryColor,
  icon,
  view,
  backButton: null,
});

export default securityServicesRegionalModule;
