import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base";

export interface DataApprovalRequestsSchema extends BasePortalModuleSchema {
  nameFilter: string;
  filterByCurrentAccount: boolean;
  filterByPendingApproval: boolean;
  reloadItemsRequested: boolean;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<DataApprovalRequestsSchema>({
  showEmptyView: false,
  nameFilter: "",
  filterByCurrentAccount: false,
  filterByPendingApproval: true,
  reloadItemsRequested: false,
});

type DataApprovalRequestsActionTypes = PortalModuleActionTypes<DataApprovalRequestsSchema>;

export const ACTION_TYPES: DataApprovalRequestsActionTypes =
  createPortalModuleActionTypes<DataApprovalRequestsSchema>(MODULE_ID, DEFAULT_STATE);

export const DataApprovalRequestsReducer = combineReducers<DataApprovalRequestsSchema>(
  createPortalModuleReducers<DataApprovalRequestsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DataApprovalRequestsReducer;
