import AppSchema from "@schemas";
import { IdentityType } from "@data";
import LocalStorageClient from "@util/LocalStorageClient";
import {
  KEY_ACCESS_TOKEN_EXPIRY_TIME,
  KEY_ACCOUNT_ID,
  KEY_FAVORITES,
  KEY_IDENTITY_TYPE,
  KEY_LAST_MOUSE_MOVE,
  KEY_PRINCIPAL_ID,
  KEY_VERSION_CODE,
  KEY_CLOSED_MAINTENANCE_REMINDERS,
} from "./constants";

const getIdentityType = (): IdentityType => {
  const identityType = LocalStorageClient.getItem(KEY_IDENTITY_TYPE);
  if (identityType === IdentityType.SERVICE) {
    return IdentityType.SERVICE;
  } else if (identityType === IdentityType.USER) {
    return IdentityType.USER;
  } else {
    return IdentityType.NONE;
  }
};

export const restoreFromLocalStorage = (): Partial<AppSchema> => ({
  versionCode: LocalStorageClient.getItem(KEY_VERSION_CODE),
  lastMouseMove: Number(LocalStorageClient.getItem(KEY_LAST_MOUSE_MOVE)),
  accountId: LocalStorageClient.getItem(KEY_ACCOUNT_ID),
  principalId: LocalStorageClient.getItem(KEY_PRINCIPAL_ID),
  identityType: getIdentityType(),
  authTokenExpiryTime: LocalStorageClient.getItem(KEY_ACCESS_TOKEN_EXPIRY_TIME),
  favorites: LocalStorageClient.getItem(KEY_FAVORITES),
  closedMaintenanceReminders: LocalStorageClient.getItem(KEY_CLOSED_MAINTENANCE_REMINDERS),
});

export default restoreFromLocalStorage;
