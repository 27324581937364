import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () => createStyles({
  container: {
    color: Color.TEXT,
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 400,
    fontStyle: "normal",
    width: "auto",
    height: "auto",
    minHeight: 50,
    padding: 8,
    wordBreak: "break-word",
  },
  placeholder: {
    color: Color.GREY7,
    cursor: "inherit",
    fontSize: 16,
    fontStyle: "italic",
    padding: 10,
  },
});

export default styles;
