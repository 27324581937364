import React from "react";
import {
  UseApiRequestModel,
  UseApiRequestProps,
  useApiRequest,
  useSecurityGroupsClient,
} from "@hooks";

type SuccessResponse = void;

export interface UseDeleteSecurityGroupProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  securityGroupName?: string;
}

export interface UseDeleteSecurityGroupModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseDeleteSecurityGroupActions {
  deleteSecurityGroup: () => void;
}

type Props = UseDeleteSecurityGroupProps;
type Model = UseDeleteSecurityGroupModel;
type Actions = UseDeleteSecurityGroupActions;
type Result = [Model, Actions];

export const useDeleteSecurityGroup = (props: Props = {}): Result => {

  const {
    securityGroupName = "",
    defaultErrorMessage = "Failed to delete security group",
    ...otherProps
  } = props;

  const SecurityGroupsClient = useSecurityGroupsClient();

  const makeApiRequest = React.useCallback(() =>
      SecurityGroupsClient.deleteSecurityGroup(securityGroupName),
    [SecurityGroupsClient, securityGroupName]);

  const [{showSuccessView, ...baseModel}, {refresh: deleteSecurityGroup}] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
      deferRequest: true,
    });

  const successMessage = React.useMemo(() => showSuccessView ?
    "Delete Successful" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    deleteSecurityGroup,
  }), [
    deleteSecurityGroup,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDeleteSecurityGroup;
