import React from "react";
import { useWorkloadManagerClient } from "@hooks";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps } from "@hooks";

type SuccessResponse = void;

export interface UseCopyWorkloadCodeProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  sourceName: string;
  sourceVersion: number;
}

export interface UseCopyWorkloadCodeModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseCopyWorkloadCodeActions {
  copyCode: () => void;
  reset: () => void;
}

type Props = UseCopyWorkloadCodeProps;
type Model = UseCopyWorkloadCodeModel;
type Actions = UseCopyWorkloadCodeActions;
type Result = [Model, Actions];

export const useCopyWorkloadCode = (props: Props): Result => {

  const {
    name,
    version,
    sourceName,
    sourceVersion,
    defaultErrorMessage = "Failed to copy workload code",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.copyWorkloadCodeApi(name, version, sourceName, sourceVersion),
    [WorkloadManagerClient, name, version, sourceName, sourceVersion]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: copyCode, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Clone Successful" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    copyCode,
    reset
  }), [
    copyCode,
    reset
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useCopyWorkloadCode;
