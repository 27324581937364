import React from "react";
import classnames from "classnames";
import MuiAccordion, { AccordionProps } from "@material-ui/core/Accordion";

export interface CustomAccordionProps extends AccordionProps {}

export const Accordion = ((props: CustomAccordionProps) => {

    const {
      className,
      children,
      ...otherProps
    } = props;

    return (
      <MuiAccordion
        {...otherProps}
        elevation={0}
        className={classnames("accordion", className)}
      >
        {children}
      </MuiAccordion>
    );
});

export default Accordion;
