import React from "react";
import styles from "./styles";
import Fab from "@components/fab";
import classnames from "classnames";
import SaveIcon from "@material-ui/icons/Save";
import CheckmarkIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/PriorityHigh";
import CircularProgress from "@components/circular-progress";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { PolicyBulkRequest, PolicyStatus } from "@data/PolicyBulkRequest";
import { ManagedPolicyBulkRequest } from "@data/ManagedPolicyBulkRequest";

export enum PolicyReviewViewColumns {
  NONE = "",
  NAME = "Policy Name",
  STATUS = "Status",
  ERRORS = "Errors",
}

export const ReviewViewListItem = withStyles(styles)((props: WithStyles<typeof styles> & {
  policyBulkRequest?: PolicyBulkRequest | ManagedPolicyBulkRequest;
  column?: PolicyReviewViewColumns;
  isManagedPolicy?: boolean;
}) => {

  const { column, isManagedPolicy, policyBulkRequest =
    (!isManagedPolicy ? PolicyBulkRequest : ManagedPolicyBulkRequest).EMPTY, classes } = props;

  if (!column) {
    return null;
  }

  const status = policyBulkRequest.getPolicyStatus();
  const success = status === PolicyStatus.SUCCESS;
  const loading = status === PolicyStatus.PROCESSING;
  const error = status === PolicyStatus.FAILED;

  switch (column) {
    case PolicyReviewViewColumns.NAME:
      return (
        <label
          className={classnames("policy", classes.label, {
            [classes.failedLabel]: error,
          })}
        >
          {policyBulkRequest.getPolicyName()}
        </label>
      );
    case PolicyReviewViewColumns.STATUS:
      return (
        <Fab
          color="primary"
          className={classnames("fab", classes.fab, {
            [classes.success]: success,
            [classes.failed]: error,
          })}
        >
          {success && !error && <CheckmarkIcon className={classnames(classes.icons, classes.successIcon)} />}
          {!success && error && <ErrorIcon className={classnames(classes.icons, classes.errorIcon)} />}
          {!success && !error && <SaveIcon className={classes.icons} />}
          {loading && <CircularProgress size={32} className={classes.progressIndicator} />}
        </Fab>
      );
    case PolicyReviewViewColumns.ERRORS:
      return (
        <label
          className={classnames("policyErrors", classes.label, {
            [classes.failedLabel]: error,
          })}
        >
          {policyBulkRequest.getErrorMessage()}
        </label>
      );
    default:
      return null;
  }
});

export default ReviewViewListItem;
