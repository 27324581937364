import React from "react";
import classnames from "classnames";
import { isEmptyString, scrollToTop } from "@util";
import { Prompt, useHistory } from "react-router-dom";
import { RestClientError } from "@network/RestClientError";
import { BackdropLoadingView } from "@components/backdrop-loading-view";
import Wizard, { WizardActions, WizardModel } from "@components/wizard";
import { usePortalSnackbar } from "@components/portal-snackbar-provider";
import { ComparableItem } from "@components/paginated-list";
import { UseApiRequestActions, UseApiRequestModel } from "@hooks";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface SubmitApiRequestViewModel<
  Data extends ComparableItem<Data>,
  Step = null,
  SuccessResponse = any,
  ErrorResponse extends RestClientError = RestClientError
  > extends Partial<UseApiRequestModel<SuccessResponse, ErrorResponse>>, WizardModel<Step> {

  className?: string;
  snackbarId?: string;
  fullHeight?: boolean;
  currentState?: Data;
  defaultState?: Data;
  successMessage?: string;
  successCallbackDelay?: number;
  unsavedChangesGuardEnabled?: boolean;
  unsavedChangesGuardMessage?: string;
  showUnsavedChangesGuard?: boolean;
  showBackdrop?: boolean;
}

export interface SubmitApiRequestViewActions<
  Data extends ComparableItem<Data>,
  Step = null,
  SuccessResponse = any,
  ErrorResponse extends RestClientError = RestClientError
  > extends Partial<UseApiRequestActions<SuccessResponse, ErrorResponse>>, WizardActions<Step> {

  cancel?: () => void;
  save?: () => void;
  onSuccess?: (item?: Data) => void;
}

type Model<Data extends ComparableItem<Data>, Step, SuccessResponse, ErrorResponse extends RestClientError> =
  SubmitApiRequestViewModel<Data, Step, SuccessResponse, ErrorResponse>;
type Actions<Data extends ComparableItem<Data>, Step, SuccessResponse, ErrorResponse extends RestClientError> =
  SubmitApiRequestViewActions<Data, Step, SuccessResponse, ErrorResponse>;
type Props<Data extends ComparableItem<Data>, Step, SuccessResponse, ErrorResponse extends RestClientError> =
  WithStyles<typeof styles> &
  Model<Data, Step, SuccessResponse, ErrorResponse> &
  Actions<Data, Step, SuccessResponse, ErrorResponse> & {

  children?: React.ReactNode;
};

export const SubmitApiRequestView = withStyles(styles)(
  <Data extends ComparableItem<Data>, Step, SuccessResponse, ErrorResponse extends RestClientError>
  (props: Props<Data, Step, SuccessResponse, ErrorResponse>) => {

    const history = useHistory();

    const {
      classes,
      className,
      snackbarId = className || `submit-api-request-view-${(+new Date() + "").substring(0, 4)}`,
      fullHeight,
      currentState,
      defaultState,
      errorMessage,
      errors,
      successMessage,
      showSuccessView = !isEmptyString(successMessage),
      successCallbackDelay = 250,
      unsavedChangesGuardEnabled: guardAgainstUnsavedChanges = true,
      unsavedChangesGuardMessage = "Are you sure you want to leave and discard these changes?",
      // Show unsaved changes message if user presses back button after making updates in the wizard
      showUnsavedChangesGuard = React.useMemo(() =>
        currentState != null && defaultState != null && !currentState.equals(defaultState),
        [currentState, defaultState]),
      showLoadingIndicator = false,
      showBackdrop = showLoadingIndicator,
      cancel = React.useCallback(() => {
        history.goBack();
      }, [history]),
      onSuccess = cancel,
      children,
      ...otherProps
    } = props;

    const [unsavedChangesGuardEnabled, setUnsavedChangesGuardEnabled] =
      React.useState<boolean>(guardAgainstUnsavedChanges);

    const onClickCancel = React.useCallback(() => {
      setUnsavedChangesGuardEnabled(false);
      if (typeof cancel === "function") {
        cancel();
      }
    }, [setUnsavedChangesGuardEnabled, cancel]);

    const onSuccessMessageShown = React.useCallback(() => {
      setUnsavedChangesGuardEnabled(false);
      onSuccess(currentState);
    }, [setUnsavedChangesGuardEnabled, onSuccess, currentState]);

    usePortalSnackbar(snackbarId, {
      errorMessage,
      successMessage,
      onSuccessMessageShown,
      successCallbackDelay,
    });

    // When the user hits the save button, make sure we scroll back to the top
    React.useLayoutEffect(() => scrollToTop(), [showBackdrop, showLoadingIndicator]);

    return (
      <React.Fragment>
        <Wizard
          {...otherProps}
          className={classnames("submitApiRequestView", className, classes.wizard, {
            [classes.fullHeight]: fullHeight,
          })}
          errorMessage={errorMessage}
          errors={errors}
          showLoadingIndicator={showLoadingIndicator}
          showSuccessIndicator={showSuccessView}
          cancel={onClickCancel}
        >
          {children}
        </Wizard>
        <BackdropLoadingView
          className={classnames("backdrop", classes.backdrop)}
          open={showBackdrop}
        />
        {guardAgainstUnsavedChanges && (
          <Prompt
            when={unsavedChangesGuardEnabled && showUnsavedChangesGuard}
            message={unsavedChangesGuardMessage}
          />
        )}
      </React.Fragment>
    );
  });

export default SubmitApiRequestView;
