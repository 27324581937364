import React from "react";
import { DeviceConfigurationAttributes, DeviceConfiguration } from "@data";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps } from "@hooks";
import useDeviceRegistryClient from "../use-device-registry-client";
import { DeviceConfigurationDataMode } from "@modules/deviceDetails/reducers";

export interface UseDeviceConfigDetailsProps extends Partial<UseApiRequestProps<DeviceConfigurationAttributes>> {
  deviceId: string;
}

export interface UseDeviceConfigDetailsModel extends UseApiRequestModel<DeviceConfigurationAttributes> {
  deviceConfig: DeviceConfiguration;
}

export interface UseDeviceConfigDetailsActions {
  refresh: () => void;
}

type Props = UseDeviceConfigDetailsProps;
type Model = UseDeviceConfigDetailsModel;
type Actions = UseDeviceConfigDetailsActions;
type Result = [Model, Actions];

export const useDeviceConfigDetails = (props: Props): Result => {

  const {
    deviceId,
    defaultErrorMessage = "Failed to get device configuration details",
    ...otherProps
  } = props;

  const DeviceRegistryClient = useDeviceRegistryClient();

  const [{ successResponse, ...baseModel }, { refresh }] =
    useApiRequest<DeviceConfigurationAttributes>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => DeviceRegistryClient.getDeviceConfiguration(deviceId, DeviceConfigurationDataMode.ALL)
    });

  const deviceConfig = React.useMemo(() => {
    const attrs = successResponse || {};
    return new DeviceConfiguration(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    deviceConfig
  }), [
    baseModel,
    deviceConfig,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    refresh,
  }), [
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDeviceConfigDetails;
