import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { CancelButton } from "@components/cancel-button";
import { ContinueButton } from "@components/continue-button";
import { SaveButton } from "@components/save-button";
import { wizardControls as styles } from "./styles";

export interface WizardControlsModel {
  className?: string;

  showLoadingIndicator?: boolean;
  showSuccessIndicator?: boolean;

  firstStepSelected?: boolean;
  lastStepSelected?: boolean;

  cancelButtonLabel?: string;
  cancelButtonDisabled?: boolean;
  showCancelButton?: boolean;

  previousStepButtonLabel?: string;
  previousStepButtonDisabled?: boolean;
  showPreviousStepButton?: boolean;

  nextStepButtonLabel?: string;
  nextStepButtonDisabled?: boolean;
  showNextStepButton?: boolean;

  saveButtonLabel?: string;
  saveButtonDisabled?: boolean;
  showSaveButton?: boolean;
}

export interface WizardControlsActions {
  cancel?: () => void;
  previousStep?: () => void;
  nextStep?: () => void;
  save?: () => void;
}

type Props = WithStyles<typeof styles> & WizardControlsModel & WizardControlsActions & {
  children?: React.ReactNode;
};

export const WizardControls = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    showLoadingIndicator,
    showSuccessIndicator,
    firstStepSelected,
    lastStepSelected,
    cancelButtonLabel = "Cancel",
    cancelButtonDisabled,
    previousStepButtonLabel = "Previous Step",
    previousStepButtonDisabled,
    nextStepButtonLabel = "Next Step",
    nextStepButtonDisabled,
    saveButtonLabel = "Save",
    saveButtonDisabled,
    cancel,
    previousStep,
    nextStep,
    save = noop,
    children,
    ...otherProps
  } = props;

  const isLoadingOrFinished = React.useMemo(() =>
    showLoadingIndicator || showSuccessIndicator,
    [showLoadingIndicator, showSuccessIndicator]);

  const {
    showCancelButton = !isLoadingOrFinished,
    showPreviousStepButton = !firstStepSelected,
    showNextStepButton = !lastStepSelected,
    showSaveButton = lastStepSelected,
  } = otherProps;

  const previousStepButtonVisible = React.useMemo(() =>
    showPreviousStepButton && !isLoadingOrFinished,
    [showPreviousStepButton, isLoadingOrFinished]);

  const nextStepButtonVisible = React.useMemo(() =>
    showNextStepButton && !isLoadingOrFinished,
    [showNextStepButton, isLoadingOrFinished]);

  const cancelButton = React.useMemo(() => !showCancelButton ? null : (
    <CancelButton
      className={classnames("cancelButton", classes.button, classes.cancelButton)}
      label={cancelButtonLabel}
      disabled={cancelButtonDisabled}
      onClick={cancel}
    />
  ), [
    classes,
    showCancelButton,
    cancelButtonLabel,
    cancelButtonDisabled,
    cancel,
  ]);

  const previousStepButton = React.useMemo(() => !previousStepButtonVisible ? null : (
    <ContinueButton
      className={classnames("previousStepButton", classes.button, classes.previousStepButton)}
      label={previousStepButtonLabel}
      disabled={previousStepButtonDisabled}
      onClick={previousStep}
      primary={false}
    />
  ), [
    classes,
    previousStepButtonVisible,
    previousStepButtonLabel,
    previousStepButtonDisabled,
    previousStep,
  ]);

  const nextStepButton = React.useMemo(() => !nextStepButtonVisible ? null : (
    <ContinueButton
      className={classnames("nextStepButton", classes.button, classes.nextStepButton)}
      label={nextStepButtonLabel}
      disabled={nextStepButtonDisabled}
      onClick={nextStep}
    />
  ), [
    classes,
    nextStepButtonVisible,
    nextStepButtonLabel,
    nextStepButtonDisabled,
    nextStep,
  ]);

  const saveButton = React.useMemo(() => !showSaveButton ? null : (
    <SaveButton
      className={classnames("saveButton", classes.saveButton)}
      buttonClassName="wizardSaveButton"
      label={saveButtonLabel}
      disabled={saveButtonDisabled}
      loading={showLoadingIndicator}
      success={showSuccessIndicator}
      save={save}
    />
  ), [
    classes,
    showSaveButton,
    saveButtonLabel,
    saveButtonDisabled,
    showLoadingIndicator,
    showSuccessIndicator,
    save,
  ]);

  return (
    <div className={classnames("wizardControls", className, classes.container)}>
      {cancelButton}
      {previousStepButton}
      {nextStepButton}
      {saveButton}
      {children}
    </div>
  );
});

export default WizardControls;
