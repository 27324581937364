import { Record } from "immutable";

export enum RegionalEmailTemplateMessageTypeStatus {
  NONE = "",
  DEPRECATED = "DEPRECATED",
}

export interface RegionalEmailTemplateMessageTypeAttrs {
  description: string;
  requiredParameters: string[];
  status?: RegionalEmailTemplateMessageTypeStatus;
}

export class RegionalEmailTemplateMessageType extends Record({
  description: "",
  requiredParameters: [],
  status: RegionalEmailTemplateMessageTypeStatus.NONE,
}) implements  RegionalEmailTemplateMessageTypeAttrs {

  public static EMPTY: RegionalEmailTemplateMessageType = new RegionalEmailTemplateMessageType();

  public readonly description: string;
  public readonly requiredParameters: string[];
  public readonly status: RegionalEmailTemplateMessageTypeStatus;

}

export default RegionalEmailTemplateMessageType;
