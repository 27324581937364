import React from "react";
import { AuditEvent } from "@data";
import { compare, isEmptyString } from "@util";
import AuditEventFilter from "@components/audit-events/AuditEventFilter";
import { AccountIdContext } from "@components";

export interface AvailableValues {
  availableTypes?: string[];
  availableSeverities?: string[];
  availableAccountIds?: string[];
  availablePrincipals?: string[];
  availableOrigins?: string[];
  availableRequestIds?: string[];
}

type Result = AvailableValues;

const getAuditEventFilterValue = (auditEvent: AuditEvent = AuditEvent.EMPTY,
                                  type: AuditEventFilter = AuditEventFilter.NONE): string => {

  switch (type) {
    case AuditEventFilter.TYPE:
      return auditEvent.getType();
    case AuditEventFilter.SEVERITY:
      return auditEvent.getSeverity();
    case AuditEventFilter.ACCOUNT_ID:
      return auditEvent.getAccountId();
    case AuditEventFilter.PRINCIPAL:
      return auditEvent.getPrincipal();
    case AuditEventFilter.ORIGIN:
      return auditEvent.getOriginator();
    case AuditEventFilter.REQUEST_ID:
      return auditEvent.getRequestId();
    default:
      return "";
  }
};

const compareAscending = (left: string, right: string) =>
  compare(left, right, true);

export const useAvailableItems = (auditEvents: AuditEvent[] = []): Result => {

  const currentAccountId = React.useContext(AccountIdContext);

  const mapItemsToValue = React.useCallback((type: AuditEventFilter) => {

    const values = Array.from(new Set(auditEvents
      .map(auditEvent => getAuditEventFilterValue(auditEvent, type))
      .filter(value => !isEmptyString(value))));

    values.sort(compareAscending);

    return values;

  }, [auditEvents]);

  const availableTypes = React.useMemo(() =>
    mapItemsToValue(AuditEventFilter.TYPE), [mapItemsToValue]);

  const availableSeverities = React.useMemo(() =>
    mapItemsToValue(AuditEventFilter.SEVERITY), [mapItemsToValue]);

  const availableAccountIds = React.useMemo(() => {

    const accountIds = Array.from(new Set(
      [currentAccountId].concat(mapItemsToValue(AuditEventFilter.ACCOUNT_ID))));

    accountIds.sort(compareAscending);

    return accountIds;

  } , [mapItemsToValue]);

  const availablePrincipals = React.useMemo(() =>
    mapItemsToValue(AuditEventFilter.PRINCIPAL), [mapItemsToValue]);

  const availableOrigins = React.useMemo(() =>
    mapItemsToValue(AuditEventFilter.ORIGIN), [mapItemsToValue]);

  const availableRequestIds = React.useMemo(() =>
    mapItemsToValue(AuditEventFilter.REQUEST_ID), [mapItemsToValue]);

  return React.useMemo(() => ({
    availableTypes,
    availableSeverities,
    availableAccountIds,
    availablePrincipals,
    availableOrigins,
    availableRequestIds,
  }), [
    availableTypes,
    availableSeverities,
    availableAccountIds,
    availablePrincipals,
    availableOrigins,
    availableRequestIds,
  ]);
};

export default useAvailableItems;
