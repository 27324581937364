import React from "react";
import { DataSetType } from "@data";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { getStringValue } from "@util";
import { useDataSets } from "@hooks";
import { DataSetsListItemData } from "@components/data-sets-list";
import { getPathToDataSet } from "@modules/dataSetDetails/helpers";
import DataSets, {
  DataSetsActions as Actions,
  DataSetsModel as Model,
} from "@components/data-sets";
import {
  setAccountFilter,
  setAliasContainsFilter,
  setCurrentAccountFilterEnabled,
  setDataSetTypeFilter,
  setGroupFilter,
} from "../actions";
import {
  getAccountFilter,
  getActionMenuItems,
  getAliasContainsFilter,
  getCurrentAccountId,
  getDataSetTypeFilter,
  getGroupFilter,
  isCurrentAccountFilterEnabled,
} from "../selectors";

export interface ContainerModel extends Model {
  currentAccountId?: string;
}

export interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const DataSetsContainer = (props: Props) => {

  const {
    currentAccountId,
    accountFilter,
    groupFilter,
    dataSetTypeFilter,
    nameFilter,
    currentAccountFilterEnabled,
    createDataSetRequest,
    ...otherProps
  } = props;

  const accountId = React.useMemo(() =>
      getStringValue(currentAccountFilterEnabled ? currentAccountId : accountFilter),
    [currentAccountFilterEnabled, currentAccountId, accountFilter]);

  const [{ dataSets, ...model }, actions] = useDataSets({
    accountId,
    dataSetGroup: groupFilter,
    dataSetType: dataSetTypeFilter,
    dataSetAliasContains: nameFilter,
  });

  const items = React.useMemo(() =>
    dataSets.map(dataSet => new DataSetsListItemData({
      id: dataSet.getDataSetId(),
      name: dataSet.getAlias(),
      description: dataSet.getDescription(),
      account: dataSet.getAccountId(),
      dataSetType: dataSet.getDataSetType(),
      tags: dataSet.getTags(),
      containsPII: dataSet.containsPii(),
      href: getPathToDataSet(dataSet.getDataSetId()),
      dataSetGroup: dataSet.getDataSetGroup(),
    })), [dataSets]);

  return (
    <DataSets
      {...model}
      {...actions}
      {...otherProps}
      items={items}
      accountFilter={accountFilter}
      groupFilter={groupFilter}
      dataSetTypeFilter={dataSetTypeFilter}
      nameFilter={nameFilter}
      currentAccountFilterEnabled={currentAccountFilterEnabled}
      showCreateButton={true}
      onClickCreateButton={createDataSetRequest}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  currentAccountId: getCurrentAccountId(state),
  nameFilter: getAliasContainsFilter(state),
  dataSetTypeFilter: getDataSetTypeFilter(state),
  currentAccountFilterEnabled: isCurrentAccountFilterEnabled(state),
  accountFilter: getAccountFilter(state),
  groupFilter: getGroupFilter(state),
  actions: getActionMenuItems(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setAccountFilter: (value: string) => dispatch(setAccountFilter(value)),
  setGroupFilter: (value: string) => dispatch(setGroupFilter(value)),
  setNameFilter: (value: string) => dispatch(setAliasContainsFilter(value)),
  setDataSetTypeFilter: (type: DataSetType) => dispatch(setDataSetTypeFilter(type)),
  setCurrentAccountFilterEnabled: (enabled: boolean) => dispatch(setCurrentAccountFilterEnabled(enabled)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DataSetsContainer);
