import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { getDataSetRequest } from "../selectors";
import DataSetRequest from "@data/DataSetRequest";
import DataSetRequestSummaryView from "@components/data-set-request-summary-view";
import DataSetRequestView, { Actions, Model } from "../components/DataSetRequestView";

interface ContainerModel extends Model {
  dataSetRequest?: DataSetRequest;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const DataSetRequestViewContainer = (props: Props) => {

  const {
    dataSetRequest = DataSetRequest.EMPTY,
    ...otherProps
  } = props;

  const dataOwners = React.useMemo(() =>
      dataSetRequest.getDataOwners().map(user => user.split(":").pop()).join(", "),
    [dataSetRequest]);

  const architects = React.useMemo(() =>
      dataSetRequest.getArchitects().map(user => user.split(":").pop()).join(", "),
    [dataSetRequest]);

  const engineeringContacts = React.useMemo(() =>
      dataSetRequest.getEngineeringContacts().map(user => user.split(":").pop()).join(", "),
    [dataSetRequest]);

  const businessOwners = React.useMemo(() =>
      dataSetRequest.getBusinessOwners().map(user => user.split(":").pop()).join(", "),
    [dataSetRequest]);

  const tags = React.useMemo(() =>
      dataSetRequest.getTags().join(", "),
    [dataSetRequest]);

  const termsConditionsId = React.useMemo(() =>
      dataSetRequest.getTermsConditionsId(),
    [dataSetRequest]);

  return (
    <DataSetRequestView {...otherProps}>
      <DataSetRequestSummaryView
        className="dataSetRequestInfo"
        showDataSetAlias={false}
        dataSetAlias={dataSetRequest.getDataSetAlias()}
        description={dataSetRequest.getDescription()}
        dataSetGroup={dataSetRequest.getDataSetGroup()}
        dataSetType={dataSetRequest.getDataSetType()}
        status={dataSetRequest.getStatus()}
        dataOwners={dataOwners}
        architects={architects}
        engineeringContacts={engineeringContacts}
        businessOwners={businessOwners}
        tags={tags}
        ingestionMechanism={dataSetRequest.getIngestionMechanism()}
        schema={dataSetRequest.getSchema()}
        sampleAnonymizedData={dataSetRequest.getSampleAnonymizedData()}
        dataClassification={dataSetRequest.getDataClassification()}
        dataOwnerApprovalStatus={dataSetRequest.getDataOwnerApprovalStatus()}
        businessOwnerApprovalStatus={dataSetRequest.getBusinessOwnerApprovalStatus()}
        dataVolume={dataSetRequest.getDataVolume()}
        dataVelocity={dataSetRequest.getDataVelocity()}
        archivingInDays={dataSetRequest.getArchivingInDays()}
        expirationInDays={dataSetRequest.getExpirationInDays()}
        confirmNonProductionData={dataSetRequest.isConfirmedNonProductionData()}
        termsConditionsId={termsConditionsId}
        createdAt={dataSetRequest.getDataSetRequestedAtTime()}
        createdBy={dataSetRequest.getDataSetRequestedBy()}
        containsPiiData={dataSetRequest.getContainsPII()}
        piiComment={dataSetRequest.getPiiComment()}
      />
    </DataSetRequestView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  dataSetRequest: getDataSetRequest(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DataSetRequestViewContainer);
