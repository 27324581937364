import React from "react";
import { noop } from "@util";
import { Namespace } from "@data";
import classnames from "classnames";
import { PrimaryIcon } from "@modules/namespaces/components/styles";
import { getPathToNamespace } from "@modules/namespaceDetails/helpers";
import { ActionMenuItem, NamespaceAction } from "@components/namespace-actions-menu";
import ModuleListView, {
  createColumns,
  ModuleListViewActions,
  ModuleListViewModel,
} from "@components/module-list-view";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import NamespacesListColumn from "./NamespacesListColumn";
import styles from "./styles";

type Item = Namespace;
type Column = NamespacesListColumn;

export const DEFAULT_NAMESPACES_LIST_COLUMNS: Column[] = [
  NamespacesListColumn.NAME,
  NamespacesListColumn.LIFECYCLE,
  NamespacesListColumn.TYPE,
  NamespacesListColumn.CREATED_AT,
  NamespacesListColumn.CREATED_BY,
];

export interface NamespacesListModel extends ModuleListViewModel<Item, Column> {
  selectedNamespace?: Namespace;
}

export interface NamespacesListActions extends ModuleListViewActions<Item, Column> {
  setSelectedNamespace?: (namespace: Namespace) => void;
  showNamespaceDetails?: (namespace: Namespace) => void;
  publishNamespace?: (namespace: Namespace) => void;
  deprecateNamespace?: (namespace: Namespace) => void;
}

type Model = NamespacesListModel;
type Actions = NamespacesListActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const NamespacesList = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    listClassName,
    showSearch = true,
    tableLayoutFixed = false,
    summaryViewIcon = PrimaryIcon,
    selectedNamespace = Namespace.EMPTY,
    columns = DEFAULT_NAMESPACES_LIST_COLUMNS,
    actions = [],
    items = [],
    listViewItems = React.useMemo(() =>
      items.map(namespace => {
        return {
          item: namespace,
          pathToDetailsView: getPathToNamespace(namespace),
          icon: PrimaryIcon,
          columnAttributes: createColumns([
            {
              className: "namespaceName",
              value: namespace.getName(),
              column: NamespacesListColumn.NAME,
              firstColumn: true,
            },
            {
              className: "namespaceLifecycle",
              value: namespace.getLifecycle(),
              column: NamespacesListColumn.LIFECYCLE,
            },
            {
              className: "namespaceType",
              value: namespace.getType(),
              column: NamespacesListColumn.TYPE,
            },
            {
              className: "namespaceCreatedAt",
              value: namespace.getCreatedAt(),
              column: NamespacesListColumn.CREATED_AT,
              isDate: true,
            },
            {
              className: "namespaceCreatedBy",
              value: namespace.getCreatedBy().split(":").pop(),
              column: NamespacesListColumn.CREATED_BY,
            },
          ]),
        };
      }), [items]),
    setSelectedNamespace = noop,
    showNamespaceDetails = noop,
    publishNamespace = noop,
    deprecateNamespace = noop,
    onClickAction = noop,
    ...otherProps
  } = props;

  const selectedItems = Namespace.EMPTY.equals(selectedNamespace) ? [] : [selectedNamespace];

  const onActionClicked = React.useCallback((namespace: Namespace, action: ActionMenuItem) => {
    switch (action.id) {
      case NamespaceAction.PUBLISH_NAMESPACE:
        return publishNamespace(namespace);
      case NamespaceAction.DEPRECATE_NAMESPACE:
        return deprecateNamespace(namespace);
      default:
        return onClickAction(namespace, action);
    }
  }, [publishNamespace, deprecateNamespace, onClickAction]);

  return (
    <ModuleListView
      {...otherProps}
      className={classnames("namespacesList", className, classes.container)}
      listClassName={classnames("list", listClassName, classes.list)}
      columns={columns}
      actions={actions}
      showSearch={showSearch}
      tableLayoutFixed={tableLayoutFixed}
      summaryViewIcon={summaryViewIcon}
      listViewItems={listViewItems}
      selectedItems={selectedItems}
      onClickAction={onActionClicked}
      onClickItem={namespace => {
        setSelectedNamespace(namespace);
        showNamespaceDetails(namespace);
      }}
    />
  );
});

export default NamespacesList;
