import React from "react";
import { noop } from "@util";
import { Application } from "@data";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import { selectApplication as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import ApplicationsList, {
  ApplicationsListActions,
  ApplicationsListModel,
} from "@components/applications-list";

export interface Model extends ApplicationsListModel {
  applicationsListClassName?: string;
  title?: string;
}

export interface Actions extends ApplicationsListActions {
  setSelectedApplication?: (application?: Application) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const SelectApplication = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    applicationsListClassName,
    title = "Select the application you are inviting this user to",
    noResultsLabel = "There are not any applications in this IoT Account to invite a user with",
    items = [],
    setSelectedApplication = noop,
    children,
    ...otherProps
  } = props;

  const showSearch = React.useMemo(() => items.length > 1, [ items ]);

  const setSelectedItems = React.useCallback((selectedApplications: Application[]) =>
    setSelectedApplication(selectedApplications.pop()), [ setSelectedApplication ]);

  return (
    <div className={classnames("selectApplication", className, classes.container)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      <ApplicationsList
        {...otherProps}
        className={classnames("list", applicationsListClassName, classes.list)}
        selectable={true}
        selectAllDisabled={true}
        showSearch={showSearch}
        noResultsLabel={noResultsLabel}
        items={items}
        setSelectedItems={setSelectedItems}
      />
      {children}
    </div>
  );
});

export default SelectApplication;
