import React from "react";
import classnames from "classnames";
import snakeCase from "lodash/snakeCase";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { usePortalSnackbar } from "@components";
import { RestClientError } from "@network";
import { useSecurityManagerClient } from "@hooks";
import { binaryDataListItem as styles } from "./styles";
import { isEmptyString, noop } from "@util/Functions";
import LoadingIndicator from "./LoadingIndicator";

export const DownloadBinaryDataListItem = withStyles(styles)((props: WithStyles<typeof styles> & {
  key?: string,
  userId?: string,
  name?: string,
  selfAuthorized?: boolean,
  onSuccess?: () => void,
  onError?: () => void,
  onErrorCallbackDelayMs?: number,
}) => {

  const {
    classes,
    key = "DownloadBinaryDataListItem",
    userId = "",
    name = "",
    selfAuthorized,
    onSuccess = noop,
    onError = noop,
    onErrorCallbackDelayMs = 2000,
  } = props;

  const SecurityManagerClient = useSecurityManagerClient();
  const linkRef = React.useRef<HTMLAnchorElement>(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  usePortalSnackbar(`download-binary-data-${snakeCase(name)}`, {
    errorMessage,
    successMessage,
    onSuccessMessageShown: onSuccess,
    onErrorMessageShown: onError,
    successCallbackDelay: 0,
    errorCallbackDelay: onErrorCallbackDelayMs,
  });

  React.useEffect(() => {

    if (successMessage || errorMessage) {
      return () => noop;
    }

    let ignore = false;

    SecurityManagerClient.getUserBinaryData(userId, name, selfAuthorized)
      .then(response => {

        const getResponseHeader = (headerName: string, defaultValue: string = "") => {
          if (!response.headers || typeof response.headers.get !== "function") {
            return defaultValue;
          } else {
            return response.headers.get(headerName) || defaultValue;
          }
        };

        const contentLength = getResponseHeader("Content-Length");

        if (contentLength === "0") {
          return Promise.reject({
            status: 404,
            message: "Binary Data Not Found",
            description: "Binary Data Not Found",
            error: `Binary Data '${name}' Not Found`,
            analytic: "404:BinaryDataNotFound",
          });
        }

        const contentType = getResponseHeader("Content-Type");

        const fileType = (contentType.match(/.*\/(.*)/) || []).pop();

        const downloadFileName = `${name}${!fileType ? "" : ("." + fileType)}`;

        return response.blob()
          .then((blob: Blob) => {
            if (!ignore) {
              const href = window.URL.createObjectURL(blob);
              const a: HTMLAnchorElement | null = linkRef.current;
              if (a !== null) {
                a.download = downloadFileName;
                a.href = href;
                a.click();
                a.href = "";
                a.download = "";
                window.URL.revokeObjectURL(href);
              }
              setSuccessMessage(`Download Complete: ${downloadFileName}`);
            }
          });
      })
      .catch((response: RestClientError) => {
        if (!ignore) {
          const { error = "Binary Data Not Found" } = response;
          setErrorMessage(`Download Failed: ${error}`);
        }
      });

    return () => { ignore = true; };

  }, [
    SecurityManagerClient,
    userId,
    name,
    selfAuthorized,
    linkRef,
    errorMessage,
    successMessage,
    setErrorMessage,
    setSuccessMessage,
  ]);

  if (isEmptyString(name)) {
    return null;
  }

  return (
    <div key={key} className={classnames("downloadBinaryDataListItem", classes.container)}>
      <a ref={linkRef}>
      {!successMessage && errorMessage && (
        <label className={classnames("label", "error", classes.label, classes.error)}>
          {errorMessage}
        </label>
      )}
      {!successMessage && !errorMessage && (
        <LoadingIndicator
          className={classnames("loadingIndicator", classes.loadingIndicator)}
          label="Downloading..."
          labelClassName={classnames("downloading", classes.label)}
        />
      )}
      </a>
    </div>
  );
});

export default DownloadBinaryDataListItem;
