import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { UserProfileAttributesListColumn } from "./UserProfileAttributesListColumn";
import { listItem as styles, ProfileAttributesIcon } from "./styles";
import { UserProfileAttribute } from "@data";
import BinaryDataListItem from "./BinaryDataListItem";

export interface UserProfileAttributeListItemProps {
  userId?: string;
  column?: UserProfileAttributesListColumn;
  attribute?: UserProfileAttribute;
  showBinaryData?: boolean;
  selfAuthorized?: boolean;
}

type Props = WithStyles<typeof styles> & UserProfileAttributeListItemProps;

export const UserProfileAttributeListItem = withStyles(styles)((props: Props) => {

  const {
    classes,
    column,
    userId,
    attribute = UserProfileAttribute.EMPTY,
    showBinaryData,
    selfAuthorized,
  } = props;

  if (!column || !attribute.hasName()) {
    return null;
  }

  switch (column) {
    case UserProfileAttributesListColumn.NAME:
      return (
        <div className={classnames("name", classes.container)}>
          <ProfileAttributesIcon className={classes.icon} />
          <label className={classnames("name", classes.label)}>{attribute.getName()}</label>
        </div>
      );
    case UserProfileAttributesListColumn.VALUE:
      if (attribute.isBinaryDataType()) {
        return !showBinaryData ? null : (
          <BinaryDataListItem
            userId={userId}
            name={attribute.getName()}
            selfAuthorized={selfAuthorized}
          />
        );
      }
      return (
        <label className={classnames("value", classes.label)}>{attribute.getValueAsString()}</label>
      );
    case UserProfileAttributesListColumn.DATA_TYPE:
      return (
        <label className={classnames("dataType", classes.label)}>{attribute.getDataType()}</label>
      );
    default:
      return null;
  }
});

export default UserProfileAttributeListItem;
