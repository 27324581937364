import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DataSetsView, { DataSetsViewActions, DataSetsViewModel } from "./DataSetsView";
import DataLakeDataSet from "@data/DataLakeDataSet";
import FileBrowserView from "./FileBrowserView";
import styles from "./styles";

export interface DataLakeBrowserModel extends DataSetsViewModel {
  className?: string;
  accountId?: string;
  customActionLabel?: string;
}

export interface DataLakeBrowserActions extends DataSetsViewActions {
  customAction?: (file: string) => void;
}

type Props = WithStyles<typeof styles> & DataLakeBrowserModel & DataLakeBrowserActions & {
  children?: React.ReactNode;
};

export const DataLakeBrowser = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    accountId,
    selectedDataSet = DataLakeDataSet.EMPTY,
    dataSets = [],
    showLoadingIndicator,
    customActionLabel,
    customAction,
    children,
    ...otherProps
  } = props;

  const showEmptyView = React.useMemo(() => dataSets.length === 0, [dataSets]);

  const dataSetAlias = React.useMemo(() => selectedDataSet.getDataSetAlias(), [selectedDataSet]);

  return (
    <React.Fragment>
      <div className={classnames("dataLakeBrowser", className, classes.container)}>
        <DataSetsView
          {...otherProps}
          className={classnames("dataSets", classes.dataSets)}
          dataSets={dataSets}
          selectedDataSet={selectedDataSet}
          showLoadingIndicator={showLoadingIndicator}
        />
        <FileBrowserView
          className={classnames("fileBrowser", classes.fileBrowser)}
          dataSetAlias={dataSetAlias}
          accountId={accountId}
          showLoadingIndicator={showLoadingIndicator}
          showEmptyView={showEmptyView}
          customActionLabel={customActionLabel}
          customAction={customAction}
        />
      </div>
      {children}
    </React.Fragment>
  );
});

export default DataLakeBrowser;
