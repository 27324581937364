import { Record } from "immutable";
import {
  DeviceTypeModelV3CredentialEncoding,
  DeviceTypeModelV3CredentialPersistence,
  DeviceTypeModelV3Credentials,
  DeviceTypeModelV3CredentialType,
  DeviceTypeModelV3Definition,
  DeviceTypeModelV3DeviceAuthentication,
  DeviceTypeModelV3DeviceAuthenticationType,
} from "@data";
import { getStringValue, isEmptyString } from "@util";

export interface CredentialsViewItemAttributes {
  name: string;
  type: DeviceTypeModelV3CredentialType;
  encoding: DeviceTypeModelV3CredentialEncoding;
  persistence: DeviceTypeModelV3CredentialPersistence;
  trustedCmsAuthorityIds: string[];
  deviceAuthentication: DeviceTypeModelV3DeviceAuthenticationType[];
}

export class CredentialsViewItem extends Record({
  name: "",
  type: DeviceTypeModelV3CredentialType.CERTIFICATE,
  encoding: DeviceTypeModelV3CredentialEncoding.PEM,
  persistence: DeviceTypeModelV3CredentialPersistence.MUTABLE,
  trustedCmsAuthorityIds: [],
  deviceAuthentication: [],
}) implements CredentialsViewItemAttributes {

  public static EMPTY: CredentialsViewItem = new CredentialsViewItem();

  public readonly name: string;
  public readonly type: DeviceTypeModelV3CredentialType;
  public readonly encoding: DeviceTypeModelV3CredentialEncoding;
  public readonly persistence: DeviceTypeModelV3CredentialPersistence;
  public readonly trustedCmsAuthorityIds: string[];
  public readonly deviceAuthentication: DeviceTypeModelV3DeviceAuthenticationType[];

  public static toSecurityDefinition(item: CredentialsViewItem): DeviceTypeModelV3Definition {
    return new DeviceTypeModelV3Definition({
      name: item.getName(),
      type: item.getType(),
      encoding: item.getEncoding(),
      persistence: item.getPersistence(),
      ...(!item.hasTrustedCmsAuthorityIds() ? ({}) : ({
        trustedCmsAuthorityIds: item.getTrustedCmsAuthorityIds(),
      })),
    });
  }

  public static toSecurityCredentials(items: CredentialsViewItem[]): DeviceTypeModelV3Credentials {
    const definitions = items
      .map(item => CredentialsViewItem.toSecurityDefinition(item))
      .filter(item => item.getType() !== DeviceTypeModelV3CredentialType.NONE);

    const definitionNames = definitions.map(definition => definition.getName());

    const deviceAuthentication = items
      .reduce((combined, item) => !item.hasDeviceAuthentication() ? combined : combined.concat(
        item.getDeviceAuthentication().map(type => new DeviceTypeModelV3DeviceAuthentication({
          type,
          credentialName: item.getName(),
        }))), [] as DeviceTypeModelV3DeviceAuthentication[])
      .filter(auth => auth.getType() !== DeviceTypeModelV3DeviceAuthenticationType.NONE &&
        definitionNames.indexOf(auth.getCredentialName()) >= 0);

    return new DeviceTypeModelV3Credentials({
      definitions: definitions.map(definition => {
        const { trustedCmsAuthorityIds = [], ...attrs } = definition.toJS();
        return {
          ...attrs,
          // Only include the authority ids attribute when defined
          ...(!Array.isArray(trustedCmsAuthorityIds) || trustedCmsAuthorityIds.length === 0 ? ({}) : ({
            trustedCmsAuthorityIds,
          })),
        };
      }),
      usage: {
        deviceAuthentication: deviceAuthentication.map(auth => auth.toJS()),
      },
    });
  }

  public getName(): string {
    return getStringValue(this.name);
  }

  public hasName(): boolean {
    return !isEmptyString(this.getName());
  }

  public getType(): DeviceTypeModelV3CredentialType {
    return this.type || DeviceTypeModelV3CredentialType.NONE;
  }

  public isCertificateCredentialType(): boolean {
    return this.type === DeviceTypeModelV3CredentialType.CERTIFICATE;
  }

  public getEncoding(): DeviceTypeModelV3CredentialEncoding {
    return this.encoding || DeviceTypeModelV3CredentialEncoding.NONE;
  }

  public getPersistence(): DeviceTypeModelV3CredentialPersistence {
    return this.persistence || DeviceTypeModelV3CredentialPersistence.NONE;
  }

  public getTrustedCmsAuthorityIds(): string[] {
    if (!this.isCertificateCredentialType()) {
      return [];
    }

    const trustedCmsAuthorityIds =
      Array.isArray(this.trustedCmsAuthorityIds) ? this.trustedCmsAuthorityIds : [];

    return trustedCmsAuthorityIds.map(getStringValue).filter(value => !isEmptyString(value));
  }

  public hasTrustedCmsAuthorityIds(): boolean {
    return this.getTrustedCmsAuthorityIds().length > 0;
  }

  public getDeviceAuthentication(): DeviceTypeModelV3DeviceAuthenticationType[] {
    const deviceAuthentication =
      Array.isArray(this.deviceAuthentication) ? this.deviceAuthentication : [];

    return deviceAuthentication.filter(type => type !== DeviceTypeModelV3DeviceAuthenticationType.NONE);
  }

  public hasDeviceAuthentication(): boolean {
    return this.getDeviceAuthentication().length > 0;
  }
}

export default CredentialsViewItem;
