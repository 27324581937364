import { PolicyStatement } from "@data";
import  PolicyStatementsListColumn  from "./PolicyStatementsListColumn";
import { Comparator, compare, isEmptyString } from "@util";
import React from "react";

export const getSearchResults = (items: PolicyStatement[], searchQuery: string) => {

  const statements = items.slice();

  if (statements.length === 0 || isEmptyString(searchQuery)) {
    return statements;
  }

  const searchFilter = searchQuery.toLowerCase();

  return statements.filter((policy: PolicyStatement) =>
    policy.getId().toLowerCase().indexOf(searchFilter) >= 0 ||
    policy.getAction().toLowerCase().indexOf(searchFilter) >= 0 ||
    policy.getResource().toLowerCase().indexOf(searchFilter) >= 0 ||
    policy.getEffect().toLowerCase().indexOf(searchFilter) >= 0);
};

const compareById = (item: PolicyStatement, otherItem: PolicyStatement, ascending: boolean) =>
  compare(item.id, otherItem.id, ascending);

const compareByAction = (item: PolicyStatement, otherItem: PolicyStatement, ascending: boolean) =>
  compare(item.action, otherItem.action, ascending);

const compareByResource = (item: PolicyStatement, otherItem: PolicyStatement, ascending: boolean) =>
  compare(item.resource, otherItem.resource, ascending);

const compareByEffect = (item: PolicyStatement, otherItem: PolicyStatement, ascending: boolean) =>
  compare(item.effect, otherItem.effect, ascending);

export const createComparator =
  (sortedColumn: PolicyStatementsListColumn, ascending: boolean): Comparator<PolicyStatement> =>
    (left: PolicyStatement, right: PolicyStatement) => {
      switch (sortedColumn) {
        case PolicyStatementsListColumn.ID:
          return compareById(left, right, ascending);
        case PolicyStatementsListColumn.ACTION:
          return compareByAction(left, right, ascending);
        case PolicyStatementsListColumn.RESOURCE:
          return compareByResource(left, right, ascending);
        case PolicyStatementsListColumn.EFFECT:
          return compareByEffect(left, right, ascending);
        default:
          return 0;
      }
    };

export const sortAndFilterPolicyStatementResult = (props: {
  items: PolicyStatement[],
  nameFilter: string,
  sortOrderAscending: boolean,
  sortByColumn: PolicyStatementsListColumn
}) => {

  const {items, nameFilter, sortOrderAscending, sortByColumn } = props;

  const comparator = React.useMemo(() =>
    createComparator(sortByColumn, sortOrderAscending), [sortByColumn, sortOrderAscending]);

  const sortedStatements = React.useMemo(() => items.sort(comparator).slice(), [items, comparator]);

  return React.useMemo(() =>
    getSearchResults(sortedStatements, nameFilter), [sortedStatements, nameFilter]);
};
