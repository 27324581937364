import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    content: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "30px",
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "28px",
      marginTop: 16,
      "&:first-child": {
        marginTop: 25,
      },
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
    qrCode: {
    },
    mfaCode: {
      marginTop: 25,
    },
    textField: {
    },
    exampleApps: {
      marginTop: 25,
      padding: 8,
      border: `1px solid ${Color.GREY3}`,
      display: "flex",
      flexFlow: "column nowrap",
    },
    exampleAppsTitle: {
      margin: 0,
      color: Color.TEXT,
      fontSize: 24,
      fontWeight: 300,
      lineHeight: "28px",
    },
    exampleAppsContent: {
      marginTop: 15,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    exampleApp: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "center",
      borderLeft: `1px solid ${Color.GREY3}`,
      "&:first-child": {
        borderLeft: "none",
      },
    },
    exampleAppIcon: {
      width: 32,
      height: 32,
      color: Color.TEXT,
      cursor: "pointer",
    },
    exampleAppName: {
      marginLeft: 8,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "18px",
      textDecoration: "underline",
      color: Color.TEXT,
      cursor: "pointer",
    },
  });

export default styles;
