import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import { JsonSchemaProperty } from "@data";
import PropertiesTable, {
  Actions as PropertiesTableActions,
  Model as PropertiesTableModel,
} from "./PropertiesTable";

export interface Model extends PropertiesTableModel {
  className?: string;
  children?: React.ReactNode;
}

export interface Actions extends PropertiesTableActions {
  showObjectProperty?: (property: JsonSchemaProperty) => void;
  showSchemaRefProperty?: (property: JsonSchemaProperty) => void;
}

export const SchemaPropertiesTable = (props: Model & Actions) => {

  const {
    className,
    showObjectProperty = noop,
    showSchemaRefProperty = noop,
    children,
    ...remainingProps
  } = props;

  const onClickProperty = (property: JsonSchemaProperty) => {
    if (property.isObject()) {
      showObjectProperty(property);
    } else if (property.isReference() && property.hasReference()) {
      showSchemaRefProperty(property);
    }
  };

  return (
    <PropertiesTable
      { ...remainingProps }
      className={classnames("schemaPropertiesTable", className)}
      onClickProperty={onClickProperty}
    >
      {children}
    </PropertiesTable>
  );
};

export default SchemaPropertiesTable;
