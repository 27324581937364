import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import { Alert, AlertTitle } from "@components";
import { useDropzone } from "react-dropzone";
import Backdrop from "@material-ui/core/Backdrop";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import LoadingIndicator from "@components/user-profile-attributes-list/LoadingIndicator";
import styles from "./styles";

// TODO: We don't currently expose a link to the Learning @ Signify transcript, but this is the deep link
// noinspection JSUnusedGlobalSymbols
export const LEARNING_TRANSCRIPT_LINK =
  "https://lighting.csod.com/samldefault.aspx?ouid=2&returnurl=/deeplink/ssodeeplink.aspx?module=transcript";

export const PRIVACY_DATA_PROTECTION_LINK = "https://lighting.csod.com/samldefault.aspx?ouid=2&returnurl=" +
  encodeURIComponent("/DeepLink/ProcessRedirect.aspx?module=lodetails&lo=58088a22-4ed3-4483-be80-a0542f900066");

export interface TrainingCertificateViewModel {
  className?: string;
  file?: File;
  success?: boolean;
  successLabel?: string;
  loading?: boolean;
  error?: boolean;
  trainingLink?: string;
  showAlert?: boolean;
  showTitle?: boolean;
  inDialog?: boolean;
}

export interface TrainingCertificateViewActions {
  onSelectFile?: (event: WindowEventHandlers) => void;
  onDrop?: (files: File[]) => void;
}

type Model = TrainingCertificateViewModel;
type Actions = TrainingCertificateViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const TrainingCertificateView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    file,
    success = false,
    successLabel = "Training certificate uploaded",
    loading = false,
    error = false,
    showAlert = true,
    showTitle = true,
    inDialog = false,
    trainingLink = PRIVACY_DATA_PROTECTION_LINK,
    children,
    onSelectFile = noop,
    onDrop = noop,
  } = props;

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    preventDropOnDocument: true,
  });

  const fileSelected = React.useMemo(() => file?.size !== 0 , [file]);

  return(
    <div
      className={classnames("trainingCertificateView", className, classes.container, {
      [classes.wizardPage]: !inDialog,
      })}
    >
      {showTitle && (
        <label className={classes.title}>
          Upload Privacy & Data Protection learning transcript
        </label>
      )}
      <form
        {...getRootProps()}
        className={classnames("uploadContent", classes.content, {
          [classes.uploadButton]: !success,
          [classes.contentDragging]: !success && isDragActive,
          [classes.contentSelected]: success || (fileSelected && !isDragActive),
        })}
      >
        {success && (
          <label className={classes.label}>{successLabel}</label>
        )}
        {!success && (
          <React.Fragment>
            {!fileSelected && (
              <label className={classnames(classes.label, classes.uploadButton)}>
                Select a file to upload
              </label>
            )}
            {fileSelected && !loading && error && (
              <label className={classnames(classes.label, classes.uploadButton)}>
                Upload Failed. Click here to try again.
              </label>
            )}
            {fileSelected && loading && (
              <LoadingIndicator
                className={classnames("loadingIndicator", classes.loadingIndicator)}
                label="Uploading..."
                labelClassName={classnames("uploading", classes.dialogLabel)}
              />
            )}
          </React.Fragment>
        )}
        <input {...getInputProps()} type="file" onChange={onSelectFile}/>
      </form>
      {showAlert && (
        <React.Fragment>
          <Alert severity="info" className={classnames("certificateInfo", classes.alert)}>
            <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
              <strong>
                Note
              </strong>
            </AlertTitle>
            <ul className={classnames("alertDescription", classes.alertDescription)}>
              <li>
                A Privacy & Data Protection learning transcript completion certificate is
                required to request data access
              </li>
              <li>
                The certificate is a PDF document issued upon completion of the training course
              </li>
              <li>
                The certificate can be obtained by completing
                <a
                  className={classes.underlined}
                  href={trainingLink}
                  target="_blank"
                >
                  Privacy & Data Protection
                </a>
                training
              </li>
              <li>
                Every year, the training must be re-taken and a valid certificate must be provided
                as evidence of completion
              </li>
            </ul>
          </Alert>
        </React.Fragment>
      )}
      {children}
      <Backdrop className={classnames("backdrop", classes.backdrop)} open={loading} />
    </div>
  );
});

export default TrainingCertificateView;
