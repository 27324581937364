import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface AccountModel {
  className?: string;
  account?: string;
  alias?: string;
}

export interface AccountActions {
}

type Model = AccountModel;
type Actions = AccountActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const Account = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    account = "-",
    alias,
    children,
  } = props;

  return (
    <div className={classnames("accountColumn", className, classes.container)}>
      <label className={classnames("account", classes.account)}>{account}</label>
      {alias && (
        <label className={classnames("alias", classes.alias)}>{alias}</label>
      )}
      {children}
    </div>
  );
});

export default Account;
