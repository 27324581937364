import React from "react";
import classnames from "classnames";
import TextField from "@components/text-field";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import {
  enterKeyHandler,
  formEventHandler,
  noop,
} from "../../../../util";

export interface Model {
  className?: string;
  type?: string;
  value?: string;
  label?: string;
  name?: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  endAdornment?: React.ReactNode;
}

export interface Actions {
  setValue?: (value: string) => void;
  login?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const LoginTextField = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    type = "text",
    value = "",
    label,
    name,
    helperText,
    error,
    disabled,
    autoFocus,
    endAdornment,
    setValue = noop,
    login = noop,
  } = props;

  const formHelperTextProps = {
    className: "formHelperText",
    classes: {
      root: classes.formHelperText,
    },
  };

  const inputLabelProps = {
    className: "inputLabel",
    classes: {
      root: classes.inputLabel,
      error: classes.error,
      focused: classes.focused,
      shrink: classes.inputLabelShrink,
    },
  };

  const inputProps = {
    className: classnames("textField", classes.textField),
  };

  return (
    <TextField
      className={classnames("loginTextField", className, classes.textField)}
      label={label}
      name={name}
      type={type}
      variant="filled"
      autoComplete="off"
      fullWidth={true}
      value={value}
      inputProps={inputProps}
      helperText={helperText}
      error={error}
      disabled={disabled}
      autoFocus={autoFocus}
      InputLabelProps={inputLabelProps}
      InputProps={{ inputProps, endAdornment }}
      FormHelperTextProps={formHelperTextProps}
      onChange={formEventHandler(setValue)}
      onKeyDown={enterKeyHandler(login)}
    />
  );
});

export default LoginTextField;
