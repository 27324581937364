import React from "react";
import classnames from "classnames";
import { clickHandler } from "@util";
import Button from "@components/button";
import { deviceData as styles } from "./styles";
import { Alert, AlertTitle } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface Model {
  className?: string;
  title?: string;
  description?: string;
  information?: string;
  actualViewSelected?: boolean;
  desiredViewSelected?: boolean;
  metadataViewSelected?: boolean;
  actualDataButtonLabel?: string;
  desiredDataButtonLabel?: string;
  metadataButtonLabel?: string;
  metadataInfoBanner?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  setActualDataView?: () => void;
  setDesiredDataView?: () => void;
  setMetadataView?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DeviceDataView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Device Data",
    description = "Select a schema to view data associated with the schema",
    information = "",
    actualViewSelected = true,
    metadataViewSelected,
    desiredViewSelected,
    actualDataButtonLabel = "Actual Configuration",
    desiredDataButtonLabel = "Desired Configuration",
    metadataButtonLabel = "Metadata",
    metadataInfoBanner,
    setActualDataView,
    setDesiredDataView,
    setMetadataView,
    children
  } = props;

  return (
    <div className={classnames("deviceDataView", className, classes.container)}>
      <label className={classnames("title", classes.title)}>{title}</label>
      <label className={classes.subtitle}>
        {description}
      </label>
      <div className={classnames("controls", classes.controls)}>
        <Button
          className={classnames("actualButton", classes.actualButton)}
          color={actualViewSelected ? "primary" : "inherit"}
          onClick={clickHandler(setActualDataView)}
          variant="contained"
        >
          {actualDataButtonLabel}
        </Button>
        <Button
          className={classnames("desiredButton", classes.desiredButton)}
          color={desiredViewSelected ? "primary" : "inherit"}
          onClick={clickHandler(setDesiredDataView)}
          variant="contained"
        >
          {desiredDataButtonLabel}
        </Button>
        <Button
          className={classnames("metadataButton", classes.desiredButton)}
          color={metadataViewSelected ? "primary" : "inherit"}
          onClick={clickHandler(setMetadataView)}
          variant="contained"
        >
          {metadataButtonLabel}
        </Button>
      </div>
      <label className={classes.information}>
        {information}
      </label>
      {metadataInfoBanner && (
        <Alert severity="info" className={classnames("metadataInfo", classes.alert)}>
          <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
            <strong>
              Metadata not updated.
            </strong>
            <p className={classnames("alertDescription", classes.alertDescription)}>
             Metadata is not updated for this schema. To update it, click on the edit button and add data.
            </p>
          </AlertTitle>
        </Alert>
      )}
      {children}
    </div>
  );
});

export default DeviceDataView;
