import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import ActionsMenu, { ActionMenuItem, ActionsMenuProps } from "@components/actions-menu";

export enum EmailTemplateAction {
  EDIT_EMAIL_TEMPLATE = "edit_email_template",
  DELETE_EMAIL_TEMPLATE = "delete_email_template",
}

export const EDIT_EMAIL_TEMPLATE_ACTION: ActionMenuItem = Object.freeze({
  id: EmailTemplateAction.EDIT_EMAIL_TEMPLATE,
  name: "Edit Email template",
  disabled: false,
});

export const DELETE_EMAIL_TEMPLATE_ACTION: ActionMenuItem = Object.freeze({
  id: EmailTemplateAction.DELETE_EMAIL_TEMPLATE,
  name: "Delete Email Template",
  disabled: false,
});

export const DEFAULT_EMAIL_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  EDIT_EMAIL_TEMPLATE_ACTION,
  DELETE_EMAIL_TEMPLATE_ACTION,
]) as ActionMenuItem[];

export interface EmailTemplateActionsMenuProps extends ActionsMenuProps {
  editEmailTemplate?: () => void;
  deleteEmailTemplate?: () => void;
}

export const withEmailTemplateActions = (Component: React.ComponentType<EmailTemplateActionsMenuProps>) =>
  (props: EmailTemplateActionsMenuProps) => {

    const {
      actions = DEFAULT_EMAIL_ACTION_MENU_ITEMS,
      editEmailTemplate = noop,
      deleteEmailTemplate = noop,
      onClickAction = noop,
    } = props;

    return (
      <Component
        {...props}
        actions={actions}
        onClickAction={(action: ActionMenuItem) => {
          if (action.id === EmailTemplateAction.EDIT_EMAIL_TEMPLATE) {
            editEmailTemplate();
          } else if (action.id === EmailTemplateAction.DELETE_EMAIL_TEMPLATE) {
            deleteEmailTemplate();
          } else {
            onClickAction(action);
          }
        }}
      />
    );
  };

export const EmailTemplateActionsMenu = withEmailTemplateActions((props: EmailTemplateActionsMenuProps) => {

  const { className, buttonLabel = "Email Template Actions" } = props;

  return (
    <ActionsMenu
      {...props}
      className={classnames("emailTemplateActionsMenu", className)}
      buttonLabel={buttonLabel}
    />
  );
});

export default EmailTemplateActionsMenu;
