import React from "react";
import classnames from "classnames";
import { SearchFilter } from "@data";
import Typography from "@material-ui/core/Typography";
import Filters from "./Filters";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const DEFAULT_MAP_SEARCH_FILTER_TO_LABEL = (searchFilter: SearchFilter) => {
  const key = searchFilter.getKey();
  const value = searchFilter.getValue();
  return [key, value].filter(s => !isEmptyString(s)).join(": ");
};

export interface QuickFiltersModel {
  className?: string;
  filterClassName?: string;
  title?: string;
  disabled?: boolean;
  filters?: SearchFilter[];
  lockedFilters?: string[];
  selectedFilters?: string[];
}

export interface QuickFiltersActions {
  onClickFilter?: (filter: SearchFilter) => void;
  mapFilterToLabel?: (filter: SearchFilter) => React.ReactNode;
}

type Model = QuickFiltersModel;
type Actions = QuickFiltersActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const QuickFilters = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    filterClassName,
    title = "Quick Filters",
    disabled,
    filters = [],
    lockedFilters = [],
    selectedFilters = [],
    onClickFilter = noop,
    mapFilterToLabel = DEFAULT_MAP_SEARCH_FILTER_TO_LABEL,
  } = props;

  if (filters.length === 0) {
    return null;
  }

  return (
    <div className={classnames("quickFilters", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        <Typography className={classnames("title", classes.title)} variant="h4">
          {title}
        </Typography>
      </div>
      <Filters
        className={classnames("filters", classes.searchFilters, filterClassName)}
        disabled={disabled}
        filters={filters}
        lockedFilters={lockedFilters}
        selectedFilters={selectedFilters}
        setFilter={onClickFilter}
        mapFilterToLabel={mapFilterToLabel}
      />
    </div>
  );
});

export default QuickFilters;
