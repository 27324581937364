import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 16,
    },
    closeMyAccountSubtitle: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "32px",
      textAlign: "center",
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
    userId: {
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "16px",
      marginTop: 16,
    },
    emptyViewLabel: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      fontStyle: "italic",
    },
    inputField: {
      flex: "1 0 auto",
      width: 300,
    },
    controls: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 20,
    },
    note: {
      flex: "1 0 auto",
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    formHelperTextError: {
      fontSize: 12,
    },
  });

export default styles;
