import { Theme } from "@material-ui/core/styles/createTheme";
import { createStyles, StyleRules } from "@material-ui/core/styles";
import { Color } from "../../../../components/styles";

export const textField: StyleRules<string> = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& input": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      padding: "8px 10px",
      margin: 0,
    },
  },
};

export const tooltip: StyleRules<string> = {
  popper: {
    width: 231,
    height: "auto",
    alignItems: "center",
    display: "flex",
    marginTop: 15,
  },
  tooltip: {
    backgroundColor: Color.WHITE,
    color: Color.GREY8,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px",
    textAlign: "left",
    whiteSpace: "normal",
    padding: "10px 15px",
    margin: 0,
    opacity: 1,
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.5)",
    borderRadius: 3,
  },
};

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    inputField: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 16,
    },
    inlineContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      marginTop: 16,
    },
    inlineInputField: {
      flex: "1 1 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginLeft: 8,
      "&:first-child": {
        marginLeft: 0,
      },
    },
    inputLabel: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    formHelperTextError: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 10,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: "8px 0 0",
      whiteSpace: "nowrap",
    },
  });
