import { Record } from "immutable";
import { getStringValue } from "@util";

export interface ActionsListItemDataAttributes {
  name: string;
}

export class ActionsListItemData extends Record({
  name: ""
}) implements ActionsListItemDataAttributes {

  public static EMPTY: ActionsListItemData = new ActionsListItemData();

  public readonly name: string;

  public getName(): string {
    return getStringValue(this.name);
  }
}

export default ActionsListItemData;
