import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { getCreateDataSetRequestPath } from "./helpers";
import { useGetDataSetRequest } from "@hooks";
import WaitForApiRequestView from "@components/wait-for-api-request-view";

export function CloneDataSetRequestView() {

  const { id: dataSetRequestId } = useParams<{ id: string }>();

  const [model] = useGetDataSetRequest({ dataSetRequestId });

  const {
    dataSetRequest,
    showLoadingIndicator,
    errorMessage,
    errors,
    showErrorView,
    showAccessDenied,
    showNotFound,
    showSuccessView,
  } = model;

  const redirect = React.useMemo(() => !showSuccessView ? null : (
    <Redirect
      to={{
        pathname: getCreateDataSetRequestPath(),
        state: {
          dataSetRequest: dataSetRequest.toJS(),
        },
      }}
    />
  ), [showSuccessView, dataSetRequest]);

  return (
    <WaitForApiRequestView
      loadingMessage="Loading data set request..."
      errorTitle="Failed to load data set request"
      errorMessage={errorMessage}
      errors={errors}
      showLoadingIndicator={showLoadingIndicator}
      showErrorView={showErrorView}
      showAccessDenied={showAccessDenied}
      showNotFound={showNotFound}
    >
      {redirect}
    </WaitForApiRequestView>
  );
}

export default CloneDataSetRequestView;
