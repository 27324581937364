import { combineReducers } from "redux";
import { Policy, PolicyAttributes } from "@data";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes
} from "@base";
import { MODULE_ID } from "../constants";
import { PolicyStatementsListColumn } from "@components/policy-statement-list";

export interface PolicyDetailsSchema extends BasePortalModuleSchema {
  policy: PolicyAttributes;
  nameFilter: string;
  sortByColumn: PolicyStatementsListColumn;
  sortOrderAscending: boolean;
}

export const DEFAULT_STATE: PolicyDetailsSchema = createPortalModuleDefaultState({
  policy: Object.freeze({ ...Policy.EMPTY.toJS() }),
  nameFilter: "",
  sortByColumn: PolicyStatementsListColumn.NONE,
  sortOrderAscending: true,
} as PolicyDetailsSchema);

export type PolicyDetailsActionTypes = PortalModuleActionTypes<PolicyDetailsSchema> & {
  LOAD_POLICY_REQUEST: string;
  LOAD_POLICY_SUCCESS: string;
  LOAD_POLICY_FAILED: string;
};

export const ACTION_TYPES: PolicyDetailsActionTypes = {
  ...createPortalModuleActionTypes<PolicyDetailsSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    LOAD_POLICY_REQUEST: `${MODULE_ID}/load_policy_request`,
    LOAD_POLICY_SUCCESS: `${MODULE_ID}/load_policy_success`,
    LOAD_POLICY_FAILED: `${MODULE_ID}/load_policy_failed`,
  },
};

export const PolicyDetailsReducer = combineReducers<PolicyDetailsSchema>(
  createPortalModuleReducers<PolicyDetailsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default PolicyDetailsReducer;
