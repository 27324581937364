import React from "react";
import classnames from "classnames";
import QualityReport from "@data/QualityReport";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import CodeCoverageStatsCard, { CodeCoverageStatsCardTrend } from "./CodeCoverageStatsCard";
import styles from "./styles";

const getCodeCoverageStatsCardTrend = (trendDelta: number) => {
  if (trendDelta === 0) {
    return CodeCoverageStatsCardTrend.FLAT;
  } else {
    return trendDelta < 0
      ? CodeCoverageStatsCardTrend.TRENDING_DOWN
      : CodeCoverageStatsCardTrend.TRENDING_UP;
  }
};

export interface CodeCoverageStatsModel {
  className?: string;
  currentReport?: QualityReport;
  previousReport?: QualityReport;
  showLineCoverageOnly?: boolean;
}

export interface CodeCoverageStatsActions {
}

type Model = CodeCoverageStatsModel;
type Actions = CodeCoverageStatsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const CodeCoverageStats = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    currentReport = QualityReport.EMPTY,
    previousReport = QualityReport.EMPTY,
    showLineCoverageOnly,
    children,
  } = props;

  const showHistoricalTrends = React.useMemo(() =>
      !QualityReport.EMPTY.equals(previousReport),
    [previousReport]);

  const lineCoverageTrendDelta = React.useMemo(() =>
      !showHistoricalTrends ? 0 : (currentReport.getLineCoverage() - previousReport.getLineCoverage()),
    [showHistoricalTrends, currentReport, previousReport]);

  const methodCoverageTrendDelta = React.useMemo(() =>
      !showHistoricalTrends ? 0 : (currentReport.getMethodCoverage() - previousReport.getMethodCoverage()),
    [showHistoricalTrends, currentReport, previousReport]);

  const classCoverageTrendDelta = React.useMemo(() =>
      !showHistoricalTrends ? 0 : (currentReport.getClassCoverage() - previousReport.getClassCoverage()),
    [showHistoricalTrends, currentReport, previousReport]);

  const branchCoverageTrendDelta = React.useMemo(() =>
      !showHistoricalTrends ? 0 : (currentReport.getBranchCoverage() - previousReport.getBranchCoverage()),
    [showHistoricalTrends, currentReport, previousReport]);

  const instructionCoverageTrendDelta = React.useMemo(() =>
      !showHistoricalTrends ? 0 : (currentReport.getInstructionCoverage() - previousReport.getInstructionCoverage()),
    [showHistoricalTrends, currentReport, previousReport]);

  const complexityCoverageTrendDelta = React.useMemo(() =>
      !showHistoricalTrends ? 0 : (currentReport.getComplexityCoverage() - previousReport.getComplexityCoverage()),
    [showHistoricalTrends, currentReport, previousReport]);

  return (
    <div className={classnames("codeCoverageStats", className, classes.container)}>
      {showLineCoverageOnly && (
        <div className={classnames("row", classes.row)}>
          <CodeCoverageStatsCard
            className={classnames("lineCoverage", "card", classes.card)}
            coverage={currentReport.getLineCoverage()}
            trendDelta={lineCoverageTrendDelta}
            trend={!showHistoricalTrends
              ? CodeCoverageStatsCardTrend.NONE
              : getCodeCoverageStatsCardTrend((lineCoverageTrendDelta))
            }
          />
        </div>
      )}
      {!showLineCoverageOnly && (
        <React.Fragment>
          <div className={classnames("row", classes.row)}>
            <CodeCoverageStatsCard
              className={classnames("lineCoverage", "card", classes.card)}
              title="Line Coverage"
              coverage={currentReport.getLineCoverage()}
              trendDelta={lineCoverageTrendDelta}
              trend={!showHistoricalTrends
                ? CodeCoverageStatsCardTrend.NONE
                : getCodeCoverageStatsCardTrend((lineCoverageTrendDelta))
              }
            />
            <CodeCoverageStatsCard
              className={classnames("methodCoverage", "card", classes.card)}
              title="Method Coverage"
              coverage={currentReport.getMethodCoverage()}
              trendDelta={methodCoverageTrendDelta}
              trend={!showHistoricalTrends
                ? CodeCoverageStatsCardTrend.NONE
                : getCodeCoverageStatsCardTrend((methodCoverageTrendDelta))
              }
            />
            <CodeCoverageStatsCard
              className={classnames("classCoverage", "card", classes.card)}
              title="Class Coverage"
              coverage={currentReport.getClassCoverage()}
              trendDelta={classCoverageTrendDelta}
              trend={!showHistoricalTrends
                ? CodeCoverageStatsCardTrend.NONE
                : getCodeCoverageStatsCardTrend((classCoverageTrendDelta))
              }
            />
          </div>
          <div className={classnames("row", classes.row)}>
            <CodeCoverageStatsCard
              className={classnames("branchCoverage", "card", classes.card)}
              title="Branch Coverage"
              coverage={currentReport.getBranchCoverage()}
              trendDelta={branchCoverageTrendDelta}
              trend={!showHistoricalTrends
                ? CodeCoverageStatsCardTrend.NONE
                : getCodeCoverageStatsCardTrend((branchCoverageTrendDelta))
              }
            />
            <CodeCoverageStatsCard
              className={classnames("instructionCoverage", "card", classes.card)}
              title="Instruction Coverage"
              coverage={currentReport.getInstructionCoverage()}
              trendDelta={instructionCoverageTrendDelta}
              trend={!showHistoricalTrends
                ? CodeCoverageStatsCardTrend.NONE
                : getCodeCoverageStatsCardTrend((instructionCoverageTrendDelta))
              }
            />
            <CodeCoverageStatsCard
              className={classnames("complexityCoverage", "card", classes.card)}
              title="Complexity Coverage"
              coverage={currentReport.getComplexityCoverage()}
              trendDelta={complexityCoverageTrendDelta}
              trend={!showHistoricalTrends
                ? CodeCoverageStatsCardTrend.NONE
                : getCodeCoverageStatsCardTrend((complexityCoverageTrendDelta))
              }
            />
          </div>
        </React.Fragment>
      )}
      {children}
    </div>
  );
});

export default CodeCoverageStats;
