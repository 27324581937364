import React from "react";
import { Module } from "@data";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import WelcomePageTitle from "./components/WelcomePageTitle";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./WelcomePageView";

export const welcomePageModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Welcome",
  title: <WelcomePageTitle />,
  breadcrumbTitle: "Home",
  className: "welcomePageModule",
  primaryColor,
  icon,
  view,
  backButton: null,
});

export default welcomePageModule;
