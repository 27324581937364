import React from "react";
import Color from "@Color";
import classnames from "classnames";
import TextField from "../text-field";
import { QRCodeSVG } from "qrcode.react";
import AppleIcon from "@material-ui/icons/Apple";
import GoogleIcon from "@material-ui/icons/Shop";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import { useActivateServiceMfa, useCreateServiceMfa } from "@hooks";
import { enterKeyHandler, formEventHandler, isEmptyString } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "../portal-module-dialog";
import styles from "./styles";

export interface EnableServiceMfaDialogModel extends PortalModuleDialogModel<string> {
}

export interface EnableServiceMfaDialogActions extends PortalModuleDialogActions<string> {
}

type Model = EnableServiceMfaDialogModel;
type Actions = EnableServiceMfaDialogActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const EnableServiceMfaDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Enable Multi-factor Authentication",
    maxWidth = "sm",
    children,
    ...otherProps
  } = props;

  const [code, setCode] = React.useState("");

  const [codeErrorMessage, setCodeErrorMessage] = React.useState("");

  const [{ id, secret, qrCodeValue, ...createState }, { createAuthenticator }] = useCreateServiceMfa();

  const [{ successMessage, ...activateState }, { activateAuthenticator, reset: resetActivateState }] =
    useActivateServiceMfa({
      id,
      code,
    });

  const showActivateView = React.useMemo(() => !isEmptyString(secret), [secret]);

  const loading = React.useMemo(() => {
    const {
      showLoadingIndicator: createAuthenticatorShowLoadingIndicator,
    } = createState;
    const {
      showLoadingIndicator: activateAuthenticatorShowLoadingIndicator,
    } = activateState;
    return createAuthenticatorShowLoadingIndicator || activateAuthenticatorShowLoadingIndicator;
  }, [createState, activateState]);

  const accessDenied = React.useMemo(() => {
    const {
      showAccessDenied: createAuthenticatorShowAccessDenied,
    } = createState;
    const {
      showAccessDenied: activateAuthenticatorShowAccessDenied,
    } = activateState;
    return createAuthenticatorShowAccessDenied || activateAuthenticatorShowAccessDenied;
  }, [createState, activateState]);

  const errorMessage = React.useMemo(() => {
    const {
      errorMessage: createAuthenticatorErrorMessage,
    } = createState;
    const {
      errorMessage: activateAuthenticatorErrorMessage,
    } = activateState;
    return createAuthenticatorErrorMessage || activateAuthenticatorErrorMessage;
  }, [createState, activateState]);

  const showEmptyView = React.useMemo(() =>
      !showActivateView && loading,
    [showActivateView, loading]);

  const showBackdrop = React.useMemo(() =>
      showActivateView && loading,
    [showActivateView, loading]);

  const updateCode = React.useCallback((updatedValue: string) => {
    resetActivateState();
    setCodeErrorMessage("");
    setCode(updatedValue);
  }, [resetActivateState, setCode, setCodeErrorMessage]);

  const onClickContinue = React.useCallback(() => {
    if (showActivateView) {
      activateAuthenticator();
    } else {
      createAuthenticator();
    }
  }, [showActivateView, activateAuthenticator, createAuthenticator]);

  React.useEffect(() => {
    if (showActivateView) {
      setCodeErrorMessage(errorMessage);
    }
  }, [showActivateView, errorMessage, setCodeErrorMessage]);

  return (
    <PortalModuleDialog
      {...otherProps}
      className={classnames("enableServiceMfaDialog", className, classes.container)}
      title={title}
      maxWidth={maxWidth}
      loading={loading}
      accessDenied={accessDenied}
      errorMessage={errorMessage}
      showEmptyView={showEmptyView}
      successMessage={successMessage}
      confirm={onClickContinue}
      {...!showActivateView ? ({}) : ({
        continueButtonLabel: "Enable MFA",
        continueButtonDisabled: isEmptyString(code) || !isEmptyString(codeErrorMessage),
      })}
    >
      <div className={classnames("content", classes.content)}>
        {!showActivateView && (
          <React.Fragment>
            <label className={classnames("title", classes.title)}>
              Are you sure you want to enable multi-factor authentication?
            </label>
            <label className={classnames("subtitle", classes.subtitle)}>
          <span className={classes.warning}>
            WARNING:
          </span>
              Once multi-factor authentication has been enabled, you will need to provide an
              authenticator code each time you authenticate with this service.
            </label>
            <label className={classnames("subtitle", classes.subtitle)}>
              Additionally, in order to disable multi-factor authentication, you will need to be able
              to provide a valid authenticator code to prove that you have access to the registered
              multi-factor authenticator.
            </label>
          </React.Fragment>
        )}
        {showActivateView && (
          <React.Fragment>
            <QRCodeSVG
              className={classnames("qrCode", classes.qrCode)}
              size={256}
              bgColor={Color.WHITE}
              fgColor={Color.BLACK}
              value={qrCodeValue}
            />
            <div className={classnames("exampleApps", classes.exampleApps)}>
              <Typography className={classnames("exampleAppsTitle", classes.exampleAppsTitle)} variant="h3">
                Multi-factor Authentication Applications
              </Typography>
              <label className={classnames("subtitle", classes.subtitle)}>
                If you are not familiar with multi-factor authentication, we suggest using
                Microsoft Authenticator, which can be found at the links below.
              </label>
              <div className={classnames("exampleAppsContent", classes.exampleAppsContent)}>
                <a
                  className={classnames("exampleApp", classes.exampleApp)}
                  href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                  target="_blank"
                >
                  <AppleIcon className={classnames("exampleAppIcon", classes.exampleAppIcon)} />
                  <label className={classnames("exampleAppName", classes.exampleAppName)}>
                    Apple Store
                  </label>
                </a>
                <a
                  className={classnames("exampleApp", classes.exampleApp)}
                  href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_US&gl=US&pli=1"
                  target="_blank"
                >
                  <GoogleIcon className={classnames("exampleAppIcon", classes.exampleAppIcon)} />
                  <label className={classnames("exampleAppName", classes.exampleAppName)}>
                    Google Store
                  </label>
                </a>
              </div>
            </div>
            <TextField
              className={classnames("mfaCode", classes.textField, classes.mfaCode)}
              size="small"
              name="mfaCode"
              color="secondary"
              variant="outlined"
              label="Authenticator Code"
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              disabled={loading}
              error={!isEmptyString(codeErrorMessage)}
              helperText={codeErrorMessage || "Scan the QR code above and enter the next one-time token to continue"}
              value={code}
              onChange={formEventHandler(updateCode)}
              onKeyDown={enterKeyHandler(onClickContinue)}
            />
          </React.Fragment>
        )}
        <Backdrop open={showBackdrop} />
      </div>
      {children}
    </PortalModuleDialog>
  );
});

export default EnableServiceMfaDialog;
