import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    downloadButton: {
      margin: "16px 0",
    },
    button : {
      fontSize: 13,
      lineHeight: "16px",
      "& > span": {
        fontSize: 13,
        lineHeight: "16px",
      },
    },
  });

export default styles;
