import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { PrimaryIcon } from "../components/styles";
import { isDataWorkloadsOneDotTwoDotTwoFeaturesEnabled } from "@util";
import { SummaryViewData, WorkloadInstance, WorkloadInstanceState } from "@data";
import { useGetWorkload, useGetWorkloadInstance, useGetWorkloadInstanceLogs } from "@hooks";
import WorkloadInstanceSummaryView from "../components/SummaryView";
import LogsView from "../components/LogsView";
import {
  ContinueButton,
  DetailsView,
  DetailsViewActions,
  DetailsViewModel,
  DetailsViewRoute,
  CompletionMetadataView,
  WorkloadCancelExecutionDialog,
} from "@components";

interface ContainerModel extends DetailsViewModel {
  name: string;
  version: number;
  executionId: string;
  cancelExecutionEnabled?: boolean;
}

interface ContainerActions extends DetailsViewActions {
}

type Props = ContainerModel & ContainerActions;

const WorkloadInstanceDetailsContainer = (props: Props) => {

  const {
    name,
    version,
    executionId,
    cancelExecutionEnabled = isDataWorkloadsOneDotTwoDotTwoFeaturesEnabled(),
    ...otherProps
  } = props;

  const [showCancelDialog, setShowCancelDialog] = React.useState(false);

  const [model, {refresh: refreshInstance}] =
    useGetWorkloadInstance({name, version, executionId});

  const [logModel, {refresh: refreshLogs, ...logActions}] =
    useGetWorkloadInstanceLogs({name, version, executionId});

  const [{ workload, ...workloadModel }, { refresh: refreshWorkload }] =
    useGetWorkload({ name, version });

  const refresh = React.useCallback(() => {
    refreshInstance();
    refreshLogs();
    refreshWorkload();
  }, [refreshInstance, refreshLogs, refreshWorkload]);

  const {workloadInstance = WorkloadInstance.EMPTY, ...otherModel} = model;

  const summaryViewItems = React.useMemo(() => [
    new SummaryViewData({
      className: "executionId",
      name: "Execution ID",
      value: workloadInstance.getExecutionId(),
    }),
    new SummaryViewData({
      className: "workloadName",
      name: "Workload Name",
      value: workloadInstance.getName(),
    }),
    new SummaryViewData({
      className: "createdBy",
      name: "Created By",
      value: workloadInstance.getCreatedBy(),
    }),
    new SummaryViewData({
      className: "executedBy",
      name: "Executed By",
      value: workloadInstance.getExecutedBy(),
    }),
    new SummaryViewData({
      className: "message",
      name: "Message",
      value: workloadInstance.getMessage(),
    }),
    new SummaryViewData({
      className: "state",
      name: "State",
      value: workloadInstance.getState(),
    }),
    new SummaryViewData({
      className: "executionDuration",
      name: "Execution Duration (ms)",
      value: workloadInstance.getExecutionDuration() > 0 ? workloadInstance.getExecutionDuration().toString() : "",
    }),
    new SummaryViewData({
      className: "createdAt",
      name: "Created At",
      value: workloadInstance.getState() === WorkloadInstanceState.QUEUED ? workloadInstance.getCreatedAt() : "",
      date: true,
    }),
    new SummaryViewData({
      className: "startedAt",
      name: "Started At",
      value: workloadInstance.getState() === WorkloadInstanceState.RUNNING ? workloadInstance.getSubmittedAt() : "",
      date: true,
    }),
    new SummaryViewData({
      className: "completedAt",
      name: "Completed At",
      value: workloadInstance.getCompletedAt(),
      date: true,
    }),
  ], [workloadInstance]);

  const autoRefreshEnabled = React.useMemo(() =>
    workloadInstance.getState() === WorkloadInstanceState.QUEUED ||
    workloadInstance.getState() === WorkloadInstanceState.RUNNING
    , [workloadInstance]);

  const SummaryView = React.useMemo(() => () =>
      (
        <WorkloadInstanceSummaryView
          summaryViewItems={summaryViewItems}
        />
      )
    , [summaryViewItems]);

  const logsView = React.useMemo<() => React.ReactElement>(() => () => {
    return <LogsView {...logModel} {...logActions}/>;
  }, [logModel, logActions]);

  const completionMetadataView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <CompletionMetadataView
        {...workloadModel}
        workloadName={name}
        version={version}
        etag={workload.getEtag()}
        refreshDetails={refreshWorkload}
        isDraftWorkload={workload.isDraft()}
        actions={workload.getWorkloadCompletionMetadataActions()}
        variables={workload.getWorkloadCompletionMetadataVariableSchemas()}
      />
    );
  }, [
    name,
    version,
    workload,
    workloadModel,
    refreshWorkload,
  ]);

  const routes: DetailsViewRoute[] = React.useMemo(() => [
    {
      id: "summary",
      name: "Summary",
      view: SummaryView,
    },
    {
      id: "completionMetadata",
      name: "Completion Metadata",
      path: "/completion-metadata",
      view: completionMetadataView,
    },
    {
      id: "logs",
      name: "Logs",
      path: "/logs",
      view: logsView,
      hidden: workloadInstance.isQueryType(),
    },
  ], [workloadInstance, SummaryView, logsView, completionMetadataView]);

  const workloadInstanceIsQueuedOrPending = React.useMemo(() =>
      workloadInstance.getState() === WorkloadInstanceState.QUEUED ||
      workloadInstance.getState() === WorkloadInstanceState.PENDING,
    [workloadInstance]);

  const cancelButton = React.useMemo(() => {
    if (!cancelExecutionEnabled) {
      return null;
    }
    // ContinueButton is wrapped in a div because the Tooltip component does not work with
    // potentially disabled elements because they do not fire events
    return (
      <Tooltip title="Cancel queued or pending execution">
        <div>
          <ContinueButton
            label="Cancel Execution"
            disabled={!workloadInstanceIsQueuedOrPending}
            onClick={() => setShowCancelDialog(true)}
          />
        </div>
      </Tooltip>
    );
  }, [cancelExecutionEnabled, workloadInstanceIsQueuedOrPending, setShowCancelDialog]);

  return (
    <React.Fragment>
      <DetailsView
        {...otherModel}
        {...otherProps}
        refresh={refresh}
        className={"workloadInstanceDetails"}
        icon={PrimaryIcon}
        title={workloadInstance.getName() + " " + workloadInstance.getCreatedAt()}
        routes={routes}
        tabsMarginTop={false}
        autoRefresh={autoRefreshEnabled}
        headerButtons={cancelButton}
      />
      {showCancelDialog && (
        <WorkloadCancelExecutionDialog
          open={showCancelDialog}
          name={name}
          version={version}
          executionId={executionId}
          close={() => setShowCancelDialog(false)}
          onSuccessMessageShown={refreshInstance}
        />
      )}
    </React.Fragment>
  );
};

export default WorkloadInstanceDetailsContainer;
