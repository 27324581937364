import { useApiRequestAnalytics } from "@hooks";
import {
  ListQualityReportsResponse,
  PortalQualityReportsClient,
} from "@network";
import {
  QualityReportAttributes,
  QualityReportPlatformDomainId,
} from "@data/QualityReport";

export type ListQualityReportsApi = (createdBefore?: string,
                                     createdAfter?: string,
                                     next?: string,
                                     limit?: number) => Promise<ListQualityReportsResponse>;

export type ListPlatformDomainQualityReportsApi = (platformDomainId: QualityReportPlatformDomainId,
                                                   createdBefore?: string,
                                                   createdAfter?: string,
                                                   next?: string,
                                                   limit?: number) => Promise<ListQualityReportsResponse>;

export type ListPlatformDomainGroupQualityReportsApi = (platformDomainId: QualityReportPlatformDomainId,
                                                        groupId: string,
                                                        createdBefore?: string,
                                                        createdAfter?: string,
                                                        next?: string,
                                                        limit?: number) => Promise<ListQualityReportsResponse>;

export type GetQualityReportApi = (platformDomainId: QualityReportPlatformDomainId,
                                   groupId: string,
                                   version?: string | "latest") => Promise<QualityReportAttributes>;

export interface PortalQualityReportsClientApi {
  listQualityReports: ListQualityReportsApi;
  listPlatformDomainQualityReports: ListPlatformDomainQualityReportsApi;
  listPlatformDomainGroupQualityReports: ListPlatformDomainGroupQualityReportsApi;
  getQualityReport: GetQualityReportApi;
}

enum ActionTypes {
  LIST_QUALITY_REPORTS_REQUEST = "portal_quality_reports_client/list_quality_reports_request",
  LIST_QUALITY_REPORTS_SUCCESS = "portal_quality_reports_client/list_quality_reports_success",
  LIST_QUALITY_REPORTS_FAILED = "portal_quality_reports_client/list_quality_reports_failed",

  LIST_PLATFORM_DOMAIN_QUALITY_REPORTS_REQUEST = "portal_quality_reports_client/list_platform_domain_quality_reports_request",
  LIST_PLATFORM_DOMAIN_QUALITY_REPORTS_SUCCESS = "portal_quality_reports_client/list_platform_domain_quality_reports_success",
  LIST_PLATFORM_DOMAIN_QUALITY_REPORTS_FAILED = "portal_quality_reports_client/list_platform_domain_quality_reports_failed",

  LIST_PLATFORM_DOMAIN_GROUP_QUALITY_REPORTS_REQUEST = "portal_quality_reports_client/list_platform_domain_group_quality_reports_request",
  LIST_PLATFORM_DOMAIN_GROUP_QUALITY_REPORTS_SUCCESS = "portal_quality_reports_client/list_platform_domain_group_quality_reports_success",
  LIST_PLATFORM_DOMAIN_GROUP_QUALITY_REPORTS_FAILED = "portal_quality_reports_client/list_platform_domain_group_quality_reports_failed",

  GET_QUALITY_REPORT_REQUEST = "portal_quality_reports_client/get_quality_report_request",
  GET_QUALITY_REPORT_SUCCESS = "portal_quality_reports_client/get_quality_report_success",
  GET_QUALITY_REPORT_FAILED = "portal_quality_reports_client/get_quality_report_failed",
}

const useListQualityReportsApi = () =>
  useApiRequestAnalytics(PortalQualityReportsClient.listQualityReports, {
    REQUEST_EVENT: ActionTypes.LIST_QUALITY_REPORTS_REQUEST,
    SUCCESS_EVENT: ActionTypes.LIST_QUALITY_REPORTS_SUCCESS,
    ERROR_EVENT: ActionTypes.LIST_QUALITY_REPORTS_FAILED,
  });

const useListPlatformDomainQualityReportsApi = () =>
  useApiRequestAnalytics(PortalQualityReportsClient.listPlatformDomainQualityReports, {
    REQUEST_EVENT: ActionTypes.LIST_PLATFORM_DOMAIN_QUALITY_REPORTS_REQUEST,
    SUCCESS_EVENT: ActionTypes.LIST_PLATFORM_DOMAIN_QUALITY_REPORTS_SUCCESS,
    ERROR_EVENT: ActionTypes.LIST_PLATFORM_DOMAIN_QUALITY_REPORTS_FAILED,
  });

const useListPlatformDomainGroupQualityReportsApi = () =>
  useApiRequestAnalytics(PortalQualityReportsClient.listPlatformDomainGroupQualityReports, {
    REQUEST_EVENT: ActionTypes.LIST_PLATFORM_DOMAIN_GROUP_QUALITY_REPORTS_REQUEST,
    SUCCESS_EVENT: ActionTypes.LIST_PLATFORM_DOMAIN_GROUP_QUALITY_REPORTS_SUCCESS,
    ERROR_EVENT: ActionTypes.LIST_PLATFORM_DOMAIN_GROUP_QUALITY_REPORTS_FAILED,
  });

const useGetQualityReportApi = () =>
  useApiRequestAnalytics(PortalQualityReportsClient.getQualityReport, {
    REQUEST_EVENT: ActionTypes.GET_QUALITY_REPORT_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_QUALITY_REPORT_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_QUALITY_REPORT_FAILED,
  });

export const usePortalQualityReportsClient = (): PortalQualityReportsClientApi => ({
  listQualityReports: useListQualityReportsApi(),
  listPlatformDomainQualityReports: useListPlatformDomainQualityReportsApi(),
  listPlatformDomainGroupQualityReports: useListPlatformDomainGroupQualityReportsApi(),
  getQualityReport: useGetQualityReportApi(),
});

export { ActionTypes as PortalQualityReportsClientActionType };

export default usePortalQualityReportsClient;
