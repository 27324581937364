import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import {
  reloadUiPackageFiles,
  closeUiDialog,
  deleteUiPackageFailed,
  deleteUiPackageRequest,
  deleteUiPackageSuccess,
  setShowDeleteUiPackageDialog,
  setShowUploadUiPackageDialog,
  uploadUiPackageFailed,
  uploadUiPackageRequest,
  uploadUiPackageSuccess,
} from "../actions";
import {
  getNumSearchResults,
  isLoadingIndicatorVisible,
  isShowDeleteUiPackageDialogVisible,
  isShowUploadUiPackageDialogVisible,
  getActionMenuItems,
  isNotFoundVisible,
  isAccessDeniedVisible,
} from "../selectors";
import Files , { Actions, Model } from "../components/Files";
import FilesList from "./FilesList";
import React from "react";
import { AccessDeniedView, DeleteUiPackage, NotFoundView, UploadUiPackage } from "@components";

interface ContainerModel extends Model {
  showNotFound?: boolean;
  showAccessDenied?: boolean;
  showDeleteUiDialog?: boolean;
  showUploadUiDialog?: boolean;
}

interface ContainerActions extends Actions {
  closeDialog?: () => void;
  uploadUiRequest?: () => void;
  uploadUiSuccess?: () => void;
  uploadUiFailed?: (error: string) => void;
  deleteUiRequest?: () => void;
  deleteUiSuccess?: () => void;
  deleteUiFailed?: (error: string) => void;
  reloadItems?: () => void;
}

type Props = ContainerModel & ContainerActions;

const FilesContainer = (props: Props) => {

  const {
    uploadUiSuccess,
    uploadUiRequest,
    uploadUiFailed,
    deleteUiRequest,
    deleteUiSuccess,
    deleteUiFailed,
    showNotFound,
    showAccessDenied,
    showDeleteUiDialog,
    showUploadUiDialog,
    closeDialog,
    reloadItems,
    ...otherProps
  } = props;

  if (showNotFound) { return <NotFoundView/>; }

  if (showAccessDenied) { return <AccessDeniedView/>; }

  return (
    <Files {...otherProps}>
      {showUploadUiDialog && (
        <UploadUiPackage
          open={showUploadUiDialog}
          cancel={closeDialog}
          uploadUiPackageRequest={uploadUiRequest}
          uploadUiPackageSuccess={uploadUiSuccess}
          uploadUiPackageFailed={uploadUiFailed}
        />
      )}
      {showDeleteUiDialog && (
        <DeleteUiPackage
          open={showDeleteUiDialog}
          cancel={closeDialog}
          onSuccessMessageShown={closeDialog}
          deleteUiPackageRequest={deleteUiRequest}
          deleteUiPackageSuccess={deleteUiSuccess}
          deleteUiPackageFailed={deleteUiFailed}
        />
      )}
      <FilesList/>
    </Files>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  numItems: getNumSearchResults(state),
  loading: isLoadingIndicatorVisible(state),
  actions: getActionMenuItems(state),
  showNotFound: isNotFoundVisible(state),
  showAccessDenied: isAccessDeniedVisible(state),
  showDeleteUiDialog: isShowDeleteUiPackageDialogVisible(state),
  showUploadUiDialog: isShowUploadUiPackageDialogVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  refresh: () => dispatch(reloadUiPackageFiles()),
  uploadUiPackage: () => dispatch(setShowUploadUiPackageDialog(true)),
  deleteUiPackage: () => dispatch(setShowDeleteUiPackageDialog(true)),
  closeDialog: () => dispatch(closeUiDialog()),
  uploadUiRequest: () => dispatch(uploadUiPackageRequest()),
  uploadUiSuccess: () => dispatch(uploadUiPackageSuccess()),
  uploadUiFailed: (error: string) => dispatch(uploadUiPackageFailed(error)),
  deleteUiRequest: () => dispatch(deleteUiPackageRequest()),
  deleteUiSuccess: () => dispatch(deleteUiPackageSuccess()),
  deleteUiFailed: (error: string) => dispatch(deleteUiPackageFailed(error)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(FilesContainer);
