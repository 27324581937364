import { Theme } from "@material-ui/core/styles/createTheme";
import { createStyles, StyleRules } from "@material-ui/core/styles";

export const formControlLabel: StyleRules<string> = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "16px",
  },
  label: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "16px",
    marginLeft: 8,
  },
};

export const textField: StyleRules<string> = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& input": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      padding: "8px 10px",
      margin: 0,
    },
  },
};

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    inputField: {
      flex: "1 0 auto",
    },
    formHelperTextError: {
      fontSize: 12,
    },
    controls: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 8,
    },
    name: {
      flex: "1 0 auto",
    },
    typeContainer: {
      flex: "0 0 auto",
      justifySelf: "flex-start",
      marginLeft: 12,
    },
    type: {
    },
    requiredContainer: {
      flex: "0 0 auto",
      justifySelf: "flex-end",
      justifyContent: "flex-end",
      marginLeft: 8,
    },
    required: {
      padding: 0,
    },
    description: {
      marginTop: 16,
    },
  });
