import React from "react";
import classnames from "classnames";
import { DeviceEnrollment, EnrollmentStatus } from "@data";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { isEmptyString } from "@util";
import { listItem as styles, ModuleIcon } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DevicesListColumn from "./DevicesListColumn";
import { Link } from "@components";
import { getPathToDevice } from "@modules/deviceDetails/helpers";
import { getPathToDeviceValidationDetails } from "@modules/deviceValidationDetails/helpers";

type StyleProps = WithStyles<typeof styles>;

export const DeviceAttributeColumn = withStyles(styles)((props: StyleProps & {
  className?: string,
  label?: string,
  icon?: React.ComponentType<SvgIconProps>,
  path?: string,
}) => {

  const { classes, className, label = "", icon: Icon, path = "" } = props;

  if (!label) {
    return null;
  }

  if (!Icon) {
    return (
      <label className={classnames(className, classes.label)}>
        {label}
      </label>
    );
  }

  return (
    <div className={classnames(className, classes.container)}>
      <Link href={path}>
        <Icon className={classes.icon} />
        <label className={classes.label}>{label}</label>
      </Link>
    </div>
  );
});

export const DevicesListItem = withStyles(styles)((props: StyleProps & {
  device?: DeviceEnrollment,
  column?: DevicesListColumn,
}) => {

  const {
    device = DeviceEnrollment.EMPTY,
    column,
  } = props;

  const deviceIdLabel: string = React.useMemo(() => {
    if (isEmptyString(device.getDeviceId())) {
      if (device.status === EnrollmentStatus.ENROLLMENT_PENDING) {
        return "Processing...";
      } else {
        return "Device ID not found";
      }
    } else {
      return device.getDeviceId();
    }
  }, [device]);

  if (!column || DeviceEnrollment.EMPTY.equals(device)) {
    return null;
  }

  const path = React.useMemo(() => {
    return device.status !== EnrollmentStatus.VALIDATED ? getPathToDevice(device.getDeviceId()) :
      getPathToDeviceValidationDetails(device.getDeviceId());
  }, [device]);

  switch (column) {
    case DevicesListColumn.DEVICE_ID:
      return (
        <DeviceAttributeColumn
          className="deviceId"
          icon={ModuleIcon}
          label={deviceIdLabel}
          path={path}
        />
      );
    case DevicesListColumn.STATUS:
      return (
        <DeviceAttributeColumn
          className="status"
          label={device.status}
        />
      );
    case DevicesListColumn.STATUS_MESSAGE:
      return (
        <DeviceAttributeColumn
          className={classnames("statusMessage")}
          label={device.statusMessage}
        />
      );
    case DevicesListColumn.UPDATED:
      return (
        <DeviceAttributeColumn
          className="updated"
          label={device.updatedBy}
        />
      );
    default:
      return null;
  }
});

export default DevicesListItem;
