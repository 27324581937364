import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    filtersMenu: {
      marginLeft: 15,
    },
    appliedFilters: {
      marginTop: 15,
      marginLeft: 4,
      paddingBottom: 20,
      borderBottom: `1px solid ${Color.LIGHT_GREY}`,
      "& + table.list": {
        marginTop: 0,
      },
      "& .searchFilters": {
        marginTop: 13,
        flexFlow: "row nowrap",
        alignItems: "center",
        "& .searchFilter": {
          marginTop: 0,
          marginLeft: 8,
          "&:first-child": {
            marginLeft: 0,
          },
        },
      },
    },
    list: {
    },
  });

export default styles;
