import React from "react";
import classnames from "classnames";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { deviceType as styles } from "./styles";
import DeviceTypeListColumn from "@components/device-types-list/DeviceTypeListColumn";
import { DeviceTypeListItem } from "@data/DeviceTypeListItem";
import { DeviceTypesList } from "@components";

export const DEFAULT_COLUMNS: DeviceTypeListColumn[] = [
  DeviceTypeListColumn.NAMESPACE,
  DeviceTypeListColumn.NAME,
  DeviceTypeListColumn.VERSION
];

export interface Model {
  className?: string;
  title?: string;
  deviceTypeIdentities?: string[];
  children?: React.ReactNode;
}

export interface Actions {
  showDeviceTypeDetailsInNewTab?: (deviceType: DeviceTypeListItem) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DeviceTypeList = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    deviceTypeIdentities = [],
    title = "Device Type",
    children,
    showDeviceTypeDetailsInNewTab
  } = props;

  const deviceTypes = React.useMemo(() => deviceTypeIdentities.map(identity =>
    DeviceTypeListItem.fromNameAndVersion(identity)), [deviceTypeIdentities]);

  return (
    <div className={classnames("deviceTypeList", className, classes.container)}>
      <label className={classnames("title", classes.title)}>{title}</label>
      <DeviceTypesList
        deviceTypes={deviceTypes}
        hideSearch={true}
        hideSummary={true}
        columns={DEFAULT_COLUMNS}
        onClickShowMoreInfo={showDeviceTypeDetailsInNewTab}
        clickable={false}
        showMoreInfoLabel="Device Type Details"
      />
      {children}
    </div>
  );
});

export default DeviceTypeList;
