import React from "react";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import VariablesListColumn from "./VariablesListColumn";
import { equalsIgnoreCase, isEmptyString, noop } from "@util";
import WorkloadVariableSchema from "@data/WorkloadVariableSchema";
import SortedSearchResultsList, {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import VariablesListItem from "./VariablesListItem";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

type Item = WorkloadVariableSchema;
type Column = VariablesListColumn;

const DEFAULT_COLUMNS: Column[] = [
  VariablesListColumn.NAME,
  VariablesListColumn.DESCRIPTION,
  VariablesListColumn.REQUIRED,
  VariablesListColumn.ACTIONS,
];

export interface VariablesListModel extends SortedSearchResultsListModel<Item, Column> {
  className?: string;
  title?: string;
  emptyViewEnabled?: boolean;
  editModeEnabled?: boolean;
  showDeleteButton?: boolean;
}

export interface VariablesListActions extends SortedSearchResultsListActions<Item, Column> {
  editVariables?: (updatedVariables: WorkloadVariableSchema[]) => void;
  customDelete?: (variable: WorkloadVariableSchema) => void;
}

type Model = VariablesListModel;
type Actions = VariablesListActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const VariablesList = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    listClassName,
    editModeEnabled,
    columns = DEFAULT_COLUMNS.filter(column =>
      column !== VariablesListColumn.ACTIONS || editModeEnabled),
    items = [],
    tableLayoutFixed = true,
    sortingDisabled = true,
    showSearch = false,
    showSummary = false,
    title = "Variables",
    noResultsLabel = "No custom variables defined",
    header = React.useMemo(() => isEmptyString(title) ? null : (
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
    ), [classes, title]),
    emptyViewEnabled,
    showDeleteButton = false,
    editVariables = noop,
    customDelete = noop,
    renderItem = (item: Item, column: Column) => (
      <VariablesListItem
        item={item}
        column={column}
        editItem={(updatedItem: WorkloadVariableSchema) => {
          const index = items.findIndex((variable: WorkloadVariableSchema) =>
            equalsIgnoreCase(variable.getName(), item.getName()));
          if (index >= 0) {
            const updatedItems = items.slice();
            updatedItems.splice(index, 1, updatedItem);
            editVariables(updatedItems);
          }
        }}
      />
    ),
    ...otherProps
  } = props;

  const variableNames = React.useMemo(() => items.map(item => item.getName()), [items]);

  const deleteItem = React.useCallback((item: WorkloadVariableSchema) => {
    const index = variableNames.indexOf(item.getName());
    if (index >= 0) {
      // Create new copy of array
      const updatedItems = items.slice();
      // Mutate updated array (delete entry)
      updatedItems.splice(index, 1);
      // Save changes
      editVariables(updatedItems);
    }
  }, [variableNames, items, editVariables]);

  const onClickDeleteIcon = React.useMemo(() =>
    showDeleteButton ? customDelete === noop ? deleteItem : customDelete : undefined,    
    [showDeleteButton, customDelete, deleteItem]);

  if (items.length === 0 && !emptyViewEnabled) {
    return null;
  }

  return (
    <SortedSearchResultsList
      {...otherProps}
      className={classnames("variablesList", className, classes.container)}
      listClassName={classnames("list", listClassName, classes.list)}
      columns={columns}
      items={items}
      tableLayoutFixed={tableLayoutFixed}
      sortingDisabled={sortingDisabled}
      showSearch={showSearch}
      showSummary={showSummary}
      noResultsLabel={noResultsLabel}
      header={header}
      renderItem={renderItem}
      onClickDeleteIcon={onClickDeleteIcon}
    />
  );
});

export default VariablesList;
