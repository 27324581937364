import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useDataAccessRequestClient
} from "@hooks";
import { DataAccessRequest } from "@data";
import { GetDataAccessRequestsResponse } from "@network";

type SuccessResponse = GetDataAccessRequestsResponse;

export interface UseGetDataAccessRequestsProps extends Partial<UseApiRequestProps<SuccessResponse>> {
}

export interface UseGetDataAccessRequestsModel extends UseApiRequestModel<SuccessResponse> {
  dataAccessRequests: DataAccessRequest[];
}

export interface UseGetDataAccessRequestsActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetDataAccessRequestsProps;
type Model = UseGetDataAccessRequestsModel;
type Actions = UseGetDataAccessRequestsActions;
type Result = [Model, Actions];

export const useGetDataAccessRequests = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to get data access requests",
    ...otherProps
  } = props;

  const DataAccessRequestClient = useDataAccessRequestClient();

  const makeApiRequest = React.useCallback(() =>
      DataAccessRequestClient.getDataAccessRequests(),
    [DataAccessRequestClient]);

  const [{successResponse, ...baseModel}, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const dataAccessRequests = React.useMemo(() => {
    const {accessRequests = []} = successResponse || {};
    return accessRequests.map(attrs => new DataAccessRequest(attrs));
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successResponse,
    dataAccessRequests,
  }), [
    baseModel,
    successResponse,
    dataAccessRequests,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetDataAccessRequests;
