import { AppSchema } from "@schemas";
import { createActions } from "@base/createActions";
import { getAuthToken } from "@main/selectors";
import { getComment, getCommentAttributesArray, getDataAccessRequest } from "./selectors";
import { DataAccessRequestClient, RestClientError } from "@network";
import {
  DataAccessRequest,
  DataAccessRequestAttributes,
  GetRequestCommentsResponse,
  RequestComment,
  RequestCommentAttributes,
} from "@data";
import { open as openDeleteDataAccessRequestDialog } from "@modules/deleteDataAccessRequest/actions";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";

export const {
  dataAccessRequest: setDataAccessRequest,
  comments: setComments,
  comment: setComment,
  createCommentBox: setCreateCommentBox,
  commentLoadingIndicator: setCommentLoadingIndicator,
  commentErrorMessage: setCommentErrorMessage,
  setErrorMessage,
  setSuccessMessage,
  showLoadingIndicator,
  hideLoadingIndicator,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  showNotFound,
  hideNotFound,
  FETCH_DATA_ACCESS_REQUEST_DETAILS_REQUEST: fetchDataAccessRequestDetailsRequest,
  FETCH_DATA_ACCESS_REQUEST_DETAILS_SUCCESS: fetchDataAccessRequestDetailsSuccess,
  FETCH_DATA_ACCESS_REQUEST_DETAILS_FAILURE: fetchDataAccessRequestDetailsFailed,
  FETCH_DATA_ACCESS_REQUEST_COMMENTS_REQUEST: fetchDataAccessRequestCommentsRequest,
  FETCH_DATA_ACCESS_REQUEST_COMMENTS_SUCCESS: fetchDataAccessRequestCommentsSuccess,
  FETCH_DATA_ACCESS_REQUEST_COMMENTS_FAILURE: fetchDataAccessRequestCommentsFailed,
  CREATE_DATA_ACCESS_REQUEST_COMMENT_REQUEST: createDataAccessRequestCommentRequest,
  CREATE_DATA_ACCESS_REQUEST_COMMENT_SUCCESS: createDataAccessRequestCommentSuccess,
  CREATE_DATA_ACCESS_REQUEST_COMMENT_FAILED: createDataAccessRequestCommentFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setDataAccessRequest());
  dispatch(setComments());
  dispatch(setComment());
  dispatch(setCreateCommentBox());
  dispatch(setCommentLoadingIndicator());
  dispatch(setCommentErrorMessage());
  return dispatch(baseReset());
};

export const showCommentLoadingIndicator = () => setCommentLoadingIndicator(true);
export const hideCommentLoadingIndicator = () => setCommentLoadingIndicator(false);

export const resetComments = () => (dispatch: any) => {
  dispatch(setComments());
  dispatch(setCreateCommentBox());
  return dispatch(setCommentErrorMessage());
};

export const addComment = (comments: RequestCommentAttributes[]) => (dispatch: any, getState: () => AppSchema) =>
  dispatch(setComments(getCommentAttributesArray(getState()).concat(comments)));

export const showCreateCommentBox = () => (dispatch: any) => {
  dispatch(setCommentErrorMessage());
  dispatch(setSuccessMessage());
  return dispatch(setCreateCommentBox(true));
};

export const hideCreateCommentBox = () => (dispatch: any) => {
  dispatch(setCommentErrorMessage());
  dispatch(setComment());
  return dispatch(setCreateCommentBox(false));
};

export const deleteDataAccessRequest = () => (dispatch: any, getState: () => AppSchema) => {
  const dataAccessRequest = getDataAccessRequest(getState());
  dispatch(openDeleteDataAccessRequestDialog(
    dataAccessRequest.getAccessRequestId(),
    dataAccessRequest.getDataSetAlias()
  ));
};

export const fetchDataAccessRequestDetails =
  (accessRequestId?: string) => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const authToken = getAuthToken(state);

  const dataAccessRequest = getDataAccessRequest(state);
  if (accessRequestId === undefined) {
    accessRequestId = dataAccessRequest.getAccessRequestId();
  }

  dispatch(showLoadingIndicator());
  dispatch(setErrorMessage());
  dispatch(hideNotFound());
  dispatch(hideAccessDenied());
  dispatch(fetchDataAccessRequestDetailsRequest());

  return DataAccessRequestClient.getDataAccessRequest(authToken, accessRequestId)
    .then((attrs: DataAccessRequestAttributes) => {

      dispatch(fetchDataAccessRequestDetailsSuccess());
      dispatch(setDataAccessRequest(new DataAccessRequest(attrs).toJS()));
      dispatch(hideLoadingIndicator());
      return dispatch(hideEmptyView());

    }, (response: RestClientError) => {

      const { analytic, error = "Fetch data access request details failed" } = response;

      dispatch(fetchDataAccessRequestDetailsFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(hideLoadingIndicator());

      if (response.status === 404) {
        dispatch(showNotFound());
      }

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideEmptyView());
    });
};

export const fetchDataAccessRequestComments = () => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const authToken = getAuthToken(state);
  const dataAccessRequest = getDataAccessRequest(state);
  const accessRequestId = dataAccessRequest.accessRequestId;

  dispatch(setErrorMessage());
  dispatch(hideAccessDenied());
  dispatch(fetchDataAccessRequestDetailsRequest());
  dispatch(showCommentLoadingIndicator());
  dispatch(resetComments());

  return DataAccessRequestClient.getDataAccessRequestComments(authToken, accessRequestId)
    .then((response: GetRequestCommentsResponse) => {
      const comments = response.comments.map(
        (attrs: RequestCommentAttributes) => new RequestComment(attrs).toJS());
      dispatch(setComments(comments));
      dispatch(hideCommentLoadingIndicator());
      return dispatch(fetchDataAccessRequestCommentsSuccess());

    }, (response: RestClientError) => {

      const {analytic, error = "Fetch data set request comments failed"} = response;

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      dispatch(fetchDataAccessRequestCommentsFailed(analytic));
      dispatch(hideCommentLoadingIndicator());
      return dispatch(setCommentErrorMessage(error));
    });
};

export const createDataAccessRequestComment = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const authToken = getAuthToken(state);
  const message = getComment(state);
  const dataAccessRequest = getDataAccessRequest(state);
  const accessRequestId = dataAccessRequest.accessRequestId;

  const json = JSON.stringify({
    message,
  });

  dispatch(createDataAccessRequestCommentRequest());
  dispatch(setSuccessMessage());
  dispatch(showCommentLoadingIndicator());
  dispatch(setCommentErrorMessage());

  return DataAccessRequestClient.createDataAccessRequestComment(authToken, accessRequestId, json)
    .then(() => {

      dispatch(resetComments());
      dispatch(createDataAccessRequestCommentSuccess());
      dispatch(hideCommentLoadingIndicator());
      dispatch(hideCreateCommentBox());
      dispatch(setSuccessMessage("Comment created"));
      return dispatch(fetchDataAccessRequestComments());
    }, (response: RestClientError) => {

      const { analytic, error = "Failed to add Comment" } = response;

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      dispatch(hideCommentLoadingIndicator());
      dispatch(createDataAccessRequestCommentFailed(analytic));
      return dispatch(setCommentErrorMessage(error));
    });
};

export const refresh = () => (dispatch: any) => {

  dispatch(fetchDataAccessRequestDetails());
  return dispatch(fetchDataAccessRequestComments());
};

export const initialize = (accessRequestId: string) => (dispatch: any) => {

  dispatch(reset());
  dispatch(setDataAccessRequest(new DataAccessRequest({ accessRequestId: accessRequestId }).toJS()));
  return dispatch(refresh());
};
