import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ContinueButton, DeviceDataType, JsonEditor } from "@components";
import { dataEditor as styles } from "./styles";
import { Header } from "@components/schema-validation-view/Header";
import { clickHandler, isEmptyString, noop } from "@util";
import { DeviceTypeModelV3MetadataMode } from "@data";

export interface Model {
  className?: string;
  schemaIdentity?: string;
  mode?: string;
  json?: string;
  readOnly?: boolean;
  showLoadingIndicator?: boolean;
  invalidJsonError?: boolean;
  dataMode?: DeviceDataType;
}

export interface Actions {
  setJson?: (json: string) => void;
  editData?: () => void;
  deleteData?: () => void;
  cancelEdit?: () => void;
  confirmEdit?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DataEditor = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    schemaIdentity,
    mode = "",
    json,
    showLoadingIndicator,
    readOnly =  true,
    invalidJsonError = false,
    dataMode,
    setJson = noop,
    editData = noop,
    deleteData = noop,
    cancelEdit = noop,
    confirmEdit = noop,
  } = props;

  const showControlButtons = React.useMemo(() =>
    !isEmptyString(schemaIdentity) && dataMode !== DeviceDataType.ACTUAL,
    [schemaIdentity, dataMode]);

  const title = React.useMemo(() => {
    if (isEmptyString(schemaIdentity) && !invalidJsonError) {
      return "Data";
    } else if (!invalidJsonError &&  dataMode === DeviceDataType.METADATA) {
      return `${schemaIdentity} : ${mode}`;
    } else if (!invalidJsonError) {
      return schemaIdentity;
    } else {
      return `${schemaIdentity} - Invalid JSON`;
    }
  }, [schemaIdentity, invalidJsonError, mode, dataMode]);

  const buttonsDisabled = React.useMemo(() =>
    mode === DeviceTypeModelV3MetadataMode.READ_ONLY
  , [mode]);

  return (
    <React.Fragment>
      <div className={classnames("dataEditor", className, classes.container, classes.editMode)}>
        <div className={classnames("actions", classes.controls)}>
          {readOnly && showControlButtons && (
            <div>
              <ContinueButton
                className={classnames("editData", classes.editButton)}
                label="Edit"
                onClick={clickHandler(editData)}
                primary={false}
                disabled={showLoadingIndicator || buttonsDisabled}
              />
              <ContinueButton
                className={classnames("deleteData", classes.deleteButton)}
                label="Delete"
                onClick={clickHandler(deleteData)}
                primary={false}
                disabled={showLoadingIndicator || buttonsDisabled}
              />
            </div>
          )}
          {!readOnly && showControlButtons && (
            <div>
              <ContinueButton
                className={classnames("cancelEdit", classes.cancelButton)}
                label="Cancel"
                onClick={clickHandler(cancelEdit)}
                primary={false}
                disabled={showLoadingIndicator || buttonsDisabled}
              />
              <ContinueButton
                className={classnames("confirmEdit", classes.editConfirmButton)}
                label="Save"
                onClick={clickHandler(confirmEdit)}
                primary={true}
                disabled={invalidJsonError || showLoadingIndicator || buttonsDisabled}
              />
            </div>
          )}
        </div>
        <Header
          className={classes.header}
          title={title}
          showLoadingIndicator={showLoadingIndicator}
          showErrorMessage={invalidJsonError}
        />
        <JsonEditor
          className={classnames("dataJsonEditor", classes.dataJsonEditor)}
          json={json}
          readOnly={readOnly}
          width="auto"
          height="300px"
          fontSize={14}
          setJson={setJson}
          highlightActiveLine={false}
          debounceChangePeriod={0}
          aceOptions={{
            highlightGutterLine: false,
            showLineNumbers: false,
            showGutter: false,
          }}
        />
      </div>
      {!readOnly && <div className={classnames("editModeModal", classes.editModeModal)} />}
    </React.Fragment>
  );
});

export default DataEditor;
