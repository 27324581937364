import React from "react";
import { ReleaseNotePlatformDomain, ReleaseStatus } from "@data/ReleaseNote";
import { ReleaseNotesTab } from "../components/ReleaseNotesTabs";
import useListReleaseNotes from "@hooks/portalReleaseNotes/use-list-release-notes";
import ReleaseNotes, { ReleaseNotesActions, ReleaseNotesModel } from "../components/ReleaseNotes";
import { getArchiveNotesLink } from "../helpers";
import { SearchFilter } from "@data";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";

const DEFAULT_RELEASE_STATES = [
  ReleaseStatus.PLANNED,
  ReleaseStatus.IN_PROGRESS,
  ReleaseStatus.COMPLETED,
  ReleaseStatus.POSTPONED
];

interface ReleaseNotesContainerModel extends ReleaseNotesModel {
  selectedTab?: ReleaseNotesTab;
  platformDomain?: ReleaseNotePlatformDomain;
}

interface ReleaseNotesContainerActions extends ReleaseNotesActions {
}

type Model = ReleaseNotesContainerModel;
type Actions = ReleaseNotesContainerActions;
type Props = Model & Actions;

const ReleaseNotesContainer = (props: Props) => {

  const {
    selectedTab = ReleaseNotesTab.SELF_SERVICE_TOOLS,
    platformDomain = ReleaseNotePlatformDomain.SELF_SERVICE_TOOLS,
    summaryViewLabel = `${selectedTab} Release Notes`,
    ...otherProps
  } = props;

  const [releaseStateFilter, setReleaseStateFilter] = React.useState(ReleaseStatus.NONE);

  const filters = DEFAULT_RELEASE_STATES.map(value => new SearchFilter({
    key: value,
    value: value
  }));

  const onClickFilter = React.useCallback((filter: SearchFilter) => {
    if (releaseStateFilter === filter.getKey()) {
      return setReleaseStateFilter(ReleaseStatus.NONE);
    }

    return setReleaseStateFilter(ReleaseStatus[filter.getKey()]);
  }, [setReleaseStateFilter, releaseStateFilter]);

  const mapFilterToLabel = (filter: SearchFilter) => {
    return startCase(toLower(filter.getKey()));
  };

  const selectedFilter = React.useMemo(() =>
    [releaseStateFilter], [releaseStateFilter]);

  const [model, actions] = useListReleaseNotes({ platformDomain, releaseStatus: releaseStateFilter });

  return (
    <ReleaseNotes
      {...otherProps}
      {...model}
      {...actions}
      summaryViewLabel={summaryViewLabel}
      filters={filters}
      mapFilterToLabel={mapFilterToLabel}
      onClickFilter={onClickFilter}
      selectedFilters={selectedFilter}
      archiveLink={getArchiveNotesLink(platformDomain)}
    />
  );
};

export default ReleaseNotesContainer;
