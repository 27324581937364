import React from "react";
import classnames from "classnames";
import Button from "@components/button";
import ErrorView from "@components/error-view";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { clickHandler, isEmptyString, noop } from "@util";
import NotFoundError from "@components/error-view/NotFoundView";
import AccessDeniedError from "@components/error-view/AccessDeniedView";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface VersionHistoryModel {
  className?: string;
  title?: string;
  showLoadingIndicator?: boolean;
  loadingLabel?: string;
  showLoadMoreButton?: boolean;
  loadMoreLabel?: string;
  showAccessDeniedError?: boolean;
  showNotFoundError?: boolean;
  errorMessageTitle?: string;
  errorMessage?: string;
  errors?: string[];
  statusCode?: number;
  listItems?: JSX.Element[];
}

export interface VersionHistoryActions {
  loadMore?: () => void;
}

type Model = VersionHistoryModel;
type Actions = VersionHistoryActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const VersionHistory = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Version History",
    showLoadingIndicator,
    loadingLabel = "Loading Version History...",
    showLoadMoreButton,
    loadMoreLabel = "Load More Versions",
    showAccessDeniedError,
    showNotFoundError,
    errorMessageTitle = "Failed to load version history",
    errorMessage,
    errors = [],
    statusCode,
    listItems = [],
    loadMore = noop,
    children,
  } = props;

  const showErrorView = React.useMemo(() =>
    !showLoadingIndicator && (!isEmptyString(errorMessage) || showAccessDeniedError || showNotFoundError),
    [showLoadingIndicator, errorMessage, showAccessDeniedError, showNotFoundError]);

  const showEmptyView = React.useMemo(() =>
      !showLoadingIndicator && listItems.length === 0,
    [showLoadingIndicator, listItems]);

  return (
    <div className={classnames("versionHistory", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        <Typography className={classnames("title", classes.title)} variant="h3">
          {title}
        </Typography>
      </div>
      <div className={classnames("list", classes.list)}>
        {showErrorView && (
          <React.Fragment>
            {showAccessDeniedError && (
              <AccessDeniedError
                className={classnames("accessDeniedError", classes.errorCodeView, classes.accessDeniedError)}
                description="You do not have permission to view the version history for this workload"
              />
            )}
            {showNotFoundError && (
              <NotFoundError
                className={classnames("notFoundError", classes.errorCodeView, classes.notFoundError)}
                description="Version history not found"
              />
            )}
            {!showAccessDeniedError && !showNotFoundError && (
              <ErrorView
                className={classnames("errorView", classes.errorView)}
                title={errorMessageTitle}
                message={errorMessage}
                errors={errors}
                statusCode={statusCode}
              />
            )}
          </React.Fragment>
        )}
        {!showErrorView && (
          <React.Fragment>
            {showLoadingIndicator && (
              <ListItem className={classnames("loadingLabel", classes.loadingLabel)}>
                {loadingLabel}
              </ListItem>
            )}
            {!showLoadingIndicator && showEmptyView && (
              <p className={classnames("emptyView", classes.emptyView)}>
                No Workload Versions Found
              </p>
            )}
            {listItems}
            {showLoadMoreButton && (
              <Button
                className={classnames("loadMoreButton", classes.loadMoreButton)}
                classes={{
                  label: classnames("label", classes.loadMoreButtonLabel),
                }}
                disabled={showLoadingIndicator}
                onClick={clickHandler(loadMore)}
              >
                {loadMoreLabel}
              </Button>
            )}
          </React.Fragment>
        )}
      </div>
      {children}
    </div>
  );
});

export default VersionHistory;
