import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import useDigitalShadowClient from "../use-digital-shadow-client";
import { ETagHeaders } from "@network";

type SuccessResponse = ETagHeaders;

export interface UseCreateDSLayoutProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  requestBody?: string;
}

export interface UseCreateDSLayoutModel extends UseApiRequestModel<SuccessResponse> {
  etag: string;
  successMessage: string;
}

export interface UseCreateDSLayoutActions extends UseApiRequestActions<SuccessResponse> {
  createLayout: () => void;
}

type Props = UseCreateDSLayoutProps;
type Model = UseCreateDSLayoutModel;
type Actions = UseCreateDSLayoutActions;
type Result = [ Model, Actions ];

export const useCreateDSLayout = (props: Props): Result => {

  const {
    requestBody = "",
    defaultErrorMessage = "Failed to create shadow layout",
    ...otherProps
  } = props;

  const DigitalShadowClient = useDigitalShadowClient();

  const makeApiRequest = React.useCallback(() =>
      DigitalShadowClient.createLayoutApi(requestBody),
    [DigitalShadowClient, requestBody]);

  const [{ successResponse, showSuccessView, ...baseModel },
    { refresh: createLayout, ...baseActions }
  ] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Shadow Layout Created" : "",
    [ showSuccessView ]);

  const etag = React.useMemo(() => {
    return successResponse?.etag || "";
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    etag,
    successMessage,
    showSuccessView
  }), [
    baseModel,
    etag,
    successMessage,
    showSuccessView
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    createLayout,
    refresh: createLayout,
  }), [
    baseActions,
    createLayout,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useCreateDSLayout;
