import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useWorkloadManagerClient,
} from "@hooks";
import { GetWorkloadConfigurationResponse, WorkloadConfigurationProperties } from "@network";

type SuccessResponse = GetWorkloadConfigurationResponse;

export interface UseGetWorkloadConfigurationProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
}

export interface UseGetWorkloadConfigurationModel extends UseApiRequestModel<SuccessResponse> {
  properties: WorkloadConfigurationProperties;
  etag: string;
  lastModified: string;
}

export interface UseGetWorkloadConfigurationActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetWorkloadConfigurationProps;
type Model = UseGetWorkloadConfigurationModel;
type Actions = UseGetWorkloadConfigurationActions;
type Result = [ Model, Actions ];

export const useGetWorkloadConfiguration = (props: Props): Result => {

  const {
    name,
    version,
    defaultErrorMessage = "Failed to get workload configuration",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
      WorkloadManagerClient.getWorkloadConfiguration(name, version),
    [ name, version ]);

  const [ { successResponse, errorResponse, ...baseModel }, actions ] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const properties = React.useMemo<WorkloadConfigurationProperties>(() => {
    const { properties: workloadProperties = {} } = successResponse || {};
    return workloadProperties;
  }, [ successResponse ]);

  const etag = React.useMemo<string>(() => {
    const { etag: propertiesEtag = "" } = successResponse || {};
    return propertiesEtag;
  }, [ successResponse ]);

  const lastModified = React.useMemo<string>(() => {
    const { lastModified: propertiesLastModified = "" } = successResponse || {};
    return propertiesLastModified;
  }, [ successResponse ]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    properties,
    etag,
    lastModified,
    errorResponse,
  }), [
    baseModel,
    properties,
    etag,
    lastModified,
    errorResponse,
  ]);

  return React.useMemo<Result>(() => [ model, actions ], [ model, actions ]);
};

export default useGetWorkloadConfiguration;
