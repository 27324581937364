import React from "react";
import classnames from "classnames";
import capitalize from "lodash/capitalize";
import Tab from "@components/tab";
import Tabs from "@components/tabs";
import Close from "@material-ui/icons/Close";
import Error from "@material-ui/icons/Error";
import Snackbar from "@material-ui/core/Snackbar";
import { IconButton } from "@components";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CircularProgress from "@components/circular-progress";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles, closeIcon, errorIcon, signifyLogo } from "./styles";
import { clickHandler, noop, tabEventHandler } from "@util";
import useDocumentTitle from "@hooks/use-document-title";
import { ThemeProvider } from "@components/styles";
import { SignifyLogo } from "@icons";
import { IdentityType } from "@data";
import SupportButton from "./SupportButton";
import TermsDialog from "./TermsDialog";
import theme from "./theme";

const CloseIcon = withStyles(closeIcon)(Close);
const ErrorIcon = withStyles(errorIcon)(Error);
const SignifyLogoIcon = withStyles(signifyLogo)(SignifyLogo);

export const AUTO_HIDE_ERROR_DURATION_MS = 15 * 1000;

export const IDENTITY_TYPES = [IdentityType.USER, IdentityType.SERVICE];

export interface Model {
  errorMessage?: string;
  showErrorMessage?: boolean;
  showTermsDialog?: boolean;
  showProgressIndicator?: boolean;
  identityType?: IdentityType;
  informationBannerVisible?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  openTermsDialog?: () => void;
  closeTermsDialog?: () => void;
  hideErrorMessage?: () => void;
  setIdentityType?: (identityType: IdentityType) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const LoginPage = withStyles(styles)((props: Props) => {

  const {
    classes,
    errorMessage,
    showErrorMessage = false,
    showTermsDialog,
    showProgressIndicator,
    identityType: selectedIdentityType = IdentityType.USER,
    informationBannerVisible,
    children,
    openTermsDialog = noop,
    closeTermsDialog = noop,
    hideErrorMessage = noop,
    setIdentityType = noop,
  } = props;

  // Ensures that the document title is always reset to just the base 'IoT Portal' when logging out
  useDocumentTitle();

  return (
    <ThemeProvider theme={theme}>
      <div
        className={classnames("loginPage", classes.container, {
          [classes.loading]: showProgressIndicator,
          [classes.informationBannerVisible]: informationBannerVisible,
      })}
      >
        <div className={classnames("content", classes.content, { [classes.blur ]: showProgressIndicator })}>
          <SignifyLogoIcon />
          <Tabs
            className={classnames("tabs", classes.tabs)}
            classes={{ indicator: classes.tabsIndicator }}
            value={selectedIdentityType}
            variant="fullWidth"
            indicatorColor="primary"
            onChange={tabEventHandler(setIdentityType)}
          >
            {IDENTITY_TYPES.map((identityType: IdentityType) => (
              <Tab
                key={`tab-${identityType}`}
                className={classnames("tab", identityType.toLowerCase(), classes.tab)}
                classes={{ selected: classes.selectedTab }}
                value={identityType}
                label={capitalize(identityType)}
                selected={identityType === selectedIdentityType}
              />
            ))}
          </Tabs>
          {children}
          <SupportButton
            className={classnames("termsOfServiceLink", classes.termsOfServiceLink)}
            label="Please review our"
            buttonLabel="Terms of Use"
            onClick={openTermsDialog}
          />
          <SupportButton
            className={classnames("forgotPasswordLink", classes.forgotPasswordLink)}
            label="For login help contact"
            buttonLabel="IOT_CUSTOMER_SUPPORT@signify.com"
            href="mailto:IOT_CUSTOMER_SUPPORT@signify.com?subject=Login&nbsp;help&body="
          />
        </div>
        <Snackbar
          className={classnames("errorSnackbar", classes.errorSnackbar)}
          autoHideDuration={AUTO_HIDE_ERROR_DURATION_MS}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={showErrorMessage}
          onClose={hideErrorMessage}
        >
          <SnackbarContent
            className={classnames("errorSnackbarContent", classes.errorSnackbarContent)}
            aria-describedby="error-snackbar-content-message"
            message={(
              <span
                id="error-snackbar-content-message"
                className={classnames("errorSnackbarContentMessage", classes.errorSnackbarContentMessage)}
              >
                <ErrorIcon />
                {errorMessage}
              </span>
            )}
            action={[
              (
                <IconButton
                  className="errorSnackbarCloseButton"
                  key="close"
                  color="inherit"
                  aria-label="Close"
                  onClick={clickHandler(hideErrorMessage)}
                >
                  <CloseIcon />
                </IconButton>
              ),
            ]}
          />
        </Snackbar>
        {showTermsDialog && <TermsDialog close={closeTermsDialog} />}
        {showProgressIndicator && (
          <CircularProgress
            className={classnames("progressIndicator", classes.progressIndicator)}
            color="primary"
            size={80}
            thickness={5}
          />
        )}
      </div>
    </ThemeProvider>
  );
});

export default LoginPage;
