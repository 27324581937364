import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  PortalModuleActionTypes,
  createPortalModuleDefaultState,
} from "@base/createReducers";

export interface ApplicationRegionalDetailsSchema extends BasePortalModuleSchema {
  applicationId: string;
}

export const DEFAULT_STATE: ApplicationRegionalDetailsSchema = createPortalModuleDefaultState({
  applicationId: "",
});

type ApplicationRegionalDetailsActionTypes = PortalModuleActionTypes<ApplicationRegionalDetailsSchema>;

export const ACTION_TYPES: ApplicationRegionalDetailsActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<ApplicationRegionalDetailsSchema>(MODULE_ID, DEFAULT_STATE),
});

export const ApplicationRegionalDetailsReducer = combineReducers<ApplicationRegionalDetailsSchema>(
  createPortalModuleReducers<ApplicationRegionalDetailsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default ApplicationRegionalDetailsReducer;
