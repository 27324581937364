import { Record } from "immutable";

export enum SignInPreference {
  ALLOW_SSO = "ALLOW_SSO",
  ALWAYS_AUTHENTICATE = "ALWAYS_AUTHENTICATE",
}

export enum SignOutPreference {
  SIGN_OUT_FROM_APP = "SIGN_OUT_FROM_APP",
  SIGN_OUT_GLOBALLY = "SIGN_OUT_GLOBALLY",
}

export interface ApplicationUserManagementAttributes {
  signInPreference: SignInPreference;
  signOutPreference: SignOutPreference;
}

export class ApplicationUserManagement extends Record({
  signInPreference: SignInPreference.ALWAYS_AUTHENTICATE,
  signOutPreference: SignOutPreference.SIGN_OUT_GLOBALLY,
}) implements ApplicationUserManagementAttributes {

  public static EMPTY: ApplicationUserManagement = new ApplicationUserManagement();

  public readonly signInPreference: SignInPreference;
  public readonly signOutPreference: SignOutPreference;
}
