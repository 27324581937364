import React from "react";
import classnames from "classnames";
import { getPluralString } from "@util";
import { reviewView as styles } from "./styles";
import { DataClassification, DataSetType, IngestionMechanism, SummaryViewData } from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { getDataClassificationDisplayName } from "@modules/dataSetRequestWizard/helpers";
import { SummaryView } from "@components";

export interface Model {
  dataSetAlias?: string;
  description?: string;
  dataSetGroup?: string;
  dataSetType?: DataSetType;
  containsPiiData?: string;
  piiComment?: string;
  tags?: string;
  dataOwners?: string;
  architects?: string;
  engineeringContacts?: string;
  businessOwners?: string;
  ingestionMechanism?: IngestionMechanism;
  schema?: string;
  sampleAnonymizedData?: string;
  dataClassification?: DataClassification;
  dataVolume?: number;
  dataVelocity?: number;
  archivingInDays?: number;
  expirationInDays?: number;
  confirmNonProductionData?: string;
  isProductionAccount?: boolean;
  showIngestionMechanism?: boolean;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ReviewView = withStyles(styles)((props: Props) => {

  const {
    classes,
    dataSetAlias,
    description,
    dataSetGroup,
    dataSetType,
    tags,
    dataOwners,
    architects,
    engineeringContacts,
    businessOwners,
    schema,
    sampleAnonymizedData,
    dataClassification,
    ingestionMechanism = IngestionMechanism.NONE,
    dataVolume = 0,
    dataVelocity = 0,
    archivingInDays = 0,
    expirationInDays = 0,
    confirmNonProductionData,
    containsPiiData,
    piiComment,
    showIngestionMechanism,
    isProductionAccount,
    children,
  } = props;

  const archivingInDaysLabel = React.useMemo(() =>
      archivingInDays <= 0
        ? "Data Is Never Archived"
        : `Data Archived After ${getPluralString(archivingInDays, { other: "Days", one: "Day" })}`,
    [archivingInDays]);

  const expirationInDaysLabel = React.useMemo(() =>
      expirationInDays <= 0
        ? "Data Does Not Expire"
        : `Data Expires After ${getPluralString(expirationInDays, { other: "Days", one: "Day" })}`,
    [expirationInDays]);

  const summaryItems = React.useMemo(() => [
    new SummaryViewData({
      className: "dataSetAlias",
      name: "Data Set Name",
      value: dataSetAlias,
    }),
    new SummaryViewData({
      className: "description",
      name: "Description",
      value: description,
    }),
    new SummaryViewData({
      className: "dataSetGroup",
      name: "Data Set Group",
      value: dataSetGroup,
    }),
    new SummaryViewData({
      className: "dataSetType",
      name: "Data Set Type",
      value: dataSetType,
    }),
    new SummaryViewData({
      className: "tags",
      name: "Tags",
      value: tags,
    }),
    new SummaryViewData({
      className: "dataOwners",
      name: "Data Owners",
      value: dataOwners,
    }),
    new SummaryViewData({
      className: "architects",
      name: "Architects",
      value: architects,
    }),
    new SummaryViewData({
      className: "engineeringContacts",
      name: "Engineering Contacts",
      value: engineeringContacts,
    }),
    new SummaryViewData({
      className: "businessOwners",
      name: "Business Owners",
      value: businessOwners,
    }),
    new SummaryViewData({
      className: "schema",
      name: "Schema",
      value: schema,
    }),
    new SummaryViewData({
      className: "sampleAnonymizedData",
      name: "Sample Anonymized Data",
      value: sampleAnonymizedData,
    }),
    new SummaryViewData({
      className: "dataClassification",
      name: "Data Classification",
      value: getDataClassificationDisplayName(dataClassification),
    }),
    new SummaryViewData({
      className: "ingestionMechanism",
      name: "Ingestion Mechanism",
      value: showIngestionMechanism ? ingestionMechanism : "",
    }),
    new SummaryViewData({
      className: "containsPii",
      name: "Contains PII Data",
      value: containsPiiData,
    }),
    new SummaryViewData({
      className: "piiComment",
      name: "PII Data Description",
      value: containsPiiData ? piiComment : "",
    }),
    new SummaryViewData({
      className: "dataVolume",
      name: "Data Volume (MB Per Month)",
      value: dataVolume.toString(),
    }),
    new SummaryViewData({
      className: "dataVelocity",
      name: "Data Velocity (Bytes Per Second)",
      value: dataVelocity.toString(),
    }),
    new SummaryViewData({
      className: "confirmNonProductionData",
      name: "Confirm Non-Production Data",
      value: isProductionAccount ? "" : confirmNonProductionData,
    }),
    new SummaryViewData({
      className: "archivingInDays",
      name: "Data Archival Policy",
      value: isProductionAccount ? archivingInDaysLabel : "",
    }),
    new SummaryViewData({
      className: "expirationInDays",
      name: "Data Expiration Policy",
      value: isProductionAccount ? expirationInDaysLabel : "",
    }),
  ], [
    dataSetAlias,
    description,
    dataSetGroup,
    dataSetType,
    tags,
    dataOwners,
    architects,
    engineeringContacts,
    businessOwners,
    schema,
    sampleAnonymizedData,
    containsPiiData,
    piiComment,
    dataClassification,
    showIngestionMechanism,
    ingestionMechanism,
    dataVolume,
    dataVelocity,
    isProductionAccount,
    confirmNonProductionData,
    archivingInDaysLabel,
    expirationInDaysLabel,
  ]);

  return (
    <div className={classnames("reviewView", classes.container)}>
      <SummaryView items={summaryItems} title="Review Data Set Request"/>
      {children}
    </div>
  );
});

export default ReviewView;
