import { AppSchema } from "@schemas";
import { createActions } from "@modules/base/createActions";
import { JsonSchemaDefinition, JsonSchemaMetadata } from "@data";
import { ETagLocationHeaders, RestClientError, SchemaRegistryClient } from "@network";
import { updateItem as updateSchemasModuleStore } from "@modules/schemas/actions";
import { SchemaWizardSelectors } from "../../selectors/schemaWizard";
import { PropertiesTableSelectors } from "../../selectors/propertiesTable";
import { EditModeActions } from "../../actions/editMode";
import { PropertiesTableActions } from "../../actions/propertiesTable";
import {
  ACTION_TYPES,
  DEFAULT_STATE,
  SchemaEditorViewMode,
  SchemaWizardStep,
} from "../../reducers/schemaWizard";

export const {
  createdSchema: setCreatedSchemaAttributes,
  namespace: setNamespace,
  json: setJson,
  schema: setSchema,
  schemaWizardStep: setSchemaWizardStep,
  schemaEditorViewMode: setSchemaEditorViewMode,
  defaultState: setDefaultStateAttributes,
  propertiesTable: setPropertiesTableAttributes,
  showPropertyEditor: setShowPropertyEditor,
  propertyEditor: setPropertyEditorAttributes,
  showRemoveProperty: setShowRemoveProperty,
  removeProperty: setRemovePropertyAttributes,
  showEditMode: setShowEditMode,
  editMode: setEditModeAttributes,
  setErrorMessage,
  setSuccessMessage,
  showLoadingIndicator,
  hideLoadingIndicator,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  SAVE_SCHEMA: saveSchema,
  SAVE_SCHEMA_SUCCESS: saveSchemaSuccess,
  SAVE_SCHEMA_FAILED: saveSchemaFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setCreatedSchemaAttributes());
  dispatch(setNamespace());
  dispatch(setJson());
  dispatch(setSchema());
  dispatch(setSchemaWizardStep());
  dispatch(setSchemaEditorViewMode());
  dispatch(setDefaultStateAttributes());
  dispatch(setPropertiesTableAttributes());
  dispatch(setShowPropertyEditor());
  dispatch(setPropertyEditorAttributes());
  dispatch(setShowRemoveProperty());
  dispatch(setRemovePropertyAttributes());
  dispatch(setShowEditMode());
  dispatch(setEditModeAttributes());
  return dispatch(baseReset());
};

export const showNamespaceView = () => setSchemaWizardStep(SchemaWizardStep.NAMESPACE);
export const showNameView = () => setSchemaWizardStep(SchemaWizardStep.NAME);
export const showSchemaEditorView = () => setSchemaWizardStep(SchemaWizardStep.SCHEMA);
export const showReviewView = () => setSchemaWizardStep(SchemaWizardStep.REVIEW);

export const showSummaryEditorView = () => setSchemaEditorViewMode(SchemaEditorViewMode.SUMMARY);
export const showJsonEditorView = () => setSchemaEditorViewMode(SchemaEditorViewMode.JSON);

export const showPropertyEditor = () => setShowPropertyEditor(true);
export const hidePropertyEditor = () => setShowPropertyEditor(false);

export const showRemoveProperty = () => setShowRemoveProperty(true);
export const hideRemoveProperty = () => setShowRemoveProperty(false);

export const updateJson = (json: string = DEFAULT_STATE.json) => (dispatch: any) => {

  dispatch(setJson(json));

  try {

    const schema = JSON.parse(json);

    dispatch(setErrorMessage());

    return dispatch(setSchema(schema));

  } catch (e) {
    return dispatch(setErrorMessage("Failed to parse schema. " +
      "Please verify that the JSON is valid before continuing."));
  }
};

export const updateSchema = (schema: JsonSchemaDefinition = DEFAULT_STATE.schema) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    // Do not overwrite schema from visual editor if edits are being made in json editor
    if (!SchemaWizardSelectors.isJsonValid(state)) {
      return dispatch(setErrorMessage("Failed to parse schema. " +
        "Please verify that the JSON is valid before continuing."));
    }

    dispatch(setErrorMessage());

    dispatch(setJson(JSON.stringify(schema, null, "  ")));

    return dispatch(setSchema(schema));
  };

export const updateNamespace = (namespace: string = "") => (dispatch: any) => {
  dispatch(setErrorMessage());
  return dispatch(setNamespace(namespace));
};

export const setTitle = (title: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const schema = SchemaWizardSelectors.getSchema(state);

    return dispatch(updateSchema({
      ...schema,
      title,
    }));
  };

export const setDescription = (description: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const schema = SchemaWizardSelectors.getSchema(state);

    return dispatch(updateSchema({
      ...schema,
      description,
    }));
  };

export const showNextStep = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  // Schema Namespace -> Schema Name
  if (SchemaWizardSelectors.isNamespaceViewSelected(state)) {

    if (!SchemaWizardSelectors.isNamespaceValid(state)) {
      return dispatch(setErrorMessage("Invalid Namespace"));
    }

    return dispatch(showNameView());
  }

  // Schema Name -> Schema Editor
  if (SchemaWizardSelectors.isNameViewSelected(state)) {

    if (!SchemaWizardSelectors.isTitleValid(getState())) {
      return dispatch(setErrorMessage("Schema title is required"));
    }

    if (!SchemaWizardSelectors.isDescriptionValid(getState())) {
      return dispatch(setErrorMessage("Schema description is required"));
    }

    return dispatch(showSchemaEditorView());
  }

  // Schema Editor -> Schema Review
  if (!SchemaWizardSelectors.isJsonValid(state)) {
    return dispatch(setErrorMessage("Failed to parse schema. " +
      "Please verify that the JSON is valid before continuing."));
  }

  if (!SchemaWizardSelectors.isTitleValid(getState())) {
    return dispatch(setErrorMessage("Schema title is required"));
  }

  if (!SchemaWizardSelectors.isDescriptionValid(getState())) {
    return dispatch(setErrorMessage("Schema description is required"));
  }

  dispatch(PropertiesTableActions.clearBreadcrumbs());

  return dispatch(showReviewView());
};

export const showPreviousStep = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const isFirstStepActive = SchemaWizardSelectors.isFirstStepActive(state);

  if (isFirstStepActive) {
    return Promise.resolve();
  }

  // Schema Name -> Schema Namespace
  if (SchemaWizardSelectors.isNameViewSelected(state)) {
    return dispatch(showNamespaceView());
  }

  // Schema Editor -> Schema Name
  if (SchemaWizardSelectors.isSchemaEditorViewSelected(state)) {

    if (!SchemaWizardSelectors.isJsonValid(state)) {
      return dispatch(setErrorMessage("Failed to parse schema. " +
        "Please verify that the JSON is valid before continuing."));
    }

    if (PropertiesTableSelectors.isViewingChildProperty(state)) {
      return dispatch(PropertiesTableActions.goBack());
    }

    if (!SchemaWizardSelectors.isTitleValid(state)) {
      dispatch(setErrorMessage("Schema title is required"));
    } else if (!SchemaWizardSelectors.isDescriptionValid(state)) {
      dispatch(setErrorMessage("Schema description is required"));
    }

    return dispatch(showNameView());
  }

  // Schema Review -> Schema Editor
  return dispatch(showSchemaEditorView());
};

export const save = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  if (SchemaWizardSelectors.isEditModeActive(state)) {
    return dispatch(EditModeActions.save());
  }

  if (!SchemaWizardSelectors.isTitleValid(state)) {
    return dispatch(setErrorMessage("Schema title is required"));
  }

  if (!SchemaWizardSelectors.isDescriptionValid(state)) {
    return dispatch(setErrorMessage("Schema description is required"));
  }

  const accessToken = SchemaWizardSelectors.getAccessToken(state);
  const namespace = SchemaWizardSelectors.getNamespace(state);
  const name = SchemaWizardSelectors.getTitle(state);
  const json = SchemaWizardSelectors.getJson(state);

  const jsonSchema = new JsonSchemaMetadata({
    description: SchemaWizardSelectors.getDescription(state),
    namespace,
    name,
  });

  dispatch(showLoadingIndicator());
  dispatch(setErrorMessage());
  dispatch(saveSchema());

  return SchemaRegistryClient.createSchema(accessToken, namespace, name, json)
    .then((response: ETagLocationHeaders) => {

      const { etag = "", version = 0 } = response;

      const updatedSchema = new JsonSchemaMetadata({
        ...jsonSchema.toJS(),
        ...(etag.length === 0 ? {} : { etag }),
        ...(version <= 0 ? {} : { version }),
      });

      dispatch(saveSchemaSuccess());
      dispatch(setCreatedSchemaAttributes(updatedSchema.toJS()));
      dispatch(updateSchemasModuleStore(updatedSchema.toJS()));
      dispatch(hideLoadingIndicator());
      return dispatch(setSuccessMessage("Schema Created"));

    }, (response: RestClientError) => {

      const { analytic, error = "Failed to create schema" } = response;
      dispatch(saveSchemaFailed(analytic));
      dispatch(hideLoadingIndicator());
      return dispatch(setErrorMessage(error));
    });
};

export const initialize = (originalMetaData: JsonSchemaMetadata,
                           originalSchema: JsonSchemaDefinition,
                           editMode?: boolean) => (dispatch: any) => {

  if (!editMode) {
    return dispatch(reset());
  }

  return dispatch(EditModeActions.initialize(originalMetaData, originalSchema));
};
