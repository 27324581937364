import React from "react";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  useOrchestrationServiceClient,
} from "@hooks";

export interface UseUpdateUserMfaRequirementProps extends Partial<UseApiRequestProps<void>> {
  userId: string;
  json: string;
}

export interface UseUpdateUserMfaRequirementModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UseUpdateUserMfaRequirementActions {
  updateMfaRequirement: () => void;
}

type Props = UseUpdateUserMfaRequirementProps;
type Model = UseUpdateUserMfaRequirementModel;
type Actions = UseUpdateUserMfaRequirementActions;
type Result = [Model, Actions];

export const useUpdateUserMfaRequirement = (props: Props): Result => {

  const {
    userId = "",
    json,
    defaultErrorMessage = "Failed to update Mfa Requirement for user",
    ...otherProps
  } = props;

  const OrchestrationServiceClient = useOrchestrationServiceClient();

  const [{ showSuccessView, ...baseModel }, { refresh: updateMfaRequirement }] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => OrchestrationServiceClient.updateUserMfaRequirement(userId, json),
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Update Successful" : "" ,
    [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    updateMfaRequirement,
  }), [
    updateMfaRequirement,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useUpdateUserMfaRequirement;
