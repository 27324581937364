import React from "react";
import classnames from "classnames";
import { JsonEditor } from "@components";
import { jsonEditorView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface Model {
  attributes?: string;
  children?: React.ReactNode;
}

export interface Actions {
  setAttributes?: (value: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const AttributesView = withStyles(styles)((props: Props) => {

  const { classes, attributes, children, setAttributes } = props;

  return (
    <div className={classnames("attributesView", classes.container)}>
      <label className={classnames("title", classes.title)}>
        Set Federation Provider Attributes
      </label>
      <JsonEditor
        className={classnames("attributes", classes.jsonEditor)}
        name="attributes"
        json={attributes}
        setJson={setAttributes}
      />
      {children}
    </div>
  );
});

export default AttributesView;
