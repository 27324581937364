import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base/createReducers";

export interface CreatePolicySchema extends BasePortalModuleSchema {
  json: string;
  policyRef: string;
  policyName: string;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<CreatePolicySchema>({
  showEmptyView: false,
  json: "",
  policyRef: "",
  policyName: "",
});

type CreatePolicyActionTypes = PortalModuleActionTypes<CreatePolicySchema> & {
  CREATE_POLICY_REQUEST: string;
  CREATE_POLICY_SUCCESS: string;
  CREATE_POLICY_FAILED: string;
};

export const ACTION_TYPES: CreatePolicyActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<CreatePolicySchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    CREATE_POLICY_REQUEST: `${MODULE_ID}/create_policy_request`,
    CREATE_POLICY_SUCCESS: `${MODULE_ID}/create_policy_success`,
    CREATE_POLICY_FAILED: `${MODULE_ID}/create_policy_failed`,
  },
});

export const CreatePolicyReducer = combineReducers<CreatePolicySchema>(
  createPortalModuleReducers<CreatePolicySchema>(ACTION_TYPES, DEFAULT_STATE));

export default CreatePolicyReducer;
