import React from "react";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useWorkloadManagerClient } from "@hooks";

export interface UseUploadWorkloadCodeProps extends Partial<UseApiRequestProps<void>> {
  uploadUrl: string;
  file: File;
}

export interface UseUploadWorkloadCodeModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UseUploadWorkloadCodeActions {
  uploadCode: () => void;
  reset: () => void;
}

type Props = UseUploadWorkloadCodeProps;
type Model = UseUploadWorkloadCodeModel;
type Actions = UseUploadWorkloadCodeActions;
type Result = [Model, Actions];

export const useUploadWorkloadCode = (props: Props): Result => {

  const {
    uploadUrl = "",
    file,
    defaultErrorMessage = "Failed to upload workload code",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.uploadWorkloadCodeApi(
      uploadUrl,
      file
    ), [WorkloadManagerClient, uploadUrl, file]);

  const [{ showSuccessView, ...baseModel }, { refresh: uploadCode, reset }] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Upload Successful" : "" ,
    [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    uploadCode,
    reset
  }), [uploadCode, reset]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useUploadWorkloadCode;
