import createStyles from "@material-ui/core/styles/createStyles";
import { Color } from "@components/styles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      fontSize: 24,
      lineHeight: "28px",
      marginTop: 32,
    },
    errorView: {
      margin: "25px 0 0",
    },
  });
