import {
  makeApiRequestAndComplete,
  withApplicationIdHeader,
  withAuthToken,
  withRequiredArguments,
} from "@network/helpers";
import { isEmptyString } from "@util";

const DASHBOARD_SECURITY_SERVICE = process.env.REACT_APP_DASHBOARD_SECURITY_SERVICE || "";

if (isEmptyString(DASHBOARD_SECURITY_SERVICE)) {
  throw new Error("Missing Environment Variable: REACT_APP_DASHBOARD_SECURITY_SERVICE");
}

export const publishDashboard = (authToken?: string, dashboardId?: number): Promise<void> => {

  const validate = () => withAuthToken(authToken)
      .then(() => withRequiredArguments([
        ["Dashboard ID", dashboardId + ""],
      ]));

  const makeRequest = () => {

    const url = `${DASHBOARD_SECURITY_SERVICE}/bdi/dmg/superset-security-manager/v1/dashboards/publish`;

    const json = JSON.stringify({
      id: dashboardId
    });

    const settings = {
      method: "POST",
      headers: withApplicationIdHeader({
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      }),
        body: json,
    };

    const defaultErrorMessage = "Failed to publish the dashboard";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const shareDashboard = (authToken?: string,
                               dashboardId?: number,
                               userId?: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["Dashboard ID", dashboardId + ""],
    ]));

  const makeRequest = () => {

    const url = `${DASHBOARD_SECURITY_SERVICE}/bdi/dmg/superset-security-manager/v1/dashboards/share`;

    const json = JSON.stringify({
      "id": dashboardId,
      "userIds": userId?.split(",").map((item) => item.trim()).filter((item) => !!item)
    });

    const settings = {
      method: "POST",
      headers: withApplicationIdHeader({
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      }),
      body: json,
    };

    const defaultErrorMessage = "Failed to share the dashboard";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
