import React from "react";
import classnames from "classnames";
import { servicesView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import RegionalServicesList, {
  RegionalServicesListActions,
  RegionalServicesListModel,
} from "@components/regional-services-list";
import { ServicesListColumn } from "@components";

export interface ServicesViewModel extends RegionalServicesListModel {
}

export interface ServicesViewActions extends RegionalServicesListActions {
}

type Model = ServicesViewModel;
type Actions = ServicesViewActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const ServicesView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    columns = [ServicesListColumn.ID],
    summaryViewLabel = "Services that belong to this group",
    ...otherProps
  } = props;

  return (
    <RegionalServicesList
      {...otherProps}
      className={classnames("servicesView", classes.container)}
      showSummary={true}
      columns={columns}
      summaryViewLabel={summaryViewLabel}
      showSearch={false}
      showIcon={false}
      showRefreshButton={false}
    />
  );
});

export default ServicesView;
