import { connect } from "react-redux";
import { AppSchema } from "../../main/schemas";
import { PropertyEditorSelectors } from "../selectors/propertyEditor";
import { NumberConstraintsActions } from "../actions/numberConstraints";
import { NumberConstraintsSelectors } from "../selectors/numberConstraints";
import { NumericConstraintsEditor, Model, Actions } from "../components/ConstraintsEditor/NumericConstraintsEditor";
import { PropertyEditorActions } from "@modules/schemaWizard/actions/propertyEditor";

const mapStateToProps = (state: AppSchema): Model => ({
  minimum: NumberConstraintsSelectors.getMinimum(state),
  minimumError: NumberConstraintsSelectors.getMinimumError(state),
  exclusiveMinimum: NumberConstraintsSelectors.getExclusiveMinimum(state),
  exclusiveMinimumError: NumberConstraintsSelectors.getExclusiveMinimumError(state),
  maximum: NumberConstraintsSelectors.getMaximum(state),
  maximumError: NumberConstraintsSelectors.getMaximumError(state),
  exclusiveMaximum: NumberConstraintsSelectors.getExclusiveMaximum(state),
  exclusiveMaximumError: NumberConstraintsSelectors.getExclusiveMaximumError(state),
  multipleOf: NumberConstraintsSelectors.getMultipleOf(state),
  multipleOfError: NumberConstraintsSelectors.getMultipleOfError(state),
  disabled: PropertyEditorSelectors.isProgressIndicatorVisible(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  setMinimum: (value: string) => dispatch(NumberConstraintsActions.updateMinimum(value)),
  setExclusiveMinimum: (value: string) => dispatch(NumberConstraintsActions.updateExclusiveMinimum(value)),
  setMaximum: (value: string) => dispatch(NumberConstraintsActions.updateMaximum(value)),
  setExclusiveMaximum: (value: string) => dispatch(NumberConstraintsActions.updateExclusiveMaximum(value)),
  setMultipleOf: (value: string) => dispatch(NumberConstraintsActions.updateMultipleOf(value)),
  save: () => dispatch(PropertyEditorActions.save()),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(NumericConstraintsEditor);
