import React from "react";
import { noop } from "@util";
import useGetAllVersionsOfWorkload from "@hooks/workloadsManager/use-get-all-versions-of-workload";
import VersionHistory, {
  VersionHistoryModel,
} from "../components/VersionHistory";
import { Workload, WorkloadState } from "@data";
import VersionHistoryListItem from "./VersionHistoryListItem";

export interface VersionHistoryContainerModel extends VersionHistoryModel {
  name: string;
  version: number;
}

export interface VersionHistoryContainerActions {
  showWorkloadVersionDetails?: (version: number) => void;
  releaseWorkload?: (workload: Workload) => void;
  deprecateWorkload?: (workload: Workload) => void;
  decommissionWorkload?: (workload: Workload) => void;
  deleteWorkloadVersion?: (workload: Workload) => void;
  switchWorkloadPrincipal?: (workload: Workload) => void;
  draftNewWorkloadVersion?: (workload: Workload, latest: boolean) => void;
}

type Model = VersionHistoryContainerModel;
type Actions = VersionHistoryContainerActions;
type Props = Model & Actions;

const VersionHistoryContainer = (props: Props) => {

  const {
    name,
    version: activeVersion,
    releaseWorkload = noop,
    deprecateWorkload = noop,
    decommissionWorkload = noop,
    deleteWorkloadVersion = noop,
    draftNewWorkloadVersion = noop,
    switchWorkloadPrincipal = noop,
    showWorkloadVersionDetails = noop,
    ...otherProps
  } = props;

  const [{ versions, ...model }, actions] = useGetAllVersionsOfWorkload({ name });

  const validVersions = React.useMemo<Workload[]>(() => versions
      .filter(workload => workload.getVersion() > 0 && workload.getState() !== WorkloadState.NONE),
    [versions]);

  const listItems = validVersions.map(workload => {
      const version = workload.getVersion();
      return (
        <VersionHistoryListItem
          key={`${workload.getName()}:${version}`}
          workload={workload}
          isActiveVersion={version > 0 && (activeVersion === version)}
          version={version}
          releaseWorkload={releaseWorkload}
          deprecateWorkload={deprecateWorkload}
          decommissionWorkload={decommissionWorkload}
          deleteWorkloadVersion={deleteWorkloadVersion}
          draftNewWorkloadVersion={draftNewWorkloadVersion}
          switchWorkloadPrincipal={switchWorkloadPrincipal}
          showWorkloadVersionDetails={showWorkloadVersionDetails}
        />
      );
    });

  return (
    <VersionHistory
      {...model}
      {...actions}
      {...otherProps}
      listItems={listItems}
    />
  );
};

export default VersionHistoryContainer;
