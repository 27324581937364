import { DataApprovalRequestClient, GetDataApprovalRequestsResponse } from "@network";
import useApiRequestAnalytics from "@hooks/use-api-request-analytics";

export type GetDataApprovalRequestsApi = () => Promise<GetDataApprovalRequestsResponse>;

export interface DataApprovalRequestClientApi {
  getDataApprovalRequests: GetDataApprovalRequestsApi;
}

enum ActionType {
  GET_DATA_APPROVAL_REQUESTS_REQUEST = "data_approval_request_client/get_data_approval_requests_request",
  GET_DATA_APPROVAL_REQUESTS_SUCCESS = "data_approval_request_client/get_data_approval_requests_success",
  GET_DATA_APPROVAL_REQUESTS_FAILED = "data_approval_request_client/get_data_approval_requests_failed",
}

const useGetDataApprovalRequests = (): GetDataApprovalRequestsApi =>
  useApiRequestAnalytics(DataApprovalRequestClient.getDataApprovalRequests, {
    REQUEST_EVENT: ActionType.GET_DATA_APPROVAL_REQUESTS_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DATA_APPROVAL_REQUESTS_SUCCESS,
    ERROR_EVENT: ActionType.GET_DATA_APPROVAL_REQUESTS_FAILED,
  });

export const useDataApprovalRequestClient = (): DataApprovalRequestClientApi => ({
  getDataApprovalRequests: useGetDataApprovalRequests(),
});

export { ActionType as DataApprovalRequestClientActionType };

export default useDataApprovalRequestClient;
