import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
    },
    filtersMenuIconButton: {
      width: 20,
      height: 20,
    },
    filtersMenuIcon: {
      width: 14,
      height: 14,
    },
    filters: {
    },
  });

export default styles;
