import React from "react";
import { FederationProvider } from "@data";
import { AuthTokenContext, FederationProviderListColumn } from "@components";
import { createComparator, getSearchResults } from "@components/federation-providers-list/helpers";
import { RestClientError, FederationProviderClient, GetFederationProvidersResponse } from "@network";

export interface UseFederationProvidersModel {
  federationProviders: FederationProvider[];
  nameFilter: string;
  errorMessage: string;
  sortOrderAscending: boolean;
  sortByColumn: FederationProviderListColumn;
  showSearch: boolean;
  showLoadingIndicator: boolean;
  totalNumFederationProviders: number;
  totalNumVisibleFederationProviders: number;
}

export interface UseFederationProvidersActions {
  refresh: () => void;
  setNameFilter: (nameFilter: string) => void;
  setSortByColumn: (column: FederationProviderListColumn) => void;
  setSortOrderAscending: (ascending: boolean) => void;
}

type Model = UseFederationProvidersModel;
type Actions = UseFederationProvidersActions;
type GetFederationProvidersApi = (authToken: string) =>
  Promise<GetFederationProvidersResponse>;

export const sortAndFilterFederationProviderResults = (props: {
  items: FederationProvider[],
  nameFilter: string,
  sortOrderAscending: boolean,
  sortByColumn: FederationProviderListColumn,
}) => {

  const { items, nameFilter, sortOrderAscending, sortByColumn } = props;

  const comparator = React.useMemo(() =>
    createComparator(sortByColumn, sortOrderAscending), [sortByColumn, sortOrderAscending]);

  const sortedFederationProviders = React.useMemo(() => items.sort(comparator).slice(), [items, comparator]);

  return React.useMemo(() =>
    getSearchResults(sortedFederationProviders, nameFilter), [sortedFederationProviders, nameFilter]);
};

export const useFederationProviders = (getFederationProviders: GetFederationProvidersApi): [Model, Actions] => {

  const authToken = React.useContext(AuthTokenContext);
  const [items, setItems] = React.useState<FederationProvider[]>([]);
  const [nameFilter, setNameFilter] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);
  const [sortOrderAscending, setSortOrderAscending] = React.useState(true);
  const [sortByColumn, setSortByColumn] = React.useState(FederationProviderListColumn.NONE);
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);

  const fetchFederationProviders = React.useCallback(() =>
      getFederationProviders(authToken),
    [authToken]);

  React.useEffect(() => {

    let ignore = false;

    setShowLoadingIndicator(true);
    setErrorMessage("");

    fetchFederationProviders()
      .then((response: GetFederationProvidersResponse) => {
        if (!ignore) {
          const { providers: results = [] } = response;
          setItems(g => g.concat(results.map(attrs => new FederationProvider(attrs))));
          setShowLoadingIndicator(false);
        }
      }, (response: RestClientError) => {
        if (!ignore) {
          const { error = "Fetch federation providers failed" } = response;
          setErrorMessage(error);
          setShowLoadingIndicator(false);
        }
      });

    return () => { ignore = true; };

  }, [lastRefresh, setItems, setErrorMessage, setShowLoadingIndicator]);

  const refresh = React.useCallback(() => {
    setItems([]);
    setSortByColumn(FederationProviderListColumn.NONE);
    setSortOrderAscending(true);
    setLastRefresh(+new Date);
  }, [setItems, setSortByColumn, setSortOrderAscending, setLastRefresh]);

  const federationProviders =
    sortAndFilterFederationProviderResults({ items, nameFilter, sortOrderAscending, sortByColumn });

  const totalNumFederationProviders = React.useMemo(() => items.length, [items]);

  const totalNumVisibleFederationProviders = React.useMemo(() => federationProviders.length, [federationProviders]);

  const showSearch = React.useMemo(() => totalNumFederationProviders > 1, [totalNumFederationProviders]);

  const model: Model = {
    federationProviders,
    nameFilter,
    errorMessage,
    sortOrderAscending,
    sortByColumn,
    showSearch,
    showLoadingIndicator,
    totalNumFederationProviders,
    totalNumVisibleFederationProviders,
  };

  const actions: Actions = {
    refresh,
    setNameFilter,
    setSortByColumn,
    setSortOrderAscending,
  };

  return [model, actions];
};

export const useAllFederationProviders = (): [Model, Actions] => {

  const getAllFederationProviders: GetFederationProvidersApi = (authToken: string) =>
    FederationProviderClient.getFederationProviders(authToken);

  return useFederationProviders(getAllFederationProviders);
};
