import createStyles from "@material-ui/core/styles/createStyles";
import { Color } from "@components";

export const styles = () =>
  createStyles({
    content: {
      textAlign: "center",
    },
    label: {
      margin: 8,
      padding: 0,
      lineHeight: "18px",
      fontSize: 16,
      color: Color.TEXT,
    },
  });

export default styles;
