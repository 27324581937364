import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import { Tab } from "@components/tab";
import { Tabs as TabsComponent } from "@components/tabs";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles, { tabs } from "./styles";

const Tabs = withStyles(tabs)(TabsComponent);

export enum DataCatalogTabType {
  DATA_SETS = "data_sets",
  DATA_SET_REQUESTS = "data_set_requests",
  DATA_ACCESS_REQUESTS = "data_access_requests",
  DATA_APPROVAL_REQUESTS = "data_approval_requests",
}

export interface DataCatalogTabsModel {
  className?: string;
  selectedTab?: DataCatalogTabType;
  dataSetsLabel?: string;
  dataSetRequestsLabel?: string;
  dataAccessRequestsLabel?: string;
  dataApprovalRequestsLabel?: string;
  pendingDataSetRequests?: number;
  pendingDataAccessRequests?: number;
  pendingDataApprovalRequests?: number;
}

export interface DataCatalogTabsActions {
  showDataSetsView?: () => void;
  showDataSetRequestsView?: () => void;
  showDataAccessRequestsView?: () => void;
  showDataApprovalRequestsView?: () => void;
}

type Props = WithStyles<typeof styles> & DataCatalogTabsModel & DataCatalogTabsActions;

export const DataCatalogTabs = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    selectedTab = DataCatalogTabType.DATA_SETS,
    dataSetsLabel = "Data Sets",
    dataSetRequestsLabel = "My Data Set Requests",
    dataAccessRequestsLabel = "My Data Access Requests",
    dataApprovalRequestsLabel = "Awaiting My Approval",
    pendingDataSetRequests,
    pendingDataAccessRequests,
    pendingDataApprovalRequests,
    showDataSetsView = noop,
    showDataSetRequestsView = noop,
    showDataAccessRequestsView = noop,
    showDataApprovalRequestsView = noop,
  } = props;

  const dataSetRequestsTabLabel = React.useMemo(() =>
      pendingDataSetRequests === undefined
        ? dataSetRequestsLabel
        : `${dataSetRequestsLabel} (${pendingDataSetRequests})`,
    [dataSetRequestsLabel, pendingDataSetRequests]);

  const dataAccessRequestsTabLabel = React.useMemo(() =>
      pendingDataAccessRequests === undefined
        ? dataAccessRequestsLabel
        : `${dataAccessRequestsLabel} (${pendingDataAccessRequests})`,
    [dataAccessRequestsLabel, pendingDataAccessRequests]);

  const dataApprovalRequestsTabLabel = React.useMemo(() =>
      pendingDataApprovalRequests === undefined
        ? dataApprovalRequestsLabel
        : `${dataApprovalRequestsLabel} (${pendingDataApprovalRequests})`,
    [dataApprovalRequestsLabel, pendingDataAccessRequests]);

  const setSelectedTab = React.useCallback((tab: DataCatalogTabType) => {
    if (selectedTab !== tab) {
      switch (tab) {
        case DataCatalogTabType.DATA_SET_REQUESTS:
          return showDataSetRequestsView();
        case DataCatalogTabType.DATA_ACCESS_REQUESTS:
          return showDataAccessRequestsView();
        case DataCatalogTabType.DATA_APPROVAL_REQUESTS:
          return showDataApprovalRequestsView();
        case DataCatalogTabType.DATA_SETS:
        default:
          return showDataSetsView();
      }
    }
  }, [
    selectedTab,
    showDataSetRequestsView,
    showDataAccessRequestsView,
    showDataApprovalRequestsView,
    showDataSetsView,
  ]);

  return (
    <Tabs
      className={classnames("dataCatalogTabs", className, classes.container)}
      value={selectedTab}
      onChange={(event, value) => {
        if (value) {
          setSelectedTab(value);
        }
      }}
    >
      <Tab
        className={classnames("dataSetsTab", classes.tab)}
        label={dataSetsLabel}
        value={DataCatalogTabType.DATA_SETS}
      />
      <Tab
        className={classnames("dataSetRequestsTab", classes.tab)}
        label={dataSetRequestsTabLabel}
        value={DataCatalogTabType.DATA_SET_REQUESTS}
      />
      <Tab
        className={classnames("dataAccessRequestsTab", classes.tab)}
        label={dataAccessRequestsTabLabel}
        value={DataCatalogTabType.DATA_ACCESS_REQUESTS}
      />
      <Tab
        className={classnames("dataApprovalRequestsTab", classes.tab)}
        label={dataApprovalRequestsTabLabel}
        value={DataCatalogTabType.DATA_APPROVAL_REQUESTS}
      />
    </Tabs>
  );
});

export default DataCatalogTabs;
