import { Record } from "immutable";
import { formatUtcDateToLocal, getStringValue } from "@util";

export enum LogLevelType {
  ALL = "ALL",
  TRACE = "TRACE",
  DEBUG = "DEBUG",
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
  OFF = "OFF",
  NOT_SPECIFIED = "NOT_SPECIFIED"
}

export interface WorkloadLogItemAttributes {
  logLevel?: LogLevelType;
  logMessage?: string;
  timestamp?: string;
}

export class WorkloadLogItem extends Record({
  logLevel: LogLevelType.ALL,
  logMessage: "",
  timestamp: "",
}) implements WorkloadLogItemAttributes {

  public static EMPTY: WorkloadLogItem = new WorkloadLogItem();

  public readonly logLevel: LogLevelType;
  public readonly logMessage: string;
  public readonly timestamp: string;

  public static from(message: string): WorkloadLogItem {
    return new WorkloadLogItem({
      logLevel: LogLevelType[message.split(" ")[1]] || LogLevelType.NOT_SPECIFIED,
      logMessage: getStringValue(message.split(" ").slice(2).join(" ")),
      timestamp: message.split(" ")[0] || "",
    });
  }

  public getLogLevel(): LogLevelType {
    return this.logLevel;
  }

  public getTimestamp(): string {
    return formatUtcDateToLocal(new Date(parseFloat(this.timestamp)));
  }

  public getMessage(): string {
    return getStringValue(this.logMessage);
  }

}

export default WorkloadLogItem;
