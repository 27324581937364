import React from "react";
import DataLakeBrowser from "./containers/DataLakeBrowser";

export function DataLakeBrowserView() {

  return (
    <DataLakeBrowser
    />
  );
}

export default DataLakeBrowserView;
