import React from "react";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  useApplicationManagerRegionalClient
} from "@hooks";
import { Application, ApplicationAttributes } from "@data/Application";

type SuccessResponse = ApplicationAttributes;

export interface UseCreateApplicationRegionalProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  json: string;
}

export interface UseCreateApplicationRegionalModel extends UseApiRequestModel<SuccessResponse> {
  application: Application;
}

export interface UseCreateApplicationRegionalActions {
  createApplication: () => void;
}

type Props = UseCreateApplicationRegionalProps;
type Model = UseCreateApplicationRegionalModel;
type Actions = UseCreateApplicationRegionalActions;
type Result = [Model, Actions];

export const useCreateApplicationRegional = (props: Props): Result => {

  const {
    json = "",
    defaultErrorMessage = "Failed to create applications",
    ...otherProps
  } = props;

  const ApplicationManagerClient = useApplicationManagerRegionalClient();

  const makeApiRequest = React.useCallback(() =>
      ApplicationManagerClient.createApplicationRegionalApi(json),
    [ApplicationManagerClient]);

  const [{ successResponse, showSuccessView, ...baseModel }, { refresh: createApplication }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const application = React.useMemo<Application>(() => {
    const attrs = successResponse || {};
    return new Application(attrs);
  }, [successResponse]);

  const successMessage = React.useMemo(() =>
      showSuccessView ? `Application Created` : "" ,
    [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    application,
    showSuccessView,
    successMessage,
  }), [
    baseModel,
    application,
    showSuccessView,
    successMessage,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    createApplication
  }), [createApplication]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useCreateApplicationRegional;
