import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { noop } from "@util";
import { styles } from "./styles";
import { DeviceEnrollment } from "@data";
import DevicesListColumn from "./DevicesListColumn";
import DevicesListItem from "./DevicesListItem";
import SortedSearchResultsList, {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel
} from "@components/sorted-search-results-list";
import { sortAndFilterDeviceResults } from "./helpers";

export const DEVICES_DEFAULT_COLUMNS: DevicesListColumn[] = [
  DevicesListColumn.DEVICE_ID,
  DevicesListColumn.STATUS,
  DevicesListColumn.STATUS_MESSAGE,
];

export interface DevicesListModel extends
  SortedSearchResultsListModel<DeviceEnrollment, DevicesListColumn> {
  className?: string;
  devices?: DeviceEnrollment[];
  nameFilter?: string;
  title?: string;
  numResults?: number;
  errorMessage?: string;
  showErrorView?: boolean;
  showProgressIndicator?: boolean;
  showLoadMoreButton?: boolean;
  showNoResultsView?: boolean;
  columns?: DevicesListColumn[];
  selectable?: boolean;
  selectAllDisabled?: boolean;
  selectedItems?: DeviceEnrollment[];
  noResultsLabel?: string;
  loadMoreLabel?: string;
  tableLayoutFixed?: boolean;
}

export interface DevicesListActions extends
  SortedSearchResultsListActions<DeviceEnrollment, DevicesListColumn> {
  loadMore?: () => void;
  clearList?: () => void;
  onClickItem?: (item: DeviceEnrollment) => void;
  setSelectedItems?: (items: DeviceEnrollment[]) => void;
}

type Props = WithStyles<typeof styles> & DevicesListModel & DevicesListActions;

export const DevicesList = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    devices = [],
    nameFilter = "",
    title = "Devices",
    errorMessage,
    sortOrderAscending = true,
    sortByColumn = DevicesListColumn.DEVICE_ID,
    showErrorView,
    showProgressIndicator,
    showLoadMoreButton,
    showNoResultsView,
    columns = DEVICES_DEFAULT_COLUMNS,
    selectable,
    selectAllDisabled,
    selectedItems = [],
    noResultsLabel = "No devices found",
    loadMoreLabel,
    tableLayoutFixed = true,
    loadMore = noop,
    onClickItem = noop,
    setSelectedItems = noop,
    ...otherProps
  } = props;

  const onItemClicked = React.useCallback((device: DeviceEnrollment) => {

    if (!selectable) {
      return onClickItem(device);
    }

    const checked = selectedItems.indexOf(device) >= 0;

    if (checked) {
      return setSelectedItems(selectedItems.filter(item => item !== device));
    } else {
      return setSelectedItems(selectedItems.concat(device));
    }

  }, [selectable, selectedItems, setSelectedItems, onClickItem]);

  const items = sortAndFilterDeviceResults({ devices, nameFilter, sortOrderAscending, sortByColumn });

  return (
    <div className={classnames("devices", className, classes.container)}>
      <h1 className={classnames("devicesTitle", classes.title)}>
        {title}
      </h1>
      <SortedSearchResultsList
        {...otherProps}
        className={classnames("devicesList", classes.deviceTypesList)}
        columns={columns}
        items={items}
        showSearch={true}
        nameFilterDelay={25}
        sortByColumn={sortByColumn}
        sortOrderAscending={sortOrderAscending}
        nameFilter={nameFilter}
        selectable={selectable}
        selectAllDisabled={selectAllDisabled}
        selectedItems={selectedItems}
        noResultsLabel={noResultsLabel}
        loadMoreLabel={loadMoreLabel}
        tableLayoutFixed={tableLayoutFixed}
        showErrorView={showErrorView}
        showLoadingIndicator={showProgressIndicator}
        showNoResultsView={showNoResultsView}
        showLoadMoreButton={showLoadMoreButton}
        onClickLoadMore={loadMore}
        onClickItem={onItemClicked}
        setSelectedItems={setSelectedItems}
        renderItem={(device: DeviceEnrollment, column: DevicesListColumn) => (
          <DevicesListItem
            device={device}
            column={column}
          />
        )}
      />
    </div>
  );
});

export default DevicesList;
