import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DataSetRequestWizard from "./containers/DataSetRequestWizard";
import { getPathToDataSetRequest } from "@modules/dataSetRequestDetails/helpers";

type LocationProps = {
  from?: Location;
  goBackOnSuccess?: boolean;
};

export function EditDataSetRequestView() {

  const history = useHistory();

  const { id: dataSetRequestId } = useParams<{ id: string }>();

  const { state: { from = undefined, goBackOnSuccess = false } = {} } = useLocation<LocationProps>();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  // If specified, go back, otherwise either replace this history entry
  // with the provided entry point or goto the details page
  const onEditSuccess = React.useCallback(() => {
    if (goBackOnSuccess) {
      history.goBack();
    } else if (from) {
      history.replace(from);
    } else {
      history.replace(getPathToDataSetRequest(dataSetRequestId));
    }
  }, [history, from, goBackOnSuccess, dataSetRequestId]);

  return (
    <DataSetRequestWizard
      key={dataSetRequestId}
      snackbarId="edit-data-set-request"
      cancel={cancel}
      onEditSuccess={onEditSuccess}
    />
  );
}

export default EditDataSetRequestView;
