import React from "react";
import { connect } from "react-redux";
import { noop } from "../../../util";
import { AppSchema } from "../../main/schemas";
import { setQueryParams } from "../../main/actions";
import { StartSession, Actions, Model } from "../components/StartSession";
import { getErrorMessage, isErrorMessageVisible } from "../selectors";
import { startSession } from "../actions";

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
  initialize?: () => void;
}

const StartSessionContainer = (props: ContainerModel & ContainerActions) => {

  const { initialize = noop, ...otherProps } = props;

  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {

    if (!initialized) {
      setInitialized(true);
      initialize();
    }

  }, [initialize, initialized, setInitialized]);

  if (!initialized) {
    return null;
  }

  return (
    <StartSession {...otherProps} />
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
  errorMessage: getErrorMessage(state),
  showErrorMessage: isErrorMessageVisible(state),
});

const mapDispatchToProps = (dispatch: any): ContainerActions => ({
  initialize: () => dispatch(startSession()),
  retry: () => dispatch(setQueryParams("")),
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(StartSessionContainer);
