import React from "react";
import { EffectivePoliciesView, EffectivePoliciesModel, EffectivePoliciesActions } from "@components";
import CustomPoliciesView from "./CustomPoliciesView";
import ManagedPoliciesView from "./ManagedPoliciesView";
import { Policy, ManagedPolicy } from "@data";

interface ContainerModel extends EffectivePoliciesModel {
  groupName?: string;
  isCustomPoliciesViewActive?: boolean;
  isManagedPoliciesViewActive?: boolean;
}

interface ContainerActions extends EffectivePoliciesActions {
  showCustomPoliciesView?: () => void;
  showManagedPoliciesView?: () => void;
  showPolicyDetails?: (policy: Policy) => void;
  showManagedPolicyDetails?: (policy: ManagedPolicy) => void;
  setRemovePolicyButtonEnabled?: (enabled: boolean) => void;
}

type Props = ContainerModel & ContainerActions;

const EffectivePoliciesViewContainer = (props: Props) => {

  const {
    groupName = "",
    showCustomPoliciesView,
    showManagedPoliciesView,
    isCustomPoliciesViewActive,
    isManagedPoliciesViewActive,
    setRemovePolicyButtonEnabled,
    showManagedPolicyDetails,
    showPolicyDetails,
    ...otherProps
  } = props;

  return (
    <EffectivePoliciesView
      {...otherProps}
      customPoliciesViewSelected={isCustomPoliciesViewActive}
      managedPoliciesViewSelected={isManagedPoliciesViewActive}
      setManagedPoliciesView={showManagedPoliciesView}
      setCustomPoliciesView={showCustomPoliciesView}
    >
      <CustomPoliciesView
        groupName={groupName}
        showPolicyDetails={showPolicyDetails}
        setDetachPolicyButtonEnabled={setRemovePolicyButtonEnabled}
        hidden={!isCustomPoliciesViewActive}
      />
      <ManagedPoliciesView
        groupName={groupName}
        showManagedPolicyDetails={showManagedPolicyDetails}
        setDetachPolicyButtonEnabled={setRemovePolicyButtonEnabled}
        hidden={!isManagedPoliciesViewActive}
      />
    </EffectivePoliciesView>
  );
};

export default EffectivePoliciesViewContainer;
