import React from "react";
import { Location } from "history";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getPathToFederationProvider } from "@modules/federationProviderDetails/helpers";
import FederationProviderWizard from "./containers/FederationProviderWizard";

type LocationProps = {
  from?: Location;
  goBackOnSuccess?: boolean;
};

export function EditFederationProviderView() {

  const history = useHistory();

  const { id: providerId } = useParams<{ id: string }>();

  const { state: { from = undefined, goBackOnSuccess = false } = {} } = useLocation<LocationProps>();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  // If specified, go back, otherwise either replace this history entry
  // with the provided entry point or goto the details page
  const onSuccess = React.useCallback(() => {
    if (goBackOnSuccess) {
      history.goBack();
    } else if (from) {
      history.replace(from);
    } else {
      history.replace(getPathToFederationProvider(providerId));
    }
  }, [history, from, goBackOnSuccess, providerId]);

  return (
    <FederationProviderWizard
      key={providerId}
      snackbarId="edit-federation-provider"
      className="editFederationProviderWizard"
      cancel={cancel}
      onSuccess={onSuccess}
    />
  );
}

export default EditFederationProviderView;
