import { Module } from "@data";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./SecurityGroupDetailsView";

type RouteParams = { id?: string };

export const securityGroupDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: true,
  name: "Security Group Details",
  title: "Security Group Details",
  className: "securityGroupDetailsModule",
  primaryColor,
  icon,
  view,
});

export default securityGroupDetailsModule;
