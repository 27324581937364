import { Record } from "immutable";
import { getStringValue } from "@util";
import {
  DeviceTypeModelV3,
  DeviceTypeModelV3CertificateConfigAttributes,
  DeviceTypeModelV3ConnectionMode,
  DeviceTypeModelV3ConnectivityAttributes,
  DeviceTypeModelV3ConstraintsAttributes,
  DeviceTypeModelV3Credentials,
  DeviceTypeModelV3DataAttr,
  DeviceTypeModelV3Definition,
  DeviceTypeModelV3DeviceAuthentication,
  DeviceTypeModelV3PropertyModelAttributes,
  DeviceTypeModelV3SecurityAttributes,
  DeviceTypeModelV3SoftwareAttributes,
  DeviceTypeModelV3UsageAttributes,
} from "@data";
import DeviceTypeRequest from "./DeviceTypeRequest";
import DeviceTypeRequestV2 from "./DeviceTypeRequestV2";

export interface DeviceTypeRequestV3Attributes {
  certificates?: DeviceTypeModelV3CertificateConfigAttributes[];
  connectivity?: DeviceTypeModelV3ConnectivityAttributes;
  data?: DeviceTypeModelV3DataAttr;
  description?: string;
  properties?: DeviceTypeModelV3PropertyModelAttributes[];
  tags?: string[];
  software?: DeviceTypeModelV3SoftwareAttributes;
  security?: DeviceTypeModelV3SecurityAttributes;
}

export class DeviceTypeRequestV3 extends Record({
  certificates: [],
  connectivity: {},
  data: {},
  description: "",
  properties: [],
  tags: [],
  software: {},
  security: {
    authorization: {
      groups: []
    },
    constraints: {},
    credentials: {
      definitions: [],
      usage: {
        deviceAuthentication: [],
      },
    },
  },
}) implements DeviceTypeRequestV3Attributes, DeviceTypeRequest {

  public static EMPTY: DeviceTypeRequestV3 = new DeviceTypeRequestV3();

  public readonly certificates: DeviceTypeModelV3CertificateConfigAttributes[];
  public readonly connectivity: DeviceTypeModelV3ConnectivityAttributes;
  public readonly data: DeviceTypeModelV3DataAttr;
  public readonly description: string;
  public readonly properties: DeviceTypeModelV3PropertyModelAttributes[];
  public readonly tags: string[];
  public readonly software: DeviceTypeModelV3SoftwareAttributes;
  public readonly security: DeviceTypeModelV3SecurityAttributes;

  public static from(deviceType: DeviceTypeModelV3): DeviceTypeRequestV3 {

    const {
      description = "",
      certificates = [],
      connectivity = {},
      data = {},
      properties = [],
      tags = [],
      software = {},
      security = {
        authorization: {
          groups: []
        },
        constraints: {},
        credentials: DeviceTypeModelV3Credentials.EMPTY.toJS(),
      },
    } = deviceType.toJS();

    return new DeviceTypeRequestV3({
      description: description || "",
      ...(Array.isArray(certificates) && certificates.length > 0 ? ({ certificates }) : ({})),
      ...(typeof connectivity === "object" ? ({ connectivity }) : ({})),
      ...(typeof data === "object" ? ({ data }) : ({})),
      ...(Array.isArray(properties) && properties.length > 0 ? ({ properties }) : ({})),
      ...(Array.isArray(tags) && tags.length > 0 ? ({ tags }) : ({})),
      ...(typeof software === "object" ? ({ software }) : ({})),
      ...(typeof security === "object" ? ({ security }) : ({})),
    });
  }

  public static fromDeviceTypeRequestV2(deviceTypeRequest: DeviceTypeRequestV2): DeviceTypeRequestV3 {

    const description = deviceTypeRequest.getDescription();
    const configuration = deviceTypeRequest.getSchemas().map(schema => ({ schema }));
    const data = { ...(configuration.length > 0 ? ({ configuration }) : ({})) };
    const properties = deviceTypeRequest.getProperties();
    const tags = deviceTypeRequest.getTags();
    const groups = deviceTypeRequest.getGroups().map(name => ({ name }));
    const authorization = { ...(groups.length > 0 ? ({ groups }) : ({})) };
    const security = {
      ...(Object.keys(authorization).length === 0 ? ({}) : ({
        authorization,
        constraints: {},
        credentials: DeviceTypeModelV3Credentials.EMPTY.toJS(),
      })),
    };

    return new DeviceTypeRequestV3({
      description: description || "",
      ...(Object.keys(data).length > 0 ? ({ data }) : ({})),
      ...(Array.isArray(properties) && properties.length > 0 ? ({ properties }) : ({})),
      ...(Array.isArray(tags) && tags.length > 0 ? ({ tags }) : ({})),
      ...(Object.keys(security).length > 0 ? ({ security }) : ({})),
    });
  }

  public getDescription(): string {
    return this.description;
  }

  public getTags(): string[] {
    return Array.isArray(this.tags) ? this.tags : [];
  }

  public getGroups(): string[] {
    const security = this.security || { authorization: { groups: [] } };
    const { authorization = { groups: [] } } = security;
    const { groups = [] } = authorization;
    const data = Array.isArray(groups) ? groups : [];
    return data.map(({ name = "" }) => getStringValue(name));
  }

  public getSchemas(): string[] {
    const { configuration = [] } = this.data || { configuration: [] };
    const data = Array.isArray(configuration) ? configuration : [];
    return data.map(({ schema = "" }) => getStringValue(schema));
  }

  public getProperties(): DeviceTypeModelV3PropertyModelAttributes[] {
    const properties = this.properties;
    return Array.isArray(properties) ? properties : [];
  }

  public getSecurity(): DeviceTypeModelV3SecurityAttributes {
    return this.security || ({
      authorization: {
        groups: []
      },
      constraints: {},
      credentials: DeviceTypeModelV3Credentials.EMPTY.toJS()
    });
  }

  public getConstraints(): DeviceTypeModelV3ConstraintsAttributes {
    const { constraints = {} } = this.getSecurity();
    return constraints || {};
  }

  public getConnectionMode(): DeviceTypeModelV3ConnectionMode {
    const { connectionMode = DeviceTypeModelV3ConnectionMode.NONE } = this.getConstraints();
    return connectionMode || DeviceTypeModelV3ConnectionMode.NONE;
  }

  public isMqttEnabled(): boolean {
    const { mqtt = false } = this.getConstraints();
    return mqtt || false;
  }

  public getCredentials(): DeviceTypeModelV3Credentials {
    const security = this.getSecurity();
    const { credentials = DeviceTypeModelV3Credentials.EMPTY.toJS() } = security;
    return new DeviceTypeModelV3Credentials(credentials);
  }

  public getDefinitions(): DeviceTypeModelV3Definition[] {
    return this.getCredentials().getDefinitions();
  }

  public getDefinitionNames(): string[] {
    return this.getCredentials().getDefinitionNames();
  }

  public getCredentialNames(): string[] {
    return this.getDefinitionNames();
  }

  public hasCredentialNames(): boolean {
    return this.getCredentialNames().length > 0;
  }

  public getUsage(): DeviceTypeModelV3UsageAttributes {
    return this.getCredentials().getUsage();
  }

  public getDeviceAuthentication(): DeviceTypeModelV3DeviceAuthentication[] {
    return this.getCredentials().getDeviceAuthentication();
  }

  public hasDeviceAuthentication(): boolean {
    return this.getDeviceAuthentication().length > 0;
  }
}

export default DeviceTypeRequestV3;
