import createStyles from "@material-ui/core/styles/createStyles";
import { StyleRules } from "@material-ui/core/styles/withStyles";
import { Color } from "../../../../components/styles";

export const textFieldTooltip: StyleRules<string> = {
  popper: {
    width: 231,
    height: "auto",
    alignItems: "center",
    display: "flex",
  },
  tooltip: {
    backgroundColor: Color.WHITE,
    color: Color.GREY8,
    padding: "10px 15px",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px",
    textAlign: "left",
    whiteSpace: "normal",
  },
};

export const accountId = () =>
  createStyles({
    tooltip: {
      width: 231,
      height: "auto",
      alignItems: "center",
      display: "flex",
      borderRadius: 3,
      fontWeight: 400,
      padding: 0,
      marginTop: -15,
      transition: "top 50ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, " +
        "transform 0ms cubic-bezier(0.23, 1, 0.32, 1) 50ms, " +
        "opacity 0ms cubic-bezier(0.23, 1, 0.32, 1) 50ms",
    },
    iconButton: {
      position: "absolute",
      right: 8,
      bottom: 18,
      width: 20,
      height: 20,
      padding: 0,
      margin: 0,
    },
    textFieldIcon: {
      width: 20,
      height: 20,
      fillOpacity: 0.4,
      color: Color.WHITE,
    },
  });

export const password = () =>
  createStyles({
    tooltip: {
      width: 231,
      height: "auto",
      alignItems: "center",
      display: "flex",
      borderRadius: 3,
      fontWeight: 400,
      padding: 0,
      marginTop: -15,
      transition: "top 50ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, " +
        "transform 0ms cubic-bezier(0.23, 1, 0.32, 1) 50ms, " +
        "opacity 0ms cubic-bezier(0.23, 1, 0.32, 1) 50ms",
    },
    iconButton: {
      position: "absolute",
      right: 8,
      bottom: 18,
      width: 20,
      height: 20,
      padding: 0,
      margin: 0,
    },
    textFieldIcon: {
      width: 20,
      height: 20,
      fillOpacity: 0.4,
      color: Color.WHITE,
    },
    hidden: {
      visibility: "hidden",
    },
  });

export const styles = () =>
  createStyles({
    textField: {
      fontSize: 13,
      height: "auto",
    },
    formHelperText: {
      fontSize: 13,
    },
    inputLabel: {
      transform: "translate(12px, 25px) scale(1)",
      "&$error:not($focused)": {
        transform: "translate(12px, 5px) scale(1)",
      },
      "&$focused": {
        color: Color.WHITE,
      },
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    focused: {
    },
    error: {
    },
  });

export default styles;
