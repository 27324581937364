import React from "react";
import { Module } from "../../data";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { PrimaryIcon } from "./components/styles";
import AccountSettings from "./containers/AccountSettings";
import { equalsIgnoreCase } from "@util";

export const accountSettingsModule: Module = {
  id: MODULE_ID,
  name: "Settings",
  title: "Account Settings",
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  icon: PrimaryIcon,
  view: <AccountSettings />,
  isNavLinkActive: (match, { pathname }) => equalsIgnoreCase(pathname, MODULE_PATH),
};

export default accountSettingsModule;
