import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "../../main/schemas";
import { EnumValueEditor, Model, Actions } from "../components/EnumValueEditor";
import { PropertyEditorSelectors } from "../selectors/propertyEditor";
import { PropertyEditorActions } from "../actions/propertyEditor";

const mapStateToProps = (state: AppSchema, ownProps: { children?: React.ReactNode  }): Model => ({
  visible: PropertyEditorSelectors.isEnumEditorVisible(state),
  propertyType: PropertyEditorSelectors.getType(state),
  enumValueError: PropertyEditorSelectors.getEnumValueError(state),
  enumValues: PropertyEditorSelectors.getEnumValues(state),
  disabled: PropertyEditorSelectors.isProgressIndicatorVisible(state),
  children: ownProps.children,
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  clearEnumValueError: () => dispatch(PropertyEditorActions.clearEnumValueError()),
  addEnumValue: (value: string) => dispatch(PropertyEditorActions.addEnumValue(value)),
  removeEnumValue: (value: string) => dispatch(PropertyEditorActions.removeEnumValue(value)),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(EnumValueEditor);
