import React from "react";
import AuditEvents from "./containers/AuditEvents";

export function AuditEventsView() {

  return (
    <AuditEvents />
  );
}

export default AuditEventsView;
