import { AppSchema } from "@schemas";
import { getAuthToken } from "@main/selectors";
import { RestClientError, SchemaRegistryClient } from "@network";
import { SchemaStateManagerActions } from "@modules/schemaStateManager/actions";
import { schemaUpdated as updateSchemasModuleStore } from "@modules/schemas/actions";
import { JsonSchemaDefinition, JsonSchemaMetadata, JsonSchemaMetadataAttributes } from "@data";
import {
  DEFAULT_STATE,
  SchemaDetailsAction,
  SchemaDetailsActionType,
} from "../reducers";
import {
  getSchemaMetaData,
  getSchemaName,
  getSchemaNamespace,
  getSchemaVersion,
} from "../selectors";

export const setJson =
  (value: string = DEFAULT_STATE.json): SchemaDetailsAction => ({
    type: SchemaDetailsActionType.SET_JSON,
    value,
  });

export const setSchema =
  (value: JsonSchemaDefinition = DEFAULT_STATE.schema): SchemaDetailsAction => ({
    type: SchemaDetailsActionType.SET_SCHEMA,
    value,
  });

export const setMetaData =
  (value: JsonSchemaMetadataAttributes = DEFAULT_STATE.metaData): SchemaDetailsAction => ({
    type: SchemaDetailsActionType.SET_META_DATA,
    value,
  });

export const setErrorMessage =
  (value: string = DEFAULT_STATE.errorMessage): SchemaDetailsAction => ({
    type: SchemaDetailsActionType.SET_ERROR_MESSAGE,
    value,
  });

export const clearErrorMessage = () => setErrorMessage();

export const setShowEmptyView =
  (value: boolean = DEFAULT_STATE.showEmptyView): SchemaDetailsAction => ({
    type: SchemaDetailsActionType.SET_SHOW_EMPTY_VIEW,
    value,
  });

export const showEmptyView = () => setShowEmptyView(true);
export const hideEmptyView = () => setShowEmptyView(false);

export const setShowAccessDenied =
  (value: boolean = DEFAULT_STATE.showAccessDenied): SchemaDetailsAction => ({
    type: SchemaDetailsActionType.SET_SHOW_ACCESS_DENIED,
    value,
  });

export const showAccessDenied = () => setShowAccessDenied(true);
export const hideAccessDenied = () => setShowAccessDenied(false);

export const setShowLoadingIndicator =
  (value: boolean = DEFAULT_STATE.showLoadingIndicator): SchemaDetailsAction => ({
    type: SchemaDetailsActionType.SET_SHOW_LOADING_INDICATOR,
    value,
  });

export const showLoadingIndicator = () => setShowLoadingIndicator(true);
export const hideLoadingIndicator = () => setShowLoadingIndicator(false);

export const setShowNotFound =
  (value: boolean = DEFAULT_STATE.showNotFound): SchemaDetailsAction => ({
    type: SchemaDetailsActionType.SET_SHOW_NOT_FOUND,
    value,
  });

export const showNotFound = () => setShowNotFound(true);
export const hideNotFound = () => setShowNotFound(false);

export const setIsLatest =
  (value: boolean = DEFAULT_STATE.isLatest): SchemaDetailsAction => ({
    type: SchemaDetailsActionType.SET_IS_LATEST,
    value,
  });

export const loadSchemaRequest = (): SchemaDetailsAction => ({
  type: SchemaDetailsActionType.LOAD_SCHEMA_REQUEST,
});

export const loadSchemaRequestSuccess = (): SchemaDetailsAction => ({
  type: SchemaDetailsActionType.LOAD_SCHEMA_REQUEST_SUCCESS,
});

export const loadSchemaRequestFailed = (error: string): SchemaDetailsAction => ({
  type: SchemaDetailsActionType.LOAD_SCHEMA_REQUEST_FAILED,
  value: error || "",
});

export const validateSchemaRequest = (): SchemaDetailsAction => ({
  type: SchemaDetailsActionType.VALIDATE_SCHEMA_REQUEST,
});

export const validateSchemaSuccess = (): SchemaDetailsAction => ({
  type: SchemaDetailsActionType.VALIDATE_SCHEMA_SUCCESS,
});

export const validateSchemaFailed = (analytic: string): SchemaDetailsAction => ({
  type: SchemaDetailsActionType.VALIDATE_SCHEMA_FAILED,
  value: analytic || "",
});

export const reset = () => (dispatch: any) => {
  dispatch(setJson());
  dispatch(setSchema());
  dispatch(setMetaData());
  dispatch(setErrorMessage());
  dispatch(setShowEmptyView());
  dispatch(setShowNotFound());
  dispatch(setShowAccessDenied());
  dispatch(setShowLoadingIndicator());
  return dispatch(setIsLatest());
};

export const draftNewSchemaVersion = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(SchemaStateManagerActions.openDraftNewSchemaVersionDialog(getSchemaMetaData(getState())));

export const promoteSchema = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(SchemaStateManagerActions.openPromoteSchemaDialog(getSchemaMetaData(getState())));

export const deprecateSchema = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(SchemaStateManagerActions.openDeprecateSchemaDialog(getSchemaMetaData(getState())));

export const decommissionSchema = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(SchemaStateManagerActions.openDecommissionSchemaDialog(getSchemaMetaData(getState())));

export const deleteSchema = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(SchemaStateManagerActions.openDeleteSchemaDialog(getSchemaMetaData(getState())));

export const fetchSchema = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const authToken = getAuthToken(state);
  const namespace = getSchemaNamespace(state);
  const name = getSchemaName(state);
  const version = getSchemaVersion(state);

  dispatch(showLoadingIndicator());
  dispatch(setErrorMessage());
  dispatch(hideNotFound());
  dispatch(hideAccessDenied());
  dispatch(setIsLatest(false));
  dispatch(loadSchemaRequest());

  const fetchSchemaDetails = () => Promise.all([
    SchemaRegistryClient.getSchemaMetadata(authToken, namespace, name, version),
    SchemaRegistryClient.getSchema(authToken, namespace, name, version),
    SchemaRegistryClient.getSchema(authToken, namespace, name),
  ]).then(([metadata, schema, latestSchema]) => ({ metadata, schema, latestSchema }));

  return fetchSchemaDetails()
    .then(( { metadata, schema, latestSchema }) => {

      const json = JSON.stringify(schema, null, "  ");
      const latest = JSON.stringify(latestSchema, null, "  ");
      const schemaMetadata = new JsonSchemaMetadata(metadata);
      const schemaMetadataAttributes = schemaMetadata.toJS();

      dispatch(setJson(json));
      dispatch(setSchema(schema));
      dispatch(setMetaData(schemaMetadataAttributes));
      dispatch(setIsLatest(json === latest));
      dispatch(updateSchemasModuleStore(schemaMetadata));
      dispatch(loadSchemaRequestSuccess());
      dispatch(hideLoadingIndicator());
      return dispatch(hideEmptyView());

    }, (response: RestClientError) => {

      const { analytic, error = "Fetch schema details failed" } = response;

      dispatch(loadSchemaRequestFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(hideLoadingIndicator());

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      if (response.status === 404) {
        dispatch(showNotFound());
      }

      return dispatch(hideEmptyView());
    });
};

export const refresh = () => (dispatch: any) => {

  return dispatch(fetchSchema());
};

export const initialize = (nameAndVersion: string) => (dispatch: any) => {

  dispatch(reset());
  dispatch(setMetaData(JsonSchemaMetadata.fromNameAndVersion(nameAndVersion).toJS()));
  return dispatch(fetchSchema());
};
