import React from "react";
import { DataSetRequest } from "@data";
import { useHistory } from "react-router-dom";
import { getPathToDataSetRequest } from "@modules/dataSetRequestDetails/helpers";
import {
  getCloneDataSetRequestPath,
  getEditDataSetRequestPath,
} from "@modules/dataSetRequestWizard/helpers";
import DataSetRequests from "./containers/DataSetRequests";

export function DataSetRequestsModuleView() {

  const history = useHistory();

  const showDataSetRequestDetails = React.useCallback((dataSetRequest: DataSetRequest) =>
    history.push(getPathToDataSetRequest(dataSetRequest.getDataSetRequestId())), [history]);

  const editDataSetRequest = React.useCallback((dataSetRequest: DataSetRequest) =>
      history.push(getEditDataSetRequestPath(dataSetRequest.getDataSetRequestId()), { goBackOnSuccess: true}),
    [history]);

  const cloneDataSetRequest = React.useCallback((dataSetRequest: DataSetRequest) =>
      history.push(getCloneDataSetRequestPath(dataSetRequest.getDataSetRequestId())),
    [history]);

  return (
    <DataSetRequests
      showDataSetRequestDetails={showDataSetRequestDetails}
      cloneDataSetRequest={cloneDataSetRequest}
      editDataSetRequest={editDataSetRequest}
    />
  );
}

export default DataSetRequestsModuleView;
