import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { isEmptyString } from "@util";
import {
  getJson as getSchemaAsJson,
  getSchemaName,
  getSchemaNamespace,
  getSchemaVersion,
} from "../selectors";
import { validateSchemaFailed, validateSchemaRequest, validateSchemaSuccess } from "../actions";
import SchemaValidationView, {
  SchemaValidationActions,
  SchemaValidationModel,
} from "@components/schema-validation-view";
import { useSchemaValidation } from "@hooks";

interface ContainerModel extends SchemaValidationModel {
  namespace?: string;
  name?: string;
  version?: string;
  showLoadingIndicator?: boolean;
}

interface ContainerActions extends SchemaValidationActions {
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytics: string) => void;
}

type Props = ContainerModel & ContainerActions;

const SchemaValidationViewContainer = (props: Props) => {

  const {
    namespace,
    name ,
    version,
    schema,
    showLoadingIndicator,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
    ...otherProps
  } = props;

  const showEmptyView = React.useMemo(() =>
      isEmptyString(schema) && showLoadingIndicator,
    [schema, showLoadingIndicator]);

  const [model, actions] = useSchemaValidation({
    namespace,
    name,
    version,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
  });

  return (
    <SchemaValidationView
      {...otherProps}
      {...model}
      {...actions}
      schema={schema}
      showEmptyView={showEmptyView}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  namespace: getSchemaNamespace(state),
  name: getSchemaName(state),
  version: getSchemaVersion(state),
  schema: getSchemaAsJson(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  trackRequestEvent: () => dispatch(validateSchemaRequest()),
  trackSuccessEvent: () => dispatch(validateSchemaSuccess()),
  trackErrorEvent: (analytics: string) => dispatch(validateSchemaFailed(analytics)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(SchemaValidationViewContainer);
