import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { isLoggedIn } from "@main/selectors";
import SessionStorageClient from "@util/SessionStorageClient";
import EndSessionListener, {
  EndSessionListenerActions as Actions,
  EndSessionListenerModel as Model,
} from "@components/end-session-listener";

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  loggedIn: isLoggedIn(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  invalidateSessionData: () => {
    SessionStorageClient.clear();
  },
  reload: () => {
    window.location.reload();
  },
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(EndSessionListener);
