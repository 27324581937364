import React from "react";
import classnames from "classnames";
import { clickHandler } from "@util";
import Button from "@components/button";
import ErrorView from "@components/error-view";
import SaveButton from "@components/save-button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface Model {
  errorMessage?: string;
  showLoadingIndicator?: boolean;
  showSuccessIndicator?: boolean;
  saveButtonLabel?: string;
  saveButtonDisabled?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  cancel?: () => void;
  save?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const PasswordRecovery = withStyles(styles)((props: Props) => {

  const {
    classes,
    errorMessage,
    showLoadingIndicator,
    showSuccessIndicator,
    saveButtonLabel = "Send Password Recovery Email",
    saveButtonDisabled = false,
    cancel,
    save,
    children,
  } = props;

  const showCancelButton = !showLoadingIndicator && !showSuccessIndicator;

  return (
    <div className={classnames("passwordRecovery", classes.container)}>
      <ErrorView className={classes.error} title="Failed to send Password Recovery Email" message={errorMessage} />
      {children}
      <div className={classnames("controls", classes.controls)}>
        {showCancelButton && (
          <Button
            className={classnames("cancelButton", classes.cancelButton)}
            classes={{
              label: classnames("label", classes.cancelButtonLabel),
            }}
            variant="text"
            onClick={clickHandler(cancel)}
          >
            Cancel
          </Button>
        )}
        <SaveButton
          className={classnames("saveButton", classes.saveButton)}
          disabled={saveButtonDisabled}
          label={saveButtonLabel}
          loading={showLoadingIndicator}
          success={showSuccessIndicator}
          save={save}
        />
      </div>
    </div>
  );
});

export default PasswordRecovery;
