import React from "react";
import AuthenticatorListColumn from "./AuthenticatorListColumn";
import { ActionMenuItem } from "@components";
import SortedSearchResultsList, {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import { sortAndFilterAuthenticatorResult } from "./helpers";
import { Authenticator } from "@data";
import { AuthenticatorIcon } from "./styles";
import AuthenticatorListItem from "./AuthenticatorListItem";
import { AuthenticatorActions } from "./AuthenticatorActionsMenu";
import { noop } from "@util";

export const DEFAULT_AUTHENTICATOR_LIST_COLUMNS: AuthenticatorListColumn[] = [
  AuthenticatorListColumn.AUTHENTICATOR_ID,
  AuthenticatorListColumn.STATUS,
  AuthenticatorListColumn.TYPE,
  AuthenticatorListColumn.LABEL
];

export type AuthenticatorListModel =
  SortedSearchResultsListModel<Authenticator, AuthenticatorListColumn>;

export interface AuthenticatorListActions
  extends SortedSearchResultsListActions<Authenticator, AuthenticatorListColumn> {
  deleteAuthenticator?: (authenticator: Authenticator) => void;
}

type Props = AuthenticatorListModel & AuthenticatorListActions;

export const AuthenticatorList = (props: Props) => {

  const {
    className = "authenticators",
    listClassName = "authenticatorList",
    columns = DEFAULT_AUTHENTICATOR_LIST_COLUMNS,
    sortByColumn = AuthenticatorListColumn.AUTHENTICATOR_ID,
    sortOrderAscending = true,
    noResultsLabel = "No authenticators set up by the user",
    summaryViewQuantities = {
      other: "authenticators",
      one: "authenticator",
    },
    items = [],
    nameFilter = "",
    deleteAuthenticator = noop,
    onClickAction = noop,
    ...otherProps
  } = props;

  const onActionClicked = React.useCallback((authenticator: Authenticator, action: ActionMenuItem) => {
    if (action.id === AuthenticatorActions.DELETE) {
      deleteAuthenticator(authenticator);
    } else {
      onClickAction(authenticator, action);
    }
  }, [deleteAuthenticator, onClickAction]);

  const authenticators =
    sortAndFilterAuthenticatorResult({ items, nameFilter, sortOrderAscending, sortByColumn });

  return (
    <SortedSearchResultsList
      {...otherProps}
      className={className}
      items={authenticators}
      columns={columns}
      nameFilter={nameFilter}
      nameFilterDelay={25}
      sortByColumn={sortByColumn}
      sortOrderAscending={sortOrderAscending}
      noResultsLabel={noResultsLabel}
      summaryViewIcon={AuthenticatorIcon}
      summaryViewQuantities={summaryViewQuantities}
      onClickAction={onActionClicked}
      renderItem={(authenticator: Authenticator, column: AuthenticatorListColumn) => (
        <AuthenticatorListItem
          authenticator={authenticator}
          column={column}
        />
      )}
    />
  );
};

export default AuthenticatorList;
