import { SchemasListColumn, SchemasList } from "@components/schemas-list";
import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { JsonSchemaMetadata } from "@data";
import { DeviceTypeDetailsSelectors } from "../selectors";
import { DeviceTypeDetailsActions } from "../actions";
import { Model, SchemasList as DeviceTypeSchemasList } from "../components/SchemasList";
import { noop } from "@util";
import { DeviceTypeSchemaViewMode } from "../reducers";

export const DEFAULT_COLUMNS: SchemasListColumn[] = [
  SchemasListColumn.NAMESPACE,
  SchemasListColumn.NAME,
  SchemasListColumn.VERSION,
];

interface ContainerModel extends Model {
  schemaIdentities?: string[];
  schemaViewMode?: DeviceTypeSchemaViewMode;
}

interface ContainerActions {
  showSchemaDetails?: (schema: JsonSchemaMetadata) => void;
  showSchemaDetailsInNewTab?: (schema: JsonSchemaMetadata) => void;
  showConfigurationView?: () => void;
  showMetadataView?: () => void;
}

const SchemasListContainer = (props: ContainerModel & ContainerActions) => {

  const {
    schemaIdentities = [],
    showSchemaDetails,
    showSchemaDetailsInNewTab,
    schemaViewMode,
    showConfigurationView = noop,
    showMetadataView = noop,
    ...otherProps
  } = props;

  const schemas = React.useMemo(() => schemaIdentities.map(schemaIdentity =>
  JsonSchemaMetadata.fromNameAndVersion(schemaIdentity)), [schemaIdentities]);

  const showNoResultView = React.useMemo(() => schemas.length === 0, [schemas]);

  const noResultLabel = React.useMemo(() =>
    `No ${schemaViewMode} schemas are attached to this Device Type`, [schemaViewMode]);

  const onChangeSchemaViewMode = React.useCallback(event => {
    if (event && event.target && event.target.value) {
      if (event.target.value === "configuration") {
        showConfigurationView();
      } else {
        showMetadataView();
      }
    }
  }, [
    showConfigurationView,
    showMetadataView
  ]);

  return (
    <DeviceTypeSchemasList
      {...otherProps}
      onChangeSchemaViewMode={onChangeSchemaViewMode}
    >
      <SchemasList
        schemas={schemas}
        hideSearch={true}
        hideSummary={true}
        columns={DEFAULT_COLUMNS}
        onClickItem={showSchemaDetails}
        onClickShowMoreInfo={showSchemaDetailsInNewTab}
        showMoreInfoLabel="Schema Details"
        noResultsLabel={noResultLabel}
        showNoResultsView={showNoResultView}
      />
    </DeviceTypeSchemasList>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  schemaIdentities: DeviceTypeDetailsSelectors.getDeviceTypeSchemas(state),
  isConfigurationSelected: DeviceTypeDetailsSelectors.isConfigurationViewSelected(state),
  isMetadataSelected: DeviceTypeDetailsSelectors.isMetadataViewSelected(state),
  schemaViewMode: DeviceTypeDetailsSelectors.getSchemaViewMode(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  showConfigurationView: () => dispatch(DeviceTypeDetailsActions.showConfigurationView()),
  showMetadataView: () => dispatch(DeviceTypeDetailsActions.showMetadataView()),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(SchemasListContainer);
