import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import { UserProfileAttributeAttributes } from "../../data";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";

export enum NewUserWizardStep {
  NONE = "",
  USER_INFO = "user_info",
  APPLICATION = "application",
  EMAIL_TEMPLATE = "email_template",
  REVIEW = "review",
}

export type UserProfileAttributeItems = {
  [key: string]: UserProfileAttributeAttributes;
};

export interface InviteUserSchema extends BasePortalModuleSchema {
  userId: string;
  userIdErrorMessage: string;
  applicationId: string;
  profile: UserProfileAttributeItems;
  emailTemplateId: string;
  sendEmailCopy: boolean;
  steps: NewUserWizardStep[];
  currentStep: NewUserWizardStep;
}

export const DEFAULT_STATE: InviteUserSchema = createPortalModuleDefaultState({
  ...BASE_DEFAULT_STATE,
  showEmptyView: false,
  userId: "",
  userIdErrorMessage: "",
  applicationId: "",
  profile: {},
  emailTemplateId: "",
  sendEmailCopy: false,
  steps: Object.freeze([
    NewUserWizardStep.USER_INFO,
    NewUserWizardStep.APPLICATION,
    NewUserWizardStep.EMAIL_TEMPLATE,
    NewUserWizardStep.REVIEW,
  ]) as NewUserWizardStep[],
  currentStep: NewUserWizardStep.USER_INFO,
});

type InviteUserActionTypes = PortalModuleActionTypes<InviteUserSchema> & {
  INVITE_USER_REQUEST: string;
  INVITE_USER_SUCCESS: string;
  INVITE_USER_FAILED: string;
};

export const ACTION_TYPES: InviteUserActionTypes = {
  ...createPortalModuleActionTypes<InviteUserSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    INVITE_USER_REQUEST: `${MODULE_ID}/invite_user_request`,
    INVITE_USER_SUCCESS: `${MODULE_ID}/invite_user_success`,
    INVITE_USER_FAILED: `${MODULE_ID}/invite_user_failed`,
  },
};

export const InviteUserReducer = combineReducers<InviteUserSchema>(
  createPortalModuleReducers<InviteUserSchema>(ACTION_TYPES, DEFAULT_STATE));

export default InviteUserReducer;
