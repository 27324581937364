import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export const HtmlPreview = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  sandbox?: string,
  srcDoc?: string,
}) => {

  const {
    classes,
    className,
    sandbox = "",
    srcDoc,
  } = props;

  return (
    <iframe
      className={classnames("htmlPreview", className, classes.container)}
      sandbox={sandbox}
      srcDoc={srcDoc}
    />
  );
});

export default HtmlPreview;
