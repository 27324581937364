import React from "react";
import { isValidNumber, noop } from "@util";
import classnames from "classnames";
import { RestClientError } from "@network";
import { usePortalSnackbar } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import useWorkloadManagerClient from "@hooks/workloadsManager/use-workload-manager-client";
import UploadDialog from "@components/upload-dialog";
import styles from "./styles";

export const UploadWorkloadCode = withStyles(styles)((props: WithStyles<typeof styles> & {
  name: string,
  version: number,
  fileName?: string,
  fileType?: string,
  open?: boolean,
  onSuccess?: () => void,
  closeDialog?: () => void,
}) => {

  const {
    open,
    classes,
    name,
    version,
    fileName = "",
    fileType = "jar",
    onSuccess = noop,
    closeDialog = noop,
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();
  const [ uploading, setUploading ] = React.useState(false);
  const [ errorMessage, setErrorMessage ] = React.useState("");
  const [ statusCode, setStatusCode ] = React.useState<number | undefined>();
  const [ successMessage, setSuccessMessage ] = React.useState("");
  const [ file, setFile ] = React.useState<File>();

  usePortalSnackbar(`upload-workload-code`, {
    errorMessage,
    successMessage,
    onSuccessMessageShown: () => {
      onSuccess();
      setSuccessMessage("");
    },
    successCallbackDelay: 0,
  });

  React.useEffect(() => {

    if (!file || !uploading || errorMessage || successMessage) {
      return () => noop;
    }

    let ignore = false;

    WorkloadManagerClient.getUploadWorkloadCodeUrlApi(name, version, fileName || file.name)
      .then((response) => {
        if (!ignore) {
          WorkloadManagerClient.uploadWorkloadCodeApi(response.uploadUrl, file).then(() => {
            if (!ignore) {
              setFile(undefined);
              setUploading(false);
              setSuccessMessage(`File ${file.name} Uploaded Successfully`);
              closeDialog();
            }
          }).catch((uploadResponse: RestClientError) => {
            if (!ignore) {
              const { description, error } = uploadResponse;
              setFile(undefined);
              setUploading(false);
              setErrorMessage(description || error || "Upload Code Failed");
              setStatusCode(typeof uploadResponse.status === "number" ? uploadResponse.status :
                parseInt(uploadResponse.status, 10));
            }
          });
        }
      })
      .catch((response: RestClientError) => {
        if (!ignore) {
          const { description, error } = response;
          setFile(undefined);
          setUploading(false);
          setErrorMessage(description || error || "Get Upload URL Failed");
          setStatusCode(isValidNumber(response.status) ?
            response.status as number : parseInt(response.status.toString(), 10));
        }
      });

    return () => {
      ignore = true;
    };

  }, [
    name,
    version,
    file,
    uploading,
    successMessage,
    errorMessage,
    setFile,
    setUploading,
    setSuccessMessage,
    setErrorMessage,
    setStatusCode,
    closeDialog,
  ]);

  return (
    <div className={classnames("uploadWorkloadCode", classes.container)}>
      <UploadDialog
        open={open}
        title={successMessage ? "Upload Successful" : "Upload Workload Code"}
        file={file}
        fileType={fileType}
        uploading={uploading}
        successMessage={successMessage}
        errorMessage={errorMessage}
        cancel={() => {
          closeDialog();
          setFile(undefined);
          setUploading(false);
          setSuccessMessage("");
          setErrorMessage("");
        }}
        setUploading={setUploading}
        setErrorMessage={setErrorMessage}
        setFile={setFile}
        statusCode={statusCode}
      />
    </div>
  );
});

export default UploadWorkloadCode;
