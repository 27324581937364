import React from "react";
import { SecurityServiceRegional } from "@data";
import {
  usePaginatedApiRequest,
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
  useSecurityServicesRegionalClient,
} from "@hooks";
import { GetSecurityServicesResponse } from "@network";

type SuccessResponse = GetSecurityServicesResponse;

export interface UseGetServicesProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
  groupName?: string;
}

export interface UseGetServicesModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  services: SecurityServiceRegional[];
  nameFilter: string;
}

export interface UseGetServicesActions extends UsePaginatedApiRequestActions<SuccessResponse> {
  setNameFilter: (nameFilter: string) => void;
}

type Props = UseGetServicesProps;
type Model = UseGetServicesModel;
type Actions = UseGetServicesActions;
type Result = [Model, Actions];

export const useGetServicesRegional = (props: Props = {}): Result => {

  const {
    groupName = "",
    defaultErrorMessage = "Failed to get services",
    ...otherProps
  } = props;

  const SecurityServiceRegionalClient = useSecurityServicesRegionalClient();

  const [nameFilter, setNameFilter] = React.useState("");

  const [services, setServices] = React.useState<SecurityServiceRegional[]>([]);

  const makeApiRequest = React.useCallback((accessToken, next = "") =>
      SecurityServiceRegionalClient.getSecurityServicesRegionalApi(nameFilter, groupName, next),
    [SecurityServiceRegionalClient, nameFilter, groupName]);

  const [{ successResponse, ...baseModel }, {
    reset: baseReset,
    refresh: baseRefresh,
    ...baseActions
  }] =
    usePaginatedApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const updatedServices = React.useMemo<SecurityServiceRegional[]>(() => {
    const items = Array.isArray(successResponse) ?  successResponse : successResponse?.items || [];
    return items.map(attrs => new SecurityServiceRegional(attrs));
  }, [successResponse]);

  const reset = React.useCallback(() => {
    setServices([]);
    baseReset();
  }, [setServices, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    services,
    nameFilter,
  }), [
    baseModel,
    services,
    nameFilter,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
    setNameFilter,
  }), [
    baseActions,
    reset,
    refresh,
    setNameFilter,
  ]);

  // Refresh whenever name filter changes
  React.useEffect(() => {
    refresh();
  }, [nameFilter, refresh]);

  React.useEffect(() => {
    setServices(currentServices => currentServices.concat(updatedServices));
  }, [updatedServices, setServices]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetServicesRegional;
