import { AppSchema } from "@main/schemas";
import { createActions } from "@modules/base";
import { getAccessToken, getUserId, getEmailTemplateId } from "./selectors";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { RestClientError, UserIdmClient } from "@network";

export const {
  userId: setUserId,
  emailTemplateId: setEmailTemplateId,
  setErrorMessage,
  setSuccessMessage,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  PASSWORD_RECOVERY_REQUEST: passwordRecoveryRequest,
  PASSWORD_RECOVERY_SUCCESS: passwordRecoverySuccess,
  PASSWORD_RECOVERY_FAILED: passwordRecoveryFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setUserId());
  dispatch(setEmailTemplateId());
  return dispatch(baseReset());
};

export const recoverPassword = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const userId = getUserId(state);
  const accessToken = getAccessToken(state);
  const emailTemplateId = getEmailTemplateId(state);
  const json = JSON.stringify({
    emailTemplateId,
  });

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(passwordRecoveryRequest());

  return UserIdmClient.passwordRecovery(accessToken, userId, json)
    .then(() => {

      dispatch(passwordRecoverySuccess());
      dispatch(hideLoadingIndicator());
      return dispatch(setSuccessMessage("Recover Password Email sent"));

    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to send email to recover password" } = response;

      dispatch(passwordRecoveryFailed(analytic));
      dispatch(hideLoadingIndicator());

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(setErrorMessage(error));
    });
};

export const initialize = (userId: string) => (dispatch: any) => {

  dispatch(reset());
  return dispatch(setUserId(userId));
};
