import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      "&$showLineCoverageOnly": {
        "& > $stats": {
          paddingBottom: 0,
          "& .card.lineCoverage": {
            paddingTop: 2,
          },
        },
        "& > $history": {
          paddingTop: 0,
          "& > .chart": {
            marginTop: 0,
          },
        },
      },
    },
    title: {
      fontSize: 14,
      textAlign: "center",
      fontWeight: 500,
      padding: "15px 10px",
      lineHeight: "1",
      color: Color.TEXT,
      backgroundColor: Color.LIGHT_GREY6,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      border: `1px solid ${Color.GREY1}`,
    },
    stats: {
      display: "flex",
      flexFlow: "column nowrap",
      padding: 15,
      borderLeft: `1px solid ${Color.GREY1}`,
      borderRight: `1px solid ${Color.GREY1}`,
    },
    history: {
      flex: "1 0 auto",
      paddingTop: 10,
      borderLeft: `1px solid ${Color.GREY1}`,
      borderRight: `1px solid ${Color.GREY1}`,
      borderBottom: `1px solid ${Color.GREY1}`,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    },
    showLineCoverageOnly: {},
  });

export default styles;
