import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel
} from "@components";
import { editRedirectUri as styles, textField } from "./styles";
import { enterKeyHandler, formEventHandler, isEmptyString, noop } from "@util";
import TextField from "@components/text-field";

const UriTextField = withStyles(textField)(TextField);

export  interface Model extends ConfirmationDialogModel<string> {
  uri?: string;
}

export interface Actions extends ConfirmationDialogActions<string> {
  setUri?: (uri: string) => void;
  save?: (uri: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const EditUriDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    uri = "",
    setUri = noop,
    save = noop,
    title = "Update Redirect URI",
    continueButtonLabel = "Save",
    open = false,
    cancel = noop,
    children,
  } = props;

  const onEnterPressed = React.useCallback(() => {
    if (!isEmptyString(uri)) {
      save(uri);
    }
  }, [save, uri]);

  const formHelperTextProps = {
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  return (
    <ConfirmationDialog
      className={classnames("editAddRedirectUriDialog", classes.container)}
      open={open}
      title={title}
      continueButtonLabel={continueButtonLabel}
      item={uri}
      cancel={cancel}
      confirm={save}
    >
      <div className={classnames("redirectUriEditor", classes.uriContainer)}>
        <div className={classnames("controls", classes.controls)}>
          <UriTextField
            className={classnames("uri", classes.inputField, classes.uri)}
            autoComplete="off"
            label="Redirect URI"
            placeholder="Set Redirect URI"
            name="redirectUri"
            value={uri}
            fullWidth={true}
            autoFocus={true}
            variant="outlined"
            margin="none"
            onChange={formEventHandler(setUri)}
            onKeyDown={enterKeyHandler(onEnterPressed)}
            FormHelperTextProps={formHelperTextProps}
            InputLabelProps={inputLabelProps}
          />
        </div>
        {children}
      </div>
    </ConfirmationDialog>
  );
});

export default EditUriDialog;
