import React from "react";
import classnames from "classnames";
import Menu from "@material-ui/core/Menu";
import { clickHandler, noop } from "@util";
import MenuItem from "@material-ui/core/MenuItem";
import Link, { UnderlineOptions } from "@components/link";
import { PopoverOrigin } from "@material-ui/core/Popover";
import ToolbarUserMenuButton from "./ToolbarUserMenuButton";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { toolbarUserMenu as styles } from "./styles";

const ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

interface Props {
  className?: string;
  label: string;
  showMyProfileButton?: boolean;
  profilePath?: string;
  settingsPath?: string;
  onClickMyProfile?: () => void;
  onClickSettings?: () => void;
  onClickLogout?: () => void;
}

interface State {
  open: boolean;
}

// TODO: Convert to pure function component
export const ToolbarUserMenu = withStyles(styles)(
  class extends React.Component<WithStyles<typeof styles> & Props, State> {

    private _ref: React.RefObject<HTMLDivElement>;

    constructor(props: WithStyles<typeof styles> & Props) {
      super(props);

      this._ref = React.createRef();

      this.state = {
        open: false,
      };
    }

    public render() {

      const toggleMenu = () => {
        this.setState((prevState: State) => ({
          open: !prevState.open,
        }));
      };

      const closeMenu = () => {
        this.setState({
          open: false,
        });
      };

      const {
        classes,
        className,
        showMyProfileButton,
        profilePath = "",
        settingsPath = "",
        onClickSettings = noop,
        onClickMyProfile = noop,
        onClickLogout = noop,
      } = this.props;

      const { open } = this.state;

      const showMyProfile = () => {
        closeMenu();
        setTimeout(onClickMyProfile, 50);
      };

      const showSettings = () => {
        closeMenu();
        setTimeout(onClickSettings, 50);
      };

      const logout = () => {
        closeMenu();
        setTimeout(onClickLogout, 50);
      };

      const containerClassName = open
        ? classnames(className, classes.container, classes.open)
        : classnames(className, classes.container, classes.closed);

      return (
        <div className={containerClassName} ref={this._ref}>
          <ToolbarUserMenuButton
            label={this.props.label}
            onClick={toggleMenu}
          />
          <Menu
            className={classnames("userMenu", classes.userMenu)}
            open={open}
            disableRestoreFocus={true}
            disableAutoFocusItem={true}
            anchorEl={open ? this._ref.current : null}
            getContentAnchorEl={null}
            anchorOrigin={ANCHOR_ORIGIN}
            transformOrigin={TRANSFORM_ORIGIN}
            onClose={closeMenu}
          >
            {showMyProfileButton && (
              <MenuItem
                key="my-profile-menu-item"
                className={classnames("myProfileView", classes.userMenuItem)}
                button={true}
                onClick={clickHandler(showMyProfile)}
              >
                <Link
                  href={profilePath}
                  underline={UnderlineOptions.NONE}
                  onClick={closeMenu}
                >
                  My Profile
                </Link>
              </MenuItem>
            )}
            <MenuItem
              key="settings-menu-item"
              className={classnames("settingsView", classes.userMenuItem)}
              button={true}
              onClick={clickHandler(showSettings)}
            >
              <Link
                href={settingsPath}
                underline={UnderlineOptions.NONE}
                onClick={closeMenu}
              >
                Settings
              </Link>
            </MenuItem>
            <MenuItem
              key="log-out-menu-item"
              className={classnames("logout", classes.userMenuItem)}
              button={true}
              onClick={logout}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      );
    }
  });

export default ToolbarUserMenu;
