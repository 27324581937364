import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { schemaView as styles } from "./styles";
import { JsonView } from "@components";
import Header from "./Header";

export interface Model {
  className?: string;
  json?: string;
}

type Props = WithStyles<typeof styles> & Model;

export const SchemaView = withStyles(styles)((props: Props) => {

  const { classes, className, json } = props;

  return (
    <div className={classnames("schemaView", className, classes.container)}>
      <Header
        className={classes.header}
        title="Schema"
      />
      <JsonView
        className={classnames("schemaJsonViewer", classes.jsonViewer)}
        json={json}
        width="auto"
        height="auto"
        fontSize={12}
        readOnly={true}
        highlightActiveLine={false}
        aceOptions={{
          highlightGutterLine: false,
        }}
      />
    </div>
  );
});

export default SchemaView;
