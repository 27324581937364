import { Record } from "immutable";
import { getStringValue } from "@util";

export interface DataLakePreSignedUrlAttributes {
  filePath: string;
  downloadURL: string;
}

export class DataLakePreSignedUrl extends Record({
  filePath: "",
  downloadURL: "",
}) implements DataLakePreSignedUrlAttributes {

  public static EMPTY: DataLakePreSignedUrl = new DataLakePreSignedUrl();

  public readonly filePath: string;
  public readonly downloadURL: string;

  public getFilePath(): string {
    return getStringValue(this.filePath);
  }

  public getDownloadUrl(): string {
    return getStringValue(this.downloadURL);
  }
}

export default DataLakePreSignedUrl;
