import React from "react";
import { User } from "@data";
import { AppSchema } from "@schemas";
import { isEmptyString } from "@util";
import { connect } from "react-redux";
import {
  useSecurityGroupUsers,
  useUsersWhoDoNotBelongToSecurityGroup,
} from "@hooks";
import { updateSelectedUsers } from "../actions";
import { getGroupName, getUserId, isAddingUserToGroup, getUsers } from "../selectors";
import SelectUser, { Actions, Model } from "../components/SelectUser";

interface ContainerModel extends Model {
  userId?: string;
  groupName?: string;
  addUserToGroup?: boolean;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const SelectUserToAddToGroup = (props: Props) => {

  const { userId = "", groupName = "", ...otherProps } = props;

  const [model, actions] = useUsersWhoDoNotBelongToSecurityGroup(groupName);

  const { users: items, ...remainingModel } = model;

  // Let security groups list component handle default value if a user has not been selected yet
  const noResultsLabel = React.useMemo(() =>
    isEmptyString(groupName) ? undefined : "Group already contains all available users", [groupName]);

  // Let security groups list component handle default value if a user has not been selected yet
  const loadingLabel = React.useMemo(() =>
    isEmptyString(groupName) ? undefined : "Loading users who can be added to group...", [groupName]);

  return (
    <SelectUser
      {...otherProps}
      {...remainingModel}
      {...actions}
      items={items}
      noResultsLabel={noResultsLabel}
      loadingLabel={loadingLabel}
    />
  );
};

const SelectUserToRemoveFromGroup = (props: Props) => {

  const { userId = "", groupName = "", ...otherProps } = props;

  const [model, actions] = useSecurityGroupUsers(groupName);

  const { users: items, ...remainingModel } = model;

  // Let security groups list component handle default value if a user has not been selected yet
  const noResultsLabel = React.useMemo(() =>
    isEmptyString(groupName) ? undefined : "Group does not contain any users", [groupName]);

  // Let security groups list component handle default value if a user has not been selected yet
  const loadingLabel = React.useMemo(() =>
    isEmptyString(groupName) ? undefined : "Loading users who belong to group...", [groupName]);

  return (
    <SelectUser
      {...otherProps}
      {...remainingModel}
      {...actions}
      items={items}
      showSearchFilters={false}
      noResultsLabel={noResultsLabel}
      loadingLabel={loadingLabel}
    />
  );
};

const SelectUserContainer = (props: Props) => {

  const { addUserToGroup, ...otherProps } = props;

  if (addUserToGroup) {
    return <SelectUserToAddToGroup {...otherProps} />;
  } else {
    return <SelectUserToRemoveFromGroup {...otherProps} />;
  }
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  userId: getUserId(state),
  groupName: getGroupName(state),
  addUserToGroup: isAddingUserToGroup(state),
  selectedItems: getUsers(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setSelectedItems: (user: User[] = []) =>
    dispatch(updateSelectedUsers(user)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(SelectUserContainer);
