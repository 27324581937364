import React from "react";
import { Device, EnrollmentStatus } from "@data";
import { DeviceColumns } from "../reducers";
import { PrimaryIcon } from "./styles";
import {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import { isEmptyString } from "@util";
import ModuleListView from "@components/module-list-view";
import { getPathToDevice } from "@modules/deviceDetails/helpers";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import { getPathToDeviceValidationDetails } from "@modules/deviceValidationDetails/helpers";

export const columns = [
  DeviceColumns.BATCH_ID,
  DeviceColumns.STATUS,
];

export interface Model extends SortedSearchResultsListModel<Device, DeviceColumns> {
  searchQuery?: string;
}

export interface Actions extends SortedSearchResultsListActions<Device, DeviceColumns> {
  showDeviceDetails?: (device: Device) => void;
}

export const DevicesList = (props: Model & Actions) => {

  const {
    items = [],
    searchQuery,
    showDeviceDetails,
    ...remainingProps
  } = props;

  const moduleListItems: ListViewItem<Device>[] = React.useMemo(() =>
    items.map(device => {
      return {
        item: device,
        pathToDetailsView: device.status !== EnrollmentStatus.VALIDATED ?
          getPathToDevice(device.getDeviceId()) : getPathToDeviceValidationDetails(device.getDeviceId()),
        icon: PrimaryIcon,
        columnAttributes: createColumns([
          {
            className: "deviceId",
            value: device.getBatchId(),
            column: DeviceColumns.BATCH_ID,
            firstColumn: true,
          },
          {
            className: "deviceStatus",
            value: device.getStatus(),
            column: DeviceColumns.STATUS,
          },
        ])
      };
    }), [items]);

  return (
    <ModuleListView
      { ...remainingProps }
      columns={columns}
      listViewItems={moduleListItems}
      onClickItem={showDeviceDetails}
      noResultsLabel={isEmptyString(searchQuery) ? "Enter Device ID to view status" : "Device ID not found" }
    />
  );
};

export default DevicesList;
