import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    badge: {
      fontSize: 12,
      fontWeight: 500,
      top: -4,
      right: -4,
    },
  });

export default styles;
