import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { resetAppState, setAuthTokenExpiryTime } from "@main/actions";
import { getRedirectUri } from "@modules/login/selectors";
import { Redirect, Route, Switch } from "react-router-dom";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  canRestoreSession,
  getModules,
  isAccessDenied,
  isInformationBannerVisible,
  isPortalLoaded,
} from "@main/selectors";
import LoginPage, { MODULE_PATH as LOGIN_PATH } from "./login";
import InformationBanner from "@components/information-banner";
import AppController from "@main/containers/AppController";
import { usePortalRedirect } from "@hooks";
import LoginPageRedirect from "./login/LoginPageRedirect";
import StartSessionListener from "./StartSessionListener";
import IdleSessionListener from "./IdleSessionListener";
import EndSessionListener from "./EndSessionListener";
import KeepServiceSessionAlive from "./KeepServiceSessionAlive";
import RestoreSession from "./RestoreSession";
import styles from "./styles";
import Module from "@data/Module";

interface Model {
  isLoggedIn?: boolean;
  sessionRestorable?: boolean;
  accessDenied?: boolean;
  showInformationBanner?: boolean;
  loginRedirectUri?: string;
  modules?: Module[];
}

interface Actions {
  onRestoreSessionFailed?: () => void;
  resetApp?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const Portal = withStyles(styles)((props: Props) => {

  const {
    classes,
    isLoggedIn,
    sessionRestorable = false,
    accessDenied,
    showInformationBanner,
    loginRedirectUri = "/",
    modules = [],
    resetApp = noop,
    onRestoreSessionFailed = noop,
  } = props;

  const redirectUri = usePortalRedirect(modules);

  const [restoringSession, setRestoringSession] = React.useState(sessionRestorable);

  const restoreSessionFailed = React.useCallback(() => {
    onRestoreSessionFailed();
    setRestoringSession(false);
  }, [onRestoreSessionFailed, setRestoringSession]);

  React.useEffect(() => {
    if (accessDenied) {
      resetApp();
    }
  }, [accessDenied, resetApp]);

  // If we have determined that the session is restorable, invoke logic to begin restoring session
  React.useEffect(() => {
    if (sessionRestorable && !restoringSession) {
      setRestoringSession(true);
    }
  }, [sessionRestorable, restoringSession, setRestoringSession]);

  // Restore session flag can be safely flipped once we are logged in
  React.useEffect(() => {
    if (isLoggedIn && restoringSession) {
      setRestoringSession(false);
    }
  }, [isLoggedIn, restoringSession, setRestoringSession]);

  if (redirectUri) {
    return <Redirect to={redirectUri} />;
  }

  return (
    <div id="iot-portal" className={classes.container}>
      {showInformationBanner && <InformationBanner />}
      <Switch>
        <Route path={LOGIN_PATH}>
          {isLoggedIn && <Redirect to={loginRedirectUri || "/"} />}
          {!isLoggedIn && (
            <LoginPage>
              {restoringSession && (
                <RestoreSession
                  restoreSessionFailed={restoreSessionFailed}
                />
              )}
              {!restoringSession && (
                <StartSessionListener />
              )}
            </LoginPage>
          )}
        </Route>
        <Route>
          {isLoggedIn && (
            <AppController>
              <IdleSessionListener />
            </AppController>
          )}
          {!isLoggedIn && <LoginPageRedirect />}
        </Route>
      </Switch>
      <EndSessionListener>
        <KeepServiceSessionAlive />
      </EndSessionListener>
    </div>
  );
});

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  accessDenied: isAccessDenied(state),
  isLoggedIn: isPortalLoaded(state),
  sessionRestorable: canRestoreSession(state),
  showInformationBanner: isInformationBannerVisible(state),
  loginRedirectUri: getRedirectUri(state),
  modules: getModules(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  resetApp: () => dispatch(resetAppState()),
  onRestoreSessionFailed: () => dispatch(setAuthTokenExpiryTime()),
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(Portal);
