import SchemaIcon from "@material-ui/icons/Description";
import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const PrimaryIcon = SchemaIcon;
export { SchemaIcon };

export const primaryColor = Color.SIGNIFY_GREEN;

export const listItem = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "center",
    },
    label: {
      color: Color.TEXT,
      cursor: "default",
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
      textTransform: "none",
      wordBreak: "break-word",
    },
    icon: {
      color: primaryColor,
      alignSelf: "center",
      width: 16,
      height: 16,
      marginTop: 0,
      marginRight: 8,
    },
    version: {
      marginLeft: 8,
    },
  });

export const controls = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "inline-flex",
      justifyContent: "flex-end",
    },
    refreshButton: {
      color: primaryColor,
    },
    controls: {

    },
    search: {
      marginTop: 15,
    },
    schemasList: {
      "& .tableHeaderColumn.version, & .tableBodyColumn.version": {
        paddingLeft: 25,
        width: 125,
      },
    },
  });

export const header = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    refreshButton: {
      color: primaryColor,
    },
    controls: {
      flex: "1 0 auto",
      display: "inline-flex",
      justifyContent: "flex-end",
    },
    search: {
      marginTop: 15,
    },
    schemasList: {
      "& .tableHeaderColumn.version, & .tableBodyColumn.version": {
        paddingLeft: 25,
        width: 125,
      },
    },
  });

export const styles = () =>
  createStyles({
    container: {
      color: primaryColor,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    summary: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      justifyContent: "center",
    },
    refreshButton: {
      color: primaryColor,
    },
    controls: {
      flex: "1 0 auto",
      display: "inline-flex",
      justifyContent: "flex-end",
    },
    search: {
      marginTop: 15,
    },
    schemasList: {
      "& .tableHeaderColumn.version, & .tableBodyColumn.version": {
        paddingLeft: 25,
        width: 125,
      },
    },
  });

export default styles;
