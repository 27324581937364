import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 21,
      lineHeight: "1",
    },
    list: {
      "& th.actions > label": {
        display: "none",
      },
      "& th.name, & td.name": {
        width: "20%",
      },
      "& th.description, & td.description": {
        width: "30%",
      },
      "& th.required, & td.required": {
        width: "15%",
      },
      "& th.actions, & td.actions": {
        width: "15%",
      },
      "& th.deleteIconButton, & td.deleteIconButton": {
        width: "15%",
      },
      "& td.deleteIconButton > button.deleteIconButton": {
        minWidth: "auto",
        width: 36,
        height: 36,
        marginLeft: "auto",
      },
    },
    popover: {
    },
  });

export default styles;
