import React from "react";
import classnames from "classnames";
import QualityReport from "@data/QualityReport";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import CodeCoverageHistory, {
  CodeCoverageHistoryActions,
  CodeCoverageHistoryModel,
} from "./CodeCoverageHistory";
import CodeCoverageStats from "./CodeCoverageStats";
import styles from "./styles";

export interface CodeCoverageModel extends CodeCoverageHistoryModel {
  className?: string;
  title?: string;
  reports?: QualityReport[];
  showLineCoverageOnly?: boolean;
}

export interface CodeCoverageActions extends CodeCoverageHistoryActions {
}

type Model = CodeCoverageModel;
type Actions = CodeCoverageActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const CodeCoverage = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Code Coverage",
    reports = [],
    showLineCoverageOnly = true,
    children,
    ...otherProps
  } = props;

  return (
    <div
      className={classnames("codeCoverage", className, classes.container, {
        [classes.showLineCoverageOnly]: showLineCoverageOnly,
      })}
    >
      <Typography className={classnames("title", classes.title)} variant="h5">
        {title}
      </Typography>
      <CodeCoverageStats
        className={classnames("stats", classes.stats)}
        currentReport={reports[0] || QualityReport.EMPTY}
        previousReport={reports[1] || QualityReport.EMPTY}
        showLineCoverageOnly={showLineCoverageOnly}
      />
      <CodeCoverageHistory
        {...otherProps}
        className={classnames("history", classes.history)}
        reports={reports}
        showLineCoverageOnly={showLineCoverageOnly}
      />
      {children}
    </div>
  );
});

export default CodeCoverage;
