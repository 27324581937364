import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { DataSetRequestWizardSelectors } from "../selectors";
import MakeRequestDialog, {
  MakeRequestListItemAttributes,
  MakeRequestDialogActions as Actions,
  MakeRequestDialogModel as Model,
} from "../components/MakeRequestDialog";
import { useUploadTermsConditionsFile } from "@hooks";

interface ContainerModel extends Model {
  editMode?: boolean;
  successTitle?: string;
  infoTitle?: string;
  dataSetRequestId?: string;
  termsConditionsFile?: File | null;
  termsConditionsFileProvided?: boolean;
  createDataSetRequestLabel?: string;
  createDataSetRequestLoadingMessage?: string;
  showCreateDataSetRequestLoadingIndicator?: boolean;
  createDataSetRequestErrorMessage?: string;
  showCreateDataSetRequestErrorView?: boolean;
  createDataSetRequestSuccessMessage?: string;
  showCreateDataSetRequestSuccessView?: boolean;
  uploadTermsConditionsLabel?: string;
  uploadTermsConditionsLoadingMessage?: string;
  uploadTermsConditionsErrorMessage?: string;
  uploadTermsConditionsSuccessMessage?: string;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const MakeRequestDialogContainer = (props: Props) => {

  const {
    dataSetRequestId,
    editMode = false,
    termsConditionsFile = null,
    title: defaultTitle = `${(editMode ? "Updating" : "Creating")} Data Set Request`,
    successTitle = `Data Set Request ${(editMode ? "Updated" : "Created")}`,
    infoTitle = "Please review the information below before proceeding",
    termsConditionsFileProvided = termsConditionsFile !== null,
    createDataSetRequestLabel = `${(editMode ? "Update" : "Create")} Data Set Request`,
    createDataSetRequestLoadingMessage = `${(editMode ? "Updating" : "Creating")} Data Set Request...`,
    showCreateDataSetRequestLoadingIndicator,
    createDataSetRequestErrorMessage = `Failed to ${(editMode ? "Update" : "Create")} Data Set Request`,
    showCreateDataSetRequestErrorView,
    createDataSetRequestSuccessMessage = `Data Set Request ${(editMode ? "Updated" : "Created")}`,
    showCreateDataSetRequestSuccessView,
    uploadTermsConditionsLabel = "Upload Terms & Conditions",
    uploadTermsConditionsLoadingMessage = "Uploading Terms & Conditions...",
    uploadTermsConditionsErrorMessage = "Failed to Upload Terms & Conditions",
    uploadTermsConditionsSuccessMessage = "Terms and Conditions Uploaded Successfully",
    ...otherProps
  } = props;

  const createDataSetRequestListItem = React.useMemo<MakeRequestListItemAttributes>(() => ({
    id: "create_data_set_request",
    label: createDataSetRequestLabel,
    loadingMessage: createDataSetRequestLoadingMessage,
    showLoadingIndicator: showCreateDataSetRequestLoadingIndicator,
    errorMessage: createDataSetRequestErrorMessage,
    showErrorView: showCreateDataSetRequestErrorView,
    successMessage: createDataSetRequestSuccessMessage,
    showSuccessView: showCreateDataSetRequestSuccessView,
  }), [
    createDataSetRequestLabel,
    createDataSetRequestLoadingMessage,
    showCreateDataSetRequestLoadingIndicator,
    createDataSetRequestErrorMessage,
    showCreateDataSetRequestErrorView,
    createDataSetRequestSuccessMessage,
    showCreateDataSetRequestSuccessView,
  ]);

  const [model, { uploadFile }] = useUploadTermsConditionsFile({
    file: termsConditionsFile || undefined,
    dataSetRequestId,
  });

  const {
    showLoadingIndicator: showUploadTermsConditionsLoadingIndicator,
    showErrorView: showUploadTermsConditionsErrorView,
    showSuccessView: showUploadTermsConditionsSuccessView,
  } = model;

  const uploadTermsConditionsListItem = React.useMemo<MakeRequestListItemAttributes>(() => ({
    id: "upload_terms_conditions",
    label: uploadTermsConditionsLabel,
    loadingMessage: uploadTermsConditionsLoadingMessage,
    showLoadingIndicator: showUploadTermsConditionsLoadingIndicator,
    errorMessage: uploadTermsConditionsErrorMessage,
    showErrorView: showUploadTermsConditionsErrorView,
    successMessage: uploadTermsConditionsSuccessMessage,
    showSuccessView: showUploadTermsConditionsSuccessView,
  }), [
    uploadTermsConditionsLabel,
    uploadTermsConditionsLoadingMessage,
    showUploadTermsConditionsLoadingIndicator,
    uploadTermsConditionsErrorMessage,
    showUploadTermsConditionsErrorView,
    uploadTermsConditionsSuccessMessage,
    showUploadTermsConditionsSuccessView,
  ]);

  const items = React.useMemo<MakeRequestListItemAttributes[]>(() =>
    [createDataSetRequestListItem]
      .concat(!termsConditionsFileProvided ? [] : [uploadTermsConditionsListItem]),
    [createDataSetRequestListItem, termsConditionsFileProvided, uploadTermsConditionsListItem]);

  const successMessage = React.useMemo(() => {
    if (!termsConditionsFileProvided) {
      return showCreateDataSetRequestSuccessView
        ? `Your data set request has been ${(editMode ? "updated" : "created")}!`
        : "";
    } else {
      return showCreateDataSetRequestSuccessView && showUploadTermsConditionsSuccessView
        ? (`Your data set request has been ${(editMode ? "updated" : "created")} ` +
          "with the terms & conditions file that you provided.")
        : "";
    }
  }, [
    editMode,
    termsConditionsFileProvided,
    showCreateDataSetRequestSuccessView,
    showUploadTermsConditionsSuccessView,
  ]);

  const infoMessage = React.useMemo(() => {
    if (!termsConditionsFileProvided) {
      return "";
    } else if (showCreateDataSetRequestSuccessView && showUploadTermsConditionsErrorView) {
      return `Your data set request has been ${(editMode ? "updated" : "created")}; ` +
        "however, we ran into a problem trying to upload the Terms & Conditions file that you " +
        "provided. Please try again from the data set request details page.";
    } else {
      return "";
    }
  }, [
    editMode,
    termsConditionsFileProvided,
    showCreateDataSetRequestSuccessView,
    showUploadTermsConditionsErrorView,
  ]);

  const title = React.useMemo(() => {
    if (!termsConditionsFileProvided) {
      return showCreateDataSetRequestSuccessView ? successTitle : defaultTitle;
    } else {
      if (showCreateDataSetRequestSuccessView && showUploadTermsConditionsSuccessView) {
        return successTitle;
      } else if (showCreateDataSetRequestSuccessView && showUploadTermsConditionsErrorView) {
        return infoTitle;
      } else {
        return defaultTitle;
      }
    }
  }, [
    termsConditionsFileProvided,
    showCreateDataSetRequestSuccessView,
    showUploadTermsConditionsSuccessView,
    showUploadTermsConditionsErrorView,
    defaultTitle,
    successTitle,
    infoTitle,
  ]);

  // Start the file upload once the create data set request has finished
  React.useEffect(() => {
    if (showCreateDataSetRequestSuccessView) {
      uploadFile();
    }
  }, [
    showCreateDataSetRequestSuccessView,
    uploadFile,
  ]);

  return (
    <MakeRequestDialog
      {...otherProps}
      title={title}
      items={items}
      successMessage={successMessage}
      infoMessage={infoMessage}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  editMode: DataSetRequestWizardSelectors.isEditModeActive(state),
  dataSetRequestId: DataSetRequestWizardSelectors.getDataSetRequestRef(state),
  showCreateDataSetRequestLoadingIndicator: DataSetRequestWizardSelectors.isLoadingIndicatorVisible(state),
  showCreateDataSetRequestErrorView: DataSetRequestWizardSelectors.isErrorMessageVisible(state),
  showCreateDataSetRequestSuccessView: DataSetRequestWizardSelectors.isSuccessMessageVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps
)(MakeRequestDialogContainer);
