import React from "react";
import classnames from "classnames";
import { isEmptyString } from "@util";
import { Application } from "@data";
import ApplicationIcon from "@material-ui/icons/ViewCarousel";
import ApplicationsListColumn from "../ApplicationsListColumn";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface ApplicationsListItemModel {
  className?: string;
  item?: Application;
  column?: ApplicationsListColumn;
}

export interface ApplicationsListItemActions {
}

type Model = ApplicationsListItemModel;
type Actions = ApplicationsListItemActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

const ApplicationId = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  id?: string,
}) => {

  const { classes, className, id = "" } = props;

  if (isEmptyString(id)) {
    return null;
  }

  return (
    <div className={classnames("applicationId", className, classes.applicationId)}>
      <ApplicationIcon className={classnames("icon", classes.icon)} />
      <label className={classnames("label", classes.label)}>{id}</label>
    </div>
  );
});

const ApplicationName = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  name?: string,
}) => {

  const { classes, className, name = "" } = props;

  return (
    <div className={classnames("applicationName", className, classes.applicationName)}>
      <label className={classnames("label", classes.label)}>{name}</label>
    </div>
  );
});

const ApplicationDescription = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  description?: string,
}) => {

  const { classes, className, description = "" } = props;

  return (
    <div className={classnames("applicationDescription", className, classes.applicationDescription)}>
      <label className={classnames("label", classes.label)}>{description}</label>
    </div>
  );
});

export const ApplicationsListItem = withStyles(styles)((props: Props) => {

  const {
    classes,
    className: initialClassName,
    item = Application.EMPTY,
    column = ApplicationsListColumn.NONE,
    children,
  } = props;

  const className = React.useMemo(() =>
      classnames("applicationsListItem", initialClassName, classes.container),
    [initialClassName, classes]);

  const content = React.useMemo(() => {
    switch (column) {
      case ApplicationsListColumn.ID:
        return (
          <ApplicationId
            className={className}
            id={item.getId()}
          />
        );
      case ApplicationsListColumn.NAME:
        return (
          <ApplicationName
            className={className}
            name={item.getName()}
          />
        );
      case ApplicationsListColumn.DESCRIPTION:
        return (
          <ApplicationDescription
            className={className}
            description={item.getDescription()}
          />
        );
      default:
        return null;
    }
  }, [column, className, item]);

  if (Application.EMPTY.equals(item) || ApplicationsListColumn.NONE === column) {
    return null;
  }

  return (
    <div className={classnames("applicationsListItem", className, classes.container)}>
      {content}
      {children}
    </div>
  );
});

export default ApplicationsListItem;
