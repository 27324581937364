import { Record } from "immutable";
import { getStringValue } from "@util";

export interface WorkloadNameAttributes {
  name: string;
}

export class WorkloadName extends Record({
  name: "",
}) implements WorkloadNameAttributes {

  public static EMPTY: WorkloadName = new WorkloadName();

  public readonly name: string;

  public static from(workloadName: string): WorkloadName {

    return new WorkloadName({
      name: workloadName,
    });
  }

  public getName(): string {
    return getStringValue(this.name);
  }
}

export default WorkloadName;
