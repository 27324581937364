import { Theme } from "@material-ui/core/styles/createTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Color, INFORMATION_BANNER_HEIGHT } from "@components";

export const headerBackgroundColor = Color.HEADER;
export const headerTextColor = Color.WHITE;
export const drawerWidth = 240;

export const toolbarUserMenuButton = () =>
  createStyles({
    button: {
      color: headerTextColor,
      height: "100%",
      display: "inline-flex",
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    label: {
      fontSize: 12,
      lineHeight: "12px",
      fontWeight: 400,
      textTransform: "none",
    },
    userIcon: {
      width: 14,
      height: 14,
      marginLeft: 8,
      marginRight: 8,
    },
    dropdownIcon: {
      width: 10,
      height: 10,
      marginLeft: 8,
      marginRight: 8,
    },
  });

export const toolbarUserMenu = () =>
  createStyles({
    container: {
      alignItems: "center",
      alignContent: "center",
      display: "flex",
      height: "100%",
    },
    open: {
      backgroundColor: Color.BLACK2,
    },
    closed: {
      backgroundColor: "inherit",
    },
    userMenu: {
      width: "auto",
      height: "100%",
      padding: 0,
    },
    userMenuItem: {
      color: Color.TEXT,
      fontSize: 16,
      lineHeight: "24px",
      padding: "11px 16px",
      fontWeight: 400,
    },
  });

export const layout = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "flex-start",
    },
    informationBannerVisible: {
      marginTop: INFORMATION_BANNER_HEIGHT,
    },
  });

const layoutHeaderStyles = createStyles({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
  },
  appBar: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "stretch",
    justifyContent: "flex-end",
    backgroundColor: headerBackgroundColor,
    color: headerTextColor,
  },
  toolbar: {
    flex: "1 0 auto",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: headerBackgroundColor,
    color: headerTextColor,
  },
  backButton: {
    // TODO: Remove if container not needed
  },
  toolbarUserMenu: {
    margin: 0,
    padding: 0,
  },
  title: {
    backgroundColor: headerBackgroundColor,
    color: headerTextColor,
    fontSize: 28,
    fontWeight: 400,
    paddingBottom: 20,
    paddingLeft: 16,
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    zIndex: 1,
    boxShadow: "0 4px 6px -2px rgba(0,0,0,0.5)",
  },
  breadcrumbTitle: {
    flexFlow: "column nowrap",
    alignItems: "start",
    paddingBottom: 10,
  },
  breadcrumbs: {
    color: headerTextColor,
    paddingTop: 5,
  },
  subtitle: {
    backgroundColor: headerBackgroundColor,
    color: headerTextColor,
    fontSize: 18,
    fontWeight: 400,
    paddingBottom: 20,
    paddingLeft: 16,
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    zIndex: 1,
    boxShadow: "0 4px 6px -2px rgba(0,0,0,0.5)",
  },
  tabsContainer: {
    backgroundColor: Color.HEADER_TABS,
    color: headerTextColor,
    paddingTop: 10,
    paddingBottom: 10,
    zIndex: 1,
    boxShadow: "0 4px 6px -2px rgba(0,0,0,0.5)",
  },
  tabs: {
    marginLeft: 10,
    marginRight: 10,
    minHeight: 0,
  },
  tab: {
    color: Color.WHITE,
    textTransform: "none",
    opacity: "1",
    minWidth: 0,
    minHeight: 0,
    padding: 0,
    margin: 8,
    "& > span": {
      marginLeft: 3,
    },
  },
  tabIndicator: {
    backgroundColor: Color.WHITE,
  },
});

export const layoutHeader = ({ mixins: { toolbar } }: Theme) =>
  createStyles({
    ...layoutHeaderStyles,
    toolbar: ({
      ...layoutHeaderStyles.toolbar,
      ...toolbar,
    }) as CSSProperties,
    maintenanceReminder: {
      marginTop: 65,
    },
    titleName: {
      display: "flex",
      alignItems: "center",
    },
    documentationButton: {
      padding: "9px 6px",
      "& svg": {
        height: "0.65em",
      }
    }
  });

export const layoutContent = (theme: Theme) =>
  createStyles({
    container: {
      flex: "1",
      display: "flex",
      flexDirection: "column",
      width: `calc(100vw - ${drawerWidth}px)`,
    },
    header: {
    },
    informationBannerVisible: {
      top: INFORMATION_BANNER_HEIGHT,
    },
    title: {
      color: Color.WHITE,
      fontSize: 28,
      fontWeight: 400,
      paddingBottom: 20,
      paddingLeft: 16,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      zIndex: 0,
    },
    subtitle: {
      color: Color.WHITE,
      fontSize: 18,
      fontWeight: 400,
      paddingBottom: 20,
      paddingLeft: 16,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      zIndex: 0,
    },
    toolbar: ({
      ...theme.mixins.toolbar,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-end",
    }) as CSSProperties,
    backButton: {
      justifyContent: "flex-start",
      padding: 0,
      marginRight: "auto",
    },
    toolbarUserMenu: {
      margin: 0,
      padding: 0,
    },
    view: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      minHeight: 500,
      position: "relative",
      padding: "35px 30px 40px",
    },
    hidden: {
      display: "none",
    },
  });
