import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import UploadTrainingCert, { UploadTrainingCertProps } from "@components/upload-training-cert";
import styles from "./styles";

type Props = WithStyles<typeof styles> & UploadTrainingCertProps & {
  children?: React.ReactNode;
};

export const CertificateView = withStyles(styles)((props: Props) => {

  const {
    classes,
    successLabel = "Training certificate uploaded. Click next step to proceed.",
    children,
    ...otherProps
  } = props;

  return (
    <UploadTrainingCert
      {...otherProps}
      className={classnames("certificateView", classes.certificateView)}
      successLabel={successLabel}
    >
      {children}
    </UploadTrainingCert>
  );
});

export default CertificateView;
