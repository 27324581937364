import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { AccessDeniedView } from "@components";
import {
  Actions as DeviceTypesListActions,
  DeviceTypesList,
  Model as DeviceTypesListModel,
} from "../../../deviceTypes/components/DeviceTypesList";
import { DeviceTypeColumn } from "../../../deviceTypes/reducers";
import { styles } from "./styles";

const deviceTypesListColumns = [
  DeviceTypeColumn.NAMESPACE,
  DeviceTypeColumn.NAME,
  DeviceTypeColumn.VERSION,
];

export interface Model extends DeviceTypesListModel {
  title?: string;
  showAccessDenied?: boolean;
}

export type Actions = DeviceTypesListActions;

type Props = WithStyles<typeof styles> & Model & Actions;

export const SoftwareVersionDetails = withStyles(styles)((props: Props) => {

  const { classes, showAccessDenied, title = "", ...otherProps } = props;

  return (
    <div className={classnames("softwareVersionDetails", classes.container)}>
      {title.trim().length > 0 && (
        <label className={classnames("title", classes.title)}>
          {title}
        </label>
      )}
      {showAccessDenied && <AccessDeniedView/>}
      {!showAccessDenied && (
        <DeviceTypesList
          {...otherProps}
          columns={deviceTypesListColumns}
          showLoadMoreButton={false}
        />
      )}
    </div>
  );
});

export default SoftwareVersionDetails;
