import React from "react";
import classnames from "classnames";
import HtmlPreview from "@components/html-preview";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import EmailTemplatesList, {
  EmailTemplatesListActions,
  EmailTemplatesListModel,
} from "@components/email-templates-list";
import { selectEmailTemplate as styles } from "./styles";

export interface Model extends EmailTemplatesListModel {
  emailTemplateSelected?: boolean;
  htmlBody?: string;
  userId?: string;
}

export interface Actions extends EmailTemplatesListActions {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const SelectEmailTemplate = withStyles(styles)((props: Props) => {

  const {
    classes,
    userId,
    children,
    emailTemplateSelected,
    htmlBody,
    ...otherProps
  } = props;

  const title = React.useMemo(() =>
      emailTemplateSelected
        ? `We will use the following e-mail template to send password reset e-mail to ${userId}`
        : `Select the e-mail template that should be used to send password reset e-mail to ${userId}` ,
    [emailTemplateSelected, userId]);

  return (
    <React.Fragment>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      <EmailTemplatesList
        {...otherProps}
        className={classnames("emailTemplates", classes.container)}
        selectable={true}
        showSearch={false}
        selectAllDisabled={true}
      />
      {emailTemplateSelected && (
        <HtmlPreview
          className={classnames("htmlPreview", classes.htmlPreview)}
          srcDoc={htmlBody}
        />
      )}
      {children}
    </React.Fragment>
  );
});

export default SelectEmailTemplate;
