import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
    },
    informationContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    setSecretContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    setTitle: {
      marginLeft: 10,
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 16,
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
    applicationId: {
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "16px",
      marginTop: 16,
    },
    applicationSecretTextField: {
      marginTop: 20,
      "& label": {
        fontSize: 14,
        lineHeight: "14px",
        fontWeight: 300,
        padding: "2px 0",
        margin: 0,
      },
      "& input": {
        fontSize: 14,
        lineHeight: "35px",
        fontWeight: 400,
        margin: 0,
      },
    },
  });

export default styles;
