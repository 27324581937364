import React from "react";
import classnames from "classnames";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Error from "@material-ui/icons/Error";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { clickHandler } from "../../../../util";
import { errorIcon, styles } from "./styles";

const ErrorIcon = withStyles(errorIcon)(Error);

export interface Model {
  title?: string;
  errorMessage?: string;
  showErrorMessage?: boolean;
}

export interface Actions {
  retry?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const StartSession = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Loading...",
    errorMessage = "Oops, something went wrong. Please try to login again.",
    showErrorMessage,
    retry,
  } = props;

  return (
    <Paper className={classnames("startSession", classes.container)} elevation={2}>
      {!showErrorMessage && (
        <div className={classnames("loading", classes.loading)}>
          <label className={classes.label}>{title}</label>
        </div>
      )}
      {showErrorMessage && (
        <React.Fragment>
          <ErrorIcon />
          <div className={classnames("expired", classes.expired)}>
            <label className={classes.label}>{errorMessage}</label>
            <Button
              className={classnames("button", classes.button)}
              variant="text"
              color="inherit"
              onClick={clickHandler(retry)}
            >
              Click <span className={classes.underlined}>here</span> to try again.
            </Button>
          </div>
        </React.Fragment>
      )}
    </Paper>
  );
});

export default StartSession;
