import createTheme from "@material-ui/core/styles/createTheme";
import { baseThemeOptions, Color } from "@components";

export const theme = createTheme({
  ...baseThemeOptions,
  palette: {
    type: "dark",
    primary: {
      main: Color.SIGNIFY_GREEN,
      light: Color.BLUE_GREY,
      dark: Color.TEXT,
      contrastText: Color.WHITE,
    },
    text: {
      primary: Color.WHITE,
      secondary: Color.BLUE_GREY,
      disabled: Color.GREY3,
      hint: Color.GREY3
    },
  },
});

export default theme;
