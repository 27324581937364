import { isEmptyString, isValidInteger } from "@util";
import {
  DeviceTypeListItemAttributes,
  DeviceTypeModelV2Attributes,
  DeviceTypeModelV3Attributes,
  DeviceTypeModelVersion,
  DeviceTypeState,
} from "@data";
import {
  createQueryParams,
  ETagHeaders,
  ETagLocationHeaders,
  getETagHeaders,
  getETagLocationHeaders,
  getEtagResponseHeader,
  getResponseHeaderValue,
  makeApiRequest,
  makeJsonApiRequest,
  withAuthToken,
  withDeviceTypeV2ApiSupport,
  withRequiredArguments,
} from "./helpers";
import isDeviceTypeV2APIEnabled from "@util/isDeviceTypeV2APIEnabled";

const REGIONAL_API = process.env.REACT_APP_REGIONAL_API || "";

if (isEmptyString(REGIONAL_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_REGIONAL_API");
}

const DEVICE_TYPE_V2_API = process.env.REACT_APP_DEVICE_TYPE_V2_API || "";

if (isDeviceTypeV2APIEnabled() && isEmptyString(DEVICE_TYPE_V2_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_DEVICE_TYPE_V2_API");
}

const DEFAULT_LIMIT = 50;

const DTS_MODEL_VERSION_HEADER = "X-IoT-Platform-Dts-Model-Version";

const getDeviceTypeModelVersionResponseHeader = (response: Response): DeviceTypeModelVersion => {

  const modelVersion = getResponseHeaderValue(response, DTS_MODEL_VERSION_HEADER);

  // Typescript does not support reverse lookups of string enums
  switch (modelVersion) {
    case DeviceTypeModelVersion.REGIONAL:
      return DeviceTypeModelVersion.REGIONAL;
    case DeviceTypeModelVersion.HISTORICAL:
      return DeviceTypeModelVersion.HISTORICAL;
    default:
      return DeviceTypeModelVersion.NONE;
  }
};

export interface GetDeviceTypesResponse {
  items: DeviceTypeListItemAttributes[];
  paging?: {
    next?: string | null;
  };
}

export interface GetSoftwareVersionsResponse {
  softwareVersions?: string[];
  deviceType: {
    nameAndVersion: string;
  };
}

export interface GetDeviceTypesBySoftwareVersionResponse {
  deviceTypeVersions: GetSoftwareVersionsResponse[];
}

export interface GetDeviceTypeResponse extends ETagHeaders, DeviceTypeModelV3Attributes {
  modelVersion?: DeviceTypeModelVersion;
}

export const getDeviceTypes = (authToken: string,
                               next: string = "",
                               nameFilter: string = "",
                               limit: number = DEFAULT_LIMIT): Promise<GetDeviceTypesResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams({
      includeModelVersion: true,
      ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, Math.min(DEFAULT_LIMIT, limit)) }),
      nameFilter,
      next,
    });

    const url = `${REGIONAL_API}/device-management/modeling/v1/types${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch device types failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getDeviceTypeV3 = (authToken: string,
                                namespace: string,
                                name: string,
                                version: string = ""): Promise<GetDeviceTypeResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/modeling/v1/types` +
      (isEmptyString(version) ? `/name/${namespace}:${name}/latest` : `/identity/${namespace}:${name}:${version}`);

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage =
      `Failed to get device type [${namespace}:${name}] w/ version [${version || "latest"}]`;

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(response => {

        const etag = getEtagResponseHeader(response);
        const modelVersion = getDeviceTypeModelVersionResponseHeader(response);

        return response.json()
          .then(attrs => ({
            etag,
            modelVersion,
            ...attrs,
          }));
      });
  };

  return validate().then(makeRequest);
};

export const getDeviceTypeV2 = (authToken: string,
                                namespace: string,
                                name: string,
                                version: string = ""): Promise<DeviceTypeModelV2Attributes> => {

  const validate = () => withDeviceTypeV2ApiSupport()
    .then(() => withAuthToken(authToken))
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
    ]));

  const makeRequest = () => {

    const url = `${DEVICE_TYPE_V2_API}/v2/dm/devtype/ns/${namespace}/n/${name}` +
      (isEmptyString(version) ? "/latest" : `/v/${version}`);

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage =
      `Failed to get device type [${namespace}:${name}] w/ version [${version || "latest"}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getLatestDeviceTypeByState = (authToken: string,
                                           namespace: string,
                                           name: string,
                                           state: string): Promise<GetDeviceTypeResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
      ["State", state],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/modeling/v1/types/name/`
    + `${namespace}:${name}/latest/state/${state}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage =
      `Failed to get latest device type [${namespace}:${name}] w/ state ${state}.`;

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(response => {

        const etag = getEtagResponseHeader(response);
        const modelVersion = getDeviceTypeModelVersionResponseHeader(response);

        return response.json()
          .then(attrs => ({
            etag,
            modelVersion,
            ...attrs,
          }));
      });
  };

  return validate().then(makeRequest);
};

export const createDeviceTypeV3 = (authToken: string,
                                   namespace: string,
                                   name: string,
                                   json: string): Promise<ETagLocationHeaders> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
      ["Device Type", json],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/modeling/v1/types/name/${namespace}:${name}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to create device type";

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getETagLocationHeaders);
  };

  return validate().then(makeRequest);
};

export const createDeviceTypeV2 = (authToken: string,
                                   namespace: string,
                                   name: string,
                                   json: string): Promise<ETagLocationHeaders> => {

  const validate = () => withDeviceTypeV2ApiSupport()
    .then(() => withAuthToken(authToken))
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
      ["Device Type", json],
    ]));

  const makeRequest = () => {

    const url = `${DEVICE_TYPE_V2_API}/v2/dm/devtype/ns/${namespace}/n/${name}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to create device type";

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getETagLocationHeaders);
  };

  return validate().then(makeRequest);
};

export const editDeviceTypeV3 = (authToken: string,
                                 namespace: string,
                                 name: string,
                                 version: string,
                                 json: string ,
                                 etag: string): Promise<ETagHeaders> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
      ["Version", version],
      ["Device Type", json],
      ["ETag", etag],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/modeling/v1/types/identity/${namespace}:${name}:${version}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
        "If-Match": etag,
      },
      body: json,
    };

    const defaultErrorMessage = `Failed to edit device type [${namespace}:${name}:${version}]`;

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getETagHeaders);
  };

  return validate().then(makeRequest);
};

export const editDeviceTypeV2 = (authToken: string,
                                 namespace: string,
                                 name: string,
                                 version: string,
                                 json: string ,
                                 etag: string): Promise<ETagHeaders> => {

  const validate = () => withDeviceTypeV2ApiSupport()
    .then(() => withAuthToken(authToken))
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
      ["Version", version],
      ["Device Type", json],
      ["ETag", etag],
    ]));

  const makeRequest = () => {

    const url = `${DEVICE_TYPE_V2_API}/v2/dm/devtype/ns/${namespace}/n/${name}/v/${version}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
        "If-Match": etag,
      },
      body: json,
    };

    const defaultErrorMessage = `Failed to edit device type [${namespace}:${name}:${version}]`;

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getETagHeaders);
  };

  return validate().then(makeRequest);
};

const updateDeviceTypeStateV2 = (authToken: string,
                                 namespace: string,
                                 name: string,
                                 version: string,
                                 etag: string,
                                 state: string): Promise<ETagHeaders> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
      ["Version", version],
      ["ETag", etag],
      ["State", state],
    ]));

  const makeRequest = () => {

    const url = `${DEVICE_TYPE_V2_API}/v2/dm/devtype` +
      `/ns/${namespace}/n/${name}/v/${version}/state/${state}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "If-Match": etag,
      },
    };

    const defaultErrorMessage =
      `Failed to transition device type [${namespace}:${name}:${version}] to state [${state}]`;

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getETagHeaders);
  };

  return validate().then(makeRequest);
};

const updateDeviceTypeStateV3 = (authToken: string,
                                 namespace: string,
                                 name: string,
                                 version: string,
                                 etag: string,
                                 state: string): Promise<ETagHeaders> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
      ["Version", version],
      ["ETag", etag],
      ["State", state],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/modeling/v1/types/identity/` +
      `${namespace}:${name}:${version}/state/${state}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "If-Match": etag,
      },
    };

    const defaultErrorMessage =
      `Failed to transition device type [${namespace}:${name}:${version}] to state [${state}]`;

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getETagHeaders);
  };

  return validate().then(makeRequest);
};

const updateDeviceTypeState = (authToken: string,
                               namespace: string,
                               name: string,
                               version: string,
                               etag: string,
                               modelVersion: DeviceTypeModelVersion,
                               state: string): Promise<ETagHeaders> => {

  if (DeviceTypeModelVersion.HISTORICAL === modelVersion) {
    return updateDeviceTypeStateV2(authToken, namespace, name, version, etag, state);
  } else {
    return updateDeviceTypeStateV3(authToken, namespace, name, version, etag, state);
  }
};

export const promoteDeviceType = (authToken: string,
                                  namespace: string,
                                  name: string,
                                  version: string,
                                  etag: string,
                                  modelVersion: DeviceTypeModelVersion): Promise<ETagHeaders> => {

  return updateDeviceTypeState(
    authToken, namespace, name, version, etag, modelVersion, DeviceTypeState.RELEASED);
};

export const deprecateDeviceType = (authToken: string,
                                    namespace: string,
                                    name: string,
                                    version: string,
                                    etag: string,
                                    modelVersion: DeviceTypeModelVersion): Promise<ETagHeaders> => {

  return updateDeviceTypeState(
    authToken, namespace, name, version, etag, modelVersion, DeviceTypeState.DEPRECATED);
};

export const decommissionDeviceType = (authToken: string,
                                       namespace: string,
                                       name: string,
                                       version: string,
                                       etag: string,
                                       modelVersion: DeviceTypeModelVersion): Promise<ETagHeaders> => {

  return updateDeviceTypeState(
    authToken, namespace, name, version, etag, modelVersion, DeviceTypeState.DECOMMISSIONED);
};

export const deleteDeviceType = (authToken: string,
                                 namespace: string,
                                 name: string,
                                 version: string,
                                 etag: string,
                                 modelVersion: DeviceTypeModelVersion): Promise<ETagHeaders> => {

  return updateDeviceTypeState(
    authToken, namespace, name, version, etag, modelVersion, DeviceTypeState.DELETED);
};

export const draftNewDeviceTypeVersionV3 = (authToken: string,
                                            namespace: string,
                                            name: string): Promise<ETagLocationHeaders> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/modeling/v1/types/name/${namespace}:${name}/state/draft`;

    const settings = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${authToken}`,
      },
    };

    const defaultErrorMessage = `Failed to draft new version of device type [${namespace}:${name}]`;

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getETagLocationHeaders);
  };

  return validate().then(makeRequest);
};

export const draftNewDeviceTypeVersionV2 = (authToken: string,
                                          namespace: string,
                                          name: string): Promise<ETagLocationHeaders> => {

  const validate = () => withDeviceTypeV2ApiSupport()
    .then(() => withAuthToken(authToken))
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
    ]));

  const makeRequest = () => {

    const url = `${DEVICE_TYPE_V2_API}/v2/dm/devtype/ns/${namespace}/n/${name}/state/draft`;

    const settings = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${authToken}`,
      },
    };

    const defaultErrorMessage = `Failed to draft new version of device type [${namespace}:${name}]`;

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getETagLocationHeaders);
  };

  return validate().then(makeRequest);
};

export const draftNewDeviceTypeVersion = (authToken: string,
                                          namespace: string,
                                          name: string,
                                          modelVersion: DeviceTypeModelVersion) => {

  if (DeviceTypeModelVersion.HISTORICAL === modelVersion) {
    return draftNewDeviceTypeVersionV2(authToken, namespace, name);
  } else {
    return draftNewDeviceTypeVersionV3(authToken, namespace, name);
  }
};

export const getDeviceTypeSoftwareVersions = (authToken: string,
                                              namespace: string,
                                              name: string,
                                              version: string): Promise<GetSoftwareVersionsResponse> => {

  const validate = () => withDeviceTypeV2ApiSupport()
    .then(() => withAuthToken(authToken))
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
      ["Version", version],
    ]));

  const makeRequest = () => {

    const url = `${DEVICE_TYPE_V2_API}/v2/dm/devtype/ns/${namespace}/n/${name}/v/${version}/software`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage =
      `Failed to get software versions for device type [${namespace}:${name}:${version}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const addSoftwareVersion = (authToken: string,
                                   namespace: string,
                                   name: string,
                                   version: string,
                                   software: string): Promise<void> => {

  const validate = () => withDeviceTypeV2ApiSupport()
    .then(() => withAuthToken(authToken))
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
      ["Version", version],
      ["Software Version", software],
    ]));

  const makeRequest = () => {

    const url = `${DEVICE_TYPE_V2_API}/v2/dm/devtype` +
      `/ns/${namespace}/n/${name}/v/${version}/software/${software}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to add software version [${software}] to ` +
      `device type [${namespace}:${name}:${version}]`;

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(() => Promise.resolve());
  };

  return validate().then(makeRequest);
};

export const removeSoftwareVersion = (authToken: string,
                                      namespace: string,
                                      name: string,
                                      version: string,
                                      software: string): Promise<void> => {

  const validate = () => withDeviceTypeV2ApiSupport()
    .then(() => withAuthToken(authToken))
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
      ["Version", version],
      ["Software Version", software],
    ]));

  const makeRequest = () => {

    const url = `${DEVICE_TYPE_V2_API}/v2/dm/devtype` +
      `/ns/${namespace}/n/${name}/v/${version}/software/${software}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to remove software version [${software}] from ` +
      `device type [${namespace}:${name}:${version}]`;

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(() => Promise.resolve());
  };

  return validate().then(makeRequest);
};

export const getDeviceTypesBySoftwareVersion = (authToken: string,
                                                namespace: string,
                                                name: string,
                                                software: string): Promise<GetDeviceTypesBySoftwareVersionResponse> => {

  const validate = () => withDeviceTypeV2ApiSupport()
    .then(() => withAuthToken(authToken))
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
      ["Software Version", software],
    ]));

  const makeRequest = () => {

    const url = `${DEVICE_TYPE_V2_API}/v2/dm/devtype/ns/${namespace}/n/${name}/software/${software}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to get device types in namespace [${namespace}]` +
      ` w/ name [${name}] that use software version [${software}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
