import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    header: {
      flex: "0 0 auto",
    },
    content: {
      flex: "1 0 auto",
      marginBottom: 25,
    },
    list: {
    },
  });

export default styles;
