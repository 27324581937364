import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      paddingTop: 10,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    searchFilters: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      marginLeft: 4,
      marginTop: 16,
    },
    searchFilter: {
      display: "flex",
      flexFlow: "row nowrap",
      minHeight: 28,
      height: "auto",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "12px",
      paddingLeft: 4,
      paddingRight: 4,
      marginLeft: 4,
      marginTop: 8,
      "&:first-child": {
        marginTop: 0,
      },
      "& span": {
        marginLeft: 2,
        marginRight: 2,
        marginTop: 4,
        marginBottom: 4,
        lineHeight: "18px",
      },
      "& label": {
        cursor: "pointer",
      },
    },
    icon: {
      color: Color.WHITE,
      width: 12,
      height: 12,
    },
    deleteIcon: {
      color: Color.WHITE,
      width: 12,
      height: 12,
    },
    fileNameFilterContainer: {
      marginTop: 10,
    },
    fileNameFilter: {
    },
  });

export default styles;
