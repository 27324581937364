import React from "react";
import {
  EffectivePoliciesView,
} from "@components";
import { EffectivePoliciesModel, EffectivePoliciesActions } from "@components";
import { ManagedPolicy, Policy } from "@data";
import AppSchema from "@schemas";
import { connect } from "react-redux";
import CustomPoliciesView from "./CustomPoliciesView";
import ManagedPoliciesView from "./ManagedPoliciesView";
import { isCustomPoliciesViewActive, isManagedPoliciesViewActive } from "../selectors";
import { showCustomPoliciesView, showManagedPoliciesView } from "../actions";

interface ContainerModel extends EffectivePoliciesModel {
}

interface ContainerActions extends EffectivePoliciesActions {
  showPolicyDetails?: (policy: Policy) => void;
  showManagedPolicyDetails?: (policy: ManagedPolicy) => void;
}

type Props = ContainerModel & ContainerActions;

const EffectivePoliciesViewContainer = (props: Props) => {

  const {
    showPolicyDetails,
    showManagedPolicyDetails,
    ...otherProps
  } = props;

  return (
      <EffectivePoliciesView {...otherProps}>
       <CustomPoliciesView showPolicyDetails={showPolicyDetails} />
       <ManagedPoliciesView showManagedPolicyDetails={showManagedPolicyDetails} />
      </EffectivePoliciesView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  customPoliciesViewSelected: isCustomPoliciesViewActive(state),
  managedPoliciesViewSelected: isManagedPoliciesViewActive(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setManagedPoliciesView: () => dispatch(showManagedPoliciesView()),
  setCustomPoliciesView: () => dispatch(showCustomPoliciesView()),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, Props>(
    mapStateToProps,
    mapDispatchToProps,
)(EffectivePoliciesViewContainer);
