import React from "react";
import classnames from "classnames";
import Link from "@components/link";
import { isEmptyString } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles, { PrimaryIcon } from "./styles";

export interface NameAndDescriptionModel {
  className?: string;
  name?: string;
  description?: string;
  href?: string;
  rel?: string;
  target?: string;
  showIcon?: boolean;
}

export interface NameAndDescriptionActions {
}

type Model = NameAndDescriptionModel;
type Actions = NameAndDescriptionActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const NameAndDescription = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    name,
    description,
    href = "",
    rel,
    target,
    showIcon = true,
    children,
  } = props;

  const noLink = React.useMemo(() => isEmptyString(href), [href]);

  return (
    <div className={classnames("nameAndDescription", className, classes.container, {[classes.noLink]: noLink})}>
      {showIcon && <PrimaryIcon className={classnames("icon", classes.icon)} />}
      <div className={classes.column}>
        {noLink ? (<label>{name}</label>) : (
          <Link
            className={classnames("name", classes.name)}
            href={href}
            rel={rel}
            target={target}
          >
            {name}
          </Link>
        )}
        {!isEmptyString(description) && (
          <label className={classnames("description", classes.description)}>{description}</label>
        )}
      </div>
      {children}
    </div>
  );
});

export default NameAndDescription;
