import React from "react";
import { useHistory } from "react-router-dom";
import { Device } from "@data";
import { getPathToDevice } from "@modules/deviceDetails/helpers";
import { getPathToDeviceValidationDetails } from "@modules/deviceValidationDetails/helpers";
import Devices from "./containers/Devices";

export function DeviceView() {

  const history = useHistory();

  const showDeviceDetails = React.useCallback((device: Device) =>
    history.push(getPathToDevice(device.getDeviceId())), [history]);

  const showDeviceValidationDetails = React.useCallback((device: Device) =>
    history.push(getPathToDeviceValidationDetails(device.getDeviceId())), [history]);

  return (
    <Devices
      showDeviceDetails={showDeviceDetails}
      showDeviceValidationDetails={showDeviceValidationDetails}
    />
  );
}

export default DeviceView;
