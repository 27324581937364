import React from "react";
import { DataSetType } from "@data";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { DataSetRequestWizardActions } from "../actions";
import { DataSetRequestWizardSelectors } from "../selectors";
import DataSetAliasView, { Model, Actions } from "../components/DataSetAliasView";

interface ContainerModel extends Model {
  hidden?: boolean;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const DataSetAliasViewContainer = (props: Props) => {

  const { hidden, ...otherProps } = props;

  if (hidden) {
    return null;
  }

  return <DataSetAliasView {...otherProps} />;
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  hidden: !DataSetRequestWizardSelectors.isDataSetAliasViewSelected(state),
  dataSetAlias: DataSetRequestWizardSelectors.getDataSetAlias(state),
  dataSetAliasError: DataSetRequestWizardSelectors.getDataSetAliasErrorMessage(state),
  description: DataSetRequestWizardSelectors.getDescription(state),
  dataSetGroup: DataSetRequestWizardSelectors.getDataSetGroup(state),
  descriptionError: DataSetRequestWizardSelectors.getDescriptionErrorMessage(state),
  dataSetType: DataSetRequestWizardSelectors.getDataSetType(state),
  tags: DataSetRequestWizardSelectors.getTags(state),
  dataSetAliasDisabled: DataSetRequestWizardSelectors.isEditModeActive(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setDataSetAlias: (dataSetAlias: string) => dispatch(DataSetRequestWizardActions.updateDataSetAlias(dataSetAlias)),
  setDescription: (description: string) => dispatch(DataSetRequestWizardActions.updateDescription(description)),
  setDataSetGroup: (group: string) => dispatch(DataSetRequestWizardActions.updateDataSetGroup(group)),
  setDataSetType: (dataSetType: DataSetType) => dispatch(DataSetRequestWizardActions.updateDataSetType(dataSetType)),
  updateTags: (tags: string[]) => dispatch(DataSetRequestWizardActions.updateTags(tags)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps
)(DataSetAliasViewContainer);
