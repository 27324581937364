import createStyles from "@material-ui/core/styles/createStyles";
import { StyleRules, Theme } from "@material-ui/core/styles";
import { Color } from "../../../../components/styles";

export const enumTextField: StyleRules<string> = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& input": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      padding: "8px 10px",
      margin: 0,
    },
  },
};

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 16,
    },
    formHelperTextError: {
      fontSize: 12,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    enumValueEditor: {
      display: "flex",
      flexFlow: "row nowrap",
    },
    enumValueInput: {
      flex: "1 0 auto",
    },
    addEnumValueButton: {
      flex: "0 0 auto",
      marginTop: 8,
      width: 28,
      height: 28,
      borderRadius: 28,
      padding: 0,
      backgroundColor: Color.WHITE,
      marginLeft: 16,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.54)",
      },
    },
    addEnumValueIcon: {
      width: 24,
      height: 24,
    },
    enumValues: {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    disabled: {
      backgroundColor: Color.GREY3,
    },
    enumValue: {
      height: 28,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "12px",
      paddingLeft: 4,
      paddingRight: 4,
      marginLeft: 8,
      marginRight: 8,
      marginTop: 16,
      "& span": {
        marginLeft: 2,
        marginRight: 2,
      },
    },
    enumValueIcon: {
      color: Color.WHITE,
      width: 12,
      height: 12,
    },
    inputField: {
      flex: "1 0 auto",
    },
    schemaName: {
      marginTop: 25,
    },
    schemaDescription: {
      marginTop: 25,
    },
    schemaTagsLabel: {
      marginTop: 25,
    },
  });

export default styles;
