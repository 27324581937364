import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () => createStyles({
  label: {
    color: Color.GREY7,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px",
    fontStyle: "normal",
  },
  italic: {
    fontStyle: "italic",
  },
  center: {
    textAlign: "center",
  },
  clickable: {
    cursor: "pointer",
  },
  errorView: {
    backgroundColor: Color.LIGHT_RED,
    textAlign: "center",
  },
  error: {
    margin: "8px",
  },
  table: {
    marginTop: 15,
    overflow: "hidden",
    borderCollapse: "collapse",
  },
  tableLayoutFixed: {
    tableLayout: "fixed",
  },
  tableHeaderRow: {
    height: 65,
  },
  tableHeaderColumn: {
    color: Color.GREY3,
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: 400,
    fontStyle: "normal",
    textAlign: "left",
    width: "auto",
    height: "auto",
    minHeight: 50,
    padding: "0 0 0 8px",
  },
  tableBodyRow: {
    height: 65,
  },
  tableBodyColumn: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 13,
    lineHeight: "16px",
    fontWeight: 400,
    fontStyle: "normal",
    width: "auto",
    height: "auto",
    minHeight: 50,
    padding: 8,
  },
  whiteSpaceNoWrapCols: {
    whiteSpace: "nowrap",
  },
  coloredRow: {
    backgroundColor: Color.LIGHT_GREY,
  },
  actionsColumn: {
    width: 100,
  },
  selectable: {
  },
  selected: {
  },
  checkboxColumn: {
    width: 50,
  },
  checkboxContainer: {
  },
  checkbox: {
    padding: 0,
  },
  expandable: {
  },
  expanded: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: Color.GREY2,
    }
  },
  expandColumn: {
    minWidth: 50,
    padding: 0,
  },
  expandContainer: {
  },
  expand: {
    padding: 0,
  },
  tableBodyRowExpanded: {
  },
  tableBodyColumnExpanded: {
  },
  icon: {
    width: 18,
    height: 18,
  },
  loadMoreButton: {
    color: Color.GREY7,
    height: "auto",
    minWidth: "auto",
    padding: "12px 8px",
  },
  addItemButton: {
    color: Color.GREY5,
    height: "auto",
    minWidth: "auto",
    padding: "12px 8px",
    fontWeight: 300,
  },
  showMoreInfoColumn: {
    width: 36,
  },
  showMoreInfoButton: {
    color: Color.GREY3,
    padding: "6px 8px",
  },
  showEditIconButton: {
    color: Color.GREY3,
    padding: "6px 8px",
  },
  showDeleteIconButton: {
    color: Color.GREY3,
    padding: "6px 8px",
  },
  showMoreInfoButtonLabel: {
    fontSize: 12,
    textTransform: "uppercase",
  },
  gray: {
    backgroundColor: Color.LIGHT_GREY3,
  },
  yellow: {
    backgroundColor: Color.LIGHT_YELLOW,
  },
  orange: {
    backgroundColor: Color.LIGHT_ORANGE,
  },
  green: {
    backgroundColor: Color.LIGHT_GREEN,
  },
  red: {
    backgroundColor: Color.RED4,
  },
  blue: {
    backgroundColor: Color.LIGHT_BLUE2,
  }
});

export default styles;
