import React from "react";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  useApplicationManagerRegionalClient
} from "@hooks";

export interface UseEditApplicationRegionalProps extends Partial<UseApiRequestProps<void>> {
  applicationId: string;
  json: string;
}

export interface UseEditApplicationRegionalModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UseEditApplicationRegionalActions {
  editApplication: () => void;
}

type Props = UseEditApplicationRegionalProps;
type Model = UseEditApplicationRegionalModel;
type Actions = UseEditApplicationRegionalActions;
type Result = [Model, Actions];

export const useEditApplicationRegional = (props: Props): Result => {

  const {
    json = "",
    applicationId = "",
    defaultErrorMessage = "Failed to edit applications",
    ...otherProps
  } = props;

  const ApplicationManagerClient = useApplicationManagerRegionalClient();

  const makeApiRequest = React.useCallback(() =>
      ApplicationManagerClient.editApplicationRegionalApi(applicationId, json),
    [ApplicationManagerClient]);

  const [{ showSuccessView, ...baseModel }, { refresh: editApplication }] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Edit Successful" : "" ,
    [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    showSuccessView,
    successMessage,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    editApplication
  }), [editApplication]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEditApplicationRegional;
