import { Theme } from "@material-ui/core/styles/createTheme";
import { alpha } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      position: "absolute",
      fontFamily: "Roboto, sans-serif",
      color: theme.palette.getContrastText(theme.palette.background.paper),
    },
    content: {
      backgroundColor: alpha(theme.palette.background.paper, 0.3),
      margin: "4px 0",
      padding: 4,
    },
    iconButton: {
      color: theme.palette.primary.contrastText,
      padding: 0,
    },
    icon: {
      width: 28,
      height: 28,
    },
    topLeft: {
      left: theme.spacing(),
      top: theme.spacing(),
    },
    topRight: {
      right: theme.spacing(),
      top: theme.spacing(),
    },
    bottomLeft: {
      left: theme.spacing(),
      bottom: theme.spacing(),
    },
    bottomRight: {
      right: theme.spacing(),
      bottom: theme.spacing(),
    },
  });

export default styles;
