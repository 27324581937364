import { Record } from "immutable";
import DeviceTypeModelV3 from "./DeviceTypeModelV3";
import DeviceTypeState from "@data/DeviceTypeState";
import { getStringValue, isEmptyString, isValidInteger } from "@util";
import DeviceTypeModelVersion from "@data/DeviceTypeModelVersion";

interface DeviceTypeListItemMetadataAttributes {
  description: string;
  namespace: string;
  name: string;
  version: number;
  state: DeviceTypeState;
  etag: string;
}

class DeviceTypeListItemMetadata extends Record({
  description: "",
  namespace: "",
  name: "",
  version: 1,
  state: DeviceTypeState.NONE,
  etag: "",
}) implements DeviceTypeListItemMetadataAttributes {

  public static EMPTY: DeviceTypeListItemMetadata = new DeviceTypeListItemMetadata();

  public readonly description: string;
  public readonly namespace: string;
  public readonly name: string;
  public readonly version: number;
  public readonly state: DeviceTypeState;
  public readonly etag: string;

  public static from(deviceType: DeviceTypeModelV3): DeviceTypeListItemMetadata {
    return new DeviceTypeListItemMetadata({
      description: deviceType.getDescription(),
      namespace: deviceType.getNamespace(),
      name: deviceType.getName(),
      version: deviceType.getVersion(),
      state: deviceType.getState(),
      etag: deviceType.getEtag(),
    });
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public getNamespace(): string {
    return getStringValue(this.namespace);
  }

  public getName(): string {
    return getStringValue(this.name);
  }

  public getVersion(): number {
    return this.version;
  }

  public getVersionAsString(): string {
    const version = this.getVersion();
    return isValidInteger(version) ? `${version}` : "";
  }

  public getState(): DeviceTypeState {
    return this.state || DeviceTypeState.NONE;
  }

  public getEtag(): string {
    return getStringValue(this.etag);
  }
}

export interface DeviceTypeListItemAttributes {
  typeIdentity: string;
  metadata: DeviceTypeListItemMetadataAttributes;
  etag: string;
  modelVersion: DeviceTypeModelVersion;
}

export class DeviceTypeListItem extends Record({
  typeIdentity: "",
  metadata: DeviceTypeListItemMetadata.EMPTY.toJS(),
  etag: "",
  modelVersion: DeviceTypeModelVersion.REGIONAL,
}) implements DeviceTypeListItemAttributes {

  public static EMPTY: DeviceTypeListItem = new DeviceTypeListItem();

  public readonly typeIdentity: string;
  public readonly metadata: DeviceTypeListItemMetadataAttributes;
  public readonly etag: string;
  public readonly modelVersion: DeviceTypeModelVersion;

  public static from(deviceType: DeviceTypeModelV3): DeviceTypeListItem {
    return new DeviceTypeListItem({
      typeIdentity: deviceType.getTypeIdentity(),
      metadata: DeviceTypeListItemMetadata.from(deviceType).toJS(),
      etag: deviceType.getEtag(),
      modelVersion: deviceType.getModelVersion(),
    });
  }

  public static fromNameAndVersion(nameAndVersion: string): DeviceTypeListItem {
    const typeIdentity = getStringValue(nameAndVersion);

    const namespace = nameAndVersion.split(":")[0] || "";
    const name = nameAndVersion.split(":")[1] || "";
    const version = nameAndVersion.split(":")[2] || "";

    return new DeviceTypeListItem({
      typeIdentity,
      metadata: {
        namespace,
        name,
        version,
      }
    });
  }

  public getTypeIdentity(): string {
    return getStringValue(this.typeIdentity);
  }

  public hasTypeIdentity(): boolean {
    return !isEmptyString(this.getTypeIdentity());
  }

  public getMetadata(): DeviceTypeListItemMetadata {
    return new DeviceTypeListItemMetadata(this.metadata);
  }

  public getDescription(): string {
    return this.getMetadata().getDescription();
  }

  public getNameSpace(): string {
    return this.getMetadata().getNamespace();
  }

  public getName(): string {
    return this.getMetadata().getName();
  }

  public getVersion(): number {
    return this.getMetadata().getVersion();
  }

  public getVersionAsString(): string {
    return this.getMetadata().getVersionAsString();
  }

  public getState(): DeviceTypeState {
    return this.getMetadata().getState();
  }

  public getEtag(): string {
    return getStringValue(this.etag) || this.getMetadata().getEtag();
  }

  public getModelVersion(): DeviceTypeModelVersion {
    return this.modelVersion;
  }

  public isRegionalModelVersion(): boolean {
    return this.getModelVersion() === DeviceTypeModelVersion.REGIONAL;
  }
}

export default DeviceTypeListItem;
