import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    title: {
      marginTop: 8,
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "1",
    },
    completionMetadata: {
      marginTop: 25,
      "& .box": {
        marginTop: 0,
      }
    },
    box: {
      flex: "1 0 auto",
      marginTop: 25,
      display: "flex",
      flexFlow: "row nowrap",
      minHeight: 350,
      overflow: "hidden",
      borderRadius: 4,
      border: `1px solid ${Color.GREY1}`,
    },
    section: {
      display: "flex",
      flexFlow: "column nowrap",
      "&:first-child > $header, &:first-child > $content": {
        borderLeft: "none",
      },
    },
    header: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      padding: "15px 12px",
      borderLeft: `1px solid ${Color.GREY1}`,
      backgroundColor: Color.LIGHT_GREY4,
    },
    addButton: {
      marginLeft: 10,
      backgroundColor: Color.WHITE,
      fontWeight: 300,
      color: Color.BLACK,
    },
    addIcon: {
      width: 18,
      height: 18,
      fontSize: 14,
      lineHeight: "1",
    },
    content: {
      flex: "1 0 auto",
      padding: "10px 10px 25px 10px",
      display: "flex",
      flexFlow: "column nowrap",
      borderTop: `1px solid ${Color.GREY1}`,
      borderLeft: `1px solid ${Color.GREY1}`,
      minHeight: 350,
    },
    actions: {
      flex: "0 0 350px",
    },
    variables: {
    },
    actionsList: {
      "& > table": {
        marginTop: 0,
      },
    },
    variablesList: {
      "& > table": {
        marginTop: 0,
      },
    },
    popover: {
    },
    addActionPopover: {
    },
    addVariablePopover: {
    },
  });

export default styles;
