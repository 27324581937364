import React from "react";
import { Namespace, NamespaceAttributes } from "@data";
import { useApiRequest, UseApiRequestModel, useNamespaceClient } from "@hooks";

type SuccessResponse = NamespaceAttributes;

export interface UseNamespaceProps {
  namespaceName?: string;
}

export interface UseNamespaceModel extends UseApiRequestModel<SuccessResponse> {
  namespace: Namespace;
}

export interface UseNamespaceActions {
  refresh: () => void;
}

type Props = UseNamespaceProps;
type Model = UseNamespaceModel;
type Actions = UseNamespaceActions;
type Result = [Model, Actions];

export const useNamespace = (props: Props): Result => {

  const {
    namespaceName = "",
    ...otherProps
  } = props;

  const NamespaceClient = useNamespaceClient();

  const defaultErrorMessage = React.useMemo(() =>
      `Failed to get namespace ${namespaceName}` , [namespaceName]);

  const [{ successResponse, ...baseModel }, { refresh }] =
      useApiRequest<SuccessResponse>({
        ...otherProps,
        defaultErrorMessage,
        makeApiRequest: () => NamespaceClient.getNamespace(namespaceName),
      });

  const namespace = React.useMemo(() => {
    const attrs = successResponse || {};
    return new Namespace(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    namespace,
  }), [
    baseModel,
    namespace,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    refresh,
  }), [
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useNamespace;
