import { AppSchema } from "@main/schemas";
import { createActions } from "@base/createActions";
import { getAuthToken } from "@main/selectors";
import { DeviceEnrollmentClient, RestClientError } from "@network";
import {
  Device,
  DeviceAttributes
} from "@data";
import { getDeviceId, getDevicesMap } from "./selectors";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { isEmptyString } from "@util";
import { OrderedMap } from "immutable";

export const {
  devices: setDevicesAttributes,
  deviceId: setDeviceId,
  setErrorMessage,
  setSuccessMessage,
  showLoadingIndicator,
  hideLoadingIndicator,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  FETCH_DEVICES_REQUEST: fetchDevicesRequest,
  FETCH_DEVICES_SUCCESS: fetchDevicesSuccess,
  FETCH_DEVICES_FAILED: fetchDevicesFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setDevicesAttributes());
  dispatch(setDeviceId());
  return dispatch(baseReset());
};

export const clearDevices = () => setDevicesAttributes();

const updateItems =
  (items: OrderedMap<string, DeviceAttributes> =
     OrderedMap<string, DeviceAttributes>(DEFAULT_STATE.devices)) =>
    (dispatch: any, getState: () => AppSchema) => dispatch(setDevicesAttributes(
      getDevicesMap(getState()).merge(
        OrderedMap<string, DeviceAttributes>(items)
          .map((attrs: DeviceAttributes) => new Device(attrs))
          .toOrderedMap())
        .map((item: Device) => item.toJS())
        .toOrderedMap()
        .toJS()));

export const addDevices = (items: DeviceAttributes[] = []) => (dispatch: any) => {

  const devices = items.reduce((data, attrs) => {
    const item = new Device(attrs);

    if (!Device.EMPTY.equals(item)) {
      return data.set(item.getBatchId(), item.toJS());
    }

    return data;
  }, OrderedMap<string, DeviceAttributes>({}));

  return dispatch(updateItems(devices));
};

export const updateDevices =
  (attrs: DeviceAttributes = Device.EMPTY.toJS()) =>
    (dispatch: any) => {

      const item = new Device(attrs);

      if (Device.EMPTY.equals(item)) {
        return Promise.resolve();
      }

      return dispatch(updateItems(OrderedMap<string, DeviceAttributes>({
        [item.getDeviceId()]: item.toJS(),
      })));
    };

export const fetchDevice = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const deviceId = getDeviceId(state);
  const authToken = getAuthToken(state);

  if (isEmptyString(deviceId)) {
    return dispatch(hideEmptyView());
  }

  dispatch(showLoadingIndicator());
  dispatch(setErrorMessage());
  dispatch(hideAccessDenied());
  dispatch(fetchDevicesRequest());

  return DeviceEnrollmentClient.getDeviceStatus(authToken, deviceId)
    .then((attrs: DeviceAttributes) => {

      // Ignore results if batchId changed while request was in progress
      if (deviceId !== getDeviceId(getState())) {
        return Promise.resolve();
      }

      dispatch(fetchDevicesSuccess());

      if (isEmptyString(attrs.deviceReference)) {
        attrs.deviceId = deviceId;
      }

      dispatch(addDevices([new Device(attrs).toJS()]));
      dispatch(hideLoadingIndicator());
      return dispatch(hideEmptyView());

    }, (response: RestClientError) => {

      // Ignore results if batchId changed while request was in progress
      if (deviceId !== getDeviceId(getState())) {
        return Promise.resolve();
      }

      const {analytic, error = "Fetch Device failed"} = response;

      dispatch(fetchDevicesFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(hideLoadingIndicator());

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideEmptyView());
    });
};

export const refresh = () => (dispatch: any) => {

  dispatch(clearDevices());
  return dispatch(fetchDevice());
};

export const initialize = () => (dispatch: any) => {

  dispatch(reset());
  return dispatch(fetchDevice());
};
