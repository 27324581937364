import React from "react";
import classnames from "classnames";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles, tableHeaderCell, tableHeaderRow } from "./styles";

const TableHeaderRow = withStyles(tableHeaderRow)(TableRow);
const TableHeaderCell = withStyles(tableHeaderCell)(TableCell);

export const RedirectUrisTableHeaderRow = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  showActionsColumn?: boolean,
}) => {

  const { classes, className, showActionsColumn } = props;

  return (
    <TableHeaderRow className={classnames("tableHeaderRow", className)}>
      <TableHeaderCell className={classnames("redirectUri", classes.uriColumn)}>
        Redirect URI
      </TableHeaderCell>
      {showActionsColumn && (
        <TableHeaderCell className={classnames("actions", classes.actionsColumn)}>
          Actions
        </TableHeaderCell>
      )}
    </TableHeaderRow>
  );

});

export default RedirectUrisTableHeaderRow;
