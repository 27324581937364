import createStyles from "@material-ui/core/styles/createStyles";
import { StyleRules, Theme } from "@material-ui/core/styles";
import { Color } from "../../../../components/styles";

export const splashScreenIcon: StyleRules<string> = {
  root: {
    color: Color.WHITE,
    width: 128,
    height: 128,
  },
};

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      alignContent: "center",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: Color.SIGNIFY_GREEN,
    },
    loading: {
      marginTop: 32,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    expired: {
      marginTop: 32,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    label: {
      color: Color.WHITE,
      fontFamily: "Roboto, sans-serif",
      fontSize: 48,
      fontWeight: 400,
      lineHeight: "48px",
      margin: "16px 8px",
    },
    button: {
      color: Color.GREY5,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
      textTransform: "none",
    },
    underlined: {
      marginLeft: 4,
      marginRight: 4,
      textDecoration: "underline",
    },
  });
