import React from "react";
import { BackIcon } from "@icons";
import classnames from "classnames";
import Button from "@components/button";
import { useDispatch } from "react-redux";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { analyticAction, clickHandler, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles, { button } from "./styles";

export enum BackButtonActionType {
  CLICK_BACK_BUTTON = "components/back-button/click_back_button",
}

const CustomButton = withStyles(button)(Button);

export interface BackButtonModel {
  className?: string;
  label?: string;
  icon?: React.ComponentType<SvgIconProps>;
}

export interface BackButtonActions {
  goBack?: () => void;
}

type Props = WithStyles<typeof styles> & BackButtonModel & BackButtonActions;

export const BackButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    label = "Back",
    icon: BackButtonIcon = BackIcon,
    goBack = noop,
  } = props;

  const dispatch = useDispatch();

  const trackEvent = React.useCallback(
    analyticAction(BackButtonActionType.CLICK_BACK_BUTTON, className),
    [className]);

  const onClickGoBack = React.useCallback(() => {
    dispatch(trackEvent());
    goBack();
  }, [dispatch, trackEvent, goBack]);

  return (
    <CustomButton
      className={classnames("backButton", className)}
      fullWidth={false}
      color="inherit"
      onClick={clickHandler(onClickGoBack)}
    >
      <BackButtonIcon className={classes.icon} />
      {label}
    </CustomButton>
  );
});

export default BackButton;
