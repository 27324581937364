import { ActionMenuItem } from "@components";

export enum DevicesTableAction {
  REMOVE_DEVICE = "remove_device",
  EDIT_DEVICE = "edit_device",
  CLONE_DEVICE = "clone_device",
}

export const DEVICES_TABLE_ACTIONS: ActionMenuItem[] = [
  {
    id: DevicesTableAction.REMOVE_DEVICE,
    name: "Remove Device",
  },
  {
    id: DevicesTableAction.EDIT_DEVICE,
    name: "Edit Device",
  },
  {
    id: DevicesTableAction.CLONE_DEVICE,
    name: "Clone Device",
  },
];
