import React from "react";
import { Module } from "@data";
import { MODULE_ID } from "./constants";
import { isDialogOpen } from "./selectors";
import { initialize, open } from "./actions";
import { PrimaryIcon } from "@modules/emailTemplates/components/styles";
import DeleteFederationProviderDialog from "./containers/DeleteFederationProviderDialog";

export const deleteFederationProvidersModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "Delete Federation Provider",
  title: "Delete Federation Provider",
  icon: PrimaryIcon,
  view: <DeleteFederationProviderDialog />,
  onClick: open,
  isDialogOpen,
  initialize,
};

export default deleteFederationProvidersModule;
