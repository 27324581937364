import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";
import ReleaseNoteIssue, { ReleaseNoteIssueAttributes } from "./ReleaseNoteIssue";

export enum ReleaseNotePlatformDomain {
  NONE = "",
  SELF_SERVICE_TOOLS = "SELF_SERVICE_TOOLS",
  DATA_AND_ANALYTICS = "DATA_AND_ANALYTICS",
  COMMON_SERVICES = "COMMON_SERVICES",
  DEVICE_MANAGEMENT = "DEVICE_MANAGEMENT",
  SECURITY_SERVICES = "SECURITY_SERVICES",
}

export enum ReleaseNoteReleaseType {
  NONE = "",
  MAJOR = "MAJOR",
  MINOR = "MINOR",
  PATCH = "PATCH",
  HOTFIX = "HOTFIX",
}

export enum ReleaseNoteState {
  NONE = "",
  DRAFT = "DRAFT",
  REVIEW = "REVIEW",
  PUBLISHED = "PUBLISHED",
}

export enum ReleaseStatus {
  NONE = "",
  POSTPONED = "POSTPONED",
  PLANNED = "PLANNED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

export interface ReleaseNoteAttributes {
  title: string;
  summary: string;
  description: string;
  version: string;
  releaseId: string;
  releaseDate: string;
  state: ReleaseNoteState;
  platformDomain: ReleaseNotePlatformDomain;
  releaseType: ReleaseNoteReleaseType;
  releaseStatus: ReleaseStatus;
  issues: ReleaseNoteIssueAttributes[];
}

export class ReleaseNote extends Record({
  title: "",
  summary: "",
  description: "",
  version: "",
  releaseId: "",
  releaseDate: "",
  state: ReleaseNoteState.NONE,
  platformDomain: ReleaseNotePlatformDomain.NONE,
  releaseType: ReleaseNoteReleaseType.NONE,
  releaseStatus: ReleaseStatus.NONE,
  issues: [],
}) implements ReleaseNoteAttributes {

  public static EMPTY: ReleaseNote = new ReleaseNote();

  public readonly title: string;
  public readonly summary: string;
  public readonly description: string;
  public readonly version: string;
  public readonly releaseId: string;
  public readonly releaseDate: string;
  public readonly state: ReleaseNoteState;
  public readonly platformDomain: ReleaseNotePlatformDomain;
  public readonly releaseType: ReleaseNoteReleaseType;
  public readonly releaseStatus: ReleaseStatus;
  public readonly issues: ReleaseNoteIssueAttributes[];

  public getTitle(): string {
    return getStringValue(this.title);
  }

  public getSummary(): string {
    return getStringValue(this.summary);
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public hasSummary(): boolean {
    return !isEmptyString(this.getSummary());
  }

  public getVersion(): string {
    return getStringValue(this.version);
  }

  public getReleaseId(): string {
    return getStringValue(this.releaseId);
  }

  public getReleaseDate(): string {
    return getStringValue(this.releaseDate);
  }

  public getReleaseState(): ReleaseNoteState {
    return this.state;
  }

  public isPublished(): boolean {
    return this.getReleaseState() === ReleaseNoteState.PUBLISHED;
  }

  public getPlatformDomain(): ReleaseNotePlatformDomain {
    return this.platformDomain;
  }

  public getReleaseType(): ReleaseNoteReleaseType {
    return this.releaseType;
  }

  public getReleaseStatus(): ReleaseStatus {
    return this.releaseStatus;
  }

  public isMajorReleaseType(): boolean {
    return this.getReleaseType() === ReleaseNoteReleaseType.MAJOR;
  }

  public isMinorReleaseType(): boolean {
    return this.getReleaseType() === ReleaseNoteReleaseType.MINOR;
  }

  public isPatchReleaseType(): boolean {
    return this.getReleaseType() === ReleaseNoteReleaseType.PATCH;
  }

  public getIssues(): ReleaseNoteIssue[] {
    const issues = Array.isArray(this.issues) ? this.issues : [];
    return issues.map(attrs => new ReleaseNoteIssue(attrs));
  }
}

export default ReleaseNote;
