import React from "react";
import { isEmptyString } from "@util";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useDataSetRequestClient,
} from "@hooks";

type SuccessResponse = File;

export interface UseTermsConditionsFileProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  termsConditionsId?: string;
  termsConditionsFile?: File | null;
  defaultFileName?: string;
}

export interface UseTermsConditionsFileModel extends UseApiRequestModel<SuccessResponse> {
  termsConditionsId: string;
  termsConditionsFile: File | null;
  termsConditionsFileName?: string;
}

export interface UseTermsConditionsFileActions extends UseApiRequestActions<SuccessResponse> {
  removeTermsConditionsFile: () => void;
  setTermsConditionsFile: (file: File | null) => void;
}

type Props = UseTermsConditionsFileProps;
type Model = UseTermsConditionsFileModel;
type Actions = UseTermsConditionsFileActions;
type Result = [Model, Actions];

export const useTermsConditionsFile = (props: Props): Result => {

  const {
    termsConditionsId: initialTermsConditionsId = "",
    termsConditionsFile: initialTermsConditionsFile = null,
    defaultFileName = "Terms & Conditions.pdf", // fallback filename if we cannot figure it out
    defaultErrorMessage = "Failed to download terms & conditions file",
    ...otherProps
  } = props;
  const [termsConditionsId, setTermsConditionsId] = React.useState<string>(initialTermsConditionsId);
  const [termsConditionsFile, setTermsConditionsFile] = React.useState<File | null>(initialTermsConditionsFile);

  const DataSetRequestClient = useDataSetRequestClient();

  const makeApiRequest = React.useCallback(() =>
    DataSetRequestClient.getTermsAndConditionsUrl(termsConditionsId)
      .then(({ termsConditionsUrl: url }) => {

        const fileName = decodeURIComponent(
            (((url || "").split("/").pop() || "").split("?")[0] || "").split("-").pop() || "") ||
          defaultFileName; // fallback filename if we cannot infer it from the url

        return fetch(url)
          .then((response: Response) => response.blob())
          .then(blob => new File([blob], fileName));
      }), [DataSetRequestClient, termsConditionsId, defaultFileName]);

  const removeTermsConditionsFile = React.useCallback(() => {
    setTermsConditionsId("");
    setTermsConditionsFile(null);
  }, [setTermsConditionsId, setTermsConditionsFile]);

  const updateTermsConditionsFile = React.useCallback((file: File | null) => {
    setTermsConditionsId("");
    setTermsConditionsFile(file);
  }, [setTermsConditionsId, setTermsConditionsFile]);

  const [{ successResponse, ...baseModel }, { refresh: downloadFile, ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
      deferRequest: true,
    });

  const termsConditionsFileName = React.useMemo(() =>
      termsConditionsFile !== null ? termsConditionsFile.name : "",
    [termsConditionsFile]);

  React.useEffect(() => {
    if (!isEmptyString(termsConditionsId) && termsConditionsFile === null) {
      downloadFile();
    }
  }, [termsConditionsId, termsConditionsFile, downloadFile]);

  React.useEffect(() => {
    if (successResponse) {
      updateTermsConditionsFile(successResponse);
    }
  }, [successResponse, updateTermsConditionsFile]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successResponse,
    termsConditionsId,
    termsConditionsFile,
    ...(isEmptyString(termsConditionsFileName) ? ({}) : ({ termsConditionsFileName })),
  }), [
    baseModel,
    successResponse,
    termsConditionsId,
    termsConditionsFile,
    termsConditionsFileName,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    refresh: downloadFile,
    removeTermsConditionsFile,
    setTermsConditionsFile: updateTermsConditionsFile,
  }), [
    baseActions,
    downloadFile,
    removeTermsConditionsFile,
    updateTermsConditionsFile,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useTermsConditionsFile;
