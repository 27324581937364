import { OrderedMap } from "immutable";
import { createSelector } from "reselect";
import { AppSchema } from "@schemas";
import { compare, isEmptyString } from "@util";
import { DeviceTypeColumn, DeviceTypeItems } from "./reducers";
import { DeviceTypeListItem, DeviceTypeListItemAttributes } from "@data";
import isDeviceTypeV2APIEnabled from "@util/isDeviceTypeV2APIEnabled";

type Comparator = (left: DeviceTypeListItem, right: DeviceTypeListItem) => number;

const compareByState = (left: DeviceTypeListItem, right: DeviceTypeListItem, ascending: boolean) =>
  compare(left.getState(), right.getState(), ascending);

const compareByVersion = (left: DeviceTypeListItem, right: DeviceTypeListItem, ascending: boolean) =>
  compare(left.getVersion(), right.getVersion(), ascending);

const compareByName = (left: DeviceTypeListItem, right: DeviceTypeListItem, ascending: boolean) => {
  const result = compare(left.getName(), right.getName(), ascending);
  return result !== 0 ? result : compareByVersion(left, right, ascending);
};

const compareByNamespace = (left: DeviceTypeListItem, right: DeviceTypeListItem, ascending: boolean) => {
  const result = compare(left.getNameSpace(), right.getNameSpace(), ascending);
  return result !== 0 ? result : compareByName(left, right, ascending);
};

const isV2ApiEnabled = () => isDeviceTypeV2APIEnabled();

export const getSearchQuery = (state: AppSchema): string => {
  return state.deviceTypes.searchQuery;
};

export const getSortedColumn = (state: AppSchema): DeviceTypeColumn => {
  return state.deviceTypes.sortedColumn;
};

export const isSortOrderAscending = (state: AppSchema): boolean => {
  return state.deviceTypes.sortOrderAscending;
};

export const getErrorMessage = (state: AppSchema): string => {
  return state.deviceTypes.errorMessage;
};

export const isAccessDeniedVisible = (state: AppSchema): boolean => {
  return state.deviceTypes.showAccessDenied;
};

export const isEmptyViewVisible = (state: AppSchema): boolean => {
  return state.deviceTypes.showEmptyView;
};

export const isLoadingIndicatorVisible = (state: AppSchema): boolean => {
  return state.deviceTypes.showLoadingIndicator;
};

const getDeviceTypeAttributes = (state: AppSchema): DeviceTypeItems => {
  return state.deviceTypes.items;
};

export const getNextPage = (state: AppSchema): string => {
  return state.deviceTypes.nextPage || "";
};

export const hasNextPage: (state: AppSchema) => boolean = createSelector(
  getNextPage, (nextPageToken: string) => !isEmptyString(nextPageToken));

export const getDeviceTypesMap: (state: AppSchema) => OrderedMap<string, DeviceTypeListItem> = createSelector(
  getDeviceTypeAttributes, (deviceTypeAttributes: { [key: string]: DeviceTypeListItemAttributes }) =>
    OrderedMap<string, DeviceTypeListItemAttributes>(deviceTypeAttributes)
      .map((attrs: DeviceTypeListItemAttributes) => new DeviceTypeListItem(attrs))
      .toOrderedMap());

export const getDeviceTypes: (state: AppSchema) => DeviceTypeListItem[] = createSelector(
  [getDeviceTypesMap, isV2ApiEnabled],
  (deviceTypesMap: OrderedMap<string, DeviceTypeListItem>, v2ApiEnabled: boolean) => {

    const deviceTypes = deviceTypesMap.toArray();

    if (v2ApiEnabled) {
      return deviceTypes;
    }

    return deviceTypes.filter(deviceType => deviceType.isRegionalModelVersion());
  });

export const getNumDeviceTypes: (state: AppSchema) => number = createSelector(
  getDeviceTypes, (deviceTypes: DeviceTypeListItem[]) => deviceTypes.length);

export const isSortButtonDisabled: (state: AppSchema) => boolean = createSelector(
  getNumDeviceTypes, (numDeviceTypes: number) => numDeviceTypes <= 1);

export const isDeviceTypesListEmpty: (state: AppSchema) => boolean = createSelector(
  getNumDeviceTypes, (numDeviceTypes: number) => numDeviceTypes === 0);

export const isErrorViewVisible: (state: AppSchema) => boolean = createSelector(
  getErrorMessage, (errorMessage: string) => !isEmptyString(errorMessage));

export const isLoadingViewVisible: (state: AppSchema) => boolean = createSelector(
  [isLoadingIndicatorVisible, isErrorViewVisible],
  (isLoading: boolean, showErrorView: boolean) =>
    isLoading && !showErrorView);

export const isNoResultsViewVisible: (state: AppSchema) => boolean = createSelector(
  [isDeviceTypesListEmpty, isErrorViewVisible, isLoadingIndicatorVisible],
  (isEmpty: boolean, showErrorView: boolean, isLoading: boolean) =>
    isEmpty && !showErrorView && !isLoading);

export const isLoadMoreButtonVisible: (state: AppSchema) => boolean = createSelector(
  [hasNextPage, isErrorViewVisible, isLoadingIndicatorVisible],
  (canLoadMore: boolean, showErrorView: boolean, isLoading: boolean) =>
    canLoadMore && !showErrorView && !isLoading);

const getComparator: (state: AppSchema) => Comparator = createSelector(
  [isSortOrderAscending, getSortedColumn],
  (ascending: boolean, sortedColumn: DeviceTypeColumn) => {

    return (deviceType: DeviceTypeListItem, otherDeviceType: DeviceTypeListItem) => {
      switch (sortedColumn) {
        case DeviceTypeColumn.NAMESPACE:
          return compareByNamespace(deviceType, otherDeviceType, ascending);
        case DeviceTypeColumn.NAME:
          return compareByName(deviceType, otherDeviceType, ascending);
        case DeviceTypeColumn.VERSION:
          return compareByVersion(deviceType, otherDeviceType, ascending);
        case DeviceTypeColumn.STATE:
          return compareByState(deviceType, otherDeviceType, ascending);
        default:
          return 0;
      }
    };
  });

export const getSearchResults: (state: AppSchema) => DeviceTypeListItem[] = createSelector(
  [isSortButtonDisabled, getSortedColumn, getDeviceTypes, getComparator],
  (sortButtonDisabled: boolean,
   sortedColumn: DeviceTypeColumn,
   deviceTypes: DeviceTypeListItem[],
   comparator: Comparator) => {

    if (sortButtonDisabled || (DeviceTypeColumn.NONE === sortedColumn)) {
      return deviceTypes;
    }

    deviceTypes.sort(comparator);
    return deviceTypes.slice();
  });

export const getDeviceTypeById = (id: string, state: AppSchema): DeviceTypeListItem => {
  return getDeviceTypesMap(state).get(id, DeviceTypeListItem.EMPTY);
};
