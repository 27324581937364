import React from "react";
import usePolicyManagementClient from "../use-policy-management-client";
import { useManagedPolicyClient, useApiRequest, UseApiRequestModel, UseApiRequestProps } from "@hooks";
import { PolicyOperation, PolicyOperationPrincipal } from "@hooks/policies/use-policy-management/helpers";
import capitalize from "lodash/capitalize";

export interface UsePolicyManagementRequestProps extends Partial<UseApiRequestProps<void>> {
  id?: string;
  policyName?: string;
  accountId?: string;
  operation?: PolicyOperation;
  principal?: PolicyOperationPrincipal;
  isManagedPolicy?: boolean;
}

export interface UsePolicyManagementModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UsePolicyManagementActions {
  save: () => void;
}

type Props = UsePolicyManagementRequestProps;
type Model = UsePolicyManagementModel;
type Actions = UsePolicyManagementActions;
type Result = [Model, Actions];

export const usePolicyManagement = (props: Props): Result => {

  const {
    id = "",
    policyName = "",
    accountId = "",
    operation = PolicyOperation.ATTACH,
    principal = PolicyOperationPrincipal.SERVICE,
    isManagedPolicy = false,
    ...otherProps
  } = props;

  const PolicyManagementClient = usePolicyManagementClient();
  const ManagedPolicyClient = useManagedPolicyClient();

  const defaultErrorMessage = React.useMemo(() =>
    `Failed to ${operation} policy to ${principal}` , [operation, principal]);

  const mapApiCallToOperation = React.useCallback(() => {
    if (operation === PolicyOperation.ATTACH) {
      if (principal === PolicyOperationPrincipal.SERVICE) {
        if (!isManagedPolicy) {
          return PolicyManagementClient.attachPolicyToService(id, policyName);
        } else {
          return ManagedPolicyClient.attachManagedPolicyToService(id, policyName, accountId);
        }
      } else if (principal === PolicyOperationPrincipal.USER) {
        if (!isManagedPolicy) {
          return PolicyManagementClient.attachPolicyToUser(id, policyName);
        } else {
          return ManagedPolicyClient.attachManagedPolicyToUser(id, policyName, accountId);
        }
      } else if (principal === PolicyOperationPrincipal.GROUP) {
        if (!isManagedPolicy) {
          return PolicyManagementClient.attachPolicyToGroup(id, policyName);
        } else {
          return ManagedPolicyClient.attachManagedPolicyToGroup(id, policyName, accountId);
        }
      }
    } else {
      if (principal === PolicyOperationPrincipal.SERVICE) {
        if (!isManagedPolicy) {
          return PolicyManagementClient.detachPolicyFromService(id, policyName);
        } else {
          return ManagedPolicyClient.detachManagedPolicyFromService(id, policyName, accountId);
        }
      } else if (principal === PolicyOperationPrincipal.USER) {
        if (!isManagedPolicy) {
          return PolicyManagementClient.detachPolicyFromUser(id, policyName);
        } else {
          return ManagedPolicyClient.detachManagedPolicyFromUser(id, policyName, accountId);
        }
      } else if (principal === PolicyOperationPrincipal.GROUP) {
        if (!isManagedPolicy) {
          return PolicyManagementClient.detachPolicyFromGroup(id, policyName);
        } else {
          return ManagedPolicyClient.detachManagedPolicyFromGroup(id, policyName, accountId);
        }
      }
    }
    return Promise.resolve();
  } , [
    operation,
    principal,
    isManagedPolicy,
    id,
    policyName,
    accountId,
    PolicyManagementClient,

  ]);

  const [{ showSuccessView, ...baseModel }, { refresh: save}] =
  useApiRequest<void>({
    ...otherProps,
    defaultErrorMessage,
    deferRequest: true,
    makeApiRequest: () => mapApiCallToOperation()
  });

  const successMessage = React.useMemo(() =>
  showSuccessView ? `${capitalize(operation)}ed policy ${policyName} ${operation === PolicyOperation.ATTACH ? "to" : "from" } ${principal} ${id}` : "" ,
    [showSuccessView, policyName, operation, principal, id]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,

  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    save,
  }), [save]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);

};

export default usePolicyManagement;
