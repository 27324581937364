import React from "react";
import { noop } from "@util";
import { Model, Actions } from "./Navigation";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import NavigationListItem, { ListItemType } from "./NavigationListItem";

type Props = Model & Actions;

export const FeedbackListItem = (props: Props) => {

  const { showFeedbackDialog = noop, ...otherProps } = props;

  return (
    <NavigationListItem
      {...otherProps}
      className={"sendFeedback"}
      name={"Submit Feedback"}
      clickable={true}
      type={ListItemType.FEEDBACK}
      icon={FeedbackOutlinedIcon}
      onClick={() => showFeedbackDialog(true)}
    />
  );
};

export default FeedbackListItem;
