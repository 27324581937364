import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DetailsViewRoute from "@components/details-view/DetailsViewRoute";
import styles from "./styles";
import { NotFoundView } from "@components";
import TabsView from "./TabsView";
import TabsButtonView from "./TabsButtonView";
import { Redirect, Route, Switch } from "react-router-dom";

export enum DetailsViewTabVariant {
  BUTTONS = "BUTTONS",
  TABS = "TABS"
}

export interface DetailsViewTabsModel {
  className?: string;
  contentClassName?: string;
  disabled?: boolean;
  tabsMarginTop?: boolean;
  routes?: DetailsViewRoute[];
  customTabs?: React.ReactNode;
  showControls?: boolean;
  loading?: boolean;
  baseUrl?: string;
  activeTab?: string | number;
  tabVariant?: DetailsViewTabVariant;
}

export interface DetailsViewTabsActions {
}

type Model = DetailsViewTabsModel;
type Actions = DetailsViewTabsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DetailsViewTabs = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    contentClassName,
    disabled,
    routes = [],
    customTabs,
    tabsMarginTop = true,
    children,
    showControls = true,
    loading = false,
    baseUrl = "",
    activeTab = "",
    tabVariant = DetailsViewTabVariant.TABS,
  } = props;

  const defaultPath = React.useMemo(() => {
    const { path: firstPath = "" } = routes[0] || {};
    return baseUrl + firstPath;
  }, [routes, baseUrl]);

  return (
    <div className={classnames("detailsViewTabs", className, classes.container)}>
      {showControls && (
        <React.Fragment>
          <TabsButtonView
            routes={routes}
            disabled={disabled}
            baseUrl={baseUrl}
            tabVariant={tabVariant}
          />
          <TabsView
            routes={routes}
            disabled={disabled}
            baseUrl={baseUrl}
            activeTab={activeTab}
            tabVariant={tabVariant}
          />
          {customTabs}
        </React.Fragment>
      )}
      <div
          style={(tabsMarginTop ? ({}) : ({ marginTop: 0 }))}
          className={classnames("content", contentClassName, classes.content)}
      >
        <Switch>
          {routes.map(({ id, path = "", view: RouteView, hidden }) => (
            <Route
              key={id}
              path={baseUrl + path}
              exact={true}
              strict={true}
            >
              {(!loading && hidden) ? <NotFoundView/> : <RouteView/>}
            </Route>
          ))}
          <Route>
            <Redirect to={defaultPath} />
          </Route>
        </Switch>
      </div>
      {children}
    </div>
  );
});

export default DetailsViewTabs;
