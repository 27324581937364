import React from "react";
import AppSchema from "@AppSchema";
import classnames from "classnames";
import { IdentityType } from "@data";
import { ActionType, isEmptyString } from "@util";
import ReportIcon from "@material-ui/icons/Report";
import { useDispatch, useSelector } from "react-redux";
import { errorCodeViewStyles as styles } from "./styles";
import { ContinueButton } from "@components/continue-button";
import isFeedbackDialogEnabled from "@util/isFeedbackDialogEnabled";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface ErrorCodeViewProps {
  label?: string;
  subLabel?: string;
  className?: string;
  description?: string;
  errorMessage?: string;
}

type Props = WithStyles<typeof styles> & ErrorCodeViewProps;

export const ErrorCodeView = withStyles(styles)((props: Props) => {

  const {
    classes,
    errorMessage = "",
    className = "errorCodeView",
    label = "500 Error",
    subLabel = "Server Error",
    description = "An error has occurred",
  } = props;

  const dispatch = useDispatch();
  const identityType = useSelector((state: AppSchema) => state.identityType);
  const principalId = useSelector((state: AppSchema) => state.principalId);
  const isUserPrincipal = !isEmptyString(principalId) && (identityType === IdentityType.USER);

  const showFeedbackButton = isFeedbackDialogEnabled() && isUserPrincipal;

  return (
    <div className={classnames(className, classes.container)}>
      <label className={classnames("label", classes.label)}>
        <ReportIcon fontSize={"large"} className={classnames("icon", classes.icon)}/>
        {label}
      </label>
      <label className={classnames("subLabel", classes.subLabel)}>
        {subLabel}
      </label>
      <label className={classnames("description", classes.description)}>
        {errorMessage.split(" ").length > 1 ? errorMessage : description}
      </label>
      <div className={classnames("buttonContainer", classes.buttonContainer)}>
        <ContinueButton
          className={classnames("nextStepButton", classes.button)}
          label={"Return Home"}
          color={"secondary"}
          onClick={() => window.location.pathname = ""}
        />
        <ContinueButton
          className={classnames("nextStepButton", classes.button)}
          label={"Refresh Page"}
          color={"secondary"}
          onClick={() => window.location.reload()}
        />
      </div>
      {showFeedbackButton && (
        <div className={classnames("feedbackContainer", classes.buttonContainer)}>
          <ContinueButton
            className={classnames("feedbackButton", classes.button)}
            color={"secondary"}
            onClick={() =>
              dispatch({
                type: ActionType.TOGGLE_FEEDBACK_DIALOG,
                value: true
              })
            }
          >
            <FeedbackOutlinedIcon
              className={classnames("feedbackIcon", classes.feedbackIcon)}
            />
            Submit Feedback
          </ContinueButton>
        </div>
      )}
    </div>
  );
});

export default ErrorCodeView;
