import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { approveRequest, close, save, setComments } from "../actions";
import { refresh } from "@modules/dataApprovalRequestDetails/actions";
import { MODULE_PATH as DETAILS_PAGE_PATH } from "@modules/dataApprovalRequestDetails/constants";
import {
  getApprovalRequestId,
  getClassName,
  getComments,
  getContinueButtonLabel,
  getDataSetAlias,
  getDialogTitle,
  getErrorMessage,
  getSuccessMessage,
  isAccessDeniedVisible,
  isApproveRequestAction,
  isDialogOpen,
  isLoadingIndicatorVisible,
} from "../selectors";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import ApproveRequestView from "../components/ApproveRequestView";

interface Model extends PortalModuleDialogModel<string> {
  approvalRequestId?: string;
  dataSetAlias?: string;
  isApprove?: boolean;
  comments?: string;
}

interface Actions extends PortalModuleDialogActions<string> {
  setComments?: (comments: string) => void;
  approveRequest?: () => void;
  refresh?: () => void;
}

type Props = Model & Actions;

const ApproveRequestDialog = (props: Props) => {

  const {
    isApprove,
    approvalRequestId,
    dataSetAlias,
    refresh: refreshDetailsPage = noop,
    ...otherProps
  } = props;

  const { cancel: closeDialog = noop, loading } = otherProps;

  const match = useRouteMatch(DETAILS_PAGE_PATH);

  const isDetailsPageLoaded = React.useMemo(() => match && match.isExact, [match]);

  const onSuccessMessageShown = React.useCallback(() => {
    if (isDetailsPageLoaded) {
      refreshDetailsPage();
    }
    closeDialog();
  }, [isDetailsPageLoaded, refreshDetailsPage, closeDialog]);

  return (
    <PortalModuleDialog {...otherProps} onSuccessMessageShown={onSuccessMessageShown}>
      <ApproveRequestView
        {...otherProps}
        approvalRequestId={approvalRequestId}
        dataSetAlias={dataSetAlias}
        isApprove={isApprove}
        commentsDisabled={loading}
      />
    </PortalModuleDialog>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  className: getClassName(state),
  comments: getComments(state),
  title: getDialogTitle(state),
  open: isDialogOpen(state),
  item: getApprovalRequestId(state),
  loading: isLoadingIndicatorVisible(state),
  accessDenied: isAccessDeniedVisible(state),
  errorMessage: getErrorMessage(state),
  successMessage: getSuccessMessage(state),
  approvalRequestId: getApprovalRequestId(state),
  dataSetAlias: getDataSetAlias(state),
  isApprove: isApproveRequestAction(state),
  continueButtonLabel: getContinueButtonLabel(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setComments: (comments: string) => dispatch(setComments(comments)),
  cancel: () => dispatch(close()),
  confirm: () => dispatch(save()),
  approveRequest: () => dispatch(approveRequest()),
  refresh: () => dispatch(refresh()),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(ApproveRequestDialog);
