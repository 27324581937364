import React from "react";
import { noop } from "@util";
import { styles } from "./styles";
import classnames from "classnames";
import { MaintenanceReminder } from "@data";
import WarnIcon from "@material-ui/icons/Warning";
import { InputAdornment } from "@material-ui/core";
import { IconButton, TextField } from "@components";
import CancelIcon from "@material-ui/icons/CancelPresentation";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export const MaintenanceBanner = withStyles(styles)((props: WithStyles<typeof styles> & {
  maintenanceReminder?: MaintenanceReminder;
  maintenanceReminderLabel?: string,
  closedMaintenanceReminders?: string[],
  updateClosedMaintenanceReminders?: () => void,
}) => {

  const {
    classes,
    maintenanceReminderLabel = "",
    closedMaintenanceReminders = [],
    maintenanceReminder = MaintenanceReminder.EMPTY,
    updateClosedMaintenanceReminders = noop,
  } = props;

  const onCloseMaintenanceReminder = React.useCallback(() => {
    updateClosedMaintenanceReminders(
      Array.from(new Set(closedMaintenanceReminders.concat([maintenanceReminder.getETag()])))
    );
  }, [maintenanceReminder, closedMaintenanceReminders, updateClosedMaintenanceReminders]);

  return (
    <div className={classnames("maintenanceReminder", classes.maintenanceReminder)}>
      <div>
        <TextField
          autoComplete="off"
          margin="none"
          fullWidth={true}
          value={"MAINTENANCE REMINDER\n" + maintenanceReminderLabel}
          onChange={noop}
          InputProps={{
            readOnly: true,
            multiline: true,
            disableUnderline: true,
            className: classnames("maintenanceLabel", classes.maintenanceLabel),
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  className={classnames("maintenanceIcon", classes.maintenanceIcon)}
                  tabIndex={-1}
                  aria-label="maintenance"
                  onClick={noop}
                  disableRipple={true}
                >
                  <WarnIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className={classnames("maintenanceCancel", classes.maintenanceIcon)}
                  tabIndex={-1}
                  aria-label="Cancel"
                  onClick={onCloseMaintenanceReminder}
                >
                  <CancelIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>
    </div>
  );
});

export default MaintenanceBanner;
