import { createSelectors } from "@base";
import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { DataSetRequestsSchema, DEFAULT_STATE } from "./reducers";

export const {
  nameFilter: getNameFilter,
  filterByCurrentUser: isFilterByCurrentUserEnabled,
  filterByPendingApproval: isFilterByPendingApprovalEnabled,
  reloadItemsRequested: isReloadItemsRequested,
  getCurrentUserId,
} = createSelectors<DataSetRequestsSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);
