import React from "react";
import { noop } from "@util";
import { RestClientError } from "@network";
import { useSecurityManagerClient } from "@hooks";
import DeleteBinaryDataDialog, {
  DeleteBinaryDataDialogActions as Actions,
  DeleteBinaryDataDialogModel as Model,
} from "./DeleteBinaryDataDialog";

export interface DeleteBinaryDataModel extends Model {
  userId?: string;
  name?: string;
  selfAuthorized?: boolean;
}

export interface DeleteBinaryDataActions extends Actions {
  showLoadingIndicator?: () => void;
}

type Props = DeleteBinaryDataModel & DeleteBinaryDataActions;

export const DeleteBinaryData = (props: Props) => {

  const {
    userId = "",
    name = "",
    selfAuthorized,
    cancel = noop,
    onSuccessMessageShown = noop,
    showLoadingIndicator: showDeletingMessage = noop,
    ...otherProps
  } = props;

  const SecurityManagerClient = useSecurityManagerClient();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);

  const deleteBinaryData = React.useCallback(() =>
    SecurityManagerClient.deleteUserBinaryData(userId, name, selfAuthorized),
    [SecurityManagerClient, userId, name, selfAuthorized]);

  React.useEffect(() => {

    let ignore = false;

    if (showLoadingIndicator) {

      setErrorMessage("");

      deleteBinaryData()
        .then(() => {
          if (!ignore) {
            setShowLoadingIndicator(false);
            setSuccessMessage("Binary Data Deleted");
          }
        }, (response: RestClientError) => {
          if (!ignore) {
            const { error = "Delete binary data failed" } = response;
            setErrorMessage(error);
            setShowLoadingIndicator(false);
          }
        });
    }

    return () => { ignore = true; };

  }, [
    showLoadingIndicator,
    setErrorMessage,
    deleteBinaryData,
    setShowLoadingIndicator,
    setSuccessMessage,
  ]);

  const confirm = React.useCallback(() => {
    showDeletingMessage();
    setShowLoadingIndicator(true);
  }, [showDeletingMessage, setShowLoadingIndicator]);

  return (
    <DeleteBinaryDataDialog
      {...otherProps}
      open={true}
      errorMessage={errorMessage}
      successMessage={successMessage}
      loading={showLoadingIndicator}
      cancel={cancel}
      confirm={confirm}
      onSuccessMessageShown={onSuccessMessageShown}
    />
  );
};

export default DeleteBinaryData;
