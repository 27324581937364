import React from "react";
import { noop } from "@util";
import { EmailTemplate } from "@data";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { useEmailTemplate } from "@hooks";
import { getAccountId } from "@main/selectors";
import DeleteEmailTemplateDialog from "@components/delete-email-template-dialog";
import { ActionMenuItem, DELETE_EMAIL_TEMPLATE_ACTION, EDIT_EMAIL_TEMPLATE_ACTION } from "@components";
import EmailTemplateDetails, { Actions, Model } from "../components/EmailTemplateDetails";

enum HtmlBodyViewMode {
  PREVIEW = "preview",
  RAW = "raw"
}

const DEFAULT_ACTIONS: ActionMenuItem[] = Object.freeze([
  EDIT_EMAIL_TEMPLATE_ACTION,
  DELETE_EMAIL_TEMPLATE_ACTION,
]) as ActionMenuItem[];

export interface ContainerModel extends Model {
  accountId?: string;
  templateId?: string;
}

export interface ContainerActions extends Actions {
  showListView?: () => void;
}

type Props = ContainerModel & ContainerActions;

const EmailTemplateDetailsContainer = (props: Props) => {

  const {
    accountId = "",
    templateId = "",
    showListView = noop,
    ...otherProps
  } = props;

  const [tabView, setTabView] = React.useState<HtmlBodyViewMode>(HtmlBodyViewMode.PREVIEW);
  const [{ emailTemplate = new EmailTemplate(), showLoadingIndicator, ...otherModel },
    { refresh, ...otherActions }] = useEmailTemplate({accountId, templateId});

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);

  const deleteEmailTemplate = React.useCallback(() => {
    setOpenDeleteDialog(true);
  }, [setOpenDeleteDialog]);

  const closeDeleteDialog = React.useCallback(() => {
    setOpenDeleteDialog(false);
  }, [setOpenDeleteDialog]);

  const onDeleteEmailSuccess = React.useCallback(() => {
    closeDeleteDialog();
    showListView();
  }, [showListView, closeDeleteDialog]);

  return (
    <React.Fragment>
      <EmailTemplateDetails
        {...otherProps}
        {...otherModel}
        {...otherActions}
        refresh={refresh}
        templateId={templateId}
        actions={DEFAULT_ACTIONS}
        title={emailTemplate.getId()}
        loading={showLoadingIndicator}
        applicationId={emailTemplate.getApplicationId()}
        language={emailTemplate.getLanguage()}
        messageType={emailTemplate.getMessageType()}
        s3Bucket={emailTemplate.getS3Bucket()}
        s3Key={emailTemplate.getS3Key()}
        subject={emailTemplate.getEmailSubject()}
        description={emailTemplate.getDescription()}
        textBody={emailTemplate.getTextBody()}
        htmlBody={emailTemplate.getHtmlBody()}
        rawViewSelected={tabView === HtmlBodyViewMode.RAW}
        showRawView={() => setTabView(HtmlBodyViewMode.RAW)}
        previewViewSelected={tabView === HtmlBodyViewMode.PREVIEW}
        showPreviewView={() => setTabView(HtmlBodyViewMode.PREVIEW)}
        deleteEmailTemplate={deleteEmailTemplate}
      />
      <DeleteEmailTemplateDialog
        open={openDeleteDialog}
        cancel={closeDeleteDialog}
        accountId={accountId}
        templateId={templateId}
        onSuccessMessageShown={onDeleteEmailSuccess}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  accountId: getAccountId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(EmailTemplateDetailsContainer);
