import React from "react";
import { useRouteMatch } from "react-router-dom";
import { ReleaseNotesTab } from "./components/ReleaseNotesTabs";
import {
  COMMON_SERVICES_RELEASE_NOTES_PATH,
  DATA_AND_ANALYTICS_RELEASE_NOTES_PATH,
  DEVICE_MANAGEMENT_RELEASE_NOTES_PATH,
  SECURITY_SERVICES_RELEASE_NOTES_PATH,
  SELF_SERVICE_TOOLS_RELEASE_NOTES_PATH,
} from "./constants";

function useSelectedTab(): ReleaseNotesTab {

  const portalReleaseNotesMatch = useRouteMatch(SELF_SERVICE_TOOLS_RELEASE_NOTES_PATH);
  const dataAndAnalyticsReleaseNotesMatch = useRouteMatch(DATA_AND_ANALYTICS_RELEASE_NOTES_PATH);
  const commonServicesReleaseNotesMatch = useRouteMatch(COMMON_SERVICES_RELEASE_NOTES_PATH);
  const deviceManagementReleaseNotesMatch = useRouteMatch(DEVICE_MANAGEMENT_RELEASE_NOTES_PATH);
  const securityServicesReleaseNotesMatch = useRouteMatch(SECURITY_SERVICES_RELEASE_NOTES_PATH);

  return React.useMemo<ReleaseNotesTab>(() => {
    if (portalReleaseNotesMatch && portalReleaseNotesMatch.isExact) {
      return ReleaseNotesTab.SELF_SERVICE_TOOLS;
    } else if (dataAndAnalyticsReleaseNotesMatch && dataAndAnalyticsReleaseNotesMatch.isExact) {
      return ReleaseNotesTab.DATA_AND_ANALYTICS;
    } else if (commonServicesReleaseNotesMatch && commonServicesReleaseNotesMatch.isExact) {
      return ReleaseNotesTab.COMMON_SERVICES;
    } else if (deviceManagementReleaseNotesMatch && deviceManagementReleaseNotesMatch.isExact) {
      return ReleaseNotesTab.DEVICE_MANAGEMENT;
    } else if (securityServicesReleaseNotesMatch && securityServicesReleaseNotesMatch.isExact) {
      return ReleaseNotesTab.SECURITY_SERVICES;
    } else {
      return ReleaseNotesTab.SECURITY_SERVICES;
    }
  }, [
    portalReleaseNotesMatch,
    dataAndAnalyticsReleaseNotesMatch,
    commonServicesReleaseNotesMatch,
    deviceManagementReleaseNotesMatch,
    securityServicesReleaseNotesMatch,
  ]);
}

export default useSelectedTab;
