import React from "react";
import { Policy } from "@data";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { PoliciesList, PoliciesListActions, PoliciesListModel } from "../policies-list";
import styles from "./styles";

export interface PoliciesModel extends PoliciesListModel {
  className?: string;
  title?: string;
  errorTitle?: string;
  loadingTitle?: string;
}

export interface PoliciesActions extends PoliciesListActions {
  showPolicyDetails?: (policy: Policy) => void;
}

type Props = WithStyles<typeof styles> & PoliciesModel & PoliciesActions & {
  children?: React.ReactNode;
};

export const Policies = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title: defaultTitle = "Policies",
    errorTitle = "Failed to load policies",
    loadingTitle = "Loading policies...",
    loadingLabel= "Fetching policies...",
    noResultsLabel: defaultNoResultsLabel = "No policies found",
    searchViewHint = "Filter results by name",
    nameFilter,
    items = [],
    showLoadingIndicator,
    errorMessage,
    showPolicyDetails = noop,
    onClickItem = showPolicyDetails,
    children,
    ...otherProps
  } = props;

  const showErrorView = React.useMemo(() =>
    !isEmptyString(errorMessage), [errorMessage]);

  const title = React.useMemo(() => {
    if (showErrorView) {
      return errorTitle;
    } else if (showLoadingIndicator) {
      return loadingTitle;
    } else {
      return defaultTitle;
    }
  }, [showErrorView, showLoadingIndicator, errorTitle, loadingTitle, defaultTitle]);

  const noResultsLabel = React.useMemo(() => {
    if (isEmptyString(nameFilter)) {
      return defaultNoResultsLabel;
    } else {
      return "No Results";
    }
  }, [nameFilter, defaultNoResultsLabel]);

  return (
    <div className={classnames("policies", className, classes.container)}>
      <h1 className={classnames("title", classes.title, {[classes.error]: showErrorView})}>
        {title}
      </h1>
      <PoliciesList
        {...otherProps}
        items={items}
        nameFilter={nameFilter}
        loadingLabel={loadingLabel}
        errorMessage={errorMessage}
        noResultsLabel={noResultsLabel}
        searchViewHint={searchViewHint}
        showLoadingIndicator={showLoadingIndicator}
        onClickItem={onClickItem}
      />
      {children}
    </div>
  );
});

export default Policies;
