import React from "react";
import { styles } from "./styles";

const DEFAULT_LINK = "https://www.tracker.dtf.signify.com/projects/IOTCS/summary";

export const SupportRequestLink = (props: {
  children: React.ReactNode;
  href?: string,
  style?: React.CSSProperties,
}) => {

  const {
    children,
    href = DEFAULT_LINK,
    style = styles.link(),
  } = props;

  return (
    <a className="supportRequestLink" style={style} href={href} target="_blank">
      {children}
    </a>
  );
};

export default SupportRequestLink;
