import React from "react";
import classnames from "classnames";
import Link from "@components/link";
import ReleaseNote from "@data/ReleaseNote";
import { isEmptyString, noop } from "@util";
import ReleaseNoteIcon from "./ReleaseNoteIcon";
import IconButton from "@components/icon-button";
import Collapse from "@material-ui/core/Collapse";
import LoadingView from "@components/loading-view";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import WhatsNewIcon from "@material-ui/icons/FiberNew";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { getPathToReleaseNote } from "@modules/releaseNoteDetails/helpers";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { MODULE_PATH as RELEASE_NOTES_PATH } from "@modules/releaseNotes/constants";
import styles from "./styles";

const DEFAULT_LEGACY_RELEASE_NOTES_HREF =
  "https://www.collaboration.dtf.signify.com/display/IOTPLAT/IoT+Platform+Releases";

export interface WhatsNewModel {
  className?: string;
  title?: string;
  emptyViewTitle?: string;
  legacyReleaseNotesHref?: string;
  releaseNotes?: ReleaseNote[];
  errorMessage?: string;
  showLoadingIndicator?: boolean;
  maxNumAutoRetry?: number;
}

export interface WhatsNewActions {
  refresh?: () => void;
}

type Model = WhatsNewModel;
type Actions = WhatsNewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const WhatsNew = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "What's New",
    legacyReleaseNotesHref = DEFAULT_LEGACY_RELEASE_NOTES_HREF,
    releaseNotes = [],
    errorMessage,
    showLoadingIndicator,
    maxNumAutoRetry = 3,
    refresh = noop,
    children,
  } = props;

  const [autoRetryCounter, setAutoRetryCounter] = React.useState(0);

  const [fetchCounter, setFetchCounter] = React.useState(0);

  const [initialized, setInitialized] = React.useState(false);

  const [collapsed, setCollapsed] = React.useState(false);

  const canAutoRetry = React.useMemo(() =>
      autoRetryCounter < maxNumAutoRetry,
    [autoRetryCounter, maxNumAutoRetry]);

  const showLoadingView = React.useMemo(() =>
      showLoadingIndicator || !initialized,
    [showLoadingIndicator, initialized]);

  const showErrorView = React.useMemo(() =>
      !isEmptyString(errorMessage) && !canAutoRetry,
    [errorMessage, canAutoRetry]);

  const showEmptyView = React.useMemo(() =>
      !showLoadingView && (showErrorView || (releaseNotes.length === 0)),
    [showLoadingView, showErrorView, releaseNotes]);

  const showListView = React.useMemo(() =>
      !showLoadingView && !showEmptyView,
    [showLoadingView, showEmptyView]);

  React.useEffect(() => {
    if (showLoadingIndicator) {
      setFetchCounter((prev) => prev + 1);
    }
  }, [showLoadingIndicator, setFetchCounter]);

  React.useEffect(() => {
    if ((!showLoadingIndicator && (autoRetryCounter === 0 && fetchCounter > 0)) || !canAutoRetry) {
      setInitialized(true);
    }
  }, [showLoadingIndicator, autoRetryCounter, fetchCounter, canAutoRetry, setInitialized]);

  React.useEffect(() => {
    if (!showLoadingIndicator && !isEmptyString(errorMessage) && canAutoRetry) {
      setAutoRetryCounter(prev => prev + 1);
      refresh();
    }
  }, [showLoadingIndicator, errorMessage, canAutoRetry, setAutoRetryCounter, refresh]);

  return (
    <div className={classnames("whatsNew", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        <WhatsNewIcon
          className={classnames("headerIcon", classes.headerIcon)}
        />
        <Typography className={classnames("title", classes.title)} variant="h3">
          {title}
        </Typography>
        <IconButton
          className={classnames("toggleCollapsedButton", classes.toggleCollapsedButton)}
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed && (
            <ExpandMore
              className={classnames("toggleCollapsedIcon", classes.toggleCollapsedIcon)}
            />
          )}
          {!collapsed && (
            <ExpandLess
              className={classnames("toggleCollapsedIcon", classes.toggleCollapsedIcon)}
            />
          )}
        </IconButton>
      </div>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit={true}>
        <div className={classnames("content", classes.content)}>
          {showLoadingView && (
            <LoadingView
              className={classnames("loadingView", classes.loadingView)}
              size={28}
            />
          )}
          {showEmptyView && (
            <div className={classnames("emptyView", classes.emptyView)}>
              <Link
                className={classnames("legacyReleaseNotesLink", classes.legacyReleaseNotesLink)}
                href={legacyReleaseNotesHref}
                target="_blank"
                rel="noopener noreferrer"
              >
                For older release notes, <u>click here</u>
              </Link>
            </div>
          )}
          {showListView && (
            <React.Fragment>
              <ul
                className={classnames("list", classes.list)}
              >
                {releaseNotes.map(releaseNote => (
                  <li
                    key={releaseNote.getReleaseId()}
                    className={classnames("listItem", classes.listItem)}
                  >
                    <ReleaseNoteIcon
                      releaseType={releaseNote.getReleaseType()}
                      className={classnames("listItemIcon", classes.listItemIcon)}
                    />
                    <div className={classnames("listItemContent", classes.listItemContent)}>
                      <Link
                        className={classnames("listItemLink", classes.listItemLink)}
                        href={getPathToReleaseNote(releaseNote)}
                      >
                        <Typography
                          className={classnames("listItemTitle", classes.listItemTitle)}
                          variant="h6"
                        >
                          {releaseNote.getTitle()}
                        </Typography>
                      </Link>
                      {releaseNote.hasSummary() && (
                        <Typography
                          className={classnames("listItemBody", classes.listItemBody)}
                          variant="body2"
                        >
                          {releaseNote.getSummary()}
                        </Typography>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
              <Link
                className={classnames("viewMoreLink", classes.viewMoreLink)}
                href={RELEASE_NOTES_PATH}
              >
                View More
              </Link>
            </React.Fragment>
          )}
        </div>
      </Collapse>
      {children}
    </div>
  );
});

export default WhatsNew;
