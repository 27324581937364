import React from "react";
import { DeviceDataType } from "@components";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  useDeviceRegistryClient,
} from "@hooks";
import { isEmptyString, noop } from "@util";
import { DeviceDataScope } from "@data";

export interface UseEditDeviceDataBySchemaProps extends Partial<UseApiRequestProps<void>> {
  deviceId: string;
  schemaIdentity: string;
  jsonObject: any;
  deviceDataType: DeviceDataType;
  typeIdentity?: string;
  deviceDataScope?: DeviceDataScope;
  refreshPage?: () => void;
}

export interface UseEditDeviceDataBySchemaModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UseEditDeviceDataBySchemaActions {
  editDeviceData: () => void;
}

type Props = UseEditDeviceDataBySchemaProps;
type Model = UseEditDeviceDataBySchemaModel;
type Actions = UseEditDeviceDataBySchemaActions;
type Result = [Model, Actions];

export const useEditDeviceDataBySchema = (props: Props): Result => {

  const {
    deviceDataType,
    deviceDataScope = DeviceDataScope.DEVICE,
    deviceId,
    schemaIdentity,
    typeIdentity = "",
    jsonObject,
    refreshPage = noop,
    ...otherProps
  } = props;

  const DeviceRegistryClient = useDeviceRegistryClient();

  const defaultErrorMessage = React.useMemo(() =>
    `Failed to edit ${deviceDataType} data for device` , [deviceDataType]);

  const requestBody = JSON.stringify({
    data: jsonObject
  });

  const mapApiCallToOperation = React.useCallback(() => {
    if (deviceDataType === DeviceDataType.DESIRED) {
      if (deviceDataScope === DeviceDataScope.DEVICE) {
        return DeviceRegistryClient.editDesiredDeviceDataBySchema(deviceId, schemaIdentity, requestBody);
      } else if (deviceDataScope === DeviceDataScope.TYPE) {
        return DeviceRegistryClient.editTypeConfigurationBySchema(typeIdentity, schemaIdentity, requestBody);
      } else {
        return DeviceRegistryClient.editRegionConfigurationBySchema(schemaIdentity, requestBody);
      }
    } else if (deviceDataType === DeviceDataType.METADATA) {
      return DeviceRegistryClient.editDeviceMetadataDataBySchema(deviceId, schemaIdentity, requestBody);
    } else {
      return DeviceRegistryClient.editActualDeviceDataBySchema(deviceId, schemaIdentity, requestBody);
    }
  }, [
    deviceDataType,
    deviceDataScope,
    deviceId,
    schemaIdentity,
    typeIdentity,
    requestBody,
  ]);

  const [{ showSuccessView, ...baseModel }, { refresh: editDeviceData }] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => mapApiCallToOperation(),
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Edit Successful" : "" ,
    [showSuccessView]);

  React.useEffect(() => {
    if (!isEmptyString(successMessage)) {
      refreshPage();
    }
  }, [successMessage, refreshPage]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    editDeviceData,
  }), [
    editDeviceData,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEditDeviceDataBySchema;
