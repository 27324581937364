import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import {
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel,
} from "@components";
import RemoveDevice from "../../components/devices/RemoveDevice";
import { DeviceDataModel } from "@data";
import { DeviceEnrollmentWizardSelectors } from "../../selectors";
import { DeviceEnrollmentWizardActions } from "../../actions";

type Model = ConfirmationDialogModel<DeviceDataModel>;
type Actions = ConfirmationDialogActions<DeviceDataModel>;

const RemoveDeviceDialog = (props: Model & Actions) => {

  const { open = false } = props;

  if (!open) {
    return null;
  }

  return (
    <ConfirmationDialog {...props}>
      <RemoveDevice />
    </ConfirmationDialog>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  className: "removeDeviceDialog",
  title: "Remove Device",
  continueButtonLabel: "Remove Device",
  open: DeviceEnrollmentWizardSelectors.isRemoveDeviceDialogVisible(state),
  item: DeviceEnrollmentWizardSelectors.getDevice(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  cancel: () => dispatch(DeviceEnrollmentWizardActions.closeRemoveDeviceDialog()),
  confirm: (device: DeviceDataModel) => dispatch(DeviceEnrollmentWizardActions.removeDevice(device.getDeviceId())),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveDeviceDialog);
