import { Module } from "@data";
import { reset } from "./actions";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { isAccessDeniedVisible, isEmptyViewVisible } from "./selectors";
import view from "./CreateUserView";

export const createUserModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Create User",
  title: "Create New User",
  className: "createUserModule",
  primaryColor,
  icon,
  view,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: reset,
});

export default createUserModule;
