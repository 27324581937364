import { Module } from "@data";
import { initialize, onMount } from "./actions";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./UsersView";

export const usersModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Users",
  title: "User Management",
  className: "usersModule",
  primaryColor,
  icon,
  view,
  backButton: null,
  initialize,
  onMount,
});

export default usersModule;
