import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { getNotes, getNoteErrorMessage, isNoteLoadingIndicatorVisible } from "../selectors";
import { Model, NoteListView } from "../components/AdminNotes/NotesListView";

const mapStateToProps = (state: AppSchema): Model => ({
  items: getNotes(state),
  errorMessage: getNoteErrorMessage(state),
  loadingIndicator: isNoteLoadingIndicatorVisible(state),
});

export default connect<Model, {}, Model>(
  mapStateToProps,
)(NoteListView);
