import React from "react";
import { Module } from "@data";
import { MODULE_ID } from "./constants";
import { equalsIgnoreCase } from "@util";
import { isDialogOpen } from "./selectors";
import { initialize, open } from "./actions";
import { PrimaryIcon } from "../dataApprovalRequests/components/styles";
import ApproveRequestDialog from "./containers/ApproveRequestDialog";

export const approveRequestModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "Approve Request",
  title: "Approve Request",
  icon: PrimaryIcon,
  view: <ApproveRequestDialog />,
  onClick: open,
  isDialogOpen,
  initialize,
  disabled: !equalsIgnoreCase("true", process.env.REACT_APP_DATA_GOVERNANCE_MODULES_ENABLED),
};

export default approveRequestModule;
