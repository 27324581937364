import Color from "@Color";
import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
      marginBottom: 12,
    },
    memorySelection: {
      width: "100%",
    },
    dropdown: {
      alignItems: "stretch",
      marginBottom: 25,
      width: 400,
      "& .MuiOutlinedInput-input": {
        paddingBottom: 12,
        paddingLeft: 10,
      },
      "& .dropdownMenuHelperText": {
        marginLeft: 4,
      },
    },
    dropdownMenuSelect: {
      "& > fieldset": {
        zIndex: 0,
        top: 0,
      },
      "& .MuiSelect-select": {
        paddingBottom: 12,
      }
    },
    dropdownMenuLabel: {
      zIndex: 1,
      backgroundColor: Color.WHITE,
      padding: "0 4px",
      marginLeft: 6,
      lineHeight: "12px",
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    customMemory: {
      width: 140,
      "& p": {
        marginLeft: 0,
        width: 500,
      }
    },
  });

export default styles;
