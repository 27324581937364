import React from "react";
import classnames from "classnames";
import {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import { PrimaryIcon } from "./styles";
import { UiPackageColumn } from "../reducers";
import ModuleListView from "@components/module-list-view";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";

export const columns = [
  UiPackageColumn.FILES,
];

export type Model = SortedSearchResultsListModel<any, any>;

export interface Actions extends SortedSearchResultsListActions<any, any> {
  toggleSortOrder?: () => void;
  setSortedColumn?: (column: string) => void;
}

export const FilesList = (props: Model & Actions) => {

  const {
    className,
    items = [],
    toggleSortOrder,
    setSortedColumn,
    ...remainingProps
  } = props;

  const moduleListItems: ListViewItem<any>[] = React.useMemo(() =>
    items.map(file => {
      return {
        item: file,
        pathToDetailsView: "",
        icon: PrimaryIcon,
        columnAttributes: createColumns([
          {
            className: "fileName",
            value: file,
            column: UiPackageColumn.FILES,
            firstColumn: true,
          }
        ])
      };
    }), [items]);

  return (
    <ModuleListView
      { ...remainingProps }
      listViewItems={moduleListItems}
      className={classnames("filesList", className)}
      columns={columns}
      onClickColumn={setSortedColumn}
      onClickToggleSortOrder={toggleSortOrder}
    />
  );
};

export default FilesList;
