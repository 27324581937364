import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";

export interface WorkloadVariableSchemaAttributes {
  name: string;
  description: string;
  required: boolean;
  type: string;
}

export class WorkloadVariableSchema extends Record({
  name: "",
  description: "",
  required: false,
  type: "STRING",
}) implements WorkloadVariableSchemaAttributes {

  public static EMPTY: WorkloadVariableSchema = new WorkloadVariableSchema();

  public readonly name: string;
  public readonly description: string;
  public readonly required: boolean;
  public readonly type: string;

  public getName(): string {
    return getStringValue(this.name);
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public isRequired(): boolean {
    return this.required;
  }

  public getType(): string {
    return getStringValue(this.type);
  }

  public hasName(): boolean {
    return !isEmptyString(this.getName());
  }
}

export default WorkloadVariableSchema;
