import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MODULE_PATH as DETAILS_PAGE_PATH } from "@modules/dataSetRequestDetails/constants";
import { close, deleteDataSetRequest } from "../actions";
import {
  getErrorMessage,
  getAccountId,
  getDataSetAlias,
  getSuccessMessage,
  isAccessDeniedVisible,
  isDialogOpen,
  isLoadingIndicatorVisible,
  isDataSetValid,
} from "../selectors";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import DeleteDataSetRequest from "../components/DeleteDataSetRequest";

interface Model extends PortalModuleDialogModel<string> {
  accountId?: string;
  dataSetAlias?: string;
}

type Actions = PortalModuleDialogActions<string>;

const DeleteDataSetRequestDialog = (props: Model & Actions) => {

  const { accountId, dataSetAlias, cancel: closeDialog = noop } = props;

  const match = useRouteMatch(DETAILS_PAGE_PATH);

  const history = useHistory();

  const goBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onSuccessMessageShown = React.useCallback(() => {

    if (match && match.isExact) {
      goBack();
    }

    closeDialog();

  }, [match, closeDialog, goBack]);

  return (
    <PortalModuleDialog {...props} onSuccessMessageShown={onSuccessMessageShown}>
      <DeleteDataSetRequest accountId={accountId} dataSetAlias={dataSetAlias}/>
    </PortalModuleDialog>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  className: "deleteDataSetRequestDialog",
  title: "Cancel Data Set Request",
  open: isDialogOpen(state),
  item: getDataSetAlias(state),
  loading: isLoadingIndicatorVisible(state),
  accessDenied: isAccessDeniedVisible(state),
  errorMessage: getErrorMessage(state),
  successMessage: getSuccessMessage(state),
  continueButtonLabel: "Cancel Request",
  continueButtonDisabled: !isDataSetValid(state),
  accountId: getAccountId(state),
  dataSetAlias: getDataSetAlias(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  cancel: () => dispatch(close()),
  confirm: () => dispatch(deleteDataSetRequest()),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteDataSetRequestDialog);
