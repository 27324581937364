import AppSchema from "@schemas";
import { isEmptyString } from "@util";
import { IdentityType, User } from "@data";
import restoreFromLocalStorage from "./restoreFromLocalStorage";
import restoreFromSessionStorage from "./restoreFromSessionStorage";

export const restorePreviousState = (): AppSchema => {

  // Restore redux store data that we keep in local storage
  const localStorageData = restoreFromLocalStorage();

  // Restore redux store data that we keep in local storage
  const sessionStorageData = restoreFromSessionStorage();

  // Assemble previous state from restored local storage data and session storage data
  const previousState = {
    ...localStorageData,
    ...sessionStorageData,
  };

  const {
    lastMouseMove = 0,
    identityType = IdentityType.NONE,
    refreshToken: restoredRefreshToken = "",
    authToken = "",
    authTokenExpiryTime = "",
    principalId = "",
    accountId = "",
    login: {
      nonce,
      identityType: lastIdentityType,
      ...loginState
    } = {},
    ...otherAttrs
  } = previousState;

  // TODO: Why is it valid if it's none?
  const isSessionStorageValid = lastIdentityType === IdentityType.NONE || identityType === lastIdentityType;

  const restoredLoginState = {
    ...loginState,
    ...(lastIdentityType === IdentityType.NONE ? ({}) : ({
      ...(identityType === lastIdentityType ? ({
        identityType: lastIdentityType,
      }) : ({
        ...(identityType === IdentityType.NONE ? ({
          identityType: lastIdentityType,
        }) : ({
          identityType,
        })),
      })),
    })),
  };

  const refreshToken = identityType === IdentityType.USER ? restoredRefreshToken : "";

  return {
    ...otherAttrs,
    ...(isEmptyString(principalId) || identityType === IdentityType.NONE ? ({}) : ({
      identityType,
      authTokenExpiryTime,
      principalId,
      accountId,
      ...(!isSessionStorageValid ? ({}) : ({
        authToken,
        refreshToken,
        lastMouseMove,
        showPortal: !isEmptyString(principalId) &&
          !isEmptyString(accountId) &&
          !isEmptyString(authToken) &&
          new Date(authTokenExpiryTime) >= new Date &&
          (identityType === IdentityType.USER ? !isEmptyString(refreshToken) : true),
        ...(identityType !== IdentityType.USER ? ({}) : ({
          currentUser: new User({
            id: principalId,
            userId: principalId,
          }).toJS(),
        })),
      })),
    })),
    ...(isEmptyString(nonce) ? ({ login: restoredLoginState }) : ({
      login: {
        ...restoredLoginState,
        nonce,
      },
    })),
  } as AppSchema;
};

export default restorePreviousState;
