import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import Button, { ButtonProps } from "@components/button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { clickHandler } from "@util/ReactEventUtils";
import styles, { button } from "./styles";

const CustomButton = withStyles(button)(Button);

export interface TextButtonProps extends ButtonProps {
  label?: string;
  onClick?: () => void;
}

type Props = WithStyles<typeof styles> & TextButtonProps;

export const TextButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className = "textButton",
    label = "Click Me",
    onClick = noop,
    fullWidth = false,
    ...otherProps
  } = props;

  const { children = label } = otherProps;

  return (
    <CustomButton
      {...otherProps}
      className={classnames(className, classes.button)}
      variant="text"
      color="inherit"
      fullWidth={fullWidth}
      onClick={clickHandler(onClick)}
    >
      {children}
    </CustomButton>
  );
});

export default TextButton;
