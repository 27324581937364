import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { namespaceView as styles } from "./styles";

export interface Model {
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const NamespaceView = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Select Device Type Namespace",
    subtitle,
    children,
  } = props;

  return (
    <div className={classnames("namespaceView", classes.container)}>
      <label className={classnames("title", classes.title)}>{title}</label>
      {subtitle && (
        <label className={classnames("subtitle", classes.subtitle)}>{subtitle}</label>
      )}
      <div className={classnames(classes.contentWrapper)}>
        {children}
      </div>
    </div>
  );
});

export default NamespaceView;
