import ModuleIcon from "@material-ui/icons/Create";
import { Theme } from "@material-ui/core/styles/createTheme";
import { createStyles, StyleRules } from "@material-ui/core/styles";
import { primaryColor as deviceTypesModulePrimaryColor } from "@modules/deviceTypes/components/styles";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = deviceTypesModulePrimaryColor;

export const nameTextField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "14px",
      fontWeight: 300,
      padding: "2px 0",
      margin: 0,
    },
    "& input": {
      fontSize: 14,
      fontWeight: 400,
      padding: "18.5px 14px",
      margin: 0,
    },
  },
};

export const descriptionTextField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& textarea": {
      fontSize: 14,
      fontWeight: 400,
      padding: 0,
      margin: 0,
    },
  },
};

export const apiVersionView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    subtitle: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 16,
      lineHeight: "28px",
      marginTop: 16,
    },
    switch: {
      marginTop: 16,
    },
    alert: {
      marginTop: 16,
    },
    alertTitle: {
      fontSize: 14,
      marginTop: 2,
    },
    alertDescription: {
      fontSize: 14,
      lineHeight: "21px",
      marginTop: 10,
      marginBottom: 0,
    },
    errorView: {
      margin: 0,
      "& label.message": {
        margin: "12px 0 6px",
      },
    },
    lockIcon: {
      color: Color.GREY3,
      width: 28,
      height: 28,
      marginLeft: 15,
    },
  });

export const namespaceView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    subtitle: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 16,
      lineHeight: "28px",
      marginTop: 16,
    },
    error: {
      marginTop: 25,
    },
    contentWrapper: {
      flex: "1 0 auto",
    },
  });

export const groupsView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    securityGroupsList: {
      flex: "1 0 auto",
    },
  });

export const schemasView = (theme: Theme) =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    backdrop: {
      background: "rgba(105, 105, 105, 0.25)",
      zIndex: theme.zIndex.modal,
    },
    progressIndicator: {
      color: "#1432ff",
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 1,
    },
    contentWrapper: {
      flex: "1 0 auto",
      "& .addFilterButton": {
        color: Color.TEXT,
      },
    },
  });

export const nameView = (theme: Theme) =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    errorViewContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 18,
      lineHeight: "21px",
      marginTop: 8,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    deviceTypeName: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    deviceTypeDescriptionLabel: {
      marginTop: 25,
    },
    deviceTypeDescription: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    tagEditor: {
      marginTop: 25,
    },
  });

export const deviceTypeEditorView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    header: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      position: "relative",
      color: primaryColor,
    },
    icon: {
      width: 40,
      height: 40,
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    controls: {
      marginTop: 16,
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    summaryEditorButton: {
      marginLeft: 0,
    },
    jsonEditorButton: {
      marginLeft: 15,
    },
    error: {
      marginTop: 25,
    },
  });

export const reviewView = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    propertyContainer: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      justifyContent: "stretch",
      marginTop: 25,
      textAlign: "left",
    },
    propertyName: {
      flex: "0 0 100px",
      alignSelf: "flex-start",
      justifySelf: "flex-start",
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      fontSize: 16,
    },
    propertyValue: {
      flex: "1 1 auto",
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "monospace",
      fontSize: 14,
      marginLeft: 15,
      fontWeight: 500,
    },
    jsonEditor: {
      flex: "1 0 350px",
      marginTop: 25,
    },
    backdrop: {
      background: "rgba(105, 105, 105, 0.25)",
      zIndex: theme.zIndex.modal,
    },
  });

export const summaryEditorView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 25,
    },
    center: {
      justifyContent: "center",
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      alignSelf: "center",
      textAlign: "center",
    },
  });

export const jsonEditorView = () =>
  createStyles({
    container: {
      flex: "1 0 350px",
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 15,
    },
    jsonEditor: {
      flex: "1 0 auto",
      marginTop: 8,
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
      marginTop: 6,
      marginBottom: 4,
    },
    helperText: {
      color: Color.TEXT,
      fontSize: 13,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 4,
    },
    toggleApiSwitch: {
    },
    extraTopMargin: {
      marginTop: 10,
    },
  });

export const editModeInitializeErrorView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
    },
    errorView: {
      "& .message": {
        marginTop: 15,
        fontSize: 16,
      },
    },
    actions: {
      marginTop: 25,
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    cancelButton: {
    },
    retryButton: {
      marginLeft: 25,
    },
  });

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
    },
  });

export default styles;
