import React from "react";
import classnames from "classnames";
import { clickHandler, noop } from "@util";
import { ActionMenuItem, ActionsMenu } from "@components";
import { Button } from "@components/button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles, { breadcrumb } from "./styles";

const Breadcrumb = withStyles(breadcrumb)(Button);

enum Action {
  ADD_PROPERTY = "add_property",
}

const actions: ActionMenuItem[] = [
  {
    id: Action.ADD_PROPERTY,
    name: "Add Property",
  },
];

export interface Model {
  className?: string;
  title?: string;
  hideActions?: boolean;
  breadcrumbs?: string[];
  children?: React.ReactNode;
}

export interface Actions {
  addProperty?: () => void;
  clearBreadcrumbs?: () => void;
  setBreadcrumbs?: (breadcrumbs: string[]) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const PropertiesEditor = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Properties",
    breadcrumbs = [],
    hideActions,
    children,
    addProperty = noop,
    clearBreadcrumbs,
    setBreadcrumbs = noop,
  } = props;

  const activeIndex = breadcrumbs.length === 0 ? 0 : breadcrumbs.length - 1;

  const onClickBreadcrumb = (selectedIndex: number) => clickHandler(() =>
    setBreadcrumbs(breadcrumbs.slice(0, selectedIndex + 1)));

  const onClickAction = (action: ActionMenuItem) => {
    if (action.id === Action.ADD_PROPERTY) {
      addProperty();
    }
  };

  return (
    <div className={classnames("propertiesEditor", className, classes.container)}>
      <div className={classnames("breadcrumbsContainer", classes.breadcrumbsContainer)}>
        {breadcrumbs.length === 0 && (
          <Breadcrumb
            className={classnames("breadcrumb", classes.breadcrumb)}
            variant="text"
            disabled={true}
            disableRipple={true}
            disableFocusRipple={true}
            disableTouchRipple={true}
          >
            {title}
          </Breadcrumb>
        )}
        {breadcrumbs.length > 0 && (
          <Breadcrumb
            className={classnames("breadcrumb", classes.breadcrumb)}
            variant="text"
            disableRipple={true}
            disableFocusRipple={true}
            disableTouchRipple={true}
            onClick={clickHandler(clearBreadcrumbs)}
          >
            {title}
          </Breadcrumb>
        )}
        {breadcrumbs.map((breadcrumbName: string, index: number) => (
          <React.Fragment key={`breadcrumb-${index}`}>
            <span
              key={`breadcrumb-${index}-divider`}
              className={classnames("breadcrumbDivider", classes.breadcrumbDivider)}
            >
              &gt;
            </span>
            <Breadcrumb
              key={`breadcrumb-${index}-button`}
              className={classnames("breadcrumb", classes.breadcrumb, {
                [classes.active]: index === activeIndex,
              })}
              variant="text"
              disableRipple={true}
              disableFocusRipple={true}
              disableTouchRipple={true}
              disabled={index === activeIndex}
              onClick={onClickBreadcrumb(index)}
            >
              {breadcrumbName}
            </Breadcrumb>
          </React.Fragment>
        ))}
        {!hideActions && (
          <ActionsMenu
            actions={actions}
            buttonVariant="icon"
            className={classes.actionsMenu}
            buttonClassName={classes.actionsMenuIconButton}
            onClickAction={onClickAction}
          />
        )}
      </div>
      {children}
    </div>
  );
});

export default PropertiesEditor;
