import React from "react";
import { noop, openBroadcastChannel } from "@util";
import {
  BROADCAST_ACTION_PING,
  BROADCAST_CHANNEL_SERVICE_RESTORE_SESSION,
} from "@store/constants";

export interface UseRestoreServiceSessionModel {
  loggedIn?: boolean;
}

export interface UseRestoreServiceSessionActions {
  setAccessToken?: (accessToken: string) => void;
  showRestoreSessionLoadingIndicator?: () => void;
  hideRestoreSessionLoadingIndicator?: () => void;
  restoreSessionFailed?: () => void;
}

type Model = UseRestoreServiceSessionModel;
type Actions = UseRestoreServiceSessionActions;
type Props = Model & Actions;

const useRestoreServiceSession = (props: Props): boolean => {

  const {
    loggedIn,
    setAccessToken = noop,
    showRestoreSessionLoadingIndicator = noop,
    hideRestoreSessionLoadingIndicator = noop,
    restoreSessionFailed = noop,
  } = props;

  const [waitingForSession, setWaitingForSession] = React.useState(!loggedIn);

  React.useEffect(() => {

    if (!waitingForSession) {
      return noop;
    }

    let ignore = false;

    const listener = (broadcastEvent: MessageEvent) => {
      if (!ignore) {
        if (broadcastEvent.data !== BROADCAST_ACTION_PING) {
          setWaitingForSession(false);
          setAccessToken(broadcastEvent.data);
        }
      }
    };

    const { postMessage, unsubscribe } = openBroadcastChannel(BROADCAST_CHANNEL_SERVICE_RESTORE_SESSION, listener);

    // As long as session has not been restored, keep sending pings in case this tab was restored
    // before the authenticated & session storage sandbox authenticated service principal tab.
    const interval = setInterval(() => {
      postMessage(BROADCAST_ACTION_PING);
    }, 50);

    postMessage(BROADCAST_ACTION_PING);

    return () => {
      ignore = true;
      clearInterval(interval);
      unsubscribe();
    };

  }, [waitingForSession, setAccessToken, setWaitingForSession]);

  // If this component was mounted, it means there is a chance that the service session can be
  // restored; however, if we have waited more than enough time to expect a broadcast from another
  // active tab, then we should hide the loading indicator and assume the session cannot be restored
  React.useEffect(() => {
    showRestoreSessionLoadingIndicator();
    const timer = setTimeout(() => {
      hideRestoreSessionLoadingIndicator();
      restoreSessionFailed();
      setWaitingForSession(false);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return waitingForSession;
};

export default useRestoreServiceSession;
