import React from "react";
import classnames from "classnames";
import { HelpIcon } from "@icons";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { TooltipIcon } from "./TooltipIcon";
import { Vector } from "../../data";
import { styles } from "./styles";

export const TooltipIconLabel = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  label: React.ReactNode,
  icon?: React.ComponentType<SvgIconProps>;
  iconColor?: string;
  iconSize?: Vector;
  iconTooltip: string,
}) => {

  const { classes, className, label, icon: Icon = HelpIcon, iconColor, iconSize, iconTooltip } = props;

  return (
    <div className={classnames(className, classes.container)}>
      <label className={classes.label}>{label}</label>
      <TooltipIcon icon={Icon} color={iconColor} size={iconSize} tooltipText={iconTooltip} />
    </div>
  );
});
