import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    row: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "24px",
    },
    requiredContainer: {
      marginLeft: 8,
    },
    textField: {
      fontSize: 12,
      padding: 0,
      height: 50,
    },
    nameTextField: {
    },
    descriptionTextField: {
      marginTop: 15,
    },
    requiredCheckbox: {
    },
  });

export default styles;
