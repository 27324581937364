import { Module } from "@data";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import CreateDigitalShadowLayoutView from "./CreateDigitalShadowLayoutView";
import EditDigitalShadowLayoutView from "./EditDigitalShadowLayoutView";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { getEditShadowLayoutPath } from "./helpers";
import { initialize, reset } from "./actions";
import { isDigitalShadowEnabled } from "@util";

type RouteParams = { id?: string; };

export const createDigitalShadowLayoutModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Create Shadow Layout",
  title: "Create New Layout",
  className: "createDigitalShadowLayoutModule",
  primaryColor,
  icon,
  onMount: reset,
  view: CreateDigitalShadowLayoutView,
  disabled: !isDigitalShadowEnabled()
});

export const editDigitalShadowLayoutModule: Module = createModule<RouteParams>({
  id: "edit-digital-shadow-layout-wizard",
  path: getEditShadowLayoutPath(":id"),
  exactPath: true,
  strictPath: true,
  name: "Edit Shadow Layout",
  title: "Edit Shadow Layout",
  className: "editDigitalShadowLayoutModule",
  primaryColor,
  icon,
  view: EditDigitalShadowLayoutView,
  onMount: ({ id: layoutName }: RouteParams = {}) => initialize(layoutName),
  disabled: !isDigitalShadowEnabled()
});

export default createDigitalShadowLayoutModule;
