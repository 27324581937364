import { combineReducers } from "redux";
import { Actions, filterAction, Identity } from "@util";
import { DeviceTypeListItemAttributes } from "@data";

export enum DeviceTypeColumn {
  NONE = "",
  NAMESPACE = "Namespace",
  NAME = "Name",
  VERSION = "Version",
  STATE = "State",
}

export type DeviceTypeItems = { [key: string]: DeviceTypeListItemAttributes };

export interface DeviceTypesSchema {
  searchQuery: string;
  sortedColumn: DeviceTypeColumn;
  sortOrderAscending: boolean;
  items: DeviceTypeItems;
  nextPage: string;
  errorMessage: string;
  showEmptyView: boolean;
  showAccessDenied: boolean;
  showLoadingIndicator: boolean;
}

export const DEFAULT_STATE: DeviceTypesSchema = Object.freeze({
  searchQuery: "",
  sortedColumn: DeviceTypeColumn.NONE,
  sortOrderAscending: true,
  items: {},
  nextPage: "",
  errorMessage: "",
  showEmptyView: true,
  showAccessDenied: false,
  showLoadingIndicator: false,
});

export enum DeviceTypesActionType {
  SET_SEARCH_QUERY = "deviceTypes/set_search_query",
  SET_SORTED_COLUMN = "deviceTypes/set_sorted_column",
  SET_SORT_ORDER = "deviceTypes/set_sort_order",
  SET_DEVICE_TYPES = "deviceTypes/set_device_types",
  SET_NEXT_PAGE = "deviceTypes/set_next_page",
  SET_ERROR_MESSAGE = "deviceTypes/set_error_message",
  TOGGLE_SHOW_LOADING_INDICATOR = "deviceTypes/toggle_show_loading_indicator",
  TOGGLE_SHOW_ACCESS_DENIED = "deviceTypes/toggle_show_access_denied",
  TOGGLE_SHOW_EMPTY_VIEW = "deviceTypes/toggle_show_empty_view",
  LOAD_DEVICE_TYPES = "deviceTypes/load_device_types",
  LOAD_DEVICE_TYPES_SUCCESS = "deviceTypes/load_device_types_success",
  LOAD_DEVICE_TYPES_FAILED = "deviceTypes/load_device_types_failed",
}

const searchQuery = filterAction<string>(Actions(
  DeviceTypesActionType.SET_SEARCH_QUERY))(Identity,
  DEFAULT_STATE.searchQuery);

const sortedColumn = filterAction<DeviceTypeColumn>(Actions(
  DeviceTypesActionType.SET_SORTED_COLUMN))(Identity,
  DEFAULT_STATE.sortedColumn);

const sortOrderAscending = filterAction<boolean>(Actions(
  DeviceTypesActionType.SET_SORT_ORDER))(Identity,
  DEFAULT_STATE.sortOrderAscending);

const items = filterAction<DeviceTypeItems>(Actions(
  DeviceTypesActionType.SET_DEVICE_TYPES))(Identity,
  DEFAULT_STATE.items);

const nextPage = filterAction<string>(Actions(
  DeviceTypesActionType.SET_NEXT_PAGE))(Identity,
  DEFAULT_STATE.nextPage);

const errorMessage = filterAction<string>(Actions(
  DeviceTypesActionType.SET_ERROR_MESSAGE))(Identity,
  DEFAULT_STATE.errorMessage);

const showEmptyView = filterAction<boolean>(Actions(
  DeviceTypesActionType.TOGGLE_SHOW_EMPTY_VIEW))(Identity,
  DEFAULT_STATE.showEmptyView);

const showAccessDenied = filterAction<boolean>(Actions(
  DeviceTypesActionType.TOGGLE_SHOW_ACCESS_DENIED))(Identity,
  DEFAULT_STATE.showAccessDenied);

const showLoadingIndicator = filterAction<boolean>(Actions(
  DeviceTypesActionType.TOGGLE_SHOW_LOADING_INDICATOR))(Identity,
  DEFAULT_STATE.showLoadingIndicator);

export const DeviceTypesReducer = combineReducers<DeviceTypesSchema>({
  searchQuery,
  sortedColumn,
  sortOrderAscending,
  items,
  nextPage,
  errorMessage,
  showEmptyView,
  showAccessDenied,
  showLoadingIndicator,
});

export default DeviceTypesReducer;
