import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { header as styles } from "./styles";
import { HeaderIcon } from "./HeaderIcon";

export interface Model {
  className?: string;
  title?: string;
  showErrorMessage?: boolean;
  showSuccessMessage?: boolean;
  showLoadingIndicator?: boolean;
}

type Props = WithStyles<typeof styles> & Model;

export const Header = withStyles(styles)((props: Props) => {

  const { classes, className, title, showErrorMessage, showSuccessMessage, showLoadingIndicator } = props;

  return (
    <div
      className={classnames("header", classes.container, className, {
        [classes.error]: showErrorMessage,
        [classes.success]: showSuccessMessage,
      })}
    >
      <HeaderIcon
        className={classnames("icon", classes.icon)}
        showErrorIcon={showErrorMessage}
        showSuccessIcon={showSuccessMessage}
        showLoadingIndicator={showLoadingIndicator}
      />
      <label className={classnames("title", classes.title)}>{title}</label>
    </div>
  );
});

export default Header;
