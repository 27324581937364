import React from "react";
import Color from "@Color";
import { connect } from "react-redux";
import { isEmptyString } from "@util";
import { AppSchema } from "@main/schemas";
import { Breadcrumb, Module } from "@data";
import { Selector } from "./createSelectors";
import { BrowserHistoryBackButton } from "@components/back-button";
import addPersistedModuleId from "@main/actions/addPersistedModuleId";
import withLegacyPath from "./withLegacyPath";
import PortalModule, {
  PortalModuleActions as Actions,
  PortalModuleModel as Model,
  PortalModuleProps as Props,
} from "@components/portal-module";

export interface CreateModuleProps<RouteParams = any> extends Module {
  className?: string;
  primaryColor?: string;
  legacyPath?: string | string[];
  view: React.ComponentType<any> | null;
  Container?: React.ComponentType<any>;
  Title?: React.ComponentType<any>;
  Navigation?: React.ComponentType<any>;
  BackButton?: React.ComponentType<any>;
  isEmptyViewVisible?: Selector<boolean>;
  isAccessDeniedVisible?: Selector<boolean>;
  onMount?: (params?: { [p in keyof RouteParams]: string }) => any;
  skipInitializeOnRouteParamsChange?: boolean;
  breadcrumbTitle?: string;
  showBreadcrumbs?: boolean;
  customBreadcrumbs?: Breadcrumb[];
  feedbackScreenshotDisabled?: boolean;
}

export const createModule = <RouteParams, >(props: CreateModuleProps<RouteParams>): Module => {

  const {
    className,
    primaryColor = Color.MODULES,
    path = "",
    legacyPath = "",
    view: View = null,
    Container = null,
    title = null,
    Title = null,
    navigation = null,
    Navigation = null,
    backButton = <BrowserHistoryBackButton className={className} />,
    BackButton = null,
    breadcrumbTitle = "",
    showBreadcrumbs = true,
    customBreadcrumbs,
    whitelistedAccounts = [],
    feedbackScreenshotDisabled = false,
    isEmptyViewVisible = () => false,
    isAccessDeniedVisible = () => false,
    initialize = () => () => Promise.resolve(),
    onMount = initialize,
    isNavLinkActive = (match, { pathname }) => {
      const isPathActive = (desiredPath: string = "", currentPath: string = "") =>
        currentPath.toLowerCase().indexOf(desiredPath.toLowerCase()) === 0;
      if (Array.isArray(path)) {
        return path.some(modulePath => isPathActive(modulePath, pathname));
      } else {
        return isPathActive(path, pathname);
      }
    },
    // Unless the module path uses params that would affect the rendered
    // view when changed, we can skip re-initialization on params changes.
    // This primarily assists with any create/wizard view that uses an
    // unsaved changes Prompt to ensure the user's intention to leave the
    // module when there are unsaved changes present (eg: accidental back button).
    // The nature of the react-router Prompt component resets the path if the user
    // did not mean to leave the page, and we do not want that to cause a module
    // re-initialization, which would remove the previous state.
    skipInitializeOnRouteParamsChange = (() => {
      const paths = Array.isArray(path) ? path : [path];
      const legacyPaths = Array.isArray(legacyPath) ? legacyPath : [legacyPath];
      return !paths.concat(legacyPaths).some(uri => uri.indexOf(":") >= 0);
    })(),
    ...otherProps
  } = props;

  const { exactPath, sensitivePath, strictPath } = otherProps;

  // If a module has registered one or more legacy paths, this will ensure that the legacy urls will
  // redirect to the new path; if the module has no legacy paths, there will be no additional
  // view decoration and the regular PortalModule component will be used instead.
  const PortalModuleView = withLegacyPath({
    legacyPath,
    path,
    exact: exactPath,
    sensitive: sensitivePath,
    strict: strictPath,
  })(PortalModule);

  const createContainer = () => {

    const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
      className,
      primaryColor,
      skipInitializeOnRouteParamsChange,
      showEmptyView: isEmptyViewVisible(state),
      showAccessDenied: isAccessDeniedVisible(state),
      ...ownProps,
    });

    const mapDispatchToProps = (dispatch: any, ownProps: Actions<RouteParams>): Actions<RouteParams> => ({
      initialize: params => dispatch(onMount(params)),
      ...ownProps,
    });

    return connect<Model, Actions<RouteParams>, Props<RouteParams>>(
      mapStateToProps,
      mapDispatchToProps,
    )(PortalModuleView);
  };

  const ViewContainer = Container || createContainer();

  const view = View === null ? null : (
    <ViewContainer>
      <View />
    </ViewContainer>
  );

  // We need to make sure that portal will respond to the legacy path so that our redirect will
  // be rendered. If the legacy path was already added to the path array, this will be a no-op -
  // as will a non-existent legacy path
  const portalModulePath = Array.from(new Set(
    (Array.isArray(path) ? path : [path])
      .concat(legacyPath)
      .filter(uri => !isEmptyString(uri))));

  return {
    view,
    initialize,
    isNavLinkActive,
    // Use original path unless combined path contains two entries
    path: portalModulePath.length <= 1 ? path : portalModulePath,
    backButton: BackButton ? <BackButton /> : backButton,
    title: Title ? <Title /> : title,
    navigation: Navigation ? <Navigation /> : navigation,
    feedbackScreenshotDisabled,
    customBreadcrumbs,
    breadcrumbTitle,
    showBreadcrumbs,
    whitelistedAccounts,
    ...otherProps
  };
};

// Helper for providing the default values that we typically use for list view modules
export const createListViewModule = <RouteParams, >(props: CreateModuleProps<RouteParams>): Module => {

  const {
    backButton = null,
    exactPath = true,
    strictPath = true,
    ...otherProps
  } = props;

  return createModule({
    backButton,
    exactPath,
    strictPath,
    ...otherProps
  });
};

// Helper for creating a module that will be persisted after the first visit and will not be unmounted
export const createLazyPersistedModule = <RouteParams, >(props: CreateModuleProps<RouteParams>): Module => {

  const {
    id,
    onMount = () => () => Promise.resolve(),
    ...otherProps
  } = props;

  return createModule({
    ...otherProps,
    id,
    persist: false,
    onMount: (params?: any) => (dispatch: any) => {
      dispatch(addPersistedModuleId(id));
      return dispatch(onMount(params));
    },
  });
};
