import { getPathToWorkload } from "@modules/dataWorkloadDetails/helpers";
import { CronDay, CronMonth, TriggerRateUnit, TriggerType } from "@data";
import { parseExpression } from "cron-parser";
import { isEmptyString, isValidInteger, isValidNumber } from "@util";

export const getAddTriggerPath = (name: string, version: number, type: TriggerType) =>
  `${getPathToWorkload(name, version)}/triggers/add/${type}`;

export const getEditTriggerPath = (name: string, version: string, type: string) =>
  `${getPathToWorkload(name, isValidInteger(version) ? Number(version) : -1)}/triggers/edit/${type}`;

export const listNextDates = (exp: string, num: number): string[] => {
  const interval = parseExpression(exp, {
    currentDate: new Date(),
    iterator: true,
    utc: true
  });

  return Array(num).fill("").map(() => interval.hasNext() ?
    new Date(interval.next().value.toString()).toUTCString() : "")
    .filter((i) => !isEmptyString(i));
};

/*
    Decrements week day values by 1
    Converts '0,30 8-9 ? * 2,4,6 *' to '0,30 8-9 * * 1,3,5'
    cronForParsing will be used in parser to get next dates
    since the parser uses different ranges for week days
*/
export const cronForParsing = (cronValues: string) => {
  const split = cronValues.substring(0, cronValues.length - 2).split(" ");
  const weekDays = split[split.length - 1];

  split[split.length - 1] = Array.from(weekDays)
    .map((char) => isValidNumber(char) ? parseFloat(char) - 1 : char).join("");

  return split.join(" ");
};

export const getCronValues = (scheduleExpression: string) => {
  const cronValues = scheduleExpression.substring(5, scheduleExpression.length - 1);
  return cronValues.split(" ");
};

export const getCronMonth = (num: number) => {
  switch (num) {
    case 1:
      return CronMonth.JANUARY;
    case 2:
      return CronMonth.FEBRUARY;
    case 3:
      return CronMonth.MARCH;
    case 4:
      return CronMonth.APRIL;
    case 5:
      return CronMonth.MAY;
    case 6:
      return CronMonth.JUNE;
    case 7:
      return CronMonth.JULY;
    case 8:
      return CronMonth.AUGUST;
    case 9:
      return CronMonth.SEPTEMBER;
    case 10:
      return CronMonth.OCTOBER;
    case 11:
      return CronMonth.NOVEMBER;
    case 12:
      return CronMonth.DECEMBER;
    default:
      return CronMonth.JANUARY;
  }
};

export const getCronDayOfWeek = (num: number) => {
  switch (num) {
    case 1:
      return CronDay.SUNDAY;
    case 2:
      return CronDay.MONDAY;
    case 3:
      return CronDay.TUESDAY;
    case 4:
      return CronDay.WEDNESDAY;
    case 5:
      return CronDay.THURSDAY;
    case 6:
      return CronDay.FRIDAY;
    case 7:
      return CronDay.SATURDAY;
    default:
      return CronDay.SUNDAY;
  }
};

// Converts a range of numbers to a string array of all numbers in the range
export const getFullRange = (input: string) => {
  if (!input.includes("-")) {
    return input;
  } else {
    const [start, end] = input.split("-");
    const arr = [];
    for (let i = parseFloat(start); i <= parseFloat(end); i++) {
      arr.push(i.toString());
    }
    return arr;
  }
};

export const mapToRateUnit = (unit: string) => {
  if (unit.includes("minute")) {
    return TriggerRateUnit.MINUTES;
  } else if (unit.includes("hour")) {
    return TriggerRateUnit.HOURS;
  } else {
    return TriggerRateUnit.DAYS;
  }
};
