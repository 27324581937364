import { Record } from "immutable";
import { RequestState } from "@data/DataApprovalRequest";

export interface ApprovalProcessingDetailAttributes {
  processedAt: string;
  processedBy: string;
  requestState: RequestState;
  comments: string;
}

export class ApprovalProcessingDetail extends Record({
  processedAt: "",
  processedBy: "",
  requestState: RequestState.PENDING,
  comments: "",
}) implements ApprovalProcessingDetailAttributes {

  public static EMPTY: ApprovalProcessingDetail = new ApprovalProcessingDetail();

  public readonly processedAt: string;
  public readonly processedBy: string;
  public readonly requestState: RequestState;
  public readonly comments: string;
}
