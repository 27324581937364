import React from "react";
import { getPathToShadowLayout } from "@modules/digitalShadowLayoutDetails/helpers";
import {
  getCreateShadowLayoutPath,
  getEditShadowLayoutPath
} from "@modules/digitalShadowLayoutWizard/helpers";
import { useHistory } from "react-router-dom";
import { DSLayoutInfo } from "@data";
import DigitalShadowLayouts from "./containers/DigitalShadowLayouts";

export function DigitalShadowTypesView() {

  const history = useHistory();

  const showLayoutDetails = React.useCallback((dsLayoutName: DSLayoutInfo) => {
    history.push(getPathToShadowLayout(dsLayoutName.getName()));
  }, [history]);

  const createLayout = React.useCallback(() =>
    history.push(getCreateShadowLayoutPath()), [history]);

  const editLayout = React.useCallback((dsLayoutName: DSLayoutInfo) => {
    history.push(getEditShadowLayoutPath(dsLayoutName.getName()));
  }, [history]);

  return (
    <DigitalShadowLayouts
      showLayoutDetails={showLayoutDetails}
      createLayout={createLayout}
      editLayout={editLayout}
    />
  );
}

export default DigitalShadowTypesView;
