import React from "react";
import classnames from "classnames";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@components/text-field";
import InputAdornment from "@material-ui/core/InputAdornment";
import { equalsIgnoreCase, formEventHandler, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface FileNameFilterModel {
  className?: string;
  disabled?: boolean;
  fileNameFilter?: string;
  fileNameFilterDelay?: number;
}

export interface FileNameFilterActions {
  setFileNameFilter?: (nameFilter: string) => void;
}

type Model = FileNameFilterModel;
type Actions = FileNameFilterActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const FileNameFilter = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    disabled,
    fileNameFilter = "",
    fileNameFilterDelay =  500,
    setFileNameFilter = noop,
  } = props;

  const [pendingNameFilter, setPendingNameFilter] = React.useState(fileNameFilter);

  // Whenever the search query changes, clear the previous timer, and create a new timer
  // to wait 500ms (default value), check if the search query does not match the case
  // insensitive nameFilter that was previously used to trigger a search. If it is a new query,
  // update the name filter to trigger a new search.
  React.useEffect(() => {

    if (!equalsIgnoreCase(pendingNameFilter, fileNameFilter)) {
      const timer = setTimeout(() => {
        setFileNameFilter(pendingNameFilter);
      }, fileNameFilterDelay);

      return () => clearTimeout(timer);
    }

    return () => noop();

  }, [fileNameFilterDelay, fileNameFilter, pendingNameFilter, setFileNameFilter]);

  return (
    <TextField
      className={classnames("fileNameFilter", className, classes.container)}
      size="small"
      type="search"
      name="fileName"
      color="secondary"
      variant="outlined"
      value={pendingNameFilter}
      disabled={disabled}
      placeholder="Example Value: .txt"
      label="Filter results by filename"
      InputLabelProps={{ shrink: true }}
      helperText="Results must have a filename that matches this value"
      onChange={formEventHandler(setPendingNameFilter)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon/>
          </InputAdornment>
        ),
      }}
    />
  );
});

export default FileNameFilter;
