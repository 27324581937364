import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { createSelector } from "reselect";
import { createSelectors, Selector } from "@base/createSelectors";
import { DEFAULT_STATE, PolicyManagementSchema, PolicyManagementViewMode, SelectPolicyStep, } from "./reducers";
import { PolicyOperation, PolicyOperationPrincipal } from "@hooks";
import { getStringValue, isEmptyString } from "@util";
import { PolicyBulkRequest } from "@data/PolicyBulkRequest";
import { ManagedPolicyBulkRequest } from "@data/ManagedPolicyBulkRequest";

const ACCOUNT_ID_REGEX = new RegExp(/lrn.*[:]([0-9]+)/);

export const {
  getErrorMessage,
  getAccessToken,
  getSuccessMessage,
  principalId: getPrincipalId,
  policyName: getPolicyName,
  policyRef: getPolicyRef,
  operation: getOperation,
  principalType: getPrincipalType,
  viewMode: getViewMode,
  policyStep: getPolicyStep,
  policyBulkRequest: getPolicyBulkRequest,
  selectedPolicies: getSelectedPolicies,
  selectedManagedPolicies: getSelectedManagedPolicies,
  managedPolicyDomain: getManagedPolicyDomain,
} = createSelectors<PolicyManagementSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const isAttachOperation: Selector<boolean> = createSelector(
  getOperation, (operation: PolicyOperation) => PolicyOperation.ATTACH === operation);

export const isDetachOperation: Selector<boolean> = createSelector(
  getOperation, (operation: PolicyOperation) => PolicyOperation.DETACH === operation);

export const isPolicySelected: Selector<boolean> = createSelector(
  getPolicyName, (policyName: string) => !isEmptyString(policyName));

export const isSaveButtonEnabled: Selector<boolean> = createSelector(
  isPolicySelected, (policySelected: boolean) => policySelected);

export const isCustomPolicyViewSelected: Selector<boolean> = createSelector(
  getViewMode, (viewMode: PolicyManagementViewMode) => viewMode === PolicyManagementViewMode.CUSTOM
);

export const isManagedPolicyViewSelected: Selector<boolean> = createSelector(
  getViewMode, (viewMode: PolicyManagementViewMode) => viewMode === PolicyManagementViewMode.MANAGED
);

export const getInformation: Selector<string> = createSelector(
  isManagedPolicyViewSelected, (managedPolicy: boolean) =>
    managedPolicy ?
      "Managed Policies are created and managed by Signify IoT platform" :
      "Custom Policies are created and managed by the users. Custom Policies provide more precise control over your polices than Managed Policies"
);

export const isReviewView: Selector<boolean> = createSelector(
  getPolicyStep, (viewMode: SelectPolicyStep) => viewMode === SelectPolicyStep.REVIEW
);

export const isPoliciesView: Selector<boolean> = createSelector(
  getPolicyStep, (viewMode: SelectPolicyStep) => viewMode === SelectPolicyStep.POLICIES
);

export const isNextButtonEnabled: Selector<boolean> = createSelector(
  [isPolicySelected], (policySelected: boolean) => policySelected);

export const getSaveButtonLabel: Selector<string> = createSelector(
  [isAttachOperation, isManagedPolicyViewSelected, getPolicyBulkRequest ],
  (attach: boolean, managedPolicy: boolean, policies: PolicyBulkRequest[] | ManagedPolicyBulkRequest[]) => {
    const plural = policies.length <= 1 ? "Policy" : "Policies";
    if (attach) {
      if (managedPolicy) {
        return "Attach Managed " + plural;
      } else {
        return "Attach Custom " + plural;
      }
    } else {
      if (managedPolicy) {
        return "Detach Managed " + plural;
      } else {
        return "Detach Custom " + plural;
      }
    }
  });

export const getModuleTitle: Selector<string> = createSelector(
  [getPrincipalId, getOperation, getPrincipalType],
  (principalId: string, operation: PolicyOperation, type: PolicyOperationPrincipal) =>
    `Select policies to ${operation} ${operation === PolicyOperation.ATTACH ? "to" : "from" } ${type} ${principalId}`);

export const getAccountIdFromPolicyRef: Selector<string> = createSelector(
  getPolicyRef, (policyRef: string = "") => {
    const match = policyRef.match(ACCOUNT_ID_REGEX);

    if (!Array.isArray(match) || match.length === 0) {
      return "";
    } else {
      return getStringValue(match.pop());
    }
  });
