import { JsonSchemaDefinition, JsonSchemaMetadataAttributes, JsonSchemaState } from "@data";
import { isEmptyString, isValidInteger } from "@util";
import {
  createQueryParams,
  ETagHeaders,
  ETagLocationHeaders,
  getETagHeaders,
  getETagLocationHeaders,
  makeApiRequest,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments,
} from "./helpers";

const SCHEMA_REGISTRY_API = process.env.REACT_APP_SCHEMA_REGISTRY_API || "";

if (isEmptyString(SCHEMA_REGISTRY_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_SCHEMA_REGISTRY_API");
}

const DEFAULT_LIMIT = 50;

export interface GetSchemasResponse {
  items: JsonSchemaMetadataAttributes[];
  paging?: {
    next?: string | null;
  };
}

export const getSchemas = (authToken: string,
                           next: string = "",
                           nameFilter: string = "",
                           query: string = "",
                           limit: number = DEFAULT_LIMIT): Promise<GetSchemasResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const defaultErrorMessage = "Fetch schemas failed";

    const baseUrl = `${SCHEMA_REGISTRY_API}/data/modeling/v1/schemas`;

    const params = {
      ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, Math.min(DEFAULT_LIMIT, limit)) }),
      next,
    };

    const headers = {
      "Authorization": `Bearer ${authToken}`,
      "Accept": "application/json",
    };

    const filter = {
      ...(isEmptyString(nameFilter) ? {} : { name: nameFilter }),
      ...(isEmptyString(query) ? {} : { query }),
    };

    if (Object.keys(filter).length === 0) {

      return makeJsonApiRequest(
        baseUrl + createQueryParams({...params, nameFilter }),
        { headers, method: "GET" },
        defaultErrorMessage);
    }

    const settings = {
      method: "POST",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filter }),
    };

    const url = `${baseUrl}/search` + createQueryParams(params);

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getSchema = (authToken: string,
                          namespace: string = "",
                          name: string = "",
                          version: string = ""): Promise<JsonSchemaDefinition> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
    ]));

  const makeRequest = () => {

    const url = `${SCHEMA_REGISTRY_API}/data/modeling/v1/schemas/` +
      (isEmptyString(version) ?
        `name/${namespace}:${name}/latest` :
        `identity/${namespace}:${name}:${version}`);

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/schema+json",
      },
    };

    const defaultErrorMessage =
      `Failed to get schema [${namespace}:${name}:${version || "latest"}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getSchemaMetadata = (authToken: string,
                                  namespace: string = "",
                                  name: string = "",
                                  version: string = ""): Promise<JsonSchemaMetadataAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
      ["Version", version],
    ]));

  const makeRequest = () => {

    const url = `${SCHEMA_REGISTRY_API}/data/modeling/v1/schemas/identity/${namespace}:${name}:${version}/metadata` ;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to get metadata for schema [${name}:${version}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const createSchema = (authToken: string,
                             namespace: string,
                             name: string,
                             json: string): Promise<ETagLocationHeaders> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
      ["Schema", json],
    ]));

  const makeRequest = () => {

    const url = `${SCHEMA_REGISTRY_API}/data/modeling/v1/schemas/name/${namespace}:${name}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/schema+json",
        "Content-Type": "application/schema+json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to create schema";

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getETagLocationHeaders);
  };

  return validate().then(makeRequest);
};

export const editSchema = (authToken: string,
                           namespace: string,
                           name: string,
                           version: string,
                           json: string,
                           etag: string): Promise<ETagHeaders> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
      ["Version", version],
      ["Schema", json],
      ["ETag", etag],
    ]));

  const makeRequest = () => {

    const url = `${SCHEMA_REGISTRY_API}/data/modeling/v1/schemas/identity/${namespace}:${name}:${version}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/schema+json",
        "Accept": "application/json",
        "If-Match": etag,
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to edit schema";

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getETagHeaders);
  };

  return validate().then(makeRequest);
};

const updateSchemaState = (authToken: string,
                           namespace: string,
                           name: string,
                           version: string,
                           etag: string,
                           state: string): Promise<ETagHeaders> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
      ["Version", version],
      ["ETag", etag],
      ["State", state],
    ]));

  const makeRequest = () => {

    const url = `${SCHEMA_REGISTRY_API}/data/modeling/v1/schemas/identity` +
      `/${namespace}:${name}:${version}/state/${state}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "If-Match": etag,
      },
    };

    const defaultErrorMessage =
      `Failed to transition schema [${namespace}:${name}:${version}] to state [${state}]`;

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getETagHeaders);
  };

  return validate().then(makeRequest);
};

export const promoteSchema = (authToken: string,
                              namespace: string = "",
                              name: string = "",
                              version: string = "",
                              etag: string = ""): Promise<ETagHeaders> => {

  return updateSchemaState(authToken, namespace, name, version, etag, JsonSchemaState.RELEASED);
};

export const deprecateSchema = (authToken: string,
                                namespace: string = "",
                                name: string = "",
                                version: string = "",
                                etag: string = ""): Promise<ETagHeaders> => {

  return updateSchemaState(authToken, namespace, name, version, etag, JsonSchemaState.DEPRECATED);
};

export const decommissionSchema = (authToken: string,
                                   namespace: string = "",
                                   name: string = "",
                                   version: string = "",
                                   etag: string = ""): Promise<ETagHeaders> => {

  return updateSchemaState(authToken, namespace, name, version, etag, JsonSchemaState.DECOMMISSIONED);
};

export const deleteSchema = (authToken: string,
                             namespace: string = "",
                             name: string = "",
                             version: string = "",
                             etag: string = ""): Promise<ETagHeaders> => {

  return updateSchemaState(authToken, namespace, name, version, etag, JsonSchemaState.DELETED);
};

export const draftNewSchemaVersion = (authToken: string,
                                      namespace: string,
                                      name: string): Promise<ETagLocationHeaders> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
    ]));

  const makeRequest = () => {

    const url =
      `${SCHEMA_REGISTRY_API}/data/modeling/v1/schemas/name/${namespace}:${name}/state/draft`;

    const settings = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/schema+json",
        "Authorization": `Bearer ${authToken}`,
      },
    };

    const defaultErrorMessage = `Failed to draft new version of schema [${namespace}:${name}]`;

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getETagLocationHeaders);
  };

  return validate().then(makeRequest);
};

export const validateSchemaData = (authToken: string,
                                   namespace: string,
                                   name: string,
                                   version: string,
                                   json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Namespace", namespace],
      ["Name", name],
      ["Version", version],
      ["Data", json],
    ]));

  const makeRequest = () => {

    const url = `${SCHEMA_REGISTRY_API}/data/modeling/v1/schemas/identity` +
      `/${namespace}:${name}:${version}/validation`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage =
      `Failed to validate data against schema [${namespace}:${name}:${version}]`;

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(() => Promise.resolve());
  };

  return validate().then(makeRequest);
};
