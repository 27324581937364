import { AppSchema } from "@main/schemas";
import { connect } from "react-redux";
import { Model, Actions, ExpireUserPasswordDialog } from "../components/ExpireUserPasswordDialog";
import { getUser, isExpirePasswordDialogVisible } from "../selectors";
import {
  close,
  expirePasswordRequest,
  expirePasswordSuccess,
  expirePasswordFailed,
} from "../actions";
import { open as openLogoutUserDialog } from "@modules/logoutUser/actions";
import { User } from "@data";

const mapStateToProps = (state: AppSchema): Model => ({
  user: getUser(state),
  expirePasswordDialog: isExpirePasswordDialogVisible(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  close: () => dispatch(close()),
  openLogoutDialogue: (user: User) => dispatch(openLogoutUserDialog(user.getUserId())),
  expirePasswordFailed: () => dispatch(expirePasswordFailed()),
  expirePasswordSuccess: () => dispatch(expirePasswordSuccess()),
  expirePasswordRequest: () => dispatch(expirePasswordRequest()),
});

export default connect<Model, Actions, Model & Actions> (
  mapStateToProps,
  mapDispatchToProps
)(ExpireUserPasswordDialog);
