import React from "react";
import { getStringValue, isEmptyString } from "@util";
import { CreateMultifactorAuthenticatorResponse } from "@network/SecurityServiceRegionalClient";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useSecurityServicesRegionalClient
} from "@hooks";
type SuccessResponse = CreateMultifactorAuthenticatorResponse;

export interface UseCreateServiceMfaProps extends Partial<UseApiRequestProps<SuccessResponse>> {
}

export interface UseCreateServiceMfaModel extends UseApiRequestModel<SuccessResponse> {
  id: string;
  secret: string;
  qrCodeValue: string;
}

export interface UseCreateServiceMfaActions extends UseApiRequestActions<SuccessResponse> {
  createAuthenticator: () => void;
}

type Props = UseCreateServiceMfaProps;
type Model = UseCreateServiceMfaModel;
type Actions = UseCreateServiceMfaActions;
type Result = [Model, Actions];

export const useCreateServiceMfa = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to create multifactor authenticator",
    ...otherProps
  } = props;

  const SecurityServiceClient = useSecurityServicesRegionalClient();

  const makeApiRequest = React.useCallback(() =>
    SecurityServiceClient.createMultifactorAuthenticatorRegionalApi(),
    [SecurityServiceClient]);

  const [{ successResponse, ...baseModel }, { refresh, ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const id = React.useMemo(() => {
    const { id: authenticatorId } = successResponse || {};
    return getStringValue(authenticatorId);
  }, [successResponse]);

  const secret = React.useMemo(() => {
    const { secret: authenticatorSecret } = successResponse || {};
    return getStringValue(authenticatorSecret);
  }, [successResponse]);

  const qrCodeValue = React.useMemo(() => {
    if (isEmptyString(id) || isEmptyString(secret)) {
      return "";
    }
    return `otpauth://totp/${encodeURI(id)}?secret=${secret}`;
  }, [id, secret]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successResponse,
    id,
    secret,
    qrCodeValue,
  }), [
    baseModel,
    successResponse,
    id,
    secret,
    qrCodeValue,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    refresh,
    createAuthenticator: refresh,
  }), [
    baseActions,
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useCreateServiceMfa;
