export enum NamespacesListColumn {
  NONE = "",
  NAME = "Name",
  LIFECYCLE = "Lifecycle",
  TYPE = "Type",
  CREATED_AT = "Created At",
  CREATED_BY = "Created By",
}

export default NamespacesListColumn;
