import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import {
  DeviceEnrollmentWizardSelectors
} from "../../selectors";
import {
  ADD_DEVICE_DIALOG_STEPS,
  ADD_DEVICE_DIALOG_STEPS_LABELS,
} from "../../steps";
import AddDeviceWizard , { Actions, Model } from "../../components/addDeviceWizard/AddDeviceWizard";
import DeviceIdView from "./DeviceIdView";
import DeviceTypeView from "./DeviceTypeView";
import DeviceDataView from "./DeviceDataView";
import CredentialsEditor from "../credentials/CredentialsEditor";
import AddDeviceEditorView from "./AddDeviceEditorView";
import {
  AddDeviceWizardActions,
  DeviceEnrollmentWizardActions,
} from "../../actions";

type Props = Model & Actions;

const AddDeviceWizardContainer = (props: Model & Actions) => {

  return (
    <AddDeviceWizard {...props}>
      <DeviceIdView />
      <DeviceTypeView />
      <DeviceDataView />
      <CredentialsEditor />
      <AddDeviceEditorView />
    </AddDeviceWizard>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  steps: ADD_DEVICE_DIALOG_STEPS,
  stepLabels: ADD_DEVICE_DIALOG_STEPS_LABELS,
  errorMessage: DeviceEnrollmentWizardSelectors.getErrorMessage(state),
  errorTitle: DeviceEnrollmentWizardSelectors.getErrorTitle(state),
  showLoadingIndicator: DeviceEnrollmentWizardSelectors.isLoadingIndicatorVisible(state),
  showProgressIndicator: DeviceEnrollmentWizardSelectors.isLoadingIndicatorVisible(state),
  showSuccessIndicator: DeviceEnrollmentWizardSelectors.isSuccessMessageVisible(state),
  saveButtonLabel: DeviceEnrollmentWizardSelectors.getDeviceWizardSaveButtonLabel(state),
  saveButtonDisabled: !DeviceEnrollmentWizardSelectors.isAddDeviceButtonEnabled(state),
  currentStep: DeviceEnrollmentWizardSelectors.getAddDeviceWizardStep(state),
  completedSteps: DeviceEnrollmentWizardSelectors.getAddDeviceCompletedSteps(state),
  disabledSteps: DeviceEnrollmentWizardSelectors.getAddDeviceDisabledSteps(state),
  previousStepButtonDisabled: DeviceEnrollmentWizardSelectors.isAddDevicePreviousStepButtonDisabled(state),
  nextStepButtonDisabled: DeviceEnrollmentWizardSelectors.isAddDeviceNextStepButtonDisabled(state),
  showSaveButton: DeviceEnrollmentWizardSelectors.isAddDeviceLastStepActive(state),
  lastStepSelected: DeviceEnrollmentWizardSelectors.isAddDeviceLastStepActive(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setCurrentStep: step => dispatch(AddDeviceWizardActions.setAddDeviceCurrentStep(step)),
  previousStep: () => dispatch(AddDeviceWizardActions.showAddDevicePreviousStep()),
  nextStep: () => dispatch(AddDeviceWizardActions.showAddDeviceNextStep()),
  save: () => dispatch(DeviceEnrollmentWizardActions.addDevice()),
  cancel: () => dispatch(DeviceEnrollmentWizardActions.closeAddDeviceDialog()),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(AddDeviceWizardContainer);
