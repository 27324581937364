import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import omit from "lodash/omit";
import { WaitForApiRequestView } from "@components";
import { DSLayoutInfo, DSTypeInfo, DSTypeInfoAttributes } from "@data";
import { hasSpecialChars, isEmptyString, noop } from "@util";
import { useCreateDSLayout, useGetDSLayout, useUpdateDSLayout, } from "@hooks";
import {
  Actions,
  DigitalShadowLayoutWizard,
  DigitalShadowLayoutWizardSteps,
  Model
} from "../components/DigitalShadowLayoutWizard";
import { WizardType } from "../reducers";
import { getLayoutName, isEditMode } from "../selectors";
import JsonReviewView from "../components/JsonReviewView";
import WizardTypeOptionView from "../components/WizardTypeOptionView";
import LayoutInfoView from "../components/LayoutInfoView";
import ChildrenView from "./ChildrenView";

export interface ContainerModel extends Model {
  layoutName?: string;
}

export interface ContainerActions extends Actions {
  showLayoutDetails?: (layoutName: string) => void;
  onEditSuccess?: () => void;
}

type Props = ContainerModel & ContainerActions;

export const WizardContainer = (props: Props) => {

  const {
    layoutName: name = "",
    isEdit,
    showLayoutDetails = noop,
    onEditSuccess = noop,
    ...otherProps
  } = props;

  const [layout, setLayout] = React.useState<DSLayoutInfo>(DSLayoutInfo.EMPTY);
  const [json, setJson] = React.useState(JSON.stringify(layout, null, "  "));
  const [etag, setEtag] = React.useState("");
  const [jsonErrorMessage, setJsonErrorMessage] = React.useState("");
  const [wizardType, setWizardType] = React.useState(WizardType.VISUAL_EDITOR);

  const layoutName = React.useMemo(() => layout.getName(), [layout]);
  const layoutDescription = React.useMemo(() => layout.getDescription(), [layout]);
  const layoutMetadata = React.useMemo(() => layout.getMetadata(), [layout]);
  const layoutChildren = React.useMemo(() =>
    layout.getChildren().map(attrs => new DSTypeInfo(attrs)),
    [layout]);

  const updateLayout = React.useCallback(
    (shadowLayout: DSLayoutInfo = DSLayoutInfo.EMPTY, updateJsonValue: boolean = true) => {
    const updatedLayout = new DSLayoutInfo( {
      ...shadowLayout.toJS()
    });
    setLayout(updatedLayout);
    if (updateJsonValue) {
      setJson(JSON.stringify(updatedLayout, null, "  "));
    }
  }, [
      setLayout,
      setJson,
  ]);

  const updateJson = React.useCallback((value: string) => {
    setJson(value);
    try {
      const updatedLayout = new DSLayoutInfo(JSON.parse(value));
      setJsonErrorMessage("");
      updateLayout(updatedLayout, false);
    } catch (e) {
      setJsonErrorMessage("Unable to parse JSON. Please correct the JSON before proceeding");
    }
  }, [
    setJson,
    setJsonErrorMessage,
    updateLayout,
  ]);

  const editModeJsonBody = React.useMemo(() => {
    const updatedLayout = omit(layout.toJS(), ["name"]);
    return JSON.stringify(updatedLayout , null, "  ");
  }, [layout]);

  const setName = React.useCallback((updatedName: string) =>
  updateLayout(new DSLayoutInfo({
    ...layout.toJS(),
    name: updatedName
  })), [updateLayout, layout]);

  const setDescription = React.useCallback((description: string) =>
    updateLayout(new DSLayoutInfo({
      ...layout.toJS(),
      description
    })), [updateLayout, layout]);

  const setMetadata = React.useCallback((metadata: any = {}) =>
    updateLayout(new DSLayoutInfo({
      ...layout.toJS(),
      metadata
    })), [updateLayout, layout]);

  const updateChildren = React.useCallback((children: DSTypeInfoAttributes[]) =>
    updateLayout(new DSLayoutInfo({
      ...layout.toJS(),
      children
    })), [updateLayout, layout]);

  const getAllSelectedTypeNames = React.useCallback((existingChildren: DSTypeInfoAttributes[]) => {
    const types: any[] = [];
    JSON.stringify(existingChildren, (key, value) => {
      if (key === "name") {
        types.push(value);
      }
      return value;
    });
    return types;
    }, []);

  const existingTypeId = React.useMemo(() => {
    return getAllSelectedTypeNames(layout.getChildren());
  }, [layout, getAllSelectedTypeNames]);

  const addMetadata = React.useCallback((key: string, value: string) => {
    const newValue = {};
    newValue[key] = value;
    const updatedMetadata = !Object.keys(layoutMetadata).some(k => k === key) ?
      Object.assign(layoutMetadata, newValue) : layoutMetadata;
    setMetadata(updatedMetadata);
  }, [layoutMetadata, setMetadata]);

  const removeMetadata = React.useCallback((key: string) => {
    let updatedMetadata = layoutMetadata;
    delete updatedMetadata[key];
    setMetadata(updatedMetadata);
  }, [layoutMetadata, setMetadata]);

  const setVisualEditorType = React.useCallback(() => {
    setWizardType(WizardType.VISUAL_EDITOR);
  }, [setWizardType]);

  const setJsonType = React.useCallback(() => {
    setWizardType(WizardType.JSON);
  }, [setWizardType]);

  const isVisualEditorType = React.useMemo(() =>
    wizardType === WizardType.VISUAL_EDITOR, [wizardType]);

  const isJsonType = React.useMemo(() =>
    wizardType === WizardType.JSON, [wizardType]);

  const isJsonValid = React.useMemo(() =>
  isEmptyString(jsonErrorMessage), [jsonErrorMessage]);

  const wizardTypeOptionView = React.useMemo(() => (
    <WizardTypeOptionView
      wizardType={wizardType}
      showInvalidJsonError={!isJsonValid}
      setVisualEditorType={setVisualEditorType}
      setJsonType={setJsonType}
    />
  ), [
    wizardType,
    isJsonValid,
    setVisualEditorType,
    setJsonType
  ]);

  const layoutInfoView = React.useMemo(() => (
    <LayoutInfoView
      name={layoutName}
      description={layoutDescription}
      editMode={isEdit}
      metadata={layoutMetadata}
      setName={setName}
      setDescription={setDescription}
      addMetadataEntry={addMetadata}
      removeMetadataEntry={removeMetadata}
    />
  ), [
    layoutName,
    layoutDescription,
    isEdit,
    layoutMetadata,
    setName,
    setDescription,
    addMetadata,
    removeMetadata
  ]);

  const jsonViewTitle = React.useMemo(() =>
      isJsonType ? "Enter JSON payload" : "Review JSON payload"
  , [isJsonType]);

  const childrenView = React.useMemo(() => (
    <ChildrenView
      types={layoutChildren}
      layout={layout}
      updateChildren={updateChildren}
      existingTypeName={existingTypeId}
    />
  ), [
    layout,
    layoutChildren,
    updateChildren,
    existingTypeId
  ]);

  const jsonReviewView = React.useMemo(() => (
    <JsonReviewView
      title={jsonViewTitle}
      json={json}
      errorMessage={jsonErrorMessage}
      setJson={updateJson}
    />
  ), [
    jsonViewTitle,
    json,
    jsonErrorMessage,
    updateJson
  ]);

  const steps = React.useMemo(() => {
    return ([] as DigitalShadowLayoutWizardSteps[])
      .concat(DigitalShadowLayoutWizardSteps.CREATE_TYPE)
      .concat(!isVisualEditorType ? [] : DigitalShadowLayoutWizardSteps.LAYOUT_INFO)
      .concat(!isVisualEditorType ? [] : DigitalShadowLayoutWizardSteps.CHILDREN)
      .concat(DigitalShadowLayoutWizardSteps.JSON_REVIEW);
  }, [isVisualEditorType]);

  const isLayoutInfoStepValid = React.useMemo(() => 
    !isEmptyString(layoutName) && !hasSpecialChars(layoutName), [layoutName]);

  const disabledSteps = React.useMemo(() => {
    return steps.filter(step => {
      switch (step) {
        case DigitalShadowLayoutWizardSteps.CREATE_TYPE:
          return false;
        case DigitalShadowLayoutWizardSteps.LAYOUT_INFO:
          return !isJsonValid;
        case DigitalShadowLayoutWizardSteps.CHILDREN:
          return !isLayoutInfoStepValid ||
            !isJsonValid;
        case DigitalShadowLayoutWizardSteps.JSON_REVIEW:
          return isVisualEditorType && !isLayoutInfoStepValid;
        default:
          return false;
      }
    });
  }, [
    isJsonValid,
    isVisualEditorType,
    isLayoutInfoStepValid,
  ]);

  const mapStepToView = React.useCallback((step: DigitalShadowLayoutWizardSteps) => {
    switch (step) {
      case DigitalShadowLayoutWizardSteps.CREATE_TYPE:
        return wizardTypeOptionView;
      case DigitalShadowLayoutWizardSteps.LAYOUT_INFO:
        return layoutInfoView;
      case DigitalShadowLayoutWizardSteps.CHILDREN:
        return childrenView;
      case DigitalShadowLayoutWizardSteps.JSON_REVIEW:
        return jsonReviewView;
      default:
        return null;
    }
  }, [
    wizardTypeOptionView,
    layoutInfoView,
    childrenView,
    jsonReviewView,
  ]);

  const [ detailsModel, detailsActions ] = useGetDSLayout({name, deferRequest: true});

  const {
    dsLayout: fetchedLayout = DSLayoutInfo.EMPTY,
    etag: fetchedEtag,
    showSuccessView : fetchSuccess,
    ...otherDetailsModel
  } = detailsModel;

  const { refresh: getLayout, ...otherDetailsActions } = detailsActions;

  const [ updateModel, updateActions ] = useUpdateDSLayout({
    requestBodyJson: editModeJsonBody,
    layoutName: name,
    requestEtag: etag,
  });

  const [ createModel, createActions ] = useCreateDSLayout({
    requestBody: json,
  });

  const { updateLayout: editLayout, ...otherUpdateActions } = updateActions;

  const { createLayout, ...otherCreateActions } = createActions;

  const otherModel = React.useMemo(() => isEdit ? updateModel : createModel,
    [isEdit, updateModel, createModel]);

  const otherActions = React.useMemo(() => isEdit ? otherUpdateActions : otherCreateActions,
    [isEdit, otherUpdateActions, otherCreateActions]);

  const onCreateSuccess = React.useCallback(() => {
    showLayoutDetails(layoutName);
  }, [layoutName, showLayoutDetails]);

  const onSuccess = React.useCallback(() => {
      isEdit ? onEditSuccess() : onCreateSuccess();
    },
    [isEdit, onEditSuccess, onCreateSuccess]);

  const saveButtonLabel = React.useMemo(() =>
      isEdit ? "Update Layout" : "Create Layout" ,
    [isEdit]);

  const saveButtonDisabled = React.useMemo(() =>
    !isJsonValid || isEmptyString(layoutName),
    [isJsonValid, layoutName]);

  const save = React.useCallback(() => {
      isEdit ? editLayout() : createLayout();
    },
    [isEdit, editLayout, createLayout]);

  React.useEffect(() => {
    if (isEdit) {
      getLayout();
    }
  }, []);

  React.useEffect(() => {
    if (!fetchedLayout.equals(DSLayoutInfo.EMPTY)) {
      updateLayout(fetchedLayout);
      setEtag(fetchedEtag.etag || "");
    }
  }, [fetchSuccess]);

  return (
    <WaitForApiRequestView
      {...otherDetailsModel}
      {...otherDetailsActions}
      className="digitalShadowLayoutWizard"
      loadingMessage="Loading Shadow Layout Wizard"
      errorTitle="Failed to load Shadow Layout"
      retry={getLayout}
    >
      <DigitalShadowLayoutWizard
        {...otherProps}
        {...otherModel}
        {...otherActions}
        saveButtonLabel={saveButtonLabel}
        saveButtonDisabled={saveButtonDisabled}
        steps={steps}
        currentState={layout}
        disabledSteps={disabledSteps}
        mapStepToView={mapStepToView}
        save={save}
        onSuccess={onSuccess}
      />
    </WaitForApiRequestView>

  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  layoutName: getLayoutName(state),
  isEdit: isEditMode(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(WizardContainer);
