import React from "react";
import classnames from "classnames";
import DataAccessRequestSummaryView, {
  DataAccessRequestSummaryViewActions,
  DataAccessRequestSummaryViewModel,
} from "@components/data-access-request-summary-view";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { dataAccessRequestView as styles } from "./styles";

export interface Model extends DataAccessRequestSummaryViewModel {
  className?: string;
  summaryViewClassName?: string;
}

export interface Actions extends DataAccessRequestSummaryViewActions {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataAccessRequestView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    summaryViewClassName,
    children,
    ...otherProps
  } = props;

  return (
    <div className={classnames("dataAccessRequest", className, classes.container)}>
      <h1 className={classnames("dataAccessRequestTitle", classes.subtitle)}>
        Related Data Access Request Information
      </h1>
      <DataAccessRequestSummaryView
        {...otherProps}
        className={classnames("summaryView", summaryViewClassName, classes.summaryView)}
      />
      {children}
    </div>
  );
});

export default DataAccessRequestView;
