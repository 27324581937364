import React from "react";
import classnames from "classnames";
import QualityReport from "@data/QualityReport";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import CodeCoverage from "../CodeCoverage";
import Defects from "./Defects";
import styles from "./styles";

export interface QualitySectionModel {
  className?: string;
  title?: string;
  staticCodeAnalysisDefectsTitle?: string;
  functionalDefectsTitle?: string;
  currentReport?: QualityReport;
  previousReport?: QualityReport;
  codeCoverageReports?: QualityReport[];
}

export interface QualitySectionActions {
}

type Model = QualitySectionModel;
type Actions = QualitySectionActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const QualitySection = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Quality",
    staticCodeAnalysisDefectsTitle = "Bugs (Static Code Analysis)",
    functionalDefectsTitle = "Bugs (Functional Defects)",
    currentReport = QualityReport.EMPTY,
    previousReport = QualityReport.EMPTY,
    codeCoverageReports = [],
    children,
  } = props;

  const showHistoricalTrends = React.useMemo(() =>
      !QualityReport.EMPTY.equals(previousReport),
    [previousReport]);

  return (
    <div className={classnames("qualitySection", className, classes.container)}>
      <Typography
        className={classnames("title", classes.title)}
        variant="h5"
      >
        {title}
      </Typography>
      <div className={classnames("content", classes.content)}>
        <div className={classnames("column", classes.column)}>
          <Defects
            className={classnames("staticCodeAnalysisDefects",
              classes.staticCodeAnalysisDefects,
            )}
            title={staticCodeAnalysisDefectsTitle}
            currentReport={currentReport.getStaticCodeAnalysisDefects()}
            previousReport={previousReport.getStaticCodeAnalysisDefects()}
            showHistoricalTrends={showHistoricalTrends}
          />
          <Defects
            className={classnames("functionalDefects",
              classes.functionalDefects,
            )}
            title={functionalDefectsTitle}
            currentReport={currentReport.getFunctionalDefects()}
            previousReport={previousReport.getFunctionalDefects()}
            showHistoricalTrends={showHistoricalTrends}
            trivialSeverityEnabled={true}
          />
        </div>
        <CodeCoverage
          className={classnames("codeCoverage", classes.column, classes.codeCoverage)}
          reports={codeCoverageReports}
        />
      </div>
      {children}
    </div>
  );
});

export default QualitySection;
