import { createSelector } from "reselect";
import { AppSchema } from "../../../main/schemas";
import {
  JsonSchemaDefinition,
  JsonSchemaMetadata,
  JsonSchemaMetadataAttributes,
} from "../../../../data";

const getOriginalMetaDataAttributes = (state: AppSchema): JsonSchemaMetadataAttributes => {
  return state.schemaWizard.editMode.originalMetaData;
};

export const getOriginalSchema = (state: AppSchema): JsonSchemaDefinition => {
  return state.schemaWizard.editMode.originalSchema;
};

export const getOriginalMetaData: (state: AppSchema) => JsonSchemaMetadata = createSelector(
  getOriginalMetaDataAttributes, (attrs: JsonSchemaMetadataAttributes) =>
    new JsonSchemaMetadata(attrs));

export const getOriginalSchemaVersion: (state: AppSchema) => number = createSelector(
  getOriginalMetaData, (metadata: JsonSchemaMetadata) =>
    metadata.version);
