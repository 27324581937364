import { Record } from "immutable";
import { getStringValue } from "@util";

export interface KeyValuePairAttributes {
  key: string;
  value: string;
}

export class KeyValuePair extends Record({
  key: "",
  value: "",
}) implements KeyValuePairAttributes {

  public static EMPTY = new KeyValuePair();

  public readonly key: string;
  public readonly value: string;

  public static fromObjectToArray(obj: any): KeyValuePair[] {
    return Object.keys(obj).map((key: string) =>
    new KeyValuePair({key, value: obj[key]}));
  }

  public getKey(): string {
    return getStringValue(this.key);
  }

  public getValue(): string {
    return getStringValue(this.value);
  }
}

export default KeyValuePair;
