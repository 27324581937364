import React from "react";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useWorkloadManagerClient } from "@hooks";

type SuccessResponse = void;

export interface UseAddActionLabelsProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  etag: string;
  actionLabels: string[];
}

export interface UseAddActionLabelsModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseAddActionLabelsActions {
  updateActionLabels: () => void;
  reset: () => void;
}

type Props = UseAddActionLabelsProps;
type Model = UseAddActionLabelsModel;
type Actions = UseAddActionLabelsActions;
type Result = [Model, Actions];

export const useAddActionLabels = (props: Props): Result => {

  const {
    name = "",
    version = 1,
    etag,
    actionLabels = [],
    defaultErrorMessage = "Failed to update action labels",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.addActionLabels(name, version, etag, actionLabels),
    [WorkloadManagerClient, name, version, etag, actionLabels]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: updateActionLabels, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Edit Successful" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    updateActionLabels,
    reset
  }), [
    updateActionLabels,
    reset
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useAddActionLabels;
