import Color from "@Color";
import { createStyles, Theme } from "@material-ui/core/styles";

export const tag = (theme: Theme) =>
  createStyles({
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    tagsLabel: {
      marginTop: 25,
    },
    tagEditor: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "flex-start",
    },
    tagInput: {
      fontFamily: "Roboto, sans-serif",
      width: 250,
    },
    fullWidth: {
      width: "calc(100% - 60px)"
    },
    tags: {
      marginTop: 15,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    tagsEmptyView: {
      color: Color.GREY3,
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      lineHeight: "14px",
      fontWeight: 300,
      fontStyle: "italic",
      margin: theme.spacing(),
    },
    tag: {
      height: 28,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
      margin: theme.spacing(),
      padding: theme.spacing(),
    },
    tagIcon: {
      color: Color.WHITE,
      width: 14,
      height: 14,
      marginLeft: 2,
      marginRight: 2,
      "&:first-child": {
        marginRight: 0,
      },
    },
    addTagIconButton: {
      marginTop: 15,
      marginLeft: 16,
      width: 32,
      height: 32,
      borderRadius: 32,
      padding: 0,
      backgroundColor: Color.WHITE,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.54)",
      },
    },
    addTagIcon: {
      width: 28,
      height: 28,
    },
  });

export default tag;
