import React from "react";
import { connect } from "react-redux";
import { SecurityGroup } from "@data";
import { AppSchema } from "@main/schemas";
import { useUserSecurityGroups } from "@components";
import { getPluralString, isEmptyString, noop } from "@util";
import { getUserId, isCurrentUserViewActive } from "../selectors";
import { setRemoveUserButtonEnabled } from "../actions";
import SecurityGroups from "@components/security-groups";

interface Model {
  currentUserViewActive?: boolean;
  hidden?: boolean;
  userId?: string;
}

interface Actions {
  showSecurityGroupDetails?: (group: SecurityGroup) => void;
  setRemoveUserButtonEnabled?: (enabled: boolean) => void;
}

type Props = Model & Actions;

const SecurityGroupsView = (props: Props) => {

  const {
    hidden,
    userId,
    showSecurityGroupDetails,
    currentUserViewActive,
    setRemoveUserButtonEnabled: enableRemoveUserButton = noop,
  } = props;

  const [ { securityGroups, ...model }, actions ] = useUserSecurityGroups(userId);

  const {
    nameFilter,
    totalNumSecurityGroups,
    totalNumVisibleSecurityGroups,
    errorMessage,
    errorStatus,
  } = model;

  const visibleErrorMessage = React.useMemo(() =>
    errorStatus === 404 ? "" : errorMessage, [errorStatus, errorMessage]);

  const title = React.useMemo(() => {

    if (!isEmptyString(nameFilter)) {
      return getPluralString(totalNumVisibleSecurityGroups, {
        other: "Groups found",
        one: "Group found",
      });
    } else {
      return (currentUserViewActive ? "You belong to " : "This user belongs to ") +
        getPluralString(totalNumSecurityGroups, {
          other: "groups",
          one: "group",
        });
    }
  }, [nameFilter, currentUserViewActive, totalNumSecurityGroups, totalNumVisibleSecurityGroups]);

  const loadingTitle = React.useMemo(() =>
      currentUserViewActive
        ? "Loading groups you belong to..."
        : "Loading groups this user belongs to...",
    [currentUserViewActive]);

  const noResultsLabel = React.useMemo(() =>
      currentUserViewActive
        ? "You do not belong to any groups"
        : "User does not belong to any groups",
    [currentUserViewActive]);

  const errorTitle = React.useMemo(() =>
      currentUserViewActive
        ? "Failed to load groups you belong to"
        : "Failed to load groups this user belongs to",
    [currentUserViewActive]);

  React.useEffect(() => {
    enableRemoveUserButton(totalNumSecurityGroups > 0);
  }, [enableRemoveUserButton, totalNumSecurityGroups]);

  if (hidden) {
    return null;
  }

  return (
    <SecurityGroups
      {...model}
      {...actions}
      className={"userSecurityGroups"}
      title={title}
      items={securityGroups}
      onClickItem={showSecurityGroupDetails}
      loadingTitle={loadingTitle}
      noResultsLabel={noResultsLabel}
      errorTitle={errorTitle}
      errorMessage={visibleErrorMessage}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  currentUserViewActive: isCurrentUserViewActive(state),
  userId: getUserId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setRemoveUserButtonEnabled: (enabled: boolean) => dispatch(setRemoveUserButtonEnabled(enabled)),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(SecurityGroupsView);
