import createStyles from "@material-ui/core/styles/createStyles";
import FederationProviderIcon from "@material-ui/icons/Domain";
import Color from "@Color";

export const primaryColor = Color.MODULES;

export const PrimaryIcon = FederationProviderIcon;

export const styles = () =>
  createStyles({
    container: {},
    createButton: {
    },
  });

export default styles;
