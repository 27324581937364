export interface BoundingClientRect {
  height: number;
  width: number;
  x: number;
  y: number;
}

export const getBoundingClientRect = (element: Element): BoundingClientRect =>
  element.getBoundingClientRect();

export const getInnerText = (element: HTMLElement): string => element.innerText;

export const scrollToTop = () => window.scrollTo(0, 0);

export const onMouseMove = (fn: any) => {
  window.addEventListener("mousemove", fn);
  return () => {
    window.removeEventListener("mousemove", fn);
  };
};

export const onLocalStorageUpdated = (callback: (event: StorageEvent) => void) => {
  const listener = (event: StorageEvent) => {
    if (event && event.storageArea === localStorage) {
      callback(event);
    }
  };
  window.addEventListener("storage", listener);
  return () => {
    window.removeEventListener("storage", listener);
  };
};

export type BroadcastChannelClient = {
  postMessage: (message: any) => void;
  unsubscribe: () => void;
};

export type BroadcastChannelCallback = (event: MessageEvent, client: BroadcastChannelClient) => void;

export type OpenBroadcastChannelApi =
  (channelName: string, callback?: BroadcastChannelCallback) => BroadcastChannelClient;

export const openBroadcastChannel: OpenBroadcastChannelApi = (channelName, callback = () => { /* no-op */ }) => {

  const bc = new BroadcastChannel(channelName);

  let listener: (broadcastEvent: MessageEvent) => void;

  const client: BroadcastChannelClient = {
    postMessage: (message: any) => {
      bc.postMessage(message);
    },
    unsubscribe: () => {
      bc.removeEventListener("message", listener);
      bc.close();
    },
  };

  listener = (broadcastEvent: MessageEvent) => {
    callback(broadcastEvent, client);
  };

  bc.addEventListener("message", listener);

  return client;
};

export const getSelection: () => Selection | null = () =>
  window.getSelection();

export const createObjectURL = (blob: Blob): string =>
  window.URL.createObjectURL(blob);
