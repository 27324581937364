import { isEmptyString } from "@util";
import { DeviceProvisioningDataAttributes } from "@data";
import {
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments
} from "@network/helpers";

const REGIONAL_API = process.env.REACT_APP_REGIONAL_API || "";

if (isEmptyString(REGIONAL_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_REGIONAL_API");
}

export const getDeviceBootstrapConfiguration = (authToken: string,
                                                authorization: string,
                                                deviceId: string): Promise<DeviceProvisioningDataAttributes> => {

  const validate = () => withAuthToken(authorization)
    .then(() => withRequiredArguments([
      ["Device ID", deviceId]
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/device-management/bootstrap/v1/devices/${deviceId}/configuration`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `${authorization}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch device bootstrap configuration failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
