import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      "& .loadingView": {
        marginTop: 15,
        color: Color.SIGNIFY_GREEN,
      },
    },
    downloadErrorView: {
      margin: "8px 0",
      "& label.title, & label.message": {
        marginTop: 10,
      },
      "& button.tryAgainButton": {
        color: Color.DARK_RED,
        height: "auto",
        marginTop: 8,
      },
    },
    title: {
      marginTop: 8,
      color: Color.TEXT,
      fontWeight: 300,
    },
    alert: {
      marginTop: 15,
    },
    alertTitle: {
      fontSize: 14,
      marginTop: 2,
      fontWeight: 600,
    },
    alertDescription: {
      fontSize: 14,
      lineHeight: "21px",
      marginTop: 10,
      marginBottom: 0,
      "&:first-child": {
        marginTop: 0,
      },
    },
    dataGovernanceLink: {
      marginLeft: 4,
      marginRight: 4,
      textDecoration: "underline",
    },
    termsConditionsFile: {
      marginTop: 15,
      minHeight: 300,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      padding: 16,
      border: `1px dashed ${Color.TEXT}`,
      borderRadius: 6,
      textAlign: "center",
      "&.empty": {
        cursor: "pointer",
        maxHeight: 500,
        "&:hover": {
          backgroundColor: Color.LIGHT_GREY4,
        },
      },
    },
    removeButton: {
      marginLeft: "auto",
      marginRight: 15,
    },
    removeIcon: {
    },
    pdfViewer: {
      alignSelf: "stretch",
    },
    dragging: {
      backgroundColor: Color.LIGHT_GREY2,
    },
    errorView: {
      marginTop: 0,
      padding: "8px 50px 12px",
      cursor: "pointer",
      "& label": {
        cursor: "pointer",
      },
    },
    emptyView: {
      textAlign: "center",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    emptyViewTitle: {
      marginTop: 10,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
      cursor: "pointer",
    },
  });

export default styles;
