import React from "react";
import classnames from "classnames";
import { noop } from "@util";
import { ActionMenuItem, ActionsMenu, ActionsMenuProps } from "../actions-menu";

export enum SecurityGroupAction {
  ATTACH_POLICY = "attach_policy",
  DETACH_POLICY = "detach_policy",
  ADD_USER = "add_user_to_group",
  REMOVE_USER = "remove_user_from_group",
  ADD_SERVICE = "add_service_to_group",
  REMOVE_SERVICE = "remove_service_from_group",
  DELETE_SECURITY_GROUP = "delete_security_group",
}

export const ATTACH_POLICY_ACTION: ActionMenuItem = Object.freeze({
  id: SecurityGroupAction.ATTACH_POLICY,
  name: "Attach Policy",
  disabled: false,
});

export const DETACH_POLICY_ACTION: ActionMenuItem = Object.freeze({
  id: SecurityGroupAction.DETACH_POLICY,
  name: "Detach Policy",
  disabled: false,
});

export const ADD_USER_ACTION: ActionMenuItem = Object.freeze({
  id: SecurityGroupAction.ADD_USER,
  name: "Add User to Group",
  disabled: false,
});

export const REMOVE_USER_ACTION: ActionMenuItem = Object.freeze({
  id: SecurityGroupAction.REMOVE_USER,
  name: "Remove User from Group",
  disabled: false,
});

export const ADD_SERVICE_ACTION: ActionMenuItem = Object.freeze({
  id: SecurityGroupAction.ADD_SERVICE,
  name: "Add Service to Group",
  disabled: false,
});

export const REMOVE_SERVICE_ACTION: ActionMenuItem = Object.freeze({
  id: SecurityGroupAction.REMOVE_SERVICE,
  name: "Remove Service from Group",
  disabled: false,
});

// TODO: Remove hidden condition once IOTSEC-2224 IS FIXED
export const DELETE_SECURITY_GROUP_ACTION: ActionMenuItem = Object.freeze({
  id: SecurityGroupAction.DELETE_SECURITY_GROUP,
  name: "Delete Security Group",
  disabled: false,
});

export const DEFAULT_SECURITY_GROUP_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  ATTACH_POLICY_ACTION,
  DETACH_POLICY_ACTION,
  ADD_USER_ACTION,
  REMOVE_USER_ACTION,
  ADD_SERVICE_ACTION,
  REMOVE_SERVICE_ACTION,
  DELETE_SECURITY_GROUP_ACTION,
]) as ActionMenuItem[];

export interface SecurityGroupActionsMenuProps extends ActionsMenuProps {
  attachPolicy?: () => void;
  detachPolicy?: () => void;
  addUser?: () => void;
  removeUser?: () => void;
  addService?: () => void;
  removeService?: () => void;
  deleteSecurityGroup?: () => void;
}

export const withSecurityGroupActions = (Component: React.ComponentType<SecurityGroupActionsMenuProps>) =>
  (props: SecurityGroupActionsMenuProps) => {

    const {
      actions = DEFAULT_SECURITY_GROUP_ACTION_MENU_ITEMS,
      onClickAction = noop,
      attachPolicy = noop,
      detachPolicy = noop,
      addUser = noop,
      removeUser = noop,
      addService = noop,
      removeService = noop,
      deleteSecurityGroup = noop,
    } = props;

    return (
      <Component
        {...props}
        actions={actions}
        onClickAction={(action: ActionMenuItem) => {
          if (action.id === SecurityGroupAction.ATTACH_POLICY) {
            attachPolicy();
          } else if (action.id === SecurityGroupAction.DETACH_POLICY) {
            detachPolicy();
          } else if (action.id === SecurityGroupAction.ADD_USER) {
            addUser();
          } else if (action.id === SecurityGroupAction.REMOVE_USER) {
            removeUser();
          } else if (action.id === SecurityGroupAction.ADD_SERVICE) {
            addService();
          } else if (action.id === SecurityGroupAction.REMOVE_SERVICE) {
            removeService();
          } else if (action.id === SecurityGroupAction.DELETE_SECURITY_GROUP) {
            deleteSecurityGroup();
          } else {
            onClickAction(action);
          }
        }}
      />
    );
  };

export const SecurityGroupActionsMenu = withSecurityGroupActions((props: SecurityGroupActionsMenuProps) => {

  const { className, buttonLabel = "Security Group Actions" } = props;

  return (
    <ActionsMenu
      {...props}
      className={classnames("securityGroupActionsMenu", className)}
      buttonLabel={buttonLabel}
    />
  );
});

export default SecurityGroupActionsMenu;
