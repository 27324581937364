import { Record } from "immutable";
import { getStringValue } from "@util";

export interface DashboardAttributes {
  id: number;
  name: string;
  published: boolean;
  publishable?: boolean;
  dataSources?: string[];
  readers?: string[];
}

export class Dashboard extends Record({
  id: 0,
  name: "",
  published: false,
  publishable: false,
  dataSources: [],
  readers: []
}) implements DashboardAttributes {

  public static EMPTY: Dashboard = new Dashboard();

  public readonly id: number;
  public readonly name: string;
  public readonly published: boolean;
  public readonly publishable: boolean;
  public readonly dataSources: string[];
  public readonly readers: string[];

  public getDashboardId(): number {
    return this.id;
  }

  public getDashboardName(): string {
    return getStringValue(this.name);
  }

  public isPublished(): boolean {
    return this.published;
  }

  public getReaders(): string[] {
    return this.readers ? this.readers : [];
  }

  public getDataSources(): string[] {
    return this.dataSources ? this.dataSources : [];
  }
}

export default Dashboard;
