import React from "react";
import { useHistory } from "react-router-dom";
import { getPathToGroup } from "@modules/securityGroupDetails/helpers";
import CreateSecurityGroup from "./containers/CreateSecurityGroup";

export function CreateSecurityGroupView() {

  const history = useHistory();

  const onSuccess = React.useCallback(group => {
    history.replace(getPathToGroup(group.getName()));
  }, [history]);

  return (
    <CreateSecurityGroup
      onSuccess={onSuccess}
    />
  );
}

export default CreateSecurityGroupView;
