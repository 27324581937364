import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import ApplicationWizard, {
  Actions,
  ApplicationWizardSteps,
  Model
} from "../components/ApplicationWizard";
import {
  Application,
  ApplicationAuthentication,
  ApplicationAuthenticationProtocol,
  ApplicationMetadataAttributes,
  ApplicationType
} from "@data";
import { SecretDialog, WaitForApiRequestView } from "@components";
import { noop, cleanEmptyStringsAndArrays, isEmptyString, hasSpecialChars } from "@util";
import omit from "lodash/omit";
import { getApplicationId, isEditMode } from "../selectors";
import ApplicationInfoView, { ApplicationScopes } from "../components/ApplicationInfoView";
import OpenIdConnectView from "../components/OpenIdConnectView";
import ApplicationSecurityView from "../components/ApplicationSecurityView";
import JsonReviewView from "../components/JsonReviewView";
import ReviewView from "../components/ReviewView";
import {
  useCreateApplicationRegional,
  useEditApplicationRegional,
  useGetApplicationRegional,
} from "@hooks";

export interface ContainerModel extends Model {
  applicationId?: string;
}

export interface ContainerActions extends Actions {
  showApplicationDetails?: (application: Application) => void;
  onEditSuccess?: () => void;
}

type Props = ContainerModel & ContainerActions;

export const ApplicationWizardContainer = (props: Props) => {

  const {
    applicationId = "",
    isEdit,
    showApplicationDetails = noop,
    onEditSuccess = noop,
    ...otherProps
  } = props;

  const [application, setApplication] = React.useState<Application>(Application.EMPTY);
  const [json, setJson] = React.useState(JSON.stringify(application, null, "  "));
  const [uri, setUri] = React.useState("");
  const [oldUri, setOldUri] = React.useState("");
  const [editUriDialog, setEditUriDialog] = React.useState(false);
  const [deleteUriDialog, setDeleteUriDialog] = React.useState(false);
  const [isEditRedirectUri, setIsEditRedirectUri] = React.useState(false);
  const [jsonErrorMessage, setJsonErrorMessage] = React.useState("");
  const [openSecretsDialog, setOpenSecretsDialog] = React.useState(false);
  const [openIdApplication, setOpenIdApplication] = React.useState(false);
  const [secret, setSecret] = React.useState("");
  const [resultAppId, setResultAppId] = React.useState("");

  const applicationName = React.useMemo(() => application.getName(), [application]);
  const applicationDescription = React.useMemo(() => application.getDescription(), [application]);
  const applicationType = React.useMemo(() => application.getApplicationType(), [application]);
  const applicationScope = React.useMemo(() => application.getScopes(), [application]);
  const applicationMetadata = React.useMemo(() => application.getApplicationMetadata(), [application]);
  const applicationRedirectUris = React.useMemo(() => application.getRedirectUriAsStringArray(), [application]);
  const applicationAuthentication = React.useMemo(() => application.getApplicationAuthentication(), [application]);
  const applicationProtocol = React.useMemo(() => application.getApplicationProtocol(), [application]);

  const addEditRedirectUriDialog = React.useMemo(() =>
      isEditRedirectUri ? "Edit Redirect Uri" : "Add Redirect Uri",
    [isEditRedirectUri]);

  const updateApplication = React.useCallback(
    (app: Application = Application.EMPTY, updateJsonValue: boolean = true) => {
    const applicationSecurity = app.isApplicationAuthenticated() ?
     omit(app.security, ["data"]) : omit(app.security, ["data", "protocol"]);
    const updatedApp = new Application({
      ...app.toJS(),
      security: {
        ...applicationSecurity,
      }
    });
    // on edit mode remove Id, State, Created and Security from JSON body as these cannot be edited
    const editModeUpdatedApp = isEdit ? omit(updatedApp.toJS(), ["id", "state", "created", "security", "type"])
      : updatedApp.toJS();
    const updatedApplication = cleanEmptyStringsAndArrays(editModeUpdatedApp);
    setApplication(updatedApp);
    if (updateJsonValue) {
      setJson(JSON.stringify(updatedApplication, null, "  "));
    }
    }, [setJson, setApplication, isEdit, application]);

  const updateJson = React.useCallback((value: string) => {
    setJson(value);
    try {
      const updatedApplication = new Application(JSON.parse(value));
      setJsonErrorMessage("");
      updateApplication(updatedApplication, false);
    } catch (e) {
      setJsonErrorMessage("Unable to parse JSON. Please correct the JSON before proceeding");
    }
  }, [
    setJson,
    updateApplication,
    setJsonErrorMessage
  ]);

  const setName = React.useCallback((name: string) =>
    updateApplication(new Application({
      ...application.toJS(),
      name
    })), [updateApplication, application]);

  const setDescription = React.useCallback((description: string) =>
    updateApplication(new Application({
      ...application.toJS(),
      description
    })), [updateApplication, application]);

  const setType = React.useCallback((type: ApplicationType) =>
    updateApplication(new Application({
      ...application.toJS(),
      type
  })), [updateApplication, application]);

  const setScope = React.useCallback((scopes: string[]) =>
    updateApplication(new Application({
      ...application.toJS(),
      scopes
    })), [updateApplication, application]);

  const setRedirectUris = React.useCallback((redirectUris: string[]) =>
    updateApplication(new Application({
      ...application.toJS(),
      redirectUris
    })), [updateApplication, application]);

  const setAuthentication = React.useCallback((authentication: ApplicationAuthentication) =>
    updateApplication(new Application({
      ...application.toJS(),
      security: {
        protocol: applicationProtocol,
        authentication
      }
    })), [updateApplication, application, applicationProtocol]);

  const setProtocol = React.useCallback((protocol: ApplicationAuthenticationProtocol) =>
    updateApplication(new Application({
      ...application.toJS(),
      security: {
        protocol,
        authentication: applicationAuthentication
      }
    })), [updateApplication, application, applicationAuthentication]);

  const setMetadata = React.useCallback((metadata: ApplicationMetadataAttributes = {}) =>
    updateApplication(new Application({
      ...application.toJS(),
      metadata
    })), [updateApplication, application]);

  const addMetadata = React.useCallback((key: string, value: string) => {
    const newValue = {};
    newValue[key] = value;
    const updatedMetadata = !Object.keys(applicationMetadata).some(k => k === key) ?
      Object.assign(applicationMetadata, newValue) : applicationMetadata;
    setMetadata(updatedMetadata);
  }, [applicationMetadata, setMetadata]);

  const removeMetadata = React.useCallback((key: string) => {
    let updatedMetadata = applicationMetadata;
    delete updatedMetadata[key];
    setMetadata(updatedMetadata);
  }, [applicationMetadata, setMetadata]);

  const openAddUriDialog = React.useCallback(() =>
  setEditUriDialog(true), [setEditUriDialog]);

  const openEditUriDialog = React.useCallback((value: string) => {
    setIsEditRedirectUri(true);
    setEditUriDialog(true);
    setUri(value);
    setOldUri(value);
  }, [setEditUriDialog, setUri, setIsEditRedirectUri, setOldUri]);

  const openRemoveUriDialog = React.useCallback((value: string) => {
    setDeleteUriDialog(true);
    setUri(value);
  }, [setDeleteUriDialog, setUri]);

  const addRedirectUri = React.useCallback((value: string) => {
    const updatedList = applicationRedirectUris.indexOf(value) === -1 ?
      applicationRedirectUris.concat(value) : applicationRedirectUris;
    setRedirectUris(updatedList);
  }, [applicationRedirectUris, setRedirectUris]);

  const closeDeleteDialog = React.useCallback(() => {
    setDeleteUriDialog(false);
    setUri("");
  }, [setDeleteUriDialog, setUri]);

  const removeRedirectUri = React.useCallback((value: string) => {
    const updatedList = applicationRedirectUris.filter(u => value !== u);
    setRedirectUris(updatedList);
  }, [applicationRedirectUris, setRedirectUris]);

  const editRedirectUri = React.useCallback((value: string) => {
    const removeOldValueList = applicationRedirectUris.filter(u => oldUri !== u);
    const updatedList = removeOldValueList.indexOf(value) === -1 ?
      removeOldValueList.concat(value) : removeOldValueList;
    setRedirectUris(updatedList);
  }, [setRedirectUris, applicationRedirectUris, oldUri]);

  const closeAddEditDialog = React.useCallback(() => {
    setEditUriDialog(false);
    setIsEditRedirectUri(false);
    setUri("");
    setOldUri("");
  }, [setEditUriDialog, setIsEditRedirectUri, setUri, setOldUri]);

  const confirmAddEdit = React.useCallback((value: string) => {
    if (isEditRedirectUri) {
      editRedirectUri(value);
    } else {
      addRedirectUri(value);
    }
    closeAddEditDialog();
  }, [isEditRedirectUri, editRedirectUri, addRedirectUri, closeAddEditDialog]);

  const confirmDelete = React.useCallback((value: string) => {
    removeRedirectUri(value);
    closeDeleteDialog();
  }, [removeRedirectUri, closeDeleteDialog]);

  const updateOpenIdApplicationValue = React.useCallback((value: boolean) => {
    if (value) {
      setScope([ApplicationScopes.OPENID]);
    } else {
      setScope([]);
    }
    setOpenIdApplication(value);
  }, [setOpenIdApplication, setScope]);

  const applicationInfoView = React.useMemo(() => (
    <ApplicationInfoView
      name={applicationName}
      description={applicationDescription}
      openIdConnectApp={openIdApplication}
      type={applicationType}
      metadata={applicationMetadata}
      addMetadataEntry={addMetadata}
      removeMetadataEntry={removeMetadata}
      editMode={isEdit}
      setName={setName}
      setDescription={setDescription}
      setType={setType}
      setOpenIdConnectApp={updateOpenIdApplicationValue}
    />
  ), [
    applicationName,
    applicationDescription,
    applicationType,
    applicationMetadata,
    addMetadata,
    removeMetadata,
    openIdApplication,
    setOpenIdApplication,
    isEdit,
    setName,
    setDescription,
    setType,
  ]);

  const openIdConnectView = React.useMemo(() => (
    <OpenIdConnectView
      uris={applicationRedirectUris}
      uri={uri}
      scope={applicationScope}
      setScope={setScope}
      editAddTitle={addEditRedirectUriDialog}
      editDialogOpen={editUriDialog}
      deleteDialogOpen={deleteUriDialog}
      addUri={openAddUriDialog}
      setUri={setUri}
      editRedirectUri={openEditUriDialog}
      removeRedirectUri={openRemoveUriDialog}
      confirmAddEdit={confirmAddEdit}
      confirmRemove={confirmDelete}
      cancelEdit={closeAddEditDialog}
      cancelRemove={closeDeleteDialog}
    />
  ), [
    applicationRedirectUris,
    uri,
    applicationScope,
    addEditRedirectUriDialog,
    editUriDialog,
    deleteUriDialog,
    openAddUriDialog,
    setUri,
    openEditUriDialog,
    openRemoveUriDialog,
    confirmAddEdit,
    confirmDelete,
    closeAddEditDialog,
    closeDeleteDialog,
    setScope,
  ]);

  const applicationSecurityView = React.useMemo(() => (
    <ApplicationSecurityView
      authentication={applicationAuthentication}
      protocol={applicationProtocol}
      setAuthentication={setAuthentication}
      setProtocol={setProtocol}
    />
  ), [
    applicationAuthentication,
    applicationProtocol,
    setAuthentication,
    setProtocol
  ]);

  const jsonReviewView = React.useMemo(() => (
    <JsonReviewView
      json={json}
      setJson={updateJson}
      errorMessage={jsonErrorMessage}
    />
  ), [json, updateJson, jsonErrorMessage]);

  const reviewView = React.useMemo(() => (
    <ReviewView
      name={applicationName}
      description={applicationDescription}
      type={applicationType}
      scope={applicationScope.toString()}
      redirectUris={applicationRedirectUris.toString()}
      authentication={applicationAuthentication}
      protocol={application.isApplicationAuthenticated() ? applicationProtocol : ""}
    />
  ), [
    applicationName,
    applicationDescription,
    applicationType,
    applicationScope,
    applicationRedirectUris,
    applicationAuthentication,
    applicationProtocol
  ]);

  const steps = React.useMemo(() => {
    return ([] as ApplicationWizardSteps[])
      .concat(ApplicationWizardSteps.APPLICATION_INFO)
      .concat(!openIdApplication ? [] : ApplicationWizardSteps.OPEN_ID_CONNECT)
      .concat(isEdit ? [] : ApplicationWizardSteps.SECURITY)
      .concat(ApplicationWizardSteps.JSON)
      .concat(ApplicationWizardSteps.REVIEW);
  }, [isEdit, openIdApplication]);

  const isApplicationInfoStepValid = React.useMemo(() =>
    !isEmptyString(applicationName) && !hasSpecialChars(applicationName), [applicationName]);

  const isJsonValid = React.useMemo(() => isEmptyString(jsonErrorMessage), [jsonErrorMessage]);

  const isOpenIdConnectStepValid = React.useMemo(() => openIdApplication ? applicationRedirectUris.length > 0 : true,
    [openIdApplication, applicationRedirectUris]);

  const disabledSteps = React.useMemo(() => {
    return steps.filter(step => {
      switch (step) {
        case ApplicationWizardSteps.APPLICATION_INFO:
          return !isJsonValid;
        case ApplicationWizardSteps.OPEN_ID_CONNECT:
          return !isApplicationInfoStepValid
            || !isJsonValid;
        case ApplicationWizardSteps.SECURITY:
          return !isApplicationInfoStepValid
            || !isOpenIdConnectStepValid
            || !isJsonValid;
        case ApplicationWizardSteps.JSON:
          return !isApplicationInfoStepValid
            || !isOpenIdConnectStepValid;
        case ApplicationWizardSteps.REVIEW:
          return !isApplicationInfoStepValid
            || !isOpenIdConnectStepValid
            || !isJsonValid;
        default:
          return false;
      }
    });
  }, [
    isApplicationInfoStepValid,
    isOpenIdConnectStepValid,
    isJsonValid
  ]);

  const mapStepToView = React.useCallback((step: ApplicationWizardSteps) => {
    switch (step) {
      case ApplicationWizardSteps.APPLICATION_INFO:
        return applicationInfoView;
      case ApplicationWizardSteps.OPEN_ID_CONNECT:
        return openIdConnectView;
      case ApplicationWizardSteps.SECURITY:
        return applicationSecurityView;
      case ApplicationWizardSteps.JSON:
        return jsonReviewView;
      case ApplicationWizardSteps.REVIEW:
        return reviewView;
      default:
        return null;
    }
  }, [
    applicationInfoView,
    openIdConnectView,
    applicationSecurityView,
    jsonReviewView,
    reviewView
  ]);

  const [ detailsModel, detailsActions ] = useGetApplicationRegional({applicationId, deferRequest: true});

  const { application: fetchedApplication = Application.EMPTY, ...otherDetailsModel } = detailsModel;

  const { getApplication, ...otherDetailsActions } = detailsActions;

  const [createModel, createActions] = useCreateApplicationRegional({
    json
  });

  const [editModel, editActions] = useEditApplicationRegional({
    applicationId,
    json
  });

  const { application: resultApplication, ...otherCreateModel } = createModel;
  const  { createApplication, ...otherCreateActions } = createActions;

  const  { editApplication, ...otherEditActions } = editActions;

  const otherModel = React.useMemo(() => isEdit ? editModel : otherCreateModel,
    [isEdit, editModel, otherCreateModel]);

  const otherActions = React.useMemo(() => isEdit ? otherEditActions : otherCreateActions,
    [isEdit, otherEditActions, otherCreateActions]);

  const onCreateSuccess = React.useCallback(() => {
    setSecret(resultApplication.getPrimarySecret());
    setResultAppId(resultApplication.getId());
    if (isEmptyString(resultApplication.getPrimarySecret())) {
      showApplicationDetails(resultApplication);
    } else {
      setOpenSecretsDialog(true);
    }
  }, [setSecret, setOpenSecretsDialog, resultApplication, setResultAppId, showApplicationDetails, secret]);

  const onSuccess = React.useCallback(() => {
      isEdit ? onEditSuccess() : onCreateSuccess();
    },
    [isEdit, onEditSuccess, onCreateSuccess]);

  const closeSecretDialog = React.useCallback(() => {
    setOpenSecretsDialog(false);
    showApplicationDetails(resultApplication);
  }, [setOpenSecretsDialog, showApplicationDetails, resultApplication ]);

  const save = React.useCallback(() => {
      isEdit ? editApplication() : createApplication();
    },
    [isEdit, editApplication, createApplication]);

  const saveButtonLabel = React.useMemo(() =>
      isEdit ? "Update Application" : "Create Application" ,
    [isEdit]);

  React.useEffect(() => {
    if (isEdit) {
      getApplication();
    }
  }, []);

  React.useEffect(() => {
    if (!fetchedApplication.equals(Application.EMPTY)) {
      updateApplication(fetchedApplication);
      fetchedApplication.getScopes().some(scope => scope === ApplicationScopes.OPENID) ?
        setOpenIdApplication(true) : setOpenIdApplication(false);
    }
  }, [fetchedApplication, setOpenIdApplication]);

  return (
    <WaitForApiRequestView
      {...otherDetailsModel}
      {...otherDetailsActions}
      className="applicationWizard"
      loadingMessage="Loading Application Wizard"
      errorTitle="Failed to load Application"
      retry={getApplication}
    >
      <ApplicationWizard
        {...otherProps}
        {...otherModel}
        {...otherActions}
        saveButtonLabel={saveButtonLabel}
        steps={steps}
        currentState={application}
        disabledSteps={disabledSteps}
        mapStepToView={mapStepToView}
        save={save}
        onSuccess={onSuccess}
      />
      <SecretDialog
        className="applicationSecretDialog"
        open={openSecretsDialog}
        secret={secret}
        fileName={resultAppId}
        closeDialog={closeSecretDialog}
      />
    </WaitForApiRequestView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  applicationId: getApplicationId(state),
  isEdit: isEditMode(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationWizardContainer);
