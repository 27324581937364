import React from "react";
import moment from "moment";
import classnames from "classnames";
import SortedSearchResultsList, {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import FileDetailsDialog from "../FileDetailsDialog";
import DataLakeFileListItem from "../DataLakeFileListItem";
import DataLakeFileColumn from "../models/DataLakeFileColumn";
import DataLakeFile, { DataLakeFileType } from "../models/DataLakeFile";
import { getStringValue, isEmptyString, noop, scrollToTop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import { DataLakeIndex } from "@data";

type Column = DataLakeFileColumn;

const DEFAULT_COLUMNS: Column[] = [
  DataLakeFileColumn.NAME,
  DataLakeFileColumn.SIZE,
  DataLakeFileColumn.UPDATED_AT,
];

const DEFAULT_YEAR_FOLDERS: DataLakeFile[] = Array((new Date().getFullYear() - 2016) + 1).fill("")
  .map((ignored, year) => new DataLakeFile({
    fileType: DataLakeFileType.FOLDER,
    fileName: `${2016 + year}`,
    fileSize: "-",
    updatedAt: "-",
  }));

const DEFAULT_MONTH_FOLDERS: DataLakeFile[] = Array(12).fill("")
  .map((ignored, month) => new DataLakeFile({
    fileType: DataLakeFileType.FOLDER,
    fileName: String(month + 1).padStart(2, "0"),
    fileSize: "-",
    updatedAt: "-",
  }));

const DEFAULT_DAY_FOLDERS: DataLakeFile[] = Array(31).fill("")
  .map((ignored, day) => new DataLakeFile({
    fileType: DataLakeFileType.FOLDER,
    fileName: String(day + 1).padStart(2, "0"),
    fileSize: "-",
    updatedAt: "-",
  }));

const DEFAULT_RENDER_ITEM = (item: DataLakeFile, column: DataLakeFileColumn) => (
  <DataLakeFileListItem
    column={column}
    item={item}
  />
);

const isLeapYear = (year: number) =>
  // Leap years must be divisible by 4
  year % 4 === 0 &&
  // And if a centennial year, it must be divisible by 400
  ((year % 100 !== 0) || (year % 100 === 0 && year % 400 === 0));

const mapSelectedMonthToDays = (selectedMonth: string, leapYear: boolean = false): DataLakeFile[] => DEFAULT_DAY_FOLDERS
  .filter((ignored, day) => {
    if (selectedMonth === "02") {
      return leapYear ? (day < 29) : (day < 28);
    } else if (selectedMonth === "04") {
      return day < 30;
    } else if (selectedMonth === "06") {
      return day < 30;
    } else if (selectedMonth === "09") {
      return day < 30;
    } else if (selectedMonth === "11") {
      return day < 30;
    } else {
      return true;
    }
  });

const DEFAULT_MAP_BREADCRUMBS_TO_VISIBLE_ITEMS = (breadcrumbs: string[], items: DataLakeFile[]) => {

  const [year = "", month = "", day = ""] = breadcrumbs.slice(1);

  if (isEmptyString(year)) {
    return DEFAULT_YEAR_FOLDERS;
  } else if (isEmptyString(month)) {
    return DEFAULT_MONTH_FOLDERS
      // Make sure that we do not include months in the future
      .filter(file => moment(`${year}-${file.getFileName()}`)
        .isSameOrBefore(moment(moment().format("YYYY-MM"))));
  } else if (isEmptyString(day)) {
    return mapSelectedMonthToDays(month, isLeapYear(Number(year)))
      // Make sure that we do not include days in the future
      .filter(file => moment(`${year}-${month}-${file.getFileName()}`)
        .isSameOrBefore(moment(moment().format("YYYY-MM-DD"))));
  } else {
    return items;
  }
};

export interface FileBrowserModel extends SortedSearchResultsListModel<DataLakeFile, Column> {
  className?: string;
  dataSetAlias?: string;
  accountId?: string;
  breadcrumbs?: string[];
  startDate?: Date | null;
  endDate?: Date | null;
  fileNameFilter?: string;
  customActionLabel?: string;
  dataLakeIndexItems?: DataLakeIndex[];
}

export interface FileBrowserActions extends SortedSearchResultsListActions<DataLakeFile, Column> {
  mapBreadcrumbsToVisibleItems?: (breadcrumbs: string[], items: DataLakeFile[]) => DataLakeFile[];
  setBreadcrumbs?: (breadcrumbs: string[]) => void;
  setStartDate?: (startDate: Date | null) => void;
  setEndDate?: (endDate: Date | null) => void;
  setFileNameFilter?: (fileNameFilter: string) => void;
  openFiltersMenu?: () => void;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
  customAction?: (file: string) => void;
  setDataLakeIndex?: (index: DataLakeIndex) => void;
  reset?: () => void;
}

type Model = FileBrowserModel;
type Actions = FileBrowserActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const FileBrowser = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    listClassName,
    loadMoreButtonClassName,
    showErrorView,
    showLoadingIndicator,
    showLoadMoreButton,
    dataLakeIndexItems = [],
    dataSetAlias = "",
    accountId = "",
    breadcrumbs = [dataSetAlias],
    columns = DEFAULT_COLUMNS,
    tableLayoutFixed = false,
    sortingDisabled = true, // TODO: REMOVE ONCE SORTING SUPPORTED
    mapBreadcrumbsToVisibleItems = DEFAULT_MAP_BREADCRUMBS_TO_VISIBLE_ITEMS,
    renderItem = DEFAULT_RENDER_ITEM,
    setBreadcrumbs = noop,
    customActionLabel,
    customAction,
    setDataLakeIndex,
    children,
    reset = noop,
    ...otherProps
  } = props;

  const items = React.useMemo(() => dataLakeIndexItems.map((item: DataLakeIndex) => new DataLakeFile({
    fileType: DataLakeFileType.FILE,
    fileName: item.getFileName(),
    fileSize: item.getFileSize(),
    updatedAt: item.getDateIndexed(),
    eTag: item.getETag(),
    filePath: item.getFilePath(),
  })), [dataLakeIndexItems]);

  const [selectedFile, setSelectedFile] = React.useState<DataLakeFile>(DataLakeFile.EMPTY);

  const activeBreadcrumb = React.useMemo(() =>
    getStringValue(breadcrumbs.slice().pop()), [breadcrumbs]);

  const isLoadMoreButtonVisible = React.useMemo(() => {

    if (!showLoadMoreButton) {
      return false;
    }

    const [ year = "", month = "", day = "" ] = breadcrumbs.slice(1);

    return !isEmptyString(year) && !isEmptyString(month) && !isEmptyString(day);

  }, [showLoadMoreButton, breadcrumbs]);

  const visibleItems = React.useMemo(() =>
      showErrorView ? [] : mapBreadcrumbsToVisibleItems(breadcrumbs, items),
    [showErrorView, items, breadcrumbs, mapBreadcrumbsToVisibleItems]);

  const showFileDetailsDialog = React.useMemo(() =>
    !DataLakeFile.EMPTY.equals(selectedFile), [selectedFile]);

  const openFileDetailsDialog = React.useCallback((file: DataLakeFile) =>
    setSelectedFile(file), [setSelectedFile]);

  const closeFileDetailsDialog = React.useCallback(() =>
    setSelectedFile(DataLakeFile.EMPTY), [setSelectedFile]);

  const onClickItem = React.useCallback((item: DataLakeFile) => {
    if (item.isFolder()) {
      setBreadcrumbs(breadcrumbs.concat(item.getFileName()));
    } else {
      openFileDetailsDialog(item);
    }
  }, [breadcrumbs, setBreadcrumbs, openFileDetailsDialog]);

  const selectIndex = React.useCallback((item: string) => {
    if (setDataLakeIndex) {
      const indexItem = dataLakeIndexItems.filter((index) => index.getFileName() === item).pop();
      setDataLakeIndex(indexItem ? indexItem : DataLakeIndex.EMPTY);
    }
  }, [dataLakeIndexItems, setDataLakeIndex]);

  // As the user changes the file path, scroll to the top in case they had
  // to scroll to the bottom of the window to find the folder that they wanted
  React.useEffect(() => {
    scrollToTop();
  }, [activeBreadcrumb]);

  // reset the browser when trying to navigate to a new file after an error view
  React.useEffect(() => {
    if (showErrorView) {
      reset();
    }
  }, [breadcrumbs]);

  return (
    <React.Fragment>
      <SortedSearchResultsList
        {...otherProps}
        className={classnames("fileBrowser", className, classes.container)}
        listClassName={classnames("list", listClassName, classes.list)}
        loadMoreButtonClassName={classnames("loadMoreButton", loadMoreButtonClassName, classes.loadMoreButton)}
        columns={columns}
        items={visibleItems}
        showErrorView={showErrorView}
        showLoadingIndicator={showLoadingIndicator}
        showLoadMoreButton={isLoadMoreButtonVisible}
        sortingDisabled={sortingDisabled}
        tableLayoutFixed={tableLayoutFixed}
        renderItem={DEFAULT_RENDER_ITEM}
        onClickItem={onClickItem}
      />
      <FileDetailsDialog
        className={classnames("fileDetailsDialog", classes.fileDetailsDialog)}
        dataSetAlias={dataSetAlias}
        accountId={accountId}
        dataLakeFile={selectedFile}
        open={showFileDetailsDialog}
        closeDialog={closeFileDetailsDialog}
        customActionLabel={customActionLabel}
        customAction={setDataLakeIndex ? selectIndex : customAction}
      />
      {children}
    </React.Fragment>
  );
});

export default FileBrowser;
