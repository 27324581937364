import React from "react";
import UploadDialog from "@components/upload-training-cert/UploadTrainingCertificateDialog";
import { useDataPrivacyTrainingStatus, useTrainingCertificateDetails } from "@hooks";
import TrainingCertificate, { Actions, Model } from "../components/TrainingCertificate";

type Props = Model & Actions;

const TrainingCertificateContainer = (props: Props) => {

  const [ openUploadDialog, setOpenUploadDialog ] = React.useState(false);

  const [ statusModel, statusActions ] = useDataPrivacyTrainingStatus({});
  const [ detailsModel, detailsActions ] = useTrainingCertificateDetails({});

  const {
    errorMessage: statusErrorMessage,
    showLoadingIndicator: statusLoadingIndicator,
    trainingStatus
  } = statusModel;
  const { refresh: statusRefresh } = statusActions;

  const {
    errorMessage: initialDetailsErrorMessage,
    showLoadingIndicator: detailsLoadingIndicator,
    errorResponse,
    certificateDetails,
  } = detailsModel;
  const { refresh: detailsRefresh } = detailsActions;

  const uploadCertificate = React.useCallback(() => {
    setOpenUploadDialog(true);
  }, [setOpenUploadDialog]);

  const closeDialog = React.useCallback(() => {
    setOpenUploadDialog(false);
  }, [setOpenUploadDialog]);

  const refresh = React.useCallback(() => {
    statusRefresh();
    detailsRefresh();
  }, [statusRefresh, detailsRefresh]);

  const showLoadingIndicator = React.useMemo(() =>
      detailsLoadingIndicator || statusLoadingIndicator,
    [detailsLoadingIndicator, statusLoadingIndicator]);

  const detailsErrorMessage = React.useMemo(() => {
    if (errorResponse?.status === 404) {
      return "";
    } else {
      return initialDetailsErrorMessage;
    }
  }, [errorResponse, initialDetailsErrorMessage]);

  const onSuccess = React.useCallback(() => {
    refresh();
  }, [refresh]);

  React.useEffect(() => refresh(), [refresh]);

  return (
    <TrainingCertificate
      {...props}
      loading={showLoadingIndicator}
      statusErrorMessage={statusErrorMessage}
      detailsErrorMessage={detailsErrorMessage}
      trainingCompleted={trainingStatus}
      uploadCertificate={uploadCertificate}
      refresh={refresh}
      certificateDetails={certificateDetails}
    >
      <UploadDialog
        open={openUploadDialog}
        closeDialog={closeDialog}
        onSuccess={onSuccess}
      />
    </TrainingCertificate>
  );
};

export default TrainingCertificateContainer;
