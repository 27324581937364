import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { bootstrapSummary as styles, PrimaryIcon } from "./styles";
import { DetailsView, SummaryView, Alert, AlertTitle } from "@components";
import { DeviceTypeModelV3CredentialType, SummaryViewData } from "@data";
import DeviceBootstrapView from "@modules/deviceDetails/components/DeviceBootstrapView";
import { noop } from "@util";

export interface Model {
  errorMessage?: string;
  loadingIndicator?: boolean;
  deviceTypeIdentity?: string;
  deviceId?: string;
  protocol?: string;
  hmacLink?: string;
  credentialType?: DeviceTypeModelV3CredentialType;
  endpoint?: string;
  children?: React.ReactNode;
  showNotFound?: boolean;
}

export interface Actions {
  refresh?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DeviceBootstrapSummaryView = withStyles(styles)((props: Props) => {

  const {
    classes,
    errorMessage,
    deviceTypeIdentity,
    credentialType,
    hmacLink = "https://www.collaboration.dtf.signify.com/display/IOTINT/Device+Identity+Management+-+Authentication+Protocol+-+Symmetric+Signature+V1",
    protocol,
    endpoint,
    loadingIndicator = false,
    children,
    refresh = noop,
    deviceId,
    showNotFound,
  } = props;

  const informationBanner = React.useMemo(() => {
    if (credentialType === DeviceTypeModelV3CredentialType.HMAC_SECRET) {
      return (
        <React.Fragment>
          <Alert severity="info" className={classnames("certificateInfo", classes.alert)}>
            <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
              <strong>
                Note
              </strong>
            </AlertTitle>
            <p className={classnames("alertDescription", classes.alertDescription)}>
              This device possesses a shared secret (Device Credential) it will use to generate a signed signature and
              pass to the platform via the HTTP standard Authorization header.
              The API endpoint information is available below.<br/>
              For more information on how to generate authorization header, click
              <a
                className={classes.underlined}
                href={hmacLink}
                target="_blank"
              >
                here
              </a>
              .
            </p>
          </Alert>
        </React.Fragment>
      );
    } else if (credentialType === DeviceTypeModelV3CredentialType.CERTIFICATE) {
      return (
        <React.Fragment>
          <Alert severity="info" className={classnames("certificateInfo", classes.alert)}>
            <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
              <strong>
                Note
              </strong>
            </AlertTitle>
            <p className={classnames("alertDescription", classes.alertDescription)}>
              The device possesses an X.509 certificate key-pair that it will use to connect to the platform
              via TLS client authentication (mTLS). The platform will validate the device certificate
              against the trusted signers for the IoT account.
              The API endpoint information is available below.<br/>
              Authorization request header is not needed as the device's certificate is used for that purpose.
            </p>
          </Alert>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Alert severity="info" className={classnames("certificateInfo", classes.alert)}>
            <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
              <strong>
                Note
              </strong>
            </AlertTitle>
            <p className={classnames("alertDescription", classes.alertDescription)}>
              We are not able to determine Credential Type for this device.
              Click on the link above for more information about device bootstrap.
            </p>
          </Alert>
        </React.Fragment>
      );
    }
  }, [credentialType]);

  const summaryViewItems = React.useMemo(() => [
    new SummaryViewData({
      className: "deviceTypeIdentity",
      name: "Device Type Identity",
      value: deviceTypeIdentity,
    }),
    new SummaryViewData({
      className: "authType",
      name: "Credential Type",
      value: credentialType,
    }),
    new SummaryViewData({
      className: "protocol",
      name: "Credential Protocol",
      value: protocol,
    }),
    new SummaryViewData({
      className: "endpoint",
      name: "API Endpoint",
      value: endpoint,
    }),
  ], [deviceTypeIdentity, credentialType, protocol, endpoint]);

  return (
    <div className={classnames("deviceBootstrapSummaryView", classes.container)}>
      <DetailsView
        icon={PrimaryIcon}
        title={deviceId}
        refresh={refresh}
        showLoadingIndicator={loadingIndicator}
        errorMessage={errorMessage}
        showNotFound={showNotFound}
      >
        <DeviceBootstrapView/>
        {informationBanner}
        <SummaryView
          items={summaryViewItems}
        />
      </DetailsView>
      {children}
    </div>
  );
});
