import { useApiRequestAnalytics } from "@hooks";
import { ListReleaseNotesResponse, PortalReleaseNotesClient } from "@network";
import { ReleaseNoteAttributes, ReleaseNotePlatformDomain, ReleaseStatus } from "@data/ReleaseNote";

export type ListReleaseNotesApi = (platformDomain: ReleaseNotePlatformDomain,
                                   releaseStatus?: ReleaseStatus,
                                   next?: string,
                                   limit?: number) => Promise<ListReleaseNotesResponse>;

export type GetReleaseNoteApi = (releaseId: string) => Promise<ReleaseNoteAttributes>;

export type GetByDomainAndVersionApi = (platformDomain: ReleaseNotePlatformDomain,
                                                   version: string) => Promise<ReleaseNoteAttributes>;

export interface PortalReleaseNotesClientApi {
  listReleaseNotes: ListReleaseNotesApi;
  getReleaseNote: GetReleaseNoteApi;
  getByVersionAndDomain: GetByDomainAndVersionApi;
}

enum ActionTypes {
  LIST_RELEASE_NOTES_REQUEST = "portal_release_notes_client/list_release_notes_request",
  LIST_RELEASE_NOTES_SUCCESS = "portal_release_notes_client/list_release_notes_success",
  LIST_RELEASE_NOTES_FAILED = "portal_release_notes_client/list_release_notes_failed",

  GET_RELEASE_NOTE_REQUEST = "portal_release_notes_client/get_release_note_request",
  GET_RELEASE_NOTE_SUCCESS = "portal_release_notes_client/get_release_note_success",
  GET_RELEASE_NOTE_FAILED = "portal_release_notes_client/get_release_note_failed",

  GET_BY_VERSION_AND_DOMAIN_REQUEST = "portal_release_notes_client/get_by_version_and_domain_request",
  GET_BY_VERSION_AND_DOMAIN_SUCCESS = "portal_release_notes_client/get_by_version_and_domain_success",
  GET_BY_VERSION_AND_DOMAIN_FAILED = "portal_release_notes_client/get_by_version_and_domain_failed",
}

const useListReleaseNotesApi = () =>
  useApiRequestAnalytics(PortalReleaseNotesClient.listReleaseNotes, {
    REQUEST_EVENT: ActionTypes.LIST_RELEASE_NOTES_REQUEST,
    SUCCESS_EVENT: ActionTypes.LIST_RELEASE_NOTES_SUCCESS,
    ERROR_EVENT: ActionTypes.LIST_RELEASE_NOTES_FAILED,
  });

const useGetReleaseNoteApi = () =>
  useApiRequestAnalytics(PortalReleaseNotesClient.getReleaseNote, {
    REQUEST_EVENT: ActionTypes.GET_RELEASE_NOTE_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_RELEASE_NOTE_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_RELEASE_NOTE_FAILED,
  });

const useGetByVersionAndDomainApi = () =>
  useApiRequestAnalytics(PortalReleaseNotesClient.getReleaseNoteByDomainAndVersion, {
    REQUEST_EVENT: ActionTypes.GET_BY_VERSION_AND_DOMAIN_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_BY_VERSION_AND_DOMAIN_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_BY_VERSION_AND_DOMAIN_FAILED,
  });

export const usePortalReleaseNotesClient = (): PortalReleaseNotesClientApi => ({
  listReleaseNotes: useListReleaseNotesApi(),
  getReleaseNote: useGetReleaseNoteApi(),
  getByVersionAndDomain: useGetByVersionAndDomainApi(),
});

export { ActionTypes as PortalReleaseNotesClientActionType };

export default usePortalReleaseNotesClient;
