import { Module } from "@data";
import { createModule } from "@modules/base";
import { initialize } from "./actions";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./ProfileAttributeDefinitionsView";

export const profileAttributeDefinitionsModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Profile Attributes",
  title: "Profile Attribute Management",
  className: "profileAttributeDefinitionModule",
  primaryColor,
  icon,
  view,
  backButton: null,
  initialize,
});

export default profileAttributeDefinitionsModule;
