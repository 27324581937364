import React from "react";
import { Module } from "@data";
import { MODULE_ID } from "./constants";
import { isExpirePasswordDialogVisible as isDialogOpen } from "./selectors";
import { initialize, open } from "./actions";
import { PrimaryIcon } from "../users/components/styles";
import ExpireUserPassword from "./containers/ExpireUserPassword";

export const expireUserPasswordModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "Expire User Password",
  title: "Expire User Password",
  icon: PrimaryIcon,
  view: <ExpireUserPassword />,
  onClick: open,
  isDialogOpen,
  initialize,
};

export default expireUserPasswordModule;
