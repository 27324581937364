import React from "react";
import InfoIcon from "@material-ui/icons/Help";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TextFieldProps } from "@material-ui/core/TextField";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { TooltipIcon } from "./TooltipIcon";

export enum TooltipIconPosition {
  START = "start",
  END = "end"
}

export const TextFieldTooltip = (props: {
  tooltipText: string,
  position?: TooltipIconPosition;
  icon?: React.ComponentType<SvgIconProps>,
}) => {

  const { tooltipText, position = TooltipIconPosition.END, icon: Icon = InfoIcon } = props;

  return (
    <InputAdornment position={position}>
      <TooltipIcon
        icon={Icon}
        tooltipText={tooltipText}
      />
    </InputAdornment>
  );
};

export const withTextFieldTooltip = (tooltipText: string,
                                     position: TooltipIconPosition = TooltipIconPosition.END,
                                     icon?: React.ComponentType<SvgIconProps>) =>

  (TextFieldComponent: React.ComponentType<TextFieldProps>) => (props: TextFieldProps) => {

    const { InputProps: inputProps = {} } = props;

    const key = position === TooltipIconPosition.END ? "endAdornment" : "startAdornment";

    return (
      <TextFieldComponent
        {...props}
        InputProps={{
          ...inputProps,
          [key]: (
            <TextFieldTooltip
              tooltipText={tooltipText}
              icon={icon}
              position={position}
            />
          )
        }}
      />
    );
  };

export const withRightTooltip = (tooltipText: string, icon?: React.ComponentType<SvgIconProps>) =>
  withTextFieldTooltip(tooltipText, TooltipIconPosition.END, icon);

export const withLeftTooltip = (tooltipText: string, icon?: React.ComponentType<SvgIconProps>) =>
  withTextFieldTooltip(tooltipText, TooltipIconPosition.START, icon);
