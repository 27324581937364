import React from "react";
import classnames from "classnames";
import { selectView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { SecurityGroupsListColumn } from "@components/security-groups-list/SecurityGroupsListColumn";
import SecurityGroupsList, {
  SecurityGroupsListActions,
  SecurityGroupsListModel,
} from "@components/security-groups-list";

export const DEFAULT_COLUMNS: SecurityGroupsListColumn[] = [SecurityGroupsListColumn.NAME];

export interface Model extends SecurityGroupsListModel {
  className?: string;
}

export interface Actions extends SecurityGroupsListActions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SelectSecurityGroup = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    selectedItems = [],
    showLoadMoreButton,
    showLoadingIndicator,
    errorMessage,
    ...otherProps
  } = props;

  const groupSelected = React.useMemo(() =>
    selectedItems.length > 0,
    [selectedItems]);

  const showLoadMore = React.useMemo(() =>
      groupSelected ? false : showLoadMoreButton,
    [groupSelected, showLoadMoreButton]);

  const error = React.useMemo(() =>
      groupSelected ? "" : errorMessage,
    [groupSelected, errorMessage]);

  return (
    <div className={classnames("selectSecurityGroup", className, classes.container)}>
      <SecurityGroupsList
        {...otherProps}
        columns={DEFAULT_COLUMNS}
        showLoadMoreButton={showLoadMore}
        showLoadingIndicator={showLoadingIndicator}
        errorMessage={error}
        selectable={true}
        selectAllDisabled={false}
        selectedItems={selectedItems}
      />
    </div>
  );
});

export default SelectSecurityGroup;
