import React from "react";

export const AuthTokenContext = React.createContext("");

export const AuthTokenProvider = (props: {
  authToken?: string,
  children?: React.ReactNode,
}) => {

  const { authToken = "", children } = props;

  return (
    <AuthTokenContext.Provider value={authToken}>
      {children}
    </AuthTokenContext.Provider>
  );
};

export default AuthTokenProvider;
