import React from "react";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  useWorkloadManagerClient,
} from "@hooks";

type SuccessResponse = void;

export interface UseSwitchWorkloadPrincipalProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
}

export interface UseSwitchWorkloadPrincipalModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseSwitchWorkloadPrincipalActions {
  switchPrincipal: () => void;
  reset: () => void;
}

type Props = UseSwitchWorkloadPrincipalProps;
type Model = UseSwitchWorkloadPrincipalModel;
type Actions = UseSwitchWorkloadPrincipalActions;
type Result = [Model, Actions];

export const useSwitchWorkloadPrincipal = (props: Props): Result => {

  const {
    name,
    version,
    defaultErrorMessage = "Failed to switch workload principal",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.switchPrincipal(name, version),
    [WorkloadManagerClient, name, version]);

  const [{ showSuccessView, successResponse, ...baseModel }, {
    refresh: switchPrincipal,
    ...baseActions
  }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
    showSuccessView ? "Workload Principal Updated" : "",
    [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    switchPrincipal,
  }), [
    baseActions,
    switchPrincipal,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useSwitchWorkloadPrincipal;
