import { createActions } from "@base/createActions";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";

export const {
  nameFilter: setNameFilter, // Should not be reset in between page changes
  reloadItemsRequested: setReloadItemsRequested,
  FETCH_USERS_REQUEST: fetchUsersRequest,
  FETCH_USERS_SUCCESS: fetchUsersSuccess,
  FETCH_USERS_FAILED: fetchUsersFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

const reset = () => (dispatch: any) => {
  dispatch(setNameFilter());
  dispatch(setReloadItemsRequested());
  return dispatch(baseReset());
};

export const reloadUsers = () => (dispatch: any) => {
  return dispatch(setReloadItemsRequested(true));
};

export const onMount = () => (dispatch: any) => {
  // Since the hook fetches users when mounted, make sure we don't refresh twice
  return dispatch(setReloadItemsRequested(false));
};

export const initialize = () => (dispatch: any) => {
  return dispatch(reset());
};
