import React from "react";
import { connect } from "react-redux";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { DeviceTypeDetailsSelectors } from "../selectors";
import { ManageSoftwareVersionsActions } from "../actions";
import RemoveSoftwareVersionDialog from "./RemoveSoftwareVersionDialog";
import SoftwareVersionDetails from "./SoftwareVersionDetails";
import SoftwareVersionEditor from "./SoftwareVersionEditor";
import { DeviceTypeListItem } from "@data";

export interface ContainerModel {
  showSoftwareVersionDetails?: boolean;
}

export interface ContainerActions {
  showDeviceTypeDetails?: (deviceType: DeviceTypeListItem) => void;
  clearErrorMessage?: () => void;
}

type Props = ContainerModel & ContainerActions;

const SoftwareVersionsViewContainer = (props: Props) => {

  const { showSoftwareVersionDetails, showDeviceTypeDetails, clearErrorMessage = noop } = props;

  React.useEffect(() => {
    return () => clearErrorMessage();
  });

  return (
    <React.Fragment>
      <SoftwareVersionEditor />
      {showSoftwareVersionDetails && (
        <SoftwareVersionDetails showDeviceTypeDetails={showDeviceTypeDetails} />
      )}
      <RemoveSoftwareVersionDialog />
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  showSoftwareVersionDetails: DeviceTypeDetailsSelectors.isSoftwareVersionDetailsVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  clearErrorMessage: () => dispatch(ManageSoftwareVersionsActions.clearErrorMessage()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(SoftwareVersionsViewContainer);
