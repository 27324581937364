import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import WorkloadVariableSchema from "@data/WorkloadVariableSchema";
import Popover, { PopoverOrigin } from "@material-ui/core/Popover";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import EditVariablesListItem from "./EditVariablesListItem";
import styles from "./styles";

const POPOVER_PORTAL_DISABLED = process.env.NODE_ENV === "test";

const DEFAULT_EDIT_POPOVER_ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: "center",
  horizontal: "right",
};

const DEFAULT_EDIT_POPOVER_TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

export interface EditVariablesListItemPopoverModel {
  className?: string;
  anchorEl?: null | Element | ((element: Element) => Element);
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  open?: boolean;
  item?: WorkloadVariableSchema;
}

export interface EditVariablesListItemPopoverActions {
  closePopover?: () => void;
  editItem?: (updatedItem: WorkloadVariableSchema) => void;
}

type Model = EditVariablesListItemPopoverModel;
type Actions = EditVariablesListItemPopoverActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const EditVariablesListItemPopover = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    anchorEl,
    anchorOrigin = DEFAULT_EDIT_POPOVER_ANCHOR_ORIGIN,
    transformOrigin = DEFAULT_EDIT_POPOVER_TRANSFORM_ORIGIN,
    open = false,
    item = WorkloadVariableSchema.EMPTY,
    closePopover = noop,
    editItem = noop,
    children,
  } = props;

  return (
    <Popover
      className={classnames("editVariablesListItemPopover", className, classes.container)}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      onClose={closePopover}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      open={open}
      disablePortal={POPOVER_PORTAL_DISABLED}
    >
      <EditVariablesListItem
        className={classnames("editVariablesListItem", classes.editVariablesListItem)}
        item={item}
        cancel={closePopover}
        save={(updatedItem: WorkloadVariableSchema) => {
          editItem(updatedItem);
          closePopover();
        }}
      />
      {children}
    </Popover>
  );
});

export default EditVariablesListItemPopover;
