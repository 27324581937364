import { ACTION_TYPES, DEFAULT_STATE, PoliciesViewMode } from "./reducers";
import { createActions } from "@base";

export const {
  policiesViewMode: setPoliciesViewMode,
  removeServiceButtonEnabled: setRemoveServiceButtonEnabled,
  detachPolicyButtonEnabled: setDetachPolicyButtonEnabled,
  serviceId: setServiceId,
  serviceEnabled: setServiceEnabled,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const showCustomPoliciesView = () => setPoliciesViewMode(PoliciesViewMode.CUSTOM);
export const showManagedPoliciesView = () => setPoliciesViewMode(PoliciesViewMode.MANAGED);

export const reset = () => (dispatch: any) => {
  dispatch(setServiceId());
  dispatch(setPoliciesViewMode());
  dispatch(setRemoveServiceButtonEnabled());
  dispatch(setDetachPolicyButtonEnabled());
  dispatch(setServiceEnabled());
  return dispatch(baseReset());
};

export const initialize = (serviceId: string) => (dispatch: any) => {
  dispatch(reset());
  return dispatch(setServiceId(serviceId));
};
