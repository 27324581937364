import React from "react";
import ErrorCodeView, { ErrorCodeViewProps } from "@components/error-view/ErrorCodeView";

export const NotFoundView = (props: ErrorCodeViewProps) => {

  const {
    errorMessage = "",
    label = "404 Error",
    className = "notFoundView",
    subLabel = "Not Found",
    description = "The requested URL was not found in the IoT Portal",
  } = props;

  return (
    <ErrorCodeView
      label={label}
      subLabel={subLabel}
      className={className}
      description={description}
      errorMessage={errorMessage}
    />
  );
};

export default NotFoundView;
