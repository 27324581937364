import React from "react";
import classnames from "classnames";
import { formEventHandler } from "@util";
import { JsonView, JsonViewModel } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { deviceEnrollment as styles } from "./styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { DeviceConfigurationDataMode } from "@modules/deviceDetails/reducers";
import MenuItem from "@material-ui/core/MenuItem";

export interface Model extends JsonViewModel {
  dataMode?: DeviceConfigurationDataMode;
}

export interface Actions {
  setDataMode?: (dataMode: DeviceConfigurationDataMode) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DeviceConfigurationJsonView = withStyles(styles)((props: Props) => {

  const {
    dataMode,
    setDataMode,
    classes,
    json,
    fileName,
  } = props;

  const mapDataModeToOptionName = (value: DeviceConfigurationDataMode) => {
    switch (value) {
      case DeviceConfigurationDataMode.ALL:
        return "All";
      case DeviceConfigurationDataMode.ACTUAL:
        return "Actual";
      case DeviceConfigurationDataMode.DESIRED:
        return "Desired";
      default:
        return null;
    }
  };

  const dataModeItems = Object.values(DeviceConfigurationDataMode)
    .map(value => {
      return (
        <MenuItem key={value} value={value}>{mapDataModeToOptionName(value)}</MenuItem>
      );
    });

  return (
    <div className={classnames("deviceConfigJsonView", classes.container)}>
      <div className={classnames("dataContainer", classes.dataContainer)}>
        <FormControl className={classnames("formControl", classes.formControl)}>
          <InputLabel
            className="dataMode"
            variant="standard"
            htmlFor="outlined-select-box-language"
          >
            Include Data
          </InputLabel>
          <Select
            value={dataMode}
            onChange={formEventHandler(setDataMode)}
          >
            {dataModeItems}
          </Select>
        </FormControl>
      </div>
      <JsonView json={json} fileName={fileName} />
    </div>
  );
});

export default DeviceConfigurationJsonView;
