import { createSelector } from "reselect";
import { isEmptyString } from "@util";
import { createSelectors, Selector } from "@base";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";
import { DisenrollDeviceSchema, DEFAULT_STATE } from "./reducers";

export const {
  open: isDialogOpen,
  deviceId: getDeviceId,
  errorMessage: getErrorMessage,
  successMessage: getSuccessMessage,
  showEmptyView: isEmptyViewVisible,
  showAccessDenied: isAccessDeniedVisible,
  showLoadingIndicator: isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
} = createSelectors<DisenrollDeviceSchema>(moduleId, schemaKey, DEFAULT_STATE);

export const isDeviceIdValid: Selector<boolean> = createSelector(
  getDeviceId, deviceId => !isEmptyString(deviceId));
