import React from "react";
import classnames from "classnames";
import MuiChip, { ChipProps as MuiChipProps } from "@material-ui/core/Chip";

export interface CustomMuiChipProps extends MuiChipProps {}

export const Chip = ((props: CustomMuiChipProps) => {

    const {
      className,
      ...otherProps
    } = props;

    return (
      <MuiChip
        {...otherProps}
        className={classnames("chip", className)}
      />
    );
});

export default Chip;
