import { Module } from "@data";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import CreateApplicationRegionalView from "./CreateApplicationRegionalView";
import EditApplicationRegionalView from "./EditApplicationRegionalView";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { getEditApplicationPath } from "./helpers";
import { initialize, reset } from "./actions";

type RouteParams = { id?: string; };

export const createApplicationRegionalModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Create Application",
  title: "Create New Application",
  className: "createApplicationRegionalModule",
  primaryColor,
  icon,
  onMount: reset,
  view: CreateApplicationRegionalView,
});

export const editApplicationRegionalModule: Module = createModule<RouteParams>({
  id: "edit_application_regional",
  path: getEditApplicationPath(":id"),
  exactPath: true,
  strictPath: true,
  name: "Edit Application",
  title: "Edit Application",
  className: "editApplicationRegionalModule",
  primaryColor,
  icon,
  view: EditApplicationRegionalView,
  onMount: ({ id: applicationId }: RouteParams = {}) => initialize(applicationId),
});

export default createApplicationRegionalModule;
