import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    button: {
    },
    active: {
    },
  });

export default styles;
