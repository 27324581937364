import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";
import { LockUserRequest, LockUserRequestAttributes } from "@data";

export enum LockUserAction {
  NONE = "",
  LOCK_USER = "lock_User",
  UNLOCK_USER = "unlock_user"
}

export interface LockUserSchema extends BasePortalModuleSchema {
  userId: string;
  open: boolean;
  action: LockUserAction;
  lockUser: LockUserRequestAttributes;
  json: string;
}

export const DEFAULT_STATE: LockUserSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  open: false,
  userId: "",
  action: LockUserAction.NONE,
  lockUser: { ...LockUserRequest.EMPTY.toJS() },
  json: JSON.stringify(LockUserRequest.EMPTY.toJS(), null, " "),
});

export type LockUserActionTypes = PortalModuleActionTypes<LockUserSchema> & {
  LOCK_USER_REQUEST: string;
  LOCK_USER_SUCCESS: string;
  LOCK_USER_FAILED: string;
  LOCK_USER_CREATE_ADMIN_NOTE_REQUEST: string;
  LOCK_USER_CREATE_ADMIN_NOTE_SUCCESS: string;
  LOCK_USER_CREATE_ADMIN_NOTE_FAILED: string;
  UNLOCK_USER_REQUEST: string;
  UNLOCK_USER_SUCCESS: string;
  UNLOCK_USER_FAILED: string;
  UNLOCK_USER_CREATE_ADMIN_NOTE_REQUEST: string;
  UNLOCK_USER_CREATE_ADMIN_NOTE_SUCCESS: string;
  UNLOCK_USER_CREATE_ADMIN_NOTE_FAILED: string;
};

export const ACTION_TYPES: LockUserActionTypes = {
  ...createPortalModuleActionTypes<LockUserSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    LOCK_USER_REQUEST: `${MODULE_ID}/lock_user_request`,
    LOCK_USER_SUCCESS: `${MODULE_ID}/lock_user_success`,
    LOCK_USER_FAILED: `${MODULE_ID}/lock_user_failed`,
    LOCK_USER_CREATE_ADMIN_NOTE_REQUEST: `${MODULE_ID}/lock_user_create_admin_note_request`,
    LOCK_USER_CREATE_ADMIN_NOTE_SUCCESS: `${MODULE_ID}/lock_user_create_admin_note_success`,
    LOCK_USER_CREATE_ADMIN_NOTE_FAILED: `${MODULE_ID}/lock_user_create_admin_note_failed`,
    UNLOCK_USER_REQUEST: `${MODULE_ID}/unlock_user_request`,
    UNLOCK_USER_SUCCESS: `${MODULE_ID}/unlock_user_success`,
    UNLOCK_USER_FAILED: `${MODULE_ID}/unlock_user_failed`,
    UNLOCK_USER_CREATE_ADMIN_NOTE_REQUEST: `${MODULE_ID}/unlock_user_create_admin_note_request`,
    UNLOCK_USER_CREATE_ADMIN_NOTE_SUCCESS: `${MODULE_ID}/unlock_user_create_admin_note_success`,
    UNLOCK_USER_CREATE_ADMIN_NOTE_FAILED: `${MODULE_ID}/unlock_user_create_admin_note_failed`,
  },
};

export const LockUserReducer = combineReducers<LockUserSchema>(
  createPortalModuleReducers<LockUserSchema>(ACTION_TYPES, DEFAULT_STATE));

export default LockUserReducer;
