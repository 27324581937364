import { Module } from "@data";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./styles";
import view from "./ApplicationsView";

export const applicationsRegionalModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Applications",
  title: "Application Management",
  className: "applicationsRegionalModule",
  primaryColor,
  icon,
  view,
  backButton: null,
});

export default applicationsRegionalModule;
