import React from "react";
import Tab from "@components/tab";
import Tabs from "@components/tabs";
import classnames from "classnames";
import { clickHandler } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface EffectivePoliciesModel {
  className?: string;
  title?: string;
  managedPoliciesViewSelected?: boolean;
  customPoliciesViewSelected?: boolean;
  children?: React.ReactNode;
}

export interface EffectivePoliciesActions {
  setManagedPoliciesView?: () => void;
  setCustomPoliciesView?: () => void;
}

enum PolicyType {
  CUSTOM = "Custom Policies",
  MANAGED = "Managed Policies"
}

type Props = WithStyles<typeof styles> & EffectivePoliciesModel & EffectivePoliciesActions;

export const EffectivePoliciesView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Policies",
    customPoliciesViewSelected = true,
    setManagedPoliciesView,
    setCustomPoliciesView,
    children,
  } = props;

  const activeTab = React.useMemo(() => {
    return customPoliciesViewSelected ? PolicyType.CUSTOM : PolicyType.MANAGED;
  }, [customPoliciesViewSelected]);

  return (
    <div className={classnames("policyManagement", className, classes.container)}>
      <label className={classnames("title", classes.title)}>
        {title}
      </label>
      <div className={classnames("controls", classes.controls)}>
        <Tabs className={classnames("tabs", classes.tabs)} value={activeTab}>
          <Tab
            style={{ "--tabPolicyValue": `\"${PolicyType.CUSTOM}\"` } as React.CSSProperties}
            className={classnames("customPoliciesButton", classes.tab)}
            onClick={clickHandler(setCustomPoliciesView)}
            label={PolicyType.CUSTOM}
            value={PolicyType.CUSTOM}
          />
          <Tab
            style={{ "--tabPolicyValue": `\"${PolicyType.MANAGED}\"` } as React.CSSProperties}
            className={classnames("managedPoliciesButton", classes.tab)}
            onClick={clickHandler(setManagedPoliciesView)}
            label={PolicyType.MANAGED}
            value={PolicyType.MANAGED}
          />
        </Tabs>
      </div>
      {children}
    </div>
  );

});

export default EffectivePoliciesView;
