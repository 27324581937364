import React from "react";
import classnames from "classnames";
import { noop, formEventHandler } from "@util";
import { TagEditor, ErrorView, TextField } from "@components";
import { nameTextField, descriptionTextField, nameView as styles } from "./styles";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";

const NameTextField = withStyles(nameTextField)(TextField);
const DescriptionTextField = withStyles(descriptionTextField)(TextField);

export interface Model {
  showTagsEditor?: boolean;
  showInvalidJsonError?: boolean;
  schemaName?: string;
  schemaNameError?: string;
  schemaNameDisabled?: boolean;
  schemaDescription?: string;
  schemaDescriptionError?: string;
  schemaTags?: string[];
  children?: React.ReactNode;
}

export interface Actions {
  setSchemaName?: (name: string) => void;
  setSchemaDescription?: (description: string) => void;
  updateTags?: (tags: string[]) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const NameView = withStyles(styles)((props: Props) => {

  const {
    classes,
    showTagsEditor,
    showInvalidJsonError,
    schemaName = "",
    schemaNameError = "",
    schemaNameDisabled = false,
    schemaDescription = "",
    schemaDescriptionError = "",
    schemaTags = [],
    setSchemaName,
    setSchemaDescription,
    updateTags = noop,
    children,
  } = props;

  if (showInvalidJsonError) {
    return (
      <div className={classnames("nameView", classes.errorViewContainer)}>
        <ErrorView
          title="JSON Syntax Error"
          message="Visual editors cannot be used until you have fixed the JSON syntax error."
        />
      </div>
    );
  }

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  return (
    <div className={classnames("nameView", classes.container)}>
      <label className={classes.title}>
        Set Schema Name & Description
      </label>
      <NameTextField
        className={classnames("schemaName", classes.schemaName)}
        autoComplete="off"
        label="Schema Name"
        name="name"
        value={schemaName}
        fullWidth={true}
        autoFocus={!schemaNameDisabled}
        disabled={schemaNameDisabled}
        variant="outlined"
        margin="none"
        required={true}
        helperText={schemaNameError}
        error={schemaNameError.length > 0}
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setSchemaName)}
      />
      <DescriptionTextField
        className={classnames("schemaDescription", classes.schemaDescription)}
        autoComplete="off"
        name="description"
        label="Schema Description"
        value={schemaDescription}
        fullWidth={true}
        multiline={true}
        minRows={3}
        maxRows={3}
        autoFocus={schemaNameDisabled}
        variant="outlined"
        margin="none"
        required={true}
        helperText={schemaDescriptionError}
        error={schemaDescriptionError.length > 0}
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setSchemaDescription)}
      />
      {showTagsEditor && (
        <TagEditor
          tags={schemaTags}
          setTags={updateTags}
          helperText="Tags to associate with this schema"
          className={classnames("tagEditor", classes.tagEditor)}
        />
      )}
      {children}
    </div>
  );
});

export default NameView;
