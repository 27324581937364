import React from "react";
import Fab from "@components/fab";
import classnames from "classnames";
import SaveIcon from "@material-ui/icons/Save";
import CheckmarkIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/PriorityHigh";
import { UserRequestStatus, UsersBulkRequest } from "@data";
import CircularProgress from "@components/circular-progress";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { reviewListItems as styles } from "./styles";

export enum UserReviewViewColumns {
  NONE = "",
  NAME = "User ID",
  STATUS = "Status",
  ERRORS = "Errors",
}

export const UserReviewViewListItem = withStyles(styles)((props: WithStyles<typeof styles> & {
  userBulkRequest?: UsersBulkRequest;
  column?: UserReviewViewColumns
}) => {

  const { column, userBulkRequest = UsersBulkRequest.EMPTY, classes } = props;

  if ( !column || UsersBulkRequest.EMPTY.equals(userBulkRequest)) {
    return null;
  }

  const status = userBulkRequest.getUserStatus();
  const success = status === UserRequestStatus.SUCCESS;
  const loading = status === UserRequestStatus.PROCESSING;
  const error = status === UserRequestStatus.FAILED;

  switch (column) {
    case UserReviewViewColumns.NAME:
      return (
        <label
          className={classnames("userId", classes.label, {
            [classes.failedLabel]: error,
          })}
        >
          {userBulkRequest.getUserId()}
        </label>
      );
    case UserReviewViewColumns.STATUS:
      return (
        <Fab
          color="primary"
          className={classnames("fab", classes.fab, {
            [classes.success]: success,
            [classes.failed]: error,
          })}
        >
          {success && !error && <CheckmarkIcon className={classnames(classes.icons, classes.successIcon)} />}
          {!success && error && <ErrorIcon className={classnames(classes.icons, classes.errorIcon)} />}
          {!success && !error && <SaveIcon className={classes.icons} />}
          {loading && <CircularProgress size={32} className={classes.progressIndicator} />}
        </Fab>
      );
    case UserReviewViewColumns.ERRORS:
      return (
        <label
          className={classnames("errors", classes.label, {
            [classes.failedLabel]: error,
          })}
        >
          {userBulkRequest.getErrorMessage()}
        </label>
      );
    default:
      return null;
  }
});

export default UserReviewViewListItem;
