import { Record } from "immutable";
import { getStringValue } from "@util";

export interface AdminNoteAttributes {
  note: string;
  createdBy: string;
  createdAt: number;
}

export class AdminNote extends Record({
  note: "",
  createdBy: "",
  createdAt: 0,
}) implements AdminNoteAttributes {

  public static EMPTY: AdminNote = new AdminNote();

  public readonly note: string;
  public readonly createdBy: string;
  public readonly createdAt: number;

  public getNote(): string {
    return getStringValue(this.note);
  }

  public getCreatedBy(): string {
    return getStringValue(this.createdBy);
  }

  public getCreatedAt(): number {
    return this.createdAt;
  }
}
