import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import {
  PortalModuleDialog,
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components";
import {
  getErrorMessage,
  getSuccessMessage,
  getUserId,
  isAccessDeniedVisible,
  isDialogOpen,
  isEmptyViewVisible,
  isLoadingIndicatorVisible,
  isUserIdValid,
} from "../selectors";
import { close, logoutUser } from "../actions";
import LogoutUser from "../components/LogoutUser";

interface Model extends PortalModuleDialogModel<string> {
  userId?: string;
}

type Actions = PortalModuleDialogActions<string>;

const LogoutUserDialog = (props: Model & Actions) => {

  const { userId, ...otherProps } = props;

  return (
    <PortalModuleDialog {...otherProps}>
      <LogoutUser userId={userId} />
    </PortalModuleDialog>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  className: "logoutUserDialog",
  title: "Logout User",
  open: isDialogOpen(state),
  item: getUserId(state),
  loading: isLoadingIndicatorVisible(state),
  showEmptyView: isEmptyViewVisible(state),
  accessDenied: isAccessDeniedVisible(state),
  errorMessage: getErrorMessage(state),
  successMessage: getSuccessMessage(state),
  continueButtonLabel: "Logout User",
  continueButtonDisabled: !isUserIdValid(state),
  userId: getUserId(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  cancel: () => dispatch(close()),
  confirm: () => dispatch(logoutUser()),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(LogoutUserDialog);
