import { createStyles } from "@material-ui/core/styles";

export const styles = () =>
  createStyles({
    downloadButton: {
      minHeight: 36,
      minWidth: 100,
    },
    loading: {},
    error: {},
    success: {},
  });

export default styles;
