import { identityAction, ReduxAction, ReduxActionCreator } from "@util/ReducerUtil";
import { JsonSchemaDefinition, JsonSchemaMetadataAttributes } from "@data";
import { ActionType, DEFAULT_STATE } from "./reducers";

type Action = ReduxAction<ActionType>;

export const setLocation: ReduxActionCreator<ActionType, string> = identityAction<ActionType, string>(
  ActionType.SET_LOCATION, DEFAULT_STATE.location);

export const setBreadcrumbs = identityAction<ActionType, string[]>(
  ActionType.SET_BREADCRUMBS, DEFAULT_STATE.breadcrumbs);

export const setSchema = identityAction<ActionType, JsonSchemaDefinition>(
  ActionType.SET_SCHEMA, DEFAULT_STATE.schema);

export const setMetadata = identityAction<ActionType, JsonSchemaMetadataAttributes>(
    ActionType.SET_METADATA, DEFAULT_STATE.metadata);

export const setErrorMessage = identityAction<ActionType, string>(
  ActionType.SET_ERROR_MESSAGE, DEFAULT_STATE.errorMessage);

export const setShowAccessDenied = identityAction<ActionType, boolean>(
  ActionType.SET_SHOW_ACCESS_DENIED, DEFAULT_STATE.showAccessDenied);

export const setShowLoadingIndicator = identityAction<ActionType, boolean>(
  ActionType.SET_SHOW_LOADING_INDICATOR, DEFAULT_STATE.showLoadingIndicator);

export const clearBreadcrumbs = (): Action => setBreadcrumbs();

export const clearErrorMessage = (): Action => setErrorMessage();

export const showAccessDenied = (): Action => setShowAccessDenied(true);
export const hideAccessDenied = (): Action => setShowAccessDenied(false);

export const showLoadingIndicator = (): Action => setShowLoadingIndicator(true);
export const hideLoadingIndicator = (): Action => setShowLoadingIndicator(false);
