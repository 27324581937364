import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const primaryColor = Color.MODULES;

export const headerIcon = () =>
  createStyles({
    container: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
    },
    icon: {
      width: 20,
      height: 20,
    },
    loadingIndicator: {
      margin: 0,
      padding: 0,
    },
    loadingIndicatorIcon: {
      margin: 0,
      padding: 0,
    },
  });

export const header = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: 8,
      backgroundColor: Color.LIGHT_GREY2,
      border: `1px solid ${Color.LIGHT_GREY}`,
      color: Color.TEXT,
      minHeight: 40,
    },
    error: {
      backgroundColor: Color.LIGHT_RED,
      border: `1px solid ${Color.RED}`,
      color: Color.DARK_RED,
    },
    success: {
      backgroundColor: "rgba(41, 228, 141, 0.25)",
      border: `1px solid ${Color.SIGNIFY_GREEN}`,
      color: Color.BLACK,
    },
    icon: {
      flex: "0 0 auto",
    },
    title: {
      flex: "0 0 auto",
      fontWeight: 400,
      fontSize: 16,
      margin: "0 8px 0 8px",
      padding: 0,
    },
  });

export const schemaView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    jsonViewer: {
      marginTop: 0,
    },
    header: {
      "& > .icon": {
        minWidth: 0,
      },
    },
  });

export const dataView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    dataJsonEditor: {
      marginTop: 0,
    },
    header: {
      "& > .icon": {
        minWidth: 20,
      },
    },
  });

export const results = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      color: Color.GREY3,
      padding: 8,
    },
    error: {
      color: Color.DARK_RED,
    },
    success: {
      color: Color.BLACK,
    },
    title: {
      fontSize: 14,
      fontWeight: 500,
      overflow: "hidden",
      whiteSpace: "normal",
      wordBreak: "break-word",
    },
    message: {
      fontSize: 13,
      fontWeight: 400,
      overflow: "hidden",
      whiteSpace: "normal",
      wordBreak: "break-word",
      marginTop: 8,
    },
  });

export const resultsView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      marginTop: 16,
    },
    results: {
      flex: "1 0 auto",
      border: `1px solid ${Color.LIGHT_GREY}`,
    },
    header: {
      "& > .icon": {
        minWidth: 20,
      },
    },
  });

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
    },
    accessDenied: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    schemaView: {
      flex: "0 0 50%",
    },
    viewContainer: {
      flex: "0 0 50%",
      paddingLeft: 16,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    dataView: {
      flex: "1 0 auto",
      minHeight: 300,
    },
    resultsView: {
      flex: "0 1 40%",
      minHeight: 125,
    },
  });

export default styles;
