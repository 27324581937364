import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import { DSTypeInfo } from "@data/DSTypeInfo";
import useDigitalShadowClient from "../use-digital-shadow-client";
import { ETagLocationHeaders } from "@network";

type SuccessResponse = ETagLocationHeaders;

export interface UseCreateDSTypeProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  requestBody?: DSTypeInfo;
}

export interface UseCreateDSTypeModel extends UseApiRequestModel<SuccessResponse> {
  etag: string;
  location: string;
  successMessage: string;
}

export interface UseCreateDSTypeActions extends UseApiRequestActions<SuccessResponse> {
  createType: () => void;
}

type Props = UseCreateDSTypeProps;
type Model = UseCreateDSTypeModel;
type Actions = UseCreateDSTypeActions;
type Result = [ Model, Actions ];

export const useCreateDSType = (props: Props): Result => {

  const {
    requestBody = DSTypeInfo.EMPTY,
    defaultErrorMessage = "Failed to create shadow type",
    ...otherProps
  } = props;

  const DigitalShadowClient = useDigitalShadowClient();

  const makeApiRequest = React.useCallback(() =>
    DigitalShadowClient.createTypeApi(JSON.stringify(requestBody)),
    [DigitalShadowClient, requestBody]);

  const [{ successResponse, showSuccessView, ...baseModel },
    { refresh: createType, ...baseActions }
    ] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Shadow Type Created" : "",
    [ showSuccessView ]);

  const etag = React.useMemo(() => {
    return successResponse?.etag || "";
  }, [successResponse]);

  const location = React.useMemo(() => {
    return successResponse?.location || "";
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    etag,
    location,
    successMessage,
    showSuccessView
  }), [
    baseModel,
    etag,
    location,
    successMessage,
    showSuccessView
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    createType,
    refresh: createType,
  }), [
    baseActions,
    createType,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useCreateDSType;
