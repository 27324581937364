import { Record } from "immutable";
import { getStringValue } from "@util";
import { DataSetDetailType } from "./DataSetDetail";
import { AccessRequestType } from "./DataAccessRequest";

export interface DataLakeDataSetAttributes {
  accountId: string;
  dataSetAlias: string;
  accessRequestType: AccessRequestType;
  dataSetType: DataSetDetailType;
}

export class DataLakeDataSet extends Record({
  accountId: "",
  dataSetAlias: "",
  accessRequestType: AccessRequestType.NONE,
  dataSetType: DataSetDetailType.NONE,
}) implements DataLakeDataSetAttributes {

  public static EMPTY: DataLakeDataSet = new DataLakeDataSet();

  public readonly accountId: string;
  public readonly dataSetAlias: string;
  public readonly accessRequestType: AccessRequestType;
  public readonly dataSetType: DataSetDetailType;

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getDataSetAlias(): string {
    return getStringValue(this.dataSetAlias);
  }

  public getAccessRequestType(): AccessRequestType {
    return this.accessRequestType || AccessRequestType.NONE;
  }

  public getDataSetType(): DataSetDetailType {
    return this.dataSetType || DataSetDetailType.NONE;
  }

  public isReadAccessAllowed(): boolean {
    const accessRequestType = this.getAccessRequestType();
    return accessRequestType === AccessRequestType.READ ||
      accessRequestType === AccessRequestType.READ_WRITE;
  }

  public isWriteAccessAllowed(): boolean {
    const accessRequestType = this.getAccessRequestType();
    return accessRequestType === AccessRequestType.WRITE ||
      accessRequestType === AccessRequestType.READ_WRITE;
  }

  public isDataLakeAcquiredDataSetType(): boolean {
    return this.getDataSetType() === DataSetDetailType.DATA_LAKE_ACQUIRED;
  }

  public isDataWarehouseDataSetType(): boolean {
    switch (this.getDataSetType()) {
      case DataSetDetailType.DATA_WAREHOUSE:
      case DataSetDetailType.DATA_WAREHOUSE_PRIVATE:
      case DataSetDetailType.DATA_WAREHOUSE_SHARED:
      case DataSetDetailType.DATA_WAREHOUSE_SYSTEM:
      case DataSetDetailType.DATA_WAREHOUSE_TABLE:
        return true;
      default:
        return false;
    }
  }
}

export default DataLakeDataSet;
