import React from "react";
import { connect } from "react-redux";
import { isEmptyString, noop } from "@util";
import { AppSchema } from "@schemas";
import {
  getAccount,
  getAccountError,
  getLoginButtonLabel,
  getPassword,
  getPasswordError,
  getToken,
  getTokenError,
  getUsername,
  getUsernameError,
  isCredentialsViewVisible,
  isRememberMeChecked,
  isShowPasswordChecked,
} from "../selectors";
import {
  login,
  setShowPassword,
  toggleRememberMe,
  updateAccount,
  updatePassword,
  updateToken,
  updateUsername,
} from "../actions";
import { useLoginPageRedirectState } from "../LoginPageRedirect";
import LoginForm, { Actions, Model } from "../components/LoginForm";

type Props = Model & Actions;

const LoginFormContainer = (props: Props) => {

  const {
    username,
    account,
    setUsername = noop,
    setAccount = noop,
    login: performLogin = noop,
  } = props;

  const [initialized, setInitialized] = React.useState(false);

  const { userId = "", accountId = "", autoLogin = false } = useLoginPageRedirectState();

  React.useEffect(() => {

    if (!initialized) {

      // If provided, override the current username with the userId query param
      if (!isEmptyString(userId)) {
        setUsername(userId);
      }

      // If provided, override the current account with the accountId query param
      if (!isEmptyString(accountId)) {
        setAccount(accountId);
      }

      setInitialized(true);
    }

    // Once initialized, if auto login was specified, verify that we have a valid
    // username and account id, and trigger the login flow if we do.
    if (autoLogin && !isEmptyString(username) && !isEmptyString(account)) {
      performLogin();
      return noop;
    }

    return noop;

  }, [
    initialized,
    autoLogin,
    userId,
    username,
    accountId,
    account,
    performLogin,
    setUsername,
    setAccount,
    setInitialized,
  ]);

  return (
    <LoginForm {...props} />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  username: getUsername(state),
  usernameError: getUsernameError(state),
  account: getAccount(state),
  accountError: getAccountError(state),
  rememberMe: isRememberMeChecked(state),
  password: getPassword(state),
  passwordError: getPasswordError(state),
  token: getToken(state),
  tokenError: getTokenError(state),
  showPassword: isShowPasswordChecked(state),
  showCredentialsView: isCredentialsViewVisible(state),
  loginButtonLabel: getLoginButtonLabel(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setUsername: (username: string) => dispatch(updateUsername(username)),
  setAccount: (account: string) => dispatch(updateAccount(account)),
  setPassword: (password: string) => dispatch(updatePassword(password)),
  setShowPassword: (showPassword: boolean) => dispatch(setShowPassword(showPassword)),
  setToken: (token: string) => dispatch(updateToken(token)),
  setRememberMe: (rememberMe: boolean) => dispatch(toggleRememberMe(rememberMe)),
  login: () => dispatch(login()),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(LoginFormContainer);
