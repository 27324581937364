import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import {
  fetchDeviceEnrollmentDetailsFailed,
  fetchDeviceEnrollmentDetailsRequest,
  fetchDeviceEnrollmentDetailsSuccess,
  setAutoRefresh as enableAutoRefresh,
} from "../actions";
import {
  getBatchId,
  isAutoRefreshEnabled,
} from "../selectors";
import { SummaryView, DetailsView, DevicesList, NotFoundView } from "@components";
import { DeviceEnrollment, EnrollmentStatus, SummaryViewData } from "@data";
import { getPluralString, isEmptyString, noop } from "@util";
import { useBatchEnrollmentDetails } from "@hooks";
import ModuleIcon from "@material-ui/icons/SettingsCell";

interface ContainerModel {
  batchId?: string;
  batchIdFromLocationState?: string;
  autoRefresh?: boolean;
  title?: string;
}

interface ContainerActions {
  showDeviceDetails?: (device: DeviceEnrollment) => void;
  showDeviceValidationDetails?: (device: DeviceEnrollment) => void;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
  setAutoRefresh?: (value: boolean) => void;
}

type Props = ContainerModel & ContainerActions;

const DeviceEnrollmentDetailsContainer = (props: Props) => {

  const {
    batchId,
    batchIdFromLocationState = "",
    autoRefresh = true,
    showDeviceDetails = noop,
    showDeviceValidationDetails = noop,
    trackRequestEvent = noop,
    trackSuccessEvent = noop,
    trackErrorEvent = noop,
    setAutoRefresh = noop,
    ...otherProps
  } = props;

  const [model, actions] = useBatchEnrollmentDetails({
    batchId,
    autoRefresh,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
  });

  const {
    deviceEnrollments,
    errorMessage,
    showAccessDenied,
    showProgressIndicator,
    showNotFound,
    numResults,
    autoRefreshCountdown = 5,
    retries = 0,
  } = model;

  const {
    refresh,
  } = actions;

  const devicesTitle = React.useMemo(() =>
    getPluralString(numResults, {
      other: "Devices",
      one: "Device",
  }), [numResults]);

  const showMoreDetails = React.useCallback((device: DeviceEnrollment) => {
    if (device.status !== EnrollmentStatus.VALIDATED) {
      showDeviceDetails(device);
    } else {
      showDeviceValidationDetails(device);
    }
  }, [showDeviceDetails, showDeviceValidationDetails]);

  const showRefreshMessage = React.useMemo(() =>
    (!showProgressIndicator && autoRefresh && retries > 0),
    [showProgressIndicator, autoRefresh, retries]);

  const refreshMessage = React.useMemo(() => showRefreshMessage ? (
      `Refreshing in ${getPluralString(autoRefreshCountdown , {one: "second", other: "seconds"})}`
  ) : "", [showRefreshMessage, autoRefreshCountdown]);

  const summaryItem: SummaryViewData[] = React.useMemo(() => [
    new SummaryViewData({
      id: "batchId",
      name: "Batch ID",
      value: batchId,
    })
  ], [batchId]);

  if (showNotFound) {
    return (
      <NotFoundView/>
    );
  }

  const enrollmentCompleted = React.useMemo(() =>
      deviceEnrollments.every(enrollment =>
        enrollment.status === EnrollmentStatus.ENROLLMENT_FAILURE ||
        enrollment.status === EnrollmentStatus.ENROLLED ||
        enrollment.status === EnrollmentStatus.DISENROLLMENT_FAILURE ||
        enrollment.status === EnrollmentStatus.DISENROLLED ||
        enrollment.status === EnrollmentStatus.DISENROLLMENT_PENDING ||
        enrollment.status === EnrollmentStatus.VALIDATION_FAILURE ||
        enrollment.status === EnrollmentStatus.VALIDATED)
    , [deviceEnrollments]);

  const autoRefreshOff = React.useMemo(() => {
    if (deviceEnrollments.length > 0) {
      return enrollmentCompleted;
    } else {
      return false;
    }
  } , [enrollmentCompleted, deviceEnrollments]);

  React.useEffect(() => {
    if (!isEmptyString(batchIdFromLocationState) && !autoRefreshOff) {
      setAutoRefresh(true);
    } else {
      setAutoRefresh(false);
    }
  } , [batchIdFromLocationState, autoRefreshOff]);

  return (
    <DetailsView
      {...otherProps}
      showLoadingIndicator={showProgressIndicator}
      showAccessDenied={showAccessDenied}
      errorMessage={errorMessage}
      refresh={refresh}
      title={batchId}
      icon={ModuleIcon}
      refreshMessage={refreshMessage}
    >
      <SummaryView items={summaryItem} />
      <DevicesList
        {...model}
        {...actions}
        className="deviceEnrollmentDevicesList"
        title={devicesTitle}
        devices={deviceEnrollments}
        onClickItem={showMoreDetails}
      />
    </DetailsView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  title: getBatchId(state),
  batchId: getBatchId(state),
  autoRefresh: isAutoRefreshEnabled(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  trackRequestEvent: () => dispatch(fetchDeviceEnrollmentDetailsRequest()),
  trackSuccessEvent: () => dispatch(fetchDeviceEnrollmentDetailsSuccess()),
  trackErrorEvent: analytic => dispatch(fetchDeviceEnrollmentDetailsFailed(analytic)),
  setAutoRefresh: (value => dispatch(enableAutoRefresh(value))),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceEnrollmentDetailsContainer);
