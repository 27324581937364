import React from "react";
import classnames from "classnames";
import Button from "@material-ui/core/Button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { AccountIdTextField, LoginTextField, PasswordTextField } from "../LoginTextField";
import { clickHandler, isEmptyString, noop } from "@util";
import { loginButton, styles } from "./styles";
import { RememberMe } from "../RememberMe";

const LoginButton = withStyles(loginButton)(Button);

export interface Model {
  serviceId?: string;
  serviceIdErrorMessage?: string;
  accountId?: string;
  accountIdErrorMessage?: string;
  secret?: string;
  secretErrorMessage?: string;
  mfaCode?: string;
  mfaCodeErrorMessage?: string;
  rememberService?: boolean;
  showLoadingIndicator?: boolean;
  showMfaCode?: boolean;
}

export interface Actions {
  setServiceId?: (serviceId: string) => void;
  setAccountId?: (accountId: string) => void;
  setSecret?: (secret: string) => void;
  setMfaCode?: (code: string) => void;
  setRememberService?: (rememberService: boolean) => void;
  login?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const ServiceLoginForm = withStyles(styles)((props: Props) => {

  const {
    classes,
    serviceId = "",
    serviceIdErrorMessage = "",
    accountId = "",
    accountIdErrorMessage = "",
    secret = "",
    secretErrorMessage = "",
    mfaCode = "",
    mfaCodeErrorMessage = "",
    rememberService,
    showLoadingIndicator,
    showMfaCode = true,
    setServiceId = noop,
    setAccountId = noop,
    setSecret = noop,
    setMfaCode = noop,
    setRememberService = noop,
    login,
  } = props;

  const [showSecret, setShowSecret] = React.useState(false);

  return (
    <div className={classnames("serviceLoginForm", classes.container)}>
      <LoginTextField
        className={classnames("serviceId", classes.serviceId)}
        name="serviceId"
        label="Service ID"
        value={serviceId}
        helperText={serviceIdErrorMessage}
        error={!isEmptyString(serviceIdErrorMessage)}
        disabled={showLoadingIndicator}
        setValue={setServiceId}
        login={login}
      />
      <AccountIdTextField
        className={classnames("accountId", classes.accountId)}
        name="accountId"
        label="Account ID"
        accountId={accountId}
        accountIdErrorMessage={accountIdErrorMessage}
        setAccountId={setAccountId}
        login={login}
      />
      <PasswordTextField
        className={classnames("secret", classes.secret)}
        name="secret"
        label="Secret"
        showPasswordTooltip="Show Secret"
        hidePasswordTooltip="Hide Secret"
        toggleShowPasswordLabel="Toggle Secret Visibility"
        password={secret}
        passwordErrorMessage={secretErrorMessage}
        showPassword={showSecret}
        setPassword={setSecret}
        setShowPassword={setShowSecret}
        login={login}
      />
      {showMfaCode && (
        <LoginTextField
          className={classnames("mfaCode", classes.mfaCode)}
          name="mfaCode"
          label="Authenticator Code"
          value={mfaCode}
          helperText={mfaCodeErrorMessage}
          error={!isEmptyString(mfaCodeErrorMessage)}
          disabled={showLoadingIndicator}
          setValue={setMfaCode}
          login={login}
        />
      )}
      <RememberMe
        className={classnames("rememberService", classes.rememberService)}
        label="Remember service"
        rememberMe={rememberService}
        setRememberMe={setRememberService}
      />
      <LoginButton
        className={classnames("loginButton", classes.loginButton)}
        variant="contained"
        fullWidth={true}
        onClick={clickHandler(login)}
      >
        Authenticate
      </LoginButton>
    </div>
  );
});

export default ServiceLoginForm;
