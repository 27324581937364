import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";

export enum ApproveRequestAction {
  NONE = "",
  APPROVE_REQUEST = "approve_request",
  REJECT_REQUEST = "reject_request",
}

export interface ApproveRequestAttributes {
  comments: string;
}

export class ApproveRequest extends Record({
  comments: "",
}) implements ApproveRequestAttributes {

  public static EMPTY: ApproveRequest = new ApproveRequest();

  public readonly comments: string;

  public getComments(): string {
    return getStringValue(this.comments + "");
  }

  public hasComments(): boolean {
    return !isEmptyString(this.getComments());
  }
}
