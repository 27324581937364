import { OrderedMap } from "immutable";
import { batch } from "react-redux";
import { AppSchema } from "@schemas";
import { getAuthToken } from "@main/selectors";
import { emptyAction, identityAction, isEmptyString, ReduxAction } from "@util";
import { DEFAULT_STATE, SchemaItems, SchemasActionType } from "../reducers";
import {
  JsonSchemaMetadata,
  JsonSchemaMetadataAttributes,
  SearchFilter,
  SearchFilterAttributes,
} from "../../../data";
import { GetSchemasResponse, RestClientError, SchemaRegistryClient } from "../../../network";
import {
  getNameFilter,
  getNextPage,
  getSchemaById,
  getSchemasMap,
  getSearchFilters,
} from "../selectors";

type ActionType = SchemasActionType;
type Action = ReduxAction<ActionType>;

export const setNameFilter = identityAction<ActionType, string>(
  SchemasActionType.SET_NAME_FILTER, DEFAULT_STATE.nameFilter);

export const setSearchFilterAttributes = identityAction<ActionType, SearchFilterAttributes[]>(
  SchemasActionType.SET_SEARCH_FILTERS, DEFAULT_STATE.searchFilters);

export const setItems = identityAction<ActionType, SchemaItems>(
  SchemasActionType.SET_ITEMS, DEFAULT_STATE.items);

export const setNextPage = identityAction<ActionType, string>(
  SchemasActionType.SET_NEXT_PAGE, DEFAULT_STATE.nextPage);

export const setErrorMessage = identityAction<ActionType, string>(
  SchemasActionType.SET_ERROR_MESSAGE, DEFAULT_STATE.errorMessage);

export const setShowEmptyView = identityAction<ActionType, boolean>(
  SchemasActionType.TOGGLE_SHOW_EMPTY_VIEW, DEFAULT_STATE.showEmptyView);

export const setShowNotFound = identityAction<ActionType, boolean>(
  SchemasActionType.TOGGLE_SHOW_NOT_FOUND, DEFAULT_STATE.showNotFound);

export const setShowAccessDenied = identityAction<ActionType, boolean>(
  SchemasActionType.TOGGLE_SHOW_ACCESS_DENIED, DEFAULT_STATE.showAccessDenied);

export const setShowLoadingIndicator = identityAction<ActionType, boolean>(
  SchemasActionType.TOGGLE_SHOW_LOADING_INDICATOR, DEFAULT_STATE.showLoadingIndicator);

export const reset = () => (dispatch: any) => {
  dispatch(setNameFilter());
  dispatch(setSearchFilterAttributes());
  dispatch(setItems());
  dispatch(setNextPage());
  dispatch(setErrorMessage());
  dispatch(setShowEmptyView());
  dispatch(setShowNotFound());
  dispatch(setShowAccessDenied());
  return dispatch(setShowLoadingIndicator());
};

export const setSearchFilters = (searchFilters: SearchFilter[] = []) =>
  (dispatch: any) => dispatch(setSearchFilterAttributes(
    searchFilters.map(searchFilter => searchFilter.toJS())));

export const updateItems =
  (items: OrderedMap<string, JsonSchemaMetadataAttributes> = OrderedMap<string, JsonSchemaMetadataAttributes>()) =>
    (dispatch: any, getState: () => AppSchema) => {

    return dispatch(setItems(
      getSchemasMap(getState()).merge(
        OrderedMap<string, JsonSchemaMetadataAttributes>(items)
          .map((attrs: JsonSchemaMetadataAttributes) => new JsonSchemaMetadata(attrs))
          .toOrderedMap())
        .map((item: JsonSchemaMetadata) => item.toJS())
        .toOrderedMap()
        .toJS()));
    };

export const addItems = (items: JsonSchemaMetadataAttributes[] = []) => (dispatch: any) => {

  const schemas = items.reduce((data, attrs) => {
    const item = new JsonSchemaMetadata(attrs);

    if (!JsonSchemaMetadata.EMPTY.equals(item)) {
      return data.set(item.getId(), item.toJS());
    }

    return data;
  }, OrderedMap<string, JsonSchemaMetadataAttributes>({}));

  return dispatch(updateItems(schemas));
};

export const removeSchema = (id: string = "") => (dispatch: any, getState: () => AppSchema) =>
  dispatch(setItems(
    getSchemasMap(getState())
      .delete(id)
      .map((item: JsonSchemaMetadata) => item.toJS())
      .toOrderedMap()
      .toJS()));

export const updateItem = (attrs: JsonSchemaMetadataAttributes = JsonSchemaMetadata.EMPTY.toJS()) =>
  (dispatch: any) => {

    const item = new JsonSchemaMetadata(attrs);

    if (JsonSchemaMetadata.EMPTY.equals(item)) {
      return Promise.resolve();
    }

    return dispatch(updateItems(OrderedMap<string, JsonSchemaMetadataAttributes>({
      [item.getId()]: item.toJS(),
    })));
  };

export const schemaUpdated = (updatedSchema: JsonSchemaMetadata = JsonSchemaMetadata.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const schema = getSchemaById(updatedSchema.getId(), getState());

    // We only need to update schemas we know about
    if (JsonSchemaMetadata.EMPTY.equals(schema)) {
      return Promise.resolve();
    }

    return dispatch(updateItem({
      ...schema.toJS(),
      ...updatedSchema.toJS(),
    }));
  };

export const clearItems = (): Action => setItems();

export const showEmptyView = (): Action => setShowEmptyView(true);
export const hideEmptyView = (): Action => setShowEmptyView(false);

export const showNotFound = (): Action => setShowNotFound(true);
export const hideNotFound = (): Action => setShowNotFound(false);

export const showAccessDenied = (): Action => setShowAccessDenied(true);
export const hideAccessDenied = (): Action => setShowAccessDenied(false);

export const showLoadingIndicator = (): Action => setShowLoadingIndicator(true);
export const hideLoadingIndicator = (): Action => setShowLoadingIndicator(false);

export const loadItemsRequest = emptyAction<ActionType>(
  SchemasActionType.LOAD_ITEMS);

export const loadItemsSuccess = emptyAction<ActionType>(
  SchemasActionType.LOAD_ITEMS_SUCCESS);

export const loadItemsFailed = identityAction<ActionType, string>(
  SchemasActionType.LOAD_ITEMS_FAILED, "");

export const fetchItems = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const authToken = getAuthToken(state);
  const nextPageToken = getNextPage(state);
  const nameFilter = getNameFilter(state);
  const query = SearchFilter.createSearchQuery(getSearchFilters(state));
  const isFirstPage = isEmptyString(nextPageToken);

  batch(() => {
    dispatch(showLoadingIndicator());
    dispatch(setErrorMessage());
    dispatch(hideNotFound());
    dispatch(hideAccessDenied());
    dispatch(loadItemsRequest());

    // Clear list if loading the first page of results
    if (isFirstPage) {
      dispatch(clearItems());
    }
  });

  return SchemaRegistryClient.getSchemas(authToken, nextPageToken, nameFilter, query)
    .then((response: GetSchemasResponse) => {

      // Ignore results if name filter changed while request was in progress
      if (nameFilter !== getNameFilter(getState())) {
        return Promise.resolve();
      }

      // Ignore results if query changed while request was in progress
      if (query !== SearchFilter.createSearchQuery(getSearchFilters(getState()))) {
        return Promise.resolve();
      }

      const { items = [], paging: { next: nextPage = "" } = {} } = response;

      const schemas = items.map((attrs: JsonSchemaMetadataAttributes) =>
        new JsonSchemaMetadata(attrs).toJS());

      batch(() => {
        dispatch(addItems(schemas));
        dispatch(setNextPage(nextPage || ""));
        dispatch(hideLoadingIndicator());
        dispatch(hideEmptyView());
      });

      return dispatch(loadItemsSuccess());

    }, (response: RestClientError) => {

      // Ignore results if name filter changed while request was in progress
      if (nameFilter !== getNameFilter(getState())) {
        return Promise.resolve();
      }

      // Ignore results if query changed while request was in progress
      if (query !== SearchFilter.createSearchQuery(getSearchFilters(getState()))) {
        return Promise.resolve();
      }

      const { analytic, status, error = "Fetch schemas failed" } = response;

      batch(() => {
        dispatch(setErrorMessage(error));
        dispatch(hideLoadingIndicator());

        if (status === 404) {
          dispatch(showNotFound());
        }

        if (status === 403) {
          dispatch(showAccessDenied());
        }

        dispatch(hideEmptyView());
      });

      return dispatch(loadItemsFailed(analytic));
    });
};

export const reloadItems = () => (dispatch: any) => {

  batch(() => {
    dispatch(setNextPage());
    dispatch(clearItems());
  });

  return dispatch(fetchItems());
};

export const onMount = () => (dispatch: any) => {

  return dispatch(reloadItems());
};

export const initialize = () => (dispatch: any) => {

  return dispatch(reset());
};
