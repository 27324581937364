import React from "react";
import { ReportFormat, ReportType } from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { generateDialog as styles } from "./styles";
import { formEventHandler, noop } from "@util";
import MenuItem from "@material-ui/core/MenuItem";
import classnames from "classnames";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

export interface Model {
  type?: ReportType;
  format?: ReportFormat;
  disabled?: boolean;
}

export interface Action {
  setType?: (type: ReportType) => void;
  setFormat?: (format: ReportFormat) => void;
}

type Props = WithStyles<typeof styles> & Model & Action;

export const GenerateUserReport = withStyles(styles)((props: Props) => {

  const {
    classes,
    type,
    format,
    disabled,
    setType = noop,
    setFormat = noop,
  } = props;

  const mapTypeToOptionName = (value: ReportType) => {
    switch (value) {
      case ReportType.USERS:
        return "Users";
      default:
        return null;
    }
  };

  const mapFormatToOptionName = (value: ReportFormat) => {
    switch (value) {
      case ReportFormat.CSV:
        return "CSV";
      default:
        return null;
    }
  };

  const reportTypeItems = Object.values(ReportType).map(value => {
    return (
      <MenuItem key={value} value={value}>{mapTypeToOptionName(value)}</MenuItem>
    );
  });

  const reportFormatItems = Object.values(ReportFormat).map(value => {
    return (
      <MenuItem key={value} value={value}>{mapFormatToOptionName(value)}</MenuItem>
    );
  });

  return (
    <div className={classnames("generateUserReport", classes.container)}>
      <label className={classnames("title", classes.title)}>
        Press the continue button to generate the following report.
        When your report is ready, a download button will appear from the users page
      </label>
      <div className={classnames("options", classes.options)}>
        <FormControl className={classnames("formControl", classes.formControl)} disabled={disabled}>
          <InputLabel
            className="reportType"
            variant="standard"
            htmlFor="outlined-select-box-language"
          >
            Report Type
          </InputLabel>
          <Select
            value={type}
            onChange={formEventHandler(setType)}
          >
            {reportTypeItems}
          </Select>
        </FormControl>
        <FormControl className={classnames("formControl", classes.formControl)} disabled={disabled}>
          <InputLabel
            className="reportFormat"
            variant="standard"
            htmlFor="outlined-select-box-language"
          >
            Report Format
          </InputLabel>
          <Select
            value={format}
            onChange={formEventHandler(setFormat)}
          >
            {reportFormatItems}
          </Select>
        </FormControl>
      </div>
    </div>
  );
});

export default GenerateUserReport;
