import React from "react";
import useApiRequest, {
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import { GetRequestCommentsResponse, RequestComment } from "@data";
import useDataSetRequestClient from "../use-data-set-request-client";

type SuccessResponse = GetRequestCommentsResponse;

export interface UseGetDataSetRequestCommentsProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  dataSetRequestId: string;
}

export interface UseGetDataSetRequestCommentsModel extends UseApiRequestModel<SuccessResponse> {
  comments: RequestComment[];
}

export interface UseGetDataSetRequestCommentsActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetDataSetRequestCommentsProps;
type Model = UseGetDataSetRequestCommentsModel;
type Actions = UseGetDataSetRequestCommentsActions;
type Result = [Model, Actions];

export const useGetDataSetRequestComments = (props: Props): Result => {

  const {
    dataSetRequestId = "",
    defaultErrorMessage = "Failed to get data set request comments",
    ...otherProps
  } = props;

  const DataSetRequestClient = useDataSetRequestClient();

  const makeApiRequest = React.useCallback(() =>
    DataSetRequestClient.getDataSetRequestComments(dataSetRequestId),
    [DataSetRequestClient, dataSetRequestId]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const comments = React.useMemo(() => {
    const { comments: items = [] } = successResponse || {};
    return items.map(attrs => new RequestComment({
      ...attrs,
      author: attrs.author.split(":").pop()
    }));
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successResponse,
    comments,
  }), [
    baseModel,
    successResponse,
    comments,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetDataSetRequestComments;
