import { createSelector } from "reselect";
import { isEmptyString } from "@util";
import { createSelectors, Selector } from "@base";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";
import { DeleteFederationProviderSchema, DEFAULT_STATE } from "./reducers";

export const {
  open: isDialogOpen,
  providerId: getProviderId,
  errorMessage: getErrorMessage,
  successMessage: getSuccessMessage,
  showEmptyView: isEmptyViewVisible,
  showAccessDenied: isAccessDeniedVisible,
  showLoadingIndicator: isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
} = createSelectors<DeleteFederationProviderSchema>(moduleId, schemaKey, DEFAULT_STATE);

export const isProviderIdValid: Selector<boolean> = createSelector(
  getProviderId, providerId => !isEmptyString(providerId));
