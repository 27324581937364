import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { FederationProviderWizardActions } from "../actions";
import { FederationProviderWizardSelectors } from "../selectors";
import FederationProviderWizard, { Actions, Model } from "../components/FederationProviderWizard";
import ClientView from "./ClientView";
import EndpointsView from "./EndpointsView";
import AttributesView from "./AttributesView";
import EditorView from "./EditorView";
import ReviewView from "./ReviewView";

interface ContainerModel extends Model {
  showClientView?: boolean;
  showEndPointsView?: boolean;
  showAttributesView?: boolean;
  showEditorView?: boolean;
  showReviewView?: boolean;
  successMessage?: string;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const FederationProviderWizardContainer = (props: Props) => {

  const {
    showClientView,
    showEndPointsView,
    showAttributesView,
    showEditorView,
    showReviewView,
    ...otherProps
  } = props;

  return (
    <FederationProviderWizard {...otherProps}>
      {showClientView && <ClientView />}
      {showEndPointsView && <EndpointsView />}
      {showAttributesView && <AttributesView />}
      {showEditorView && <EditorView />}
      {showReviewView && <ReviewView />}
    </FederationProviderWizard>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  showClientView: FederationProviderWizardSelectors.isClientViewSelected(state),
  showEndPointsView: FederationProviderWizardSelectors.isEndpointsViewSelected(state),
  showAttributesView: FederationProviderWizardSelectors.isAttributesViewSelected(state),
  showEditorView: FederationProviderWizardSelectors.isEditorViewSelected(state),
  showReviewView: FederationProviderWizardSelectors.isReviewViewSelected(state),
  currentState: FederationProviderWizardSelectors.getCurrentState(state),
  defaultState: FederationProviderWizardSelectors.getDefaultState(state),
  successMessage: FederationProviderWizardSelectors.getSuccessMessage(state),
  errorTitle: FederationProviderWizardSelectors.getErrorTitle(state),
  errorMessage: FederationProviderWizardSelectors.getErrorMessage(state),
  errors: FederationProviderWizardSelectors.getErrors(state),
  showLoadingIndicator: FederationProviderWizardSelectors.isLoadingIndicatorVisible(state),
  showSuccessIndicator: FederationProviderWizardSelectors.isSuccessMessageVisible(state),
  currentStep: FederationProviderWizardSelectors.getCurrentStep(state),
  completedSteps: FederationProviderWizardSelectors.getCompletedSteps(state),
  disabledSteps: FederationProviderWizardSelectors.getDisabledSteps(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  onChangeCurrentStep: step => dispatch(FederationProviderWizardActions.setCurrentStep(step)),
  save: () => dispatch(FederationProviderWizardActions.save()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(FederationProviderWizardContainer);
