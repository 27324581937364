import React from "react";
import classnames from "classnames";
import { ActionMenuItem, ActionsMenu, ActionsMenuProps } from "@components";
import { noop } from "@util";

export enum WorkloadTriggerAction {
  REMOVE_WORKLOAD_TRIGGER = "remove_workload_trigger",
}

export const REMOVE_WORKLOAD_TRIGGER: ActionMenuItem = Object.freeze({
  id: WorkloadTriggerAction.REMOVE_WORKLOAD_TRIGGER,
  name: "Remove Trigger",
  disabled: false,
});

export const DEFAULT_WORKLOAD_TRIGGER_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  REMOVE_WORKLOAD_TRIGGER,
]) as ActionMenuItem[];

export interface WorkloadTriggerActionsMenuProps extends ActionsMenuProps {
  removeWorkloadTrigger?: () => void;
}

export const withWorkloadTriggerActions = (Component: React.ComponentType<WorkloadTriggerActionsMenuProps>) =>
  (props: WorkloadTriggerActionsMenuProps) => {

    const {
      actions = DEFAULT_WORKLOAD_TRIGGER_ACTION_MENU_ITEMS,
      removeWorkloadTrigger = noop,
      onClickAction = noop,
    } = props;

    return (
      <Component
        {...props}
        actions={actions}
        onClickAction={(action: ActionMenuItem) => {
          switch (action.id) {
          case WorkloadTriggerAction.REMOVE_WORKLOAD_TRIGGER:
            return removeWorkloadTrigger();
          default:
            return onClickAction(action);
          }
        }}
      />
    );
  };

export const WorkloadTriggerActionsMenu = withWorkloadTriggerActions(
  (props: WorkloadTriggerActionsMenuProps) => {

  const { className, buttonLabel = "Workload Trigger Actions" } = props;

  return (
    <ActionsMenu
      {...props}
      className={classnames("workloadTriggerActionsMenu", className)}
      buttonLabel={buttonLabel}
    />
  );
});

export default WorkloadTriggerActionsMenu;
