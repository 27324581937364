import React from "react";
import { noop } from "@util";
import styles from "./styles";
import classnames from "classnames";
import FilterButton from "./FilterButton";
import Popover, { PopoverOrigin } from "@material-ui/core/Popover";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

const DEFAULT_ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: "center",
  horizontal: "center",
};

const DEFAULT_TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: "top",
  horizontal: "center",
};

export interface FiltersMenuModel {
  className?: string;
  buttonClassName?: string;
  iconClassName?: string;
  showBadge?: boolean;
  badgeCount?: number;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  open?: boolean;
  disabled?: boolean;
}

export interface FiltersMenuActions {
  openMenu?: () => void;
  closeMenu?: () => void;
}

type Model = FiltersMenuModel;

type Actions = FiltersMenuActions;

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const FiltersMenu = React.memo(withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    buttonClassName,
    iconClassName,
    showBadge,
    badgeCount,
    anchorOrigin = DEFAULT_ANCHOR_ORIGIN,
    transformOrigin = DEFAULT_TRANSFORM_ORIGIN,
    open = false,
    disabled = false,
    openMenu = noop,
    closeMenu = noop,
    children,
  } = props;

  const anchorRef = React.useRef(null);

  return (
    <div className={classnames("filtersMenu", className, classes.container)}>
      <FilterButton
        ref={anchorRef}
        className={classnames("openButton", buttonClassName, classes.openButton)}
        disabled={disabled}
        iconClassName={iconClassName}
        showBadge={showBadge}
        badgeCount={badgeCount}
        onClick={openMenu}
      />
      <Popover
        className={classnames("popover", classes.popover)}
        anchorEl={anchorRef.current}
        getContentAnchorEl={null}
        onClose={closeMenu}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={open}
        disablePortal={process.env.NODE_ENV === "test"}
      >
        {children}
      </Popover>
    </div>
  );
}));

export default FiltersMenu;
