import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";

export interface DeleteDataAccessRequestSchema extends BasePortalModuleSchema {
  accessRequestId: string;
  dataSetAlias: string;
  open: boolean;
}

export const DEFAULT_STATE: DeleteDataAccessRequestSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  open: false,
  accessRequestId: "",
  dataSetAlias: "",
});

export type DeleteDataAccessRequestActionTypes = PortalModuleActionTypes<DeleteDataAccessRequestSchema> & {
  DELETE_DATA_ACCESS_REQUEST_REQUEST: string;
  DELETE_DATA_ACCESS_REQUEST_SUCCESS: string;
  DELETE_DATA_ACCESS_REQUEST_FAILED: string;
};

export type DeleteDataAccessRequestActionType =
  DeleteDataAccessRequestActionTypes[keyof DeleteDataAccessRequestActionTypes];

export const ACTION_TYPES: DeleteDataAccessRequestActionTypes = {
  ...createPortalModuleActionTypes<DeleteDataAccessRequestSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    DELETE_DATA_ACCESS_REQUEST_REQUEST: `${MODULE_ID}/delete_data_access_request_request`,
    DELETE_DATA_ACCESS_REQUEST_SUCCESS: `${MODULE_ID}/delete_data_access_request_success`,
    DELETE_DATA_ACCESS_REQUEST_FAILED: `${MODULE_ID}/delete_ddata_access_request_failed`,
  },
};

export const DeleteDataAccessRequestReducer = combineReducers<DeleteDataAccessRequestSchema>(
  createPortalModuleReducers<DeleteDataAccessRequestSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DeleteDataAccessRequestReducer;
