import React from "react";
import classnames from "classnames";
import Select from "@material-ui/core/Select";
import { noop, formEventHandler, isEmptyString } from "@util";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@components/text-field";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Alert, AlertTitle, TagEditor } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { textField, textAreaField, dataSetAliasView as styles } from "./styles";
import { DataSetType } from "@data";

const NameTextField = withStyles(textField)(TextField);
const DescriptionTextField = withStyles(textAreaField)(TextField);

export interface Model {
  dataSetAlias?: string;
  dataSetAliasError?: string;
  dataSetAliasDisabled?: boolean;
  description?: string;
  dataSetGroup?: string;
  descriptionError?: string;
  dataLakeLink?: string;
  dataSetType?: DataSetType;
  tags?: string[];
  children?: React.ReactNode;
}

export interface Actions {
  setDataSetAlias?: (dataSetAlias: string) => void;
  setDescription?: (description: string) => void;
  setDataSetGroup?: (group: string) => void;
  setDataSetType?: (dataSetType: DataSetType) => void;
  updateTags?: (tags: string[]) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DataSetAliasView = withStyles(styles)((props: Props) => {

  const {
    classes,
    dataSetAlias = "",
    dataSetAliasError = "",
    dataSetAliasDisabled = false,
    description = "",
    dataSetGroup = "",
    descriptionError = "",
    dataLakeLink = "https://www.collaboration.dtf.signify.com/display/IOTPLAT/Data+Lake+-+Physical+Organization",
    dataSetType = DataSetType.DATA_LAKE_ACQUIRED,
    tags = [],
    setDataSetAlias,
    setDescription,
    setDataSetGroup,
    setDataSetType,
    updateTags = noop,
    children,
  } = props;

  const helperText = dataSetAliasError.length > 0 ?
      "Data set name should be unique with minimum length 4. No special characters or capital letters allowed." :
      "Unique identifier for the data set";

  const mapDataSetTypeToOptionName = (value: DataSetType) => {
    switch (value) {
      case DataSetType.DATA_LAKE_ACQUIRED:
        return "Data Lake Acquired";
      case DataSetType.DATA_LAKE_BLOBS:
        return "Data Lake Blobs";
      case DataSetType.DATA_WAREHOUSE_SYSTEM:
        return "Data Warehouse System";
      default:
        return null;
    }
  };

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const dataSetTypeMenuItems = Object.values(DataSetType)
    .filter(n => !isEmptyString(mapDataSetTypeToOptionName(n)))
    .map(value => <MenuItem key={value} value={value}>{mapDataSetTypeToOptionName(value)}</MenuItem>);

  return (
    <div className={classnames("dataSetAliasView", classes.container)}>
      <label className={classes.title}>
        {dataSetAliasDisabled ? "Data Set Name" : "Set Data Set Name"}
      </label>
      <NameTextField
        className={classnames("dataSetAlias", classes.dataSetAlias)}
        autoComplete="off"
        label="Data Set Name"
        name="dataSetAlias"
        value={dataSetAlias}
        fullWidth={true}
        autoFocus={!dataSetAliasDisabled}
        disabled={dataSetAliasDisabled}
        variant="outlined"
        margin="none"
        required={true}
        helperText={helperText}
        error={dataSetAliasError.length > 0}
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setDataSetAlias)}
      />
      <DescriptionTextField
        className={classnames("description", classes.description)}
        autoComplete="off"
        name="description"
        label="Description"
        value={description}
        fullWidth={true}
        multiline={true}
        minRows={3}
        autoFocus={false}
        required={true}
        helperText={descriptionError || "Description of the data set"}
        error={descriptionError.length > 0}
        variant="outlined"
        margin="none"
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setDescription)}
      />
      <NameTextField
        className={classnames("dataSetGroup", classes.dataSetGroup)}
        autoComplete="off"
        label="Data Set Group"
        name="dataSetGroup"
        value={dataSetGroup}
        fullWidth={true}
        variant="outlined"
        helperText={"Group that this data set will belong to (optional)"}
        margin="none"
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setDataSetGroup)}
      />
      <div className={classnames("dataSetTypeContainer", classes.dataSetTypeContainer)}>
        <FormControl className={classnames("formControl", classes.formControl)}>
          <InputLabel
            className="dataSetType"
            variant="standard"
          >
            Data Set Type
          </InputLabel>
          <Select
            value={dataSetType}
            onChange={formEventHandler(setDataSetType)}
          >
            {dataSetTypeMenuItems}
          </Select>
        </FormControl>
        <Alert severity="info" className={classnames("certificateInfo", classes.alert)}>
          <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
            <strong>
              Data Set Type
            </strong>
          </AlertTitle>
          <ul className={classnames("alertDescription", classes.alertDescription)}>
            <li>
              <strong>Data Lake Acquired - </strong>
              unstructured, semi-structured or structured data that is created externally,
              from outside the IoT platform (e.g. a Signify service backend, a device)
              that is to be collected and persisted in the IoT Data Lake.
              The IoT platform specifies a standard partitioning scheme for such data.
              <a
                className={classes.underlined}
                href={dataLakeLink}
                target="_blank"
              >
                Click Here
              </a>
              for more information
            </li>
            <li>
              <strong>Data Lake Blobs - </strong>
              structured derived data, which is created by the act of processing one or
              more Data Lake Acquired data sets. Can be flexibly partitioned by the data publisher.
            </li>
            <li>
              <strong>Data Warehouse - System data - </strong>
              this is a data set which is highly structured in nature and is designed to be queried using Presto SQL.
            </li>
          </ul>
        </Alert>
      </div>
      <TagEditor
        tags={tags}
        setTags={updateTags}
        helperText="Tags to associate with this data set"
        className={classnames("tagEditor", classes.tagEditor)}
      />
      {children}
    </div>
  );
});

export default DataSetAliasView;
