import { Record } from "immutable";
import { getStringValue } from "@util";
import { SecurityProtocol } from "@data/CreateSecurityServiceRegionalRequest";

export enum SecurityServiceState {
  NONE = "",
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export enum ServiceMfaStatus {
  NONE = "",
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export enum ServiceSecretState {
  NONE = "NONE",
  AVAILABLE = "AVAILABLE",
  UNAVAILABLE = "UNAVAILABLE",
}

export interface ServiceSecretsAvailabilityAttributes {
  primary: ServiceSecretState;
  secondary: ServiceSecretState;
}

export interface ServiceSecretsAttributes {
  availability?: ServiceSecretsAvailabilityAttributes;
  primary?: string;
}

export interface ServiceSecurityDataAttributes {
  secrets: ServiceSecretsAttributes;
}

export interface ServiceSecurityAttributes {
  data?: ServiceSecurityDataAttributes;
  protocol?: SecurityProtocol;
}

export interface ServiceMfaAttributes {
  id?: string;
  status: ServiceMfaStatus;
}

export interface SecurityServiceRegionalAttributes {
  id: string;
  created?: string;
  state?: SecurityServiceState;
  security?: ServiceSecurityAttributes;
  mfa?: ServiceMfaAttributes;
}

export class SecurityServiceRegional extends Record({
  id: "",
  created: "",
  state: SecurityServiceState.NONE,
  security: {
    data: {
      secrets: {
        availability: {
          secondary: ServiceSecretState.NONE,
          primary: ServiceSecretState.NONE,
        },
        primary: "",
      }
    },
    protocol: SecurityProtocol.NONE,
  },
  mfa: {
    id: "",
    status: ServiceMfaStatus.NONE,
  },
}) implements SecurityServiceRegionalAttributes {

  public static EMPTY: SecurityServiceRegional = new SecurityServiceRegional();

  public readonly id: string;
  public readonly created: string;
  public readonly state: SecurityServiceState;
  public readonly security?: ServiceSecurityAttributes;
  public readonly mfa?: ServiceMfaAttributes;

  public getId(): string {
    return getStringValue(this.id);
  }

  public getCreated(): string {
    return getStringValue(this.created);
  }

  public getServiceState(): SecurityServiceState {
    return this.state;
  }

  public isEnabled(): boolean {
    return this.state === SecurityServiceState.ENABLED;
  }

  public getPrimarySecret(): string {
    const { data: { secrets : { primary = "" } = {} } = {}} = this.security || {};

    return getStringValue(primary);
  }

  public getServiceProtocol(): SecurityProtocol {
    const { protocol = SecurityProtocol.NONE } = this.security || {};
    return protocol;
  }

  public getPrimarySecretAvailability(): ServiceSecretState {
    const { data: { secrets : { availability : { primary = ServiceSecretState.NONE} = {} } = {} } = {}}
      = this.security || {};

    return primary;
  }

  public isPrimarySecretAvailable(): boolean {
    const { data: { secrets : { availability : { primary = ServiceSecretState.NONE} = {} } = {} } = {}}
      = this.security || {};

    return primary !== ServiceSecretState.UNAVAILABLE;
  }

  public getSecondarySecretAvailability(): ServiceSecretState {
    const { data: { secrets : { availability : { secondary = ServiceSecretState.NONE} = {} } = {} } = {}}
      = this.security || {};

    return secondary;
  }

  public isSecondarySecretAvailable(): boolean {
    const { data: { secrets : { availability : { secondary = ServiceSecretState.NONE} = {} } = {} } = {}}
      = this.security || {};

    return secondary !== ServiceSecretState.UNAVAILABLE;
  }

  public getMfaStatus(): ServiceMfaStatus {
    const { status = ServiceMfaStatus.NONE } = this.mfa || {};
    return status;
  }

  public isMfaEnabled(): boolean {
    return ServiceMfaStatus.ENABLED === this.getMfaStatus();
  }

  public getMfaAuthenticatorId(): string {
    const { id = "" } = this.mfa || {};
    return id;
  }
}

export default SecurityServiceRegional;
