import React from "react";
import { SecurityServiceRegional } from "@data";
import {
  ActionMenuItem,
  ADD_SERVICE_TO_GROUP_ACTION,
  ATTACH_POLICY_TO_SERVICE_ACTION,
  DELETE_SERVICE_REGIONAL,
  DETACH_POLICY_FROM_SERVICE_ACTION,
  ModuleListViewActions,
  ModuleListView,
  ModuleListViewModel,
  REMOVE_SERVICE_FROM_GROUP_ACTION,
  ServiceAction,
  ServicesListColumn,
} from "@components";
import useGetServicesRegional from "@hooks/serviceManagerRegional/use-get-services-regional";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import { getPathToService } from "@modules/securityServiceRegionalDetails/helpers";
import { PrimaryIcon } from "../styles";
import { noop, Quantities } from "@util";
import { ServiceActionOperation } from "@hooks";
import lowerCase from "lodash/lowerCase";
import ServiceActionOperationDialog from "@components/service-action-operation-dialog";

export enum SecurityServicesRegionalColumn {
  NONE = "",
  ID = "ID",
  CREATED = "Created",
  STATE = "Enabled?",
  MFA = "MFA",
}

export const COLUMNS: SecurityServicesRegionalColumn[] = [
  SecurityServicesRegionalColumn.ID,
  SecurityServicesRegionalColumn.STATE,
  SecurityServicesRegionalColumn.MFA,
  SecurityServicesRegionalColumn.CREATED,
];

const ACTION_ITEMS: ActionMenuItem[] = [
  ADD_SERVICE_TO_GROUP_ACTION,
  REMOVE_SERVICE_FROM_GROUP_ACTION,
  ATTACH_POLICY_TO_SERVICE_ACTION,
  DETACH_POLICY_FROM_SERVICE_ACTION,
  DELETE_SERVICE_REGIONAL,
];

type ContainerModel = ModuleListViewModel<SecurityServiceRegional, SecurityServicesRegionalColumn>;

interface ContainerActions extends ModuleListViewActions<SecurityServiceRegional, SecurityServicesRegionalColumn> {
  showServiceDetails?: (service: SecurityServiceRegional) => void;
  createService?: (service: SecurityServiceRegional) => void;
  addServiceToGroup?: (service: SecurityServiceRegional) => void;
  attachPolicy?: (service: SecurityServiceRegional) => void;
  detachPolicy?: (service: SecurityServiceRegional) => void;
  removeServiceFromGroup?: (service: SecurityServiceRegional) => void;
}

type Props = ContainerModel & ContainerActions;

const SecurityServicesContainer = (props: Props) => {

  const {
    showServiceDetails,
    showSearch = true,
    searchViewHint = "Filter results that start with this name",
    createService = noop,
    addServiceToGroup = noop,
    actions: serviceActions = ACTION_ITEMS,
    attachPolicy = noop,
    detachPolicy = noop,
    removeServiceFromGroup = noop,
    onClickAction = noop,
    ...otherProps
  } = props;

  const [serviceId, setServiceId] = React.useState("");
  const [openActionOperationDialog, setOpenActionOperationDialog] = React.useState(false);
  const [actionOperation, setActionOperation] = React.useState(ServiceActionOperation.NONE);

  const [model, actions] = useGetServicesRegional();

  const { nameFilter, services, ...otherModels } = model;

  const { refresh: refreshList, setNameFilter, ...otherActions } = actions;

  const moduleListItems: ListViewItem<SecurityServiceRegional>[] = React.useMemo(() =>
    services.map(service => {
      return {
        item: service,
        pathToDetailsView: getPathToService(service.getId()),
        icon: PrimaryIcon,
        columnAttributes: createColumns([
          {
            className: "serviceId",
            value: service.getId(),
            column: SecurityServicesRegionalColumn.ID,
            firstColumn: true,
          },
          {
            className: "serviceCreated",
            value: service.getCreated(),
            column: SecurityServicesRegionalColumn.CREATED,
            isDate: true,
          },
          {
            className: "serviceState",
            value: service.getServiceState(),
            column: SecurityServicesRegionalColumn.STATE,
            checkmark: service.getServiceState(),
          },
          {
            className: "mfaStatus",
            value: service.getMfaStatus(),
            column: ServicesListColumn.MFA,
            checkmark: service.isMfaEnabled(),
          },
        ]),
      };
    }), [services]);

  const summaryViewQuantities: Quantities = {
    other: "services",
    one: "service",
  };

  const deleteServiceRegional = React.useCallback(
    (service: SecurityServiceRegional = SecurityServiceRegional.EMPTY) => {
      setServiceId(service.getId());
      setActionOperation(ServiceActionOperation.DELETE);
      setOpenActionOperationDialog(true);
    }, [setServiceId, setActionOperation, setOpenActionOperationDialog]);

  const onClickServiceAction = React.useCallback((service: SecurityServiceRegional, action: ActionMenuItem) => {
    switch (action.id) {
    case ServiceAction.DELETE_SERVICE_REGIONAL:
      return deleteServiceRegional(service);
    case ServiceAction.ADD_SERVICE_TO_GROUP:
      return addServiceToGroup(service);
    case ServiceAction.REMOVE_SERVICE_FROM_GROUP:
      return removeServiceFromGroup(service);
    case ServiceAction.ATTACH_POLICY_TO_SERVICE:
      return attachPolicy(service);
    case ServiceAction.DETACH_POLICY_FROM_SERVICE:
      return detachPolicy(service);
    default:
      return onClickAction(service, action);
    }
  }, [
    deleteServiceRegional,
    addServiceToGroup,
    removeServiceFromGroup,
    attachPolicy,
    detachPolicy,
    onClickAction,
  ]);

  const closeActionOperationDialog = React.useCallback(() => {
    setServiceId("");
    setActionOperation(ServiceActionOperation.NONE);
    return setOpenActionOperationDialog(false);
  }, [setServiceId, setActionOperation, setOpenActionOperationDialog]);

  const onOperationSuccess = React.useCallback(() => {
    closeActionOperationDialog();
    refreshList();
  }, [refreshList, closeActionOperationDialog]);

  const dialogClassName = React.useMemo(() => `${lowerCase(actionOperation)}ServiceDialog`, [actionOperation]);

  return (
    <React.Fragment>
      <ModuleListView
        {...otherModels}
        {...otherActions}
        {...otherProps}
        className={"services"}
        summaryViewIcon={PrimaryIcon}
        nameFilter={nameFilter}
        listClassName={"servicesRegionalList"}
        columns={COLUMNS}
        listViewItems={moduleListItems}
        showSummary={true}
        showSearch={showSearch}
        searchViewHint={searchViewHint}
        showCreateButton={true}
        actions={serviceActions}
        onClickAction={onClickServiceAction}
        createButtonLabel={"Create Service"}
        onClickCreateButton={createService}
        summaryViewQuantities={summaryViewQuantities}
        setNameFilter={setNameFilter}
        onClickItem={showServiceDetails}
        refresh={refreshList}
      />
      <ServiceActionOperationDialog
        dialogClassName={dialogClassName}
        open={openActionOperationDialog}
        serviceId={serviceId}
        operation={actionOperation}
        onSuccessMessageShown={onOperationSuccess}
        cancel={closeActionOperationDialog}
      />
    </React.Fragment>
  );
};

export default SecurityServicesContainer;
