import React from "react";
import DeviceTypeView, { Model, Actions } from "../components/DeviceTypeView";
import { DeviceTypeListItem } from "@data";
import { isEmptyString } from "@util";
import { useDeviceTypes } from "@hooks";
import uniqBy from "lodash/uniqBy";

interface ContainerModel extends Model {
  deviceNamespace?: string;
  deviceType?: string;
  accountId?: string;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const DeviceTypeContainerView = (props: Props) => {

  const {
    deviceNamespace = "",
    deviceType = "",
    accountId,
    ...otherProps
  } = props;

  const [ model, api ] = useDeviceTypes();

  const {
    searchQuery,
    deviceTypes: items,
    showLoadMoreButton: isLoadMoreButtonVisible,
  } = model;

  const uniqueDeviceTypes = React.useMemo(() =>
    uniqBy(items.map(dt => new DeviceTypeListItem({
      typeIdentity: `${dt.getNameSpace()}:${dt.getName()}:1`,
      metadata: {
        namespace: dt.getNameSpace(),
        name: dt.getName()
      }
    })), "typeIdentity"), [items]);

  const typeIdentity = React.useMemo(() => `${deviceNamespace}:${deviceType}:1`,
    [deviceNamespace, deviceType]);

  const selectedItems: DeviceTypeListItem[] = React.useMemo(() =>
      uniqueDeviceTypes.filter(dt => typeIdentity.indexOf(dt.getTypeIdentity()) >= 0),
    [typeIdentity, uniqueDeviceTypes]);

  const isItemSelected = React.useMemo(() =>
    selectedItems.length > 0, [selectedItems]);

  const deviceTypes = React.useMemo(() =>
      isItemSelected ? selectedItems : uniqueDeviceTypes,
    [isItemSelected, selectedItems, uniqueDeviceTypes]);

  const noResultsLabel = React.useMemo(() => {
    if (isEmptyString(searchQuery)) {
      return `No device types found in account ${accountId}`;
    } else {
      return "No device types found";
    }
  }, [searchQuery, accountId]);

  const showLoadMoreButton = React.useMemo(() =>
    isLoadMoreButtonVisible && !isItemSelected,
    [isLoadMoreButtonVisible, isItemSelected]);

  return (
    <DeviceTypeView
      {...otherProps}
      {...model}
      {...api}
      deviceTypes={deviceTypes}
      hideSearch={isItemSelected}
      noResultsLabel={noResultsLabel}
      selectedItems={selectedItems}
      showLoadMoreButton={showLoadMoreButton}
    />
  );
};

export default DeviceTypeContainerView;
