import { Color } from "@Color";
import ModuleIcon from "@material-ui/icons/ViewQuilt";
import createStyles from "@material-ui/core/styles/createStyles";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    container: {
    },
    table: {
      "& th.expandColumn, & td.expandColumn": {
        width: "10%",
      },
    },
  });
