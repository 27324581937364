import { Record } from "immutable";

export interface VectorAttributes {
  x: number;
  y: number;
}

export class Vector extends Record({
  x: 0,
  y: 0,
}) implements VectorAttributes {

  public static EMPTY: Vector = new Vector();

  public readonly x: number;
  public readonly y: number;

  public static create(x: number, y: number): Vector {
    return new Vector({ x, y });
  }

  public static square(size: number): Vector {
    return Vector.create(size, size);
  }
}

export default Vector;
