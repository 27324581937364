import React from "react";
import Fab from "../fab";
import Badge from "../badge";
import classnames from "classnames";
import { clickHandler } from "@util";
import { filterButton as styles } from "./styles";
import FilterIcon from "@material-ui/icons/FilterList";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

type Props = WithStyles<typeof styles> & {
  className?: string;
  iconClassName?: string;
  showBadge?: boolean;
  badgeCount?: number;
  disabled?: boolean;
  onClick?: () => void;
};

export const FilterButton = withStyles(styles)(React.forwardRef((props: Props, ref?: React.Ref<any>) => {

  const {
    classes,
    className,
    iconClassName,
    badgeCount = 0,
    showBadge = badgeCount > 0,
    disabled,
    onClick,
  } = props;

  const badgeContent = React.useMemo(() => showBadge ? badgeCount : 0, [showBadge, badgeCount]);

  return (
    <Fab
      className={classnames("filterButton", className, classes.container)}
      aria-label="filter-button"
      color="default"
      size="small"
      ref={ref}
      disabled={disabled}
      onClick={clickHandler(onClick)}
    >
      <Badge
        aria-label={badgeContent === 0 ? "hidden-badge" : "visible-badge"}
        badgeContent={badgeContent}
      >
        <FilterIcon
          className={classnames("filterIcon", iconClassName, classes.icon)}
          color="inherit"
        />
      </Badge>
    </Fab>
  );
}));

export default FilterButton;
