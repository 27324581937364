import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import { FilterSwitch, SummaryView } from "@components";
import { InviteUserState } from "../models";
import { reviewView as styles } from "./styles";
import { InviteUserRequest, SummaryViewData } from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface Model {
  currentState?: InviteUserState;
  request?: InviteUserRequest;
  sendEmailCopy?: boolean;
}

export interface Actions {
  setSendEmailCopy?: (value: boolean) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const ReviewView = withStyles(styles)((props: Props) => {

  const {
    classes,
    request = InviteUserRequest.EMPTY,
    currentState = InviteUserState.EMPTY,
    sendEmailCopy = false,
    setSendEmailCopy = noop,
  } = props;

  const summaryItems = React.useMemo(() => [
    new SummaryViewData({
      className: "userId",
      name: "User ID",
      value: currentState.userId,
    }),
    new SummaryViewData({
      className: "applicationId",
      name: "Application ID",
      value: currentState.applicationId,
    }),
    new SummaryViewData({
      className: "emailTemplateId",
      name: "Template ID",
      value: currentState.emailTemplateId,
    }),
    new SummaryViewData({
      className: "firstName",
      name: "First Name",
      value: request.profile?.firstName?.value,
    }),
    new SummaryViewData({
      className: "middle_name",
      name: "Middle Name",
      value: request.profile?.middle_name?.value,
    }),
    new SummaryViewData({
      className: "lastName",
      name: "Last Name",
      value: request.profile?.lastName?.value,
    }),
    new SummaryViewData({
      className: "family_name",
      name: "Family Name",
      value: request.profile?.family_name?.value,
    }),
    new SummaryViewData({
      className: "given_name",
      name: "Given Name",
      value: request.profile?.given_name?.value,
    }),
    new SummaryViewData({
      className: "nickname",
      name: "Nick-name",
      value: request.profile?.nickname?.value,
    }),
    new SummaryViewData({
      className: "language",
      name: "Language",
      value: request.profile?.language?.value,
    }),
    new SummaryViewData({
      className: "zoneinfo",
      name: "Zone Info",
      value: request.profile?.zoneinfo?.value,
    }),
  ], [currentState, request]);

  const onClickSwitch = React.useCallback((value: boolean) => {
    return value ? setSendEmailCopy(true) : setSendEmailCopy(false);
  }, [setSendEmailCopy]);

  return (
    <div className={classnames("reviewView", classes.container)}>
      <SummaryView items={summaryItems} title="Review Invitation" />
      <FilterSwitch
        className="sendCopySwitch"
        title="Send a copy of the invitation to me"
        titleClassName="sendCopySwitchTitle"
        showTitle={true}
        checked={sendEmailCopy}
        leftLabel="Disabled"
        leftLabelClassName="disabled"
        rightLabel="Enabled"
        rightLabelClassName="enabled"
        onChange={onClickSwitch}
      />
    </div>
  );
});

export default ReviewView;
