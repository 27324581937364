import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { listItem as styles } from "./styles";
import { DSTypeInfo, KeyValuePair } from "@data";
import { Chip } from "@components";
import DSLayoutChildrenListColumn from "./DSLayoutChildrenListColumn";
import classnames from "classnames";

export const DSLayoutChildrenListItem = withStyles(styles)((props: WithStyles<typeof styles> & {
  type: DSTypeInfo,
  column: string,
}) => {

  const { classes, type = DSTypeInfo.EMPTY, column } = props;

  if ( !column || DSTypeInfo.EMPTY.equals(type) ) {
    return null;
  }

  const metadataArrayAsString = React.useMemo(() =>
   KeyValuePair.fromObjectToArray(type.getMetadata())
     .map((value: KeyValuePair) => `${value.getKey()} : ${value.getValue()}`), [type]);

  switch (column) {
    case DSLayoutChildrenListColumn.NAME:
      return (
        <div className={classnames("name", classes.container)}>
          <label className={classnames("name", classes.label)}>{type.getName()}</label>
        </div>
      );
    case DSLayoutChildrenListColumn.DESCRIPTION:
      return (
        <label className={classnames("description", classes.label)}>{type.getDescription()}</label>
      );
    case DSLayoutChildrenListColumn.METADATA:
      return (
        <ul className={classnames("metadata", classes.tags)}>
          {metadataArrayAsString.map((metadata: string, index: number) => (
            <li key={`${metadata}-${index}`} className={classnames("metadataValue", classes.tag)}>
              <Chip
                className={classnames("chip", classes.chip)}
                label={metadata}
              />
            </li>
          ))}
        </ul>
      );
    default:
      return null;
  }
});

export default DSLayoutChildrenListItem;
