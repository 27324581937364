import React from "react";
import classnames from "classnames";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { JsonSchemaProperty } from "../../../../data";
import { PropertyInfo } from "./PropertyInfo";
import { styles } from "./styles";

export interface Model {
  property?: JsonSchemaProperty;
}

export const RemoveProperty = withStyles(styles)((props: WithStyles<typeof styles> & Model) => {

  const {
    classes,
    property = JsonSchemaProperty.EMPTY,
  } = props;

  return (
    <div className={classnames("removeProperty", "content", classes.content)}>
      <label className={classnames("title", classes.title)}>
        Are you sure you want to remove this property?
      </label>
      <label className={classnames("subtitle", classes.subtitle)}>
        <span className={classes.warning}>WARNING:</span>This action cannot be undone!
      </label>
      <PropertyInfo property={property} />
    </div>
  );
});

export default RemoveProperty;
