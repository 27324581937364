import { Breadcrumb } from "@data";
import { MODULE_TITLE } from "./constants";
import { match as Match } from "react-router-dom";
import { isQualityReportsListViewEnabled } from "@util";
import welcomePageModule from "@modules/welcome/module";
import { QualityReportsTab } from "@modules/qualityReports/components/QualityReportsTabs";
import {
  BASE_URL as QUALITY_REPORTS_BASE_URL,
  COMMON_SERVICES_QUALITY_REPORTS_PATH,
  DATA_AND_ANALYTICS_QUALITY_REPORTS_PATH,
  DEVICE_MANAGEMENT_QUALITY_REPORTS_PATH,
  MODULE_TITLE as QUALITY_REPORTS_TITLE,
  SECURITY_SERVICES_QUALITY_REPORTS_PATH,
} from "@modules/qualityReports/constants";

const LIST_VIEW_ENABLED = isQualityReportsListViewEnabled();

const BASE_BREADCRUMBS = Object.freeze<Breadcrumb[]>([
    new Breadcrumb({
      title: welcomePageModule.breadcrumbTitle,
      url: welcomePageModule.path,
    }),
  ].concat(!LIST_VIEW_ENABLED ? [] : [(new Breadcrumb({
    title: QUALITY_REPORTS_TITLE,
    url: QUALITY_REPORTS_BASE_URL,
  }))]),
);

const PLATFORM_DOMAIN_BREADCRUMBS = Object.freeze<Breadcrumb[]>([
  new Breadcrumb({
    title: QualityReportsTab.SECURITY_SERVICES,
    url: SECURITY_SERVICES_QUALITY_REPORTS_PATH,
  }),
  new Breadcrumb({
    title: QualityReportsTab.DEVICE_MANAGEMENT,
    url: DEVICE_MANAGEMENT_QUALITY_REPORTS_PATH,
  }),
  new Breadcrumb({
    title: QualityReportsTab.DATA_AND_ANALYTICS,
    url: DATA_AND_ANALYTICS_QUALITY_REPORTS_PATH,
  }),
  new Breadcrumb({
    title: QualityReportsTab.COMMON_SERVICES,
    url: COMMON_SERVICES_QUALITY_REPORTS_PATH,
  }),
]);

function customBreadcrumbsFactory(match: Match) {

  const { path, url } = match;

  return BASE_BREADCRUMBS
    .concat(!LIST_VIEW_ENABLED ? [] : (PLATFORM_DOMAIN_BREADCRUMBS
      .find(breadcrumb => path.indexOf(breadcrumb.getURL()) >= 0) || []))
    .concat([new Breadcrumb({
      title: MODULE_TITLE,
      url,
    })]);
}

export default customBreadcrumbsFactory;
