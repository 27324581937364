import createStyles from "@material-ui/core/styles/createStyles";
import { Color } from "@Color";
import { Theme } from "@material-ui/core/styles";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      flex: "1 0 auto",
    },
    loadingLabel: {
      display: "block",
      textAlign: "center",
      fontStyle: "italic",
      color: Color.TEXT,
      fontSize: 30,
      fontWeight: 300,
      paddingTop: 15,
      paddingBottom: 15,
    },
    informationContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 16,
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
    typeName: {
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "16px",
      marginTop: 16,
    },
    typeInfoContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    infoTitle: {
      color: Color.TEXT,
      fontWeight: 300,
      paddingLeft: 8,
    },
    description: {
      marginTop: 15,
      color: Color.TEXT,
      fontSize: 16,
      height: "auto",
      padding: 8,
      "& label": {
        marginLeft: 8,
        marginTop: 9,
      }
    },
    metadata: {
      color: Color.TEXT,
      fontWeight: 200,
      marginTop: 15,
      paddingLeft: 8,
    },
    metadataTextFields: {
      marginTop: 10,
      display: "flex",
      flexFlow: "row nowrap",
      "& label": {
        marginLeft: 8,
        marginTop: 9,
      }
    },
    metadataKey: {
      fontFamily: "Roboto, sans-serif",
      minWidth: 250,
    },
    metadataValue: {
      fontFamily: "Roboto, sans-serif",
      minWidth: 250,
      marginLeft: 10,
    },
    addMetadataButton: {
      margin: "16px 0 0 16px",
      width: 32,
      height: 32,
      borderRadius: 32,
      padding: 0,
      backgroundColor: Color.WHITE,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.54)",
      },
    },
    metadataTagContainer: {
      display: "flex",
      flexFlow: "row wrap",
    },
    metadataTag: {
      height: 28,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
      margin: theme.spacing(),
      padding: theme.spacing(),
      color: Color.WHITE,
    },
    metadataIcon: {
      color: Color.WHITE,
      width: 14,
      height: 14,
      marginLeft: 2,
      marginRight: 2,
      "&:first-child": {
        marginRight: 0,
      },
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
  });

export default styles;
