import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import classnames from "classnames";
import {
  SortedSearchResultsList,
  SortedSearchResultsListModel,
  SortedSearchResultsListActions,
  ContinueButton,
  RefreshButton,
  SummaryViewWithActions,
  SummaryViewActionItems,
} from "@components";
import { configurationView as styles } from "./styles";
import { WorkloadConfiguration } from "@data";
import { sortConfigListResult } from "./helpers";
import WorkloadKeyValueListItem from "@components/workload-key-value-view/WorkloadKeyValueListItem";
import { isEmptyString } from "@util";

export enum WorkloadKeyValueListColumn {
  KEY= "Key",
  VALUE = "Value"
}

export interface WorkloadKeyValueViewModel
  extends SortedSearchResultsListModel<WorkloadConfiguration, WorkloadKeyValueListColumn> {
  summaryViewItems?: SummaryViewActionItems[];
  configuration: WorkloadConfiguration[];
  addConfigurationButtonDisabled?: boolean;
  deleteAllButtonDisabled?: boolean;
  showEditConfigButton?: boolean;
  showDeleteConfigButton?: boolean;
}

export interface WorkloadKeyValueViewActions extends
  SortedSearchResultsListActions<WorkloadConfiguration, WorkloadKeyValueListColumn> {
  addConfiguration?: () => void;
  deleteAll?: () => void;
  editConfig?: (config: WorkloadConfiguration) => void;
  deleteConfig?: (config: WorkloadConfiguration) => void;
}

type Props = WithStyles<typeof styles> & WorkloadKeyValueViewModel & WorkloadKeyValueViewActions;

export const WorkloadKeyValueView = withStyles(styles)((props: Props) => {

  const {
    classes,
    summaryViewItems,
    configuration,
    addConfigurationButtonDisabled,
    addConfiguration,
    deleteAllButtonDisabled,
    deleteAll,
    showEditConfigButton,
    editConfig,
    showDeleteConfigButton,
    deleteConfig,
    showLoadingIndicator,
    refresh,
    statusCode,
    error,
  } = props;

  const [sortByColumn, setSortByColumn] = React.useState<string>(WorkloadKeyValueListColumn.KEY);
  const [sortOrderAscending, setSortOrderAscending] = React.useState(true);

  const sortedItems = sortConfigListResult({items: configuration, sortOrderAscending, sortByColumn});
  const DEFAULT_COLUMNS = [WorkloadKeyValueListColumn.KEY, WorkloadKeyValueListColumn.VALUE];

  const disableDeleteAllButton = React.useMemo(() =>
    deleteAllButtonDisabled || configuration.length < 1,
    [deleteAllButtonDisabled, configuration]);

  const onClickEditIcon = React.useMemo(() => showEditConfigButton ? editConfig : undefined,
    [showEditConfigButton, editConfig]);

  const onClickDeleteIcon = React.useMemo(() => showDeleteConfigButton ? deleteConfig : undefined,
    [showDeleteConfigButton, deleteConfig]);

  const showSummary = React.useMemo(
    () => summaryViewItems?.find(item => !isEmptyString(item.summaryViewItem.getValue())), [summaryViewItems]);

  return (
    <div className={classnames("configurationView", classes.container)}>
      {showSummary && <SummaryViewWithActions items={summaryViewItems}/>}
      <div className={classnames("header", classes.header)}>
        <div className={classnames("headerContent", classes.headerContent)}>
          <label className={classnames("title", classes.title)}>
            Properties
          </label>
          <RefreshButton
            className={classnames("refreshPropertiesButton")}
            loading={showLoadingIndicator}
            refresh={refresh}
          />
        </div>
        <div className={classnames("controls", classes.controls)}>
          <ContinueButton
            className={classnames("addConfiguration", classes.addConfiguration)}
            color="primary"
            label="Add Configuration"
            disabled={addConfigurationButtonDisabled}
            onClick={addConfiguration}
          />
          <ContinueButton
            className={classnames("deleteAllButton", classes.deleteAllButton)}
            color="secondary"
            label="Delete All"
            disabled={disableDeleteAllButton}
            onClick={deleteAll}
          />
        </div>
      </div>
      <SortedSearchResultsList
        className={classnames("configurationList", classes.configurationList)}
        columns={DEFAULT_COLUMNS}
        items={sortedItems}
        noResultsLabel={`Configuration data is not set up for this workload`}
        renderItem={(attribute: WorkloadConfiguration, column: WorkloadKeyValueListColumn) =>
          <WorkloadKeyValueListItem column={column} attribute={attribute} />}
        showLoadingIndicator={showLoadingIndicator}
        sortByColumn={sortByColumn}
        sortOrderAscending={sortOrderAscending}
        setSortByColumn={setSortByColumn}
        setSortOrderAscending={setSortOrderAscending}
        onClickItem={onClickEditIcon}
        onClickEditIcon={onClickEditIcon}
        onClickDeleteIcon={onClickDeleteIcon}
        refresh={refresh}
        errorMessage={error}
        statusCode={statusCode}
      />
    </div>
  );
});

export default WorkloadKeyValueView;
