import React from "react";
import { Namespace } from "@data";
import { useHistory } from "react-router-dom";
import { getCreateNamespacePath } from "@modules/createNamespace/helpers";
import { getPathToNamespace } from "@modules/namespaceDetails/helpers";
import Namespaces from "./containers/Namespaces";

export function NamespacesView() {

  const history = useHistory();

  const createNamespace = React.useCallback(() =>
    history.push(getCreateNamespacePath()), [history]);

  const showNamespaceDetails = React.useCallback((namespace: Namespace) => {
    history.push(getPathToNamespace(namespace));
  }, [history]);

  return (
    <Namespaces
      createNamespace={createNamespace}
      showNamespaceDetails={showNamespaceDetails}
    />
  );
}

export default NamespacesView;
