import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      paddingTop: 8,
      paddingLeft: 16,
      borderBottom: `1px solid ${Color.GREY1}`,
      borderLeft: `1px solid ${Color.GREY1}`,
      borderRight: `1px solid ${Color.GREY1}`,
      cursor: "pointer !important",
      "&:last-child": {
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
      },
      "& label, & $tag": {
        cursor: "pointer !important",
      },
      "&:hover, &:active, &:focus": {
        backgroundColor: "rgba(249,255,251,0.7)",
      },
      "&$active": {
        cursor: "default !important",
        "& label, & $tag": {
          cursor: "default !important",
        },
        backgroundColor: "#F2FCF5",
        "&:hover, &:active, &:focus": {
          backgroundColor: "#F2FCF5 !important",
        },
        "& $stateIconContainer": {
          backgroundColor: "#F2FCF5",
          "& $stateIcon": {
            color: Color.SIGNIFY_GREEN,
          },
        },
        "& $stateLabel": {
          fontWeight: 500,
        },
      },
    },
    listItemContent: {
      display: "flex",
      flexFlow: "column nowrap",
      borderLeft: `1px solid ${Color.GREY3}`,
      position: "relative",
      paddingLeft: 16,
      paddingTop: 8,
    },
    stateIconContainer: {
      width: 20,
      height: "auto",
      backgroundColor: Color.WHITE,
      position: "absolute",
      left: -10,
      top: 0,
      paddingTop: 8,
      "&$released": {
        width: 21,
        top: 0,
      },
      "&$decommissioned, &$deprecated": {
        width: 16,
        left: -8,
        top: 1,
      },
    },
    stateIcon: {
      width: 20,
      height: "auto",
      color: Color.GREY6,
      "&$released": {
        color: Color.SIGNIFY_GREEN,
        width: 21,
      },
      "&$decommissioned, &$deprecated": {
        width: 16,
      },
    },
    stateLabel: {
      color: Color.BLACK,
      fontSize: 14,
      fontWeight: 400,
    },
    actionsMenu: {
      marginLeft: 4,
      marginRight: 4,
      position: "absolute",
      right: 0,
      top: 0,
    },
    actionsMenuIconButton: {
      padding: 6,
    },
    versionLabel: {
      color: Color.TEXT,
      fontSize: 14,
      fontWeight: 300,
      marginTop: 10,
    },
    descriptionLabel: {
      color: Color.TEXT,
      fontSize: 12,
      fontWeight: 300,
      marginTop: 10,
      marginRight: 2,
      overflow: "hidden",
      wordBreak: "break-word",
    },
    tags: {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "center",
      marginTop: 12,
      marginBottom: 12,
      gap: 8,
    },
    tagsEmptyView: {
      color: Color.BLACK,
      fontSize: 10,
      fontWeight: 400,
      fontStyle: "italic",
    },
    tag: {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "center",
      fontSize: 10,
      color: Color.BLACK,
      padding: "6px 2px",
      height: "auto",
    },
    tagIcon: {
      color: Color.BLACK,
      width: 10,
      height: 10,
      marginLeft: 8,
    },
    tagLabel: {
      marginLeft: 2,
      color: Color.BLACK,
      fontSize: 11,
      fontWeight: 400,
      lineHeight: "11px",
    },
    active: {},
    draft: {},
    released: {},
    deprecated: {},
    decommissioned: {},
    inProgressIcon: {
      color: `${Color.SIGNIFY_GREEN} !important`,
      animation: `$rotation 2s infinite linear`,
    },
    failedIcon: {
      color: `${Color.RED2} !important`,
      marginTop: -2,
    },
    failedLabel: {
      color: Color.RED2,
    },
    "@keyframes rotation": {
      from: {
        transform: "rotate(0deg)",
      },
      to: {
        transform: "rotate(359deg)",
      },
    },
  });

export default styles;
