import React from "react";
import classnames from "classnames";
import { dataSetsView as styles } from "../components/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { DataSetsList, DataSetsListActions, DataSetsListColumn, DataSetsListModel } from "@components";

interface Model extends DataSetsListModel {
  title?: string;
  className?: string;
}

interface Actions extends DataSetsListActions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

const DataSetsViewContainer = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title,
    ...otherProps
  } = props;

  return (
    <div className={classnames("dataSetsView", classes.container)}>
      <label className={classnames("title", classes.title)}>
        {title}
      </label>
      <div className={classnames("dataSetContainer", classes.dataSetContainer)}>
        <DataSetsList
          {...otherProps}
          columns={[DataSetsListColumn.NAME, DataSetsListColumn.TYPE, DataSetsListColumn.ACCOUNT]}
          sortByColumn={DataSetsListColumn.NAME}
          sortOrderAscending={true}
          tableLayoutFixed={false}
          className={classnames(className)}
          target="_blank"
          rel="noopener noreferrer"
          selectable={true}
          selectAllDisabled={true}
          showSummary={true}
          showIcon={false}
          showCreateButton={false}
          showSearch={true}
          actions={[]}
          showMoreInfoLabel="Data Set Details"
        />
      </div>
    </div>
  );
});

export default DataSetsViewContainer;
