import React from "react";
import { Policy } from "@data";
import { isEmptyString } from "@util";
import { connect } from "react-redux";
import AppSchema from "@schemas";
import capitalize from "lodash/capitalize";
import { PolicyBulkRequest } from "@data/PolicyBulkRequest";
import { setPolicyBulkRequest, setPolicyName, setSelectedPolicies } from "../actions";
import SelectPolicy, { Actions, Model } from "../components/SelectPolicy";
import {
  PolicyOperation,
  PolicyOperationPrincipal,
  useAccountPolicies,
  useAccountPolicyThatIsNotAttachedToPrincipal
} from "@hooks";
import {
  getPrincipalId,
  getOperation,
  getPrincipalType,
  getPolicyName,
  isCustomPolicyViewSelected,
  getSelectedPolicies,
} from "../selectors";

interface ContainerModel extends Model {
  principalId?: string;
  policyName?: string;
  principalType?: PolicyOperationPrincipal;
  operation?: PolicyOperation;
  hidden?: boolean;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const SelectPolicyToAttachToPrincipal = (props: Props) => {

  const {
    principalId = "",
    policyName = "",
    principalType = PolicyOperationPrincipal.SERVICE ,
    hidden,
    ...otherProps
  } = props;

  const [attachedPolicyNames, setAttachedPolicyNames] = React.useState<string[]>([]);

  const [ model, actions ] =
    useAccountPolicyThatIsNotAttachedToPrincipal(principalType, principalId, attachedPolicyNames);

  const { policies: items, excludedPolicyNames, ...remainingModel } = model;

  React.useEffect(() => setAttachedPolicyNames(excludedPolicyNames), [excludedPolicyNames, setAttachedPolicyNames]);

  const selectedItems: Policy[] = React.useMemo(() =>
      isEmptyString(policyName) ? [] : [
        new Policy({
          info: {
            name: policyName,
          }
        })],
    [policyName]);

  const noResultsLabel = React.useMemo(() =>
    isEmptyString(principalId) ?
      undefined :
      `${capitalize(principalType)} is attached to all the available Policies`, [principalType, capitalize]);

  const loadingLabel = React.useMemo(() =>
    isEmptyString(principalId) ?
      undefined :
      `Loading policies that can be attached to the ${principalType}...`, [principalType]);

  if (hidden) {
    return null;
  }

  return (
    <SelectPolicy
      {...otherProps}
      {...remainingModel}
      {...actions}
      items={items}
      className={"selectCustomPolicies"}
      selectedItems={selectedItems}
      noResultsLabel={noResultsLabel}
      loadingLabel={loadingLabel}
    />
  );

};

const SelectPolicyToDetachFromPrincipal = (props: Props) => {

  const {
    principalId = "",
    policyName = "",
    principalType = PolicyOperationPrincipal.SERVICE ,
    hidden,
    ...otherProps } = props;

  const [ model, actions ] = useAccountPolicies(principalType, principalId);

  const { policies: items, ...remainingModel } = model;

  const selectedItems: Policy[] = React.useMemo(() =>
      isEmptyString(policyName) ? [] : [
        new Policy({
          info: {
            name: policyName,
          }
        })],
    [policyName]);

  const noResultsLabel = React.useMemo(() =>
    isEmptyString(principalId) ?
      undefined :
      `There are no managed policies attached to this ${principalType}`, [principalType]);

  const loadingLabel = React.useMemo(() =>
    isEmptyString(principalId) ?
      undefined :
      `Loading policies that can be attached to the ${principalType}...`, [principalType]);

  if (hidden) {
    return null;
  }

  return (
    <SelectPolicy
      {...otherProps}
      {...remainingModel}
      {...actions}
      items={items}
      className={"selectCustomPolicies"}
      selectedItems={selectedItems}
      noResultsLabel={noResultsLabel}
      loadingLabel={loadingLabel}
    />
  );

};

const SelectPolicyContainer = (props: Props) => {

  const { operation, ...otherProps } = props;

  if (operation === PolicyOperation.ATTACH ) {
    return (<SelectPolicyToAttachToPrincipal {...otherProps} />);
  } else {
    return (<SelectPolicyToDetachFromPrincipal {...otherProps} />);
  }
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  principalId: getPrincipalId(state),
  policyName: getPolicyName(state),
  principalType: getPrincipalType(state),
  operation: getOperation(state),
  hidden: !isCustomPolicyViewSelected(state),
  selectedPolicies: getSelectedPolicies(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setSelectedPolicy: (policy: Policy = Policy.EMPTY) => dispatch(setPolicyName(policy.getName())),
  setPolicyBulkRequest: (req: PolicyBulkRequest[]) => dispatch(setPolicyBulkRequest(req)),
  setSelectedPolicies: (items: Policy[]) => dispatch(setSelectedPolicies(items)),
  ...ownProps
});

export default connect<ContainerModel, Actions, ContainerModel & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(SelectPolicyContainer);
