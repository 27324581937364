import React from "react";
import DataLakeFilePath, {
  DataLakeFilePathActions,
  DataLakeFilePathModel,
} from "../DataLakeFilePath";
import classnames from "classnames";
import DataLakeFileBrowserFiltersMenu, {
  DataLakeFileBrowserFiltersMenuActions,
  DataLakeFileBrowserFiltersMenuModel,
} from "../DataLakeFileBrowserFiltersMenu";
import RefreshButton from "@components/refresh-button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface DataLakeFileBrowserHeaderModel extends
  DataLakeFileBrowserFiltersMenuModel, DataLakeFilePathModel {

  className?: string;
  disabled?: boolean;
  showLoadingIndicator?: boolean;
  showFiltersMenu?: boolean;
  showRefreshButton?: boolean;
}

export interface DataLakeFileBrowserHeaderActions extends
  DataLakeFileBrowserFiltersMenuActions, DataLakeFilePathActions {

  refresh?: () => void;
  openMenu?: () => void;
  closeMenu?: () => void;
}

type Model = DataLakeFileBrowserHeaderModel;
type Actions = DataLakeFileBrowserHeaderActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataLakeFileBrowserHeader = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    disabled,
    showLoadingIndicator,
    showFiltersMenu,
    showRefreshButton,
    refresh,
    openMenu,
    closeMenu,
    children,
    ...otherProps
  } = props;

  return (
    <div className={classnames("dataLakeFileBrowserHeader", className, classes.container)}>
      <DataLakeFileBrowserFiltersMenu
        {...otherProps}
        className={classnames("filtersMenu", classes.filtersMenu)}
        disabled={showLoadingIndicator || disabled}
        open={showFiltersMenu}
        openMenu={openMenu}
        closeMenu={closeMenu}
      />
      <DataLakeFilePath
        {...otherProps}
        className={classnames("breadcrumbs", classes.breadcrumbs)}
      />
      {showRefreshButton && (
        <RefreshButton
          className={classnames("refreshButton", classes.refreshButton)}
          iconClassName={classnames("refreshButtonIcon", classes.refreshButtonIcon)}
          loadingIndicatorSize={20}
          loading={showLoadingIndicator}
          disabled={disabled}
          refresh={refresh}
        />
      )}
      {children}
    </div>
  );
});

export default DataLakeFileBrowserHeader;
