import React from "react";
import classnames from "classnames";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles, tableBodyCell, tableBodyRow } from "./styles";

const TableBodyRow = withStyles(tableBodyRow)(TableRow);
const TableBodyCell = withStyles(tableBodyCell)(TableCell);

export const TableFooterRowView = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  columnClassName?: string,
  numColumns?: number,
  children?: React.ReactNode,
}) => {

  const { className, columnClassName, numColumns = 0, children } = props;

  if (numColumns <= 0 || !children) {
    return null;
  }

  return (
    <TableBodyRow className={classnames("tableFooterRow", className)}>
      <TableBodyCell
        className={classnames("tableFooterColumn", columnClassName)}
        colSpan={numColumns}
      >
        {children}
      </TableBodyCell>
    </TableBodyRow>
  );
});

export default TableFooterRowView;
