import { Record } from "immutable";

export interface QualityReportCVEVulnerabilitiesAttributes {
  critical: number;
  high: number;
  medium: number;
  low: number;
  none: number;
}

export class QualityReportCVEVulnerabilities extends Record({
  critical: 0,
  high: 0,
  medium: 0,
  low: 0,
  none: 0,
}) implements QualityReportCVEVulnerabilitiesAttributes {

  public static EMPTY: QualityReportCVEVulnerabilities = new QualityReportCVEVulnerabilities();

  public readonly critical: number;
  public readonly high: number;
  public readonly medium: number;
  public readonly low: number;
  public readonly none: number;

  public getNumCriticalVulnerabilities(): number {
    return Math.max(0, this.critical);
  }

  public getNumHighVulnerabilities(): number {
    return Math.max(0, this.high);
  }

  public getNumMediumVulnerabilities(): number {
    return Math.max(0, this.medium);
  }

  public getNumLowVulnerabilities(): number {
    return Math.max(0, this.low);
  }

  public getNumNoneVulnerabilities(): number {
    return Math.max(0, this.none);
  }
}

export default QualityReportCVEVulnerabilities;
