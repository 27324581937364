import React from "react";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { view as styles } from "./styles";
import EditRedirectUriDialog from "./EditRedirectUriDialog";
import RemoveRedirectUriDialog from "./RemoveRedirectUriDialog";
import { RedirectUrisTable } from "@components/redirect-uri-table";
import { noop } from "@util";
import { Autocomplete } from "../../../components/autocomplete/Autocomplete";
import { ApplicationScopes } from "@modules/applicationWizardRegional/components/ApplicationInfoView";

export const DEFAULT_APPLICATION_SCOPE_VALUES = [
  ApplicationScopes.OPENID,
  ApplicationScopes.EMAIL,
  ApplicationScopes.PROFILE,
];

export interface Model {
  title?: string;
  uris?: string[];
  uri?: string;
  editAddTitle?: string;
  editDialogOpen?: boolean;
  deleteDialogOpen?: boolean;
  scope?: string[];
  scopeValues?: ApplicationScopes[];
}

export interface Actions {
  addUri?: () => void;
  setUri?: (uri: string) => void;
  editRedirectUri?: (uri: string) => void;
  removeRedirectUri?: (uri: string) => void;
  confirmAddEdit?: (uri: string) => void;
  confirmRemove?: (uri: string) => void;
  cancelEdit?: () => void;
  cancelRemove?: () => void;
  setScope?: (scope: string[]) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const OpenIdConnectView = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Enter Open Id Connect information",
    uris = [],
    uri = "",
    editAddTitle = "Add Redirect Uri",
    editDialogOpen = false,
    deleteDialogOpen = false,
    scope = [],
    scopeValues = DEFAULT_APPLICATION_SCOPE_VALUES,
    addUri = noop,
    setUri = noop,
    setScope = noop,
    editRedirectUri = noop,
    removeRedirectUri = noop,
    confirmAddEdit = noop,
    confirmRemove = noop,
    cancelEdit = noop,
    cancelRemove = noop,
    children,
  } = props;

  return(
    <div className={classnames("openIdConnectView", classes.container)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      <Autocomplete
        className={classnames("applicationScopes", classes.autocomplete)}
        label="Scope(s)"
        placeholder="Add additional Scope(s) (optional)"
        noOptionsLabel=""
        options={scopeValues}
        value={scope}
        setValue={setScope}
        customValuesDisabled={true}
      />
      <RedirectUrisTable
        className="redirectUriTable"
        uris={uris}
        addUri={addUri}
        editRedirectUri={editRedirectUri}
        removeRedirectUri={removeRedirectUri}
      />
      <EditRedirectUriDialog
        uri={uri}
        open={editDialogOpen}
        title={editAddTitle}
        setUri={setUri}
        save={confirmAddEdit}
        cancel={cancelEdit}
      />
      <RemoveRedirectUriDialog
        open={deleteDialogOpen}
        item={uri}
        confirm={confirmRemove}
        cancel={cancelRemove}
      />
      {children}
    </div>
  );
});

export default OpenIdConnectView;
