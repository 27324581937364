import createStyles from "@material-ui/core/styles/createStyles";
import { AuditEventIcon } from "@components/audit-events-list/styles";
import Color from "@components/styles/Color";

export { AuditEventIcon };

export const appliedFilters = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
    },
    header: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    title: {
      color: Color.TEXT,
      margin: 0,
      fontWeight: 300,
      fontSize: 18,
    },
    searchFilters: {
      marginTop: 8,
    },
    clearFiltersButton: {
      height: "auto",
      minWidth: "auto",
      marginTop: 2,
      marginLeft: 8,
      padding: "4px 8px",
    },
    clearFiltersButtonLabel: {
      fontSize: 13,
      fontWeight: 500,
      textTransform: "uppercase",
    },
    disabledButton: {
      "& .icon, & .label": {
        color: Color.GREY3,
      },
    },
  });

export const auditEventFilters = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      padding: 25,
      minWidth: 500,
    },
    autocomplete: {
      flex: "1 0 auto",
      marginTop: 25,
      "&:first-child": {
        marginTop: 0,
      },
    },
    footer: {
      marginTop: 16,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "space-between",
    },
    limit: {
      "& label + .dropdownMenu": {
        marginTop: 8,
      },
    },
    clearFiltersButton: {
      height: "auto",
      minWidth: "auto",
      padding: "6px 8px",
      marginTop: 5,
    },
    clearFiltersButtonLabel: {
      textTransform: "uppercase",
    },
  });

export const filtersMenu = () =>
  createStyles({
    container: {
    },
    openButton: {
    },
    popover: {
    },
  });

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    controls: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    summary: {
    },
    refreshButton: {
    },
    filtersMenu: {
      marginLeft: 15,
    },
    auditEventFilters: {
    },
    filterByDates: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      paddingTop: 8,
      paddingBottom: 8,
    },
    filterByStartDate: {
    },
    filterByEndDate: {
    },
    datePicker: {
      flex: "1 0 auto",
      marginLeft: 8,
      "&:first-child": {
        marginLeft: 15,
      },
    },
    appliedFilters: {
      marginTop: 8,
    },
    list: {
    },
  });

export default styles;
