import React from "react";
import classnames from "classnames";
import MuiIconButton, { IconButtonProps } from "@material-ui/core/IconButton";

export interface CustomIconButtonProps extends IconButtonProps {
}

export const IconButton = React.forwardRef((props: CustomIconButtonProps, ref: React.Ref<any>) => {

  const {
    className,
    children,
    ...otherProps
  } = props;

  return (
    <MuiIconButton
      {...otherProps}
      className={classnames("iconButton", className)}
      ref={ref}
    >
      {children}
    </MuiIconButton>
  );
});

export default IconButton;
