import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { getNoteErrorMessage, isAdminUser } from "../selectors";
import CreateNoteView from "./CreateNoteView";
import NotesListView from "./NotesListView";
import { Model, AdminNotesView } from "../components/AdminNotes";

interface ContainerModel extends Model {
  hidden?: boolean;
  adminUser?: boolean;
}

const AdminNotesContainer = (props: ContainerModel) => {

  const { adminUser, hidden, ...otherProps } = props;

  if (hidden) {
    return null;
  }

  return (
    <AdminNotesView {...otherProps}>
      {adminUser && <CreateNoteView />}
      <NotesListView />
    </AdminNotesView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  adminUser: isAdminUser(state),
  errorMessage: getNoteErrorMessage(state),
  ...ownProps
});

export default connect<ContainerModel, {}, ContainerModel>(
  mapStateToProps,
)(AdminNotesContainer);
