import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    row: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "space-between",
      marginTop: 25,
      "&:first-child": {
        marginTop: 0,
      },
    },
    card: {
      flex: "1 1 0",
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      marginLeft: 25,
      "&:first-child": {
        marginLeft: 0,
      },
    },
  });

export default styles;
