import ProfileAttributeIcon from "@material-ui/icons/PermContactCalendar";
import { Color } from "@Color";
import createStyles from "@material-ui/core/styles/createStyles";

export const primaryColor = Color.MODULES;

export const PrimaryIcon = ProfileAttributeIcon;

export const profileAttributeDefinitions = () =>
  createStyles({
    profileAttributeDefinitionActions: {
      justifySelf: "flex-end",
      marginLeft: "auto",
    },
  });

export const styles = () =>
  createStyles({
    container: {
    },
    list: {
      "& .tableHeaderColumn.dataType, & .tableBodyColumn.dataType": {
        paddingLeft: 25,
        width: 125,
      },
      "& .tableHeaderColumn.maxLength, & .tableBodyColumn.maxLength": {
        paddingLeft: 25,
        width: 100,
      },
      "& .tableHeaderColumn.optional, & .tableBodyColumn.optional": {
        paddingLeft: 25,
        width: 100,
      },
      "& .tableHeaderColumn.hidden, & .tableBodyColumn.hidden": {
        paddingLeft: 25,
        width: 100,
      },
      "& .tableHeaderColumn.immutable, & .tableBodyColumn.immutable": {
        paddingLeft: 25,
        width: 100,
      },
    },
    profileAttributeDefinitionActions: {
      justifySelf: "flex-end",
      marginLeft: "auto",
    },
  });

export default styles;
