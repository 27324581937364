import ModuleIcon from "@material-ui/icons/Security";
import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@components/styles/Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    container: {
      color: primaryColor,
    },
    createButton: {
      marginLeft: "auto",
    },
    list: {
    },
  });

export default styles;
