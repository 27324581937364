import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { dataSetRequestView as styles } from "./styles";

export interface Model {
  children?: React.ReactNode;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DataSetRequestView = withStyles(styles)((props: Props) => {

  const {
    classes,
    children,
  } = props;

  return (
    <div className={classnames("dataSetRequest", classes.container)}>
      <h1 className={classnames("dataSetRequestTitle", classes.subtitle)}>
        Related Data Set Request Information
      </h1>
      {children}
    </div>
  );
});

export default DataSetRequestView;
