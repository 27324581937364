import React from "react";
import classnames from "classnames";
import TextField from "@components/text-field";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { userFilterDialog as styles, textField } from "./styles";
import { enterKeyHandler, formEventHandler, noop } from "@util";
import { ConfirmationDialog } from "@components/confirmation-dialog/ConfirmationDialog";
import { SearchFilter } from "@data";

const AttributeNameTextField = withStyles(textField)(TextField);
const AttributeCriteriaTextField = withStyles(textField)(TextField);

export interface Model {
  open?: boolean;
  searchFilter?: SearchFilter;
}

export interface Action {
  cancel?: () => void;
  addSearchFilter?: (searchFilter: SearchFilter) => void;
}

type Props = WithStyles<typeof styles> & Model & Action;

export const UserFilterDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    open,
    searchFilter: initialSearchFilter = SearchFilter.EMPTY,
    cancel = noop,
    addSearchFilter = noop,
  } = props;

  const [searchFilter, setSearchFilter] = React.useState(initialSearchFilter);

  const continueButtonDisabled = React.useMemo(() =>
    !(searchFilter.hasKey() && searchFilter.hasValue()),
    [searchFilter]);

  const setKey = React.useCallback((key: string) => setSearchFilter(new SearchFilter({
    ...searchFilter.toJS(),
    key,
  })), [setSearchFilter, searchFilter]);

  const setValue = React.useCallback((value: string) => setSearchFilter(new SearchFilter({
    ...searchFilter.toJS(),
    value,
  })), [setSearchFilter, searchFilter]);

  const save = React.useCallback(() => {
    addSearchFilter(searchFilter);
    setSearchFilter(SearchFilter.EMPTY);
  }, [addSearchFilter, searchFilter]);

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  return (
    <ConfirmationDialog
      className={classnames("addFilterDialog", classes.dialogContainer)}
      title="Filter by profile attributes"
      continueButtonLabel="Add Filter"
      continueButtonDisabled={continueButtonDisabled}
      open={open}
      cancel={cancel}
      confirm={save}
    >
      <div className={classnames("userFilterDialog", classes.container)}>
        <div className={classnames("attributeKeyContainer", classes.attributeKeyContainer)}>
          <AttributeNameTextField
            className={classnames("attributeName", classes.inputField)}
            autoFocus={true}
            autoComplete="off"
            label="Attribute Name"
            placeholder="Attribute Name"
            name="attributeName"
            value={searchFilter.getKey()}
            fullWidth={true}
            variant="outlined"
            margin="none"
            onChange={formEventHandler(setKey)}
            InputLabelProps={inputLabelProps}
          />
          <AttributeCriteriaTextField
            className={classnames("attributeCriteria", classes.inputField)}
            autoFocus={false}
            autoComplete="off"
            label="Attribute Criteria"
            placeholder="Attribute Criteria"
            name="attributeCriteria"
            value={searchFilter.getValue()}
            fullWidth={true}
            variant="outlined"
            margin="none"
            onChange={formEventHandler(setValue)}
            onKeyDown={enterKeyHandler(save)}
            InputLabelProps={inputLabelProps}
          />
        </div>
      </div>
    </ConfirmationDialog>
  );
});

export default UserFilterDialog;
