import { Module } from "@data";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./styles";
import view from "./DigitalShadowLayoutsView";
import { isDigitalShadowEnabled } from "@util";

export const digitalShadowLayoutsModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Shadow Layouts",
  title: "Shadow Layout Management",
  className: "digitalShadowLayoutsModule",
  primaryColor,
  icon,
  view,
  backButton: null,
  disabled: !isDigitalShadowEnabled(),
});

export default digitalShadowLayoutsModule;
