import moment from "moment";
import { v4 as uuid } from "uuid";
import { batch } from "react-redux";
import { IdentityType } from "@data";
import { AppSchema } from "@main/schemas";
import { DEFAULT_STATE, LoginActionType } from "./reducers";
import { createUserSession } from "@main/actions";
import { DEFAULT_REDIRECT_URI, ERROR_REQUIRED_FIELD } from "./constants";
import {
  equalsIgnoreCase,
  getStringValue,
  identityAction,
  isEmptyString,
  ReduxAction,
  removeUrlParameter,
} from "@util";
import * as LoginHelpers from "./helpers";
import {
  FederatedLoginSessionInfo,
  LoginResponse,
  PortalClient,
  RestClientError,
  UserIdmLegacyClient,
} from "@network";
import {
  getAccount,
  getErrorUrlParameter,
  getFederatedLoginResponseAccountId,
  getFederatedLoginResponseCode,
  getFederatedLoginResponseRedirectUri,
  getFederatedLoginResponseUsername,
  getFederatedLoginUrl,
  getIdentityType,
  getNonce,
  getPassword,
  getServiceAccountId,
  getServiceId,
  getToken,
  getUsername,
  hasWhitelistedAccounts,
  isCredentialsViewVisible,
  isFederatedLoginResponseAvailable,
  isFederatedLoginSupported,
  isRememberMeChecked,
  isRememberServiceChecked,
  isValidAccount,
  isValidPassword,
  isValidServiceAccountId,
  isValidServiceId,
  isValidServiceSecret,
  isValidToken,
  isValidUsername,
} from "./selectors";
import { SecurityServiceRegionalClientActionType } from "@hooks";

type Action = ReduxAction<LoginActionType>;

export const setNonce = identityAction<LoginActionType, string>(
  LoginActionType.SET_NONCE, DEFAULT_STATE.nonce);

export const setUsername = identityAction<LoginActionType, string>(
  LoginActionType.SET_USERNAME, DEFAULT_STATE.username);

export const setUsernameError = identityAction<LoginActionType, string>(
  LoginActionType.SET_USERNAME_ERROR, DEFAULT_STATE.usernameError);

export const setAccount = identityAction<LoginActionType, string>(
  LoginActionType.SET_ACCOUNT, DEFAULT_STATE.account);

export const setAccountError = identityAction<LoginActionType, string>(
  LoginActionType.SET_ACCOUNT_ERROR, DEFAULT_STATE.accountError);

export const setPassword = identityAction<LoginActionType, string>(
  LoginActionType.SET_PASSWORD, DEFAULT_STATE.password);

export const setPasswordError = identityAction<LoginActionType, string>(
  LoginActionType.SET_PASSWORD_ERROR, DEFAULT_STATE.passwordError);

export const setToken = identityAction<LoginActionType, string>(
  LoginActionType.SET_TOKEN, DEFAULT_STATE.token);

export const setTokenError = identityAction<LoginActionType, string>(
  LoginActionType.SET_TOKEN_ERROR, DEFAULT_STATE.tokenError);

export const setShowPassword = identityAction<LoginActionType, boolean>(
  LoginActionType.SET_SHOW_PASSWORD, DEFAULT_STATE.showPassword);

export const setRememberMe = identityAction<LoginActionType, boolean>(
  LoginActionType.SET_REMEMBER_ME, DEFAULT_STATE.rememberMe);

export const setShowCredentialsView = identityAction<LoginActionType, boolean>(
  LoginActionType.SET_SHOW_CREDENTIALS_VIEW, DEFAULT_STATE.showCredentialsView);

export const setShowTermsDialog = identityAction<LoginActionType, boolean>(
  LoginActionType.SET_SHOW_TERMS_DIALOG, DEFAULT_STATE.showTermsDialog);

export const setShowLoadingIndicator = identityAction<LoginActionType, boolean>(
  LoginActionType.SET_SHOW_LOADING_INDICATOR, DEFAULT_STATE.showLoadingIndicator);

export const setShowErrorMessage = identityAction<LoginActionType, boolean>(
  LoginActionType.SET_SHOW_ERROR_MESSAGE, DEFAULT_STATE.showErrorMessage);

export const setErrorMessage = identityAction<LoginActionType, string>(
  LoginActionType.SET_ERROR_MESSAGE, DEFAULT_STATE.errorMessage);

export const setIdentityType = identityAction<LoginActionType, IdentityType>(
  LoginActionType.SET_IDENTITY_TYPE, DEFAULT_STATE.identityType);

export const setServiceId = identityAction<LoginActionType, string>(
  LoginActionType.SET_SERVICE_ID, DEFAULT_STATE.serviceId);

export const setServiceIdError = identityAction<LoginActionType, string>(
  LoginActionType.SET_SERVICE_ID_ERROR, DEFAULT_STATE.serviceIdError);

export const setMfaCode = identityAction<LoginActionType, string>(
  LoginActionType.SET_MFA_CODE, DEFAULT_STATE.mfaCode);

export const setMfaCodeError = identityAction<LoginActionType, string>(
  LoginActionType.SET_MFA_CODE_ERROR, DEFAULT_STATE.mfaCodeError);

export const setServiceAccountId = identityAction<LoginActionType, string>(
  LoginActionType.SET_SERVICE_ACCOUNT_ID, DEFAULT_STATE.serviceAccountId);

export const setServiceAccountIdError = identityAction<LoginActionType, string>(
  LoginActionType.SET_SERVICE_ACCOUNT_ID_ERROR, DEFAULT_STATE.serviceAccountIdError);

export const setServiceSecret = identityAction<LoginActionType, string>(
  LoginActionType.SET_SERVICE_SECRET, DEFAULT_STATE.serviceSecret);

export const setServiceSecretError = identityAction<LoginActionType, string>(
  LoginActionType.SET_SERVICE_SECRET_ERROR, DEFAULT_STATE.serviceSecretError);

export const setRememberService = identityAction<LoginActionType, boolean>(
  LoginActionType.SET_REMEMBER_SERVICE, DEFAULT_STATE.rememberService);

export const setRedirectUri = identityAction<LoginActionType, string>(
  LoginActionType.SET_REDIRECT_URI, DEFAULT_STATE.redirectUri);

export const redirectToFederatedLoginUrl = (value: string = ""): Action => ({
  type: LoginActionType.REDIRECT_TO_FEDERATED_LOGIN_URL,
  value: value || "",
});

export const federatedLoginFailed = (error: string = ""): Action => ({
  type: LoginActionType.FEDERATED_LOGIN_FAILED,
  value: error || "",
});

export const fetchOidcTokensRequest = (value: string = ""): Action => ({
  type: LoginActionType.FETCH_OIDC_TOKENS_REQUEST,
  value: value || "",
});

export const fetchOidcTokensSuccess = (value: string = ""): Action => ({
  type: LoginActionType.FETCH_OIDC_TOKENS_SUCCESS,
  value: value || "",
});

export const fetchOidcTokensFailed = (error: string): Action => ({
  type: LoginActionType.FETCH_OIDC_TOKENS_FAILED,
  value: error || "",
});

export const securityManagerLoginRequest = (value: string = ""): Action => ({
  type: LoginActionType.SECURITY_MANAGER_LOGIN_REQUEST,
  value: value || "",
});

export const securityManagerLoginSuccess = (value: string = ""): Action => ({
  type: LoginActionType.SECURITY_MANAGER_LOGIN_SUCCESS,
  value: value || "",
});

export const securityManagerLoginFailed = (error: string): Action => ({
  type: LoginActionType.SECURITY_MANAGER_LOGIN_FAILED,
  value: error || "",
});

export const authenticateServiceRequest = identityAction<SecurityServiceRegionalClientActionType>(
  SecurityServiceRegionalClientActionType.AUTHENTICATE_SERVICE_REGIONAL_REQUEST, "");

export const authenticateServiceSuccess = identityAction<SecurityServiceRegionalClientActionType>(
  SecurityServiceRegionalClientActionType.AUTHENTICATE_SERVICE_REGIONAL_SUCCESS, "");

export const authenticateServiceFailed = identityAction<SecurityServiceRegionalClientActionType>(
  SecurityServiceRegionalClientActionType.AUTHENTICATE_SERVICE_REGIONAL_FAILED, "");

export const showCredentialsView = (): Action => setShowCredentialsView(true);
export const hideCredentialsView = (): Action => setShowCredentialsView(false);

export const showTermsDialog = (): Action => setShowTermsDialog(true);
export const hideTermsDialog = (): Action => setShowTermsDialog(false);

export const showLoadingIndicator = (): Action => setShowLoadingIndicator(true);
export const hideLoadingIndicator = (): Action => setShowLoadingIndicator(false);

export const clearErrorMessage = (): Action => setErrorMessage("");
export const clearUsernameError = (): Action => setUsernameError("");
export const clearAccountError = (): Action => setAccountError("");
export const clearPasswordError = (): Action => setPasswordError("");
export const clearTokenError = (): Action => setTokenError("");

export const clearServiceIdError = (): Action => setServiceIdError("");
export const clearServiceAccountIdError = (): Action => setServiceAccountIdError("");
export const clearServiceSecretError = (): Action => setServiceSecretError("");
export const clearMfaCodeError = (): Action => setMfaCodeError("");

export const updateServiceId = (serviceId: string = "") => (dispatch: any) => batch(() => {
  dispatch(clearServiceIdError());
  return dispatch(setServiceId(serviceId));
});

export const updateMfaCode = (mfaCode: string = "") => (dispatch: any) => batch(() => {
  dispatch(clearMfaCodeError());
  return dispatch(setMfaCode(mfaCode));
});

export const updateServiceAccountId = (accountId: string = "") => (dispatch: any) => batch(() => {
  dispatch(clearServiceAccountIdError());
  return dispatch(setServiceAccountId(accountId));
});

export const updateServiceSecret = (secret: string = "") => (dispatch: any) => batch(() => {
  dispatch(clearServiceSecretError());
  return dispatch(setServiceSecret(secret));
});

export const showErrorMessage = (errorMessage: string) => (dispatch: any) => batch(() => {
  dispatch(setErrorMessage(errorMessage));
  return dispatch(setShowErrorMessage(true));
});

export const hideErrorMessage = (): Action => setShowErrorMessage(false);

export const updateToken = (token: string = "") => (dispatch: any) => batch(() => {
  dispatch(clearTokenError());
  return dispatch(setToken(token));
});

export const updatePassword = (password: string = "") => (dispatch: any) => batch(() => {
  dispatch(clearPasswordError());
  return dispatch(setPassword(password));
});

export const resetCredentials = () => (dispatch: any) => batch(() => {
  dispatch(updatePassword(""));
  return dispatch(updateToken(""));
});

export const updateAccount = (account: string = "") =>
  (dispatch: any, getState: () => AppSchema) => batch(() => {

    // If federated login is enabled, check if credentials view should be visible
    if (hasWhitelistedAccounts()) {

      const state = getState();

      // Credentials view should only be shown once user stops updating username & account fields
      if (isCredentialsViewVisible(state) && !equalsIgnoreCase(account, getAccount(state))) {
        dispatch(hideCredentialsView());
        dispatch(resetCredentials());
      }
    }

    dispatch(clearAccountError());
    return dispatch(setAccount(account));
  });

export const updateUsername = (username: string = "") =>
  (dispatch: any, getState: () => AppSchema) => batch(() => {

    // If federated login is enabled, check if credentials view should be visible
    if (hasWhitelistedAccounts()) {

      const state = getState();

      // Credentials view should only be shown once user stops updating username & account fields
      if (isCredentialsViewVisible(state) && !equalsIgnoreCase(username, getUsername(state))) {
        dispatch(hideCredentialsView());
        dispatch(resetCredentials());
      }
    }

    dispatch(clearUsernameError());
    return dispatch(setUsername(username));
  });

export const toggleRememberMe = (rememberMe: boolean) =>
  (dispatch: any, getState: () => AppSchema) => {

    if (!rememberMe) {
      LoginHelpers.saveUsername("");
      LoginHelpers.saveAccount("");
    } else {
      LoginHelpers.saveUsername(getUsername(getState()));
      LoginHelpers.saveAccount(getAccount(getState()));
    }

    return dispatch(setRememberMe(rememberMe));
  };

export const toggleRememberService = (rememberService: boolean) =>
  (dispatch: any, getState: () => AppSchema) => {

    if (!rememberService) {
      LoginHelpers.saveServiceId("");
      LoginHelpers.saveServiceAccountId("");
    } else {
      LoginHelpers.saveServiceId(getServiceId(getState()));
      LoginHelpers.saveServiceAccountId(getServiceAccountId(getState()));
    }

    return dispatch(setRememberService(rememberService));
  };

export const loginWithSecurityManager = () => (dispatch: any, getState: () => AppSchema) => {

  if (!isValidPassword(getState())) {
    return dispatch(setPasswordError(ERROR_REQUIRED_FIELD));
  } else {
    dispatch(clearPasswordError());
  }

  if (!isValidToken(getState())) {
    return dispatch(setTokenError(ERROR_REQUIRED_FIELD));
  } else {
    dispatch(clearTokenError());
  }

  const state = getState();
  const token = getStringValue(getToken(state));
  const password = getPassword(state); // Do not trim because a space is a valid password character
  const username = getStringValue(getUsername(state));
  const accountId = getStringValue(getAccount(state));

  batch(() => {
    dispatch(showLoadingIndicator());
    dispatch(hideErrorMessage());
    dispatch(clearErrorMessage());
    dispatch(securityManagerLoginRequest(accountId));
  });

  return UserIdmLegacyClient.login(accountId, username, password, token)
    .then((response: LoginResponse) => {

      dispatch(securityManagerLoginSuccess(accountId));
      dispatch(hideLoadingIndicator());
      return dispatch(createUserSession(response));

    }, (response: RestClientError) => {

      const { error, analytic } = response;

      batch(() => {
        dispatch(showErrorMessage(error));
        dispatch(hideLoadingIndicator());
      });

      return dispatch(securityManagerLoginFailed(`${accountId}:${analytic}`));
    });
};

export const loginWithFederationProvider = () => (dispatch: any, getState: () => AppSchema) => {

  dispatch(showLoadingIndicator());

  dispatch(setNonce(uuid()));

  const state = getState();
  const accountId = getStringValue(getAccount(state));
  const federatedLoginUrl = getFederatedLoginUrl(state);

  // Delay redirect to ensure that redux middleware has been given enough
  // time to process any events still in queue
  setTimeout(() => {
    LoginHelpers.redirectToUrl(federatedLoginUrl);
  }, 50);

  return dispatch(redirectToFederatedLoginUrl(accountId));
};

export const login = () => (dispatch: any, getState: () => AppSchema) => {

  if (!isValidUsername(getState())) {
    return dispatch(setUsernameError(ERROR_REQUIRED_FIELD));
  } else {
    dispatch(clearUsernameError());
  }

  if (!isValidAccount(getState())) {
    return dispatch(setAccountError(ERROR_REQUIRED_FIELD));
  } else {
    dispatch(clearAccountError());
  }

  if (isRememberMeChecked(getState())) {
    LoginHelpers.saveUsername(getUsername(getState()));
    LoginHelpers.saveAccount(getAccount(getState()));
  } else {
    LoginHelpers.saveUsername("");
    LoginHelpers.saveAccount("");
  }

  if (!isFederatedLoginSupported(getState())) {
    if (!isCredentialsViewVisible(getState())) {
      return dispatch(showCredentialsView());
    } else {
      return dispatch(loginWithSecurityManager());
    }
  }

  return dispatch(loginWithFederationProvider());
};

export const startSession = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accountId = getAccount(state);
  const code = getFederatedLoginResponseCode(state);

  batch(() => {
    dispatch(showLoadingIndicator());
    dispatch(hideErrorMessage());
    dispatch(clearErrorMessage());
    dispatch(fetchOidcTokensRequest(accountId));
  });

  return PortalClient.getFederatedLoginSessionInfo(code)
    .then((response: FederatedLoginSessionInfo) => {

      const { expiresIn, ...otherProps } = response;

      const accessTokenExpiry = moment().add(expiresIn, "seconds").toISOString();

      dispatch(fetchOidcTokensSuccess(accountId));
      dispatch(hideLoadingIndicator());
      return dispatch(createUserSession({ ...otherProps, accessTokenExpiry }));

    }, (response: RestClientError) => {

      const { analytic, error = "Oops, something went wrong. Please try to login again." } = response;

      batch(() => {
        dispatch(showErrorMessage(error));
        dispatch(hideLoadingIndicator());
      });

      return dispatch(fetchOidcTokensFailed(`${accountId}:${analytic}`));
    });
};

export const authenticateService = () => (dispatch: any, getState: () => AppSchema) => {

  if (!isValidServiceId(getState())) {
    return dispatch(setServiceIdError(ERROR_REQUIRED_FIELD));
  } else {
    dispatch(clearServiceIdError());
  }

  if (!isValidServiceAccountId(getState())) {
    return dispatch(setServiceAccountIdError(ERROR_REQUIRED_FIELD));
  } else {
    dispatch(clearServiceAccountIdError());
  }

  if (!isValidServiceSecret(getState())) {
    return dispatch(setServiceSecretError(ERROR_REQUIRED_FIELD));
  } else {
    dispatch(clearServiceSecretError());
  }

  // We do not perform any client-side validation of the MFA field and defer validation to API
  dispatch(clearMfaCodeError());

  const state = getState();
  const serviceId = getStringValue(getServiceId(state));
  const accountId = getStringValue(getServiceAccountId(state));

  if (isRememberServiceChecked(state)) {
    LoginHelpers.saveServiceId(serviceId);
    LoginHelpers.saveServiceAccountId(accountId);
  } else {
    LoginHelpers.saveServiceId("");
    LoginHelpers.saveServiceAccountId("");
  }

  return batch(() => {
    dispatch(showLoadingIndicator());
    dispatch(hideErrorMessage());
    dispatch(clearErrorMessage());
    dispatch(authenticateServiceRequest(accountId));
  });
};

export const reset = () => (dispatch: any) => batch(() => {
  dispatch(setNonce());
  dispatch(setUsername());
  dispatch(setUsernameError());
  dispatch(setAccount());
  dispatch(setAccountError());
  dispatch(setPassword());
  dispatch(setPasswordError());
  dispatch(setToken());
  dispatch(setTokenError());
  dispatch(setShowPassword());
  dispatch(setRememberMe());
  dispatch(setShowCredentialsView());
  dispatch(setShowTermsDialog());
  dispatch(setShowLoadingIndicator());
  dispatch(setShowErrorMessage());
  dispatch(setErrorMessage());
  dispatch(setIdentityType());
  dispatch(setServiceId());
  dispatch(setServiceIdError());
  dispatch(setMfaCode());
  dispatch(setMfaCodeError());
  dispatch(setServiceAccountId());
  dispatch(setServiceAccountIdError());
  dispatch(setServiceSecret());
  dispatch(setServiceSecretError());
  dispatch(setRememberService());
  return dispatch(setRedirectUri());
});

export const initialize = (redirectUri: string = DEFAULT_REDIRECT_URI) =>
  (dispatch: any, getState: () => AppSchema) => batch(() => {

    const state = getState();
    const accountId = getAccount(state);
    const identityType = getIdentityType(state);

    // Determine if we were redirected to portal in error
    const error = getErrorUrlParameter(state);

    // Do not reset the nonce - in case we are returning from the federated login w/ a valid token
    const requestNonce = getNonce(state);

    // If federated login response is not available and the user previously clicked
    // the 'Remember Me' checkbox, populate the username & account id; otherwise,
    // rely on the federated login response state
    const savedUsername = LoginHelpers.getSavedUsername();
    const savedAccount = LoginHelpers.getSavedAccount();
    const rememberMe = !isEmptyString(savedUsername) || !isEmptyString(savedAccount);
    const hasFederatedLoginResponse = isFederatedLoginResponseAvailable(state);
    const federatedLoginResponseUsername = getFederatedLoginResponseUsername(state);
    const federatedLoginResponseAccount = getFederatedLoginResponseAccountId(state);
    const federatedLoginResponseRedirectUri = getFederatedLoginResponseRedirectUri(state);
    const username = hasFederatedLoginResponse ? federatedLoginResponseUsername : savedUsername;
    const account = hasFederatedLoginResponse ? federatedLoginResponseAccount : savedAccount;

    // Restore saved service credentials - if available
    const savedServiceId = LoginHelpers.getSavedServiceId();
    const savedServiceAccountId = LoginHelpers.getSavedServiceAccountId();
    const rememberService = !isEmptyString(savedServiceId) || !isEmptyString(savedServiceAccountId);

    // We do not allow the following federated login related query params to be stored in the redirect uri
    const sanitizedRedirectUri =
      removeUrlParameter( // nonce
        removeUrlParameter( // state
          removeUrlParameter( // id_token
            removeUrlParameter(redirectUri, "code"), // code
            "id_token"),
          "state"),
        "nonce")
        // Do not include the query param suffix if there are no remaining query params
        .split("?").filter(param => !isEmptyString(param)).join("?");

    dispatch(reset());
    dispatch(setNonce(requestNonce));
    dispatch(setUsername(username));
    dispatch(setAccount(account));
    dispatch(setRememberMe(rememberMe));
    dispatch(setIdentityType(identityType));
    dispatch(setServiceId(savedServiceId));
    dispatch(setServiceAccountId(savedServiceAccountId));
    dispatch(setRememberService(rememberService));

    // If we are returning from login success, use the redirectUri embedded in the federated login
    // response; otherwise, store the provided redirectUri so that we can embed it in the federated
    // login state and ensure that we restore the user's preferred path on login success.
    dispatch(setRedirectUri(hasFederatedLoginResponse
      ? federatedLoginResponseRedirectUri : sanitizedRedirectUri));

    // If we were redirected back from federated login with an error, show a message
    // to the reason and track the federated login failure event.
    if (!isEmptyString(error)) {
      if (hasFederatedLoginResponse) {
        dispatch(federatedLoginFailed(`${accountId}:${error}`));
      }
      dispatch(setErrorMessage(LoginHelpers.formatFederatedLoginErrorMessage(error)));
      dispatch(setShowErrorMessage(true));
    }

    // Show all the fields by default if there are no whitelisted accounts
    return dispatch(setShowCredentialsView(!hasWhitelistedAccounts()));
  });
