import { combineReducers } from "redux";
import { Actions, filterAction, Identity } from "@util/ReducerUtil";
import {
  DEFAULT_SCHEMA,
  JsonSchemaDefinition,
  JsonSchemaMetadata,
  JsonSchemaMetadataAttributes,
} from "@data";

export interface Schema {
  location: string;
  breadcrumbs: string[];
  schema: JsonSchemaDefinition;
  metadata: JsonSchemaMetadataAttributes;
  errorMessage: string;
  showAccessDenied: boolean;
  showLoadingIndicator: boolean;
}

export const DEFAULT_STATE: Schema = {
  location: "",
  breadcrumbs: [],
  schema: { ...DEFAULT_SCHEMA },
  metadata: JsonSchemaMetadata.EMPTY.toJS(),
  errorMessage: "",
  showAccessDenied: false,
  showLoadingIndicator: false,
};

export enum ActionType {
  SET_LOCATION = "schema_properties/set_location",
  SET_BREADCRUMBS = "schema_properties/set_breadcrumbs",
  SET_SCHEMA = "schema_properties/set_schema",
  SET_METADATA = "schema_properties/set_metadata",
  SET_ERROR_MESSAGE = "schema_properties/set_error_message",
  SET_SHOW_ACCESS_DENIED = "schema_properties/set_show_access_denied",
  SET_SHOW_LOADING_INDICATOR = "schema_properties/set_show_loading_indicator",
}

const location = filterAction<string>(Actions(
  ActionType.SET_LOCATION))(Identity,
  DEFAULT_STATE.location);

const breadcrumbs = filterAction<string[]>(Actions(
  ActionType.SET_BREADCRUMBS))(Identity,
  DEFAULT_STATE.breadcrumbs);

const schema = filterAction<JsonSchemaDefinition>(Actions(
  ActionType.SET_SCHEMA))(Identity,
  DEFAULT_STATE.schema);

const metadata = filterAction<JsonSchemaMetadataAttributes>(Actions(
  ActionType.SET_METADATA))(Identity,
  DEFAULT_STATE.metadata);

const errorMessage = filterAction<string>(Actions(
  ActionType.SET_ERROR_MESSAGE))(Identity,
  DEFAULT_STATE.errorMessage);

const showAccessDenied = filterAction<boolean>(Actions(
  ActionType.SET_SHOW_ACCESS_DENIED))(Identity,
  DEFAULT_STATE.showAccessDenied);

const showLoadingIndicator = filterAction<boolean>(Actions(
  ActionType.SET_SHOW_LOADING_INDICATOR))(Identity,
  DEFAULT_STATE.showLoadingIndicator);

export const Reducer = combineReducers<Schema>({
  location,
  breadcrumbs,
  schema,
  metadata,
  errorMessage,
  showAccessDenied,
  showLoadingIndicator,
});

export default Reducer;
