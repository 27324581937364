import { isEmptyString } from "./Functions";
import LocalStorage from "./LocalStorage";

export interface LocalStorageApi<V> {
  hasItem(key: string): boolean;
  getItem(key: string, defaultValue: V): V;
  setItem(key: string, data: V): LocalStorageApi<V>;
  removeItem(key: string): LocalStorageApi<V>;
  clear(): LocalStorageApi<V>;
}

class LocalStorageClient implements LocalStorageApi<string> {

  public hasItem(key: string): boolean {
    const val = LocalStorage.getItem(key);
    return val !== null && !isEmptyString(val);
  }

  public getItem(key: string, defaultValue: string = ""): string {
    const val = LocalStorage.getItem(key);
    return val || defaultValue;
  }

  public setItem(key: string, data: string): LocalStorageApi<string> {
    if (isEmptyString(data)) {
      return this.removeItem(key);
    }
    LocalStorage.setItem(key, data);
    return this;
  }

  public removeItem(key: string): LocalStorageApi<string> {
    LocalStorage.removeItem(key);
    return this;
  }

  public clear(): LocalStorageApi<string> {
    LocalStorage.clear();
    return this;
  }
}

export default new LocalStorageClient();
