import React from "react";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  useOrchestrationServiceClient,
} from "@hooks";
import { UserAdministrationAttributes, UserAdministration } from "@data";

type SuccessResponse = UserAdministrationAttributes;

export interface UseGetUserAdminInfoServiceProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  userId: string;
}

export interface UseGetUserAdminInfoServiceModel extends UseApiRequestModel<SuccessResponse> {
  userAdministration: UserAdministration;
}

export interface UseGetUserAdminInfoServiceActions {
  refresh: () => void;
}

type Props = UseGetUserAdminInfoServiceProps;
type Model = UseGetUserAdminInfoServiceModel;
type Actions = UseGetUserAdminInfoServiceActions;
type Result = [Model, Actions];

export const useGetUserAdminInfoService = (props: Props): Result => {

  const {
    userId = "",
    ...otherProps
  } = props;

  const OrchestrationServiceClient = useOrchestrationServiceClient();

  const defaultErrorMessage = React.useMemo(() =>
    `Failed to get user administration info for ${userId}` , [userId]);

  const [{ successResponse, ...baseModel }, { refresh }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => OrchestrationServiceClient.getUserAdminInfo(userId),
    });

  const userAdministration = React.useMemo(() => {
    const attrs = successResponse || {};
    return new UserAdministration(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    userAdministration,
  }), [
    baseModel,
    userAdministration,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    refresh,
  }), [
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetUserAdminInfoService;
