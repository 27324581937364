import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { AuthTokenContext, usePortalSnackbar } from "@components";
import { RestClientError, DataSetRequestClient } from "@network";
import {  styles } from "./styles";
import { noop } from "@util/Functions";
import UploadDialog from "@components/upload-dialog";

export const UploadTermsAndConditions = withStyles(styles)((props: WithStyles<typeof styles> & {
  dataSetRequestId?: string,
  open?: boolean,
  onSuccess?: () => void,
  uploadTermsAndConditionsRequest?: () => void,
  uploadTermsAndConditionsSuccess?: () => void,
  uploadTermsAndConditionsFailed?: (error: string) => void,
  closeDialog?: () => void,
}) => {

  const {
    classes,
    dataSetRequestId = "",
    open,
    onSuccess = noop,
    uploadTermsAndConditionsRequest = noop,
    uploadTermsAndConditionsSuccess = noop,
    uploadTermsAndConditionsFailed = noop,
    closeDialog = noop,
  } = props;

  const authToken = React.useContext(AuthTokenContext);
  const [uploading, setUploading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [file, setFile] = React.useState<File>();
  const [successDialog, setSuccessDialog] = React.useState(false);

  usePortalSnackbar(`upload-terms-and-conditions`, {
    errorMessage,
    successMessage,
    onSuccessMessageShown: onSuccess,
    successCallbackDelay: 0,
  });

  React.useEffect(() => {

    if (!file || !uploading || errorMessage || successMessage) {
      return () => noop;
    }

    let ignore = false;
    uploadTermsAndConditionsRequest();

    DataSetRequestClient.uploadTermsAndConditions(authToken, dataSetRequestId, file)
      .then(() => {
        if (!ignore) {
          setUploading(false);
          setSuccessDialog(true);
          uploadTermsAndConditionsSuccess();
          setSuccessMessage("Terms and Conditions Upload Successfully");
          closeDialog();
        }
      })
      .catch((response: RestClientError) => {
        if (!ignore) {
          const { description, error } = response;
          setUploading(false);
          uploadTermsAndConditionsFailed(error);
          setErrorMessage(description || error || "Upload Failed");
        }
      });

    return () => { ignore = true; };

  }, [
    authToken,
    file,
    uploading,
    successMessage,
    errorMessage,
    setUploading,
    setSuccessDialog,
    setSuccessMessage,
    setErrorMessage,
    uploadTermsAndConditionsRequest,
    uploadTermsAndConditionsSuccess,
    uploadTermsAndConditionsFailed,
    closeDialog,
    dataSetRequestId,
  ]);

  return (
    <div className={classnames("uploadTermsAndConditions", classes.container)}>
      <UploadDialog
        open={open}
        title={successDialog ? "Upload Successful" : "Upload Terms and Conditions"}
        file={file}
        uploading={uploading}
        successDialog={successDialog}
        successMessage={successMessage}
        errorMessage={errorMessage}
        cancel={closeDialog}
        setUploading={setUploading}
        setErrorMessage={setErrorMessage}
        setFile={setFile}
      />
    </div>
  );
});

export default UploadTermsAndConditions;
