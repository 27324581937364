import { DeviceTypeListItem } from "@data";
import { Comparator, compare } from "@util";
import DeviceTypeListColumn from "./DeviceTypeListColumn";

const compareByState = (item: DeviceTypeListItem, otherItem: DeviceTypeListItem, ascending: boolean) =>
  compare(item.getState(), otherItem.getState(), ascending);

const compareByVersion = (item: DeviceTypeListItem, otherItem: DeviceTypeListItem, ascending: boolean) =>
  compare(item.getVersion(), otherItem.getVersion(), ascending);

const compareByName = (item: DeviceTypeListItem, otherItem: DeviceTypeListItem, ascending: boolean) => {
  const result = compare(item.getName(), otherItem.getName(), ascending);
  return result !== 0 ? result : compareByVersion(item, otherItem, ascending);
};

const compareByNamespace = (item: DeviceTypeListItem, otherItem: DeviceTypeListItem, ascending: boolean) => {
  const result = compare(item.getNameSpace(), otherItem.getNameSpace(), ascending);
  return result !== 0 ? result : compareByName(item, otherItem, ascending);
};

export const createComparator = (sortedColumn: DeviceTypeListColumn,
                                 ascending: boolean): Comparator<DeviceTypeListItem> =>

  (item: DeviceTypeListItem, otherItem: DeviceTypeListItem) => {
    switch (sortedColumn) {
      case DeviceTypeListColumn.NAMESPACE:
        return compareByNamespace(item, otherItem, ascending);
      case DeviceTypeListColumn.NAME:
        return compareByName(item, otherItem, ascending);
      case DeviceTypeListColumn.VERSION:
        return compareByVersion(item, otherItem, ascending);
      case DeviceTypeListColumn.STATE:
        return compareByState(item, otherItem, ascending);
      default:
        return 0;
    }
  };

export default createComparator;
