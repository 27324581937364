import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      fontFamily: "Roboto, sans-serif",
    },
    inputField: {
      flex: "1 0 auto",
    },
    formHelperTextError: {
      fontSize: 12,
    },
    dropdownMenuLabel: {
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    dropdownMenuHelperText: {
    },
    dropdownMenuErrorMessage: {
      fontSize: 12,
      lineHeight: "0",
      fontWeight: 400,
      padding: 0,
      margin: "8px 0",
    },
    dropdownMenuLoadingMessage: {
      fontSize: 12,
      fontStyle: "italic",
      lineHeight: "0",
      fontWeight: 400,
      padding: 0,
      margin: "8px 0",
    },
    dropdownMenu: {
      marginTop: 0,
    },
    emptyValueLabel: {
    },
  });

export default styles;
