import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const generateDialog = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      fontSize: 18,
      fontWeight: 200,
      lineHeight: "25px",
    },
    options: {
      display: "flex",
      flexDirection: "row",
    },
    formControl: {
      marginTop: 30,
      marginLeft: 15,
      minWidth: 250,

    },
    formHelperText: {
      color: Color.TEXT,
      fontSize: 13,
    },
    formHelperTextError: {
      marginBottom: 15,
    },
  });
