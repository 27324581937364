"use strict";

if (typeof Promise === "undefined") {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React"s erratic future behavior.
  require("promise/lib/rejection-tracking").enable();
  window.Promise = require("promise/lib/es6-extensions.js");
}

// Load Fonts as long as we aren't running tests
if (process.env.NODE_ENV !== "test") {
  require("../fonts/roboto/roboto.css");
}

// Polyfills specifically for running tests
if (process.env.NODE_ENV === "test") {

  // This polyfill is required in order for tests to interact w/ material-ui Popover components
  // noinspection JSValidateTypes
  if (typeof global.document.createRange === "undefined") {
    global.document.createRange = () => ({
      setStart: () => {
      },
      setEnd: () => {
      },
      commonAncestorContainer: {
        nodeName: 'BODY',
        ownerDocument: document,
      },
    });
  }

  // This polyfill is required in order for us to even mock the react-ace component
  // noinspection JSUnresolvedVariable
  if (typeof global.ace === "undefined") {
    require("react-ace");
  }
}
