import React from "react";
import classnames from "classnames";
import NameAndDescription from "./NameAndDescription";
import { noop, Quantities } from "@util";
import { PrecedingWorkloadCompletionStatus, Trigger, TriggerType } from "@data";
import { styles, PrimaryIcon } from "./styles";
import { createColumns } from "@components/module-list-view/helpers";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  DEFAULT_TRIGGER_ACTION_MENU_ITEMS,
  ListViewItem,
  ModuleListView,
  ModuleListViewActions,
  ModuleListViewModel,
} from "@components";
import { cronForParsing, listNextDates } from "@modules/dataWorkloadTriggerWizard/helpers";
import { mapTriggerTypeToOptionName } from "@hooks";

export enum TriggerListColumns {
  TYPE = "Type",
  ID = "ID",
  EVENT = "Event",
  DATA_SET_ALIAS = "Data Set Alias",
  ACCOUNT_ID = "Account ID",
  SCHEDULE_EXPRESSION = "Schedule Expression",
  SCHEDULE_ID = "Schedule ID",
}

export const DEFAULT_TRIGGER_LIST_COLUMNS: TriggerListColumns[] = [
  TriggerListColumns.TYPE,
  TriggerListColumns.DATA_SET_ALIAS,
  TriggerListColumns.ACCOUNT_ID,
  TriggerListColumns.SCHEDULE_EXPRESSION,
];

const mapCompletionStatusToLabel = (completionStatus: PrecedingWorkloadCompletionStatus) => {
  switch (completionStatus) {
    case PrecedingWorkloadCompletionStatus.ANY:
      return "Any";
    case PrecedingWorkloadCompletionStatus.SUCCEEDED:
      return "Succeeded";
    case PrecedingWorkloadCompletionStatus.FAILED:
      return "Failed";
    default:
      return "";
  }
};

export interface TriggersListModel extends ModuleListViewModel<Trigger, TriggerListColumns> {
  triggers: Trigger[];
  target?: string;
  rel?: string;
}

export interface TriggersListActions extends ModuleListViewActions<Trigger, TriggerListColumns> {
}

type Props = WithStyles<typeof styles> & TriggersListModel & TriggersListActions;

export const TriggersList = withStyles(styles)((props: Props) => {

  const {
    classes,
    triggers,
    columns = DEFAULT_TRIGGER_LIST_COLUMNS,
    actions = DEFAULT_TRIGGER_ACTION_MENU_ITEMS,
    rel,
    target,
    refresh = noop,
    ...otherProps
  } = props;

  const [nameFilter, setNameFilter] = React.useState("");

  const getEventName = (trigger: Trigger) => {
    switch (trigger.getType()) {
      case TriggerType.DATA_LAKE:
        return "Data Set: " + trigger.getDataSetAlias();
      case TriggerType.SCHEDULE:
        const label = trigger.getScheduleExpression().indexOf("cron") < 0 ? "Rate: " : "Schedule: ";
        return label + trigger.getScheduleExpression();
      case TriggerType.WORKLOAD_COMPLETE:
        return "Preceding Workload: " + trigger.getPrecedingWorkloadName();
      default:
          return "";
    }
  };

  const getEventDescription = (trigger: Trigger) => {
    switch (trigger.getType()) {
      case TriggerType.DATA_LAKE:
        return "Account: " + trigger.getAccountId();
      case TriggerType.SCHEDULE:
        if (trigger.getScheduleExpression().indexOf("cron") < 0) {
          return "";
        }
        const scheduleExpression = trigger.getScheduleExpression().slice(5, trigger.getScheduleExpression().length - 1);
        return "Next Date: " +
          new Date(listNextDates(cronForParsing(scheduleExpression), 1)[0]).toUTCString();
      case TriggerType.WORKLOAD_COMPLETE:
        return "Completion Status: " + mapCompletionStatusToLabel(trigger.getPrecedingWorkloadCompletionStatus());
      default:
        return "";
    }
  };

  const getEventConditions = (trigger: Trigger) => {
    switch (trigger.getType()) {
      case TriggerType.WORKLOAD_COMPLETE:
        return trigger.getConditions().length ? "Actions: " + trigger.getConditions().join(", ") : "";
      default:
        return "";
    }
  };

  const moduleListItems: ListViewItem<Trigger>[] = React.useMemo(() =>
    triggers.map(trigger => {
      return {
        item: trigger,
        icon: PrimaryIcon,
        rel: rel,
        target: target,
        pathToDetailsView: "",
        columnAttributes: createColumns([
          {
            className: "type",
            value: mapTriggerTypeToOptionName(trigger.getType()),
            column: TriggerListColumns.TYPE,
          },
          {
            className: "event",
            value: getEventName(trigger),
            column: TriggerListColumns.EVENT,
            customEl: (
              <NameAndDescription
                name={getEventName(trigger)}
                description={getEventDescription(trigger)}
                conditions={getEventConditions(trigger)}
              />
            )
          },
        ])
      };
    }), [triggers, rel, target]);

  const summaryViewQuantities: Quantities = {
    other: "triggers",
    one: "trigger",
  };

  return (
    <ModuleListView
      {...otherProps}
      className={classnames("triggers", classes.triggers)}
      listClassName={"triggersList"}
      listViewItems={moduleListItems}
      refresh={refresh}
      showSummary={false}
      summaryViewIcon={PrimaryIcon}
      columns={[
        TriggerListColumns.EVENT,
        TriggerListColumns.TYPE,
      ]}
      summaryViewQuantities={summaryViewQuantities}
      noResultsLabel={"No triggers for this workload"}
      nameFilter={nameFilter}
      setNameFilter={setNameFilter}
      actions={actions}
    />
  );
});

export default TriggersList;
