export enum PolicyStatementsListColumn {
  NONE = "",
  ID = "ID",
  ACTION = "Action",
  RESOURCE = "Resource",
  EFFECT = "Effect",
  CONDITION = "Condition",
}

export default PolicyStatementsListColumn;
