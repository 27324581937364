import createStyles from "@material-ui/core/styles/createStyles";
import { Color } from "@Color";
import ModuleIcon from "@material-ui/icons/Smartphone";

export const primaryColor = Color.MODULES;

export const PrimaryIcon = ModuleIcon;

export const waitForDeviceValidationDetails = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    alert: {
      marginTop: 15,
    },
    alertTitle: {
      fontSize: 18,
      lineHeight: "21px",
      marginTop: 2,
      fontWeight: 400,
    },
    alertMessage: {
      fontSize: 14,
      lineHeight: "16px",
      marginTop: 10,
      fontWeight: 300,
    },
  });

export const request = () =>
  createStyles({
    container: {
      color: primaryColor,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    header: {
      flex: "0 0 50px",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      position: "relative",
      color: Color.TEXT,
    },
    title: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: 0,
    },
    enrollDeviceButton: {
      justifySelf: "flex-end",
      padding: "6px 16px",
      marginLeft: "auto",
    },
    jsonView: {
      flex: "1 0 auto",
      minHeight: 500,
    },
  });
