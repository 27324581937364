import { Record } from "immutable";
import { DeviceTypeRequestV3 } from "./DeviceTypeRequestV3";

export interface DeviceTypeWizardStateAttributes {
  namespace: string;
  name: string;
  json: string;
}

// We must do this outside of the record class definition because you cannot reference
// static field values in the record defaults; unless you have specifically enforced
// their evaluation before the class is defined - like in this case.
const DEFAULT_JSON = JSON.stringify(DeviceTypeRequestV3.EMPTY.toJS(), null, "  ");

export class DeviceTypeWizardState extends Record({
  namespace: "",
  name: "",
  json: DEFAULT_JSON,
}) implements DeviceTypeWizardStateAttributes {

  public static EMPTY = new DeviceTypeWizardState();

  public readonly namespace: string;
  public readonly name: string;
  public readonly json: string;
}

export default DeviceTypeWizardState;
