import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import SummaryView, { Actions, Model } from "../components/SummaryView";
import {
  getAnonymousId,
  getCreatedAt,
  getUserId,
  getUserStatus,
  isLoadingIndicatorVisible,
} from "../selectors";

interface ContainerModel extends Model {
  isLoading?: boolean;
  hidden?: boolean;
}

export interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const SummaryViewContainer = (props: Props) => {

  const {
    hidden,
    children,
    ...otherProps
  } = props;

  if (hidden) {
    return null;
  }

  return (
    <SummaryView {...otherProps}>
      {children}
    </SummaryView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  isLoading: isLoadingIndicatorVisible(state),
  userId: getUserId(state),
  status: getUserStatus(state),
  anonymousId: getAnonymousId(state),
  createdAt: getCreatedAt(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(SummaryViewContainer);
