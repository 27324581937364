import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles";
import { Color } from "../styles";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      justifyContent: "center",
    },
    progress: {
      color: "inherit",
    },
    label: {
      color: Color.GREY3,
      fontFamily: "Roboto, sans-serif",
      fontSize: 24,
      fontStyle: "italic",
      fontWeight: 300,
      lineHeight: "50px",
      margin: "0 0 0 25px",
      padding: 0,
    },
  });

export default styles;
