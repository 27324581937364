import {
  MODULE_PATH as BASE_URL,
  LEGACY_MODULE_PATH as LEGACY_BASE_URL,
} from "@modules/dataApprovalRequests/constants";

export const MODULE_ID = "data_approval_request_details";
export const MODULE_PATH = `${BASE_URL}/:id`;
export const LEGACY_MODULE_PATH = `${LEGACY_BASE_URL}/:id`;

export const VIEW_APPROVAL_REQUEST_REDIRECT = "viewApprovalRequest";
export const VIEW_APPROVAL_REQUEST_COMMENTS_REDIRECT = "viewApprovalRequestComments";

export const SCHEMA_KEY = "dataApprovalRequestDetails";

export { BASE_URL, LEGACY_BASE_URL };
