import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import { PolicyOperation, PolicyOperationPrincipal } from "@hooks/policies/use-policy-management/helpers";
import { PolicyBulkRequest } from "@data/PolicyBulkRequest";
import { ManagedPolicy, Policy } from "@data";
import { ManagedPolicyBulkRequest } from "@data/ManagedPolicyBulkRequest";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes
} from "@base/createReducers";

export enum PolicyManagementViewMode {
  CUSTOM = "custom",
  MANAGED = "managed"
}

export enum SelectPolicyStep {
  NONE = "",
  POLICIES = "policies",
  REVIEW = "review",
}

export interface PolicyManagementSchema extends BasePortalModuleSchema {
  principalId: string;
  policyName: string;
  policyRef: string;
  operation: PolicyOperation;
  principalType: PolicyOperationPrincipal;
  viewMode: PolicyManagementViewMode;
  managedPolicyDomain: string;
  showReviewView: boolean;
  policyBulkRequest: PolicyBulkRequest[] | ManagedPolicyBulkRequest[];
  selectedPolicies: Policy[];
  selectedManagedPolicies: ManagedPolicy[];
  policyStep: SelectPolicyStep;
}

export const DEFAULT_STATE: PolicyManagementSchema = createPortalModuleDefaultState({
  principalId: "",
  policyName: "",
  policyRef: "",
  operation: PolicyOperation.ATTACH,
  principalType: PolicyOperationPrincipal.SERVICE,
  viewMode: PolicyManagementViewMode.CUSTOM,
  managedPolicyDomain: "",
  showReviewView: false,
  policyBulkRequest: [],
  selectedPolicies: [],
  selectedManagedPolicies: [],
  policyStep: SelectPolicyStep.POLICIES,
});

type PolicyManagementActionTypes = PortalModuleActionTypes<PolicyManagementSchema> & {
  ATTACH_POLICY_TO_SERVICE_REQUEST: string;
  ATTACH_POLICY_TO_SERVICE_SUCCESS: string;
  ATTACH_POLICY_TO_SERVICE_FAILED: string;
  ATTACH_POLICY_TO_USER_REQUEST: string;
  ATTACH_POLICY_TO_USER_SUCCESS: string;
  ATTACH_POLICY_TO_USER_FAILED: string;
  ATTACH_POLICY_TO_GROUP_REQUEST: string;
  ATTACH_POLICY_TO_GROUP_SUCCESS: string;
  ATTACH_POLICY_TO_GROUP_FAILED: string;
  DETACH_POLICY_FROM_SERVICE_REQUEST: string;
  DETACH_POLICY_FROM_SERVICE_SUCCESS: string;
  DETACH_POLICY_FROM_SERVICE_FAILED: string;
  DETACH_POLICY_FROM_USER_REQUEST: string;
  DETACH_POLICY_FROM_USER_SUCCESS: string;
  DETACH_POLICY_FROM_USER_FAILED: string;
  DETACH_POLICY_FROM_GROUP_REQUEST: string;
  DETACH_POLICY_FROM_GROUP_SUCCESS: string;
  DETACH_POLICY_FROM_GROUP_FAILED: string;
};

export const ACTION_TYPES: PolicyManagementActionTypes = Object.freeze({
  ...createPortalModuleActionTypes(MODULE_ID, DEFAULT_STATE),
  ...{
    ATTACH_POLICY_TO_SERVICE_REQUEST: `${MODULE_ID}/attach_policy_to_service_request`,
    ATTACH_POLICY_TO_SERVICE_SUCCESS: `${MODULE_ID}/attach_policy_to_service_success`,
    ATTACH_POLICY_TO_SERVICE_FAILED: `${MODULE_ID}/attach_policy_to_service_failed`,
    ATTACH_POLICY_TO_USER_REQUEST: `${MODULE_ID}/attach_policy_to_user_request`,
    ATTACH_POLICY_TO_USER_SUCCESS: `${MODULE_ID}/attach_policy_to_user_success`,
    ATTACH_POLICY_TO_USER_FAILED: `${MODULE_ID}/attach_policy_to_user_failed`,
    ATTACH_POLICY_TO_GROUP_REQUEST: `${MODULE_ID}/attach_policy_to_group_request`,
    ATTACH_POLICY_TO_GROUP_SUCCESS: `${MODULE_ID}/attach_policy_to_group_success`,
    ATTACH_POLICY_TO_GROUP_FAILED: `${MODULE_ID}/attach_policy_to_group_failed`,
    DETACH_POLICY_FROM_SERVICE_REQUEST: `${MODULE_ID}/detach_policy_from_service_request`,
    DETACH_POLICY_FROM_SERVICE_SUCCESS: `${MODULE_ID}/detach_policy_from_service_success`,
    DETACH_POLICY_FROM_SERVICE_FAILED: `${MODULE_ID}/detach_policy_from_service_failed`,
    DETACH_POLICY_FROM_USER_REQUEST: `${MODULE_ID}/detach_policy_from_user_request`,
    DETACH_POLICY_FROM_USER_SUCCESS: `${MODULE_ID}/detach_policy_from_user_success`,
    DETACH_POLICY_FROM_USER_FAILED: `${MODULE_ID}/detach_policy_from_user_failed`,
    DETACH_POLICY_FROM_GROUP_REQUEST: `${MODULE_ID}/detach_policy_from_group_request`,
    DETACH_POLICY_FROM_GROUP_SUCCESS: `${MODULE_ID}/detach_policy_from_group_success`,
    DETACH_POLICY_FROM_GROUP_FAILED: `${MODULE_ID}/detach_policy_from_group_failed`,
  }
});

export const PolicyManagementReducer = combineReducers<PolicyManagementSchema>(
  createPortalModuleReducers<PolicyManagementSchema>(ACTION_TYPES, DEFAULT_STATE));

export default PolicyManagementReducer;
