import { Module } from "@data";
import { createModule } from "@modules/base";
import { initialize, onMount } from "./actions";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./EmailTemplatesView";

export const emailTemplatesModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Email Templates",
  title: "Email Template Management",
  className: "emailTemplatesModule",
  primaryColor,
  icon,
  view,
  backButton: null,
  initialize,
  onMount,
});

export default emailTemplatesModule;
