import React from "react";
import classnames from "classnames";
import JsonEditor, { JsonEditorActions, JsonEditorModel } from "../json-editor";
import "brace/mode/html";

export interface HtmlEditorModel extends JsonEditorModel {
  html?: string;
}

export interface HtmlEditorActions extends JsonEditorActions {
  setHtml?: (updatedHtml: string) => void;
}

type Props = HtmlEditorModel & HtmlEditorActions;

export const HtmlEditor = (props: Props) => {

  const { className, html, setHtml, ...otherProps } = props;

  return (
    <JsonEditor
      {...otherProps}
      className={classnames("htmlEditor", className)}
      mode="html"
      json={html}
      setJson={setHtml}
    />
  );
};

export default HtmlEditor;
