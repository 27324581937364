import React from "react";
import classnames from "classnames";
import { Alert } from "@components/alert";
import Dialog from "@material-ui/core/Dialog";
import { noop, openBroadcastChannel } from "@util";
import Typography from "@material-ui/core/Typography";
import ContinueButton from "@components/continue-button";
import DialogContent from "@material-ui/core/DialogContent";
import BackdropLoadingView from "@components/backdrop-loading-view";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { BROADCAST_ACTION_LOGOUT, BROADCAST_CHANNEL_END_SESSION } from "@store/constants";
import styles from "./styles";

export interface EndSessionListenerModel {
  loggedIn?: boolean;
}

export interface EndSessionListenerActions {
  invalidateSessionData?: () => void;
  reload?: () => void;
}

type Model = EndSessionListenerModel;
type Actions = EndSessionListenerActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const EndSessionListener = withStyles(styles)((props: Props) => {

  const {
    classes,
    loggedIn,
    reload = noop,
    invalidateSessionData = noop,
    children,
  } = props;

  const [wasLoggedIn, setWasLoggedIn] = React.useState(loggedIn);
  const [showLoggedOutDialog, setShowLoggedOutDialog] = React.useState(false);
  const [reloading, setReloading] = React.useState(false);

  const onClickReload = React.useCallback(() => {
    setReloading(true);
  }, [setReloading]);

  React.useEffect(() => {
    if (!reloading) {
      return noop;
    }
    const timer = setTimeout(() => {
      reload();
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [reloading, reload]);

  React.useEffect(() => {
    if (loggedIn !== wasLoggedIn) {
      if (wasLoggedIn && !loggedIn) {
        const { postMessage, unsubscribe } = openBroadcastChannel(BROADCAST_CHANNEL_END_SESSION);
        postMessage(BROADCAST_ACTION_LOGOUT);
        unsubscribe();
      }
      setWasLoggedIn(loggedIn);
    }
  }, [loggedIn, wasLoggedIn, setWasLoggedIn]);

  React.useEffect(() => {

    if (!loggedIn || showLoggedOutDialog) {
      return noop;
    }

    const listener = (broadcastEvent: MessageEvent) => {
      if (broadcastEvent.data === BROADCAST_ACTION_LOGOUT) {
        setShowLoggedOutDialog(true);
      }
    };

    const { unsubscribe } = openBroadcastChannel(BROADCAST_CHANNEL_END_SESSION, listener);

    return () => {
      unsubscribe();
    };

  }, [loggedIn, showLoggedOutDialog, setShowLoggedOutDialog]);

  React.useEffect(() => {
    if (showLoggedOutDialog) {
      invalidateSessionData();
    }
  }, [showLoggedOutDialog, invalidateSessionData]);

  if (!showLoggedOutDialog) {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Dialog
        className={classnames("endSessionListener", classes.container)}
        maxWidth="sm"
        open={true}
      >
        <DialogContent
          className={classnames("dialogContent", classes.dialogContent)}
        >
          <Typography className={classnames("title", classes.title )} variant="h4">
            Please sign in again
          </Typography>
          <Alert className={classnames("infoView", classes.infoView )} severity="info">
            You were signed out of your account.<br/>
            Please press <b>Reload</b> to sign in to the IoT Portal again.
          </Alert>
          <div className={classnames("controls", classes.controls)}>
            <ContinueButton
              className={classnames("continueButton", classes.continueButton)}
              disabled={reloading}
              label="Reload"
              onClick={onClickReload}
            />
          </div>
        </DialogContent>
      </Dialog>
      <BackdropLoadingView
        className={classnames("backdrop", classes.backdrop)}
        showProgressIndicator={reloading}
        open={true}
      />
    </React.Fragment>
  );
});

export default EndSessionListener;
