import { isEmptyString } from "@util";
import { AppSchema } from "@main/schemas";
import { createActions } from "@modules/base";
import { reloadUsers } from "@modules/users/actions";
import {
  getAccessToken,
  getJson,
  getLockUser,
  getUserId,
  getUserLockAction,
  isLockUserAction,
} from "./selectors";
import { ACTION_TYPES, DEFAULT_STATE, LockUserAction } from "./reducers";
import { RestClientError, UserIdmClient } from "@network";
import { LockUserRequest } from "@data";

export const {
  userId: setUserId,
  open: setOpen,
  action: setAction,
  lockUser: setLockUser,
  json: setJson,
  setErrorMessage,
  setSuccessMessage,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  LOCK_USER_REQUEST: lockUserRequest,
  LOCK_USER_SUCCESS: lockUserSuccess,
  LOCK_USER_FAILED: lockUserFailed,
  LOCK_USER_CREATE_ADMIN_NOTE_REQUEST: lockUserCreateAdminNoteRequest,
  LOCK_USER_CREATE_ADMIN_NOTE_SUCCESS: lockUserCreateAdminNoteSuccess,
  LOCK_USER_CREATE_ADMIN_NOTE_FAILED: lockUserCreateAdminNoteFailed,
  UNLOCK_USER_REQUEST: unlockUserRequest,
  UNLOCK_USER_SUCCESS: unlockUserSuccess,
  UNLOCK_USER_FAILED: unlockUserFailed,
  UNLOCK_USER_CREATE_ADMIN_NOTE_REQUEST: unlockUserCreateAdminNoteRequest,
  UNLOCK_USER_CREATE_ADMIN_NOTE_SUCCESS: unlockUserCreateAdminNoteSuccess,
  UNLOCK_USER_CREATE_ADMIN_NOTE_FAILED: unlockUserCreateAdminNoteFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setUserId());
  dispatch(setJson());
  dispatch(setLockUser());
  dispatch(setOpen());
  dispatch(setAction());
  return dispatch(baseReset());
};

export const trackCreateAdminNoteRequest = () =>
  (dispatch: any, getState: () => AppSchema) => dispatch(
    isLockUserAction(getState())
      ? lockUserCreateAdminNoteRequest()
      : unlockUserCreateAdminNoteRequest());

export const trackCreateAdminNoteSuccess = () =>
  (dispatch: any, getState: () => AppSchema) => dispatch(
    isLockUserAction(getState())
      ? lockUserCreateAdminNoteSuccess()
      : unlockUserCreateAdminNoteSuccess());

export const trackCreateAdminNoteFailed = (analytic: string) =>
  (dispatch: any, getState: () => AppSchema) => dispatch(
    isLockUserAction(getState())
      ? lockUserCreateAdminNoteFailed(analytic)
      : unlockUserCreateAdminNoteFailed(analytic));

export const updateLockUser = (lockRequest: LockUserRequest) => (dispatch: any) => {

  const attrs = lockRequest.toJS();

  dispatch(setJson(JSON.stringify(attrs, null, " ")));

  dispatch(setLockUser(attrs));

  return dispatch(setErrorMessage());
};

export const updateUserLocked = (userLocked: boolean) => (dispatch: any, getState: () => AppSchema) => {

  const lockRequest = getLockUser(getState());

  return dispatch(updateLockUser( new LockUserRequest({
    ...lockRequest.toJS(),
    userLocked,
  })));
};

export const save = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const userId = getUserId(state);
  const accessToken = getAccessToken(state);
  const action = getUserLockAction(state);
  const json = getJson(state);

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());

  if (action === LockUserAction.LOCK_USER) {
    dispatch(lockUserRequest());
  } else {
    dispatch(unlockUserRequest());
  }

  return UserIdmClient.lockUnlockUser(accessToken, userId, json)
    .then(() => {

      if (action === LockUserAction.LOCK_USER) {
        dispatch(lockUserSuccess());
      } else {
        dispatch(unlockUserSuccess());
      }
      dispatch(reloadUsers());
      dispatch(hideLoadingIndicator());

      if (action === LockUserAction.LOCK_USER) {
        return dispatch(setSuccessMessage("User Locked"));
      } else {
        return dispatch(setSuccessMessage("User Unlocked"));
      }
    }, (response: RestClientError) => {

      const { analytic, status, error } = response;

      if (action === LockUserAction.LOCK_USER) {
        dispatch(lockUserFailed(analytic));
      } else {
        dispatch(unlockUserFailed(analytic));
      }

      dispatch(hideLoadingIndicator());

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(setErrorMessage(error));
    });
};

export const open = (userId: string, action: LockUserAction) => (dispatch: any) => {

  if (isEmptyString(userId)) {
    return Promise.resolve();
  }

  dispatch(reset());
  dispatch(setUserId(userId));
  dispatch(setAction(action));

  if (action === LockUserAction.LOCK_USER) {
    dispatch(updateUserLocked(true));
  } else {
    dispatch(updateUserLocked(false));
  }

  return dispatch(setOpen(true));
};

export const close = reset;

export const initialize = reset;
