import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { noop } from "@util";
import { useCancelWorkloadExecution } from "@hooks";

export interface WorkloadCancelExecutionDialogModel extends PortalModuleDialogModel<string> {
  name: string;
  version: number;
  executionId: string;
}

export interface WorkloadCancelExecutionDialogActions extends PortalModuleDialogActions<string> {
  close?: () => void;
}

type Props = WithStyles<typeof styles> & WorkloadCancelExecutionDialogModel & WorkloadCancelExecutionDialogActions;

export const WorkloadCancelExecutionDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    name,
    version,
    executionId,
    title = "Cancel Workload Execution",
    close = noop,
    onClose = close,
    onSuccessMessageShown = noop,
    ...otherProps
  } = props;

  const [{ showLoadingIndicator, ...model }, { cancelWorkloadExecution, reset, ...actions }] =
    useCancelWorkloadExecution({ name, version, executionIds: [executionId] });

  const onSuccess = React.useCallback(() => {
    close();
    onSuccessMessageShown();
  }, [onSuccessMessageShown, close]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...model}
      {...actions}
      className={classnames("workloadCancelExecutionDialog", className)}
      title={title}
      loading={showLoadingIndicator}
      confirm={cancelWorkloadExecution}
      cancel={close}
      onClose={onClose}
      onSuccessMessageShown={onSuccess}
    >
      <div className={classnames("draftNewVersion", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Are you sure you want to cancel this workload execution?
          </label>
          <label className={classnames("name", classes.id)}>
            <b>Execution Id:</b> {executionId}
          </label>
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadCancelExecutionDialog;
