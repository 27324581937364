import React from "react";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { AccessDeniedView } from "@components/error-view/AccessDeniedView";
import { Color } from "@Color";
import { LoadingView } from "@components/loading-view";
import { noop } from "@util/Functions";
import styles from "./styles";

export interface PortalModuleModel {
  className?: string;
  primaryColor?: string;
  showEmptyView?: boolean;
  showAccessDenied?: boolean;
  skipInitializeOnRouteParamsChange?: boolean;
  children?: React.ReactNode;
}

export interface PortalModuleActions<RouteParams = any> {
  initialize?: (params?: { [p in keyof RouteParams]: string }) => void;
}

export type PortalModuleProps<RouteParams = any> = PortalModuleModel & PortalModuleActions<RouteParams>;

type Props<RouteParams = any> = WithStyles<typeof styles> & PortalModuleProps<RouteParams>;

export const PortalModule = withStyles(styles)(<RouteParams, >(props: Props<RouteParams>) => {

  const {
    classes,
    className,
    primaryColor = Color.MODULES,
    showAccessDenied,
    showEmptyView,
    skipInitializeOnRouteParamsChange,
    children,
    initialize = noop,
  } = props;

  const params = useParams<RouteParams>();

  const [initializedParams, setInitializedParams] = React.useState(JSON.stringify(params));

  const [initialized, setInitialized] = React.useState(false);

  // Updated route params will trigger a re-initialization to ensure that we update
  // the module state to reflect that the path has been updated
  React.useEffect(() => {
    if (!skipInitializeOnRouteParamsChange) {
      const updatedParams = JSON.stringify(params);
      if (updatedParams !== initializedParams) {
        setInitialized(false);
      }
    }
  }, [
    params,
    initializedParams,
    skipInitializeOnRouteParamsChange,
    setInitialized,
  ]);

  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setInitializedParams(JSON.stringify(params));
      initialize(params);
    }
  }, [params, initialize, initialized, setInitialized, setInitializedParams]);

  if (!initialized) {
    return null;
  }

  if (showAccessDenied) { return <AccessDeniedView/>; }

  if (showEmptyView) {
    return (
      <div className={classnames(className, classes.container)}>
        <LoadingView className="emptyView" color={primaryColor} />
      </div>
    );
  }

  return (
    <div className={classnames(className, classes.container)}>
      {children}
    </div>
  );
});

export default PortalModule;
