import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { reviewView as styles } from "./styles";

export interface Model {
  domain?: string;
  clientId?: string;
  clientSecret?: string;
  scope?: string;
  authorizationEndpoint?: string;
  discoveryDocument?: string;
  tokenEndpoint?: string;
  userEndpoint?: string;
  jwksUri?: string;
  issuer?: string;
  federationClosed?: string;
  attributes?: string;
  children?: React.ReactNode;
}

export interface Actions {}

type Props = WithStyles<typeof styles> & Model & Actions;

export const ReviewView = withStyles(styles)((props: Props) => {

  const {
    classes,
    domain,
    clientId,
    clientSecret,
    scope,
    authorizationEndpoint,
    discoveryDocument,
    tokenEndpoint,
    userEndpoint,
    jwksUri,
    issuer,
    federationClosed,
    attributes,
    children,
  } = props;

  return (
    <div className={classnames("reviewView", classes.container)}>
      <label className={classnames("title", classes.title)}>Review Federation Provider</label>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Domain: </label>
        <label className={classnames("domain", classes.propertyValue)}>{domain}</label>
      </div>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Client ID: </label>
        <label className={classnames("clientId", classes.propertyValue)}>{clientId}</label>
      </div>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Client Secret: </label>
        <label className={classnames("clientSecret", classes.propertyValue)}>{clientSecret}</label>
      </div>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Scope: </label>
        <label className={classnames("scope", classes.propertyValue, classes.json)}>{scope}</label>
      </div>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Authorization Endpoint: </label>
        <label className={classnames("authorizationEndpoint", classes.propertyValue)}>{authorizationEndpoint}</label>
      </div>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Discovery Document: </label>
        <label className={classnames("discoveryDocument", classes.propertyValue)}>{discoveryDocument}</label>
      </div>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Token Endpoint: </label>
        <label className={classnames("tokenEndpoint", classes.propertyValue)}>{tokenEndpoint}</label>
      </div>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>User Endpoint: </label>
        <label className={classnames("userEndpoint", classes.propertyValue)}>{userEndpoint}</label>
      </div>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>JWKS Uri: </label>
        <label className={classnames("jwksUri", classes.propertyValue)}>{jwksUri}</label>
      </div>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Issuer: </label>
        <label className={classnames("issuer", classes.propertyValue)}>{issuer}</label>
      </div>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Federation Closed: </label>
        <label className={classnames("federationClosed", classes.propertyValue)}>{federationClosed}</label>
      </div>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Attributes: </label>
        <label className={classnames("attributes", classes.propertyValue, classes.json)}>{attributes}</label>
      </div>
      {children}
    </div>
  );
});

export default ReviewView;
