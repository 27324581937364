import React from "react";
import Color from "@Color";
import { Vector } from "@data";
import classnames from "classnames";
import { clickHandler } from "@util";
import Button from "@components/button";
import IconButton from "@components/icon-button";
import { withHoveredState } from "@components/helpers";
import { HelpIcon, SortDescIcon, SortIcon } from "@icons";
import TooltipIcon from "@components/tooltip/TooltipIcon";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const SortButton = (props: WithStyles<typeof styles> & {
  label: string,
  tooltip?: string,
  active?: boolean,
  hovered?: boolean,
  ascending?: boolean,
  setSortedColumn?: () => void,
  toggleSortOrder?: () => void,
}) => {

  const {
    classes,
    label,
    tooltip,
    active,
    hovered,
    ascending,
    setSortedColumn,
    toggleSortOrder,
  } = props;

  const Icon = ascending ? SortIcon : SortDescIcon;

  const iconColor = hovered
    ? (active ? Color.GREY8 : Color.GREY7)
    : (active ? Color.GREY6 : Color.TRANSPARENT);

  const onClick = clickHandler(active ? toggleSortOrder : setSortedColumn);

  return (
    <div className={classnames("sortButton", classes.container)}>
      <Button
        className={classnames("button", classes.button)}
        classes={{
          label: classnames("label", classes.label),
        }}
        fullWidth={false}
        disableTouchRipple={true}
        onClick={onClick}
      >
        {label}
      </Button>
      {tooltip && (
        <div className={classes.tooltipIcon}>
          <TooltipIcon
            icon={HelpIcon}
            size={Vector.square(15)}
            tooltipText={tooltip}
            onClick={onClick}
          />
        </div>
      )}
      <IconButton
        className={classes.iconButton}
        disableTouchRipple={true}
        onClick={onClick}
      >
        <Icon htmlColor={iconColor} className={classes.icon} />
      </IconButton>
    </div>
  );
};

export default withStyles(styles)(withHoveredState(SortButton));
