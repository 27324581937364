import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import { IdentityType } from "@data";
import tokenRefresh from "@util/TokenRefresh";
import { usePortalSnackbar } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface Model {
  accountId?: string;
  userId?: string;
  authToken?: string;
  refreshToken?: string;
  nextRefresh?: number;
  identityType?: IdentityType;
  successMessage?: string;
  children?: React.ReactNode;
}

export interface Actions {
  initialize?: () => void;
  onSuccess?: () => void;
  updateAuthToken?: (error: null | Error, token: string, expiryTime: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const App = withStyles(styles)((props: Props) => {

  const {
    classes,
    accountId = "",
    userId = "",
    authToken = "",
    refreshToken = "",
    nextRefresh = 0,
    identityType = IdentityType.USER,
    successMessage = "",
    children,
    onSuccess = noop,
    initialize = noop,
    updateAuthToken = noop,
  } = props;

  const [initialized, setInitialized] = React.useState(false);

  const isServicePrincipal = React.useMemo(() =>
    identityType === IdentityType.SERVICE, [identityType]);

  // TODO: This kind of logic should not be in the main App component
  usePortalSnackbar("user-report-available", {
    successMessage,
    onSuccessMessageShown: onSuccess,
    autoHideDuration: 5000,
    successCallbackDelay: 250,
  });

  React.useEffect(() => {

    if (!initialized) {
      setInitialized(true);
      initialize();
    }

    if (isServicePrincipal) {
      return () => noop;
    }

    const cancelTokenRefresh = tokenRefresh(
      accountId, userId, authToken, refreshToken, updateAuthToken, nextRefresh);

    return () => {
      cancelTokenRefresh();
    };

  }, [
    initialized,
    setInitialized,
    initialize,
    accountId,
    userId,
    authToken,
    refreshToken,
    updateAuthToken,
    nextRefresh,
    isServicePrincipal,
  ]);

  return (
    <div className={classnames("app", classes.container)}>
      {children}
    </div>
  );
});

export default App;
