import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

const styles = () =>
  createStyles({
    container: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      color: Color.SIGNIFY_GREEN,
    },
  });

export default styles;
