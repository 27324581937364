import React from "react";
import classnames from "classnames";
import DataAccessRequestSummaryView, {
  DataAccessRequestSummaryViewActions,
  DataAccessRequestSummaryViewModel,
} from "@components/data-access-request-summary-view";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { summaryView as styles } from "./styles";

export interface Model extends DataAccessRequestSummaryViewModel {
  className?: string;
}

export interface Actions extends DataAccessRequestSummaryViewActions {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const SummaryView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    children,
    ...otherProps
  } = props;

  return (
    <DataAccessRequestSummaryView
      {...otherProps}
      className={classnames("summaryView", className, classes.container)}
    >
      {children}
    </DataAccessRequestSummaryView>
  );
});

export default SummaryView;
