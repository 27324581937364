import { Record } from "immutable";
import { ManagedPolicy, ManagedPolicyAttributes } from "@data";
import { equalsIgnoreCase } from "@util";
import { PolicyStatus } from "@data/PolicyBulkRequest";

export interface ManagedPolicyBulkRequestAttributes {
  policy: ManagedPolicyAttributes;
  status: PolicyStatus;
  managed: boolean;
  errorMessage: string;
}

export class ManagedPolicyBulkRequest extends Record({
  policy: ManagedPolicy.EMPTY.toJS(),
  status: PolicyStatus.QUEUED,
  managed: true,
  errorMessage: "",
}) implements ManagedPolicyBulkRequestAttributes {

  public static EMPTY: ManagedPolicyBulkRequest = new ManagedPolicyBulkRequest();

  public readonly policy: ManagedPolicyAttributes;
  public readonly status: PolicyStatus;
  public readonly managed: boolean;
  public readonly errorMessage: string;

  // We need to override equals because this Record contains a nested Object property
  //
  // Reference: https://immutable-js.github.io/immutable-js/docs/#/Record/equals
  // Value equality: Records use value equality when compared with is() or record.equals().
  // That is, two Records with the same keys and values are equal. Plain objects use reference
  // equality. Two objects with the same keys and values are not equal since they are different
  // objects. This is important to consider when using objects as keys in a Map or values in a
  // Set, which use equality when retrieving values.
  public equals(other: ManagedPolicyBulkRequest): boolean {
    return this.getPolicy().equals(other.getPolicy()) &&
      equalsIgnoreCase(this.status, other.status);
  }

  public getPolicy(): ManagedPolicy {
    return new ManagedPolicy(this.policy);
  }

  public getPolicyName(): string {
    return this.getPolicy().getPolicy().getName();
  }

  public getPolicyStatus(): PolicyStatus {
    return this.status;
  }

  public getErrorMessage(): string {
    return this.errorMessage;
  }
}
