import { Record } from "immutable";
import { equalsIgnoreCase, getStringValue } from "@util";

export interface FederationProviderScope {
  values: string[];
  separator: string;
}

export interface FederationProviderDynamicAttributes {
  objects?: any;
  refs?: any;
}

export interface FederationProviderAttribute {
  dynamic?: FederationProviderDynamicAttributes;
  constant?: any;
}

export interface FederationProviderAttributes {
  accountId: string;
  providerId: string;
  domain: string;
  clientId: string;
  clientSecret: string;
  scope?: FederationProviderScope;
  discoveryDocument?: string;
  authorizationEndpoint?: string;
  tokenEndpoint?: string;
  userEndpoint?: string;
  jwksUri?: string;
  issuer?: string;
  federationClosed: boolean;
  attributes: FederationProviderAttribute;
}

export class FederationProvider extends Record({
  accountId: "",
  providerId: "",
  domain: "",
  clientId: "",
  clientSecret: "",
  scope: {},
  discoveryDocument: "",
  authorizationEndpoint: "",
  tokenEndpoint: "",
  userEndpoint: "",
  jwksUri: "",
  issuer: "",
  federationClosed: false,
  attributes: {},
}) implements FederationProviderAttributes {

  public static EMPTY: FederationProvider = new FederationProvider();

  public readonly accountId: string;
  public readonly providerId: string;
  public readonly domain: string;
  public readonly clientId: string;
  public readonly clientSecret: string;
  public readonly discoveryDocument: string;
  public readonly authorizationEndpoint: string;
  public readonly tokenEndpoint: string;
  public readonly userEndpoint: string;
  public readonly jwksUri: string;
  public readonly issuer: string;
  public readonly federationClosed: boolean;
  public readonly scope: FederationProviderScope;
  public readonly attributes: FederationProviderAttribute;

  // We need to override equals because this Record contains a nested Object property
  //
  // Reference: https://immutable-js.github.io/immutable-js/docs/#/Record/equals
  // Value equality: Records use value equality when compared with is() or record.equals().
  // That is, two Records with the same keys and values are equal. Plain objects use reference
  // equality. Two objects with the same keys and values are not equal since they are different
  // objects. This is important to consider when using objects as keys in a Map or values in a
  // Set, which use equality when retrieving values.
  public equals(other: FederationProvider): boolean {
    return equalsIgnoreCase(JSON.stringify(this.toJS()), JSON.stringify(other.toJS()));
  }

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getProviderId(): string {
    return getStringValue(this.providerId);
  }

  public getDomain(): string {
    return getStringValue(this.domain);
  }

  public getClientId(): string {
    return getStringValue(this.clientId);
  }
}
