import { createStyles, StyleRules } from "@material-ui/core/styles";
import ModuleIcon from "@material-ui/icons/Create";
import Color from "@Color";

export const primaryColor = Color.MODULES;

export const PrimaryIcon = ModuleIcon;

export const textField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "14px",
      fontWeight: 300,
      padding: "2px 0",
      margin: 0,
    },
    "& input": {
      fontSize: 14,
      fontWeight: 400,
      padding: "18.5px 14px",
      margin: 0,
    },
  },
};

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    certificateView: {
      "& .container": {
        alignItems: "flex-start",
      }
    },
    typeView: {
    },
    principalView: {
    },
    servicePrincipalView: {
    },
    servicePrincipalsList: {
    },
    deviceTypeView: {
    },
    iotCloudRoleView: {
    },
    reviewView: {
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      marginTop: 8,
    },
    subtitle: {
      fontWeight: 300,
      marginTop: 15,
    },
    servicesList: {
      marginTop: 8,
    },
    deviceTypeList: {
      marginTop: 8,
    },
    dropdownMenu: {
      marginTop: 16,
      minWidth: 250,
      display: "inline-flex",
      alignItems: "stretch",
    },
    dropdownMenuLabel: {
      marginTop: 8,
    },
    dropdownMenuEmptyValueLabel: {
      fontStyle: "italic",
    },
    autocomplete: {
      marginTop: 25,
      marginBottom: 25,
      maxWidth: 500,
    },
    trustedAccountsForCloudRole: {
      margin: 0,
      flex: "0 0 500px",
    },
    autocompleteOption: {
      "&[aria-disabled='true']": {
        opacity: "1.0",
      },
    },
    invalidAutocompleteOption: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    invalidAutocompleteOptionValue: {
      color: Color.TEXT,
      fontWeight: 300,
      fontStyle: "italic",
    },
    invalidAutocompleteOptionError: {
      color: Color.DARK_RED,
      fontWeight: 400,
      marginTop: 6,
    },
    textarea: {
      marginTop: 25,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    awsAccountId: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    summaryViewLabel: {
      marginTop: 25,
    },
    summaryViewLabelName: {
      flex: "0 0 175px",
      alignSelf: "center",
    },
    summaryViewLabelValue: {
      lineHeight: 2,
      padding: "5px 10px",
      whiteSpace: "pre-line",
    },
    section: {
      marginTop: 32,
      paddingTop: 16,
      borderTop: `1px solid ${Color.GREY1}`,
      "&:first-child": {
        marginTop: 0,
        paddingTop: 0,
        borderTop: "none",
      },
    },
    trustedAccounts: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    cloudRoleIdentifier: {
    },
    cloudRoleIdentifiersContainer: {
      marginTop: 16,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    autocompleteContainer: {
      flex: "1 0 auto",
      marginTop: 25,
      marginBottom: 25,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    cloudRoleIdentifiers: {
      marginTop: 0,
    },
    retryButton: {
      marginLeft: 25,
      textTransform: "none",
      verticalAlign: "baseline",
      color: Color.DARK_RED,
      fontWeight: 400,
      display: "inline-block",
    },
    addIconButton: {
      marginLeft: 16,
      width: 24,
      height: 24,
      borderRadius: 32,
      padding: 0,
      backgroundColor: Color.WHITE,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.54)",
      },
    },
    addIcon: {
      width: 16,
      height: 16,
    },
  });

export default styles;
