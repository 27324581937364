import createStyles from "@material-ui/core/styles/createStyles";
import ModuleIcon from "@material-ui/icons/CollectionsBookmark";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      "& .jsonView": {
        marginTop: 15,
      },
    },
  });

export default styles;
