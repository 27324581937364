import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import FiltersMenu from "@components/filters-menu";
import { PopoverOrigin } from "@material-ui/core/Popover";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import { WorkloadsFilters, WorkloadsFiltersActions, WorkloadsFiltersModel } from "@components";

const DEFAULT_ANCHOR_ORIGIN: PopoverOrigin = {
  horizontal: "right",
  vertical: "top",
};

const DEFAULT_TRANSFORM_ORIGIN: PopoverOrigin = {
  horizontal: "left",
  vertical: "top",
};

export interface WorkloadsFiltersMenuModel extends WorkloadsFiltersModel {
  className?: string;
  disabled?: boolean;
  open?: boolean;
  showBadge?: boolean;
  badgeCount?: number;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export interface WorkloadsFiltersMenuActions extends WorkloadsFiltersActions {
  openMenu?: () => void;
  closeMenu?: () => void;
}

type Model = WorkloadsFiltersMenuModel;
type Actions = WorkloadsFiltersMenuActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const WorkloadsFiltersMenu = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    disabled,
    open = false,
    badgeCount = 0,
    showBadge = badgeCount > 0,
    anchorOrigin = DEFAULT_ANCHOR_ORIGIN,
    transformOrigin = DEFAULT_TRANSFORM_ORIGIN,
    openMenu = noop,
    closeMenu = noop,
    children,
    ...otherProps
  } = props;

  return (
    <FiltersMenu
      className={classnames("workloadsFiltersMenu", className, classes.container)}
      showBadge={showBadge}
      badgeCount={badgeCount}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      open={open}
      disabled={disabled}
      openMenu={openMenu}
      closeMenu={closeMenu}
    >
      <WorkloadsFilters
        {...otherProps}
        disabled={disabled}
        className={classnames("filters", classes.filters)}
      >
        {children}
      </WorkloadsFilters>
    </FiltersMenu>
  );
});

export default WorkloadsFiltersMenu;
