import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";

export interface PublishDashboardSchema extends BasePortalModuleSchema {
  dashboardId: number;
  publishable: boolean;
  publishDashboard: boolean;
  publishedDashboardId: number;
}

export const DEFAULT_STATE: PublishDashboardSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  publishDashboard: false,
  dashboardId: -1,
  publishable: true,
  publishedDashboardId: -1,
});

type PublishDashboardActionType = PortalModuleActionTypes<PublishDashboardSchema> & {
  PUBLISH_DASHBOARD_REQUEST: string;
  PUBLISH_DASHBOARD_SUCCESS: string;
  PUBLISH_DASHBOARD_FAILED: string;
};

export const ACTION_TYPES: PublishDashboardActionType = Object.freeze({
  ...createPortalModuleActionTypes<PublishDashboardSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    PUBLISH_DASHBOARD_REQUEST: `${MODULE_ID}/publish_dashboard_request`,
    PUBLISH_DASHBOARD_SUCCESS: `${MODULE_ID}/publish_dashboard_success`,
    PUBLISH_DASHBOARD_FAILED: `${MODULE_ID}/publish_dashboard_failed`,
  },
});

export const PublishDashboardReducer = combineReducers<PublishDashboardSchema>(
  createPortalModuleReducers<PublishDashboardSchema>(ACTION_TYPES, DEFAULT_STATE));

export default PublishDashboardReducer;
