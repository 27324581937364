import React from "react";
import { CreateWorkloadResponse } from "@network";
import {
  CreateWorkloadRequestV3,
  CreateWorkloadRequestAttributesV3,
} from "@data/CreateWorkloadRequestV3";
import { CreateWorkloadRequest } from "@data";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useWorkloadManagerClient,
} from "@hooks";
import { isDataWorkloadsOneDotThreeFeaturesEnabled } from "@util";

type SuccessResponse = CreateWorkloadResponse;

export interface UseCreateWorkloadProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  data?: CreateWorkloadRequestV3;
}

export interface UseCreateWorkloadModel extends UseApiRequestModel<SuccessResponse> {
  name: string;
  version: number;
  successMessage: string;
}

export interface UseCreateWorkloadActions extends UseApiRequestActions<SuccessResponse> {
  createWorkload: () => void;
}

type Props = UseCreateWorkloadProps;
type Model = UseCreateWorkloadModel;
type Actions = UseCreateWorkloadActions;
type Result = [ Model, Actions ];

export const useCreateWorkload = (props: Props): Result => {

  const {
    data = CreateWorkloadRequestV3.EMPTY,
    defaultErrorMessage = "Failed to create workload",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const useNewModel = isDataWorkloadsOneDotThreeFeaturesEnabled();

  const attrs = React.useMemo<CreateWorkloadRequestAttributesV3>(() => {
    const workload = useNewModel ? data : CreateWorkloadRequest.from(data);
    const { type, policy, runtime, entryPoint, timeout, memory, query, ...otherData } = workload.toJS();
    const { upload, ...otherQueryData } = query;

    return {
      type,
      "@type": type,
      ...(data.hasPolicy() ? ({ policy }) : ({})),
      ...(data.isSelectQuery() ? ({ query }) : ({})),
      ...(data.isInsertQuery() ? ({ query: otherQueryData }) : ({})),
      ...(data.isFunctionType() ? ({ runtime, entryPoint, timeout, memory }) : ({})),
      ...otherData,
    };
  }, [ data ]);

  const makeApiRequest = React.useCallback(() =>
      WorkloadManagerClient.createWorkloadApi(JSON.stringify(attrs)),
    [ WorkloadManagerClient, attrs ]);

  const [ { showSuccessView, successResponse, ...baseModel }, {
    refresh: createWorkload,
    ...baseActions
  } ] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Workload Created" : "",
    [ showSuccessView ]);

  const name = React.useMemo<string>(() => {
    const { name: workloadName } = attrs;
    return workloadName;
  }, [ attrs ]);

  const version = React.useMemo<number>(() => {
    const { version: workloadVersion = 1 } = successResponse || {};
    return workloadVersion;
  }, [ successResponse ]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
    name,
    version,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
    name,
    version,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    createWorkload,
    refresh: createWorkload,
  }), [
    baseActions,
    createWorkload,
  ]);

  return React.useMemo<Result>(() => [ model, actions ], [ model, actions ]);
};

export default useCreateWorkload;
