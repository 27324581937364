import { Module } from "@data";
import { createModule } from "@modules/base";
import { reset } from "./actions/schemaWizard";
import { isCommonServicesModulesDisabled } from "@util";
import { isAccessDeniedVisible, isEmptyViewVisible } from "./selectors/schemaWizard";
import { getCreateSchemaPath, getEditSchemaPath } from "./helpers";
import { primaryColor, PrimaryIcon as icon } from "../schemas/components/styles";
import CreateSchemaView from "./CreateSchemaView";
import EditSchemaView from "./EditSchemaView";
import BackButton from "./containers/BackButton";

type RouteParams = { id?: string; };

export const createSchemaWizardModule: Module = createModule({
  id: "create_schema",
  path: getCreateSchemaPath(),
  exactPath: true,
  strictPath: true,
  name: "Create Schema Wizard",
  title: "Create New Schema Wizard",
  className: "createSchemaWizardModule",
  primaryColor,
  icon,
  BackButton,
  view: CreateSchemaView,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: reset,
  disabled: isCommonServicesModulesDisabled(),
});

export const editSchemaWizardModule: Module = createModule<RouteParams>({
  id: "edit_schema",
  path: getEditSchemaPath(":id"),
  exactPath: true,
  strictPath: true,
  name: "Edit Schema Wizard",
  title: "Edit Schema Wizard",
  className: "editSchemaWizardModule",
  primaryColor,
  icon,
  BackButton,
  view: EditSchemaView,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  skipInitializeOnRouteParamsChange: true,
  onMount: reset,
  disabled: isCommonServicesModulesDisabled(),
});

export default createSchemaWizardModule;
