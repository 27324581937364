import React from "react";
import classnames from "classnames";
import camelCase from "lodash/camelCase";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@components/text-field";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { formControlLabel, styles, textField } from "./styles";
import { ConfirmationDialog } from "../../confirmation-dialog";
import { SearchFilter, SearchFilterCondition } from "../../../data";
import {
  enterKeyHandler,
  equalsIgnoreCase,
  formEventHandler,
  isEmptyString,
  noop,
} from "../../../util";

const FilterKeyTextField = withStyles(textField)(TextField);
const FilterValueTextField = withStyles(textField)(TextField);
const VerbatimFormControlLabel = withStyles(formControlLabel)(FormControlLabel);

const DEFAULT_CONDITIONS = Object.freeze([
  SearchFilterCondition.CONTAINS,
  SearchFilterCondition.DOES_NOT_CONTAIN,
  SearchFilterCondition.EQUALS,
  SearchFilterCondition.DOES_NOT_EQUAL,
]);

const DEFAULT_CONDITION_TO_LABEL_MAPPER = (condition: SearchFilterCondition): string => {
  return `${condition}`.replace(/[_\-]/g, " ");
};

export interface Model {
  open?: boolean;
  filters?: SearchFilter[];
  selectedFilter?: SearchFilter;
  availableConditions?: SearchFilterCondition[];
}

export interface Actions {
  cancel?: () => void;
  addFilter?: (filter: SearchFilter) => void;
  updateFilter?: (filter: SearchFilter) => void;
  mapConditionToLabel?: (condition: SearchFilterCondition) => string;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const AddFilterDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    open,
    filters = [],
    selectedFilter = SearchFilter.EMPTY,
    availableConditions = DEFAULT_CONDITIONS.slice(),
    cancel = noop,
    addFilter = noop,
    updateFilter = noop,
    mapConditionToLabel = DEFAULT_CONDITION_TO_LABEL_MAPPER,
  } = props;

  const formHelperTextProps = {
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const [filter, setFilter] = React.useState<SearchFilter>(
    new SearchFilter(selectedFilter.toJS()));

  const [errorMessage, setErrorMessage] = React.useState("");
  const [filterKeyErrorMessage, setFilterKeyErrorMessage] = React.useState("");
  const [filterValueErrorMessage, setFilterValueErrorMessage] = React.useState("");

  const onClickAddFilter = React.useCallback(() => {
    if (!filter.hasKey()) {
      setFilterKeyErrorMessage("Missing argument [key]");
    } else if (!filter.hasValue()) {
      setFilterValueErrorMessage("Missing argument [value]");
    } else if (!SearchFilter.EMPTY.equals(selectedFilter)) {
      updateFilter(filter);
    } else {

      const query = filter.getSearchQuery();

      const alreadyExists = filters.some((searchFilter: SearchFilter) =>
        equalsIgnoreCase(query, searchFilter.getSearchQuery()));

      if (alreadyExists) {
        setErrorMessage("There is already a filter for this key/value/condition");
      } else {
        addFilter(filter);
      }
    }
  }, [
    filter,
    filters,
    selectedFilter,
    setErrorMessage,
    setFilterKeyErrorMessage,
    setFilterValueErrorMessage,
    addFilter,
    updateFilter,
  ]);

  const setFilterKey = React.useCallback((key: string) =>
    setFilter(new SearchFilter({ ...filter.toJS(), key })), [filter, setFilter]);

  const setFilterKeyVerbatim = React.useCallback((isKeyVerbatim: boolean) =>
    setFilter(new SearchFilter({ ...filter.toJS(), isKeyVerbatim })), [filter, setFilter]);

  const setFilterValue = React.useCallback((value: string) =>
    setFilter(new SearchFilter({ ...filter.toJS(), value })), [filter, setFilter]);

  const setFilterValueVerbatim = React.useCallback((isValueVerbatim: boolean) =>
    setFilter(new SearchFilter({ ...filter.toJS(), isValueVerbatim })), [filter, setFilter]);

  const setFilterCondition = React.useCallback((condition: SearchFilterCondition) =>
    setFilter(new SearchFilter({ ...filter.toJS(), condition })), [filter, setFilter]);

  const continueButtonDisabled = React.useMemo(() =>
    isEmptyString(filter.getSearchQuery()), [filter]);

  const continueButtonLabel = React.useMemo(() => {
    if (SearchFilter.EMPTY.equals(selectedFilter)) {
      return "Add Filter";
    } else {
      return "Update Filter";
    }
  }, [selectedFilter]);

  React.useEffect(() => {
    setErrorMessage("");
    setFilterKeyErrorMessage("");
    setFilterValueErrorMessage("");
  }, [
    filter,
    setErrorMessage,
    setFilterKeyErrorMessage,
    setFilterValueErrorMessage,
  ]);

  return (
    <ConfirmationDialog
      className={classnames("addFilterDialog", classes.container)}
      title="Filter by schema property"
      continueButtonLabel={continueButtonLabel}
      maxWidth="sm"
      continueButtonDisabled={continueButtonDisabled}
      errorMessage={errorMessage}
      open={open}
      cancel={cancel}
      confirm={onClickAddFilter}
    >
      <div className={classnames("addFilter", classes.addFilter)}>
        <div className={classnames("filterKeyContainer", classes.filterKeyContainer)}>
          <FilterKeyTextField
            className={classnames("filterKey", classes.inputField)}
            autoFocus={true}
            autoComplete="off"
            label="Schema property name"
            placeholder="Schema property name"
            name="filterKey"
            value={filter.key}
            fullWidth={true}
            helperText={filterKeyErrorMessage}
            variant="outlined"
            margin="none"
            error={filterKeyErrorMessage.length > 0}
            onChange={formEventHandler(setFilterKey)}
            FormHelperTextProps={formHelperTextProps}
            InputLabelProps={inputLabelProps}
          />
          <VerbatimFormControlLabel
            className={classnames("checkboxContainer", classes.checkboxContainer)}
            label="Case Sensitive"
            control={(
              <Checkbox
                className={classnames("checkbox", classes.checkbox)}
                checked={filter.isKeyVerbatim}
                onChange={formEventHandler(() => setFilterKeyVerbatim(!filter.isKeyVerbatim))}
              />
            )}
          />
        </div>
        <FormControl className={classnames("filterConditionContainer", classes.filterConditionContainer)}>
          <Select
            className={classnames("filterConditionDropdown", classes.filterConditionDropdown)}
            value={filter.condition}
            autoWidth={true}
            disableUnderline={true}
            onChange={formEventHandler(setFilterCondition)}
          >
            {availableConditions.map((condition: SearchFilterCondition) => (
              <MenuItem
                key={`filter_condition-${condition}`.toLowerCase()}
                className={classnames(
                  "filterCondition",
                  camelCase(condition),
                  classes.filterCondition,
                )}
                value={condition}
                selected={filter.condition === condition}
              >
                {mapConditionToLabel(condition)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={classnames("filterValueContainer", classes.filterValueContainer)}>
          <FilterValueTextField
            className={classnames("filterValue", classes.inputField)}
            autoComplete="off"
            label="Schema property value"
            placeholder="Schema property value"
            name="filterValue"
            value={filter.value}
            fullWidth={true}
            helperText={filterValueErrorMessage}
            variant="outlined"
            margin="none"
            error={filterValueErrorMessage.length > 0}
            onChange={formEventHandler(setFilterValue)}
            onKeyDown={enterKeyHandler(onClickAddFilter)}
            FormHelperTextProps={formHelperTextProps}
            InputLabelProps={inputLabelProps}
          />
          <VerbatimFormControlLabel
            className={classnames("checkboxContainer", classes.checkboxContainer)}
            label="Case Sensitive"
            control={(
              <Checkbox
                className={classnames("checkbox", classes.checkbox)}
                checked={filter.isValueVerbatim}
                onChange={formEventHandler(() => setFilterValueVerbatim(!filter.isValueVerbatim))}
              />
            )}
          />
        </div>
      </div>
    </ConfirmationDialog>
  );
});

export default AddFilterDialog;
