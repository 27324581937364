import React from "react";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  useWorkloadManagerClient
} from "@hooks";
import WorkloadConfigurationOperation from "./WorkloadConfigurationOperation";
import lowerCase from "lodash/lowerCase";
import upperFirst from "lodash/upperFirst";

type SuccessResponse = void;

export interface UseWorkloadConfigurationManagementProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  operation: WorkloadConfigurationOperation;
  key?: string;
  value?: string;
  keys?: string[];
}

export interface UseWorkloadConfigurationManagementModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseWorkloadConfigurationManagementActions {
  performOperation: () => void;
}

type Props = UseWorkloadConfigurationManagementProps;
type Model = UseWorkloadConfigurationManagementModel;
type Actions = UseWorkloadConfigurationManagementActions;
type Result = [Model, Actions];

export const useWorkloadConfigurationManagement = (props: Props): Result => {

  const {
    name,
    version,
    key = "",
    value = "",
    keys = [],
    operation = WorkloadConfigurationOperation.NONE,
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const mapApiToOperation = React.useCallback(() => {
    switch (operation) {
      case WorkloadConfigurationOperation.ADD:
        return WorkloadManagerClient.updateWorkloadConfiguration(name, version, { [key]: value });
      case WorkloadConfigurationOperation.DELETE_KEYS:
        return WorkloadManagerClient.deleteWorkloadConfigurationKeys(name, version, keys);
      case WorkloadConfigurationOperation.DELETE_BY_KEY:
        return WorkloadManagerClient.deleteWorkloadConfigurationByKey(name, version, key);
      case WorkloadConfigurationOperation.EDIT:
        return WorkloadManagerClient.updateWorkloadConfigurationByKey(name, version, key, value);
      default:
        return Promise.resolve();
    }
  }, [operation, name, version, key, value, keys]);

  const mapDefaultErrorMessage = React.useMemo(() => {
    switch (operation) {
      case WorkloadConfigurationOperation.ADD:
        return "Failed to add workload configuration";
      case WorkloadConfigurationOperation.DELETE_KEYS:
        return "Failed to delete workload configuration";
      case WorkloadConfigurationOperation.DELETE_BY_KEY:
        return "Failed to delete configuration";
      case WorkloadConfigurationOperation.EDIT:
        return "Failed to edit configuration";
      default:
        return "Operation Failed";
    }
  }, [operation]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: performOperation }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage: mapDefaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => mapApiToOperation(),
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? `${upperFirst(lowerCase(operation))} Successful` : "" ,
    [showSuccessView, operation]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    performOperation,
  }), [
    performOperation,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useWorkloadConfigurationManagement;
