import React from "react";
import {
  UseApiRequestModel,
  UseApiRequestProps,
  useApiRequest,
  useSecurityServicesRegionalClient
} from "@hooks";
import { ServiceSecretOperation } from "./ServiceSecretOperation";
import { SecurityServiceRegionalAttributes, SecurityServiceRegional } from "@data";

type SuccessResponse = SecurityServiceRegionalAttributes | void;

export interface UseServiceSecretManagementProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  serviceId: string;
  secret?: string;
  operation: ServiceSecretOperation;
}

export interface UseServiceSecretManagementModel extends UseApiRequestModel<SuccessResponse> {
  service: SecurityServiceRegional;
  successMessage: string;
}

export interface UseServiceSecretManagementActions {
  performOperation: () => void;
}

type Props = UseServiceSecretManagementProps;
type Model = UseServiceSecretManagementModel;
type Actions = UseServiceSecretManagementActions;
type Result = [Model, Actions];

export const useServiceSecretManagement = (props: Props): Result => {

  const {
    serviceId = "",
    secret = "",
    operation = ServiceSecretOperation.NONE,
    ...otherProps
  } = props;

  const SecurityServicesRegionalClient = useSecurityServicesRegionalClient();

  const defaultErrorMessage = React.useMemo(() =>
    `Failed to ${operation} service secret`, [operation]);

  const mapApiToOperation = React.useCallback(() => {
    switch (operation) {
    case ServiceSecretOperation.DELETE:
      return SecurityServicesRegionalClient.deleteServiceSecretApi(serviceId);
    case ServiceSecretOperation.SET:
      return SecurityServicesRegionalClient.setServiceSecretApi(serviceId, JSON.stringify({ secret }));
    case ServiceSecretOperation.GENERATE:
      return SecurityServicesRegionalClient.generateServiceSecretApi(serviceId);
    default:
      return Promise.resolve();
    }
  }, [operation, serviceId, secret]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: performOperation }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => mapApiToOperation(),
    });

  const service = React.useMemo<SecurityServiceRegional>(() => {
    const attrs = successResponse || {};
    return new SecurityServiceRegional(attrs);
  }, [successResponse]);

  const successMessage = React.useMemo(() =>
      showSuccessView ? `${operation} Successful` : "" ,
    [showSuccessView, operation]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    service,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    service,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    performOperation
  }), [performOperation]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useServiceSecretManagement;
