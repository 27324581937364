import React from "react";
import { connect } from "react-redux";
import AppSchema from "@schemas";
import { PolicyBulkRequest } from "@data/PolicyBulkRequest";
import { ManagedPolicyBulkRequest } from "@data/ManagedPolicyBulkRequest";
import ReviewViewList from "@modules/policyManagement/components/ReviewViewList";
import { getPolicyBulkRequest, isManagedPolicyViewSelected } from "@modules/policyManagement/selectors";

interface ContainerModel {
  policyBulkRequest?: PolicyBulkRequest[] | ManagedPolicyBulkRequest[];
  isManagedPolicy?: boolean;
}

const ReviewViewContainer = (props: ContainerModel) => {

  const {
    policyBulkRequest = [],
    isManagedPolicy = false,
  } = props;

  return (
    <ReviewViewList
      items={policyBulkRequest}
      isManagedPolicy={isManagedPolicy}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  policyBulkRequest: getPolicyBulkRequest(state),
  isManagedPolicy: isManagedPolicyViewSelected(state),
});

export default connect<ContainerModel, {}, ContainerModel>(
  mapStateToProps
)(ReviewViewContainer);
