import React from "react";
import classnames from "classnames";
import TextField from "../text-field";
import { useDeleteServiceMfa } from "@hooks";
import { enterKeyHandler, formEventHandler, isEmptyString } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "../portal-module-dialog";
import styles from "./styles";

export interface DisableServiceMfaDialogMfaDialogModel extends PortalModuleDialogModel<string> {
  authenticatorId?: string;
}

export interface DisableServiceMfaDialogMfaDialogActions extends PortalModuleDialogActions<string> {
}

type Model = DisableServiceMfaDialogMfaDialogModel;
type Actions = DisableServiceMfaDialogMfaDialogActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DisableServiceMfaDialogMfaDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    authenticatorId,
    title = "Disable Multi-factor Authentication",
    maxWidth = "sm",
    children,
    ...otherProps
  } = props;

  const [code, setCode] = React.useState("");

  const [codeErrorMessage, setCodeErrorMessage] = React.useState("");

  const [model, actions] = useDeleteServiceMfa({
    code,
    id: authenticatorId,
  });

  const {
    errorMessage,
    successMessage,
    showLoadingIndicator: loading,
    showAccessDenied: accessDenied ,
  } = model;

  const { deleteAuthenticator, reset } = actions;

  const updateCode = React.useCallback((updatedValue: string) => {
    reset();
    setCodeErrorMessage("");
    setCode(updatedValue);
  }, [reset, setCode, setCodeErrorMessage]);

  React.useEffect(() => {
    setCodeErrorMessage(errorMessage);
  }, [errorMessage, setCodeErrorMessage]);

  return (
    <PortalModuleDialog
      {...otherProps}
      className={classnames("disableServiceMfaDialog", className, classes.container)}
      title={title}
      maxWidth={maxWidth}
      loading={loading}
      accessDenied={accessDenied}
      errorMessage={errorMessage}
      successMessage={successMessage}
      confirm={deleteAuthenticator}
      continueButtonLabel="Disable MFA"
      continueButtonDisabled={isEmptyString(code) || !isEmptyString(codeErrorMessage)}
    >
      <div className={classnames("content", classes.content)}>
        <label className={classnames("title", classes.title)}>
          Are you sure you want to disable multi-factor authentication?
        </label>
        <label className={classnames("subtitle", classes.subtitle)}>
          <span className={classes.warning}>WARNING:</span>This action cannot be undone!
        </label>
        <TextField
          className={classnames("mfaCode", classes.textField, classes.mfaCode)}
          size="small"
          name="mfaCode"
          color="secondary"
          variant="outlined"
          label="Authenticator Code"
          fullWidth={true}
          InputLabelProps={{ shrink: true }}
          disabled={loading}
          error={!isEmptyString(codeErrorMessage)}
          helperText={codeErrorMessage || "Please enter the most recent authenticator code to continue"}
          value={code}
          onChange={formEventHandler(updateCode)}
          onKeyDown={enterKeyHandler(deleteAuthenticator)}
        />
      </div>
      {children}
    </PortalModuleDialog>
  );
});

export default DisableServiceMfaDialogMfaDialog;
