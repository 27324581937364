import createStyles from "@material-ui/core/styles/createStyles";
import ModuleIcon from "@material-ui/icons/Description";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    container: {},
    createButton: {
    },
  });

export default styles;
