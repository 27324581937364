import { ApplicationAttributes, ApplicationUserManagementAttributes } from "@data";
import { useApiRequestAnalytics } from "@hooks";
import { ApplicationManagerRegionalClient } from "@network";

export type GetApplicationsRegionalApi = () => Promise<ApplicationAttributes[]>;

export type GetApplicationRegionalApi = (applicationId: string) => Promise<ApplicationAttributes>;

export type DeleteApplicationRegionalApi = (applicationId: string) => Promise<void>;

export type EnableApplicationRegionalApi = (applicationId: string) => Promise<void>;

export type DisableApplicationRegionalApi = (applicationId: string) => Promise<void>;

export type GenerateApplicationSecretApi = (applicationId: string) => Promise<ApplicationAttributes>;

export type DeleteApplicationSecretApi = (applicationId: string) => Promise<void>;

export type SetApplicationSecretApi = (applicationId: string, json: string) => Promise<void>;

export type AuthenticateApplicationApi = (applicationId: string, json: string) => Promise<void>;

export type CreateApplicationRegionalApi = (json: string) => Promise<ApplicationAttributes>;

export type EditApplicationRegionalApi = (applicationId: string, json: string) => Promise<void>;

export type GetApplicationUserManagementApi = (applicationId: string) => Promise<ApplicationUserManagementAttributes>;

export type UpdateApplicationUserManagementApi = (applicationId: string, json: string) => Promise<void>;

export interface ApplicationManagerRegionalClientApi {
  getApplicationsRegionalApi: GetApplicationsRegionalApi;
  getApplicationRegionalApi: GetApplicationRegionalApi;
  deleteApplicationRegionalApi: DeleteApplicationRegionalApi;
  enableApplicationRegionalApi: EnableApplicationRegionalApi;
  disableApplicationRegionalApi: DisableApplicationRegionalApi;
  generateApplicationSecretApi: GenerateApplicationSecretApi;
  deleteApplicationSecretApi: DeleteApplicationSecretApi;
  setApplicationSecretApi: SetApplicationSecretApi;
  authenticateApplicationApi: AuthenticateApplicationApi;
  createApplicationRegionalApi: CreateApplicationRegionalApi;
  editApplicationRegionalApi: EditApplicationRegionalApi;
  getApplicationUserManagementApi: GetApplicationUserManagementApi;
  updateApplicationUserManagementApi: UpdateApplicationUserManagementApi;
}

enum ActionTypes {
  GET_APPLICATIONS_REGIONAL_REQUEST = "application_manager_regional_client/get_applications_regional_request",
  GET_APPLICATIONS_REGIONAL_SUCCESS = "application_manager_regional_client/get_applications_regional_success",
  GET_APPLICATIONS_REGIONAL_FAILED = "application_manager_regional_client/get_applications_regional_failed",

  GET_APPLICATION_REGIONAL_REQUEST = "application_manager_regional_client/get_application_regional_request",
  GET_APPLICATION_REGIONAL_SUCCESS = "application_manager_regional_client/get_application_regional_success",
  GET_APPLICATION_REGIONAL_FAILED = "application_manager_regional_client/get_application_regional_failed",

  DELETE_APPLICATION_REGIONAL_REQUEST = "application_manager_regional_client/delete_application_regional_request",
  DELETE_APPLICATION_REGIONAL_SUCCESS = "application_manager_regional_client/delete_application_regional_success",
  DELETE_APPLICATION_REGIONAL_FAILED = "application_manager_regional_client/delete_application_regional_failed",

  ENABLE_APPLICATION_REGIONAL_REQUEST = "application_manager_regional_client/enable_application_regional_request",
  ENABLE_APPLICATION_REGIONAL_SUCCESS = "application_manager_regional_client/enable_application_regional_success",
  ENABLE_APPLICATION_REGIONAL_FAILED = "application_manager_regional_client/enable_application_regional_failed",

  DISABLE_APPLICATION_REGIONAL_REQUEST = "application_manager_regional_client/disable_application_regional_request",
  DISABLE_APPLICATION_REGIONAL_SUCCESS = "application_manager_regional_client/disable_application_regional_success",
  DISABLE_APPLICATION_REGIONAL_FAILED = "application_manager_regional_client/disable_application_regional_failed",

  GENERATE_APPLICATION_SECRET_REQUEST = "application_manager_regional_client/generate_application_secret_request",
  GENERATE_APPLICATION_SECRET_SUCCESS = "application_manager_regional_client/generate_application_secret_success",
  GENERATE_APPLICATION_SECRET_FAILED = "application_manager_regional_client/generate_application_secret_failed",

  DELETE_APPLICATION_SECRET_REQUEST = "application_manager_regional_client/delete_application_secret_request",
  DELETE_APPLICATION_SECRET_SUCCESS = "application_manager_regional_client/delete_application_secret_success",
  DELETE_APPLICATION_SECRET_FAILED = "application_manager_regional_client/delete_application_secret_failed",

  SET_APPLICATION_SECRET_REQUEST = "application_manager_regional_client/set_application_secret_request",
  SET_APPLICATION_SECRET_SUCCESS = "application_manager_regional_client/set_application_secret_success",
  SET_APPLICATION_SECRET_FAILED = "application_manager_regional_client/set_application_secret_failed",

  AUTHENTICATE_APPLICATION_REQUEST = "application_manager_regional_client/authenticate_application_request",
  AUTHENTICATE_APPLICATION_SUCCESS = "application_manager_regional_client/authenticate_application_success",
  AUTHENTICATE_APPLICATION_FAILED = "application_manager_regional_client/authenticate_application_failed",

  CREATE_APPLICATION_REGIONAL_REQUEST = "application_manager_regional_client/create_application_regional_request",
  CREATE_APPLICATION_REGIONAL_SUCCESS = "application_manager_regional_client/create_application_regional_success",
  CREATE_APPLICATION_REGIONAL_FAILED = "application_manager_regional_client/create_application_regional_failed",

  EDIT_APPLICATION_REGIONAL_REQUEST = "application_manager_regional_client/edit_application_regional_request",
  EDIT_APPLICATION_REGIONAL_SUCCESS = "application_manager_regional_client/edit_application_regional_success",
  EDIT_APPLICATION_REGIONAL_FAILED = "application_manager_regional_client/edit_application_regional_failed",

  GET_APPLICATION_USER_MANAGEMENT_REQUEST = "application_manager_regional_client/get_application_user_management_request",
  GET_APPLICATION_USER_MANAGEMENT_SUCCESS = "application_manager_regional_client/get_application_user_management_success",
  GET_APPLICATION_USER_MANAGEMENT_FAILED = "application_manager_regional_client/get_application_user_management_failed",

  UPDATE_APPLICATION_USER_MANAGEMENT_REQUEST = "application_manager_regional_client/update_application_user_management_request",
  UPDATE_APPLICATION_USER_MANAGEMENT_SUCCESS = "application_manager_regional_client/update_application_user_management_success",
  UPDATE_APPLICATION_USER_MANAGEMENT_FAILED = "application_manager_regional_client/update_application_user_management_failed",
}

export const useGetApplicationsRegionalApi = (): GetApplicationsRegionalApi =>
  useApiRequestAnalytics(ApplicationManagerRegionalClient.getApplicationsRegionalApi, {
    REQUEST_EVENT: ActionTypes.GET_APPLICATIONS_REGIONAL_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_APPLICATIONS_REGIONAL_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_APPLICATIONS_REGIONAL_FAILED,
  });

export const useGetApplicationRegionalApi = (): GetApplicationRegionalApi =>
  useApiRequestAnalytics(ApplicationManagerRegionalClient.getApplicationRegionalApi, {
    REQUEST_EVENT: ActionTypes.GET_APPLICATION_REGIONAL_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_APPLICATION_REGIONAL_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_APPLICATION_REGIONAL_FAILED,
  });

export const useDeleteApplicationRegionalApi = (): DeleteApplicationRegionalApi =>
  useApiRequestAnalytics(ApplicationManagerRegionalClient.deleteApplicationRegionalApi, {
    REQUEST_EVENT: ActionTypes.DELETE_APPLICATION_REGIONAL_REQUEST,
    SUCCESS_EVENT: ActionTypes.DELETE_APPLICATION_REGIONAL_SUCCESS,
    ERROR_EVENT: ActionTypes.DELETE_APPLICATION_REGIONAL_FAILED,
  });

export const useEnableApplicationRegionalApi = (): EnableApplicationRegionalApi =>
  useApiRequestAnalytics(ApplicationManagerRegionalClient.enableApplicationRegionalApi, {
    REQUEST_EVENT: ActionTypes.ENABLE_APPLICATION_REGIONAL_REQUEST,
    SUCCESS_EVENT: ActionTypes.ENABLE_APPLICATION_REGIONAL_SUCCESS,
    ERROR_EVENT: ActionTypes.ENABLE_APPLICATION_REGIONAL_FAILED,
  });

export const useDisableApplicationRegionalApi = (): DisableApplicationRegionalApi =>
  useApiRequestAnalytics(ApplicationManagerRegionalClient.disableApplicationRegionalApi, {
    REQUEST_EVENT: ActionTypes.DISABLE_APPLICATION_REGIONAL_REQUEST,
    SUCCESS_EVENT: ActionTypes.DISABLE_APPLICATION_REGIONAL_SUCCESS,
    ERROR_EVENT: ActionTypes.DISABLE_APPLICATION_REGIONAL_FAILED,
  });

export const useGenerateApplicationSecretApi = (): GenerateApplicationSecretApi =>
  useApiRequestAnalytics(ApplicationManagerRegionalClient.generateApplicationSecret, {
    REQUEST_EVENT: ActionTypes.GENERATE_APPLICATION_SECRET_REQUEST,
    SUCCESS_EVENT: ActionTypes.GENERATE_APPLICATION_SECRET_SUCCESS,
    ERROR_EVENT: ActionTypes.GENERATE_APPLICATION_SECRET_FAILED,
  });

export const useDeleteApplicationSecretApi = (): DeleteApplicationSecretApi =>
  useApiRequestAnalytics(ApplicationManagerRegionalClient.deleteApplicationSecret, {
    REQUEST_EVENT: ActionTypes.DELETE_APPLICATION_SECRET_REQUEST,
    SUCCESS_EVENT: ActionTypes.DELETE_APPLICATION_SECRET_SUCCESS,
    ERROR_EVENT: ActionTypes.DELETE_APPLICATION_SECRET_FAILED,
  });

export const useSetApplicationSecretApi = (): SetApplicationSecretApi =>
  useApiRequestAnalytics(ApplicationManagerRegionalClient.setApplicationSecret, {
    REQUEST_EVENT: ActionTypes.SET_APPLICATION_SECRET_REQUEST,
    SUCCESS_EVENT: ActionTypes.SET_APPLICATION_SECRET_SUCCESS,
    ERROR_EVENT: ActionTypes.SET_APPLICATION_SECRET_FAILED,
  });

export const useAuthenticateApplicationApi = (): AuthenticateApplicationApi =>
  useApiRequestAnalytics(ApplicationManagerRegionalClient.authenticateApplication, {
    REQUEST_EVENT: ActionTypes.AUTHENTICATE_APPLICATION_REQUEST,
    SUCCESS_EVENT: ActionTypes.AUTHENTICATE_APPLICATION_SUCCESS,
    ERROR_EVENT: ActionTypes.AUTHENTICATE_APPLICATION_FAILED,
  });

export const useCreateApplicationRegionalApi = (): CreateApplicationRegionalApi =>
  useApiRequestAnalytics(ApplicationManagerRegionalClient.createApplicationRegionalApi, {
    REQUEST_EVENT: ActionTypes.CREATE_APPLICATION_REGIONAL_REQUEST,
    SUCCESS_EVENT: ActionTypes.CREATE_APPLICATION_REGIONAL_SUCCESS,
    ERROR_EVENT: ActionTypes.CREATE_APPLICATION_REGIONAL_FAILED,
  });

export const useEditApplicationRegionalApi = (): EditApplicationRegionalApi =>
  useApiRequestAnalytics(ApplicationManagerRegionalClient.editApplicationRegionalApi, {
    REQUEST_EVENT: ActionTypes.EDIT_APPLICATION_REGIONAL_REQUEST,
    SUCCESS_EVENT: ActionTypes.EDIT_APPLICATION_REGIONAL_SUCCESS,
    ERROR_EVENT: ActionTypes.EDIT_APPLICATION_REGIONAL_FAILED,
  });

export const useGetApplicationUserManagementApi = (): GetApplicationUserManagementApi =>
  useApiRequestAnalytics(ApplicationManagerRegionalClient.getApplicationUserManagement, {
    REQUEST_EVENT: ActionTypes.GET_APPLICATION_USER_MANAGEMENT_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_APPLICATION_USER_MANAGEMENT_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_APPLICATION_USER_MANAGEMENT_FAILED,
  });

export const useUpdateApplicationUserManagementApi = (): UpdateApplicationUserManagementApi =>
  useApiRequestAnalytics(ApplicationManagerRegionalClient.updateApplicationUserManagement, {
    REQUEST_EVENT: ActionTypes.UPDATE_APPLICATION_USER_MANAGEMENT_REQUEST,
    SUCCESS_EVENT: ActionTypes.UPDATE_APPLICATION_USER_MANAGEMENT_SUCCESS,
    ERROR_EVENT: ActionTypes.UPDATE_APPLICATION_USER_MANAGEMENT_FAILED,
  });

export const useApplicationManagerRegionalClient = (): ApplicationManagerRegionalClientApi => ({
  getApplicationsRegionalApi: useGetApplicationsRegionalApi(),
  getApplicationRegionalApi: useGetApplicationRegionalApi(),
  deleteApplicationRegionalApi: useDeleteApplicationRegionalApi(),
  enableApplicationRegionalApi: useEnableApplicationRegionalApi(),
  disableApplicationRegionalApi: useDisableApplicationRegionalApi(),
  generateApplicationSecretApi: useGenerateApplicationSecretApi(),
  deleteApplicationSecretApi: useDeleteApplicationSecretApi(),
  setApplicationSecretApi: useSetApplicationSecretApi(),
  authenticateApplicationApi: useAuthenticateApplicationApi(),
  createApplicationRegionalApi: useCreateApplicationRegionalApi(),
  editApplicationRegionalApi: useEditApplicationRegionalApi(),
  getApplicationUserManagementApi: useGetApplicationUserManagementApi(),
  updateApplicationUserManagementApi: useUpdateApplicationUserManagementApi(),
});

export { ActionTypes as ApplicationManagerRegionalClientActionType };

export default useApplicationManagerRegionalClient;
