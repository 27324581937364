import { Color } from "@Color";
import ModuleIcon from "@material-ui/icons/ViewCarousel";
import createStyles from "@material-ui/core/styles/createStyles";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const userManagementView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    errorView: {
      paddingBottom: 10,
      marginBottom: 35,
      "& label.title, & label.message": {
        marginTop: 10,
      },
    },
    summaryContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    summaryItemsContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      position: "relative",
      marginTop: 20,
    },
    name: {
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      fontSize: 16,
      minWidth: 200,
      alignSelf: "flex-start",
    },
    value: {
      backgroundColor: "#eaeaea",
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "monospace",
      fontSize: 12,
      marginLeft: 15,
      fontWeight: 300,
      padding: 10,
    },
    editIcon: {
      marginLeft: 20,
    },
    alert: {
      margin: "0 0 10px 0",
    },
    alertTitle: {
      fontSize: 14,
      marginTop: 2,
    },
    alertDescription: {
      fontSize: 14,
      lineHeight: "21px",
      marginTop: 10,
      marginBottom: 0,
      paddingLeft: 20,
    },
    controls: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      marginLeft: "auto",
      position: "relative",
    },
    saveButton: {
      justifySelf: "flex-end",
      marginLeft: 10,
    },
    cancelButton: {
      fontWeight: 500,
      marginLeft: "auto",
    },
    formControl: {
      minWidth: 250,
    },
    applicationSecurityView: {
      marginTop: 0,
    }
  });
