import React from "react";
import { useApiRequest, UseApiRequestModel, useDataAccessRequestClient } from "@hooks";
import { CreateDataAccessRequestResponse, TrainingStatusResponse } from "@network";

export interface UseTrainingStatusModel extends UseApiRequestModel<CreateDataAccessRequestResponse> {
  trainingCompleted: boolean;
}

export interface UseTrainingStatusActions {
  refresh: () => void;
}

type Model = UseTrainingStatusModel;
type Actions = UseTrainingStatusActions;
type Result = [Model, Actions];

export const useTrainingStatus = (): Result => {

  const DataAccessRequestClient = useDataAccessRequestClient();

  const [{ successResponse, ...baseModel }, { refresh }] = useApiRequest<TrainingStatusResponse>({
    makeApiRequest: DataAccessRequestClient.getTrainingStatus,
    defaultErrorMessage: "Failed to fetch training status",
  });

  const trainingCompleted = React.useMemo(() => {
    const { trainingCompleted: completedTraining = false } = successResponse || {};
    return completedTraining;
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    trainingCompleted,
  }), [
    baseModel,
    trainingCompleted,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    refresh,
  }), [
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useTrainingStatus;
