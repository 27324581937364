import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      color: Color.LIGHT_BLUE,
    },
    icon: {
      width: 16,
      height: 20,
      marginLeft: 0,
      marginRight: 8,
    },
    label: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "24px",
      textDecoration: "none",
      textTransform: "none",
    },
  });

export default styles;
