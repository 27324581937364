import { Module } from "@data";
import { createModule } from "@base/createModule";
import { isEmptyString, isValidNumber } from "@util";
import { getPathToWorkloadInstance } from "./helpers";
import {
  MODULE_ID,
  MODULE_PATH,
  RouteParams,
  VIEW_WORKLOAD_EXECUTION_INSTANCE_REDIRECT,
} from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import DATA_WORKLOADS_REQUIREMENTS from "@modules/dataWorkloads/requirements";
import view from "./WorkloadInstanceDetailsView";
import { DATA_PROCESSING_DEVELOPER_GUIDE } from "@modules/dataWorkloads/constants";

export const workloadInstanceDetailsModule: Module = createModule<RouteParams>({
  ...DATA_WORKLOADS_REQUIREMENTS,
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: false,
  showBreadcrumbs: true,
  name: "Workload Instance Details",
  title: "Workload Instance Details",
  className: "workloadInstanceDetailsModule",
  documentationLink: DATA_PROCESSING_DEVELOPER_GUIDE,
  primaryColor,
  icon,
  view,
  redirects: {
    [VIEW_WORKLOAD_EXECUTION_INSTANCE_REDIRECT]: (action, resourceId, extraQueryParams) => {
      const workloadName = extraQueryParams.get("workloadName") || "";
      const workloadVersion = extraQueryParams.get("workloadVersion") || "";
      if (isEmptyString(workloadName) || !isValidNumber(workloadVersion) || isEmptyString(resourceId)) {
        return null;
      } else {
        extraQueryParams.delete("workloadName");
        extraQueryParams.delete("workloadVersion");
        return `${getPathToWorkloadInstance(workloadName, Number(workloadVersion), resourceId)}?${extraQueryParams}`;
      }
    },
  },
});

export default workloadInstanceDetailsModule;
