import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { JsonEditor, JsonEditorActions, JsonEditorModel } from "@components/json-editor";
import { DeviceEnrollmentWizardSelectors } from "../selectors";
import { DeviceEnrollmentWizardActions } from "../actions";

const mapStateToProps = (state: AppSchema): JsonEditorModel => ({
  className: "devicesJsonEditor",
  json: DeviceEnrollmentWizardSelectors.getDevicesAsJson(state),
  readOnly: false,
  width: "auto",
  name: "devicesJsonEditor",
  fontSize: 12
});

const mapDispatchToProps = (dispatch: any): JsonEditorActions => ({
  setJson: (json: string) => dispatch(DeviceEnrollmentWizardActions.updateDevicesJson(json)),
});

export default connect<JsonEditorModel, JsonEditorActions, JsonEditorModel & JsonEditorActions>(
  mapStateToProps,
  mapDispatchToProps,
)(JsonEditor);
