import React from "react";
import { noop } from "@util";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { SoftwareVersionDetailsActions } from "../actions";
import { DeviceTypeDetailsSelectors, SoftwareVersionDetailsSelectors } from "../selectors";
import { Actions, Model, SoftwareVersionDetails } from "../components/SoftwareVersionDetails";

interface ContainerModel extends Model {
  namespace?: string;
  name?: string;
  softwareVersion?: string;
}

interface ContainerActions extends Actions {
  loadDeviceTypes?: (namespace: string, name: string, softwareVersion: string) => void;
}

type Props = ContainerModel & ContainerActions;

const SoftwareVersionDetailsContainer = (props: Props) => {

  const {
    namespace = "",
    name = "",
    softwareVersion = "",
    loadDeviceTypes = noop,
    ...otherProps
  } = props;

  // When this component is mounted, fetch the device types that correspond to the
  // active namespace, name, and software version.
  React.useEffect(() => {

    loadDeviceTypes(namespace, name, softwareVersion);

  }, [namespace, name, softwareVersion, loadDeviceTypes]);

  return (
    <SoftwareVersionDetails {...otherProps} />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  namespace: DeviceTypeDetailsSelectors.getDeviceTypeNamespace(state),
  name: DeviceTypeDetailsSelectors.getDeviceTypeName(state),
  softwareVersion: DeviceTypeDetailsSelectors.getSelectedSoftwareVersion(state),
  title: SoftwareVersionDetailsSelectors.getTitle(state),
  showAccessDenied: SoftwareVersionDetailsSelectors.isAccessDeniedVisible(state),
  items: SoftwareVersionDetailsSelectors.getSortedDeviceTypes(state),
  selectedItems: SoftwareVersionDetailsSelectors.getSelectedDeviceTypes(state),
  error: SoftwareVersionDetailsSelectors.getErrorMessage(state),
  noResultsLabel: SoftwareVersionDetailsSelectors.getNoResultsLabel(state),
  showErrorView: SoftwareVersionDetailsSelectors.isErrorViewVisible(state),
  showNoResultsView: SoftwareVersionDetailsSelectors.isNoResultsViewVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  loadMore: () => dispatch(SoftwareVersionDetailsActions.fetchDeviceTypes()),
  loadDeviceTypes: (namespace: string, name: string, softwareVersion: string) =>
    dispatch(SoftwareVersionDetailsActions.loadDeviceTypes(namespace, name, softwareVersion)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(SoftwareVersionDetailsContainer);
