import React from "react";
import classnames from "classnames";
import LinkButton, { LinkButtonModel, LinkButtonActions } from "@components/link-button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface DetailsViewTabModel extends LinkButtonModel {
}

export interface DetailsViewTabActions extends LinkButtonActions {
}

type Model = DetailsViewTabModel;
type Actions = DetailsViewTabActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DetailsViewTab = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    activeClassName,
    children,
    ...otherProps
  } = props;

  return (
    <LinkButton
      {...otherProps}
      className={classnames("detailsViewTab", className, classes.button)}
      activeClassName={classnames("active", activeClassName, classes.active)}
      variant="contained"
    >
      {children}
    </LinkButton>
  );
});

export default DetailsViewTab;
