import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { users as styles } from "./styles";

export const Users = withStyles(styles)((props: WithStyles<typeof styles> & {
  title?: string,
  children?: React.ReactNode,
}) => {

  const {
    classes,
    title,
    children,
  } = props;

  return (
    <div className={classnames("users", classes.container)}>
      <h1 className={classnames("usersTitle", classes.title)}>
        {title}
      </h1>
      {children}
    </div>
  );
});

export default Users;
