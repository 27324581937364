import React from "react";
import classnames from "classnames";
import { equalsIgnoreCase, noop } from "@util";
import { ActionMenuItem, ActionsMenu, ActionsMenuProps } from "../actions-menu";

export enum ProfileAttributeDefinitionAction {
  EDIT_PROFILE_ATTRIBUTE_DEFINITION = "edit_profile_attribute_definition",
}

// TODO: Edit profile attribute definitions is not currently safe to expose in the UI
export const EDIT_PROFILE_ATTRIBUTE_DEFINITION_ACTION: ActionMenuItem = Object.freeze({
  id: ProfileAttributeDefinitionAction.EDIT_PROFILE_ATTRIBUTE_DEFINITION,
  name: "Edit Profile Attribute Definitions",
  disabled: false,
  hidden: equalsIgnoreCase("true", process.env.REACT_APP_EDIT_PROFILE_ATTRIBUTE_DEFINITIONS_DISABLED),
});

export const DEFAULT_ATTRIBUTE_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  EDIT_PROFILE_ATTRIBUTE_DEFINITION_ACTION,
]) as ActionMenuItem[];

export interface ProfileAttributeDefinitionsActionsMenuProps extends ActionsMenuProps {
  editProfileAttributeDefinition?: () => void;
}

type Props = ProfileAttributeDefinitionsActionsMenuProps;

export const ProfileAttributeDefinitionActionsMenu = (props: Props) => {

  const {
    className,
    buttonLabel = "Attribute Actions",
    actions = DEFAULT_ATTRIBUTE_ACTION_MENU_ITEMS,
    editProfileAttributeDefinition = noop,
    onClickAction = noop,
    ...otherProps
  } = props;

  return (
    <ActionsMenu
      {...otherProps}
      className={classnames("profileAttributeDefinitionActionsMenu", className)}
      actions={actions}
      buttonLabel={buttonLabel}
      onClickAction={(action: ActionMenuItem) => {
        if (action.id === ProfileAttributeDefinitionAction.EDIT_PROFILE_ATTRIBUTE_DEFINITION) {
          editProfileAttributeDefinition();
        } else {
          onClickAction(action);
        }
      }}
    />
  );
};

export default ProfileAttributeDefinitionActionsMenu;
