import AppSchema from "@AppSchema";
import { connect } from "react-redux";
import { getModuleTitle } from "../selectors";
import Title, { Model } from "../components/Title";

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  title: getModuleTitle(state),
  ...ownProps,
});

export default connect<Model>(
  mapStateToProps,
)(Title);
