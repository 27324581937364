import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    root: {
      height: 36,
      minWidth: 100,
      padding: "6px 16px",
      color: Color.TEXT,
    },
    label: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
      padding: 0,
      margin: 0,
      textTransform: "none",
    },
    contained: {
      backgroundColor: Color.WHITE,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.54)",
      },
    },
    containedPrimary: {
      color: Color.BLACK,
      backgroundColor: Color.SIGNIFY_GREEN,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      "&:hover": {
        backgroundColor: Color.SIGNIFY_GREEN2,
      },
      "&:active": {
        backgroundColor: Color.SIGNIFY_GREEN3,
      },
    },
    disabled: {
    },
  });

export default styles;
