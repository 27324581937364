import React from "react";
import { ReleaseNoteReleaseType, ReleaseStatus } from "@data";
import useListReleaseNotes from "@hooks/portalReleaseNotes/use-list-release-notes";
import WelcomePage, { WelcomePageActions, WelcomePageModel } from "../components/WelcomePage";

export interface WhatsNewWelcomePageModel extends WelcomePageModel {
}

export interface WhatsNewWelcomePageActions extends WelcomePageActions {
}

type Model = WhatsNewWelcomePageModel;
type Actions = WhatsNewWelcomePageActions;
type Props = Model & Actions;

const WhatsNewWelcomePage = (props: Props) => {

  const [
    {
      releaseNotes,
      errorMessage: whatsNewErrorMessage,
      showLoadingIndicator: whatsNewShowLoadingIndicator,
    },
    {
      refresh: whatsNewRefresh,
    },
  ] = useListReleaseNotes();

  const whatsNewReleaseNotes = React.useMemo(() => releaseNotes
      .filter(releaseNote =>
        (releaseNote.getReleaseType() === ReleaseNoteReleaseType.MAJOR ||
          releaseNote.getReleaseType() === ReleaseNoteReleaseType.MINOR) &&
        (releaseNote.getReleaseStatus() === ReleaseStatus.COMPLETED))
          .slice(0, 3),
    [releaseNotes]);

  return (
    <WelcomePage
      {...props}
      whatsNewReleaseNotes={whatsNewReleaseNotes}
      whatsNewErrorMessage={whatsNewErrorMessage}
      whatsNewShowLoadingIndicator={whatsNewShowLoadingIndicator}
      whatsNewRefresh={whatsNewRefresh}
    />
  );
};

export default WhatsNewWelcomePage;
