import { createSelector } from "reselect";
import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { createSelectors, Selector } from "@modules/base/createSelectors";
import { DashboardDetailsSchema, DEFAULT_STATE } from "./reducers";
import { Dashboard, SummaryViewData } from "@data";
import { getItems as getDashboards } from "@modules/dashboards/selectors";

export const {
  dashboardId: getDashboardId,
  initialized: isInitialized,
  getErrorMessage,
  isErrorMessageVisible,
} = createSelectors<DashboardDetailsSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getDashboard: Selector<Dashboard> =
  createSelector([ isInitialized, getDashboardId, getDashboards ],
    (initialized, dashboardId, dashboards) => {
      const item = dashboards.find((dashboard) => dashboard.id === dashboardId);
      if (item) {
        return new Dashboard(item);
      }
      return new Dashboard();
    });

export const getSummaryViewItems: Selector<SummaryViewData[]> = createSelector(
  getDashboard,
  (dashboard) => ([
    new SummaryViewData({
      className: "batchId",
      name: "Dashboard Id",
      value: dashboard.getDashboardId(),
    }),
    new SummaryViewData({
      className: "status",
      name: "Published",
      value: dashboard.isPublished(),
    }),
  ]));
