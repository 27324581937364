import React from "react";
import classnames from "classnames";
import { clickHandler, noop } from "@util";
import MuiDialog from "@material-ui/core/Dialog";
import CancelButton from "@components/cancel-button";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { dialog, dialogActions, dialogContent, dialogTitle, errorView, styles } from "./styles";

const Dialog = withStyles(dialog)(MuiDialog);
const DialogTitle = withStyles(dialogTitle)(MuiDialogTitle);
const DialogContent = withStyles(dialogContent)(MuiDialogContent);
const DialogActions = withStyles(dialogActions)(MuiDialogActions);

const ErrorView = withStyles(errorView)((props: WithStyles<typeof errorView> & {
  className?: string,
  title?: string,
  message?: string,

}) => {

  const {
    classes,
    className = "errorView",
    title = "",
    message = "",
  } = props;

  if (!title && !message) {
    return null;
  }

  return (
    <div className={classnames(className, classes.container)}>
      <ErrorIcon className={classnames("icon", classes.icon)} color="inherit" />
      {title && (<label className={classnames("title", classes.title)}>{title}</label>)}
      {message && (<label className={classnames("message", classes.message)}>{message}</label>)}
    </div>
  );
});

const AccessDeniedView = () => (
  <ErrorView
    className="accessDenied"
    title="Access Denied"
    message="You do not have permission to perform this action"
  />
);

export interface WizardDialogModel {
  className?: string;
  title?: string;
  open?: boolean;
  accessDenied?: boolean;
  errorMessage?: string;
  cancelButtonLabel?: string;
  wizardWindow?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  children?: React.ReactNode;
}

export interface WizardDialogActions {
  cancel?: () => void;
  onClose?: () => void;
}

type Props = WithStyles<typeof styles> & WizardDialogModel & WizardDialogActions;

export const WizardDialog = withStyles(styles)(<Item, >(props: Props) => {

  const {
    classes,
    className,
    title = "Wizard Dialog",
    open = false,
    accessDenied,
    errorMessage = "",
    cancelButtonLabel = "Cancel",
    maxWidth = "lg",
    cancel,
    onClose = noop,
    children,
  } = props;

  if (accessDenied) {
    return (
      <Dialog
        className={classnames("wizardDialog", "accessDenied", className)}
        open={open}
        maxWidth="sm"
        fullWidth={true}
        disableEscapeKeyDown={true}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose(event, reason);
          }
        }}
      >
        <DialogTitle className="title" disableTypography={true}>
          {title}
        </DialogTitle>
        <DialogContent>
          <AccessDeniedView />
        </DialogContent>
        <DialogActions>
          <CancelButton
            className={classnames("cancelButton", classes.button, classes.cancelButton)}
            fullWidth={false}
            onClick={clickHandler(cancel)}
          >
            {cancelButtonLabel}
          </CancelButton>
        </DialogActions>
      </Dialog>
    );
  }

  const showErrorView = errorMessage.length > 0;

  return (
    <Dialog
      className={classnames("wizardDialog", className)}
      open={open}
      maxWidth={maxWidth}
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose(event, reason);
        }
      }}
    >
      <DialogTitle className="title" disableTypography={true}>
        {title}
      </DialogTitle>
      <DialogContent className="content">
        {showErrorView && <ErrorView title="Error" message={errorMessage} />}
        {children}
      </DialogContent>
    </Dialog>
  );
});

export default WizardDialog;
