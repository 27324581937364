import React from "react";
import PolicyStatementsListColumn from "./PolicyStatementsListColumn";
import SortedSearchResultsList, {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import { PolicyStatement } from "@data";
import { JsonDialog } from "@components";
import PolicyStatementListItems from "./PolicyStatementListItem";
import { sortAndFilterPolicyStatementResult } from "./helpers";

export const DEFAULT_POLICY_STATEMENTS_LIST_COLUMNS: PolicyStatementsListColumn[] = [
  PolicyStatementsListColumn.ACTION,
  PolicyStatementsListColumn.EFFECT,
  PolicyStatementsListColumn.RESOURCE,
  PolicyStatementsListColumn.CONDITION,
];

export type PolicyStatementsListModel = SortedSearchResultsListModel<PolicyStatement, PolicyStatementsListColumn>;

export type PolicyStatementsListActions = SortedSearchResultsListActions<PolicyStatement, PolicyStatementsListColumn>;

type Props = PolicyStatementsListModel & PolicyStatementsListActions;

export const PolicyStatementsList = (props: Props) => {

  const {
    className = "policyStatements",
    listClassName = "policyStatementsList",
    columns = DEFAULT_POLICY_STATEMENTS_LIST_COLUMNS,
    sortByColumn = PolicyStatementsListColumn.NONE,
    sortOrderAscending = true,
    noResultsLabel = "No policy statements found",
    summaryViewQuantities = {
      other: "Policy Statements",
      one: "Policy Statement",
    },
    items = [],
    nameFilter = "",
    ...otherProps
  } = props;

  const statements = sortAndFilterPolicyStatementResult({ items, nameFilter, sortOrderAscending, sortByColumn });

  const [showConditionDialog, setShowConditionDialog] = React.useState<boolean>(false);
  const [conditionJSON, setConditionJSON] = React.useState<string>("");

  const openConditionDialog = React.useCallback((json: string) => {
    setConditionJSON(json);
    setShowConditionDialog(true);
  }, [setConditionJSON, setShowConditionDialog]);

  const closeConditionDialog = React.useCallback(() => {
    setConditionJSON("");
    setShowConditionDialog(false);
  }, [setConditionJSON, setShowConditionDialog]);

  return (
    <React.Fragment>
      <SortedSearchResultsList
        {...otherProps}
        className={className}
        items={statements}
        columns={columns}
        nameFilter={nameFilter}
        nameFilterDelay={25}
        sortByColumn={sortByColumn}
        sortOrderAscending={sortOrderAscending}
        noResultsLabel={noResultsLabel}
        summaryViewQuantities={summaryViewQuantities}
        renderItem={(statement: PolicyStatement, column: PolicyStatementsListColumn) => (
          <PolicyStatementListItems
            openConditionDialog={openConditionDialog}
            statement={statement}
            column={column}
          />
        )}
      />
      <JsonDialog
        schema={conditionJSON}
        open={showConditionDialog}
        title={"Condition JSON {}"}
        cancel={closeConditionDialog}
      />
    </React.Fragment>
  );
};

export default PolicyStatementsList;
