import React from "react";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import { AccessRequestPrincipalType, AccessRequestType } from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import SummaryViewLabel from "@components/summary-view-label";
import styles from "./styles";

export interface Model {
  accountId?: string;
  dataSetAlias?: string;
  accessRequestType?: AccessRequestType;
  accessRequestPrincipalType?: AccessRequestPrincipalType;
  servicePrincipalIdentifier?: string;
  deviceType?: string;
  deviceNamespace?: string;
  iotCloudRole?: boolean;
  principalAccountForCloudStorage?: string;
  trustedAccountsForCloudRole?: string;
  isAccessForExistingCloudResource?: boolean;
  cloudRoleIdentifier?: string;
  reason?: string;
  dataSetGroup?: string;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ReviewView = withStyles(styles)((props: Props) => {

  const {
    classes,
    accountId,
    dataSetAlias,
    accessRequestType,
    accessRequestPrincipalType,
    servicePrincipalIdentifier,
    principalAccountForCloudStorage,
    deviceType,
    deviceNamespace,
    iotCloudRole,
    trustedAccountsForCloudRole,
    isAccessForExistingCloudResource,
    cloudRoleIdentifier,
    reason,
    dataSetGroup,
    children,
  } = props;

  const cloudRoleIdentifierLabel = React.useMemo(() =>
    isAccessForExistingCloudResource ? cloudRoleIdentifier : "Create New IoT Cloud Role",
    [isAccessForExistingCloudResource, cloudRoleIdentifier]);

  return (
    <div className={classnames("reviewView", classes.reviewView)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        Review Data Access Request
      </Typography>
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Data Set"
        nameClassName={classes.summaryViewLabelName}
        value={dataSetAlias}
        valueClassName={classnames("dataSetAlias", classes.summaryViewLabelValue)}
      />
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Account"
        nameClassName={classes.summaryViewLabelName}
        value={accountId}
        valueClassName={classnames("accountId", classes.summaryViewLabelValue)}
      />
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Access Type"
        nameClassName={classes.summaryViewLabelName}
        value={accessRequestType}
        valueClassName={classnames("accessRequestType", classes.summaryViewLabelValue)}
      />
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Request Type"
        nameClassName={classes.summaryViewLabelName}
        value={accessRequestPrincipalType}
        valueClassName={classnames("accessRequestPrincipalType", classes.summaryViewLabelValue)}
      />
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Data Set Group"
        nameClassName={classes.summaryViewLabelName}
        value={dataSetGroup}
        valueClassName={classnames("dataSetGroup", classes.summaryViewLabelValue)}
      />
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Service Principal Identifier"
        nameClassName={classes.summaryViewLabelName}
        value={servicePrincipalIdentifier}
        valueClassName={classnames("servicePrincipalIdentifier", classes.summaryViewLabelValue)}
      />
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Device Namespace"
        nameClassName={classes.summaryViewLabelName}
        value={deviceNamespace}
        valueClassName={classnames("deviceNamespace", classes.summaryViewLabelValue)}
      />
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Device Type"
        nameClassName={classes.summaryViewLabelName}
        value={deviceType}
        valueClassName={classnames("deviceType", classes.summaryViewLabelValue)}
      />
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Principal Account For Cloud Storage"
        nameClassName={classes.summaryViewLabelName}
        value={principalAccountForCloudStorage}
        valueClassName={classnames("principalAccountForCloudStorage", classes.summaryViewLabelValue)}
      />
      {iotCloudRole && (
        <React.Fragment>
          <SummaryViewLabel
            className={classes.summaryViewLabel}
            name="Trusted Accounts for Cloud Role"
            nameClassName={classes.summaryViewLabelName}
            value={trustedAccountsForCloudRole}
            valueClassName={classnames("trustedAccountsForCloudRole", classes.summaryViewLabelValue)}
          />
          <SummaryViewLabel
            className={classes.summaryViewLabel}
            name="Cloud Role Identifier"
            nameClassName={classes.summaryViewLabelName}
            value={cloudRoleIdentifierLabel}
            valueClassName={classnames("cloudRoleIdentifier", classes.summaryViewLabelValue)}
          />
        </React.Fragment>
      )}
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Reason"
        nameClassName={classes.summaryViewLabelName}
        value={reason}
        valueClassName={classnames("reason", classes.summaryViewLabelValue)}
      />
      {children}
    </div>
  );
});

export default ReviewView;
