import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      width: 30,
      height: 30,
      marginLeft: 15,
    },
    icon: {
      color: Color.SIGNIFY_GREEN,
      width: 20,
      height: 20,
    },
  });

export default styles;
