import React from "react";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@components/text-field";
import { formEventHandler, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles, textField } from "./styles";

const AccountIdTextField = withStyles(textField)(TextField);

export interface Model {
  awsAccountId?: string;
  awsAccountIdError?: string;
}

export interface Actions {
  setAwsAccountId?: (awsAccountId: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const IotCloudStorageView = withStyles(styles)((props: Props) => {

  const {
    classes,
    awsAccountId = "",
    awsAccountIdError = "",
    setAwsAccountId = noop,
    children,
  } = props;

  const helperText = React.useMemo(() => awsAccountIdError.length > 0 ?
    "AWS Account ID Must Be 12-Digit Number" :
    "Enter Principal AWS Account ID for Cloud Storage", [awsAccountIdError]);

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  return(
    <div className={classnames("iotCloudStorageView", classes.iotCloudRoleView)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        Set AWS Account For IoT Cloud Storage
      </Typography>
      <AccountIdTextField
        className={classnames("awsAccountId", classes.awsAccountId)}
        autoComplete="off"
        label="AWS Account ID"
        name="awsAccountId"
        value={awsAccountId}
        fullWidth={true}
        autoFocus={true}
        variant="outlined"
        margin="none"
        required={true}
        helperText={helperText}
        error={awsAccountIdError.length > 0}
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setAwsAccountId)}
      />
      {children}
    </div>
  );
});

export default IotCloudStorageView;
