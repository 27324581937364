import React from "react";
import classnames from "classnames";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export enum CodeCoverageStatsCardTrend {
  NONE,
  TRENDING_UP,
  FLAT,
  TRENDING_DOWN,
}

export interface CodeCoverageStatsCardModel {
  className?: string;
  title?: string;
  coverage?: number;
  trendDelta?: number;
  trend?: CodeCoverageStatsCardTrend;
  trendEmptyViewEnabled?: boolean;
}

export interface CodeCoverageStatsCardActions {
}

type Model = CodeCoverageStatsCardModel;
type Actions = CodeCoverageStatsCardActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const CodeCoverageStatsCard = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title,
    coverage = 0.0,
    trendDelta = 0.0,
    trend = CodeCoverageStatsCardTrend.NONE,
    trendEmptyViewEnabled,
  } = props;

  const showTrend = React.useMemo(() => trend !== CodeCoverageStatsCardTrend.NONE, [trend]);

  const showTrendEmptyView = React.useMemo(() =>
    !showTrend && trendEmptyViewEnabled, [showTrend, trendEmptyViewEnabled]);

  const showStats = React.useMemo(() =>
    showTrend || showTrendEmptyView, [showTrend, showTrendEmptyView]);

  return (
    <Card
      className={classnames("codeCoverageStatsCard", className, classes.container, {
        [classes.addBottomPadding]: !showStats,
      })}
    >
      {title && (
        <Typography className={classnames("title", classes.title)}>
          {title}
        </Typography>
      )}
      <Typography
        className={classnames("coverage", classes.coverage, {
          [classes.goodCoverage]: coverage >= 80,
        })}
        variant="h1"
      >
        {coverage.toFixed(2)}%
      </Typography>
      {showStats && (
        <div className={classnames("stats", classes.stats)}>
          {showTrendEmptyView && (
            <label className={classnames("noTrendLabel", classes.noTrendLabel)}>
              No Trend History
            </label>
          )}
          {showTrend && (
            <React.Fragment>
              {trend === CodeCoverageStatsCardTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.icon, classes.trendingUp)}
                />
              )}
              {trend === CodeCoverageStatsCardTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.icon, classes.trendingFlat)}
                />
              )}
              {trend === CodeCoverageStatsCardTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.icon, classes.trendingDown)}
                />
              )}
              {trendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.deltaLabel, {
                    [classes.trendingUp]: trend === CodeCoverageStatsCardTrend.TRENDING_UP,
                    [classes.trendingFlat]: trend === CodeCoverageStatsCardTrend.FLAT,
                    [classes.trendingDown]: trend === CodeCoverageStatsCardTrend.TRENDING_DOWN,
                  })}
                  variant="h6"
                >
                  ({trendDelta < 0 ? "" : "+"}{trendDelta.toFixed(2)}%)
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </Card>
  );
});

export default CodeCoverageStatsCard;
