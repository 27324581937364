import { analyticAction, identityAction, noop, ReduxAction } from "@util";
import {
  BasePortalModuleSchema,
  PortalModuleActionType,
  PortalModuleActionTypes,
} from "./createReducers";

type Action<V = any, ActionType = PortalModuleActionType> = ReduxAction<ActionType, V>;

type ActionCreator<V = any, ActionType = PortalModuleActionType> =
  (value?: V) => Action<V, ActionType>;

type ActionCreators<S extends BasePortalModuleSchema = BasePortalModuleSchema> = {
  [K in keyof S]: ActionCreator<S[K]>;
};

type AnalyticActionCreators<ActionType = PortalModuleActionType> = {
  [K in keyof ActionType]: ActionCreator<string, ActionType>;
};

export const createPortalModuleActionCreators =
  <State extends BasePortalModuleSchema, ActionTypes = PortalModuleActionTypes<State>>(
    actionTypes: ActionTypes, defaultState: State) => {

    return Object.keys(defaultState)
      .reduce((actions: ActionCreators<State>, key: keyof ActionCreators) => {
        actions[key] = identityAction(actionTypes[key], defaultState[key]);
        return actions;
      }, {} as ActionCreators<State>);
  };

export const createActions =
  <State extends BasePortalModuleSchema,
    ActionTypes = PortalModuleActionTypes<State>,
    ActionType = ActionTypes[keyof ActionTypes]>(actionTypes: ActionTypes,
                                                 defaultState: State) => {

    const actionCreators = createPortalModuleActionCreators<State, ActionTypes>(
      actionTypes, defaultState);

    const {
      errors: setErrors,
      errorMessage: setErrorMessage,
      successMessage: setSuccessMessage,
      showEmptyView: setShowEmptyView,
      showAccessDenied: setShowAccessDenied,
      showLoadingIndicator: setShowLoadingIndicator,
      showNotFound: setShowNotFound,
      ...otherActionCreators
    } = actionCreators;

    const analyticActionCreators = Object.keys(actionTypes)
      .filter(key => !defaultState.hasOwnProperty(key))
      .reduce((data: AnalyticActionCreators<ActionType>, key: string) => {
        if (actionTypes[key]) {
          data[key] = analyticAction<ActionType>(actionTypes[key]);
        }
        return data;
      }, {} as AnalyticActionCreators<ActionType>);

    const createAnalyticAction = (action: string, ...args: any[]) => {
      let callback: ReduxAction<ActionType, string> | (() => void) = analyticActionCreators[action];
      if (typeof callback !== "function") {
        callback = noop;
      }
      return callback.apply(analyticActionCreators, args);
    };

    return {
      ...otherActionCreators,
      ...analyticActionCreators,
      createAnalyticAction,
      setErrors,
      setErrorMessage,
      setSuccessMessage,
      setShowNotFound,
      setShowEmptyView,
      setShowAccessDenied,
      setShowLoadingIndicator,
      showNotFound: () => setShowNotFound(true),
      hideNotFound: () => setShowNotFound(false),
      showEmptyView: () => setShowEmptyView(true),
      hideEmptyView: () => setShowEmptyView(false),
      showAccessDenied: () => setShowAccessDenied(true),
      hideAccessDenied: () => setShowAccessDenied(false),
      showLoadingIndicator: () => setShowLoadingIndicator(true),
      hideLoadingIndicator: () => setShowLoadingIndicator(false),
      baseReset: () => (dispatch: any) => {
        dispatch(setErrors());
        dispatch(setErrorMessage());
        dispatch(setSuccessMessage());
        dispatch(setShowNotFound());
        dispatch(setShowEmptyView());
        dispatch(setShowAccessDenied());
        return dispatch(setShowLoadingIndicator());
      },
    };
  };
