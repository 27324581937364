import { Record } from "immutable";
import { getStringValue } from "@util";

export enum  EnrollmentStatus {
  QUEUED = "QUEUED",
  ENROLLMENT_PENDING = "ENROLLMENT_PENDING",
  ENROLLMENT_FAILURE = "ENROLLMENT_FAILURE",
  ENROLLED = "ENROLLED",
  DISENROLLMENT_PENDING = "DISENROLLMENT_PENDING",
  DISENROLLMENT_FAILURE = "DISENROLLMENT_FAILURE",
  DISENROLLED = "DISENROLLED",
  VALIDATION_PENDING = "VALIDATION_PENDING",
  VALIDATION_FAILURE = "VALIDATION_FAILURE",
  VALIDATED = "VALIDATED"
}

export interface DeviceEnrollmentAttributes {
  deviceId?: string;
  deviceReference?: string;
  status: EnrollmentStatus;
  statusMessage?: string;
  updatedBy?: string;
}

export class DeviceEnrollment extends Record({
  deviceId: "",
  deviceReference: "",
  status: EnrollmentStatus.QUEUED,
  statusMessage: "",
  updatedBy: "",
})implements DeviceEnrollmentAttributes {

  public static EMPTY: DeviceEnrollment = new DeviceEnrollment();

  public readonly deviceReference: string;
  public readonly deviceId: string;
  public readonly status: EnrollmentStatus;
  public readonly statusMessage: string;
  public readonly updatedBy: string;

  public getDeviceReference(): string {
    return getStringValue(this.deviceReference);
  }

  public getStatusMessage(): string {
    return getStringValue(this.statusMessage);
  }

  public getDeviceId(): string {
    return getStringValue(this.deviceId);
  }
}

export default DeviceEnrollment;
