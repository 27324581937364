import React from "react";
import classnames from "classnames";
import TextField from "@components/text-field";
import { clickHandler, formEventHandler, noop } from "@util";
import { Button, SaveButton, usePortalSnackbar } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { createNotesView as styles, noteTextField } from "./styles";

const NoteTextField = withStyles(noteTextField)(TextField);

export interface Model {
  createNoteBoxVisible?: boolean;
  note?: string;
  showCancelButton?: boolean;
  showSaveButton?: boolean;
  saveButtonLabel?: string;
  showProgressIndicator?: boolean;
  buttonsDisabled?: boolean;
  finished?: boolean;
  errorMessage?: string;
  successMessage?: string;
  children?: React.ReactNode;
}

export interface Actions {
  save?: () => void;
  cancel?: () => void;
  setNote?: (note: string) => void;
  addNote?: () => void;
  onSuccess?: (note: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const CreateNoteView = withStyles(styles)((props: Props) => {

  const {
    classes,
    createNoteBoxVisible,
    showCancelButton,
    showSaveButton,
    saveButtonLabel,
    note,
    showProgressIndicator,
    buttonsDisabled,
    finished: showSuccessIndicator,
    children,
    errorMessage = "",
    successMessage = "",
    onSuccess = noop,
    save = noop,
    cancel,
    setNote,
    addNote,
  } = props;

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const onSuccessMessageShown = React.useCallback(() => {
    onSuccess(note);
  }, [onSuccess, note]);

  usePortalSnackbar("create-note", {
    errorMessage,
    successMessage,
    onSuccessMessageShown,
    successCallbackDelay: 250
  });

  return (
    <div className={classnames("createNoteView", classes.container)}>
      {!createNoteBoxVisible && (
        <Button
          className={classnames("addNoteButton", classes.addNoteButton)}
          color="primary"
          variant="contained"
          fullWidth={false}
          onClick={clickHandler(addNote)}
        >
          Add Note
        </Button>
      )}
      {createNoteBoxVisible && (
        <div className={classnames("createNoteContainer", classes.createNoteContainer)}>
          <NoteTextField
            className={classnames("note", classes.note)}
            autoComplete="off"
            name="note"
            label="Note"
            value={note}
            fullWidth={true}
            multiline={true}
            minRows={3}
            maxRows={3}
            variant="outlined"
            margin="none"
            InputLabelProps={inputLabelProps}
            onChange={formEventHandler(setNote)}
          />
          <div className={classnames("actionsContainer", classes.actionsContainer)}>
            {showCancelButton && (
              <Button
                className={classnames("cancelButton", classes.cancelButton)}
                disabled={buttonsDisabled}
                variant="text"
                color="inherit"
                onClick={clickHandler(cancel)}
              >
                Cancel
              </Button>
            )}
            <SaveButton
              className={classnames("saveButton", classes.saveButton)}
              label={saveButtonLabel}
              disabled={!showSaveButton}
              loading={showProgressIndicator}
              success={showSuccessIndicator}
              save={save}
            />
          </div>
        </div>
      )}
      {children}
    </div>
  );
});

export default CreateNoteView;
