import { combineReducers } from "redux";
import { Actions, filterAction, Identity } from "../../../util/ReducerUtil";

export interface SoftwareVersionDetailsSchema {
  namespace: string;
  name: string;
  softwareVersion: string;
  deviceTypeVersions: string[];
  errorMessage: string;
  showAccessDenied: boolean;
  showLoadingIndicator: boolean;
}

export const DEFAULT_STATE: SoftwareVersionDetailsSchema = Object.freeze({
  namespace: "",
  name: "",
  softwareVersion: "",
  deviceTypeVersions: [],
  errorMessage: "",
  showAccessDenied: false,
  showLoadingIndicator: false,
});

export enum SoftwareVersionDetailsActionType {
  SET_NAMESPACE = "software_version_details/set_namespace",
  SET_NAME = "software_version_details/set_name",
  SET_SOFTWARE_VERSION = "software_version_details/set_software_version",
  SET_DEVICE_TYPE_VERSIONS = "software_version_details/set_device_type_versions",
  SET_ERROR_MESSAGE = "software_version_details/set_error_message",
  SET_SHOW_ACCESS_DENIED = "software_version_details/set_show_access_denied",
  SET_SHOW_LOADING_INDICATOR = "software_version_details/set_show_loading_indicator",
  FETCH_DEVICE_TYPES_REQUEST = "software_version_details/fetch_device_types_success",
  FETCH_DEVICE_TYPES_SUCCESS = "software_version_details/fetch_device_types_success",
  FETCH_DEVICE_TYPES_FAILED = "software_version_details/fetch_device_types_failed",
}

const namespace = filterAction<string>(Actions(
  SoftwareVersionDetailsActionType.SET_NAMESPACE))(Identity,
  DEFAULT_STATE.namespace);

const name = filterAction<string>(Actions(
  SoftwareVersionDetailsActionType.SET_NAME))(Identity,
  DEFAULT_STATE.name);

const softwareVersion = filterAction<string>(Actions(
  SoftwareVersionDetailsActionType.SET_SOFTWARE_VERSION))(Identity,
  DEFAULT_STATE.softwareVersion);

const deviceTypeVersions = filterAction<string[]>(Actions(
  SoftwareVersionDetailsActionType.SET_DEVICE_TYPE_VERSIONS))(Identity,
  DEFAULT_STATE.deviceTypeVersions);

const errorMessage = filterAction<string>(Actions(
  SoftwareVersionDetailsActionType.SET_ERROR_MESSAGE))(Identity,
  DEFAULT_STATE.errorMessage);

const showAccessDenied = filterAction<boolean>(Actions(
  SoftwareVersionDetailsActionType.SET_SHOW_ACCESS_DENIED))(Identity,
  DEFAULT_STATE.showAccessDenied);

const showLoadingIndicator = filterAction<boolean>(Actions(
  SoftwareVersionDetailsActionType.SET_SHOW_LOADING_INDICATOR))(Identity,
  DEFAULT_STATE.showLoadingIndicator);

export const SoftwareVersionDetailsReducer = combineReducers<SoftwareVersionDetailsSchema>({
  namespace,
  name,
  softwareVersion,
  deviceTypeVersions,
  errorMessage,
  showAccessDenied,
  showLoadingIndicator,
});

export default SoftwareVersionDetailsReducer;
