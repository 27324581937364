import React from "react";
import classnames from "classnames";
import MuiStepper, { StepperProps } from "@material-ui/core/Stepper";

export interface CustomStepperProps extends StepperProps {}

export const Stepper = ((props: CustomStepperProps) => {

    const {
      className,
      ...otherProps
    } = props;

    return (
      <MuiStepper
        {...otherProps}
        className={classnames("stepper", className)}
      />
    );
});

export default Stepper;
