import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import EnrollmentHistoryList , { Actions, Model } from "../components/EnrollmentHistoryList";
import { getDeviceEnrollmentHistory, setSortedColumn, toggleSortOrder } from "../actions";
import {
  getErrorMessage,
  getSearchQuery,
  getSearchResults,
  getSortedColumn,
  isLoadingIndicator,
  isSortOrderAscending,
} from "../selectors";

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => {

  const items = getSearchResults(state);
  const isEmpty = items.length === 0;
  const error = getErrorMessage(state);
  const searchQuery = getSearchQuery(state);
  const showErrorView = error.length > 0;
  const isLoading = isLoadingIndicator(state);
  const showNoResultsView = isEmpty && !showErrorView && !isLoading;
  const showLoadingView = isLoading && !showErrorView && (isEmpty || !searchQuery);

  return {
    items,
    error,
    showErrorView,
    showLoadingView,
    showNoResultsView,
    sortedColumn: getSortedColumn(state),
    sortOrderAscending: isSortOrderAscending(state),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: Actions = {}): Actions => ({
  loadMore: () => dispatch(getDeviceEnrollmentHistory()),
  toggleSortOrder: () => dispatch(toggleSortOrder()),
  setSortedColumn: (column: string) => dispatch(setSortedColumn(column)),
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(EnrollmentHistoryList);
