import React from "react";
import { noop } from "@util";
import Chip from "@components/chip";
import classnames from "classnames";
import { SearchFilter } from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { filters as styles } from "./styles";

export interface FiltersModel {
  className?: string;
  disabled?: boolean;
  filters?: SearchFilter[];
  lockedFilters?: string[];
  selectedFilters?: string[];
  children?: React.ReactNode;
}

export interface FiltersActions {
  setFilter?: (searchFilter: SearchFilter) => void;
  mapFilterToLabel?: (searchFilter: SearchFilter) => React.ReactNode;
}

type Model = FiltersModel;
type Actions = FiltersActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const Filters = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    filters = [],
    disabled,
    lockedFilters = [],
    selectedFilters = [],
    setFilter = noop,
    mapFilterToLabel = (filter: SearchFilter) =>
      `${filter.getKey()} ${filter.condition} ${filter.getValue()}`,
    children,
  } = props;

  return (
    <React.Fragment>
      {filters.length > 0 && (
        <div className={classnames("quickFilters", className, classes.container)}>
          {filters.map((filter: SearchFilter) => {

            const filterDisabled = disabled || lockedFilters.indexOf(filter.getKey()) >= 0;

            const selected = selectedFilters.indexOf(filter.getKey()) >= 0;

            return (
              <Chip
                key={filter.getSearchQuery() || filter.getKey()}
                className={classnames("filters", filter.getKey(), classes.searchFilter, {
                  [classes.selected] : selected
                })}
                clickable={!filterDisabled}
                color={"secondary"}
                disabled={filterDisabled}
                label={mapFilterToLabel(filter)}
                onClick={() => setFilter(filter)}
              />
            );
          })}
        </div>
      )}
      {children}
    </React.Fragment>
  );
});

export default Filters;
