import React from "react";
import { TrainingStatusResponse } from "@network";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useDataAccessRequestClient } from "@hooks";

export interface UseDataPrivacyTrainingStatusModel extends UseApiRequestModel<TrainingStatusResponse> {
  trainingStatus?: boolean;
}

export interface UseDataPrivacyTrainingStatusActions {
  refresh: () => void;
}

type Props = Partial<UseApiRequestProps<TrainingStatusResponse>>;
type Model = UseDataPrivacyTrainingStatusModel;
type Actions = UseDataPrivacyTrainingStatusActions;
type Result = [Model, Actions];

export const useDataPrivacyTrainingStatus = (props: Props): Result => {

  const {
    defaultErrorMessage = "Failed to get data privacy training status",
    ...otherProps
  } = props;

  const DataAccessRequestClient = useDataAccessRequestClient();

  const [{ successResponse, ...baseModel }, { refresh }] =
    useApiRequest<TrainingStatusResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => DataAccessRequestClient.getTrainingStatus()
    });

  const trainingStatus = React.useMemo(() => {
    const { trainingCompleted: status } = successResponse || {};
    return status;
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    trainingStatus
  }), [
    baseModel,
    trainingStatus,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    refresh,
  }), [
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDataPrivacyTrainingStatus;
