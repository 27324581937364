import Color from "@Color";
import createStyles from "@material-ui/core/styles/createStyles";
import { StyleRules } from "@material-ui/core/styles/withStyles";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
      marginBottom: 30,
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 16,
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
    name: {
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "16px",
      marginTop: 16,
      marginBottom: 16,
    },
    descriptionInput: {
      width: "100%",
    },
    textField: {
      color: Color.TEXT,
      fontSize: 16,
      height: "auto",
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    tooltip: {
      width: 231,
      height: "auto",
      alignItems: "center",
      display: "flex",
      borderRadius: 3,
      fontWeight: 400,
    },
    iconButton: {
      position: "absolute",
      right: 8,
      bottom: 18,
      width: 20,
      height: 20,
      padding: 0,
      margin: 0,
    },
    textFieldIcon: {
      width: 20,
      height: 20,
      fillOpacity: 0.4,
    },
    hidden: {
      visibility: "hidden",
    },
  });

export const textFieldTooltip: StyleRules<string> = {
  popper: {
    width: 231,
    height: "auto",
    alignItems: "center",
    display: "flex",
  },
  tooltip: {
    padding: "10px 15px",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px",
    textAlign: "left",
    whiteSpace: "normal",
  },
};

export default styles;
