import { MODULE_PATH as BASE_URL } from "@modules/emailTemplates/constants";
import { getPathToEmailTemplate } from "@modules/emailTemplateDetails/helpers";

export enum EmailTemplateWizardStep {
  NONE = "",
  TEMPLATE = "template",
  APPLICATION = "application",
  BODY = "body",
  REVIEW = "review",
}

export const DEFAULT_STEPS = [
  EmailTemplateWizardStep.TEMPLATE,
  EmailTemplateWizardStep.APPLICATION,
  EmailTemplateWizardStep.BODY,
  EmailTemplateWizardStep.REVIEW,
];

export const DEFAULT_STEP_LABELS = {
  [EmailTemplateWizardStep.TEMPLATE]: "Email Template Details",
  [EmailTemplateWizardStep.APPLICATION]: "Application ID",
  [EmailTemplateWizardStep.BODY]: "Email Body Details",
  [EmailTemplateWizardStep.REVIEW]: "Finish",
};

export const getCreateEmailTemplatePath = () => `${BASE_URL}/create`;

export const getEditEmailTemplatePath = (emailTemplateId: string) =>
  `${getPathToEmailTemplate(emailTemplateId)}/edit`;

export const getErrorTitle = (errorMessage: string = "", editMode: boolean = false) => {
  if (errorMessage.length === 0) {
    return "";
  }
  return editMode ? "Update Email Template Failed" : "Create Email Template Failed";
};

export const getTemplateIdErrorMessage = (isValid: boolean, errorMessage: string) => {
  return !isValid && errorMessage.length > 0 ? "Invalid Template Id" : "";
};

export const getEmailSubjectErrorMessage = (isValid: boolean, errorMessage: string) => {
  return !isValid && errorMessage.length > 0 ? "Invalid Email Subject" : "";
};

export const getDescriptionErrorMessage = (isValid: boolean, errorMessage: string) => {
  return !isValid && errorMessage.length > 0 ? "Invalid Description" : "";
};

export const getSteps = (editMode: boolean) =>
  DEFAULT_STEPS.filter(step => {
    switch (step) {
      case EmailTemplateWizardStep.APPLICATION:
        return !editMode;
      default:
        return true;
    }
  });

export const getDisabledSteps = (
  steps: EmailTemplateWizardStep[],
  validTemplateId: boolean,
  validBody: boolean,
  foundDuplicates: boolean,
) => {

  if (!validTemplateId) {
    return steps.filter(step => {
      switch (step) {
        case EmailTemplateWizardStep.TEMPLATE:
          return false;
        default:
          return true;
      }
    });
  }

  if (foundDuplicates) {
    return steps.filter(step => {
      switch (step) {
        case EmailTemplateWizardStep.TEMPLATE:
        case EmailTemplateWizardStep.APPLICATION:
          return false;
        default:
          return true;
      }
    });
  }

  if (!validBody) {
    return steps.filter(step => {
      switch (step) {
        case EmailTemplateWizardStep.TEMPLATE:
        case EmailTemplateWizardStep.APPLICATION:
        case EmailTemplateWizardStep.BODY:
          return false;
        default:
          return true;
      }
    });
  }

  return [];
};

export const getCompletedSteps =
  (
    steps: EmailTemplateWizardStep[],
    currentStep: EmailTemplateWizardStep,
    validTemplateId: boolean,
    validBody: boolean,
    validApplicationId: boolean,
    loading: boolean,
    success: boolean,
  ) => {

    const currentStepIndex = steps.indexOf(currentStep);

    return steps.filter((step: EmailTemplateWizardStep, index: number) => {

      const isCurrentStep = (step === currentStep);

      switch (step) {
        case EmailTemplateWizardStep.TEMPLATE:
          return !isCurrentStep && validTemplateId;
        case EmailTemplateWizardStep.BODY:
          return !isCurrentStep && validBody;
        case EmailTemplateWizardStep.APPLICATION:
          return !isCurrentStep && (validApplicationId || currentStepIndex > index);
        case EmailTemplateWizardStep.REVIEW:
          return loading || success;
        default:
          return false;
      }
    });
  };
