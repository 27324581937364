import AppSchema from "@schemas";
import { IdentityType } from "@data";
import SessionStorageClient from "@util/SessionStorageClient";
import { DEFAULT_STATE as DEFAULT_LOGIN_STATE } from "@modules/login/reducers";
import {
  KEY_ACCESS_TOKEN,
  KEY_LAST_IDENTITY_TYPE,
  KEY_NONCE,
  KEY_REFRESH_TOKEN,
} from "./constants";

const getLastIdentityType = (): IdentityType => {
  const identityType = SessionStorageClient.getItem(KEY_LAST_IDENTITY_TYPE);
  if (identityType === IdentityType.SERVICE) {
    return IdentityType.SERVICE;
  } else if (identityType === IdentityType.USER) {
    return IdentityType.USER;
  } else {
    return IdentityType.NONE;
  }
};

export const restoreFromSessionStorage = (): Partial<AppSchema> => ({
  authToken: SessionStorageClient.getItem(KEY_ACCESS_TOKEN),
  refreshToken: SessionStorageClient.getItem(KEY_REFRESH_TOKEN),
  login: {
    ...DEFAULT_LOGIN_STATE,
    nonce: SessionStorageClient.getItem(KEY_NONCE),
    identityType: getLastIdentityType(),
  },
});

export default restoreFromSessionStorage;
