import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import { DeviceAttributes } from "@data";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base/createReducers";

export enum DeviceColumns {
  BATCH_ID = "Batch ID",
  STATUS = "Status",
}

export type DeviceItems = { [key: string]: DeviceAttributes };

export interface DevicesSchema extends BasePortalModuleSchema {
  devices: DeviceItems;
  deviceId: string;
}

export const DEFAULT_STATE: DevicesSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  devices: {},
  deviceId: "",
});

export type DevicesActionTypes = PortalModuleActionTypes<DevicesSchema> & {
  FETCH_DEVICES_REQUEST: string;
  FETCH_DEVICES_SUCCESS: string;
  FETCH_DEVICES_FAILED: string;
};

export const ACTION_TYPES: DevicesActionTypes = {
  ...createPortalModuleActionTypes<DevicesSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    FETCH_DEVICES_REQUEST: `${MODULE_ID}/fetch_devices_request`,
    FETCH_DEVICES_SUCCESS: `${MODULE_ID}/fetch_devices_success`,
    FETCH_DEVICES_FAILED: `${MODULE_ID}/fetch_devices_failed`,
  },
};

export const DevicesReducer = combineReducers<DevicesSchema>(
  createPortalModuleReducers<DevicesSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DevicesReducer;
