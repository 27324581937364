import { Record } from "immutable";
import { isValidNumber } from "../util";
import { JsonSchemaPropertiesDefinition, JsonSchemaPropertyType } from "../data";

// JsonSchema Number Property Type
//
// Reference Material:
//  - https://json-schema.org/understanding-json-schema/reference/numeric.html
//  - https://docs.opis.io/json-schema/1.x/number.html
export interface JsonSchemaNumberConstraintsAttributes {
  // A number is valid against this keyword if is greater than, or equal to, the value of this
  // keyword. Value of this keyword must be a number (integer or float).
  minimum?: number;
  // A number is valid against this keyword if is strictly greater than the value of this keyword.
  // Value of this keyword must be a number (integer or float) or a boolean. If this keyword holds
  // a boolean, then the minimum keyword is required and is used as reference for comparison.
  exclusiveMinimum?: number;
  // A number is valid against this keyword if is less than, or equal to, the value of this
  // keyword. Value of this keyword must be a number (integer or float).
  maximum?: number;
  // A number is valid against this keyword if is strictly less than the value of this keyword.
  // Value of this keyword must be a number (integer or float) or a boolean. If this keyword holds
  // a boolean, then the maximum keyword is required and is used as reference for comparison.
  exclusiveMaximum?: number;
  //  A number is valid against this keyword if the division between the number and the the value
  //  of this keyword results in an integer. Value of this keyword must be a strictly positive
  //  number (zero is not allowed).
  multipleOf?: number;
}

export type NumberValidationData = {
  minimum?: number,
  exclusiveMinimum?: number,
  maximum?: number,
  exclusiveMaximum?: number,
  multipleOf?: number,
};

export class JsonSchemaNumberConstraints extends Record({
  minimum: NaN,
  exclusiveMinimum: NaN,
  maximum: NaN,
  exclusiveMaximum: NaN,
  multipleOf: NaN,
}) implements JsonSchemaNumberConstraintsAttributes {

  public static EMPTY: JsonSchemaNumberConstraints = new JsonSchemaNumberConstraints();

  public readonly minimum: number;
  public readonly exclusiveMinimum: number;
  public readonly maximum: number;
  public readonly exclusiveMaximum: number;
  public readonly multipleOf: number;

  public static from(property: JsonSchemaPropertiesDefinition = {}): JsonSchemaNumberConstraints {

    const {
      type,
      minimum,
      exclusiveMinimum,
      maximum,
      exclusiveMaximum,
      multipleOf,
    } = property;

    if (JsonSchemaPropertyType.NUMBER !== type) {
      return JsonSchemaNumberConstraints.EMPTY;
    }

    const attrs = {
      ...(!isValidNumber(minimum) ? {} : { minimum: Number(minimum) }),
      ...(!isValidNumber(exclusiveMinimum) ? {} : { exclusiveMinimum: Number(exclusiveMinimum) }),
      ...(!isValidNumber(maximum) ? {} : { maximum: Number(maximum) }),
      ...(!isValidNumber(exclusiveMaximum) ? {} : { exclusiveMaximum: Number(exclusiveMaximum) }),
      ...(!isValidNumber(multipleOf) ? {} : { multipleOf: Number(multipleOf) }),
    };

    return new JsonSchemaNumberConstraints(attrs);
  }

  public hasMinimum(): boolean {
    return isValidNumber(this.minimum);
  }

  public hasExclusiveMinimum(): boolean {
    return isValidNumber(this.exclusiveMinimum);
  }

  public hasMaximum(): boolean {
    return isValidNumber(this.maximum);
  }

  public hasExclusiveMaximum(): boolean {
    return isValidNumber(this.exclusiveMaximum);
  }

  public hasMultipleOf(): boolean {
    return isValidNumber(this.multipleOf);
  }

  public getValidationData(): NumberValidationData {

    const data: NumberValidationData = { ...this.toJS() };

    if (!this.hasMinimum()) {
      delete data.minimum;
    }

    if (!this.hasExclusiveMinimum()) {
      delete data.exclusiveMinimum;
    }

    if (!this.hasMaximum()) {
      delete data.maximum;
    }

    if (!this.hasExclusiveMaximum()) {
      delete data.exclusiveMaximum;
    }

    if (!this.hasMultipleOf()) {
      delete data.multipleOf;
    }

    return data;
  }
}
