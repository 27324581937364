import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { getJson, getNamespaceName } from "../selectors";
import { JsonViewModel, JsonView } from "@components";
import { DEFAULT_STATE as DEFAULT_NAMESPACE_DETAILS_STATE } from "../reducers";

interface ContainerModel extends JsonViewModel {
    isLoading?: boolean;
}

const JsonViewContainer = (props: ContainerModel) => {

    const { isLoading, json, ...otherProps } = props;

    const jsonData = React.useMemo(() =>
        isLoading ? DEFAULT_NAMESPACE_DETAILS_STATE.json : json, [isLoading, json]);

    return (
        <JsonView
            {...otherProps}
            json={jsonData}
        />
    );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
    json: getJson(state),
    fileName: `${getNamespaceName(state)}.json`,
});

export default connect<ContainerModel, {}, ContainerModel>(
    mapStateToProps,
)(JsonViewContainer);
