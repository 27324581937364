import React from "react";
import classnames from "classnames";
import FolderIcon from "@material-ui/icons/Folder";
import { formatAsISO8601DateTimeString } from "@util";
import FileIcon from "@material-ui/icons/Description";
import DataLakeFileColumn from "../models/DataLakeFileColumn";
import DataLakeFile, { DataLakeFileType } from "../models/DataLakeFile";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface DataLakeFileListItemModel {
  className?: string;
  item?: DataLakeFile;
  column?: DataLakeFileColumn;
}

export interface DataLakeFileListItemActions {
}

type Model = DataLakeFileListItemModel;
type Actions = DataLakeFileListItemActions;
type Props = WithStyles<typeof styles> & Model & Actions;

const DataLakeFileListItemName = withStyles(styles)((props: Props) => {

  const { classes, className, item = DataLakeFile.EMPTY } = props;

  const fileName = React.useMemo(() => item.getFileName(), [item]);

  const fileType = React.useMemo(() => item.getFileType(), [item]);

  const Icon = React.useMemo(() => {
    switch (fileType) {
      case DataLakeFileType.FOLDER:
        return FolderIcon;
      case DataLakeFileType.FILE:
      default:
        return FileIcon;
    }
  }, [fileType]);

  if (DataLakeFile.EMPTY.equals(item)) {
    return null;
  }

  return (
    <div className={classnames("nameColumn", className, classes.container, classes.nameColumn)}>
      <Icon className={classnames("icon", classes.icon)} />
      <label className={classnames("label", classes.label)}>
        {fileName}
      </label>
    </div>
  );
});

export const DataLakeFileListItem = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    item = DataLakeFile.EMPTY,
    column = DataLakeFileColumn.NONE,
  } = props;

  if (DataLakeFile.EMPTY.equals(item) || DataLakeFileColumn.NONE === column) {
    return null;
  }

  switch (column) {
    case DataLakeFileColumn.NAME:
      return <DataLakeFileListItemName className={className} item={item} />;
    case DataLakeFileColumn.SIZE:
      return (
        <div className={classnames("sizeColumn", className, classes.container, classes.sizeColumn)}>
          <label className={classnames("label", classes.label)}>
            {item.getFileSize()}
          </label>
        </div>
      );
    case DataLakeFileColumn.UPDATED_AT:
      return (
        <div className={classnames("updatedAtColumn", className, classes.container, classes.updatedAtColumn)}>
          <label className={classnames("label", classes.label)}>
            {formatAsISO8601DateTimeString(item.getUpdatedAt(), "-")}
          </label>
        </div>
      );
    default:
      return null;
  }
});

export default DataLakeFileListItem;
