import React from "react";
import DataSetsListColumn from "../models/DataSetsListColumn";
import DataSetsListItemData from "../models/DataSetsListItemData";
import { createComparator, getSearchResults } from "../helpers";

export interface UseSortedAndFilteredDataSetsProps {
  items?: DataSetsListItemData[];
  sortByColumn?: DataSetsListColumn;
  sortOrderAscending?: boolean;
  nameFilter?: string;
}

type Props = UseSortedAndFilteredDataSetsProps;

export const useSortedSearchResults = (props: Props): DataSetsListItemData[] => {

  const {
    items = [],
    sortByColumn = DataSetsListColumn.NONE,
    sortOrderAscending = true,
    nameFilter = "",
  } = props;

  const comparator = React.useMemo(() =>
      createComparator(sortByColumn, sortOrderAscending),
    [sortByColumn, sortOrderAscending]);

  const sortedItems = React.useMemo(() =>
    items.sort(comparator).slice(), [items, comparator]);

  return React.useMemo(() =>
      getSearchResults(sortedItems, nameFilter),
    [sortedItems, nameFilter]);
};

export default useSortedSearchResults;
