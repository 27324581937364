import React from "react";
import useApiRequest, {
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import ReleaseNote, { ReleaseNoteAttributes } from "@data/ReleaseNote";
import usePortalReleaseNotesClient from "../use-portal-release-notes-client";

type SuccessResponse = ReleaseNoteAttributes;

export interface UseGetReleaseNoteProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  releaseId: string;
}

export interface UseGetReleaseNoteModel extends UseApiRequestModel<SuccessResponse> {
  releaseNote: ReleaseNote;
}

export interface UseGetReleaseNoteActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetReleaseNoteProps;
type Model = UseGetReleaseNoteModel;
type Actions = UseGetReleaseNoteActions;
type Result = [Model, Actions];

export const useGetReleaseNote = (props: Props): Result => {

  const {
    releaseId,
    defaultErrorMessage = "Failed to get release note",
    ...otherProps
  } = props;

  const PortalReleaseNotesClient = usePortalReleaseNotesClient();

  const makeApiRequest = React.useCallback(() =>
    PortalReleaseNotesClient.getReleaseNote(releaseId), [PortalReleaseNotesClient, releaseId]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const releaseNote = React.useMemo<ReleaseNote>(() => {
    return new ReleaseNote(successResponse || {});
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    releaseNote,
  }), [
    baseModel,
    releaseNote,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetReleaseNote;
