import React from "react";
import { noop } from "@util";
import { JsonSchemaPropertyDefaultValueType, JsonSchemaPropertyType } from "@data";
import BooleanDefaultValueEditor from "./BooleanDefaultValueEditor";
import IntegerDefaultValueEditor from "./IntegerDefaultValueEditor";
import NumberDefaultValueEditor from "./NumberDefaultValueEditor";
import StringDefaultValueEditor from "./StringDefaultValueEditor";
import EnumDefaultValueEditor from "./EnumDefaultValueEditor";

export type DefaultValueTypes = string | number | null | boolean | undefined;

export interface Model {
  className?: string;
  propertyType?: JsonSchemaPropertyType;
  enumValues?: JsonSchemaPropertyDefaultValueType[];
  defaultValue?: DefaultValueTypes;
  defaultValueError?: string;
  disabled?: boolean;
}

export interface Actions {
  setDefaultValue?: (defaultValue: DefaultValueTypes) => void;
  clearDefaultValueError?: () => void;
  save?: () => void;
}

export const DefaultValueEditor = (props: Model & Actions) => {

  const {
    propertyType,
    defaultValue,
    enumValues = [],
    ...remainingProps
  } = props;

  const showEnumEditor = Array.isArray(enumValues) && enumValues.length > 0;

  if (propertyType === JsonSchemaPropertyType.STRING) {

    if (!showEnumEditor) {
      return (
        <StringDefaultValueEditor
          {...remainingProps}
          defaultValue={defaultValue as string}
        />
      );
    }

    const { setDefaultValue = noop } = remainingProps;

    const setDefaultEnumValue = (selectedEnumValue: DefaultValueTypes) =>
      setDefaultValue(selectedEnumValue as string);

    return (
      <EnumDefaultValueEditor
        {...remainingProps}
        enumValues={enumValues as string[]}
        defaultValue={defaultValue as string}
        setDefaultValue={setDefaultEnumValue}
      />
    );
  }

  if (propertyType === JsonSchemaPropertyType.NUMBER) {

    if (!showEnumEditor) {
      return (
        <NumberDefaultValueEditor
          {...remainingProps}
          defaultValue={defaultValue as number}
        />
      );
    }

    const { setDefaultValue = noop } = remainingProps;

    const setDefaultEnumValue = (selectedEnumValue: DefaultValueTypes) =>
      setDefaultValue(Number(selectedEnumValue));

    return (
      <EnumDefaultValueEditor
        {...remainingProps}
        enumValues={enumValues as number[]}
        defaultValue={defaultValue as number}
        setDefaultValue={setDefaultEnumValue}
      />
    );
  }

  if (propertyType === JsonSchemaPropertyType.INTEGER) {

    if (!showEnumEditor) {
      return (
        <IntegerDefaultValueEditor
          {...remainingProps}
          defaultValue={defaultValue as number}
        />
      );
    }

    const { setDefaultValue = noop } = remainingProps;

    const setDefaultEnumValue = (selectedEnumValue: DefaultValueTypes) =>
      setDefaultValue(Number(selectedEnumValue));

    return (
      <EnumDefaultValueEditor
        {...remainingProps}
        enumValues={enumValues as number[]}
        defaultValue={defaultValue as number}
        setDefaultValue={setDefaultEnumValue}
      />
    );
  }

  if (propertyType === JsonSchemaPropertyType.BOOLEAN) {

    if (!showEnumEditor) {
      return (
        <BooleanDefaultValueEditor
          {...remainingProps}
          defaultValue={typeof defaultValue === "boolean" ? (defaultValue as boolean) : undefined}
        />
      );
    }

    const { setDefaultValue = noop } = remainingProps;

    const setDefaultEnumValue = (selectedEnumValue: DefaultValueTypes) =>
      setDefaultValue(selectedEnumValue as boolean);

    return (
      <EnumDefaultValueEditor
        {...remainingProps}
        enumValues={enumValues as boolean[]}
        defaultValue={defaultValue as boolean}
        setDefaultValue={setDefaultEnumValue}
      />
    );
  }

  if (propertyType === JsonSchemaPropertyType.NULL) {

    return (
      <EnumDefaultValueEditor {...props} enumValues={[null]} />
    );
  }

  return null;
};

export default DefaultValueEditor;
