import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { Model, Actions, SchemaDataView } from "../components/SchemaDataView";
import {
  isDeviceDataEditMode,
  getDeviceDataViewMode,
  isDeviceScopeViewActive,
  isTypeScopeViewActive,
  isRegionScopeViewActive
} from "../selectors";
import {
  setDeviceDataEditMode,
  openDeleteDataDialog,
} from "../actions";

type Props = Model & Actions;

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  readOnly: !isDeviceDataEditMode(state),
  dataMode: getDeviceDataViewMode(state),
  isDeviceScopeViewActive: isDeviceScopeViewActive(state),
  isTypeScopeViewActive: isTypeScopeViewActive(state),
  isRegionScopeViewActive: isRegionScopeViewActive(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  editData: () => dispatch(setDeviceDataEditMode(true)),
  deleteData: () => dispatch(openDeleteDataDialog()),
  ...ownProps
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(SchemaDataView);
