import React from "react";
import { noop } from "@util";
import { Policy } from "@data";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { setDetachPolicyButtonEnabled } from "../actions";
import { PoliciesList } from "@components";
import { PolicyOperationPrincipal, useAccountPolicies } from "@hooks";
import { getUserId, isCurrentUserViewActive, isCustomPoliciesViewActive } from "../selectors";

interface Model {
  currentUserViewActive?: boolean;
  hidden?: boolean;
  userId?: string;
}

interface Actions {
  showPolicyDetails?: (policy: Policy) => void;
  setDetachPolicyButtonEnabled?: (enabled: boolean) => void;
}

type Props = Model & Actions;

const CustomPoliciesView = (props: Props) => {

  const {
    hidden,
    userId,
    showPolicyDetails,
    currentUserViewActive,
    setDetachPolicyButtonEnabled: enableDetachPolicyButton = noop,
  } = props;

  const [ { policies, ...model }, actions ] = useAccountPolicies(PolicyOperationPrincipal.USER, userId);

  const { totalNumPolicies, errorMessage, showNotFound, ...otherModel } = model;

  const visibleErrorMessage = React.useMemo(() =>
    showNotFound ? "" : errorMessage, [showNotFound, errorMessage]);

  const loadingLabel = React.useMemo(() =>
      currentUserViewActive
        ? "Loading policies attached to you..."
        : "Loading policies attached to this user...",
    [currentUserViewActive]);

  const noResultsLabel = React.useMemo(() =>
      currentUserViewActive
        ? "You do not have any policies attached"
        : "User does not have any policies attached",
    [currentUserViewActive]);

  React.useEffect(() => {
    enableDetachPolicyButton(totalNumPolicies > 0);
  }, [enableDetachPolicyButton, totalNumPolicies]);

  if (hidden) {
    return null;
  }

  return (
    <PoliciesList
      {...otherModel}
      {...actions}
      className={"userCustomPolicies"}
      items={policies}
      onClickItem={showPolicyDetails}
      loadingLabel={loadingLabel}
      noResultsLabel={noResultsLabel}
      errorMessage={visibleErrorMessage}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  currentUserViewActive: isCurrentUserViewActive(state),
  hidden: !isCustomPoliciesViewActive(state),
  userId: getUserId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setDetachPolicyButtonEnabled: (enabled: boolean) => dispatch(setDetachPolicyButtonEnabled(enabled)),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(CustomPoliciesView);
