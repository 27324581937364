import React from "react";
import { connect } from "react-redux";
import { equalsIgnoreCase, isEmptyString } from "@util";
import { AppSchema } from "@main/schemas";
import { useUserProfileAttributes } from "@components";
import {
  getUserId,
  isCurrentUserViewActive,
  isUserClosed,
} from "../selectors";
import ProfileView, { Actions, Model } from "../components/ProfileView";
import EditProfileView from "./EditProfileView";

// TODO: Remove once IOTSEC figures out how they want the Binary Profile Attributes API to work
const SHOW_BINARY_PROFILE_ATTRIBUTES =
  equalsIgnoreCase("true", process.env.REACT_APP_BINARY_PROFILE_ATTRIBUTES_ENABLED);

interface ContainerModel extends Model {
  hidden?: boolean;
  closedUserAccount?: boolean;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const ProfileViewContainer = (props: Props) => {

  const { hidden, userId = "", selfAuthorized, closedUserAccount, ...otherProps } = props;

  const [ showEditMode, setShowEditMode ] = React.useState(false);

  const [ model, actions ] = useUserProfileAttributes(userId, selfAuthorized);

  const { data: allItems, items, errorMessage, showLoadingIndicator } = model;

  const binaryDataDetected = React.useMemo(() =>
    allItems.some(item => item.isBinaryDataType()),
    [allItems]);

  const showBinaryData = React.useMemo(() =>
    SHOW_BINARY_PROFILE_ATTRIBUTES && !showEditMode && binaryDataDetected,
    [showEditMode, binaryDataDetected]);

  const { refresh } = actions;

  const editButtonDisabled = React.useMemo(() =>
    closedUserAccount || showLoadingIndicator || !isEmptyString(errorMessage) || allItems.length === 0,
    [closedUserAccount, showLoadingIndicator, errorMessage, allItems]);

  if (hidden) {
    return null;
  }

  if (showEditMode) {
    return (
      <EditProfileView
        items={allItems}
        cancel={() => setShowEditMode(false)}
        onSuccess={() => {
          setShowEditMode(false);
          refresh();
        }}
      />
    );
  }

  return (
    <ProfileView
      {...model}
      {...actions}
      {...otherProps}
      items={items}
      userId={userId}
      showBinaryData={showBinaryData}
      selfAuthorized={selfAuthorized}
      editButtonDisabled={editButtonDisabled}
      edit={() => setShowEditMode(true)}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  userId: getUserId(state),
  selfAuthorized: isCurrentUserViewActive(state),
  closedUserAccount: isUserClosed(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps,
});

export default connect<Model, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileViewContainer);
