import { createStyles, Theme } from "@material-ui/core/styles";
import ModuleIcon from "@material-ui/icons/CalendarToday";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "32px",
      marginBottom: 25,
    },
    instructions: {
      marginBottom: 0,
      fontWeight: 300,
    },
    inputField: {
      marginBottom: 25,
    },
    typeFieldsContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: "20px",
      marginBottom: "20px",
    },
    typeDropdownContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "start",
      minWidth: 250,
      width: "fit-content",
    },
    dropdown: {
      minWidth: 100,
      marginTop: 10,
    },
    dropdownCron: {
      margin: "5px 10px 5px 10px",
      width: 130,
    },
    dropdownUnit: {
      margin: "5px 10px 5px 0px",
      "& .MuiInputBase-root": {
        height: 38,
        minWidth: 110,
      },
      "& .MuiFormHelperText-root": {
        fontSize: 12,
        textAlign: "left",
        opacity: 0.5,
        margin: "3px 0px 0px 15px"
      }
    },
    dropdownCronDisabled: {
      opacity: 0.3,
    },
    schedule: {
      color: Color.TEXT,
      marginTop: 20,
    },
    scheduleTitle: {
      padding: 20,
      fontSize: 18,
      fontWeight: 500,
      backgroundColor: Color.LIGHT_GREY7,
      borderBottom: "1px solid " + Color.LIGHT_GREY6
    },
    scheduleContent: {
      padding: 20,
    },
    scheduleDescription: {
      fontSize: 13,
      fontWeight: 300,
      color: Color.GREY4,
      marginTop: 5,
      marginBottom: 5,
    },
    scheduleNextDatesTitle: {
      marginTop: 20,
      marginBottom: 20,
    },
    scheduleNextDatesInfo: {
      fontSize: 13,
      fontWeight: 300,
      fontStyle: "italic",
      color: Color.GREY4,
      marginTop: 5,
      marginBottom: 5,
    },
    scheduleNextDates: {
      fontSize: 13,
      marginTop: 5,
      marginBottom: 5,
    },
    scheduleButton: {
      fontSize: 16,
      maxWidth: 350,
      minHeight: 100,
      height: "100%",
      textAlign: "left",
      lineHeight: 30,
      marginRight: 20,
      marginTop: 10,
      marginBottom: 20,
      paddingLeft: 0,
      border: "1px solid " + Color.GREY1,
      borderRadius: 5,
      alignItems: "normal",
      "& .MuiButton-label": {
        lineHeight: "28px",
      },
    },
    scheduleButtonChecked: {
      backgroundColor: Color.LIGHT_GREEN2,
      border: "1px solid " + Color.SIGNIFY_GREEN,
    },
    scheduleRadio: {
      alignItems: "normal",
      paddingTop: 2,
      "&&:hover": {
        backgroundColor: "transparent",
      },
    },
    settings: {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "baseline",
      fontWeight: 500
    },
    link: {
      fontSize: 13,
      color: Color.SIGNIFY_GREEN,
      "&:hover": {
        color: Color.SIGNIFY_GREEN3,
      }
    },
    divider: {
      marginLeft: 10,
      marginRight: 10,
      color: Color.LIGHT_GREY5,
    },
    expressionTitle: {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "baseline",
    },
    warn: {
      color: "red",
      fontSize: 13,
      fontWeight: 300,
    },
    clearButton: {
      height: "auto",
      minWidth: "auto",
      margin: "6px 6px 6px 20px",
      background: Color.LIGHT_GREY4,
    },
    clearButtonLabel: {
      textTransform: "uppercase",
    },
    rateValue: {
      maxWidth: 110,
      margin: "5px 10px 5px 10px",
      "& .MuiInputBase-input": {
        width: "100%",
        textAlign: "left",
        paddingLeft: 15
      }
    },
    textField: {
      color: Color.TEXT,
      fontSize: 16,
      height: "auto",
    },
    rateTextField: {
      fontSize: 12,
      width: "50px",
      fontWeight: 400,
      color: Color.TEXT,
      padding: "5px",
      textAlign: "center",
    },
    formHelperText: {
      color: Color.TEXT,
      fontSize: 12,
      textAlign: "left",
      opacity: 0.5,
      margin: "3px 0px 0px 15px"
    },
    formHelperTextError: {
      marginBottom: 15,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    dataSetsTitle: {
      color: Color.TEXT,
      fontWeight: 300,
      marginBottom: 0,
    },
    dataSetsList: {
      "& table": {
        marginTop: 0,
      },
      "& th.name" : {
        width: "auto"
      },
      "& th.account" : {
        width: "auto"
      }
    }
  });

export const workloads = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: -50,
      "& .summaryViewContainer label": {
        fontSize: 16,
        fontWeight: 300,
      },
      "& .search": {
        marginTop: 0,
      },
    },
  });

export default styles;
