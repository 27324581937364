import { createStyles } from "@material-ui/core/styles";
import { SettingsIcon as ModuleIcon } from "@components/icons";
import Color from "@components/styles/Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
    },
    header: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontSize: 24,
      fontWeight: 300,
      lineHeight: "29px",
      marginLeft: 10,
      "&:first-child": {
        marginLeft: 0,
      },
    },
    documentation: {
      alignSelf: "stretch",
      backgroundColor: "#F5F5F5",
      borderRadius: 3,
      marginTop: 35,
      padding: 20,
      maxWidth: "70%",
    },
    label: {
      color: Color.GREY6,
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 300,
      lineHeight: "24px",
      margin: 0,
    },
    version: {
      position: "absolute",
      bottom: 0,
      right: 0,
      margin: 10,
      fontSize: 10,
    },
  });

export default styles;
