import React from "react";
import {
  Model,
  Actions,
  ChildrenView
} from "../components/ChildrenView";
import DSTypeListDialog from "./DSTypeListDialog";
import RemoveChildDialog from "../components/RemoveChildDialog";
import { DSLayoutInfo, DSTypeInfo, DSTypeInfoAttributes } from "@data";
import { isEmptyString, noop } from "@util";

interface ContainerModel extends Model {
  existingTypeName?: string[];
  layout?: DSLayoutInfo;
}

interface ContainerActions extends Actions {
  updateChildren?: (children: DSTypeInfoAttributes[]) => void;
}

const ChildrenViewContainer = (props: ContainerModel & ContainerActions) => {

  const {
    existingTypeName = [],
    layout = DSLayoutInfo.EMPTY,
    updateChildren = noop,
    ...otherProps
  } = props;

  const [ openDSTypeDialog, setOpeDSTypeDialog ] = React.useState(false);
  const [ openRemoveChildDialog, setOpenRemoveChildDialog ] = React.useState(false);
  const [ resetExpanded, setResetExpanded ] = React.useState(false);

  const [parent, setParent] = React.useState("");
  const [childToRemove, setChildToRemove] = React.useState(DSTypeInfo.EMPTY);

  const addFirstChild = React.useCallback((child: DSTypeInfo) => {
    updateChildren([ { ...child.toJS() }]);
  }, [updateChildren]);

  const appendChildLayout = React.useCallback<any>(
    (existingChildren: any, parentName: string, childData: DSTypeInfoAttributes) => {
      if (!existingChildren) {
        return existingChildren;
      }

      let children;

      if (existingChildren.name === parentName) {
        children = existingChildren.hasOwnProperty("children")
          ? [...existingChildren.children, childData] : [childData];
      } else {
        children = existingChildren.children.map((childNode: any) =>
          appendChildLayout(childNode, parentName, childData));
      }

      return { ...existingChildren, children };
    }, []);

  const removeChildLayout = React.useCallback<any>(
    (existingChildren: DSTypeInfoAttributes[], childRemove: string) => {
      let obj = existingChildren.filter(item => {
        return item.name !== childRemove;
      });
      for (let i in obj ) {
        if (obj[i].hasOwnProperty("children")) {
          obj[i].children = removeChildLayout(obj[i].children, childRemove);
        }
      }
      return obj;
    }, []);

  const addChildToType = React.useCallback(
    (child: DSTypeInfo, parentType: DSTypeInfo = DSTypeInfo.EMPTY) => {
      if (parentType.equals( DSTypeInfo.EMPTY)) {
        return addFirstChild(child);
      }
      const updatedChildren = appendChildLayout(layout.getChildren()[0], parentType.getName(), child.toJS());
      updateChildren([updatedChildren]);
    }, [appendChildLayout, updateChildren, layout]);

  const removeChildFromType = React.useCallback(
    (child: DSTypeInfo) => {
      const updatedChildren = removeChildLayout(layout.getChildren(), child.getName());
      updateChildren(updatedChildren);
    }, [removeChildLayout, updateChildren, layout]);

  const onClickAddChildren = React.useCallback(() => {
    setOpeDSTypeDialog(true);
  }, [setOpeDSTypeDialog, parent]);

  const cancel = React.useCallback(() => {
    setOpeDSTypeDialog(false);
  }, [setOpeDSTypeDialog]);

  const addChild = React.useCallback((child: DSTypeInfo) => {
    setOpeDSTypeDialog(false);
    setResetExpanded(false);
    const parentType = isEmptyString(parent) ? DSTypeInfo.EMPTY : new DSTypeInfo({ name: parent});
    addChildToType(child, parentType);
  }, [addChildToType, parent, setOpeDSTypeDialog, setResetExpanded]);

  const onClickRemoveChild = React.useCallback((item: DSTypeInfo) => {
    setOpenRemoveChildDialog(true);
    setChildToRemove(item);
  }, [setOpenRemoveChildDialog, setChildToRemove]);

  const cancelRemoveChild = React.useCallback(() => {
    setOpenRemoveChildDialog(false);
    setChildToRemove(DSTypeInfo.EMPTY);
  }, [setOpenRemoveChildDialog, setChildToRemove]);

  const removeChildConfirm = React.useCallback((item: DSTypeInfo) => {
    setOpenRemoveChildDialog(false);
    removeChildFromType(item);
    setResetExpanded(true);
    setParent("");
  }, [removeChildFromType, setOpenRemoveChildDialog, setResetExpanded, setParent]);

  const title = React.useMemo(() =>
    isEmptyString(parent) ? "Add child" : `Add child to ${parent}`, [parent]);

  return (
    <React.Fragment>
      <ChildrenView
        {...otherProps}
        onClickAddChildren={onClickAddChildren}
        setParentNodeName={setParent}
        resetExpanded={resetExpanded}
        removeChild={onClickRemoveChild}
      >
        <DSTypeListDialog
          open={openDSTypeDialog}
          title={title}
          cancel={cancel}
          addChild={addChild}
          existingTypes={existingTypeName}
        />
        <RemoveChildDialog
          open={openRemoveChildDialog}
          item={childToRemove}
          confirm={removeChildConfirm}
          cancel={cancelRemoveChild}
        />
      </ChildrenView>
    </React.Fragment>
  );
};

export default ChildrenViewContainer;
