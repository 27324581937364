import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ErrorView, LoadingView } from "@components";
import { ResultsView } from "./ResultsView";
import { SchemaView } from "./SchemaView";
import { DataView } from "./DataView";
import { styles, primaryColor } from "./styles";
import { isValidJson } from "@util";

export interface SchemaValidationModel {
  data?: string;
  schema?: string;
  successMessage?: string;
  errorMessage?: string;
  showAccessDenied?: boolean;
  showEmptyView?: boolean;
  showLoadingIndicator?: boolean;
}

export interface SchemaValidationActions {
  setData?: (data: string) => void;
}

type Props = WithStyles<typeof styles> & SchemaValidationModel & SchemaValidationActions;

export const SchemaValidationView = withStyles(styles)((props: Props) => {

  const {
    classes,
    data = "",
    errorMessage = "",
    successMessage = "",
    showAccessDenied,
    showLoadingIndicator,
    showEmptyView,
    ...remainingProps
  } = props;

  const isJsonValid = React.useMemo(() => isValidJson(data), [data]);

  const visibleErrorMessage = React.useMemo(() =>
    isJsonValid ? errorMessage : "", [isJsonValid, errorMessage]);

  const showSuccessMessage = React.useMemo(() =>
    successMessage.trim().length > 0, [successMessage]);

  const showErrorMessage = React.useMemo(() =>
    visibleErrorMessage.trim().length > 0, [visibleErrorMessage]);

  const showInvalidJsonError = React.useMemo(() =>
    data.trim().length > 0 && !isJsonValid, [data, isJsonValid]);

  if (showAccessDenied) {
    return (
      <ErrorView
        className={classnames("accessDenied", classes.accessDenied)}
        title="Access Denied"
        message="You do not have permission to perform this action"
      />
    );
  }

  if (showEmptyView) {
    return (
      <LoadingView color={primaryColor} />
    );
  }

  const {
    schema,
    setData,
  } = remainingProps;

  return (
    <div className={classnames("validationView", classes.container)}>
      <SchemaView
        className={classnames("schemaView", classes.schemaView)}
        json={schema}
      />
      <div className={classnames("viewContainer", classes.viewContainer)}>
        <DataView
          className={classnames("dataView", classes.dataView)}
          json={data}
          showErrorMessage={showErrorMessage}
          showSuccessMessage={showSuccessMessage}
          showLoadingIndicator={showLoadingIndicator}
          showInvalidJsonError={showInvalidJsonError}
          setJson={setData}
        />
        <ResultsView
          className={classnames("resultsView", classes.resultsView)}
          message={showErrorMessage ? errorMessage : successMessage}
          showErrorMessage={showErrorMessage}
          showSuccessMessage={showSuccessMessage}
          showLoadingIndicator={showLoadingIndicator}
        />
      </div>
    </div>
  );
});

export default SchemaValidationView;
