import { getStringValue } from "@util";
import { Record } from "immutable";

export interface DataLakeIndexQueryAttributes {
  accountId: string;
  dataSetAlias: string;
  startDate: string;
  endDate?: string;
  fileNameContains?: string;
}

export class DataLakeIndexQuery extends Record({
  accountId: "",
  dataSetAlias: "",
  startDate: "",
  endDate: "",
  fileNameContains: "",
}) implements DataLakeIndexQueryAttributes {

  public static EMPTY: DataLakeIndexQuery = new DataLakeIndexQuery();

  public readonly accountId: string;
  public readonly dataSetAlias: string;
  public readonly startDate: string;
  public readonly endDate: string;
  public readonly fileNameContains: string;

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getDataSetAlias(): string {
    return getStringValue(this.dataSetAlias);
  }

  public getStartDate(): string {
    return getStringValue(this.startDate);
  }

  public getEndDate(): string {
    return getStringValue(this.endDate);
  }

  public getFileNameContains(): string {
    return getStringValue(this.fileNameContains);
  }
}

export default DataLakeIndexQuery;
