import { Module } from "@data";
import { isDeviceManagementEnabled } from "@util";
import { DeviceEnrollmentWizardActions } from "./actions";
import { createModule } from "@modules/base/createModule";
import { getEnrollDevicePath, getValidateDevicePath } from "./helpers";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { DeviceEnrollmentWizardSelectors } from "./selectors";
import DeviceEnrollmentWizardView from "./DeviceEnrollmentWizardView";

export const enrollDeviceModule: Module = createModule ({
  id: "enroll_device_wizard",
  path: getEnrollDevicePath(),
  exactPath: true,
  strictPath: true,
  name: "Device Enrollment Wizard",
  title: "Device Enrollment Wizard",
  className: "enrollDeviceModule",
  primaryColor,
  icon,
  view: DeviceEnrollmentWizardView,
  isEmptyViewVisible: DeviceEnrollmentWizardSelectors.isEmptyViewVisible,
  isAccessDeniedVisible: DeviceEnrollmentWizardSelectors.isAccessDeniedVisible,
  onMount: DeviceEnrollmentWizardActions.openWizard,
  disabled: !isDeviceManagementEnabled(),
});

export const validateDeviceModule: Module = createModule ({
  id: "validate_device_wizard",
  path: getValidateDevicePath(),
  exactPath: true,
  strictPath: true,
  name: "Validate Device Wizard",
  title: "Validate Device Wizard",
  className: "validateDeviceModule",
  primaryColor,
  icon,
  view: DeviceEnrollmentWizardView,
  isEmptyViewVisible: DeviceEnrollmentWizardSelectors.isEmptyViewVisible,
  isAccessDeniedVisible: DeviceEnrollmentWizardSelectors.isAccessDeniedVisible,
  onMount: DeviceEnrollmentWizardActions.initialize,
  disabled: !isDeviceManagementEnabled(),
});

export default enrollDeviceModule;
