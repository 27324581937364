import React from "react";
import { noop } from "@util";
import { Button } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { listItem as styles } from "./styles";
import { PolicyStatement } from "@data";
import { PolicyStatementsListColumn } from "./PolicyStatementsListColumn";
import classnames from "classnames";

export const PolicyStatementListItems = withStyles(styles)((props: WithStyles<typeof styles> & {
  openConditionDialog: (json: string) => void;
  statement: PolicyStatement,
  column: string,
}) => {

  const {
    classes,
    column,
    statement = PolicyStatement.EMPTY,
    openConditionDialog = noop
  } = props;

  if ( !column || PolicyStatement.EMPTY.equals(statement) ) {
    return null;
  }

  const onClickCondition = React.useCallback(() => {
    return openConditionDialog(JSON.stringify(statement.condition, null, "  "));
  }, [statement, openConditionDialog]);

  switch (column) {
    case PolicyStatementsListColumn.ID:
      return (
        <div className={classnames("id", classes.container)}>
          <label className={classnames("id", classes.label)}>{statement.id}</label>
        </div>
      );
    case PolicyStatementsListColumn.ACTION:
      return (
        <label className={classnames("action", classes.label)}>{statement.action}</label>
      );
    case PolicyStatementsListColumn.RESOURCE:
      return (
        <label className={classnames("resource", classes.label)}>{statement.resource}</label>
      );
    case PolicyStatementsListColumn.EFFECT:
      return (
        <label className={classnames("effect", classes.label)}>{statement.effect}</label>
      );
    case PolicyStatementsListColumn.CONDITION:
      return (
        Object.keys(statement.condition).length ? (
          <label className={classnames("condition", classes.label)}>
            <Button
              variant="outlined"
              onClick={onClickCondition}
            >
              View
            </Button>
          </label>
        ) : null
      );
    default:
      return null;
  }
});

export default PolicyStatementListItems;
