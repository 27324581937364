import React from "react";
import { useDispatch } from "react-redux";
import { DataSetRequestWizardActions } from "./actions";
import { useHistory, useLocation } from "react-router-dom";
import { DataSetRequest, DataSetRequestAttributes } from "@data";
import DataSetRequestWizard from "./containers/DataSetRequestWizard";
import { getPathToDataSetRequest } from "@modules/dataSetRequestDetails/helpers";

type LocationProps = {
  dataSetRequest?: DataSetRequestAttributes;
};

export function CreateDataSetRequestView() {

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    state: {
      dataSetRequest: dataSetRequestAttributes = DataSetRequest.EMPTY.toJS(),
    } = {},
  } = useLocation<LocationProps>();

  const [initialized, setInitialized] = React.useState(false);

  const dataSetRequest = React.useMemo<null | DataSetRequest>(() =>
      typeof dataSetRequestAttributes !== "object" ? null : new DataSetRequest(dataSetRequestAttributes),
    [dataSetRequestAttributes]);

  const isCloneFlowActive = React.useMemo(() =>
    dataSetRequest !== null && !DataSetRequest.EMPTY.equals(dataSetRequest),
    [dataSetRequest]);

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const showDataSetDetails = React.useCallback(dataSetRequestId => {
    history.replace(getPathToDataSetRequest(dataSetRequestId));
  }, [history]);

  React.useEffect(() => {

    if (!initialized) {

      if (isCloneFlowActive) {
        dispatch(DataSetRequestWizardActions.cloneDataSetRequest(dataSetRequest as DataSetRequest));
      }

      setInitialized(true);
    }

  }, [initialized, isCloneFlowActive, dataSetRequest, dispatch, setInitialized]);

  if (!initialized) {
    return null;
  }

  return (
    <DataSetRequestWizard
      snackbarId="create-data-set-request"
      cancel={cancel}
      showDataSetDetails={showDataSetDetails}
    />
  );
}

export default CreateDataSetRequestView;
