import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import ActionsMenu, { ActionMenuItem, ActionsMenuProps } from "../actions-menu";

export enum DataSetAction {
  NONE = "",
  REQUEST_ACCESS = "request_access",
}

export const REQUEST_ACCESS: ActionMenuItem = Object.freeze({
  id: DataSetAction.REQUEST_ACCESS,
  name: "Request Access",
  disabled: false,
  hidden: false,
});

export const DEFAULT_DATA_SET_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  REQUEST_ACCESS,
]) as ActionMenuItem[];

export interface DataSetActionsMenuProps extends ActionsMenuProps {
  requestDataSetAccess?: () => void;
}

type Props = DataSetActionsMenuProps;

export const withDataSetActions = (Component: React.ComponentType<Props>) => (props: Props) => {

  const {
    actions = DEFAULT_DATA_SET_ACTION_MENU_ITEMS,
    requestDataSetAccess = noop,
    onClickAction = noop,
    ...otherProps
  } = props;

  const onActionClicked = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case DataSetAction.REQUEST_ACCESS:
        return requestDataSetAccess();
      default:
        return onClickAction(action);
    }
  }, [requestDataSetAccess, onClickAction]);

  return (
    <Component
      {...otherProps}
      actions={actions}
      onClickAction={onActionClicked}
    />
  );
};

export const DataSetActionsMenu = withDataSetActions((props: Props) => {

  const { className, buttonLabel = "Data Set Actions", ...otherProps } = props;

  return (
    <ActionsMenu
      {...otherProps}
      className={classnames("dataSetActionsMenu", className)}
      buttonLabel={buttonLabel}
    />
  );
});

export default DataSetActionsMenu;
