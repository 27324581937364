import { Module } from "@data";
import { initialize } from "./actions";
import { createModule } from "@base/createModule";
import { getPathToDataAccessRequest } from "./helpers";
import { equalsIgnoreCase, isEmptyString } from "@util";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { isAccessDeniedVisible, isEmptyViewVisible } from "./selectors";
import {
  LEGACY_MODULE_PATH,
  MODULE_ID,
  MODULE_PATH,
  VIEW_ACCESS_REQUEST_COMMENTS_REDIRECT,
} from "./constants";
import view from "./DataAccessRequestDetailsView";

type RouteParams = { id?: string };

export const dataAccessRequestDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATH,
  legacyPath: LEGACY_MODULE_PATH,
  exactPath: false,
  strictPath: false,
  name: "Data Access Request Details",
  title: "Data Access Request Details",
  className: "dataAccessRequestDetailsModule",
  primaryColor,
  icon,
  view,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: ({ id = "" }: RouteParams = {}) => initialize(id),
  redirects: {
    [VIEW_ACCESS_REQUEST_COMMENTS_REDIRECT]: (action, resourceId, extraQueryParams) =>
      isEmptyString(resourceId) ? null : `${getPathToDataAccessRequest(resourceId)}/comments?${extraQueryParams}`,
  },
  disabled: !equalsIgnoreCase("true", process.env.REACT_APP_DATA_GOVERNANCE_MODULES_ENABLED),
});

export default dataAccessRequestDetailsModule;
