import React from "react";
import classnames from "classnames";
import { isEmptyString } from "@util";
import {
  AccessRequestApprovalDetail,
  AccessRequestPrincipalType,
  AccessRequestType,
  DataAccessRequest,
  RequestState,
} from "@data";
import SummaryViewLabel from "@components/summary-view-label";
import { AccountIdContext } from "@components/account-id-provider";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { getRequestStateDisplayName } from "@modules/dataApprovalRequests/helpers";
import styles from "./styles";
import AccessRequestApprovalDetailsList
  from "@components/data-access-request-summary-view/AccessRequestApprovalDetailsList";

export interface DataAccessRequestSummaryViewModel {
  className?: string;
  dataAccessRequest?: DataAccessRequest;
  currentAccountId?: string;
  accountId?: string;
  showAccountId?: boolean;
  dataSetAlias?: string;
  showDataSetAlias?: boolean;
  reason?: string;
  accessRequestType?: AccessRequestType;
  accessRequestPrincipalType?: AccessRequestPrincipalType;
  servicePrincipalIdentifier?: string;
  dataSetGroup?: string;
  deviceType?: string;
  deviceNamespace?: string;
  trustedAccountsForCloudRole?: string;
  cloudRoleIdentifier?: string;
  cloudStorageIdentifier?: string;
  requestState?: RequestState;
  requestedBy?: string;
  requestedAtTime?: string;
  accessRequestApprovalDetails?: AccessRequestApprovalDetail[];
}

export interface DataAccessRequestSummaryViewActions {
}

type Model = DataAccessRequestSummaryViewModel;
type Actions = DataAccessRequestSummaryViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataAccessRequestSummaryView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    dataAccessRequest = DataAccessRequest.EMPTY,
    currentAccountId = React.useContext(AccountIdContext),
    accountId = dataAccessRequest.getAccountId(),
    showAccountId = accountId !== currentAccountId,
    dataSetAlias = dataAccessRequest.getDataSetAlias(),
    showDataSetAlias = !isEmptyString(dataSetAlias),
    reason = dataAccessRequest.getReason(),
    accessRequestType = dataAccessRequest.getAccessRequestType(),
    accessRequestPrincipalType = dataAccessRequest.getAccessRequestPrincipalType(),
    servicePrincipalIdentifier = dataAccessRequest.getServicePrincipalIdentifier(),
    dataSetGroup = dataAccessRequest.getDataSetGroup(),
    deviceType = dataAccessRequest.getDeviceType(),
    deviceNamespace = dataAccessRequest.getDeviceNamespace(),
    trustedAccountsForCloudRole = dataAccessRequest.getTrustedAccountsForCloudRole().join(", "),
    cloudRoleIdentifier = dataAccessRequest.getCloudRoleIdentifier(),
    cloudStorageIdentifier = dataAccessRequest.getCloudStorageIdentifier(),
    requestState = dataAccessRequest.getRequestState(),
    requestedBy = dataAccessRequest.getRequestedBy(),
    requestedAtTime = dataAccessRequest.getRequestedAtTime(),
    accessRequestApprovalDetails = dataAccessRequest.getAccessRequestApprovalDetails(),
    children,
  } = props;

  return (
    <div className={classnames("dataAccessRequestSummaryView", className, classes.container)}>
      {showDataSetAlias && (
        <React.Fragment>
          <SummaryViewLabel
            className="requestedBy"
            name="Requested By"
            value={requestedBy}
          />
          <SummaryViewLabel
            className="requestedAtTime"
            name="Request Time"
            value={requestedAtTime}
          />
          {showAccountId && (
            <SummaryViewLabel
              className="accountId"
              name="Account Id"
              value={accountId}
            />
          )}
          <SummaryViewLabel
            className="dataSetAlias"
            name="Data Set"
            value={dataSetAlias}
          />
        </React.Fragment>
      )}
      <SummaryViewLabel
        className="accessRequestType"
        name="Access Type"
        value={accessRequestType}
      />
      <SummaryViewLabel
        className="accessRequestPrincipalType"
        name="Request Type"
        value={accessRequestPrincipalType}
      />
      <SummaryViewLabel
        className="servicePrincipalIdentifier"
        name="Service Principal Identifier"
        value={servicePrincipalIdentifier}
      />
      <SummaryViewLabel
        className="dataSetGroup"
        name="Data Set Group"
        value={dataSetGroup}
      />
      <SummaryViewLabel
        className="deviceType"
        name="Device Namespace"
        value={deviceNamespace}
      />
      <SummaryViewLabel
        className="deviceType"
        name="Device Type"
        value={deviceType}
      />
      <SummaryViewLabel
        className="trustedAccountsForCloudRole"
        name="Trusted Accounts for Cloud Role"
        value={trustedAccountsForCloudRole}
      />
      <SummaryViewLabel
        className="cloudRoleIdentifier"
        name="Cloud Role Identifier"
        value={cloudRoleIdentifier}
      />
      <SummaryViewLabel
        className="cloudStorageIdentifier"
        name="Cloud Storage Identifier"
        value={cloudStorageIdentifier}
      />
      <SummaryViewLabel
        className="requestState"
        name="State"
        value={getRequestStateDisplayName(requestState)}
      />
      <SummaryViewLabel
        className="reason"
        name="Reason"
        value={reason}
      />
      <AccessRequestApprovalDetailsList
        className={classnames("accessRequestApprovalDetails", classes.accessRequestApprovalDetails)}
        items={accessRequestApprovalDetails}
      />
      {children}
    </div>
  );
});

export default DataAccessRequestSummaryView;
