import React from "react";
import classnames from "classnames";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { clickHandler, equalsIgnoreCase, noop } from "@util";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles, dialog, dialogTitle, dialogContent, dialogActions } from "./styles";

const ANALYTICS_ENABLED =
  !equalsIgnoreCase("true", process.env.REACT_APP_ANALYTICS_DISABLED);

export const SIGNIFY_PRIVACY_NOTICE_LINK = "https://signify.service-now.com/hrportal" +
  "?id=sig_hr_kb_article&sys_id=49cd6de1db1b634038ec18fe3b9619ee#";

export const GOOGLE_PRIVACY_NOTICE_LINK =
  "https://policies.google.com/technologies/partner-sites";

const TermsOfServiceDialog = withStyles(dialog)(Dialog);
const TermsOfServiceDialogTitle = withStyles(dialogTitle)(DialogTitle);
const TermsOfServiceDialogContent = withStyles(dialogContent)(DialogContent);
const TermsOfServiceDialogActions = withStyles(dialogActions)(DialogActions);

type StyleProps = WithStyles<typeof styles>;

export const PrivacyNoticeLink = withStyles(styles)((props: StyleProps & {
  href: string,
  title: string,
}) => {

  const { classes, href, title } = props;

  return (
    <a
      className={classnames("privacyNoticeLink", classes.label, classes.link)}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {title}
    </a>
  );
});

export const TermsDialog = withStyles(styles)((props: StyleProps & {
  close?: () => void,
  onClose?: () => void,
}) => {

  const { classes, close, onClose = noop, } = props;

  return (
    <TermsOfServiceDialog
      className="termsOfServiceDialog"
      open={true}
      maxWidth="sm"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose(event, reason);
        }
      }}
    >
      <TermsOfServiceDialogTitle className="title" disableTypography={true}>
        Terms of use
      </TermsOfServiceDialogTitle>
      <TermsOfServiceDialogContent className="content">
        <p className={classes.label}>
          Please be advised that the Data made available through this tool is confidential
          and protected under Signify information guidelines.
        </p>
        <p className={classes.label}>
          Employee personal data used for authentication purposes will be processed in
          accordance with our&nbsp;
          <PrivacyNoticeLink
            title="Signify Employees Privacy Notice"
            href={SIGNIFY_PRIVACY_NOTICE_LINK}
          />
          &nbsp;(requires VPN).
        </p>
        <p className={classes.label}>
          The Data is the property of Signify Business Units and is not the intended for
          forwarding, dissemination, or reproduction. Access to the Data is provided by the
          respective Data Owner through the IoT Data Governance process and is provided for
          specified use only.
        </p>
        {ANALYTICS_ENABLED && (
          <p className={classes.label}>
            Please note that this application uses cookies to improve user experience by collecting
            non-personal user activity using Google Analytics. You can learn more about how Google
            collects and processes data&nbsp;
            <PrivacyNoticeLink
              title="here"
              href={GOOGLE_PRIVACY_NOTICE_LINK}
            />.
          </p>
        )}
      </TermsOfServiceDialogContent>
      <TermsOfServiceDialogActions className="actions">
        <Button
          className={classnames("closeButton", classes.closeButton)}
          color="primary"
          onClick={clickHandler(close)}
        >
          Close
        </Button>
      </TermsOfServiceDialogActions>
    </TermsOfServiceDialog>
  );
});

export default TermsDialog;
