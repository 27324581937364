import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";
import { EnrollmentStatus } from "@data/DeviceEnrollment";

export interface DeviceAttributes {
  batchId: string;
  deviceId?: string;
  deviceReference?: string;
  status: EnrollmentStatus;
  statusMessage?: string;
  updatedAt?: string;
  updatedBy?: string;
}

export class Device extends Record({
  batchId: "",
  deviceId: "",
  deviceReference: "",
  status: EnrollmentStatus.QUEUED,
  statusMessage: "",
  updatedBy: "",
  updatedAt: "",
})implements DeviceAttributes {

  public static EMPTY: Device = new Device();

  public readonly batchId: string;
  public readonly deviceId: string;
  public readonly deviceReference: string;
  public readonly status: EnrollmentStatus;
  public readonly statusMessage: string;
  public readonly updatedBy: string;
  public readonly updatedAt: string;

  public getDeviceId(): string {

    if (!isEmptyString(this.deviceReference)) {
      return getStringValue(this.deviceReference.split(":").pop());
    } else {
      return getStringValue(this.deviceId);
    }
  }

  public getBatchId(): string {
    return getStringValue(this.batchId);
  }

  public getStatus(): string {
    return this.status;
  }

  public getStatusMessage(): string {
    return getStringValue(this.statusMessage);
  }

  public getUpdatedBy(): string {
    return getStringValue(this.updatedBy);
  }

  public getUpdatedPerson(): string {
    return getStringValue(this.getUpdatedBy().split(":").pop());
  }

  public getUpdatedAt(): string {
    return getStringValue(this.updatedAt);
  }
}

export default Device;
