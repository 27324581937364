import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { DataLakeFileBrowser } from "@components";
import { DataLakeIndex } from "@data";
import { noop } from "@util";

export interface DataLakeFileBrowserDialogModel extends PortalModuleDialogModel<string> {
  name?: string;
  version?: number;
  dataSetAlias?: string;
  accountId?: string;
  title?: string;
  header?: string;
}

export interface DataLakeFileBrowserDialogActions extends PortalModuleDialogActions<string> {
  setDataLakeIndex?: (file: DataLakeIndex) => void;
}

type Props = WithStyles<typeof styles> & DataLakeFileBrowserDialogModel & DataLakeFileBrowserDialogActions;

export const DataLakeFileBrowserDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    dataSetAlias = "",
    title = "Data Lake File Browser",
    header = "Select data lake file",
    setDataLakeIndex = noop,
    onClose = noop,
    ...otherProps
  } = props;

  return (
    <PortalModuleDialog
      {...otherProps}
      className="dataLakeFileBrowserDialog"
      maxWidth="lg"
      title={title}
      continueButtonLabel="Select"
      hideContinueButton={true}
    >
      <div className={classnames("dataLakeFileBrowserDialogContainer", classes.container)}>
        <label className={classnames("title", classes.title)}>
          {header}
        </label>
        <DataLakeFileBrowser
          {...otherProps}
          className={classnames("fileBrowserView", classes.browserContainer)}
          headerClassName={classnames("fileBrowserViewHeader", classes.header)}
          contentClassName={classnames("fileBrowserViewContent", classes.content)}
          listClassName={classnames("fileBrowserViewList", classes.list)}
          dataSetAlias={dataSetAlias}
          setDataLakeIndex={setDataLakeIndex}
          customActionLabel="Select File"
        />
      </div>
    </PortalModuleDialog>
  );
});

export default DataLakeFileBrowserDialog;
