import React from "react";
import classnames from "classnames";
import { styles, textField } from "./styles";
import TextField from "../text-field/TextField";
import { formEventHandler, hasSpecialChars, isEmptyString, noop } from "@util";
import { CreateWorkloadRequest } from "@data/CreateWorkloadRequest";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { useWorkloadConfigurationManagement, WorkloadConfigurationOperations } from "@hooks";
import WorkloadConfigurationOperation
  from "@hooks/workloadsManager/use-workload-configuration-management/WorkloadConfigurationOperation";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";

const ConfigurationTextField = withStyles(textField)(TextField);

export interface WorkloadConfigurationOperationDialogModel extends PortalModuleDialogModel<string> {
  data?: CreateWorkloadRequest;
  dialogClassName?: string;
  name: string;
  version: number;
  operation: WorkloadConfigurationOperations;
  keyValue?: string;
  value?: string;
  keys?: string[];
}

export interface WorkloadConfigurationOperationDialogActions extends PortalModuleDialogActions<string> {
  setWorkloadData?: (data: CreateWorkloadRequest) => void;
}

type Props = WithStyles< typeof styles> &
  WorkloadConfigurationOperationDialogModel & WorkloadConfigurationOperationDialogActions;

export const WorkloadKeyValueOperationDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    dialogClassName,
    name,
    version,
    keyValue: initialKey = "",
    value: initialValue = "",
    keys = [],
    data = CreateWorkloadRequest.EMPTY,
    operation = WorkloadConfigurationOperations.NONE,
    setWorkloadData = noop,
    cancel = noop,
    ...otherProps
  } = props;

  if (operation === WorkloadConfigurationOperations.NONE) {
    return null;
  }

  const [key, setKey] = React.useState(initialKey);
  const [value, setValue] = React.useState(initialValue);

  const title =  React.useMemo(() => {
    switch (operation) {
      case WorkloadConfigurationOperation.ADD:
        return `Add Workload Configuration`;
      case WorkloadConfigurationOperation.DELETE_KEYS:
        return `Delete All Workload Configuration`;
      case WorkloadConfigurationOperation.DELETE_BY_KEY:
        return `Delete Workload Configuration`;
      case WorkloadConfigurationOperation.EDIT:
        return `Update Workload Configuration`;
      default:
        return `Workload Configuration`;
    }
  }, [operation]);

  const continueButtonLabel =  React.useMemo(() => {
    switch (operation) {
      case WorkloadConfigurationOperation.ADD:
        return "Add";
      case WorkloadConfigurationOperation.DELETE_KEYS:
        return "Delete All";
      case WorkloadConfigurationOperation.DELETE_BY_KEY:
        return "Delete";
      case WorkloadConfigurationOperation.EDIT:
        return "Update";
      default:
        return "Continue";
    }
  }, [operation]);

  const isDeleteAll = React.useMemo(() => WorkloadConfigurationOperation.DELETE_KEYS === operation, [operation]);
  const isDelete = React.useMemo(() => WorkloadConfigurationOperation.DELETE_BY_KEY === operation, [operation]);
  const isAdd = React.useMemo(() => WorkloadConfigurationOperation.ADD === operation, [operation]);
  const isEdit = React.useMemo(() => WorkloadConfigurationOperation.EDIT === operation, [operation]);

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const dialog = (
    <div className={classnames("workloadOperation", classes.container)}>
      {(isDelete || isDeleteAll) && (
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Are you sure you want to delete this workload configuration?
          </label>
          <label className={classnames("subtitle", classes.subtitle)}>
            <span className={classes.warning}>WARNING:</span>This action cannot be undone!
          </label>
          {isDelete && (
            <label className={classnames("keyValuePair", classes.keyValuePair)}>
              {key}:{value}
            </label>
          )}
        </React.Fragment>
      )}
      {(isAdd || isEdit) && (
        <React.Fragment>
          {isAdd && (
            <label className={classnames("title", classes.title)}>
              Add Configuration Information
            </label>
          )}
          {isEdit && (
            <label className={classnames("title", classes.title)}>
              Update Configuration Information
            </label>
          )}
          <div className={classnames(`configurationTextFields`, classes.configurationTextFields)}>
            <ConfigurationTextField
              className={classnames(`configurationKey`, classes.configurationKey)}
              autoComplete="off"
              name={`ConfigurationKey`}
              label="Key"
              value={key}
              disabled={isEdit}
              variant="outlined"
              disableSpecialChars={true}
              InputLabelProps={inputLabelProps}
              onChange={formEventHandler(setKey)}
            />
            <ConfigurationTextField
              className={classnames(`configurationValue`, classes.configurationValue)}
              autoComplete="off"
              name={`configurationValue`}
              label="Value"
              value={value}
              variant="outlined"
              InputLabelProps={inputLabelProps}
              onChange={formEventHandler(setValue)}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );

  if (!isEmptyString(name)) {
    const [model, actions] = useWorkloadConfigurationManagement({name, version, operation, key, value, keys});

    const {showLoadingIndicator, ...otherModel} = model;
    const {performOperation, ...otherActions} = actions;

    const continueButtonDisabled = React.useMemo(() =>
      operation !== WorkloadConfigurationOperations.DELETE_KEYS ?
      isEmptyString(key) || isEmptyString(value) || hasSpecialChars(key) : false,
      [operation, key, value]);

    const confirm = React.useCallback(() => {
      performOperation();
    }, [performOperation]);

    return (
      <PortalModuleDialog
        {...otherProps}
        {...otherModel}
        {...otherActions}
        className={classnames(`workloadConfigurationOperationsDialog`, dialogClassName)}
        title={title}
        loading={showLoadingIndicator}
        continueButtonLabel={continueButtonLabel}
        continueButtonDisabled={continueButtonDisabled}
        saveOnEnterPressed={true}
        confirm={confirm}
        cancel={cancel}
      >
        {dialog}
      </PortalModuleDialog>
    );
  }

  return null;
});

export default WorkloadKeyValueOperationDialog;
