import AppSchema from "@AppSchema";
import { connect } from "react-redux";
import { showPreviousStep } from "../actions/deviceTypeWizard";
import { isFirstStepActive } from "../selectors/deviceTypeWizard";
import WizardBackButton, {
  WizardBackButtonActions as Actions,
  WizardBackButtonModel as Model,
} from "@components/back-button/WizardBackButton";

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  className: "deviceTypeWizardBackButton",
  firstStepSelected: isFirstStepActive(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  showPreviousStep: () => dispatch(showPreviousStep()),
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(WizardBackButton);
