import React from "react";
import classnames from "classnames";
import {
  equalsIgnoreCase,
  isCertificateAuthoritiesModulesEnabled,
  isCommonServicesModulesDisabled,
  isDataWorkloadsModuleEnabled,
  isDeviceManagementEnabled,
  isDigitalShadowEnabled,
  isQualityReportsEnabled,
  isCostReportsEnabled,
  isReleaseNotesEnabled,
} from "@util";
import { QualityReportPlatformDomainId } from "@data/QualityReport";
import ReleaseNote, { ReleaseNotePlatformDomain } from "@data/ReleaseNote";
import ReleaseNotesIconButton from "@components/release-notes-icon-button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import CostReportsIconButton from "@components/cost-reports-icon-button";
import QualityReportsIconButton from "@components/quality-reports-icon-button";
import { PrimaryIcon as AuditEventsIcon } from "@modules/auditEvents/components/styles";
import { MODULE_PATH as AUDIT_EVENTS_MODULE_PATH } from "@modules/auditEvents/constants";
import {
  DASHBOARDS_MODULE_ENABLED,
  DashboardsIcon,
  QueryDataIcon,
  QuerySchedulesIcon,
  UserDatabaseIcon,
} from "@modules/portlet/module";
import {
  DASHBOARDS_MODULE_PATH,
  QUERY_DATA_MODULE_PATH,
  QUERY_SCHEDULES_MODULE_PATH,
  USER_DATABASE_MODULE_PATH,
} from "@modules/portlet/constants";
import { PrimaryIcon as DashboardAccessControlIcon } from "@modules/dashboards/components/styles";
import { MODULE_PATH as DASHBOARD_ACCESS_CONTROL_MODULE_PATH } from "@modules/dashboards/constants";
import { PrimaryIcon as DataSetsIcon } from "@modules/dataSets/components/styles";
import { MODULE_PATH as DATA_SETS_MODULE_PATH } from "@modules/dataSets/constants";
import {
  PrimaryIcon as DataApprovalRequestsIcon,
} from "@modules/dataApprovalRequests/components/styles";
import {
  MODULE_PATH as DATA_APPROVAL_REQUESTS_MODULE_PATH,
} from "@modules/dataApprovalRequests/constants";
import { PrimaryIcon as DataSetRequestsIcon } from "@modules/dataSetRequests/components/styles";
import { MODULE_PATH as DATA_SET_REQUESTS_MODULE_PATH } from "@modules/dataSetRequests/constants";
import {
  PrimaryIcon as DataAccessRequestsIcon,
} from "@modules/dataAccessRequests/components/styles";
import {
  MODULE_PATH as DATA_ACCESS_REQUESTS_MODULE_PATH,
} from "@modules/dataAccessRequests/constants";
import { PrimaryIcon as NamespacesIcon } from "@modules/namespaces/components/styles";
import { MODULE_PATH as NAMESPACES_MODULE_PATH } from "@modules/namespaces/constants";
import { PrimaryIcon as SchemasIcon } from "@modules/schemas/components/styles";
import { MODULE_PATH as SCHEMAS_MODULE_PATH } from "@modules/schemas/constants";
import { PrimaryIcon as DeviceTypesIcon } from "@modules/deviceTypes/components/styles";
import { MODULE_PATH as DEVICE_TYPES_MODULE_PATH } from "@modules/deviceTypes/constants";
import { PrimaryIcon as DevicesIcon } from "@modules/devices/components/styles";
import { MODULE_PATH as DEVICES_MODULE_PATH } from "@modules/devices/constants";
import { PrimaryIcon as DeviceEnrollmentIcon } from "@modules/deviceEnrollment/components/styles";
import { MODULE_PATH as DEVICE_ENROLLMENT_MODULE_PATH } from "@modules/deviceEnrollment/constants";
import { PrimaryIcon as PoliciesIcon } from "@modules/policies/components/styles";
import { MODULE_PATH as POLICIES_MODULE_PATH } from "@modules/policies/constants";
import { PrimaryIcon as ManagedPoliciesIcon } from "@modules/managedPolicies/components/styles";
import { MODULE_PATH as MANAGED_POLICIES_MODULE_PATH } from "@modules/managedPolicies/constants";
import { PrimaryIcon as SecurityGroupsIcon } from "@modules/securityGroups/components/styles";
import { MODULE_PATH as SECURITY_GROUPS_MODULE_PATH } from "@modules/securityGroups/constants";
import { PrimaryIcon as ServicesRegionalIcon } from "@modules/securityServicesRegional/styles";
import {
  MODULE_PATH as SERVICES_REGIONAL_MODULE_PATH,
} from "@modules/securityServicesRegional/constants";
import { PrimaryIcon as UsersIcon } from "@modules/users/components/styles";
import { MODULE_PATH as USERS_MODULE_PATH } from "@modules/users/constants";
import { PrimaryIcon as EmailTemplatesIcon } from "@modules/emailTemplates/components/styles";
import { MODULE_PATH as EMAIL_TEMPLATES_MODULE_PATH } from "@modules/emailTemplates/constants";
import { PrimaryIcon as HostedUiManagementIcon } from "@modules/uiPackage/components/styles";
import { MODULE_PATH as HOSTED_UI_MANAGEMENT_MODULE_PATH } from "@modules/uiPackage/constants";
import {
  PrimaryIcon as ProfileAttributesIcon,
} from "@modules/profileAttributeDefinitions/components/styles";
import {
  MODULE_PATH as PROFILE_ATTRIBUTES_MODULE_PATH,
} from "@modules/profileAttributeDefinitions/constants";
import {
  PrimaryIcon as FederationProvidersIcon,
} from "@modules/federationProviders/components/styles";
import {
  MODULE_PATH as FEDERATION_PROVIDERS_MODULE_PATH,
} from "@modules/federationProviders/constants";
import { PrimaryIcon as ApplicationsRegionalIcon } from "@modules/applicationsRegional/styles";
import {
  MODULE_PATH as APPLICATIONS_REGIONAL_MODULE_PATH,
} from "@modules/applicationsRegional/constants";
import {
  PrimaryIcon as CertificateAuthoritiesIcon,
} from "@modules/certificateAuthorities/components/styles";
import {
  MODULE_PATH as CERTIFICATE_AUTHORITIES_MODULE_PATH,
} from "@modules/certificateAuthorities/constants";
import {
  PrimaryIcon as TrainingCertificateIcon,
} from "@modules/trainingCertificate/components/styles";
import {
  MODULE_PATH as TRAINING_CERTIFICATE_MODULE_PATH,
} from "@modules/trainingCertificate/constants";
import { PrimaryIcon as DataLakeBrowserIcon } from "@modules/dataLakeBrowser/styles";
import { MODULE_PATH as DATA_LAKE_BROWSER_MODULE_PATH } from "@modules/dataLakeBrowser/constants";
import { PrimaryIcon as DataWorkloadsIcon } from "@modules/dataWorkloads/styles";
import { MODULE_PATH as DATA_WORKLOADS_MODULE_PATH } from "@modules/dataWorkloads/constants";
import { PrimaryIcon as DigitalShadowTypesIcon } from "@modules/digitalShadowTypes/styles";
import { MODULE_PATH as DS_TYPES_MODULE_PATH } from "@modules/digitalShadowTypes/constants";
import { PrimaryIcon as DigitalShadowLayoutsIcon } from "@modules/digitalShadowLayouts/styles";
import { MODULE_PATH as DS_LAYOUTS_MODULE_PATH } from "@modules/digitalShadowLayouts/constants";
import WelcomePageDomain from "../containers/WelcomePageDomain";
import WhatsNew from "./WhatsNew";
import styles from "./styles";

const CERTIFICATE_AUTHORITIES_MODULES_DISABLED =
  !isCertificateAuthoritiesModulesEnabled();
const DATA_ANALYTICS_MODULES_DISABLED =
  !equalsIgnoreCase("true", process.env.REACT_APP_DATA_ANALYTICS_MODULES_ENABLED);
const DATA_LAKE_BROWSER_MODULE_DISABLED =
  !equalsIgnoreCase("true", process.env.REACT_APP_DATA_LAKE_BROWSER_MODULE_ENABLED);
const DATA_WORKLOADS_MODULE_DISABLED = !isDataWorkloadsModuleEnabled();
const DIGITAL_SHADOW_MODULE_DISABLED = !isDigitalShadowEnabled();
const WHATS_NEW_DISABLED = !isReleaseNotesEnabled();
const COST_REPORTS_ENABLED = isCostReportsEnabled();
const QUALITY_REPORTS_ENABLED = isQualityReportsEnabled();
const COMMON_SERVICES_MODULES_DISABLED = isCommonServicesModulesDisabled();
const DEVICE_MANAGEMENT_MODULES_ENABLED = isDeviceManagementEnabled();

export interface WelcomePageModel {
  showDataAndAnalytics?: boolean;
  showDashboardsLink?: boolean;
  showDeviceEnrollmentLink?: boolean;
  showCertificateAuthorityLink?: boolean;
  showDevicesLink?: boolean;
  showDataLake?: boolean;
  showDataWorkloads?: boolean;
  showDigitalShadow?: boolean;
  showApplicationsRegionalModule?: boolean;
  showSecurityServicesRegionalModule?: boolean;
  showWhatsNew?: boolean;
  whatsNewClassName?: string;
  whatsNewTitle?: string;
  whatsNewReleaseNotes?: ReleaseNote[];
  whatsNewErrorMessage?: string;
  whatsNewShowLoadingIndicator?: boolean;
  showSecurityServicesQualityReportsButton?: boolean;
  showCommonServices?: boolean;
  showDeviceManagement?: boolean;
  showCostReports?: boolean;
}

export interface WelcomePageActions {
  whatsNewRefresh?: () => void;
}

type Model = WelcomePageModel;
type Actions = WelcomePageActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const WelcomePage = withStyles(styles)((props: Props) => {

  const {
    classes,
    showDataAndAnalytics = !DATA_ANALYTICS_MODULES_DISABLED,
    showDashboardsLink = DASHBOARDS_MODULE_ENABLED,
    showDeviceEnrollmentLink = DEVICE_MANAGEMENT_MODULES_ENABLED,
    showCertificateAuthorityLink = !CERTIFICATE_AUTHORITIES_MODULES_DISABLED,
    showDevicesLink = DEVICE_MANAGEMENT_MODULES_ENABLED,
    showDataLake = !DATA_LAKE_BROWSER_MODULE_DISABLED,
    showDataWorkloads = !DATA_WORKLOADS_MODULE_DISABLED,
    showDigitalShadow = !DIGITAL_SHADOW_MODULE_DISABLED,
    showWhatsNew = !WHATS_NEW_DISABLED,
    showSecurityServicesQualityReportsButton = QUALITY_REPORTS_ENABLED,
    showCommonServices = !COMMON_SERVICES_MODULES_DISABLED,
    showDeviceManagement = DEVICE_MANAGEMENT_MODULES_ENABLED,
    showCostReports = COST_REPORTS_ENABLED,
    whatsNewClassName,
    whatsNewTitle,
    whatsNewReleaseNotes,
    whatsNewErrorMessage,
    whatsNewShowLoadingIndicator,
    whatsNewRefresh,
    children,
  } = props;

  const dataReporting = React.useMemo(() => !showDashboardsLink ? null : (
    <WelcomePageDomain
      className={classnames("dataReporting", classes.dataReporting)}
      title={"Data Reporting"}
      subheader={true}
      links={[
        {
          className: "dashboards",
          path: DASHBOARDS_MODULE_PATH,
          label: "Dashboards",
          icon: DashboardsIcon,
        },
        {
          className: "dashboardAccessControl",
          path: DASHBOARD_ACCESS_CONTROL_MODULE_PATH,
          label: "Dashboard Access",
          icon: DashboardAccessControlIcon,
        },
      ]}
    />
  ), [classes, showDashboardsLink]);

  const dataWarehouse = React.useMemo(() => (
    <WelcomePageDomain
      className={classnames("dataWarehouse", classes.dataWarehouse)}
      title={"Data Warehouse"}
      subheader={true}
      links={[
        {
          className: "myDatabase",
          path: USER_DATABASE_MODULE_PATH,
          label: "My Database",
          icon: UserDatabaseIcon,
        },
        {
          className: "queryData",
          path: QUERY_DATA_MODULE_PATH,
          label: "Query Data",
          icon: QueryDataIcon,
        },
        {
          className: "querySchedules",
          path: QUERY_SCHEDULES_MODULE_PATH,
          label: "Query Schedules",
          icon: QuerySchedulesIcon,
        },
      ]}
    />
  ), [classes]);

  const dataCatalog = React.useMemo(() => (
    <WelcomePageDomain
      className={classnames("dataCatalog", classes.dataCatalog)}
      title={"Data Catalog"}
      subheader={true}
      links={[
        {
          className: "dataSets",
          path: DATA_SETS_MODULE_PATH,
          label: "Data Sets",
          icon: DataSetsIcon,
        },
      ]}
    />
  ), [classes]);

  const dataPrivacy = React.useMemo(() => (
    <WelcomePageDomain
      className={classnames("dataPrivacy", classes.dataCatalog)}
      title={"Data Privacy"}
      subheader={true}
      links={[
        {
          className: "trainingCertificate",
          path: TRAINING_CERTIFICATE_MODULE_PATH,
          label: "Training Certificate",
          icon: TrainingCertificateIcon,
        },
      ]}
    />
  ), [classes]);

  const dataGovernance = React.useMemo(() => (
    <WelcomePageDomain
      className={classnames("dataGovernance", classes.dataGovernance)}
      title={"Data Governance"}
      subheader={true}
      links={[
        {
          className: "dataSetRequests",
          path: DATA_SET_REQUESTS_MODULE_PATH,
          label: "Data Set Requests",
          icon: DataSetRequestsIcon,
        },
        {
          className: "dataAccessRequests",
          path: DATA_ACCESS_REQUESTS_MODULE_PATH,
          label: "Data Access Requests",
          icon: DataAccessRequestsIcon,
        },
        {
          className: "dataApprovalRequests",
          path: DATA_APPROVAL_REQUESTS_MODULE_PATH,
          label: "My Data Approvals",
          icon: DataApprovalRequestsIcon,
        },
      ]}
    />
  ), [classes]);

  const dataLake = React.useMemo(() => !showDataLake ? null : (
    <WelcomePageDomain
      className={classnames("dataLake", classes.dataLake)}
      title={"Data Lake"}
      subheader={true}
      links={[
        {
          className: "dataLakeBrowser",
          path: DATA_LAKE_BROWSER_MODULE_PATH,
          label: "Browser",
          icon: DataLakeBrowserIcon,
        },
      ]}
    />
  ), [classes, showDataLake]);

  const dataProcessing = React.useMemo(() => !showDataWorkloads ? null : (
    <WelcomePageDomain
      className={classnames("dataProcessing")}
      title={"Data Processing"}
      subheader={true}
      links={[
        {
          className: "dataWorkloads",
          path: DATA_WORKLOADS_MODULE_PATH,
          label: "Workloads",
          icon: DataWorkloadsIcon,
        },
      ]}
    />
  ), [classes, showDataWorkloads]);

  const dataAndAnalytics = React.useMemo(() => !showDataAndAnalytics ? null : (
    <div className={classnames("dataAndAnalytics", classes.domain, classes.dataAndAnalytics)}>
      <div className={classnames("header", classes.header)}>
        <label className={classnames("title", classes.title)}>
          Data & Analytics
        </label>
        <ReleaseNotesIconButton
          className={classnames("dataAndAnalyticsReleaseNotesButton", classes.releaseNotesIconButton)}
          platformDomain={ReleaseNotePlatformDomain.DATA_AND_ANALYTICS}
          tooltip="Data & Analytics Release Notes"
        />
      </div>
      <div className={classnames("content", classes.content)}>
        <div className={classnames("dataAndAnalyticsContent", classes.dataAndAnalyticsContent)}>
          <div className={classnames("dataAndAnalyticsFirstRow", classes.dataAndAnalyticsFirstRow)}>
            <div className={classnames("dataAndAnalyticsLeftColumn", classes.dataAndAnalyticsLeftColumn)}>
              {dataWarehouse}
              {dataCatalog}
              {dataPrivacy}
              {dataProcessing}
            </div>
            <div className={classnames("dataAndAnalyticsRightColumn", classes.dataAndAnalyticsRightColumn)}>
              {dataGovernance}
              {dataReporting}
              {dataLake}
            </div>
          </div>
        </div>
      </div>
    </div>
  ), [
    classes,
    showDataAndAnalytics,
    dataWarehouse,
    dataCatalog,
    dataPrivacy,
    dataGovernance,
    dataReporting,
    dataLake,
  ]);

  const commonServices = React.useMemo(() => !showCommonServices ? null : (
    <WelcomePageDomain
      className={classnames("commonServices", classes.commonServices)}
      title="Common Services"
      releaseNotesPlatformDomain={ReleaseNotePlatformDomain.COMMON_SERVICES}
      releaseNotesClassName={classnames("commonServicesReleaseNotesButton", classes.releaseNotesIconButton)}
      releaseNotesTooltip="Common Services Release Notes"
      links={[
        {
          className: "namespaces",
          path: NAMESPACES_MODULE_PATH,
          label: "Namespaces",
          icon: NamespacesIcon,
        },
        {
          className: "schemas",
          path: SCHEMAS_MODULE_PATH,
          label: "Schemas",
          icon: SchemasIcon,
        },
      ]}
    />
  ), [classes, showCommonServices]);

  const deviceManagementWithoutDigitalShadow = React.useMemo(() => (
    <WelcomePageDomain
      className={classnames("deviceManagement", classes.deviceManagement)}
      title="Device Management"
      releaseNotesPlatformDomain={ReleaseNotePlatformDomain.DEVICE_MANAGEMENT}
      releaseNotesClassName={classnames("deviceManagementReleaseNotesButton", classes.releaseNotesIconButton)}
      releaseNotesTooltip="Device Management Release Notes"
      links={[
        {
          className: "deviceTypes",
          path: DEVICE_TYPES_MODULE_PATH,
          label: "Device Types",
          icon: DeviceTypesIcon,
        },
      ].concat(!showDeviceEnrollmentLink ? [] : ([
        {
          className: "deviceEnrollment",
          path: DEVICE_ENROLLMENT_MODULE_PATH,
          label: "Device Enrollment",
          icon: DeviceEnrollmentIcon,
        },
      ])).concat(!showDevicesLink ? [] : ([
        {
          className: "devices",
          path: DEVICES_MODULE_PATH,
          label: "Devices",
          icon: DevicesIcon,
        },
      ]))}
    />
  ), [classes, showDeviceEnrollmentLink, showDevicesLink]);

  const device = React.useMemo(() => (
    <WelcomePageDomain
      className={classnames("device", classes.device)}
      title="Device"
      subheader={true}
      links={[
        {
          className: "deviceTypes",
          path: DEVICE_TYPES_MODULE_PATH,
          label: "Device Types",
          icon: DeviceTypesIcon,
        },
      ].concat(!showDeviceEnrollmentLink ? [] : ([
        {
          className: "deviceEnrollment",
          path: DEVICE_ENROLLMENT_MODULE_PATH,
          label: "Device Enrollment",
          icon: DeviceEnrollmentIcon,
        },
      ])).concat(!showDevicesLink ? [] : ([
        {
          className: "devices",
          path: DEVICES_MODULE_PATH,
          label: "Devices",
          icon: DevicesIcon,
        },
      ]))}
    />
  ), [classes, showDeviceEnrollmentLink, showDevicesLink]);

  const digitalShadow = React.useMemo(() => (
    <WelcomePageDomain
      className={classnames("digitalShadow", classes.digitalShadow)}
      title={"Digital Shadow"}
      subheader={true}
      links={[
        {
          className: "dsTypes",
          path:  DS_TYPES_MODULE_PATH,
          label: "Shadow Types",
          icon: DigitalShadowTypesIcon,
        },
        {
          className: "dsLayouts",
          path: DS_LAYOUTS_MODULE_PATH,
          label: "Shadow Layouts",
          icon: DigitalShadowLayoutsIcon,
        },
      ]}
    />
  ), [classes]);

  const deviceManagementWithDigitalShadow = React.useMemo(() => (
    <div className={classnames("deviceManagement", classes.domain, classes.deviceManagement)}>
      <div className={classnames("header", classes.header)}>
        <label className={classnames("title", classes.title)}>
          Device Management
        </label>
        <ReleaseNotesIconButton
          className={classnames("deviceManagementReleaseNotesButton", classes.releaseNotesIconButton)}
          platformDomain={ReleaseNotePlatformDomain.DEVICE_MANAGEMENT}
          tooltip="Device Management Release Notes"
        />
      </div>
      <div className={classnames("content", classes.content)}>
        <div className={classnames("deviceManagementContent", classes.deviceManagementContent)}>
          <div className={classnames("deviceManagementLeftColumn", classes.deviceManagementLeftColumn)}>
            {device}
          </div>
          <div className={classnames("deviceManagementRightColumn", classes.deviceManagementRightColumn)}>
            {digitalShadow}
          </div>
        </div>
      </div>
    </div>
  ), [
    classes,
    device,
    digitalShadow
  ]);

  const deviceManagement = React.useMemo(() =>
      !showDeviceManagement
        ? null
        : (showDigitalShadow ? deviceManagementWithDigitalShadow : deviceManagementWithoutDigitalShadow),
    [
      showDeviceManagement,
      showDigitalShadow,
      deviceManagementWithDigitalShadow,
      deviceManagementWithoutDigitalShadow,
    ]);

  const authorization = React.useMemo(() => (
    <WelcomePageDomain
      className={classnames("authorization", classes.authorization)}
      title={"Authorization"}
      subheader={true}
      links={[
        {
          className: "policies",
          path: POLICIES_MODULE_PATH,
          label: "Policies",
          icon: PoliciesIcon,
        },
        {
          className: "securityGroups",
          path: SECURITY_GROUPS_MODULE_PATH,
          label: "Security Groups",
          icon: SecurityGroupsIcon,
        },
        {
          className: "managedPolicies",
          path: MANAGED_POLICIES_MODULE_PATH,
          label: "Managed Policies",
          icon: ManagedPoliciesIcon,
        },
        ]}
    />
  ), [classes]);

  const serviceIdentityManagement = React.useMemo(() => (
    <WelcomePageDomain
      className={classnames("serviceIdentityManagement", classes.serviceIdentityManagement)}
      title={"Service Identity Management"}
      subheader={true}
      links={[
        {
          className: "services",
          path:  SERVICES_REGIONAL_MODULE_PATH,
          label: "Services",
          icon: ServicesRegionalIcon,
        },
      ]}
    />
  ), [classes]);

  const userIdentityManagement = React.useMemo(() => (
    <WelcomePageDomain
      className={classnames("userIdentityManagement", classes.userIdentityManagement)}
      title={"User Identity Management"}
      subheader={true}
      links={[
        {
          className: "users",
          path: USERS_MODULE_PATH,
          label: "Users",
          icon: UsersIcon,
        },
        {
          className: "emailTemplates",
          path: EMAIL_TEMPLATES_MODULE_PATH,
          label: "Email Templates",
          icon: EmailTemplatesIcon,
        },
        {
          className: "hostedUiManagement",
          path: HOSTED_UI_MANAGEMENT_MODULE_PATH,
          label: "Hosted UI Management",
          icon: HostedUiManagementIcon,
        },
        {
          className: "profileAttributes",
          path: PROFILE_ATTRIBUTES_MODULE_PATH,
          label: "Profile Attributes",
          icon: ProfileAttributesIcon,
        },
        {
          className: "federationProviders",
          path: FEDERATION_PROVIDERS_MODULE_PATH,
          label: "Federation Providers",
          icon: FederationProvidersIcon,
        },
      ]}
    />
  ), [classes]);

  const audit = React.useMemo(() => (
    <WelcomePageDomain
      className={classnames("audit", classes.audit)}
      title={"Audit"}
      subheader={true}
      links={[
        {
          className: "auditEvents",
          path: AUDIT_EVENTS_MODULE_PATH,
          label: "Audit Events",
          icon: AuditEventsIcon,
        },
      ]}
    />
  ), [classes]);

  const certificates = React.useMemo(() => (
    <WelcomePageDomain
      className={classnames("certificates", classes.certificates)}
      title={"Certificates"}
      subheader={true}
      links={[
        {
          className: "authorities",
          path: CERTIFICATE_AUTHORITIES_MODULE_PATH,
          label: "Authorities",
          icon: CertificateAuthoritiesIcon,
        },
      ]}
    />
  ), [classes]);

  const applications = React.useMemo(() => (
    <WelcomePageDomain
      className={classnames("applicationIdentityManagement", classes.applicationIdentityManagement)}
      title={"Application Identity Management"}
      subheader={true}
      links={[
        {
          className: "applications",
          path: APPLICATIONS_REGIONAL_MODULE_PATH,
          label: "Applications",
          icon: ApplicationsRegionalIcon,
        },
      ]}
    />
  ), [classes]);

  const securityServices = React.useMemo(() => (
    <div className={classnames("securityServices", classes.domain, classes.securityServices)}>
      <div className={classnames("header", classes.header)}>
        <label className={classnames("title", classes.title)}>
          Security Services
        </label>
        <ReleaseNotesIconButton
          className={classnames("securityServicesReleaseNotesButton", classes.releaseNotesIconButton)}
          platformDomain={ReleaseNotePlatformDomain.SECURITY_SERVICES}
          tooltip="Security Services Release Notes"
        />
        {showSecurityServicesQualityReportsButton && (
          <QualityReportsIconButton
            className={classnames("securityServicesQualityReportsButton", classes.qualityReportsIconButton)}
            platformDomain={QualityReportPlatformDomainId.SECURITY_SERVICES}
            tooltip="Security Services Quality Reports"
          />
        )}
        {showCostReports && (
          <CostReportsIconButton
            tooltip="Security Services Cost Reports"
            className={classnames("securityServicesCostReportsButton", classes.costReportsIconButton)}
          />
        )}
      </div>
      <div className={classnames("content", classes.content)}>
        <div className={classnames("securityServicesContent", classes.securityServicesContent)}>
          <div className={classnames("securityServicesLeftColumn", classes.securityServicesLeftColumn)}>
            {authorization}
            {serviceIdentityManagement}
            {audit}
          </div>
          <div className={classnames("securityServicesRightColumn", classes.securityServicesRightColumn)}>
            {userIdentityManagement}
            {applications}
            {showCertificateAuthorityLink && certificates}
          </div>
        </div>
      </div>
    </div>
  ), [
    classes,
    authorization,
    serviceIdentityManagement,
    userIdentityManagement,
    audit,
    applications,
    showCostReports,
    showSecurityServicesQualityReportsButton,
  ]);

  const showLeftColumn = React.useMemo(() =>
    showDataAndAnalytics || showCommonServices || showDeviceManagement,
  [showDataAndAnalytics, showCommonServices, showDeviceManagement]);

  const leftColumn = React.useMemo(() => !showLeftColumn ? null : (
    <div className={classnames("leftColumn", classes.column, classes.leftColumn)}>
      {showDataAndAnalytics ? dataAndAnalytics : null}
      {commonServices}
      {deviceManagement}
    </div>
  ), [
    classes,
    showLeftColumn,
    showDataAndAnalytics,
    dataAndAnalytics,
    commonServices,
    deviceManagement,
  ]);

  const whatsNew = React.useMemo(() => !showWhatsNew ? null : (
    <WhatsNew
      className={classnames("whatsNew", whatsNewClassName, classes.whatsNew)}
      title={whatsNewTitle}
      releaseNotes={whatsNewReleaseNotes}
      errorMessage={whatsNewErrorMessage}
      showLoadingIndicator={whatsNewShowLoadingIndicator}
      refresh={whatsNewRefresh}
    />
  ), [
    classes,
    showWhatsNew,
    whatsNewClassName,
    whatsNewTitle,
    whatsNewReleaseNotes,
    whatsNewErrorMessage,
    whatsNewShowLoadingIndicator,
    whatsNewRefresh,
  ]);

  const rightColumn = React.useMemo(() => {
    return (
      <div
        className={classnames("rightColumn", classes.column, classes.rightColumn, {
          [classes.fullWidth]: !showLeftColumn,
        })}
      >
        {whatsNew}
        {securityServices}
      </div>
    );
  }, [
    classes,
    showLeftColumn,
    whatsNew,
    securityServices,
  ]);

  return (
    <div className={classnames("welcomePage", classes.container)}>
      <div className={classnames("row", classes.row)}>
        {leftColumn}
        {rightColumn}
      </div>
      {children}
    </div>
  );
});

export default WelcomePage;
