import { combineReducers } from "redux";
import { Actions, filterAction, Identity } from "@util";
import { DashboardAttributes } from "@data";
import { DashboardsTabType } from "./components/DashboardsTabs";

export enum DashboardColumn {
  NAME = "Name",
}

export interface DashboardsSchema {
  selectedTab: string;
  items: DashboardAttributes[];
  itemsRequestedTime: number;
  searchQuery: string;
  sortedColumn: DashboardColumn;
  sortOrderAscending: boolean;
  errorMessage: string;
  showEmptyView: boolean;
  showAccessDenied: boolean;
  showLoadingIndicator: boolean;
}

export const DEFAULT_STATE: DashboardsSchema = Object.freeze({
  selectedTab: DashboardsTabType.PUBLISHED,
  items: [],
  itemsRequestedTime: 0,
  searchQuery: "",
  sortedColumn: DashboardColumn.NAME,
  sortOrderAscending: true,
  errorMessage: "",
  showEmptyView: true,
  showAccessDenied: false,
  showLoadingIndicator: false,
});

export enum DashboardsActionType {
  SET_SELECTED_TAB = "dashboards/set_selected_tab",
  SET_ITEMS = "dashboards/set_dashboards",
  SET_SEARCH_QUERY = "dashboards/set_search_query",
  SET_SORTED_COLUMN = "dashboards/set_sorted_column",
  SET_SORT_ORDER_ASCENDING = "dashboards/set_sort_order_ascending",
  SET_ERROR_MESSAGE = "dashboards/set_error_message",
  SET_SHOW_EMPTY_VIEW = "dashboards/set_show_empty_view",
  SET_SHOW_ACCESS_DENIED = "dashboards/set_show_access_denied",
  SET_SHOW_LOADING_INDICATOR = "dashboards/set_show_loading_indicator",
  FETCH_ALL_DASHBOARDS_REQUEST = "dashboards/fetch_all_dashboards_request",
  FETCH_ALL_DASHBOARDS_SUCCESS = "dashboards/fetch_all_dashboards_success",
  FETCH_ALL_DASHBOARDS_FAILED = "dashboards/fetch_all_dashboards_failed",
}

const selectedTab = filterAction<string>(Actions(
  DashboardsActionType.SET_SELECTED_TAB))(Identity, DEFAULT_STATE.selectedTab);

const items = filterAction<DashboardAttributes[]>(Actions(
    DashboardsActionType.SET_ITEMS))(Identity,
  DEFAULT_STATE.items);

const itemsRequestedTime = filterAction<number>(Actions(
    DashboardsActionType.FETCH_ALL_DASHBOARDS_REQUEST))(Identity,
    DEFAULT_STATE.itemsRequestedTime);

const searchQuery = filterAction<string>(Actions(
    DashboardsActionType.SET_SEARCH_QUERY))(Identity,
  DEFAULT_STATE.searchQuery);

const sortedColumn = filterAction<DashboardColumn>(Actions(
    DashboardsActionType.SET_SORTED_COLUMN))(Identity,
  DEFAULT_STATE.sortedColumn);

const sortOrderAscending = filterAction<boolean>(Actions(
    DashboardsActionType.SET_SORT_ORDER_ASCENDING))(Identity,
  DEFAULT_STATE.sortOrderAscending);

const errorMessage = filterAction<string>(Actions(
    DashboardsActionType.SET_ERROR_MESSAGE))(Identity,
  DEFAULT_STATE.errorMessage);

const showEmptyView = filterAction<boolean>(Actions(
    DashboardsActionType.SET_SHOW_EMPTY_VIEW))(Identity,
  DEFAULT_STATE.showEmptyView);

const showAccessDenied = filterAction<boolean>(Actions(
    DashboardsActionType.SET_SHOW_ACCESS_DENIED))(Identity,
  DEFAULT_STATE.showAccessDenied);

const showLoadingIndicator = filterAction<boolean>(Actions(
    DashboardsActionType.SET_SHOW_LOADING_INDICATOR))(Identity,
  DEFAULT_STATE.showLoadingIndicator);

export const DashboardsReducer = combineReducers<DashboardsSchema>({
  selectedTab,
  searchQuery,
  sortedColumn,
  sortOrderAscending,
  items,
  itemsRequestedTime,
  errorMessage,
  showEmptyView,
  showAccessDenied,
  showLoadingIndicator,
});

export default DashboardsReducer;
