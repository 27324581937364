import React from "react";
import classnames from "classnames";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { DeviceTypeInfoLabel } from "./DeviceTypeInfoLabel";
import { deviceTypeInfo as styles } from "./styles";
import { DeviceTypeListItem } from "@data";

export const DeviceTypeInfo = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  deviceType: DeviceTypeListItem,
  value?: string,
}) => {

  const {
    classes,
    className = "deviceTypeInfo",
    deviceType = DeviceTypeListItem.EMPTY,
  } = props;

  if (DeviceTypeListItem.EMPTY.equals(deviceType)) {
    return (
      <div className={classnames("emptyView", className, classes.container)}>
        <label className={classes.emptyViewLabel}>No Device Type Provided</label>
      </div>
    );
  }

  return (
    <div className={classnames(className, classes.container)}>
      <DeviceTypeInfoLabel className="namespace" name="Namespace" value={deviceType.getNameSpace()} />
      <DeviceTypeInfoLabel className="name" name="Name" value={deviceType.getName()} />
      <DeviceTypeInfoLabel className="version" name="Version" value={`${deviceType.getVersion()}`} />
    </div>
  );
});

export default DeviceTypeInfo;
