import createStyles from "@material-ui/core/styles/createStyles";
import { Color } from "@components";

export const styles = () =>
  createStyles({
    container: {},
    autocompleteTextField: {
    },
    autocompleteFormHelperText: {
      fontSize: 13,
    },
    autocompleteInput: {
      padding: "12px 14px",
    },
    autocompleteInputLabel: {
      transform: "translate(12px, 25px) scale(1)",
      "&$error:not($focused)": {
        transform: "translate(12px, 5px) scale(1)",
      },
      "&$focused": {
      },
    },
    autocompleteInputLabelShrink: {
      lineHeight: "1 !important",
    },
    autocompleteOption: {
      color: Color.TEXT,
      padding: "8px 12px 8px 12px",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "14px",
    },
    autocompleteTag: {
      maxWidth: 200,
      fontSize: 10,
      "& svg": {
        color: Color.WHITE,
        "&:hover, &:active, &:focus": {
          color: Color.BLACK,
        },
      },
    },
    autocompleteTextFieldInput: {
      "& .MuiAutocomplete-clearIndicator svg": {
        width: 20,
        height: 20,
        color: Color.WHITE,
      },
    },
    autocompleteTextFieldInputPropsInput: {
      lineHeight: "1 !important",
    },
    focused: {},
    error: {},
  });

export default styles;
