import { createSelectors, Selector } from "@base/createSelectors";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";
import { createSelector } from "reselect";
import { DEFAULT_STATE, ReportSteps, UserReportSchema } from "./reducers";
import { ReportFormat, ReportStatus, ReportType, UserReport, UserReportAttributes } from "@data";

export const {
  userReport: getUserReportAttributes,
  reportId: getReportId,
  step: getStep,
  generateDialog: isGenerateDialog,
  type: getType,
  format: getFormat,
  getErrorMessage,
  getSuccessMessage,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  getAccessToken,
} = createSelectors<UserReportSchema>(moduleId, schemaKey, DEFAULT_STATE);

export const getUserReport: Selector<UserReport> = createSelector(
  getUserReportAttributes, (attrs: UserReportAttributes) => new UserReport(attrs)
);

export const getUserReportId: Selector<string> = createSelector(
  getUserReport, (report: UserReport) => report.getId()
);

export const getUserReportStatus: Selector<ReportStatus> = createSelector(
  getUserReport, (report: UserReport) => report.getStatus()
);

export const getUserReportType: Selector<ReportType> = createSelector(
  getUserReport, (report: UserReport) => report.getType()
);

export const getUserReportFormat: Selector<ReportFormat> = createSelector(
  getUserReport, (report: UserReport) => report.getFormat()
);

export const getUserReportLink: Selector<string> = createSelector(
  getUserReport, (report: UserReport) => report.getLink()
);

export const getUserReportAccountId: Selector<string> = createSelector(
  getUserReport, (report: UserReport) => report.getAccountId()
);

export const isButtonActive: Selector<boolean> = createSelector(
  getStep, (step: ReportSteps) => {
    return step === ReportSteps.GENERATE || step === ReportSteps.COMPLETE;
  });

export const getButtonLabel: Selector<string> = createSelector(
  getStep, (step: ReportSteps) => {

    if (step === ReportSteps.GENERATE ) {
      return "Export User Info";
    } else if (step === ReportSteps.LOADING) {
      return "Generating Report...";
    } else {
      return "Download Report";
    }
  });
