import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base/createReducers";

export interface EditPolicySchema extends BasePortalModuleSchema {
  json: string;
  policyRef: string;
  policyName: string;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<EditPolicySchema>({
  showEmptyView: false,
  json: "",
  policyRef: "",
  policyName: "",
});

type EditPolicyActionTypes = PortalModuleActionTypes<EditPolicySchema> & {
  EDIT_POLICY_REQUEST: string;
  EDIT_POLICY_SUCCESS: string;
  EDIT_POLICY_FAILED: string;
  FETCH_POLICY_REQUEST: string;
  FETCH_POLICY_SUCCESS: string;
  FETCH_POLICY_FAILED: string;
};

export const ACTION_TYPES: EditPolicyActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<EditPolicySchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    EDIT_POLICY_REQUEST: `${MODULE_ID}/edit_policy_request`,
    EDIT_POLICY_SUCCESS: `${MODULE_ID}/edit_policy_success`,
    EDIT_POLICY_FAILED: `${MODULE_ID}/edit_policy_failed`,
    FETCH_POLICY_REQUEST: `${MODULE_ID}/fetch_policy_request`,
    FETCH_POLICY_SUCCESS: `${MODULE_ID}/fetch_policy_success`,
    FETCH_POLICY_FAILED: `${MODULE_ID}/fetch_policy_failed`,
  },
});

export const EditPolicyReducer = combineReducers<EditPolicySchema>(
  createPortalModuleReducers<EditPolicySchema>(ACTION_TYPES, DEFAULT_STATE));

export default EditPolicyReducer;
