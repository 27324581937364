import { createStyles } from "@material-ui/core/styles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    wizard: {
    },
    backdrop: {
    },
    fullHeight: {
      flex: "1 0 auto",
    },
  });

export default styles;
