import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import { DeviceDataModel, DeviceDataModelAttributes } from "@data";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@modules/base/createReducers";

export interface DeviceValidationDetailsSchema extends BasePortalModuleSchema {
  deviceId: string;
  deviceRequest: DeviceDataModelAttributes;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<DeviceValidationDetailsSchema>({
  deviceId: "",
  deviceRequest: Object.freeze({ ...DeviceDataModel.EMPTY.toJS() }),
});

type DeviceValidationDetailsActionTypes = PortalModuleActionTypes<DeviceValidationDetailsSchema> & {
  FETCH_DEVICE_VALIDATION_DETAILS_REQUEST: string;
  FETCH_DEVICE_VALIDATION_DETAILS_SUCCESS: string;
  FETCH_DEVICE_VALIDATION_DETAILS_FAILURE: string;
};

export const ACTION_TYPES: DeviceValidationDetailsActionTypes = {
  ...createPortalModuleActionTypes<DeviceValidationDetailsSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    FETCH_DEVICE_VALIDATION_DETAILS_REQUEST: `${MODULE_ID}/fetch_device_validation_details_request`,
    FETCH_DEVICE_VALIDATION_DETAILS_SUCCESS: `${MODULE_ID}/fetch_device_validation_details_success`,
    FETCH_DEVICE_VALIDATION_DETAILS_FAILURE: `${MODULE_ID}/fetch_device_validation_details_failure`,
  },
};

export const DeviceValidationDetailsReducer = combineReducers<DeviceValidationDetailsSchema>(
  createPortalModuleReducers<DeviceValidationDetailsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DeviceValidationDetailsReducer;
