import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import {
  DeviceEnrollmentWizardSelectors
} from "../../selectors";
import IdView , { Actions, Model } from "../../components/IdView";
import { AddDeviceWizardActions } from "../../actions/";

interface ContainerModel extends Model {
  hidden?: boolean;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const DeviceIdViewContainer = (props: Props) => {

  const { hidden, ...otherProps } = props;

  if (hidden) {
    return null;
  }

  return <IdView {...otherProps} />;
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => {

  const hidden = !DeviceEnrollmentWizardSelectors.isDeviceIdViewSelected(state);

  if (!DeviceEnrollmentWizardSelectors.isDeviceEnrollmentJsonValid(state)) {
    return {
      hidden,
      showInvalidJsonError: true,
      ...ownProps
    };
  }

  return {
    hidden,
    id: DeviceEnrollmentWizardSelectors.getDevice(state).getDeviceId(),
    className: "deviceIdView",
    title: "Device ID",
    label: "Device ID",
    idFieldName: "deviceId",
    idError: DeviceEnrollmentWizardSelectors.isDeviceIdValid(state)
      ? DeviceEnrollmentWizardSelectors.getErrorMessage(state) : "",
    disabled: DeviceEnrollmentWizardSelectors.isDeviceEditMode(state),
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions) => ({
  setId: (deviceId: string) => dispatch(AddDeviceWizardActions.updateDeviceId(deviceId)),
  generateRandomId: () => dispatch(AddDeviceWizardActions.updateDeviceId(uuidV4())),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps
)(DeviceIdViewContainer);
