import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useCurrentAccountId from "@hooks/use-current-account-id";
import DataWorkloadWizard from "./containers/DataWorkloadWizard";
import { CreateWorkloadRequestV3 } from "@data";
import { getPathToWorkload } from "@modules/dataWorkloadDetails/helpers";
import DataSetsListItemData from "@components/data-sets-list/models/DataSetsListItemData";

export interface CodeInfo {
  sourceName: string;
  sourceVersion: number;
  containsCode: boolean;
}

type LocationProps = {
  code?: CodeInfo;
  data?: CreateWorkloadRequestV3;
};

export function CreateWorkloadWizardView() {

  const history = useHistory();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const showWorkloadDetails = React.useCallback(id => {
    history.replace(getPathToWorkload(id));
  }, [history]);

  const showDataSetDetails = React.useCallback(
    (item: DataSetsListItemData) => history.push(item.getHref()), [history]);

  const showDataSetDetailsInNewTab = React.useCallback(
    (item: DataSetsListItemData) => item.getHref(), []);

  const {
    state: {
      code: { sourceName, sourceVersion, containsCode } = { sourceName: "", sourceVersion: 1, containsCode: false },
      data: workloadRequestAttributes = CreateWorkloadRequestV3.EMPTY.toJS(),
    } = {},
  } = useLocation<LocationProps>();

  const accountId = useCurrentAccountId();
  const [initialized, setInitialized] = React.useState(false);
  const [data, setData] = React.useState<CreateWorkloadRequestV3>(new CreateWorkloadRequestV3({ accountId }));

  const workloadRequest = React.useMemo<null | CreateWorkloadRequestV3>(() =>
      typeof workloadRequestAttributes !== "object"
        ? null
        : new CreateWorkloadRequestV3({ accountId, ...workloadRequestAttributes }),
    [workloadRequestAttributes]);

  const isCloneFlowActive = React.useMemo(() =>
      workloadRequest !== null && !CreateWorkloadRequestV3.EMPTY.equals(workloadRequest),
    [workloadRequest]);

  React.useEffect(() => {

    if (!initialized) {

      if (isCloneFlowActive && workloadRequest !== null) {
        setData(workloadRequest);
      }

      setInitialized(true);
    }

  }, [initialized, isCloneFlowActive, workloadRequest, setInitialized]);

  if (!initialized) {
    return null;
  }

  return (
    <DataWorkloadWizard
      data={data}
      cancel={cancel}
      containsCode={containsCode}
      sourceName={sourceName}
      sourceVersion={sourceVersion}
      showDataSetDetails={showDataSetDetails}
      showWorkloadDetails={showWorkloadDetails}
      showDataSetDetailsInNewTab={showDataSetDetailsInNewTab}
    />
  );
}

export default CreateWorkloadWizardView;
