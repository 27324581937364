import React from "react";
import classnames from "classnames";
import { SearchFilter } from "@data";
import Button from "@components/button";
import { clickHandler, noop } from "@util";
import AddFilterDialog from "../AddFilterDialog";
import SearchFilters from "@components/search-filters";
import AddFilterIcon from "@material-ui/icons/FilterList";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface Model {
  className?: string;
  disabled?: boolean;
  searchFilters?: SearchFilter[];
  children?: React.ReactNode;
}

export interface Actions {
  setSearchFilters?: (searchFilters: SearchFilter[]) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const ManageSearchFilters = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    disabled,
    searchFilters = [],
    setSearchFilters = noop,
    children,
  } = props;

  const [showAddFilterDialog, setShowAddFilterDialog] = React.useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState<SearchFilter>(SearchFilter.EMPTY);

  React.useEffect(() => {
    setShowAddFilterDialog(false);
    setSelectedFilter(SearchFilter.EMPTY);
  }, [searchFilters, setSelectedFilter, setShowAddFilterDialog]);

  return (
    <div className={classnames("manageSearchFilters", className, classes.container)}>
      <Button
        className={classnames("addFilterButton", classes.addFilterButton)}
        classes={{
          label: classnames("label", classes.addFilterButtonLabel),
          disabled: classnames("disabled", classes.disabledButton),
        }}
        color="primary"
        size="small"
        disableFocusRipple={true}
        disableRipple={true}
        disabled={disabled}
        onClick={clickHandler(() => {
          setSelectedFilter(SearchFilter.EMPTY);
          setShowAddFilterDialog(true);
        })}
      >
        <AddFilterIcon className={classnames("addFilterIcon", "icon", classes.addFilterIcon)} />
        Add Search Filter
      </Button>
      <SearchFilters
        className={classes.searchFilters}
        searchFilters={searchFilters}
        setSearchFilters={(filters: SearchFilter[]) => setSearchFilters(filters)}
        setSelectedSearchFilter={(filter: SearchFilter) => {
          setSelectedFilter(filter);
          setShowAddFilterDialog(true);
        }}
      />
      {showAddFilterDialog && (
        <AddFilterDialog
          open={showAddFilterDialog}
          filters={searchFilters}
          selectedFilter={selectedFilter}
          cancel={() => setShowAddFilterDialog(false)}
          addFilter={(filter: SearchFilter) => {
            setSearchFilters(searchFilters.concat(filter));
          }}
          updateFilter={(filter: SearchFilter) => {
            const previousSearchQuery = selectedFilter.getSearchQuery();
            setSearchFilters(searchFilters.map((searchFilter: SearchFilter) => {
              if (searchFilter.getSearchQuery() === previousSearchQuery) {
                return filter;
              } else {
                return searchFilter;
              }
            }));
          }}
        />
      )}
      {children}
    </div>
  );
});

export default ManageSearchFilters;
