import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { getClassName, getGroupName, getServiceId, isAddingServiceToGroup } from "../selectors";
import { updateServiceId } from "../actions";
import { SecurityServiceRegional } from "@data";
import useGetServicesRegional from "@hooks/serviceManagerRegional/use-get-services-regional";
import WaitForApiRequestView from "@components/wait-for-api-request-view";
import useSecurityGroupServices from "@hooks/securityGroups/use-security-group-services";
import useAllSecurityGroupServices from "@hooks/securityGroups/use-all-security-group-services";
import SelectServiceView, {
  SelectServiceViewActions,
  SelectServiceViewModel,
} from "../components/SelectServiceView";

interface ContainerModel extends SelectServiceViewModel {
  groupName?: string;
  serviceId?: string;
  addServiceToGroup?: boolean;
}

interface ContainerActions extends SelectServiceViewActions {
}

type Props = ContainerModel & ContainerActions;

const SelectServiceToAddToGroup = (props: Props) => {

  const [{ services: items, ...model }, actions] = useGetServicesRegional();

  return (
    <SelectServiceView
      {...props}
      {...model}
      {...actions}
      items={items}
    />
  );
};

const LoadServicesToAddToGroup = (props: Props) => {

  const {
    groupName = "",
    loadingLabel = "Loading services that can be added to this group...",
    ...otherProps
  } = props;

  const [{ services, ...model }, actions] = useAllSecurityGroupServices({ groupName });

  const { refresh: retry } = actions;

  const excludedServiceIds = React.useMemo(() =>
      services.map(service => service.getId()),
    [services]);

  return (
    <WaitForApiRequestView
      className="loadServicesToAddToGroup"
      loadingMessage={loadingLabel}
      errorTitle="Failed to load services that can be added to this group"
      retry={retry}
      {...model}
      {...actions}
    >
      <SelectServiceToAddToGroup
        {...otherProps}
        groupName={groupName}
        excludedServiceIds={excludedServiceIds}
        loadingLabel={loadingLabel}
      />
    </WaitForApiRequestView>
  );
};

const SelectServiceToRemoveFromGroup = (props: Props) => {

  const {
    groupName = "",
    loadingLabel = "Loading services that belong to this group...",
    ...otherProps
  } = props;

  const [{ services: items, ...model }, actions] = useSecurityGroupServices({ groupName });

  return (
    <SelectServiceView
      {...otherProps}
      {...model}
      {...actions}
      items={items}
      loadingLabel={loadingLabel}
      showSearch={false}
    />
  );
};

const SelectServiceViewContainer = (props: Props) => {

  const { addServiceToGroup, ...otherProps } = props;

  if (addServiceToGroup) {
    return <LoadServicesToAddToGroup {...otherProps} />;
  } else {
    return <SelectServiceToRemoveFromGroup {...otherProps} />;
  }
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  className: getClassName(state),
  serviceId: getServiceId(state),
  groupName: getGroupName(state),
  addServiceToGroup: isAddingServiceToGroup(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setSelectedService: (service: SecurityServiceRegional = SecurityServiceRegional.EMPTY) =>
    dispatch(updateServiceId(service.getId())),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(SelectServiceViewContainer);
