import { Record } from "immutable";
import { getStringValue } from "@util";

export interface DSTypeInfoMetadata {
  [key: string]: string;
}

export interface DSTypeInfoAttributes {
  name: string;
  description: string;
  metadata: DSTypeInfoMetadata;
  children?: DSTypeInfoAttributes[];
}

export class DSTypeInfo extends Record({
  name: "",
  description: "",
  metadata: {},
  children: [],
}) implements DSTypeInfoAttributes {

  public static EMPTY: DSTypeInfo = new DSTypeInfo();

  public readonly name: string;
  public readonly description: string;
  public readonly metadata: DSTypeInfoMetadata;
  public readonly children: DSTypeInfoAttributes[];

  public getName(): string {
    return getStringValue(this.name);
  }

  public getDescription(): string {
    return this.description;
  }

  public getMetadata(): DSTypeInfoMetadata {
    const metadata = this.metadata || {};

    return { ...metadata };
  }

  public getChildren(): DSTypeInfoAttributes[] {
    return Array.isArray(this.children) ? this.children : [];
  }
}
