import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
    },
    searchFilter: {
      height: 28,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "12px",
      paddingLeft: 4,
      paddingRight: 4,
      marginTop: 8,
      "&:first-child": {
        marginTop: 0,
      },
      "& span": {
        marginLeft: 2,
        marginRight: 2,
      },
    },
    icon: {
      color: Color.WHITE,
      width: 12,
      height: 12,
    },
    deleteIcon: {
      color: Color.WHITE,
      width: 12,
      height: 12,
    },
    searchFilterKeyVerbatim: {},
    searchFilterValueVerbatim: {},
  });

export default styles;
