import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ActionMenuItem } from "@components";
import {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import { DashboardColumn } from "../reducers";
import { Dashboard } from "@data";
import { list as styles, PrimaryIcon } from "./styles";
import { noop } from "@util";
import ModuleListView from "@components/module-list-view";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import { path as pathToDashboard } from "@modules/dashboardDetails/constants";

export const DEFAULT_COLUMNS = [
  DashboardColumn.NAME,
];

export enum DashboardAction {
  NONE = "",
  PUBLISH_DASHBOARD = "publish_dashboard",
  SHARE_DASHBOARD = "share_dashboard",
}

export const PUBLISH_DASHBOARD: ActionMenuItem = Object.freeze({
  id: DashboardAction.PUBLISH_DASHBOARD,
  name: "Publish",
  disabled: false,
  hidden: false,
});

export const SHARE_DASHBOARD: ActionMenuItem = Object.freeze({
  id: DashboardAction.SHARE_DASHBOARD,
  name: "Share",
  disabled: false,
  hidden: false,
});

export const DEFAULT_ACTIONS: ActionMenuItem[] = Object.freeze([
  PUBLISH_DASHBOARD, SHARE_DASHBOARD
]) as ActionMenuItem[];

export interface Model extends SortedSearchResultsListModel<Dashboard, DashboardColumn> {
  selectedDataSet?: Dashboard;
  requestAccessActionEnabled?: boolean;
}

export interface Actions extends SortedSearchResultsListActions<Dashboard, DashboardColumn> {
  setSortedColumn?: (column: DashboardColumn) => void;
  toggleSortOrder?: () => void;
  setSelectedDataSet?: (dataSetInfo: Dashboard) => void;
  onClickItemActions?: (dataSet: Dashboard) => void;
  publishDashboard?: (dataSetInfo: Dashboard) => void;
  shareDashboard?: (dataSet: Dashboard) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DashboardsList = withStyles(styles)((props: Props) => {

  const {
    classes,
    noResultsLabel = "You don't have any dashboards",
    selectedDataSet = Dashboard.EMPTY,
    requestAccessActionEnabled = true,
    columns = DEFAULT_COLUMNS,
    actions = DEFAULT_ACTIONS,
    items: dataSets = [],
    setSortedColumn,
    toggleSortOrder,
    onClickAction = noop,
    publishDashboard = noop,
    shareDashboard = noop,
    onClickItemActions = noop,
    ...otherProps
  } = props;

  const visibleActions = React.useMemo(() => actions.filter((action: ActionMenuItem) => {
    switch (action.id) {
      case DashboardAction.PUBLISH_DASHBOARD:
      case DashboardAction.SHARE_DASHBOARD:
        return requestAccessActionEnabled;
      default:
        return true;
    }
  }), [actions, requestAccessActionEnabled]);

  const onActionClicked = React.useCallback((dashboard: Dashboard, action: ActionMenuItem) => {
    switch (action.id) {
      case DashboardAction.PUBLISH_DASHBOARD:
        return publishDashboard(dashboard);
      case DashboardAction.SHARE_DASHBOARD:
        return shareDashboard(dashboard);
      default:
        return onClickAction(dashboard, action);
    }
  }, [publishDashboard, shareDashboard, onClickAction]);

  const selectedItems = Dashboard.EMPTY.equals(selectedDataSet) ? [] : [selectedDataSet];

  const moduleListItems: ListViewItem<Dashboard>[] = React.useMemo(() =>
    dataSets.map(item => {
      return {
        item: item,
        pathToDetailsView: pathToDashboard(item.getDashboardId()),
        icon: PrimaryIcon,
        columnAttributes: createColumns([
          {
            className: "dashboardName",
            value: item.getDashboardName(),
            column: DashboardColumn.NAME,
            firstColumn: true,
          },
        ])
      };
    }), [dataSets]);

  return (
    <ModuleListView
      {...otherProps}
      className={classnames("dataSetsList", classes.container)}
      noResultsLabel={noResultsLabel}
      actions={visibleActions}
      listViewItems={moduleListItems}
      selectedItems={selectedItems}
      columns={columns}
      tableLayoutFixed={false}
      onClickToggleSortOrder={toggleSortOrder}
      onClickColumn={setSortedColumn}
      onClickItem={onClickItemActions}
      onClickAction={onActionClicked}
    />
  );
});

export default DashboardsList;
