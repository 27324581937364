import React from "react";
import classnames from "classnames";
import MuiTabs, { TabsProps } from "@material-ui/core/Tabs";

export interface CustomTabsProps extends Omit<TabsProps, "onChange"> {
  onChange?: ((event: React.ChangeEvent<{}>, value: any) => void) | ((event:
    React.FormEvent<HTMLButtonElement>) => void);
}

export const Tabs = ((props: CustomTabsProps) => {

  const {
    className,
    ...otherProps
  } = props;

  return (
    <MuiTabs
      {...otherProps}
      className={classnames("tabs", className)}
    />
  );
});

export default Tabs;
