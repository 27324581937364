import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import ReviewView, { Actions, Model } from "../components/ReviewView";
import { DeviceEnrollmentWizardSelectors } from "../selectors";

interface ContainerModel extends Model {
  hidden?: boolean;
}

type Props = ContainerModel & Actions;

const ReviewViewContainer = (props: Props) => {

  const { hidden, ...otherProps } = props;

  if (hidden) {
    return null;
  }

  return <ReviewView {...otherProps} />;
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  hidden: !DeviceEnrollmentWizardSelectors.isReviewViewSelected(state),
  batchId: DeviceEnrollmentWizardSelectors.getBatchId(state),
  fileUpload: DeviceEnrollmentWizardSelectors.isFileUploadSelected(state),
  file: DeviceEnrollmentWizardSelectors.getFile(state),
  loading: DeviceEnrollmentWizardSelectors.isLoadingIndicatorVisible(state),
  json: DeviceEnrollmentWizardSelectors.getDevicesAsJson(state),
  showBackdrop: DeviceEnrollmentWizardSelectors.isLoadingIndicatorVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  ...ownProps,
});

export default connect<ContainerModel, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(ReviewViewContainer);
