import React from "react";
import classnames from "classnames";
import Backdrop from "@material-ui/core/Backdrop";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { reviewView as styles } from "./styles";
import { JsonEditor } from "@components";
import { DeviceDataModel } from "@data";
import { getPluralString, isEmptyString } from "@util";

export interface Model {
  loading?: boolean;
  batchId?: string;
  file?: File;
  fileUpload?: boolean;
  json?: string;
  showBackdrop?: boolean;
}

export interface Actions {
}

const DEFAULT_MAX_FILE_SIZE_MB = 5 * (1024 * 1024);

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ReviewView = withStyles(styles)((props: Props) => {

  const {
    classes,
    json,
    batchId,
    file = new File([], ""),
    showBackdrop = false,
    fileUpload = false,
    children,
  } = props;

  const [devices, setDevices] = React.useState<DeviceDataModel[]>([]);

  const fileName = file?.name !== undefined ? file.name : "File";

  React.useEffect(() => {
    if (file.size > 0 && file.size < DEFAULT_MAX_FILE_SIZE_MB) {
      const reader = new FileReader();
      reader.onload = () => {
        const fileContent = reader.result ? reader.result.toString() : "";
        const encodedDevices = fileContent.split("\n").filter(value => !isEmptyString(value));
        try {
          const devicesAttr = encodedDevices.map((encodedDevice: string) => JSON.parse(atob(encodedDevice)));
          const devicesObject = devicesAttr.map(deviceAttr => new DeviceDataModel(deviceAttr));
          setDevices(devicesObject);
        } catch (e) {
          setDevices([]);
        }
      };
      reader.onerror = () => {
        setDevices([]);
      };
      reader.readAsText(file);
    }
  }, [file, setDevices]);

  const fileInfo = React.useMemo(() => {
    if (file.size >= DEFAULT_MAX_FILE_SIZE_MB) {
      return "The uploaded file is too large to process. Please make sure the correct file is uploaded before submitting the request";
    } else  if (devices.length === 0) {
      return "Unable to read the uploaded file. Please make sure the correct file is uploaded before submitting the request";
    } else {
      return `The uploaded file contains ${getPluralString(devices.length, { other: "devices", one: "device" })}. Click Enroll Device button to submit request`;
    }
  }, [file, devices, getPluralString]);

  const validFile = React.useMemo(() =>
    file.size < DEFAULT_MAX_FILE_SIZE_MB && devices.length > 0, [file, devices]);

  return (
    <div className={classnames("reviewView", classes.container)}>
      <label className={classnames("title", classes.title)}>Review Device Enrollment Data</label>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Batch Id: </label>
        <label className={classnames("batchId", classes.propertyValue)}>{batchId}</label>
      </div>
      {fileUpload && (
        <div className={classnames("fileContainer", classes.fileContainer)}>
          <div className={classnames(classes.propertyContainer)}>
            <label className={classnames(classes.propertyName)}>File Name: </label>
            <label className={classnames("file", classes.propertyValue)}>{fileName}</label>
          </div>
          <label className={classes.subtitle}>
            {fileInfo}
          </label>
          {validFile && (
            <div className={classnames(classes.propertyContainer, classes.deviceEnrollmentContainer)}>
              <JsonEditor
                json={JSON.stringify(devices, null, "  ")}
                readOnly={true}
                width="auto"
                showGutter={false}
                showPrintMargin={false}
              />
            </div>
          )}
        </div>
      )}
      {!fileUpload && (
        <div className={classnames(classes.propertyContainer, classes.deviceEnrollmentContainer)}>
          <div className={classnames("jsonContainer", classes.jsonContainer)}>
            <JsonEditor
              json={json}
              readOnly={true}
              width="auto"
              showGutter={false}
              showPrintMargin={false}
            />
          </div>
        </div>
      )}
      <Backdrop className={classnames("backdrop", classes.backdrop)} open={showBackdrop} />
      {children}
    </div>
  );
});

export default ReviewView;
