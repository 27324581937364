import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { ManageSoftwareVersionsSelectors } from "../selectors";
import { Model, RemoveSoftwareVersion, } from "../components/RemoveSoftwareVersion";

const mapStateToProps = (state: AppSchema): Model => ({
  softwareVersion: ManageSoftwareVersionsSelectors.getSoftwareVersion(state),
});

export default connect<Model, {}, Model>(
  mapStateToProps,
)(RemoveSoftwareVersion);
