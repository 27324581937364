import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      "& .summaryView label.name": {
        minWidth: 240,
      }
    },
    content: {
      marginTop: 32,
      color: Color.SIGNIFY_GREEN,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
  });

export default styles;
