import React from "react";
import classnames from "classnames";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { isEmptyString, noop } from "@util";
import { CreateWorkloadRequest, CreateWorkloadRequestAttributes } from "@data";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DropdownMenu,
  TextField,
} from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { advancedSettings as styles } from "./styles";

export interface Model {
  data?: CreateWorkloadRequest;
  memorySizes?: string[];
}

export interface Actions {
  setWorkloadData?: (data: Partial<CreateWorkloadRequestAttributes>) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const mapMinutesToSeconds = (minutes: string) => parseInt(minutes.split(" ")[0], 10) * 60;

export const mapSecondsToMinutes = (seconds: number) => {
  const min = seconds / 60;
  return min > 1 ? `${min} minutes` : `${min} minute`;
};

export enum MemorySize {
  SMALL = "Small (756MB)",
  MEDIUM = "Medium (1024MB)",
  LARGE = "Large (2048MB)",
  OTHER = "Other",
}

export const mapMemoryToSize = (amount: number) => {
  switch (amount) {
    case 756:
      return MemorySize.SMALL;
    case 1024:
      return MemorySize.MEDIUM;
    case 2048:
      return MemorySize.LARGE;
    default:
      return MemorySize.OTHER;
  }
};

export const mapSizeToMemory = (s: MemorySize, customMemory: number) => {
  switch (s) {
    case MemorySize.MEDIUM:
      return 1024;
    case MemorySize.LARGE:
      return 2048;
    case MemorySize.SMALL:
      return 756;
    default:
      return customMemory;
  }
};

export const AdvancedSettings = withStyles(styles)((props: Props) => {

  const {
    classes,
    data = CreateWorkloadRequest.EMPTY,
    memorySizes = [MemorySize.SMALL, MemorySize.MEDIUM, MemorySize.LARGE, MemorySize.OTHER],
    setWorkloadData = noop,
  } = props;

  const [size, setSize] = React.useState<MemorySize>(MemorySize.SMALL);
  const [customMemory, setCustomMemory] = React.useState<number>(0);

  const updateSize = React.useCallback((newSize: MemorySize) => {
    setSize(newSize);
    setWorkloadData({ memory: mapSizeToMemory(newSize, customMemory) });
  }, [setSize, setWorkloadData, mapSizeToMemory, customMemory]);

  const updateCustomMemory = React.useCallback((amount: number) => {
    setCustomMemory(amount);
    setWorkloadData({ memory: amount });
  }, [setWorkloadData, setCustomMemory]);

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    }
  };

  React.useEffect(() => {
    setSize(mapMemoryToSize(data.getMemory()));
    setCustomMemory(data.getMemory());
  }, []);

  return (
    <div className={classnames("advancedSettings", classes.container)}>
      <Accordion className={classes.noLine}>
        <AccordionSummary
          className={classnames(classes.summary, classes.noPadding)}
          expandIcon={<ExpandMoreIcon />}
        >
          <label className={classes.label}>Advanced Settings</label>
        </AccordionSummary>
        <AccordionDetails className={classnames(classes.noPadding, classes.details)}>
          <DropdownMenu
            className={classnames("timeout", classes.dropdown)}
            dropdownMenuLabel="Timeout"
            selectedValue={mapSecondsToMinutes(data.getTimeout())}
            setSelectedValue={(value: string) => setWorkloadData({
              timeout: mapMinutesToSeconds(value),
            })}
            values={["1 minute", "2 minutes", "5 minutes", "10 minutes", "15 minutes"]}
            hideEmptyValue={true}
            dropdownMenuHint="Select the maximum duration of a running instance of the workload"
            variant="outlined"
            selectClassName={classes.dropdownMenuSelect}
            dropdownMenuLabelClassName={classes.dropdownMenuLabel}
          />
          <DropdownMenu
            className={classnames("memory", classes.dropdown)}
            dropdownMenuLabel="Memory"
            selectedValue={size}
            setSelectedValue={updateSize}
            values={memorySizes}
            hideEmptyValue={true}
            dropdownMenuHint="Select the amount of memory the workload can use"
            variant="outlined"
            selectClassName={classes.dropdownMenuSelect}
            dropdownMenuLabelClassName={classes.dropdownMenuLabel}
          />
          {size === MemorySize.OTHER && (
            <TextField
              className={classnames("customMemory", classes.customMemory)}
              type="number"
              value={customMemory}
              onChange={event =>
                updateCustomMemory(isEmptyString(event.target.value) ? 0 : parseInt(event.target.value, 10))}
              variant="outlined"
              inputProps={{ min: 756, max: 2000 }}
              InputLabelProps={inputLabelProps}
              error={customMemory < 756 || customMemory > 2000}
              label="Custom Memory"
              helperText="Enter custom amount of memory in MB. Note: must be between 756 and 2000 MB"
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

export default AdvancedSettings;
