export enum ProfileAttributeDefinitionListColumn {
  NONE = "",
  NAME = "Name",
  DESCRIPTION = "Description",
  HIDDEN = "Hidden",
  OPTIONAL = "Optional",
  IMMUTABLE = "Immutable",
  DATA_TYPE = "Data Type",
  MAX_LENGTH = "Max Length",
}

export default ProfileAttributeDefinitionListColumn;
