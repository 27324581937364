import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { getEditEmailTemplatePath } from "@modules/emailTemplateWizard/helpers";
import EmailTemplateDetails from "./containers/EmailTemplateDetails";
import { MODULE_PATH as EMAIL_TEMPLATES_LIST_PATH } from "@modules/emailTemplates/constants";

export function EmailTemplateDetailsView() {

  const history = useHistory();

  const { id: emailTemplateId } = useParams<{ id: string }>();

  const showListView = React.useCallback(() => {
    history.push(EMAIL_TEMPLATES_LIST_PATH);
  }, [history]);

  const editEmailTemplate = React.useCallback(() =>
    history.push(getEditEmailTemplatePath(emailTemplateId), { goBackOnSuccess: true }),
    [history, emailTemplateId]);

  return (
    <EmailTemplateDetails
      showListView={showListView}
      templateId={emailTemplateId}
      editEmailTemplate={editEmailTemplate}
    />
  );
}

export default EmailTemplateDetailsView;
