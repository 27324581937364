import React from "react";
import classnames from "classnames";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { PropertyInfoLabel } from "./PropertyInfoLabel";
import { JsonSchemaProperty } from "../../../../data";
import { propertyInfo as styles } from "./styles";

export const PropertyInfo = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  property: JsonSchemaProperty,
  value?: string,
}) => {

  const {
    classes,
    className = "propertyInfo",
    property = JsonSchemaProperty.EMPTY,
  } = props;

  if (JsonSchemaProperty.EMPTY.equals(property)) {
    return (
      <div className={classnames("emptyView", className, classes.container)}>
        <label className={classes.emptyViewLabel}>No Property Info Available</label>
      </div>
    );
  }

  return (
    <div className={classnames(className, classes.container)}>
      <PropertyInfoLabel className="name" name="Name" value={property.name} />
      <PropertyInfoLabel className="type" name="Type" value={property.type} />
    </div>
  );
});

export default PropertyInfo;
