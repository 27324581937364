import React from "react";
import styles from "./styles";
import classnames from "classnames";
import Tabs from "@components/tabs";
import { TabLink as Tab } from "@components/tab";
import camelCase from "lodash/camelCase";
import { DetailsViewTabVariant } from "./DetailsViewTabs";
import DetailsViewRoute from "@components/details-view/DetailsViewRoute";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface TabsViewModel {
  baseUrl?: string;
  disabled?: boolean;
  routes?: DetailsViewRoute[];
  activeTab?: string | number;
  tabVariant?: DetailsViewTabVariant;
}

export interface TabsViewActions {
}

type Model = TabsViewModel;
type Actions = TabsViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const TabsView = withStyles(styles)((props: Props) => {

  const {
    classes,
    disabled,
    routes = [],
    baseUrl = "",
    activeTab = "",
    tabVariant,
  } = props;

  if (tabVariant !== DetailsViewTabVariant.TABS) {
    return null;
  }

  return (
    <Tabs className={classnames("tabs", classes.tabs)} value={activeTab}>
      {routes.map(({ id, name: label, path = "", className: tabClassName, hidden }) => {
        if (hidden) {
          return null;
        } else {
          return (
            <Tab
              key={id}
              style={{ "--tabValue": `\"${label}\"` } as React.CSSProperties}
              className={classnames(tabClassName || `${camelCase(id)}Button`, classes.tab)}
              disabled={disabled}
              label={label}
              value={label}
              to={baseUrl + path}
            />
          );
        }
      })}
    </Tabs>
  );
});

export default TabsView;
