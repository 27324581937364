import React from "react";
import { useParams } from "react-router-dom";
import AuditEventDetails from "./containers/AuditEventDetails";

export function ApplicationDetailsView() {

  const { id: auditEventId } = useParams<{ id: string }>();

  return (
    <AuditEventDetails
      auditEventId={auditEventId}
    />
  );
}

export default ApplicationDetailsView;
