import React from "react";
import classnames from "classnames";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { schemasView as styles } from "./styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@components/circular-progress";

export interface Model {
  title?: string;
  showBackDrop?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SchemasView = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Select Device Type Schemas",
    className = "schemasView",
    showBackDrop = false,
    children
  } = props;

  return (
    <div className={classnames("schemasView", className, classes.container)}>
      <label className={classnames("title", classes.title)}>
        {title}
      </label>
      <Backdrop className={classnames("backdrop", classes.backdrop)} open={showBackDrop} />
      {showBackDrop && (
        <CircularProgress size={68} className={classes.progressIndicator}/>
      )}
      <div className={classnames(classes.contentWrapper)}>
        {children}
      </div>
    </div>
  );
});

export default SchemasView;
