import React from "react";
import classnames from "classnames";
import CopyButton from "./CopyButton";
import { useClipboard } from "@hooks";
import { getSelection, isEmptyString } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const DEFAULT_TOOLTIP_LEAVE_DELAY = 100;

export const CopyAccessToken = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  title?: string,
  subtitle?: string,
  accessToken?: string,
  hint?: string,
  successLabel?: string,
  errorLabel?: string,
  tooltipLeaveDelay?: number,
}) => {

  const {
    classes,
    className,
    title = "",
    subtitle = "",
    accessToken = "",
    hint = "Copy",
    tooltipLeaveDelay = DEFAULT_TOOLTIP_LEAVE_DELAY,
    successLabel = "Copied!",
    errorLabel = "Copy Failed",
  } = props;

  const {
    copy,
    showSuccess,
    showError,
    showTooltip,
    setShowTooltip,
  } = useClipboard(accessToken, tooltipLeaveDelay);

  const accessTokenRef = React.useRef<HTMLDivElement | null>(null);

  const [accessTokenHovered, setAccessTokenHovered] = React.useState(false);

  const [accessTokenClicked, setAccessTokenClicked] = React.useState(false);

  const tooltip = React.useMemo(() => {
    if (showSuccess) {
      return successLabel;
    } else if (showError) {
      return errorLabel;
    } else {
      return hint;
    }
  }, [showSuccess, showError]);

  // Select contents of access token when hovered/expanded and clicked
  React.useEffect(() => {
    if (accessTokenRef.current !== null) {
      const selection = getSelection();
      if (selection) {
        if (accessTokenHovered && accessTokenClicked) {
          selection.selectAllChildren(accessTokenRef.current);
        } else {
          selection.empty();
        }
      }
    }
  }, [accessTokenRef, accessTokenHovered, accessTokenClicked]);

  // Remove selection when cursor leaves access token
  React.useEffect(() => {
    if (!accessTokenHovered) {
      setAccessTokenClicked(false);
    }
  }, [accessTokenHovered, setAccessTokenClicked]);

  if (isEmptyString(accessToken)) {
    return null;
  }

  return (
    <div className={classnames("copyAccessToken", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        <label className={classnames("title", classes.title)}>{title}</label>
      </div>
      {subtitle && (<label className={classnames("expiresAt", classes.subtitle)}>{subtitle}</label>)}
      <div className={classnames("tokenAndButtonContainer", classes.tokenAndButtonContainer)}>
        <div
          onClick={() => setAccessTokenClicked(true)}
          onMouseEnter={() => setAccessTokenHovered(true)}
          onMouseLeave={() => setAccessTokenHovered(false)}
          className={classnames("accessTokenContainer",
            accessTokenHovered ? classes.accessTokenContainerIn : classes.accessTokenContainerOut)}
        >
          <div
            ref={accessTokenRef}
            className={classnames("accessToken",
              accessTokenHovered ? classes.accessTokenIn : classes.accessTokenOut)}
          >
            {accessToken}
          </div>
        </div>
        <CopyButton
          copy={copy}
          tooltip={tooltip}
          showTooltip={showTooltip}
          tooltipLeaveDelay={tooltipLeaveDelay}
          onShowTooltip={() => setShowTooltip(true)}
          onHideTooltip={() => setShowTooltip(false)}
        />
      </div>
    </div>
  );
});

export default CopyAccessToken;
