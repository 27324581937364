import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { DeviceTypeWizardActions } from "../actions";
import { DeviceTypeWizardSelectors } from "../selectors";
import DeviceTypeWizard, { Actions, Model } from "../components/DeviceTypeWizard";
import EditModeInitializeErrorView from "./EditModeInitializeErrorView";
import ApiVersionView from "./ApiVersionView";
import NamespaceView from "./NamespaceView";
import NameView from "./NameView";
import ConnectivityView from "./ConnectivityView";
import GroupView from "./GroupsView";
import SchemasView from "./SchemasView";
import SecretsSchemasView from "./SecretsSchemasView";
import CredentialsView from "./CredentialsView";
import DeviceTypeEditorView from "./DeviceTypeEditorView";
import ReviewView from "./ReviewView";
import { SecurityGroup } from "@data";

interface ContainerModel extends Model {
  snackbarId?: string;
  successMessage?: string;
  createdDeviceTypeRef?: string;
  showEditModeInitializeErrorView?: boolean;
  showModelVersionNotSupportedError?: boolean;
}

interface ContainerActions extends Actions {
  showDeviceTypeDetails?: (deviceTypeRef: string) => void;
  showSecurityGroupDetails?: (securityGroup: SecurityGroup) => void;
}

type Props = ContainerModel & ContainerActions;

const DeviceTypeWizardContainer = (props: ContainerModel & ContainerActions) => {

  const {
    createdDeviceTypeRef = "",
    showEditModeInitializeErrorView,
    showModelVersionNotSupportedError,
    cancel,
    showDeviceTypeDetails = noop,
    showSecurityGroupDetails,
    ...otherProps
  } = props;

  const onSuccess = React.useCallback(() => {
    showDeviceTypeDetails(createdDeviceTypeRef);
  }, [showDeviceTypeDetails, createdDeviceTypeRef]);

  if (showEditModeInitializeErrorView) {
    return <EditModeInitializeErrorView cancel={cancel} />;
  }

  if (showModelVersionNotSupportedError) {
    return (
      <EditModeInitializeErrorView
        className="apiVersionNotSupported"
        errorTitle="Device Type Not Supported"
        errorMessage={"Oops, this device type model version is not supported."}
        hideRetryButton={true}
        cancel={cancel}
      />
    );
  }

  return (
    <DeviceTypeWizard
      {...otherProps}
      cancel={cancel}
      onSuccess={onSuccess}
    >
      <ApiVersionView />
      <NamespaceView />
      <NameView />
      <ConnectivityView />
      <GroupView showSecurityGroupDetails={showSecurityGroupDetails}/>
      <SchemasView />
      <SecretsSchemasView />
      <CredentialsView />
      <DeviceTypeEditorView />
      <ReviewView />
    </DeviceTypeWizard>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  currentState: DeviceTypeWizardSelectors.getDeviceTypeWizardState(state),
  defaultState: DeviceTypeWizardSelectors.getDeviceTypeWizardDefaultState(state),
  steps: DeviceTypeWizardSelectors.getSteps(state),
  stepLabels: DeviceTypeWizardSelectors.getStepLabels(state),
  createdDeviceTypeRef: DeviceTypeWizardSelectors.getDeviceTypeRef(state),
  successMessage: DeviceTypeWizardSelectors.getSuccessMessage(state),
  errorMessage: DeviceTypeWizardSelectors.getErrorMessage(state),
  errorTitle: DeviceTypeWizardSelectors.getErrorTitle(state),
  showLoadingIndicator: DeviceTypeWizardSelectors.isLoadingIndicatorVisible(state),
  showSuccessIndicator: DeviceTypeWizardSelectors.isSuccessMessageVisible(state),
  saveButtonDisabled: !DeviceTypeWizardSelectors.isSaveButtonEnabled(state),
  currentStep: DeviceTypeWizardSelectors.getDeviceTypeWizardStep(state),
  completedSteps: DeviceTypeWizardSelectors.getCompletedSteps(state),
  disabledSteps: DeviceTypeWizardSelectors.getDisabledSteps(state),
  previousStepButtonDisabled: DeviceTypeWizardSelectors.isPreviousStepButtonDisabled(state),
  nextStepButtonDisabled: DeviceTypeWizardSelectors.isNextStepButtonDisabled(state),
  showSaveButton: DeviceTypeWizardSelectors.isLastStepActive(state),
  showEditModeInitializeErrorView: DeviceTypeWizardSelectors.isEditModeInitializeErrorVisible(state),
  showModelVersionNotSupportedError: DeviceTypeWizardSelectors.isModelVersionNotSupportedErrorVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setCurrentStep: step => dispatch(DeviceTypeWizardActions.setCurrentStep(step)),
  previousStep: () => dispatch(DeviceTypeWizardActions.showPreviousStep()),
  nextStep: () => dispatch(DeviceTypeWizardActions.showNextStep()),
  save: () => dispatch(DeviceTypeWizardActions.save()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceTypeWizardContainer);
