import { Record } from "immutable";
import {
  equalsIgnoreCase,
  getStringValue,
  isEmptyString,
  isValidNumber,
  parseBoolean,
} from "@util";

export type UserProfileAttributesMap = {
  [key: string]: UserProfileAttribute;
};

export interface UserProfileAttributeAttributes {
  name: string;
  value: string | number | boolean | null;
  dataType: string;
}

export class UserProfileAttribute extends Record({
  name: "",
  value: "",
  dataType: "",
}) implements UserProfileAttributeAttributes {

  public static EMPTY = new UserProfileAttribute();

  public readonly name: string;
  public readonly value: string | number | boolean | null;
  public readonly dataType: string;

  public getName(): string {
    return getStringValue(this.name);
  }

  public getValue(): string | number | boolean | null {
    const value = this.value;

    if (value === null) {
      return null;
    }

    if (this.isStringDataType()) {
      return `${value}`;
    }

    if (this.isBooleanDataType()) {
      return parseBoolean(`${value}`);
    }

    if (this.isNumberDataType() && typeof value !== "boolean" && isValidNumber(value)) {
      return Number(value);
    }

    return this.value;
  }

  public getDataType(): string {
    return getStringValue(this.dataType);
  }

  public hasName(): boolean {
    return !isEmptyString(this.getName());
  }

  public isBinaryDataType(): boolean {
    return this.getDataType().toLowerCase().indexOf("binary") >= 0;
  }

  public isStringDataType(): boolean {
    return equalsIgnoreCase("STRING", this.getDataType());
  }

  public isBooleanDataType(): boolean {
    return equalsIgnoreCase("BOOLEAN", this.getDataType());
  }

  public isIntegerDataType(): boolean {
    return equalsIgnoreCase("INTEGER", this.getDataType());
  }

  public isShortDataType(): boolean {
    return equalsIgnoreCase("SHORT", this.getDataType());
  }

  public isLongDataType(): boolean {
    return equalsIgnoreCase("LONG", this.getDataType());
  }

  public isFloatDataType(): boolean {
    return equalsIgnoreCase("FLOAT", this.getDataType());
  }

  public isNumberDataType(): boolean {
    return this.isIntegerDataType() ||
      this.isShortDataType() ||
      this.isLongDataType() ||
      this.isFloatDataType();
  }

  public getValueAsString(): string {
    return `${this.getValue()}`;
  }

  public isNameFilterMatch(searchQuery: string): boolean {

    const nameFilter = searchQuery.toLowerCase();

    return this.getName().toLowerCase().indexOf(nameFilter) >= 0 ||
      this.getValueAsString().toLowerCase().indexOf(nameFilter) >= 0 ||
      this.getDataType().toLowerCase().indexOf(nameFilter) >= 0;
  }
}

export default UserProfileAttribute;
