import React from "react";
import { QualityReportPlatformDomainId } from "@data/QualityReport";
import { QualityReportsTab } from "../components/QualityReportsTabs";
import QualityReports, {
  QualityReportsActions,
  QualityReportsModel,
} from "../components/QualityReports";
import useListQualityReports from "@hooks/portalQualityReports/use-list-quality-reports";

interface ContainerModel extends QualityReportsModel {
  selectedTab?: QualityReportsTab;
  platformDomainId?: QualityReportPlatformDomainId;
}

interface ContainerActions extends QualityReportsActions {
}

type Model = ContainerModel;
type Actions = ContainerActions;
type Props = Model & Actions;

const QualityReportsContainer = (props: Props) => {

  const {
    selectedTab = QualityReportsTab.SECURITY_SERVICES,
    platformDomainId = QualityReportPlatformDomainId.SECURITY_SERVICES,
    title = `${selectedTab} Quality Reports`,
    ...otherProps
  } = props;

  const [model, actions] = useListQualityReports({ platformDomainId });

  return (
    <QualityReports
      {...otherProps}
      {...model}
      {...actions}
      title={title}
    />
  );
};

export default QualityReportsContainer;
