import React from "react";
import { noop } from "@util";
import { Vector } from "@data";
import classnames from "classnames";
import ConfirmationDialog, {
  ConfirmationDialogActions,
  ConfirmationDialogModel,
} from "@components/confirmation-dialog";
import copyToClipboard from "clipboard-copy";
import TextField from "@components/text-field";
import CopyContent from "@material-ui/icons/FileCopy";
import TooltipIcon from "@components/tooltip/TooltipIcon";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles, { textField } from "./styles";

const SecretField = withStyles(textField)(TextField);

export interface SecretDialogModel extends ConfirmationDialogModel<string> {
  className?: string;
  secret?: string;
  fileName?: string;
  bodyText?: string;
  additionalText?: string;
  secretFieldLabel?: string;
  children?: React.ReactNode;
}

export interface SecretDialogActions extends ConfirmationDialogActions<string> {
  closeDialog?: () => void;
}

type Props = WithStyles<typeof styles> & SecretDialogModel & SecretDialogActions;

const BODY_TEXT = "This is the ONLY time the application secret can be viewed. " +
  "You cannot retrieve this secret later; however, you can generate a new secret at any time.";

export const SecretDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className = "applicationSecretDialog",
    secret = "",
    fileName = "",
    children,
    title = "Application Secret",
    open = false,
    continueButtonLabel = "Download Secret",
    secretFieldLabel = "Application Secret",
    bodyText = BODY_TEXT,
    additionalText = "",
    closeDialog = noop,
    ...remainingProps
  } = props;

  if (!open) {
    return null;
  }

  const copy = () => {
    return copyToClipboard(secret);
  };

  const download = React.useCallback(() => {
    const element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(secret));
    element.setAttribute("download", fileName + "_secret");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();
  }, [document, secret, fileName]);

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const inputProps = {
    className: classnames("textField", classes.textField),
    spellCheck: false,
  };

  return (
    <ConfirmationDialog
      {...remainingProps}
      className={classnames("secretDialog", className, classes.container)}
      open={open}
      continueButtonLabel={continueButtonLabel}
      cancelButtonLabel="Close"
      title={title}
      confirm={download}
      cancel={closeDialog}
    >
      <React.Fragment>
        <label className={classnames("subtitle", classes.subtitle)}>
          <span className={classes.warning}>NOTE:</span>
          <span className={classes.textRed}>{bodyText}</span>
          <span className={classes.additionalText}>{additionalText}</span>
        </label>
        <div className={classnames("controls", classes.controls)}>
          <SecretField
            className={classnames("secret", classes.inputField, classes.secret)}
            autoComplete="off"
            label={secretFieldLabel}
            name="secret"
            value={secret}
            fullWidth={true}
            autoFocus={true}
            disabled={true}
            variant="outlined"
            margin="none"
            InputLabelProps={inputLabelProps}
            InputProps={{
              inputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <div className={classnames("copyControls", classes.copyControls)}>
                    <TooltipIcon
                      className={classnames("copyToClipboardButton", classes.copyToClipboardButton)}
                      PopperProps={{
                        className: classnames("tooltipIconPopper", classes.tooltipIconPopper),
                      }}
                      onClick={copy}
                      tooltipText="Copy to clipboard"
                      icon={CopyContent}
                      size={Vector.square(24)}
                      disableTouchRipple={false}
                    />
                  </div>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </React.Fragment>
      {children}
    </ConfirmationDialog>
  );
});

export default SecretDialog;
