import React from "react";
import { isEmptyString } from "@util";
import usePaginatedApiRequest, {
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
} from "@hooks/use-paginated-api-request";
import { ListQualityReportsResponse } from "@network/PortalQualityReportsClient";
import QualityReport, { QualityReportPlatformDomainId } from "@data/QualityReport";
import usePortalQualityReportsClient from "../use-portal-quality-reports-client";

type SuccessResponse = ListQualityReportsResponse;

export interface UseListQualityReportsProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
  platformDomainId?: QualityReportPlatformDomainId;
  groupId?: string;
  createdBefore?: string;
  createdAfter?: string;
  limit?: number;
}

export interface UseListQualityReportsModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  reports: QualityReport[];
  totalNumItems: number;
}

export interface UseListQualityReportsActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseListQualityReportsProps;
type Model = UseListQualityReportsModel;
type Actions = UseListQualityReportsActions;
type Result = [Model, Actions];

export const useListQualityReports = (props: Props = {}): Result => {

  const {
    platformDomainId = QualityReportPlatformDomainId.NONE,
    groupId = "",
    createdBefore,
    createdAfter,
    limit,
    defaultErrorMessage = "Failed to list quality reports",
    ...otherProps
  } = props;

  const PortalQualityReportsClient = usePortalQualityReportsClient();

  const [reports, setReports] = React.useState<QualityReport[]>([]);

  const makeApiRequest = React.useCallback((accessToken, next) => {
    if (platformDomainId === QualityReportPlatformDomainId.NONE) {
      return PortalQualityReportsClient.listQualityReports(
        createdBefore, createdAfter, next, limit);
    } else if (isEmptyString(groupId)) {
      return PortalQualityReportsClient.listPlatformDomainQualityReports(
        platformDomainId, createdBefore, createdAfter, next, limit);
    } else {
      return PortalQualityReportsClient.listPlatformDomainGroupQualityReports(
        platformDomainId, groupId, createdBefore, createdAfter, next, limit);
    }
  }, [PortalQualityReportsClient, platformDomainId, groupId, createdBefore, createdAfter, limit]);

  const [{ successResponse, ...baseModel }, {
    reset: baseReset,
    refresh: baseRefresh,
    ...baseActions
  }] = usePaginatedApiRequest<SuccessResponse>({
    ...otherProps,
    defaultErrorMessage,
    makeApiRequest,
  });

  const totalNumItems = React.useMemo<number>(() => {
    const { paging: { totalNumItems: numItems = reports.length } = {} } = successResponse || { paging: {} };
    return numItems;
  }, [successResponse, reports]);

  const updatedReports = React.useMemo<QualityReport[]>(() => {
    const { reports: items = [] } = successResponse || {};
    return items.map(attrs => new QualityReport(attrs));
  }, [successResponse]);

  const reset = React.useCallback(() => {
    setReports([]);
    baseReset();
  }, [setReports, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    reports,
    totalNumItems,
  }), [
    baseModel,
    reports,
    totalNumItems,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    refresh();
  }, [
    refresh,
    platformDomainId,
    groupId,
    createdBefore,
    createdAfter,
  ]);

  React.useEffect(() => {
    setReports(currentReports => currentReports.concat(updatedReports));
  }, [updatedReports, setReports]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useListQualityReports;
