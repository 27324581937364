import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { isEmptyString } from "@util";
import { styles } from "./styles";
import { User } from "@data";
import { ExpireUserPassword } from "@components";

export interface Model {
  user?: User;
  expirePasswordDialog?: boolean;
}

export interface Actions {
  close?: () => void;
  openLogoutDialogue?: (user: User) => void;
  expirePasswordRequest?: () => void;
  expirePasswordSuccess?: () => void;
  expirePasswordFailed?: (error: string) => void;
}

type Props = WithStyles<typeof  styles> & Model & Actions;

export const ExpireUserPasswordDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    user = User.EMPTY,
    expirePasswordDialog = false,
    close,
    openLogoutDialogue,
    expirePasswordFailed,
    expirePasswordRequest,
    expirePasswordSuccess,
  } = props;

  if (isEmptyString(user.getUserId())) {
    return (
      <label className={classnames("emptyViewLabel", classes.emptyViewLabel)}>
        No User Selected
      </label>
    );
  }

  return (
    <div className={classnames("expireUserPassword", classes.container)}>
      <ExpireUserPassword
        open={expirePasswordDialog}
        cancel={close}
        user={user}
        onSuccessMessageShown={close}
        openLogoutUserDialog={openLogoutDialogue}
        expirePasswordRequest={expirePasswordRequest}
        expirePasswordSuccess={expirePasswordSuccess}
        expirePasswordFailed={expirePasswordFailed}
      />
    </div>
  );
});

export default ExpireUserPasswordDialog;
