import React from "react";
import classnames from "classnames";
import { noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import FilterSwitch from "../filter-switch/FilterSwitch";
import styles from "./styles";

export interface ToggleDtsModelVersionSwitchModel {
  className?: string;
  disabled?: boolean;
  color?: "primary" | "secondary" | "default";
  historicalApiLabel?: string;
  regionalApiLabel?: string;
  regionalApiSelected?: boolean;
}

export interface ToggleDtsModelVersionSwitchActions {
  showHistoricalApi?: () => void;
  showRegionalApi?: () => void;
}

type Model = ToggleDtsModelVersionSwitchModel;
type Actions = ToggleDtsModelVersionSwitchActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ToggleDtsModelVersionSwitch = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    disabled,
    color = "primary",
    historicalApiLabel = "Historical",
    regionalApiLabel = "Regional",
    regionalApiSelected,
    showHistoricalApi = noop,
    showRegionalApi = noop,
    children,
  } = props;

  const onChange = React.useCallback(() => {
    if (regionalApiSelected) {
      showHistoricalApi();
    } else {
      showRegionalApi();
    }
  }, [regionalApiSelected, showHistoricalApi, showRegionalApi]);

  return (
    <FilterSwitch
      className={classnames("toggleDtsModelVersionSwitch", className, classes.container)}
      leftLabelClassName={classnames("historicalApiLabel", classes.label)}
      rightLabelClassName={classnames("regionalApiLabel", classes.label)}
      disabled={disabled}
      color={color}
      leftLabel={historicalApiLabel}
      rightLabel={regionalApiLabel}
      checked={regionalApiSelected}
      onChange={onChange}
    >
      {children}
    </FilterSwitch>
  );
});

export default ToggleDtsModelVersionSwitch;
