import { isEmptyString } from "@util";
import { DataSetRequestAttributes } from "@data/DataSetRequest";
import {
  makeApiRequest,
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments,
} from "./helpers";
import { DataSetRequestEventAttributes } from "@data/DataSetRequestEvent";
import { GetRequestCommentsResponse } from "@data/RequestComment";

const DATA_SET_REQUEST_API = process.env.REACT_APP_DATA_SET_REQUEST_API || "";

if (isEmptyString(DATA_SET_REQUEST_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_DATA_SET_REQUEST_API");
}

export interface GetDataSetRequestsResponse {
  dataSetRequests: DataSetRequestAttributes[];
  paging?: {
    next?: string;
  };
}

export interface GetDataSetRequestEventsResponse {
  events: DataSetRequestEventAttributes[];
  paging?: {
    next?: string;
  };
}

export interface CreateDataSetRequestResponse {
  dataSetRequestId: string;
  dataSetPrivacyTnCRequired: boolean;
}

export interface TermsConditionsUrlResponse {
  termsConditionsUrl: string;
}

export const getDataSetRequests = (authToken: string): Promise<GetDataSetRequestsResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${DATA_SET_REQUEST_API}/data/management/v1/governance/datasets`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Data Set Requests failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const createDataSetRequest = (authToken: string,
                                    json: string): Promise<CreateDataSetRequestResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Create Data Set Request", json],
    ]));

  const makeRequest = () => {

    const url = `${DATA_SET_REQUEST_API}/data/management/v1/governance/datasets`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to create Data Set Request";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const editDataSetRequest = (authToken: string,
                                   dataSetRequestId: string,
                                   json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Data Set Request Id", dataSetRequestId],
      ["Edit Data Set Request", json],
    ]));

  const makeRequest = () => {

    const url = `${DATA_SET_REQUEST_API}/data/management/v1/governance/datasets/${dataSetRequestId}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to eidt Data Set Request";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getDataSetRequest = (authToken: string,
                                  dataSetRequestId: string): Promise<DataSetRequestAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Data Set Request Id", dataSetRequestId]
    ]));

  const makeRequest = () => {

    const url = `${DATA_SET_REQUEST_API}/data/management/v1/governance/datasets/${dataSetRequestId}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Data Set Request failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getDataSetRequestEvents = (authToken: string,
                                        dataSetRequestId: string): Promise<GetDataSetRequestEventsResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Data Set Request Id", dataSetRequestId]
    ]));

  const makeRequest = () => {

    const url = `${DATA_SET_REQUEST_API}/data/management/v1/governance/datasets/${dataSetRequestId}/events`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Data Set Request Events failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getDataSetRequestComments = (authToken: string,
                                          dataSetRequestId: string): Promise<GetRequestCommentsResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Data Set Request Id", dataSetRequestId]
    ]));

  const makeRequest = () => {

    const url = `${DATA_SET_REQUEST_API}/data/management/v1/governance/datasets/${dataSetRequestId}/comments`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Data Set Request Comments failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const createDataSetRequestComment = (authToken?: string,
                                            dataSetRequestId?: string,
                                            json?: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Data Set Request Id", dataSetRequestId],
      ["Comment", json],
    ]));

  const makeRequest = () => {

    const url = `${DATA_SET_REQUEST_API}/data/management/v1/governance/datasets/${dataSetRequestId}/comments`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to add comment";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };
  return validate().then(makeRequest);
};

export const deleteDataSetRequest = (authToken: string,
                                     accountId: string,
                                     dataSetAlias: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Account Id", accountId],
      ["Data Set Name", dataSetAlias]
    ]));

  const makeRequest = () => {

    const url = `${DATA_SET_REQUEST_API}/data/management/v1/governance/accounts/${accountId}/datasets/${dataSetAlias}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to cancel data set request for [${accountId}, ${dataSetAlias}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const uploadTermsAndConditions = (authToken: string,
                                         dataSetRequestId: string,
                                         file?: File): Promise<any> => {

  if (!file) {
    return Promise.reject({
      error: "Invalid File",
      status: 400,
      message: "Bad Request",
      description: "You must provide the file that you want to upload",
      analytic: `400:Bad Request:Invalid File`,
    });
  }

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Data Set Request Id", dataSetRequestId],
    ]));

  const makeRequest = () => {

    const url =
      `${DATA_SET_REQUEST_API}/data/management/v1/governance` +
      `/datasets/${dataSetRequestId}/privacy-terms-and-conditions?fileName=${file.name}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/octet-stream",
      },
      body: file,
    };

    const defaultErrorMessage = "Failed to upload Terms and Conditions";

    return makeApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getTermsAndConditionsUrl = (authToken: string,
                                         termsConditionsId: string): Promise<TermsConditionsUrlResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Privacy Terms & Conditions Id", termsConditionsId]
    ]));

  const makeRequest = () => {

    const url =
      `${DATA_SET_REQUEST_API}/data/management/v1/governance/privacy-terms-and-conditions/${termsConditionsId}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to get the privacy terms & conditions URL";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
