import createStyles from "@material-ui/core/styles/createStyles";
import { PrimaryIcon } from "@modules/dataSets/components/styles";
import Color from "@Color";

export const ModuleIcon = PrimaryIcon;

export const primaryColor = Color.MODULES;

export const summaryView = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      "& label.name": {
        minWidth: 120,
      },
    },
    content: {
      marginTop: 32,
      color: primaryColor,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      minWidth: 100,
      alignSelf: "flex-start",
      fontSize: 24,
      lineHeight: "28px",
    },
    tagsTitle: {
      marginTop: 15,
    },
    chipsContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginTop: 15,
      marginBottom: 15,
    },
    chip: {
      color: Color.TEXT,
      height: 28,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "12px",
      paddingLeft: 4,
      paddingRight: 4,
      marginLeft: 8,
      marginRight: 8,
      marginTop: 8,
      "&:first-child": {
        marginLeft: 0,
      },
      "& span": {
        marginLeft: 2,
        marginRight: 2,
        paddingRight: 8,
      },
    },
    icon: {
      color: Color.BLACK,
      width: 12,
      height: 12,
    },
    summaryViewLabel: {
      "& label": {
        minWidth: 180,
      }
    }
  });

export const dataLakeView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 25,
    },
    dataLakeFileBrowser: {
      flex: "1 0 auto",
    },
  });

export const accessRequestsView = () =>
  createStyles({
    container: {
      marginTop: "25px",
    },
  });

export const listItem = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "center",
      "&:first-child": {
        marginLeft: 4,
      },
    },
    icon: {
      color: primaryColor,
      alignSelf: "center",
      width: 16,
      height: 16,
      marginTop: 0,
      marginRight: 10,
    },
    label: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "16px",
      wordBreak: "break-word",
      marginRight: 8,
    },
  });
