import React from "react";
import { isValidInteger } from "@util";
import { RouteParams } from "./constants";
import Trigger, { TriggerAttributes } from "@data/Trigger";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getPathToWorkloadTriggers } from "@modules/dataWorkloadDetails/helpers";
import TriggerWizard from "./containers/TriggerWizard";
import useTriggerType from "./useTriggerType";

type LocationProps = {
  trigger?: TriggerAttributes
};

export function EditTriggerView() {

  const history = useHistory();

  const type = useTriggerType();

  const { name, version: versionParam } = useParams<RouteParams>();

  const {
    state: {
      trigger: triggerAttrs = Trigger.EMPTY.toJS(),
    } = {},
  } = useLocation<LocationProps>();

  const [initialized, setInitialized] = React.useState(false);

  const [redirectToWorkloadDetails, setRedirectToWorkloadDetails] = React.useState(false);

  const version = React.useMemo(() => {
    return isValidInteger(versionParam) ? Number(versionParam) : -1;
  }, [versionParam]);

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const showWorkloadDetails = React.useCallback(() => {
    history.replace(getPathToWorkloadTriggers(name, version));
  }, [name, version, history]);

  const triggerStateDetected = React.useMemo(() =>
      !Trigger.EMPTY.equals(new Trigger(triggerAttrs)),
    [triggerAttrs]);

  React.useEffect(() => {
    if (!initialized) {
      if (!triggerStateDetected) {
        setRedirectToWorkloadDetails(true);
      } else {
        setInitialized(true);
      }
    }
  }, [initialized, triggerStateDetected, setRedirectToWorkloadDetails, setInitialized]);

  React.useEffect(() => {
    if (redirectToWorkloadDetails) {
      showWorkloadDetails();
    }
  }, [redirectToWorkloadDetails, showWorkloadDetails]);

  if (!initialized) {
    return null;
  }

  return (
    <TriggerWizard
      name={name}
      version={version}
      isEdit={true}
      type={type}
      cancel={cancel}
      showWorkloadDetails={showWorkloadDetails}
    />
  );
}

export default EditTriggerView;
