import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { isUsersView, isUsersReviewView } from "../selectors";
import SelectUser from "./SelectUser";
import ReviewUsersView from "./ReviewUsersView";
import { Model, SelectSecurityGroupsView } from "../components/SelectSecurityGroupsView";

interface ContainerModel extends Model {
  showUsersView?: boolean;
  showUserReviewView?: boolean;
}

const SelectSecurityGroupViewContainer = (props: ContainerModel) => {
  const {
    showUsersView,
    showUserReviewView,
    ...otherProps
  } = props;

  return (
    <SelectSecurityGroupsView {...otherProps}>
      {showUsersView && <SelectUser/>}
      {showUserReviewView && <ReviewUsersView/>}
    </SelectSecurityGroupsView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  showUserReviewView: isUsersReviewView(state),
  showUsersView: isUsersView(state),
});

export default connect<ContainerModel, {}, ContainerModel>(
  mapStateToProps
)(SelectSecurityGroupViewContainer);
