import { IdentityType } from "@data";
import { combineReducers } from "redux";
import { DEFAULT_REDIRECT_URI } from "./constants";
import { Actions, Identity, filterAction } from "@util";

export interface LoginSchema {
  nonce: string;
  username: string;
  usernameError: string;
  account: string;
  accountError: string;
  password: string;
  passwordError: string;
  token: string;
  tokenError: string;
  showPassword: boolean;
  rememberMe: boolean;
  showCredentialsView: boolean;
  showTermsDialog: boolean;
  showLoadingIndicator: boolean;
  showErrorMessage: boolean;
  errorMessage: string;
  identityType: IdentityType;
  serviceId: string;
  serviceIdError: string;
  serviceAccountId: string;
  serviceAccountIdError: string;
  serviceSecret: string;
  serviceSecretError: string;
  mfaCode: string;
  mfaCodeError: string;
  rememberService: boolean;
  redirectUri: string;
}

export const DEFAULT_STATE: LoginSchema = Object.freeze({
  nonce: "",
  username: "",
  usernameError: "",
  account: "",
  accountError: "",
  password: "",
  passwordError: "",
  token: "",
  tokenError: "",
  showPassword: false,
  rememberMe: false,
  showCredentialsView: false,
  showTermsDialog: false,
  showLoadingIndicator: false,
  showErrorMessage: false,
  errorMessage: "",
  identityType: IdentityType.USER,
  serviceId: "",
  serviceIdError: "",
  serviceAccountId: "",
  serviceAccountIdError: "",
  serviceSecret: "",
  serviceSecretError: "",
  mfaCode: "",
  mfaCodeError: "",
  rememberService: false,
  redirectUri: DEFAULT_REDIRECT_URI,
});

export enum LoginActionType {
  SET_NONCE = "login/set_nonce",
  SET_USERNAME = "login/set_username",
  SET_USERNAME_ERROR = "login/set_username_error",
  SET_ACCOUNT = "login/set_account",
  SET_ACCOUNT_ERROR = "login/set_account_error",
  SET_PASSWORD = "login/set_password",
  SET_PASSWORD_ERROR = "login/set_password_error",
  SET_TOKEN = "login/set_token",
  SET_TOKEN_ERROR = "login/set_token_error",
  SET_SHOW_PASSWORD = "login/set_show_password",
  SET_REMEMBER_ME = "login/set_remember_me",
  SET_SHOW_CREDENTIALS_VIEW = "login/set_show_credentials_view",
  SET_SHOW_TERMS_DIALOG = "login/set_show_terms_dialog",
  SET_SHOW_LOADING_INDICATOR = "login/set_show_loading_indicator",
  SET_SHOW_ERROR_MESSAGE = "login/set_show_error_message",
  SET_ERROR_MESSAGE = "login/set_error_message",
  SET_IDENTITY_TYPE = "login/set_identity_type",
  SET_SERVICE_ID = "login/set_service_id",
  SET_SERVICE_ID_ERROR = "login/set_service_id_error",
  SET_SERVICE_ACCOUNT_ID = "login/set_service_account_id",
  SET_SERVICE_ACCOUNT_ID_ERROR = "login/set_service_account_id_error",
  SET_SERVICE_SECRET = "login/set_service_secret",
  SET_SERVICE_SECRET_ERROR = "login/set_service_secret_error",
  SET_MFA_CODE = "login/set_mfa_code",
  SET_MFA_CODE_ERROR = "login/set_mfa_code_error",
  SET_REMEMBER_SERVICE = "login/set_remember_service",
  REDIRECT_TO_FEDERATED_LOGIN_URL = "login/redirect_to_federated_login_url",
  FEDERATED_LOGIN_FAILED = "login/federated_login_failed",
  FETCH_OIDC_TOKENS_REQUEST = "login/fetch_oidc_tokens_request",
  FETCH_OIDC_TOKENS_SUCCESS = "login/fetch_oidc_tokens_success",
  FETCH_OIDC_TOKENS_FAILED = "login/fetch_oidc_tokens_failed",
  SECURITY_MANAGER_LOGIN_REQUEST = "login/security_manager_login_request",
  SECURITY_MANAGER_LOGIN_SUCCESS = "login/security_manager_login_success",
  SECURITY_MANAGER_LOGIN_FAILED = "login/security_manager_login_failed",
  SET_REDIRECT_URI = "login/set_redirect_uri",
}

const nonce = filterAction<string>(Actions(
  LoginActionType.SET_NONCE))(Identity,
  DEFAULT_STATE.nonce);

const username = filterAction<string>(Actions(
  LoginActionType.SET_USERNAME))(Identity,
  DEFAULT_STATE.username);

const usernameError = filterAction<string>(Actions(
  LoginActionType.SET_USERNAME_ERROR))(Identity,
  DEFAULT_STATE.usernameError);

const account = filterAction<string>(Actions(
  LoginActionType.SET_ACCOUNT))(Identity,
  DEFAULT_STATE.account);

const accountError = filterAction<string>(Actions(
  LoginActionType.SET_ACCOUNT_ERROR))(Identity,
  DEFAULT_STATE.accountError);

const password = filterAction<string>(Actions(
  LoginActionType.SET_PASSWORD))(Identity,
  DEFAULT_STATE.password);

const passwordError = filterAction<string>(Actions(
  LoginActionType.SET_PASSWORD_ERROR))(Identity,
  DEFAULT_STATE.passwordError);

const token = filterAction<string>(Actions(
  LoginActionType.SET_TOKEN))(Identity,
  DEFAULT_STATE.token);

const tokenError = filterAction<string>(Actions(
  LoginActionType.SET_TOKEN_ERROR))(Identity,
  DEFAULT_STATE.tokenError);

const showPassword = filterAction<boolean>(Actions(
  LoginActionType.SET_SHOW_PASSWORD))(Identity,
  DEFAULT_STATE.showPassword);

const rememberMe = filterAction<boolean>(Actions(
  LoginActionType.SET_REMEMBER_ME))(Identity,
  DEFAULT_STATE.rememberMe);

const showCredentialsView = filterAction<boolean>(Actions(
  LoginActionType.SET_SHOW_CREDENTIALS_VIEW))(Identity,
  DEFAULT_STATE.showCredentialsView);

const showTermsDialog = filterAction<boolean>(Actions(
  LoginActionType.SET_SHOW_TERMS_DIALOG))(Identity,
  DEFAULT_STATE.showTermsDialog);

const showLoadingIndicator = filterAction<boolean>(Actions(
  LoginActionType.SET_SHOW_LOADING_INDICATOR))(Identity,
  DEFAULT_STATE.showLoadingIndicator);

const showErrorMessage = filterAction<boolean>(Actions(
  LoginActionType.SET_SHOW_ERROR_MESSAGE))(Identity,
  DEFAULT_STATE.showErrorMessage);

const errorMessage = filterAction<string>(Actions(
  LoginActionType.SET_ERROR_MESSAGE))(Identity,
  DEFAULT_STATE.errorMessage);

const identityType = filterAction<IdentityType>(Actions(
  LoginActionType.SET_IDENTITY_TYPE))(Identity,
  DEFAULT_STATE.identityType);

const serviceId = filterAction<string>(Actions(
  LoginActionType.SET_SERVICE_ID))(Identity,
  DEFAULT_STATE.serviceId);

const serviceIdError = filterAction<string>(Actions(
  LoginActionType.SET_SERVICE_ID_ERROR))(Identity,
  DEFAULT_STATE.serviceIdError);

const serviceAccountId = filterAction<string>(Actions(
  LoginActionType.SET_SERVICE_ACCOUNT_ID))(Identity,
  DEFAULT_STATE.serviceAccountId);

const serviceAccountIdError = filterAction<string>(Actions(
  LoginActionType.SET_SERVICE_ACCOUNT_ID_ERROR))(Identity,
  DEFAULT_STATE.serviceAccountIdError);

const serviceSecret = filterAction<string>(Actions(
  LoginActionType.SET_SERVICE_SECRET))(Identity,
  DEFAULT_STATE.serviceSecret);

const serviceSecretError = filterAction<string>(Actions(
  LoginActionType.SET_SERVICE_SECRET_ERROR))(Identity,
  DEFAULT_STATE.serviceSecretError);

const mfaCode = filterAction<string>(Actions(
  LoginActionType.SET_MFA_CODE))(Identity,
  DEFAULT_STATE.mfaCode);

const mfaCodeError = filterAction<string>(Actions(
  LoginActionType.SET_MFA_CODE_ERROR))(Identity,
  DEFAULT_STATE.mfaCodeError);

const rememberService = filterAction<boolean>(Actions(
  LoginActionType.SET_REMEMBER_SERVICE))(Identity,
  DEFAULT_STATE.rememberService);

const redirectUri = filterAction<string>(Actions(
  LoginActionType.SET_REDIRECT_URI))(Identity,
  DEFAULT_STATE.redirectUri);

export const LoginReducer = combineReducers<LoginSchema>({
  nonce,
  username,
  usernameError,
  account,
  accountError,
  password,
  passwordError,
  token,
  tokenError,
  showPassword,
  rememberMe,
  showCredentialsView,
  showTermsDialog,
  showLoadingIndicator,
  showErrorMessage,
  errorMessage,
  identityType,
  serviceId,
  serviceIdError,
  serviceAccountId,
  serviceAccountIdError,
  serviceSecret,
  serviceSecretError,
  mfaCode,
  mfaCodeError,
  rememberService,
  redirectUri,
});

export default LoginReducer;
