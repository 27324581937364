import React from "react";
import { Location } from "history";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getPathToUser } from "@modules/userDetails/helpers";
import PasswordRecovery from "./containers/PasswordRecovery";

type LocationProps = {
  from?: Location;
  goBackOnSuccess?: boolean;
};

export function PasswordRecoveryView() {

  const history = useHistory();

  const { id: userId } = useParams<{ id: string }>();

  const { state: { from = undefined, goBackOnSuccess = false } = {} } = useLocation<LocationProps>();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  // If specified, go back, otherwise either replace this history entry
  // with the provided entry point or goto the details page
  const onSuccess = React.useCallback(() => {
    if (goBackOnSuccess) {
      history.goBack();
    } else if (from) {
      history.replace(from);
    } else {
      history.replace(getPathToUser(userId));
    }
  }, [history, from, goBackOnSuccess, userId]);

  return (
    <PasswordRecovery
      key={userId}
      cancel={cancel}
      onSuccess={onSuccess}
    />
  );
}

export default PasswordRecoveryView;
