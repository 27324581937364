import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      padding: 10,
      borderRadius: 6,
      border: "1px solid #000",
    },
  });

export default styles;
