import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      "& > .MuiDialog-container > .MuiDialog-paper": {
        maxWidth: 800,
      },
    },
    content: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    header: {
      fontSize: 24,
      fontWeight: 500,
      textDecoration: "underline",
      alignSelf: "flex-start",
    },
    title: {
      marginTop: 20,
      textAlign: "left",
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "32px",
      backgroundColor: Color.LIGHT_GREY6,
      border: `1px solid ${Color.GREY1}`,
      padding: "8px 16px",
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 16,
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
    textField: {
      marginTop: 25,
    },
  });

export default styles;
