import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";
import {
  CreateWorkloadRequestAttributes,
  Policy,
  PolicyAttributes,
  QueryWorkloadAttributes,
  WorkloadCompletionMetadata,
  WorkloadData,
  WorkloadDataSetsAttributes,
  WorkloadInternetAccessConfig,
  WorkloadQueryType,
  WorkloadRuntimeOption,
  WorkloadType,
  WorkloadVariableSchema,
} from "@data";

export interface CreateWorkloadRequestAttributesV3 extends CreateWorkloadRequestAttributes {
  workloadInternetAccessConfig?: WorkloadInternetAccessConfig;
}

export class CreateWorkloadRequestV3 extends Record({
  "@type": WorkloadType.FUNCTION,
  type: WorkloadType.FUNCTION,
  accountId: "",
  name: "",
  description: "",
  tags: [],
  data: {
    inputs: [],
    outputs: [],
  },
  entryPoint: "",
  timeout: 60,
  memory: 756,
  runtime: WorkloadRuntimeOption.NONE,
  policy: Policy.EMPTY.toJS(),
  query: {
    type: WorkloadQueryType.SELECT,
    query: "",
    databaseName: "",
    tableName: "",
    upload: false,
    overwrite: false,
  },
  completionMetadata: {
    actions: [],
    variableSchemas: [],
  },
  workloadInternetAccessConfig: {
    internetAccess: false,
    whitelistedDnsNames: [],
  },
}) implements CreateWorkloadRequestAttributesV3 {

  public static EMPTY: CreateWorkloadRequestV3 = new CreateWorkloadRequestV3();

  public readonly "@type": WorkloadType;
  public readonly type: WorkloadType;
  public readonly accountId: string;
  public readonly name: string;
  public readonly description: string;
  public readonly tags: string[];
  public readonly data: WorkloadData;
  public readonly entryPoint: string;
  public readonly timeout: number;
  public readonly memory: number;
  public readonly runtime: WorkloadRuntimeOption;
  public readonly policy: PolicyAttributes;
  public readonly query: QueryWorkloadAttributes;
  public readonly completionMetadata: WorkloadCompletionMetadata;
  public readonly workloadInternetAccessConfig: WorkloadInternetAccessConfig;

  public getType(): WorkloadType {
    return this.type;
  }

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getName(): string {
    return getStringValue(this.name);
  }

  public getDescription(): string {
    return this.description;
  }

  public getTags(): string[] {
    return Array.isArray(this.tags) ? this.tags : [];
  }

  public getData(): WorkloadData {
    const { inputs = [], outputs = [] } = this.data || {};
    return {
      inputs,
      outputs,
    };
  }

  public getDataInputs(): WorkloadDataSetsAttributes[] {
    const { inputs = [] } = this.getData();
    return inputs;
  }

  public getDataOutputs(): WorkloadDataSetsAttributes[] {
    const { outputs = [] } = this.getData();
    return outputs;
  }

  public getEntryPoint(): string {
    return this.entryPoint;
  }

  public getRuntime(): WorkloadRuntimeOption {
    return this.runtime;
  }

  public getPolicy(): Policy {
    return new Policy(this.policy);
  }

  public hasPolicy(): boolean {
    return this.getPolicy().isValid();
  }

  public getDatabaseName(): string {
    const { databaseName = "" } = this.query;
    return databaseName;
  }

  public getTableName(): string {
    const { tableName = "" } = this.query;
    return tableName;
  }

  public getQueryType(): WorkloadQueryType {
    const { type = WorkloadQueryType.SELECT } = this.query;
    return type;
  }

  public getQuery(): string {
    const { query = "" } = this.query;
    return query;
  }

  public isOverwrite(): boolean {
    const { overwrite = false } = this.query;
    return overwrite;
  }

  public isUpload(): boolean {
    const { upload = false } = this.query;
    return upload;
  }

  public isSelectQuery(): boolean {
    const { type = "" } = this.query;
    return this.type === WorkloadType.QUERY && type === WorkloadQueryType.SELECT;
  }

  public isInsertQuery(): boolean {
    const { type = "" } = this.query;
    return this.type === WorkloadType.QUERY && type === WorkloadQueryType.INSERT;
  }

  public isFunctionType(): boolean {
    return this.type === WorkloadType.FUNCTION;
  }

  public isQueryType(): boolean {
    return this.type === WorkloadType.QUERY;
  }

  public getTimeout(): number {
    return this.timeout;
  }

  public getTimeoutAsString(): string {
    return this.timeout + "s";
  }

  public getMemory(): number {
    return this.memory;
  }

  public getMemoryAsString(): string {
    return this.memory + "MB";
  }

  public getWorkloadCompletionMetadata(): WorkloadCompletionMetadata {
    const {
      actions = [],
      variableSchemas = [],
    } = this.completionMetadata || {};
    return {
      actions: Array.isArray(actions) ? actions : [],
      variableSchemas: Array.isArray(variableSchemas) ? variableSchemas : [],
    };
  }

  public getWorkloadCompletionMetadataActions(): string[] {
    const { actions = [] } = this.getWorkloadCompletionMetadata();
    return actions.map(getStringValue).filter(it => !isEmptyString(it));
  }

  public getWorkloadCompletionMetadataVariableSchemas(): WorkloadVariableSchema[] {
    const { variableSchemas = [] } = this.getWorkloadCompletionMetadata();
    return variableSchemas
      .map(attrs => new WorkloadVariableSchema(attrs))
      .filter(variableSchema => variableSchema.hasName());
  }

  public getInternetAccessConfig(): WorkloadInternetAccessConfig {
    return this.workloadInternetAccessConfig;
  }

  public hasInternetAccess(): boolean {
    return this.workloadInternetAccessConfig ? this.workloadInternetAccessConfig.internetAccess : false;
  }

  public getWhitelistedDNSNames(): string[] {
    return Array.isArray(this.workloadInternetAccessConfig.whitelistedDnsNames) ?
      this.workloadInternetAccessConfig.whitelistedDnsNames : [];
  }
}

export default CreateWorkloadRequestV3;
