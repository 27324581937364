import React from "react";
import classnames from "classnames";
import { isEmptyString } from "@util";
import { getBoundingClientRect, getInnerText } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import MuiTableCell, { TableCellProps as MuiTableCellProps } from "@material-ui/core/TableCell";
import styles from "./styles";

export interface PaginatedListTableCellProps<Item = any> extends MuiTableCellProps {
  className?: string;
  item?: any;
  placeholder?: string;
  placeholderClassName?: string;
  placeholderEnabled?: boolean;
  placeholderEl?: React.ReactNode;
}

type Props<Item = any> = WithStyles<typeof styles> & PaginatedListTableCellProps<Item> & {
  children?: React.ReactNode;
};

export const PaginatedListTableCell = withStyles(styles)(<Item, >(props: Props<Item>) => {

  const {
    classes,
    className,
    item,
    placeholder = "-",
    placeholderClassName,
    placeholderEnabled = true,
    placeholderEl = !placeholderEnabled ? null : (
      <label className={classnames("placeholder", placeholderClassName, classes.placeholder)}>
        {placeholder}
      </label>
    ),
    children,
    ...otherProps
  } = props;

  const placeholderSupported = React.useMemo(() =>
      placeholderEnabled && placeholderEl !== null,
    [placeholderEnabled, placeholderEl]);

  const [showPlaceholder, setShowPlaceholder] = React.useState(false);

  const ref = React.useRef<HTMLTableCellElement | null>(null);

  React.useEffect(() => {
    if (placeholderSupported && ref.current !== null) {
      const innertText = getInnerText(ref.current);
      if (isEmptyString(innertText) || (innertText.indexOf(placeholder) === 0)) {
        // If there is no child element, we can safely show the placeholder
        //
        // NOTE: lastElementChild === firstElementChild if there is only a single dom node child;
        //       however, when the placeholder is visible, it will be the firstElementChild, and
        //       the lastElementChild will refer to the actual table cell content, which is why
        //       we prefer to use lastElementChild when checking dimensions to see if it is empty.
        if (!ref.current.lastElementChild) {
          setShowPlaceholder(true);
        } else {
          // Otherwise, we need to verify there isn't something like an image that
          // would not have text, but would still have visible dimensions in DOM
          const { width, height } = getBoundingClientRect(ref.current.lastElementChild);
          setShowPlaceholder(width === 0 || height === 0);
        }
      }
    }
  }, [ref, item, placeholderSupported, setShowPlaceholder]);

  return (
    <MuiTableCell
      ref={ref}
      className={classnames("paginatedListTableCell", className, classes.container)}
      {...otherProps}
    >
      {showPlaceholder && placeholderEl}
      {children}
    </MuiTableCell>
  );
});

export default PaginatedListTableCell;
