import { DeviceEnrollment } from "@data";
import { Comparator, compare } from "@util";
import { DevicesListColumn } from "./DevicesListColumn";

const compareByDeviceId = (item: DeviceEnrollment, otherItem: DeviceEnrollment, ascending: boolean) =>
  compare(item.getDeviceId(), otherItem.getDeviceId(), ascending);

const compareByStatus = (item: DeviceEnrollment, otherItem: DeviceEnrollment, ascending: boolean) =>
  compare(item.status, otherItem.status, ascending);

const compareByStatusMessage = (item: DeviceEnrollment, otherItem: DeviceEnrollment, ascending: boolean) =>
  compare(item.statusMessage, otherItem.statusMessage, ascending);

export const createComparator = (sortedColumn: DevicesListColumn, ascending: boolean): Comparator<DeviceEnrollment> =>
  (item: DeviceEnrollment, otherItem: DeviceEnrollment) => {
    switch (sortedColumn) {
      case DevicesListColumn.DEVICE_ID:
        return compareByDeviceId(item, otherItem, ascending);
      case DevicesListColumn.STATUS:
        return compareByStatus(item, otherItem, ascending);
      case DevicesListColumn.STATUS_MESSAGE:
        return compareByStatusMessage(item, otherItem, ascending);
      default:
        return 0;
    }
  };

export default createComparator;
