import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      position: "relative",
      minHeight: 60,
    },
    icon: {
      width: 48,
      height: 48,
      color: Color.SIGNIFY_GREEN,
    },
    label: {
      color: Color.TEXT,
      fontSize: 24,
      fontWeight: 300,
      margin: "0 0 0 8px",
      padding: 0,
    },
  });

export default styles;
