import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface DownloadFileModel {
  className?: string;
  href?: string;
  fileName?: string;
  downloadFinishedDelay?: number;
}

export interface DownloadFileActions {
  downloadStarted?: () => void;
  downloadFinished?: () => void;
}

export type DownloadFileProps = DownloadFileModel & DownloadFileActions;

type Props = WithStyles<typeof styles> & DownloadFileProps;

export const DownloadFile = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    href = "",
    fileName = "",
    downloadFinishedDelay = 1000,
    downloadStarted = noop,
    downloadFinished = noop,
  } = props;

  const ref = React.useRef<HTMLAnchorElement>(null);

  const isValidUrl = React.useMemo(() => !isEmptyString(href), [href]);

  React.useEffect(() => {
    if (isValidUrl) {
      downloadStarted();
    }
  }, [isValidUrl, downloadStarted]);

  React.useEffect(() => {

    const anchor: HTMLAnchorElement | null = ref.current;

    if (isValidUrl && anchor !== null) {
      anchor.click();
      anchor.href = "";
      anchor.download = "";
      return () => setTimeout(downloadFinished, downloadFinishedDelay);
    }

    return noop;

  }, [ref, isValidUrl, downloadFinished, downloadFinishedDelay]);

  if (!isValidUrl) {
    return null;
  }

  return (
    <a
      ref={ref}
      className={classnames("downloadFile", className, classes.anchor)}
      href={href}
      download={fileName}
    />
  );
});

export default DownloadFile;
