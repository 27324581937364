import { Record } from "immutable";
import { isValidInteger } from "../util";
import {
  JsonSchemaNumberConstraintsAttributes,
  JsonSchemaPropertiesDefinition,
  JsonSchemaPropertyType,
  NumberValidationData,
} from "../data";

// JsonSchema Integer Property Type - A subset of the Number Property Type
//
// Reference Material:
//  - https://json-schema.org/understanding-json-schema/reference/numeric.html#integer
//  - https://docs.opis.io/json-schema/1.x/integer.html
export type JsonSchemaIntegerConstraintsAttributes = JsonSchemaNumberConstraintsAttributes;

export type IntegerValidationData = NumberValidationData;

export class JsonSchemaIntegerConstraints extends Record({
  minimum: NaN,
  exclusiveMinimum: NaN,
  maximum: NaN,
  exclusiveMaximum: NaN,
  multipleOf: NaN,
}) implements JsonSchemaIntegerConstraintsAttributes {

  public static EMPTY: JsonSchemaIntegerConstraints = new JsonSchemaIntegerConstraints();

  public readonly minimum: number;
  public readonly exclusiveMinimum: number;
  public readonly maximum: number;
  public readonly exclusiveMaximum: number;
  public readonly multipleOf: number;

  public static from(property: JsonSchemaPropertiesDefinition = {}): JsonSchemaIntegerConstraints {

    const {
      type,
      minimum,
      exclusiveMinimum,
      maximum,
      exclusiveMaximum,
      multipleOf,
    } = property;

    if (JsonSchemaPropertyType.INTEGER !== type) {
      return JsonSchemaIntegerConstraints.EMPTY;
    }

    const attrs = {
      ...(!isValidInteger(minimum) ? {} : { minimum: Number(minimum) }),
      ...(!isValidInteger(exclusiveMinimum) ? {} : { exclusiveMinimum: Number(exclusiveMinimum) }),
      ...(!isValidInteger(maximum) ? {} : { maximum: Number(maximum) }),
      ...(!isValidInteger(exclusiveMaximum) ? {} : { exclusiveMaximum: Number(exclusiveMaximum) }),
      ...(!isValidInteger(multipleOf) ? {} : { multipleOf: Number(multipleOf) }),
    };

    return new JsonSchemaIntegerConstraints(attrs);
  }

  public hasMinimum(): boolean {
    return isValidInteger(this.minimum);
  }

  public hasExclusiveMinimum(): boolean {
    return isValidInteger(this.exclusiveMinimum);
  }

  public hasMaximum(): boolean {
    return isValidInteger(this.maximum);
  }

  public hasExclusiveMaximum(): boolean {
    return isValidInteger(this.exclusiveMaximum);
  }

  public hasMultipleOf(): boolean {
    return isValidInteger(this.multipleOf);
  }

  public getValidationData(): IntegerValidationData {

    const data: IntegerValidationData = { ...this.toJS() };

    if (!this.hasMinimum()) {
      delete data.minimum;
    }

    if (!this.hasExclusiveMinimum()) {
      delete data.exclusiveMinimum;
    }

    if (!this.hasMaximum()) {
      delete data.maximum;
    }

    if (!this.hasExclusiveMaximum()) {
      delete data.exclusiveMaximum;
    }

    if (!this.hasMultipleOf()) {
      delete data.multipleOf;
    }

    return data;
  }
}
