import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";
import { AccessRequestApprovalDetail, AccessRequestApprovalDetailAttributes } from "@data/AccessRequestApprovalDetail";
import { RequestState } from "@data/DataApprovalRequest";

export enum AccessRequestType {
  NONE = "",
  READ = "READ",
  WRITE = "WRITE",
  READ_WRITE = "READ_WRITE",
}

export enum AccessRequestPrincipalType {
  IOT_USER_PRINCIPAL = "IOT_USER_PRINCIPAL",
  IOT_SERVICE_PRINCIPAL = "IOT_SERVICE_PRINCIPAL",
  IOT_CLOUD_ROLE = "IOT_CLOUD_ROLE",
  IOT_CLOUD_USER = "IOT_CLOUD_USER",
  IOT_DEVICE_TYPE = "IOT_DEVICE_TYPE",
  IOT_CLOUD_STORAGE = "IOT_CLOUD_STORAGE"
}

export interface DataAccessRequestAttributes {
  accessRequestApprovalDetails?: AccessRequestApprovalDetailAttributes[];
  accessRequestId: string;
  accessRequestPrincipalType: string;
  accessRequestType: AccessRequestType;
  accountId: string;
  dataSetAlias: string;
  reason?: string;
  requestState: RequestState;
  requestedAtTime: string;
  requestedBy?: string;
  servicePrincipalIdentifier: string | undefined;
  deviceType: string | undefined;
  deviceNamespace: string | undefined;
  dataSetGroup: string | undefined;
  isAccessForExistingCloudResource: boolean | undefined;
  trustedAccountsForCloudRole: string[] | undefined;
  cloudRoleIdentifier: string | undefined;
  cloudStorageIdentifier: string | undefined;
}

export class DataAccessRequest extends Record({
  accessRequestApprovalDetails: [],
  accessRequestId: "",
  accessRequestPrincipalType: AccessRequestPrincipalType.IOT_USER_PRINCIPAL,
  accessRequestType: AccessRequestType.READ,
  accountId: "",
  dataSetAlias: "",
  reason: "",
  requestState: RequestState.PENDING,
  requestedAtTime: "",
  requestedBy: "",
  servicePrincipalIdentifier: undefined,
  deviceType: undefined,
  deviceNamespace: undefined,
  dataSetGroup: undefined,
  isAccessForExistingCloudResource: undefined,
  trustedAccountsForCloudRole: undefined,
  cloudRoleIdentifier: undefined,
  cloudStorageIdentifier: undefined,
}) implements DataAccessRequestAttributes {

  public static EMPTY: DataAccessRequest = new DataAccessRequest();

  public readonly accessRequestApprovalDetails: AccessRequestApprovalDetailAttributes[];
  public readonly accessRequestId: string;
  public readonly accessRequestPrincipalType: AccessRequestPrincipalType;
  public readonly accessRequestType: AccessRequestType;
  public readonly accountId: string;
  public readonly dataSetAlias: string;
  public readonly reason: string;
  public readonly requestState: RequestState;
  public readonly requestedAtTime: string;
  public readonly requestedBy: string;
  public readonly servicePrincipalIdentifier: string | undefined;
  public readonly deviceType: string | undefined;
  public readonly deviceNamespace: string | undefined;
  public readonly dataSetGroup: string | undefined;
  public readonly isAccessForExistingCloudResource: boolean | undefined;
  public readonly trustedAccountsForCloudRole: string[] | undefined;
  public readonly cloudRoleIdentifier: string | undefined;
  public readonly cloudStorageIdentifier: string | undefined;

  public getAccessRequestApprovalDetails(): AccessRequestApprovalDetail[] {
    return (Array.isArray(this.accessRequestApprovalDetails) ? this.accessRequestApprovalDetails : [])
      .map((attrs: AccessRequestApprovalDetailAttributes) => new AccessRequestApprovalDetail(attrs));
  }

  public getAccessRequestId(): string {
    return getStringValue(this.accessRequestId);
  }

  public getAccessRequestPrincipalType(): AccessRequestPrincipalType {
    return this.accessRequestPrincipalType;
  }

  public getAccessRequestType(): AccessRequestType {
    return this.accessRequestType;
  }

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getDataSetAlias(): string {
    return getStringValue(this.dataSetAlias);
  }

  public getReason(): string {
    return getStringValue(this.reason);
  }

  public getRequestState(): RequestState {
    return this.requestState;
  }

  public getRequestedAtTime(): string {
    return getStringValue(this.requestedAtTime);
  }

  public getCreatedAt(): string {
    return this.getRequestedAtTime();
  }

  public getRequestedBy(): string {
    return getStringValue(this.requestedBy);
  }

  public isServicePrincipalRequest(): boolean {
    return this.getAccessRequestPrincipalType() === AccessRequestPrincipalType.IOT_SERVICE_PRINCIPAL;
  }

  public getServicePrincipalIdentifier(): string {
    return getStringValue(this.servicePrincipalIdentifier);
  }

  public getDeviceType(): string {
    return getStringValue(this.deviceType);
  }

  public getDeviceNamespace(): string {
    return getStringValue(this.deviceNamespace);
  }

  public getDataSetGroup(): string {
    return getStringValue(this.dataSetGroup);
  }

  public hasServicePrincipalIdentifier(): boolean {
    return !isEmptyString(this.getServicePrincipalIdentifier());
  }

  public isDataAccessRequestForExistingCloudResource(): boolean {
    return this.isAccessForExistingCloudResource === true;
  }

  public isIotCloudRole(): boolean {
    return this.getAccessRequestPrincipalType() === AccessRequestPrincipalType.IOT_CLOUD_ROLE;
  }

  public isIotCloudStorage(): boolean {
    return this.getAccessRequestPrincipalType() === AccessRequestPrincipalType.IOT_CLOUD_STORAGE;
  }

  public getTrustedAccountsForCloudRole(): string[] {
    const trustedAccountsForCloudRole = this.trustedAccountsForCloudRole;

    if (!Array.isArray(trustedAccountsForCloudRole)) {
      return [];
    }

    return trustedAccountsForCloudRole.map(getStringValue).filter(id => !isEmptyString(id));
  }

  public hasTrustedAccountsForCloudRole(): boolean {
    return this.getTrustedAccountsForCloudRole().length > 0;
  }

  public getCloudRoleIdentifier(): string {
    return getStringValue(this.cloudRoleIdentifier);
  }

  public hasCloudRoleIdentifier(): boolean {
    return !isEmptyString(this.getCloudRoleIdentifier());
  }

  public getCloudStorageIdentifier(): string {
    return getStringValue(this.cloudStorageIdentifier);
  }

  public hasCloudStorageIdentifier(): boolean {
    return !isEmptyString(this.getCloudStorageIdentifier());
  }
}

export default DataAccessRequest;
