import React from "react";
import {
  usePaginatedApiRequest,
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
  useNamespaceClient,
} from "@hooks";
import { GetNamespacesResponse } from "@network";
import { Namespace } from "@data";

type SuccessResponse = GetNamespacesResponse;

export interface UseNamespacesProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
  namespaces?: Namespace[];
}

export interface UseNamespacesModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  namespaces: Namespace[];
}

export interface UseNamespacesActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseNamespacesProps;
type Model = UseNamespacesModel;
type Actions = UseNamespacesActions;
type Result = [Model, Actions];

export const useNamespaces = (props: Props = {}): Result => {

  const {
    namespaces: initialNamespaces = [],
    defaultErrorMessage = "Failed to get namespaces",
    ...otherProps
  } = props;

  const NamespaceClient = useNamespaceClient();

  const [namespaces, setNamespaces] = React.useState<Namespace[]>(initialNamespaces);

  const makeApiRequest = React.useCallback((accessToken, next) =>
      NamespaceClient.getNamespaces(next),
    [NamespaceClient]);

  const [
    {successResponse, ...baseModel},
    {reset: baseReset, refresh: baseRefresh, ...baseActions},
  ] = usePaginatedApiRequest<SuccessResponse>({
    ...otherProps,
    defaultErrorMessage,
    makeApiRequest,
  });

  const updatedNamespaces = React.useMemo(() => {
    const { entries: items = [] } = successResponse || {};
    return items.map(attrs => new Namespace(attrs));
  }, [successResponse]);

  const reset = React.useCallback(() => {
    setNamespaces([]);
    baseReset();
  }, [setNamespaces, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successResponse,
    namespaces,
  }), [
    baseModel,
    successResponse,
    namespaces,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    setNamespaces(currentNamespaces => currentNamespaces.concat(updatedNamespaces));
  }, [updatedNamespaces, setNamespaces]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useNamespaces;
