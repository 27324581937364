import React from "react";
import { DSTypeInfo } from "@data";
import classnames from "classnames";
import { SummaryViewLabel } from "@components/audit-events-list/AuditEventListItemExpanded/SummaryViewLabel";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface DSTypeListItemExpandedModel {
  className?: string;
  type?: DSTypeInfo;
}

export interface DSTypeListItemExpandedActions {
}

type Model = DSTypeListItemExpandedModel;
type Actions = DSTypeListItemExpandedActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const DSTypeListItemExpanded = React.memo(withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    type = DSTypeInfo.EMPTY,
  } = props;

  const value = React.useMemo(() => {
    if ( Object.keys(type.getMetadata()).length > 0 ) {
      return JSON.stringify(type.getMetadata(), null, "  ");
    } else {
      return "No Metadata available for this type. Edit type to add metadata";
    }
  }, [type]);

  return (
    <div className={classnames("dsTypeListItemExpanded", className, classes.container)}>
      <div className={classnames(classes.summaryView, classes.jsonView)}>
        <SummaryViewLabel
          className={classnames("metadata", classes.metadata)}
          nameClassName={classnames("summaryViewLabelName", classes.jsonLabel)}
          valueClassName={classnames("summaryViewLabelValue", classes.jsonValue)}
          value={value}
          name="Metadata"
        />
      </div>
    </div>
  );
}));

export default DSTypeListItemExpanded;
