import { UserAdministrationAttributes } from "@data";
import { OrchestrationServiceClient } from "@network";
import { useApiRequestAnalytics } from "@hooks";

export type CloseUserAccountApi =
  (userId: string, selfAuthorized: boolean) => Promise<void>;

export type GetUserAdminInfoApi =
  (userId: string) => Promise<UserAdministrationAttributes>;

export type UpdateUserMfaRequirementApi =
  (userId: string, json: string) => Promise<void>;

export type DeleteAuthenticatorApi =
  (userId: string, authenticatorId: string) => Promise<void>;

export interface OrchestrationServiceClientApi {
  closeUserAccount: CloseUserAccountApi;
  getUserAdminInfo: GetUserAdminInfoApi;
  updateUserMfaRequirement: UpdateUserMfaRequirementApi;
  deleteAuthenticatorApi: DeleteAuthenticatorApi;
}

enum ActionType {
  CLOSE_USER_ACCOUNT_REQUEST = "orchestration_service_client/close_user_account_request",
  CLOSE_USER_ACCOUNT_SUCCESS = "orchestration_service_client/close_user_account_success",
  CLOSE_USER_ACCOUNT_FAILED = "orchestration_service_client/close_user_account_failed",

  GET_USER_ADMIN_INFO_REQUEST = "orchestration_service_client/get_user_admin_info_request",
  GET_USER_ADMIN_INFO_SUCCESS = "orchestration_service_client/get_user_admin_info_success",
  GET_USER_ADMIN_INFO_FAILED = "orchestration_service_client/get_user_admin_info_failed",

  UPDATE_USER_MFA_REQUIREMENT_REQUEST = "orchestration_service_client/update_user_mfa_requirement_request",
  UPDATE_USER_MFA_REQUIREMENT_SUCCESS = "orchestration_service_client/update_user_mfa_requirement_success",
  UPDATE_USER_MFA_REQUIREMENT_FAILED = "orchestration_service_client/update_user_mfa_requirement_failed",

  DELETE_AUTHENTICATOR_REQUEST = "orchestration_service_client/delete_authenticator_request",
  DELETE_AUTHENTICATOR_SUCCESS = "orchestration_service_client/delete_authenticator_success",
  DELETE_AUTHENTICATOR_FAILED = "orchestration_service_client/delete_authenticator_failed",
}

export const useCloseUserAccountApi = (): CloseUserAccountApi =>
  useApiRequestAnalytics(OrchestrationServiceClient.closeUserAccount, {
    REQUEST_EVENT: ActionType.CLOSE_USER_ACCOUNT_REQUEST,
    SUCCESS_EVENT: ActionType.CLOSE_USER_ACCOUNT_SUCCESS,
    ERROR_EVENT: ActionType.CLOSE_USER_ACCOUNT_FAILED
  });

export const useGetUserAdminInfoApi = (): GetUserAdminInfoApi =>
  useApiRequestAnalytics(OrchestrationServiceClient.getUserAdminInfo, {
    REQUEST_EVENT: ActionType.GET_USER_ADMIN_INFO_REQUEST,
    SUCCESS_EVENT: ActionType.GET_USER_ADMIN_INFO_SUCCESS,
    ERROR_EVENT: ActionType.GET_USER_ADMIN_INFO_FAILED
  });

export const useUpdateUserMfaRequirementApi = (): UpdateUserMfaRequirementApi =>
  useApiRequestAnalytics(OrchestrationServiceClient.updateUserMfaRequirement, {
    REQUEST_EVENT: ActionType.UPDATE_USER_MFA_REQUIREMENT_REQUEST,
    SUCCESS_EVENT: ActionType.UPDATE_USER_MFA_REQUIREMENT_SUCCESS,
    ERROR_EVENT: ActionType.UPDATE_USER_MFA_REQUIREMENT_FAILED
  });

export const useDeleteAuthenticatorApi = (): DeleteAuthenticatorApi =>
  useApiRequestAnalytics(OrchestrationServiceClient.deleteAuthenticator, {
    REQUEST_EVENT: ActionType.DELETE_AUTHENTICATOR_REQUEST,
    SUCCESS_EVENT: ActionType.DELETE_AUTHENTICATOR_SUCCESS,
    ERROR_EVENT: ActionType.DELETE_AUTHENTICATOR_FAILED
  });

export const useOrchestrationServiceClient = (): OrchestrationServiceClientApi => ({
  closeUserAccount: useCloseUserAccountApi(),
  getUserAdminInfo: useGetUserAdminInfoApi(),
  updateUserMfaRequirement: useUpdateUserMfaRequirementApi(),
  deleteAuthenticatorApi: useDeleteAuthenticatorApi(),
});

export { ActionType as OrchestrationServiceClientActionType };

export default useOrchestrationServiceClient;
