import createStyles from "@material-ui/core/styles/createStyles";
import { Color } from "@Color";

export const primaryColor = Color.MODULES;

export const listItem = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "center",
    },
    label: {
      color: Color.TEXT,
      cursor: "default",
      fontFamily: "Roboto, sans-serif",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "16px",
      textTransform: "none",
      wordBreak: "break-word",
      marginRight: 8,
    },
  });
