import { isEmptyString, isValidInteger } from "@util";
import { ReleaseNoteAttributes, ReleaseNote } from "@data";
import {
  createQueryParams,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments,
} from "@network/helpers";

const RELEASE_NOTES_API = process.env.REACT_APP_PORTAL_RELEASE_NOTES_API || "";
const DEFAULT_LIMIT = 25;

if (isEmptyString(RELEASE_NOTES_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_PORTAL_RELEASE_NOTES_API");
}

export interface ListReleaseNotesResponse {
  releaseNotes: ReleaseNoteAttributes[];
  paging: {
    next?: string;
  };
}

export const listReleaseNotes = (authToken: string,
                                 platformDomain: string,
                                 releaseStatus: string = "",
                                 next: string = "",
                                 limit: number = DEFAULT_LIMIT): Promise<ListReleaseNotesResponse> => {

  const validate = () => withAuthToken(authToken);

  const queryParams = createQueryParams({
    platformDomain,
    releaseStatus,
    next,
    ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, Math.min(DEFAULT_LIMIT, limit)) }),
  });

  const makeRequest = () => {

    const url = `${RELEASE_NOTES_API}/portal/platform-administration/v1/release-notes/${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch release notes failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getReleaseNote = (authToken: string,
                               releaseId: string): Promise<ReleaseNoteAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Release Note ID", releaseId],
    ]));

  const makeRequest = () => {

    const url = `${RELEASE_NOTES_API}/portal/platform-administration/v1/release-notes/${releaseId}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch release note failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage)
      .then((attrs: ReleaseNoteAttributes) => {
        const releaseNote = new ReleaseNote(attrs);
        if (!releaseNote.isPublished()) {
          return Promise.reject({
            status: 404,
            message: "Release Note Not Found",
            description: "Release Note Not Found",
            error: `Release Note '${releaseId}' Not Found`,
            analytic: "404:ReleaseNoteNotFound",
          });
        } else {
          return attrs;
        }
      });
  };

  return validate().then(makeRequest);
};

export const getReleaseNoteByDomainAndVersion = (authToken: string,
                                                 domain: string,
                                                 version: string): Promise<ReleaseNoteAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Release Note Domain", domain],
      ["Release Note Version", version],
    ]));

  const makeRequest = () => {

    const url = `${RELEASE_NOTES_API}/portal/platform-administration/v1/release-notes/domain/${domain}/v/${version}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch release note failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage)
      .then((attrs: ReleaseNoteAttributes) => {
        const releaseNote = new ReleaseNote(attrs);
        if (!releaseNote.isPublished()) {
          return Promise.reject({
            status: 404,
            message: "Release Note Not Found",
            description: "Release Note Not Found",
            error: `Release Note Not Found`,
            analytic: "404:ReleaseNoteNotFound",
          });
        } else {
          return attrs;
        }
      });
  };

  return validate().then(makeRequest);
};
