import React from "react";
import usePaginatedApiRequest, {
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
} from "@hooks/use-paginated-api-request";
import ReleaseNote, { ReleaseNotePlatformDomain, ReleaseStatus } from "@data/ReleaseNote";
import { ListReleaseNotesResponse } from "@network/PortalReleaseNotesClient";
import usePortalReleaseNotesClient from "../use-portal-release-notes-client";

type SuccessResponse = ListReleaseNotesResponse;

export interface UseListReleaseNotesProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
  platformDomain?: ReleaseNotePlatformDomain;
  releaseStatus?: ReleaseStatus;
  limit?: number;
}

export interface UseListReleaseNotesModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  releaseNotes: ReleaseNote[];
}

export interface UseListReleaseNotesActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseListReleaseNotesProps;
type Model = UseListReleaseNotesModel;
type Actions = UseListReleaseNotesActions;
type Result = [Model, Actions];

export const useListReleaseNotes = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to list release notes",
    platformDomain = ReleaseNotePlatformDomain.NONE,
    releaseStatus = ReleaseStatus.NONE,
    limit,
    ...otherProps
  } = props;

  const PortalReleaseNotesClient = usePortalReleaseNotesClient();

  const [releaseNotes, setReleaseNotes] = React.useState<ReleaseNote[]>([]);

  const makeApiRequest = React.useCallback((accessToken, next) =>
      PortalReleaseNotesClient.listReleaseNotes(platformDomain, releaseStatus, next, limit),
    [PortalReleaseNotesClient, platformDomain, releaseStatus, limit]);

  const [{ successResponse, ...baseModel }, {
    reset: baseReset,
    refresh: baseRefresh,
    ...baseActions
  }] =
    usePaginatedApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const updatedReleaseNotes = React.useMemo<ReleaseNote[]>(() => {
    const { releaseNotes: items = [] } = successResponse || {};
    return items.map(attrs => new ReleaseNote(attrs));
  }, [successResponse]);

  const reset = React.useCallback(() => {
    setReleaseNotes([]);
    baseReset();
  }, [setReleaseNotes, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    releaseNotes,
  }), [
    baseModel,
    releaseNotes,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    refresh();
  }, [
    refresh,
    platformDomain,
    releaseStatus,
    limit,
  ]);

  React.useEffect(() => {
    setReleaseNotes(currentReleaseNotes => currentReleaseNotes.concat(updatedReleaseNotes));
  }, [updatedReleaseNotes, setReleaseNotes]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useListReleaseNotes;
