import React from "react";
import classnames from "classnames";
import { dataLakeView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DataLakeFileBrowser, { DataLakeFileBrowserModel, DataLakeFileBrowserActions } from "@components/data-lake-file-browser";

export interface Model extends DataLakeFileBrowserModel {
}

export interface Actions extends DataLakeFileBrowserActions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DataLakeView = withStyles(styles)((props: Props) => {

  const { classes, className, ...otherProps } = props;

  return (
    <div className={classnames("dataLakeView", className, classes.container)}>
      <DataLakeFileBrowser
        {...otherProps}
        className={classnames("dataLakeFileBrowser", classes.dataLakeFileBrowser)}
      />
    </div>
  );
});

export default DataLakeView;
