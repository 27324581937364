import { Record } from "immutable";

interface WorkloadCloneCodeRequestAttributes {
  name: string;
  version: number;
  sourceName: string;
  sourceVersion: number;
}

export class WorkloadCloneCodeRequest extends Record({
  name: "",
  version: 1,
  sourceName: "",
  sourceVersion: 1,
}) implements WorkloadCloneCodeRequestAttributes {

  public static EMPTY: WorkloadCloneCodeRequest = new WorkloadCloneCodeRequest();

  public readonly name: string;
  public readonly version: number;
  public readonly sourceName: string;
  public readonly sourceVersion: number;

  public getName(): string {
    return this.name;
  }

  public getVersion(): number {
    return this.version;
  }

  public getSourceName(): string {
    return this.sourceName;
  }

  public getSourceVersion(): number {
    return this.sourceVersion;
  }
}
