export enum PolicyOperation {
  ATTACH = "attach",
  DETACH = "detach"
}

export enum PolicyOperationPrincipal {
  SERVICE = "service",
  USER = "user",
  GROUP = "group",
  DEVICE = "device",
  APPLICATION = "application"
}
