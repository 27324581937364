import {
  DigitalShadowClient,
  ETagHeaders,
  ETagLocationHeaders,
  GetDSLayoutResponse,
  GetDSTypeResponse,
  ListLayoutsTypesResponse,
  ListTypesResponse,
} from "@network";
import { useApiRequestAnalytics } from "@hooks";

export type ListTypesApi = (next?: string, limit?: number) => Promise<ListTypesResponse>;

export type GetTypeApi = (name: string) => Promise<GetDSTypeResponse>;

export type CreateTypeApi = (json: string) => Promise<ETagLocationHeaders>;

export type UpdateTypeApi = (name: string, json: string, etag: string) => Promise<ETagHeaders>;

export type DeleteTypeApi = (name: string, etag: string) => Promise<void>;

export type ListLayoutsApi = (next?: string, limit?: number) => Promise<ListLayoutsTypesResponse>;

export type GetLayoutApi = (name: string) => Promise<GetDSLayoutResponse>;

export type CreateLayoutApi = (json: string) => Promise<ETagHeaders>;

export type UpdateLayoutApi = (name: string, json: string, etag: string) => Promise<ETagHeaders>;

export type DeleteLayoutApi = (name: string, etag: string) => Promise<void>;

export interface DigitalShadowClientApi {
  listTypesApi: ListTypesApi;
  getTypeApi: GetTypeApi;
  createTypeApi: CreateTypeApi;
  updateTypeApi: UpdateTypeApi;
  deleteTypeApi: DeleteTypeApi;
  listLayoutsApi: ListLayoutsApi;
  getLayoutApi: GetLayoutApi;
  createLayoutApi: CreateTypeApi;
  updateLayoutApi: UpdateTypeApi;
  deleteLayoutApi: DeleteTypeApi;
}

enum ActionType {
  LIST_DS_TYPES_REQUEST = "digital_shadow_client/list_ds_types_request",
  LIST_DS_TYPES_SUCCESS = "digital_shadow_client/list_ds_types_success",
  LIST_DS_TYPES_FAILED = "digital_shadow_client/list_ds_types_failed",

  GET_DS_TYPE_REQUEST = "digital_shadow_client/get_ds_type_request",
  GET_DS_TYPE_SUCCESS = "digital_shadow_client/get_ds_type_success",
  GET_DS_TYPE_FAILED = "digital_shadow_client/get_ds_type_failed",

  CREATE_DS_TYPE_REQUEST = "digital_shadow_client/create_ds_type_request",
  CREATE_DS_TYPE_SUCCESS = "digital_shadow_client/create_ds_type_success",
  CREATE_DS_TYPE_FAILED = "digital_shadow_client/create_ds_type_failed",

  UPDATE_DS_TYPE_REQUEST = "digital_shadow_client/update_ds_type_request",
  UPDATE_DS_TYPE_SUCCESS = "digital_shadow_client/update_ds_type_success",
  UPDATE_DS_TYPE_FAILED = "digital_shadow_client/update_ds_type_failed",

  DELETE_DS_TYPE_REQUEST = "digital_shadow_client/delete_ds_type_request",
  DELETE_DS_TYPE_SUCCESS = "digital_shadow_client/delete_ds_type_success",
  DELETE_DS_TYPE_FAILED = "digital_shadow_client/delete_ds_type_failed",

  LIST_DS_LAYOUTS_REQUEST = "digital_shadow_client/list_ds_layouts_request",
  LIST_DS_LAYOUTS_SUCCESS = "digital_shadow_client/list_ds_layouts_success",
  LIST_DS_LAYOUTS_FAILED = "digital_shadow_client/list_ds_layouts_failed",

  GET_DS_LAYOUT_REQUEST = "digital_shadow_client/get_ds_layout_request",
  GET_DS_LAYOUT_SUCCESS = "digital_shadow_client/get_ds_layout_success",
  GET_DS_LAYOUT_FAILED = "digital_shadow_client/get_ds_layout_failed",

  CREATE_DS_LAYOUT_REQUEST = "digital_shadow_client/create_ds_layout_request",
  CREATE_DS_LAYOUT_SUCCESS = "digital_shadow_client/create_ds_layout_success",
  CREATE_DS_LAYOUT_FAILED = "digital_shadow_client/create_ds_layout_failed",

  UPDATE_DS_LAYOUT_REQUEST = "digital_shadow_client/update_ds_layout_request",
  UPDATE_DS_LAYOUT_SUCCESS = "digital_shadow_client/update_ds_layout_success",
  UPDATE_DS_LAYOUT_FAILED = "digital_shadow_client/update_ds_layout_failed",

  DELETE_DS_LAYOUT_REQUEST = "digital_shadow_client/delete_ds_layout_request",
  DELETE_DS_LAYOUT_SUCCESS = "digital_shadow_client/delete_ds_layout_success",
  DELETE_DS_LAYOUT_FAILED = "digital_shadow_client/delete_ds_layout_failed",
}

export const useListTypesApi = (): ListTypesApi =>
  useApiRequestAnalytics(DigitalShadowClient.listTypes, {
    REQUEST_EVENT: ActionType.LIST_DS_TYPES_REQUEST,
    SUCCESS_EVENT: ActionType.LIST_DS_TYPES_SUCCESS,
    ERROR_EVENT: ActionType.LIST_DS_TYPES_FAILED,
  });

export const useGetTypeApi = (): GetTypeApi =>
  useApiRequestAnalytics(DigitalShadowClient.getDigitalShadowType, {
    REQUEST_EVENT: ActionType.GET_DS_TYPE_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DS_TYPE_SUCCESS,
    ERROR_EVENT: ActionType.GET_DS_TYPE_FAILED,
  });

export const useCreateTypeApi = (): CreateTypeApi =>
  useApiRequestAnalytics(DigitalShadowClient.createDSType, {
    REQUEST_EVENT: ActionType.CREATE_DS_TYPE_REQUEST,
    SUCCESS_EVENT: ActionType.CREATE_DS_TYPE_SUCCESS,
    ERROR_EVENT: ActionType.CREATE_DS_TYPE_FAILED,
  });

export const useUpdateTypeApi = (): UpdateTypeApi =>
  useApiRequestAnalytics(DigitalShadowClient.updateDSType, {
    REQUEST_EVENT: ActionType.UPDATE_DS_TYPE_REQUEST,
    SUCCESS_EVENT: ActionType.UPDATE_DS_TYPE_SUCCESS,
    ERROR_EVENT: ActionType.UPDATE_DS_TYPE_FAILED,
  });

export const useDeleteTypeApi = (): DeleteTypeApi =>
  useApiRequestAnalytics(DigitalShadowClient.deleteDSType, {
    REQUEST_EVENT: ActionType.DELETE_DS_TYPE_REQUEST,
    SUCCESS_EVENT: ActionType.DELETE_DS_TYPE_SUCCESS,
    ERROR_EVENT: ActionType.DELETE_DS_TYPE_FAILED,
  });

export const useListLayoutsApi = (): ListLayoutsApi =>
  useApiRequestAnalytics(DigitalShadowClient.listLayouts, {
    REQUEST_EVENT: ActionType.LIST_DS_LAYOUTS_REQUEST,
    SUCCESS_EVENT: ActionType.LIST_DS_LAYOUTS_SUCCESS,
    ERROR_EVENT: ActionType.LIST_DS_LAYOUTS_FAILED,
  });

export const useGetLayoutApi = (): GetLayoutApi =>
  useApiRequestAnalytics(DigitalShadowClient.getDigitalShadowLayout, {
    REQUEST_EVENT: ActionType.GET_DS_LAYOUT_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DS_LAYOUT_SUCCESS,
    ERROR_EVENT: ActionType.GET_DS_LAYOUT_FAILED,
  });

export const useCreateLayoutApi = (): CreateLayoutApi =>
  useApiRequestAnalytics(DigitalShadowClient.createDSLayout, {
    REQUEST_EVENT: ActionType.CREATE_DS_TYPE_REQUEST,
    SUCCESS_EVENT: ActionType.CREATE_DS_TYPE_SUCCESS,
    ERROR_EVENT: ActionType.CREATE_DS_TYPE_FAILED,
  });

export const useUpdateLayoutApi = (): UpdateLayoutApi =>
  useApiRequestAnalytics(DigitalShadowClient.updateDSLayout, {
    REQUEST_EVENT: ActionType.UPDATE_DS_TYPE_REQUEST,
    SUCCESS_EVENT: ActionType.UPDATE_DS_TYPE_SUCCESS,
    ERROR_EVENT: ActionType.UPDATE_DS_TYPE_FAILED,
  });

export const useDeleteLayoutApi = (): DeleteLayoutApi =>
  useApiRequestAnalytics(DigitalShadowClient.deleteDSLayout, {
    REQUEST_EVENT: ActionType.DELETE_DS_TYPE_REQUEST,
    SUCCESS_EVENT: ActionType.DELETE_DS_TYPE_SUCCESS,
    ERROR_EVENT: ActionType.DELETE_DS_TYPE_FAILED,
  });

export const useDigitalShadowClient = (): DigitalShadowClientApi => ({
  listTypesApi: useListTypesApi(),
  getTypeApi: useGetTypeApi(),
  createTypeApi: useCreateTypeApi(),
  updateTypeApi: useUpdateTypeApi(),
  deleteTypeApi: useDeleteTypeApi(),
  listLayoutsApi: useListLayoutsApi(),
  getLayoutApi: useGetLayoutApi(),
  createLayoutApi: useCreateLayoutApi(),
  updateLayoutApi: useUpdateLayoutApi(),
  deleteLayoutApi: useDeleteLayoutApi(),
});

export { ActionType as DigitalShadowClientActionType };

export default useDigitalShadowClient;
