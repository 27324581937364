import React from "react";
import { useHistory, useParams } from "react-router-dom";
import DigitalShadowLayoutDetails from "./containers/DigitalShadowLayoutDetails";
import { MODULE_PATH as SHADOW_LIST_PATH } from "@modules/digitalShadowLayouts/constants";
import { getEditShadowLayoutPath } from "@modules/digitalShadowLayoutWizard/helpers";

export function DigitalShadowLayoutDetailsView() {

  const { id: name } = useParams<{ id: string }>();

  const history = useHistory();

  const showListView = React.useCallback(() => {
    history.push(SHADOW_LIST_PATH);
  }, [history]);

  const editLayout = React.useCallback(() => {
    history.push(getEditShadowLayoutPath(name), { goBackOnSuccess: true});
  }, [history, name]);

  return (
    <DigitalShadowLayoutDetails
      name={name}
      showListView={showListView}
      editLayout={editLayout}
    />
  );
}

export default DigitalShadowLayoutDetailsView;
