import React from "react";
import { useHistory } from "react-router-dom";
import CreateNamespace from "./containers/CreateNamespace";

export function CreateNamespaceView() {

  const history = useHistory();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onSuccess = React.useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <CreateNamespace
      cancel={cancel}
      onSuccess={onSuccess}
    />
  );
}

export default CreateNamespaceView;
