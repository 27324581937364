import React from "react";
import classnames from "classnames";
import { summaryView as styles } from "./styles";
import { MetadataView } from "./MetadataView";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { KeyValuePair, SummaryViewData } from "@data";
import { SummaryView } from "@components";

export interface Model {
  summaryViewItems?: SummaryViewData[];
  metadataItems?: KeyValuePair[];
  children?: React.ReactNode;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const LayoutSummaryView = withStyles(styles)((props: Props) => {

  const {
    classes,
    summaryViewItems = [],
    metadataItems = [],
    children,
  } = props;

  return (
    <div className={classnames("layoutDetailsSummaryView", classes.container)}>
      <SummaryView
        className={classnames("summaryView", classes.summaryView)}
        items={summaryViewItems}
      />
      <MetadataView
        className={classnames("metadataTable", classes.metadataTable)}
        items={metadataItems}
      />
      {children}
    </div>
  );
});

export default LayoutSummaryView;
