import { createStyles, Theme } from "@material-ui/core/styles";
import Color from "@components/styles/Color";

export const primaryColor = Color.MODULES;

export const module = (theme: Theme) =>
  createStyles({
      container: {
          border: 0,
          flex: "1 0 auto",
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "stretch",
          fontFamily: "Roboto, sans-serif",
      },
  });
