import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { KEY_LAST_MOUSE_MOVE } from "@store/constants";
import { logout, setLastMouseMoved } from "@main/actions";
import LocalStorageClient from "@util/LocalStorageClient";
import { getLastMouseMove, isLoggedIn } from "@main/selectors";
import IdleSessionListener, {
  IdleSessionListenerActions as Actions,
  IdleSessionListenerModel as Model,
} from "@components/idle-session-listener";

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const IdleSessionListenerContainer = (props: Props) => {

  const {
    ...otherProps
  } = props;

  return (
    <IdleSessionListener {...otherProps} />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  loggedIn: isLoggedIn(state),
  lastMouseMove: getLastMouseMove(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  getLastPersistedMouseMove: () => Number(LocalStorageClient.getItem(KEY_LAST_MOUSE_MOVE)),
  updateLastMouseMove: (lastMouseMove: number) => dispatch(setLastMouseMoved(lastMouseMove)),
  logoutDueToInactivity: () => dispatch(logout()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(IdleSessionListenerContainer);
