import { createStyles } from "@material-ui/core/styles";
import ModuleIcon from "@material-ui/icons/Security";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const selectView = () =>
  createStyles({
    container: {
      color: primaryColor,
      "& .search": {
        marginTop: 25,
      },
      "& table": {
        marginTop: 0,
      },
      "& td label": {
        cursor: "pointer",
      },
    },
  });

export const styles = () =>
  createStyles({
    container: {
      color: primaryColor,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: 0,
      padding: 0,
    },
    error: {
      flex: "0 0 auto",
      marginTop: 25,
    },
    headerButtonControls: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      marginTop: 16,
    },
    footerButtonControls: {
      flex: "0 0 auto",
      marginTop: 25,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    policiesButton: {
      marginLeft: 0,
    },
    managedPoliciesButton: {
      marginLeft: 15,
    },
    information: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 250,
      fontSize: 16,
      lineHeight: "21px",
      marginTop: 25,
    },
    cancelButton: {
    },
    cancelButtonLabel: {
      color: Color.BLACK,
    },
    saveButton: {
    },
    button: {
      height: 36,
      minWidth: 100,
      padding: "6px 16px",
      marginLeft: 16,
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "16px",
      textTransform: "none",
    },
    nextStepButton: {
      color: Color.BLACK,
      marginLeft: 16,
    },
    label: {
      color: Color.TEXT,
      cursor: "default",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
      textTransform: "none",
      wordBreak: "break-word",
      marginRight: 8,
    },
    fab: {
      width: 28,
      height: 28,
    },
    progressIndicator: {
      color: Color.SIGNIFY_GREEN,
      position: "absolute",
    },
    icons: {
      fontSize: 13,
    },
    failedLabel: {
      color: Color.RED,
    },
    success: {
      backgroundColor: Color.SIGNIFY_GREEN,
      color: Color.BLACK,
    },
    failed: {
      backgroundColor: Color.RED,
      color: Color.BLACK,
    },
    errorIcon: {
      color: Color.BLACK,
    },
    successIcon: {
      color: Color.BLACK,
    },
  });

export default styles;
