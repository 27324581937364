import React from "react";
import { ApplicationType, ApplicationMetadataAttributes } from "@data";
import { applicationInfoView as styles, textAreaField, textField } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { clickHandler, formEventHandler, hasSpecialChars, noop } from "@util";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import TextField from "@components/text-field";
import DropdownMenu from "@components/dropdown-menu/DropdownMenu";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import { Chip, IconButton, Alert, AlertTitle } from "@components";
import AddIcon from "@material-ui/icons/AddOutlined";
import RemoveIcon from "@material-ui/icons/Clear";
import MetadataIcon from "@material-ui/icons/Label";
import { isApplicationTypeEnabled } from "@util";

const NameTextField = withStyles(textField)(TextField);
const MetadataTextField = withStyles(textField)(TextField);
const DescriptionTextArea = withStyles(textAreaField)(TextField);

export type ApplicationTypeLabels<K extends keyof any = ApplicationType> = {
  [P in K]: string;
};

export enum ApplicationScopes {
  OPENID = "openid",
  EMAIL = "email",
  PROFILE = "profile"
}

export const DEFAULT_APPLICATION_TYPES = [
  ApplicationType.NONE,
  ApplicationType.WEB,
  ApplicationType.NATIVE,
  ApplicationType.INSECURE_NATIVE,
  ApplicationType.API,
];

export const DEFAULT_APPLICATION_TYPE_LABELS: ApplicationTypeLabels = {
  [ApplicationType.NONE]: "NONE",
  [ApplicationType.WEB]: "Web",
  [ApplicationType.NATIVE]: "Native",
  [ApplicationType.INSECURE_NATIVE]: "Insecure Native",
  [ApplicationType.API]: "API",
};

export interface Model {
  title?: string;
  name?: string;
  description?: string;
  type?: ApplicationType;
  types?: ApplicationType[];
  typeLabels?: ApplicationTypeLabels;
  idHelperText?: string;
  editMode?: boolean;
  openIdConnectApp?: boolean;
  metadata?: ApplicationMetadataAttributes;
}

export interface Actions {
  setName?: (name: string) => void;
  setDescription?: (description: string) => void;
  setType?: (type: ApplicationType) => void;
  addMetadataEntry?: (key: string, value: string) => void;
  removeMetadataEntry?: (key: string) => void;
  setOpenIdConnectApp?: (value: boolean) => void;
  mapApplicationTypeToLabel?: (type: ApplicationType) => React.ReactNode | string | null;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ApplicationInfoView = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Set Basic Application Information",
    description = "",
    name = "",
    type = ApplicationType.NONE,
    editMode = false,
    openIdConnectApp = false,
    types = DEFAULT_APPLICATION_TYPES,
    typeLabels = DEFAULT_APPLICATION_TYPE_LABELS,
    metadata = {} ,
    children,
    setName = noop,
    setDescription = noop,
    setType = noop,
    setOpenIdConnectApp = noop,
    addMetadataEntry = noop,
    removeMetadataEntry = noop,
    mapApplicationTypeToLabel = React.useCallback((appType: ApplicationType) =>
      typeLabels[appType] || null, [typeLabels]),
  } = props;

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const [key, setKey] = React.useState("");
  const [value, setValue] = React.useState("");

  const addMetadata = React.useCallback(() => {
    addMetadataEntry(key, value);
    setKey("");
    setValue("");
  }, [key, value, addMetadataEntry, setValue, setKey]);

  const removeMetadata = (k: string) => () => {
    if (removeMetadataEntry) {
      return removeMetadataEntry(k);
    }
  };

  const onChangeIsOpenIdConnectApp = React.useCallback(event => {
    if (event && event.target && event.target.value) {
      setOpenIdConnectApp(event.target.value === "yes");
    }
  }, [setOpenIdConnectApp]);

  const validMetadata = React.useMemo(() =>
    key.trim().length > 0 && value.trim().length > 0 && !hasSpecialChars(key), [key, value]);

  return (
    <div className={classnames("applicationInfoView", classes.container)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      {!editMode && (
        <React.Fragment>
          <Typography variant="h5" className={classnames("openIdConnect", classes.openIdConnect)}>
            Is this an Open ID connect application?
          </Typography>
          <RadioGroup
            className={classnames("radioGroup", "isOpenIdApp")}
            row={true}
          >
            <FormControlLabel
              className={classnames("radio", "yes")}
              label="Yes"
              control={(
                <Radio
                  value="yes"
                  checked={openIdConnectApp}
                  onChange={onChangeIsOpenIdConnectApp}
                />
              )}
            />
            <FormControlLabel
              className={classnames("radio", "no")}
              label="No"
              control={(
                <Radio
                  value="no"
                  checked={!openIdConnectApp}
                  onChange={onChangeIsOpenIdConnectApp}
                />
              )}
            />
          </RadioGroup>
        </React.Fragment>
      )}
      <NameTextField
        className={classnames("nameTextField", classes.nameTextField)}
        autoComplete="off"
        required={true}
        label="Application Name"
        name="name"
        value={name}
        fullWidth={true}
        autoFocus={true}
        variant="outlined"
        margin="none"
        disableSpecialChars={true}
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setName)}
      />
      <DescriptionTextArea
        className={classnames("description", classes.description)}
        autoComplete="off"
        name="description"
        label="Description"
        value={description}
        fullWidth={true}
        multiline={true}
        minRows={3}
        maxRows={3}
        autoFocus={false}
        variant="outlined"
        margin="none"
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setDescription)}
      />
      {isApplicationTypeEnabled() && (
        <div className={classnames("applicationTypeContainer", classes.applicationTypeContainer)}>
          <DropdownMenu
              className={classnames("applicationType", classes.dropdownMenu)}
              hideEmptyValue={false}
              disabled={editMode}
              dropdownMenuLabel="Application Type"
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
              emptyValueLabel="NONE"
              emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
              values={types}
              selectedValue={type}
              setSelectedValue={setType}
              mapValueToLabel={mapApplicationTypeToLabel}
          />
          <Alert severity="info" className={classnames("applicationTypeInfo", classes.alert)}>
            <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
              <strong>
                Application Type
              </strong>
            </AlertTitle>
            <ul className={classnames("alertDescription", classes.alertDescription)}>
              <li>
                <strong>Web - </strong>
                A web-based application
              </li>
              <li>
                <strong>Native - </strong>
                A native (mobile) application
              </li>
              <li>
                <strong>Insecure Native - </strong>
                An insecure native application
              </li>
              <li>
                <strong>API - </strong>
                An 'API' application
              </li>
            </ul>
          </Alert>
        </div>
      )}
      <Typography className={classnames("metadata", classes.metadata)} variant="h5">
        Application Metadata
      </Typography>
      <div className={classnames("metadataContainer", classes.metadataContainer)}>
        <MetadataTextField
          className={classnames("metadataKey", classes.metadataKey)}
          autoComplete="off"
          name="metadataKey"
          label="Key"
          value={key}
          variant="outlined"
          disableSpecialChars={true}
          InputLabelProps={inputLabelProps}
          onChange={formEventHandler(setKey)}
        />
        <MetadataTextField
          className={classnames("metadataValue", classes.metadataValue)}
          autoComplete="off"
          name="metadataValue"
          label="Value"
          value={value}
          variant="outlined"
          InputLabelProps={inputLabelProps}
          onChange={formEventHandler(setValue)}
        />
        {validMetadata && (
          <IconButton
            className={classnames("addMetadataButton", classes.addMetadataButton)}
            color="primary"
            onClick={clickHandler(addMetadata)}
          >
            <AddIcon />
          </IconButton>
        )}
      </div>
      <div className={classnames("metadataContainer", classes.metadataContainer)}>
        {Object.entries(metadata).map((data: string[] = []) => (
          <Chip
            key={data[0]}
            label={`${data[0]} : ${data[1]}`}
            color="primary"
            className={classnames("metadataTag", classes.metadataTag)}
            icon={<MetadataIcon className={classnames("scopeIcon", classes.metadataIcon)} />}
            deleteIcon={<RemoveIcon className={classnames("removeIcon", classes.metadataIcon)} />}
            onDelete={removeMetadata(data[0])}
          />
        ))}
      </div>
      {children}
    </div>
  );
});

export default ApplicationInfoView;
