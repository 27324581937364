import React from "react";
import classnames from "classnames";
import { clickHandler, noop } from "@util";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { DeviceDataModel, TableHeaderRowData } from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ActionMenuItem, Button, TableHeaderRowView, TableFooterRowView } from "@components";
import DevicesTableBodyRow from "../../devices/devicesTable/DevicesTableBodyRow";
import styles from "../../credentials/credentialsTable/styles";

export const NUM_COLUMNS = 2;

export interface Model {
  className?: string;
  devices?: DeviceDataModel[];
  actions?: ActionMenuItem[];
  addButtonDisabled?: boolean;
  headerColumns?: TableHeaderRowData[];
  children?: React.ReactNode;
}

export interface Actions {
  addDevice?: () => void;
  onClickDeviceAction?: (device: DeviceDataModel, action: ActionMenuItem) => void;
  onClickItem?: (device: DeviceDataModel) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DevicesTable = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    addButtonDisabled,
    devices = [],
    actions = [],
    headerColumns = [],
    children,
    addDevice,
    onClickDeviceAction = noop,
    onClickItem = noop,
  } = props;

  const showActionsColumn = actions.length > 0;

  const numItems = React.useMemo(() => devices.length, [devices]);

  const clickable = typeof onClickItem === "function" && onClickItem !== noop;

  const numColumns = showActionsColumn ? NUM_COLUMNS : (NUM_COLUMNS - 1);

  const onItemClicked = React.useCallback((row: number) => {
    if (onClickItem && numItems > row) {
      return () => onClickItem(devices[row]);
    } else {
      return noop;
    }
  }, [onClickItem, numItems, devices]);

  return (
    <Table className={classnames("devicesTable", className, classes.table)}>
      <TableHead>
        <TableHeaderRowView
          className="devicesTable"
          items={headerColumns}
          showActionsColumn={showActionsColumn}
        />
      </TableHead>
      <TableBody>
        {devices.map((device: DeviceDataModel, rowIndex: number) => (
          <DevicesTableBodyRow
            device={device}
            key={`table-body-row-${rowIndex}`}
            actions={actions}
            clickable={clickable}
            onClickAction={(action: ActionMenuItem) => onClickDeviceAction(device, action)}
            onClick={onItemClicked(rowIndex)}
          />
        ))}
        <TableFooterRowView
          className={classnames("addButtonRow", classes.addButtonRow)}
          columnClassName={classnames("addButtonColumn", classes.addButtonColumn)}
          numColumns={numColumns}
        >
          {!addButtonDisabled && (
            <Button
              className={classnames("addButton", classes.addButton)}
              classes={{
                label: classnames("label", classes.addButtonLabel),
              }}
              onClick={clickHandler(addDevice)}
              disableRipple={true}
            >
            Add Device
            </Button>
          )}
        </TableFooterRowView>
        <TableFooterRowView numColumns={numColumns}>
          {children}
        </TableFooterRowView>
      </TableBody>
    </Table>
  );
});

export default DevicesTable;
