import React from "react";
import classnames from "classnames";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { CredentialsTableBodyRow } from "./CredentialsTableBodyRow";
import { TableHeaderRowView, TableFooterRowView } from "@components";
import { styles } from "./styles";
import { CredentialAttributes, TableHeaderRowData } from "@data";
import { NoResultsView } from "@components/paginated-list/PaginatedList";

export const NUM_COLUMNS = 3;

export interface Model {
  className?: string;
  credentials?: CredentialAttributes[];
  noResultLabel?: string;
  headerColumns?: TableHeaderRowData[];
  children?: React.ReactNode;
}

export interface Actions {
  updateCredentialValue?: (name: string, value: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const CredentialsTable = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    headerColumns = [],
    credentials = [],
    noResultLabel = "No Credentials available for selected device type",
    children,
    updateCredentialValue,
  } = props;

  const numColumns = (NUM_COLUMNS - 1);

  const showNoResultsView = React.useMemo( () =>
    credentials.length === 0 , [credentials]);

  const noResultsView = React.useMemo(() => !showNoResultsView ? null : (
    <NoResultsView numColumns={numColumns} label={noResultLabel} />
  ), [showNoResultsView, numColumns, noResultLabel]);

  return (
    <Table className={classnames("credentialsTable", className, classes.table)}>
      <TableHead>
        <TableHeaderRowView
          className="credentialsTable"
          items={headerColumns}
        />
      </TableHead>
      <TableBody>
        {credentials.map((credential: CredentialAttributes, rowIndex: number) => (
          <CredentialsTableBodyRow
            credential={credential}
            key={`table-body-row-${rowIndex}`}
            updateCredentialValue={updateCredentialValue}
          />
        ))}
        <TableFooterRowView numColumns={numColumns}>
          {children}
        </TableFooterRowView>
        {noResultsView}
      </TableBody>
    </Table>
  );
});

export default CredentialsTable;
