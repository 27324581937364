import React from "react";
import { connect } from "react-redux";
import { UsersBulkRequest } from "@data";
import { UserReviewViewList } from "../components/UserReviewViewList";
import AppSchema from "@schemas";
import { getUsersBulkRequest } from "@modules/groupManagementWizard/selectors";

interface ContainerModel {
  usersBulkRequest?: UsersBulkRequest[];
}

const ReviewUsersViewContainer = (props: ContainerModel) => {

  const {
    usersBulkRequest = [],
  } = props;

  return (
    <UserReviewViewList
      items={usersBulkRequest}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  usersBulkRequest: getUsersBulkRequest(state),
});

export default connect<ContainerModel, {}, ContainerModel>(
  mapStateToProps
)(ReviewUsersViewContainer);
