import { Record } from "immutable";
import { getStringValue } from "@util";

export interface WorkloadConfigurationAttributes {
  key: string;
  value: string;
}

export class WorkloadConfiguration extends Record({
  key: "",
  value: "",
}) implements WorkloadConfigurationAttributes {

  public static EMPTY = new WorkloadConfiguration();

  public readonly key: string;
  public readonly value: string;

  public getKey(): string {
    return getStringValue(this.key);
  }

  public getValue(): string {
    return getStringValue(this.value);
  }
}

export default WorkloadConfiguration;
