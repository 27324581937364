import React from "react";
import classnames from "classnames";
import { ErrorView } from "@components";
import { clickHandler, enterKeyHandler, noop } from "@util";
import MuiDialog from "@material-ui/core/Dialog";
import SaveButton from "@components/save-button";
import CancelButton from "@components/cancel-button";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles, { dialog, dialogActions, dialogContent, dialogTitle } from "./styles";

const Dialog = withStyles(dialog)(MuiDialog);
const DialogTitle = withStyles(dialogTitle)(MuiDialogTitle);
const DialogContent = withStyles(dialogContent)(MuiDialogContent);
const DialogActions = withStyles(dialogActions)(MuiDialogActions);

const AccessDeniedView = () => (
  <ErrorView
    className="accessDenied"
    title="Access Denied"
    message="You do not have permission to perform this action"
  />
);

export interface ConfirmationDialogModel<Item> {
  className?: string;
  title?: string;
  open?: boolean;
  item?: Item;
  loading?: boolean;
  accessDenied?: boolean;
  errorMessage?: string;
  successMessage?: string;
  cancelButtonLabel?: string;
  cancelButtonDisabled?: boolean;
  cancelButtonTabIndex?: number;
  continueButtonLabel?: string;
  continueButtonDisabled?: boolean;
  continueButtonTabIndex?: number;
  hideCancelButton?: boolean;
  hideContinueButton?: boolean;
  showCloseIconOnSuccess?: boolean;
  saveOnEnterPressed?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  statusCode?: number;
  children?: React.ReactNode;
}

export interface ConfirmationDialogActions<Item> {
  cancel?: () => void;
  confirm?: (item?: Item) => void;
  onClickCloseIcon?: () => void;
  onClose?: () => void;
}

type Props<Item> = WithStyles<typeof styles> & ConfirmationDialogModel<Item> & ConfirmationDialogActions<Item>;

export const ConfirmationDialog = withStyles(styles)(<Item, >(props: Props<Item>) => {

  const {
    classes,
    className,
    item,
    title = "Confirm",
    open = false,
    loading,
    accessDenied,
    errorMessage = "",
    successMessage = "",
    cancelButtonLabel = "Cancel",
    cancelButtonDisabled,
    cancelButtonTabIndex = -1,
    continueButtonLabel = "Continue",
    continueButtonTabIndex = -1,
    continueButtonDisabled,
    hideCancelButton = false,
    hideContinueButton = false,
    showCloseIconOnSuccess = false,
    saveOnEnterPressed = false,
    maxWidth = "sm",
    statusCode,
    cancel,
    onClickCloseIcon,
    confirm = noop,
    onClose = noop,
    children,
  } = props;

  if (accessDenied) {
    return (
      <Dialog
        className={classnames("confirmationDialog", "accessDenied", className)}
        open={open}
        maxWidth="sm"
        fullWidth={true}
        disableEscapeKeyDown={true}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose(event, reason);
          }
        }}
      >
        <DialogTitle className="title" disableTypography={true}>
          {title}
        </DialogTitle>
        <DialogContent>
          <AccessDeniedView />
        </DialogContent>
        <DialogActions>
          <CancelButton
            className={classnames("cancelButton", classes.cancelButton)}
            uppercase={true}
            label={cancelButtonLabel}
            onClick={clickHandler(cancel)}
          />
        </DialogActions>
      </Dialog>
    );
  }

  const success = successMessage.length > 0;
  const showErrorView = errorMessage.length > 0;
  const cancelButtonHidden = hideCancelButton || loading || success;

  const onEnterKeyPressed = React.useCallback(() => {
    if (saveOnEnterPressed && !continueButtonDisabled) {
      return confirm(item);
    } else {
      return noop;
    }
  } , [confirm, item, continueButtonDisabled, saveOnEnterPressed]);

  return (
    <Dialog
      className={classnames("confirmationDialog", className)}
      open={open}
      maxWidth={maxWidth}
      fullWidth={true}
      disableEscapeKeyDown={true}
      onKeyUp={enterKeyHandler(onEnterKeyPressed)}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose(event, reason);
        }
      }}
    >
      <DialogTitle className="title" disableTypography={true}>
        {title}
      </DialogTitle>
      <DialogContent className="content">
        {showErrorView && <ErrorView title="Error" message={errorMessage} statusCode={statusCode} />}
        {children}
      </DialogContent>
      <DialogActions className="actions">
        {!cancelButtonHidden && (
          <CancelButton
            className={classnames("cancelButton", classes.cancelButton)}
            tabIndex={cancelButtonTabIndex}
            disabled={cancelButtonDisabled}
            label={cancelButtonLabel}
            onClick={clickHandler(cancel)}
          />
        )}
        {!hideContinueButton && (
          <SaveButton
            className={classnames("continueButton", classes.continueButton)}
            success={success}
            loading={loading}
            disabled={continueButtonDisabled}
            label={continueButtonLabel}
            showCloseIconOnSuccess={showCloseIconOnSuccess}
            tabIndex={continueButtonTabIndex}
            save={() => confirm(item)}
            onClickCloseIcon={onClickCloseIcon}
          />
        )}
      </DialogActions>
    </Dialog>
  );
});

export default ConfirmationDialog;
