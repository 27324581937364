import React from "react";
import classnames from "classnames";
import { Link, LinkProps } from "react-router-dom";
import MuiTab, { TabProps } from "@material-ui/core/Tab";

export const Tab = ((props: TabProps) => {

  const {
    className,
    ...otherProps
  } = props;

  return (
    <MuiTab
      {...otherProps}
      className={classnames("tab", className)}
    />
  );
});

export const TabLink = ((props: TabProps<Link, LinkProps>) => {

  const {
    className,
    ...otherProps
  } = props;

  return (
    <MuiTab
      {...otherProps}
      component={Link}
      className={classnames("tab", className)}
    />
  );
});

export default Tab;
