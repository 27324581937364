import React from "react";
import { WorkloadVariableSchema } from "@data";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useWorkloadManagerClient } from "@hooks";

type SuccessResponse = void;

export interface UseEditVariableProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  etag: string;
  variableName: string;
  variable: WorkloadVariableSchema;
}

export interface UseEditVariableModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseEditVariableActions {
  save: () => void;
  reset: () => void;
}

type Props = UseEditVariableProps;
type Model = UseEditVariableModel;
type Actions = UseEditVariableActions;
type Result = [Model, Actions];

export const useEditVariable = (props: Props): Result => {

  const {
    name,
    version,
    etag,
    variableName,
    variable = WorkloadVariableSchema.EMPTY,
    defaultErrorMessage = "Failed to edit workload completion variable",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.editWorkloadVariableApi(
      name,
      version,
      variableName,
      etag,
      JSON.stringify({ schema: variable })),
    [WorkloadManagerClient, name, version, etag, variableName, variable]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: save, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Variable Updated" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    save,
    reset
  }), [
    save,
    reset
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEditVariable;
