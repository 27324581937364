import { Record } from "immutable";
import { CreateDataSetRequest } from "@data";

export interface DataSetRequestStateAttributes {
  json: string;
}

// We must do this outside of the record class definition because you cannot reference
// static field values in the record defaults; unless you have specifically enforced
// their evaluation before the class is defined - like in this case.
const DEFAULT_JSON = JSON.stringify(CreateDataSetRequest.EMPTY.toJS(), null, "  ");

export class DataSetRequestState extends Record({
  json: DEFAULT_JSON,
}) implements DataSetRequestStateAttributes {

  public static EMPTY = new DataSetRequestState();

  public readonly json: string;
}

export default DataSetRequestState;
