import React from "react";
import styles from "./styles";
import classnames from "classnames";
import CancelButton from "@components/cancel-button";
import ContinueButton from "@components/continue-button";
import TextFieldStyled from "@components/text-field-styled";
import ActionsListItemData from "../../../ActionsListItemData";
import { equalsIgnoreCase, hasSpecialChars, isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface EditActionsListItemModel {
  className?: string;
  item?: ActionsListItemData;
}

export interface EditActionsListItemActions {
  save?: (value: ActionsListItemData) => void;
  cancel?: () => void;
  isDuplicate?: (name: string) => boolean;
}

type Model = EditActionsListItemModel;
type Actions = EditActionsListItemActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const EditActionsListItem = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    item = ActionsListItemData.EMPTY,
    save = noop,
    cancel = noop,
    isDuplicate,
    children,
  } = props;

  const [updatedName, setUpdatedName] = React.useState(item.getName());

  const saveButtonDisabled = React.useMemo(() =>
    isEmptyString(updatedName) ||
    hasSpecialChars(updatedName) ||
    equalsIgnoreCase(updatedName, item.getName()) ||
    (isDuplicate ? isDuplicate(updatedName) : false),
    [updatedName, isDuplicate, item]);

  const saveItem = React.useCallback(() => {
    save(new ActionsListItemData({
      ...item.toJS(),
      name: updatedName,
    }));
  }, [item, updatedName]);

  const onEnterPressed = React.useCallback(() => {
    if (!saveButtonDisabled) {
      saveItem();
    }
  }, [saveButtonDisabled, saveItem]);

  return (
    <div className={classnames("editActionsListItem", className, classes.container)}>
      <TextFieldStyled
        className={classnames("actionNameTextField", classes.textField)}
        label="Action Name"
        placeholder="Set Action Name"
        autoFocus={true}
        shrinkInputLabel={true}
        value={updatedName}
        disableSpecialChars={true}
        setValue={(value) => setUpdatedName(value.toUpperCase())}
        onEnterPressed={onEnterPressed}
      />
      <div className={classnames("controls", classes.controls)}>
        <CancelButton
          className={classnames("cancelButton", classes.cancelButton)}
          label="Cancel"
          onClick={cancel}
        />
        <ContinueButton
          className={classnames("saveButton", classes.saveButton)}
          disabled={saveButtonDisabled}
          label="Save"
          onClick={saveItem}
        />
      </div>
      {children}
    </div>
  );
});

export default EditActionsListItem;
