import React from "react";
import { isEmptyString, noop } from "@util";
import classnames from "classnames";
import AdminNoteTextField from "@components/admin-note-text-field";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { useCreateAdminNote, UseCreateAdminNoteProps } from "@hooks";
import styles from "./styles";
import AccessDeniedErrorView from "@components/error-view";
import ErrorView from "@components/error-view";

export const DEFAULT_CREATE_ADMIN_NOTE_ERROR_VIEW_TITLE =
  "Failed to add note to user's account";

export const DEFAULT_CREATE_ADMIN_NOTE_ACCESS_DENIED_MESSAGE =
  "You do not have permission to add notes to this user's account";

export const DEFAULT_CREATE_ADMIN_NOTE_ERROR_MESSAGE =
  "Your note could not be added to this users account. " +
  "Please consider adding it manually from their user details page.";

export interface CreateAdminNoteProps extends UseCreateAdminNoteProps {
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  showLoadingIndicator?: boolean;
  setOmitAdminNote?: (omitAdminNote: boolean) => void;
  setSuccessMessage?: (successMessage: string) => void;
  setShowAdminNoteError?: (showAdminNoteError: boolean) => void;
  setShowLoadingIndicator?: (showLoadingIndicator: boolean) => void;
}

type Props = WithStyles<typeof styles> & CreateAdminNoteProps & {
  children?: React.ReactNode;
};

export const CreateAdminNote = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    disabled,
    placeholder,
    showLoadingIndicator,
    setOmitAdminNote = noop,
    setSuccessMessage = noop,
    setShowLoadingIndicator = noop,
    children,
    ...otherProps
  } = props;

  const [model, actions] = useCreateAdminNote(otherProps);

  const {
    note,
    errorMessage,
    successMessage,
    showAccessDenied,
    showLoadingIndicator: saving,
  } = model;

  const { setNote, save } = actions;

  React.useEffect(() => setSuccessMessage(successMessage), [successMessage, setSuccessMessage]);

  // Trigger save once parent indicates to show the loading indicator
  React.useEffect(() => {
    if (showLoadingIndicator && !saving && !errorMessage && !successMessage && !showAccessDenied) {
      if (isEmptyString(note)) {
        setOmitAdminNote(true);
      } else {
        save();
      }
    }
  }, [
    showLoadingIndicator,
    saving,
    errorMessage,
    successMessage,
    showAccessDenied,
    note,
    save,
    setOmitAdminNote,
  ]);

  // Hide loading indicator once request completes regardless of outcome
  React.useEffect(() => {
    if (!saving &&
      (showAccessDenied || !isEmptyString(errorMessage) || !isEmptyString(successMessage))) {

      setShowLoadingIndicator(false);
    }
  }, [saving, showAccessDenied, errorMessage, successMessage, setShowLoadingIndicator]);

  if (showAccessDenied || errorMessage) {
    return (
      <div className={classnames("createAdminNote", className, classes.container)}>
        {showAccessDenied && (
          <AccessDeniedErrorView
            className={classnames("accessDenied", classes.errorView, classes.accessDenied)}
            title={DEFAULT_CREATE_ADMIN_NOTE_ERROR_VIEW_TITLE}
            message={DEFAULT_CREATE_ADMIN_NOTE_ACCESS_DENIED_MESSAGE}
          />
        )}
        {!showAccessDenied && (
          <ErrorView
            className={classnames("errorView", classes.errorView)}
            title={DEFAULT_CREATE_ADMIN_NOTE_ERROR_VIEW_TITLE}
            message={DEFAULT_CREATE_ADMIN_NOTE_ERROR_MESSAGE}
          />
        )}
      </div>
    );
  }

  return (
    <div className={classnames("createAdminNote", className, classes.container)}>
      {children}
      <AdminNoteTextField
        className={classnames("adminNoteTextField", classes.adminNoteTextField)}
        note={note}
        placeholder={placeholder}
        setNote={setNote}
        disabled={disabled || showLoadingIndicator || saving || !isEmptyString(successMessage)}
      />
    </div>
  );
});

export default CreateAdminNote;
