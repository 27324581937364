import React from "react";
import classnames from "classnames";
import Button from "@components/button";
import { usePortalSnackbar } from "@components";
import SaveButton from "@components/save-button";
import TextField from "@components/text-field";
import CancelButton from "@components/cancel-button";
import { clickHandler, formEventHandler, noop } from "@util";
import { commentTextField, createCommentsView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

const CommentTextField = withStyles(commentTextField)(TextField);

export interface Model {
  createCommentBoxVisible?: boolean;
  comment?: string;
  showCancelButton?: boolean;
  showSaveButton?: boolean;
  showProgressIndicator?: boolean;
  buttonsDisabled?: boolean;
  finished?: boolean;
  errorMessage?: string;
  successMessage?: string;
  children?: React.ReactNode;
}

export interface Actions {
  save?: () => void;
  cancel?: () => void;
  setComment?: (comment: string) => void;
  addComment?: () => void;
  onSuccess?: (comment: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const CreateCommentView = withStyles(styles)((props: Props) => {

  const {
    classes,
    createCommentBoxVisible,
    showCancelButton,
    showSaveButton,
    comment,
    buttonsDisabled,
    showProgressIndicator: loading,
    finished: success,
    children,
    errorMessage = "",
    successMessage = "",
    onSuccess = noop,
    save = noop,
    cancel,
    setComment,
    addComment,
  } = props;

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const onSuccessMessageShown = React.useCallback(() => {
    onSuccess(comment);
  }, [onSuccess, comment]);

  usePortalSnackbar("create-comment", {
    errorMessage,
    successMessage,
    onSuccessMessageShown,
    successCallbackDelay: 250
  });

  return (
    <div className={classnames("createCommentView", classes.container)}>
      {!createCommentBoxVisible && (
        <Button
          className={classnames("addCommentButton", classes.addCommentButton)}
          variant="contained"
          color="secondary"
          fullWidth={false}
          onClick={clickHandler(addComment)}
        >
          Add Comment
        </Button>
      )}
      {createCommentBoxVisible && (
        <div className={classnames("createCommentContainer", classes.createCommentContainer)}>
          <CommentTextField
            className={classnames("comment", classes.comment)}
            autoComplete="off"
            name="comment"
            label="Comment"
            value={comment}
            fullWidth={true}
            multiline={true}
            minRows={3}
            maxRows={3}
            variant="outlined"
            margin="none"
            InputLabelProps={inputLabelProps}
            onChange={formEventHandler(setComment)}
          />
          <div className={classnames("actionsContainer", classes.actionsContainer)}>
            {showCancelButton && (
              <CancelButton
                className={classnames("cancelButton", classes.cancelButton)}
                disabled={buttonsDisabled}
                onClick={clickHandler(cancel)}
              />
            )}
            <SaveButton
              className={classnames("saveButton", classes.saveButton)}
              disabled={!showSaveButton}
              loading={loading}
              success={success}
              save={save}
            />
          </div>
        </div>
      )}
      {children}
    </div>
  );
});

export default CreateCommentView;
