import { connect } from "react-redux";
import { AppSchema } from "../../main/schemas";
import { JsonSchemaProperty } from "../../../data";
import {
  PROPERTIES_TABLE_ACTIONS as actions,
  PropertiesTableAction,
} from "../components/PropertiesTable/PropertiesTableAction";
import { Actions, Model, PropertiesTable } from "../components/PropertiesTable";
import { ActionMenuItem } from "../../../components/actions-menu";
import { noop } from "../../../util";

export interface PropertiesTableProps {
  className?: string;
  properties?: JsonSchemaProperty[];
  addProperty?: () => void;
  editProperty?: (property: JsonSchemaProperty) => void;
  removeProperty?: (property: JsonSchemaProperty) => void;
  cloneProperty?: (property: JsonSchemaProperty) => void;
  onClickProperty?: (property: JsonSchemaProperty) => void;
}

const mapStateToProps = (state: AppSchema, ownProps: PropertiesTableProps): Model => {

  const { className, properties } = ownProps;

  return {
    className,
    actions,
    properties,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: PropertiesTableProps): Actions => {

  const {
    onClickProperty,
    editProperty = noop,
    removeProperty = noop,
    cloneProperty = noop,
  } = ownProps;

  const onClickPropertyAction = (property: JsonSchemaProperty, action: ActionMenuItem) => {
    if (PropertiesTableAction.EDIT_PROPERTY === action.id) {
      editProperty(property);
    } else if (PropertiesTableAction.REMOVE_PROPERTY === action.id) {
      removeProperty(property);
    } else if (PropertiesTableAction.CLONE_PROPERTY === action.id) {
      cloneProperty(property);
    }
  };

  return {
    onClickProperty,
    onClickPropertyAction,
  };
};

export default connect<Model, Actions, PropertiesTableProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PropertiesTable);
