import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    title: {
      fontSize: 18,
      textAlign: "center",
      fontWeight: 400,
      padding: "15px 10px",
      lineHeight: "1",
      color: Color.TEXT,
      backgroundColor: Color.LIGHT_GREY6,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      border: `1px solid ${Color.GREY1}`,
    },
    content: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      padding: "4px 15px 15px",
      margin: 0,
      borderLeft: `1px solid ${Color.GREY1}`,
      borderRight: `1px solid ${Color.GREY1}`,
      borderBottom: `1px solid ${Color.GREY1}`,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    },
    column: {
      marginTop: 15,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginLeft: 15,
      "&:first-child": {
        marginLeft: 0,
      },
    },
    openSourceVulnerabilities: {
    },
    staticCodeAnalysisVulnerabilities: {
    },
  });

export default styles;
