import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { createActions } from "@base";

export const {
  applicationId: setApplicationId,
  editMode: setEditMode,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setApplicationId());
  dispatch(setEditMode());
  return dispatch(baseReset());
};

export const initialize = (applicationId: string = "") => (dispatch: any) => {

  dispatch(reset());
  dispatch(setEditMode(true));
  return dispatch(setApplicationId(applicationId));
};
