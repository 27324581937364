import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useWorkloadManagerClient,
} from "@hooks";

export interface UseDeleteWorkloadProps extends Partial<UseApiRequestProps<void>> {
  name: string;
  version?: number;
}

export interface UseDeleteWorkloadModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UseDeleteWorkloadActions extends UseApiRequestActions<void> {
  deleteWorkload: () => void;
}

type Props = UseDeleteWorkloadProps;
type Model = UseDeleteWorkloadModel;
type Actions = UseDeleteWorkloadActions;
type Result = [ Model, Actions ];

export const useDeleteWorkload = (props: Props): Result => {

  const {
    name,
    version,
    defaultErrorMessage = `Failed to delete ${version ? "workload version" : "workload"}`,
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() => {
    if (version) {
      return WorkloadManagerClient.deleteWorkloadVersion(name, version);
    } else {
      return WorkloadManagerClient.deleteWorkloadApi(name);
    }
  }, [WorkloadManagerClient, name, version]);

  const [ { showSuccessView, ...baseModel }, { refresh: deleteWorkload, ...baseActions } ] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
    showSuccessView ? `${version ? "Workload Version" : "Workload"} Deleted Successfully` : "",
    [showSuccessView, version]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    deleteWorkload,
    refresh: deleteWorkload,
  }), [
    baseActions,
    deleteWorkload,
  ]);

  return React.useMemo<Result>(() => [ model, actions ], [ model, actions ]);
};

export default useDeleteWorkload;
