export enum SchemasListColumn {
  NONE = "",
  NAMESPACE = "Namespace",
  NAME = "Name",
  VERSION = "Version",
  STATE = "State",
  LAST_UPDATED = "Last Updated",
}

export default SchemasListColumn;
