import React from "react";
import classnames from "classnames";
import ReleaseNote from "@data/ReleaseNote";
import { useHistory } from "react-router-dom";
import { getPathToReleaseNote } from "@modules/releaseNoteDetails/helpers";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import ModuleListView, {
  createColumns,
  ModuleListViewActions,
  ModuleListViewModel,
} from "@components/module-list-view";
import styles, { PrimaryIcon } from "./styles";
import { Alert, Chip, Link, QuickFilters } from "@components";
import { formatDateToUTC, noop } from "@util";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import { SearchFilter } from "@data";

export enum ReleaseNotesListColumn {
  RELEASE = "Release",
  RELEASE_DATE = "Date",
  RELEASE_TYPE = "Type",
  RELEASE_STATUS = "Status",
  DESCRIPTION = "Description",
}

export const DEFAULT_RELEASE_NOTES_LIST_COLUMNS: ReleaseNotesListColumn[] = [
  ReleaseNotesListColumn.RELEASE,
  ReleaseNotesListColumn.RELEASE_DATE,
  ReleaseNotesListColumn.RELEASE_TYPE,
  ReleaseNotesListColumn.RELEASE_STATUS,
  ReleaseNotesListColumn.DESCRIPTION,
];

type Item = ReleaseNote;
type Column = ReleaseNotesListColumn;

export interface ReleaseNotesModel extends ModuleListViewModel<Item, Column> {
  className?: string;
  title?: string;
  releaseNotes?: ReleaseNote[];
  filters?: SearchFilter[];
  selectedFilters?: string[];
  archiveLink?: string;
}

export interface ReleaseNotesActions extends ModuleListViewActions<Item, Column> {
  onClickFilter?: (filter: SearchFilter) => void;
  mapFilterToLabel?: (filter: SearchFilter) => React.ReactNode;
}

type Model = ReleaseNotesModel;
type Actions = ReleaseNotesActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ReleaseNotes = withStyles(styles)((props: Props) => {

  const history = useHistory();

  const {
    classes,
    className,
    listClassName,
    archiveLink = "",
    showSummary = true,
    showSearch = false,
    showLoadingIndicator,
    tableLayoutFixed = false,
    summaryViewIcon = PrimaryIcon,
    columns = DEFAULT_RELEASE_NOTES_LIST_COLUMNS,
    releaseNotes = [],
    filters = [],
    selectedFilters = [],
    items = releaseNotes,
    listViewItems = React.useMemo(() =>
      items.map(releaseNote => {
        return {
          item: releaseNote,
          pathToDetailsView: getPathToReleaseNote(releaseNote),
          icon: PrimaryIcon,
          columnAttributes: createColumns([
            {
              className: "release",
              value: releaseNote.getTitle(),
              column: ReleaseNotesListColumn.RELEASE,
              firstColumn: true,
            },
            {
              className: "releaseDate",
              value: formatDateToUTC(releaseNote.getReleaseDate()),
              column: ReleaseNotesListColumn.RELEASE_DATE,
            },
            {
              className: "releaseType",
              value: startCase(toLower(releaseNote.getReleaseType())),
              column: ReleaseNotesListColumn.RELEASE_TYPE,
              customEl: (
                <Chip
                  className={classnames("chip", className, classes.typeChip)}
                  label={startCase(toLower(releaseNote.getReleaseType()))}
                />
              )
            },
            {
              className: "releaseStatus",
              value: startCase(toLower(releaseNote.getReleaseStatus())),
              column: ReleaseNotesListColumn.RELEASE_STATUS,
              customEl: (
                <Chip
                  className={classnames("chip", className, classes.chip,
                    classes[releaseNote.getReleaseStatus().toLowerCase()]
                  )}
                  label={startCase(toLower(releaseNote.getReleaseStatus()))}
                />
              )
            },
            {
              className: "description",
              value: releaseNote.getSummary(),
              column: ReleaseNotesListColumn.DESCRIPTION,
            },
          ]),
        };
      }), [items]),
    onClickItem = React.useCallback((releaseNote: ReleaseNote) => {
      history.push(getPathToReleaseNote(releaseNote));
    }, [history]),
    onClickFilter = noop,
    mapFilterToLabel,
    children,
    ...otherProps
  } = props;

  return (
    <React.Fragment>
      <ModuleListView
        {...otherProps}
        className={classnames("releaseNotes", className, classes.container)}
        listClassName={classnames("releaseNotesList", listClassName, classes.list)}
        showSummary={showSummary}
        showLoadingIndicator={showLoadingIndicator}
        showSearch={showSearch}
        tableLayoutFixed={tableLayoutFixed}
        summaryViewIcon={summaryViewIcon}
        columns={columns}
        items={items}
        listViewItems={listViewItems}
        onClickItem={onClickItem}
        header={(
          <QuickFilters
            className={classnames("quickFilters", classes.quickFilters)}
            filterClassName={"filterByReleaseStatus"}
            title={"Filter By Status: "}
            selectedFilters={selectedFilters}
            filters={filters}
            disabled={showLoadingIndicator}
            onClickFilter={onClickFilter}
            mapFilterToLabel={mapFilterToLabel}
          />
        )}
      >
        {children}
      </ModuleListView>
      <Alert severity="info" className={classnames("releaseNotesAlert", classes.alert)}>
        For older release notes,{" "}<Link className={classes.link} href={archiveLink}>click here</Link>
      </Alert>
    </React.Fragment>
  );
});

export default ReleaseNotes;
