
import { createSelector } from "reselect";
import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { createSelectors, Selector } from "@base";
import {
  DEFAULT_STATE,
  DevicesSchema,
  DeviceItems,
} from "./reducers";
import { Device, DeviceAttributes } from "@data";
import { OrderedMap } from "immutable";

export const {
  devices: getDeviceAttributes,
  deviceId: getDeviceId,
  getErrorMessage,
  getSuccessMessage,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
} = createSelectors<DevicesSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getDevicesMap: Selector<OrderedMap<string, Device>> = createSelector(
  getDeviceAttributes, (devices: DeviceItems) =>
    OrderedMap<string, DeviceAttributes>(devices)
      .map((attrs: DeviceAttributes) => new Device(attrs))
      .toOrderedMap()
);

export const getDevices: Selector<Device[]> = createSelector(
  getDevicesMap, (devices: OrderedMap<string, Device>) =>
    devices.toArray());

export const getResult: Selector<Device[]> = createSelector(
  [getDevices, getDeviceId],
  (devices: Device[], deviceId: string = "") => {
    if (deviceId.length === 0) {
      return [];
    } else {
      return devices;
    }
  }
);

export const getNumResult: Selector<number> = createSelector(
  getResult, (items: Device[]) => items.length);
