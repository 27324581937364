import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { JsonEditor, JsonEditorModel, JsonEditorActions } from "@components/json-editor";
import { DeviceEnrollmentWizardSelectors } from "../../selectors";
import { AddDeviceWizardActions } from "../../actions/";

const mapStateToProps = (state: AppSchema): JsonEditorModel => ({
  className: "deviceEnrollmentJsonEditorView",
  json: DeviceEnrollmentWizardSelectors.getDeviceEnrollmentAsJson(state),
  readOnly: false,
  width: "auto",
  height: "400px",
  name: "deviceEnrollmentJsonEditorView",
  fontSize: 12
});

const mapDispatchToProps = (dispatch: any): JsonEditorActions => ({
  setJson: (json: string) => dispatch(AddDeviceWizardActions.updateDeviceJson(json)),
});

export default connect<JsonEditorModel, JsonEditorActions, JsonEditorModel & JsonEditorActions>(
  mapStateToProps,
  mapDispatchToProps,
)(JsonEditor);
