import React from "react";
import { EmailTemplateRequest } from "@data";
import {
  UseApiRequestModel,
  UseApiRequestProps,
  useApiRequest,
  useEmailTemplateClient,
} from "@hooks";

type SuccessResponse = void;

export interface UseCreateEmailTemplateProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  editMode: boolean;
  accountId: string;
  templateId: string;
  emailTemplateRequest: EmailTemplateRequest;
}

export interface UseCreateEmailTemplateModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseCreateEmailTemplateActions {
  save: () => void;
  reset: () => void;
}

type Props = UseCreateEmailTemplateProps;
type Model = UseCreateEmailTemplateModel;
type Actions = UseCreateEmailTemplateActions;
type Result = [Model, Actions];

export const useCreateEmailTemplate = (props: Props): Result => {

  const {
    accountId = "",
    templateId = "",
    editMode = false,
    emailTemplateRequest,
    defaultErrorMessage = `Failed to ${editMode ? "edit" : "create"} email template`,
    ...otherProps
  } = props;

  const {
    applicationId,
    body: {
      htmlBody,
      ...body
    },
    ...attrs
  } = emailTemplateRequest.toJS();

  const json = JSON.stringify({
    ...attrs,
    // Request body cannot contain an empty string application id property
    ...(!emailTemplateRequest.hasApplicationId() ? ({}) : ({
      applicationId,
    })),
    body: {
      ...body,
      // Request body cannot contain an empty string html body property
      ...(!emailTemplateRequest.hasHtmlBody() ? ({}) : ({
        htmlBody,
      })),
    },
  }, null, "  ");

  const EmailTemplateClient = useEmailTemplateClient();

  const makeApiRequest = React.useCallback(() => editMode ?
    EmailTemplateClient.editEmailTemplate(accountId, templateId, json) :
    EmailTemplateClient.createEmailTemplate(accountId, templateId, json),
    [EmailTemplateClient, editMode, accountId, templateId, json]);

  const [{ showSuccessView, ...baseModel }, { reset, refresh: save }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
      deferRequest: true,
    });

  const successMessage = React.useMemo(() => showSuccessView ?
    `Email Template ${editMode ? "Updated" : "Created"}` : "",
    [editMode, showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    reset,
    save,
  }), [
    reset,
    save,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useCreateEmailTemplate;
