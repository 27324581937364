import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  SubmitApiRequestView,
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
} from "@components/submit-api-request-view/SubmitApiRequestView";
import { DeviceTypeWizardStep } from "../reducers/deviceTypeWizard";
import {
  CREATE_DEVICE_TYPE_V3_WIZARD_STEPS as DEFAULT_STEPS,
  CREATE_DEVICE_TYPE_WIZARD_STEP_LABELS as DEFAULT_STEP_LABELS,
} from "../selectors/deviceTypeWizard";
import DeviceTypeWizardState from "../models/DeviceTypeWizardState";
import styles from "./styles";

export interface Model extends SubmitApiRequestViewModel<DeviceTypeWizardState, DeviceTypeWizardStep> {
  stepLabels?: { [key: string]: string };
}

export interface Actions extends SubmitApiRequestViewActions<DeviceTypeWizardState, DeviceTypeWizardStep> {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DeviceTypeWizard = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    currentState = DeviceTypeWizardState.EMPTY,
    defaultState = DeviceTypeWizardState.EMPTY,
    snackbarId = "device-type-wizard",
    errorTitle = "Save Device Type Failed",
    steps = DEFAULT_STEPS,
    stepLabels = DEFAULT_STEP_LABELS,
    mapStepToLabel = React.useCallback(step => stepLabels[step] || null, [stepLabels]),
    children,
    ...otherProps
  } = props;

  return (
    <SubmitApiRequestView
      {...otherProps}
      className={classnames("deviceTypeWizard", className, classes.container)}
      snackbarId={snackbarId}
      errorTitle={errorTitle}
      steps={steps}
      currentState={currentState}
      defaultState={defaultState}
      mapStepToLabel={mapStepToLabel}
    >
      {children}
    </SubmitApiRequestView>
  );
});

export default DeviceTypeWizard;
