import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { summaryView as styles } from "./styles";
import { SummaryViewModel, SummaryView as DataSetSummaryView, ChipView } from "@components";
import { SummaryViewData } from "@data";
import TagsIcon from "@material-ui/icons/Label";
import OwnerIcon from "@material-ui/icons/AccountBox";

export interface Model extends SummaryViewModel {
  summaryViewItems?: SummaryViewData[];
  dataSetOwners?: string[];
  tags?: string[];
  metadataLinks?: string[];
  loading?: boolean;
  children?: React.ReactNode;
}

type Props = WithStyles<typeof styles> & Model;

export const SummaryView = withStyles(styles)((props: Props) => {

  const {
    classes,
    summaryViewItems,
    dataSetOwners = [],
    tags = [],
    metadataLinks = [],
    loading,
    children
  } = props;

  const showOwners = dataSetOwners.length > 0;
  const showTags = tags.length > 0;
  const showLinks = metadataLinks.length > 0;

  return (
    <div className={classnames("summaryView", classes.container)}>
      <DataSetSummaryView
        items={summaryViewItems}
      />
      {!loading && (
        <React.Fragment>
          {showOwners && (
            <ChipView
              title={"Data Set Owners"}
              items={dataSetOwners}
              className={"owner"}
              icon={OwnerIcon}
            />
          )}
          {showTags && (
            <ChipView
              title={"Tags"}
              items={tags}
              className={"tag"}
              icon={TagsIcon}
              marginTop={showOwners}
            />
          )}
          {showLinks && (
            <ChipView
              title={"Metadata Links"}
              items={metadataLinks}
              className={"link"}
              icon={TagsIcon}
              marginTop={showOwners || showTags}
            />
          )}
        </React.Fragment>
      )}
      {children}
    </div>
  );
});

export default SummaryView;
