import { Comparator, compare } from "@util";
import DataSetsListColumn from "../models/DataSetsListColumn";
import DataSetsListItemData from "../models/DataSetsListItemData";

const compareByName = (left: DataSetsListItemData, right: DataSetsListItemData, ascending: boolean) =>
  compare(
    `${left.getName()} ${left.getDescription()}`,
    `${right.getName()} ${right.getDescription()}`,
    ascending);

const compareByAccount = (left: DataSetsListItemData, right: DataSetsListItemData, ascending: boolean) => {
  const result = compare(left.getAccount(), right.getAccount(), ascending);
  return result !== 0 ? result : compareByName(left, right, ascending);
};

const compareByGroup = (left: DataSetsListItemData, right: DataSetsListItemData, ascending: boolean) => {
  const result = compare(left.getDataSetGroup(), right.getDataSetGroup(), ascending);
  return result !== 0 ? result : compareByName(left, right, ascending);
};

const compareByTags = (left: DataSetsListItemData, right: DataSetsListItemData, ascending: boolean) => {
  const result = compare(left.getTags().join(","), right.getTags().join(","), ascending);
  return result !== 0 ? result : compareByName(left, right, ascending);
};

export const createComparator =
  (sortedColumn: DataSetsListColumn, ascending: boolean): Comparator<DataSetsListItemData> =>
    (left: DataSetsListItemData, right: DataSetsListItemData) => {
      switch (sortedColumn) {
        case DataSetsListColumn.NAME:
          return compareByName(left, right, ascending);
        case DataSetsListColumn.ACCOUNT:
          return compareByAccount(left, right, ascending);
        case DataSetsListColumn.GROUP:
          return compareByGroup(left, right, ascending);
        case DataSetsListColumn.TAGS:
          return compareByTags(left, right, ascending);
        default:
          return 0;
      }
    };

export default createComparator;
