import Color from "@Color";
import { createStyles, StyleRules, Theme } from "@material-ui/core/styles";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
      marginBottom: 30,
    },
    dnsEditor: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "flex-start",
    },
    textField: {
      color: Color.TEXT,
      fontSize: 16,
      height: "auto",
    },
    dnsInput: {
      fontFamily: "Roboto, sans-serif",
      width: "90%",
    },
    dnsNames: {
      marginTop: 15,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    dnsEmptyView: {
      color: Color.GREY3,
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      lineHeight: "14px",
      fontWeight: 300,
      fontStyle: "italic",
      margin: theme.spacing(),
    },
    dnsName: {
      height: 28,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
      margin: theme.spacing(),
      padding: theme.spacing(),
    },
    nameIcon: {
      color: Color.WHITE,
      width: 14,
      height: 14,
      marginLeft: 2,
      marginRight: 2,
      "&:first-child": {
        marginRight: 0,
      },
    },
    addIconButton: {
      marginTop: 16,
      marginLeft: 16,
      width: 32,
      height: 32,
      borderRadius: 32,
      padding: 0,
      backgroundColor: Color.WHITE,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.54)",
      },
    },
  });

export const textFieldTooltip: StyleRules<string> = {
  popper: {
    width: 231,
    height: "auto",
    alignItems: "center",
    display: "flex",
  },
  tooltip: {
    padding: "10px 15px",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px",
    textAlign: "left",
    whiteSpace: "normal",
  },
};

export default styles;
