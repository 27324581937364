import React from "react";
import {
  JsonSchemaDefinition,
  JsonSchemaMetadataAttributes,
  JsonSchemaProperty,
} from "@data";
import { AuthTokenContext } from "@components/auth-token-provider";
import { SchemaRegistryClient, RestClientError } from "../../../network";
import Reducer, { DEFAULT_STATE } from "@components/schema-properties/reducers";
import * as Selectors from "@components/schema-properties/selectors";
import * as Actions from "@components/schema-properties/actions";

interface Model {
  location: string;
  breadcrumbs: string[];
  schema: JsonSchemaDefinition;
  metadata: JsonSchemaMetadataAttributes;
  properties: JsonSchemaProperty[];
  showExternalSchemaRef: boolean;
  externalSchemaRef: string;
  errorMessage: string;
  showAccessDenied: boolean;
  showNotFoundError: boolean;
  showLoadingIndicator: boolean;
  showChildProperties: boolean;
}

interface Actions {
  refresh: () => void;
  clearBreadcrumbs: () => void;
  setBreadcrumbs: (breadcrumbs: string[]) => void;
}

export const useSchemaLocation = (location: string): [Model, Actions] => {

  const [lastRefresh, setLastRefresh] = React.useState(+new Date);

  const [state, dispatch] = React.useReducer(Reducer, { ...DEFAULT_STATE, location });

  const authToken = React.useContext(AuthTokenContext);
  const isValidLocation = Selectors.isValidLocation(state);
  const namespace = Selectors.getNamespace(state);
  const name = Selectors.getName(state);
  const version = Selectors.getVersion(state);

  React.useEffect(() => {

    let ignore = false;

    if (!isValidLocation) {
      dispatch(Actions.setErrorMessage("Invalid Schema Reference"));
      return () => { ignore = true; };
    }

    dispatch(Actions.showLoadingIndicator());
    dispatch(Actions.clearErrorMessage());
    dispatch(Actions.hideAccessDenied());
    dispatch(Actions.setMetadata());
    dispatch(Actions.setSchema());

    Promise.all([
      SchemaRegistryClient.getSchema(authToken, namespace, name, version),
      SchemaRegistryClient.getSchemaMetadata(authToken, namespace, name, version),
    ])
      .then(([ schema, metadata ]) => {

        if (!ignore) {
          dispatch(Actions.setSchema(schema));
          dispatch(Actions.setMetadata(metadata));
          dispatch(Actions.hideLoadingIndicator());
        }

      }, (response: RestClientError) => {

        if (!ignore) {
          const { error = "Fetch schema details failed" } = response;
          dispatch(Actions.setErrorMessage(error));
          dispatch(Actions.hideLoadingIndicator());
          dispatch(Actions.setShowAccessDenied(response.status === 403));
        }
      });

    return () => { ignore = true; };

  }, [
    lastRefresh,
    location,
    dispatch,
    authToken,
    isValidLocation,
    namespace,
    name,
    version,
  ]);

  const data = {
    location: Selectors.getLocation(state),
    breadcrumbs: Selectors.getBreadcrumbs(state),
    schema: Selectors.getRootSchema(state),
    metadata: Selectors.getRootSchemaMetadata(state),
    properties: Selectors.getJsonSchemaProperties(state),
    showExternalSchemaRef: Selectors.isExternalSchemaRefSelected(state),
    externalSchemaRef: Selectors.getExternalSchemaRef(state),
    errorMessage: Selectors.getErrorMessage(state),
    showAccessDenied: Selectors.isAccessDeniedVisible(state),
    showNotFoundError: Selectors.isNotFoundErrorVisible(state),
    showLoadingIndicator: Selectors.isLoadingIndicatorVisible(state),
    showChildProperties: Selectors.isViewingChildProperty(state),
  };

  const api = {
    refresh: () => setLastRefresh(+new Date),
    clearBreadcrumbs: () => dispatch(Actions.clearBreadcrumbs()),
    setBreadcrumbs: (breadcrumbs: string[]) => dispatch(Actions.setBreadcrumbs(breadcrumbs)),
  };

  return [data, api];
};

export default useSchemaLocation;
