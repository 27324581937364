import React from "react";
import classnames from "classnames";
import { events as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DataSetRequestEvent from "@data/DataSetRequestEvent";
import { getStringValue } from "@util";
import {
  ListViewItem,
  ModuleListView,
  ModuleListViewActions,
  ModuleListViewModel,
  createColumns
} from "@components";

export enum EventsColumn {
  NONE = "",
  STATE = "State",
  COMMENTS = "Comments",
  ACTION_PERFORMED_BY = "Action Performed By",
  EVENT_TIME = "Event Time",
}

export const columns: EventsColumn[] = [
  EventsColumn.STATE,
  EventsColumn.COMMENTS,
  EventsColumn.ACTION_PERFORMED_BY,
  EventsColumn.EVENT_TIME,
];

const getActionPerformedByDisplayName = (actionPerformedBy: string) => {
  return getStringValue(actionPerformedBy.split(":").pop());
};

export interface Model extends ModuleListViewModel<DataSetRequestEvent, EventsColumn> {
}

export interface Actions extends ModuleListViewActions<DataSetRequestEvent, EventsColumn> {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const EventsList = withStyles(styles)((props: Props) => {

  const {
    classes,
    items = [],
    ...otherProps
  } = props;

  const moduleListItems: ListViewItem<DataSetRequestEvent>[] = React.useMemo(() =>
    items.map(event => {
      return {
        item: event,
        icon: null,
        columnAttributes: createColumns([
          {
            className: "state",
            value: event.state,
            column: EventsColumn.STATE,
            firstColumn: true,
          },
          {
            className: "comments",
            value: event.comments,
            column: EventsColumn.COMMENTS,
          },
          {
            className: "actionPerformedBy",
            value: getActionPerformedByDisplayName(event.actionPerformedBy),
            column: EventsColumn.ACTION_PERFORMED_BY,
          },
          {
            className: "eventTime",
            value: event.eventTime,
            column: EventsColumn.EVENT_TIME,
          }
        ])
      };
    }), [items]);

  return(
    <div className={classnames("events", classes.container)}>
      <ModuleListView
        {...otherProps}
        className={classnames("eventsList", classes.eventsList)}
        noResultsLabel="No events found"
        columns={columns}
        listViewItems={moduleListItems}
        showIcon={false}
        showSummary={true}
        showRefreshButton={false}
        summaryViewQuantities={{
          other: "Events",
          one: "Event",
        }}
      />
    </div>
  );
});
