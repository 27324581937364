import React from "react";
import classnames from "classnames";
import { ActionMenuItem, ActionsMenu, ActionsMenuProps } from "../actions-menu";
import { noop } from "@util";

export enum PolicyAction {
  EDIT = "edit_policy",
  DELETE = "delete_policy",
}

export const POLICY_ACTION_MENU_ITEMS: ActionMenuItem[] = [
  {
    id: PolicyAction.EDIT,
    name: "Edit Policy",
  },
  {
    id: PolicyAction.DELETE,
    name: "Delete Policy",
  },
];

export interface PolicyActionsMenuProps extends ActionsMenuProps {
  editPolicy: () => void;
  deletePolicy: () => void;
}

export const withPolicyActions = (Component: React.ComponentType<PolicyActionsMenuProps>) =>
  (props: PolicyActionsMenuProps) => {

    const {
      actions = POLICY_ACTION_MENU_ITEMS,
      onClickAction = noop,
      editPolicy = noop,
      deletePolicy = noop,
    } = props;

    return (
      <Component
        {...props}
        actions={actions}
        onClickAction={(action: ActionMenuItem) => {
          if (action.id === PolicyAction.DELETE) {
            deletePolicy();
          } else  if (action.id === PolicyAction.EDIT) {
            editPolicy();
          } else {
            onClickAction(action);
          }
        }}
      />
    );
  };

export const PolicyActionsMenu = withPolicyActions((props: PolicyActionsMenuProps) => {

  const { className, buttonLabel = "Policy Actions" } = props;

  return (
    <ActionsMenu
      {...props}
      className={classnames("policyActionsMenu", className)}
      buttonLabel={buttonLabel}
    />
  );
});

export default PolicyActionsMenu;
