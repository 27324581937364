import React from "react";
import { RouteParams } from "./constants";
import { getPathToReleaseNote, mapUriToPlatformDomain } from "./helpers";
import { Redirect, useLocation, useParams } from "react-router-dom";
import ReleaseNoteDetails from "./containers/ReleaseNoteDetails";
import { checkIfValidUUID } from "@util";
import { useGetReleaseNote } from "@hooks";
import { WaitForApiRequestView } from "@components";

function ReleaseNoteUUIDView() {

  const { version = "" } = useParams<RouteParams>();

  const [{ releaseNote: note, showSuccessView, ...fetchModel } , fetchActions ] =
    useGetReleaseNote({ releaseId: version });

  const { refresh: retry } = fetchActions;

  const redirect = React.useMemo(() => !showSuccessView ? null : (
    <Redirect to={getPathToReleaseNote(note)} />
  ), [showSuccessView, note]);

  return (
    <WaitForApiRequestView
      loadingMessage="Loading Release Note..."
      errorTitle="Failed to load Release Note"
      retry={retry}
      {...fetchModel}
      {...fetchActions}
    >
      {redirect}
    </WaitForApiRequestView>
  );
}

export function ReleaseNoteVersionView() {

  const { version = "" } = useParams<RouteParams>();

  const { pathname } = useLocation();

  const domainPathName = React.useMemo(() => {
    const arr = pathname.split("/");
    arr.splice(-1, 1);
    return arr.join("/");
  }, [pathname]);

  const domain = React.useMemo(() =>
      mapUriToPlatformDomain(domainPathName), [domainPathName]);

  return (
    <ReleaseNoteDetails
      domain={domain}
      version={version}
    />
  );
}

export function ReleaseNoteDetailsView() {

  const { version = "" } = useParams<RouteParams>();

  const isUUIDView = React.useMemo(() => checkIfValidUUID(version), [version]);

  return isUUIDView ?  <ReleaseNoteUUIDView /> :  <ReleaseNoteVersionView /> ;

}

export default ReleaseNoteDetailsView;
