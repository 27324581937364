import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import {
  Model,
  Actions,
  DeviceBootstrapSummaryView
} from "../components/DeviceBootstrapSummaryView";
import {
  getDeviceId,
  isLoadingIndicator,
  getDeviceTypeIdentity,
  getConfigRefresh,
  isNotFoundVisible
} from "../selectors";
import {
  refresh,
  setConfigRefresh,
  setShowLoadingIndicator,
} from "../actions";
import { useDeviceType } from "@hooks";
import {
  DeviceTypeModelV3CredentialType,
  DeviceTypeModelV3DeviceAuthenticationType
} from "@data";
import { isEmptyString, noop } from "@util";

interface ContainerModel extends Model {
  refreshPage?: boolean;
}

interface ContainerActions extends Actions {
  setLoadingIndicator?: (value: boolean) => void;
  setRefresh?: (value: boolean) => void;
  refresh?: () => void;
}

type Props = ContainerModel & ContainerActions;

const DeviceBootstrapSummaryViewContainer = (props: Props) => {

  const {
    loadingIndicator,
    deviceTypeIdentity = "",
    deviceId = "",
    refreshPage = false,
    setLoadingIndicator = noop,
    setRefresh = noop,
    ...otherProps
  } = props;

  const DEVICE_BOOTSTRAP_API = process.env.REACT_APP_DEVICE_BOOTSTRAP_API || "";
  const DEVICE_BOOTSTRAP_CERTIFICATE_API = process.env.REACT_APP_DEVICE_BOOTSTRAP_CERTIFICATE_API || "";

  const namespace = deviceTypeIdentity.split(":")[0] || "";
  const name = deviceTypeIdentity.split(":")[1] || "";
  const version = deviceTypeIdentity.split(":")[2] || "";

  const [ deviceTypeModel, deviceTypeAction ] = useDeviceType(namespace, name, version);

  const {
    deviceType,
    errorMessage,
    showProgressIndicator: loading,
  } = deviceTypeModel;

  const {
    refresh: refreshAction,
  } = deviceTypeAction;

  const credentialType = React.useMemo(() => {
      const type = deviceType.getCredentialType()[0];
      if (typeof type !== undefined || !isEmptyString(type)) {
        return type;
      } else {
        return DeviceTypeModelV3CredentialType.NONE;
      }
  }, [deviceType]);

  const protocol = React.useMemo(() => {
    const deviceProtocol = deviceType.getDeviceAuthenticationType()[0];
    if (typeof deviceProtocol !== undefined || !isEmptyString(deviceProtocol)) {
      return deviceProtocol;
    } else {
      return DeviceTypeModelV3DeviceAuthenticationType.NONE;
    }
  }, [deviceType]);

  const endpoint = React.useMemo(() => {
    if (credentialType === DeviceTypeModelV3CredentialType.HMAC_SECRET) {
      return `GET: ${DEVICE_BOOTSTRAP_API}/device-management/bootstrap/v1/devices/${deviceId}/configuration`;
      } else if (credentialType === DeviceTypeModelV3CredentialType.CERTIFICATE) {
        // tslint:disable-next-line:max-line-length
        return `GET: ${DEVICE_BOOTSTRAP_CERTIFICATE_API}/device-management/bootstrap/v1/devices/${deviceId}/configuration`;
      } else {
      return "";
    }
    }, [credentialType, deviceId, DEVICE_BOOTSTRAP_API, DEVICE_BOOTSTRAP_CERTIFICATE_API]);

  React.useEffect(() => {
    refreshAction();
    setRefresh(false);
  }, [refreshPage]);

  React.useEffect(() => {
    setLoadingIndicator(loading);
  }, [loading]);

  return (
    <DeviceBootstrapSummaryView
      {...otherProps}
      errorMessage={errorMessage}
      deviceId={deviceId}
      loadingIndicator={loadingIndicator}
      deviceTypeIdentity={deviceTypeIdentity}
      credentialType={credentialType}
      endpoint={endpoint}
      protocol={protocol}
    />
    );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  deviceId: getDeviceId(state),
  loadingIndicator: isLoadingIndicator(state),
  deviceTypeIdentity: getDeviceTypeIdentity(state),
  refreshPage: getConfigRefresh(state),
  showNotFound: isNotFoundVisible(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setLoadingIndicator: (value: boolean) => dispatch(setShowLoadingIndicator(value)),
  setRefresh: (value: boolean) => dispatch(setConfigRefresh(value)),
  refresh: () => dispatch(refresh()),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceBootstrapSummaryViewContainer);
