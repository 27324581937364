import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import FileNameFilter, { FileNameFilterActions, FileNameFilterModel } from "../FileNameFilter";
import RefreshButton, {
  RefreshButtonActions,
  RefreshButtonModel,
} from "@components/refresh-button";
import styles from "./styles";

export interface FileNameFilterAndRefreshButtonModel extends FileNameFilterModel, RefreshButtonModel {
  className?: string;
  fileNameFilterClassName?: string;
  refreshButtonClassName?: string;
}

export interface FileNameFilterAndRefreshButton extends FileNameFilterActions, RefreshButtonActions {
  refresh?: () => void;
}

type Model = FileNameFilterAndRefreshButtonModel;
type Actions = FileNameFilterAndRefreshButton;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const FileNameFilterAndRefreshButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    fileNameFilterClassName,
    refreshButtonClassName,
    disabled,
    fileNameFilter,
    fileNameFilterDelay,
    setFileNameFilter,
    loading,
    loadingIndicatorSize = 14,
    ...refreshButtonProps
  } = props;

  return (
    <div className={classnames("fileNameFilterAndRefreshButton", className, classes.container)}>
      <FileNameFilter
        className={classnames("fileNameFilter", fileNameFilterClassName, classes.fileNameFilter)}
        disabled={disabled}
        fileNameFilter={fileNameFilter}
        fileNameFilterDelay={fileNameFilterDelay}
        setFileNameFilter={setFileNameFilter}
      />
      <RefreshButton
        {...refreshButtonProps}
        className={classnames("refreshButton", refreshButtonClassName, classes.refreshButton)}
        iconClassName={classnames("refreshIcon", classes.refreshIcon)}
        loadingIndicatorSize={14}
        loading={loading}
        disabled={disabled || loading}
      />
    </div>
  );
});

export default FileNameFilterAndRefreshButton;
