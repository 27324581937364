import React from "react";
import classnames from "classnames";
import InfoIcon from "@material-ui/icons/Info";
import MinorIcon from "@material-ui/icons/Warning";
import Typography from "@material-ui/core/Typography";
import MajorIcon from "@material-ui/icons/ErrorOutline";
import { QualityReportTrend } from "@data/QualityReport";
import BlockerIcon from "@material-ui/icons/ReportProblem";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrivialIcon from "@material-ui/icons/PanoramaFishEye";
import QualityReportDefects from "@data/QualityReportDefects";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import CriticalIcon from "@material-ui/icons/PriorityHighOutlined";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const getQualityReportTrend = (trendDelta: number) => {
  if (trendDelta === 0) {
    return QualityReportTrend.FLAT;
  } else {
    return trendDelta < 0
      ? QualityReportTrend.TRENDING_DOWN
      : QualityReportTrend.TRENDING_UP;
  }
};

export interface DefectsModel {
  className?: string;
  title?: string;
  currentReport?: QualityReportDefects;
  previousReport?: QualityReportDefects;
  showHistoricalTrends?: boolean;
  trivialSeverityEnabled?: boolean;
}

export interface DefectsActions {
}

type Model = DefectsModel;
type Actions = DefectsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const Defects = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Open Defects",
    currentReport = QualityReportDefects.EMPTY,
    previousReport = QualityReportDefects.EMPTY,
    showHistoricalTrends,
    trivialSeverityEnabled,
    children,
  } = props;

  const numBlockerDefects = React.useMemo(() =>
      currentReport.getNumBlockerDefects(),
    [currentReport]);

  const blockerDefectsTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumBlockerDefects() - previousReport.getNumBlockerDefects()),
    [showHistoricalTrends, currentReport, previousReport]);

  const blockerDefectsTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(blockerDefectsTrendDelta),
    [showHistoricalTrends, blockerDefectsTrendDelta]);

  const numCriticalDefects = React.useMemo(() =>
      currentReport.getNumCriticalDefects(),
    [currentReport]);

  const criticalDefectsTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumCriticalDefects() - previousReport.getNumCriticalDefects()),
    [showHistoricalTrends, currentReport, previousReport]);

  const criticalDefectsTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(criticalDefectsTrendDelta),
    [showHistoricalTrends, criticalDefectsTrendDelta]);

  const numMajorDefects = React.useMemo(() =>
      currentReport.getNumMajorDefects(),
    [currentReport]);

  const majorDefectsTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumMajorDefects() - previousReport.getNumMajorDefects()),
    [showHistoricalTrends, currentReport, previousReport]);

  const majorDefectsTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(majorDefectsTrendDelta),
    [showHistoricalTrends, majorDefectsTrendDelta]);

  const numMinorDefects = React.useMemo(() =>
      currentReport.getNumMinorDefects(),
    [currentReport]);

  const minorDefectsTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumMinorDefects() - previousReport.getNumMinorDefects()),
    [showHistoricalTrends, currentReport, previousReport]);

  const minorDefectsTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(minorDefectsTrendDelta),
    [showHistoricalTrends, minorDefectsTrendDelta]);

  const numInfoDefects = React.useMemo(() =>
      currentReport.getNumInfoDefects(),
    [currentReport]);

  const infoDefectsTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumInfoDefects() - previousReport.getNumInfoDefects()),
    [showHistoricalTrends, currentReport, previousReport]);

  const infoDefectsTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(infoDefectsTrendDelta),
    [showHistoricalTrends, infoDefectsTrendDelta]);

  const numDefects = React.useMemo(() =>
      numBlockerDefects +
      numCriticalDefects +
      numMajorDefects +
      numMinorDefects +
      numInfoDefects,
    [
      numBlockerDefects,
      numCriticalDefects,
      numMajorDefects,
      numMinorDefects,
      numInfoDefects,
    ]);

  const blockerSeverityBarClipPath = React.useMemo(() =>
      numDefects <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numBlockerDefects / numDefects)) * 100}% 0% 0%)`,
    [numDefects, numBlockerDefects]);

  const criticalSeverityBarClipPath = React.useMemo(() =>
      numDefects <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numCriticalDefects / numDefects)) * 100}% 0% 0%)`,
    [numDefects, numCriticalDefects]);

  const majorSeverityBarClipPath = React.useMemo(() =>
      numDefects <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numMajorDefects / numDefects)) * 100}% 0% 0%)`,
    [numDefects, numMajorDefects]);

  const minorSeverityBarClipPath = React.useMemo(() =>
      numDefects <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numMinorDefects / numDefects)) * 100}% 0% 0%)`,
    [numDefects, numMinorDefects]);

  const infoSeverityBarClipPath = React.useMemo(() =>
      numDefects <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numInfoDefects / numDefects)) * 100}% 0% 0%)`,
    [numDefects, numInfoDefects]);

  return (
    <div className={classnames("defects", className, classes.container)}>
      <Typography
        className={classnames("title", classes.title)}
        variant="h5"
      >
        {title}
      </Typography>
      <div className={classnames("content", classes.content)}>
        <div className={classnames("blockerDefects", classes.row)}>
          <BlockerIcon
            className={classnames("blockerIcon", classes.severityIcon, classes.blocker)}
          />
          <Typography className={classnames("severityLabel", classes.severityLabel)}>
            Blocker
          </Typography>
          <div
            className={classnames("blockerSeverityBar", classes.severityBar, classes.blocker)}
            style={{ clipPath: blockerSeverityBarClipPath }}
          />
          <Typography className={classnames("severityCountLabel", classes.severityCountLabel)}>
            {numBlockerDefects}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {blockerDefectsTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {blockerDefectsTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {blockerDefectsTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {blockerDefectsTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: blockerDefectsTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: blockerDefectsTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: blockerDefectsTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({blockerDefectsTrendDelta < 0 ? "" : "+"}{blockerDefectsTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("criticalDefects", classes.row)}>
          <CriticalIcon
            className={classnames("criticalIcon", classes.severityIcon, classes.critical)}
          />
          <Typography className={classnames("severityLabel", classes.severityLabel)}>
            Critical
          </Typography>
          <div
            className={classnames("criticalSeverityBar", classes.severityBar, classes.critical)}
            style={{ clipPath: criticalSeverityBarClipPath }}
          />
          <Typography className={classnames("severityCountLabel", classes.severityCountLabel)}>
            {numCriticalDefects}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {criticalDefectsTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {criticalDefectsTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {criticalDefectsTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {criticalDefectsTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: criticalDefectsTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: criticalDefectsTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: criticalDefectsTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({criticalDefectsTrendDelta < 0 ? "" : "+"}{criticalDefectsTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("majorDefects", classes.row)}>
          <MajorIcon className={classnames("majorIcon", classes.severityIcon, classes.major)}/>
          <Typography className={classnames("severityLabel", classes.severityLabel)}>
            Major
          </Typography>
          <div
            className={classnames("majorSeverityBar", classes.severityBar, classes.major)}
            style={{ clipPath: majorSeverityBarClipPath }}
          />
          <Typography className={classnames("severityCountLabel", classes.severityCountLabel)}>
            {numMajorDefects}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {majorDefectsTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {majorDefectsTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {majorDefectsTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {majorDefectsTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: majorDefectsTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: majorDefectsTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: majorDefectsTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({majorDefectsTrendDelta < 0 ? "" : "+"}{majorDefectsTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("minorDefects", classes.row)}>
          <MinorIcon className={classnames("minorIcon", classes.severityIcon, classes.minor)}/>
          <Typography className={classnames("severityLabel", classes.severityLabel)}>
            Minor
          </Typography>
          <div
            className={classnames("minorSeverityBar", classes.severityBar, classes.minor)}
            style={{ clipPath: minorSeverityBarClipPath }}
          />
          <Typography className={classnames("severityCountLabel", classes.severityCountLabel)}>
            {numMinorDefects}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {minorDefectsTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {minorDefectsTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {minorDefectsTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {minorDefectsTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: minorDefectsTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: minorDefectsTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: minorDefectsTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({minorDefectsTrendDelta < 0 ? "" : "+"}{minorDefectsTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("infoDefects", classes.row)}>
          {trivialSeverityEnabled && (
            <React.Fragment>
              <TrivialIcon
                className={classnames("infoIcon", classes.severityIcon, classes.trivial)}
              />
              <Typography className={classnames("severityLabel", classes.severityLabel)}>
                Trivial
              </Typography>
            </React.Fragment>
          )}
          {!trivialSeverityEnabled && (
            <React.Fragment>
              <InfoIcon
                className={classnames("infoIcon", classes.severityIcon, classes.info)}
              />
              <Typography className={classnames("severityLabel", classes.severityLabel)}>
                Info
              </Typography>
            </React.Fragment>
          )}
          <div
            className={classnames("infoSeverityBar", classes.severityBar, classes.info)}
            style={{ clipPath: infoSeverityBarClipPath }}
          />
          <Typography className={classnames("severityCountLabel", classes.severityCountLabel)}>
            {numInfoDefects}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {infoDefectsTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {infoDefectsTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {infoDefectsTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {infoDefectsTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: infoDefectsTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: infoDefectsTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: infoDefectsTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({infoDefectsTrendDelta < 0 ? "" : "+"}{infoDefectsTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("footer", classes.footer)}>
          <Typography className={classnames("totalCountPrefix", classes.totalCountPrefix)}>
            {numDefects}
            <Typography
              className={classnames("totalCountSuffix", classes.totalCountSuffix)}
              component="span"
            >
              {numDefects === 1 ? "bug" : "bugs"}
            </Typography>
          </Typography>
        </div>
      </div>
      {children}
    </div>
  );
});

export default Defects;
