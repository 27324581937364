import React from "react";
import { ActionMenuItem } from "@components/actions-menu";
import { DEFAULT_SERVICE_ACTION_MENU_ITEMS } from "@components/services-action-menu";
import ServicesListColumn from "./ServicesListColumn";
import SortedSearchResultsList, {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import { SecurityServiceRegional } from "@data";
import { ServiceIcon } from "./styles";
import { noop } from "@util";
import { ServiceAction } from "@components/services-action-menu";
import ModuleListItem from "@components/module-list-view/ModuleListItem";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import { sortSecurityServiceListItems } from "./helpers";
import { getPathToService } from "@modules/securityServiceRegionalDetails/helpers";

export const DEFAULT_REGIONAL_SERVICES_LIST_COLUMNS: ServicesListColumn[] = [
  ServicesListColumn.ID,
  ServicesListColumn.STATE,
  ServicesListColumn.MFA,
  ServicesListColumn.CREATED,
];

export type RegionalServicesListModel = SortedSearchResultsListModel<SecurityServiceRegional, ServicesListColumn>;

export interface RegionalServicesListActions
  extends SortedSearchResultsListActions<SecurityServiceRegional, ServicesListColumn> {
  addServiceToGroup?: (service: SecurityServiceRegional) => void;
  removeServiceFromGroup?: (service: SecurityServiceRegional) => void;
  attachPolicy?: (service: SecurityServiceRegional) => void;
  detachPolicy?: (service: SecurityServiceRegional) => void;
  deleteService?: (service: SecurityServiceRegional) => void;
}

type Props = RegionalServicesListModel & RegionalServicesListActions;

export const RegionalServicesList = (props: Props) => {

  const {
    className = "services",
    listClassName = "servicesList",
    columns = DEFAULT_REGIONAL_SERVICES_LIST_COLUMNS,
    sortByColumn: initialSortByColumn = ServicesListColumn.NONE,
    sortOrderAscending: initialSortOrderAscending = true,
    noResultsLabel = "No services found",
    summaryViewQuantities = {
      other: "services",
      one: "service",
    },
    items = [],
    showSearch = true,
    searchViewHint = "Filter results that start with this name",
    nameFilter = "",
    nameFilterDelay = 250,
    showLoadMoreButton,
    actions = DEFAULT_SERVICE_ACTION_MENU_ITEMS,
    addServiceToGroup = noop,
    removeServiceFromGroup = noop,
    attachPolicy = noop,
    detachPolicy = noop,
    onClickAction = noop,
    deleteService = noop,
    refresh: baseRefresh = noop,
    ...otherProps
  } = props;

  const [sortByColumn, setSortByColumn] = React.useState<string>(initialSortByColumn);

  const [sortOrderAscending, setSortOrderAscending] = React.useState(initialSortOrderAscending);

  const onClickServiceAction = React.useCallback((service: SecurityServiceRegional, action: ActionMenuItem) => {
    switch (action.id) {
      case ServiceAction.DELETE_SERVICE_REGIONAL:
        return deleteService(service);
      case ServiceAction.ADD_SERVICE_TO_GROUP:
        return addServiceToGroup(service);
      case ServiceAction.REMOVE_SERVICE_FROM_GROUP:
        return removeServiceFromGroup(service);
      case ServiceAction.ATTACH_POLICY_TO_SERVICE:
        return attachPolicy(service);
      case ServiceAction.DETACH_POLICY_FROM_SERVICE:
        return detachPolicy(service);
      default:
        return onClickAction(service, action);
    }
  }, [
    deleteService,
    addServiceToGroup,
    removeServiceFromGroup,
    attachPolicy,
    detachPolicy,
    onClickAction,
  ]);

  // Skip sorting if load more button is visible since we only support sorting non-paginated results
  const services = React.useMemo(() =>
      showLoadMoreButton ? items : sortSecurityServiceListItems({ items, sortOrderAscending, sortByColumn }),
    [showLoadMoreButton, items, sortOrderAscending, sortByColumn]);

  const listItems: ListViewItem<SecurityServiceRegional>[] = React.useMemo(() =>
    services.map(service => {
      return {
        item: service,
        pathToDetailsView: getPathToService(service.getId()),
        icon: ServiceIcon,
        columnAttributes: createColumns([
          {
            className: "serviceId",
            value: service.getId(),
            column: ServicesListColumn.ID,
            firstColumn: true,
          },
          {
            className: "serviceCreated",
            value: service.getCreated(),
            column: ServicesListColumn.CREATED,
          },
          {
            className: "serviceState",
            value: service.getServiceState(),
            column: ServicesListColumn.STATE,
          },
          {
            className: "mfaStatus",
            value: service.getMfaStatus(),
            column: ServicesListColumn.MFA,
            checkmark: service.isMfaEnabled(),
          },
        ]),
      };
    }), [services]);

  const refresh = React.useCallback(() => {
    setSortByColumn(initialSortByColumn);
    setSortOrderAscending(initialSortOrderAscending);
    baseRefresh();
  }, [
    initialSortByColumn,
    initialSortOrderAscending,
    setSortByColumn,
    setSortOrderAscending,
    baseRefresh,
  ]);

  return (
    <SortedSearchResultsList
      {...otherProps}
      className={className}
      items={services}
      columns={columns}
      showSearch={showSearch}
      searchViewHint={searchViewHint}
      nameFilter={nameFilter}
      nameFilterDelay={nameFilterDelay}
      showLoadMoreButton={showLoadMoreButton}
      sortOrderAscending={sortOrderAscending}
      sortByColumn={sortByColumn}
      noResultsLabel={noResultsLabel}
      summaryViewIcon={ServiceIcon}
      summaryViewQuantities={summaryViewQuantities}
      onClickAction={onClickServiceAction}
      setSortByColumn={setSortByColumn}
      setSortOrderAscending={setSortOrderAscending}
      refresh={refresh}
      renderItem={(item: SecurityServiceRegional, column: ServicesListColumn) => (
        <ModuleListItem
          column={column}
          item={item}
          listItems={listItems}
        />
      )}
    />
  );
};

export default RegionalServicesList;
