import { Record } from "immutable";
import { getStringValue } from "@util";

export enum ReportStatus {
  REQUESTED = "REQUESTED",
  STARTED = "STARTED",
  FINISHED = "FINISHED",
  ERROR = "ERROR",
}

export enum ReportType {
  USERS = "USERS"
}

export enum ReportFormat {
  CSV = "CSV"
}

export interface UserReportAttributes {
  id: string;
  status: ReportStatus;
  type: ReportType;
  format: ReportFormat;
  link: string;
  accountId: string;
}

export class UserReport extends Record({
  id: "string",
  status: ReportStatus.STARTED,
  type: ReportType.USERS,
  format: ReportFormat.CSV,
  link: "",
  accountId: ""
}) implements UserReportAttributes {

  public static EMPTY: UserReport = new UserReport();

  public readonly id: string;
  public readonly status: ReportStatus;
  public readonly type: ReportType;
  public readonly format: ReportFormat;
  public readonly link: string;
  public readonly accountId: string;

  public getId(): string {
    return getStringValue(this.id);
  }

  public getLink(): string {
    return getStringValue(this.link);
  }

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getStatus(): ReportStatus {
    return this.status;
  }

  public getType(): ReportType {
    return this.type;
  }

  public getFormat(): ReportFormat {
    return this.format;
  }
}
