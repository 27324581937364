import React from "react";
import Color from "@Color";
import Fab from "@components/fab";
import classnames from "classnames";
import { clickHandler } from "@util";
import EditIcon from "@material-ui/icons/Edit";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface EditButtonModel {
  className?: string;
  color?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export interface EditButtonActions {
  edit?: () => void;
}

type Model = EditButtonModel;
type Actions = EditButtonActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const EditButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    disabled = false,
    color = Color.SIGNIFY_GREEN,
    edit,
    style = {},
  } = props;

  return (
    <Fab
      className={classnames("editButton", className, classes.container)}
      disabled={disabled}
      style={{ color, ...style }}
      onClick={clickHandler(edit)}
    >
      <EditIcon className={classnames("icon", classes.icon)} color="inherit" />
    </Fab>
  );
});

export default EditButton;
