import React from "react";
import styles from "./styles";
import classnames from "classnames";
import { clickHandler, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Button, FilterSwitch, NameFilter } from "@components";

export interface WorkloadsFiltersModel {
  className?: string;
  disabled?: boolean;
  filterByReleasedState?: boolean;
  releasedByNameFilter?: string;
  hideClearButton?: boolean;
  clearButtonDisabled?: boolean;
  clearButtonLabel?: string;
}

export interface WorkloadsFiltersActions {
  setFilterByReleasedState?: (value: boolean) => void;
  setReleasedByNameFilter?: (value: string) => void;
  clearFilters?: () => void;
}

type Model = WorkloadsFiltersModel;
type Actions = WorkloadsFiltersActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const WorkloadsFilters = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    disabled,
    hideClearButton,
    filterByReleasedState,
    releasedByNameFilter,
    clearButtonDisabled,
    clearButtonLabel = "Clear Filter",
    setFilterByReleasedState = noop,
    setReleasedByNameFilter = noop,
    clearFilters = noop,
  } = props;

  const showFooter = React.useMemo(() => !hideClearButton, [hideClearButton]);

  const onClickClearFilters = React.useCallback(clickHandler(() => {
    clearFilters();
  }), [clearFilters]);

  return (
    <div
      className={classnames("workloadsFilters",
        "filtersMenu",
        className,
        classes.container, 
        { [classes.showFooter]: showFooter }
      )}
    >
      <FilterSwitch
        className={classnames("workloadReleasedFilterSwitch", classes.filterSwitch)}
        disabled={disabled}
        rightLabel="Show only Released Workloads"
        checked={filterByReleasedState}
        onChange={checked => setFilterByReleasedState(checked)}
      />
      <NameFilter
        className={classnames("searchPrincipal", classes.search)}
        ariaLabel="search-principal"
        fullWidth={true}
        hint="Filter by release principal"
        nameFilter={releasedByNameFilter}
        setNameFilter={setReleasedByNameFilter}
      />
      {showFooter && (
        <div className={classnames("footer", classes.footer)}>
            <Button
              className={classnames("clearFiltersButton", classes.clearFiltersButton)}
              classes={{
                label: classnames("label", classes.clearFiltersButtonLabel),
              }}
              disabled={disabled || clearButtonDisabled}
              onClick={onClickClearFilters}
            >
              {clearButtonLabel}
            </Button>
        </div>
      )}
    </div>
  );
});

export default WorkloadsFilters;
