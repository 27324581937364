import { BASE_URL } from "./constants";
import { DeviceDetailsTabsType } from "@modules/deviceDetails/components/DeviceDetailsTabs";

export const getPathToDevice = (deviceId: string) =>
  `${BASE_URL}/${deviceId}`;

export const getPathToDeviceEnrollmentView = (deviceId: string) =>
  `${getPathToDevice(deviceId)}/${DeviceDetailsTabsType.ENROLLMENT}`;

export const getPathToDeviceConfigurationView = (deviceId: string) =>
  `${getPathToDevice(deviceId)}/${DeviceDetailsTabsType.CONFIGURATION}`;

export const getPathToDeviceBootstrapView = (deviceId: string) =>
  `${getPathToDevice(deviceId)}/${DeviceDetailsTabsType.BOOTSTRAP}`;

export const getPathToDeviceValidationDetailsView = (deviceId: string) =>
  `${getPathToDevice(deviceId)}/${DeviceDetailsTabsType.VALIDATION}`;
