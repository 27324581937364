import React from "react";
import classnames from "classnames";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { SchemaInfoLabel } from "./SchemaInfoLabel";
import { schemaInfo as styles } from "./styles";
import { JsonSchemaMetadata } from "../../../data";

export const SchemaInfo = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  schema: JsonSchemaMetadata,
  version?: string,
}) => {

  const {
    classes,
    className = "schemaInfo",
    schema = JsonSchemaMetadata.EMPTY,
  } = props;

  if (JsonSchemaMetadata.EMPTY.equals(schema)) {
    return (
      <div className={classnames("emptyView", className, classes.container)}>
        <label className={classes.emptyViewLabel}>No Schema Provided</label>
      </div>
    );
  }

  const { version = `${schema.version}` } = props;

  return (
    <div className={classnames(className, classes.container)}>
      <SchemaInfoLabel className="namespace" name="Namespace" value={schema.namespace} />
      <SchemaInfoLabel className="name" name="Name" value={schema.name} />
      <SchemaInfoLabel className="version" name="Version" value={version} />
    </div>
  );
});

export default SchemaInfo;
