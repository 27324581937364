import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import IdView , { Actions, Model } from "../components/IdView";
import { DeviceEnrollmentWizardSelectors } from "../selectors";
import { DeviceEnrollmentWizardActions } from "../actions";

interface ContainerModel extends Model {
  hidden?: boolean;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const BatchIdViewContainer = (props: Props) => {

  const { hidden, ...otherProps } = props;

  if (hidden) {
    return null;
  }

  return <IdView {...otherProps} />;
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => {

  const hidden = !DeviceEnrollmentWizardSelectors.isBatchIdViewSelected(state);

  if (!DeviceEnrollmentWizardSelectors.isDevicesJsonValid(state)) {
    return {
      hidden,
      showInvalidJsonError: true,
      ...ownProps
    };
  }

  return {
    hidden,
    id: DeviceEnrollmentWizardSelectors.getBatchId(state),
    className: "batchIdView",
    title: "Batch ID",
    label: "Batch ID",
    idFieldName: "batchId",
    description: "This unique ID can be used to check the status of all the devices you are enrolling",
    idError: DeviceEnrollmentWizardSelectors.isBatchIdValid(state) ?
      DeviceEnrollmentWizardSelectors.getErrorMessage(state) : "",
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions) => ({
  setId: (batchId: string) => dispatch(DeviceEnrollmentWizardActions.setBatchId(batchId)),
  generateRandomId: () => dispatch(DeviceEnrollmentWizardActions.setBatchId(uuidV4())),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps
)(BatchIdViewContainer);
