import React from "react";
import classnames from "classnames";
import {
  ActionMenuItem,
  ActionsMenu,
  ActionsMenuProps,
} from "@components";
import { noop } from "@util";

export enum WorkloadAction {
  CODE_ACTIONS = "code_actions",
  TRIGGERS_ACTIONS = "triggers_actions",
  STATE_ACTIONS = "state_actions",
  DELETE_WORKLOAD = "delete_workload",
  CLONE_WORKLOAD = "clone_workload",
  EDIT_ACTIONS = "edit_actions",
  EDIT_POLICY = "edit_policy",
  EDIT_DESCRIPTION = "edit_description",
  EDIT_ENTRY_POINT = "edit_entry_point",
  EDIT_TAGS = "edit_tags",
  EDIT_QUERY = "edit_query",
  UPLOAD_CODE = "upload_code",
  DOWNLOAD_CODE = "download_code",
  ADD_SCHEDULE_TRIGGER = "add_schedule_trigger",
  ADD_DATA_LAKE_TRIGGER = "add_data_lake_trigger",
  ADD_WORKLOAD_COMPLETE_TRIGGER = "add_workload_complete_trigger",
  DRAFT_NEW_VERSION = "draft_new_version",
  DRAFT_LATEST_VERSION = "draft_latest_version",
  DRAFT_EXISTING_VERSION = "draft_existing_version",
  DELETE_VERSION = "delete_version",
  SWITCH_PRINCIPAL = "switch_principal",
  RELEASE = "release",
  DEPRECATE = "deprecate",
  DECOMMISSION = "decommission",
}

export const CLONE_WORKLOAD: ActionMenuItem = Object.freeze({
  id: WorkloadAction.CLONE_WORKLOAD,
  name: "Clone Workload",
  disabled: false,
});

export const DELETE_WORKLOAD: ActionMenuItem = Object.freeze({
  id: WorkloadAction.DELETE_WORKLOAD,
  name: "Delete Workload",
  disabled: false,
});

export const EDIT_POLICY: ActionMenuItem = Object.freeze({
  id: WorkloadAction.EDIT_POLICY,
  name: "Edit Policy",
  disabled: false,
});

export const EDIT_DESCRIPTION: ActionMenuItem = Object.freeze({
  id: WorkloadAction.EDIT_DESCRIPTION,
  name: "Edit Description",
  disabled: false,
});

export const EDIT_ENTRY_POINT: ActionMenuItem = Object.freeze({
  id: WorkloadAction.EDIT_ENTRY_POINT,
  name: "Edit Entry Point",
  disabled: false,
});

export const EDIT_TAGS: ActionMenuItem = Object.freeze({
  id: WorkloadAction.EDIT_TAGS,
  name: "Edit Tags",
  disabled: false,
});

export const EDIT_QUERY: ActionMenuItem = Object.freeze({
  id: WorkloadAction.EDIT_QUERY,
  name: "Edit Query",
  disabled: false,
});

export const EDIT_ACTIONS: ActionMenuItem = Object.freeze({
  id: WorkloadAction.EDIT_ACTIONS,
  name: "Edit",
  disabled: false,
  actions: [
    EDIT_POLICY,
    EDIT_DESCRIPTION,
    EDIT_ENTRY_POINT,
    EDIT_QUERY,
    EDIT_TAGS,
  ],
});

export const UPLOAD_CODE: ActionMenuItem = Object.freeze({
  id: WorkloadAction.UPLOAD_CODE,
  name: "Upload Code",
  disabled: false,
});

export const DOWNLOAD_CODE: ActionMenuItem = Object.freeze({
  id: WorkloadAction.DOWNLOAD_CODE,
  name: "Download Code",
  disabled: false,
});

export const ADD_SCHEDULE_TRIGGER: ActionMenuItem = Object.freeze({
  id: WorkloadAction.ADD_SCHEDULE_TRIGGER,
  name: "Add Schedule Trigger",
});

export const ADD_DATA_LAKE_TRIGGER: ActionMenuItem = Object.freeze({
  id: WorkloadAction.ADD_DATA_LAKE_TRIGGER,
  name: "Add Data Lake Trigger",
});

export const ADD_WORKLOAD_COMPLETE_TRIGGER: ActionMenuItem = Object.freeze({
  id: WorkloadAction.ADD_WORKLOAD_COMPLETE_TRIGGER,
  name: "Add Workload Complete Trigger",
});

export const DRAFT_LATEST_VERSION: ActionMenuItem = Object.freeze({
  id: WorkloadAction.DRAFT_LATEST_VERSION,
  name: "From Latest Version",
});

export const DRAFT_EXISTING_VERSION: ActionMenuItem = Object.freeze({
  id: WorkloadAction.DRAFT_EXISTING_VERSION,
  name: "From Existing Version",
});

export const DELETE_VERSION: ActionMenuItem = Object.freeze({
  id: WorkloadAction.DELETE_VERSION,
  name: "Delete Version",
});

export const DRAFT_NEW_VERSION: ActionMenuItem = Object.freeze({
  id: WorkloadAction.DRAFT_NEW_VERSION,
  name: "Draft New Version",
});

export const RELEASE_WORKLOAD: ActionMenuItem = Object.freeze({
  id: WorkloadAction.RELEASE,
  name: "Release Workload",
});

export const DEPRECATE_WORKLOAD: ActionMenuItem = Object.freeze({
  id: WorkloadAction.DEPRECATE,
  name: "Deprecate Workload",
});

export const DECOMMISSION_WORKLOAD: ActionMenuItem = Object.freeze({
  id: WorkloadAction.DECOMMISSION,
  name: "Decommission Workload",
});

export const SWITCH_PRINCIPAL: ActionMenuItem = Object.freeze({
  id: WorkloadAction.SWITCH_PRINCIPAL,
  name: "Switch Principal",
});

export const CODE_ACTIONS: ActionMenuItem = Object.freeze({
  id: WorkloadAction.CODE_ACTIONS,
  name: "Code",
  disabled: false,
  actions: [
    UPLOAD_CODE,
    DOWNLOAD_CODE,
  ],
});

export const TRIGGERS_ACTIONS: ActionMenuItem = Object.freeze({
  id: WorkloadAction.TRIGGERS_ACTIONS,
  name: "Trigger",
  disabled: false,
  actions: [
    ADD_SCHEDULE_TRIGGER,
    ADD_DATA_LAKE_TRIGGER,
  ],
});

export const STATE_ACTIONS: ActionMenuItem = Object.freeze({
  id: WorkloadAction.STATE_ACTIONS,
  name: "State",
  disabled: false,
  actions: [
    RELEASE_WORKLOAD,
    DEPRECATE_WORKLOAD,
    DECOMMISSION_WORKLOAD,
  ],
});

export const DEFAULT_WORKLOAD_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  EDIT_ACTIONS,
  CODE_ACTIONS,
  TRIGGERS_ACTIONS,
  STATE_ACTIONS,
  CLONE_WORKLOAD,
  DRAFT_NEW_VERSION,
  SWITCH_PRINCIPAL,
  DELETE_WORKLOAD,
]) as ActionMenuItem[];

export interface WorkloadActionsMenuProps extends ActionsMenuProps {
  deleteWorkload?: () => void;
  cloneWorkload?: () => void;
  editDescription?: () => void;
  uploadCode?: () => void;
  downloadCode?: () => void;
  draftNewVersion?: (latest: boolean) => void;
  switchPrincipal?: () => void;
  releaseWorkload?: () => void;
  decommissionWorkload?: () => void;
}

export const withWorkloadActions = (Component: React.ComponentType<WorkloadActionsMenuProps>) =>
  (props: WorkloadActionsMenuProps) => {

    const {
      actions = DEFAULT_WORKLOAD_ACTION_MENU_ITEMS,
      deleteWorkload = noop,
      cloneWorkload = noop,
      editDescription = noop,
      uploadCode = noop,
      downloadCode = noop,
      draftNewVersion = noop,
      switchPrincipal = noop,
      releaseWorkload = noop,
      decommissionWorkload = noop,
      onClickAction = noop,
    } = props;

    return (
      <Component
        {...props}
        actions={actions}
        onClickAction={(action: ActionMenuItem) => {
          switch (action.id) {
          case WorkloadAction.DELETE_WORKLOAD:
            return deleteWorkload();
          case WorkloadAction.CLONE_WORKLOAD:
            return cloneWorkload();
          case WorkloadAction.EDIT_DESCRIPTION:
            return editDescription();
          case WorkloadAction.UPLOAD_CODE:
            return uploadCode();
          case WorkloadAction.DOWNLOAD_CODE:
            return downloadCode();
          case WorkloadAction.DRAFT_LATEST_VERSION:
            return draftNewVersion(true);
          case WorkloadAction.DRAFT_EXISTING_VERSION:
            return draftNewVersion(false);
          case WorkloadAction.RELEASE:
            return releaseWorkload();
          case WorkloadAction.DECOMMISSION:
            return decommissionWorkload();
          case WorkloadAction.SWITCH_PRINCIPAL:
            return switchPrincipal();
          default:
            return onClickAction(action);
          }
        }}
      />
    );
  };

export const WorkloadActionsMenu = withWorkloadActions((props: WorkloadActionsMenuProps) => {

  const { className, buttonLabel = "Workload Actions" } = props;

  return (
    <ActionsMenu
      {...props}
      className={classnames("workloadActionsMenu", className)}
      buttonLabel={buttonLabel}
    />
  );
});

export default WorkloadActionsMenu;
