import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { Theme } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AuthTokenProvider, AccountIdProvider, PortalSnackbarProvider, ThemeProvider } from "@components";
import defaultTheme from "./theme";

export interface Model {
  theme?: Theme;
  maxSnack?: number;
  authToken?: string;
  accountId?: string;
  children?: React.ReactNode;
}

export interface Actions {
}

type Props = Model & Actions;

export const AppController = (props: Props) => {

  const {
    authToken,
    accountId,
    maxSnack = 3,
    theme = defaultTheme,
    children,
  } = props;

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <PortalSnackbarProvider maxSnack={maxSnack}>
          <AuthTokenProvider authToken={authToken}>
            <AccountIdProvider accountId={accountId}>
              {children}
            </AccountIdProvider>
          </AuthTokenProvider>
        </PortalSnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default AppController;
