import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getPathToApplication } from "@modules/applicationRegionalDetails/helpers";
import ApplicationWizard from "./containers/ApplicationWizard";
import { Location } from "history";

type LocationProps = {
  from?: Location;
  goBackOnSuccess?: boolean;
};

export function CreateApplicationRegionalView() {

  const history = useHistory();

  const { id: applicationId } = useParams<{ id: string}>();

  const { state: { from = undefined, goBackOnSuccess = false} = {} } = useLocation<LocationProps>();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onSuccess = React.useCallback(() => {
    if (goBackOnSuccess) {
      history.goBack();
    } else if (from) {
      history.replace(from);
    } else {
      history.replace(getPathToApplication(applicationId));
    }
  }, [history, from, goBackOnSuccess, applicationId]);

  return (
    <ApplicationWizard
      key={applicationId}
      cancel={cancel}
      onEditSuccess={onSuccess}
    />
  );
}

export default CreateApplicationRegionalView;
