import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { updateUserId } from "../actions";
import { getUserId, getUserIdErrorMessage, isLoadingIndicatorVisible } from "../selectors";
import UserInfo, { Actions, Model } from "../components/UserInfo";

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  userId: getUserId(state),
  userIdErrorMessage: getUserIdErrorMessage(state),
  showLoadingIndicator: isLoadingIndicatorVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setUserId: (userId: string) => dispatch(updateUserId(userId)),
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(UserInfo);
