import React from "react";
import classnames from "classnames";
import Checkbox from "@material-ui/core/Checkbox";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { rememberMeCheckbox, rememberMeFormControlLabel } from "./styles";
import { checkboxEventHandler, noop } from "../../../../util";

const RememberMeCheckbox = withStyles(rememberMeCheckbox)(Checkbox);
const RememberMeFormControlLabel = withStyles(rememberMeFormControlLabel)(FormControlLabel);

export const RememberMe = (props: {
  className?: string,
  label?: string,
  rememberMe?: boolean,
  setRememberMe?: (rememberMe: boolean) => void,
}) => {

  const {
    className,
    label = "Remember me",
    rememberMe,
    setRememberMe = noop,
  } = props;

  return (
    <RememberMeFormControlLabel
      className={classnames("rememberMe", className)}
      label={label}
      control={(
        <RememberMeCheckbox
          className="rememberMeCheckbox"
          checked={rememberMe}
          onChange={checkboxEventHandler(setRememberMe)}
        />
      )}
    />
  );
};

export default RememberMe;
