import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MODULE_PATH as DETAILS_PAGE_PATH } from "@modules/userDetails/constants";
import {
  close,
  closeUser,
  closeUserCreateAdminNoteFailed,
  closeUserCreateAdminNoteRequest,
  closeUserCreateAdminNoteSuccess,
} from "../actions";
import {
  getAdminNotePlaceholder,
  getContinueButtonLabel,
  getErrorMessage,
  getSuccessMessage,
  getTitle,
  getUserId,
  isAccessDeniedVisible,
  isDialogOpen,
  isLoadingIndicatorVisible,
  isSelfAuthorized,
  isUserIdValid,
} from "../selectors";
import CreateAdminNoteDialog, {
  CreateAdminNoteDialogActions,
  CreateAdminNoteDialogModel,
} from "@components/create-admin-note-dialog";
import CloseUser, { Model as CloseUserModel } from "../components/CloseUser";

interface Model extends CreateAdminNoteDialogModel<string>, CloseUserModel {
}

interface Actions extends CreateAdminNoteDialogActions<string> {
}

type Props = Model & Actions;

const CloseUserDialog = (props: Props) => {

  const { userId, selfAuthorized, cancel: closeDialog = noop } = props;

  const history = useHistory();

  const match = useRouteMatch(DETAILS_PAGE_PATH);

  const isDetailsPageLoaded = React.useMemo(() => match && match.isExact, [match]);

  const goBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onSuccessMessageShown = React.useCallback(() => {
    if (isDetailsPageLoaded) {
      goBack();
    }
    closeDialog();
  }, [isDetailsPageLoaded, goBack, closeDialog]);

  return (
    <CreateAdminNoteDialog {...props} onSuccessMessageShown={onSuccessMessageShown}>
      <CloseUser userId={userId} selfAuthorized={selfAuthorized} />
    </CreateAdminNoteDialog>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  className: "closeUserDialog",
  title: getTitle(state),
  open: isDialogOpen(state),
  item: getUserId(state),
  selfAuthorized: isSelfAuthorized(state),
  loading: isLoadingIndicatorVisible(state),
  accessDenied: isAccessDeniedVisible(state),
  errorMessage: getErrorMessage(state),
  successMessage: getSuccessMessage(state),
  continueButtonLabel: getContinueButtonLabel(state),
  continueButtonDisabled: !isUserIdValid(state),
  userId: getUserId(state),
  placeholder: getAdminNotePlaceholder(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  cancel: () => dispatch(close()),
  confirm: () => dispatch(closeUser()),
  trackCreateAdminNoteRequest: () => dispatch(closeUserCreateAdminNoteRequest()),
  trackCreateAdminNoteSuccess: () => dispatch(closeUserCreateAdminNoteSuccess()),
  trackCreateAdminNoteFailed: analytic => dispatch(closeUserCreateAdminNoteFailed(analytic)),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(CloseUserDialog);
