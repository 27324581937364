import React from "react";
import { Module } from "@data";
import { MODULE_ID } from "./constants";
import { isDialogOpen } from "./selectors";
import { initialize, open } from "./actions";
import { PrimaryIcon } from "@modules/policies/components/styles";
import DeletePolicyDialog from "./containers/DeletePolicyDialog";

export const deletePolicyModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "Delete Policy",
  title: "Delete Policy",
  icon: PrimaryIcon,
  view: <DeletePolicyDialog />,
  onClick: open,
  isDialogOpen,
  initialize,
};

export default deletePolicyModule;
