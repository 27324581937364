import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { isEmptyString, noop } from "@util";
import { useParams } from "react-router-dom";
import {
  approveDataApprovalRequest,
  fetchTermsAndConditionsFailed,
  fetchTermsAndConditionsRequest,
  fetchTermsAndConditionsSuccess,
  refresh,
  rejectDataApprovalRequest,
} from "../actions";
import {
  getActionMenuItems,
  getErrorMessage,
  getPrivacyTermsConditionsId,
  getTrainingCertificateId,
  isNotFoundVisible,
  isLoadingIndicatorVisible,
} from "../selectors";
import DataApprovalRequestDetails, {
  Actions,
  Model,
} from "../components/DataApprovalRequestDetails";
import DetailsViewRoute from "@components/details-view/DetailsViewRoute";
import CommentsView from "./CommentsView";
import SummaryView from "./SummaryView";
import TermsConditionsView from "./TermsConditionsView";
import TrainingCertificateView from "./TrainingCertificateView";

enum DataApprovalRequestDetailsViewRoute {
  SUMMARY_VIEW = "summary",
  COMMENTS_VIEW = "comments",
  TERMS_CONDITIONS_VIEW = "terms-conditions",
  TRAINING_CERTIFICATE_VIEW = "training-certificate",
}

const ROUTES: DetailsViewRoute[] = [
  {
    id: DataApprovalRequestDetailsViewRoute.SUMMARY_VIEW,
    name: "Summary",
    view: SummaryView,
  },
  {
    id: DataApprovalRequestDetailsViewRoute.COMMENTS_VIEW,
    name: "Comments",
    path: "/comments",
    view: CommentsView,
  },
  {
    id: DataApprovalRequestDetailsViewRoute.TERMS_CONDITIONS_VIEW,
    name: "Privacy Terms & Conditions",
    path: "/terms-conditions",
    view: TermsConditionsView,
    hidden: true,
  },
  {
    id: DataApprovalRequestDetailsViewRoute.TRAINING_CERTIFICATE_VIEW,
    name: "Training Certificate",
    path: "/training-certificate",
    view: TrainingCertificateView,
    hidden: true,
  },
];

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
  initialize?: (id: string) => void;
}

type Props = ContainerModel & ContainerActions;

const DataApprovalRequestDetailsContainer = (props: Props) => {

  const {
    routes = ROUTES,
    termsConditionsId,
    trainingCertificateId,
    initialize: initializeModule = noop,
    ...otherProps
  } = props;

  const { id } = useParams<{ id: string }>();

  const [initialized, setInitialized] = React.useState(false);

  const validRoutes = React.useMemo(() =>
    routes.map(route => {
      const { id: routeId } = route;
      if (routeId === DataApprovalRequestDetailsViewRoute.TERMS_CONDITIONS_VIEW) {
        return {
          ...route,
          hidden: isEmptyString(termsConditionsId),
        };
      } else if (routeId === DataApprovalRequestDetailsViewRoute.TRAINING_CERTIFICATE_VIEW) {
        return {
          ...route,
          hidden: isEmptyString(trainingCertificateId),
        };
      } else {
        return route;
      }
    }), [routes, termsConditionsId, trainingCertificateId]);

  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      initializeModule(id);
    }
  }, [id, initialized, setInitialized]);

  return (
    <DataApprovalRequestDetails
      {...otherProps}
      routes={validRoutes}
      trainingCertificateId={trainingCertificateId}
      termsConditionsId={termsConditionsId}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  showLoadingIndicator: isLoadingIndicatorVisible(state),
  showNotFound: isNotFoundVisible(state),
  errorMessage: getErrorMessage(state),
  actions: getActionMenuItems(state),
  trainingCertificateId: getTrainingCertificateId(state),
  termsConditionsId: getPrivacyTermsConditionsId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  refresh: () => dispatch(refresh()),
  approveDataApprovalRequest: () => dispatch(approveDataApprovalRequest()),
  rejectDataApprovalRequest: () => dispatch(rejectDataApprovalRequest()),
  trackDownloadTermsAndConditionsRequestEvent: () => dispatch(fetchTermsAndConditionsRequest()),
  trackDownloadTermsAndConditionsSuccessEvent: () => dispatch(fetchTermsAndConditionsSuccess()),
  trackDownloadTermsAndConditionsErrorEvent: analytic => dispatch(fetchTermsAndConditionsFailed(analytic)),
  ...ownProps,
});

export default connect<Model, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DataApprovalRequestDetailsContainer);
