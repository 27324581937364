import { useApiRequestAnalytics } from "@hooks";
import { EmailTemplateAttributes } from "@data";
import { EmailTemplateClient, GetEmailLanguagesResponse, GetEmailRequirementsResponse } from "@network";

export type CreateEmailTemplateApi = (accountId: string, templateId: string, json: string) => Promise<void>;
export type EditEmailTemplateApi = (accountId: string, templateId: string, json: string) => Promise<void>;
export type DeleteEmailTemplateApi = (accountId: string, templateId: string) => Promise<void>;
export type GetEmailTemplateApi = (accountId: string, templateId: string) => Promise<EmailTemplateAttributes>;
export type GetEmailTemplatesApi = (accountId: string) => Promise<EmailTemplateAttributes[]>;
export type GetEmailTemplateReqsApi = () => Promise<GetEmailRequirementsResponse>;
export type GetEmailTemplateLangApi = () => Promise<GetEmailLanguagesResponse>;
export type ValidateEmailTemplateApi = (json: string) => Promise<void>;

export interface EmailTemplateClientApi {
  createEmailTemplate: CreateEmailTemplateApi;
  editEmailTemplate: EditEmailTemplateApi;
  deleteEmailTemplate: DeleteEmailTemplateApi;
  getEmailTemplate: GetEmailTemplateApi;
  getEmailTemplates: GetEmailTemplatesApi;
  getEmailTemplateReqs: GetEmailTemplateReqsApi;
  getEmailTemplateLang: GetEmailTemplateLangApi;
  validateEmailTemplate: ValidateEmailTemplateApi;
}

enum ActionTypes {
  CREATE_EMAIL_TEMPLATE_REQUEST = "email_template_client/create_email_template_request",
  CREATE_EMAIL_TEMPLATE_SUCCESS = "email_template_client/create_email_template_success",
  CREATE_EMAIL_TEMPLATE_FAILED = "email_template_client/create_email_template_failed",

  EDIT_EMAIL_TEMPLATE_REQUEST = "email_template_client/edit_email_template_request",
  EDIT_EMAIL_TEMPLATE_SUCCESS = "email_template_client/edit_email_template_success",
  EDIT_EMAIL_TEMPLATE_FAILED = "email_template_client/edit_email_template_failed",

  DELETE_EMAIL_TEMPLATE_REQUEST = "email_template_client/delete_email_template_request",
  DELETE_EMAIL_TEMPLATE_SUCCESS = "email_template_client/delete_email_template_success",
  DELETE_EMAIL_TEMPLATE_FAILED = "email_template_client/delete_email_template_failed",

  GET_EMAIL_TEMPLATE_REQUEST = "email_template_client/get_email_template_request",
  GET_EMAIL_TEMPLATE_SUCCESS = "email_template_client/get_email_template_success",
  GET_EMAIL_TEMPLATE_FAILED = "email_template_client/get_email_template_failed",

  GET_EMAIL_TEMPLATES_REQUEST = "email_template_client/get_email_templates_request",
  GET_EMAIL_TEMPLATES_SUCCESS = "email_template_client/get_email_templates_success",
  GET_EMAIL_TEMPLATES_FAILED = "email_template_client/get_email_templates_failed",

  GET_EMAIL_TEMPLATE_REQS_REQUEST = "email_template_client/get_email_template_reqs_request",
  GET_EMAIL_TEMPLATE_REQS_SUCCESS = "email_template_client/get_email_template_reqs_success",
  GET_EMAIL_TEMPLATE_REQS_FAILED = "email_template_client/get_email_template_reqs_failed",
  
  GET_EMAIL_TEMPLATE_LANG_REQUEST = "email_template_client/get_email_template_lang_request",
  GET_EMAIL_TEMPLATE_LANG_SUCCESS = "email_template_client/get_email_template_lang_success",
  GET_EMAIL_TEMPLATE_LANG_FAILED = "email_template_client/get_email_template_lang_failed",

  VALIDATE_EMAIL_TEMPLATE_REQUEST = "email_template_client/validate_email_template_request",
  VALIDATE_EMAIL_TEMPLATE_SUCCESS = "email_template_client/validate_email_template_success",
  VALIDATE_EMAIL_TEMPLATE_FAILED = "email_template_client/validate_email_template_failed",
}

export const useCreateEmailTemplateApi = () =>
  useApiRequestAnalytics(EmailTemplateClient.createEmailTemplate, {
    REQUEST_EVENT: ActionTypes.CREATE_EMAIL_TEMPLATE_REQUEST,
    SUCCESS_EVENT: ActionTypes.CREATE_EMAIL_TEMPLATE_SUCCESS,
    ERROR_EVENT: ActionTypes.CREATE_EMAIL_TEMPLATE_FAILED,
  });

export const useEditEmailTemplateApi = () =>
  useApiRequestAnalytics(EmailTemplateClient.editEmailTemplate, {
    REQUEST_EVENT: ActionTypes.EDIT_EMAIL_TEMPLATE_REQUEST,
    SUCCESS_EVENT: ActionTypes.EDIT_EMAIL_TEMPLATE_SUCCESS,
    ERROR_EVENT: ActionTypes.EDIT_EMAIL_TEMPLATE_FAILED,
  });

export const useDeleteEmailTemplateApi = () =>
  useApiRequestAnalytics(EmailTemplateClient.deleteEmailTemplate, {
    REQUEST_EVENT: ActionTypes.DELETE_EMAIL_TEMPLATE_REQUEST,
    SUCCESS_EVENT: ActionTypes.DELETE_EMAIL_TEMPLATE_SUCCESS,
    ERROR_EVENT: ActionTypes.DELETE_EMAIL_TEMPLATE_FAILED,
  });

export const useGetEmailTemplateApi = () =>
  useApiRequestAnalytics(EmailTemplateClient.getEmailTemplate, {
    REQUEST_EVENT: ActionTypes.GET_EMAIL_TEMPLATE_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_EMAIL_TEMPLATE_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_EMAIL_TEMPLATE_FAILED,
  });

export const useGetEmailTemplatesApi = () =>
  useApiRequestAnalytics(EmailTemplateClient.getEmailTemplates, {
    REQUEST_EVENT: ActionTypes.GET_EMAIL_TEMPLATES_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_EMAIL_TEMPLATES_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_EMAIL_TEMPLATES_FAILED,
  });

export const useGetEmailTemplateReqsApi = () =>
  useApiRequestAnalytics(EmailTemplateClient.getEmailRequirements, {
    REQUEST_EVENT: ActionTypes.GET_EMAIL_TEMPLATE_REQS_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_EMAIL_TEMPLATE_REQS_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_EMAIL_TEMPLATE_REQS_FAILED,
  });

export const useGetEmailTemplateLangApi = () =>
  useApiRequestAnalytics(EmailTemplateClient.getEmailLanguages, {
    REQUEST_EVENT: ActionTypes.GET_EMAIL_TEMPLATE_LANG_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_EMAIL_TEMPLATE_LANG_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_EMAIL_TEMPLATE_LANG_FAILED,
  });

export const useValidateEmailTemplateApi = () =>
  useApiRequestAnalytics(EmailTemplateClient.validateEmailTemplate, {
    REQUEST_EVENT: ActionTypes.VALIDATE_EMAIL_TEMPLATE_REQUEST,
    SUCCESS_EVENT: ActionTypes.VALIDATE_EMAIL_TEMPLATE_SUCCESS,
    ERROR_EVENT: ActionTypes.VALIDATE_EMAIL_TEMPLATE_FAILED,
  });

export const useEmailTemplateClient = (): EmailTemplateClientApi => ({
  createEmailTemplate: useCreateEmailTemplateApi(),
  editEmailTemplate: useEditEmailTemplateApi(),
  deleteEmailTemplate: useDeleteEmailTemplateApi(),
  getEmailTemplate: useGetEmailTemplateApi(),
  getEmailTemplates: useGetEmailTemplatesApi(),
  getEmailTemplateReqs: useGetEmailTemplateReqsApi(),
  getEmailTemplateLang: useGetEmailTemplateLangApi(),
  validateEmailTemplate: useValidateEmailTemplateApi(),
});

export { ActionTypes as EmailTemplateClientActionTypes };

export default useEmailTemplateClient;
