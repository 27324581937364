import { Record } from "immutable";
import {
  DeviceTypeModelV2,
  DeviceTypeModelV2Metadata,
  DeviceTypeModelV2MetadataAttributes,
  DeviceTypeModelV3PropertyModelAttributes,
} from "@data";
import DeviceTypeRequest from "./DeviceTypeRequest";
import DeviceTypeRequestV3 from "./DeviceTypeRequestV3";

export interface DeviceTypeRequestV2MetadataAttributes extends DeviceTypeModelV2MetadataAttributes {
  properties?: any[];
}

export interface DeviceTypeRequestV2Attributes {
  description: string;
  metadata: DeviceTypeRequestV2MetadataAttributes;
}

export class DeviceTypeRequestV2 extends Record({
  description: "",
  metadata: {
    groups: [],
    schemas: [],
    secretsSchema: "",
    tags: [],
    properties: [],
  },
}) implements DeviceTypeRequestV2Attributes, DeviceTypeRequest {

  public static EMPTY: DeviceTypeRequestV2 = new DeviceTypeRequestV2();

  public readonly description: string;
  public readonly metadata: DeviceTypeRequestV2MetadataAttributes;

  public static from(deviceType: DeviceTypeModelV2): DeviceTypeRequestV2 {

    const { description = "", metadata = {} } = deviceType.toJS();

    const {
      properties = [],
      permittedDeviceCredentials,
      mqtt,
      certificates = [],
      ...metadataAttrs
    } = metadata;

    return new DeviceTypeRequestV2({
      description: description || "",
      metadata: {
        ...new DeviceTypeModelV2Metadata(metadataAttrs).toJS(),
        ...(Array.isArray(properties) && properties.length > 0 ? ({ properties }) : ({})),
        ...(typeof permittedDeviceCredentials === "object" ? ({ permittedDeviceCredentials }) : ({})),
        ...(typeof mqtt === "object" ? ({ mqtt }) : ({})),
        ...(Array.isArray(certificates) && certificates.length > 0 ? ({ certificates }) : ({})),
      },
    });
  }

  public static fromDeviceTypeRequestV3(deviceTypeRequest: DeviceTypeRequestV3): DeviceTypeRequestV2 {

    const properties = deviceTypeRequest.getProperties();

    return new DeviceTypeRequestV2({
      description: deviceTypeRequest.getDescription(),
      metadata: {
        ...new DeviceTypeModelV2Metadata({
          groups: deviceTypeRequest.getGroups(),
          schemas: deviceTypeRequest.getSchemas(),
          tags: deviceTypeRequest.getTags(),
        }).toJS(),
        properties: Array.isArray(properties) ? properties : [],
      },
    });
  }

  public getDescription(): string {
    return this.description;
  }

  public getTags(): string[] {

    const { tags } = this.metadata || {};

    return Array.isArray(tags) ? tags : [];
  }

  public getGroups(): string[] {

    const { groups } = this.metadata || {};

    return Array.isArray(groups) ? groups : [];
  }

  public getSchemas(): string[] {

    const { schemas } = this.metadata || {};

    return Array.isArray(schemas) ? schemas : [];
  }

  public getSecretsSchema(): string {

    const { secretsSchema = "" } = this.metadata || {};

    return `${secretsSchema}`.trim();
  }

  public getProperties(): DeviceTypeModelV3PropertyModelAttributes[] {

    const { properties } = this.metadata || {};

    return Array.isArray(properties) ? properties : [];
  }

  public hasSecretsSchema(): boolean {
    return this.getSecretsSchema().length > 0;
  }
}

export default DeviceTypeRequestV2;
