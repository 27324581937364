import ModuleIcon from "@material-ui/icons/Assignment";
import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const summaryView = () =>
  createStyles({
    container: {
      color: primaryColor,
      flex: "1 0 auto",
    },
  });

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      "& .content": {
        "& > div": {
          // The details view component provides us with consistent margin-top for each content view
          marginTop: 0,
        },
      },
    },
  });

export default styles;
