import { Module } from "@data";
import AppSchema from "@schemas";
import { disableModuleId } from "@main/actions";
import { isQualityReportsEnabled } from "@util";
import { isUserPrincipal } from "@main/selectors";
import { createModule } from "@base/createModule";
import { ModuleIcon as icon } from "./components/styles";
import { MODULE_ID, MODULE_PATHS, MODULE_TITLE, RouteParams } from "./constants";
import customBreadcrumbsFactory from "./customBreadcrumbsFactory";
import view from "./QualityReportDetailsView";

export const qualityReportDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATHS,
  exactPath: false,
  strictPath: false,
  showBreadcrumbs: true,
  name: MODULE_TITLE,
  title: MODULE_TITLE,
  className: "qualityReportDetailsModule",
  icon,
  view,
  customBreadcrumbsFactory,
  disabled: !isQualityReportsEnabled(),
  // In addition to checking environment variable to determine if module should
  // be enabled, we must also only enable this module if a user principal is active.
  //
  // We do not need to make the additional environment check because this will only run if
  // the module's disabled flag is not false
  initialize: () => (dispatch: any, getState: () => AppSchema) => {
    const state = getState();
    if (!isUserPrincipal(state)) {
      return dispatch(disableModuleId(MODULE_ID));
    }
    return Promise.resolve();
  }
});

export default qualityReportDetailsModule;
