import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useSecurityServicesRegionalClient
} from "@hooks";

type SuccessResponse = void;

export interface UseDeleteServiceMfaProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  code: string;
  id?: string;
}

export interface UseDeleteServiceMfaModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseDeleteServiceMfaActions extends UseApiRequestActions<SuccessResponse> {
  deleteAuthenticator: () => void;
}

type Props = UseDeleteServiceMfaProps;
type Model = UseDeleteServiceMfaModel;
type Actions = UseDeleteServiceMfaActions;
type Result = [Model, Actions];

export const useDeleteServiceMfa = (props: Props): Result => {

  const {
    code,
    id = "",
    defaultErrorMessage = "Failed to disable multi-factor authentication",
    ...otherProps
  } = props;

  const SecurityServiceClient = useSecurityServicesRegionalClient();

  const makeApiRequest = React.useCallback(() =>
      SecurityServiceClient.deleteMultifactorAuthenticatorRegionalApi(code, id),
    [SecurityServiceClient, code, id]);

  const [{ showSuccessView, ...baseModel }, { refresh, ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Multi-factor authentication disabled" : "",
    [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    showSuccessView,
    successMessage,
  }), [
    baseModel,
    showSuccessView,
    successMessage,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    refresh,
    deleteAuthenticator: refresh,
  }), [
    baseActions,
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDeleteServiceMfa;
