import React from "react";
import classnames from "classnames";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { schemasView as styles } from "./styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import { noop } from "@util";

export interface Model {
  className?: string;
  title?: string;
  isConfigurationSelected?: boolean;
  isMetadataSelected?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  onChangeSchemaViewMode?: (event: any) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SchemasList = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Device Type Schemas",
    isConfigurationSelected,
    isMetadataSelected,
    onChangeSchemaViewMode = noop,
    children
  } = props;

  return (
    <div className={classnames("deviceTypeSchemasList", className, classes.container)}>
      <label className={classnames("title", classes.title)}>{title}</label>
      <RadioGroup
        className={classnames("radioGroup", "schemasRadioGroup")}
        row={true}
      >
        <FormControlLabel
          className={classnames("radio", "configuration", classes.radioLabel)}
          label="Configuration"
          control={(
            <Radio
              value="configuration"
              checked={isConfigurationSelected}
              onChange={onChangeSchemaViewMode}
            />
          )}
        />
        <FormControlLabel
          className={classnames("radio", "metadata", classes.radioLabel)}
          label="Metadata"
          control={(
            <Radio
              value="metadata"
              checked={isMetadataSelected}
              onChange={onChangeSchemaViewMode}
            />
          )}
        />
      </RadioGroup>
      {children}
    </div>
  );
});

export default SchemasList;
