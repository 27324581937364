import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
    },
    header: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    title: {
      color: Color.TEXT,
      margin: 0,
      fontWeight: 400,
      fontSize: 14,
    },
    searchFilters: {
    },
  });

export const filters = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "flex-start",
      marginLeft: 10,
    },
    searchFilter: {
      height: 28,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "12px",
      paddingLeft: 4,
      paddingRight: 4,
      marginLeft: 10,
      backgroundColor: Color.LIGHT_GREY6,
      "&:hover": {
        backgroundColor: Color.LIGHT_GREY3,
      },
      "&:first-child": {
        marginTop: 0,
      },
      "& span": {
        marginLeft: 2,
        marginRight: 2,
      },
    },
    selected: {
      backgroundColor: Color.SIGNIFY_GREEN,
      "&:hover": {
        backgroundColor: Color.SIGNIFY_GREEN2,
      }
    },
  });

export default styles;
