import { Module } from "@data";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./CreateServiceView";

export const createServiceRegionalModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Create Service",
  title: "Create New Service",
  className: "createServiceRegionalModule",
  primaryColor,
  icon,
  view,
});

export default createServiceRegionalModule;
