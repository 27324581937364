import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { DataSetRequestWizardActions } from "../../actions";
import { DataSetRequestWizardSelectors } from "../../selectors";
import TermsConditions, {
  TermsConditionsActions as Actions,
  TermsConditionsModel as Model,
} from "../../components/TermsConditions";
import useTermsConditionsFile from "./useTermsConditionsFile";

interface ContainerModel extends Model {
  hidden?: boolean;
  termsConditionsId?: string;
  termsConditionsFile?: File | null;
}

interface ContainerActions extends Actions {
  onChangeTermsConditionsFile?: (file: File | null) => void;
  trackAddTermsConditionsFile?: () => void;
  trackRemoveTermsConditionsFile?: () => void;
}

type Props = ContainerModel & ContainerActions;

const TermsConditionsContainer = (props: Props) => {

  const {
    hidden,
    termsConditionsId: initialTermsConditionsId = "",
    termsConditionsFile: initialTermsConditionsFile = null,
    onChangeTermsConditionsFile = noop,
    trackAddTermsConditionsFile = noop,
    trackRemoveTermsConditionsFile = noop,
    ...otherProps
  } = props;

  const [{
    termsConditionsId,
    termsConditionsFile,
    termsConditionsFileName,
    ...model
  }, {
    removeTermsConditionsFile,
    setTermsConditionsFile,
    ...actions
  }] = useTermsConditionsFile({
    termsConditionsId: initialTermsConditionsId,
    termsConditionsFile: initialTermsConditionsFile,
  });

  const trackAndSetTermsConditionsFile = React.useCallback((file: File | null) => {
    if (file) {
      trackAddTermsConditionsFile();
    }
    setTermsConditionsFile(file);
  }, [trackAddTermsConditionsFile, setTermsConditionsFile]);

  const trackAndRemoveTermsConditionsFile = React.useCallback(() => {
    trackRemoveTermsConditionsFile();
    removeTermsConditionsFile();
  }, [trackRemoveTermsConditionsFile, removeTermsConditionsFile]);

  React.useEffect(() => {
    onChangeTermsConditionsFile(termsConditionsFile);
  }, [termsConditionsFile, onChangeTermsConditionsFile]);

  if (hidden) {
    return null;
  }

  return (
    <TermsConditions
      {...model}
      {...actions}
      {...otherProps}
      termsConditionsId={termsConditionsId}
      file={termsConditionsFile}
      fileName={termsConditionsFileName}
      setTermsConditionsFile={trackAndSetTermsConditionsFile}
      removeTermsConditionsFile={trackAndRemoveTermsConditionsFile}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  hidden: !DataSetRequestWizardSelectors.isTermsConditionsViewSelected(state),
  termsConditionsId: DataSetRequestWizardSelectors.getTermsConditionsId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  trackAddTermsConditionsFile: () => dispatch(DataSetRequestWizardActions.trackAddTermsConditionsFile()),
  trackRemoveTermsConditionsFile: () => dispatch(DataSetRequestWizardActions.trackRemoveTermsConditionsFile()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps
)(TermsConditionsContainer);
