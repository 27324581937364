import React from "react";
import { Policy } from "@data";
import classnames from "classnames";
import { ContinueButton, POLICY_ACTION_MENU_ITEMS } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { PoliciesList, PoliciesListActions, PoliciesListModel } from "@components/policies-list";
import styles from "./styles";

export interface Model extends PoliciesListModel {
  createPolicyButtonLabel?: string;
}

export interface Actions extends PoliciesListActions {
  showCreatePolicyWizard?: () => void;
  showPolicyDetails?: (policy: Policy) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const Policies = withStyles(styles)((props: Props) => {

  const {
    classes,
    createPolicyButtonLabel = "Create Policy",
    actions = POLICY_ACTION_MENU_ITEMS,
    showCreatePolicyWizard,
    showPolicyDetails,
    children,
    ...otherProps
  } = props;

  return (
    <PoliciesList
      {...otherProps}
      summaryViewLabel="Policies"
      className={classnames("policies", classes.container)}
      listClassName={classnames("policiesList", classes.list)}
      showSummary={true}
      showSearch={true}
      actions={actions}
      onClickItem={showPolicyDetails}
    >
      <ContinueButton
        className={classnames("createPolicyButton", "createButton", classes.createButton)}
        label={createPolicyButtonLabel}
        onClick={showCreatePolicyWizard}
      />
      {children}
    </PoliciesList>
  );
});

export default Policies;
