import React from "react";
import { DeviceDataModel } from "@data";
import { useHistory } from "react-router-dom";
import { getPathToDeviceEnrollment } from "@modules/deviceEnrollmentDetails/helpers";
import { getPathToDeviceValidationDetails } from "@modules/deviceValidationDetails/helpers";
import DeviceEnrollmentWizard from "./containers/DeviceEnrollmentWizard";

export function DeviceEnrollmentWizardView() {

  const history = useHistory();

  const showDeviceEnrollmentDetails = React.useCallback(
    (batchId: string, deviceRequests: DeviceDataModel[] = [] ) => {
    history.replace(getPathToDeviceEnrollment(batchId) , {
      batchId,
      deviceRequests : deviceRequests.map((request: DeviceDataModel = DeviceDataModel.EMPTY) => request.toJS())
    });
  }, [history]);

  const showDeviceValidationDetails = React.useCallback(
    (deviceId: string, deviceRequest: DeviceDataModel = DeviceDataModel.EMPTY.toJS()) => {
      history.replace(getPathToDeviceValidationDetails(deviceId), {
        deviceRequest: deviceRequest.toJS(),
      });
    }, [history]);

  return (
    <DeviceEnrollmentWizard
      showDeviceEnrollmentDetails={showDeviceEnrollmentDetails}
      showDeviceValidationDetails={showDeviceValidationDetails}
    />
  );
}

export default DeviceEnrollmentWizardView;
