import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { FederationProviderWizardActions } from "../actions";
import { FederationProviderWizardSelectors } from "../selectors";
import EditorView, { Model, Actions } from "../components/EditorView";

const mapStateToProps = (state: AppSchema): Model => ({
  json: FederationProviderWizardSelectors.getJson(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  setJson: (value: string) => dispatch(FederationProviderWizardActions.updateJson(value)),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(EditorView);
