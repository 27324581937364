import React from "react";
import { ManagedPolicy, Policy, SecurityServiceRegional, User } from "@data";
import { useHistory, useParams } from "react-router-dom";
import { getPathToUser } from "@modules/userDetails/helpers";
import { getPathToPolicy } from "@modules/policyDetails/helpers";
import { getPathToService } from "@modules/securityServiceRegionalDetails/helpers";
import {
  getAttachPoliciesToGroupPath,
  getDetachPoliciesFromGroupPath,
} from "@modules/policyManagement/helpers";
import {
  getAddGroupServicePath,
  getAddGroupUserPath,
  getRemoveGroupServicePath,
  getRemoveGroupUserPath,
} from "@modules/groupManagementWizard/helpers";
import SecurityGroupDetails from "./containers/SecurityGroupDetails";
import { getPathToManagedPolicy } from "@modules/managedPolicyDetails/helpers";
import { MODULE_PATH as SECURITY_GROUPS_LIST_PATH } from "@modules/securityGroups/constants";

export function SecurityGroupDetailsView() {

  const history = useHistory();

  const { id: groupName } = useParams<{ id: string }>();

  const showListView = React.useCallback(() => {
    history.push(SECURITY_GROUPS_LIST_PATH);
  }, [history]);

  const attachPolicy = React.useCallback(() =>
    history.push(getAttachPoliciesToGroupPath(groupName)), [history, groupName]);

  const detachPolicy = React.useCallback(() =>
    history.push(getDetachPoliciesFromGroupPath(groupName)), [history, groupName]);

  const addUser = React.useCallback(() =>
    history.push(getAddGroupUserPath(groupName)), [history, groupName]);

  const removeUser = React.useCallback(() =>
    history.push(getRemoveGroupUserPath(groupName)), [history, groupName]);

  const addService = React.useCallback(() =>
    history.push(getAddGroupServicePath(groupName)), [history, groupName]);

  const removeService = React.useCallback(() =>
    history.push(getRemoveGroupServicePath(groupName)), [history, groupName]);

  const showUserDetails = React.useCallback((user: User) =>
    history.push(getPathToUser(user.getUserId())), [history]);

  const showServiceDetails = React.useCallback((service: SecurityServiceRegional) =>
    history.push(getPathToService(service.getId())), [history]);

  const showPolicyDetails = React.useCallback((policy: Policy) =>
    history.push(getPathToPolicy(policy.getName())), [history]);

  const showManagedPolicyDetails = React.useCallback((policy: ManagedPolicy) =>
    history.push(getPathToManagedPolicy(policy.getPolicy().getName(), policy.getAccountId())), [history]);

  return (
    <SecurityGroupDetails
      groupName={groupName}
      addUser={addUser}
      removeUser={removeUser}
      addService={addService}
      removeService={removeService}
      attachPolicy={attachPolicy}
      detachPolicy={detachPolicy}
      showListView={showListView}
      showUserDetails={showUserDetails}
      showServiceDetails={showServiceDetails}
      showManagedPolicyDetails={showManagedPolicyDetails}
      showPolicyDetails={showPolicyDetails}
    />
  );
}

export default SecurityGroupDetailsView;
