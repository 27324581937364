import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { setNameFilter } from "../actions";
import { getNameFilter } from "../selectors";
import { getAccountId } from "@main/selectors";
import { useProfileAttributeDefinitions } from "@hooks";
import ProfileAttributeDefinitions, {
  Actions,
  Model,
} from "../components/ProfileAttributeDefinitions";

export interface ContainerModel extends Model {
  accountId?: string;
}

export interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const ProfileAttributeDefinitionsContainer = (props: Props) => {

  const {
    accountId = "",
    ...otherProps
  } = props;

  const [{ attributes = [], ...otherModel }, { refresh, ...otherActions }]
    = useProfileAttributeDefinitions({ accountId });

  return (
    <ProfileAttributeDefinitions
      {...otherProps}
      {...otherModel}
      {...otherActions}
      refresh={refresh}
      items={attributes}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): ContainerModel => ({
  nameFilter: getNameFilter(state),
  accountId: getAccountId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): ContainerActions => ({
  setNameFilter: (nameFilter: string) => dispatch(setNameFilter(nameFilter)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileAttributeDefinitionsContainer);
