import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "flex-start",
    },
    fullscreen: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "center",
      alignContent: "center",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: Color.SIGNIFY_GREEN,
    },
    label: {
      color: Color.WHITE,
      fontFamily: "Roboto, sans-serif",
      fontSize: 48,
      fontWeight: 400,
      lineHeight: "48px",
      margin: "16px 8px",
    },
    button: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
      textTransform: "none",
      color: Color.WHITE,
    },
  });

export default styles;
