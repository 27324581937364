import React from "react";
import { noop } from "@util";
import { Module } from "@data";
import { isArray } from "lodash";
import modules from "@main/modules";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { toggleShowFeedbackDialog } from "@main/actions";
import { isFeedbackDialogOpen } from "@main/selectors";
import { useSubmitUserFeedback, useScreenshot } from "@hooks";
import isUserFeedbackScreenshotEnabled from "@util/isUserFeedbackScreenshotEnabled";
import { SendFeedbackDialog, Actions, Model } from "../components/SendFeedbackDialog";

interface ContainerModel extends Model {
  modules?: Module[];
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const SendFeedbackDialogContainer = (props: Props) => {

  const { isDialogOpen, showFeedbackDialog = noop, modules: moduleList = [], ...otherProps } = props;

  const [feedback, setFeedback] = React.useState("");

  const [{image = "", isLoading}, {takeScreenshot, clear}] = useScreenshot();

  const base64ImageData = React.useMemo(() =>
    image.split(",").pop(), [image]);

  const location = useLocation();

  const screenshotDisabledModules = React.useMemo(() =>
      moduleList.filter(({feedbackScreenshotDisabled}) => feedbackScreenshotDisabled),
    [moduleList]);

  const disableScreenshot = React.useMemo(() => {
    return !isUserFeedbackScreenshotEnabled() || screenshotDisabledModules.some(
      ({
         isNavLinkActive = () => false,
         exactPath: isExact = false,
         path = ""
       }) =>
        isNavLinkActive({
          path: isArray(path) ? path[0] : path, url: location.pathname, isExact, params: ""
        }, location)
    );
  }, [screenshotDisabledModules, location]);

  const [
    {showSuccessView, showLoadingIndicator, successMessage, errorMessage},
    {submitFeedback, reset}] = useSubmitUserFeedback({
    message: feedback,
    imageData: base64ImageData,
  });

  const closeDialog = React.useCallback(() => {
    clear();
    reset();
    setFeedback("");
    showFeedbackDialog(false);
  }, [showFeedbackDialog, clear, setFeedback, reset]);

  const onUpdateSuccess = React.useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  React.useEffect(() => {
    showFeedbackDialog(false);
  }, [showSuccessView]);

  return (
    <SendFeedbackDialog
      {...otherProps}
      open={isDialogOpen}
      image={image}
      errorMessage={errorMessage}
      successMessage={successMessage}
      apiLoading={showLoadingIndicator}
      feedback={feedback}
      imageLoading={isLoading}
      takeScreenshot={takeScreenshot}
      clear={clear}
      submitFeedback={submitFeedback}
      closeDialog={closeDialog}
      setFeedback={setFeedback}
      onSuccessMessageShown={onUpdateSuccess}
      disableScreenshot={disableScreenshot}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  isDialogOpen: isFeedbackDialogOpen(state),
  modules: modules,
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  showFeedbackDialog: (value: boolean) => dispatch(toggleShowFeedbackDialog(value)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(SendFeedbackDialogContainer);
