import { isEmptyString } from "@util";
import { createSelector } from "reselect";
import { ChangePasswordSchema, ChangePasswordSteps, DEFAULT_STATE } from "./reducers";
import { createSelectors, Selector } from "@base/createSelectors";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";

export const {
  userId: getUserId,
  password: getPassword,
  step: getChangePasswordStep,
  open: isOpen,
  showPassword: isShowPassword,
  getErrorMessage,
  getSuccessMessage,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  getAccessToken,
} = createSelectors<ChangePasswordSchema>(moduleId, schemaKey, DEFAULT_STATE);

export const isUserIdValid: Selector<boolean> = createSelector(
  getUserId, userId => !isEmptyString(userId));

export const isPasswordValid: Selector<boolean> = createSelector(
  getPassword, password => password.length >= 32);

export const isSaveButtonEnabled: Selector<boolean> = createSelector(
  [isUserIdValid, isPasswordValid], (userId: boolean, password: boolean) => {
    return userId && password;
  });

export const isPasswordStep: Selector<boolean> = createSelector(
  getChangePasswordStep, step => step === ChangePasswordSteps.PASSWORD
);

export const isConfirmStep: Selector<boolean> = createSelector(
  getChangePasswordStep, step => step === ChangePasswordSteps.CONFIRM
);
