import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { ManageSoftwareVersionsActions } from "../actions";
import { ManageSoftwareVersionsSelectors } from "../selectors";
import {
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel,
} from "@components";
import RemoveSoftwareVersion from "./RemoveSoftwareVersion";

type Model = ConfirmationDialogModel<string>;
type Actions = ConfirmationDialogActions<string>;

const RemoveSoftwareVersionDialog = (props: Model & Actions) => {

  const { open = false } = props;

  if (!open) {
    return null;
  }

  return (
    <ConfirmationDialog {...props}>
      <RemoveSoftwareVersion />
    </ConfirmationDialog>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  className: "removeSoftwareVersionDialog",
  title: "Remove Software Version",
  continueButtonLabel: "Remove Software Version",
  open: ManageSoftwareVersionsSelectors.isRemoveDialogVisible(state),
  item: ManageSoftwareVersionsSelectors.getSoftwareVersion(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  cancel: () => dispatch(ManageSoftwareVersionsActions.hideRemoveDialog()),
  confirm: (softwareVersion: string) => {
    dispatch(ManageSoftwareVersionsActions.hideRemoveDialog());
    dispatch(ManageSoftwareVersionsActions.removeSoftwareVersion(softwareVersion));
  },
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveSoftwareVersionDialog);
