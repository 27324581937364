import { isEmptyString } from "@util";
import { createSelector } from "reselect";
import { createSelectors, Selector } from "@base";
import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { ActionMenuItem } from "@components/actions-menu";
import { DataSetsSchema, DEFAULT_STATE } from "./reducers";
import { REQUEST_ACCESS } from "@components/data-set-actions-menu";

export const {
  accountFilter: getAccountFilter,
  groupFilter: getGroupFilter,
  dataSetTypeFilter: getDataSetTypeFilter,
  aliasContainsFilter: getAliasContainsFilter,
  currentAccountFilterEnabled: isCurrentAccountFilterEnabled,
  getCurrentAccountId,
  isUserPrincipal,
} = createSelectors<DataSetsSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getAccountIdFilter: Selector<string> = createSelector(
  [isCurrentAccountFilterEnabled, getCurrentAccountId, getAccountFilter],
  (currentAccountFilterEnabled: boolean, currentAccountId: string, accountFilter: string) =>
    currentAccountFilterEnabled ? (isEmptyString(accountFilter) ? currentAccountId : accountFilter) : "");

export const isCreateButtonVisible: Selector<boolean> = createSelector(
  isUserPrincipal, (userPrincipal: boolean) => userPrincipal);

export const getActionMenuItems: Selector<ActionMenuItem[]> = createSelector(
  isUserPrincipal, (userPrincipal: boolean) => ([
    {
      ...REQUEST_ACCESS,
      hidden: !userPrincipal,
    },
  ]));
