import React from "react";
import { Location } from "history";
import { equalsIgnoreCase } from "@util";
import { Redirect, useLocation } from "react-router-dom";
import { MODULE_PATH as LOGIN_PATH, DEFAULT_REDIRECT_URI } from "./constants";
import { LoginPageQueryParams, mapSearchToLoginPageQueryParams } from "@hooks";

export interface LoginPageRedirectState extends LoginPageQueryParams {
  from?: Location;
  redirectUri?: string;
}

export const useLoginPageRedirectState = (): LoginPageRedirectState => {

  const { state = {} } = useLocation<LoginPageRedirectState>() || {};

  return state || {};
};

export const LoginPageRedirect = () => {

  const from = useLocation();

  const state = React.useMemo(() => {

    const { pathname: redirectUri = DEFAULT_REDIRECT_URI, search = "" } = from;

    const loginPageRedirectUri = redirectUri || DEFAULT_REDIRECT_URI;

    return {
      from,
      ...mapSearchToLoginPageQueryParams(search),
      redirectUri: equalsIgnoreCase("/login", loginPageRedirectUri) ? DEFAULT_REDIRECT_URI : loginPageRedirectUri,
    };
  }, [from]);

  return (
    <Redirect to={{ pathname: LOGIN_PATH, state }} />
  );
};

export default LoginPageRedirect;
