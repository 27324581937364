import { Module } from "@data";
import { reset } from "./actions";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { isCommonServicesModulesDisabled } from "@util";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { isAccessDeniedVisible } from "./selectors";
import view from "./CreateNamespaceView";

export const createNamespaceModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Create Namespace",
  title: "Create New Namespace",
  className: "createNamespaceModule",
  primaryColor,
  icon,
  view,
  isAccessDeniedVisible,
  onMount: reset,
  disabled: isCommonServicesModulesDisabled(),
});

export default createNamespaceModule;
