import { createStyles, Theme } from "@material-ui/core/styles";

export const styles = (theme: Theme) =>
  createStyles({
    dialog: {
    },
    content: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
    },
    requiredFields: {
      flex: "0 0 275px",
      display: "flex",
      flexFlow: "column nowrap",
      marginRight: 20,
    },
    requiredFieldsTitle: {
      margin: 0,
      padding: 0,
      fontSize: 21,
      fontWeight: 400,
      lineHeight: "24px",
    },
    requiredField: {
      marginTop: 15,
    },
    jsonEditor: {
      flex: "1 0 auto",
    },
    backdrop: {
      background: "rgba(105, 105, 105, 0.25)",
      zIndex: theme.zIndex.modal,
    },
  });

export default styles;
