import React from "react";
import { Breadcrumb, Module } from "@data";
import { equalsIgnoreCase, getStringValue, isEmptyString } from "@util";
import {
  DASHBOARDS_MODULE_ID,
  DASHBOARDS_MODULE_PATH,
  DASHBOARDS_MODULE_TITLE,
  QUERY_DATA_MODULE_PATH,
  QUERY_SCHEDULES_MODULE_PATH,
  USER_DATABASE_MODULE_PATH,
} from "./constants";
import DashboardsIcon from "@material-ui/icons/Dashboard";
import Storage from "@material-ui/icons/Storage";
import Assessment from "@material-ui/icons/Assessment";
import ScheduleIcon from "@material-ui/icons/Schedule";
import PortletContainer from "./containers/PortletContainer";
import SupersetPortletContainer from "@modules/portlet/containers/SupersetPortletContainer";
import welcomePageModule from "@modules/welcome/module";
import { createLazyPersistedModule } from "@base";

const DATA_ANALYTICS_MODULES_ENABLED =
  equalsIgnoreCase("true", process.env.REACT_APP_DATA_ANALYTICS_MODULES_ENABLED);

const DEVELOPMENT_MODE = process.env.NODE_ENV === "development";
const SUPERSET_PORTLET_URL = getStringValue(process.env.REACT_APP_SUPERSET_PORTLET);
export const DASHBOARDS_MODULE_ENABLED =
  equalsIgnoreCase("true", process.env.REACT_APP_SUPERSET_PORTLET_ENABLED) &&
  // Do not enable superset in production builds regardless of environment - we do not want to
  // accidentally deploy to our dev environment with superset enabled and pointing at localhost
  !isEmptyString(SUPERSET_PORTLET_URL) && (SUPERSET_PORTLET_URL.indexOf("localhost") === -1 || DEVELOPMENT_MODE);

export interface Portlet {
  id: string;
  path: string;
  title: string;
  name: string;
  icon: any;
  className?: string;
}

export function portletFactory(portlet: Portlet,
                               url: string,
                               tokenAttribute: string,
                               enabled: boolean): Module {

  const { path, title, className } = portlet;

  const view = () => (
    <PortletContainer
      accessToken=""
      className={className}
      url={url}
      tokenAttribute={tokenAttribute}
    />
  );

  return createLazyPersistedModule({
    exactPath: true,
    strictPath: true,
    backButton: null,
    style: {
      padding: 0,
    },
    view,
    disabled: !enabled,
    isNavLinkActive: (match, { pathname }) => equalsIgnoreCase(pathname, path),
    feedbackScreenshotDisabled: true,
    showBreadcrumbs: true,
    customBreadcrumbs: [
      new Breadcrumb({title: welcomePageModule.breadcrumbTitle, url: welcomePageModule.path}),
      new Breadcrumb({title, url: path})
    ],
    ... portlet
  });
}

export function supersetPortletFactory(portlet: Portlet,
                                       url: string,
                                       tokenAttribute: string,
                                       enabled: boolean): Module {

  const { path, title, className } = portlet;

  const view = () => (
    <SupersetPortletContainer
      className={className}
      url={url}
      tokenAttribute={tokenAttribute}
    />
  );

  return createLazyPersistedModule({
    exactPath: true,
    strictPath: true,
    backButton: null,
    style: {
      padding: 0,
    },
    view,
    disabled: !enabled,
    isNavLinkActive: (match, { pathname }) => equalsIgnoreCase(pathname, path),
    feedbackScreenshotDisabled: true,
    showBreadcrumbs: true,
    customBreadcrumbs: [
      new Breadcrumb({title: welcomePageModule.breadcrumbTitle, url: welcomePageModule.path}),
      new Breadcrumb({title, url: path})
    ],
    ... portlet
  });
}

export const QuerySchedules = portletFactory({
      id: "query_schedules",
      path: QUERY_SCHEDULES_MODULE_PATH,
      title: "Query Schedules",
      name: "Query Schedules",
      icon: ScheduleIcon,
      className: "querySchedulesModule",
    },
    process.env.REACT_APP_DATA_WAREHOUSE_PORTLET + "/query/schedules",
    "#",
    DATA_ANALYTICS_MODULES_ENABLED);

export const QueryData = portletFactory({
      id: "query_data",
      path: QUERY_DATA_MODULE_PATH,
      title: "Query Data",
      name: "Query Data",
      icon: Assessment,
      className: "queryDataModule",
  },
    process.env.REACT_APP_DATA_WAREHOUSE_PORTLET + "/query",
    "#",
    DATA_ANALYTICS_MODULES_ENABLED);

export const UserDatabase = portletFactory({
      id: "my_database",
      path: USER_DATABASE_MODULE_PATH,
      title: "My Database",
      name: "My Database",
      icon: Storage,
      className: "myDatabaseModule",
  },
    process.env.REACT_APP_DATA_WAREHOUSE_PORTLET + "/query/personal",
    "#",
    DATA_ANALYTICS_MODULES_ENABLED);

export const SupersetDashboards = supersetPortletFactory({
        id: DASHBOARDS_MODULE_ID,
        path: DASHBOARDS_MODULE_PATH,
        title: DASHBOARDS_MODULE_TITLE,
        name: "Dashboards",
        icon: DashboardsIcon,
        className: "dashboardsModule",
    },
    SUPERSET_PORTLET_URL,
    "access_token",
    DASHBOARDS_MODULE_ENABLED);

export { ScheduleIcon as QuerySchedulesIcon };
export { Assessment as QueryDataIcon };
export { Storage as UserDatabaseIcon };
export { DashboardsIcon };
