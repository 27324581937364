import React from "react";
import { connect } from "react-redux";
import { SecurityGroupBulkRequest } from "@data";
import { ReviewViewList } from "@components";
import AppSchema from "@schemas";
import { getSecurityGroupsBulkRequest } from "@modules/groupManagementWizard/selectors";

interface ContainerModel {
  groupBulkRequest?: SecurityGroupBulkRequest[];
}

const ReviewViewContainer = (props: ContainerModel) => {

  const {
    groupBulkRequest = [],
  } = props;

  return (
    <ReviewViewList
      items={groupBulkRequest}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  groupBulkRequest: getSecurityGroupsBulkRequest(state),
});

export default connect<ContainerModel, {}, ContainerModel>(
  mapStateToProps
)(ReviewViewContainer);
