import AppSchema from "@schemas";
import { FederationProviderWizardState } from "../models";
import { FederationProviderWizardActions } from "../actions";
import { FederationProviderWizardSelectors } from "../selectors";
import { FederationProviderClient, RestClientError } from "@network";
import { FederationProvider, FederationProviderAttributes, FederationProviderRequest } from "@data";

export const save = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accessToken = FederationProviderWizardSelectors.getAccessToken(state);
  const json = FederationProviderWizardSelectors.getJson(state);
  const providerId = FederationProviderWizardSelectors.getProviderId(state);

  dispatch(FederationProviderWizardActions.showLoadingIndicator());
  dispatch(FederationProviderWizardActions.hideAccessDenied());
  dispatch(FederationProviderWizardActions.setErrors());
  dispatch(FederationProviderWizardActions.setErrorMessage());
  dispatch(FederationProviderWizardActions.editFederationProviderRequest());

  return FederationProviderClient.editFederationProvider(accessToken, providerId, json)
    .then(() => {

      dispatch(FederationProviderWizardActions.editFederationProviderSuccess());
      dispatch(FederationProviderWizardActions.setSuccessMessage("Federation Provider Updated"));
      return dispatch(FederationProviderWizardActions.hideLoadingIndicator());

    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to update Federation Provider", errors } = response;

      dispatch(FederationProviderWizardActions.editFederationProviderFailed(analytic));
      dispatch(FederationProviderWizardActions.setErrors(errors));
      dispatch(FederationProviderWizardActions.setErrorMessage(error));

      if (status === 403) {
        dispatch(FederationProviderWizardActions.showAccessDenied());
      }

      return dispatch(FederationProviderWizardActions.hideLoadingIndicator());
    });
};

export const fetchFederationProvider = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accessToken = FederationProviderWizardSelectors.getAccessToken(state);
  const providerId = FederationProviderWizardSelectors.getProviderId(state);

  dispatch(FederationProviderWizardActions.showLoadingIndicator());
  dispatch(FederationProviderWizardActions.hideAccessDenied());
  dispatch(FederationProviderWizardActions.setErrors());
  dispatch(FederationProviderWizardActions.setErrorMessage());
  dispatch(FederationProviderWizardActions.fetchFederationProviderRequest());

  return FederationProviderClient.getFederationProvider(accessToken, providerId)
    .then((attrs: FederationProviderAttributes) => {

      const federationProvider = new FederationProvider(attrs);
      const federationProviderRequest = FederationProviderRequest.from(federationProvider);
      const defaultState = new FederationProviderWizardState({
        ...federationProviderRequest.toJS(),
        providerId: federationProvider.getProviderId(),
      });

      dispatch(FederationProviderWizardActions.fetchFederationProviderSuccess());
      dispatch(FederationProviderWizardActions.updateFederationProvider(federationProviderRequest));
      dispatch(FederationProviderWizardActions.setDefaultState(defaultState));
      dispatch(FederationProviderWizardActions.hideLoadingIndicator());
      dispatch(FederationProviderWizardActions.hideEmptyView());

    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to fetch Federation Provider", errors } = response;

      dispatch(FederationProviderWizardActions.fetchFederationProviderFailed(analytic));
      dispatch(FederationProviderWizardActions.setErrors(errors));
      dispatch(FederationProviderWizardActions.setErrorMessage(error));

      if (status === 403) {
        dispatch(FederationProviderWizardActions.showAccessDenied());
      }

      return dispatch(FederationProviderWizardActions.hideLoadingIndicator());
    });
};

export const initialize = (providerId: string = "") => (dispatch: any) => {

  dispatch(FederationProviderWizardActions.reset());
  dispatch(FederationProviderWizardActions.showEmptyView());
  dispatch(FederationProviderWizardActions.setProviderId(providerId));
  dispatch(FederationProviderWizardActions.showEditMode());
  dispatch(FederationProviderWizardActions.showClientView());
  return dispatch(fetchFederationProvider());
};
