import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { DeviceTypeDetailsActions } from "../actions";
import { DeviceTypeDetailsSelectors } from "../selectors";
import JsonView, { Model, Actions } from "../components/JsonView";

const mapStateToProps = (state: AppSchema): Model => ({
  json: DeviceTypeDetailsSelectors.getJson(state),
  fileName: `${DeviceTypeDetailsSelectors.getDeviceTypeName(state)}.json`,
  isToggleApiSwitchVisible: DeviceTypeDetailsSelectors.isToggleJsonApiSwitchVisible(state),
  isToggleApiSwitchDisabled: DeviceTypeDetailsSelectors.isLoadingIndicatorVisible(state),
  isHistoricalDataViewSelected: DeviceTypeDetailsSelectors.isHistoricalDataViewSelected(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  setShowHistoricalDataView: (value: boolean) => dispatch(DeviceTypeDetailsActions.setShowHistoricalDataView(value)),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(JsonView);
