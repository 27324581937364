import React from "react";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { SummaryViewActionItems, SummaryViewWithActions } from "@components";
import styles from "./styles";

export interface SecurityViewModel {
  className?: string;
  contentClassName?: string;
  title?: string;
  items?: SummaryViewActionItems[];
}

type Model = SecurityViewModel;
type Props = WithStyles<typeof styles> & Model & {
  children?: React.ReactNode;
};

export const SecurityView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    contentClassName,
    title = "Security",
    items = [],
    children,
  } = props;

  return (
    <React.Fragment>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      <SummaryViewWithActions
        className={classnames("servicesSummaryView", className, classes.summaryView)}
        contentClassName={classnames("servicesSummaryViewContent", contentClassName, classes.summaryViewContent)}
        items={items}
      >
        {children}
      </SummaryViewWithActions>
    </React.Fragment>
  );
});

export default SecurityView;
