import { Record } from "immutable";
import { getStringValue, isEmptyString, isValidInteger } from "@util/Functions";
import DeviceTypeState from "./DeviceTypeState";

export interface DeviceTypeModelV2MetadataAttributes {
  groups: string[];
  schemas: string[];
  secretsSchema: string;
  tags: string[];
}

export class DeviceTypeModelV2Metadata extends Record({
  groups: [],
  schemas: [],
  secretsSchema: "",
  tags: [],
}) implements DeviceTypeModelV2MetadataAttributes {

  public static EMPTY: DeviceTypeModelV2Metadata = new DeviceTypeModelV2Metadata();

  public readonly groups: string[];
  public readonly schemas: string[];
  public readonly secretsSchema: string;
  public readonly tags: string[];

  public getGroups(): string[] {
    const groups = Array.isArray(this.groups) ? this.groups : [];
    return groups
      .map(group => getStringValue(group))
      .filter(group => !isEmptyString(group));
  }

  public getSchemas(): string[] {
    const schemas = Array.isArray(this.schemas) ? this.schemas : [];
    return schemas
      .map(schema => getStringValue(schema))
      .filter(schema => !isEmptyString(schema));
  }

  public getSecretsSchema(): string {
    return getStringValue(this.secretsSchema);
  }

  public getTags(): string[] {
    const tags = Array.isArray(this.tags) ? this.tags : [];
    return tags
      .map(tag => getStringValue(tag))
      .filter(tag => !isEmptyString(tag));
  }
}

export interface DeviceTypeModelV2Attributes {
  etag:	string;
  accountId: string;
  createdAt: string;
  createdBy: string;
  description: string;
  state: DeviceTypeState;
  metadata: DeviceTypeModelV2MetadataAttributes;
  nameAndVersion: string;
  updatedAt: string;
  updatedBy: string;
}

export class DeviceTypeModelV2 extends Record({
  etag: "",
  accountId: "",
  createdAt: "",
  createdBy: "",
  description: "",
  state: DeviceTypeState.NONE,
  metadata: DeviceTypeModelV2Metadata.EMPTY.toJS(),
  nameAndVersion: "",
  updatedAt: "",
  updatedBy: "",
}) implements DeviceTypeModelV2Attributes {

  public static EMPTY: DeviceTypeModelV2 = new DeviceTypeModelV2();

  public readonly etag: string;
  public readonly accountId: string;
  public readonly createdAt: string;
  public readonly createdBy: string;
  public readonly description: string;
  public readonly state: DeviceTypeState;
  public readonly metadata: DeviceTypeModelV2MetadataAttributes;
  public readonly nameAndVersion: string;
  public readonly updatedAt: string;
  public readonly updatedBy: string;

  public getEtag(): string {
    return getStringValue(this.etag);
  }

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getCreatedAt(): string {
    return getStringValue(this.createdAt);
  }

  public getCreatedBy(): string {
    return getStringValue(this.createdBy);
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public getState(): DeviceTypeState {
    return this.state || DeviceTypeState.NONE;
  }

  public getMetadata(): DeviceTypeModelV2Metadata {
    return new DeviceTypeModelV2Metadata(this.metadata);
  }

  public getNameAndVersion(): string {
    return getStringValue(this.nameAndVersion);
  }

  public hasNameAndVersion(): boolean {
    return !isEmptyString(this.getNameAndVersion());
  }

  public getUpdatedAt(): string {
    return getStringValue(this.updatedAt);
  }

  public getUpdatedBy(): string {
    return getStringValue(this.updatedBy);
  }

  public getNamespace(): string {
    return this.getNameAndVersion().split(":")[0] || "";
  }

  public getName(): string {
    return this.getNameAndVersion().split(":")[1] || "";
  }

  public getVersion(): string {
    return this.getNameAndVersion().split(":")[2] || "";
  }

  public getVersionAsNumber(defaultValue: number = 0): number {
    const version = this.getVersion();
    return isValidInteger(version) ? Number(version) : defaultValue;
  }

  public getGroups(): string[] {
    return this.getMetadata().getGroups();
  }

  public getSchemas(): string[] {
    return this.getMetadata().getSchemas();
  }

  public getSecretsSchema(): string {
    return this.getMetadata().getSecretsSchema();
  }

  public getTags(): string[] {
    return this.getMetadata().getTags();
  }
}

export default DeviceTypeModelV2;
