import React from "react";
import MuiLink from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { stopPropagation } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import classnames from "classnames";

interface LinkProps {
  href: string;
  rel?: string;
  target?: string;
  children: React.ReactNode;
  className?: string;
  underline?: UnderlineOptions;
  onClick?: (event: React.MouseEvent<{}>) => void;
}

export enum UnderlineOptions {
  NONE = "none",
  ALWAYS = "always",
  HOVER = "hover",
}

type Props = WithStyles<typeof styles> & LinkProps;

export const Link = withStyles(styles)((props: Props) => {

  const {
    rel,
    href,
    target,
    children,
    className,
    classes,
    underline = UnderlineOptions.ALWAYS,
    onClick = stopPropagation,
    ...otherProps
  } = props;

  return (
    <MuiLink
      {...otherProps}
      {...(href.indexOf("http") >= 0 ? ({
        href,
        target: "_blank",
        rel: "noopener noreferrer",
      }) : ({
        component: RouterLink,
        to: href,
        target,
      }))}
      className={classnames("link", className, classes.link)}
      underline={underline}
      rel={rel}
      onClick={onClick}
    >
      {children}
    </MuiLink>
  );
});

export default Link;
