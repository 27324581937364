import React from "react";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  useOrchestrationServiceClient,
} from "@hooks";

export interface UseDeleteAuthenticatorServiceProps extends Partial<UseApiRequestProps<void>> {
  userId: string;
  authenticatorId: string;
}

export interface UseDeleteAuthenticatorServiceModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UseDeleteAuthenticatorServiceActions {
  deleteAuthenticator: () => void;
}

type Props = UseDeleteAuthenticatorServiceProps;
type Model = UseDeleteAuthenticatorServiceModel;
type Actions = UseDeleteAuthenticatorServiceActions;
type Result = [Model, Actions];

export const useDeleteAuthenticatorService = (props: Props): Result => {

  const {
    userId = "",
    authenticatorId = "",
    defaultErrorMessage = "Failed to delete Authenticator",
    ...otherProps
  } = props;

  const OrchestrationServiceClient = useOrchestrationServiceClient();

  const [{ showSuccessView, ...baseModel }, { refresh: deleteAuthenticator }] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => OrchestrationServiceClient.deleteAuthenticatorApi(userId, authenticatorId),
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Delete Successful" : "" ,
    [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    deleteAuthenticator,
  }), [
    deleteAuthenticator,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDeleteAuthenticatorService;
