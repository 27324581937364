import { MODULE_PATH as BASE_URL } from "@modules/dataWorkloads/constants";
import { getPathToWorkload } from "@modules/dataWorkloadDetails/helpers";

export const getCreateWorkloadPath = () => `${BASE_URL}/create`;

export const getCloneWorkloadPath = (name: string, version: number) =>
  `${getPathToWorkload(name, version)}/clone`;

export const getDataSetId = (account: string, dataSetAlias: string) => {
  return `lrn:iot:dmg::${account}:dataset:alias:${dataSetAlias}`;
};
