import { Record } from "immutable";

export interface CustomPropAttributes {
  [key: string]: any;
}

export interface CustomDataAttributes {
  device?: CustomPropAttributes;
  region?: CustomPropAttributes;
  type?: CustomPropAttributes;
}

export interface CustomAttributes {
  data: CustomDataAttributes;
}

export interface SystemBootstrapAttributes {
  [key: string]: any;
}

export interface SystemAttributes {
  data: SystemBootstrapAttributes;
}
export interface DeviceProvisioningDataAttributes {
  custom: CustomAttributes;
  system: SystemAttributes;
}

export class DeviceProvisioningData extends Record({
  custom: {
    data: {},
  },
  system: {
    data: {},
  }
}) implements DeviceProvisioningDataAttributes {

  public static EMPTY: DeviceProvisioningData = new DeviceProvisioningData();

  public readonly custom: CustomAttributes;
  public readonly system: SystemAttributes;

  public getCustomData(): CustomDataAttributes {
    const { data = {} } = this.custom || {};
    return data;
  }

  public getSystemData(): SystemBootstrapAttributes {
    const { data = {} } = this.system || {};
    return data;
  }
}

export default DeviceProvisioningData;
