import React from "react";
import { useHistory } from "react-router-dom";
import { getPathToApplication } from "@modules/applicationRegionalDetails/helpers";
import ApplicationWizard from "./containers/ApplicationWizard";
import { Application } from "@data";

export function CreateApplicationRegionalView() {

  const history = useHistory();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const showApplicationDetails = React.useCallback((application: Application) => {
    history.replace(getPathToApplication(application.getId()));
  }, [history]);

  return (
    <ApplicationWizard
      cancel={cancel}
      showApplicationDetails={showApplicationDetails}
    />
  );
}

export default CreateApplicationRegionalView;
