import {
  CredentialAttributes,
  DataAttributes,
  DeviceDataModel,
  DeviceTypeListItem,
  JsonSchemaMetadata,
  JsonSchemaMetadataAttributes
} from "@data";
import { OrderedMap } from "immutable";
import { AppSchema } from "@schemas";
import { DeviceEnrollmentWizardSelectors } from "../selectors";
import { DeviceDataMode } from "../reducers";
import { AddDeviceWizardStep } from "../steps";
import { getAuthToken } from "@main/selectors";
import {
  DeviceEnrollmentClient,
  DeviceEnrollmentLocationHeaders,
  RestClientError,
} from "@network";
import { isEmptyString } from "@util";
import {
  deviceEnrollmentFailed,
  deviceEnrollmentRequest,
  deviceEnrollmentSuccess,
  hideAccessDenied,
  hideLoadingIndicator,
  setAddDeviceWizardStep,
  setAvailableSchemas,
  setDeviceDataDialog,
  setDeviceDataMode,
  setDevice,
  setDeviceRef,
  setErrorMessage,
  setErrorTitle,
  setSchema,
  setSchemaIdentity,
  setDeviceData,
  setSuccessMessage,
  showAccessDenied,
  showLoadingIndicator,
  validateDeviceEnrollmentFailed,
  validateDeviceEnrollmentRequest,
  validateDeviceEnrollmentSuccess
} from "./deviceEnrollmentWizard";

export const showDeviceIdView = () => setAddDeviceWizardStep(AddDeviceWizardStep.DEVICE_ID);
export const showDeviceTypeView = () => setAddDeviceWizardStep(AddDeviceWizardStep.DEVICE_TYPE);
export const showDataView = () => setAddDeviceWizardStep(AddDeviceWizardStep.DATA);
export const showCredentialsView = () => setAddDeviceWizardStep(AddDeviceWizardStep.CREDENTIALS);
export const showAddDeviceEditorView = () => setAddDeviceWizardStep(AddDeviceWizardStep.EDITOR);

// Device Data Mode
export const showActualData = () => setDeviceDataMode(DeviceDataMode.ACTUAL);
export const showDesiredData = () => setDeviceDataMode(DeviceDataMode.DESIRED);

export const updateDeviceJson = (json: string = JSON.stringify(DeviceDataModel.EMPTY.toJS(), null, "  ")) =>
  (dispatch: any) => {

    try {
      const deviceEnrollmentReq = new DeviceDataModel(JSON.parse(json));

      const deviceEnrollment = deviceEnrollmentReq.toJS();

      dispatch(setDevice(deviceEnrollment));

      return dispatch(setErrorMessage());
    } catch (e) {
      return dispatch(setErrorMessage("Failed to parse device enrollment. " +
        "Please verify that the JSON is valid before continuing."));
    }
  };

export const updateDeviceEnrollment = (deviceEnrollment: DeviceDataModel = DeviceDataModel.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    if (!DeviceEnrollmentWizardSelectors.isDeviceEnrollmentJsonValid(state)) {
      return dispatch(setErrorMessage("Failed to parse device enrollment. " +
        "Please verify that the JSON is valid before continuing."));
    }

    const enrollment = deviceEnrollment.toJS();

    dispatch(setDevice(enrollment));

    return dispatch(setErrorMessage());
  };

export const updateDeviceId = (deviceId: string = "") => (dispatch: any, getState: () => AppSchema) => {

  const deviceEnrollment = DeviceEnrollmentWizardSelectors.getDevice(getState());

  return dispatch(updateDeviceEnrollment(new DeviceDataModel({
    ...deviceEnrollment.toJS(),
    deviceId,
  })));
};

export const updateDeviceType = (deviceTypeListItem: DeviceTypeListItem = DeviceTypeListItem.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const deviceEnrollment = DeviceEnrollmentWizardSelectors.getDevice(getState());

    const deviceType = deviceTypeListItem.getTypeIdentity();

    return dispatch(updateDeviceEnrollment(new DeviceDataModel({
      ...deviceEnrollment.toJS(),
      deviceType,
    })));
  };

export const updateActualData = (data: DataAttributes[] = []) => (dispatch: any, getState: () => AppSchema) => {

  const deviceEnrollment = DeviceEnrollmentWizardSelectors.getDevice(getState());

  const {deviceData = {}} = deviceEnrollment.toJS();
  const {actual = {}} = deviceData;

  return dispatch(updateDeviceEnrollment(new DeviceDataModel({
      ...deviceEnrollment.toJS(),
      deviceData: {
        ...deviceData,
        actual: {
          ...actual,
          data,
        }
      }
    }
  )));
};

export const updateDesiredData = (data: DataAttributes[] = []) => (dispatch: any, getState: () => AppSchema) => {

  const deviceEnrollment = DeviceEnrollmentWizardSelectors.getDevice(getState());

  const {deviceData = {}} = deviceEnrollment.toJS();
  const {desired = {}} = deviceData;

  return dispatch(updateDeviceEnrollment(new DeviceDataModel({
      ...deviceEnrollment.toJS(),
      deviceData: {
        ...deviceData,
        desired: {
          ...desired,
          data,
        }
      }
    }
  )));
};

export const updateActualPopulateAllSchema =
  (populateAllSchemas: boolean = false) => (dispatch: any, getState: () => AppSchema) => {

    const deviceEnrollment = DeviceEnrollmentWizardSelectors.getDevice(getState());

    const {deviceData = {}} = deviceEnrollment.toJS();
    const {actual = {}} = deviceData;

    return dispatch(updateDeviceEnrollment(new DeviceDataModel({
        ...deviceEnrollment.toJS(),
        deviceData: {
          ...deviceData,
          actual: {
            ...actual,
            populateAllSchemas,
          }
        }
      }
    )));
  };

export const updateDesiredPopulateAllSchema =
  (populateAllSchemas: boolean = false) => (dispatch: any, getState: () => AppSchema) => {

  const deviceEnrollment = DeviceEnrollmentWizardSelectors.getDevice(getState());

  const {deviceData = {}} = deviceEnrollment.toJS();
  const {desired = {}} = deviceData;

  return dispatch(updateDeviceEnrollment(new DeviceDataModel({
      ...deviceEnrollment.toJS(),
      deviceData: {
        ...deviceData,
        desired: {
          ...desired,
          populateAllSchemas,
        }
      }
    }
  )));
};

export const updateCredentials = (credentials: CredentialAttributes[] = []) =>
  (dispatch: any, getState: () => AppSchema) => {

    const deviceEnrollment = DeviceEnrollmentWizardSelectors.getDevice(getState());

    const {device = {}} = deviceEnrollment.toJS();
    const {security = {}} = device;

    return dispatch(updateDeviceEnrollment(new DeviceDataModel({
        ...deviceEnrollment.toJS(),
        security: {
          ...security,
          credentials: credentials.map(credential => ({
            name: credential.name,
            value: credential.value,
          }))
        }
      }
    )));

  };

export const addCredential = (name: string = "", value: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const deviceEnrollment = DeviceEnrollmentWizardSelectors.getDevice(getState());

    const credentials = deviceEnrollment.getCredentials();

    const credential = {
      name: name,
      value: value
    };

    const updatedCredentials = credentials.indexOf(credential) === -1 ?
      credentials.concat(credential) : credentials;

    return dispatch(updateCredentials(updatedCredentials));

  };

export const clearCredentials = () => updateCredentials([]);

export const updateCredentialValue = (name: string = "", value: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const deviceEnrollment = DeviceEnrollmentWizardSelectors.getDevice(getState());

    const credentials = deviceEnrollment.getCredentials();

    const updatedCredentials = credentials.map(credential => credential.name === name ?
      {
        name: name,
        value: value,
      } : credential);

    return dispatch(updateCredentials(updatedCredentials));

  };

export const addActualSchemaData = (data: DataAttributes) =>
  (dispatch: any, getState: () => AppSchema) => {

    const actualData = DeviceEnrollmentWizardSelectors.getDevice(getState()).getActualData();

    const updatedActualData = actualData.concat(data);

    return dispatch(updateActualData(updatedActualData));
  };

export const removeActualSchemaData = (schemaIdentity: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const actualData = DeviceEnrollmentWizardSelectors.getDevice(getState()).getActualData();

    const updatedActualData = actualData.filter(data => data.schemaIdentity !== schemaIdentity);

    return dispatch(updateActualData(updatedActualData));
  };

export const addDesiredSchemaData = (data: DataAttributes) =>
  (dispatch: any, getState: () => AppSchema) => {

    const desiredData = DeviceEnrollmentWizardSelectors.getDevice(getState()).getDesiredData();

    const updatedDesiredData = desiredData.concat(data);

    return dispatch(updateDesiredData(updatedDesiredData));
  };

export const removeDesiredSchemaData = (schemaIdentity: string = "") =>
  (dispatch: any, getState: () => AppSchema) => {

    const desiredData = DeviceEnrollmentWizardSelectors.getDevice(getState()).getDesiredData();

    const updatedDesiredData = desiredData.filter(data => data.schemaIdentity !== schemaIdentity);

    return dispatch(updateDesiredData(updatedDesiredData));
  };

export const updateAvailableSchemas =
  (items: OrderedMap<string, JsonSchemaMetadataAttributes> = OrderedMap<string, JsonSchemaMetadataAttributes>()) =>
    (dispatch: any, getState: () => AppSchema) => {

      return dispatch(setAvailableSchemas(
        DeviceEnrollmentWizardSelectors.getSchemasMap(getState()).merge(
          OrderedMap<string, JsonSchemaMetadataAttributes>(items)
            .map((attrs: JsonSchemaMetadataAttributes) => new JsonSchemaMetadata(attrs))
            .toOrderedMap())
          .map((item: JsonSchemaMetadata) => item.toJS())
          .toOrderedMap()
          .toJS()));
    };

export const addAvailableSchemas = (items: JsonSchemaMetadataAttributes[] = []) => (dispatch: any) => {

  const schemas = items.reduce((data, attrs) => {
    const item = new JsonSchemaMetadata(attrs);

    if (!JsonSchemaMetadata.EMPTY.equals(item)) {
      return data.set(item.getId(), item.toJS());
    }

    return data;
  }, OrderedMap<string, JsonSchemaMetadataAttributes>({}));

  return dispatch(updateAvailableSchemas(schemas));
};

export const updateActualSchemaList = (schemaIdentities: string[] = []) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const actualSchemas = DeviceEnrollmentWizardSelectors.getDevice(state).getActualSchemas();

    if (schemaIdentities.length < actualSchemas.length) {
      const missingSchema = actualSchemas.filter(schemaIdentity => schemaIdentities.indexOf(schemaIdentity) < 0);
      return dispatch(removeActualSchemaData(missingSchema[0]));
    }
  };

export const updateDesiredSchemaList = (schemaIdentities: string[] = []) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const desiredSchemas = DeviceEnrollmentWizardSelectors.getDevice(state).getDesiredSchemas();

    if (schemaIdentities.length < desiredSchemas.length) {
      const missingSchema = desiredSchemas.filter(schemaIdentity => schemaIdentities.indexOf(schemaIdentity) < 0);
      return dispatch(removeDesiredSchemaData(missingSchema[0]));
    }
  };

export const updateSchemaList = (schemaIdentities: string[] = []) =>
  (dispatch: any, getState: () => AppSchema) => {

    if (DeviceEnrollmentWizardSelectors.isActualDeviceDataModeActive(getState())) {
      return dispatch(updateActualSchemaList(schemaIdentities));
    }

    return dispatch(updateDesiredSchemaList(schemaIdentities));

  };

export const updatePopulateAllSchema = (value: boolean) =>
  (dispatch: any, getState: () => AppSchema) => {

    if (DeviceEnrollmentWizardSelectors.isActualDeviceDataModeActive(getState())) {
      return dispatch(updateActualPopulateAllSchema(value));
    }

    return dispatch(updateDesiredPopulateAllSchema(value));

  };

export const addSchemaData = (data: DataAttributes) =>
  (dispatch: any, getState: () => AppSchema) => {

    dispatch(closeDeviceDataDialog());

    if (DeviceEnrollmentWizardSelectors.isActualDeviceDataModeActive(getState())) {
      return dispatch(addActualSchemaData(data));
    }

    return dispatch(addDesiredSchemaData(data));
  };

export const setAddDeviceCurrentStep = (step: AddDeviceWizardStep) =>
  (dispatch: any, getState: () => AppSchema) => {

    const disabledStep = DeviceEnrollmentWizardSelectors.getAddDeviceDisabledSteps(getState());

    if (AddDeviceWizardStep.NONE === step || disabledStep.indexOf(step) >= 0) {
      return Promise.resolve();
    }

    dispatch(setErrorMessage());
    return dispatch(setAddDeviceWizardStep(step));
  };

export const showAddDeviceNextStep = () => (dispatch: any, getState: () => AppSchema) => {

  return dispatch(setAddDeviceCurrentStep(DeviceEnrollmentWizardSelectors.getAddDeviceNextStep(getState())));
};

export const showAddDevicePreviousStep = () => (dispatch: any, getState: () => AppSchema) => {

  return dispatch(setAddDeviceCurrentStep(DeviceEnrollmentWizardSelectors.getAddDevicePreviousStep(getState())));
};

export const enrollDevice = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const accessToken = getAuthToken(state);
  const batchId = DeviceEnrollmentWizardSelectors.getBatchId(state);
  const device = DeviceEnrollmentWizardSelectors.getDevices(state)[0];
  const json = JSON.stringify({
    batchId,
    device,
  });

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setErrorMessage());
  dispatch(deviceEnrollmentRequest());

  return DeviceEnrollmentClient.enrollDevice(accessToken, json)
    .then((response: DeviceEnrollmentLocationHeaders) => {

      const {deviceId: deviceId = ""} = response;

      if (!isEmptyString(deviceId)) {
        dispatch(setDeviceRef(deviceId));
      } else {
        dispatch(setDeviceRef(DeviceEnrollmentWizardSelectors.getDevice(state).getDeviceId()));
      }
      dispatch(deviceEnrollmentSuccess());
      dispatch(setSuccessMessage("Device Enrollment Request Submitted"));
      return dispatch(hideLoadingIndicator());
    }, (response: RestClientError) => {

      const {analytic, status, error = "Failed to start device enrollment"} = response;

      dispatch(deviceEnrollmentFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(setErrorTitle("Device Enrollment Failed"));

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideLoadingIndicator());
    });
};

export const validateDeviceEnrollment = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();

  const accessToken = getAuthToken(state);
  const batchId = DeviceEnrollmentWizardSelectors.getBatchId(state);
  const device = DeviceEnrollmentWizardSelectors.getDevices(state)[0];
  const json = JSON.stringify({
    batchId,
    device,
  });

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(validateDeviceEnrollmentRequest());

  return DeviceEnrollmentClient.validateDeviceEnrollment(accessToken, json)
    .then((response: DeviceEnrollmentLocationHeaders) => {

      const {deviceId: deviceId = ""} = response;

      if (!isEmptyString(deviceId)) {
        dispatch(setDeviceRef(deviceId));
      } else {
        dispatch(setDeviceRef(DeviceEnrollmentWizardSelectors.getDevice(state).getDeviceId()));
      }
      dispatch(validateDeviceEnrollmentSuccess());
      dispatch(setSuccessMessage("Validation request submitted"));
      return dispatch(hideLoadingIndicator());

    }, (response: RestClientError) => {

      const {analytic, status, error = "Failed to validate device enrollment"} = response;

      dispatch(validateDeviceEnrollmentFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(setErrorTitle("Device Validation Failed"));

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideLoadingIndicator());
    });
};

export const closeDeviceDataDialog = () => (dispatch: any) => {
  dispatch(setSchema());
  dispatch(setSchemaIdentity());
  dispatch(setDeviceData());
  return dispatch(setDeviceDataDialog(false));
};

export const updateSchemaData = (data: DataAttributes) => (dispatch: any) => {
  dispatch(addSchemaData({
    schemaIdentity: data.schemaIdentity,
    schemaData: JSON.parse(!isEmptyString(data.schemaData) ? data.schemaData : "{}"),
  }));
};
