import { useHistory, useLocation } from "react-router-dom";
import React from "react";
import { Location } from "history";
import PolicyManagement from "./containers/PolicyManagement";

export function PolicyManagementView() {

  const history = useHistory();

  const { state: { from } = { from: undefined } } = useLocation<{ from?: Location }>();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onSuccess = React.useCallback(() => {
    if (from) {
      history.replace(from);
    } else {
      history.goBack();
    }
  }, [history, from]);

  return (
    <PolicyManagement
     cancel={cancel}
     onSuccess={onSuccess}
    />
  );
}

export default PolicyManagementView;
