import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
    },
    fileDetailsDialogPresentation: {
      justifyContent: "flex-end",
      alignItems: "flex-start",
    },
    fullHeight: {
      alignItems: "stretch",
    },
    fileDetailsDialogPaper: {
      flex: "1 0 auto",
    },
    fileDetailsDialogContainer: {
      marginLeft: "auto",
      maxWidth: 650,
      maxHeight: "none",
      margin: 0,
      borderRadius: 0,
      border: "none",
    },
    fileDetailsDialogTitle: {
      padding: "15px 0",
      borderBottom: `1px solid ${Color.GREY1}`,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    fileDetailsDialogTitleLabel: {
      display: "block",
      fontSize: 28,
      fontWeight: 400,
      lineHeight: "1",
      marginLeft: 15,
    },
    fileDetailsDialogCloseIconButton: {
      marginLeft: "auto",
      marginRight: 8,
    },
    fileDetailsDialogCloseIcon: {
      color: Color.GREY7,
      width: 28,
      height: 28,
    },
    fileDetailsDialogContent: {
      margin: 15,
      display: "flex",
      flexFlow: "column nowrap",
    },
    fileDetailsDialogContentLabel: {
    },
    fileDetailsDialogContentLabelName: {
      fontSize: 12,
      fontWeight: 400,
      minWidth: 80,
    },
    fileDetailsDialogContentLabelValue: {
      fontSize: 11,
      fontWeight: 300,
      backgroundColor: "transparent",
      fontFamily: "Roboto, sans-serif",
      padding: 0,
      marginLeft: 5,
    },
    metadata: {
      marginTop: 15,
    },
    downloadButton: {
      marginBottom: 5,
    },
    actionButton: {
    },
  });

export default styles;
