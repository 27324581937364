import {
  MODULE_PATH as BASE_URL,
  LEGACY_MODULE_PATH as LEGACY_BASE_URL,
} from "@modules/dataSetRequests/constants";

export const MODULE_ID = "data_set_request_details";
export const MODULE_PATH = `${BASE_URL}/:id`;
export const LEGACY_MODULE_PATH = `${LEGACY_BASE_URL}/:id`;

export const VIEW_DATA_SET_REQUEST_COMMENTS_REDIRECT = "viewDataSetRequestComments";

export { BASE_URL, LEGACY_BASE_URL };
