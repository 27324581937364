import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";

export interface Model {
}

type Props = WithStyles<typeof styles> & Model;

export const PublishDashboard = withStyles(styles)((props: Props) => {

  const { classes } = props;

  const content = React.useMemo(() => {

    return (
      <React.Fragment>
        <label className={classnames("title", classes.title)}>
          Are you sure you want to publish this dashboard?
        </label>
        <label className={classnames("subtitle", classes.subtitle)}>
          <span className={classes.warning}>WARNING:</span>This action cannot be undone!
        </label>
      </React.Fragment>
    );
  }, []);

  return (
    <div className={classnames("confirmation", classes.container)}>
      {content}
    </div>
  );
});

export default PublishDashboard;
