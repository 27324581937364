import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { binaryDataListItem as styles } from "./styles";
import { isEmptyString, noop } from "@util/Functions";
import { DeleteBinaryData } from "@components";
import LoadingIndicator from "./LoadingIndicator";

export const DeleteBinaryDataListItem = withStyles(styles)((props: WithStyles<typeof styles> & {
  key?: string,
  userId?: string,
  name?: string,
  selfAuthorized?: boolean,
  cancel?: () => void,
  onSuccess?: () => void,
}) => {

  const {
    classes,
    key = "DeleteBinaryDataListItem",
    userId = "",
    name = "",
    selfAuthorized,
    cancel = noop,
    onSuccess = noop,
  } = props;

  const [showDeletingIndicator, setShowDeletingIndicator] = React.useState(false);

  if (isEmptyString(name)) {
    return null;
  }

  return (
    <div key={key} className={classnames("deleteBinaryDataListItem", classes.container)}>
      {showDeletingIndicator && (
        <LoadingIndicator
          className={classnames("loadingIndicator", classes.loadingIndicator)}
          label="Deleting..."
          labelClassName={classnames("deleting", classes.label, classes.deleting)}
        />
      )}
      {!showDeletingIndicator && (
        <label className={classnames(classes.label, classes.deleting)}>
          Delete Requested
        </label>
      )}
      <DeleteBinaryData
        userId={userId}
        name={name}
        selfAuthorized={selfAuthorized}
        cancel={cancel}
        onSuccessMessageShown={onSuccess}
        showLoadingIndicator={() => setShowDeletingIndicator(true)}
      />
    </div>
  );
});

export default DeleteBinaryDataListItem;
