import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    root: {
      fontSize: 13,
    },
    bold: {
      fontWeight: 500,
    },
  });

export default styles;
