import { DeviceEnrollment } from "@data";
import React from "react";
import { AuthTokenContext } from "@components";
import { equalsIgnoreCase, isEmptyString, noop } from "@util";
import {
  DeviceEnrollmentClient,
  GetBatchEnrollmentDetailsResponse,
  RestClientError,
} from "@network";

export interface UseBatchEnrollmentDetailsProps {
  batchId?: string;
  autoRefresh?: boolean;
  initialAutoRefreshDelaySecs?: number;
  initialRetries?: number;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
}

export interface UseBatchEnrollmentDetailsModel {
  batchId: string;
  deviceEnrollments: DeviceEnrollment[];
  nameFilter: string;
  numResults: number;
  showLoadMoreButton: boolean;
  errorMessage: string;
  showNotFound: boolean;
  showErrorView: boolean;
  showProgressIndicator: boolean;
  showNoResultsView: boolean;
  showAccessDenied: boolean;
  autoRefreshCountdown?: number;
  retries?: number;
}

export interface UseBatchEnrollmentDetailsActions {
  setNameFilter: (searchQuery: string) => void;
  clearList: () => void;
  loadMore: () => void;
  refresh: () => void;
}

export const useBatchEnrollmentDetails = (props: UseBatchEnrollmentDetailsProps = {}):
  [UseBatchEnrollmentDetailsModel, UseBatchEnrollmentDetailsActions] => {

  const {
    batchId = "" ,
    autoRefresh = false,
    initialAutoRefreshDelaySecs = 5,
    initialRetries = 5,
    trackRequestEvent = noop,
    trackSuccessEvent = noop,
    trackErrorEvent = noop,
  } = props;

  const authToken = React.useContext(AuthTokenContext);
  const [nameFilter, setNameFilter] = React.useState("");
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);
  const [nextPageToken, setNextPageToken] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showProgressIndicator, setShowProgressIndicator] = React.useState(false);
  const [showNotFound, setShowNotFound] = React.useState(false);
  const [showAccessDenied, setShowAccessDenied] = React.useState(false);
  const [deviceEnrollments, setDeviceEnrollments] = React.useState<DeviceEnrollment[]>([]);
  const [autoRefreshCountdown, setAutoRefreshCountdown] = React.useState(initialAutoRefreshDelaySecs);
  const [retries, setRetries] = React.useState(initialRetries);
  const [autoRefreshDelaySecs, setAutoRefreshDelaySecs] = React.useState(initialAutoRefreshDelaySecs);

  const numResults = React.useMemo(() => deviceEnrollments.length, [deviceEnrollments]);

  const showErrorView = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]);

  const showLoadMoreButton = React.useMemo(() =>
    !isEmptyString(nextPageToken) && !showErrorView && !showProgressIndicator,
    [nextPageToken, showErrorView, showProgressIndicator]);

  const showNoResultsView = React.useMemo(() =>
    numResults === 0 && !showErrorView && !showProgressIndicator,
    [numResults, showErrorView, showProgressIndicator]);

  const updateDevices = React.useCallback((devices: DeviceEnrollment[] = []) => {

    // Make sure there are no duplicates and prefer the updated value to the older value
    setDeviceEnrollments(currentItems => currentItems
      .filter(item => {
        const deviceId = item.getDeviceId();
        return !devices.some(otherDevices => equalsIgnoreCase(deviceId, otherDevices.getDeviceId()));
      })
      .concat(devices));

  }, [setDeviceEnrollments]);

  const getDeviceEnrollmentDetails = React.useCallback(() =>
      DeviceEnrollmentClient.getBatchEnrollmentDetails(authToken, batchId, nextPageToken),
    [authToken, nextPageToken, batchId]);

  const clearList = React.useCallback(() => {
    setDeviceEnrollments([]);
  }, [setDeviceEnrollments]);

  const loadMore = React.useCallback(() => {
    setLastRefresh(+new Date);
  }, [setLastRefresh]);

  const autoRefreshActive = React.useMemo(() =>
    autoRefresh && retries > 0 , [autoRefresh, retries]);

  const reset = React.useCallback(() => {
    setShowProgressIndicator(false);
    setShowAccessDenied(false);
    setShowNotFound(false);
    setErrorMessage("");
    setNextPageToken("");
    clearList();
  }, [
    setShowProgressIndicator,
    setShowAccessDenied,
    setShowNotFound,
    setErrorMessage,
    setNextPageToken,
    clearList,
  ]);

  const refresh = React.useCallback(() => {
    reset();
    loadMore();
  }, [reset, loadMore]);

  React.useEffect(() => {

    let ignore = false;

    setShowProgressIndicator(true);

    setErrorMessage("");
    trackRequestEvent();

    getDeviceEnrollmentDetails()
      .then((response: GetBatchEnrollmentDetailsResponse) => {
        if (!ignore) {
          const { devices = [] , paging: { next: nextPage = "" } = {} } = response;
          trackSuccessEvent();
          updateDevices(devices.map(attrs => new DeviceEnrollment(attrs)));
          setShowProgressIndicator(false);
          setNextPageToken(nextPage || "");
        }
      }, (response: RestClientError) => {
        if (!ignore) {
          const { analytic, status,  error = "Fetch device enrollment details failed" } = response;
          trackErrorEvent(analytic);
          if (status === 403) {
            setShowAccessDenied(true);
          }
          if (status === 404) {
            setShowNotFound(true);
          }
          setErrorMessage(error);
          setShowProgressIndicator(false);
        }
      });

    return () => { ignore = true; };

  }, [
    lastRefresh,
    updateDevices,
    setNextPageToken,
    setErrorMessage,
    setShowProgressIndicator,
    setShowAccessDenied,
    setShowNotFound,
    getDeviceEnrollmentDetails,
    setRetries,
    setAutoRefreshDelaySecs,
    retries,
    autoRefreshDelaySecs,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
  ]);

  // Auto Refresh functionality: We refresh the page 5 times irrespective of the status of enrollment
  // because not all the devices in the enrollment request are returned as enrollment is an async process.
  React.useEffect(() => {

    if (!autoRefreshActive) {
      return noop;
    }

    if (showProgressIndicator) {
      setAutoRefreshCountdown(autoRefreshDelaySecs);
      return noop;
    }

    if (autoRefreshCountdown > 0) {
      const timer = setTimeout(() => setAutoRefreshCountdown(autoRefreshCountdown - 1), 1000);
      return () => clearTimeout(timer);
    }

    refresh();
    setRetries(retries - 1);
    setAutoRefreshDelaySecs(autoRefreshDelaySecs + 3);

    return noop;

  }, [
    autoRefreshActive,
    showProgressIndicator,
    setAutoRefreshCountdown,
    autoRefreshCountdown,
    refresh,
    setRetries,
    setAutoRefreshDelaySecs,
  ]);

  const model = React.useMemo<UseBatchEnrollmentDetailsModel>(() => ({
    batchId,
    deviceEnrollments,
    nameFilter,
    numResults,
    showLoadMoreButton,
    errorMessage,
    showNotFound,
    showErrorView,
    showProgressIndicator,
    showNoResultsView,
    showAccessDenied,
    autoRefreshCountdown,
    retries,
  }), [
    batchId,
    deviceEnrollments,
    nameFilter,
    numResults,
    showLoadMoreButton,
    errorMessage,
    showNotFound,
    showErrorView,
    showProgressIndicator,
    showNoResultsView,
    showAccessDenied,
    autoRefreshCountdown,
    retries
  ]);

  const actions = React.useMemo<UseBatchEnrollmentDetailsActions>(() => ({
    setNameFilter,
    refresh,
    loadMore,
    clearList,
  }), [
    setNameFilter,
    refresh,
    loadMore,
    clearList,
  ]);

  return React.useMemo(() => [model, actions], [model, actions]);
};

export default useBatchEnrollmentDetails;
