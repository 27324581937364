import { DataSetRequestAttributes, GetRequestCommentsResponse } from "@data";
import {
  DataSetRequestClient,
  GetDataSetRequestsResponse,
  TermsConditionsUrlResponse,
  GetDataSetRequestEventsResponse,
} from "@network";
import { useApiRequestAnalytics } from "@hooks";

export type GetDataSetRequestsApi = () => Promise<GetDataSetRequestsResponse>;

export type GetDataSetRequestApi = (dataSetRequestId: string) => Promise<DataSetRequestAttributes>;

export type GetDataSetRequestEventsApi = (dataSetRequestId: string) => Promise<GetDataSetRequestEventsResponse>;

export type GetDataSetRequestCommentsApi = (dataSetRequestId: string) => Promise<GetRequestCommentsResponse>;

export type CreateDataSetRequestCommentApi = (dataSetRequestId: string, json: string) => Promise<void>;

export type GetTermsAndConditionsUrlApi = (termsConditionsId: string) => Promise<TermsConditionsUrlResponse>;

export type UploadTermsAndConditionsApi = (dataSetRequestId: string, file?: File) => Promise<any>;

export interface DataSetRequestClientApi {
  getDataSetRequests: GetDataSetRequestsApi;
  getDataSetRequest: GetDataSetRequestApi;
  getDataSetRequestEvents: GetDataSetRequestEventsApi;
  getDataSetRequestComments: GetDataSetRequestCommentsApi;
  createDataSetRequestComment: CreateDataSetRequestCommentApi;
  getTermsAndConditionsUrl: GetTermsAndConditionsUrlApi;
  uploadTermsAndConditions: UploadTermsAndConditionsApi;
}

enum ActionType {
  GET_DATA_SET_REQUEST_EVENTS_REQUEST = "data_set_request_client/get_data_set_request_events_request",
  GET_DATA_SET_REQUEST_EVENTS_SUCCESS = "data_set_request_client/get_data_set_request_events_success",
  GET_DATA_SET_REQUEST_EVENTS_FAILED = "data_set_request_client/get_data_set_request_events_failed",

  GET_DATA_SET_REQUEST_COMMENTS_REQUEST = "data_set_request_client/get_data_set_request_comments_request",
  GET_DATA_SET_REQUEST_COMMENTS_SUCCESS = "data_set_request_client/get_data_set_request_comments_success",
  GET_DATA_SET_REQUEST_COMMENTS_FAILED = "data_set_request_client/get_data_set_request_comments_failed",

  CREATE_DATA_SET_REQUEST_COMMENT_REQUEST = "data_set_request_client/create_data_set_request_comment_request",
  CREATE_DATA_SET_REQUEST_COMMENT_SUCCESS = "data_set_request_client/create_data_set_request_comment_success",
  CREATE_DATA_SET_REQUEST_COMMENT_FAILED = "data_set_request_client/create_data_set_request_comment_failed",

  GET_DATA_SET_REQUESTS_REQUEST = "data_set_request_client/get_data_set_requests_request",
  GET_DATA_SET_REQUESTS_SUCCESS = "data_set_request_client/get_data_set_requests_success",
  GET_DATA_SET_REQUESTS_FAILED = "data_set_request_client/get_data_set_requests_failed",

  GET_DATA_SET_REQUEST_REQUEST = "data_set_request_client/get_data_set_request_request",
  GET_DATA_SET_REQUEST_SUCCESS = "data_set_request_client/get_data_set_request_success",
  GET_DATA_SET_REQUEST_FAILED = "data_set_request_client/get_data_set_request_failed",

  GET_TERMS_AND_CONDITIONS_URL_REQUEST = "data_set_request_client/get_terms_and_conditions_url_request",
  GET_TERMS_AND_CONDITIONS_URL_SUCCESS = "data_set_request_client/get_terms_and_conditions_url_success",
  GET_TERMS_AND_CONDITIONS_URL_FAILED = "data_set_request_client/get_terms_and_conditions_url_failed",

  UPLOAD_TERMS_AND_CONDITIONS_REQUEST = "data_set_request_client/upload_terms_and_conditions_request",
  UPLOAD_TERMS_AND_CONDITIONS_SUCCESS = "data_set_request_client/upload_terms_and_conditions_success",
  UPLOAD_TERMS_AND_CONDITIONS_FAILED = "data_set_request_client/upload_terms_and_conditions_failed",
}

export const useGetDataSetRequestsApi = (): GetDataSetRequestsApi =>
  useApiRequestAnalytics(DataSetRequestClient.getDataSetRequests, {
    REQUEST_EVENT: ActionType.GET_DATA_SET_REQUESTS_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DATA_SET_REQUESTS_SUCCESS,
    ERROR_EVENT: ActionType.GET_DATA_SET_REQUESTS_FAILED,
  });

export const useGetDataSetRequestApi = (): GetDataSetRequestApi =>
  useApiRequestAnalytics(DataSetRequestClient.getDataSetRequest, {
    REQUEST_EVENT: ActionType.GET_DATA_SET_REQUEST_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DATA_SET_REQUEST_SUCCESS,
    ERROR_EVENT: ActionType.GET_DATA_SET_REQUEST_FAILED,
  });

export const useGetDataSetRequestEventsApi = (): GetDataSetRequestEventsApi =>
  useApiRequestAnalytics(DataSetRequestClient.getDataSetRequestEvents, {
    REQUEST_EVENT: ActionType.GET_DATA_SET_REQUEST_EVENTS_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DATA_SET_REQUEST_EVENTS_SUCCESS,
    ERROR_EVENT: ActionType.GET_DATA_SET_REQUEST_EVENTS_FAILED,
  });

export const useGetDataSetRequestCommentsApi = (): GetDataSetRequestCommentsApi =>
  useApiRequestAnalytics(DataSetRequestClient.getDataSetRequestComments, {
    REQUEST_EVENT: ActionType.GET_DATA_SET_REQUEST_COMMENTS_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DATA_SET_REQUEST_COMMENTS_SUCCESS,
    ERROR_EVENT: ActionType.GET_DATA_SET_REQUEST_COMMENTS_FAILED,
  });

export const useCreateDataSetRequestCommentApi = (): CreateDataSetRequestCommentApi =>
  useApiRequestAnalytics(DataSetRequestClient.createDataSetRequestComment, {
    REQUEST_EVENT: ActionType.CREATE_DATA_SET_REQUEST_COMMENT_REQUEST,
    SUCCESS_EVENT: ActionType.CREATE_DATA_SET_REQUEST_COMMENT_SUCCESS,
    ERROR_EVENT: ActionType.CREATE_DATA_SET_REQUEST_COMMENT_FAILED,
  });

export const useGetTermsAndConditionsUrlApi = (): GetTermsAndConditionsUrlApi =>
  useApiRequestAnalytics(DataSetRequestClient.getTermsAndConditionsUrl, {
    REQUEST_EVENT: ActionType.GET_TERMS_AND_CONDITIONS_URL_REQUEST,
    SUCCESS_EVENT: ActionType.GET_TERMS_AND_CONDITIONS_URL_SUCCESS,
    ERROR_EVENT: ActionType.GET_TERMS_AND_CONDITIONS_URL_FAILED,
  });

export const useUploadTermsAndConditionsApi = (): UploadTermsAndConditionsApi =>
  useApiRequestAnalytics(DataSetRequestClient.uploadTermsAndConditions, {
    REQUEST_EVENT: ActionType.UPLOAD_TERMS_AND_CONDITIONS_REQUEST,
    SUCCESS_EVENT: ActionType.UPLOAD_TERMS_AND_CONDITIONS_SUCCESS,
    ERROR_EVENT: ActionType.UPLOAD_TERMS_AND_CONDITIONS_FAILED,
  });

export const useDataSetRequestClient = (): DataSetRequestClientApi => ({
  getDataSetRequests: useGetDataSetRequestsApi(),
  getDataSetRequest: useGetDataSetRequestApi(),
  getDataSetRequestEvents: useGetDataSetRequestEventsApi(),
  getDataSetRequestComments: useGetDataSetRequestCommentsApi(),
  createDataSetRequestComment: useCreateDataSetRequestCommentApi(),
  getTermsAndConditionsUrl: useGetTermsAndConditionsUrlApi(),
  uploadTermsAndConditions: useUploadTermsAndConditionsApi(),
});

export { ActionType as DataSetRequestClientActionType };

export default useDataSetRequestClient;
