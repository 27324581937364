import React from "react";
import { isEmptyString, noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { DeviceConfigurationDataMode } from "../reducers";
import { DeviceTypeListItem, JsonSchemaMetadata } from "@data";
import {
  getDataMode,
  isLoadingIndicator,
  getConfigurationErrorMessage,
  getDeviceId,
  getErrorMessage,
  isNotFoundVisible,
} from "../selectors";
import {
  refresh,
  updateDataMode,
} from "../actions";
import DeviceConfigurationView, { Model, Actions } from "../components/DeviceConfigurationView";
import DeviceConfigurationSummaryView from "./DeviceConfigurationSummaryView";
import DeviceConfigurationJsonView from "./DeviceConfigurationJsonView";
import { DetailsViewRoute } from "@components";
import ErrorView from "@components/error-view";

interface ContainerActions extends Actions {
  showDeviceTypeDetailsInNewTab?: (deviceType: DeviceTypeListItem) => void;
  showSchemaDetailsInNewTab?: (schema: JsonSchemaMetadata) => void;
}

type Props = Model & ContainerActions;

const DeviceConfigurationViewContainer = (props: Props) => {

  const {
    showDeviceTypeDetailsInNewTab = noop,
    showSchemaDetailsInNewTab = noop,
    configurationErrorMessage,
  } = props;

  if (!isEmptyString(configurationErrorMessage)) {
    return (
      <ErrorView
        message={configurationErrorMessage}
      />
    );
  }

  const DeviceConfigSummaryView = React.useMemo<() => React.ReactElement>(() => () => {
    return(
      <DeviceConfigurationSummaryView
        showDeviceTypeDetailsInNewTab={showDeviceTypeDetailsInNewTab}
        showSchemaDetailsInNewTab={showSchemaDetailsInNewTab}
        errorMessage={configurationErrorMessage}
      />
    );
  }, [showDeviceTypeDetailsInNewTab, showSchemaDetailsInNewTab, configurationErrorMessage]);

  const routes: DetailsViewRoute[] = React.useMemo(() => [
    {
      id: "summary",
      name: "Summary",
      view: DeviceConfigSummaryView,
    },
    {
      id: "json",
      name: "{} JSON",
      path: "/json",
      view: DeviceConfigurationJsonView
    }
  ], [DeviceConfigSummaryView]);

  return (
    <DeviceConfigurationView
      {...props}
      routes={routes}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  dataMode: getDataMode(state),
  loading: isLoadingIndicator(state),
  configurationErrorMessage: getConfigurationErrorMessage(state),
  deviceId: getDeviceId(state),
  errorMessage: getErrorMessage(state),
  showNotFound: isNotFoundVisible(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setDataMode: (dataMode: DeviceConfigurationDataMode) => dispatch(updateDataMode(dataMode)),
  refresh: () => dispatch(refresh()),
  ...ownProps
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceConfigurationViewContainer);
