import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { toggleShowFeedbackDialog } from "@main/actions";
import {
  getCurrentAccountId,
  getUserFavoriteModules,
  isInformationBannerVisible,
  isMaintenanceReminderVisible,
  isQualityReportsEnabled,
  isCostReportsEnabled,
  isUserPrincipal,
} from "@main/selectors";
import Navigation, { Actions, Model } from "../components/Navigation";
import getNavigationModules from "@main/selectors/getNavigationModules";

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
}

type Props = ContainerActions & ContainerModel;

const NavigationContainer = (props: Props) => {
  const {
    favoriteModules = [],
    ...otherProps
  } = props;

  return (
    <Navigation
      {...otherProps}
      favoriteModules={favoriteModules}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): ContainerModel => ({
  accountId: getCurrentAccountId(state),
  showMaintenanceReminder: isMaintenanceReminderVisible(state),
  informationBannerVisible: isInformationBannerVisible(state),
  isUserPrincipal: isUserPrincipal(state),
  favoriteModules: getUserFavoriteModules(state),
  modules: getNavigationModules(state),
  showQualityReportsIconButton: isQualityReportsEnabled(state),
  showCostReportsIconButton: isCostReportsEnabled(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  showFeedbackDialog: (value: boolean) => dispatch(toggleShowFeedbackDialog(value)),
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(NavigationContainer);
