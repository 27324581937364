import React from "react";
import classnames from "classnames";
import Button from "@components/button";
import DownloadIcon from "@material-ui/icons/GetApp";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export const DownloadLink = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  href?: string,
  label?: string,
  icon: React.ComponentType<SvgIconProps>,
}) => {

  const { classes, className, href, label, icon: Icon = DownloadIcon } = props;

  if (!href || !label) {
    return null;
  }

  return (
    <Button
      className={classnames("downloadLink", className, classes.container)}
      classes={{
        label: classnames("label", classes.label),
      }}
      href={href}
    >
      <Icon
        className={classes.icon}
        color="inherit"
      />
      {label}
    </Button>
  );
});

export default DownloadLink;
