import createIcon from "./createIcon";

export const BackIcon = createIcon(require("./resources/ic_back.svg"));
export const CheckmarkIcon = createIcon(require("./resources/ic_checkmark.svg"));
export const DropdownIcon = createIcon(require("./resources/ic_dropdown.svg"));
export const HelpIcon = createIcon(require("./resources/ic_help.svg"));
export const InventoryIcon = createIcon(require("./resources/ic_inventory.svg"));
export const PendingActionsIcon = createIcon(require("./resources/ic_pending_actions.svg"));
export const SettingsIcon = createIcon(require("./resources/ic_settings.svg"));
export const SortIcon = createIcon(require("./resources/ic_sort.svg"));
export const SortDescIcon = createIcon(require("./resources/ic_sort_desc.svg"));
export const UserIcon = createIcon(require("./resources/ic_user.svg"));
export const UsersIcon = createIcon(require("./resources/ic_users.svg"));
export const SignifyLogo = createIcon(require("./resources/ic_signify_logo.svg"));
export const SignifyIcon = createIcon(require("./resources/ic_signify_icon.svg"));
