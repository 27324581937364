import { isEmptyString, isValidInteger } from "@util";
import {
  DSLayoutInfoAttributes,
  DSTypeInfoAttributes,
  DSTypeLayoutAttributes
} from "@data";
import {
  createQueryParams,
  ETagHeaders,
  ETagLocationHeaders,
  getETagHeaders,
  getETagLocationHeaders,
  makeApiRequest,
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments
} from "@network/helpers";

const DIGITAL_SHADOW_API = process.env.REACT_APP_DIGITAL_SHADOW_API || "";
const DEFAULT_LIMIT = 50;

if (isEmptyString(DIGITAL_SHADOW_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_DIGITAL_SHADOW_API");
}

export interface ListLayoutsTypesResponse {
  items: DSTypeLayoutAttributes[];
  paging: {
    next?: string;
  };
}

export interface ListTypesResponse {
  items: DSTypeInfoAttributes[];
  paging: {
    next?: string;
  };
}

export interface GetDSLayoutResponse {
  etag: ETagHeaders;
  layoutAttrs: DSLayoutInfoAttributes;
}

export interface GetDSTypeResponse {
  etag: ETagHeaders;
  typeAttrs: DSTypeInfoAttributes;
}

export const listTypes = (authToken: string,
                          next: string = "",
                          limit: number = DEFAULT_LIMIT): Promise<ListLayoutsTypesResponse> => {

  const validate = () => withAuthToken(authToken);

  const queryParams = createQueryParams({
    offset: next,
    ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, Math.min(DEFAULT_LIMIT, limit)) }),
  });

  const makeRequest = () => {

    const url = `${DIGITAL_SHADOW_API}/device-management/shadow/v1/configurations/types${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Digital Shadow Types failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getDigitalShadowType = (authToken: string,
                                     name: string): Promise<GetDSTypeResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Type", name],
    ]));

  const makeRequest = () => {

    const url = `${DIGITAL_SHADOW_API}/device-management/shadow/v1/configurations/types/${name}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Digital Shadow type failed";

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(response => {

        const etag = getETagHeaders(response);

        return response.json()
          .then(attrs => ({
            etag,
            typeAttrs: attrs
          }));
      });
  };

  return validate().then(makeRequest);
};

export const createDSType = (authToken: string,
                             json: string): Promise<ETagLocationHeaders> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Type", json]
    ]));

  const makeRequest = () => {

    const url = `${DIGITAL_SHADOW_API}/device-management/shadow/v1/configurations/types`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to create digital shadow type";

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getETagLocationHeaders);
  };

  return validate().then(makeRequest);
};

export const updateDSType = (authToken: string,
                             name: string,
                             json: string,
                             etag: string): Promise<ETagHeaders> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Name", name],
      ["Type", json],
      ["Etag", etag],
    ]));

  const makeRequest = () => {

    const url = `${DIGITAL_SHADOW_API}/device-management/shadow/v1/configurations/types/${name}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
        "If-Match": etag,
      },
      body: json,
    };

    const defaultErrorMessage = `Failed to update digital shadow type ${name}`;

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getETagHeaders);
  };

  return validate().then(makeRequest);
};

export const deleteDSType = (authToken: string,
                             name: string,
                             etag: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Name", name],
      ["Etag", etag],
    ]));

  const makeRequest = () => {

    const url = `${DIGITAL_SHADOW_API}/device-management/shadow/v1/configurations/types/${name}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "If-Match": etag,
      },
    };

    const defaultErrorMessage = `Failed to delete digital shadow type ${name}`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const listLayouts = (authToken: string,
                            next: string = "",
                            limit: number = DEFAULT_LIMIT): Promise<ListLayoutsTypesResponse> => {

  const validate = () => withAuthToken(authToken);

  const queryParams = createQueryParams({
    offset: next,
    ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, Math.min(DEFAULT_LIMIT, limit)) }),
  });

  const makeRequest = () => {

    const url = `${DIGITAL_SHADOW_API}/device-management/shadow/v1/configurations/layouts${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Digital Shadow Layouts failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getDigitalShadowLayout = (authToken: string,
                                       name: string): Promise<GetDSLayoutResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Layout", name],
    ]));

  const makeRequest = () => {

    const url = `${DIGITAL_SHADOW_API}/device-management/shadow/v1/configurations/layouts/${name}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Digital Shadow layout failed";

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(response => {

        const etag = getETagHeaders(response);

        return response.json()
          .then(attrs => ({
            etag,
            layoutAttrs: attrs
          }));
      });
  };

  return validate().then(makeRequest);
};

export const createDSLayout = (authToken: string,
                               json: string): Promise<ETagHeaders> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Layout", json]
    ]));

  const makeRequest = () => {

    const url = `${DIGITAL_SHADOW_API}/device-management/shadow/v1/configurations/layouts`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to create digital shadow layout";

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getETagLocationHeaders);
  };

  return validate().then(makeRequest);
};

export const updateDSLayout = (authToken: string,
                               name: string,
                               json: string,
                               etag: string): Promise<ETagHeaders> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Name", name],
      ["Layout", json],
      ["Etag", etag],
    ]));

  const makeRequest = () => {

    const url = `${DIGITAL_SHADOW_API}/device-management/shadow/v1/configurations/layouts/${name}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
        "If-Match": etag,
      },
      body: json,
    };

    const defaultErrorMessage = `Failed to update digital shadow layout ${name}`;

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(getETagHeaders);
  };

  return validate().then(makeRequest);
};

export const deleteDSLayout = (authToken: string,
                               name: string,
                               etag: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Name", name],
      ["Etag", etag],
    ]));

  const makeRequest = () => {

    const url = `${DIGITAL_SHADOW_API}/device-management/shadow/v1/configurations/layouts/${name}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "If-Match": etag,
      },
    };

    const defaultErrorMessage = `Failed to delete digital shadow layout ${name}`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
