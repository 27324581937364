import { AppSchema } from "@schemas";
import { DeviceTypeClient, ETagHeaders, RestClientError } from "@network";
import { DeviceTypeModelV2, DeviceTypeModelV3, DeviceTypeModelVersion } from "@data";
import { DeviceTypeRequestV2, DeviceTypeRequestV3, DeviceTypeWizardState } from "../models";
import { DeviceTypeWizardStep } from "../reducers/deviceTypeWizard";
import { DeviceTypeWizardSelectors } from "../selectors";
import { DeviceTypeWizardActions } from "../actions";
import isDeviceTypeV2APIEnabled from "@util/isDeviceTypeV2APIEnabled";

export const save = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const isRegionalApiModel = DeviceTypeWizardSelectors.isRegionalApiSelected(state);
  const modelVersion = DeviceTypeWizardSelectors.getModelVersion(state);
  const accessToken = DeviceTypeWizardSelectors.getAccessToken(state);
  const namespace = DeviceTypeWizardSelectors.getNamespace(state);
  const name = DeviceTypeWizardSelectors.getName(state);
  const version = DeviceTypeWizardSelectors.getVersion(state);
  const json = DeviceTypeWizardSelectors.getJson(state);
  const etag = DeviceTypeWizardSelectors.getEtag(state);

  const editDeviceType = () => {
    if (isRegionalApiModel) {
      return DeviceTypeClient.editDeviceTypeV3(accessToken, namespace, name, version, json, etag);
    } else {
      return DeviceTypeClient.editDeviceTypeV2(accessToken, namespace, name, version, json, etag);
    }
  };

  dispatch(DeviceTypeWizardActions.showLoadingIndicator());
  dispatch(DeviceTypeWizardActions.hideAccessDenied());
  dispatch(DeviceTypeWizardActions.setErrorMessage());
  dispatch(DeviceTypeWizardActions.editDeviceTypeRequest(modelVersion));

  return editDeviceType()
    .then((response: ETagHeaders) => {

      const { etag: updatedEtag } = response;

      dispatch(DeviceTypeWizardActions.editDeviceTypeSuccess(modelVersion));
      dispatch(DeviceTypeWizardActions.setEtag(updatedEtag));
      dispatch(DeviceTypeWizardActions.setSuccessMessage("Device Type Updated"));
      return dispatch(DeviceTypeWizardActions.hideLoadingIndicator());

    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to update Device Type" } = response;

      dispatch(DeviceTypeWizardActions.editDeviceTypeFailed(`${modelVersion}:${analytic}`));
      dispatch(DeviceTypeWizardActions.setErrorMessage(error));

      if (status === 403) {
        dispatch(DeviceTypeWizardActions.showAccessDenied());
      }

      return dispatch(DeviceTypeWizardActions.hideLoadingIndicator());
    });
};

export const fetchDeviceType = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accessToken = DeviceTypeWizardSelectors.getAccessToken(state);
  const namespace = DeviceTypeWizardSelectors.getNamespace(state);
  const name = DeviceTypeWizardSelectors.getName(state);
  const version = DeviceTypeWizardSelectors.getDeviceTypeRefVersion(state);

  const getDeviceTypeV2 = () => {
    if (!isDeviceTypeV2APIEnabled()) {
      return Promise.resolve(DeviceTypeModelV2.EMPTY.toJS());
    } else {
      return DeviceTypeClient.getDeviceTypeV2(accessToken, namespace, name, version);
    }
  };

  const getDeviceType = () => Promise.all([
    DeviceTypeClient.getDeviceTypeV3(accessToken, namespace, name, version),
    getDeviceTypeV2(),
  ]);

  dispatch(DeviceTypeWizardActions.showLoadingIndicator());
  dispatch(DeviceTypeWizardActions.hideAccessDenied());
  dispatch(DeviceTypeWizardActions.setErrorMessage());
  dispatch(DeviceTypeWizardActions.setShowEditModeInitializeError(false));
  dispatch(DeviceTypeWizardActions.fetchDeviceTypeRequest());

  return getDeviceType()
    .then(([responseV3, deviceTypeV2Attrs]) => {

      const { etag, modelVersion, ...deviceTypeV3Attrs } = responseV3;

      const deviceTypeRequestV3Attrs = DeviceTypeRequestV3.from(
        new DeviceTypeModelV3({
          etag,
          modelVersion,
          ...deviceTypeV3Attrs,
        })).toJS();

      const deviceTypeRequestV2Attrs = DeviceTypeRequestV2.from(
        new DeviceTypeModelV2(deviceTypeV2Attrs)).toJS();

      const deviceTypeRequestAttrs = DeviceTypeModelVersion.HISTORICAL === modelVersion
        ? deviceTypeRequestV2Attrs : deviceTypeRequestV3Attrs;

      const json = JSON.stringify(deviceTypeRequestAttrs, null, "  ");

      dispatch(DeviceTypeWizardActions.fetchDeviceTypeSuccess());
      dispatch(DeviceTypeWizardActions.setEtag(etag));
      dispatch(DeviceTypeWizardActions.setModelVersion(modelVersion));
      dispatch(DeviceTypeWizardActions.setDeviceTypeRequestAttributes(deviceTypeRequestV3Attrs));
      dispatch(DeviceTypeWizardActions.setDeviceTypeRequestV2Attributes(deviceTypeRequestV2Attrs));
      dispatch(DeviceTypeWizardActions.setJson(json));
      dispatch(DeviceTypeWizardActions.setDeviceTypeWizardDefaultState(new DeviceTypeWizardState({
        namespace,
        name,
        json,
      }).toJS()));
      dispatch(DeviceTypeWizardActions.hideLoadingIndicator());
      return dispatch(DeviceTypeWizardActions.hideEmptyView());

    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to fetch Device Type" } = response;

      dispatch(DeviceTypeWizardActions.fetchDeviceTypeFailed(analytic));
      dispatch(DeviceTypeWizardActions.setErrorMessage(error));
      dispatch(DeviceTypeWizardActions.setShowEditModeInitializeError(true));
      dispatch(DeviceTypeWizardActions.hideLoadingIndicator());

      if (status === 403) {
        dispatch(DeviceTypeWizardActions.showAccessDenied());
      }

      dispatch(DeviceTypeWizardActions.hideLoadingIndicator());
      return dispatch(DeviceTypeWizardActions.hideEmptyView());
    });
};

export const initialize = (typeIdentity: string = "") => (dispatch: any) => {

  const deviceType = new DeviceTypeModelV3({ typeIdentity });

  dispatch(DeviceTypeWizardActions.reset());
  dispatch(DeviceTypeWizardActions.showEmptyView());
  dispatch(DeviceTypeWizardActions.setDeviceTypeRef(typeIdentity));
  dispatch(DeviceTypeWizardActions.showEditMode());
  dispatch(DeviceTypeWizardActions.setDeviceTypeWizardStep(DeviceTypeWizardStep.EDITOR));
  dispatch(DeviceTypeWizardActions.setNamespace(deviceType.getNamespace()));
  dispatch(DeviceTypeWizardActions.setName(deviceType.getName()));
  dispatch(DeviceTypeWizardActions.setVersion(deviceType.getVersion()));
  dispatch(DeviceTypeWizardActions.setDeviceTypeRequestAttributes(DeviceTypeRequestV3.from(deviceType)));
  return dispatch(fetchDeviceType());
};

export const retryInitialize = () => (dispatch: any, getState: () => AppSchema) => {
  return dispatch(initialize(DeviceTypeWizardSelectors.getDeviceTypeRef(getState())));
};
