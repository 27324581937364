import React from "react";
import classnames from "classnames";
import { DataSetType } from "@data";
import Button from "@components/button";
import NameFilter from "@components/name-filter";
import DropdownMenu from "@components/dropdown-menu";
import FilterSwitch from "@components/filter-switch";
import { clickHandler, isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const DEFAULT_VALIDATE_ACCOUNT_FILTER = (account: string) => /^[0-9]{10}$/.test(account);
const DEFAULT_VALIDATE_ACCOUNT_FILTER_ERROR_MESSAGE =
  "Account ID Must Be 10-Digit Number";

const DEFAULT_VALIDATE_GROUP_FILTER = (group: string) => `${group}`.trim().length >= 4;
const DEFAULT_VALIDATE_GROUP_FILTER_ERROR_MESSAGE =
  "Group must be at least 4 characters";

const mapDataSetTypeToOptionName = (value: DataSetType) => {
  switch (value) {
    case DataSetType.DATA_LAKE_ACQUIRED:
      return "Data Lake Acquired";
    case DataSetType.DATA_LAKE_BLOBS:
      return "Data Lake Blobs";
    case DataSetType.DATA_WAREHOUSE_SYSTEM:
      return "Data Warehouse System";
    case DataSetType.DATA_WAREHOUSE_SHARED:
      return "Data Warehouse Shared";
    default:
      return null;
  }
};

export interface DataSetsFiltersModel {
  className?: string;
  disabled?: boolean;
  dataSetTypeFilter?: DataSetType;
  dataSetTypeFilters?: DataSetType[];
  showAccountFilterButton?: boolean;
  currentAccountFilterEnabled?: boolean;
  showAccountFilter?: boolean;
  validateAccountFilterEnabled?: boolean;
  validateAccountFilterErrorMessage?: string;
  accountFilter?: string;
  showGroupFilter?: boolean;
  validateGroupFilterEnabled?: boolean;
  validateGroupFilterErrorMessage?: string;
  groupFilter?: string;
  hideClearButton?: boolean;
  clearButtonDisabled?: boolean;
  clearButtonLabel?: string;
}

export interface DataSetsFiltersActions {
  setDataSetTypeFilter?: (value: DataSetType) => void;
  setCurrentAccountFilterEnabled?: (enabled: boolean) => void;
  validateAccountFilter?: (value: string) => boolean;
  setAccountFilter?: (value: string) => void;
  validateGroupFilter?: (value: string) => boolean;
  setGroupFilter?: (value: string) => void;
  clearFilters?: () => void;
}

type Model = DataSetsFiltersModel;
type Actions = DataSetsFiltersActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataSetsFilters = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    disabled,
    dataSetTypeFilter = DataSetType.NONE,
    dataSetTypeFilters = Object.values(DataSetType),
    showAccountFilterButton = true,
    currentAccountFilterEnabled = true,
    showAccountFilter = true,
    validateAccountFilterEnabled = true,
    validateAccountFilterErrorMessage = DEFAULT_VALIDATE_ACCOUNT_FILTER_ERROR_MESSAGE,
    accountFilter = "",
    showGroupFilter = true,
    validateGroupFilterEnabled = true,
    validateGroupFilterErrorMessage = DEFAULT_VALIDATE_GROUP_FILTER_ERROR_MESSAGE,
    groupFilter = "",
    hideClearButton,
    clearButtonDisabled,
    clearButtonLabel = "Clear Filters",
    setDataSetTypeFilter = noop,
    setCurrentAccountFilterEnabled = noop,
    validateAccountFilter = DEFAULT_VALIDATE_ACCOUNT_FILTER,
    setAccountFilter = noop,
    validateGroupFilter = DEFAULT_VALIDATE_GROUP_FILTER,
    setGroupFilter = noop,
    clearFilters = noop,
    children,
  } = props;

  const [showDataSetsFromAllAccounts, setShowDataSetsFromAllAccounts] =
    React.useState(!currentAccountFilterEnabled && isEmptyString(accountFilter));

  // Our footer currently only contains the clear button, but filter menu footers typically also
  // contain a limit dropdown; however, we do not have support for that in the data sets list
  const showFooter = React.useMemo(() =>
    !hideClearButton, [hideClearButton]);

  const onClickClearFilters = React.useCallback(clickHandler(() => {
    clearFilters();
    setShowDataSetsFromAllAccounts(false);
  }), [clearFilters, setShowDataSetsFromAllAccounts]);

  return (
    <div
      className={classnames("dataSetsFilters",
        "filtersMenu",
        className,
        classes.container, {
          [classes.showFooter]: showFooter,
        },
      )}
    >
      <DropdownMenu
        className={classnames("dataSetTypeFilter", classes.dropdownMenu, classes.dataSetTypeFilter)}
        disabled={disabled}
        dropdownMenuLabelClassName={classes.dropdownMenuLabel}
        dropdownMenuLabel="Data Set Type"
        emptyValueLabel="All Data Sets"
        values={dataSetTypeFilters}
        selectedValue={dataSetTypeFilter}
        setSelectedValue={setDataSetTypeFilter}
        mapValueToLabel={mapDataSetTypeToOptionName}
      />
      {showAccountFilterButton && (
        <FilterSwitch
          className={classnames("currentAccountFilterSwitch",
            classes.filterSwitch,
            classes.currentAccountFilterSwitch,
          )}
          disabled={disabled}
          rightLabel="Show data sets from all accounts"
          checked={showDataSetsFromAllAccounts}
          onChange={() => {

            const updatedShowDataSetsFromAllAccounts = !showDataSetsFromAllAccounts;

            // When this switch is enabled, it means that we are fetching data sets for ALL accounts;
            // we must therefore disable the current account filter and clear the account
            // filter query since neither of these fields make logical sense when fetching
            // data sets across all accounts.
            if (updatedShowDataSetsFromAllAccounts) {
              setShowDataSetsFromAllAccounts(true);
              setCurrentAccountFilterEnabled(false);
              setAccountFilter("");
            } else {
              // When this switch is disabled; however, we can infer whether the current account
              // filter is enabled based on whether the account filter query text field is empty
              setShowDataSetsFromAllAccounts(false);
              setCurrentAccountFilterEnabled(isEmptyString(accountFilter));
            }
          }}
        />
      )}
      {showAccountFilter && (
        <NameFilter
          className={classnames("searchAccount", classes.search, classes.searchAccount)}
          ariaLabel="search-account"
          fullWidth={true}
          disabled={disabled}
          hint="Filter by account"
          nameFilter={accountFilter}
          validationEnabled={validateAccountFilterEnabled}
          validationErrorMessage={validateAccountFilterErrorMessage}
          validateNameFilter={validateAccountFilter}
          setNameFilter={updatedValue => {
            setAccountFilter(updatedValue);

            // If the account filter query is updated to a non-empty value, disable the
            // current account filter since we cannot use both
            if (!isEmptyString(updatedValue)) {
              setShowDataSetsFromAllAccounts(false);
              setCurrentAccountFilterEnabled(false);
            } else {
              // If the text field is cleared; however, we should toggle the current account filter
              // based on the inverse of whether the filter switch to show data sets from all
              // accounts is enabled or disabled; if disabled, we should fetch data sets for the
              // current account only, but if enabled, we should fetch data sets for ALL accounts.
              setCurrentAccountFilterEnabled(!showDataSetsFromAllAccounts);
            }
          }}
        />
      )}
      {showGroupFilter && (
        <NameFilter
          className={classnames("searchGroup", classes.search, classes.searchGroup)}
          ariaLabel="search-group"
          fullWidth={true}
          disabled={disabled}
          hint="Filter by group"
          nameFilter={groupFilter}
          validationEnabled={validateGroupFilterEnabled}
          validationErrorMessage={validateGroupFilterErrorMessage}
          validateNameFilter={validateGroupFilter}
          setNameFilter={setGroupFilter}
        />
      )}
      {showFooter && (
        <div className={classnames("footer", classes.footer)}>
          {!hideClearButton && (
            <Button
              className={classnames("clearFiltersButton", classes.clearFiltersButton)}
              classes={{
                label: classnames("label", classes.clearFiltersButtonLabel),
              }}
              disabled={disabled || clearButtonDisabled}
              onClick={onClickClearFilters}
            >
              {clearButtonLabel}
            </Button>
          )}
        </div>
      )}
      {children}
    </div>
  );
});

export default DataSetsFilters;
