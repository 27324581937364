import React from "react";
import classnames from "classnames";
import { clickHandler, noop } from "@util";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { ActionMenuItem } from "@components";
import { Button } from "../button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { RedirectUrisTableBodyRow } from "./RedirectUriTableBodyRow";
import { RedirectUrisTableFooterRow } from "./RedirectUrisTableFooterRow";
import { RedirectUrisTableHeaderRow } from "./RedirectUrisTableHeaderRow";
import { RedirectUrisTableAction, REDIRECT_URIS_TABLE_ACTIONS } from "./RedirectUrisTableAction";
import { addButton, styles } from "./styles";

export const NUM_COLUMNS = 2;

const AddButton = withStyles(addButton)(Button);

export interface RedirectUriTableModel {
  className?: string;
  uris?: string[];
  actions?: ActionMenuItem[];
  children?: React.ReactNode;
}

export interface RedirectUriTableActions {
  addUri?: () => void;
  editRedirectUri?: (uri: string) => void;
  removeRedirectUri?: (uri: string) => void;
}

type Props = WithStyles<typeof styles> & RedirectUriTableModel & RedirectUriTableActions;

export const RedirectUrisTable = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    uris = [],
    actions = REDIRECT_URIS_TABLE_ACTIONS,
    children,
    addUri,
    editRedirectUri = noop,
    removeRedirectUri = noop,
  } = props;

  const showActionsColumn = actions.length > 0;

  const onClickUriAction = React.useCallback((uri: string, action: ActionMenuItem) => {
    if (RedirectUrisTableAction.EDIT_URI === action.id) {
      editRedirectUri(uri);
    } else if (RedirectUrisTableAction.REMOVE_URI === action.id) {
      removeRedirectUri(uri);
    }
  } , [editRedirectUri, removeRedirectUri]);

  const numColumns = showActionsColumn ? NUM_COLUMNS : (NUM_COLUMNS - 1);

  return (
    <Table className={classnames("redirectUrisTable", className, classes.table)}>
      <TableHead>
        <RedirectUrisTableHeaderRow showActionsColumn={showActionsColumn} />
      </TableHead>
      <TableBody>
        {uris.map((uri: string, rowIndex: number) => (
          <RedirectUrisTableBodyRow
            uri={uri}
            key={`table-body-row-${rowIndex}`}
            actions={actions}
            onClickAction={(action: ActionMenuItem) => onClickUriAction(uri, action)}
            editUri={editRedirectUri}
          />
        ))}
        <RedirectUrisTableFooterRow
          className={classnames("addButtonRow", classes.addButtonRow)}
          columnClassName={classnames("addButtonColumn", classes.addButtonColumn)}
          numColumns={numColumns}
        >
          <AddButton
            className={classnames("addButton", classes.addButton)}
            onClick={clickHandler(addUri)}
            disableRipple={true}
          >
            Add Redirect Uri
          </AddButton>
        </RedirectUrisTableFooterRow>
        <RedirectUrisTableFooterRow numColumns={numColumns}>
          {children}
        </RedirectUrisTableFooterRow>
      </TableBody>
    </Table>
  );
});

export default RedirectUrisTable;
