import createStyles from "@material-ui/core/styles/createStyles";
import { PrimaryIcon } from "@modules/qualityReports/components/styles";
import Color from "@Color";

export { PrimaryIcon, PrimaryIcon as ModuleIcon };

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    header: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    icon: {
      color: Color.SIGNIFY_GREEN,
      width: "auto",
      height: 48,
    },
    title: {
      marginLeft: 10,
      color: Color.TEXT,
      fontWeight: 300,
    },
    createdAt: {
      marginLeft: 60,
      fontSize: 11,
      fontWeight: 300,
      color: Color.GREY4,
    },
    refreshButton: {
      color: Color.SIGNIFY_GREEN,
      marginLeft: 10,
    },
    row: {
      marginTop: 15,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
    },
    column: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    flexRow: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      "& > div": {
        marginLeft: 15,
        "&:first-child": {
          marginLeft: 0,
        },
      },
      "& > .licenses, & > .flexColumn.firstColumn": {
        marginTop: 15,
        flex: "1 0 auto",
      },
      "& > .codeCoverage": {
        flex: "1 0 auto",
        minWidth: 500,
      },
    },
    flexColumn: {
      display: "flex",
      flexFlow: "column nowrap",

      "& > div": {
        marginTop: 15,
        "&:first-child": {
          marginTop: 0,
        },
      },
    },
    licenses: {
      flex: "1 0 auto",
    },
    tests: {
      flex: "1 0 auto",
      marginLeft: 15,
    },
    securitySection: {
      marginLeft: 15,
      flex: "8 0 auto",
    },
    qualitySection: {
      marginTop: 15,
      flex: "2 0 auto",
    },
    codeCoverage: {
      marginLeft: 15,
      flex: "8 0 auto",
      minWidth: 868,
    },
  });

export default styles;
