import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";
import { SecurityGroupBulkRequestAttributes, UsersBulkRequestAttributes } from "@data";

export enum GroupManagementWizardAction {
  NONE = "",
  ADD_USER = "add_user",
  REMOVE_USER = "remove_user",
  ADD_SERVICE = "add_service",
  REMOVE_SERVICE = "remove_service",
}

export enum SelectSecurityGroupStep {
  NONE = "",
  GROUPS = "groups",
  REVIEW = "review",
}

export enum SelectUserStep {
  NONE = "",
  USERS = "users",
  REVIEW = "review",
}

export interface GroupManagementWizardSchema extends BasePortalModuleSchema {
  userId: string;
  serviceId: string;
  groupName: string;
  securityGroupBulkRequest: SecurityGroupBulkRequestAttributes[];
  userBulkRequest: UsersBulkRequestAttributes[];
  action: GroupManagementWizardAction;
  groupsStep: SelectSecurityGroupStep;
  usersStep: SelectUserStep;
}

export const DEFAULT_STATE: GroupManagementWizardSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  showEmptyView: false,
  userId: "",
  serviceId: "",
  groupName: "",
  securityGroupBulkRequest: [],
  userBulkRequest: [],
  action: GroupManagementWizardAction.NONE,
  groupsStep: SelectSecurityGroupStep.NONE,
  usersStep: SelectUserStep.NONE,
});

export type GroupManagementWizardActionTypes = PortalModuleActionTypes<GroupManagementWizardSchema> & {
  ADD_USER_TO_GROUPS_REQUEST: string;
  ADD_USER_TO_GROUPS_SUCCESS: string;
  ADD_USER_TO_GROUPS_FAILED: string;
  REMOVE_USER_FROM_GROUPS_REQUEST: string;
  REMOVE_USER_FROM_GROUPS_SUCCESS: string;
  REMOVE_USER_FROM_GROUPS_FAILED: string;
  ADD_SERVICE_TO_GROUPS_REQUEST: string;
  ADD_SERVICE_TO_GROUPS_SUCCESS: string;
  ADD_SERVICE_TO_GROUPS_FAILED: string;
  REMOVE_SERVICE_FROM_GROUPS_REQUEST: string;
  REMOVE_SERVICE_FROM_GROUPS_SUCCESS: string;
  REMOVE_SERVICE_FROM_GROUPS_FAILED: string;
};

export const ACTION_TYPES: GroupManagementWizardActionTypes = {
  ...createPortalModuleActionTypes<GroupManagementWizardSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    ADD_USER_TO_GROUPS_REQUEST: `${MODULE_ID}/add_user_to_groups_request`,
    ADD_USER_TO_GROUPS_SUCCESS: `${MODULE_ID}/add_user_to_groups_success`,
    ADD_USER_TO_GROUPS_FAILED: `${MODULE_ID}/add_user_to_groups_failed`,
    REMOVE_USER_FROM_GROUPS_REQUEST: `${MODULE_ID}/remove_user_from_groups_request`,
    REMOVE_USER_FROM_GROUPS_SUCCESS: `${MODULE_ID}/remove_user_from_groups_success`,
    REMOVE_USER_FROM_GROUPS_FAILED: `${MODULE_ID}/remove_user_from_groups_failed`,
    ADD_SERVICE_TO_GROUPS_REQUEST: `${MODULE_ID}/add_service_to_groups_request`,
    ADD_SERVICE_TO_GROUPS_SUCCESS: `${MODULE_ID}/add_service_to_groups_success`,
    ADD_SERVICE_TO_GROUPS_FAILED: `${MODULE_ID}/add_service_to_groups_failed`,
    REMOVE_SERVICE_FROM_GROUPS_REQUEST: `${MODULE_ID}/remove_service_from_groups_request`,
    REMOVE_SERVICE_FROM_GROUPS_SUCCESS: `${MODULE_ID}/remove_service_from_groups_success`,
    REMOVE_SERVICE_FROM_GROUPS_FAILED: `${MODULE_ID}/remove_service_from_groups_failed`,
  },
};

export const GroupManagementWizardReducer = combineReducers<GroupManagementWizardSchema>(
  createPortalModuleReducers<GroupManagementWizardSchema>(ACTION_TYPES, DEFAULT_STATE));

export default GroupManagementWizardReducer;
