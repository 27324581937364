import React from "react";
import { ApplicationOperations } from "@components";
import {
  UseApiRequestModel,
  UseApiRequestProps,
  useApiRequest,
  useApplicationManagerRegionalClient
} from "@hooks";

export interface UseApplicationActionManagementProps extends Partial<UseApiRequestProps<void>> {
  applicationId: string;
  operation: ApplicationOperations;
}

export interface UseApplicationActionManagementModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UseApplicationActionManagementActions {
  performOperation: () => void;
}

type Props = UseApplicationActionManagementProps;
type Model = UseApplicationActionManagementModel;
type Actions = UseApplicationActionManagementActions;
type Result = [Model, Actions];

export const useApplicationActionManagement = (props: Props): Result => {

  const {
    applicationId = "",
    operation = ApplicationOperations.NONE,
    ...otherProps
  } = props;

  const ApplicationRegionalClient = useApplicationManagerRegionalClient();

  const defaultErrorMessage = React.useMemo(() =>
    `Failed to ${operation} application`, [operation]);

  const mapApiToOperation = React.useCallback(() => {
    switch (operation) {
    case ApplicationOperations.DELETE:
      return ApplicationRegionalClient.deleteApplicationRegionalApi(applicationId);
    case ApplicationOperations.ENABLE:
      return ApplicationRegionalClient.enableApplicationRegionalApi(applicationId);
    case ApplicationOperations.DISABLE:
      return ApplicationRegionalClient.disableApplicationRegionalApi(applicationId);
    default:
      return Promise.resolve();
    }
  }, [operation, applicationId]);

  const [{ showSuccessView, ...baseModel }, { refresh: performOperation }] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => mapApiToOperation(),
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? `${operation} Successful` : "" ,
    [showSuccessView, operation]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    performOperation
  }), [performOperation]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useApplicationActionManagement;
