import React from "react";
import classnames from "classnames";
import { JsonEditor } from "@components";
import { jsonEditorView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface Model {
  json?: string;
}

export interface Actions {
  setJson?: (value: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const EditorView = withStyles(styles)((props: Props) => {

  const { classes, json, children, setJson } = props;

  return (
    <div className={classnames("editorView", classes.container)}>
      <label className={classnames("title", classes.title)}>
        Federation Provider Editor
      </label>
      <JsonEditor
        className={classnames("attributes", classes.jsonEditor)}
        name="federationProviderJsonEditor"
        json={json}
        setJson={setJson}
      />
      {children}
    </div>
  );
});

export default EditorView;
