import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const dataSetNameFilter = () =>
  createStyles({
    container: {
    },
  });

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    header: {
      flex: "0 0 auto",
      backgroundColor: Color.LIGHT_GREY4,
      borderBottom: `1px solid ${Color.GREY1}`,
      padding: "24px 12px",
    },
    title: {
      color: Color.TEXT,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "22px",
    },
    controls: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      padding: "6px 0 6px 0",
      borderBottom: `1px solid ${Color.GREY1}`,
    },
    sortIconButton: {
      width: "auto",
      height: "auto",
      marginLeft: 5,
    },
    sortIcon: {
      width: 10,
      height: 11,
    },
    nameFilter: {
      flex: "1 0 auto",
      marginLeft: 5,
      marginRight: 10,
      "& input": {
        fontSize: 12,
        lineHeight: "0",
        marginTop: 4,
      },
    },
    refreshIconButton: {
      color: Color.SIGNIFY_GREEN,
      marginLeft: "auto",
      marginRight: 8,
      minWidth: 18,
      minHeight: 18,
      width: 18,
      height: 18,
    },
    refreshIcon: {
      width: 12,
      height: 12,
    },
    dataSets: {
      flex: "1 0 auto",
    },
    dataSet: {
      color: Color.TEXT,
      fontSize: 12,
      fontWeight: 300,
      paddingTop: 15,
      paddingBottom: 15,
    },
    selected: {
      backgroundColor: Color.LIGHT_GREY2,
      fontWeight: 500,
    },
    icon: {
      width: 18,
      height: 18,
      marginRight: 8,
    },
    emptyLabel: {
      display: "block",
      textAlign: "center",
      fontStyle: "italic",
      color: Color.TEXT,
      fontSize: 12,
      fontWeight: 300,
      paddingTop: 15,
      paddingBottom: 15,
    },
    loadingLabel: {
      display: "block",
      textAlign: "center",
      fontStyle: "italic",
      color: Color.TEXT,
      fontSize: 12,
      fontWeight: 300,
      paddingTop: 15,
      paddingBottom: 15,
    },
    errorListItem: {
      padding: 0,
    },
    errorView: {
      flex: "1 0 auto",
      margin: 0,
      "& label.title": {
        fontSize: 13,
      },
      "& label.message": {
        fontSize: 11,
      },
      "& button.tryAgainButton": {
        fontSize: 13,
        height: "auto",
      },
    },
  });

export default styles;
