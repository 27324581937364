import { Theme } from "@material-ui/core/styles/createTheme";
import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const wizardStepper = () =>
  createStyles({
    container: {
      color: Color.SIGNIFY_GREEN,
      padding: 0,
    },
    steps: {
    },
    step: {
    },
    stepButton: {
    },
    stepLabel: {
    },
    title: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: "32px 0 0 0",
      padding: 0,
    },
  });

export const wizardControls = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      height: 36,
      minWidth: 100,
      padding: "6px 16px",
      marginLeft: 16,
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "16px",
      textTransform: "none",
    },
    cancelButton: {
      fontWeight: 500,
      marginLeft: 0,
    },
    previousStepButton: {
    },
    nextStepButton: {
    },
    saveButton: {
    },
  });

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    stepper: {
      flex: "0 0 auto",
    },
    error: {
      flex: "0 0 auto",
      marginTop: 16,
    },
    controls: {
      flex: "0 0 auto",
      marginTop: 25,
    },
    content: {
      flex: "1 0 auto",
      marginTop: 16,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    documentation: {
      position: "fixed",
      bottom: "calc((100% - 120px) / 2)",
      right: 0,
      rotate: "270deg",
      borderRadius: 0,
      marginRight: -42,
      padding: 6,
      height: 28,
      fontSize: 12,
      backgroundColor: Color.SIGNIFY_GREEN,
      "&:hover": {
        backgroundColor: Color.SIGNIFY_GREEN,
      },
      "& a": {
        color: Color.BLACK,
        fontWeight: 300,
        textDecoration: "none",
      },
    }
  });

export default styles;
