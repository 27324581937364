import React from "react";
import { noop } from "@util";
import { useHistory } from "react-router-dom";
import BackButton, { BackButtonActions, BackButtonModel } from "@components/back-button/BackButton";

export const BrowserHistoryBackButton = (props: BackButtonModel & BackButtonActions) => {

  const {
    className = "browserHistoryBackButton",
    goBack = noop,
    ...otherProps
  } = props;

  const history = useHistory();

  const onClickGoBack = React.useCallback(() => {
    history.goBack();
    goBack();
  }, [history, goBack]);

  return (
    <BackButton
      {...otherProps}
      className={className}
      goBack={onClickGoBack}
    />
  );
};

export default BrowserHistoryBackButton;
