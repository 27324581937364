import React from "react";
import { isEmptyString, noop } from "@util";
import { User } from "@data";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import {
  getErrorMessage,
  getSuccessMessage,
  getUser,
  isLoadingIndicatorVisible,
  isSuccessMessageVisible,
  isPortalUser,
  isFederatedUser,
  getSelectedFederationProvider,
} from "../selectors";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel
} from "@components/submit-api-request-view";
import { createUser } from "../actions";
import EditProfileAttributes from "./EditProfileAttributes";
import UserInfo from "./UserInfo";

interface ContainerModel extends SubmitApiRequestViewModel<User> {
  user?: User;
  successMessage?: string;
  isPortalUser?: boolean;
  isFederatedUser?: boolean;
  selectedFederationProvider?: string;
}

interface ContainerActions extends SubmitApiRequestViewActions<User> {
  showUserDetails?: (user: User) => void;
}

type Props = ContainerModel & ContainerActions;

const CreateUserContainer = (props: Props) => {

  const {
    currentState = User.EMPTY,
    defaultState = User.EMPTY,
    showUserDetails: showUserDetailsPage = noop,
    isPortalUser: portalUser,
    isFederatedUser: federatedUser,
    selectedFederationProvider = "",
    ...otherProps
  } = props;

  const showEditProfileAttributes = React.useMemo(() => {

    if (portalUser) {
      return true;
    }

    if (federatedUser) {
      return !isEmptyString(selectedFederationProvider);
    }

    return true;

  }, [portalUser, federatedUser, selectedFederationProvider]);

  return (
    <SubmitApiRequestView
      {...otherProps}
      snackbarId="create-user"
      errorTitle="Create User Failed"
      saveButtonLabel="Create User"
      currentState={currentState}
      defaultState={defaultState}
      saveButtonDisabled={!showEditProfileAttributes}
      onSuccess={() => showUserDetailsPage(currentState)}
    >
      <UserInfo />
      {showEditProfileAttributes && <EditProfileAttributes />}
    </SubmitApiRequestView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  currentState: getUser(state),
  successMessage: getSuccessMessage(state),
  errorMessage: getErrorMessage(state),
  showLoadingIndicator: isLoadingIndicatorVisible(state),
  showSuccessIndicator: isSuccessMessageVisible(state),
  isPortalUser: isPortalUser(state),
  isFederatedUser: isFederatedUser(state),
  selectedFederationProvider: getSelectedFederationProvider(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  save: () => dispatch(createUser()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(CreateUserContainer);
