import React from "react";
import { noop } from "@util";
import { styles } from "./styles";
import classNames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  ActionsListColumn,
  ActionsListItemData,
  createColumns,
  ListViewItem,
  ModuleListView,
  ModuleListViewActions,
  ModuleListViewModel,
} from "@components";

type Model = ModuleListViewModel<ActionsListItemData, ActionsListColumn>;
type Actions = ModuleListViewActions<ActionsListItemData, ActionsListColumn>;
type Props = WithStyles<typeof styles> & Model & Actions;

export const WorkloadActionsList = withStyles(styles)((props: Props) => {

  const {
    classes,
    items = [],
    selectedItems = [],
    noResultsLabel = "No actions found",
    summaryViewLabel = "Workloads",
    setSelectedItems = noop,
    ...otherProps
  } = props;

  const moduleListItems: ListViewItem<ActionsListItemData>[] = React.useMemo(() => {
    return items.map((item) => ({
      item: item,
      icon: null,
      pathToDetailsView: "",
      columnAttributes: createColumns([
        {
          className: "workloadAction",
          value: item.getName(),
          column: ActionsListColumn.NAME,
          firstColumn: true,
        },
      ]),
    }));
  }, [items]);

  return (
    <ModuleListView
      {...otherProps}
      className={classNames("conditionsList", classes.container)}
      noResultsLabel={noResultsLabel}
      columns={[ActionsListColumn.NAME]}
      listViewItems={moduleListItems}
      showSummary={false}
      showSearch={false}
      sortingDisabled={true}
      selectable={true}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
    />
  );
});

export default WorkloadActionsList;
