import { createStyles } from "@material-ui/core/styles";
import ModuleIcon from "@material-ui/icons/DeveloperMode";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const schemasView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 30,
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      minWidth: 100,
      alignSelf: "flex-start",
      fontSize: 24,
      lineHeight: "28px",
    },
    radioLabel: {
      color: Color.TEXT,
    }
  });

export const jsonView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    jsonViewer: {
      flex: "1 0 auto",
      marginTop: 8,
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
      marginTop: 21,
      marginBottom: 4,
    },
    helperText: {
      color: Color.TEXT,
      fontSize: 13,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 4,
    },
    toggleApiSwitch: {
    },
    extraTopMargin: {
      marginTop: 25,
    },
  });
