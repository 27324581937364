import React from "react";
import { Location } from "history";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getPathToDeviceType } from "@modules/deviceTypeDetails/helpers";
import DeviceTypeWizard from "./containers/DeviceTypeWizard";

type LocationProps = {
  from?: Location;
  goBackOnSuccess?: boolean;
};

export function EditDeviceTypeView() {

  const history = useHistory();

  const { id: nameAndVersion } = useParams<{ id: string }>();

  const { state: { from = undefined, goBackOnSuccess = false } = {} } = useLocation<LocationProps>();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  // If specified, go back, otherwise either replace this history entry
  // with the provided entry point or goto the details page
  const showDeviceTypeDetails = React.useCallback(() => {
    if (goBackOnSuccess) {
      history.goBack();
    } else if (from) {
      history.replace(from);
    } else {
      history.replace(getPathToDeviceType(nameAndVersion));
    }
  }, [history, from, goBackOnSuccess, nameAndVersion]);

  return (
    <DeviceTypeWizard
      className="editDeviceTypeWizard"
      snackbarId="edit-device-type"
      cancel={cancel}
      showDeviceTypeDetails={showDeviceTypeDetails}
    />
  );
}

export default EditDeviceTypeView;
