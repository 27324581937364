import React from "react";
import classnames from "classnames";
import { ActionMenuItem, ActionsMenu, ActionsMenuProps } from "@components/actions-menu";
import { noop } from "@util";

export enum FederationProviderAction {
  EDIT_FEDERATION_PROVIDER = "edit_federation_provider",
  DELETE_FEDERATION_PROVIDER = "delete_federation_provider",
}

export const EDIT_FEDERATION_PROVIDER_ACTION: ActionMenuItem = Object.freeze({
  id: FederationProviderAction.EDIT_FEDERATION_PROVIDER,
  name: "Edit Federation Provider",
  disabled: false,
});

export const DELETE_FEDERATION_PROVIDER_ACTION: ActionMenuItem = Object.freeze({
  id: FederationProviderAction.DELETE_FEDERATION_PROVIDER,
  name: "Delete Federation Provider",
  disabled: false,
});

export const DEFAULT_FEDERATION_PROVIDER_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  EDIT_FEDERATION_PROVIDER_ACTION,
  DELETE_FEDERATION_PROVIDER_ACTION,
]) as ActionMenuItem[];

export interface FederationProviderActionsMenuProps extends ActionsMenuProps {
  editFederationProvider?: () => void;
  deleteFederationProvider?: () => void;
}

export const withFederationProviderActions = (Component: React.ComponentType<FederationProviderActionsMenuProps>) =>
  (props: FederationProviderActionsMenuProps) => {

    const {
      actions = DEFAULT_FEDERATION_PROVIDER_ACTION_MENU_ITEMS,
      editFederationProvider = noop,
      deleteFederationProvider = noop,
      onClickAction = noop,
    } = props;

    return (
      <Component
        {...props}
        actions={actions}
        onClickAction={(action: ActionMenuItem) => {
         if (action.id === FederationProviderAction.DELETE_FEDERATION_PROVIDER) {
           deleteFederationProvider();
          } else if (action.id === FederationProviderAction.EDIT_FEDERATION_PROVIDER) {
            editFederationProvider();
          } else {
            onClickAction(action);
          }
        }}
      />
    );
  };

export const FederationProviderActionsMenu =
  withFederationProviderActions((props: FederationProviderActionsMenuProps) => {

  const { className, buttonLabel = "Federation Provider Actions" } = props;

  return (
    <ActionsMenu
      {...props}
      className={classnames("federationProviderActionsMenu", className)}
      buttonLabel={buttonLabel}
    />
  );
});

export default FederationProviderActionsMenu;
