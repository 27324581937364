import { isEmptyString, isValidInteger } from "@util";
import { DataLakeIndexAttributes, DataLakeIndexQueryAttributes } from "@data";
import {
  createQueryParams,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments,
} from "@network/helpers";

const DEFAULT_LIMIT = 50;

const DATA_LAKE_INDEX_SERVICE_API = process.env.REACT_APP_DATA_LAKE_INDEX_SERVICE_API;

if (isEmptyString(DATA_LAKE_INDEX_SERVICE_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_DATA_LAKE_INDEX_SERVICE_API");
}

export interface GetDataLakeIndexResponse {
  query: DataLakeIndexQueryAttributes;
  results: DataLakeIndexAttributes[];
  paging?: {
    next?: string;
  };
}

export const getDataLakeQueryResults = (authToken: string,
                                        dataSetAlias: string = "",
                                        startDate: string = "",
                                        endDate: string = "",
                                        accountId: string = "",
                                        fileNameContains: string = "",
                                        next: string = "",
                                        limit: number = DEFAULT_LIMIT): Promise<GetDataLakeIndexResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Data Set Name", dataSetAlias],
      ["Start Date", startDate],
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({
      ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, Math.min(DEFAULT_LIMIT, limit)) }),
      ...(!isEmptyString(endDate) ? ({ endDate }) : ({})),
      ...(!isEmptyString(accountId) ? ({ accountId }) : ({})),
      ...(!isEmptyString(fileNameContains) ? ({ fileNameContains }) : ({})),
      startDate,
      next,
    });

    const url = `${DATA_LAKE_INDEX_SERVICE_API}/primary-storage/index/v2/dataset/${dataSetAlias}${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Data Lake Index Query Failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
