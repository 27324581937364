import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { SummaryViewLabel } from "@components/summary-view-label";
import { summaryView as styles } from "./styles";
import { getApprovalRequestTypeDisplayName, getRequestStateDisplayName } from "@modules/dataApprovalRequests/helpers";
import { ApprovalRequestType, RequestState } from "@data";

export interface Model {
  accountId?: string;
  showAccountId?: boolean;
  dataSetAlias?: string;
  requestType?: ApprovalRequestType;
  requestState?: RequestState;
  approvers?: string;
  deviceTypeIdentifier?: string;
  requestedAt?: string;
  requestedBy?: string;
  children?: React.ReactNode;
}

type Props = WithStyles<typeof styles> & Model;

export const SummaryView = withStyles(styles)((props: Props) => {

  const {
    classes,
    accountId,
    showAccountId,
    dataSetAlias,
    requestType = ApprovalRequestType.IOT_APPROVAL,
    requestState,
    approvers,
    deviceTypeIdentifier,
    requestedAt,
    requestedBy,
    children,
  } = props;

  return (
    <div className={classnames("summaryView", classes.container)}>
      {showAccountId && (
        <SummaryViewLabel className="accountId" name="Account Id" value={accountId}/>
      )}
      <SummaryViewLabel className="dataSetAlias" name="Data Set" value={dataSetAlias}/>
      <SummaryViewLabel
        className="requestType"
        name="Request Type"
        value={getApprovalRequestTypeDisplayName(requestType)}
      />
      <SummaryViewLabel className="requestSate" name="Request State" value={getRequestStateDisplayName(requestState)}/>
      <SummaryViewLabel className="deviceTypeIdentifier" name="Device Type Identifier" value={deviceTypeIdentifier}/>
      <SummaryViewLabel className="approvers" name="Approvers" value={approvers}/>
      <SummaryViewLabel className="requestedBy" name="Requested By" value={requestedBy}/>
      <SummaryViewLabel className="requestedAt" name="Requested At" value={requestedAt}/>
      <div className={classnames("content", classes.content)}>
        {children}
      </div>
    </div>
  );
});

export default SummaryView;
