import { isEmptyString } from "@util";
import { AppSchema } from "@main/schemas";
import { createActions } from "@modules/base";
import { refresh as refreshDataCatalogModule } from "@modules/dataCatalog/actions";
import { getAccessToken, getAccessRequestId } from "./selectors";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { DataAccessRequestClient, RestClientError } from "@network";

export const {
  accessRequestId: setAccessRequestId,
  dataSetAlias: setDataSetAlias,
  open: setOpen,
  setErrorMessage,
  setSuccessMessage,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  DELETE_DATA_ACCESS_REQUEST_REQUEST: deleteDataAccessRequestRequest,
  DELETE_DATA_ACCESS_REQUEST_SUCCESS: deleteDataAccessRequestSuccess,
  DELETE_DATA_ACCESS_REQUEST_FAILED: deleteDataAccessRequestFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setAccessRequestId());
  dispatch(setDataSetAlias());
  dispatch(setOpen());
  return dispatch(baseReset());
};

export const deleteDataAccessRequest = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accessRequestId = getAccessRequestId(state);
  const accessToken = getAccessToken(state);

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(deleteDataAccessRequestRequest());

  return DataAccessRequestClient.deleteDataAccessRequest(accessToken, accessRequestId)
    .then(() => {

      dispatch(deleteDataAccessRequestSuccess());
      dispatch(refreshDataCatalogModule());
      dispatch(hideLoadingIndicator());
      return dispatch(setSuccessMessage("Data Access Request Cancelled"));

    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to cancel data access request" } = response;

      dispatch(deleteDataAccessRequestFailed(analytic));
      dispatch(hideLoadingIndicator());

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(setErrorMessage(error));
    });
};

export const open = (accessRequestId: string, dataSetAlias: string) => (dispatch: any) => {

  if (isEmptyString(accessRequestId) || isEmptyString(dataSetAlias)) {
    return Promise.resolve();
  }

  dispatch(reset());
  dispatch(setAccessRequestId(accessRequestId));
  dispatch(setDataSetAlias(dataSetAlias));
  return dispatch(setOpen(true));
};

export const close = reset;

export const initialize = reset;
