import { isEmptyString } from "@util";
import { FederationProviderAttributes } from "@data";
import {
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments
} from "@network/helpers";

const FEDERATION_PROVIDER_API = process.env.REACT_APP_FEDERATION_PROVIDER_API || "";

if (isEmptyString(FEDERATION_PROVIDER_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_FEDERATION_PROVIDER_API");
}

export interface GetFederationProvidersResponse {
  providers: FederationProviderAttributes[];
}

export const getFederationProviders = (authToken: string): Promise<GetFederationProvidersResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${FEDERATION_PROVIDER_API}/v3/aam/admin/users/federation/providers`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Federation Providers failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getFederationProvider = (authToken: string,
                                      providerId: string): Promise<FederationProviderAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Provider Id", providerId]
    ]));

  const makeRequest = () => {

    const url = `${FEDERATION_PROVIDER_API}/v3/aam/admin/users/federation/providers/${providerId}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch Federation Provider failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteFederationProvider = (authToken: string,
                                         providerId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Provider Id", providerId],
    ]));

  const makeRequest = () => {

    const url = `${FEDERATION_PROVIDER_API}/v3/aam/admin/users/federation/providers/${providerId}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to delete federation provider [${providerId}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const createFederationProvider = (authToken: string,
                                         json: string): Promise<FederationProviderAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["FederationProvider", json],
    ]));

  const makeRequest = () => {

    const url = `${FEDERATION_PROVIDER_API}/v3/aam/admin/users/federation/providers`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to create federation provider";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const editFederationProvider = (authToken: string,
                                       providerId: string,
                                       json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["FederationProvider", json,
        "Provider Id", providerId],
    ]));

  const makeRequest = () => {

    const url = `${FEDERATION_PROVIDER_API}/v3/aam/admin/users/federation/providers/${providerId}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to edit federation provider";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
