import { createStyles } from "@material-ui/core/styles";

export const styles = () =>
  createStyles({
    anchor: {
      display: "none",
    },
  });

export default styles;
