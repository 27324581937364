import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";
import { ApplicationType } from "./ApplicationType";
import { RedirectUriListItemData } from "@data/RedirectUriListItemData";

export enum ApplicationState {
  NONE = "",
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export enum ApplicationAuthentication {
  NONE = "NONE",
  AUTHENTICATED = "AUTHENTICATED",
}

export enum ApplicationSecretState {
  NONE = "NONE",
  AVAILABLE = "AVAILABLE",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum ApplicationAuthenticationProtocol {
  SHARED_SECRET_1 = "shared-secret:1"
}

export interface ApplicationMetadataAttributes {
  [key: string]: string;
}

export interface ApplicationSecretsAvailabilityAttributes {
  primary: ApplicationSecretState;
  secondary: ApplicationSecretState;
}

export interface ApplicationSecretsAttributes {
  availability?: ApplicationSecretsAvailabilityAttributes;
  primary?: string;
}

export interface ApplicationSecurityDataAttributes {
  secrets: ApplicationSecretsAttributes;
}

export interface ApplicationSecurityAttributes {
  data?: ApplicationSecurityDataAttributes;
  protocol: ApplicationAuthenticationProtocol;
  authentication: ApplicationAuthentication;
}

export interface ApplicationAttributes {
  name: string;
  description?: string;
  redirectUris?: string[];
  id?: string;
  type?: ApplicationType;
  scopes?: string[];
  state?: ApplicationState;
  created?: string;
  metadata?: ApplicationMetadataAttributes;
  security?: ApplicationSecurityAttributes;
}

export class Application extends Record({
  name: "",
  description: "",
  redirectUris: [],
  id: "",
  type: ApplicationType.NONE,
  scopes: [],
  state: ApplicationState.NONE,
  created: "",
  metadata: {},
  security: {
    data: {
      secrets: {
        availability: {
          secondary: ApplicationSecretState.NONE,
          primary: ApplicationSecretState.NONE,
        },
        primary: "",
      }
    },
    protocol: ApplicationAuthenticationProtocol.SHARED_SECRET_1,
    authentication: ApplicationAuthentication.NONE,
  }
}) implements ApplicationAttributes {

  public static EMPTY: Application = new Application();

  public readonly name: string;
  public readonly description: string;
  public readonly redirectUris: string[];
  public readonly id: string;
  public readonly type: ApplicationType;
  public readonly scopes: string[];
  public readonly state: ApplicationState;
  public readonly created: string;
  public readonly metadata: ApplicationMetadataAttributes;
  public readonly security: ApplicationSecurityAttributes;

  public getName(): string {
    return getStringValue(this.name);
  }

  public getId(): string {
    return getStringValue(this.id);
  }

  public hasApplicationId(): boolean {
    return !isEmptyString(this.getId());
  }

  public getDescription(): string {
    return this.description || "";
  }

  public getRedirectUriAsStringArray(): string[] {
    if (Array.isArray(this.redirectUris)) {
      return this.redirectUris
        .map((redirectUri: string) => getStringValue(redirectUri))
        .filter((redirectUri: string) => !isEmptyString(redirectUri));
    } else {
      return [];
    }
  }

  public getRedirectUris(): RedirectUriListItemData[] {
    return this.getRedirectUriAsStringArray()
      .map(redirectUri => new RedirectUriListItemData({
      uri: redirectUri
    }));
  }

  public getScopes(): string[] {
    if (Array.isArray(this.scopes)) {
      return this.scopes
        .map((scope: string) => getStringValue(scope))
        .filter((scope: string) => !isEmptyString(scope));
    } else {
      return [];
    }

  }

  public getApplicationType(): ApplicationType {
    return this.type;
  }

  public getApplicationState(): ApplicationState {
    return this.state;
  }

  public isEnabled(): boolean {
    return this.state === ApplicationState.ENABLED;
  }

  public getCreated(): string {
    return getStringValue(this.created);
  }

  public getPrimarySecret(): string {
    const { data: { secrets : { primary = "" } = {} } = {}} = this.security || {};

    return getStringValue(primary);
  }

  public getApplicationAuthentication(): ApplicationAuthentication {
    const { authentication = ApplicationAuthentication.NONE } = this.security || {};
    return authentication;
  }

  public getApplicationProtocol(): ApplicationAuthenticationProtocol {
    const { protocol = ApplicationAuthenticationProtocol.SHARED_SECRET_1 } = this.security || {};
    return protocol;
  }

  public getApplicationMetadata(): ApplicationMetadataAttributes {
    const metadata = this.metadata || {};

    return { ...metadata };
  }

  public isApplicationAuthenticated(): boolean {
    return this.getApplicationAuthentication() === ApplicationAuthentication.AUTHENTICATED;
  }

  public getPrimarySecretAvailability(): ApplicationSecretState {
    const { data: { secrets : { availability : { primary = ApplicationSecretState.NONE} = {} } = {} } = {}}
      = this.security || {};

    return primary;
  }

  public getSecondarySecretAvailability(): ApplicationSecretState {
    const { data: { secrets : { availability : { secondary = ApplicationSecretState.NONE} = {} } = {} } = {}}
      = this.security || {};

    return secondary;
  }

  public isSecondarySecretAvailable(): boolean {
    const { data: { secrets : { availability : { secondary = ApplicationSecretState.NONE} = {} } = {} } = {}}
    = this.security || {};

    return secondary === ApplicationSecretState.AVAILABLE;
  }
}
