import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { useEditVariable } from "@hooks";
import { isEmptyString, noop } from "@util";
import { WorkloadVariableSchema } from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import TextFieldStyled from "@components/text-field-styled";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export interface WorkloadEditVariableDialogModel extends PortalModuleDialogModel<string> {
  name?: string;
  version?: number;
  variable?: WorkloadVariableSchema;
  etag?: string;
}

export interface WorkloadEditVariableDialogActions extends PortalModuleDialogActions<string> {
}

type Props = WithStyles<typeof styles> & WorkloadEditVariableDialogModel & WorkloadEditVariableDialogActions;

export const WorkloadEditVariableDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    name = "",
    etag = "",
    version = 1,
    variable = WorkloadVariableSchema.EMPTY,
    cancel: cancelDialog = noop,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  const [required, setRequired] = React.useState(variable.isRequired());
  const [description, setDescription] = React.useState(variable.getDescription());

  const [{ showLoadingIndicator, ...otherModel }, { save, reset }] =
    useEditVariable({ name, version, variableName: variable.getName(), etag,
      variable: { ...variable.toJS(), required, description }});

  const cancel = React.useCallback(() => {
    reset();
    cancelDialog();
    setRequired(variable.isRequired());
    setDescription(variable.getDescription());
  }, [reset, cancelDialog, setRequired, setDescription, variable]);

  const onSuccessMessageShown = React.useCallback(() => {
    reset();
    onSuccess();
  }, [reset, onSuccess]);

  React.useEffect(() => {
    setRequired(variable.isRequired());
    setDescription(variable.getDescription());
  }, [variable]);

  if (isEmptyString(name)) {
    return null;
  }

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      className={classnames("workloadEditVariableDialog")}
      title="Edit Workload Completion Variable"
      loading={showLoadingIndicator}
      continueButtonLabel="Save"
      continueButtonDisabled={variable.isRequired() === required && variable.getDescription() === description}
      onSuccessMessageShown={onSuccessMessageShown}
      confirm={save}
      cancel={cancel}
    >
      <div className={classnames("workloadVariableContainer", classes.container)}>
        <label className={classnames("title", classes.title)}>
          Enter the new variable schema:
        </label>
        <div className={classnames("row", classes.row)}>
          <TextFieldStyled
            className={classnames("nameTextField", classes.textField, classes.nameTextField)}
            label="Variable Name"
            placeholder="Set Variable Name"
            autoFocus={true}
            shrinkInputLabel={true}
            value={variable.getName()}
            disabled={true}
          />
          <FormControlLabel
            className={classnames("requiredContainer", classes.requiredContainer)}
            label="Required?"
            control={(
              <Checkbox
                className={classnames("requiredCheckbox", classes.requiredCheckbox)}
                checked={required}
                onChange={() => setRequired(!required)}
              />
            )}
          />
      </div>
      <TextFieldStyled
        className={classnames("descriptionTextField", classes.textField, classes.descriptionTextField)}
        label="Variable Description (Optional)"
        placeholder="Set Variable Description"
        shrinkInputLabel={true}
        value={description}
        setValue={setDescription}
      />
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadEditVariableDialog;
