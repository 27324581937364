import { createStyles, Theme } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
    },
    dialogContent: {
      backgroundColor: Color.WHITE,
      padding: "0px !important",
    },
    title: {
      backgroundColor: Color.GREY10,
      color: Color.TEXT,
      fontSize: 28,
      fontWeight: 400,
      padding: 16,
      boxShadow: "0 2px 4px 0 rgb(0 0 0 / 50%)",
    },
    infoView: {
      fontSize: 16,
      lineHeight: 2,
      margin: 15,
      backgroundColor: Color.TRANSPARENT,
      "& .MuiAlert-icon": {
        marginTop: 6,
        color: Color.SIGNIFY_GREEN,
      },
      "& .MuiAlert-message": {
        color: Color.TEXT,
      },
    },
    controls: {
      padding: 15,
      backgroundColor: Color.LIGHT_GREY5,
      borderTop: `1px solid ${Color.GREY1}`,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    continueButton: {
      "&.disabled": {
        color: "rgba(0, 0, 0, 0.26)",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
      }
    },
    backdrop: {
      background: "rgba(105, 105, 105, 0.25)",
      zIndex: theme.zIndex.modal,
    },
  });

export default styles;
