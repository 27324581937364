import React from "react";
import { useHistory } from "react-router-dom";
import { getPathToPolicy } from "@modules/policyDetails/helpers";
import CreatePolicy from "./containers/CreatePolicy";

export function CreatePolicyView() {

  const history = useHistory();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onSuccess = React.useCallback(policy => {
    history.replace(getPathToPolicy(policy.getName()));
  }, [history]);

  return (
    <CreatePolicy
      cancel={cancel}
      onSuccess={onSuccess}
    />
  );
}

export default CreatePolicyView;
