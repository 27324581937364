import React from "react";
import QualityReportDetails, {
  QualityReportDetailsActions,
  QualityReportDetailsModel,
} from "../components/QualityReportDetails";
import QualityReport from "@data/QualityReport";
import WaitForApiRequestView from "@components/wait-for-api-request-view";
import useListQualityReports from "@hooks/portalQualityReports/use-list-quality-reports";

interface ContainerModel extends QualityReportDetailsModel {
}

interface ContainerActions extends QualityReportDetailsActions {
}

type Model = ContainerModel;
type Actions = ContainerActions;
type Props = Model & Actions;

const QualityReportDetailsContainer = (props: Props) => {

  const {
    report = QualityReport.EMPTY,
    ...otherProps
  } = props;

  const [{ reports: previousReports, totalNumItems, showSuccessView, ...model }, { refresh, ...actions }] =
    useListQualityReports({
      platformDomainId: report.getPlatformDomainId(),
      groupId: report.getGroupId(),
      createdBefore: report.getCreatedAt(),
    });

  const previousVersions = React.useMemo(() =>
      previousReports.filter(previousReport => previousReport.getVersion() !== report.getVersion()),
    [previousReports, report]);

  const title = React.useMemo(() =>
    `${report.getPlatformDomainName()} - ${report.getGroupName()} - ${report.getVersion()}`,
    [report]);

  return (
    <WaitForApiRequestView
      loadingMessage="Loading Quality Report Historical Trends..."
      errorTitle="Failed to load quality report history"
      showSuccessView={showSuccessView}
      refresh={refresh}
      retry={refresh}
      {...model}
      {...actions}
    >
      {showSuccessView && (
        <QualityReportDetails
          {...otherProps}
          title={title}
          report={report}
          previousVersions={previousVersions}
          refresh={refresh}
        />
      )}
    </WaitForApiRequestView>
  );
};

export default QualityReportDetailsContainer;
