import { createSelector } from "reselect";
import { SCHEMA_KEY } from "./constants";
import { SchemaAction } from "./reducers";
import { JsonSchemaMetadata, JsonSchemaMetadataAttributes } from "../../data";
import { AppSelector, createModuleSelectorFactory, isEmptyString } from "../../util";

const createModuleSelector = createModuleSelectorFactory(SCHEMA_KEY);
export const isDialogOpen = createModuleSelector<boolean>(state => state.showDialog);
export const getSchemaAction = createModuleSelector<SchemaAction>(state => state.schemaAction);
export const getErrorMessage = createModuleSelector<string>(state => state.errorMessage);
export const getSuccessMessage = createModuleSelector<string>(state => state.successMessage);
export const isAccessDeniedVisible = createModuleSelector<boolean>(state => state.showAccessDenied);
export const isProgressIndicatorVisible = createModuleSelector<boolean>(state => state.showProgressIndicator);

export const getSchema = createSelector(
  createModuleSelector<JsonSchemaMetadataAttributes>(state => state.schema),
  (attrs => new JsonSchemaMetadata(attrs)));

export const getDialogClassName: AppSelector<string> = createSelector(
  getSchemaAction, (schemaAction: SchemaAction) => {

    switch (schemaAction) {
      case SchemaAction.PROMOTE:
        return "promoteSchemaDialog";
      case SchemaAction.DRAFT:
        return "draftSchemaDialog";
      case SchemaAction.DEPRECATE:
        return "deprecateSchemaDialog";
      case SchemaAction.DECOMMISSION:
        return "decommissionSchemaDialog";
      case SchemaAction.DELETE:
        return "deleteSchemaDialog";
      default:
        return "invalidSchemaAction";
    }
  });

export const isSuccessMessageVisible: AppSelector<boolean> = createSelector(
  getSuccessMessage, (successMessage: string) => !isEmptyString(successMessage));

export const getDialogTitle: AppSelector<string> = createSelector(
  [getSchemaAction, isSuccessMessageVisible],
  (schemaAction: SchemaAction, showSuccessMessage: boolean) => {

    switch (schemaAction) {
      case SchemaAction.PROMOTE:
        return showSuccessMessage ? "Schema Promoted" : "Promote Schema";
      case SchemaAction.DRAFT:
        return showSuccessMessage ? "New Schema Version Drafted" : "Draft New Schema Version";
      case SchemaAction.DEPRECATE:
        return showSuccessMessage ? "Schema Deprecated" : "Deprecate Schema";
      case SchemaAction.DECOMMISSION:
        return showSuccessMessage ? "Schema Decommissioned" : "Decommission Schema";
      case SchemaAction.DELETE:
        return showSuccessMessage ? "Schema Deleted" : "Delete Schema";
      default:
        return "Invalid Schema Action";
    }
  });

export const getDialogContinueButtonLabel: AppSelector<string> = createSelector(
  getSchemaAction, (schemaAction: SchemaAction) => {

    switch (schemaAction) {
      case SchemaAction.PROMOTE:
        return "Promote Schema";
      case SchemaAction.DRAFT:
        return "Draft New Schema Version";
      case SchemaAction.DEPRECATE:
        return "Deprecate Schema";
      case SchemaAction.DECOMMISSION:
        return "Decommission Schema";
      case SchemaAction.DELETE:
        return "Delete Schema";
      default:
        return "Go Back";
    }
  });
