import React from "react";
import { Workload, WorkloadAttributes } from "@data";
import { useWorkloadManagerClient } from "@hooks";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  UseApiRequestActions,
} from "@hooks";

type SuccessResponse = WorkloadAttributes;

export interface UseGetWorkloadProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version?: number | "latest";
}

export interface UseGetWorkloadModel extends UseApiRequestModel<SuccessResponse> {
  workload: Workload;
}

export interface UseGetWorkloadActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetWorkloadProps;
type Model = UseGetWorkloadModel;
type Actions = UseGetWorkloadActions;
type Result = [Model, Actions];

export const useGetWorkload = (props: Props): Result => {

  const {
    name,
    version = "latest",
    defaultErrorMessage = "Failed to get workload",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() => WorkloadManagerClient.getWorkloadApi(name, version),
    [WorkloadManagerClient, name, version]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const workload = React.useMemo<Workload>(() => {
    const attrs = successResponse ? successResponse : {};
    return new Workload(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    workload,
  }), [
    baseModel,
    workload,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetWorkload;
