import React from "react";
import classnames from "classnames";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@components/circular-progress";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { CircularProgressProps } from "@material-ui/core/CircularProgress/CircularProgress";
import styles from "./styles";
import { clickHandler } from "@util";

export interface BackdropLoadingViewModel {
  className?: string;
  open?: boolean;
  showProgressIndicator?: boolean;
  progressIndicatorClassName?: string;
  progressIndicatorColor?: "primary" | "secondary" | "inherit";
  progressIndicatorProps?: Partial<CircularProgressProps>;
}

export interface BackdropLoadingViewActions {
  onClick?: () => void;
}

type Model = BackdropLoadingViewModel;
type Actions = BackdropLoadingViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const BackdropLoadingView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    open = false,
    showProgressIndicator = true,
    progressIndicatorClassName,
    progressIndicatorColor = "inherit",
    progressIndicatorProps = {},
    onClick,
    children,
  } = props;

  return (
    <Backdrop
      className={classnames("backdrop", className, classes.backdrop, {
        [classes.showProgressIndicator]: showProgressIndicator,
      })}
      onClick={clickHandler(onClick)}
      open={open}
    >
      {showProgressIndicator && (
        <CircularProgress
          {...progressIndicatorProps}
          className={classnames("progressIndicator",
            progressIndicatorClassName,
            classes.showProgressIndicator,
            classes.progressIndicator,
          )}
          color={progressIndicatorColor}
        />
      )}
      {children}
    </Backdrop>
  );
});

export default BackdropLoadingView;
