import React from "react";
import styles from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { getPluralString, noop } from "@util";
import classnames from "classnames";
import CloseIcon from "@material-ui/icons/Close";

export interface AutoRefreshProps {
  className?: string;
  autoRefreshEnabled?: boolean;
  refreshInterval?: number;
  retries?: number;
  showLoadingIndicator?: boolean;
  refresh?: () => void;
}

type Props = WithStyles<typeof styles> & AutoRefreshProps;

export const AutoRefresh = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    autoRefreshEnabled = false,
    refreshInterval = 10,
    retries: initialRetries = 10,
    showLoadingIndicator = false,
    refresh = noop,
  } = props;

  const [autoRefreshCountdown, setAutoRefreshCountdown] = React.useState(refreshInterval);
  const [retries, setRetries] = React.useState(initialRetries);

  const autoRefreshActive = React.useMemo(() =>
    autoRefreshEnabled && retries > 0 , [autoRefreshEnabled, retries]);

  const refreshMessage = React.useMemo(() => {
    if (!autoRefreshActive) {
      return "";
    }
    return "Refreshing in " + getPluralString(autoRefreshCountdown, {
      one: "second",
      other: "seconds",
    });
  }, [autoRefreshActive, autoRefreshCountdown]);

  const stopAutoRefresh = React.useCallback(() => {
    setRetries(0);
  }, [setRetries]);

  React.useEffect(() => {

    if (!autoRefreshActive) {
      return noop;
    }

    if (showLoadingIndicator) {
      setAutoRefreshCountdown(refreshInterval);
      return noop;
    }

    if (autoRefreshCountdown > 0) {
      const timer = setTimeout(() => setAutoRefreshCountdown(autoRefreshCountdown - 1), 1000);
      return () => clearTimeout(timer);
    }

    refresh();
    setRetries(retries - 1);
    return noop;

  }, [
    autoRefreshActive,
    showLoadingIndicator,
    autoRefreshCountdown,
    refresh,
  ]);

  if (!autoRefreshActive) {
    return null;
  }

  return (
    <div className={classnames("autoRefresh", className, classes.container)}>
      <p className={classnames("refreshMessage", classes.refreshMessage)}>{refreshMessage}</p>
      <CloseIcon className={classes.closeIcon} onClick={stopAutoRefresh}/>
    </div>
  );
});

export default AutoRefresh;
