import { createSelector } from "reselect";
import { createSelectors, Selector } from "@base/createSelectors";
import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { AuditEventsSchema, DEFAULT_STATE } from "./reducers";
import { AvailableValues } from "@hooks";

export const {
  availableAccountIds,
  availableOrigins: getAvailableOrigins,
  availableTypes: getAvailableTypes,
  availableSeverities: getAvailableSeverities,
  availablePrincipals: getAvailablePrincipals,
  availableRequestIds: getAvailableRequestIds,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  getCurrentAccountId,
  getAccessToken,
} = createSelectors<AuditEventsSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getAvailableAccountIds: Selector<string[]> = createSelector(
  [availableAccountIds, getCurrentAccountId], (accountIds: string[], currentAccountId: string) =>
    Array.from(new Set(accountIds.concat([currentAccountId]))));

export const getAvailableAutocompleteValues: Selector<AvailableValues> = createSelector(
  [
    getAvailableTypes,
    getAvailableSeverities,
    getAvailableAccountIds,
    getAvailablePrincipals,
    getAvailableOrigins,
    getAvailableRequestIds,
  ],
  (availableTypes: string[],
   availableSeverities: string[],
   accountIds: string[],
   availablePrincipals: string[],
   availableOrigins: string[],
   availableRequestIds: string[]) => ({
    availableTypes,
    availableSeverities,
    availableAccountIds: accountIds,
    availablePrincipals,
    availableOrigins,
    availableRequestIds,
  }));
