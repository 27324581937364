import Color from "@Color";
import ModuleIcon from "@material-ui/icons/MonetizationOn";
import createStyles from "@material-ui/core/styles/createStyles";

export const PrimaryIcon = ModuleIcon;

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    header: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    body: {
      marginTop: 20,
    },
    icon: {
      color: Color.SIGNIFY_GREEN,
      width: "auto",
      height: 48,
    },
    title: {
      marginLeft: 10,
      color: Color.TEXT,
      fontWeight: 300,
    },
  });

export default styles;
