import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";

export enum TriggerType {
  NONE = "",
  SCHEDULE = "SCHEDULE",
  DATA_LAKE = "DATA_LAKE",
  WORKLOAD_COMPLETE = "WORKLOAD_COMPLETE",
  MANUAL_EXECUTE = "MANUAL_EXECUTE",
}

export enum TriggerScheduleType {
  NONE = "",
  RATE = "RATE_BASED",
  CRON = "CRON_BASED",
}

export enum TriggerRateUnit {
  MINUTE = "MINUTE",
  MINUTES = "MINUTES",
  HOUR = "HOUR",
  HOURS = "HOURS",
  DAY = "DAY",
  DAYS = "DAYS",
}

export enum PrecedingWorkloadCompletionStatus {
  ANY = "ANY",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
}

export interface CompletionState {
  actions?: string[];
  completionStatus?: PrecedingWorkloadCompletionStatus;
}

const USER_REGEX = new RegExp(/lrn.*:principal:user:(\S+)/);

export interface TriggerAttributes {
  eventBindingId?: string;
  createdAt?: string;
  createdBy?: string;
  description?: string;
  "@type": TriggerType;
  eventType?: TriggerType;
  type?: TriggerType;
  name?: string;
  scheduleId?: string;
  scheduleExpression?: string;
  enabled?: boolean;
  rateValue?: number;
  rateUnit?: TriggerRateUnit;
  precedingWorkloadName?: string;
  dataSetAlias?: string;
  accountId?: string;
  completionState?: CompletionState;
}

export class Trigger extends Record({
  eventBindingId: "",
  createdAt: "",
  createdBy: "",
  description: "",
  "@type": TriggerType.NONE,
  eventType: TriggerType.NONE,
  type: TriggerType.NONE,
  name: "",
  scheduleId: "",
  scheduleExpression: "",
  enabled: false,
  precedingWorkloadName: "",
  dataSetAlias: "",
  accountId: "",
  completionState: {
    actions: [],
    completionStatus: PrecedingWorkloadCompletionStatus.ANY,
  },
}) implements TriggerAttributes {

  public static EMPTY: Trigger = new Trigger();

  public readonly eventBindingId: string;
  public readonly createdAt: string;
  public readonly createdBy: string;
  public readonly description: string;
  public readonly eventType: TriggerType;
  public readonly "@type": TriggerType;
  public readonly type: TriggerType;
  public readonly name: string;
  public readonly scheduleId: string;
  public readonly scheduleExpression: string;
  public readonly enabled: boolean;
  public readonly precedingWorkloadName: string;
  public readonly dataSetAlias: string;
  public readonly accountId: string;
  public readonly completionState: CompletionState;

  public getCreatedAt(): string {
    return getStringValue(this.createdAt);
  }

  public getCreatedBy(): string {
    return getStringValue(this.createdBy);
  }

  public getCreatedByUser(): string {
    const match = this.createdBy.match(USER_REGEX);
    return (!Array.isArray(match) || match.length === 0) ? "" : getStringValue(match.pop());
  }

  public getName(): string {
    return getStringValue(this.name);
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public getId(): string {
    return getStringValue(this.eventBindingId);
  }

  public getType(): TriggerType {
    return !isEmptyString(this.type) ? this.type : this.eventType;
  }

  public getScheduleId(): string {
    return this.scheduleId;
  }

  public getScheduleExpression(): string {
    return this.scheduleExpression;
  }

  public isEnabled(): boolean {
    return this.enabled;
  }

  public getConditions(): string[] {
    const { actions = [] } = this.completionState;
    return Array.isArray(actions) ? actions : [];
  }

  public getPrecedingWorkloadName(): string {
    return getStringValue(this.precedingWorkloadName);
  }

  public getPrecedingWorkloadCompletionStatus(): PrecedingWorkloadCompletionStatus {
    const { completionStatus = PrecedingWorkloadCompletionStatus.ANY } = this.completionState;
    return completionStatus;
  }

  public getDataSetAlias(): string {
    return getStringValue(this.dataSetAlias);
  }

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }
}

export default Trigger;
