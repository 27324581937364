import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { SchemaWizardActions } from "../actions/schemaWizard";
import { SchemaWizardSelectors } from "../selectors/schemaWizard";
import SchemaEditorView, { Actions, Model } from "../components/SchemaEditorView";
import SummaryEditorView from "./SummaryEditorView";
import JsonEditorView from "./JsonEditorView";

interface ContainerModel extends Model {
  hidden?: boolean;
}

type Props = ContainerModel & Actions;

const SchemaEditorViewContainer = (props: Props) => {

  const { hidden, ...otherProps } = props;

  const { summaryEditorSelected, jsonEditorSelected } = otherProps;

  if (hidden) {
    return null;
  }

  return (
    <SchemaEditorView {...props}>
      {summaryEditorSelected && <SummaryEditorView/>}
      {jsonEditorSelected && <JsonEditorView/>}
    </SchemaEditorView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): Model => {

  // We do not show the error message if the json is invalid because both editor modes
  // have their own mechanism for showing invalid json errors. The top-most error view
  // should be reserved for other types of validation errors that do not disrupt the
  // user's flow as they are typing.
  const errorMessage = !SchemaWizardSelectors.isJsonValid(state)
    ? "" : SchemaWizardSelectors.getErrorMessage(state);

  return {
    errorMessage,
    hidden: !SchemaWizardSelectors.isSchemaEditorViewSelected(state),
    summaryEditorSelected: SchemaWizardSelectors.isSummaryEditorSelected(state),
    jsonEditorSelected: SchemaWizardSelectors.isJsonEditorSelected(state),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  showSummaryEditor: () => dispatch(SchemaWizardActions.showSummaryEditorView()),
  showJsonEditor: () => dispatch(SchemaWizardActions.showJsonEditorView()),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(SchemaEditorViewContainer);
