import { isEmptyString } from "@util";
import { createSelector } from "reselect";
import { DEFAULT_STATE, ExpireUserPasswordSchema } from "./reducers";
import { createSelectors, Selector } from "@base/createSelectors";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";

export const {
  user: getUser,
  expirePasswordDialog: isExpirePasswordDialogVisible,
} = createSelectors<ExpireUserPasswordSchema>(moduleId, schemaKey, DEFAULT_STATE);

export const isUserIdValid: Selector<boolean> = createSelector(
  getUser, user => !isEmptyString(user.getUserId()));
