import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MODULE_PATH as DETAILS_PAGE_PATH } from "@modules/dataAccessRequestDetails/constants";
import { close, deleteDataAccessRequest } from "../actions";
import {
  getErrorMessage,
  getAccessRequestId,
  getDataSetAlias,
  getSuccessMessage,
  isAccessDeniedVisible,
  isDialogOpen,
  isLoadingIndicatorVisible,
  isAccessRequestIdValid,
} from "../selectors";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import DeleteDataAccessRequest from "../components/DeleteDataAccessRequest";

interface Model extends PortalModuleDialogModel<string> {
  accessRequestId?: string;
  dataSetAlias?: string;
}

type Actions = PortalModuleDialogActions<string>;

const DeleteDataAccessRequestDialog = (props: Model & Actions) => {

  const { accessRequestId, dataSetAlias, cancel: closeDialog = noop } = props;

  const match = useRouteMatch(DETAILS_PAGE_PATH);

  const history = useHistory();

  const goBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onSuccessMessageShown = React.useCallback(() => {

    if (match && match.isExact) {
      goBack();
    }

    closeDialog();

  }, [match, closeDialog, goBack]);

  return (
    <PortalModuleDialog {...props} onSuccessMessageShown={onSuccessMessageShown}>
      <DeleteDataAccessRequest accessRequestId={accessRequestId} dataSetAlias={dataSetAlias}/>
    </PortalModuleDialog>
  );
};

const mapStateToProps = (state: AppSchema): Model => ({
  className: "deleteDataAccessRequestDialog",
  title: "Cancel Data Access Request",
  open: isDialogOpen(state),
  item: getAccessRequestId(state),
  loading: isLoadingIndicatorVisible(state),
  accessDenied: isAccessDeniedVisible(state),
  errorMessage: getErrorMessage(state),
  successMessage: getSuccessMessage(state),
  continueButtonLabel: "Cancel Request",
  continueButtonDisabled: !isAccessRequestIdValid(state),
  accessRequestId: getAccessRequestId(state),
  dataSetAlias: getDataSetAlias(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  cancel: () => dispatch(close()),
  confirm: () => dispatch(deleteDataAccessRequest()),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteDataAccessRequestDialog);
