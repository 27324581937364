import { Module } from "@data";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./styles";
import view from "./AuditEventDetailsView";

type RouteParams = { id?: string };

export const auditEventDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: false,
  name: "Audit Event Details",
  title: "Audit Event Details",
  className: "auditEventDetailsModule",
  primaryColor,
  icon,
  view,
});

export default auditEventDetailsModule;
