import React from "react";
import { isEmptyString, noop } from "@util";
import { AuthTokenContext } from "@components";
import { RestClientError, SchemaRegistryClient } from "@network";
import { DEFAULT_SCHEMA, JsonSchemaDefinition, JsonSchemaMetadata } from "@data";

const SCHEMA_REF_REGEX = /^.*\/ns\/(.+)\/n\/(.+)\/v\/(.+)$/;

const getSchemaRefMatch = (schemaRef: string, index: number, defaultValue: string = ""): string => {

  const match = schemaRef.match(SCHEMA_REF_REGEX);

  if (Array.isArray(match) && match.length > index) {
    return match[index].trim();
  }

  return defaultValue;
};

const getNamespaceFromSchemaRef = (schemaRef: string = "") => getSchemaRefMatch(schemaRef, 1);
const getNameFromSchemaRef = (schemaRef: string = "") => getSchemaRefMatch(schemaRef, 2);
const getVersionFromSchemaRef = (schemaRef: string = "") => getSchemaRefMatch(schemaRef, 3);

export interface UseSchemaRefModel {
  schema: JsonSchemaDefinition;
  metadata: JsonSchemaMetadata;
  errorMessage: string;
  showErrorView: boolean;
  showProgressIndicator: boolean;
  isValidSchemaRef: boolean;
}

export interface UseSchemaRefActions {
  refresh: () => void;
}

export const useSchemaRef = (schemaRef: string = ""): [UseSchemaRefModel, UseSchemaRefActions] => {

  const authToken = React.useContext(AuthTokenContext);
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);
  const [schema, setSchema] = React.useState(DEFAULT_SCHEMA);
  const [metadata, setMetadata] = React.useState(JsonSchemaMetadata.EMPTY);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showProgressIndicator, setShowProgressIndicator] = React.useState(false);

  const isValidSchemaRef = React.useMemo(() => SCHEMA_REF_REGEX.test(schemaRef), [schemaRef]);
  const namespace = React.useMemo(() => getNamespaceFromSchemaRef(schemaRef), [schemaRef]);
  const name = React.useMemo(() => getNameFromSchemaRef(schemaRef), [schemaRef]);
  const version = React.useMemo(() => getVersionFromSchemaRef(schemaRef), [schemaRef]);

  React.useEffect(() => {

    if (!SCHEMA_REF_REGEX.test(schemaRef)) {

      if (schemaRef !== "#") {
        console.error(`ERROR! schemaRef [${schemaRef}] does not match pattern [${SCHEMA_REF_REGEX}]`);
      }

      return () => noop;
    }

    let ignore = false;

    setShowProgressIndicator(true);
    setErrorMessage("");

    Promise.all([
        SchemaRegistryClient.getSchema(authToken, namespace, name, version),
        SchemaRegistryClient.getSchemaMetadata(authToken, namespace, name, version),
      ])
      .then(([ jsonSchema, jsonSchemaMetadata ]) => {

        if (!ignore) {
          setSchema(jsonSchema);
          setMetadata(new JsonSchemaMetadata(jsonSchemaMetadata));
          setShowProgressIndicator(false);
        }

      }, (response: RestClientError) => {

        if (!ignore) {
          const { error = "Fetch schema details failed" } = response;
          setErrorMessage(error);
          setShowProgressIndicator(false);
        }
      });

    return () => { ignore = true; };

  }, [
    schemaRef,
    authToken,
    lastRefresh,
    namespace,
    name,
    version,
  ]);

  const showErrorView = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]);

  const model: UseSchemaRefModel = {
    schema,
    metadata,
    errorMessage,
    showErrorView,
    showProgressIndicator,
    isValidSchemaRef,
  };

  const actions: UseSchemaRefActions = {
    refresh: () => setLastRefresh(+new Date),
  };

  return [model, actions];
};

export default useSchemaRef;
