import React from "react";
import classnames from "classnames";
import { SearchFilter } from "@data";
import Button from "@components/button";
import Typography from "@material-ui/core/Typography";
import SearchFilters from "@components/search-filters";
import { clickHandler, isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const DEFAULT_MAP_SEARCH_FILTER_TO_LABEL = (searchFilter: SearchFilter) => {
  const key = searchFilter.getKey();
  const value = searchFilter.getValue();
  return [key, value].filter(s => !isEmptyString(s)).join(": ");
};

export interface AppliedFiltersModel {
  className?: string;
  title?: string;
  disabled?: boolean;
  hideClearButton?: boolean;
  clearButtonLabel?: string;
  searchFilters?: SearchFilter[];
  lockedFilters?: string[];
}

export interface AppliedFiltersActions {
  clearFilters?: () => void;
  setSearchFilters?: (searchFilters: SearchFilter[]) => void;
  onClickSearchFilter?: (searchFilter: SearchFilter) => void;
  mapSearchFilterToLabel?: (searchFilter: SearchFilter) => React.ReactNode;
}

type Model = AppliedFiltersModel;
type Actions = AppliedFiltersActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const AppliedFilters = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Search Filters",
    disabled,
    hideClearButton,
    searchFilters = [],
    lockedFilters = [],
    clearButtonLabel = lockedFilters.length === 0 ? "Clear" : "Reset",
    clearFilters = noop,
    setSearchFilters = noop,
    onClickSearchFilter = noop,
    mapSearchFilterToLabel = DEFAULT_MAP_SEARCH_FILTER_TO_LABEL,
  } = props;

  if (searchFilters.length === 0) {
    return null;
  }

  return (
    <div className={classnames("appliedFilters", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        <Typography className={classnames("title", classes.title)} variant="h4">
          {title}
        </Typography>
        {!hideClearButton && (
          <Button
            className={classnames("clearFiltersButton", classes.clearFiltersButton)}
            classes={{
              label: classnames("label", classes.clearFiltersButtonLabel),
              disabled: classnames("disabled", classes.disabledButton),
            }}
            disabled={disabled}
            onClick={clickHandler(clearFilters)}
          >
            {clearButtonLabel}
          </Button>
        )}
      </div>
      <SearchFilters
        className={classnames("searchFilters", classes.searchFilters)}
        disabled={disabled}
        color={!disabled ? "secondary" : "default"}
        searchFilters={searchFilters}
        lockedFilters={lockedFilters}
        setSearchFilters={setSearchFilters}
        setSelectedSearchFilter={onClickSearchFilter}
        mapSearchFilterToLabel={mapSearchFilterToLabel}
      />
    </div>
  );
});

export default AppliedFilters;
