import React from "react";
import classnames from "classnames";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { SuccessView } from "./SuccessView";
import { DeviceTypeListItem } from "@data";
import { styles } from "./styles";
import DeviceTypeInfo from "./DeviceTypeInfo";

export const PromoteView = withStyles(styles)((props: WithStyles<typeof styles> & {
  item?: DeviceTypeListItem,
  successMessage?: string,
}) => {

  const {
    classes,
    item: deviceType = DeviceTypeListItem.EMPTY,
    successMessage = "",
  } = props;

  if (successMessage.length > 0) {
    return <SuccessView className="promote" title="Success" message={successMessage} />;
  }

  return (
    <div className={classnames("promote", "content", classes.content)}>
      <label className={classnames("title", classes.title)}>
        Are you sure you want to promote this device type?
      </label>
      <DeviceTypeInfo deviceType={deviceType} />
    </div>
  );
});

export default PromoteView;
