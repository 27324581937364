import Color from "@Color";
import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    buttons: {
      flex: "0 0 50px",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    button: {
      marginLeft: 15,
      "&:first-child": {
        marginLeft: 0,
      },
    },
    active: {
    },
    content: {
      marginTop: 25,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    tabs: {
      color: Color.TEXT,
      minHeight: 0,
      borderBottom: `1px solid ${Color.GREY1}`,
      "& .MuiTabs-flexContainer": {
        gap: 10,
      },
      "& .MuiTab-textColorInherit.Mui-selected": {
        fontWeight: 400,
      }
    },
    tab: {
      textTransform: "none",
      opacity: "1",
      minWidth: 0,
      minHeight: 0,
      fontWeight: 300,
      padding: "10px 5px",
      "&:hover": {
        background: Color.LIGHT_GREY4,
      },
      "& span.MuiTab-wrapper::after": {
        display: "block",
        content: "var(--tabValue)",
        color: Color.TRANSPARENT,
        overflow: "hidden",
        visibility: "hidden",
        fontWeight: 500,
        height: 0,
      }
    },
  });

export default styles;
