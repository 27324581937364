import { isEmptyString, isValidInteger } from "@util";
import { PolicyAttributes, SecurityGroupAttributes } from "@data";
import {
  createQueryParams,
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments
} from "@network/helpers";

const DEFAULT_LIMIT = 50;

const AUTHORIZATION_SERVICE_URL = process.env.REACT_APP_AUTHORIZATION_SERVICE_API_LEGACY || "";

if (isEmptyString(AUTHORIZATION_SERVICE_URL)) {
  throw new Error("Missing Environment Variable: REACT_APP_AUTHORIZATION_SERVICE_API_LEGACY");
}

export interface GetSecurityGroupIdentitiesResponse {
  groupName: string;
  identityReferences: IdentityReference[];
  paging?: {
    next?: string;
  };
}

export interface IdentityReference {
  id: string;
  identityType: string;
}

export interface GetSecurityGroupsResponse {
  groups: SecurityGroupAttributes[];
  paging?: {
    next?: string;
  };
}

export interface GetAttachedPoliciesResponse {
  policies: PolicyAttributes[];
  paging?: {
    next?: string;
  };
}

export interface CreateSecurityGroupResponse {
  groupRef: string;
}

export const createSecurityGroup = (authToken: string,
  json: string): Promise<CreateSecurityGroupResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Security Group", json],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/groups`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to create security group";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteSecurityGroup = (authToken: string,
  groupName: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Security Group Name", groupName],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/groups/${groupName}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to delete security group [${groupName}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const addUserToSecurityGroup = (authToken: string,
  userId: string,
  groupName: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["Security Group Name", groupName],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/groups/${groupName}/users/${userId}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to add user [${userId}] to group [${groupName}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const removeUserFromSecurityGroup = (authToken: string,
  userId: string,
  groupName: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["Security Group Name", groupName],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/groups/${groupName}/users/${userId}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to remove user [${userId}] from group [${groupName}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const addServiceToSecurityGroup = (authToken: string,
  serviceId: string,
  groupName: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Service ID", serviceId],
      ["Security Group Name", groupName],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/groups/${groupName}/services/${serviceId}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to add service [${serviceId}] to group [${groupName}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const removeServiceFromSecurityGroup = (authToken: string,
  serviceId: string,
  groupName: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Service ID", serviceId],
      ["Security Group Name", groupName],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/groups/${groupName}/services/${serviceId}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to remove service [${serviceId}] from group [${groupName}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getSecurityGroups = (authToken: string,
  next: string = "",
  groupName: string = "",
  limit: number = DEFAULT_LIMIT,
): Promise<GetSecurityGroupsResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams({
      ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, Math.min(DEFAULT_LIMIT, limit)) }),
      ...(!isEmptyString(groupName) && groupName.length >= 3 ? ({ groupName }) : ({})),
      next,
    });

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/groups${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch security groups failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getSecurityGroup = (authToken: string,
  groupName: string = ""): Promise<SecurityGroupAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Security Group Name", groupName],
    ]));

  const makeRequest = () => {

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/groups/${groupName}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to get security group [${groupName}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getSecurityGroupUsers = (authToken: string,
  groupName: string = "",
  next: string = "",
  limit: number = DEFAULT_LIMIT,
): Promise<GetSecurityGroupIdentitiesResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Security Group Name", groupName],
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({
      ...(!isValidInteger(limit) ? {} : {limit: Math.max(1, Math.min(DEFAULT_LIMIT, limit))}),
      next,
    });

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/groups/${groupName}/users${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to get users that belong to security group [${groupName}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getSecurityGroupServices = (authToken: string,
  groupName: string = "",
  next: string = "",
  limit: number = DEFAULT_LIMIT,
): Promise<GetSecurityGroupIdentitiesResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Security Group Name", groupName],
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({
      ...(!isValidInteger(limit) ? {} : {limit: Math.max(1, Math.min(DEFAULT_LIMIT, limit))}),
      next,
    });

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/groups/${groupName}/services${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to get services that belong to security group [${groupName}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getSecurityGroupsByUserId = (authToken: string,
  userId: string,
  next: string = "",
  groupName: string = "",
  limit: number = DEFAULT_LIMIT,
): Promise<GetSecurityGroupsResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({
      ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, Math.min(DEFAULT_LIMIT, limit)) }),
      ...(!isEmptyString(groupName) && groupName.length >= 3 ? ({ groupName }) : ({})),
      userId,
      next,
    });

    const url = `${AUTHORIZATION_SERVICE_URL}/v2/aam/groups${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to get security groups for user [${userId}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
