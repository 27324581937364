import React from "react";
import { useListCloudRolePrincipals } from "@hooks";
import IotCloudRoleView, { Model, Actions } from "../components/IotCloudRoleView";

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const IotCloudRoleContainerView = (props: Props) => {

  const [model, actions] = useListCloudRolePrincipals();

  const {
    principalIdentifiers: cloudRoleIdentifiers,
    showLoadingIndicator: loadingCloudRoleIdentifiers,
    errorMessage: cloudRoleIdentifiersErrorMessage,
  } = model;

  const {
    listCloudRolePrincipals: refreshCloudRoleIdentifiers,
  } = actions;

  return (
    <IotCloudRoleView
      {...props}
      cloudRoleIdentifiers={cloudRoleIdentifiers}
      loadingCloudRoleIdentifiers={loadingCloudRoleIdentifiers}
      cloudRoleIdentifiersErrorMessage={cloudRoleIdentifiersErrorMessage}
      refreshCloudRoleIdentifiers={refreshCloudRoleIdentifiers}
    />
  );
};

export default IotCloudRoleContainerView;
