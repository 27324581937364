import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      "& label.name": {
        minWidth: 250,
      },
    },
    accessRequestApprovalDetails: {
      flex: "1 0 auto",
      marginTop: 32,
    },
  });

export default styles;
