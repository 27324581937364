import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { JsonEditor } from "@components";
import { dataView as styles } from "./styles";
import { Header } from "./Header";
import { equalsIgnoreCase, noop } from "@util";

export interface Model {
  className?: string;
  json?: string;
  showErrorMessage?: boolean;
  showSuccessMessage?: boolean;
  showLoadingIndicator?: boolean;
  showInvalidJsonError?: boolean;
  delay?: number;
}

export interface Actions {
  setJson?: (json: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DataView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    json,
    showErrorMessage,
    showSuccessMessage,
    showLoadingIndicator,
    showInvalidJsonError,
    delay = 1000,
    setJson = noop,
  } = props;

  const [updatedJson, setUpdatedJson] = React.useState(json);

  React.useEffect(() => {
    if (!equalsIgnoreCase(updatedJson, json)) {
      const timer = setTimeout(() => {
        setJson(updatedJson);
      }, delay);

      return () => clearTimeout(timer);
    }

    return () => noop();
  }, [updatedJson, json, setJson]);

  return (
    <div className={classnames("dataView", className, classes.container)}>
      <Header
        className={classes.header}
        title={showInvalidJsonError ? "Data - Invalid JSON" : "Data"}
        showErrorMessage={showErrorMessage || showInvalidJsonError}
        showSuccessMessage={showSuccessMessage}
        showLoadingIndicator={showLoadingIndicator}
      />
      <JsonEditor
        className={classnames("dataJsonEditor", classes.dataJsonEditor)}
        json={updatedJson}
        width="auto"
        height="auto"
        fontSize={14}
        setJson={setUpdatedJson}
        highlightActiveLine={false}
        debounceChangePeriod={0}
        aceOptions={{
          highlightGutterLine: false,
          showLineNumbers: false,
          showGutter: false,
        }}
      />
    </div>
  );
});

export default DataView;
