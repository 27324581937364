import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    label: {
      color: Color.TEXT,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "22px",
      marginLeft: 10,
      "&:first-child": {
        marginLeft: 0,
      },
    },
    link: {
      color: Color.BLUE,
      cursor: "pointer",
      textDecoration: "underline",
    },
    divider: {
      color: Color.GREY4,
      fontSize: 22,
      fontWeight: 300,
    },
    active: {
    },
  });

export default styles;
