import React from "react";
import { Device } from "@data";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import {
  getDeviceAsJson,
  getDeviceId,
  getSummaryViewItems,
  isDeviceEnrolled,
  isEnrollmentFailure,
  isLoadingIndicator,
  isNotFoundVisible,
} from "../selectors";
import { disenrollDevice, openDeviceEnrollment, refresh } from "../actions";
import { Actions, DeviceEnrollmentView, Model } from "../components/DeviceEnrollmentView";
import EnrollmentHistoryListView from "@modules/deviceDetails/containers/EnrollmentHistoryListView";

interface ContainerModel extends Model {
  deviceId?: string;
}

interface ContainerActions extends Actions {
  showEnrollmentDetails?: (device: Device) => void;
}

type Props = ContainerModel & ContainerActions;

const DeviceEnrollmentViewContainer = (props: Props) => {

  const {
    showEnrollmentDetails,
    showLoadingIndicator,
    ...otherProps
  } = props;

  const enrollmentHistoryView = React.useMemo(() => (
    <EnrollmentHistoryListView showEnrollmentDetails={showEnrollmentDetails}/>
  ), [showEnrollmentDetails]);

  return (
    <DeviceEnrollmentView
      {...otherProps}
      showLoadingIndicator={showLoadingIndicator}
      additionalViews={enrollmentHistoryView}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  disenrollDeviceButtonDisabled: !isDeviceEnrolled(state),
  showLoadingIndicator: isLoadingIndicator(state),
  showReEnrollDeviceButton: isEnrollmentFailure(state),
  items: getSummaryViewItems(state),
  json: getDeviceAsJson(state),
  deviceId: getDeviceId(state),
  showNotFound: isNotFoundVisible(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  disenrollDevice: () => dispatch(disenrollDevice()),
  deviceReEnrollment: () => dispatch(openDeviceEnrollment()),
  refresh: () => dispatch(refresh()),
  ...ownProps
});

export default connect<Model, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceEnrollmentViewContainer);
