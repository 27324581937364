import { combineReducers } from "redux";
import { Actions, filterAction, Identity, ReduxAction } from "../../../util/ReducerUtil";
import {
  JsonSchemaPropertyAttributes,
  JsonSchemaProperty,
  JsonSchemaPropertyType,
  JsonSchemaPropertyDefaultValueType,
} from "../../../data";
import {
  DEFAULT_STATE as STRING_CONSTRAINTS_DEFAULT_STATE,
  StringConstraintsReducer,
  StringConstraintsSchema,
} from "./stringConstraints";
import {
  DEFAULT_STATE as NUMBER_CONSTRAINTS_DEFAULT_STATE,
  NumberConstraintsReducer,
  NumberConstraintsSchema,
} from "./numberConstraints";
import {
  DEFAULT_STATE as INTEGER_CONSTRAINTS_DEFAULT_STATE,
  IntegerConstraintsReducer,
  IntegerConstraintsSchema,
} from "./integerConstraints";

export interface PropertyEditorSchema {
  errorMessage: string;
  original: JsonSchemaPropertyAttributes;
  name: string;
  nameError: string;
  type: JsonSchemaPropertyType;
  description: string;
  required: boolean;
  enumValues: JsonSchemaPropertyDefaultValueType[];
  enumValueError: string;
  defaultValue: JsonSchemaPropertyDefaultValueType;
  defaultValueError: string;
  showProgressIndicator: boolean;
  stringConstraints: StringConstraintsSchema;
  numberConstraints: NumberConstraintsSchema;
  integerConstraints: IntegerConstraintsSchema;
  clonedProperty: boolean;
}

export const DEFAULT_STATE: PropertyEditorSchema = Object.assign({
  errorMessage: "",
  original: JsonSchemaProperty.EMPTY.toJS(),
  name: JsonSchemaProperty.EMPTY.name,
  nameError: "",
  type: JsonSchemaProperty.EMPTY.type,
  description: JsonSchemaProperty.EMPTY.description,
  required: JsonSchemaProperty.EMPTY.required,
  enumValues: JsonSchemaProperty.EMPTY.enumValues,
  enumValueError: "",
  defaultValue: JsonSchemaProperty.EMPTY.defaultValue,
  defaultValueError: "",
  showProgressIndicator: false,
  stringConstraints: { ...STRING_CONSTRAINTS_DEFAULT_STATE },
  numberConstraints: { ...NUMBER_CONSTRAINTS_DEFAULT_STATE },
  integerConstraints: { ...INTEGER_CONSTRAINTS_DEFAULT_STATE },
  clonedProperty: false,
});

export enum PropertyEditorActionType {
  SET_ERROR_MESSAGE = "schema_wizard/property_editor/set_error_message",
  SET_ORIGINAL = "schema_wizard/property_editor/set_original",
  SET_NAME = "schema_wizard/property_editor/set_name",
  SET_NAME_ERROR = "schema_wizard/property_editor/set_name_error",
  SET_TYPE = "schema_wizard/property_editor/set_type",
  SET_DESCRIPTION = "schema_wizard/property_editor/set_description",
  SET_REQUIRED = "schema_wizard/property_editor/set_required",
  SET_CLONED_PROPERTY = "schema_wizard/property_editor/set_cloned_property",
  SET_ENUM_VALUES = "schema_wizard/property_editor/set_enum_values",
  SET_ENUM_VALUE_ERROR = "schema_wizard/property_editor/set_enum_value_error",
  SET_DEFAULT_VALUE = "schema_wizard/property_editor/set_default_value",
  SET_DEFAULT_VALUE_ERROR = "schema_wizard/property_editor/set_default_value_error",
  TOGGLE_SHOW_PROGRESS_INDICATOR = "schema_wizard/property_editor/toggle_show_progress_indicator",
  VALIDATE_PROPERTY = "schema_wizard/property_editor/validate_property",
  VALIDATE_PROPERTY_SUCCESS = "schema_wizard/property_editor/validate_property_success",
  VALIDATE_PROPERTY_FAILED = "schema_wizard/property_editor/validate_property_failed",
}

export type PropertyEditorAction = ReduxAction<PropertyEditorActionType>;

const errorMessage = filterAction<string>(Actions(
  PropertyEditorActionType.SET_ERROR_MESSAGE))(Identity, DEFAULT_STATE.errorMessage);

const original = filterAction<JsonSchemaPropertyAttributes>(Actions(
  PropertyEditorActionType.SET_ORIGINAL))(Identity, DEFAULT_STATE.original);

const name = filterAction<string>(Actions(
  PropertyEditorActionType.SET_NAME))(Identity, DEFAULT_STATE.name);

const nameError = filterAction<string>(Actions(
  PropertyEditorActionType.SET_NAME_ERROR))(Identity, DEFAULT_STATE.nameError);

const type = filterAction<JsonSchemaPropertyType>(Actions(
  PropertyEditorActionType.SET_TYPE))(Identity, DEFAULT_STATE.type);

const description = filterAction<string>(Actions(
  PropertyEditorActionType.SET_DESCRIPTION))(Identity, DEFAULT_STATE.description);

const required = filterAction<boolean>(Actions(
  PropertyEditorActionType.SET_REQUIRED))(Identity, DEFAULT_STATE.required);

const clonedProperty = filterAction<boolean>(Actions(
  PropertyEditorActionType.SET_CLONED_PROPERTY))(Identity, DEFAULT_STATE.clonedProperty);

const enumValues = filterAction<JsonSchemaPropertyDefaultValueType[]>(Actions(
  PropertyEditorActionType.SET_ENUM_VALUES))(Identity, DEFAULT_STATE.enumValues);

const enumValueError = filterAction<string>(Actions(
  PropertyEditorActionType.SET_ENUM_VALUE_ERROR))(Identity, DEFAULT_STATE.enumValueError);

const defaultValue = filterAction<JsonSchemaPropertyDefaultValueType>(Actions(
  PropertyEditorActionType.SET_DEFAULT_VALUE))(Identity, DEFAULT_STATE.defaultValue);

const defaultValueError = filterAction<string>(Actions(
  PropertyEditorActionType.SET_DEFAULT_VALUE_ERROR))(Identity, DEFAULT_STATE.defaultValueError);

const showProgressIndicator = filterAction<boolean>(Actions(
  PropertyEditorActionType.TOGGLE_SHOW_PROGRESS_INDICATOR))(Identity,
  DEFAULT_STATE.showProgressIndicator);

export const PropertyEditorReducer = combineReducers<PropertyEditorSchema>({
  errorMessage,
  original,
  name,
  nameError,
  type,
  description,
  required,
  clonedProperty,
  enumValues,
  enumValueError,
  defaultValue,
  defaultValueError,
  showProgressIndicator,
  stringConstraints: StringConstraintsReducer,
  numberConstraints: NumberConstraintsReducer,
  integerConstraints: IntegerConstraintsReducer,
});

export default PropertyEditorReducer;
