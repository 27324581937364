import { Record } from "immutable";
import { getStringValue } from "@util";

export enum MaintenanceReminderState {
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
}

export enum MaintenanceReminderStatus {
  ACTIVE = "ACTIVE",
  UPCOMING = "UPCOMING",
}

export interface MaintenanceReminderAttributes {
  reminderId?: string;
  startDate?: string;
  endDate?: string;
  description?: string;
  reminderState?: MaintenanceReminderState;
  lastModifiedAt?: string;
  eTag?: string;
}

export class MaintenanceReminder extends Record({
  reminderId: "",
  startDate: "",
  endDate: "",
  description: "",
  reminderState: MaintenanceReminderState.DRAFT,
  lastModifiedAt: "",
  eTag: "",
}) implements MaintenanceReminderAttributes {

  public static EMPTY: MaintenanceReminder = new MaintenanceReminder();

  public readonly reminderId: string;
  public readonly startDate: string;
  public readonly endDate: string;
  public readonly description: string;
  public readonly reminderState: MaintenanceReminderState;
  public readonly lastModifiedAt: string;
  public readonly eTag: string;

  public getReminderId(): string {
    return getStringValue(this.reminderId);
  }

  public getStartDate(): string {
    return getStringValue(this.startDate);
  }

  public getEndDate(): string {
    return getStringValue(this.endDate);
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public getReminderState(): string {
    return getStringValue(this.reminderState);
  }

  public getLastModifiedAt(): string {
    return getStringValue(this.lastModifiedAt);
  }

  public getETag(): string {
    return getStringValue(this.eTag);
  }
}

export default MaintenanceReminder;
