import React from "react";
import { useHistory } from "react-router-dom";
import { SecurityGroup } from "@data/SecurityGroup";
import { getPathToGroup } from "@modules/securityGroupDetails/helpers";
import { getCreateGroupPath } from "@modules/createSecurityGroup/helpers";
import {
  getAddGroupServicePath,
  getAddGroupUserPath,
  getRemoveGroupServicePath,
  getRemoveGroupUserPath,
} from "@modules/groupManagementWizard/helpers";
import {
  getAttachPoliciesToGroupPath,
  getDetachPoliciesFromGroupPath,
} from "@modules/policyManagement/helpers";
import SecurityGroups from "./containers/SecurityGroups";

export function SecurityGroupsView() {

  const history = useHistory();

  const createSecurityGroup = React.useCallback(() =>
    history.push(getCreateGroupPath()), [history]);

  const showSecurityGroupDetails = React.useCallback((group: SecurityGroup) =>
    history.push(getPathToGroup(group.getName())), [history]);

  const attachPolicy = React.useCallback((group: SecurityGroup) =>
    history.push(getAttachPoliciesToGroupPath(group.getName())), [history]);

  const detachPolicy = React.useCallback((group: SecurityGroup) =>
    history.push(getDetachPoliciesFromGroupPath(group.getName())), [history]);

  const addUser = React.useCallback((group: SecurityGroup) =>
    history.push(getAddGroupUserPath(group.getName())), [history]);

  const removeUser = React.useCallback((group: SecurityGroup) =>
    history.push(getRemoveGroupUserPath(group.getName())), [history]);

  const addService = React.useCallback((group: SecurityGroup) =>
    history.push(getAddGroupServicePath(group.getName())), [history]);

  const removeService = React.useCallback((group: SecurityGroup) =>
    history.push(getRemoveGroupServicePath(group.getName())), [history]);

  return (
    <SecurityGroups
      addUser={addUser}
      removeUser={removeUser}
      addService={addService}
      removeService={removeService}
      attachPolicy={attachPolicy}
      detachPolicy={detachPolicy}
      createSecurityGroup={createSecurityGroup}
      showSecurityGroupDetails={showSecurityGroupDetails}
    />
  );
}

export default SecurityGroupsView;
