import React from "react";
import classnames from "classnames";
import TextField from "@components/text-field";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  formEventHandler,
  noop } from "@util";
import { serviceInfo as styles } from "./styles";
import { DropdownMenu } from "@components";
import { SecurityProtocol } from "@data";

export interface Model {
  serviceId?: string;
  protocol?: string;
  showLoadingIndicator?: boolean;
}

export interface Actions {
  setServiceId?: (serviceId: string) => void;
  setProtocol?: (protocol: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const ServiceInfo = withStyles(styles)((props: Props) => {

  const {
    classes,
    serviceId = "",
    showLoadingIndicator = false,
    protocol = SecurityProtocol.SHARED_SECRET,
    setServiceId = noop,
    setProtocol = noop,
  } = props;

  const formHelperTextProps = {
    className: classnames("formHelperText", classes.formHelperText),
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const inputProps = {
    className: classnames("textField", classes.textField),
    spellCheck: false,
  };

  return (
    <div className={classnames("serviceInfo", classes.container)}>
      <TextField
        className={classnames("service", classes.textField, classes.serviceId)}
        type="text"
        variant="outlined"
        autoComplete="off"
        margin="none"
        fullWidth={true}
        autoFocus={true}
        label="Service ID"
        name="serviceId"
        value={serviceId}
        inputProps={inputProps}
        helperText="Unique Identifier for a service"
        disabled={showLoadingIndicator}
        placeholder="Service Id"
        onChange={formEventHandler(setServiceId)}
        FormHelperTextProps={formHelperTextProps}
        InputLabelProps={inputLabelProps}
        disableSpecialChars={true}
      />
      <div className={classnames("protocolContainer", classes.protocolContainer)}>
        <DropdownMenu
          dropdownMenuLabel="Protocol"
          dropdownMenuHint="Protocol used for service secret"
          values={[SecurityProtocol.SHARED_SECRET]}
          selectedValue={protocol}
          setSelectedValue={setProtocol}
        />
      </div>
    </div>
  );
});

export default ServiceInfo;
