import { createSelector } from "reselect";
import { isEmptyString, isValidJson } from "@util";
import { MODULE_ID, SCHEMA_KEY } from "../constants";
import { createSelectors, Selector } from "@modules/base/createSelectors";
import { FederationProviderRequest, FederationProviderRequestAttributes } from "@data";
import {
  DEFAULT_STATE,
  FederationProviderWizardSchema,
  FederationProviderWizardStep,
} from "../reducers";
import { FederationProviderWizardState, FederationProviderWizardStateAttributes } from "../models";

export const DEFAULT_STEPS = [
  FederationProviderWizardStep.CLIENT,
  FederationProviderWizardStep.ENDPOINTS,
  FederationProviderWizardStep.ATTRIBUTES,
  FederationProviderWizardStep.EDITOR,
  FederationProviderWizardStep.REVIEW,
];

export const DEFAULT_STEP_LABELS = {
  [FederationProviderWizardStep.CLIENT]: "Client Info",
  [FederationProviderWizardStep.ENDPOINTS]: "Endpoint Info",
  [FederationProviderWizardStep.ATTRIBUTES]: "Federation Provider Attributes",
  [FederationProviderWizardStep.EDITOR]: "JSON",
  [FederationProviderWizardStep.REVIEW]: "Finish"
};

export const {
  json: getJson,
  federationProviderRequest: getFederationProviderRequestAttributes,
  currentStep: getCurrentStep,
  showEditMode: isEditModeActive,
  providerId: getProviderId,
  defaultState: getDefaultStateAttributes,
  getErrors,
  getErrorMessage,
  getSuccessMessage,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  getAccessToken,
} = createSelectors<FederationProviderWizardSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getFederationProviderRequest: Selector<FederationProviderRequest> = createSelector(
  getFederationProviderRequestAttributes, (attrs: FederationProviderRequestAttributes) => {
    return new FederationProviderRequest(attrs);
  });

export const getDefaultState: Selector<FederationProviderWizardState> = createSelector(
  getDefaultStateAttributes, (attrs: FederationProviderWizardStateAttributes) =>
    new FederationProviderWizardState(attrs));

export const getCurrentState: Selector<FederationProviderWizardState> = createSelector(
  [getFederationProviderRequest, getProviderId],
  (federationProviderRequest: FederationProviderRequest, providerId: string) =>
    new FederationProviderWizardState({
      ...federationProviderRequest.toJS(),
      providerId,
    }));

export const getDomain: Selector<string> = createSelector(
  getFederationProviderRequest, (federationProviderRequest: FederationProviderRequest) => {
    return federationProviderRequest.domain;
});

export const getClientId: Selector<string> = createSelector(
  getFederationProviderRequest, (federationProviderRequest: FederationProviderRequest) => {
    return federationProviderRequest.clientId;
  });

export const getClientSecret: Selector<string> = createSelector(
  getFederationProviderRequest, (federationProviderRequest: FederationProviderRequest) => {
    return federationProviderRequest.clientSecret;
  });

export const getDiscoveryDocument: Selector<string> = createSelector(
  getFederationProviderRequest, (federationProviderRequest: FederationProviderRequest) => {
    return federationProviderRequest.discoveryDocument;
  });

export const getAuthorizationEndpoint: Selector<string> = createSelector(
  getFederationProviderRequest, (federationProviderRequest: FederationProviderRequest) => {
    return federationProviderRequest.authorizationEndpoint;
  });

export const getTokenEndpoint: Selector<string> = createSelector(
  getFederationProviderRequest, (federationProviderRequest: FederationProviderRequest) => {
    return federationProviderRequest.tokenEndpoint;
  });

export const getUserEndpoint: Selector<string> = createSelector(
  getFederationProviderRequest, (federationProviderRequest: FederationProviderRequest) => {
    return federationProviderRequest.userEndpoint;
  });

export const getJwksUri: Selector<string> = createSelector(
  getFederationProviderRequest, (federationProviderRequest: FederationProviderRequest) => {
    return federationProviderRequest.jwksUri;
  });

export const getIssuer: Selector<string> = createSelector(
  getFederationProviderRequest, (federationProviderRequest: FederationProviderRequest) => {
    return federationProviderRequest.issuer;
  });

export const isFederationClosed: Selector<boolean> = createSelector(
  getFederationProviderRequest, (federationProviderRequest: FederationProviderRequest) => {
    return federationProviderRequest.federationClosed;
  });

export const getScope: Selector<string[]> = createSelector(
  getFederationProviderRequest, (federationProviderRequest: FederationProviderRequest) => {
    return Array.isArray(federationProviderRequest.scope.values) ? federationProviderRequest.scope.values : [];
  });

export const getScopeAsJson: Selector<string> = createSelector(
  getFederationProviderRequest, (federationProviderRequest: FederationProviderRequest) => {
    return JSON.stringify(federationProviderRequest.scope, null, "  ");
  });

export const getAttributes: Selector<string> = createSelector(
  getFederationProviderRequest, (federationProviderRequest: FederationProviderRequest) => {
    return JSON.stringify(federationProviderRequest.attributes, null, "  ");
  });

export const getErrorTitle: Selector<string> = createSelector(
  [getErrorMessage, isEditModeActive],
  (errorMessage: string = "", editMode: boolean = false) => {

    if (errorMessage.length === 0) {
      return "";
    }

    return editMode ? "Update Federation Provider Failed" : "Create Federation Provider Failed";
  });

export const isClientViewSelected: Selector<boolean> = createSelector(
  getCurrentStep, (step: FederationProviderWizardStep) => {
    return FederationProviderWizardStep.CLIENT === step;
  });

export const isEndpointsViewSelected: Selector<boolean> = createSelector(
  getCurrentStep, (step: FederationProviderWizardStep) => {
    return FederationProviderWizardStep.ENDPOINTS === step;
  });

export const isAttributesViewSelected: Selector<boolean> = createSelector(
  getCurrentStep, (step: FederationProviderWizardStep) => {
    return FederationProviderWizardStep.ATTRIBUTES === step;
  });

export const isEditorViewSelected: Selector<boolean> = createSelector(
  getCurrentStep, (step: FederationProviderWizardStep) => {
    return FederationProviderWizardStep.EDITOR === step;
  });

export const isReviewViewSelected: Selector<boolean> = createSelector(
  getCurrentStep, (step: FederationProviderWizardStep) => {
    return FederationProviderWizardStep.REVIEW === step;
  });

export const isJsonValid: Selector<boolean>  = createSelector(
  getJson, (json: string) => isValidJson(json));

export const isClientInfoValid: Selector<boolean> = createSelector(
  [getDomain, getClientId, getClientSecret],
  (domain: string, clientId: string, clientSecret: string) =>
    !isEmptyString(domain) && !isEmptyString(clientId) && !isEmptyString(clientSecret));

export const isEndpointInfoValid: Selector<boolean> = createSelector(
  [getAuthorizationEndpoint, getTokenEndpoint],
  (authorizationEndpoint: string, tokenEndpoint: string) =>
    !isEmptyString(authorizationEndpoint) && !isEmptyString(tokenEndpoint));

export const getDisabledSteps: Selector<FederationProviderWizardStep[]> = createSelector(
  [getCurrentStep, isJsonValid, isClientInfoValid, isEndpointInfoValid],
  (
    currentStep: FederationProviderWizardStep,
    validJson: boolean,
    validClientInfo: boolean,
    validEndpointInfo: boolean,
  ) => {

    if (!validJson) {
      return DEFAULT_STEPS.filter(step => step !== currentStep);
    }

    if (!validClientInfo) {
      return DEFAULT_STEPS.filter(step => {
        switch (step) {
          case FederationProviderWizardStep.CLIENT:
            return false;
          default:
            return true;
        }
      });
    }

    if (!validEndpointInfo) {
      return DEFAULT_STEPS.filter(step => {
        switch (step) {
          case FederationProviderWizardStep.CLIENT:
          case FederationProviderWizardStep.ENDPOINTS:
            return false;
          default:
            return true;
        }
      });
    }

    return [];
  });

export const getCompletedSteps: Selector<FederationProviderWizardStep[]> = createSelector(
  [
    getCurrentStep,
    isClientInfoValid,
    isEndpointInfoValid,
    isLoadingIndicatorVisible,
    isSuccessMessageVisible,
  ],
  (
    currentStep: FederationProviderWizardStep,
    validClientInfo: boolean,
    validEndpointInfo: boolean,
    loading: boolean,
    success: boolean,
  ) => {

    const currentStepIndex = DEFAULT_STEPS.indexOf(currentStep);

    return DEFAULT_STEPS.filter((step, index) => {

      const isCurrentStep = (step === currentStep);

      switch (step) {
        case FederationProviderWizardStep.CLIENT:
          return !isCurrentStep && validClientInfo;
        case FederationProviderWizardStep.ENDPOINTS:
          return !isCurrentStep && validEndpointInfo;
        case FederationProviderWizardStep.ATTRIBUTES:
          return currentStepIndex > index;
        case FederationProviderWizardStep.EDITOR:
          return currentStepIndex > index;
        case FederationProviderWizardStep.REVIEW:
          return loading || success;
        default:
          return false;
      }
    });
  });
