import React from "react";
import { Policy } from "@data";
import { useHistory } from "react-router-dom";
import { getCreatePolicyPath } from "@modules/createPolicy/helpers";
import { getEditPolicyPath } from "@modules/editPolicy/helpers";
import { getPathToPolicy } from "@modules/policyDetails/helpers";
import Policies from "./containers/Policies";

export function PoliciesView() {

  const history = useHistory();

  const showCreatePolicyWizard = React.useCallback(() =>
    history.push(getCreatePolicyPath()), [history]);

  const showPolicyDetails = React.useCallback((policy: Policy) =>
    history.push(getPathToPolicy(policy.getName())), [history]);

  const editPolicy = React.useCallback((policy: Policy) =>
  history.push(getEditPolicyPath(policy.getName())), [history]);

  return (
    <Policies
      showCreatePolicyWizard={showCreatePolicyWizard}
      showPolicyDetails={showPolicyDetails}
      editPolicy={editPolicy}
    />
  );
}

export default PoliciesView;
