import createStyles from "@material-ui/core/styles/createStyles";
import { StyleRules } from "@material-ui/core/styles";
import Color from "@Color";
import { primaryColor } from "@modules/createUser/components/styles";

export const textField: StyleRules<string> = {
    root: {
        fontFamily: "Roboto, sans-serif",
        fontSize: 12,
        fontWeight: 300,
        padding: 0,
        margin: 0,
        "& label": {
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            lineHeight: "0",
            fontWeight: 300,
            padding: 0,
            margin: 0,
        },
        "& input": {
            fontFamily: "Roboto, sans-serif",
            fontSize: 12,
            fontWeight: 400,
            padding: "8px 10px",
            margin: 0,
        },
    },
};

export const userInfo = () =>
    createStyles({
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
            justifyContent: "flex-start",
        },
        federationProvidersContainer: {
            flex: "1 0 auto",
            display: "flex",
            flexFlow: "row nowrap",
        },
        federationProvidersDropdown: {
            flex: "1 0 auto",
            marginLeft: 15,
        },
        federationProvidersDropdownMenu: {
        },
        radioGroup: {
        },
        radio: {
        },
        userId: {
            marginTop: 15,
        },
        userIdEndAdornment: {
            color: Color.TEXT,
            fontSize: 16,
        },
        passwordContainer: {
            marginTop: 15,
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
        },
        userIdValue: {
        },
        passwordControls: {
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
        },
        showHidePasswordButton: {
            width: "auto !important",
            height: "auto !important",
            padding: 12,
        },
        randomPasswordButton: {
            width: "auto !important",
            height: "auto !important",
            padding: 12,
        },
        mfaEnabled: {
            marginTop: 15,
        },
        mfaSecret: {
            marginTop: 15,
        },
        checkboxContainer: {
            margin: "15px 0 0",
        },
        checkbox: {
            color: primaryColor,
            padding: 0,
        },
        iconButton: {
            position: "absolute",
            right: 8,
            bottom: 18,
            width: 20,
            height: 20,
            padding: 0,
            margin: 0,
        },
        tooltipIconPopper: {
            marginTop: 12,
            justifyContent: "flex-end",
        },
        textField: {
            color: Color.TEXT,
            fontSize: 16,
            height: "auto",
            minWidth: "300px",
            maxWidth: "700px",
        },
        formHelperText: {
            color: Color.TEXT,
            fontSize: 13,
        },
        formHelperTextError: {
            marginBottom: 15,
        },
        portalUserFormHelperTextError: {
            marginBottom: 0,
        },
        inputLabelShrink: {
            lineHeight: "1 !important",
        },
        modal: {
            background: "rgba(105, 105, 105, 0.25)",
            position: "fixed",
            zIndex: 9999,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
        },
        loadingMessage: {
            padding: 25,
            marginBottom: 25,
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            "& h1": {
                fontWeight: 500,
            },
            "& h4": {
                marginTop: 15,
                fontWeight: 500,
                color: Color.SIGNIFY_GREEN,
            },
            "& h6": {
            },
        },
        loadingMessageLabel: {
            margin: 15,
        },
    });

export const styles = () =>
    createStyles({
        container: {
            flex: "1 0 auto",
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center",
            justifyContent: "center",
        },
        title: {
            fontSize: 18,
            fontWeight: 400,
            lineHeight: "21px",
        },
        subtitle: {
            fontSize: 16,
            fontWeight: 300,
            lineHeight: "18px",
            marginTop: 16,
        },
        warning: {
            fontWeight: 500,
            marginRight: 4,
        },
        emptyViewLabel: {
            fontSize: 16,
            fontWeight: 300,
            lineHeight: "18px",
            fontStyle: "italic",
        },
        userId: {
            fontSize: 14,
            fontWeight: 300,
            lineHeight: "16px",
            marginTop: 16,
        },
    });

export default styles;
