import createStyles from "@material-ui/core/styles/createStyles";
import { StyleRules } from "@material-ui/core/styles";
import Color from "../styles/Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    label: {
      marginRight: 5,
    },
  });

export const tooltipIcon = () =>
  createStyles({
    tooltipIconButton: {
      width: 15,
      height: 15,
      margin: 0,
      padding: 0,
    },
    tooltipIcon: {
      width: 15,
      height: 15,
    },
    tooltipIconPopper: {
      zIndex: 9999,
    },
  });

export const tooltip: StyleRules = {
  popper: {
    width: 231,
    height: "auto",
    alignItems: "center",
    display: "flex",
    marginTop: 15,
  },
  tooltip: {
    backgroundColor: Color.WHITE,
    color: Color.GREY8,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px",
    textAlign: "left",
    whiteSpace: "normal",
    padding: "10px 15px",
    margin: 0,
    opacity: 1,
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.5)",
    borderRadius: 3,
  },
};

export default styles;
