import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  createPortalModuleDefaultState,
  PortalModuleActionTypes,
} from "../base/createReducers";

export interface FederationProvidersSchema extends BasePortalModuleSchema {
  nameFilter: string;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<FederationProvidersSchema>({
  nameFilter: "",
});

export type FederationProvidersActionTypes = PortalModuleActionTypes<FederationProvidersSchema>;

export const ACTION_TYPES: FederationProvidersActionTypes =
  createPortalModuleActionTypes<FederationProvidersSchema>(MODULE_ID, DEFAULT_STATE);

export const FederationProvidersReducer = combineReducers<FederationProvidersSchema>(
  createPortalModuleReducers<FederationProvidersSchema>(ACTION_TYPES, DEFAULT_STATE));

export default FederationProvidersReducer;
