import React from "react";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import SummaryViewLabel from "@components/summary-view-label";
import { reviewView as styles } from "./styles";

export interface Model {
  title?: string;
  name?: string;
  description?: string;
  type?: string;
  scope?: string;
  redirectUris?: string;
  metadata?: string;
  authentication?: string;
  protocol?: string;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ReviewView = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Review Application Details",
    name,
    description,
    type,
    scope,
    redirectUris,
    metadata,
    authentication,
    protocol,
    children,
  } = props;

  return (
    <div className={classnames("reviewView", classes.container)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Application Name"
        nameClassName={classes.summaryViewLabelName}
        value={name}
        valueClassName={classnames("name", classes.summaryViewLabelValue)}
      />
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Description"
        nameClassName={classes.summaryViewLabelName}
        value={description}
        valueClassName={classnames("description", classes.summaryViewLabelValue)}
      />
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Application Type"
        nameClassName={classes.summaryViewLabelName}
        value={type}
        valueClassName={classnames("type", classes.summaryViewLabelValue)}
      />
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Scope(s)"
        nameClassName={classes.summaryViewLabelName}
        value={scope}
        valueClassName={classnames("scope", classes.summaryViewLabelValue)}
      />
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Redirect URI(s)"
        nameClassName={classes.summaryViewLabelName}
        value={redirectUris}
        valueClassName={classnames("redirectUri", classes.summaryViewLabelValue)}
      />
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Metadata"
        nameClassName={classes.summaryViewLabelName}
        value={metadata}
        valueClassName={classnames("metadata", classes.summaryViewLabelValue)}
      />
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Authentication"
        nameClassName={classes.summaryViewLabelName}
        value={authentication}
        valueClassName={classnames("authentication", classes.summaryViewLabelValue)}
      />
      <SummaryViewLabel
        className={classes.summaryViewLabel}
        name="Protocol"
        nameClassName={classes.summaryViewLabelName}
        value={protocol}
        valueClassName={classnames("protocol", classes.summaryViewLabelValue)}
      />
      {children}
    </div>
  );
});

export default ReviewView;
