import React from "react";
import classnames from "classnames";
import { workloadInfo as styles } from "./styles";
import { TextField, DropdownMenu } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { formEventHandler, noop } from "@util";
import {
  CreateWorkloadRequest,
  CreateWorkloadRequestAttributesV3,
  DEFAULT_WORKLOAD_TYPE_LABELS,
  WorkloadRuntimeOption,
  WorkloadType,
  WorkloadTypeLabels,
} from "@data";
import AdvancedSettings from "./AdvancedSettings";

export enum WorkloadLanguage {
  NONE = "",
  JAVA = "Java",
  PYTHON = "Python",
}

export enum JavaWorkloadVersion {
  NONE = "",
  JAVA8 = "v1.8",
}

export enum PythonWorkloadVersion {
  NONE = "",
  PYTHON27 = "v2.7",
  PYTHON37 = "v3.7",
}

export type WorkloadLanguageVersion = JavaWorkloadVersion | PythonWorkloadVersion | "";

export const WORKLOAD_LANGUAGES: WorkloadLanguage[] = [
  WorkloadLanguage.JAVA,
];

export const JAVA_WORKLOAD_LANGUAGE_VERSION_LABELS: string[] = [
  JavaWorkloadVersion.JAVA8,
];

export const PYTHON_WORKLOAD_LANGUAGE_VERSION_LABELS: string[] = [
  PythonWorkloadVersion.PYTHON27,
  PythonWorkloadVersion.PYTHON37,
];

export const DEFAULT_WORKLOAD_TYPES: WorkloadType[] = [WorkloadType.FUNCTION, WorkloadType.QUERY];

const mapWorkloadRuntimeOptionToWorkloadLanguage = (runtime: WorkloadRuntimeOption): WorkloadLanguage => {
  switch (runtime) {
    case WorkloadRuntimeOption.JAVA8:
      return WorkloadLanguage.JAVA;
    case WorkloadRuntimeOption.PYTHON37:
    case WorkloadRuntimeOption.PYTHON27:
      return WorkloadLanguage.PYTHON;
    default:
      return WorkloadLanguage.NONE;
  }
};

const mapWorkloadRuntimeOptionToWorkloadLanguageVersion = (runtime: WorkloadRuntimeOption): WorkloadLanguageVersion => {
  switch (runtime) {
    case WorkloadRuntimeOption.JAVA8:
      return JavaWorkloadVersion.JAVA8;
    case WorkloadRuntimeOption.PYTHON37:
      return PythonWorkloadVersion.PYTHON37;
    case WorkloadRuntimeOption.PYTHON27:
      return PythonWorkloadVersion.PYTHON27;
    default:
      return "";
  }
};

const mapWorkloadLanguageToLabel = (language: WorkloadLanguage): string => language;

const mapWorkloadLanguageVersionToLabel = (version: string): React.ReactNode => {
  switch (version) {
    case JavaWorkloadVersion.JAVA8:
      return JavaWorkloadVersion.JAVA8;
    case PythonWorkloadVersion.PYTHON27:
      return PythonWorkloadVersion.PYTHON27;
    case PythonWorkloadVersion.PYTHON37:
      return PythonWorkloadVersion.PYTHON37;
    default:
      return "";
  }
};

const mapWorkloadLanguageVersionToWorkloadRuntimeOption = (version: WorkloadLanguageVersion): WorkloadRuntimeOption => {
  switch (version) {
    case JavaWorkloadVersion.JAVA8:
      return WorkloadRuntimeOption.JAVA8;
    case PythonWorkloadVersion.PYTHON27:
      return WorkloadRuntimeOption.PYTHON27;
    case PythonWorkloadVersion.PYTHON37:
      return WorkloadRuntimeOption.PYTHON37;
    default:
      return WorkloadRuntimeOption.NONE;
  }
};

export interface Model {
  data?: CreateWorkloadRequest;
  types?: WorkloadType[];
  typeLabels?: WorkloadTypeLabels;
}

export interface Actions {
  clearSelectedDataSets?: () => void;
  setWorkloadData?: (data: Partial<CreateWorkloadRequestAttributesV3>) => void;
  mapWorkloadTypeToLabel?: (type: WorkloadType) => React.ReactNode | string | null;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const TypeView = withStyles(styles)((props: Props) => {

  const {
    classes,
    data = CreateWorkloadRequest.EMPTY,
    types = DEFAULT_WORKLOAD_TYPES,
    typeLabels = DEFAULT_WORKLOAD_TYPE_LABELS,
    mapWorkloadTypeToLabel = React.useCallback((type: WorkloadType) =>
      typeLabels[type] || null, [typeLabels]),
    setWorkloadData = noop,
    clearSelectedDataSets = noop,
  } = props;

  const isFunctionType = React.useMemo(() => data.isFunctionType(), [data]);

  const [ selectedWorkloadLanguage, setSelectedWorkloadLanguage ] =
    React.useState<WorkloadLanguage>(mapWorkloadRuntimeOptionToWorkloadLanguage(data.getRuntime()));

  const [ selectedWorkloadLanguageVersion, setSelectedWorkloadLanguageVersion ] =
    React.useState<WorkloadLanguageVersion>(mapWorkloadRuntimeOptionToWorkloadLanguageVersion(data.getRuntime()));

  const [ selectedRuntime, setSelectedRuntime ] = React.useState<WorkloadRuntimeOption>(data.getRuntime());

  const languageVersionLabels = React.useMemo(() => {
    switch (selectedWorkloadLanguage) {
      case WorkloadLanguage.JAVA:
        return JAVA_WORKLOAD_LANGUAGE_VERSION_LABELS;
      case WorkloadLanguage.PYTHON:
        return PYTHON_WORKLOAD_LANGUAGE_VERSION_LABELS;
      default:
        return [];
    }
  }, [ selectedWorkloadLanguage ]);

  const entryPointHelperText = React.useMemo(() => {
    switch (selectedWorkloadLanguage) {
      case WorkloadLanguage.PYTHON:
        return "When the Python runtime environment is selected, the current value is in the [File name].[Function name] format. If the current value is index.entryPoint, then when the function is triggered the entry point function in the index.py file is executed.";
      case WorkloadLanguage.JAVA:
        return "When the Java runtime environment is selected, the request entry point must be the class that implements the com.signify.iot.platform.bdi.dataprocessing.DataProcessingWorkload interface.";
      default:
        return "Specifies the function in the file to be executed first when you invoke a function.";
    }
  }, [ selectedWorkloadLanguage ]);

  const entryPointPlaceholder = React.useMemo(() => {
    switch (selectedWorkloadLanguage) {
      case WorkloadLanguage.PYTHON:
        return "workload.entryPoint";
      case WorkloadLanguage.JAVA:
        return "com.signify.workload.WorkloadEventEntryPoint";
      default:
        return "Enter a request entry point";
    }
  }, [ selectedWorkloadLanguage ]);

  const showLanguageVersion = React.useMemo(() =>
      WorkloadLanguage.NONE !== selectedWorkloadLanguage,
    [ selectedWorkloadLanguage ]);

  const showEntryPoint = React.useMemo(() =>
      selectedRuntime !== WorkloadRuntimeOption.NONE,
    [ selectedRuntime ]);

  React.useEffect(() => {
    setSelectedRuntime(mapWorkloadLanguageVersionToWorkloadRuntimeOption(
      selectedWorkloadLanguageVersion));
  }, [
    selectedWorkloadLanguageVersion,
    setSelectedRuntime,
  ]);

  return (
    <div className={classnames("workloadType", classes.container)}>
      <label className={classes.title}>
        Configure Workload Type
      </label>
      <div className={classnames("typeContainer", classes.typeContainer)}>
        <DropdownMenu
          className={classnames("type", classes.dropdown, classes.selectType)}
          selectClassName={classes.dropdownMenuSelect}
          fullWidth={true}
          variant="outlined"
          dropdownMenuLabel="Type"
          dropdownMenuHint="Select the workload type"
          hideEmptyValue={true}
          dropdownMenuLabelClassName={classes.dropdownMenuLabel}
          mapValueToLabel={mapWorkloadTypeToLabel}
          emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
          values={types}
          selectedValue={data.getType()}
          setSelectedValue={type => {
            clearSelectedDataSets();
            setWorkloadData({
              "@type": WorkloadType[type],
              type: WorkloadType[type],
              data: { inputs: [], outputs: [] }
            });
          }}
        />
        {isFunctionType && (
          <React.Fragment>
            <DropdownMenu
              className={classnames("language", classes.dropdown, classes.language)}
              selectClassName={classes.dropdownMenuSelect}
              fullWidth={true}
              variant="outlined"
              dropdownMenuHint="Select language that the workload code will be written in"
              dropdownMenuLabel="Language"
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
              emptyValueLabel="Select language"
              emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
              values={WORKLOAD_LANGUAGES}
              mapValueToLabel={mapWorkloadLanguageToLabel}
              selectedValue={selectedWorkloadLanguage}
              setSelectedValue={language => {
                setWorkloadData({ runtime: WorkloadRuntimeOption.NONE, entryPoint: "" });
                setSelectedRuntime(WorkloadRuntimeOption.NONE);
                setSelectedWorkloadLanguageVersion("");
                setSelectedWorkloadLanguage(language as WorkloadLanguage);
              }}
            />
            {showLanguageVersion && (
              <DropdownMenu
                className={classnames("languageVersion", classes.dropdown, classes.languageVersion)}
                selectClassName={classes.dropdownMenuSelect}
                fullWidth={true}
                variant="outlined"
                dropdownMenuHint="Select language version that the workload code will be written in"
                dropdownMenuLabel="Version"
                dropdownMenuLabelClassName={classes.dropdownMenuLabel}
                emptyValueLabel="Select version"
                emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
                values={languageVersionLabels}
                mapValueToLabel={mapWorkloadLanguageVersionToLabel}
                selectedValue={selectedWorkloadLanguageVersion}
                setSelectedValue={language => {
                  setWorkloadData({ runtime: WorkloadRuntimeOption.NONE, entryPoint: "" });
                  setSelectedWorkloadLanguageVersion(language as WorkloadLanguageVersion);
                  setSelectedRuntime(mapWorkloadLanguageVersionToWorkloadRuntimeOption(
                    language as WorkloadLanguageVersion));
                }}
              />
            )}
            {showEntryPoint && (
              <TextField
                className={classnames("entryPoint", classes.entryPoint)}
                size="small"
                name="entryPoint"
                color="secondary"
                variant="outlined"
                label="Request Entry Point"
                disableSpecialChars={true}
                placeholder={entryPointPlaceholder}
                InputLabelProps={{ shrink: true }}
                helperText={entryPointHelperText}
                value={data.getEntryPoint()}
                onChange={formEventHandler(updatedValue => setWorkloadData({
                  runtime: selectedRuntime,
                  entryPoint: updatedValue,
                }))}
              />
            )}
            {isFunctionType && (
              <AdvancedSettings data={data} setWorkloadData={setWorkloadData} />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
});

export default TypeView;
