import { Record } from "immutable";
import { getStringValue } from "@util";
import { SecurityProtocol } from "@data/CreateSecurityServiceRegionalRequest";

export interface AuthenticateServiceSecurityAttributes {
  protocol: SecurityProtocol;
  data: {
    secret: string
  };
}

export interface AuthenticateServiceRequestAttributes {
  account: string;
  security: AuthenticateServiceSecurityAttributes;
}

export class AuthenticateServiceRequest extends Record({
  account: "",
  security: {
    protocol: SecurityProtocol.SHARED_SECRET,
    data: {
      secret: ""
    },
  },
}) implements AuthenticateServiceRequestAttributes {

  public static EMPTY: AuthenticateServiceRequest = new AuthenticateServiceRequest();

  public readonly account: string;
  public readonly security: AuthenticateServiceSecurityAttributes;

  public getAccount(): string {
    return getStringValue(this.account);
  }

  public getProtocol(): string {
    const { protocol: protocol = "" } = this.security || {};

    return getStringValue(protocol);
  }

  public getSecret(): string {
    const { data: { secret: secret = "" } } = this.security;

    return getStringValue(secret);
  }

  public getSecurity(): AuthenticateServiceSecurityAttributes {
    return this.security;
  }
}

export default AuthenticateServiceRequest;
