import { isEmptyString } from "@util";
import { createSelector } from "reselect";
import { DEFAULT_STATE, PasswordRecoverySchema } from "./reducers";
import { createSelectors, Selector } from "@base/createSelectors";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";

export const {
  userId: getUserId,
  emailTemplateId: getEmailTemplateId,
  getErrorMessage,
  getSuccessMessage,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isEmptyViewVisible,
  isSuccessMessageVisible,
  getAccessToken,
} = createSelectors<PasswordRecoverySchema>(moduleId, schemaKey, DEFAULT_STATE);

export const isUserIdValid: Selector<boolean> = createSelector(
  getUserId, userId => !isEmptyString(userId));

export const isEmailTemplateValid: Selector<boolean> = createSelector(
  getEmailTemplateId, (emailTemplateId: string) => !isEmptyString(emailTemplateId));
