import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { useAddVariables } from "@hooks";
import { isEmptyString, noop } from "@util";
import { WorkloadVariableSchema } from "@data";
import { WorkloadCompletionMetadata } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";

export interface WorkloadAddVariablesDialogModel extends PortalModuleDialogModel<string> {
  name?: string;
  version?: number;
  etag?: string;
}

export interface WorkloadAddVariablesDialogActions extends PortalModuleDialogActions<string> {
}

type Props = WithStyles<typeof styles> & WorkloadAddVariablesDialogModel & WorkloadAddVariablesDialogActions;

export const WorkloadAddVariablesDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    name = "",
    etag = "",
    version = 1,
    cancel: cancelDialog = noop,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  const [variables, setVariables] = React.useState<WorkloadVariableSchema[]>([]);

  const addVariable = React.useCallback((updatedItem: WorkloadVariableSchema) => {
    setVariables(variables.concat([updatedItem]));
  }, [setVariables, variables]);

  const editVariables = React.useCallback((updatedVariables: WorkloadVariableSchema[]) => {
    setVariables(updatedVariables);
  }, [setVariables]);

  const [{ showLoadingIndicator, ...otherModel }, { save, reset }] =
    useAddVariables({ name, version, etag, variables });

  const cancel = React.useCallback(() => {
    reset();
    cancelDialog();
    setVariables([]);
  }, [reset, cancelDialog, setVariables]);

  const onSuccessMessageShown = React.useCallback(() => {
    reset();
    onSuccess();
  }, [reset, onSuccess]);

  if (isEmptyString(name)) {
    return null;
  }

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      className={classnames("workloadAddVariablesDialog")}
      title={"Add Workload Completion Variables"}
      loading={showLoadingIndicator}
      continueButtonLabel={"Save"}
      continueButtonDisabled={!variables.length}
      onSuccessMessageShown={onSuccessMessageShown}
      confirm={save}
      cancel={cancel}
    >
      <div className={classnames("workloadVariablesContainer", classes.container)}>
        <label className={classnames("title", classes.title)}>
          Enter all the new variables in the table below.
        </label>
        <label className={classnames("title", classes.title)}>
          These new variables will be added to the existing list of variables.
        </label>
        <WorkloadCompletionMetadata
          title=""
          hideActions={true}
          variables={variables}
          showAddVariables={true}
          showEditVariables={true}
          showDeleteVariables={true}
          customAddVariable={addVariable}
          customEditVariable={editVariables}
        />
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadAddVariablesDialog;
