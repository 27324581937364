import createStyles from "@material-ui/core/styles/createStyles";
import { StyleRules } from "@material-ui/core/styles/withStyles";
import { Color, INFORMATION_BANNER_HEIGHT } from "@components";

export const closeIcon: StyleRules = {
  root: {
    color: Color.WHITE,
    width: 20,
    height: 20,
  },
};

export const errorIcon: StyleRules = {
  root: {
    color: Color.WHITE,
    width: 20,
    height: 20,
    marginRight: 15,
  },
};

export const signifyLogo: StyleRules = {
  root: {
    width: 445,
    height: 155,
    display: "block",
    alignSelf: "center",
  },
};

export const styles = () =>
  createStyles({
    container: {
      backgroundColor: Color.BLACK5,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "center",
      minWidth: 360,
      position: "relative",
    },
    informationBannerVisible: {
      marginTop: INFORMATION_BANNER_HEIGHT,
    },
    content: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      margin: 30,
      minWidth: 300,
    },
    loading: {
      background: "rgba(105, 105, 105, 0.5)",
    },
    blur: {
      filter: "blur(5px)",
    },
    logo: {
      width: 70.63,
      height: 90,
      display: "block",
      alignSelf: "center",
    },
    title: {
      color: Color.WHITE,
      textAlign: "center",
      fontFamily: "Roboto, sans-serif",
      fontSize: 28,
      fontWeight: 300,
      margin: "19px 0 0 0",
    },
    progressIndicator: {
      position: "fixed",
      zIndex: 999,
      width: "100px",
      height: "100px",
      margin: "auto",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    termsOfServiceLink: {
      marginTop: 15,
    },
    forgotPasswordLink: {

    },
    errorSnackbar: {

    },
    errorSnackbarContent: {
      backgroundColor: "#d03133",
      lineHeight: "20px",
      maxWidth: "none",
    },
    errorSnackbarContentMessage: {
      color: Color.WHITE,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    tabsContainer: {
      backgroundColor: Color.TRANSPARENT,
      marginBottom: 16,
    },
    tabs: {

    },
    tabsIndicator: {
      display: "none",
    },
    tab: {
      opacity: 1,
      color: "rgba(255, 255, 255, 0.75)",
      fontSize: 16,
      fontWeight: 500,
      minWidth: 0,
    },
    selectedTab: {
      color: Color.SIGNIFY_GREEN,
      backgroundColor: Color.BLACK5,
      boxShadow: `0 -1px 0 ${Color.SIGNIFY_GREEN} inset`,
    },
  });

export default styles;
