import React from "react";
import CostReports, {
  CostReportsActions,
  CostReportsModel,
} from "../components/CostReports";

interface ContainerModel extends CostReportsModel {
}

interface ContainerActions extends CostReportsActions {
}

type Model = ContainerModel;
type Actions = ContainerActions;
type Props = Model & Actions;

const CostReportsContainer = (props: Props) => {

  return <CostReports />;
};

export default CostReportsContainer;
