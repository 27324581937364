import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    loadingView: {
      color: Color.SIGNIFY_GREEN,
    },
    errorView: {
      paddingBottom: 10,
      marginBottom: 35,
      "& label.title, & label.message": {
        marginTop: 10,
      },
      "& button.tryAgainButton": {
        color: Color.DARK_RED,
      },
    },
  });

export default styles;
