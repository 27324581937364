import { Record } from "immutable";
import Workload from "@data/Workload";

export interface EditWorkloadRequestAttributes {
  description?: string;
  tags?: string[];
}

export class EditWorkloadRequest extends Record({
  description: "",
  tags: [],
}) implements EditWorkloadRequestAttributes {

  public static EMPTY: EditWorkloadRequest = new EditWorkloadRequest();

  public readonly description: string;
  public readonly tags: string[];

  public static from(attributes: Workload): EditWorkloadRequest {
    return new EditWorkloadRequest({
      description: attributes.getDescription(),
      tags: attributes.getTags(),
    });
  }

  public getDescription(): string {
    return this.description;
  }

  public getTags(): string[] {
    return this.tags;
  }
}
