import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { JsonEditorModel, JsonEditor } from "@components/json-editor";
import { DeviceEnrollmentWizardSelectors } from "../../selectors";

const mapStateToProps = (state: AppSchema): JsonEditorModel => ({
  className: "deviceTypeJsonView",
  json: DeviceEnrollmentWizardSelectors.getDeviceTypeAsJson(state),
  readOnly: true,
  width: "auto",
  height: "300px",
  name: "deviceTypeJsonView",
  fontSize: 12
});

export default connect<JsonEditorModel, {}, JsonEditorModel>(
  mapStateToProps,
)(JsonEditor);
