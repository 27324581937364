import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import {
  AccessRequestPrincipalType,
  AccessRequestType,
  CreateDataAccessRequest,
  DeviceTypeListItem,
  SecurityServiceRegional,
} from "@data";
import { equalsIgnoreCase, getStringValue, isEmptyString, noop } from "@util";
import { useCreateDataAccessRequest, useDataSet } from "@hooks";
import { WaitForApiRequestView } from "@components";
import DataAccessRequestWizard, {
  Actions,
  DataAccessRequestWizardStep,
  DEFAULT_STEP_LABELS,
  DEVICE_TYPE_STEP_LABELS,
  Model,
} from "../components/DataAccessRequestWizard";
import CertificateView from "../components/CertificateView";
import PrincipalView, { DEFAULT_PRINCIPAL_TYPES } from "../components/PrincipalView";
import ServicePrincipalView from "./ServicePrincipalView";
import DeviceTypeView from "./DeviceTypeView";
import IotCloudRoleView from "./IotCloudRoleView";
import TypeView, {
  CROSS_ACCOUNT_ACCESS_REQUEST_TYPES_HELPER_TEXT,
  DEFAULT_ACCESS_REQUEST_TYPES,
  DEFAULT_ACCESS_REQUEST_TYPES_HELPER_TEXT,
} from "../components/TypeView";
import DeviceTypeAccessReasonView from "../components/DeviceTypeAccessReasonView";
import IotCloudStorageAccessReasonView from "../components/IotCloudStorageAccessReasonView";
import IotCloudStorageView from "../components/IotCloudStorageView";
import ReviewView from "../components/ReviewView";
import { getCurrentAccountId } from "@main/selectors";
import { isValidAwsAccountId } from "../helpers";

const ACCOUNT_ID_REGEX = new RegExp(/lrn.*[:]([0-9]+)/);

export interface ContainerModel extends Model {
  dataSetId?: string;
  snackbarId?: string;
  trainingCompleted?: boolean;
  accountId?: string;
}

export interface ContainerActions extends Actions {
  showDataAccessRequestDetails?: (accessRequestId: string) => void;
  refreshDataCatalogModule?: () => void;
}

type Props = ContainerModel & ContainerActions;

export const DataAccessRequestWizardContainer = (props: Props) => {

  const {
    dataSetId = "",
    accountId = "",
    trainingCompleted,
    showDataAccessRequestDetails = noop,
    refreshDataCatalogModule = noop,
    ...otherProps
  } = props;

  const initialData = React.useMemo(() =>
    CreateDataAccessRequest.fromDataSetId(dataSetId), [dataSetId]);

  const dataSetAlias = React.useMemo(() =>
    getStringValue(dataSetId.split(":").pop()), [dataSetId]);

  const dataSetAccountId = React.useMemo(() => {
    const match = dataSetId.match(ACCOUNT_ID_REGEX);

    if (!Array.isArray(match) || match.length === 0) {
      return "";
    } else {
      return getStringValue(match.pop());
    }
  }, [dataSetId]);

  const [{dataSet, showProgressIndicator, ...model}, {refresh}] = useDataSet({
    dataSetAlias,
    accountId: dataSetAccountId
  });

  const dataSetGroup = React.useMemo(() => dataSet.getDataSetGroup(), [dataSet]);

  const [initialDataWithGroup, setInitialDataWithGroup] =
    React.useState<CreateDataAccessRequest>(new CreateDataAccessRequest({
    ...initialData.toJS(),
  }));

  const [data, setData] = React.useState<CreateDataAccessRequest>(new CreateDataAccessRequest({
    ...initialData.toJS(),
  }));

  const [error, setError] = React.useState("");

  const updateData = React.useCallback((accessRequest) => {
    setError("");
    setData(accessRequest);
  }, [setError, setData]);

  const [certificateUploaded, setCertificateUploaded] = React.useState(trainingCompleted);

  const isCrossAccountRequest = React.useMemo(() =>
    !equalsIgnoreCase(accountId, data.getAccountId()), [accountId, data]);

  const accessRequestTypes = React.useMemo<AccessRequestType[]>(() =>
      isCrossAccountRequest ? [AccessRequestType.READ] : DEFAULT_ACCESS_REQUEST_TYPES,
    [isCrossAccountRequest]);

  const accessRequestTypesHelperText = React.useMemo(() =>
      isCrossAccountRequest
        ? CROSS_ACCOUNT_ACCESS_REQUEST_TYPES_HELPER_TEXT
        : DEFAULT_ACCESS_REQUEST_TYPES_HELPER_TEXT,
    [isCrossAccountRequest]);

  const serviceId = React.useMemo(() =>
    data.getServicePrincipalIdentifier().split(":").pop(), [data]);

  const deviceNamespace = React.useMemo(() =>
    data.getDeviceNamespace(), [data]);

  const name = React.useMemo(() =>
    data.getDeviceType(), [data]);

  const trustedAccountsForCloudRole = React.useMemo(() =>
    data.getTrustedAccountsForCloudRole(), [data]);

  const isAccessForExistingCloudResource = React.useMemo(() =>
    data.isDataAccessRequestForExistingCloudResource(), [data]);

  const cloudRoleIdentifier = React.useMemo(() =>
    data.getCloudRoleIdentifier(), [data]);

  const principalAccountForCloudStorage = React.useMemo(() =>
    data.getPrincipalAccountForCloudStorage(), [data]);

  const principalAccountForCloudStorageError = React.useMemo(() =>
      principalAccountForCloudStorage.length > 0 && !isValidAwsAccountId(principalAccountForCloudStorage)
        ? "Invalid" : "",
    [principalAccountForCloudStorage]);

  React.useEffect(() => {
    setData(new CreateDataAccessRequest( {
      ...data.toJS(),
      dataSetGroup
    }));
    setInitialDataWithGroup(new CreateDataAccessRequest( {
      ...initialData.toJS(),
      dataSetGroup
    }));
  }, [dataSetGroup]);

  const [{ accessRequestId, errorMessage, ...apiRequestState }, { createDataAccessRequest }] =
    useCreateDataAccessRequest({
      data,
      refreshDataCatalogModule,
    });

  const onSuccess = React.useCallback(() => {
    showDataAccessRequestDetails(accessRequestId);
  }, [showDataAccessRequestDetails, accessRequestId]);

  const setAccessRequestType = React.useCallback((accessRequestType: AccessRequestType) =>
    updateData(new CreateDataAccessRequest({
      ...data.toJS(),
      accessRequestType,
    })), [data, updateData]);

  const setPrincipalType = React.useCallback((accessRequestPrincipalType: AccessRequestPrincipalType) =>
    updateData(new CreateDataAccessRequest({
      ...data.toJS(),
      accessRequestPrincipalType,
      ...(accessRequestPrincipalType !== AccessRequestPrincipalType.IOT_USER_PRINCIPAL ? ({}) : ({
        accessRequestType: AccessRequestType.READ,
        servicePrincipalIdentifier: undefined,
        deviceType: undefined,
        deviceNamespace: undefined,
        isAccessForExistingCloudResource: undefined,
        trustedAccountsForCloudRole: undefined,
        cloudRoleIdentifier: undefined,
        principalAccountForCloudStorage: undefined,
      })),
      ...(accessRequestPrincipalType !== AccessRequestPrincipalType.IOT_SERVICE_PRINCIPAL ? ({}) : ({
        accessRequestType: AccessRequestType.READ,
        isAccessForExistingCloudResource: undefined,
        deviceType: undefined,
        deviceNamespace: undefined,
        trustedAccountsForCloudRole: undefined,
        cloudRoleIdentifier: undefined,
        principalAccountForCloudStorage: undefined,
      })),
      ...(accessRequestPrincipalType !== AccessRequestPrincipalType.IOT_DEVICE_TYPE ? ({}) : ({
        accessRequestType: AccessRequestType.WRITE,
        servicePrincipalIdentifier: undefined,
        isAccessForExistingCloudResource: undefined,
        trustedAccountsForCloudRole: undefined,
        cloudRoleIdentifier: undefined,
        principalAccountForCloudStorage: undefined,
      })),
      ...(accessRequestPrincipalType !== AccessRequestPrincipalType.IOT_CLOUD_ROLE ? ({}) : ({
        accessRequestType: AccessRequestType.READ,
        servicePrincipalIdentifier: undefined,
        deviceType: undefined,
        deviceNamespace: undefined,
        principalAccountForCloudStorage: undefined,
        ...(data.isDataAccessRequestForExistingCloudResource() ? ({}) : ({
          cloudRoleIdentifier: undefined,
          isAccessForExistingCloudResource: false,
        })),
        ...(!data.isDataAccessRequestForExistingCloudResource() ? ({}) : ({
          isAccessForExistingCloudResource: true,
        })),
      })),
      ...(accessRequestPrincipalType !== AccessRequestPrincipalType.IOT_CLOUD_STORAGE ? ({}) : ({
        accessRequestType: AccessRequestType.READ,
        servicePrincipalIdentifier: undefined,
        deviceType: undefined,
        deviceNamespace: undefined,
        isAccessForExistingCloudResource: undefined,
        trustedAccountsForCloudRole: undefined,
        cloudRoleIdentifier: undefined,
      })),
    })), [data, updateData]);

  const setServicePrincipalIdentifier = React.useCallback(
    (service: SecurityServiceRegional = SecurityServiceRegional.EMPTY) =>
      updateData(new CreateDataAccessRequest({
      ...data.toJS(),
      servicePrincipalIdentifier:
        `lrn:iot:aam::${accountId}:principal:service:${service.getId()}`,
    })), [data, accountId, updateData]);

  const setDeviceType = React.useCallback((deviceType: DeviceTypeListItem = DeviceTypeListItem.EMPTY) =>
    updateData(new CreateDataAccessRequest({
      ...data.toJS(),
      deviceNamespace: `${deviceType.getTypeIdentity().split(":")[0]}`,
      deviceType: `${deviceType.getTypeIdentity().split(":")[1]}`,
    })), [data, accountId, updateData]);

  const setTrustedAccountsForCloudRole = React.useCallback((accounts?: string[]) =>
    updateData(new CreateDataAccessRequest({
      ...data.toJS(),
      trustedAccountsForCloudRole: accounts,
    })), [data, updateData]);

  const setPrincipalAccountForCloudStorage = React.useCallback((accounts?: string) =>
    updateData(new CreateDataAccessRequest({
      ...data.toJS(),
      principalAccountForCloudStorage: accounts,
    })), [data, updateData]);

  const setCloudRoleIdentifier = React.useCallback((identifier?: string) =>
    updateData(new CreateDataAccessRequest({
      ...data.toJS(),
      ...(isEmptyString(identifier) ? ({
        cloudRoleIdentifier: undefined,
        isAccessForExistingCloudResource: false,
      }) : ({
        trustedAccountsForCloudRole: undefined,
        cloudRoleIdentifier: getStringValue(identifier),
        isAccessForExistingCloudResource: true,
      })),
    })), [data, updateData]);

  const setReason = React.useCallback((reason: string) =>
    updateData(new CreateDataAccessRequest({
      ...data.toJS(),
      reason,
    })), [data, updateData]);

  const certificateView = React.useMemo(() => (
    <CertificateView
      certificateUploaded={certificateUploaded}
      onSuccess={() => setCertificateUploaded(true)}
    />
  ), [certificateUploaded, setCertificateUploaded]);

  const typeView = React.useMemo(() => {
    if (data.isDeviceTypeRequest()) {
      return (
        <DeviceTypeAccessReasonView
          reason={data.reason}
          setReason={setReason}
        />
        );
    } else if (data.isIotCloudStorageRequest()) {
      return (
        <IotCloudStorageAccessReasonView
          reason={data.reason}
          setReason={setReason}
        />
      );
    } else {
      return (
        <TypeView
          accessRequestTypes={accessRequestTypes}
          accessRequestTypesHelperText={accessRequestTypesHelperText}
          accessRequestType={data.getAccessRequestType()}
          setAccessRequestType={setAccessRequestType}
          reason={data.reason}
          setReason={setReason}
        />
      );
    }
}, [
    data,
    accessRequestTypes,
    accessRequestTypesHelperText,
    setAccessRequestType,
    setReason,
  ]);

  const principalTypes = React.useMemo(() =>
      !isCrossAccountRequest
        ? DEFAULT_PRINCIPAL_TYPES
        : DEFAULT_PRINCIPAL_TYPES
          // Cross account data access requests are only permitted to READ; whereas, device type
          // data access requests are only permitted to WRITE, so we cannot allow users to submit
          // cross account device type data access requests.
          .filter(principalType => principalType !== AccessRequestPrincipalType.IOT_DEVICE_TYPE),
    [isCrossAccountRequest]);

  const principalView = React.useMemo(() => (
    <PrincipalView
      principalType={data.getAccessRequestPrincipalType()}
      principalTypes={principalTypes}
      setPrincipalType={setPrincipalType}
    />
  ), [data, principalTypes, setPrincipalType]);

  const servicePrincipalView = React.useMemo(() => (
    <ServicePrincipalView
      serviceId={serviceId}
      setSelectedService={setServicePrincipalIdentifier}
    />
  ), [serviceId, setServicePrincipalIdentifier]);

  const deviceTypeView = React.useMemo(() => (
    <DeviceTypeView
      deviceNamespace={deviceNamespace}
      deviceType={name}
      accountId={accountId}
      setSelectedType={setDeviceType}
    />
  ), [
    deviceNamespace,
    name,
    accountId,
    setDeviceType
  ]);

  const iotCloudRoleView = React.useMemo(() => (
    <IotCloudRoleView
      isAccessForExistingCloudResource={isAccessForExistingCloudResource}
      trustedAccountsForCloudRole={trustedAccountsForCloudRole}
      cloudRoleIdentifier={cloudRoleIdentifier}
      setTrustedAccountsForCloudRole={setTrustedAccountsForCloudRole}
      setCloudRoleIdentifier={setCloudRoleIdentifier}
    />
  ), [
    trustedAccountsForCloudRole,
    cloudRoleIdentifier,
    setTrustedAccountsForCloudRole,
    setCloudRoleIdentifier,
  ]);

  const iotCloudStorageView = React.useMemo(() => (
    <IotCloudStorageView
      awsAccountId={principalAccountForCloudStorage}
      awsAccountIdError={principalAccountForCloudStorageError}
      setAwsAccountId={setPrincipalAccountForCloudStorage}
    />
  ), [
    principalAccountForCloudStorage,
    principalAccountForCloudStorageError,
    setPrincipalAccountForCloudStorage
  ]);

  const reviewView = React.useMemo(() => (
    <ReviewView
      accountId={data.getAccountId()}
      dataSetAlias={data.getDataSetAlias()}
      accessRequestType={data.getAccessRequestType()}
      accessRequestPrincipalType={data.getAccessRequestPrincipalType()}
      servicePrincipalIdentifier={data.getServicePrincipalIdentifier()}
      deviceNamespace={data.getDeviceNamespace()}
      deviceType={data.getDeviceType()}
      principalAccountForCloudStorage={data.getPrincipalAccountForCloudStorage()}
      iotCloudRole={data.isIotCloudRole()}
      trustedAccountsForCloudRole={data.getTrustedAccountsForCloudRole().join(", ")}
      isAccessForExistingCloudResource={data.isDataAccessRequestForExistingCloudResource()}
      cloudRoleIdentifier={data.getCloudRoleIdentifier()}
      reason={data.getReason()}
      dataSetGroup={dataSetGroup}
    />
  ), [data, dataSetGroup]);

  const steps = React.useMemo(() => {
    return ([] as DataAccessRequestWizardStep[])
      .concat(trainingCompleted ? [] : [DataAccessRequestWizardStep.CERTIFICATE])
      .concat(DataAccessRequestWizardStep.PRINCIPAL)
      .concat(!data.isServicePrincipalRequest() ? [] : DataAccessRequestWizardStep.SERVICE_PRINCIPAL)
      .concat(!data.isDeviceTypeRequest() ? [] : DataAccessRequestWizardStep.DEVICE_TYPE)
      .concat(!data.isIotCloudRole() ? [] : DataAccessRequestWizardStep.IOT_CLOUD_ROLE)
      .concat(!data.isIotCloudStorageRequest() ? [] : DataAccessRequestWizardStep.IOT_CLOUD_STORAGE)
      .concat(DataAccessRequestWizardStep.TYPE)
      .concat(DataAccessRequestWizardStep.REVIEW);
  }, [trainingCompleted, data]);

  const stepLabels = React.useMemo(() =>
      data.isDeviceTypeRequest() || data.isIotCloudStorageRequest() ? DEVICE_TYPE_STEP_LABELS : DEFAULT_STEP_LABELS,
    [data]);

  const disabledSteps = React.useMemo(() => {
    return steps.filter(step => {
      switch (step) {
        case DataAccessRequestWizardStep.PRINCIPAL:
          return !certificateUploaded;
        case DataAccessRequestWizardStep.TYPE:
          return !certificateUploaded
            || (data.isServicePrincipalRequest() && isEmptyString(serviceId))
            || (data.isDeviceTypeRequest() && isEmptyString(deviceNamespace))
            || (data.isIotCloudStorageRequest() && !isValidAwsAccountId(principalAccountForCloudStorage))
            || (data.isIotCloudRole()
              && (!isAccessForExistingCloudResource
                && trustedAccountsForCloudRole.length === 0))
            || (data.isIotCloudRole()
              && (isAccessForExistingCloudResource
                && isEmptyString(cloudRoleIdentifier)));
        case DataAccessRequestWizardStep.REVIEW:
          return !certificateUploaded
            || (data.isServicePrincipalRequest() && isEmptyString(serviceId))
            || (data.isDeviceTypeRequest() && isEmptyString(deviceNamespace))
            || (data.isIotCloudStorageRequest() && !isValidAwsAccountId(principalAccountForCloudStorage))
            || (data.isIotCloudRole()
              && (!isAccessForExistingCloudResource
                && trustedAccountsForCloudRole.length === 0))
            || (data.isIotCloudRole()
              && (isAccessForExistingCloudResource
                && isEmptyString(cloudRoleIdentifier)))
            || !data.hasReason();
        default:
          return false;
      }
    });
  }, [
    steps,
    certificateUploaded,
    data,
    serviceId,
    deviceNamespace,
    principalAccountForCloudStorage,
    isAccessForExistingCloudResource,
    trustedAccountsForCloudRole,
    cloudRoleIdentifier,
  ]);

  const mapStepToView = React.useCallback((step: DataAccessRequestWizardStep) => {
    switch (step) {
      case DataAccessRequestWizardStep.CERTIFICATE:
        return certificateView;
      case DataAccessRequestWizardStep.PRINCIPAL:
        return principalView;
      case DataAccessRequestWizardStep.SERVICE_PRINCIPAL:
        return servicePrincipalView;
      case DataAccessRequestWizardStep.DEVICE_TYPE:
        return deviceTypeView;
      case DataAccessRequestWizardStep.IOT_CLOUD_ROLE:
        return iotCloudRoleView;
    case DataAccessRequestWizardStep.IOT_CLOUD_STORAGE:
      return iotCloudStorageView;
      case DataAccessRequestWizardStep.TYPE:
        return typeView;
      case DataAccessRequestWizardStep.REVIEW:
        return reviewView;
      default:
        return null;
    }
  }, [
    certificateView,
    principalView,
    servicePrincipalView,
    deviceTypeView,
    iotCloudRoleView,
    iotCloudStorageView,
    typeView,
    reviewView,
  ]);

  React.useEffect(() => setError(errorMessage), [errorMessage]);

  return (
    <WaitForApiRequestView
      {...model}
      loadingMessage="Loading Data Access Request Wizard..."
      retry={refresh}
      showLoadingIndicator={showProgressIndicator}
    >
      <DataAccessRequestWizard
        {...otherProps}
        {...apiRequestState}
        currentState={data}
        defaultState={initialDataWithGroup}
        steps={steps}
        stepLabels={stepLabels}
        disabledSteps={disabledSteps}
        mapStepToView={mapStepToView}
        save={createDataAccessRequest}
        onSuccess={onSuccess}
        errorMessage={error}
      />
    </WaitForApiRequestView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  accountId: getCurrentAccountId(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(DataAccessRequestWizardContainer);
