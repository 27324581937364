import { ManagedPolicyInfoAttributes } from "@data";
import { GetManagedPoliciesResponse, AuthorizationServiceClient } from "@network";
import { useApiRequestAnalytics } from "@hooks";

export type GetManagedPoliciesApi = () => Promise<GetManagedPoliciesResponse>;

export type GetManagedPolicyApi = (policyName: string) => Promise<ManagedPolicyInfoAttributes>;

export type AttachManagedPolicyToServiceApi =
  (serviceId: string, policyName: string, accountId: string) => Promise<void>;

export type DetachManagedPolicyFromServiceApi =
  (serviceId: string, policyName: string, accountId: string) => Promise<void>;

export type AttachManagedPolicyToGroupApi =
  (groupName: string, policyName: string, accountId: string) => Promise<void>;

export type DetachManagedPolicyFromGroupApi =
  (groupName: string, policyName: string, accountId: string) => Promise<void>;

export type AttachManagedPolicyToUserApi =
  (userId: string, policyName: string, accountId: string) => Promise<void>;

export type DetachManagedPolicyFromUserApi =
  (userId: string, policyName: string, accountId: string) => Promise<void>;

export interface ManagedPolicyClientApi {
  getManagedPolicies: GetManagedPoliciesApi;
  getManagedPolicy: GetManagedPolicyApi;
  attachManagedPolicyToService: AttachManagedPolicyToServiceApi;
  detachManagedPolicyFromService: DetachManagedPolicyFromServiceApi;
  attachManagedPolicyToGroup: AttachManagedPolicyToGroupApi;
  detachManagedPolicyFromGroup: DetachManagedPolicyFromGroupApi;
  attachManagedPolicyToUser: AttachManagedPolicyToUserApi;
  detachManagedPolicyFromUser: DetachManagedPolicyFromUserApi;
}

enum ActionType {
  GET_MANAGED_POLICIES_REQUEST = "managed_policy_client/get_managed_policies_request",
  GET_MANAGED_POLICIES_SUCCESS = "managed_policy_client/get_managed_policies_success",
  GET_MANAGED_POLICIES_FAILED = "managed_policy_client/get_managed_policies_failed",

  GET_MANAGED_POLICY_REQUEST = "managed_policy_client/get_managed_policy_request",
  GET_MANAGED_POLICY_SUCCESS = "managed_policy_client/get_managed_policy_success",
  GET_MANAGED_POLICY_FAILED = "managed_policy_client/get_managed_policy_failed",

  ATTACH_MANAGED_POLICY_TO_SERVICE_REQUEST = "managed_policy_client/attach_managed_policy_to_service_request",
  ATTACH_MANAGED_POLICY_TO_SERVICE_SUCCESS = "managed_policy_client/attach_managed_policy_to_service_success",
  ATTACH_MANAGED_POLICY_TO_SERVICE_FAILED = "managed_policy_client/attach_managed_policy_to_service_failed",

  DETACH_MANAGED_POLICY_FROM_SERVICE_REQUEST = "managed_policy_client/detach_managed_policy_from_service_request",
  DETACH_MANAGED_POLICY_FROM_SERVICE_SUCCESS = "managed_policy_client/detach_managed_policy_from_service_success",
  DETACH_MANAGED_POLICY_FROM_SERVICE_FAILED = "managed_policy_client/detach_managed_policy_from_service_failed",

  ATTACH_MANAGED_POLICY_TO_GROUP_REQUEST = "managed_policy_client/attach_managed_policy_to_group_request",
  ATTACH_MANAGED_POLICY_TO_GROUP_SUCCESS = "managed_policy_client/attach_managed_policy_to_group_success",
  ATTACH_MANAGED_POLICY_TO_GROUP_FAILED = "managed_policy_client/attach_managed_policy_to_group_failed",

  DETACH_MANAGED_POLICY_FROM_GROUP_REQUEST = "managed_policy_client/detach_managed_policy_from_group_request",
  DETACH_MANAGED_POLICY_FROM_GROUP_SUCCESS = "managed_policy_client/detach_managed_policy_from_group_success",
  DETACH_MANAGED_POLICY_FROM_GROUP_FAILED = "managed_policy_client/detach_managed_policy_from_group_failed",

  ATTACH_MANAGED_POLICY_TO_USER_REQUEST = "managed_policy_client/attach_managed_policy_to_user_request",
  ATTACH_MANAGED_POLICY_TO_USER_SUCCESS = "managed_policy_client/attach_managed_policy_to_user_success",
  ATTACH_MANAGED_POLICY_TO_USER_FAILED = "managed_policy_client/attach_managed_policy_to_user_failed",

  DETACH_MANAGED_POLICY_FROM_USER_REQUEST = "managed_policy_client/detach_managed_policy_from_user_request",
  DETACH_MANAGED_POLICY_FROM_USER_SUCCESS = "managed_policy_client/detach_managed_policy_from_user_success",
  DETACH_MANAGED_POLICY_FROM_USER_FAILED = "managed_policy_client/detach_managed_policy_from_user_failed",
}

export const useGetManagedPoliciesApi = (): GetManagedPoliciesApi =>
  useApiRequestAnalytics(AuthorizationServiceClient.getManagedPolicies, {
    REQUEST_EVENT: ActionType.GET_MANAGED_POLICIES_REQUEST,
    SUCCESS_EVENT: ActionType.GET_MANAGED_POLICIES_SUCCESS,
    ERROR_EVENT: ActionType.GET_MANAGED_POLICIES_FAILED,
  });

export const useGetManagedPolicyApi = (): GetManagedPolicyApi =>
  useApiRequestAnalytics(AuthorizationServiceClient.getManagedPolicy, {
    REQUEST_EVENT: ActionType.GET_MANAGED_POLICY_REQUEST,
    SUCCESS_EVENT: ActionType.GET_MANAGED_POLICY_SUCCESS,
    ERROR_EVENT: ActionType.GET_MANAGED_POLICY_FAILED,
  });

export const useAttachManagedPolicyToServiceApi = (): AttachManagedPolicyToServiceApi =>
  useApiRequestAnalytics(AuthorizationServiceClient.attachManagedPolicyToService, {
    REQUEST_EVENT: ActionType.ATTACH_MANAGED_POLICY_TO_SERVICE_REQUEST,
    SUCCESS_EVENT: ActionType.ATTACH_MANAGED_POLICY_TO_SERVICE_SUCCESS,
    ERROR_EVENT: ActionType.ATTACH_MANAGED_POLICY_TO_SERVICE_FAILED,
  });

export const useDetachManagedPolicyFromServiceApi = (): DetachManagedPolicyFromServiceApi =>
  useApiRequestAnalytics(AuthorizationServiceClient.detachManagedPolicyFromService, {
    REQUEST_EVENT: ActionType.DETACH_MANAGED_POLICY_FROM_SERVICE_REQUEST,
    SUCCESS_EVENT: ActionType.DETACH_MANAGED_POLICY_FROM_SERVICE_SUCCESS,
    ERROR_EVENT: ActionType.DETACH_MANAGED_POLICY_FROM_SERVICE_FAILED,
  });

export const useAttachManagedPolicyToUserApi = (): AttachManagedPolicyToUserApi =>
  useApiRequestAnalytics(AuthorizationServiceClient.attachManagedPolicyToUser, {
    REQUEST_EVENT: ActionType.ATTACH_MANAGED_POLICY_TO_USER_REQUEST,
    SUCCESS_EVENT: ActionType.ATTACH_MANAGED_POLICY_TO_USER_SUCCESS,
    ERROR_EVENT: ActionType.ATTACH_MANAGED_POLICY_TO_USER_FAILED,
  });

export const useDetachManagedPolicyFromUserApi = (): DetachManagedPolicyFromUserApi =>
  useApiRequestAnalytics(AuthorizationServiceClient.detachManagedPolicyFromUser, {
    REQUEST_EVENT: ActionType.DETACH_MANAGED_POLICY_FROM_USER_REQUEST,
    SUCCESS_EVENT: ActionType.DETACH_MANAGED_POLICY_FROM_USER_SUCCESS,
    ERROR_EVENT: ActionType.DETACH_MANAGED_POLICY_FROM_USER_FAILED,
  });

export const useAttachManagedPolicyToGroupApi = (): AttachManagedPolicyToGroupApi =>
  useApiRequestAnalytics(AuthorizationServiceClient.attachManagedPolicyToGroup, {
    REQUEST_EVENT: ActionType.ATTACH_MANAGED_POLICY_TO_GROUP_REQUEST,
    SUCCESS_EVENT: ActionType.ATTACH_MANAGED_POLICY_TO_GROUP_SUCCESS,
    ERROR_EVENT: ActionType.ATTACH_MANAGED_POLICY_TO_GROUP_FAILED,
  });

export const useDetachManagedPolicyFromGroupApi = (): DetachManagedPolicyFromGroupApi =>
  useApiRequestAnalytics(AuthorizationServiceClient.detachManagedPolicyFromGroup, {
    REQUEST_EVENT: ActionType.DETACH_MANAGED_POLICY_FROM_GROUP_REQUEST,
    SUCCESS_EVENT: ActionType.DETACH_MANAGED_POLICY_FROM_GROUP_SUCCESS,
    ERROR_EVENT: ActionType.DETACH_MANAGED_POLICY_FROM_GROUP_FAILED,
  });

export const useManagedPolicyClient = (): ManagedPolicyClientApi => ({
  getManagedPolicies: useGetManagedPoliciesApi(),
  getManagedPolicy: useGetManagedPolicyApi(),
  attachManagedPolicyToService: useAttachManagedPolicyToServiceApi(),
  detachManagedPolicyFromService: useDetachManagedPolicyFromServiceApi(),
  attachManagedPolicyToGroup: useAttachManagedPolicyToGroupApi(),
  detachManagedPolicyFromGroup: useDetachManagedPolicyFromGroupApi(),
  attachManagedPolicyToUser: useAttachManagedPolicyToUserApi(),
  detachManagedPolicyFromUser: useDetachManagedPolicyFromUserApi(),
});

export { ActionType as ManagedPolicyClientActionType };

export default useManagedPolicyClient;
