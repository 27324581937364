import React from "react";
import AppSchema from "@schemas";
import { connect } from "react-redux";
import { ManagedPolicy, Policy } from "@data";
import {
  isCustomPoliciesViewActive,
  isManagedPoliciesViewActive,
} from "../selectors";
import { showCustomPoliciesView, showManagedPoliciesView } from "../actions";
import {
  EffectivePoliciesActions,
  EffectivePoliciesModel,
  EffectivePoliciesView,
} from "@components";
import CustomPoliciesView from "./CustomPoliciesView";
import ManagedPoliciesView from "./ManagedPoliciesView";
import ImplicitPermissionStatements from "./ImplicitPermissionStatements";

interface ContainerModel extends EffectivePoliciesModel {
  hidden?: boolean;
}

interface ContainerActions extends EffectivePoliciesActions {
  showPolicyDetails?: (policy: Policy) => void;
  showManagedPolicyDetails?: (policy: ManagedPolicy) => void;
}

type Props = ContainerModel & ContainerActions;

const EffectivePoliciesViewContainer = (props: Props) => {

  const {
    hidden,
    showManagedPolicyDetails,
    showPolicyDetails,
    ...otherProps
  } = props;

  if (hidden) {
    return null;
  }

  return (
    <EffectivePoliciesView {...otherProps}>
      <CustomPoliciesView showPolicyDetails={showPolicyDetails} />
      <ManagedPoliciesView showManagedPolicyDetails={showManagedPolicyDetails} />
      <ImplicitPermissionStatements />
    </EffectivePoliciesView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  customPoliciesViewSelected: isCustomPoliciesViewActive(state),
  managedPoliciesViewSelected: isManagedPoliciesViewActive(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setManagedPoliciesView: () => dispatch(showManagedPoliciesView()),
  setCustomPoliciesView: () => dispatch(showCustomPoliciesView()),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps
)(EffectivePoliciesViewContainer);
