import React from "react";
import classnames from "classnames";
import { clickHandler, getStringValue, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface DataLakeFilePathModel {
  className?: string;
  breadcrumbs?: string[];
}

export interface DataLakeFilePathActions {
  setBreadcrumbs?: (breadcrumbs: string[]) => void;
}

type Model = DataLakeFilePathModel;
type Actions = DataLakeFilePathActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataLakeFilePath = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    breadcrumbs = [],
    setBreadcrumbs = noop,
    children,
  } = props;

  const activeBreadcrumb = React.useMemo(() => getStringValue(breadcrumbs.slice().pop()), [breadcrumbs]);

  const activeBreadcrumbDepth = React.useMemo(() => breadcrumbs.length, [breadcrumbs]);

  const withOnClickHandler = (updatedBreadcrumbs: string[]) =>
    clickHandler(() => setBreadcrumbs(updatedBreadcrumbs));

  if (breadcrumbs.length === 0) {
    return null;
  }

  return (
    <div className={classnames("dataLakeFilePath", className, classes.container)}>
      {breadcrumbs.map((breadcrumb: string, index: number) => {
        if (breadcrumb === activeBreadcrumb && (activeBreadcrumbDepth === (index + 1))) {
          return (
            <label
              key={`breadcrumb-${index}`}
              className={classnames("active", classes.label, classes.active)}
            >
              {breadcrumb}
            </label>
          );
        } else {
          return (
            <React.Fragment key={`breadcrumb-${index}`}>
              <label
                className={classnames("link", classes.label, classes.link)}
                onClick={withOnClickHandler(breadcrumbs.slice(0, index + 1))}
              >
                {breadcrumb}
              </label>
              <label className={classnames("divider", classes.label, classes.divider)}>
                /
              </label>
            </React.Fragment>
          );
        }
      })}
      {children}
    </div>
  );
});

export default DataLakeFilePath;
