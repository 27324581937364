import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { deleteDialog as styles } from "../styles";

export interface Model {
  authenticatorId?: string;
}

type Props = WithStyles<typeof styles> & Model;

export const DeleteAuthenticator = withStyles(styles)((props: Props) => {

  const { classes, authenticatorId = "" } = props;

  const content = React.useMemo(() => {

    return (
      <React.Fragment>
        <label className={classnames("title", classes.title)}>
          Are you sure you want to delete authenticator: {authenticatorId}?
        </label>
        <label className={classnames("subtitle", classes.subtitle)}>
          <span className={classes.warning}>WARNING:</span>This action cannot be undone!
        </label>
      </React.Fragment>
    );
  }, [authenticatorId]);

  return (
    <div className={classnames("deleteAuthenticator", classes.container)}>
      {content}
    </div>
  );
});

export default DeleteAuthenticator;
