import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useDataSetRequestClient,
} from "@hooks";

type SuccessResponse = File;

export interface UseUploadTermsConditionsFileProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  file?: File;
  dataSetRequestId?: string;
}

export interface UseUploadTermsConditionsFileModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseUploadTermsConditionsFileActions extends UseApiRequestActions<SuccessResponse> {
  uploadFile: () => void;
}

type Props = UseUploadTermsConditionsFileProps;
type Model = UseUploadTermsConditionsFileModel;
type Actions = UseUploadTermsConditionsFileActions;
type Result = [Model, Actions];

export const useUploadTermsConditionsFile = (props: Props): Result => {

  const {
    file,
    dataSetRequestId = "",
    defaultErrorMessage = "Failed to Upload Terms & Conditions",
    ...otherProps
  } = props;

  const DataSetRequestClient = useDataSetRequestClient();

  const makeApiRequest = React.useCallback(() =>
      DataSetRequestClient.uploadTermsAndConditions(dataSetRequestId, file),
    [DataSetRequestClient, dataSetRequestId, file]);

  const [{ showSuccessView, ...baseModel }, { refresh, ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
      deferRequest: true,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Terms & Conditions Uploaded Successfully" : "",
    [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    showSuccessView,
    successMessage,
  }), [
    baseModel,
    showSuccessView,
    successMessage,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    refresh,
    uploadFile: refresh,
  }), [
    baseActions,
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useUploadTermsConditionsFile;
