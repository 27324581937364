import { createSelector } from "reselect";
import {
  AdminNote,
  AdminNoteAttributes,
  Policy,
  PolicyStatement,
  User,
  UserAttributes,
  UserStatus
} from "@data";
import { applyInProd, equalsIgnoreCase } from "@util/Functions";
import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { createSelectors, Selector } from "@base/createSelectors";
import { DEFAULT_STATE, UserDetailsSchema, PoliciesViewMode } from "./reducers";
import { ActionMenuItem } from "@components/actions-menu";
import {
  ADD_USER_TO_GROUP_ACTION,
  ATTACH_POLICY_TO_USER_ACTION,
  DETACH_POLICY_FROM_USER_ACTION,
  DELETE_USER,
  LOGOUT_USER,
  LOCK_USER,
  UNLOCK_USER,
  REMOVE_USER_FROM_GROUP_ACTION,
  CLOSE_USER,
  POLICY_ACTIONS,
  GROUP_ACTIONS,
  PASSWORD_ACTIONS,
  ACCOUNT_ACTIONS,
} from "@components/user-actions-menu";

export const {
  policiesViewMode: getPoliciesViewMode,
  user: getUserAttributes,
  removeUserButtonEnabled: isRemoveUserButtonEnabled,
  detachPolicyButtonEnabled: isDetachPolicyButtonEnabled,
  notes: getNotesAttributes,
  note: getNote,
  adminUser: isAdminUser,
  noteNextPage: getNoteNextPage,
  createNoteBox: isCreateNoteBox,
  noteLoadingIndicator : isNoteLoadingIndicatorVisible,
  noteErrorMessage: getNoteErrorMessage,
  policyLoadingIndicator : isPolicyLoadingIndicatorVisible,
  policyErrorMessage: getPolicyErrorMessage,
  deleteAuthenticatorDialog: isDeleteAuthenticatorDialogOpen,
  updateMfaDialog: isUpdateMfaDialogOpen,
  pageRefresh: isPageRefresh,
  errorMessage: getErrorMessage,
  successMessage: getSuccessMessage,
  showEmptyView: isEmptyViewVisible,
  showAccessDenied: isAccessDeniedVisible,
  showLoadingIndicator: isLoadingIndicatorVisible,
  implicitPermissionPolicy: getImplicitPermissionPolicy,
  getCurrentUserId,
  isErrorMessageVisible,
  isNotFoundVisible,
} = createSelectors<UserDetailsSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getUser: Selector<User> = createSelector(
  getUserAttributes, (attrs: UserAttributes) => new User(attrs));

export const getUserId: Selector<string> = createSelector(
  getUser, (user: User) => user.getUserId());

export const getCreatedAt: Selector<string> = createSelector(
  getUser, (user: User) => user.getCreatedAt());

export const getLastLogin: Selector<string> = createSelector(
  getUser, (user: User) => user.getLastLogin());

export const getLastPasswordChange: Selector<string> = createSelector(
  getUser, (user: User) => user.getLastPasswordChange());

export const getAnonymousId: Selector<string> = createSelector(
  getUser, (user: User) => user.getAnonymousId());

export const getUserStatus: Selector<UserStatus> = createSelector(
  getUser, (user: User) => user.getStatus());

export const isCurrentUserViewActive: Selector<boolean> = createSelector(
  [getUserId, getCurrentUserId], (userId: string, currentUserId: string) =>
    equalsIgnoreCase(userId, currentUserId));

export const isUserLocked: Selector<boolean> = createSelector(
  getUserStatus, (status: UserStatus) =>
    UserStatus.LOCKED === status);

export const isUserClosed: Selector<boolean> = createSelector(
  getUserStatus, (status: UserStatus) =>
    UserStatus.CLOSED === status);

export const isUserUnconfirmed: Selector<boolean> = createSelector(
  getUserStatus, (status: UserStatus) =>
    UserStatus.UNCONFIRMED === status);

export const isActionsMenuButtonDisabled: Selector<boolean> = createSelector(
  isUserClosed, (userIsClosed: boolean) => {

    // Disable actions menu button if user's account is closed (aka soft delete)
    // UNLESS we are running in development mode so that we have an easy way to delete closed users
    return applyInProd(
      () => userIsClosed,
      () => userIsClosed
        // Keep it disabled in tests if the user status is closed
        && process.env.NODE_ENV !== "development");
  });

export const getImplicitPermissionStatements: Selector<PolicyStatement[]> = createSelector(
  getImplicitPermissionPolicy, (policy: Policy = Policy.EMPTY) => {
    try {
      return policy.getStatements();
    } catch {
      return [];
    }
  });

export const getNotes: Selector<AdminNote[]> = createSelector(
  getNotesAttributes, (notes: AdminNoteAttributes[]) =>
    notes.map((attrs: AdminNoteAttributes) => new AdminNote((attrs))));

export const isAddNoteButtonActive: Selector<boolean> = createSelector(
  getNote, (note: string) => {
    return note.trim().length > 0;
  });

export const getMyProfileViewActionMenuItems: Selector<ActionMenuItem[]> = createSelector(
  [isRemoveUserButtonEnabled, isDetachPolicyButtonEnabled, isUserLocked],
  (removeUserButtonEnabled: boolean,
   detachPolicyButtonEnabled: boolean) => ([
    {
      ...GROUP_ACTIONS,
      actions: [
        ADD_USER_TO_GROUP_ACTION,
        {
          ...REMOVE_USER_FROM_GROUP_ACTION,
          disabled: !removeUserButtonEnabled,
        },
      ]
    },
    {
      ...POLICY_ACTIONS,
      actions: [
        ATTACH_POLICY_TO_USER_ACTION,
        {
          ...DETACH_POLICY_FROM_USER_ACTION,
          disabled: !detachPolicyButtonEnabled,
        },
      ]
    },
    {
      ...CLOSE_USER,
      name: "Close My Account",
    },
  ]));

export const getActionMenuItems: Selector<ActionMenuItem[]> = createSelector(
  [
    isRemoveUserButtonEnabled,
    isDetachPolicyButtonEnabled,
    isUserLocked,
    isUserUnconfirmed,
  ], (
    removeUserButtonEnabled: boolean,
    detachPolicyButtonEnabled: boolean,
    userLocked: boolean,
    userUnconfirmed: boolean
  ) => ([
    {
      ...GROUP_ACTIONS,
      actions: [
        {
          ...ADD_USER_TO_GROUP_ACTION,
          disabled: userUnconfirmed,
        },
        {
          ...REMOVE_USER_FROM_GROUP_ACTION,
          disabled: !removeUserButtonEnabled || userUnconfirmed,
        },
      ]
    },
    {
      ...POLICY_ACTIONS,
      actions: [
        {
          ...ATTACH_POLICY_TO_USER_ACTION,
          disabled: userUnconfirmed,
        },
        {
          ...DETACH_POLICY_FROM_USER_ACTION,
          disabled: !detachPolicyButtonEnabled || userUnconfirmed,
        },
      ]
    },
    PASSWORD_ACTIONS,
    {
      ...ACCOUNT_ACTIONS,
      actions: [
        LOGOUT_USER,
        {
          ...LOCK_USER,
          disabled: userLocked,
        },
        {
          ...UNLOCK_USER,
          disabled: !userLocked,
        },
        CLOSE_USER,
        DELETE_USER,
      ]
    }
  ]));

export const isCustomPoliciesViewActive: Selector<boolean> = createSelector(
  getPoliciesViewMode, (viewMode: PoliciesViewMode) =>
    PoliciesViewMode.CUSTOM === viewMode);

export const isManagedPoliciesViewActive: Selector<boolean> = createSelector(
  getPoliciesViewMode, (viewMode: PoliciesViewMode) =>
    PoliciesViewMode.MANAGED === viewMode);
