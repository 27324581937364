import { CertificateAuthority } from "@data";
import { CertificateAuthorityListColumn } from "./CertificateAuthorityListColumn";
import { Comparator, compare, isEmptyString } from "@util";

export const getSearchResults = (items: CertificateAuthority[], searchQuery: string) => {

  const authorities = items.slice();

  if (authorities.length === 0 || isEmptyString(searchQuery)) {
    return authorities;
  }

  const searchFilter = searchQuery.toLowerCase();

  return authorities.filter((authority: CertificateAuthority) =>
    authority.getId().toLowerCase().indexOf(searchFilter) >= 0 ||
    authority.getCommonName().toLowerCase().indexOf(searchFilter) >= 0);
};

const compareByAuthorityId = (left: CertificateAuthority, right: CertificateAuthority, ascending: boolean) =>
  compare(left.getId(), right.getId(), ascending);

const compareByCommonName = (left: CertificateAuthority, right: CertificateAuthority, ascending: boolean) =>
  compare(left.getCommonName(), right.getCommonName(), ascending);

export const createComparator =
  (sortedColumn: CertificateAuthorityListColumn, ascending: boolean): Comparator<CertificateAuthority> =>
    (left: CertificateAuthority, right: CertificateAuthority) => {
      switch (sortedColumn) {
        case CertificateAuthorityListColumn.AUTHORITY_ID:
          return compareByAuthorityId(left, right, ascending);
        case CertificateAuthorityListColumn.COMMON_NAME:
          return compareByCommonName(left, right, ascending);
        default:
          return 0;
      }
    };
