import { isEmptyString } from "@util";
import { createSelector } from "reselect";
import { DEFAULT_STATE, DeleteDataAccessRequestSchema } from "./reducers";
import { createSelectors, Selector } from "@base/createSelectors";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";

export const {
  accessRequestId: getAccessRequestId,
  dataSetAlias: getDataSetAlias,
  open: isDialogOpen,
  getErrorMessage,
  getSuccessMessage,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  getAccessToken,
} = createSelectors<DeleteDataAccessRequestSchema>(moduleId, schemaKey, DEFAULT_STATE);

export const isAccessRequestIdValid: Selector<boolean> = createSelector(
  getAccessRequestId, accessRequestId => !isEmptyString(accessRequestId));
