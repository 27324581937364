import { AppSchema } from "@schemas";
import { getPolicyName } from "./selectors";
import { getAuthToken } from "@main/selectors";
import { PolicyClient, RestClientError } from "@network";
import { emptyAction, identityAction, ReduxAction } from "@util";
import { DEFAULT_STATE, DeletePolicyActionType } from "./reducers";

type ActionType = DeletePolicyActionType;
type Action = ReduxAction<ActionType>;

export const setOpen = identityAction<ActionType, boolean>(
  DeletePolicyActionType.SET_OPEN, DEFAULT_STATE.open);

export const setPolicyName = identityAction<ActionType, string>(
  DeletePolicyActionType.SET_POLICY_NAME, DEFAULT_STATE.policyName);

export const setErrorMessage = identityAction<ActionType, string>(
  DeletePolicyActionType.SET_ERROR_MESSAGE, DEFAULT_STATE.errorMessage);

export const setSuccessMessage = identityAction<ActionType, string>(
  DeletePolicyActionType.SET_SUCCESS_MESSAGE, DEFAULT_STATE.successMessage);

export const setShowProgressIndicator = identityAction<ActionType, boolean>(
  DeletePolicyActionType.SET_SHOW_PROGRESS_INDICATOR, DEFAULT_STATE.showProgressIndicator);

export const setShowAccessDenied = identityAction<ActionType, boolean>(
  DeletePolicyActionType.SET_SHOW_ACCESS_DENIED, DEFAULT_STATE.showAccessDenied);

export const showProgressIndicator = (): Action => setShowProgressIndicator(true);
export const hideProgressIndicator = (): Action => setShowProgressIndicator(false);

export const showAccessDenied = (): Action => setShowAccessDenied(true);
export const hideAccessDenied = (): Action => setShowAccessDenied(false);

export const clearErrorMessage = (): Action => setErrorMessage("");
export const clearSuccessMessage = (): Action => setSuccessMessage("");

export const deletePolicyRequest = emptyAction<ActionType>(
  DeletePolicyActionType.DELETE_POLICY_REQUEST);

export const deletePolicySuccess = identityAction<ActionType, string>(
  DeletePolicyActionType.DELETE_POLICY_SUCCESS, "");

export const deletePolicyFailed = identityAction<ActionType, string>(
  DeletePolicyActionType.DELETE_POLICY_FAILED, "");

export const deletePolicy = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const authToken = getAuthToken(state);
  const policyName = getPolicyName(state);

  dispatch(showProgressIndicator());
  dispatch(hideAccessDenied());
  dispatch(clearErrorMessage());
  dispatch(clearSuccessMessage());
  dispatch(deletePolicyRequest());

  return PolicyClient.deletePolicy(authToken, policyName)
    .then(() => {

      dispatch(deletePolicySuccess());
      dispatch(hideProgressIndicator());
      return dispatch(setSuccessMessage("Policy Deleted"));

    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to delete policy" } = response;

      dispatch(deletePolicyFailed(analytic));
      dispatch(hideProgressIndicator());

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(setErrorMessage(error));
    });
};

export const reset = () => (dispatch: any) => {
  dispatch(setOpen());
  dispatch(setPolicyName());
  dispatch(setErrorMessage());
  dispatch(setSuccessMessage());
  dispatch(setShowProgressIndicator());
  return dispatch(setShowAccessDenied());
};

export const open = (policyName: string) => (dispatch: any) => {
  dispatch(reset());
  dispatch(setPolicyName(policyName));
  return dispatch(setOpen(true));
};

export const close = () => (dispatch: any) => dispatch(reset());

export const initialize = () => (dispatch: any) => dispatch(close());
