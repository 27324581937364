import { MODULE_PATH as BASE_URL } from "@modules/qualityReports/constants";
import { MODULE_PATHS as BASE_MODULE_PATHS } from "@modules/qualityReports/constants";

export const MODULE_ID = "quality_report_details";

export const MODULE_TITLE = "Quality Report Details";

export const MODULE_PATHS = BASE_MODULE_PATHS
  .filter(path => path !== BASE_URL)
  .map(baseUrl => `${baseUrl}/:groupId/:version`);

export interface RouteParams {
  groupId: string;
  version: string;
}

export { BASE_URL };
