import React from "react";
import classnames from "classnames";
import { clickHandler, isEmptyString, noop } from "@util";
import { useDropzone } from "react-dropzone";
import ErrorView from "@components/error-view";
import PdfViewer from "@components/pdf-viewer";
import Tooltip from "@material-ui/core/Tooltip";
import RemoveIcon from "@material-ui/icons/Clear";
import { IconButton, Alert } from "@components";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import WaitForApiRequestView, {
  WaitForApiRequestViewActions,
  WaitForApiRequestViewModel,
} from "@components/wait-for-api-request-view";
import styles from "./styles";

export interface TermsConditionsModel extends WaitForApiRequestViewModel {
  className?: string;
  title?: string;
  errorSubtitle?: string;
  emptyViewTitle?: string;
  defaultInvalidFileErrorMessage?: string;
  defaultFileFormatErrorMessage?: string;
  removeButtonTooltip?: string;
  dataGovernanceLink?: string;
  termsConditionsId?: string;
  file?: File | null;
  fileName?: string;
}

export interface TermsConditionsActions extends WaitForApiRequestViewActions {
  removeTermsConditionsFile?: () => void;
  setTermsConditionsFile?: (file: File) => void;
}

type Model = TermsConditionsModel;
type Actions = TermsConditionsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const TermsConditions = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    loadingMessage = "Downloading Terms & Conditions...",
    errorTitle = "Failed to Download Terms & Conditions",
    errorSubtitle = "We ran into an issue trying to download the terms & conditions document. " +
      "Please try again or feel free to provide your own document using the drag-and-drop " +
      "interface below.",
    title = "Set Privacy Terms & Conditions",
    emptyViewTitle = "Select a PDF file to upload",
    defaultInvalidFileErrorMessage = "Invalid File",
    defaultFileFormatErrorMessage = "File format must be PDF",
    removeButtonTooltip = "Remove File",
    // TODO: Extract this into a common component constant
    dataGovernanceLink = "https://www.collaboration.dtf.signify.com/display/IOTPLAT/IoT+Data+Governance+Process",
    file,
    fileName,
    termsConditionsId,
    showErrorView,
    errorMessage = "",
    errors = [],
    errorRetryButtonLabel = "Retry",
    refresh = noop,
    retry = refresh,
    removeTermsConditionsFile = noop,
    setTermsConditionsFile = noop,
    children,
    ...otherProps
  } = props;

  const [selectFileErrorMessage, setSelectFileErrorMessage] = React.useState("");

  const clearErrorMessage = React.useCallback(() =>
    setSelectFileErrorMessage(""), [setSelectFileErrorMessage]);

  const updateTermsConditionsFile = React.useCallback((selectedFile?: File) => {
    if (!selectedFile) {
      setSelectFileErrorMessage(defaultInvalidFileErrorMessage);
      removeTermsConditionsFile();
    } else {
      const fileType = selectedFile.name.split(".").pop();
      if (fileType === "pdf") {
        clearErrorMessage();
        setTermsConditionsFile(selectedFile);
      } else {
        setSelectFileErrorMessage(defaultFileFormatErrorMessage);
        removeTermsConditionsFile();
      }
    }
  }, [
    defaultInvalidFileErrorMessage,
    defaultFileFormatErrorMessage,
    clearErrorMessage,
    setSelectFileErrorMessage,
    setTermsConditionsFile,
    removeTermsConditionsFile,
  ]);

  const onDrop = React.useCallback(([selectedFile] = []) =>
      updateTermsConditionsFile(selectedFile),
    [updateTermsConditionsFile]);

  const onSelectFile = React.useCallback(event =>
      onDrop(Array.prototype.slice.call(
        event && event.target && event.target.files ? event.target.files : [])),
    [onDrop]);

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    preventDropOnDocument: true,
  });

  return (
    <WaitForApiRequestView
      {...otherProps}
      className={classnames("termsConditions", className, classes.container)}
      loadingMessage={loadingMessage}
      errorRetryButtonLabel={errorRetryButtonLabel}
      refresh={refresh}
      retry={retry}
    >
      {showErrorView && !file && termsConditionsId && (
        <ErrorView
          className={classnames("downloadErrorView", classes.downloadErrorView)}
          title={errorTitle}
          message={errorSubtitle}
          errors={errors.concat([errorMessage]).filter(e => !isEmptyString(e))}
          retryButtonLabel={errorRetryButtonLabel}
          retry={retry}
        />
      )}
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      <Alert
        className={classnames("alert", classes.alert)}
        severity="info"
      >
        <p className={classnames("alertDescription", classes.alertDescription)}>
          Before this Data Set can be created, you must upload a <strong>PDF</strong> containing the <strong>
          Privacy Terms and Conditions</strong> associated with this data set.
        </p>
        <p className={classnames("alertDescription", classes.alertDescription)}>
          As part of the
          <a
            className={classnames("dataGovernanceLink", classes.dataGovernanceLink)}
            href={dataGovernanceLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            IoT Data Governance process
          </a>
          , evidence must be provided:
        </p>
        <ul className={classnames("alertDescription", classes.alertDescription)}>
          <li>
            that describes the permission for Signify to collect & store this data
          </li>
          <li>
            that describes how Signify is permitted to use this data
          </li>
        </ul>
        <p className={classnames("alertDescription", classes.alertDescription)}>
          Typically, the source of such evidence is a legal document
          (for example, product Terms & Conditions) that is approved and accepted by a customer.
          A copy of the document/contract/terms & conditions that a customer accepts must be attached to the
          data set creation request.<br/>
          Note that IoT Platform is the Data Processor.
          The Data Controller responsibilities reside with the originator of this data set
        </p>
      </Alert>
      <form
        {...getRootProps()}
        className={classnames("termsConditionsFile", classes.termsConditionsFile, {
          [classes.dragging]: isDragActive,
          "empty": !file,
        })}
      >
        {file && (
          <PdfViewer
            className={classnames("pdfViewer", classes.pdfViewer)}
            file={file}
            fileName={fileName}
            hideDownloadButton={true}
            customControls={!file ? null : (
              <Tooltip title={removeButtonTooltip}>
                <IconButton
                  className={classnames("removeButton", classes.removeButton)}
                  onClick={clickHandler(removeTermsConditionsFile)}
                >
                  <RemoveIcon className={classnames("removeIcon", classes.removeIcon)} />
                </IconButton>
              </Tooltip>
            )}
          />
        )}
        {!file && (
          <React.Fragment>
            <div className={classnames("emptyView", classes.emptyView)}>
              <ErrorView
                className={classnames("errorView", classes.errorView)}
                showCloseIcon={true}
                message={selectFileErrorMessage}
                onClickCloseIcon={clearErrorMessage}
              />
              <Typography className={classnames("emptyViewTitle", classes.emptyViewTitle)} variant="h5">
                {emptyViewTitle}
              </Typography>
            </div>
            <input {...getInputProps()} type="file" onChange={onSelectFile} />
          </React.Fragment>
        )}
      </form>
      {children}
    </WaitForApiRequestView>
  );
});

export default TermsConditions;
