import React from "react";
import {
  UseApiRequestModel,
  UseApiRequestProps,
  useApiRequest,
  useProfileAttributeClient,
} from "@hooks";

type SuccessResponse = void;

export interface UseEditProfileAttributeDefinitionsProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  json: string;
  accountId: string;
}

export interface UseEditProfileAttributeDefinitionsModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseEditProfileAttributeDefinitionsActions {
  save: () => void;
  reset: () => void;
}

type Props = UseEditProfileAttributeDefinitionsProps;
type Model = UseEditProfileAttributeDefinitionsModel;
type Actions = UseEditProfileAttributeDefinitionsActions;
type Result = [Model, Actions];

export const useEditProfileAttributeDefinitions = (props: Props): Result => {

  const {
    json = "",
    accountId = "",
    defaultErrorMessage = "Failed to edit profile attribute definitions",
    ...otherProps
  } = props;

  const ProfileAttributeClient = useProfileAttributeClient();

  const makeApiRequest = React.useCallback(() =>
    ProfileAttributeClient.editProfileAttributeDefinitions(accountId, json),
    [ProfileAttributeClient, accountId, json]);

  const [{ showSuccessView, ...baseModel }, { reset, refresh: save }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
      deferRequest: true,
    });

  const successMessage = React.useMemo(() => showSuccessView ?
    "Profile Attribute Definitions Updated" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    reset,
    save,
  }), [
    reset,
    save,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEditProfileAttributeDefinitions;
