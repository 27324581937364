import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
    },
    header: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    title: {
      color: Color.TEXT,
      margin: 0,
      fontWeight: 300,
      fontSize: 18,
    },
    searchFilters: {
      marginTop: 8,
    },
    clearFiltersButton: {
      height: "auto",
      minWidth: "auto",
      marginTop: 2,
      marginLeft: 8,
      padding: "4px 8px",
    },
    clearFiltersButtonLabel: {
      fontSize: 13,
      fontWeight: 500,
      textTransform: "uppercase",
    },
    disabledButton: {
      "& .icon, & .label": {
        color: Color.GREY3,
      },
    },
  });

export default styles;
