import React from "react";
import { isValidInteger } from "@util";
import { RouteParams } from "./constants";
import { useParams } from "react-router-dom";
import WorkloadInstanceDetails from "./containers/WorkloadInstanceDetails";

export function WorkloadInstanceDetailsView() {

  const { name, version: versionParam, executionId } = useParams<RouteParams>();

  const version = React.useMemo(() => {
    return isValidInteger(versionParam) ? Number(versionParam) : -1;
  }, [ versionParam ]);

  return (
    <WorkloadInstanceDetails
      name={name}
      version={version}
      executionId={executionId}
    />
  );
}

export default WorkloadInstanceDetailsView;
