import React, { RefObject } from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { getAuthToken } from "@main/selectors";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { module } from "./styles";

export interface PortletAppSchema {
  className?: string;
  accessToken: string;
}

export interface PortletProps {
  url: string;
  tokenAttribute: string;
}

export interface ContainerProps {
  url: string;
  tokenAttribute: string;
}

const Portlet = withStyles(module)((props: WithStyles<typeof module> & PortletAppSchema & PortletProps) => {

  const {
    classes,
    className,
    url,
    tokenAttribute,
    accessToken
  } = props;

  if (tokenAttribute === "#") {
    let iframeUrl = url + "#" + accessToken;
    return <iframe src={iframeUrl} className={classnames(className, classes.container)}/>;
  } else {

    interface Container {
      iframeRef: RefObject<HTMLIFrameElement>;
      iframe: any;
    }
    const [state, setState] = React.useState<Container>();

    if (!state) {
      const iframeUrl = url + "?" + tokenAttribute + "=" + accessToken;
      const iframeRef = React.createRef<HTMLIFrameElement>();
      const iframe = (
        <iframe
          ref={iframeRef}
          src={iframeUrl}
          className={classnames(className, classes.container)}
        />
      );

      setState({iframeRef, iframe});
      return iframe;
    }

    console.log("PortletContainer rendered");

    state.iframeRef.current?.contentWindow?.postMessage("signify:access_token=" + accessToken, new URL(url).origin);
    return state.iframe;
  }

});

export default connect<PortletAppSchema, {}, PortletAppSchema>(
  (state: AppSchema, { className }): PortletAppSchema => ({
    className,
    accessToken: getAuthToken(state),
  }),
)((props: PortletAppSchema & ContainerProps) => {
  if (props.accessToken.split(".").length === 3) {
    return (
      <Portlet {...props} />
    );
  } else {
    return null;
  }
});
