import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { Application } from "@data";
import { isEmptyString, noop } from "@util";
import classnames from "classnames";
import { useApplicationSecretManagement } from "@hooks";
import ApplicationSecretOperation from "@hooks/applicationManagerRegional/use-application-secret-management/ApplicationSecretOperation";
import { TextFieldStyled } from "@components/text-field-styled";
import { SecretDialog } from "@components";

export interface ApplicationSecretManagementDialogModel extends PortalModuleDialogModel<Application> {
  dialogClassName?: string;
  applicationId?: string;
  operation: ApplicationSecretOperation;
}

export interface ApplicationSecretManagementDialogActions extends PortalModuleDialogActions<Application> {
}

type Props = WithStyles< typeof styles> &
  ApplicationSecretManagementDialogModel & ApplicationSecretManagementDialogActions;

export const ApplicationSecretManagementDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    dialogClassName,
    applicationId = "",
    operation = ApplicationSecretOperation.NONE,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  if (isEmptyString(applicationId) || operation === ApplicationSecretOperation.NONE) {
    return null;
  }

  const [secret, setSecret] = React.useState("");
  const [openSecretsDialog, setOpenSecretsDialog] = React.useState(false);

  const isDelete = React.useMemo(() => operation === ApplicationSecretOperation.DELETE ,
    [operation]);

  const isGenerate = React.useMemo(() => operation === ApplicationSecretOperation.GENERATE ,
    [operation]);

  const isSet = React.useMemo(() => operation === ApplicationSecretOperation.SET ,
    [operation]);

  const title = React.useMemo(() =>
      `${operation} Application Secret` ,
    [operation]);

  const continueButtonDisabled = React.useMemo(() => isSet ? secret.length < 8 : false  , [secret, isSet]);

  const [ model, actions] = useApplicationSecretManagement({
    applicationId,
    secret,
    operation
  });

  const { showLoadingIndicator, application, successMessage, errorMessage, ...otherModel } = model;
  const { performOperation, ...otherActions } = actions;

  const confirm = React.useCallback(() => {
    performOperation();
  }, [performOperation]);

  const generatedSecret = React.useMemo(() => application.getPrimarySecret(), [application]);

  const onSuccessOperation = React.useCallback( () => {
    if (isGenerate) {
      setOpenSecretsDialog(true);
    } else {
      onSuccess();
    }
  }, [setOpenSecretsDialog, onSuccess]);

  const closeSecretDialog = React.useCallback(() => {
    setOpenSecretsDialog(false);
    onSuccess();
  }, [setOpenSecretsDialog, onSuccess]);

  return (
    <React.Fragment>
      <PortalModuleDialog
        {...otherProps}
        {...otherModel}
        {...otherActions}
        className={classnames("applicationSecretManagementDialog", dialogClassName)}
        title={title}
        loading={showLoadingIndicator}
        continueButtonLabel={operation}
        confirm={confirm}
        continueButtonDisabled={continueButtonDisabled}
        onSuccessMessageShown={onSuccessOperation}
        successMessage={successMessage}
        errorMessage={errorMessage}
      >
        <div className={classnames("applicationOperation", classes.container)}>
          <React.Fragment>
            {isGenerate && (
              <div className={classnames("generateSecret", classes.informationContainer)}>
                <label className={classnames("title", classes.title)}>
                  Are you sure you want to generate new application secret?
                </label>
                <label className={classnames("subtitle", classes.subtitle)}>
                <span className={classes.warning}>WARNING:</span>This will expire the old secret and generate a new one!
                </label>
                <label className={classnames("applicationId", classes.applicationId)}>
                  {applicationId}
                </label>
              </div>
            )}
            {isDelete && (
              <div className={classnames("deleteSecret", classes.informationContainer)}>
                <label className={classnames("title", classes.title)}>
                  Are you sure you want to delete old application secret?
                </label>
                <label className={classnames("subtitle", classes.subtitle)}>
                  This will delete the old (secondary) secret!
                </label>
                <label className={classnames("applicationId", classes.applicationId)}>
                  {applicationId}
                </label>
              </div>
            )}
            {isSet && (
              <div className={classnames("setSecret", classes.setSecretContainer)}>
                <label className={classnames("title", classes.title, classes.setTitle)}>
                  Set new application secret
                </label>
                <TextFieldStyled
                  className={classnames("applicationSecretTextField", classes.applicationSecretTextField)}
                  name={"secret"}
                  label="Secret"
                  value={secret}
                  fullWidth={true}
                  autoFocus={true}
                  placeholder={"Set Application Secret"}
                  helperText={"Min length 8 characters. Must contain small letter, capital letter, number and special character(-._~)"}
                  setValue={setSecret}
                />
              </div>
            )}
          </React.Fragment>
        </div>
      </PortalModuleDialog>
      <SecretDialog
        open={openSecretsDialog}
        secret={generatedSecret}
        fileName={applicationId}
        closeDialog={closeSecretDialog}
      />
    </React.Fragment>
  );
});

export default ApplicationSecretManagementDialog;
