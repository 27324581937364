import { createStyles } from "@material-ui/core/styles";
import { SignifyIcon as ModuleIcon } from "@components/icons";
import Color from "@components/styles/Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const welcomePageTitle = () =>
  createStyles({
    container: {
      marginLeft: 8,
      "& .icon": {
        color: Color.SIGNIFY_GREEN,
        "&:hover": {
          color: Color.SIGNIFY_GREEN2,
        },
      },
    },
  });

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    row: {
      display: "flex",
      flexFlow: "row nowrap",
    },
    column: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    leftColumn: {
      flex: "1 0 auto",
      paddingRight: 25,
    },
    rightColumn: {
      flex: "1 0 auto",
      maxWidth: "38vw",
      "&$fullWidth": {
        maxWidth: "100%",
      },
    },
    fullWidth: {},
    domain: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 25,
      "&:first-child": {
        marginTop: 0,
      },
    },
    subheaderDomain: {
      marginTop: 15,
    },
    header: {
      padding: 15,
      backgroundColor: Color.LIGHT_GREY,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    releaseNotesIconButton: {
      marginLeft: 8,
      "& .icon": {
        color: Color.BLACK2,
        width: 18,
        "&:hover": {
          color: Color.GREY9,
        },
      },
    },
    qualityReportsIconButton: {
      marginLeft: 8,
      "& .icon": {
        color: Color.BLACK2,
        width: 18,
        "&:hover": {
          color: Color.GREY9,
        },
      },
    },
    costReportsIconButton: {
      marginLeft: 8,
      "& .icon": {
        color: Color.BLACK2,
        width: 18,
        "&:hover": {
          color: Color.GREY9,
        },
      },
    },
    subheader: {
      padding: 8,
    },
    title: {
      color: Color.TEXT,
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "21px",
    },
    subheaderTitle: {
      fontSize: 14,
      lineHeight: "16px",
    },
    content: {
      padding: "15px 12px 8px",
      backgroundColor: Color.LIGHT_GREY2,
    },
    links: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
    },
    link: {
      color: Color.BLACK,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      textDecoration: "underline",
    },
    icon: {
      color: Color.SIGNIFY_GREEN2,
      cursor: "pointer",
      width: 16,
      height: 16,
    },
    moduleItem: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      marginTop: 10,
      "&:first-child": {
        marginTop: 0,
      },
    },
    favoriteButton: {
      padding: 0,
    },
    favoriteIcon: {
      cursor: "pointer",
      width: 16,
      height: 16,
    },
    label: {
      cursor: "pointer",
      marginLeft: 10,
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 14,
      lineHeight: "24px",
      "&:hover": {
        fontWeight: 400,
      },
    },
    dataAndAnalytics: {
      backgroundColor: Color.LIGHT_GREY2,
      paddingBottom: 15,
    },
    dataAndAnalyticsContent: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    dataAndAnalyticsFirstRow: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
    },
    dataAndAnalyticsSecondRow: {
      marginTop: 8,
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
    },
    dataAndAnalyticsLeftColumn: {
      flex: "1 0 50%",
      display: "flex",
      flexFlow: "column nowrap",
      paddingRight: 12,
    },
    dataAndAnalyticsRightColumn: {
      flex: "1 0 50%",
      display: "flex",
      flexFlow: "column nowrap",
      paddingLeft: 12,
    },
    dataReporting: {
    },
    dataLake: {
    },
    dataWarehouse: {
    },
    dataCatalog: {
    },
    dataGovernance: {
    },
    securityServices: {
      flex: "1 0 auto",
      backgroundColor: Color.LIGHT_GREY2,
    },
    securityServicesContent: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
    },
    securityServicesFirstRow: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
    },
    securityServicesSecondRow: {
      marginTop: 8,
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
    },
    securityServicesLeftColumn: {
      flex: "1 0 50%",
      display: "flex",
      flexFlow: "column nowrap",
      paddingRight: 12,
    },
    securityServicesRightColumn: {
      flex: "1 0 50%",
      display: "flex",
      flexFlow: "column nowrap",
      paddingLeft: 12,
    },
    commonServices: {
      flex: "1 0 auto",
      backgroundColor: Color.LIGHT_GREY2,
      paddingBottom: 8,
    },
    deviceManagement: {
      flex: "1 0 auto",
      backgroundColor: Color.LIGHT_GREY2,
    },
    deviceManagementContent: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
    },
    deviceManagementLeftColumn: {
      flex: "1 0 50%",
      display: "flex",
      flexFlow: "column nowrap",
      paddingRight: 12,
    },
    deviceManagementRightColumn: {
      flex: "1 0 50%",
      display: "flex",
      flexFlow: "column nowrap",
      paddingLeft: 12,
    },
    authorization: {
    },
    serviceIdentityManagement: {
    },
    digitalShadow: {
    },
    device: {
    },
    userIdentityManagement: {
    },
    applicationIdentityManagement: {
    },
    audit: {
    },
    certificates: {
    },
    whatsNew: {
    },
  });

export default styles;
