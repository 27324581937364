import React from "react";
import classnames from "classnames";
import { CreateDataAccessRequest } from "@data/CreateDataAccessRequest";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
} from "@components/submit-api-request-view";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export enum DataAccessRequestWizardStep {
  CERTIFICATE = "certificate",
  PRINCIPAL = "principal",
  SERVICE_PRINCIPAL = "service_principal",
  DEVICE_TYPE = "device_type",
  IOT_CLOUD_ROLE = "iot_cloud_role",
  IOT_CLOUD_STORAGE = "iot_cloud_storage",
  TYPE = "type",
  REVIEW = "review",
}

export type DataAccessRequestWizardStepLabels<K extends keyof any = DataAccessRequestWizardStep> = {
  [P in K]: string;
};

export const DEFAULT_STEPS: DataAccessRequestWizardStep[] = [
  DataAccessRequestWizardStep.PRINCIPAL,
  DataAccessRequestWizardStep.TYPE,
  DataAccessRequestWizardStep.REVIEW,
];

export const DEFAULT_STEP_LABELS: DataAccessRequestWizardStepLabels = {
  [DataAccessRequestWizardStep.CERTIFICATE]: "Upload Training Certificate",
  [DataAccessRequestWizardStep.PRINCIPAL]: "Request Type",
  [DataAccessRequestWizardStep.SERVICE_PRINCIPAL]: "Service Principal",
  [DataAccessRequestWizardStep.DEVICE_TYPE]: "Device Type",
  [DataAccessRequestWizardStep.IOT_CLOUD_ROLE]: "IoT Cloud Role",
  [DataAccessRequestWizardStep.IOT_CLOUD_STORAGE]: "IoT Cloud Storage",
  [DataAccessRequestWizardStep.TYPE]: "Access Type and Reason",
  [DataAccessRequestWizardStep.REVIEW]: "Finish",
};

export const DEVICE_TYPE_STEP_LABELS: DataAccessRequestWizardStepLabels = {
  ...DEFAULT_STEP_LABELS,
  [DataAccessRequestWizardStep.TYPE]: "Access Reason",
};

export interface Model extends SubmitApiRequestViewModel<CreateDataAccessRequest, DataAccessRequestWizardStep> {
  stepLabels?: DataAccessRequestWizardStepLabels;
}

export interface Actions extends SubmitApiRequestViewActions<CreateDataAccessRequest, DataAccessRequestWizardStep> {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataAccessRequestWizard = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    snackbarId = "data-access-request-wizard",
    errorTitle = "Request Data Access Failed",
    saveButtonLabel = "Submit Request",
    steps = DEFAULT_STEPS,
    stepLabels = DEFAULT_STEP_LABELS,
    mapStepToLabel = React.useCallback(step => stepLabels[step] || null, [stepLabels]),
    children,
    ...otherProps
  } = props;

  return (
    <SubmitApiRequestView
      {...otherProps}
      className={classnames("dataAccessRequestWizard", className, classes.container)}
      snackbarId={snackbarId}
      errorTitle={errorTitle}
      saveButtonLabel={saveButtonLabel}
      steps={steps}
      mapStepToLabel={mapStepToLabel}
    >
      {children}
    </SubmitApiRequestView>
  );
});

export default DataAccessRequestWizard;
