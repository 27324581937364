import React from "react";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  useApplicationManagerRegionalClient
} from "@hooks";
import { Application, ApplicationAttributes } from "@data/Application";

type SuccessResponse = ApplicationAttributes;

export interface UseGetApplicationRegionalProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  applicationId: string;
}

export interface UseGetApplicationRegionalModel extends UseApiRequestModel<SuccessResponse> {
  application: Application;
}

export interface UseGetApplicationRegionalActions {
  getApplication: () => void;
}

type Props = UseGetApplicationRegionalProps;
type Model = UseGetApplicationRegionalModel;
type Actions = UseGetApplicationRegionalActions;
type Result = [Model, Actions];

export const useGetApplicationRegional = (props: Props): Result => {

  const {
    applicationId = "",
    defaultErrorMessage = "Failed to get applications",
    ...otherProps
  } = props;

  const ApplicationManagerClient = useApplicationManagerRegionalClient();

  const makeApiRequest = React.useCallback(() =>
      ApplicationManagerClient.getApplicationRegionalApi(applicationId),
    [ApplicationManagerClient]);

  const [{ successResponse, ...baseModel }, { refresh: getApplication }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const application = React.useMemo<Application>(() => {
    const attrs = successResponse || {};
    return new Application(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    application,
  }), [
    baseModel,
    application,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    getApplication
  }), [getApplication]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetApplicationRegional;
