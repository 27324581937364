import { Color } from "@Color";
import ModuleIcon from "@material-ui/icons/Apartment";
import { createStyles } from "@material-ui/core/styles";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const summaryView = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
    },
    summaryView: {
      display: "flex",
      flex: "0 0 50%",
      flexFlow: "column nowrap",
      paddingRight: 25,
      marginTop: 0,
      "& label.name": {
        minWidth: 100,
      },
    },
    metadataTable: {
      display: "flex",
      flex: "0 0 50%",
      flexFlow: "column nowrap",
      paddingLeft: 25,
    },
  });

export const metadataView = () =>
  createStyles({
    container: {
      color: primaryColor,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      margin: 0,
      fontWeight: 350,
      fontSize: 20,
    },
  });

export const childrenView = () =>
  createStyles({
    container: {
      color: primaryColor,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      marginTop: 10
    },
    title: {
      color: Color.TEXT,
      margin: 0,
      fontWeight: 400,
      fontSize: 20,
    },
  });
