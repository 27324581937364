import { Record } from "immutable";
import { JsonSchemaPropertiesDefinition, JsonSchemaPropertyType } from "../data";
import { isValidNumber } from "../util";

// JsonSchema String Property Type
//
// Reference Material:
//  - https://json-schema.org/understanding-json-schema/reference/string.html
//  - https://docs.opis.io/json-schema/1.x/string.html
export interface JsonSchemaStringConstraintsAttributes {
  // A string is valid against this keyword if its length is greater then, or equal to, the value
  // of this keyword. Value of this keyword must be a non-negative integer.
  minLength?: number;
  // A string is valid against this keyword if its length is lower then, or equal to, the value
  // of this keyword. Value of this keyword must be a non-negative integer.
  maxLength?: number;
  // A string is valid against this keyword if it matches the regular expression specified by
  // the value of this keyword. Value of this keyword must be a string representing a valid
  // regular expression.
  pattern?: string;
}

export type StringValidationData = {
  minLength?: number,
  maxLength?: number,
  pattern?: string,
};

export class JsonSchemaStringConstraints extends Record({
  minLength: NaN,
  maxLength: NaN,
  pattern: "",
}) implements JsonSchemaStringConstraintsAttributes {

  public static EMPTY: JsonSchemaStringConstraints = new JsonSchemaStringConstraints();

  public readonly minLength: number;
  public readonly maxLength: number;
  public readonly pattern: string;

  public static from(property: JsonSchemaPropertiesDefinition = {}): JsonSchemaStringConstraints {

    const { type, minLength, maxLength, pattern } = property;

    if (JsonSchemaPropertyType.STRING !== type) {
      return JsonSchemaStringConstraints.EMPTY;
    }

    const attrs = {
      ...(!isValidNumber(minLength) ? {} : { minLength: Number(minLength) }),
      ...(!isValidNumber(maxLength) ? {} : { maxLength: Number(maxLength) }),
      ...(pattern == null ? {} : { pattern }),
    };

    return new JsonSchemaStringConstraints(attrs);
  }

  public hasMinLength(): boolean {
    return isValidNumber(this.minLength);
  }

  public hasMaxLength(): boolean {
    return isValidNumber(this.maxLength);
  }

  public hasPattern(): boolean {
    return (this.pattern + "").trim().length > 0;
  }

  public getValidationData(): StringValidationData {

    const data: StringValidationData = { ...this.toJS() };

    if (!this.hasMinLength()) {
      delete data.minLength;
    }

    if (!this.hasMaxLength()) {
      delete data.maxLength;
    }

    if (!this.hasPattern()) {
      delete data.pattern;
    }

    return data;
  }
}
