import React from "react";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  useApplicationManagerRegionalClient
} from "@hooks";
import { ApplicationUserManagementAttributes, ApplicationUserManagement } from "@data";

type SuccessResponse = ApplicationUserManagementAttributes;

export interface UseGetApplicationUserManagementProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  applicationId: string;
}

export interface UseGetApplicationUserManagementModel extends UseApiRequestModel<SuccessResponse> {
  applicationUserManagement: ApplicationUserManagement;
}

export interface UseGetApplicationUserManagementActions {
  getApplicationUserManagement: () => void;
}

type Props = UseGetApplicationUserManagementProps;
type Model = UseGetApplicationUserManagementModel;
type Actions = UseGetApplicationUserManagementActions;
type Result = [Model, Actions];

export const useGetApplicationUserManagement = (props: Props): Result => {

  const {
    applicationId = "",
    defaultErrorMessage = "Failed to get application user management",
    ...otherProps
  } = props;

  const ApplicationManagerClient = useApplicationManagerRegionalClient();

  const makeApiRequest = React.useCallback(() =>
      ApplicationManagerClient.getApplicationUserManagementApi(applicationId),
    [ApplicationManagerClient]);

  const [{ successResponse, ...baseModel }, { refresh: getApplicationUserManagement }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      deferRequest: true,
      defaultErrorMessage,
      makeApiRequest,
    });

  const applicationUserManagement = React.useMemo<ApplicationUserManagement>(() => {
    const attrs = successResponse || {};
    return new ApplicationUserManagement(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    applicationUserManagement,
  }), [
    baseModel,
    applicationUserManagement,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    getApplicationUserManagement
  }), [getApplicationUserManagement]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetApplicationUserManagement;
