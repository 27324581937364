import { createActions } from "@modules/base";
import { ACTION_TYPES, DEFAULT_STATE, PolicyManagementViewMode, SelectPolicyStep } from "./reducers";
import { PolicyOperation, PolicyOperationPrincipal } from "@hooks";
import AppSchema from "@schemas";
import { PolicyBulkRequest, PolicyStatus } from "@data/PolicyBulkRequest";
import { ManagedPolicyBulkRequest } from "@data/ManagedPolicyBulkRequest";
import { equalsIgnoreCase } from "@util";
import { AuthorizationServiceClient, PolicyClient } from "@network";
import capitalize from "lodash/capitalize";
import {
  getAccessToken,
  getPolicyBulkRequest,
} from "@modules/policyManagement/selectors";

export const {
  setErrorMessage,
  setSuccessMessage,
  principalId: setPrincipalId,
  policyName: setPolicyName,
  policyRef: setPolicyRef,
  operation: setOperation,
  principalType: setPrincipalType,
  viewMode: setViewMode,
  policyStep: setPolicyStep,
  policyBulkRequest: setPolicyBulkRequest,
  selectedPolicies: setSelectedPolicies,
  selectedManagedPolicies: setSelectedManagedPolicies,
  managedPolicyDomain: setManagedPolicyDomain,
  showLoadingIndicator,
  hideLoadingIndicator,
  ATTACH_POLICY_TO_SERVICE_REQUEST: attachPolicyToServiceRequest,
  ATTACH_POLICY_TO_SERVICE_SUCCESS: attachPolicyToServiceSuccess,
  ATTACH_POLICY_TO_SERVICE_FAILED: attachPolicyToServiceFailed,
  ATTACH_POLICY_TO_USER_REQUEST: attachPolicyToUserRequest,
  ATTACH_POLICY_TO_USER_SUCCESS: attachPolicyToUserSuccess,
  ATTACH_POLICY_TO_USER_FAILED: attachPolicyToUserFailed,
  ATTACH_POLICY_TO_GROUP_REQUEST: attachPolicyToGroupRequest,
  ATTACH_POLICY_TO_GROUP_SUCCESS: attachPolicyToGroupSuccess,
  ATTACH_POLICY_TO_GROUP_FAILED: attachPolicyToGroupFailed,
  DETACH_POLICY_FROM_SERVICE_REQUEST: detachPolicyFromServiceRequest,
  DETACH_POLICY_FROM_SERVICE_SUCCESS: detachPolicyFromServiceSuccess,
  DETACH_POLICY_FROM_SERVICE_FAILED: detachPolicyFromServiceFailed,
  DETACH_POLICY_FROM_USER_REQUEST: detachPolicyFromUserRequest,
  DETACH_POLICY_FROM_USER_SUCCESS: detachPolicyFromUserSuccess,
  DETACH_POLICY_FROM_USER_FAILED: detachPolicyFromUserFailed,
  DETACH_POLICY_FROM_GROUP_REQUEST: detachPolicyFromGroupRequest,
  DETACH_POLICY_FROM_GROUP_SUCCESS: detachPolicyFromGroupSuccess,
  DETACH_POLICY_FROM_GROUP_FAILED: detachPolicyFromGroupFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setPrincipalId());
  dispatch(setPolicyName());
  dispatch(setPolicyRef());
  dispatch(setOperation());
  dispatch(setPrincipalType());
  dispatch(setViewMode());
  dispatch(setPolicyStep());
  dispatch(setPolicyBulkRequest());
  dispatch(setSelectedPolicies());
  dispatch(setSelectedManagedPolicies());
  return dispatch(baseReset());
};

export const showCustomPolicyView = () => setViewMode(PolicyManagementViewMode.CUSTOM);
export const showManagedPolicyView = () => setViewMode(PolicyManagementViewMode.MANAGED);
export const showPolicyReviewView = () => setPolicyStep(SelectPolicyStep.REVIEW);
export const showPolicyView = () => setPolicyStep(SelectPolicyStep.POLICIES);

export const initialize = (props: {
  id?: string,
  action?: PolicyOperation,
  principalType?: PolicyOperationPrincipal
}) => (dispatch: any) => {

  const {
    id = "",
    action,
    principalType = PolicyOperationPrincipal.SERVICE
  } = props;

  dispatch(reset());
  dispatch(setPrincipalId(id));

  dispatch(setOperation(action));
  return dispatch(setPrincipalType(principalType));
};

export const setPolicyStatus = (policyRequest: PolicyBulkRequest | ManagedPolicyBulkRequest,
                                status: PolicyStatus, errorMessage: string = "") =>
                               (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const policies = getPolicyBulkRequest(state);
  const policy = {
    policy: policyRequest.getPolicy().toJS(),
    status: status,
    errorMessage: errorMessage,
  };

  const updatedPolicy = policyRequest.managed ?
    new ManagedPolicyBulkRequest(policy) :
    new PolicyBulkRequest(policy);

  let remainingPolicies: any = [];
  for (let i = 0; i < policies.length; i++) {
    if (!equalsIgnoreCase(policies[i].getPolicyName(), policyRequest.getPolicyName())) {
      remainingPolicies.push(policies[i]);
    } else {
      remainingPolicies.push(updatedPolicy);
    }
  }

  return dispatch(setPolicyBulkRequest(remainingPolicies));
};

const updatePolicy = (policy: PolicyBulkRequest | ManagedPolicyBulkRequest, operation: PolicyOperation,
                         principal: PolicyOperationPrincipal, isManagedPolicy: boolean,
                         id: string, accountId: string) =>
                        (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const accessToken = getAccessToken(state);
  const policyName = policy.getPolicyName();

  dispatch(setPolicyStatus(policy, PolicyStatus.PROCESSING));

  return mapApiCallToOperation(operation, principal, isManagedPolicy, id, policyName, accountId, accessToken)
    .then(() => {
      return dispatch(setPolicyStatus(policy, PolicyStatus.SUCCESS));
    }, (error) => {
      return dispatch(setPolicyStatus(policy, PolicyStatus.FAILED, error.description));
    });
};

export const updatePolicies = (operation: PolicyOperation, principal: PolicyOperationPrincipal,
                               isManagedPolicy: boolean, id: string,
                               accountId: string) => (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const policies = getPolicyBulkRequest(state);

  dispatch(showLoadingIndicator());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());

  const analytics = {
    ATTACH_POLICY_TO_SERVICE_REQUEST: attachPolicyToServiceRequest(),
    ATTACH_POLICY_TO_USER_REQUEST: attachPolicyToUserRequest(),
    ATTACH_POLICY_TO_GROUP_REQUEST: attachPolicyToGroupRequest(),
    DETACH_POLICY_FROM_SERVICE_REQUEST: detachPolicyFromServiceRequest(),
    DETACH_POLICY_FROM_USER_REQUEST: detachPolicyFromUserRequest(),
    DETACH_POLICY_FROM_GROUP_REQUEST: detachPolicyFromGroupRequest(),
  };
  const toOrFrom = (operation === PolicyOperation.ATTACH ? "to" : "from");
  const request = (operation + "_POLICY_" + toOrFrom + "_" + principal + "_REQUEST").toUpperCase();
  dispatch(analytics[request]);

  let requests = [];
  for (let i = 0; i < policies.length; i++) {
    if (policies[i].getPolicyStatus() !== PolicyStatus.SUCCESS) {
      requests.push(dispatch(updatePolicy(policies[i], operation, principal, isManagedPolicy, id, accountId)));
    }
  }

  return Promise.all(requests).then(() => dispatch(setStatusMessage(id, principal, operation)));
};

const mapApiCallToOperation = (operation: PolicyOperation, principal: PolicyOperationPrincipal, 
                               isManagedPolicy: boolean, id: string, policyName: string, 
                               accountId: string, accessToken: string) => {

  if (operation === PolicyOperation.ATTACH) {
    if (principal === PolicyOperationPrincipal.SERVICE) {
      if (!isManagedPolicy) {
        return PolicyClient.attachPolicyToService(accessToken, id, policyName);
      } else {
        return AuthorizationServiceClient.attachManagedPolicyToService(accessToken, id, policyName, accountId);
      }
    } else if (principal === PolicyOperationPrincipal.USER) {
      if (!isManagedPolicy) {
        return PolicyClient.attachPolicyToUser(accessToken, id, policyName);
      } else {
        return AuthorizationServiceClient.attachManagedPolicyToUser(accessToken, id, policyName, accountId);
      }
    } else if (principal === PolicyOperationPrincipal.GROUP) {
      if (!isManagedPolicy) {
        return PolicyClient.attachPolicyToGroup(accessToken, id, policyName);
      } else {
        return AuthorizationServiceClient.attachManagedPolicyToGroup(accessToken, id, policyName, accountId);
      }
    }
  } else {
    if (principal === PolicyOperationPrincipal.SERVICE) {
      if (!isManagedPolicy) {
        return PolicyClient.detachPolicyFromService(accessToken, id, policyName);
      } else {
        return AuthorizationServiceClient.detachManagedPolicyFromService(accessToken, id, policyName, accountId);
      }
    } else if (principal === PolicyOperationPrincipal.USER) {
      if (!isManagedPolicy) {
        return PolicyClient.detachPolicyFromUser(accessToken, id, policyName);
      } else {
        return AuthorizationServiceClient.detachManagedPolicyFromUser(accessToken, id, policyName, accountId);
      }
    } else if (principal === PolicyOperationPrincipal.GROUP) {
      if (!isManagedPolicy) {
        return PolicyClient.detachPolicyFromGroup(accessToken, id, policyName);
      } else {
        return AuthorizationServiceClient.detachManagedPolicyFromGroup(accessToken, id, policyName, accountId);
      }
    }
  }
  return Promise.resolve();
};

const setStatusMessage = (id: string, principal: PolicyOperationPrincipal, operation: PolicyOperation) =>
  (dispatch: any, getState: () => AppSchema) => {
  const state = getState();
  const policies = getPolicyBulkRequest(state);

  let successRequests = [];
  for (let i = 0; i < policies.length; i++) {
    if (policies[i].getPolicyStatus() === PolicyStatus.SUCCESS) {
      successRequests.push(policies[i]);
    }
  }

  if (successRequests.length === policies.length) {
    const analytics = {
      ATTACH_POLICY_TO_SERVICE_SUCCESS: attachPolicyToServiceSuccess(),
      ATTACH_POLICY_TO_USER_SUCCESS: attachPolicyToUserSuccess(),
      ATTACH_POLICY_TO_GROUP_SUCCESS: attachPolicyToGroupSuccess(),
      DETACH_POLICY_FROM_SERVICE_SUCCESS: detachPolicyFromServiceSuccess(),
      DETACH_POLICY_FROM_USER_SUCCESS: detachPolicyFromUserSuccess(),
      DETACH_POLICY_FROM_GROUP_SUCCESS: detachPolicyFromGroupSuccess(),
    };
    const toOrFrom = (operation === PolicyOperation.ATTACH ? "to" : "from");
    const success = (operation + "_POLICY_" + toOrFrom + "_" + principal + "_SUCCESS").toUpperCase();
    dispatch(analytics[success]);

    dispatch(setSuccessMessage(`${capitalize(operation)}ed policies ${operation === PolicyOperation.ATTACH ? "to" : "from" } ${principal} ${id}`));
    return dispatch(hideLoadingIndicator());
  } else {
    const analytics = {
      ATTACH_POLICY_TO_SERVICE_FAILED: attachPolicyToServiceFailed(),
      ATTACH_POLICY_TO_USER_FAILED: attachPolicyToUserFailed(),
      ATTACH_POLICY_TO_GROUP_FAILED: attachPolicyToGroupFailed(),
      DETACH_POLICY_FROM_SERVICE_FAILED: detachPolicyFromServiceFailed(),
      DETACH_POLICY_FROM_USER_FAILED: detachPolicyFromUserFailed(),
      DETACH_POLICY_FROM_GROUP_FAILED: detachPolicyFromGroupFailed(),
    };
    const toOrFrom = (operation === PolicyOperation.ATTACH ? "to" : "from");
    const failed = (operation + "_POLICY_" + toOrFrom + "_" + principal + "_FAILED").toUpperCase();
    dispatch(analytics[failed]);

    dispatch(setErrorMessage(`Failed to ${operation} policies ${operation === PolicyOperation.ATTACH ? "to" : "from" } ${principal} ${id}`));
  }

  return dispatch(hideLoadingIndicator());
};
