import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DeviceEnrollmentWizardState from "../models/DeviceEnrollmentWizardState";
import {
  SubmitApiRequestView,
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
  ThemeProvider,
} from "@components";
import {
  DEVICE_ENROLLMENT_WIZARD_FILE_UPLOAD_STEPS as DEFAULT_STEPS,
  DEVICE_ENROLLMENT_WIZARD_STEPS_LABELS as DEFAULT_STEP_LABELS,
  DeviceEnrollmentWizardStep,
} from "../steps";
import styles from "./styles";
import theme from "./theme";

export interface Model extends SubmitApiRequestViewModel<DeviceEnrollmentWizardState, DeviceEnrollmentWizardStep> {
  stepLabels?: { [key: string]: string };
}

export interface Actions extends SubmitApiRequestViewActions<DeviceEnrollmentWizardState, DeviceEnrollmentWizardStep> {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DeviceEnrollmentWizard = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    currentState = DeviceEnrollmentWizardState.EMPTY,
    defaultState = DeviceEnrollmentWizardState.EMPTY,
    snackbarId = "device-enrollment-wizard",
    errorTitle = "Enrollment Failed",
    steps = DEFAULT_STEPS,
    stepLabels = DEFAULT_STEP_LABELS,
    mapStepToLabel = React.useCallback(step => stepLabels[step] || null, [stepLabels]),
    children,
    ...otherProps
  } = props;

  return (
    <ThemeProvider theme={theme}>
      <SubmitApiRequestView
        {...otherProps}
        className={classnames("deviceEnrollmentWizard", className, classes.container)}
        snackbarId={snackbarId}
        errorTitle={errorTitle}
        steps={steps}
        currentState={currentState}
        defaultState={defaultState}
        mapStepToLabel={mapStepToLabel}
      >
        {children}
      </SubmitApiRequestView>
    </ThemeProvider>
  );
});

export default DeviceEnrollmentWizard;
