import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import sqlFormatter from "@sqltools/formatter";
import { Typography, useMediaQuery } from "@material-ui/core";
import TableIcon from "@material-ui/icons/ViewListSharp";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import AceEditor, { AceOptions, EditorProps } from "react-ace";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  styles,
  accordionStyle,
  accordionSummaryStyle,
  accordionDetailsStyle,
  accordionColumnDetailsStyle
} from "./styles";
import {
  QueryDatabaseTable,
  WorkloadQueryType,
  QueryWorkloadAttributes,
  DEFAULT_WORKLOAD_QUERY_TYPE_LABELS,
} from "@data";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  MissingAlertView,
  AlertSeverity,
  DropdownMenu,
  Autocomplete,
  Button
} from "@components";
import "brace/theme/tomorrow";
import "brace/ext/searchbox";
import "brace/mode/mysql";

const DEFAULT_EDITOR_PROPS = {
  $blockScrolling: Infinity,
};

export interface WorkloadQueryEditorModel {
  className?: string;
  name?: string;
  mode?: string;
  sql?: string;
  width?: string;
  height?: string;
  readOnly?: boolean;
  tabSize?: number;
  fontSize?: number;
  showGutter?: boolean;
  wrapEnabled?: boolean;
  showPrintMargin?: boolean;
  highlightActiveLine?: boolean;
  debounceChangePeriod?: number;
  editorProps?: EditorProps;
  aceOptions?: AceOptions;
  marginTop?: boolean;
  loadingTables: boolean;
  loadingDatabases: boolean;
  overwrite: boolean;
  upload: boolean;
  tableName: string;
  databases: string[];
  databaseName: string;
  dataSources?: string[];
  tables: QueryDatabaseTable[];
  queryType: WorkloadQueryType;
  queryTypeLabels?: WorkloadQueryType;
  disableDatabaseName?: boolean;
  disableUpload?: boolean;
  children?: React.ReactNode;
}

export interface WorkloadQueryEditorActions {
  loadTables: () => void;
  loadDatabases: () => void;
  setQueryData: (data: QueryWorkloadAttributes) => void;
  mapWorkloadQueryTypeToLabel?: (type: WorkloadQueryType) => React.ReactNode | string | null;
}

type Props = WithStyles<typeof styles> & WorkloadQueryEditorModel & WorkloadQueryEditorActions;

const DatabaseAccordion = withStyles(accordionStyle)(Accordion);
const DatabaseAccordionSummary = withStyles(accordionSummaryStyle)(AccordionSummary);
const DatabaseAccordionDetails = withStyles(accordionDetailsStyle)(AccordionDetails);
const ColumnsAccordionDetails = withStyles(accordionColumnDetailsStyle)(AccordionDetails);

export const WorkloadQueryEditor = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    name = "workloadQueryEditor",
    mode = "mysql",
    sql = "{}",
    width = "auto",
    height = "auto",
    readOnly,
    tabSize = 2,
    fontSize = 12,
    showGutter,
    wrapEnabled = true,
    showPrintMargin,
    highlightActiveLine = true,
    debounceChangePeriod = 0,
    editorProps = DEFAULT_EDITOR_PROPS,
    aceOptions = {},
    marginTop = false,
    databaseName = "",
    tableName = "",
    tables = [],
    databases = [],
    dataSources = [],
    upload = false,
    overwrite = false,
    loadingDatabases = false,
    loadingTables = false,
    disableDatabaseName = false,
    disableUpload = false,
    queryType = WorkloadQueryType.SELECT,
    queryTypeLabels = DEFAULT_WORKLOAD_QUERY_TYPE_LABELS,
    mapWorkloadQueryTypeToLabel = React.useCallback((type: WorkloadQueryType) =>
      queryTypeLabels[type] || null, [queryTypeLabels]),
    loadDatabases = noop,
    loadTables = noop,
    setQueryData = noop,
    children,
  } = props;

  const smallViewport = useMediaQuery("(max-width:1300px)");

  const databaseOptions = React.useMemo(() =>
    Array.from(new Set(databases)), [databases]);

  const tableOptions = React.useMemo(() =>
    Array.from(new Set(tables.map(({ name: n }) => n))), [tables]);

  const databaseNameLabel = React.useMemo(() => {
    return !isEmptyString(databaseName) ? databaseName :
      (loadingDatabases ? "Loading..." : "No database selected");
  }, [databaseName, loadingDatabases]);

  const tableNameLabel = React.useMemo(() => {
    return !isEmptyString(tableName) ? tableName :
      (loadingTables ? "Loading..." : "No table selected");
  }, [tableName, loadingTables]);

  const tableColumns = React.useMemo(() => {
    return tables.find(({ name: schemaName }) => schemaName === tableName)?.getColumns() || [];
  }, [tables, tableName]);

  const setSQL = React.useCallback((sqlQuery) => {
    setQueryData({ query: sqlQuery });
  }, [setQueryData]);

  const beautifySQL = React.useCallback(() => {
    setSQL(sqlFormatter.format(sql));
  }, [setSQL, sql]);

  const setUpload = React.useCallback((value) => {
    setQueryData({ upload: value === "TRUE" });
  }, [setQueryData]);

  const setOverwrite = React.useCallback((value) => {
    setQueryData({ overwrite: value === "TRUE" });
  }, [setQueryData]);

  const setQueryType = React.useCallback((type) => {
    setQueryData({
      type: type,
      query: type === WorkloadQueryType.SELECT ?
        `SELECT * FROM ${databaseName}.${tableName}` :
        `INSERT INTO ... SELECT ... FROM ${databaseName}.${tableName}`
    });
  }, [setQueryData, databaseName, tableName]);

  const setDatabaseName = React.useCallback((updatedValue) => {
    setQueryData({
      databaseName: updatedValue[0],
      tableName: "",
      upload: false,
      overwrite: false,
      type: WorkloadQueryType.SELECT,
      query: ""
    });
  }, [setQueryData]);

  const setTableName = React.useCallback((updatedValue) => {
    setQueryData({
      tableName: updatedValue[0],
      upload: false,
      overwrite: false,
      type: WorkloadQueryType.SELECT,
      query: `SELECT * FROM ${databaseName}.${updatedValue[0]}`
    });
  }, [setQueryData, databaseName]);

  React.useEffect(() => !isEmptyString(tableName) ? loadTables() : noop, []);

  const databaseHelperText = React.useMemo(() =>
    `Select the database that the query workload will use ` +
    `based on the selected data set input${dataSources.length > 1 ? "s" : ""} ${dataSources.join(", ")}.`,
    [dataSources]);

  return (
    <div
      className={classnames("panels", {
        [classes.panelsCol]: smallViewport,
        [classes.panelsRow]: !smallViewport,
      })}
    >
      <div className={classnames("queryEditorOptions", classes.queryEditorOptions)}>
        {children}
        <Autocomplete
          disabled={disableDatabaseName}
          label={"Database"}
          className={classnames("selectDatabase", classes.dropdown, classes.database)}
          placeholder={isEmptyString(databaseName) ? "Select a database name" : ""}
          helperText={databaseHelperText}
          loadingText="Loading databases..."
          loading={loadingDatabases}
          options={databaseOptions}
          value={databaseName.length > 0 ? [databaseName] : []}
          maxNumSelectedValues={1}
          onOpen={loadDatabases}
          setValue={setDatabaseName}
        />
        {!isEmptyString(databaseName) && (
          <Autocomplete
            label={"Table"}
            className={classnames("selectTable", classes.dropdown, classes.database)}
            placeholder={isEmptyString(tableName) ? "Select a table name" : ""}
            helperText="Select the table that the query workload will use"
            loadingText="Loading tables..."
            loading={loadingTables}
            options={tableOptions}
            value={tableName.length > 0 ? [tableName] : []}
            maxNumSelectedValues={1}
            onOpen={loadTables}
            setValue={setTableName}
          />
        )}
        {!isEmptyString(databaseName) && !isEmptyString(tableName) && (
          <React.Fragment>
            <DropdownMenu
              className={classnames("queryType", classes.dropdown)}
              selectClassName={classes.dropdownMenuSelect}
              fullWidth={true}
              variant="outlined"
              dropdownMenuLabel="Query Type"
              dropdownMenuHint="Select the query type"
              hideEmptyValue={true}
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
              mapValueToLabel={mapWorkloadQueryTypeToLabel}
              emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
              values={Object.keys(WorkloadQueryType)}
              selectedValue={queryType}
              setSelectedValue={setQueryType}
            />
            <DropdownMenu
              className={classnames("overwrite", classes.dropdown)}
              selectClassName={classes.dropdownMenuSelect}
              fullWidth={true}
              variant="outlined"
              dropdownMenuLabel="Overwrite"
              dropdownMenuHint="Overwrite the existing data"
              hideEmptyValue={true}
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
              emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
              values={["TRUE", "FALSE"]}
              selectedValue={overwrite.toString().toUpperCase()}
              setSelectedValue={setOverwrite}
            />
            {queryType === WorkloadQueryType.SELECT && (
              <DropdownMenu
                disabled={disableUpload}
                className={classnames("upload", classes.dropdown)}
                selectClassName={classes.dropdownMenuSelect}
                fullWidth={true}
                variant="outlined"
                dropdownMenuLabel="Upload"
                dropdownMenuHint="Upload the results of the query to the specified destination table"
                hideEmptyValue={true}
                dropdownMenuLabelClassName={classes.dropdownMenuLabel}
                emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
                values={["TRUE", "FALSE"]}
                selectedValue={upload.toString().toUpperCase()}
                setSelectedValue={setUpload}
              />
            )}
          </React.Fragment>
        )}
      </div>
      <div className={classnames("queryEditorContainer", classes.queryEditorContainer)}>
        <div className={classnames("queryHeaderContainer", classes.queryHeaderContainer)}>
          <div className={classnames("queryHeaderTitle", classes.queryHeaderTitle)}>
            SQL Query Editor
            <Button
              onClick={beautifySQL}
              className={classnames("beautifyButton", classes.beautifyButton)}
            >
              Beautify
            </Button>
          </div>
        </div>
        <div className={classnames("queryContent", classes.queryContent)}>
          <div key={databaseName} className={classnames("databaseItem", classes.databaseItem)}>
            <DatabaseAccordion expanded={!isEmptyString(databaseName)}>
              <DatabaseAccordionSummary
                id={`${databaseName}-header`}
                aria-controls={`${databaseName}-content`}
                expandIcon={loadingDatabases ? <CircularProgress size={16} />
                  : <ArrowRightIcon fontSize={"small"} />}
              >
                <Typography>{databaseNameLabel}</Typography>
              </DatabaseAccordionSummary>
              <DatabaseAccordionDetails className={"databaseTables"}>
                <DatabaseAccordion
                  expanded={!isEmptyString(tableName) && tableColumns.length > 0}
                  className={"databaseTableItem"}
                >
                  <DatabaseAccordionSummary
                    aria-controls={`${databaseName}-${tableName}-content`}
                    id={`${databaseName}-${tableName}-header`}
                    expandIcon={loadingTables ? <CircularProgress size={16} />
                      : <ArrowRightIcon fontSize={"small"} />}
                  >
                    <Typography>{tableNameLabel}</Typography>
                  </DatabaseAccordionSummary>
                </DatabaseAccordion>
              </DatabaseAccordionDetails>
              {tableColumns.map(({ name: colName, type: colType }) => (
                <ColumnsAccordionDetails className={"tableColumns"} key={`${colName}-${colType}`}>
                  <DatabaseAccordion expanded={false} className={"tableColumns"} >
                    <DatabaseAccordionSummary
                      aria-controls={`${databaseName}-${tableName}-tableColumns`}
                      id={`${databaseName}-${tableName}-tableColumns`}
                      expandIcon={<TableIcon fontSize={"inherit"} />}
                    >
                      <Typography>
                        {colName} <span className={classnames("colType", classes.colType)}>({colType})</span>
                      </Typography>
                    </DatabaseAccordionSummary>
                  </DatabaseAccordion>
                </ColumnsAccordionDetails>
              ))}
            </DatabaseAccordion>
          </div>
          <div
            className={classnames(className, classes.container, {
              [classes.marginTop]: marginTop,
            })}
          >
            {!isEmptyString(sql) && isEmptyString(databaseName) && isEmptyString(tableName) && (
              <MissingAlertView
                className={classnames("queryAlert", classes.queryAlert)}
                severity={AlertSeverity.WARNING}
                showAction={false}
                message={"A database and table name must be selected from the dropdown menu"}
              />
            )}
            {!isEmptyString(sql) && !isEmptyString(databaseName) && isEmptyString(tableName) && (
              <MissingAlertView
                className={classnames("queryAlert", classes.queryAlert)}
                severity={AlertSeverity.WARNING}
                showAction={false}
                message={"A table name must be selected from the dropdown menu"}
              />
            )}
            <AceEditor
              className={classnames("sqlEditor", classes.sqlEditor)}
              name={name}
              mode={mode}
              theme="tomorrow"
              value={sql}
              width={width}
              height={height}
              tabSize={tabSize}
              readOnly={readOnly}
              fontSize={fontSize}
              showGutter={showGutter}
              wrapEnabled={wrapEnabled}
              showPrintMargin={showPrintMargin}
              highlightActiveLine={!readOnly && highlightActiveLine}
              debounceChangePeriod={debounceChangePeriod}
              editorProps={editorProps}
              setOptions={aceOptions}
              onChange={setSQL}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default WorkloadQueryEditor;
