import React from "react";
import { isEmptyString, noop } from "@util";
import {
  ActionMenuItem,
  DetailsView,
  SummaryView,
  DetailsViewRoute,
  DEFAULT_EMAIL_ACTION_MENU_ITEMS,
  EmailTemplateAction,
  HtmlBodyView,
  HtmlBodyViewModel,
  HtmlBodyViewActions
} from "@components";
import { PrimaryIcon } from "./styles";
import { EmailTemplateMessageType, SummaryViewData } from "@data";
import { mapMessageTypeToOptionName } from "@modules/emailTemplateWizard/components/TemplateView";

export interface Model extends HtmlBodyViewModel {
  loading?: boolean;
  showNotFound?: boolean;
  showAccessDenied?: boolean;
  errorMessage?: string;
  title?: string;
  actionsLabel?: string;
  actions?: ActionMenuItem[];
  templateId?: string;
  applicationId?: string;
  language?: string;
  messageType?: string;
  s3Bucket?: string;
  s3Key?: string;
  subject?: string;
  description?: string;
  textBody?: string;
}

export interface Actions extends HtmlBodyViewActions {
  editEmailTemplate?: () => void;
  deleteEmailTemplate?: (templateId: string) => void;
  onClickAction?: (action: ActionMenuItem) => void;
  refresh?: () => void;
}

type Props = Model & Actions;

export const EmailTemplateDetails = (props: Props) => {

  const {
    loading,
    showNotFound,
    showAccessDenied,
    errorMessage,
    actionsLabel = "Actions",
    actions = [],
    title = "Email Template Details",
    children,
    editEmailTemplate = noop,
    deleteEmailTemplate = noop,
    onClickAction = noop,
    refresh,
    templateId,
    applicationId,
    language,
    messageType = EmailTemplateMessageType.NONE,
    s3Bucket,
    s3Key,
    subject,
    description,
    textBody,
    htmlBody,
    ...otherProps
  } = props;

  const summaryViewItems = React.useMemo(() => [
    new SummaryViewData({
      className: "templateId",
      name: "Template ID",
      value: templateId,
    }),
    new SummaryViewData({
      className: "applicationId",
      name: "Application ID",
      value: applicationId,
    }),
    new SummaryViewData({
      className: "language",
      name: "Language",
      value: language,
    }),
    new SummaryViewData({
      className: "messageType",
      name: "Message Type",
      value: mapMessageTypeToOptionName(messageType as EmailTemplateMessageType),
    }),
    new SummaryViewData({
      className: "s3Bucket",
      name: "s3 Bucket",
      value: s3Bucket,
    }),
    new SummaryViewData({
      className: "s3Key",
      name: "s3 Key",
      value: s3Key,
    }),
    new SummaryViewData({
      className: "description",
      name: "Description",
      value: description,
    }),
    new SummaryViewData({
      className: "subject",
      name: "Email Subject",
      value: subject,
    }),
    new SummaryViewData({
      className: "textBody",
      name: "Email Text Body",
      value: textBody,
    }),
  ], [
    templateId,
    applicationId,
    language,
    messageType,
    s3Bucket,
    s3Key,
    description,
    subject,
    textBody,
  ]);

  const TemplateSummaryView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <SummaryView
        items={summaryViewItems}
      >
        {!isEmptyString(htmlBody) && (
            <HtmlBodyView
              htmlBody={htmlBody}
              {...otherProps}
            />
          )}
      </SummaryView>
    );
  }, [summaryViewItems, htmlBody, otherProps]);

  const route: DetailsViewRoute[] = React.useMemo(() => [
    {
      id: "summaryView",
      name: "Summary",
      view: TemplateSummaryView,
    }
  ], [TemplateSummaryView]);

  const actionClicked = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case EmailTemplateAction.EDIT_EMAIL_TEMPLATE:
        return editEmailTemplate();
      case EmailTemplateAction.DELETE_EMAIL_TEMPLATE:
        return deleteEmailTemplate(templateId);
      default:
        return onClickAction(action);
    }
  }, [templateId, editEmailTemplate, deleteEmailTemplate, onClickAction]);

  return (
    <DetailsView
      icon={PrimaryIcon}
      title={title}
      refresh={refresh}
      routes={route}
      tabsMarginTop={false}
      actions={DEFAULT_EMAIL_ACTION_MENU_ITEMS}
      onClickAction={actionClicked}
      showLoadingIndicator={loading}
      errorMessage={errorMessage}
      showAccessDenied={showAccessDenied}
      showNotFound={showNotFound}
    />
  );
};

export default EmailTemplateDetails;
