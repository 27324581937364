import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";
import { ApprovalRequestType, RequestState } from "@data/DataApprovalRequest";

export interface AccessRequestApprovalDetailAttributes {
  approvalRequestId: string;
  approvalRequestType: ApprovalRequestType;
  requestState: RequestState;
  approvers: string[];
  approvalComments: string;
  approvedAt: string;
  approvedBy: string;
}

export class AccessRequestApprovalDetail extends Record({
  approvalRequestId: "",
  approvalRequestType: ApprovalRequestType.DATA_SET_ACCESS_REQUEST_DO,
  requestState: RequestState.PENDING,
  approvers: [],
  approvalComments: "",
  approvedAt: "",
  approvedBy: "",
}) implements AccessRequestApprovalDetailAttributes {

  public static EMPTY: AccessRequestApprovalDetail = new AccessRequestApprovalDetail();

  public readonly approvalRequestId: string;
  public readonly approvalRequestType: ApprovalRequestType;
  public readonly requestState: RequestState;
  public readonly approvers: string[];
  public readonly approvalComments: string;
  public readonly approvedAt: string;
  public readonly approvedBy: string;

  public getApprovalRequestId(): string {
    return getStringValue(this.approvalRequestId);
  }

  public getApprovalRequestType(): ApprovalRequestType {
    return this.approvalRequestType;
  }

  public getRequestState(): RequestState {
    return this.requestState;
  }

  public getApprovers(): string[] {
    const approvers = Array.isArray(this.approvers) ? this.approvers : [];
    return approvers
      .map(approver => getStringValue(approver.split(":").pop()))
      .filter(approver => !isEmptyString(approver))
      .sort();
  }

  public getApprovalComments(): string {
    return getStringValue(this.approvalComments);
  }

  public getApprovedAt(): string {
    return this.approvedAt;
  }

  public getApprovedBy(): string {
    return getStringValue(this.approvedBy).split(":").pop() || "";
  }
}
