import React from "react";
import classnames from "classnames";
import Button from "@material-ui/core/Button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { clickHandler } from "../../../../util";
import { styles } from "./styles";

export const SupportButton = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  label: string,
  buttonLabel: string,
  href?: string,
  onClick?: () => void,
}) => {

  const { classes, className, label, buttonLabel, href, onClick } = props;

  return (
    <div className={classnames("supportButton", className, classes.container)}>
      <label className={classnames("label", classes.label)}>{label}</label>
      <Button
        className={classnames("button", classes.button)}
        href={href}
        onClick={clickHandler(onClick)}
      >
        {buttonLabel}
      </Button>
    </div>
  );
});

export default SupportButton;
