import { Module } from "@data";
import { initialize } from "./actions";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./styles";
import view from "./containers/ManagedPolicyDetails";

type RouteParams = { id?: string; accountId?: string; };

export const managedPolicyDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: true,
  name: "Managed Policy Details",
  title: "Managed Policy Details",
  className: "managedPolicyDetailsModule",
  primaryColor,
  icon,
  view,
  onMount: ({ id = "", accountId = "" }: RouteParams = {}) => initialize(id, accountId),
});

export default managedPolicyDetailsModule;
