import { createStyles } from "@material-ui/core/styles";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "inline-flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      padding: "6px 16px",
      marginLeft: 15,
      "&:first-child": {
        marginLeft: 0,
      },
    },
    createDataSetRequestButton: {
    },
  });

export default styles;
