import React from "react";
import classnames from "classnames";
import DefaultIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  ActionMenuItem,
  ActionsMenu,
  ErrorView,
  NotFoundView,
  RefreshButton,
  AccessDeniedView,
  AutoRefresh,
} from "@components";
import {
  UseApiRequestActions,
  UseApiRequestModel,
} from "@hooks";
import DetailsViewRoute from "./DetailsViewRoute";
import DetailsViewTabs, { DetailsViewTabVariant } from "./DetailsViewTabs";
import styles from "./styles";
import { isEmptyString, noop } from "@util";
import { useRouteMatch } from "react-router-dom";

export interface DetailsViewModel extends Partial<UseApiRequestModel> {
  className?: string;
  contentClassName?: string;
  header?: React.ReactNode;
  showHeader?: boolean;
  subheader?: React.ReactNode;
  icon?: React.ComponentType<SvgIconProps>;
  title?: string;
  errorTitle?: string;
  routes?: DetailsViewRoute[];
  actions?: ActionMenuItem[];
  actionsLabel?: string;
  actionsMenuDisabled?: boolean;
  tabsMarginTop?: boolean;
  customTabs?: React.ReactNode;
  headerButtons?: React.ReactNode;
  refreshMessage?: string;
  hideRefreshButton?: boolean;
  autoRefresh?: boolean;
  refreshInterval?: number;
  retries?: number;
  tabVariant?: DetailsViewTabVariant;
  statusCode?: number;
}

export interface DetailsViewActions extends Partial<UseApiRequestActions> {
  onClickAction?: (action: ActionMenuItem) => void;
  onTabChange?: (tab?: string) => void;
}

type Model = DetailsViewModel;
type Actions = DetailsViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

const LoadingPlaceholderView = () => (
  <Typography variant="h3" style={{ margin: "25px 0 0", fontWeight: 300 }}>
    Loading...
  </Typography>
);

export const DetailsView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    contentClassName,
    header,
    showHeader = true,
    subheader,
    icon: PrimaryIcon = DefaultIcon,
    title = "Overview",
    routes = [],
    showLoadingIndicator,
    actions,
    actionsLabel = "Actions",
    actionsMenuDisabled = showLoadingIndicator,
    customTabs,
    errorTitle,
    errorMessage,
    errors,
    tabVariant,
    tabsMarginTop = true,
    showNotFound = false,
    showAccessDenied = false,
    hideRefreshButton,
    refresh,
    onClickAction,
    onTabChange = noop,
    children,
    refreshMessage,
    headerButtons,
    autoRefresh = false,
    refreshInterval,
    retries,
    statusCode,
  } = props;

  const detailViewRoutes = React.useMemo<DetailsViewRoute[]>(() =>
    routes
      .filter(({ disabled }) => !disabled)
      .map(({ view, ...route }) => ({
        ...route,
        view: showLoadingIndicator ? LoadingPlaceholderView : view,
      })), [routes, showLoadingIndicator]);

  const match = useRouteMatch();
  const { url: baseUrl = "" } = match || {};

  // Initial tab should default to the first non-hidden tab
  const findInitialTab = React.useCallback(() =>
    detailViewRoutes.find(({ hidden }) => !hidden)?.name || 0, [detailViewRoutes]);
  const [activeTab, setActiveTab] = React.useState<string | number>(findInitialTab());

  routes.forEach(({ name: label, path = "", hidden, disabled: disabledRoute }) => {
    const isMatch = useRouteMatch(baseUrl + path);
    if (isMatch && isMatch.isExact) {
      if (`${activeTab}` !== label && !hidden && !disabledRoute) {
        setActiveTab(label);
      }
    }
  });

  const validTab = React.useCallback((tab) => {
    return detailViewRoutes.some(({ name, hidden }) => name === tab && !hidden) ? tab : findInitialTab();
  }, [detailViewRoutes, findInitialTab]);

  React.useEffect(() => onTabChange(`${activeTab}`), [onTabChange, activeTab]);

  if (showNotFound) { return <NotFoundView/>; }

  if (showAccessDenied) { return <AccessDeniedView/>; }

  return (
    <div className={classnames("detailsView", className, classes.container)}>
      <ErrorView
        className={classnames("error", classes.errorView)}
        title={errorTitle}
        message={errorMessage}
        errors={errors}
        statusCode={statusCode}
      />
      {showHeader && (
        <React.Fragment>
          {header}
          <div className={classnames("header", classes.header)}>
            <PrimaryIcon className={classnames("icon", classes.icon)} />
            <h1 className={classnames("title", classes.title)}>{title}</h1>
            {!hideRefreshButton && (
              <RefreshButton
                className={classnames("refreshButton", classes.refreshButton)}
                loading={showLoadingIndicator}
                refresh={refresh}
              />
            )}
            {!isEmptyString(refreshMessage) && (
              <p className={classnames("refreshMessage", classes.refreshMessage)}>{refreshMessage}</p>
            )}
            {autoRefresh && (
              <AutoRefresh
                className={"detailsViewAutoRefresh"}
                autoRefreshEnabled={autoRefresh}
                showLoadingIndicator={showLoadingIndicator}
                refreshInterval={refreshInterval}
                retries={retries}
                refresh={refresh}
              />
            )}
            <div className={classnames("headerButtons", classes.headerButtons)}>
              {headerButtons}
              <ActionsMenu
                className={classnames("actionsMenu", classes.actionsMenu)}
                actions={actions}
                buttonLabel={actionsLabel}
                disabled={actionsMenuDisabled || showLoadingIndicator}
                onClickAction={onClickAction}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      {subheader}
      {detailViewRoutes.length === 0 ? null : (
        <DetailsViewTabs
          className={classnames("controls", classes.controls)}
          contentClassName={contentClassName}
          disabled={showLoadingIndicator}
          routes={detailViewRoutes}
          customTabs={customTabs}
          tabsMarginTop={tabsMarginTop}
          showControls={detailViewRoutes.length > 1}
          loading={showLoadingIndicator}
          tabVariant={tabVariant}
          baseUrl={baseUrl}
          activeTab={validTab(activeTab)}
        />
      )}
      {children}
    </div>
  );
});

export default DetailsView;
