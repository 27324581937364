import createStyles from "@material-ui/core/styles/createStyles";
import { StyleRules } from "@material-ui/core/styles";
import { Color } from "@Color";

export const chip: StyleRules<string> = {
  root: {
    height: 28,
    minWidth: 80,
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
  },
  label: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "12px",
    textTransform: "none",
  },
  colorPrimary: {
    color: Color.WHITE,
    backgroundColor: Color.SIGNIFY_GREEN,
    "&:hover": {
      backgroundColor: Color.SIGNIFY_GREEN2,
    },
    "&:active, &:focus": {
      backgroundColor: Color.SIGNIFY_GREEN2,
    },
  },
  colorSecondary: {
    color: Color.TEXT,
    backgroundColor: Color.WHITE,
    "&:hover": {
      backgroundColor: Color.LIGHT_GREY2,
    },
    "&:active, &:focus": {
      backgroundColor: Color.WHITE,
    },
  },
  icon: {
    width: 12,
    height: 12,
  },
  iconColorPrimary: {
    color: Color.WHITE,
  },
  iconColorSecondary: {
    color: Color.TEXT,
  },
  deleteIcon: {
    width: 12,
    height: 12,
  },
  deleteIconColorPrimary: {
    color: Color.WHITE,
    "&:hover, &:active, &:focus": {
      color: Color.AQUA,
    },
  },
  deleteIconColorSecondary: {
    color: Color.GREY3,
    "&:hover, &:active, &:focus": {
      color: Color.BLACK,
    },
  },
};

export const textField: StyleRules<string> = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      color: Color.BLACK,
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& input": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      padding: "8px 10px",
      margin: 0,
    },
  },
};

export const loadingIndicator: StyleRules<string> = {
  root: {
    width: 28,
    height: 28,
    verticalAlign: "middle",
    margin: 0,
    padding: 0,
  },
  svg: {
    width: 16,
    height: 16,
    margin: 0,
    padding: 0,
  },
  circle: {
    width: 16,
    height: 16,
  },
};

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginTop: 25,
    },
    errorMessage: {
      display: "flex",
      flexFlow: "column nowrap",
      alignSelf: "stretch",
      marginTop: 0,
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "21px",
    },
    editor: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 16,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    formHelperTextError: {
      fontSize: 12,
    },
    textField: {
      width: 250,
    },
    loadingIndicator: {
      marginLeft: 16,
    },
    addIconButton: {
      width: 28,
      height: 28,
      borderRadius: 28,
      padding: 0,
      backgroundColor: Color.WHITE,
      marginLeft: 16,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.54)",
      },
    },
    addIcon: {
      width: 24,
      height: 24,
    },
    softwareVersions: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginTop: 25,
    },
    disabled: {
      backgroundColor: Color.GREY3,
      "&.softwareVersion": {
        paddingRight: 12,
        "&:active, &:focus": {
          backgroundColor: Color.GREY3,
        },
      },
    },
    softwareVersion: {
      paddingLeft: 4,
      paddingRight: 4,
      marginLeft: 8,
      marginRight: 8,
      "&:first-child": {
        marginLeft: 0,
      },
      "& span": {
        marginLeft: 2,
        marginRight: 2,
      },
    },
    emptyViewLabel: {
      color: Color.GREY3,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 12,
      fontStyle: "italic",
      lineHeight: "14px",
      margin: "2px 6px",
    },
  });
