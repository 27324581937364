import React from "react";
import classnames from "classnames";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import TextField from "@components/text-field";
import { TooltipIconLabel } from "@components";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@components/circular-progress";
import { formEventHandler, isEmptyString, noop } from "@util";
import { formControlLabel, textField, styles } from "./styles";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

const FeedbackTextField = withStyles(textField)(TextField);
const FormControlLabel = withStyles(formControlLabel)(MuiFormControlLabel);

export interface Model extends PortalModuleDialogModel<any> {
  isDialogOpen?: boolean;
  apiLoading?: boolean;
  imageLoading?: boolean;
  errorMessage?: string;
  successMessage?: string;
  image?: string;
  feedback?: string;
  disableScreenshot?: boolean;
}

export interface Actions extends PortalModuleDialogActions<any> {
  closeDialog?: () => void;
  takeScreenshot?: () => void;
  clear?: () => void;
  cancel?: () => void;
  submitFeedback?: () => void;
  setFeedback?: (value: string) => void;
  showFeedbackDialog?: (value: boolean) => void;
}

type Props = WithStyles< typeof styles> & Model & Actions;

export const SendFeedbackDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    errorMessage,
    successMessage,
    image = "",
    feedback = "",
    imageLoading,
    apiLoading,
    disableScreenshot = false,
    closeDialog = noop,
    takeScreenshot = noop,
    clear = noop,
    submitFeedback = noop,
    setFeedback = noop,
    ...otherProps
  } = props;

  return (
    <PortalModuleDialog
      {...otherProps}
      className={classnames("sendFeedbackDialog")}
      title={"Submit Feedback"}
      loading={apiLoading}
      errorMessage={errorMessage}
      onSuccessMessageShown={closeDialog}
      successMessage={successMessage}
      continueButtonLabel={"Submit Feedback"}
      continueButtonDisabled={imageLoading || apiLoading || isEmptyString(feedback)}
      confirm={submitFeedback}
      cancel={closeDialog}
    >
      <div className={classnames("feedbackDialog", classes.container)}>
        <React.Fragment>
          <FeedbackTextField
            className={classnames("feedbackText", classes.textBox)}
            autoComplete="off"
            label="Feedback"
            name="Feedback"
            value={feedback}
            fullWidth={true}
            autoFocus={true}
            multiline={true}
            minRows={3}
            variant="outlined"
            margin="none"
            placeholder="Have feedback? Find a bug? We'd love to hear from you!"
            onChange={formEventHandler(setFeedback)}
          />
          <TooltipIconLabel
            className={classnames("feedbackInfo", classes.feedbackInfo)}
            label={"Please don’t include any sensitive information"}
            iconTooltip={"Sensitive information is any data that should be protected. For example, " +
              "don’t include passwords, tokens, application secrets, and personal details."}
          />
          {!disableScreenshot && (
            <div className={classnames("feedbackInfo", classes.feedbackInfo)}>
              A screenshot will help us better understand your feedback (optional)
            </div>
          )}
          {disableScreenshot && (
            <div className={classnames("disabledScreenshotInfo", classes.feedbackInfo)}>
              <div>Screenshots are not supported on this page. Please provide more detail about the issue.</div>
            </div>
          )}
          <FormControlLabel
            className={classnames("requiredContainer", classes.inputField, classes.requiredContainer)}
            label="Include Screenshot"
            disabled={disableScreenshot}
            control={(
              <Checkbox
                className={classnames("includeScreenshotCheckbox", classes.checkbox)}
                checked={!isEmptyString(image)}
                onChange={isEmptyString(image) ? () => takeScreenshot() : clear}
              />
            )}
          />
          {image && !imageLoading && <img className={"screenshotImage"} width={"80%"} src={image} alt={"Feedback screenshot"}/>}
          {imageLoading && <CircularProgress/>}
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default SendFeedbackDialog;
