import { createStyles, Theme } from "@material-ui/core/styles";
import ModuleIcon from "@material-ui/icons/CalendarToday";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      "& div.nameFilter": {
        marginTop: 0,
      },
    },
    list: {
      marginTop: 0,
    },
    filterSwitch: {
      marginTop: 10,
      "& span": {
        marginLeft: 0,
      }
    },
    filtersMenu: {
      marginLeft: 15,
    },
    appliedFilters: {
      marginTop: 15,
      marginLeft: 4,
      paddingBottom: 20,
      borderBottom: `1px solid ${Color.LIGHT_GREY}`,
      "& + table.list": {
        marginTop: 0,
      },
      "& .searchFilters": {
        marginTop: 13,
        flexFlow: "row nowrap",
        alignItems: "center",
        "& .searchFilter": {
          marginTop: 0,
          marginLeft: 8,
          "&:first-child": {
            marginLeft: 0,
          },
        },
      },
    },
  });

export default styles;
