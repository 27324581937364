import React from "react";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { applicationSecurityView as styles } from "./styles";
import { ApplicationAuthentication, ApplicationAuthenticationProtocol, } from "@data";
import DropdownMenu from "@components/dropdown-menu/DropdownMenu";
import { noop } from "@util";

export const DEFAULT_AUTHENTICATION = [
  ApplicationAuthentication.NONE,
  ApplicationAuthentication.AUTHENTICATED,
];

export interface Model {
  title?: string;
  authentication?: ApplicationAuthentication;
  authentications?: ApplicationAuthentication[];
  protocol?: ApplicationAuthenticationProtocol;
}

export interface Actions {
  setAuthentication?: (auth: ApplicationAuthentication) => void;
  setProtocol?: (protocol: ApplicationAuthenticationProtocol) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ApplicationSecurityView = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Select Application Authentication and Protocol",
    authentication,
    authentications = DEFAULT_AUTHENTICATION,
    protocol = ApplicationAuthenticationProtocol.SHARED_SECRET_1,
    setAuthentication = noop,
    setProtocol = noop,
    children,
  } = props;

  const showProtocol = React.useMemo(() => authentication === ApplicationAuthentication.AUTHENTICATED
  , [authentication]);

  return (
    <div className={classnames("applicationSecurityView", classes.container)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      <DropdownMenu
        className={classnames("applicationAuthentication", classes.dropdownMenu)}
        hideEmptyValue={true}
        dropdownMenuLabel="Application Authentication"
        dropdownMenuHint="Do you need authentication for this application?"
        dropdownMenuLabelClassName={classes.dropdownMenuLabel}
        values={authentications}
        fullWidth={false}
        selectedValue={authentication}
        setSelectedValue={setAuthentication}
      />
      {showProtocol && (
        <DropdownMenu
          className={classnames("applicationAuthenticationProtocol", classes.dropdownMenu)}
          hideEmptyValue={true}
          dropdownMenuLabel="Protocol"
          dropdownMenuHint="Authentication Protocol"
          fullWidth={false}
          dropdownMenuLabelClassName={classes.dropdownMenuLabel}
          values={[ApplicationAuthenticationProtocol.SHARED_SECRET_1]}
          selectedValue={protocol}
          setSelectedValue={setProtocol}
        />
      )}
      {children}
    </div>
  );
});

export default ApplicationSecurityView;
