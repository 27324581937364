import { AppSchema } from "@schemas";
import { reloadApp } from "@main/actions";
import { AppReducer } from "@main/reducers";
import AnalyticsMiddleware from "../analytics";
import cleanLocalStorage from "./cleanLocalStorage";
import thunk, { ThunkMiddleware } from "redux-thunk";
import cleanSessionStorage from "./cleanSessionStorage";
import restorePreviousState from "./restorePreviousState";
import { composeWithDevTools } from "redux-devtools-extension";
import { canRestoreSession, isLoggedIn } from "@main/selectors";
import { applyInProd, equalsIgnoreCase, ErrorDispatcher } from "@util";
import migrateLegacyDataIfNecessary from "./migrateLegacyDataIfNecessary";
import createLocalStorageMiddleware from "./createLocalStorageMiddleware";
import { applyMiddleware, createStore, Store, StoreEnhancer } from "redux";
import createSessionStorageMiddleware from "./createSessionStorageMiddleware";

const DEVELOPMENT_MODE = process.env.NODE_ENV === "development";

const ENABLE_DEV_TOOLS = DEVELOPMENT_MODE &&
  !equalsIgnoreCase("true", process.env.REACT_APP_HIDE_DEV_TOOLS);

const ENABLE_ANALYTICS_MIDDLEWARE =
  !equalsIgnoreCase("true", process.env.REACT_APP_ANALYTICS_DISABLED);

const DEV_TOOLS_OPTIONS = {
  trace: true,
  traceLimit: 25,
};

const createApplicationStore = (): Store<AppSchema> => {

  // Migrate legacy data if internal version code is either not detected or <= current version
  if (migrateLegacyDataIfNecessary()) {
    console.info("Legacy Data Migrated");
  }

  // Assemble previous state from restored local storage data and session storage data
  const previousState = restorePreviousState();

  // Create middleware to keep local storage data that we care about up to date
  const keepLocalStorageUpdated = createLocalStorageMiddleware();

  // Create middleware to keep session storage data that we care about up to date
  const keepSessionStorageUpdated = createSessionStorageMiddleware();

  const Thunk = thunk as ThunkMiddleware<AppSchema>;

  const createProductionMiddleware = (): StoreEnhancer => ENABLE_ANALYTICS_MIDDLEWARE
    ? applyMiddleware(keepLocalStorageUpdated, keepSessionStorageUpdated, AnalyticsMiddleware(), ErrorDispatcher, Thunk)
    : applyMiddleware(keepLocalStorageUpdated, keepSessionStorageUpdated, ErrorDispatcher, Thunk);

  const createDevelopmentMiddleware = (): StoreEnhancer => {

    const middleware = applyMiddleware(
      keepLocalStorageUpdated,
      keepSessionStorageUpdated,
      ErrorDispatcher,
      Thunk,
    );

    return ENABLE_DEV_TOOLS ? composeWithDevTools(DEV_TOOLS_OPTIONS)(middleware) : middleware;
  };

  const preloadedState = AppReducer(previousState, reloadApp());

  const storeEnhancer = applyInProd(createProductionMiddleware, createDevelopmentMiddleware);

  const store = createStore(AppReducer, preloadedState, storeEnhancer);

  const state = store.getState();

  const loggedIn = isLoggedIn(state);

  const sessionRestorable = canRestoreSession(state);

  // TODO: Re-visit this to make sure this is the appropriate place to do this
  cleanLocalStorage(loggedIn, sessionRestorable);

  // TODO: Re-visit this to make sure this is the appropriate place to do this
  cleanSessionStorage(loggedIn);

  return store;
};

export default createApplicationStore;
