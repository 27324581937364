import { Record } from "immutable";
import { getStringValue, isValidNumber } from "@util";

export interface DataLakeIndexAttributes {
  objectKey: string;
  contentLength: string;
  dateIndexed: string;
  bucketName: string;
  region: string;
  eTag: string;
}

export class DataLakeIndex extends Record({
  objectKey: "",
  contentLength: "",
  dateIndexed: "",
  bucketName: "",
  region: "",
  eTag: "",
}) implements DataLakeIndexAttributes {

  public static EMPTY: DataLakeIndex = new DataLakeIndex();

  public readonly objectKey: string;
  public readonly contentLength: string;
  public readonly dateIndexed: string;
  public readonly bucketName: string;
  public readonly region: string;
  public readonly eTag: string;

  public getObjectKey(): string {
    return getStringValue(this.objectKey);
  }

  public getContentLength(): string {
    return getStringValue(this.contentLength);
  }

  public getDateIndexed(): string {
    return getStringValue(this.dateIndexed);
  }

  public getBucketName(): string {
    return getStringValue(this.bucketName);
  }

  public getRegion(): string {
    return getStringValue(this.region);
  }

  public getETag(): string {
    return getStringValue(this.eTag);
  }

  public getFileName(): string {
    return getStringValue(this.getObjectKey().split("/").pop());
  }

  public getFileSize(): string {
    const size = this.getContentLength();
    return isValidNumber(size) ? `${Number(size) / 1000} KB` : size;
  }

  public getFilePath(): string {
    return `s3://${this.getBucketName()}/${this.getObjectKey()}`;
  }
}

export default DataLakeIndex;
