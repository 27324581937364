import React from "react";
import { noop } from "@util";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MODULE_PATH as DETAILS_PAGE_PATH } from "@modules/policyDetails/constants";
import { MODULE_PATH as LIST_VIEW_PATH } from "@modules/policies/constants";
import { close, deletePolicy } from "../actions";
import {
  getErrorMessage,
  getPolicyName,
  getSuccessMessage,
  isAccessDeniedVisible,
  isDialogOpen,
  isPolicyNameValid,
  isProgressIndicatorVisible,
} from "../selectors";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import DeletePolicy from "../components/DeletePolicy";

interface Model extends PortalModuleDialogModel<string> {
  policyName?: string;
}

interface Actions extends PortalModuleDialogActions<string> {
}

type Props = Model & Actions;

const DeletePolicyDialog = (props: Props) => {

  const { policyName, ...otherProps } = props;

  const { cancel: closeDialog = noop } = otherProps;

  const match = useRouteMatch(DETAILS_PAGE_PATH);

  const history = useHistory();

  const showListView = React.useCallback(() => {
    history.push(LIST_VIEW_PATH);
  }, [history]);

  const onSuccessMessageShown = React.useCallback(() => {
    closeDialog();

    if (match) {
      showListView();
    }
  }, [match, closeDialog, showListView]);

  return (
    <PortalModuleDialog {...otherProps} onSuccessMessageShown={onSuccessMessageShown}>
      <DeletePolicy policyName={policyName} />
    </PortalModuleDialog>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  policyName: getPolicyName(state),
  className: "deletePolicyDialog",
  title: "Delete Policy",
  open: isDialogOpen(state),
  item: getPolicyName(state),
  loading: isProgressIndicatorVisible(state),
  accessDenied: isAccessDeniedVisible(state),
  errorMessage: getErrorMessage(state),
  successMessage: getSuccessMessage(state),
  continueButtonLabel: "Delete Policy",
  continueButtonDisabled: !isPolicyNameValid(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Model): Actions => ({
  cancel: () => dispatch(close()),
  confirm: () => dispatch(deletePolicy()),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeletePolicyDialog);
