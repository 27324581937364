import React from "react";
import classnames from "classnames";
import { getPluralString } from "@util";
import { SummaryView } from "@components/summary-view/SummaryView";
import { DataClassification, DataSetState, DataSetType, IngestionMechanism, SummaryViewData } from "@data";
import { getDataClassificationDisplayName } from "@modules/dataSetRequestWizard/helpers";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import { mapStatusToStatusName } from "@components/data-set-requests-list";

export interface DataSetRequestSummaryViewModel {
  className?: string;
  showDataSetAlias?: boolean;
  dataSetAlias?: string;
  description?: string;
  dataSetGroup?: string;
  dataSetType?: DataSetType;
  status?: DataSetState;
  dataOwners?: string;
  architects?: string;
  engineeringContacts?: string;
  businessOwners?: string;
  tags?: string;
  ingestionMechanism?: IngestionMechanism;
  schema?: string;
  sampleAnonymizedData?: string;
  dataClassification?: DataClassification;
  dataOwnerApprovalStatus?: DataSetState;
  businessOwnerApprovalStatus?: DataSetState;
  dataVolume?: number;
  dataVelocity?: number;
  archivingInDays?: number;
  expirationInDays?: number;
  confirmNonProductionData?: boolean;
  termsConditionsId?: string;
  createdAt?: string;
  createdBy?: string;
  containsPiiData?: boolean;
  piiComment?: string;
}

export interface DataSetRequestSummaryViewActions {
}

type Model = DataSetRequestSummaryViewModel;
type Actions = DataSetRequestSummaryViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataSetRequestSummaryView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className = "summaryView",
    showDataSetAlias = true,
    dataSetAlias,
    description,
    dataSetGroup,
    dataSetType,
    status = DataSetState.NONE,
    dataOwners,
    architects,
    engineeringContacts,
    businessOwners,
    tags,
    ingestionMechanism = IngestionMechanism.NONE,
    schema,
    sampleAnonymizedData,
    dataClassification,
    dataOwnerApprovalStatus,
    businessOwnerApprovalStatus,
    dataVolume = 0,
    dataVelocity = 0,
    archivingInDays = 0,
    expirationInDays = 0,
    confirmNonProductionData,
    termsConditionsId,
    createdAt,
    createdBy,
    containsPiiData,
    piiComment,
    children,
  } = props;

  const archivingInDaysLabel = React.useMemo(() =>
      archivingInDays <= 0
        ? "Data Is Never Archived"
        : `Data Archived After ${getPluralString(archivingInDays, { other: "Days", one: "Day" })}`,
    [archivingInDays]);

  const expirationInDaysLabel = React.useMemo(() =>
      expirationInDays <= 0
        ? "Data Does Not Expire"
        : `Data Expires After ${getPluralString(expirationInDays, { other: "Days", one: "Day" })}`,
    [expirationInDays]);

  const summaryItems = React.useMemo(() => [
    new SummaryViewData({
      className: "dataSetAlias",
      name: "Data Set Name",
      value: showDataSetAlias ? dataSetAlias : "",
    }),
    new SummaryViewData({
      className: "description",
      name: "Description",
      value: description,
    }),
    new SummaryViewData({
      className: "dataSetGroup",
      name: "Data Set Group",
      value: dataSetGroup,
    }),
    new SummaryViewData({
      className: "dataSetType",
      name: "Data Set Type",
      value: dataSetType,
    }),
    new SummaryViewData({
      className: "status",
      name: "Status",
      value: mapStatusToStatusName(status),
    }),
    new SummaryViewData({
      className: "dataOwners",
      name: "Data Owners",
      value: dataOwners,
    }),
    new SummaryViewData({
      className: "architects",
      name: "Architects",
      value: architects,
    }),
    new SummaryViewData({
      className: "engineeringContacts",
      name: "Engineering Contacts",
      value: engineeringContacts,
    }),
    new SummaryViewData({
      className: "businessOwners",
      name: "Business Owners",
      value: businessOwners,
    }),
    new SummaryViewData({
      className: "tags",
      name: "Tags",
      value: tags,
    }),
    new SummaryViewData({
      className: "schema",
      name: "Schema",
      value: schema,
    }),
    new SummaryViewData({
      className: "sampleAnonymizedData",
      name: "Sample Anonymized Data",
      value: sampleAnonymizedData,
    }),
    new SummaryViewData({
      className: "dataClassification",
      name: "Data Classification",
      value: getDataClassificationDisplayName(dataClassification),
    }),
    new SummaryViewData({
      className: "dataOwnerApprovalStatus",
      name: "Data Owner Approval Status",
      value: dataOwnerApprovalStatus,
    }),
    new SummaryViewData({
      className: "businessOwnerApprovalStatus",
      name: "Business Owner Approval Status",
      value: businessOwnerApprovalStatus,
    }),
    new SummaryViewData({
      className: "ingestionMechanism",
      name: "Ingestion Mechanism",
      value: ingestionMechanism,
    }),
    new SummaryViewData({
      className: "containsPii",
      name: "Contains PII Data",
      value: `${containsPiiData}`,
    }),
    new SummaryViewData({
      className: "piiComment",
      name: "PII Data Description",
      value: containsPiiData ? piiComment : "",
    }),
    new SummaryViewData({
      className: "dataVolume",
      name: "Data Volume (MB Per Month)",
      value: dataVolume.toString(),
    }),
    new SummaryViewData({
      className: "dataVelocity",
      name: "Data Velocity (Bytes Per Second)",
      value: dataVelocity.toString(),
    }),
    new SummaryViewData({
      className: "confirmNonProductionData",
      name: "Confirm Non-Production Data",
      value: confirmNonProductionData ? `${confirmNonProductionData}` : "",
    }),
    new SummaryViewData({
      className: "archivingInDays",
      name: "Data Archival Policy",
      value: archivingInDaysLabel,
    }),
    new SummaryViewData({
      className: "expirationInDays",
      name: "Data Expiration Policy",
      value: expirationInDaysLabel,
    }),
    new SummaryViewData({
      className: "termsConditionsId",
      name: "Terms Conditions Id",
      value: termsConditionsId,
    }),
    new SummaryViewData({
      className: "createdBy",
      name: "Created By",
      value: createdBy,
      date: false,
    }),
    new SummaryViewData({
      className: "createdAt",
      name: "Created At",
      value: createdAt,
      date: true,
    }),
  ], [
    showDataSetAlias,
    dataSetAlias,
    description,
    dataSetGroup,
    dataSetType,
    status,
    tags,
    dataOwners,
    architects,
    engineeringContacts,
    businessOwners,
    schema,
    sampleAnonymizedData,
    dataOwnerApprovalStatus,
    businessOwnerApprovalStatus,
    containsPiiData,
    piiComment,
    dataClassification,
    ingestionMechanism,
    dataVolume,
    dataVelocity,
    archivingInDaysLabel,
    expirationInDaysLabel,
    termsConditionsId,
    createdBy,
    createdAt,
  ]);

  return (
    <div className={classnames(className, classes.container)}>
      <SummaryView items={summaryItems}/>
      <div className={classnames("content", classes.content)}>
        {children}
      </div>
    </div>
  );
});

export default DataSetRequestSummaryView;
