import React from "react";
import classnames from "classnames";
import { JsonView as JsonViewer } from "@components/json-view";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { reviewView as styles } from "./styles";
import Backdrop from "@material-ui/core/Backdrop";

export interface Model {
  namespace?: string;
  name?: string;
  version?: string;
  json?: string;
  showBackdrop?: boolean;
}

type Props = WithStyles<typeof styles> & Model & {
  children?: React.ReactNode;
};

export const ReviewView = withStyles(styles)((props: Props) => {

  const {
    classes,
    namespace,
    name,
    version,
    json,
    showBackdrop = false,
    children,
  } = props;

  return (
    <div className={classnames("reviewView", classes.container)}>
      <label className={classnames("title", classes.title)}>Review Schema</label>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Namespace: </label>
        <label className={classnames("namespace", classes.propertyValue)}>{namespace}</label>
      </div>
      <div className={classnames(classes.propertyContainer)}>
        <label className={classnames(classes.propertyName)}>Name: </label>
        <label className={classnames("name", classes.propertyValue)}>{name}</label>
      </div>
      {version && (
        <div className={classnames(classes.propertyContainer)}>
          <label className={classnames(classes.propertyName)}>Version: </label>
          <label className={classnames("version", classes.propertyValue)}>{version}</label>
        </div>
      )}
      <JsonViewer
        className={classnames("jsonEditor", classes.jsonEditor)}
        json={json}
        showGutter={false}
        showPrintMargin={false}
      />
      <Backdrop className={classnames("backdrop", classes.backdrop)} open={showBackdrop} />
      {children}
    </div>
  );
});

export default ReviewView;
