import { combineReducers } from "redux";
import { Actions, filterAction, Identity, ReduxAction } from "../../../util/ReducerUtil";

export interface NumberConstraintsSchema {
  minimum: string;
  minimumError: string;
  exclusiveMinimum: string;
  exclusiveMinimumError: string;
  maximum: string;
  maximumError: string;
  exclusiveMaximum: string;
  exclusiveMaximumError: string;
  multipleOf: string;
  multipleOfError: string;
}

export const DEFAULT_STATE: NumberConstraintsSchema = {
  minimum: "",
  minimumError: "",
  exclusiveMinimum: "",
  exclusiveMinimumError: "",
  maximum: "",
  maximumError: "",
  exclusiveMaximum: "",
  exclusiveMaximumError: "",
  multipleOf: "",
  multipleOfError: "",
};

export enum NumberConstraintsActionType {
  SET_MINIMUM = "schema_wizard/number_constraints/set_minimum",
  SET_MINIMUM_ERROR = "schema_wizard/number_constraints/set_minimum_error",
  SET_EXCLUSIVE_MINIMUM = "schema_wizard/number_constraints/set_exclusive_minimum",
  SET_EXCLUSIVE_MINIMUM_ERROR = "schema_wizard/number_constraints/set_exclusive_minimum_error",
  SET_MAXIMUM = "schema_wizard/number_constraints/set_maximum",
  SET_MAXIMUM_ERROR = "schema_wizard/number_constraints/set_maximum_error",
  SET_EXCLUSIVE_MAXIMUM = "schema_wizard/number_constraints/set_exclusive_maximum",
  SET_EXCLUSIVE_MAXIMUM_ERROR = "schema_wizard/number_constraints/set_exclusive_maximum_error",
  SET_MULTIPLE_OF = "schema_wizard/number_constraints/set_multiple_of",
  SET_MULTIPLE_OF_ERROR = "schema_wizard/number_constraints/set_multiple_of_error",
}

export type NumberConstraintsAction = ReduxAction<NumberConstraintsActionType>;

const minimum = filterAction<string>(Actions(
  NumberConstraintsActionType.SET_MINIMUM))(Identity,
  DEFAULT_STATE.minimum);

const minimumError = filterAction<string>(Actions(
  NumberConstraintsActionType.SET_MINIMUM_ERROR))(Identity,
  DEFAULT_STATE.minimumError);

const exclusiveMinimum = filterAction<string>(Actions(
  NumberConstraintsActionType.SET_EXCLUSIVE_MINIMUM))(Identity,
  DEFAULT_STATE.exclusiveMinimum);

const exclusiveMinimumError = filterAction<string>(Actions(
  NumberConstraintsActionType.SET_EXCLUSIVE_MINIMUM_ERROR))(Identity,
  DEFAULT_STATE.exclusiveMinimumError);

const maximum = filterAction<string>(Actions(
  NumberConstraintsActionType.SET_MAXIMUM))(Identity,
  DEFAULT_STATE.maximum);

const maximumError = filterAction<string>(Actions(
  NumberConstraintsActionType.SET_MAXIMUM_ERROR))(Identity,
  DEFAULT_STATE.maximumError);

const exclusiveMaximum = filterAction<string>(Actions(
  NumberConstraintsActionType.SET_EXCLUSIVE_MAXIMUM))(Identity,
  DEFAULT_STATE.exclusiveMaximum);

const exclusiveMaximumError = filterAction<string>(Actions(
  NumberConstraintsActionType.SET_EXCLUSIVE_MAXIMUM_ERROR))(Identity,
  DEFAULT_STATE.exclusiveMaximumError);

const multipleOf = filterAction<string>(Actions(
  NumberConstraintsActionType.SET_MULTIPLE_OF))(Identity,
  DEFAULT_STATE.multipleOf);

const multipleOfError = filterAction<string>(Actions(
  NumberConstraintsActionType.SET_MULTIPLE_OF_ERROR))(Identity,
  DEFAULT_STATE.multipleOfError);

export const NumberConstraintsReducer = combineReducers<NumberConstraintsSchema>({
  minimum,
  minimumError,
  exclusiveMinimum,
  exclusiveMinimumError,
  maximum,
  maximumError,
  exclusiveMaximum,
  exclusiveMaximumError,
  multipleOf,
  multipleOfError,
});

export default NumberConstraintsReducer;
