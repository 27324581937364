import { connect } from "react-redux";
import { AppSchema } from "../../main/schemas";
import { JsonEditorView, Model, Actions } from "../components/JsonEditorView";
import { SchemaWizardActions } from "../actions/schemaWizard";
import { SchemaWizardSelectors } from "../selectors/schemaWizard";

const mapStateToProps = (state: AppSchema): Model => ({
  json: SchemaWizardSelectors.getJson(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  setJson: (json: string) => dispatch(SchemaWizardActions.updateJson(json)),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(JsonEditorView);
