import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";
import AuthenticateServiceRequest, {
  AuthenticateServiceRequestAttributes,
  AuthenticateServiceSecurityAttributes,
} from "./AuthenticateServiceRequest";

interface AuthenticateServiceMfaRequestMfaAttributes {
  code: string;
}

export interface AuthenticateServiceMfaRequestAttributes extends AuthenticateServiceRequestAttributes {
  mfa: AuthenticateServiceMfaRequestMfaAttributes;
}

export class AuthenticateServiceMfaRequest extends Record({
  ...AuthenticateServiceRequest.EMPTY.toJS(),
  mfa: {
    code: "",
  },
}) implements AuthenticateServiceMfaRequestAttributes {

  public static EMPTY: AuthenticateServiceMfaRequest = new AuthenticateServiceMfaRequest();

  public readonly account: string;
  public readonly security: AuthenticateServiceSecurityAttributes;
  public readonly mfa: AuthenticateServiceMfaRequestMfaAttributes;

  public getAccount(): string {
    return getStringValue(this.account);
  }

  public getProtocol(): string {
    const { protocol: protocol = "" } = this.security || {};

    return getStringValue(protocol);
  }

  public getSecret(): string {
    const { data: { secret: secret = "" } } = this.security;

    return getStringValue(secret);
  }

  public getSecurity(): AuthenticateServiceSecurityAttributes {
    return this.security;
  }

  public getMfaCode(): string {
    const { code = "" } = this.mfa || { code: "" };
    return getStringValue(code);
  }

  public hasMfaCode(): boolean {
    return !isEmptyString(this.getMfaCode());
  }

  public toAuthenticateServiceRequest(): AuthenticateServiceRequest {
    const { mfa, ...attrs } = this.toJS();
    return new AuthenticateServiceRequest(attrs);
  }

  public toJSON(): any {
    if (this.hasMfaCode()) {
      return this.toJS();
    } else {
      return this.toAuthenticateServiceRequest().toJS();
    }
  }
}

export default AuthenticateServiceMfaRequest;
