import React from "react";
import classnames from "classnames";
import SortedSearchResultsList, {
  sortAndFilterResults,
  SortedSearchResultsListActions as ListActions,
  SortedSearchResultsListModel as ListModel,
} from "@components/sorted-search-results-list";
import { Application } from "@data";
import ApplicationsListItem from "./ApplicationsListItem";
import ApplicationsListColumn from "./ApplicationsListColumn";
import { filterResultsByNameFilter, mapItemToColumnValue } from "./helpers";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

type Item = Application;
type Column = ApplicationsListColumn;

const DEFAULT_COLUMNS: Column[] = [
  ApplicationsListColumn.ID,
  ApplicationsListColumn.NAME,
  ApplicationsListColumn.DESCRIPTION,
];

const DEFAULT_RENDER_ITEM = (item: Item, column: Column) => (
  <ApplicationsListItem
    item={item}
    column={column}
  />
);

export interface ApplicationsListModel extends ListModel<Item, Column> {
  className?: string;
}

export interface ApplicationsListActions extends ListActions<Item, Column> {
}

type Model = ApplicationsListModel;
type Actions = ApplicationsListActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ApplicationsList = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    nameFilterDelay = 0,
    tableLayoutFixed = false,
    columns = DEFAULT_COLUMNS,
    items = [],
    noResultsLabel = "No applications found",
    summaryViewQuantities = {
      other: "applications",
      one: "application",
    },
    renderItem = DEFAULT_RENDER_ITEM,
    children,
    ...otherProps
  } = props;

  const [nameFilter, setNameFilter] = React.useState("");

  const [sortOrderAscending, setSortOrderAscending] = React.useState(true);

  const [sortByColumn, setSortByColumn] = React.useState(ApplicationsListColumn.NONE);

  const visibleItems = sortAndFilterResults({
    items,
    nameFilter,
    sortByColumn,
    sortOrderAscending,
    filterResultsByNameFilter,
    mapItemToColumnValue,
  });

  return (
    <React.Fragment>
      <SortedSearchResultsList
        {...otherProps}
        className={classnames("applicationsList", className, classes.container)}
        tableLayoutFixed={tableLayoutFixed}
        columns={columns}
        items={visibleItems}
        nameFilter={nameFilter}
        nameFilterDelay={nameFilterDelay}
        sortByColumn={sortByColumn}
        sortOrderAscending={sortOrderAscending}
        noResultsLabel={noResultsLabel}
        summaryViewQuantities={summaryViewQuantities}
        renderItem={renderItem}
        setNameFilter={setNameFilter}
        setSortByColumn={(column: ApplicationsListColumn) => setSortByColumn(column)}
        setSortOrderAscending={setSortOrderAscending}
      />
      {children}
    </React.Fragment>
  );
});

export default ApplicationsList;
