import React from "react";
import classnames from "classnames";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { SuccessView } from "./SuccessView";
import { JsonSchemaMetadata } from "../../../data";
import { styles } from "./styles";
import SchemaInfo from "./SchemaInfo";

export const DeleteView = withStyles(styles)((props: WithStyles<typeof styles> & {
  item?: JsonSchemaMetadata,
  successMessage?: string,
}) => {

  const {
    classes,
    item: schema = JsonSchemaMetadata.EMPTY,
    successMessage = "",
  } = props;

  if (successMessage.length > 0) {
    return <SuccessView className="delete" title="Success" message={successMessage} />;
  }

  return (
    <div className={classnames("delete", "content", classes.content)}>
      <label className={classnames("title", classes.title)}>
        Are you sure you want to delete this schema?
      </label>
      <label className={classnames("subtitle", classes.subtitle)}>
        <span className={classes.warning}>WARNING:</span>This action cannot be undone!
      </label>
      <SchemaInfo schema={schema} />
    </div>
  );
});

export default DeleteView;
