import React from "react";
import classnames from "classnames";
import MuiStep, { StepProps } from "@material-ui/core/Step";

export interface CustomStepProps extends StepProps {}

export const Step = ((props: CustomStepProps) => {

    const {
      className,
      ...otherProps
    } = props;

    return (
      <MuiStep
        {...otherProps}
        className={classnames("step", className)}
      />
    );
});

export default Step;
