import React from "react";
import classnames from "classnames";
import { userManagementView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { SummaryViewWithActions, SummaryViewWithActionsModel } from "@components";

type Props = WithStyles<typeof styles> & SummaryViewWithActionsModel;

export const ApplicationSecurityView = withStyles(styles)((props: Props) => {

  const {
    classes,
    items,
    className,
  } = props;

  return (
    <SummaryViewWithActions
      items={items}
      className={classnames(className, classes.applicationSecurityView)}
    />
  );
});

export default ApplicationSecurityView;
