import React from "react";
import classnames from "classnames";
import {
  getApprovalRequestTypeDisplayName,
  getRequestStateDisplayName,
} from "@modules/dataApprovalRequests/helpers";
import { AccessRequestApprovalDetail } from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import AccessRequestApprovalDetailsListColumn from "../AccessRequestApprovalDetailsListColumn";
import styles from "./styles";

export interface AccessRequestApprovalDetailsListItemModel {
  className?: string;
  item?: AccessRequestApprovalDetail;
  column?: AccessRequestApprovalDetailsListColumn;
}

export interface AccessRequestApprovalDetailsListItemActions {
}

type Model = AccessRequestApprovalDetailsListItemModel;
type Actions = AccessRequestApprovalDetailsListItemActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

const ApproversColumn = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  approvers?: string[],
  children?: React.ReactNode,
}) => {

  const { classes, className, approvers = [], children } = props;

  const showEmptyView = React.useMemo(() => approvers.length === 0, [approvers]);

  return (
    <div
      className={classnames("approversColumn", className, classes.container, classes.approvers)}
    >
      {showEmptyView && (
        <label className={classnames("placeholder", classes.placeholder)}>
          None
        </label>
      )}
      {!showEmptyView && (
        <ul className={classnames("approversList", classes.approversList)}>
          {approvers.map(approver => (
            <li key={approver} className={classnames("approver", classes.approver)}>
              <a
                className={classnames("approverLabel", classes.approverLabel)}
                href={`mailto:${approver}`}
              >
                {approver}
              </a>
            </li>
          ))}
        </ul>
      )}
      {children}
    </div>
  );
});

const ApprovedByColumn = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  approvedBy?: string,
  children?: React.ReactNode,
}) => {

  const { classes, className, approvedBy = "", children } = props;

  return (
    <div
      className={classnames("approvedByColumn", className, classes.container, classes.approvedBy)}
    >
      {!approvedBy && (
        <label className={classnames("placeholder", classes.placeholder)}>
          None
        </label>
      )}
      {approvedBy && (
        <a
          className={classnames("label", classes.approverLabel)}
          href={`mailto:${approvedBy}`}
        >
          {approvedBy}
        </a>
      )}
      {children}
    </div>
  );
});

export const AccessRequestApprovalDetailsListItem = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    item = AccessRequestApprovalDetail.EMPTY,
    column = AccessRequestApprovalDetailsListColumn.NONE,
    children,
  } = props;

  if (AccessRequestApprovalDetail.EMPTY.equals(item) || AccessRequestApprovalDetailsListColumn.NONE === column) {
    return null;
  }

  switch (column) {
    case AccessRequestApprovalDetailsListColumn.APPROVAL_REQUEST_TYPE:
      return (
        <div
          className={classnames("approvalRequestTypeColumn", className, classes.container, classes.approvalRequestType)}
        >
          <label className={classnames("label", classes.label)}>
            {getApprovalRequestTypeDisplayName(item.getApprovalRequestType())}
          </label>
          {children}
        </div>
      );
    case AccessRequestApprovalDetailsListColumn.REQUEST_STATE:
      return (
        <div
          className={classnames("requestStateColumn", className, classes.container, classes.requestState)}
        >
          <label className={classnames("label", classes.label)}>
            {getRequestStateDisplayName(item.getRequestState())}
          </label>
          {children}
        </div>
      );
    case AccessRequestApprovalDetailsListColumn.APPROVERS:
      return (
        <ApproversColumn
          className={className}
          approvers={item.getApprovers()}
        >
          {children}
        </ApproversColumn>
      );
    case AccessRequestApprovalDetailsListColumn.APPROVAL_COMMENTS:
      return (
        <div
          className={classnames("approvalCommentsColumn", className, classes.container, classes.approvalComments)}
        >
          <label className={classnames("label", classes.label)}>
            {item.getApprovalComments()}
          </label>
          {children}
        </div>
      );
    case AccessRequestApprovalDetailsListColumn.APPROVED_BY:
      return (
        <ApprovedByColumn
          className={className}
          approvedBy={item.getApprovedBy()}
        >
          {children}
        </ApprovedByColumn>
      );
    case AccessRequestApprovalDetailsListColumn.APPROVED_AT:
      return (
        <div
          className={classnames("approvedAtColumn", className, classes.container, classes.approvedAt)}
        >
          <label className={classnames("label", classes.label)}>
            {item.getApprovedAt()}
          </label>
          {children}
        </div>
      );
    default:
      return null;
  }
});

export default AccessRequestApprovalDetailsListItem;
