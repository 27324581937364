import React from "react";
import { JsonSchemaMetadata } from "@data";
import { useHistory } from "react-router-dom";
import { getPathToSchema } from "@modules/schemaDetails/helpers";
import { getCreateSchemaPath } from "@modules/schemaWizard/helpers";
import Schemas from "./containers/Schemas";

export function SchemasView() {

  const history = useHistory();

  const createSchema = React.useCallback(() =>
    history.push(getCreateSchemaPath()), [history]);

  const showSchemaDetails = React.useCallback((schema: JsonSchemaMetadata) =>
    history.push(getPathToSchema(schema.getId())), [history]);

  return (
    <Schemas
      createSchema={createSchema}
      showSchemaDetails={showSchemaDetails}
    />
  );
}

export default SchemasView;
