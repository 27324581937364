import {
  AccessRequestType,
  DataAccessRequestAttributes,
  GetRequestCommentsResponse,
  TrainingCertificateInfoAttributes,
} from "@data";
import {
  CreateDataAccessRequestResponse,
  DataAccessRequestClient,
  GetDataAccessRequestsResponse,
  GetDataSetAccessRequestsResponse,
  ListAccessibleDataSetsResponse,
  ListPrincipalIdentifiersResponse,
  RevokeAccessResponse,
  TrainingCertUrlResponse,
  TrainingStatusResponse,
} from "@network";
import { useApiRequestAnalytics } from "@hooks";

export type GetDataAccessRequestsApi = () => Promise<GetDataAccessRequestsResponse>;

export type GetAccountDataAccessRequestsApi = () => Promise<GetDataAccessRequestsResponse>;

export type CreateDataAccessRequestApi = (json: string) => Promise<CreateDataAccessRequestResponse>;

export type GetDataAccessRequestApi = (accessRequestId: string) => Promise<DataAccessRequestAttributes>;

export type GetDataAccessRequestCommentsApi = (accessRequestId: string) => Promise<GetRequestCommentsResponse>;

export type CreateDataAccessRequestCommentApi = (accessRequestId: string, json: string) => Promise<void>;

export type DeleteDataAccessRequestApi = (accessRequestId: string) => Promise<void>;

export type UploadTrainingCertApi = (file: File) => Promise<any>;

export type GetTrainingStatusApi = () => Promise<TrainingStatusResponse>;

export type GetTrainingCertificateDetailsApi = () => Promise<TrainingCertificateInfoAttributes>;

export type GetTrainingCertUrlApi = (trainingCertificateId: string) => Promise<TrainingCertUrlResponse>;

export type ListCloudRolePrincipalsApi = () => Promise<ListPrincipalIdentifiersResponse>;

export type ListAccessibleDataSetsApi =
  (accessRequestType?: AccessRequestType, next?: string) => Promise<ListAccessibleDataSetsResponse>;

export type GetDataSetAccessRequestsApi =
  (accountId: string, dataSetAlias: string) => Promise<GetDataSetAccessRequestsResponse>;

export type RevokeDataAccessByOwnerApi =
  (accountId: string, dataSetAlias: string, json: string) => Promise<RevokeAccessResponse>;

export interface DataAccessRequestClientApi {
  getDataAccessRequests: GetDataAccessRequestsApi;
  getAccountDataAccessRequests: GetAccountDataAccessRequestsApi;
  createDataAccessRequest: CreateDataAccessRequestApi;
  getDataAccessRequest: GetDataAccessRequestApi;
  getDataAccessRequestComments: GetDataAccessRequestCommentsApi;
  createDataAccessRequestComment: CreateDataAccessRequestCommentApi;
  deleteDataAccessRequest: DeleteDataAccessRequestApi;
  uploadTrainingCert: UploadTrainingCertApi;
  getTrainingStatus: GetTrainingStatusApi;
  getTrainingCertificateDetails: GetTrainingCertificateDetailsApi;
  getTrainingCertUrl: GetTrainingCertUrlApi;
  listCloudRolePrincipals: ListCloudRolePrincipalsApi;
  listAccessibleDataSets: ListAccessibleDataSetsApi;
  getDataSetAccessRequests: GetDataSetAccessRequestsApi;
  revokeDataAccessByOwner: RevokeDataAccessByOwnerApi;
}

enum ActionType {
  GET_DATA_ACCESS_REQUESTS_REQUEST = "data_access_request_client/get_data_access_requests_request",
  GET_DATA_ACCESS_REQUESTS_SUCCESS = "data_access_request_client/get_data_access_requests_success",
  GET_DATA_ACCESS_REQUESTS_FAILED = "data_access_request_client/get_data_access_requests_failed",

  GET_ACCOUNT_DATA_ACCESS_REQUESTS_REQUEST = "data_access_request_client/get_account_data_access_requests_request",
  GET_ACCOUNT_DATA_ACCESS_REQUESTS_SUCCESS = "data_access_request_client/get_account_data_access_requests_success",
  GET_ACCOUNT_DATA_ACCESS_REQUESTS_FAILED = "data_access_request_client/get_account_data_access_requests_failed",

  CREATE_DATA_ACCESS_REQUEST_REQUEST = "data_access_request_client/create_data_access_request_request",
  CREATE_DATA_ACCESS_REQUEST_SUCCESS = "data_access_request_client/create_data_access_request_success",
  CREATE_DATA_ACCESS_REQUEST_FAILED = "data_access_request_client/create_data_access_request_failed",

  GET_DATA_ACCESS_REQUEST_REQUEST = "data_access_request_client/get_data_access_request_request",
  GET_DATA_ACCESS_REQUEST_SUCCESS = "data_access_request_client/get_data_access_request_success",
  GET_DATA_ACCESS_REQUEST_FAILED = "data_access_request_client/get_data_access_request_failed",

  GET_DATA_ACCESS_REQUEST_COMMENTS_REQUEST = "data_access_request_client/get_data_access_request_comments_request",
  GET_DATA_ACCESS_REQUEST_COMMENTS_SUCCESS = "data_access_request_client/get_data_access_request_comments_success",
  GET_DATA_ACCESS_REQUEST_COMMENTS_FAILED = "data_access_request_client/get_data_access_request_comments_failed",

  CREATE_DATA_ACCESS_REQUEST_COMMENT_REQUEST = "data_access_request_client/create_data_access_request_comment_request",
  CREATE_DATA_ACCESS_REQUEST_COMMENT_SUCCESS = "data_access_request_client/create_data_access_request_comment_success",
  CREATE_DATA_ACCESS_REQUEST_COMMENT_FAILED = "data_access_request_client/create_data_access_request_comment_failed",

  DELETE_DATA_ACCESS_REQUEST_REQUEST = "data_access_request_client/delete_data_access_request_request",
  DELETE_DATA_ACCESS_REQUEST_SUCCESS = "data_access_request_client/delete_data_access_request_success",
  DELETE_DATA_ACCESS_REQUEST_FAILED = "data_access_request_client/delete_data_access_request_failed",

  UPLOAD_TRAINING_CERT_REQUEST = "data_access_request_client/upload_training_cert_request",
  UPLOAD_TRAINING_CERT_SUCCESS = "data_access_request_client/upload_training_cert_success",
  UPLOAD_TRAINING_CERT_FAILED = "data_access_request_client/upload_training_cert_failed",

  GET_TRAINING_STATUS_REQUEST = "data_access_request_client/get_training_status_request",
  GET_TRAINING_STATUS_SUCCESS = "data_access_request_client/get_training_status_success",
  GET_TRAINING_STATUS_FAILED = "data_access_request_client/get_training_status_failed",

  GET_TRAINING_CERT_DETAILS_REQUEST = "data_access_request_client/get_training_cert_details_request",
  GET_TRAINING_CERT_DETAILS_SUCCESS = "data_access_request_client/get_training_cert_details_success",
  GET_TRAINING_CERT_DETAILS_FAILED = "data_access_request_client/get_training_cert_details_failed",

  GET_TRAINING_CERT_URL_REQUEST = "data_access_request_client/get_training_cert_url_request",
  GET_TRAINING_CERT_URL_SUCCESS = "data_access_request_client/get_training_cert_url_success",
  GET_TRAINING_CERT_URL_FAILED = "data_access_request_client/get_training_cert_url_failed",

  LIST_CLOUD_ROLE_PRINCIPALS_REQUEST = "data_access_request_client/list_cloud_role_principals_request",
  LIST_CLOUD_ROLE_PRINCIPALS_SUCCESS = "data_access_request_client/list_cloud_role_principals_success",
  LIST_CLOUD_ROLE_PRINCIPALS_FAILED = "data_access_request_client/list_cloud_role_principals_failed",

  LIST_ACCESSIBLE_DATA_SETS_REQUEST = "data_access_request_client/list_accessible_data_sets_request",
  LIST_ACCESSIBLE_DATA_SETS_SUCCESS = "data_access_request_client/list_accessible_data_sets_success",
  LIST_ACCESSIBLE_DATA_SETS_FAILED = "data_access_request_client/list_accessible_data_sets_failed",

  GET_DATA_SET_ACCESS_REQUESTS_REQUEST = "data_access_request_client/get_data_set_access_requests_request",
  GET_DATA_SET_ACCESS_REQUESTS_SUCCESS = "data_access_request_client/get_data_set_access_requests_success",
  GET_DATA_SET_ACCESS_REQUESTS_FAILED = "data_access_request_client/get_data_set_access_requests_failed",

  REVOKE_DATA_ACCESS_BY_OWNER_REQUEST = "data_access_request_client/revoke_data_access_by_owner_request",
  REVOKE_DATA_ACCESS_BY_OWNER_SUCCESS = "data_access_request_client/revoke_data_access_by_owner_success",
  REVOKE_DATA_ACCESS_BY_OWNER_FAILED = "data_access_request_client/revoke_data_access_by_owner_failed",
}

export const useGetDataAccessRequestsApi = (): GetDataAccessRequestsApi =>
  useApiRequestAnalytics(DataAccessRequestClient.getDataAccessRequests, {
    REQUEST_EVENT: ActionType.GET_DATA_ACCESS_REQUESTS_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DATA_ACCESS_REQUESTS_SUCCESS,
    ERROR_EVENT: ActionType.GET_DATA_ACCESS_REQUESTS_FAILED,
  });

export const useGetAccountDataAccessRequestsApi = (): GetAccountDataAccessRequestsApi =>
  useApiRequestAnalytics(DataAccessRequestClient.getAccountDataAccessRequests, {
    REQUEST_EVENT: ActionType.GET_ACCOUNT_DATA_ACCESS_REQUESTS_REQUEST,
    SUCCESS_EVENT: ActionType.GET_ACCOUNT_DATA_ACCESS_REQUESTS_SUCCESS,
    ERROR_EVENT: ActionType.GET_ACCOUNT_DATA_ACCESS_REQUESTS_FAILED,
  });

export const useCreateDataAccessRequestApi = (): CreateDataAccessRequestApi =>
  useApiRequestAnalytics(DataAccessRequestClient.createDataAccessRequest, {
    REQUEST_EVENT: ActionType.CREATE_DATA_ACCESS_REQUEST_REQUEST,
    SUCCESS_EVENT: ActionType.CREATE_DATA_ACCESS_REQUEST_SUCCESS,
    ERROR_EVENT: ActionType.CREATE_DATA_ACCESS_REQUEST_FAILED,
  });

export const useGetDataAccessRequestApi = (): GetDataAccessRequestApi =>
  useApiRequestAnalytics(DataAccessRequestClient.getDataAccessRequest, {
    REQUEST_EVENT: ActionType.GET_DATA_ACCESS_REQUEST_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DATA_ACCESS_REQUEST_SUCCESS,
    ERROR_EVENT: ActionType.GET_DATA_ACCESS_REQUEST_FAILED,
  });

export const useGetDataAccessRequestCommentsApi = (): GetDataAccessRequestCommentsApi =>
  useApiRequestAnalytics(DataAccessRequestClient.getDataAccessRequestComments, {
    REQUEST_EVENT: ActionType.GET_DATA_ACCESS_REQUEST_COMMENTS_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DATA_ACCESS_REQUEST_COMMENTS_SUCCESS,
    ERROR_EVENT: ActionType.GET_DATA_ACCESS_REQUEST_COMMENTS_FAILED,
  });

export const useCreateDataAccessRequestCommentApi = (): CreateDataAccessRequestCommentApi =>
  useApiRequestAnalytics(DataAccessRequestClient.createDataAccessRequestComment, {
    REQUEST_EVENT: ActionType.CREATE_DATA_ACCESS_REQUEST_COMMENT_REQUEST,
    SUCCESS_EVENT: ActionType.CREATE_DATA_ACCESS_REQUEST_COMMENT_SUCCESS,
    ERROR_EVENT: ActionType.CREATE_DATA_ACCESS_REQUEST_COMMENT_FAILED,
  });

export const useDeleteDataAccessRequestApi = (): DeleteDataAccessRequestApi =>
  useApiRequestAnalytics(DataAccessRequestClient.deleteDataAccessRequest, {
    REQUEST_EVENT: ActionType.DELETE_DATA_ACCESS_REQUEST_REQUEST,
    SUCCESS_EVENT: ActionType.DELETE_DATA_ACCESS_REQUEST_SUCCESS,
    ERROR_EVENT: ActionType.DELETE_DATA_ACCESS_REQUEST_FAILED,
  });

export const useUploadTrainingCertApi = (): UploadTrainingCertApi =>
  useApiRequestAnalytics(DataAccessRequestClient.uploadTrainingCert, {
    REQUEST_EVENT: ActionType.UPLOAD_TRAINING_CERT_REQUEST,
    SUCCESS_EVENT: ActionType.UPLOAD_TRAINING_CERT_SUCCESS,
    ERROR_EVENT: ActionType.UPLOAD_TRAINING_CERT_FAILED,
  });

export const useGetTrainingStatusApi = (): GetTrainingStatusApi =>
  useApiRequestAnalytics(DataAccessRequestClient.getTrainingStatus, {
    REQUEST_EVENT: ActionType.GET_TRAINING_STATUS_REQUEST,
    SUCCESS_EVENT: ActionType.GET_TRAINING_STATUS_SUCCESS,
    ERROR_EVENT: ActionType.GET_TRAINING_STATUS_FAILED,
  });

export const useGetTrainingCertificateDetailsApi = (): GetTrainingCertificateDetailsApi =>
  useApiRequestAnalytics(DataAccessRequestClient.getTrainingCertificateDetails, {
    REQUEST_EVENT: ActionType.GET_TRAINING_CERT_DETAILS_REQUEST,
    SUCCESS_EVENT: ActionType.GET_TRAINING_CERT_DETAILS_SUCCESS,
    ERROR_EVENT: ActionType.GET_TRAINING_CERT_DETAILS_FAILED,
  });

export const useGetTrainingCertUrlApi = (): GetTrainingCertUrlApi =>
  useApiRequestAnalytics(DataAccessRequestClient.getTrainingCertUrl, {
    REQUEST_EVENT: ActionType.GET_TRAINING_CERT_URL_REQUEST,
    SUCCESS_EVENT: ActionType.GET_TRAINING_CERT_URL_SUCCESS,
    ERROR_EVENT: ActionType.GET_TRAINING_CERT_URL_FAILED,
  });

export const useListCloudRolePrincipalsApi = (): ListCloudRolePrincipalsApi =>
  useApiRequestAnalytics(DataAccessRequestClient.listCloudRolePrincipals, {
    REQUEST_EVENT: ActionType.LIST_CLOUD_ROLE_PRINCIPALS_REQUEST,
    SUCCESS_EVENT: ActionType.LIST_CLOUD_ROLE_PRINCIPALS_SUCCESS,
    ERROR_EVENT: ActionType.LIST_CLOUD_ROLE_PRINCIPALS_FAILED,
  });

export const useListAccessibleDataSetsApi = (): ListAccessibleDataSetsApi =>
  useApiRequestAnalytics(DataAccessRequestClient.listAccessibleDataSets, {
    REQUEST_EVENT: ActionType.LIST_ACCESSIBLE_DATA_SETS_REQUEST,
    SUCCESS_EVENT: ActionType.LIST_ACCESSIBLE_DATA_SETS_SUCCESS,
    ERROR_EVENT: ActionType.LIST_ACCESSIBLE_DATA_SETS_FAILED,
  });

export const useGetDataSetAccessRequestsApi = (): GetDataSetAccessRequestsApi =>
  useApiRequestAnalytics(DataAccessRequestClient.getDataSetAccessRequests, {
    REQUEST_EVENT: ActionType.GET_DATA_SET_ACCESS_REQUESTS_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DATA_SET_ACCESS_REQUESTS_SUCCESS,
    ERROR_EVENT: ActionType.GET_DATA_SET_ACCESS_REQUESTS_FAILED,
  });

export const useRevokeDataAccessByOwnerApi = (): RevokeDataAccessByOwnerApi =>
  useApiRequestAnalytics(DataAccessRequestClient.revokeDataAccessByOwner, {
    REQUEST_EVENT: ActionType.REVOKE_DATA_ACCESS_BY_OWNER_REQUEST,
    SUCCESS_EVENT: ActionType.REVOKE_DATA_ACCESS_BY_OWNER_SUCCESS,
    ERROR_EVENT: ActionType.REVOKE_DATA_ACCESS_BY_OWNER_FAILED,
  });

export const useDataAccessRequestClient = (): DataAccessRequestClientApi => ({
  getDataAccessRequests: useGetDataAccessRequestsApi(),
  getAccountDataAccessRequests: useGetAccountDataAccessRequestsApi(),
  createDataAccessRequest: useCreateDataAccessRequestApi(),
  getDataAccessRequest: useGetDataAccessRequestApi(),
  getDataAccessRequestComments: useGetDataAccessRequestCommentsApi(),
  createDataAccessRequestComment: useCreateDataAccessRequestCommentApi(),
  deleteDataAccessRequest: useDeleteDataAccessRequestApi(),
  uploadTrainingCert: useUploadTrainingCertApi(),
  getTrainingStatus: useGetTrainingStatusApi(),
  getTrainingCertificateDetails: useGetTrainingCertificateDetailsApi(),
  getTrainingCertUrl: useGetTrainingCertUrlApi(),
  listCloudRolePrincipals: useListCloudRolePrincipalsApi(),
  listAccessibleDataSets: useListAccessibleDataSetsApi(),
  getDataSetAccessRequests: useGetDataSetAccessRequestsApi(),
  revokeDataAccessByOwner: useRevokeDataAccessByOwnerApi(),
});

export { ActionType as DataAccessRequestClientActionType };

export default useDataAccessRequestClient;
