import React from "react";

export interface Model {
  children?: React.ReactNode;
}

export const SelectSecurityGroupsView = (props: Model) => {

  const { children } = props;

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

export default SelectSecurityGroupsView;
