import { TriggerType } from "@data";

export const mapTriggerTypeToOptionName = (value: TriggerType) => {
  switch (value) {
    case TriggerType.SCHEDULE:
      return "Schedule";
    case TriggerType.DATA_LAKE:
      return "Data Lake";
    case TriggerType.WORKLOAD_COMPLETE:
      return "Workload Complete";
    default:
      return null;
  }
};
