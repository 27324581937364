import React from "react";
import { connect } from "react-redux";
import { ManagedPolicy } from "@data";
import { AppSchema } from "@main/schemas";
import { isEmptyString, noop } from "@util";
import { getUserId, isCurrentUserViewActive, isManagedPoliciesViewActive } from "../selectors";
import { setDetachPolicyButtonEnabled } from "../actions";
import { ManagedPoliciesList } from "@components";
import {
  PolicyOperationPrincipal,
  useManagedPolicies as useManagedPoliciesList,
  useManagedEffectivePolicies
} from "@hooks";

interface Model {
  currentUserViewActive?: boolean;
  hidden?: boolean;
  userId?: string;
}

interface Actions {
  showManagedPolicyDetails?: (policy: ManagedPolicy) => void;
  setDetachPolicyButtonEnabled?: (enabled: boolean) => void;
}

type Props = Model & Actions;

const ManagedPoliciesView = (props: Props) => {

  const {
    hidden,
    userId,
    showManagedPolicyDetails,
    currentUserViewActive,
    setDetachPolicyButtonEnabled: enableDetachPolicyButton = noop,
    ...otherProps
  } = props;

  const [ policyModel ] = useManagedEffectivePolicies(PolicyOperationPrincipal.USER, userId);

  const {
    policies = [],
    showLoadingIndicator: policyLoading,
    errorMessage: policyErrorMessage,
    errorStatus,
    ...remainingPolicyModel } = policyModel;

  const visiblePolicyErrorMessage = React.useMemo(() =>
    errorStatus === 404 ? "" : policyErrorMessage, [errorStatus, policyErrorMessage]);

  const [ model, actions ] = useManagedPoliciesList();

  const {
    managedPolicies = [],
    showLoadingIndicator: managedPolicyLoading,
    errorMessage: managedPolicyErrorMessage,
    nameFilter,
    ...remainingManagedPolicyModel
  } = model;

  const items: ManagedPolicy[] = React.useMemo(() => {
    return managedPolicies.filter(managedPolicy =>
      policies.some(policy => policy.getName() === managedPolicy.getPolicy().getName()));
  }, [managedPolicies, policies]);

  const showSearch = React.useMemo(() => !isEmptyString(nameFilter) || items.length > 1, [items, nameFilter]);

  const { totalNumPolicies } = model;

  const loadingIndicator = React.useMemo(() => policyLoading || managedPolicyLoading,
    [policyLoading, managedPolicyLoading]);

  const errorMessage = React.useMemo(() => {
    if (!isEmptyString(managedPolicyErrorMessage)) {
      return managedPolicyErrorMessage;
    } else if (!isEmptyString(visiblePolicyErrorMessage)) {
      return visiblePolicyErrorMessage;
    } else {
      return "";
    }
  }, [managedPolicyErrorMessage, visiblePolicyErrorMessage]);

  const loadingLabel = React.useMemo(() =>
      currentUserViewActive
        ? "Loading policies attached to you..."
        : "Loading policies attached to this user...",
    [currentUserViewActive]);

  const noResultsLabel = React.useMemo(() =>
      currentUserViewActive
        ? "You do not have any policies attached"
        : "User does not have any policies attached",
    [currentUserViewActive]);

  React.useEffect(() => {
    enableDetachPolicyButton(totalNumPolicies > 0);
  }, [enableDetachPolicyButton, totalNumPolicies]);

  if (hidden) {
    return null;
  }

  return (
    <ManagedPoliciesList
      {...otherProps}
      {...remainingPolicyModel}
      {...remainingManagedPolicyModel}
      {...actions}
      className={"userManagedPolicies"}
      items={items}
      nameFilter={nameFilter}
      showSearch={showSearch}
      showLoadingIndicator={loadingIndicator}
      errorMessage={errorMessage}
      onClickItem={showManagedPolicyDetails}
      loadingLabel={loadingLabel}
      noResultsLabel={noResultsLabel}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  currentUserViewActive: isCurrentUserViewActive(state),
  hidden: !isManagedPoliciesViewActive(state),
  userId: getUserId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setDetachPolicyButtonEnabled: (enabled: boolean) => dispatch(setDetachPolicyButtonEnabled(enabled)),
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(ManagedPoliciesView);
