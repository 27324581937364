import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { useDeleteDSLayout, useGetDSLayout } from "@hooks";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import { DSLayoutInfo } from "@data";
import DSLayoutOperation from "@components/ds-layout-operation";

export interface DeleteDSLayoutDialogModel extends PortalModuleDialogModel<string> {
  layoutName?: string;
  operation?: DSLayoutOperation;
}

export interface DeleteDSLayoutDialogActions extends PortalModuleDialogActions<string> {
  onSuccess?: () => void;
  cancel?: () => void;
}

type Props = WithStyles<typeof styles> & DeleteDSLayoutDialogModel & DeleteDSLayoutDialogActions;

export const DeleteDSLayoutDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    layoutName = "",
    operation = DSLayoutOperation.NONE,
    onSuccess = noop,
    cancel = noop,
    ...otherProps
  } = props;

  if (operation === DSLayoutOperation.NONE) {
    return null;
  }

  const [ model, actions ] = useGetDSLayout({
    name: layoutName,
    deferRequest: true
  });

  const {
    showLoadingIndicator: fetchLoadingIndicator,
    dsLayout,
    etag: layoutEtag,
    errorMessage: fetchErrorMessage,
    showErrorView: fetchError,
    showSuccessView: fetchSuccess,
  }  = model;
  const { refresh: getLayout }  = actions;

  const [ errorMessage, setErrorMessage ] = React.useState("");
  const [ etag, setEtag ] = React.useState("");
  const [ layout, setLayout ] = React.useState<DSLayoutInfo>(DSLayoutInfo.EMPTY);

  const [{
    showLoadingIndicator,
    successMessage,
    errorMessage: deleteErrorMessage,
    showErrorView: deleteError,
  }, { deleteLayout }] =
    useDeleteDSLayout({ name: layout.getName(), etag });

  const continueButtonDisabled = React.useMemo(() =>
    fetchLoadingIndicator || isEmptyString(layoutName ) || isEmptyString(etag),
    [fetchLoadingIndicator, layoutName, etag]);

  React.useEffect(() => {
    if (fetchError) {
      setErrorMessage(fetchErrorMessage);
    } else if (deleteError) {
      setErrorMessage(deleteErrorMessage);
    } else {
      setErrorMessage("");
    }
  }, [
    fetchError,
    deleteError
  ]);

  React.useEffect(() => {
    if (!isEmptyString(layoutName)) {
      getLayout();
    }
    return noop;
  }, [layoutName]);

  React.useEffect(() => {
    setLayout(dsLayout);
    setEtag(layoutEtag.etag || "");
  }, [fetchSuccess]);

  return (
    <PortalModuleDialog
      {...otherProps}
      className={classnames("deleteDSLayoutDialog")}
      title={"Delete Shadow Layout"}
      loading={showLoadingIndicator}
      errorMessage={errorMessage}
      successMessage={successMessage}
      continueButtonLabel={"Delete"}
      continueButtonDisabled={continueButtonDisabled}
      confirm={deleteLayout}
      onSuccessMessageShown={onSuccess}
      cancel={cancel}
    >
      <div className={classnames("deleteDSLayout", classes.container)}>
        {fetchLoadingIndicator && (
          <ListItem className={classnames("loadingLabel", classes.loadingLabel)}>
            Loading...
          </ListItem>
        )}
        {!fetchLoadingIndicator && (
          <React.Fragment>
            <label className={classnames("title", classes.title)}>
              Are you sure you want to delete this layout?
            </label>
            <label className={classnames("subtitle", classes.subtitle)}>
              <span className={classes.warning}>WARNING:</span>This action cannot be undone!
            </label>
            <label className={classnames("layoutName", classes.layoutName)}>
              {layout.getName()}
            </label>
          </React.Fragment>
        )}
      </div>
    </PortalModuleDialog>
  );
});

export default DeleteDSLayoutDialog;
