import React from "react";
import classnames from "classnames";
import MuiAccordionDetails, { AccordionDetailsProps } from "@material-ui/core/AccordionDetails";

export interface CustomAccordionDetailsProps extends AccordionDetailsProps {}

export const AccordionDetails = ((props: CustomAccordionDetailsProps) => {

    const {
      className,
      ...otherProps
    } = props;

    return (
      <MuiAccordionDetails
        {...otherProps}
        className={classnames("accordionDetails", className)}
      />
    );
});

export default AccordionDetails;
