import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import TextField from "@components/text-field";
import { enterKeyHandler, formEventHandler, isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import useDeleteWorkload from "@hooks/workloadsManager/use-delete-workload";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";

export interface DeleteWorkloadDialogModel extends PortalModuleDialogModel<string> {
  name: string;
  version?: number;
}

export interface DeleteWorkloadDialogActions extends PortalModuleDialogActions<string> {
}

type Props = WithStyles<typeof styles> & DeleteWorkloadDialogModel & DeleteWorkloadDialogActions;

export const DeleteWorkloadDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    name,
    version,
    continueButtonLabel = "Delete",
    cancel = noop,
    onClose = noop,
    ...otherProps
  } = props;

  const title = React.useMemo(() => {
    return version ? `Delete Workload Version ${version}?` :
      isEmptyString(name) ? "Delete Workload" : `Delete Workload "${name}"?`;
  }, [name, version]);

  const [ confirmationInput, setConfirmationInput ] = React.useState("");

  const [ continueButtonDisabled, setContinueButtonDisabled ] = React.useState(true);

  const [ { showLoadingIndicator, ...model }, { deleteWorkload, ...actions } ] =
    useDeleteWorkload({ name, version });

  const resetState = React.useCallback(() => {
    setConfirmationInput("");
  }, [ setConfirmationInput ]);

  const onCloseDialog = React.useCallback(() => {
    resetState();
    onClose();
  }, [ resetState, onClose ]);

  const onCancel = React.useCallback(() => {
    resetState();
    cancel();
  }, [ resetState, cancel ]);

  const enterKeyPressed = React.useCallback(() => {
    if (!continueButtonDisabled) {
      deleteWorkload();
    }
  }, [continueButtonDisabled, deleteWorkload]);

  React.useEffect(() => {
    setContinueButtonDisabled(name !== confirmationInput);
  }, [ name, confirmationInput, setContinueButtonDisabled ]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...model}
      {...actions}
      className={classnames("deleteWorkloadDialog", className, classes.container)}
      title={title}
      loading={showLoadingIndicator}
      continueButtonLabel={continueButtonLabel}
      continueButtonDisabled={continueButtonDisabled}
      confirm={deleteWorkload}
      onClose={onCloseDialog}
      cancel={onCancel}
    >
      <div className={classnames("deleteWorkload", classes.content)}>
        <React.Fragment>
          <label className={classnames("header", classes.header)}>
            Are you sure you want to delete this workload{version ? " version" : ""}?
          </label>
          {!version && (
            <label className={classnames("title", classes.title)}>
              Are you sure that you want to delete the workload <b>{name}</b> and all metadata, triggers,
              logs, and code for every version of this workload?
            </label>
          )}
          {version && (
            <label className={classnames("title", classes.title)}>
              Are you sure that you want to delete version {version} of
              the workload <b>{name}</b> including all of its metadata, triggers,
              logs, and code?
            </label>
          )}
          {!version && (
          <label className={classnames("subtitle", classes.subtitle)}>
            All versions of this workload will be <b>permanently removed</b> and cannot be restored
            after being deleted.
            </label>
          )}
          <label className={classnames("subtitle", classes.subtitle)}>
            <span className={classes.warning}>WARNING:</span>This action cannot be undone!
          </label>
          <TextField
            className={classnames("textField", classes.textField)}
            size="small"
            name="workloadName"
            color="secondary"
            variant="outlined"
            label="Confirm Deletion"
            placeholder={name}
            fullWidth={true}
            disabled={showLoadingIndicator}
            InputLabelProps={{ shrink: true }}
            helperText="To confirm deletion, enter the name of the workload."
            value={confirmationInput}
            onChange={formEventHandler(setConfirmationInput)}
            onKeyDown={enterKeyHandler(enterKeyPressed)}
          />
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default DeleteWorkloadDialog;
