import { isEmptyString, isValidInteger } from "@util";
import {
  createQueryParams,
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments
} from "@network/helpers";
import {
  EffectivePolicyAttributes,
  ManagedPolicyAttributes,
  ManagedPolicyInfoAttributes,
} from "@data";
import { PolicyOperationPrincipal } from "@hooks";

const REGIONAL_API_URL = process.env.REACT_APP_REGIONAL_API || "";

if (isEmptyString(REGIONAL_API_URL)) {
  throw new Error("Missing Environment Variable: REACT_APP_REGIONAL_API");
}

const DEFAULT_LIMIT = 50;

export interface GetManagedPoliciesResponse {
  policies: ManagedPolicyAttributes[];
  paging?: {
    next?: string;
  };
}

export interface GetEffectivePolicyResponse {
  principal: string;
  policies: EffectivePolicyAttributes[];
  paging?: {
    next?: string;
  };
}

export const getManagedPolicies = (authToken: string,
                                   next: string = "",
                                   policyName: string = "",
                                   limit: number = DEFAULT_LIMIT): Promise<GetManagedPoliciesResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams({
      ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, Math.min(DEFAULT_LIMIT, limit)) }),
      ...(!isEmptyString(policyName) && policyName.length >= 3 ? ({ policyName }) : ({})),
      next,
      showDescription: true,
    });

    const url = `${REGIONAL_API_URL}/security/authorization/v1/managedpolicies${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch managed policies failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getManagedPolicy = (authToken: string,
                                 policyName: string,
                                 accountId: string): Promise<ManagedPolicyInfoAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Policy Name", policyName],
      ["Account ID", accountId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/authorization/v1/managedpolicies/${policyName}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "X-IoT-Platform-AccountId": `${accountId}`,
      },
    };

    const defaultErrorMessage = `Failed to get managed policy [${policyName}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getEffectivePolicies = (authToken: string,
                                     principalType: PolicyOperationPrincipal,
                                     principalId: string,
                                     next: string = "",
                                     policyName: string = ""): Promise<GetEffectivePolicyResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Principal Type", principalType],
      ["Principal ID", principalId],
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({
      ...(!isEmptyString(policyName) && policyName.length >= 3 ? ({ policyName }) : ({})),
      next,
    });

    const url = `${REGIONAL_API_URL}/security/authorization/v1/policies/${principalType}/${principalId}${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to get effective policies";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getEffectiveGroupPolicies = (authToken: string,
                                          groupName: string,
                                          policyName: string = ""): Promise<EffectivePolicyAttributes[]> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Group Name", groupName],
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({
      ...(!isEmptyString(policyName) && policyName.length >= 3 ? ({ policyName }) : ({})),
    });

    const url = `${REGIONAL_API_URL}/security/authorization/v1/policies/groups/${groupName}${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to get effective group policies";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const attachManagedPolicyToService = (authToken: string,
                                             serviceId: string,
                                             policyName: string,
                                             accountId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Service ID", serviceId],
      ["Policy Name", policyName],
      ["Account ID", accountId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/authorization/v1/managedpolicies/${policyName}/services/${serviceId}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-IoT-Platform-AccountId": `${accountId}`,
      },
    };

    const defaultErrorMessage = `Failed to attach managed policy [${policyName}] to service [${serviceId}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const detachManagedPolicyFromService = (authToken: string,
                                               serviceId: string,
                                               policyName: string,
                                               accountId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Service ID", serviceId],
      ["Policy Name", policyName],
      ["Account ID", accountId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/authorization/v1/managedpolicies/${policyName}/services/${serviceId}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-IoT-Platform-AccountId": `${accountId}`,
      },
    };

    const defaultErrorMessage = `Failed to detach managed policy [${policyName}] to service [${serviceId}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const attachManagedPolicyToUser = (authToken: string,
                                          userId: string,
                                          policyName: string,
                                          accountId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["Policy Name", policyName],
      ["Account ID", accountId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/authorization/v1/managedpolicies/${policyName}/users/${userId}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-IoT-Platform-AccountId": `${accountId}`,
      },
    };

    const defaultErrorMessage = `Failed to attach managed policy [${policyName}] to user [${userId}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const detachManagedPolicyFromUser = (authToken: string,
                                            userId: string,
                                            policyName: string,
                                            accountId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["Policy Name", policyName],
      ["Account ID", accountId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/authorization/v1/managedpolicies/${policyName}/users/${userId}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-IoT-Platform-AccountId": `${accountId}`,
      },
    };

    const defaultErrorMessage = `Failed to detach managed policy [${policyName}] to user [${userId}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const attachManagedPolicyToGroup = (authToken: string,
                                           groupName: string,
                                           policyName: string,
                                           accountId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Group Name", groupName],
      ["Policy Name", policyName],
      ["Account ID", accountId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/authorization/v1/managedpolicies/${policyName}/groups/${groupName}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-IoT-Platform-AccountId": `${accountId}`,
      },
    };

    const defaultErrorMessage = `Failed to attach managed policy [${policyName}] to group [${groupName}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const detachManagedPolicyFromGroup = (authToken: string,
                                             groupName: string,
                                             policyName: string,
                                             accountId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Group Name", groupName],
      ["Policy Name", policyName],
      ["Account ID", accountId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API_URL}/security/authorization/v1/managedpolicies/${policyName}/groups/${groupName}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-IoT-Platform-AccountId": `${accountId}`,
      },
    };

    const defaultErrorMessage = `Failed to detach managed policy [${policyName}] to group [${groupName}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
