import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  PortalModuleActionTypes,
  createPortalModuleDefaultState,
} from "@base/createReducers";

export interface DeviceEnrollmentDetailsSchema extends BasePortalModuleSchema {
  batchId: string;
  autoRefresh: boolean;
}

export const DEFAULT_STATE: DeviceEnrollmentDetailsSchema = createPortalModuleDefaultState({
  batchId: "",
  autoRefresh: false,
});

type DeviceEnrollmentDetailsActionTypes = PortalModuleActionTypes<DeviceEnrollmentDetailsSchema> & {
  FETCH_DEVICE_ENROLLMENT_DETAILS_REQUEST: string;
  FETCH_DEVICE_ENROLLMENT_DETAILS_SUCCESS: string;
  FETCH_DEVICE_ENROLLMENT_DETAILS_FAILURE: string;
};

export const ACTION_TYPES: DeviceEnrollmentDetailsActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<DeviceEnrollmentDetailsSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    FETCH_DEVICE_ENROLLMENT_DETAILS_REQUEST: `${MODULE_ID}/fetch_device_enrollment_details_request`,
    FETCH_DEVICE_ENROLLMENT_DETAILS_SUCCESS: `${MODULE_ID}/fetch_device_enrollment_details_success`,
    FETCH_DEVICE_ENROLLMENT_DETAILS_FAILURE: `${MODULE_ID}/fetch_device_enrollment_details_failure`,
  },
});

export const DeviceEnrollmentDetailsReducer = combineReducers<DeviceEnrollmentDetailsSchema>(
  createPortalModuleReducers<DeviceEnrollmentDetailsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DeviceEnrollmentDetailsReducer;
