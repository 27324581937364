import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { editor as styles } from "../styles";
import { ErrorView } from "@components";

export interface Model {
  showInvalidJsonError?: boolean;
  title?: string;
  children?: React.ReactNode;
}

type Props = WithStyles<typeof styles> & Model ;

export const CredentialsEditor = withStyles(styles)((props: Props) => {

  const {
    classes,
    showInvalidJsonError,
    children,
  } = props;

  if (showInvalidJsonError) {
    return (
      <div className={classnames("nameView", classes.errorViewContainer)}>
        <ErrorView
          title="JSON Syntax Error"
          message="Visual editors cannot be used until you have fixed the JSON syntax error."
        />
      </div>
    );
  }

  return (
    <div className={classnames("credentialsView", classes.container)}>
      <label className={classes.title}>
        Credentials
      </label>
      {children}
    </div>
  );
});
