import React from "react";
import classnames from "classnames";
import Button from "@components/button";
import camelCase from "lodash/camelCase";
import { clickHandler, noop } from "@util";
import EditIcon from "@material-ui/icons/Edit";
import RequiredIcon from "@material-ui/icons/Check";
import Typography from "@material-ui/core/Typography";
import WorkloadVariableSchema from "@data/WorkloadVariableSchema";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import EditVariablesListItemPopover from "./EditVariablesListItemPopover";
import VariablesListColumn from "../VariablesListColumn";
import styles from "./styles";

export interface VariablesListItemModel {
  className?: string;
  column?: VariablesListColumn;
  item?: WorkloadVariableSchema;
}

export interface VariablesListItemActions {
  editItem?: (updatedItem: WorkloadVariableSchema) => void;
  customEdit?: (item: WorkloadVariableSchema) => void;
}

type Model = VariablesListItemModel;
type Actions = VariablesListItemActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const VariablesListItem = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    column = VariablesListColumn.NONE,
    item = WorkloadVariableSchema.EMPTY,
    editItem = noop,
    customEdit,
    children,
  } = props;

  const editIconButtonRef = React.useRef(null);

  const [showEditPopover, setShowEditPopover] = React.useState(false);

  const content = React.useMemo(() => {
    if (column === VariablesListColumn.NAME) {
      return (
        <Typography className={classnames("nameLabel", classes.label)}>
          {item.getName()}
        </Typography>
      );
    } else if (column === VariablesListColumn.DESCRIPTION) {
      return (
        <Typography className={classnames("descriptionLabel", classes.label)}>
          {item.getDescription()}
        </Typography>
      );
    } else if (column === VariablesListColumn.REQUIRED) {
      if (item.isRequired()) {
        return (
          <RequiredIcon className={classnames("requiredIcon", classes.icon, classes.requiredIcon)} />
        );
      } else {
        return (
          <label className={classnames("notRequired", classes.notRequired)}>
            -
          </label>
        );
      }
    } else if (column === VariablesListColumn.ACTIONS) {
      return (
        <Button
          ref={editIconButtonRef}
          className={classnames(
            "editIconButton",
            classes.iconButton,
            classes.editIconButton,
          )}
          onClick={clickHandler(() => {
            customEdit ? customEdit(item) : setShowEditPopover(true);
          })}
          name="editIconButton"
        >
          <EditIcon className={classnames("editIcon", classes.icon, classes.editIcon)} />
        </Button>
      );
    }
    return null;
  }, [classes, column, item, editIconButtonRef, customEdit, setShowEditPopover]);

  const closePopover = React.useCallback(() => {
    setShowEditPopover(false);
  }, [setShowEditPopover]);

  if (WorkloadVariableSchema.EMPTY.equals(item) || column === VariablesListColumn.NONE) {
    return null;
  }

  return (
    <div className={classnames("variablesListItem", className, camelCase(`${column}Column`), classes.container)}>
      {content}
      <EditVariablesListItemPopover
        className={classnames("popover", classes.popover)}
        anchorEl={editIconButtonRef.current}
        open={showEditPopover}
        item={item}
        editItem={editItem}
        closePopover={closePopover}
      />
      {children}
    </div>
  );
});

export default VariablesListItem;
