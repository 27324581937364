import React from "react";
import { Policy } from "@data";
import { noop } from "@util";
import { PoliciesList } from "@components";
import { useAccountPolicies, PolicyOperationPrincipal } from "@hooks";

export interface Model {
  groupName?: string;
  hidden?: boolean;
}

export interface Actions {
  showPolicyDetails?: (policy: Policy) => void;
  setDetachPolicyButtonEnabled?: (enabled: boolean) => void;
}

type Props = Model & Actions;

const CustomPoliciesView = (props: Props) => {

  const {
    hidden,
    groupName,
    showPolicyDetails,
    setDetachPolicyButtonEnabled: enableDetachPolicyButton = noop,
  } = props;

  const [{ policies, ...model}, actions] = useAccountPolicies(PolicyOperationPrincipal.GROUP, groupName);

  const { totalNumPolicies } = model;

  React.useEffect(() => {
    enableDetachPolicyButton(totalNumPolicies > 0);
  }, [enableDetachPolicyButton, totalNumPolicies]);

  if (hidden) {
    return null;
  }

  return (
    <PoliciesList
      {...model}
      {...actions}
      className={"groupCustomPolicies"}
      items={policies}
      onClickItem={showPolicyDetails}
      loadingLabel="Loading policies attached to this group..."
      noResultsLabel="Group does not have any policies attached"
    />
  );
};

export default CustomPoliciesView;
