import { Module } from "@data";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./CreateSecurityGroupView";

export const createSecurityGroupModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Create Security Group",
  title: "Create New Security Group",
  className: "createSecurityGroupModule",
  primaryColor,
  icon,
  view,
});

export default createSecurityGroupModule;
