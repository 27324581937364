import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { noop } from "@util/Functions";
import { styles } from "./styles";
import {
  Color,
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel,
  LoadingView,
  usePortalSnackbar,
} from "@components";

export interface PortalModuleDialogModel<Item> extends ConfirmationDialogModel<Item> {
  primaryColor?: string;
  showEmptyView?: boolean;
  closeOnSuccessDelay?: number;
  children?: React.ReactNode;
}

export interface PortalModuleDialogActions<Item> extends ConfirmationDialogActions<Item> {
  onSuccessMessageShown?: () => void;
}

export type PortalModuleDialogProps<Item> =
  PortalModuleDialogModel<Item> & PortalModuleDialogActions<Item>;

type Props<Item = string> = WithStyles<typeof styles> & PortalModuleDialogProps<Item>;

export const PortalModuleDialog = withStyles(styles)(<Item, >(props: Props<Item>) => {

  const {
    classes,
    primaryColor = Color.MODULES,
    showEmptyView,
    closeOnSuccessDelay = 500,
    onSuccessMessageShown,
    children,
    ...otherProps
  } = props;

  const {
    open,
    errorMessage = "",
    successMessage = "",
    className = "portal-module-dialog",
    cancel = noop,
  } = otherProps;

  usePortalSnackbar(className, {
    errorMessage,
    successMessage,
    onSuccessMessageShown: onSuccessMessageShown || cancel,
    successCallbackDelay: closeOnSuccessDelay,
  });

  if (!open) {
    return null;
  }

  return (
    <ConfirmationDialog {...otherProps}>
      <div className={classnames("portalModuleDialog", className, classes.container)}>
        {showEmptyView && <LoadingView className="emptyView" color={primaryColor} />}
        {!showEmptyView && children}
      </div>
    </ConfirmationDialog>
  );
});

export default PortalModuleDialog;
