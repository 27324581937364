import React from "react";
import { GetEmailRequirementsResponse } from "@network";
import EmailTemplateReqs from "@data/EmailTemplateRequirements";
import { useApiRequest, UseApiRequestModel, useEmailTemplateClient } from "@hooks";

type SuccessResponse = GetEmailRequirementsResponse;

export interface UseEmailTemplateReqsProps {}

export interface UseEmailTemplateReqsModel extends UseApiRequestModel<SuccessResponse> {
  emailRequirements: EmailTemplateReqs;
}

export interface UseEmailTemplateReqsActions {
  refresh: () => void;
}

type Props = UseEmailTemplateReqsProps;
type Model = UseEmailTemplateReqsModel;
type Actions = UseEmailTemplateReqsActions;
type Result = [Model, Actions];

export const useEmailTemplateRequirements = (props: Props = {}): Result => {

  const {
    ...otherProps
  } = props;

  const EmailTemplateClient = useEmailTemplateClient();

  const defaultErrorMessage = React.useMemo(() =>
      "Failed to get email requirements" , []);

  const [{ successResponse, ...baseModel }, { refresh }] =
      useApiRequest<SuccessResponse>({
        ...otherProps,
        defaultErrorMessage,
        makeApiRequest: () => EmailTemplateClient.getEmailTemplateReqs(),
      });

  const emailRequirements = React.useMemo(() => {
    const attrs = successResponse || {};
    return new EmailTemplateReqs(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    emailRequirements,
  }), [
    baseModel,
    emailRequirements,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    refresh,
  }), [
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEmailTemplateRequirements;
