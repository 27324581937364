import React from "react";
import { noop } from "@util";
import setDate from "date-fns/set";
import classnames from "classnames";
import { SearchFilter } from "@data";
import formatDate from "date-fns/format";
import styles, { AuditEventIcon } from "./styles";
import EndDateIcon from "@material-ui/icons/Today";
import StartDateIcon from "@material-ui/icons/DateRange";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  NotFoundView,
  AccessDeniedView,
  AuditEventsList,
  AuditEventsListActions,
  AuditEventsListModel,
  DatePicker,
  FiltersMenu,
  RefreshButton,
} from "@components";
import { withQuantity } from "@components/summary";
import { SelectedValues, AvailableValues } from "@hooks";
import AuditEventFilters from "./AuditEventFilters";
import AppliedFilters from "../applied-filters";
import AuditEventFilter from "./AuditEventFilter";

export const DATE_PICKER_FORMAT = "yyyy-MM-dd HH:mm";

const Summary = withQuantity({
  other: "audit events",
  one: "audit event",
});

export interface AuditEventsModel extends AuditEventsListModel {
  className?: string;
  showNotFound?: boolean;
  showAccessDenied?: boolean;
  showLoadingIndicator?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  searchFilters?: SearchFilter[];
  lockedFilters?: AuditEventFilter[];
  limit?: number;
  availableValues?: AvailableValues;
  selectedValues?: SelectedValues;
}

export interface AuditEventsActions extends AuditEventsListActions {
  refresh?: () => void;
  setStartDate?: (date?: Date | null) => void;
  setEndDate?: (date?: Date | null) => void;
  setSearchFilters?: (searchFilters: SearchFilter[]) => void;
  setLimit?: (limit: number) => void;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
}

type Model = AuditEventsModel;
type Actions = AuditEventsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const AuditEvents = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    showNotFound,
    showAccessDenied,
    showLoadingIndicator,
    startDate,
    endDate,
    limit,
    searchFilters = [],
    lockedFilters = [],
    selectedValues = {},
    availableValues = {},
    refresh,
    setStartDate,
    setEndDate,
    setLimit,
    setSearchFilters = noop,
    children,
    ...otherProps
  } = props;

  const { items = [] } = otherProps;

  const numResults = React.useMemo(() => items.length, [items]);

  const {
    selectedTypes = [],
    selectedSeverities = [],
    selectedAccountIds = [],
    selectedPrincipals = [],
    selectedOrigins = [],
    selectedRequestIds = [],
  } = selectedValues;

  const badgeCount = React.useMemo(() => {

    const mutableFilters = searchFilters
      .filter(searchFilter =>
        (lockedFilters as string[]).indexOf(searchFilter.getKey()) === -1);

    return mutableFilters.length;

  }, [searchFilters, lockedFilters]);

  const hideClearButton = React.useMemo(() => badgeCount === 0, [badgeCount]);

  const setSelectedTypes = React.useCallback((updatedValue: string[]) => {
    setSearchFilters(([] as SearchFilter[])
      .concat(updatedValue.map(value => new SearchFilter({
        value,
        key: AuditEventFilter.TYPE,
      })))
      .concat(searchFilters.filter(searchFilter => searchFilter.getKey() !== AuditEventFilter.TYPE)));
  }, [setSearchFilters, searchFilters]);

  const setSelectedSeverities = React.useCallback((updatedValue: string[]) => {
    setSearchFilters(([] as SearchFilter[])
      .concat(updatedValue.map(value => new SearchFilter({
        value,
        key: AuditEventFilter.SEVERITY,
      })))
      .concat(searchFilters.filter(searchFilter => searchFilter.getKey() !== AuditEventFilter.SEVERITY)));
  }, [setSearchFilters, searchFilters]);

  const setSelectedPrincipals = React.useCallback((updatedValue: string[]) => {
    setSearchFilters(([] as SearchFilter[])
      .concat(updatedValue.map(value => new SearchFilter({
        value,
        key: AuditEventFilter.PRINCIPAL,
      })))
      .concat(searchFilters.filter(searchFilter => searchFilter.getKey() !== AuditEventFilter.PRINCIPAL)));
  }, [setSearchFilters, searchFilters]);

  const setSelectedAccountIds = React.useCallback((updatedValue: string[]) => {
    setSearchFilters(([] as SearchFilter[])
      .concat(updatedValue.map(value => new SearchFilter({
        value,
        key: AuditEventFilter.ACCOUNT_ID,
      })))
      .concat(searchFilters.filter(searchFilter => searchFilter.getKey() !== AuditEventFilter.ACCOUNT_ID)));
  }, [setSearchFilters, searchFilters]);

  const setSelectedOrigins = React.useCallback((updatedValue: string[]) => {
    setSearchFilters(([] as SearchFilter[])
      .concat(updatedValue.map(value => new SearchFilter({
        value,
        key: AuditEventFilter.ORIGIN,
      })))
      .concat(searchFilters.filter(searchFilter => searchFilter.getKey() !== AuditEventFilter.ORIGIN)));
  }, [setSearchFilters, searchFilters]);

  const setSelectedRequestIds = React.useCallback((updatedValue: string[]) => {
    setSearchFilters(([] as SearchFilter[])
      .concat(updatedValue.map(value => new SearchFilter({
        value,
        key: AuditEventFilter.REQUEST_ID,
      })))
      .concat(searchFilters.filter(searchFilter => searchFilter.getKey() !== AuditEventFilter.REQUEST_ID)));
  }, [setSearchFilters, searchFilters]);

  const {
    availableTypes,
    availableSeverities,
    availableAccountIds,
    availablePrincipals,
    availableOrigins,
    availableRequestIds,
  } = availableValues;

  const [open, setOpen] = React.useState(false);

  const openMenu = React.useCallback(() => setOpen(true), [setOpen]);

  const closeMenu = React.useCallback(() => setOpen(false), [setOpen]);

  const clearFilters = React.useCallback(() =>
      setSearchFilters(searchFilters.filter(searchFilter =>
        (lockedFilters as string[]).indexOf(searchFilter.getKey()) >= 0)),
    [setSearchFilters, searchFilters, lockedFilters]);

  if (showNotFound) { return <NotFoundView/>; }

  if (showAccessDenied) { return <AccessDeniedView/>; }

  return (
    <div className={classnames("auditEvents", className, classes.container)}>
      <div className={classnames("controls", classes.controls)}>
        <Summary
          className={classnames("summary", classes.summary)}
          icon={AuditEventIcon}
          quantity={numResults}
          applyMinWidth={true}
          {...!showLoadingIndicator ? {} : { label: "Loading..." }}
        />
        <RefreshButton
          className={classnames("refreshButton", classes.refreshButton)}
          loading={showLoadingIndicator}
          refresh={refresh}
        />
        <FiltersMenu
          className={classnames("filtersMenu", classes.filtersMenu)}
          open={open}
          badgeCount={badgeCount}
          openMenu={openMenu}
          closeMenu={closeMenu}
        >
          <AuditEventFilters
            className={classnames("auditEventFilters", classes.auditEventFilters)}
            showLoadingIndicator={showLoadingIndicator}
            lockedFilters={lockedFilters}
            hideClearButton={hideClearButton}
            limit={limit}
            setLimit={setLimit}
            clearFilters={clearFilters}

            availableTypes={availableTypes}
            availableSeverities={availableSeverities}
            availableAccountIds={availableAccountIds}
            availablePrincipals={availablePrincipals}
            availableOrigins={availableOrigins}
            availableRequestIds={availableRequestIds}

            selectedTypes={selectedTypes}
            selectedSeverities={selectedSeverities}
            selectedAccountIds={selectedAccountIds}
            selectedPrincipals={selectedPrincipals}
            selectedOrigins={selectedOrigins}
            selectedRequestIds={selectedRequestIds}

            setSelectedTypes={setSelectedTypes}
            setSelectedSeverities={setSelectedSeverities}
            setSelectedPrincipals={setSelectedPrincipals}
            setSelectedAccountIds={setSelectedAccountIds}
            setSelectedOrigins={setSelectedOrigins}
            setSelectedRequestIds={setSelectedRequestIds}
          />
        </FiltersMenu>
        <div className={classnames("filterByDates", classes.filterByDates)}>
          <DatePicker
            className={classnames("filterByStartDate", classes.datePicker, classes.filterByStartDate)}
            label={"Start date"}
            icon={<StartDateIcon/>}
            disabled={showLoadingIndicator}
            selectedDate={startDate}
            setSelectedDate={setStartDate}
            maxDate={endDate}
            clearable={false}
          />
          <DatePicker
            className={classnames("filterByEndDate", classes.datePicker, classes.filterByEndDate)}
            label={"End date"}
            icon={<EndDateIcon/>}
            disabled={showLoadingIndicator}
            selectedDate={endDate}
            setSelectedDate={setEndDate}
            emptyLabel={formatDate(setDate(new Date, { seconds: 0, milliseconds: 0 }), DATE_PICKER_FORMAT)}
            minDate={startDate}
          />
        </div>
      </div>
      <AppliedFilters
        className={classnames("appliedFilters", classes.appliedFilters)}
        disabled={showLoadingIndicator}
        searchFilters={searchFilters}
        lockedFilters={lockedFilters}
        hideClearButton={hideClearButton}
        clearFilters={clearFilters}
        setSearchFilters={setSearchFilters}
        onClickSearchFilter={openMenu}
      />
      <AuditEventsList
        {...otherProps}
        className={classnames("list", classes.list)}
        showLoadingIndicator={showLoadingIndicator}
        refresh={refresh}
      />
      {children}
    </div>
  );
});

export default AuditEvents;
