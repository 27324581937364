import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    content: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    jsonViewer: {
      marginTop: 0,
      height: 500,
    },
    header: {
      "& > .icon": {
        minWidth: 0,
      },
    },
  });
