import { Record } from "immutable";
import { DeviceDataModel } from "@data";

export interface DeviceEnrollmentWizardStateAttributes {
  batchId: string;
  fileUploaded: boolean;
  numDevices: number;
  json: string;
}

// We must do this outside of the record class definition because you cannot reference
// static field values in the record defaults; unless you have specifically enforced
// their evaluation before the class is defined - like in this case.
const DEFAULT_JSON = JSON.stringify(DeviceDataModel.EMPTY.toJS(), null, "  ");

export class DeviceEnrollmentWizardState extends Record({
  batchId: "",
  fileUploaded: false,
  numDevices: 0,
  json: DEFAULT_JSON,
}) implements DeviceEnrollmentWizardStateAttributes {

  public static EMPTY = new DeviceEnrollmentWizardState();

  public readonly batchId: string;
  public readonly fileUploaded: boolean;
  public readonly numDevices: number;
  public readonly json: string;
}

export default DeviceEnrollmentWizardState;
