import React from "react";
import { useHistory } from "react-router-dom";
import { getPathToUser } from "@modules/userDetails/helpers";
import InviteUser from "./containers/InviteUser";

export function InviteUserView() {

  const history = useHistory();

  const showUserDetails = React.useCallback((userId: string) => {
    history.replace(getPathToUser(userId));
  }, [history]);

  return (
    <InviteUser
      showUserDetails={showUserDetails}
    />
  );
}

export default InviteUserView;
