import React from "react";
import classnames from "classnames";
import { isEmptyString } from "@util/Functions";

export interface Model {
  className?: string;
  title?: string;
}

type Props = Model & {
  children?: React.ReactNode;
};

export const Title = (props: Props) => {

  const { className, title = null, children } = props;

  if (isEmptyString(title)) {
    return null;
  }

  return (
    <React.Fragment>
      <label className={classnames("title", className)}>
        {title}
      </label>
      {children}
    </React.Fragment>
  );
};

export default Title;
