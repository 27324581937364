import React from "react";
import { noop } from "@util";
import { SecurityServiceRegional } from "@data";
import useSecurityGroupServices from "@hooks/securityGroups/use-security-group-services";
import ServicesView, { ServicesViewActions, ServicesViewModel } from "../components/ServicesView";

interface Model extends ServicesViewModel {
  groupName?: string;
}

interface Actions extends ServicesViewActions {
  showServiceDetails?: (service: SecurityServiceRegional) => void;
  setRemoveServiceButtonEnabled?: (enabled: boolean) => void;
}

type Props = Model & Actions;

const ServicesViewContainer = (props: Props) => {

  const {
    groupName = "",
    showServiceDetails,
    setRemoveServiceButtonEnabled: enableRemoveServiceButton = noop,
    ...otherProps
  } = props;

  const [{ services, ...model }, actions] = useSecurityGroupServices({ groupName });

  React.useEffect(() => {
    enableRemoveServiceButton(services.length > 0);
  }, [enableRemoveServiceButton, services]);

  return (
    <ServicesView
      {...otherProps}
      {...model}
      {...actions}
      items={services}
      onClickItem={showServiceDetails}
    />
  );
};

export default ServicesViewContainer;
