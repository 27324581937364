import React from "react";
import classnames from "classnames";
import { clickHandler, noop } from "@util";
import Paper from "@material-ui/core/Paper";
import Label from "@material-ui/core/Typography/Typography";
import { ErrorView, JsonEditor, SaveButton, CancelButton } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const DELAYED_LOADING_MESSAGE_DELAY_MS = 1000 * 5;

export interface Model {
  json?: string;
  errorMessage?: string;
  showLoadingIndicator?: boolean;
  showSuccessIndicator?: boolean;
  saveButtonLabel?: string;
  isJsonValid?: boolean;
}

export interface Actions {
  setJson?: (newValue: string) => void;
  clearErrorMessage?: () => void;
  cancel?: () => void;
  save?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const EditProfileAttributeDefinition = withStyles(styles)((props: Props) => {

  const {
    classes,
    json,
    errorMessage,
    showLoadingIndicator,
    showSuccessIndicator,
    isJsonValid,
    saveButtonLabel = "Update Profile Attributes",
    setJson,
    clearErrorMessage,
    cancel,
    save,
  } = props;

  const [showDelayedLoadingMessage, setShowDelayedLoadingMessage] = React.useState(false);

  const showCancelButton = !showLoadingIndicator && !showSuccessIndicator;

  React.useEffect(() => {

    if (!showLoadingIndicator) {
      setShowDelayedLoadingMessage(false);
      return () => noop;
    }

    const timer = setTimeout(() =>
      setShowDelayedLoadingMessage(true), DELAYED_LOADING_MESSAGE_DELAY_MS);

    return () => clearTimeout(timer);

  }, [showLoadingIndicator, setShowDelayedLoadingMessage]);

  return (
    <div className={classnames("editProfileAttributeDefinition", classes.container)}>
      {showLoadingIndicator && (
        <React.Fragment>
          {showDelayedLoadingMessage && (
            <Paper
              className={classnames("loadingMessage", classes.loadingMessage)}
              elevation={5}
            >
              <Label className={classes.loadingMessageLabel} variant="h1" color="inherit">
                Saving... Please wait...
              </Label>
              <Label className={classes.loadingMessageLabel} variant="h4" color="inherit">
                This process can sometimes take up to 60 seconds
              </Label>
              <Label className={classes.loadingMessageLabel} variant="subtitle1" color="inherit">
                Please avoid reloading the page while this request is in progress to ensure
                that you receive confirmation that the request completed without error.
              </Label>
            </Paper>
          )}
          <div className={classnames("modal", classes.modal)} />
        </React.Fragment>
      )}
      <ErrorView
        className={classes.error}
        title="Update Profile Attribute Definition Failed"
        message={errorMessage}
        showCloseIcon={true}
        onClickCloseIcon={clearErrorMessage}
      />
      <JsonEditor
        className={classnames("jsonEditor", classes.jsonEditor)}
        name="profileAttributesJsonViewEditor"
        readOnly={showLoadingIndicator}
        setJson={setJson}
        json={json}
      />
      <div className={classnames("controls", classes.controls)}>
        {showCancelButton && (
          <CancelButton
            className={classnames("cancelButton", classes.cancelButton)}
            classes={{
              label: classnames("label", classes.cancelButtonLabel),
            }}
            variant="text"
            onClick={clickHandler(cancel)}
          />
        )}
        <SaveButton
          className={classnames("saveButton", classes.saveButton)}
          disabled={!isJsonValid}
          label={saveButtonLabel}
          loading={showLoadingIndicator}
          success={showSuccessIndicator}
          save={save}
        />
      </div>
    </div>
  );
});

export default EditProfileAttributeDefinition;
