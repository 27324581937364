import React from "react";
import {
  UseApiRequestModel,
  UseApiRequestProps,
  useApiRequest,
  useApplicationManagerRegionalClient,
} from "@hooks";
import { ApplicationAuthenticationProtocol } from "@data";

export interface UseAuthenticateApplicationProps extends Partial<UseApiRequestProps<void>> {
  applicationId: string;
  secret: string;
  protocol: ApplicationAuthenticationProtocol;
}

export interface UseAuthenticateApplicationModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UseAuthenticateApplicationActions {
  authenticate: () => void;
}

type Props = UseAuthenticateApplicationProps;
type Model = UseAuthenticateApplicationModel;
type Actions = UseAuthenticateApplicationActions;
type Result = [Model, Actions];

export const useAuthenticateApplication = (props: Props): Result => {

  const {
    applicationId = "",
    secret = "",
    protocol = ApplicationAuthenticationProtocol.SHARED_SECRET_1,
    ...otherProps
  } = props;

  const ApplicationRegionalClient = useApplicationManagerRegionalClient();

  const json = React.useMemo(() => {
    return {
      protocol,
      data: {
        secret
      }
    };
    }, [secret]);

  const [{ showSuccessView, ...baseModel }, { refresh: authenticate }] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage: "Failed to authenticate application",
      deferRequest: true,
      makeApiRequest: () => ApplicationRegionalClient.authenticateApplicationApi(applicationId, JSON.stringify(json)),
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? `Application Successfully Authenticated` : "" ,
    [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    authenticate
  }), [authenticate]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useAuthenticateApplication;
