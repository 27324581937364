import React from "react";
import classnames from "classnames";
import { isEmptyString } from "@util";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import DefaultIcon from "@material-ui/icons/PlayCircleOutline";
import SuccessIcon from "@material-ui/icons/CheckCircleOutline";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export enum RequiredFieldLabelState {
  NONE,
  SUCCESS,
  ERROR,
}

export interface RequiredFieldLabelModel {
  className?: string;
  title?: string;
  helperText?: string;
  state?: RequiredFieldLabelState;
}

export interface RequiredFieldLabelActions {
}

type Model = RequiredFieldLabelModel;
type Actions = RequiredFieldLabelActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const RequiredFieldLabel = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title,
    helperText,
    state = RequiredFieldLabelState.NONE,
  } = props;

  const Icon = React.useMemo(() => {
    switch (state) {
      case RequiredFieldLabelState.SUCCESS:
        return SuccessIcon;
      case RequiredFieldLabelState.ERROR:
        return ErrorIcon;
      case RequiredFieldLabelState.NONE:
      default:
        return DefaultIcon;
    }
  }, [state]);

  if (isEmptyString(title)) {
    return null;
  }

  return (
    <div className={classnames("requiredFieldLabel", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        <Icon
          className={classnames("icon", classes.icon, {
            [classes.successIcon]: state === RequiredFieldLabelState.SUCCESS,
            [classes.errorIcon]: state === RequiredFieldLabelState.ERROR,
          })}
        />
        <Typography className={classnames("title", classes.title)} variant="h4">
          {title}
        </Typography>
      </div>
      {helperText && (
        <Typography className={classnames("helperText", classes.helperText)} variant="body1">
          {helperText}
        </Typography>
      )}
    </div>
  );
});

export default RequiredFieldLabel;
