import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import {
  getAuthTokenExpiryTime,
  getCurrentAccountId,
  getIdentityType,
  getPrincipalId,
} from "@main/selectors";
import StartSessionListener, {
  StartSessionListenerActions as Actions,
  StartSessionListenerModel as Model,
} from "@components/start-session-listener";

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  accessTokenExpiryTime: getAuthTokenExpiryTime(state),
  accountId: getCurrentAccountId(state),
  principalId: getPrincipalId(state),
  identityType: getIdentityType(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  reload: () => {
    window.location.reload();
  },
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(StartSessionListener);
