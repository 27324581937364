import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    header: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
    },
    actionsMenu: {
      marginLeft: 4,
    },
    actionsMenuIconButton: {
    },
    table: {
      "& th.authenticationTypeS, & td.authenticationTypeS": {
        minWidth: 250,
      },
    },
    label: {
      color: Color.TEXT,
      cursor: "pointer",
      fontSize: 12,
      lineHeight: "14px",
      fontWeight: 400,
    },
    placeholder: {
      fontStyle: "italic",
    },
    name: {
    },
    type: {
    },
    encoding: {
    },
    persistence: {
    },
    authorityIds: {
    },
    deviceAuthenticationTypes: {
      display: "flex",
      flexFlow: "column nowrap",
      overflow: "hidden",
      whiteSpace: "normal",
      wordBreak: "break-word",
    },
    deviceAuthentication: {
    },
    addButton: {
    },
  });

export default styles;
