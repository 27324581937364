import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteDataAccessRequest, refresh } from "../actions";
import { getErrorMessage, getTitle, isLoadingIndicatorVisible, isNotFoundVisible } from "../selectors";
import DataAccessRequestDetails, { Actions, Model } from "../components/DataAccessRequestDetails";
import DetailsViewRoute from "@components/details-view/DetailsViewRoute";
import SummaryView from "./SummaryView";
import CommentsView from "./CommentsView";

const ROUTES: DetailsViewRoute[] = [
  {
    id: "summary",
    name: "Summary",
    view: SummaryView,
  },
  {
    id: "comments",
    name: "Comments",
    path: "/comments",
    view: CommentsView,
  },
];

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
  initialize?: (id: string) => void;
}

type Props = ContainerModel & ContainerActions;

const DataAccessRequestDetailsContainer = (props: Props) => {

  const {
    routes = ROUTES,
    initialize: initializeModule = noop,
    ...otherProps
  } = props;

  const { id } = useParams<{ id: string }>();

  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      initializeModule(id);
    }
  }, [id, initialized, setInitialized]);

  return (
    <DataAccessRequestDetails
      {...otherProps}
      routes={routes}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  title: getTitle(state),
  showLoadingIndicator: isLoadingIndicatorVisible(state),
  showNotFound: isNotFoundVisible(state),
  errorMessage: getErrorMessage(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  refresh: () => dispatch(refresh()),
  deleteDataAccessRequest: () => dispatch(deleteDataAccessRequest()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DataAccessRequestDetailsContainer);
