import React from "react";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import SelectServiceRegional, {
  SelectServiceActions,
  SelectServiceModel,
} from "@components/select-service-regional";
import styles from "./styles";

export interface Model extends SelectServiceModel {
  className?: string;
  listClassName?: string;
  title?: string;
}

export interface Actions extends SelectServiceActions {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ServicePrincipalView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    listClassName,
    title = "Select Service Principal",
    children,
    ...otherProps
  } = props;

  return (
    <div className={classnames("servicePrincipalView", className, classes.servicePrincipalView)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      <SelectServiceRegional
        {...otherProps}
        className={classnames("servicePrincipalsList", listClassName, classes.servicePrincipalsList)}
      />
      {children}
    </div>
  );
});

export default ServicePrincipalView;
