import { RequestState, ApprovalRequestType } from "@data";
import { MODULE_PATH } from "@modules/dataApprovalRequests/constants";

export const getPathToDataSetApprovalRequest = (eventId: string) =>
  `${MODULE_PATH}/${eventId}`;

export const getRequestStateDisplayName = (requestState: RequestState | undefined): string => {
  switch (requestState) {
    case RequestState.APPROVED:
      return "Approved";
    case RequestState.PENDING:
    case RequestState.PENDING_DO_APPROVAL:
      return "Pending";
    case RequestState.REJECTED:
      return "Rejected";
    case RequestState.REVOKED:
      return "Revoked";
    case RequestState.CANCELLED:
      return "Cancelled";
    default:
      return "";
  }
};

export const getApprovalRequestTypeDisplayName = (approvalRequestType: ApprovalRequestType | undefined): string => {
  switch (approvalRequestType) {
    case ApprovalRequestType.ADDITIONAL_INFORMATION:
      return "Additional Information";
    case ApprovalRequestType.IOT_APPROVAL:
      return "IOT Approval";
    case ApprovalRequestType.BO_APPROVAL:
      return "Business Owner Approval";
    case ApprovalRequestType.DO_APPROVAL:
      return "Data Owner Approval";
    case ApprovalRequestType.DATA_SET_ACCESS_REQUEST_DO:
      return "Data Owner Access Request";
    case ApprovalRequestType.DATA_SET_ACCESS_REQUEST_LM:
      return "Line Manager Access Request";
    default:
      return "";
  }
};
