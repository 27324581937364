import get from "lodash/get";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import { createSelector } from "reselect";
import { Schema } from "./reducers";
import {
  JsonSchemaDefinition, JsonSchemaMetadata, JsonSchemaMetadataAttributes,
  JsonSchemaPropertiesDefinition,
  JsonSchemaProperty,
} from "@data";

export const SCHEMA_LOCATION = /^.*\/ns\/(.+)\/n\/(.+)\/v\/(.+)$/;
export const SCHEMA_LOCATION_UPDATED = /^.*\/data\/modeling\/v1\/schemas\/identity\/(.+):(.+):(.+)$/;

export const getLocation = (state: Schema): string => {
  return state.location;
};

export const getBreadcrumbs = (state: Schema): string[] => {
  return state.breadcrumbs;
};

export const getRootSchema = (state: Schema): JsonSchemaDefinition => {
  return state.schema;
};

export const getRootSchemaMetadataAttributes = (state: Schema): JsonSchemaMetadataAttributes => {
  return state.metadata;
};

export const getErrorMessage = (state: Schema): string => {
  return state.errorMessage;
};

export const isAccessDeniedVisible = (state: Schema): boolean => {
  return state.showAccessDenied;
};

export const isLoadingIndicatorVisible = (state: Schema): boolean => {
  return state.showLoadingIndicator;
};

export const getRootSchemaMetadata: (state: Schema) => JsonSchemaMetadata = createSelector(
  getRootSchemaMetadataAttributes, (attrs: JsonSchemaMetadataAttributes) =>
    new JsonSchemaMetadata(attrs));

export const getMatch: any = createSelector(
  getLocation, (location: string) => {

    if (!SCHEMA_LOCATION.test(location)) {
      if (!SCHEMA_LOCATION_UPDATED.test(location)) {
        return [];
      } else {
        return location.match(SCHEMA_LOCATION_UPDATED);
      }
    }
    return location.match(SCHEMA_LOCATION);
  });

export const getNamespace: (state: Schema) => string = createSelector(
  getMatch, (match: any) => {
    if (Array.isArray(match) && match.length > 1) {
      return `${match[1]}`.trim();
    } else {
      return "";
    }
  });

export const getName: (state: Schema) => string = createSelector(
  getMatch, (match: any) => {
    if (Array.isArray(match) && match.length > 1) {
      return `${match[2]}`.trim();
    } else {
      return "";
    }
  });

export const getVersion: (state: Schema) => string = createSelector(
  getMatch, (match: any) => {
    if (Array.isArray(match) && match.length > 1) {
      return `${match[3]}`.trim();
    } else {
      return "";
    }
  });

export const isValidLocation: (state: Schema) => boolean = createSelector(
  [getNamespace, getName, getVersion],
  (namespace: string, name: string, version: string) => {
    return namespace.length > 0 && name.length > 0 && version.length > 0;
});

export const isViewingChildProperty: (state: Schema) => boolean = createSelector(
  getBreadcrumbs, (schemaBreadcrumbs: string[]) => schemaBreadcrumbs.length > 0);

export const getPathToProperties: (state: Schema) => string[] = createSelector(
  getBreadcrumbs, (schemaBreadcrumbs: string[]) => {

    if (schemaBreadcrumbs.length === 0) {
      return [];
    }

    return flatten(schemaBreadcrumbs.map((key: string) => ["properties"].concat(key)));
  });

export const getSchema: (state: Schema) => JsonSchemaDefinition = createSelector(
  [getPathToProperties, getRootSchema],
  (path: string[], jsonSchemaDefinition: JsonSchemaDefinition) => {

    if (path.length === 0) {
      return jsonSchemaDefinition;
    }

    return get(jsonSchemaDefinition, path, {});
  });

export const isNotFoundErrorVisible: (state: Schema) => boolean = createSelector(
  getSchema, (selectedSchema: JsonSchemaDefinition) => isEmpty(selectedSchema));

export const getProperties: (state: Schema) => JsonSchemaPropertiesDefinition =
  createSelector(getSchema, (selectedSchema: JsonSchemaDefinition) => {

    const properties = selectedSchema.properties || {};

    return Object.keys(properties).reduce((data, propertyName) => {
      data[propertyName.trim()] = properties[propertyName];
      return data;
    }, {});
  });

export const getRequiredProperties: (state: Schema) => string[] = createSelector(
  getSchema, (selectedSchema: JsonSchemaDefinition) => {

    const requiredProperties = Array.isArray(selectedSchema.required)
      ? selectedSchema.required : [];

    return requiredProperties
      .map((propertyName: string) => propertyName.trim())
      .filter((propertyName: string) => propertyName.length > 0);
  });

export const getJsonSchemaProperties: (state: Schema) => JsonSchemaProperty[] =
  createSelector([getProperties, getRequiredProperties],
    (properties: JsonSchemaPropertiesDefinition, requiredProperties: string[]) => {

      return Object.keys(properties).map((name: string) =>
        JsonSchemaProperty.from(name, properties[name], requiredProperties));
    });

export const getExternalSchemaRef: (state: Schema) => string = createSelector(
  [isViewingChildProperty, getSchema],
  (showChildProperty: boolean, selectedSchema: JsonSchemaDefinition) => {

    if (!showChildProperty) {
      return "";
    }

    const { $ref } = selectedSchema;

    if (typeof $ref !== "string" || $ref.trim().length === 0) {
      return "";
    }

    return $ref;
  });

export const isExternalSchemaRefSelected: (state: Schema) => boolean = createSelector(
  getExternalSchemaRef, (reference: string) =>
    reference.length > 0 && reference.indexOf("#/definitions") !== 0);
