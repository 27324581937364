import React from "react";
import classnames from "classnames";
import TextField from "@components/text-field";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { withTextFieldTooltip } from "@components/tooltip/TextFieldTooltip";
import { enterKeyHandler, formEventHandler, noop } from "../../../../util";
import { styles, textField } from "./styles";

export const MIN_LENGTH_TOOLTIP = "A string is valid against this keyword if its length is " +
  "greater then, or equal to, the value of this keyword. Value of this keyword must be a " +
  "non-negative integer.";

export const MAX_LENGTH_TOOLTIP = "A string is valid against this keyword if its length is " +
  "lower then, or equal to, the value of this keyword. Value of this keyword must be a " +
  "non-negative integer.";

export const PATTERN_TOOLTIP = "A string is valid against this keyword if it matches the regular " +
  "expression specified by the value of this keyword. Value of this keyword must be a string " +
  "representing a valid regular expression.";

const StringConstraintTextField = withStyles(textField)(TextField);

const MinLengthTextField = withTextFieldTooltip(MIN_LENGTH_TOOLTIP)(StringConstraintTextField);
const MaxLengthTextField = withTextFieldTooltip(MAX_LENGTH_TOOLTIP)(StringConstraintTextField);
const PatternTextField = withTextFieldTooltip(PATTERN_TOOLTIP)(StringConstraintTextField);

export interface Model {
  className?: string;
  minLength?: string;
  minLengthError?: string;
  maxLength?: string;
  maxLengthError?: string;
  pattern?: string;
  patternError?: string;
  disabled?: boolean;
}

export interface Actions {
  setMinLength?: (value: string) => void;
  setMaxLength?: (value: string) => void;
  setPattern?: (value: string) => void;
  save?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const StringConstraintsEditor = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    minLength = "",
    minLengthError = "",
    maxLength = "",
    maxLengthError = "",
    pattern = "",
    patternError = "",
    disabled = false,
    setMinLength = noop,
    setMaxLength = noop,
    setPattern = noop,
    save = noop,
  } = props;

  const formHelperTextProps = {
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  return (
    <div className={classnames("stringConstraintsEditor", className, classes.container)}>
      <div className={classes.inlineContainer}>
        <MinLengthTextField
          className={classnames("minLength", classes.inlineInputField)}
          label="Min Length (optional)"
          placeholder="Minimum length (optional)"
          name="minLength"
          value={minLength}
          helperText={minLengthError}
          error={minLengthError.length > 0}
          onChange={formEventHandler(setMinLength)}
          onKeyDown={enterKeyHandler(save)}
          autoComplete="off"
          fullWidth={false}
          disabled={disabled}
          variant="outlined"
          margin="none"
          FormHelperTextProps={formHelperTextProps}
          InputLabelProps={inputLabelProps}
        />
        <MaxLengthTextField
          className={classnames("maxLength", classes.inlineInputField)}
          label="Max Length (optional)"
          placeholder="Maximum length"
          name="maxLength"
          value={maxLength}
          helperText={maxLengthError}
          error={maxLengthError.length > 0}
          onChange={formEventHandler(setMaxLength)}
          onKeyDown={enterKeyHandler(save)}
          autoComplete="off"
          fullWidth={false}
          disabled={disabled}
          variant="outlined"
          margin="none"
          FormHelperTextProps={formHelperTextProps}
          InputLabelProps={inputLabelProps}
        />
      </div>
      <PatternTextField
        className={classnames("pattern", classes.inputField)}
        label="Pattern (optional)"
        placeholder="Regular expression this property must validate against (optional)"
        name="pattern"
        value={pattern}
        helperText={patternError}
        error={patternError.length > 0}
        onChange={formEventHandler(setPattern)}
        onKeyDown={enterKeyHandler(save)}
        autoComplete="off"
        fullWidth={true}
        disabled={disabled}
        variant="outlined"
        margin="none"
        FormHelperTextProps={formHelperTextProps}
        InputLabelProps={inputLabelProps}
      />
    </div>
  );
});

export default StringConstraintsEditor;
