import { Color } from "@Color";
import ModuleIcon from "@material-ui/icons/PlayCircleOutline";
import { createStyles } from "@material-ui/core";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const summaryView = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    dataSetContainer: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      marginTop: 25,
    },
    dataSetInputContainer: {
      display: "flex",
      flex: "0 0 50%",
      flexFlow: "column nowrap",
      paddingRight: 20,
    },
    dataSetOutputContainer: {
      display: "flex",
      flex: "0 0 50%",
      flexFlow: "column nowrap",
      paddingLeft: 20,
    },
    dataListView: {
      marginBottom: 40,
    },
    dataSetTitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      minWidth: 100,
      alignSelf: "flex-start",
      fontSize: 24,
      lineHeight: "28px",
    },
  });

export const logsView = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    filters: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      paddingTop: 15,
      paddingBottom: 8,
    },
    datePicker: {
      marginRight: 8,
      flex: "1 0 0",
    },
    level: {
      minWidth: 200,
      marginRight: 8,
      "& .dropdownMenu": {
        minWidth: 200,
      }
    },
    limit: {
      "& label + .dropdownMenu": {
        marginTop: 8,
        minWidth: 75,
      },
      minWidth: 75,
    },
    notSupported: {
      marginTop: 40,
    }
  });
