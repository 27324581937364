import React from "react";
import { DeviceTypeListItem as DeviceTypeData } from "@data";
import { useHistory } from "react-router-dom";
import { getPathToDeviceType } from "@modules/deviceTypeDetails/helpers";
import { getCreateDeviceTypePath } from "@modules/deviceTypeWizard/helpers";
import DeviceTypes from "./containers/DeviceTypes";

export function DeviceTypesView() {

  const history = useHistory();

  const createDeviceType = React.useCallback(() =>
    history.push(getCreateDeviceTypePath()), [history]);

  const showDeviceTypeDetails = React.useCallback((deviceType: DeviceTypeData) =>
    history.push(getPathToDeviceType(deviceType.getTypeIdentity())), [history]);

  return (
    <DeviceTypes
      createDeviceType={createDeviceType}
      showDeviceTypeDetails={showDeviceTypeDetails}
    />
  );
}

export default DeviceTypesView;
