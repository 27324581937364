import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { setFormat, setType, close, generateUserReport } from "../actions";
import {
  isGenerateDialog,
  isLoadingIndicatorVisible,
  isAccessDeniedVisible,
  getErrorMessage,
  getSuccessMessage,
  getType,
  getFormat,
} from "../selectors";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import GenerateUserReport from "../components/GenerateUserReport";
import { ReportFormat, ReportType } from "@data";

interface Model extends PortalModuleDialogModel<string> {
  type?: ReportType;
  format?: ReportFormat;
}

interface Actions extends PortalModuleDialogActions<string> {
  setReportType?: (type: ReportType) => void;
  setReportFormat?: (format: ReportFormat) => void;
}

type Props = Model & Actions;

const GenerateUserReportDialog = (props: Props) => {

  const {
    type,
    format,
    setReportType,
    setReportFormat,
    ...otherProps
  } = props;

  const { loading } = otherProps;

  return (
    <PortalModuleDialog {...otherProps}>
      <GenerateUserReport
        type={type}
        format={format}
        disabled={loading}
        setType={setReportType}
        setFormat={setReportFormat}
      />
    </PortalModuleDialog>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  className: "generateUserReportDialog",
  title: "Export User Info",
  open: isGenerateDialog(state),
  loading: isLoadingIndicatorVisible(state),
  accessDenied: isAccessDeniedVisible(state),
  errorMessage: getErrorMessage(state),
  successMessage: getSuccessMessage(state),
  continueButtonLabel: "Continue",
  type: getType(state),
  format: getFormat(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  cancel: () => dispatch(close()),
  confirm: () => dispatch(generateUserReport()),
  setReportFormat: (format: ReportFormat) => dispatch(setFormat(format)),
  setReportType: (type: ReportType) => dispatch(setType(type)),
  ...ownProps
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(GenerateUserReportDialog);
