import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import {
  ActionMenuItem,
  DataApprovalRequestAction,
  DEFAULT_DATA_APPROVAL_REQUEST_ACTION_MENU_ITEMS,
  DownloadTermsAndConditionsButton,
  DownloadTrainingCertificateButton,
} from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DetailsView, { DetailsViewActions, DetailsViewModel } from "@components/details-view";
import styles, { PrimaryIcon } from "./styles";

export interface Model extends DetailsViewModel {
  trainingCertificateId?: string;
  termsConditionsId?: string;
  viewTrainingCertButtonLabel?: string;
  viewTermsConditionsButtonLabel?: string;
}

export interface Actions extends DetailsViewActions {
  approveDataApprovalRequest?: () => void;
  rejectDataApprovalRequest?: () => void;
  trackDownloadTermsAndConditionsRequestEvent?: () => void;
  trackDownloadTermsAndConditionsSuccessEvent?: () => void;
  trackDownloadTermsAndConditionsErrorEvent?: (analytic: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataApprovalRequestDetails = withStyles(styles)((props: Props) => {

  const {
    classes,
    icon = PrimaryIcon,
    title = "Approval Request Details",
    actions = DEFAULT_DATA_APPROVAL_REQUEST_ACTION_MENU_ITEMS,
    actionsLabel = "Actions",
    showLoadingIndicator,
    trainingCertificateId,
    termsConditionsId,
    viewTrainingCertButtonLabel,
    viewTermsConditionsButtonLabel,
    approveDataApprovalRequest = noop,
    rejectDataApprovalRequest = noop,
    onClickAction = noop,
    trackDownloadTermsAndConditionsRequestEvent = noop,
    trackDownloadTermsAndConditionsSuccessEvent = noop,
    trackDownloadTermsAndConditionsErrorEvent = noop,
    children,
    ...otherProps
  } = props;

  const downloadTrainingCertificateTab = React.useMemo(() =>
    isEmptyString(trainingCertificateId) ? null : (
      <DownloadTrainingCertificateButton
        className={classnames("viewTrainingCertButton", classes.viewModeButton)}
        buttonClassName={classnames("downloadButton", classes.downloadButton)}
        disabled={showLoadingIndicator}
        label={viewTrainingCertButtonLabel}
        trainingCertificateId={trainingCertificateId}
      />
    ), [
    classes,
    showLoadingIndicator,
    viewTrainingCertButtonLabel,
    trainingCertificateId,
  ]);

  const downloadTermsAndConditionsTab = React.useMemo(() =>
    isEmptyString(termsConditionsId) ? null : (
      <DownloadTermsAndConditionsButton
        className={classnames("viewTermsAndConditionsButton", classes.viewModeButton)}
        buttonClassName={classnames("downloadButton", classes.downloadButton)}
        disabled={showLoadingIndicator}
        label={viewTermsConditionsButtonLabel}
        termsConditionsId={termsConditionsId}
        trackRequestEvent={trackDownloadTermsAndConditionsRequestEvent}
        trackSuccessEvent={trackDownloadTermsAndConditionsSuccessEvent}
        trackErrorEvent={trackDownloadTermsAndConditionsErrorEvent}
      />
    ), [
    classes,
    showLoadingIndicator,
    viewTermsConditionsButtonLabel,
    termsConditionsId,
    trackDownloadTermsAndConditionsRequestEvent,
    trackDownloadTermsAndConditionsSuccessEvent,
    trackDownloadTermsAndConditionsErrorEvent,
  ]);

  const customTabs = React.useMemo(() => (
    <React.Fragment>
      {downloadTrainingCertificateTab}
      {downloadTermsAndConditionsTab}
    </React.Fragment>
  ), [
    downloadTrainingCertificateTab,
    downloadTermsAndConditionsTab,
  ]);

  const actionClicked = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case DataApprovalRequestAction.APPROVE:
        return approveDataApprovalRequest();
      case DataApprovalRequestAction.REJECT:
        return rejectDataApprovalRequest();
      default:
        return onClickAction(action);
    }
  }, [approveDataApprovalRequest, rejectDataApprovalRequest, onClickAction]);

  return (
    <DetailsView
      {...otherProps}
      className={classnames("dataApprovalRequestDetails", classes.container)}
      icon={icon}
      title={title}
      customTabs={customTabs}
      actions={actions}
      actionsLabel={actionsLabel}
      showLoadingIndicator={showLoadingIndicator}
      onClickAction={actionClicked}
    >
      {children}
    </DetailsView>
  );
});

export default DataApprovalRequestDetails;
