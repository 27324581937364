import Module from "@data/Module";
import { initialize } from "./actions";
import { getPathToUser } from "./helpers";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH, MY_PROFILE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { isAccessDeniedVisible, isEmptyViewVisible } from "./selectors";
import view from "./UserDetailsView";
import { isEmptyString } from "@util";

type RouteParams = { id?: string };

export const userDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: false,
  name: "User Details",
  title: "User Details",
  className: "userDetailsModule",
  primaryColor,
  icon,
  view,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: ({ id = "" }: RouteParams = {}) => initialize(id),
  redirects: {
    "viewUser": (action, resourceId, extraQueryParams) =>
      isEmptyString(resourceId) ? null : `${getPathToUser(resourceId)}?${extraQueryParams}`,
  },
});

export const myProfileModule: Module = createModule<RouteParams>({
  id: "myProfile",
  path: MY_PROFILE_PATH,
  exactPath: false,
  strictPath: false,
  name: "My Profile",
  title: "My Profile",
  className: "myProfileModule",
  primaryColor,
  icon,
  view,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  onMount: () => initialize(""),
});

export default userDetailsModule;
