import React from "react";
import { Store } from "redux";
import { History } from "history";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { AppSchema } from "@main/schemas";
import { createBrowserHistory } from "history";
import Portal from "@modules/Portal";

export const Application = (props: {
  store: Store<AppSchema>,
  history?: History,
  children?: React.ReactNode,
}) => {

  const { store, history = createBrowserHistory(), children } = props;

  return (
    <Provider store={store}>
      <Router history={history}>
        <Portal />
        {children}
      </Router>
    </Provider>
  );
};

export default Application;
