import React from "react";
import { TriggerType } from "@data";
import { useRouteMatch } from "react-router-dom";
import {
  ADD_DATA_LAKE_MODULE_PATH,
  ADD_SCHEDULE_TRIGGER_MODULE_PATH,
  ADD_WORKLOAD_COMPLETE_MODULE_PATH,
  EDIT_DATA_LAKE_MODULE_PATH,
  EDIT_SCHEDULE_TRIGGER_MODULE_PATH,
  EDIT_WORKLOAD_COMPLETE_MODULE_PATH,
} from "./constants";

export function useTriggerType(): TriggerType {

  const addScheduleTriggerRouteMatch = useRouteMatch(ADD_SCHEDULE_TRIGGER_MODULE_PATH);

  const editScheduleTriggerRouteMatch = useRouteMatch(EDIT_SCHEDULE_TRIGGER_MODULE_PATH);

  const scheduleTriggerActive = React.useMemo(() => {
    if (addScheduleTriggerRouteMatch) {
      return addScheduleTriggerRouteMatch.isExact;
    } else {
      return editScheduleTriggerRouteMatch && editScheduleTriggerRouteMatch.isExact;
    }
  }, [addScheduleTriggerRouteMatch, editScheduleTriggerRouteMatch]);

  const addDataLakeTriggerRouteMatch = useRouteMatch(ADD_DATA_LAKE_MODULE_PATH);

  const editDataLakeTriggerRouteMatch = useRouteMatch(EDIT_DATA_LAKE_MODULE_PATH);

  const dataLakeTriggerActive = React.useMemo(() => {
    if (addDataLakeTriggerRouteMatch) {
      return addDataLakeTriggerRouteMatch.isExact;
    } else {
      return editDataLakeTriggerRouteMatch && editDataLakeTriggerRouteMatch.isExact;
    }
  }, [addDataLakeTriggerRouteMatch, editDataLakeTriggerRouteMatch]);

  const addWorkloadCompleteTriggerRouteMatch = useRouteMatch(ADD_WORKLOAD_COMPLETE_MODULE_PATH);

  const editWorkloadCompleteTriggerRouteMatch = useRouteMatch(EDIT_WORKLOAD_COMPLETE_MODULE_PATH);

  const workloadCompleteTriggerActive = React.useMemo(() => {
    if (addWorkloadCompleteTriggerRouteMatch) {
      return addWorkloadCompleteTriggerRouteMatch.isExact;
    } else {
      return editWorkloadCompleteTriggerRouteMatch && editWorkloadCompleteTriggerRouteMatch.isExact;
    }
  }, [addWorkloadCompleteTriggerRouteMatch, editWorkloadCompleteTriggerRouteMatch]);

  return React.useMemo(() => {
    if (scheduleTriggerActive) {
      return TriggerType.SCHEDULE;
    } else if (dataLakeTriggerActive) {
      return TriggerType.DATA_LAKE;
    } else if (workloadCompleteTriggerActive) {
      return TriggerType.WORKLOAD_COMPLETE;
    } else {
      return TriggerType.NONE;
    }
  }, [scheduleTriggerActive, dataLakeTriggerActive, workloadCompleteTriggerActive]);
}

export default useTriggerType;
