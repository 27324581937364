import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    list: {
      marginTop: 0,
      "& tr.loadingView p.loadingLabel": {
        margin: 0,
      },
      "& tr.noResultsView p.noResultsLabel": {
        margin: 0,
      },
    },
    fileDetailsDialog: {
    },
    loadMoreButton: {
      height: "auto",
    },
  });

export default styles;
