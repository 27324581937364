import React from "react";
import Module from "@data/Module";
import classnames from "classnames";
import { getStringValue } from "@util/Functions";
import { ListItemType, NavigationListItem } from "./NavigationListItem";

export const ModuleListItem = (props: Module & {
  className?: string,
}) => {

  const {
    className,
    id,
    name,
    icon,
    path: modulePath,
    exactPath,
    strictPath,
    disabled,
    navigation,
    isNavLinkActive: isActive,
  } = props;

  const path = React.useMemo(() => getStringValue(
    Array.isArray(modulePath) ? modulePath[0] : modulePath), [modulePath]);

  if (disabled || !path) {
    return null;
  }

  return (
    <React.Fragment>
      <NavigationListItem
        className={classnames("moduleListItem", className)}
        id={id}
        name={name}
        icon={icon}
        path={path}
        exactPath={exactPath}
        strictPath={strictPath}
        type={ListItemType.LINK}
        clickable={true}
        isActive={isActive}
      />
      {navigation}
    </React.Fragment>
  );
};

export default ModuleListItem;
