import React from "react";
import { noop } from "@util";
import { SecurityGroup } from "@data";
import { SecurityGroupIcon } from "./styles";
import { ActionMenuItem } from "../actions-menu";
import { SecurityGroupAction } from "../security-group-actions-menu";
import { SecurityGroupsListColumn } from "./SecurityGroupsListColumn";
import { sortAndFilterSecurityGroupResults } from "./useSecurityGroups";
import {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import ModuleListView from "@components/module-list-view";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import { getPathToSecurityGroup } from "@components/security-groups-list/helpers";
import { DeleteSecurityGroupDialog } from "@components/delete-security-group-dialog";

export const DEFAULT_SECURITY_GROUPS_LIST_COLUMNS: SecurityGroupsListColumn[] = [
  SecurityGroupsListColumn.NAME,
  SecurityGroupsListColumn.PATH,
];

export type SecurityGroupsListModel = SortedSearchResultsListModel<SecurityGroup, SecurityGroupsListColumn>;

export interface SecurityGroupsListActions extends
  SortedSearchResultsListActions<SecurityGroup, SecurityGroupsListColumn> {

  attachPolicy?: (group: SecurityGroup) => void;
  detachPolicy?: (group: SecurityGroup) => void;
  addUser?: (group: SecurityGroup) => void;
  removeUser?: (group: SecurityGroup) => void;
  addService?: (group: SecurityGroup) => void;
  removeService?: (group: SecurityGroup) => void;
  onDeleteSuccess?: () => void;
}

type Props = SecurityGroupsListModel & SecurityGroupsListActions;

export const SecurityGroupsList = (props: Props) => {

  const {
    className = "securityGroups",
    listClassName = "securityGroupsList",
    columns = DEFAULT_SECURITY_GROUPS_LIST_COLUMNS,
    sortByColumn = SecurityGroupsListColumn.NAME,
    sortOrderAscending = true,
    noResultsLabel = "No security groups found",
    searchViewHint = "Filter results by group name",
    summaryViewQuantities = {
      other: "security groups",
      one: "security group",
    },
    items = [],
    nameFilter = "",
    nameFilterDelay = 250,
    attachPolicy = noop,
    detachPolicy = noop,
    addUser = noop,
    removeUser = noop,
    addService = noop,
    removeService = noop,
    onClickAction = noop,
    onDeleteSuccess,
    ...otherProps
  } = props;

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);
  const [securityGroupName, setSecurityGroupName] = React.useState<string>("");

  const deleteSecurityGroup = React.useCallback((group: SecurityGroup) => {
    setSecurityGroupName(group.getName());
    setOpenDeleteDialog(true);
  }, [setSecurityGroupName, setOpenDeleteDialog]);

  const closeDeleteDialog = React.useCallback(() => {
    setOpenDeleteDialog(false);
  }, [setOpenDeleteDialog]);

  const onDeleteGroupSuccess = React.useCallback(() => {
    if (onDeleteSuccess) {
      onDeleteSuccess();
    }
    closeDeleteDialog();
  }, [onDeleteSuccess, closeDeleteDialog]);

  const onActionClicked = React.useCallback((group: SecurityGroup, action: ActionMenuItem) => {
    switch (action.id) {
      case SecurityGroupAction.ATTACH_POLICY:
        return attachPolicy(group);
      case SecurityGroupAction.DETACH_POLICY:
        return detachPolicy(group);
      case SecurityGroupAction.ADD_USER:
        return addUser(group);
      case SecurityGroupAction.REMOVE_USER:
        return removeUser(group);
      case SecurityGroupAction.ADD_SERVICE:
        return addService(group);
      case SecurityGroupAction.REMOVE_SERVICE:
        return removeService(group);
      case SecurityGroupAction.DELETE_SECURITY_GROUP:
        return deleteSecurityGroup(group);
      default:
        return onClickAction(group, action);
    }
  }, [attachPolicy, detachPolicy, addUser, removeUser, deleteSecurityGroup, onClickAction]);

  const securityGroups = sortAndFilterSecurityGroupResults({ items, nameFilter, sortOrderAscending, sortByColumn });

  const moduleListItems: ListViewItem<SecurityGroup>[] = React.useMemo(() =>
    securityGroups.map(group => {
      return {
        item: group,
        pathToDetailsView: getPathToSecurityGroup(group.getName()),
        icon: SecurityGroupIcon,
        columnAttributes: createColumns([
          {
            className: "groupName",
            value: group.getName(),
            column: SecurityGroupsListColumn.NAME,
            firstColumn: true,
          },
          {
            className: "groupPath",
            value: group.getPath(),
            column: SecurityGroupsListColumn.PATH,
          },
        ])
      };
    }), [securityGroups]);

  return (
    <React.Fragment>
      <ModuleListView
        {...otherProps}
        className={className}
        listViewItems={moduleListItems}
        columns={columns}
        nameFilter={nameFilter}
        nameFilterDelay={nameFilterDelay}
        sortByColumn={sortByColumn}
        sortOrderAscending={sortOrderAscending}
        noResultsLabel={noResultsLabel}
        searchViewHint={searchViewHint}
        summaryViewIcon={SecurityGroupIcon}
        summaryViewQuantities={summaryViewQuantities}
        onClickAction={onActionClicked}
      />
      {onDeleteSuccess && (
        <DeleteSecurityGroupDialog
          open={openDeleteDialog}
          cancel={closeDeleteDialog}
          securityGroupName={securityGroupName}
          onSuccessMessageShown={onDeleteGroupSuccess}
        />
      )}
    </React.Fragment>
  );
};

export default SecurityGroupsList;
