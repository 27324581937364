import createStyles from "@material-ui/core/styles/createStyles";
import { Color } from "@components";

export const dialog = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    uploadBox: {
    }
  });

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    errorView: {
      paddingBottom: 10,
      marginBottom: 35,
      "& label.title, & label.message": {
        marginTop: 10,
      },
      "& button.tryAgainButton": {
        color: Color.DARK_RED,
      },
    },
  });

export default styles;
