import React from "react";
import classnames from "classnames";
import { ActionMenuItem, ActionsMenu, ActionsMenuProps } from "@components/actions-menu";
import { noop } from "@util";

export enum UiPackageManagementAction {
  UPLOAD_UI_PACKAGE = "upload_ui_package",
  DELETE_UI_PACKAGE = "delete_ui_package",
}

export const UPLOAD_UI_PACKAGE_ACTION: ActionMenuItem = Object.freeze({
  id: UiPackageManagementAction.UPLOAD_UI_PACKAGE,
  name: "Upload UI Package",
  disabled: false,
});

export const DELETE_UI_PACKAGE_ACTION: ActionMenuItem = Object.freeze({
  id: UiPackageManagementAction.DELETE_UI_PACKAGE,
  name: "Delete UI Package",
  disabled: false,
});

export const DEFAULT_UI_PACKAGE_ACTION_MENU_ITEMS: ActionMenuItem[] = Object.freeze([
  UPLOAD_UI_PACKAGE_ACTION,
  DELETE_UI_PACKAGE_ACTION,
]) as ActionMenuItem[];

export interface UiPackageManagementActionsMenuProps extends ActionsMenuProps {
  uploadUiPackage?: () => void;
  deleteUiPackage?: () => void;
}

export const withUiPackageManagementActions =
  (Component: React.ComponentType<UiPackageManagementActionsMenuProps>) =>
    (props: UiPackageManagementActionsMenuProps) => {

      const {
        actions = DEFAULT_UI_PACKAGE_ACTION_MENU_ITEMS,
        uploadUiPackage = noop,
        deleteUiPackage = noop,
        onClickAction = noop,
      } = props;

      return (
        <Component
          {...props}
          actions={actions}
          onClickAction={(action: ActionMenuItem) => {
            if (action.id === UiPackageManagementAction.UPLOAD_UI_PACKAGE) {
              uploadUiPackage();
            } else if (action.id === UiPackageManagementAction.DELETE_UI_PACKAGE) {
              deleteUiPackage();
            } else {
              onClickAction(action);
            }
          }}
        />
      );
    };

export const UiPackageManagementActionsMenu =
  withUiPackageManagementActions((props: UiPackageManagementActionsMenuProps) => {

    const { className, buttonLabel = "Attribute Actions" } = props;

    return (
      <ActionsMenu
        {...props}
        className={classnames("uiPackageManagementActionsMenu", className)}
        buttonLabel={buttonLabel}
      />
    );
  });

export default UiPackageManagementActionsMenu;
