import React from "react";
import { SearchFilter } from "@data";
import { AuditEventFilter } from "@components/audit-events/AuditEventFilter";
import { compare, isEmptyString } from "@util";

export interface SelectedValues {
  selectedTypes?: string[];
  selectedSeverities?: string[];
  selectedAccountIds?: string[];
  selectedPrincipals?: string[];
  selectedOrigins?: string[];
  selectedRequestIds?: string[];
}

type Result = SelectedValues;

export const useSearchFilters = (searchFilters: SearchFilter[] = []): Result => {

  const mapSearchFiltersToValue = React.useCallback((type: AuditEventFilter) => {

    const selectedValues = Array.from(new Set(searchFilters
      .filter(searchFilter => type === searchFilter.getKey() && !isEmptyString(searchFilter.getValue()))
      .map(searchFilter => searchFilter.getValue())));

    selectedValues.sort((left, right) => compare(left, right, true));

    return selectedValues;

  }, [searchFilters]);

  const selectedTypes = React.useMemo(() =>
    mapSearchFiltersToValue(AuditEventFilter.TYPE),
    [mapSearchFiltersToValue]);

  const selectedSeverities = React.useMemo(() =>
    mapSearchFiltersToValue(AuditEventFilter.SEVERITY),
    [mapSearchFiltersToValue]);

  const selectedAccountIds = React.useMemo(() =>
    mapSearchFiltersToValue(AuditEventFilter.ACCOUNT_ID),
    [mapSearchFiltersToValue]);

  const selectedPrincipals = React.useMemo(() =>
    mapSearchFiltersToValue(AuditEventFilter.PRINCIPAL),
    [mapSearchFiltersToValue]);

  const selectedOrigins = React.useMemo(() =>
    mapSearchFiltersToValue(AuditEventFilter.ORIGIN),
    [mapSearchFiltersToValue]);

  const selectedRequestIds = React.useMemo(() =>
    mapSearchFiltersToValue(AuditEventFilter.REQUEST_ID),
    [mapSearchFiltersToValue]);

  return React.useMemo(() => ({
    selectedTypes,
    selectedSeverities,
    selectedAccountIds,
    selectedPrincipals,
    selectedOrigins,
    selectedRequestIds,
  }), [
    selectedTypes,
    selectedSeverities,
    selectedAccountIds,
    selectedPrincipals,
    selectedOrigins,
    selectedRequestIds,
  ]);
};

export default useSearchFilters;
