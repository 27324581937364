import React from "react";
import { isEmptyString } from "@util";
import { SecurityGroup } from "@data";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
} from "@components/submit-api-request-view";
import { useCreateSecurityGroup } from "@hooks";
import SecurityGroupInfo from "../components/SecurityGroupInfo";

interface ContainerModel extends SubmitApiRequestViewModel<SecurityGroup> {
}

interface ContainerActions extends SubmitApiRequestViewActions<SecurityGroup> {
}

type Props = ContainerModel & ContainerActions;

const CreateSecurityGroupContainer = (props: Props) => {

  const {
    currentState = SecurityGroup.EMPTY,
    defaultState = SecurityGroup.EMPTY,
    ...otherProps
  } = props;

  const [securityGroup, setSecurityGroup] = React.useState<SecurityGroup>(defaultState);

  const [{ errorMessage, ...otherModel }, { createSecurityGroup, reset }] = useCreateSecurityGroup({
    json: JSON.stringify(securityGroup.toJS())
  });

  const saveButtonDisabled = React.useMemo(() => {
    return !securityGroup.hasName() || !securityGroup.hasPath() || !securityGroup.isValidPath();
  }, [securityGroup]);

  const setName = React.useCallback((name: string) => {
    setSecurityGroup(new SecurityGroup({...securityGroup.toJS(), name: name.toLowerCase()}));
  }, [securityGroup, setSecurityGroup]);

  const setPath = React.useCallback((path: string) => {
    setSecurityGroup(new SecurityGroup({...securityGroup.toJS(), path}));
  }, [securityGroup, setSecurityGroup]);

  const pathErrorMessage = React.useMemo(() => {
    return !securityGroup.isValidPath() ? "Path must end with '/'" : "";
  }, [securityGroup]);

  React.useEffect(() => {
    if (!isEmptyString(errorMessage)) {
      reset();
    }
  }, [securityGroup, reset]);

  return (
    <SubmitApiRequestView
      {...otherModel}
      {...otherProps}
      snackbarId="create-security-group"
      errorTitle="Create Security Group Failed"
      saveButtonLabel="Create Security Group"
      currentState={securityGroup}
      defaultState={defaultState}
      errorMessage={errorMessage}
      save={createSecurityGroup}
      saveButtonDisabled={saveButtonDisabled}
    >
      <SecurityGroupInfo
        setName={setName}
        setPath={setPath}
        save={createSecurityGroup}
        name={securityGroup.getName()}
        path={securityGroup.getPath()}
        saveOnEnter={!saveButtonDisabled}
        pathErrorMessage={pathErrorMessage}
      />
    </SubmitApiRequestView>
  );
};

export default CreateSecurityGroupContainer;
