import React from "react";
import classnames from "classnames";
import { isEmptyString } from "@util";
import Button, { ButtonProps } from "@components/button";
import { NavLink, useRouteMatch } from "react-router-dom";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const NavLinkButton = (props: ButtonProps<NavLink, { component?: NavLink }>) =>
  <Button {...props} component={NavLink} />;

export interface LinkButtonModel {
  className?: string;
  activeClassName?: string;
  disabled?: boolean;
  label?: string;
  path?: string;
  exact?: boolean;
  strict?: boolean;
  variant?: "text" | "outlined" | "contained";
}

export interface LinkButtonActions {
}

type Model = LinkButtonModel;
type Actions = LinkButtonActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const LinkButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    activeClassName,
    disabled,
    label = "",
    path = "",
    exact = true,
    strict = true,
    variant = "contained",
    children,
    ...otherProps
  } = props;

  const match = useRouteMatch(path);

  const isActive = React.useMemo(() => match && match.isExact, [match]);

  if (isEmptyString(label) || isEmptyString(path)) {
    return null;
  }

  return (
    <NavLinkButton
      {...otherProps}
      className={classnames("linkButton", className, classes.button)}
      activeClassName={classnames("active", activeClassName, classes.active)}
      color={isActive ? "primary" : "inherit"}
      variant={variant}
      disabled={disabled && !isActive}
      strict={strict}
      exact={exact}
      to={path}
    >
      {label}
      {children}
    </NavLinkButton>
  );
});

export default LinkButton;
