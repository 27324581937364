import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  PortalModuleActionTypes,
  createPortalModuleDefaultState,
} from "@base/createReducers";

export enum WizardType {
  VISUAL_EDITOR = "visual_editor",
  JSON = "json"
}

export interface DigitalShadowLayoutWizardSchema extends BasePortalModuleSchema {
  layoutName: string;
  editMode: boolean;
}

export const DEFAULT_STATE: DigitalShadowLayoutWizardSchema = createPortalModuleDefaultState({
  layoutName: "",
  editMode: false,
});

type DigitalShadowLayoutWizardActionTypes = PortalModuleActionTypes<DigitalShadowLayoutWizardSchema>;

export const ACTION_TYPES: DigitalShadowLayoutWizardActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<DigitalShadowLayoutWizardSchema>(MODULE_ID, DEFAULT_STATE),
});

export const DigitalShadowLayoutWizardReducer = combineReducers<DigitalShadowLayoutWizardSchema>(
  createPortalModuleReducers<DigitalShadowLayoutWizardSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DigitalShadowLayoutWizardReducer;
