import React from "react";
import classnames from "classnames";
import { clickHandler, noop } from "@util";
import { ContinueButton } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface DataCatalogControlsModel {
  className?: string;
}

export interface DataCatalogControlsActions {
  createDataSetRequest?: () => void;
}

type Model = DataCatalogControlsModel;
type Actions = DataCatalogControlsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataCatalogControls = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    createDataSetRequest = noop,
    children,
  } = props;

  return (
    <div className={classnames("dataCatalogControls", className, classes.container)}>
      <ContinueButton
        className={classnames("createDataSetRequestButton", classes.button, classes.createDataSetRequestButton)}
        label="Create Data Set"
        onClick={clickHandler(createDataSetRequest)}
        primary={true}
      />
      {children}
    </div>
  );
});

export default DataCatalogControls;
