import { Module } from "@data";
import { createModule } from "@modules/base/createModule";
import { getCreateEmailTemplatePath, getEditEmailTemplatePath } from "./helpers";
import { primaryColor, PrimaryIcon as icon } from "@modules/emailTemplates/components/styles";
import CreateEmailTemplateView from "./CreateEmailTemplateView";
import EditEmailTemplateView from "./EditEmailTemplateView";

type RouteParams = { id?: string; };

export const createEmailTemplateWizardModule: Module = createModule ({
  id: "create_email_template",
  path: getCreateEmailTemplatePath(),
  exactPath: true,
  strictPath: true,
  name: "Create Email Template Wizard",
  title: "Create New Email Template",
  className: "createEmailTemplateWizardModule",
  primaryColor,
  icon,
  view: CreateEmailTemplateView,
});

export const editEmailTemplateWizardModule: Module = createModule<RouteParams> ({
  id: "edit_email_template",
  path: getEditEmailTemplatePath(":id"),
  exactPath: true,
  strictPath: true,
  name: "Edit Email Template Wizard",
  title: "Edit Email Template",
  className: "editEmailTemplateWizardModule",
  primaryColor,
  icon,
  view: EditEmailTemplateView,
});
