export enum AccessRequestApprovalDetailsListColumn {
  NONE = "",
  APPROVAL_REQUEST_TYPE = "Request Type",
  REQUEST_STATE = "State",
  APPROVERS = "Approvers",
  APPROVAL_COMMENTS = "Comments",
  APPROVED_BY = "Approved By",
  APPROVED_AT = "Approved At",
}

export default AccessRequestApprovalDetailsListColumn;
