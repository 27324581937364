import React from "react";
import classnames from "classnames";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DataLakeDataSet from "@data/DataLakeDataSet";
import DataSetNameFilter from "./DataSetNameFilter";
import StorageIcon from "@material-ui/icons/Storage";
import { IconButton } from "@components";
import Typography from "@material-ui/core/Typography";
import { clickHandler, compare, isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ErrorView, RefreshButton, SortDescIcon, SortIcon } from "@components";
import styles from "./styles";

export interface DataSetsViewModel {
  className?: string;
  dataSets?: DataLakeDataSet[];
  selectedDataSet?: DataLakeDataSet;
  sortOrderAscending?: boolean;
  nameFilter?: string;
  showLoadingIndicator?: boolean;
  disabled?: boolean;
  errorTitle?: string;
  errorMessage?: string;
  errorViewRetryButtonLabel?: string;
}

export interface DataSetsViewActions {
  refresh?: () => void;
  setSelectedDataSet?: (dataSet: DataLakeDataSet) => void;
  onChangeSortOrder?: (ascending: boolean) => void;
  onChangeNameFilter?: (nameFilter: string) => void;
}

type Model = DataSetsViewModel;
type Actions = DataSetsViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataSetsView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    dataSets = [],
    selectedDataSet = dataSets[0] || DataLakeDataSet.EMPTY,
    sortOrderAscending: initialSortOrderAscending = true,
    nameFilter: initialNameFilter = "",
    showLoadingIndicator,
    disabled,
    errorTitle = "Failed to load data sets",
    errorMessage = "",
    errorViewRetryButtonLabel = "Retry",
    refresh = noop,
    setSelectedDataSet = noop,
    onChangeSortOrder = noop,
    onChangeNameFilter = noop,
    children,
  } = props;

  const [nameFilter, setNameFilter] = React.useState(initialNameFilter);

  const [ascending, setAscending] = React.useState<boolean>(initialSortOrderAscending);

  const Icon = React.useMemo(() => ascending ? SortIcon : SortDescIcon, [ascending]);

  const visibleItems = React.useMemo(() => {
    const searchQuery = nameFilter.toLowerCase();
    const filteredItems = isEmptyString(nameFilter)
      ? dataSets.slice()
      : dataSets.filter(dataSet => dataSet.getDataSetAlias().toLowerCase().indexOf(searchQuery) >= 0);
    filteredItems.sort((left, right) => compare(
      left.getDataSetAlias(),
      right.getDataSetAlias(),
      ascending));
    return filteredItems;
  }, [nameFilter, dataSets, ascending]);

  const showErrorView = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]);

  const toggleSortOrder = React.useCallback(clickHandler(() =>
    setAscending(!ascending)), [setAscending, ascending]);

  React.useEffect(() => {
    onChangeNameFilter(nameFilter);
  }, [nameFilter, onChangeNameFilter]);

  React.useEffect(() => {
    onChangeSortOrder(ascending);
  }, [ascending, onChangeSortOrder]);

  return (
    <div className={classnames("dataSetsView", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        <Typography className={classnames("title", classes.title)}>
          Data Sets
        </Typography>
      </div>
      <div className={classnames("controls", classes.controls)}>
        <IconButton
          className={classnames("sortIconButton", classes.sortIconButton)}
          size="small"
          disabled={disabled || showLoadingIndicator || showErrorView}
          onClick={toggleSortOrder}
        >
          <Icon className={classnames("sortIcon", classes.sortIcon)} />
        </IconButton>
        <DataSetNameFilter
          className={classnames("nameFilter", classes.nameFilter)}
          disabled={disabled || showLoadingIndicator || showErrorView}
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
        />
        <RefreshButton
          className={classnames("refreshIconButton", classes.refreshIconButton)}
          iconClassName={classnames("refreshIcon", classes.refreshIcon)}
          loadingIndicatorSize={14}
          loading={showLoadingIndicator}
          disabled={disabled || showLoadingIndicator}
          refresh={refresh}
        />
      </div>
      <List
        className={classnames("dataSets", classes.dataSets)}
        disablePadding={true}
      >
        {showErrorView && (
          <ListItem className={classnames("errorListItem", classes.errorListItem)}>
            <ErrorView
              className={classnames("errorView", classes.errorView)}
              title={errorTitle}
              message={errorMessage}
              retryButtonLabel={errorViewRetryButtonLabel}
              retry={refresh}
            />
          </ListItem>
        )}
        {!showErrorView && (
          <React.Fragment>
            {showLoadingIndicator && (
              <ListItem className={classnames("loadingLabel", classes.loadingLabel)}>
                Loading...
              </ListItem>
            )}
            {!showLoadingIndicator && (
              <React.Fragment>
                {visibleItems.length === 0 && (
                  <ListItem className={classnames("emptyLabel", classes.emptyLabel)}>
                    No Data Sets Found
                  </ListItem>
                )}
                {visibleItems.length > 0 && visibleItems.map((dataSet, index) => (
                  <ListItem
                    key={`data-set-${index}`}
                    className={classnames("dataSet", classes.dataSet, {
                      [classes.selected]: dataSet.equals(selectedDataSet),
                    })}
                    button={true}
                    onClick={() => setSelectedDataSet(dataSet)}
                  >
                    <StorageIcon className={classnames("icon", classes.icon)} />
                    {dataSet.getDataSetAlias()}
                  </ListItem>
                ))}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </List>
      {children}
    </div>
  );
});

export default DataSetsView;
