import React from "react";
import classnames from "classnames";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";

export const SuccessView = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  title?: string,
  message?: string,
  children?: React.ReactNode,
}) => {

  const {
    classes,
    className,
    title = "Success!",
    message = "Device Type State Updated!",
    children,
  } = props;

  return (
    <div className={classnames("content", "success", className, classes.content)}>
      <label className={classnames("title", classes.title)}>
        {title}
      </label>
      <label className={classnames("message", classes.label)}>
        {message}
      </label>
      {children}
    </div>
  );
});

export default SuccessView;
