import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import * as History from "history";
import {
  getDeviceId,
  isLoadingIndicator,
  getSelectedTab,
  isNotFoundVisible,
  getErrorMessage,
  isAccessDeniedVisible
} from "../selectors";
import { refresh, setSelectedTab } from "../actions";
import { Device, DeviceTypeListItem, JsonSchemaMetadata } from "@data";
import { DeviceDetailsTabsType } from "../components/DeviceDetailsTabs";
import DeviceConfigurationView from "./DeviceConfigurationView";
import DeviceEnrollmentView from "./DeviceEnrollmentView";
import DeviceBootstrapSummaryViewContainer from "./DeviceBootstrapSummaryView";
import { AccessDeniedView, NotFoundView } from "@components";
import { noop } from "@util";
import { Redirect, Route } from "react-router-dom";
import {
  getPathToDeviceBootstrapView,
  getPathToDeviceConfigurationView,
  getPathToDeviceEnrollmentView,
  getPathToDevice,
} from "../helpers";

export interface ContainerModel {
  selectedTab?: DeviceDetailsTabsType;
  loading?: boolean;
  showNotFound?: boolean;
  showAccessDenied?: boolean;
  errorMessage?: string;
  deviceId?: string;
  configurationErrorMessage?: string;
  location?: History.Location | undefined;
}

interface ContainerActions {
  updateSelectedTab?: (selectedTab: DeviceDetailsTabsType) => void;
  showEnrollmentDetails?: (device: Device) => void;
  showDeviceTypeDetailsInNewTab?: (deviceType: DeviceTypeListItem) => void;
  showSchemaDetailsInNewTab?: (schema: JsonSchemaMetadata) => void;
  openDeviceEnrollmentWizard?: () => void;
  refresh?: () => void;
}

type Props = ContainerModel & ContainerActions;

const DeviceDetailsContainer = (props: Props) => {

  const {
    showNotFound,
    showAccessDenied,
    selectedTab = DeviceDetailsTabsType.ENROLLMENT,
    updateSelectedTab = noop,
    showEnrollmentDetails,
    showDeviceTypeDetailsInNewTab = noop,
    showSchemaDetailsInNewTab = noop,
    openDeviceEnrollmentWizard = noop,
    deviceId = "",
    location,
  } = props;

  React.useEffect(() => {
    updateSelectedTab(selectedTab);
  }, [updateSelectedTab, selectedTab]);

  if (showNotFound) {
    return (
      <NotFoundView
        subLabel={"Device Not Found"}
        description={"Try refreshing the page if the request was submitted a few seconds ago"}
      />
    );
  }

  if (showAccessDenied) { return <AccessDeniedView/>; }

  return (
    <div className={"detailsViewContainer"}>
        <Route
          key={"deviceEnrollmentView"}
          path={getPathToDeviceEnrollmentView(deviceId)}
        >
          <DeviceEnrollmentView
            showEnrollmentDetails={showEnrollmentDetails}
            openDeviceEnrollmentWizard={openDeviceEnrollmentWizard}
          />
        </Route>
        <Route
          key={"deviceConfigView"}
          path={getPathToDeviceConfigurationView(deviceId)}
        >
          <DeviceConfigurationView
            showDeviceTypeDetailsInNewTab={showDeviceTypeDetailsInNewTab}
            showSchemaDetailsInNewTab={showSchemaDetailsInNewTab}
          />
        </Route>
        <Route
          key={"deviceBootstrapView"}
          path={getPathToDeviceBootstrapView(deviceId)}
        >
          <DeviceBootstrapSummaryViewContainer />
        </Route>
        <Route path={getPathToDevice(deviceId)} exact={true}>
          <Redirect
            to={{
              pathname: getPathToDeviceEnrollmentView(deviceId),
              state: location ? location.state : undefined
            }}
          />
        </Route>
    </div>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  errorMessage: getErrorMessage(state),
  loading: isLoadingIndicator(state),
  deviceId: getDeviceId(state),
  selectedTab: getSelectedTab(state),
  showNotFound: isNotFoundVisible(state),
  showAccessDenied: isAccessDeniedVisible(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  refresh: () => dispatch(refresh()),
  updateSelectedTab: selectedTab => dispatch(setSelectedTab(selectedTab)),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceDetailsContainer);
