import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";

export interface DisenrollDeviceSchema extends BasePortalModuleSchema {
  open: boolean;
  deviceId: string;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<DisenrollDeviceSchema>({
  ...BASE_DEFAULT_STATE,
  showEmptyView: false,
  open: false,
  deviceId: "",
});

export type DisenrollDeviceActionTypes = PortalModuleActionTypes<DisenrollDeviceSchema> & {
  DISENROLL_DEVICE_REQUEST: string;
  DISENROLL_DEVICE_SUCCESS: string;
  DISENROLL_DEVICE_FAILED: string;
};

export const ACTION_TYPES: DisenrollDeviceActionTypes = {
  ...createPortalModuleActionTypes<DisenrollDeviceSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    DISENROLL_DEVICE_REQUEST: `${MODULE_ID}/disenroll_device_request`,
    DISENROLL_DEVICE_SUCCESS: `${MODULE_ID}/disenroll_device_success`,
    DISENROLL_DEVICE_FAILED: `${MODULE_ID}/disenroll_device_failed`,
  },
};

export const DisenrollDeviceReducer = combineReducers<DisenrollDeviceSchema>(
  createPortalModuleReducers<DisenrollDeviceSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DisenrollDeviceReducer;
