import { Record } from "immutable";
import { Policy, PolicyAttributes } from "@data/Policy";
import { ReferenceAttributes } from "@data/./ManagedPolicy";
import { equalsIgnoreCase, getStringValue } from "@util";

const ACCOUNT_ID_REGEX = new RegExp(/lrn.*[:]([0-9]+)/);

export interface ManagedPolicyInfoAttributes {
  name: string;
  domain: string;
  description: string;
  reference: ReferenceAttributes;
  policy: PolicyAttributes;
}

export class ManagedPolicyInfo extends Record({
  name: "",
  domain: "",
  description: "",
  reference: {
    policyRef: "",
  },
  policy: Policy.EMPTY.toJS(),
}) implements ManagedPolicyInfoAttributes {

  public static EMPTY: ManagedPolicyInfo = new ManagedPolicyInfo();

  public readonly policy: PolicyAttributes;
  public readonly reference: ReferenceAttributes;
  public readonly name: string;
  public readonly domain: string;
  public readonly description: string;

  public equals(other: ManagedPolicyInfo): boolean {
    return equalsIgnoreCase(JSON.stringify(this.toJS()), JSON.stringify(other.toJS()));
  }

  public getPolicyName(): string {
    return getStringValue(this.name);
  }

  public getDomain(): string {
    return getStringValue(this.domain);
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public getReference(): ReferenceAttributes {
    return this.reference;
  }

  public getPolicyRef(): string {
    return getStringValue(this.getReference().policyRef);
  }

  public getAccountId(): string {
    const policyRef = this.getPolicyRef();

    const match = policyRef.match(ACCOUNT_ID_REGEX);

    if (!Array.isArray(match) || match.length === 0) {
      return "";
    } else {
      return getStringValue(match.pop()); // we want the last match
    }
  }

  public getPolicy(): Policy {
    return new Policy(this.policy);
  }
}
