import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { close, publishDashboard } from "../actions";
import {
  isDialogOpen,
  isPublishable,
  isLoadingIndicatorVisible,
  isAccessDeniedVisible,
  getErrorMessage,
  getSuccessMessage,
} from "../selectors";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import PublishDashboard from "../components/PublishDashboard";
import CannotPublishDashboard from "../components/CannotPublishDashboard";

interface Model extends PortalModuleDialogModel<string> {
  dashboardId?: number;
  publishable?: boolean;
}

interface Actions extends PortalModuleDialogActions<string> {
}

type Props = Model & Actions;

const PublishDashboardDialog = (props: Props) => {
  if (props.publishable) {
    return (
        <PortalModuleDialog {...props}>
          <PublishDashboard/>
        </PortalModuleDialog>
    );
  } else {
    return (
        <PortalModuleDialog {...props}>
          <CannotPublishDashboard />
        </PortalModuleDialog>
    );
  }
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  className: "publishDashboardDialog",
  title: isPublishable(state) ? "Publish Dashboard" : "Temporary Failure",
  open: isDialogOpen(state),
  loading: isLoadingIndicatorVisible(state),
  accessDenied: isAccessDeniedVisible(state),
  errorMessage: getErrorMessage(state),
  successMessage: getSuccessMessage(state),
  continueButtonLabel: "Confirm",
  publishable: isPublishable(state),
  continueButtonDisabled: !isPublishable(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  cancel: () => dispatch(close()),
  confirm: () => dispatch(publishDashboard()),
  ...ownProps
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(PublishDashboardDialog);
