import React, { RefObject } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { getAuthToken } from "@main/selectors";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { module } from "./styles";
import { SCHEMA_KEY as DASHBOARDS_SCHEMA_KEY } from "@modules/dashboards/constants";
import { SCHEMA_KEY as DASHBOARDS_PUBLISHING_SCHEMA_KEY } from "@modules/publishDashboard/constants";
import { DashboardsActionType } from "@modules/dashboards/reducers";
import { SupersetLoadedContent } from "@modules/portlet/actions";
import { getStringValue } from "@util";

export interface PortletAppSchema {
  className?: string;
  accessToken?: string;
  requestedDashboardsTimestamp?: number;
  publishedDashboardId?: number;
  metadata?: SupersetLoadedContent;
}

export interface PortletProps {
  url: string;
  tokenAttribute: string;
}

const Portlet = withStyles(module)((props: WithStyles<typeof module> & PortletAppSchema & PortletProps) => {

  const {
    classes,
    className,
    url,
    tokenAttribute,
    accessToken,
    requestedDashboardsTimestamp,
    publishedDashboardId,
    metadata
  } = props;

  interface FrameContainer {
    iframeRef?: RefObject<HTMLIFrameElement>;
    div?: any;
  }

  interface MessageContainer {
    requestedDashboardsTimestamp?: number;
    publishedDashboardId?: number;
    deliveredAccessToken?: string;
  }

  const [messageContainer, setMessageContainer] = React.useState<MessageContainer>({
    requestedDashboardsTimestamp: -1,
    publishedDashboardId: -1,
    deliveredAccessToken: ""
  });

  const [iframeContainer, setIFrames] = React.useState<FrameContainer>({
  });

  const [loadedContentMeta, setLoadedContentMeta] = React.useState<SupersetLoadedContent>({url, time: Date.now()});
  const [channelReady, setChannelReady] = React.useState<Boolean>(false);
  const [pendingMessages, setPendingMessages] = React.useState<string[]>([]);

  const { search } = useLocation();

  const queryParams = new URLSearchParams(getStringValue(search));

  function newFrame(iframeUrl: string) {
    const name = "" + Date.now();
    const iframeRef = React.createRef<HTMLIFrameElement>();
    const div = (
        <div className={classnames(className, classes.container)}>
          <iframe
              name={name}
              ref={iframeRef}
              className={classnames(className, classes.container)}
              onLoad={(e) => {
                try {
                  // @ts-ignore
                  if (e.target.contentWindow.origin === document.location.origin) {
                    // have not loaded yet
                    return;
                  }
                } catch (e) {
                  if (e.name === "SecurityError") {
                    // error is expected, you can't view across origins
                    setChannelReady(true);
                  } else {
                    throw e;
                  }
                }
              }}
          />
          <form style={{display: "none"}} action={iframeUrl} target={name} id={name}>
            <input type="text" name={tokenAttribute} value={accessToken} readOnly={true} />
            <input type="text" name="view" value={queryParams.get("resourceId") || ""} readOnly={true} />
          </form>
        </div>
    );
    setTimeout(() => document.forms[name]?.submit());
    setIFrames({
      iframeRef,
      div,
    });
    setLoadedContentMeta({url: iframeUrl, time: Date.now()});
    setPendingMessages(pendingMessages);
    return div;
  }

  if (!iframeContainer?.div) {
    return newFrame(url);
  }

  let reloadUrl = metadata?.url;
  const refreshTime = metadata?.time;
  const appLoadedTime = loadedContentMeta.time;
  if (refreshTime && reloadUrl && appLoadedTime < refreshTime) {
    return newFrame(reloadUrl);
  }

  function maybeSendMessage(message: string) {
    if (channelReady) {
      iframeContainer.iframeRef?.current?.contentWindow?.postMessage(message, new URL(url).origin);
    } else {
      if (pendingMessages.indexOf(message) > -1) {
        return;
      }
      pendingMessages.push(message);
      setPendingMessages(pendingMessages);
    }
  }

  function basic_check(param: number|undefined) {
    return param && param > -1;
  }

  let updateMessageContainer = false;

  if (accessToken && accessToken !== messageContainer.deliveredAccessToken) {
    maybeSendMessage("signify:access_token=" + accessToken);
    updateMessageContainer = true;
  }

  if (basic_check(requestedDashboardsTimestamp) &&
      requestedDashboardsTimestamp !== messageContainer.requestedDashboardsTimestamp) {
    maybeSendMessage("signify:" + DashboardsActionType.FETCH_ALL_DASHBOARDS_REQUEST);
    updateMessageContainer = true;
  }

  if (basic_check(publishedDashboardId) && publishedDashboardId !== messageContainer.publishedDashboardId) {
    maybeSendMessage("signify:published_dashboard:" + publishedDashboardId);
    updateMessageContainer = true;
  }

  if (updateMessageContainer) {
    setMessageContainer({
      publishedDashboardId,
      requestedDashboardsTimestamp,
      deliveredAccessToken: accessToken
    });
  }

  if (process.env.NODE_ENV !== "test") {
    console.debug("pending_messages", pendingMessages);
  }

  if (channelReady && pendingMessages.length) {
    pendingMessages.forEach(function(message: string) {
      maybeSendMessage(message);
    });
    setPendingMessages([]);
    if (process.env.NODE_ENV !== "test") {
      console.debug("messages sent");
    }
  }

  return iframeContainer.div;
});

export default connect<PortletAppSchema, {}, PortletAppSchema>(
    (state: AppSchema, { className }): PortletAppSchema => ({
      className,
      accessToken: getAuthToken(state),
      requestedDashboardsTimestamp: state[DASHBOARDS_SCHEMA_KEY].itemsRequestedTime,
      publishedDashboardId: state[DASHBOARDS_PUBLISHING_SCHEMA_KEY].publishedDashboardId,
      metadata: state.supersetLoadedContent
    }),
)((props: PortletAppSchema & PortletProps) => {
  if (props.accessToken?.split(".").length === 3) {
    return (
        <Portlet {...props} />
    );
  } else {
    return null;
  }
});
