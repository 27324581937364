import React from "react";
import classnames from "classnames";
import { formEventHandler, noop } from "@util";
import { workloadInfo as styles } from "./styles";
import { TagEditor, TextField } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { CreateWorkloadRequest, CreateWorkloadRequestAttributes } from "@data/CreateWorkloadRequest";

export interface Model {
  data?: CreateWorkloadRequest;
  showLoadingIndicator?: boolean;
}

export interface Actions {
  setWorkloadData?: (data: Partial<CreateWorkloadRequestAttributes>) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const InfoView = withStyles(styles)((props: Props) => {

  const {
    classes,
    showLoadingIndicator = false,
    data = CreateWorkloadRequest.EMPTY,
    setWorkloadData = noop,
  } = props;

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  return (
    <div className={classnames("workloadInfo", classes.container)}>
      <label className={classes.title}>Set Workload Information</label>
      <TextField
        className={classnames("name", classes.marginBottom, classes.textField)}
        type="text"
        variant="outlined"
        autoComplete="off"
        margin="none"
        fullWidth={true}
        autoFocus={true}
        required={true}
        label="Workload Name"
        name="workloadName"
        value={data.getName()}
        disabled={showLoadingIndicator}
        placeholder="Workload Name"
        helperText={"Workload name cannot include spaces or uppercase letters"}
        onChange={formEventHandler((name: string) => setWorkloadData({ name: name.toLowerCase() }))}
        InputLabelProps={inputLabelProps}
        disableSpecialChars={true}
      />
      <TextField
        className={classnames("description", classes.marginBottom, classes.textField)}
        type="text"
        variant="outlined"
        autoComplete="off"
        margin="none"
        fullWidth={true}
        multiline={true}
        minRows={3}
        label="Workload Description"
        name="workloadDescription"
        value={data.description}
        disabled={showLoadingIndicator}
        placeholder="Workload Description"
        onChange={formEventHandler((description: string) => setWorkloadData({ description }))}
        InputLabelProps={inputLabelProps}
      />
      <TagEditor
        tags={data.tags}
        helperText="Tags to associate with this workload"
        setTags={(tags) => setWorkloadData({ tags })}
      />
    </div>
  );
});

export default InfoView;
