import createStyles from "@material-ui/core/styles/createStyles";
import { UsersIcon as ModuleIcon } from "@icons";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const listItem = () =>
  createStyles({
    container: {
      color: primaryColor,
      marginTop: 32,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    controls: {
      flex: "1 0 auto",
      display: "inline-flex",
      justifyContent: "flex-end",
    },
    search: {
      marginTop: 20,
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: 0,
    },
    listContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "center",
      "&:first-child": {
        marginLeft: 4,
      },
    },
    label: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "16px",
      wordBreak: "break-word",
      marginRight: 8,
    },
  });

export const summaryView = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 25,
    },
    summaryViewLabel: {
      "& label.name": {
        alignSelf: "center",
        minWidth: 250,
      },
    },
    content: {
      marginTop: 16,
      color: primaryColor,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      "& .profileView, & .securityGroups, & .policies": {
        marginTop: 16,
      },
    },
  });

export const authenticationSummaryView = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 25,
    },
    errorMessageContainer: {
      marginBottom: 20,
    },
    summaryViewContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      position: "relative",
      marginTop: 15,
    },
    labelContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      maxWidth: 250,
    },
    failedLoginLabel: {
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      fontSize: 16,
      minWidth: 100,
      alignSelf: "flex-start",
    },
    failedLoginInfo: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 14,
      minWidth: 100,
      marginTop: 5,
      alignSelf: "flex-start",
    },
    failedLoginValue: {
      backgroundColor: "#eaeaea",
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "monospace",
      fontSize: 12,
      marginLeft: 15,
      fontWeight: 300,
      padding: 10,
    },
    summaryViewLabel: {
      "& label.name": {
        alignSelf: "center",
        minWidth: 250,
      },
    },
    content: {
      marginTop: 16,
      color: primaryColor,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      "& .profileView, & .securityGroups, & .policies": {
        marginTop: 16,
      },
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "32px",
      marginTop: 8,
    },
  });

export const userDetails = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    errorMessageContainer: {
      marginBottom: 35,
    },
    header: {
      minHeight: 50,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      position: "relative",
      color: primaryColor,
    },
    icon: {
      width: 40,
      height: 40,
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: "0 0 0 8px",
    },
    actionsMenuIcon: {
      marginLeft: 8,
    },
    actionsMenu: {
      justifySelf: "flex-end",
      marginLeft: "auto",
    },
    controls: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      marginTop: 15,
    },
    viewModeButton: {
      marginLeft: 15,
      "&:first-child": {
        marginLeft: 0,
      },
    },
  });

export const deleteDialog = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 16,
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
    templateId: {
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "16px",
      marginTop: 16,
    },
  });

export const multiFactorAuthView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginBottom: 30,
    },
    formControl: {
      marginTop: 20,
      minWidth: 250,
      width: "fit-content",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "32px",
      marginTop: 8,
    },
    subtitle: {
      color: Color.TEXT,
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 16,
    },
  });
