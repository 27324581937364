import { isEmptyString } from "@util";
import { makeJsonApiRequest, withAuthToken, withRequiredArguments } from "./helpers";

const QUERY_DATABASE_API = process.env.REACT_APP_QUERY_DATABASE_API || "";

if (isEmptyString(QUERY_DATABASE_API)) {
  throw new Error("Missing Environment Variable: QUERY_DATABASE_API");
}

export interface GetQueryDatabasesResponse {
  databaseName: string;
}

export interface QueryTableColumns {
  name: string;
  type: string;
}

export interface QueryTableSchema {
  columns: QueryTableColumns[];
}

export interface GetQueryDatabasesTables {
  name: string;
  databaseName: string;
  schema: QueryTableSchema;
}

export interface GetQueryDatabasesTableResponse {
  name: string;
  tables: GetQueryDatabasesTables[];
}

export const getQueryDatabases = (authToken: string): Promise<GetQueryDatabasesResponse[]> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${QUERY_DATABASE_API}/bdi/dmg/query-service/v1/databases/list`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      }
    };

    const defaultErrorMessage = "Fetch query databases failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getQueryDatabasesTables = (authToken: string,
                                       databaseName: string): Promise<GetQueryDatabasesTableResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Database Name", databaseName],
    ]));

  const makeRequest = () => {

    const url = `${QUERY_DATABASE_API}/bdi/dmg/query-service/v1/databases/${databaseName}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      }
    };

    const defaultErrorMessage = "Fetch query database tables failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
