import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { createSelector } from "reselect";
import { DEFAULT_STATE, ManagedPolicyDetailsSchema, } from "./reducers";
import { createSelectors, Selector } from "@modules/base/createSelectors";
import { ManagedPolicyInfo, ManagedPolicyInfoAttributes, PolicyStatement, SummaryViewData } from "@data";
import capitalize from "lodash/capitalize";

export const {
  policyName: getPolicyName,
  accountId: getAccountId,
  managedPolicy: getManagedPolicyAttributes,
  nameFilter: getNameFilter,
  sortByColumn: getSortByColumn,
  sortOrderAscending: isSortOrderAscending,
} = createSelectors<ManagedPolicyDetailsSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getManagedPolicy: Selector<ManagedPolicyInfo> = createSelector(
  getManagedPolicyAttributes, (attrs: ManagedPolicyInfoAttributes) =>
    new ManagedPolicyInfo(attrs));

export const getJson: Selector<string> = createSelector(
  getManagedPolicy, (policy: ManagedPolicyInfo) =>
    JSON.stringify(policy.toJS(), null, "  "));

export const getManagedPolicyName: Selector<string> = createSelector(
  getManagedPolicy, (policy: ManagedPolicyInfo) => policy.getPolicyName());

export const getDomain: Selector<string> = createSelector(
  getManagedPolicy, (policy: ManagedPolicyInfo) => policy.getDomain());

export const getReference: Selector<string> = createSelector(
  getManagedPolicy, (policy: ManagedPolicyInfo) => policy.getPolicyRef());

export const getManagedPolicyDescription: Selector<string> = createSelector(
  getManagedPolicy, (policy: ManagedPolicyInfo) => policy.getDescription());

export const getManagedPolicyVersion: Selector<string> = createSelector(
  getManagedPolicy, (policy: ManagedPolicyInfo) => policy.getPolicy().getVersion());

export const getManagedPolicyPath: Selector<string> = createSelector(
  getManagedPolicy, (policy: ManagedPolicyInfo) => policy.getPolicy().getPath());

export const getStatements: Selector<PolicyStatement[]> = createSelector(
  getManagedPolicy, (policy: ManagedPolicyInfo) => policy.getPolicy().getStatements());

export const getSummaryViewItems: Selector<SummaryViewData[]> = createSelector(
  [
    getManagedPolicyName,
    getDomain,
    getManagedPolicyDescription,
    getManagedPolicyVersion,
    getManagedPolicyPath,
    getReference,
  ],
  (
    name: string,
    domain: string,
    description: string,
    version: string,
    path: string,
    policyRef: string
  ) => ([
    new SummaryViewData({
      className: "name",
      name: "Name",
      value: name,
    }),
    new SummaryViewData({
      className: "domain",
      name: "Domain",
      value: capitalize(domain.toLowerCase()),
    }),
    new SummaryViewData({
      className: "description",
      name: "Description",
      value: description,
    }),
    new SummaryViewData({
      className: "version",
      name: "Version",
      value: version,
    }),
    new SummaryViewData({
      className: "path",
      name: "Path",
      value: path,
    }),
    new SummaryViewData({
      className: "reference",
      name: "Reference",
      value: policyRef,
    }),
  ]));
