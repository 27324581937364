import React from "react";
import { Location } from "history";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getPathToPolicy } from "@modules/policyDetails/helpers";
import EditPolicy from "./containers/EditPolicy";

type LocationProps = {
  from?: Location;
  goBackOnSuccess?: boolean;
};

export function EditPolicyView() {

  const history = useHistory();

  const { id: policyName } = useParams<{ id: string}>();

  const { state: { from = undefined, goBackOnSuccess = false} = {} } = useLocation<LocationProps>();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onSuccess = React.useCallback(() => {
    if (goBackOnSuccess) {
      history.goBack();
    } else if (from) {
      history.replace(from);
    } else {
      history.replace(getPathToPolicy(policyName));
    }
  }, [history, from, goBackOnSuccess, policyName]);

  return (
    <EditPolicy
      key={policyName}
      cancel={cancel}
      onSuccess={onSuccess}
    />
  );
}

export default EditPolicyView;
