import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      "&$addBottomPadding": {
        paddingBottom: 12,
      },
    },
    title: {
      marginTop: 10,
      flex: "0 0 auto",
      color: Color.TEXT,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
    },
    coverage: {
      marginTop: 12,
      fontSize: 48,
      fontWeight: 300,
      lineHeight: "1",
      "&$goodCoverage": {
        color: Color.SIGNIFY_GREEN3,
      },
    },
    stats: {
      marginTop: 15,
      marginBottom: 15,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    icon: {
      width: 24,
      height: 24,
    },
    trendingUp: {
      color: Color.SIGNIFY_GREEN3,
    },
    trendingFlat: {
      color: Color.GREY4,
    },
    trendingDown: {
      color: Color.RED,
    },
    deltaLabel: {
      marginLeft: 10,
      fontWeight: 400,
    },
    noTrendLabel: {
      fontSize: 10,
      fontWeight: 300,
      textAlign: "center",
    },
    addBottomPadding: {
    },
    goodCoverage: {
    },
  });

export default styles;
