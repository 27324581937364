import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "center",
    },
    header: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    title: {
      flex: "0 0 auto",
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
    },
    deleteButton: {
      marginLeft: 10,
      width: 26,
      height: 26,
    },
    deleteIcon: {
      color: Color.BLACK,
      width: 14,
      height: 14,
    },
    content: {
      flex: "1 0 auto",
      marginTop: 20,
    },
    emptyView: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      padding: 8,
      border: `1px dashed ${Color.GREY11}`,
      borderRadius: 8,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: Color.LIGHT_GREY6,
      },
    },
    emptyViewLabel: {
      color: Color.GREY7,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
    },
    jsonView: {
      "& .jsonEditor": {
        flex: "0 0 175px",
        minHeight: "auto",
        maxHeight: 175,
        overflow: "hidden",
      },
    },
    underlined: {
      textDecoration: "underline",
    },
    removePolicyDialog: {
    },
    removePolicy: {
    },
    workloadPolicyEditorDialog: {
    },
    workloadPolicyEditor: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
    },
    workloadPolicyRequiredFields: {
      flex: "0 0 275px",
      display: "flex",
      flexFlow: "column nowrap",
      marginRight: 20,
    },
    workloadPolicyRequiredFieldsTitle: {
      margin: 0,
      padding: 0,
      fontSize: 21,
      fontWeight: 400,
      lineHeight: "24px",
    },
    workloadPolicyRequiredField: {
      marginTop: 15,
    },
    workloadPolicyJsonEditor: {
      flex: "1 0 auto",
    },
  });

export default styles;
