import { Record } from "immutable";
import { getStringValue } from "@util";

export interface TableHeaderRowDataAttributes {
  className: string;
  label: string;
}

export class TableHeaderRowData extends Record({
  className: "",
  label: "",
}) implements TableHeaderRowDataAttributes {

  public static EMPTY: TableHeaderRowData = new TableHeaderRowData();

  public readonly label: string;
  public readonly className: string;

  public getLabel(): string {
    return getStringValue(this.label);
  }

  public getClassName(): string {
    return getStringValue(this.className);
  }

}

export default TableHeaderRowData;
