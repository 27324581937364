import { AppSchema } from "@main/schemas";
import { getAuthToken } from "@main/selectors";
import { createActions } from "@modules/base/createActions";
import { RestClientError, FederationProviderClient } from "@network";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { getProviderId } from "./selectors";
import { isEmptyString } from "@util/Functions";

export const {
  providerId: setProviderId,
  open: setOpen,
  setErrorMessage,
  setSuccessMessage,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  DELETE_FEDERATION_PROVIDER_REQUEST: deleteFederationProviderRequest,
  DELETE_FEDERATION_PROVIDER_SUCCESS: deleteFederationProviderSuccess,
  DELETE_FEDERATION_PROVIDER_FAILED: deleteFederationProviderFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const deleteFederationProvider = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const providerId = getProviderId(state);
  const authToken = getAuthToken(state);

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(deleteFederationProviderRequest());

  return FederationProviderClient.deleteFederationProvider(authToken, providerId)
    .then(() => {

      dispatch(deleteFederationProviderSuccess());
      dispatch(hideLoadingIndicator());
      return dispatch(setSuccessMessage("Federation Provider Deleted"));

    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to delete federation provider" } = response;

      dispatch(deleteFederationProviderFailed(analytic));
      dispatch(hideLoadingIndicator());

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(setErrorMessage(error));
    });
};

export const reset = () => (dispatch: any) => {
  dispatch(setProviderId());
  dispatch(setOpen());
  return dispatch(baseReset());
};

export const open = (providerId: string) => (dispatch: any) => {

  if (isEmptyString(providerId)) {
    return Promise.resolve();
  }

  dispatch(reset());
  dispatch(setProviderId(providerId));
  return dispatch(setOpen(true));
};

export const close = reset;

export const initialize = reset;
