import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { getDataMode, getDeviceConfigurationAsJson, getDeviceId } from "../selectors";
import { DeviceConfigurationDataMode } from "@modules/deviceDetails/reducers";
import { updateDataMode } from "@modules/deviceDetails/actions";
import { DeviceConfigurationJsonView, Model, Actions } from "../components/DeviceConfigurationJsonView";

const mapStateToProps = (state: AppSchema): Model => ({
  json: getDeviceConfigurationAsJson(state),
  name: "deviceConfigurationJsonView",
  fileName: `${getDeviceId(state)}.json`,
  height: "500px",
  className: "deviceConfigurationJsonView",
  dataMode: getDataMode(state),
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setDataMode: (dataMode: DeviceConfigurationDataMode) => dispatch(updateDataMode(dataMode)),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceConfigurationJsonView);
