import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createTheme";
import ModuleIcon from "@material-ui/icons/Smartphone";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const deviceEnrollment = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      minHeight: "50vh",
    },
    controls: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      marginTop: 16,
    },
    summaryButton: {
      marginLeft: 0,
    },
    jsonButton: {
      marginLeft: 15,
    },
    reEnrollDeviceButton: {
      justifySelf: "flex-end",
      padding: "6px 16px",
      marginLeft: "auto",
    },
    disenrollDeviceButton: {
      justifySelf: "flex-end",
      padding: "6px 16px",
      marginLeft: "auto",
    },
    dataContainer: {
    },
    errorMessageContainer: {
      marginBottom: 35,
    },
    formControl: {
      marginTop: 40,
      minWidth: 250,
      width: "fit-content",
    },
  });

export const deviceBootstrap = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 22,
      lineHeight: "30px",
      marginTop: 10,
    },
    alert: {
      marginTop: 25,
    },
    alertTitle: {
      fontSize: 14,
      marginTop: 2,
    },
    alertDescription: {
      fontSize: 14,
      lineHeight: "21px",
      marginTop: 10,
      marginBottom: 0,
      paddingLeft: 20,
    },
    underlined: {
      marginLeft: 4,
      marginRight: 4,
      textDecoration: "underline",
    },
  });

export const deviceType = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 25,
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      minWidth: 100,
      alignSelf: "flex-start",
      fontSize: 24,
      lineHeight: "28px",
    },
  });

export const deviceData = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 25,
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      minWidth: 100,
      alignSelf: "flex-start",
      fontSize: 24,
      lineHeight: "28px",
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 18,
      lineHeight: "21px",
      marginTop: 8,
    },
    information: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 250,
      fontSize: 16,
      lineHeight: "21px",
      marginTop: 25,
    },
    infoIcon: {
      width: "auto !important",
      height: "auto !important",
      padding: 12,
    },
    controls: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      marginTop: 16,
    },
    actualButton: {
      marginLeft: 0,
    },
    desiredButton: {
      marginLeft: 15,
    },
    alert: {
      marginTop: 25,
    },
    alertTitle: {
      fontSize: 14,
      marginTop: 2,
    },
    alertDescription: {
      fontSize: 14,
      lineHeight: "21px",
      marginTop: 10,
      marginBottom: 0,
      paddingLeft: 20,
    },
  });

export const deviceDetails = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    errorMessageContainer: {
      marginBottom: 35,
    },
    header: {
      minHeight: 50,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      position: "relative",
      color: primaryColor,
    },
    icon: {
      width: 40,
      height: 40,
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: "0 0 0 8px",
    },
  });

export const batches = () =>
  createStyles({
    container: {
      color: primaryColor,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    controls: {
      flex: "1 0 auto",
      display: "inline-flex",
      justifyContent: "flex-end",
    },
    header: {
      minHeight: 50,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      position: "relative",
      color: primaryColor,
    },
    search: {
      marginTop: 15,
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: 0,
    },
  });

export const listItem = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "center",
      "&:first-child": {
        marginLeft: 4,
      },
    },
    label: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "16px",
      wordBreak: "break-word",
      marginRight: 8,
    },
    icon: {
      color: primaryColor,
      alignSelf: "center",
      width: 16,
      height: 16,
      marginTop: 0,
      marginRight: 10,
    },
  });

export const deleteDialog = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 16,
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
    templateId: {
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "16px",
      marginTop: 16,
    },
  });

export const dataEditor = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      position: "relative",
    },
    dataJsonEditor: {
      marginTop: 0,
    },
    header: {
      "& > .icon": {
        minWidth: 20,
      },
    },
    controls: {
      display: "inline-flex",
      justifyContent: "flex-end",
      height: 50,
    },
    editButton: {
      marginLeft: "auto",
      padding: "6px 16px",
    },
    deleteButton: {
      marginLeft: 15,
      padding: "6px 16px",
    },
    cancelButton: {
      marginLeft: "auto",
      padding: "6px 16px",
    },
    editConfirmButton: {
      marginLeft: 15,
      padding: "6px 16px",
    },
    editMode: {
      zIndex: 1201,
      backgroundColor: Color.WHITE,
      padding: "20px 10px",
      margin: "-10px",
    },
    editModeModal: {
      background: "rgba(105, 105, 105, 0.25)",
      position: "fixed",
      zIndex: 1200,
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
  });

export const schemaData = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      marginTop: 25,
      height: 300,
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      minWidth: 100,
      alignSelf: "flex-start",
      fontSize: 24,
      lineHeight: "28px",
    },
    schemas: {
      flex: "0 0 50%",
    },
    data: {
      flex: "0 0 50%",
      paddingLeft: 16,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    radioGroup: {
    },
    radio: {
    },
  });

export const configSummary = (theme: Theme) =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    errorMessageContainer: {
      marginBottom: 10,
    },
    backdrop: {
      background: "rgba(105, 105, 105, 0.25)",
      zIndex: theme.zIndex.modal,
    },
    progressIndicator: {
      color: "#1432ff",
      position: "absolute",
      top: "50%",
      left: "50%",
      zIndex: 1,
    },
  });

export const bootstrapSummary = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      "& label.name": {
        minWidth: 200,
      },
    },
    errorMessageContainer: {
      marginBottom: 10,
    },
    credentials: {
      display: "flex",
      flexFlow: "row nowrap",
    },
    name: {
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      fontSize: 16,
      alignSelf: "flex-start",
      paddingTop: 15,
    },
    textField: {
      fontSize: 20,
      width: "60%",
      paddingLeft: 15,
      "& input": {
        fontSize: 15,
      },
    },
    alert: {
      marginTop: 20,
      marginBottom: 20,
    },
    alertTitle: {
      fontSize: 14,
      marginTop: 2,
    },
    alertDescription: {
      fontSize: 14,
      lineHeight: "21px",
      marginTop: 10,
      marginBottom: 0,
      paddingLeft: 20,
    },
    underlined: {
      marginLeft: 4,
      marginRight: 4,
      textDecoration: "underline",
    },
  });
