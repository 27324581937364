export const DEFAULT_ERROR_MESSAGE = "An unexpected error was encountered. Please try again.";

interface ApiErrorResponse {
  status?: number | string;
  message?: string;
  description?: string;
  errors?: string[];
}

export interface RestClientError {
  status: number | string;
  message: string;
  description: string;
  error: string;
  analytic: string;
  errors?: string[];
}

export const withRestClientErrorHandling =
  (defaultErrorMessage: string = DEFAULT_ERROR_MESSAGE) => (response: Response) => {

    if (response.ok) {
      return Promise.resolve(response);
    }

    const statusCode = response.status;
    const textStatus = response.statusText;

    return response.json()
      .catch((error: Error) => ({
        status: statusCode,
        message: error && error.message ? error.message : defaultErrorMessage,
        description: defaultErrorMessage,
      }))
      .then((errorResponse: ApiErrorResponse) => {

        const { status, message, description, errors } = errorResponse;

        const errorsList = Array.isArray(errors) ? errors : [];

        const baseError = {
          status: status || statusCode,
          message: message || textStatus,
          description: description || defaultErrorMessage,
          error: description || message || defaultErrorMessage,
          errors: errorsList,
        };

        return Promise.reject({
          ...baseError,
          analytic: `${baseError.status}:${baseError.message}` +
            // This should help figure out through our analytics which api's support the detailed errors response
            (errorsList.length === 0 ? "" : ":errors-included"),
        });
      });
  };

export default RestClientError;
