import React from "react";
import { Application } from "@data";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { isEmptyString, noop } from "@util";
import { updateApplicationId } from "../actions";
import { getApplicationId, isApplicationViewActive } from "../selectors";
import useGetApplications from "@hooks/applicationManagerRegional/use-get-applications-regional";
import SelectApplication, { Actions, Model } from "../components/SelectApplication";

interface ContainerModel extends Model {
  hidden?: boolean;
  applicationId?: string;
}

interface ContainerActions extends Actions {
  setApplicationId?: (applicationId: string) => void;
}

type Props = ContainerModel & ContainerActions;

const SelectApplicationContainer = (props: Props) => {

  const {
    hidden,
    applicationId = "",
    setApplicationId = noop,
    ...otherProps
  } = props;

  const [{ applications, ...model }, actions] = useGetApplications();

  const selectedItems = React.useMemo(() => {
    if (isEmptyString(applicationId)) {
      return [];
    }
    const selectedApplication = applications.find(item => item.getId() === applicationId);
    return selectedApplication ? [selectedApplication] : [new Application({ id: applicationId })];
  }, [applicationId, applications]);

  const items = React.useMemo(() =>
      selectedItems.length > 0 ? selectedItems : applications,
    [selectedItems, applications]);

  const setSelectedApplication = React.useCallback(
    (application: Application = Application.EMPTY) =>
      setApplicationId(application.getId()), [setApplicationId]);

  if (hidden) {
    return null;
  }

  return (
    <SelectApplication
      {...otherProps}
      {...model}
      {...actions}
      items={items}
      selectedItems={selectedItems}
      setSelectedApplication={setSelectedApplication}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  hidden: !isApplicationViewActive(state),
  applicationId: getApplicationId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setApplicationId: (applicationId: string) => dispatch(updateApplicationId(applicationId)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps
)(SelectApplicationContainer);
