import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { createActions } from "@base";

export const {
  applicationId: setApplicationId,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setApplicationId());
  return dispatch(baseReset());
};

export const initialize = (applicationId: string) => (dispatch: any) => {

  dispatch(reset());
  return dispatch(setApplicationId(applicationId));
};
