import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import SingleDateFileBrowser from "./SingleDateFileBrowser";
import CustomSearchFileBrowser from "./CustomSearchFileBrowser";
import DataLakeFileBrowserHeader from "./DataLakeFileBrowserHeader";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import { DataLakeIndex } from "@data";

export interface DataLakeFileBrowserModel {
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  listClassName?: string;
  dataSetAlias?: string;
  accountId?: string;
  breadcrumbs?: string[];
  startDate?: Date | null;
  endDate?: Date | null;
  fileNameFilter?: string;
  customActionLabel?: string;
}

export interface DataLakeFileBrowserActions {
  onChangeBreadcrumbs?: (breadcrumbs: string[]) => void;
  onChangeStartDate?: (startDate: Date | null) => void;
  onChangeEndDate?: (endDate: Date | null) => void;
  onChangeFileNameFilter?: (fileNameFilter: string) => void;
  customAction?: (file: string) => void;
  setDataLakeIndex?: (file: DataLakeIndex) => void;
}

type Model = DataLakeFileBrowserModel;
type Actions = DataLakeFileBrowserActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataLakeFileBrowser = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    headerClassName,
    contentClassName,
    listClassName,
    dataSetAlias = "",
    accountId = "",
    breadcrumbs: initialBreadcrumbs = [dataSetAlias],
    startDate: initialStartDate = null,
    endDate: initialEndDate = null,
    fileNameFilter: initialFileNameFilter = "",
    onChangeBreadcrumbs = noop,
    onChangeStartDate = noop,
    onChangeEndDate = noop,
    onChangeFileNameFilter = noop,
    customActionLabel,
    customAction,
    setDataLakeIndex,
    children,
  } = props;

  const [initialized, setInitialized] = React.useState(false);

  const [showFiltersMenu, setShowFiltersMenu] = React.useState(false);

  const [breadcrumbs, setBreadcrumbs] = React.useState<string[]>(initialBreadcrumbs);

  const [startDate, setStartDate] = React.useState<Date | null>(initialStartDate);

  const [endDate, setEndDate] = React.useState<Date | null>(initialEndDate);

  const [fileNameFilter, setFileNameFilter] = React.useState<string>(initialFileNameFilter);

  const searchFiltersEnabled = React.useMemo(() =>
    startDate !== null || endDate !== null,
    [startDate, endDate]);

  const FileBrowser = React.useMemo(() =>
    searchFiltersEnabled ? CustomSearchFileBrowser : SingleDateFileBrowser,
    [searchFiltersEnabled]);

  const openMenu = React.useCallback(() => setShowFiltersMenu(true), [setShowFiltersMenu]);

  const closeMenu = React.useCallback(() => setShowFiltersMenu(false), [setShowFiltersMenu]);

  React.useEffect(() => {
    onChangeBreadcrumbs(breadcrumbs);
  }, [breadcrumbs, onChangeBreadcrumbs]);

  React.useEffect(() => {
    onChangeStartDate(startDate);
  }, [startDate, onChangeStartDate]);

  React.useEffect(() => {
    onChangeEndDate(endDate);
  }, [endDate, onChangeEndDate]);

  React.useEffect(() => {
    onChangeFileNameFilter(fileNameFilter);
  }, [fileNameFilter, onChangeFileNameFilter]);

  // Reset the breadcrumbs and file name filter whenever the data set alias changes
  // and whenever file browser type changes from single date to custom search
  React.useEffect(() => {
    setBreadcrumbs([dataSetAlias]);
    setFileNameFilter("");
    setInitialized(false);
  }, [FileBrowser, dataSetAlias, setBreadcrumbs, setFileNameFilter, setInitialized]);

  // Used as a sentinel to ensure we can properly unmount and clear child state when necessary
  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
  }, [initialized, setInitialized]);

  if (isEmptyString(dataSetAlias)) {
    return null;
  }

  return (
    <div className={classnames("dataLakeFileBrowser", className, classes.container)}>
      <DataLakeFileBrowserHeader
        className={classnames("header", headerClassName, classes.header)}
        breadcrumbs={breadcrumbs}
        startDate={startDate}
        endDate={endDate}
        showFiltersMenu={showFiltersMenu}
        setBreadcrumbs={setBreadcrumbs}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        openMenu={openMenu}
        closeMenu={closeMenu}
      />
      {initialized && (
        <FileBrowser
          className={classnames("content", contentClassName, classes.content)}
          listClassName={classnames("list", listClassName, classes.list)}
          breadcrumbs={breadcrumbs}
          dataSetAlias={dataSetAlias}
          accountId={accountId}
          startDate={startDate}
          endDate={endDate}
          fileNameFilter={fileNameFilter}
          setBreadcrumbs={setBreadcrumbs}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          openFiltersMenu={openMenu}
          setFileNameFilter={setFileNameFilter}
          customActionLabel={customActionLabel}
          customAction={customAction}
          setDataLakeIndex={setDataLakeIndex}
        />
      )}
      {children}
    </div>
  );
});

export default DataLakeFileBrowser;
