import { Record } from "immutable";
import { getStringValue } from "@util";

export interface BreadcrumbAttributes {
  url?: string;
  title?: string;
}

export class Breadcrumb extends Record({
  url: "",
  title: "",
}) implements BreadcrumbAttributes {

  public static EMPTY: Breadcrumb = new Breadcrumb();

  public readonly url: string;
  public readonly title: string;

  public getURL(): string {
    return getStringValue(this.url);
  }

  public getTitle(): string {
    return getStringValue(this.title);
  }
}

export default Breadcrumb;
