import React from "react";
import DashboardDetails from "./containers/DashboardDetails";

export function DashboardDetailsView() {

  return (
    <DashboardDetails />
  );
}

export default DashboardDetailsView;
