import { makeJsonApiRequest, withAuthToken } from "@network/helpers";
import { isEmptyString } from "@util";

const USERIDM_UI_MANAGER_URL = process.env.REACT_APP_USERIDM_UI_MANAGER_REGIONAL || "";

if (isEmptyString(USERIDM_UI_MANAGER_URL)) {
  throw new Error(
    "Missing Environment Variable: REACT_APP_USERIDM_UI_MANAGER_REGIONAL"
  );
}

export interface GetUiPackageFilesResponse {
  package: {
    files: string[]
  };
}

export const getUiPackageFiles = (authToken: string): Promise<GetUiPackageFilesResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${USERIDM_UI_MANAGER_URL}/security/configuration/v1/user-interactions/hosted-ui/files`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
      },
    };

    const defaultErrorMessage = "Failed to get UI Package files";

    return makeJsonApiRequest(url, settings, defaultErrorMessage)
      // This is a workaround for IOTSEC-4253, which does not have regional support for this api in ali-cloud
      .catch(error => {

        const { status = 500 } = error || {};

        // If a valid error was encountered, return the error
        if (status !== 500 && status !== 404) {
          return Promise.reject(error);
        }

        // Otherwise, try using the legacy api if the regional api support is missing
        return import("./UiManagerLegacyClient")
          .then(UiManagerLegacyClient => UiManagerLegacyClient.listUiPackageFiles(authToken));
      });
  };

  return validate().then(makeRequest);
};
