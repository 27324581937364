import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import {
  getStatements,
  getNameFilter,
  getSortByColumn,
  isSortOrderAscending,
} from "../selectors";
import {
  setNameFilter,
  setSortByColumn,
  setSortOrderAscending,
} from "../actions";
import {
  PolicyStatementsList,
  PolicyStatementsListColumn,
  PolicyStatementsListActions,
  PolicyStatementsListModel,
} from "@components";
import { PolicyStatement } from "@data";

interface ContainerModel extends PolicyStatementsListModel {
  items?: PolicyStatement[];
}

interface ContainerActions extends PolicyStatementsListActions {
}

const StatementsContainer = (props: ContainerModel & ContainerActions) => {

  const {
    items = [],
    ...otherProps
  } = props;

  return (
    <PolicyStatementsList
      {...otherProps}
      items={items}
      showSearch={true}
      showSummary={true}
      showIcon={false}
      showRefreshButton={false}
    />
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
  items: getStatements(state),
  nameFilter: getNameFilter(state),
  sortOrderAscending: isSortOrderAscending(state),
  sortByColumn: getSortByColumn(state),
});

const mapDispatchToProps = (dispatch: any): ContainerActions => ({
  setNameFilter: (nameFilter: string) => dispatch(setNameFilter(nameFilter)),
  setSortOrderAscending: (ascending: boolean) => dispatch(setSortOrderAscending(ascending)),
  setSortByColumn: (column: PolicyStatementsListColumn) => dispatch(setSortByColumn(column)),
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(StatementsContainer);
