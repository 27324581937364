import { Module } from "@data";
import { equalsIgnoreCase } from "@util";
import { initialize, onMount } from "./actions";
import { createListViewModule } from "@base/createModule";
import { PrimaryIcon as icon } from "./components/styles";
import {
  LEGACY_MODULE_PATH,
  LIST_APPROVAL_REQUESTS_REDIRECT,
  MODULE_ID,
  MODULE_PATH,
} from "./constants";
import view from "./DataApprovalRequestsModuleView";

export const dataApprovalRequestsModule: Module = createListViewModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  legacyPath: LEGACY_MODULE_PATH,
  name: "My Data Approvals",
  title: "My Data Approvals",
  className: "dataApprovalRequestsModule",
  icon,
  view,
  initialize,
  onMount,
  redirects: {
    [LIST_APPROVAL_REQUESTS_REDIRECT]: (action, resourceId, extraQueryParams) => {
      return `${MODULE_PATH}?${extraQueryParams}`;
    },
  },
  disabled: !equalsIgnoreCase("true", process.env.REACT_APP_DATA_GOVERNANCE_MODULES_ENABLED),
});

export default dataApprovalRequestsModule;
