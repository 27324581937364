import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    dialog: {
    },
    content: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 18,
    },
    label: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "18px",
      textAlign: "center",
      marginTop: 16,
      marginBottom: 16,
    },
    emphasis: {
      fontWeight: 500,
      marginRight: 4,
    },
    credentialInfo: {
      marginTop: 15,
      padding: "8px 4px 12px",
      border: `1px solid ${Color.GREY1}`,
      borderRadius: 6,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    summaryViewLabel: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      marginTop: 8,
      "&:first-child": {
        marginTop: 0,
      },
    },
    summaryViewLabelName: {
      flex: "0 0 45%",
      fontSize: 11,
      fontWeight: 500,
      textAlign: "end",
    },
    summaryViewLabelValue: {
      flex: "0 0 auto",
      fontSize: 12,
      fontWeight: 300,
      marginLeft: 8,
      padding: "8px 6px",
      backgroundColor: Color.LIGHT_GREY3,
    },
    summaryViewLabelValues: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      overflow: "hidden",
      whiteSpace: "normal",
      wordBreak: "break-word",
    },
  });

export default styles;
