import { combineReducers } from "redux";
import { Actions, filterAction, Identity, ReduxAction } from "../../util";

export interface CreateNamespaceSchema {
  namespace: string;
  errorMessage: string;
  successMessage: string;
  showAccessDenied: boolean;
  showProgressIndicator: boolean;
}

export enum CreateNamespaceActionType {
  SET_NAMESPACE = "create_namespace/set_namespace",
  SET_ERROR_MESSAGE = "create_namespace/set_error_message",
  SET_SUCCESS_MESSAGE = "create_namespace/set_success_message",
  TOGGLE_SHOW_ACCESS_DENIED = "create_namespace/toggle_show_access_denied",
  TOGGLE_SHOW_PROGRESS_INDICATOR = "create_namespace/toggle_show_progress_indicator",
  CREATE_NAMESPACE_REQUEST = "create_namespace/create_namespace_request",
  CREATE_NAMESPACE_REQUEST_SUCCESS = "create_namespace/create_namespace_request_success",
  CREATE_NAMESPACE_REQUEST_FAILURE = "create_namespace/create_namespace_request_failure",
}

export type CreateNamespaceAction = ReduxAction<CreateNamespaceActionType>;

const namespace = filterAction<string>(Actions(
  CreateNamespaceActionType.SET_NAMESPACE))(Identity, "");

const errorMessage = filterAction<string>(Actions(
  CreateNamespaceActionType.SET_ERROR_MESSAGE))(Identity, "");

const successMessage = filterAction<string>(Actions(
  CreateNamespaceActionType.SET_SUCCESS_MESSAGE))(Identity, "");

const showAccessDenied = filterAction<boolean>(Actions(
  CreateNamespaceActionType.TOGGLE_SHOW_ACCESS_DENIED))(Identity, false);

const showProgressIndicator = filterAction<boolean>(Actions(
  CreateNamespaceActionType.TOGGLE_SHOW_PROGRESS_INDICATOR))(Identity, false);

export const CreateNamespaceReducer = combineReducers<CreateNamespaceSchema>({
  namespace,
  errorMessage,
  successMessage,
  showAccessDenied,
  showProgressIndicator,
});
