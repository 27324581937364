import React from "react";
import classnames from "classnames";
import snakeCase from "lodash/snakeCase";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { RestClientError } from "@network";
import { useSecurityManagerClient } from "@hooks";
import { usePortalSnackbar, UploadDialog } from "@components";
import { binaryDataListItem as styles } from "./styles";
import { isEmptyString, noop } from "@util/Functions";
import LoadingIndicator from "./LoadingIndicator";

export const EditBinaryDataListItem = withStyles(styles)((props: WithStyles<typeof styles> & {
  userId?: string,
  name?: string,
  selfAuthorized?: boolean,
  cancel?: () => void,
  onSuccess?: () => void,
}) => {

  const {
    classes,
    userId = "",
    name = "",
    selfAuthorized,
    cancel = noop,
    onSuccess = noop,
  } = props;

  const SecurityManagerClient = useSecurityManagerClient();
  const [uploading, setUploading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [file, setFile] = React.useState<File>();

  usePortalSnackbar(`upload-binary-data-${snakeCase(name)}`, {
    errorMessage,
    successMessage,
    onSuccessMessageShown: onSuccess,
    successCallbackDelay: 0,
  });

  React.useEffect(() => {

    if (!file || !uploading || errorMessage || successMessage) {
      return () => noop;
    }

    let ignore = false;

    SecurityManagerClient.updateUserBinaryData(userId, name, file, selfAuthorized)
      .then(() => {
        if (!ignore) {
          setUploading(false);
          setSuccessMessage("Binary Data Uploaded");
        }
      })
      .catch((response: RestClientError) => {
        if (!ignore) {
          const { description, error } = response;
          setUploading(false);
          setErrorMessage(description || error || "Upload Failed");
        }
      });

    return () => { ignore = true; };

  }, [
    SecurityManagerClient,
    userId,
    name,
    file,
    selfAuthorized,
    uploading,
    successMessage,
    errorMessage,
    setUploading,
    setSuccessMessage,
    setErrorMessage,
  ]);

  if (isEmptyString(name)) {
    return null;
  }

  return (
    <div className={classnames("editBinaryDataListItem", classes.container)}>
      {uploading && (
        <LoadingIndicator
          className={classnames("loadingIndicator", classes.loadingIndicator)}
          label="Uploading..."
          labelClassName={classnames("uploading", classes.label)}
        />
      )}
      {!uploading && (
        <label className={classnames(classes.label)}>
          Upload Requested
        </label>
      )}
      <UploadDialog
        open={true}
        title="Upload Binary Data"
        file={file}
        uploading={uploading}
        successMessage={successMessage}
        errorMessage={errorMessage}
        cancel={cancel}
        setUploading={setUploading}
        setErrorMessage={setErrorMessage}
        setFile={setFile}
      />
    </div>
  );
});

export default EditBinaryDataListItem;
