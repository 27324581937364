import {
  AuthenticateServiceRegionalResponse,
  CreateMultifactorAuthenticatorResponse,
  GetServiceDataSetsResponse,
  SecurityServiceRegionalClient,
  GetSecurityServicesResponse,
} from "@network";
import { SecurityServiceRegionalAttributes } from "@data";
import { useApiRequestAnalytics } from "@hooks";

export type AuthenticateServicesRegionalApi = (serviceId: string,
                                               accountId: string,
                                               secret: string,
                                               mfaCode?: string) => Promise<AuthenticateServiceRegionalResponse>;

export type GetSecurityServicesRegionalApi = (nameFilter?: string,
                                              groupName?: string,
                                              next?: string,
                                              limit?: number) => Promise<GetSecurityServicesResponse>;

export type GetSecurityServiceRegionalApi = (serviceId: string) => Promise<SecurityServiceRegionalAttributes>;

export type GetServiceDataSetsApi =
  (accountId: string, serviceId: string, accessRequestType: string) => Promise<GetServiceDataSetsResponse>;

export type CreateSecurityServiceRegionalApi = (json: string) => Promise<SecurityServiceRegionalAttributes>;

export type GenerateServiceSecretApi = (serviceId: string) => Promise<SecurityServiceRegionalAttributes>;

export type SetServiceSecretApi = (serviceId: string, json: string) => Promise<void>;

export type DeleteServiceSecretApi = (serviceId: string) => Promise<void>;

export type DeleteServiceRegionalApi = (serviceId: string) => Promise<void>;

export type EnableServiceRegionalApi = (serviceId: string) => Promise<void>;

export type DisableServiceRegionalApi = (serviceId: string) => Promise<void>;

export type CreateMultifactorAuthenticatorRegionalApi = () => Promise<CreateMultifactorAuthenticatorResponse>;

export type ActivateMultifactorAuthenticatorRegionalApi = (code: string, id?: string) => Promise<void>;

export type DeleteMultifactorAuthenticatorRegionalApi = (code: string, id?: string) => Promise<void>;

export interface SecurityServiceRegionalClientApi {
  authenticateServiceRegionalApi: AuthenticateServicesRegionalApi;
  getSecurityServicesRegionalApi: GetSecurityServicesRegionalApi;
  getSecurityServiceRegionalApi: GetSecurityServiceRegionalApi;
  getServiceDataSetsApi: GetServiceDataSetsApi;
  createSecurityServiceRegionalApi: CreateSecurityServiceRegionalApi;
  generateServiceSecretApi: GenerateServiceSecretApi;
  setServiceSecretApi: SetServiceSecretApi;
  deleteServiceSecretApi: DeleteServiceSecretApi;
  deleteServiceRegionalApi: DeleteServiceRegionalApi;
  enableServiceRegionalApi: EnableServiceRegionalApi;
  disableServiceRegionalApi: DisableServiceRegionalApi;
  createMultifactorAuthenticatorRegionalApi: CreateMultifactorAuthenticatorRegionalApi;
  activateMultifactorAuthenticatorRegionalApi: ActivateMultifactorAuthenticatorRegionalApi;
  deleteMultifactorAuthenticatorRegionalApi: DeleteMultifactorAuthenticatorRegionalApi;
}

enum ActionType {
  AUTHENTICATE_SERVICE_REGIONAL_REQUEST = "security_services_regional_client/authenticate_service_regional_request",
  AUTHENTICATE_SERVICE_REGIONAL_SUCCESS = "security_services_regional_client/authenticate_service_regional_success",
  AUTHENTICATE_SERVICE_REGIONAL_FAILED = "security_services_regional_client/authenticate_service_regional_failed",

  GET_SERVICES_REGIONAL_REQUEST = "security_services_regional_client/get_security_services_regional_request",
  GET_SERVICES_REGIONAL_SUCCESS = "security_services_regional_client/get_security_services_regional_success",
  GET_SERVICES_REGIONAL_FAILED = "security_services_regional_client/get_security_services_regional_failed",

  GET_SERVICE_REGIONAL_REQUEST = "security_services_regional_client/get_security_service_regional_request",
  GET_SERVICE_REGIONAL_SUCCESS = "security_services_regional_client/get_security_service_regional_success",
  GET_SERVICE_REGIONAL_FAILED = "security_services_regional_client/get_security_service_regional_failed",

  GET_SERVICE_DATA_SETS_REQUEST = "security_services_regional_client/get_service_data_sets_request",
  GET_SERVICE_DATA_SETS_SUCCESS = "security_services_regional_client/get_service_data_sets_success",
  GET_SERVICE_DATA_SETS_FAILED = "security_services_regional_client/get_service_data_sets_failed",

  CREATE_SERVICE_REGIONAL_REQUEST = "security_services_regional_client/create_security_service_regional_request",
  CREATE_SERVICE_REGIONAL_SUCCESS = "security_services_regional_client/create_security_service_regional_success",
  CREATE_SERVICE_REGIONAL_FAILED = "security_services_regional_client/create_security_service_regional_failed",

  GENERATE_SERVICE_SECRET_REQUEST = "security_services_regional_client/generate_service_secret_request",
  GENERATE_SERVICE_SECRET_SUCCESS = "security_services_regional_client/generate_service_secret_success",
  GENERATE_SERVICE_SECRET_FAILED = "security_services_regional_client/generate_service_secret_failed",

  SET_SERVICE_SECRET_REQUEST = "security_services_regional_client/set_service_secret_request",
  SET_SERVICE_SECRET_SUCCESS = "security_services_regional_client/set_service_secret_success",
  SET_SERVICE_SECRET_FAILED = "security_services_regional_client/set_service_secret_failed",

  DELETE_SERVICE_SECRET_REQUEST = "security_services_regional_client/delete_service_secret_request",
  DELETE_SERVICE_SECRET_SUCCESS = "security_services_regional_client/delete_service_secret_success",
  DELETE_SERVICE_SECRET_FAILED = "security_services_regional_client/delete_service_secret_failed",

  DELETE_SERVICE_REGIONAL_REQUEST = "security_services_regional_client/delete_service_regional_request",
  DELETE_SERVICE_REGIONAL_SUCCESS = "security_services_regional_client/delete_service_regional_success",
  DELETE_SERVICE_REGIONAL_FAILED = "security_services_regional_client/delete_service_regional_failed",

  ENABLE_SERVICE_REGIONAL_REQUEST = "security_services_regional_client/enable_service_regional_request",
  ENABLE_SERVICE_REGIONAL_SUCCESS = "security_services_regional_client/enable_service_regional_success",
  ENABLE_SERVICE_REGIONAL_FAILED = "security_services_regional_client/enable_service_regional_failed",

  DISABLE_SERVICE_REGIONAL_REQUEST = "security_services_regional_client/disable_service_regional_request",
  DISABLE_SERVICE_REGIONAL_SUCCESS = "security_services_regional_client/disable_service_regional_success",
  DISABLE_SERVICE_REGIONAL_FAILED = "security_services_regional_client/disable_service_regional_failed",

  CREATE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_REQUEST = "security_services_regional_client/create_multifactor_authenticator_regional_request",
  CREATE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_SUCCESS = "security_services_regional_client/create_multifactor_authenticator_regional_success",
  CREATE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_FAILED = "security_services_regional_client/create_multifactor_authenticator_regional_failed",

  ACTIVATE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_REQUEST = "security_services_regional_client/activate_multifactor_authenticator_regional_request",
  ACTIVATE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_SUCCESS = "security_services_regional_client/activate_multifactor_authenticator_regional_success",
  ACTIVATE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_FAILED = "security_services_regional_client/activate_multifactor_authenticator_regional_failed",

  DELETE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_REQUEST = "security_services_regional_client/delete_multifactor_authenticator_regional_request",
  DELETE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_SUCCESS = "security_services_regional_client/delete_multifactor_authenticator_regional_success",
  DELETE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_FAILED = "security_services_regional_client/delete_multifactor_authenticator_regional_failed",
}

export const useAuthenticateServiceRegionalApi = (): AuthenticateServicesRegionalApi =>
  useApiRequestAnalytics(SecurityServiceRegionalClient.authenticateServiceRegional, {
    REQUEST_EVENT: ActionType.AUTHENTICATE_SERVICE_REGIONAL_REQUEST,
    SUCCESS_EVENT: ActionType.AUTHENTICATE_SERVICE_REGIONAL_SUCCESS,
    ERROR_EVENT: ActionType.AUTHENTICATE_SERVICE_REGIONAL_FAILED,
  });

export const useGetSecurityServicesRegionalApi = (): GetSecurityServicesRegionalApi =>
  useApiRequestAnalytics(SecurityServiceRegionalClient.getServicesRegional, {
    REQUEST_EVENT: ActionType.GET_SERVICES_REGIONAL_REQUEST,
    SUCCESS_EVENT: ActionType.GET_SERVICES_REGIONAL_SUCCESS,
    ERROR_EVENT: ActionType.GET_SERVICES_REGIONAL_FAILED,
  });

export const useGetSecurityServiceRegionalApi = (): GetSecurityServiceRegionalApi =>
  useApiRequestAnalytics(SecurityServiceRegionalClient.getServiceRegional, {
    REQUEST_EVENT: ActionType.GET_SERVICE_REGIONAL_REQUEST,
    SUCCESS_EVENT: ActionType.GET_SERVICE_REGIONAL_SUCCESS,
    ERROR_EVENT: ActionType.GET_SERVICE_REGIONAL_FAILED,
  });

export const useGetServiceDataSetsApi = (): GetServiceDataSetsApi =>
  useApiRequestAnalytics(SecurityServiceRegionalClient.getServiceDataSets, {
    REQUEST_EVENT: ActionType.GET_SERVICE_DATA_SETS_REQUEST,
    SUCCESS_EVENT: ActionType.GET_SERVICE_DATA_SETS_SUCCESS,
    ERROR_EVENT: ActionType.GET_SERVICE_DATA_SETS_FAILED,
  });

export const useCreateSecurityServiceRegionalApi = (): CreateSecurityServiceRegionalApi =>
    useApiRequestAnalytics(SecurityServiceRegionalClient.createServiceRegional, {
     REQUEST_EVENT: ActionType.CREATE_SERVICE_REGIONAL_REQUEST,
     SUCCESS_EVENT: ActionType.CREATE_SERVICE_REGIONAL_SUCCESS,
     ERROR_EVENT: ActionType.CREATE_SERVICE_REGIONAL_FAILED,
  });

export const useGenerateServiceSecretApi = (): GenerateServiceSecretApi =>
  useApiRequestAnalytics(SecurityServiceRegionalClient.generateServiceSecret, {
    REQUEST_EVENT: ActionType.GENERATE_SERVICE_SECRET_REQUEST,
    SUCCESS_EVENT: ActionType.GENERATE_SERVICE_SECRET_SUCCESS,
    ERROR_EVENT: ActionType.GENERATE_SERVICE_SECRET_FAILED,
  });

export const useSetServiceSecretApi = (): SetServiceSecretApi =>
  useApiRequestAnalytics(SecurityServiceRegionalClient.setServiceSecret, {
    REQUEST_EVENT: ActionType.SET_SERVICE_SECRET_REQUEST,
    SUCCESS_EVENT: ActionType.SET_SERVICE_SECRET_SUCCESS,
    ERROR_EVENT: ActionType.SET_SERVICE_SECRET_FAILED,
  });

export const useDeleteServiceSecretApi = (): DeleteServiceSecretApi =>
  useApiRequestAnalytics(SecurityServiceRegionalClient.deleteServiceSecret, {
    REQUEST_EVENT: ActionType.DELETE_SERVICE_SECRET_REQUEST,
    SUCCESS_EVENT: ActionType.DELETE_SERVICE_SECRET_SUCCESS,
    ERROR_EVENT: ActionType.DELETE_SERVICE_SECRET_FAILED,
  });

export const useDeleteServiceRegionalApi = (): DeleteServiceRegionalApi =>
  useApiRequestAnalytics(SecurityServiceRegionalClient.deleteServiceRegional, {
    REQUEST_EVENT: ActionType.DELETE_SERVICE_REGIONAL_REQUEST,
    SUCCESS_EVENT: ActionType.DELETE_SERVICE_REGIONAL_SUCCESS,
    ERROR_EVENT: ActionType.DELETE_SERVICE_REGIONAL_FAILED,
  });

export const useEnableServiceRegionalApi = (): EnableServiceRegionalApi =>
  useApiRequestAnalytics(SecurityServiceRegionalClient.enableServiceRegionalApi, {
    REQUEST_EVENT: ActionType.ENABLE_SERVICE_REGIONAL_REQUEST,
    SUCCESS_EVENT: ActionType.ENABLE_SERVICE_REGIONAL_SUCCESS,
    ERROR_EVENT: ActionType.ENABLE_SERVICE_REGIONAL_FAILED,
  });

export const useDisableServiceRegionalApi = (): DisableServiceRegionalApi =>
  useApiRequestAnalytics(SecurityServiceRegionalClient.disableServiceRegionalApi, {
    REQUEST_EVENT: ActionType.DISABLE_SERVICE_REGIONAL_REQUEST,
    SUCCESS_EVENT: ActionType.DISABLE_SERVICE_REGIONAL_SUCCESS,
    ERROR_EVENT: ActionType.DISABLE_SERVICE_REGIONAL_FAILED,
  });

export const useCreateMultifactorAuthenticatorRegionalApi = (): CreateMultifactorAuthenticatorRegionalApi =>
  useApiRequestAnalytics(SecurityServiceRegionalClient.createMultifactorAuthenticator, {
    REQUEST_EVENT: ActionType.CREATE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_REQUEST,
    SUCCESS_EVENT: ActionType.CREATE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_SUCCESS,
    ERROR_EVENT: ActionType.CREATE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_FAILED,
  });

export const useActivateMultifactorAuthenticatorRegionalApi = (): ActivateMultifactorAuthenticatorRegionalApi =>
  useApiRequestAnalytics(SecurityServiceRegionalClient.activateMultifactorAuthenticator, {
    REQUEST_EVENT: ActionType.ACTIVATE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_REQUEST,
    SUCCESS_EVENT: ActionType.ACTIVATE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_SUCCESS,
    ERROR_EVENT: ActionType.ACTIVATE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_FAILED,
  });

export const useDeleteMultifactorAuthenticatorRegionalApi = (): DeleteMultifactorAuthenticatorRegionalApi =>
  useApiRequestAnalytics(SecurityServiceRegionalClient.deleteMultifactorAuthenticator, {
    REQUEST_EVENT: ActionType.DELETE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_REQUEST,
    SUCCESS_EVENT: ActionType.DELETE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_SUCCESS,
    ERROR_EVENT: ActionType.DELETE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_FAILED,
  });

export const useSecurityServicesRegionalClient = (): SecurityServiceRegionalClientApi => ({
  authenticateServiceRegionalApi: useAuthenticateServiceRegionalApi(),
  getSecurityServicesRegionalApi: useGetSecurityServicesRegionalApi(),
  getSecurityServiceRegionalApi: useGetSecurityServiceRegionalApi(),
  getServiceDataSetsApi: useGetServiceDataSetsApi(),
  createSecurityServiceRegionalApi: useCreateSecurityServiceRegionalApi(),
  generateServiceSecretApi: useGenerateServiceSecretApi(),
  setServiceSecretApi: useSetServiceSecretApi(),
  deleteServiceSecretApi: useDeleteServiceSecretApi(),
  deleteServiceRegionalApi: useDeleteServiceRegionalApi(),
  enableServiceRegionalApi: useEnableServiceRegionalApi(),
  disableServiceRegionalApi: useDisableServiceRegionalApi(),
  createMultifactorAuthenticatorRegionalApi: useCreateMultifactorAuthenticatorRegionalApi(),
  activateMultifactorAuthenticatorRegionalApi: useActivateMultifactorAuthenticatorRegionalApi(),
  deleteMultifactorAuthenticatorRegionalApi: useDeleteMultifactorAuthenticatorRegionalApi(),
});

export { ActionType as SecurityServiceRegionalClientActionType };

export default useSecurityServicesRegionalClient;
