import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import { useGetWorkload, useUpdateWorkloadTrigger } from "@hooks";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { PrecedingWorkloadCompletionStatus, Trigger, UpdateTriggerRequest } from "@data";
import { ActionsListItemData, DropdownMenu, WorkloadActionsList } from "@components";
import PortalModuleDialog, { PortalModuleDialogActions, PortalModuleDialogModel } from "@components/portal-module-dialog";

export interface WorkloadCompletionDialogModel extends PortalModuleDialogModel<string> {
  name?: string;
  version?: number;
  trigger?: Trigger;
}

export interface WorkloadCompletionDialogActions extends PortalModuleDialogActions<string> {
}

type Props = WithStyles<typeof styles> & WorkloadCompletionDialogModel & WorkloadCompletionDialogActions;

export const WorkloadCompletionDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    name = "",
    version = 1,
    trigger = Trigger.EMPTY,
    cancel: cancelDialog = noop,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  const { precedingWorkloadName } = trigger;

  const [status, setStatus] = React.useState(trigger.getPrecedingWorkloadCompletionStatus());
  const [actions, setActions] = React.useState(trigger.getConditions());

  const [{ workload: precedingWorkload, ...otherPrecedingModel }, { refresh, ...otherPrecedingActions }] =
    useGetWorkload({ name: precedingWorkloadName, deferRequest: true });

  const precedingActions = precedingWorkload.getWorkloadCompletionMetadataActions();

  const [{ showLoadingIndicator, ...otherModel }, { updateTrigger, reset }] =
    useUpdateWorkloadTrigger({
      name,
      version,
      trigger: new UpdateTriggerRequest({
        type: trigger.getType(),
        eventType: trigger.getType(),
        eventBindingId: trigger.getId(),
        accountId: trigger.getAccountId(),
        precedingWorkloadName: precedingWorkloadName,
        completionState: {
          ...trigger.toJS().completionState,
          actions: actions.filter(i => precedingActions.includes(i)),
          completionStatus: status
        }
      })
    });

  const updateStatus = (updatedStatus: PrecedingWorkloadCompletionStatus) => {
    setStatus(updatedStatus);
  };

  const confirm = React.useCallback(() => {
    updateTrigger();
  }, [updateTrigger]);

  const cancel = React.useCallback(() => {
    reset();
    cancelDialog();
    setActions(trigger.getConditions());
    setStatus(trigger.getPrecedingWorkloadCompletionStatus());
  }, [trigger, reset, cancelDialog, setStatus, setActions]);

  const onSuccessMessageShown = React.useCallback(() => {
    reset();
    onSuccess();
  }, [reset, onSuccess]);

  const items = React.useMemo(() =>
    precedingActions.map(
      action => new ActionsListItemData({ name: action })
    ), [precedingActions]);

  const selectedItems = React.useMemo(() =>
    actions.map(action => new ActionsListItemData({ name: action })), [actions]);

  const setSelectedItems = React.useCallback((updatedActions: ActionsListItemData[]) => {
    setActions(updatedActions.map(item => item.getName()));
  }, [setActions]);

  React.useEffect(() => {
    if (!isEmptyString(precedingWorkloadName)) {
      refresh();
      setSelectedItems([]);
    }
  }, [precedingWorkloadName, refresh]);

  React.useEffect(() => {
    setActions(trigger.getConditions());
    setStatus(trigger.getPrecedingWorkloadCompletionStatus());
  }, [trigger, setStatus, setActions]);

  if (isEmptyString(name)) {
    return null;
  }

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      className="workloadCompletionDialog"
      title="Edit Preceding Workload Completion Event"
      loading={showLoadingIndicator}
      continueButtonLabel="Save"
      onSuccessMessageShown={onSuccessMessageShown}
      confirm={confirm}
      cancel={cancel}
    >
      <div className={classnames("workloadCompletionContainer", classes.container)}>
        <label className={classnames("name", classes.name)}>
          <b>Preceding Workload:</b> {precedingWorkloadName}
        </label>
        <div className={classnames("statusContainer", classes.statusContainer)}>
          <label className={classnames("title", classes.title)}>
            Select the new completion status for this trigger:
          </label>
          <DropdownMenu
            className={classnames("workloadCompletionStatus", classes.dropdown)}
            hideEmptyValue={true}
            values={Object.values(PrecedingWorkloadCompletionStatus)}
            selectedValue={status}
            setSelectedValue={(newStatus) => updateStatus(newStatus as PrecedingWorkloadCompletionStatus)}
          />
        </div>
        <label className={classnames("title", classes.title)}>
          Add actions that the preceding workload will be required to have:
        </label>
        <WorkloadActionsList
          {...otherProps}
          {...otherPrecedingModel}
          {...otherPrecedingActions}
          items={items}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          noResultsLabel={`No actions found for ${precedingWorkload.getName()}`}
        />
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadCompletionDialog;
