import React from "react";
import styles from "./styles";
import classnames from "classnames";
import { Button, VariablesListColumn } from "@components";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { PopoverOrigin } from "@material-ui/core/Popover";
import { clickHandler, isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import VariablesList, { EditVariablesListItemPopover } from "./VariablesList";
import ActionsList, { ActionsListItemData, EditActionsListItemPopover } from "./ActionsList";
import { CreateWorkloadRequest, CreateWorkloadRequestAttributes, WorkloadVariableSchema } from "@data";

const DEFAULT_POPOVER_ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: "center",
  horizontal: "center",
};

const DEFAULT_POPOVER_TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: "top",
  horizontal: "center",
};

export interface WorkloadCompletionMetadataViewModel {
  className?: string;
  title?: string;
  data?: CreateWorkloadRequest;
  actions?: string[];
  variables?: WorkloadVariableSchema[];
  showAddActions?: boolean;
  showDeleteActions?: boolean;
  showEditActions?: boolean;
  showAddVariables?: boolean;
  showEditVariables?: boolean;
  showDeleteVariables?: boolean;
  showLoadingIndicator?: boolean;
  hideActions?: boolean;
  hideVariables?: boolean;
}

export interface WorkloadCompletionMetadataViewActions {
  setWorkloadData?: (data: Partial<CreateWorkloadRequestAttributes>) => void;
  customAddAction?: (action: ActionsListItemData) => void;
  customDeleteAction?: (action: ActionsListItemData) => void;
  customEditActions?: (actions: ActionsListItemData[]) => void;
  customAddVariable?: (updatedItem: WorkloadVariableSchema) => void;
  customEditVariable?: (updatedVariables: WorkloadVariableSchema[]) => void;
  customDeleteVariable?: (variable: WorkloadVariableSchema) => void;
  customRenderVariable?: (item: WorkloadVariableSchema, column: VariablesListColumn) => React.ReactNode;
  onClickAddAction?: () => void;
  onClickAddVariable?: () => void;
}

type Model = WorkloadCompletionMetadataViewModel;
type Actions = WorkloadCompletionMetadataViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const WorkloadCompletionMetadata = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Workload Completion Metadata",
    data = CreateWorkloadRequest.EMPTY,
    actions = data.getWorkloadCompletionMetadataActions(),
    variables = data.getWorkloadCompletionMetadataVariableSchemas(),
    showEditActions = false,
    showAddActions = false,
    showDeleteActions = false,
    showAddVariables = false,
    showEditVariables = false,
    showDeleteVariables = false,
    showLoadingIndicator = false,
    hideActions = false,
    hideVariables = false,
    onClickAddAction,
    onClickAddVariable,
    customAddVariable = noop,
    customEditVariable = noop,
    customDeleteVariable = noop,
    customRenderVariable,
    customAddAction = noop,
    customDeleteAction = noop,
    customEditActions = noop,
    setWorkloadData = noop,
    children,
  } = props;

  const addActionButtonRef = React.useRef(null);

  const addVariableButtonRef = React.useRef(null);

  const [showAddActionPopover, setShowAddActionPopover] = React.useState(false);

  const [showAddVariablePopover, setShowAddVariablePopover] = React.useState(false);

  const openAddActionPopover = React.useCallback(() => {
    setShowAddActionPopover(true);
  }, [setShowAddActionPopover]);

  const closeAddActionPopover = React.useCallback(() => {
    setShowAddActionPopover(false);
  }, [setShowAddActionPopover]);

  const openAddVariablePopover = React.useCallback(() => {
    setShowAddVariablePopover(true);
  }, [setShowAddVariablePopover]);

  const closeAddVariablePopover = React.useCallback(() => {
    setShowAddVariablePopover(false);
  }, [setShowAddVariablePopover]);

  const addAction = customAddAction !== noop ? customAddAction :
    React.useCallback((updatedItem: ActionsListItemData) => {
      setWorkloadData({
      completionMetadata: {
        ...data.getWorkloadCompletionMetadata(),
        actions: Array.from(new Set(
          data.getWorkloadCompletionMetadataActions().concat([updatedItem.getName()]))),
      },
    });
  }, [setWorkloadData, customAddAction, data]);

  const addVariable = customAddVariable !== noop ? customAddVariable :
    React.useCallback((updatedItem: WorkloadVariableSchema) => {
      setWorkloadData({
      completionMetadata: {
        ...data.getWorkloadCompletionMetadata(),
        variableSchemas: data.getWorkloadCompletionMetadataVariableSchemas()
          .concat(updatedItem)
          .map(item => item.toJS()),
      },
    });
  }, [setWorkloadData, data]);

  const editActions = customEditActions !== noop ? customEditActions :
    React.useCallback((updatedActions: ActionsListItemData[]) => {
      setWorkloadData({
        completionMetadata: {
          ...data.getWorkloadCompletionMetadata(),
          actions: updatedActions.map(action => action.getName()),
        },
      });
    }, [setWorkloadData, customEditActions, data]);

  const editVariables = customEditVariable !== noop ? customEditVariable :
    React.useCallback((updatedVariables: WorkloadVariableSchema[]) => {
      setWorkloadData({
      completionMetadata: {
        ...data.getWorkloadCompletionMetadata(),
        variableSchemas: updatedVariables.map(variable => variable.toJS()),
      },
    });
  }, [setWorkloadData, data]);

  const isDuplicate = React.useCallback((name: string) => {
    return actions.includes(name);
  }, [actions]);

  return (
    <div className={classnames("workloadCompletionMetadata", className, classes.container)}>
      {!isEmptyString(title) && (
        <Typography className={classnames("title", classes.title)} variant="h3">
          {title}
        </Typography>
      )}
      <div className={classnames("box", classes.box)}>
        {!hideActions && (
          <div className={classnames("actions", classes.section, { [classes.actions]: !hideVariables })}>
            <div className={classnames("header", classes.header)}>
              <Typography className={classnames("subtitle", classes.subtitle)} variant="h5">
                Actions
              </Typography>
              {showAddActions && (
                <React.Fragment>
                  <Button
                    ref={addActionButtonRef}
                    className={classnames("addButton", classes.addButton)}
                    variant="outlined"
                    startIcon={<AddIcon className={classnames("addIcon", classes.addIcon)} />}
                    onClick={onClickAddAction ? onClickAddAction : clickHandler(openAddActionPopover)}
                  >
                    Add New Action
                  </Button>
                  <EditActionsListItemPopover
                    className={classnames("addActionPopover", classes.popover, classes.addActionPopover)}
                    anchorEl={addActionButtonRef.current}
                    anchorOrigin={DEFAULT_POPOVER_ANCHOR_ORIGIN}
                    transformOrigin={DEFAULT_POPOVER_TRANSFORM_ORIGIN}
                    open={showAddActionPopover}
                    item={new ActionsListItemData()}
                    editItem={addAction}
                    closePopover={closeAddActionPopover}
                    isDuplicate={isDuplicate}
                  />
                </React.Fragment>
              )}
            </div>
            <div className={classnames("content", classes.content)}>
              <ActionsList
                className={classnames("actionsList", classes.actionsList)}
                title=""
                editModeEnabled={showEditActions}
                emptyViewEnabled={true}
                workloadCompletionMetadataActions={actions}
                showDeleteButton={showDeleteActions}
                showLoadingIndicator={showLoadingIndicator}
                customDelete={customDeleteAction}
                editActions={editActions}
              />
            </div>
          </div>
        )}
        {!hideVariables && (
          <div className={classnames("variables", classes.section, classes.variables)}>
            <div className={classnames("header", classes.header)}>
              <Typography className={classnames("subtitle", classes.subtitle)} variant="h5">
                Variables
              </Typography>
              {showAddVariables && (
                <React.Fragment>
                  <Button
                    ref={addVariableButtonRef}
                    className={classnames("addButton", classes.addButton)}
                    variant="outlined"
                    startIcon={<AddIcon className={classnames("addIcon", classes.addIcon)} />}
                    onClick={onClickAddVariable ? onClickAddVariable : clickHandler(openAddVariablePopover)}
                  >
                    Add New Variable
                  </Button>
                  <EditVariablesListItemPopover
                    className={classnames("addVariablePopover", classes.popover, classes.addVariablePopover)}
                    anchorEl={addVariableButtonRef.current}
                    anchorOrigin={DEFAULT_POPOVER_ANCHOR_ORIGIN}
                    transformOrigin={DEFAULT_POPOVER_TRANSFORM_ORIGIN}
                    open={showAddVariablePopover}
                    item={new WorkloadVariableSchema()}
                    editItem={addVariable}
                    closePopover={closeAddVariablePopover}
                  />
                </React.Fragment>
              )}
            </div>
            <div className={classnames("content", classes.content)}>
              <VariablesList
                className={classnames("variablesList", classes.variablesList)}
                title=""
                editModeEnabled={showEditVariables}
                emptyViewEnabled={true}
                items={variables}
                showDeleteButton={showDeleteVariables}
                showLoadingIndicator={showLoadingIndicator}
                customDelete={customDeleteVariable}
                editVariables={editVariables}
                renderItem={customRenderVariable}
              />
            </div>
          </div>
        )}
      </div>
      {children}
    </div>
  );
});

export default WorkloadCompletionMetadata;
