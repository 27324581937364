import React from "react";
import { Module } from "@data";
import { MODULE_ID } from "./constants";
import { isDialogOpen } from "./selectors";
import { initialize, open } from "./actions";
import { PrimaryIcon } from "@modules/users/components/styles";
import LogoutUserDialog from "./containers/LogoutUserDialog";

export const logoutUserModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "Logout User",
  title: "Logout User",
  icon: PrimaryIcon,
  view: <LogoutUserDialog />,
  onClick: open,
  isDialogOpen,
  initialize,
};

export default logoutUserModule;
