import React from "react";
import { Workload } from "@data";
import { GetAllVersionsOfWorkloadResponse } from "@network";
import {
  usePaginatedApiRequest,
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
  useWorkloadManagerClient,
} from "@hooks/index";

type SuccessResponse = GetAllVersionsOfWorkloadResponse;

export interface UseGetAllVersionsOfWorkloadProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
  name: string;
}

export interface UseGetAllVersionsOfWorkloadModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  versions: Workload[];
}

export interface UseGetAllVersionsOfWorkloadActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseGetAllVersionsOfWorkloadProps;
type Model = UseGetAllVersionsOfWorkloadModel;
type Actions = UseGetAllVersionsOfWorkloadActions;
type Result = [Model, Actions];

export const useGetAllVersionsOfWorkload = (props: Props): Result => {

  const {
    name,
    defaultErrorMessage = "Failed to get all versions of workload",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const [versions, setVersions] = React.useState<Workload[]>([]);

  const makeApiRequest = React.useCallback((accessToken, next) =>
      WorkloadManagerClient.getAllVersionsOfWorkload(name, next),
    [WorkloadManagerClient, name]);

  const [
    { successResponse, ...baseModel },
    { reset: baseReset, refresh: baseRefresh, ...baseActions },
  ] = usePaginatedApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
  });

  const updatedVersions = React.useMemo<Workload[]>(() => {
    const { versions: items = [] } = successResponse || {};
    return items.map(attrs => new Workload(attrs));
  }, [successResponse]);

  const reset = React.useCallback(() => {
    setVersions([]);
    baseReset();
  }, [setVersions, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successResponse,
    versions,
  }), [
    baseModel,
    successResponse,
    versions,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    setVersions(currentVersions => currentVersions.concat(updatedVersions));
  }, [updatedVersions, setVersions]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetAllVersionsOfWorkload;
