import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { DataSetRequestWizardActions } from "../actions";
import { DataSetRequestWizardSelectors } from "../selectors";
import EditDescriptionView, { Actions, Model } from "../components/EditDescriptionView";

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const EditDescriptionContainer = (props: Props) => {

  return (
    <EditDescriptionView {...props} />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  dataSetAlias: DataSetRequestWizardSelectors.getDataSetAlias(state),
  description: DataSetRequestWizardSelectors.getDescription(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setDescription: description => dispatch(DataSetRequestWizardActions.updateDescription(description)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(EditDescriptionContainer);
