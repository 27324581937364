import { Module } from "@data";
import { initialize } from "./actions";
import { createModule } from "@base/createModule";
import { isDeviceManagementEnabled } from "@util";
import { MODULE_ID, MODULE_PATH, primaryColor, PrimaryIcon as icon } from "./constants";
import view from "./DeviceEnrollmentDetailsView";

type RouteParams = { id?: string };

export const deviceEnrollmentDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Device Enrollment Details",
  title: "Device Enrollment Details",
  className: "deviceEnrollmentDetailsModule",
  primaryColor,
  icon,
  view,
  onMount: ({ id = "" }: RouteParams = {}) => initialize(id),
  disabled: !isDeviceManagementEnabled(),
});

export default deviceEnrollmentDetailsModule;
