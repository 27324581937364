import React from "react";
import classnames from "classnames";
import kebabCase from "lodash/kebabCase";
import QualityReport from "@data/QualityReport";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface LicensesModel {
  className?: string;
  title?: string;
  report?: QualityReport;
}

export interface LicensesActions {
}

type Model = LicensesModel;
type Actions = LicensesActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const Licenses = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Licensing Inventory",
    report = QualityReport.EMPTY,
    children,
  } = props;

  const licenses = React.useMemo(() =>
      report.getLicenses(),
    [report]);

  const showEmptyView = React.useMemo(() =>
      licenses.length === 0,
    [licenses]);

  const splitList = React.useMemo(() =>
      !showEmptyView && Math.floor(licenses.length / 2) >= 8,
    [showEmptyView, licenses]);

  const listEl = React.useMemo(() => {
    if (showEmptyView) {
      return (
        <Typography className={classnames("emptyView", classes.emptyView)} variant="h6">
          No Licenses Used
        </Typography>
      );
    }
    if (!splitList) {
      return (
        <ul className={classnames("list", classes.list)}>
          {licenses.map(license => (
            <li
              key={kebabCase(license)}
              className={classnames("listItem", classes.listItem)}
            >
              <Typography
                className={classnames("license", classes.license)}
                variant="body1"
              >
                {license}
              </Typography>
            </li>
          ))}
        </ul>
      );
    }
    const splitAt = Math.max(10, Math.floor(licenses.length / 2));
    const firstList = licenses.slice(0, splitAt);
    const secondList = licenses.slice(splitAt);
    return (
      <React.Fragment>
        <ul className={classnames("list", classes.list)}>
          {firstList.map(license => (
            <li
              key={kebabCase(license)}
              className={classnames("listItem", classes.listItem)}
            >
              <Typography
                className={classnames("license", classes.license)}
                variant="body1"
              >
                {license}
              </Typography>
            </li>
          ))}
        </ul>
        <ul className={classnames("list", classes.list)}>
          {secondList.map(license => (
            <li
              key={kebabCase(license)}
              className={classnames("listItem", classes.listItem)}
            >
              <Typography
                className={classnames("license", classes.license)}
                variant="body1"
              >
                {license}
              </Typography>
            </li>
          ))}
        </ul>
      </React.Fragment>
    );
  }, [showEmptyView, splitList, licenses, classes]);

  return (
    <div className={classnames("licenses", className, classes.container)}>
      <Typography
        className={classnames("title", classes.title)}
        variant="h5"
      >
        {title}
      </Typography>
      <div className={classnames("content", classes.content)}>
        {listEl}
      </div>
      {children}
    </div>
  );
});

export default Licenses;
