import React from "react";
import { LogLevelType, WorkloadLogItem } from "@data";
import { GetWorkloadInstanceLogsResponse, SearchInstanceLogParams } from "@network";
import {
  usePaginatedApiRequest,
  useWorkloadManagerClient,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps
} from "@hooks";
import { formatLocalDateToUtc, isEmptyString } from "@util";

type SuccessResponse = GetWorkloadInstanceLogsResponse;

export interface UseGetWorkloadInstanceLogsProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  executionId?: string;
}

export interface UseGetWorkloadInstanceLogsModel extends UseApiRequestModel<SuccessResponse> {
}

export interface UseGetWorkloadInstanceLogsActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetWorkloadInstanceLogsProps;
type Model = UseGetWorkloadInstanceLogsModel;
type Actions = UseGetWorkloadInstanceLogsActions;
type Result = [Model, Actions];

export const useGetWorkloadInstanceLogs = (props: Props): Result => {

  const {
    name,
    version,
    executionId = "",
    defaultErrorMessage = "Failed to get workload instance logs",
    ...otherProps
  } = props;

  const [logResults, setLogResults] = React.useState<WorkloadLogItem[]>([]);
  const [messageContains, setMessageContains] = React.useState<string>("");
  const [startDate, setStartDate] = React.useState<string>("");
  const [endDate, setEndDate] = React.useState<string>("");
  const [logLevel, setLogLevel] = React.useState<LogLevelType>(LogLevelType.NOT_SPECIFIED);
  const [limit, setLimit] = React.useState(100);

  const selectedStartDate = React.useMemo(() =>
    isEmptyString(startDate) ? null : new Date(startDate), [startDate]);
  const selectedEndDate = React.useMemo(() =>
    isEmptyString(endDate) ? null : new Date(endDate), [endDate]);

  const WorkloadManagerClient = useWorkloadManagerClient();

  const params = React.useMemo<SearchInstanceLogParams>(() => {
    return { messageContains, startDate, endDate, logLevel, limit };
  }, [messageContains, startDate, endDate, logLevel, limit]);

  const makeApiRequest = React.useCallback((accessToken, next) =>
    WorkloadManagerClient.getWorkloadInstanceLogsApi(name, version, executionId, next, params),
    [WorkloadManagerClient, name, version, executionId]);

  const [{ successResponse, ...baseModel }, { reset: baseReset, refresh: baseRefresh, ...baseActions }] =
    usePaginatedApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const updatedLogResults = React.useMemo<WorkloadLogItem[]>(() => {
    const { messages = [] } = successResponse || {};
    return messages.map(message => WorkloadLogItem.from(message));
  }, [successResponse]);

  const reset = React.useCallback(() => {
    setLogResults([]);
    baseReset();
  }, [baseReset, setLogResults]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [baseRefresh, reset]);

  const updateStartDate = React.useCallback((date?: Date | null) => {
    setStartDate(date ? formatLocalDateToUtc(date) : "");
    refresh();
  }, [setStartDate, refresh]);

  const updateEndDate = React.useCallback((date?: Date | null) => {
    setEndDate(date ? formatLocalDateToUtc(date) : "");
    refresh();
  }, [setEndDate, refresh]);

  const updateMessageContains = React.useCallback((state) => {
    setMessageContains(state);
    refresh();
  }, [setMessageContains, refresh]);

  const updateLogLevel = React.useCallback((state) => {
    setLogLevel(state);
    refresh();
  }, [setLogLevel, refresh]);

  const updateLimit = React.useCallback((lim) => {
    setLimit(lim);
    refresh();
  }, [setLimit, refresh]);

  React.useEffect(() => {
    setLogResults(currentLogResults => currentLogResults.concat(updatedLogResults));
  }, [setLogResults, updatedLogResults]);

  const items = React.useMemo(
    () => logResults.length ? logResults : updatedLogResults,
    [logResults, updatedLogResults]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    items,
    limit,
    logLevel,
    messageContains,
    startDate: selectedStartDate,
    endDate: selectedEndDate,
  }), [
    baseModel,
    items,
    limit,
    logLevel,
    messageContains,
    startDate,
    endDate,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    setLimit: updateLimit,
    setStartDate: updateStartDate,
    setEndDate: updateEndDate,
    setLogLevel: updateLogLevel,
    setMessageContains: updateMessageContains,
    reset,
    refresh,
  }), [
    baseActions,
    updateLimit,
    updateStartDate,
    updateEndDate,
    updateLogLevel,
    updateMessageContains,
    reset,
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetWorkloadInstanceLogs;
