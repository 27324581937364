import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  SubmitApiRequestView,
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
  ThemeProvider,
} from "@components";
import { DataSetRequestWizardStep } from "../reducers/dataSetRequestWizard";
import {
  CREATE_DATA_SET_REQUEST_STEPS as DEFAULT_STEPS,
  CREATE_DATA_SET_REQUEST_STEPS_LABEL as DEFAULT_STEP_LABELS,
} from "../selectors/dataSetRequestWizard";
import DataSetRequestState from "../model/DataSetRequestState";
import styles from "./styles";
import theme from "./theme";

export interface Model extends SubmitApiRequestViewModel<DataSetRequestState, DataSetRequestWizardStep> {
  stepLabels?: { [key: string]: string };
}

export interface Actions extends SubmitApiRequestViewActions<DataSetRequestState, DataSetRequestWizardStep> {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataSetRequestWizard = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    currentState = DataSetRequestState.EMPTY,
    defaultState = DataSetRequestState.EMPTY,
    snackbarId = "data-set-request-wizard",
    errorTitle = "Create Data Set Failed",
    saveButtonLabel = "Submit",
    steps = DEFAULT_STEPS,
    stepLabels = DEFAULT_STEP_LABELS,
    mapStepToLabel = React.useCallback(step => stepLabels[step] || null, [stepLabels]),
    children,
    ...otherProps
  } = props;

  return (
    <ThemeProvider theme={theme}>
      <SubmitApiRequestView
        {...otherProps}
        className={classnames("dataSetRequestWizard", className, classes.container)}
        snackbarId={snackbarId}
        errorTitle={errorTitle}
        saveButtonLabel={saveButtonLabel}
        steps={steps}
        currentState={currentState}
        defaultState={defaultState}
        mapStepToLabel={mapStepToLabel}
      >
        {children}
      </SubmitApiRequestView>
    </ThemeProvider>
  );
});

export default DataSetRequestWizard;
