import React from "react";
import Policy from "@data/Policy";
import Fab from "@components/fab";
import classnames from "classnames";
import JsonView from "@components/json-view";
import JsonEditor from "@components/json-editor";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import { clickHandler, isValidJson, noop } from "@util";
import DeleteItemDialog from "@components/delete-item-dialog";
import ConfirmationDialog from "@components/confirmation-dialog";
import RequiredFieldLabel, { RequiredFieldLabelState } from "@components/required-field-label";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface WorkloadPolicyModel {
  className?: string;
  accountId: string;
  title?: string;
  workloadName?: string;
  policyTemplate?: Policy;
  policy?: Policy;
}

export interface WorkloadPolicyActions {
  setPolicy?: (policy: Policy) => void;
  removePolicy?: () => void;
}

type Model = WorkloadPolicyModel;
type Actions = WorkloadPolicyActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const WorkloadPolicy = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    accountId,
    title = "Workload Policy",
    workloadName = "",
    policyTemplate = new Policy({
      info: {
        name: `${workloadName || "workload"}-policy`,
        description: "Workload policy",
        path: "/",
        version: "2016-05-22",
      },
      statements: [
        {
          action: "",
          effect: "Allow",
          resource: `lrn:iot:aam::${accountId}:::`,
        },
      ],
    }),
    policy = new Policy({ ...policyTemplate.toJS() }),
    setPolicy = noop,
    removePolicy = () => setPolicy(Policy.EMPTY),
    children,
  } = props;

  const [json, setJson] = React.useState(JSON.stringify(policy.toJS(), null, "  "));

  const [pendingPolicy, setPendingPolicy] = React.useState(Policy.EMPTY);

  const [showEditor, setShowEditor] = React.useState(false);

  const [showRemovePolicyDialog, setShowRemovePolicyDialog] = React.useState(false);

  const showEmptyView = React.useMemo(() => !policy.isValid(), [policy]);

  const validJson = React.useMemo(() =>
    isValidJson(json), [json]);

  const validPolicyName = React.useMemo(() =>
    pendingPolicy.hasName(), [pendingPolicy]);

  const validPolicyStatements = React.useMemo(() =>
    pendingPolicy.hasStatements(), [pendingPolicy]);

  const continueButtonDisabled = React.useMemo(() =>
      !validJson || !validPolicyName || !validPolicyStatements,
    [validJson, validPolicyName, validPolicyStatements]);

  const policyNameRequiredFieldLabelState = React.useMemo<RequiredFieldLabelState>(() => {
    return validPolicyName ? RequiredFieldLabelState.SUCCESS : RequiredFieldLabelState.ERROR;
  }, [validPolicyName]);

  const policyStatementsRequiredFieldLabelState = React.useMemo<RequiredFieldLabelState>(() => {
    return validPolicyStatements ? RequiredFieldLabelState.SUCCESS : RequiredFieldLabelState.ERROR;
  }, [validPolicyStatements]);

  React.useEffect(() => {
    if (validJson) {
      setPendingPolicy(new Policy(JSON.parse(json)));
    }
  }, [validJson, json, setPendingPolicy]);

  return (
    <div className={classnames("workloadPolicy", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        <Typography className={classnames("title", classes.title)} variant="h4">
          {title}
        </Typography>
        {!showEmptyView && (
          <Fab
            className={classnames("deleteButton", classes.deleteButton)}
            onClick={clickHandler(() => setShowRemovePolicyDialog(true))}
          >
            <DeleteIcon className={classnames("deleteIcon", classes.deleteIcon)}/>
          </Fab>
        )}
      </div>
      <div
        className={classnames("content", classes.content, {
          "emptyView": showEmptyView,
          [classes.emptyView]: showEmptyView,
        })}
        onClick={() => setShowEditor(true)}
      >
        {showEmptyView && (
          <React.Fragment>
            <p className={classnames("emptyViewLabel", classes.emptyViewLabel)}>
              No policy attached to this workload
            </p>
            <p className={classnames("emptyViewLabel", classes.emptyViewLabel)}>
              Click <span className={classes.underlined}>here</span> to attach a policy
            </p>
          </React.Fragment>
        )}
        {!showEmptyView && (
          <JsonView
            className={classnames("jsonView", classes.jsonView)}
            fileName={`${policy.getName()}.json`}
            json={json}
          />
        )}
      </div>
      <DeleteItemDialog
        dialogClassName={classnames("removePolicyDialog", classes.removePolicyDialog)}
        dialogTitle="Remove workload policy"
        className={classnames("removePolicy", classes.removePolicy)}
        title="Are you sure you want to remove this workload policy?"
        open={showRemovePolicyDialog}
        confirm={() => {
          removePolicy();
          setShowRemovePolicyDialog(false);
          setJson(JSON.stringify(policyTemplate.toJS(), null, "  "));
        }}
        cancel={() => {
          setShowRemovePolicyDialog(false);
        }}
      />
      <ConfirmationDialog
        className={classnames("workloadPolicyEditorDialog", classes.workloadPolicyEditorDialog)}
        open={showEditor}
        maxWidth="md"
        title="Update Workload Policy"
        continueButtonLabel="Save"
        continueButtonDisabled={continueButtonDisabled}
        cancel={() => {
          setJson(JSON.stringify(policy.toJS(), null, "  "));
          setShowEditor(false);
        }}
        confirm={() => {
          setPolicy(pendingPolicy);
          setShowEditor(false);
        }}
      >
        <div className={classnames("workloadPolicyEditor", classes.workloadPolicyEditor)}>
          <div
            className={classnames("workloadPolicyRequiredFields", classes.workloadPolicyRequiredFields)}
          >
            <Typography
              className={classnames("workloadPolicyRequiredFieldsTitle", classes.workloadPolicyRequiredFieldsTitle)}
              variant="h2"
            >
              Policy Requirements
            </Typography>
            <RequiredFieldLabel
              className={classnames("name", "workloadPolicyRequiredField", classes.workloadPolicyRequiredField)}
              title="Policy Name"
              helperText="Policy name cannot be empty"
              state={policyNameRequiredFieldLabelState}
            />
            <RequiredFieldLabel
              className={classnames("statements", "workloadPolicyRequiredField", classes.workloadPolicyRequiredField)}
              title="Policy Statements"
              helperText="Policy must include at least one statement with an action, effect, and resource"
              state={policyStatementsRequiredFieldLabelState}
            />
          </div>
          <JsonEditor
            className={classnames("workloadPolicyJsonEditor", classes.workloadPolicyJsonEditor)}
            debounceChangePeriod={50}
            marginTop={false}
            json={json}
            setJson={setJson}
          />
        </div>
      </ConfirmationDialog>
      {children}
    </div>
  );
});

export default WorkloadPolicy;
