import { ActionType } from "@util/ReducerUtil";
import { getStringValue } from "@util/Functions";
import cleanLocalStorage from "./cleanLocalStorage";
import { AnyAction, Dispatch, Middleware } from "redux";
import LocalStorageClient from "@util/LocalStorageClient";
import {
  KEY_ACCESS_TOKEN_EXPIRY_TIME,
  KEY_ACCOUNT_ID,
  KEY_FAVORITES,
  KEY_IDENTITY_TYPE,
  KEY_LAST_MOUSE_MOVE,
  KEY_PRINCIPAL_ID,
  KEY_VERSION_CODE,
  KEY_CLOSED_MAINTENANCE_REMINDERS,
} from "./constants";

export const createLocalStorageMiddleware = (): Middleware =>
  () => (next: Dispatch) => (action: AnyAction) => {

    const { type, value = "" } = action;

    // Cast the value to a string in case the value is a number/boolean
    const persistedValue = getStringValue(value + "");

    if (type === ActionType.SET_VERSION_CODE) {
      LocalStorageClient.setItem(KEY_VERSION_CODE, persistedValue);
    } else if (type === ActionType.UPDATE_LAST_MOUSE_MOVED) {
      LocalStorageClient.setItem(KEY_LAST_MOUSE_MOVE, persistedValue);
    } else if (type === ActionType.UPDATE_AUTH_TOKEN_EXPIRY_TIME) {
      LocalStorageClient.setItem(KEY_ACCESS_TOKEN_EXPIRY_TIME, persistedValue);
    } else if (type === ActionType.SET_ACCOUNT_ID) {
      LocalStorageClient.setItem(KEY_ACCOUNT_ID, persistedValue);
    } else if (type === ActionType.SET_PRINCIPAL_ID) {
      LocalStorageClient.setItem(KEY_PRINCIPAL_ID, persistedValue);
    } else if (type === ActionType.SET_IDENTITY_TYPE) {
      LocalStorageClient.setItem(KEY_IDENTITY_TYPE, persistedValue);
    } else if (type === ActionType.SET_USER_FAVORITES) {
      LocalStorageClient.setItem(KEY_FAVORITES, persistedValue);
    } else if (type === ActionType.UPDATE_CLOSED_MAINTENANCE_REMINDERS) {
      LocalStorageClient.setItem(KEY_CLOSED_MAINTENANCE_REMINDERS, persistedValue);
    } else if (type === ActionType.RESET) {
      // Purge sensitive local storage if a RESET event occurs, which means the user is being logged out
      // The only data we want to keep around is the version code, which tells us the last version
      // of the app that was loaded in case we ever decide to change what kind of data is being
      // stored and this way we have a better understanding of exactly what data is stored.
      cleanLocalStorage();
    }

    return next(action);
  };

export default createLocalStorageMiddleware;
