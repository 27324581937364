import React from "react";
import { isValidInteger } from "@util";
import { useGetWorkload, useGetWorkloadCodeHead } from "@hooks";
import { getCreateWorkloadPath } from "./helpers";
import { CloneWorkloadRouteParams } from "./constants";
import { Redirect, useParams } from "react-router-dom";
import WaitForApiRequestView from "@components/wait-for-api-request-view";

export function CloneWorkloadWizardView() {

  const { name, version: versionParam } = useParams<CloneWorkloadRouteParams>();

  const version = React.useMemo(() => {
    return isValidInteger(versionParam) ? Number(versionParam) : -1;
  }, [versionParam]);

  const [model, { refresh: retry }] = useGetWorkload({ name, version });

  const {
    workload,
    showLoadingIndicator,
    errorMessage,
    errors,
    showErrorView,
    showAccessDenied,
    showNotFound,
    showSuccessView,
  } = model;

  // Skip this altogether for query type workloads
  const [
    {
      showLoadingIndicator: showLoadingIndicatorCodeHead,
      showSuccessView: showSuccessViewCodeHead,
      errorMessage: errorMessageCodeHead,
      showErrorView: showErrorViewCodeHead,
      showAccessDenied: showAccessDeniedCodeHead,
      errors: errorsCodeHead,
    },
    {
      refresh: fetchCode,
    },
  ] = useGetWorkloadCodeHead({ name, version, deferRequest: true });

  const codeUploaded = React.useMemo(() =>
    workload.isFunctionType() && showSuccessViewCodeHead, [workload, showSuccessViewCodeHead]);

  const readyToRedirect = React.useMemo(() =>
    workload.isQueryType() ? showSuccessView : (showSuccessView && !showLoadingIndicatorCodeHead),
    [workload, showSuccessView, showLoadingIndicatorCodeHead]);

  const redirect = React.useMemo(() => !readyToRedirect ? null : (
    <Redirect
      to={{
        pathname: getCreateWorkloadPath(),
        state: {
          data: {
            ...workload.toJS(),
            name: `${workload.getName()}-clone`,
            data: { inputs: [], outputs: [] },
          },
          code: {
            containsCode: codeUploaded,
            sourceName: workload.getName(),
            sourceVersion: workload.getVersion(),
          },
        },
      }}
    />
  ), [showSuccessView, showSuccessViewCodeHead, workload, codeUploaded]);

  React.useEffect(() => {
    if (showSuccessView && workload.isFunctionType()) {
      fetchCode();
    }
  }, [showSuccessView, workload, fetchCode]);

  return (
    <WaitForApiRequestView
      loadingMessage="Loading workload..."
      errorTitle="Failed to load workload"
      errorMessage={errorMessage || errorMessageCodeHead}
      errors={errors || errorsCodeHead}
      showLoadingIndicator={showLoadingIndicator || showLoadingIndicatorCodeHead}
      showErrorView={showErrorView || showErrorViewCodeHead}
      showAccessDenied={showAccessDenied || showAccessDeniedCodeHead}
      showNotFound={showNotFound}
      retry={retry}
    >
      {redirect}
    </WaitForApiRequestView>
  );
}

export default CloneWorkloadWizardView;
