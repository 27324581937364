import Color from "@Color";
import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    descriptionInput: {
      width: "100%",
    },
    textField: {
      color: Color.TEXT,
      fontSize: 16,
      height: "auto",
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
  });

export default styles;
