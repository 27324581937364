import ReleaseNote, { ReleaseNotePlatformDomain } from "@data/ReleaseNote";
import {
  BASE_URL,
  COMMON_SERVICES_RELEASE_NOTES_PATH,
  DATA_AND_ANALYTICS_RELEASE_NOTES_PATH,
  DEVICE_MANAGEMENT_RELEASE_NOTES_PATH,
  SECURITY_SERVICES_RELEASE_NOTES_PATH,
  SELF_SERVICE_TOOLS_RELEASE_NOTES_PATH,
} from "@modules/releaseNotes/constants";

const getBaseUri = (releaseNote: ReleaseNote) => {
  const platformDomain = releaseNote.getPlatformDomain();
  switch (platformDomain) {
    case ReleaseNotePlatformDomain.SELF_SERVICE_TOOLS:
      return SELF_SERVICE_TOOLS_RELEASE_NOTES_PATH;
    case ReleaseNotePlatformDomain.DATA_AND_ANALYTICS:
      return DATA_AND_ANALYTICS_RELEASE_NOTES_PATH;
    case ReleaseNotePlatformDomain.COMMON_SERVICES:
      return COMMON_SERVICES_RELEASE_NOTES_PATH;
    case ReleaseNotePlatformDomain.DEVICE_MANAGEMENT:
      return DEVICE_MANAGEMENT_RELEASE_NOTES_PATH;
    case ReleaseNotePlatformDomain.SECURITY_SERVICES:
      return SECURITY_SERVICES_RELEASE_NOTES_PATH;
    case ReleaseNotePlatformDomain.NONE:
    default:
      return BASE_URL;
  }
};

export const getPathToReleaseNote = (releaseNote: ReleaseNote) => {
  return `${getBaseUri(releaseNote)}/${releaseNote.getVersion()}`;
};

export const mapUriToPlatformDomain = (path: string) => {
  switch (path) {
    case SELF_SERVICE_TOOLS_RELEASE_NOTES_PATH:
      return ReleaseNotePlatformDomain.SELF_SERVICE_TOOLS;
    case DATA_AND_ANALYTICS_RELEASE_NOTES_PATH:
      return ReleaseNotePlatformDomain.DATA_AND_ANALYTICS;
    case COMMON_SERVICES_RELEASE_NOTES_PATH:
      return ReleaseNotePlatformDomain.COMMON_SERVICES;
    case DEVICE_MANAGEMENT_RELEASE_NOTES_PATH:
      return ReleaseNotePlatformDomain.DEVICE_MANAGEMENT;
    case SECURITY_SERVICES_RELEASE_NOTES_PATH:
    case BASE_URL:
    default:
      return ReleaseNotePlatformDomain.SECURITY_SERVICES;
  }
};
