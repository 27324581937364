import React from "react";
import { Vector } from "@data";
import classnames from "classnames";
import Radio from "@material-ui/core/Radio";
import Paper from "@material-ui/core/Paper";
import { TooltipIcon } from "@components/tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@components/text-field";
import Typography from "@material-ui/core/Typography";
import ShowPasswordIcon from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import RandomPasswordIcon from "@material-ui/icons/Autorenew";
import HidePasswordIcon from "@material-ui/icons/VisibilityOff";
import { formControlLabel, userInfo as styles } from "./styles";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Color, DropdownMenu } from "@components";
import { useAllFederationProviders } from "@hooks";
import { clickHandler, equalsIgnoreCase, formEventHandler, isEmptyString, noop } from "@util";

// Only show verbose loading message if request has been in progress for this long
const DELAYED_LOADING_MESSAGE_DELAY_MS = 1000 * 5;

const MFA_SUPPORTED = equalsIgnoreCase("true", process.env.REACT_APP_CREATE_USER_MFA_SUPPORTED);

const UserInfoFormControlLabel = withStyles(formControlLabel)(FormControlLabel);

export interface Model {
  userId?: string;
  userIdErrorMessage?: string;
  password?: string;
  passwordErrorMessage?: string;
  showPassword?: boolean;
  showPasswordTooltip?: string;
  hidePasswordTooltip?: string;
  mfaEnabled?: boolean;
  mfaSecret?: string;
  mfaSecretErrorMessage?: string;
  isPortalUser?: boolean;
  isFederatedUser?: boolean;
  selectedFederationProvider?: string;
  showLoadingIndicator?: boolean;
}

export interface Actions {
  setErrorMessage?: (errorMessage: string) => void;
  setUserId?: (userId: string) => void;
  setPassword?: (password: string) => void;
  setShowPassword?: (showPassword: boolean) => void;
  setMfaEnabled?: (mfaEnabled: boolean) => void;
  setMfaSecret?: (mfaSecret: string) => void;
  setIsPortalUser?: (isPortalUser: boolean) => void;
  setIsFederatedUser?: (isFederatedUser: boolean) => void;
  setFederationProviders?: (federationProviders: string[]) => void;
  setSelectedFederationProvider?: (selectedFederationProvider: string) => void;
  generateRandomPassword?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const UserInfo = withStyles(styles)((props: Props) => {

  const {
    classes,
    userId = "",
    userIdErrorMessage = "",
    password = "",
    passwordErrorMessage = "",
    showPassword = false,
    mfaEnabled = false,
    mfaSecret = "",
    mfaSecretErrorMessage = "",
    showLoadingIndicator = false,
    isPortalUser = true,
    isFederatedUser = true,
    selectedFederationProvider = "",
    setErrorMessage = noop,
    setUserId = noop,
    setPassword = noop,
    setShowPassword = noop,
    setMfaEnabled = noop,
    setMfaSecret = noop,
    setIsPortalUser = noop,
    setIsFederatedUser = noop,
    setFederationProviders = noop,
    setSelectedFederationProvider = noop,
    generateRandomPassword = noop,
  } = props;

  const formHelperTextProps = {
    className: classnames("formHelperText", classes.formHelperText),
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const inputProps = {
    className: classnames("textField", classes.textField),
    spellCheck: false,
  };

  const [{
    federationProviders,
    errorMessage: federationProvidersErrorMessage,
    showLoadingIndicator: isLoadingFederationProviders,
  }] = useAllFederationProviders();

  const domains = React.useMemo(() =>
    Array.from(new Set(federationProviders.map(federationProvider => federationProvider.getDomain()))),
    [federationProviders]);

  const [showDelayedLoadingMessage, setShowDelayedLoadingMessage] = React.useState(false);

  const [portalUserErrorMessage, setPortalUserErrorMessage] = React.useState("");

  const [federatedUserErrorMessage, setFederatedUserErrorMessage] = React.useState("");

  const [iotUserErrorMessage, setIotUserErrorMessage] = React.useState("");

  const onChangeIsPortalUser = React.useCallback(event => {
    if (event && event.target && event.target.value) {
      setIsPortalUser(event.target.value === "yes");
    }
  }, [setIsPortalUser]);

  const onChangeIsFederatedUser = React.useCallback(event => {
    if (event && event.target && event.target.value) {
      setIsFederatedUser(event.target.value === "yes");
    }
  }, [setIsFederatedUser]);

  const showHidePasswordTooltip = React.useMemo(() =>
    showPassword ? "Hide Password" : "Show Password", [showPassword]);

  const userIdErrorMessageHelperText = React.useMemo(() => {

    if (!isEmptyString(userIdErrorMessage)) {
      return userIdErrorMessage;
    }

    if (isPortalUser) {
      return portalUserErrorMessage;
    }

    if (isFederatedUser) {
      return federatedUserErrorMessage;
    }

    return iotUserErrorMessage;

  }, [
    userIdErrorMessage,
    isPortalUser,
    isFederatedUser,
    portalUserErrorMessage,
    federatedUserErrorMessage,
    iotUserErrorMessage,
  ]);

  const showUserIdErrorMessage = React.useMemo(() =>
    !isEmptyString(userIdErrorMessageHelperText), [userIdErrorMessageHelperText]);

  const ShowHidePasswordIcon = React.useMemo(() =>
    showPassword ? HidePasswordIcon : ShowPasswordIcon, [showPassword]);

  const showUserIdTextField = React.useMemo(() => {

    if (isPortalUser) {
      return true;
    }

    if (isFederatedUser) {
      return !isEmptyString(selectedFederationProvider);
    }

    return true;

  }, [isPortalUser, isFederatedUser, selectedFederationProvider]);

  React.useEffect(() => {

    if (!showLoadingIndicator) {
      setShowDelayedLoadingMessage(false);
      return () => noop;
    }

    // Wait 5 seconds before showing delayed loading message - in case of slow requests
    // to prevent user from accidentally thinking the request is hung, when it can sometimes
    // take up to 60 seconds for the user to be created; other times it can take 2 seconds.
    const timer = setTimeout(() =>
      setShowDelayedLoadingMessage(true), DELAYED_LOADING_MESSAGE_DELAY_MS);

    return () => clearTimeout(timer);

  }, [showLoadingIndicator, setShowDelayedLoadingMessage]);

  React.useEffect(() => {

    setPortalUserErrorMessage("");

    // Validate email address every 500ms if new user is a portal user
    const timer = setTimeout(() => {

      if (isPortalUser && userId.indexOf("@") >= 0 && !/^.*@(signify|cooperlighting).com$/.test(userId)) {
        setPortalUserErrorMessage("Only @signify.com email addresses are allowed for IoT Portal Users");
      }

    }, 500);

    return () => clearTimeout(timer);

  }, [userId, isPortalUser, setPortalUserErrorMessage]);

  React.useEffect(() => {

    setFederatedUserErrorMessage("");

    // Validate email address every 500ms if new user is a portal user
    const timer = setTimeout(() => {

      if (!isPortalUser && isFederatedUser && userId.indexOf("@") >= 0 &&
        !(new RegExp(`^.*@${selectedFederationProvider}$`)).test(userId)) {

        setFederatedUserErrorMessage(`Email address must end with @${selectedFederationProvider}`);
      }

    }, 500);

    return () => clearTimeout(timer);

  }, [userId, isPortalUser, isFederatedUser, selectedFederationProvider, setFederatedUserErrorMessage]);

  React.useEffect(() => {

    setIotUserErrorMessage("");

    // Validate email address every 500ms if new user is a portal user
    const timer = setTimeout(() => {

      if (!isPortalUser && !isFederatedUser && userId.indexOf("@") >= 0 &&
        domains.some(domain => new RegExp(`^.*@${domain}$`).test(userId))) {

        setIotUserErrorMessage(
          "This email address cannot be used because there is a federation provider registered for this domain");
      }

    }, 500);

    return () => clearTimeout(timer);

  }, [userId, isPortalUser, isFederatedUser, domains, setIotUserErrorMessage]);

  React.useEffect(() => {

    // Clear error whenever radio button value changes
    setIotUserErrorMessage("");
    setPortalUserErrorMessage("");

  }, [isPortalUser, setPortalUserErrorMessage]);

  React.useEffect(() => {

    // Clear error whenever radio button value changes
    setIotUserErrorMessage("");
    setFederatedUserErrorMessage("");

  }, [isFederatedUser, setFederatedUserErrorMessage]);

  React.useEffect(() => {

    // Pass the federation provider domains along to the parent component whenever they change
    setFederationProviders(domains);

  }, [domains, setFederationProviders]);

  React.useEffect(() => {

    // Pass the federation providers error message along to the parent component
    setErrorMessage(federationProvidersErrorMessage);

  }, [federationProvidersErrorMessage, setErrorMessage]);

  return (
    <div className={classnames("userInfo", classes.container)}>
      {showLoadingIndicator && (
        <React.Fragment>
          {showDelayedLoadingMessage && (
            <Paper
              className={classnames("loadingMessage", classes.loadingMessage)}
              elevation={5}
            >
              <Typography className={classes.loadingMessageLabel} variant="h1" color="inherit">
                Creating User... Please wait...
              </Typography>
              <Typography className={classes.loadingMessageLabel} variant="h4" color="inherit">
                This process can sometimes take up to 60 seconds
              </Typography>
              <Typography className={classes.loadingMessageLabel} variant="subtitle1" color="inherit">
                Please avoid reloading the page while this request is in progress to ensure
                that you receive confirmation that the user was created.
              </Typography>
            </Paper>
          )}
          <div className={classnames("modal", classes.modal)} />
        </React.Fragment>
      )}
      <Typography variant="h3" style={{ fontWeight: 300 }}>
        Does this user need to be able to login to the IoT Portal?
      </Typography>
      <Typography variant="subtitle1" style={{ margin: "16px 0 8px" }}>
        <b>NOTE: </b>IoT Portal users must be Signify employees with a valid <i>@signify.com</i> email address
      </Typography>
      <RadioGroup
        className={classnames("radioGroup", "isPortalUser", classes.radioGroup)}
        row={true}
      >
        <FormControlLabel
          className={classnames("radio", "yes", classes.radio)}
          label="Yes"
          control={(
            <Radio
              value="yes"
              checked={isPortalUser}
              onChange={onChangeIsPortalUser}
            />
          )}
        />
        <FormControlLabel
          className={classnames("radio", "no", classes.radio)}
          label="No"
          control={(
            <Radio
              value="no"
              checked={!isPortalUser}
              onChange={onChangeIsPortalUser}
            />
          )}
        />
      </RadioGroup>
      {!isPortalUser && (
        <React.Fragment>
          <Typography variant="h3" style={{ fontWeight: 300, margin: "16px 0 8px" }}>
            Will this user login using a 3rd-party federation provider? (eg: gmail.com)
          </Typography>
          <div className={classnames("federationProvidersContainer", classes.federationProvidersContainer)}>
            <RadioGroup
              className={classnames("radioGroup", "isFederatedUser", classes.radioGroup)}
              row={true}
            >
              <FormControlLabel
                className={classnames("radio", "yes", classes.radio)}
                label="Yes"
                control={(
                  <Radio
                    value="yes"
                    checked={isFederatedUser}
                    onChange={onChangeIsFederatedUser}
                  />
                )}
              />
              <FormControlLabel
                className={classnames("radio", "no", classes.radio)}
                label="No"
                control={(
                  <Radio
                    value="no"
                    checked={!isFederatedUser}
                    onChange={onChangeIsFederatedUser}
                  />
                )}
              />
            </RadioGroup>
            {isFederatedUser && (
              <div className={classnames("federationProvidersDropdown", classes.federationProvidersDropdown)}>
                {isLoadingFederationProviders && (
                  <Typography variant="subtitle1" style={{ fontWeight: 300 }}>
                    Loading Federation Providers...
                  </Typography>
                )}
                {!isLoadingFederationProviders && (
                  <DropdownMenu
                    className={classnames("federationProvidersDropdownMenu", classes.federationProvidersDropdownMenu)}
                    values={domains}
                    disabled={isLoadingFederationProviders}
                    selectedValue={selectedFederationProvider}
                    errorMessage={federationProvidersErrorMessage}
                    setSelectedValue={setSelectedFederationProvider}
                    emptyValueLabel={"Select the domain that this user will login with"}
                  />
                )}
              </div>
            )}
          </div>
        </React.Fragment>
      )}
      {!showUserIdTextField && isFederatedUser && domains.length === 0 && (
        <React.Fragment>
          <Typography variant="h6" style={{ fontWeight: 300, color: Color.RED, marginTop: 15 }}>
            Oops, there aren't any registered federation providers in this IoT account.
          </Typography>
        </React.Fragment>
      )}
      {showUserIdTextField && (
        <React.Fragment>
          <TextField
            className={classnames("userId", classes.textField, classes.userId)}
            type="text"
            variant="outlined"
            autoComplete="off"
            margin="none"
            fullWidth={true}
            autoFocus={true}
            label="Email address"
            name="userId"
            value={userId}
            disabled={showLoadingIndicator}
            placeholder={isPortalUser ? "Signify email address" : "User email address"}
            helperText={userIdErrorMessageHelperText}
            error={showUserIdErrorMessage}
            onChange={formEventHandler(setUserId)}
            FormHelperTextProps={{
              ...formHelperTextProps,
              className: classnames(formHelperTextProps.className, {
                "error": showUserIdErrorMessage,
              }),
              ...(!isPortalUser ? {} : {
                classes: {
                  ...formHelperTextProps.classes,
                  error: classes.portalUserFormHelperTextError,
                },
              }),
            }}
            InputLabelProps={inputLabelProps}
            InputProps={{
              inputProps,
              ...(!isPortalUser ? {} : ({
                ...(userId.indexOf("@") >= 0 ? {} : {
                  endAdornment: (
                    <InputAdornment position="end">
                      <label className={classnames("userIdEndAdornment", classes.userIdEndAdornment)}>
                        @signify.com
                      </label>
                    </InputAdornment>
                  ),
                }),
              })),
              ...(!isFederatedUser || isEmptyString(selectedFederationProvider) ? {} : ({
                ...(userId.indexOf("@") >= 0 ? {} : {
                  endAdornment: (
                    <InputAdornment position="end">
                      <label className={classnames("userIdEndAdornment", classes.userIdEndAdornment)}>
                        @{selectedFederationProvider}
                      </label>
                    </InputAdornment>
                  ),
                }),
              })),
            }}
          />
          {!isPortalUser && !isFederatedUser && (
            <React.Fragment>
              <div className={classnames("passwordContainer", classes.passwordContainer)}>
                <TextField
                  className={classnames("password", classes.textField, classes.password)}
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  autoComplete="off"
                  margin="none"
                  fullWidth={true}
                  label="Password"
                  name="userId"
                  value={password}
                  helperText={passwordErrorMessage}
                  disabled={showLoadingIndicator}
                  placeholder="User Password"
                  error={passwordErrorMessage.length > 0}
                  onChange={formEventHandler(setPassword)}
                  FormHelperTextProps={formHelperTextProps}
                  InputLabelProps={inputLabelProps}
                  InputProps={{
                    inputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className={classnames("passwordControls", classes.passwordControls)}>
                          <TooltipIcon
                            className={classnames(classes.showHidePasswordButton, {
                              "hidePasswordButton": showPassword,
                              "showPasswordButton": !showPassword,
                            })}
                            PopperProps={{
                              className: classnames("tooltipIconPopper", classes.tooltipIconPopper),
                            }}
                            onClick={clickHandler(() => setShowPassword(!showPassword))}
                            tooltipText={showHidePasswordTooltip}
                            icon={ShowHidePasswordIcon}
                            size={Vector.square(24)}
                            disableTouchRipple={false}
                          />
                          <TooltipIcon
                            className={classnames("randomPasswordButton", classes.randomPasswordButton)}
                            PopperProps={{
                              className: classnames("tooltipIconPopper", classes.tooltipIconPopper),
                            }}
                            onClick={clickHandler(generateRandomPassword)}
                            tooltipText="Generate random password"
                            icon={RandomPasswordIcon}
                            size={Vector.square(24)}
                            disableTouchRipple={false}
                          />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {MFA_SUPPORTED && (
                <React.Fragment>
                  <UserInfoFormControlLabel
                    className={classnames("mfaEnabled", classes.checkboxContainer)}
                    label="MFA Enabled?"
                    control={(
                      <Checkbox
                        className={classnames("checkbox", classes.checkbox)}
                        color="secondary"
                        disabled={showLoadingIndicator}
                        checked={mfaEnabled}
                        onChange={formEventHandler(() => setMfaEnabled(!mfaEnabled))}
                      />
                    )}
                  />
                  {mfaEnabled && (
                    <TextField
                      className={classnames("mfaSecret", classes.textField, classes.mfaSecret)}
                      type="text"
                      variant="outlined"
                      autoComplete="off"
                      margin="none"
                      fullWidth={true}
                      label="MFA Secret"
                      name="mfaSecret"
                      value={mfaSecret}
                      inputProps={inputProps}
                      helperText={mfaSecretErrorMessage}
                      disabled={showLoadingIndicator}
                      placeholder="Multi-Factor Authentication Token"
                      error={mfaSecretErrorMessage.length > 0}
                      onChange={formEventHandler(setMfaSecret)}
                      FormHelperTextProps={formHelperTextProps}
                      InputLabelProps={inputLabelProps}
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
});

export default UserInfo;
