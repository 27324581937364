import React from "react";
import useSecurityGroupServices, {
  UseSecurityGroupServicesActions,
  UseSecurityGroupServicesModel,
  UseSecurityGroupServicesProps,
} from "../use-security-group-services";

export interface UseAllSecurityGroupServicesProps extends UseSecurityGroupServicesProps {
}

export interface UseAllSecurityGroupServicesModel extends UseSecurityGroupServicesModel {
}

export interface UseAllSecurityGroupServicesActions extends UseSecurityGroupServicesActions {
}

type Props = UseAllSecurityGroupServicesProps;
type Model = UseAllSecurityGroupServicesModel;
type Actions = UseAllSecurityGroupServicesActions;
type Result = [Model, Actions];

export const useAllSecurityGroupServices = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to get all security group services",
    ...otherProps
  } = props;

  const [model, actions] = useSecurityGroupServices({ defaultErrorMessage, ...otherProps });

  const { nextPageToken } = model;

  const { loadMore } = actions;

  React.useEffect(() => {
    loadMore();
  }, [nextPageToken]);

  return [model, actions];
};

export default useAllSecurityGroupServices;
