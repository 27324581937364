import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { SchemaWizardActions } from "../actions/schemaWizard";
import { SchemaWizardSelectors } from "../selectors/schemaWizard";
import NameView, { Actions, Model } from "../components/NameView";

interface ContainerModel extends Model {
  hidden?: boolean;
}

type Props = ContainerModel & Actions;

const NameViewContainer = (props: Props) => {

  const { hidden, ...otherProps } = props;

  if (hidden) {
    return null;
  }

  return <NameView {...otherProps} />;
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => {

  // Just to be safe, verify the current json schema state is valid before proceeding
  if (!SchemaWizardSelectors.isJsonValid(state)) {
    return {
      showInvalidJsonError: true,
    };
  }

  // TODO: Current solution only supports a single error message. Need a more scalable solution.
  const errorMessage = SchemaWizardSelectors.getErrorMessage(state);
  const schemaNameError =
    !errorMessage || SchemaWizardSelectors.isTitleValid(state) ? "" : errorMessage;
  const schemaDescriptionError =
    !errorMessage || schemaNameError || SchemaWizardSelectors.isDescriptionValid(state) ? "" : errorMessage;

  return {
    hidden: !SchemaWizardSelectors.isNameViewSelected(state),
    schemaName: SchemaWizardSelectors.getTitle(state),
    schemaNameError,
    schemaNameDisabled: SchemaWizardSelectors.isEditModeActive(state),
    schemaDescription: SchemaWizardSelectors.getDescription(state),
    schemaDescriptionError,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setSchemaName: (title: string) => dispatch(SchemaWizardActions.setTitle(title)),
  setSchemaDescription: (description: string) => dispatch(SchemaWizardActions.setDescription(description)),
  ...ownProps,
});

export default connect<ContainerModel, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(NameViewContainer);
