import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { PolicyStatementsListColumn } from "@components/policy-statement-list";
import { listItem as styles } from "./styles";
import PaginatedList, {
  PaginatedListActions,
  PaginatedListModel,
} from "@components/paginated-list";
import { PolicyStatement } from "@data";

export const columns = [
  PolicyStatementsListColumn.ID,
  PolicyStatementsListColumn.ACTION,
  PolicyStatementsListColumn.RESOURCE,
  PolicyStatementsListColumn.EFFECT,
];

const StatementListItems = withStyles(styles)((props: WithStyles<typeof styles> & {
  statement: PolicyStatement,
  column: string,
}) => {

  const { classes, statement = PolicyStatement.EMPTY, column } = props;

  if ( !column || PolicyStatement.EMPTY.equals(statement) ) {
    return null;
  }

  switch (column) {
    case PolicyStatementsListColumn.ID:
      return (
        <div className={classnames("id", classes.listContainer)}>
          <label className={classnames("id", classes.label)}>{statement.id}</label>
        </div>
      );
    case PolicyStatementsListColumn.ACTION:
      return (
        <label className={classnames("action", classes.label)}>{statement.action}</label>
      );
    case PolicyStatementsListColumn.RESOURCE:
      return (
        <label className={classnames("resource", classes.label)}>{statement.resource}</label>
      );
    case PolicyStatementsListColumn.EFFECT:
      return (
        <label className={classnames("effect", classes.label)}>{statement.effect}</label>
      );
    default:
      return null;
  }
});

export type Model = PaginatedListModel<PolicyStatement>;

export interface Actions extends PaginatedListActions<PolicyStatement> {
  loadMore?: () => void;
  toggleSortOrder?: () => void;
  setSortedColumn?: (column: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const StatementsList = withStyles(styles)((props: Props) => {

  const {
    classes,
    loadMore,
    toggleSortOrder,
    setSortedColumn,
    ...remainingProps
  } = props;

  return(
      <div className={classnames("statements", classes.container)}>
        <h2 className={classnames("statementsTitle", classes.title)}>
          Implicit Permission Statements
        </h2>
        <PaginatedList
          { ...remainingProps }
          columns={columns}
          tableLayoutFixed={false}
          onClickLoadMore={loadMore}
          onClickColumn={setSortedColumn}
          onClickToggleSortOrder={toggleSortOrder}
          renderItem={(statement: PolicyStatement, column: string) => (
            <StatementListItems statement={statement} column={column} />
          )}
        />
      </div>
  );
});
