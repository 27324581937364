import React from "react";
import Policy from "@data/Policy";
import classnames from "classnames";
import { isValidJson, noop } from "@util";
import JsonEditor from "@components/json-editor";
import Typography from "@material-ui/core/Typography";
import BackdropLoadingView from "@components/backdrop-loading-view";
import RequiredFieldLabel, { RequiredFieldLabelState } from "@components/required-field-label";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import styles from "./styles";

export interface WorkloadPolicyEditorDialogModel extends PortalModuleDialogModel<Policy> {
  className?: string;
  accountId: string;
  workloadName?: string;
  policyTemplate?: Policy;
  policy?: Policy;
}

export interface WorkloadPolicyEditorDialogActions extends PortalModuleDialogActions<Policy> {
  setPolicy?: (policy: Policy) => void;
  save?: (policy: Policy) => void;
}

type Model = WorkloadPolicyEditorDialogModel;
type Actions = WorkloadPolicyEditorDialogActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const WorkloadPolicyEditorDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    accountId,
    workloadName = "",
    policyTemplate = new Policy({
      info: {
        name: `${workloadName || "workload"}-policy`,
        description: "Workload policy",
        path: "/",
        version: "2016-05-22",
      },
      statements: [
        {
          action: "",
          effect: "Allow",
          resource: `lrn:iot:aam::${accountId}:::`,
        },
      ],
    }),
    policy = new Policy({ ...policyTemplate.toJS() }),
    maxWidth = "md",
    title = "Update Workload Policy",
    continueButtonLabel = "Save",
    continueButtonDisabled = false,
    loading,
    setPolicy = noop,
    save = noop,
    confirm = save,
    cancel = noop,
    ...otherProps
  } = props;

  const [json, setJson] = React.useState(JSON.stringify(policy.toJS(), null, "  "));

  const validJson = React.useMemo(() =>
    isValidJson(json), [json]);

  const validPolicyName = React.useMemo(() =>
    policy.hasName(), [policy]);

  const validPolicyStatements = React.useMemo(() =>
    policy.hasStatements(), [policy]);

  const validPolicy = React.useMemo(() =>
      validJson && validPolicyName && validPolicyStatements,
    [validJson, validPolicyName, validPolicyStatements]);

  const disabled = React.useMemo(() =>
      continueButtonDisabled || !validPolicy,
    [continueButtonDisabled, validPolicy]);

  const policyNameRequiredFieldLabelState = React.useMemo<RequiredFieldLabelState>(() => {
    return validPolicyName ? RequiredFieldLabelState.SUCCESS : RequiredFieldLabelState.ERROR;
  }, [validPolicyName]);

  const policyStatementsRequiredFieldLabelState = React.useMemo<RequiredFieldLabelState>(() => {
    return validPolicyStatements ? RequiredFieldLabelState.SUCCESS : RequiredFieldLabelState.ERROR;
  }, [validPolicyStatements]);

  const onClickCancel = React.useCallback(() => {
    setJson(JSON.stringify(policy.toJS(), null, "  "));
    cancel();
  }, [setJson, cancel]);

  React.useEffect(() => {
    if (validJson) {
      setPolicy(new Policy(JSON.parse(json)));
    }
  }, [validJson, json, setPolicy]);

  return (
    <PortalModuleDialog
      {...otherProps}
      className={classnames("workloadPolicyEditorDialog", className, classes.dialog)}
      maxWidth={maxWidth}
      title={title}
      continueButtonLabel={continueButtonLabel}
      continueButtonDisabled={disabled}
      loading={loading}
      item={policy}
      cancel={onClickCancel}
      confirm={confirm}
    >
      <div className={classnames("workloadPolicyEditor", classes.content)}>
        <div
          className={classnames("workloadPolicyRequiredFields", classes.requiredFields)}
        >
          <Typography
            className={classnames("workloadPolicyRequiredFieldsTitle", classes.requiredFieldsTitle)}
            variant="h2"
          >
            Policy Requirements
          </Typography>
          <RequiredFieldLabel
            className={classnames("name", "workloadPolicyRequiredField", classes.requiredField)}
            title="Policy Name"
            helperText="Policy name cannot be empty"
            state={policyNameRequiredFieldLabelState}
          />
          <RequiredFieldLabel
            className={classnames("statements", "workloadPolicyRequiredField", classes.requiredField)}
            title="Policy Statements"
            helperText="Policy must include at least one statement with an action, effect, and resource"
            state={policyStatementsRequiredFieldLabelState}
          />
        </div>
        <JsonEditor
          className={classnames("workloadPolicyJsonEditor", classes.jsonEditor)}
          debounceChangePeriod={50}
          marginTop={false}
          json={json}
          setJson={setJson}
        />
        <BackdropLoadingView
          className={classnames("backdrop", classes.backdrop)}
          showProgressIndicator={loading}
          open={loading}
        />
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadPolicyEditorDialog;
