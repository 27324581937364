import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";

export interface UsersSchema extends BasePortalModuleSchema {
  nameFilter: string;
  reloadItemsRequested: boolean;
}

export const DEFAULT_STATE: UsersSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  showEmptyView: false,
  nameFilter: "",
  reloadItemsRequested: false,
});

export type UsersActionTypes = PortalModuleActionTypes<UsersSchema> & {
  FETCH_USERS_REQUEST: string;
  FETCH_USERS_SUCCESS: string;
  FETCH_USERS_FAILED: string;
};

export const ACTION_TYPES: UsersActionTypes = {
  ...createPortalModuleActionTypes<UsersSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    FETCH_USERS_REQUEST: `${MODULE_ID}/fetch_users_request`,
    FETCH_USERS_SUCCESS: `${MODULE_ID}/fetch_users_success`,
    FETCH_USERS_FAILED: `${MODULE_ID}/fetch_users_failed`,
  },
};

export const UsersReducer = combineReducers<UsersSchema>(
  createPortalModuleReducers<UsersSchema>(ACTION_TYPES, DEFAULT_STATE));

export default UsersReducer;
