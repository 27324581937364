import React from "react";
import { Namespace } from "@data";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { DeviceTypeWizardActions } from "../actions";
import { DeviceTypeWizardSelectors } from "../selectors";
import { equalsIgnoreCase, isEmptyString, noop } from "@util";
import NamespacesList, {
  NamespacesListActions,
  NamespacesListModel,
} from "@components/namespaces-list";
import { useNamespaces } from "@hooks";
import NamespaceView, { Actions, Model } from "../components/NamespaceView";

interface ContainerModel extends Model, NamespacesListModel {
  hidden?: boolean;
  accountId?: string;
  selectable?: boolean;
  selectedNamespaceName?: string;
}

interface ContainerActions extends Actions, NamespacesListActions {
  removeSelectedNamespace?: () => void;
  setSelectedNamespace?: (namespace: Namespace) => void;
}

type Props = ContainerModel & ContainerActions;

const NamespaceViewContainer = (props: Props) => {

  const {
    hidden,
    title,
    subtitle,
    accountId = "",
    selectable = true,
    selectedNamespaceName = "",
    setSelectedNamespace = noop,
    removeSelectedNamespace = noop,
    ...otherProps
  } = props;

  const [nameFilter, setNameFilter] = React.useState("");

  const [{namespaces, ...model}, actions] = useNamespaces();

  const selectedNamespace = React.useMemo(() => {
    if (!selectable || isEmptyString(selectedNamespaceName)) {
      return Namespace.EMPTY;
    }
    const selectedItem = namespaces.find(item => equalsIgnoreCase(selectedNamespaceName, item.getName()));
    return selectedItem || Namespace.EMPTY;
  }, [selectable, selectedNamespaceName, namespaces]);

  const items = React.useMemo(() =>
      Namespace.EMPTY.equals(selectedNamespace) ? namespaces : [selectedNamespace],
    [selectedNamespace, namespaces]);

  const setSelectedItems = React.useCallback((selectedItems: Namespace[]) => {

    const selectedItem = selectedItems.pop() || Namespace.EMPTY;

    if (isEmptyString(selectedItem.name) || selectedNamespace.name === selectedItem.name) {
      removeSelectedNamespace();
    } else {
      setSelectedNamespace(selectedItem);
    }
  }, [removeSelectedNamespace, setSelectedNamespace]);

  if (hidden) {
    return null;
  }

  return (
    <NamespaceView title={title} subtitle={subtitle}>
      <NamespacesList
        {...model}
        {...actions}
        {...otherProps}
        items={items}
        selectable={selectable}
        selectAllDisabled={true}
        noResultsLabel={`No namespaces found in account ${accountId}`}
        selectedNamespace={selectedNamespace}
        nameFilter={nameFilter}
        setNameFilter={setNameFilter}
        setSelectedItems={setSelectedItems}
        setSelectedNamespace={(namespace: Namespace) => setSelectedItems([namespace])}
      />
    </NamespaceView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  hidden: !DeviceTypeWizardSelectors.isNamespaceViewSelected(state),
  accountId: DeviceTypeWizardSelectors.getCurrentAccountId(state),
  selectable: !DeviceTypeWizardSelectors.isEditModeActive(state),
  selectedNamespaceName: DeviceTypeWizardSelectors.getNamespace(state),
  title: DeviceTypeWizardSelectors.getNamespaceViewTitle(state),
  subtitle: DeviceTypeWizardSelectors.getNamespaceViewSubtitle(state),
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  removeSelectedNamespace: () => dispatch(DeviceTypeWizardActions.updateNamespace()),
  setSelectedNamespace: (namespace: Namespace = Namespace.EMPTY) =>
    dispatch(DeviceTypeWizardActions.updateNamespace(namespace.name)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(NamespaceViewContainer);
