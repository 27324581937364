import { createStyles, StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const button: StyleRules<string> = {
  root: {
    padding: 0,
    height: 28,
    marginRight: "auto",
    justifyContent: "flex-start",
  },
  label: {
    color: Color.WHITE,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "28px",
    margin: 0,
    padding: 0,
    textTransform: "uppercase",
  },
};

export const styles = () =>
  createStyles({
    icon: {
      color: Color.WHITE,
      width: 7.4,
      height: 12,
      marginLeft: 0,
      marginRight: 8,
    },
  });

export default styles;
