import createTheme from "@material-ui/core/styles/createTheme";
import { themeOptions } from "@main/components/theme";
import Color from "@Color";

const { palette: basePalette = {} } = themeOptions;

const { primary: basePrimaryColors = {} } = basePalette;

export const theme = createTheme({
  ...themeOptions,
  palette: {
    ...basePalette,
    primary: {
      ...basePrimaryColors,
      main: Color.SIGNIFY_GREEN,
    },
  },
});

export default theme;
