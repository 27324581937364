import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import { multiFactorAuthView as styles } from "../styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { AuthenticatorRequirement } from "@data";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

export interface Model {
  title?: string;
  externalUser?: boolean;
  mfaRequired?: AuthenticatorRequirement;
}

export interface Actions {
  onChange?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const MultiFactorAuthentication = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Multi-factor Authentication",
    externalUser,
    mfaRequired = AuthenticatorRequirement.NONE,
    onChange = noop,
    children,
  } = props;

  const mapAuthenticationRequirementToOptionName = (value: AuthenticatorRequirement) => {
    switch (value) {
    case AuthenticatorRequirement.NONE:
      return "MFA NOT REQUIRED";
    case AuthenticatorRequirement.REQUIRED:
      return "MFA REQUIRED";
    default:
      return null;
    }
  };

  const authenticationRequirementItems = Object.values(AuthenticatorRequirement).map(value => {
    return (
      <MenuItem
        className={classnames("mfaRequirement")}
        key={value}
        value={value}
      >
        {mapAuthenticationRequirementToOptionName(value)}
      </MenuItem>
    );
  });

  return (
    <div className={classnames("multiFactorAuthentication", classes.container)}>
      <React.Fragment>
        <label className={classnames("title", classes.title)}>{title}</label>
      </React.Fragment>
      <FormControl className={classnames("formControl", classes.formControl)}>
        <Select
          className="mfaRequirementSelect"
          value={mfaRequired}
          onChange={onChange}
          disabled={externalUser}
        >
          {authenticationRequirementItems}
        </Select>
      </FormControl>
      {!externalUser && (
        <label className={classnames("subtitle", classes.subtitle)}>
        Determines whether the user is allowed to login without multi-factor authentication
        </label>
      )}
      {externalUser && (
        <label className={classnames("subtitle", classes.subtitle)}>
          MFA requirement cannot be changed for an EXTERNAL (federated) user
        </label>
      )}
      {children}
    </div>
  );
});

export default MultiFactorAuthentication;
