import { AppSchema } from "@schemas";
import { getAuthToken } from "@main/selectors";
import { PolicyDetailsSelectors } from "../selectors";
import { PolicyClient, RestClientError } from "@network";
import { Policy, PolicyAttributes, PolicyInfo } from "@data";
import { open as openDeletePolicyDialog } from "@modules/deletePolicy/actions";
import { DEFAULT_STATE, ACTION_TYPES } from "../reducers";
import { createActions } from "@base";

export const {
  policy: setPolicy,
  nameFilter: setNameFilter,
  sortByColumn: setSortByColumn,
  sortOrderAscending: setSortOrderAscending,
  setErrorMessage,
  showNotFound,
  hideNotFound,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  LOAD_POLICY_REQUEST: loadPolicyRequest,
  LOAD_POLICY_SUCCESS: loadPolicySuccess,
  LOAD_POLICY_FAILED: loadPolicyFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setPolicy());
  dispatch(setNameFilter());
  dispatch(setSortByColumn());
  dispatch(setSortOrderAscending());
  return dispatch(baseReset());
};

export const showDeletePolicyDialog = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(openDeletePolicyDialog(PolicyDetailsSelectors.getPolicyName(getState())));

export const fetchPolicy = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const authToken = getAuthToken(state);
  const name = PolicyDetailsSelectors.getPolicyName(state);

  dispatch(showLoadingIndicator());
  dispatch(setErrorMessage());
  dispatch(hideNotFound());
  dispatch(hideAccessDenied());
  dispatch(loadPolicyRequest());

  return PolicyClient.getPolicy(authToken, name)
    .then((attrs: PolicyAttributes) => {

      dispatch(loadPolicySuccess());
      dispatch(setPolicy(new Policy(attrs).toJS()));
      dispatch(hideLoadingIndicator());
      return dispatch(hideEmptyView());

    }, (response: RestClientError) => {

      const { analytic, error = "Fetch policy details failed" } = response;

      dispatch(loadPolicyFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(hideLoadingIndicator());

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      if (response.status === 404) {
        dispatch(showNotFound());
      }

      return dispatch(hideEmptyView());
    });
};

export const refresh = () => (dispatch: any) => {
  dispatch(setSortByColumn());
  dispatch(setSortOrderAscending());
  return dispatch(fetchPolicy());
};

export const initialize = (name: string) => (dispatch: any) => {

  dispatch(reset());
  dispatch(setPolicy(new Policy({ info: new PolicyInfo({ name }).toJS() }).toJS()));
  return dispatch(fetchPolicy());
};
