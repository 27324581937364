import React from "react";
import { noop } from "@util";
import { styles } from "./styles";
import classnames from "classnames";
import { useSwitchWorkloadPrincipal } from "@hooks";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";

export interface WorkloadSwitchPrincipalDialogModel extends PortalModuleDialogModel<string> {
  name: string;
  version: number;
}

export interface WorkloadSwitchPrincipalDialogActions extends PortalModuleDialogActions<string> {
  close?: () => void;
}

type Props = WithStyles<typeof styles> & WorkloadSwitchPrincipalDialogModel & WorkloadSwitchPrincipalDialogActions;

export const WorkloadSwitchPrincipalDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    name,
    version,
    title = "Switch Release Principal",
    continueButtonLabel = "Save",
    close = noop,
    ...otherProps
  } = props;

  const [{ showLoadingIndicator, ...model }, { switchPrincipal, reset, ...actions }] =
    useSwitchWorkloadPrincipal({ name, version });

  const closeDialog = React.useCallback(() => {
    reset();
    close();
  }, [reset, close]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...model}
      {...actions}
      className={classnames("workloadSwitchPrincipalDialog", className)}
      title={title}
      loading={showLoadingIndicator}
      continueButtonLabel={continueButtonLabel}
      confirm={switchPrincipal}
      cancel={closeDialog}
    >
      <div className={classnames("switchPrincipal", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Are you sure you want to switch the principal of this workload?
          </label>
          <label className={classnames("subtitle", classes.subTitle)}>
            Data set access to the workloads data set inputs and outputs is required.
          </label>
          <label className={classnames("subtitle", classes.subTitle)}>
            NOTE: The release principal will be set to your user principal
          </label>
          <label className={classnames("name", classes.name)}>
            <b>Name:</b> {name}
            <span className={classnames("version", classes.version)}>
              <b>Version:</b> {version}
            </span>
          </label>
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadSwitchPrincipalDialog;
