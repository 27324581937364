import React from "react";
import classnames from "classnames";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { JsonSchemaPropertyDefaultValueType } from "../../../../data";
import { formEventHandler, noop } from "../../../../util";
import { styles } from "./styles";

export interface Model {
  className?: string;
  defaultValue?: JsonSchemaPropertyDefaultValueType;
  defaultValueError?: string;
  enumValues?: JsonSchemaPropertyDefaultValueType[];
  disabled?: boolean;
}

export interface Actions {
  setDefaultValue?: (defaultValue: JsonSchemaPropertyDefaultValueType) => void;
  clearDefaultValueError?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const EnumDefaultValueEditor = withStyles(styles)((props: Props) => {

  const {
    classes,
    className = "enumDefaultValueEditor",
    defaultValue = "",
    defaultValueError = "",
    enumValues = [],
    disabled = false,
    setDefaultValue = noop,
    clearDefaultValueError = noop,
  } = props;

  if (!Array.isArray(enumValues) || enumValues.length === 0) {
    return null;
  }

  const showError = defaultValueError.length > 0;

  const updateDefaultValue = (updatedDefaultValue: string) => {
    clearDefaultValueError();
    setDefaultValue(updatedDefaultValue);
  };

  return (
    <div className={classnames(className, classes.container)}>
      <FormControl
        className={classnames(classes.inputField, classes.defaultEnumValueContainer)}
        fullWidth={false}
        error={showError}
      >
        <InputLabel
          className={classes.defaultEnumValueLabel}
          shrink={true}
          error={showError}
        >
          Default Value (optional)
        </InputLabel>
        <Select
          className={classnames("defaultValue", classes.defaultEnumValue)}
          name="defaultEnumValue"
          value={defaultValue + ""}
          disabled={disabled}
          autoWidth={true}
          disableUnderline={true}
          displayEmpty={true}
          error={showError}
          onChange={formEventHandler(updateDefaultValue)}
        >
          <MenuItem
            key="default-value-none"
            value=""
            selected={defaultValue === ""}
          >
            <em>None</em>
          </MenuItem>
          {enumValues.map((value: string, index: number) => (
            <MenuItem
              key={`default-value-${index}`}
              selected={defaultValue === value}
              value={value}
            >
              {value + ""}
            </MenuItem>
          ))}
        </Select>
        {showError && (
          <FormHelperText className={classes.defaultEnumValueErrorLabel}>
            {defaultValueError}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
});

export default EnumDefaultValueEditor;
