import { connect } from "react-redux";
import { AppSchema } from "../../main/schemas";
import { RemovePropertySelectors } from "../selectors/removeProperty";
import { Model, RemoveProperty, } from "../components/RemoveProperty";

const mapStateToProps = (state: AppSchema): Model => ({
  property: RemovePropertySelectors.getProperty(state),
});

export default connect<Model, {}, Model>(
  mapStateToProps,
)(RemoveProperty);
