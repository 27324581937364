import { User } from "@data";
import { UsersListColumn } from "./UsersListColumn";
import { Comparator, compare, getStringValue, isEmptyString } from "@util";

export const getSearchResults = (items: User[], searchQuery: string) => {

  const users = items.slice();

  if (users.length === 0 || isEmptyString(searchQuery)) {
    return users;
  }

  const nameFilter = searchQuery.toLowerCase();

  return users.filter((user: User) =>
    user.getUserId().toLowerCase().indexOf(nameFilter) >= 0 ||
    getStringValue(user.getStatus()).toLowerCase().indexOf(nameFilter) >= 0);
};

const compareByName = (left: User, right: User, ascending: boolean) =>
  compare(left.getUserId(), right.getUserId(), ascending);

const compareByStatus = (left: User, right: User, ascending: boolean) =>
  compare(left.getStatus(), right.getStatus(), ascending);

export const createComparator =
  (sortedColumn: UsersListColumn, ascending: boolean): Comparator<User> =>
    (left: User, right: User) => {
      switch (sortedColumn) {
        case UsersListColumn.NAME:
          return compareByName(left, right, ascending);
        case UsersListColumn.STATUS:
          return compareByStatus(left, right, ascending);
        default:
          return 0;
      }
    };
