import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    account: {
      cursor: "inherit",
      color: Color.TEXT,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "14px",
      wordBreak: "break-word",
    },
    alias: {
      marginTop: 8,
      cursor: "inherit",
      color: Color.GREY3,
      fontSize: 11,
      fontWeight: 300,
      lineHeight: "12px",
      wordBreak: "break-word",
    },
  });

export default styles;
