import React from "react";
import { FederationProvider } from "@data";
import { GetFederationProvidersResponse } from "@network";
import { useFederationProvidersClient } from "../use-federation-providers-client";
import { useApiRequest, UseApiRequestActions, UseApiRequestModel, UseApiRequestProps } from "@hooks";

type SuccessResponse = GetFederationProvidersResponse;

export interface UseGetFederationProvidersProps extends Partial<UseApiRequestProps<SuccessResponse>> {
}

export interface UseGetFederationProvidersModel extends UseApiRequestModel<SuccessResponse> {
  items: FederationProvider[];
}

export interface UseGetFederationProvidersActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetFederationProvidersProps;
type Model = UseGetFederationProvidersModel;
type Actions = UseGetFederationProvidersActions;
type Result = [Model, Actions];

export const useGetFederationProviders = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to get federation providers",
    ...otherProps
  } = props;

  const FederationProvidersClient = useFederationProvidersClient();

  const makeApiRequest = React.useCallback(() =>
    FederationProvidersClient.getFederationProviders(),
    [FederationProvidersClient]);

  const [{ successResponse, ...baseModel }, { ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const items = React.useMemo(() => {
    const { providers = [] } = successResponse || {};
    return providers.map(attrs => new FederationProvider(attrs));
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    items,
  }), [
    baseModel,
    items,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
  }), [
    baseActions,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetFederationProviders;
