import { MODULE_PATH as BASE_URL } from "@modules/dataWorkloads/constants";

export const MODULE_ID = "data_workload_details";
export const MODULE_PATH = `${BASE_URL}/:name/version/:version`;
export const LEGACY_MODULE_PATH = `${BASE_URL}/:name`;
export const LATEST_VERSION_MODULE_PATH = `${BASE_URL}/:name/version/latest`;

export const VIEW_WORKLOAD_EXECUTION_HISTORY_REDIRECT = "viewWorkloadExecutions";

export interface RouteParams {
  name: string;
  version: string;
}

export { BASE_URL };
