import React from "react";
import { useDispatch } from "react-redux";
import { DeviceDataModel } from "@data";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getEnrollDevicePath } from "@modules/deviceEnrollmentWizard/helpers";
import DeviceValidationDetails from "./containers/DeviceValidationDetails";
import { setDeviceRequest } from "./actions";

export function DeviceValidationDetailsView() {

  const location = useLocation<any>();

  const history = useHistory();

  const dispatch = useDispatch();

  const { id: deviceId } = useParams<{ id: string }>();

  const {
    state: {
      deviceRequest: deviceRequestAttrs = DeviceDataModel.EMPTY.toJS(),
    } = {},
  } = location;

  const deviceRequest = React.useMemo(() =>
    new DeviceDataModel(deviceRequestAttrs), [deviceRequestAttrs]);

  const enrollDevice = React.useCallback(() =>
    history.push(getEnrollDevicePath()), [history]);

  React.useEffect(() => {
    if (deviceId === deviceRequest.getDeviceId()) {
      dispatch(setDeviceRequest(deviceRequest));
    }
  }, []);

  return (
    <DeviceValidationDetails
      deviceId={deviceId}
      enrollDevice={enrollDevice}
    />
  );
}

export default DeviceValidationDetailsView;
