import Color from "@Color";
import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    root: {
      "& .MuiSelect-root": {
        padding: "5px 0px 0px 5px",
      }
    },
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      fontFamily: "Roboto, sans-serif",
    },
    chip: {
      color: Color.TEXT,
      height: 28,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "12px",
      marginRight: 5,
      marginBottom: 5,
      background: "transparent",
      "& span": {
        marginLeft: 2,
        marginRight: 2,
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
    box: {
      display: "flex",
      flexWrap: "wrap"
    },
    inputField: {
      flex: "1 0 auto",
    },
    formHelperTextError: {
      fontSize: 12,
    },
    clearDropdown: {
      marginRight: 5,
    },
    dropdownMenuLabel: {
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    dropdownMenuHelperText: {
      width: "100%",
      opacity: "50%",
      marginLeft: 15
    },
    dropdownMenuErrorMessage: {
      fontSize: 12,
      lineHeight: "0",
      fontWeight: 400,
      padding: 0,
      margin: "8px 0",
    },
    dropdownMenuLoadingMessage: {
      fontSize: 12,
      fontStyle: "italic",
      lineHeight: "0",
      fontWeight: 400,
      padding: 0,
      margin: "8px 0",
    },
    dropdownMenu: {
      marginTop: 0,
      width: 130,
      fontStyle: "italic"
    },
    emptyValueLabel: {
    },
  });

export default styles;
