import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { isUserPrincipal } from "@main/selectors";
import { Actions, DashboardsList, Model } from "../components/DashboardsList";
import { setSortedColumn, toggleSortOrder } from "../actions";
import { DashboardColumn } from "../reducers";
import {
  getDashboards,
  getErrorMessage,
  getSearchQuery,
  getSortedColumn,
  isLoadingViewVisible,
  isSortOrderAscending,
} from "../selectors";
import { Dashboard } from "@data";

export interface ContainerModel extends Model {
  noResultsLabel?: string;
  dashboards?: Dashboard[];
  selectedDataSet?: Dashboard;
  filterSearchResults?: (dataSetInfo: Dashboard) => boolean;
}

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => {

  const {
    noResultsLabel,
    selectedDataSet,
    dashboards = getDashboards(state),
    filterSearchResults = () => true,
    ...otherProps
  } = ownProps;

  const items = dashboards.filter(filterSearchResults);
  const isEmpty = items.length === 0;
  const error = getErrorMessage(state);
  const searchQuery = getSearchQuery(state);
  const showErrorView = error.length > 0;
  const isLoading = isLoadingViewVisible(state);
  const showNoResultsView = isEmpty && !showErrorView && !isLoading;
  const showLoadingIndicator = isLoading && !showErrorView && (isEmpty || !searchQuery);

  return {
    items,
    error,
    noResultsLabel,
    showErrorView,
    showLoadingIndicator,
    showNoResultsView,
    selectedDataSet,
    sortedColumn: getSortedColumn(state),
    sortOrderAscending: isSortOrderAscending(state),
    requestAccessActionEnabled: isUserPrincipal(state),
    ...otherProps,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => {

  return {
    toggleSortOrder: () => dispatch(toggleSortOrder()),
    setSortedColumn: (column: DashboardColumn) => dispatch(setSortedColumn(column)),
    ...ownProps,
  };
};

export default connect<ContainerModel, Actions, ContainerModel & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardsList);
