import AppSchema from "@AppSchema";
import { connect } from "react-redux";
import { retryInitialize } from "../actions/editMode";
import { getErrorMessage } from "../selectors/dataSetRequestWizard";
import {
  EditModeInitializeErrorViewActions as Actions,
  EditModeInitializeErrorView,
  EditModeInitializeErrorViewModel as Model
} from "@components/edit-mode-initialize-error-view";

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  errorTitle: "Data Set Not Found",
  errorMessage: getErrorMessage(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  retry: () => dispatch(retryInitialize()),
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(EditModeInitializeErrorView);
