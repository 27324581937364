import { createSelector } from "reselect";
import { isEmptyString } from "@util/Functions";
import { MODULE_ID, SCHEMA_KEY } from "./constants";
import { createSelectors, Selector } from "@modules/base/createSelectors";
import { InviteUserRequest, UserProfileAttribute, UserProfileAttributesMap } from "@data";
import {
  DEFAULT_STATE,
  InviteUserSchema,
  NewUserWizardStep,
  UserProfileAttributeItems,
} from "./reducers";
import InviteUserState from "./models/InviteUserState";

export const {
  userId: getUserId,
  userIdErrorMessage: getUserIdErrorMessage,
  applicationId: getApplicationId,
  profile: getProfile,
  emailTemplateId: getEmailTemplateId,
  steps: getSteps,
  sendEmailCopy: getSendEmailCopy,
  currentStep: getCurrentStep,
  errorMessage: getErrorMessage,
  successMessage: getSuccessMessage,
  showEmptyView: isEmptyViewVisible,
  showAccessDenied: isAccessDeniedVisible,
  showLoadingIndicator: isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  getAccessToken,
} = createSelectors<InviteUserSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const isUserIdValid: Selector<boolean> = createSelector(
  getUserId, (userId: string) => !isEmptyString(userId));

export const isApplicationIdValid: Selector<boolean> = createSelector(
  getApplicationId, (applicationId: string) => !isEmptyString(applicationId));

export const isEmailTemplateValid: Selector<boolean> = createSelector(
  getEmailTemplateId, (emailTemplateId: string) => !isEmptyString(emailTemplateId));

// Selecting an email template is optional now
export const isSaveButtonEnabled: Selector<boolean> = createSelector(
  [isUserIdValid, isApplicationIdValid],
  (validUserId: boolean, validApplicationId: boolean) =>
    validUserId && validApplicationId);

export const getUserProfileAttributesMap: Selector<UserProfileAttributesMap> = createSelector(
  getProfile, (items: UserProfileAttributeItems) =>
    Object.keys(items).reduce((attrs: UserProfileAttributesMap, key: string) => {
      attrs[key] = new UserProfileAttribute(items[key]);
      return attrs;
    }, {}));

export const getUserProfileAttributes: Selector<UserProfileAttribute[]> = createSelector(
  getUserProfileAttributesMap, (attributes: UserProfileAttributesMap) =>
    Object.keys(attributes).map(key => attributes[key]));

export const isProfileValueUpdated: Selector<boolean> = createSelector(
  getUserProfileAttributes, (attrs: UserProfileAttribute[]) =>
    attrs.some(attr => !isEmptyString(attr.value)));

export const getInviteUserRequest: Selector<InviteUserRequest> = createSelector(
  [getUserProfileAttributesMap, getEmailTemplateId],
  (profile: UserProfileAttributesMap, emailTemplateId: string) =>
    InviteUserRequest.from(profile, emailTemplateId));

export const isUserInfoViewActive: Selector<boolean> = createSelector(
  getCurrentStep, (step: NewUserWizardStep) =>
    NewUserWizardStep.USER_INFO === step);

export const isEmailTemplateViewActive: Selector<boolean> = createSelector(
  getCurrentStep, (step: NewUserWizardStep) =>
    NewUserWizardStep.EMAIL_TEMPLATE === step);

export const isApplicationViewActive: Selector<boolean> = createSelector(
  getCurrentStep, (step: NewUserWizardStep) =>
    NewUserWizardStep.APPLICATION === step);

export const isReviewViewActive: Selector<boolean> = createSelector(
  getCurrentStep, (step: NewUserWizardStep) =>
    NewUserWizardStep.REVIEW === step);

export const isFirstStepSelected: Selector<boolean> = createSelector(
  [getSteps, getCurrentStep], (steps: NewUserWizardStep[], currentStep: NewUserWizardStep) =>
    steps.indexOf(currentStep) <= 0);

export const getPreviousStep: Selector<NewUserWizardStep> = createSelector(
  [getSteps, getCurrentStep], (steps: NewUserWizardStep[], currentStep: NewUserWizardStep) => {

    const index = steps.indexOf(currentStep);

    if (index <= 0) {
      return currentStep;
    }

    return steps[index - 1] || currentStep;
  });

export const getNextStep: Selector<NewUserWizardStep> = createSelector(
  [getSteps, getCurrentStep], (steps: NewUserWizardStep[], currentStep: NewUserWizardStep) => {

    const index = steps.indexOf(currentStep);

    if (index === -1) {
      return currentStep;
    }

    return steps[index + 1] || currentStep;
  });

export const getCompletedSteps: Selector<NewUserWizardStep[]> = createSelector(
  [getSteps, isUserIdValid, isApplicationIdValid, isEmailTemplateValid],
  (steps: NewUserWizardStep[],
   validUserId: boolean,
   validApplicationId: boolean,
   validEmailTemplate: boolean) => {

    if (steps.length === 0) {
      return [];
    }

    return steps.filter(step => {
      switch (step) {
        case NewUserWizardStep.USER_INFO:
          return validUserId;
        case NewUserWizardStep.APPLICATION:
          return validApplicationId;
        case NewUserWizardStep.EMAIL_TEMPLATE:
          return validEmailTemplate;
        default:
          return false;
      }
    });
  });

export const getDisabledSteps: Selector<NewUserWizardStep[]> = createSelector(
  [getSteps, isUserIdValid, isApplicationIdValid],
  (steps: NewUserWizardStep[],
    validUserId: boolean,
    validApplicationId: boolean) => {

    if (!validUserId) {
      return steps.filter(step => {
        switch (step) {
          case NewUserWizardStep.USER_INFO:
            return false;
          default:
            return true;
        }
      });
    }

    if (!validApplicationId) {
      return steps.filter(step => {
        switch (step) {
          case NewUserWizardStep.USER_INFO:
            return false;
          case NewUserWizardStep.APPLICATION:
            return false;
          default:
            return true;
        }
      });
    }

    return [];
  });

export const getInviteUserState: Selector<InviteUserState> = createSelector(
  [getUserId, getApplicationId, getEmailTemplateId, isProfileValueUpdated],
  (userId: string, applicationId: string, emailTemplateId: string, profileValueUpdated: boolean) =>
    new InviteUserState({ userId, applicationId, emailTemplateId, profileValueUpdated }));
