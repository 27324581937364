import { createSelector } from "reselect";
import { AppSchema } from "../../../main/schemas";
import { JsonSchemaPropertyType, JsonSchemaIntegerConstraints } from "../../../../data";

export const getMinimum = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.integerConstraints.minimum;
};

export const getMinimumError = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.integerConstraints.minimumError;
};

export const getExclusiveMinimum = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.integerConstraints.exclusiveMinimum;
};

export const getExclusiveMinimumError = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.integerConstraints.exclusiveMinimumError;
};

export const getMaximum = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.integerConstraints.maximum;
};

export const getMaximumError = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.integerConstraints.maximumError;
};

export const getExclusiveMaximum = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.integerConstraints.exclusiveMaximum;
};

export const getExclusiveMaximumError = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.integerConstraints.exclusiveMaximumError;
};

export const getMultipleOf = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.integerConstraints.multipleOf;
};

export const getMultipleOfError = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.integerConstraints.multipleOfError;
};

export const getConstraints: (state: AppSchema) => JsonSchemaIntegerConstraints = createSelector(
  [
    getMinimum,
    getExclusiveMinimum,
    getMaximum,
    getExclusiveMaximum,
    getMultipleOf,
  ],
  (minimum: string,
   exclusiveMinimum: string,
   maximum: string,
   exclusiveMaximum: string,
   multipleOf: string) => {

    return JsonSchemaIntegerConstraints.from({
      type: JsonSchemaPropertyType.INTEGER,
      minimum,
      exclusiveMinimum,
      maximum,
      exclusiveMaximum,
      multipleOf,
    });
  }
);

export const hasValidationErrors: (state: AppSchema) => boolean = createSelector(
  [
    getMinimumError,
    getExclusiveMinimumError,
    getMaximumError,
    getExclusiveMaximumError,
    getMultipleOfError,
  ],
  (minimumError: string,
   exclusiveMinimumError: string,
   maximumError: string,
   exclusiveMaximumError: string,
   multipleOfError: string) => {

    return minimumError.trim().length > 0 ||
      exclusiveMinimumError.trim().length > 0 ||
      maximumError.trim().length > 0 ||
      exclusiveMaximumError.trim().length > 0 ||
      multipleOfError.trim().length > 0;
  }
);
