import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { results as styles } from "./styles";

export interface Model {
  className?: string;
  title?: string;
  message?: string;
  showErrorMessage?: boolean;
  showSuccessMessage?: boolean;
}

type Props = WithStyles<typeof styles> & Model;

export const Results = withStyles(styles)((props: Props) => {

  const { classes, className, title, message, showErrorMessage, showSuccessMessage } = props;

  return (
    <div
      className={classnames("results", className, classes.container, {
        [classes.error]: showErrorMessage,
        [classes.success]: showSuccessMessage,
      })}
    >
      {title && <label className={classnames("title", classes.title)}>{title}</label>}
      <label className={classnames("message", classes.message)}>{message}</label>
    </div>
  );
});
