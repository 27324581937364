import React from "react";
import classnames from "classnames";
import { QualityReportTrend } from "@data";
import InfoIcon from "@material-ui/icons/Info";
import MinorIcon from "@material-ui/icons/Warning";
import Typography from "@material-ui/core/Typography";
import MajorIcon from "@material-ui/icons/ErrorOutline";
import BlockerIcon from "@material-ui/icons/ReportProblem";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import CriticalIcon from "@material-ui/icons/PriorityHighOutlined";
import QualityReportVulnerabilities from "@data/QualityReportVulnerabilities";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const getQualityReportTrend = (trendDelta: number) => {
  if (trendDelta === 0) {
    return QualityReportTrend.FLAT;
  } else {
    return trendDelta < 0
      ? QualityReportTrend.TRENDING_DOWN
      : QualityReportTrend.TRENDING_UP;
  }
};

export interface VulnerabilitiesModel {
  className?: string;
  title?: string;
  currentReport?: QualityReportVulnerabilities;
  previousReport?: QualityReportVulnerabilities;
  showHistoricalTrends?: boolean;
}

export interface VulnerabilitiesActions {
}

type Model = VulnerabilitiesModel;
type Actions = VulnerabilitiesActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const Vulnerabilities = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Open Vulnerabilities",
    currentReport = QualityReportVulnerabilities.EMPTY,
    previousReport = QualityReportVulnerabilities.EMPTY,
    showHistoricalTrends,
    children,
  } = props;

  const numBlockerVulnerabilities = React.useMemo(() =>
      currentReport.getNumBlockerVulnerabilities(),
    [currentReport]);

  const blockerVulnerabilitiesTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumBlockerVulnerabilities() - previousReport.getNumBlockerVulnerabilities()),
    [showHistoricalTrends, currentReport, previousReport]);

  const blockerVulnerabilitiesTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(blockerVulnerabilitiesTrendDelta),
    [showHistoricalTrends, blockerVulnerabilitiesTrendDelta]);

  const numCriticalVulnerabilities = React.useMemo(() =>
      currentReport.getNumCriticalVulnerabilities(),
    [currentReport]);

  const criticalVulnerabilitiesTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumCriticalVulnerabilities() - previousReport.getNumCriticalVulnerabilities()),
    [showHistoricalTrends, currentReport, previousReport]);

  const criticalVulnerabilitiesTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(criticalVulnerabilitiesTrendDelta),
    [showHistoricalTrends, criticalVulnerabilitiesTrendDelta]);

  const numMajorVulnerabilities = React.useMemo(() =>
      currentReport.getNumMajorVulnerabilities(),
    [currentReport]);

  const majorVulnerabilitiesTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumMajorVulnerabilities() - previousReport.getNumMajorVulnerabilities()),
    [showHistoricalTrends, currentReport, previousReport]);

  const majorVulnerabilitiesTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(majorVulnerabilitiesTrendDelta),
    [showHistoricalTrends, majorVulnerabilitiesTrendDelta]);

  const numMinorVulnerabilities = React.useMemo(() =>
      currentReport.getNumMinorVulnerabilities(),
    [currentReport]);

  const minorVulnerabilitiesTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumMinorVulnerabilities() - previousReport.getNumMinorVulnerabilities()),
    [showHistoricalTrends, currentReport, previousReport]);

  const minorVulnerabilitiesTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(minorVulnerabilitiesTrendDelta),
    [showHistoricalTrends, minorVulnerabilitiesTrendDelta]);

  const numInfoVulnerabilities = React.useMemo(() =>
      currentReport.getNumInfoVulnerabilities(),
    [currentReport]);

  const infoVulnerabilitiesTrendDelta = React.useMemo(() =>
      !showHistoricalTrends
        ? 0
        : (currentReport.getNumInfoVulnerabilities() - previousReport.getNumInfoVulnerabilities()),
    [showHistoricalTrends, currentReport, previousReport]);

  const infoVulnerabilitiesTrend = React.useMemo(() =>
      !showHistoricalTrends
        ? QualityReportTrend.NONE
        : getQualityReportTrend(infoVulnerabilitiesTrendDelta),
    [showHistoricalTrends, infoVulnerabilitiesTrendDelta]);

  const numVulnerabilities = React.useMemo(() =>
      numBlockerVulnerabilities +
      numCriticalVulnerabilities +
      numMajorVulnerabilities +
      numMinorVulnerabilities +
      numInfoVulnerabilities,
    [
      numBlockerVulnerabilities,
      numCriticalVulnerabilities,
      numMajorVulnerabilities,
      numMinorVulnerabilities,
      numInfoVulnerabilities,
    ]);

  const blockerSeverityBarClipPath = React.useMemo(() =>
      numVulnerabilities <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numBlockerVulnerabilities / numVulnerabilities)) * 100}% 0% 0%)`,
    [numVulnerabilities, numBlockerVulnerabilities]);

  const criticalSeverityBarClipPath = React.useMemo(() =>
      numVulnerabilities <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numCriticalVulnerabilities / numVulnerabilities)) * 100}% 0% 0%)`,
    [numVulnerabilities, numCriticalVulnerabilities]);

  const majorSeverityBarClipPath = React.useMemo(() =>
      numVulnerabilities <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numMajorVulnerabilities / numVulnerabilities)) * 100}% 0% 0%)`,
    [numVulnerabilities, numMajorVulnerabilities]);

  const minorSeverityBarClipPath = React.useMemo(() =>
      numVulnerabilities <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numMinorVulnerabilities / numVulnerabilities)) * 100}% 0% 0%)`,
    [numVulnerabilities, numMinorVulnerabilities]);

  const infoSeverityBarClipPath = React.useMemo(() =>
      numVulnerabilities <= 0
        ? "inset(0% 100% 0% 0%)"
        : `inset(0% ${(1 - (numInfoVulnerabilities / numVulnerabilities)) * 100}% 0% 0%)`,
    [numVulnerabilities, numInfoVulnerabilities]);

  return (
    <div className={classnames("vulnerabilities", className, classes.container)}>
      <Typography
        className={classnames("title", classes.title)}
        variant="h5"
      >
        {title}
      </Typography>
      <div className={classnames("content", classes.content)}>
        <div className={classnames("blockerVulnerabilities", classes.row)}>
          <BlockerIcon
            className={classnames("blockerIcon", classes.severityIcon, classes.blocker)}
          />
          <Typography className={classnames("severityLabel", classes.severityLabel)}>
            Blocker
          </Typography>
          <div
            className={classnames("blockerSeverityBar", classes.severityBar, classes.blocker)}
            style={{ clipPath: blockerSeverityBarClipPath }}
          />
          <Typography className={classnames("severityCountLabel", classes.severityCountLabel)}>
            {numBlockerVulnerabilities}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {blockerVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {blockerVulnerabilitiesTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {blockerVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {blockerVulnerabilitiesTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: blockerVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: blockerVulnerabilitiesTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: blockerVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({blockerVulnerabilitiesTrendDelta < 0 ? "" : "+"}{blockerVulnerabilitiesTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("criticalVulnerabilities", classes.row)}>
          <CriticalIcon
            className={classnames("criticalIcon", classes.severityIcon, classes.critical)}
          />
          <Typography className={classnames("severityLabel", classes.severityLabel)}>
            Critical
          </Typography>
          <div
            className={classnames("criticalSeverityBar", classes.severityBar, classes.critical)}
            style={{ clipPath: criticalSeverityBarClipPath }}
          />
          <Typography className={classnames("severityCountLabel", classes.severityCountLabel)}>
            {numCriticalVulnerabilities}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {criticalVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {criticalVulnerabilitiesTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {criticalVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {criticalVulnerabilitiesTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: criticalVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: criticalVulnerabilitiesTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: criticalVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({criticalVulnerabilitiesTrendDelta < 0 ? "" : "+"}{criticalVulnerabilitiesTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("majorVulnerabilities", classes.row)}>
          <MajorIcon className={classnames("majorIcon", classes.severityIcon, classes.major)}/>
          <Typography className={classnames("severityLabel", classes.severityLabel)}>
            Major
          </Typography>
          <div
            className={classnames("majorSeverityBar", classes.severityBar, classes.major)}
            style={{ clipPath: majorSeverityBarClipPath }}
          />
          <Typography className={classnames("severityCountLabel", classes.severityCountLabel)}>
            {numMajorVulnerabilities}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {majorVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {majorVulnerabilitiesTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {majorVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {majorVulnerabilitiesTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: majorVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: majorVulnerabilitiesTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: majorVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({majorVulnerabilitiesTrendDelta < 0 ? "" : "+"}{majorVulnerabilitiesTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("minorVulnerabilities", classes.row)}>
          <MinorIcon className={classnames("minorIcon", classes.severityIcon, classes.minor)}/>
          <Typography className={classnames("severityLabel", classes.severityLabel)}>
            Minor
          </Typography>
          <div
            className={classnames("minorSeverityBar", classes.severityBar, classes.minor)}
            style={{ clipPath: minorSeverityBarClipPath }}
          />
          <Typography className={classnames("severityCountLabel", classes.severityCountLabel)}>
            {numMinorVulnerabilities}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {minorVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {minorVulnerabilitiesTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {minorVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {minorVulnerabilitiesTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: minorVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: minorVulnerabilitiesTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: minorVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({minorVulnerabilitiesTrendDelta < 0 ? "" : "+"}{minorVulnerabilitiesTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("infoVulnerabilities", classes.row)}>
          <InfoIcon
            className={classnames("infoIcon", classes.severityIcon, classes.info)}
          />
          <Typography className={classnames("severityLabel", classes.severityLabel)}>
            Info
          </Typography>
          <div
            className={classnames("infoSeverityBar", classes.severityBar, classes.info)}
            style={{ clipPath: infoSeverityBarClipPath }}
          />
          <Typography className={classnames("severityCountLabel", classes.severityCountLabel)}>
            {numInfoVulnerabilities}
          </Typography>
          {showHistoricalTrends && (
            <React.Fragment>
              {infoVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP && (
                <TrendingUpIcon
                  className={classnames("trendingUpIcon", classes.trendingIcon, classes.trendingUp)}
                />
              )}
              {infoVulnerabilitiesTrend === QualityReportTrend.FLAT && (
                <TrendingFlatIcon
                  className={classnames("trendingFlatIcon", classes.trendingIcon, classes.trendingFlat)}
                />
              )}
              {infoVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN && (
                <TrendingDownIcon
                  className={classnames("trendingDownIcon", classes.trendingIcon, classes.trendingDown)}
                />
              )}
              {infoVulnerabilitiesTrendDelta !== 0.0 && (
                <Typography
                  className={classnames("deltaLabel", classes.trendingDeltaLabel, {
                    [classes.trendingUp]: infoVulnerabilitiesTrend === QualityReportTrend.TRENDING_UP,
                    [classes.trendingFlat]: infoVulnerabilitiesTrend === QualityReportTrend.FLAT,
                    [classes.trendingDown]: infoVulnerabilitiesTrend === QualityReportTrend.TRENDING_DOWN,
                  })}
                >
                  ({infoVulnerabilitiesTrendDelta < 0 ? "" : "+"}{infoVulnerabilitiesTrendDelta.toFixed(2)})
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames("footer", classes.footer)}>
          <Typography className={classnames("totalCountPrefix", classes.totalCountPrefix)}>
            {numVulnerabilities}
            <Typography
              className={classnames("totalCountSuffix", classes.totalCountSuffix)}
              component="span"
            >
              {numVulnerabilities === 1 ? "vulnerability" : "vulnerabilities"}
            </Typography>
          </Typography>
        </div>
      </div>
      {children}
    </div>
  );
});

export default Vulnerabilities;
