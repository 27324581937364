import { Record } from "immutable";
import { getStringValue } from "@util";

export enum AuthenticatorStatus {
  NONE = "",
  ACTIVATED = "ACTIVATED",
  CREATED = "CREATED"
}

export enum AuthenticatorType {
  NONE = "",
  TIME_BASED_ONE_TIME_PASSWORD = "TIME_BASED_ONE_TIME_PASSWORD"
}

export interface AuthenticatorAttributes {
  id: string;
  label: string;
  activationStatus: AuthenticatorStatus;
  type: AuthenticatorType;
}

export class Authenticator extends Record({
  id: "",
  label: "",
  activationStatus: AuthenticatorStatus.NONE,
  type: AuthenticatorType.NONE
}) implements AuthenticatorAttributes {

  public static EMPTY: Authenticator = new Authenticator();

  public readonly id: string;
  public readonly label: string;
  public readonly activationStatus: AuthenticatorStatus;
  public readonly type: AuthenticatorType;

  public getId(): string {
    return getStringValue(this.id);
  }

  public getStatus(): AuthenticatorStatus {
    return this.activationStatus;
  }

  public getLabel(): string {
    return getStringValue(this.label);
  }

  public getType(): AuthenticatorType {
    return this.type;
  }

}
