import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";

export interface CloseUserSchema extends BasePortalModuleSchema {
  userId: string;
  open: boolean;
  selfAuthorized: boolean;
}

export const DEFAULT_STATE: CloseUserSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  open: false,
  userId: "",
  selfAuthorized: false,
});

export type CloseUserActionTypes = PortalModuleActionTypes<CloseUserSchema> & {
  CLOSE_USER_REQUEST: string;
  CLOSE_USER_SUCCESS: string;
  CLOSE_USER_FAILED: string;
  CLOSE_USER_CREATE_ADMIN_NOTE_REQUEST: string;
  CLOSE_USER_CREATE_ADMIN_NOTE_SUCCESS: string;
  CLOSE_USER_CREATE_ADMIN_NOTE_FAILED: string;
};

export const ACTION_TYPES: CloseUserActionTypes = {
  ...createPortalModuleActionTypes<CloseUserSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    CLOSE_USER_REQUEST: `${MODULE_ID}/close_user_request`,
    CLOSE_USER_SUCCESS: `${MODULE_ID}/close_user_success`,
    CLOSE_USER_FAILED: `${MODULE_ID}/close_user_failed`,
    CLOSE_USER_CREATE_ADMIN_NOTE_REQUEST: `${MODULE_ID}/close_user_create_admin_note_request`,
    CLOSE_USER_CREATE_ADMIN_NOTE_SUCCESS: `${MODULE_ID}/close_user_create_admin_note_success`,
    CLOSE_USER_CREATE_ADMIN_NOTE_FAILED: `${MODULE_ID}/close_user_create_admin_note_failed`,
  },
};

export const CloseUserReducer = combineReducers<CloseUserSchema>(
  createPortalModuleReducers<CloseUserSchema>(ACTION_TYPES, DEFAULT_STATE));

export default CloseUserReducer;
