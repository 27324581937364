import createStyles from "@material-ui/core/styles/createStyles";
import ModuleIcon from "@material-ui/icons/Assignment";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const events = () =>
  createStyles({
    container: {
      color: primaryColor,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      marginTop: 25,
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: 0,
    },
    eventsList: {
      "& > .summaryViewContainer": {
        "& > .summary": {
          minHeight: "auto",
          "& > label": {
            margin: 0,
          },
        },
      },
    }
  });

export const comments = () =>
  createStyles({
    container: {
      marginTop: 25,
      "& .createCommentView": {
        marginTop: 20,
        marginBottom: 10,
      }
    },
  });

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      color: Color.TEXT,
    },
    alert: {
      marginBottom: 35,
    },
    alertTitle: {
      fontSize: 14,
      marginTop: 2,
    },
    alertDescription: {
      fontSize: 14,
      lineHeight: "21px",
      marginTop: 10,
      marginBottom: 0,
      maxWidth: 800,
    },
    uploadButton: {
      height: "auto",
      marginLeft: 15,
      marginRight: 15,
      padding: "8px 10px 6px",
    },
    uploadButtonLabel: {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "18px",
    },
    underlined: {
      marginLeft: 4,
      marginRight: 4,
      textDecoration: "underline",
    },
    customTab: {
      margin: "0 0 0 15px",
      "&:first-child": {
        marginLeft: 0,
      },
    },
    downloadButton: {
      color: Color.TEXT,
      backgroundColor: Color.WHITE,
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.54)",
      },
    },
  });

export default styles;
