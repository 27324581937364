import React from "react";
import classnames from "classnames";
import { TabLink as Tab } from "@components/tab";
import { Tabs as TabsComponent } from "@components/tabs";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  COMMON_SERVICES_RELEASE_NOTES_PATH,
  DATA_AND_ANALYTICS_RELEASE_NOTES_PATH,
  DEVICE_MANAGEMENT_RELEASE_NOTES_PATH,
  SECURITY_SERVICES_RELEASE_NOTES_PATH,
  SELF_SERVICE_TOOLS_RELEASE_NOTES_PATH,
} from "@modules/releaseNotes/constants";
import styles, { tabs } from "./styles";

const Tabs = withStyles(tabs)(TabsComponent);

export enum ReleaseNotesTab {
  SELF_SERVICE_TOOLS = "Self-Service Tools",
  DATA_AND_ANALYTICS = "Data & Analytics",
  COMMON_SERVICES = "Common Services",
  DEVICE_MANAGEMENT = "Device Management",
  SECURITY_SERVICES = "Security Services",
}

export interface ReleaseNotesTabsModel {
  className?: string;
  selectedTab?: ReleaseNotesTab;
}

export interface ReleaseNotesTabsActions {
}

type Model = ReleaseNotesTabsModel;
type Actions = ReleaseNotesTabsActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const ReleaseNotesTabs = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    selectedTab = ReleaseNotesTab.SECURITY_SERVICES,
  } = props;

  return (
    <Tabs
      className={classnames("releaseNotesTabs", className, classes.container)}
      value={selectedTab}
    >
      <Tab
        className={classnames("securityServicesTab", classes.tab)}
        to={SECURITY_SERVICES_RELEASE_NOTES_PATH}
        label={ReleaseNotesTab.SECURITY_SERVICES}
        value={ReleaseNotesTab.SECURITY_SERVICES}
      />
      <Tab
        className={classnames("commonServicesTab", classes.tab)}
        to={COMMON_SERVICES_RELEASE_NOTES_PATH}
        label={ReleaseNotesTab.COMMON_SERVICES}
        value={ReleaseNotesTab.COMMON_SERVICES}
      />
      <Tab
        className={classnames("deviceManagementTab", classes.tab)}
        to={DEVICE_MANAGEMENT_RELEASE_NOTES_PATH}
        label={ReleaseNotesTab.DEVICE_MANAGEMENT}
        value={ReleaseNotesTab.DEVICE_MANAGEMENT}
      />
      <Tab
        className={classnames("dataAndAnalyticsTab", classes.tab)}
        to={DATA_AND_ANALYTICS_RELEASE_NOTES_PATH}
        label={ReleaseNotesTab.DATA_AND_ANALYTICS}
        value={ReleaseNotesTab.DATA_AND_ANALYTICS}
      />
      <Tab
        className={classnames("portalTab", classes.tab)}
        to={SELF_SERVICE_TOOLS_RELEASE_NOTES_PATH}
        label={ReleaseNotesTab.SELF_SERVICE_TOOLS}
        value={ReleaseNotesTab.SELF_SERVICE_TOOLS}
      />
    </Tabs>
  );
});

export default ReleaseNotesTabs;
