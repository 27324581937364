import React from "react";
import { Vector } from "@data";
import classnames from "classnames";
import { clickHandler, noop } from "@util";
import Switch from "@material-ui/core/Switch";
import InfoIcon from "@material-ui/icons/Info";
import Backdrop from "@material-ui/core/Backdrop";
import { Button, TooltipIcon } from "@components";
import CircularProgress from "@components/circular-progress";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { deviceDataView as styles } from "../styles";

export interface Model {
  title?: string;
  description?: string;
  information?: string;
  actualViewSelected?: boolean;
  desiredViewSelected?: boolean;
  actualDataButtonLabel?: string;
  desiredDataButtonLabel?: string;
  populateAllSchema?: boolean;
  showBackDrop?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  setActualDataView?: () => void;
  setDesiredDataView?: () => void;
  setPopulateAllSchemaValue?: (value: boolean) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const  DeviceDataView = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Device Data",
    description = "Select a Schema to add data to this device",
    information = "",
    actualViewSelected = true,
    populateAllSchema = false,
    desiredViewSelected,
    actualDataButtonLabel = "Actual",
    desiredDataButtonLabel = "Desired",
    showBackDrop = false,
    setActualDataView,
    setDesiredDataView,
    setPopulateAllSchemaValue = noop,
    children
  } = props;

  const onChange = React.useCallback(() => {
    setPopulateAllSchemaValue(!populateAllSchema);
  }, [setPopulateAllSchemaValue, populateAllSchema]);

  return (
    <div className={classnames("deviceDataView", classes.container)}>
      <label className={classnames("title", classes.title)}>
        {title}
      </label>
      <label className={classes.subtitle}>
        {description}
      </label>
      <div className={classnames("controls", classes.controls)}>
        <Button
          className={classnames("actualButton", classes.actualButton)}
          color={actualViewSelected ? "primary" : "inherit"}
          onClick={clickHandler(setActualDataView)}
          variant="contained"
        >
          {actualDataButtonLabel}
        </Button>
        <Button
          className={classnames("desiredButton", classes.desiredButton)}
          color={desiredViewSelected ? "primary" : "inherit"}
          onClick={clickHandler(setDesiredDataView)}
          variant="contained"
        >
          {desiredDataButtonLabel}
        </Button>
      </div>
      <label className={classes.information}>
        {information}
      </label>
      <div className={classnames("populateAllSchemaSwitch", classes.switchContainer)}>
        <TooltipIcon
          className={classnames("infoIcon", classes.infoIcon)}
          PopperProps={{
            className: classnames("tooltipIconPopper", classes.tooltipIconPopper),
          }}
          tooltipText="Populate data for missing schemas if set to true"
          icon={InfoIcon}
          size={Vector.square(24)}
          disableTouchRipple={true}
        />
        <label
          className={classnames("populateAllSchemaLabel", classes.label)}
          onClick={clickHandler(() => setPopulateAllSchemaValue(!populateAllSchema))}
        >
          Populate All Schema
        </label>
        <Switch
          className={classnames("populateAllSchemaSwitch", classes.switch)}
          color="primary"
          checked={populateAllSchema}
          onChange={onChange}
        />
      </div>
      <Backdrop className={classnames("backdrop", classes.backdrop)} open={showBackDrop} />
      {showBackDrop && (
        <CircularProgress size={68} className={classes.progressIndicator}/>
      )}
      <div className={classnames(classes.contentWrapper)}>
        {children}
      </div>
    </div>
  );
});

export default DeviceDataView;
