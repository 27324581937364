import React from "react";
import classnames from "classnames";
import camelCase from "lodash/camelCase";
import Dialog from "@material-ui/core/Dialog";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@components";
import ContinueButton from "@components/continue-button";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import MakeRequestListItem, { MakeRequestListItemAttributes } from "./MakeRequestListItem";
import styles from "./styles";
import { isEmptyString, noop } from "@util";

export interface MakeRequestDialogModel {
  className?: string;
  title?: string;
  successMessage?: string;
  successViewEnabled?: boolean;
  infoMessage?: string;
  infoViewEnabled?: boolean;
  items?: MakeRequestListItemAttributes[];
  continueButtonLabel?: string;
  autoCloseEnabled?: boolean;
  autoCloseCountdownMs?: number;
}

export interface MakeRequestDialogActions {
  close?: () => void;
}

type Model = MakeRequestDialogModel;
type Actions = MakeRequestDialogActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const MakeRequestDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title,
    successMessage,
    successViewEnabled = !isEmptyString(successMessage),
    infoMessage,
    infoViewEnabled = !isEmptyString(infoMessage),
    items = [],
    continueButtonLabel: initialContinueButtonLabel = "Continue",
    autoCloseEnabled = true,
    autoCloseCountdownMs = 5 * 1000,
    close = noop,
    children,
  } = props;

  const [autoCloseCountdown, setAutoCloseCountdown] = React.useState(
    Math.round(autoCloseCountdownMs / 1000));

  const allRequestsComplete = React.useMemo(() =>
    items.length > 0 && items.every(({ showSuccessView: success, showErrorView: error }) =>
        success || error),
  [items]);

  const allRequestsSuccessful = React.useMemo(() =>
    items.length > 0 && items.every(({ showSuccessView: success }) => success),
  [items]);

  const showSuccessView = React.useMemo(() =>
    successViewEnabled && !isEmptyString(successMessage) && allRequestsSuccessful,
    [successViewEnabled, successMessage, allRequestsSuccessful]);

  const showInfoView = React.useMemo(() =>
      infoViewEnabled && !isEmptyString(infoMessage) && allRequestsComplete,
    [infoViewEnabled, infoMessage, allRequestsComplete]);

  const autoClose = React.useMemo(() =>
    autoCloseEnabled && allRequestsSuccessful,
    [autoCloseEnabled, allRequestsSuccessful]);

  const continueButtonLabel = React.useMemo(() => {
    if (!autoClose) {
      return initialContinueButtonLabel;
    } else {
      return `${initialContinueButtonLabel} (${autoCloseCountdown})`;
    }
  }, [initialContinueButtonLabel, autoClose, autoCloseCountdown]);

  React.useEffect(() => {

    if (!autoClose) {
      return noop;
    }

    if (autoCloseCountdown <= 0) {
      close();
      return noop;
    }

    const timer = setTimeout(() => {
      setAutoCloseCountdown(Math.max(0, autoCloseCountdown - 1));
    }, 1000);

    return () => clearTimeout(timer);

  }, [autoClose, autoCloseCountdown, setAutoCloseCountdown]);

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={classnames("makeRequestDialog", className, classes.container)}>
      <Dialog
        className={classnames("dialog", classes.dialog)}
        maxWidth="md"
        open={true}
      >
        <DialogContent
          className={classnames("dialogContent", classes.dialogContent)}
        >
          {title && (
            <Typography className={classnames("title", classes.title )} variant="h4">
              {title}
            </Typography>
          )}
          {showSuccessView && (
            <Alert className={classnames("successView", classes.successView )} severity="success">
              {successMessage}
            </Alert>
          )}
          {showInfoView && (
            <Alert className={classnames("infoView", classes.infoView )} severity="info">
              {infoMessage}
            </Alert>
          )}
          <div className={classnames("list", classes.list)}>
            {items.map(item => {
              const {id, ...itemProps} = item;
              return (
                <MakeRequestListItem
                  key={id}
                  id={id}
                  className={classnames("listItem", camelCase(id), classes.listItem)}
                  {...itemProps}
                />
              );
            })}
          </div>
          <div className={classnames("controls", classes.controls)}>
            <ContinueButton
              className={classnames("continueButton", classes.continueButton)}
              disabled={!allRequestsComplete}
              label={continueButtonLabel}
              onClick={close}
            />
          </div>
        </DialogContent>
      </Dialog>
      <Backdrop
        className={classnames("backdrop", classes.backdrop)}
        open={true}
      />
      {children}
    </div>
  );
});

export { MakeRequestListItemAttributes };

export default MakeRequestDialog;
