import React from "react";
import classnames from "classnames";
import { isEmptyString } from "@util";
import { SecurityServiceRegional } from "@data";
import { servicesView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import SelectServiceRegional, {
  SelectServiceActions,
  SelectServiceModel,
} from "@components/select-service-regional";

export interface SelectServiceViewModel extends SelectServiceModel {
  className?: string;
  serviceId?: string;
  excludedServiceIds?: string[];
}

export interface SelectServiceViewActions extends SelectServiceActions {
}

type Model = SelectServiceViewModel;
type Actions = SelectServiceViewActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const SelectServiceView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    serviceId,
    excludedServiceIds = [],
    items = [],
    selectedItems = React.useMemo(() =>
        isEmptyString(serviceId) ? [] : [new SecurityServiceRegional({ id: serviceId })],
      [serviceId]),
    showSearch = React.useMemo(() => selectedItems.length === 0, [selectedItems]),
    ...otherProps
  } = props;

  const validItems = React.useMemo(() =>
      items.filter((item: SecurityServiceRegional) => excludedServiceIds.indexOf(item.getId()) === -1),
    [items, excludedServiceIds]);

  return (
    <SelectServiceRegional
      {...otherProps}
      className={classnames("selectServiceView", className, classes.container)}
      items={validItems}
      selectedItems={selectedItems}
      showSearch={showSearch}
    />
  );
});

export default SelectServiceView;
