import { ManagedPolicy } from "@data";
import { ManagedPoliciesListColumn } from "./ManagedPoliciesListColumn";
import { Comparator, compare, isEmptyString } from "@util";

export const getSearchResults = (items: ManagedPolicy[], searchQuery: string) => {

  const policies = items.slice();

  if (policies.length === 0 || isEmptyString(searchQuery)) {
    return policies;
  }

  return policies.filter((policy: ManagedPolicy) =>
    policy.getPolicy().getName().indexOf(searchQuery) >= 0 ||
    policy.getDescription().indexOf(searchQuery) >= 0);
};

const compareByName = (left: ManagedPolicy, right: ManagedPolicy, ascending: boolean) =>
  compare(left.getPolicy().getName(), right.getPolicy().getName(), ascending);

const compareByDescription = (left: ManagedPolicy, right: ManagedPolicy, ascending: boolean) =>
  compare(left.getDescription(), right.getDescription(), ascending);

export const createComparator =
  (sortedColumn: ManagedPoliciesListColumn, ascending: boolean): Comparator<ManagedPolicy> =>
    (left: ManagedPolicy, right: ManagedPolicy) => {
      switch (sortedColumn) {
        case ManagedPoliciesListColumn.NAME:
          return compareByName(left, right, ascending);
        case ManagedPoliciesListColumn.DESCRIPTION:
          return compareByDescription(left, right, ascending);
        default:
          return 0;
      }
    };
