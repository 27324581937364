import { Record } from "immutable";
import { getStringValueNoTrim, isEmptyString } from "@util";

export interface EmailBodyAttributes {
  textBody: string;
  htmlBody: string;
  description: string;
  emailSubject: string;
}

export class EmailBody extends Record({
  textBody: "",
  htmlBody: "",
  description: "",
  emailSubject: "",
}) implements EmailBodyAttributes {

  public static EMPTY = new EmailBody();

  public readonly textBody: string;
  public readonly htmlBody: string;
  public readonly description: string;
  public readonly emailSubject: string;

  public getTextBody(): string {
    return getStringValueNoTrim(this.textBody);
  }

  public hasTextBody(): boolean {
    return !isEmptyString(this.getTextBody());
  }

  public getHtmlBody(): string {
    return getStringValueNoTrim(this.htmlBody);
  }

  public hasHtmlBody(): boolean {
    return !isEmptyString(this.getHtmlBody());
  }

  public getDescription(): string {
    return getStringValueNoTrim(this.description);
  }

  public hasDescription(): boolean {
    return !isEmptyString(this.getDescription());
  }

  public getEmailSubject(): string {
    return getStringValueNoTrim(this.emailSubject);
  }

  public hasEmailSubject(): boolean {
    return !isEmptyString(this.getEmailSubject());
  }
}

export default EmailBody;
