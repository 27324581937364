import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { open as openDeleteDataSetRequestDialog } from "@modules/deleteDataSetRequest/actions";
import {
  setFilterByCurrentUser,
  setFilterByPendingApproval,
  setNameFilter,
  setReloadItemsRequested,
} from "../actions";
import {
  getCurrentUserId,
  getNameFilter,
  isFilterByCurrentUserEnabled,
  isFilterByPendingApprovalEnabled,
  isReloadItemsRequested,
} from "../selectors";
import DataSetRequests, {
  DataSetRequestsActions as Actions,
  DataSetRequestsModel as Model,
} from "@components/data-set-requests";
import useGetDataSetRequests from "@hooks/dataSets/use-get-data-set-requests";

interface ContainerModel extends Model {
  reloadItemsRequested?: boolean;
}

interface ContainerActions extends Actions {
  requestReload?: () => void;
  onItemsReloaded?: () => void;
}

type Props = ContainerModel & ContainerActions;

const DataSetRequestsContainer = (props: Props) => {

  const {
    reloadItemsRequested,
    requestReload = noop,
    onItemsReloaded = noop,
    ...otherProps
  } = props;

  const [{dataSetRequests: items, ...model}, {refresh, ...actions}] =
    useGetDataSetRequests();

  React.useEffect(() => {
    if (reloadItemsRequested) {
      refresh();
      onItemsReloaded();
    }
  }, [reloadItemsRequested, refresh, onItemsReloaded]);

  if (reloadItemsRequested) {
    return null;
  }

  return (
    <DataSetRequests
      {...model}
      {...actions}
      {...otherProps}
      items={items}
      refresh={refresh}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  nameFilter: getNameFilter(state),
  filterByCurrentUser: isFilterByCurrentUserEnabled(state),
  currentUserId: getCurrentUserId(state),
  filterByPendingApproval: isFilterByPendingApprovalEnabled(state),
  reloadItemsRequested: isReloadItemsRequested(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setNameFilter: value => dispatch(setNameFilter(value)),
  setFilterByCurrentUser: value => dispatch(setFilterByCurrentUser(value)),
  setFilterByPendingApproval: value => dispatch(setFilterByPendingApproval(value)),
  deleteDataSetRequest: dataSetRequest =>
    dispatch(openDeleteDataSetRequestDialog(
      dataSetRequest.getAccountId(),
      dataSetRequest.getDataSetAlias(),
    )),
  requestReload: () => dispatch(setReloadItemsRequested(true)),
  onItemsReloaded: () => dispatch(setReloadItemsRequested(false)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DataSetRequestsContainer);
