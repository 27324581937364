import React from "react";
import {
  usePaginatedApiRequest,
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
  useWorkloadManagerClient
} from "@hooks";
import { WorkloadInstance, WorkloadInstanceState } from "@data";
import { GetWorkloadInstancesResponse, SearchInstancesParams } from "@network";
import { formatLocalDateToUtc, isEmptyString } from "@util";

type SuccessResponse = GetWorkloadInstancesResponse;

export interface UseGetWorkloadInstancesProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  workloadInstances?: WorkloadInstance[];
  initialStartDate?: string;
}

export interface UseGetWorkloadInstancesModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  workloadInstances: WorkloadInstance[];
  startDate?: Date | null;
  endDate?: Date | null;
  instanceState?: WorkloadInstanceState;
  limit?: number;
}

export interface UseGetWorkloadInstancesActions extends UsePaginatedApiRequestActions<SuccessResponse> {
  setStartDate: (date?: Date) => void;
  setEndDate: (date?: Date) => void;
  setInstanceState: (state: WorkloadInstanceState) => void;
  setLimit: (limit: number) => void;
}

type Props = UseGetWorkloadInstancesProps;
type Model = UseGetWorkloadInstancesModel;
type Actions = UseGetWorkloadInstancesActions;
type Result = [Model, Actions];

export const useGetWorkloadInstances = (props: Props): Result => {

  const {
    name,
    version,
    defaultErrorMessage = "Failed to get workload execution instances",
    workloadInstances: initialWorkloadInstances = [],
    initialStartDate = "",
    ...otherProps
  } = props;

  const [workloadInstances, setWorkloadInstances] = React.useState<WorkloadInstance[]>(initialWorkloadInstances);
  const [startDate, setStartDate] = React.useState<string>(initialStartDate);
  const [endDate, setEndDate] = React.useState<string>("");
  const [instanceState, setInstanceState] = React.useState<WorkloadInstanceState>();
  const [limit, setLimit] = React.useState(100);

  React.useEffect(() => setStartDate(initialStartDate), [initialStartDate]);

  const selectedStartDate = React.useMemo(() =>
    isEmptyString(startDate) ? null : new Date(startDate), [startDate]);
  const selectedEndDate = React.useMemo(() =>
    isEmptyString(endDate) ? null : new Date(endDate), [endDate]);

  const WorkloadManagerClient = useWorkloadManagerClient();
  const params = React.useMemo<SearchInstancesParams>(() => {
    return {
      startDate,
      endDate,
      stateFilter: instanceState,
      limit,
    };
  }, [startDate, endDate, instanceState, limit]);
  const makeApiRequest = React.useCallback(
    (accessToken, next) => WorkloadManagerClient.getWorkloadInstancesApi(name, version, next, params),
    [WorkloadManagerClient, name, version, params]);
  const [{ successResponse, nextPageToken, ...baseModel }, { reset: baseReset, refresh: baseRefresh, ...baseActions }] =
    usePaginatedApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const reset = React.useCallback(() => {
    setWorkloadInstances([]);
    baseReset();
  }, [baseReset, setWorkloadInstances]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [baseRefresh, reset]);

  const updateStartDate = React.useCallback((date?: Date | null) => {
    if (date) {
      setStartDate(formatLocalDateToUtc(date));
    } else {
      setStartDate("");
    }
    refresh();
  }, [setStartDate, refresh]);

  const updateEndDate = React.useCallback((date?: Date | null) => {
    if (date) {
      setEndDate(formatLocalDateToUtc(date));
    } else {
      setEndDate("");
    }
    refresh();
  }, [setEndDate, refresh]);

  const updateState = React.useCallback((state) => {
    setInstanceState(state);
    refresh();
  }, [setInstanceState, refresh]);

  const updateLimit = React.useCallback((lim) => {
    setLimit(lim);
    refresh();
  }, [setLimit, refresh]);

  const updatedWorkloadInstances = React.useMemo<WorkloadInstance[]>(() => {
    const { executions = [] } = successResponse ? successResponse : {};
    return executions.map(attrs => new WorkloadInstance(attrs));
  }, [successResponse]);

  React.useEffect(() => {
      setWorkloadInstances(currentWorkloadInstances => currentWorkloadInstances.concat(updatedWorkloadInstances));
  }, [updatedWorkloadInstances]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    workloadInstances,
    startDate: selectedStartDate,
    endDate: selectedEndDate,
    instanceState,
    limit,
  }), [
    baseModel,
    workloadInstances,
    selectedStartDate,
    selectedEndDate,
    instanceState,
    limit,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    setStartDate: updateStartDate,
    setEndDate: updateEndDate,
    setInstanceState: updateState,
    setLimit: updateLimit,
    reset,
    refresh,
  }), [
    baseActions,
    updateStartDate,
    updateEndDate,
    updateState,
    updateLimit,
    reset,
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetWorkloadInstances;
