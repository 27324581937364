import { ComparableItem } from "@components";
import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import DefaultIcon from "@material-ui/icons/Info";
import ColumnAttributes, { DEFAULT_COLUMN_ATTRIBUTES } from "./ColumnAttributes";

export interface ListViewItem<Item extends ComparableItem<Item>> {
  item: Item;
  rel?: string;
  target?: string;
  pathToDetailsView?: string;
  icon?: React.ComponentType<SvgIconProps> | null;
  columnAttributes: ColumnAttributes<Item>[];
}

export const DEFAULT_LIST_VIEW_ITEM: ListViewItem<any> = {
  item: {},
  rel: "",
  target: "",
  pathToDetailsView: "",
  icon: DefaultIcon,
  columnAttributes: [ DEFAULT_COLUMN_ATTRIBUTES ],
};

export default ListViewItem;
