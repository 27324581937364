import { StyleRules } from "@material-ui/core/styles/withStyles";
import { Color } from "../../../../components/styles";

export const rememberMeCheckbox: StyleRules<string> = {
  root: {
    color: "rgba(237, 243, 245, 0.5)",
    "&$checked": {
      color: "rgba(237, 243, 245, 0.5)",
    },
  },
  checked: {
    color: "rgba(237, 243, 245, 0.5)",
  },
};

export const rememberMeFormControlLabel: StyleRules<string> = {
  root: {
    marginTop: 15,
  },
  label: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "16px",
    color: Color.WHITE,
  },
};
