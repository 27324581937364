import React from "react";
import { noop } from "@util";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { usePortalSnackbar } from "@components/portal-snackbar-provider";
import {
  getErrorMessage,
  getSuccessMessage,
  isLoadingIndicatorVisible,
  isSuccessMessageVisible,
  isEmailTemplateValid,
} from "../selectors";
import { recoverPassword } from "../actions";
import PasswordRecovery , { Actions, Model } from "../components/PasswordRecovery";
import SelectEmailTemplate from "./SelectEmailTemplate";

interface ContainerModel extends Model {
  successMessage?: string;
}

interface ContainerActions extends Actions {
  onSuccess?: () => void;
}

type Props = ContainerModel & ContainerActions;

const PasswordRecoverContainer = (props: Props) => {

  const {
    successMessage = "",
    onSuccess = noop,
    ...otherProps
  } = props;

  const { errorMessage = "" } = otherProps;

  usePortalSnackbar("create-user", {
    errorMessage,
    successMessage,
    onSuccessMessageShown: onSuccess,
    successCallbackDelay: 500,
  });

  return (
    <PasswordRecovery {...otherProps}>
      <SelectEmailTemplate />
    </PasswordRecovery>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  successMessage: getSuccessMessage(state),
  errorMessage: getErrorMessage(state),
  showLoadingIndicator: isLoadingIndicatorVisible(state),
  showSuccessIndicator: isSuccessMessageVisible(state),
  saveButtonDisabled: !isEmailTemplateValid(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  save: () => dispatch(recoverPassword()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordRecoverContainer);
