import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { Application, ApplicationAuthenticationProtocol } from "@data";
import { getStringValue, isEmptyString, noop } from "@util";
import classnames from "classnames";
import { useAuthenticateApplication, AuthenticateApplicationOperation } from "@hooks";
import { TextFieldStyled } from "@components/text-field-styled";
import { DropdownMenu } from "@components/dropdown-menu";

export interface ApplicationAuthenticationDialogModel extends PortalModuleDialogModel<Application> {
  dialogClassName?: string;
  applicationId?: string;
  // Added just to reset the dialog
  operation: AuthenticateApplicationOperation;
}

export interface ApplicationAuthenticationDialogActions extends PortalModuleDialogActions<Application> {
}

type Props = WithStyles< typeof styles> &
  ApplicationAuthenticationDialogModel & ApplicationAuthenticationDialogActions;

export const ApplicationAuthenticationDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    dialogClassName,
    applicationId = "",
    operation = AuthenticateApplicationOperation.NONE,
    onSuccessMessageShown: onSuccess = noop,
    cancel,
    ...otherProps
  } = props;

  if (isEmptyString(applicationId) || operation === AuthenticateApplicationOperation.NONE) {
    return null;
  }

  const [secret, setSecret] = React.useState("");
  const [protocol, setProtocol] = React.useState(ApplicationAuthenticationProtocol.SHARED_SECRET_1);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const continueButtonDisabled = React.useMemo(() => secret.length < 8, [secret]);

  const information = React.useMemo(() => !isSuccess ?
    "Enter application secret to authenticate application" :
    "Application Authentication Success! The secret you entered is correct", [isSuccess]);

  const title = React.useMemo(() => !isSuccess ?
    "Authenticate Application" :
    "Authentication Success", [isSuccess]);

  const updateProtocol = React.useCallback((applicationProtocol: string) => {
    if (applicationProtocol === getStringValue(ApplicationAuthenticationProtocol.SHARED_SECRET_1)) {
      setProtocol(ApplicationAuthenticationProtocol.SHARED_SECRET_1);
    }
  } , [setProtocol]);

  const [ model, actions] = useAuthenticateApplication({
    applicationId,
    secret,
    protocol,
  });

  const { showLoadingIndicator, successMessage, ...otherModel } = model;
  const { authenticate, ...otherActions } = actions;

  const confirm = React.useCallback(() => {
    authenticate();
  }, [authenticate]);

  const onSuccessOperation = React.useCallback( () => {
    setIsSuccess(true);
  }, [setIsSuccess]);

  return (
    <React.Fragment>
      <PortalModuleDialog
        {...otherProps}
        {...otherModel}
        {...otherActions}
        className={classnames("applicationAuthenticationDialog", dialogClassName)}
        title={title}
        loading={showLoadingIndicator}
        continueButtonLabel="Authenticate"
        confirm={confirm}
        cancel={cancel}
        onClickCloseIcon={cancel}
        hideCancelButton={false}
        showCloseIconOnSuccess={true}
        continueButtonDisabled={continueButtonDisabled}
        onSuccessMessageShown={onSuccessOperation}
        successMessage={successMessage}
      >
        <div className={classnames("applicationOperation", classes.container)}>
          <label className={classnames("title", classes.title)}>
            {information}
          </label>
          {!isSuccess && (
            <label className={classnames("title", classes.title, classes.subtitle)}>
              Application secret is generated while creating application.
              If you do not have one, you can generate or set new application secret from application details page
            </label>
          )}
          <DropdownMenu
            className={classnames("applicationAuthenticationProtocol", classes.dropdownMenu)}
            hideEmptyValue={true}
            dropdownMenuLabel="Protocol"
            dropdownMenuHint="Authentication Protocol"
            dropdownMenuLabelClassName={classes.dropdownMenuLabel}
            values={[ApplicationAuthenticationProtocol.SHARED_SECRET_1]}
            selectedValue={protocol}
            setSelectedValue={updateProtocol}
          />
          <TextFieldStyled
            className={classnames("applicationSecretTextField", classes.applicationSecretTextField)}
            name={"secret"}
            label="Secret"
            value={secret}
            fullWidth={true}
            autoFocus={true}
            placeholder={"Enter Application Secret"}
            helperText={"Enter application secret to verify if you have the correct secret"}
            setValue={setSecret}
          />
        </div>
      </PortalModuleDialog>
    </React.Fragment>
  );
});

export default ApplicationAuthenticationDialog;
