import React from "react";
import { SecurityServiceRegional } from "@data";
import { GetSecurityGroupIdentitiesResponse } from "@network";
import {
  usePaginatedApiRequest,
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
  useSecurityGroupsClient,
} from "@hooks";

type SuccessResponse = GetSecurityGroupIdentitiesResponse;

export interface UseSecurityGroupServicesProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
  groupName?: string;
  limit?: number;
}

export interface UseSecurityGroupServicesModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  services: SecurityServiceRegional[];
}

export interface UseSecurityGroupServicesActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseSecurityGroupServicesProps;
type Model = UseSecurityGroupServicesModel;
type Actions = UseSecurityGroupServicesActions;
type Result = [Model, Actions];

export const useSecurityGroupServices = (props: Props = {}): Result => {

  const {
    limit,
    groupName = "",
    defaultErrorMessage = "Failed to get security group services",
    ...otherProps
  } = props;

  const SecurityGroupsClient = useSecurityGroupsClient();

  const [services, setServices] = React.useState<SecurityServiceRegional[]>([]);

  const makeApiRequest = React.useCallback((accessToken, next) =>
      SecurityGroupsClient.getSecurityGroupServices(groupName, next, limit),
    [SecurityGroupsClient, groupName, limit]);

  const [
    { successResponse, ...baseModel },
    { reset: baseReset, refresh: baseRefresh, ...baseActions },
  ] = usePaginatedApiRequest<SuccessResponse>({
    ...otherProps,
    defaultErrorMessage,
    makeApiRequest,
  });

  const updatedServices = React.useMemo(() => {
    const { identityReferences: items = [] } = successResponse || {};
    return items.map(({ id }) => new SecurityServiceRegional({ id, serviceId: id }));
  }, [successResponse]);

  const reset = React.useCallback(() => {
    setServices([]);
    baseReset();
  }, [setServices, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successResponse,
    services,
  }), [
    baseModel,
    successResponse,
    services,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    setServices(currentServices => currentServices.concat(updatedServices));
  }, [updatedServices, setServices]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useSecurityGroupServices;
