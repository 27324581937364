import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { getAuthToken, isLoggedIn, isServicePrincipal } from "@main/selectors";
import useKeepServiceSessionAlive, {
  UseKeepServiceSessionAliveModel as Model,
  UseKeepServiceSessionAliveActions as Actions,
} from "@hooks/session/use-keep-service-session-alive";

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const KeepServiceSessionAliveContainer = (props: Props) => {

  const {
    loggedIn,
    servicePrincipalActive,
    accessToken,
  } = props;

  useKeepServiceSessionAlive({
    loggedIn,
    servicePrincipalActive,
    accessToken,
  });

  return null;
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  loggedIn: isLoggedIn(state),
  servicePrincipalActive: isServicePrincipal(state),
  accessToken: getAuthToken(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(KeepServiceSessionAliveContainer);
