export enum CronMonth {
  JANUARY = "January",
  FEBRUARY = "February",
  MARCH = "March",
  APRIL = "April",
  MAY = "May",
  JUNE = "June",
  JULY = "July",
  AUGUST = "August",
  SEPTEMBER = "September",
  OCTOBER = "October",
  NOVEMBER = "November",
  DECEMBER = "December",
}

export enum CronDay {
  SUNDAY = "Sunday",
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  SATURDAY = "Saturday",
}

export enum CronWeek {
  FIRST = "First",
  SECOND = "Second",
  THIRD = "Third",
  FOURTH = "Fourth",
}

export enum CronOption {
  DAY_OF_WEEK = "day_of_week",
  DAY_OF_MONTH = "day_of_month",
  DAY_OF_EXACT_WEEK = "day_of_exact_week",
}