import React from "react";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useWorkloadManagerClient } from "@hooks";

type SuccessResponse = void;

export interface UseDeleteActionLabelProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  etag: string;
  actionLabel: string;
}

export interface UseDeleteActionLabelModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseDeleteActionLabelActions {
  deleteActionLabel: () => void;
  reset: () => void;
}

type Props = UseDeleteActionLabelProps;
type Model = UseDeleteActionLabelModel;
type Actions = UseDeleteActionLabelActions;
type Result = [Model, Actions];

export const useDeleteActionLabel = (props: Props): Result => {

  const {
    name = "",
    version = 1,
    etag,
    actionLabel = "",
    defaultErrorMessage = "Failed to delete the action label",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.deleteActionLabel(name, version, etag, actionLabel),
    [WorkloadManagerClient, name, version, etag, actionLabel]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: deleteActionLabel, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Delete Successful" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    deleteActionLabel,
    reset
  }), [
    deleteActionLabel,
    reset
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDeleteActionLabel;
