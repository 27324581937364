import { Module } from "@data";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { isCertificateAuthoritiesModulesEnabled } from "@util";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./containers/CertificateAuthorities";

export const certificateAuthoritiesModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Authorities",
  title: "Authorities Management",
  className: "certificateAuthoritiesModule",
  primaryColor,
  icon,
  view,
  backButton: null,
  disabled: !isCertificateAuthoritiesModulesEnabled(),
});

export default certificateAuthoritiesModule;
