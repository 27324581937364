import { createActions } from "@base/createActions";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";

export const {
  nameFilter: setNameFilter, // Should not be reset in between page changes
  filterByCurrentAccount: setFilterByCurrentAccount, // Should not be reset in between page changes
  filterByPendingApproval: setFilterByPendingApproval, // Should not be reset in between page changes
  reloadItemsRequested: setReloadItemsRequested,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const {baseReset} = privateActions;

const reset = () => (dispatch: any) => {
  dispatch(setNameFilter());
  dispatch(setFilterByCurrentAccount());
  dispatch(setFilterByPendingApproval());
  dispatch(setReloadItemsRequested());
  return dispatch(baseReset());
};

export const reloadItems = () => (dispatch: any) => {
  return dispatch(setReloadItemsRequested(true));
};

export const onMount = () => (dispatch: any) => {
  // Since the hook fetches users when mounted, make sure we don't refresh twice
  return dispatch(setReloadItemsRequested(false));
};

export const initialize = () => (dispatch: any) => {
  return dispatch(reset());
};
