import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  DataAccessRequest,
  DataAccessRequestAttributes,
  DataApprovalRequest,
  DataApprovalRequestAttributes,
  DataSetRequest,
  DataSetRequestAttributes,
  RequestCommentAttributes,
} from "@data";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base/createReducers";

export interface DataApprovalRequestDetailsSchema extends BasePortalModuleSchema {
  dataApprovalRequest: DataApprovalRequestAttributes;
  dataSetRequest: DataSetRequestAttributes;
  dataAccessRequest: DataAccessRequestAttributes;
  comments: RequestCommentAttributes[];
  comment: string;
  createCommentBox: boolean;
  commentErrorMessage: string;
  commentLoadingIndicator: boolean;
}

export const DEFAULT_STATE: DataApprovalRequestDetailsSchema = createPortalModuleDefaultState({
  dataApprovalRequest: Object.freeze({ ...DataApprovalRequest.EMPTY.toJS() }),
  dataSetRequest: Object.freeze({ ...DataSetRequest.EMPTY.toJS() }),
  dataAccessRequest: Object.freeze({ ...DataAccessRequest.EMPTY.toJS() }),
  comments: [],
  comment: "",
  createCommentBox: false,
  commentErrorMessage: "",
  commentLoadingIndicator: false,
});

type DataApprovalRequestDetailsActionTypes = PortalModuleActionTypes<DataApprovalRequestDetailsSchema> & {
  FETCH_DATA_APPROVAL_REQUEST_DETAILS_REQUEST: string;
  FETCH_DATA_APPROVAL_REQUEST_DETAILS_SUCCESS: string;
  FETCH_DATA_APPROVAL_REQUEST_DETAILS_FAILURE: string;
  FETCH_DATA_APPROVAL_REQUEST_COMMENTS_REQUEST: string;
  FETCH_DATA_APPROVAL_REQUEST_COMMENTS_SUCCESS: string;
  FETCH_DATA_APPROVAL_REQUEST_COMMENTS_FAILURE: string;
  CREATE_DATA_APPROVAL_REQUEST_COMMENT_REQUEST: string;
  CREATE_DATA_APPROVAL_REQUEST_COMMENT_SUCCESS: string;
  CREATE_DATA_APPROVAL_REQUEST_COMMENT_FAILED: string;
  FETCH_TERMS_AND_CONDITIONS_REQUEST: string;
  FETCH_TERMS_AND_CONDITIONS_SUCCESS: string;
  FETCH_TERMS_AND_CONDITIONS_FAILURE: string;
};

export const ACTION_TYPES: DataApprovalRequestDetailsActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<DataApprovalRequestDetailsSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    FETCH_DATA_APPROVAL_REQUEST_DETAILS_REQUEST: `${MODULE_ID}/fetch_data_approval_request_details_request`,
    FETCH_DATA_APPROVAL_REQUEST_DETAILS_SUCCESS: `${MODULE_ID}/fetch_data_approval_request_details_success`,
    FETCH_DATA_APPROVAL_REQUEST_DETAILS_FAILURE: `${MODULE_ID}/fetch_data_approval_request_details_failure`,
    FETCH_DATA_APPROVAL_REQUEST_COMMENTS_REQUEST: `${MODULE_ID}/fetch_data_approval_request_comments_request`,
    FETCH_DATA_APPROVAL_REQUEST_COMMENTS_SUCCESS: `${MODULE_ID}/fetch_data_approval_request_comments_success`,
    FETCH_DATA_APPROVAL_REQUEST_COMMENTS_FAILURE: `${MODULE_ID}/fetch_data_approval_request_comments_failure`,
    CREATE_DATA_APPROVAL_REQUEST_COMMENT_REQUEST: `${MODULE_ID}/create_data_approval_request_comment_request`,
    CREATE_DATA_APPROVAL_REQUEST_COMMENT_SUCCESS: `${MODULE_ID}/create_data_approval_request_comment_success`,
    CREATE_DATA_APPROVAL_REQUEST_COMMENT_FAILED: `${MODULE_ID}/create_data_approval_request_comments_failure`,
    FETCH_TERMS_AND_CONDITIONS_REQUEST: `${MODULE_ID}/fetch_terms_and_conditions_request`,
    FETCH_TERMS_AND_CONDITIONS_SUCCESS: `${MODULE_ID}/fetch_terms_and_conditions_success`,
    FETCH_TERMS_AND_CONDITIONS_FAILURE: `${MODULE_ID}/fetch_terms_and_conditions_failure`,
  },
});

export const DataApprovalRequestDetailsReducer = combineReducers<DataApprovalRequestDetailsSchema>(
  createPortalModuleReducers<DataApprovalRequestDetailsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DataApprovalRequestDetailsReducer;
