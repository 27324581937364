import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import { DropdownIcon } from "@icons";
import Button from "@components/button";
import { openMenuButton as styles } from "./styles";
import { IconButton } from "@components/icon-button";
import IconButtonIcon from "@material-ui/icons/MoreVert";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface OpenMenuButtonProps {
  className?: string;
  disabled?: boolean;
  disableRipple?: boolean;
  variant?: "button" | "icon";
  label?: string;
  icon?: React.ComponentType<SvgIconProps>;
  onClick?: (event: React.MouseEvent<{}>) => void;
}

type Props = WithStyles<typeof styles> & OpenMenuButtonProps;

export const OpenMenuButton = withStyles(styles)(React.forwardRef((props: Props, ref?: React.Ref<any>) => {

  const {
    classes,
    className,
    disabled,
    disableRipple,
    variant = "button",
    label = "Actions",
    icon: OpenMenuIcon = IconButtonIcon,
    onClick = noop,
  } = props;

  if (variant === "button") {
    return (
      <Button
        ref={ref}
        className={classnames("openMenuButton", className, classes.button)}
        color="primary"
        variant="contained"
        disabled={disabled}
        disableRipple={disableRipple}
        onClick={onClick}
      >
        {label}
        <DropdownIcon className={classes.buttonIcon} />
      </Button>
    );
  }

  return (
    <IconButton
      ref={ref}
      className={classnames("openMenuButton", className, classes.iconButton)}
      disableRipple={disableRipple}
      onClick={onClick}
    >
      <OpenMenuIcon className={classes.icon} />
    </IconButton>
  );
}));

export default OpenMenuButton;
