import React from "react";
import { SecurityGroupBulkRequest } from "@data";
import ReviewViewColumns from "./ReviewViewColumns";
import SortedSearchResultsList, {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import ReviewViewListItem from "./ReviewViewListItem";

const DEFAULT_REVIEW_LIST_COLUMNS: ReviewViewColumns[] = [
  ReviewViewColumns.NAME,
  ReviewViewColumns.STATUS,
  ReviewViewColumns.ERRORS,
];

export type ReviewViewListModel = SortedSearchResultsListModel<SecurityGroupBulkRequest, ReviewViewColumns>;

export type ReviewViewListActions = SortedSearchResultsListActions<SecurityGroupBulkRequest, ReviewViewColumns>;

type Props = ReviewViewListModel & ReviewViewListActions;

export const ReviewViewList = (props: Props) => {

  const {
    className = "reviewViewList",
    columns = DEFAULT_REVIEW_LIST_COLUMNS,
    noResultsLabel = "No Security Groups selected",
    summaryViewQuantities = {
      other: "security groups",
      one: "security group",
    },
    items = [],
    ...otherProps
  } = props;

  return (
    <SortedSearchResultsList
      {...otherProps}
      className={className}
      items={items}
      columns={columns}
      sortingDisabled={true}
      noResultsLabel={noResultsLabel}
      summaryViewQuantities={summaryViewQuantities}
      renderItem={(group: SecurityGroupBulkRequest, column: ReviewViewColumns) => (
        <ReviewViewListItem
          groupBulkRequest={group}
          column={column}
        />
      )}
    />
  );
};

export default ReviewViewList;
