import { Module } from "@data";
import { reset } from "./actions";
import { createModule } from "@base/createModule";
import { isDeviceManagementEnabled } from "@util";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { isAccessDeniedVisible, isEmptyViewVisible } from "./selectors";
import view from "./DeviceTypeDetailsView";

export const deviceTypeDetailsModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: false,
  name: "Device Type Details",
  title: "Device Type Details",
  className: "deviceTypeDetailsModule",
  primaryColor,
  icon,
  view,
  isEmptyViewVisible: isEmptyViewVisible,
  isAccessDeniedVisible: isAccessDeniedVisible,
  onMount: reset,
  disabled: !isDeviceManagementEnabled(),
});

export default deviceTypeDetailsModule;
