import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";

export enum DeviceTypeModelV3DeviceAuthenticationType {
  NONE = "",
  X509 = "x-signify-iot/device-authentication/x509:v1",
  ASYM = "x-signify-iot/device-signature/asym:v1",
  SYM = "x-signify-iot/device-authentication/sym:v1",
}

export interface DeviceTypeModelV3DeviceAuthenticationAttributes {
  credentialName: string;
  type: DeviceTypeModelV3DeviceAuthenticationType;
}

export class DeviceTypeModelV3DeviceAuthentication extends Record({
  credentialName: "",
  type: DeviceTypeModelV3DeviceAuthenticationType.NONE,
}) implements DeviceTypeModelV3DeviceAuthenticationAttributes {

  public static EMPTY: DeviceTypeModelV3DeviceAuthentication = new DeviceTypeModelV3DeviceAuthentication();

  public readonly credentialName: string;
  public readonly type: DeviceTypeModelV3DeviceAuthenticationType;

  public getCredentialName(): string {
    return getStringValue(this.credentialName);
  }

  public getType(): DeviceTypeModelV3DeviceAuthenticationType {
    return this.type || DeviceTypeModelV3DeviceAuthenticationType.NONE;
  }

  public hasCredentialName(): boolean {
    return !isEmptyString(this.getCredentialName());
  }

  public hasType(): boolean {
    return this.getType() !== DeviceTypeModelV3DeviceAuthenticationType.NONE;
  }
}

export default DeviceTypeModelV3DeviceAuthentication;
