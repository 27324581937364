import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useDataAccessRequestClient,
} from "@hooks";
import { DataSetAccessRequestAttributes, DataSetAccessRequest } from "@data";
import { GetDataSetAccessRequestsResponse } from "@network";

type SuccessResponse = GetDataSetAccessRequestsResponse;

export interface UseGetDataSetAccessRequestProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  accountId: string;
  dataSetId: string;
}

export interface UseGetDataSetAccessRequestModel extends UseApiRequestModel<SuccessResponse> {
  dataAccessRequests: DataSetAccessRequest[];
}

export interface UseGetDataSetAccessRequestActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetDataSetAccessRequestProps;
type Model = UseGetDataSetAccessRequestModel;
type Actions = UseGetDataSetAccessRequestActions;
type Result = [Model, Actions];

export const useGetDataSetAccessRequests = (props: Props): Result => {

  const {
    dataSetId = "",
    accountId = "",
    defaultErrorMessage = "Failed to get data set request details",
    ...otherProps
  } = props;

  const DataAccessRequestClient = useDataAccessRequestClient();

  const makeApiRequest = React.useCallback(() =>
      DataAccessRequestClient.getDataSetAccessRequests(accountId, dataSetId),
    [DataAccessRequestClient, accountId, dataSetId]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const dataAccessRequests = React.useMemo(() => {
    const { provisionedAccesses } = successResponse || {};
    return provisionedAccesses ? provisionedAccesses.map(
      (attrs: DataSetAccessRequestAttributes) => new DataSetAccessRequest(attrs)) : [];
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    dataAccessRequests,
  }), [
    baseModel,
    dataAccessRequests,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetDataSetAccessRequests;
