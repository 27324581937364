import { isValidJson } from "@util";
import { createSelector } from "reselect";
import { MODULE_ID, SCHEMA_KEY } from "../../constants";
import { createSelectors, Selector } from "@base/createSelectors";
import { JsonSchemaDefinition, JsonSchemaMetadata } from "@data";
import { SchemaWizardState, SchemaWizardStateAttributes } from "../../models";
import {
  DEFAULT_STATE,
  SchemaEditorViewMode,
  SchemaWizardSchema,
  SchemaWizardStep,
} from "../../reducers/schemaWizard";
import { EditModeSelectors } from "../editMode";

export const CREATE_SCHEMA_WIZARD_STEPS = [
  SchemaWizardStep.NAMESPACE,
  SchemaWizardStep.NAME,
  SchemaWizardStep.SCHEMA,
  SchemaWizardStep.REVIEW,
];

export const EDIT_SCHEMA_WIZARD_STEPS = [
  SchemaWizardStep.NAME,
  SchemaWizardStep.SCHEMA,
  SchemaWizardStep.REVIEW,
];

export const {
  createdSchema: getCreatedSchemaAttributes,
  namespace: getNamespace,
  json: getJson,
  schema: getSchema,
  schemaWizardStep: getSchemaWizardStep,
  schemaEditorViewMode: getSchemaEditorViewMode,
  defaultState: getDefaultStateAttributes,
  propertiesTable: getPropertiesTableAttributes,
  showPropertyEditor: isPropertyEditorVisible,
  propertyEditor: getPropertyEditorAttributes,
  showRemoveProperty: isRemovePropertyVisible,
  removeProperty: getRemovePropertyAttributes,
  showEditMode: isEditModeActive,
  editMode: getEditModeAttributes,
  getAccessToken,
  getCurrentUser,
  getCurrentUserId,
  getCurrentAccountId,
  getErrorMessage,
  getSuccessMessage,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  isEmptyViewVisible,
  isAccessDeniedVisible,
  isLoadingIndicatorVisible,
} = createSelectors<SchemaWizardSchema>(MODULE_ID, SCHEMA_KEY, DEFAULT_STATE);

export const getCreatedSchema: Selector<JsonSchemaMetadata> = createSelector(
  getCreatedSchemaAttributes, (attrs: JsonSchemaMetadata) => new JsonSchemaMetadata(attrs));

export const getTitle: Selector<string> = createSelector(
  getSchema, (schema: JsonSchemaDefinition) => {
    return schema.title || "";
  });

export const getDescription: Selector<string> = createSelector(
  getSchema, (schema: JsonSchemaDefinition) => {
    return schema.description || "";
  });

export const getErrorTitle: Selector<string> = createSelector(
  [getErrorMessage, isEditModeActive],
  (errorMessage: string = "", editMode: boolean = false) => {

    if (errorMessage.length === 0) {
      return "";
    }

    return editMode ? "Update Schema Failed" : "Create Schema Failed";
  });

export const getReviewSchemaVersionLabel: Selector<string> = createSelector(
  [isEditModeActive, EditModeSelectors.getOriginalSchemaVersion],
  (editMode: boolean, originalSchemaVersion: number) => {
    return editMode ? `${originalSchemaVersion}` : "";
  });

export const isNamespaceViewSelected: Selector<boolean> = createSelector(
  getSchemaWizardStep, (schemaWizardStep: SchemaWizardStep) => {
    return SchemaWizardStep.NAMESPACE === schemaWizardStep;
  });

export const isNameViewSelected: Selector<boolean> = createSelector(
  getSchemaWizardStep, (schemaWizardStep: SchemaWizardStep) => {
    return SchemaWizardStep.NAME === schemaWizardStep;
  });

export const isSchemaEditorViewSelected: Selector<boolean> = createSelector(
  getSchemaWizardStep, (schemaWizardStep: SchemaWizardStep) => {
    return SchemaWizardStep.SCHEMA === schemaWizardStep;
  });

export const isReviewViewSelected: Selector<boolean> = createSelector(
  getSchemaWizardStep, (schemaWizardStep: SchemaWizardStep) => {
    return SchemaWizardStep.REVIEW === schemaWizardStep;
  });

export const isLastStepActive: Selector<boolean> = createSelector(
  getSchemaWizardStep, (schemaWizardStep: SchemaWizardStep) =>
    SchemaWizardStep.REVIEW === schemaWizardStep);

export const isFirstStepActive: Selector<boolean> = createSelector(
  [isEditModeActive, getSchemaWizardStep],
  (editMode: boolean, schemaWizardStep: SchemaWizardStep) => {

    if (editMode) {
      return SchemaWizardStep.NAME === schemaWizardStep;
    }

    return SchemaWizardStep.NAMESPACE === schemaWizardStep;
  });

export const isSummaryEditorSelected: Selector<boolean> = createSelector(
  getSchemaEditorViewMode, (schemaEditorViewMode: SchemaEditorViewMode) => {
    return SchemaEditorViewMode.SUMMARY === schemaEditorViewMode;
  });

export const isJsonEditorSelected: Selector<boolean> = createSelector(
  getSchemaEditorViewMode, (schemaEditorViewMode: SchemaEditorViewMode) => {
    return SchemaEditorViewMode.JSON === schemaEditorViewMode;
  });

export const isNamespaceValid: Selector<boolean> = createSelector(
  getNamespace, (namespace: string) => {
    return namespace.trim().length > 0;
  });

export const isTitleValid: Selector<boolean> = createSelector(
  getTitle, (title: string) => {
    return title.trim().length > 0;
  });

export const isDescriptionValid: Selector<boolean> = createSelector(
  getDescription, (description: string) => {
    return description.trim().length > 0;
  });

export const isJsonValid: Selector<boolean> = createSelector(
  getJson, (json: string) => {
    return isValidJson(json);
  });

export const isSchemaCreated: Selector<boolean> = createSelector(
  getCreatedSchema, (schema: JsonSchemaMetadata) => schema.hasId());

export const isSaveButtonEnabled: Selector<boolean> = createSelector(
  [isNamespaceValid, isTitleValid, isDescriptionValid, isJsonValid],
  (validNamespace: boolean,
   validTitle: boolean,
   validDescription: boolean,
   validJson: boolean) =>
    validNamespace && validTitle && validDescription && validJson);

export const getSteps: Selector<SchemaWizardStep[]> = createSelector(
  isEditModeActive, (editMode: boolean) =>
    editMode ? EDIT_SCHEMA_WIZARD_STEPS : CREATE_SCHEMA_WIZARD_STEPS);

export const getDisabledSteps: Selector<SchemaWizardStep[]> = createSelector(
  [
    getSteps,
    isNamespaceValid,
    isTitleValid,
    isDescriptionValid,
    isJsonValid,
  ],
  (steps: SchemaWizardStep[],
   validNamespace: boolean,
   validTitle: boolean,
   validDescription: boolean,
   validJson: boolean) => {

    if (!validNamespace) {
      return steps.filter(step => SchemaWizardStep.NAMESPACE !== step);
    }

    if (!validTitle || !validDescription) {
      return steps.filter(step => SchemaWizardStep.NAMESPACE !== step &&
        SchemaWizardStep.NAME !== step);
    }

    if (!validJson) {
      return steps.filter(step => SchemaWizardStep.SCHEMA !== step);
    }

    return [];
  });

export const getCompletedSteps: Selector<SchemaWizardStep[]> = createSelector(
  [
    getSteps,
    getSchemaWizardStep,
    isNamespaceValid,
    isTitleValid,
    isDescriptionValid,
    isJsonValid,
    isLastStepActive,
    isLoadingIndicatorVisible,
    isSchemaCreated,
  ],
  (steps: SchemaWizardStep[],
   currentStep: SchemaWizardStep,
   validNamespace: boolean,
   validTitle: boolean,
   validDescription: boolean,
   validJson: boolean,
   lastStepActive: boolean,
   loading: boolean,
   success: boolean) => steps.filter((step: SchemaWizardStep) => {

    const isCurrentStep = (step === currentStep);

    switch (step) {
      case SchemaWizardStep.NAMESPACE:
        return !isCurrentStep && validNamespace;
      case SchemaWizardStep.NAME:
        return !isCurrentStep && validNamespace && validTitle && validDescription;
      case SchemaWizardStep.SCHEMA:
        return lastStepActive && validNamespace && validTitle && validDescription && validJson;
      case SchemaWizardStep.REVIEW:
        return (loading || success);
      default:
        return false;
    }
  }));

export const getSchemaWizardDefaultState: Selector<SchemaWizardState> = createSelector(
  getDefaultStateAttributes, (attrs: SchemaWizardStateAttributes) =>
    new SchemaWizardState(attrs));

export const getSchemaWizardState: Selector<SchemaWizardState> = createSelector(
  [getNamespace, getTitle, getJson], (namespace: string, name: string, json: string) =>
    new SchemaWizardState({ namespace, name, json }));
