import React from "react";
import classnames from "classnames";
import Button from "@components/button";
import { clickHandler, noop } from "@util";
import ActionsMenu, { ActionMenuItem } from "@components/actions-menu";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { breadcrumbs as styles } from "./styles";

export interface Model {
  className?: string;
  title?: string;
  actions?: ActionMenuItem[];
  breadcrumbs?: string[];
  children?: React.ReactNode;
}

export interface Actions {
  clearBreadcrumbs?: () => void;
  setBreadcrumbs?: (breadcrumbs: string[]) => void;
  onClickAction?: (action: ActionMenuItem) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SchemaPropertiesBreadcrumbs = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Properties",
    breadcrumbs = [],
    actions = [],
    children,
    clearBreadcrumbs,
    setBreadcrumbs = noop,
    onClickAction,
  } = props;

  const showActions = Array.isArray(actions) && actions.length > 0;

  const activeIndex = breadcrumbs.length === 0 ? 0 : breadcrumbs.length - 1;

  const onClickBreadcrumb = (selectedIndex: number) => clickHandler(() =>
    setBreadcrumbs(breadcrumbs.slice(0, selectedIndex + 1)));

  return (
    <div className={classnames("propertiesEditor", className, classes.container)}>
      <div className={classnames("breadcrumbsContainer", classes.breadcrumbsContainer)}>
        {breadcrumbs.length === 0 && (
          <Button
            className={classnames("breadcrumb", classes.breadcrumb, classes.active)}
            classes={{
              label: classnames("label", classes.breadcrumbLabel),
            }}
            variant="text"
            disabled={true}
            disableRipple={true}
            disableFocusRipple={true}
            disableTouchRipple={true}
          >
            {title}
          </Button>
        )}
        {breadcrumbs.length > 0 && (
          <Button
            className={classnames("breadcrumb", classes.breadcrumb)}
            classes={{
              label: classnames("label", classes.breadcrumbLabel),
            }}
            variant="text"
            disableRipple={true}
            disableFocusRipple={true}
            disableTouchRipple={true}
            onClick={clickHandler(clearBreadcrumbs)}
          >
            {title}
          </Button>
        )}
        {breadcrumbs.map((breadcrumbName: string, index: number) => (
          <React.Fragment key={`breadcrumb-${index}`}>
            <span
              key={`breadcrumb-${index}-divider`}
              className={classnames("breadcrumbDivider", classes.breadcrumbDivider)}
            >
              &gt;
            </span>
            <Button
              key={`breadcrumb-${index}-button`}
              className={classnames("breadcrumb", classes.breadcrumb, {
                [classes.active]: index === activeIndex,
              })}
              classes={{
                label: classnames("label", classes.breadcrumbLabel),
              }}
              variant="text"
              disableRipple={true}
              disableFocusRipple={true}
              disableTouchRipple={true}
              disabled={index === activeIndex}
              onClick={onClickBreadcrumb(index)}
            >
              {breadcrumbName}
            </Button>
          </React.Fragment>
        ))}
        {showActions && (
          <ActionsMenu
            actions={actions}
            buttonVariant="icon"
            className={classes.actionsMenu}
            buttonClassName={classes.actionsMenuIconButton}
            onClickAction={onClickAction}
          />
        )}
        {children}
      </div>
    </div>
  );
});

export default SchemaPropertiesBreadcrumbs;
