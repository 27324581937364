import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import { DeviceEnrollmentStatusAttributes } from "@data";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base/createReducers";

export enum DeviceEnrollmentColumns {
  BATCH_ID = "Batch ID",
  STATUS = "Status",
  UPDATED = "Updated"
}

export type DeviceEnrollmentItems = { [key: string]: DeviceEnrollmentStatusAttributes };

export interface DeviceEnrollmentSchema extends BasePortalModuleSchema {
  deviceEnrollments: DeviceEnrollmentItems;
  batchId: string;
}

export const DEFAULT_STATE: DeviceEnrollmentSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  deviceEnrollments: {},
  batchId: "",
});

export type DeviceEnrollmentActionTypes = PortalModuleActionTypes<DeviceEnrollmentSchema> & {
  FETCH_DEVICE_ENROLLMENTS_REQUEST: string;
  FETCH_DEVICE_ENROLLMENTS_SUCCESS: string;
  FETCH_DEVICE_ENROLLMENTS_FAILED: string;
};

export const ACTION_TYPES: DeviceEnrollmentActionTypes = {
  ...createPortalModuleActionTypes<DeviceEnrollmentSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    FETCH_DEVICE_ENROLLMENTS_REQUEST: `${MODULE_ID}/fetch_device_enrollments_request`,
    FETCH_DEVICE_ENROLLMENTS_SUCCESS: `${MODULE_ID}/fetch_device_enrollments_success`,
    FETCH_DEVICE_ENROLLMENTS_FAILED: `${MODULE_ID}/fetch_device_enrollments_failed`,
  },
};

export const DeviceEnrollmentReducer = combineReducers<DeviceEnrollmentSchema>(
  createPortalModuleReducers<DeviceEnrollmentSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DeviceEnrollmentReducer;
