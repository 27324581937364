import React from "react";
import classnames from "classnames";
import TextField from "@components/text-field";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { formEventHandler } from "@util";
import { textField, textAreaField, dataSetAliasView as styles } from "./styles";

const NameTextField = withStyles(textField)(TextField);
const DescriptionTextField = withStyles(textAreaField)(TextField);

export interface Model {
  dataSetAlias?: string;
  description?: string;
  descriptionError?: string;
}

export interface Actions {
  setDescription?: (description: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const EditDescriptionView = withStyles(styles)((props: Props) => {

  const {
    classes,
    dataSetAlias = "",
    description = "",
    descriptionError = "",
    setDescription,
  } = props;

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  return (
    <div className={classnames("editDescriptionView", classes.container)}>
      <NameTextField
        className={classnames("dataSetAlias", classes.dataSetAlias)}
        autoComplete="off"
        label="Data Set Name"
        name="dataSetAlias"
        value={dataSetAlias}
        fullWidth={true}
        autoFocus={false}
        disabled={true}
        variant="outlined"
        margin="none"
        required={true}
        InputLabelProps={inputLabelProps}
      />
      <DescriptionTextField
        className={classnames("description", classes.description)}
        autoComplete="off"
        name="description"
        label="Description"
        value={description}
        fullWidth={true}
        multiline={true}
        minRows={3}
        autoFocus={false}
        required={true}
        helperText={descriptionError}
        error={descriptionError.length > 0}
        variant="outlined"
        margin="none"
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setDescription)}
      />
    </div>
  );
});

export default EditDescriptionView;
