import React from "react";
import Color from "@Color";
import Fab from "@components/fab";
import classnames from "classnames";
import { clickHandler } from "@util";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@components/circular-progress";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface RefreshButtonModel {
  className?: string;
  iconClassName?: string;
  color?: string;
  loading?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  loadingIndicatorSize?: number | string;
}

export interface RefreshButtonActions {
  refresh?: () => void;
}

type Model = RefreshButtonModel;
type Actions = RefreshButtonActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const RefreshButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    iconClassName,
    color = Color.SIGNIFY_GREEN,
    loading,
    disabled = false,
    refresh,
    style = {},
    loadingIndicatorSize,
  } = props;

  if (loading) {
    return (
      <CircularProgress
        className={classnames("refreshButton", "loading", className)}
        style={{ color, ...style }}
        size={loadingIndicatorSize}
        color="inherit"
        classes={{
          root: classnames(classes.container, className),
        }}
      />
    );
  }

  return (
    <Fab
      className={classnames("refreshButton", className, classes.container)}
      disabled={disabled}
      style={{ color, ...style }}
      onClick={clickHandler(refresh)}
    >
      <RefreshIcon className={classnames("icon", iconClassName, classes.icon)} color="inherit" />
    </Fab>
  );
});

export default RefreshButton;
