import React from "react";
import { QueryDatabaseTable } from "@data";
import { useQueryDatabasesManagerClient } from "@hooks";
import { GetQueryDatabasesTableResponse } from "@network";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, UseApiRequestActions } from "@hooks";

type SuccessResponse = GetQueryDatabasesTableResponse;

export interface UseGetQueryDatabasesTablesProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  databaseName?: string;
}

export interface UseGetQueryDatabasesTablesModel extends UseApiRequestModel<SuccessResponse> {
  tables?: QueryDatabaseTable[];
}

export interface UseGetQueryDatabasesTablesActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetQueryDatabasesTablesProps;
type Model = UseGetQueryDatabasesTablesModel;
type Actions = UseGetQueryDatabasesTablesActions;
type Result = [Model, Actions];

export const useGetQueryDatabasesTables = (props: Props): Result => {

  const {
    databaseName = "",
    defaultErrorMessage = "Failed to get query database tables",
    ...otherProps
  } = props;

  const QueryDatabasesClient = useQueryDatabasesManagerClient();

  const makeApiRequest = React.useCallback(() => QueryDatabasesClient.getQueryDatabasesTables(databaseName),
    [QueryDatabasesClient, databaseName]);

  const [{ successResponse, showSuccessView, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      deferRequest: true,
      defaultErrorMessage,
      makeApiRequest,
    });

  const tables = React.useMemo<QueryDatabaseTable[]>(() => {
    return successResponse?.tables ? successResponse.tables.map(
      ({ name, schema }) => new QueryDatabaseTable({ name, columns: schema.columns })) : [];
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    showSuccessView,
    tables,
  }), [
    baseModel,
    showSuccessView,
    tables,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetQueryDatabasesTables;
