import React from "react";
import classnames from "classnames";
import { noop, formEventHandler } from "@util";
import { TextField, TagEditor, ErrorView } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { nameTextField, descriptionTextField, nameView as styles } from "./styles";

const NameTextField = withStyles(nameTextField)(TextField);
const DescriptionTextField = withStyles(descriptionTextField)(TextField);

export interface Model {
  showInvalidJsonError?: boolean;
  deviceTypeName?: string;
  deviceTypeNameError?: string;
  deviceTypeNameDisabled?: boolean;
  deviceTypeDescription?: string;
  deviceTypeTags?: string[];
  children?: React.ReactNode;
}

export interface Actions {
  setDeviceTypeName?: (name: string) => void;
  setDeviceTypeDescription?: (description: string) => void;
  updateTags?: (tags: string[]) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const NameView = withStyles(styles)((props: Props) => {

  const {
    classes,
    showInvalidJsonError,
    deviceTypeName = "",
    deviceTypeNameError = "",
    deviceTypeNameDisabled = false,
    deviceTypeDescription = "",
    deviceTypeTags = [],
    setDeviceTypeName,
    setDeviceTypeDescription,
    updateTags = noop,
    children,
  } = props;

  if (showInvalidJsonError) {
    return (
      <div className={classnames("nameView", classes.errorViewContainer)}>
        <ErrorView
          title="JSON Syntax Error"
          message="Visual editors cannot be used until you have fixed the JSON syntax error."
        />
      </div>
    );
  }

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  return (
    <div className={classnames("nameView", classes.container)}>
      <label className={classes.title}>
        Set Device Type Name & Description
      </label>
      <NameTextField
        className={classnames("deviceTypeName", classes.deviceTypeName)}
        color="secondary"
        autoComplete="off"
        label="Device Type Name"
        name="name"
        value={deviceTypeName}
        fullWidth={true}
        autoFocus={!deviceTypeNameDisabled}
        disabled={deviceTypeNameDisabled}
        variant="outlined"
        margin="none"
        required={true}
        helperText={deviceTypeNameError}
        error={deviceTypeNameError.length > 0}
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setDeviceTypeName)}
      />
      <DescriptionTextField
        className={classnames("deviceTypeDescription", classes.deviceTypeDescription)}
        color="secondary"
        autoComplete="off"
        name="description"
        label="Device Type Description (optional)"
        value={deviceTypeDescription}
        fullWidth={true}
        multiline={true}
        minRows={3}
        maxRows={3}
        autoFocus={deviceTypeNameDisabled}
        variant="outlined"
        margin="none"
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setDeviceTypeDescription)}
      />
      <TagEditor
        setTags={updateTags}
        tags={deviceTypeTags}
        helperText="Tags to associate with this device type"
        className={classnames("tagEditor", classes.tagEditor)}
      />
      {children}
    </div>
  );
});

export default NameView;
