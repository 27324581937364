import React from "react";
import classnames from "classnames";
import { clickHandler, noop } from "@util";
import Button from "@components/button";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles, { dialog, dialogContent, dialogActions, dialogTitle } from "./styles";

const Dialog = withStyles(dialog)(MuiDialog);
const DialogTitle = withStyles(dialogTitle)(MuiDialogTitle);
const DialogContent = withStyles(dialogContent)(MuiDialogContent);
const DialogActions = withStyles(dialogActions)(MuiDialogActions);

export const AlertDialog = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string;
  title?: string;
  open?: boolean;
  closeButtonLabel?: string;
  close?: () => void;
  onClose?: () => void;
  children?: React.ReactNode;
}) => {

  const {
    classes,
    className,
    title = "Alert",
    open = false,
    closeButtonLabel = "OK",
    close,
    onClose = noop,
    children,
  } = props;

  return (
    <Dialog
      className={classnames("alertDialog", className)}
      open={open}
      maxWidth="sm"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose(event, reason);
        }
      }}
    >
      <DialogTitle className="title" disableTypography={true}>
        {title}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          className={classnames("cancelButton", classes.cancelButton)}
          classes={{
            label: classnames("label", classes.cancelButtonLabel),
          }}
          fullWidth={false}
          onClick={clickHandler(close)}
        >
          {closeButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default AlertDialog;
