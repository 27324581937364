export type Primitive = string | number | boolean;

export type Comparator<T> = (left: T, right: T, ascending?: boolean) => number;

export type PrimitiveComparator = Comparator<Primitive>;

export const compare: PrimitiveComparator =
  (left: Primitive, right: Primitive, ascending?: boolean) => {
    if (left === right) {
      return 0;
    } else if (ascending) {
      return left < right ? -1 : 1;
    } else {
      return left < right ? 1 : -1;
    }
  };

type MapItemToColumnValue<Item, Column> =
  (item: Item, column: Column) => Primitive;

type ComparatorFactor<Item, Column> =
  (sortedColumn: Column, ascending?: boolean) => Comparator<Item>;

export const createComparatorFactory =
  <Item, Column>(mapper: MapItemToColumnValue<Item, Column>): ComparatorFactor<Item, Column> =>
    (sortedColumn: Column, ascending?: boolean): Comparator<Item> =>
      (left: Item, right: Item) =>
        compare(
          mapper(left, sortedColumn),
          mapper(right, sortedColumn),
          ascending);
