import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";

export interface DeleteUserSchema extends BasePortalModuleSchema {
  userId: string;
  open: boolean;
}

export const DEFAULT_STATE: DeleteUserSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  open: false,
  userId: "",
});

export type DeleteUserActionTypes = PortalModuleActionTypes<DeleteUserSchema> & {
  DELETE_USER_REQUEST: string;
  DELETE_USER_SUCCESS: string;
  DELETE_USER_FAILED: string;
};

export type DeleteUserActionType = DeleteUserActionTypes[keyof DeleteUserActionTypes];

export const ACTION_TYPES: DeleteUserActionTypes = {
  ...createPortalModuleActionTypes<DeleteUserSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    DELETE_USER_REQUEST: `${MODULE_ID}/delete_user_request`,
    DELETE_USER_SUCCESS: `${MODULE_ID}/delete_user_success`,
    DELETE_USER_FAILED: `${MODULE_ID}/delete_user_failed`,
  },
};

export const DeleteUserReducer = combineReducers<DeleteUserSchema>(
  createPortalModuleReducers<DeleteUserSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DeleteUserReducer;
