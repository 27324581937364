import { AppSchema } from "@main/schemas";
import { getAuthToken } from "@main/selectors";
import { createActions } from "@modules/base/createActions";
import { RestClientError, DeviceEnrollmentClient } from "@network";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { getDeviceId } from "./selectors";
import { isEmptyString } from "@util/Functions";

export const {
  deviceId: setDeviceId,
  open: setOpen,
  setErrorMessage,
  setSuccessMessage,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  DISENROLL_DEVICE_REQUEST: disenrollDeviceRequest,
  DISENROLL_DEVICE_SUCCESS: disenrollDeviceSuccess,
  DISENROLL_DEVICE_FAILED: disenrollDeviceFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const disenrollDevice = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const deviceId = getDeviceId(state);
  const authToken = getAuthToken(state);

  dispatch(showLoadingIndicator());
  dispatch(hideAccessDenied());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(disenrollDeviceRequest());

  return DeviceEnrollmentClient.disenrollDevice(authToken, deviceId)
    .then(() => {

      dispatch(disenrollDeviceSuccess());
      dispatch(hideLoadingIndicator());
      return dispatch(setSuccessMessage("Device disenrollment request submitted"));

    }, (response: RestClientError) => {

      const { analytic, status, error = "Failed to submit device disenrollment request" } = response;

      dispatch(disenrollDeviceFailed(analytic));
      dispatch(hideLoadingIndicator());

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(setErrorMessage(error));
    });
};

export const reset = () => (dispatch: any) => {
  dispatch(setDeviceId());
  dispatch(setOpen());
  return dispatch(baseReset());
};

export const open = (deviceId: string) => (dispatch: any) => {

  if (isEmptyString(deviceId)) {
    return Promise.resolve();
  }

  dispatch(reset());
  dispatch(setDeviceId(deviceId));
  return dispatch(setOpen(true));
};

export const close = reset;

export const initialize = reset;
