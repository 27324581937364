import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useDataAccessRequestClient
} from "@hooks";
import { RevokeAccessResponse } from "@network";
import { AccessRequestPrincipalType, AccessRequestType, RevokeDataAccessRequest } from "@data";

type SuccessResponse = RevokeAccessResponse;

export interface UseRevokeDataAccessByOwnerProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  accountId: string;
  dataSetAlias: string;
  accessPrincipalType: AccessRequestPrincipalType;
  accessRequestType: AccessRequestType;
  principalIdentifier: string;
}

export interface UseRevokeDataAccessByOwnerModel extends UseApiRequestModel<SuccessResponse> {
  revokeAccessRequestId: string;
  successMessage: string;
}

export interface UseRevokeDataAccessByOwnerActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseRevokeDataAccessByOwnerProps;
type Model = UseRevokeDataAccessByOwnerModel;
type Actions = UseRevokeDataAccessByOwnerActions;
type Result = [Model, Actions];

export const useRevokeDataAccessByOwner = (props: Props): Result => {

  const {
    dataSetAlias = "",
    accountId = "",
    accessPrincipalType,
    accessRequestType,
    principalIdentifier,
    defaultErrorMessage = "Failed to revoke data set access",
    ...otherProps
  } = props;

  const DataAccessRequestClient = useDataAccessRequestClient();

  const revokeDataAccessRequest = new RevokeDataAccessRequest({
    accessPrincipalType,
    accessRequestType,
    principalIdentifier,
  });

  const makeApiRequest = React.useCallback(() =>
      DataAccessRequestClient.revokeDataAccessByOwner(accountId, dataSetAlias, JSON.stringify(revokeDataAccessRequest)),
    [DataAccessRequestClient, accountId, dataSetAlias, revokeDataAccessRequest]);

  const [{ successResponse, showSuccessView, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const revokeAccessRequestId = React.useMemo(() => {
    const { revokeAccessRequestId: requestId } = successResponse || {};
    return requestId ? requestId : "";
  }, [successResponse]);

  const successMessage = React.useMemo(() => {
    return showSuccessView ? "Revoke data set access successful" : "";
  }, [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    revokeAccessRequestId,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    revokeAccessRequestId,
    successMessage,
    showSuccessView,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useRevokeDataAccessByOwner;
