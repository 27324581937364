import React from "react";
import { DataLakeFileMetadata, GetDataLakeFileMetadataResponse } from "@network";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useDataLakeFileTransferClient,
} from "@hooks";

type SuccessResponse = GetDataLakeFileMetadataResponse;

export interface UseDataLakeFileMetadataProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  dataSetAlias?: string;
  file?: string;
  accountId?: string;
}

export interface UseDataLakeFileMetadataModel extends UseApiRequestModel<SuccessResponse> {
  file: string;
  metadata: DataLakeFileMetadata;
}

export interface UseDataLakeFileMetadataActions extends UseApiRequestActions<SuccessResponse> {
  fetchDataLakeFileMetadata: () => void;
}

type Props = UseDataLakeFileMetadataProps;
type Model = UseDataLakeFileMetadataModel;
type Actions = UseDataLakeFileMetadataActions;
type Result = [Model, Actions];

export const useDataLakeFileMetadata = (props: Props): Result => {

  const {
    dataSetAlias = "",
    file = "",
    accountId = "",
    defaultErrorMessage = "Failed to get data lake file metadata",
    ...otherProps
  } = props;

  const DataLakeFileTransferClient = useDataLakeFileTransferClient();

  const makeApiRequest = React.useCallback(() =>
      DataLakeFileTransferClient.getDataLakeFileMetadata(dataSetAlias, file, accountId),
    [DataLakeFileTransferClient, dataSetAlias, file, accountId]);

  const [{ successResponse, ...baseModel }, { refresh, ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const metadata = React.useMemo<DataLakeFileMetadata>(() => {
    const { metadata: result = {} } = successResponse || {};
    return result;
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    file,
    metadata,
  }), [
    baseModel,
    file,
    metadata,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    refresh,
    fetchDataLakeFileMetadata: refresh,
  }), [
    baseActions,
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDataLakeFileMetadata;
