import { AppSchema } from "@main/schemas";
import { createActions } from "@modules/base/createActions";
import { getAuthToken } from "@main/selectors";
import { RestClientError, UiManagerClient, GetUiPackageFilesResponse } from "@network";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import { getFiles, isSortOrderAscending } from "./selectors";

export const {
  files: setFiles,
  sortByColumn: setSortByColumn,
  sortOrderAscending: setSortOrderAscending,
  showDeleteUiPackageDialog: setShowDeleteUiPackageDialog,
  showUploadUiPackageDialog: setShowUploadUiPackageDialog,
  setErrorMessage,
  showEmptyView,
  hideEmptyView,
  showNotFound,
  hideNotFound,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  FETCH_UI_PACKAGE_REQUEST: fetchUiPackageRequest,
  FETCH_UI_PACKAGE_SUCCESS: fetchUiPackageSuccess,
  FETCH_UI_PACKAGE_FAILED: fetchUiPackageFailed,
  UPLOAD_UI_PACKAGE_REQUEST: uploadUiPackageRequest,
  UPLOAD_UI_PACKAGE_SUCCESS: uploadUiPackageSuccess,
  UPLOAD_UI_PACKAGE_FAILED: uploadUiPackageFailed,
  DELETE_UI_PACKAGE_REQUEST: deleteUiPackageRequest,
  DELETE_UI_PACKAGE_SUCCESS: deleteUiPackageSuccess,
  DELETE_UI_PACKAGE_FAILED: deleteUiPackageFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const toggleSortOrder = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(setSortOrderAscending(!isSortOrderAscending(getState())));

export const reset = () => (dispatch: any) => {
  dispatch(setFiles());
  dispatch(setSortByColumn());
  dispatch(setSortOrderAscending());
  dispatch(setShowDeleteUiPackageDialog());
  dispatch(setShowUploadUiPackageDialog());
  return dispatch(baseReset());
};

export const clearFiles = () => setFiles();

export const addFiles = (files: string[]) =>
  (dispatch: any, getState: () => AppSchema) =>
    dispatch(setFiles(getFiles(getState()).concat(files)));

export const fetchUiPackageFiles = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const authToken = getAuthToken(state);

  dispatch(showLoadingIndicator());
  dispatch(setErrorMessage());
  dispatch(hideNotFound());
  dispatch(hideAccessDenied());
  dispatch(fetchUiPackageRequest());

  return UiManagerClient.getUiPackageFiles(authToken)
    .then((response: GetUiPackageFilesResponse) => {

      const { package: { files = [] }} = response;

      dispatch(clearFiles());
      dispatch(addFiles(files));
      dispatch(fetchUiPackageSuccess());
      dispatch(hideLoadingIndicator());
      return dispatch(hideEmptyView());

    }, (response: RestClientError) => {

      const { analytic, error = "Fetch UI Package files failed" } = response;

      dispatch(fetchUiPackageFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(hideLoadingIndicator());

      if (response.status === 404) {
        dispatch(showNotFound());
      }

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideEmptyView());
    });
};

export const reloadUiPackageFiles = () => (dispatch: any) => {
  dispatch(clearFiles());
  dispatch(setSortOrderAscending());
  dispatch(setSortByColumn());
  return dispatch(fetchUiPackageFiles());
};

export const onMount = () => (dispatch: any) => {

  return dispatch(reloadUiPackageFiles());
};

export const initialize = () => (dispatch: any) => {

  return dispatch(reset());
};

export const closeUiDialog = () => (dispatch: any) => {
  dispatch(reloadUiPackageFiles());
  dispatch(setShowDeleteUiPackageDialog(false));
  return dispatch(setShowUploadUiPackageDialog(false));
};
