import React from "react";
import useApiRequest, {
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import ReleaseNote, { ReleaseNoteAttributes, ReleaseNotePlatformDomain } from "@data/ReleaseNote";
import usePortalReleaseNotesClient from "../use-portal-release-notes-client";

type SuccessResponse = ReleaseNoteAttributes;

export interface UseGetByDomainAndVersionProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  domain: ReleaseNotePlatformDomain;
  version: string;
}

export interface UseGetByDomainAndVersionModel extends UseApiRequestModel<SuccessResponse> {
  releaseNote: ReleaseNote;
}

export interface UseGetByDomainAndVersionActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetByDomainAndVersionProps;
type Model = UseGetByDomainAndVersionModel;
type Actions = UseGetByDomainAndVersionActions;
type Result = [Model, Actions];

export const useGetByDomainAndVersion = (props: Props): Result => {

  const {
    domain,
    version,
    defaultErrorMessage = "Failed to get release note",
    ...otherProps
  } = props;

  const PortalReleaseNotesClient = usePortalReleaseNotesClient();

  const makeApiRequest = React.useCallback(() =>
    PortalReleaseNotesClient.getByVersionAndDomain(domain, version), [PortalReleaseNotesClient, domain, version]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const releaseNote = React.useMemo<ReleaseNote>(() => {
    return new ReleaseNote(successResponse || {});
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    releaseNote,
  }), [
    baseModel,
    releaseNote,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetByDomainAndVersion;
