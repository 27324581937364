import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { Actions, Model, ServiceLoginForm } from "../components/ServiceLoginForm";
import {
  getMfaCode,
  getMfaCodeError,
  getServiceAccountId,
  getServiceAccountIdError,
  getServiceId,
  getServiceIdError,
  getServiceSecret,
  getServiceSecretError,
  isLoadingIndicatorVisible,
  isRememberServiceChecked,
} from "../selectors";
import {
  authenticateService,
  authenticateServiceFailed,
  authenticateServiceSuccess,
  hideLoadingIndicator,
  setMfaCodeError,
  showErrorMessage,
  toggleRememberService,
  updateMfaCode,
  updateServiceAccountId,
  updateServiceId,
  updateServiceSecret,
} from "../actions";
import { useAuthenticateServiceRegional } from "@hooks";
import { getStringValue, isEmptyString, noop } from "@util";
import { createServiceSession } from "@main/actions";
import { AuthenticateServiceRegionalResponse } from "@network";

const DEFAULT_ERROR_MESSAGE = "The credentials you entered are incorrect. Please try again.";

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
  authenticateSecurityServiceSuccess?: (value: string) => void;
  authenticateSecurityServiceFailed?: (value: string) => void;
  showError?: (error: string) => void;
  hideLoading?: () => void;
  createSecurityServiceSession?:
    (response: AuthenticateServiceRegionalResponse, accountId: string, serviceId: string) => void;
  setMfaCodeErrorMessage?: (error: string) => void;
}

export type ServiceLoginFormContainerProps = ContainerModel & ContainerActions;

const ServiceLoginFormContainer = (props: ServiceLoginFormContainerProps) => {

  const {
    serviceId = "",
    accountId = "",
    secret = "",
    mfaCode = "",
    showLoadingIndicator,
    authenticateSecurityServiceSuccess = noop,
    authenticateSecurityServiceFailed = noop,
    showError = noop,
    hideLoading = noop,
    createSecurityServiceSession = noop,
    setMfaCodeErrorMessage = noop,
  } = props;

  const [{accessToken, expiration, errorMessage, errorResponse, mfaCodeErrorMessage},
    {authenticateService: authenticate}] = useAuthenticateServiceRegional({
      serviceId: getStringValue(serviceId),
      accountId: getStringValue(accountId),
      secret,
      mfaCode,
    });

  React.useEffect(() => {
    if (showLoadingIndicator) {
      authenticate();
    }
  }, [showLoadingIndicator]);

  React.useEffect(() => {
      if (!isEmptyString(accessToken) && !isEmptyString(expiration)) {
        authenticateSecurityServiceSuccess(accountId);
        hideLoading();
        createSecurityServiceSession({accessToken, expiration}, accountId, serviceId);
      } else if (errorResponse) {
        const { description, status, analytic } = errorResponse;
        let message;
        switch (status) {
          case 401:
            message = DEFAULT_ERROR_MESSAGE;
            break;
          case 500:
            message = "Oops, something went wrong. Please try again.";
            break;
          default:
            message = description || errorMessage;
        }
        showError(message || DEFAULT_ERROR_MESSAGE);
        hideLoading();
        authenticateSecurityServiceFailed(`${accountId}:${analytic}`);
      }
  }, [accessToken, expiration, errorMessage]);

  React.useEffect(() => {
      setMfaCodeErrorMessage(mfaCodeErrorMessage);
  }, [mfaCodeErrorMessage, setMfaCodeErrorMessage]);

  return <ServiceLoginForm {...props} />;
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  serviceId: getServiceId(state),
  serviceIdErrorMessage: getServiceIdError(state),
  accountId: getServiceAccountId(state),
  accountIdErrorMessage: getServiceAccountIdError(state),
  secret: getServiceSecret(state),
  secretErrorMessage: getServiceSecretError(state),
  mfaCode: getMfaCode(state),
  mfaCodeErrorMessage: getMfaCodeError(state),
  rememberService: isRememberServiceChecked(state),
  showLoadingIndicator: isLoadingIndicatorVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setServiceId: (serviceId: string) => dispatch(updateServiceId(serviceId)),
  setAccountId: (accountId: string) => dispatch(updateServiceAccountId(accountId)),
  setSecret: (secret: string) => dispatch(updateServiceSecret(secret)),
  setMfaCode: (mfaCode: string) => dispatch(updateMfaCode(mfaCode)),
  setMfaCodeErrorMessage: (error: string) => dispatch(setMfaCodeError(error)),
  setRememberService: (rememberService: boolean) => dispatch(toggleRememberService(rememberService)),
  login: () => dispatch(authenticateService()),
  authenticateSecurityServiceSuccess: (value: string)  => dispatch(authenticateServiceSuccess(value)),
  authenticateSecurityServiceFailed: (value: string)  => dispatch(authenticateServiceFailed(value)),
  showError: (error: string)  => dispatch(showErrorMessage(error)),
  hideLoading: ()  => dispatch(hideLoadingIndicator()),
  createSecurityServiceSession:
    (response: AuthenticateServiceRegionalResponse, accountId: string, serviceId: string) =>
      dispatch(createServiceSession(response, accountId, serviceId)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, ServiceLoginFormContainerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceLoginFormContainer);
