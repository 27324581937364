import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import { useDeleteVariable } from "@hooks";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";

export interface WorkloadDeleteVariableDialogModel extends PortalModuleDialogModel<string> {
  name?: string;
  version?: number;
  etag?: string;
  variable?: string;
}

export interface WorkloadDeleteVariableDialogActions extends PortalModuleDialogActions<string> {
}

type Props = WithStyles<typeof styles> & WorkloadDeleteVariableDialogModel & WorkloadDeleteVariableDialogActions;

export const WorkloadDeleteVariableDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    name = "",
    version = 1,
    etag = "",
    variable = "",
    cancel: cancelDialog = noop,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  const [{ showLoadingIndicator, ...otherModel }, { deleteVariable, reset }] =
    useDeleteVariable({ name, version, etag, variable });

  const cancel = React.useCallback(() => {
    reset();
    cancelDialog();
  }, [reset, cancelDialog]);

  const onSuccessMessageShown = React.useCallback(() => {
    reset();
    onSuccess();
  }, [reset, onSuccess]);

  if (isEmptyString(name)) {
    return null;
  }

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      className="workloadDeleteVariableDialog"
      title="Delete Completion Variable"
      loading={showLoadingIndicator}
      continueButtonLabel="Delete"
      onSuccessMessageShown={onSuccessMessageShown}
      confirm={deleteVariable}
      cancel={cancel}
    >
      <div className={classnames("workloadDeleteVariableContainer", classes.container)}>
        <label className={classnames("title", classes.title)}>
          Are you sure you want to delete this variable from {name}?
        </label>
        <label className={classnames("name", classes.variable)}>
          <b>Variable:</b> {variable}
        </label>
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadDeleteVariableDialog;
