export enum DataAccessRequestsListColumn {
  NONE = "",
  NAME = "Data Set",
  ACCOUNT = "Account",
  STATE = "State",
  TYPE = "Access Type",
  PRINCIPAL_TYPE = "Request Type",
}

export default DataAccessRequestsListColumn;
