import createStyles from "@material-ui/core/styles/createStyles";

export const markdownStyle = () =>
  createStyles({
    markdownView: {
      whiteSpace: "normal",
      "& pre:has(code)": {
        background: "#f0f0f3",
        borderRadius: 5,
        padding: 5,
      },
      "& p code": {
        lineHeight: "26px",
        background: "#f0f0f3",
        borderRadius: 5,
        padding: 5,
      },
    },
  });

export default markdownStyle;
