import createStyles from "@material-ui/core/styles/createStyles";
import ModuleIcon from "@material-ui/icons/Security";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const users = () =>
  createStyles({
    container: {
      color: primaryColor,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: "0 0 0 6px",
    },
  });

export const servicesView = () =>
  createStyles({
    container: {
      "& .summaryViewContainer .summary": {
        minHeight: "auto",
        "& > label": {
          margin: 0,
        },
      },
    },
  });
