import React from "react";
import { noop } from "@util";
import { Policy } from "@data";
import { PolicyIcon } from "./styles";
import { sortAndFilterPolicyResult } from "@hooks";
import { ActionMenuItem, ModuleListView, PolicyAction } from "@components";
import PoliciesListColumn from "./PoliciesListColumn";
import {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import { getPathToPolicy } from "@modules/policyDetails/helpers";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";

export const DEFAULT_POLICIES_LIST_COLUMNS: PoliciesListColumn[] = [
  PoliciesListColumn.NAME,
  PoliciesListColumn.PATH,
  PoliciesListColumn.VERSION,
  PoliciesListColumn.DESCRIPTION,
];

export interface PoliciesListModel extends SortedSearchResultsListModel<Policy, PoliciesListColumn> {
}

export interface PoliciesListActions extends SortedSearchResultsListActions<Policy, PoliciesListColumn> {
  deletePolicy?: (policy: Policy) => void;
  editPolicy?: (policy: Policy) => void;
}

type Props = PoliciesListModel & PoliciesListActions;

export const PoliciesList = (props: Props) => {

  const {
    className = "policies",
    listClassName = "policiesList",
    columns = DEFAULT_POLICIES_LIST_COLUMNS,
    sortByColumn = PoliciesListColumn.NAME,
    sortOrderAscending = true,
    noResultsLabel = "No policies found",
    summaryViewQuantities = {
      other: "policies",
      one: "policy",
    },
    items = [],
    nameFilter = "",
    nameFilterDelay = 250,
    searchViewHint = "Filter results that start with this name",
    deletePolicy = noop,
    editPolicy = noop,
    onClickAction = noop,
    ...otherProps
  } = props;

  const onActionClicked = React.useCallback((policy: Policy, action: ActionMenuItem) => {
    if (action.id === PolicyAction.DELETE) {
      deletePolicy(policy);
    } else if (action.id === PolicyAction.EDIT) {
      editPolicy(policy);
    } else {
      onClickAction(policy, action);
    }
  }, [deletePolicy, editPolicy, onClickAction]);

  const policies = sortAndFilterPolicyResult({ items, nameFilter, sortOrderAscending, sortByColumn });

  const moduleListItems: ListViewItem<Policy>[] = React.useMemo(() =>
    policies.map(policy => {
      return {
        item: policy,
        pathToDetailsView: getPathToPolicy(policy.getName()),
        icon: PolicyIcon,
        columnAttributes: createColumns([
          {
            className: "policyName",
            value: policy.getName(),
            column: PoliciesListColumn.NAME,
            firstColumn: true,
          },
          {
            className: "policyPath",
            value: policy.getPath(),
            column: PoliciesListColumn.PATH,
          },
          {
            className: "policyVersion",
            value: policy.getVersion(),
            column: PoliciesListColumn.VERSION,
          },
          {
            className: "policyDescription",
            value: policy.getDescription(),
            column: PoliciesListColumn.DESCRIPTION,
          },
        ])
      };
    }), [policies]);

  return (
    <ModuleListView
      {...otherProps}
      className={className}
      listViewItems={moduleListItems}
      columns={columns}
      nameFilter={nameFilter}
      nameFilterDelay={nameFilterDelay}
      searchViewHint={searchViewHint}
      sortByColumn={sortByColumn}
      sortOrderAscending={sortOrderAscending}
      noResultsLabel={noResultsLabel}
      summaryViewIcon={PolicyIcon}
      summaryViewQuantities={summaryViewQuantities}
      onClickAction={onActionClicked}
    />
  );
};

export default PoliciesList;
