import React from "react";
import { useDispatch } from "react-redux";
import { RestClientError, UserIdmLegacyClient } from "@network";
import { identityAction } from "@util/ReducerUtil";
import { AuthTokenContext } from "@components/auth-token-provider";

export enum SecurityManagerClientActionType {
  GET_USER_BINARY_DATA_REQUEST = "security_manager_client/get_user_binary_data_request",
  GET_USER_BINARY_DATA_SUCCESS = "security_manager_client/get_user_binary_data_success",
  GET_USER_BINARY_DATA_FAILED = "security_manager_client/get_user_binary_data_failed",

  UPDATE_USER_BINARY_DATA_REQUEST = "security_manager_client/update_user_binary_data_request",
  UPDATE_USER_BINARY_DATA_SUCCESS = "security_manager_client/update_user_binary_data_success",
  UPDATE_USER_BINARY_DATA_FAILED = "security_manager_client/update_user_binary_data_failed",

  DELETE_USER_BINARY_DATA_REQUEST = "security_manager_client/delete user binary data request",
  DELETE_USER_BINARY_DATA_SUCCESS = "security_manager_client/delete_user_binary_data_success",
  DELETE_USER_BINARY_DATA_FAILED = "security_manager_client/delete_user_binary_data_failed",
}

const ActionType = SecurityManagerClientActionType;

const withAnalytic = (actionType: SecurityManagerClientActionType) =>
  identityAction<SecurityManagerClientActionType>(actionType, "");

const withSuccessHandler = (trackEvent: () => void) => (response: any) => {
  trackEvent();
  return Promise.resolve(response);
};

const withErrorHandler = (trackEvent: (analytic: string) => void) => (response: RestClientError) => {
  const { analytic } = response;
  trackEvent(analytic);
  return Promise.reject(response);
};

const Analytics = {
  getUserBinaryDataRequest: withAnalytic(ActionType.GET_USER_BINARY_DATA_REQUEST),
  getUserBinaryDataSuccess: withAnalytic(ActionType.GET_USER_BINARY_DATA_SUCCESS),
  getUserBinaryDataFailed: withAnalytic(ActionType.GET_USER_BINARY_DATA_FAILED),

  updateUserBinaryDataRequest: withAnalytic(ActionType.UPDATE_USER_BINARY_DATA_REQUEST),
  updateUserBinaryDataSuccess: withAnalytic(ActionType.UPDATE_USER_BINARY_DATA_SUCCESS),
  updateUserBinaryDataFailed: withAnalytic(ActionType.UPDATE_USER_BINARY_DATA_FAILED),

  deleteUserBinaryDataRequest: withAnalytic(ActionType.DELETE_USER_BINARY_DATA_REQUEST),
  deleteUserBinaryDataSuccess: withAnalytic(ActionType.DELETE_USER_BINARY_DATA_SUCCESS),
  deleteUserBinaryDataFailed: withAnalytic(ActionType.DELETE_USER_BINARY_DATA_FAILED),
};

type GetUserBinaryDataApi = (userId: string,
                             name: string,
                             selfAuthorized?: boolean) => Promise<any>;

type UpdateUserBinaryDataApi = (userId: string,
                                name: string,
                                file: File,
                                selfAuthorized?: boolean) => Promise<any>;

type DeleteUserBinaryDataApi = (userId: string,
                                name: string,
                                selfAuthorized?: boolean) => Promise<any>;

export interface UseSecurityManagerClientApi {
  getUserBinaryData: GetUserBinaryDataApi;
  updateUserBinaryData: UpdateUserBinaryDataApi;
  deleteUserBinaryData: DeleteUserBinaryDataApi;
}

export const useGetUserBinaryDataApi = (): GetUserBinaryDataApi => {

  const dispatch = useDispatch();

  const authToken = React.useContext(AuthTokenContext);

  return React.useCallback((userId: string = "",
                            name: string = "",
                            selfAuthorized?: boolean) => {

    dispatch(Analytics.getUserBinaryDataRequest());

    return UserIdmLegacyClient.getUserBinaryData(authToken, userId, name, selfAuthorized)
      .then(
        withSuccessHandler(() => dispatch(Analytics.getUserBinaryDataSuccess())),
        withErrorHandler(analytic => dispatch(Analytics.getUserBinaryDataFailed(analytic))));

  }, [authToken, dispatch]);
};

export const useUpdateUserBinaryDataApi = (): UpdateUserBinaryDataApi => {

  const dispatch = useDispatch();

  const authToken = React.useContext(AuthTokenContext);

  return React.useCallback((userId: string = "",
                            name: string = "",
                            file: File,
                            selfAuthorized?: boolean) => {

    dispatch(Analytics.updateUserBinaryDataRequest());

    return UserIdmLegacyClient.updateUserBinaryData(authToken, userId, name, file, selfAuthorized)
      .then(
        withSuccessHandler(() => dispatch(Analytics.updateUserBinaryDataSuccess())),
        withErrorHandler(analytic => dispatch(Analytics.updateUserBinaryDataFailed(analytic))));

  }, [authToken, dispatch]);
};

export const useDeleteUserBinaryDataApi = (): DeleteUserBinaryDataApi => {

  const dispatch = useDispatch();

  const authToken = React.useContext(AuthTokenContext);

  return React.useCallback((userId: string = "",
                            name: string = "",
                            selfAuthorized?: boolean) => {

    dispatch(Analytics.deleteUserBinaryDataRequest());

    return UserIdmLegacyClient.deleteUserBinaryData(authToken, userId, name, selfAuthorized)
      .then(
        withSuccessHandler(() => dispatch(Analytics.deleteUserBinaryDataSuccess())),
        withErrorHandler(analytic => dispatch(Analytics.deleteUserBinaryDataFailed(analytic))));

  }, [authToken, dispatch]);
};

export const useSecurityManagerClient = (): UseSecurityManagerClientApi => ({
  getUserBinaryData: useGetUserBinaryDataApi(),
  updateUserBinaryData: useUpdateUserBinaryDataApi(),
  deleteUserBinaryData: useDeleteUserBinaryDataApi(),
});

export default useSecurityManagerClient;
