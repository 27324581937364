import Color from "@Color";
import { StyleRules } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { primaryColor } from "@modules/createUser/components/styles";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 16,
    },
    textBox: {
      fontFamily: "Roboto, sans-serif",
    },
    checkbox: {
      color: primaryColor,
      padding: 0,
    },
    inputField: {
      flex: "1 0 auto",
    },
    requiredContainer: {
      flex: "0 0 auto",
      justifySelf: "flex-end",
      justifyContent: "flex-end",
      margin: "10px 0 10px 0",
    },
    feedbackInfo: {
      color: Color.TEXT,
      fontSize: 14,
      fontWeight: 300,
      marginTop: 10,
    }
  });

export const formControlLabel: StyleRules<string> = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "16px",
  },
  label: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "16px",
    marginLeft: 8,
  },
};

export const textField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "10px",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& textarea": {
      fontSize: 14,
      fontWeight: 400,
      padding: 0,
      margin: 0,
    },
    "& input": {
      fontSize: 14,
      fontWeight: 400,
      padding: "18.5px 14px",
      margin: 0,
    },
  },
};

export default styles;
