import { createStyles } from "@material-ui/core/styles";
import { UsersIcon } from "@icons";
import Color from "@Color";

export const PrimaryIcon = UsersIcon;

export const primaryColor = Color.MODULES;

export const selectEmailTemplate = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
    },
    title: {
      margin: 0,
      fontWeight: 300,
    },
    htmlPreview: {
      flex: "1 0 auto",
      marginTop: 20,
    },
  });

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    error: {
      margin: "0 0 15px 0",
    },
    controls: {
      marginTop: 15,
      marginLeft: "auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    cancelButton: {
    },
    cancelButtonLabel: {
      color: Color.BLACK,
    },
    saveButton: {
      marginLeft: 25,
    },
  });

export default styles;
