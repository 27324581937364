import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { getComments, getCommentErrorMessage, isCommentLoadingIndicatorVisible } from "../selectors";
import { Model, CommentsListView } from "@components/request-comments/CommentsListView";

const mapStateToProps = (state: AppSchema): Model => ({
  items: getComments(state),
  errorMessage: getCommentErrorMessage(state),
  loadingIndicator: isCommentLoadingIndicatorVisible(state),
});

export default connect<Model, {}, Model>(
  mapStateToProps,
)(CommentsListView);
