import { Module } from "@data";
import { initialize } from "./actions";
import { createModule } from "@modules/base";
import { PolicyManagementView, } from "./PolicyManagementView";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import { PolicyOperation, PolicyOperationPrincipal } from "@hooks";
import { getManageGroupPolicyBaseUrl, getManageServicePolicyBaseUrl, getManageUserPolicyBaseUrl, } from "./helpers";

type RouteParams = { id?: string; action?: PolicyOperation };

export const manageUserEffectivePoliciesModule: Module = createModule<RouteParams>({
  id: "manage_user_effective_policies",
  path: `${getManageUserPolicyBaseUrl(":id")}/:action`,
  exactPath: true,
  strictPath: true,
  name: "Manage User Policies",
  title: "Manage User Policies",
  className: "manageUserEffectivePoliciesModule",
  primaryColor,
  icon,
  view: PolicyManagementView,
  onMount: ({ id, action,  }: RouteParams = {}) =>
    initialize({ id, action, principalType: PolicyOperationPrincipal.USER }),
});

export const manageServiceEffectivePoliciesModule: Module = createModule<RouteParams>({
  id: "manage_service_effective_policies",
  path: `${getManageServicePolicyBaseUrl(":id")}/:action`,
  exactPath: true,
  strictPath: true,
  name: "Manage Service Policies",
  title: "Manage Service Policies",
  className: "manageServiceEffectivePoliciesModule",
  primaryColor,
  icon,
  view: PolicyManagementView,
  onMount: ({ id, action,  }: RouteParams = {}) =>
    initialize({ id, action, principalType: PolicyOperationPrincipal.SERVICE }),
});

export const manageGroupEffectivePoliciesModule: Module = createModule<RouteParams>({
  id: "manage_group_effective_policies",
  path: `${getManageGroupPolicyBaseUrl(":id")}/:action`,
  exactPath: true,
  strictPath: true,
  name: "Manage Group Policies",
  title: "Manage Group Policies",
  className: "manageGroupEffectivePoliciesModule",
  primaryColor,
  icon,
  view: PolicyManagementView,
  onMount: ({ id, action,  }: RouteParams = {}) =>
    initialize({ id, action, principalType: PolicyOperationPrincipal.GROUP }),
});
