import React from "react";
import { noop } from "@util";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { DEFAULT_SCHEMA, JsonSchemaDefinition, JsonSchemaMetadata } from "@data";
import { SchemaWizardActions } from "../actions/schemaWizard";
import { SchemaWizardSelectors } from "../selectors/schemaWizard";
import { SchemaWizardStep as Step } from "@modules/schemaWizard/reducers/schemaWizard";
import SchemaWizard, { Actions, Model } from "../components/SchemaWizard";
import NamespaceView from "./NamespaceView";
import NameView from "./NameView";
import SchemaEditorView from "./SchemaEditorView";
import ReviewView from "./ReviewView";

export interface SchemaWizardContainerModel extends Model {
  editMode?: boolean;
  snackbarId?: string;
  successMessage?: string;
  metadata?: JsonSchemaMetadata;
  schema?: JsonSchemaDefinition;
  createdSchema?: JsonSchemaMetadata;
}

export interface SchemaWizardContainerActions extends Actions {
  initialize?: (metadata: JsonSchemaMetadata, schema: JsonSchemaDefinition, editMode?: boolean) => void;
  onSaveSchemaSuccess?: (schema: JsonSchemaMetadata) => void;
}

export type SchemaWizardContainerProps = SchemaWizardContainerModel & SchemaWizardContainerActions;

function SchemaWizardContainer(props: SchemaWizardContainerProps) {

  const {
    editMode,
    metadata = JsonSchemaMetadata.EMPTY,
    schema = { ...DEFAULT_SCHEMA },
    createdSchema = JsonSchemaMetadata.EMPTY,
    initialize = noop,
    onSaveSchemaSuccess = noop,
    ...otherProps
  } = props;

  const [initialized, setInitialized] = React.useState(false);

  const onSuccess = React.useCallback(() => {
    onSaveSchemaSuccess(createdSchema);
  }, [createdSchema, onSaveSchemaSuccess]);

  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      initialize(metadata, schema, editMode);
    }
  }, [initialized, metadata, schema, editMode, setInitialized, initialize]);

  if (!initialized) {
    return null;
  }

  return (
    <SchemaWizard
      {...otherProps}
      onSuccess={onSuccess}
    >
      <NamespaceView />
      <NameView />
      <SchemaEditorView />
      <ReviewView />
    </SchemaWizard>
  );
}

const mapStateToProps = (state: AppSchema, ownProps: SchemaWizardContainerModel): SchemaWizardContainerModel => ({
  currentState: SchemaWizardSelectors.getSchemaWizardState(state),
  defaultState: SchemaWizardSelectors.getSchemaWizardDefaultState(state),
  createdSchema: SchemaWizardSelectors.getCreatedSchema(state),
  successMessage: SchemaWizardSelectors.getSuccessMessage(state),
  errorMessage: SchemaWizardSelectors.getErrorMessage(state),
  errorTitle: SchemaWizardSelectors.getErrorTitle(state),
  showLoadingIndicator: SchemaWizardSelectors.isLoadingIndicatorVisible(state),
  showSuccessIndicator: SchemaWizardSelectors.isSuccessMessageVisible(state),
  saveButtonDisabled: !SchemaWizardSelectors.isSaveButtonEnabled(state),
  currentStep: SchemaWizardSelectors.getSchemaWizardStep(state),
  steps: SchemaWizardSelectors.getSteps(state),
  completedSteps: SchemaWizardSelectors.getCompletedSteps(state),
  disabledSteps: SchemaWizardSelectors.getDisabledSteps(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: SchemaWizardContainerActions): SchemaWizardContainerActions => ({
  previousStep: () => dispatch(SchemaWizardActions.showPreviousStep()),
  nextStep: () => dispatch(SchemaWizardActions.showNextStep()),
  save: () => dispatch(SchemaWizardActions.save()),
  setCurrentStep: (step: Step) => dispatch(SchemaWizardActions.setSchemaWizardStep(step)),
  initialize: (metadata: JsonSchemaMetadata, schema: JsonSchemaDefinition, editMode?: boolean) =>
    dispatch(SchemaWizardActions.initialize(metadata, schema, editMode)),
  ...ownProps,
});

export default connect<SchemaWizardContainerModel, SchemaWizardContainerActions, SchemaWizardContainerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SchemaWizardContainer);
