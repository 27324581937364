export const MODULE_ID = "quality_reports";
export const MODULE_TITLE = "Quality Reports";
export const MODULE_PATH = "/quality-reports";
export const SECURITY_SERVICES_QUALITY_REPORTS_PATH = `${MODULE_PATH}/security-services`;
export const DEVICE_MANAGEMENT_QUALITY_REPORTS_PATH = `${MODULE_PATH}/device-management`;
export const DATA_AND_ANALYTICS_QUALITY_REPORTS_PATH = `${MODULE_PATH}/data-and-analytics`;
export const COMMON_SERVICES_QUALITY_REPORTS_PATH = `${MODULE_PATH}/common-services`;
export const SELF_SERVICE_TOOLS_QUALITY_REPORTS_PATH = `${MODULE_PATH}/self-service-tools`;
export const MODULE_PATHS = [
  MODULE_PATH,
  SECURITY_SERVICES_QUALITY_REPORTS_PATH,
  DEVICE_MANAGEMENT_QUALITY_REPORTS_PATH,
  DATA_AND_ANALYTICS_QUALITY_REPORTS_PATH,
  COMMON_SERVICES_QUALITY_REPORTS_PATH,
  SELF_SERVICE_TOOLS_QUALITY_REPORTS_PATH,
];

export { MODULE_PATH as BASE_URL };
