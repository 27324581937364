import { createActions } from "@modules/base/createActions";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";

export const {
  nameFilter: setNameFilter,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setNameFilter());
  return dispatch(baseReset());
};

export const refresh = () => (dispatch: any) => {
  return dispatch(reset());
};

export const onMount = () => () => {
  return Promise.resolve();
};

export const initialize = () => (dispatch: any) => {
  return dispatch(reset());
};
