import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    dataSetType: {
      fontSize: 11,
    },
  });

export default styles;
