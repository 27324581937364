import React from "react";
import classnames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import MuiFab, { FabProps as MuiFabProps } from "@material-ui/core/Fab";
import styles from "./styles";

const CustomMuiFab = withStyles(styles)(MuiFab);

export interface CustomMuiFabProps extends MuiFabProps {
}

export type FabProps<C extends React.ElementType = "button", P = {}> =
  MuiFabProps<C, {component?: C}> & CustomMuiFabProps;

export const Fab = React.forwardRef(
  <C extends React.ElementType>(props: FabProps<C>, ref?: React.Ref<any>) => {

    const {
      className,
      children,
      ...otherProps
    } = props;

    return (
      <CustomMuiFab
        ref={ref}
        className={classnames("fab", className)}
        {...otherProps}
      >
        {children}
      </CustomMuiFab>
    );
  });

export default Fab;
