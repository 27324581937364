import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
    },
    fileNameFilter: {
      flex: "1 0 auto",
      marginRight: 10,
    },
    refreshButton: {
      color: Color.SIGNIFY_GREEN,
      marginTop: 10,
      marginLeft: "auto",
      marginRight: 8,
      minWidth: 25,
      minHeight: 25,
      width: 25,
      height: 25,
    },
    refreshIcon: {
      width: 15,
      height: 15,
    },
  });

export default styles;
