import { Record } from "immutable";
import { getStringValue } from "@util";
import { WorkloadDataSetsAttributes, WorkloadType } from "@data/Workload";

export enum WorkloadInstanceState {
  NONE = "NONE",
  QUEUED = "QUEUED",
  RUNNING = "RUNNING",
  PENDING = "PENDING",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
  SKIPPED = "SKIPPED",
}

export interface WorkloadInstanceAttributes {
  completedAt?: string;
  createdBy?: string;
  dataScanned?: number;
  executedBy?: string;
  executionId?: string;
  executionDuration?: number;
  message?: string;
  type?: WorkloadType;
  state?: WorkloadInstanceState;
  submittedAt?: string;
  createdAt?: string;
  dataOutputs?: WorkloadDataSetsAttributes[];
  dataSources?: WorkloadDataSetsAttributes[];
  name?: string;
  version?: number;
}

export class WorkloadInstance extends Record({
  completedAt: "",
  executedBy: "",
  executionId: "",
  type: WorkloadType.FUNCTION,
  state: WorkloadInstanceState.NONE,
  submittedAt: "",
  createdBy: "",
  createdAt: "",
  dataScanned: 0,
  executionDuration: 0,
  message: "",
  dataSources: [],
  dataOutputs: [],
  name: "",
  version: -1,
}) implements WorkloadInstanceAttributes {

  public static EMPTY: WorkloadInstance = new WorkloadInstance();

  public readonly completedAt: string;
  public readonly executedBy: string;
  public readonly executionId: string;
  public readonly type: WorkloadType;
  public readonly state: WorkloadInstanceState;
  public readonly submittedAt: string;
  public readonly createdBy: string;
  public readonly createdAt: string;
  public readonly dataScanned: number;
  public readonly executionDuration: number;
  public readonly message: string;
  public readonly dataOutputs: WorkloadDataSetsAttributes[];
  public readonly dataSources: WorkloadDataSetsAttributes[];
  public readonly name: string;
  public readonly version: number;

  public getCompletedAt(): string {
    return getStringValue(this.completedAt);
  }

  public getExecutedBy(): string {
    return getStringValue(this.executedBy);
  }

  public getExecutedByServiceId(): string {
    const res = getStringValue(this.executedBy).match("principal:service:(.+)");
    return res ? res[1] : "";
  }

  public getExecutionId(): string {
    return getStringValue(this.executionId);
  }

  public getState(): WorkloadInstanceState {
    return this.state;
  }

  public getType(): WorkloadType {
    return this.type;
  }

  public isQueryType(): boolean {
    return this.type === WorkloadType.QUERY;
  }

  public getSubmittedAt(): string {
    return getStringValue(this.submittedAt);
  }

  public getCreatedBy(): string {
    return getStringValue(this.createdBy);
  }

  public getCreatedAt(): string {
    return getStringValue(this.createdAt);
  }

  public getDataScanned(): number {
    return this.dataScanned;
  }

  public getExecutionDuration(): number {
    return this.executionDuration;
  }

  public getMessage(): string {
    return getStringValue(this.message);
  }

  public getDataOutputs(): WorkloadDataSetsAttributes[] {
    return this.dataOutputs;
  }

  public getDataSources(): WorkloadDataSetsAttributes[] {
    return this.dataSources;
  }

  public getName(): string {
    return getStringValue(this.name);
  }

  public getVersion(): number {
    return this.version;
  }
}

export default WorkloadInstance;
