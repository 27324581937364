import React from "react";
import { Module } from "@data";
import { MODULE_ID } from "./constants";
import { equalsIgnoreCase } from "@util";
import { isDialogOpen } from "./selectors";
import { initialize, open } from "./actions";
import { PrimaryIcon } from "@modules/users/components/styles";
import DeleteDataAccessRequestDialog from "./containers/DeleteDataAccessRequestDialog";

export const deleteDataAccessRequestModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "Cancel Data Access Request",
  title: "Cancel Data Access Request",
  icon: PrimaryIcon,
  view: <DeleteDataAccessRequestDialog />,
  onClick: open,
  isDialogOpen,
  initialize,
  disabled: !equalsIgnoreCase("true", process.env.REACT_APP_DATA_GOVERNANCE_MODULES_ENABLED),
};

export default deleteDataAccessRequestModule;
