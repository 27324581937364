import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import ConfirmationDialog from "@components/confirmation-dialog";
import UploadTrainingCert from "@components/upload-training-cert";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { dialog as styles } from "./styles";

export const UploadTrainingCertificateDialog = withStyles(styles)((props: WithStyles<typeof styles> & {
  open?: boolean,
  closeDialog?: () => void,
  onSuccess?: () => void,
}) => {

  const {
    classes,
    open,
    closeDialog = noop,
    onSuccess = noop,
  } = props;

  const success = React.useCallback(() => {
    closeDialog();
    onSuccess();
  }, [closeDialog, onSuccess]);

  return (
    <ConfirmationDialog
      className={classnames("uploadTrainingCertificateDialog")}
      open={open}
      title="Upload Training Certificate"
      hideContinueButton={true}
      cancel={closeDialog}
    >
      <UploadTrainingCert
        className={classnames("uploadTrainingCertificate", classes.uploadBox)}
        successLabel={"Certificate Uploaded"}
        onSuccess={success}
        showAlert={false}
        showTitle={false}
        inDialog={true}
      />
    </ConfirmationDialog>
  );
});

export default UploadTrainingCertificateDialog;
