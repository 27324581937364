import React from "react";
import { useHistory } from "react-router-dom";
import { PUBLISHED_DASHBOARDS_PATH, UNPUBLISHED_DASHBOARDS_PATH } from "./constants";
import { useDashboardsQueryParams } from "@hooks";
import DashboardsTabs from "./components/DashboardsTabs";

export function DashboardsTabsView() {

  const history = useHistory();

  const { selectedTab } = useDashboardsQueryParams();

  const showPublishedView = React.useCallback(() =>
    history.push(PUBLISHED_DASHBOARDS_PATH), [history]);

  const showUnpublishedView = React.useCallback(() =>
    history.push(UNPUBLISHED_DASHBOARDS_PATH), [history]);

  // Do not render the tabs component if we do not recognize the selected tab value
  if (selectedTab === null) {
    return null;
  }

  return (
    <DashboardsTabs
      selectedTab={selectedTab}
      showPublishedView={showPublishedView}
      showUnpublishedView={showUnpublishedView}
    />
  );
}

export default DashboardsTabsView;
