import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import useDigitalShadowClient from "../use-digital-shadow-client";

export interface UseDeleteDSLayoutProps extends Partial<UseApiRequestProps<void>> {
  name: string;
  etag: string;
}

export interface UseDeleteDSLayoutModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UseDeleteDSLayoutActions extends UseApiRequestActions<void> {
  deleteLayout: () => void;
}

type Props = UseDeleteDSLayoutProps;
type Model = UseDeleteDSLayoutModel;
type Actions = UseDeleteDSLayoutActions;
type Result = [ Model, Actions ];

export const useDeleteDSLayout = (props: Props): Result => {

  const {
    name = "",
    etag = "",
    defaultErrorMessage = "Failed to delete shadow layout",
    ...otherProps
  } = props;

  const DigitalShadowClient = useDigitalShadowClient();

  const makeApiRequest = React.useCallback(() =>
      DigitalShadowClient.deleteLayoutApi(name, etag),
    [DigitalShadowClient, name, etag]);

  const [{ showSuccessView, ...baseModel },
    { refresh: deleteLayout, ...baseActions }
  ] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Shadow Layout Deleted" : "",
    [ showSuccessView ]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    etag,
    location,
    successMessage,
    showSuccessView
  }), [
    baseModel,
    etag,
    location,
    successMessage,
    showSuccessView
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    deleteLayout,
    refresh: deleteLayout,
  }), [
    baseActions,
    deleteLayout,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDeleteDSLayout;
