import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base";

export interface SecurityGroupsSchema extends BasePortalModuleSchema {
  searchQuery: string;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<SecurityGroupsSchema>({
  searchQuery: "",
});

type SecurityGroupsActionType = PortalModuleActionTypes<SecurityGroupsSchema>;

export const ACTION_TYPES: SecurityGroupsActionType =
  createPortalModuleActionTypes<SecurityGroupsSchema>(MODULE_ID, DEFAULT_STATE);

export const SecurityGroupsReducer = combineReducers<SecurityGroupsSchema>(
  createPortalModuleReducers<SecurityGroupsSchema>(ACTION_TYPES, DEFAULT_STATE)
);

export default SecurityGroupsReducer;
