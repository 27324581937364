import React from "react";
import classnames from "classnames";
import { AccessRequestType } from "@data";
import TextField from "@components/text-field";
import DropdownMenu from "@components/dropdown-menu";
import Typography from "@material-ui/core/Typography";
import { formEventHandler, isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export type AccessRequestTypeLabels<K extends keyof any = AccessRequestType> = {
  [P in K]: string;
};

export const DEFAULT_ACCESS_REQUEST_TYPES = [
  AccessRequestType.READ,
  AccessRequestType.WRITE,
  AccessRequestType.READ_WRITE,
];

export const DEFAULT_ACCESS_REQUEST_TYPE_LABELS: AccessRequestTypeLabels = {
  [AccessRequestType.NONE]: "",
  [AccessRequestType.READ]: "Read",
  [AccessRequestType.WRITE]: "Write",
  [AccessRequestType.READ_WRITE]: "Read and Write",
};

export const DEFAULT_ACCESS_REQUEST_TYPES_HELPER_TEXT =
  "Select the type of access you are requesting to this data set";

export const CROSS_ACCOUNT_ACCESS_REQUEST_TYPES_HELPER_TEXT =
  "Cross account data access requests only support READ access";

export interface Model {
  className?: string;
  title?: string;
  subtitle?: string;
  reason?: string;
  reasonLabel?: string;
  accessRequestTypes?: AccessRequestType[];
  accessRequestType?: AccessRequestType;
  accessRequestTypeLabels?: AccessRequestTypeLabels;
  accessRequestTypesHelperText?: string;
  hideAccessRequestTypesDropdownMenu?: boolean;
}

export interface Actions {
  setReason?: (reason: string) => void;
  setAccessRequestType?: (accessRequestType: AccessRequestType) => void;
  mapAccessRequestTypeToLabel?: (accessRequestType: AccessRequestType) => React.ReactNode | string | null;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const TypeView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title: customTitle = "",
    subtitle,
    reason = "",
    reasonLabel = "Please enter the reason - or purpose - for requesting access to this data set",
    accessRequestTypes = DEFAULT_ACCESS_REQUEST_TYPES,
    accessRequestType = AccessRequestType.READ,
    accessRequestTypeLabels = DEFAULT_ACCESS_REQUEST_TYPE_LABELS,
    accessRequestTypesHelperText = DEFAULT_ACCESS_REQUEST_TYPES_HELPER_TEXT,
    hideAccessRequestTypesDropdownMenu,
    setReason = noop,
    setAccessRequestType = noop,
    mapAccessRequestTypeToLabel = React.useCallback((type: AccessRequestType) =>
      accessRequestTypeLabels[type] || null, [accessRequestTypeLabels]),
    children,
  } = props;

  const title = React.useMemo(() => !isEmptyString(customTitle) ? customTitle : (
    accessRequestTypes.length > 1
      ? "Set Access Request Type & Reason"
      : "Set Access Request Reason"
  ), [customTitle, accessRequestTypes]);

  return (
    <div className={classnames("typeView", className, classes.typeView)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      {subtitle && (
        <Typography className={classnames("subtitle", classes.subtitle)} variant="h5">
          {subtitle}
        </Typography>
      )}
      {!hideAccessRequestTypesDropdownMenu && (
        <DropdownMenu
          className={classnames("accessRequestType", classes.dropdownMenu)}
          hideEmptyValue={true}
          dropdownMenuLabel="Access Request Type"
          dropdownMenuLabelClassName={classes.dropdownMenuLabel}
          dropdownMenuHint={accessRequestTypesHelperText}
          values={accessRequestTypes}
          selectedValue={accessRequestType}
          setSelectedValue={setAccessRequestType}
          mapValueToLabel={mapAccessRequestTypeToLabel}
        />
      )}
      <TextField
        className={classnames("reason", classes.textarea)}
        color="secondary"
        name="reason"
        minRows={4}
        maxRows={4}
        value={reason}
        required={true}
        fullWidth={true}
        multiline={true}
        autoFocus={false}
        variant="outlined"
        label={reasonLabel}
        onChange={formEventHandler(setReason)}
      />
      {children}
    </div>
  );
});

export default TypeView;
