import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { usePortalSnackbar } from "@components/portal-snackbar-provider";
import {
  getClassName,
  getErrorMessage,
  getErrorTitle,
  getSaveButtonLabel,
  getSnackbarId,
  getSuccessMessage,
  getTitle,
  isLoadingIndicatorVisible,
  isSaveButtonEnabled,
  isSuccessMessageVisible,
  showLastStep,
  showFirstStep,
  isPreviousButtonEnabled,
} from "../selectors";
import { save, showNextStep, showPreviousStep } from "../actions";
import GroupManagementWizard, { Actions, Model } from "../components/GroupManagementWizard";

export interface ContainerModel extends Model {
  snackbarId?: string;
  successMessage?: string;
}

export interface ContainerActions extends Actions {
  onSuccess?: () => void;
}

type Props = ContainerModel & ContainerActions & {
  children?: React.ReactNode;
};

const GroupManagementWizardContainer = (props: Props) => {

  const {
    snackbarId = "group-management-wizard",
    successMessage,
    onSuccess,
    children,
    ...otherProps
  } = props;

  const { errorMessage } = otherProps;

  usePortalSnackbar(snackbarId, {
    errorMessage,
    successMessage,
    onSuccessMessageShown: onSuccess,
    successCallbackDelay: 750,
  });

  return (
    <GroupManagementWizard {...otherProps}>
      {children}
    </GroupManagementWizard>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  className: getClassName(state),
  title: getTitle(state),
  snackbarId: getSnackbarId(state),
  errorTitle: getErrorTitle(state),
  errorMessage: getErrorMessage(state),
  showLoadingIndicator: isLoadingIndicatorVisible(state),
  showSuccessIndicator: isSuccessMessageVisible(state),
  saveButtonLabel: getSaveButtonLabel(state),
  saveButtonDisabled: !isSaveButtonEnabled(state),
  previousStepButtonDisabled: !isPreviousButtonEnabled(state),
  successMessage: getSuccessMessage(state),
  showSelectGroupsView: showFirstStep(state),
  showReviewView: showLastStep(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  save: () => dispatch(save()),
  nextStep: () => dispatch(showNextStep()),
  previousStep: () => dispatch(showPreviousStep()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(GroupManagementWizardContainer);
