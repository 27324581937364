import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { SecurityGroupsList, SecurityGroupsListModel, SecurityGroupsListActions } from "@components/security-groups-list";
import { groupsView as styles } from "./styles";

export interface Model extends SecurityGroupsListModel {
  title?: string;
}

export interface Actions extends SecurityGroupsListActions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const GroupsView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Select Device Type Authorization Groups",
    ...otherProps
  } = props;

  return (
    <div className={classnames("groupsView", classes.container)}>
      <label className={classnames("title", classes.title)}>
        {title}
      </label>
      <SecurityGroupsList
        {...otherProps}
        className={classnames("securityGroupsList", className, classes.securityGroupsList)}
      />
    </div>
  );
});

export default GroupsView;
