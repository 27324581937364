import { Record } from "immutable";
import { DataSetType } from "@data";
import { equalsIgnoreCase, getStringValue, isEmptyString } from "@util";

export interface DataSetsListItemDataAttributes {
  id: string;
  name: string;
  description: string;
  account: string;
  dataSetType: DataSetType;
  tags: string[];
  containsPII: boolean;
  href: string;
  dataSetGroup: string;
}

export class DataSetsListItemData extends Record({
  id: "",
  name: "",
  description: "",
  account: "",
  dataSetType: DataSetType.NONE,
  tags: [],
  containsPII: false,
  href: "",
  dataSetGroup: "",
}) implements DataSetsListItemDataAttributes {

  public static EMPTY: DataSetsListItemData = new DataSetsListItemData();

  public readonly id: string;
  public readonly name: string;
  public readonly description: string;
  public readonly account: string;
  public readonly dataSetType: DataSetType;
  public readonly tags: string[];
  public readonly containsPII: boolean;
  public readonly href: string;
  public readonly dataSetGroup: string;

  public static fromWorkloadDataSetAttributes(dataSetAlias: string, accountId: string): DataSetsListItemData {
    return new DataSetsListItemData({
      id: `lrn:iot:dmg::${accountId}:dataset:alias:${dataSetAlias}`,
      name: dataSetAlias,
      account: accountId,
      href: `/data-sets/lrn:iot:dmg::${accountId}:dataset:alias:${dataSetAlias}`
    });
  }

  public equals(other: DataSetsListItemData): boolean {
    return equalsIgnoreCase(this.getId(), other.getId());
  }

  public getId(): string {
    return getStringValue(this.id);
  }

  public getName(): string {
    return getStringValue(this.name);
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public getAccount(): string {
    return getStringValue(this.account);
  }

  public getAccountId(): string {
    const account = getStringValue(this.account);
    const result = account.split(/[\(\)]/).filter(it => !isEmptyString(it));
    return getStringValue(result[0]);
  }

  public getAccountAlias(): string {
    const account = getStringValue(this.account);
    const result = account.split(/[\(\)]/).filter(it => !isEmptyString(it));
    return result.length < 2 ? "" : getStringValue(result.pop());
  }

  public getDataSetType(): DataSetType {
    return this.dataSetType;
  }

  public getTags(): string[] {
    const tags = this.tags;
    return !Array.isArray(tags) ? [] : tags.filter(tag => !isEmptyString(tag));
  }

  public getContainsPII(): boolean {
    return this.containsPII;
  }

  public getHref(): string {
    return getStringValue(this.href);
  }

  public getDataSetGroup(): string {
    return getStringValue(this.dataSetGroup);
  }
}

export default DataSetsListItemData;
