import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "../base/createReducers";

export enum ChangePasswordSteps {
  PASSWORD = "password",
  CONFIRM = "confirm",
}

export interface ChangePasswordSchema extends BasePortalModuleSchema {
  userId: string;
  password: string;
  step: ChangePasswordSteps;
  showPassword: boolean;
  open: boolean;
}

export const DEFAULT_STATE: ChangePasswordSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  userId: "",
  password: "",
  step: ChangePasswordSteps.PASSWORD,
  showPassword: false,
  open: false,
});

export type ChangePasswordActionTypes = PortalModuleActionTypes<ChangePasswordSchema> & {
  CHANGE_PASSWORD_REQUEST: string;
  CHANGE_PASSWORD_SUCCESS: string;
  CHANGE_PASSWORD_FAILED: string;
};

export type ChangePasswordActionType = ChangePasswordActionTypes[keyof ChangePasswordActionTypes];

export const ACTION_TYPES: ChangePasswordActionTypes = {
  ...createPortalModuleActionTypes<ChangePasswordSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    CHANGE_PASSWORD_REQUEST: `${MODULE_ID}/change_password_request`,
    CHANGE_PASSWORD_SUCCESS: `${MODULE_ID}/change_password_success`,
    CHANGE_PASSWORD_FAILED: `${MODULE_ID}/change_password_failed`,
  },
};

export const ChangePasswordReducer = combineReducers<ChangePasswordSchema>(
  createPortalModuleReducers<ChangePasswordSchema>(ACTION_TYPES, DEFAULT_STATE));

export default ChangePasswordReducer;
