import { AppSchema } from "@schemas";
import { getSchemaAction } from "../selectors";
import { SchemasActions } from "@modules/schemas/actions";
import { getAuthToken, getCurrentAccountId } from "@main/selectors";
import { emptyAction, identityAction, isValidNumber, ReduxAction } from "@util";
import { DEFAULT_STATE, SchemaAction, SchemaStateManagerActionType } from "../reducers";
import { JsonSchemaMetadata, JsonSchemaMetadataAttributes, JsonSchemaState } from "@data";
import { ETagHeaders, ETagLocationHeaders, RestClientError, SchemaRegistryClient } from "@network";

type ActionType = SchemaStateManagerActionType;
type Action = ReduxAction<ActionType>;

export const setShowDialog = identityAction<ActionType, boolean>(
  SchemaStateManagerActionType.SET_SHOW_DIALOG, DEFAULT_STATE.showDialog);

export const setSchema = identityAction<ActionType, JsonSchemaMetadataAttributes>(
  SchemaStateManagerActionType.SET_SCHEMA, DEFAULT_STATE.schema);

export const setSchemaAction = identityAction<ActionType, SchemaAction>(
  SchemaStateManagerActionType.SET_SCHEMA_ACTION, DEFAULT_STATE.schemaAction);

export const setErrorMessage = identityAction<ActionType, string>(
  SchemaStateManagerActionType.SET_ERROR_MESSAGE, DEFAULT_STATE.errorMessage);

export const setSuccessMessage = identityAction<ActionType, string>(
  SchemaStateManagerActionType.SET_SUCCESS_MESSAGE, DEFAULT_STATE.successMessage);

export const setShowAccessDenied = identityAction<ActionType, boolean>(
  SchemaStateManagerActionType.SET_SHOW_ACCESS_DENIED, DEFAULT_STATE.showAccessDenied);

export const setShowProgressIndicator = identityAction<ActionType, boolean>(
  SchemaStateManagerActionType.SET_SHOW_PROGRESS_INDICATOR, DEFAULT_STATE.showProgressIndicator);

export const draftNewSchemaVersionRequest = emptyAction<ActionType>(
  SchemaStateManagerActionType.DRAFT_NEW_SCHEMA_VERSION_REQUEST);

export const draftNewSchemaVersionSuccess = identityAction<ActionType, string>(
  SchemaStateManagerActionType.DRAFT_NEW_SCHEMA_VERSION_SUCCESS, "");

export const draftNewSchemaVersionFailed = identityAction<ActionType, string>(
  SchemaStateManagerActionType.DRAFT_NEW_SCHEMA_VERSION_FAILED, "");

export const promoteSchemaRequest = emptyAction<ActionType>(
  SchemaStateManagerActionType.PROMOTE_SCHEMA_REQUEST);

export const promoteSchemaRequestSuccess = identityAction<ActionType, string>(
  SchemaStateManagerActionType.PROMOTE_SCHEMA_REQUEST_SUCCESS, "");

export const promoteSchemaRequestFailed = identityAction<ActionType, string>(
  SchemaStateManagerActionType.PROMOTE_SCHEMA_REQUEST_FAILED, "");

export const deprecateSchemaRequest = emptyAction<ActionType>(
  SchemaStateManagerActionType.DEPRECATE_SCHEMA_REQUEST);

export const deprecateSchemaRequestSuccess = identityAction<ActionType, string>(
  SchemaStateManagerActionType.DEPRECATE_SCHEMA_REQUEST_SUCCESS, "");

export const deprecateSchemaRequestFailed = identityAction<ActionType, string>(
  SchemaStateManagerActionType.DEPRECATE_SCHEMA_REQUEST_FAILED, "");

export const decommissionSchemaRequest = emptyAction<ActionType>(
  SchemaStateManagerActionType.DECOMMISSION_SCHEMA_REQUEST);

export const decommissionSchemaRequestSuccess = identityAction<ActionType, string>(
  SchemaStateManagerActionType.DECOMMISSION_SCHEMA_REQUEST_SUCCESS, "");

export const decommissionSchemaRequestFailed = identityAction<ActionType, string>(
  SchemaStateManagerActionType.DECOMMISSION_SCHEMA_REQUEST_FAILED, "");

export const deleteSchemaRequest = emptyAction<ActionType>(
  SchemaStateManagerActionType.DELETE_SCHEMA_REQUEST);

export const deleteSchemaRequestSuccess = identityAction<ActionType, string>(
  SchemaStateManagerActionType.DELETE_SCHEMA_REQUEST_SUCCESS, "");

export const deleteSchemaRequestFailed = identityAction<ActionType, string>(
  SchemaStateManagerActionType.DELETE_SCHEMA_REQUEST_FAILED, "");

export const clearErrorMessage = (): Action => setErrorMessage("");
export const clearSuccessMessage = (): Action => setSuccessMessage("");

export const showDialog = (): Action => setShowDialog(true);
export const hideDialog = (): Action => setShowDialog(false);

export const showAccessDenied = (): Action => setShowAccessDenied(true);
export const hideAccessDenied = (): Action => setShowAccessDenied(false);

export const showProgressIndicator = (): Action => setShowProgressIndicator(true);
export const hideProgressIndicator = (): Action => setShowProgressIndicator(false);

export const draftNewSchemaVersion = (schema: JsonSchemaMetadata = JsonSchemaMetadata.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();
    const authToken = getAuthToken(state);
    const accountId = getCurrentAccountId(state);
    const namespace = schema.namespace;
    const name = schema.name;
    const version = schema.version;
    const schemaId = schema.getId();

    dispatch(showProgressIndicator());
    dispatch(hideAccessDenied());
    dispatch(clearErrorMessage());
    dispatch(clearSuccessMessage());
    dispatch(draftNewSchemaVersionRequest());

    return SchemaRegistryClient.draftNewSchemaVersion(authToken, namespace, name)
      .then((response: ETagLocationHeaders) => {

        const { version: updatedVersion = 0 } = response;

        dispatch(draftNewSchemaVersionSuccess(accountId));
        dispatch(SchemasActions.reloadItems());

        // Make sure the response contained the updated version number
        if (isValidNumber(updatedVersion) && updatedVersion > version) {
          const { nameAndVersion = `${namespace}:${name}:${updatedVersion}` } = response;
          dispatch(setSchema(JsonSchemaMetadata.fromNameAndVersion(nameAndVersion).toJS()));
        }

        dispatch(hideProgressIndicator());
        return dispatch(setSuccessMessage("Drafted new schema from version: " + schemaId));

      }, (response: RestClientError) => {

        const { analytic, status, error = "Failed to draft new schema version from: " + schemaId } = response;

        dispatch(draftNewSchemaVersionFailed(`${accountId}:${analytic}`));
        dispatch(hideProgressIndicator());

        if (status === 403) {
          dispatch(showAccessDenied());
        }

        return dispatch(setErrorMessage(error));
      });
  };

export const promoteSchema = (schema: JsonSchemaMetadata = JsonSchemaMetadata.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();
    const authToken = getAuthToken(state);
    const accountId = getCurrentAccountId(state);
    const namespace = schema.namespace;
    const name = schema.name;
    const version = schema.version + "";
    const etag = schema.etag;
    const schemaId = schema.getId();

    dispatch(showProgressIndicator());
    dispatch(hideAccessDenied());
    dispatch(clearErrorMessage());
    dispatch(clearSuccessMessage());
    dispatch(promoteSchemaRequest());

    return SchemaRegistryClient.promoteSchema(authToken, namespace, name, version, etag)
      .then((response: ETagHeaders) => {

        const { etag: updatedEtag = etag } = response;

        const updatedSchema = new JsonSchemaMetadata({
          ...schema.toJS(),
          etag: updatedEtag,
          state: JsonSchemaState.RELEASED,
        });

        dispatch(promoteSchemaRequestSuccess(accountId));
        dispatch(SchemasActions.schemaUpdated(updatedSchema));
        dispatch(hideProgressIndicator());
        return dispatch(setSuccessMessage("Promoted schema: " + schemaId));

      }, (response: RestClientError) => {

        const { analytic, status, error = "Failed to promote schema: " + schemaId } = response;

        dispatch(promoteSchemaRequestFailed(`${accountId}:${analytic}`));
        dispatch(hideProgressIndicator());

        if (status === 403) {
          dispatch(showAccessDenied());
        }

        return dispatch(setErrorMessage(error));
      });
  };

export const deprecateSchema = (schema: JsonSchemaMetadata = JsonSchemaMetadata.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();
    const authToken = getAuthToken(state);
    const accountId = getCurrentAccountId(state);
    const namespace = schema.namespace;
    const name = schema.name;
    const version = schema.version + "";
    const etag = schema.etag;
    const schemaId = schema.getId();

    dispatch(showProgressIndicator());
    dispatch(hideAccessDenied());
    dispatch(clearErrorMessage());
    dispatch(clearSuccessMessage());
    dispatch(deprecateSchemaRequest());

    return SchemaRegistryClient.deprecateSchema(authToken, namespace, name, version, etag)
      .then((response: ETagHeaders) => {

        const { etag: updatedEtag = etag } = response;

        const updatedSchema = new JsonSchemaMetadata({
          ...schema.toJS(),
          etag: updatedEtag,
          state: JsonSchemaState.DEPRECATED,
        });

        dispatch(deprecateSchemaRequestSuccess(accountId));
        dispatch(SchemasActions.schemaUpdated(updatedSchema));
        dispatch(hideProgressIndicator());
        return dispatch(setSuccessMessage("Deprecated schema: " + schemaId));

      }, (response: RestClientError) => {

        const { analytic, status, error = "Failed to deprecate schema: " + schemaId } = response;

        dispatch(deprecateSchemaRequestFailed(`${accountId}:${analytic}`));
        dispatch(hideProgressIndicator());

        if (status === 403) {
          dispatch(showAccessDenied());
        }

        return dispatch(setErrorMessage(error));
      });
  };

export const decommissionSchema = (schema: JsonSchemaMetadata = JsonSchemaMetadata.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();
    const authToken = getAuthToken(state);
    const accountId = getCurrentAccountId(state);
    const namespace = schema.namespace;
    const name = schema.name;
    const version = schema.version + "";
    const etag = schema.etag;
    const schemaId = schema.getId();

    dispatch(showProgressIndicator());
    dispatch(hideAccessDenied());
    dispatch(clearErrorMessage());
    dispatch(clearSuccessMessage());
    dispatch(decommissionSchemaRequest());

    return SchemaRegistryClient.decommissionSchema(authToken, namespace, name, version, etag)
      .then((response: ETagHeaders) => {

        const { etag: updatedEtag = etag } = response;

        const updatedSchema = new JsonSchemaMetadata({
          ...schema.toJS(),
          etag: updatedEtag,
          state: JsonSchemaState.DECOMMISSIONED,
        });

        dispatch(decommissionSchemaRequestSuccess(accountId));
        dispatch(SchemasActions.schemaUpdated(updatedSchema));
        dispatch(hideProgressIndicator());
        return dispatch(setSuccessMessage("Decommissioned schema: " + schemaId));

      }, (response: RestClientError) => {

        const { analytic, status, error = "Failed to decommission schema: " + schemaId } = response;

        dispatch(decommissionSchemaRequestFailed(`${accountId}:${analytic}`));
        dispatch(hideProgressIndicator());

        if (status === 403) {
          dispatch(showAccessDenied());
        }

        return dispatch(setErrorMessage(error));
      });
  };

export const deleteSchema = (schema: JsonSchemaMetadata = JsonSchemaMetadata.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();
    const authToken = getAuthToken(state);
    const accountId = getCurrentAccountId(state);
    const namespace = schema.namespace;
    const name = schema.name;
    const version = schema.version + "";
    const etag = schema.etag;
    const schemaId = schema.getId();

    dispatch(showProgressIndicator());
    dispatch(hideAccessDenied());
    dispatch(clearErrorMessage());
    dispatch(clearSuccessMessage());
    dispatch(deleteSchemaRequest());

    return SchemaRegistryClient.deleteSchema(authToken, namespace, name, version, etag)
      .then(() => {

        dispatch(deleteSchemaRequestSuccess(accountId));
        dispatch(SchemasActions.removeSchema(schemaId));
        dispatch(setSuccessMessage("Deleted schema: " + schemaId));
        return dispatch(hideProgressIndicator());

      }, (response: RestClientError) => {

        const { analytic, status, error = "Failed to delete schema: " + schemaId } = response;

        dispatch(deleteSchemaRequestFailed(analytic));
        dispatch(hideProgressIndicator());

        if (status === 403) {
          dispatch(showAccessDenied());
        }

        return dispatch(setErrorMessage(error));
      });
  };

export const executeSchemaAction = (schema: JsonSchemaMetadata = JsonSchemaMetadata.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    if (JsonSchemaMetadata.EMPTY.equals(schema)) {
      return dispatch(setErrorMessage("Invalid Schema"));
    }

    const state = getState();

    const action = getSchemaAction(state);

    switch (action) {
      case SchemaAction.DRAFT:
        return dispatch(draftNewSchemaVersion(schema));
      case SchemaAction.PROMOTE:
        return dispatch(promoteSchema(schema));
      case SchemaAction.DEPRECATE:
        return dispatch(deprecateSchema(schema));
      case SchemaAction.DECOMMISSION:
        return dispatch(decommissionSchema(schema));
      case SchemaAction.DELETE:
        return dispatch(deleteSchema(schema));
      default:
        return dispatch(setErrorMessage("Invalid Schema State Transition"));
    }
  };

export const reset = () => (dispatch: any) => {
  dispatch(setShowDialog());
  dispatch(setSchema());
  dispatch(setSchemaAction());
  dispatch(setErrorMessage());
  dispatch(setSuccessMessage());
  dispatch(setShowAccessDenied());
  return dispatch(setShowProgressIndicator());
};

export const open = (schema: JsonSchemaMetadata, schemaAction: SchemaAction) => (dispatch: any) => {
  dispatch(reset());
  dispatch(setSchema(schema));
  dispatch(setSchemaAction(schemaAction));
  return dispatch(showDialog());
};

export const openDraftNewSchemaVersionDialog = (schema: JsonSchemaMetadata) => (dispatch: any) =>
  dispatch(open(schema, SchemaAction.DRAFT));

export const openPromoteSchemaDialog = (schema: JsonSchemaMetadata) => (dispatch: any) =>
  dispatch(open(schema, SchemaAction.PROMOTE));

export const openDeprecateSchemaDialog = (schema: JsonSchemaMetadata) => (dispatch: any) =>
  dispatch(open(schema, SchemaAction.DEPRECATE));

export const openDecommissionSchemaDialog = (schema: JsonSchemaMetadata) => (dispatch: any) =>
  dispatch(open(schema, SchemaAction.DECOMMISSION));

export const openDeleteSchemaDialog = (schema: JsonSchemaMetadata) => (dispatch: any) =>
  dispatch(open(schema, SchemaAction.DELETE));

export const close = () => (dispatch: any) => dispatch(reset());

export const initialize = () => (dispatch: any) => dispatch(close());
