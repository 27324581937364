import React from "react";
import ErrorCodeView, { ErrorCodeViewProps } from "@components/error-view/ErrorCodeView";

export const AccessDeniedView = (props: ErrorCodeViewProps) => {

  const {
    errorMessage = "",
    label = "403 Error",
    className = "accessDenied",
    subLabel = "Access Denied",
    description = "You do not have permission to access this resource",
  } = props;

  return (
    <ErrorCodeView
      label={label}
      subLabel={subLabel}
      className={className}
      description={description}
      errorMessage={errorMessage}
    />
  );
};

export default AccessDeniedView;
