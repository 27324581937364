import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    errorView: {
      paddingBottom: 10,
      marginBottom: 35,
      "& label.title, & label.message": {
        marginTop: 10,
      },
      "& button.tryAgainButton": {
        color: Color.DARK_RED,
      },
    },
    header: {
      minHeight: 50,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      position: "relative",
    },
    headerButtons: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      marginLeft: "auto",
      "& $actionsMenu": {
        marginLeft: 15,
      },
    },
    icon: {
      color: Color.SIGNIFY_GREEN,
      width: 40,
      height: 40,
    },
    title: {
      color: Color.BLACK,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: "0 0 0 8px",
    },
    refreshButton: {
      color: Color.SIGNIFY_GREEN,
    },
    actionsMenuIcon: {
      marginLeft: 8,
    },
    actionsMenu: {
      justifySelf: "flex-end",
      marginLeft: "auto",
    },
    controls: {
      marginTop: 10,
    },
    refreshMessage: {
      fontSize: 14,
      lineHeight: "16px",
      margin: "0 0 0 15px",
      fontWeight: 300,
    }
  });

export default styles;
