import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    content: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "30px",
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "28px",
      marginTop: 16,
      "&:first-child": {
        marginTop: 25,
      },
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
    mfaCode: {
      marginTop: 25,
    },
    textField: {
    },
  });

export default styles;
