import React from "react";
import styles from "./styles";
import classnames from "classnames";
import { Box } from "@material-ui/core";
import { Chip } from "@components/chip";
import Select from "@material-ui/core/Select";
import TooltipIcon from "../tooltip/TooltipIcon";
import MenuItem from "@material-ui/core/MenuItem";
import RemoveIcon from "@material-ui/icons/Cancel";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DropdownIcon from "@material-ui/icons/ArrowDropDown";
import FormHelperText from "@material-ui/core/FormHelperText";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { FormChangeEvent, Identity, isEmptyString, noop } from "@util";

export interface DropdownCronMenuModel {
  className?: string;
  emptyValueLabelClassName?: string;
  selectedValues?: string[];
  values?: string[];
  disabledValues?: string[];
  errorMessage?: string;
  dropdownMenuLabel?: string;
  dropdownMenuHint?: string;
  showDropdownMenuHintLabel?: boolean;
  dropdownMenuLabelClassName?: string;
  hideEmptyValue?: boolean;
  emptyValueLabel?: string;
  fullWidth?: boolean;
  autoWidth?: boolean;
  columns?: number;
  disabled?: boolean;
  variant?: "standard" | "outlined" | "filled";
  loading?: boolean;
  loadingMessage?: string;
  loadingDropdownMenuHint?: string;
}

export interface DropdownCronMenuActions {
  onClear?: () => void;
  setSelectedValues?: (selectedValues: string[]) => void;
  mapValueToLabel?: (value: string) => React.ReactNode | string | null;
}

type Props = WithStyles<typeof styles> & DropdownCronMenuModel & DropdownCronMenuActions;

export const DropdownCronMenu = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    emptyValueLabelClassName,
    selectedValues = [],
    values = [],
    disabledValues = [],
    errorMessage = "",
    dropdownMenuLabel = "",
    dropdownMenuHint = "",
    showDropdownMenuHintLabel = true,
    dropdownMenuLabelClassName,
    hideEmptyValue,
    emptyValueLabel = "",
    fullWidth = false,
    autoWidth = true,
    columns = 0,
    disabled,
    loading,
    loadingMessage = "Loading...",
    loadingDropdownMenuHint = "",
    variant = "outlined",
    setSelectedValues = noop,
    mapValueToLabel = Identity,
    onClear,
  } = props;

  const [showClear, setShowClear] = React.useState<boolean>(false);

  // Do not render dropdown menu if there are no options to select; unless it is loading
  if (!Array.isArray(values) || (values.length === 0 && !loading && hideEmptyValue)) {
    return null;
  }

  const showErrorMessage = React.useMemo(() =>
    !loading && !isEmptyString(errorMessage), [loading, errorMessage]);

  const showDropdownMenuLabel = React.useMemo(() =>
    !isEmptyString(dropdownMenuLabel), [dropdownMenuLabel]);

  const showLoadingDropdownMenuHint = React.useMemo(() =>
    loading && !isEmptyString(loadingDropdownMenuHint), [loading, loadingDropdownMenuHint]);

  const showDropdownMenuHint = React.useMemo(() =>
    showDropdownMenuHintLabel && !showErrorMessage && !showLoadingDropdownMenuHint && !isEmptyString(dropdownMenuHint),
    [showDropdownMenuHintLabel, showErrorMessage, showLoadingDropdownMenuHint, dropdownMenuHint]);

  const MenuProps = {
    MenuListProps: {
      style: {
        gap: "10px",
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
      },
    },
  };

  const eventHandler = React.useCallback((callback?: (newValue: string[]) => void) => {
    return (event: FormChangeEvent) => {
      if (callback) {
        const newValue: string | string[] = event.target.value;
        callback(Array.isArray(newValue) ? newValue : newValue.split(","));
      }
    };
  }, []);

  const nonEmptyValueItems = React.useMemo(() => {
    return selectedValues.filter((s) => s !== emptyValueLabel);
  }, [selectedValues]);

  return (
    <FormControl
      className={classnames("dropdownMenuContainer", className, classes.container)}
      color="secondary"
      error={showErrorMessage}
      fullWidth={fullWidth}
      onPointerEnter={() => setShowClear(true)}
      onPointerLeave={() => setShowClear(false)}
    >
      {showDropdownMenuLabel && (
        <InputLabel
          className={classnames("dropdownMenuLabel", dropdownMenuLabelClassName, classes.dropdownMenuLabel)}
          error={showErrorMessage}
          shrink={true}
        >
          {dropdownMenuLabel}
        </InputLabel>
      )}
      <Select
        className={classnames("dropdownMenu", classes.dropdownMenu, classes.root)}
        multiple={true}
        disabled={disabled || loading}
        variant={variant}
        value={selectedValues.length ? nonEmptyValueItems : [emptyValueLabel]}
        error={showErrorMessage}
        autoWidth={autoWidth}
        displayEmpty={!hideEmptyValue}
        onChange={eventHandler(setSelectedValues)}
        MenuProps={columns > 0 ? MenuProps : {}}
        renderValue={(selected: any) => (
          <Box className={classnames("box", classes.box)}>
            {selected.map((value: string) => (
              <Chip key={value} label={value} className={classnames("chip", classes.chip)} />
            ))}
          </Box>
        )}
        onClose={() => setShowClear(false)}
        IconComponent={() =>  {
          return onClear && showClear && nonEmptyValueItems.length ? (
            <TooltipIcon
              onClick={onClear}
              icon={RemoveIcon}
              tooltipText={"Reset"}
              disableTouchRipple={false}
              className={classnames("clearDropdown", classes.clearDropdown)}
            />
          ) : (
            <TooltipIcon
              icon={DropdownIcon}
              disableTouchRipple={false}
              className={classnames("defaultDropdown", classes.clearDropdown)}
              tooltipText={!isEmptyString(dropdownMenuHint) ? dropdownMenuHint : "period"}
            />
          );
        }}
      >
        {!hideEmptyValue && (
          <MenuItem
            value=""
            selected={selectedValues.indexOf("") >= 0}
          >
            <em className={classnames("emptyValueLabel", emptyValueLabelClassName, classes.emptyValueLabel)}>
              {loading ? loadingMessage : emptyValueLabel}
            </em>
          </MenuItem>
        )}
        {values.map((value: string, index: number) => isEmptyString(value) ? null : (
          <MenuItem
            key={`dropdown-menu-item-${index}`}
            selected={selectedValues.indexOf(value) >= 0}
            disabled={disabledValues.indexOf(value) >= 0}
            value={value}
          >
            {mapValueToLabel(value)}
          </MenuItem>
        ))}
      </Select>
      {showLoadingDropdownMenuHint && (
        <FormHelperText
          className={classnames("dropdownMenuLoadingMessage", classes.dropdownMenuLoadingMessage)}
        >
          {loadingDropdownMenuHint}
        </FormHelperText>
      )}
      {showErrorMessage && (
        <FormHelperText
          className={classnames("dropdownMenuErrorMessage", classes.dropdownMenuErrorMessage)}
        >
          {errorMessage}
        </FormHelperText>
      )}
      {showDropdownMenuHint && (
        <FormHelperText
          className={classnames("dropdownMenuHelperText", classes.dropdownMenuHelperText)}
        >
          {dropdownMenuHint}
        </FormHelperText>
      )}
    </FormControl>
  );
});

export default DropdownCronMenu;
