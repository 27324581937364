import { createStyles, StyleRules } from "@material-ui/core/styles";
import Color from "@Color";

export const dialog: StyleRules<string> = {
  paper: {
    padding: 0,
    overflow: "hidden",
  },
};

export const dialogTitle: StyleRules<string> = {
  root: {
    background: Color.SIGNIFY_GREEN,
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    color: Color.WHITE,
    fontFamily: "Roboto, sans-serif",
    fontSize: 24,
    fontWeight: 400,
    lineHeight: "28px",
    margin: 0,
    padding: 16,
  },
};

export const dialogContent: StyleRules<string> = {
  root: {
    margin: "32px 16px 16px",
    padding: 0,
  },
};

export const dialogActions: StyleRules<string> = {
  root: {
    minHeight: 72,
    padding: "0 16px",
    margin: "16px 0 0",
    backgroundColor: "#eee",
  },
};

export const styles = () =>
  createStyles({
    cancelButton: {
    },
    cancelButtonLabel: {
      color: Color.BLACK,
    },
  });

export default styles;
