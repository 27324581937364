import React from "react";
import classnames from "classnames";
import MuiCircularProgress, { CircularProgressProps as MuiCircularProgressProps } from "@material-ui/core/CircularProgress";

export interface CustomMuiCircularProgressProps extends MuiCircularProgressProps {}

export const CircularProgress = ((props: CustomMuiCircularProgressProps) => {

    const {
      className,
      ...otherProps
    } = props;

    return (
      <MuiCircularProgress
        {...otherProps}
        className={classnames("circularProgress", className)}
      />
    );
});

export default CircularProgress;
