import React from "react";
import classnames from "classnames";
import { DataSetRequest } from "@data";
import { clickHandler, isEmptyString, noop } from "@util";
import {
  Alert,
  AlertTitle,
  ActionMenuItem,
  Button,
  DataSetRequestAction,
  DEFAULT_DATA_SET_REQUEST_ACTION_MENU_ITEMS,
} from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DetailsView, { DetailsViewActions, DetailsViewModel } from "@components/details-view";
import DownloadTermsAndConditionsButton from "@components/download-terms-and-conditions-button";
import styles, { PrimaryIcon } from "./styles";

export interface Model extends DetailsViewModel {
  dataSetRequest?: DataSetRequest;
  showTermsAndConditionsBanner?: boolean;
  dataGovernanceLink?: string;
  termsConditionsId?: string;
  showDownloadTermsAndConditionsButton?: boolean;
}

export interface Actions extends DetailsViewActions {
  cloneDataSetRequest?: () => void;
  editDataSetRequest?: () => void;
  deleteDataSetRequest?: (data: DataSetRequest) => void;
  uploadTermsAndConditions?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataSetRequestDetails = withStyles(styles)((props: Props) => {

  const {
    classes,
    dataSetRequest,
    icon = PrimaryIcon,
    title = "Data Set Request Details",
    actions = DEFAULT_DATA_SET_REQUEST_ACTION_MENU_ITEMS,
    actionsLabel = "Actions",
    showDownloadTermsAndConditionsButton = false,
    showTermsAndConditionsBanner,
    // TODO: Extract this into a common component constant
    dataGovernanceLink = "https://www.collaboration.dtf.signify.com/display/IOTPLAT/IoT+Data+Governance+Process",
    termsConditionsId = "",
    showLoadingIndicator,
    onClickAction = noop,
    cloneDataSetRequest = noop,
    editDataSetRequest = noop,
    deleteDataSetRequest = noop,
    uploadTermsAndConditions = noop,
    children,
    ...otherProps
  } = props;

  const header = React.useMemo(() => !showTermsAndConditionsBanner ? null : (
    <Alert
      className={classnames("termsAndConditionsRequiredBanner", classes.alert)}
      severity="info"
      action={(
        <Button
          className={classnames("uploadButton", classes.uploadButton)}
          classes={{
            label: classnames("label", classes.uploadButtonLabel),
          }}
          color="primary"
          size="small"
          variant="contained"
          onClick={clickHandler(uploadTermsAndConditions)}
        >
          Upload Privacy Terms & Conditions
        </Button>
      )}
    >
      <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
        <strong>
          Privacy Terms & Conditions Required
        </strong>
      </AlertTitle>
      <p className={classnames("alertDescription", classes.alertDescription)}>
        Before this Data Set can be created, you must upload a <strong>PDF</strong> containing the <strong>
        Privacy Terms and Conditions</strong> associated with this data set.
      </p>
      <p className={classnames("alertDescription", classes.alertDescription)}>
        As part of the
        <a
          className={classes.underlined}
          href={dataGovernanceLink}
          target="_blank"
        >
          IoT Data Governance process
        </a>
        , evidence must be provided:
      </p>
        <ul className={classnames("alertDescription", classes.alertDescription)}>
          <li>
            that describes the permission for Signify to collect & store this data
          </li>
          <li>
            that describes how Signify is permitted to use this data
          </li>
        </ul>
      <p className={classnames("alertDescription", classes.alertDescription)}>
        Typically, the source of such evidence is a legal document
        (for example, product Terms & Conditions) that is approved and accepted by a customer.
        A copy of the document/contract/terms & conditions that a customer accepts must be attached to the
        data set creation request.<br/>
        Note that IoT Platform is the Data Processor.
        The Data Controller responsibilities reside with the originator of this data set
      </p>
    </Alert>
  ), [showTermsAndConditionsBanner, classes, uploadTermsAndConditions]);

  const downloadTermsAndConditionsTab = React.useMemo(() =>
    !showDownloadTermsAndConditionsButton || isEmptyString(termsConditionsId) ? null : (
      <DownloadTermsAndConditionsButton
        className={classnames("customTab", classes.customTab)}
        buttonClassName={classnames("downloadButton", classes.downloadButton)}
        termsConditionsId={termsConditionsId}
        disabled={showLoadingIndicator}
      />
    ), [classes, showDownloadTermsAndConditionsButton, termsConditionsId, showLoadingIndicator]);

  const customTabs = React.useMemo(() => (
    <React.Fragment>
      {downloadTermsAndConditionsTab}
    </React.Fragment>
  ), [downloadTermsAndConditionsTab]);

  const actionClicked = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case DataSetRequestAction.CLONE:
        return cloneDataSetRequest();
      case DataSetRequestAction.EDIT:
        return editDataSetRequest();
      case DataSetRequestAction.UPLOAD_TERMS_AND_CONDITIONS:
        return uploadTermsAndConditions();
      case DataSetRequestAction.DELETE:
        return deleteDataSetRequest(dataSetRequest);
      default:
        return onClickAction(action);
    }
  }, [
    dataSetRequest,
    cloneDataSetRequest,
    editDataSetRequest,
    uploadTermsAndConditions,
    deleteDataSetRequest,
    onClickAction,
  ]);

  return (
    <DetailsView
      {...otherProps}
      className={classnames("dataSetRequestDetails", classes.container)}
      header={header}
      icon={icon}
      title={title}
      actions={actions}
      actionsLabel={actionsLabel}
      customTabs={customTabs}
      showLoadingIndicator={showLoadingIndicator}
      onClickAction={actionClicked}
      tabsMarginTop={false}
    >
      {children}
    </DetailsView>
  );
});

export default DataSetRequestDetails;
