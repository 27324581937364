import React from "react";
import classnames from "classnames";
import { ActionMenuItem, ActionsMenu, ActionsMenuProps } from "../actions-menu";
import { noop } from "@util";

export enum AuthenticatorActions {
  DELETE = "delete_authenticator",
}

export const AUTHENTICATOR_ACTION_MENU_ITEMS: ActionMenuItem[] = [
  {
    id: AuthenticatorActions.DELETE,
    name: "Delete Authenticator",
  },
];

export interface AuthenticatorActionsMenuProps extends ActionsMenuProps {
  deleteAuthenticator: () => void;
}

export const withAuthenticatorActions = (Component: React.ComponentType<AuthenticatorActionsMenuProps>) =>
  (props: AuthenticatorActionsMenuProps) => {

    const {
      actions = AUTHENTICATOR_ACTION_MENU_ITEMS,
      onClickAction = noop,
      deleteAuthenticator = noop,
    } = props;

    return (
      <Component
        {...props}
        actions={actions}
        onClickAction={(action: ActionMenuItem) => {
          if (action.id === AuthenticatorActions.DELETE) {
            deleteAuthenticator();
          } else {
            onClickAction(action);
          }
        }}
      />
    );
  };

export const AuthenticatorActionsMenu = withAuthenticatorActions((props: AuthenticatorActionsMenuProps) => {

  const { className, buttonLabel = "Authenticator Actions" } = props;

  return (
    <ActionsMenu
      {...props}
      className={classnames("authenticatorActionsMenu", className)}
      buttonLabel={buttonLabel}
    />
  );
});

export default AuthenticatorActionsMenu;
