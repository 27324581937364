import { Module } from "@data";
import { equalsIgnoreCase } from "@util";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./containers/TrainingCertificate";

export const trainingCertificateModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Training Certificate",
  title: "Training Certificate Management",
  className: "trainingCertificateModule",
  primaryColor,
  icon,
  view,
  backButton: null,
  // Module can be disabled on its own but also falls under the umbrella of D&A
  disabled: equalsIgnoreCase("false", process.env.REACT_APP_TRAINING_CERTIFICATE_MODULE_ENABLED) ||
    equalsIgnoreCase("false", process.env.REACT_APP_DATA_ANALYTICS_MODULES_ENABLED),
});

export default trainingCertificateModule;
