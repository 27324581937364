import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  PortalModuleActionTypes,
  createPortalModuleDefaultState,
} from "@base/createReducers";

export interface DashboardDetailsSchema extends BasePortalModuleSchema {
  dashboardId: number;
  initialized: boolean;
}

export const DEFAULT_STATE: DashboardDetailsSchema = createPortalModuleDefaultState({
  dashboardId: -1,
  initialized: false,
});

type DataSetDetailsActionTypes = PortalModuleActionTypes<DashboardDetailsSchema>;

export const ACTION_TYPES: DataSetDetailsActionTypes = Object.freeze({
  ...createPortalModuleActionTypes<DashboardDetailsSchema>(MODULE_ID, DEFAULT_STATE),
});

export const DashboardDetailsReducer = combineReducers<DashboardDetailsSchema>(
  createPortalModuleReducers<DashboardDetailsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DashboardDetailsReducer;
