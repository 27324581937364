import ModuleIcon from "@material-ui/icons/Security";
import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const selectView = () =>
  createStyles({
    container: {
      color: primaryColor,
      "& table": {
        marginTop: 15,
      },
      "& td label": {
        cursor: "pointer",
      },
    },
  });

export const servicesView = () =>
  createStyles({
    container: {
      "& .summaryViewContainer .summary": {
        minHeight: "auto",
        "& > label": {
          margin: 0,
        },
      },
    },
  });

export const reviewView = () =>
  createStyles({
    container: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: 25,
      "& label.name": {
        minWidth: 120,
      },
    },
    content: {
      marginTop: 32,
      color: primaryColor,
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
  });

export const reviewListItems = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "center",
    },
    label: {
      color: Color.TEXT,
      cursor: "default",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
      textTransform: "none",
      wordBreak: "break-word",
      marginRight: 8,
    },
    fab: {
      width: 28,
      height: 28,
    },
    progressIndicator: {
      color: Color.SIGNIFY_GREEN,
      position: "absolute",
    },
    icons: {
      fontSize: 13,
    },
    failedLabel: {
      color: Color.RED,
    },
    success: {
      backgroundColor: Color.SIGNIFY_GREEN,
      color: Color.BLACK,
    },
    failed: {
      backgroundColor: Color.RED,
      color: Color.BLACK,
    },
    errorIcon: {
      color: Color.BLACK,
    },
    successIcon: {
      color: Color.BLACK,
    },
  });

export const styles = () =>
  createStyles({
    container: {
      color: primaryColor,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      "& .loadServicesToAddToGroup .loadingView": {
        marginTop: 25,
      },
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: 0,
      padding: 0,
    },
    error: {
      flex: "0 0 auto",
      marginTop: 25,
    },
    controls: {
      flex: "0 0 auto",
      marginTop: 25,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      height: 36,
      minWidth: 100,
      padding: "6px 16px",
      marginLeft: 16,
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "16px",
      textTransform: "none",
    },
    cancelButton: {
      fontWeight: 500,
      marginLeft: 0,
    },
    saveButton: {
    },
    nextStepButton: {
      color: Color.BLACK,
      marginLeft: 16,
    },
    previousStepButton: {
      color: Color.BLACK,
      marginLeft: 8,
    },
  });

export default styles;
