import React from "react";
import { isDataWorkloadsOneDotThreeFeaturesEnabled } from "@util";
import classnames from "classnames";
import DnsNameIcon from "@material-ui/icons/CheckCircle";
import { configurationView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ChipView, WorkloadDnsNamesDialog, WorkloadInternetAccessDialog } from "@components";
import WorkloadKeyValueView, { WorkloadKeyValueViewModel, WorkloadKeyValueViewActions } from "@components/workload-key-value-view";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { WorkloadInternetAccessConfig } from "@data";

export interface Model extends WorkloadKeyValueViewModel {
  workloadName: string;
  version: number;
  etag: string;
  internetAccessConfiguration: WorkloadInternetAccessConfig;
  showEditInternetAccess?: boolean;
}

export interface Actions extends WorkloadKeyValueViewActions {
  refreshWorkload?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ConfigurationView = withStyles(styles)((props: Props) => {

  const {
    classes,
    workloadName,
    version,
    etag,
    internetAccessConfiguration,
    showEditInternetAccess = isDataWorkloadsOneDotThreeFeaturesEnabled(),
    refreshWorkload,
    statusCode,
    ...otherProps
  } = props;

  const [openAccessDialog, setOpenAccessDialog] = React.useState(false);
  const [openDnsNamesDialog, setOpenDnsNamesDialog] = React.useState(false);

  return (
    <div className={classnames("configurationView", classes.container)}>
      <WorkloadKeyValueView
        {...otherProps}
        statusCode={statusCode}
      />
      {showEditInternetAccess && (
        <React.Fragment>
          <label className={classnames(classes.title)}>Internet Access</label>
          <div className={classnames(classes.row)}>
            <label className={classnames(classes.label)}>Internet Access:</label>
            <div className={classnames(classes.switchContainer)}>
              <label className={classnames("leftLabel", classes.leftLabel)}>
                No
              </label>
              <FormControlLabel
                control={(
                  <Switch
                    checked={internetAccessConfiguration.internetAccess}
                    onChange={() => setOpenAccessDialog(true)}
                  />
                  )}
                label="Yes"
              />
            </div>
          </div>
          {internetAccessConfiguration.internetAccess && (
            <ChipView
              title="Whitelisted DNS Names:"
              items={internetAccessConfiguration.whitelistedDnsNames}
              className={classnames("dnsNames", classes.dnsNames)}
              icon={DnsNameIcon}
              showEmptyView={true}
              emptyViewLabel="No DNS names provided"
              onClickEditIcon={() => setOpenDnsNamesDialog(true)}
              showEditIcon={showEditInternetAccess}
              editTooltipText="Update DNS Name"
              editIconClassName="editDnsNames"
              marginTop={true}
            />
          )}
          <WorkloadInternetAccessDialog
            configuration={internetAccessConfiguration}
            open={openAccessDialog}
            name={workloadName}
            version={version}
            etag={etag}
            cancel={() => setOpenAccessDialog(false)}
            onSuccessMessageShown={refreshWorkload}
          />
          <WorkloadDnsNamesDialog
            open={openDnsNamesDialog}
            name={workloadName}
            version={version}
            etag={etag}
            configuration={internetAccessConfiguration}
            cancel={() => setOpenDnsNamesDialog(false)}
            onSuccessMessageShown={refreshWorkload}
          />
        </React.Fragment>
      )}
    </div>
  );
});

export default ConfigurationView;
