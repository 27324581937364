import React from "react";
import { Policy } from "@data";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  useWorkloadManagerClient,
} from "@hooks";

type SuccessResponse = void;

export interface UseEditWorkloadPolicyProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  etag: string;
  policy?: Policy;
}

export interface UseEditWorkloadPolicyModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseEditWorkloadPolicyActions {
  editWorkloadPolicy: () => void;
  reset: () => void;
}

type Props = UseEditWorkloadPolicyProps;
type Model = UseEditWorkloadPolicyModel;
type Actions = UseEditWorkloadPolicyActions;
type Result = [Model, Actions];

export const useEditWorkloadPolicy = (props: Props): Result => {

  const {
    name,
    version,
    etag,
    policy = Policy.EMPTY,
    defaultErrorMessage = "Failed to edit workload policy",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
      WorkloadManagerClient.editWorkloadPolicy(name, version, etag, JSON.stringify(policy.toJS())),
    [WorkloadManagerClient, name, version, etag, policy]);

  const [{ showSuccessView, successResponse, ...baseModel }, {
    refresh: editWorkloadPolicy,
    ...baseActions
  }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? "Workload Policy Updated" : "",
    [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    editWorkloadPolicy,
  }), [
    baseActions,
    editWorkloadPolicy,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEditWorkloadPolicy;
