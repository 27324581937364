import ModuleIcon from "@material-ui/icons/Create";
import { Theme } from "@material-ui/core/styles/createTheme";
import { createStyles, StyleRules } from "@material-ui/core/styles";
import { primaryColor as deviceEnrollmentModulePrimaryColor } from "@modules/deviceEnrollment/components/styles";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = deviceEnrollmentModulePrimaryColor;

export const idTextField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontSize: 14,
      lineHeight: "14px",
      fontWeight: 300,
      padding: "2px 0",
      margin: 0,
    },
    "& input": {
      fontSize: 14,
      fontWeight: 400,
      padding: "18.5px 14px",
      margin: 0,
    },
  },
};

export const enrollmentTypeView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    switchContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      marginTop: 15,
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    subtitle: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 16,
      lineHeight: "28px",
      marginTop: 16,
    },
    label: {
      fontWeight: 300,
      fontSize: 14,
    },
    switch: {
      marginLeft: 8,
      marginRight: 8,
    },
    alert: {
      marginTop: 16,
    },
    alertTitle: {
      fontSize: 14,
      marginTop: 2,
    },
    alertDescription: {
      fontSize: 14,
      lineHeight: "21px",
      marginTop: 10,
      marginBottom: 0,
    },
    errorViewContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
  });

export const fileUploadView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    content: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      padding: 16,
      borderRadius: 6,
      textAlign: "center",
      marginTop: 16,
      border: `1px dashed ${Color.TEXT}`,
      width: "100%",
    },
    contentDragging: {
      backgroundColor: Color.LIGHT_GREY2,
    },
    contentSelected: {
      backgroundColor: Color.LIGHT_GREY,
    },
    label: {
      flex: "0 0 auto",
      margin: 8,
      fontSize: 18,
      lineHeight: "21px",
      fontWeight: 400,
    },
    propertyName: {
      flex: "0 0 100px",
      alignSelf: "flex-start",
      justifySelf: "flex-start",
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      fontSize: 16,
      margin: "auto",
    },
  });

export const batchIdView = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    errorViewContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 18,
      lineHeight: "21px",
      marginTop: 8,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    uuidControls: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    uuidButton: {
      width: "auto !important",
      height: "auto !important",
      padding: 12,
    },
    idField: {
      marginTop: 25,
      fontFamily: "Roboto, sans-serif",
    },
    textField: {
      color: Color.TEXT,
      fontSize: 16,
      height: "auto",
      minWidth: "300px",
      maxWidth: "700px",
    },
    formHelperText: {
      color: Color.TEXT,
      fontSize: 13,
    },
    formHelperTextError: {
      marginBottom: 15,
    },
    tooltipIconPopper: {
      marginTop: 12,
      justifyContent: "flex-end",
    },
  });

export const deviceDataView = (theme: Theme) =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 18,
      lineHeight: "21px",
      marginTop: 8,
    },
    information: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 250,
      fontSize: 16,
      lineHeight: "21px",
      marginTop: 25,
    },
    infoIcon: {
      width: "auto !important",
      height: "auto !important",
      padding: 12,
    },
    tooltipIconPopper: {
      marginTop: 12,
      justifyContent: "flex-end",
    },
    contentWrapper: {
      flex: "1 0 auto",
      "& .addFilterButton": {
        color: Color.TEXT,
      },
    },
    controls: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      marginTop: 16,
    },
    actualButton: {
      marginLeft: 0,
    },
    desiredButton: {
      marginLeft: 15,
    },
    switchContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      alignContent: "center",
      marginTop: 15,
    },
    label: {
      fontWeight: 300,
      fontSize: 14,
    },
    switch: {
      marginLeft: 8,
      marginRight: 8,
    },
    backdrop: {
      background: "rgba(105, 105, 105, 0.25)",
      zIndex: theme.zIndex.modal,
    },
    progressIndicator: {
      color: Color.SIGNIFY_GREEN2,
      position: "absolute",
      top: "50%",
      left: "50%",
      zIndex: 1,
    },
  });

export const editor = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    errorViewContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
  });

export const editorDialog = () =>
  createStyles({
    container: {
      "& .content": {
        paddingTop: 16,

        "& .errorView": {
          padding: "4px 16px",
          flexFlow: "row wrap",
          marginBottom: 0,

          "& .icon": {
            width: 24,
            height: 24,
            fontSize: 12,
            marginRight: 8,
          },

          "& .title": {
            display: "none",
          },

          "& .message": {
            fontSize: 12,
            lineHeight: 0,
            margin: 0,
          },
        },
      },
    },
    paper: {
      padding: 0,
      overflow: "hidden",
      height: 1000
    },
  });

export const removeDialog = () =>
  createStyles({
    content: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
    },
    subtitle: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 18,
    },
    label: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "18px",
      textAlign: "center",
      marginTop: 16,
      marginBottom: 16,
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
  });

export const deviceEnrollmentEditorView = (theme: Theme) =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    headerContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
      marginBottom: 15,
    },
    validateButton: {
      justifySelf: "flex-end",
      padding: "6px 16px",
      marginLeft: "auto",
    },
    backdrop: {
      background: "rgba(105, 105, 105, 0.25)",
      zIndex: theme.zIndex.modal,
    },
    downloadEncodedFile: {
      justifySelf: "flex-end",
      padding: "6px 16px",
      marginLeft: "auto",
    },
    controls: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      marginTop: 16,
    },
    enrollmentButton: {
      marginLeft: 0,
    },
    typeButton: {
      marginLeft: 15,
    },
  });

export const deviceTypeInfoView = (theme: Theme) =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 20,
      lineHeight: "32px",
      marginTop: 15,
    },
    backdrop: {
      background: "rgba(105, 105, 105, 0.25)",
      zIndex: theme.zIndex.modal,
    },
  });

export const reviewView = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    fileContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    propertyContainer: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      justifyContent: "stretch",
      marginTop: 25,
      textAlign: "left",
    },
    propertyName: {
      flex: "0 0 100px",
      alignSelf: "flex-start",
      justifySelf: "flex-start",
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      fontSize: 16,
    },
    propertyValue: {
      flex: "1 1 auto",
      color: Color.TEXT,
      display: "inline-block",
      fontFamily: "monospace",
      fontSize: 14,
      marginLeft: 15,
      fontWeight: 500,
    },
    subtitle: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
      marginTop: 25,
    },
    deviceEnrollmentContainer: {
      flex: "1 0 350px",
      display: "flex",
      alignItems: "stretch",
    },
    jsonContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      marginLeft: 15,
    },
    backdrop: {
      background: "rgba(105, 105, 105, 0.25)",
      zIndex: theme.zIndex.modal,
    },
    button: {
      lineHeight: "21px",
      fontSize: 16,
      textTransform: "none",
      alignSelf: "baseline",
      marginTop: 10,
    },
    underlined: {
      marginLeft: 4,
      marginRight: 4,
      textDecoration: "underline",
    },
  });

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
    },
    progressIndicator: {
      color: Color.SIGNIFY_GREEN2,
      position: "absolute",
      top: "50%",
      left: "50%",
      zIndex: 1,
    },
  });

export default styles;
