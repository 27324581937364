import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DetailsView, { DetailsViewActions, DetailsViewModel } from "@components/details-view";
import styles from "./styles";

export interface SecurityServiceDetailsModel extends DetailsViewModel {
  className?: string;
  contentClassName?: string;
  showMfaFilterSwitch?: boolean;
}

export interface SecurityServiceDetailsActions extends DetailsViewActions {
}

type Model = SecurityServiceDetailsModel;
type Actions = SecurityServiceDetailsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const SecurityServiceDetails = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    contentClassName,
    showLoadingIndicator,
    showMfaFilterSwitch,
    children,
    ...otherProps
  } = props;

  return (
    <DetailsView
      {...otherProps}
      className={classnames("securityServiceDetails", className, classes.container, {
        [classnames("loading", classes.loading)]: showLoadingIndicator,
        [classnames("showMfaFilterSwitch", classes.showMfaFilterSwitch)]: showMfaFilterSwitch,
      })}
      contentClassName={classnames("securityServiceDetailsContent", contentClassName, classes.content, {
        [classnames("loading", classes.loading)]: showLoadingIndicator,
        [classnames("showMfaFilterSwitch", classes.showMfaFilterSwitch)]: showMfaFilterSwitch,
      })}
      showLoadingIndicator={showLoadingIndicator}
    >
      {children}
    </DetailsView>
  );
});

export default SecurityServiceDetails;
