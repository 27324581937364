import Ajv from "ajv";
import isEmpty from "lodash/isEmpty";
import { createSelector } from "reselect";
import {
  JsonSchemaIntegerConstraints,
  JsonSchemaNumberConstraints,
  JsonSchemaPropertiesDefinition,
  JsonSchemaProperty,
  JsonSchemaPropertyAttributes,
  JsonSchemaPropertyConstraints,
  JsonSchemaPropertyDefaultValueType,
  JsonSchemaPropertyType,
  JsonSchemaStringConstraints,
} from "../../../../data";
import { AppSchema } from "../../../main/schemas";
import { PropertiesTableSelectors } from "../../selectors/propertiesTable";
import { StringConstraintsSelectors } from "../../selectors/stringConstraints";
import { NumberConstraintsSelectors } from "../../selectors/numberConstraints";
import { IntegerConstraintsSelectors } from "../../selectors/integerConstraints";

export interface ValidationResult {
  isValid: boolean;
  error?: string;
}

const VALID_RESULT: ValidationResult = { isValid: true };

const DEFAULT_PROPERTY_EDITOR_TYPES = [
  JsonSchemaPropertyType.STRING,
  JsonSchemaPropertyType.NUMBER,
  JsonSchemaPropertyType.INTEGER,
  JsonSchemaPropertyType.BOOLEAN,
  JsonSchemaPropertyType.NULL,
  JsonSchemaPropertyType.OBJECT,
  JsonSchemaPropertyType.REFERENCE,
];

export const getErrorMessage = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.errorMessage;
};

export const getOriginal = (state: AppSchema): JsonSchemaPropertyAttributes => {
  return state.schemaWizard.propertyEditor.original;
};

export const getName = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.name;
};

export const getNameError = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.nameError;
};

export const getType = (state: AppSchema): JsonSchemaPropertyType => {
  return state.schemaWizard.propertyEditor.type;
};

export const getDescription = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.description;
};

export const isRequired = (state: AppSchema): boolean => {
  return state.schemaWizard.propertyEditor.required;
};

export const isClonedProperty = (state: AppSchema): boolean => {
  return state.schemaWizard.propertyEditor.clonedProperty;
};

export const getEnumValues = (state: AppSchema): JsonSchemaPropertyDefaultValueType[] => {
  return state.schemaWizard.propertyEditor.enumValues;
};

export const getEnumValueError = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.enumValueError;
};

export const getDefaultValue = (state: AppSchema): JsonSchemaPropertyDefaultValueType => {
  return state.schemaWizard.propertyEditor.defaultValue;
};

export const getDefaultValueError = (state: AppSchema): string => {
  return state.schemaWizard.propertyEditor.defaultValueError;
};

export const isProgressIndicatorVisible = (state: AppSchema): boolean => {
  return state.schemaWizard.propertyEditor.showProgressIndicator;
};

export const getOriginalProperty: (state: AppSchema) => JsonSchemaProperty = createSelector(
  getOriginal, (original: JsonSchemaPropertyAttributes) =>
    new JsonSchemaProperty(original));

export const getOriginalPropertyType: (state: AppSchema) => JsonSchemaPropertyType = createSelector(
  getOriginalProperty, (originalProperty: JsonSchemaProperty) => {
    return originalProperty.type;
  });

export const getConstraints: (state: AppSchema) => JsonSchemaPropertyConstraints = createSelector(
  [
    StringConstraintsSelectors.getConstraints,
    NumberConstraintsSelectors.getConstraints,
    IntegerConstraintsSelectors.getConstraints,
  ],
  (stringConstraints: JsonSchemaStringConstraints,
   numberConstraints: JsonSchemaNumberConstraints,
   integerConstraints: JsonSchemaIntegerConstraints) => {

    const constraints = {
      ...stringConstraints.getValidationData(),
      ...numberConstraints.getValidationData(),
      ...integerConstraints.getValidationData(),
    };

    return isEmpty(constraints) ? null : constraints;
  }
);

export const getProperty: (state: AppSchema) => JsonSchemaProperty = createSelector(
  [
    getName,
    getType,
    getDescription,
    isRequired,
    getEnumValues,
    getDefaultValue,
    getConstraints,
  ],
  (name: string,
   type: JsonSchemaPropertyType,
   description: string,
   required: boolean,
   enumValues: JsonSchemaPropertyDefaultValueType[],
   defaultValue: JsonSchemaPropertyDefaultValueType,
   constraints: JsonSchemaPropertyConstraints) => {

    const attributes = {
      name,
      type,
      description,
      required,
      enumValues,
      ...(constraints === null ? {} : {
        constraints: {
          ...constraints,
        },
      }),
    };

    if (typeof defaultValue === "undefined") {
      return new JsonSchemaProperty(attributes);
    }

    if (defaultValue === "") {
      return new JsonSchemaProperty(attributes);
    }

    if (defaultValue === null && type !== JsonSchemaPropertyType.NULL) {
      return new JsonSchemaProperty(attributes);
    }

    if (type === JsonSchemaPropertyType.NUMBER || type === JsonSchemaPropertyType.INTEGER) {

      if (typeof defaultValue === "string" || typeof defaultValue === "number") {

        const defaultValueAsNumber = Number(defaultValue + "");

        if (!isNaN(defaultValueAsNumber)) {
          return new JsonSchemaProperty({
            ...attributes,
            defaultValue: defaultValueAsNumber,
          });
        }
      }
    }

    if (type === JsonSchemaPropertyType.STRING) {
      return new JsonSchemaProperty({
        ...attributes,
        defaultValue: (defaultValue + "").trim(),
      });
    }

    return new JsonSchemaProperty({ ...attributes, defaultValue });
  });

export const isEnumEditorVisible: (state: AppSchema) => boolean = createSelector(
  getProperty, (property: JsonSchemaProperty) => {

    // Enum values can only be added to basic property types
    return property.isBasicPropertyType();
  }
);

export const isConstraintsEditorVisible: (state: AppSchema) => boolean = createSelector(
  getProperty, (property: JsonSchemaProperty) => {

    // We do not allow constraints to be added to properties w/ enum values because these
    // values are specifically chosen by the user as the only legal values and therefore
    // adding extra constraints would be redundant and pretty useless.
    if (property.hasEnumValues()) {
      return false;
    }

    return property.isString() || property.isNumber() || property.isInteger();
  }
);

export const isStringConstraintsVisible: (state: AppSchema) => boolean = createSelector(
  [isConstraintsEditorVisible, getProperty],
  (showConstraintsEditor: boolean, property: JsonSchemaProperty) => {

    return showConstraintsEditor && property.isString();
  }
);

export const isNumberConstraintsVisible: (state: AppSchema) => boolean = createSelector(
  [isConstraintsEditorVisible, getProperty],
  (showConstraintsEditor: boolean, property: JsonSchemaProperty) => {

    return showConstraintsEditor && property.isNumber();
  }
);

export const isIntegerConstraintsVisible: (state: AppSchema) => boolean = createSelector(
  [isConstraintsEditorVisible, getProperty],
  (showConstraintsEditor: boolean, property: JsonSchemaProperty) => {

    return showConstraintsEditor && property.isInteger();
  }
);

export const isAddingProperty: (state: AppSchema) => boolean = createSelector(
  [getOriginalProperty, isClonedProperty],
  (originalProperty: JsonSchemaProperty, cloned: boolean) => {
    return JsonSchemaProperty.EMPTY.equals(originalProperty) || cloned;
  });

export const getClassNamePrefix: (state: AppSchema) => string = createSelector(
  isAddingProperty, (showAddProperty: boolean) => {
    return showAddProperty ? "add" : "edit";
  });

export const getPropertyEditorClassName: (state: AppSchema) => string = createSelector(
  getClassNamePrefix, (prefix: string) => {
    return `${prefix}Property`;
  });

export const getPropertyEditorTypes: (state: AppSchema) => string[] = createSelector(
  [isAddingProperty, getType, getOriginalPropertyType],
  (isNewProperty: boolean,
   selectedType: JsonSchemaPropertyType,
   originalType: JsonSchemaPropertyType) => {

    const type = isNewProperty ? selectedType : originalType;

    // Make sure the selected type appears in the dropdown menu or it will not render
    // This can occur if the selected type is outside the scope of the basic property types,
    // such as definitions and external references.
    return DEFAULT_PROPERTY_EDITOR_TYPES.indexOf(type) === -1
      ? DEFAULT_PROPERTY_EDITOR_TYPES.concat(type)
      : DEFAULT_PROPERTY_EDITOR_TYPES.slice();
  });

export const getDefaultValueEditorClassName: (state: AppSchema) => string = createSelector(
  getClassNamePrefix, (prefix: string) => {
    return `${prefix}PropertyDefaultValueEditor`;
  });

export const getDialogClassName: (state: AppSchema) => string = createSelector(
  getClassNamePrefix, (prefix: string) => {
    return `${prefix}PropertyDialog`;
  });

export const getDialogMaxWidth: (state: AppSchema) => "xs" | "sm" | "md" | "lg" | "xl" | false =
  createSelector(getType, (selectedType: JsonSchemaPropertyType) => {

      switch (selectedType) {
        case JsonSchemaPropertyType.ARRAY:
          return "md";
        default:
          return "sm";
      }
    });

export const getDialogTitle: (state: AppSchema) => string = createSelector(
  isAddingProperty, (showAddProperty: boolean) => {
    return showAddProperty ? "Add Property" : "Edit Property";
  });

export const getSaveButtonLabel: (state: AppSchema) => string = createSelector(
  isAddingProperty, (showAddProperty: boolean) => {
    return showAddProperty ? "Add Property" : "Update Property";
  });

export const hasName: (state: AppSchema) => boolean = createSelector(
  getName, (name: string) => name.trim().length > 0);

export const hasUniqueName: (state: AppSchema) => boolean = createSelector(
  [PropertiesTableSelectors.getProperties, getName, isAddingProperty, getOriginalProperty],
  (properties: JsonSchemaPropertiesDefinition,
   name: string,
   showAddProperty: boolean,
   originalProperty: JsonSchemaProperty) => {

    const propertyName = name.trim().toLowerCase();

    // If edit mode is enabled, allow the user to change the property name's case (eg: foo => FOO)
    if (!showAddProperty && propertyName === originalProperty.getName().toLowerCase()) {
      return true;
    }

    return propertyName.length > 0 &&
      !Object.keys(properties).some((otherPropertyName: string) =>
        otherPropertyName.toLowerCase().trim() === propertyName);
  });

export const validateDefaultValue: (state: AppSchema) => ValidationResult = createSelector(
  getProperty, (property: JsonSchemaProperty) => {

    if (!property.hasDefaultValue()) {
      return { ...VALID_RESULT };
    }

    const ajv = new Ajv;

    const validate = ajv.compile(property.getValidationData());

    const isValid = validate(property.defaultValue);

    if (isValid) {
      return { ...VALID_RESULT };
    }

    return {
      isValid: false,
      error: ajv.errorsText(validate.errors),
    };
  });

export const isNameErrorVisible: (state: AppSchema) => boolean = createSelector(
  getNameError, (nameError: string) => nameError.trim().length > 0);

export const isEnumValueErrorVisible: (state: AppSchema) => boolean = createSelector(
  getEnumValueError, (enumValueError: string) => enumValueError.trim().length > 0);

export const isDefaultValueErrorVisible: (state: AppSchema) => boolean = createSelector(
  getDefaultValueError, (defaultValueError: string) => defaultValueError.trim().length > 0);

export const hasValidationErrors: (state: AppSchema) => boolean = createSelector(
  [
    isNameErrorVisible,
    isEnumValueErrorVisible,
    isDefaultValueErrorVisible,
    StringConstraintsSelectors.hasValidationErrors,
    NumberConstraintsSelectors.hasValidationErrors,
    IntegerConstraintsSelectors.hasValidationErrors,
  ],
  (showNameError: boolean,
   showEnumValueError: boolean,
   showDefaultValueError: boolean,
   invalidStringConstraints: boolean,
   invalidNumberConstraints: boolean,
   invalidIntegerConstraints: boolean) => {

    return showNameError ||
      showEnumValueError ||
      showDefaultValueError ||
      invalidStringConstraints ||
      invalidNumberConstraints ||
      invalidIntegerConstraints;
  }
);

export const isSaveButtonEnabled: (state: AppSchema) => boolean = createSelector(
  [hasName, hasValidationErrors],
  (isNameValid: boolean, validationFailed: boolean) => {

    return isNameValid && !validationFailed;
  }
);
