import React from "react";
import { Application } from "@data";
import { isEmptyString, noop } from "@util";
import useGetApplicationsRegional from "@hooks/applicationManagerRegional/use-get-applications-regional";
import ApplicationView, { Actions, Model } from "../components/ApplicationView";

export interface ContainerModel extends Model {
  hidden?: boolean;
  foundDuplicates?: boolean;
  applicationId?: string;
  accountId?: string;
}

export interface ContainerActions extends Actions {
  setApplicationId?: (applicationId: string) => void;
  trackApplicationSelected?: () => void;
}

type Props = ContainerModel & ContainerActions;

const ApplicationViewContainer = (props: Props) => {

  const {
    hidden,
    accountId = "",
    applicationId = "",
    setApplicationId = noop,
    trackApplicationSelected = noop,
    ...otherProps
  } = props;

  const [{ applications: items, ...otherModel }, { refresh, ...otherActions }] =
    useGetApplicationsRegional({deferRequest: true});

  const selectedItems = React.useMemo(() => {
    if (isEmptyString(applicationId)) {
      return [];
    }
    const selectedApplication = items.find(item => item.getId() === applicationId);
    return selectedApplication ? [selectedApplication] : [new Application({ id: applicationId })];
  }, [applicationId, items]);

  const setSelectedApplication = React.useCallback(
    (application: Application = Application.EMPTY) => {

      setApplicationId(application.getId());

      // We are interested in knowing when users try to create application email templates
      if (application.hasApplicationId()) {
        trackApplicationSelected();
      }

    }, [setApplicationId, trackApplicationSelected]);

  React.useEffect(() => {
    refresh();
  }, []);

  if (hidden) {
    return null;
  }

  return (
    <ApplicationView
      {...otherModel}
      {...otherActions}
      items={items}
      applicationId={applicationId}
      selectedItems={selectedItems}
      setSelectedApplication={setSelectedApplication}
      {...otherProps}
    />
  );
};

export default ApplicationViewContainer;
