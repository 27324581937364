import React from "react";
import html2canvas from "html2canvas";

export interface UseScreenshotModel {
  image: string;
  isLoading: boolean;
}

export interface UseScreenshotActions {
  takeScreenshot: () => void;
  clear: () => void;
}

type Result = [UseScreenshotModel, UseScreenshotActions];

export const useScreenshot = (): Result => {

  const [image, setImage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const clear = React.useCallback(() => {
    setImage("");
    setIsLoading(false);
  }, [setImage, setIsLoading]);

  // Screenshot dialog if one is open, otherwise screenshot the root
  const element = document.querySelector<HTMLElement>("div.MuiDialog-root:not(.sendFeedbackDialog)") ||
    document.getElementById("root") || document.body;

  const takeScreenshot = React.useCallback(() => {
    setIsLoading(true);
    html2canvas(element, {
      height: window.innerHeight,
      width: window.innerWidth,
      windowHeight: element.scrollHeight,
      windowWidth: element.scrollWidth
    })
      .then((canvas: HTMLCanvasElement) => {
        setImage(canvas.toDataURL("image/jpeg", 0.3));
        setIsLoading(false);
      }, clear);
  }, [element, clear]);

  const model = React.useMemo<UseScreenshotModel>(() => ({
    image,
    isLoading,
  }), [
    image,
    isLoading,
  ]);

  const actions = React.useMemo<UseScreenshotActions>(() => ({
    takeScreenshot,
    clear,
  }), [
    takeScreenshot,
    clear,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useScreenshot;
