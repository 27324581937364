import React from "react";
import { useHistory } from "react-router-dom";
import { getEditProfileAttributeDefinitionsPath } from "@modules/editProfileAttributeDefinition/helpers";
import ProfileAttributeDefinitions from "./containers/ProfileAttributeDefinitions";

export function ProfileAttributeDefinitionsView() {

  const history = useHistory();

  const editProfileAttributeDefinitions = React.useCallback(() =>
    history.push(getEditProfileAttributeDefinitionsPath()), [history]);

  return (
    <ProfileAttributeDefinitions
      editProfileAttributeDefinitions={editProfileAttributeDefinitions}
    />
  );
}

export default ProfileAttributeDefinitionsView;
