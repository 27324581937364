import React from "react";
import AppSchema from "@schemas";
import { connect } from "react-redux";
import { isEmptyString } from "@util";
import PolicyManagement, { Actions, Model } from "../components/PolicyManagement";
import SelectManagedPolicy from "./SelectManagedPolicy";
import SelectPolicy from "./SelectPolicy";
import ReviewView from "./ReviewView";
import { usePortalSnackbar } from "@components";
import { PolicyOperation, PolicyOperationPrincipal } from "@hooks";
import {
  getAccountIdFromPolicyRef,
  getErrorMessage,
  getInformation,
  getModuleTitle,
  getOperation,
  getPolicyName,
  getPrincipalId,
  getPrincipalType,
  getSaveButtonLabel,
  getSuccessMessage,
  isCustomPolicyViewSelected,
  isManagedPolicyViewSelected,
  isNextButtonEnabled,
  isPoliciesView,
  isReviewView,
  isSaveButtonEnabled,
} from "../selectors";
import {
  setErrorMessage,
  setPolicyBulkRequest,
  setPolicyName,
  setPolicyRef,
  setSelectedManagedPolicies,
  setSelectedPolicies,
  showCustomPolicyView,
  showManagedPolicyView,
  showPolicyReviewView,
  showPolicyView,
  updatePolicies
} from "../actions";

interface ContainerModel extends Model {
  snackbarId?: string;
  id?: string;
  policyName?: string;
  accountId?: string;
  successMessage?: string;
  operation?: PolicyOperation;
  principal?: PolicyOperationPrincipal;
  isManagedPolicy?: boolean;
  showReviewView?: boolean;
  showPoliciesView?: boolean;
}

export interface ContainerActions extends Actions {
  onSuccess?: () => void;
  setPolicyName?: (name: string) => void;
  setPolicyRef?: (ref: string) => void;
  nextStep?: () => void;
  previousStep?: (error: boolean) => void;
  updatePolicies?: (operation: PolicyOperation, principal: PolicyOperationPrincipal,
                    isManagedPolicy: boolean, id: string, accountId: string) => void;
}

type Props = ContainerModel & ContainerActions;

const PolicyManagementContainer = (props: Props) => {

  const {
    snackbarId = "policy-management",
    id = "",
    policyName = "",
    accountId = "",
    operation,
    principal,
    isManagedPolicy,
    showReviewView,
    showPoliciesView,
    successMessage,
    onSuccess,
    ...otherProps
  } = props;

  const { errorMessage } = otherProps;

  usePortalSnackbar(snackbarId, {
    errorMessage,
    successMessage,
    onSuccessMessageShown: onSuccess,
    successCallbackDelay: 750,
  });

  const successIndicator = React.useMemo(() =>
  !isEmptyString(successMessage), [successMessage]);

  const viewButtonDisabled = React.useMemo(() =>
  !isEmptyString(policyName), [policyName]);

  return (
    <PolicyManagement
      {...otherProps}
      id={id}
      policyName={policyName}
      accountId={accountId}
      operation={operation}
      principal={principal}
      isManagedPolicy={isManagedPolicy}
      showReviewView={showReviewView}
      showPoliciesView={showPoliciesView}
      showSuccessIndicator={successIndicator}
      errorMessage={errorMessage}
      viewButtonDisabled={viewButtonDisabled}
    >
      {showPoliciesView && <SelectManagedPolicy />}
      {showPoliciesView && <SelectPolicy />}
      {showReviewView && <ReviewView />}
    </PolicyManagement>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  id: getPrincipalId(state),
  policyName: getPolicyName(state),
  accountId: getAccountIdFromPolicyRef(state),
  operation: getOperation(state),
  principal: getPrincipalType(state),
  isManagedPolicy: isManagedPolicyViewSelected(state),
  title: getModuleTitle(state),
  saveButtonLabel: getSaveButtonLabel(state),
  saveButtonDisabled: !isSaveButtonEnabled(state),
  nextButtonDisabled: !isNextButtonEnabled(state),
  showReviewView: isReviewView(state),
  showPoliciesView: isPoliciesView(state),
  successMessage: getSuccessMessage(state),
  errorMessage: getErrorMessage(state),
  policiesViewSelected: isCustomPolicyViewSelected(state),
  managedPoliciesViewSelected: isManagedPolicyViewSelected(state),
  information: getInformation(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setManagedPoliciesView: () => dispatch(showManagedPolicyView()),
  setPoliciesView: () => dispatch(showCustomPolicyView()),
  setPolicyName: (name: string) => dispatch(setPolicyName(name)),
  setPolicyRef: (ref: string) => dispatch(setPolicyRef(ref)),
  nextStep: () => dispatch(showPolicyReviewView()),
  previousStep: (error: boolean) => {
      dispatch(showPolicyView());
      if (error) {
        dispatch(setErrorMessage());
        dispatch(setSelectedPolicies());
        dispatch(setSelectedManagedPolicies());
        dispatch(setPolicyBulkRequest());
      }
    },
  updatePolicies: (operation: PolicyOperation, principal: PolicyOperationPrincipal,
                   isManagedPolicy: boolean, id: string, accountId: string) =>
    dispatch(updatePolicies(operation, principal, isManagedPolicy, id, accountId)),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps
)(PolicyManagementContainer);
