import { isEmptyString } from "@util";
import { AppSchema } from "@main/schemas";
import { UserProfileAttributesMap } from "@data";
import { reloadUsers } from "@modules/users/actions";
import { createActions } from "@modules/base/createActions";
import { RestClientError, UserIdmClient } from "@network";
import { ACTION_TYPES, DEFAULT_STATE, NewUserWizardStep } from "./reducers";
import {
  getAccessToken,
  getApplicationId,
  getEmailTemplateId,
  getInviteUserRequest,
  getNextStep,
  getPreviousStep,
  getSendEmailCopy,
  getUserId,
  isApplicationIdValid,
  isUserIdValid,
} from "./selectors";

export const {
  userId: setUserId,
  userIdErrorMessage: setUserIdErrorMessage,
  applicationId: setApplicationId,
  profile: setProfile,
  emailTemplateId: setEmailTemplateId,
  steps: setSteps,
  currentStep: setCurrentStep,
  sendEmailCopy: setSendEmailCopy,
  setErrorMessage,
  setSuccessMessage,
  showLoadingIndicator,
  hideLoadingIndicator,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  INVITE_USER_REQUEST: inviteUserRequest,
  INVITE_USER_SUCCESS: inviteUserSuccess,
  INVITE_USER_FAILED: inviteUserFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setUserId());
  dispatch(setUserIdErrorMessage());
  dispatch(setApplicationId());
  dispatch(setProfile());
  dispatch(setEmailTemplateId());
  dispatch(setSendEmailCopy());
  dispatch(setSteps());
  dispatch(setCurrentStep());
  return dispatch(baseReset());
};

export const showUserInfoStep = () => (dispatch: any) =>
  dispatch(setCurrentStep(NewUserWizardStep.USER_INFO));

export const showApplicationStep = () => (dispatch: any) =>
  dispatch(setCurrentStep(NewUserWizardStep.APPLICATION));

export const showPreviousStep = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(setCurrentStep(getPreviousStep(getState())));

export const showNextStep = () => (dispatch: any, getState: () => AppSchema) =>
  dispatch(setCurrentStep(getNextStep(getState())));

export const updateUserId = (userId: string) => (dispatch: any) => {
  dispatch(setUserIdErrorMessage());
  return dispatch(setUserId(userId));
};

export const updateApplicationId = (applicationId: string) => (dispatch: any) => {
  dispatch(setApplicationId(applicationId));
  // Reset the email template id whenever the application id changes to ensure that the
  // user must consciously select an email template appropriate for that application; this
  // logic will prevent a previously selected email template for a different application from
  // being used if the application id is changed
  return dispatch(setEmailTemplateId());
};

export const updateProfile = (profile: UserProfileAttributesMap) => (dispatch: any) => {

  const profileAttributes = Object.keys(profile)
    .reduce((attributes: UserProfileAttributesMap, key: string) => {
      const attribute = profile[key];
      attributes[attribute.getName()] = attribute.toJS();
      return attributes;
    }, {});

  return dispatch(setProfile(profileAttributes));
};

export const inviteUser = () => (dispatch: any, getState: () => AppSchema) => {

  const validUserId = isUserIdValid(getState());

  if (!validUserId) {
    dispatch(setUserIdErrorMessage("Invalid User ID"));
  } else {
    dispatch(setUserIdErrorMessage());
  }

  if (!validUserId) {
    return dispatch(showUserInfoStep());
  }

  const validApplicationId = isApplicationIdValid(getState());

  if (!validApplicationId) {
    return dispatch(showApplicationStep());
  }

  const state = getState();
  const accessToken = getAccessToken(state);
  const userId = getUserId(state);
  const applicationId = getApplicationId(state);
  const request = getInviteUserRequest(state);
  const emailTemplateId = getEmailTemplateId(state);
  const sendEmailCopy = getSendEmailCopy(state);

  const json = isEmptyString(emailTemplateId) ?
    JSON.stringify({
      userId,
      copyEmailToAdmin: sendEmailCopy,
      profile: request.profile,
    }) :
    JSON.stringify({
      userId,
      copyEmailToAdmin: sendEmailCopy,
      profile: request.profile,
      emailTemplateId: request.getEmailTemplateId()
    });

  dispatch(showLoadingIndicator());
  dispatch(setSuccessMessage());
  dispatch(setErrorMessage());
  dispatch(hideAccessDenied());
  dispatch(inviteUserRequest());

  return UserIdmClient.inviteUser(accessToken, applicationId, json)
    .then(() => {

      dispatch(inviteUserSuccess());
      dispatch(setSuccessMessage("User Invited"));
      dispatch(hideLoadingIndicator());
      return dispatch(reloadUsers());

    }, (response: RestClientError) => {

      const { analytic, error = "Invite user failed" } = response;

      dispatch(inviteUserFailed(analytic));
      dispatch(setErrorMessage(error));

      if (response.status === 403) {
        dispatch(showAccessDenied());
      }

      return dispatch(hideLoadingIndicator());
    });
};
