import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { DeviceTypeWizardActions } from "../actions";
import { DeviceTypeWizardSelectors } from "../selectors";
import NameView, { Actions, Model } from "../components/NameView";

interface ContainerModel extends Model {
  hidden?: boolean;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const NameViewContainer = (props: Props) => {

  const { hidden, ...otherProps } = props;

  if (hidden) {
    return null;
  }

  return <NameView {...otherProps} />;
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => {

  const hidden = !DeviceTypeWizardSelectors.isNameViewSelected(state);

  if (!DeviceTypeWizardSelectors.isJsonValid(state)) {
    return {
      hidden,
      showInvalidJsonError: true,
      ...ownProps,
    };
  }

  return {
    hidden,
    deviceTypeName: DeviceTypeWizardSelectors.getName(state),
    deviceTypeNameError: !DeviceTypeWizardSelectors.isNameValid(state)
      ? DeviceTypeWizardSelectors.getErrorMessage(state) : "",
    deviceTypeNameDisabled: DeviceTypeWizardSelectors.isEditModeActive(state),
    deviceTypeDescription: DeviceTypeWizardSelectors.getDescription(state),
    deviceTypeTags: DeviceTypeWizardSelectors.getTags(state),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setDeviceTypeName: (name: string) => dispatch(DeviceTypeWizardActions.updateName(name)),
  setDeviceTypeDescription: (description: string) => dispatch(DeviceTypeWizardActions.updateDescription(description)),
  updateTags: (tags: string[]) => dispatch(DeviceTypeWizardActions.updateTags(tags)),
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(NameViewContainer);
