import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { FederationProviderWizardActions } from "../actions";
import { FederationProviderWizardSelectors } from "../selectors";
import ClientView, { Actions, Model } from "../components/ClientView";

const mapStateToProps = (state: AppSchema): Model => {

  if (!FederationProviderWizardSelectors.isJsonValid(state)) {
    return {
      showInvalidJsonError: true,
    };
  }

  return {
    domain: FederationProviderWizardSelectors.getDomain(state),
    clientId: FederationProviderWizardSelectors.getClientId(state),
    clientSecret: FederationProviderWizardSelectors.getClientSecret(state),
    scope: FederationProviderWizardSelectors.getScope(state),
  };
};

const mapDispatchToProps = (dispatch: any): Actions => ({
  setDomain: (domain: string) => dispatch(FederationProviderWizardActions.updateDomain(domain)),
  setClientId: (clientId: string) => dispatch(FederationProviderWizardActions.updateClientId(clientId)),
  setClientSecret: (clientSecret: string) => dispatch(FederationProviderWizardActions.updateClientSecret(clientSecret)),
  updateScope: (scope: string[]) => dispatch(FederationProviderWizardActions.updateScope(scope)),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(ClientView);
