import { ActionType } from "@util/ReducerUtil";
import { getStringValue } from "@util/Functions";
import { AnyAction, Dispatch, Middleware } from "redux";
import { LoginActionType } from "@modules/login/reducers";
import SessionStorageClient from "@util/SessionStorageClient";
import {
  KEY_ACCESS_TOKEN,
  KEY_LAST_IDENTITY_TYPE,
  KEY_NONCE,
  KEY_REFRESH_TOKEN,
} from "./constants";

export const createSessionStorageMiddleware = (): Middleware =>
  () => (next: Dispatch) => (action: AnyAction) => {

    const { type, value = "" } = action;

    // Cast the value to a string in case the value is a number/boolean
    const persistedValue = getStringValue(value + "");

    if (type === ActionType.UPDATE_AUTH_TOKEN) {
      SessionStorageClient.setItem(KEY_ACCESS_TOKEN, persistedValue);
    } else if (type === ActionType.UPDATE_REFRESH_TOKEN) {
      SessionStorageClient.setItem(KEY_REFRESH_TOKEN, persistedValue);
    } else if (type === ActionType.SET_IDENTITY_TYPE) {
      SessionStorageClient.setItem(KEY_LAST_IDENTITY_TYPE, persistedValue);
    } else if (type === LoginActionType.SET_NONCE) {
      SessionStorageClient.setItem(KEY_NONCE, persistedValue);
    } else if (type === LoginActionType.FETCH_OIDC_TOKENS_SUCCESS) {
      // We can remove the nonce key once the user is logged in since it's no longer needed
      SessionStorageClient.removeItem(KEY_NONCE);
    } else if (type === ActionType.RESET) {
      // Purge session storage if a RESET event occurs, which means the user is being logged out
      SessionStorageClient.clear();
    }

    return next(action);
  };

export default createSessionStorageMiddleware;
