import React from "react";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useDataAccessRequestClient } from "@hooks";

export interface UseUploadTrainingCertFileProps extends Partial<UseApiRequestProps<void>> {
  file?: File;
}

export interface UseUploadTrainingCertFileModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UseUploadTrainingCertFileActions {
  uploadTrainingCert: (file: File) => void;
}

type Props = UseUploadTrainingCertFileProps;
type Model = UseUploadTrainingCertFileModel;
type Actions = UseUploadTrainingCertFileActions;
type Result = [Model, Actions];

export const useUploadTrainingCertFile = (props: Props): Result => {

  const {
    defaultErrorMessage = "Failed to upload training certificate",
    file = new File([], ""),
    ...otherProps
  } = props;

  const DataAccessRequestClient = useDataAccessRequestClient();

  const makeApiRequest = React.useCallback(() =>
      DataAccessRequestClient.uploadTrainingCert(file),
    [DataAccessRequestClient, file]);

  const [baseModel, { refresh: uploadTrainingCert }] =
    useApiRequest<void>({
      ...otherProps,
      makeApiRequest,
      defaultErrorMessage,
      deferRequest: true,
    });

  const successMessage = React.useMemo(() => {
    const { showSuccessView } = baseModel;
    return showSuccessView ? "Training certificate uploaded" : "";
  }, [baseModel]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
  }), [
    baseModel,
    successMessage,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    uploadTrainingCert,
  }), [
    uploadTrainingCert,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useUploadTrainingCertFile;
