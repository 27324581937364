import { Record } from "immutable";
import { ApprovalProcessingDetailAttributes } from "@data/ApprovalProcessingDetail";
import { AccessRequestType } from "@data/DataAccessRequest";
import { getStringValue } from "@util";

export enum RequestState {
  PENDING = "PENDING",
  PENDING_DO_APPROVAL = "PENDING_DO_APPROVAL",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  REVOKED = "REVOKED",
  CANCELLED = "CANCELLED",
}

export enum ApprovalRequestType {
  ADDITIONAL_INFORMATION = "ADDITIONAL_INFORMATION",
  IOT_APPROVAL = "IOT_APPROVAL",
  BO_APPROVAL = "BO_APPROVAL",
  DO_APPROVAL = "DO_APPROVAL",
  DATA_SET_ACCESS_REQUEST_DO = "DATA_SET_ACCESS_REQUEST_DO",
  DATA_SET_ACCESS_REQUEST_LM = "DATA_SET_ACCESS_REQUEST_LM",
}

export interface AdditionalInformation {
  reason: string;
  accessRequestType: AccessRequestType;
  trainingCompletionCertificateId: string;
  privacyTermsConditionsId: string;
  deviceTypeIdentifier?: string;
}

export interface DataApprovalRequestAttributes {
  approvalRequestId: string;
  accountId: string;
  dataSetAlias: string;
  requestState?: RequestState;
  requestType?: ApprovalRequestType;
  approvers?: string[];
  requestedBy?: string;
  requestedAt?: string;
  sourceSystemRequestId?: string;
  processingDetails: ApprovalProcessingDetailAttributes[];
  additionalInformation: AdditionalInformation;
}

export class DataApprovalRequest extends Record({
  approvalRequestId: "",
  dataSetAlias: "",
  accountId: "",
  requestState: RequestState.PENDING,
  requestType: ApprovalRequestType.IOT_APPROVAL,
  approvers: [],
  requestedBy: "",
  requestedAt: "",
  sourceSystemRequestId: "",
  processingDetails: [],
  additionalInformation: {},
}) implements DataApprovalRequestAttributes {

  public static EMPTY: DataApprovalRequest = new DataApprovalRequest();

  public readonly approvalRequestId: string;
  public readonly dataSetAlias: string;
  public readonly accountId: string;
  public readonly requestState: RequestState;
  public readonly requestType: ApprovalRequestType;
  public readonly approvers: string[];
  public readonly requestedBy: string;
  public readonly requestedAt: string;
  public readonly sourceSystemRequestId: string;
  public readonly processingDetails: ApprovalProcessingDetailAttributes[];
  public readonly additionalInformation: AdditionalInformation;

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getName(): string {
    return getStringValue(this.dataSetAlias);
  }

  public getApprovalRequestId(): string {
    return getStringValue(this.approvalRequestId);
  }

  public getStatus(): string {
    return getStringValue(this.requestState);
  }

  public getType(): string {
    return getStringValue(this.requestType);
  }

  public isPending(): boolean {
    const requestState = this.requestState;
    return requestState === RequestState.PENDING || requestState === RequestState.PENDING_DO_APPROVAL;
  }

  public getRequestedBy(): string {
    const res = getStringValue(this.requestedBy).match("principal:user:(.+)");
    return res ? res[1] : "";
  }
}
