import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { getAuthToken, getAuthTokenExpiryTime } from "@main/selectors";
import { BuildInfoAnchor, withBuildInfo } from "@components/build-info";
import { AccountSettings, Actions, Model } from "../components/AccountSettings";

const VERSION = process.env.REACT_APP_VERSION || "";

export interface ContainerModel extends Model {
}

export interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const mapStateToProps = (state: AppSchema): Model => ({
  accessToken: getAuthToken(state),
  accessTokenExpiration: getAuthTokenExpiryTime(state),
  version: VERSION,
});

const AccountSettingsPage = connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
)(AccountSettings);

export default withBuildInfo(AccountSettingsPage, BuildInfoAnchor.BOTTOM_LEFT);
