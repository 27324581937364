import { Record } from "immutable";
import { getStringValue } from "@util";

export interface RedirectUriListItemDataAttributes {
  uri: string;
}

export class RedirectUriListItemData extends Record({
  uri: ""
}) implements RedirectUriListItemDataAttributes {

  public static EMPTY: RedirectUriListItemData = new RedirectUriListItemData();

  public readonly uri: string;

  public getUri(): string {
    return getStringValue(this.uri);
  }
}
