import { StyleRules } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const textField: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    "& label": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      lineHeight: "0",
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    "& input": {
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      padding: "8px 10px",
      margin: 0,
    },
  },
};

export const formControlLabel: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "16px",
  },
  label: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "16px",
    marginLeft: 8,
  },
};

export const userFilterDialog = () =>
  createStyles({
    dialogContainer: {
      "& .content": {

        "& .errorView": {
          padding: 15,
          flexFlow: "row wrap",
          marginBottom: 25,
          marginTop: -8,

          "& .icon": {
            width: 24,
            height: 24,
            fontSize: 12,
            marginRight: 8,
          },

          "& .title": {
            display: "none",
          },

          "& .message": {
            fontSize: 12,
            lineHeight: 0,
            margin: 0,
          },
        },
      },
    },
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "center",
    },
    attributeKeyContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
    },
    inputField: {
      flex: "1 0 auto",
      maxWidth: 250,
      marginLeft: 15,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
  });

export const searchFilters = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      marginBottom: 16,
    },
    filter: {
      display: "flex",
      flexFlow: "row nowrap",
    },
    searchFilter: {
      height: 28,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "12px",
      paddingLeft: 4,
      paddingRight: 4,
      marginLeft: 4,
      marginTop: 8,
      "&:first-child": {
        marginTop: 0,
      },
      "& span": {
        marginLeft: 2,
        marginRight: 2,
      },
    },
    icon: {
      color: Color.WHITE,
      width: 12,
      height: 12,
    },
    deleteIcon: {
      color: Color.WHITE,
      width: 12,
      height: 12,
    },
    checkboxContainer: {
      margin: "4px 8px",
    },
    checkbox: {
      padding: 0,
    },
  });

export const addFilter = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      borderBottom: "1px solid #ebebeb",
    },
    controls: {
      margin: "8px 0",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    addFilterButton: {
      minWidth: "auto",
      padding: 0,
    },
    addFilterButtonLabel: {
      color: Color.TEXT,
      fontWeight: 400,
      marginRight: 4,
    },
    addFilterIcon: {
      color: Color.SIGNIFY_GREEN,
      width: 24,
      height: 24,
      marginRight: 8,
      marginLeft: 4,
    },
    clearFiltersButton: {
      height: "auto",
      minWidth: "auto",
    },
    clearFiltersButtonLabel: {
      fontSize: 13,
      fontWeight: 500,
      textTransform: "uppercase",
    },
    disabledButton: {
      "& .icon, & .label": {
        color: Color.GREY3,
      },
    },
  });
