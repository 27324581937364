import React from "react";
import { SecurityServiceRegional } from "@data";
import { useHistory } from "react-router-dom";
import { getPathToService } from "@modules/securityServiceRegionalDetails/helpers";
import CreateService from "./containers/CreateService";

export function CreateServiceView() {

  const history = useHistory();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const showServiceDetails = React.useCallback((service: SecurityServiceRegional) => {
    history.replace(getPathToService(service.getId()));
  }, [history]);

  return (
    <CreateService
      cancel={cancel}
      showServiceDetails={showServiceDetails}
    />
  );
}

export default CreateServiceView;
