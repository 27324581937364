import React from "react";
import { Module } from "@data";
import { MODULE_ID } from "./constants";
import { initialize, open } from "./actions";
import { isDialogVisible } from "./selectors";
import { PrimaryIcon } from "./components/styles";
import PrimaryView from "./containers";

export const deviceTypeManagerModule: Module = {
  id: MODULE_ID,
  dialog: true,
  name: "Device Type Manager",
  title: "Execute Device Type State Transition",
  icon: PrimaryIcon,
  view: <PrimaryView />,
  isDialogOpen: isDialogVisible,
  initialize: () => (dispatch: any) => dispatch(initialize()),
  onClick: open,
};

export default deviceTypeManagerModule;
