import React from "react";
import {
  ConfirmationDialog,
  ConfirmationDialogActions,
  ConfirmationDialogModel,
} from "../../../components/confirmation-dialog";
import { DecommissionView } from "./DecommissionView";
import { DeprecateView } from "./DeprecateView";
import { DraftView } from "./DraftView";
import { PromoteView } from "./PromoteView";
import { JsonSchemaMetadata } from "../../../data";
import { SchemaAction } from "../reducers";
import { DeleteView } from "./DeleteView";
import { AlertDialog, ErrorView } from "@components";

export interface Model extends ConfirmationDialogModel<JsonSchemaMetadata> {
  schemaAction?: SchemaAction;
}

export interface Actions extends ConfirmationDialogActions<JsonSchemaMetadata> {
}

export const SchemaStateManager = (props: Model & Actions) => {

  const { schemaAction = SchemaAction.NONE, ...remainingProps } = props;

  const {
    item: schema = JsonSchemaMetadata.EMPTY,
    open = false,
    successMessage = "",
    cancel,
  } = remainingProps;

  if (!open) {
    return null;
  }

  const schemaId = schema.getId();

  const etag = schema.etag || "";

  const isMissingEtag = etag.length === 0;

  // Invalid schema error should only be shown if we are not showing a success message in case
  // the schema was deleted, which would explain a valid reason for the schema id being empty
  // since we remove the schema from the redux store on delete success.
  if ((isMissingEtag || schemaId.length === 0) && successMessage.length === 0) {
    return (
      <AlertDialog open={true} title="Error" close={cancel}>
        {!isMissingEtag && <ErrorView message="Invalid Schema" />}
        {isMissingEtag && (
          <ErrorView
            title="Invalid Schema"
            message={"Missing etag. Please try again from the schema details page."}
          />
        )}
      </AlertDialog>
    );
  }

  if (schemaAction === SchemaAction.DELETE) {
    return (
      <ConfirmationDialog { ...remainingProps }>
        <DeleteView { ...remainingProps } />
      </ConfirmationDialog>
    );
  }

  if (schemaAction === SchemaAction.PROMOTE) {
    return (
      <ConfirmationDialog { ...remainingProps }>
        <PromoteView { ...remainingProps } />
      </ConfirmationDialog>
    );
  }

  if (schemaAction === SchemaAction.DECOMMISSION) {
    return (
      <ConfirmationDialog { ...remainingProps }>
        <DecommissionView { ...remainingProps } />
      </ConfirmationDialog>
    );
  }

  if (schemaAction === SchemaAction.DEPRECATE) {
    return (
      <ConfirmationDialog { ...remainingProps }>
        <DeprecateView { ...remainingProps } />
      </ConfirmationDialog>
    );
  }

  if (schemaAction === SchemaAction.DRAFT) {
    return (
      <ConfirmationDialog { ...remainingProps }>
        <DraftView { ...remainingProps } />
      </ConfirmationDialog>
    );
  }

  return (
    <AlertDialog open={true} title="Invalid Schema Action" close={cancel}>
      <ErrorView message={`Unrecognized schema action [${schemaAction}] cannot be executed.`} />
    </AlertDialog>
  );
};

export default SchemaStateManager;
