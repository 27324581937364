export const Color = {
  TRANSPARENT: "transparent",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  BLACK2: "#474C55",
  BLACK3: "#3E434B",
  BLACK4: "#18202c",
  BLACK5: "#232f3e",
  CHROME: "#EDF3F5",
  LIGHT_GREY: "#E7E7E7",
  LIGHT_GREY2: "#F5F5F5",
  LIGHT_GREY3: "#EAEAEA",
  LIGHT_GREY4: "#F9FAF9",
  LIGHT_GREY5: "#EEEEEE",
  LIGHT_GREY6: "#F7F7F7",
  LIGHT_GREY7: "#FDFDFD",
  GREY1: "#D7D7D7",
  GREY2: "#BEC8D2",
  GREY3: "#98A2AE",
  GREY4: "#4D5766",
  GREY5: "#3C4858",
  GREY6: "#9E9E9E",
  GREY7: "#8D8D8D",
  GREY8: "#696969",
  GREY9: "#777777",
  GREY10: "#C8C7C7",
  GREY11: "#69696B",
  RED: "#F80F28",
  RED2: "#EC3B4C",
  RED3: "#ED3B4B",
  DARK_RED: "#A94442",
  LIGHT_RED: "#F8D7DA",
  RED4: "#FCE0E2",
  YELLOW: "#FFEA34",
  YELLOW2: "#F3C96B",
  LIGHT_YELLOW: "#FFFAE6",
  MUSTARD: "#F2BE00",
  AQUA: "#42C9FF",
  GREEN: "#5CEA00",
  LIGHT_GREEN: "#F5FCF7",
  LIGHT_GREEN2: "#F4FCF8",
  DARK_GREEN: "#6ABC3F",
  BLUE_GREEN: "#0A5A75",
  BLUE: "#0066A1",
  BLUE2: "#0069AC",
  BLUE3: "#066890",
  BLUE4: "#2980e4",
  BLUE_GREY: "#B9CFDA",
  DARK_BLUE: "#1B578E",
  LIGHT_DARK_BLUE: "#2D6F9E",
  DARK_BLUE_GREEN: "#176498",
  LIGHT_BLUE: "#337FBF",
  LIGHT_BLUE2: "#E8F4FD",
  LIGHT_BLUE3: "#C6E1F5",
  LIGHT_BLUE_GREEN: "#3184B1",
  BABY_BLUE: "#00C9FF",
  PURPLE: "#C04AFF",
  PURPLE2: "#9721FF",
  PURPLE3: "#734F87",
  PURPLE4: "#ECDCFF",
  PINK: "#EC407A",
  BROWN: "#8D6E63",
  TEAL: "#00C9A4",
  ORANGE: "#FF9800",
  LIGHT_ORANGE: "#FFF0D1",
  TEXT: "#2B2B2B",
  SIGNIFY_GREEN: "#29E48D",
  SIGNIFY_GREEN2: "#27D484",
  SIGNIFY_GREEN3: "#21B873",
  MODULES: "#29E48D",
  LIST_ITEMS: "#2980e4",
  DIVIDER: "#404854",
  HEADER: "#3C3C41",
  HEADER_TABS: "#2C2C2F",
};

export default Color;
