import { DataLakeIndexClient, GetDataLakeIndexResponse } from "@network";
import { useApiRequestAnalytics } from "@hooks";

export type GetDataLakeQueryResultsApi = (
  dataSetAlias: string,
  startDate: string,
  endDate: string,
  accountId: string,
  fileNameContains: string,
  nextPageToken: string) => Promise<GetDataLakeIndexResponse>;

export interface DataLakeIndexClientApi {
  getDataLakeQueryResults: GetDataLakeQueryResultsApi;
}

enum ActionType {
  GET_DATA_LAKE_INDEX_QUERY_RESULTS_REQUEST = "data_lake_index_client/get_data_lake_index_query_results_request",
  GET_DATA_LAKE_INDEX_QUERY_RESULTS_SUCCESS = "data_lake_index_client/get_data_lake_index_query_results_success",
  GET_DATA_LAKE_INDEX_QUERY_RESULTS_FAILED = "data_lake_index_client/get_data_lake_index_query_results_failed",
}

export const useGetDataLakeQueryResultsApi = (): GetDataLakeQueryResultsApi =>
  useApiRequestAnalytics(DataLakeIndexClient.getDataLakeQueryResults, {
    REQUEST_EVENT: ActionType.GET_DATA_LAKE_INDEX_QUERY_RESULTS_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DATA_LAKE_INDEX_QUERY_RESULTS_SUCCESS,
    ERROR_EVENT: ActionType.GET_DATA_LAKE_INDEX_QUERY_RESULTS_FAILED,
  });

export const useDataLakeIndexClient = (): DataLakeIndexClientApi => ({
  getDataLakeQueryResults: useGetDataLakeQueryResultsApi(),
});

export { ActionType as DataLakeIndexClientActionType };

export default useDataLakeIndexClient;
