import React from "react";
import classnames from "classnames";
import { clickHandler } from "@util";
import { EmailTemplate } from "@data";
import {
  ActionMenuItem,
  ContinueButton,
  DELETE_EMAIL_TEMPLATE_ACTION,
  EDIT_EMAIL_TEMPLATE_ACTION,
} from "@components";
import EmailTemplatesList, {
  EmailTemplatesListActions,
  EmailTemplatesListModel,
} from "@components/email-templates-list";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export const DEFAULT_ACTIONS: ActionMenuItem[] = Object.freeze([
  EDIT_EMAIL_TEMPLATE_ACTION,
  DELETE_EMAIL_TEMPLATE_ACTION,
]) as ActionMenuItem[];

export interface Model extends EmailTemplatesListModel {
  children?: React.ReactNode;
  showNotFound?: boolean;
  showAccessDenied?: boolean;
}

export interface Actions extends EmailTemplatesListActions {
  createEmailTemplate?: () => void;
  showEmailTemplateDetails?: (emailTemplate: EmailTemplate) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const EmailTemplates = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    createEmailTemplate,
    showEmailTemplateDetails,
    actions = DEFAULT_ACTIONS,
    children,
    ...otherProps
  } = props;

  return (
    <EmailTemplatesList
      {...otherProps}
      className={classnames("emailTemplates", className, classes.container)}
      actions={actions}
      showSummary={true}
      showSearch={true}
      onClickItem={showEmailTemplateDetails}
    >
      <ContinueButton
        className={classnames("createEmailTemplateButton", classes.createButton)}
        onClick={clickHandler(createEmailTemplate)}
      >
        Create Email Template
      </ContinueButton>
      {children}
    </EmailTemplatesList>
  );
});

export default EmailTemplates;
