import React from "react";
import classnames from "classnames";
import CircularProgress from "@components/circular-progress";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { loadingIndicator as styles } from "./styles";

export const LoadingIndicator = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  label?: string,
  labelClassName?: string,
  size?: number | string;
}) => {

  const { classes, className, label = "Loading...", labelClassName, size = 12 } = props;

  return (
    <div className={classnames(className, classes.container)}>
      <CircularProgress
        size={size}
        color="inherit"
        className="loadingIndicator"
        classes={{
          root: classes.loadingIndicator,
          svg: classes.loadingIndicatorIcon,
        }}
      />
      <label className={classnames(labelClassName, classes.label)}>
        {label}
      </label>
    </div>
  );
});

export default LoadingIndicator;
