import React from "react";
import { connect } from "react-redux";
import { Policy } from "@data";
import { AppSchema } from "@schemas";
import { noop } from "@util";
import { PoliciesList } from "@components";
import { PolicyOperationPrincipal, useAccountPolicies } from "@hooks";
import { getServiceId } from "../selectors";
import { setDetachPolicyButtonEnabled } from "../actions";
import { isCustomPoliciesViewActive } from "../selectors";

export interface Model {
  serviceId?: string;
  hidden?: boolean;
}

export interface Actions {
  showPolicyDetails?: (policy: Policy) => void;
  setDetachPolicyButtonEnabled?: (enabled: boolean) => void;
}

type Props = Model & Actions;

const CustomPoliciesView = (props: Props) => {

  const {
    hidden,
    serviceId,
    showPolicyDetails,
    setDetachPolicyButtonEnabled: enableDetachPolicyButton = noop,
  } = props;

  const [{ policies, ...model}, actions] = useAccountPolicies(PolicyOperationPrincipal.SERVICE, serviceId);

  const { totalNumPolicies } = model;

  React.useEffect(() => {
    enableDetachPolicyButton(totalNumPolicies > 0);
  }, [enableDetachPolicyButton, totalNumPolicies]);

  if (hidden) {
    return null;
  }

  return (
    <PoliciesList
      {...model}
      {...actions}
      className={"serviceCustomPolicies"}
      items={policies}
      onClickItem={showPolicyDetails}
      loadingLabel="Loading policies attached to this service..."
      noResultsLabel="Service does not have any policies attached"
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  serviceId: getServiceId(state),
  hidden: !isCustomPoliciesViewActive(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  setDetachPolicyButtonEnabled: (enabled: boolean) => dispatch(setDetachPolicyButtonEnabled(enabled)),
  ...ownProps,
});

export default connect <Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(CustomPoliciesView);
