import React from "react";
import classnames from "classnames";
import isBefore from "date-fns/isBefore";
import EndDateIcon from "@material-ui/icons/Today";
import DatePicker from "../../date-picker/DatePicker";
import isWithinInterval from "date-fns/isWithinInterval";
import StartDateIcon from "@material-ui/icons/DateRange";
import { beginningOfDayDate, daysAway, endOfDayDate, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const DATE_PICKER_DATE_FORMAT = "yyyy-MM-dd";

export interface DataLakeFileBrowserFiltersModel {
  className?: string;
  disabled?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
}

export interface DataLakeFileBrowserFiltersActions {
  setStartDate?: (date: Date | null) => void;
  setEndDate?: (date: Date | null) => void;
}

type Model = DataLakeFileBrowserFiltersModel;
type Actions = DataLakeFileBrowserFiltersActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataLakeFileBrowserFilters = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    disabled,
    startDate = null,
    endDate = null,
    setStartDate = noop,
    setEndDate = noop,
    children,
  } = props;

  const maxEndDate: Date | null = React.useMemo(() => {
    if (startDate === null) {
      return null;
    }
    const today = endOfDayDate(new Date());
    const maxDateAfterStartDate = daysAway(59, startDate);
    return isBefore(today, maxDateAfterStartDate) ? today : maxDateAfterStartDate;
  }, [startDate]);

  const updateStartDate = React.useCallback((pendingStartDate: Date | null) => {

    // If the start date is cleared, we must also clear the end date, because you cannot
    // make a query with an end date and not a start date
    if (pendingStartDate === null) {
      setStartDate(null);
      setEndDate(null);
      return;
    }

    const maxDateAfterPendingStartDate = daysAway(59, pendingStartDate);

    // If an end date has not been selected, we need to make sure that the pending start date
    // is within the 60 day max time range allowed by the data lake index api; otherwise, we need
    // to specify an end date 60 days from the pending start date to avoid a 400 bad request error.
    if (endDate === null) {

      const today = endOfDayDate(new Date());

      // If the start date is within sixty days of the current date,
      // we do not need to manually set the end date.
      if (isBefore(today, maxDateAfterPendingStartDate)) {
        setStartDate(pendingStartDate);
        return;
      }

      // Otherwise, we need to provide an end date, which we will default to the max allowed
      setStartDate(pendingStartDate);
      setEndDate(maxDateAfterPendingStartDate);
      return;
    }

    // If the user has previously provided an end date, we need to make sure that the end date
    // is within 60 days of the new start date; otherwise, we need to change the end date to
    // the max allowed value of 60 days after the new start date
    const isEndDateValid = isWithinInterval(endDate, {
      start: pendingStartDate,
      end: maxDateAfterPendingStartDate,
    });

    if (isEndDateValid) {
      setStartDate(pendingStartDate);
    } else {
      setStartDate(pendingStartDate);
      setEndDate(maxDateAfterPendingStartDate);
    }

  }, [endDate, setStartDate, setEndDate]);

  return (
    <div className={classnames("dataLakeFileBrowserFilters", className, classes.container)}>
      <DatePicker
        className={classnames("startDate", classes.filter, classes.datePicker, classes.startDate)}
        variant="date"
        label={"Start date"}
        icon={<StartDateIcon/>}
        disabled={disabled}
        format={DATE_PICKER_DATE_FORMAT}
        clearable={true}
        maxDate={endDate === null ? endOfDayDate(new Date()) : endDate}
        disableFuture={true}
        selectedDate={startDate}
        setSelectedDate={(date: Date | null) => {
          updateStartDate(date === null ? null : beginningOfDayDate(date));
        }}
      />
      <DatePicker
        className={classnames("endDate", classes.filter, classes.datePicker, classes.endDate)}
        variant="date"
        label={"End date"}
        icon={<EndDateIcon/>}
        disabled={disabled}
        format={DATE_PICKER_DATE_FORMAT}
        clearable={true}
        minDate={startDate}
        maxDate={maxEndDate}
        disableFuture={true}
        selectedDate={endDate}
        setSelectedDate={(date: Date | null) => {
          setEndDate(date === null ? null : endOfDayDate(date));
        }}
      />
      {children}
    </div>
  );
});

export default DataLakeFileBrowserFilters;
