import React from "react";
import { Primitive } from "@util/Comparators";
import { RestClientError } from "@network/RestClientError";
import { getStringValue, isEmptyString } from "@util/Functions";
import { AuthTokenContext } from "@components/auth-token-provider";
import sortAndFilterResults from "@components/sorted-search-results-list/sortAndFilterResults";

export interface UseSortedSearchResultsModel<Item, Column> {
  data: Item[];
  items: Item[];
  nameFilter: string;
  errorMessage: string;
  showLoadMoreButton: boolean;
  sortOrderAscending: boolean;
  sortByColumn?: Column;
  showSearch: boolean;
  showLoadingIndicator: boolean;
  totalNumItems: number;
  totalNumVisibleItems: number;
}

export interface UseSortedSearchResultsActions<Item, Column> {
  refresh: () => void;
  loadMore: () => void;
  setNameFilter: (nameFilter?: string) => void;
  setSortByColumn: (column?: Column) => void;
  setSortOrderAscending: (ascending?: boolean) => void;
}

export type FetchItemsResponse<Item> = {
  items?: Item[];
  next?: string;
};

export type FetchItemsApi<Item> =
  (authToken: string, nextPage?: string) => Promise<FetchItemsResponse<Item>>;

export interface UseSortedSearchResultsProps<Item, Column> {
  defaultSortByColumn?: Column;
  mapItemToColumnValue?: (item: Item, column: Column) => Primitive;
  filterResultsByNameFilter?: (item: Item, searchQuery: string) => boolean;
  fetchItems?: FetchItemsApi<Item>;
}

type Model<Item, Column> = UseSortedSearchResultsModel<Item, Column>;
type Actions<Item, Column> = UseSortedSearchResultsActions<Item, Column>;
type Props<Item, Column> = UseSortedSearchResultsProps<Item, Column>;
type ReturnValue<Item, Column> = [Model<Item, Column>, Actions<Item, Column>];

export const useSortedSearchResults = <Item, Column>(props: Props<Item, Column>): ReturnValue<Item, Column> => {

  const {
    defaultSortByColumn,
    mapItemToColumnValue,
    filterResultsByNameFilter,
    fetchItems = () => Promise.resolve({}),
  } = props;

  const authToken = React.useContext(AuthTokenContext);
  const [data, setData] = React.useState<Item[]>([]);
  const [nameFilter, setNameFilter] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [nextPageToken, setNextPageToken] = React.useState("");
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);
  const [sortOrderAscending, setSortOrderAscending] = React.useState(true);
  const [sortByColumn, setSortByColumn] = React.useState<Column | undefined>(defaultSortByColumn);
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);

  const listItems = React.useCallback(() => {

    if (isEmptyString(nextPageToken)) {
      setData([]);
    }

    return fetchItems(authToken, nextPageToken);

  }, [authToken, nextPageToken, setData, fetchItems]);

  React.useEffect(() => {

    let ignore = false;

    setShowLoadingIndicator(true);
    setErrorMessage("");

    listItems()
      .then(({ items: results = [], next = "" }: FetchItemsResponse<Item>) => {
        if (!ignore) {
          setData(current => current.concat(Array.isArray(results) ? results : []));
          setNextPageToken(getStringValue(next));
          setShowLoadingIndicator(false);
        }
      }, (response: RestClientError) => {
        if (!ignore) {
          const { error = "Fetch items failed" } = response;
          setErrorMessage(error);
          setShowLoadingIndicator(false);
        }
      });

    return () => { ignore = true; };

  }, [
    lastRefresh,
    setData,
    setNextPageToken,
    setErrorMessage,
    setShowLoadingIndicator,
    listItems,
  ]);

  const showLoadMoreButton = React.useMemo(() =>
    !isEmptyString(nextPageToken) && isEmptyString(errorMessage) && !showLoadingIndicator,
    [nextPageToken, errorMessage, showLoadingIndicator]);

  // This is also used as the retry callback in the event of an error
  const loadMore = React.useCallback(() => {
    setLastRefresh(+new Date);
  }, [setLastRefresh]);

  const refresh = React.useCallback(() => {
    setData([]);
    setNextPageToken("");
    setLastRefresh(+new Date);
    setSortOrderAscending(true);
    setSortByColumn(defaultSortByColumn);
  }, [
    setData,
    setNextPageToken,
    setLastRefresh,
    setSortOrderAscending,
    setSortByColumn,
    defaultSortByColumn,
  ]);

  const items = sortAndFilterResults<Item, Column>({
    items: data,
    nameFilter,
    sortByColumn,
    sortOrderAscending,
    mapItemToColumnValue,
    filterResultsByNameFilter,
  });

  const totalNumItems = React.useMemo(() => data.length, [data]);

  const totalNumVisibleItems = React.useMemo(() => items.length, [items]);

  const showSearch = React.useMemo(() => totalNumItems > 1, [totalNumItems]);

  const model = React.useMemo<Model<Item, Column>>(() => ({
    data,
    items,
    nameFilter,
    errorMessage,
    showLoadMoreButton,
    sortOrderAscending,
    sortByColumn,
    showSearch,
    showLoadingIndicator,
    totalNumItems,
    totalNumVisibleItems,
  }), [
    items,
    nameFilter,
    errorMessage,
    showLoadMoreButton,
    sortOrderAscending,
    sortByColumn,
    showSearch,
    showLoadingIndicator,
    totalNumItems,
    totalNumVisibleItems,
  ]);

  const actions = React.useMemo<Actions<Item, Column>>(() => ({
    refresh,
    loadMore,
    setNameFilter: value => setNameFilter(value || ""),
    setSortByColumn: value => setSortByColumn(value || defaultSortByColumn),
    setSortOrderAscending: value => setSortOrderAscending(typeof value === "boolean" ? value : true),
  }), [
    refresh,
    loadMore,
    setNameFilter,
    setSortByColumn,
    setSortOrderAscending,
  ]);

  return React.useMemo(() => [model, actions], [model, actions]);
};

export default useSortedSearchResults;
