import { AuditEvent } from "@data";
import { AppSchema } from "@schemas";
import { compare, isEmptyString } from "@util";
import { createActions } from "@base/createActions";
import { ACTION_TYPES, DEFAULT_STATE } from "./reducers";
import {
  getAvailableAccountIds,
  getAvailableOrigins,
  getAvailablePrincipals,
  getAvailableRequestIds,
  getAvailableSeverities,
  getAvailableTypes,
} from "./selectors";

export const {
  availableAccountIds: setAvailableAccountIds,
  availableOrigins: setAvailableOrigins,
  availableTypes: setAvailableTypes,
  availableSeverities: setAvailableSeverities,
  availablePrincipals: setAvailablePrincipals,
  availableRequestIds: setAvailableRequestIds,
  setErrorMessage,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  showLoadingIndicator,
  hideLoadingIndicator,
  FIND_EVENTS_REQUEST: findEventsRequest,
  FIND_EVENTS_SUCCESS: findEventsSuccess,
  FIND_EVENTS_FAILURE: findEventsFailure,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setAvailableAccountIds());
  dispatch(setAvailableOrigins());
  dispatch(setAvailableTypes());
  dispatch(setAvailableSeverities());
  dispatch(setAvailablePrincipals());
  dispatch(setAvailableRequestIds());
  return dispatch(baseReset());
};

const resetAutocompleteOptions = () => (dispatch: any) => {
  dispatch(setAvailableAccountIds());
  dispatch(setAvailableOrigins());
  dispatch(setAvailableTypes());
  dispatch(setAvailableSeverities());
  dispatch(setAvailablePrincipals());
  return dispatch(setAvailableRequestIds());
};

const updateAvailableTypes = (events: AuditEvent[]) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const current = getAvailableTypes(state);

    const updated: string[] = Array
      .from(new Set(current.concat(events.map(event => new AuditEvent(event).getType()))))
      .filter(value => !isEmptyString(value));

    updated.sort((left, right) => compare(left, right, true));

    return dispatch(setAvailableTypes(updated));
  };

const updateAvailableAccountIds = (events: AuditEvent[]) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const current = getAvailableAccountIds(state);

    const updated: string[] = Array
      .from(new Set(current.concat(events.map(event => new AuditEvent(event).getAccountId()))))
      .filter(value => !isEmptyString(value));

    updated.sort((left, right) => compare(left, right, true));

    return dispatch(setAvailableAccountIds(updated));
  };

const updateAvailableOrigins = (events: AuditEvent[]) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const current = getAvailableOrigins(state);

    const updated: string[] = Array
      .from(new Set(current.concat(events.map(event => new AuditEvent(event).getOriginator()))))
      .filter(value => !isEmptyString(value));

    updated.sort((left, right) => compare(left, right, true));

    return dispatch(setAvailableOrigins(updated));
  };

const updateAvailableRequestIds = (events: AuditEvent[]) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const current = getAvailableRequestIds(state);

    const updated: string[] = Array
      .from(new Set(current.concat(events.map(event => new AuditEvent(event).getRequestId()))))
      .filter(value => !isEmptyString(value));

    updated.sort((left, right) => compare(left, right, true));

    return dispatch(setAvailableRequestIds(updated));
  };

const updateAvailableSeverities = (events: AuditEvent[]) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const current = getAvailableSeverities(state);

    const updated: string[] = Array
      .from(new Set(current.concat(events.map(event => new AuditEvent(event).getSeverity()))))
      .filter(value => !isEmptyString(value));

    updated.sort((left, right) => compare(left, right, true));

    return dispatch(setAvailableSeverities(updated));
  };

const updateAvailablePrincipals = (events: AuditEvent[]) =>
  (dispatch: any, getState: () => AppSchema) => {

    const state = getState();

    const current = getAvailablePrincipals(state);

    const updated: string[] = Array
      .from(new Set(current.concat(events.map(event => new AuditEvent(event).getPrincipal()))))
      .filter(value => !isEmptyString(value));

    updated.sort((left, right) => compare(left, right, true));

    return dispatch(setAvailablePrincipals(updated));
  };

export const updateAutocompleteOptions = (events: AuditEvent[], overwrite: boolean = true) =>
  (dispatch: any) => {

  if (overwrite) {
    dispatch(resetAutocompleteOptions());
  }

  return Promise.all([
    dispatch(updateAvailableTypes(events)),
    dispatch(updateAvailableAccountIds(events)),
    dispatch(updateAvailableOrigins(events)),
    dispatch(updateAvailableRequestIds(events)),
    dispatch(updateAvailableSeverities(events)),
    dispatch(updateAvailablePrincipals(events)),
  ]);
};

export const initialize = () => (dispatch: any) => {

  return dispatch(reset());
};
