import React from "react";
import { DataApprovalRequest } from "@data";
import { useHistory } from "react-router-dom";
import { getPathToDataApprovalRequest } from "@modules/dataApprovalRequestDetails/helpers";
import DataApprovalRequestsModule from "./containers/DataApprovalRequestsModule";

export function DataApprovalRequestsModuleView() {

  const history = useHistory();

  const showDataApprovalRequestDetails = React.useCallback((dataApprovalRequest: DataApprovalRequest) =>
    history.push(getPathToDataApprovalRequest(dataApprovalRequest.approvalRequestId)), [history]);

  return (
    <DataApprovalRequestsModule
      showDataApprovalRequestDetails={showDataApprovalRequestDetails}
    />
  );
}

export default DataApprovalRequestsModuleView;
