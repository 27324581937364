import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import {
  getErrorMessage,
  getNamespace,
  getSuccessMessage,
  isNamespaceValid,
  isProgressIndicatorVisible,
} from "../selectors";
import { save } from "../actions";
import { usePortalSnackbar } from "@components/portal-snackbar-provider";
import CreateNamespace, { Actions, Model } from "../components/CreateNamespace";
import NamespaceInfo from "./NamespaceInfo";

export interface ContainerModel extends Model {
  namespace?: string;
  errorMessage?: string;
  successMessage?: string;
}

export interface ContainerActions extends Actions {
  onSuccess?: (namespace: string) => void;
}

type Props = ContainerModel & ContainerActions;

const NamespaceWizardModuleContainer = (props: Props) => {

  const {
    namespace = "",
    errorMessage = "",
    successMessage = "",
    onSuccess = noop,
    ...otherProps
  } = props;

  const onSuccessMessageShown = React.useCallback(() => {
    onSuccess(namespace);
  }, [onSuccess, namespace]);

  usePortalSnackbar("create-namespace", {
    errorMessage,
    successMessage,
    onSuccessMessageShown,
    successCallbackDelay: 250
  });

  return (
    <CreateNamespace {...otherProps}>
      <NamespaceInfo />
    </CreateNamespace>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => {

  const successMessage = getSuccessMessage(state);
  const finished = !!(successMessage && successMessage.length > 0);
  const showProgressIndicator = isProgressIndicatorVisible(state);
  const buttonsDisabled = showProgressIndicator || finished || !isNamespaceValid(state);

  const showCancelButton = !finished && !showProgressIndicator;

  return {
    showProgressIndicator,
    showCancelButton,
    buttonsDisabled,
    finished,
    namespace: getNamespace(state),
    errorMessage: getErrorMessage(state),
    successMessage,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  save: () => dispatch(save()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(NamespaceWizardModuleContainer);
