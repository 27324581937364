import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { setSendEmailCopy } from "../actions";
import { ReviewView, Actions, Model } from "../components/ReviewView";
import { isReviewViewActive, getInviteUserState, getInviteUserRequest, getSendEmailCopy } from "../selectors";

interface ContainerModel extends Model {
  hidden?: boolean;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const ReviewViewContainer = (props: Props) => {

  const {
    hidden,
    ...otherProps
  } = props;

  if (hidden) {
    return null;
  }

  return (
    <ReviewView
      {...otherProps}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  hidden: !isReviewViewActive(state),
  currentState: getInviteUserState(state),
  request: getInviteUserRequest(state),
  sendEmailCopy: getSendEmailCopy(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setSendEmailCopy: (value: boolean) => dispatch(setSendEmailCopy(value)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps
)(ReviewViewContainer);
