import createStyles from "@material-ui/core/styles/createStyles";
import { UsersIcon as ModuleIcon } from "@icons";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    button: {
      marginLeft: 15,
      "&:first-child": {
        marginLeft: 0,
      },
    },
    buttonLabel: {
      textTransform: "uppercase",
    },
    inviteUserButton: {
      padding: "6px 16px",
    },
    reportButton: {
      padding: "6px 16px",
    },
    createUserButton: {
    },
  });

export default styles;
