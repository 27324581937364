import React from "react";
import { isEmptyString, noop } from "@util";
import { JsonSchemaMetadata } from "@data";
import { AuthTokenContext } from "@components";
import { RestClientError, SchemaRegistryClient } from "@network";

export interface UseSchemaMetadataModel {
  metadata: JsonSchemaMetadata;
  errorMessage: string;
  showErrorView: boolean;
  showProgressIndicator: boolean;
  isValidSchemaId: boolean;
}

export interface UseSchemaMetadataActions {
  refresh: () => void;
}

export const useSchemaMetadata = (namespace: string,
                                  name: string,
                                  version: string): [UseSchemaMetadataModel, UseSchemaMetadataActions] => {

  const authToken = React.useContext(AuthTokenContext);
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);
  const [metadata, setMetadata] = React.useState(JsonSchemaMetadata.EMPTY);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showProgressIndicator, setShowProgressIndicator] = React.useState(false);

  const isValidSchemaId = React.useMemo(() =>
    !isEmptyString(namespace) &&
    !isEmptyString(name) &&
    !isEmptyString(version),
    [namespace, name, version]);

  React.useEffect(() => {

    if (!isValidSchemaId) {
      return () => noop;
    }

    let ignore = false;

    setShowProgressIndicator(true);
    setErrorMessage("");

    SchemaRegistryClient.getSchemaMetadata(authToken, namespace, name, version)
      .then((jsonSchemaMetadata: JsonSchemaMetadata) => {

        if (!ignore) {
          setMetadata(new JsonSchemaMetadata(jsonSchemaMetadata));
          setShowProgressIndicator(false);
        }

      }, (response: RestClientError) => {

        if (!ignore) {
          const { error = "Fetch schema metadata failed" } = response;
          setErrorMessage(error);
          setShowProgressIndicator(false);
        }
      });

    return () => { ignore = true; };

  }, [
    isValidSchemaId,
    authToken,
    lastRefresh,
    namespace,
    name,
    version,
  ]);

  const showErrorView = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]);

  const model: UseSchemaMetadataModel = {
    metadata,
    errorMessage,
    showErrorView,
    showProgressIndicator,
    isValidSchemaId,
  };

  const actions: UseSchemaMetadataActions = {
    refresh: () => setLastRefresh(+new Date),
  };

  return [model, actions];
};

export default useSchemaMetadata;
