import React from "react";
import { User } from "@data/User";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { equalsIgnoreCase, isGenerateUserReportButtonDisabled, noop } from "@util";
import { useAccountUsers } from "@hooks";
import { LockUserAction } from "@modules/lockUser/reducers";
import { open as openLogoutUserDialog } from "@modules/logoutUser/actions";
import { open as openDeleteUserDialog } from "@modules/deleteUser/actions";
import { open as openLockUserDialog } from "@modules/lockUser/actions";
import { open as openCloseUserDialog } from "@modules/closeUser/actions";
import { open as openExpirePasswordDialog } from "@modules/expireUserPassword/actions";
import { open as openChangePasswordDialog } from "@modules/changePassword/actions";
import { open as openUserReportDialog } from "@modules/userReport/actions";
import {
  getButtonLabel as getReportButtonLabel,
  isButtonActive as isReportButtonActive,
} from "@modules/userReport/selectors";
import {
  fetchUsersFailed,
  fetchUsersRequest,
  fetchUsersSuccess,
  setNameFilter,
  setReloadItemsRequested,
} from "../actions";
import { getNameFilter, isReloadItemsRequested } from "../selectors";
import Users, { Actions, Model } from "../components/Users";

const SHOW_GENERATE_REPORT_BUTTON = !isGenerateUserReportButtonDisabled();

interface ContainerModel extends Model {
  reloadItemsRequested?: boolean;
}

interface ContainerActions extends Actions {
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
  updateNameFilter?: (value: string) => void;
  onItemsReloaded?: () => void;
}

type Props = ContainerModel & ContainerActions;

const UsersContainer = (props: Props) => {

  const {
    nameFilter,
    reloadItemsRequested,
    showGenerateReportButton = SHOW_GENERATE_REPORT_BUTTON,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
    updateNameFilter = noop,
    onItemsReloaded = noop,
    ...otherProps
  } = props;

  const [{ users: items, ...model }, { refresh, ...actions }] = useAccountUsers({
    nameFilter,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
  });

  const { nameFilter: updatedNameFilter } = model;

  // Keep our redux store up-to-date with the hook's latest nameFilter state value
  React.useEffect(() => {
    if (!equalsIgnoreCase(nameFilter, updatedNameFilter)) {
      updateNameFilter(updatedNameFilter);
    }
  }, [nameFilter, updatedNameFilter, updateNameFilter]);

  React.useEffect(() => {
    if (reloadItemsRequested) {
      refresh();
      onItemsReloaded();
    }
  }, [reloadItemsRequested, refresh, onItemsReloaded]);

  if (reloadItemsRequested) {
    return null;
  }

  return (
    <Users
      {...model}
      {...actions}
      {...otherProps}
      items={items}
      showGenerateReportButton={showGenerateReportButton}
      refresh={refresh}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  nameFilter: getNameFilter(state),
  reloadItemsRequested: isReloadItemsRequested(state),
  reportButtonActive: isReportButtonActive(state),
  reportButtonLabel: getReportButtonLabel(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  logoutUser: (user: User) => dispatch(openLogoutUserDialog(user.getUserId())),
  expirePassword: (user: User) => dispatch(openExpirePasswordDialog(user)),
  changePassword: (user: User) => dispatch(openChangePasswordDialog(user.getUserId())),
  deleteUser: (user: User) => dispatch(openDeleteUserDialog(user.getUserId())),
  closeUser: (user: User) => dispatch(openCloseUserDialog(user.getUserId())),
  lockUser: (user: User) => dispatch(openLockUserDialog(user.getUserId(), LockUserAction.LOCK_USER)),
  unlockUser: (user: User) => dispatch(openLockUserDialog(user.getUserId(), LockUserAction.UNLOCK_USER)),
  generateReport: () => dispatch(openUserReportDialog()),
  trackRequestEvent: () => dispatch(fetchUsersRequest()),
  trackSuccessEvent: () => dispatch(fetchUsersSuccess()),
  trackErrorEvent: analytic => dispatch(fetchUsersFailed(analytic)),
  updateNameFilter: value => dispatch(setNameFilter(value)),
  onItemsReloaded: () => dispatch(setReloadItemsRequested(false)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(UsersContainer);
